import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import Dropdown from "../../commonModules/InputSearchDropdown";
import Select from "../../commonModules/InputFieldMultipleSelect";
import AdminService from "../../../services/admin.service";
import { decryptData } from "../../../services/auth-service";
import Validations from "../../../validations/validations";
import Cities from '../../cities.json'
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import ErrorMessage from "../../commonModules/ErrorMessage";
const lobValuesAll = [
  { label: "Health", value: "HEALTH" },
  { label: "Motor", value: "MOTOR" },
  { label: "Life", value: "LIFE" },
];

export default function ContainerForAgent(props) {
  const [lobValues, setLobValues] = useState(lobValuesAll);
  const [selectedAgency, setAgency] = useState();
  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      console.log("no user");
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  const [disabled, setDisabled] = useState(true);
  const [allAgencies, setAllAgencies] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [selectedAgencyId, setSelectedAgencyId] = useState();

  const [cities, setCities] = useState([]);
  const [allStates, setAllStates] = useState([]);
  const [filteredStates, setFilteredStates] = useState([]);

  useEffect(() => {
    // fetch('../../cities.json')
    //   .then(response => response.json())
    //   .then(data => {
    // console.log('fetched', Cities);
      const uniqueStates = [...new Set(Cities.map(item => item.state))];
      setCities(Cities.map(item => ({ label: item.name, value: item.name, state: item.state })));
      setAllStates(uniqueStates.map(state => ({ label: state, value: state })));
      setFilteredStates(uniqueStates.map(state => ({ label: state, value: state })));
      // })
      // .catch(error => console.error('Error fetching cities:', error));
  }, []);

 

  useEffect(() => {
    fetchAgencies();
  }, []);

  const fetchAgencies = async () => {
    try {
      await AdminService.listAgency().then((response) => {
        setAllAgencies(response.data.data);
      });
    } catch (error) {
      console.log(error);
      // console.error("Error:", error.response.data.data);
      // let errorObj = error.response.data.data;
      // alert(Object.values(errorObj));
    }
  };

  let initialData = {
    name: "",
    address: "",
    lineOfBusiness: [],
    city: "",
    state: "",
    contactPersonName: "",
    mobileNumber: "",
    email: "",
    headOffice: "",
    pincode: "",
    agencyId: "AG-1",
    createdBy: user?.email ? user.email : "",
    status:"ACTIVE"
  };

  const validationSchema = Validations.branchValidations;

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      handleSubmit()
      console.log("values", values);
    },
  });
  const maxLength = 256;
  const maxLengthHeadOffice = 50;
  const handleLobChange =async (value) => {
    console.log("valueaaaaaaaaaaa",value)
    let lineOfBusiness= await value.map((ele) => ele.value)
    console.log("lineOfBusiness",lineOfBusiness)
    formik.setFieldValue("lineOfBusiness", lineOfBusiness);

  };

  const handleClosePopup = () => {
    setShowPopup(false);
    window.location.href = "/ListOfBranch";
  };

  const handleSet = async (value) => {
    setSelectedAgencyId(value);
    console.log("value", value);
    const agency = allAgencies.filter((agency) => agency.agencyName == value);
    setAgency(agency);
    console.log("agency val", agency)
    let arr = [];
    await agency[0].lineOfBusiness
      .slice(1, -1)
      .split(",")
      .map((lob) => {
        arr.push(
          lobValuesAll.filter((item) => {
            return lob.includes(item.value);
          })[0]
        );
      });
    setLobValues(arr);
    console.log("agency[0].aggencyType",agency[0].agencyType)
    if (agency[0].agencyType.slice(1, -1) === "SINGLEBANCH") {
      console.log("agency[0].id", agency[0].id);
      let data = { agencyId: agency[0].id };
      try {
        await AdminService.getAllBranchByAgencyId(data).then((res) => {
          if (res?.data?.data?.length < 1) {
            setIsShow(false);
            setDisabled(false);
          } else {
            setIsShow(true);
            setDisabled(true);
          }
        });
      } catch (error) {
        console.log(error);
      }
    } else {
      setIsShow(false);
      setDisabled(false);
    }
  };

  useEffect(() => {
  formik.setFieldValue("createdBy",user?.email)
  }, [user]);

  const handleSubmit = async () => {
   // e.preventDefault();
    const userData = formik.values;
   // userData.lineOfBusiness = formik.values.lineOfBusiness.map((a) => a.value);
    userData.agencyId = selectedAgency[0].id;
    console.log("values",userData)
    try {
      await AdminService.addBranch(userData);
      setShowPopup(true);
    } catch (error) {
      console.error("Error:", error);
      alert("Error Occured!");
      //alert(error)
      // console.error("Error:", error.response.data.data);
      // let errorObj = error.response.data.data;
      // alert(Object.values(errorObj));
    }
  };
  useEffect(() => {
    handleCityChange(formik.values.city);
  }, [formik.values.city]);
  useEffect(() => {
    handleStateChange(formik.values.state);
  }, [formik.values.state]);
  
  const handleStateChange = (selectedState)=>{

  }

  const handleCityChange = (selectedCityId) => {
    //console.log("jiiiiiiii")
    const selectedCity = cities.find(city => city.value === selectedCityId);
    console.log("selectedCity",selectedCity)
    if (selectedCity) {
      setFilteredStates([{ label: selectedCity.state, value: selectedCity.state }]);
      formik.setFieldValue('state', selectedCity.state); // Automatically set the state in formik
    } else {
      setFilteredStates(allStates); // Reset to all states if no city is selected
      formik.setFieldValue('state', ''); // Reset the state in formik
    }
  };

  return (
    <>
      <Form className="form-bg">
        <Row style={{ textAlign: "left" }}>
          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel aria-autocomplete="off">Agency Name</FloatingLabel>
            <Dropdown options={allAgencies} handleSet={handleSet} />
            {isShow ? (
              <span display="none">
                *You can not add Branch to single branch agency
              </span>
            ) : null}
          </Col>
        </Row>
        <Row style={{ textAlign: "left", zIndex: -1 }}>
          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="name"
              label="Branch Name*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="name"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className="floating-input"
                disabled={disabled}
              />
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.name && formik.errors.name ? (
                <div className="text-danger">{formik.errors.name}</div>
              ) : null}
            </Form.Text>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="address"
              label="Address*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="address"
                type="address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                className="floating-input"
                maxLength={maxLength}
                disabled={disabled}
              />
              <div
                style={{
                  marginTop: "5px",
                  fontSize: "0.6rem",
                  textAlign: "right",
                }}
              >
                {maxLength - formik.values.address.length}/256
              </div>

              {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
              <Form.Text className="text-danger">
                {formik.touched.address && formik.errors.address ? (
                  <div className="text-danger">{formik.errors.address}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <InputFieldDropdown
              formikFieldName="city"
              label="City*"
              optionsArray={cities}
              formik={formik}
              disabled={disabled}
            />
            <ErrorMessage formik={formik} fieldValue="city" />
          </Col>
          <Col md={6} sm={12} className="mb-3">
            <InputFieldDropdown
              formikFieldName="state"
              label="State*"
              optionsArray={filteredStates}
              formik={formik}
              disabled={disabled}
            />
            <ErrorMessage formik={formik} fieldValue="state" />
          </Col>

          {/* <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="city"
              label="City *"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="city"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                className="floating-input"
                disabled={disabled}
              />
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.city && formik.errors.city ? (
                <div className="text-danger">{formik.errors.city}</div>
              ) : null}
            </Form.Text>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="state"
              label="State *"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="state"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.state}
                className="floating-input"
                disabled={disabled}
              />
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.state && formik.errors.state ? (
                <div className="text-danger">{formik.errors.state}</div>
              ) : null}
            </Form.Text>
          </Col> */}

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="mobileNumber"
              label="Phone Number*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="mobileNumber"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.mobileNumber}
                className="floating-input"
                disabled={disabled}
              />
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                <div className="text-danger">{formik.errors.mobileNumber}</div>
              ) : null}
            </Form.Text>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="email"
              label="Email*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="email"
                type="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className="floating-input"
                disabled={disabled}
              />
              {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
              <Form.Text className="text-danger">
                {formik.touched.email && formik.errors.email ? (
                  <div className="text-danger">{formik.errors.email}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="headOffice"
              label="Head Office *"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="headOffice"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.headOffice}
                className="floating-input"
                maxLength={maxLengthHeadOffice}
                disabled={disabled}
              />
              <div
                style={{
                  marginTop: "5px",
                  fontSize: "0.6rem",
                  textAlign: "right",
                }}
              >
                {maxLengthHeadOffice - formik.values.headOffice.length}/50
              </div>
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.headOffice && formik.errors.headOffice ? (
                <div className="text-danger">{formik.errors.headOffice}</div>
              ) : null}
            </Form.Text>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="pincode"
              label="Pincode *"
              className=""
              aria-autocomplete="off"
              
            >
              <Form.Control
                name="pincode"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.pincode}
                className="floating-input"
                disabled={disabled}
              />
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.pincode && formik.errors.pincode ? (
                <div className="text-danger">{formik.errors.pincode}</div>
              ) : null}
            </Form.Text>
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="contactPersonName"
              label="Contact Person Name *"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="contactPersonName"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contactPersonName}
                className="floating-input"
                disabled={disabled}
              />
            </FloatingLabel>
            <Form.Text className="text-danger">
              {formik.touched.contactPersonName &&
              formik.errors.contactPersonName ? (
                <div className="text-danger">
                  {formik.errors.contactPersonName}
                </div>
              ) : null}
            </Form.Text>
          </Col>

          <Col sm={12} md={6} className="pt-2">
            <Form.Group controlId="lineOfBusiness" className="floating-input">
              <FloatingLabel className="floating-input">
                Line of Business
              </FloatingLabel>
              <Select
                options={lobValues}
                onChange={handleLobChange}
                isSearchable={false}
                isMulti={true}
                value={formik.values.lineOfBusiness}
                disabled={disabled}
              />
              <Form.Text className="text-danger">
                {formik.touched.lineOfBusiness &&
                formik.errors.lineOfBusiness ? (
                  <div className="text-danger">
                    {formik.errors.lineOfBusiness}
                  </div>
                ) : null}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
        <Button
          className="primary-btn"
          style={{ alignItems: "right", marginRight: "5px" }}
          type="submit"
          onClick={formik.handleSubmit}
          disabled={disabled}
        >
          Add Branch
        </Button>

        <Button
          type="button"
          className="btn-secondary"
          disabled={!formik.dirty}
          onClick={() => formik.resetForm()}
        >
          Cancel
        </Button>
      </Form>

      {showPopup && (
        <Modal show={showPopup} onHide={handleClosePopup}>
          <Modal.Header closeButton>
            <Modal.Title>Branch Added Successfully !</Modal.Title>
          </Modal.Header>

          <Modal.Footer>
            <Button
              type="button"
              className="primary-btn"
              onClick={handleClosePopup}
            >
              Okay!
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}