import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CountainerForHealth from "../CommonComponents/CountainerForHealth";
import health from "../../../../src/images/health.png";
import UnderConstruction from "../../../../src/images/UnderConstruction.jpg"

export default function HealthPage() {

  // ---- --useEffect---------
  useEffect(() => {
    // Add the "car-page" class to the body element when the component mounts
    document.body.classList.add("car-page");
    // Clean up function: Remove the "car-page" class when the component unmounts
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);
  // ---methods and jsx------

  return (
    <React.Fragment>
      <Container>
<img src={UnderConstruction} alt="" width={"50%"} />
        {/* <Row>
          <h4 className="mb-5 mt-5">Find the best Health plan</h4>
          <Col sm={12} md={5}>
            <div>
              <img src={health} alt="" width={"100%"} />
            </div>
          </Col>
          <Col sm={12} md={7}>
            <CountainerForHealth />
          </Col>
        </Row> */}
      </Container>
    </React.Fragment>
  );
}
