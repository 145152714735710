export const carData = [
  {
    name: "What are the types of insurance",
  },
  {
    name: "What is Covered in your insurance",
  },
  {
    name: "What is Covered in your insurance",
  },
  {
    name: "What is Covered in your insurance",
  },
];

export const cities = [
  { type: "Hyderabad", name: "AP-09 Hyderabad" },
  { type: "Hyderabad", name: "AP-10 Hyderabad" },
  { type: "Hyderabad", name: "AP-11 Hyderabad" },
  { type: "Pune", name: "MH-01 Pune" },
  { type: "Pune", name: "MH-02 Pune" },
  { type: "Delhi", name: "D-01 Secunderabad" },
  { type: "Gurgaon", name: "D-02 Secunderabad" },
  { type: "Faridabad", name: "D-02 Secunderabad" },
  { type: "Ahmedabad", name: "D-03 Secunderabad" },
  { type: "Bangalore", name: "D-05 Secunderabad" },
  { type: "Delhi", name: "D-02 Delhi" },
  { type: "Delhi", name: "D-02 Delhi" },
  { type: "Delhi", name: "D-02 Delhi" },
  { type: "Mumbai", name: "D-02 Mumbai" },
  { type: "Mumbai", name: "D-02 Mumbai" },
  { type: "Mumbai", name: "D-02 Mumbai" },
  { type: "Pune", name: "D-02 Secunderabad" },
  { type: "Chennai", name: "D-02 Secunderabad" },
  { type: "Kolkata", name: "D-02 Kolkata" },
  { type: "Kolkata", name: "D-02 Kolkata" },
  { type: "Kolkata", name: "D-02 Kolkata" },
  { type: "Kolkata", name: "D-02 Kolkata" },
  { type: "Kolkata", name: "D-02 Kolkata" },
  { type: "Kolkata", name: "D-02 Kolkata" },
  { type: "Kolkata", name: "D-02 Kolkata" },

];
export const carbikeformikValues = [
  {
    name: "Hyderabad",
    list: [
      "AP-09 Hyderabad",
      "AP-10 Secunderabad",
      "AP-11 Malakpet Hyderabad East",
    ],
  },
  {
    name: "Delhi",
  },
  {
    name: "Gurgaon",
  },
  {
    name: "Faridabad",
  },
  {
    name: "Ahmedabad",
  },
  {
    name: "Bangalore",
  },
  {
    name: "Mumbai",
  },
  {
    name: "Pune",
  },
  {
    name: "Chennai",
  },
  {
    name: "Kolkata",
  },
];

export const paymentConstants = {
  HELPLINE_PHONE: "(022) 68284343",
  HELPLINE_EMAIL: "contact@policies365.com",

  contactForm: {
    serviceTypes: {
      COMPLAINT: "complaint",
      QUERY: "query",
      REQUEST: "request",
    },
    serviceOn: {
      CAR: "complaint",
      TWO_WHEELER: "query",
      LIFE: "request",
      HEALTH: "health",
      OTHER: "other",
    },
  }
}

export const ckycDetails = {
  "success": true,
  "message": "OK",
  "Unique_Id": "1",
  "Endpoint_2_URL": null,
  "PAN_Verified": "true",
  "KYC_Verified": "true",
  "kyc_data": {
    "CKYC": {
      "req_id": "9338b32e0ed447b68b257ccdc6cfb0bb_1_897430430",
      "success": true,
      "error_message": null,
      "ckyc_remarks": "OK",
      "result": {
        "PERSONAL_DETAILS": {
          "CONSTI_TYPE": "01",
          "ACC_TYPE": "01",
          "CKYC_NO": "20092293714327",
          "PREFIX": "Mr",
          "FNAME": "PRATIK",
          "MNAME": "PRASHANTRAO",
          "LNAME": "BHUMKAR",
          "FULLNAME": "Mr PRATIK PRASHANTRAO BHUMKAR",
          "MAIDEN_PREFIX": null,
          "MAIDEN_FNAME": null,
          "MAIDEN_MNAME": null,
          "MAIDEN_LNAME": null,
          "MAIDEN_FULLNAME": null,
          "FATHERSPOUSE_FLAG": "01",
          "FATHER_PREFIX": "Mr",
          "FATHER_FNAME": "PRASHANTRAO",
          "FATHER_MNAME": "KISANRAO",
          "FATHER_LNAME": "BHUMKAR",
          "FATHER_FULLNAME": "Mr PRASHANTRAO KISANRAO BHUMKAR",
          "MOTHER_PREFIX": "Mrs",
          "MOTHER_FNAME": "VANDANA",
          "MOTHER_MNAME": null,
          "MOTHER_LNAME": null,
          "MOTHER_FULLNAME": "Mrs VANDANA",
          "GENDER": "M",
          "DOB": "16-02-1992",
          "PAN": "BCCPB8920R",
          "FORM_SIXTY": null,
          "PERM_LINE1": "pranav plaza,B wing 3rd floor",
          "PERM_LINE2": "f no. 4-a, aurangpura road, ne",
          "PERM_LINE3": "ar bhaji mandi, bha Aurangabad",
          "PERM_CITY": "Aurangabad",
          "PERM_DIST": "Aurangabad",
          "PERM_STATE": "MH",
          "PERM_COUNTRY": "IN",
          "PERM_PIN": "431001",
          "PERM_POA": "01",
          "PERM_CORRES_SAMEFLAG": "N",
          "CORRES_LINE1": "pranav plaza,B wing 3rd floor",
          "CORRES_LINE2": "f no. 4-a, aurangpura road, ne",
          "CORRES_LINE3": "ar bhaji mandi, bha Aurangabad",
          "CORRES_CITY": "Aurangabad",
          "CORRES_DIST": "Aurangabad",
          "CORRES_STATE": "MH",
          "CORRES_COUNTRY": "IN",
          "CORRES_PIN": "431001",
          "CORRES_POA": null,
          "RESI_STD_CODE": null,
          "RESI_TEL_NUM": null,
          "OFF_STD_CODE": null,
          "OFF_TEL_NUM": null,
          "MOB_CODE": "91",
          "MOB_NUM": "8655534942",
          "EMAIL": "pratikbhumkr@gmail.com",
          "REMARKS": null,
          "DEC_DATE": "14-08-2021",
          "DEC_PLACE": "BANGALORE",
          "KYC_DATE": "25-11-2017",
          "DOC_SUB": "01",
          "KYC_NAME": "********",
          "KYC_DESIGNATION": "********",
          "KYC_BRANCH": "********",
          "KYC_EMPCODE": "********",
          "ORG_NAME": "********",
          "ORG_CODE": "********",
          "NUM_IDENTITY": "1",
          "NUM_RELATED": "0",
          "NUM_IMAGES": "4",
          "UPDATED_DATE": "02-12-2022",
          "Error_Message": null,
          "AGE": null,
          "IMAGE_TYPE": null,
          "PHOTO": null
        },
        "IDENTITY_DETAILS": {
          "IDENTITY": [
            {
              "SEQUENCE_NO": "1",
              "IDENT_TYPE": "E",
              "IDENT_NUM": "XXXXXXXX2098",
              "IDVER_STATUS": "02",
              "IDENT_NAME": "Proof of Possession of Aadhaar"
            }
          ]
        },
        "RELATED_PERSON_DETAILS": null
      }
    }
  },
  "verified_at": "2023-10-04T07:18:54.744Z"
}

export const financierNames = [
  { value: "", label: "Please Select" },
  { value: "Hsbc Bank", label: "HSBC Bank" },
  { value: "Hdfc Bank Ltd", label: "HDFC Bank Ltd" },
  { value: "Citibank n.a", label: "Citibank N.A." },
  { value: "Icici Bank Ltd", label: "ICICI Bank Ltd" },
  { value: "Syndicate bank", label: "Syndicate Bank" },
  { value: "Reserve Bank Of India", label: "Reserve Bank Of India" },
  { value: "State Bank Of India", label: "State Bank Of India" },
  { value: "Central Bank Of India", label: "Central Bank Of India" },
  { value: "Uti Bank", label: "Uti Bank" },
  { value: "Canara Bank", label: "Canara Bank" },
  { value: "United Bank Of India", label: "United Bank Of India" },
  { value: "Bank Of India", label: "Bank Of India" },
  { value: "Uco Bank", label: "Uco Bank" },
  { value: "Federal Bank", label: "Federal Bank" },
  { value: "Indusind Bank", label: "Indusind Bank" },
  { value: "Ing Vysya Bank", label: "Ing Vysya Bank" },
  { value: "Allahabad Bank", label: "Allahabad Bank" },
  { value: "American Express Bank", label: "American Express Bank" },
  { value: "Andhra Bank", label: "Andhra Bank" },
  { value: "Anz Grindlays Bank", label: "Anz Grindlays Bank" },
  { value: "Banaskantha Mehasna Gramin Bank", label: "Banaskantha Mehasna Gramin Bank" },
  { value: "Bank Of Baroda", label: "Bank Of Baroda" },
  { value: "Bank Of Maharashtra", label: "Bank Of Maharashtra" },
  { value: "Bank Of Tokyo", label: "Bank Of Tokyo" },
  { value: "Bharat Overseas Bank", label: "Bharat Overseas Bank" },
  { value: "Corporation Bank", label: "Corporation Bank" },
  { value: "Dena Bank", label: "Dena Bank" },
  { value: "Indian Bank", label: "Indian Bank" },
  { value: "Indian Overseas Bank", label: "Indian Overseas Bank" },
  { value: "Pragati Mahila Nag Sah Bank Mydt", label: "Pragati Mahila Nag Sah Bank Mydt" },
  { value: "Oriental Bank Of Commerce", label: "Oriental Bank Of Commerce" },
  { value: "Punjab National Bank", label: "Punjab National Bank" },
  { value: "Standard Chartered Bank", label: "Standard Chartered Bank" },
  { value: "State Bank Of Hydrabad", label: "State Bank Of Hydrabad" },
  { value: "State Bank Of Indore", label: "State Bank Of Indore" },
  { value: "State Bank Of Mysore", label: "State Bank Of Mysore" },
  { value: "State Bank Of Patiala", label: "State Bank Of Patiala" },
  { value: "State Bank Of Travencore", label: "State Bank Of Travencore" },
  { value: "Abhyudaya Co Op Bank Ltd", label: "Abhyudaya Co Op Bank Ltd" },
  { value: "The Zoroastrian Co Operative Bank", label: "The Zoroastrian Co Operative Bank" },
  { value: "Vijaya Bank", label: "Vijaya Bank" },
  { value: "Idbi Bank", label: "Idbi Bank" },
  { value: "Bank Of Bahrain And Kuwait", label: "Bank Of Bahrain And Kuwait" },
  { value: "Deutsche Bank", label: "Deutsche Bank" },
  { value: "Dresdner Bank", label: "Dresdner Bank" },
  { value: "The Safe Cooperative Bank", label: "The Safe Cooperative Bank" },
  { value: "Barclays Bank", label: "Barclays Bank" },
  { value: "Bnp Paribas", label: "Bnp Paribas" },
  { value: "Navodaya Urban Co Op Bank Ltd, Nagpur", label: "Navodaya Urban Co Op Bank Ltd, Nagpur" },
  { value: "Sbi Commercial And International Bank", label: "Sbi Commercial And International Bank" },
  { value: "The Jaoli Sahakari Bank Ltd", label: "The Jaoli Sahakari Bank Ltd" },
  { value: "Other Bank", label: "Other Bank" },
  { value: "The Cosmos Co Op Bank Ltd", label: "The Cosmos Co Op Bank Ltd" },
  { value: "Saraswat Co Op Bank", label: "Saraswat Co Op Bank Ltd" },
  { value: "Apna Sahakari Bank Limited", label: "Apna Sahakari Bank Limited" },
  { value: "Bank Of Punjab", label: "Bank Of Punjab" },
  { value: "The Kangra Co Op Bank Ltd", label: "The Kangra Co Op Bank Ltd" },
  { value: "Tamilnad Mercantile Bank Ltd", label: "Tamilnad Mercantile Bank Ltd" },
  { value: "Karnataka Bank Ltd", label: "Karnataka Bank Ltd" },
  { value: "Delhi State Coop Bank", label: "Delhi State Coop Bank" },
  { value: "Bank Of America", label: "Bank Of America" },
  { value: "Bhagyodaya Urban Co Op Bank Ltd", label: "Bhagyodaya Urban Co Op Bank Ltd" },
  { value: "The Lakshmi Vilas Bank Ltd", label: "The Lakshmi Vilas Bank Ltd" },
  { value: "The Kurukshetra Central Co Op Bank Ltd", label: "The Kurukshetra Central Co Op Bank Ltd" },
  { value: "Lord Krishna", label: "Lord Krishna" },
  { value: "Amanath Co Operative Bank Ltd", label: "Amanath Co Operative Bank Ltd" },
  { value: "South Indian Bank Limited", label: "South Indian Bank Limited" },
  { value: "Catholic Syrian Bank Ltd", label: "Catholic Syrian Bank Ltd" },
  { value: "The National Co Operative Bank Ltd", label: "The National Co Operative Bank Ltd" },
  { value: "Deepak Sahakari Bank Ltd", label: "Deepak Sahakari Bank Ltd" },
  { value: "The Yamunanagar Central Co Op Bank Ltd", label: "The Yamunanagar Central Co Op Bank Ltd" },
  { value: "The Hisar Distt Central Co Operative Bank Ltd", label: "The Hisar Distt Central Co Operative Bank Ltd" },
  { value: "Union Bank Of India", label: "Union Bank Of India" },
  { value: "City Union Bank Ltd", label: "City Union Bank Ltd" },
  { value: "Axis Bank Ltd", label: "Axis Bank Ltd" },
  { value: "Kotak Mahindra Prime Ltd", label: "Kotak Mahindra Prime Ltd" },
  { value: "Bassein Catholic Co Operative Bank Ltd", label: "Bassein Catholic Co Operative Bank Ltd" },
  { value: "Vasai Vikas Sahakari Bank Ltd", label: "Vasai Vikas Sahakari Bank Ltd" },
  { value: "The Greater Bombay Co Operative Bank Ltd", label: "The Greater Bombay Co Operative Bank Ltd" },
  { value: "Abn Loan Care Pl", label: "Abn Loan Care Pl" },
  { value: "Abn Loan Care Stpl", label: "Abn Loan Care Stpl" },
  { value: "The Veerashaiva Co Op Bank Ltd", label: "The Veerashaiva Co Op Bank Ltd" },
  { value: "Janakalyan Sahakari Bank Ltd", label: "Janakalyan Sahakari Bank Ltd" },
  { value: "The Bharat Co Op Bank Ltd", label: "The Bharat Co Op Bank Ltd" },
  { value: "Punjab And Maharashtra Co Op Bank Ltd", label: "Punjab And Maharashtra Co Op Bank Ltd" },
  { value: "Citizen Credit Co Op Bank Ltd", label: "Citizen Credit Co Op Bank Ltd" },
  { value: "The North Kanara G.S.B. Co Op Bank Ltd", label: "The North Kanara G.S.B. Co Op Bank Ltd" },
  { value: "The Abhinav Sahakari Bank Ltd", label: "The Abhinav Sahakari Bank Ltd" },
  { value: "The Thane Janata Sahakari Bank Ltd", label: "The Thane Janata Sahakari Bank Ltd" },
  { value: "Punjab And Sind Bank", label: "Punjab And Sind Bank" },
  { value: "The Vishweshwar Sahakari Bank Ltd.", label: "The Vishweshwar Sahakari Bank Ltd." },
  { value: "New India Co Op Bank Ltd", label: "New India Co Op Bank Ltd" },
  { value: "The Ajara Urban Co Op Bank Ltd", label: "The Ajara Urban Co Op Bank Ltd" },
  { value: "The Pratap Co Operative Bank Ltd", label: "The Pratap Co Operative Bank Ltd" },
  { value: "Abn Amro Healthwise", label: "Abn Amro Healthwise" },
  { value: "The Karad Urban Co Op Bank Ltd", label: "The Karad Urban Co Op Bank Ltd" },
  { value: "Sundarlal Sawji Urban Co Op Bank Ltd", label: "Sundarlal Sawji Urban Co Op Bank Ltd" },
  { value: "Parsik Janta Sahkari Bank", label: "Parsik Janta Sahkari Bank" },
  { value: "Hinduthan Co Op Bank", label: "Hinduthan Co Op Bank" },
  { value: "Mahalakshmi Co Op Bank Ltd", label: "Mahalakshmi Co Op Bank Ltd" },
  { value: "Gurgaon Gramin Bank", label: "Gurgaon Gramin Bank" },
  { value: "The Bhuj Mercantile Co Op Bank Ltd", label: "The Bhuj Mercantile Co Op Bank Ltd" },
  { value: "The Gandhidham Mercantile Co Op Bank Ltd", label: "The Gandhidham Mercantile Co Op Bank Ltd" },
  { value: "The Gandhidham Co Op Bank Ltd", label: "The Gandhidham Co Op Bank Ltd" },
  { value: "The Kachchh District Central Co Op Bank Ltd", label: "The Kachchh District Central Co Op Bank Ltd" },
  { value: "Dena Gujarat Gramin Bank", label: "Dena Gujarat Gramin Bank" },
  { value: "Sharda Gramin Bank", label: "Sharda Gramin Bank" },
  { value: "Sanmitra Urban Co Op Bank Ltd Akola", label: "Sanmitra Urban Co Op Bank Ltd Akola" },
  { value: "The Karnataka State Co Operative Apex Bank Ltd", label: "The Karnataka State Co Operative Apex Bank Ltd" },
  { value: "Tumkur Grain Merchant Co Op Bank Ltd", label: "Tumkur Grain Merchant Co Op Bank Ltd" },
  { value: "The Tumkur District Co Op Bank Ltd", label: "The Tumkur District Co Op Bank Ltd" },
  { value: "The Vaish Co Operative New Bank Ltd", label: "The Vaish Co Operative New Bank Ltd" },
  { value: "Solapur Nagari Audyogik Saha. Bank Niy.", label: "Solapur Nagari Audyogik Saha. Bank Niy." },
  { value: "The Ap State Coop. Bank Ltd.", label: "The Ap State Coop. Bank Ltd." },
  { value: "The Mahanagar Co Op Bank Ltd", label: "The Mahanagar Co Op Bank Ltd" },
  { value: "Nav Jeevan Co Op Bank Ltd", label: "Nav Jeevan Co Op Bank Ltd" },
  { value: "Swaraj Urban Co Op Bank Ltd", label: "Swaraj Urban Co Op Bank Ltd" },
  { value: "Kurla Nagarik Sahakari Bank Ltd", label: "Kurla Nagarik Sahakari Bank Ltd" },
  { value: "Pragati Co Op Bank Ltd", label: "Pragati Co Op Bank Ltd" },
  { value: "Shree Banaskantha Dis Cent Co Op Bank Ltd", label: "Shree Banaskantha Dis Cent Co Op Bank Ltd" },
  { value: "Gozaria Nagarik Sahakari Bank Ltd", label: "Gozaria Nagarik Sahakari Bank Ltd" },
  { value: "Sarvodaya Commercial Co Op Bank Ltd", label: "Sarvodaya Commercial Co Op Bank Ltd" },
  { value: "The Kukarwada Nagarik Sahakari Bank Ltd", label: "The Kukarwada Nagarik Sahakari Bank Ltd" },
  { value: "Malad Sahakari Bank Ltd", label: "Malad Sahakari Bank Ltd" },
  { value: "Maratha Sahakari Bank Ltd", label: "Maratha Sahakari Bank Ltd" },
  { value: "Shri Arihant Co Op Bank Ltd", label: "Shri Arihant Co Op Bank Ltd" },
  { value: "The Nainital Bank Ltd", label: "The Nainital Bank Ltd" },
  { value: "Cg Rajya Sahakari Bank Mydt", label: "Cg Rajya Sahakari Bank Mydt" },
  { value: "State Bank Of Saurashtra", label: "State Bank Of Saurashtra" },
  { value: "Annasaheb Patil Urban Co Op Bank Ltd", label: "Annasaheb Patil Urban Co Op Bank Ltd" },
  { value: "Arjun Urban Co Op Bank Ltd", label: "Arjun Urban Co Op Bank Ltd" },
  { value: "Siddharth Sahakari Bank Ltd", label: "Siddharth Sahakari Bank Ltd" },
  { value: "Janata Sahakari Bank Ltd", label: "Janata Sahakari Bank Ltd" },
  { value: "Rupee Co Op Bank Ltd", label: "Rupee Co Op Bank Ltd" },
  { value: "Pune Peoples Co Op Bank Ltd", label: "Pune Peoples Co Op Bank Ltd" },
  { value: "Pune District Central Co Op Bank Ltd", label: "Pune District Central Co Op Bank Ltd" },
  { value: "Shree Laxmi Co Op Bank Ltd", label: "Shree Laxmi Co Op Bank Ltd" },
  { value: "Baramati Sahakari Bank Ltd", label: "Baramati Sahakari Bank Ltd" },
  { value: "Bhagini Nivedita Sahakari Bank Ltd", label: "Bhagini Nivedita Sahakari Bank Ltd" },
  { value: "Mahesh Sahakari Bank Ltd", label: "Mahesh Sahakari Bank Ltd" },
  { value: "Citizen Co Op Bank Ltd", label: "Citizen Co Op Bank Ltd" },
  { value: "Gramin Vikas Bank", label: "Gramin Vikas Bank" },
  { value: "Ichalkaranji Janata Sahakari Bank Ltd", label: "Ichalkaranji Janata Sahakari Bank Ltd" },
  { value: "Kolhapur Janata Sahakari Bank Ltd", label: "Kolhapur Janata Sahakari Bank Ltd" },
  { value: "Kolhapur District Central Co Op Bank Ltd", label: "Kolhapur District Central Co Op Bank Ltd" },
  { value: "Lokmangal Co Op Bank Ltd", label: "Lokmangal Co Op Bank Ltd" },
  { value: "Mehsana District Central Co Op Bank Ltd", label: "Mehsana District Central Co Op Bank Ltd" },
  { value: "Lokmangal Urban Coop Bank Ltd", label: "Lokmangal Urban Coop Bank Ltd" },
  { value: "Manorama Co Operative Bank Ltd", label: "Manorama Co Operative Bank Ltd" },
  { value: "Mohol Urban Co Op Bank Ltd", label: "Mohol Urban Co Op Bank Ltd" },
  { value: "The Kalyan Janata Sahakari Bank Ltd", label: "The Kalyan Janata Sahakari Bank Ltd" },
  { value: "Mula Sahakari Bank Ltd", label: "Mula Sahakari Bank Ltd" },
  { value: "Maharashtra State Co Op Bank Ltd", label: "Maharashtra State Co Op Bank Ltd" },
  { value: "Nilkanth Urban Co Op Bank Ltd", label: "Nilkanth Urban Co Op Bank Ltd" },
  { value: "Osmanabad Janata Sahakari Bank Ltd", label: "Osmanabad Janata Sahakari Bank Ltd" },
  { value: "Rukmini Sahakari Bank Ltd", label: "Rukmini Sahakari Bank Ltd" },
  { value: "Solapur Janata Sahakari Bank Ltd", label: "Solapur Janata Sahakari Bank Ltd" },
  { value: "Sholapur Siddheshwar Sahakari Bank Ltd", label: "Sholapur Siddheshwar Sahakari Bank Ltd" },
  { value: "Samarth Sahakari Bank Ltd", label: "Samarth Sahakari Bank Ltd" },
  { value: "Una Peoples Co Op Bank", label: "Una Peoples Co Op Bank" },
  { value: "Shree Samarth Sahakari Bank Ltd", label: "Shree Samarth Sahakari Bank Ltd" },
  { value: "Shri Swami Samarth Sahakari Bank Ltd", label: "Shri Swami Samarth Sahakari Bank Ltd" },
  { value: "The Sonepat Urban Co Opbank Ltd", label: "The Sonepat Urban Co Opbank Ltd" },
  { value: "Narmda Malwa Gramin Bank", label: "Narmda Malwa Gramin Bank" },
  { value: "The Panipat Urban Co Op Bank Ltd", label: "The Panipat Urban Co Op Bank Ltd" },
  { value: "Sahebrao Deshmukh Co Op Bank Ltd", label: "Sahebrao Deshmukh Co Op Bank Ltd" },
  { value: "The Dahanu Road Janata Co Op Bank Ltd", label: "The Dahanu Road Janata Co Op Bank Ltd" },
  { value: "The Ankola Urban Co Operative Bank Ltd", label: "The Ankola Urban Co Operative Bank Ltd" },
  { value: "Sopankaka Sahakari Bank Ltd", label: "Sopankaka Sahakari Bank Ltd" },
  { value: "The Kanara District Cetral Co Op Bank Ltd", label: "The Kanara District Cetral Co Op Bank Ltd" },
  { value: "The Visakhapatnam Co Operative Bank Ltd", label: "The Visakhapatnam Co Operative Bank Ltd" },
  { value: "Janaseva Sahakari Bank Ltd", label: "Janaseva Sahakari Bank Ltd" },
  { value: "Rajarambapu Sahakari Bank Ltd", label: "Rajarambapu Sahakari Bank Ltd" },
  { value: "Thodupuzha Urban Co Op Bank", label: "Thodupuzha Urban Co Op Bank" },
  { value: "Parshwanath Co Op Bank Ltd", label: "Parshwanath Co Op Bank Ltd" },
  { value: "The Kolhapur Urban Co Op Bank Ltd", label: "The Kolhapur Urban Co Op Bank Ltd" },
  { value: "Rewa Sidhi Gramin Bank", label: "Rewa Sidhi Gramin Bank" },
  { value: "Shree Panchaganga Nagri Sahakari Bank Ltd", label: "Shree Panchaganga Nagri Sahakari Bank Ltd" },
  { value: "The Pulpally Service Co Op Bank Ltd", label: "The Pulpally Service Co Op Bank Ltd" },
  { value: "Indore Cloth Market Co Op Bank Ltd", label: "Indore Cloth Market Co Op Bank Ltd" },
  { value: "Jila Sahakari Kendriya Bank Maryadit Mandla", label: "Jila Sahakari Kendriya Bank Maryadit Mandla" },
  { value: "Paraspar Sahayak Co Op Bank Ltd", label: "Paraspar Sahayak Co Op Bank Ltd" },
  { value: "Transport Co Op Bank Ltd", label: "Transport Co Op Bank Ltd" },
  { value: "Nalgonda Dist Co Op Central Bank Ltd", label: "Nalgonda Dist Co Op Central Bank Ltd" },
  { value: "Himachal Gramin Bank", label: "Himachal Gramin Bank" },
  { value: "Himachal Pradesh State Co Op Bank Ltd", label: "Himachal Pradesh State Co Op Bank Ltd" },
  { value: "Ellaquai Dehati Bank", label: "Ellaquai Dehati Bank" },
  { value: "Baramulla Co Op Bank", label: "Baramulla Co Op Bank" },
  { value: "Jammu Rural Bank", label: "Jammu Rural Bank" },
  { value: "Jammu Central Co Op Bank", label: "Jammu Central Co Op Bank" },
  { value: "Bharuch District Central Co Op Bank Ltd", label: "Bharuch District Central Co Op Bank Ltd" },
  { value: "Basaveshwar Sahakar Bank Niyamit", label: "Basaveshwar Sahakar Bank Niyamit" },
  { value: "Etawah Urban Co Op Bank Ltd", label: "Etawah Urban Co Op Bank Ltd" },
  { value: "The Pen Co Op Urban Bank Ltd", label: "The Pen Co Op Urban Bank Ltd" },
  { value: "The Hindusthan Co Op Bank Ltd", label: "The Hindusthan Co Op Bank Ltd" },
  { value: "Jambusar Peoples Co Op Bank Ltd", label: "Jambusar Peoples Co Op Bank Ltd" },
  { value: "Bankura District Central Co Op Bank Ltd", label: "Bankura District Central Co Op Bank Ltd" },
  { value: "Durgapur Steel City Peoples Co Op Bank Ltd", label: "Durgapur Steel City Peoples Co Op Bank Ltd" },
  { value: "Bangiya Gramin Vikash Bank", label: "Bangiya Gramin Vikash Bank" },
  { value: "Contai Co Op Bank Ltd", label: "Contai Co Op Bank Ltd" },
  { value: "Burdwan Central Co Op Bank Ltd", label: "Burdwan Central Co Op Bank Ltd" },
  { value: "Durgapur Mahila Co Op Bank Ltd", label: "Durgapur Mahila Co Op Bank Ltd" },
  { value: "Bhiwani Central Co Op Bank Ltd", label: "Bhiwani Central Co Op Bank Ltd" },
  { value: "Tamilnadu Industrical Co Op Bank Ltd", label: "Tamilnadu Industrical Co Op Bank Ltd" },
  { value: "Almora Urban Co Op Bank Ltd", label: "Almora Urban Co Op Bank Ltd" },
  { value: "Aryavart Gramin Bank", label: "Aryavart Gramin Bank" },
  { value: "Baroda Uttar Pradesh Gramin Bank", label: "Baroda Uttar Pradesh Gramin Bank" },
  { value: "Govt Of Karnataka Treasury Cheque", label: "Govt Of Karnataka Treasury Cheque" },
  { value: "Baroda Western Uttar Pradesh Gramin Bank", label: "Baroda Western Uttar Pradesh Gramin Bank" },
  { value: "Kashipur Urban Co Op Bank Ltd", label: "Kashipur Urban Co Op Bank Ltd" },
  { value: "Udham Singh Nagar District Co Op Bank Ltd", label: "Udham Singh Nagar District Co Op Bank Ltd" },
  { value: "Kurmanchal Nagar Sahakari Bank Ltd", label: "Kurmanchal Nagar Sahakari Bank Ltd" },
  { value: "Nainital Almora Kshetriya Gramin Bank", label: "Nainital Almora Kshetriya Gramin Bank" },
  { value: "The New Urban Co Op Bank Ltd", label: "The New Urban Co Op Bank Ltd" },
  { value: "Nainital District Co Op Bank Ltd", label: "Nainital District Co Op Bank Ltd" },
  { value: "Prathama Bank", label: "Prathama Bank" },
  { value: "Rampur Zila Sahakari Bank Ltd", label: "Rampur Zila Sahakari Bank Ltd" },
  { value: "Kurmanchal Nagar Sahkari Bank Ltd", label: "Kurmanchal Nagar Sahkari Bank Ltd" },
  { value: "Progressive Urban Co Op Bank Ltd", label: "Progressive Urban Co Op Bank Ltd" },
  { value: "Shreeram Sahakari Bank Ltd", label: "Shreeram Sahakari Bank Ltd" },
  { value: "Post Office Savings Bank", label: "Post Office Savings Bank" },
  { value: "Union Co Op Bank Ltd", label: "Union Co Op Bank Ltd" },
  { value: "The Mehsana Urban Co Op Bank Ltd", label: "The Mehsana Urban Co Op Bank Ltd" },
  { value: "Kukarwada Nagarik Sahakari Bank Ltd", label: "Kukarwada Nagarik Sahakari Bank Ltd" },
  { value: "Vijay Co Op Bank Ltd", label: "Vijay Co Op Bank Ltd" },
  { value: "Saraspur Nagrik Co Op Bank Ltd", label: "Saraspur Nagrik Co Op Bank Ltd" },
  { value: "Mahila Vikas Co Op Bank Ltd", label: "Mahila Vikas Co Op Bank Ltd" },
  { value: "Naroda Nagarik Co Op Bank Ltd", label: "Naroda Nagarik Co Op Bank Ltd" },
  { value: "Chanasma Nagarik Sahakari Bank Ltd", label: "Chanasma Nagarik Sahakari Bank Ltd" },
  { value: "Bhagyodaya Co Op Bank Ltd", label: "Bhagyodaya Co Op Bank Ltd" },
  { value: "Karnavati Co Op Bank Ltd", label: "Karnavati Co Op Bank Ltd" },
  { value: "Trichur Urban Co Op Bank Ltd", label: "Trichur Urban Co Op Bank Ltd" },
  { value: "Kalol Nagarik Sahakari Bank Ltd", label: "Kalol Nagarik Sahakari Bank Ltd" },
  { value: "The Bavla Nagarik Sahakari Bank Ltd", label: "The Bavla Nagarik Sahakari Bank Ltd" },
  { value: "Navnirman Co Op Bank Ltd", label: "Navnirman Co Op Bank Ltd" },
  { value: "Kalupur Commercial Co Op Bank Ltd", label: "Kalupur Commercial Co Op Bank Ltd" },
  { value: "Gandhinagar Nagarik Co Op Bank Ltd", label: "Gandhinagar Nagarik Co Op Bank Ltd" },
  { value: "Ahmedabad District Co Op Bank Ltd", label: "Ahmedabad District Co Op Bank Ltd" },
  { value: "Ahmedabad Mercantile Co Op Bank Ltd", label: "Ahmedabad Mercantile Co Op Bank Ltd" },
  { value: "Kheralu Nag Sah Bank Ltd", label: "Kheralu Nag Sah Bank Ltd" },
  { value: "Coastal Local Area Bank Ltd", label: "Coastal Local Area Bank Ltd" },
  { value: "Betul Nagrik Sahakari Bank Mydt", label: "Betul Nagrik Sahakari Bank Mydt" },
  { value: "North Malabar Gramin Bank", label: "North Malabar Gramin Bank" },
  { value: "Cherthala Govt Servants Co Op Bank Ltd", label: "Cherthala Govt Servants Co Op Bank Ltd" },
  { value: "Idukki District Co Op Bank Ltd", label: "Idukki District Co Op Bank Ltd" },
  { value: "Kottayam District Co Op Bank Ltd", label: "Kottayam District Co Op Bank Ltd" },
  { value: "Thalayolaparambu Farmers Service Co Op Bank Ltd", label: "Thalayolaparambu Farmers Service Co Op Bank Ltd" },
  { value: "Thalappalam Service Co Op Bank Ltd", label: "Thalappalam Service Co Op Bank Ltd" },
  { value: "Ankleshwar Udyognagar Co Op Bank Ltd", label: "Ankleshwar Udyognagar Co Op Bank Ltd" },
  { value: "Hooghly District Central Co Op Bank Ltd", label: "Hooghly District Central Co Op Bank Ltd" },
  { value: "Maharashtra Godavari Gramin Bank", label: "Maharashtra Godavari Gramin Bank" },
  { value: "Sri Vasavamba Co Op Bank Ltd", label: "Sri Vasavamba Co Op Bank Ltd" },
  { value: "Mehemadabad Urban Peoples Co Op Bank Ltd", label: "Mehemadabad Urban Peoples Co Op Bank Ltd" },
  { value: "Mahakaushal Kshetriya Gramin Bank", label: "Mahakaushal Kshetriya Gramin Bank" },
  { value: "Balaji Bank", label: "Balaji Bank" },
  { value: "Madhya Bharat Gramin Bank", label: "Madhya Bharat Gramin Bank" },
  { value: "Yes Bank Ltd", label: "Yes Bank Ltd" },
  { value: "Adarsh Bank", label: "Adarsh Bank" },
  { value: "Bagalkot District Central Co Op Bank Ltd", label: "Bagalkot District Central Co Op Bank Ltd" },
  { value: "Thane Bharat Sahakari Bank Ltd", label: "Thane Bharat Sahakari Bank Ltd" },
  { value: "Capital Local Area Bank", label: "Capital Local Area Bank" },
  { value: "Bank Of Rajastan Ltd", label: "Bank Of Rajastan Ltd" },
  { value: "Panayal Service Co Opp Bank Ltd", label: "Panayal Service Co Opp Bank Ltd" },
  { value: "Mewar Aanchalik Gramin Bank", label: "Mewar Aanchalik Gramin Bank" },
  { value: "Nedupuzha Service Co Op Bank Ltd", label: "Nedupuzha Service Co Op Bank Ltd" },
  { value: "Deccan Grameena Bank", label: "Deccan Grameena Bank" },
  { value: "Agroha Bank", label: "Agroha Bank" },
  { value: "Sarangpur Co Op Bank Ltd", label: "Sarangpur Co Op Bank Ltd" },
  { value: "Thasra Peoples Co Op Bank Ltd", label: "Thasra Peoples Co Op Bank Ltd" },
  { value: "Nadiad People Co Op Bank Ltd", label: "Nadiad People Co Op Bank Ltd" },
  { value: "Mansa Nagarik Sahakari Bank Ltd", label: "Mansa Nagarik Sahakari Bank Ltd" },
  { value: "Sadhana Sahakari Bank Ltd", label: "Sadhana Sahakari Bank Ltd" },
  { value: "Hassan Dist Co Op Central Bank Ltd", label: "Hassan Dist Co Op Central Bank Ltd" },
  { value: "Lonavala Sahakari Bank Ltd", label: "Lonavala Sahakari Bank Ltd" },
  { value: "Madhya Pradesh Rajya Sahakari Bank Mydt", label: "Madhya Pradesh Rajya Sahakari Bank Mydt" },
  { value: "Karimnagar Co Op Urban Bank Ltd", label: "Karimnagar Co Op Urban Bank Ltd" },
  { value: "Gulshan Mercantile Urban Co Op Bank Ltd", label: "Gulshan Mercantile Urban Co Op Bank Ltd" },
  { value: "The Ganga Mercantile Urban Co Op Bank Ltd", label: "The Ganga Mercantile Urban Co Op Bank Ltd" },
  { value: "Mahesh Urban Co Op Bank Ltd", label: "Mahesh Urban Co Op Bank Ltd" },
  { value: "Shreyas Gramin Bank", label: "Shreyas Gramin Bank" },
  { value: "Jamuna Gramin Bank", label: "Jamuna Gramin Bank" },
  { value: "Municipal Co Op Bank Ltd", label: "Municipal Co Op Bank Ltd" },
  { value: "Sree Anjaneya Co Op Bank Ltd", label: "Sree Anjaneya Co Op Bank Ltd" },
  { value: "Cherukunnu Service Co Op Bank Ltd", label: "Cherukunnu Service Co Op Bank Ltd" },
  { value: "Ezhome Service Co Op Bank Ltd", label: "Ezhome Service Co Op Bank Ltd" },
  { value: "Kadannappalli Panapuzha Service Co Op Bank Ltd", label: "Kadannappalli Panapuzha Service Co Op Bank Ltd" },
  { value: "Kannur District Co Op Bank Ltd", label: "Kannur District Co Op Bank Ltd" },
  { value: "Kasaragod District Co Op Bank Ltd", label: "Kasaragod District Co Op Bank Ltd" },
  { value: "Kasaragod Service Co Op Bank Ltd", label: "Kasaragod Service Co Op Bank Ltd" },
  { value: "Kodakkad Service Co Op Bank Ltd", label: "Kodakkad Service Co Op Bank Ltd" },
  { value: "Adai Co Op Rural Bank Ltd", label: "Adai Co Op Rural Bank Ltd" },
  { value: "Mattanur Co Op Rural Bank Ltd", label: "Mattanur Co Op Rural Bank Ltd" },
  { value: "Mattul Service Co Op Bank Ltd", label: "Mattul Service Co Op Bank Ltd" },
  { value: "Muliyar Service Co Op Bank Ltd", label: "Muliyar Service Co Op Bank Ltd" },
  { value: "Mullakkodi Co Op Rural Bank Ltd", label: "Mullakkodi Co Op Rural Bank Ltd" },
  { value: "Nileshwar Service Co Op Bank Ltd", label: "Nileshwar Service Co Op Bank Ltd" },
  { value: "Nileshwar Co Op Urban Bank Ltd", label: "Nileshwar Co Op Urban Bank Ltd" },
  { value: "Pallikere Service Co Op Bank Ltd", label: "Pallikere Service Co Op Bank Ltd" },
  { value: "Pappinisseri Co Op Rural Bank Ltd", label: "Pappinisseri Co Op Rural Bank Ltd" },
  { value: "Payyavoor Service Co Op Bank Ltd", label: "Payyavoor Service Co Op Bank Ltd" },
  { value: "Valapattanam Service Co Op Bank Ltd", label: "Valapattanam Service Co Op Bank Ltd" },
  { value: "Vellur Service Co Op Bank Ltd", label: "Vellur Service Co Op Bank Ltd" },
  { value: "Nasik Dist Central Co Op Bank Ltd", label: "Nasik Dist Central Co Op Bank Ltd" },
  { value: "Nashik District Girana Sahakari Bank Ltd", label: "Nashik District Girana Sahakari Bank Ltd" },
  { value: "Developement Credit Bank Ltd", label: "Developement Credit Bank Ltd" },
  { value: "Nasik Merchants Co Op Bank Ltd", label: "Nasik Merchants Co Op Bank Ltd" },
  { value: "Navapur Merchantile Co Op Bank Ltd", label: "Navapur Merchantile Co Op Bank Ltd" },
  { value: "Ghoti Merchants Co Op Bank Ltd", label: "Ghoti Merchants Co Op Bank Ltd" },
  { value: "Shree Ganesh Sahakari Bank Ltd", label: "Shree Ganesh Sahakari Bank Ltd" },
  { value: "Hasti Co Op Bank Ltd", label: "Hasti Co Op Bank Ltd" },
  { value: "Shree Mahesh Co Op Bank Ltd", label: "Shree Mahesh Co Op Bank Ltd" },
  { value: "Dhule Vikas Sahakari Bank Ltd", label: "Dhule Vikas Sahakari Bank Ltd" },
  { value: "Deolali Vyapari Sahakari Bank Ltd", label: "Deolali Vyapari Sahakari Bank Ltd" },
  { value: "Vishwas Co Op Bank Ltd", label: "Vishwas Co Op Bank Ltd" },
  { value: "Girana Sahakari Bank Ltd", label: "Girana Sahakari Bank Ltd" },
  { value: "Nashik Jilha Mahila Sahakari Bank Ltd", label: "Nashik Jilha Mahila Sahakari Bank Ltd" },
  { value: "The Akola Urban Co Op Bank Ltd,Akola", label: "The Akola Urban Co Op Bank Ltd,Akola" },
  { value: "Janlaxmi Cooperative Bank Ltd", label: "Janlaxmi Cooperative Bank Ltd" },
  { value: "Pimpalgaon Merchants Co Op Bank Ltd", label: "Pimpalgaon Merchants Co Op Bank Ltd" },
  { value: "Dadasaheb Rawal Co Op Bank Ltd", label: "Dadasaheb Rawal Co Op Bank Ltd" },
  { value: "Pragati Urban Co Op Bank Ltd", label: "Pragati Urban Co Op Bank Ltd" },
  { value: "Dhule And Nandurbar District Central Co Op Bank L", label: "Dhule And Nandurbar District Central Co Op Bank L" },
  { value: "Niphad Urban Co Op Bank Ltd", label: "Niphad Urban Co Op Bank Ltd" },
  { value: "Rajlaxmi Urban Co Op Bank Ltd", label: "Rajlaxmi Urban Co Op Bank Ltd" },
  { value: "Janseva Co Op Bank Ltd", label: "Janseva Co Op Bank Ltd" },
  { value: "Dombivli Nagari Sahakari Bank Ltd", label: "Dombivli Nagari Sahakari Bank Ltd" },
  { value: "Ratnakar Bank Ltd", label: "Ratnakar Bank Ltd" },
  { value: "Nasik Jilha Sahkri Bank Ltd", label: "Nasik Jilha Sahkri Bank Ltd" },
  { value: "Nanded Marchant Co Op Bank Ltd", label: "Nanded Marchant Co Op Bank Ltd" },
  { value: "Latur Urban Co Op Bank Ltd", label: "Latur Urban Co Op Bank Ltd" },
  { value: "Marathwada Gramin Bank", label: "Marathwada Gramin Bank" },
  { value: "Shankar Nagri Sahakari Bank Ltd", label: "Shankar Nagri Sahakari Bank Ltd" },
  { value: "The Akola Janata Commercial Co Op Bank Ltd,Akola", label: "The Akola Janata Commercial Co Op Bank Ltd,Akola" },
  { value: "Godavari Urban Co Op Bank Ltd", label: "Godavari Urban Co Op Bank Ltd" },
  { value: "Sabarkantha District Central Co Op Bank Ltd", label: "Sabarkantha District Central Co Op Bank Ltd" },
  { value: "Himatnagar Nagarik Sahakari Bank Ltd", label: "Himatnagar Nagarik Sahakari Bank Ltd" },
  { value: "The Sarvodaya Nagarik Sahakari Bank Ltd", label: "The Sarvodaya Nagarik Sahakari Bank Ltd" },
  { value: "Malpur Nagrik Sahakari Bank Ltd", label: "Malpur Nagrik Sahakari Bank Ltd" },
  { value: "Vadali Nagrik Sahakari Bank Ltd", label: "Vadali Nagrik Sahakari Bank Ltd" },
  { value: "Busniess Co Op Bank Ltd", label: "Busniess Co Op Bank Ltd" },
  { value: "Jalgaon Janata Sahakari Bank Ltd", label: "Jalgaon Janata Sahakari Bank Ltd" },
  { value: "Rajarshi Shahu Sahakari Bank", label: "Rajarshi Shahu Sahakari Bank" },
  { value: "Pune Merchants Co Op Bank", label: "Pune Merchants Co Op Bank" },
  { value: "Chembur Nagarik Sahakari Bank Ltd", label: "Chembur Nagarik Sahakari Bank Ltd" },
  { value: "Nagarik Sahakari Bank Ltd", label: "Nagarik Sahakari Bank Ltd" },
  { value: "Udaipur Urban Co Operative Bank Ltd", label: "Udaipur Urban Co Operative Bank Ltd" },
  { value: "Commercial Co Op Bank Ltd", label: "Commercial Co Op Bank Ltd" },
  { value: "Peoples Urban Co Op Bank Ltd", label: "Peoples Urban Co Op Bank Ltd" },
  { value: "Ernakulam Dt. Co Op Bank Ltd", label: "Ernakulam Dt. Co Op Bank Ltd" },
  { value: "Karnala Nagari Sahakari Bank Ltd", label: "Karnala Nagari Sahakari Bank Ltd" },
  { value: "Naval Dockyard Co Op Bank Ltd", label: "Naval Dockyard Co Op Bank Ltd" },
  { value: "The Satara Sahakari Bank Ltd", label: "The Satara Sahakari Bank Ltd" },
  { value: "Mogaveera Co Op Bank Ltd", label: "Mogaveera Co Op Bank Ltd" },
  { value: "Banaras Mercantile Co Op Bank Ltd", label: "Banaras Mercantile Co Op Bank Ltd" },
  { value: "Bhadohi Urban Co Op Bank Ltd", label: "Bhadohi Urban Co Op Bank Ltd" },
  { value: "Kashi Gomti Samyut Gramin Bank", label: "Kashi Gomti Samyut Gramin Bank" },
  { value: "Kashi Gramin Bank", label: "Kashi Gramin Bank" },
  { value: "Kasi Gramin Bank", label: "Kasi Gramin Bank" },
  { value: "Purvanchal Gramin Bank", label: "Purvanchal Gramin Bank" },
  { value: "Samyut Kshetriya Gramin Bank", label: "Samyut Kshetriya Gramin Bank" },
  { value: "Jammu And Kashmir Bank Ltd", label: "Jammu And Kashmir Bank Ltd" },
  { value: "Triveni Kshetriya Gramin Bank", label: "Triveni Kshetriya Gramin Bank" },
  { value: "Zila Sahakari Bank Ltd", label: "Zila Sahakari Bank Ltd" },
  { value: "Shri Baria Nagarik Sahakari Bank Ltd", label: "Shri Baria Nagarik Sahakari Bank Ltd" },
  { value: "Janata Co Op Bank Ltd", label: "Janata Co Op Bank Ltd" },
  { value: "Santrampur Urban Co Op Bank Ltd", label: "Santrampur Urban Co Op Bank Ltd" },
  { value: "Vepar Udhyog Vikas Sahakari Bank Ltd", label: "Vepar Udhyog Vikas Sahakari Bank Ltd" },
  { value: "Baroda Gujarat Gramin Bank", label: "Baroda Gujarat Gramin Bank" },
  { value: "Godhra Urban Co Op Bank Ltd", label: "Godhra Urban Co Op Bank Ltd" },
  { value: "Panchmahal Vadodara Gramin Bank", label: "Panchmahal Vadodara Gramin Bank" },
  { value: "Kalol Urban Co Operative Bank Ltd", label: "Kalol Urban Co Operative Bank Ltd" },
  { value: "Sevalia Urban Co Op Bank Ltd", label: "Sevalia Urban Co Op Bank Ltd" },
  { value: "Shree Mahalaxmi Mercantile Co Op Bank Ltd", label: "Shree Mahalaxmi Mercantile Co Op Bank Ltd" },
  { value: "Godhra City Co Op Bank Ltd", label: "Godhra City Co Op Bank Ltd" },
  { value: "M P Rajya Sahakari Bank Mydt", label: "M P Rajya Sahakari Bank Mydt" },
  { value: "Arihant Urban Co Op Bank Ltd", label: "Arihant Urban Co Op Bank Ltd" },
  { value: "Nkgsb Co Op Bank Ltd", label: "Nkgsb Co Op Bank Ltd" },
  { value: "Deccan Merchants Co Op Bank Ltd", label: "Deccan Merchants Co Op Bank Ltd" },
  { value: "Mumbai District Central Co Op Bank Ltd", label: "Mumbai District Central Co Op Bank Ltd" },
  { value: "Sindhanur Urban Souharda Co Op Bank Ltd", label: "Sindhanur Urban Souharda Co Op Bank Ltd" },
  { value: "Shri Bilur Gurubasava Pattin Sahakari Sangha Niyam", label: "Shri Bilur Gurubasava Pattin Sahakari Sangha Niyam" },
  { value: "Karnataka Vikas Grameen Bank", label: "Karnataka Vikas Grameen Bank" },
  { value: "Mangal Co Op Bank Ltd", label: "Mangal Co Op Bank Ltd" },
  { value: "Erode District Central Co Op Bank", label: "Erode District Central Co Op Bank" },
  { value: "Sravasthi Gramin Bank", label: "Sravasthi Gramin Bank" },
  { value: "Prime Co Op Bank Ltd", label: "Prime Co Op Bank Ltd" },
  { value: "Uma Co Op Bank Ltd", label: "Uma Co Op Bank Ltd" },
  { value: "Baroda Central Co Op Bank Ltd", label: "Baroda Central Co Op Bank Ltd" },
  { value: "Baroda City Co Op Bank Ltd", label: "Baroda City Co Op Bank Ltd" },
  { value: "Shree Vardhman Sahakari Bank Ltd", label: "Shree Vardhman Sahakari Bank Ltd" },
  { value: "Makarpura Industrial Estate Co Op Bank Ltd", label: "Makarpura Industrial Estate Co Op Bank Ltd" },
  { value: "Kaduthuruthy Regional Service Co Op Bank", label: "Kaduthuruthy Regional Service Co Op Bank" },
  { value: "Kaduthuruthy Urban Co Op Bank Ltd", label: "Kaduthuruthy Urban Co Op Bank Ltd" },
  { value: "Kothavara Service Co Op Bank Ltd", label: "Kothavara Service Co Op Bank Ltd" },
  { value: "The Pathanamthitta District Co Op Bank Ltd", label: "The Pathanamthitta District Co Op Bank Ltd" },
  { value: "Rajasthan Gramin Bank", label: "Rajasthan Gramin Bank" },
  { value: "Baroda Rajasthan Gramin Bank", label: "Baroda Rajasthan Gramin Bank" },
  { value: "Jaipur Thar Gramin Bank", label: "Jaipur Thar Gramin Bank" },
  { value: "Chitradurga Dist Co Op Central Bank Ltd", label: "Chitradurga Dist Co Op Central Bank Ltd" },
  { value: "Bhavasara Kshtriya Co Op Bank Ltd", label: "Bhavasara Kshtriya Co Op Bank Ltd" },
  { value: "Tamil Nadu State Apex Co Op Bank Ltd", label: "Tamil Nadu State Apex Co Op Bank Ltd" },
  { value: "Peoples Co Operative Bank Ltd", label: "Peoples Co Operative Bank Ltd" },
  { value: "Maharashtra Gramin Bank", label: "Maharashtra Gramin Bank" },
  { value: "Talod Nagarik Sahakari Bank Ltd", label: "Talod Nagarik Sahakari Bank Ltd" },
  { value: "Ottappalam Service Co Op Bank Ltd", label: "Ottappalam Service Co Op Bank Ltd" },
  { value: "The Mattancherry Sarvajanik Co Op Bank Ltd3284", label: "The Mattancherry Sarvajanik Co Op Bank Ltd3284" },
  { value: "Annasaheb Magar Sahakari Bank Maryadit", label: "Annasaheb Magar Sahakari Bank Maryadit" },
  { value: "Rajgurunagar Sahakari Bank", label: "Rajgurunagar Sahakari Bank" },
  { value: "Kodagu District Co Op Central Bank Ltd", label: "Kodagu District Co Op Central Bank Ltd" },
  { value: "C K P Co Op Bank Ltd", label: "C K P Co Op Bank Ltd" },
  { value: "Kokan Mercantile Co Op Bank Ltd", label: "Kokan Mercantile Co Op Bank Ltd" },
  { value: "Kariyad Service Co Op Bank Ltd", label: "Kariyad Service Co Op Bank Ltd" },
  { value: "Pulikkal Service Co Op Bank Ltd", label: "Pulikkal Service Co Op Bank Ltd" },
  { value: "Kottakkal Co Op Urban Bank Ltd", label: "Kottakkal Co Op Urban Bank Ltd" },
  { value: "Puduppadi Service Co Op Bank", label: "Puduppadi Service Co Op Bank" },
  { value: "Kozhikode District Co Op Bank Ltd", label: "Kozhikode District Co Op Bank Ltd" },
  { value: "South Malabar Gramin Bank", label: "South Malabar Gramin Bank" },
  { value: "Amalner Co Op Urban Bank Ltd", label: "Amalner Co Op Urban Bank Ltd" },
  { value: "Deola Merchants Co Op Bank Ltd", label: "Deola Merchants Co Op Bank Ltd" },
  { value: "The Janalaxmi Co Op Bank Ltd", label: "The Janalaxmi Co Op Bank Ltd" },
  { value: "The Khamgaon Urban Co Op Bank Ltd", label: "The Khamgaon Urban Co Op Bank Ltd" },
  { value: "Sushilkumar Nahata Urban Co Op Bank Ltd", label: "Sushilkumar Nahata Urban Co Op Bank Ltd" },
  { value: "Pusad Urban Co Op Bank Ltd", label: "Pusad Urban Co Op Bank Ltd" },
  { value: "Godawari Laxmi Co Op Bank Ltd", label: "Godawari Laxmi Co Op Bank Ltd" },
  { value: "Jalgaon Janta Sahakari Bank Ltd", label: "Jalgaon Janta Sahakari Bank Ltd" },
  { value: "Jalgaon District Central Co Op Bank Ltd", label: "Jalgaon District Central Co Op Bank Ltd" },
  { value: "Chopda Peoples Co Op Bank Ltd", label: "Chopda Peoples Co Op Bank Ltd" },
  { value: "Patan Co Op Bank Ltd", label: "Patan Co Op Bank Ltd" },
  { value: "R S Co Op Bank Ltd", label: "R S Co Op Bank Ltd" },
  { value: "Raniganj Co Op Bank Ltd", label: "Raniganj Co Op Bank Ltd" },
  { value: "Raipur Urban Mercantile Co Op Bank Ltd", label: "Raipur Urban Mercantile Co Op Bank Ltd" },
  { value: "Naval Dockyard Co-Op Bank Ltd", label: "Naval Dockyard Co-Op Bank Ltd" },
  { value: "Andhra Pradesh Grameena Vikas Bank", label: "Andhra Pradesh Grameena Vikas Bank" },
  { value: "Jain Co Op Bank Ltd", label: "Jain Co Op Bank Ltd" },
  { value: "Jhabua Dhar Kshetriya Gramin Bank", label: "Jhabua Dhar Kshetriya Gramin Bank" },
  { value: "Prerana Co Op Bank Ltd", label: "Prerana Co Op Bank Ltd" },
  { value: "Uttarbanga Kshetriya Gramin Bank", label: "Uttarbanga Kshetriya Gramin Bank" },
  { value: "Post Office Saving Bank", label: "Post Office Saving Bank" },
  { value: "Lucknow Kshetriya Gramin Bank", label: "Lucknow Kshetriya Gramin Bank" },
  { value: "City Co Op Bank Ltd", label: "City Co Op Bank Ltd" },
  { value: "Bijapur Sahakari Bank Niyamit", label: "Bijapur Sahakari Bank Niyamit" },
  { value: "Shri Siddheshwar Co Op Bank Ltd", label: "Shri Siddheshwar Co Op Bank Ltd" },
  { value: "Jaipur Central Co Op Bank Ltd", label: "Jaipur Central Co Op Bank Ltd" },
  { value: "The Udaipur Central Co Op Bank Ltd", label: "The Udaipur Central Co Op Bank Ltd" },
  { value: "Rajasthan State Co Op Bank Ltd", label: "Rajasthan State Co Op Bank Ltd" },
  { value: "Indian Mercantile Co Op Bank Ltd", label: "Indian Mercantile Co Op Bank Ltd" },
  { value: "Uttar Pradesh Co Op Bank Ltd", label: "Uttar Pradesh Co Op Bank Ltd" },
  { value: "Avadh Gramin Bank Ltd", label: "Avadh Gramin Bank Ltd" },
  { value: "Banda Urban Co Op Bank Ltd", label: "Banda Urban Co Op Bank Ltd" },
  { value: "Baroda Eastern Uttar Pradesh Gramin Bank", label: "Baroda Eastern Uttar Pradesh Gramin Bank" },
  { value: "Chhatrasal Gramin Bank", label: "Chhatrasal Gramin Bank" },
  { value: "Farrukhabad Gramin Bank", label: "Farrukhabad Gramin Bank" },
  { value: "Fatehpur District Co Op Bank Ltd", label: "Fatehpur District Co Op Bank Ltd" },
  { value: "Kanpur Kshetriya Gramin Bank", label: "Kanpur Kshetriya Gramin Bank" },
  { value: "Kshetriya Kishan Gramin Bank", label: "Kshetriya Kishan Gramin Bank" },
  { value: "United Merchantile Co Op Bank Ltd", label: "United Merchantile Co Op Bank Ltd" },
  { value: "Apani Sahakari Bank Ltd", label: "Apani Sahakari Bank Ltd" },
  { value: "Colour Merchants Co Op Bank Ltd", label: "Colour Merchants Co Op Bank Ltd" },
  { value: "Gujarat Ambuja Co Op Bank Ltd", label: "Gujarat Ambuja Co Op Bank Ltd" },
  { value: "Gujarat Mercantile Co Op Bank Ltd", label: "Gujarat Mercantile Co Op Bank Ltd" },
  { value: "Kankaria Maninagar Nagarik Sahakari Bank Ltd", label: "Kankaria Maninagar Nagarik Sahakari Bank Ltd" },
  { value: "Maninagar Co Operative Bank Ltd", label: "Maninagar Co Operative Bank Ltd" },
  { value: "Nidhi Co Operative Bank Ltd", label: "Nidhi Co Operative Bank Ltd" },
  { value: "Nutan Nagarik Sahakari Bank Ltd", label: "Nutan Nagarik Sahakari Bank Ltd" },
  { value: "Progressive Mercantile Co Op Bank Ltd", label: "Progressive Mercantile Co Op Bank Ltd" },
  { value: "Rajkot Nagarik Sahakari Bank Ltd", label: "Rajkot Nagarik Sahakari Bank Ltd" },
  { value: "Rajkot Peoples Co Op Bank Ltd", label: "Rajkot Peoples Co Op Bank Ltd" },
  { value: "Sardar Vallabhbhai Sahakari Bank Ltd", label: "Sardar Vallabhbhai Sahakari Bank Ltd" },
  { value: "Shree Dharti Co Operative Bank Ltd", label: "Shree Dharti Co Operative Bank Ltd" },
  { value: "Shree Kadi Nagarik Sahakari Bank Tld", label: "Shree Kadi Nagarik Sahakari Bank Tld" },
  { value: "Shreenath Co Operative Bank Ltd", label: "Shreenath Co Operative Bank Ltd" },
  { value: "Shree Rajkot District Co Op Bank Ltd", label: "Shree Rajkot District Co Op Bank Ltd" },
  { value: "Siddhi Co Op Bank Ltd", label: "Siddhi Co Op Bank Ltd" },
  { value: "Sihor Nagarik Sahakari Bank Ltd", label: "Sihor Nagarik Sahakari Bank Ltd" },
  { value: "Surat Nagrik Sahakari Bank Ltd", label: "Surat Nagrik Sahakari Bank Ltd" },
  { value: "Surat National Co Op Bank Ltd", label: "Surat National Co Op Bank Ltd" },
  { value: "Textile Traders Co Op Bank Ltd", label: "Textile Traders Co Op Bank Ltd" },
  { value: "Adinath Co Op Bank Ltd", label: "Adinath Co Op Bank Ltd" },
  { value: "Amarnath Co Op Bank Ltd", label: "Amarnath Co Op Bank Ltd" },
  { value: "Bapungar Mahila Co Op Bank Ltd", label: "Bapungar Mahila Co Op Bank Ltd" },
  { value: "Chhapi Nagarik Sahakari Bank Ltd", label: "Chhapi Nagarik Sahakari Bank Ltd" },
  { value: "The Co Op Bank Of Mehsana Ltd", label: "The Co Op Bank Of Mehsana Ltd" },
  { value: "The Co Op Bank Of Rajkot Ltd", label: "The Co Op Bank Of Rajkot Ltd" },
  { value: "Financial Co Op Bank Ltd", label: "Financial Co Op Bank Ltd" },
  { value: "Gandhinagar Urban Co Op Bank Ltd", label: "Gandhinagar Urban Co Op Bank Ltd" },
  { value: "Idar Nagarik Sahakari Bank Ltd", label: "Idar Nagarik Sahakari Bank Ltd" },
  { value: "Jhalod Urban Co Operative Bank Ltd", label: "Jhalod Urban Co Operative Bank Ltd" },
  { value: "Kadi Nagarik Sahakari Bank Ltd", label: "Kadi Nagarik Sahakari Bank Ltd" },
  { value: "Kapadwanj Peoples Co Op Bank Ltd", label: "Kapadwanj Peoples Co Op Bank Ltd" },
  { value: "Karur Vysya Bank Ltd", label: "Karur Vysya Bank Ltd" },
  { value: "Lunawada Peoples Co Op Bank Ltd", label: "Lunawada Peoples Co Op Bank Ltd" },
  { value: "Mansa Nagarik Sahakari Bank Tld", label: "Mansa Nagarik Sahakari Bank Tld" },
  { value: "Mehsana Nagarik Sahakari Bank Ltd", label: "Mehsana Nagarik Sahakari Bank Ltd" },
  { value: "Modasa Nagrik Sahakari Bank Ltd", label: "Modasa Nagrik Sahakari Bank Ltd" },
  { value: "Navanagar Co Operative Bank Ltd", label: "Navanagar Co Operative Bank Ltd" },
  { value: "Nawanagar Co Op Bank Ltd", label: "Nawanagar Co Op Bank Ltd" },
  { value: "Panchsheel Mercantile Co Op Bank Ltd", label: "Panchsheel Mercantile Co Op Bank Ltd" },
  { value: "Patni Nagarik Sahakari Bank Ltd", label: "Patni Nagarik Sahakari Bank Ltd" },
  { value: "Rander Peoples Co Op Bank Ltd", label: "Rander Peoples Co Op Bank Ltd" },
  { value: "Saraspur Co Op Bank Ltd", label: "Saraspur Co Op Bank Ltd" },
  { value: "Sarvodaya Sahakari Bank Ltd", label: "Sarvodaya Sahakari Bank Ltd" },
  { value: "Saurashtra Co Operative Bank Ltd", label: "Saurashtra Co Operative Bank Ltd" },
  { value: "Social Co Op Bank Ltd", label: "Social Co Op Bank Ltd" },
  { value: "Surat District Co Op Bank Ltd", label: "Surat District Co Op Bank Ltd" },
  { value: "Surat Mercantile Co Op Bank Ltd", label: "Surat Mercantile Co Op Bank Ltd" },
  { value: "The Surat Peoples Co Op Bank Ltd", label: "The Surat Peoples Co Op Bank Ltd" },
  { value: "Sutex Co Operative Bank Ltd", label: "Sutex Co Operative Bank Ltd" },
  { value: "Suvikas Peoples Co Op Bank Ltd", label: "Suvikas Peoples Co Op Bank Ltd" },
  { value: "Udhna Citizen Co Op Bank Ltd", label: "Udhna Citizen Co Op Bank Ltd" },
  { value: "United Co Operative Bank Ltd", label: "United Co Operative Bank Ltd" },
  { value: "Varachha Co Op Bank Ltd", label: "Varachha Co Op Bank Ltd" },
  { value: "Sadguru Gahininath Urban Co Op Bank Ltd", label: "Sadguru Gahininath Urban Co Op Bank Ltd" },
  { value: "Durg Rajnandgaon Gramin Bank Ltd", label: "Durg Rajnandgaon Gramin Bank Ltd" },
  { value: "Amritsar Central Co Op Bank Ltd", label: "Amritsar Central Co Op Bank Ltd" },
  { value: "Gurdaspur Amritsar Kshetriya Gramin Vikas Bank", label: "Gurdaspur Amritsar Kshetriya Gramin Vikas Bank" },
  { value: "Gurdaspur Central Co Op Bank Ltd", label: "Gurdaspur Central Co Op Bank Ltd" },
  { value: "Vaish Co Op Adarsh Bank Ltd", label: "Vaish Co Op Adarsh Bank Ltd" },
  { value: "Mattancherry Sarvajanik Co Op Bank Ltd", label: "Mattancherry Sarvajanik Co Op Bank Ltd" },
  { value: "Malviya Urban Co Op Bank Ltd", label: "Malviya Urban Co Op Bank Ltd" },
  { value: "Sri Siddaparvatha Pattina Souharda Sahakari N(Spss", label: "Sri Siddaparvatha Pattina Souharda Sahakari N(Spss" },
  { value: "Jansewa Urban Co Op Bank Ltd", label: "Jansewa Urban Co Op Bank Ltd" },
  { value: "Indraprastha Sehkari Bank Ltd", label: "Indraprastha Sehkari Bank Ltd" },
  { value: "Mahad Co Op Urban Bank Ltd", label: "Mahad Co Op Urban Bank Ltd" },
  { value: "North Kanara Gsb Co Op Bank", label: "North Kanara Gsb Co Op Bank" },
  { value: "Sangli Sahakari Bank Ltd", label: "Sangli Sahakari Bank Ltd" },
  { value: "Ambala Central Co Op Bank Ltd", label: "Ambala Central Co Op Bank Ltd" },
  { value: "Ropar Central Co Op Bank Ltd", label: "Ropar Central Co Op Bank Ltd" },
  { value: "Panchkula Urban Central Co Op Bank Ltd", label: "Panchkula Urban Central Co Op Bank Ltd" },
  { value: "Punjab Gramin Bank", label: "Punjab Gramin Bank" },
  { value: "Muslim Co Op Bank Ltd", label: "Muslim Co Op Bank Ltd" },
  { value: "Gondia District Central Co Op Bank Ltd", label: "Gondia District Central Co Op Bank Ltd" },
  { value: "Shishak Sahakari Bank Ltd", label: "Shishak Sahakari Bank Ltd" },
  { value: "Tirupati Urban Co Op Bank Ltd", label: "Tirupati Urban Co Op Bank Ltd" },
  { value: "Shree Warana Co Op Bank", label: "Shree Warana Co Op Bank" },
  { value: "Nandani Sahakari Bank Ltd", label: "Nandani Sahakari Bank Ltd" },
  { value: "Jivaji Sahakari Bank Ltd", label: "Jivaji Sahakari Bank Ltd" },
  { value: "Vyankateshwara Sahakari Bank Ltd", label: "Vyankateshwara Sahakari Bank Ltd" },
  { value: "Nagar Urban Coop Bank Ltd", label: "Nagar Urban Coop Bank Ltd" },
  { value: "Sardar Bhiladwalla Pardi Peoples Co Op Bank Ltd", label: "Sardar Bhiladwalla Pardi Peoples Co Op Bank Ltd" },
  { value: "Goa State Co Op Bank Ltd", label: "Goa State Co Op Bank Ltd" },
  { value: "Assam Gramin Vikash Bank", label: "Assam Gramin Vikash Bank" },
  { value: "Keonjhar Central Co Op Bank Ltd", label: "Keonjhar Central Co Op Bank Ltd" },
  { value: "Orissa State Co Op Bank Ltd", label: "Orissa State Co Op Bank Ltd" },
  { value: "Neelachal Gramya Bank", label: "Neelachal Gramya Bank" },
  { value: "Chikhli Urban Co Op Bank Ltd", label: "Chikhli Urban Co Op Bank Ltd" },
  { value: "The Malkapur Urban Co Op Bank Ltd", label: "The Malkapur Urban Co Op Bank Ltd" },
  { value: "Ambajogai Peoples Co Op Bank Ltd", label: "Ambajogai Peoples Co Op Bank Ltd" },
  { value: "Aurangabad District Central Co Op Bank Ltd", label: "Aurangabad District Central Co Op Bank Ltd" },
  { value: "Vaidyanath Urban Co Op Bank Ltd", label: "Vaidyanath Urban Co Op Bank Ltd" },
  { value: "Deogiri Nagari Sahakari Bank Ltd", label: "Deogiri Nagari Sahakari Bank Ltd" },
  { value: "Priydarshani Mahila Nagari Sahakari Bank Ltd", label: "Priydarshani Mahila Nagari Sahakari Bank Ltd" },
  { value: "Ajantha Urban Co Op Bank Ltd", label: "Ajantha Urban Co Op Bank Ltd" },
  { value: "Dwarkadas Mantri Nagari Sahakari Bank Ltd", label: "Dwarkadas Mantri Nagari Sahakari Bank Ltd" },
  { value: "Ganraj Nagari Sahkari Bank Ltd", label: "Ganraj Nagari Sahkari Bank Ltd" },
  { value: "Lokvikas Nagari Sahakari Bank Ltd", label: "Lokvikas Nagari Sahakari Bank Ltd" },
  { value: "Kisan Nagari Sahakari Bank Ltd", label: "Kisan Nagari Sahakari Bank Ltd" },
  { value: "Aurangabad Jalna Gramin Bank", label: "Aurangabad Jalna Gramin Bank" },
  { value: "Ayarkunnam Service Co Op Bank Ltd", label: "Ayarkunnam Service Co Op Bank Ltd" },
  { value: "Surat Mercantile Bank Ltd", label: "Surat Mercantile Bank Ltd" },
  { value: "Akhand Anand Co Op Bank Ltd", label: "Akhand Anand Co Op Bank Ltd" },
  { value: "Suvodaya Sahakari Bank Ltd", label: "Suvodaya Sahakari Bank Ltd" },
  { value: "Textile Co Op Bank Of Surat Ltd", label: "Textile Co Op Bank Of Surat Ltd" },
  { value: "Adarsh Co Op Bank Ltd", label: "Adarsh Co Op Bank Ltd" },
  { value: "Brahmadeodada Mane Sahakari Bank Ltd", label: "Brahmadeodada Mane Sahakari Bank Ltd" },
  { value: "Dr Annasaheb Chougule Urban Co Op Bank Ltd", label: "Dr Annasaheb Chougule Urban Co Op Bank Ltd" },
  { value: "Shri Swami Samarth Urban Co Op Bank Ltd", label: "Shri Swami Samarth Urban Co Op Bank Ltd" },
  { value: "Shri Mahavir Urban Co Op Bank Ltd", label: "Shri Mahavir Urban Co Op Bank Ltd" },
  { value: "Sangola Urban Co Op Bank Ltd", label: "Sangola Urban Co Op Bank Ltd" },
  { value: "Shankarrao Mohite Patil Sah Bank Ltd", label: "Shankarrao Mohite Patil Sah Bank Ltd" },
  { value: "Sharad Nagari Sahakari Bank Ltd", label: "Sharad Nagari Sahakari Bank Ltd" },
  { value: "Shivshakti Urban Co Op Bank Ltd", label: "Shivshakti Urban Co Op Bank Ltd" },
  { value: "Shri Revansiddehswar Sahakari Bank", label: "Shri Revansiddehswar Sahakari Bank" },
  { value: "Shri Rukmini Nagari Sahkari Bank Ltd", label: "Shri Rukmini Nagari Sahkari Bank Ltd" },
  { value: "Siddheshwar Sahakari Bank Ltd", label: "Siddheshwar Sahakari Bank Ltd" },
  { value: "Siddheshwar Urban Co Op Bank Ltd", label: "Siddheshwar Urban Co Op Bank Ltd" },
  { value: "Laxmi Co Op Bank Ltd", label: "Laxmi Co Op Bank Ltd" },
  { value: "Karmala Co Op Bank Ltd", label: "Karmala Co Op Bank Ltd" },
  { value: "Karmala Urban Co Op Bank Ltd", label: "Karmala Urban Co Op Bank Ltd" },
  { value: "Mangalwedha Urban Co Op Bank Ltd", label: "Mangalwedha Urban Co Op Bank Ltd" },
  { value: "Udgir Urban Co Op Bank Ltd", label: "Udgir Urban Co Op Bank Ltd" },
  { value: "Sholapur Distcent Co Op Bank Ltd", label: "Sholapur Distcent Co Op Bank Ltd" },
  { value: "Sholapur Social Urban Co Op Bank Ltd", label: "Sholapur Social Urban Co Op Bank Ltd" },
  { value: "Ahmednagar Dist Cent Co Op Bank Ltd", label: "Ahmednagar Dist Cent Co Op Bank Ltd" },
  { value: "Ahmednagar Merchants Co Op Bank Ltd", label: "Ahmednagar Merchants Co Op Bank Ltd" },
  { value: "Ahmednagar Sahakari Bank Ltd", label: "Ahmednagar Sahakari Bank Ltd" },
  { value: "Pandharpur Merchants Co Op Bank Ltd", label: "Pandharpur Merchants Co Op Bank Ltd" },
  { value: "Pandharpur Urban Co Op Bank Ltd", label: "Pandharpur Urban Co Op Bank Ltd" },
  { value: "Vikas Sahakari Bank Ltd", label: "Vikas Sahakari Bank Ltd" },
  { value: "Vyapari Sahakari Bank Maryadits", label: "Vyapari Sahakari Bank Maryadits" },
  { value: "Vidyanand Co Op Bank Ltd", label: "Vidyanand Co Op Bank Ltd" },
  { value: "Vasantdada Nagari Sahakari Bank Ltd", label: "Vasantdada Nagari Sahakari Bank Ltd" },
  { value: "Sundarlal Sawaji Urban Co Op Bank Ltd", label: "Sundarlal Sawaji Urban Co Op Bank Ltd" },
  { value: "Laxmi Mahila Nagrik Sahakari Bank", label: "Laxmi Mahila Nagrik Sahakari Bank" },
  { value: "Renuka Nagrik Sahakari Bank Ltd", label: "Renuka Nagrik Sahakari Bank Ltd" },
  { value: "Jila Sahakari Kendriya Bank Maryadit", label: "Jila Sahakari Kendriya Bank Maryadit" },
  { value: "Chhattisgarh Gramin Bank", label: "Chhattisgarh Gramin Bank" },
  { value: "Madhav Nagrik Sahakari Bank Ltd", label: "Madhav Nagrik Sahakari Bank Ltd" },
  { value: "A.P. Mahesh Co Op Urban Bank Limited", label: "A.P. Mahesh Co Op Urban Bank Limited" },
  { value: "Adarsh Co Op Urban Bank Ltd", label: "Adarsh Co Op Urban Bank Ltd" },
  { value: "Khattri Co Op Urban Bank Limited", label: "Khattri Co Op Urban Bank Limited" },
  { value: "Noble Co Op Bank Ltd", label: "Noble Co Op Bank Ltd" },
  { value: "Amravati District Central Co Op Bank Ltd", label: "Amravati District Central Co Op Bank Ltd" },
  { value: "Amravati Merchants Co Op Bank Ltd", label: "Amravati Merchants Co Op Bank Ltd" },
  { value: "The Washim Urban Co Op Bank Ltd,Washim", label: "The Washim Urban Co Op Bank Ltd,Washim" },
  { value: "Kalyan Janata Sahakari Bank Ltd", label: "Kalyan Janata Sahakari Bank Ltd" },
  { value: "Mahaveer Co Op Urban Bank Ltd", label: "Mahaveer Co Op Urban Bank Ltd" },
  { value: "Ap Grameena Vikas Bank", label: "Ap Grameena Vikas Bank" },
  { value: "The Mahad Co Op Bank Ltd", label: "The Mahad Co Op Bank Ltd" },
  { value: "Sachin Industrial Co Op Bank Ltd", label: "Sachin Industrial Co Op Bank Ltd" },
  { value: "Shree Deesa Nagarik Sahakari Bank Ltd", label: "Shree Deesa Nagarik Sahakari Bank Ltd" },
  { value: "Associate Co Op Bank Ltd", label: "Associate Co Op Bank Ltd" },
  { value: "Dattatray Maharaj Kalambe Jaoli Sahakari Bank Ltd", label: "Dattatray Maharaj Kalambe Jaoli Sahakari Bank Ltd" },
  { value: "Kanara District Central Co Op Bank Ltd", label: "Kanara District Central Co Op Bank Ltd" },
  { value: "The Agrasen Co Op Urban Bank Ltd", label: "The Agrasen Co Op Urban Bank Ltd" },
  { value: "Hyderabad Dist Co Op Central Bank Ltd", label: "Hyderabad Dist Co Op Central Bank Ltd" },
  { value: "The Shamrao Vithal Co Op Bank Ltd", label: "The Shamrao Vithal Co Op Bank Ltd" },
  { value: "The Ahmednagar Sahakari Bank Ltd", label: "The Ahmednagar Sahakari Bank Ltd" },
  { value: "Samata Sahakari Bank Ltd", label: "Samata Sahakari Bank Ltd" },
  { value: "Vasai Janata Sahakari Bank Ltd", label: "Vasai Janata Sahakari Bank Ltd" },
  { value: "Pandian Grama Bank", label: "Pandian Grama Bank" },
  { value: "Maha Edha Urban Co Op Bank Ltd", label: "Maha Edha Urban Co Op Bank Ltd" },
  { value: "Sonepat Central Co Op Bank Ltd", label: "Sonepat Central Co Op Bank Ltd" },
  { value: "Panipat Central Co Op Bank Ltd", label: "Panipat Central Co Op Bank Ltd" },
  { value: "Sirsi Urban Sahakari Bank Ltd", label: "Sirsi Urban Sahakari Bank Ltd" },
  { value: "Belgaum Pioneer Urban Co Op Credit Bank Ltd", label: "Belgaum Pioneer Urban Co Op Credit Bank Ltd" },
  { value: "Abhinandan Urban Co Op Bank Ltd", label: "Abhinandan Urban Co Op Bank Ltd" },
  { value: "Shramik Sahakari Bank Ltd", label: "Shramik Sahakari Bank Ltd" },
  { value: "Shatabdi Mahila Sahakari Bank Ltd", label: "Shatabdi Mahila Sahakari Bank Ltd" },
  { value: "Puri Gramya Bank", label: "Puri Gramya Bank" },
  { value: "Fazilka Central Co Op Bank Ltd", label: "Fazilka Central Co Op Bank Ltd" },
  { value: "Mahamedha Urban Co Op Bank Ltd Co Op Bank Ltd", label: "Mahamedha Urban Co Op Bank Ltd Co Op Bank Ltd" },
  { value: "Ap Raja Rajeswari Mahila Co Op Urban Bank Ltd", label: "Ap Raja Rajeswari Mahila Co Op Urban Bank Ltd" },
  { value: "Kakatiya Bank", label: "Kakatiya Bank" },
  { value: "Gonadal Nagrik Sahakari Bank Ltd", label: "Gonadal Nagrik Sahakari Bank Ltd" },
  { value: "Jivan Comm Co Op Bank Ltd", label: "Jivan Comm Co Op Bank Ltd" },
  { value: "Rajkot Commercial Co Op Bank Ltd", label: "Rajkot Commercial Co Op Bank Ltd" },
  { value: "Vijay Commercial Co Op Bank Ltd", label: "Vijay Commercial Co Op Bank Ltd" },
  { value: "Nizamabad Dist Co Op Central Bank Ltd", label: "Nizamabad Dist Co Op Central Bank Ltd" },
  { value: "Nagrota Bagwan Co Op Non Agri Th And Cr Society Lt", label: "Nagrota Bagwan Co Op Non Agri Th And Cr Society Lt" },
  { value: "Jagaon Peoples Co Op Bank Ltd", label: "Jagaon Peoples Co Op Bank Ltd" },
  { value: "Nasikroad Deolali Vyapari Sahkari Bank Ltd", label: "Nasikroad Deolali Vyapari Sahkari Bank Ltd" },
  { value: "Jankalyan Co Op Bank Ltd", label: "Jankalyan Co Op Bank Ltd" },
  { value: "The Sind Co Op Urban Bank Ltd", label: "The Sind Co Op Urban Bank Ltd" },
  { value: "The Yavatmal Urban Co Op Bank Ltd", label: "The Yavatmal Urban Co Op Bank Ltd" },
  { value: "Industrial Co Op Bank Ltd", label: "Industrial Co Op Bank Ltd" },
  { value: "Assam Co Op Apex Bank Ltd", label: "Assam Co Op Apex Bank Ltd" },
  { value: "Latur District Central Co Op Bank Ltd", label: "Latur District Central Co Op Bank Ltd" },
  { value: "The Usilampatti Co Op Urban Bank Ltd", label: "The Usilampatti Co Op Urban Bank Ltd" },
  { value: "The Ajmer Urban Co Op Bank Ltd", label: "The Ajmer Urban Co Op Bank Ltd" },
  { value: "Barmer Central Co Op Bank Ltd", label: "Barmer Central Co Op Bank Ltd" },
  { value: "Wainganga Kshteriya Gramin Bank", label: "Wainganga Kshteriya Gramin Bank" },
  { value: "Yavatmal Dist Central Co Op Bank Ltd", label: "Yavatmal Dist Central Co Op Bank Ltd" },
  { value: "Textile Co Op Bank Ltd", label: "Textile Co Op Bank Ltd" },
  { value: "Noida Commercial Co Op Bank Ltd", label: "Noida Commercial Co Op Bank Ltd" },
  { value: "Shree Gajanan Lokseva Sahakari Bank Maryaditshree", label: "Shree Gajanan Lokseva Sahakari Bank Maryaditshree" },
  { value: "Model Co Op Bank Ltd", label: "Model Co Op Bank Ltd" },
  { value: "Bhavani Sahakari Bank Ltd", label: "Bhavani Sahakari Bank Ltd" },
  { value: "Shivajirao Bhosale Sahakari Bank Ltd", label: "Shivajirao Bhosale Sahakari Bank Ltd" },
  { value: "The Abdul Rahiman Nagar Service Co Op Bank Ltd", label: "The Abdul Rahiman Nagar Service Co Op Bank Ltd" },
  { value: "Sultansbattery Service Co Op Bank Ltd", label: "Sultansbattery Service Co Op Bank Ltd" },
  { value: "Koduvally Service Co Op Bank Ltd", label: "Koduvally Service Co Op Bank Ltd" },
  { value: "Oorakam Service Co Op Bank", label: "Oorakam Service Co Op Bank" },
  { value: "Manjeri Co Op Urban Bank Ltd", label: "Manjeri Co Op Urban Bank Ltd" },
  { value: "Kancheepuram Central Co Op Bank Ltd", label: "Kancheepuram Central Co Op Bank Ltd" },
  { value: "Mangalore Catholic Co Op Bank Ltd", label: "Mangalore Catholic Co Op Bank Ltd" },
  { value: "South Canara Dist Central Co Op Bank Ltd", label: "South Canara Dist Central Co Op Bank Ltd" },
  { value: "Sri Bhagavathi Co Op Bank Ltd", label: "Sri Bhagavathi Co Op Bank Ltd" },
  { value: "Netravati Grameena Bank", label: "Netravati Grameena Bank" },
  { value: "Chikmagalur District Co Op Bank Ltd", label: "Chikmagalur District Co Op Bank Ltd" },
  { value: "Jamia Co Op Bank Ltd", label: "Jamia Co Op Bank Ltd" },
  { value: "Delhi Nagrik Sehkari Bank Ltd", label: "Delhi Nagrik Sehkari Bank Ltd" },
  { value: "Vysya Co Op Bank Ltd", label: "Vysya Co Op Bank Ltd" },
  { value: "Nehru Nagar Co Op Bank Ltd", label: "Nehru Nagar Co Op Bank Ltd" },
  { value: "Dhrangadhra Peoples Co Op Bank Ltd", label: "Dhrangadhra Peoples Co Op Bank Ltd" },
  { value: "Chandrapur Dist Central Co Op Bank Ltd", label: "Chandrapur Dist Central Co Op Bank Ltd" },
  { value: "Satpura Narmada Kshetriya Gramin Bank", label: "Satpura Narmada Kshetriya Gramin Bank" },
  { value: "Suvarnayug Sahakari Bank Ltd", label: "Suvarnayug Sahakari Bank Ltd" },
  { value: "The Eenadu Co Op Urban Bank Ltd", label: "The Eenadu Co Op Urban Bank Ltd" },
  { value: "Raigad District Central Co Op Bank Ltd", label: "Raigad District Central Co Op Bank Ltd" },
  { value: "Lokseva Sahakari Bank Ltd", label: "Lokseva Sahakari Bank Ltd" },
  { value: "Shimoga Arecanut Mandy Merchants Co Op Bank Ltd", label: "Shimoga Arecanut Mandy Merchants Co Op Bank Ltd" },
  { value: "Imperial Urban Co Op Bank Ltd", label: "Imperial Urban Co Op Bank Ltd" },
  { value: "Bijapur District Central Co Op Bank Ltd", label: "Bijapur District Central Co Op Bank Ltd" },
  { value: "Raddi Sahakara Bank Niyamitha", label: "Raddi Sahakara Bank Niyamitha" },
  { value: "Dist Co Op Central Bank Ltd", label: "Dist Co Op Central Bank Ltd" },
  { value: "Khambhat Nagrik Sahakari Bank Ltd", label: "Khambhat Nagrik Sahakari Bank Ltd" },
  { value: "Swami Samarth Sahakari Bank Ltd", label: "Swami Samarth Sahakari Bank Ltd" },
  { value: "Vaikom Urban Co Operative Bank Ltd", label: "Vaikom Urban Co Operative Bank Ltd" },
  { value: "Kottayam Co Operative Urban Bank Limited", label: "Kottayam Co Operative Urban Bank Limited" },
  { value: "Sultanpur Kshetriya Gramin Bank", label: "Sultanpur Kshetriya Gramin Bank" },
  { value: "M S Co Op Bank Ltd", label: "M S Co Op Bank Ltd" },
  { value: "Sankheda Nagarik Sahakari Bank Ltd", label: "Sankheda Nagarik Sahakari Bank Ltd" },
  { value: "Thrissur District Co Op Bank Ltd", label: "Thrissur District Co Op Bank Ltd" },
  { value: "Shirpur Merchants Co Op Bank Ltd", label: "Shirpur Merchants Co Op Bank Ltd" },
  { value: "Etawah Kshetriya Gramin Bank", label: "Etawah Kshetriya Gramin Bank" },
  { value: "Kranthi Co Op Urban Bank Ltd", label: "Kranthi Co Op Urban Bank Ltd" },
  { value: "Sankari Co Op Urban Bank Ltd", label: "Sankari Co Op Urban Bank Ltd" },
  { value: "Annasaheb Savant Co Op Urban Bank Mahad Ltd", label: "Annasaheb Savant Co Op Urban Bank Mahad Ltd" },
  { value: "Ujjain Audhyogik Vikas Nag Sah Bank Mydt", label: "Ujjain Audhyogik Vikas Nag Sah Bank Mydt" },
  { value: "Pragathi Gramina Bank", label: "Pragathi Gramina Bank" },
  { value: "Muttathody Service Co Opbank Ltd", label: "Muttathody Service Co Opbank Ltd" },
  { value: "Kappad Service Co Op Bank Ltd", label: "Kappad Service Co Op Bank Ltd" },
  { value: "Vidharbha Kshetriya Gramin Bank", label: "Vidharbha Kshetriya Gramin Bank" },
  { value: "Dr Panjabrao Deshmukh Urban Co Op Bank Ltd", label: "Dr Panjabrao Deshmukh Urban Co Op Bank Ltd" },
  { value: "Bhandari Co Op Bank Ltd", label: "Bhandari Co Op Bank Ltd" },
  { value: "Excellent Co Op Bank Ltd", label: "Excellent Co Op Bank Ltd" },
  { value: "Rajdhani Nagar Sahkari Bank Ltd", label: "Rajdhani Nagar Sahkari Bank Ltd" },
  { value: "Bhagya Laxmi Mahila Sahakari Bank Ltd", label: "Bhagya Laxmi Mahila Sahakari Bank Ltd" },
  { value: "Sarvodaya Co Op Bank Ltd", label: "Sarvodaya Co Op Bank Ltd" },
  { value: "Hindu Co Op Bank Ltd", label: "Hindu Co Op Bank Ltd" },
  { value: "Moradabad Zila Sahkari Bank Ltd", label: "Moradabad Zila Sahkari Bank Ltd" },
  { value: "Shri Vinayak Sahakari Bank Ltd", label: "Shri Vinayak Sahakari Bank Ltd" },
  { value: "Hongkong And Shanghai Banking Co Op Ltd", label: "Hongkong And Shanghai Banking Co Op Ltd" },
  { value: "Shri Mahila Sewa Sahakari Bank Ltd", label: "Shri Mahila Sewa Sahakari Bank Ltd" },
  { value: "Industrial Development Bank Of India Ltd", label: "Industrial Development Bank Of India Ltd" },
  { value: "Sterling Urban Co Op Bank Ltd", label: "Sterling Urban Co Op Bank Ltd" },
  { value: "Kalinga Gramya Bank", label: "Kalinga Gramya Bank" },
  { value: "The Thenhipalam Co Op Rural Bank Ltd", label: "The Thenhipalam Co Op Rural Bank Ltd" },
  { value: "Feroke Co Op Urban Bank Ltd", label: "Feroke Co Op Urban Bank Ltd" },
  { value: "Cheruvaranam Service Co Op Bank Ltd", label: "Cheruvaranam Service Co Op Bank Ltd" },
  { value: "Thirunalloor Service Co Op Bank Ltd", label: "Thirunalloor Service Co Op Bank Ltd" },
  { value: "Anand Mercantile Co Op Bank Ltd", label: "Anand Mercantile Co Op Bank Ltd" },
  { value: "Millath Co Op Bank Ltd", label: "Millath Co Op Bank Ltd" },
  { value: "Shree Bhavnagar Nagarik Sahakari Bank Ltd", label: "Shree Bhavnagar Nagarik Sahakari Bank Ltd" },
  { value: "Bhavnagar District Co Op Bank Ltd", label: "Bhavnagar District Co Op Bank Ltd" },
  { value: "Sihor Mercantile Co Op Bank Ltd", label: "Sihor Mercantile Co Op Bank Ltd" },
  { value: "Mahuva Nagarik Sahakari Bank Ltd", label: "Mahuva Nagarik Sahakari Bank Ltd" },
  { value: "Amreli Nagarik Sahakari Bank Ltd", label: "Amreli Nagarik Sahakari Bank Ltd" },
  { value: "Saurashtra Gramin Bank", label: "Saurashtra Gramin Bank" },
  { value: "Botad Mercantile Co Op Bank Ltd", label: "Botad Mercantile Co Op Bank Ltd" },
  { value: "Kangra Central Co Op Bank Ltd", label: "Kangra Central Co Op Bank Ltd" },
  { value: "Ajinkyatara Sahakari Bank Ltd", label: "Ajinkyatara Sahakari Bank Ltd" },
  { value: "Haryana State Co Op Apex Bank Ltd", label: "Haryana State Co Op Apex Bank Ltd" },
  { value: "Haryana Gramin Bank", label: "Haryana Gramin Bank" },
  { value: "Chandigarh State Co Op Bank Ltd", label: "Chandigarh State Co Op Bank Ltd" },
  { value: "Vima Kamgar Co Op Bank Ltd", label: "Vima Kamgar Co Op Bank Ltd" },
  { value: "Bhandara Gramin Bank", label: "Bhandara Gramin Bank" },
  { value: "Gandhibag Sahakari Bank Ltd", label: "Gandhibag Sahakari Bank Ltd" },
  { value: "Vikas Souharda Co Op Bank Ltd", label: "Vikas Souharda Co Op Bank Ltd" },
  { value: "Progressive Co Op Bank Ltd", label: "Progressive Co Op Bank Ltd" },
  { value: "Thiruvananthapuram District Co Op Bank Ltd", label: "Thiruvananthapuram District Co Op Bank Ltd" },
  { value: "Pune Urban Co Op Bank Ltd", label: "Pune Urban Co Op Bank Ltd" },
  { value: "Jalna Merchants Co Op Bank Ltd", label: "Jalna Merchants Co Op Bank Ltd" },
  { value: "Shri Anand Nagari Sahakari Bank Ltd", label: "Shri Anand Nagari Sahakari Bank Ltd" },
  { value: "Bapuji Co Op Bank Ltd", label: "Bapuji Co Op Bank Ltd" },
  { value: "Malleshwaram Co Op Bank Ltd", label: "Malleshwaram Co Op Bank Ltd" },
  { value: "Cosmos Co Op Bank Ltd", label: "Cosmos Co Op Bank Ltd" },
  { value: "Mahamedha Urban Co Op Bank Ltd", label: "Mahamedha Urban Co Op Bank Ltd" },
  { value: "Ghaziabad Zila Sahkari Bank Ltd", label: "Ghaziabad Zila Sahkari Bank Ltd" },
  { value: "Hindon Gramin Bank", label: "Hindon Gramin Bank" },
  { value: "Uttar Pradesh Gramin Bank", label: "Uttar Pradesh Gramin Bank" },
  { value: "Chaamoli Zila Sahkari Bank Ltd", label: "Chaamoli Zila Sahkari Bank Ltd" },
  { value: "Uttranchal Gramin Bank", label: "Uttranchal Gramin Bank" },
  { value: "Goa Urban Co Op Bank Ltd", label: "Goa Urban Co Op Bank Ltd" },
  { value: "Surendranagar District Co Op Bank Ltd", label: "Surendranagar District Co Op Bank Ltd" },
  { value: "Surendranagar Mercantile Co Op Bank Ltd", label: "Surendranagar Mercantile Co Op Bank Ltd" },
  { value: "Panihati Co Op Bank Ltd", label: "Panihati Co Op Bank Ltd" },
  { value: "Marketyard Commercial Co Op Bank Ltd", label: "Marketyard Commercial Co Op Bank Ltd" },
  { value: "Pragati Sahakari Bank Ltd", label: "Pragati Sahakari Bank Ltd" },
  { value: "Shree Co Op Bank Ltd", label: "Shree Co Op Bank Ltd" },
  { value: "Kheda District Central Co Op Bank Ltd", label: "Kheda District Central Co Op Bank Ltd" },
  { value: "Baroda Traders Co Op Bank Ltd", label: "Baroda Traders Co Op Bank Ltd" },
  { value: "The Kerala State Co Op Bank Ltd", label: "The Kerala State Co Op Bank Ltd" },
  { value: "Kutch Co Op Bank Ltd", label: "Kutch Co Op Bank Ltd" },
  { value: "Faridabad Central Co Op Bank Ltd", label: "Faridabad Central Co Op Bank Ltd" },
  { value: "Muvattupuzha Service Co Op Bank Ltd", label: "Muvattupuzha Service Co Op Bank Ltd" },
  { value: "Sree Subramanyeswara Co Op Bank Ltd", label: "Sree Subramanyeswara Co Op Bank Ltd" },
  { value: "Ratlam Mandsaur Kshetriya Gramin Bank", label: "Ratlam Mandsaur Kshetriya Gramin Bank" },
  { value: "The Uttarpara Co Op Bank Ltd", label: "The Uttarpara Co Op Bank Ltd" },
  { value: "Jagruti Co Op Urban Bank Ltd", label: "Jagruti Co Op Urban Bank Ltd" },
  { value: "Randheja Commercial Co Op Bank Ltd", label: "Randheja Commercial Co Op Bank Ltd" },
  { value: "Mandla Balaghat Kshetriya Gramin Bank", label: "Mandla Balaghat Kshetriya Gramin Bank" },
  { value: "Nagpur Nagarik Sahakari Bank Ltd", label: "Nagpur Nagarik Sahakari Bank Ltd" },
  { value: "Wardha Dist Central Co Op Bank Ltd", label: "Wardha Dist Central Co Op Bank Ltd" },
  { value: "Kudamaloor Service Co Op Bank Ltd", label: "Kudamaloor Service Co Op Bank Ltd" },
  { value: "Kunbi Sahakari Bank Ltd", label: "Kunbi Sahakari Bank Ltd" },
  { value: "Patan Nagarik Sahakari Bank Ltd", label: "Patan Nagarik Sahakari Bank Ltd" },
  { value: "Bhabhar Vibhag Nagrik Sahakari Bank Ltd", label: "Bhabhar Vibhag Nagrik Sahakari Bank Ltd" },
  { value: "Sardargunj Mercantile Co Op Bank Ltd", label: "Sardargunj Mercantile Co Op Bank Ltd" },
  { value: "Gujarat State Co Op Bank Ltd", label: "Gujarat State Co Op Bank Ltd" },
  { value: "Ichalkaranji Merchants Co Op Bank Ltd", label: "Ichalkaranji Merchants Co Op Bank Ltd" },
  { value: "Eluri Co Op Urban Bank Ltd", label: "Eluri Co Op Urban Bank Ltd" },
  { value: "Adarsh Mahila Co Op Bank Ltd", label: "Adarsh Mahila Co Op Bank Ltd" },
  { value: "Ijnore Urban Co Op Bank Ltd", label: "Ijnore Urban Co Op Bank Ltd" },
  { value: "Parvatiya Gramin Bank", label: "Parvatiya Gramin Bank" },
  { value: "Vyavsayik Sahkari Bank Ltd", label: "Vyavsayik Sahkari Bank Ltd" },
  { value: "Malappuram District Co Op Bank Ltd", label: "Malappuram District Co Op Bank Ltd" },
  { value: "Sri Gokarnanath Co Op Bank Ltd", label: "Sri Gokarnanath Co Op Bank Ltd" },
  { value: "Mandya Dist Co Op Central Bank Ltd", label: "Mandya Dist Co Op Central Bank Ltd" },
  { value: "Kottayam Service Co Op Bank Ltd", label: "Kottayam Service Co Op Bank Ltd" },
  { value: "Chittariparamba Service Co Op Bank", label: "Chittariparamba Service Co Op Bank" },
  { value: "Arab Bangladesh Bank Ltd", label: "Arab Bangladesh Bank Ltd" },
  { value: "Jhalawar Kendriya Sahakari Bank Ltd", label: "Jhalawar Kendriya Sahakari Bank Ltd" },
  { value: "Baitarani Gramya Bank", label: "Baitarani Gramya Bank" },
  { value: "Sahyadri Sahakari Bank Ltd", label: "Sahyadri Sahakari Bank Ltd" },
  { value: "Vallabh Vidyanagar Commercial Co Op Bank Ltd", label: "Vallabh Vidyanagar Commercial Co Op Bank Ltd" },
  { value: "Kkumbhagam Service Co Op Bank Ltd", label: "Kkumbhagam Service Co Op Bank Ltd" },
  { value: "Shree Tukaram Co Op Bank Ltd", label: "Shree Tukaram Co Op Bank Ltd" },
  { value: "Indore Swayamsiddh Mahila Co Op Bank Ltd", label: "Indore Swayamsiddh Mahila Co Op Bank Ltd" },
  { value: "Bhavana Rishi Co Op Urban Bank Ltd", label: "Bhavana Rishi Co Op Urban Bank Ltd" },
  { value: "The Rajajinagar Co Op Bank Ltd", label: "The Rajajinagar Co Op Bank Ltd" },
  { value: "Abu Dhabi Commercial Bank", label: "Abu Dhabi Commercial Bank" },
  { value: "Anuradha Urban Co Op Bank Ltd", label: "Anuradha Urban Co Op Bank Ltd" },
  { value: "The Alappuzha District Co Op Bank Ltd", label: "The Alappuzha District Co Op Bank Ltd" },
  { value: "Chovva Co Op Rural Bank Ltd", label: "Chovva Co Op Rural Bank Ltd" },
  { value: "Konkan Prant Sahakari Bank Ltd", label: "Konkan Prant Sahakari Bank Ltd" },
  { value: "The Cuttack Central Co Op. Bank Ltd", label: "The Cuttack Central Co Op. Bank Ltd" },
  { value: "Rushikulya Gramya Bank", label: "Rushikulya Gramya Bank" },
  { value: "Krishna Grameena Bank", label: "Krishna Grameena Bank" },
  { value: "Sreenidhi Souharda Sahakari Bank Niyamitha", label: "Sreenidhi Souharda Sahakari Bank Niyamitha" },
  { value: "The Moonnilavu Service Co Operative Bank Ltd", label: "The Moonnilavu Service Co Operative Bank Ltd" },
  { value: "Shree Mahalaxmi Coop Bank Ltd", label: "Shree Mahalaxmi Coop Bank Ltd" },
  { value: "Vaishya Sahakari Bank Ltd", label: "Vaishya Sahakari Bank Ltd" },
  { value: "Irinjalakuda Town Co Operative Bank Ltd", label: "Irinjalakuda Town Co Operative Bank Ltd" },
  { value: "Shree Siddhivinayak Nagari Sahakari Bank Ltd.", label: "Shree Siddhivinayak Nagari Sahakari Bank Ltd." },
  { value: "The Sulthan Bathery Service Co Op Bank Ltd", label: "The Sulthan Bathery Service Co Op Bank Ltd" },
  { value: "The Azad Urban Co Op Bank Ltd", label: "The Azad Urban Co Op Bank Ltd" },
  { value: "The A P Janata Co Op Urban Bank Ltd", label: "The A P Janata Co Op Urban Bank Ltd" },
  { value: "The Meenachil East Urban Co Operative Bank Ltd", label: "The Meenachil East Urban Co Operative Bank Ltd" },
  { value: "The Rajapur Sahakari Bank Ltd", label: "The Rajapur Sahakari Bank Ltd" },
  { value: "Paschim Banga Gramin Bank", label: "Paschim Banga Gramin Bank" },
  { value: "Laxmi Vilas Bank Ltd", label: "Laxmi Vilas Bank Ltd" },
  { value: "Nagpur District Central Co Op Bank Ltd", label: "Nagpur District Central Co Op Bank Ltd" },
  { value: "Dr. Babasaheb Ambedkar Urban Co Op Bank Ltd", label: "Dr. Babasaheb Ambedkar Urban Co Op Bank Ltd" },
  { value: "Madikai Service Co Op Bank Ltd", label: "Madikai Service Co Op Bank Ltd" },
  { value: "Kanhileri Sivapuram Service Co Op Bank", label: "Kanhileri Sivapuram Service Co Op Bank" },
  { value: "Cheruthazham Service Co Op Bank", label: "Cheruthazham Service Co Op Bank" },
  { value: "Mapusa Urban Co Op Bank Of Goa Ltd", label: "Mapusa Urban Co Op Bank Of Goa Ltd" },
  { value: "Banaskantha Mercantile Co Op Bank Ltd", label: "Banaskantha Mercantile Co Op Bank Ltd" },
  { value: "Pimpri Chinchwad Sahakari Bank Maryadit", label: "Pimpri Chinchwad Sahakari Bank Maryadit" },
  { value: "Harij Nagarik Sahakari Bank Ltd", label: "Harij Nagarik Sahakari Bank Ltd" },
  { value: "Surendranagar Bhavnagar Gramin Bank", label: "Surendranagar Bhavnagar Gramin Bank" },
  { value: "Cachar Gramin Bank", label: "Cachar Gramin Bank" },
  { value: "Vyavasayik Evam Audhyogik Sah Bank Ltd", label: "Vyavasayik Evam Audhyogik Sah Bank Ltd" },
  { value: "The Veraval Mercantile Co Op Bank Ltd", label: "The Veraval Mercantile Co Op Bank Ltd" },
  { value: "The Hotel Industrialists Co Op Bank Ltd", label: "The Hotel Industrialists Co Op Bank Ltd" },
  { value: "Shree Sharada Sahakari Bank Ltd", label: "Shree Sharada Sahakari Bank Ltd" },
  { value: "The Udaipur Mahila Samridhi Urban Co Op Bank Ltd", label: "The Udaipur Mahila Samridhi Urban Co Op Bank Ltd" },
  { value: "The Udaipur Mahila Urban Co Op Bank Ltd", label: "The Udaipur Mahila Urban Co Op Bank Ltd" },
  { value: "The Sirohi Central Co Op Bank Ltd", label: "The Sirohi Central Co Op Bank Ltd" },
  { value: "The Urban Co Opbank Ltd", label: "The Urban Co Opbank Ltd" },
  { value: "Bhadradri Co Op Urban Bank Ltd", label: "Bhadradri Co Op Urban Bank Ltd" },
  { value: "The Gurgaon Central Co Op Bank Ltd", label: "The Gurgaon Central Co Op Bank Ltd" },
  { value: "Sri Kanyakaparameswari Co Op Bank Ltd", label: "Sri Kanyakaparameswari Co Op Bank Ltd" },
  { value: "The Kheda Peoples Co Op Bank Ltd", label: "The Kheda Peoples Co Op Bank Ltd" },
  { value: "The Dahod Urban Co Op Bank Ltd", label: "The Dahod Urban Co Op Bank Ltd" },
  { value: "Shree Chhani Nagrik Sahakari Bank Ltd", label: "Shree Chhani Nagrik Sahakari Bank Ltd" },
  { value: "State Transport Co Op Bank Ltd", label: "State Transport Co Op Bank Ltd" },
  { value: "The Bijnore Urban Co Op Bank Ltd", label: "The Bijnore Urban Co Op Bank Ltd" },
  { value: "The Chitanvispura Sahakari Bank Ltd", label: "The Chitanvispura Sahakari Bank Ltd" },
  { value: "Shalini Sahakari Bank Ltd", label: "Shalini Sahakari Bank Ltd" },
  { value: "Jaipur Nagaur Aanchalik Gramin Bank", label: "Jaipur Nagaur Aanchalik Gramin Bank" },
  { value: "Navi Mumbai Co Op Bank Ltd", label: "Navi Mumbai Co Op Bank Ltd" },
  { value: "Scotia Bank", label: "Scotia Bank" },
  { value: "Suvarna Co Op Bank Ltd", label: "Suvarna Co Op Bank Ltd" },
  { value: "Indore Paraspar Sahakari Bank Ltd", label: "Indore Paraspar Sahakari Bank Ltd" },
  { value: "Mahila Co Op Bank Ltd", label: "Mahila Co Op Bank Ltd" },
  { value: "Sir M Visveswaraya Co Op Bank Ltd", label: "Sir M Visveswaraya Co Op Bank Ltd" },
  { value: "The Bantra Co Op Bank Ltd", label: "The Bantra Co Op Bank Ltd" },
  { value: "Amballoor Service Co Op Bank Ltd", label: "Amballoor Service Co Op Bank Ltd" },
  { value: "The Gulbarga Dist Co Op Central Bank Ltd", label: "The Gulbarga Dist Co Op Central Bank Ltd" },
  { value: "Alibaug Co Op Urban Bank Ltd", label: "Alibaug Co Op Urban Bank Ltd" },
  { value: "The Pudukkottai Co Op Urban Bank Ltd", label: "The Pudukkottai Co Op Urban Bank Ltd" },
  { value: "Samruddhi Co Op Bank Ltd", label: "Samruddhi Co Op Bank Ltd" },
  { value: "Ravi Commercial Urban Co Op Bank Ltd", label: "Ravi Commercial Urban Co Op Bank Ltd" },
  { value: "Wardha Nagari Sahakari Adhikosh Bank Mydt", label: "Wardha Nagari Sahakari Adhikosh Bank Mydt" },
  { value: "Shrikrishna Co Op Bank Ltd", label: "Shrikrishna Co Op Bank Ltd" },
  { value: "Sarva Up Gramin Bank", label: "Sarva Up Gramin Bank" },
  { value: "The Meenadom Service Co Op Bank Ltd", label: "The Meenadom Service Co Op Bank Ltd" },
  { value: "The Velloor Service Co Op Bank", label: "The Velloor Service Co Op Bank" },
  { value: "The Kalghatgi Urban Co Op Bank Ltd", label: "The Kalghatgi Urban Co Op Bank Ltd" },
  { value: "Sree Thyagaraja Co Op Bank Ltd", label: "Sree Thyagaraja Co Op Bank Ltd" },
  { value: "The Ranuj Nagarik Sahakari Bank Ltd", label: "The Ranuj Nagarik Sahakari Bank Ltd" },
  { value: "Solapur Gramin Bank", label: "Solapur Gramin Bank" },
  { value: "The Singhbhum District Central Co Op Bank Ltd", label: "The Singhbhum District Central Co Op Bank Ltd" },
  { value: "The Apna Co Op Urban Bank Ltd", label: "The Apna Co Op Urban Bank Ltd" },
  { value: "The Agrasen Nagari Sahakari Bank Ltd", label: "The Agrasen Nagari Sahakari Bank Ltd" },
  { value: "The A B E Co Op Bank Ltd", label: "The A B E Co Op Bank Ltd" },
  { value: "The Pachora Peoples Co Op Bank Ltd", label: "The Pachora Peoples Co Op Bank Ltd" },
  { value: "The Padra Nagar Nagrik Sahakari Bank Ltd", label: "The Padra Nagar Nagrik Sahakari Bank Ltd" },
  { value: "Ratnagiri District Central Co Op Bank Ltd", label: "Ratnagiri District Central Co Op Bank Ltd" },
  { value: "The Pattukkottai Co Op Urban Bank Ltd", label: "The Pattukkottai Co Op Urban Bank Ltd" },
  { value: "Indore Premier Co Op Bank Ltd", label: "Indore Premier Co Op Bank Ltd" },
  { value: "The Kanakamahalakshmi Co Op Bank Ltd", label: "The Kanakamahalakshmi Co Op Bank Ltd" },
  { value: "Rythara Seva Sahakara Bank", label: "Rythara Seva Sahakara Bank" },
  { value: "Shinhan Bank", label: "Shinhan Bank" },
  { value: "Shri Kanyaka Nagari Sahakari Bank Ltd", label: "Shri Kanyaka Nagari Sahakari Bank Ltd" },
  { value: "The Tapindu Urban Co Op Bank Ltd", label: "The Tapindu Urban Co Op Bank Ltd" },
  { value: "Edathala Service Co Op Bank Ltd", label: "Edathala Service Co Op Bank Ltd" },
  { value: "Jijau Commercial Co Op Bank Ltd", label: "Jijau Commercial Co Op Bank Ltd" },
  { value: "The Kalwan Merchants Co Op Bank Ltd", label: "The Kalwan Merchants Co Op Bank Ltd" },
  { value: "The Manekchowk Co Op Bank Ltd", label: "The Manekchowk Co Op Bank Ltd" },
  { value: "Hadoti Kshetriya Gramin Bank", label: "Hadoti Kshetriya Gramin Bank" },
  { value: "The Cuttack Credit Co Op Ltd", label: "The Cuttack Credit Co Op Ltd" },
  { value: "State Bank Of  Bikaner And Jaipur", label: "State Bank Of  Bikaner And Jaipur" },
  { value: "Jalaun District Co Op Bank Ltd", label: "Jalaun District Co Op Bank Ltd" },
  { value: "The Rajsamand Urban Co Op Bank Ltd", label: "The Rajsamand Urban Co Op Bank Ltd" },
  { value: "Shri Veershaiv Co Op Bank Ltd", label: "Shri Veershaiv Co Op Bank Ltd" },
  { value: "Aman Sahakari Bank Ltd", label: "Aman Sahakari Bank Ltd" },
  { value: "Sadalga Urban Souharda Sahakari Bank Niyamit", label: "Sadalga Urban Souharda Sahakari Bank Niyamit" },
  { value: "The Janatha Co Operative Bank Ltd", label: "The Janatha Co Operative Bank Ltd" },
  { value: "Shriram Urban Co Op Bank Ltd", label: "Shriram Urban Co Op Bank Ltd" },
  { value: "Greenland Finance And Leasing P Ltd", label: "Greenland Finance And Leasing P Ltd" },
  { value: "Rishab Financial Services", label: "Rishab Financial Services" },
  { value: "Deepak Financial Services", label: "Deepak Financial Services" },
  { value: "Wheelocity Financial Services", label: "Wheelocity Financial Services" },
  { value: "The Mahila Urban Co Op Bank Ltd", label: "The Mahila Urban Co Op Bank Ltd" },
  { value: "The Jogindra Central Co Op Bank Ltd", label: "The Jogindra Central Co Op Bank Ltd" },
  { value: "Junagadh Commercial Co Op Bank Ltd", label: "Junagadh Commercial Co Op Bank Ltd" },
  { value: "Nirmal Urban Co Op Bank Ltd", label: "Nirmal Urban Co Op Bank Ltd" },
  { value: "Mizuho Corporate Bank Ltd", label: "Mizuho Corporate Bank Ltd" },
  { value: "Bombay Mercantile Co Op Bank Ltd", label: "Bombay Mercantile Co Op Bank Ltd" },
  { value: "Jarneshwar Nagrik Sahakari Bank Myd", label: "Jarneshwar Nagrik Sahakari Bank Myd" },
  { value: "The Needs Of Life Co Op Bank Ltd", label: "The Needs Of Life Co Op Bank Ltd" },
  { value: "The Secunderabad Mercantile Co Op Urban Bank Ltd", label: "The Secunderabad Mercantile Co Op Urban Bank Ltd" },
  { value: "Ap Mahajans Co Op Urban Bank Ltd", label: "Ap Mahajans Co Op Urban Bank Ltd" },
  { value: "Punjab And Kashmir Finance Ltd", label: "Punjab And Kashmir Finance Ltd" },
  { value: "The Davangere Urban Co Op Bank Ltd", label: "The Davangere Urban Co Op Bank Ltd" },
  { value: "The Merchants Souhardha Sahakara Bank Niyamitha", label: "The Merchants Souhardha Sahakara Bank Niyamitha" },
  { value: "Lord Krishna Bank Ltd", label: "Lord Krishna Bank Ltd" },
  { value: "Bharat Sahkari Bank Ltd", label: "Bharat Sahkari Bank Ltd" },
  { value: "Bhandara District Central Co Op Bank Ltd", label: "Bhandara District Central Co Op Bank Ltd" },
  { value: "The Karannur Service Co Op Bank Ltd", label: "The Karannur Service Co Op Bank Ltd" },
  { value: "The Sakthi Primary Agricultural Co Op Bank Ltd", label: "The Sakthi Primary Agricultural Co Op Bank Ltd" },
  { value: "Bharati Sahakari Bank Ltd", label: "Bharati Sahakari Bank Ltd" },
  { value: "Birdev Sahakari Bank Ltd", label: "Birdev Sahakari Bank Ltd" },
  { value: "Kallappanna Awade Inchalkaranji Janata Sahakari Bank Ltd", label: "Kallappanna Awade Inchalkaranji Janata Sahakari Bank Ltd" },
  { value: "The Deccan Urban Co Op Bank Ltd", label: "The Deccan Urban Co Op Bank Ltd" },
  { value: "Ace Co Op Bank Ltd", label: "Ace Co Op Bank Ltd" },
  { value: "The Dharmaj Peoples Co Op Bank Ltd", label: "The Dharmaj Peoples Co Op Bank Ltd" },
  { value: "The Karnal Central Co Op Bank Ltd", label: "The Karnal Central Co Op Bank Ltd" },
  { value: "The Punjab State Co Op Bank Ltd", label: "The Punjab State Co Op Bank Ltd" },
  { value: "Tirumala Co Op Urban Bank Ltd", label: "Tirumala Co Op Urban Bank Ltd" },
  { value: "The Raiganj Central Co Op Bank Ltd", label: "The Raiganj Central Co Op Bank Ltd" },
  { value: "The Nandura Urban Co Op Bank Ltd", label: "The Nandura Urban Co Op Bank Ltd" },
  { value: "Ballia Kshetriya Gramin Bank", label: "Ballia Kshetriya Gramin Bank" },
  { value: "The Panniyankara Service Co Op Bank Ltd", label: "The Panniyankara Service Co Op Bank Ltd" },
  { value: "The Jamnagar Dist Co Op Bank Ltd", label: "The Jamnagar Dist Co Op Bank Ltd" },
  { value: "The Veraval Peoples Co Op Bank Ltd", label: "The Veraval Peoples Co Op Bank Ltd" },
  { value: "Padmavathi Co Op Urban Bank Ltd", label: "Padmavathi Co Op Urban Bank Ltd" },
  { value: "The Dadasaheb Rawal Co Op Bank Of Dondaicha Ltd", label: "The Dadasaheb Rawal Co Op Bank Of Dondaicha Ltd" },
  { value: "Vepar Vikas Co Op Bank Ltd", label: "Vepar Vikas Co Op Bank Ltd" },
  { value: "Sree Charan Bank", label: "Sree Charan Bank" },
  { value: "Thekkumbagam Service Co Op Bank Ltd", label: "Thekkumbagam Service Co Op Bank Ltd" },
  { value: "Nasik District Industrial And Mercantile Co Op Bank Ltd", label: "Nasik District Industrial And Mercantile Co Op Bank Ltd" },
  { value: "Smriti Nagrik Sahakari Bank Mydt", label: "Smriti Nagrik Sahakari Bank Mydt" },
  { value: "The Kapurthala Central Co Op Bank Ltd", label: "The Kapurthala Central Co Op Bank Ltd" },
  { value: "The Bellary District Co Op Central Bank Ltd", label: "The Bellary District Co Op Central Bank Ltd" },
  { value: "Chennai Central Co Op Bank Ltd", label: "Chennai Central Co Op Bank Ltd" },
  { value: "Brahmawart Commercial Co Op Bank Ltd", label: "Brahmawart Commercial Co Op Bank Ltd" },
  { value: "The Bhandara Urban Co Op Bank Ltd", label: "The Bhandara Urban Co Op Bank Ltd" },
  { value: "The Karad Janata Sahakari Bank Ltd Karad", label: "The Karad Janata Sahakari Bank Ltd Karad" },
  { value: "Pitambar Finance Co", label: "Pitambar Finance Co" },
  { value: "Pitambar Dealcom Pvt Ltd", label: "Pitambar Dealcom Pvt Ltd" },
  { value: "Pitambar Trading Co", label: "Pitambar Trading Co" },
  { value: "Parbhani District Central Co Op Bank Ltd", label: "Parbhani District Central Co Op Bank Ltd" },
  { value: "Vidya Sahkari Bank Ltd", label: "Vidya Sahkari Bank Ltd" },
  { value: "Navanagar Urban Coop Bank Limited", label: "Navanagar Urban Coop Bank Limited" },
  { value: "Saptagiri Grameena Bank", label: "Saptagiri Grameena Bank" },
  { value: "The Bidar Dist Co Op Central Bank Ltd", label: "The Bidar Dist Co Op Central Bank Ltd" },
  { value: "Sree Banashankari Mahila Co Op Bank Ltd", label: "Sree Banashankari Mahila Co Op Bank Ltd" },
  { value: "Wardhaman Urban Co Op Bank Ltd", label: "Wardhaman Urban Co Op Bank Ltd" },
  { value: "Sulaimani Co Op Bank Ltd", label: "Sulaimani Co Op Bank Ltd" },
  { value: "Ambarnath Jai Hind Co Op Bank Ltd, Ambarnath", label: "Ambarnath Jai Hind Co Op Bank Ltd, Ambarnath" },
  { value: "Tehri Garhwal Zila Sahkari Bank Ltd", label: "Tehri Garhwal Zila Sahkari Bank Ltd" },
  { value: "The Kendrapara Urban Co Op Bank Ltd", label: "The Kendrapara Urban Co Op Bank Ltd" },
  { value: "Suco Souharda Sahakari Bank", label: "Suco Souharda Sahakari Bank" },
  { value: "The Hubli Urban Co Op Bank Ltd", label: "The Hubli Urban Co Op Bank Ltd" },
  { value: "Garha Co Op Bank Ltd", label: "Garha Co Op Bank Ltd" },
  { value: "Bharat Heavy Electricals Employees Co Op Bank Ltd, Trichy", label: "Bharat Heavy Electricals Employees Co Op Bank Ltd, Trichy" },
  { value: "Mpss Bank", label: "Mpss Bank" },
  { value: "The Karmachari Co Op Bank Ltd", label: "The Karmachari Co Op Bank Ltd" },
  { value: "Chandrapur Gadchiroli Gramin Bank", label: "Chandrapur Gadchiroli Gramin Bank" },
  { value: "Shanthi Lal Jain", label: "Shanthi Lal Jain" },
  { value: "Suklacha Finance Corporation", label: "Suklacha Finance Corporation" },
  { value: "Dugar Finance And Investments Ltd", label: "Dugar Finance And Investments Ltd" },
  { value: "Jangra Finance Corporation", label: "Jangra Finance Corporation" },
  { value: "Nahar Financial Services", label: "Nahar Financial Services" },
  { value: "B.Lalith Chand Nahar Financier", label: "B.Lalith Chand Nahar Financier" },
  { value: "S.V.Finance", label: "S.V.Finance" },
  { value: "Arihant Finance", label: "Arihant Finance" },
  { value: "Adehswar Finance", label: "Adehswar Finance" },
  { value: "Shyam Kumar Mehta Financier", label: "Shyam Kumar Mehta Financier" },
  { value: "Motor Finance Corporation", label: "Motor Finance Corporation" },
  { value: "Guardian Souharda Sahakari Bank Niyamita", label: "Guardian Souharda Sahakari Bank Niyamita" },
  { value: "Shekhawati Gramin Bank", label: "Shekhawati Gramin Bank" },
  { value: "Intergral Urban Co Op Bank Ltd", label: "Intergral Urban Co Op Bank Ltd" },
  { value: "Kshetriya Gramin Bank Hoshangabad", label: "Kshetriya Gramin Bank Hoshangabad" },
  { value: "The Chathamangalam Service Co Op Bank Ltd", label: "The Chathamangalam Service Co Op Bank Ltd" },
  { value: "The Eachome Service Co Op Bank Ltd", label: "The Eachome Service Co Op Bank Ltd" },
  { value: "The West Bengal State Co Op Bank Ltd", label: "The West Bengal State Co Op Bank Ltd" },
  { value: "Jalore Nagrik Sahakari Bank Ltd", label: "Jalore Nagrik Sahakari Bank Ltd" },
  { value: "Anandeshwari Nagrik Sah Bank Myd", label: "Anandeshwari Nagrik Sah Bank Myd" },
  { value: "State Bank Of Mauritius Ltd", label: "State Bank Of Mauritius Ltd" },
  { value: "Dbs Bank Limited", label: "Dbs Bank Limited" },
  { value: "The Tiruchirapalli Dt Central Co Op Bank Ltd", label: "The Tiruchirapalli Dt Central Co Op Bank Ltd" },
  { value: "Maharana Pratap Co Op Urban Bank Ltd", label: "Maharana Pratap Co Op Urban Bank Ltd" },
  { value: "Shriram Trade Finance Co Ltd", label: "Shriram Trade Finance Co Ltd" },
  { value: "K P R Investment Private Ltd", label: "K P R Investment Private Ltd" },
  { value: "Devi Gayatri Co Op Urban Bank Ltd", label: "Devi Gayatri Co Op Urban Bank Ltd" },
  { value: "The Belgaum District Central Co Op Bank Ltd Belgaum", label: "The Belgaum District Central Co Op Bank Ltd Belgaum" },
  { value: "The Central Co Op Bank Ltd Bhilwara", label: "The Central Co Op Bank Ltd Bhilwara" },
  { value: "United Commercial Co Op Bank Ltd", label: "United Commercial Co Op Bank Ltd" },
  { value: "The Vysya Bank Ltd", label: "The Vysya Bank Ltd" },
  { value: "Vinod Associates", label: "Vinod Associates" },
  { value: "The Hoshiarpur Central Co Op Bank Ltd", label: "The Hoshiarpur Central Co Op Bank Ltd" },
  { value: "The Baghat Urban Co Op Bank Ltd", label: "The Baghat Urban Co Op Bank Ltd" },
  { value: "The Bhuj Commercial Co Op Bank Ltd", label: "The Bhuj Commercial Co Op Bank Ltd" },
  { value: "Mandvi Mercantile Co Op Bank", label: "Mandvi Mercantile Co Op Bank" },
  { value: "Krishna Co Op Credit Society Ltd,Bilagi", label: "Krishna Co Op Credit Society Ltd,Bilagi" },
  { value: "The Shahada Peoples Co Op Bank Ltd", label: "The Shahada Peoples Co Op Bank Ltd" },
  { value: "Reserve Bank Employees Co Op Bank Ltd", label: "Reserve Bank Employees Co Op Bank Ltd" },
  { value: "The Kodungallur Town Co Op Bank Ltd", label: "The Kodungallur Town Co Op Bank Ltd" },
  { value: "The Belgaum Industrial Co Op Bank Ltd,Belgaum", label: "The Belgaum Industrial Co Op Bank Ltd,Belgaum" },
  { value: "Shree Basaveshwar Co Op Bank Ltd,Belgaum", label: "Shree Basaveshwar Co Op Bank Ltd,Belgaum" },
  { value: "The Gokak Urban Co Op Credit Bank Ltd,Gokak", label: "The Gokak Urban Co Op Credit Bank Ltd,Gokak" },
  { value: "The Nagpur Mahanagarpalika Karmachari Sahakari Bank Ltd Nagpur", label: "The Nagpur Mahanagarpalika Karmachari Sahakari Bank Ltd Nagpur" },
  { value: "The Utkal Co Op Banking Society Ltd", label: "The Utkal Co Op Banking Society Ltd" },
  { value: "The Mayurbhanj Central Co Op Bank Ltd", label: "The Mayurbhanj Central Co Op Bank Ltd" },
  { value: "Youth Development Co Op Bank Ltd", label: "Youth Development Co Op Bank Ltd" },
  { value: "Navsarjan Industrial Co Op Bank Ltd", label: "Navsarjan Industrial Co Op Bank Ltd" },
  { value: "Keshav Sahkari Bank Ltd", label: "Keshav Sahkari Bank Ltd" },
  { value: "Shri Satyavijay Sahakari Bank Ltd", label: "Shri Satyavijay Sahakari Bank Ltd" },
  { value: "Jolarpet Co Op Ubran Bank Ltd", label: "Jolarpet Co Op Ubran Bank Ltd" },
  { value: "The Surendranagar Mer Co Op Bank Ltd", label: "The Surendranagar Mer Co Op Bank Ltd" },
  { value: "The Mandal Nagrik Sahakari Bank Ltd", label: "The Mandal Nagrik Sahakari Bank Ltd" },
  { value: "Sanmitra Sahakari Bank Ltd", label: "Sanmitra Sahakari Bank Ltd" },
  { value: "Arvind Sahakari Bank Ltd", label: "Arvind Sahakari Bank Ltd" },
  { value: "The Kodinar Taluka Co Op Banking Union Ltd", label: "The Kodinar Taluka Co Op Banking Union Ltd" },
  { value: "Lala Urban Co Op Bank Ltd", label: "Lala Urban Co Op Bank Ltd" },
  { value: "Shree Chatrapati Urban Co Op Bank Ltd", label: "Shree Chatrapati Urban Co Op Bank Ltd" },
  { value: "The Villupuram District Central Co Op Bank Ltd", label: "The Villupuram District Central Co Op Bank Ltd" },
  { value: "Harekrishna Finance", label: "Harekrishna Finance" },
  { value: "Sangli Urban Co Op Bank Ltd", label: "Sangli Urban Co Op Bank Ltd" },
  { value: "Jhalawar Nagrik Sahkari Bank Ltd", label: "Jhalawar Nagrik Sahkari Bank Ltd" },
  { value: "The Kattappana Urban Co Op Bank Ltd", label: "The Kattappana Urban Co Op Bank Ltd" },
  { value: "Arthunkal Village Service Co Op Bank Ltd", label: "Arthunkal Village Service Co Op Bank Ltd" },
  { value: "Bhopal Co Op Central Bank Ltd", label: "Bhopal Co Op Central Bank Ltd" },
  { value: "The Iringal Service Co Op Bank Ltd", label: "The Iringal Service Co Op Bank Ltd" },
  { value: "The Thoprankudy Service Co Op Bank Ltd", label: "The Thoprankudy Service Co Op Bank Ltd" },
  { value: "The Thane Dist Central Co Op Bank Ltd", label: "The Thane Dist Central Co Op Bank Ltd" },
  { value: "Etah Urban Co Op Bank Ltd", label: "Etah Urban Co Op Bank Ltd" },
  { value: "Shree Bhailalbhai Contractor Smarak Co Op Bank Ltd", label: "Shree Bhailalbhai Contractor Smarak Co Op Bank Ltd" },
  { value: "Balasinor Nagarik Sahakari Bank Ltd", label: "Balasinor Nagarik Sahakari Bank Ltd" },
  { value: "Nasik Jilha Mahila Vikas Sahakari Bank Ltd", label: "Nasik Jilha Mahila Vikas Sahakari Bank Ltd" },
  { value: "The Karimnagar District Co Op Central Bank Ltd", label: "The Karimnagar District Co Op Central Bank Ltd" },
  { value: "Sree Charan Souharda Co Op Bank Ltd", label: "Sree Charan Souharda Co Op Bank Ltd" },
  { value: "Buldana Urban Co Op Credit Society Ltd", label: "Buldana Urban Co Op Credit Society Ltd" },
  { value: "Warangal Urban Co Op Bank Ltd", label: "Warangal Urban Co Op Bank Ltd" },
  { value: "The Mehsana Jilla Panchayat Karmachari Co Op Bank Ltd", label: "The Mehsana Jilla Panchayat Karmachari Co Op Bank Ltd" },
  { value: "Krung Thai Bank Public Company Ltd", label: "Krung Thai Bank Public Company Ltd" },
  { value: "Vindhyavasini Gramin Bank", label: "Vindhyavasini Gramin Bank" },
  { value: "The Seva Vikas Co Op Bank Ltd", label: "The Seva Vikas Co Op Bank Ltd" },
  { value: "Shri Chhatrapati Rajarshi Shahu Urban Co Op Bank Ltd", label: "Shri Chhatrapati Rajarshi Shahu Urban Co Op Bank Ltd" },
  { value: "Sri Siddaparavatha Pattina Souharda Sahakari N Spss", label: "Sri Siddaparavatha Pattina Souharda Sahakari N Spss" },
  { value: "Sandur Pattana Souharda Sahakari Bank Ni Sps", label: "Sandur Pattana Souharda Sahakari Bank Ni Sps" },
  { value: "Cauvery Kalpatharu Grameena Bank", label: "Cauvery Kalpatharu Grameena Bank" },
  { value: "Jharkhand Gramin Bank", label: "Jharkhand Gramin Bank" },
  { value: "The Bihar State Co Op Bank Ltd", label: "The Bihar State Co Op Bank Ltd" },
  { value: "The D C C Bank Ltd", label: "The D C C Bank Ltd" },
  { value: "The Kosamba Mercantile Co Op Bank Ltd", label: "The Kosamba Mercantile Co Op Bank Ltd" },
  { value: "Dr. Jaiprakash Mundada Urban Co Op Bank Ltd.,Jawlabazar", label: "Dr. Jaiprakash Mundada Urban Co Op Bank Ltd.,Jawlabazar" },
  { value: "The Kulappully Service Co Op Bank Ltd", label: "The Kulappully Service Co Op Bank Ltd" },
  { value: "Manapparai Town Co Op Bank Ltd", label: "Manapparai Town Co Op Bank Ltd" },
  { value: "The Bank Of Rajasthan Ltd", label: "The Bank Of Rajasthan Ltd" },
  { value: "Oman International Bank.S.A.O.G.", label: "Oman International Bank.S.A.O.G." },
  { value: "Buldana Gramin Bank", label: "Buldana Gramin Bank" },
  { value: "The Nilambur Co Op Urban Bank Ltd", label: "The Nilambur Co Op Urban Bank Ltd" },
  { value: "Sri Kannikaparameshwari Co Op Bank Ltd", label: "Sri Kannikaparameshwari Co Op Bank Ltd" },
  { value: "Dr.Babasaheb Ambedkar Nagari Sahakari Bank Ltd", label: "Dr.Babasaheb Ambedkar Nagari Sahakari Bank Ltd" },
  { value: "Central India Bankers", label: "Central India Bankers" },
  { value: "The Mandvi Nagrik Sahakari Bank", label: "The Mandvi Nagrik Sahakari Bank" },
  { value: "The Limdi Urban Co Op Bank Ltd", label: "The Limdi Urban Co Op Bank Ltd" },
  { value: "The Amravati Peoples Co Op Bank Ltd", label: "The Amravati Peoples Co Op Bank Ltd" },
  { value: "Ms Bhagwandas Ramnath", label: "Ms Bhagwandas Ramnath" },
  { value: "Gayaprosad Hiralal", label: "Gayaprosad Hiralal" },
  { value: "Liluah Co Op Bank Ltd", label: "Liluah Co Op Bank Ltd" },
  { value: "United Finance India", label: "United Finance India" },
  { value: "Esbi Udyog", label: "Esbi Udyog" },
  { value: "East Coast Tradefin Ltd", label: "East Coast Tradefin Ltd" },
  { value: "Bhagwan Das Ramnath", label: "Bhagwan Das Ramnath" },
  { value: "Merlin Park Service P Ltd", label: "Merlin Park Service P Ltd" },
  { value: "Mgb Gramin Bank", label: "Mgb Gramin Bank" },
  { value: "Pavana Sahakari Bank Ltd", label: "Pavana Sahakari Bank Ltd" },
  { value: "Jubilee Hills Mercantile Co Op Urban Bank Ltd", label: "Jubilee Hills Mercantile Co Op Urban Bank Ltd" },
  { value: "Moospet Service Co Op Bank Ltd", label: "Moospet Service Co Op Bank Ltd" },
  { value: "The Trithala Service Co Op Bank Ltd", label: "The Trithala Service Co Op Bank Ltd" },
  { value: "Shreeji Bhatia Co Op Bank Ltd", label: "Shreeji Bhatia Co Op Bank Ltd" },
  { value: "Palus Sahakari Bank Ltd", label: "Palus Sahakari Bank Ltd" },
  { value: "The Puthuperiyaram Service Co Op Bank Ltd", label: "The Puthuperiyaram Service Co Op Bank Ltd" },
  { value: "Lucknow Urban Co Op Bank Ltd", label: "Lucknow Urban Co Op Bank Ltd" },
  { value: "Allahabad Kshetriya Gramin Bank", label: "Allahabad Kshetriya Gramin Bank" },
  { value: "Nagarik Samabay Bank Ltd", label: "Nagarik Samabay Bank Ltd" },
  { value: "Shushruti Souharda Sahakara Bank Niyamita", label: "Shushruti Souharda Sahakara Bank Niyamita" },
  { value: "The Bangalore City Co Op Bank Ltd", label: "The Bangalore City Co Op Bank Ltd" },
  { value: "Siddhpur Nagarik Sahakari Bank Ltd", label: "Siddhpur Nagarik Sahakari Bank Ltd" },
  { value: "Pinakini Grameena Bank", label: "Pinakini Grameena Bank" },
  { value: "Uttrakhand Co Op Bank Ltd", label: "Uttrakhand Co Op Bank Ltd" },
  { value: "The Malda District Central Co Op Bank Ltd", label: "The Malda District Central Co Op Bank Ltd" },
  { value: "Ramrajya Sahakari Bank Ltd Pune", label: "Ramrajya Sahakari Bank Ltd Pune" },
  { value: "Shriram City Union Fin Ltd", label: "Shriram City Union Fin Ltd" },
  { value: "Akshaya Financial Services", label: "Akshaya Financial Services" },
  { value: "The Valavannur Service Co Op Bank Ltd", label: "The Valavannur Service Co Op Bank Ltd" },
  { value: "Thennala Service Co Op Bank Ltd", label: "Thennala Service Co Op Bank Ltd" },
  { value: "Jai Bhawani Sahakari Bank Ltd", label: "Jai Bhawani Sahakari Bank Ltd" },
  { value: "Shree Lodra Nagarik Sahakari Bank Ltd", label: "Shree Lodra Nagarik Sahakari Bank Ltd" },
  { value: "The Bank Of  Tokyo Mitsubishi Ufj Ltd", label: "The Bank Of  Tokyo Mitsubishi Ufj Ltd" },
  { value: "Baranagar Co Op Bank Ltd", label: "Baranagar Co Op Bank Ltd" },
  { value: "Meghalaya Rural Bank", label: "Meghalaya Rural Bank" },
  { value: "The Santragachi Co Op Bank Ltd", label: "The Santragachi Co Op Bank Ltd" },
  { value: "The Rohtak Central Co Op Bank Ltd", label: "The Rohtak Central Co Op Bank Ltd" },
  { value: "The Mysore Merchants Co Op Bank Ltd", label: "The Mysore Merchants Co Op Bank Ltd" },
  { value: "The Yadagiri Lakshmi Narsimha Swamy Co Op Urban Bank Ltd", label: "The Yadagiri Lakshmi Narsimha Swamy Co Op Urban Bank Ltd" },
  { value: "Shimoga Dist Co Op Central Bank Ltd", label: "Shimoga Dist Co Op Central Bank Ltd" },
  { value: "Shree Talaja Nagarik Sahakari Bank Ltd", label: "Shree Talaja Nagarik Sahakari Bank Ltd" },
  { value: "The Thurayur Service Co Op Bank Ltd", label: "The Thurayur Service Co Op Bank Ltd" },
  { value: "U S Enterprises", label: "U S Enterprises" },
  { value: "The Bicholim Urban Co Op Bank Ltd", label: "The Bicholim Urban Co Op Bank Ltd" },
  { value: "Vimal Finances", label: "Vimal Finances" },
  { value: "Nanesh Credit Corporation", label: "Nanesh Credit Corporation" },
  { value: "The Panvel Co Op Urban Bank Ltd", label: "The Panvel Co Op Urban Bank Ltd" },
  { value: "Sri Lakshminarayana Co Op Urban Bank Ltd", label: "Sri Lakshminarayana Co Op Urban Bank Ltd" },
  { value: "The Bhatkal Urban Co Op Bank Ltd", label: "The Bhatkal Urban Co Op Bank Ltd" },
  { value: "Tulsi Gramin Bank", label: "Tulsi Gramin Bank" },
  { value: "The Sangli Dist Central Co Op Bank Ltd", label: "The Sangli Dist Central Co Op Bank Ltd" },
  { value: "Hutatma Sahakari Bank", label: "Hutatma Sahakari Bank" },
  { value: "Varun Credit Corporation", label: "Varun Credit Corporation" },
  { value: "The Raj Laxmi Mahila Urban Co Op Bank Ltd", label: "The Raj Laxmi Mahila Urban Co Op Bank Ltd" },
  { value: "Bangalore Dist And Bangalore Rural Dist Co Op Central Bank Ltd", label: "Bangalore Dist And Bangalore Rural Dist Co Op Central Bank Ltd" },
  { value: "The A P Vardhaman Mahila Co Op Urban Bank Ltd", label: "The A P Vardhaman Mahila Co Op Urban Bank Ltd" },
  { value: "Shree Savli Nagrik Sahakari Bank Ltd", label: "Shree Savli Nagrik Sahakari Bank Ltd" },
  { value: "Gayathri Bank", label: "Gayathri Bank" },
  { value: "The Inchiany Service Co Op Bank Ltd", label: "The Inchiany Service Co Op Bank Ltd" },
  { value: "Sri Visakha Grmeena Bank", label: "Sri Visakha Grmeena Bank" },
  { value: "The Anantapur Dist Co Op Central Bank Ltd", label: "The Anantapur Dist Co Op Central Bank Ltd" },
  { value: "The Andhra Pradesh State Co Op Bank Ltd", label: "The Andhra Pradesh State Co Op Bank Ltd" },
  { value: "The Boral Union Co Op Bank Ltd", label: "The Boral Union Co Op Bank Ltd" },
  { value: "The Nagrota Bagwan Co Op Non Agri Th And Cr Society Ltd", label: "The Nagrota Bagwan Co Op Non Agri Th And Cr Society Ltd" },
  { value: "Jammu And Kashmir State Co Op Bank Ltd", label: "Jammu And Kashmir State Co Op Bank Ltd" },
  { value: "Kamraz Rural Bank", label: "Kamraz Rural Bank" },
  { value: "Sri Lakshmi Mahila Sahakara Bank Niyamitha", label: "Sri Lakshmi Mahila Sahakara Bank Niyamitha" },
  { value: "Pali Urban Co Op Bank Ltd", label: "Pali Urban Co Op Bank Ltd" },
  { value: "Jaihind Urban Co Op Bank Ltd", label: "Jaihind Urban Co Op Bank Ltd" },
  { value: "Sindhanur Urban Co Op Bank Ltd", label: "Sindhanur Urban Co Op Bank Ltd" },
  { value: "Kolar Gramin Bank", label: "Kolar Gramin Bank" },
  { value: "Haveli Sahakari Bank Ltd", label: "Haveli Sahakari Bank Ltd" },
  { value: "Ashok Finance And Credit Corporation", label: "Ashok Finance And Credit Corporation" },
  { value: "The Womens Co Op Bank Ltd ,Panaji", label: "The Womens Co Op Bank Ltd ,Panaji" },
  { value: "The Nilgiris Dist Central Co Op Bank Ltd", label: "The Nilgiris Dist Central Co Op Bank Ltd" },
  { value: "Visveshvaraya Grameena Bank", label: "Visveshvaraya Grameena Bank" },
  { value: "Bharathiya Sahakara Bank Niyamitha", label: "Bharathiya Sahakara Bank Niyamitha" },
  { value: "Bijapur Mahalaxmi Urban Co Op Bank Ltd", label: "Bijapur Mahalaxmi Urban Co Op Bank Ltd" },
  { value: "Sri Ganesh Co Op Bank Ltd", label: "Sri Ganesh Co Op Bank Ltd" },
  { value: "Shri Vijay Mahantesh Co Op Bank Ltd", label: "Shri Vijay Mahantesh Co Op Bank Ltd" },
  { value: "Veerashaiva Sahakari Bank Ltd", label: "Veerashaiva Sahakari Bank Ltd" },
  { value: "The Namakkal Co Op Urban Bank Ltd", label: "The Namakkal Co Op Urban Bank Ltd" },
  { value: "Shri Bilur Gurubasava Pattin Sahakari Sangha Niyamit", label: "Shri Bilur Gurubasava Pattin Sahakari Sangha Niyamit" },
  { value: "Vyaparik Au Sa Bank Ltd", label: "Vyaparik Au Sa Bank Ltd" },
  { value: "Dharmavir Sambhaji Urban Co Op Bank Ltd", label: "Dharmavir Sambhaji Urban Co Op Bank Ltd" },
  { value: "The Dindigul Central Co Op Bank Ltd", label: "The Dindigul Central Co Op Bank Ltd" },
  { value: "Sampada Sahakari Bank Ltd", label: "Sampada Sahakari Bank Ltd" },
  { value: "The Amravati Zilla Parishad Shikshak Sahakari Bank Ltd", label: "The Amravati Zilla Parishad Shikshak Sahakari Bank Ltd" },
  { value: "Tamilnadu State Co Op Bank Ltd", label: "Tamilnadu State Co Op Bank Ltd" },
  { value: "The Guntur Co Op Urban Bank Ltd", label: "The Guntur Co Op Urban Bank Ltd" },
  { value: "Etawah Distt Co Op Bank Ltd", label: "Etawah Distt Co Op Bank Ltd" },
  { value: "Kolavalloor Service Co Op Bank Ltd", label: "Kolavalloor Service Co Op Bank Ltd" },
  { value: "The Accountant Generals Office Employees Co Op Bank Ltd", label: "The Accountant Generals Office Employees Co Op Bank Ltd" },
  { value: "The Tirupur Co Op Urban Bank Ltd", label: "The Tirupur Co Op Urban Bank Ltd" },
  { value: "Sehore Nagrik Sahakari Bank Ltd", label: "Sehore Nagrik Sahakari Bank Ltd" },
  { value: "Sabarkantha Gandhinagar Gramin Bank", label: "Sabarkantha Gandhinagar Gramin Bank" },
  { value: "Shiva Sahakari Bank Niyamitha", label: "Shiva Sahakari Bank Niyamitha" },
  { value: "Koovappally Service Co Op Bank Ltd", label: "Koovappally Service Co Op Bank Ltd" },
  { value: "The Maravanthuruthu Service Co Op Bank Ltd", label: "The Maravanthuruthu Service Co Op Bank Ltd" },
  { value: "Aligarh Zila Sahkari Bank Ltd", label: "Aligarh Zila Sahkari Bank Ltd" },
  { value: "Surpur Urban Co Op Bank Ltd, Surpur", label: "Surpur Urban Co Op Bank Ltd, Surpur" },
  { value: "Lalbaug Co Op Bank Ltd", label: "Lalbaug Co Op Bank Ltd" },
  { value: "Pawandoot Merchants Pvt Ltd", label: "Pawandoot Merchants Pvt Ltd" },
  { value: "Shree Murugharajendra Co Op Bank Ltd", label: "Shree Murugharajendra Co Op Bank Ltd" },
  { value: "Wardha Urban And Rural Co Op Credit Society Ltd", label: "Wardha Urban And Rural Co Op Credit Society Ltd" },
  { value: "The Pallikkal Service Co Op Bank Ltd", label: "The Pallikkal Service Co Op Bank Ltd" },
  { value: "The Kuttiady Co Op Urban Bank Ltd", label: "The Kuttiady Co Op Urban Bank Ltd" },
  { value: "Eramala Panchayat Vanitha Co Op Society", label: "Eramala Panchayat Vanitha Co Op Society" },
  { value: "Bilaspur Raipur Kshetriya Gramin Bank", label: "Bilaspur Raipur Kshetriya Gramin Bank" },
  { value: "Surguja Kshetriya Gramin Bank", label: "Surguja Kshetriya Gramin Bank" },
  { value: "Govt Of Uttarakhand", label: "Govt Of Uttarakhand" },
  { value: "Vehicles And General Finance Co Ltd", label: "Vehicles And General Finance Co Ltd" },
  { value: "Sri Dasmesh Finance Co", label: "Sri Dasmesh Finance Co" },
  { value: "Chinatrust Commercial Bank", label: "Chinatrust Commercial Bank" },
  { value: "The Umreth Urban Co Operative Bank Ltd", label: "The Umreth Urban Co Operative Bank Ltd" },
  { value: "The Kuttiattoor Panchayath Service Co Op Bank Ltd", label: "The Kuttiattoor Panchayath Service Co Op Bank Ltd" },
  { value: "Shri Yugprabhav Sahakari Bank Ltd", label: "Shri Yugprabhav Sahakari Bank Ltd" },
  { value: "Howrah Gramin Bank", label: "Howrah Gramin Bank" },
  { value: "Nattika Firka Co Op Rural Bank Ltd", label: "Nattika Firka Co Op Rural Bank Ltd" },
  { value: "Innovative Co Op Urban Bank Ltd", label: "Innovative Co Op Urban Bank Ltd" },
  { value: "M.N.S. Bank", label: "M.N.S. Bank" },
  { value: "Pune Cantonment Sahakari Bank Ltd", label: "Pune Cantonment Sahakari Bank Ltd" },
  { value: "Agra Zila Sahkari Bank Ltd", label: "Agra Zila Sahkari Bank Ltd" },
  { value: "Aligarh Gramin Bank", label: "Aligarh Gramin Bank" },
  { value: "The Pazhavangadikara Service Co Op Bank Ltd", label: "The Pazhavangadikara Service Co Op Bank Ltd" },
  { value: "The Malegaon Merchants Co Op Bank Ltd", label: "The Malegaon Merchants Co Op Bank Ltd" },
  { value: "Jaap Finance And Investment P Ltd", label: "Jaap Finance And Investment P Ltd" },
  { value: "Proficient Leasing And Financial Ltd", label: "Proficient Leasing And Financial Ltd" },
  { value: "The Madgaum Urban Co Op Bank Ltd", label: "The Madgaum Urban Co Op Bank Ltd" },
  { value: "The Salem District Central Co Op Bank Ltd", label: "The Salem District Central Co Op Bank Ltd" },
  { value: "Andhra Pragathi Grameena Bank", label: "Andhra Pragathi Grameena Bank" },
  { value: "The Mahendragarh Central Co Op Bank Ltd", label: "The Mahendragarh Central Co Op Bank Ltd" },
  { value: "Vidisha  Bhopal Kshetriya Gramin Bank", label: "Vidisha  Bhopal Kshetriya Gramin Bank" },
  { value: "Vattekkad Service Co Op Bank Ltd", label: "Vattekkad Service Co Op Bank Ltd" },
  { value: "Choundeshwari Sahakari Bank Ltd", label: "Choundeshwari Sahakari Bank Ltd" },
  { value: "Development Co Op Bank Ltd", label: "Development Co Op Bank Ltd" },
  { value: "The Kodiyathoor Service Co Op  Bank Ltd", label: "The Kodiyathoor Service Co Op  Bank Ltd" },
  { value: "Calicut City Co Op Bank Ltd", label: "Calicut City Co Op Bank Ltd" },
  { value: "The Bhatinda Central Co Op Bank Ltd", label: "The Bhatinda Central Co Op Bank Ltd" },
  { value: "Ujjain Nagrik Sahakari Pedhi Mydt", label: "Ujjain Nagrik Sahakari Pedhi Mydt" },
  { value: "Shree Tirupati Nagari Sah Pat Sanstha Maryadit", label: "Shree Tirupati Nagari Sah Pat Sanstha Maryadit" },
  { value: "Kewal Chand  Mishrimall", label: "Kewal Chand  Mishrimall" },
  { value: "Kewal Chand Rajeshkumar Huf", label: "Kewal Chand Rajeshkumar Huf" },
  { value: "Udyam Vikas Sahakari Bank Ltd", label: "Udyam Vikas Sahakari Bank Ltd" },
  { value: "Karnataka Rajya Kaigarika Sahakara Bank Niyamitha", label: "Karnataka Rajya Kaigarika Sahakara Bank Niyamitha" },
  { value: "Sri Sudha Co Op Bank Ltd", label: "Sri Sudha Co Op Bank Ltd" },
  { value: "Vidarbha Merchants Urban Co Op Bank Ltd", label: "Vidarbha Merchants Urban Co Op Bank Ltd" },
  { value: "Chartered Sahakari Bank Niyamitha", label: "Chartered Sahakari Bank Niyamitha" },
  { value: "The Kizhathadiyoor Service Co Op Bank Ltd", label: "The Kizhathadiyoor Service Co Op Bank Ltd" },
  { value: "The Guruvayur Co Op Urban Bank Ltd", label: "The Guruvayur Co Op Urban Bank Ltd" },
  { value: "Sultanpur Zila Sahakari Bank Ltd", label: "Sultanpur Zila Sahakari Bank Ltd" },
  { value: "Rushika Mahila Nagrik Co Op Bank Ltd", label: "Rushika Mahila Nagrik Co Op Bank Ltd" },
  { value: "The Gadhinglaj Urban Co Op Bank Ltd", label: "The Gadhinglaj Urban Co Op Bank Ltd" },
  { value: "The Banaskantha District Central Co Op Bank Ltd", label: "The Banaskantha District Central Co Op Bank Ltd" },
  { value: "The Anickadu Regional Farmers Service Co Op Bank Ltd", label: "The Anickadu Regional Farmers Service Co Op Bank Ltd" },
  { value: "Angadi Service Co Op Bank Ltd", label: "Angadi Service Co Op Bank Ltd" },
  { value: "The Sultans Battery Co Op Urban Bank Ltd", label: "The Sultans Battery Co Op Urban Bank Ltd" },
  { value: "Swatantra Deo Enterpise", label: "Swatantra Deo Enterpise" },
  { value: "Chambal Gwalior Kshetriya Gramin Bank", label: "Chambal Gwalior Kshetriya Gramin Bank" },
  { value: "The Pondicherry State Co Op Bank Ltd", label: "The Pondicherry State Co Op Bank Ltd" },
  { value: "Challakere Taluk Kanaka Pathina Sahakara Sangha Niyamitha", label: "Challakere Taluk Kanaka Pathina Sahakara Sangha Niyamitha" },
  { value: "Sindhudurg Sahakari Bank Ltd", label: "Sindhudurg Sahakari Bank Ltd" },
  { value: "Thane Gramin Bank", label: "Thane Gramin Bank" },
  { value: "Shivalik Mercantile Bank", label: "Shivalik Mercantile Bank" },
  { value: "The Parwanoo Urban Co Op Bank Ltd", label: "The Parwanoo Urban Co Op Bank Ltd" },
  { value: "The Sinor Nagarik Sahakari Bank Ltd", label: "The Sinor Nagarik Sahakari Bank Ltd" },
  { value: "The Balasore Bhadrak Central Co Op Bank Ltd", label: "The Balasore Bhadrak Central Co Op Bank Ltd" },
  { value: "Raigad Sahakari Bank Ltd", label: "Raigad Sahakari Bank Ltd" },
  { value: "Valsad District Central Co Op Bank Ltd", label: "Valsad District Central Co Op Bank Ltd" },
  { value: "Shri Anand Co Op Bank Ltd", label: "Shri Anand Co Op Bank Ltd" },
  { value: "Rameshwar Co O Bank Ltd", label: "Rameshwar Co O Bank Ltd" },
  { value: "The Rajputana Mahila Urban Co Op Bank Ltd", label: "The Rajputana Mahila Urban Co Op Bank Ltd" },
  { value: "The Jamnagar Peoples Co Op Bank Ltd", label: "The Jamnagar Peoples Co Op Bank Ltd" },
  { value: "Bhilwara Urban Co Op Bank Ltd", label: "Bhilwara Urban Co Op Bank Ltd" },
  { value: "Maharashtra Nagari Sahakari Bank Mary", label: "Maharashtra Nagari Sahakari Bank Mary" },
  { value: "Kalyan Urban Co Op Bank Ltd", label: "Kalyan Urban Co Op Bank Ltd" },
  { value: "Sharad Sahakari Bank Ltd", label: "Sharad Sahakari Bank Ltd" },
  { value: "Pune Sahakari Bank Limited", label: "Pune Sahakari Bank Limited" },
  { value: "Krushiseva Urban Co Op Bank Ltd", label: "Krushiseva Urban Co Op Bank Ltd" },
  { value: "Sardar Nagrik Sahakari Bank Maryadit", label: "Sardar Nagrik Sahakari Bank Maryadit" },
  { value: "Kota Mahila Nagrik Sahkari Bank Ltd", label: "Kota Mahila Nagrik Sahkari Bank Ltd" },
  { value: "Jijamata Mahila Sahakari Bank Ltd Pune", label: "Jijamata Mahila Sahakari Bank Ltd Pune" },
  { value: "Eruvessy Service Co Op Bank Ltd", label: "Eruvessy Service Co Op Bank Ltd" },
  { value: "The Kopargaon Peoples Co Op Bank Ltd", label: "The Kopargaon Peoples Co Op Bank Ltd" },
  { value: "The Bharatpur Central Co Op Bank Ltd", label: "The Bharatpur Central Co Op Bank Ltd" },
  { value: "The New Agra Urban Co Op Bank Ltd", label: "The New Agra Urban Co Op Bank Ltd" },
  { value: "Jankalyan Urban Co Op Bank Ltd", label: "Jankalyan Urban Co Op Bank Ltd" },
  { value: "Siddhasiri Souharda Sahakari Ltd", label: "Siddhasiri Souharda Sahakari Ltd" },
  { value: "Nyayamitra Sahakara Bank Niyamita", label: "Nyayamitra Sahakara Bank Niyamita" },
  { value: "The Maharaja Co Op Urban Bank Ltd", label: "The Maharaja Co Op Urban Bank Ltd" },
  { value: "National Mercantile Co Op Bank Ltd", label: "National Mercantile Co Op Bank Ltd" },
  { value: "The Pudukkottai Dist Central Co Op Bank Ltd", label: "The Pudukkottai Dist Central Co Op Bank Ltd" },
  { value: "The Ranchi Khunti Central Co Op Bank Ltd", label: "The Ranchi Khunti Central Co Op Bank Ltd" },
  { value: "Alpha Motor Finance Ltd", label: "Alpha Motor Finance Ltd" },
  { value: "The Cherpalcheri Co Op Urban Bank Ltd", label: "The Cherpalcheri Co Op Urban Bank Ltd" },
  { value: "The Kooroppada Service Co Op Bank Ltd", label: "The Kooroppada Service Co Op Bank Ltd" },
  { value: "Mohan Finance Limited", label: "Mohan Finance Limited" },
  { value: "Basti Gramin Bank", label: "Basti Gramin Bank" },
  { value: "Sagar Gramin Bank", label: "Sagar Gramin Bank" },
  { value: "Samatha Mahila Co Op Urbank Bank Ltd", label: "Samatha Mahila Co Op Urbank Bank Ltd" },
  { value: "Muzaffarnagar District Co Op Bank Ltd", label: "Muzaffarnagar District Co Op Bank Ltd" },
  { value: "The Valapad Service Co Op Bank Ltd", label: "The Valapad Service Co Op Bank Ltd" },
  { value: "The Mumbai Mahanagarpalika Shikshan Vibhag Sahakari Bank Ltd", label: "The Mumbai Mahanagarpalika Shikshan Vibhag Sahakari Bank Ltd" },
  { value: "Priyadarshani Nagari Sahakari Bank Ltd", label: "Priyadarshani Nagari Sahakari Bank Ltd" },
  { value: "Surat Bharuch Gramin Bank", label: "Surat Bharuch Gramin Bank" },
  { value: "Sri Srishaila Credit Co Op Society Ltd", label: "Sri Srishaila Credit Co Op Society Ltd" },
  { value: "The Hanumanthanagar Co Op Bank Ltd", label: "The Hanumanthanagar Co Op Bank Ltd" },
  { value: "The Satara District Central Co Op Bank", label: "The Satara District Central Co Op Bank" },
  { value: "Kota Nagrik Sahkari Bank Ltd", label: "Kota Nagrik Sahkari Bank Ltd" },
  { value: "Centurion Bank Of Punjab Ltd", label: "Centurion Bank Of Punjab Ltd" },
  { value: "The Vaijapur Merchants Co Op Bank Ltd", label: "The Vaijapur Merchants Co Op Bank Ltd" },
  { value: "The Bayad Nagarik Sahakari Bank Ltd", label: "The Bayad Nagarik Sahakari Bank Ltd" },
  { value: "Urban Co Op Bank Ltd", label: "Urban Co Op Bank Ltd" },
  { value: "Shivalik Mercantile Co Op Bank Ltd", label: "Shivalik Mercantile Co Op Bank Ltd" },
  { value: "Madheshwari Urban Development Co Op Bank Ltd", label: "Madheshwari Urban Development Co Op Bank Ltd" },
  { value: "The Davangere Harihar Urban Sahakara Bank Niyamitha Davangere", label: "The Davangere Harihar Urban Sahakara Bank Niyamitha Davangere" },
  { value: "The Gauhati Co Op Urban Bank Ltd", label: "The Gauhati Co Op Urban Bank Ltd" },
  { value: "Shrimant Malojiraje Sahakari Bank Ltd", label: "Shrimant Malojiraje Sahakari Bank Ltd" },
  { value: "Markandey Nagri Sahakari Bank Ltd", label: "Markandey Nagri Sahakari Bank Ltd" },
  { value: "Karnataka Souharda Pattina Sahakari Niyamita Bijapur", label: "Karnataka Souharda Pattina Sahakari Niyamita Bijapur" },
  { value: "The Tirur Urban Co Op Bank Ltd", label: "The Tirur Urban Co Op Bank Ltd" },
  { value: "The Buldana District Central Co Op Bank Ltd", label: "The Buldana District Central Co Op Bank Ltd" },
  { value: "Koyna Sahakari Bank Ltd", label: "Koyna Sahakari Bank Ltd" },
  { value: "Satabdi Finlease Pvt Ltd", label: "Satabdi Finlease Pvt Ltd" },
  { value: "Pravara Sahakari Bank Ltd", label: "Pravara Sahakari Bank Ltd" },
  { value: "The Tanalur Service Co Opbank Ltd", label: "The Tanalur Service Co Opbank Ltd" },
  { value: "The Tirur Service Coop Bank Ltd", label: "The Tirur Service Coop Bank Ltd" },
  { value: "The Panathady Service Co Op Bank Ltd", label: "The Panathady Service Co Op Bank Ltd" },
  { value: "Mannapuram Finance General Finance And General Leasing Ltd", label: "Mannapuram Finance General Finance And General Leasing Ltd" },
  { value: "The Maharashtra Mantralaya And Allied Offices Co Op Bank Ltd", label: "The Maharashtra Mantralaya And Allied Offices Co Op Bank Ltd" },
  { value: "Allahabad District Co Op Bank Ltd", label: "Allahabad District Co Op Bank Ltd" },
  { value: "Mansarovar Urban Co Op Bank Ltd", label: "Mansarovar Urban Co Op Bank Ltd" },
  { value: "Janata Sahakari Bank Ltd Pune", label: "Janata Sahakari Bank Ltd Pune" },
  { value: "Eloor Service Co Op Bank Ltd", label: "Eloor Service Co Op Bank Ltd" },
  { value: "The Taliparamba Co Op Urban Bank Ltd", label: "The Taliparamba Co Op Urban Bank Ltd" },
  { value: "Rajmata Urban Co Op Bank Ltd", label: "Rajmata Urban Co Op Bank Ltd" },
  { value: "The Citizens Urban Co Op Bank Ltd", label: "The Citizens Urban Co Op Bank Ltd" },
  { value: "Swarna Bharathi Sahakara Bank Niyamitha", label: "Swarna Bharathi Sahakara Bank Niyamitha" },
  { value: "The Madurai Dist Central Co Op Bank Ltd", label: "The Madurai Dist Central Co Op Bank Ltd" },
  { value: "Karassery Service Co Op Bank Ltd", label: "Karassery Service Co Op Bank Ltd" },
  { value: "Shree Laxmi Mahila Sahakai Bank Ltd", label: "Shree Laxmi Mahila Sahakai Bank Ltd" },
  { value: "Satpura Kshetriya Gramin Bank", label: "Satpura Kshetriya Gramin Bank" },
  { value: "The Sikar Kendriya Sahakari Bank Ltd", label: "The Sikar Kendriya Sahakari Bank Ltd" },
  { value: "Vaishali Urban Co Op Bank Ltd", label: "Vaishali Urban Co Op Bank Ltd" },
  { value: "Amar Financiers Regd", label: "Amar Financiers Regd" },
  { value: "Zilla Rani Channamma Mahila Sahakari Bank Niyamit", label: "Zilla Rani Channamma Mahila Sahakari Bank Niyamit" },
  { value: "The Pattikkad Service Co Op Bank Ltd", label: "The Pattikkad Service Co Op Bank Ltd" },
  { value: "The Ferozepur Central Co Op Bank Ltd", label: "The Ferozepur Central Co Op Bank Ltd" },
  { value: "Nicholson Co Op Urban Bank Ltd", label: "Nicholson Co Op Urban Bank Ltd" },
  { value: "Shri Gajanan Nagari Sahakari Bank Ltd", label: "Shri Gajanan Nagari Sahakari Bank Ltd" },
  { value: "Nawanshahr Central Co Op Bank Ltd", label: "Nawanshahr Central Co Op Bank Ltd" },
  { value: "Shree Patneshwar Urban Co Op Bank Ltd", label: "Shree Patneshwar Urban Co Op Bank Ltd" },
  { value: "The Bharat Co Op Bank Mumbai Ltd", label: "The Bharat Co Op Bank Mumbai Ltd" },
  { value: "Raigarh Kshetriya Gramin Bank", label: "Raigarh Kshetriya Gramin Bank" },
  { value: "Pratapgarh Kshetriya Gramin Bank", label: "Pratapgarh Kshetriya Gramin Bank" },
  { value: "Navabharat Bank", label: "Navabharat Bank" },
  { value: "Prasad Enterprises", label: "Prasad Enterprises" },
  { value: "Jamnagar Mahila Sahkari Bank Ltd", label: "Jamnagar Mahila Sahkari Bank Ltd" },
  { value: "Co Op Bank Of Baroda", label: "Co Op Bank Of Baroda" },
  { value: "The Kodinar Nagrik Sahakari Bank Ltd", label: "The Kodinar Nagrik Sahakari Bank Ltd" },
  { value: "Ajinkyatara Mahila Sahakari Bank", label: "Ajinkyatara Mahila Sahakari Bank" },
  { value: "Jaysingpur Udgaon Sahakari Bank Ltd", label: "Jaysingpur Udgaon Sahakari Bank Ltd" },
  { value: "The Ranga Reddy Co Op Urban Bank Ltd", label: "The Ranga Reddy Co Op Urban Bank Ltd" },
  { value: "Baltikuri Co Op Bank Ltd", label: "Baltikuri Co Op Bank Ltd" },
  { value: "Devipatan Kshetriya Gramin Bank", label: "Devipatan Kshetriya Gramin Bank" },
  { value: "Bihar Kshetriya Gramin Bank", label: "Bihar Kshetriya Gramin Bank" },
  { value: "The Faiz Mercantile Co Op Bank Ltd", label: "The Faiz Mercantile Co Op Bank Ltd" },
  { value: "Jabalpur Mahila Nagrik Sahakari Bank Ltd", label: "Jabalpur Mahila Nagrik Sahakari Bank Ltd" },
  { value: "Ahmednagar Shahar Sahakari Bank Ltd", label: "Ahmednagar Shahar Sahakari Bank Ltd" },
  { value: "The Grain Merchants Co Op Bank Ltd", label: "The Grain Merchants Co Op Bank Ltd" },
  { value: "Karnataka State Industrial Co Op Bank Ltd", label: "Karnataka State Industrial Co Op Bank Ltd" },
  { value: "The Chaitanya Co Op Urban Bank Ltd", label: "The Chaitanya Co Op Urban Bank Ltd" },
  { value: "Commercial Motor And General Finance Co", label: "Commercial Motor And General Finance Co" },
  { value: "The Chittoor District Co Op Central Bank Limited", label: "The Chittoor District Co Op Central Bank Limited" },
  { value: "Prince Motor Finance Co Ltd", label: "Prince Motor Finance Co Ltd" },
  { value: "Shahjahanpur Kshetriya Gramin Bank", label: "Shahjahanpur Kshetriya Gramin Bank" },
  { value: "The Manganam Service Co Op Bank Ltd", label: "The Manganam Service Co Op Bank Ltd" },
  { value: "Baran Nagrik Sahakari Bank Ltd", label: "Baran Nagrik Sahakari Bank Ltd" },
  { value: "The Palakkad District Co Op Bank Ltd", label: "The Palakkad District Co Op Bank Ltd" },
  { value: "The Revdanda Co Op Urban Bank Ltd", label: "The Revdanda Co Op Urban Bank Ltd" },
  { value: "The Chelembra Co Op Urban Bank Ul Ltd", label: "The Chelembra Co Op Urban Bank Ul Ltd" },
  { value: "The Triprangode Service Co Op Bank", label: "The Triprangode Service Co Op Bank" },
  { value: "The Khurda Central Co Op Bank Ltd", label: "The Khurda Central Co Op Bank Ltd" },
  { value: "Singh Motor And Finance Co", label: "Singh Motor And Finance Co" },
  { value: "The United Puri Nimapara Central Co Op Bank Ltd", label: "The United Puri Nimapara Central Co Op Bank Ltd" },
  { value: "Astha Mahila Nagrik Sahakari Bank Mydt", label: "Astha Mahila Nagrik Sahakari Bank Mydt" },
  { value: "Tatpar Resources Pvt Ltd", label: "Tatpar Resources Pvt Ltd" },
  { value: "Shree Samaratha Nagari Co Op Society Ltd. Shegaon", label: "Shree Samaratha Nagari Co Op Society Ltd. Shegaon" },
  { value: "The Mehkar Urban Co Op Bank Ltd", label: "The Mehkar Urban Co Op Bank Ltd" },
  { value: "Jai Hind Co Op Bank Ltd Ambernath", label: "Jai Hind Co Op Bank Ltd Ambernath" },
  { value: "Tuli Finance Company", label: "Tuli Finance Company" },
  { value: "Ratanchand Shah Sahakari Bank Ltd", label: "Ratanchand Shah Sahakari Bank Ltd" },
  { value: "Shri Gajanan Maharaj Urban Co Op Bank Ltd", label: "Shri Gajanan Maharaj Urban Co Op Bank Ltd" },
  { value: "The Bardoli Nagrik Sahakari Bank Ltd", label: "The Bardoli Nagrik Sahakari Bank Ltd" },
  { value: "Alaknanda Gramin Bank", label: "Alaknanda Gramin Bank" },
  { value: "Gaur Gramin Bank", label: "Gaur Gramin Bank" },
  { value: "The Sundargarh District Central Co Op Bank Ltd", label: "The Sundargarh District Central Co Op Bank Ltd" },
  { value: "Amreli Jilla Madhyasth Sahakari Bank Ltd", label: "Amreli Jilla Madhyasth Sahakari Bank Ltd" },
  { value: "The Mysore Silk Cloth Merchants Co Op Bank Ltd", label: "The Mysore Silk Cloth Merchants Co Op Bank Ltd" },
  { value: "Mahishmati Nagrik Sahakari Bank Ltd Mandla", label: "Mahishmati Nagrik Sahakari Bank Ltd Mandla" },
  { value: "Mullankolly Service Co Op Bank Ltd", label: "Mullankolly Service Co Op Bank Ltd" },
  { value: "The Meenachil Service Co Op Bank Ltd", label: "The Meenachil Service Co Op Bank Ltd" },
  { value: "Murshidabad District Central Co Op Bank Ltd", label: "Murshidabad District Central Co Op Bank Ltd" },
  { value: "The Davangere Dist Central Co Op Bank Ltd", label: "The Davangere Dist Central Co Op Bank Ltd" },
  { value: "Juneja Finance Company", label: "Juneja Finance Company" },
  { value: "Gokul Co Op Urban Bank Ltd", label: "Gokul Co Op Urban Bank Ltd" },
  { value: "The Erumad Primary Agrl Co Op Bank Ltd", label: "The Erumad Primary Agrl Co Op Bank Ltd" },
  { value: "Ashokanagar Co Op Bank Ltd", label: "Ashokanagar Co Op Bank Ltd" },
  { value: "Srimatha Mahila Sahakari Bank Niyamitha", label: "Srimatha Mahila Sahakari Bank Niyamitha" },
  { value: "Jodhpur Nagrik Saha Kari Bank Ltd", label: "Jodhpur Nagrik Saha Kari Bank Ltd" },
  { value: "Marady Service Co Op Bank Ltd", label: "Marady Service Co Op Bank Ltd" },
  { value: "The Waghodia Urban Co Op Bank Ltd", label: "The Waghodia Urban Co Op Bank Ltd" },
  { value: "The Tirunelveli District Central Co Op Bank Ltd", label: "The Tirunelveli District Central Co Op Bank Ltd" },
  { value: "The Secunderabad Co Op Urban Bank Ltd", label: "The Secunderabad Co Op Urban Bank Ltd" },
  { value: "Janseva Sahakari Bank Ltd", label: "Janseva Sahakari Bank Ltd" },
  { value: "Umiya Urban Co Op Bank Mydt", label: "Umiya Urban Co Op Bank Mydt" },
  { value: "Antwerp Diamond Bank", label: "Antwerp Diamond Bank" },
  { value: "The South Indian Bank Ltd", label: "The South Indian Bank Ltd" },
  { value: "Shree Narayana Guru Co Op Bank Ltd", label: "Shree Narayana Guru Co Op Bank Ltd" },
  { value: "Vikas Urban Co Op Bank Niyamitha", label: "Vikas Urban Co Op Bank Niyamitha" },
  { value: "The Mulakuzha Service Co Op Bank Ltd", label: "The Mulakuzha Service Co Op Bank Ltd" },
  { value: "Pala Urban Co Op Bank Ltd", label: "Pala Urban Co Op Bank Ltd" },
  { value: "Ramgarhia Co Op Bank Ltd", label: "Ramgarhia Co Op Bank Ltd" },
  { value: "Trithala Block Co Opurban Credit Society Ltd", label: "Trithala Block Co Opurban Credit Society Ltd" },
  { value: "The Karnataka Co Op Bank Ltd", label: "The Karnataka Co Op Bank Ltd" },
  { value: "The Vardhman Co Op Bank Ltd", label: "The Vardhman Co Op Bank Ltd" },
  { value: "Bhagyodaya Friends Urban Co Op Bank Ltd", label: "Bhagyodaya Friends Urban Co Op Bank Ltd" },
  { value: "The Koodali Service Co Op Bank Ltd", label: "The Koodali Service Co Op Bank Ltd" },
  { value: "The Nicholson Co Op Town Bank", label: "The Nicholson Co Op Town Bank" },
  { value: "Harihareshwar Sahakari Bank Ltd Wai", label: "Harihareshwar Sahakari Bank Ltd Wai" },
  { value: "Indrayani Co Op Bank Ltd", label: "Indrayani Co Op Bank Ltd" },
  { value: "Ode Urben Co Op Bank Ltd", label: "Ode Urben Co Op Bank Ltd" },
  { value: "Nagar Sahkari Bank Ltd", label: "Nagar Sahkari Bank Ltd" },
  { value: "The Nandurbar Merchants Co Op Bank Ltd", label: "The Nandurbar Merchants Co Op Bank Ltd" },
  { value: "The Azhicode Service Co Op Bank Ltd", label: "The Azhicode Service Co Op Bank Ltd" },
  { value: "The Gandhi Co Op Urban Bank Ltd", label: "The Gandhi Co Op Urban Bank Ltd" },
  { value: "Bhilwara Mahila Urban Co Op Bank Ltd", label: "Bhilwara Mahila Urban Co Op Bank Ltd" },
  { value: "Ajay Finance", label: "Ajay Finance" },
  { value: "Rajgarh Sehore Kshetriya Gramin Bank", label: "Rajgarh Sehore Kshetriya Gramin Bank" },
  { value: "The Mattancherry Mahajanik Co Op Urban Bank Ltd", label: "The Mattancherry Mahajanik Co Op Urban Bank Ltd" },
  { value: "Perumbavoor Urban Co Op Bank Ltd", label: "Perumbavoor Urban Co Op Bank Ltd" },
  { value: "The Alwaye Urban Co Op Bank Ltd", label: "The Alwaye Urban Co Op Bank Ltd" },
  { value: "Sree Narayan Guru Co Op Bank Ltd", label: "Sree Narayan Guru Co Op Bank Ltd" },
  { value: "The Cuddalore District Central Co Op Bank Ltd", label: "The Cuddalore District Central Co Op Bank Ltd" },
  { value: "The Wayanad District Co Op Bank Ltd", label: "The Wayanad District Co Op Bank Ltd" },
  { value: "Palluruthy Madalam Service Co Op Bank", label: "Palluruthy Madalam Service Co Op Bank" },
  { value: "The Radhasoami Urban Co Op Bank Ltd", label: "The Radhasoami Urban Co Op Bank Ltd" },
  { value: "The Mukkom Service Co Op Bank Ltd", label: "The Mukkom Service Co Op Bank Ltd" },
  { value: "Krishna Mercantile Co Op Bank Ltd", label: "Krishna Mercantile Co Op Bank Ltd" },
  { value: "Chitradurga Gramin Bank", label: "Chitradurga Gramin Bank" },
  { value: "Valakom Service Co Op Bank Ltd", label: "Valakom Service Co Op Bank Ltd" },
  { value: "Tarapur Co Op Urban Bank Ltd", label: "Tarapur Co Op Urban Bank Ltd" },
  { value: "Jamshedpur Urban Co Op Bank Ltd", label: "Jamshedpur Urban Co Op Bank Ltd" },
  { value: "Subhadra Local Area Bank Ltd", label: "Subhadra Local Area Bank Ltd" },
  { value: "The Tarn Taran Central Co Op Bank Ltd", label: "The Tarn Taran Central Co Op Bank Ltd" },
  { value: "Patdi Nagarik Sahakari Bank Ltd", label: "Patdi Nagarik Sahakari Bank Ltd" },
  { value: "The Vazhoor Farmers Service Co Op Bank Ltd", label: "The Vazhoor Farmers Service Co Op Bank Ltd" },
  { value: "Anil Auto Finance P Ltd", label: "Anil Auto Finance P Ltd" },
  { value: "The District Co Op Central Bank Ltd,Kurnool", label: "The District Co Op Central Bank Ltd,Kurnool" },
  { value: "The Wai Urban Co Op Bank Ltd", label: "The Wai Urban Co Op Bank Ltd" },
  { value: "The Kaira District Central Co Op Bank Ltd", label: "The Kaira District Central Co Op Bank Ltd" },
  { value: "The Akola District Central Co Op Bank Ltd Akola", label: "The Akola District Central Co Op Bank Ltd Akola" },
  { value: "The Umayattukara Service Co Op Bank Ltd", label: "The Umayattukara Service Co Op Bank Ltd" },
  { value: "Chaitanya Urban Co Op Bank Ltd", label: "Chaitanya Urban Co Op Bank Ltd" },
  { value: "The Chandgad Urban Co Op Bank Ltd", label: "The Chandgad Urban Co Op Bank Ltd" },
  { value: "Purasawalkam Co Op Bank Ltd", label: "Purasawalkam Co Op Bank Ltd" },
  { value: "The Town Co Op Bank Ltd", label: "The Town Co Op Bank Ltd" },
  { value: "Daivagna Credit Co Op Society Ltd", label: "Daivagna Credit Co Op Society Ltd" },
  { value: "Indore Ujjain Kshetriya Gramin Bank", label: "Indore Ujjain Kshetriya Gramin Bank" },
  { value: "The Jalna Dist Central Co Op Bank Ltd", label: "The Jalna Dist Central Co Op Bank Ltd" },
  { value: "Government Of Gujarat", label: "Government Of Gujarat" },
  { value: "Erattupetta Service Co Op Bank Ltd", label: "Erattupetta Service Co Op Bank Ltd" },
  { value: "Independence Co Op Bank Ltd", label: "Independence Co Op Bank Ltd" },
  { value: "The Hospet Co Op City Bank Ltd", label: "The Hospet Co Op City Bank Ltd" },
  { value: "Ananda Co Op Bank Ltd", label: "Ananda Co Op Bank Ltd" },
  { value: "Cauvery Grameena Bank", label: "Cauvery Grameena Bank" },
  { value: "The Mysore And Chamarajanagar District Co Op Central Bank Ltd", label: "The Mysore And Chamarajanagar District Co Op Central Bank Ltd" },
  { value: "The Kallar Service Co Op Bank Ltd", label: "The Kallar Service Co Op Bank Ltd" },
  { value: "The Jalgaon Peoples Co Op Bank Ltd", label: "The Jalgaon Peoples Co Op Bank Ltd" },
  { value: "Kasara Credit Corporation", label: "Kasara Credit Corporation" },
  { value: "The Chandwad Merchants Co Op Bank Ltd", label: "The Chandwad Merchants Co Op Bank Ltd" },
  { value: "Thiruvarppu Village Service Co Operative Bank Ltd", label: "Thiruvarppu Village Service Co Operative Bank Ltd" },
  { value: "Mahabir Credit Corporation", label: "Mahabir Credit Corporation" },
  { value: "The Kottapadi Service Co Op Bank Ltd", label: "The Kottapadi Service Co Op Bank Ltd" },
  { value: "Ksara Credit Corporation", label: "Ksara Credit Corporation" },
  { value: "Daivadnya Sahakara Bank Niyamit", label: "Daivadnya Sahakara Bank Niyamit" },
  { value: "Etah Distt Co Op Bank Ltd", label: "Etah Distt Co Op Bank Ltd" },
  { value: "The Erode Co Op Urban Bank Ltd", label: "The Erode Co Op Urban Bank Ltd" },
  { value: "The Ludhiana Central Co Op Bank Ltd", label: "The Ludhiana Central Co Op Bank Ltd" },
  { value: "Ranni Service Co Op Bank Ltd", label: "Ranni Service Co Op Bank Ltd" },
  { value: "See Anantha Grameena Bank", label: "See Anantha Grameena Bank" },
  { value: "Dakshina Kannada Jilla Mahila Co Op Bank", label: "Dakshina Kannada Jilla Mahila Co Op Bank" },
  { value: "Bhatpara Naihati Co Op Bank Ltd", label: "Bhatpara Naihati Co Op Bank Ltd" },
  { value: "Raigarh Nagrik Sahkari Bank Ltd", label: "Raigarh Nagrik Sahkari Bank Ltd" },
  { value: "Etah Gramin Bank", label: "Etah Gramin Bank" },
  { value: "The Gandhi Gunj Co Op Bank Ltd", label: "The Gandhi Gunj Co Op Bank Ltd" },
  { value: "The Jamkhandi Urban Co Op Bank Ltd", label: "The Jamkhandi Urban Co Op Bank Ltd" },
  { value: "Krishna Bhima Samruddhi Local Area Bank Ltd", label: "Krishna Bhima Samruddhi Local Area Bank Ltd" },
  { value: "The Alwar Central Co Op Bank Ltd", label: "The Alwar Central Co Op Bank Ltd" },
  { value: "The Chalakudy Town Co Op Bank", label: "The Chalakudy Town Co Op Bank" },
  { value: "The Moga Central Co Op Bank Ltd", label: "The Moga Central Co Op Bank Ltd" },
  { value: "Pallavan Grama Bank", label: "Pallavan Grama Bank" },
  { value: "The Swarna Co Op Urban Bank Ltd", label: "The Swarna Co Op Urban Bank Ltd" },
  { value: "Krishna Pattan Sahakari Bank N", label: "Krishna Pattan Sahakari Bank N" },
  { value: "Sri Sai Sales And Services", label: "Sri Sai Sales And Services" },
  { value: "Harish Chandra Singh And Co", label: "Harish Chandra Singh And Co" },
  { value: "The Halol Urban Co Op Bank Ltd", label: "The Halol Urban Co Op Bank Ltd" },
  { value: "The Dadasaheb Ramrao Patil Co Op Bank Ltd", label: "The Dadasaheb Ramrao Patil Co Op Bank Ltd" },
  { value: "Golconda Grameena Bank", label: "Golconda Grameena Bank" },
  { value: "Kamala Co Op Bank Ltd", label: "Kamala Co Op Bank Ltd" },
  { value: "The Meghalaya Co Op Apex Bank Ltd", label: "The Meghalaya Co Op Apex Bank Ltd" },
  { value: "The Vaish Co Op Commercial Bank Ltd", label: "The Vaish Co Op Commercial Bank Ltd" },
  { value: "National Urban Co Op Bank Ltd", label: "National Urban Co Op Bank Ltd" },
  { value: "Fatehpur Kshetriya Gramin Bank", label: "Fatehpur Kshetriya Gramin Bank" },
  { value: "The Pochampally Co Op Urban Bank Ltd", label: "The Pochampally Co Op Urban Bank Ltd" },
  { value: "Shriram Transport Finance Company Ltd", label: "Shriram Transport Finance Company Ltd" },
  { value: "Indira Shramik Mahila Nagari Shakari Bank Niy", label: "Indira Shramik Mahila Nagari Shakari Bank Niy" },
  { value: "Capital One Multipurpose Co Op Society Ltd", label: "Capital One Multipurpose Co Op Society Ltd" },
  { value: "Jalpaiguri Central Co Op Bank Ltd", label: "Jalpaiguri Central Co Op Bank Ltd" },
  { value: "The Hindustan Co Op Credit Society Ltd", label: "The Hindustan Co Op Credit Society Ltd" },
  { value: "Shri Chatrapati Shivaji Maharaj Sahakari Bank Niyamita", label: "Shri Chatrapati Shivaji Maharaj Sahakari Bank Niyamita" },
  { value: "Nannambra Service Co Op Bank Ltd", label: "Nannambra Service Co Op Bank Ltd" },
  { value: "The Parappanangadi Co Op Service Bank Ltd", label: "The Parappanangadi Co Op Service Bank Ltd" },
  { value: "The Cheruvathur Farmers Service Co Op Bank Ltd", label: "The Cheruvathur Farmers Service Co Op Bank Ltd" },
  { value: "Mahatma Fule Dist Urban Co Op Bank Ltd", label: "Mahatma Fule Dist Urban Co Op Bank Ltd" },
  { value: "Aloor Service Co Op Bank Ltd", label: "Aloor Service Co Op Bank Ltd" },
  { value: "The Cuttack Credit Co Op Society Ltd", label: "The Cuttack Credit Co Op Society Ltd" },
  { value: "Sainik Sahakari Bank Ltd", label: "Sainik Sahakari Bank Ltd" },
  { value: "Ghazipur Urban Co Op Bank Ltd", label: "Ghazipur Urban Co Op Bank Ltd" },
  { value: "Karad Merchant Sahakari Credit Sanstha Maryadit Karad", label: "Karad Merchant Sahakari Credit Sanstha Maryadit Karad" },
  { value: "Centurion Bank Ltd", label: "Centurion Bank Ltd" },
  { value: "Tumkur Veerashaiva Co Op Bank Ltd", label: "Tumkur Veerashaiva Co Op Bank Ltd" },
  { value: "The Mannarkkad Rural Service Co Op Bank Ltd", label: "The Mannarkkad Rural Service Co Op Bank Ltd" },
  { value: "Maratha Co Op Bank Ltd Belgaum", label: "Maratha Co Op Bank Ltd Belgaum" },
  { value: "The Sanghamitra Co Op Urban Bank Ltd", label: "The Sanghamitra Co Op Urban Bank Ltd" },
  { value: "Ranga Reddy Bank", label: "Ranga Reddy Bank" },
  { value: "Sri Channabasavaswamy Souhardha Pattana Sahakari Bank Niyamit", label: "Sri Channabasavaswamy Souhardha Pattana Sahakari Bank Niyamit" },
  { value: "Chittorgarh Kendriya Sahakari Bank Ltd", label: "Chittorgarh Kendriya Sahakari Bank Ltd" },
  { value: "Puduvai Bharathiar Grama Bank", label: "Puduvai Bharathiar Grama Bank" },
  { value: "Madhya Bihar Gramin Bank", label: "Madhya Bihar Gramin Bank" },
  { value: "Sri Sharadamba Mahila Co Op Urban Bank Ltd", label: "Sri Sharadamba Mahila Co Op Urban Bank Ltd" },
  { value: "The Sathamba Peoples Co Op Bank Ltd", label: "The Sathamba Peoples Co Op Bank Ltd" },
  { value: "Sarsa Peoples Co Op Bank Ltd", label: "Sarsa Peoples Co Op Bank Ltd" },
  { value: "Sahyadri Gramin Bank", label: "Sahyadri Gramin Bank" },
  { value: "Kanjirappally Central Service Co Op Bank Ltd", label: "Kanjirappally Central Service Co Op Bank Ltd" },
  { value: "The Charada Nagarik Sahakari Bank Ltd", label: "The Charada Nagarik Sahakari Bank Ltd" },
  { value: "Muzaffarnagar Kshetriya Gramin Bank", label: "Muzaffarnagar Kshetriya Gramin Bank" },
  { value: "The Thazhekode Service Co Op Bank Ltd", label: "The Thazhekode Service Co Op Bank Ltd" },
  { value: "Sir M. Vishweshwaraiah Sahakar Bank Niyamitha", label: "Sir M. Vishweshwaraiah Sahakar Bank Niyamitha" },
  { value: "Sahara Alpasankyatara Vividodesha Sahakara Sangha Niyamitha", label: "Sahara Alpasankyatara Vividodesha Sahakara Sangha Niyamitha" },
  { value: "Punnad Service Co Op Bank Ltd", label: "Punnad Service Co Op Bank Ltd" },
  { value: "The Kolacheri Service Co Op Bank Ltd", label: "The Kolacheri Service Co Op Bank Ltd" },
  { value: "Utkal Gramya Bank", label: "Utkal Gramya Bank" },
  { value: "Chaitanya Godavari Grameena Bank", label: "Chaitanya Godavari Grameena Bank" },
  { value: "The Madura Sourashtra Co Op Bank Ltd", label: "The Madura Sourashtra Co Op Bank Ltd" },
  { value: "Junagadh Vibhagiya Nagrik Sahakari Bank Ltd", label: "Junagadh Vibhagiya Nagrik Sahakari Bank Ltd" },
  { value: "Sri Saraswathi Grameena Bank", label: "Sri Saraswathi Grameena Bank" },
  { value: "Sonali Bank", label: "Sonali Bank" },
  { value: "The Halol Mercantile Co Op Bank Ltd", label: "The Halol Mercantile Co Op Bank Ltd" },
  { value: "The Janata Commercial Co Op Bank Ltd", label: "The Janata Commercial Co Op Bank Ltd" },
  { value: "Koraput Panchabati Gramya Bank", label: "Koraput Panchabati Gramya Bank" },
  { value: "Trivandrum Co Op Urban Bank Ltd", label: "Trivandrum Co Op Urban Bank Ltd" },
  { value: "Naduvannur Regional Co Op Bank Ltd", label: "Naduvannur Regional Co Op Bank Ltd" },
  { value: "Koteshwara Sahakari Bank Niyamitha", label: "Koteshwara Sahakari Bank Niyamitha" },
  { value: "The Palanpur Peoples Co Op Bank Ltd", label: "The Palanpur Peoples Co Op Bank Ltd" },
  { value: "Samastipur Kshetriya Gramin Bank", label: "Samastipur Kshetriya Gramin Bank" },
  { value: "The Kolar District Co Op Central Bank Ltd", label: "The Kolar District Co Op Central Bank Ltd" },
  { value: "Shubhlakshmi Mahila Co Op Bank Ltd", label: "Shubhlakshmi Mahila Co Op Bank Ltd" },
  { value: "Giran And Sons", label: "Giran And Sons" },
  { value: "The Karjan Nagrik Sahakari Bank Ltd", label: "The Karjan Nagrik Sahakari Bank Ltd" },
  { value: "Seven Hills Co Operative Urban Bank Ltd", label: "Seven Hills Co Operative Urban Bank Ltd" },
  { value: "Chaintanya Mahila Sahakari Bank Niyamith", label: "Chaintanya Mahila Sahakari Bank Niyamith" },
  { value: "The Vita Merchants Co Operative Bank Ltd", label: "The Vita Merchants Co Operative Bank Ltd" },
  { value: "Rani Laxmibai Urban Co Operative Bank Ltd", label: "Rani Laxmibai Urban Co Operative Bank Ltd" },
  { value: "Bhingar Urban Co Op Bank Ltd", label: "Bhingar Urban Co Op Bank Ltd" },
  { value: "The Mercantile Co Op Bank Ltd", label: "The Mercantile Co Op Bank Ltd" },
  { value: "The Income Tax Dept Co Op Bank Ltd", label: "The Income Tax Dept Co Op Bank Ltd" },
  { value: "The Kuthuparamba Co Op Rural Bank Ltd", label: "The Kuthuparamba Co Op Rural Bank Ltd" },
  { value: "Sri Sathavahana Grameena Bank", label: "Sri Sathavahana Grameena Bank" },
  { value: "The Calicut Co Operative Urban Bank Ltd", label: "The Calicut Co Operative Urban Bank Ltd" },
  { value: "State Bank Of Bikaner And Jaipur", label: "State Bank Of Bikaner And Jaipur" },
  { value: "The Viramgam Mer Co Op Bank Ltd, Viramgam", label: "The Viramgam Mer Co Op Bank Ltd, Viramgam" },
  { value: "Kotak Mahindra Bank", label: "Kotak Mahindra Bank" },
  { value: "Bardhaman Gramin Bank", label: "Bardhaman Gramin Bank" },
  { value: "Manappuram Asset Finance", label: "Manappuram Asset Finance" },
  { value: "Sri Ambabhavani Urban Co Op Bank Ltd", label: "Sri Ambabhavani Urban Co Op Bank Ltd" },
  { value: "Alavi Co Op Bank Ltd", label: "Alavi Co Op Bank Ltd" },
  { value: "Manjira Grameena Bank", label: "Manjira Grameena Bank" },
  { value: "Shri Beereshwar Souhard Credit Sahakari Ltd", label: "Shri Beereshwar Souhard Credit Sahakari Ltd" },
  { value: "The Vijapur Nagarik Sahakari Bank Ltd", label: "The Vijapur Nagarik Sahakari Bank Ltd" },
  { value: "Unjha Nagarik Sahakari Bank Ltd", label: "Unjha Nagarik Sahakari Bank Ltd" },
  { value: "Shri Bharat Urban Co Op Bank Ltd", label: "Shri Bharat Urban Co Op Bank Ltd" },
  { value: "Ambala Kurukshetra Gramin Bank", label: "Ambala Kurukshetra Gramin Bank" },
  { value: "Rohit Enterprises", label: "Rohit Enterprises" },
  { value: "Perintalmanna Service Co Operative Bank Ltd", label: "Perintalmanna Service Co Operative Bank Ltd" },
  { value: "The Nediyiruppu Service Co Operative Bank Ltd", label: "The Nediyiruppu Service Co Operative Bank Ltd" },
  { value: "The Areacode Service Co Operative Bank Ltd", label: "The Areacode Service Co Operative Bank Ltd" },
  { value: "Kollam Dist Co Operative Bank Ltd", label: "Kollam Dist Co Operative Bank Ltd" },
  { value: "The Rajpipla Nagrik Sahakari Bank Ltd", label: "The Rajpipla Nagrik Sahakari Bank Ltd" },
  { value: "Muzaffarnagar Urban Co Operative Bank Ltd", label: "Muzaffarnagar Urban Co Operative Bank Ltd" },
  { value: "The Belgaum Zilla Rani Channamma Mahila Sahakari Bank Niyamit", label: "The Belgaum Zilla Rani Channamma Mahila Sahakari Bank Niyamit" },
  { value: "The Sholapur District Central Co Op Bank Ltd", label: "The Sholapur District Central Co Op Bank Ltd" },
  { value: "Maradu Service Co Operative Bank Ltd", label: "Maradu Service Co Operative Bank Ltd" },
  { value: "The Khedbrahma Nagarik Sahakari Bank Ltd", label: "The Khedbrahma Nagarik Sahakari Bank Ltd" },
  { value: "Areeparampu Service Co Operative Bank Ltd", label: "Areeparampu Service Co Operative Bank Ltd" },
  { value: "The Tiruvalla East Co Operative Bank Ltd", label: "The Tiruvalla East Co Operative Bank Ltd" },
  { value: "The Manjeshwar Service Co Operative Bank Ltd", label: "The Manjeshwar Service Co Operative Bank Ltd" },
  { value: "Tamluk Ghatal Central Co Operative Bank Ltd", label: "Tamluk Ghatal Central Co Operative Bank Ltd" },
  { value: "Nimar Kshetriya Gramin Bank", label: "Nimar Kshetriya Gramin Bank" },
  { value: "The Bellad Bagewadi Urban Co Op Bank Ltd", label: "The Bellad Bagewadi Urban Co Op Bank Ltd" },
  { value: "Mysore Zilla Mahila Sahakara Bank Niyamitha", label: "Mysore Zilla Mahila Sahakara Bank Niyamitha" },
  { value: "The Shegaon Shree Agrasen", label: "The Shegaon Shree Agrasen" },
  { value: "Nadia District Central Co Operative Bank Ltd", label: "Nadia District Central Co Operative Bank Ltd" },
  { value: "Co Operative Bank Of Ahmedabad Ltd", label: "Co Operative Bank Of Ahmedabad Ltd" },
  { value: "The Vellamunda Service Co Op Bank Ltd", label: "The Vellamunda Service Co Op Bank Ltd" },
  { value: "Ashok Nagari Sahakari Bank Ltd", label: "Ashok Nagari Sahakari Bank Ltd" },
  { value: "The Uttarsanda Peoples Co Op Bank Ltd", label: "The Uttarsanda Peoples Co Op Bank Ltd" },
  { value: "Traders Co Operative Society", label: "Traders Co Operative Society" },
  { value: "The Parayakad Service Co Operative Bank Ltd", label: "The Parayakad Service Co Operative Bank Ltd" },
  { value: "The Merchants Co Op Bank Ltd Dhule", label: "The Merchants Co Op Bank Ltd Dhule" },
  { value: "Gopinath Patil Parsik Janata Sahakari Bank Ltd", label: "Gopinath Patil Parsik Janata Sahakari Bank Ltd" },
  { value: "Patliputra Gramin Bank", label: "Patliputra Gramin Bank" },
  { value: "Balageria Central Co Operative Bank Limited", label: "Balageria Central Co Operative Bank Limited" },
  { value: "Orix Autos Business Solutions Ltd", label: "Orix Autos Business Solutions Ltd" },
  { value: "The Mangalpady Service Co Op Bank Ltd", label: "The Mangalpady Service Co Op Bank Ltd" },
  { value: "The Karthedom Rural Co Op Society Ltd", label: "The Karthedom Rural Co Op Society Ltd" },
  { value: "The Mayyil Service Co Operative Bank", label: "The Mayyil Service Co Operative Bank" },
  { value: "Todi Investors", label: "Todi Investors" },
  { value: "The Kanjikode Service Co Op Bank Ltd", label: "The Kanjikode Service Co Op Bank Ltd" },
  { value: "Vaijanath Appa Saraf Marathwada Nagri Sahakari Bank Ltd", label: "Vaijanath Appa Saraf Marathwada Nagri Sahakari Bank Ltd" },
  { value: "Khetriya Gramin Bank", label: "Khetriya Gramin Bank" },
  { value: "The Rayat Sevak Co Op Bank Ltd Satara", label: "The Rayat Sevak Co Op Bank Ltd Satara" },
  { value: "The Vandiperiyar Service Co Operative Bank Ltd", label: "The Vandiperiyar Service Co Operative Bank Ltd" },
  { value: "The Coonoor Co Operative Urban Bank Ltd", label: "The Coonoor Co Operative Urban Bank Ltd" },
  { value: "The Panchkula Central Co Operative Bank Ltd", label: "The Panchkula Central Co Operative Bank Ltd" },
  { value: "Kottappady East Service Co Op Bank Ltd", label: "Kottappady East Service Co Op Bank Ltd" },
  { value: "The Kalpetta Service Co Operative Bank Ltd", label: "The Kalpetta Service Co Operative Bank Ltd" },
  { value: "Calicut City Service Co Operative Bank Ltd", label: "Calicut City Service Co Operative Bank Ltd" },
  { value: "Jijamata Mahila Nagri Sahakari Bank Ltd", label: "Jijamata Mahila Nagri Sahakari Bank Ltd" },
  { value: "The Coimbatore District Central Co Op Bank Ltd", label: "The Coimbatore District Central Co Op Bank Ltd" },
  { value: "The Kuttyeri Service Co Op Bank Ltd", label: "The Kuttyeri Service Co Op Bank Ltd" },
  { value: "The Aechoor Service Co Op Bank Ltd", label: "The Aechoor Service Co Op Bank Ltd" },
  { value: "The Talikoti Sahakari Bank Niyamit", label: "The Talikoti Sahakari Bank Niyamit" },
  { value: "Nishant Sahakari Gramin Patpurwatha Sanstha Ltd, Akola", label: "Nishant Sahakari Gramin Patpurwatha Sanstha Ltd, Akola" },
  { value: "Indira Mahila Sah Bank Ltd Nandurbar", label: "Indira Mahila Sah Bank Ltd Nandurbar" },
  { value: "The Mavoor Service Co Op Bank Ltd", label: "The Mavoor Service Co Op Bank Ltd" },
  { value: "Ulliyeri Service Co Op Bank Ltd No D 2033", label: "Ulliyeri Service Co Op Bank Ltd No D 2033" },
  { value: "The Nayagarh District Central Co Op Bank Ltd", label: "The Nayagarh District Central Co Op Bank Ltd" },
  { value: "Poojya Shri Mahantappa Co Op Society Ny", label: "Poojya Shri Mahantappa Co Op Society Ny" },
  { value: "The Vengara Service Co Op Rural Bank Ltd", label: "The Vengara Service Co Op Rural Bank Ltd" },
  { value: "The Unava Nagarik Sahakari Bank Ltd", label: "The Unava Nagarik Sahakari Bank Ltd" },
  { value: "The Kanamala Service Co Op Bank Ltd", label: "The Kanamala Service Co Op Bank Ltd" },
  { value: "The Angadipuram Service Co Op Bank Ltd", label: "The Angadipuram Service Co Op Bank Ltd" },
  { value: "The Mangattidom Service Co Op Bank Ltd", label: "The Mangattidom Service Co Op Bank Ltd" },
  { value: "Primary Agricultural Co Op Society Ltd", label: "Primary Agricultural Co Op Society Ltd" },
  { value: "Deendayal Nagari Sahakari Bank Ltd Ambajogai", label: "Deendayal Nagari Sahakari Bank Ltd Ambajogai" },
  { value: "Shri Gajanan Urban Co Op Credit Society Ltd", label: "Shri Gajanan Urban Co Op Credit Society Ltd" },
  { value: "The Melampara Service Co Op Bank Ltd", label: "The Melampara Service Co Op Bank Ltd" },
  { value: "Bijapur Grameena Bank", label: "Bijapur Grameena Bank" },
  { value: "Sri Rama Co Op Bank Ltd", label: "Sri Rama Co Op Bank Ltd" },
  { value: "Rendal Sahakari Bank Ltd", label: "Rendal Sahakari Bank Ltd" },
  { value: "Kuttapuzha Service Co Operative Bank Ltd", label: "Kuttapuzha Service Co Operative Bank Ltd" },
  { value: "The Pij Peoples Co Op Bank Ltd", label: "The Pij Peoples Co Op Bank Ltd" },
  { value: "Vadnagar Nagrik Sahkari Bank Ltd", label: "Vadnagar Nagrik Sahkari Bank Ltd" },
  { value: "Ilkal Co Op Bank Ltd", label: "Ilkal Co Op Bank Ltd" },
  { value: "Tellabelly Primary Agriculture Co Op Society", label: "Tellabelly Primary Agriculture Co Op Society" },
  { value: "Murshidabad Gramin Bank", label: "Murshidabad Gramin Bank" },
  { value: "The Panangad Service Co Operative Bank", label: "The Panangad Service Co Operative Bank" },
  { value: "Uttar Bihar Gramin Bank", label: "Uttar Bihar Gramin Bank" },
  { value: "The Karimkunnam Service Co Op Bank Ltd", label: "The Karimkunnam Service Co Op Bank Ltd" },
  { value: "The Krishna District Co Operative Central Bank Limited", label: "The Krishna District Co Operative Central Bank Limited" },
  { value: "The Kanaka Pattana Sahakari Bank Niyamita", label: "The Kanaka Pattana Sahakari Bank Niyamita" },
  { value: "The Brahmamangalam Gramaswaraj Service Co Op Bank Ltd", label: "The Brahmamangalam Gramaswaraj Service Co Op Bank Ltd" },
  { value: "Prathamika Krushi Pattina Sahakari Bank Niyamit", label: "Prathamika Krushi Pattina Sahakari Bank Niyamit" },
  { value: "The Panamaram Service Co Op Bank Ltd", label: "The Panamaram Service Co Op Bank Ltd" },
  { value: "Vatakara Town Co Op Uraban Society", label: "Vatakara Town Co Op Uraban Society" },
  { value: "Mahabir Gmb Tradefin Pvt Ltd", label: "Mahabir Gmb Tradefin Pvt Ltd" },
  { value: "Kolhapur Mahila Sahakari Bank Ltd", label: "Kolhapur Mahila Sahakari Bank Ltd" },
  { value: "Ponani Co Op Urban Bank Ltd", label: "Ponani Co Op Urban Bank Ltd" },
  { value: "S.S.K Co Op Credit Society Ltd", label: "S.S.K Co Op Credit Society Ltd" },
  { value: "The Sangrur Central Co Op Bank Ltd", label: "The Sangrur Central Co Op Bank Ltd" },
  { value: "The Nutan Nagrik Co Op Credit Society Ltd", label: "The Nutan Nagrik Co Op Credit Society Ltd" },
  { value: "Rani Lakshmibai Kshetriya Gramin Bank", label: "Rani Lakshmibai Kshetriya Gramin Bank" },
  { value: "The Kolayad Service Co Op Bank Ltd", label: "The Kolayad Service Co Op Bank Ltd" },
  { value: "Perfect Urban Co Op Credit Society", label: "Perfect Urban Co Op Credit Society" },
  { value: "Nedumprom Service Co Operative Bank Ltd", label: "Nedumprom Service Co Operative Bank Ltd" },
  { value: "Mainpuri Zila Sahkari Bank Ltd", label: "Mainpuri Zila Sahkari Bank Ltd" },
  { value: "Twin Cities Co Operative Urban Bank Limited", label: "Twin Cities Co Operative Urban Bank Limited" },
  { value: "Shrisai Finlease Ltd", label: "Shrisai Finlease Ltd" },
  { value: "George Town Co Operative Bank", label: "George Town Co Operative Bank" },
  { value: "Saidapet Co Operative Bank", label: "Saidapet Co Operative Bank" },
  { value: "Chalakudy Urban Co Operative Bank Ltd", label: "Chalakudy Urban Co Operative Bank Ltd" },
  { value: "Bhoj Nagrik Sahakari Bank Mydt", label: "Bhoj Nagrik Sahakari Bank Mydt" },
  { value: "Kohinoor Sahakari Bank Ltd", label: "Kohinoor Sahakari Bank Ltd" },
  { value: "The Quilon Co Op Urban Bank Ltd", label: "The Quilon Co Op Urban Bank Ltd" },
  { value: "The Central Services Co Op Society Ltd", label: "The Central Services Co Op Society Ltd" },
  { value: "The Kottathara Service Co Op Bank Ltd", label: "The Kottathara Service Co Op Bank Ltd" },
  { value: "The Mannam Service Co Op Bank Ltd", label: "The Mannam Service Co Op Bank Ltd" },
  { value: "Howrah District Central Co Op Bank Ltd", label: "Howrah District Central Co Op Bank Ltd" },
  { value: "Mahindra And Mahindra Financial Services Limited", label: "Mahindra And Mahindra Financial Services Limited" },
  { value: "Kaipamangalam Service Co Op Bank Ltd", label: "Kaipamangalam Service Co Op Bank Ltd" },
  { value: "The Chalakudy Urban Co Op Bank Ltd", label: "The Chalakudy Urban Co Op Bank Ltd" },
  { value: "The Thalore Service Co Op Bank Ltd", label: "The Thalore Service Co Op Bank Ltd" },
  { value: "The Payyanur Co Op Rural Bank Ltd", label: "The Payyanur Co Op Rural Bank Ltd" },
  { value: "The Farrukhabad Dist Co Op Bank Ltd", label: "The Farrukhabad Dist Co Op Bank Ltd" },
  { value: "The Santhigram Service Co Op Bank Ltd", label: "The Santhigram Service Co Op Bank Ltd" },
  { value: "The Nashik Merchants Co Op Bank Ltd", label: "The Nashik Merchants Co Op Bank Ltd" },
  { value: "The Chelannur Service Co Op Bank Ltd", label: "The Chelannur Service Co Op Bank Ltd" },
  { value: "The Jalandhar Central Co Operative Bank Ltd", label: "The Jalandhar Central Co Operative Bank Ltd" },
  { value: "Sri Bharathi Co Operative Urban Bank Limited", label: "Sri Bharathi Co Operative Urban Bank Limited" },
  { value: "Zodiac Auto Finance", label: "Zodiac Auto Finance" },
  { value: "Citi Fiscal Services P Ltd", label: "Citi Fiscal Services P Ltd" },
  { value: "Chikmagalur Kodagu Grameena Bank", label: "Chikmagalur Kodagu Grameena Bank" },
  { value: "Mini Bank Gram Seva Sahakari Samiti Ltd", label: "Mini Bank Gram Seva Sahakari Samiti Ltd" },
  { value: "Ankit Finance Corporation", label: "Ankit Finance Corporation" },
  { value: "Maa Sharda Mahila Nagrik Sahakari Bank Mydt", label: "Maa Sharda Mahila Nagrik Sahakari Bank Mydt" },
  { value: "Kuttiady Vanitha Co Op Society Ltd", label: "Kuttiady Vanitha Co Op Society Ltd" },
  { value: "Rkd Financial Services Ltd", label: "Rkd Financial Services Ltd" },
  { value: "Sadguru Nagrik Sahakari Bank Mydt", label: "Sadguru Nagrik Sahakari Bank Mydt" },
  { value: "Malaprabha Grameena Bank", label: "Malaprabha Grameena Bank" },
  { value: "Jayprakash Narayan Nagri Sahakari Bank Ltd", label: "Jayprakash Narayan Nagri Sahakari Bank Ltd" },
  { value: "The Chirakkal Service Co Operative Bank Ltd", label: "The Chirakkal Service Co Operative Bank Ltd" },
  { value: "Arambagh Co Operative Agricultural Marketing Society Ltd", label: "Arambagh Co Operative Agricultural Marketing Society Ltd" },
  { value: "R A Finance And Leasing Co P Ltd", label: "R A Finance And Leasing Co P Ltd" },
  { value: "Uttarkashi Zila Sahkari Bank Ltd", label: "Uttarkashi Zila Sahkari Bank Ltd" },
  { value: "Ajmer Central Co Operative Bank Ltd", label: "Ajmer Central Co Operative Bank Ltd" },
  { value: "Alwar Bharatpur Aanchalik Gramin Bank", label: "Alwar Bharatpur Aanchalik Gramin Bank" },
  { value: "The Kagal Co Op Bank Ltd", label: "The Kagal Co Op Bank Ltd" },
  { value: "The Adoor Co Op Bank Ltd", label: "The Adoor Co Op Bank Ltd" },
  { value: "The Madai Service Co Op Bank Ltd", label: "The Madai Service Co Op Bank Ltd" },
  { value: "Pragjyotish Gaonlia Bank", label: "Pragjyotish Gaonlia Bank" },
  { value: "The Bank Employees Co Operative Bank Ltd", label: "The Bank Employees Co Operative Bank Ltd" },
  { value: "Fullerton India Credit Co Ltd", label: "Fullerton India Credit Co Ltd" },
  { value: "Bussan Auto Finance India Pvt Ltd", label: "Bussan Auto Finance India Pvt Ltd" },
  { value: "The Yavatmal Mahila Sahakari Bank Ltd", label: "The Yavatmal Mahila Sahakari Bank Ltd" },
  { value: "The Kizhuparamba Service Co Op Bank Ltd", label: "The Kizhuparamba Service Co Op Bank Ltd" },
  { value: "The Calicut North Service Co Op Bank Ltd", label: "The Calicut North Service Co Op Bank Ltd" },
  { value: "Nagnath Urban Co Op Bank Ltd", label: "Nagnath Urban Co Op Bank Ltd" },
  { value: "Hindustan Petroleum Corporation Limited", label: "Hindustan Petroleum Corporation Limited" },
  { value: "The Thiruvalla Service Co Op Bank Ltd", label: "The Thiruvalla Service Co Op Bank Ltd" },
  { value: "Shibir India Limited", label: "Shibir India Limited" },
  { value: "The Padinharathara Service Co Op Bank Ltd", label: "The Padinharathara Service Co Op Bank Ltd" },
  { value: "The Vadayar Service Co Operative Bank Ltd Vadayar", label: "The Vadayar Service Co Operative Bank Ltd Vadayar" },
  { value: "The Thodupuzha Urban Co Operative Bank Ltd Thodupuzha", label: "The Thodupuzha Urban Co Operative Bank Ltd Thodupuzha" },
  { value: "The Chenkara Service Co Opertive Bank Ltd", label: "The Chenkara Service Co Opertive Bank Ltd" },
  { value: "Shree Shiveshwar Nagari Sahakari Bank Ltd", label: "Shree Shiveshwar Nagari Sahakari Bank Ltd" },
  { value: "Col Rd Nikam Sainik Sahakari Bank Ltd", label: "Col Rd Nikam Sainik Sahakari Bank Ltd" },
  { value: "Vellala Co Operative Bank Ltd", label: "Vellala Co Operative Bank Ltd" },
  { value: "The Pattanur Service Co Operative Bank Ltd", label: "The Pattanur Service Co Operative Bank Ltd" },
  { value: "The Thayanoor Service Co Op Bank Ltd", label: "The Thayanoor Service Co Op Bank Ltd" },
  { value: "The Maloth Service Co Op Bank Ltd", label: "The Maloth Service Co Op Bank Ltd" },
  { value: "The Trikarpur Farmers Service Co Op Bank Ltd", label: "The Trikarpur Farmers Service Co Op Bank Ltd" },
  { value: "The Teekoy Service Co Op Bank Ltd", label: "The Teekoy Service Co Op Bank Ltd" },
  { value: "The Poonjar Service Co Op Bank Ltd", label: "The Poonjar Service Co Op Bank Ltd" },
  { value: "The Bodeli Urban Co Op Bank Ltd", label: "The Bodeli Urban Co Op Bank Ltd" },
  { value: "The Eramam Kuttur Service Co Operative Bank Ltd", label: "The Eramam Kuttur Service Co Operative Bank Ltd" },
  { value: "Jhabak Finance Corporation", label: "Jhabak Finance Corporation" },
  { value: "Varalaxmi Credit Sahakara Sangha Ltd", label: "Varalaxmi Credit Sahakara Sangha Ltd" },
  { value: "Citizen Nagari Sahakari Patshanstha Marya", label: "Citizen Nagari Sahakari Patshanstha Marya" },
  { value: "The Payyanur Service Co Operative Bank Ltd", label: "The Payyanur Service Co Operative Bank Ltd" },
  { value: "The Thimiri Service Co Operative Bank Ltd", label: "The Thimiri Service Co Operative Bank Ltd" },
  { value: "The Shanmukhapuram Co Operative Bank Ltd", label: "The Shanmukhapuram Co Operative Bank Ltd" },
  { value: "The Jind Central Co Operative Bank Ltd", label: "The Jind Central Co Operative Bank Ltd" },
  { value: "Krunal Enterprise", label: "Krunal Enterprise" },
  { value: "Thayar Finance", label: "Thayar Finance" },
  { value: "The Kakinada Co Op Town Bank Ltd", label: "The Kakinada Co Op Town Bank Ltd" },
  { value: "The Sircilla Co Op Urban Bank Ltd", label: "The Sircilla Co Op Urban Bank Ltd" },
  { value: "Naroda Finance", label: "Naroda Finance" },
  { value: "The Madai Co Op Rural Bank Ltd", label: "The Madai Co Op Rural Bank Ltd" },
  { value: "The Kurumathur Service Co Op Bank Ltd", label: "The Kurumathur Service Co Op Bank Ltd" },
  { value: "Abhay Finanace Company", label: "Abhay Finanace Company" },
  { value: "Parshwanath Finance", label: "Parshwanath Finance" },
  { value: "The Bechraji Nagarik Sahakari Bank Ltd", label: "The Bechraji Nagarik Sahakari Bank Ltd" },
  { value: "The Bailhongal Urban Co Operative Bank Ltd,Bailhongal", label: "The Bailhongal Urban Co Operative Bank Ltd,Bailhongal" },
  { value: "Satyashodhak Sahakari Bank Ltd", label: "Satyashodhak Sahakari Bank Ltd" },
  { value: "Cuttack Gramya Bank", label: "Cuttack Gramya Bank" },
  { value: "Shree Sadguru Jangli Maharaj Sahakari Bank Ltd", label: "Shree Sadguru Jangli Maharaj Sahakari Bank Ltd" },
  { value: "The Jawhar Urban Co Op Bank Ltd", label: "The Jawhar Urban Co Op Bank Ltd" },
  { value: "Ramkrishnapur Co Op Bank Ltd", label: "Ramkrishnapur Co Op Bank Ltd" },
  { value: "The Chemnad Service Co Operative Bank Ltd", label: "The Chemnad Service Co Operative Bank Ltd" },
  { value: "The Risod Urban Co Op Credit Society Ltd Risod Dt Washim", label: "The Risod Urban Co Op Credit Society Ltd Risod Dt Washim" },
  { value: "The Vani Merchants Co Operative Bank Ltd", label: "The Vani Merchants Co Operative Bank Ltd" },
  { value: "The Chengala Service Co Operative Bank Ltd", label: "The Chengala Service Co Operative Bank Ltd" },
  { value: "The Kadirur Service Co Operative Bank Ltd", label: "The Kadirur Service Co Operative Bank Ltd" },
  { value: "Raichur District Co Op Central Bank Ltd", label: "Raichur District Co Op Central Bank Ltd" },
  { value: "The Enadimangalam Service Co Operative Bank Ltd", label: "The Enadimangalam Service Co Operative Bank Ltd" },
  { value: "Venkurinji Service Co Operative Bank Ltd", label: "Venkurinji Service Co Operative Bank Ltd" },
  { value: "Shree Finvest", label: "Shree Finvest" },
  { value: "Padmashree Dr Vitthalrao Vikhe Patil Co Op Bank Ltd", label: "Padmashree Dr Vitthalrao Vikhe Patil Co Op Bank Ltd" },
  { value: "Samata Co Op Development Bank Ltd", label: "Samata Co Op Development Bank Ltd" },
  { value: "Srirama Grameena Bank", label: "Srirama Grameena Bank" },
  { value: "The Stambhadri Co Op Urban Bank Ltd", label: "The Stambhadri Co Op Urban Bank Ltd" },
  { value: "The Kaithal Central Co Op Bank Ltd", label: "The Kaithal Central Co Op Bank Ltd" },
  { value: "The Pondicherry Co Op Urban Bank Ltd", label: "The Pondicherry Co Op Urban Bank Ltd" },
  { value: "Bilagi Pattan Sahakari Bank Niyamit", label: "Bilagi Pattan Sahakari Bank Niyamit" },
  { value: "The Mathil Service Co Op Bank Ltd", label: "The Mathil Service Co Op Bank Ltd" },
  { value: "Railway Employees Co Op Banking Society Ltd", label: "Railway Employees Co Op Banking Society Ltd" },
  { value: "Kanakadurga Grameena Bank", label: "Kanakadurga Grameena Bank" },
  { value: "The Tiruvannamalai District Central Co Op Bank Ltd", label: "The Tiruvannamalai District Central Co Op Bank Ltd" },
  { value: "The Gadchiroli District Central Co Op Bank Ltd", label: "The Gadchiroli District Central Co Op Bank Ltd" },
  { value: "Dhandai Gramin Path Sanstha", label: "Dhandai Gramin Path Sanstha" },
  { value: "The Udupi Co Op Town Bank Ltd", label: "The Udupi Co Op Town Bank Ltd" },
  { value: "M.D.Pawar Peoples Bank Ltd", label: "M.D.Pawar Peoples Bank Ltd" },
  { value: "The Valavoor Service Co Op Bank Ltd", label: "The Valavoor Service Co Op Bank Ltd" },
  { value: "Shri Vyankatesh Co Op Bank Ltd Malegaon", label: "Shri Vyankatesh Co Op Bank Ltd Malegaon" },
  { value: "Kodukulanji Service Co Op Bank Ltd", label: "Kodukulanji Service Co Op Bank Ltd" },
  { value: "Poovarany Service Co Operative Bank Ltd", label: "Poovarany Service Co Operative Bank Ltd" },
  { value: "Thar Aanchalik Gramin Bank", label: "Thar Aanchalik Gramin Bank" },
  { value: "Rajgarhia Commercial Company", label: "Rajgarhia Commercial Company" },
  { value: "The Anantapur Co Op Town Bank Ltd.,Anantapur", label: "The Anantapur Co Op Town Bank Ltd.,Anantapur" },
  { value: "The Haveri Urban Co Op Bank Ltd", label: "The Haveri Urban Co Op Bank Ltd" },
  { value: "Abn Amro Bank", label: "Abn Amro Bank" },
  { value: "The Oonnukal Service Co Op Bank Ltd", label: "The Oonnukal Service Co Op Bank Ltd" },
  { value: "Neela Krishna Bank", label: "Neela Krishna Bank" },
  { value: "Abhay Trading Co", label: "Abhay Trading Co" },
  { value: "The Alakode Service Co Operative Bank Ltd", label: "The Alakode Service Co Operative Bank Ltd" },
  { value: "Puzhavathu Service Co Operative Bank Ltd", label: "Puzhavathu Service Co Operative Bank Ltd" },
  { value: "The Vemballur Service Co Operative Bank Ltd", label: "The Vemballur Service Co Operative Bank Ltd" },
  { value: "Akola Merchant Co Operative Bank Ltd", label: "Akola Merchant Co Operative Bank Ltd" },
  { value: "The Adoor Co Op Urban Bank Ltd", label: "The Adoor Co Op Urban Bank Ltd" },
  { value: "The Kurundwad Urban Co Op Bank Ltd", label: "The Kurundwad Urban Co Op Bank Ltd" },
  { value: "Shree Jyotiba Sahakari Bank Maryadit", label: "Shree Jyotiba Sahakari Bank Maryadit" },
  { value: "Vijay Nagar Kale Wadi Pimpri Pune", label: "Vijay Nagar Kale Wadi Pimpri Pune" },
  { value: "Bank Of Ceylon", label: "Bank Of Ceylon" },
  { value: "The Sirsa Central Co Op Bank Ltd", label: "The Sirsa Central Co Op Bank Ltd" },
  { value: "The Mowanchery Co Op Rural Bank Ltd", label: "The Mowanchery Co Op Rural Bank Ltd" },
  { value: "Shree Hanuman Sugar And Industries Ltd", label: "Shree Hanuman Sugar And Industries Ltd" },
  { value: "The Shree Suvarna Sahakari Bank Ltd", label: "The Shree Suvarna Sahakari Bank Ltd" },
  { value: "Family Credit Limited", label: "Family Credit Limited" },
  { value: "Naman Commodities Pvt Ltd", label: "Naman Commodities Pvt Ltd" },
  { value: "The Muzhappilangad Service Co Op Bank Ltd", label: "The Muzhappilangad Service Co Op Bank Ltd" },
  { value: "Kumarakom Regional Service Co Op Bank Ltd", label: "Kumarakom Regional Service Co Op Bank Ltd" },
  { value: "Indapur Urban Co Op Bank Ltd", label: "Indapur Urban Co Op Bank Ltd" },
  { value: "The Swasakthi Mercantile Co Op Urban Bank Ltd", label: "The Swasakthi Mercantile Co Op Urban Bank Ltd" },
  { value: "Dapoli Urban Co Op Bank Ltd", label: "Dapoli Urban Co Op Bank Ltd" },
  { value: "Anjarakandy Farmers Service Co Op Bank Ltd", label: "Anjarakandy Farmers Service Co Op Bank Ltd" },
  { value: "Srinivasa Padmavathi Co Operative Urban Bank Ltd", label: "Srinivasa Padmavathi Co Operative Urban Bank Ltd" },
  { value: "Vaikom Town Service Co Op Bank Ltd", label: "Vaikom Town Service Co Op Bank Ltd" },
  { value: "Kunnummakkara Service Co Op Bank Ltd", label: "Kunnummakkara Service Co Op Bank Ltd" },
  { value: "Vetaleshwar Sahakari Patpedhi Maryadit", label: "Vetaleshwar Sahakari Patpedhi Maryadit" },
  { value: "Bajaj Auto Finance", label: "Bajaj Auto Finance" },
  { value: "Edathirinji Service Co Op Bank Ltd", label: "Edathirinji Service Co Op Bank Ltd" },
  { value: "The Vejalpur Nagrik Shahkari Bank Ltd", label: "The Vejalpur Nagrik Shahkari Bank Ltd" },
  { value: "The Ottapalam Co Op Urban Bank Ltd", label: "The Ottapalam Co Op Urban Bank Ltd" },
  { value: "Kribhco Ltd", label: "Kribhco Ltd" },
  { value: "Mala Block Town Co Op Bank Ltd", label: "Mala Block Town Co Op Bank Ltd" },
  { value: "Daund Urban Co Op Bnak Ltd", label: "Daund Urban Co Op Bnak Ltd" },
  { value: "Kadanad Service Co Op Bank Ltd", label: "Kadanad Service Co Op Bank Ltd" },
  { value: "The Vsv Co Op Bank Ltd", label: "The Vsv Co Op Bank Ltd" },
  { value: "Nopany Investments Pvt Ltd", label: "Nopany Investments Pvt Ltd" },
  { value: "Parner Talika Sainik Sahakari Bank Ltd", label: "Parner Talika Sainik Sahakari Bank Ltd" },
  { value: "Vasavi Co Op Urban Bank Ltd", label: "Vasavi Co Op Urban Bank Ltd" },
  { value: "Kasundia Co Op Bank Ltd", label: "Kasundia Co Op Bank Ltd" },
  { value: "Firozabad Zila Sehkari Bank Ltd", label: "Firozabad Zila Sehkari Bank Ltd" },
  { value: "The Vaikom Palliprethussery Service Co Op Bank Ltd", label: "The Vaikom Palliprethussery Service Co Op Bank Ltd" },
  { value: "Treasury Savings Bank", label: "Treasury Savings Bank" },
  { value: "The Mulgund Urban Souharda Co Op Bank Ltd", label: "The Mulgund Urban Souharda Co Op Bank Ltd" },
  { value: "J And K Grameen Bank", label: "J And K Grameen Bank" },
  { value: "Porbandar Commercial Co Op Bank Ltd", label: "Porbandar Commercial Co Op Bank Ltd" },
  { value: "The Elamkulam Service Co Op Bank Ltd", label: "The Elamkulam Service Co Op Bank Ltd" },
  { value: "The Urangattiri Service Co Op Bank Ltd", label: "The Urangattiri Service Co Op Bank Ltd" },
  { value: "Meppayur Service Co Op Bank Ltd", label: "Meppayur Service Co Op Bank Ltd" },
  { value: "Chengaroth Panchayath Service Co Op Bank Ltd", label: "Chengaroth Panchayath Service Co Op Bank Ltd" },
  { value: "The Periye Service Co Op Bank Ltd", label: "The Periye Service Co Op Bank Ltd" },
  { value: "Best Auto Finance", label: "Best Auto Finance" },
  { value: "Wainganga Krishna Gramin Bank", label: "Wainganga Krishna Gramin Bank" },
  { value: "Saibaba Janata Sahakari Bank Ltd", label: "Saibaba Janata Sahakari Bank Ltd" },
  { value: "Supreme Carriers", label: "Supreme Carriers" },
  { value: "Sangameswara Grameena Bank", label: "Sangameswara Grameena Bank" },
  { value: "The Baidyabati Sheoraphuli Co Operative Bank Limited", label: "The Baidyabati Sheoraphuli Co Operative Bank Limited" },
  { value: "The Tirur", label: "The Tirur" },
  { value: "Shri Revansiddheswar Pattan Sahakari Bank Niyamit", label: "Shri Revansiddheswar Pattan Sahakari Bank Niyamit" },
  { value: "Mathura Zila Sahkari Bank Ltd Mathura", label: "Mathura Zila Sahkari Bank Ltd Mathura" },
  { value: "Banda District Co Op Bank Ltd", label: "Banda District Co Op Bank Ltd" },
  { value: "Sudershan Leasing And Finance Co", label: "Sudershan Leasing And Finance Co" },
  { value: "Jhunjhunu Kendriya Sahakari Bank Ltd", label: "Jhunjhunu Kendriya Sahakari Bank Ltd" },
  { value: "Sincere Auto Stores Pvt Ltd", label: "Sincere Auto Stores Pvt Ltd" },
  { value: "Janata Urban Co Op Bank Ltd", label: "Janata Urban Co Op Bank Ltd" },
  { value: "Chaitanya Grameena Bank", label: "Chaitanya Grameena Bank" },
  { value: "The Kattapana Service Co Op Bank Ltd", label: "The Kattapana Service Co Op Bank Ltd" },
  { value: "Shree Bhavani Kripa Finance Services", label: "Shree Bhavani Kripa Finance Services" },
  { value: "Yeshwant Nagari Sahakari Bank Ltd.", label: "Yeshwant Nagari Sahakari Bank Ltd." },
  { value: "Shree Kanaiya Motors", label: "Shree Kanaiya Motors" },
  { value: "The Payangadi Urban Co Op Bank Ltd", label: "The Payangadi Urban Co Op Bank Ltd" },
  { value: "Aakansha Sahkari Patpedi", label: "Aakansha Sahkari Patpedi" },
  { value: "The Muzhuvannoor Service Co Op Bank Ltd", label: "The Muzhuvannoor Service Co Op Bank Ltd" },
  { value: "Loknete Dattaji Patil Sahkari Bank Ltd", label: "Loknete Dattaji Patil Sahkari Bank Ltd" },
  { value: "The Naranammoozhy Service Co Op Bank Ltd", label: "The Naranammoozhy Service Co Op Bank Ltd" },
  { value: "The Kuppamthala Service Co Op Bank Ltd", label: "The Kuppamthala Service Co Op Bank Ltd" },
  { value: "The Koottar Service Co Op Bank Ltd", label: "The Koottar Service Co Op Bank Ltd" },
  { value: "Vikramaditya Nagrik Sah Bank Mydt", label: "Vikramaditya Nagrik Sah Bank Mydt" },
  { value: "The Anantnag Central Co Op Bank Ltd", label: "The Anantnag Central Co Op Bank Ltd" },
  { value: "The Pattambi Service Co Op Bank Ltd", label: "The Pattambi Service Co Op Bank Ltd" },
  { value: "Skylark Leasing Limited", label: "Skylark Leasing Limited" },
  { value: "Kerala Mercantile Co Op Bank Ltd", label: "Kerala Mercantile Co Op Bank Ltd" },
  { value: "The Parathode Service Co Op Bank", label: "The Parathode Service Co Op Bank" },
  { value: "Chirakadavu Service Co Op Bank Ltd", label: "Chirakadavu Service Co Op Bank Ltd" },
  { value: "Kareepra Panchayat Service Co Op Bank Ltd", label: "Kareepra Panchayat Service Co Op Bank Ltd" },
  { value: "The Akalakunnam Village Service Co Op Bank Ltd", label: "The Akalakunnam Village Service Co Op Bank Ltd" },
  { value: "Valayam Service Co Operative Bank", label: "Valayam Service Co Operative Bank" },
  { value: "The Ponkunnam Service Co Op Bank Ltd", label: "The Ponkunnam Service Co Op Bank Ltd" },
  { value: "The Kuttoor Service Co Op Bank Ltd", label: "The Kuttoor Service Co Op Bank Ltd" },
  { value: "Punnathura Service Co Op Bank Ltd", label: "Punnathura Service Co Op Bank Ltd" },
  { value: "Saraswat Bank", label: "Saraswat Bank" },
  { value: "The Hisar Urban Co Op Bank Ltd", label: "The Hisar Urban Co Op Bank Ltd" },
  { value: "Msk Motor Credits", label: "Msk Motor Credits" },
  { value: "The Pattithara Service Co Op Bank Ltd", label: "The Pattithara Service Co Op Bank Ltd" },
  { value: "The S.A.S Nagar Central Co Op Bank Ltd", label: "The S.A.S Nagar Central Co Op Bank Ltd" },
  { value: "Gbr Finvest Private Ltd", label: "Gbr Finvest Private Ltd" },
  { value: "Priyadarshani Gramin Bigarsheti Sah Patsanstha Maryadit", label: "Priyadarshani Gramin Bigarsheti Sah Patsanstha Maryadit" },
  { value: "Bhupathiraju Co Op Credit Bank Ltd", label: "Bhupathiraju Co Op Credit Bank Ltd" },
  { value: "Shree Krupa Nagari Sah. Pathsanstha Ltd", label: "Shree Krupa Nagari Sah. Pathsanstha Ltd" },
  { value: "Khardah Co Op Bank Ltd", label: "Khardah Co Op Bank Ltd" },
  { value: "Kayakkodi Service Co Opbank Ltd", label: "Kayakkodi Service Co Opbank Ltd" },
  { value: "The Cheemeni Service Co Op Bank Ltd", label: "The Cheemeni Service Co Op Bank Ltd" },
  { value: "The Kolari Service Co Op Bank Ltd", label: "The Kolari Service Co Op Bank Ltd" },
  { value: "Nmdc Ltd", label: "Nmdc Ltd" },
  { value: "Ashok Sahakari Bank Ltd", label: "Ashok Sahakari Bank Ltd" },
  { value: "Shri Basaveshwar Pattana Sahakari Bank Niyamit", label: "Shri Basaveshwar Pattana Sahakari Bank Niyamit" },
  { value: "The Osmanabad Dist Central Co Op Bank Ltd", label: "The Osmanabad Dist Central Co Op Bank Ltd" },
  { value: "Sri Kalidasa Sahakara Bank Niyamitha", label: "Sri Kalidasa Sahakara Bank Niyamitha" },
  { value: "Shree Kulswami Co Op Cr Soc Ltd", label: "Shree Kulswami Co Op Cr Soc Ltd" },
  { value: "Shree Warana Sahakari Bank Ltd", label: "Shree Warana Sahakari Bank Ltd" },
  { value: "Gyan Ganga Credit Corporation", label: "Gyan Ganga Credit Corporation" },
  { value: "Omkar Nagreeya Sahkari Bank Ltd", label: "Omkar Nagreeya Sahkari Bank Ltd" },
  { value: "Bidar Mahila Urban Co Op Bank Ltd.", label: "Bidar Mahila Urban Co Op Bank Ltd." },
  { value: "Tungabhadra Gramin Bank", label: "Tungabhadra Gramin Bank" },
  { value: "Anmol Financial Services Ltd", label: "Anmol Financial Services Ltd" },
  { value: "Dost Fincap Pvt Ltd", label: "Dost Fincap Pvt Ltd" },
  { value: "Ara Finance", label: "Ara Finance" },
  { value: "Jayam Auto Finance", label: "Jayam Auto Finance" },
  { value: "Annamalai Group Sivanmalai Murugan Motor Finance", label: "Annamalai Group Sivanmalai Murugan Motor Finance" },
  { value: "S K Sri Ranga Auto Finance", label: "S K Sri Ranga Auto Finance" },
  { value: "Nahar Credit Pvt Ltd", label: "Nahar Credit Pvt Ltd" },
  { value: "Leaseplan India Ltd", label: "Leaseplan India Ltd" },
  { value: "Deep Finvest Ltd", label: "Deep Finvest Ltd" },
  { value: "Gautam Sahakari Bank Ltd", label: "Gautam Sahakari Bank Ltd" },
  { value: "Air Corporations Employees Co Op Bank Ltd", label: "Air Corporations Employees Co Op Bank Ltd" },
  { value: "The Cardamom Merchants Co Op Bank Ltd", label: "The Cardamom Merchants Co Op Bank Ltd" },
  { value: "The Satana Merchants Co Op Bank Ltd", label: "The Satana Merchants Co Op Bank Ltd" },
  { value: "The Kannur Town Service Co Op Bank Ltd", label: "The Kannur Town Service Co Op Bank Ltd" },
  { value: "Jagan Nath Din Dayal Finance Co", label: "Jagan Nath Din Dayal Finance Co" },
  { value: "The Botad Peoples Co Op Bank Ltd", label: "The Botad Peoples Co Op Bank Ltd" },
  { value: "Tandur Mahila Co Op Urban Bank Ltd", label: "Tandur Mahila Co Op Urban Bank Ltd" },
  { value: "The Lasalgaon Merchants Co Op Bank Ltd", label: "The Lasalgaon Merchants Co Op Bank Ltd" },
  { value: "E E 153 Sattankulam Primary Agricultural Co Op Bank Ltd", label: "E E 153 Sattankulam Primary Agricultural Co Op Bank Ltd" },
  { value: "Chhindwara Seoni Kshetriya Gramin Bank", label: "Chhindwara Seoni Kshetriya Gramin Bank" },
  { value: "Kallettumkara Service Coop Bank Ltd", label: "Kallettumkara Service Coop Bank Ltd" },
  { value: "Barabanki Gramin Bank", label: "Barabanki Gramin Bank" },
  { value: "Northern Railway Primary Co Op Bank Ltd", label: "Northern Railway Primary Co Op Bank Ltd" },
  { value: "M S Nest Associates", label: "M S Nest Associates" },
  { value: "The Postal And R M S Employees Co Op Bank Ltd Ambala Cantt", label: "The Postal And R M S Employees Co Op Bank Ltd Ambala Cantt" },
  { value: "The Vayalar Service Co Op Bank Ltd", label: "The Vayalar Service Co Op Bank Ltd" },
  { value: "The Jhajjar Central Co Op Bank Ltd", label: "The Jhajjar Central Co Op Bank Ltd" },
  { value: "Vardhaman Mahila Co Op Urban Bank Ltd", label: "Vardhaman Mahila Co Op Urban Bank Ltd" },
  { value: "The Pandanad Service Co Op Bank Ltd", label: "The Pandanad Service Co Op Bank Ltd" },
  { value: "Kothari Finance", label: "Kothari Finance" },
  { value: "Gbr Enterprises", label: "Gbr Enterprises" },
  { value: "The Ahmednagar District Primary Teachers Co Op Bank Ltd", label: "The Ahmednagar District Primary Teachers Co Op Bank Ltd" },
  { value: "Catholic Bank", label: "Catholic Bank" },
  { value: "The Rajasthan Urban Co Op Bank Ltd", label: "The Rajasthan Urban Co Op Bank Ltd" },
  { value: "Onkar Investo", label: "Onkar Investo" },
  { value: "The Calicut Town Service Co Op Bank", label: "The Calicut Town Service Co Op Bank" },
  { value: "Kodassery Multipurpose Co Op Society Ltd", label: "Kodassery Multipurpose Co Op Society Ltd" },
  { value: "Puthuruthy Service Co Op Bank Ltd", label: "Puthuruthy Service Co Op Bank Ltd" },
  { value: "Poothrikka Service Co Op Bank Ltd", label: "Poothrikka Service Co Op Bank Ltd" },
  { value: "Seven Hills Finance And Leasing", label: "Seven Hills Finance And Leasing" },
  { value: "The Mugu Service Co Op Bank Ltd", label: "The Mugu Service Co Op Bank Ltd" },
  { value: "The Kalliad Service Co Op Bank Ltd", label: "The Kalliad Service Co Op Bank Ltd" },
  { value: "Anjaneya Automobile Finance", label: "Anjaneya Automobile Finance" },
  { value: "The West Chalakudy Service Co Op Bank Ltd", label: "The West Chalakudy Service Co Op Bank Ltd" },
  { value: "The Karuvannur Service Co Operative Bank Ltd", label: "The Karuvannur Service Co Operative Bank Ltd" },
  { value: "The Little Kancheepuram Co Op Urban Bank Ltd", label: "The Little Kancheepuram Co Op Urban Bank Ltd" },
  { value: "Stewart Holl India Pvt Ltd", label: "Stewart Holl India Pvt Ltd" },
  { value: "The Kanhirode Service Co Op Bank Ltd", label: "The Kanhirode Service Co Op Bank Ltd" },
  { value: "Model Bank", label: "Model Bank" },
  { value: "The Royal Bank Of Scotland", label: "The Royal Bank Of Scotland" },
  { value: "The Rewari Central Co Op Bank Ltd", label: "The Rewari Central Co Op Bank Ltd" },
  { value: "The Kadachira Service Co Operative Bank Ltd", label: "The Kadachira Service Co Operative Bank Ltd" },
  { value: "Pvk Investments", label: "Pvk Investments" },
  { value: "Kunnamthanam Service Co Operative Bank Ltd", label: "Kunnamthanam Service Co Operative Bank Ltd" },
  { value: "Vijay Raj Maroti", label: "Vijay Raj Maroti" },
  { value: "Anup Enterprises", label: "Anup Enterprises" },
  { value: "The Ariyallur Service Co Op Bank Ltd", label: "The Ariyallur Service Co Op Bank Ltd" },
  { value: "The Chekkiad Service Co Op Bank Ltd", label: "The Chekkiad Service Co Op Bank Ltd" },
  { value: "The Thalayazham Service Co Op Bank Ltd", label: "The Thalayazham Service Co Op Bank Ltd" },
  { value: "Gobinda Enterprise", label: "Gobinda Enterprise" },
  { value: "The Thachinganadam Service Co Op Bank Ltd", label: "The Thachinganadam Service Co Op Bank Ltd" },
  { value: "The Kasaragod Co Op Town Bank Ltd", label: "The Kasaragod Co Op Town Bank Ltd" },
  { value: "The Shirpur Peoples Co Op Bank Ltd", label: "The Shirpur Peoples Co Op Bank Ltd" },
  { value: "Santhali Juth Seva Sahkari Mandali Ltd", label: "Santhali Juth Seva Sahkari Mandali Ltd" },
  { value: "The Nedumattam Service Co Operative Bank Ltd", label: "The Nedumattam Service Co Operative Bank Ltd" },
  { value: "Gautam Finance Corporation", label: "Gautam Finance Corporation" },
  { value: "M Yogesh Chand Baghmar", label: "M Yogesh Chand Baghmar" },
  { value: "Sundaram Finance Ltd", label: "Sundaram Finance Ltd" },
  { value: "Siddaganga Urban Co Op Bank Ltd", label: "Siddaganga Urban Co Op Bank Ltd" },
  { value: "Irikkur Service Co Operative Bank Ltd", label: "Irikkur Service Co Operative Bank Ltd" },
  { value: "Sahakara Bank Niyamitha", label: "Sahakara Bank Niyamitha" },
  { value: "Dewesh Moto Fin Services", label: "Dewesh Moto Fin Services" },
  { value: "Dewesh Motors", label: "Dewesh Motors" },
  { value: "Vijay Souhard Credit Sahakari Ltd", label: "Vijay Souhard Credit Sahakari Ltd" },
  { value: "Naunton Enterprises", label: "Naunton Enterprises" },
  { value: "Vijaykant Finance", label: "Vijaykant Finance" },
  { value: "The Athavanad Services Co Oprative Bank", label: "The Athavanad Services Co Oprative Bank" },
  { value: "The Muktsar Central Co Op Bank Ltd", label: "The Muktsar Central Co Op Bank Ltd" },
  { value: "Uttranchal State Co Op Bank Ltd", label: "Uttranchal State Co Op Bank Ltd" },
  { value: "The Nattika Service Co Op Bank Ltd", label: "The Nattika Service Co Op Bank Ltd" },
  { value: "The Tirur Co Op Rural Bank Ltd", label: "The Tirur Co Op Rural Bank Ltd" },
  { value: "The Poovathumkadavu Farmers Service Co Op Bank Ltd", label: "The Poovathumkadavu Farmers Service Co Op Bank Ltd" },
  { value: "Poornawadi Nagrik Sahakari Bank Ltd", label: "Poornawadi Nagrik Sahakari Bank Ltd" },
  { value: "Janatha Seva Co Op Bank Ltd", label: "Janatha Seva Co Op Bank Ltd" },
  { value: "Thaniyam Panchayath Service Co Op Bank Ltd", label: "Thaniyam Panchayath Service Co Op Bank Ltd" },
  { value: "Sri Parshwanatha Sahakara Bank Niyamitha", label: "Sri Parshwanatha Sahakara Bank Niyamitha" },
  { value: "Sri Parshwanatha Sahakara Bank", label: "Sri Parshwanatha Sahakara Bank" },
  { value: "Pee Jay Finance Company Limited", label: "Pee Jay Finance Company Limited" },
  { value: "Ajapar Seva Sahkari Mandal Ltd", label: "Ajapar Seva Sahkari Mandal Ltd" },
  { value: "Jay Jalaram Incap Finance Ltd", label: "Jay Jalaram Incap Finance Ltd" },
  { value: "The Savanur Urban Co Op Bank Ltd", label: "The Savanur Urban Co Op Bank Ltd" },
  { value: "Shri Laxmikrupa Urban Co Op Bank Ltd", label: "Shri Laxmikrupa Urban Co Op Bank Ltd" },
  { value: "Vaishya Nagari Sahakari Bank Ltd", label: "Vaishya Nagari Sahakari Bank Ltd" },
  { value: "Pragya Finance", label: "Pragya Finance" },
  { value: "Dewas Shajapur Kshetriya Gramin Bank", label: "Dewas Shajapur Kshetriya Gramin Bank" },
  { value: "The Bundi Central Co Op Bank Ltd", label: "The Bundi Central Co Op Bank Ltd" },
  { value: "Kattangal Co Op Urban Bank Ltd", label: "Kattangal Co Op Urban Bank Ltd" },
  { value: "Dewesh Enterprises", label: "Dewesh Enterprises" },
  { value: "The Kuttikol Service Co Operative Bank Ltd", label: "The Kuttikol Service Co Operative Bank Ltd" },
  { value: "A J Finance Pvt Ltd", label: "A J Finance Pvt Ltd" },
  { value: "The Darussalam Co Op Urban Bank Ltd", label: "The Darussalam Co Op Urban Bank Ltd" },
  { value: "Neelkanth Co Op Bank Ltd", label: "Neelkanth Co Op Bank Ltd" },
  { value: "The Manjoor Service Co Op Bank Ltd", label: "The Manjoor Service Co Op Bank Ltd" },
  { value: "Rajdeep Enterprises", label: "Rajdeep Enterprises" },
  { value: "The Marakkara Service Co Op Bank Ltd", label: "The Marakkara Service Co Op Bank Ltd" },
  { value: "Seshadri Auto Finance", label: "Seshadri Auto Finance" },
  { value: "Ajay Surana", label: "Ajay Surana" },
  { value: "Sanjay Kumar Surana", label: "Sanjay Kumar Surana" },
  { value: "Navkar Associates", label: "Navkar Associates" },
  { value: "The Meghraj Nagarik Sahakari Bank Ltd", label: "The Meghraj Nagarik Sahakari Bank Ltd" },
  { value: "The Pioneer Urban Co Op Bank Ltd", label: "The Pioneer Urban Co Op Bank Ltd" },
  { value: "Porbandar Vibhagiya Nagarik Sahakari Bank Ltd", label: "Porbandar Vibhagiya Nagarik Sahakari Bank Ltd" },
  { value: "The Karnataka Central Co Op Bank Ltd", label: "The Karnataka Central Co Op Bank Ltd" },
  { value: "The Citizen Co Operative Bank Ltd", label: "The Citizen Co Operative Bank Ltd" },
  { value: "Muktai Co Op Bank Ltd", label: "Muktai Co Op Bank Ltd" },
  { value: "Kotak Mahindra Bank Ltd", label: "Kotak Mahindra Bank Ltd" },
  { value: "Royal Bank Of Scotland", label: "Royal Bank Of Scotland" },
  { value: "Bhopal Nagrik Sahakari Bank Limited", label: "Bhopal Nagrik Sahakari Bank Limited" },
  { value: "The Kunnummal Block Employees Co Op Society Ltd", label: "The Kunnummal Block Employees Co Op Society Ltd" },
  { value: "The Bagalkot Co Op Bank Ltd", label: "The Bagalkot Co Op Bank Ltd" },
  { value: "Bilaspur Nagrik Sahakari Bank Ltd", label: "Bilaspur Nagrik Sahakari Bank Ltd" },
  { value: "The Madavoor Service Co Op Bank Ltd", label: "The Madavoor Service Co Op Bank Ltd" },
  { value: "The Chavara Service Co Op Bank Ltd", label: "The Chavara Service Co Op Bank Ltd" },
  { value: "The Meppayur Co Op Town Bank Ltd", label: "The Meppayur Co Op Town Bank Ltd" },
  { value: "Saket Finance", label: "Saket Finance" },
  { value: "The Morazha Kalliasseri Service Co Operative Bank Ltd", label: "The Morazha Kalliasseri Service Co Operative Bank Ltd" },
  { value: "The Pathanamthitta Service Co Operative Bank Ltd", label: "The Pathanamthitta Service Co Operative Bank Ltd" },
  { value: "The Beed District Central Co Op Bank Ltd", label: "The Beed District Central Co Op Bank Ltd" },
  { value: "The Kota Central Co Op Bank Ltd", label: "The Kota Central Co Op Bank Ltd" },
  { value: "Ntpc Ltd", label: "Ntpc Ltd" },
  { value: "The Lakhvad Nagarik Sahakari Bank Ltd", label: "The Lakhvad Nagarik Sahakari Bank Ltd" },
  { value: "The Sri Kannikaparameswari Co Op Bank Ltd", label: "The Sri Kannikaparameswari Co Op Bank Ltd" },
  { value: "Koodal Finance", label: "Koodal Finance" },
  { value: "Amaravathy Service Co Opaerative Bank Ltd", label: "Amaravathy Service Co Opaerative Bank Ltd" },
  { value: "Kaipuzha Service Co Operative Bank Ltd", label: "Kaipuzha Service Co Operative Bank Ltd" },
  { value: "The Idukki Kanjikuzhy Service Co Op Bank Ltd", label: "The Idukki Kanjikuzhy Service Co Op Bank Ltd" },
  { value: "Kamrupa Trade Center", label: "Kamrupa Trade Center" },
  { value: "Bsr Traders", label: "Bsr Traders" },
  { value: "Sangli Vaibhav Co Op Credit Society Ltd", label: "Sangli Vaibhav Co Op Credit Society Ltd" },
  { value: "Thazhekad Service Co Operative Bank", label: "Thazhekad Service Co Operative Bank" },
  { value: "Gbr Motors", label: "Gbr Motors" },
  { value: "The Chokli Service Co Operative Bank Ltd", label: "The Chokli Service Co Operative Bank Ltd" },
  { value: "Uttam Kr Kothary And Sons", label: "Uttam Kr Kothary And Sons" },
  { value: "Maa Tara Enterprise", label: "Maa Tara Enterprise" },
  { value: "Visnagar Lease Finance Co Ltd", label: "Visnagar Lease Finance Co Ltd" },
  { value: "Kabir Roadlines", label: "Kabir Roadlines" },
  { value: "The Pazhuvil Service Co Op Bank Ltd", label: "The Pazhuvil Service Co Op Bank Ltd" },
  { value: "Cheruvattoor Rural Co Op Bank", label: "Cheruvattoor Rural Co Op Bank" },
  { value: "The Thirumittacode Service Co Op Bank", label: "The Thirumittacode Service Co Op Bank" },
  { value: "The Cannanore Co Op Urban Bank Ltd", label: "The Cannanore Co Op Urban Bank Ltd" },
  { value: "Shree Gaondevi Sahakari Pathpedi Ltd", label: "Shree Gaondevi Sahakari Pathpedi Ltd" },
  { value: "Shri Venkateswara Grameena Bank", label: "Shri Venkateswara Grameena Bank" },
  { value: "Smart Hire Purchase", label: "Smart Hire Purchase" },
  { value: "The Jalna Peoples Co Operative Bank Ltd", label: "The Jalna Peoples Co Operative Bank Ltd" },
  { value: "Vinco Finins Pvt Ltd", label: "Vinco Finins Pvt Ltd" },
  { value: "Kanmanam Service Co Op Bank Ltd", label: "Kanmanam Service Co Op Bank Ltd" },
  { value: "The Bilgi Urban Co Op Bank Ltd", label: "The Bilgi Urban Co Op Bank Ltd" },
  { value: "Shivkrupa Sahakari Patpedhi Ltd", label: "Shivkrupa Sahakari Patpedhi Ltd" },
  { value: "The Panamkulam Ettumuna Service Co Op Bank Ltd", label: "The Panamkulam Ettumuna Service Co Op Bank Ltd" },
  { value: "The Shoranur Co Op Urban Bank Ltd", label: "The Shoranur Co Op Urban Bank Ltd" },
  { value: "Shri Mahabaleshwar Co Op Bank Ltd", label: "Shri Mahabaleshwar Co Op Bank Ltd" },
  { value: "The Mugberia Central Co Op Bank Ltd", label: "The Mugberia Central Co Op Bank Ltd" },
  { value: "Dayal Auto Finance", label: "Dayal Auto Finance" },
  { value: "Esbee Growth Funds P Ltd", label: "Esbee Growth Funds P Ltd" },
  { value: "The Dhulia District Central Co Op Bank Ltd", label: "The Dhulia District Central Co Op Bank Ltd" },
  { value: "The Comptrollers Office Co Op Bank Ltd", label: "The Comptrollers Office Co Op Bank Ltd" },
  { value: "The Chithari Service Co Op. Bank Ltd", label: "The Chithari Service Co Op. Bank Ltd" },
  { value: "The Ghoghamba Vibhag Nagrik Sahakari Bank Ltd", label: "The Ghoghamba Vibhag Nagrik Sahakari Bank Ltd" },
  { value: "Mandvi Co Op Bank Ltd", label: "Mandvi Co Op Bank Ltd" },
  { value: "Mahesh Mahila Nagari Sahakari Patsanstha Maryadit", label: "Mahesh Mahila Nagari Sahakari Patsanstha Maryadit" },
  { value: "The Valanchery Service Co Op Bank Ltd", label: "The Valanchery Service Co Op Bank Ltd" },
  { value: "Dreamz Enterprises", label: "Dreamz Enterprises" },
  { value: "Muvattupuzha Urban Co Operative Bank", label: "Muvattupuzha Urban Co Operative Bank" },
  { value: "The Hosdurg Service Co Op Bank Limited", label: "The Hosdurg Service Co Op Bank Limited" },
  { value: "Kattappana Rural Development Co Op Society Ltd", label: "Kattappana Rural Development Co Op Society Ltd" },
  { value: "Lucky Finance", label: "Lucky Finance" },
  { value: "Kat Credits", label: "Kat Credits" },
  { value: "Kat Finance", label: "Kat Finance" },
  { value: "Kedareshwar Nagari Sahakari Patsanstha", label: "Kedareshwar Nagari Sahakari Patsanstha" },
  { value: "Vaipur Service Co Operative Bank Ltd", label: "Vaipur Service Co Operative Bank Ltd" },
  { value: "Rajshri Finance", label: "Rajshri Finance" },
  { value: "Tirunavaya Service Co Operative Bank Ltd", label: "Tirunavaya Service Co Operative Bank Ltd" },
  { value: "Vasantdada Shetkari Sahakari Bank Ltd", label: "Vasantdada Shetkari Sahakari Bank Ltd" },
  { value: "Velimukku Service Co Op Bank", label: "Velimukku Service Co Op Bank" },
  { value: "The Mini Bank Service Co Op Society Ltd", label: "The Mini Bank Service Co Op Society Ltd" },
  { value: "Chaithanya Credit Corporation", label: "Chaithanya Credit Corporation" },
  { value: "The Thidanad Service Co Op Bank Ltd", label: "The Thidanad Service Co Op Bank Ltd" },
  { value: "The Konni Regional Co Op Bank Ltd", label: "The Konni Regional Co Op Bank Ltd" },
  { value: "Sudha Co Op Urban Bank Ltd", label: "Sudha Co Op Urban Bank Ltd" },
  { value: "Peruvanthanam Service Co Op Bank", label: "Peruvanthanam Service Co Op Bank" },
  { value: "Mahanagar Nagrik Sahakari Bank Mydt", label: "Mahanagar Nagrik Sahakari Bank Mydt" },
  { value: "Thirumala Auto Finance", label: "Thirumala Auto Finance" },
  { value: "Govind Auto Finance", label: "Govind Auto Finance" },
  { value: "The Velom Panchayath Service Co Op Bank Ltd", label: "The Velom Panchayath Service Co Op Bank Ltd" },
  { value: "Vishwakarma Nagari Sahakari Bank Ltd. Aurangabad", label: "Vishwakarma Nagari Sahakari Bank Ltd. Aurangabad" },
  { value: "Sri Gangamma Thalli Auto Finance", label: "Sri Gangamma Thalli Auto Finance" },
  { value: "Dhp Leasing Ltd", label: "Dhp Leasing Ltd" },
  { value: "The Gpr Co Op Urban Bank Ltd", label: "The Gpr Co Op Urban Bank Ltd" },
  { value: "Sree Vigneshwara Leasing", label: "Sree Vigneshwara Leasing" },
  { value: "Tanwar Finance Co", label: "Tanwar Finance Co" },
  { value: "Ajanta Finance Co", label: "Ajanta Finance Co" },
  { value: "The Chengaroor Service Co Op Bank Ltd", label: "The Chengaroor Service Co Op Bank Ltd" },
  { value: "The Kuttipuram Service Co Operative Bank Ltd", label: "The Kuttipuram Service Co Operative Bank Ltd" },
  { value: "Sri Gangammathali Auto Finance", label: "Sri Gangammathali Auto Finance" },
  { value: "The Dr Panjabrao Deshmukh Urban Co Oprative Bank Ltd Amravati", label: "The Dr Panjabrao Deshmukh Urban Co Oprative Bank Ltd Amravati" },
  { value: "The Iriveri Service Co Operative Bank Ltd", label: "The Iriveri Service Co Operative Bank Ltd" },
  { value: "Chavassery Service Co Op Bank Ltd", label: "Chavassery Service Co Op Bank Ltd" },
  { value: "The Kaviyoor Service Co Operative Bank Ltd", label: "The Kaviyoor Service Co Operative Bank Ltd" },
  { value: "Shrinath Motors", label: "Shrinath Motors" },
  { value: "J.S.Finance", label: "J.S.Finance" },
  { value: "Paras Finance", label: "Paras Finance" },
  { value: "Lalwani Lease Finance Ltd", label: "Lalwani Lease Finance Ltd" },
  { value: "Rajshree Credit Croporation", label: "Rajshree Credit Croporation" },
  { value: "Indian Credit Co Op Society Ltd", label: "Indian Credit Co Op Society Ltd" },
  { value: "S P N Commercial Pvt Ltd", label: "S P N Commercial Pvt Ltd" },
  { value: "Ms Visnagar Lease Finance Co Ltd", label: "Ms Visnagar Lease Finance Co Ltd" },
  { value: "Ms Kabir Roadlines", label: "Ms Kabir Roadlines" },
  { value: "The Tamilnadu Circle Postal Co Op Bank Ltd", label: "The Tamilnadu Circle Postal Co Op Bank Ltd" },
  { value: "The Dholpur Urban Co Op Bank Ltd", label: "The Dholpur Urban Co Op Bank Ltd" },
  { value: "S Rikab Chand", label: "S Rikab Chand" },
  { value: "Universal Co Op Urban Bank Ltd", label: "Universal Co Op Urban Bank Ltd" },
  { value: "Vazhappally Service Co Op Bank Ltd", label: "Vazhappally Service Co Op Bank Ltd" },
  { value: "Sri Arasi Auto Finance", label: "Sri Arasi Auto Finance" },
  { value: "Senthil Auto Finance", label: "Senthil Auto Finance" },
  { value: "Cauvery Finance", label: "Cauvery Finance" },
  { value: "Shri Maya Finance", label: "Shri Maya Finance" },
  { value: "Sivaraj Finance", label: "Sivaraj Finance" },
  { value: "Sri Siva Selvi Auto Finance", label: "Sri Siva Selvi Auto Finance" },
  { value: "Rajgarhia Auto Finance P Ltd", label: "Rajgarhia Auto Finance P Ltd" },
  { value: "The Vellore Dt. Central Coop", label: "The Vellore Dt. Central Coop" },
  { value: "Saibaba Nagari Sahakari Bank Ltd Sailu", label: "Saibaba Nagari Sahakari Bank Ltd Sailu" },
  { value: "The Chazhoor Service Co Operative Bank Ltd", label: "The Chazhoor Service Co Operative Bank Ltd" },
  { value: "The Thankamany Service Co Op Bank Ltd,Thankamany", label: "The Thankamany Service Co Op Bank Ltd,Thankamany" },
  { value: "Poojya Shri Mahantappagalavar Urban Co Op Credit Society Ny", label: "Poojya Shri Mahantappagalavar Urban Co Op Credit Society Ny" },
  { value: "Krishna Sahakari Bank Ltd", label: "Krishna Sahakari Bank Ltd" },
  { value: "The Manjibail Service Co Op.Bank Ltd", label: "The Manjibail Service Co Op.Bank Ltd" },
  { value: "Janaseva Sahakari Bank Borivli Ltd", label: "Janaseva Sahakari Bank Borivli Ltd" },
  { value: "Hinduja Finance Corporation Limited", label: "Hinduja Finance Corporation Limited" },
  { value: "The Manmandir Co Op Bank Ltd", label: "The Manmandir Co Op Bank Ltd" },
  { value: "The Majibail Service Co Op Bank Ltd", label: "The Majibail Service Co Op Bank Ltd" },
  { value: "Dnyaneshwar Mauli Sah Pathpedhi Ltd", label: "Dnyaneshwar Mauli Sah Pathpedhi Ltd" },
  { value: "Navyug Vikas Nagari Sahakari Patpedhi Ltd", label: "Navyug Vikas Nagari Sahakari Patpedhi Ltd" },
  { value: "Subhash Baghmar", label: "Subhash Baghmar" },
  { value: "The Panchkula Urban Co Op Bank Ltd.", label: "The Panchkula Urban Co Op Bank Ltd." },
  { value: "Uae Exchange And Financial Services Ltd", label: "Uae Exchange And Financial Services Ltd" },
  { value: "Garh Pratapnagar Ghatal Nischindipur Skus Ltd", label: "Garh Pratapnagar Ghatal Nischindipur Skus Ltd" },
  { value: "The Shertallan South Service Co Op Bank Ltd", label: "The Shertallan South Service Co Op Bank Ltd" },
  { value: "Madappally Service Co Op Bank Ltd", label: "Madappally Service Co Op Bank Ltd" },
  { value: "Ap Transco Vijayawada", label: "Ap Transco Vijayawada" },
  { value: "The Vechoor Panchatath Service Co Operative Bank Ltd", label: "The Vechoor Panchatath Service Co Operative Bank Ltd" },
  { value: "Imbichibava Memorial Ruiral Co Op Society Ltd", label: "Imbichibava Memorial Ruiral Co Op Society Ltd" },
  { value: "The Mannarkkad Sahakarana Urban Credit Society Ltd", label: "The Mannarkkad Sahakarana Urban Credit Society Ltd" },
  { value: "The Mananthavady Farmers Service Co Op Bank Ltd", label: "The Mananthavady Farmers Service Co Op Bank Ltd" },
  { value: "The Kommeri Service Co Op Bank Ltd", label: "The Kommeri Service Co Op Bank Ltd" },
  { value: "G Somchand Nahar", label: "G Somchand Nahar" },
  { value: "Karayad Service Co Operative Bank Ltd", label: "Karayad Service Co Operative Bank Ltd" },
  { value: "The Maneed Service Co Op Bank Ltd", label: "The Maneed Service Co Op Bank Ltd" },
  { value: "Merchants Liberal Co Opearative Bank Ltd", label: "Merchants Liberal Co Opearative Bank Ltd" },
  { value: "Gv Finance Company", label: "Gv Finance Company" },
  { value: "Sant Namdev Nagari Sahakari Pat Sanstha Maryadit", label: "Sant Namdev Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "The Jintur Urban Co Op Bank Ltd Jintur", label: "The Jintur Urban Co Op Bank Ltd Jintur" },
  { value: "Kayanna Service Co Op Bank Ltd", label: "Kayanna Service Co Op Bank Ltd" },
  { value: "J P More And Sons", label: "J P More And Sons" },
  { value: "The Narath Service Co Op Bank Ltd", label: "The Narath Service Co Op Bank Ltd" },
  { value: "Pananchery Service Co Op Bank Ltd", label: "Pananchery Service Co Op Bank Ltd" },
  { value: "Kannimala Service Co Op Bank Ltd", label: "Kannimala Service Co Op Bank Ltd" },
  { value: "Changaroth Panchayat Service Co Op Bank Ltd", label: "Changaroth Panchayat Service Co Op Bank Ltd" },
  { value: "Shankeshwar Co", label: "Shankeshwar Co" },
  { value: "The Babasaheb Deshmukh Sah Bank Ltd Atpadi", label: "The Babasaheb Deshmukh Sah Bank Ltd Atpadi" },
  { value: "Raigad Janata Nagari Sahakari Patpedhi Maryadit", label: "Raigad Janata Nagari Sahakari Patpedhi Maryadit" },
  { value: "Brainwave Adverts", label: "Brainwave Adverts" },
  { value: "The Payyanur Co Op Town Bank Ltd", label: "The Payyanur Co Op Town Bank Ltd" },
  { value: "The Kaduthuruthy Karshika Service Co Op Bank", label: "The Kaduthuruthy Karshika Service Co Op Bank" },
  { value: "Padam Motor Finance Co", label: "Padam Motor Finance Co" },
  { value: "Sutlej Gramin Bank", label: "Sutlej Gramin Bank" },
  { value: "Sthaneshwar Fin Co", label: "Sthaneshwar Fin Co" },
  { value: "Laxmi Sahakari Bank Niyamit", label: "Laxmi Sahakari Bank Niyamit" },
  { value: "The Kakkattil Co Operative Rural Bank Ltd", label: "The Kakkattil Co Operative Rural Bank Ltd" },
  { value: "Daljeet Secura Finance Company", label: "Daljeet Secura Finance Company" },
  { value: "Samarth Sahakari Patpedhi Maryadit", label: "Samarth Sahakari Patpedhi Maryadit" },
  { value: "Ongc Ltd", label: "Ongc Ltd" },
  { value: "Bhilai Nagrik Sahakari Bank Maryadit", label: "Bhilai Nagrik Sahakari Bank Maryadit" },
  { value: "Wana Nagrik Sahakari Bank Ltd", label: "Wana Nagrik Sahakari Bank Ltd" },
  { value: "Kanayannur Service Co Op Bank Ltd", label: "Kanayannur Service Co Op Bank Ltd" },
  { value: "Kayiliad Service Co Operative Bank Ltd", label: "Kayiliad Service Co Operative Bank Ltd" },
  { value: "Paras Trading And Co", label: "Paras Trading And Co" },
  { value: "Kedarnath Urban Co Op Bank Ltd", label: "Kedarnath Urban Co Op Bank Ltd" },
  { value: "Bharat Kumar", label: "Bharat Kumar" },
  { value: "Lloyds Finance Ltd", label: "Lloyds Finance Ltd" },
  { value: "Junagadh Jilla Sahakari Bank Ltd", label: "Junagadh Jilla Sahakari Bank Ltd" },
  { value: "Riddhi Siddhi Syndicate", label: "Riddhi Siddhi Syndicate" },
  { value: "Emirates Nbd Bank", label: "Emirates Nbd Bank" },
  { value: "Rajendra Kumar Surendra Kumar", label: "Rajendra Kumar Surendra Kumar" },
  { value: "The Vellangallur Service Co Op Bank Ltd", label: "The Vellangallur Service Co Op Bank Ltd" },
  { value: "Executive Engineer Transmission Division, Getco", label: "Executive Engineer Transmission Division, Getco" },
  { value: "Archana Investments", label: "Archana Investments" },
  { value: "Magma Fincorp Limited", label: "Magma Fincorp Limited" },
  { value: "The Kallamkunnu Service Co Op Bank Ltd", label: "The Kallamkunnu Service Co Op Bank Ltd" },
  { value: "The Ammapet Urban Co Op Bank Ltd", label: "The Ammapet Urban Co Op Bank Ltd" },
  { value: "Hiesh Investment", label: "Hiesh Investment" },
  { value: "Mas Finance And Services Ltd", label: "Mas Finance And Services Ltd" },
  { value: "Walchandnagar Sahakari Bank Ltd", label: "Walchandnagar Sahakari Bank Ltd" },
  { value: "Hinduja Leyland Finance Limited", label: "Hinduja Leyland Finance Limited" },
  { value: "The Vadgaon Urban Co Op Bank Ltd", label: "The Vadgaon Urban Co Op Bank Ltd" },
  { value: "The Sholapur Social Urban Co Op Bank Ltd,Solapur", label: "The Sholapur Social Urban Co Op Bank Ltd,Solapur" },
  { value: "Delta Finance", label: "Delta Finance" },
  { value: "Bazaari Global Finance Ltd", label: "Bazaari Global Finance Ltd" },
  { value: "Barrackpore Investors", label: "Barrackpore Investors" },
  { value: "Gail India Limited", label: "Gail India Limited" },
  { value: "Cheminova India Ltd", label: "Cheminova India Ltd" },
  { value: "The Dahod Mercantile Co Op. Bank Ltd", label: "The Dahod Mercantile Co Op. Bank Ltd" },
  { value: "The Chururu Baheri Co Op Agr Ser Society Ltd.", label: "The Chururu Baheri Co Op Agr Ser Society Ltd." },
  { value: "The Perinthalmanna Co Op Urban Bank Ltd", label: "The Perinthalmanna Co Op Urban Bank Ltd" },
  { value: "Paras Finlease", label: "Paras Finlease" },
  { value: "Shree Yashvant Sahakari Bank Maryadit", label: "Shree Yashvant Sahakari Bank Maryadit" },
  { value: "Nhpc Ltd", label: "Nhpc Ltd" },
  { value: "The Jain Sahakari Bank Limited", label: "The Jain Sahakari Bank Limited" },
  { value: "Shiv Shakti Co Op Credit Soc Ltd.", label: "Shiv Shakti Co Op Credit Soc Ltd." },
  { value: "Bajaj Finance Ltd", label: "Bajaj Finance Ltd" },
  { value: "The Kiliyanthara Service Co Op Bank Ltd", label: "The Kiliyanthara Service Co Op Bank Ltd" },
  { value: "The Shiggaon Urban Co Op Bank Ltd", label: "The Shiggaon Urban Co Op Bank Ltd" },
  { value: "Jay Tuljabhavani Urban Co Op Bank Ltd", label: "Jay Tuljabhavani Urban Co Op Bank Ltd" },
  { value: "The Kudakkachira Service Co Operative Bank Ltd, Kudakkachira Branch", label: "The Kudakkachira Service Co Operative Bank Ltd, Kudakkachira Branch" },
  { value: "Fullerton India Credit Coltd", label: "Fullerton India Credit Coltd" },
  { value: "Ramawatar Didwania", label: "Ramawatar Didwania" },
  { value: "The Dhandwar Co Op Agree Multipurpose Society Ltd", label: "The Dhandwar Co Op Agree Multipurpose Society Ltd" },
  { value: "Ms Globe Finance", label: "Ms Globe Finance" },
  { value: "Ayush Finance Corporation", label: "Ayush Finance Corporation" },
  { value: "Kangazha Service Co Operative Bank Ltd", label: "Kangazha Service Co Operative Bank Ltd" },
  { value: "Kumarakom Vadakkumbhagom Service Co Operative Bank Ltd", label: "Kumarakom Vadakkumbhagom Service Co Operative Bank Ltd" },
  { value: "The Kakkoor Service Co Operative Bank", label: "The Kakkoor Service Co Operative Bank" },
  { value: "Bank Of Maharastra", label: "Bank Of Maharastra" },
  { value: "Valsad Dangs Gramin Bank", label: "Valsad Dangs Gramin Bank" },
  { value: "Akshaya Co Operative Credit Society Ltd", label: "Akshaya Co Operative Credit Society Ltd" },
  { value: "The District Co Op Central Bank Ltd", label: "The District Co Op Central Bank Ltd" },
  { value: "Seyad Shariat Finance Limited", label: "Seyad Shariat Finance Limited" },
  { value: "The Chikhli Urban Co Op Bank Ltd", label: "The Chikhli Urban Co Op Bank Ltd" },
  { value: "The Ranipet Town Co Op. Bank Ltd", label: "The Ranipet Town Co Op. Bank Ltd" },
  { value: "Parmarathi Finance", label: "Parmarathi Finance" },
  { value: "Laxmi Urban Co Operative Bank Ltd", label: "Laxmi Urban Co Operative Bank Ltd" },
  { value: "Sri Guru Raghavendra Sahakara Bank Niyamitha", label: "Sri Guru Raghavendra Sahakara Bank Niyamitha" },
  { value: "Lasalgaon Gramin Bigarsheti Sahkari Patsanstha Maryadit", label: "Lasalgaon Gramin Bigarsheti Sahkari Patsanstha Maryadit" },
  { value: "Azabpur Kalan Kishan Seva Sahakari Samiti Ltd", label: "Azabpur Kalan Kishan Seva Sahakari Samiti Ltd" },
  { value: "The Kanchiyar Service Co Operative Bank Ltd", label: "The Kanchiyar Service Co Operative Bank Ltd" },
  { value: "The Dhinoj Nagarik Bank Ltd", label: "The Dhinoj Nagarik Bank Ltd" },
  { value: "Sanjay Investment Corporation", label: "Sanjay Investment Corporation" },
  { value: "Pioneer Finance Company Pvt Ltd", label: "Pioneer Finance Company Pvt Ltd" },
  { value: "The Jodhpur Central Co Operative Bank Ltd", label: "The Jodhpur Central Co Operative Bank Ltd" },
  { value: "Ala Service Co Operative Bank Ltd", label: "Ala Service Co Operative Bank Ltd" },
  { value: "The Ananthavoor Service Cooperative Bank Ltd", label: "The Ananthavoor Service Cooperative Bank Ltd" },
  { value: "Shree Sevashakti Gramin Bigarsheti Sahkari Pantsanstha", label: "Shree Sevashakti Gramin Bigarsheti Sahkari Pantsanstha" },
  { value: "Sonhira Sahakari Pathpedi Maryadit", label: "Sonhira Sahakari Pathpedi Maryadit" },
  { value: "The Tiruvalla Urban Co Op Bank Ltd", label: "The Tiruvalla Urban Co Op Bank Ltd" },
  { value: "The Coimbatore City Co Bank Ltd", label: "The Coimbatore City Co Bank Ltd" },
  { value: "The Paravoor Snv Regional Co Op Bank Ltd", label: "The Paravoor Snv Regional Co Op Bank Ltd" },
  { value: "Shri Janata Sahakari Bank Ltd", label: "Shri Janata Sahakari Bank Ltd" },
  { value: "Karamuck Service Co Operative Bank Ltd", label: "Karamuck Service Co Operative Bank Ltd" },
  { value: "Sindhudurg Co Op Bank Ltd", label: "Sindhudurg Co Op Bank Ltd" },
  { value: "The Poothady Service Co Op Bank Ltd", label: "The Poothady Service Co Op Bank Ltd" },
  { value: "The Chiplun Urban Co Op Bank Ltd", label: "The Chiplun Urban Co Op Bank Ltd" },
  { value: "Steel Authority Of India Ltd", label: "Steel Authority Of India Ltd" },
  { value: "The Changanacherry Taluk Vyapari Vyavasai Co Operative Society Ltd", label: "The Changanacherry Taluk Vyapari Vyavasai Co Operative Society Ltd" },
  { value: "Sail Dsp", label: "Sail Dsp" },
  { value: "Manba Finance Ltd", label: "Manba Finance Ltd" },
  { value: "Jijamata Mahila Sahakari Bank Ltd", label: "Jijamata Mahila Sahakari Bank Ltd" },
  { value: "The Limbasi Urban Co Op Bank Ltd", label: "The Limbasi Urban Co Op Bank Ltd" },
  { value: "District Coop Bank Ltd", label: "District Coop Bank Ltd" },
  { value: "Chethallur Service Co Op Bank Ltd", label: "Chethallur Service Co Op Bank Ltd" },
  { value: "Keshrichand P Associates", label: "Keshrichand P Associates" },
  { value: "Reliance Capital Ltd", label: "Reliance Capital Ltd" },
  { value: "Tata Capital Pvt Ltd", label: "Tata Capital Pvt Ltd" },
  { value: "The Karapuzha Service Co Operative Bank Ltd", label: "The Karapuzha Service Co Operative Bank Ltd" },
  { value: "Jaisri Auto Finance", label: "Jaisri Auto Finance" },
  { value: "Sarvodaya Sahkari Sharafi Mandali Ltd", label: "Sarvodaya Sahkari Sharafi Mandali Ltd" },
  { value: "The Pottankad Services Co Op Bank Ltd", label: "The Pottankad Services Co Op Bank Ltd" },
  { value: "A K Investment", label: "A K Investment" },
  { value: "Shri Ram Equipment Fin Co Ltd", label: "Shri Ram Equipment Fin Co Ltd" },
  { value: "Tata Finance", label: "Tata Finance" },
  { value: "Shree Bharat Co Op Bank Ltd", label: "Shree Bharat Co Op Bank Ltd" },
  { value: "Shirsoli Sahkari Patpedhi Limited", label: "Shirsoli Sahkari Patpedhi Limited" },
  { value: "I G Corporation", label: "I G Corporation" },
  { value: "The Mayani Urban Co Op Bank Ltd", label: "The Mayani Urban Co Op Bank Ltd" },
  { value: "Janata Sahakari Bank Ltd Satara", label: "Janata Sahakari Bank Ltd Satara" },
  { value: "Indian Oil Corparation Ltd", label: "Indian Oil Corparation Ltd" },
  { value: "The Lunawada Nagarik Sahakari Bank Ltd", label: "The Lunawada Nagarik Sahakari Bank Ltd" },
  { value: "The Edamuttam Service Co Op Bank Ltd", label: "The Edamuttam Service Co Op Bank Ltd" },
  { value: "Ms Ashapura Finance", label: "Ms Ashapura Finance" },
  { value: "Mamani Investo Pvt Ltd", label: "Mamani Investo Pvt Ltd" },
  { value: "Ajay Singla Huf", label: "Ajay Singla Huf" },
  { value: "Self Ugvcl Company", label: "Self Ugvcl Company" },
  { value: "L And T Finance Ltd", label: "L And T Finance Ltd" },
  { value: "Sanmitra Mahila Nagari Sahakari Bank Ltd", label: "Sanmitra Mahila Nagari Sahakari Bank Ltd" },
  { value: "The Kodoli Urban Co Op Bank Ltd", label: "The Kodoli Urban Co Op Bank Ltd" },
  { value: "Adarniya P D Patilsaheb Bank Lit Karad", label: "Adarniya P D Patilsaheb Bank Lit Karad" },
  { value: "The Patan Urban Co Op Bank Ltd", label: "The Patan Urban Co Op Bank Ltd" },
  { value: "Jath Urban Co Operative Bank Ltd", label: "Jath Urban Co Operative Bank Ltd" },
  { value: "The Koottumugham Service Co Op Bank Ltd", label: "The Koottumugham Service Co Op Bank Ltd" },
  { value: "The Angadimogar Service Co Operative Bank Ltd", label: "The Angadimogar Service Co Operative Bank Ltd" },
  { value: "Agif", label: "Agif" },
  { value: "Keezhallur Service Co Operative Bank", label: "Keezhallur Service Co Operative Bank" },
  { value: "Nesar Chitfunds And Finance", label: "Nesar Chitfunds And Finance" },
  { value: "Jankalyan Nagari Sahakari Patsanstha Maryadit", label: "Jankalyan Nagari Sahakari Patsanstha Maryadit" },
  { value: "Swatantrya Senani Late Shripal Alase Kaka Kurundwad Urban Co Op Bank Ltd", label: "Swatantrya Senani Late Shripal Alase Kaka Kurundwad Urban Co Op Bank Ltd" },
  { value: "The Alathur Primary Co Op Agri Bank", label: "The Alathur Primary Co Op Agri Bank" },
  { value: "Shree Basaveshwar Urban Co Operative Bank Ltd", label: "Shree Basaveshwar Urban Co Operative Bank Ltd" },
  { value: "Shri Saraswati Sarvasayik Nagari Sahakari Patsanstha Maryadit", label: "Shri Saraswati Sarvasayik Nagari Sahakari Patsanstha Maryadit" },
  { value: "Dhakuria Co Operative Bank Ltd", label: "Dhakuria Co Operative Bank Ltd" },
  { value: "Karassery Service Co Operative Bank Ltd", label: "Karassery Service Co Operative Bank Ltd" },
  { value: "Procter And Gamble Home Products", label: "Procter And Gamble Home Products" },
  { value: "Gomti Gramin Bank", label: "Gomti Gramin Bank" },
  { value: "Securities And Exchange Board Of India", label: "Securities And Exchange Board Of India" },
  { value: "The Konark Urban Co Op Bank Ltd", label: "The Konark Urban Co Op Bank Ltd" },
  { value: "The Palghat Co Operative Urban Bank Lt", label: "The Palghat Co Operative Urban Bank Lt" },
  { value: "The Aryapuram Co Operative Urban Bank Ltd", label: "The Aryapuram Co Operative Urban Bank Ltd" },
  { value: "Kuber Auto Co", label: "Kuber Auto Co" },
  { value: "Priya And Co", label: "Priya And Co" },
  { value: "G C Finance", label: "G C Finance" },
  { value: "Kamdhenu Investment", label: "Kamdhenu Investment" },
  { value: "Mahakali Distributors Pvt Ltd", label: "Mahakali Distributors Pvt Ltd" },
  { value: "The Thrichur Urban Co Operative Bank Ltd", label: "The Thrichur Urban Co Operative Bank Ltd" },
  { value: "Kuber Auto Co.", label: "Kuber Auto Co." },
  { value: "Magma Fincap Ltd", label: "Magma Fincap Ltd" },
  { value: "Kukarvada Nagarik Co Op Bank Ltd", label: "Kukarvada Nagarik Co Op Bank Ltd" },
  { value: "Sri Lakshmi Credit Corporation", label: "Sri Lakshmi Credit Corporation" },
  { value: "Velmurugan Finance", label: "Velmurugan Finance" },
  { value: "Siva Finance", label: "Siva Finance" },
  { value: "K R S Corporation", label: "K R S Corporation" },
  { value: "Cholamandalam Investment And Finance Co Ltd", label: "Cholamandalam Investment And Finance Co Ltd" },
  { value: "Vaiyagam Finance", label: "Vaiyagam Finance" },
  { value: "Senthamil Finance", label: "Senthamil Finance" },
  { value: "Tata Motors Finance Ltd", label: "Tata Motors Finance Ltd" },
  { value: "Tata Finance Ltd", label: "Tata Finance Ltd" },
  { value: "Sudar Oli Finance", label: "Sudar Oli Finance" },
  { value: "Bvs Financier", label: "Bvs Financier" },
  { value: "Sri Lakshmi Finance", label: "Sri Lakshmi Finance" },
  { value: "Lakshmi Auto Finance", label: "Lakshmi Auto Finance" },
  { value: "Kandhan Auto Finance", label: "Kandhan Auto Finance" },
  { value: "Sri Siva Finance", label: "Sri Siva Finance" },
  { value: "Sri Amman Auto Leasing", label: "Sri Amman Auto Leasing" },
  { value: "Vanniar Auto Finance", label: "Vanniar Auto Finance" },
  { value: "Balamurugan Auto Finance", label: "Balamurugan Auto Finance" },
  { value: "Sri Annamar Finance", label: "Sri Annamar Finance" },
  { value: "Sri Sakthi Financier", label: "Sri Sakthi Financier" },
  { value: "Er Financial Services", label: "Er Financial Services" },
  { value: "Velava Auto Finance", label: "Velava Auto Finance" },
  { value: "Marutham Auto Finance", label: "Marutham Auto Finance" },
  { value: "Amaravathi Hire Purchase Financier", label: "Amaravathi Hire Purchase Financier" },
  { value: "Bharani Finance", label: "Bharani Finance" },
  { value: "Bhavani Gounder Auto Finance", label: "Bhavani Gounder Auto Finance" },
  { value: "Gounder Auto Finance", label: "Gounder Auto Finance" },
  { value: "Gac Finance", label: "Gac Finance" },
  { value: "Balu Finance", label: "Balu Finance" },
  { value: "The Bhavani Gounder Auto Finance", label: "The Bhavani Gounder Auto Finance" },
  { value: "Amaravathi Hire Purchese Financiers", label: "Amaravathi Hire Purchese Financiers" },
  { value: "Velavaa Auto Finanace", label: "Velavaa Auto Finanace" },
  { value: "Shri Suguna Auto Finanace", label: "Shri Suguna Auto Finanace" },
  { value: "Alagu Auto Finanace", label: "Alagu Auto Finanace" },
  { value: "Muthukuviyal Finance", label: "Muthukuviyal Finance" },
  { value: "Shri Kumaran Auto Consulting", label: "Shri Kumaran Auto Consulting" },
  { value: "Sri Thirumalai Andavar Finance", label: "Sri Thirumalai Andavar Finance" },
  { value: "Suriyan Finance", label: "Suriyan Finance" },
  { value: "Thiru Kumaran Motor Finance", label: "Thiru Kumaran Motor Finance" },
  { value: "Viruchum Auto Credits", label: "Viruchum Auto Credits" },
  { value: "Jai Sri Auto Finance", label: "Jai Sri Auto Finance" },
  { value: "Tvs Credit Finance And Services Ltd", label: "Tvs Credit Finance And Services Ltd" },
  { value: "Sri Ram Auto Finanace", label: "Sri Ram Auto Finanace" },
  { value: "Sri Saranya Auto Finance", label: "Sri Saranya Auto Finance" },
  { value: "Sri Saranya Auto Finanace Corpretion Ltd", label: "Sri Saranya Auto Finanace Corpretion Ltd" },
  { value: "L Dilip Kumar Finance", label: "L Dilip Kumar Finance" },
  { value: "Archana Dilip Finance", label: "Archana Dilip Finance" },
  { value: "Kumaraguru Auto Finance", label: "Kumaraguru Auto Finance" },
  { value: "A.A.Auto Finance", label: "A.A.Auto Finance" },
  { value: "Dhalavaipalayam Primary Agricultural Co Operative Credit Society Ltd", label: "Dhalavaipalayam Primary Agricultural Co Operative Credit Society Ltd" },
  { value: "B K Finance", label: "B K Finance" },
  { value: "B V S Financers", label: "B V S Financers" },
  { value: "Cholamandalam Dbs Finance Ltd", label: "Cholamandalam Dbs Finance Ltd" },
  { value: "I.G.Corporation", label: "I.G.Corporation" },
  { value: "Lakshmi Balaji Auto Finance", label: "Lakshmi Balaji Auto Finance" },
  { value: "Mb Finance", label: "Mb Finance" },
  { value: "Senthil Auto Finance", label: "Senthil Auto Finance" },
  { value: "Senthilvel Auto Finance", label: "Senthilvel Auto Finance" },
  { value: "Shri Finance", label: "Shri Finance" },
  { value: "Shri Ram Transport Finance Co.Ltd", label: "Shri Ram Transport Finance Co.Ltd" },
  { value: "Sriram City Union Finance Ltd", label: "Sriram City Union Finance Ltd" },
  { value: "Udhaya Finance", label: "Udhaya Finance" },
  { value: "Adithya Motor Financier", label: "Adithya Motor Financier" },
  { value: "Ambay Finance", label: "Ambay Finance" },
  { value: "Bussan Auto Finance India P Ltd", label: "Bussan Auto Finance India P Ltd" },
  { value: "Chettinadu Auto Services", label: "Chettinadu Auto Services" },
  { value: "Dhanalaxmi Finance", label: "Dhanalaxmi Finance" },
  { value: "Epr Associates", label: "Epr Associates" },
  { value: "Fullerton India Credit Co. Ltd", label: "Fullerton India Credit Co. Ltd" },
  { value: "Hinduja Layland Finance Ltd", label: "Hinduja Layland Finance Ltd" },
  { value: "Kumara Guru Auto Finance", label: "Kumara Guru Auto Finance" },
  { value: "M.K.Auto Finance", label: "M.K.Auto Finance" },
  { value: "Malar Finance", label: "Malar Finance" },
  { value: "Muthoot Capital Services Ltd", label: "Muthoot Capital Services Ltd" },
  { value: "S.K. Investment", label: "S.K. Investment" },
  { value: "Sree Dhanalakshmi Credits", label: "Sree Dhanalakshmi Credits" },
  { value: "Sree Dhanalaxmi Syndicate", label: "Sree Dhanalaxmi Syndicate" },
  { value: "Sun Finance", label: "Sun Finance" },
  { value: "The Balaji Financiers", label: "The Balaji Financiers" },
  { value: "Tvs Credit Services Ltd", label: "Tvs Credit Services Ltd" },
  { value: "Velavaa Auto Finance", label: "Velavaa Auto Finance" },
  { value: "A.L.F", label: "A.L.F" },
  { value: "Abu Bikes", label: "Abu Bikes" },
  { value: "Amrutha Auto Finance", label: "Amrutha Auto Finance" },
  { value: "Assets Finance Pvt Ltd", label: "Assets Finance Pvt Ltd" },
  { value: "Family Credit Ltd", label: "Family Credit Ltd" },
  { value: "Gugaliya Finance", label: "Gugaliya Finance" },
  { value: "J K D Subha Lakshmi Finance", label: "J K D Subha Lakshmi Finance" },
  { value: "Lodha Finance", label: "Lodha Finance" },
  { value: "Rajagopal Finance And Leasing", label: "Rajagopal Finance And Leasing" },
  { value: "S A  Auto Services", label: "S A  Auto Services" },
  { value: "Senthil Finance", label: "Senthil Finance" },
  { value: "Sneha Sangama Pattina Sahakara Sangha Niyamitha", label: "Sneha Sangama Pattina Sahakara Sangha Niyamitha" },
  { value: "Sri Lingam Auto Corporation", label: "Sri Lingam Auto Corporation" },
  { value: "Sri Panduranga Credit Co-Op Socitety Ltd", label: "Sri Panduranga Credit Co-Op Socitety Ltd" },
  { value: "The Balaji & Co", label: "The Balaji & Co" },
  { value: "Tiruppur Auto Credits", label: "Tiruppur Auto Credits" },
  { value: "Velayutha Samy Finance", label: "Velayutha Samy Finance" },
  { value: "Venus Finance", label: "Venus Finance" },
  { value: "Govindarajan And Co.", label: "Govindarajan And Co." },
  { value: "Kamadenu Leasing", label: "Kamadenu Leasing" },
  { value: "L Dhilip Kumar Finance", label: "L Dhilip Kumar Finance" },
  { value: "Lotus Finance", label: "Lotus Finance" },
  { value: "Madhu Motor Finance", label: "Madhu Motor Finance" },
  { value: "Mahaveer Investment", label: "Mahaveer Investment" },
  { value: "Prashant Investments", label: "Prashant Investments" },
  { value: "Shaan Finance Ltd", label: "Shaan Finance Ltd" },
  { value: "Shri Ram City Union Finance Ltd", label: "Shri Ram City Union Finance Ltd" },
  { value: "Sivasakthi Motor Credit", label: "Sivasakthi Motor Credit" },
  { value: "Star Investments", label: "Star Investments" },
  { value: "Arul Sakthi Auto Finanace", label: "Arul Sakthi Auto Finanace" },
  { value: "Gokulam Auto Finance", label: "Gokulam Auto Finance" },
  { value: "Jai Sakthi Auto Finance", label: "Jai Sakthi Auto Finance" },
  { value: "N.B.B.Auto Finance", label: "N.B.B.Auto Finance" },
  { value: "Kalaimagal Auto Finance", label: "Kalaimagal Auto Finance" },
  { value: "Kuberan Auto Finance", label: "Kuberan Auto Finance" },
  { value: "S.K.Thirumagal Auto Finance", label: "S.K.Thirumagal Auto Finance" },
  { value: "Sri Sivanmalai Aandavar Auto Finance", label: "Sri Sivanmalai Aandavar Auto Finance" },
  { value: "Ponnazhaku Motor Finance", label: "Ponnazhaku Motor Finance" },
  { value: "M B Finance", label: "M B Finance" },
  { value: "T C Prathapmal", label: "T C Prathapmal" },
  { value: "P Ajiet Huf Finance", label: "P Ajiet Huf Finance" },
  { value: "P Ajiet Huf", label: "P Ajiet Huf" },
  { value: "Bajaj Auto Finance Limited", label: "Bajaj Auto Finance Limited" },
  { value: "Sri Vaishnavi Finance", label: "Sri Vaishnavi Finance" },
  { value: "Mahendra Kumar", label: "Mahendra Kumar" },
  { value: "Jain Finance", label: "Jain Finance" },
  { value: "Indra Bai", label: "Indra Bai" },
  { value: "Indra Bai Financier", label: "Indra Bai Financier" },
  { value: "Mahaveer Finance", label: "Mahaveer Finance" },
  { value: "Active Finance And Investments", label: "Active Finance And Investments" },
  { value: "Annai Auto Finance", label: "Annai Auto Finance" },
  { value: "Sri Vaishnavi Auto Finance", label: "Sri Vaishnavi Auto Finance" },
  { value: "Sri Panduranga Credit Co Op Society Ltd", label: "Sri Panduranga Credit Co Op Society Ltd" },
  { value: "Swamy Vivekananda Credit Co Op Society Ltd", label: "Swamy Vivekananda Credit Co Op Society Ltd" },
  { value: "Tumkur Merchants Souharda Credit Co Op Ltd", label: "Tumkur Merchants Souharda Credit Co Op Ltd" },
  { value: "Sneha Sangama Souhada Credit Co Op Ltd", label: "Sneha Sangama Souhada Credit Co Op Ltd" },
  { value: "Shivashree Souhada Credit Co Op Ltd", label: "Shivashree Souhada Credit Co Op Ltd" },
  { value: "Momin Credit Co Op Society Ltd", label: "Momin Credit Co Op Society Ltd" },
  { value: "Sri Basaveshwara Credit Co Op Society Ltd", label: "Sri Basaveshwara Credit Co Op Society Ltd" },
  { value: "Sri Krishna Pattina Shakara Sangha Niyamitha", label: "Sri Krishna Pattina Shakara Sangha Niyamitha" },
  { value: "Maharshi Valmiki Pattina Sahakara Sangha Niyamitha", label: "Maharshi Valmiki Pattina Sahakara Sangha Niyamitha" },
  { value: "Sri Shankari Pattina Sahakara Sangha Niyamitha", label: "Sri Shankari Pattina Sahakara Sangha Niyamitha" },
  { value: "G C Auto Com", label: "G C Auto Com" },
  { value: "G C Finance Co", label: "G C Finance Co" },
  { value: "Mercury Finance", label: "Mercury Finance" },
  { value: "Selva Kumaran Finance", label: "Selva Kumaran Finance" },
  { value: "Venus Enterprises", label: "Venus Enterprises" },
  { value: "Velan Finance", label: "Velan Finance" },
  { value: "Deposit Insurance And Credit Guarantee Corporation Of India", label: "Deposit Insurance And Credit Guarantee Corporation Of India" },
  { value: "Ahmedabad Vegi Gen Com Agent Co Op Society Ltd", label: "Ahmedabad Vegi Gen Com Agent Co Op Society Ltd" },
  { value: "The Kararinakam Service Co Operative Bank Limited", label: "The Kararinakam Service Co Operative Bank Limited" },
  { value: "Jhalod Vaypar Udoya Vikas Sahakari Bank Ltd", label: "Jhalod Vaypar Udoya Vikas Sahakari Bank Ltd" },
  { value: "The Irinave Service Co Operative Bank Limited", label: "The Irinave Service Co Operative Bank Limited" },
  { value: "D Y Patil Sahakari Bank Ltd", label: "D Y Patil Sahakari Bank Ltd" },
  { value: "Dhanlaxmi Bank Ltd", label: "Dhanlaxmi Bank Ltd" },
  { value: "Kollad Service Co Op Bank Ltd", label: "Kollad Service Co Op Bank Ltd" },
  { value: "M L Soni And Sons", label: "M L Soni And Sons" },
  { value: "The Nabadwip Co Op Credit Bank Ltd", label: "The Nabadwip Co Op Credit Bank Ltd" },
  { value: "Agrahari Traders", label: "Agrahari Traders" },
  { value: "Bhel Employee Co Op Credit Society Ltd", label: "Bhel Employee Co Op Credit Society Ltd" },
  { value: "The Changanacherry Co Operative Urban Bank Ltd", label: "The Changanacherry Co Operative Urban Bank Ltd" },
  { value: "The Parur Co Op Bank Ltd", label: "The Parur Co Op Bank Ltd" },
  { value: "The Dehgam Nagarik Sahkari Sharafi Mandali Ltd", label: "The Dehgam Nagarik Sahkari Sharafi Mandali Ltd" },
  { value: "Krunal Construction And Finance Ltd", label: "Krunal Construction And Finance Ltd" },
  { value: "The Kuruvambalam Service Co Operative Bank Ltd", label: "The Kuruvambalam Service Co Operative Bank Ltd" },
  { value: "Tata Steel Limited", label: "Tata Steel Limited" },
  { value: "Chhallani Mahaveer Chand", label: "Chhallani Mahaveer Chand" },
  { value: "Janata Sahakari Bank Ltd Kurduwadi", label: "Janata Sahakari Bank Ltd Kurduwadi" },
  { value: "Ikf Finance Ltd", label: "Ikf Finance Ltd" },
  { value: "The Vita Urban Co Op Bank Ltd", label: "The Vita Urban Co Op Bank Ltd" },
  { value: "Vidyasagar Co Operative Bank Ltd", label: "Vidyasagar Co Operative Bank Ltd" },
  { value: "Numaligarh Refinary Ltd", label: "Numaligarh Refinary Ltd" },
  { value: "Vananchal Gramin Bank", label: "Vananchal Gramin Bank" },
  { value: "Associated Automobiles", label: "Associated Automobiles" },
  { value: "Shivdaulat Sahakari Bank Ltd", label: "Shivdaulat Sahakari Bank Ltd" },
  { value: "The Kadavallur Service Co Op Bank Ltd", label: "The Kadavallur Service Co Op Bank Ltd" },
  { value: "Royal Financiers", label: "Royal Financiers" },
  { value: "The Padiyotchal Service Co Operative Bank Ltd", label: "The Padiyotchal Service Co Operative Bank Ltd" },
  { value: "The Bardoli Khanthi Seva Shakari Mandali Ltd", label: "The Bardoli Khanthi Seva Shakari Mandali Ltd" },
  { value: "The Alanallur Co Op Urban Credit Society Ltd", label: "The Alanallur Co Op Urban Credit Society Ltd" },
  { value: "Shivam Sahakari Bank Ltd", label: "Shivam Sahakari Bank Ltd" },
  { value: "P.G.V.C.L", label: "P.G.V.C.L" },
  { value: "Shri Veerpulikeshi Co Operative Bank Ltd", label: "Shri Veerpulikeshi Co Operative Bank Ltd" },
  { value: "Thodupuzha Town Service Co Operative Bank Ltd", label: "Thodupuzha Town Service Co Operative Bank Ltd" },
  { value: "The Cherpalcheri Co Operative Urban Bank Ltd", label: "The Cherpalcheri Co Operative Urban Bank Ltd" },
  { value: "The Vaniamkulam Service Co Op Bank Ltd", label: "The Vaniamkulam Service Co Op Bank Ltd" },
  { value: "Shri Bilur Gurubasava Pattin Sahari Sangha Niyamit", label: "Shri Bilur Gurubasava Pattin Sahari Sangha Niyamit" },
  { value: "Kusalava Finance Ltd", label: "Kusalava Finance Ltd" },
  { value: "Bmw India Finencial Services", label: "Bmw India Finencial Services" },
  { value: "Vijaylaxmi Finance", label: "Vijaylaxmi Finance" },
  { value: "Nagnechiya Ray Finance", label: "Nagnechiya Ray Finance" },
  { value: "Hinglaj Finance", label: "Hinglaj Finance" },
  { value: "Navjeevan Credit Co Operative Society Ltd", label: "Navjeevan Credit Co Operative Society Ltd" },
  { value: "Sanjeevani Credit Co Operative Society Ltd", label: "Sanjeevani Credit Co Operative Society Ltd" },
  { value: "Khinw Karan Baid Huf", label: "Khinw Karan Baid Huf" },
  { value: "The Eenadu Co Operative Urban Bank Ltd", label: "The Eenadu Co Operative Urban Bank Ltd" },
  { value: "The Kodimatha Co Operative Bank Ltd No.376", label: "The Kodimatha Co Operative Bank Ltd No.376" },
  { value: "Bharat Petroleum Corp.Ltd", label: "Bharat Petroleum Corp.Ltd" },
  { value: "Sri Bharani And Co Bankers", label: "Sri Bharani And Co Bankers" },
  { value: "Shree Baba Finance Co", label: "Shree Baba Finance Co" },
  { value: "The Mekkadampu Service Co Operative Bank Ltd, No 4073", label: "The Mekkadampu Service Co Operative Bank Ltd, No 4073" },
  { value: "The Manathana Service Co Operative Bank Ltd", label: "The Manathana Service Co Operative Bank Ltd" },
  { value: "Au Financiers India Pvt Ltd", label: "Au Financiers India Pvt Ltd" },
  { value: "Rajarajan Finance", label: "Rajarajan Finance" },
  { value: "Viramgam Mercantile Co Operative Bank", label: "Viramgam Mercantile Co Operative Bank" },
  { value: "L I C Of India", label: "L I C Of India" },
  { value: "The Cochin Co Operative Agri And Rural Developement Bank Ltd", label: "The Cochin Co Operative Agri And Rural Developement Bank Ltd" },
  { value: "Mansing Co Operative Bank Ltd", label: "Mansing Co Operative Bank Ltd" },
  { value: "State Bank Of Sikkim", label: "State Bank Of Sikkim" },
  { value: "The Thrickodithanam Service Co Operative Bank Ltd", label: "The Thrickodithanam Service Co Operative Bank Ltd" },
  { value: "The Ghatal Peoples Co Operative Bank Ltd", label: "The Ghatal Peoples Co Operative Bank Ltd" },
  { value: "Kogta Financial I Ltd", label: "Kogta Financial I Ltd" },
  { value: "Vallikunnu Service Co Operative Bank Ltd", label: "Vallikunnu Service Co Operative Bank Ltd" },
  { value: "Bhilwara Ajmer Kshetriya Gramin Bank", label: "Bhilwara Ajmer Kshetriya Gramin Bank" },
  { value: "The Ponnampara Service Co Operative Bank Ltd", label: "The Ponnampara Service Co Operative Bank Ltd" },
  { value: "Varanga Co Operative Agricultural Bank Ltd", label: "Varanga Co Operative Agricultural Bank Ltd" },
  { value: "Kuravilangad Service Co Operative Bank Ltd", label: "Kuravilangad Service Co Operative Bank Ltd" },
  { value: "State Bank Of Hyderabad", label: "State Bank Of Hyderabad" },
  { value: "Lawrance Krishna Co Op Credit Soc Ltd", label: "Lawrance Krishna Co Op Credit Soc Ltd" },
  { value: "Vintage Credit And Leasing", label: "Vintage Credit And Leasing" },
  { value: "Shree Rajendra Suri Sakh Sahakarita Mydt", label: "Shree Rajendra Suri Sakh Sahakarita Mydt" },
  { value: "Chitrakoot Motor Finance P Ltd", label: "Chitrakoot Motor Finance P Ltd" },
  { value: "Sri Varri Finance", label: "Sri Varri Finance" },
  { value: "Sri Gajendra Finance", label: "Sri Gajendra Finance" },
  { value: "Shri Kaliyamman Auto Leasing", label: "Shri Kaliyamman Auto Leasing" },
  { value: "Arul Patchiyamman Finance", label: "Arul Patchiyamman Finance" },
  { value: "Sri Pathee Auto Finance", label: "Sri Pathee Auto Finance" },
  { value: "Kanjikovil Sri Devi Finance", label: "Kanjikovil Sri Devi Finance" },
  { value: "Sri Azhagu Sakthi Auto Finance", label: "Sri Azhagu Sakthi Auto Finance" },
  { value: "Kandan Finance", label: "Kandan Finance" },
  { value: "Swastik Auto Agencies", label: "Swastik Auto Agencies" },
  { value: "Shri Krishna Auto Agencies", label: "Shri Krishna Auto Agencies" },
  { value: "Ms Rajasthan Motors", label: "Ms Rajasthan Motors" },
  { value: "The Kulasekharamangalam Service Co Operative Bank Ltd", label: "The Kulasekharamangalam Service Co Operative Bank Ltd" },
  { value: "Aruna Sahakara Bank Niyamitha", label: "Aruna Sahakara Bank Niyamitha" },
  { value: "The Hongkong And Shangahi Banking Corporation Limited", label: "The Hongkong And Shangahi Banking Corporation Limited" },
  { value: "Thrissur Service Co Operative Bank", label: "Thrissur Service Co Operative Bank" },
  { value: "Vikas Co Operative Bank Ltd, Latur", label: "Vikas Co Operative Bank Ltd, Latur" },
  { value: "Rajadhani Co Op Urban Bank Ltd", label: "Rajadhani Co Op Urban Bank Ltd" },
  { value: "Naveen Finance And Investments", label: "Naveen Finance And Investments" },
  { value: "The Elankur Service Co Operative Bank Ltd", label: "The Elankur Service Co Operative Bank Ltd" },
  { value: "Golden Enterprises", label: "Golden Enterprises" },
  { value: "The Innespeta Co Operative Urban Bank Limited", label: "The Innespeta Co Operative Urban Bank Limited" },
  { value: "A R Nagar Ser Co Op Bank", label: "A R Nagar Ser Co Op Bank" },
  { value: "The Inchakundu Service Co Op Bank Ltd", label: "The Inchakundu Service Co Op Bank Ltd" },
  { value: "The Kollam City Service Co Operative Bank Ltd", label: "The Kollam City Service Co Operative Bank Ltd" },
  { value: "Common Wealth Bank", label: "Common Wealth Bank" },
  { value: "M S Eximp P Ltd", label: "M S Eximp P Ltd" },
  { value: "H K Auto", label: "H K Auto" },
  { value: "Tjsb Bank", label: "Tjsb Bank" },
  { value: "Shukrit Enterprises", label: "Shukrit Enterprises" },
  { value: "The Chinnakanal Service Co Operative Bank Ltd", label: "The Chinnakanal Service Co Operative Bank Ltd" },
  { value: "The Malappuram Service Co Op Bank Ltd", label: "The Malappuram Service Co Op Bank Ltd" },
  { value: "Krunal Capital", label: "Krunal Capital" },
  { value: "Krunal Finance", label: "Krunal Finance" },
  { value: "Akash Leasing And Finance", label: "Akash Leasing And Finance" },
  { value: "Arman Finance Ltd", label: "Arman Finance Ltd" },
  { value: "Bhaichand Hirachand Raisoni Cr Co Op Society", label: "Bhaichand Hirachand Raisoni Cr Co Op Society" },
  { value: "Shri Shanti Guru Finance", label: "Shri Shanti Guru Finance" },
  { value: "Shree Vinayak Mitra Mandal", label: "Shree Vinayak Mitra Mandal" },
  { value: "Mas Financial Ser Ltd", label: "Mas Financial Ser Ltd" },
  { value: "Shubh Labh Mitra Mandal", label: "Shubh Labh Mitra Mandal" },
  { value: "Ghaghret Gam Samasth Mitra Mandal", label: "Ghaghret Gam Samasth Mitra Mandal" },
  { value: "Arman Financial Ser Ltd", label: "Arman Financial Ser Ltd" },
  { value: "Shreeram City Union Finacne Ltd", label: "Shreeram City Union Finacne Ltd" },
  { value: "Ceejay Finance", label: "Ceejay Finance" },
  { value: "Umiya Mitra Mandal", label: "Umiya Mitra Mandal" },
  { value: "Vinayal Mitra Mandal", label: "Vinayal Mitra Mandal" },
  { value: "Galaxy Lease Finance", label: "Galaxy Lease Finance" },
  { value: "Kodassery Elinjipra Service Co Operative Bank Ltd", label: "Kodassery Elinjipra Service Co Operative Bank Ltd" },
  { value: "Ghaghret Gam Samast Yuvak Mitra Mandal", label: "Ghaghret Gam Samast Yuvak Mitra Mandal" },
  { value: "Rajshree Auto Finance Pvt Ltd", label: "Rajshree Auto Finance Pvt Ltd" },
  { value: "L N S Tejasri Automobile Financiers", label: "L N S Tejasri Automobile Financiers" },
  { value: "Suresh Finance Corporation", label: "Suresh Finance Corporation" },
  { value: "Sai Santosh Finance", label: "Sai Santosh Finance" },
  { value: "Bharat And Company", label: "Bharat And Company" },
  { value: "The Prakasam District Co Operative Central Bank Ltd", label: "The Prakasam District Co Operative Central Bank Ltd" },
  { value: "Haldia Metcoke Pvt Ltd", label: "Haldia Metcoke Pvt Ltd" },
  { value: "Ge Countrywide Consumer Finance", label: "Ge Countrywide Consumer Finance" },
  { value: "Maruti Finance Company", label: "Maruti Finance Company" },
  { value: "Unique And Company", label: "Unique And Company" },
  { value: "M M Investment", label: "M M Investment" },
  { value: "Suvarna Enterprises", label: "Suvarna Enterprises" },
  { value: "Arman Finance Services Ltd", label: "Arman Finance Services Ltd" },
  { value: "Future Capital Holdings Limited", label: "Future Capital Holdings Limited" },
  { value: "New Umiya Finance", label: "New Umiya Finance" },
  { value: "Akash Lease Finance", label: "Akash Lease Finance" },
  { value: "Shriram City Union Finance Ltd", label: "Shriram City Union Finance Ltd" },
  { value: "Shree Mahakali Mitra Mandal", label: "Shree Mahakali Mitra Mandal" },
  { value: "Gurudev Mitra Mandal", label: "Gurudev Mitra Mandal" },
  { value: "Mas Financial Services Ltd", label: "Mas Financial Services Ltd" },
  { value: "The Co Op Bank Mehsana", label: "The Co Op Bank Mehsana" },
  { value: "M S Hindalco Industries Ltd", label: "M S Hindalco Industries Ltd" },
  { value: "Jankalyan Sahakari Pathpedi", label: "Jankalyan Sahakari Pathpedi" },
  { value: "Shantai Gramin Bigarsheti Patsanstha Maryadit", label: "Shantai Gramin Bigarsheti Patsanstha Maryadit" },
  { value: "Shree Guru Umedpuri Mitra Mandal Finance", label: "Shree Guru Umedpuri Mitra Mandal Finance" },
  { value: "The Ashta Peoples Co Op Bank Ltd Ashta", label: "The Ashta Peoples Co Op Bank Ltd Ashta" },
  { value: "C L Credit Corporation", label: "C L Credit Corporation" },
  { value: "Sribir Finance Ltd", label: "Sribir Finance Ltd" },
  { value: "Jitendra Trading Co", label: "Jitendra Trading Co" },
  { value: "The Chembur Nagarik Sahakari Bank Ltd", label: "The Chembur Nagarik Sahakari Bank Ltd" },
  { value: "Suvarna Interprises", label: "Suvarna Interprises" },
  { value: "Manapuram Asset Finance Ltd", label: "Manapuram Asset Finance Ltd" },
  { value: "Arham Investment", label: "Arham Investment" },
  { value: "Rishab Investments", label: "Rishab Investments" },
  { value: "Diehsh Kumar Kohtari", label: "Diehsh Kumar Kohtari" },
  { value: "Raychand Chopra And Sons", label: "Raychand Chopra And Sons" },
  { value: "Kamalchand Kothari", label: "Kamalchand Kothari" },
  { value: "Prakash Dugar", label: "Prakash Dugar" },
  { value: "Ankit Dugar", label: "Ankit Dugar" },
  { value: "Manohar Finance", label: "Manohar Finance" },
  { value: "Reliable Investment", label: "Reliable Investment" },
  { value: "Reliable Finance", label: "Reliable Finance" },
  { value: "O G Insvestment", label: "O G Insvestment" },
  { value: "Swan Investments", label: "Swan Investments" },
  { value: "Prakash Finance", label: "Prakash Finance" },
  { value: "Santhosh Finance", label: "Santhosh Finance" },
  { value: "Neha Dugar", label: "Neha Dugar" },
  { value: "Dilip Bohra Jain", label: "Dilip Bohra Jain" },
  { value: "Rajesh Bohra Jain", label: "Rajesh Bohra Jain" },
  { value: "Kataria Finance", label: "Kataria Finance" },
  { value: "Kangra Central Co Opertaive Bank Ltd.", label: "Kangra Central Co Opertaive Bank Ltd." },
  { value: "Ab Bank Limited", label: "Ab Bank Limited" },
  { value: "The Puzhathi Service Co Operative Bank Limited", label: "The Puzhathi Service Co Operative Bank Limited" },
  { value: "Sri Venkateswara Finance", label: "Sri Venkateswara Finance" },
  { value: "Srinivas Finance", label: "Srinivas Finance" },
  { value: "Madhukan Finance Services Ltd", label: "Madhukan Finance Services Ltd" },
  { value: "The Chuzhali Service Co Operative Bank Ltd", label: "The Chuzhali Service Co Operative Bank Ltd" },
  { value: "Shree Gangani Finance", label: "Shree Gangani Finance" },
  { value: "The Peralasseri Service Co Operative Bank Ltd", label: "The Peralasseri Service Co Operative Bank Ltd" },
  { value: "Arman Financial Services Ltd", label: "Arman Financial Services Ltd" },
  { value: "Religare Finvest Ltd", label: "Religare Finvest Ltd" },
  { value: "Jay Jalaram Co Op Credit Society", label: "Jay Jalaram Co Op Credit Society" },
  { value: "The Hansot Nagaric Sahakari Bank Ltd", label: "The Hansot Nagaric Sahakari Bank Ltd" },
  { value: "Sreerag General Finance", label: "Sreerag General Finance" },
  { value: "Sri Vijay Shanti Finance", label: "Sri Vijay Shanti Finance" },
  { value: "Shri Samridhi Finance", label: "Shri Samridhi Finance" },
  { value: "Sri Tulasi Finance", label: "Sri Tulasi Finance" },
  { value: "Raj Rajeshwari Finance", label: "Raj Rajeshwari Finance" },
  { value: "Garg Finance", label: "Garg Finance" },
  { value: "Tatapur Co Operative Urban Bank Ltd", label: "Tatapur Co Operative Urban Bank Ltd" },
  { value: "Kachur Credit Co Op Society Ltd", label: "Kachur Credit Co Op Society Ltd" },
  { value: "Galaxy Group Hp Finance", label: "Galaxy Group Hp Finance" },
  { value: "Sri Raja Rajeshwara Hire Pruchase", label: "Sri Raja Rajeshwara Hire Pruchase" },
  { value: "Hemant Kumar M Jain", label: "Hemant Kumar M Jain" },
  { value: "Neerja Fincap Pvt Ltd", label: "Neerja Fincap Pvt Ltd" },
  { value: "Dash Leasing And Finance", label: "Dash Leasing And Finance" },
  { value: "Equitas Fianace Pvt Ltd", label: "Equitas Fianace Pvt Ltd" },
  { value: "Anand Growth Fund Pvt Ltd", label: "Anand Growth Fund Pvt Ltd" },
  { value: "U Tharun Jain Huf Financier", label: "U Tharun Jain Huf Financier" },
  { value: "Ahmednagar Zilla Prathmik Shikshak Sahakari Bank Ltd", label: "Ahmednagar Zilla Prathmik Shikshak Sahakari Bank Ltd" },
  { value: "Swabhimani Souharda Credit Co Op Ltd", label: "Swabhimani Souharda Credit Co Op Ltd" },
  { value: "Bhairu And Co", label: "Bhairu And Co" },
  { value: "The Eriyad Service Co Op Bank Ltd", label: "The Eriyad Service Co Op Bank Ltd" },
  { value: "Shriram Equipment Finance Co Ltd", label: "Shriram Equipment Finance Co Ltd" },
  { value: "The Kathvada Seva Sahkari Mandali Ltd", label: "The Kathvada Seva Sahkari Mandali Ltd" },
  { value: "Sri Vishwamangala Souharda Pattina Sahakari Niyamitha", label: "Sri Vishwamangala Souharda Pattina Sahakari Niyamitha" },
  { value: "The Kangra Co Op Primary Agri And Rural Dev Bank Ltd", label: "The Kangra Co Op Primary Agri And Rural Dev Bank Ltd" },
  { value: "Guru Nanak Motors", label: "Guru Nanak Motors" },
  { value: "Swatantrya Veer Sawarkar Ngr Sah Pat Ltd", label: "Swatantrya Veer Sawarkar Ngr Sah Pat Ltd" },
  { value: "Mamatha Enter Prises", label: "Mamatha Enter Prises" },
  { value: "Bhagya Laxmi Enterprises And Auto Finance", label: "Bhagya Laxmi Enterprises And Auto Finance" },
  { value: "Sri Balaji Enter Prises And Auto Finance", label: "Sri Balaji Enter Prises And Auto Finance" },
  { value: "Ponnalagu Motor Finance", label: "Ponnalagu Motor Finance" },
  { value: "Sri Veerakumar Auto Finance", label: "Sri Veerakumar Auto Finance" },
  { value: "Sri Laxmi Narasimha Motors And Auto Finance", label: "Sri Laxmi Narasimha Motors And Auto Finance" },
  { value: "Sri Laxmi Narasimha Swamy Motors And Auto Finance", label: "Sri Laxmi Narasimha Swamy Motors And Auto Finance" },
  { value: "Sri Manikanta Enter Prises And Auto Finance", label: "Sri Manikanta Enter Prises And Auto Finance" },
  { value: "Sri Raghava Motors", label: "Sri Raghava Motors" },
  { value: "Sri Manju Enterprises", label: "Sri Manju Enterprises" },
  { value: "Sri Raghavendra Motors", label: "Sri Raghavendra Motors" },
  { value: "Sri Rama Motors And Auto Finance", label: "Sri Rama Motors And Auto Finance" },
  { value: "Sri Venkateshwara Auto Finance", label: "Sri Venkateshwara Auto Finance" },
  { value: "Srinivasa Motors", label: "Srinivasa Motors" },
  { value: "Dhanlaxmi Mahila Nagari Patsanshta Marya", label: "Dhanlaxmi Mahila Nagari Patsanshta Marya" },
  { value: "Bholeshankar Nagari Sahkari Patsanstha Marya", label: "Bholeshankar Nagari Sahkari Patsanstha Marya" },
  { value: "Safe Auto Credits And Investments", label: "Safe Auto Credits And Investments" },
  { value: "Mary Auto Finance", label: "Mary Auto Finance" },
  { value: "The Ode Nagrik Credit Co Op Soc Ltd", label: "The Ode Nagrik Credit Co Op Soc Ltd" },
  { value: "Aar Kay Fin Lease Pvt Ltd", label: "Aar Kay Fin Lease Pvt Ltd" },
  { value: "Gambit Leasing And Finance Pvt Ltd", label: "Gambit Leasing And Finance Pvt Ltd" },
  { value: "The Urban Co Op Bank Ltd, Perinthalmanna", label: "The Urban Co Op Bank Ltd, Perinthalmanna" },
  { value: "Sharada Gramin Bigar Sheti Sahakari Patsanstha Maryadit", label: "Sharada Gramin Bigar Sheti Sahakari Patsanstha Maryadit" },
  { value: "Yashwant Nagari Sahakari Patsanstha Pimpwandi", label: "Yashwant Nagari Sahakari Patsanstha Pimpwandi" },
  { value: "Matrubhumi Nagari Sah Bank Ltd", label: "Matrubhumi Nagari Sah Bank Ltd" },
  { value: "Etawah Baliya Kshetriy Gramin Bank", label: "Etawah Baliya Kshetriy Gramin Bank" },
  { value: "Mahindra And Mahindra Finance Service Ltd", label: "Mahindra And Mahindra Finance Service Ltd" },
  { value: "Tata Financial Services Ltd", label: "Tata Financial Services Ltd" },
  { value: "Subhash Chand Nahar And Sons", label: "Subhash Chand Nahar And Sons" },
  { value: "Hanuman Nagar Co Op Bank", label: "Hanuman Nagar Co Op Bank" },
  { value: "Dadasaheb Deshmukh Sah. Bank Ltd", label: "Dadasaheb Deshmukh Sah. Bank Ltd" },
  { value: "Army Group Insurance Fund", label: "Army Group Insurance Fund" },
  { value: "Citicorp Finance India Ltd", label: "Citicorp Finance India Ltd" },
  { value: "Srei Equipment Finance Ltd", label: "Srei Equipment Finance Ltd" },
  { value: "Toyota Finance Service Ltd", label: "Toyota Finance Service Ltd" },
  { value: "The Undera Co Op Credit Soc Mandeli Ltd", label: "The Undera Co Op Credit Soc Mandeli Ltd" },
  { value: "Kerhar Finance And Leasing Pvt Ltd", label: "Kerhar Finance And Leasing Pvt Ltd" },
  { value: "Volkswagen Finance Pvt Ltd", label: "Volkswagen Finance Pvt Ltd" },
  { value: "Mahaveer Raj Huf", label: "Mahaveer Raj Huf" },
  { value: "Harika Sai Financiers", label: "Harika Sai Financiers" },
  { value: "Arval Bnp Paribas Group", label: "Arval Bnp Paribas Group" },
  { value: "S Chatterjee And Co", label: "S Chatterjee And Co" },
  { value: "Guru Finance", label: "Guru Finance" },
  { value: "Sri Vel Motor Finance", label: "Sri Vel Motor Finance" },
  { value: "Indiabulls Financial Services Ltd", label: "Indiabulls Financial Services Ltd" },
  { value: "Indus Ind Bank", label: "Indus Ind Bank" },
  { value: "Shree Mahavir Nag Sah Patpedhi Mydt", label: "Shree Mahavir Nag Sah Patpedhi Mydt" },
  { value: "Jambut Gra Big Sah Pat Mar", label: "Jambut Gra Big Sah Pat Mar" },
  { value: "Sangmitra Sahakari Mahila Bank", label: "Sangmitra Sahakari Mahila Bank" },
  { value: "Shri Virdhawal Jagdale Sah Motor Vahatuk San Mar", label: "Shri Virdhawal Jagdale Sah Motor Vahatuk San Mar" },
  { value: "The Ezhumattoor Service Co Op Bank Ltd", label: "The Ezhumattoor Service Co Op Bank Ltd" },
  { value: "Sree Kamakshi Auto Finance", label: "Sree Kamakshi Auto Finance" },
  { value: "Manvi Pattana Souharda Sahakari Bank Niyamit", label: "Manvi Pattana Souharda Sahakari Bank Niyamit" },
  { value: "Babu Auto Finance", label: "Babu Auto Finance" },
  { value: "Shree Marudhar Investment", label: "Shree Marudhar Investment" },
  { value: "Shri Sadguru Gopaji Baba Parisar Gra Big Sheti Sah Pat Maryadit", label: "Shri Sadguru Gopaji Baba Parisar Gra Big Sheti Sah Pat Maryadit" },
  { value: "Prakash Auto Finance", label: "Prakash Auto Finance" },
  { value: "Srg Securities Finance Ltd", label: "Srg Securities Finance Ltd" },
  { value: "Subho Enterprise", label: "Subho Enterprise" },
  { value: "The Nagrota Co Op Urban Th And Cr Society Ltd", label: "The Nagrota Co Op Urban Th And Cr Society Ltd" },
  { value: "Jagaravi Finance", label: "Jagaravi Finance" },
  { value: "Decan Finance Ltd", label: "Decan Finance Ltd" },
  { value: "Mukatai Devi Gramin Biger Sheti Sah Patsantha", label: "Mukatai Devi Gramin Biger Sheti Sah Patsantha" },
  { value: "Himgiri Auto Finance Pvt Ltd", label: "Himgiri Auto Finance Pvt Ltd" },
  { value: "Shivam Associates", label: "Shivam Associates" },
  { value: "The Shibpur Co Op Bank Ltd", label: "The Shibpur Co Op Bank Ltd" },
  { value: "Citizen Co Operative Bank Ltd, Noida", label: "Citizen Co Operative Bank Ltd, Noida" },
  { value: "Gajanand Fin Invst Pvt Ltd", label: "Gajanand Fin Invst Pvt Ltd" },
  { value: "Tata Capital Financial Services Ltd", label: "Tata Capital Financial Services Ltd" },
  { value: "Tirupati Credit Corporation", label: "Tirupati Credit Corporation" },
  { value: "Impact Leasing P Ltd", label: "Impact Leasing P Ltd" },
  { value: "H Kishore Kumar Dakiliya", label: "H Kishore Kumar Dakiliya" },
  { value: "Roop Auto Finance", label: "Roop Auto Finance" },
  { value: "Shree Mahalaksmi Mahila Nagari Sahakari Patsanstha Maryadit", label: "Shree Mahalaksmi Mahila Nagari Sahakari Patsanstha Maryadit" },
  { value: "Kanhur Pathar Multistate Co Op Credit Socity Ltd", label: "Kanhur Pathar Multistate Co Op Credit Socity Ltd" },
  { value: "Sumit And Company", label: "Sumit And Company" },
  { value: "Pincha Syndicates", label: "Pincha Syndicates" },
  { value: "G G Investments", label: "G G Investments" },
  { value: "V M R Associat", label: "V M R Associat" },
  { value: "V M R Associat", label: "V M R Associat" },
  { value: "Dev Motor Finance", label: "Dev Motor Finance" },
  { value: "Shilpha Investments", label: "Shilpha Investments" },
  { value: "S Ramesh Chand Jain Huf", label: "S Ramesh Chand Jain Huf" },
  { value: "Sri Lakshmi Investments", label: "Sri Lakshmi Investments" },
  { value: "Oswal Investment", label: "Oswal Investment" },
  { value: "Shree Rishabh Motors", label: "Shree Rishabh Motors" },
  { value: "Good Luck Finance", label: "Good Luck Finance" },
  { value: "Akme Fintrade I Ltd", label: "Akme Fintrade I Ltd" },
  { value: "Samta Nagari Sahakari Pathsantha Maryadit", label: "Samta Nagari Sahakari Pathsantha Maryadit" },
  { value: "Vedika Credit Capital Ltd", label: "Vedika Credit Capital Ltd" },
  { value: "Maulana Azad Cr Co Op Society", label: "Maulana Azad Cr Co Op Society" },
  { value: "Govt. Of Up And Uttarakhand, Through Se, Lvcc I, Irrigation", label: "Govt. Of Up And Uttarakhand, Through Se, Lvcc I, Irrigation" },
  { value: "M P Finance", label: "M P Finance" },
  { value: "The Vanzar Group Seva Sahakari Mandali Ltd", label: "The Vanzar Group Seva Sahakari Mandali Ltd" },
  { value: "Gopal And Co", label: "Gopal And Co" },
  { value: "Shri Ganesh Motors", label: "Shri Ganesh Motors" },
  { value: "Ambica Co Op Credit Society Ltd", label: "Ambica Co Op Credit Society Ltd" },
  { value: "Anjali Co Op Credit Soc Ltd", label: "Anjali Co Op Credit Soc Ltd" },
  { value: "Venkitangu Farmers Co Opertaive Bank Ltd", label: "Venkitangu Farmers Co Opertaive Bank Ltd" },
  { value: "Shri Mahindra Auto Finance", label: "Shri Mahindra Auto Finance" },
  { value: "Vardhaman Nagari Sahkari Patsanshta", label: "Vardhaman Nagari Sahkari Patsanshta" },
  { value: "Nirvan Nivesh Financiers", label: "Nirvan Nivesh Financiers" },
  { value: "Ansh Inversements", label: "Ansh Inversements" },
  { value: "Jain Finance Corporation", label: "Jain Finance Corporation" },
  { value: "Sawan Invesements", label: "Sawan Invesements" },
  { value: "Murude Sahakari Patsanstha Maryadit", label: "Murude Sahakari Patsanstha Maryadit" },
  { value: "Greater Bank", label: "Greater Bank" },
  { value: "The Midnapore Peoples Co Operative Bank Ltd", label: "The Midnapore Peoples Co Operative Bank Ltd" },
  { value: "Indusind Bank Ltd", label: "Indusind Bank Ltd" },
  { value: "Anurag Auto Finance", label: "Anurag Auto Finance" },
  { value: "The Chala Service Co Operative Bank Limited", label: "The Chala Service Co Operative Bank Limited" },
  { value: "The Kadamboor Service Co Operative Bank Ltd", label: "The Kadamboor Service Co Operative Bank Ltd" },
  { value: "Patteppadam Rural Co Operative Society Ltd", label: "Patteppadam Rural Co Operative Society Ltd" },
  { value: "Laxmi Sainath Hp And Finance", label: "Laxmi Sainath Hp And Finance" },
  { value: "Karini Hire Purchase And Financiers", label: "Karini Hire Purchase And Financiers" },
  { value: "Sri Raja Rajeshwara Hp", label: "Sri Raja Rajeshwara Hp" },
  { value: "Galaxy Group Hp And Finance", label: "Galaxy Group Hp And Finance" },
  { value: "The Plantation Corporation Of Kerala", label: "The Plantation Corporation Of Kerala" },
  { value: "The Ganapathi Financier", label: "The Ganapathi Financier" },
  { value: "Shri Baria Nagrik Sahakari Bank Ltd", label: "Shri Baria Nagrik Sahakari Bank Ltd" },
  { value: "Jay Yogeshwar Seva Sahakari Mandali Ltd", label: "Jay Yogeshwar Seva Sahakari Mandali Ltd" },
  { value: "Neminath Finance", label: "Neminath Finance" },
  { value: "Pushpak Financiers", label: "Pushpak Financiers" },
  { value: "Kuber Finance", label: "Kuber Finance" },
  { value: "Shree Gee Investment", label: "Shree Gee Investment" },
  { value: "Hiran Investment Co", label: "Hiran Investment Co" },
  { value: "Santosh Auto Finance", label: "Santosh Auto Finance" },
  { value: "Balaji Auto Finance", label: "Balaji Auto Finance" },
  { value: "Shree Sangani Finance", label: "Shree Sangani Finance" },
  { value: "United Puri Nimapara Central Co Operative Bank Ltd", label: "United Puri Nimapara Central Co Operative Bank Ltd" },
  { value: "N S Jain Finsta Money Lenders", label: "N S Jain Finsta Money Lenders" },
  { value: "Margao Urban Co Op Bank Ltd", label: "Margao Urban Co Op Bank Ltd" },
  { value: "Babanrao Awtade Nagari Biger Sheti Sah Pat San Mar", label: "Babanrao Awtade Nagari Biger Sheti Sah Pat San Mar" },
  { value: "Purandar Nagari Sahakari Pat Sanstha Maryadit", label: "Purandar Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Shri Kalbhairvnath Gramin Biger Sheti Sah Path Sans Marydit", label: "Shri Kalbhairvnath Gramin Biger Sheti Sah Path Sans Marydit" },
  { value: "Sant Dnyaneshwar Gramin B.S.Sah.Patsanstha Maryadit", label: "Sant Dnyaneshwar Gramin B.S.Sah.Patsanstha Maryadit" },
  { value: "The Hajipur Gramin Seva Sahakari Mandli Ltd", label: "The Hajipur Gramin Seva Sahakari Mandli Ltd" },
  { value: "The Pappinisseri Service Co Operative Bank Ltd", label: "The Pappinisseri Service Co Operative Bank Ltd" },
  { value: "Manu Financial Associates Regd", label: "Manu Financial Associates Regd" },
  { value: "Rahul Finance Investment", label: "Rahul Finance Investment" },
  { value: "Narsinha Grameen Bigarsheti Patsanstha", label: "Narsinha Grameen Bigarsheti Patsanstha" },
  { value: "Vishal Purandar Nagari Sahakari Patsanstha Ltd", label: "Vishal Purandar Nagari Sahakari Patsanstha Ltd" },
  { value: "Agresen Maharaj Nagari Sahakari Pat San", label: "Agresen Maharaj Nagari Sahakari Pat San" },
  { value: "Shiggaon Urban Co Op Bank Ltd", label: "Shiggaon Urban Co Op Bank Ltd" },
  { value: "Mangrul Panchkroshi Sahkari Cr.Society Ltd.", label: "Mangrul Panchkroshi Sahkari Cr.Society Ltd." },
  { value: "Mahatma Phule Nagari Sahkari Patsanstha Marya", label: "Mahatma Phule Nagari Sahkari Patsanstha Marya" },
  { value: "The Revdanda Co Op Urban Bank Ltd.", label: "The Revdanda Co Op Urban Bank Ltd." },
  { value: "Hdb Financial Services Ltd", label: "Hdb Financial Services Ltd" },
  { value: "Fursungi Gramin Biger Sheti Sahakari Pat San Marya", label: "Fursungi Gramin Biger Sheti Sahakari Pat San Marya" },
  { value: "Nageshwar Nagari Sahakari Pat Sanstha Maryadit", label: "Nageshwar Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Jyoti Associate", label: "Jyoti Associate" },
  { value: "The Pookkottur Service Co Operative Bank Ltd", label: "The Pookkottur Service Co Operative Bank Ltd" },
  { value: "Shree Ganesh Biger Sheti Sah Pat San Maryad", label: "Shree Ganesh Biger Sheti Sah Pat San Maryad" },
  { value: "Jay Sitaram Nagari Sahakari Pat Sanstha Maryadit", label: "Jay Sitaram Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Mansi Auto Care", label: "Mansi Auto Care" },
  { value: "The Godhra Transport Co Op Ltd", label: "The Godhra Transport Co Op Ltd" },
  { value: "The Amanath Co Operative Credit Society Niyamit", label: "The Amanath Co Operative Credit Society Niyamit" },
  { value: "Hind Vijay Nagari Sahakari Pat Sanstha Maryadit", label: "Hind Vijay Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Shree Ganesh Gramin Biger Sheti Sah Pat San Maryad", label: "Shree Ganesh Gramin Biger Sheti Sah Pat San Maryad" },
  { value: "Wagjaimata Mahila Gramin Biger Sheti Sah Pat San", label: "Wagjaimata Mahila Gramin Biger Sheti Sah Pat San" },
  { value: "Shri T S Gramin Biger Sheti Sah Pat San Maryadit", label: "Shri T S Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Nirmala M A D G Co Op Cr Soc Ltd", label: "Nirmala M A D G Co Op Cr Soc Ltd" },
  { value: "Ceerarani Channamma Mahila Souhardha Pattina Sahakari Niyamitha", label: "Ceerarani Channamma Mahila Souhardha Pattina Sahakari Niyamitha" },
  { value: "Mahayogi Vemana Souharda Credit Co Op Ltd", label: "Mahayogi Vemana Souharda Credit Co Op Ltd" },
  { value: "Anand Gramin Bigar Sheti Sahakari Patsanstha", label: "Anand Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Bramhananda Gramin Bigar Sheti Sahakari Patsanstha", label: "Bramhananda Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Shree Sant Goroba Kaka Nagri Sahakari Patsanstha", label: "Shree Sant Goroba Kaka Nagri Sahakari Patsanstha" },
  { value: "Gurudatta Nagari Sahakari Patsanstha", label: "Gurudatta Nagari Sahakari Patsanstha" },
  { value: "Shree Saptasurungi Mata Sahakari Patsanstha", label: "Shree Saptasurungi Mata Sahakari Patsanstha" },
  { value: "Sinnar Nagari Sahakari Patsanstha", label: "Sinnar Nagari Sahakari Patsanstha" },
  { value: "Shree Lekha Nagari Sahakari Patsanstha", label: "Shree Lekha Nagari Sahakari Patsanstha" },
  { value: "Sai Nagari Sahakari Patsanstha", label: "Sai Nagari Sahakari Patsanstha" },
  { value: "Sankalp Gramin Bigar Sheti Sahakari Patsanstha", label: "Sankalp Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Sant Santaji Sahakari Patsanstha", label: "Sant Santaji Sahakari Patsanstha" },
  { value: "Siddeshwar Sahakari Patsanstha", label: "Siddeshwar Sahakari Patsanstha" },
  { value: "Krantiveer Vasantrao Naik Patsanstha", label: "Krantiveer Vasantrao Naik Patsanstha" },
  { value: "Dilip Anna Bigar Sheti Sahakari Patsanstha", label: "Dilip Anna Bigar Sheti Sahakari Patsanstha" },
  { value: "Jayshree Gramin Bigar Sheti Sahakari Patsanstha", label: "Jayshree Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Jay Shree Ram Nagari Sahakari Patsanstha", label: "Jay Shree Ram Nagari Sahakari Patsanstha" },
  { value: "Shreemant Thorle Bajirao Peshve Sahakari Patsanstha", label: "Shreemant Thorle Bajirao Peshve Sahakari Patsanstha" },
  { value: "The Godhra City Co Op. Bank Ltd.", label: "The Godhra City Co Op. Bank Ltd." },
  { value: "The Godhra Urban Co Op. Bank Ltd.", label: "The Godhra Urban Co Op. Bank Ltd." },
  { value: "The Kumaranellur Co Op. Service Bank Ltd.", label: "The Kumaranellur Co Op. Service Bank Ltd." },
  { value: "Nabard National Bank For Agriculture And Rural Development", label: "Nabard National Bank For Agriculture And Rural Development" },
  { value: "Jagdamba Mata Sahakari Patsanstha", label: "Jagdamba Mata Sahakari Patsanstha" },
  { value: "Shree Deonadhi Khore Gramin Bigar Sheti Sahakari Patsanstha", label: "Shree Deonadhi Khore Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Kasturi Nagari Sahakari Patsanstha", label: "Kasturi Nagari Sahakari Patsanstha" },
  { value: "Goverdhan Gramin Bigar Sheti Sahakari Patsanstha", label: "Goverdhan Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Jija Mata Mahila Nagari Patsanstha", label: "Jija Mata Mahila Nagari Patsanstha" },
  { value: "Dhanlaxmi Gramin Bigar Sheti Patsanstha Ltd", label: "Dhanlaxmi Gramin Bigar Sheti Patsanstha Ltd" },
  { value: "Bhairavnath Nagari Sahakari Patsanstha", label: "Bhairavnath Nagari Sahakari Patsanstha" },
  { value: "Loknete Shankarrao Balalji Waje Sahakari Patsanstha", label: "Loknete Shankarrao Balalji Waje Sahakari Patsanstha" },
  { value: "Azad Nagari Sahakari Patsanstha", label: "Azad Nagari Sahakari Patsanstha" },
  { value: "Info India", label: "Info India" },
  { value: "Mbna Europe Bank Ltd", label: "Mbna Europe Bank Ltd" },
  { value: "The Cherpalcheri Service Co Operative Bank Ltd", label: "The Cherpalcheri Service Co Operative Bank Ltd" },
  { value: "The Mankadapalliprom Service Co Operative Bank Limited", label: "The Mankadapalliprom Service Co Operative Bank Limited" },
  { value: "Dhanvatari Gramin Bigarsheti Sahakari Pat Mar", label: "Dhanvatari Gramin Bigarsheti Sahakari Pat Mar" },
  { value: "Vardhman Nagrik Sahakari Sharafi Mandali", label: "Vardhman Nagrik Sahakari Sharafi Mandali" },
  { value: "Mallabhum Gramin Bank", label: "Mallabhum Gramin Bank" },
  { value: "Firstrand Bank Limited", label: "Firstrand Bank Limited" },
  { value: "Sree Gee Investment Ghy", label: "Sree Gee Investment Ghy" },
  { value: "Vetri Vehicle Finance", label: "Vetri Vehicle Finance" },
  { value: "Sai Gokul Leasing", label: "Sai Gokul Leasing" },
  { value: "Anuj Auto Finance", label: "Anuj Auto Finance" },
  { value: "Future Capital Financial Service Ltd", label: "Future Capital Financial Service Ltd" },
  { value: "Shri Omkar Mahila Nagari Sahakari Patsanstha", label: "Shri Omkar Mahila Nagari Sahakari Patsanstha" },
  { value: "Toyota Financial Services India Ltd", label: "Toyota Financial Services India Ltd" },
  { value: "Modern Hire Purchase", label: "Modern Hire Purchase" },
  { value: "Shri Rajendra Suri Sakh Sahkarit", label: "Shri Rajendra Suri Sakh Sahkarit" },
  { value: "Ganesh Nagari Pat Sanstha", label: "Ganesh Nagari Pat Sanstha" },
  { value: "Mahavri Gramin Biger Sheti Sah Pat San Maryadit", label: "Mahavri Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Toyata Financial Services India Ltd", label: "Toyata Financial Services India Ltd" },
  { value: "Sri Madhu Auto Finance", label: "Sri Madhu Auto Finance" },
  { value: "M J Auto Finance", label: "M J Auto Finance" },
  { value: "Av Auto Finance", label: "Av Auto Finance" },
  { value: "The Vendanmettu Service Co Operative Bank Ltd", label: "The Vendanmettu Service Co Operative Bank Ltd" },
  { value: "Mahavir Gramin Biger Sheti Sah Pat San Maryadit", label: "Mahavir Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "The Vandanmettu Service Co Operative Bank Ltd", label: "The Vandanmettu Service Co Operative Bank Ltd" },
  { value: "The Ambika Mahila Sahakari Bank Ltd", label: "The Ambika Mahila Sahakari Bank Ltd" },
  { value: "Maheshwari Nagari Sahakari Patsansta Maryadit", label: "Maheshwari Nagari Sahakari Patsansta Maryadit" },
  { value: "Mahila Urban Co Op Bank", label: "Mahila Urban Co Op Bank" },
  { value: "K.R And Co. Financers And Money Landers", label: "K.R And Co. Financers And Money Landers" },
  { value: "Jai Bhavani Co Op Credit Society Ltd", label: "Jai Bhavani Co Op Credit Society Ltd" },
  { value: "Pravara Mahila Gramin B.S. Sah. Patsanstha Ltd", label: "Pravara Mahila Gramin B.S. Sah. Patsanstha Ltd" },
  { value: "Matru Financial Services Ltd", label: "Matru Financial Services Ltd" },
  { value: "Sri Madhu Auto Financiers", label: "Sri Madhu Auto Financiers" },
  { value: "Kurunji Auto Finance", label: "Kurunji Auto Finance" },
  { value: "Sri Jai Sakthi Auto Finance", label: "Sri Jai Sakthi Auto Finance" },
  { value: "The Ganapathy Financier", label: "The Ganapathy Financier" },
  { value: "Arun Kothari Consulting And Fin Hydrabd", label: "Arun Kothari Consulting And Fin Hydrabd" },
  { value: "Galaxy Finanecers", label: "Galaxy Finanecers" },
  { value: "Maruti Suzuki India Ltd", label: "Maruti Suzuki India Ltd" },
  { value: "National Insurance Employees Co Operative Credit And Banking Society Ltd", label: "National Insurance Employees Co Operative Credit And Banking Society Ltd" },
  { value: "Pragati Finvest", label: "Pragati Finvest" },
  { value: "G C M Enterprises", label: "G C M Enterprises" },
  { value: "Tml Financial Services Ltd", label: "Tml Financial Services Ltd" },
  { value: "The Ahmedabad Urban Peoples Co Op Bank Ltd", label: "The Ahmedabad Urban Peoples Co Op Bank Ltd" },
  { value: "Sri Manikanta Motors And Auto Finance", label: "Sri Manikanta Motors And Auto Finance" },
  { value: "Venkateshwara Enter Prises And Auto Finance", label: "Venkateshwara Enter Prises And Auto Finance" },
  { value: "The Mehsana Mahila Sahakari Bank Ltd", label: "The Mehsana Mahila Sahakari Bank Ltd" },
  { value: "Visharad Automobiles Finance Ltd", label: "Visharad Automobiles Finance Ltd" },
  { value: "Shree Shiv Sahyadri Nagari Sah Pat Mar", label: "Shree Shiv Sahyadri Nagari Sah Pat Mar" },
  { value: "Reliance Consumer Finance", label: "Reliance Consumer Finance" },
  { value: "Vettakkal Service Co Operative Bank", label: "Vettakkal Service Co Operative Bank" },
  { value: "Ram Gramin Biger Sheti Sahakari Pat San Maryadit", label: "Ram Gramin Biger Sheti Sahakari Pat San Maryadit" },
  { value: "The Panchham Seva Sahakari Mandli Ltd", label: "The Panchham Seva Sahakari Mandli Ltd" },
  { value: "The Aakru Seva Sahkari Mandli Ltd", label: "The Aakru Seva Sahkari Mandli Ltd" },
  { value: "The Bajarda Seva Sahakari Mandali Ltd", label: "The Bajarda Seva Sahakari Mandali Ltd" },
  { value: "The Gampix Seva Sahakari Mandli Ltd", label: "The Gampix Seva Sahakari Mandli Ltd" },
  { value: "Nani Devi Bhandari", label: "Nani Devi Bhandari" },
  { value: "The Gamph Seva Sahakari Mandli Ltd", label: "The Gamph Seva Sahakari Mandli Ltd" },
  { value: "Kumari Vrushali Janjire Gramin Biger Sheti Sah Pat", label: "Kumari Vrushali Janjire Gramin Biger Sheti Sah Pat" },
  { value: "Kshatrakulotpnna Maratha Paraspar Co Op Bank Ltd", label: "Kshatrakulotpnna Maratha Paraspar Co Op Bank Ltd" },
  { value: "Avb Employees Co Operative Credit Society", label: "Avb Employees Co Operative Credit Society" },
  { value: "Siri Chandana Auto Lines", label: "Siri Chandana Auto Lines" },
  { value: "Manikanta Motors", label: "Manikanta Motors" },
  { value: "Gayatri Financers", label: "Gayatri Financers" },
  { value: "The Bhandara Nagari Sahakari Pat Sanstha Ltd", label: "The Bhandara Nagari Sahakari Pat Sanstha Ltd" },
  { value: "Shree Pashwanath Gramin Biger Sheti Sah Patsantha Marya Rashin", label: "Shree Pashwanath Gramin Biger Sheti Sah Patsantha Marya Rashin" },
  { value: "Shyam Tractors", label: "Shyam Tractors" },
  { value: "Swargvasi Tukaram Wani Gramin Bigar Sheti Sah Pat Maryadit", label: "Swargvasi Tukaram Wani Gramin Bigar Sheti Sah Pat Maryadit" },
  { value: "The Bhandara Mahila Nagari Sahakari Pat Sanstha Ltd", label: "The Bhandara Mahila Nagari Sahakari Pat Sanstha Ltd" },
  { value: "Janahit Nagari Sah Pat Pethi", label: "Janahit Nagari Sah Pat Pethi" },
  { value: "New Perumbavoor Auto Financiers", label: "New Perumbavoor Auto Financiers" },
  { value: "Kantipudi Engineering Company Pvt Ltd", label: "Kantipudi Engineering Company Pvt Ltd" },
  { value: "Ms Punjab Kashmir Finance Co Ltd", label: "Ms Punjab Kashmir Finance Co Ltd" },
  { value: "Gayatri Dudh Utpadkanchi Gramin Biger Sheti Sah Pat", label: "Gayatri Dudh Utpadkanchi Gramin Biger Sheti Sah Pat" },
  { value: "Coastal Finance", label: "Coastal Finance" },
  { value: "Sri Bharani Auto Finance", label: "Sri Bharani Auto Finance" },
  { value: "Velmurugan Auto Finance", label: "Velmurugan Auto Finance" },
  { value: "Sri Saravana Balaji Auto Finance", label: "Sri Saravana Balaji Auto Finance" },
  { value: "Musalee Finance", label: "Musalee Finance" },
  { value: "State Bank Of Travancore", label: "State Bank Of Travancore" },
  { value: "Sahyog Nagri Sahkari Patsanshta", label: "Sahyog Nagri Sahkari Patsanshta" },
  { value: "Sonheera Co Op Ccredit Soc. Ltd", label: "Sonheera Co Op Ccredit Soc. Ltd" },
  { value: "Anandashakthi Bankers", label: "Anandashakthi Bankers" },
  { value: "Bharat Motors Ltd", label: "Bharat Motors Ltd" },
  { value: "K Nanha Gyan Finance Co", label: "K Nanha Gyan Finance Co" },
  { value: "Isgec Heavy Engineering Ltd.", label: "Isgec Heavy Engineering Ltd." },
  { value: "Taleda Corporation", label: "Taleda Corporation" },
  { value: "Western India Transport Finance Co Pvt Ltd", label: "Western India Transport Finance Co Pvt Ltd" },
  { value: "Raj Investments", label: "Raj Investments" },
  { value: "Shri Laxmi Transport", label: "Shri Laxmi Transport" },
  { value: "Goutham Auto Finance", label: "Goutham Auto Finance" },
  { value: "Veda Co Op Society", label: "Veda Co Op Society" },
  { value: "The Hiriyur Urban Co Op Bank", label: "The Hiriyur Urban Co Op Bank" },
  { value: "Kanaka Credit Co Op Society", label: "Kanaka Credit Co Op Society" },
  { value: "Shri Laxmi Nimnagari Sahakari Pat Puravata San Mar", label: "Shri Laxmi Nimnagari Sahakari Pat Puravata San Mar" },
  { value: "Amco Auto Finance", label: "Amco Auto Finance" },
  { value: "Lessor Religare Finvest Ltd", label: "Lessor Religare Finvest Ltd" },
  { value: "N.J.S. Finance Co. Ltd", label: "N.J.S. Finance Co. Ltd" },
  { value: "G M Auto And Financiers", label: "G M Auto And Financiers" },
  { value: "Shir Ram City Union Finance", label: "Shir Ram City Union Finance" },
  { value: "Laxmi Auto Fianance", label: "Laxmi Auto Fianance" },
  { value: "Sri Sai Auto Fiance", label: "Sri Sai Auto Fiance" },
  { value: "Bhausaheb Birajdar Nagari Sahakari Bank Ltd", label: "Bhausaheb Birajdar Nagari Sahakari Bank Ltd" },
  { value: "Suharsh Co Op Credit Society Ltd", label: "Suharsh Co Op Credit Society Ltd" },
  { value: "Kai Annasaheb Lonari Nagari Sah Patsanstha Ltd", label: "Kai Annasaheb Lonari Nagari Sah Patsanstha Ltd" },
  { value: "Rajiv Gandhi Big Set Sah Pat San Ltd", label: "Rajiv Gandhi Big Set Sah Pat San Ltd" },
  { value: "Sadhavna Sah Bank Ltd", label: "Sadhavna Sah Bank Ltd" },
  { value: "Audi Finance", label: "Audi Finance" },
  { value: "Babalpura Grap Seva Sahakari Mandali", label: "Babalpura Grap Seva Sahakari Mandali" },
  { value: "Anoj Finlease Pvt Ltd", label: "Anoj Finlease Pvt Ltd" },
  { value: "Citi Corp Maruti Fin Ltd", label: "Citi Corp Maruti Fin Ltd" },
  { value: "Rajshri Shahu Gramin Sah.Patsanstha May.Shirpur", label: "Rajshri Shahu Gramin Sah.Patsanstha May.Shirpur" },
  { value: "Pimpalgaon Sarai Gramin Bigar Sheti Sah.Patsanstha May.Pimpalgaon Sarai", label: "Pimpalgaon Sarai Gramin Bigar Sheti Sah.Patsanstha May.Pimpalgaon Sarai" },
  { value: "Shivsai Nagri Sahkari Patsanstha May.Buldana", label: "Shivsai Nagri Sahkari Patsanstha May.Buldana" },
  { value: "Aaditya Urban Co Op Credit Society May.Buldana", label: "Aaditya Urban Co Op Credit Society May.Buldana" },
  { value: "Balaji Urban Co Op.Credit Society Ltd Chikhli", label: "Balaji Urban Co Op.Credit Society Ltd Chikhli" },
  { value: "Bhagodya Gramin Bigar Sheti Sah Patsanstha May.Deulghat", label: "Bhagodya Gramin Bigar Sheti Sah Patsanstha May.Deulghat" },
  { value: "Shri Chandranath Swami Nagri Sah Patsanstha May.Deulgaon Raja", label: "Shri Chandranath Swami Nagri Sah Patsanstha May.Deulgaon Raja" },
  { value: "Satyajit Nagri Sahkari Patsanstha May.Deulgaon Raja", label: "Satyajit Nagri Sahkari Patsanstha May.Deulgaon Raja" },
  { value: "Shri Ambika Urban Co.Op.Cr.Society Ltd Chikhli", label: "Shri Ambika Urban Co.Op.Cr.Society Ltd Chikhli" },
  { value: "Shriram Nagri Sah Patsanstha May.Chikhli", label: "Shriram Nagri Sah Patsanstha May.Chikhli" },
  { value: "Shri Vankatesh Nagari Sah Pat San Maryadit", label: "Shri Vankatesh Nagari Sah Pat San Maryadit" },
  { value: "Tumsar Nagri Sah Pat San Maryadit", label: "Tumsar Nagri Sah Pat San Maryadit" },
  { value: "Balasore Co Operative Urban Bank Ltd", label: "Balasore Co Operative Urban Bank Ltd" },
  { value: "Sakthi Finance Ltd", label: "Sakthi Finance Ltd" },
  { value: "Pragati Gramin Biger Sheti Sah Pat Sanstha Ltd", label: "Pragati Gramin Biger Sheti Sah Pat Sanstha Ltd" },
  { value: "Shri Bhagadeshwar Gramin Biger Sheti Sah Pat Sanstha Ltd", label: "Shri Bhagadeshwar Gramin Biger Sheti Sah Pat Sanstha Ltd" },
  { value: "Srinidhi Motors", label: "Srinidhi Motors" },
  { value: "Vijyatha Auto Finance", label: "Vijyatha Auto Finance" },
  { value: "Shri Venkatesh Nagari Sah Pat San Maryadit", label: "Shri Venkatesh Nagari Sah Pat San Maryadit" },
  { value: "Hanuman Gramin Biger Sheti Sah Pat San Maryadit", label: "Hanuman Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Agasti Gramin B.S.Sah.Patsanstha Maryadit", label: "Agasti Gramin B.S.Sah.Patsanstha Maryadit" },
  { value: "Kanchan Auto Finance Ltd", label: "Kanchan Auto Finance Ltd" },
  { value: "Sree Rajath Enterprises", label: "Sree Rajath Enterprises" },
  { value: "Mercury Car Rentals", label: "Mercury Car Rentals" },
  { value: "Savatamaharaj Gramin Biger Sheti Sah Pat San Marya", label: "Savatamaharaj Gramin Biger Sheti Sah Pat San Marya" },
  { value: "Ganesh Khedgaon Vikas Seva Sah Sanstha", label: "Ganesh Khedgaon Vikas Seva Sah Sanstha" },
  { value: "Narendera Finance Company", label: "Narendera Finance Company" },
  { value: "Future Capital Holdings Ltd", label: "Future Capital Holdings Ltd" },
  { value: "Aanya Finance", label: "Aanya Finance" },
  { value: "Manglam Finance", label: "Manglam Finance" },
  { value: "Singer Finance", label: "Singer Finance" },
  { value: "Kabra Motors", label: "Kabra Motors" },
  { value: "Ayyappa Auto Finance", label: "Ayyappa Auto Finance" },
  { value: "Kanhaiyalal Anurag Kumar", label: "Kanhaiyalal Anurag Kumar" },
  { value: "Mahesh Nagari Multi State Co Op Credit Soc Ltd", label: "Mahesh Nagari Multi State Co Op Credit Soc Ltd" },
  { value: "Savali Gramin Big Sah Path Parner Parner", label: "Savali Gramin Big Sah Path Parner Parner" },
  { value: "Sbi Funds Management Pvt Ltd", label: "Sbi Funds Management Pvt Ltd" },
  { value: "Landesbank Baden Wurttemberg", label: "Landesbank Baden Wurttemberg" },
  { value: "International Finance Corporation", label: "International Finance Corporation" },
  { value: "Export Import Bank Of India", label: "Export Import Bank Of India" },
  { value: "Sharda Nagri Sahakari Pathpedi Ltd", label: "Sharda Nagri Sahakari Pathpedi Ltd" },
  { value: "Yashwant Co Op Credit Society Ltd", label: "Yashwant Co Op Credit Society Ltd" },
  { value: "Krushnai Sahakari Patsanstha Mary Wai", label: "Krushnai Sahakari Patsanstha Mary Wai" },
  { value: "Ozar Merchant Ltd", label: "Ozar Merchant Ltd" },
  { value: "Mahatma Phule Multi State Co Op Bank Ltd", label: "Mahatma Phule Multi State Co Op Bank Ltd" },
  { value: "R R Associate", label: "R R Associate" },
  { value: "Way 2 Serve Micro Finance Pvt Ltd", label: "Way 2 Serve Micro Finance Pvt Ltd" },
  { value: "Pavithra Finance And Investment", label: "Pavithra Finance And Investment" },
  { value: "West Bengal Minorities Development And Finance Corporation", label: "West Bengal Minorities Development And Finance Corporation" },
  { value: "Sri Lakshmi Narasimha Auto Finance Coproration", label: "Sri Lakshmi Narasimha Auto Finance Coproration" },
  { value: "Janata Sahakari Bank Ltd Ajara", label: "Janata Sahakari Bank Ltd Ajara" },
  { value: "M J Finance", label: "M J Finance" },
  { value: "Vishal Auto", label: "Vishal Auto" },
  { value: "Shweta Enterprises", label: "Shweta Enterprises" },
  { value: "Shree Consultancy And Insurance Services", label: "Shree Consultancy And Insurance Services" },
  { value: "Bhagyashree Mahila Nag Sah Path San", label: "Bhagyashree Mahila Nag Sah Path San" },
  { value: "Balaji Capital Ltd", label: "Balaji Capital Ltd" },
  { value: "Government Of Kerala", label: "Government Of Kerala" },
  { value: "Director, Vikram Sarabhai Space Center", label: "Director, Vikram Sarabhai Space Center" },
  { value: "Suresh Chand Sokdia", label: "Suresh Chand Sokdia" },
  { value: "Jumbo Finvest Pvt Limited", label: "Jumbo Finvest Pvt Limited" },
  { value: "The Kamran Vikas Credit Coop Society Ltd", label: "The Kamran Vikas Credit Coop Society Ltd" },
  { value: "Kisan Union Gramin Big Sheti Sah Patsant Parner", label: "Kisan Union Gramin Big Sheti Sah Patsant Parner" },
  { value: "Lovely Motor Finance", label: "Lovely Motor Finance" },
  { value: "Sri Byraveshwara Sahakara Bank Niyamitha", label: "Sri Byraveshwara Sahakara Bank Niyamitha" },
  { value: "Trinity Co Op Credit Society Ltd", label: "Trinity Co Op Credit Society Ltd" },
  { value: "Shaw Finance Co", label: "Shaw Finance Co" },
  { value: "Malayidamthuruth Service Co Op Bank Ltd", label: "Malayidamthuruth Service Co Op Bank Ltd" },
  { value: "Osmanabad Janta Nagari Sa. Bank Ltd", label: "Osmanabad Janta Nagari Sa. Bank Ltd" },
  { value: "Aditya Anagha Multistate Credit Co Op Society", label: "Aditya Anagha Multistate Credit Co Op Society" },
  { value: "Dr Hefdewad Gramin Bigarsheti Gramin Path Sanstha", label: "Dr Hefdewad Gramin Bigarsheti Gramin Path Sanstha" },
  { value: "Jai Mata Di Nagari Sahakari Pathsantha Maryadit", label: "Jai Mata Di Nagari Sahakari Pathsantha Maryadit" },
  { value: "Takniki Kara Sahak Sakh Sans Maryadit", label: "Takniki Kara Sahak Sakh Sans Maryadit" },
  { value: "Shree Ganesh Nagari Sahkari Pat Sanshtha, Marya Bhokardan", label: "Shree Ganesh Nagari Sahkari Pat Sanshtha, Marya Bhokardan" },
  { value: "Swastik Enterprises", label: "Swastik Enterprises" },
  { value: "Jai Sitaram Nagri Sah Pathsanstha", label: "Jai Sitaram Nagri Sah Pathsanstha" },
  { value: "Sahakar Maharshi Ma Kakasaheb Biger Sheti Sah Patsanstha Mary", label: "Sahakar Maharshi Ma Kakasaheb Biger Sheti Sah Patsanstha Mary" },
  { value: "Saibaba Gramin Biger Sheti Sah Pat Mary", label: "Saibaba Gramin Biger Sheti Sah Pat Mary" },
  { value: "P D Patil Sahakari Bank Ltd", label: "P D Patil Sahakari Bank Ltd" },
  { value: "Sri Sharavana Balajii Auto Finance", label: "Sri Sharavana Balajii Auto Finance" },
  { value: "Danashiri Auto Finance", label: "Danashiri Auto Finance" },
  { value: "Sree Raajaa Lakshmi Finance", label: "Sree Raajaa Lakshmi Finance" },
  { value: "Seendhur Velu Auto Finance", label: "Seendhur Velu Auto Finance" },
  { value: "Sri Ponni Auto Finance", label: "Sri Ponni Auto Finance" },
  { value: "Skm Auto Finance", label: "Skm Auto Finance" },
  { value: "Oais Auto Financial Services Ltd", label: "Oais Auto Financial Services Ltd" },
  { value: "Mahalaxmi Mahila Co Op Credit Soc Ltd", label: "Mahalaxmi Mahila Co Op Credit Soc Ltd" },
  { value: "Karavali Credit Co Op Society Ltd", label: "Karavali Credit Co Op Society Ltd" },
  { value: "Bhawani Credit Co Op Society Ltd", label: "Bhawani Credit Co Op Society Ltd" },
  { value: "Delhi Financial Corporation", label: "Delhi Financial Corporation" },
  { value: "Manchar Nagari Sah Path Mar", label: "Manchar Nagari Sah Path Mar" },
  { value: "Vikas Co Op Credit Society Ltd", label: "Vikas Co Op Credit Society Ltd" },
  { value: "Shalibhadra Finance Limited", label: "Shalibhadra Finance Limited" },
  { value: "Mas Financials Finance Services Ltd", label: "Mas Financials Finance Services Ltd" },
  { value: "Vanaviz Auto Finance", label: "Vanaviz Auto Finance" },
  { value: "Sun Motors And Auto Finance", label: "Sun Motors And Auto Finance" },
  { value: "Hallapura Pattina Souharda Sahakari Ni", label: "Hallapura Pattina Souharda Sahakari Ni" },
  { value: "K S Capital Ser Pvt Ltd", label: "K S Capital Ser Pvt Ltd" },
  { value: "Shri Vishweshwar Sahakari Pathpedi Maryadit Mumbai", label: "Shri Vishweshwar Sahakari Pathpedi Maryadit Mumbai" },
  { value: "Sree Lakshmi Auto Finance And Finance", label: "Sree Lakshmi Auto Finance And Finance" },
  { value: "The Payyanad Service Co Op Bank Ltd", label: "The Payyanad Service Co Op Bank Ltd" },
  { value: "Tata Capital Limited", label: "Tata Capital Limited" },
  { value: "Parth Finance", label: "Parth Finance" },
  { value: "Akme Fincon Pvt Ltd", label: "Akme Fincon Pvt Ltd" },
  { value: "Rajender Kumar Dharewa", label: "Rajender Kumar Dharewa" },
  { value: "Navkaar Finance", label: "Navkaar Finance" },
  { value: "Aashirwad Investment", label: "Aashirwad Investment" },
  { value: "Joy Sethia", label: "Joy Sethia" },
  { value: "Kamlajadevi Gramin Bigarsheti Patsanstha Maryadit", label: "Kamlajadevi Gramin Bigarsheti Patsanstha Maryadit" },
  { value: "Astra Zeneca Pharma India Ltd", label: "Astra Zeneca Pharma India Ltd" },
  { value: "Kulasekharapuram Service Co Op Bank Ltd", label: "Kulasekharapuram Service Co Op Bank Ltd" },
  { value: "Payal Portfolio Private Limited", label: "Payal Portfolio Private Limited" },
  { value: "St Gonsalo Garcia Co Op Soc", label: "St Gonsalo Garcia Co Op Soc" },
  { value: "Shri Gayatri Credit Souhard Sahakari Niyamitha", label: "Shri Gayatri Credit Souhard Sahakari Niyamitha" },
  { value: "Jaihind Nagari Sahakari Pat Sanstha Maryadit", label: "Jaihind Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Goutham Chand Automobile Finance", label: "Goutham Chand Automobile Finance" },
  { value: "Simer Hire Purchase Limited", label: "Simer Hire Purchase Limited" },
  { value: "Dnyandeep Gramin Biger Sheti Sah Pat San Maryadit", label: "Dnyandeep Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Mangalmurti Nagari Sahakari Pat Maryadit", label: "Mangalmurti Nagari Sahakari Pat Maryadit" },
  { value: "Nagpur Mahila Vikas Co Op Credit Soc Ltd", label: "Nagpur Mahila Vikas Co Op Credit Soc Ltd" },
  { value: "Ganraj Gramin Biger Sheti Sah Pat San Maryadit", label: "Ganraj Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Simar Hire Purchase Limited", label: "Simar Hire Purchase Limited" },
  { value: "Prerna Sahakari Bank Ltd", label: "Prerna Sahakari Bank Ltd" },
  { value: "Vasantrao Patil Kakade Gramin Biger Sheti Sah Pat", label: "Vasantrao Patil Kakade Gramin Biger Sheti Sah Pat" },
  { value: "Tjsb Sahakari Bank Ltd", label: "Tjsb Sahakari Bank Ltd" },
  { value: "Maharashtra Bank Ltd", label: "Maharashtra Bank Ltd" },
  { value: "Taminadu Bus Opreator Finance Corporation Ltd , Chennai", label: "Taminadu Bus Opreator Finance Corporation Ltd , Chennai" },
  { value: "Kabirdas Investment Ltd", label: "Kabirdas Investment Ltd" },
  { value: "Sunil Tater", label: "Sunil Tater" },
  { value: "D Kishan Chand Jain", label: "D Kishan Chand Jain" },
  { value: "Thirupathi Finance", label: "Thirupathi Finance" },
  { value: "The Vengad Service Co Operative Bank Ltd", label: "The Vengad Service Co Operative Bank Ltd" },
  { value: "Cochin Spices Pvt Ltd", label: "Cochin Spices Pvt Ltd" },
  { value: "Chatrapati Rajeshri Shahu Urban Co Op Bank Ltd", label: "Chatrapati Rajeshri Shahu Urban Co Op Bank Ltd" },
  { value: "Hdfc Bank", label: "Hdfc Bank" },
  { value: "Paragon Finance Ltd", label: "Paragon Finance Ltd" },
  { value: "Ge Capital T F S Ltd", label: "Ge Capital T F S Ltd" },
  { value: "Magma Fin Leasing Ltd", label: "Magma Fin Leasing Ltd" },
  { value: "Someshwar Nagari Sah Pat Maryadit", label: "Someshwar Nagari Sah Pat Maryadit" },
  { value: "Shree Bharatnagar Seva Sahkari Mandli Ltd", label: "Shree Bharatnagar Seva Sahkari Mandli Ltd" },
  { value: "Sant Rohidascharmakar And Charma Udyog Vikas Mahama", label: "Sant Rohidascharmakar And Charma Udyog Vikas Mahama" },
  { value: "Mahatma Fule Gramin Non Agri Sahkari Pat Sanstha", label: "Mahatma Fule Gramin Non Agri Sahkari Pat Sanstha" },
  { value: "Mazi Sainik Nagari Sahakari Pat San Maryadit", label: "Mazi Sainik Nagari Sahakari Pat San Maryadit" },
  { value: "Chhatrpati Vikas Society", label: "Chhatrpati Vikas Society" },
  { value: "Amit Finance", label: "Amit Finance" },
  { value: "Prapti Finance", label: "Prapti Finance" },
  { value: "Alang Credits And Holdings P Ltd", label: "Alang Credits And Holdings P Ltd" },
  { value: "Janpriya Sahakari Pathsantha Maryadit", label: "Janpriya Sahakari Pathsantha Maryadit" },
  { value: "Bhavani Urban Co Op Bank Ltd Georai", label: "Bhavani Urban Co Op Bank Ltd Georai" },
  { value: "Lalith Sethia H U F", label: "Lalith Sethia H U F" },
  { value: "Shri Naminath Gramin Biger Sheti Sah Pat Ltd", label: "Shri Naminath Gramin Biger Sheti Sah Pat Ltd" },
  { value: "Fine Motors Finvest Pvt Ltd", label: "Fine Motors Finvest Pvt Ltd" },
  { value: "The Umiya Sahakari Sharafi Mandali Ltd", label: "The Umiya Sahakari Sharafi Mandali Ltd" },
  { value: "Sharadrao Pawar Nagari Sah Patsanstha", label: "Sharadrao Pawar Nagari Sah Patsanstha" },
  { value: "Belthangady Taluk Rubber Growers Marketing And Processing Co Op Society Ltd", label: "Belthangady Taluk Rubber Growers Marketing And Processing Co Op Society Ltd" },
  { value: "Shri Kalmeshwar Sahakari Patsanstha Maryadit Ltd", label: "Shri Kalmeshwar Sahakari Patsanstha Maryadit Ltd" },
  { value: "Pimpri Chnchwad Co Op Bank Ltd", label: "Pimpri Chnchwad Co Op Bank Ltd" },
  { value: "Vardhman C Co Op So Ltd", label: "Vardhman C Co Op So Ltd" },
  { value: "Magma Leasing Limited", label: "Magma Leasing Limited" },
  { value: "Bahujan Coal Urja Sah Pat Sanstha", label: "Bahujan Coal Urja Sah Pat Sanstha" },
  { value: "Amardevi Nagari Sahakari Pat Sanstha Maryadit", label: "Amardevi Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Ge Capital Transportation Financial Services Ltd", label: "Ge Capital Transportation Financial Services Ltd" },
  { value: "Gujarat Narmada Valley Fert Co Ltd", label: "Gujarat Narmada Valley Fert Co Ltd" },
  { value: "Ashwamegh Co Op Credit Soc Ltd", label: "Ashwamegh Co Op Credit Soc Ltd" },
  { value: "Shree Jain Gramin Biger Sheti Sahakari Pat San Mar", label: "Shree Jain Gramin Biger Sheti Sahakari Pat San Mar" },
  { value: "Srei Equipments Finance Pvt Ltd", label: "Srei Equipments Finance Pvt Ltd" },
  { value: "Mashreq Bank Psc", label: "Mashreq Bank Psc" },
  { value: "Shree Jalaram Co Op Consumer Soc Ltd", label: "Shree Jalaram Co Op Consumer Soc Ltd" },
  { value: "Satara District Central Co Operative Bank Ltd", label: "Satara District Central Co Operative Bank Ltd" },
  { value: "India Infoline Finance Ltd", label: "India Infoline Finance Ltd" },
  { value: "Shree Dattkrupa Nagari Sah Path", label: "Shree Dattkrupa Nagari Sah Path" },
  { value: "Shri Sant Sawatamali Gbss. Pat Santha", label: "Shri Sant Sawatamali Gbss. Pat Santha" },
  { value: "Shree Sai Gramin Biger Sheti Path", label: "Shree Sai Gramin Biger Sheti Path" },
  { value: "Satnam Finance Co.", label: "Satnam Finance Co." },
  { value: "Vijay Sah.Pat.San.Mar.Ichalkaranji", label: "Vijay Sah.Pat.San.Mar.Ichalkaranji" },
  { value: "Azad Hind Nag.Sah.Pat.Sant.Ltd", label: "Azad Hind Nag.Sah.Pat.Sant.Ltd" },
  { value: "Dhawalsagar Gram.B.S.S.P.San.Mar", label: "Dhawalsagar Gram.B.S.S.P.San.Mar" },
  { value: "Jinendra Gramin B.S.S.P.Mar", label: "Jinendra Gramin B.S.S.P.Mar" },
  { value: "Sarvoday G.B.S.S.P.S.Mar", label: "Sarvoday G.B.S.S.P.S.Mar" },
  { value: "Shrimati Koushalaybai A.N.Nimbalkar N.S", label: "Shrimati Koushalaybai A.N.Nimbalkar N.S" },
  { value: "Rajashri Shahu N.S.P.S.Br Tardal", label: "Rajashri Shahu N.S.P.S.Br Tardal" },
  { value: "Yuvashakti Nag.Sah.Pat.San.Mar.Rendal", label: "Yuvashakti Nag.Sah.Pat.San.Mar.Rendal" },
  { value: "Veeracharya B.K.A.S And Urban Co Cr Soci Shedshal", label: "Veeracharya B.K.A.S And Urban Co Cr Soci Shedshal" },
  { value: "Jaykisan Gr.B.S.S.Pat Sa Mar Nandani", label: "Jaykisan Gr.B.S.S.Pat Sa Mar Nandani" },
  { value: "Shri Mahalaxmi Nag. Sah.Pat.San.Mar.", label: "Shri Mahalaxmi Nag. Sah.Pat.San.Mar." },
  { value: "Shri Kalleshwar Vks.V.S.S.Mydt", label: "Shri Kalleshwar Vks.V.S.S.Mydt" },
  { value: "Shri Jodmahalaxmi Vividh.Sou.Sah.Nymt", label: "Shri Jodmahalaxmi Vividh.Sou.Sah.Nymt" },
  { value: "Shri Shivneri G.B.S.S.P.S.Mydt", label: "Shri Shivneri G.B.S.S.P.S.Mydt" },
  { value: "Dhanashri Gramin Bigar Sheti Sahakari Pat Sanstha", label: "Dhanashri Gramin Bigar Sheti Sahakari Pat Sanstha" },
  { value: "Shri Shivajiraje Vyapari N.S.P.S.Mydt", label: "Shri Shivajiraje Vyapari N.S.P.S.Mydt" },
  { value: "Lokmanya Multipurpose Co Op Society Ltd", label: "Lokmanya Multipurpose Co Op Society Ltd" },
  { value: "Udyog Nagari Sah Pat Sanstha Mydt", label: "Udyog Nagari Sah Pat Sanstha Mydt" },
  { value: "Sarvoday Nagari Sah Patsanstha", label: "Sarvoday Nagari Sah Patsanstha" },
  { value: "Shri Laxmi Cr.Sou.Sah.Ltd,Manjari", label: "Shri Laxmi Cr.Sou.Sah.Ltd,Manjari" },
  { value: "The Janta Urban Co Op Bank.Cr.Soc.Ltd", label: "The Janta Urban Co Op Bank.Cr.Soc.Ltd" },
  { value: "Tardal Gramin.Bigersheti.Sah.Patsanstha.Mary.Tardal", label: "Tardal Gramin.Bigersheti.Sah.Patsanstha.Mary.Tardal" },
  { value: "Shri Jaybhavani Nagari Sahkari Patsanstha.Mary.Hupari", label: "Shri Jaybhavani Nagari Sahkari Patsanstha.Mary.Hupari" },
  { value: "Dhanashri Gramin Bigersheti Sah.Patsanstha.Mar.Herwad", label: "Dhanashri Gramin Bigersheti Sah.Patsanstha.Mar.Herwad" },
  { value: "Rangoli V.K.S. Vikas Seva San.Mar.Rangoli", label: "Rangoli V.K.S. Vikas Seva San.Mar.Rangoli" },
  { value: "Mangaon Nagari Sah Patsanstha Mangaon", label: "Mangaon Nagari Sah Patsanstha Mangaon" },
  { value: "The Islampur Urban Co Op Bank Ltd", label: "The Islampur Urban Co Op Bank Ltd" },
  { value: "Modakeshwer Gramin Bin Sheti Saha Path", label: "Modakeshwer Gramin Bin Sheti Saha Path" },
  { value: "Maharudra Hanuman N S Pat", label: "Maharudra Hanuman N S Pat" },
  { value: "Sai Shakti Nagri Sahakari Pat Sanstha Maryadit", label: "Sai Shakti Nagri Sahakari Pat Sanstha Maryadit" },
  { value: "Dnyansawardhini Gramin Biger Sheti Sah Pat San Mar", label: "Dnyansawardhini Gramin Biger Sheti Sah Pat San Mar" },
  { value: "Kherva Co Op Bank Ltd", label: "Kherva Co Op Bank Ltd" },
  { value: "President Of India Space Applications Center Isro", label: "President Of India Space Applications Center Isro" },
  { value: "India Bull Financial Services Ltd", label: "India Bull Financial Services Ltd" },
  { value: "Bramhapuri Urban Co Op Bank Ltd", label: "Bramhapuri Urban Co Op Bank Ltd" },
  { value: "Anmol Financial Services Regd", label: "Anmol Financial Services Regd" },
  { value: "Khashbag Urban Co Op Credit Society Ltd", label: "Khashbag Urban Co Op Credit Society Ltd" },
  { value: "Shramjivi Nagar Nagrik Bank", label: "Shramjivi Nagar Nagrik Bank" },
  { value: "The Morayur Service Co Op Bank Ltd", label: "The Morayur Service Co Op Bank Ltd" },
  { value: "Deonadi Khore Big. Sah. Path. San. Mry.", label: "Deonadi Khore Big. Sah. Path. San. Mry." },
  { value: "The Khanapur Co Operative Bank Ltd., Khanapur", label: "The Khanapur Co Operative Bank Ltd., Khanapur" },
  { value: "Credit Agricole", label: "Credit Agricole" },
  { value: "Sharadchandra Sahakari Patsanstha Maryadit Mumbai", label: "Sharadchandra Sahakari Patsanstha Maryadit Mumbai" },
  { value: "Gawadewadi Vivid Karyakari Sahakari Society", label: "Gawadewadi Vivid Karyakari Sahakari Society" },
  { value: "Jagruti Patsanstha Ltd", label: "Jagruti Patsanstha Ltd" },
  { value: "Arora Financial Services Regd", label: "Arora Financial Services Regd" },
  { value: "Lg Electronics India Pvt Ltd", label: "Lg Electronics India Pvt Ltd" },
  { value: "Way 2 Serve Micro Finance Pvt. Ltd", label: "Way 2 Serve Micro Finance Pvt. Ltd" },
  { value: "Adithya Motors", label: "Adithya Motors" },
  { value: "Golden Motors", label: "Golden Motors" },
  { value: "Suryabha Developers Pvt Ltd", label: "Suryabha Developers Pvt Ltd" },
  { value: "Bamne Janseva Sahakari Patpedi Ltd", label: "Bamne Janseva Sahakari Patpedi Ltd" },
  { value: "Lalit Investment", label: "Lalit Investment" },
  { value: "S A Investments", label: "S A Investments" },
  { value: "Vijay Syndicate", label: "Vijay Syndicate" },
  { value: "Narsing Nag Sah Patsanstha", label: "Narsing Nag Sah Patsanstha" },
  { value: "Capital First Limited", label: "Capital First Limited" },
  { value: "Flutron India Credit Co Ltd", label: "Flutron India Credit Co Ltd" },
  { value: "Punjab Kassmir Finance Ltd.", label: "Punjab Kassmir Finance Ltd." },
  { value: "Jalalpur Vajifa Seva Sahakari Mandali Ltd", label: "Jalalpur Vajifa Seva Sahakari Mandali Ltd" },
  { value: "Shree Radhay Shyam Financial Services", label: "Shree Radhay Shyam Financial Services" },
  { value: "Shree Hariyant Gramin Sahakari Pat Sanstha Maryadit", label: "Shree Hariyant Gramin Sahakari Pat Sanstha Maryadit" },
  { value: "Ekta Nagari Saha Path Sanstha Mryadit", label: "Ekta Nagari Saha Path Sanstha Mryadit" },
  { value: "Shree Parevavir Co Op Credit Society", label: "Shree Parevavir Co Op Credit Society" },
  { value: "Shripatraodada Sahakari Bank Ltd Kolhapur", label: "Shripatraodada Sahakari Bank Ltd Kolhapur" },
  { value: "Odisha Gramya Bank", label: "Odisha Gramya Bank" },
  { value: "Saibaba Mitra Mandal Finance", label: "Saibaba Mitra Mandal Finance" },
  { value: "Infrastructure Development Finance Company Limited", label: "Infrastructure Development Finance Company Limited" },
  { value: "A S K Enterprises", label: "A S K Enterprises" },
  { value: "Anjali Co Op Credit Society Ltd", label: "Anjali Co Op Credit Society Ltd" },
  { value: "Allahabad Up Gramin Bank", label: "Allahabad Up Gramin Bank" },
  { value: "Ameeta And Co", label: "Ameeta And Co" },
  { value: "Lease Index India Ltd", label: "Lease Index India Ltd" },
  { value: "Meenachil Co Op Agricultural And Rural Development Bank Ltd", label: "Meenachil Co Op Agricultural And Rural Development Bank Ltd" },
  { value: "Rhino Finance Co", label: "Rhino Finance Co" },
  { value: "Thrissur Auto Hire Purchase", label: "Thrissur Auto Hire Purchase" },
  { value: "The Vaniyambadi Town Co Operative Bank Ltd", label: "The Vaniyambadi Town Co Operative Bank Ltd" },
  { value: "N M C E Of India Ltd", label: "N M C E Of India Ltd" },
  { value: "Jai Vaibhav Laxmi Multistate Co Op Credit Soc Ltd", label: "Jai Vaibhav Laxmi Multistate Co Op Credit Soc Ltd" },
  { value: "Iland Fs Trust Company Limited", label: "Iland Fs Trust Company Limited" },
  { value: "Shree Sant Sena Maharaj Nagari Sah Patsansta Mar", label: "Shree Sant Sena Maharaj Nagari Sah Patsansta Mar" },
  { value: "The Isanpur Mota Seva Sahakari Mandali Ltd", label: "The Isanpur Mota Seva Sahakari Mandali Ltd" },
  { value: "Shri Siddhnath Nagari Sahakari Pat San Maryadit", label: "Shri Siddhnath Nagari Sahakari Pat San Maryadit" },
  { value: "Smile Finance", label: "Smile Finance" },
  { value: "Genuine Financial Services Gfs", label: "Genuine Financial Services Gfs" },
  { value: "Shyam Finance", label: "Shyam Finance" },
  { value: "Keshav Lease Finance", label: "Keshav Lease Finance" },
  { value: "Tara Auto Finance", label: "Tara Auto Finance" },
  { value: "Shree Kherva Co Op Credit Society Ltd", label: "Shree Kherva Co Op Credit Society Ltd" },
  { value: "Manvik Enterprises", label: "Manvik Enterprises" },
  { value: "Sml Finance Ltd", label: "Sml Finance Ltd" },
  { value: "Union Farmers Service Co Op Sl Ltd", label: "Union Farmers Service Co Op Sl Ltd" },
  { value: "Basantima Co Op Credit Soc Ltd", label: "Basantima Co Op Credit Soc Ltd" },
  { value: "Sidbi", label: "Sidbi" },
  { value: "Late Fakira Jairam Patil Sah Pat Maryadit", label: "Late Fakira Jairam Patil Sah Pat Maryadit" },
  { value: "Jagdambe Matoshri Gadadavane Adi Vi Kary Soc Ltd", label: "Jagdambe Matoshri Gadadavane Adi Vi Kary Soc Ltd" },
  { value: "The Ganesh Sahakari Bank Ltd", label: "The Ganesh Sahakari Bank Ltd" },
  { value: "Wardha Nagrik Sahakari Adhikosh Bank Mydt", label: "Wardha Nagrik Sahakari Adhikosh Bank Mydt" },
  { value: "Defence Accounts Co Operative Bank Ltd", label: "Defence Accounts Co Operative Bank Ltd" },
  { value: "The Trant Seva Sahakari Mandli Ltd", label: "The Trant Seva Sahakari Mandli Ltd" },
  { value: "Au Financiers India Ltd", label: "Au Financiers India Ltd" },
  { value: "New Periyar Auto Finance, Perumbavoor", label: "New Periyar Auto Finance, Perumbavoor" },
  { value: "Abhiradhi Pattina Souharda Sahakari Ni", label: "Abhiradhi Pattina Souharda Sahakari Ni" },
  { value: "Madan Investments", label: "Madan Investments" },
  { value: "Sri Rajeshwari Enterprises", label: "Sri Rajeshwari Enterprises" },
  { value: "Rajashri Shahu Vividh Karyakari Sah Vikas Seva Sanstha", label: "Rajashri Shahu Vividh Karyakari Sah Vikas Seva Sanstha" },
  { value: "Late Shri Shivajirao Dadaso Patil Vikas Seva Sanstha Ltd Bhadole", label: "Late Shri Shivajirao Dadaso Patil Vikas Seva Sanstha Ltd Bhadole" },
  { value: "Jai Ganesh Minorities Multipurpose Co Op Society Ltd", label: "Jai Ganesh Minorities Multipurpose Co Op Society Ltd" },
  { value: "Vadgaon Saraf Vyapari Nagari Patsanstha", label: "Vadgaon Saraf Vyapari Nagari Patsanstha" },
  { value: "Shri Sidheshwar Nagari Patsanstha", label: "Shri Sidheshwar Nagari Patsanstha" },
  { value: "Janta Sahkari Bank Ltd", label: "Janta Sahkari Bank Ltd" },
  { value: "Dhanashri Nagari Sahakari Patsanstha Maraydit Chikhali", label: "Dhanashri Nagari Sahakari Patsanstha Maraydit Chikhali" },
  { value: "V K Lease Finance", label: "V K Lease Finance" },
  { value: "Shri Anand Gramin Bigarsheti Saha Pathsans", label: "Shri Anand Gramin Bigarsheti Saha Pathsans" },
  { value: "Fine Autop Leasing And Finance P Ltd", label: "Fine Autop Leasing And Finance P Ltd" },
  { value: "Aman Agro Industries", label: "Aman Agro Industries" },
  { value: "Idfc Limited", label: "Idfc Limited" },
  { value: "Angel Prompt Finance", label: "Angel Prompt Finance" },
  { value: "Nagercoil Prompt Finance", label: "Nagercoil Prompt Finance" },
  { value: "Prompt Finance", label: "Prompt Finance" },
  { value: "Shree Nana Vekhende Gramin Patasanstha", label: "Shree Nana Vekhende Gramin Patasanstha" },
  { value: "Rokdeshwar Gramin Biger Sheti Sah Pat San Maryadit", label: "Rokdeshwar Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Late Balu Ambaji Patil Vikas Seva Sanstha", label: "Late Balu Ambaji Patil Vikas Seva Sanstha" },
  { value: "Paraswanath Finance Corporation", label: "Paraswanath Finance Corporation" },
  { value: "Saikrupa Sahakari Patpedhi Maryadit", label: "Saikrupa Sahakari Patpedhi Maryadit" },
  { value: "The Nangal Jarialan Co Op Agr Society Ltd", label: "The Nangal Jarialan Co Op Agr Society Ltd" },
  { value: "Anz Bank", label: "Anz Bank" },
  { value: "Karuna Dugar", label: "Karuna Dugar" },
  { value: "Prakash Dugar Hvf", label: "Prakash Dugar Hvf" },
  { value: "S K Motors", label: "S K Motors" },
  { value: "The Borgaon Urban Co Op Bank Ltd", label: "The Borgaon Urban Co Op Bank Ltd" },
  { value: "Tardal Gramin Bigar Sheti Sah Pat Sanstha Marya Tardal", label: "Tardal Gramin Bigar Sheti Sah Pat Sanstha Marya Tardal" },
  { value: "Krystal Employees Co Op Credit Society Ltd", label: "Krystal Employees Co Op Credit Society Ltd" },
  { value: "Mangalmurti Sahakari Patpedi Ltd", label: "Mangalmurti Sahakari Patpedi Ltd" },
  { value: "Shri Prem Finance", label: "Shri Prem Finance" },
  { value: "The Government Emp Co Op Credit Soc Ltd", label: "The Government Emp Co Op Credit Soc Ltd" },
  { value: "Hyderabad Mutual Benefit Society", label: "Hyderabad Mutual Benefit Society" },
  { value: "Shri Birdev Co Op Credit Society Ltd", label: "Shri Birdev Co Op Credit Society Ltd" },
  { value: "Shri Harha Mahadev Vikas Seva Sanstha Koparde", label: "Shri Harha Mahadev Vikas Seva Sanstha Koparde" },
  { value: "Agriculture Rural Development Bank", label: "Agriculture Rural Development Bank" },
  { value: "Central Madhya Pradesh Gramin Bank", label: "Central Madhya Pradesh Gramin Bank" },
  { value: "Shri Dharmasthala Manjunatha Pattina Souharda Sahakari Niyamita", label: "Shri Dharmasthala Manjunatha Pattina Souharda Sahakari Niyamita" },
  { value: "The Peoples Co Op Credit Society Ltd", label: "The Peoples Co Op Credit Society Ltd" },
  { value: "Shri Kalmeshwar Sahakari Patsanstra Ltd", label: "Shri Kalmeshwar Sahakari Patsanstra Ltd" },
  { value: "Jumbo Finvest India Ltd", label: "Jumbo Finvest India Ltd" },
  { value: "Sri Vaishnavo Finance", label: "Sri Vaishnavo Finance" },
  { value: "Surendra Kumar Kothari", label: "Surendra Kumar Kothari" },
  { value: "Brd Securites Ltd", label: "Brd Securites Ltd" },
  { value: "Krushna Finance", label: "Krushna Finance" },
  { value: "Shree Ganesh Co Op Credit Society Ltd", label: "Shree Ganesh Co Op Credit Society Ltd" },
  { value: "Central Madhyapradesh Gramin Bank", label: "Central Madhyapradesh Gramin Bank" },
  { value: "Shri Jay Jinendra Credit Souhard Sahakari Nyt", label: "Shri Jay Jinendra Credit Souhard Sahakari Nyt" },
  { value: "Prerana Sahakari Pathsansth Mydt", label: "Prerana Sahakari Pathsansth Mydt" },
  { value: "The Tabba Co Op Agri Ser Society Ltd", label: "The Tabba Co Op Agri Ser Society Ltd" },
  { value: "Shrikrishna Co Op Credit Society Ltd", label: "Shrikrishna Co Op Credit Society Ltd" },
  { value: "Lic Of India", label: "Lic Of India" },
  { value: "Anusaya Shinde Big Sheti Sah Pat Sanstha Parner", label: "Anusaya Shinde Big Sheti Sah Pat Sanstha Parner" },
  { value: "Shram Safalya Sahkari Patsansta", label: "Shram Safalya Sahkari Patsansta" },
  { value: "Swastik Finance", label: "Swastik Finance" },
  { value: "Nitesh Surana And Sons", label: "Nitesh Surana And Sons" },
  { value: "Indiabulls Infrasturucture Credit Limited", label: "Indiabulls Infrasturucture Credit Limited" },
  { value: "Samtani Enterprises", label: "Samtani Enterprises" },
  { value: "Mansi Mahila Nagari Sahkari Patsanshta Marya Aurangabad", label: "Mansi Mahila Nagari Sahkari Patsanshta Marya Aurangabad" },
  { value: "Yogi Co Op Credit And Consumers Society Ltd", label: "Yogi Co Op Credit And Consumers Society Ltd" },
  { value: "Ankit Finance", label: "Ankit Finance" },
  { value: "Magma Shrachi Finance Ltd", label: "Magma Shrachi Finance Ltd" },
  { value: "Dr Vitthalrao Vikhe Patil Co Op Bank Ltd", label: "Dr Vitthalrao Vikhe Patil Co Op Bank Ltd" },
  { value: "U B V V N", label: "U B V V N" },
  { value: "Morya Nagari Sah Patsanstha Mary", label: "Morya Nagari Sah Patsanstha Mary" },
  { value: "Dist Manager Mahatma Phule Back Word Class Development Corporation Ltd", label: "Dist Manager Mahatma Phule Back Word Class Development Corporation Ltd" },
  { value: "Shivkrupa Nagari Sahakari Patsantha Ltd", label: "Shivkrupa Nagari Sahakari Patsantha Ltd" },
  { value: "Idea Cellular Ltd", label: "Idea Cellular Ltd" },
  { value: "Hani Finance", label: "Hani Finance" },
  { value: "Capital Hind And Fin Pvt Ltd", label: "Capital Hind And Fin Pvt Ltd" },
  { value: "The Balakrishna Multipurpose Co Op Society Ltd", label: "The Balakrishna Multipurpose Co Op Society Ltd" },
  { value: "Shri Jai Jinendra Credit Souhard Sahakari Nyt", label: "Shri Jai Jinendra Credit Souhard Sahakari Nyt" },
  { value: "Shamanewadi Credit Souhard Sahakari Nyt Shamanewad", label: "Shamanewadi Credit Souhard Sahakari Nyt Shamanewad" },
  { value: "Keshar Finance And Leasing Pvt Ltd", label: "Keshar Finance And Leasing Pvt Ltd" },
  { value: "Maula Co Op Credit Soc Ltd", label: "Maula Co Op Credit Soc Ltd" },
  { value: "Sardarmal Kankaria", label: "Sardarmal Kankaria" },
  { value: "Kamal Auto Finance Ltd", label: "Kamal Auto Finance Ltd" },
  { value: "Mahalaxmi Credit Co Op Soc Ltd", label: "Mahalaxmi Credit Co Op Soc Ltd" },
  { value: "The Pirna Urban Co Op Credit Society Ltd", label: "The Pirna Urban Co Op Credit Society Ltd" },
  { value: "Ek Noor Hire Purchase Regd", label: "Ek Noor Hire Purchase Regd" },
  { value: "Hcbl Co Op Bank Ltd", label: "Hcbl Co Op Bank Ltd" },
  { value: "Empower India Capital Investments Pvt Ltd", label: "Empower India Capital Investments Pvt Ltd" },
  { value: "Utham And Co", label: "Utham And Co" },
  { value: "Lalith Sethia Huf", label: "Lalith Sethia Huf" },
  { value: "Gujarat Thakor And Koli Vikas Nigam", label: "Gujarat Thakor And Koli Vikas Nigam" },
  { value: "Shree Parshwananth Finance", label: "Shree Parshwananth Finance" },
  { value: "Shree Shakthi Auto Finance", label: "Shree Shakthi Auto Finance" },
  { value: "Sujal Co Op Credit Soc Ltd", label: "Sujal Co Op Credit Soc Ltd" },
  { value: "Shradha Co Op Cr Soc Ltd", label: "Shradha Co Op Cr Soc Ltd" },
  { value: "Shree Mangalmurti Co Op Cr Soc Ltd", label: "Shree Mangalmurti Co Op Cr Soc Ltd" },
  { value: "Maulikrupa Sahakari Pathpedhi Ltd", label: "Maulikrupa Sahakari Pathpedhi Ltd" },
  { value: "Goregoan Nagari Sahakari Path Pedi", label: "Goregoan Nagari Sahakari Path Pedi" },
  { value: "Shri Kalmeshwar Sahakari Patsanstha Ltd", label: "Shri Kalmeshwar Sahakari Patsanstha Ltd" },
  { value: "Lokseva Sahakari Pathpedi Ltd", label: "Lokseva Sahakari Pathpedi Ltd" },
  { value: "Ramkrishna Hari Sahakari Pathsansta Maryadit", label: "Ramkrishna Hari Sahakari Pathsansta Maryadit" },
  { value: "Sarvajanik Co Op Cr Soc Ltd", label: "Sarvajanik Co Op Cr Soc Ltd" },
  { value: "Harikrupa Co Op Credit Society Ltd", label: "Harikrupa Co Op Credit Society Ltd" },
  { value: "Moreshwar Nagari Sahakari Pathsanstha Ltd", label: "Moreshwar Nagari Sahakari Pathsanstha Ltd" },
  { value: "Dnaneshwar Mauli Sahakari Patapedi Ltd", label: "Dnaneshwar Mauli Sahakari Patapedi Ltd" },
  { value: "Pendarwadi Nagari Sahakari Patasantha Ltd", label: "Pendarwadi Nagari Sahakari Patasantha Ltd" },
  { value: "Shri Gajanan Nagari Sahakari Patsanshta Ltd", label: "Shri Gajanan Nagari Sahakari Patsanshta Ltd" },
  { value: "Mahanand Sahakari Pat Sanstha Ltd", label: "Mahanand Sahakari Pat Sanstha Ltd" },
  { value: "General Finance Corporation", label: "General Finance Corporation" },
  { value: "Buldana Urban Co Op Credit Society Ltd Buldana", label: "Buldana Urban Co Op Credit Society Ltd Buldana" },
  { value: "Idbi Trusteeship Services Limited", label: "Idbi Trusteeship Services Limited" },
  { value: "Saras Finance Company", label: "Saras Finance Company" },
  { value: "The Chandigarh State Co Op Bank Ltd", label: "The Chandigarh State Co Op Bank Ltd" },
  { value: "Loknete Khainhiyalal Pat Sha Marya", label: "Loknete Khainhiyalal Pat Sha Marya" },
  { value: "Uae Xchang Finance", label: "Uae Xchang Finance" },
  { value: "Shri Ragunath Swami Nagari Sahakari Path Sanstha", label: "Shri Ragunath Swami Nagari Sahakari Path Sanstha" },
  { value: "Jay Hari Gramin Bigar Sheti Saha Pathsanstha", label: "Jay Hari Gramin Bigar Sheti Saha Pathsanstha" },
  { value: "Jogeshwari Gramin Bigar Sheti Pathsanstha", label: "Jogeshwari Gramin Bigar Sheti Pathsanstha" },
  { value: "Shree Ram Sahakari Patpedhi Maryadit", label: "Shree Ram Sahakari Patpedhi Maryadit" },
  { value: "Baroda Rajasthan Kshetriya Gramin Bank", label: "Baroda Rajasthan Kshetriya Gramin Bank" },
  { value: "First Leasing Company Of India Ltd", label: "First Leasing Company Of India Ltd" },
  { value: "The Kangra Co Operative Bank Ltd", label: "The Kangra Co Operative Bank Ltd" },
  { value: "Sbicap Trustee Company Limited", label: "Sbicap Trustee Company Limited" },
  { value: "Dulichand Finance And Leasing Ltd", label: "Dulichand Finance And Leasing Ltd" },
  { value: "The Devgad Urban Co Operative Bank", label: "The Devgad Urban Co Operative Bank" },
  { value: "Kamal Chand G", label: "Kamal Chand G" },
  { value: "The Thasra Vepari Mandal Co. Op. Credit Soc. Ltd.", label: "The Thasra Vepari Mandal Co. Op. Credit Soc. Ltd." },
  { value: "B.Mangesh Kumar Kothari", label: "B.Mangesh Kumar Kothari" },
  { value: "Sai Nidhi Co Op Credit Soc Ltd", label: "Sai Nidhi Co Op Credit Soc Ltd" },
  { value: "B H R Multistate Co Credit Soc Ltd", label: "B H R Multistate Co Credit Soc Ltd" },
  { value: "The Talikulam Service Co Operative Bank Ltd.", label: "The Talikulam Service Co Operative Bank Ltd." },
  { value: "Shriram Housing Finance Ltd.", label: "Shriram Housing Finance Ltd." },
  { value: "Shree Nakoda Finance", label: "Shree Nakoda Finance" },
  { value: "Central Services Co Operative Society Ltd", label: "Central Services Co Operative Society Ltd" },
  { value: "Irinjalakuda Welfare Society Ltd", label: "Irinjalakuda Welfare Society Ltd" },
  { value: "The Bk Vibhag Road Transport Credit Co Op Society Ltd.", label: "The Bk Vibhag Road Transport Credit Co Op Society Ltd." },
  { value: "Irinjalakuda Social Welfare Society Ltd", label: "Irinjalakuda Social Welfare Society Ltd" },
  { value: "Samrudha Jivan Nagari Sahakari Pat San Maryadit", label: "Samrudha Jivan Nagari Sahakari Pat San Maryadit" },
  { value: "Export Import Bank Of The United State", label: "Export Import Bank Of The United State" },
  { value: "Shalivahan Nagari Saha Path San Maryadit", label: "Shalivahan Nagari Saha Path San Maryadit" },
  { value: "Dalal Securities And Investment P Ltd", label: "Dalal Securities And Investment P Ltd" },
  { value: "Sanmitra Gramin Bigarsheti Sah Pathsanstha Mary.", label: "Sanmitra Gramin Bigarsheti Sah Pathsanstha Mary." },
  { value: "Vidarbha Konhan Gramin Bank", label: "Vidarbha Konhan Gramin Bank" },
  { value: "Ugt Vibhag Vivith Karyalakari Sahakari Mandli Ltd", label: "Ugt Vibhag Vivith Karyalakari Sahakari Mandli Ltd" },
  { value: "Navhind Multi Purpose Co Op Soc Ltd", label: "Navhind Multi Purpose Co Op Soc Ltd" },
  { value: "Rajarshi Shahu Gbss P S Mydt", label: "Rajarshi Shahu Gbss P S Mydt" },
  { value: "Shri Shivajiraje Vyapari Ns Pat Sanstha", label: "Shri Shivajiraje Vyapari Ns Pat Sanstha" },
  { value: "Malkapur Urban Co Op Credit Society Ltd", label: "Malkapur Urban Co Op Credit Society Ltd" },
  { value: "Shri Datta Gbss Ps Mydt", label: "Shri Datta Gbss Ps Mydt" },
  { value: "Kalpdrum Gbbs Ps Mydt", label: "Kalpdrum Gbbs Ps Mydt" },
  { value: "Shri Pp Ba Kr Mahadev Ma Gbss Ps Mydt", label: "Shri Pp Ba Kr Mahadev Ma Gbss Ps Mydt" },
  { value: "Shri Gurubasav Mp Co Op Soc Ltd", label: "Shri Gurubasav Mp Co Op Soc Ltd" },
  { value: "Shri Gurubasav Mp Co P Soc Ltd", label: "Shri Gurubasav Mp Co P Soc Ltd" },
  { value: "Shri Siddeshwar Multi Purpos Sou Sah Nymt", label: "Shri Siddeshwar Multi Purpos Sou Sah Nymt" },
  { value: "Shri Chourashidevi Mul Pur Sou Sah Nymt", label: "Shri Chourashidevi Mul Pur Sou Sah Nymt" },
  { value: "Vijay Laxmi Multipurpose Co Op Soc Ltd", label: "Vijay Laxmi Multipurpose Co Op Soc Ltd" },
  { value: "Navhind Co Op Credit Soc Ltd", label: "Navhind Co Op Credit Soc Ltd" },
  { value: "Maharashtra Nagari Sah Pat Sanstha Mydt", label: "Maharashtra Nagari Sah Pat Sanstha Mydt" },
  { value: "Sharad Nsps Mydt", label: "Sharad Nsps Mydt" },
  { value: "Shree Gayatri Urban Cr Sou Sah Nymt", label: "Shree Gayatri Urban Cr Sou Sah Nymt" },
  { value: "Shri Jodmahalaxmi Vividh Sou Sah Nymt", label: "Shri Jodmahalaxmi Vividh Sou Sah Nymt" },
  { value: "Shri Jyotirling G B S S.P S Mydt", label: "Shri Jyotirling G B S S.P S Mydt" },
  { value: "Shivner Maratha Sah Pat Ltd", label: "Shivner Maratha Sah Pat Ltd" },
  { value: "Kubadthal Group Seva Sahakari Mandali Ltd", label: "Kubadthal Group Seva Sahakari Mandali Ltd" },
  { value: "Hingraj Credit Co Op Grahak Mandali", label: "Hingraj Credit Co Op Grahak Mandali" },
  { value: "Ceeten Motor Finance Pvt Ltd", label: "Ceeten Motor Finance Pvt Ltd" },
  { value: "Ahiliyadevi Urban Co Op Bank Ltd", label: "Ahiliyadevi Urban Co Op Bank Ltd" },
  { value: "Maina Investments", label: "Maina Investments" },
  { value: "Sangram Nagari Sahakari Patsanstha Maryadit", label: "Sangram Nagari Sahakari Patsanstha Maryadit" },
  { value: "Mahaveer Leafin Corporation", label: "Mahaveer Leafin Corporation" },
  { value: "Power Grid Corporation Of India Limited", label: "Power Grid Corporation Of India Limited" },
  { value: "Harirampur Skus Ltd", label: "Harirampur Skus Ltd" },
  { value: "Punya Ngari Sah Path Marya", label: "Punya Ngari Sah Path Marya" },
  { value: "Janta Sahakari Bank Ltd Gondia", label: "Janta Sahakari Bank Ltd Gondia" },
  { value: "John Deere Financial India Pvt Ltd", label: "John Deere Financial India Pvt Ltd" },
  { value: "Asha Vaibhav Co Op Cr Soc Ltd", label: "Asha Vaibhav Co Op Cr Soc Ltd" },
  { value: "Vishwamurti Co Op Cr Soc Ltd", label: "Vishwamurti Co Op Cr Soc Ltd" },
  { value: "Dnyandeep Co Op Bank Ltd.", label: "Dnyandeep Co Op Bank Ltd." },
  { value: "Sri Sharanabasaveshwar Pattana Sahakar Bank Ni", label: "Sri Sharanabasaveshwar Pattana Sahakar Bank Ni" },
  { value: "Gujarat Scheduled Caste Development Corporation", label: "Gujarat Scheduled Caste Development Corporation" },
  { value: "India Bulls Infrastructure Credit Ltd", label: "India Bulls Infrastructure Credit Ltd" },
  { value: "Bansal Credits Limited", label: "Bansal Credits Limited" },
  { value: "Surya Enterprises Pvt Ltd", label: "Surya Enterprises Pvt Ltd" },
  { value: "Sudershan Motor Invt Co", label: "Sudershan Motor Invt Co" },
  { value: "Haritha Syndicate", label: "Haritha Syndicate" },
  { value: "Sankalp Co Operative Credit Society Ltd", label: "Sankalp Co Operative Credit Society Ltd" },
  { value: "Ceejay Finance Limited", label: "Ceejay Finance Limited" },
  { value: "Future Financial Services", label: "Future Financial Services" },
  { value: "Chintamanrao Mormare Gramin Bigarsheti Sahakari", label: "Chintamanrao Mormare Gramin Bigarsheti Sahakari" },
  { value: "Harmeet Motors", label: "Harmeet Motors" },
  { value: "Kothari Budhmal Padamchand And Co", label: "Kothari Budhmal Padamchand And Co" },
  { value: "Mahalxmi Nagari Sahakari Patsanstha Marya.", label: "Mahalxmi Nagari Sahakari Patsanstha Marya." },
  { value: "Adinath Auto Advizer", label: "Adinath Auto Advizer" },
  { value: "Future Financial Servicess", label: "Future Financial Servicess" },
  { value: "The Diamond Jubilee Co P Credit Society Ltd", label: "The Diamond Jubilee Co P Credit Society Ltd" },
  { value: "Dfs India Pvt Ltd", label: "Dfs India Pvt Ltd" },
  { value: "Mahalaxmi Nagari Sahakari Patsanstha Sahakari Maryadit Jalna", label: "Mahalaxmi Nagari Sahakari Patsanstha Sahakari Maryadit Jalna" },
  { value: "Andarsul Urban Co Op Bank Ltd", label: "Andarsul Urban Co Op Bank Ltd" },
  { value: "Chola Mandalam Finance Co Ltd", label: "Chola Mandalam Finance Co Ltd" },
  { value: "Naresh Kumar Huf", label: "Naresh Kumar Huf" },
  { value: "Vijay Enterprises", label: "Vijay Enterprises" },
  { value: "Dsp Emp. Co Op Society", label: "Dsp Emp. Co Op Society" },
  { value: "Landesbank", label: "Landesbank" },
  { value: "Dilip Gramin Biger Sheti Sahakari Pat San Maryadit", label: "Dilip Gramin Biger Sheti Sahakari Pat San Maryadit" },
  { value: "Finvent Finance And Investments Ltd", label: "Finvent Finance And Investments Ltd" },
  { value: "Yashwantrao Nagari Sahakari Pathpedi Maryadit", label: "Yashwantrao Nagari Sahakari Pathpedi Maryadit" },
  { value: "Premnath Ramesh Kumar", label: "Premnath Ramesh Kumar" },
  { value: "Power System Operation Corporation Ltd", label: "Power System Operation Corporation Ltd" },
  { value: "Shri Santaji Nag Sah Pat Mary", label: "Shri Santaji Nag Sah Pat Mary" },
  { value: "Elamakkara Samuhya Kshema Co Operative Society Ltd", label: "Elamakkara Samuhya Kshema Co Operative Society Ltd" },
  { value: "Sharadchandra Sahakari Patsansta Maryadit", label: "Sharadchandra Sahakari Patsansta Maryadit" },
  { value: "Venkata Bhavani Hire Purchase Finance", label: "Venkata Bhavani Hire Purchase Finance" },
  { value: "Saptashrungi Mata Mahila Gra Big Sah Pathsanstha Maryadit", label: "Saptashrungi Mata Mahila Gra Big Sah Pathsanstha Maryadit" },
  { value: "Sant Sena Sah Pat Sanstha Mar", label: "Sant Sena Sah Pat Sanstha Mar" },
  { value: "Janatha Pattina Souhardha Sahakari Niyamita", label: "Janatha Pattina Souhardha Sahakari Niyamita" },
  { value: "Vidarbha Kokan Gramin Bank", label: "Vidarbha Kokan Gramin Bank" },
  { value: "Shri Gajanan Maharaj Nag Sah Pat Mary", label: "Shri Gajanan Maharaj Nag Sah Pat Mary" },
  { value: "Kakasaheb Thorat Gramin Bigar Sheti Sah Pat San Maryadit", label: "Kakasaheb Thorat Gramin Bigar Sheti Sah Pat San Maryadit" },
  { value: "Brydal Kuries P Ltd", label: "Brydal Kuries P Ltd" },
  { value: "The Jain Nagari Sahkari Pat Sanstha M", label: "The Jain Nagari Sahkari Pat Sanstha M" },
  { value: "Ankita S Mehta", label: "Ankita S Mehta" },
  { value: "Amar Finance Co", label: "Amar Finance Co" },
  { value: "Brahmani Leasing Finance", label: "Brahmani Leasing Finance" },
  { value: "Goreshwar Gramin Multi State Co Operative Credit Society Ltd", label: "Goreshwar Gramin Multi State Co Operative Credit Society Ltd" },
  { value: "Parashar Krushi Gramin Biger Sheti Sah Pathsanstha Maryadit", label: "Parashar Krushi Gramin Biger Sheti Sah Pathsanstha Maryadit" },
  { value: "Parner Gramin Bigarsheti Sah Pathsanstha Maryadit.", label: "Parner Gramin Bigarsheti Sah Pathsanstha Maryadit." },
  { value: "Shivrai Gramin B S Sah Pathsantha Maryadit Parnet", label: "Shivrai Gramin B S Sah Pathsantha Maryadit Parnet" },
  { value: "M A Co Babasaheb Thube Gramin Bigarsheti Sah. Pathsanstha Maryadit0 Panoli", label: "M A Co Babasaheb Thube Gramin Bigarsheti Sah. Pathsanstha Maryadit0 Panoli" },
  { value: "Bhairavnath Gramin Bigarsheti Sah Pathsanstha Maryadit", label: "Bhairavnath Gramin Bigarsheti Sah Pathsanstha Maryadit" },
  { value: "Jankalyan Gramin Bigar Sheti Sah Pathsanstha Maryadit", label: "Jankalyan Gramin Bigar Sheti Sah Pathsanstha Maryadit" },
  { value: "Aadhar Gramin Bigarsheti Sah Pathasanstha Maryadit", label: "Aadhar Gramin Bigarsheti Sah Pathasanstha Maryadit" },
  { value: "Ramling Gramin Bigersheti Sahkari Patsantha Maryadit", label: "Ramling Gramin Bigersheti Sahkari Patsantha Maryadit" },
  { value: "Rajmata Jijau Mahila Gramin Bigarsheti Sahkari Pathsanstha Maryadit", label: "Rajmata Jijau Mahila Gramin Bigarsheti Sahkari Pathsanstha Maryadit" },
  { value: "Kisan Union Gramin Bigarsheti Sah Pathsanstha Maryadit", label: "Kisan Union Gramin Bigarsheti Sah Pathsanstha Maryadit" },
  { value: "Karandi Rural Non Agri Co Op Credit So Ltd", label: "Karandi Rural Non Agri Co Op Credit So Ltd" },
  { value: "Shri Dattakrupa Gramin Bigersheti Sahakari Pathsanstha Maryadit", label: "Shri Dattakrupa Gramin Bigersheti Sahakari Pathsanstha Maryadit" },
  { value: "Juinagar Janata Co Op Credit Society Lytd", label: "Juinagar Janata Co Op Credit Society Lytd" },
  { value: "The Eastern Railway Employees Co Operative Bank Ltd", label: "The Eastern Railway Employees Co Operative Bank Ltd" },
  { value: "Bhaveshwari Sahakari Pathsantha Mayardit Mumbai", label: "Bhaveshwari Sahakari Pathsantha Mayardit Mumbai" },
  { value: "Sugat Sahakari Pathsantha Mayardit", label: "Sugat Sahakari Pathsantha Mayardit" },
  { value: "Navsandesh Sahakari Pathsantha Mayardit Mumbai", label: "Navsandesh Sahakari Pathsantha Mayardit Mumbai" },
  { value: "Marathwada Sahakari Pathsantha Mayardit Mumbai", label: "Marathwada Sahakari Pathsantha Mayardit Mumbai" },
  { value: "Shri Krushna Gramin Big She Sahakari P", label: "Shri Krushna Gramin Big She Sahakari P" },
  { value: "Aryavart Kshetriya Gramin Bank", label: "Aryavart Kshetriya Gramin Bank" },
  { value: "Chordia Capital Market Ltd Indore", label: "Chordia Capital Market Ltd Indore" },
  { value: "Shree Aditya Co Op Credit Soc Ltd", label: "Shree Aditya Co Op Credit Soc Ltd" },
  { value: "Ms Kamal Kunj Finance", label: "Ms Kamal Kunj Finance" },
  { value: "Ms Ess Kay Auto Finance Pvt Ltd", label: "Ms Ess Kay Auto Finance Pvt Ltd" },
  { value: "Woori Bank", label: "Woori Bank" },
  { value: "Shri Arihant Merchants Privatelimited", label: "Shri Arihant Merchants Privatelimited" },
  { value: "The Tamilnadu State Apex Co Operative Bank Ltd", label: "The Tamilnadu State Apex Co Operative Bank Ltd" },
  { value: "Shri Narsingh Gramin Biger Sheti Sahakari Pat San", label: "Shri Narsingh Gramin Biger Sheti Sahakari Pat San" },
  { value: "Hero Fincorp Ltd", label: "Hero Fincorp Ltd" },
  { value: "Nashik Road Nagri Sah Path San May", label: "Nashik Road Nagri Sah Path San May" },
  { value: "Shivam Finance", label: "Shivam Finance" },
  { value: "Tulsi Finance", label: "Tulsi Finance" },
  { value: "Choundeshwari Nagari Sahakari Pat Sanstha", label: "Choundeshwari Nagari Sahakari Pat Sanstha" },
  { value: "The Mantha Urban Co Op Bank Ltd Mantha", label: "The Mantha Urban Co Op Bank Ltd Mantha" },
  { value: "Uma Maheshwara Auto Finance", label: "Uma Maheshwara Auto Finance" },
  { value: "Adhi Laxmi Auto Finance", label: "Adhi Laxmi Auto Finance" },
  { value: "Navya Sai Auto Finance", label: "Navya Sai Auto Finance" },
  { value: "Sri Laxmi Srinivasa Auto Finance", label: "Sri Laxmi Srinivasa Auto Finance" },
  { value: "Sai Balaji Employee And Auto Finance", label: "Sai Balaji Employee And Auto Finance" },
  { value: "Savitribai Bhagoji Nimde Kunabi Gramin Bigarsheti Sah Patsanstha Maryadit", label: "Savitribai Bhagoji Nimde Kunabi Gramin Bigarsheti Sah Patsanstha Maryadit" },
  { value: "Lodra Nagrik Sahakari Bank Ltd", label: "Lodra Nagrik Sahakari Bank Ltd" },
  { value: "Aadhar Gramin Bigarsheti Sah Patsanstha Marya", label: "Aadhar Gramin Bigarsheti Sah Patsanstha Marya" },
  { value: "R And T Motors", label: "R And T Motors" },
  { value: "The Maharashtra State Co Op Bank Ltd.", label: "The Maharashtra State Co Op Bank Ltd." },
  { value: "Vendere Sales Services Pvt Ltd", label: "Vendere Sales Services Pvt Ltd" },
  { value: "Manku Agro Tech Pvt Ltd", label: "Manku Agro Tech Pvt Ltd" },
  { value: "Kailash Chand Choraria", label: "Kailash Chand Choraria" },
  { value: "Alfaa Credit", label: "Alfaa Credit" },
  { value: "Uti Asset Management Co Ltd", label: "Uti Asset Management Co Ltd" },
  { value: "R And T Motors", label: "R And T Motors" },
  { value: "Waris Finc And Investment Ltd", label: "Waris Finc And Investment Ltd" },
  { value: "Bhw Home Finance Ltd", label: "Bhw Home Finance Ltd" },
  { value: "Lilavati", label: "Lilavati" },
  { value: "Shri Guruprasad Nagari Shah Pat Ltd", label: "Shri Guruprasad Nagari Shah Pat Ltd" },
  { value: "Suvida Finance", label: "Suvida Finance" },
  { value: "Indiabulls Infrastructure Credit Ltd", label: "Indiabulls Infrastructure Credit Ltd" },
  { value: "P Satish Chand Sisodiya", label: "P Satish Chand Sisodiya" },
  { value: "Disha Financiers", label: "Disha Financiers" },
  { value: "Adhishwar Autombile Finance", label: "Adhishwar Autombile Finance" },
  { value: "Swami Vivekanand Nagari Sahkari Patsanshta Marya, Jalna", label: "Swami Vivekanand Nagari Sahkari Patsanshta Marya, Jalna" },
  { value: "The Radhanpur Nagrik Sahkari Mandali Ltd", label: "The Radhanpur Nagrik Sahkari Mandali Ltd" },
  { value: "Om Finance Services", label: "Om Finance Services" },
  { value: "Habib Bank Ag Zurich", label: "Habib Bank Ag Zurich" },
  { value: "Dr Daulatrao Sonuji Aher G B Sheti Saha Sanstha", label: "Dr Daulatrao Sonuji Aher G B Sheti Saha Sanstha" },
  { value: "Loknete Kanhaiyalalji Nahar Nagari Saha Path Nah", label: "Loknete Kanhaiyalalji Nahar Nagari Saha Path Nah" },
  { value: "Barkha Associates", label: "Barkha Associates" },
  { value: "Kushal Automobile Finance", label: "Kushal Automobile Finance" },
  { value: "Dhruv Financial Services", label: "Dhruv Financial Services" },
  { value: "Sri Kethaki Sangameshwara Auto Finance", label: "Sri Kethaki Sangameshwara Auto Finance" },
  { value: "Kar Finance Co", label: "Kar Finance Co" },
  { value: "Saptashrungi Coperative Credit Society Ltd", label: "Saptashrungi Coperative Credit Society Ltd" },
  { value: "Il And Fs Trust Co.Ltd", label: "Il And Fs Trust Co.Ltd" },
  { value: "Bhairavnath Dhudh Udpada G.B. She Path.", label: "Bhairavnath Dhudh Udpada G.B. She Path." },
  { value: "Vaibhavsai Finance", label: "Vaibhavsai Finance" },
  { value: "Namdeo Kadam Gramin Bigarsheti Sahakari Pat Sanstha", label: "Namdeo Kadam Gramin Bigarsheti Sahakari Pat Sanstha" },
  { value: "Daimler Financial Services India Pvt Ltd", label: "Daimler Financial Services India Pvt Ltd" },
  { value: "Indra Co Op Credit Society Ltd", label: "Indra Co Op Credit Society Ltd" },
  { value: "B G Shirke Gramin Biger Sheti Sah Pat San Maryadit", label: "B G Shirke Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Shri Trimurti Gramin Bigar Sheti Sah Pat San Maryadit", label: "Shri Trimurti Gramin Bigar Sheti Sah Pat San Maryadit" },
  { value: "Dantaleshwar Gramin Bigar Sheti Sah Pat Sanstha Marya", label: "Dantaleshwar Gramin Bigar Sheti Sah Pat Sanstha Marya" },
  { value: "Hero Financial Corporation", label: "Hero Financial Corporation" },
  { value: "Sharadchandra G.B.S.Sah.Path.Sanstha", label: "Sharadchandra G.B.S.Sah.Path.Sanstha" },
  { value: "Fortune Credit Capital Ltd", label: "Fortune Credit Capital Ltd" },
  { value: "Pandit Nehru Bhajipala Vyapari Nagari Sah Pat San", label: "Pandit Nehru Bhajipala Vyapari Nagari Sah Pat San" },
  { value: "Sevagiri Gra Big Sheti Sah Path San", label: "Sevagiri Gra Big Sheti Sah Path San" },
  { value: "The Dhanera Mercantile Co Operative Bank Ltd", label: "The Dhanera Mercantile Co Operative Bank Ltd" },
  { value: "Hero Fincorp Limited", label: "Hero Fincorp Limited" },
  { value: "Ajmani Investment India Pvt Ltd", label: "Ajmani Investment India Pvt Ltd" },
  { value: "Sri Ashapura Hire Purchase And Investment", label: "Sri Ashapura Hire Purchase And Investment" },
  { value: "Kai Devenra Nag Sah Pat San Ltd", label: "Kai Devenra Nag Sah Pat San Ltd" },
  { value: "Chorpa Brothers Finance Corporation", label: "Chorpa Brothers Finance Corporation" },
  { value: "Nakoda Finance Corporation", label: "Nakoda Finance Corporation" },
  { value: "Vasantdada Patil Kakade Gramin Big Sah Patsanstha", label: "Vasantdada Patil Kakade Gramin Big Sah Patsanstha" },
  { value: "Chakshu Finance", label: "Chakshu Finance" },
  { value: "Vrudheshwar Urban Multi State Co Op Society Ltd", label: "Vrudheshwar Urban Multi State Co Op Society Ltd" },
  { value: "The Yashwant Co Operative Bank Ltd, Phaltan", label: "The Yashwant Co Operative Bank Ltd, Phaltan" },
  { value: "Rakhecha Financiers", label: "Rakhecha Financiers" },
  { value: "Maa Ashapura Financiers", label: "Maa Ashapura Financiers" },
  { value: "Dadu Corporation", label: "Dadu Corporation" },
  { value: "Ratna Shika Finance", label: "Ratna Shika Finance" },
  { value: "Bangalore, Bangalore Rural Ramanagara Dist Co Op Central Bank Ltd", label: "Bangalore, Bangalore Rural Ramanagara Dist Co Op Central Bank Ltd" },
  { value: "Star Health And Allied Insurance Co Ltd", label: "Star Health And Allied Insurance Co Ltd" },
  { value: "Shrikrushna Nagari Sahakari Pat Sanstha Maryadit", label: "Shrikrushna Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Agro India Finance Pvt Ltd", label: "Agro India Finance Pvt Ltd" },
  { value: "Berar Finance Ltd", label: "Berar Finance Ltd" },
  { value: "Shree Yashwant Vivid Karyakari Seva Sah Soc Ltd", label: "Shree Yashwant Vivid Karyakari Seva Sah Soc Ltd" },
  { value: "Surya Finlease Pvt Ltd", label: "Surya Finlease Pvt Ltd" },
  { value: "Aryan Enterprise", label: "Aryan Enterprise" },
  { value: "Integral Urban Co Operative Bank", label: "Integral Urban Co Operative Bank" },
  { value: "B S S Trading Co.", label: "B S S Trading Co." },
  { value: "Laxmi Ganapathi Finance", label: "Laxmi Ganapathi Finance" },
  { value: "Saras Finance", label: "Saras Finance" },
  { value: "Janki Securities Ltd", label: "Janki Securities Ltd" },
  { value: "New Bharath Financial Service", label: "New Bharath Financial Service" },
  { value: "Shree Bhadrakali Group Seva Sahkari Mandli Ltd", label: "Shree Bhadrakali Group Seva Sahkari Mandli Ltd" },
  { value: "Arihant Auto", label: "Arihant Auto" },
  { value: "Sansar Vividh Karyakari Sahakari Society Ltd", label: "Sansar Vividh Karyakari Sahakari Society Ltd" },
  { value: "Dattatray Maharaj Kalambe Jawali Sahakari Bank Ltd", label: "Dattatray Maharaj Kalambe Jawali Sahakari Bank Ltd" },
  { value: "Home Credit India Finance Pvt Ltd", label: "Home Credit India Finance Pvt Ltd" },
  { value: "Youva Utkarsh Co Op Credit Soc Ltd", label: "Youva Utkarsh Co Op Credit Soc Ltd" },
  { value: "Raja Rajeshwari Hire Purchase And Finance", label: "Raja Rajeshwari Hire Purchase And Finance" },
  { value: "Raja Rajeshwari Hire Purchase And Finance", label: "Raja Rajeshwari Hire Purchase And Finance" },
  { value: "Jagrut Yuvak Co Op Credit Soc Ltd", label: "Jagrut Yuvak Co Op Credit Soc Ltd" },
  { value: "C G Enterprises", label: "C G Enterprises" },
  { value: "Shri Thorle Bajirao Peshve Nag Sah Pat Mary", label: "Shri Thorle Bajirao Peshve Nag Sah Pat Mary" },
  { value: "Jeemy Auto Finance", label: "Jeemy Auto Finance" },
  { value: "Samridhi General Finencial Services", label: "Samridhi General Finencial Services" },
  { value: "Nashik Zilha Sarkari And Parisad Karmachari Sahakari Bank Niyamit", label: "Nashik Zilha Sarkari And Parisad Karmachari Sahakari Bank Niyamit" },
  { value: "Dinesh Gramin Bigar Sheti Sahakari Pat Sanstha Mar", label: "Dinesh Gramin Bigar Sheti Sahakari Pat Sanstha Mar" },
  { value: "Khandala Tal Shri Harale Vaishnav Gramin Bigar Sheti", label: "Khandala Tal Shri Harale Vaishnav Gramin Bigar Sheti" },
  { value: "Dholka Dakshin Vibhag Urban Credit Sahakari Mandali Ltd", label: "Dholka Dakshin Vibhag Urban Credit Sahakari Mandali Ltd" },
  { value: "Fluent India Pvt Ltd", label: "Fluent India Pvt Ltd" },
  { value: "The H P State Co Op Bank Ltd", label: "The H P State Co Op Bank Ltd" },
  { value: "Sri Manjunatheswara Credit Co Op Society Ltd", label: "Sri Manjunatheswara Credit Co Op Society Ltd" },
  { value: "Manjunatha Credit Co Op Society Ltd", label: "Manjunatha Credit Co Op Society Ltd" },
  { value: "The Golden Jubilee Co Operative Credit Society Ltd", label: "The Golden Jubilee Co Operative Credit Society Ltd" },
  { value: "Sri Vishwakarma Urban Credit Society Sahakari Niyamit", label: "Sri Vishwakarma Urban Credit Society Sahakari Niyamit" },
  { value: "Shri Jadi Siddeshwar Co Op Credit Society Ltd", label: "Shri Jadi Siddeshwar Co Op Credit Society Ltd" },
  { value: "The Pachhapur Urban Co Op Bank Ltd", label: "The Pachhapur Urban Co Op Bank Ltd" },
  { value: "Shri Basaveshwar Multi Purpose Co Op Society Ltd", label: "Shri Basaveshwar Multi Purpose Co Op Society Ltd" },
  { value: "Shri Gayatri Urban Credit Souhard Shahakari Niyamit", label: "Shri Gayatri Urban Credit Souhard Shahakari Niyamit" },
  { value: "Kuruhinshetti Urban Co Op Credit Society Ltd", label: "Kuruhinshetti Urban Co Op Credit Society Ltd" },
  { value: "Chaitanya Co Op Credit Society Ltd", label: "Chaitanya Co Op Credit Society Ltd" },
  { value: "Shri Basaveshwar Urban Co Op Credit Society Ltd", label: "Shri Basaveshwar Urban Co Op Credit Society Ltd" },
  { value: "Shri Sai Urban Co Op Credit Society Ltd", label: "Shri Sai Urban Co Op Credit Society Ltd" },
  { value: "Shri Kalmeshwar Co Op Credit Society Ltd", label: "Shri Kalmeshwar Co Op Credit Society Ltd" },
  { value: "The Omkar Co Op Credit Society Ltd", label: "The Omkar Co Op Credit Society Ltd" },
  { value: "Maratha Urban Co Op Credit Society Ltd", label: "Maratha Urban Co Op Credit Society Ltd" },
  { value: "Shri Vigneshwar Vivid Uddeshgala Sou Sah Niyamit", label: "Shri Vigneshwar Vivid Uddeshgala Sou Sah Niyamit" },
  { value: "Rajarshee Shahu Co Op Credit Society Ltd", label: "Rajarshee Shahu Co Op Credit Society Ltd" },
  { value: "Shri Yadnavalka Nagari Sahakari Patsanstha Marya", label: "Shri Yadnavalka Nagari Sahakari Patsanstha Marya" },
  { value: "Jadeyashankarlingeshwar Credit Co Op Ltd.", label: "Jadeyashankarlingeshwar Credit Co Op Ltd." },
  { value: "Shri Shyam Auto Finance", label: "Shri Shyam Auto Finance" },
  { value: "The Kottanad Service Co Op Bank Ltd", label: "The Kottanad Service Co Op Bank Ltd" },
  { value: "Kerala Gramin Bank", label: "Kerala Gramin Bank" },
  { value: "Ask Enterprises", label: "Ask Enterprises" },
  { value: "Romsy Enterprises", label: "Romsy Enterprises" },
  { value: "Swarn Jyoti Investment P Ltd", label: "Swarn Jyoti Investment P Ltd" },
  { value: "Amar Finlease Pvt Ltd", label: "Amar Finlease Pvt Ltd" },
  { value: "Sardar Vallabh Bhai Patel Gramin Sahakari Patsanstha Maryadit", label: "Sardar Vallabh Bhai Patel Gramin Sahakari Patsanstha Maryadit" },
  { value: "Chandram Urban Co Op Credit Society Ltd", label: "Chandram Urban Co Op Credit Society Ltd" },
  { value: "Omshanti Nagari Sahakari Patsanstha Maryadit", label: "Omshanti Nagari Sahakari Patsanstha Maryadit" },
  { value: "Rajdeep Auto Finance", label: "Rajdeep Auto Finance" },
  { value: "Shri Mahalaxmi Multi Purpose Co Op Society Ltd", label: "Shri Mahalaxmi Multi Purpose Co Op Society Ltd" },
  { value: "Sharadchandra Nagari Sahakari Patsanstha Maryadit", label: "Sharadchandra Nagari Sahakari Patsanstha Maryadit" },
  { value: "Shri Gajanan Lokseva Sahakari Bank Maryadit", label: "Shri Gajanan Lokseva Sahakari Bank Maryadit" },
  { value: "Alnavar Urban Co Op Bank Ltd", label: "Alnavar Urban Co Op Bank Ltd" },
  { value: "Shree Bhutanath Co Op Credit Society Ltd", label: "Shree Bhutanath Co Op Credit Society Ltd" },
  { value: "Shri Shivabodharang Urban Co Op Credit Society Ltd", label: "Shri Shivabodharang Urban Co Op Credit Society Ltd" },
  { value: "Dhanalaxmi Multi Pupose Souharad Society Ltd", label: "Dhanalaxmi Multi Pupose Souharad Society Ltd" },
  { value: "Gavalinagar Nagari Sah Patsansta Maryadit", label: "Gavalinagar Nagari Sah Patsansta Maryadit" },
  { value: "Saxena Auto Sales And Finance", label: "Saxena Auto Sales And Finance" },
  { value: "Divine Grace Green Farming And Marketing Multi State Co Op Society Ltd", label: "Divine Grace Green Farming And Marketing Multi State Co Op Society Ltd" },
  { value: "Bohra Finance", label: "Bohra Finance" },
  { value: "Shree Krishna Finance", label: "Shree Krishna Finance" },
  { value: "Manfgaldeep Finance", label: "Manfgaldeep Finance" },
  { value: "The Katlary Kariyana Mar Sahkari Sarafi Mandali Ltd", label: "The Katlary Kariyana Mar Sahkari Sarafi Mandali Ltd" },
  { value: "The Amlehar Jadeed Co Op Agri Service Society", label: "The Amlehar Jadeed Co Op Agri Service Society" },
  { value: "The Citizens Cooperative Bank Ltd", label: "The Citizens Cooperative Bank Ltd" },
  { value: "Ananda Bhagya Lakshmi Auto And General Finance", label: "Ananda Bhagya Lakshmi Auto And General Finance" },
  { value: "Ahmednagar Mahanagar Palika Karmachari Patsanstha Maryadit", label: "Ahmednagar Mahanagar Palika Karmachari Patsanstha Maryadit" },
  { value: "Shrijeet Finance", label: "Shrijeet Finance" },
  { value: "Khairtabad Vikram Finance", label: "Khairtabad Vikram Finance" },
  { value: "Jain General Finance Company", label: "Jain General Finance Company" },
  { value: "Shree Dhanalaxmi Vividoddeshagal Souhard Sahakari Niyamit", label: "Shree Dhanalaxmi Vividoddeshagal Souhard Sahakari Niyamit" },
  { value: "The Patil Multipurpose Souharda Sahakari Ltd", label: "The Patil Multipurpose Souharda Sahakari Ltd" },
  { value: "Shri Jodamahalaxmi Vividhoddeshagala Souhardh Sahakari Niyamit", label: "Shri Jodamahalaxmi Vividhoddeshagala Souhardh Sahakari Niyamit" },
  { value: "Kumbarara Vividodeshgala Souhard Sahakari Niyamit", label: "Kumbarara Vividodeshgala Souhard Sahakari Niyamit" },
  { value: "Yamakanamardi Balakrishna Souhard Pattin Sahakari Niyamit", label: "Yamakanamardi Balakrishna Souhard Pattin Sahakari Niyamit" },
  { value: "Pankaj Motors", label: "Pankaj Motors" },
  { value: "Jai Hanuman Multipurpose Souhardh Sahakari Niyamit", label: "Jai Hanuman Multipurpose Souhardh Sahakari Niyamit" },
  { value: "Shree Pisedev Co Operative Credit Society Ltd", label: "Shree Pisedev Co Operative Credit Society Ltd" },
  { value: "The Alnavar Urban Co Op Bank Ltd", label: "The Alnavar Urban Co Op Bank Ltd" },
  { value: "Rajkot Commerciat Co Operative Bank Ltd", label: "Rajkot Commerciat Co Operative Bank Ltd" },
  { value: "Navakarnataka Souharda Credit Co Operative Ltd", label: "Navakarnataka Souharda Credit Co Operative Ltd" },
  { value: "Ghatandevi Nagri Sahakari Patsanstha Maryadit", label: "Ghatandevi Nagri Sahakari Patsanstha Maryadit" },
  { value: "Malayala Manorama Co Ltd", label: "Malayala Manorama Co Ltd" },
  { value: "Jai Nakoda Automobile Finance", label: "Jai Nakoda Automobile Finance" },
  { value: "Khiwasara Finance And Leasing Ltd", label: "Khiwasara Finance And Leasing Ltd" },
  { value: "Manav Finance", label: "Manav Finance" },
  { value: "Sri Shanti Financers", label: "Sri Shanti Financers" },
  { value: "Sri Arihant Financiers", label: "Sri Arihant Financiers" },
  { value: "Pragathi Finance", label: "Pragathi Finance" },
  { value: "Sidhi Auto Finance", label: "Sidhi Auto Finance" },
  { value: "Mahananda Mult State Urban Co Op Credit Society Ltd", label: "Mahananda Mult State Urban Co Op Credit Society Ltd" },
  { value: "Unnati Finance", label: "Unnati Finance" },
  { value: "Raj Sons", label: "Raj Sons" },
  { value: "Raj Finance", label: "Raj Finance" },
  { value: "Sugans", label: "Sugans" },
  { value: "Vijayshanti Finance", label: "Vijayshanti Finance" },
  { value: "Sri Tulsi Finance", label: "Sri Tulsi Finance" },
  { value: "Dinesh Finance Corporation", label: "Dinesh Finance Corporation" },
  { value: "Vibha Financers", label: "Vibha Financers" },
  { value: "Padmavathi Automobile Finance", label: "Padmavathi Automobile Finance" },
  { value: "Lonarbaba Gramin Bigar Sheti Sahakari Patsanstha Maryadit", label: "Lonarbaba Gramin Bigar Sheti Sahakari Patsanstha Maryadit" },
  { value: "Bacardi India Pvt Ltd", label: "Bacardi India Pvt Ltd" },
  { value: "Aditya Birla Finance Limited", label: "Aditya Birla Finance Limited" },
  { value: "The Vijay Credit And Supply", label: "The Vijay Credit And Supply" },
  { value: "Shri Kulswami Sahakari Patsanstha Maryadit", label: "Shri Kulswami Sahakari Patsanstha Maryadit" },
  { value: "Rolson Commercial Co", label: "Rolson Commercial Co" },
  { value: "Malanadu Co Operative Agricultural And Rural Development Bank", label: "Malanadu Co Operative Agricultural And Rural Development Bank" },
  { value: "Smita Financeial Ser Pvt Ltd", label: "Smita Financeial Ser Pvt Ltd" },
  { value: "The Khedbrahma Group Seva Sahakari Mandali Ltd", label: "The Khedbrahma Group Seva Sahakari Mandali Ltd" },
  { value: "Gadhinglai Taluka Sah Patpedhi Maryadit", label: "Gadhinglai Taluka Sah Patpedhi Maryadit" },
  { value: "Bamne Janseva Sah Pathpedhi Maryadit", label: "Bamne Janseva Sah Pathpedhi Maryadit" },
  { value: "Mangalmurti Sah Patpedhi Maryadit", label: "Mangalmurti Sah Patpedhi Maryadit" },
  { value: "Shri Kaalbhairav Nagarik Sah Pathpedi Maryadit", label: "Shri Kaalbhairav Nagarik Sah Pathpedi Maryadit" },
  { value: "Om Gorai Nagari Sah Pathsanstha Maryadit", label: "Om Gorai Nagari Sah Pathsanstha Maryadit" },
  { value: "Pyramid Finance Pvt Limited", label: "Pyramid Finance Pvt Limited" },
  { value: "The Hoshiarpur Telecom Emp Co Op Thrift And Credit Society Ltd", label: "The Hoshiarpur Telecom Emp Co Op Thrift And Credit Society Ltd" },
  { value: "Samaj Kalyan Sahakari Patsantha Maryadit", label: "Samaj Kalyan Sahakari Patsantha Maryadit" },
  { value: "Karanje Gramin Bigar Sheti Sahakari Patsanstha Maryadit", label: "Karanje Gramin Bigar Sheti Sahakari Patsanstha Maryadit" },
  { value: "Mohan Motor Distributors Pvt Ltd", label: "Mohan Motor Distributors Pvt Ltd" },
  { value: "Sri Sai Baba Group Of Company", label: "Sri Sai Baba Group Of Company" },
  { value: "Secretary V V S S Ltd", label: "Secretary V V S S Ltd" },
  { value: "C S Honda Finance", label: "C S Honda Finance" },
  { value: "Swastik Automobiles", label: "Swastik Automobiles" },
  { value: "Shri Kamlmeshwar Sah Pat Ltd", label: "Shri Kamlmeshwar Sah Pat Ltd" },
  { value: "Mahendar Automobile Finance", label: "Mahendar Automobile Finance" },
  { value: "Vinayaka Hire Purchase", label: "Vinayaka Hire Purchase" },
  { value: "The Bhoyeeni Group Seva Sahakari Mandali Limited", label: "The Bhoyeeni Group Seva Sahakari Mandali Limited" },
  { value: "The Ukhload Group Seva Sahakari Mandali Limited", label: "The Ukhload Group Seva Sahakari Mandali Limited" },
  { value: "Venkatadri Hire Purchase And Finance", label: "Venkatadri Hire Purchase And Finance" },
  { value: "Poonam Dharewa", label: "Poonam Dharewa" },
  { value: "Shri Ram Money Lenders", label: "Shri Ram Money Lenders" },
  { value: "Bramhadev Coop Credit Society Ltd", label: "Bramhadev Coop Credit Society Ltd" },
  { value: "Secretary To State Legislature", label: "Secretary To State Legislature" },
  { value: "Arihant Nagri Sahkari Patsanstha Maryadit", label: "Arihant Nagri Sahkari Patsanstha Maryadit" },
  { value: "Ekata Retail Kirana Merchant Nagri Sahakari Patsanstha Maryadit, Jalgaon", label: "Ekata Retail Kirana Merchant Nagri Sahakari Patsanstha Maryadit, Jalgaon" },
  { value: "Shri Mallikarjun Multipurpose Souhard Sahakari Limited", label: "Shri Mallikarjun Multipurpose Souhard Sahakari Limited" },
  { value: "Parshvanath Investor", label: "Parshvanath Investor" },
  { value: "Hindauniya Motors", label: "Hindauniya Motors" },
  { value: "Sri Mahalakshmi Financev", label: "Sri Mahalakshmi Financev" },
  { value: "Om Finance", label: "Om Finance" },
  { value: "The Citizen Urban Co Op Bank Ltd", label: "The Citizen Urban Co Op Bank Ltd" },
  { value: "R S B Co Op Credit Society Ltd", label: "R S B Co Op Credit Society Ltd" },
  { value: "Shekh Fareed", label: "Shekh Fareed" },
  { value: "The Mauli Urban Co Op Cr Society Ltd", label: "The Mauli Urban Co Op Cr Society Ltd" },
  { value: "The Kokata Seva Sahkari Mandli Limited Kokata", label: "The Kokata Seva Sahkari Mandli Limited Kokata" },
  { value: "Siddhirajnath Gramin Biger Sheti Sahakari Patsanstha", label: "Siddhirajnath Gramin Biger Sheti Sahakari Patsanstha" },
  { value: "Rajtoran Gramin Biger Sheti Sahakari Patsanstha Maryadit", label: "Rajtoran Gramin Biger Sheti Sahakari Patsanstha Maryadit" },
  { value: "Abasaheb Gramin Biger Sheti Sahakari Patsanstha Maryadit", label: "Abasaheb Gramin Biger Sheti Sahakari Patsanstha Maryadit" },
  { value: "United Finance Company", label: "United Finance Company" },
  { value: "Nagaur Sahakari Bhoomi Vikash Bank", label: "Nagaur Sahakari Bhoomi Vikash Bank" },
  { value: "Jai Jalaram Co Op Credit Soc Ltd", label: "Jai Jalaram Co Op Credit Soc Ltd" },
  { value: "Sri Raja Ganapathi Finance", label: "Sri Raja Ganapathi Finance" },
  { value: "Sree Padmanithi Auto Finance", label: "Sree Padmanithi Auto Finance" },
  { value: "Aadhitya Finance", label: "Aadhitya Finance" },
  { value: "Akshaya Finance", label: "Akshaya Finance" },
  { value: "P M Chordiya", label: "P M Chordiya" },
  { value: "The Janhit Thrif And Credit Co Op Soc Ltd", label: "The Janhit Thrif And Credit Co Op Soc Ltd" },
  { value: "Mulamoottil Consumer Credits Ltd", label: "Mulamoottil Consumer Credits Ltd" },
  { value: "The Ksfe Ltd", label: "The Ksfe Ltd" },
  { value: "Mahavir Multistate Credit Co Op Society", label: "Mahavir Multistate Credit Co Op Society" },
  { value: "Nakoda Automobile Finance", label: "Nakoda Automobile Finance" },
  { value: "Chopra Brothers Finance Corporation", label: "Chopra Brothers Finance Corporation" },
  { value: "Shri Vitthal Nagari Sahkari Pathasantha Maryadit", label: "Shri Vitthal Nagari Sahkari Pathasantha Maryadit" },
  { value: "Shree Bhairavnath Co Op Credit Society Ltd", label: "Shree Bhairavnath Co Op Credit Society Ltd" },
  { value: "Shree Ganesh Nagari Sahakari Patpedhi Maryadit", label: "Shree Ganesh Nagari Sahakari Patpedhi Maryadit" },
  { value: "Chetana Pattina Souharda Sahakari Niyamita", label: "Chetana Pattina Souharda Sahakari Niyamita" },
  { value: "Aditya Urban Co Op Credit Society", label: "Aditya Urban Co Op Credit Society" },
  { value: "Asstt. Dir. Of Town Planning", label: "Asstt. Dir. Of Town Planning" },
  { value: "Avirat Co Op Credit Society Ltd", label: "Avirat Co Op Credit Society Ltd" },
  { value: "Mulund Vikas Sahkari Pethpedi Maryadit", label: "Mulund Vikas Sahkari Pethpedi Maryadit" },
  { value: "Shree Saikrupshree Saikrupa Sahkari Patsanstha Maryadita Sahkari Patsanstha Maryadit", label: "Shree Saikrupshree Saikrupa Sahkari Patsanstha Maryadita Sahkari Patsanstha Maryadit" },
  { value: "Shivam Co. Op. Credit Society Ltd", label: "Shivam Co. Op. Credit Society Ltd" },
  { value: "K.Sou. Savitribai Bhagoji Nimde Sahkari Patsanstha Maryadit", label: "K.Sou. Savitribai Bhagoji Nimde Sahkari Patsanstha Maryadit" },
  { value: "Shubhangi Nagri Sahkari Patsanstha Maryadit", label: "Shubhangi Nagri Sahkari Patsanstha Maryadit" },
  { value: "Vishal Sahkari Pethpedi Maryadit", label: "Vishal Sahkari Pethpedi Maryadit" },
  { value: "Nishkam Finance Corporation", label: "Nishkam Finance Corporation" },
  { value: "M S Eastern Auto Investo Pvt Ltd", label: "M S Eastern Auto Investo Pvt Ltd" },
  { value: "Vinod Financier Auto Mobile Financier", label: "Vinod Financier Auto Mobile Financier" },
  { value: "Ganesh Paraspar Patsanstha Maryadit Yeola", label: "Ganesh Paraspar Patsanstha Maryadit Yeola" },
  { value: "B S Finance", label: "B S Finance" },
  { value: "Shri Aundheshwar Gramin Biger Sheti Sahakari Patsanstha", label: "Shri Aundheshwar Gramin Biger Sheti Sahakari Patsanstha" },
  { value: "Om Datta Chaitanya Sahakari Bank Ltd", label: "Om Datta Chaitanya Sahakari Bank Ltd" },
  { value: "Credit Agricole Corporate And Investment Bank", label: "Credit Agricole Corporate And Investment Bank" },
  { value: "Amdar Sitaram Ghandat Gramin Bigar Sheti Sahakari Patsantha", label: "Amdar Sitaram Ghandat Gramin Bigar Sheti Sahakari Patsantha" },
  { value: "Vishista Hire Purchase And Finance", label: "Vishista Hire Purchase And Finance" },
  { value: "Cidco Nagri Sahkari Pathsantha", label: "Cidco Nagri Sahkari Pathsantha" },
  { value: "Shubham Nagari Sahakari Pathsanstha Maryadit", label: "Shubham Nagari Sahakari Pathsanstha Maryadit" },
  { value: "Satyam Shivam Co Op Credit Society Ltd", label: "Satyam Shivam Co Op Credit Society Ltd" },
  { value: "Bhagwati Gramin Bigarsheti Shahakari Patsantha Maryadit", label: "Bhagwati Gramin Bigarsheti Shahakari Patsantha Maryadit" },
  { value: "Shree Ank Finhold Pvt Ltd", label: "Shree Ank Finhold Pvt Ltd" },
  { value: "Trikal Leasing And Finance Ltd", label: "Trikal Leasing And Finance Ltd" },
  { value: "Varun Co Op Credit Society Ltd", label: "Varun Co Op Credit Society Ltd" },
  { value: "Shri Padmavati Mata Multistate Co Op Credit Society Ltd", label: "Shri Padmavati Mata Multistate Co Op Credit Society Ltd" },
  { value: "Industrial Financial Corporation Of India Ltd", label: "Industrial Financial Corporation Of India Ltd" },
  { value: "The Sangamner Merchants Co Op Bank Ltd", label: "The Sangamner Merchants Co Op Bank Ltd" },
  { value: "Money Master Leasing And Finance Ltd", label: "Money Master Leasing And Finance Ltd" },
  { value: "Raj Lakshmi Enterprise", label: "Raj Lakshmi Enterprise" },
  { value: "Lotus Trading Company", label: "Lotus Trading Company" },
  { value: "Trinayan Trading Co", label: "Trinayan Trading Co" },
  { value: "Maa Durga Trade And Associates", label: "Maa Durga Trade And Associates" },
  { value: "Ganapati Finance Company", label: "Ganapati Finance Company" },
  { value: "Jai Ambe Co Op. Credit Society Ltd", label: "Jai Ambe Co Op. Credit Society Ltd" },
  { value: "Police Co Op Society Ltd", label: "Police Co Op Society Ltd" },
  { value: "Nav Jyoti Fincrop Limited", label: "Nav Jyoti Fincrop Limited" },
  { value: "Kumar Gunjal Patsanstha", label: "Kumar Gunjal Patsanstha" },
  { value: "Sincere Marketing Services Pvt Ltd", label: "Sincere Marketing Services Pvt Ltd" },
  { value: "Ms D L Enterprises", label: "Ms D L Enterprises" },
  { value: "Vijay Hemant Finance And Estates Ltd", label: "Vijay Hemant Finance And Estates Ltd" },
  { value: "Shri Honnavadevi Multi Purpose Sahakari Sangh Ltd", label: "Shri Honnavadevi Multi Purpose Sahakari Sangh Ltd" },
  { value: "Jakaty Hire Purchase Associates", label: "Jakaty Hire Purchase Associates" },
  { value: "Shri Sai Hire Purchase Corporation", label: "Shri Sai Hire Purchase Corporation" },
  { value: "Plantation Corporation Kerala Ltd", label: "Plantation Corporation Kerala Ltd" },
  { value: "Aadhar Multipurpose Souhard Sahakari Niyamit", label: "Aadhar Multipurpose Souhard Sahakari Niyamit" },
  { value: "Dhule Medium Project Divsional No 02", label: "Dhule Medium Project Divsional No 02" },
  { value: "Aspinwall And Co Ltd", label: "Aspinwall And Co Ltd" },
  { value: "The Konkan Janata Sahakari Patsanstha Ltd", label: "The Konkan Janata Sahakari Patsanstha Ltd" },
  { value: "Raghubir Business Pvt Ltd", label: "Raghubir Business Pvt Ltd" },
  { value: "Madpur S.K.U.S Ltd", label: "Madpur S.K.U.S Ltd" },
  { value: "Ganga Financiers", label: "Ganga Financiers" },
  { value: "Sushil Sahakari Patasanstha Ltd", label: "Sushil Sahakari Patasanstha Ltd" },
  { value: "Kongu Auto Finance", label: "Kongu Auto Finance" },
  { value: "Kongu Financing Enterprises", label: "Kongu Financing Enterprises" },
  { value: "Kongu Financing Investment", label: "Kongu Financing Investment" },
  { value: "The Sela Seva Sahakari Mandali Ltd", label: "The Sela Seva Sahakari Mandali Ltd" },
  { value: "Gayatri Automobile Finance", label: "Gayatri Automobile Finance" },
  { value: "United Motors", label: "United Motors" },
  { value: "Borivali New Mandpeshwar Coop Credit Society Ltd", label: "Borivali New Mandpeshwar Coop Credit Society Ltd" },
  { value: "Sharad Multistate", label: "Sharad Multistate" },
  { value: "Dnyansavardhani Gramin Bigarsheti Shahakari Patsantha Maryadit", label: "Dnyansavardhani Gramin Bigarsheti Shahakari Patsantha Maryadit" },
  { value: "Gajanan Lokseva Sahakari Bank Ltd", label: "Gajanan Lokseva Sahakari Bank Ltd" },
  { value: "Rsb Coop Credit Society Ltd", label: "Rsb Coop Credit Society Ltd" },
  { value: "Mahanagar Co.Op Bank Ltd", label: "Mahanagar Co.Op Bank Ltd" },
  { value: "Gomoh Pacs Ltd", label: "Gomoh Pacs Ltd" },
  { value: "Sangeetha Investment", label: "Sangeetha Investment" },
  { value: "Sirimalle Auto Financiers", label: "Sirimalle Auto Financiers" },
  { value: "Anand Hire Purches Associates", label: "Anand Hire Purches Associates" },
  { value: "Priya Finvest", label: "Priya Finvest" },
  { value: "The Sabarkantha Dist Central Sahakari Bank Ltd.", label: "The Sabarkantha Dist Central Sahakari Bank Ltd." },
  { value: "Kodrali Group Seva Sahakari Mandli Ltd", label: "Kodrali Group Seva Sahakari Mandli Ltd" },
  { value: "The Bhaini Kalan Coop. Agri. Multipurpose Service Society Ltd.", label: "The Bhaini Kalan Coop. Agri. Multipurpose Service Society Ltd." },
  { value: "The Suvala Seva Shakari Mandali Ltd", label: "The Suvala Seva Shakari Mandali Ltd" },
  { value: "The Rampura Group Seva Sahakari Mandali Ltd", label: "The Rampura Group Seva Sahakari Mandali Ltd" },
  { value: "A U Financiers India P Ltd", label: "A U Financiers India P Ltd" },
  { value: "Ad Manum Finance Ltd", label: "Ad Manum Finance Ltd" },
  { value: "T S Gramin Bigarsheti Sahkari Patsanstha Maryadit", label: "T S Gramin Bigarsheti Sahkari Patsanstha Maryadit" },
  { value: "Jkmm Auto Consultancy Pvt Ltd", label: "Jkmm Auto Consultancy Pvt Ltd" },
  { value: "Baghmar Finance Limited", label: "Baghmar Finance Limited" },
  { value: "Kankriya Finance", label: "Kankriya Finance" },
  { value: "Liberty Videocon General Insurance Co. Ltd.", label: "Liberty Videocon General Insurance Co. Ltd." },
  { value: "R K Finlease", label: "R K Finlease" },
  { value: "Khairatabad Vikram Finance", label: "Khairatabad Vikram Finance" },
  { value: "Vikram Finance And Services", label: "Vikram Finance And Services" },
  { value: "M K Corporation", label: "M K Corporation" },
  { value: "M K Finance", label: "M K Finance" },
  { value: "M K Enterprises", label: "M K Enterprises" },
  { value: "Darshan Finance", label: "Darshan Finance" },
  { value: "Sai Spoorthy Financial Services", label: "Sai Spoorthy Financial Services" },
  { value: "Sahayog Multistate Co Op. Credit Society Ltd", label: "Sahayog Multistate Co Op. Credit Society Ltd" },
  { value: "Shri Yogiraj Janardan Gramin Biger Sheti Sahkari Patsanstha Maryadit", label: "Shri Yogiraj Janardan Gramin Biger Sheti Sahkari Patsanstha Maryadit" },
  { value: "Veda Auto Enterprises", label: "Veda Auto Enterprises" },
  { value: "Shree Pooja Jyoti Co Op. Credit Society Ltd.", label: "Shree Pooja Jyoti Co Op. Credit Society Ltd." },
  { value: "Kaliara S.K.U.S Ltd", label: "Kaliara S.K.U.S Ltd" },
  { value: "Rathood Finance", label: "Rathood Finance" },
  { value: "Tanmay Khabiya", label: "Tanmay Khabiya" },
  { value: "Seema Credits", label: "Seema Credits" },
  { value: "Parakh Finance", label: "Parakh Finance" },
  { value: "Venkateshwara Finance", label: "Venkateshwara Finance" },
  { value: "Pavankumar Auto Fin R", label: "Pavankumar Auto Fin R" },
  { value: "Dhanya Vyaparigala Pattina Sahakari Sangha Niyamitha", label: "Dhanya Vyaparigala Pattina Sahakari Sangha Niyamitha" },
  { value: "V M Finance", label: "V M Finance" },
  { value: "Sri Paramahamsa Pattina Sahakari Sanga", label: "Sri Paramahamsa Pattina Sahakari Sanga" },
  { value: "Sai Finance", label: "Sai Finance" },
  { value: "Manoj Kumar Jain", label: "Manoj Kumar Jain" },
  { value: "Tated Finance Corporation", label: "Tated Finance Corporation" },
  { value: "Shree Tirthankar Finance Corporation", label: "Shree Tirthankar Finance Corporation" },
  { value: "Aap Auto Finance", label: "Aap Auto Finance" },
  { value: "The Dadhol Agriculture Co Op Society", label: "The Dadhol Agriculture Co Op Society" },
  { value: "The Thuleta Seva Sahakari Mandali Ltd", label: "The Thuleta Seva Sahakari Mandali Ltd" },
  { value: "M A Trading And Financing Pvt Ltd", label: "M A Trading And Financing Pvt Ltd" },
  { value: "Pragathi Krishna Gramina Bank", label: "Pragathi Krishna Gramina Bank" },
  { value: "P R Chwajer Financiers", label: "P R Chwajer Financiers" },
  { value: "Deepak Credit Corporation", label: "Deepak Credit Corporation" },
  { value: "M Deepaa Jain", label: "M Deepaa Jain" },
  { value: "Sunrise Investment", label: "Sunrise Investment" },
  { value: "Bhagya Finance", label: "Bhagya Finance" },
  { value: "S Ramesh Chand Jain", label: "S Ramesh Chand Jain" },
  { value: "Ram Dev Baba Finance", label: "Ram Dev Baba Finance" },
  { value: "S Ramesh Chand Jain Hub", label: "S Ramesh Chand Jain Hub" },
  { value: "Sri Krishna H P Company", label: "Sri Krishna H P Company" },
  { value: "Vishwakarma H P Co India Pvt Ltd", label: "Vishwakarma H P Co India Pvt Ltd" },
  { value: "Helpline Trading Co", label: "Helpline Trading Co" },
  { value: "Balaji Finance Company", label: "Balaji Finance Company" },
  { value: "Dugar Finance Company", label: "Dugar Finance Company" },
  { value: "Hasti Finance Company", label: "Hasti Finance Company" },
  { value: "Subham Thrift And Credit Co Operative Society Ltd", label: "Subham Thrift And Credit Co Operative Society Ltd" },
  { value: "Shree Sadguru Co Op Credit Ltd", label: "Shree Sadguru Co Op Credit Ltd" },
  { value: "Beed Urban Multi State Co Op Credit Society Ltd", label: "Beed Urban Multi State Co Op Credit Society Ltd" },
  { value: "Shree Laxminaryan Nagari Sahakari Patsantha Maryadit", label: "Shree Laxminaryan Nagari Sahakari Patsantha Maryadit" },
  { value: "Janklyan Sahakari Patpedhi", label: "Janklyan Sahakari Patpedhi" },
  { value: "Kothari Credits", label: "Kothari Credits" },
  { value: "Mohileshwar Co Op Credit Society Ltd", label: "Mohileshwar Co Op Credit Society Ltd" },
  { value: "Gautam Dresses", label: "Gautam Dresses" },
  { value: "Ravi Finance And Leasing Co", label: "Ravi Finance And Leasing Co" },
  { value: "Hangamai Mahila Gr Big Sah Pat Parner", label: "Hangamai Mahila Gr Big Sah Pat Parner" },
  { value: "Marudhara Gramin Bank, Sayla", label: "Marudhara Gramin Bank, Sayla" },
  { value: "Ms Moksha Jain Financer", label: "Ms Moksha Jain Financer" },
  { value: "Om Gorai Nagari Sahakari Patsanstha Maryadit", label: "Om Gorai Nagari Sahakari Patsanstha Maryadit" },
  { value: "The Thori Mubarak Grup Seva Sahkari Mandali Ltd", label: "The Thori Mubarak Grup Seva Sahkari Mandali Ltd" },
  { value: "Rajkamal Nagari Sahakari Path Santh", label: "Rajkamal Nagari Sahakari Path Santh" },
  { value: "Marudhara Gramin Bank Sayla", label: "Marudhara Gramin Bank Sayla" },
  { value: "Starline Associates", label: "Starline Associates" },
  { value: "Guru Krupa Co Op Credit Soc Ltd", label: "Guru Krupa Co Op Credit Soc Ltd" },
  { value: "Ge Capital Services India", label: "Ge Capital Services India" },
  { value: "Aakarshna Auto Service", label: "Aakarshna Auto Service" },
  { value: "Hariyali Urban Co Op Credit Soc Ltd", label: "Hariyali Urban Co Op Credit Soc Ltd" },
  { value: "Sampada Sahakari Patsanstha Ltd", label: "Sampada Sahakari Patsanstha Ltd" },
  { value: "Bhagwati Prasad Bajoria Huf", label: "Bhagwati Prasad Bajoria Huf" },
  { value: "Jayant Enterpries", label: "Jayant Enterpries" },
  { value: "Shri Nandi Pathina Sahakari Sangh Niyamit", label: "Shri Nandi Pathina Sahakari Sangh Niyamit" },
  { value: "Adhavan Auto Finance", label: "Adhavan Auto Finance" },
  { value: "Vivekanand Gramin Bigar Sheti Sahkari Patsanstha", label: "Vivekanand Gramin Bigar Sheti Sahkari Patsanstha" },
  { value: "Jamkhed Nagri Sahakari Patsanstha", label: "Jamkhed Nagri Sahakari Patsanstha" },
  { value: "H U Gugale Gramin Sahkari Patsanstha", label: "H U Gugale Gramin Sahkari Patsanstha" },
  { value: "Fortune Integrated Assets Finance Ltd", label: "Fortune Integrated Assets Finance Ltd" },
  { value: "Renuka Urban Co Operative Society", label: "Renuka Urban Co Operative Society" },
  { value: "H K Investments", label: "H K Investments" },
  { value: "Fortune Integrated Asset Finance Limited", label: "Fortune Integrated Asset Finance Limited" },
  { value: "Veena Finance Corporation", label: "Veena Finance Corporation" },
  { value: "Toyota Financial Services India Limited", label: "Toyota Financial Services India Limited" },
  { value: "Sirohi Home Products Pvt Ltd", label: "Sirohi Home Products Pvt Ltd" },
  { value: "Ekta Financial Services", label: "Ekta Financial Services" },
  { value: "Pushpak Co Op Credit Soc Ltd", label: "Pushpak Co Op Credit Soc Ltd" },
  { value: "Kishanlal Sancheti Nagri Co Op Society Ltd", label: "Kishanlal Sancheti Nagri Co Op Society Ltd" },
  { value: "Ms Sai Laxmi Narsimha Swamy Auto Finance", label: "Ms Sai Laxmi Narsimha Swamy Auto Finance" },
  { value: "Kalyan Bibhag", label: "Kalyan Bibhag" },
  { value: "Mumbai Vidi Tambakhu Vyapari Sahakari Patpedhi Maryadit", label: "Mumbai Vidi Tambakhu Vyapari Sahakari Patpedhi Maryadit" },
  { value: "Sure Baankers", label: "Sure Baankers" },
  { value: "Pure Bankers", label: "Pure Bankers" },
  { value: "K.S.F.C", label: "K.S.F.C" },
  { value: "Diwakar Co. Op. Credit Society Ltd.", label: "Diwakar Co. Op. Credit Society Ltd." },
  { value: "Kishore Finance", label: "Kishore Finance" },
  { value: "Uttam Chand Surana", label: "Uttam Chand Surana" },
  { value: "Pawan Kumar Surana", label: "Pawan Kumar Surana" },
  { value: "Sri Ram City Union Finance Ltd", label: "Sri Ram City Union Finance Ltd" },
  { value: "Sriram Trade Finance Co.Ltd", label: "Sriram Trade Finance Co.Ltd" },
  { value: "Sri Sadaiyappa Finance", label: "Sri Sadaiyappa Finance" },
  { value: "Velavan Motor Finance", label: "Velavan Motor Finance" },
  { value: "Tirupur Auto Credits", label: "Tirupur Auto Credits" },
  { value: "Valli Auto Finance", label: "Valli Auto Finance" },
  { value: "Sri Ratham Auto Finance", label: "Sri Ratham Auto Finance" },
  { value: "Sarva Haryana Gramin Bank", label: "Sarva Haryana Gramin Bank" },
  { value: "Abinaya Auto Finance Ltd", label: "Abinaya Auto Finance Ltd" },
  { value: "G.S.Finance Service", label: "G.S.Finance Service" },
  { value: "Gowtham Auto Finance", label: "Gowtham Auto Finance" },
  { value: "Sa Auto Finance", label: "Sa Auto Finance" },
  { value: "Surya Auto Finance", label: "Surya Auto Finance" },
  { value: "Manoj P Kothari", label: "Manoj P Kothari" },
  { value: "The Maruthonkara Service Co Op Bank Ltd", label: "The Maruthonkara Service Co Op Bank Ltd" },
  { value: "The Dharampeth Mahila Multi State Co Op So Ltd", label: "The Dharampeth Mahila Multi State Co Op So Ltd" },
  { value: "Aadhar Sah Patpedhi Maryadit", label: "Aadhar Sah Patpedhi Maryadit" },
  { value: "Yogesh Co Op Credit Society Ltd", label: "Yogesh Co Op Credit Society Ltd" },
  { value: "Sandeep Dangi", label: "Sandeep Dangi" },
  { value: "Kiran Bothra", label: "Kiran Bothra" },
  { value: "Kanthilal Bafna Huf", label: "Kanthilal Bafna Huf" },
  { value: "Bhumika Jain", label: "Bhumika Jain" },
  { value: "Kavitha Finance Company", label: "Kavitha Finance Company" },
  { value: "Tara Investment", label: "Tara Investment" },
  { value: "Dinesh Kothari", label: "Dinesh Kothari" },
  { value: "Jai Investment", label: "Jai Investment" },
  { value: "P M Finance", label: "P M Finance" },
  { value: "J Credit", label: "J Credit" },
  { value: "Vidhi Finance", label: "Vidhi Finance" },
  { value: "Neetu Bafna", label: "Neetu Bafna" },
  { value: "Bihar State Minorities Corporation Ltd", label: "Bihar State Minorities Corporation Ltd" },
  { value: "N M Finance", label: "N M Finance" },
  { value: "Gamij Seva Sahakari Mandali Ltd", label: "Gamij Seva Sahakari Mandali Ltd" },
  { value: "Kapur Finance Co", label: "Kapur Finance Co" },
  { value: "Kocher Finance", label: "Kocher Finance" },
  { value: "Sri Palaniraja Credits", label: "Sri Palaniraja Credits" },
  { value: "Erode Kanagaraja Finance", label: "Erode Kanagaraja Finance" },
  { value: "Mangalmurti Sahakari Patpedhi Maryadit, Mumbai", label: "Mangalmurti Sahakari Patpedhi Maryadit, Mumbai" },
  { value: "Sri Balaganapathy Bankers", label: "Sri Balaganapathy Bankers" },
  { value: "Malwa Ludhiana Motors Finance Ltd", label: "Malwa Ludhiana Motors Finance Ltd" },
  { value: "F D Finance", label: "F D Finance" },
  { value: "Nikhil Finance Corporation", label: "Nikhil Finance Corporation" },
  { value: "Vipin Finance", label: "Vipin Finance" },
  { value: "Liluah Co Operative Bank Ltd", label: "Liluah Co Operative Bank Ltd" },
  { value: "Swathy Investment", label: "Swathy Investment" },
  { value: "Sagar Finance Corprn", label: "Sagar Finance Corprn" },
  { value: "Palladam F .S C.S", label: "Palladam F .S C.S" },
  { value: "M.G.B Auto Finance", label: "M.G.B Auto Finance" },
  { value: "Sri Velavan Motor Finance", label: "Sri Velavan Motor Finance" },
  { value: "Selvi Auto Finance", label: "Selvi Auto Finance" },
  { value: "Chettinadu Auto Credits", label: "Chettinadu Auto Credits" },
  { value: "Muthu Kuviyal Investments", label: "Muthu Kuviyal Investments" },
  { value: "S P S Auto Finance", label: "S P S Auto Finance" },
  { value: "Mansa Auto Finance", label: "Mansa Auto Finance" },
  { value: "Preeti Syndicat", label: "Preeti Syndicat" },
  { value: "Kamal Finance", label: "Kamal Finance" },
  { value: "Sree Balaji Auto Finance", label: "Sree Balaji Auto Finance" },
  { value: "Sri Balaji Motor Finance", label: "Sri Balaji Motor Finance" },
  { value: "Chandan Distributors", label: "Chandan Distributors" },
  { value: "Sree Balaji Investment", label: "Sree Balaji Investment" },
  { value: "Sri Laxmi Auto Finance", label: "Sri Laxmi Auto Finance" },
  { value: "Raja Ganapathi Auto Finance", label: "Raja Ganapathi Auto Finance" },
  { value: "Amman Auto Financies", label: "Amman Auto Financies" },
  { value: "Arul Auto Finance", label: "Arul Auto Finance" },
  { value: "Arul Murugan Finance", label: "Arul Murugan Finance" },
  { value: "Sri Amman Investmentss", label: "Sri Amman Investmentss" },
  { value: "The Balaji Bankers", label: "The Balaji Bankers" },
  { value: "Sri Vinayaga Auto Finance", label: "Sri Vinayaga Auto Finance" },
  { value: "The Balaji Enterprises", label: "The Balaji Enterprises" },
  { value: "Mercury Auto Finance", label: "Mercury Auto Finance" },
  { value: "Sri Palani Andavar Auto Finance", label: "Sri Palani Andavar Auto Finance" },
  { value: "The Jalgaon Nagari Sah Pat Ltd", label: "The Jalgaon Nagari Sah Pat Ltd" },
  { value: "Lsr Corporation Bank", label: "Lsr Corporation Bank" },
  { value: "Shri Krishna H.P Co", label: "Shri Krishna H.P Co" },
  { value: "New Kamrupa Trading Co", label: "New Kamrupa Trading Co" },
  { value: "Marudhara Gramin Bank", label: "Marudhara Gramin Bank" },
  { value: "Mahanagar Vyapari Nagari Sahakari Patsantha", label: "Mahanagar Vyapari Nagari Sahakari Patsantha" },
  { value: "The Quilon Co Operative Urban Bank Ltd", label: "The Quilon Co Operative Urban Bank Ltd" },
  { value: "Valmiki Urban Co Op Bank Ltd", label: "Valmiki Urban Co Op Bank Ltd" },
  { value: "Dewan Housing Finance Corporation Ltd", label: "Dewan Housing Finance Corporation Ltd" },
  { value: "Balusseri Regioanl Co Op Bank", label: "Balusseri Regioanl Co Op Bank" },
  { value: "Bhardwaj Finance", label: "Bhardwaj Finance" },
  { value: "Suswani Finance", label: "Suswani Finance" },
  { value: "Vijay Investment", label: "Vijay Investment" },
  { value: "Adheshwar Finance", label: "Adheshwar Finance" },
  { value: "Vijay Kumar Dugar", label: "Vijay Kumar Dugar" },
  { value: "Shree Investment", label: "Shree Investment" },
  { value: "Hanumantrao Chaudhari Kalbhairavnath Nag. Sah. Patsanstha", label: "Hanumantrao Chaudhari Kalbhairavnath Nag. Sah. Patsanstha" },
  { value: "Shri Mahalingeshwar Patha Sanstha Mayad Dit Ulsanthi", label: "Shri Mahalingeshwar Patha Sanstha Mayad Dit Ulsanthi" },
  { value: "Shreeji Investment And Financial Services", label: "Shreeji Investment And Financial Services" },
  { value: "Aman Finance Co Regd", label: "Aman Finance Co Regd" },
  { value: "Sail Rsp", label: "Sail Rsp" },
  { value: "Sri Renukadevi Souhard Credit Sahakari Niyamit", label: "Sri Renukadevi Souhard Credit Sahakari Niyamit" },
  { value: "Vidharbha Konkan Gramin Bank", label: "Vidharbha Konkan Gramin Bank" },
  { value: "Bharathi Motors", label: "Bharathi Motors" },
  { value: "Manjula Finance", label: "Manjula Finance" },
  { value: "Manjula Auto Finance Company", label: "Manjula Auto Finance Company" },
  { value: "Matoshri Mahila Sahakari Bank Ltd", label: "Matoshri Mahila Sahakari Bank Ltd" },
  { value: "Sri Hole Hucheshwar Co Op Credit Society Ltd", label: "Sri Hole Hucheshwar Co Op Credit Society Ltd" },
  { value: "Ms Barnala Finance Company", label: "Ms Barnala Finance Company" },
  { value: "Krantiveer Sangolli Rayanna Urban Co Op Credit Society Ltd", label: "Krantiveer Sangolli Rayanna Urban Co Op Credit Society Ltd" },
  { value: "Bss Trading Co", label: "Bss Trading Co" },
  { value: "Ashok Finance", label: "Ashok Finance" },
  { value: "Kusum And Co", label: "Kusum And Co" },
  { value: "Rahul Uddayag", label: "Rahul Uddayag" },
  { value: "Jhargram Co Operative Agriculture And Rural Development", label: "Jhargram Co Operative Agriculture And Rural Development" },
  { value: "Baragarh Skus Ltd", label: "Baragarh Skus Ltd" },
  { value: "National Dairy Development Board", label: "National Dairy Development Board" },
  { value: "Ramdev Motors And Finance", label: "Ramdev Motors And Finance" },
  { value: "Vinstar Finance Ltd", label: "Vinstar Finance Ltd" },
  { value: "Shivshambho Co Op Credit Society Ltd", label: "Shivshambho Co Op Credit Society Ltd" },
  { value: "Sre Selvakumaran Finance", label: "Sre Selvakumaran Finance" },
  { value: "Sri Arupadayar Finance", label: "Sri Arupadayar Finance" },
  { value: "Karpaca Lakshmi Auto Investment", label: "Karpaca Lakshmi Auto Investment" },
  { value: "Shri Vishwaguru Multi Purpose Co Op Society Niyamit", label: "Shri Vishwaguru Multi Purpose Co Op Society Niyamit" },
  { value: "Kalakundri Nagari Sahakari Patsantha Maryadit", label: "Kalakundri Nagari Sahakari Patsantha Maryadit" },
  { value: "Taximens Finance Leasing Ltd", label: "Taximens Finance Leasing Ltd" },
  { value: "Krishana Finance", label: "Krishana Finance" },
  { value: "Priya Nagari Sahakari Patsantha Mary", label: "Priya Nagari Sahakari Patsantha Mary" },
  { value: "Sumangal Finance And Investment", label: "Sumangal Finance And Investment" },
  { value: "Dn Credit Corporation", label: "Dn Credit Corporation" },
  { value: "Ghansawangi Gramin Bigar Sheti Sahakari Patsanstha Ghansawangi", label: "Ghansawangi Gramin Bigar Sheti Sahakari Patsanstha Ghansawangi" },
  { value: "Magma Itl Finance Limited", label: "Magma Itl Finance Limited" },
  { value: "Shree Saikrupa Sahkari Patsanstha Maryadit", label: "Shree Saikrupa Sahkari Patsanstha Maryadit" },
  { value: "The Bhadran People S Co Op Bank Ltd", label: "The Bhadran People S Co Op Bank Ltd" },
  { value: "Sri Sai Auto Finance", label: "Sri Sai Auto Finance" },
  { value: "Sairam Auto Finance", label: "Sairam Auto Finance" },
  { value: "Suprabhat Credit Souharda Sahakari Ltd", label: "Suprabhat Credit Souharda Sahakari Ltd" },
  { value: "Sri Kuber Auto Finance", label: "Sri Kuber Auto Finance" },
  { value: "Nanbargal Investment", label: "Nanbargal Investment" },
  { value: "Suryaa Enterprise", label: "Suryaa Enterprise" },
  { value: "Kiran Hire Purchase And Leasin", label: "Kiran Hire Purchase And Leasin" },
  { value: "Bijay Finance And Investment Private Limited", label: "Bijay Finance And Investment Private Limited" },
  { value: "V T Finance", label: "V T Finance" },
  { value: "Hunuman Finance", label: "Hunuman Finance" },
  { value: "Shreeji Investment And Finance Services", label: "Shreeji Investment And Finance Services" },
  { value: "Central Co Operative Bank", label: "Central Co Operative Bank" },
  { value: "Swatantradeo Merchantiles Pvt Ltd", label: "Swatantradeo Merchantiles Pvt Ltd" },
  { value: "Ambey Enterprises", label: "Ambey Enterprises" },
  { value: "Dcb Bank Ltd.", label: "Dcb Bank Ltd." },
  { value: "Karvy Financial Services Limited", label: "Karvy Financial Services Limited" },
  { value: "Vinod Kumar Rathi", label: "Vinod Kumar Rathi" },
  { value: "Kushal Finance", label: "Kushal Finance" },
  { value: "Subhash Finance", label: "Subhash Finance" },
  { value: "Pukhraj Sikchi", label: "Pukhraj Sikchi" },
  { value: "Surendrakumar Kothari", label: "Surendrakumar Kothari" },
  { value: "Vishnu Prakash Sikchi", label: "Vishnu Prakash Sikchi" },
  { value: "Bhavana Finance", label: "Bhavana Finance" },
  { value: "Shri Yerriswamy Credit Souhard Sahakari Niyamit", label: "Shri Yerriswamy Credit Souhard Sahakari Niyamit" },
  { value: "Shreejee Credit Co Operative Society Ltd", label: "Shreejee Credit Co Operative Society Ltd" },
  { value: "Siddharth Marketing Finance Limited", label: "Siddharth Marketing Finance Limited" },
  { value: "Shree Siddheshvari Enterprise", label: "Shree Siddheshvari Enterprise" },
  { value: "G Dineshkumar Jain Huf", label: "G Dineshkumar Jain Huf" },
  { value: "D Sangeetha", label: "D Sangeetha" },
  { value: "G.Dineshkumar Jain", label: "G.Dineshkumar Jain" },
  { value: "G.Kiran Kumari", label: "G.Kiran Kumari" },
  { value: "Goutam Chand Jain And Sons", label: "Goutam Chand Jain And Sons" },
  { value: "Superfinance And Investments Corporation", label: "Superfinance And Investments Corporation" },
  { value: "Pinky And Co", label: "Pinky And Co" },
  { value: "Mertiya Finance And Leasing", label: "Mertiya Finance And Leasing" },
  { value: "Pappu Finance", label: "Pappu Finance" },
  { value: "Raj Finance And Leasing", label: "Raj Finance And Leasing" },
  { value: "Ranu Finance And Leasing", label: "Ranu Finance And Leasing" },
  { value: "Shree Karni Finance And Leasing", label: "Shree Karni Finance And Leasing" },
  { value: "Yas Finance", label: "Yas Finance" },
  { value: "Jharkhand State Co Operative Bank", label: "Jharkhand State Co Operative Bank" },
  { value: "Mayur Mitra Souharda Pattina Sahakara Sangha Niyamitha", label: "Mayur Mitra Souharda Pattina Sahakara Sangha Niyamitha" },
  { value: "Shivashree Souharda Credit Co Op Ltd", label: "Shivashree Souharda Credit Co Op Ltd" },
  { value: "Shree Sharneshwar Gramin Bigar Sheti Shah Pat Marya", label: "Shree Sharneshwar Gramin Bigar Sheti Shah Pat Marya" },
  { value: "Dr Uttamrao Shende Gramin Biger Sheti Sah Pat San", label: "Dr Uttamrao Shende Gramin Biger Sheti Sah Pat San" },
  { value: "Badami Devi R Basna", label: "Badami Devi R Basna" },
  { value: "Sri Sai Kothakota Auto Finance", label: "Sri Sai Kothakota Auto Finance" },
  { value: "R K D Fin Ltd", label: "R K D Fin Ltd" },
  { value: "Quality Phale And Bhajipala Saha Sanstha Maryadit", label: "Quality Phale And Bhajipala Saha Sanstha Maryadit" },
  { value: "Revangaon Gramin Biger Sheti Sah Pat San Maryadit", label: "Revangaon Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Namma Auto", label: "Namma Auto" },
  { value: "The Pritisangam Sahakari Bank Ltd", label: "The Pritisangam Sahakari Bank Ltd" },
  { value: "C.D Co Oprative Credit Society Ltd", label: "C.D Co Oprative Credit Society Ltd" },
  { value: "Geos V P L Financial Services Private Ltd", label: "Geos V P L Financial Services Private Ltd" },
  { value: "Maruthi Auto Finance", label: "Maruthi Auto Finance" },
  { value: "Shri Pisedev Multipurpose Co Op Society Ltd", label: "Shri Pisedev Multipurpose Co Op Society Ltd" },
  { value: "Sayali Gramin Bigarsheti Sahakari Patsanstha Maryya", label: "Sayali Gramin Bigarsheti Sahakari Patsanstha Maryya" },
  { value: "Shanti Investment", label: "Shanti Investment" },
  { value: "Khumbi Vikas Sahakari Pethpedi Ltd", label: "Khumbi Vikas Sahakari Pethpedi Ltd" },
  { value: "Bijender Lohia And Co India Pvt Ltd", label: "Bijender Lohia And Co India Pvt Ltd" },
  { value: "Assandh Finance Co", label: "Assandh Finance Co" },
  { value: "Nirmal Motors", label: "Nirmal Motors" },
  { value: "Madhyanchal Gramin Bank", label: "Madhyanchal Gramin Bank" },
  { value: "Abaso Gramin Biger Sheti Sahakari Pat San Maryadit", label: "Abaso Gramin Biger Sheti Sahakari Pat San Maryadit" },
  { value: "Sanmati Sahakari Bank Ltd", label: "Sanmati Sahakari Bank Ltd" },
  { value: "Shri Jivapar Seva Sahkari Mandali Ltd", label: "Shri Jivapar Seva Sahkari Mandali Ltd" },
  { value: "Nanesh Finance Corporation", label: "Nanesh Finance Corporation" },
  { value: "Surana Finance Corporation", label: "Surana Finance Corporation" },
  { value: "Shri Bhagyalaxmi Co Op Multi Purpose Society Ltd", label: "Shri Bhagyalaxmi Co Op Multi Purpose Society Ltd" },
  { value: "Dr Shiriesh Patil Nag Sah Pat Santh", label: "Dr Shiriesh Patil Nag Sah Pat Santh" },
  { value: "Bellad Bagewadi Krishi Seva Sahakari Sangh Niyamit", label: "Bellad Bagewadi Krishi Seva Sahakari Sangh Niyamit" },
  { value: "Jadopur Shukul Pacs Ltd", label: "Jadopur Shukul Pacs Ltd" },
  { value: "The Balaji Credits", label: "The Balaji Credits" },
  { value: "The Kothamanagalam Taluk Co Op Agrl And Rural Devpt Bank Ltd", label: "The Kothamanagalam Taluk Co Op Agrl And Rural Devpt Bank Ltd" },
  { value: "Talegaon Nagari Sahakari Pat Sanstha Maryadit", label: "Talegaon Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Datt Guru Co Op Credit Society Ltd", label: "Datt Guru Co Op Credit Society Ltd" },
  { value: "D N Credit Corporation,Automobile Financiers", label: "D N Credit Corporation,Automobile Financiers" },
  { value: "Jumbo Finvest India Limited", label: "Jumbo Finvest India Limited" },
  { value: "Sri Selvavinaya Auto Finance", label: "Sri Selvavinaya Auto Finance" },
  { value: "Sri Vinayaga Enterprises", label: "Sri Vinayaga Enterprises" },
  { value: "Sri Senthur Murugan Finance", label: "Sri Senthur Murugan Finance" },
  { value: "Super Coop Credit Society Ltd", label: "Super Coop Credit Society Ltd" },
  { value: "Bairi Reddy Finance Ltd", label: "Bairi Reddy Finance Ltd" },
  { value: "Shree Nurshih Sarawati Nagari Shah Pat Mar", label: "Shree Nurshih Sarawati Nagari Shah Pat Mar" },
  { value: "Avadh Raj Agencies", label: "Avadh Raj Agencies" },
  { value: "Hangamai Mahila Gra.Big.Sah.Pat.Mar Hanga", label: "Hangamai Mahila Gra.Big.Sah.Pat.Mar Hanga" },
  { value: "Jamkhed Merchants Co Op Bank Ltd", label: "Jamkhed Merchants Co Op Bank Ltd" },
  { value: "Sri Kethaki Vishweshwara Auto Finance", label: "Sri Kethaki Vishweshwara Auto Finance" },
  { value: "Shree Vivekanandji Sahkari Patpedhi Maryadit", label: "Shree Vivekanandji Sahkari Patpedhi Maryadit" },
  { value: "Shree Kalbhairav Nagri Sah Pat Maryadit", label: "Shree Kalbhairav Nagri Sah Pat Maryadit" },
  { value: "Bamne Janseva Sahakari Patpedhi Maryadit", label: "Bamne Janseva Sahakari Patpedhi Maryadit" },
  { value: "Mangalmurti Sahakari Patpedhi Maryadit", label: "Mangalmurti Sahakari Patpedhi Maryadit" },
  { value: "Gadhinglaj Taluka Sahakari Patpedhi Maryadit", label: "Gadhinglaj Taluka Sahakari Patpedhi Maryadit" },
  { value: "Haripriya Auto Finance Corporation", label: "Haripriya Auto Finance Corporation" },
  { value: "Punyashlok Ahilyadevi Holkar Nag Sah Pat", label: "Punyashlok Ahilyadevi Holkar Nag Sah Pat" },
  { value: "Vyankatesh Nagari Sah Pat San Ltd", label: "Vyankatesh Nagari Sah Pat San Ltd" },
  { value: "Vedaganga Co Op Credit Soc Ltd", label: "Vedaganga Co Op Credit Soc Ltd" },
  { value: "Jai Yogeshwar Nagri Shakari Patsansta Maryadit", label: "Jai Yogeshwar Nagri Shakari Patsansta Maryadit" },
  { value: "The Kollam District Co Operative Bank", label: "The Kollam District Co Operative Bank" },
  { value: "Shree Gajanan Sahakari Path Ltd", label: "Shree Gajanan Sahakari Path Ltd" },
  { value: "Ashish Enterprises", label: "Ashish Enterprises" },
  { value: "Arihant Credit Corporation", label: "Arihant Credit Corporation" },
  { value: "Shree Misri Corporation", label: "Shree Misri Corporation" },
  { value: "Sepr Refractories India Ltd", label: "Sepr Refractories India Ltd" },
  { value: "Navkar Auto", label: "Navkar Auto" },
  { value: "Shrinath Co Op Soc Ltd", label: "Shrinath Co Op Soc Ltd" },
  { value: "Sri Lakshmi Gayathri Auto Finance", label: "Sri Lakshmi Gayathri Auto Finance" },
  { value: "K S Trading Co", label: "K S Trading Co" },
  { value: "Shri Arihanth Credit Souhard Sahakari Ltd", label: "Shri Arihanth Credit Souhard Sahakari Ltd" },
  { value: "Muthoot Vehicle And Asset Finance Ltd", label: "Muthoot Vehicle And Asset Finance Ltd" },
  { value: "Venkata Srinivasa Auto Finance", label: "Venkata Srinivasa Auto Finance" },
  { value: "Maina Associates", label: "Maina Associates" },
  { value: "Venugram Multi Purpose Co Op Sct Ltd", label: "Venugram Multi Purpose Co Op Sct Ltd" },
  { value: "The Chandranagar Group Seva Sahakari Mandali Limited", label: "The Chandranagar Group Seva Sahakari Mandali Limited" },
  { value: "Jatinder Finance Pvt Ltd And Iob", label: "Jatinder Finance Pvt Ltd And Iob" },
  { value: "Jitendra Finance Ltd", label: "Jitendra Finance Ltd" },
  { value: "Bhaveshwari Sahakari Patsanstha Ltd", label: "Bhaveshwari Sahakari Patsanstha Ltd" },
  { value: "The Bhutbhavani Seva Sahakari Mandali Ltd", label: "The Bhutbhavani Seva Sahakari Mandali Ltd" },
  { value: "Goreshwar Multi State Co Op Soc", label: "Goreshwar Multi State Co Op Soc" },
  { value: "Ramling G.B.S.S.Pat", label: "Ramling G.B.S.S.Pat" },
  { value: "Rajmata Jijau Mahila Sahkari G.B.S.S Pat", label: "Rajmata Jijau Mahila Sahkari G.B.S.S Pat" },
  { value: "Wighnahar Gramin Big Sheti .Soc.Ltd", label: "Wighnahar Gramin Big Sheti .Soc.Ltd" },
  { value: "Jain Oswal Co Op Credit Soc", label: "Jain Oswal Co Op Credit Soc" },
  { value: "Baliraja Gramin Big Sah Sanstha", label: "Baliraja Gramin Big Sah Sanstha" },
  { value: "Bhairavnath Dhudh Ut Sah Pat", label: "Bhairavnath Dhudh Ut Sah Pat" },
  { value: "Alkuti Gra Big Sheti Sah Pat", label: "Alkuti Gra Big Sheti Sah Pat" },
  { value: "Sri Kuber Agency", label: "Sri Kuber Agency" },
  { value: "Krishna Finance", label: "Krishna Finance" },
  { value: "Raj Kumar Mehta", label: "Raj Kumar Mehta" },
  { value: "Citi Bank Na", label: "Citi Bank Na" },
  { value: "Rajashri Shahu Gramin Biger Sheti Sahkari Path Sanstha Maryadit", label: "Rajashri Shahu Gramin Biger Sheti Sahkari Path Sanstha Maryadit" },
  { value: "Shri Mahalaxmi Gramin Biger Sheti Sahkari Path Sanstha Maryadit", label: "Shri Mahalaxmi Gramin Biger Sheti Sahkari Path Sanstha Maryadit" },
  { value: "Karmaveer Bhaurao Patil Multi State Co Op Credit Society", label: "Karmaveer Bhaurao Patil Multi State Co Op Credit Society" },
  { value: "Shri Ganesh Nagari Sahkari Path Sanstha Maryadit", label: "Shri Ganesh Nagari Sahkari Path Sanstha Maryadit" },
  { value: "Shri Hanuman Nagari Sahkari Path Sanstha Maryadit", label: "Shri Hanuman Nagari Sahkari Path Sanstha Maryadit" },
  { value: "Navjeevan Gramin Biger Sheti Sahkari Path Sanstha Maryadit", label: "Navjeevan Gramin Biger Sheti Sahkari Path Sanstha Maryadit" },
  { value: "The Banahatti Minor Urban Co Op Credit Soc Ltd", label: "The Banahatti Minor Urban Co Op Credit Soc Ltd" },
  { value: "Om Multipurpose Souhard Sahakari Ltd", label: "Om Multipurpose Souhard Sahakari Ltd" },
  { value: "Shri Darshan Nagari Sahkari Path Sanstha Maryadit", label: "Shri Darshan Nagari Sahkari Path Sanstha Maryadit" },
  { value: "Hattaragi Shri Basavesh Co Op Credit Society Ltd", label: "Hattaragi Shri Basavesh Co Op Credit Society Ltd" },
  { value: "Jay Hanuman Muilti Purpose Souhard Sahakari Ltd", label: "Jay Hanuman Muilti Purpose Souhard Sahakari Ltd" },
  { value: "Siddhivinayak Co Op Credit Society", label: "Siddhivinayak Co Op Credit Society" },
  { value: "Shri Kalbhairav Vivid Udde Souhard Sahakari Ltd", label: "Shri Kalbhairav Vivid Udde Souhard Sahakari Ltd" },
  { value: "Shri Ravalnath Gramin Biger Sheti Sahkari Path Sanstha Maryadit", label: "Shri Ravalnath Gramin Biger Sheti Sahkari Path Sanstha Maryadit" },
  { value: "Shri Dnyaneshwar Gramin Biger Sheti Sahkari Path Sanstha Maryadit", label: "Shri Dnyaneshwar Gramin Biger Sheti Sahkari Path Sanstha Maryadit" },
  { value: "Tudaye Gramin Biger Sheti Sahkari Path Sanstha Maryadit", label: "Tudaye Gramin Biger Sheti Sahkari Path Sanstha Maryadit" },
  { value: "Rajiv Gandhi Gramin Biger Sheti Sahkari Path Sanstha Maryadit", label: "Rajiv Gandhi Gramin Biger Sheti Sahkari Path Sanstha Maryadit" },
  { value: "Khasbag Urban Co Op Cre Soc Ltd", label: "Khasbag Urban Co Op Cre Soc Ltd" },
  { value: "Moulana Azad Gramin Biger Sheti Sahkari Path Sanstha Maryadit", label: "Moulana Azad Gramin Biger Sheti Sahkari Path Sanstha Maryadit" },
  { value: "Shri Gajanan Gramin Biger Sheti Sahkari Path Sanstha Maryadit", label: "Shri Gajanan Gramin Biger Sheti Sahkari Path Sanstha Maryadit" },
  { value: "Sri Gokul Finance", label: "Sri Gokul Finance" },
  { value: "Sri Annamalai Consulting", label: "Sri Annamalai Consulting" },
  { value: "Arihant Finance Company", label: "Arihant Finance Company" },
  { value: "The Tapindu Urban Co Operative Bank Ltd", label: "The Tapindu Urban Co Operative Bank Ltd" },
  { value: "Pujyashri Guru Kanhayya Gra Big Sheti Patsanstha", label: "Pujyashri Guru Kanhayya Gra Big Sheti Patsanstha" },
  { value: "Karanje Gramin Biger Sheti Sahakari Pat Santha Maryadit", label: "Karanje Gramin Biger Sheti Sahakari Pat Santha Maryadit" },
  { value: "Ashok Trading Company", label: "Ashok Trading Company" },
  { value: "Ashok Motors", label: "Ashok Motors" },
  { value: "Jai Laxmi Investment", label: "Jai Laxmi Investment" },
  { value: "Ashok Investment", label: "Ashok Investment" },
  { value: "Kankaria Investment", label: "Kankaria Investment" },
  { value: "Abhishek Investment", label: "Abhishek Investment" },
  { value: "Abhishek Finance", label: "Abhishek Finance" },
  { value: "Shankheshwar Finance", label: "Shankheshwar Finance" },
  { value: "D C Finance", label: "D C Finance" },
  { value: "Asha Auto Finance", label: "Asha Auto Finance" },
  { value: "Shreenath Trading Co", label: "Shreenath Trading Co" },
  { value: "Riderz Finance Investments", label: "Riderz Finance Investments" },
  { value: "G Pawan Kumar Lalwani", label: "G Pawan Kumar Lalwani" },
  { value: "Ravitha Lalwani Finance", label: "Ravitha Lalwani Finance" },
  { value: "De Lage Landen Financial Services India Pvt Ltd", label: "De Lage Landen Financial Services India Pvt Ltd" },
  { value: "Bihar Gramin Bank", label: "Bihar Gramin Bank" },
  { value: "Shree Dhanalaxmi Multi Sah Sangh Niy", label: "Shree Dhanalaxmi Multi Sah Sangh Niy" },
  { value: "Kalpana Sethia Financier", label: "Kalpana Sethia Financier" },
  { value: "B G Finance", label: "B G Finance" },
  { value: "Vikas Finance", label: "Vikas Finance" },
  { value: "P Rajesh Finance", label: "P Rajesh Finance" },
  { value: "Khushboo Investment Financier", label: "Khushboo Investment Financier" },
  { value: "Anjuman E Mauje Imdad", label: "Anjuman E Mauje Imdad" },
  { value: "Yamkanmardi Balkrishna Sou Sah P S Ltd", label: "Yamkanmardi Balkrishna Sou Sah P S Ltd" },
  { value: "Shri Veerbhadreshwar Cr Sou Sah Ltd", label: "Shri Veerbhadreshwar Cr Sou Sah Ltd" },
  { value: "Adarsh Gramin Big Sheti Sah Patsanstha Mydt", label: "Adarsh Gramin Big Sheti Sah Patsanstha Mydt" },
  { value: "Om Multipurpose Sou Sah Nymt", label: "Om Multipurpose Sou Sah Nymt" },
  { value: "Shri Vitthalai Gramin Big Sheti Sah Path Sanstha Mydt", label: "Shri Vitthalai Gramin Big Sheti Sah Path Sanstha Mydt" },
  { value: "Shri Kamdhenu Nag Sah Path Sanstha Mydt", label: "Shri Kamdhenu Nag Sah Path Sanstha Mydt" },
  { value: "Chhatrapati Tararani Mahila Gramin Sah Path Sanstha Mydt", label: "Chhatrapati Tararani Mahila Gramin Sah Path Sanstha Mydt" },
  { value: "Vyapari Nagari Gramin Big Sheti Sah Path Sanstha Ltd", label: "Vyapari Nagari Gramin Big Sheti Sah Path Sanstha Ltd" },
  { value: "Shri Ram Nag Sah Path Sanstha Mydt", label: "Shri Ram Nag Sah Path Sanstha Mydt" },
  { value: "Shri Bal Hanuman Nag Sah Path Sanstha Mydt", label: "Shri Bal Hanuman Nag Sah Path Sanstha Mydt" },
  { value: "Sarjeraodada Naik Shirala Sah Bank Ltd", label: "Sarjeraodada Naik Shirala Sah Bank Ltd" },
  { value: "Sangli Traders Co Op Cre Society Ltd", label: "Sangli Traders Co Op Cre Society Ltd" },
  { value: "Shri Bhaveshwari Mahila Multipurpose Co Op Soc Ltd", label: "Shri Bhaveshwari Mahila Multipurpose Co Op Soc Ltd" },
  { value: "Janseva Lokmanya Multipurpose Co Op Society", label: "Janseva Lokmanya Multipurpose Co Op Society" },
  { value: "Shrimant Automobile", label: "Shrimant Automobile" },
  { value: "Chennai Petroleum Corporation Ltd", label: "Chennai Petroleum Corporation Ltd" },
  { value: "The Bodiya Seva Sahkari Mandali Ltd", label: "The Bodiya Seva Sahkari Mandali Ltd" },
  { value: "The Mingalpur Seva Sahkari Mandali", label: "The Mingalpur Seva Sahkari Mandali" },
  { value: "B G M Finance", label: "B G M Finance" },
  { value: "Nirmala Investments", label: "Nirmala Investments" },
  { value: "Roop Investments", label: "Roop Investments" },
  { value: "V L M Enterprises", label: "V L M Enterprises" },
  { value: "Naganur Urban Credit Souhard Sahakari Niyamit", label: "Naganur Urban Credit Souhard Sahakari Niyamit" },
  { value: "Dharam Investment", label: "Dharam Investment" },
  { value: "Yash Finance", label: "Yash Finance" },
  { value: "Bhatia Trading Co Ltd", label: "Bhatia Trading Co Ltd" },
  { value: "M And M Financial Service Ltd", label: "M And M Financial Service Ltd" },
  { value: "Kesav Motors", label: "Kesav Motors" },
  { value: "Thasra People Co Op Bank Ltd", label: "Thasra People Co Op Bank Ltd" },
  { value: "Chandan Motor Finance", label: "Chandan Motor Finance" },
  { value: "Modi Finance", label: "Modi Finance" },
  { value: "Bothra Trading Co", label: "Bothra Trading Co" },
  { value: "Jimmy Motors", label: "Jimmy Motors" },
  { value: "Shree Motors", label: "Shree Motors" },
  { value: "Sajan Credit Corp", label: "Sajan Credit Corp" },
  { value: "Ajitnath Corp", label: "Ajitnath Corp" },
  { value: "Karanwati Trading Co", label: "Karanwati Trading Co" },
  { value: "Shrishanthanti Guru Finance", label: "Shrishanthanti Guru Finance" },
  { value: "Balwant Motors", label: "Balwant Motors" },
  { value: "Mayur Motors", label: "Mayur Motors" },
  { value: "Raj Trading Co", label: "Raj Trading Co" },
  { value: "Chiplun Taluka Co Op Credit Society Ltd", label: "Chiplun Taluka Co Op Credit Society Ltd" },
  { value: "Ganpati Finance", label: "Ganpati Finance" },
  { value: "Hudco Ltd", label: "Hudco Ltd" },
  { value: "Ganpati Namdev Bawane Nag Sah Path Sanstha Mydt", label: "Ganpati Namdev Bawane Nag Sah Path Sanstha Mydt" },
  { value: "Jai Shivrai Gra Big Sheti Sah Path Sanstha Mydt", label: "Jai Shivrai Gra Big Sheti Sah Path Sanstha Mydt" },
  { value: "Kaveri Grameena Bank", label: "Kaveri Grameena Bank" },
  { value: "The Unargadh Seva Sahkari Mandali", label: "The Unargadh Seva Sahkari Mandali" },
  { value: "The Janseva Co Op Credit Society Ltd", label: "The Janseva Co Op Credit Society Ltd" },
  { value: "The Dhanera Nagarik Sahakari Mandali Ltd", label: "The Dhanera Nagarik Sahakari Mandali Ltd" },
  { value: "Chandgad Taluka Thekedar Gram Biger Sheti Sah Pathsanstha Ltd", label: "Chandgad Taluka Thekedar Gram Biger Sheti Sah Pathsanstha Ltd" },
  { value: "Shri Mahalaxmi Co Op Cre Society Ltd", label: "Shri Mahalaxmi Co Op Cre Society Ltd" },
  { value: "Omkar Nag Sah Path Sanstha Mydt", label: "Omkar Nag Sah Path Sanstha Mydt" },
  { value: "Jawahar Nag Sah Path Sanstha Mydt", label: "Jawahar Nag Sah Path Sanstha Mydt" },
  { value: "Shri Vandevi Multipurpose Co Op Society Ltd", label: "Shri Vandevi Multipurpose Co Op Society Ltd" },
  { value: "The Khodal Seva Sahkari Mandali", label: "The Khodal Seva Sahkari Mandali" },
  { value: "M K Co Op Credit Society Ltd", label: "M K Co Op Credit Society Ltd" },
  { value: "Trinetra Housing And Lease Fin Pvt Ltd", label: "Trinetra Housing And Lease Fin Pvt Ltd" },
  { value: "Gagan Trading Co", label: "Gagan Trading Co" },
  { value: "Siddharth Investment", label: "Siddharth Investment" },
  { value: "Jain Auto Finance", label: "Jain Auto Finance" },
  { value: "Modi And Company", label: "Modi And Company" },
  { value: "Dhram Finance", label: "Dhram Finance" },
  { value: "Kamal Corp", label: "Kamal Corp" },
  { value: "Ambica Motors", label: "Ambica Motors" },
  { value: "Adeshwar Finance Co", label: "Adeshwar Finance Co" },
  { value: "New Varsha Lease Automobile Financiers", label: "New Varsha Lease Automobile Financiers" },
  { value: "R D Investment", label: "R D Investment" },
  { value: "Jain Motors", label: "Jain Motors" },
  { value: "Sunrise Enterprise", label: "Sunrise Enterprise" },
  { value: "D.N. Motors", label: "D.N. Motors" },
  { value: "Aarti Corp", label: "Aarti Corp" },
  { value: "Balasinor Vikas Co Op Credit Co Ltd.", label: "Balasinor Vikas Co Op Credit Co Ltd." },
  { value: "Laxmi Trading Co", label: "Laxmi Trading Co" },
  { value: "K K Finance", label: "K K Finance" },
  { value: "Shree Mahakali Auto Finance", label: "Shree Mahakali Auto Finance" },
  { value: "Archana Investment", label: "Archana Investment" },
  { value: "Chaudhary Finance", label: "Chaudhary Finance" },
  { value: "Shree Shyam Motors", label: "Shree Shyam Motors" },
  { value: "Mayur Finance", label: "Mayur Finance" },
  { value: "Mayur Marketing", label: "Mayur Marketing" },
  { value: "Raj Shree Motors", label: "Raj Shree Motors" },
  { value: "Mandot Finance Com", label: "Mandot Finance Com" },
  { value: "The Kheda People Co Op Bank Ltd", label: "The Kheda People Co Op Bank Ltd" },
  { value: "G K Trading Co", label: "G K Trading Co" },
  { value: "Siddh Shree Enter Prise", label: "Siddh Shree Enter Prise" },
  { value: "Bothra Finance", label: "Bothra Finance" },
  { value: "Savitribai Phule Mahila Gra Big She Sah Patsansta Mar", label: "Savitribai Phule Mahila Gra Big She Sah Patsansta Mar" },
  { value: "Ashapura Investment", label: "Ashapura Investment" },
  { value: "K.K. Finance", label: "K.K. Finance" },
  { value: "Krishna Investment", label: "Krishna Investment" },
  { value: "D.C. Finance", label: "D.C. Finance" },
  { value: "Bajaj Finance Co Ltd", label: "Bajaj Finance Co Ltd" },
  { value: "Shree Shyam Motors", label: "Shree Shyam Motors" },
  { value: "Arham Associates", label: "Arham Associates" },
  { value: "Eqitas Finance Pvt Ltd", label: "Eqitas Finance Pvt Ltd" },
  { value: "Maruti Finance", label: "Maruti Finance" },
  { value: "Rajesh Finlease", label: "Rajesh Finlease" },
  { value: "Jay Laxmi Investment", label: "Jay Laxmi Investment" },
  { value: "Navkar Fianance Co", label: "Navkar Fianance Co" },
  { value: "Shree Namokar Enterprise", label: "Shree Namokar Enterprise" },
  { value: "J K Corp", label: "J K Corp" },
  { value: "Shree Khodiyar Trading Co", label: "Shree Khodiyar Trading Co" },
  { value: "Mahalaxmi Finance", label: "Mahalaxmi Finance" },
  { value: "Bothra Auto Finance", label: "Bothra Auto Finance" },
  { value: "Ashi Kumar Mehta", label: "Ashi Kumar Mehta" },
  { value: "Jain Marketing", label: "Jain Marketing" },
  { value: "Ad Manum Finance", label: "Ad Manum Finance" },
  { value: "Bhairav Finance Company", label: "Bhairav Finance Company" },
  { value: "N A Finance", label: "N A Finance" },
  { value: "Shree Bharat Co Bank Ltd", label: "Shree Bharat Co Bank Ltd" },
  { value: "Manot Investment", label: "Manot Investment" },
  { value: "Durga Enterprise", label: "Durga Enterprise" },
  { value: "Shree Shramjivi Nagrik Sahkari Mandali Ltd.", label: "Shree Shramjivi Nagrik Sahkari Mandali Ltd." },
  { value: "Nidhi Corp", label: "Nidhi Corp" },
  { value: "Navkar Associate", label: "Navkar Associate" },
  { value: "Rajesh Finance Co", label: "Rajesh Finance Co" },
  { value: "Ashok Kumar Mehta", label: "Ashok Kumar Mehta" },
  { value: "Mayur Investment", label: "Mayur Investment" },
  { value: "Shree Motor", label: "Shree Motor" },
  { value: "Prakash And Co", label: "Prakash And Co" },
  { value: "Bothra Motors Finance", label: "Bothra Motors Finance" },
  { value: "Shanti Finstock", label: "Shanti Finstock" },
  { value: "Amit And Co", label: "Amit And Co" },
  { value: "Anjumane Mauje Imdad", label: "Anjumane Mauje Imdad" },
  { value: "Bothra Finlease Corp", label: "Bothra Finlease Corp" },
  { value: "Yash Auto Finance", label: "Yash Auto Finance" },
  { value: "Ashok Trading Co", label: "Ashok Trading Co" },
  { value: "Bhaghmar Care Pvt Ltd", label: "Bhaghmar Care Pvt Ltd" },
  { value: "Hitesh Investment", label: "Hitesh Investment" },
  { value: "Balwant Raj Motors", label: "Balwant Raj Motors" },
  { value: "Ankir Finance", label: "Ankir Finance" },
  { value: "Yogyata Enterprise", label: "Yogyata Enterprise" },
  { value: "Surabhi Finance", label: "Surabhi Finance" },
  { value: "Sanjay Auto Investment", label: "Sanjay Auto Investment" },
  { value: "K S Finance", label: "K S Finance" },
  { value: "Arihant Investment", label: "Arihant Investment" },
  { value: "The Gujarat State Co Op Ruler Developmenr Bank Ltd", label: "The Gujarat State Co Op Ruler Developmenr Bank Ltd" },
  { value: "Ankir Enterprise", label: "Ankir Enterprise" },
  { value: "Umiya Finance Corp", label: "Umiya Finance Corp" },
  { value: "J R Finance", label: "J R Finance" },
  { value: "Dedhiya Fin And Investment Corp", label: "Dedhiya Fin And Investment Corp" },
  { value: "Shri Vijalpore People Co Op Credit Soc Ltd", label: "Shri Vijalpore People Co Op Credit Soc Ltd" },
  { value: "Manish Finance Co", label: "Manish Finance Co" },
  { value: "Rajshree Motors", label: "Rajshree Motors" },
  { value: "Arvind Auto Finance", label: "Arvind Auto Finance" },
  { value: "The Baroda Central Co Op Bank Ltd", label: "The Baroda Central Co Op Bank Ltd" },
  { value: "K Raj Finance Co", label: "K Raj Finance Co" },
  { value: "The Sarvoday Sahkari Bank Ltd", label: "The Sarvoday Sahkari Bank Ltd" },
  { value: "K.S Finance", label: "K.S Finance" },
  { value: "Kapil Investment", label: "Kapil Investment" },
  { value: "Dn Motors", label: "Dn Motors" },
  { value: "The Tarapur Nagrik Co Op Credit Soc Ltd", label: "The Tarapur Nagrik Co Op Credit Soc Ltd" },
  { value: "Kalpatru Finance Corp", label: "Kalpatru Finance Corp" },
  { value: "Subh Laxmi Finance", label: "Subh Laxmi Finance" },
  { value: "Dangi Financiers", label: "Dangi Financiers" },
  { value: "Bajaj Fin Ltd", label: "Bajaj Fin Ltd" },
  { value: "Osiaji Motors", label: "Osiaji Motors" },
  { value: "The Yogeshwar Cr. Sahakari Manddal Ltd", label: "The Yogeshwar Cr. Sahakari Manddal Ltd" },
  { value: "Kewal Financiers", label: "Kewal Financiers" },
  { value: "Mutha Finance", label: "Mutha Finance" },
  { value: "Anand Finance Co Ltd", label: "Anand Finance Co Ltd" },
  { value: "Bengani Investment", label: "Bengani Investment" },
  { value: "F.M. Enterprise", label: "F.M. Enterprise" },
  { value: "Amrut And Co", label: "Amrut And Co" },
  { value: "Jain Associate", label: "Jain Associate" },
  { value: "Shri Sardar Patel Co Credit So Ltd", label: "Shri Sardar Patel Co Credit So Ltd" },
  { value: "Swa Amdar Sanjaysinh Gaikwad Gra Sah Path Sanstha Mydt", label: "Swa Amdar Sanjaysinh Gaikwad Gra Sah Path Sanstha Mydt" },
  { value: "Adarsh Gra Sah Path Sanstha Mydt", label: "Adarsh Gra Sah Path Sanstha Mydt" },
  { value: "Orix Auto Infrastructure Services Ltd", label: "Orix Auto Infrastructure Services Ltd" },
  { value: "Shri Khodiyar Trading Co", label: "Shri Khodiyar Trading Co" },
  { value: "Shree Rama Auto Deal", label: "Shree Rama Auto Deal" },
  { value: "Shri Shyam Motors", label: "Shri Shyam Motors" },
  { value: "Shree Shanwariya Trading Co", label: "Shree Shanwariya Trading Co" },
  { value: "Mandot Investment", label: "Mandot Investment" },
  { value: "The Malpur Nagrik Sahakari Bank Ltd", label: "The Malpur Nagrik Sahakari Bank Ltd" },
  { value: "Shri Namokar Enterprise", label: "Shri Namokar Enterprise" },
  { value: "The Sutex Co Op Bank Ltd", label: "The Sutex Co Op Bank Ltd" },
  { value: "Blwant Raj Motors", label: "Blwant Raj Motors" },
  { value: "Shree Ganesh Motors", label: "Shree Ganesh Motors" },
  { value: "Harsh Finance", label: "Harsh Finance" },
  { value: "Sardar Gunj Mercantile Co Op Bank Ltd", label: "Sardar Gunj Mercantile Co Op Bank Ltd" },
  { value: "Shree Morbi Vibhag Seva Sahkari Mandali Ltd", label: "Shree Morbi Vibhag Seva Sahkari Mandali Ltd" },
  { value: "The Co Op Agri Society", label: "The Co Op Agri Society" },
  { value: "Rishabh Enterprise", label: "Rishabh Enterprise" },
  { value: "Shiv Shakti Finance", label: "Shiv Shakti Finance" },
  { value: "R S Auto Co", label: "R S Auto Co" },
  { value: "Bothara Finance", label: "Bothara Finance" },
  { value: "Dhedhiya Finance And Investment Corp", label: "Dhedhiya Finance And Investment Corp" },
  { value: "Rishab Motors", label: "Rishab Motors" },
  { value: "Namokar Enterprise", label: "Namokar Enterprise" },
  { value: "Ushabh Investment", label: "Ushabh Investment" },
  { value: "Shree Namokar Finance", label: "Shree Namokar Finance" },
  { value: "Rathod Finance", label: "Rathod Finance" },
  { value: "The Karanatak Bank Ltd", label: "The Karanatak Bank Ltd" },
  { value: "Rishabh Corp", label: "Rishabh Corp" },
  { value: "M B Fincap", label: "M B Fincap" },
  { value: "Bhirav Finance Co", label: "Bhirav Finance Co" },
  { value: "Ganesh Finance", label: "Ganesh Finance" },
  { value: "Shri Rama Auto Deal", label: "Shri Rama Auto Deal" },
  { value: "Subhlaxmi Finance", label: "Subhlaxmi Finance" },
  { value: "Satguru Auto Fianance", label: "Satguru Auto Fianance" },
  { value: "K Rajat And Co", label: "K Rajat And Co" },
  { value: "Y And H Patel Fin Ltd", label: "Y And H Patel Fin Ltd" },
  { value: "Dugar Finance", label: "Dugar Finance" },
  { value: "The Janta Shasafi Sah. Mandli Ltd", label: "The Janta Shasafi Sah. Mandli Ltd" },
  { value: "Bithra Finance", label: "Bithra Finance" },
  { value: "The Mehlav Peoples Co Op Cre Soc Ltd", label: "The Mehlav Peoples Co Op Cre Soc Ltd" },
  { value: "Shri Ganesh Auto Finance", label: "Shri Ganesh Auto Finance" },
  { value: "Namokar Finance", label: "Namokar Finance" },
  { value: "Ishwar Krupa Auto Finance", label: "Ishwar Krupa Auto Finance" },
  { value: "Bhatia Finance Co", label: "Bhatia Finance Co" },
  { value: "Mandot Enterprise", label: "Mandot Enterprise" },
  { value: "The Posun Credit Co Op Soc Ltd", label: "The Posun Credit Co Op Soc Ltd" },
  { value: "Abhinandan Finance", label: "Abhinandan Finance" },
  { value: "Manish Investment", label: "Manish Investment" },
  { value: "Shree Mahavir Finance", label: "Shree Mahavir Finance" },
  { value: "Shah Misharilal Parasmal And Co", label: "Shah Misharilal Parasmal And Co" },
  { value: "Dugar Investment", label: "Dugar Investment" },
  { value: "M K Investment", label: "M K Investment" },
  { value: "Shree Shanti Guru Finance", label: "Shree Shanti Guru Finance" },
  { value: "Goyal Enterprize", label: "Goyal Enterprize" },
  { value: "Jain Enterprise", label: "Jain Enterprise" },
  { value: "Dena Gujrat Gramin Bank", label: "Dena Gujrat Gramin Bank" },
  { value: "Harsolia Finance Ltd", label: "Harsolia Finance Ltd" },
  { value: "Kanawati Trading Co", label: "Kanawati Trading Co" },
  { value: "Shri Ambika Finance", label: "Shri Ambika Finance" },
  { value: "Suvidha Co Op Soc Ltd", label: "Suvidha Co Op Soc Ltd" },
  { value: "Jr Finance", label: "Jr Finance" },
  { value: "Shree Ambika Finance", label: "Shree Ambika Finance" },
  { value: "Shri Mahavir Finance", label: "Shri Mahavir Finance" },
  { value: "Shree Shreenathaji Dhiran And Ghahak Sah Man Ltd", label: "Shree Shreenathaji Dhiran And Ghahak Sah Man Ltd" },
  { value: "Baghmar Fin Ltd", label: "Baghmar Fin Ltd" },
  { value: "Shriram Auto Deal", label: "Shriram Auto Deal" },
  { value: "Jai Laxmi Enterprise", label: "Jai Laxmi Enterprise" },
  { value: "The S K Dist Co Op Bank Ltd Sardoi", label: "The S K Dist Co Op Bank Ltd Sardoi" },
  { value: "Shbham Finance", label: "Shbham Finance" },
  { value: "Subham Finance", label: "Subham Finance" },
  { value: "Famuly Credit Ltd", label: "Famuly Credit Ltd" },
  { value: "C J Finance Ltd", label: "C J Finance Ltd" },
  { value: "Rishab Finance", label: "Rishab Finance" },
  { value: "Shri Sidhi Finance", label: "Shri Sidhi Finance" },
  { value: "Ajesh Finance Com", label: "Ajesh Finance Com" },
  { value: "Dm Finance", label: "Dm Finance" },
  { value: "K V Enterprise", label: "K V Enterprise" },
  { value: "Sajan Auto Investment", label: "Sajan Auto Investment" },
  { value: "Shri Namokar Finance", label: "Shri Namokar Finance" },
  { value: "Mauyr Marketing", label: "Mauyr Marketing" },
  { value: "The Sabarkantha Dit Co Op Bank Ltd", label: "The Sabarkantha Dit Co Op Bank Ltd" },
  { value: "Nagnechi Auto Finance", label: "Nagnechi Auto Finance" },
  { value: "Dhara Motor Finance Ltd", label: "Dhara Motor Finance Ltd" },
  { value: "Shri Mahalaxmi Multipurpose Co Op Bank Ltd", label: "Shri Mahalaxmi Multipurpose Co Op Bank Ltd" },
  { value: "Shriram Transport Finance Pvt Ltd", label: "Shriram Transport Finance Pvt Ltd" },
  { value: "The Maharashtre Co Op Credit Society Ltd", label: "The Maharashtre Co Op Credit Society Ltd" },
  { value: "Dunil Co Op Credit Soc Ltd", label: "Dunil Co Op Credit Soc Ltd" },
  { value: "Jamunalal Parakh Finance Pvt Ltd", label: "Jamunalal Parakh Finance Pvt Ltd" },
  { value: "Sri Gayatri Co Operative Urban Bank Limited", label: "Sri Gayatri Co Operative Urban Bank Limited" },
  { value: "Sri Senthoor Velan Finance", label: "Sri Senthoor Velan Finance" },
  { value: "Kanhoor Pathar Multistate Cp Op Credit Soc Ltd", label: "Kanhoor Pathar Multistate Cp Op Credit Soc Ltd" },
  { value: "Bambawade Nag Sah Path Sanstha Mydt", label: "Bambawade Nag Sah Path Sanstha Mydt" },
  { value: "Shri Shivashankar Urban Co Op Cr Society", label: "Shri Shivashankar Urban Co Op Cr Society" },
  { value: "Repco Bank Ltd", label: "Repco Bank Ltd" },
  { value: "Navnath Nag Sah Path Sanstha Mydt", label: "Navnath Nag Sah Path Sanstha Mydt" },
  { value: "Mahatma Fule Gra Big Sheti Sah Path Sanstha Mydt", label: "Mahatma Fule Gra Big Sheti Sah Path Sanstha Mydt" },
  { value: "The Patil Multipurpose Sou Sah Niyamit", label: "The Patil Multipurpose Sou Sah Niyamit" },
  { value: "Shri Soubhgyalaxmi Vivid Sou Sah Niyamit", label: "Shri Soubhgyalaxmi Vivid Sou Sah Niyamit" },
  { value: "Kodoli Vyapari Gra Sah Path Sanstha Mydt", label: "Kodoli Vyapari Gra Sah Path Sanstha Mydt" },
  { value: "Shri Shivajiraje Vyapari Nag Sah Path Sanstha Mydt", label: "Shri Shivajiraje Vyapari Nag Sah Path Sanstha Mydt" },
  { value: "Wagjai Co Op Credit Soc Ltd", label: "Wagjai Co Op Credit Soc Ltd" },
  { value: "Jai Mata Ji Co Op Credit Soc Ltd", label: "Jai Mata Ji Co Op Credit Soc Ltd" },
  { value: "Raje Shivaji Pat.Kanhur Pathar", label: "Raje Shivaji Pat.Kanhur Pathar" },
  { value: "Hinduja Leyland Fin Ser Ltd", label: "Hinduja Leyland Fin Ser Ltd" },
  { value: "Sukh Chain Finance Company Limited", label: "Sukh Chain Finance Company Limited" },
  { value: "The Bantra Co Operative Bank Ltd", label: "The Bantra Co Operative Bank Ltd" },
  { value: "United Urban Co Op Credit Society Ltd", label: "United Urban Co Op Credit Society Ltd" },
  { value: "Shri Revansiddheshwar Viv Udde Sah San Nymt", label: "Shri Revansiddheshwar Viv Udde Sah San Nymt" },
  { value: "Maratha Gra Big Sheti Sah Path Sanstha Mydt", label: "Maratha Gra Big Sheti Sah Path Sanstha Mydt" },
  { value: "Ashta Lokmanya Nagari Sahakari Pat Sanstha Maryadit", label: "Ashta Lokmanya Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Shiv Nagari Sahkari Pat Sanstha Ltd", label: "Shiv Nagari Sahkari Pat Sanstha Ltd" },
  { value: "Tarang Financiers Regd", label: "Tarang Financiers Regd" },
  { value: "Kalmeshwar Vividuddeshgal Sou Sah Nymt", label: "Kalmeshwar Vividuddeshgal Sou Sah Nymt" },
  { value: "Shri Parjanya Mahadev Nag Sah Path Sanstha Mydt", label: "Shri Parjanya Mahadev Nag Sah Path Sanstha Mydt" },
  { value: "Shri Baliraja Kurkun Gram Big Sheti Sah Pathsanstha Mydt", label: "Shri Baliraja Kurkun Gram Big Sheti Sah Pathsanstha Mydt" },
  { value: "Kodoli Nag Sah Path Sanstha Mydt", label: "Kodoli Nag Sah Path Sanstha Mydt" },
  { value: "Shri Chh Rajaram Timber Sah Pathsanstha Mydt", label: "Shri Chh Rajaram Timber Sah Pathsanstha Mydt" },
  { value: "Shri Bapuso Chougule Nag Sah Path Sanstha Mydt", label: "Shri Bapuso Chougule Nag Sah Path Sanstha Mydt" },
  { value: "The Terdal Urban Sou Credit Co Op Ltd", label: "The Terdal Urban Sou Credit Co Op Ltd" },
  { value: "Kranti Sena Multi Purpose Co Op Soc Ltd Khanapurkranti Sena Multi Purpose Co Op Soc Ltd", label: "Kranti Sena Multi Purpose Co Op Soc Ltd Khanapurkranti Sena Multi Purpose Co Op Soc Ltd" },
  { value: "Shri Vinayak Souharda Credit Cooperative Ltd", label: "Shri Vinayak Souharda Credit Cooperative Ltd" },
  { value: "Ujval Finance Coraporation", label: "Ujval Finance Coraporation" },
  { value: "Sugan Finance", label: "Sugan Finance" },
  { value: "Padam Finance And Co", label: "Padam Finance And Co" },
  { value: "Krushnarao Bhegade Nagari Sah Pat San Mar", label: "Krushnarao Bhegade Nagari Sah Pat San Mar" },
  { value: "Shriram Transport Finance Co Ltd", label: "Shriram Transport Finance Co Ltd" },
  { value: "Rajgad Co Op Credit Soc Ltd", label: "Rajgad Co Op Credit Soc Ltd" },
  { value: "Tulja Bhavani Gra Big Sheti Saha Path Sanstha", label: "Tulja Bhavani Gra Big Sheti Saha Path Sanstha" },
  { value: "The Vijay Urban Co Op Credit Soc Ltd", label: "The Vijay Urban Co Op Credit Soc Ltd" },
  { value: "Adarsha Jain Nagari Sah.Pat Ltd", label: "Adarsha Jain Nagari Sah.Pat Ltd" },
  { value: "Dr Shirish Patil Nagari Sah Pat Sanstha", label: "Dr Shirish Patil Nagari Sah Pat Sanstha" },
  { value: "Om Gurudev Gramin Bigarsheti Sah Pat Mar", label: "Om Gurudev Gramin Bigarsheti Sah Pat Mar" },
  { value: "Bharat Bhushan Sahakari Pat Mary", label: "Bharat Bhushan Sahakari Pat Mary" },
  { value: "The West Bengal State Co Operative Bank Ltd", label: "The West Bengal State Co Operative Bank Ltd" },
  { value: "Shri Mahaveer Multi Purp Souh Sah Nyt", label: "Shri Mahaveer Multi Purp Souh Sah Nyt" },
  { value: "Vijaya Lakshmi Auto Financiers", label: "Vijaya Lakshmi Auto Financiers" },
  { value: "The Windsor Bankers", label: "The Windsor Bankers" },
  { value: "Sanghavi Credit Corporation", label: "Sanghavi Credit Corporation" },
  { value: "Bellad Bagewdi Krishi Souhard Sahakari Sangh Niyamit", label: "Bellad Bagewdi Krishi Souhard Sahakari Sangh Niyamit" },
  { value: "N Dilip Kumar Challani Chennai", label: "N Dilip Kumar Challani Chennai" },
  { value: "Venkata Maruthi Hire Purchase And Finance", label: "Venkata Maruthi Hire Purchase And Finance" },
  { value: "East West Finance Co", label: "East West Finance Co" },
  { value: "Narmada Jhauba Gramin Bank", label: "Narmada Jhauba Gramin Bank" },
  { value: "Shanti Auto Finance", label: "Shanti Auto Finance" },
  { value: "Fingrowth Co Operative Bank Ltd", label: "Fingrowth Co Operative Bank Ltd" },
  { value: "M H Bafna And Co", label: "M H Bafna And Co" },
  { value: "Amity Charitable Trust", label: "Amity Charitable Trust" },
  { value: "Sagar Credits Ltd", label: "Sagar Credits Ltd" },
  { value: "Thenhipalam Co Op Rural Bank", label: "Thenhipalam Co Op Rural Bank" },
  { value: "Naresh Kumar Chhajer", label: "Naresh Kumar Chhajer" },
  { value: "Suvarna Leasing R", label: "Suvarna Leasing R" },
  { value: "K S B C D C Ltd", label: "K S B C D C Ltd" },
  { value: "The Uzhuva Service Co Operative Bank Ltd", label: "The Uzhuva Service Co Operative Bank Ltd" },
  { value: "Shri Tatyasaheb Mohite Nagri Sahakari Patpedhi Ltd", label: "Shri Tatyasaheb Mohite Nagri Sahakari Patpedhi Ltd" },
  { value: "Pune District Central Cooperative Bank Ltd", label: "Pune District Central Cooperative Bank Ltd" },
  { value: "Indian Infoline Finance Limited", label: "Indian Infoline Finance Limited" },
  { value: "Tata Capital Finance Services Ltd", label: "Tata Capital Finance Services Ltd" },
  { value: "The Aldona Urban Co Operative Credit Society Ltd", label: "The Aldona Urban Co Operative Credit Society Ltd" },
  { value: "Prabat Coop Credit Society Ltd", label: "Prabat Coop Credit Society Ltd" },
  { value: "Agash Auto Finance", label: "Agash Auto Finance" },
  { value: "Sivalaiya Finance", label: "Sivalaiya Finance" },
  { value: "Sivanantha Finance", label: "Sivanantha Finance" },
  { value: "Sri Siva Sakthi Finance", label: "Sri Siva Sakthi Finance" },
  { value: "Kalarcode Service Co Operative Bank Ltd", label: "Kalarcode Service Co Operative Bank Ltd" },
  { value: "The Jamboti Multi Purpose Co Op Society Ltd", label: "The Jamboti Multi Purpose Co Op Society Ltd" },
  { value: "The Lala Hanuman Ashoknagar Seva Sahakari Mandali", label: "The Lala Hanuman Ashoknagar Seva Sahakari Mandali" },
  { value: "The Jhund Seva Sahakari Mandali", label: "The Jhund Seva Sahakari Mandali" },
  { value: "Elumalaiyan Finance", label: "Elumalaiyan Finance" },
  { value: "Shiva Sakthi Finance", label: "Shiva Sakthi Finance" },
  { value: "Vigneshwara Finance Corporation", label: "Vigneshwara Finance Corporation" },
  { value: "Siemens Financial Services Pvt Ltd", label: "Siemens Financial Services Pvt Ltd" },
  { value: "Post And Telecom Bsnl", label: "Post And Telecom Bsnl" },
  { value: "Abivruddhi Finance And Investments Regd", label: "Abivruddhi Finance And Investments Regd" },
  { value: "India Infrastructure Finance Company Ltd Iifcl", label: "India Infrastructure Finance Company Ltd Iifcl" },
  { value: "Ponmagal Investments", label: "Ponmagal Investments" },
  { value: "K P Auto Finance", label: "K P Auto Finance" },
  { value: "A A 11 Kasapettai Pa Co Credit Society", label: "A A 11 Kasapettai Pa Co Credit Society" },
  { value: "Sree Abinaya Auto Finance", label: "Sree Abinaya Auto Finance" },
  { value: "Surya Motor Cresits", label: "Surya Motor Cresits" },
  { value: "Swasthik Auto Finance", label: "Swasthik Auto Finance" },
  { value: "Shree Sowbaghaya Finance", label: "Shree Sowbaghaya Finance" },
  { value: "Baldeep Finance Co. Regd", label: "Baldeep Finance Co. Regd" },
  { value: "Goverdhan Motors R", label: "Goverdhan Motors R" },
  { value: "Shree Birdev Finance Pvt Ltd", label: "Shree Birdev Finance Pvt Ltd" },
  { value: "Bhoruka Classic Finance Pvt Ltd", label: "Bhoruka Classic Finance Pvt Ltd" },
  { value: "Shri Basaveshwar Prathamika Krushi Patth Sah Sangh", label: "Shri Basaveshwar Prathamika Krushi Patth Sah Sangh" },
  { value: "Vaishno Motors And Finance", label: "Vaishno Motors And Finance" },
  { value: "Sahara India Financial Corporation Ltd", label: "Sahara India Financial Corporation Ltd" },
  { value: "Express Shipping Agency", label: "Express Shipping Agency" },
  { value: "Gyandeep Finance", label: "Gyandeep Finance" },
  { value: "Pushpak In Corp", label: "Pushpak In Corp" },
  { value: "Shree Nd Finance", label: "Shree Nd Finance" },
  { value: "Chennai Fin Cap", label: "Chennai Fin Cap" },
  { value: "Shikshak Sahakari Bank Ltd", label: "Shikshak Sahakari Bank Ltd" },
  { value: "Shri Sevalal Maharaj Co Op Credit Society Ltd", label: "Shri Sevalal Maharaj Co Op Credit Society Ltd" },
  { value: "Sree Vijay Auto Leasing", label: "Sree Vijay Auto Leasing" },
  { value: "New Pandher Finance Co Regd", label: "New Pandher Finance Co Regd" },
  { value: "Shree Subrahmanya Sahakara Sangha", label: "Shree Subrahmanya Sahakara Sangha" },
  { value: "Srivaari Thirumalai Finance", label: "Srivaari Thirumalai Finance" },
  { value: "The Yamkanmardi Laxmi Urban Cre Sou Sah Ltd", label: "The Yamkanmardi Laxmi Urban Cre Sou Sah Ltd" },
  { value: "Shri Gajanan Maharaj Co Op Cre Soc Ltd", label: "Shri Gajanan Maharaj Co Op Cre Soc Ltd" },
  { value: "Shri Vyapari Nag Sah Path Sanstha Mydt", label: "Shri Vyapari Nag Sah Path Sanstha Mydt" },
  { value: "Swami Vivekanand Urban Co Op Cre Soc Ltd", label: "Swami Vivekanand Urban Co Op Cre Soc Ltd" },
  { value: "Yamakanmardi Arihant Mul Pur Co Op Cre Soc Nymt", label: "Yamakanmardi Arihant Mul Pur Co Op Cre Soc Nymt" },
  { value: "Shri Basaveshwar Urban Cre Sou Sah Nymt", label: "Shri Basaveshwar Urban Cre Sou Sah Nymt" },
  { value: "Shri Basaveshwar Co Op Cre Sou Sah Nymt", label: "Shri Basaveshwar Co Op Cre Sou Sah Nymt" },
  { value: "Shree Keshav Govind Big Sheti Sahakari Path", label: "Shree Keshav Govind Big Sheti Sahakari Path" },
  { value: "Sri Shakthi Auto Finance", label: "Sri Shakthi Auto Finance" },
  { value: "Balaji And Co Finance", label: "Balaji And Co Finance" },
  { value: "Kamal And Co Finance", label: "Kamal And Co Finance" },
  { value: "The Agrasen Co Operative Urban Bank Limited", label: "The Agrasen Co Operative Urban Bank Limited" },
  { value: "Marathwada Vibhag Nagri Sahakari Patsantha", label: "Marathwada Vibhag Nagri Sahakari Patsantha" },
  { value: "L And T Fincorp Limited", label: "L And T Fincorp Limited" },
  { value: "The Mahaveer Co Op Bank Ltd", label: "The Mahaveer Co Op Bank Ltd" },
  { value: "Pragorssive Co Op Bank Ltd", label: "Pragorssive Co Op Bank Ltd" },
  { value: "Sri Bhramaramba Pattina Souharda Sahakari Niyamita", label: "Sri Bhramaramba Pattina Souharda Sahakari Niyamita" },
  { value: "Kamgar Ekta Nagari Sahakari Patpedhi Ltd", label: "Kamgar Ekta Nagari Sahakari Patpedhi Ltd" },
  { value: "Tvs Credit Ltd", label: "Tvs Credit Ltd" },
  { value: "Tourism Finance Corporation Of India Ltd", label: "Tourism Finance Corporation Of India Ltd" },
  { value: "The Satyaprakash Coop Cr So", label: "The Satyaprakash Coop Cr So" },
  { value: "Shree Shahir Parshram Maharaj Gramin Big Saha Patsanstha", label: "Shree Shahir Parshram Maharaj Gramin Big Saha Patsanstha" },
  { value: "Suvarnalaxmi Gramin Bigarshati Sahakari Patsanstha Matydit", label: "Suvarnalaxmi Gramin Bigarshati Sahakari Patsanstha Matydit" },
  { value: "Jitwal Finance Regd", label: "Jitwal Finance Regd" },
  { value: "Sri Sai Vishweshwara M", label: "Sri Sai Vishweshwara M" },
  { value: "M.S. Agency", label: "M.S. Agency" },
  { value: "Vishnu Sai Finance Corporation", label: "Vishnu Sai Finance Corporation" },
  { value: "Chopra Automobile Finance", label: "Chopra Automobile Finance" },
  { value: "Bharath And Company", label: "Bharath And Company" },
  { value: "Bhuvanajyothi Cre Sou Sah Ltd", label: "Bhuvanajyothi Cre Sou Sah Ltd" },
  { value: "Utkarsh And Company", label: "Utkarsh And Company" },
  { value: "Shri Hemalingeshwar Pattin Souharda Sahakari N", label: "Shri Hemalingeshwar Pattin Souharda Sahakari N" },
  { value: "Hari Ohm Shroff", label: "Hari Ohm Shroff" },
  { value: "Sri Suba Lakshmi Auto Finance", label: "Sri Suba Lakshmi Auto Finance" },
  { value: "Surana Finance", label: "Surana Finance" },
  { value: "The Tasgaon Urban Co Op Bank Ltd", label: "The Tasgaon Urban Co Op Bank Ltd" },
  { value: "Ing Vysya Bank Ltd", label: "Ing Vysya Bank Ltd" },
  { value: "Governor Of Tamilnadu Assistant Executive Engineer Pwd Irrigation Sub Division", label: "Governor Of Tamilnadu Assistant Executive Engineer Pwd Irrigation Sub Division" },
  { value: "Varalakshmi Financiers", label: "Varalakshmi Financiers" },
  { value: "Nemichand Finance Corporation", label: "Nemichand Finance Corporation" },
  { value: "Paras Auto Finance", label: "Paras Auto Finance" },
  { value: "Priya Auto Mobile Finance", label: "Priya Auto Mobile Finance" },
  { value: "Rishab Financiers", label: "Rishab Financiers" },
  { value: "Padmavathi Auto Mobile Finance", label: "Padmavathi Auto Mobile Finance" },
  { value: "Champalal Financiers", label: "Champalal Financiers" },
  { value: "Manidhari Auto Mobile Finance", label: "Manidhari Auto Mobile Finance" },
  { value: "Jithender Financers", label: "Jithender Financers" },
  { value: "Sri Rajarajeshwara Auto Finance", label: "Sri Rajarajeshwara Auto Finance" },
  { value: "Shivanjali Nagari Sahakari Patsanstha Ltd", label: "Shivanjali Nagari Sahakari Patsanstha Ltd" },
  { value: "Vishal Junnar Sahakari Patpedhi", label: "Vishal Junnar Sahakari Patpedhi" },
  { value: "Vishal Junnar Sahakari Patpedhi Ltd", label: "Vishal Junnar Sahakari Patpedhi Ltd" },
  { value: "Vishal Junner Sahakari Patpedhi Maryadeet", label: "Vishal Junner Sahakari Patpedhi Maryadeet" },
  { value: "Bavanimotors", label: "Bavanimotors" },
  { value: "Om Auto Finance", label: "Om Auto Finance" },
  { value: "Lokmanya Nag Sah Path Sanstha Mydt", label: "Lokmanya Nag Sah Path Sanstha Mydt" },
  { value: "The Kunabi G.B.S.P.S.Ltd", label: "The Kunabi G.B.S.P.S.Ltd" },
  { value: "Shri Uday Sah Pat Sanstha Mydt", label: "Shri Uday Sah Pat Sanstha Mydt" },
  { value: "The Yamkanmardi R.C.Vivid.Udd.Sah.San.Nymt", label: "The Yamkanmardi R.C.Vivid.Udd.Sah.San.Nymt" },
  { value: "Kumbhars Vividuddshgal Sou.Sah.Nymt", label: "Kumbhars Vividuddshgal Sou.Sah.Nymt" },
  { value: "Dhanshri G.B.S.S.P.S.Ltd", label: "Dhanshri G.B.S.S.P.S.Ltd" },
  { value: "A To Z Insurance Broking", label: "A To Z Insurance Broking" },
  { value: "Anirudh Insurance Advisory Services", label: "Anirudh Insurance Advisory Services" },
  { value: "Comptroller Uas G.K.V.K, Bangalore", label: "Comptroller Uas G.K.V.K, Bangalore" },
  { value: "The Ahmedabad District Co-Op Bank Ltd", label: "The Ahmedabad District Co-Op Bank Ltd" },
  { value: "Jagruti Co Op Credit Society Ltd", label: "Jagruti Co Op Credit Society Ltd" },
  { value: "Shree Malgangadevi Sahakari Patpedhi Ltd", label: "Shree Malgangadevi Sahakari Patpedhi Ltd" },
  { value: "17134468 Leasplan Ind Ltd", label: "17134468 Leasplan Ind Ltd" },
  { value: "1739 Thingalur Pacb Ltd", label: "1739 Thingalur Pacb Ltd" },
  { value: "20 Th Century Finance Cor.Ltd", label: "20 Th Century Finance Cor.Ltd" },
  { value: "2026 Nallampatti Pacb Ltd", label: "2026 Nallampatti Pacb Ltd" },
  { value: "3I Infotech Trusteeship Pvt Ltd", label: "3I Infotech Trusteeship Pvt Ltd" },
  { value: "9481 Karadivavi Pacb", label: "9481 Karadivavi Pacb" },
  { value: "A  K Investment", label: "A  K Investment" },
  { value: "A A Motors", label: "A A Motors" },
  { value: "A And H Patel Finance Pvt Ltd", label: "A And H Patel Finance Pvt Ltd" },
  { value: "A And K Enterprises", label: "A And K Enterprises" },
  { value: "A B Carz Private Limited", label: "A B Carz Private Limited" },
  { value: "A C F Limited", label: "A C F Limited" },
  { value: "A D B  Khajuha", label: "A D B  Khajuha" },
  { value: "A D Manum Finance Ltd", label: "A D Manum Finance Ltd" },
  { value: "A Duli Chand Chhajer", label: "A Duli Chand Chhajer" },
  { value: "A F G I S", label: "A F G I S" },
  { value: "A H Finance Corp ( R )", label: "A H Finance Corp ( R )" },
  { value: "A Hanumantha Kumar", label: "A Hanumantha Kumar" },
  { value: "A J Motors", label: "A J Motors" },
  { value: "A K Finance", label: "A K Finance" },
  { value: "A Kavitha Chhallani", label: "A Kavitha Chhallani" },
  { value: "A Laduram Dungarwal Financier", label: "A Laduram Dungarwal Financier" },
  { value: "A M Finance", label: "A M Finance" },
  { value: "A M Motors", label: "A M Motors" },
  { value: "A M Srinivasan", label: "A M Srinivasan" },
  { value: "A Nehanth Associates", label: "A Nehanth Associates" },
  { value: "A P Finance", label: "A P Finance" },
  { value: "A P Foods", label: "A P Foods" },
  { value: "A P R Consultants P Ltd", label: "A P R Consultants P Ltd" },
  { value: "A P S F C", label: "A P S F C" },
  { value: "A P S Financial Services.", label: "A P S Financial Services." },
  { value: "A P Vardhaman (Mahila) Co-Op Urban Bk Lt", label: "A P Vardhaman (Mahila) Co-Op Urban Bk Lt" },
  { value: "A R D B", label: "A R D B" },
  { value: "A R Grameena Vikas Bank", label: "A R Grameena Vikas Bank" },
  { value: "A R Jawahar Nahar", label: "A R Jawahar Nahar" },
  { value: "A R Jawaharlal", label: "A R Jawaharlal" },
  { value: "A R Jawaharlal & Sons", label: "A R Jawaharlal & Sons" },
  { value: "A R Jawaharlal Nahar & Sons", label: "A R Jawaharlal Nahar & Sons" },
  { value: "A R T Leasing Private Limited.", label: "A R T Leasing Private Limited." },
  { value: "A S K Inc", label: "A S K Inc" },
  { value: "A S Motors", label: "A S Motors" },
  { value: "A Sachdev Finance", label: "A Sachdev Finance" },
  { value: "A Scheduled Commercial Bank.", label: "A Scheduled Commercial Bank." },
  { value: "A Sumer Chand", label: "A Sumer Chand" },
  { value: "A To Z Insurance Broker Pvt Ltd", label: "A To Z Insurance Broker Pvt Ltd" },
  { value: "A U  Financer", label: "A U  Financer" },
  { value: "A U Finance Ltd", label: "A U Finance Ltd" },
  { value: "A U Financiers I Ltd", label: "A U Financiers I Ltd" },
  { value: "A U Financiers India Pvt Ltd", label: "A U Financiers India Pvt Ltd" },
  { value: "A V Finance", label: "A V Finance" },
  { value: "A W Employees Co-Op Credit Society And Bank Ltd", label: "A W Employees Co-Op Credit Society And Bank Ltd" },
  { value: "A. C. Cexl Cus. A.Nagar On Behalf Pre. Ind A’Nagar", label: "A. C. Cexl Cus. A.Nagar On Behalf Pre. Ind A’Nagar" },
  { value: "A. Dulchand Chhajer", label: "A. Dulchand Chhajer" },
  { value: "A. Rajendrakumar Bokdia", label: "A. Rajendrakumar Bokdia" },
  { value: "A. S. Industrial Corp.", label: "A. S. Industrial Corp." },
  { value: "A. S. Investment", label: "A. S. Investment" },
  { value: "A. S. K. Enterprises", label: "A. S. K. Enterprises" },
  { value: "A.A.526 Kinipalayam Pacb Ltd", label: "A.A.526 Kinipalayam Pacb Ltd" },
  { value: "A.A.I.", label: "A.A.I." },
  { value: "A.B. Finance", label: "A.B. Finance" },
  { value: "A.B.Singur Farmers Service C.O.Soc Ltd", label: "A.B.Singur Farmers Service C.O.Soc Ltd" },
  { value: "A.C.C. Bank", label: "A.C.C. Bank" },
  { value: "A.D.B", label: "A.D.B" },
  { value: "A.G. Motor Gen. Finince Co", label: "A.G. Motor Gen. Finince Co" },
  { value: "A.G.I.F", label: "A.G.I.F" },
  { value: "A.K.Invest", label: "A.K.Invest" },
  { value: "A.K.S. And Comp", label: "A.K.S. And Comp" },
  { value: "A.M Finance", label: "A.M Finance" },
  { value: "A.M Transport", label: "A.M Transport" },
  { value: "A.M.Finance", label: "A.M.Finance" },
  { value: "A.Muruga Das", label: "A.Muruga Das" },
  { value: "A.P. Vardhaman (Mahila) Co-Op Bank Ltd.", label: "A.P. Vardhaman (Mahila) Co-Op Bank Ltd." },
  { value: "A.P.Grameena Vikas Bank", label: "A.P.Grameena Vikas Bank" },
  { value: "A.P.Mahajans Co-Op Urban Bank Ltd", label: "A.P.Mahajans Co-Op Urban Bank Ltd" },
  { value: "A.P.S.T.C.F.C Ltd", label: "A.P.S.T.C.F.C Ltd" },
  { value: "A.R.K Auto Finance", label: "A.R.K Auto Finance" },
  { value: "A.S. Enterprise", label: "A.S. Enterprise" },
  { value: "A.S. Yousef", label: "A.S. Yousef" },
  { value: "A.S.And Co.", label: "A.S.And Co." },
  { value: "A.S.Motors", label: "A.S.Motors" },
  { value: "A.S.Y Financiers", label: "A.S.Y Financiers" },
  { value: "A.V Satyanarayana Automobile Financiers", label: "A.V Satyanarayana Automobile Financiers" },
  { value: "A.V. Enterprises", label: "A.V. Enterprises" },
  { value: "A.V. Financess India Pvt Ltd", label: "A.V. Financess India Pvt Ltd" },
  { value: "A/C L&T Finance Ltd.", label: "A/C L&T Finance Ltd." },
  { value: "Aa 206 Periya Koduvery Paccb Ltd", label: "Aa 206 Periya Koduvery Paccb Ltd" },
  { value: "Aa 309 Lakkapuram Pudur Pacb Ltd", label: "Aa 309 Lakkapuram Pudur Pacb Ltd" },
  { value: "Aa 511 Kandappa Gounder Valasu Pacb Ltd", label: "Aa 511 Kandappa Gounder Valasu Pacb Ltd" },
  { value: "Aa 520 Veerappampalayam Pacb Ltd", label: "Aa 520 Veerappampalayam Pacb Ltd" },
  { value: "Aa 534,Sinniampalayam Pacb Ltd", label: "Aa 534,Sinniampalayam Pacb Ltd" },
  { value: "Aa 543 Kampuliampatti Pacb", label: "Aa 543 Kampuliampatti Pacb" },
  { value: "Aa Ell Finance", label: "Aa Ell Finance" },
  { value: "Aa Investment Company", label: "Aa Investment Company" },
  { value: "Aa. 538 Vaipadi Pacb Ltd", label: "Aa. 538 Vaipadi Pacb Ltd" },
  { value: "Aa11 Modavandi Sathyamangalam Pacb", label: "Aa11 Modavandi Sathyamangalam Pacb" },
  { value: "Aabid Hussain", label: "Aabid Hussain" },
  { value: "Aacharya Shree Anand Rishiji Credit Society Ltd", label: "Aacharya Shree Anand Rishiji Credit Society Ltd" },
  { value: "Aadarsa Finance", label: "Aadarsa Finance" },
  { value: "Aadhar Co-Op Credit Socty.", label: "Aadhar Co-Op Credit Socty." },
  { value: "Aadhar Sahakari Patpedhi Ltd", label: "Aadhar Sahakari Patpedhi Ltd" },
  { value: "Aadhi Bagavan Auto Finance", label: "Aadhi Bagavan Auto Finance" },
  { value: "Aadhi Sakthi Finance", label: "Aadhi Sakthi Finance" },
  { value: "Aaell Finance", label: "Aaell Finance" },
  { value: "Aai (Iad) Chennai Airport", label: "Aai (Iad) Chennai Airport" },
  { value: "Aaiiadnscbi", label: "Aaiiadnscbi" },
  { value: "Aakar Sahakari Patpedhi Ltd", label: "Aakar Sahakari Patpedhi Ltd" },
  { value: "Aakriti Finance Ltd.", label: "Aakriti Finance Ltd." },
  { value: "Aalaya Bankers", label: "Aalaya Bankers" },
  { value: "Aanantham Finance", label: "Aanantham Finance" },
  { value: "Aanantham Finance,Bhavani", label: "Aanantham Finance,Bhavani" },
  { value: "Aaradhana Auto Finance", label: "Aaradhana Auto Finance" },
  { value: "Aaradhna Ur Co Op Cr So Ltd ,Surat", label: "Aaradhna Ur Co Op Cr So Ltd ,Surat" },
  { value: "Aaron Auto Finance", label: "Aaron Auto Finance" },
  { value: "Aaron Finance", label: "Aaron Finance" },
  { value: "Aarthi Devi Finance", label: "Aarthi Devi Finance" },
  { value: "Aarti Corporation", label: "Aarti Corporation" },
  { value: "Aarti Devi Sancheti", label: "Aarti Devi Sancheti" },
  { value: "Aarti Investmenst", label: "Aarti Investmenst" },
  { value: "Aarti Motor Finance", label: "Aarti Motor Finance" },
  { value: "Aarti Urban Co.Op. Credit Society Ltd.", label: "Aarti Urban Co.Op. Credit Society Ltd." },
  { value: "Aashapura Investment", label: "Aashapura Investment" },
  { value: "Aashapura Investment", label: "Aashapura Investment" },
  { value: "Aashish Finance", label: "Aashish Finance" },
  { value: "Aashit Auto Finance", label: "Aashit Auto Finance" },
  { value: "Aathira Hire Purchase", label: "Aathira Hire Purchase" },
  { value: "Aavkar Arben Co Op Soc.Ltd", label: "Aavkar Arben Co Op Soc.Ltd" },
  { value: "Aayush Finance", label: "Aayush Finance" },
  { value: "Ab Finance", label: "Ab Finance" },
  { value: "Abasaveshwar Co-Op Bank Ltd", label: "Abasaveshwar Co-Op Bank Ltd" },
  { value: "Abc Inc,", label: "Abc Inc," },
  { value: "Abdul Khadar K K", label: "Abdul Khadar K K" },
  { value: "Abdul Rahiman Nagar Services Cp Bank Ltd", label: "Abdul Rahiman Nagar Services Cp Bank Ltd" },
  { value: "Abdul Saleem P", label: "Abdul Saleem P" },
  { value: "Abdurahiman C P", label: "Abdurahiman C P" },
  { value: "Abhay Auto,Surat", label: "Abhay Auto,Surat" },
  { value: "Abhay Finance Co", label: "Abhay Finance Co" },
  { value: "Abhay Finance Corporation", label: "Abhay Finance Corporation" },
  { value: "Abhay Finance Mishri Karupa", label: "Abhay Finance Mishri Karupa" },
  { value: "Abhay Kumar Jain L N Puram  Banglore", label: "Abhay Kumar Jain L N Puram  Banglore" },
  { value: "Abhay Kumar Lea Finance Inuesmest Ltd", label: "Abhay Kumar Lea Finance Inuesmest Ltd" },
  { value: "Abhay Laxmi Co Op Credit Soc Ltd", label: "Abhay Laxmi Co Op Credit Soc Ltd" },
  { value: "Abhaykumar M Yadav", label: "Abhaykumar M Yadav" },
  { value: "Abhijit Dada Kadam Nagari Sahkari Patsanstha", label: "Abhijit Dada Kadam Nagari Sahkari Patsanstha" },
  { value: "Abhilash Finance", label: "Abhilash Finance" },
  { value: "Abhinandan Credit Corporation", label: "Abhinandan Credit Corporation" },
  { value: "Abhinandan Urban Co-Op Bank Ltd", label: "Abhinandan Urban Co-Op Bank Ltd" },
  { value: "Abhinav Co Op Bank Ltd", label: "Abhinav Co Op Bank Ltd" },
  { value: "Abhinav Gra Big Sheti Sahahakari Patsansta", label: "Abhinav Gra Big Sheti Sahahakari Patsansta" },
  { value: "Abhinav Mahajan", label: "Abhinav Mahajan" },
  { value: "Abhinav Path Sanstha", label: "Abhinav Path Sanstha" },
  { value: "Abhinav Sahakari Bank Ltd", label: "Abhinav Sahakari Bank Ltd" },
  { value: "Abhinav Sahakari Bank Ltd., Dombivli,Thane", label: "Abhinav Sahakari Bank Ltd., Dombivli,Thane" },
  { value: "Abhishek & Co", label: "Abhishek & Co" },
  { value: "Abhivrudhi Finance & Investment", label: "Abhivrudhi Finance & Investment" },
  { value: "Abhivrudhi Mahila Co-Op Bank Ltd", label: "Abhivrudhi Mahila Co-Op Bank Ltd" },
  { value: "Abhyudaya Co-Op.Bank Ltd., Mumbai", label: "Abhyudaya Co-Op.Bank Ltd., Mumbai" },
  { value: "Abhyudaya Credit C.O.Society Ltd", label: "Abhyudaya Credit C.O.Society Ltd" },
  { value: "Abhyudaya Credit Co-Op Society Ltd - Karkala.", label: "Abhyudaya Credit Co-Op Society Ltd - Karkala." },
  { value: "Abi Finance", label: "Abi Finance" },
  { value: "Abinandan G.S.S.", label: "Abinandan G.S.S." },
  { value: "Abinaya Auto Services", label: "Abinaya Auto Services" },
  { value: "Abinayaa Auto Finance", label: "Abinayaa Auto Finance" },
  { value: "Abiramam Co-Op. Urban Bank Ltd,", label: "Abiramam Co-Op. Urban Bank Ltd," },
  { value: "Abirami Auto Financiers", label: "Abirami Auto Financiers" },
  { value: "Abirami Finance", label: "Abirami Finance" },
  { value: "Abirami Financial Services (India) Ltd", label: "Abirami Financial Services (India) Ltd" },
  { value: "Abirami Hire Purchase Finance (P) Ltd", label: "Abirami Hire Purchase Finance (P) Ltd" },
  { value: "Abirayaa Auto Finance", label: "Abirayaa Auto Finance" },
  { value: "Abishek Finance", label: "Abishek Finance" },
  { value: "Abn Amro Bank N V", label: "Abn Amro Bank N V" },
  { value: "Abn Amro Finance Ltd", label: "Abn Amro Finance Ltd" },
  { value: "Abn-Amro Bank", label: "Abn-Amro Bank" },
  { value: "Abs Leasing And Finance ( India ) Ltd", label: "Abs Leasing And Finance ( India ) Ltd" },
  { value: "Abt Finance Ltd.", label: "Abt Finance Ltd." },
  { value: "Abu Dhabi Comm Bank L", label: "Abu Dhabi Comm Bank L" },
  { value: "Abu Dhabi Commercial Bank Ltd.", label: "Abu Dhabi Commercial Bank Ltd." },
  { value: "Abu Dhabi Commercial Bank Ltd.", label: "Abu Dhabi Commercial Bank Ltd." },
  { value: "Abyudaya Credit Co-Operative Society Ltd", label: "Abyudaya Credit Co-Operative Society Ltd" },
  { value: "Accountant Generals Office Co-Op Bank Ltd", label: "Accountant Generals Office Co-Op Bank Ltd" },
  { value: "Accountant Generals Office Employees Co-Op Bank", label: "Accountant Generals Office Employees Co-Op Bank" },
  { value: "Ace  Coop Bank Ltd", label: "Ace  Coop Bank Ltd" },
  { value: "Ace Co-Operative Bank Ltd", label: "Ace Co-Operative Bank Ltd" },
  { value: "Ace Finlease Pvt Ltd", label: "Ace Finlease Pvt Ltd" },
  { value: "Acg Financers Ltd", label: "Acg Financers Ltd" },
  { value: "Acg Leasing Ltd.", label: "Acg Leasing Ltd." },
  { value: "Acg Sons Finance Ltd", label: "Acg Sons Finance Ltd" },
  { value: "Acg Sons Financirs Ltd", label: "Acg Sons Financirs Ltd" },
  { value: "Achal Finance Ltd", label: "Achal Finance Ltd" },
  { value: "Achitam Marketing", label: "Achitam Marketing" },
  { value: "Achlapur Urban Co-Op Bank Ltd", label: "Achlapur Urban Co-Op Bank Ltd" },
  { value: "Acorn Finance Pvt. Ltd.", label: "Acorn Finance Pvt. Ltd." },
  { value: "Active Finance", label: "Active Finance" },
  { value: "Ad Manam Finance Co.Ltd.", label: "Ad Manam Finance Co.Ltd." },
  { value: "Ad Yashwantrao Chavan Gr Bi She Sps", label: "Ad Yashwantrao Chavan Gr Bi She Sps" },
  { value: "Ad-Manum Finance Ltd", label: "Ad-Manum Finance Ltd" },
  { value: "Adajan Nagrik Sahakari Banbk Ltd", label: "Adajan Nagrik Sahakari Banbk Ltd" },
  { value: "Adajan Nagrik Sahakari Bank Ltd.", label: "Adajan Nagrik Sahakari Bank Ltd." },
  { value: "Adajan Nagrik Sahakari Bank Ltd., Surat", label: "Adajan Nagrik Sahakari Bank Ltd., Surat" },
  { value: "Adam Investments", label: "Adam Investments" },
  { value: "Adarniya P D Patil Saheb Ltd", label: "Adarniya P D Patil Saheb Ltd" },
  { value: "Adarsh Bigar Sheti Sah. Patsanstha", label: "Adarsh Bigar Sheti Sah. Patsanstha" },
  { value: "Adarsh Co-Op Cr Soc Ltd", label: "Adarsh Co-Op Cr Soc Ltd" },
  { value: "Adarsh Co-Op Urban Bank Ltd", label: "Adarsh Co-Op Urban Bank Ltd" },
  { value: "Adarsh Co-Operative Bank Ltd", label: "Adarsh Co-Operative Bank Ltd" },
  { value: "Adarsh Credit Co-Operation Society Ltd", label: "Adarsh Credit Co-Operation Society Ltd" },
  { value: "Adarsh Financiers Pvt. Ltd.", label: "Adarsh Financiers Pvt. Ltd." },
  { value: "Adarsh General Motor Financers Pvt. Ltd.", label: "Adarsh General Motor Financers Pvt. Ltd." },
  { value: "Adarsh Janata Sahakari Bank Ltd.", label: "Adarsh Janata Sahakari Bank Ltd." },
  { value: "Adarsh Mahila Mercantile Coop Bank Ltd", label: "Adarsh Mahila Mercantile Coop Bank Ltd" },
  { value: "Adarsh Mahila Nagari Sahakari Bank M.", label: "Adarsh Mahila Nagari Sahakari Bank M." },
  { value: "Adarsh Nagari Sahkari Patsanstha Maryadit", label: "Adarsh Nagari Sahkari Patsanstha Maryadit" },
  { value: "Adarsh Sahakari Griha Nirman", label: "Adarsh Sahakari Griha Nirman" },
  { value: "Adarsh Sethi", label: "Adarsh Sethi" },
  { value: "Adarsh Tractor Co", label: "Adarsh Tractor Co" },
  { value: "Adarsha Consumer Co Operative Society Ltd.", label: "Adarsha Consumer Co Operative Society Ltd." },
  { value: "Adarsha Gramin Bigar Sheti Sahakari Patsanstha", label: "Adarsha Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Adarsha Janata Ur Co-Op Society Ltd", label: "Adarsha Janata Ur Co-Op Society Ltd" },
  { value: "Adarsha Motor Credit", label: "Adarsha Motor Credit" },
  { value: "Adarsha Pattana Sahakari Bank Niyamitha", label: "Adarsha Pattana Sahakari Bank Niyamitha" },
  { value: "Adayar Finance And Leasing Ltd.", label: "Adayar Finance And Leasing Ltd." },
  { value: "Adcc Bank Ltd", label: "Adcc Bank Ltd" },
  { value: "Add Truck Commercial (P) Ltd.", label: "Add Truck Commercial (P) Ltd." },
  { value: "Adeshwar Enterprises", label: "Adeshwar Enterprises" },
  { value: "Adeshwar Finance", label: "Adeshwar Finance" },
  { value: "Adeshwar Marketing", label: "Adeshwar Marketing" },
  { value: "Adeshwar Traders", label: "Adeshwar Traders" },
  { value: "Adeshwar Trading Co", label: "Adeshwar Trading Co" },
  { value: "Adhar Nag Sah Patsanstha", label: "Adhar Nag Sah Patsanstha" },
  { value: "Adhar Sahkari Patpedhi Ltd", label: "Adhar Sahkari Patpedhi Ltd" },
  { value: "Adharsha Bharathi Farmers Service Co-Operative Society Ltd", label: "Adharsha Bharathi Farmers Service Co-Operative Society Ltd" },
  { value: "Adheswar Finance", label: "Adheswar Finance" },
  { value: "Adhi Finance", label: "Adhi Finance" },
  { value: "Adhilaxmi Automobile Finance", label: "Adhilaxmi Automobile Finance" },
  { value: "Adhinath Enterprise", label: "Adhinath Enterprise" },
  { value: "Adhinath Enterprise", label: "Adhinath Enterprise" },
  { value: "Adhipan Finance", label: "Adhipan Finance" },
  { value: "Adhisakthi Capitals", label: "Adhisakthi Capitals" },
  { value: "Adhiyaman Grama Bank", label: "Adhiyaman Grama Bank" },
  { value: "Adhiyaman Gramin Bank Ltd", label: "Adhiyaman Gramin Bank Ltd" },
  { value: "Adhunik Bharat Finance Pvt Ltd", label: "Adhunik Bharat Finance Pvt Ltd" },
  { value: "Adhyapaka Co-Op. Bank Ltd.", label: "Adhyapaka Co-Op. Bank Ltd." },
  { value: "Adi Lakshmi Finance Corporation", label: "Adi Lakshmi Finance Corporation" },
  { value: "Adi Om Prakash", label: "Adi Om Prakash" },
  { value: "Adil Amanat Co-Operative Society", label: "Adil Amanat Co-Operative Society" },
  { value: "Adilabad District Central Co-Operative Bank Ltd.", label: "Adilabad District Central Co-Operative Bank Ltd." },
  { value: "Adim Jati Seva Sahkari Samiti Maryadit  Ashulkhar", label: "Adim Jati Seva Sahkari Samiti Maryadit  Ashulkhar" },
  { value: "Adinath Co-Op. Bank Ltd., Surat", label: "Adinath Co-Op. Bank Ltd., Surat" },
  { value: "Adinath Corporation", label: "Adinath Corporation" },
  { value: "Adinath Credit And Savings Co Op Society Ltd", label: "Adinath Credit And Savings Co Op Society Ltd" },
  { value: "Adinath Enterprise", label: "Adinath Enterprise" },
  { value: "Adinath Finance", label: "Adinath Finance" },
  { value: "Adinath Investment", label: "Adinath Investment" },
  { value: "Adinath Nagari Sahakari Patsantha Maryadit", label: "Adinath Nagari Sahakari Patsantha Maryadit" },
  { value: "Adinath Urban Co-Op Bank", label: "Adinath Urban Co-Op Bank" },
  { value: "Adirampattinam Co-Op. Urban Bank Ltd", label: "Adirampattinam Co-Op. Urban Bank Ltd" },
  { value: "Adithya Auto Finance", label: "Adithya Auto Finance" },
  { value: "Adithya Finance", label: "Adithya Finance" },
  { value: "Adithya Hire Purchase", label: "Adithya Hire Purchase" },
  { value: "Aditi Urban Society", label: "Aditi Urban Society" },
  { value: "Adity Finance", label: "Adity Finance" },
  { value: "Adity Finance Corporation Raichur", label: "Adity Finance Corporation Raichur" },
  { value: "Aditya Associates", label: "Aditya Associates" },
  { value: "Aditya Auto Financiers", label: "Aditya Auto Financiers" },
  { value: "Aditya Automboile Financiers", label: "Aditya Automboile Financiers" },
  { value: "Aditya Credits Co.", label: "Aditya Credits Co." },
  { value: "Aditya Finance Co.", label: "Aditya Finance Co." },
  { value: "Aditya Finance Corporation", label: "Aditya Finance Corporation" },
  { value: "Aditya Investors", label: "Aditya Investors" },
  { value: "Aditya Leasing Corporation", label: "Aditya Leasing Corporation" },
  { value: "Aditya Nagri Sahakari Patsanstha Maryadit", label: "Aditya Nagri Sahakari Patsanstha Maryadit" },
  { value: "Aditya Nanda", label: "Aditya Nanda" },
  { value: "Adivasi Vitta & Vikas Nigam", label: "Adivasi Vitta & Vikas Nigam" },
  { value: "Adlakha Finance Co.", label: "Adlakha Finance Co." },
  { value: "Admanum Finance Ltd", label: "Admanum Finance Ltd" },
  { value: "Admin Finance Ltd", label: "Admin Finance Ltd" },
  { value: "Admin Finance Ltd Indore", label: "Admin Finance Ltd Indore" },
  { value: "Administrative Staff College", label: "Administrative Staff College" },
  { value: "Ador Finance Ltd.", label: "Ador Finance Ltd." },
  { value: "Adrash Credit Co-Operetive Society Ltd.", label: "Adrash Credit Co-Operetive Society Ltd." },
  { value: "Adrsha Path Sanstha", label: "Adrsha Path Sanstha" },
  { value: "Ads Finance Co.", label: "Ads Finance Co." },
  { value: "Ads Finance Corp. (Regd)", label: "Ads Finance Corp. (Regd)" },
  { value: "Adsum Advisory Services", label: "Adsum Advisory Services" },
  { value: "Adursh Mahila Co-Op Credit Society Ltd.", label: "Adursh Mahila Co-Op Credit Society Ltd." },
  { value: "Adusumilli Finance Company", label: "Adusumilli Finance Company" },
  { value: "Advance India Insurance Services", label: "Advance India Insurance Services" },
  { value: "Advanced Level Telecommunication Training Centre", label: "Advanced Level Telecommunication Training Centre" },
  { value: "Advit Auto Finance (P) Ltd.", label: "Advit Auto Finance (P) Ltd." },
  { value: "Aeronautical Dive Corment Agency Ministry Of Defen", label: "Aeronautical Dive Corment Agency Ministry Of Defen" },
  { value: "Aeyanandli Nagari Sah Path Asnvstha Mrdt", label: "Aeyanandli Nagari Sah Path Asnvstha Mrdt" },
  { value: "Agadi Vyavasaya Seva Sahakari  Bank Niyamita", label: "Agadi Vyavasaya Seva Sahakari  Bank Niyamita" },
  { value: "Agartala Co Op Urban Bank Ltd", label: "Agartala Co Op Urban Bank Ltd" },
  { value: "Agartala Co-Op Urban Bank Ltd", label: "Agartala Co-Op Urban Bank Ltd" },
  { value: "Agarwal Enterprise", label: "Agarwal Enterprise" },
  { value: "Agarwal Motor Financer", label: "Agarwal Motor Financer" },
  { value: "Agashi Co-Op Credit Society", label: "Agashi Co-Op Credit Society" },
  { value: "Agasti Sangam Sahakari Patpedhi Ltd.", label: "Agasti Sangam Sahakari Patpedhi Ltd." },
  { value: "Aggarwal Fabrication & Engg Works.", label: "Aggarwal Fabrication & Engg Works." },
  { value: "Aggarwal Finance", label: "Aggarwal Finance" },
  { value: "Aggarwal Finance Company", label: "Aggarwal Finance Company" },
  { value: "Agile Insurance Brokers P Ltd", label: "Agile Insurance Brokers P Ltd" },
  { value: "Agis", label: "Agis" },
  { value: "Agl Finance And Udyog", label: "Agl Finance And Udyog" },
  { value: "Agmarknet", label: "Agmarknet" },
  { value: "Agni Banniraya Co-Operative Society", label: "Agni Banniraya Co-Operative Society" },
  { value: "Agni Banniraya Swamy Patthina Sahakara Sanga", label: "Agni Banniraya Swamy Patthina Sahakara Sanga" },
  { value: "Agra District Co.Operative Bank Ltd.", label: "Agra District Co.Operative Bank Ltd." },
  { value: "Agra Road Vyapari Nag Sah.Patsanstha Myd", label: "Agra Road Vyapari Nag Sah.Patsanstha Myd" },
  { value: "Agrasen Auto Finance", label: "Agrasen Auto Finance" },
  { value: "Agrasen Co-Op Urban Bank Ltd", label: "Agrasen Co-Op Urban Bank Ltd" },
  { value: "Agrasen Urban Co.Op Bank Ltd", label: "Agrasen Urban Co.Op Bank Ltd" },
  { value: "Agrawal Fiscal Pvt Ltd", label: "Agrawal Fiscal Pvt Ltd" },
  { value: "Agrawal Money Finance", label: "Agrawal Money Finance" },
  { value: "Agrawal Overseas ,Surat", label: "Agrawal Overseas ,Surat" },
  { value: "Agrawal Palstics", label: "Agrawal Palstics" },
  { value: "Agri&Rural Devp Bank Ltd", label: "Agri&Rural Devp Bank Ltd" },
  { value: "Agri&Rural Devp: Bank Ltd", label: "Agri&Rural Devp: Bank Ltd" },
  { value: "Agricultural And Processed Food Products Export Development Authority", label: "Agricultural And Processed Food Products Export Development Authority" },
  { value: "Agricultural Bank Finance", label: "Agricultural Bank Finance" },
  { value: "Agricultural Sava Sahakari Bank Ltd.", label: "Agricultural Sava Sahakari Bank Ltd." },
  { value: "Agriculture Co Op Bank Ltd", label: "Agriculture Co Op Bank Ltd" },
  { value: "Agro Indus Finance And Leasing India Ltd", label: "Agro Indus Finance And Leasing India Ltd" },
  { value: "Agrsen Againcies", label: "Agrsen Againcies" },
  { value: "Agvb Algapur Branch", label: "Agvb Algapur Branch" },
  { value: "Agwani Investment", label: "Agwani Investment" },
  { value: "Ah Finance Corp", label: "Ah Finance Corp" },
  { value: "Ahilayadevi Mahila Nagsahakari Co-Op Bank Ltd", label: "Ahilayadevi Mahila Nagsahakari Co-Op Bank Ltd" },
  { value: "Ahilyadevi Holkar N. S. P.", label: "Ahilyadevi Holkar N. S. P." },
  { value: "Ahilyadevi Holkar Nagri Sahakari Pathsanstha", label: "Ahilyadevi Holkar Nagri Sahakari Pathsanstha" },
  { value: "Ahilyadevi Nagari Sahakari Path Sanstha Maryadit", label: "Ahilyadevi Nagari Sahakari Path Sanstha Maryadit" },
  { value: "Ahilyadevi Urban Co-Op Bank Ltd", label: "Ahilyadevi Urban Co-Op Bank Ltd" },
  { value: "Ahilyadevi Urban Co-Op. Bank Ltd.", label: "Ahilyadevi Urban Co-Op. Bank Ltd." },
  { value: "Ahinsa Nagri Sahakari Pathsanstha Maryadit", label: "Ahinsa Nagri Sahakari Pathsanstha Maryadit" },
  { value: "Ahmedabad Dist. Co-Op Bank Ltd.", label: "Ahmedabad Dist. Co-Op Bank Ltd." },
  { value: "Ahmedabad Mercantile Co-Op. Bank Ltd.", label: "Ahmedabad Mercantile Co-Op. Bank Ltd." },
  { value: "Ahmedabad Peoples Co-Op Bank Ltd.", label: "Ahmedabad Peoples Co-Op Bank Ltd." },
  { value: "Ahmedabad Urban Co-Op Bank Ltd.", label: "Ahmedabad Urban Co-Op Bank Ltd." },
  { value: "Ahmedabad Vegi Gen Com Agent Co Op", label: "Ahmedabad Vegi Gen Com Agent Co Op" },
  { value: "Ahmednagar District Central Co-Operative Bank Ltd.", label: "Ahmednagar District Central Co-Operative Bank Ltd." },
  { value: "Ahmednagar Jilha Maratha Seva Nagari Sahakari Patsanstha", label: "Ahmednagar Jilha Maratha Seva Nagari Sahakari Patsanstha" },
  { value: "Ahmednagar Jilha Shri Sant Goroba Kaka", label: "Ahmednagar Jilha Shri Sant Goroba Kaka" },
  { value: "Ahmednagar Merchants Co-Op Bank Ltd", label: "Ahmednagar Merchants Co-Op Bank Ltd" },
  { value: "Ahmednagar Mirchi Merchant Nagri Sahakari Pathsanstha", label: "Ahmednagar Mirchi Merchant Nagri Sahakari Pathsanstha" },
  { value: "Ahmednagar Sahakari Bank Ltd., Mumbai", label: "Ahmednagar Sahakari Bank Ltd., Mumbai" },
  { value: "Ahmednagar Shahar Sahakari Bank Ltd.", label: "Ahmednagar Shahar Sahakari Bank Ltd." },
  { value: "Ahmednagar Urban Bank Ahmednagar", label: "Ahmednagar Urban Bank Ahmednagar" },
  { value: "Ahmednagar Zilha Prat. Shi. Sah.Bank Ltd", label: "Ahmednagar Zilha Prat. Shi. Sah.Bank Ltd" },
  { value: "Ahmednagar Zilla Maratha Seva Nagari Patsanstha Ahmednagar", label: "Ahmednagar Zilla Maratha Seva Nagari Patsanstha Ahmednagar" },
  { value: "Ahmenagar Sahakari Bank Ltd.", label: "Ahmenagar Sahakari Bank Ltd." },
  { value: "Aikaranadu Service Co-Op Bank Ltd.", label: "Aikaranadu Service Co-Op Bank Ltd." },
  { value: "Ainakidu Subramanya Pry Agrl Co-Op Bank Ltd", label: "Ainakidu Subramanya Pry Agrl Co-Op Bank Ltd" },
  { value: "Aio Automotive Pvt. Ltd.", label: "Aio Automotive Pvt. Ltd." },
  { value: "Air Corpn Employees Co-Op Bank Ltd", label: "Air Corpn Employees Co-Op Bank Ltd" },
  { value: "Air Corporation Co-Op Bank", label: "Air Corporation Co-Op Bank" },
  { value: "Air Corporation Employees Co-Op Bank Ltd", label: "Air Corporation Employees Co-Op Bank Ltd" },
  { value: "Air Force Group Ins.Scr.", label: "Air Force Group Ins.Scr." },
  { value: "Air India", label: "Air India" },
  { value: "Air India Charters Ltd.", label: "Air India Charters Ltd." },
  { value: "Air India Corporation", label: "Air India Corporation" },
  { value: "Air Travel Enterprises India Ltd", label: "Air Travel Enterprises India Ltd" },
  { value: "Airapuram Service Co-Op Bank Ltd,", label: "Airapuram Service Co-Op Bank Ltd," },
  { value: "Airline Allied Service Ltd.", label: "Airline Allied Service Ltd." },
  { value: "Airport Authority Of India", label: "Airport Authority Of India" },
  { value: "Airport Tourist Taxi Drivers And Owners Multipurposeco Op Society Ltd", label: "Airport Tourist Taxi Drivers And Owners Multipurposeco Op Society Ltd" },
  { value: "Airports Authority Of India Ltd.", label: "Airports Authority Of India Ltd." },
  { value: "Aishwarya Laxmi Leasing", label: "Aishwarya Laxmi Leasing" },
  { value: "Aiswaraya Finance", label: "Aiswaraya Finance" },
  { value: "Aiswarya Auto Finance", label: "Aiswarya Auto Finance" },
  { value: "Aiswarya Hire Purchase", label: "Aiswarya Hire Purchase" },
  { value: "Aiswarya Motors Finance", label: "Aiswarya Motors Finance" },
  { value: "Aivernad Primary Agricultural Co Bank Lt", label: "Aivernad Primary Agricultural Co Bank Lt" },
  { value: "Ajanta Instalment Ltd", label: "Ajanta Instalment Ltd" },
  { value: "Ajanta Motor Finance", label: "Ajanta Motor Finance" },
  { value: "Ajanta Urban Co Op Bank Ltd", label: "Ajanta Urban Co Op Bank Ltd" },
  { value: "Ajantha Instalments (P) Ltd", label: "Ajantha Instalments (P) Ltd" },
  { value: "Ajantha Urban Co-Op. Bank Ltd.", label: "Ajantha Urban Co-Op. Bank Ltd." },
  { value: "Ajanur Co-Op. Urban Bank Ltd.", label: "Ajanur Co-Op. Urban Bank Ltd." },
  { value: "Ajara Arban Co Op Bank Ltd", label: "Ajara Arban Co Op Bank Ltd" },
  { value: "Ajara Urban Co-Op Bank Ltd", label: "Ajara Urban Co-Op Bank Ltd" },
  { value: "Ajay Auto Finance", label: "Ajay Auto Finance" },
  { value: "Ajay Bafnad", label: "Ajay Bafnad" },
  { value: "Ajay Co-Op Credit Soc Ltd", label: "Ajay Co-Op Credit Soc Ltd" },
  { value: "Ajay Coop Credit Ltd", label: "Ajay Coop Credit Ltd" },
  { value: "Ajay Haip Purshase Comp.", label: "Ajay Haip Purshase Comp." },
  { value: "Ajay Harry", label: "Ajay Harry" },
  { value: "Ajay Hire Purchase And Leasing Co.", label: "Ajay Hire Purchase And Leasing Co." },
  { value: "Ajay Investment", label: "Ajay Investment" },
  { value: "Ajay Jain", label: "Ajay Jain" },
  { value: "Ajay Kumar Dugar", label: "Ajay Kumar Dugar" },
  { value: "Ajay Kumar Jain", label: "Ajay Kumar Jain" },
  { value: "Ajay Kumar Nahar", label: "Ajay Kumar Nahar" },
  { value: "Ajay M", label: "Ajay M" },
  { value: "Ajay Motors", label: "Ajay Motors" },
  { value: "Ajay Sharma", label: "Ajay Sharma" },
  { value: "Ajay Shree Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit", label: "Ajay Shree Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit" },
  { value: "Ajay Verma", label: "Ajay Verma" },
  { value: "Ajeet Kumar And Sons Finance", label: "Ajeet Kumar And Sons Finance" },
  { value: "Ajhani Investments I P Ltd", label: "Ajhani Investments I P Ltd" },
  { value: "Ajidas N.A", label: "Ajidas N.A" },
  { value: "Ajinka Yatra Mahila Sahakari Bank Ltd", label: "Ajinka Yatra Mahila Sahakari Bank Ltd" },
  { value: "Ajinkya Mahila Sahakari Bank Ltd", label: "Ajinkya Mahila Sahakari Bank Ltd" },
  { value: "Ajinkya Yatra Sahakari Bank", label: "Ajinkya Yatra Sahakari Bank" },
  { value: "Ajinkyatara Mahila Sahakari Bank Lsatara", label: "Ajinkyatara Mahila Sahakari Bank Lsatara" },
  { value: "Ajinkyatara Nagri Co-Op Soc Ltd.", label: "Ajinkyatara Nagri Co-Op Soc Ltd." },
  { value: "Ajinkyatara Ng.Sh.Path.", label: "Ajinkyatara Ng.Sh.Path." },
  { value: "Ajinkyatara Saha. Pathsanshta Maryadit", label: "Ajinkyatara Saha. Pathsanshta Maryadit" },
  { value: "Ajinkyatara Sahkari Bank Ltd.", label: "Ajinkyatara Sahkari Bank Ltd." },
  { value: "Ajinkyatarasohari Patsanstha Ltd", label: "Ajinkyatarasohari Patsanstha Ltd" },
  { value: "Ajit Finance Corporation", label: "Ajit Finance Corporation" },
  { value: "Ajit Kumar Dughar", label: "Ajit Kumar Dughar" },
  { value: "Ajit Motor Finance Co", label: "Ajit Motor Finance Co" },
  { value: "Ajit Motor Hire Purchase (P) Ltd.", label: "Ajit Motor Hire Purchase (P) Ltd." },
  { value: "Ajit Nagari Sahakari Bank Ltd.", label: "Ajit Nagari Sahakari Bank Ltd." },
  { value: "Ajit Nagari Sahkari Pat Sanstha Maryadit", label: "Ajit Nagari Sahkari Pat Sanstha Maryadit" },
  { value: "Ajit Nagarik Sahakari Bank Ltd", label: "Ajit Nagarik Sahakari Bank Ltd" },
  { value: "Ajit Sahakari Bank Ltd.", label: "Ajit Sahakari Bank Ltd." },
  { value: "Ajit Singh Sahni & Sons", label: "Ajit Singh Sahni & Sons" },
  { value: "Ajitdada Gramin Bigar Sheti Sah. Pathsanstha Maryadit", label: "Ajitdada Gramin Bigar Sheti Sah. Pathsanstha Maryadit" },
  { value: "Ajith Auto Finance", label: "Ajith Auto Finance" },
  { value: "Ajith Kumar", label: "Ajith Kumar" },
  { value: "Ajith Mal Kothari", label: "Ajith Mal Kothari" },
  { value: "Ajitha Finance", label: "Ajitha Finance" },
  { value: "Ajithraj Finance", label: "Ajithraj Finance" },
  { value: "Ajitnath Corporation", label: "Ajitnath Corporation" },
  { value: "Ajmani Enterprises", label: "Ajmani Enterprises" },
  { value: "Ajmani Hire Purchase & Investments Ltd", label: "Ajmani Hire Purchase & Investments Ltd" },
  { value: "Ajmani Investment (I) Pvt Ltd", label: "Ajmani Investment (I) Pvt Ltd" },
  { value: "Ajmani Investment Pvt. Ltd.", label: "Ajmani Investment Pvt. Ltd." },
  { value: "Ajmani Leasing And Finance Ltd", label: "Ajmani Leasing And Finance Ltd" },
  { value: "Ajmer Central Co-Operative Bank Ltd.", label: "Ajmer Central Co-Operative Bank Ltd." },
  { value: "Ajmer District Central Co-Operative Bank Ltd.", label: "Ajmer District Central Co-Operative Bank Ltd." },
  { value: "Ajmer Sahakari Bhumi Vikas Bank", label: "Ajmer Sahakari Bhumi Vikas Bank" },
  { value: "Ajoy Sinha Roy", label: "Ajoy Sinha Roy" },
  { value: "Ajra Urban Coop Bank Ltd", label: "Ajra Urban Coop Bank Ltd" },
  { value: "Ajsb", label: "Ajsb" },
  { value: "Ajwani Finance Investment India Pvt Ltd", label: "Ajwani Finance Investment India Pvt Ltd" },
  { value: "Ak Finance", label: "Ak Finance" },
  { value: "Akaansha Credit & Leasing Pvt Ltd.", label: "Akaansha Credit & Leasing Pvt Ltd." },
  { value: "Akal Finlease Pvt. Ltd.", label: "Akal Finlease Pvt. Ltd." },
  { value: "Akanksha Automobile Financiers", label: "Akanksha Automobile Financiers" },
  { value: "Akash Finance", label: "Akash Finance" },
  { value: "Akash Ganga Motor Hire Purchase", label: "Akash Ganga Motor Hire Purchase" },
  { value: "Akash Ganga Motor Pvt Ltd", label: "Akash Ganga Motor Pvt Ltd" },
  { value: "Akash Garga Motors", label: "Akash Garga Motors" },
  { value: "Akash House Of Tractors", label: "Akash House Of Tractors" },
  { value: "Akashganga Sharafi Mandali", label: "Akashganga Sharafi Mandali" },
  { value: "Akathethara Service Co-Op. Bank Ltd", label: "Akathethara Service Co-Op. Bank Ltd" },
  { value: "Akbar Insurance Agency Services Pvt Ltd", label: "Akbar Insurance Agency Services Pvt Ltd" },
  { value: "Akbar Online Booking Co Pvt Ltd", label: "Akbar Online Booking Co Pvt Ltd" },
  { value: "Akbar Travels Of India Pvt Ltd", label: "Akbar Travels Of India Pvt Ltd" },
  { value: "Akchaya Finance", label: "Akchaya Finance" },
  { value: "Akhand Aanand Co-Op. Bank Ltd", label: "Akhand Aanand Co-Op. Bank Ltd" },
  { value: "Akhanda Anand Co-Op. Bank Ltd., Surat", label: "Akhanda Anand Co-Op. Bank Ltd., Surat" },
  { value: "Akj Fincap Ltd", label: "Akj Fincap Ltd" },
  { value: "Akkalbai", label: "Akkalbai" },
  { value: "Akkamahadevi Mahila N. S. P. S. Mary", label: "Akkamahadevi Mahila N. S. P. S. Mary" },
  { value: "Akkamahadevi Mahila Sahakari Bnk.Niyamit", label: "Akkamahadevi Mahila Sahakari Bnk.Niyamit" },
  { value: "Akki-Alur Urban Co-Op Bank Ltd", label: "Akki-Alur Urban Co-Op Bank Ltd" },
  { value: "Akl Finance Corporation", label: "Akl Finance Corporation" },
  { value: "Akme Fintrade India Ltd.", label: "Akme Fintrade India Ltd." },
  { value: "Akniki Karamchari Suh, Sad. San. Indore", label: "Akniki Karamchari Suh, Sad. San. Indore" },
  { value: "Akola District Central Co-Operative Bank Ltd.", label: "Akola District Central Co-Operative Bank Ltd." },
  { value: "Akola Gramin Bank.", label: "Akola Gramin Bank." },
  { value: "Akola Janata Co-Op Bank", label: "Akola Janata Co-Op Bank" },
  { value: "Akola Janata Commercial Co-Op. Bank Ltd., Akola", label: "Akola Janata Commercial Co-Op. Bank Ltd., Akola" },
  { value: "Akola Janata Sahakari Bank Ltd.", label: "Akola Janata Sahakari Bank Ltd." },
  { value: "Akola Janta Commercial Co-Op Bank", label: "Akola Janta Commercial Co-Op Bank" },
  { value: "Akola Merchant Co-Op. Bank Ltd.", label: "Akola Merchant Co-Op. Bank Ltd." },
  { value: "Akola Urban Co-Op Bank Ltd.", label: "Akola Urban Co-Op Bank Ltd." },
  { value: "Akola Zilla Nagri Sahkari Patsanstha Ltd.", label: "Akola Zilla Nagri Sahkari Patsanstha Ltd." },
  { value: "Akot Shahar Nagari Sah. Pat. Sanstha", label: "Akot Shahar Nagari Sah. Pat. Sanstha" },
  { value: "Akot Urban Co-Op Bank Ltd", label: "Akot Urban Co-Op Bank Ltd" },
  { value: "Aks & Co", label: "Aks & Co" },
  { value: "Aksayaah Sri Auto Finance", label: "Aksayaah Sri Auto Finance" },
  { value: "Akshabi Financier", label: "Akshabi Financier" },
  { value: "Akshara Auto Finance Corp", label: "Akshara Auto Finance Corp" },
  { value: "Akshay Enterprises", label: "Akshay Enterprises" },
  { value: "Akshay Finance", label: "Akshay Finance" },
  { value: "Akshay Hire Purchase", label: "Akshay Hire Purchase" },
  { value: "Akshay Investments", label: "Akshay Investments" },
  { value: "Akshay Sahakari Pat Sanstha Maryadit", label: "Akshay Sahakari Pat Sanstha Maryadit" },
  { value: "Akshaya Co-Op Cardit Society Ltd.", label: "Akshaya Co-Op Cardit Society Ltd." },
  { value: "Akshaya Co-Op. Credit Society Ltd", label: "Akshaya Co-Op. Credit Society Ltd" },
  { value: "Akshaya Finance And Investments", label: "Akshaya Finance And Investments" },
  { value: "Akshaya Finance Services", label: "Akshaya Finance Services" },
  { value: "Akshaya Financials Services P Ltd", label: "Akshaya Financials Services P Ltd" },
  { value: "Akshayaa India Tours And Travels (P) Ltd", label: "Akshayaa India Tours And Travels (P) Ltd" },
  { value: "Akshit Enterprises", label: "Akshit Enterprises" },
  { value: "Akshmi Leasing", label: "Akshmi Leasing" },
  { value: "Akshya Finance And Investments", label: "Akshya Finance And Investments" },
  { value: "Akush Leasing Finance", label: "Akush Leasing Finance" },
  { value: "Al Ansar Co Op Credit Society Ltd", label: "Al Ansar Co Op Credit Society Ltd" },
  { value: "Al Barr Finance House Ltd (Al Baraka Finance House Limited)", label: "Al Barr Finance House Ltd (Al Baraka Finance House Limited)" },
  { value: "Al Finance", label: "Al Finance" },
  { value: "Al Finance And Agrawal Fiscal Pvt Ltd.", label: "Al Finance And Agrawal Fiscal Pvt Ltd." },
  { value: "Al-Ameen M & B Communities Cr. Co-Op. So", label: "Al-Ameen M & B Communities Cr. Co-Op. So" },
  { value: "Al-Iqra Credit Co-Op. Society Ltd.", label: "Al-Iqra Credit Co-Op. Society Ltd." },
  { value: "Alag Credits And Holdings Pvt Ltd.", label: "Alag Credits And Holdings Pvt Ltd." },
  { value: "Alagendren Finance Ltd.", label: "Alagendren Finance Ltd." },
  { value: "Alagu Auto Finance", label: "Alagu Auto Finance" },
  { value: "Alagu Auto Financiers", label: "Alagu Auto Financiers" },
  { value: "Alagu Finance", label: "Alagu Finance" },
  { value: "Alagu Financiers", label: "Alagu Financiers" },
  { value: "Alagu Lakshmi Finance", label: "Alagu Lakshmi Finance" },
  { value: "Alahabad Bank", label: "Alahabad Bank" },
  { value: "Alakhnanda Gramin Bank", label: "Alakhnanda Gramin Bank" },
  { value: "Alamgir Motor Finance Ltd.", label: "Alamgir Motor Finance Ltd." },
  { value: "Alamu & Co", label: "Alamu & Co" },
  { value: "Alang Credit & Holding Pvt Ltd", label: "Alang Credit & Holding Pvt Ltd" },
  { value: "Alang Motors", label: "Alang Motors" },
  { value: "Alangad Co-Op Bank Ltd.", label: "Alangad Co-Op Bank Ltd." },
  { value: "Alangar Finance", label: "Alangar Finance" },
  { value: "Alankar Auto Ltd", label: "Alankar Auto Ltd" },
  { value: "Alankar Credit Co Op Bank Ltd", label: "Alankar Credit Co Op Bank Ltd" },
  { value: "Alankar Finance & Chitfund Co.", label: "Alankar Finance & Chitfund Co." },
  { value: "Alankar Investments", label: "Alankar Investments" },
  { value: "Alappuza Dist. Central C-Op. Bank Ltd.", label: "Alappuza Dist. Central C-Op. Bank Ltd." },
  { value: "Alappuzha District Central Co-Operative Bank Ltd.", label: "Alappuzha District Central Co-Operative Bank Ltd." },
  { value: "Alarsa Nagrik Cooperative Credit Society", label: "Alarsa Nagrik Cooperative Credit Society" },
  { value: "Alavi Co-Op Bank Ltd.", label: "Alavi Co-Op Bank Ltd." },
  { value: "Alavi Co-Operative Bank Ltd", label: "Alavi Co-Operative Bank Ltd" },
  { value: "Alcatel Lucent India Ltd", label: "Alcatel Lucent India Ltd" },
  { value: "Ald Automotive Pvt Ltd", label: "Ald Automotive Pvt Ltd" },
  { value: "Ald Automotive Pvt Ltd.", label: "Ald Automotive Pvt Ltd." },
  { value: "Ale Service Co-Op Bank Ltd", label: "Ale Service Co-Op Bank Ltd" },
  { value: "Alen Co-Op.Bank Ltd., Mumbai", label: "Alen Co-Op.Bank Ltd., Mumbai" },
  { value: "Alert Insurance Brokers Private Limited", label: "Alert Insurance Brokers Private Limited" },
  { value: "Alfa Division Of Indusland Bank", label: "Alfa Division Of Indusland Bank" },
  { value: "Alfc Adivise Of Indusind Bank Ltd, Ongole", label: "Alfc Adivise Of Indusind Bank Ltd, Ongole" },
  { value: "Alfl", label: "Alfl" },
  { value: "Alfl Of Division Indusind Bank", label: "Alfl Of Division Indusind Bank" },
  { value: "Algemene Bank Nederland", label: "Algemene Bank Nederland" },
  { value: "Alhind Tours And Travels", label: "Alhind Tours And Travels" },
  { value: "Alibag Co-Operative Urban Bank Ltd.", label: "Alibag Co-Operative Urban Bank Ltd." },
  { value: "Alibag Urban Co-Op Bank Ltd", label: "Alibag Urban Co-Op Bank Ltd" },
  { value: "Aligarh Muslim University", label: "Aligarh Muslim University" },
  { value: "Aligarh Zila Sahakari Bank Ltd.", label: "Aligarh Zila Sahakari Bank Ltd." },
  { value: "Alipurduar Co-Op Agri & Rural Dev. B L", label: "Alipurduar Co-Op Agri & Rural Dev. B L" },
  { value: "Aliya Air Travels Pvt Ltd", label: "Aliya Air Travels Pvt Ltd" },
  { value: "Alkaben Yogesh Trivedi", label: "Alkaben Yogesh Trivedi" },
  { value: "Alkuti Gramin Bigar Sheti Sahakari Patsanstha", label: "Alkuti Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Alkuti Gramin Biger Sheti Sahakari Pathasanstha", label: "Alkuti Gramin Biger Sheti Sahakari Pathasanstha" },
  { value: "All India Institute Of Medical Sciences", label: "All India Institute Of Medical Sciences" },
  { value: "All India Radio", label: "All India Radio" },
  { value: "Allabax Mohamed Shaikh", label: "Allabax Mohamed Shaikh" },
  { value: "Allahabad Bank & Joint Direct Of Indsutries(Mmr)", label: "Allahabad Bank & Joint Direct Of Indsutries(Mmr)" },
  { value: "Allahabad Bank & Joint Direct Of Industries", label: "Allahabad Bank & Joint Direct Of Industries" },
  { value: "Allahabad District Co- Op Bank Ltd", label: "Allahabad District Co- Op Bank Ltd" },
  { value: "Allahabad Gramin Bank", label: "Allahabad Gramin Bank" },
  { value: "Allahabad U P Gramin Bank", label: "Allahabad U P Gramin Bank" },
  { value: "Allahabad U P Gramin Bank", label: "Allahabad U P Gramin Bank" },
  { value: "Allahabad University", label: "Allahabad University" },
  { value: "Allasahah Minority Urban Co Op Society", label: "Allasahah Minority Urban Co Op Society" },
  { value: "Alleppey Co Operative Dev Bank Ltd", label: "Alleppey Co Operative Dev Bank Ltd" },
  { value: "Alleppey Co-Operative Agricultural And Rural Development Bank Ltd", label: "Alleppey Co-Operative Agricultural And Rural Development Bank Ltd" },
  { value: "Alliance Ins. Brokers Pvt. Ltd.", label: "Alliance Ins. Brokers Pvt. Ltd." },
  { value: "Alliance Venture Capital Advisors Limited", label: "Alliance Venture Capital Advisors Limited" },
  { value: "Allied Finance Co.", label: "Allied Finance Co." },
  { value: "Allied Finance Cor", label: "Allied Finance Cor" },
  { value: "Almar Oil & Finance Ltd", label: "Almar Oil & Finance Ltd" },
  { value: "Almel Urban Co Op Bank Ltd", label: "Almel Urban Co Op Bank Ltd" },
  { value: "Almora Urban Co-Op Bank Ltd", label: "Almora Urban Co-Op Bank Ltd" },
  { value: "Almora Urban Co-Operative Bank Ltd.", label: "Almora Urban Co-Operative Bank Ltd." },
  { value: "Almora Zila Sahkari Bank Ltd.", label: "Almora Zila Sahkari Bank Ltd." },
  { value: "Almore Zila Sahkari Bank Limited", label: "Almore Zila Sahkari Bank Limited" },
  { value: "Alna Finance", label: "Alna Finance" },
  { value: "Alnavar Urban Co-Op Bank Ltd", label: "Alnavar Urban Co-Op Bank Ltd" },
  { value: "Alok Finance Corporation", label: "Alok Finance Corporation" },
  { value: "Alok Gupta", label: "Alok Gupta" },
  { value: "Aloor-Harkooru Vyavasaya Seva Sah Bank N", label: "Aloor-Harkooru Vyavasaya Seva Sah Bank N" },
  { value: "Alpha Motor Finance", label: "Alpha Motor Finance" },
  { value: "Alpha Motor Finance Limited", label: "Alpha Motor Finance Limited" },
  { value: "Alpha Motors Finance Ltd.", label: "Alpha Motors Finance Ltd." },
  { value: "Alpic Finance Ltd.", label: "Alpic Finance Ltd." },
  { value: "Alta Leasing And Finance Ltd.", label: "Alta Leasing And Finance Ltd." },
  { value: "Aluf Ltd", label: "Aluf Ltd" },
  { value: "Alwar Anchalik Gramin Bank Ltd", label: "Alwar Anchalik Gramin Bank Ltd" },
  { value: "Alwar District Central Co-Operative Bank Ltd.", label: "Alwar District Central Co-Operative Bank Ltd." },
  { value: "Alwar Finance Co Ltd", label: "Alwar Finance Co Ltd" },
  { value: "Alwar Genral Finance Co. Ltd", label: "Alwar Genral Finance Co. Ltd" },
  { value: "Alwar Urban Co-Op. Bank Ltd.", label: "Alwar Urban Co-Op. Bank Ltd." },
  { value: "Alwar-Bharatpur Anchalik Gramin Bank", label: "Alwar-Bharatpur Anchalik Gramin Bank" },
  { value: "Alwaye Urban Co-Op Bank Ltd", label: "Alwaye Urban Co-Op Bank Ltd" },
  { value: "Am. Krushnarao Bhegade Nag", label: "Am. Krushnarao Bhegade Nag" },
  { value: "Amac Financial Service", label: "Amac Financial Service" },
  { value: "Amaldeep Enterprise", label: "Amaldeep Enterprise" },
  { value: "Amalgamated Plantation Pvt Ltd", label: "Amalgamated Plantation Pvt Ltd" },
  { value: "Amalner Co-Operative Urban Bank Ltd.", label: "Amalner Co-Operative Urban Bank Ltd." },
  { value: "Amalner Urban Co-Op Bank Ltd", label: "Amalner Urban Co-Op Bank Ltd" },
  { value: "Aman Chabra", label: "Aman Chabra" },
  { value: "Aman Chhate Vyapari Nagri Sahkari Pat Sanshta", label: "Aman Chhate Vyapari Nagri Sahkari Pat Sanshta" },
  { value: "Aman Finance", label: "Aman Finance" },
  { value: "Aman Majority Co-Op Credit Society", label: "Aman Majority Co-Op Credit Society" },
  { value: "Aman Minority Co -Op Credit Society", label: "Aman Minority Co -Op Credit Society" },
  { value: "Aman Trading Co", label: "Aman Trading Co" },
  { value: "Amanat Mahila Multipurpose Co-Op Society", label: "Amanat Mahila Multipurpose Co-Op Society" },
  { value: "Amanath Co-Op Bank Ltd", label: "Amanath Co-Op Bank Ltd" },
  { value: "Amandeep Singh", label: "Amandeep Singh" },
  { value: "Amar And Co", label: "Amar And Co" },
  { value: "Amar Auto Finance", label: "Amar Auto Finance" },
  { value: "Amar Fauji Motor And Gen Fin", label: "Amar Fauji Motor And Gen Fin" },
  { value: "Amar Fin Lease Pvt Ltd", label: "Amar Fin Lease Pvt Ltd" },
  { value: "Amar Finance Com. Ltd", label: "Amar Finance Com. Ltd" },
  { value: "Amar Finance Corporation", label: "Amar Finance Corporation" },
  { value: "Amar Financiers", label: "Amar Financiers" },
  { value: "Amar Finelax Pvt Ltd.", label: "Amar Finelax Pvt Ltd." },
  { value: "Amar Gurukrupa Insurance And Consultants", label: "Amar Gurukrupa Insurance And Consultants" },
  { value: "Amarapur Seva Sahakari Mandali Ltd.", label: "Amarapur Seva Sahakari Mandali Ltd." },
  { value: "Amarat Trading Company", label: "Amarat Trading Company" },
  { value: "Amarat Trading Copany", label: "Amarat Trading Copany" },
  { value: "Amaravathi Auto Finance", label: "Amaravathi Auto Finance" },
  { value: "Amaravathi Finance", label: "Amaravathi Finance" },
  { value: "Amaravathi Hire Purchase Financierss", label: "Amaravathi Hire Purchase Financierss" },
  { value: "Amaravati Zilla Prashikshak Sahakari Bank Ltd", label: "Amaravati Zilla Prashikshak Sahakari Bank Ltd" },
  { value: "Amardevi Nagari Sah Pat Maryadit", label: "Amardevi Nagari Sah Pat Maryadit" },
  { value: "Amarjeetkaur J. Chhatwal", label: "Amarjeetkaur J. Chhatwal" },
  { value: "Amarjeetsingh Sampuransingh Saluja Hp Pvt Ltd", label: "Amarjeetsingh Sampuransingh Saluja Hp Pvt Ltd" },
  { value: "Amarjit Finance Pvt. Ltd.", label: "Amarjit Finance Pvt. Ltd." },
  { value: "Amarnath Co-Op Bank Ltd", label: "Amarnath Co-Op Bank Ltd" },
  { value: "Amarnath Co-Op Bank Ltd", label: "Amarnath Co-Op Bank Ltd" },
  { value: "Amarpreet Finance Pvt. Ltd.", label: "Amarpreet Finance Pvt. Ltd." },
  { value: "Amarv Kawar Jain", label: "Amarv Kawar Jain" },
  { value: "Amba Auto Deals", label: "Amba Auto Deals" },
  { value: "Amba Auto Finance", label: "Amba Auto Finance" },
  { value: "Amba Leasing And Fin Co", label: "Amba Leasing And Fin Co" },
  { value: "Ambai Auto Credites", label: "Ambai Auto Credites" },
  { value: "Ambai Gramin Big Sheti Sah Pat Sant", label: "Ambai Gramin Big Sheti Sah Pat Sant" },
  { value: "Ambajogai Peoples Co-Op. Bank Ltd.", label: "Ambajogai Peoples Co-Op. Bank Ltd." },
  { value: "Ambajogai Peoples Co-Op Bank Ltd", label: "Ambajogai Peoples Co-Op Bank Ltd" },
  { value: "Ambala District Central Co-Operative Bank Ltd.", label: "Ambala District Central Co-Operative Bank Ltd." },
  { value: "Ambalapad Service Co.Op Bank Ltd", label: "Ambalapad Service Co.Op Bank Ltd" },
  { value: "Ambalapd Service C.O.B.L,Punnamparambu", label: "Ambalapd Service C.O.B.L,Punnamparambu" },
  { value: "Amballoor Janata Service Co-Op Bank Ltd,", label: "Amballoor Janata Service Co-Op Bank Ltd," },
  { value: "Ambap Vahan Dharak Nagari Sahkari Pat Sanstha Maryadit", label: "Ambap Vahan Dharak Nagari Sahkari Pat Sanstha Maryadit" },
  { value: "Ambarnath Jai Hind Co. Bank Ltd.", label: "Ambarnath Jai Hind Co. Bank Ltd." },
  { value: "Ambati Auto Financiers", label: "Ambati Auto Financiers" },
  { value: "Ambernath Jaihind  Co Op Bank", label: "Ambernath Jaihind  Co Op Bank" },
  { value: "Ambeshwar Gramin Bigarsheti Sahakari Patsansta", label: "Ambeshwar Gramin Bigarsheti Sahakari Patsansta" },
  { value: "Ambica Auto Finance", label: "Ambica Auto Finance" },
  { value: "Ambica Finanace", label: "Ambica Finanace" },
  { value: "Ambica Financiers", label: "Ambica Financiers" },
  { value: "Ambica Instalments (P) Ltd", label: "Ambica Instalments (P) Ltd" },
  { value: "Ambica Pattina Souharda Sahakari Niyamitha", label: "Ambica Pattina Souharda Sahakari Niyamitha" },
  { value: "Ambika Financiers", label: "Ambika Financiers" },
  { value: "Ambika Gramin Biger Sheti Pat. Sanstha.", label: "Ambika Gramin Biger Sheti Pat. Sanstha." },
  { value: "Ambika Installments Ltd.", label: "Ambika Installments Ltd." },
  { value: "Ambika Investments", label: "Ambika Investments" },
  { value: "Ambika Mahila Nagari Sah. Pat Sanstha Maryadit", label: "Ambika Mahila Nagari Sah. Pat Sanstha Maryadit" },
  { value: "Ambika Mahila Sahakari Bank Ltd", label: "Ambika Mahila Sahakari Bank Ltd" },
  { value: "Ambika Motors", label: "Ambika Motors" },
  { value: "Ambika Pattina Souharda Sahakari Niyamita", label: "Ambika Pattina Souharda Sahakari Niyamita" },
  { value: "Ambika Souharda Sahakari Niyamitha  Ankola", label: "Ambika Souharda Sahakari Niyamitha  Ankola" },
  { value: "Ambrosia Auto World Pvt Ltd", label: "Ambrosia Auto World Pvt Ltd" },
  { value: "Ambrosia Autoword(Pvt)Ltd", label: "Ambrosia Autoword(Pvt)Ltd" },
  { value: "Amdar Krushnarao Bhegade Nagari Pathsanstha", label: "Amdar Krushnarao Bhegade Nagari Pathsanstha" },
  { value: "Ameer Urban Co Op Bank Ltd", label: "Ameer Urban Co Op Bank Ltd" },
  { value: "Ameer Urban Co-Op Bank Ltd.", label: "Ameer Urban Co-Op Bank Ltd." },
  { value: "American Express Bank Limited", label: "American Express Bank Limited" },
  { value: "American Express Bank Ltd.", label: "American Express Bank Ltd." },
  { value: "Amidtown Ins. Services Pvt. Ltd.", label: "Amidtown Ins. Services Pvt. Ltd." },
  { value: "Amigo Travels Private Limited", label: "Amigo Travels Private Limited" },
  { value: "Amin Auto Agency", label: "Amin Auto Agency" },
  { value: "Amin Motors", label: "Amin Motors" },
  { value: "Amir Gundacha Sons", label: "Amir Gundacha Sons" },
  { value: "Amirtha Auto Finance", label: "Amirtha Auto Finance" },
  { value: "Amirtham Auto Finance", label: "Amirtham Auto Finance" },
  { value: "Amit & Co", label: "Amit & Co" },
  { value: "Amit & Co", label: "Amit & Co" },
  { value: "Amit And Tanwar Co.", label: "Amit And Tanwar Co." },
  { value: "Amit Auto Finance Chhatri Bari Assam Guwahati", label: "Amit Auto Finance Chhatri Bari Assam Guwahati" },
  { value: "Amit Dalal", label: "Amit Dalal" },
  { value: "Amit Finance Co.", label: "Amit Finance Co." },
  { value: "Amit Finance Leasing", label: "Amit Finance Leasing" },
  { value: "Amit Gundecha & Sons Finance", label: "Amit Gundecha & Sons Finance" },
  { value: "Amit Investment", label: "Amit Investment" },
  { value: "Amit J Mehta", label: "Amit J Mehta" },
  { value: "Amit Jain", label: "Amit Jain" },
  { value: "Amit Khotari Finance Corporation", label: "Amit Khotari Finance Corporation" },
  { value: "Amit Kumar Chhajed", label: "Amit Kumar Chhajed" },
  { value: "Amit Motors", label: "Amit Motors" },
  { value: "Amit Pahwa", label: "Amit Pahwa" },
  { value: "Amit Rupal", label: "Amit Rupal" },
  { value: "Amit Saxena", label: "Amit Saxena" },
  { value: "Amj Investments", label: "Amj Investments" },
  { value: "Amla Fin (P) Ltd", label: "Amla Fin (P) Ltd" },
  { value: "Ammaiyappar Investments", label: "Ammaiyappar Investments" },
  { value: "Amman Auto Finance", label: "Amman Auto Finance" },
  { value: "Amman Auto Financier", label: "Amman Auto Financier" },
  { value: "Amman Auto Leasing", label: "Amman Auto Leasing" },
  { value: "Amman Finance", label: "Amman Finance" },
  { value: "Amman Finance", label: "Amman Finance" },
  { value: "Amman Investments", label: "Amman Investments" },
  { value: "Ammapalayam Primary Agricultary Co-Op Bk", label: "Ammapalayam Primary Agricultary Co-Op Bk" },
  { value: "Amo Lakchand Jawarilal Financier", label: "Amo Lakchand Jawarilal Financier" },
  { value: "Amod Jain", label: "Amod Jain" },
  { value: "Amod Nagarik Co-Op Bank Ltd", label: "Amod Nagarik Co-Op Bank Ltd" },
  { value: "Ampar Co Op Agricultural Bank Ltd", label: "Ampar Co Op Agricultural Bank Ltd" },
  { value: "Ampar Co-Op Agricultural Bk Ltd.", label: "Ampar Co-Op Agricultural Bk Ltd." },
  { value: "Ampor Co-Op Agricultural Bank Ltd", label: "Ampor Co-Op Agricultural Bank Ltd" },
  { value: "Amr Investments", label: "Amr Investments" },
  { value: "Amrapali Co Op Cre Soc Ltd", label: "Amrapali Co Op Cre Soc Ltd" },
  { value: "Amraram", label: "Amraram" },
  { value: "Amrat & Co", label: "Amrat & Co" },
  { value: "Amrat Finance Corporation", label: "Amrat Finance Corporation" },
  { value: "Amravathi Motor Finance", label: "Amravathi Motor Finance" },
  { value: "Amravati Dis Cen Coop Bank Ltd,", label: "Amravati Dis Cen Coop Bank Ltd," },
  { value: "Amravati Merchants Co-Op Bank Ltd", label: "Amravati Merchants Co-Op Bank Ltd" },
  { value: "Amravati Zilla Mahila Sahakari Bank Ltd", label: "Amravati Zilla Mahila Sahakari Bank Ltd" },
  { value: "Amravati Zilla Mahila Sahakari Bank Ltd.", label: "Amravati Zilla Mahila Sahakari Bank Ltd." },
  { value: "Amraw Kawar Kothari Finance Co Ltd", label: "Amraw Kawar Kothari Finance Co Ltd" },
  { value: "Amrawati District Central Co-Operative Bank Ltd.", label: "Amrawati District Central Co-Operative Bank Ltd." },
  { value: "Amreily Gramin Bank Ltd", label: "Amreily Gramin Bank Ltd" },
  { value: "Amreli Dist. Central Co.Op. Bank Ltd", label: "Amreli Dist. Central Co.Op. Bank Ltd" },
  { value: "Amreli District Central Co-Operative Bank Ltd.", label: "Amreli District Central Co-Operative Bank Ltd." },
  { value: "Amreli Jilla Madhyastha Sahakari Bank Lt", label: "Amreli Jilla Madhyastha Sahakari Bank Lt" },
  { value: "Amreli Nagrik Sahakari Bank", label: "Amreli Nagrik Sahakari Bank" },
  { value: "Amrit Auto Finance", label: "Amrit Auto Finance" },
  { value: "Amrit Finance", label: "Amrit Finance" },
  { value: "Amrit Hire Purchase Ltd", label: "Amrit Hire Purchase Ltd" },
  { value: "Amrit Investment", label: "Amrit Investment" },
  { value: "Amritsar Central Co-Op Bank Ltd.", label: "Amritsar Central Co-Op Bank Ltd." },
  { value: "Amritsar District Central Co-Operative Bank Ltd.", label: "Amritsar District Central Co-Operative Bank Ltd." },
  { value: "Amroha Trade Fin Ltd.", label: "Amroha Trade Fin Ltd." },
  { value: "Amrudvahini Urban Co-Op Bank Ltd", label: "Amrudvahini Urban Co-Op Bank Ltd" },
  { value: "Amrut And Co.", label: "Amrut And Co." },
  { value: "Amrut Finance Corp", label: "Amrut Finance Corp" },
  { value: "Amrut Finance Corp", label: "Amrut Finance Corp" },
  { value: "Amrut Finance Corp", label: "Amrut Finance Corp" },
  { value: "Amrut Gramin Bigar Sheti Sahakari Pathsanstha Ltd", label: "Amrut Gramin Bigar Sheti Sahakari Pathsanstha Ltd" },
  { value: "Amrut Trading Company", label: "Amrut Trading Company" },
  { value: "Amrutanjan Finance Ltd.", label: "Amrutanjan Finance Ltd." },
  { value: "Amrutha Finance", label: "Amrutha Finance" },
  { value: "Amrutham Auto Finance", label: "Amrutham Auto Finance" },
  { value: "Amrutvahini Urban Co-Op Bank Ltd", label: "Amrutvahini Urban Co-Op Bank Ltd" },
  { value: "Amrutvail Nagri Sahakari Path Sanstha Maryadit", label: "Amrutvail Nagri Sahakari Path Sanstha Maryadit" },
  { value: "Amutha Surabi Finance", label: "Amutha Surabi Finance" },
  { value: "Amutham Finance", label: "Amutham Finance" },
  { value: "Amy Pharma", label: "Amy Pharma" },
  { value: "Ana Utkarsh Urban Co Op Credit Society", label: "Ana Utkarsh Urban Co Op Credit Society" },
  { value: "Anadi Trading And Investments Private Limited", label: "Anadi Trading And Investments Private Limited" },
  { value: "Anakkara Service Co-Op.Bk.L.", label: "Anakkara Service Co-Op.Bk.L." },
  { value: "Anam Finance Ltd.", label: "Anam Finance Ltd." },
  { value: "Anand Advances Co.", label: "Anand Advances Co." },
  { value: "Anand Auto Co Op Credit Society", label: "Anand Auto Co Op Credit Society" },
  { value: "Anand Finance Co. Ltd.", label: "Anand Finance Co. Ltd." },
  { value: "Anand Finance Corporation", label: "Anand Finance Corporation" },
  { value: "Anand Gramin Bigar Sheti Sahakari Patsanstha Maryadit", label: "Anand Gramin Bigar Sheti Sahakari Patsanstha Maryadit" },
  { value: "Anand Groups Finance Co", label: "Anand Groups Finance Co" },
  { value: "Anand Growth Fund", label: "Anand Growth Fund" },
  { value: "Anand Hire Purchase Associates", label: "Anand Hire Purchase Associates" },
  { value: "Anand Investments", label: "Anand Investments" },
  { value: "Anand Ishar Cr.Co-Op Society Ltd.", label: "Anand Ishar Cr.Co-Op Society Ltd." },
  { value: "Anand Kumar", label: "Anand Kumar" },
  { value: "Anand Kumar Jain Finance", label: "Anand Kumar Jain Finance" },
  { value: "Anand Kumar Jain Huf", label: "Anand Kumar Jain Huf" },
  { value: "Anand Lakshmi Finance Ltd.", label: "Anand Lakshmi Finance Ltd." },
  { value: "Anand Merchantile Co-Op Bank Ltd", label: "Anand Merchantile Co-Op Bank Ltd" },
  { value: "Anand Motors", label: "Anand Motors" },
  { value: "Anand Muthu Financier", label: "Anand Muthu Financier" },
  { value: "Anand Nagari Sahakari Bank Ltd", label: "Anand Nagari Sahakari Bank Ltd" },
  { value: "Anand Nagarik Co Op. Bank", label: "Anand Nagarik Co Op. Bank" },
  { value: "Anand Nagarik Co-Op.Credit Society Ltd.", label: "Anand Nagarik Co-Op.Credit Society Ltd." },
  { value: "Anand Nagri Sahakari Patsanstha Maryadit, Nashik Road", label: "Anand Nagri Sahakari Patsanstha Maryadit, Nashik Road" },
  { value: "Anand Nagrik Co Operattive Credit Society", label: "Anand Nagrik Co Operattive Credit Society" },
  { value: "Anand Peoples Co-Op Bank Ltd", label: "Anand Peoples Co-Op Bank Ltd" },
  { value: "Anand Sons Auto Finance Co.", label: "Anand Sons Auto Finance Co." },
  { value: "Anand Traders", label: "Anand Traders" },
  { value: "Anand Urban Co-Op Bank Ltd", label: "Anand Urban Co-Op Bank Ltd" },
  { value: "Ananda Co-Op Bank Ltd", label: "Ananda Co-Op Bank Ltd" },
  { value: "Ananda Co-Op. Bank Ltd", label: "Ananda Co-Op. Bank Ltd" },
  { value: "Anandeshwari Nagrik Sah. Bank Mydt.", label: "Anandeshwari Nagrik Sah. Bank Mydt." },
  { value: "Anandmurti Gramin Bigar Sheti Sahakari Pat Maryadit", label: "Anandmurti Gramin Bigar Sheti Sahakari Pat Maryadit" },
  { value: "Anandprem Nagari Sahakari Pathsanstha Maryadit", label: "Anandprem Nagari Sahakari Pathsanstha Maryadit" },
  { value: "Anandpur Sahib Urban Planning And Development Authority", label: "Anandpur Sahib Urban Planning And Development Authority" },
  { value: "Anandrao Gramin Bigar Sheti Pat Sanstha", label: "Anandrao Gramin Bigar Sheti Pat Sanstha" },
  { value: "Anant Gramin Bigar Sheti Sah Pat Maryadit", label: "Anant Gramin Bigar Sheti Sah Pat Maryadit" },
  { value: "Anant Leasing( R )", label: "Anant Leasing( R )" },
  { value: "Anant Nagari Pat Sanstha Ltd", label: "Anant Nagari Pat Sanstha Ltd" },
  { value: "Ananta Krupa Gramin Bigarsheti Sah Pat San Marya", label: "Ananta Krupa Gramin Bigarsheti Sah Pat San Marya" },
  { value: "Anantha Padmanaba Credit Co-Operative Society Ltd", label: "Anantha Padmanaba Credit Co-Operative Society Ltd" },
  { value: "Anantham Capitals - Velayutham Palayam.", label: "Anantham Capitals - Velayutham Palayam." },
  { value: "Anantkrupa Gramin Biger Sheti Sah", label: "Anantkrupa Gramin Biger Sheti Sah" },
  { value: "Anantnag District Central Co-Operative Bank Ltd.", label: "Anantnag District Central Co-Operative Bank Ltd." },
  { value: "Anantpur District Central Co-Operative Bank Ltd.", label: "Anantpur District Central Co-Operative Bank Ltd." },
  { value: "Ananya Auto Finance", label: "Ananya Auto Finance" },
  { value: "Anapanthy Services Bank Ltd.", label: "Anapanthy Services Bank Ltd." },
  { value: "Anas.S", label: "Anas.S" },
  { value: "Anavelsons Auto Fin. Co. Ltd.", label: "Anavelsons Auto Fin. Co. Ltd." },
  { value: "Anbu Finance Corporation", label: "Anbu Finance Corporation" },
  { value: "Anbu Murugan Finance", label: "Anbu Murugan Finance" },
  { value: "Anchit Finance Company", label: "Anchit Finance Company" },
  { value: "Anchit Finance Company", label: "Anchit Finance Company" },
  { value: "Andaman & Nicobar State Co-Op Bank Ltd", label: "Andaman & Nicobar State Co-Op Bank Ltd" },
  { value: "Andaman And Nicobar Central Co-Operative Bank Ltd.", label: "Andaman And Nicobar Central Co-Operative Bank Ltd." },
  { value: "Andaman And Nicobar Islands Forest And Plantation Development Crop. Ltd.", label: "Andaman And Nicobar Islands Forest And Plantation Development Crop. Ltd." },
  { value: "Andavar Finance", label: "Andavar Finance" },
  { value: "Andavar Group Finance", label: "Andavar Group Finance" },
  { value: "Andavar Group Motor Finance", label: "Andavar Group Motor Finance" },
  { value: "Andhra Finance", label: "Andhra Finance" },
  { value: "Andhra Pradesh Genco", label: "Andhra Pradesh Genco" },
  { value: "Andhra Pradesh Grameena Vikas Bank,", label: "Andhra Pradesh Grameena Vikas Bank," },
  { value: "Andhra Pradesh Gramina Vikasa Bank", label: "Andhra Pradesh Gramina Vikasa Bank" },
  { value: "Andhra Pradesh Industrial Development Corporation", label: "Andhra Pradesh Industrial Development Corporation" },
  { value: "Andhra Pradesh Infrastructure Corporation Ltd", label: "Andhra Pradesh Infrastructure Corporation Ltd" },
  { value: "Andhra Pradesh Power Generation Corporation Ltd", label: "Andhra Pradesh Power Generation Corporation Ltd" },
  { value: "Andhra Pradesh State Co-Op Bank Ltd.", label: "Andhra Pradesh State Co-Op Bank Ltd." },
  { value: "Andhra Pradesh State Financial Corporation", label: "Andhra Pradesh State Financial Corporation" },
  { value: "Andhra Pradesh State Tourism Development Corporation Ltd.", label: "Andhra Pradesh State Tourism Development Corporation Ltd." },
  { value: "Andhra Pradesh Transco", label: "Andhra Pradesh Transco" },
  { value: "Andhra Pradesh Transco Of Ltd", label: "Andhra Pradesh Transco Of Ltd" },
  { value: "Andhrapradesh Mahesh Co-Op Urban Bank Ltd.", label: "Andhrapradesh Mahesh Co-Op Urban Bank Ltd." },
  { value: "Andrew Yule And Company Ltd", label: "Andrew Yule And Company Ltd" },
  { value: "Andumesh Finance", label: "Andumesh Finance" },
  { value: "Aneesh Antony", label: "Aneesh Antony" },
  { value: "Ang Resources Ltd", label: "Ang Resources Ltd" },
  { value: "Angadi Service Co-Op. Bank Ltd.", label: "Angadi Service Co-Op. Bank Ltd." },
  { value: "Angalakurichi Malayappan Satheshba", label: "Angalakurichi Malayappan Satheshba" },
  { value: "Angalamman Auto Finance", label: "Angalamman Auto Finance" },
  { value: "Angalamman Finance Corporation", label: "Angalamman Finance Corporation" },
  { value: "Angamaly Service Co-Op Bank Ltd", label: "Angamaly Service Co-Op Bank Ltd" },
  { value: "Angul District Central Co-Operative Bank Ltd.", label: "Angul District Central Co-Operative Bank Ltd." },
  { value: "Anhanth Credit Corporation", label: "Anhanth Credit Corporation" },
  { value: "Ani Lafin (P) Ltd.", label: "Ani Lafin (P) Ltd." },
  { value: "Anickadu Co-Operative Bank", label: "Anickadu Co-Operative Bank" },
  { value: "Anickadu Regional Farmers Service Co-Op Bank", label: "Anickadu Regional Farmers Service Co-Op Bank" },
  { value: "Anil Agarwal & Sons", label: "Anil Agarwal & Sons" },
  { value: "Anil Agarwal & Sons (Huf)", label: "Anil Agarwal & Sons (Huf)" },
  { value: "Anil Agarwal Financier", label: "Anil Agarwal Financier" },
  { value: "Anil Auto Finance", label: "Anil Auto Finance" },
  { value: "Anil Auto Finance (P) Ltd.", label: "Anil Auto Finance (P) Ltd." },
  { value: "Anil Co Op Credit Soc Ltd", label: "Anil Co Op Credit Soc Ltd" },
  { value: "Anil Co Op Credit Soc Ltd . Evershine City", label: "Anil Co Op Credit Soc Ltd . Evershine City" },
  { value: "Anil Enterprise", label: "Anil Enterprise" },
  { value: "Anil Finance", label: "Anil Finance" },
  { value: "Anil Finance Corporation", label: "Anil Finance Corporation" },
  { value: "Anil Hire Purchase", label: "Anil Hire Purchase" },
  { value: "Anil Investment", label: "Anil Investment" },
  { value: "Anil Jain Finance", label: "Anil Jain Finance" },
  { value: "Anil Jain Huf", label: "Anil Jain Huf" },
  { value: "Anil Kumar", label: "Anil Kumar" },
  { value: "Anil Kumar A", label: "Anil Kumar A" },
  { value: "Anil Kumar Batra", label: "Anil Kumar Batra" },
  { value: "Anil Kumar Betala", label: "Anil Kumar Betala" },
  { value: "Anil Kumar Danda", label: "Anil Kumar Danda" },
  { value: "Anil Kumar Danweer Lal", label: "Anil Kumar Danweer Lal" },
  { value: "Anil Kumar Darda Huf", label: "Anil Kumar Darda Huf" },
  { value: "Anil Kumar Dungarwal Financier", label: "Anil Kumar Dungarwal Financier" },
  { value: "Anil Kumar J Mehta", label: "Anil Kumar J Mehta" },
  { value: "Anil Kumar Khivasara", label: "Anil Kumar Khivasara" },
  { value: "Anil Poulose", label: "Anil Poulose" },
  { value: "Anil Traders", label: "Anil Traders" },
  { value: "Anila Fin (P) Ltd", label: "Anila Fin (P) Ltd" },
  { value: "Anilkumar M", label: "Anilkumar M" },
  { value: "Anilkumar S. Singh", label: "Anilkumar S. Singh" },
  { value: "Anilkumar Sethiya Financier", label: "Anilkumar Sethiya Financier" },
  { value: "Animesh Tribhuwannath Munshi", label: "Animesh Tribhuwannath Munshi" },
  { value: "Anirudh Agrawal", label: "Anirudh Agrawal" },
  { value: "Anirudh Enterprise", label: "Anirudh Enterprise" },
  { value: "Anirudh Enterprises", label: "Anirudh Enterprises" },
  { value: "Anirudhha Enterprise", label: "Anirudhha Enterprise" },
  { value: "Anisha Enterprise", label: "Anisha Enterprise" },
  { value: "Anita Enterprise", label: "Anita Enterprise" },
  { value: "Anita Enterprises", label: "Anita Enterprises" },
  { value: "Anita Financer Pvt. Ltd.", label: "Anita Financer Pvt. Ltd." },
  { value: "Anita Kumari", label: "Anita Kumari" },
  { value: "Anitha Bohara Financier", label: "Anitha Bohara Financier" },
  { value: "Anitha Finance", label: "Anitha Finance" },
  { value: "Anitha Kanwar Bagmar", label: "Anitha Kanwar Bagmar" },
  { value: "Anj Finance Company", label: "Anj Finance Company" },
  { value: "Anjaheya Credit", label: "Anjaheya Credit" },
  { value: "Anjali Auto Finance", label: "Anjali Auto Finance" },
  { value: "Anjali Co Op Credit Soc Ltd Mumbai", label: "Anjali Co Op Credit Soc Ltd Mumbai" },
  { value: "Anjana A/F", label: "Anjana A/F" },
  { value: "Anjana Auto Finance", label: "Anjana Auto Finance" },
  { value: "Anjana Gupta", label: "Anjana Gupta" },
  { value: "Anjana Investments", label: "Anjana Investments" },
  { value: "Anjana Sri Automobile Finance", label: "Anjana Sri Automobile Finance" },
  { value: "Anjaneya Credit Co-Op Bank.", label: "Anjaneya Credit Co-Op Bank." },
  { value: "Anjangaon Surji Nagarik Sahakari Bank Ltd", label: "Anjangaon Surji Nagarik Sahakari Bank Ltd" },
  { value: "Anjani Finance", label: "Anjani Finance" },
  { value: "Anjani Hire Purchase & Investment Ltd", label: "Anjani Hire Purchase & Investment Ltd" },
  { value: "Anjani Motors Credit", label: "Anjani Motors Credit" },
  { value: "Anjanna Investments", label: "Anjanna Investments" },
  { value: "Anjna Investment Pvt Limited", label: "Anjna Investment Pvt Limited" },
  { value: "Anju  Enterprises,Surat", label: "Anju  Enterprises,Surat" },
  { value: "Anju Investment", label: "Anju Investment" },
  { value: "Ankalgi Urban Co-Op Bank Ltd", label: "Ankalgi Urban Co-Op Bank Ltd" },
  { value: "Ankamaly Auto Financiers", label: "Ankamaly Auto Financiers" },
  { value: "Ankar Investo", label: "Ankar Investo" },
  { value: "Ankeetha Finance", label: "Ankeetha Finance" },
  { value: "Ankheridevi Gramin Bigar Seti Sah Pat San", label: "Ankheridevi Gramin Bigar Seti Sah Pat San" },
  { value: "Ankit Commerce Ltd", label: "Ankit Commerce Ltd" },
  { value: "Ankit Investment", label: "Ankit Investment" },
  { value: "Ankit Jajkumar Mehta", label: "Ankit Jajkumar Mehta" },
  { value: "Ankit Tayal", label: "Ankit Tayal" },
  { value: "Ankitha Finance", label: "Ankitha Finance" },
  { value: "Ankleshwar Nagarik Sahakari Bank Ltd", label: "Ankleshwar Nagarik Sahakari Bank Ltd" },
  { value: "Ankleshwar Udyognagar Co-Op Bank Ltd", label: "Ankleshwar Udyognagar Co-Op Bank Ltd" },
  { value: "Ankola Urban Co-Op Bank Ltd", label: "Ankola Urban Co-Op Bank Ltd" },
  { value: "Ankur Auto Finance", label: "Ankur Auto Finance" },
  { value: "Ankur Services", label: "Ankur Services" },
  { value: "Ankur Trade Links Pvt Ltd", label: "Ankur Trade Links Pvt Ltd" },
  { value: "Anmol Auto Finance", label: "Anmol Auto Finance" },
  { value: "Anmol Finance Corp", label: "Anmol Finance Corp" },
  { value: "Anmol Hire Puirchase Pvt Ltd", label: "Anmol Hire Puirchase Pvt Ltd" },
  { value: "Anna Auto Finance", label: "Anna Auto Finance" },
  { value: "Anna Finance Limited", label: "Anna Finance Limited" },
  { value: "Anna Saheb Nale Nagari Sah Pat", label: "Anna Saheb Nale Nagari Sah Pat" },
  { value: "Anna University, Chennai", label: "Anna University, Chennai" },
  { value: "Annai Arul Finance", label: "Annai Arul Finance" },
  { value: "Annai Benefits", label: "Annai Benefits" },
  { value: "Annai Finance", label: "Annai Finance" },
  { value: "Annai India Enterprises", label: "Annai India Enterprises" },
  { value: "Annakara Service Co-Op Society", label: "Annakara Service Co-Op Society" },
  { value: "Annamalai Auto Investment", label: "Annamalai Auto Investment" },
  { value: "Annamalai Consulting", label: "Annamalai Consulting" },
  { value: "Annamallai Finance Ltd", label: "Annamallai Finance Ltd" },
  { value: "Annapoorna Finvest Finance Ltd", label: "Annapoorna Finvest Finance Ltd" },
  { value: "Annapoorna Finvest Ltd.", label: "Annapoorna Finvest Ltd." },
  { value: "Annapurna Enterprises", label: "Annapurna Enterprises" },
  { value: "Annapurna Finance", label: "Annapurna Finance" },
  { value: "Annapurna Nagari Sahkari Bank Ltd", label: "Annapurna Nagari Sahkari Bank Ltd" },
  { value: "Annasaheb Chougle Urban Co Op Bank", label: "Annasaheb Chougle Urban Co Op Bank" },
  { value: "Annasaheb Karale Janata Sahakari Bank Ltd", label: "Annasaheb Karale Janata Sahakari Bank Ltd" },
  { value: "Annasaheb Karele Janta Sahakari Bank Ltd", label: "Annasaheb Karele Janta Sahakari Bank Ltd" },
  { value: "Annasaheb Magar Sah Bank Maryadit", label: "Annasaheb Magar Sah Bank Maryadit" },
  { value: "Annasaheb Magar Sah Pat", label: "Annasaheb Magar Sah Pat" },
  { value: "Annasaheb Patil Urban Co-Op Bank Ltd", label: "Annasaheb Patil Urban Co-Op Bank Ltd" },
  { value: "Annasaheb Sawant Co Op Urban Bank Mahad Ltd", label: "Annasaheb Sawant Co Op Urban Bank Mahad Ltd" },
  { value: "Annu Investment & Finance Co.", label: "Annu Investment & Finance Co." },
  { value: "Anoj Fin Co P Ltd", label: "Anoj Fin Co P Ltd" },
  { value: "Anon Marketing", label: "Anon Marketing" },
  { value: "Anoop K Raj", label: "Anoop K Raj" },
  { value: "Anop Finance", label: "Anop Finance" },
  { value: "Anr Com Serve Pvt Ltd", label: "Anr Com Serve Pvt Ltd" },
  { value: "Anraj Jain Financier", label: "Anraj Jain Financier" },
  { value: "Anshu Finance Co Ltd", label: "Anshu Finance Co Ltd" },
  { value: "Anshu Leasing Pvt Ltd", label: "Anshu Leasing Pvt Ltd" },
  { value: "Anshul Auto Finance (P) Ltd", label: "Anshul Auto Finance (P) Ltd" },
  { value: "Ansul Motors Pvt Ltd", label: "Ansul Motors Pvt Ltd" },
  { value: "Antali Finance", label: "Antali Finance" },
  { value: "Anti Doping Commission Of India", label: "Anti Doping Commission Of India" },
  { value: "Antrix Corporation Ltd.", label: "Antrix Corporation Ltd." },
  { value: "Antyanvasvsai Sahakari Vikas Samiti Mydt", label: "Antyanvasvsai Sahakari Vikas Samiti Mydt" },
  { value: "Anubhav Co-Op Bank Ltd", label: "Anubhav Co-Op Bank Ltd" },
  { value: "Anufina Investment And Leasing Ltd.", label: "Anufina Investment And Leasing Ltd." },
  { value: "Anugraha Auto Finance", label: "Anugraha Auto Finance" },
  { value: "Anukriti Leasing And Exports Pvt. Ltd.", label: "Anukriti Leasing And Exports Pvt. Ltd." },
  { value: "Anumalla Shankaraiah", label: "Anumalla Shankaraiah" },
  { value: "Anup Auto", label: "Anup Auto" },
  { value: "Anup Prakash", label: "Anup Prakash" },
  { value: "Anupallavi Finance", label: "Anupallavi Finance" },
  { value: "Anupam Motor Finance Ltd", label: "Anupam Motor Finance Ltd" },
  { value: "Anupam S Mehta", label: "Anupam S Mehta" },
  { value: "Anupama Auto Finacne", label: "Anupama Auto Finacne" },
  { value: "Anupama Finance And Leasing Ltd.", label: "Anupama Finance And Leasing Ltd." },
  { value: "Anupama Rawat", label: "Anupama Rawat" },
  { value: "Anuraag Auto Finance", label: "Anuraag Auto Finance" },
  { value: "Anuradha", label: "Anuradha" },
  { value: "Anuradha Financiers", label: "Anuradha Financiers" },
  { value: "Anuradha Urban Co-Op Bank Limited", label: "Anuradha Urban Co-Op Bank Limited" },
  { value: "Anurag Dugar & Sons", label: "Anurag Dugar & Sons" },
  { value: "Anurag Finace .Co Ltd", label: "Anurag Finace .Co Ltd" },
  { value: "Anushasan Finance Ltd", label: "Anushasan Finance Ltd" },
  { value: "Anushashun Finance", label: "Anushashun Finance" },
  { value: "Anuvish Finance (P) Ltd.", label: "Anuvish Finance (P) Ltd." },
  { value: "Anway F/N Bsr", label: "Anway F/N Bsr" },
  { value: "Anyonya Co.Op Bank Ltd", label: "Anyonya Co.Op Bank Ltd" },
  { value: "Anyonya Sah. Mandali Co-Op Bank Ltd.", label: "Anyonya Sah. Mandali Co-Op Bank Ltd." },
  { value: "Anyonya Sahayakari Mandali Coop Bank Ltd", label: "Anyonya Sahayakari Mandali Coop Bank Ltd" },
  { value: "Anyonya Shahayak Sahakari Mandali Ltd", label: "Anyonya Shahayak Sahakari Mandali Ltd" },
  { value: "Anz Grindlays Bank Ltd.", label: "Anz Grindlays Bank Ltd." },
  { value: "Aor Kry Finlease Ltd.", label: "Aor Kry Finlease Ltd." },
  { value: "Ap Genco", label: "Ap Genco" },
  { value: "Ap Grameena Bank", label: "Ap Grameena Bank" },
  { value: "Ap Housing Board", label: "Ap Housing Board" },
  { value: "Ap Pollution Control Board", label: "Ap Pollution Control Board" },
  { value: "Ap State Co-Op Finance Corporation Ltd", label: "Ap State Co-Op Finance Corporation Ltd" },
  { value: "Ap State Housing Corporation Ltd", label: "Ap State Housing Corporation Ltd" },
  { value: "Apana Motors Finance Co Ltd", label: "Apana Motors Finance Co Ltd" },
  { value: "Apana Nagari Sahakari Credit Soc Ltd", label: "Apana Nagari Sahakari Credit Soc Ltd" },
  { value: "Aparna Auto Finance", label: "Aparna Auto Finance" },
  { value: "Aparna Vikesh Patel", label: "Aparna Vikesh Patel" },
  { value: "Apc Finance (India) Ltd", label: "Apc Finance (India) Ltd" },
  { value: "Apco Vehicles India Pvt Ltd", label: "Apco Vehicles India Pvt Ltd" },
  { value: "Ape Finance India Ltd.", label: "Ape Finance India Ltd." },
  { value: "Apeejay Finance", label: "Apeejay Finance" },
  { value: "Apeejay Finance Co Op Ltd", label: "Apeejay Finance Co Op Ltd" },
  { value: "Apeejay Finance Group Ltd", label: "Apeejay Finance Group Ltd" },
  { value: "Apeejay Finance Group Ltd.", label: "Apeejay Finance Group Ltd." },
  { value: "Apeejay Finance Ltd.", label: "Apeejay Finance Ltd." },
  { value: "Apeejay Finance Services Ltd.", label: "Apeejay Finance Services Ltd." },
  { value: "Apeshwar Credit Co-Op. Society Ltd", label: "Apeshwar Credit Co-Op. Society Ltd" },
  { value: "Apex Bank", label: "Apex Bank" },
  { value: "Apex Co-Op Bank Of Urban Bank", label: "Apex Co-Op Bank Of Urban Bank" },
  { value: "Apex Co-Op Bank Of Urban Banks Of Maharashtra And Goa Ltd.", label: "Apex Co-Op Bank Of Urban Banks Of Maharashtra And Goa Ltd." },
  { value: "Apex Co-Op. Bank", label: "Apex Co-Op. Bank" },
  { value: "Apex Finance Co.", label: "Apex Finance Co." },
  { value: "Apex Trading Co.", label: "Apex Trading Co." },
  { value: "Apg Finance Ltd", label: "Apg Finance Ltd" },
  { value: "Aphelion Inv & Finance Pvt Ltd", label: "Aphelion Inv & Finance Pvt Ltd" },
  { value: "Aphilion Finance Ltd", label: "Aphilion Finance Ltd" },
  { value: "Apidc - Venture Capital Limited", label: "Apidc - Venture Capital Limited" },
  { value: "Apj Finance Co Ltd", label: "Apj Finance Co Ltd" },
  { value: "Apna Finance (India) Ltd.", label: "Apna Finance (India) Ltd." },
  { value: "Apna Investments", label: "Apna Investments" },
  { value: "Apna Motor Finance", label: "Apna Motor Finance" },
  { value: "Apna Nagari Co Op Credit Soc Ltd", label: "Apna Nagari Co Op Credit Soc Ltd" },
  { value: "Apna Sahakari Bank Ltd.", label: "Apna Sahakari Bank Ltd." },
  { value: "Apna Sahakari Bank Ltd.", label: "Apna Sahakari Bank Ltd." },
  { value: "Apna Sahakari Co-Operative Bank", label: "Apna Sahakari Co-Operative Bank" },
  { value: "Apni Sahakari Bank Ltd., Ahmedabad", label: "Apni Sahakari Bank Ltd., Ahmedabad" },
  { value: "Apoorva Finance", label: "Apoorva Finance" },
  { value: "Appasaheb Birnale Sahakari Bank Ltd", label: "Appasaheb Birnale Sahakari Bank Ltd" },
  { value: "Appee Jay Finance Ltd", label: "Appee Jay Finance Ltd" },
  { value: "Apple Credit Corporation Ltd.", label: "Apple Credit Corporation Ltd." },
  { value: "Apple Finance Ltd", label: "Apple Finance Ltd" },
  { value: "Apple Finance Ltd", label: "Apple Finance Ltd" },
  { value: "Appolo Finance Ltd", label: "Appolo Finance Ltd" },
  { value: "Appu Auto Finance", label: "Appu Auto Finance" },
  { value: "Apra Auto (I) Pvt Ltd", label: "Apra Auto (I) Pvt Ltd" },
  { value: "Aps Hire Purchase Ltd.", label: "Aps Hire Purchase Ltd." },
  { value: "Apwb", label: "Apwb" },
  { value: "Aquila Finance Pvt Ltd", label: "Aquila Finance Pvt Ltd" },
  { value: "Ar Shanthilal Nahar Sons", label: "Ar Shanthilal Nahar Sons" },
  { value: "Ar.A.S &P.V.P.V Motor Erode Private Limited", label: "Ar.A.S &P.V.P.V Motor Erode Private Limited" },
  { value: "Ara Auto Finance", label: "Ara Auto Finance" },
  { value: "Arab Bangladesh Bank", label: "Arab Bangladesh Bank" },
  { value: "Arab Bangladesh Bank Limited", label: "Arab Bangladesh Bank Limited" },
  { value: "Arachan Dilip Kumar Finance", label: "Arachan Dilip Kumar Finance" },
  { value: "Arag V.K.C Society Ltd", label: "Arag V.K.C Society Ltd" },
  { value: "Arahimhkan Jabbarkhan Pathan", label: "Arahimhkan Jabbarkhan Pathan" },
  { value: "Arakandanallur Primary Agri Coop Bk Ltd.", label: "Arakandanallur Primary Agri Coop Bk Ltd." },
  { value: "Aralam Farm Employees Co-Op Credit Soc", label: "Aralam Farm Employees Co-Op Credit Soc" },
  { value: "Aralasandra Vyavsaya Seva Sah Bank", label: "Aralasandra Vyavsaya Seva Sah Bank" },
  { value: "Aranattukara Oriental Coop Bank Ltd", label: "Aranattukara Oriental Coop Bank Ltd" },
  { value: "Arashu", label: "Arashu" },
  { value: "Arashu Auto Finance", label: "Arashu Auto Finance" },
  { value: "Arashu Auto Financierrs", label: "Arashu Auto Financierrs" },
  { value: "Arashu Finance", label: "Arashu Finance" },
  { value: "Arasi Auto Finance", label: "Arasi Auto Finance" },
  { value: "Arasi Auto Finance Corporation", label: "Arasi Auto Finance Corporation" },
  { value: "Arasii Auto Financier", label: "Arasii Auto Financier" },
  { value: "Aravali Kshetriya Gramin Bank", label: "Aravali Kshetriya Gramin Bank" },
  { value: "Aravali Kshetriya Gramin Bank Ltd", label: "Aravali Kshetriya Gramin Bank Ltd" },
  { value: "Aravind B", label: "Aravind B" },
  { value: "Aravind Kumar", label: "Aravind Kumar" },
  { value: "Aravinth Motor Finance", label: "Aravinth Motor Finance" },
  { value: "Aray Vaishya Co-Op Bank Ltd", label: "Aray Vaishya Co-Op Bank Ltd" },
  { value: "Arayapuram Urban Co-Op Bank Ltd", label: "Arayapuram Urban Co-Op Bank Ltd" },
  { value: "Archaeological Survey Of India", label: "Archaeological Survey Of India" },
  { value: "Archana Auto Finance", label: "Archana Auto Finance" },
  { value: "Archana Dilip Kumar", label: "Archana Dilip Kumar" },
  { value: "Archana Investments.", label: "Archana Investments." },
  { value: "Archin Finance & Investment", label: "Archin Finance & Investment" },
  { value: "Arcot Finance And Agency Ltd.", label: "Arcot Finance And Agency Ltd." },
  { value: "Arcot Sri Sakthi Velan Auto Finance", label: "Arcot Sri Sakthi Velan Auto Finance" },
  { value: "Ardas Finlease", label: "Ardas Finlease" },
  { value: "Area Manager Food Corporation India", label: "Area Manager Food Corporation India" },
  { value: "Ari Ram Auto Fin Ltd", label: "Ari Ram Auto Fin Ltd" },
  { value: "Arihant Associates", label: "Arihant Associates" },
  { value: "Arihant Auto Finance", label: "Arihant Auto Finance" },
  { value: "Arihant Co-Op. Bank Ltd.", label: "Arihant Co-Op. Bank Ltd." },
  { value: "Arihant Eicher Tractor", label: "Arihant Eicher Tractor" },
  { value: "Arihant Enterprise", label: "Arihant Enterprise" },
  { value: "Arihant Fin Corporation", label: "Arihant Fin Corporation" },
  { value: "Arihant Finance & Co.", label: "Arihant Finance & Co." },
  { value: "Arihant Finance And Leasing Company", label: "Arihant Finance And Leasing Company" },
  { value: "Arihant Finance Syndicate", label: "Arihant Finance Syndicate" },
  { value: "Arihant Gramin Sah Patsanstha", label: "Arihant Gramin Sah Patsanstha" },
  { value: "Arihant Hire Purchase Company", label: "Arihant Hire Purchase Company" },
  { value: "Arihant Investments", label: "Arihant Investments" },
  { value: "Arihant M (P) Ltd.", label: "Arihant M (P) Ltd." },
  { value: "Arihant Motor Finance", label: "Arihant Motor Finance" },
  { value: "Arihant Motors", label: "Arihant Motors" },
  { value: "Arihant Traders", label: "Arihant Traders" },
  { value: "Arihant Trading Company", label: "Arihant Trading Company" },
  { value: "Arihant Urban Co-Operative Bank Ltd.", label: "Arihant Urban Co-Operative Bank Ltd." },
  { value: "Arihant Urban Souhard Credit Sahakari Ltd", label: "Arihant Urban Souhard Credit Sahakari Ltd" },
  { value: "Arihanth Credit Corporation", label: "Arihanth Credit Corporation" },
  { value: "Arihanth Credit Corporation", label: "Arihanth Credit Corporation" },
  { value: "Arihanth Investments", label: "Arihanth Investments" },
  { value: "Arinath Associates", label: "Arinath Associates" },
  { value: "Arivand Kumar & Sons", label: "Arivand Kumar & Sons" },
  { value: "Ariyakudi Primary Agricultural Co-Op.Bk.", label: "Ariyakudi Primary Agricultural Co-Op.Bk." },
  { value: "Ariyalur Rota Finance", label: "Ariyalur Rota Finance" },
  { value: "Arjun Co-Op Bank Ltd", label: "Arjun Co-Op Bank Ltd" },
  { value: "Arjun Credit Investment Pvt Ltd.", label: "Arjun Credit Investment Pvt Ltd." },
  { value: "Arjun Urban Co-Op Bank Ltd", label: "Arjun Urban Co-Op Bank Ltd" },
  { value: "Arjun Urban Co.Op. Bank Ltd.", label: "Arjun Urban Co.Op. Bank Ltd." },
  { value: "Arkay Finance", label: "Arkay Finance" },
  { value: "Arman Financial", label: "Arman Financial" },
  { value: "Arman Financial Services Ltd.", label: "Arman Financial Services Ltd." },
  { value: "Arman Lease And Finance Ltd.", label: "Arman Lease And Finance Ltd." },
  { value: "Arman Motors", label: "Arman Motors" },
  { value: "Army Base W/S Credit Co-Op Primary Bank Ltd", label: "Army Base W/S Credit Co-Op Primary Bank Ltd" },
  { value: "Army Group Insurance", label: "Army Group Insurance" },
  { value: "Arnath Investment", label: "Arnath Investment" },
  { value: "Arni Sri Om Ganapathi Finance", label: "Arni Sri Om Ganapathi Finance" },
  { value: "Aroodh Jyoti Pattan Sahakara Bank Niyamitha", label: "Aroodh Jyoti Pattan Sahakara Bank Niyamitha" },
  { value: "Aroodh Paltana Sahakar Bank Niyamit", label: "Aroodh Paltana Sahakar Bank Niyamit" },
  { value: "Arora And Sons Chaudhary Market", label: "Arora And Sons Chaudhary Market" },
  { value: "Arora Automobile Finance", label: "Arora Automobile Finance" },
  { value: "Arora Finance Company Rania", label: "Arora Finance Company Rania" },
  { value: "Arora Financial Services Ltd.", label: "Arora Financial Services Ltd." },
  { value: "Arpan Associates", label: "Arpan Associates" },
  { value: "Arpic Finance Private Ltd.", label: "Arpic Finance Private Ltd." },
  { value: "Arpit Kamalkishor Kothari", label: "Arpit Kamalkishor Kothari" },
  { value: "Arrodhjyoti Pattana Sahakara B Niyamitha", label: "Arrodhjyoti Pattana Sahakara B Niyamitha" },
  { value: "Arsikere Urban Co-Op Credit Bank Ltd", label: "Arsikere Urban Co-Op Credit Bank Ltd" },
  { value: "Arthasiddhi Nagari Sahakari Patsanstha Maryadit", label: "Arthasiddhi Nagari Sahakari Patsanstha Maryadit" },
  { value: "Arthaved Gamin Bigar Sheti Patsanstha", label: "Arthaved Gamin Bigar Sheti Patsanstha" },
  { value: "Arthiga Finance", label: "Arthiga Finance" },
  { value: "Arthved Patsanstha Ltd", label: "Arthved Patsanstha Ltd" },
  { value: "Arthy Auto Finance", label: "Arthy Auto Finance" },
  { value: "Arti Associates", label: "Arti Associates" },
  { value: "Arti Finance Co.", label: "Arti Finance Co." },
  { value: "Artificial Limbs Mfg. Corpn. Of India", label: "Artificial Limbs Mfg. Corpn. Of India" },
  { value: "Arul Bankers", label: "Arul Bankers" },
  { value: "Arul Jothi Finance", label: "Arul Jothi Finance" },
  { value: "Arul Lakshmi Finance", label: "Arul Lakshmi Finance" },
  { value: "Arul Murugan Investments", label: "Arul Murugan Investments" },
  { value: "Arul Nithi Finance", label: "Arul Nithi Finance" },
  { value: "Arul Sakthi Auto Finance", label: "Arul Sakthi Auto Finance" },
  { value: "Arul Sakthi Finance", label: "Arul Sakthi Finance" },
  { value: "Aruldevan Finance", label: "Aruldevan Finance" },
  { value: "Arulmigu Manakulavinayagar Finance And Chits", label: "Arulmigu Manakulavinayagar Finance And Chits" },
  { value: "Arun", label: "Arun" },
  { value: "Arun & Sons", label: "Arun & Sons" },
  { value: "Arun Agnihotri", label: "Arun Agnihotri" },
  { value: "Arun Auto Finance", label: "Arun Auto Finance" },
  { value: "Arun Finance", label: "Arun Finance" },
  { value: "Arun Haran", label: "Arun Haran" },
  { value: "Arun Kothari Consulting Financial Servises", label: "Arun Kothari Consulting Financial Servises" },
  { value: "Arun Kumar", label: "Arun Kumar" },
  { value: "Arun Kumar Goel", label: "Arun Kumar Goel" },
  { value: "Arun Kumar S", label: "Arun Kumar S" },
  { value: "Arun Nahar & Sons", label: "Arun Nahar & Sons" },
  { value: "Aruna Leasing And Finance (P) Ltd.", label: "Aruna Leasing And Finance (P) Ltd." },
  { value: "Aruna Leasing Finance", label: "Aruna Leasing Finance" },
  { value: "Arunachal Pradesh Rural Bank", label: "Arunachal Pradesh Rural Bank" },
  { value: "Arunachal Pradesh Rural Bank", label: "Arunachal Pradesh Rural Bank" },
  { value: "Arunachal Pradesh State Central Co-Oprative Bank Ltd.", label: "Arunachal Pradesh State Central Co-Oprative Bank Ltd." },
  { value: "Arunachal Pradesh State Industrial Development Corporation", label: "Arunachal Pradesh State Industrial Development Corporation" },
  { value: "Arunachal Pradesh State Tourism Development Corporation Ltd.", label: "Arunachal Pradesh State Tourism Development Corporation Ltd." },
  { value: "Arunachal Rural Bank", label: "Arunachal Rural Bank" },
  { value: "Arunachala Auto Finance", label: "Arunachala Auto Finance" },
  { value: "Arunagiriyar Finance", label: "Arunagiriyar Finance" },
  { value: "Arunodaya Co-Op Credit Soc. Ltd.", label: "Arunodaya Co-Op Credit Soc. Ltd." },
  { value: "Arur Vyavasaya Seva Shankara Bank Ltd", label: "Arur Vyavasaya Seva Shankara Bank Ltd" },
  { value: "Aruvii Auto Finance Corp", label: "Aruvii Auto Finance Corp" },
  { value: "Aruvii Auto Finance Corporation", label: "Aruvii Auto Finance Corporation" },
  { value: "Arval India Pvt Ltd", label: "Arval India Pvt Ltd" },
  { value: "Arvarat Bank", label: "Arvarat Bank" },
  { value: "Arvid Kumar", label: "Arvid Kumar" },
  { value: "Arvind Auto Financers", label: "Arvind Auto Financers" },
  { value: "Arvind Cottnspin Midc", label: "Arvind Cottnspin Midc" },
  { value: "Arvind Credit", label: "Arvind Credit" },
  { value: "Arvind Finance", label: "Arvind Finance" },
  { value: "Arvind Investment", label: "Arvind Investment" },
  { value: "Arvind Kumar", label: "Arvind Kumar" },
  { value: "Arvind Kumar & Son", label: "Arvind Kumar & Son" },
  { value: "Arvind Kumar & Sons", label: "Arvind Kumar & Sons" },
  { value: "Arvind Kumar Toshniwal", label: "Arvind Kumar Toshniwal" },
  { value: "Arvind Mills Ltd", label: "Arvind Mills Ltd" },
  { value: "Arvind Nagari Sah. Patsanstha Maryadit", label: "Arvind Nagari Sah. Patsanstha Maryadit" },
  { value: "Arvind Products Ltd", label: "Arvind Products Ltd" },
  { value: "Arvind Sahakari Bank Ltd.,", label: "Arvind Sahakari Bank Ltd.," },
  { value: "Arya Chanakya Nagari Shkari Patsanshta", label: "Arya Chanakya Nagari Shkari Patsanshta" },
  { value: "Arya Finance", label: "Arya Finance" },
  { value: "Arya Motors", label: "Arya Motors" },
  { value: "Arya Vaishya Shree Ram Sahakara Sangha Niyamitha", label: "Arya Vaishya Shree Ram Sahakara Sangha Niyamitha" },
  { value: "Arya Vysya Sri Kanaka Parameshwari Co-Op Society Ltd", label: "Arya Vysya Sri Kanaka Parameshwari Co-Op Society Ltd" },
  { value: "Aryabhat Gramin Bank", label: "Aryabhat Gramin Bank" },
  { value: "Aryaman Automobiles", label: "Aryaman Automobiles" },
  { value: "Aryanandi Gramin B S Patpurwatha Wadha Ramnath", label: "Aryanandi Gramin B S Patpurwatha Wadha Ramnath" },
  { value: "Aryavart Grameen Bank", label: "Aryavart Grameen Bank" },
  { value: "Aryavart Gramin Bank,", label: "Aryavart Gramin Bank," },
  { value: "Aryavrat Gramin Bank", label: "Aryavrat Gramin Bank" },
  { value: "Asba Finance", label: "Asba Finance" },
  { value: "Ascard Bank Ltd", label: "Ascard Bank Ltd" },
  { value: "Asdec", label: "Asdec" },
  { value: "Aserbee Associate", label: "Aserbee Associate" },
  { value: "Asha Auto Enterprise", label: "Asha Auto Enterprise" },
  { value: "Asha Credit Corporation", label: "Asha Credit Corporation" },
  { value: "Asha Devi Finance", label: "Asha Devi Finance" },
  { value: "Asha Devi Radheshyam Goyal", label: "Asha Devi Radheshyam Goyal" },
  { value: "Asha Enterprises", label: "Asha Enterprises" },
  { value: "Asha Finance", label: "Asha Finance" },
  { value: "Asha Finance Corporation", label: "Asha Finance Corporation" },
  { value: "Asha Vaibhav Co-Op Credit Society Ltd.", label: "Asha Vaibhav Co-Op Credit Society Ltd." },
  { value: "Ashabai Financier", label: "Ashabai Financier" },
  { value: "Ashabh Finance", label: "Ashabh Finance" },
  { value: "Ashadevi Mahila Nagari Sah Patsantha Maryadit", label: "Ashadevi Mahila Nagari Sah Patsantha Maryadit" },
  { value: "Ashamro", label: "Ashamro" },
  { value: "Ashapura Finance", label: "Ashapura Finance" },
  { value: "Ashavamegh Garamin Bigar Sheti Parner", label: "Ashavamegh Garamin Bigar Sheti Parner" },
  { value: "Ashija Services (P) Ltd.", label: "Ashija Services (P) Ltd." },
  { value: "Ashirwad Co Op Credit Soc Ltd", label: "Ashirwad Co Op Credit Soc Ltd" },
  { value: "Ashirwad Finance", label: "Ashirwad Finance" },
  { value: "Ashirwad Mahila Nagarik Sahakari Bank Ltd", label: "Ashirwad Mahila Nagarik Sahakari Bank Ltd" },
  { value: "Ashish Auto Finance", label: "Ashish Auto Finance" },
  { value: "Ashish Finance", label: "Ashish Finance" },
  { value: "Ashish Finance Corporation", label: "Ashish Finance Corporation" },
  { value: "Ashish Kumar Kedia", label: "Ashish Kumar Kedia" },
  { value: "Ashish Paliwal", label: "Ashish Paliwal" },
  { value: "Ashish Shivkumarsing Thakur", label: "Ashish Shivkumarsing Thakur" },
  { value: "Ashok Auto Finance", label: "Ashok Auto Finance" },
  { value: "Ashok Auto Ltd", label: "Ashok Auto Ltd" },
  { value: "Ashok Babulal Karnavat", label: "Ashok Babulal Karnavat" },
  { value: "Ashok Bafna", label: "Ashok Bafna" },
  { value: "Ashok Chand", label: "Ashok Chand" },
  { value: "Ashok Credit Corporation", label: "Ashok Credit Corporation" },
  { value: "Ashok Enterprises", label: "Ashok Enterprises" },
  { value: "Ashok Finance & Credit Corporation", label: "Ashok Finance & Credit Corporation" },
  { value: "Ashok Finance Company", label: "Ashok Finance Company" },
  { value: "Ashok Finance Corporation", label: "Ashok Finance Corporation" },
  { value: "Ashok Investments", label: "Ashok Investments" },
  { value: "Ashok Kumar Finance", label: "Ashok Kumar Finance" },
  { value: "Ashok Kumar Jain", label: "Ashok Kumar Jain" },
  { value: "Ashok Kumar Kankaria & Sons", label: "Ashok Kumar Kankaria & Sons" },
  { value: "Ashok Kumar Lunia", label: "Ashok Kumar Lunia" },
  { value: "Ashok Kumar Nahar", label: "Ashok Kumar Nahar" },
  { value: "Ashok Kumar Padamchand & Co.", label: "Ashok Kumar Padamchand & Co." },
  { value: "Ashok Lealand Finance Ltd", label: "Ashok Lealand Finance Ltd" },
  { value: "Ashok Leyland", label: "Ashok Leyland" },
  { value: "Ashok Leyland A Division Of Indus Bank", label: "Ashok Leyland A Division Of Indus Bank" },
  { value: "Ashok Leyland Fin. Company Ltd.", label: "Ashok Leyland Fin. Company Ltd." },
  { value: "Ashok Leyland Finance Indire Bank", label: "Ashok Leyland Finance Indire Bank" },
  { value: "Ashok Leyland Finance Ltd.", label: "Ashok Leyland Finance Ltd." },
  { value: "Ashok Leyland Financial", label: "Ashok Leyland Financial" },
  { value: "Ashok Lodha", label: "Ashok Lodha" },
  { value: "Ashok Mehata", label: "Ashok Mehata" },
  { value: "Ashok Motor Credits", label: "Ashok Motor Credits" },
  { value: "Ashok Motor Finance", label: "Ashok Motor Finance" },
  { value: "Ashok Nahar", label: "Ashok Nahar" },
  { value: "Ashok Sahakari Bank Ltd.", label: "Ashok Sahakari Bank Ltd." },
  { value: "Ashok Trading & Co", label: "Ashok Trading & Co" },
  { value: "Ashok Trading Ltd", label: "Ashok Trading Ltd" },
  { value: "Ashok Travels Private Limited", label: "Ashok Travels Private Limited" },
  { value: "Ashoka & Co", label: "Ashoka & Co" },
  { value: "Ashoka And Co", label: "Ashoka And Co" },
  { value: "Ashoka Motor Credits", label: "Ashoka Motor Credits" },
  { value: "Ashoka Motor Finance Co Ltd", label: "Ashoka Motor Finance Co Ltd" },
  { value: "Ashokanagar Co-Op. Bank Ltd", label: "Ashokanagar Co-Op. Bank Ltd" },
  { value: "Ashokanagar Cooperative Bank Ltd", label: "Ashokanagar Cooperative Bank Ltd" },
  { value: "Ashoknagar Co-Op Bank Ltd", label: "Ashoknagar Co-Op Bank Ltd" },
  { value: "Ashokrao Bankar N.S.P.Ltd", label: "Ashokrao Bankar N.S.P.Ltd" },
  { value: "Ashram Associates", label: "Ashram Associates" },
  { value: "Ashray Co-Op Credit Soc Ltd", label: "Ashray Co-Op Credit Soc Ltd" },
  { value: "Ashraya Credit Co Op Society Ltd", label: "Ashraya Credit Co Op Society Ltd" },
  { value: "Ashta Peoples Co-Op Bank Ltd", label: "Ashta Peoples Co-Op Bank Ltd" },
  { value: "Ashta Urban Co-Op Bank Ltd", label: "Ashta Urban Co-Op Bank Ltd" },
  { value: "Ashtalakshmi Finance", label: "Ashtalakshmi Finance" },
  { value: "Ashtavinayak Nagri Sahakari Pathsanstha Maryadit", label: "Ashtavinayak Nagri Sahakari Pathsanstha Maryadit" },
  { value: "Ashti Nagari Sahakari Path Sanstha", label: "Ashti Nagari Sahakari Path Sanstha" },
  { value: "Ashtvinayak Gramin Bigar Sheti Sahkari Pat Sanstha Ltd", label: "Ashtvinayak Gramin Bigar Sheti Sahkari Pat Sanstha Ltd" },
  { value: "Ashutosh Insurance Broking  Co. Pvt. Ltd", label: "Ashutosh Insurance Broking  Co. Pvt. Ltd" },
  { value: "Ashwani Finance Corporation", label: "Ashwani Finance Corporation" },
  { value: "Ashwani Kumar", label: "Ashwani Kumar" },
  { value: "Ashwani Mahendru", label: "Ashwani Mahendru" },
  { value: "Ashwanikumar Arban Co Op Credit Soc Ltd", label: "Ashwanikumar Arban Co Op Credit Soc Ltd" },
  { value: "Ashwatha Pura Pathina Souharda Sahakari Niyamitha", label: "Ashwatha Pura Pathina Souharda Sahakari Niyamitha" },
  { value: "Ashwin Lunawat", label: "Ashwin Lunawat" },
  { value: "Ashwini Pattini Souharda Sahakari Niyamit", label: "Ashwini Pattini Souharda Sahakari Niyamit" },
  { value: "Asia Pacific Textiles And Clothing Forum", label: "Asia Pacific Textiles And Clothing Forum" },
  { value: "Asian Financial Services Ltd", label: "Asian Financial Services Ltd" },
  { value: "Asian Paint Employee Co-Operative Credit Soc. Ltd.", label: "Asian Paint Employee Co-Operative Credit Soc. Ltd." },
  { value: "Asija Sec. Pvt. Ltd.", label: "Asija Sec. Pvt. Ltd." },
  { value: "Asir Rajesh", label: "Asir Rajesh" },
  { value: "Asiramam Co - Op-Urban Bank", label: "Asiramam Co - Op-Urban Bank" },
  { value: "Asish Auto Finance", label: "Asish Auto Finance" },
  { value: "Ask Insurance Broking And Risk Management Services Pvt Ltd", label: "Ask Insurance Broking And Risk Management Services Pvt Ltd" },
  { value: "Aska District Central Co-Operative Bank Ltd.", label: "Aska District Central Co-Operative Bank Ltd." },
  { value: "Asmita Saha Patpedhi Maryadit", label: "Asmita Saha Patpedhi Maryadit" },
  { value: "Asmitha Micro Finance Ltd", label: "Asmitha Micro Finance Ltd" },
  { value: "Aspa Finance", label: "Aspa Finance" },
  { value: "Ass Ahand Fin Comp", label: "Ass Ahand Fin Comp" },
  { value: "Ass India Fin Ser P Ltd", label: "Ass India Fin Ser P Ltd" },
  { value: "Assalam Financial And Investment Company [P] Ltd.", label: "Assalam Financial And Investment Company [P] Ltd." },
  { value: "Assam Ashok Hotel Corporation Ltd.", label: "Assam Ashok Hotel Corporation Ltd." },
  { value: "Assam Co-Op Apex Bank Ltd.", label: "Assam Co-Op Apex Bank Ltd." },
  { value: "Assam Financial Corporation", label: "Assam Financial Corporation" },
  { value: "Assam Gramin Bank", label: "Assam Gramin Bank" },
  { value: "Assam Gramin Bikash Bank", label: "Assam Gramin Bikash Bank" },
  { value: "Assam Gramin Vikas Bank", label: "Assam Gramin Vikas Bank" },
  { value: "Assam Gramin Vikasa Bank", label: "Assam Gramin Vikasa Bank" },
  { value: "Assam Hire Purchase Co (P) Ltd", label: "Assam Hire Purchase Co (P) Ltd" },
  { value: "Assam State Central Co-Oprative Bank Ltd.", label: "Assam State Central Co-Oprative Bank Ltd." },
  { value: "Assam State Tourism Development Corporation Ltd.", label: "Assam State Tourism Development Corporation Ltd." },
  { value: "Asset Reconstruction Company (India) Limited", label: "Asset Reconstruction Company (India) Limited" },
  { value: "Assets Finance Pvt Ltd", label: "Assets Finance Pvt Ltd" },
  { value: "Assistant General Manager Telecom", label: "Assistant General Manager Telecom" },
  { value: "Associate  Co.Op. Bank Ltd", label: "Associate  Co.Op. Bank Ltd" },
  { value: "Associate Co-Op Bank Ltd.", label: "Associate Co-Op Bank Ltd." },
  { value: "Associate Financial Service Pvt Ltd", label: "Associate Financial Service Pvt Ltd" },
  { value: "Associate India Financial Services (P) Ltd", label: "Associate India Financial Services (P) Ltd" },
  { value: "Associated Co-Op Bank Ltd", label: "Associated Co-Op Bank Ltd" },
  { value: "Associated Credit Corporation", label: "Associated Credit Corporation" },
  { value: "Associated Hire Purchase And Credits Ltd", label: "Associated Hire Purchase And Credits Ltd" },
  { value: "Associated India Fin Services P Ltd", label: "Associated India Fin Services P Ltd" },
  { value: "Associates", label: "Associates" },
  { value: "Associates Finance Ltd", label: "Associates Finance Ltd" },
  { value: "Associates Finance Of Investments India Ltd.", label: "Associates Finance Of Investments India Ltd." },
  { value: "Associates India Finance Service Ltd", label: "Associates India Finance Service Ltd" },
  { value: "Association For Sarva Seva Forms", label: "Association For Sarva Seva Forms" },
  { value: "Association Of State Training Institutions In India", label: "Association Of State Training Institutions In India" },
  { value: "Asst Manager F & A Pay Rsp Sail", label: "Asst Manager F & A Pay Rsp Sail" },
  { value: "Astalakshmi Finance", label: "Astalakshmi Finance" },
  { value: "Astavinayak Urban Coop Credit Siciety Lt", label: "Astavinayak Urban Coop Credit Siciety Lt" },
  { value: "Astha Dealers Private Ltd.", label: "Astha Dealers Private Ltd." },
  { value: "Astha Mahila Nagrik Sahakari Bank Maryadit", label: "Astha Mahila Nagrik Sahakari Bank Maryadit" },
  { value: "Astha Mahila Nagrik Sahakari Bank Mydt.", label: "Astha Mahila Nagrik Sahakari Bank Mydt." },
  { value: "Astrazeneca India Private Limited", label: "Astrazeneca India Private Limited" },
  { value: "Astro Insurance Brokers Ltd.", label: "Astro Insurance Brokers Ltd." },
  { value: "Astvinayak Gramin Biger Sheti Sahakari Pathsanstha Ltd", label: "Astvinayak Gramin Biger Sheti Sahakari Pathsanstha Ltd" },
  { value: "Astvinayak Nagari Sahakari Pathsanstha.", label: "Astvinayak Nagari Sahakari Pathsanstha." },
  { value: "Aswin Finance Ltd", label: "Aswin Finance Ltd" },
  { value: "Atal Finance Company", label: "Atal Finance Company" },
  { value: "Atamakuri Durgaprasad A/F", label: "Atamakuri Durgaprasad A/F" },
  { value: "Ath Global Finance", label: "Ath Global Finance" },
  { value: "Athani Heare Purchase", label: "Athani Heare Purchase" },
  { value: "Athani Hire Purchase Co", label: "Athani Hire Purchase Co" },
  { value: "Athani Mahila Multiperpose Co-Op Society Ltd", label: "Athani Mahila Multiperpose Co-Op Society Ltd" },
  { value: "Atharva Motors Private Ltd", label: "Atharva Motors Private Ltd" },
  { value: "Athavan Finance", label: "Athavan Finance" },
  { value: "Athirai Finance", label: "Athirai Finance" },
  { value: "Athmakuri Sita Ramaiah Automobile Finance", label: "Athmakuri Sita Ramaiah Automobile Finance" },
  { value: "Athur Primary Agri Co Op Bank Ltd", label: "Athur Primary Agri Co Op Bank Ltd" },
  { value: "Athur Primary Agricultural Co Op Bank Lt", label: "Athur Primary Agricultural Co Op Bank Lt" },
  { value: "Atlantic Groups Finance", label: "Atlantic Groups Finance" },
  { value: "Atmakuri Durga Prasad Automobile Financiers", label: "Atmakuri Durga Prasad Automobile Financiers" },
  { value: "Atmashakti Gramin Bigar Sheti Sah Pat Sanstha Maryadit", label: "Atmashakti Gramin Bigar Sheti Sah Pat Sanstha Maryadit" },
  { value: "Atomic Energy Regulatory Board", label: "Atomic Energy Regulatory Board" },
  { value: "Atomic Minerals Directorate", label: "Atomic Minerals Directorate" },
  { value: "Attal Finance Corporation", label: "Attal Finance Corporation" },
  { value: "Attappaug Service Co-Op Bank Ltd,", label: "Attappaug Service Co-Op Bank Ltd," },
  { value: "Attrie Hire Purchase & Leasing Co", label: "Attrie Hire Purchase & Leasing Co" },
  { value: "Atul Nagari Sahakari Patsansthan Maryadit", label: "Atul Nagari Sahakari Patsansthan Maryadit" },
  { value: "Au Financers ( I ) P. Ltd", label: "Au Financers ( I ) P. Ltd" },
  { value: "Au Financers (India) Pvt. Ltd.", label: "Au Financers (India) Pvt. Ltd." },
  { value: "Au Financiers (I) Pvt. Ltd", label: "Au Financiers (I) Pvt. Ltd" },
  { value: "Au Financiers (India) Pvt Ltd", label: "Au Financiers (India) Pvt Ltd" },
  { value: "Aujla Finance Corporation Registered", label: "Aujla Finance Corporation Registered" },
  { value: "Aulakh Motor Finance Co", label: "Aulakh Motor Finance Co" },
  { value: "Aum Bima Suraksha Broking", label: "Aum Bima Suraksha Broking" },
  { value: "Auram Leasing And Finance Ltd.", label: "Auram Leasing And Finance Ltd." },
  { value: "Aurangabad - Jalna Gramin Bank", label: "Aurangabad - Jalna Gramin Bank" },
  { value: "Aurangabad Dist Industrial & Urban Co Op Bank Ltd", label: "Aurangabad Dist Industrial & Urban Co Op Bank Ltd" },
  { value: "Aurangabad District Central Co-Operative Bank Ltd.", label: "Aurangabad District Central Co-Operative Bank Ltd." },
  { value: "Aurangabad Peoples Co-Op Bank Ltd", label: "Aurangabad Peoples Co-Op Bank Ltd" },
  { value: "Aurex Auto Infracture Service Ltd.", label: "Aurex Auto Infracture Service Ltd." },
  { value: "Aurv Motors", label: "Aurv Motors" },
  { value: "Autarky Portfolio", label: "Autarky Portfolio" },
  { value: "Auto Champion", label: "Auto Champion" },
  { value: "Auto Co-Op Cr.Soc", label: "Auto Co-Op Cr.Soc" },
  { value: "Auto Finance", label: "Auto Finance" },
  { value: "Auto Finance Trade", label: "Auto Finance Trade" },
  { value: "Auto Financiers", label: "Auto Financiers" },
  { value: "Auto Fintrade", label: "Auto Fintrade" },
  { value: "Auto H. P. Finance Co.", label: "Auto H. P. Finance Co." },
  { value: "Auto India", label: "Auto India" },
  { value: "Auto India Investments", label: "Auto India Investments" },
  { value: "Auto Investors", label: "Auto Investors" },
  { value: "Auto Lease And Hire Purchase", label: "Auto Lease And Hire Purchase" },
  { value: "Auto Nirvahakara Credit Co-Op Society Ltd Brahamagiri  Udupi.", label: "Auto Nirvahakara Credit Co-Op Society Ltd Brahamagiri  Udupi." },
  { value: "Auto Plus", label: "Auto Plus" },
  { value: "Auto Trade & Fin. Corp.", label: "Auto Trade & Fin. Corp." },
  { value: "Auto Trading Co.", label: "Auto Trading Co." },
  { value: "Auto Zone", label: "Auto Zone" },
  { value: "Automobile Financiers", label: "Automobile Financiers" },
  { value: "Automobile General Finance Company (P) Ltd.", label: "Automobile General Finance Company (P) Ltd." },
  { value: "Automotive Axles Ltd", label: "Automotive Axles Ltd" },
  { value: "Av Finance Ltd", label: "Av Finance Ltd" },
  { value: "Av Financiers India Pvt. Ltd.", label: "Av Financiers India Pvt. Ltd." },
  { value: "Avanakuzhi Service Co-Op Bank Ltd.", label: "Avanakuzhi Service Co-Op Bank Ltd." },
  { value: "Avanashi Agricultural Producers", label: "Avanashi Agricultural Producers" },
  { value: "Avanthi Enterprises", label: "Avanthi Enterprises" },
  { value: "Avaran Hire Purchase", label: "Avaran Hire Purchase" },
  { value: "Avc Bank", label: "Avc Bank" },
  { value: "Avdhoot Lease And Finance", label: "Avdhoot Lease And Finance" },
  { value: "Avinash Finance Corporetion Ltd Dhamtari", label: "Avinash Finance Corporetion Ltd Dhamtari" },
  { value: "Avinashi Agri Producers  Co Op Marketing Society Ltd", label: "Avinashi Agri Producers  Co Op Marketing Society Ltd" },
  { value: "Avirath Co Op Credit Society Ltd", label: "Avirath Co Op Credit Society Ltd" },
  { value: "Avm Finance", label: "Avm Finance" },
  { value: "Avm Syndicate", label: "Avm Syndicate" },
  { value: "Avni Grover", label: "Avni Grover" },
  { value: "Avni Lease Finance Pvt Ltd", label: "Avni Lease Finance Pvt Ltd" },
  { value: "Avon Auto Sales", label: "Avon Auto Sales" },
  { value: "Avon Capital Services Ltd.", label: "Avon Capital Services Ltd." },
  { value: "Avs Enterprises", label: "Avs Enterprises" },
  { value: "Avs Finance", label: "Avs Finance" },
  { value: "Avyaya Finance Ltd.", label: "Avyaya Finance Ltd." },
  { value: "Awadh Finance Co. Tanda", label: "Awadh Finance Co. Tanda" },
  { value: "Awadh Gramin Bank Ltd", label: "Awadh Gramin Bank Ltd" },
  { value: "Awanthi Associates", label: "Awanthi Associates" },
  { value: "Awanthi Enterprises ( R )", label: "Awanthi Enterprises ( R )" },
  { value: "Awasthi Enterprises", label: "Awasthi Enterprises" },
  { value: "Awumi Urban Co –Oprative Credit Society Ltd", label: "Awumi Urban Co –Oprative Credit Society Ltd" },
  { value: "Axis Bank", label: "Axis Bank" },
  { value: "Axz Enterprises", label: "Axz Enterprises" },
  { value: "Ayeekuppam Pri Agriculture Co-Op Bank Lt", label: "Ayeekuppam Pri Agriculture Co-Op Bank Lt" },
  { value: "Ayngaraan Auto Finance", label: "Ayngaraan Auto Finance" },
  { value: "Ayroor Service Co-Operative Bank Ltd", label: "Ayroor Service Co-Operative Bank Ltd" },
  { value: "Ayyapa Finance", label: "Ayyapa Finance" },
  { value: "Ayyappa Finance", label: "Ayyappa Finance" },
  { value: "Ayyappa Finance & Leasing", label: "Ayyappa Finance & Leasing" },
  { value: "Ayyappa Motor Credit", label: "Ayyappa Motor Credit" },
  { value: "Ayyappan Finance", label: "Ayyappan Finance" },
  { value: "Ayyappancoil Ser.Co-Op.Bnak Ltd.", label: "Ayyappancoil Ser.Co-Op.Bnak Ltd." },
  { value: "Azad Alpasankyatar Sahakari Pattin Sangh", label: "Azad Alpasankyatar Sahakari Pattin Sangh" },
  { value: "Azad Co-Op Bank Ltd", label: "Azad Co-Op Bank Ltd" },
  { value: "Azad Credit Co Op Society Ltd.", label: "Azad Credit Co Op Society Ltd." },
  { value: "Azad Credit Co-Op Bank Ltd", label: "Azad Credit Co-Op Bank Ltd" },
  { value: "Azad Gramin Bigar Sheti Sah Pathsanstha Marya", label: "Azad Gramin Bigar Sheti Sah Pathsanstha Marya" },
  { value: "Azad Hind Coop Credit Soc Ltd", label: "Azad Hind Coop Credit Soc Ltd" },
  { value: "Azad Urabn Co-Op. Bank Ltd.", label: "Azad Urabn Co-Op. Bank Ltd." },
  { value: "Azad Urban Co-Op Bank Ltd", label: "Azad Urban Co-Op Bank Ltd" },
  { value: "Azad Urban Co-Op Bank Ltd", label: "Azad Urban Co-Op Bank Ltd" },
  { value: "Azad Vehicle Finance & Hire Purchase", label: "Azad Vehicle Finance & Hire Purchase" },
  { value: "Azhikode Service Co-Op. Bank Ltd.", label: "Azhikode Service Co-Op. Bank Ltd." },
  { value: "Azhiyur Service Co-Op. Bank Ltd.", label: "Azhiyur Service Co-Op. Bank Ltd." },
  { value: "Azhwar Bankers", label: "Azhwar Bankers" },
  { value: "Azmani Investments (I) Pvt Ltd", label: "Azmani Investments (I) Pvt Ltd" },
  { value: "Azmani L&T Ltd.", label: "Azmani L&T Ltd." },
  { value: "Azta Associates", label: "Azta Associates" },
  { value: "B Anil Kumar Financier", label: "B Anil Kumar Financier" },
  { value: "B B C C Bank.", label: "B B C C Bank." },
  { value: "B C L Financial Services Ltd", label: "B C L Financial Services Ltd" },
  { value: "B D A Comunication", label: "B D A Comunication" },
  { value: "B D C C Bank", label: "B D C C Bank" },
  { value: "B D Credits Ltd", label: "B D Credits Ltd" },
  { value: "B D Khaitan", label: "B D Khaitan" },
  { value: "B Dharamchand Bokdia Financier", label: "B Dharamchand Bokdia Financier" },
  { value: "B E U.P Gramin Bank Ltd", label: "B E U.P Gramin Bank Ltd" },
  { value: "B G Bhawarilal Bohra", label: "B G Bhawarilal Bohra" },
  { value: "B G Bhawrilal", label: "B G Bhawrilal" },
  { value: "B Gotham Chand & Sons", label: "B Gotham Chand & Sons" },
  { value: "B Goutamchand Bhandari Financiers", label: "B Goutamchand Bhandari Financiers" },
  { value: "B Gyanchands Sons", label: "B Gyanchands Sons" },
  { value: "B H E Employees Co Op Bank Ltd", label: "B H E Employees Co Op Bank Ltd" },
  { value: "B H E L Employees Co-Op Bank Ltd", label: "B H E L Employees Co-Op Bank Ltd" },
  { value: "B H Indusree Motors", label: "B H Indusree Motors" },
  { value: "B H L Employees Co-Operative Bank Ltd", label: "B H L Employees Co-Operative Bank Ltd" },
  { value: "B Jabar Chand Bothra", label: "B Jabar Chand Bothra" },
  { value: "B K Finance Company Pvt Ltd", label: "B K Finance Company Pvt Ltd" },
  { value: "B K G Bank", label: "B K G Bank" },
  { value: "B K K Finance", label: "B K K Finance" },
  { value: "B K T Finance", label: "B K T Finance" },
  { value: "B Kabra Fin Corp", label: "B Kabra Fin Corp" },
  { value: "B Kabra Finance Corporation", label: "B Kabra Finance Corporation" },
  { value: "B Kamala Bai Financiers", label: "B Kamala Bai Financiers" },
  { value: "B Kamalesh Kumar Finance", label: "B Kamalesh Kumar Finance" },
  { value: "B Kishore And Jain", label: "B Kishore And Jain" },
  { value: "B M Associates", label: "B M Associates" },
  { value: "B M Finance", label: "B M Finance" },
  { value: "B Mahendra Jain And Sons", label: "B Mahendra Jain And Sons" },
  { value: "B Mangal Jain", label: "B Mangal Jain" },
  { value: "B Mangal Jewellary", label: "B Mangal Jewellary" },
  { value: "B Mangesh Kumar Kothari", label: "B Mangesh Kumar Kothari" },
  { value: "B Manikandan", label: "B Manikandan" },
  { value: "B Mohanlal Darda", label: "B Mohanlal Darda" },
  { value: "B Prem Chand Dugar Financier", label: "B Prem Chand Dugar Financier" },
  { value: "B Premchand Dugar Finance Ltd.", label: "B Premchand Dugar Finance Ltd." },
  { value: "B R Ambedkar Dev Ocprn Ltd", label: "B R Ambedkar Dev Ocprn Ltd" },
  { value: "B R Katariya & Sons (Huf)", label: "B R Katariya & Sons (Huf)" },
  { value: "B R Motor Fin And Leasing Co", label: "B R Motor Fin And Leasing Co" },
  { value: "B Ramachandra (Hebbar).", label: "B Ramachandra (Hebbar)." },
  { value: "B S Guliani", label: "B S Guliani" },
  { value: "B S N L", label: "B S N L" },
  { value: "B Sandeep Kumar Jain", label: "B Sandeep Kumar Jain" },
  { value: "B Sanjay Kumar", label: "B Sanjay Kumar" },
  { value: "B Saraswathy", label: "B Saraswathy" },
  { value: "B U Bohra", label: "B U Bohra" },
  { value: "B V Finance And Leasing", label: "B V Finance And Leasing" },
  { value: "B V Leasing & Finance Company Ltd", label: "B V Leasing & Finance Company Ltd" },
  { value: "B W Up Gramin Bank", label: "B W Up Gramin Bank" },
  { value: "B. D. Investment", label: "B. D. Investment" },
  { value: "B. Dilipkumar Jain", label: "B. Dilipkumar Jain" },
  { value: "B. Gotham Chand Jain", label: "B. Gotham Chand Jain" },
  { value: "B. Lalith Chand Nahar", label: "B. Lalith Chand Nahar" },
  { value: "B. M. Auto Finance", label: "B. M. Auto Finance" },
  { value: "B. Mendhar Kumar Bajaj", label: "B. Mendhar Kumar Bajaj" },
  { value: "B. Padamchand Financier", label: "B. Padamchand Financier" },
  { value: "B. Ramchand Jain & Sons", label: "B. Ramchand Jain & Sons" },
  { value: "B. Rameshchandra And Sons", label: "B. Rameshchandra And Sons" },
  { value: "B.A.E Ltd", label: "B.A.E Ltd" },
  { value: "B.A.F Ltd", label: "B.A.F Ltd" },
  { value: "B.C.C.F.L", label: "B.C.C.F.L" },
  { value: "B.C.C.F.L.", label: "B.C.C.F.L." },
  { value: "B.C.K.G. Bank", label: "B.C.K.G. Bank" },
  { value: "B.Chenakumari Chopda", label: "B.Chenakumari Chopda" },
  { value: "B.D. Credites Ltd", label: "B.D. Credites Ltd" },
  { value: "B.D. Credits Ltd", label: "B.D. Credits Ltd" },
  { value: "B.D. Credits Ltd.", label: "B.D. Credits Ltd." },
  { value: "B.D.Credits Ltd", label: "B.D.Credits Ltd" },
  { value: "B.D.Credits Private Limited", label: "B.D.Credits Private Limited" },
  { value: "B.D.Investment", label: "B.D.Investment" },
  { value: "B.Dillip Kumar Jain", label: "B.Dillip Kumar Jain" },
  { value: "B.Dmi Devi Finance", label: "B.Dmi Devi Finance" },
  { value: "B.G. International", label: "B.G. International" },
  { value: "B.G.G.B", label: "B.G.G.B" },
  { value: "B.G.G.B Dabhava", label: "B.G.G.B Dabhava" },
  { value: "B.G.G.B Halol Branch", label: "B.G.G.B Halol Branch" },
  { value: "B.H.E.Employees Co-Op. Bank Ltd.", label: "B.H.E.Employees Co-Op. Bank Ltd." },
  { value: "B.Hosahalli Sahakari Vyavasaya Bank Niyamitha  Palguni", label: "B.Hosahalli Sahakari Vyavasaya Bank Niyamitha  Palguni" },
  { value: "B.K. Finance Company", label: "B.K. Finance Company" },
  { value: "B.L. Finlease (India) Pvt Ltd", label: "B.L. Finlease (India) Pvt Ltd" },
  { value: "B.M. Enterprises", label: "B.M. Enterprises" },
  { value: "B.M.F Credits Financial", label: "B.M.F Credits Financial" },
  { value: "B.Mangesh Kumar Kothari Financier", label: "B.Mangesh Kumar Kothari Financier" },
  { value: "B.Nanthakumar Jain Pawn Broker", label: "B.Nanthakumar Jain Pawn Broker" },
  { value: "B.P. U. P. G Bank", label: "B.P. U. P. G Bank" },
  { value: "B.P.C.L.", label: "B.P.C.L." },
  { value: "B.R Motors Finance & Leasing Co.", label: "B.R Motors Finance & Leasing Co." },
  { value: "B.R.Finance & Leasing Co", label: "B.R.Finance & Leasing Co" },
  { value: "B.R.G.B", label: "B.R.G.B" },
  { value: "B.R.M. Lease And Credit Pvt Ltd.", label: "B.R.M. Lease And Credit Pvt Ltd." },
  { value: "B.Rajarathinam", label: "B.Rajarathinam" },
  { value: "B.V Finance & Leasing Ltd", label: "B.V Finance & Leasing Ltd" },
  { value: "B.V.S Financers", label: "B.V.S Financers" },
  { value: "B.V.V. Ramana Murthy Finance", label: "B.V.V. Ramana Murthy Finance" },
  { value: "Baalas Investment", label: "Baalas Investment" },
  { value: "Baba Farid Investment Pvt. Ltd", label: "Baba Farid Investment Pvt. Ltd" },
  { value: "Baba Finance Pvt Ltd.", label: "Baba Finance Pvt Ltd." },
  { value: "Baba Markanada Finance", label: "Baba Markanada Finance" },
  { value: "Baba Mungipa Enterprises Tosham", label: "Baba Mungipa Enterprises Tosham" },
  { value: "Babasaheb Deshmukh Sahakari Bank Ltd.", label: "Babasaheb Deshmukh Sahakari Bank Ltd." },
  { value: "Babasaheb Deshmukh Sahakari Bank Ltd.,", label: "Babasaheb Deshmukh Sahakari Bank Ltd.," },
  { value: "Babasaheb Tube Patsanstha  Panoli", label: "Babasaheb Tube Patsanstha  Panoli" },
  { value: "Babawade Gramin Sah Pat Sans Mydt", label: "Babawade Gramin Sah Pat Sans Mydt" },
  { value: "Babita Jhabakh", label: "Babita Jhabakh" },
  { value: "Babita Kankaria", label: "Babita Kankaria" },
  { value: "Babitha Franklin", label: "Babitha Franklin" },
  { value: "Babodi Finance And Leasing", label: "Babodi Finance And Leasing" },
  { value: "Babu Rao Awatade Gramin Patsanshta Mangalweda", label: "Babu Rao Awatade Gramin Patsanshta Mangalweda" },
  { value: "Babulal Financier", label: "Babulal Financier" },
  { value: "Babulal Jain Finance", label: "Babulal Jain Finance" },
  { value: "Backward Class Kalyan Nigam", label: "Backward Class Kalyan Nigam" },
  { value: "Badagabettu Credit Co-Op. Society Ltd", label: "Badagabettu Credit Co-Op. Society Ltd" },
  { value: "Badagara Co-Op Rural Bank Ltd", label: "Badagara Co-Op Rural Bank Ltd" },
  { value: "Badagara Urban Co-Op Bank Ltd", label: "Badagara Urban Co-Op Bank Ltd" },
  { value: "Badagubettu Credit Co-Operative Society Ltd", label: "Badagubettu Credit Co-Operative Society Ltd" },
  { value: "Badani Fin Corporation", label: "Badani Fin Corporation" },
  { value: "Badnavati Investment", label: "Badnavati Investment" },
  { value: "Badri Kedar Auto Finance", label: "Badri Kedar Auto Finance" },
  { value: "Badri Kedar Financiers", label: "Badri Kedar Financiers" },
  { value: "Baf India (P) Ltd", label: "Baf India (P) Ltd" },
  { value: "Bafl", label: "Bafl" },
  { value: "Bafna Finance", label: "Bafna Finance" },
  { value: "Bagadi Finance & Leasing", label: "Bagadi Finance & Leasing" },
  { value: "Bagalkot Co Bank Ltd", label: "Bagalkot Co Bank Ltd" },
  { value: "Bagalkot Dist. Central Co-Op. Bank Ltd.", label: "Bagalkot Dist. Central Co-Op. Bank Ltd." },
  { value: "Bagalkot Urban Co-Op Bank Ltd", label: "Bagalkot Urban Co-Op Bank Ltd" },
  { value: "Bagalkot Urban Co-Operative Bank Ltd", label: "Bagalkot Urban Co-Operative Bank Ltd" },
  { value: "Bagasara Nagarik Sahakari Mandali", label: "Bagasara Nagarik Sahakari Mandali" },
  { value: "Baghmar Care Pvt Ltd", label: "Baghmar Care Pvt Ltd" },
  { value: "Baghmar Finance Ltd", label: "Baghmar Finance Ltd" },
  { value: "Bagodi Finance", label: "Bagodi Finance" },
  { value: "Bagodi Finance Leasing Sindhanur", label: "Bagodi Finance Leasing Sindhanur" },
  { value: "Bagodt Fainance & Leasing", label: "Bagodt Fainance & Leasing" },
  { value: "Bagsara Vividh Juth Seva Sahakari Mandali Ltd", label: "Bagsara Vividh Juth Seva Sahakari Mandali Ltd" },
  { value: "Bagyodaya Co-Op Bank Ltd.", label: "Bagyodaya Co-Op Bank Ltd." },
  { value: "Bahraich District Co-Operative Bank Ltd.", label: "Bahraich District Co-Operative Bank Ltd." },
  { value: "Bahujan Hitay Nagari Sah Pat Sanstha", label: "Bahujan Hitay Nagari Sah Pat Sanstha" },
  { value: "Bahuudeshiya Nagri Pat Sanstha Maryadit", label: "Bahuudeshiya Nagri Pat Sanstha Maryadit" },
  { value: "Baid Finance", label: "Baid Finance" },
  { value: "Baid Finance Company", label: "Baid Finance Company" },
  { value: "Baid Leasing & Finance Ltd.", label: "Baid Leasing & Finance Ltd." },
  { value: "Baid Leasing And Finance", label: "Baid Leasing And Finance" },
  { value: "Baidehi Enterprises Pvt Ltd", label: "Baidehi Enterprises Pvt Ltd" },
  { value: "Baidyabati Sheoraphuli Coop Bank Ltd", label: "Baidyabati Sheoraphuli Coop Bank Ltd" },
  { value: "Baidyabati Sherophuli Co-Op Bank Ltd", label: "Baidyabati Sherophuli Co-Op Bank Ltd" },
  { value: "Baiju K  P", label: "Baiju K  P" },
  { value: "Baiju V", label: "Baiju V" },
  { value: "Baikuntha International", label: "Baikuntha International" },
  { value: "Bailhongal Merchants Co-Op Bank Ltd", label: "Bailhongal Merchants Co-Op Bank Ltd" },
  { value: "Bailhongal Urban Co-Op Bank Ltd", label: "Bailhongal Urban Co-Op Bank Ltd" },
  { value: "Baio Leasing & Finance Co.", label: "Baio Leasing & Finance Co." },
  { value: "Bairavi Finance & Investment (P) Ltd", label: "Bairavi Finance & Investment (P) Ltd" },
  { value: "Bairavi Finance & Investments Pvt Ltd", label: "Bairavi Finance & Investments Pvt Ltd" },
  { value: "Bairi Reddy Finance", label: "Bairi Reddy Finance" },
  { value: "Baitarani Bank", label: "Baitarani Bank" },
  { value: "Baitarani Gramya Bank,", label: "Baitarani Gramya Bank," },
  { value: "Baithul Islam Fin And Investment (India) Pvt Ltd.", label: "Baithul Islam Fin And Investment (India) Pvt Ltd." },
  { value: "Bajaj & Co", label: "Bajaj & Co" },
  { value: "Bajaj Alience", label: "Bajaj Alience" },
  { value: "Bajaj Auto Finance Ltd - Pune", label: "Bajaj Auto Finance Ltd - Pune" },
  { value: "Bajaj Auto Finance Ltd.", label: "Bajaj Auto Finance Ltd." },
  { value: "Bajaj Auto Ltd", label: "Bajaj Auto Ltd" },
  { value: "Bajaj Chetak Finance", label: "Bajaj Chetak Finance" },
  { value: "Bajaj Finance Co.", label: "Bajaj Finance Co." },
  { value: "Bajaj Financiers", label: "Bajaj Financiers" },
  { value: "Bajaj Hirers And Lessors Ltd", label: "Bajaj Hirers And Lessors Ltd" },
  { value: "Bajaj Instalments Supply Ltd.", label: "Bajaj Instalments Supply Ltd." },
  { value: "Bajaj Leasing And Finance Limited", label: "Bajaj Leasing And Finance Limited" },
  { value: "Bajaj Minidor Pick Up 499 Cc Is Not Available", label: "Bajaj Minidor Pick Up 499 Cc Is Not Available" },
  { value: "Bajaj Motors Finance Ltd", label: "Bajaj Motors Finance Ltd" },
  { value: "Bajaj Reddy Finance", label: "Bajaj Reddy Finance" },
  { value: "Bajarang Hire Purchase", label: "Bajarang Hire Purchase" },
  { value: "Bajirao Appa Sahakari Bank Ltd.", label: "Bajirao Appa Sahakari Bank Ltd." },
  { value: "Bajpe Vyavasaya Seva Sahakari Bank Ltd.", label: "Bajpe Vyavasaya Seva Sahakari Bank Ltd." },
  { value: "Bajpe Vyavasaya Seva Sahakari Bank Niyamitha.", label: "Bajpe Vyavasaya Seva Sahakari Bank Niyamitha." },
  { value: "Bajpe Vyavasaya Seva Sahakari Bank Nyt.", label: "Bajpe Vyavasaya Seva Sahakari Bank Nyt." },
  { value: "Bajrang Associate", label: "Bajrang Associate" },
  { value: "Bajrang Gramin Bigar Sheti Patsansta", label: "Bajrang Gramin Bigar Sheti Patsansta" },
  { value: "Bajwa Advance Pvt. Ltd. Khanna", label: "Bajwa Advance Pvt. Ltd. Khanna" },
  { value: "Bajwa Finance Company", label: "Bajwa Finance Company" },
  { value: "Bakaji Patil Bigar Sheti Sahakari Path Pedhi", label: "Bakaji Patil Bigar Sheti Sahakari Path Pedhi" },
  { value: "Bakajirao Patil Gra. Big. S. S. P. Ltd", label: "Bakajirao Patil Gra. Big. S. S. P. Ltd" },
  { value: "Bakshi Auto Deals", label: "Bakshi Auto Deals" },
  { value: "Bala Ji Enterpries", label: "Bala Ji Enterpries" },
  { value: "Bala Murugan Auto Finance", label: "Bala Murugan Auto Finance" },
  { value: "Balageria Central Co-Op Bank Ltd.", label: "Balageria Central Co-Op Bank Ltd." },
  { value: "Balageria Central Co-Operative Bank Ltd.", label: "Balageria Central Co-Operative Bank Ltd." },
  { value: "Balageria District Central Co-Operative Bank Ltd.", label: "Balageria District Central Co-Operative Bank Ltd." },
  { value: "Balajee Associates", label: "Balajee Associates" },
  { value: "Balaji  Automotive Financiers", label: "Balaji  Automotive Financiers" },
  { value: "Balaji  Instalments  (P) Ltd", label: "Balaji  Instalments  (P) Ltd" },
  { value: "Balaji  Instalments Supply  (P) Ltd", label: "Balaji  Instalments Supply  (P) Ltd" },
  { value: "Balaji & Co", label: "Balaji & Co" },
  { value: "Balaji & Co", label: "Balaji & Co" },
  { value: "Balaji & Co Finance", label: "Balaji & Co Finance" },
  { value: "Balaji And Company", label: "Balaji And Company" },
  { value: "Balaji Auto Consultant", label: "Balaji Auto Consultant" },
  { value: "Balaji Automobles And Finance Co", label: "Balaji Automobles And Finance Co" },
  { value: "Balaji Employee & Auto Finance", label: "Balaji Employee & Auto Finance" },
  { value: "Balaji Fin.Co.Regd.", label: "Balaji Fin.Co.Regd." },
  { value: "Balaji Finance", label: "Balaji Finance" },
  { value: "Balaji Finance Company  New Delhi", label: "Balaji Finance Company  New Delhi" },
  { value: "Balaji Finance,Surt", label: "Balaji Finance,Surt" },
  { value: "Balaji Fund Growth Ltd.", label: "Balaji Fund Growth Ltd." },
  { value: "Balaji Fund Growth.", label: "Balaji Fund Growth." },
  { value: "Balaji Gramin Nagari Sahakari Patsanstha", label: "Balaji Gramin Nagari Sahakari Patsanstha" },
  { value: "Balaji Hp And Finance", label: "Balaji Hp And Finance" },
  { value: "Balaji Indofin (P) Ltd", label: "Balaji Indofin (P) Ltd" },
  { value: "Balaji Instalments Supply Ltd", label: "Balaji Instalments Supply Ltd" },
  { value: "Balaji Investments", label: "Balaji Investments" },
  { value: "Balaji M & G Finance", label: "Balaji M & G Finance" },
  { value: "Balaji Motor Credit", label: "Balaji Motor Credit" },
  { value: "Balaji Motor Finance Corporation", label: "Balaji Motor Finance Corporation" },
  { value: "Balaji Motors", label: "Balaji Motors" },
  { value: "Balaji Nag.Sah.Mah.Mar ,Br Katar Khatav", label: "Balaji Nag.Sah.Mah.Mar ,Br Katar Khatav" },
  { value: "Balaji Nagari Sah Pat Sanstha", label: "Balaji Nagari Sah Pat Sanstha" },
  { value: "Balaji Sahakari Patpedi Ltd", label: "Balaji Sahakari Patpedi Ltd" },
  { value: "Balaji Urban Co-Op. Bank Ltd.", label: "Balaji Urban Co-Op. Bank Ltd." },
  { value: "Balammadevi Auto Leasing", label: "Balammadevi Auto Leasing" },
  { value: "Balasaheb Co Op Credit Soc", label: "Balasaheb Co Op Credit Soc" },
  { value: "Balasinor Nagrik Sahakari Bank Ltd", label: "Balasinor Nagrik Sahakari Bank Ltd" },
  { value: "Balasinor Road Transport Co Credit Soc Ltd", label: "Balasinor Road Transport Co Credit Soc Ltd" },
  { value: "Balasinor Vikas Co Op Credit Soc Ltd", label: "Balasinor Vikas Co Op Credit Soc Ltd" },
  { value: "Balasinor Vikas Co-Op Credit Soc. Ltd.,", label: "Balasinor Vikas Co-Op Credit Soc. Ltd.," },
  { value: "Balasore Bhadrak Cent Co-Op Bank Ltd", label: "Balasore Bhadrak Cent Co-Op Bank Ltd" },
  { value: "Balasore District Central Co-Operative Bank Ltd.", label: "Balasore District Central Co-Operative Bank Ltd." },
  { value: "Balasore Gramya Bank", label: "Balasore Gramya Bank" },
  { value: "Balasore Mercantile Credit Co-Op Ltd", label: "Balasore Mercantile Credit Co-Op Ltd" },
  { value: "Balavinayaga Finance", label: "Balavinayaga Finance" },
  { value: "Balbir Finance Ltd", label: "Balbir Finance Ltd" },
  { value: "Baldaskrupa Mahadevrao Mahadik Gramin Bigar Sheti Sahakari Pat Santha Maryadit", label: "Baldaskrupa Mahadevrao Mahadik Gramin Bigar Sheti Sahakari Pat Santha Maryadit" },
  { value: "Baldeep Finance Co", label: "Baldeep Finance Co" },
  { value: "Baldev Finance Company", label: "Baldev Finance Company" },
  { value: "Baldev Finance Pvt. Ltd.", label: "Baldev Finance Pvt. Ltd." },
  { value: "Baldev Finlease Pvt Ltd", label: "Baldev Finlease Pvt Ltd" },
  { value: "Balia Kshetriya Gramin Bank", label: "Balia Kshetriya Gramin Bank" },
  { value: "Baliraja Gramin Bigar Sati Patsanstha", label: "Baliraja Gramin Bigar Sati Patsanstha" },
  { value: "Balitikuri Co-Operative Bank Ltd", label: "Balitikuri Co-Operative Bank Ltd" },
  { value: "Baljeet Leasing And Credit Pvt Ltd", label: "Baljeet Leasing And Credit Pvt Ltd" },
  { value: "Ballaleshwar Sahakari Pathpedi Maryadit", label: "Ballaleshwar Sahakari Pathpedi Maryadit" },
  { value: "Ballaleshwar Sahakari Pathpedi Maryadit", label: "Ballaleshwar Sahakari Pathpedi Maryadit" },
  { value: "Ballia Etawa Gramin Bank", label: "Ballia Etawa Gramin Bank" },
  { value: "Bally Co-Operative Bank Ltd", label: "Bally Co-Operative Bank Ltd" },
  { value: "Balmer Laurie And Company Limited", label: "Balmer Laurie And Company Limited" },
  { value: "Balotara Urban Co-Op Bank Ltd", label: "Balotara Urban Co-Op Bank Ltd" },
  { value: "Balotra Urban Co-Op Bk Ltd.", label: "Balotra Urban Co-Op Bk Ltd." },
  { value: "Balotra Urban Co-Operative Bank Ltd.", label: "Balotra Urban Co-Operative Bank Ltd." },
  { value: "Balraj Hire Purchase Pvt. Ltd.", label: "Balraj Hire Purchase Pvt. Ltd." },
  { value: "Balu Auto Finance", label: "Balu Auto Finance" },
  { value: "Balu Auto Finance", label: "Balu Auto Finance" },
  { value: "Balurghat District Central Co-Operative Bank Ltd.", label: "Balurghat District Central Co-Operative Bank Ltd." },
  { value: "Balusseri Urban Co-Op Bank Ltd", label: "Balusseri Urban Co-Op Bank Ltd" },
  { value: "Bamane Janseva Sahakari Patpethi Ltd", label: "Bamane Janseva Sahakari Patpethi Ltd" },
  { value: "Bamboo Estate Range", label: "Bamboo Estate Range" },
  { value: "Bambwade Nagari Sahakari Path Sanstha", label: "Bambwade Nagari Sahakari Path Sanstha" },
  { value: "Bamne Janseva Credit Soc Ltd", label: "Bamne Janseva Credit Soc Ltd" },
  { value: "Bamne Janseva Sahakari Pathpedi Ltd.", label: "Bamne Janseva Sahakari Pathpedi Ltd." },
  { value: "Banahatti Minority Ur Co Op Cr Society Ltd", label: "Banahatti Minority Ur Co Op Cr Society Ltd" },
  { value: "Banaras Hindu University", label: "Banaras Hindu University" },
  { value: "Banaras Merchantile Co-Operative Bank", label: "Banaras Merchantile Co-Operative Bank" },
  { value: "Banaras Merchantile Co-Operative Bank Lt", label: "Banaras Merchantile Co-Operative Bank Lt" },
  { value: "Banashkantha Madhyasahakari Bank", label: "Banashkantha Madhyasahakari Bank" },
  { value: "Banaskantha District Central Co-Operative Bank Ltd.", label: "Banaskantha District Central Co-Operative Bank Ltd." },
  { value: "Banaskantha Mehsana Gramin Bank", label: "Banaskantha Mehsana Gramin Bank" },
  { value: "Banaskantha Mercantile Co Op Bank.", label: "Banaskantha Mercantile Co Op Bank." },
  { value: "Bancassurance Bank", label: "Bancassurance Bank" },
  { value: "Bancassurance Motor Bank Aplha", label: "Bancassurance Motor Bank Aplha" },
  { value: "Bancassurance Motor Bank Omega", label: "Bancassurance Motor Bank Omega" },
  { value: "Banda Dist. Co-Op Bank Ltd,", label: "Banda Dist. Co-Op Bank Ltd," },
  { value: "Banda Urban Co-Op Bank Ltd", label: "Banda Urban Co-Op Bank Ltd" },
  { value: "Banda Urban Co-Op. Bank Ltd.", label: "Banda Urban Co-Op. Bank Ltd." },
  { value: "Baner Nagari Sah Pat Maryadit", label: "Baner Nagari Sah Pat Maryadit" },
  { value: "Banga Gramin Bikash Bank", label: "Banga Gramin Bikash Bank" },
  { value: "Bangady Co-Op Agricultural Bank Ltd.", label: "Bangady Co-Op Agricultural Bank Ltd." },
  { value: "Bangaiya Gramin Bikash Bank", label: "Bangaiya Gramin Bikash Bank" },
  { value: "Bangalore City Co-Op Bank Ltd.", label: "Bangalore City Co-Op Bank Ltd." },
  { value: "Bangalore Commercial Transpoter Co-Op Ltd", label: "Bangalore Commercial Transpoter Co-Op Ltd" },
  { value: "Bangalore Credit Co-Operative Society Ltd", label: "Bangalore Credit Co-Operative Society Ltd" },
  { value: "Bangalore District Central Co-Operative Bank Ltd.", label: "Bangalore District Central Co-Operative Bank Ltd." },
  { value: "Bangalore Mercantile Co-Op. Bank Ltd", label: "Bangalore Mercantile Co-Op. Bank Ltd" },
  { value: "Bangalore Milk Union Ltd.", label: "Bangalore Milk Union Ltd." },
  { value: "Bangalore Souhardha C Co Op Bk Ltd", label: "Bangalore Souhardha C Co Op Bk Ltd" },
  { value: "Banganga Nagari Sahakari Pathsanstha", label: "Banganga Nagari Sahakari Pathsanstha" },
  { value: "Bangani Mercantiles (P) Ltd", label: "Bangani Mercantiles (P) Ltd" },
  { value: "Bangiya Gramin Bank", label: "Bangiya Gramin Bank" },
  { value: "Bangiya Gramin Vikash Bank.", label: "Bangiya Gramin Vikash Bank." },
  { value: "Banglore University", label: "Banglore University" },
  { value: "Banjari Global Financer Ltd", label: "Banjari Global Financer Ltd" },
  { value: "Bank Financial Services", label: "Bank Financial Services" },
  { value: "Bank Internasional Indonesia", label: "Bank Internasional Indonesia" },
  { value: "Bank Muscat", label: "Bank Muscat" },
  { value: "Bank Muscat International S. A. O. G", label: "Bank Muscat International S. A. O. G" },
  { value: "Bank Muscat International Saog", label: "Bank Muscat International Saog" },
  { value: "Bank Of  Nova Scotia", label: "Bank Of  Nova Scotia" },
  { value: "Bank Of Allahabad", label: "Bank Of Allahabad" },
  { value: "Bank Of Bahrain & Kuwait B.S.C.", label: "Bank Of Bahrain & Kuwait B.S.C." },
  { value: "Bank Of Bahrain And Kuwait Bsc., Mumbai", label: "Bank Of Bahrain And Kuwait Bsc., Mumbai" },
  { value: "Bank Of Baroda , Vardhari (Gu) - 388270", label: "Bank Of Baroda , Vardhari (Gu) - 388270" },
  { value: "Bank Of Baroda Easstern Gramin Bank", label: "Bank Of Baroda Easstern Gramin Bank" },
  { value: "Bank Of Baroda Eastern Gramin Bank Akbarpur  Ambedkar Nagar", label: "Bank Of Baroda Eastern Gramin Bank Akbarpur  Ambedkar Nagar" },
  { value: "Bank Of Baroda Vallabh Vidhyanagar Branc", label: "Bank Of Baroda Vallabh Vidhyanagar Branc" },
  { value: "Bank Of Baroda, Mumbai", label: "Bank Of Baroda, Mumbai" },
  { value: "Bank Of Baroda- Margao Brancc", label: "Bank Of Baroda- Margao Brancc" },
  { value: "Bank Of Bhutan", label: "Bank Of Bhutan" },
  { value: "Bank Of Cr. And Comm. Internl.(Overseas)Ltd., Mumbai", label: "Bank Of Cr. And Comm. Internl.(Overseas)Ltd., Mumbai" },
  { value: "Bank Of Goa", label: "Bank Of Goa" },
  { value: "Bank Of Goa", label: "Bank Of Goa" },
  { value: "Bank Of Goa Ltd.", label: "Bank Of Goa Ltd." },
  { value: "Bank Of Hyderabad", label: "Bank Of Hyderabad" },
  { value: "Bank Of India (Pen) Br Kamarli", label: "Bank Of India (Pen) Br Kamarli" },
  { value: "Bank Of Indore", label: "Bank Of Indore" },
  { value: "Bank Of Indore", label: "Bank Of Indore" },
  { value: "Bank Of Karnataka Ltd", label: "Bank Of Karnataka Ltd" },
  { value: "Bank Of Madura Ltd.", label: "Bank Of Madura Ltd." },
  { value: "Bank Of Muscat Saog", label: "Bank Of Muscat Saog" },
  { value: "Bank Of Mysore", label: "Bank Of Mysore" },
  { value: "Bank Of Nova Scotia", label: "Bank Of Nova Scotia" },
  { value: "Bank Of Patiyala", label: "Bank Of Patiyala" },
  { value: "Bank Of Punjab Ltd.", label: "Bank Of Punjab Ltd." },
  { value: "Bank Of Punjab Ltd.", label: "Bank Of Punjab Ltd." },
  { value: "Bank Of Rajasthan Ltd", label: "Bank Of Rajasthan Ltd" },
  { value: "Bank Of Rajasthan Ltd.", label: "Bank Of Rajasthan Ltd." },
  { value: "Bank Of Shyamrao Vithal Co Op Bank", label: "Bank Of Shyamrao Vithal Co Op Bank" },
  { value: "Bank Of Thanjavur Ltd.", label: "Bank Of Thanjavur Ltd." },
  { value: "Bank Of Tokyo Ltd.", label: "Bank Of Tokyo Ltd." },
  { value: "Bank Of Travancore", label: "Bank Of Travancore" },
  { value: "Bank Om Maharashtra", label: "Bank Om Maharashtra" },
  { value: "Bank Stock Holding Corporation Of India", label: "Bank Stock Holding Corporation Of India" },
  { value: "Bankey Bihari Finance", label: "Bankey Bihari Finance" },
  { value: "Banki District Central Co-Operative Bank Ltd.", label: "Banki District Central Co-Operative Bank Ltd." },
  { value: "Bankof Baroda", label: "Bankof Baroda" },
  { value: "Banks Of Consortium", label: "Banks Of Consortium" },
  { value: "Bankura Dist. Co-Op Agri & Rural Dev. Bank Ltd.", label: "Bankura Dist. Co-Op Agri & Rural Dev. Bank Ltd." },
  { value: "Bankura District Central Co-Op Bank Ltd.", label: "Bankura District Central Co-Op Bank Ltd." },
  { value: "Bankura District Central Co-Operative Bank Ltd.", label: "Bankura District Central Co-Operative Bank Ltd." },
  { value: "Bankura Town Co-Op. Bank Ltd.", label: "Bankura Town Co-Op. Bank Ltd." },
  { value: "Bannanje Grahakara Vividoddesh Sahakara Sangh Udupi", label: "Bannanje Grahakara Vividoddesh Sahakara Sangh Udupi" },
  { value: "Bannari Amman Auto Finance", label: "Bannari Amman Auto Finance" },
  { value: "Bannikopp Urban Co-Op Bank Ltd", label: "Bannikopp Urban Co-Op Bank Ltd" },
  { value: "Banque Indosuez", label: "Banque Indosuez" },
  { value: "Banque Indosuez, Bombay", label: "Banque Indosuez, Bombay" },
  { value: "Banque Nationale De Paris", label: "Banque Nationale De Paris" },
  { value: "Banque Nationale De Paris", label: "Banque Nationale De Paris" },
  { value: "Bansal Automart", label: "Bansal Automart" },
  { value: "Bansal Enterprises", label: "Bansal Enterprises" },
  { value: "Bansal Motor Finance Ltd.", label: "Bansal Motor Finance Ltd." },
  { value: "Bansal Motors", label: "Bansal Motors" },
  { value: "Bansla Fin Ltd", label: "Bansla Fin Ltd" },
  { value: "Banswara Com. Co-Op Bank Ltd", label: "Banswara Com. Co-Op Bank Ltd" },
  { value: "Banswara District Central Co-Operative Bank Ltd.", label: "Banswara District Central Co-Operative Bank Ltd." },
  { value: "Bantra Co-Op Bank Ltd.", label: "Bantra Co-Op Bank Ltd." },
  { value: "Bantwal Catholic Co Op Bank Ltd", label: "Bantwal Catholic Co Op Bank Ltd" },
  { value: "Banusaheb Gawde Nagari Patsanstha", label: "Banusaheb Gawde Nagari Patsanstha" },
  { value: "Banwari Finance Co", label: "Banwari Finance Co" },
  { value: "Banwat Auto Finance", label: "Banwat Auto Finance" },
  { value: "Bap Ins. Brokers Pvt. Ltd.", label: "Bap Ins. Brokers Pvt. Ltd." },
  { value: "Bapooji  Pattan Souharda Sahakari Niyamitha", label: "Bapooji  Pattan Souharda Sahakari Niyamitha" },
  { value: "Bapooji Pattina Souharda Sahakari Niyamitha", label: "Bapooji Pattina Souharda Sahakari Niyamitha" },
  { value: "Bapu Saheb Gawade Gramin Bigarsheti Shakari Patsanstha", label: "Bapu Saheb Gawade Gramin Bigarsheti Shakari Patsanstha" },
  { value: "Bapuji Bua Nagari Path Sanstha Maryadit", label: "Bapuji Bua Nagari Path Sanstha Maryadit" },
  { value: "Bapuji Co-Op Bank", label: "Bapuji Co-Op Bank" },
  { value: "Bapuji Co-Op Bank Ltd", label: "Bapuji Co-Op Bank Ltd" },
  { value: "Bapuji Pattin Souhard Sahakari Niyamit", label: "Bapuji Pattin Souhard Sahakari Niyamit" },
  { value: "Bapurao Saheb Vasantrao Deshmukh Patpedhi.", label: "Bapurao Saheb Vasantrao Deshmukh Patpedhi." },
  { value: "Barabarki Gramin Bank", label: "Barabarki Gramin Bank" },
  { value: "Baragarh S K U S Ltd.", label: "Baragarh S K U S Ltd." },
  { value: "Baramati Nagari Sah Patsah Mar", label: "Baramati Nagari Sah Patsah Mar" },
  { value: "Baramati Sahakari Bank Ltd.", label: "Baramati Sahakari Bank Ltd." },
  { value: "Baramecha Finance Corp.", label: "Baramecha Finance Corp." },
  { value: "Baramullah District Central Co-Operative Bank Ltd.", label: "Baramullah District Central Co-Operative Bank Ltd." },
  { value: "Baran Kendriya Sahakari Bank Ltd,", label: "Baran Kendriya Sahakari Bank Ltd," },
  { value: "Baran Nagrik Sahakari Bank Ltd.", label: "Baran Nagrik Sahakari Bank Ltd." },
  { value: "Baranagar Co.Operative Bank Ltd.", label: "Baranagar Co.Operative Bank Ltd." },
  { value: "Barath Finance Ltd - Namakkal.", label: "Barath Finance Ltd - Namakkal." },
  { value: "Barath Kumar Finance", label: "Barath Kumar Finance" },
  { value: "Barathi Credits", label: "Barathi Credits" },
  { value: "Baraut Credits Pvt Ltd", label: "Baraut Credits Pvt Ltd" },
  { value: "Barclays Bank Plc", label: "Barclays Bank Plc" },
  { value: "Barclays Bank Plc.", label: "Barclays Bank Plc." },
  { value: "Bardez Urban Co-Op Credit", label: "Bardez Urban Co-Op Credit" },
  { value: "Bardhaman Germin Bank", label: "Bardhaman Germin Bank" },
  { value: "Bardoli Nagrik Sahakari Co Op Bank", label: "Bardoli Nagrik Sahakari Co Op Bank" },
  { value: "Bareilly Corporation Bank Ltd", label: "Bareilly Corporation Bank Ltd" },
  { value: "Bareilly Corporation Bank Ltd.", label: "Bareilly Corporation Bank Ltd." },
  { value: "Bareilly Kshetriya Gramin Bank", label: "Bareilly Kshetriya Gramin Bank" },
  { value: "Bareilly Western Uttar Pradesh Gr Bank", label: "Bareilly Western Uttar Pradesh Gr Bank" },
  { value: "Bareily Kshetriya Gramin Bank", label: "Bareily Kshetriya Gramin Bank" },
  { value: "Bareja Automobile Ltd", label: "Bareja Automobile Ltd" },
  { value: "Bargavi Finance", label: "Bargavi Finance" },
  { value: "Bargur Primary Agricultural Co-Op Bank L", label: "Bargur Primary Agricultural Co-Op Bank L" },
  { value: "Baria Nagarik Sahakari Bank Ltd", label: "Baria Nagarik Sahakari Bank Ltd" },
  { value: "Baring Private Equity Partners (India) Limited", label: "Baring Private Equity Partners (India) Limited" },
  { value: "Baripada Urban Co-Op Bank Ltd", label: "Baripada Urban Co-Op Bank Ltd" },
  { value: "Barkha Financiers Ltd.", label: "Barkha Financiers Ltd." },
  { value: "Barkur Sahakari Vyavasayika Bank Niyamitha", label: "Barkur Sahakari Vyavasayika Bank Niyamitha" },
  { value: "Barmecha Finance Corporation", label: "Barmecha Finance Corporation" },
  { value: "Barmer District Central Co-Operative Bank Ltd.", label: "Barmer District Central Co-Operative Bank Ltd." },
  { value: "Barod Purwi Chetriya Gramin Bank", label: "Barod Purwi Chetriya Gramin Bank" },
  { value: "Baroda Central Co-Op Bank Ltd", label: "Baroda Central Co-Op Bank Ltd" },
  { value: "Baroda City Co-Op Bank Ltd.", label: "Baroda City Co-Op Bank Ltd." },
  { value: "Baroda Dist. Indl.Co-Op Bank Ltd.", label: "Baroda Dist. Indl.Co-Op Bank Ltd." },
  { value: "Baroda District Indl. Co-Op. Bank Ltd.", label: "Baroda District Indl. Co-Op. Bank Ltd." },
  { value: "Baroda Eastern U.P.Gramin Bank", label: "Baroda Eastern U.P.Gramin Bank" },
  { value: "Baroda Eastern Up Gramin Bank", label: "Baroda Eastern Up Gramin Bank" },
  { value: "Baroda Fincap Ltd", label: "Baroda Fincap Ltd" },
  { value: "Baroda Gramin Bank", label: "Baroda Gramin Bank" },
  { value: "Baroda Kshetriya Gramin Bank", label: "Baroda Kshetriya Gramin Bank" },
  { value: "Baroda Mercantile Co-Op Bank Ltd.", label: "Baroda Mercantile Co-Op Bank Ltd." },
  { value: "Baroda Paschimi U.P Gramin Bank,", label: "Baroda Paschimi U.P Gramin Bank," },
  { value: "Baroda Peoples Co-Op Bank Ltd.", label: "Baroda Peoples Co-Op Bank Ltd." },
  { value: "Baroda Poorbi Up Gramin Bank", label: "Baroda Poorbi Up Gramin Bank" },
  { value: "Baroda Purui U P Gramin Bank Sarsaul", label: "Baroda Purui U P Gramin Bank Sarsaul" },
  { value: "Baroda Purvi U.P.G.B. Shambhuga", label: "Baroda Purvi U.P.G.B. Shambhuga" },
  { value: "Baroda Purvi Uttar Pradesh Gramin Bank,", label: "Baroda Purvi Uttar Pradesh Gramin Bank," },
  { value: "Baroda Rajasthan Gramin Bank - Baravenda", label: "Baroda Rajasthan Gramin Bank - Baravenda" },
  { value: "Baroda Traders Co-Op Bank Ltd.", label: "Baroda Traders Co-Op Bank Ltd." },
  { value: "Baroda Western Skgb", label: "Baroda Western Skgb" },
  { value: "Baroda Western U P Gramin Bank.", label: "Baroda Western U P Gramin Bank." },
  { value: "Barsat Investment Pvt. Ltd.", label: "Barsat Investment Pvt. Ltd." },
  { value: "Basant & Company", label: "Basant & Company" },
  { value: "Basant Automobile Financiers", label: "Basant Automobile Financiers" },
  { value: "Basava Auto Finance", label: "Basava Auto Finance" },
  { value: "Basava Shree Nawkarar Pathin Sangha Ltd", label: "Basava Shree Nawkarar Pathin Sangha Ltd" },
  { value: "Basavakalyan Pattan Sahakar Bank Niyamit", label: "Basavakalyan Pattan Sahakar Bank Niyamit" },
  { value: "Basavaraj Ravadi", label: "Basavaraj Ravadi" },
  { value: "Basavarajendra Souharda Pattin Sahakari Niyamit", label: "Basavarajendra Souharda Pattin Sahakari Niyamit" },
  { value: "Basavasti Navkara Uathina Sahakari San N", label: "Basavasti Navkara Uathina Sahakari San N" },
  { value: "Basaveshwar  Co-Op Credit Bank", label: "Basaveshwar  Co-Op Credit Bank" },
  { value: "Basaveshwar Bank Ltd", label: "Basaveshwar Bank Ltd" },
  { value: "Basaveshwar Co Op Credit Society Ltd", label: "Basaveshwar Co Op Credit Society Ltd" },
  { value: "Basaveshwar Co-Op Bank Ltd", label: "Basaveshwar Co-Op Bank Ltd" },
  { value: "Basaveshwar Pattin Sauhard Sahakari Niyamit", label: "Basaveshwar Pattin Sauhard Sahakari Niyamit" },
  { value: "Basaveshwar Pattinna Sah Bank Niyamitha Sindagi", label: "Basaveshwar Pattinna Sah Bank Niyamitha Sindagi" },
  { value: "Basaveshwar Sahakar Bank Niyamit,", label: "Basaveshwar Sahakar Bank Niyamit," },
  { value: "Basaveshwar Sahakari Bank", label: "Basaveshwar Sahakari Bank" },
  { value: "Basaveshwar Souhard Credit Sahakari Niyamit", label: "Basaveshwar Souhard Credit Sahakari Niyamit" },
  { value: "Basaveshwar Urban Co-Operative Credit Society", label: "Basaveshwar Urban Co-Operative Credit Society" },
  { value: "Basaveshwar Urban Co-Operative Society Gurlapur", label: "Basaveshwar Urban Co-Operative Society Gurlapur" },
  { value: "Basaveshwar Urban Credit Co Op Society Galgali", label: "Basaveshwar Urban Credit Co Op Society Galgali" },
  { value: "Basaveshwara Credit Co- Op Soc Ltd", label: "Basaveshwara Credit Co- Op Soc Ltd" },
  { value: "Basaveshwara Pattana Sahakari Bnak Niyamitha", label: "Basaveshwara Pattana Sahakari Bnak Niyamitha" },
  { value: "Basaveswara Pattana Sahakari Sangha Bank Niyamita", label: "Basaveswara Pattana Sahakari Sangha Bank Niyamita" },
  { value: "Basilco India Lmt.", label: "Basilco India Lmt." },
  { value: "Basist Leasing And Finance Company Limited", label: "Basist Leasing And Finance Company Limited" },
  { value: "Basoda Nagarik Sahakari Bank Ltd", label: "Basoda Nagarik Sahakari Bank Ltd" },
  { value: "Basoda Nagrik Sahakari Bank Ltd", label: "Basoda Nagrik Sahakari Bank Ltd" },
  { value: "Basoda Nagrik Sahkari Bank Marya.", label: "Basoda Nagrik Sahkari Bank Marya." },
  { value: "Bassein Catholic Co-Op Bank", label: "Bassein Catholic Co-Op Bank" },
  { value: "Bassein Catholic Co-Op Bank Ltd.", label: "Bassein Catholic Co-Op Bank Ltd." },
  { value: "Bassein Catholic Co-Operative Bank Ltd.", label: "Bassein Catholic Co-Operative Bank Ltd." },
  { value: "Bassein Co-Op Bank", label: "Bassein Co-Op Bank" },
  { value: "Bastar Kshatriya Grameen Bank", label: "Bastar Kshatriya Grameen Bank" },
  { value: "Bastar Kshetriya Gramin Bank", label: "Bastar Kshetriya Gramin Bank" },
  { value: "Bastimal And Sons", label: "Bastimal And Sons" },
  { value: "Basundhara Agencies Pvt. Ltd.", label: "Basundhara Agencies Pvt. Ltd." },
  { value: "Basuveshwar Sahakar Bank Niyamit", label: "Basuveshwar Sahakar Bank Niyamit" },
  { value: "Basweshwara Motors", label: "Basweshwara Motors" },
  { value: "Batan Chand Bothara", label: "Batan Chand Bothara" },
  { value: "Bathla And Co. Ltd.", label: "Bathla And Co. Ltd." },
  { value: "Batra G Auto Deals", label: "Batra G Auto Deals" },
  { value: "Batra Motors And Genral Financiers", label: "Batra Motors And Genral Financiers" },
  { value: "Batri Reddy Finance", label: "Batri Reddy Finance" },
  { value: "Bavla Nagrik Sahakari Bank Ltd., Ahmedabad", label: "Bavla Nagrik Sahakari Bank Ltd., Ahmedabad" },
  { value: "Bawa Motors", label: "Bawa Motors" },
  { value: "Bawan Investment", label: "Bawan Investment" },
  { value: "Baweja Traders", label: "Baweja Traders" },
  { value: "Bayad Nagarik Sahakari Bk Ltd,", label: "Bayad Nagarik Sahakari Bk Ltd," },
  { value: "Bba Prathisthan Co-Op Credit Soc. Ltd.", label: "Bba Prathisthan Co-Op Credit Soc. Ltd." },
  { value: "Bba Pratishtan", label: "Bba Pratishtan" },
  { value: "Bbc Fin Pvt. Ltd.", label: "Bbc Fin Pvt. Ltd." },
  { value: "Bcb Ltd", label: "Bcb Ltd" },
  { value: "Bcc Bank Ltd", label: "Bcc Bank Ltd" },
  { value: "Bcc Bank Ltd", label: "Bcc Bank Ltd" },
  { value: "Bcp Finance & Investment Ltd", label: "Bcp Finance & Investment Ltd" },
  { value: "Bdb Finance", label: "Bdb Finance" },
  { value: "Bdcc Bank", label: "Bdcc Bank" },
  { value: "Be Gramin Bank Ltd", label: "Be Gramin Bank Ltd" },
  { value: "Beacon Ins. Pvt. Ltd.", label: "Beacon Ins. Pvt. Ltd." },
  { value: "Beas Valley Corporation Ltd.", label: "Beas Valley Corporation Ltd." },
  { value: "Beawar Urban Co- Op Bank Ltd", label: "Beawar Urban Co- Op Bank Ltd" },
  { value: "Becon Intrective Services", label: "Becon Intrective Services" },
  { value: "Bee Pee Jay Finance Ltd", label: "Bee Pee Jay Finance Ltd" },
  { value: "Bee Pee Jay Finance Ltd. Sbi Ssi", label: "Bee Pee Jay Finance Ltd. Sbi Ssi" },
  { value: "Beekay Financial Services", label: "Beekay Financial Services" },
  { value: "Beena Enterprises", label: "Beena Enterprises" },
  { value: "Beena Finance", label: "Beena Finance" },
  { value: "Beena Investments & Finance Ltd", label: "Beena Investments & Finance Ltd" },
  { value: "Beena John", label: "Beena John" },
  { value: "Beenadevi S Mehta", label: "Beenadevi S Mehta" },
  { value: "Beep Finance", label: "Beep Finance" },
  { value: "Beereshwar Souhard Credit Sahakari", label: "Beereshwar Souhard Credit Sahakari" },
  { value: "Beeru Brothers Finance Ltd", label: "Beeru Brothers Finance Ltd" },
  { value: "Begampura Nagrik Sahkari Mandli Ltd", label: "Begampura Nagrik Sahkari Mandli Ltd" },
  { value: "Begumpura Nag & Sahakari Co-Op.Credit Society", label: "Begumpura Nag & Sahakari Co-Op.Credit Society" },
  { value: "Begusarai Kshetriya Gramin Bank", label: "Begusarai Kshetriya Gramin Bank" },
  { value: "Belgaon Marathi Mitra Mandal Nagari Sahakari Pathsanstha", label: "Belgaon Marathi Mitra Mandal Nagari Sahakari Pathsanstha" },
  { value: "Belgaum Catholic Co-Op Bank Ltd", label: "Belgaum Catholic Co-Op Bank Ltd" },
  { value: "Belgaum Dist Revenue Employees Co-Op Bank Ltd", label: "Belgaum Dist Revenue Employees Co-Op Bank Ltd" },
  { value: "Belgaum Dist.Revenue Employes Co-Op Bank", label: "Belgaum Dist.Revenue Employes Co-Op Bank" },
  { value: "Belgaum District Central Co-Operative Bank Ltd.", label: "Belgaum District Central Co-Operative Bank Ltd." },
  { value: "Belgaum Industrial Co-Op Bank Ltd", label: "Belgaum Industrial Co-Op Bank Ltd" },
  { value: "Belief Consulting Pvt Ltd", label: "Belief Consulting Pvt Ltd" },
  { value: "Bell Leasing And Hire Purchase Ltd.", label: "Bell Leasing And Hire Purchase Ltd." },
  { value: "Bellad Bagewadi Souharda Co Op Bank Ltd", label: "Bellad Bagewadi Souharda Co Op Bank Ltd" },
  { value: "Bellad Bagewadi Urban Souhard Sahakari Niyamit", label: "Bellad Bagewadi Urban Souhard Sahakari Niyamit" },
  { value: "Bellad Enterprises Pvt Ltd", label: "Bellad Enterprises Pvt Ltd" },
  { value: "Bellad-Bagewadi Urban Co-Op Bank Ltd.", label: "Bellad-Bagewadi Urban Co-Op Bank Ltd." },
  { value: "Bellad-Bagewadi Urban Souhard Sah.Bk.Nyt", label: "Bellad-Bagewadi Urban Souhard Sah.Bk.Nyt" },
  { value: "Bellare Co- Op. Agricultural Bk. Ltd", label: "Bellare Co- Op. Agricultural Bk. Ltd" },
  { value: "Bellary District Central Co-Operative Bank Ltd.", label: "Bellary District Central Co-Operative Bank Ltd." },
  { value: "Bellary Urban Co-Operative Bank", label: "Bellary Urban Co-Operative Bank" },
  { value: "Bellur Gurabasav Pattin Sahakari Bank Nimit", label: "Bellur Gurabasav Pattin Sahakari Bank Nimit" },
  { value: "Belman Vyasaya Seva Sahakari Bank Ltd - Belman", label: "Belman Vyasaya Seva Sahakari Bank Ltd - Belman" },
  { value: "Belpu Co-Op Agricultural Bank Ni.", label: "Belpu Co-Op Agricultural Bank Ni." },
  { value: "Beltala Finance Co", label: "Beltala Finance Co" },
  { value: "Belthan.Tal Rub.Grow.Mkt&Proc.Co.Soc L", label: "Belthan.Tal Rub.Grow.Mkt&Proc.Co.Soc L" },
  { value: "Belur Urban Co-Op Bank Ltd.", label: "Belur Urban Co-Op Bank Ltd." },
  { value: "Belur Urban Co-Operative Bank Ltd.", label: "Belur Urban Co-Operative Bank Ltd." },
  { value: "Beml Finance Ltd.", label: "Beml Finance Ltd." },
  { value: "Benaras State Bank Ltd.", label: "Benaras State Bank Ltd." },
  { value: "Bengal Chemicals And Pharmaceuticals Ltd.", label: "Bengal Chemicals And Pharmaceuticals Ltd." },
  { value: "Bengal Immunity Ltd.", label: "Bengal Immunity Ltd." },
  { value: "Bengani Investments", label: "Bengani Investments" },
  { value: "Bengani Mercantiles", label: "Bengani Mercantiles" },
  { value: "Benipal & Co.", label: "Benipal & Co." },
  { value: "Benseion Catholic Bank", label: "Benseion Catholic Bank" },
  { value: "Berar Fin.Co.Ltd, Nagpur", label: "Berar Fin.Co.Ltd, Nagpur" },
  { value: "Berar Finance Limited.", label: "Berar Finance Limited." },
  { value: "Berhampore Urban Co-Op.Credit Society Lt", label: "Berhampore Urban Co-Op.Credit Society Lt" },
  { value: "Berhampur District Central Co-Operative Bank Ltd.", label: "Berhampur District Central Co-Operative Bank Ltd." },
  { value: "Beribel Auto Finance, Khanna", label: "Beribel Auto Finance, Khanna" },
  { value: "Betala Finance", label: "Betala Finance" },
  { value: "Betala Motor Finance Ltd", label: "Betala Motor Finance Ltd" },
  { value: "Betul Nagrik Sahakari Bank Mydt.", label: "Betul Nagrik Sahakari Bank Mydt." },
  { value: "Beupgb", label: "Beupgb" },
  { value: "Bfl Finance Ltd", label: "Bfl Finance Ltd" },
  { value: "Bhabesh Deka", label: "Bhabesh Deka" },
  { value: "Bhabesh Deka Finance", label: "Bhabesh Deka Finance" },
  { value: "Bhabha Atomic Research Centre", label: "Bhabha Atomic Research Centre" },
  { value: "Bhachar Vibhag Nagrik Sahakari Bank Ltd", label: "Bhachar Vibhag Nagrik Sahakari Bank Ltd" },
  { value: "Bhadana Finance Ltd.", label: "Bhadana Finance Ltd." },
  { value: "Bhadani Finance Corpn Ltd.", label: "Bhadani Finance Corpn Ltd." },
  { value: "Bhadar Gramin Bank", label: "Bhadar Gramin Bank" },
  { value: "Bhadgaon Peoples Co-Op Bank Ltd", label: "Bhadgaon Peoples Co-Op Bank Ltd" },
  { value: "Bhadiyad Group Seva Sahakari Mandali Ltd", label: "Bhadiyad Group Seva Sahakari Mandali Ltd" },
  { value: "Bhadiyad Seva Sahakari Ltd", label: "Bhadiyad Seva Sahakari Ltd" },
  { value: "Bhadohi Urban Co-Op Bank Ltd", label: "Bhadohi Urban Co-Op Bank Ltd" },
  { value: "Bhadohi Urban Co-Op Bank Ltd.", label: "Bhadohi Urban Co-Op Bank Ltd." },
  { value: "Bhadora Tractors", label: "Bhadora Tractors" },
  { value: "Bhadora Trctors", label: "Bhadora Trctors" },
  { value: "Bhadra Finance And Lease", label: "Bhadra Finance And Lease" },
  { value: "Bhadradri Co-Operative Urban Bank Ltd.", label: "Bhadradri Co-Operative Urban Bank Ltd." },
  { value: "Bhadran Gam Sahakari Sarafi Mandali", label: "Bhadran Gam Sahakari Sarafi Mandali" },
  { value: "Bhagaban Das Auto Finance Ltd.", label: "Bhagaban Das Auto Finance Ltd." },
  { value: "Bhagadeshwar G B S Pat", label: "Bhagadeshwar G B S Pat" },
  { value: "Bhagalpur Banka K G Bank.", label: "Bhagalpur Banka K G Bank." },
  { value: "Bhagalpur Banka Kshetriya Gramin Bank", label: "Bhagalpur Banka Kshetriya Gramin Bank" },
  { value: "Bhagamandala Vyavasaya Seva Sah. Bank N.", label: "Bhagamandala Vyavasaya Seva Sah. Bank N." },
  { value: "Bhagamandala Vyavasaya Seva Saha Bk L", label: "Bhagamandala Vyavasaya Seva Saha Bk L" },
  { value: "Bhagat Urban Co-Op Bank Ltd. Solan", label: "Bhagat Urban Co-Op Bank Ltd. Solan" },
  { value: "Bhagini Nivedita Sahakari Bank Ltd.", label: "Bhagini Nivedita Sahakari Bank Ltd." },
  { value: "Bhagini Nivedita Sha.Bank Maryadit, Pune", label: "Bhagini Nivedita Sha.Bank Maryadit, Pune" },
  { value: "Bhagirath Gramin Bank", label: "Bhagirath Gramin Bank" },
  { value: "Bhagoydaya Co Op Credit Pat Pedhi Ltd", label: "Bhagoydaya Co Op Credit Pat Pedhi Ltd" },
  { value: "Bhagoydaya Mahila Co-Operative Society", label: "Bhagoydaya Mahila Co-Operative Society" },
  { value: "Bhagshri Nagari Path Sanstha Maryadit.", label: "Bhagshri Nagari Path Sanstha Maryadit." },
  { value: "Bhagwaan Cars", label: "Bhagwaan Cars" },
  { value: "Bhagwan Financiers", label: "Bhagwan Financiers" },
  { value: "Bhagwan Hire Purchase Ltd.", label: "Bhagwan Hire Purchase Ltd." },
  { value: "Bhagwan Instalments Ltd.", label: "Bhagwan Instalments Ltd." },
  { value: "Bhagwandas Ramnath", label: "Bhagwandas Ramnath" },
  { value: "Bhagwandas Vyapar Udyog Ltd", label: "Bhagwandas Vyapar Udyog Ltd" },
  { value: "Bhagwanrao Bharne Nagari Sahakari Pat Sanstha", label: "Bhagwanrao Bharne Nagari Sahakari Pat Sanstha" },
  { value: "Bhagwant Sahakari Pathsanstha Maryadit", label: "Bhagwant Sahakari Pathsanstha Maryadit" },
  { value: "Bhagwat Associates", label: "Bhagwat Associates" },
  { value: "Bhagwati Financiers", label: "Bhagwati Financiers" },
  { value: "Bhagwati Mitra Mandal", label: "Bhagwati Mitra Mandal" },
  { value: "Bhagwati Motor Finance Co", label: "Bhagwati Motor Finance Co" },
  { value: "Bhagya Lakshmi Auto Mobile Finance", label: "Bhagya Lakshmi Auto Mobile Finance" },
  { value: "Bhagya Laxmi Gramin Biger Sheti Pat San Maryadit", label: "Bhagya Laxmi Gramin Biger Sheti Pat San Maryadit" },
  { value: "Bhagya Sri Auto Financers", label: "Bhagya Sri Auto Financers" },
  { value: "Bhagyajyothi Leasing", label: "Bhagyajyothi Leasing" },
  { value: "Bhagyajyothi Leasing", label: "Bhagyajyothi Leasing" },
  { value: "Bhagyalaxmi Mahila Gramin Biger Sahakari Pathasanstha Parner", label: "Bhagyalaxmi Mahila Gramin Biger Sahakari Pathasanstha Parner" },
  { value: "Bhagyalaxmi Mahila Sahakari Bank.", label: "Bhagyalaxmi Mahila Sahakari Bank." },
  { value: "Bhagyalaxmi Motor Credit", label: "Bhagyalaxmi Motor Credit" },
  { value: "Bhagyanagar Urban Co-Op Bank Ltd", label: "Bhagyanagar Urban Co-Op Bank Ltd" },
  { value: "Bhagyashree Gramin Bigar Sheti Sakhari Pantsanstha", label: "Bhagyashree Gramin Bigar Sheti Sakhari Pantsanstha" },
  { value: "Bhagyashree Nagarik Sahakari Path Sanstha Maryadit", label: "Bhagyashree Nagarik Sahakari Path Sanstha Maryadit" },
  { value: "Bhagyawanti Motors", label: "Bhagyawanti Motors" },
  { value: "Bhagyoday Co Op Credit Soc Ltd", label: "Bhagyoday Co Op Credit Soc Ltd" },
  { value: "Bhagyoday Sahakari  Pat. Ltd.", label: "Bhagyoday Sahakari  Pat. Ltd." },
  { value: "Bhagyoday Sahakari Patsanstha Ltd.", label: "Bhagyoday Sahakari Patsanstha Ltd." },
  { value: "Bhagyoday Ur Co Op Cr So Ltd", label: "Bhagyoday Ur Co Op Cr So Ltd" },
  { value: "Bhagyodaya Co-Op Bank Ltd", label: "Bhagyodaya Co-Op Bank Ltd" },
  { value: "Bhagyodaya Friends Urban Coop Bank Ltd,", label: "Bhagyodaya Friends Urban Coop Bank Ltd," },
  { value: "Bhagyodaya Nag Sah  Pat Sanstha", label: "Bhagyodaya Nag Sah  Pat Sanstha" },
  { value: "Bhagyodaya Sahakari Patpedhi Maryadit", label: "Bhagyodaya Sahakari Patpedhi Maryadit" },
  { value: "Bhagyodaya Sahakari Patpedi Maryadit", label: "Bhagyodaya Sahakari Patpedi Maryadit" },
  { value: "Bhagyodaya Urban Co-Op. Bank Ltd.", label: "Bhagyodaya Urban Co-Op. Bank Ltd." },
  { value: "Bhaichand Hirachand Raisoni Multi-State Co.Op. Credit Society", label: "Bhaichand Hirachand Raisoni Multi-State Co.Op. Credit Society" },
  { value: "Bhaichand Hirachand Raisoni Sahkari Patsanstha", label: "Bhaichand Hirachand Raisoni Sahkari Patsanstha" },
  { value: "Bhaichand Hirachand Raisony Co Op Bank Ltd.", label: "Bhaichand Hirachand Raisony Co Op Bank Ltd." },
  { value: "Bhairavji Finance", label: "Bhairavji Finance" },
  { value: "Bhairavnath Gramin Bigar Sheti S Pat.", label: "Bhairavnath Gramin Bigar Sheti S Pat." },
  { value: "Bhairavnath Nag Sah Bigar Sheti Path Sanstha", label: "Bhairavnath Nag Sah Bigar Sheti Path Sanstha" },
  { value: "Bhairavnath Nagari Path Sanstha Ltd.", label: "Bhairavnath Nagari Path Sanstha Ltd." },
  { value: "Bhairodan Pagaria & Sons", label: "Bhairodan Pagaria & Sons" },
  { value: "Bhairunath Dudh Udpatak Gramin Bigar Seti Pat Sanstha", label: "Bhairunath Dudh Udpatak Gramin Bigar Seti Pat Sanstha" },
  { value: "Bhajan Finance Corp", label: "Bhajan Finance Corp" },
  { value: "Bhakad Agro Agency Mau Chowpaty", label: "Bhakad Agro Agency Mau Chowpaty" },
  { value: "Bhakra Beas Management Board", label: "Bhakra Beas Management Board" },
  { value: "Bhalothia Finlease", label: "Bhalothia Finlease" },
  { value: "Bhamavaram Urban Co-Op Bank Ltd", label: "Bhamavaram Urban Co-Op Bank Ltd" },
  { value: "Bhandara District Central Co-Operative Bank Ltd.", label: "Bhandara District Central Co-Operative Bank Ltd." },
  { value: "Bhandari Automobile Finance", label: "Bhandari Automobile Finance" },
  { value: "Bhandari Co-Op Bank Ltd", label: "Bhandari Co-Op Bank Ltd" },
  { value: "Bhandari Co-Op. Bank Ltd.", label: "Bhandari Co-Op. Bank Ltd." },
  { value: "Bhandari Fin Corp", label: "Bhandari Fin Corp" },
  { value: "Bhandari Finance", label: "Bhandari Finance" },
  { value: "Bhandari Finance Corpn", label: "Bhandari Finance Corpn" },
  { value: "Bhandari Leafin Ltd", label: "Bhandari Leafin Ltd" },
  { value: "Bhandari Trader & Finance Corporation", label: "Bhandari Trader & Finance Corporation" },
  { value: "Bhandra Gramin Bank", label: "Bhandra Gramin Bank" },
  { value: "Bhandri Co-Operative Bank Ltd.", label: "Bhandri Co-Operative Bank Ltd." },
  { value: "Bhandup Sahakari Patsanstha Maryadit", label: "Bhandup Sahakari Patsanstha Maryadit" },
  { value: "Bhandup Vikas Sah Pat Ltd.", label: "Bhandup Vikas Sah Pat Ltd." },
  { value: "Bhandup Vikas Sahakari Patpedhi Maryadit", label: "Bhandup Vikas Sahakari Patpedhi Maryadit" },
  { value: "Bhangari Motor Fin", label: "Bhangari Motor Fin" },
  { value: "Bhanot Finance Private Limited", label: "Bhanot Finance Private Limited" },
  { value: "Bhansali Finance", label: "Bhansali Finance" },
  { value: "Bhansali Investment", label: "Bhansali Investment" },
  { value: "Bhanwarlal Sethiya Sons Finance", label: "Bhanwarlal Sethiya Sons Finance" },
  { value: "Bhanwarmal Daftari", label: "Bhanwarmal Daftari" },
  { value: "Bharani Sri Auto Finance", label: "Bharani Sri Auto Finance" },
  { value: "Bharat Agro", label: "Bharat Agro" },
  { value: "Bharat Aluminum Co. Ltd.", label: "Bharat Aluminum Co. Ltd." },
  { value: "Bharat Auto Leasing (R).", label: "Bharat Auto Leasing (R)." },
  { value: "Bharat Bhari Udyog Nigam Ltd.", label: "Bharat Bhari Udyog Nigam Ltd." },
  { value: "Bharat Brakes And Valves Ltd.", label: "Bharat Brakes And Valves Ltd." },
  { value: "Bharat Capital Servicesprivate Limited", label: "Bharat Capital Servicesprivate Limited" },
  { value: "Bharat Co Op Bank Mumbai Ltd", label: "Bharat Co Op Bank Mumbai Ltd" },
  { value: "Bharat Co Op Credit Society Ltd", label: "Bharat Co Op Credit Society Ltd" },
  { value: "Bharat Coking Coal Limited", label: "Bharat Coking Coal Limited" },
  { value: "Bharat Coop Bank", label: "Bharat Coop Bank" },
  { value: "Bharat Dynamics Ltd.", label: "Bharat Dynamics Ltd." },
  { value: "Bharat Earth Moves Ltd.", label: "Bharat Earth Moves Ltd." },
  { value: "Bharat Electronics Ltd.", label: "Bharat Electronics Ltd." },
  { value: "Bharat Finance Co.", label: "Bharat Finance Co." },
  { value: "Bharat Finance Corporation", label: "Bharat Finance Corporation" },
  { value: "Bharat Financiers.", label: "Bharat Financiers." },
  { value: "Bharat Gold Mines Ltd.", label: "Bharat Gold Mines Ltd." },
  { value: "Bharat Heavy Electricals Employees C.O.B", label: "Bharat Heavy Electricals Employees C.O.B" },
  { value: "Bharat Heavy Electricals Limited", label: "Bharat Heavy Electricals Limited" },
  { value: "Bharat Heavy Plate And Vessels Ltd.", label: "Bharat Heavy Plate And Vessels Ltd." },
  { value: "Bharat Immunological And Biological Corp.", label: "Bharat Immunological And Biological Corp." },
  { value: "Bharat Leather Corporation Ltd.", label: "Bharat Leather Corporation Ltd." },
  { value: "Bharat Mehta And Sons", label: "Bharat Mehta And Sons" },
  { value: "Bharat Motor Financier Regd", label: "Bharat Motor Financier Regd" },
  { value: "Bharat Opthalmic Glass Ltd.", label: "Bharat Opthalmic Glass Ltd." },
  { value: "Bharat Overseas Bank Ltd", label: "Bharat Overseas Bank Ltd" },
  { value: "Bharat Overseas Bank Ltd.", label: "Bharat Overseas Bank Ltd." },
  { value: "Bharat Overseas Bank Ltd.", label: "Bharat Overseas Bank Ltd." },
  { value: "Bharat Petroleum Corporation Limited", label: "Bharat Petroleum Corporation Limited" },
  { value: "Bharat Petroleum Corporation Ltd.", label: "Bharat Petroleum Corporation Ltd." },
  { value: "Bharat Petrolium Corporation Ltd.", label: "Bharat Petrolium Corporation Ltd." },
  { value: "Bharat Process And Mechanical Engineering Ltd.", label: "Bharat Process And Mechanical Engineering Ltd." },
  { value: "Bharat Pumps And Compressors Ltd.", label: "Bharat Pumps And Compressors Ltd." },
  { value: "Bharat Refractories Limited", label: "Bharat Refractories Limited" },
  { value: "Bharat Sahakari Bank Ltd.", label: "Bharat Sahakari Bank Ltd." },
  { value: "Bharat Sanchar Nigam Ltd.", label: "Bharat Sanchar Nigam Ltd." },
  { value: "Bharat Shrda (H U F)", label: "Bharat Shrda (H U F)" },
  { value: "Bharat State Bank", label: "Bharat State Bank" },
  { value: "Bharat Urban Co Op Bank Ltd", label: "Bharat Urban Co Op Bank Ltd" },
  { value: "Bharat Urban Co-Op. Bank Ltd.", label: "Bharat Urban Co-Op. Bank Ltd." },
  { value: "Bharat Wagon And Engineering Co. Ltd.", label: "Bharat Wagon And Engineering Co. Ltd." },
  { value: "Bharat Yantra Nigam Ltd.", label: "Bharat Yantra Nigam Ltd." },
  { value: "Bharath & Company", label: "Bharath & Company" },
  { value: "Bharath Chordia", label: "Bharath Chordia" },
  { value: "Bharath Co-Operative", label: "Bharath Co-Operative" },
  { value: "Bharath Credit Co-Operative Society Ltd", label: "Bharath Credit Co-Operative Society Ltd" },
  { value: "Bharath Finance Corporation", label: "Bharath Finance Corporation" },
  { value: "Bharath Kumar Finance", label: "Bharath Kumar Finance" },
  { value: "Bharath Motors", label: "Bharath Motors" },
  { value: "Bharath Petroleum  Company Ltd.", label: "Bharath Petroleum  Company Ltd." },
  { value: "Bharathi Auto Finance", label: "Bharathi Auto Finance" },
  { value: "Bharathi Credits", label: "Bharathi Credits" },
  { value: "Bharathi Finance", label: "Bharathi Finance" },
  { value: "Bharathiya Sahakara Bank Niyaitha,", label: "Bharathiya Sahakara Bank Niyaitha," },
  { value: "Bharathiya Samrudhi Finance Ltd", label: "Bharathiya Samrudhi Finance Ltd" },
  { value: "Bharati Sah Bank Ltd", label: "Bharati Sah Bank Ltd" },
  { value: "Bharati Sahakari Bank Limited", label: "Bharati Sahakari Bank Limited" },
  { value: "Bharati Sahakari Bank Ltd., Pune", label: "Bharati Sahakari Bank Ltd., Pune" },
  { value: "Bharatiya Sindhu Co-Op Cerdit Society Lt", label: "Bharatiya Sindhu Co-Op Cerdit Society Lt" },
  { value: "Bharatkumar B Desai", label: "Bharatkumar B Desai" },
  { value: "Bharatmata Sah.Pat.Sanstha", label: "Bharatmata Sah.Pat.Sanstha" },
  { value: "Bharatpur District Central Co-Operative Bank Ltd.", label: "Bharatpur District Central Co-Operative Bank Ltd." },
  { value: "Bharatratna Babasaheb Ambedkar Pratishthan Co-Op Bank Ltd", label: "Bharatratna Babasaheb Ambedkar Pratishthan Co-Op Bank Ltd" },
  { value: "Bhargavi Auto Financiers", label: "Bhargavi Auto Financiers" },
  { value: "Bhargavi Finance Co Ltd", label: "Bhargavi Finance Co Ltd" },
  { value: "Bharmu Nigoji Ganade Gramin Bigar Shety Sahkari Pat Sanstha Maryadit", label: "Bharmu Nigoji Ganade Gramin Bigar Shety Sahkari Pat Sanstha Maryadit" },
  { value: "Bharti Maind Nagari Sahakari Path Sanstha Maryadit", label: "Bharti Maind Nagari Sahakari Path Sanstha Maryadit" },
  { value: "Bharti Sahakari Bank Ltd", label: "Bharti Sahakari Bank Ltd" },
  { value: "Bhartia Samrudhi Finance Ltd.", label: "Bhartia Samrudhi Finance Ltd." },
  { value: "Bhartiya Jeevan Bima Nigam", label: "Bhartiya Jeevan Bima Nigam" },
  { value: "Bhartiya Jeevan Bima Nigam Panvel", label: "Bhartiya Jeevan Bima Nigam Panvel" },
  { value: "Bhartiya Parivahan Nigam", label: "Bhartiya Parivahan Nigam" },
  { value: "Bhartiya Sindhu Sah Pat Sanstha", label: "Bhartiya Sindhu Sah Pat Sanstha" },
  { value: "Bhartiya State Bank Ltd.", label: "Bhartiya State Bank Ltd." },
  { value: "Bharuch District Central Co-Operative Bank Ltd.", label: "Bharuch District Central Co-Operative Bank Ltd." },
  { value: "Bharuch Nagarik Sahakari Bank Ltd,", label: "Bharuch Nagarik Sahakari Bank Ltd," },
  { value: "Bhaskar Motors", label: "Bhaskar Motors" },
  { value: "Bhaskar Narayan Finance Co. (P) Ltd.", label: "Bhaskar Narayan Finance Co. (P) Ltd." },
  { value: "Bhat Sewa Sahakari Mandli", label: "Bhat Sewa Sahakari Mandli" },
  { value: "Bhatia Finance Co.", label: "Bhatia Finance Co." },
  { value: "Bhatia Investment", label: "Bhatia Investment" },
  { value: "Bhatia Trading Company", label: "Bhatia Trading Company" },
  { value: "Bhatinda District Central Co-Operative Bank Ltd.", label: "Bhatinda District Central Co-Operative Bank Ltd." },
  { value: "Bhatkal Agril & Rural Development C.B.L.", label: "Bhatkal Agril & Rural Development C.B.L." },
  { value: "Bhatkal Urban Co-Op Bank Ltd", label: "Bhatkal Urban Co-Op Bank Ltd" },
  { value: "Bhatpara Naihati Co-Op Bank Ltd", label: "Bhatpara Naihati Co-Op Bank Ltd" },
  { value: "Bhatpara-Naihati Co-Op. Bank Ltd.", label: "Bhatpara-Naihati Co-Op. Bank Ltd." },
  { value: "Bhausaheb Birajdar Nagri Sahakari Bk Lt.", label: "Bhausaheb Birajdar Nagri Sahakari Bk Lt." },
  { value: "Bhausaheb Hande Gramin Bigar Sheti Sah Patsansth Ltd.", label: "Bhausaheb Hande Gramin Bigar Sheti Sah Patsansth Ltd." },
  { value: "Bhausaheb Nawale Gramin Bigar Sheti Sah Patsansth Ltd.", label: "Bhausaheb Nawale Gramin Bigar Sheti Sah Patsansth Ltd." },
  { value: "Bhavana Sethi", label: "Bhavana Sethi" },
  { value: "Bhavanagar District Central Co-Operative Bank Ltd.", label: "Bhavanagar District Central Co-Operative Bank Ltd." },
  { value: "Bhavani Annai Auto Finance", label: "Bhavani Annai Auto Finance" },
  { value: "Bhavani Banjara Credit Souhard Sahakari Niyamith", label: "Bhavani Banjara Credit Souhard Sahakari Niyamith" },
  { value: "Bhavani Co-Op. Bank Ltd.", label: "Bhavani Co-Op. Bank Ltd." },
  { value: "Bhavani Coop Credit Soc Ltd", label: "Bhavani Coop Credit Soc Ltd" },
  { value: "Bhavani Finance", label: "Bhavani Finance" },
  { value: "Bhavani Finance Services", label: "Bhavani Finance Services" },
  { value: "Bhavani Gounder", label: "Bhavani Gounder" },
  { value: "Bhavani Hp & Finance", label: "Bhavani Hp & Finance" },
  { value: "Bhavani Sahakari Bank Ltd.", label: "Bhavani Sahakari Bank Ltd." },
  { value: "Bhavani Sahakati Bank L", label: "Bhavani Sahakati Bank L" },
  { value: "Bhavani Udhayam Finance", label: "Bhavani Udhayam Finance" },
  { value: "Bhavani Urban Co Bk L,", label: "Bhavani Urban Co Bk L," },
  { value: "Bhavani Urban Co-Op Bank Ltd,", label: "Bhavani Urban Co-Op Bank Ltd," },
  { value: "Bhavna Automobiles Pvt Ltd", label: "Bhavna Automobiles Pvt Ltd" },
  { value: "Bhavnagar Dist Co-Op Bank Ltd", label: "Bhavnagar Dist Co-Op Bank Ltd" },
  { value: "Bhavnagar District Co-Op Bank Ltd.", label: "Bhavnagar District Co-Op Bank Ltd." },
  { value: "Bhavnagar District Co-Operative Bank Ltd", label: "Bhavnagar District Co-Operative Bank Ltd" },
  { value: "Bhavnagar Mahila Nagarik Sahakari Bk L,", label: "Bhavnagar Mahila Nagarik Sahakari Bk L," },
  { value: "Bhavnagar Nagrik Sahakari Bank Ltd.", label: "Bhavnagar Nagrik Sahakari Bank Ltd." },
  { value: "Bhavsar Nagari Sahakari Pat Sanstha Mary", label: "Bhavsar Nagari Sahakari Pat Sanstha Mary" },
  { value: "Bhavsara Kshariya Co-Op Bank Ltd", label: "Bhavsara Kshariya Co-Op Bank Ltd" },
  { value: "Bhavya Associate", label: "Bhavya Associate" },
  { value: "Bhawani Patna District Central Co-Operative Bank Ltd.", label: "Bhawani Patna District Central Co-Operative Bank Ltd." },
  { value: "Bhawani Sahakari Bank Ltd", label: "Bhawani Sahakari Bank Ltd" },
  { value: "Bhawar Finance", label: "Bhawar Finance" },
  { value: "Bhawari Kavar Sisodia", label: "Bhawari Kavar Sisodia" },
  { value: "Bhawarlal Patowari (Huf)", label: "Bhawarlal Patowari (Huf)" },
  { value: "Bhawati Motor Finance Co.", label: "Bhawati Motor Finance Co." },
  { value: "Bhaweshwari Sahakari Patsanstha Ltd", label: "Bhaweshwari Sahakari Patsanstha Ltd" },
  { value: "Bhaweshwari Sahakari Patsantha Ltd", label: "Bhaweshwari Sahakari Patsantha Ltd" },
  { value: "Bhawna Investment", label: "Bhawna Investment" },
  { value: "Bhawna Investment Assam", label: "Bhawna Investment Assam" },
  { value: "Bhed Sangam Nag Sah Pat San", label: "Bhed Sangam Nag Sah Pat San" },
  { value: "Bhedasgaon Nagri Sahakari Pathsanstha Maryadit", label: "Bhedasgaon Nagri Sahakari Pathsanstha Maryadit" },
  { value: "Bhedsangam Nag Sah Pat San", label: "Bhedsangam Nag Sah Pat San" },
  { value: "Bhee Co-Operative Society", label: "Bhee Co-Operative Society" },
  { value: "Bhel Credit Co-Operative Society", label: "Bhel Credit Co-Operative Society" },
  { value: "Bhel Eccs Ltd", label: "Bhel Eccs Ltd" },
  { value: "Bhel Employees Co-Operative Bank Ltd", label: "Bhel Employees Co-Operative Bank Ltd" },
  { value: "Bhel Employees Coop Credit Society Ltd", label: "Bhel Employees Coop Credit Society Ltd" },
  { value: "Bhendekar Finance", label: "Bhendekar Finance" },
  { value: "Bherul Surana Sowcarpeth", label: "Bherul Surana Sowcarpeth" },
  { value: "Bherulal Surana", label: "Bherulal Surana" },
  { value: "Bhilai District Central Co-Op Bank Ltd", label: "Bhilai District Central Co-Op Bank Ltd" },
  { value: "Bhilai District Krushi Gramin Vikas Bank Ltd", label: "Bhilai District Krushi Gramin Vikas Bank Ltd" },
  { value: "Bhilai Nagarik Sahakari Bank Marydit", label: "Bhilai Nagarik Sahakari Bank Marydit" },
  { value: "Bhilwara -Ajmer Kshetriya Gramin Bank", label: "Bhilwara -Ajmer Kshetriya Gramin Bank" },
  { value: "Bhilwara District Central Co-Operative Bank Ltd.", label: "Bhilwara District Central Co-Operative Bank Ltd." },
  { value: "Bhilwara Mahila Urban Co-Op Bank Ltd", label: "Bhilwara Mahila Urban Co-Op Bank Ltd" },
  { value: "Bhilwara Mahila Urban Coop Bank Ltd", label: "Bhilwara Mahila Urban Coop Bank Ltd" },
  { value: "Bhilwara Urban Co-Op Bank Ltd", label: "Bhilwara Urban Co-Op Bank Ltd" },
  { value: "Bhilwara Urban Co-Operative Bank Ltd.", label: "Bhilwara Urban Co-Operative Bank Ltd." },
  { value: "Bhima Nagrik Sahkari Bank Ltd,", label: "Bhima Nagrik Sahkari Bank Ltd," },
  { value: "Bhimashankar Nagari Sahakari Bank Ltd,", label: "Bhimashankar Nagari Sahakari Bank Ltd," },
  { value: "Bhimashanker Nagari Sahakari Patsansta", label: "Bhimashanker Nagari Sahakari Patsansta" },
  { value: "Bhind Nagrik Sahakari Bamk Maryadit", label: "Bhind Nagrik Sahakari Bamk Maryadit" },
  { value: "Bhingar Nagari Sahakari Pathasanstha", label: "Bhingar Nagari Sahakari Pathasanstha" },
  { value: "Bhingar Urban Co-Operative Bank Ltd.", label: "Bhingar Urban Co-Operative Bank Ltd." },
  { value: "Bhiravnath Dhudh Udpadak", label: "Bhiravnath Dhudh Udpadak" },
  { value: "Bhiravnath Dhudh Udpadar Parner", label: "Bhiravnath Dhudh Udpadar Parner" },
  { value: "Bhiravnath Dhudh Udpadar Parver", label: "Bhiravnath Dhudh Udpadar Parver" },
  { value: "Bhiravnath Pat Sanstha", label: "Bhiravnath Pat Sanstha" },
  { value: "Bhirawnath Patsastha", label: "Bhirawnath Patsastha" },
  { value: "Bhiwani District Central Co-Operative Bank Ltd.", label: "Bhiwani District Central Co-Operative Bank Ltd." },
  { value: "Bhobesh Deka Finance", label: "Bhobesh Deka Finance" },
  { value: "Bhoj Nagarik Sahakari Bank Mydt.", label: "Bhoj Nagarik Sahakari Bank Mydt." },
  { value: "Bhoj Urban Co-Op Bank Ltd", label: "Bhoj Urban Co-Op Bank Ltd" },
  { value: "Bhojlingeshwar Finance And Investments", label: "Bhojlingeshwar Finance And Investments" },
  { value: "Bhojpur - Rohtas Gramin Bank", label: "Bhojpur - Rohtas Gramin Bank" },
  { value: "Bhojwani Leasing And Finance Ltd", label: "Bhojwani Leasing And Finance Ltd" },
  { value: "Bholath Hire Purchase Read", label: "Bholath Hire Purchase Read" },
  { value: "Bholenath Lease And Finance", label: "Bholenath Lease And Finance" },
  { value: "Bhomi Vikas Bank", label: "Bhomi Vikas Bank" },
  { value: "Bhoomi Tractor", label: "Bhoomi Tractor" },
  { value: "Bhoomtaluka Sahakri Patsanstha", label: "Bhoomtaluka Sahakri Patsanstha" },
  { value: "Bhopal Co-Op Central Bank Ltd., Bhopal", label: "Bhopal Co-Op Central Bank Ltd., Bhopal" },
  { value: "Bhopal Co-Op. Central Bank Ltd.", label: "Bhopal Co-Op. Central Bank Ltd." },
  { value: "Bhopal Nagrik Sahakari Bank Ltd., Bhopal", label: "Bhopal Nagrik Sahakari Bank Ltd., Bhopal" },
  { value: "Bhopal Nagrik Sahkari Bank Ltd", label: "Bhopal Nagrik Sahkari Bank Ltd" },
  { value: "Bhoparai Finance Corp.", label: "Bhoparai Finance Corp." },
  { value: "Bhosale Leasing & Finance Company Ltd", label: "Bhosale Leasing & Finance Company Ltd" },
  { value: "Bhowarilal Bohra", label: "Bhowarilal Bohra" },
  { value: "Bhpc", label: "Bhpc" },
  { value: "Bhramaramba Pattana Sahakara Sangha Niyamita", label: "Bhramaramba Pattana Sahakara Sangha Niyamita" },
  { value: "Bhramarambha Pattina Souharda Sahakari Niyamitha", label: "Bhramarambha Pattina Souharda Sahakari Niyamitha" },
  { value: "Bhramini Leaseng Finance", label: "Bhramini Leaseng Finance" },
  { value: "Bhuj Mercantile Coop. Bank", label: "Bhuj Mercantile Coop. Bank" },
  { value: "Bhumi Vikas Bank", label: "Bhumi Vikas Bank" },
  { value: "Bhumika Motors", label: "Bhumika Motors" },
  { value: "Bhupathi Raju Co-Op. Credit Bank Ltd.", label: "Bhupathi Raju Co-Op. Credit Bank Ltd." },
  { value: "Bhupendrabhai Chhaganlal Shah", label: "Bhupendrabhai Chhaganlal Shah" },
  { value: "Bhupgadh Juth Seva Sahakari Mandli Ltd", label: "Bhupgadh Juth Seva Sahakari Mandli Ltd" },
  { value: "Bhurani Associate", label: "Bhurani Associate" },
  { value: "Bhurat Finance And Leasing Co", label: "Bhurat Finance And Leasing Co" },
  { value: "Bhuravnath Dhudh Utpadak Gramin Bigar Sheti Sah Pat", label: "Bhuravnath Dhudh Utpadak Gramin Bigar Sheti Sah Pat" },
  { value: "Bhurtia Samrudhi Fin Ltd", label: "Bhurtia Samrudhi Fin Ltd" },
  { value: "Bhusawal Peoples Co-Op Bank Ltd", label: "Bhusawal Peoples Co-Op Bank Ltd" },
  { value: "Bhussan Auto Finance Pvt. Ltd.", label: "Bhussan Auto Finance Pvt. Ltd." },
  { value: "Bhutada Finance", label: "Bhutada Finance" },
  { value: "Bhutanath Co-Op Credit Society Ltd.", label: "Bhutanath Co-Op Credit Society Ltd." },
  { value: "Bhuteshwar Co-Op Credit Society Ltd", label: "Bhuteshwar Co-Op Credit Society Ltd" },
  { value: "Bhuteshwar Pattina Sahakari Sangh Niyamita - Sirsi", label: "Bhuteshwar Pattina Sahakari Sangh Niyamita - Sirsi" },
  { value: "Bhyraweshwara Co-Operative Bak Ltd Tumkur", label: "Bhyraweshwara Co-Operative Bak Ltd Tumkur" },
  { value: "Bible Society Of India", label: "Bible Society Of India" },
  { value: "Bicholim Urban Co-Op. Bank Ltd., Bicholim, Goa", label: "Bicholim Urban Co-Op. Bank Ltd., Bicholim, Goa" },
  { value: "Bico Bite Finance", label: "Bico Bite Finance" },
  { value: "Bid District Central Co-Operative Bank Ltd.", label: "Bid District Central Co-Operative Bank Ltd." },
  { value: "Bidamidevi", label: "Bidamidevi" },
  { value: "Bidar District Central Co-Operative Bank Ltd.", label: "Bidar District Central Co-Operative Bank Ltd." },
  { value: "Bidar Mahila Urban Co-Op Bank Ltd", label: "Bidar Mahila Urban Co-Op Bank Ltd" },
  { value: "Bidar Urban Co-Operative Bank Ltd.", label: "Bidar Urban Co-Operative Bank Ltd." },
  { value: "Biecco Lawrie Ltd.", label: "Biecco Lawrie Ltd." },
  { value: "Bihar Bidhan Sabha Patna", label: "Bihar Bidhan Sabha Patna" },
  { value: "Bihar Industrial Credit And Investment Corporation", label: "Bihar Industrial Credit And Investment Corporation" },
  { value: "Bihar Kshetriya Gramin Bank Ltd,", label: "Bihar Kshetriya Gramin Bank Ltd," },
  { value: "Bihar Kshetriya Gramin Bank Maraja", label: "Bihar Kshetriya Gramin Bank Maraja" },
  { value: "Bihar State Co-Op Bank Ltd.", label: "Bihar State Co-Op Bank Ltd." },
  { value: "Bihar State Financial Corporation", label: "Bihar State Financial Corporation" },
  { value: "Bihar State Minorities Fin.Corp.Ltd", label: "Bihar State Minorities Fin.Corp.Ltd" },
  { value: "Bihar State Tourism Development Corporation Ltd.", label: "Bihar State Tourism Development Corporation Ltd." },
  { value: "Bihari Ji Motor Financec Co. Pvt. Ltd", label: "Bihari Ji Motor Financec Co. Pvt. Ltd" },
  { value: "Bihari Ji Motor Financec Co. Pvt. Ltd.", label: "Bihari Ji Motor Financec Co. Pvt. Ltd." },
  { value: "Bijapur Co-Op Urban Bank.", label: "Bijapur Co-Op Urban Bank." },
  { value: "Bijapur District Central Co-Operative Bank Ltd.", label: "Bijapur District Central Co-Operative Bank Ltd." },
  { value: "Bijapur District Mahila Co-Op Bank Ltd", label: "Bijapur District Mahila Co-Op Bank Ltd" },
  { value: "Bijapur Jilla Sah.Naukarar Sah.Bank Niy", label: "Bijapur Jilla Sah.Naukarar Sah.Bank Niy" },
  { value: "Bijapur Mahalaxmi Urban Co-Op Bank", label: "Bijapur Mahalaxmi Urban Co-Op Bank" },
  { value: "Bijapur Mahalaxmi Urban Co-Op Bank Ltd", label: "Bijapur Mahalaxmi Urban Co-Op Bank Ltd" },
  { value: "Bijapur Sahakari Niyamit Bank", label: "Bijapur Sahakari Niyamit Bank" },
  { value: "Bijapur Zilla Sarakari Naukarar Sah B N", label: "Bijapur Zilla Sarakari Naukarar Sah B N" },
  { value: "Bijapur Zilla Sarakari Naukarar Sah Bk N", label: "Bijapur Zilla Sarakari Naukarar Sah Bk N" },
  { value: "Bijapur Zilla Sarakari Naukarar Sahakari Bank Niyamit", label: "Bijapur Zilla Sarakari Naukarar Sahakari Bank Niyamit" },
  { value: "Bijapur Zilla Sarakari Noukarar Sahatari", label: "Bijapur Zilla Sarakari Noukarar Sahatari" },
  { value: "Bijnor Lerasing Ltd", label: "Bijnor Lerasing Ltd" },
  { value: "Bijnor Urban Co-Op Bank Ltd", label: "Bijnor Urban Co-Op Bank Ltd" },
  { value: "Bikaner Commercial Co Ltd", label: "Bikaner Commercial Co Ltd" },
  { value: "Bikaner District Central Co-Operative Bank Ltd.", label: "Bikaner District Central Co-Operative Bank Ltd." },
  { value: "Bikaner Finance", label: "Bikaner Finance" },
  { value: "Bikaner Keshtriya  Gramin Bank,", label: "Bikaner Keshtriya  Gramin Bank," },
  { value: "Bikaner Urban Co-Op. Bank Ltd.", label: "Bikaner Urban Co-Op. Bank Ltd." },
  { value: "Bilagi Pattan Sahakari Bk Niyamit", label: "Bilagi Pattan Sahakari Bk Niyamit" },
  { value: "Bilasa Mahila Nagrik Sahakari Bank", label: "Bilasa Mahila Nagrik Sahakari Bank" },
  { value: "Bilaspur - Raipur Kshetriya Gramin Bank", label: "Bilaspur - Raipur Kshetriya Gramin Bank" },
  { value: "Bilaspur Nagrik Sahakari Bank Ltd.", label: "Bilaspur Nagrik Sahakari Bank Ltd." },
  { value: "Bilaspur Nagrik Sahkari Bank Ltd.", label: "Bilaspur Nagrik Sahkari Bank Ltd." },
  { value: "Bilaspur Raipur Kshetriya Gramin Bank Ltd", label: "Bilaspur Raipur Kshetriya Gramin Bank Ltd" },
  { value: "Bilinele Co-Op Agricultural Bank Ltd", label: "Bilinele Co-Op Agricultural Bank Ltd" },
  { value: "Bilul Gurubasan Co-Op Society Ltd", label: "Bilul Gurubasan Co-Op Society Ltd" },
  { value: "Bilur Gurubasava Pattin Sahakari Sangha", label: "Bilur Gurubasava Pattin Sahakari Sangha" },
  { value: "Bimla Enterprises Bst", label: "Bimla Enterprises Bst" },
  { value: "Bimla Enterprises Bulandsahar", label: "Bimla Enterprises Bulandsahar" },
  { value: "Bimla Finance", label: "Bimla Finance" },
  { value: "Bimla Jain Financiers", label: "Bimla Jain Financiers" },
  { value: "Binayakiya Auto Finanace", label: "Binayakiya Auto Finanace" },
  { value: "Binayakiya Finance", label: "Binayakiya Finance" },
  { value: "Bindhu Investments", label: "Bindhu Investments" },
  { value: "Bindiya Capital And Securities Pvt. Ltd.", label: "Bindiya Capital And Securities Pvt. Ltd." },
  { value: "Bindiya Capital Ltd", label: "Bindiya Capital Ltd" },
  { value: "Binny Auto Finance", label: "Binny Auto Finance" },
  { value: "Bio Technology Information System", label: "Bio Technology Information System" },
  { value: "Biplabi Kumar Roy", label: "Biplabi Kumar Roy" },
  { value: "Bir Credit", label: "Bir Credit" },
  { value: "Bir Finencer", label: "Bir Finencer" },
  { value: "Bir Motor Finance And Leasing Company", label: "Bir Motor Finance And Leasing Company" },
  { value: "Birbhum District Central Co-Operative Bank Ltd.", label: "Birbhum District Central Co-Operative Bank Ltd." },
  { value: "Birdev Sahkari Bank Ltd.", label: "Birdev Sahkari Bank Ltd." },
  { value: "Birds, Jute And Exports Ltd.", label: "Birds, Jute And Exports Ltd." },
  { value: "Birla Century Finance Ltd", label: "Birla Century Finance Ltd" },
  { value: "Birla Global Finance Ltd.", label: "Birla Global Finance Ltd." },
  { value: "Biroba Maharaj Gramin Bigarsheti Sahakari Pat Sanstha", label: "Biroba Maharaj Gramin Bigarsheti Sahakari Pat Sanstha" },
  { value: "Birur Pputtana Soucharda Puttana Sahakari Niyamita", label: "Birur Pputtana Soucharda Puttana Sahakari Niyamita" },
  { value: "Bishan Dass Wadhwa & Co.", label: "Bishan Dass Wadhwa & Co." },
  { value: "Bishon  Dass  Wadhwa  &  Co.", label: "Bishon  Dass  Wadhwa  &  Co." },
  { value: "Bisra Lamps Limited", label: "Bisra Lamps Limited" },
  { value: "Biswas Auto", label: "Biswas Auto" },
  { value: "Biviyes Finance And Leasing Ltd", label: "Biviyes Finance And Leasing Ltd" },
  { value: "Bkt Finance", label: "Bkt Finance" },
  { value: "Black Diamond Finance Ltd.", label: "Black Diamond Finance Ltd." },
  { value: "Blend Insurance Brokers Pvt Ltd", label: "Blend Insurance Brokers Pvt Ltd" },
  { value: "Blesson. P .Raj", label: "Blesson. P .Raj" },
  { value: "Blinic Finance Pvt Ltd", label: "Blinic Finance Pvt Ltd" },
  { value: "Blink Finance Pvt Ltd", label: "Blink Finance Pvt Ltd" },
  { value: "Blue Chip Finance Pvt. Ltd.", label: "Blue Chip Finance Pvt. Ltd." },
  { value: "Blue Diamond Securities And Finance Ltd.", label: "Blue Diamond Securities And Finance Ltd." },
  { value: "Blue Jay Finlease (P) Ltd.", label: "Blue Jay Finlease (P) Ltd." },
  { value: "Blue Line Finance Pvt. Ltd.", label: "Blue Line Finance Pvt. Ltd." },
  { value: "Blue Skies Travel & Tours  Pvt. Ltd.", label: "Blue Skies Travel & Tours  Pvt. Ltd." },
  { value: "Bluechip Corporate Investment Centre Limited", label: "Bluechip Corporate Investment Centre Limited" },
  { value: "Bmw India Financial Service Pvt Ltd", label: "Bmw India Financial Service Pvt Ltd" },
  { value: "Board For Industrial And Financial Reconstruction", label: "Board For Industrial And Financial Reconstruction" },
  { value: "Board Of Intermediate Education", label: "Board Of Intermediate Education" },
  { value: "Bobby Enterpri", label: "Bobby Enterpri" },
  { value: "Bohara Motors", label: "Bohara Motors" },
  { value: "Bohra Financiers", label: "Bohra Financiers" },
  { value: "Bohra Investments", label: "Bohra Investments" },
  { value: "Bohra Motor Finance", label: "Bohra Motor Finance" },
  { value: "Bohra Syndicate", label: "Bohra Syndicate" },
  { value: "Bokadia Finance", label: "Bokadia Finance" },
  { value: "Bokaro Steel Plant", label: "Bokaro Steel Plant" },
  { value: "Bokaro Steel Plant Sail", label: "Bokaro Steel Plant Sail" },
  { value: "Bolangir Anchalik Gramya Bank", label: "Bolangir Anchalik Gramya Bank" },
  { value: "Bolangir Anchalik Gramya Bank", label: "Bolangir Anchalik Gramya Bank" },
  { value: "Bolangir Central Co-Operative Bank Ltd", label: "Bolangir Central Co-Operative Bank Ltd" },
  { value: "Bolangir District Central Co-Operative Bank Ltd.", label: "Bolangir District Central Co-Operative Bank Ltd." },
  { value: "Bombay Co Op Bank Ltd", label: "Bombay Co Op Bank Ltd" },
  { value: "Bombay Mercantile Co-Op Bank Ltd", label: "Bombay Mercantile Co-Op Bank Ltd" },
  { value: "Bombay Mercantile Co-Operative Bank Ltd", label: "Bombay Mercantile Co-Operative Bank Ltd" },
  { value: "Bombay Stock Exchange", label: "Bombay Stock Exchange" },
  { value: "Bombay Taximen Association Co-Op Credit Society", label: "Bombay Taximen Association Co-Op Credit Society" },
  { value: "Bonanza Online Com Limited", label: "Bonanza Online Com Limited" },
  { value: "Bone Chem Pvt Ltd.", label: "Bone Chem Pvt Ltd." },
  { value: "Bongaigaon Refinary Petrochemical Ltd", label: "Bongaigaon Refinary Petrochemical Ltd" },
  { value: "Bongaigaon Refinery And Petrochemicals Ltd.", label: "Bongaigaon Refinery And Petrochemicals Ltd." },
  { value: "Booh Finance Co. Pvt. Ltd", label: "Booh Finance Co. Pvt. Ltd" },
  { value: "Boparai Finance Corp Regd", label: "Boparai Finance Corp Regd" },
  { value: "Boral Union Co-Op Bank Ltd", label: "Boral Union Co-Op Bank Ltd" },
  { value: "Border Security Force", label: "Border Security Force" },
  { value: "Borivali Jankalyan Pat Ltd", label: "Borivali Jankalyan Pat Ltd" },
  { value: "Borsad Nagrik Sahakari Bank Ltd", label: "Borsad Nagrik Sahakari Bank Ltd" },
  { value: "Botad  Co Op Urben Cr.& Consumer Soc,Ltd", label: "Botad  Co Op Urben Cr.& Consumer Soc,Ltd" },
  { value: "Botad People Co Op Bank", label: "Botad People Co Op Bank" },
  { value: "Bothara Credit Corporation", label: "Bothara Credit Corporation" },
  { value: "Bothra Credit Corporation", label: "Bothra Credit Corporation" },
  { value: "Bothra Credit Corporation", label: "Bothra Credit Corporation" },
  { value: "Bothra Finance Corporation", label: "Bothra Finance Corporation" },
  { value: "Bothra Finlease Corporation", label: "Bothra Finlease Corporation" },
  { value: "Bothra Finstock Pvt. Ltd.", label: "Bothra Finstock Pvt. Ltd." },
  { value: "Bothra Hire Purchase Co", label: "Bothra Hire Purchase Co" },
  { value: "Bothra Investments", label: "Bothra Investments" },
  { value: "Bothra Motor Finance", label: "Bothra Motor Finance" },
  { value: "Bothra Motor Financiar", label: "Bothra Motor Financiar" },
  { value: "Boudh District Central Co-Operative Bank Ltd.", label: "Boudh District Central Co-Operative Bank Ltd." },
  { value: "Bpcl Employees Co-Operative Society Ltd", label: "Bpcl Employees Co-Operative Society Ltd" },
  { value: "Bpcl Kochi Refinery Ltd", label: "Bpcl Kochi Refinery Ltd" },
  { value: "Bpscl", label: "Bpscl" },
  { value: "Brahamakay Commercial Co. Op. Bank Ltd", label: "Brahamakay Commercial Co. Op. Bank Ltd" },
  { value: "Brahamani Finance", label: "Brahamani Finance" },
  { value: "Brahmadeodada Mane Sahakari Bank Ltd Solapur", label: "Brahmadeodada Mane Sahakari Bank Ltd Solapur" },
  { value: "Brahmadeodada Mane Sahakari Bank Ltd.", label: "Brahmadeodada Mane Sahakari Bank Ltd." },
  { value: "Brahman Pattina Sahakari Sangha Niyamit", label: "Brahman Pattina Sahakari Sangha Niyamit" },
  { value: "Brahmapuri Urban Co-Op Bank Ltd", label: "Brahmapuri Urban Co-Op Bank Ltd" },
  { value: "Brahmarshi Co Op Credit Society Ltd", label: "Brahmarshi Co Op Credit Society Ltd" },
  { value: "Brahmavar Commercial Co-Op Bank Ltd", label: "Brahmavar Commercial Co-Op Bank Ltd" },
  { value: "Brahmawart Commercial Co-Op. Bank Ltd.", label: "Brahmawart Commercial Co-Op. Bank Ltd." },
  { value: "Brahmawat Co Bank Ltd", label: "Brahmawat Co Bank Ltd" },
  { value: "Brahmling G B S S P S Mrdt", label: "Brahmling G B S S P S Mrdt" },
  { value: "Braithwait, Burn And Jessop Const. Co. Ltd.", label: "Braithwait, Burn And Jessop Const. Co. Ltd." },
  { value: "Brakes India Limited", label: "Brakes India Limited" },
  { value: "Bramaramba Auto Finance", label: "Bramaramba Auto Finance" },
  { value: "Bramarhaba Pattina Shakari Sanga Niyamita", label: "Bramarhaba Pattina Shakari Sanga Niyamita" },
  { value: "Bramdevdada Manesahakari Bank Ltd", label: "Bramdevdada Manesahakari Bank Ltd" },
  { value: "Bramhapuri Urban Co.Op Bank Ltd.", label: "Bramhapuri Urban Co.Op Bank Ltd." },
  { value: "Brd Securities Ltd.", label: "Brd Securities Ltd." },
  { value: "Brei Instructure Finance Ltd", label: "Brei Instructure Finance Ltd" },
  { value: "Brhmawat Comm.", label: "Brhmawat Comm." },
  { value: "Bridge And Roof (Co.) Ltd.", label: "Bridge And Roof (Co.) Ltd." },
  { value: "Bright Credit And Loans", label: "Bright Credit And Loans" },
  { value: "Bright Finance Pvt. Ltd", label: "Bright Finance Pvt. Ltd" },
  { value: "Brijendra Hire Purchase  And Finance Ltd", label: "Brijendra Hire Purchase  And Finance Ltd" },
  { value: "Brijlal Biyani Urban Co Op Credit Society", label: "Brijlal Biyani Urban Co Op Credit Society" },
  { value: "Brilliant Agency", label: "Brilliant Agency" },
  { value: "British Bank Of Middle East", label: "British Bank Of Middle East" },
  { value: "British India Corporation Ltd.", label: "British India Corporation Ltd." },
  { value: "Brk In Ins", label: "Brk In Ins" },
  { value: "Brm Lease And Credit Pvt Ltd", label: "Brm Lease And Credit Pvt Ltd" },
  { value: "Brm Leasing Credit Ltd", label: "Brm Leasing Credit Ltd" },
  { value: "Brm Leasing Ltd", label: "Brm Leasing Ltd" },
  { value: "Broadcasting Engineering Corporation Of India Limited", label: "Broadcasting Engineering Corporation Of India Limited" },
  { value: "Broadway Financial Service", label: "Broadway Financial Service" },
  { value: "Brothers Finance Corp. Regd", label: "Brothers Finance Corp. Regd" },
  { value: "Brr Leafin Pvt. Ltd.", label: "Brr Leafin Pvt. Ltd." },
  { value: "Bruh - Adim Jati Sev.Saha.Samiti Mydt.", label: "Bruh - Adim Jati Sev.Saha.Samiti Mydt." },
  { value: "Brushware Ltd.", label: "Brushware Ltd." },
  { value: "Brydal Kuries Pvt Ltd.", label: "Brydal Kuries Pvt Ltd." },
  { value: "Bsnl Co-Operative Society", label: "Bsnl Co-Operative Society" },
  { value: "Bsp Loan", label: "Bsp Loan" },
  { value: "Btl Investment Ltd.", label: "Btl Investment Ltd." },
  { value: "Budaun Zila Sahkari Bank Ltd", label: "Budaun Zila Sahkari Bank Ltd" },
  { value: "Bugadikatte Panch Croshi Co Op Crd Soc", label: "Bugadikatte Panch Croshi Co Op Crd Soc" },
  { value: "Building Materials And Technology Promotion Council", label: "Building Materials And Technology Promotion Council" },
  { value: "Bukkasagara Primary Agaricul Cr Cp Bk Lt", label: "Bukkasagara Primary Agaricul Cr Cp Bk Lt" },
  { value: "Buldana Gramin Bank Ltd.", label: "Buldana Gramin Bank Ltd." },
  { value: "Buldana Ur.Co-Op.Cre.Soc.Ltd.,", label: "Buldana Ur.Co-Op.Cre.Soc.Ltd.," },
  { value: "Buldana Urban Co.Op. Credit Soc. Ltd", label: "Buldana Urban Co.Op. Credit Soc. Ltd" },
  { value: "Buldhana District Central Co-Operative Bank Ltd.", label: "Buldhana District Central Co-Operative Bank Ltd." },
  { value: "Buldhana Urban Co Op Bank", label: "Buldhana Urban Co Op Bank" },
  { value: "Buldhana Urban Co Op Credit Soc Ltd", label: "Buldhana Urban Co Op Credit Soc Ltd" },
  { value: "Buldhana Urban Credit Co. Op So. Ltd", label: "Buldhana Urban Credit Co. Op So. Ltd" },
  { value: "Buldhana Zilha Kendriya Sahakari Bank Ltd", label: "Buldhana Zilha Kendriya Sahakari Bank Ltd" },
  { value: "Bulland Leasing & Finance", label: "Bulland Leasing & Finance" },
  { value: "Bundela Auto Finance", label: "Bundela Auto Finance" },
  { value: "Bundelkhand Kshetriya Gramin Bank", label: "Bundelkhand Kshetriya Gramin Bank" },
  { value: "Bundelkhand Kshetriya Gramin Bank", label: "Bundelkhand Kshetriya Gramin Bank" },
  { value: "Bundi Chittore Kshetriya Gramin Bank Ltd", label: "Bundi Chittore Kshetriya Gramin Bank Ltd" },
  { value: "Bundi Chittorgarh Kshetriya Gramin Bank", label: "Bundi Chittorgarh Kshetriya Gramin Bank" },
  { value: "Bundi District Central Co-Operative Bank Ltd.", label: "Bundi District Central Co-Operative Bank Ltd." },
  { value: "Bung Finance Corporation", label: "Bung Finance Corporation" },
  { value: "Bunty Auto Deals", label: "Bunty Auto Deals" },
  { value: "Burdwan District Central Co-Operative Bank Ltd.", label: "Burdwan District Central Co-Operative Bank Ltd." },
  { value: "Bureau Of Civil Aviation Security", label: "Bureau Of Civil Aviation Security" },
  { value: "Bureau Of Indian Standards", label: "Bureau Of Indian Standards" },
  { value: "Burn Standard Co. Ltd.", label: "Burn Standard Co. Ltd." },
  { value: "Business Co-Op Bank Ltd", label: "Business Co-Op Bank Ltd" },
  { value: "Business Co-Operative Bank Ltd.", label: "Business Co-Operative Bank Ltd." },
  { value: "Business Coop Bank Ltd", label: "Business Coop Bank Ltd" },
  { value: "Bussan Finance Ltd", label: "Bussan Finance Ltd" },
  { value: "Busybee Finance And Investments Ltd.", label: "Busybee Finance And Investments Ltd." },
  { value: "Buvasaheb Nawale Grahmin Bigar Sheti Sahkari Patsanstha Ltd", label: "Buvasaheb Nawale Grahmin Bigar Sheti Sahkari Patsanstha Ltd" },
  { value: "Buwasaheb Nawale Gramin Bigar Sheti Patsanstha", label: "Buwasaheb Nawale Gramin Bigar Sheti Patsanstha" },
  { value: "Buzzan Auto Finance India Pvt Ltd", label: "Buzzan Auto Finance India Pvt Ltd" },
  { value: "Bvs Financiers", label: "Bvs Financiers" },
  { value: "Bvv Ramana Murthy Finance", label: "Bvv Ramana Murthy Finance" },
  { value: "Bw Up Gramin Bank", label: "Bw Up Gramin Bank" },
  { value: "Byraweshwara Co Operative Bank", label: "Byraweshwara Co Operative Bank" },
  { value: "C And Md Engineers India Ltd.", label: "C And Md Engineers India Ltd." },
  { value: "C B S S Niyamit", label: "C B S S Niyamit" },
  { value: "C Byregowda Credit Co-Op Society Ltd", label: "C Byregowda Credit Co-Op Society Ltd" },
  { value: "C C F I L", label: "C C F I L" },
  { value: "C C Motors", label: "C C Motors" },
  { value: "C D Block Chachiot Co-Op M P Society Ltd", label: "C D Block Chachiot Co-Op M P Society Ltd" },
  { value: "C Dulichand Bothra Financiers", label: "C Dulichand Bothra Financiers" },
  { value: "C F C F India Ltd.", label: "C F C F India Ltd." },
  { value: "C G Mandi Board", label: "C G Mandi Board" },
  { value: "C G Rajshaa Karj Bank Mydt.", label: "C G Rajshaa Karj Bank Mydt." },
  { value: "C Hari Devi Finance Corporation", label: "C Hari Devi Finance Corporation" },
  { value: "C Hirala Jain", label: "C Hirala Jain" },
  { value: "C Jatan Raj Bagmar Financier", label: "C Jatan Raj Bagmar Financier" },
  { value: "C K G B", label: "C K G B" },
  { value: "C K P Co-Op Bank Ltd", label: "C K P Co-Op Bank Ltd" },
  { value: "C Lakshmi Narasimma Financier", label: "C Lakshmi Narasimma Financier" },
  { value: "C Madanlal Financier", label: "C Madanlal Financier" },
  { value: "C Mahaveer Chand Surana", label: "C Mahaveer Chand Surana" },
  { value: "C Naincy Khatod", label: "C Naincy Khatod" },
  { value: "C P Investments", label: "C P Investments" },
  { value: "C Pukhraj Baghamr Financer", label: "C Pukhraj Baghamr Financer" },
  { value: "C Pukhraj Bagmar", label: "C Pukhraj Bagmar" },
  { value: "C Pukhraj Bagmar& Sons", label: "C Pukhraj Bagmar& Sons" },
  { value: "C S Auto Services", label: "C S Auto Services" },
  { value: "C Tarachand", label: "C Tarachand" },
  { value: "C Yregowda Credit Co-Op Society Ltd", label: "C Yregowda Credit Co-Op Society Ltd" },
  { value: "C. G. Rajya Sahakari Bank Mydt", label: "C. G. Rajya Sahakari Bank Mydt" },
  { value: "C. Prakash Chand", label: "C. Prakash Chand" },
  { value: "C. S. E. B.", label: "C. S. E. B." },
  { value: "C.B. Of P. Ltd", label: "C.B. Of P. Ltd" },
  { value: "C.B. Po.Ltd", label: "C.B. Po.Ltd" },
  { value: "C.B.N.A.", label: "C.B.N.A." },
  { value: "C.B.P Ltd", label: "C.B.P Ltd" },
  { value: "C.D Block Chachiot Co-Op Credit Society Ltd", label: "C.D Block Chachiot Co-Op Credit Society Ltd" },
  { value: "C.D.C.C. Bank Ltd.", label: "C.D.C.C. Bank Ltd." },
  { value: "C.G. Nishktjan Vitt Avam Vikas Nigam", label: "C.G. Nishktjan Vitt Avam Vikas Nigam" },
  { value: "C.K Finance Corporation", label: "C.K Finance Corporation" },
  { value: "C.K.G. Bank", label: "C.K.G. Bank" },
  { value: "C.L Enterprises", label: "C.L Enterprises" },
  { value: "C.L Enterprises Kindily Do The Needful", label: "C.L Enterprises Kindily Do The Needful" },
  { value: "C.M.F.I. Ltd.", label: "C.M.F.I. Ltd." },
  { value: "C.Nirmala Kanwar Finance", label: "C.Nirmala Kanwar Finance" },
  { value: "C.P.Investments", label: "C.P.Investments" },
  { value: "C.R.S. Auto Finance", label: "C.R.S. Auto Finance" },
  { value: "C.S.Gill And Co", label: "C.S.Gill And Co" },
  { value: "C.S.P.H.C.L.", label: "C.S.P.H.C.L." },
  { value: "C.T Hire Purchase", label: "C.T Hire Purchase" },
  { value: "C.V.S.Finance", label: "C.V.S.Finance" },
  { value: "Cabal Ins. Services Pvt. Ltd.", label: "Cabal Ins. Services Pvt. Ltd." },
  { value: "Cabdli Finance", label: "Cabdli Finance" },
  { value: "Cachar Gramin Vikash Bank", label: "Cachar Gramin Vikash Bank" },
  { value: "Cais Auto Financial Services Ltd", label: "Cais Auto Financial Services Ltd" },
  { value: "Calcutta Investors", label: "Calcutta Investors" },
  { value: "Calicut City Service Co-Op Bank", label: "Calicut City Service Co-Op Bank" },
  { value: "Calicut University Staff Co Credit Soc L", label: "Calicut University Staff Co Credit Soc L" },
  { value: "Calorex Investment And Finance Ltd.", label: "Calorex Investment And Finance Ltd." },
  { value: "Calvery Hire Purchase", label: "Calvery Hire Purchase" },
  { value: "Calyon Bank", label: "Calyon Bank" },
  { value: "Cambay Hindu Merchants Co-Op Bank Ltd", label: "Cambay Hindu Merchants Co-Op Bank Ltd" },
  { value: "Cams", label: "Cams" },
  { value: "Can-Indian Financial Service Inc", label: "Can-Indian Financial Service Inc" },
  { value: "Can-Indian Financial Services Inc", label: "Can-Indian Financial Services Inc" },
  { value: "Canara Bank - Bijadi Branch - Koteshwara", label: "Canara Bank - Bijadi Branch - Koteshwara" },
  { value: "Canara Bank - Gangolli Branch", label: "Canara Bank - Gangolli Branch" },
  { value: "Canara Bank - Karkala", label: "Canara Bank - Karkala" },
  { value: "Canara Bank - Main Road - Karkala.", label: "Canara Bank - Main Road - Karkala." },
  { value: "Canara Bank - Moodubelle", label: "Canara Bank - Moodubelle" },
  { value: "Canara Bank - Udupi", label: "Canara Bank - Udupi" },
  { value: "Canara Bank - Uppoor", label: "Canara Bank - Uppoor" },
  { value: "Canara Bank - Varamballi", label: "Canara Bank - Varamballi" },
  { value: "Canara Bank - Yermal Thenka", label: "Canara Bank - Yermal Thenka" },
  { value: "Canara Bank Beejadi Branch Kundapur.", label: "Canara Bank Beejadi Branch Kundapur." },
  { value: "Canara Bank Neralakatte Branch Kundapur.", label: "Canara Bank Neralakatte Branch Kundapur." },
  { value: "Canara Bank Sacharipet", label: "Canara Bank Sacharipet" },
  { value: "Canara Bank Varambally Bramhavara.", label: "Canara Bank Varambally Bramhavara." },
  { value: "Canara Bank, Srirangam", label: "Canara Bank, Srirangam" },
  { value: "Canara Credit Co-Op Society Ltd - Ambalpady", label: "Canara Credit Co-Op Society Ltd - Ambalpady" },
  { value: "Canara District Central Co-Operative Bank", label: "Canara District Central Co-Operative Bank" },
  { value: "Canbank Factors Ltd", label: "Canbank Factors Ltd" },
  { value: "Canbank Venture Capital Fund Limited", label: "Canbank Venture Capital Fund Limited" },
  { value: "Candolim Urban Co Operative Credit Society", label: "Candolim Urban Co Operative Credit Society" },
  { value: "Candolim Urban Co-Op Credit Society Ltd.", label: "Candolim Urban Co-Op Credit Society Ltd." },
  { value: "Capital Auto Deal", label: "Capital Auto Deal" },
  { value: "Capital Bank", label: "Capital Bank" },
  { value: "Capital Credit Co-Op. Ltd.", label: "Capital Credit Co-Op. Ltd." },
  { value: "Capital Credit Syndicate", label: "Capital Credit Syndicate" },
  { value: "Capital Finance Co. Ltd", label: "Capital Finance Co. Ltd" },
  { value: "Capital Financial Co. Ltd.", label: "Capital Financial Co. Ltd." },
  { value: "Capital Fortunes Ltd.", label: "Capital Fortunes Ltd." },
  { value: "Capital Investment", label: "Capital Investment" },
  { value: "Capital Local Area Bank Ltd", label: "Capital Local Area Bank Ltd" },
  { value: "Capital Local Area Bank Ltd.", label: "Capital Local Area Bank Ltd." },
  { value: "Capital Motor Invest Co.", label: "Capital Motor Invest Co." },
  { value: "Capital Transport Financial Services Ltd", label: "Capital Transport Financial Services Ltd" },
  { value: "Capital Trust Ltd", label: "Capital Trust Ltd" },
  { value: "Car India", label: "Car India" },
  { value: "Cardomaon Merchants Co-Op Bank Ltd", label: "Cardomaon Merchants Co-Op Bank Ltd" },
  { value: "Cargill India Private Ltd", label: "Cargill India Private Ltd" },
  { value: "Cargo Motors Pvt. Ltd", label: "Cargo Motors Pvt. Ltd" },
  { value: "Carmel Auto Finance", label: "Carmel Auto Finance" },
  { value: "Carol Equipments India Ltd.", label: "Carol Equipments India Ltd." },
  { value: "Carona Bank", label: "Carona Bank" },
  { value: "Carpet Export Promotion Council", label: "Carpet Export Promotion Council" },
  { value: "Carthic Credits Ltd.", label: "Carthic Credits Ltd." },
  { value: "Carzonrent India (P) Ltd", label: "Carzonrent India (P) Ltd" },
  { value: "Catholic Credit Co-Op Society Ltd", label: "Catholic Credit Co-Op Society Ltd" },
  { value: "Catholic Syrian Bank Ltd.", label: "Catholic Syrian Bank Ltd." },
  { value: "Cauvery Kalpatharu Grameena Bank Ltd,", label: "Cauvery Kalpatharu Grameena Bank Ltd," },
  { value: "Cavery Kalpatharu Grameena Bank", label: "Cavery Kalpatharu Grameena Bank" },
  { value: "Cawnpore Textiles Ltd.", label: "Cawnpore Textiles Ltd." },
  { value: "Cbi Belari", label: "Cbi Belari" },
  { value: "Cbp Ltd. Hazratganj Lko.", label: "Cbp Ltd. Hazratganj Lko." },
  { value: "Cbs Bank Gangavathi", label: "Cbs Bank Gangavathi" },
  { value: "Ccfil", label: "Ccfil" },
  { value: "Ccfl Finance", label: "Ccfl Finance" },
  { value: "Cd Investments", label: "Cd Investments" },
  { value: "Ceat Financial Services Ltd", label: "Ceat Financial Services Ltd" },
  { value: "Ceejax Finance Limited", label: "Ceejax Finance Limited" },
  { value: "Ceejay Finance Ltd.", label: "Ceejay Finance Ltd." },
  { value: "Ceeten Motor Finance Ltd", label: "Ceeten Motor Finance Ltd" },
  { value: "Cement Corporation Of India Ltd", label: "Cement Corporation Of India Ltd" },
  { value: "Centra Bank", label: "Centra Bank" },
  { value: "Centra Bank Of Punjab", label: "Centra Bank Of Punjab" },
  { value: "Central Adoption Resource Agency", label: "Central Adoption Resource Agency" },
  { value: "Central Arid Zone Research Institute", label: "Central Arid Zone Research Institute" },
  { value: "Central Bank", label: "Central Bank" },
  { value: "Central Bank Of Commerce", label: "Central Bank Of Commerce" },
  { value: "Central Bank Of India In Consortium With Axis Bank Ltd", label: "Central Bank Of India In Consortium With Axis Bank Ltd" },
  { value: "Central Bank Of India, Sakur (Ma)-422619", label: "Central Bank Of India, Sakur (Ma)-422619" },
  { value: "Central Bank Of Punjab", label: "Central Bank Of Punjab" },
  { value: "Central Board Of Direct Taxes", label: "Central Board Of Direct Taxes" },
  { value: "Central Board Of Excise And Customs", label: "Central Board Of Excise And Customs" },
  { value: "Central Board Of Secondary Education", label: "Central Board Of Secondary Education" },
  { value: "Central Bureau Of Investigation", label: "Central Bureau Of Investigation" },
  { value: "Central Bureau Of Narcotics", label: "Central Bureau Of Narcotics" },
  { value: "Central Car Dealers", label: "Central Car Dealers" },
  { value: "Central Co Op  Bank", label: "Central Co Op  Bank" },
  { value: "Central Co-Operative Bank Ltd.", label: "Central Co-Operative Bank Ltd." },
  { value: "Central Co-Operative Bank Ltd.", label: "Central Co-Operative Bank Ltd." },
  { value: "Central Coalfields Limited", label: "Central Coalfields Limited" },
  { value: "Central Cottage Industries Corporation Of India Ltd.", label: "Central Cottage Industries Corporation Of India Ltd." },
  { value: "Central Council For Research In Ayurveda And Siddha", label: "Central Council For Research In Ayurveda And Siddha" },
  { value: "Central Council For Research In Homeopathy", label: "Central Council For Research In Homeopathy" },
  { value: "Central Council For Research In Unani Medicine", label: "Central Council For Research In Unani Medicine" },
  { value: "Central Drug Research Institute", label: "Central Drug Research Institute" },
  { value: "Central Electricity Authority", label: "Central Electricity Authority" },
  { value: "Central Electro Chemical Research Institute", label: "Central Electro Chemical Research Institute" },
  { value: "Central Electronics Ltd.", label: "Central Electronics Ltd." },
  { value: "Central Finance Ltd", label: "Central Finance Ltd" },
  { value: "Central Food Technological Research Institute", label: "Central Food Technological Research Institute" },
  { value: "Central Fuel Research Institute", label: "Central Fuel Research Institute" },
  { value: "Central Godavari Krushak Seva Sanstha Maryadit", label: "Central Godavari Krushak Seva Sanstha Maryadit" },
  { value: "Central Government Employees Welfare Housing Organisation", label: "Central Government Employees Welfare Housing Organisation" },
  { value: "Central Hindi Directorate", label: "Central Hindi Directorate" },
  { value: "Central Hire Purchase Corp.", label: "Central Hire Purchase Corp." },
  { value: "Central Industrial Security Force", label: "Central Industrial Security Force" },
  { value: "Central Inland Capture Fisheries Research Institute", label: "Central Inland Capture Fisheries Research Institute" },
  { value: "Central Inland Water Transport Crop. Ltd.", label: "Central Inland Water Transport Crop. Ltd." },
  { value: "Central Institute For Research On Goats", label: "Central Institute For Research On Goats" },
  { value: "Central Institute Of Agricultural Engineering", label: "Central Institute Of Agricultural Engineering" },
  { value: "Central Institute Of Brackishwater Aquaculture", label: "Central Institute Of Brackishwater Aquaculture" },
  { value: "Central Institute Of Educational Technology", label: "Central Institute Of Educational Technology" },
  { value: "Central Institute Of English And Foreign Languages", label: "Central Institute Of English And Foreign Languages" },
  { value: "Central Institute Of Fisheries Education", label: "Central Institute Of Fisheries Education" },
  { value: "Central Institute Of Indian Languages", label: "Central Institute Of Indian Languages" },
  { value: "Central Institute Of Medicinal And Aromatic Plants", label: "Central Institute Of Medicinal And Aromatic Plants" },
  { value: "Central Institute Of Road Transport", label: "Central Institute Of Road Transport" },
  { value: "Central Marine Fisheries Research Institute", label: "Central Marine Fisheries Research Institute" },
  { value: "Central Mechanical Engineering Research Institute", label: "Central Mechanical Engineering Research Institute" },
  { value: "Central Mine Planning And Design Institute", label: "Central Mine Planning And Design Institute" },
  { value: "Central Plantation Crops Research Institute", label: "Central Plantation Crops Research Institute" },
  { value: "Central Power Research Institute", label: "Central Power Research Institute" },
  { value: "Central Public Liabrary", label: "Central Public Liabrary" },
  { value: "Central Public Works Department", label: "Central Public Works Department" },
  { value: "Central Reserve Police Force", label: "Central Reserve Police Force" },
  { value: "Central Soil And Materials Research Station", label: "Central Soil And Materials Research Station" },
  { value: "Central Vigilance Commission", label: "Central Vigilance Commission" },
  { value: "Central Warehousing Corporation Ltd.", label: "Central Warehousing Corporation Ltd." },
  { value: "Central Water And Power Research Station", label: "Central Water And Power Research Station" },
  { value: "Central Water Commission", label: "Central Water Commission" },
  { value: "Centre For Advanced Technology", label: "Centre For Advanced Technology" },
  { value: "Centre For Biochemical Technology", label: "Centre For Biochemical Technology" },
  { value: "Centre For Cellular And Molecular Biology", label: "Centre For Cellular And Molecular Biology" },
  { value: "Centre For Cultural Resources And Training", label: "Centre For Cultural Resources And Training" },
  { value: "Centre For Development Of Advanced Computing", label: "Centre For Development Of Advanced Computing" },
  { value: "Centre For Development Of Stones", label: "Centre For Development Of Stones" },
  { value: "Centre For Development Of Telematics", label: "Centre For Development Of Telematics" },
  { value: "Centre For Electronics Design And Technology Of India", label: "Centre For Electronics Design And Technology Of India" },
  { value: "Centre For Entrepreneurship Development Of Karnataka", label: "Centre For Entrepreneurship Development Of Karnataka" },
  { value: "Centre For Liquid Crystal Research", label: "Centre For Liquid Crystal Research" },
  { value: "Centre For Materials For Electronics Technology", label: "Centre For Materials For Electronics Technology" },
  { value: "Centuran Bank Of Punjab Ltd.", label: "Centuran Bank Of Punjab Ltd." },
  { value: "Centurian Bank Ltd.", label: "Centurian Bank Ltd." },
  { value: "Centurian Bank Of Commerce", label: "Centurian Bank Of Commerce" },
  { value: "Centurian Bank Of Punjab", label: "Centurian Bank Of Punjab" },
  { value: "Centurian Bank Of Punjab Ltd.", label: "Centurian Bank Of Punjab Ltd." },
  { value: "Centurian Finance", label: "Centurian Finance" },
  { value: "Centurion Bank Cent Cent", label: "Centurion Bank Cent Cent" },
  { value: "Centurion Bank Limited", label: "Centurion Bank Limited" },
  { value: "Centurion Bank Ltd.", label: "Centurion Bank Ltd." },
  { value: "Centurion Bank Ltd.", label: "Centurion Bank Ltd." },
  { value: "Centurion Bank Of India", label: "Centurion Bank Of India" },
  { value: "Centurion Bank Of India, Madurai.", label: "Centurion Bank Of India, Madurai." },
  { value: "Centurion Bank Of Punjab", label: "Centurion Bank Of Punjab" },
  { value: "Centurion Bank Of Punjab Limited", label: "Centurion Bank Of Punjab Limited" },
  { value: "Centurion Bank Of Punjab Limited", label: "Centurion Bank Of Punjab Limited" },
  { value: "Centurion Bank Of Punjab Limited", label: "Centurion Bank Of Punjab Limited" },
  { value: "Century Auto Finance", label: "Century Auto Finance" },
  { value: "Cersen And Turbo Fin Ltd", label: "Cersen And Turbo Fin Ltd" },
  { value: "Cet Sail", label: "Cet Sail" },
  { value: "Cfcf Ind Ltd", label: "Cfcf Ind Ltd" },
  { value: "Cfcf India Ltd", label: "Cfcf India Ltd" },
  { value: "Cfcfi Ltd", label: "Cfcfi Ltd" },
  { value: "Cg Rajya Ant. Sah. Vihayvamvik Nig.", label: "Cg Rajya Ant. Sah. Vihayvamvik Nig." },
  { value: "Ch Grameen Bank", label: "Ch Grameen Bank" },
  { value: "Ch Rama Devi Automobile Finance", label: "Ch Rama Devi Automobile Finance" },
  { value: "Chadha Finance.", label: "Chadha Finance." },
  { value: "Chadha Housing And Capital Services", label: "Chadha Housing And Capital Services" },
  { value: "Chadrapur District Central Co-Operative Bank Ltd.", label: "Chadrapur District Central Co-Operative Bank Ltd." },
  { value: "Chagan Bhujbal Nagari Sahkari Patsanstha Ltd.", label: "Chagan Bhujbal Nagari Sahkari Patsanstha Ltd." },
  { value: "Chahal Finance Co.", label: "Chahal Finance Co." },
  { value: "Chahavi Enterprise", label: "Chahavi Enterprise" },
  { value: "Chainraj Auto Finance", label: "Chainraj Auto Finance" },
  { value: "Chainraj Fin", label: "Chainraj Fin" },
  { value: "Chair Fin & Investment Pvt Ltd", label: "Chair Fin & Investment Pvt Ltd" },
  { value: "Chair Finance And Invorsment Ltd", label: "Chair Finance And Invorsment Ltd" },
  { value: "Chair Finance And Private Ltd", label: "Chair Finance And Private Ltd" },
  { value: "Chair Finance Andinvorsment Ltd (Mp)", label: "Chair Finance Andinvorsment Ltd (Mp)" },
  { value: "Chairaman Airport Authority Of India", label: "Chairaman Airport Authority Of India" },
  { value: "Chairman & Managing Director Engineer India Ltd", label: "Chairman & Managing Director Engineer India Ltd" },
  { value: "Chairman Airport Authority Of India", label: "Chairman Airport Authority Of India" },
  { value: "Chairman Pci", label: "Chairman Pci" },
  { value: "Chairman Port Trust", label: "Chairman Port Trust" },
  { value: "Chairman Tneb", label: "Chairman Tneb" },
  { value: "Chaitanya Finance Co.", label: "Chaitanya Finance Co." },
  { value: "Chaitanya Gramin Bigarsheti S. Patsantha", label: "Chaitanya Gramin Bigarsheti S. Patsantha" },
  { value: "Chaitanya Mahila Co-Op Bank Ltd", label: "Chaitanya Mahila Co-Op Bank Ltd" },
  { value: "Chaitanya Mahila Sahakari Bank Niyamith", label: "Chaitanya Mahila Sahakari Bank Niyamith" },
  { value: "Chaitanya Nagari Sah Path Sanstha", label: "Chaitanya Nagari Sah Path Sanstha" },
  { value: "Chaitanya Purushula Podupu Sangam", label: "Chaitanya Purushula Podupu Sangam" },
  { value: "Chaitanya Urban Co-Op Bank Ltd", label: "Chaitanya Urban Co-Op Bank Ltd" },
  { value: "Chaitanya Urban Co.Op. Bk Ltd", label: "Chaitanya Urban Co.Op. Bk Ltd" },
  { value: "Chaitnya Godavari Grameena Bank", label: "Chaitnya Godavari Grameena Bank" },
  { value: "Chakan Gramin Bigar Sheti Sahakari Path Sanstha Maryadit", label: "Chakan Gramin Bigar Sheti Sahakari Path Sanstha Maryadit" },
  { value: "Chakreshwar Gramin Big Sheti Path Sanstha", label: "Chakreshwar Gramin Big Sheti Path Sanstha" },
  { value: "Chalakudy Urban Co-Op. Bank Ltd.", label: "Chalakudy Urban Co-Op. Bank Ltd." },
  { value: "Chalamandalam Obs Finance", label: "Chalamandalam Obs Finance" },
  { value: "Chalisgaon Peoples Co-Op Bank Ltd", label: "Chalisgaon Peoples Co-Op Bank Ltd" },
  { value: "Challa Rajasekhar Auto Finance", label: "Challa Rajasekhar Auto Finance" },
  { value: "Challa Rajashekara Auto Mobile Financiers", label: "Challa Rajashekara Auto Mobile Financiers" },
  { value: "Challakere T Barashdatarar Pathina Sahkara Sanha", label: "Challakere T Barashdatarar Pathina Sahkara Sanha" },
  { value: "Challakere Town Consumer Credit Co-Op Society", label: "Challakere Town Consumer Credit Co-Op Society" },
  { value: "Challakere Town Consumer Patthina Sahakara Sanga", label: "Challakere Town Consumer Patthina Sahakara Sanga" },
  { value: "Challakere Town Consumers Cre Co-Op.S.L", label: "Challakere Town Consumers Cre Co-Op.S.L" },
  { value: "Challakere Urban Co-Op Bank Ltd", label: "Challakere Urban Co-Op Bank Ltd" },
  { value: "Challani Finance", label: "Challani Finance" },
  { value: "Challani Global Finance", label: "Challani Global Finance" },
  { value: "Chamba Urban Co-Op Bank Ltd", label: "Chamba Urban Co-Op Bank Ltd" },
  { value: "Chambal Fertilizers And Chemicals Ltd.", label: "Chambal Fertilizers And Chemicals Ltd." },
  { value: "Chambal Gwalior Kshetriya Gramin Bank.", label: "Chambal Gwalior Kshetriya Gramin Bank." },
  { value: "Chambal Gwaliyar Bank", label: "Chambal Gwaliyar Bank" },
  { value: "Chambal Kshetriya Gramin Bank", label: "Chambal Kshetriya Gramin Bank" },
  { value: "Chambers Equity And Financial Co.Ltd.", label: "Chambers Equity And Financial Co.Ltd." },
  { value: "Chamoli District Co-Op Bank Ltd,", label: "Chamoli District Co-Op Bank Ltd," },
  { value: "Chamoli Zila Sahkari Bank Ltd", label: "Chamoli Zila Sahkari Bank Ltd" },
  { value: "Champa Bai", label: "Champa Bai" },
  { value: "Champalal Bhansali", label: "Champalal Bhansali" },
  { value: "Champaran Kshetriya Gramin Bank", label: "Champaran Kshetriya Gramin Bank" },
  { value: "Champavati Urban Co-Op Bank Ltd", label: "Champavati Urban Co-Op Bank Ltd" },
  { value: "Champavati Urban Co.Op Bank Ltd.", label: "Champavati Urban Co.Op Bank Ltd." },
  { value: "Champawati Gramin Bigarsheti Sahakari Patsanstha Maryadit Revdanda", label: "Champawati Gramin Bigarsheti Sahakari Patsanstha Maryadit Revdanda" },
  { value: "Chan Motors", label: "Chan Motors" },
  { value: "Chanasma Commercial Co-Op Bank Ltd", label: "Chanasma Commercial Co-Op Bank Ltd" },
  { value: "Chanaurlo And Finvest (P) Ltd", label: "Chanaurlo And Finvest (P) Ltd" },
  { value: "Chancellor Motor Finance", label: "Chancellor Motor Finance" },
  { value: "Chanchal Auto Finance", label: "Chanchal Auto Finance" },
  { value: "Chanchal Devi Finance Ltd", label: "Chanchal Devi Finance Ltd" },
  { value: "Chanchal Finance", label: "Chanchal Finance" },
  { value: "Chanchal Finance Corporation", label: "Chanchal Finance Corporation" },
  { value: "Chanchal Jain", label: "Chanchal Jain" },
  { value: "Chanchal Kanwal", label: "Chanchal Kanwal" },
  { value: "Chanchal Kawar", label: "Chanchal Kawar" },
  { value: "Chanchal Kawar Jain Automobile Finance", label: "Chanchal Kawar Jain Automobile Finance" },
  { value: "Chand Kanwar Jain", label: "Chand Kanwar Jain" },
  { value: "Chand Tara Bigarsheti Sah Pat", label: "Chand Tara Bigarsheti Sah Pat" },
  { value: "Chand Tara Bigarsheti Sah Pat", label: "Chand Tara Bigarsheti Sah Pat" },
  { value: "Chandadevi Jesan Saria", label: "Chandadevi Jesan Saria" },
  { value: "Chandamal Jain", label: "Chandamal Jain" },
  { value: "Chandamal Jain", label: "Chandamal Jain" },
  { value: "Chandan Finance Corp", label: "Chandan Finance Corp" },
  { value: "Chandan Prakash", label: "Chandan Prakash" },
  { value: "Chandanappally Service Co-Opera Bank Ltd", label: "Chandanappally Service Co-Opera Bank Ltd" },
  { value: "Chandanbala Finance", label: "Chandanbala Finance" },
  { value: "Chandandhara Finance Ltd", label: "Chandandhara Finance Ltd" },
  { value: "Chandavat  Enterprises", label: "Chandavat  Enterprises" },
  { value: "Chandgad Co-Op Cr Society Ltd", label: "Chandgad Co-Op Cr Society Ltd" },
  { value: "Chandgad Co-Op Cr Society Ltd.", label: "Chandgad Co-Op Cr Society Ltd." },
  { value: "Chandgad Gbssps Mtm", label: "Chandgad Gbssps Mtm" },
  { value: "Chandgad Urban Co-Op Bank Ltd", label: "Chandgad Urban Co-Op Bank Ltd" },
  { value: "Chandigarh Industrial And Tourism Development Corporation", label: "Chandigarh Industrial And Tourism Development Corporation" },
  { value: "Chandigarh State Co.Op.Bank Ltd., Chandigarh", label: "Chandigarh State Co.Op.Bank Ltd., Chandigarh" },
  { value: "Chandigarh-Punjab Co-Op Bank Ltd", label: "Chandigarh-Punjab Co-Op Bank Ltd" },
  { value: "Chandini Finance", label: "Chandini Finance" },
  { value: "Chandmal Finance", label: "Chandmal Finance" },
  { value: "Chandmal Jain", label: "Chandmal Jain" },
  { value: "Chandmal Jain Financier", label: "Chandmal Jain Financier" },
  { value: "Chandra Bai Finance", label: "Chandra Bai Finance" },
  { value: "Chandra Finance", label: "Chandra Finance" },
  { value: "Chandra Hi Pur Finance", label: "Chandra Hi Pur Finance" },
  { value: "Chandra Kanwar Bagmar", label: "Chandra Kanwar Bagmar" },
  { value: "Chandra Loka Auto Financiers", label: "Chandra Loka Auto Financiers" },
  { value: "Chandra Prakash And Sons", label: "Chandra Prakash And Sons" },
  { value: "Chandra Priya Auto Financiers", label: "Chandra Priya Auto Financiers" },
  { value: "Chandrakantha Surana", label: "Chandrakantha Surana" },
  { value: "Chandrakumari Financiar", label: "Chandrakumari Financiar" },
  { value: "Chandralok Finvest Pvt.Ltd", label: "Chandralok Finvest Pvt.Ltd" },
  { value: "Chandrama Gramin Bigar Sheti Sahkari Patsanstha", label: "Chandrama Gramin Bigar Sheti Sahkari Patsanstha" },
  { value: "Chandrama Nagari Sahakari Pat Sanstha", label: "Chandrama Nagari Sahakari Pat Sanstha" },
  { value: "Chandramani Auto Finance", label: "Chandramani Auto Finance" },
  { value: "Chandramani Auto Finance", label: "Chandramani Auto Finance" },
  { value: "Chandrapandhari Gramini Bigar Sheti Sah Patsanstha", label: "Chandrapandhari Gramini Bigar Sheti Sah Patsanstha" },
  { value: "Chandraprabhu Finance", label: "Chandraprabhu Finance" },
  { value: "Chandraprabhu Gramin Biger Sheti .", label: "Chandraprabhu Gramin Biger Sheti ." },
  { value: "Chandraprabhu Investment", label: "Chandraprabhu Investment" },
  { value: "Chandraprabhu Nag.Sah.Pat.Sanstha.", label: "Chandraprabhu Nag.Sah.Pat.Sanstha." },
  { value: "Chandrapur Zilha Madhyavarti Sahakari Bank", label: "Chandrapur Zilha Madhyavarti Sahakari Bank" },
  { value: "Chandrashekhar Gramin Biger Sheti Sah Pat Sanstha Mydt", label: "Chandrashekhar Gramin Biger Sheti Sah Pat Sanstha Mydt" },
  { value: "Chandraswami Bank Ltd", label: "Chandraswami Bank Ltd" },
  { value: "Chandwad Merchants Co-Op Bank Ltd", label: "Chandwad Merchants Co-Op Bank Ltd" },
  { value: "Changaroth Panchayath Service Co-Op B.L.", label: "Changaroth Panchayath Service Co-Op B.L." },
  { value: "Channa Basava Pssni", label: "Channa Basava Pssni" },
  { value: "Channapattina Souhardha Sahakari Bank Niyamita", label: "Channapattina Souhardha Sahakari Bank Niyamita" },
  { value: "Channeshwar Urban Co-Op Credit Societies", label: "Channeshwar Urban Co-Op Credit Societies" },
  { value: "Chansma Nagrik Sahakari Bank Ltd.", label: "Chansma Nagrik Sahakari Bank Ltd." },
  { value: "Chansma Nagrik Sahkari Bank Ltd", label: "Chansma Nagrik Sahkari Bank Ltd" },
  { value: "Chappi Nagarik Sahakari Bank Ltd", label: "Chappi Nagarik Sahakari Bank Ltd" },
  { value: "Charada Nagarik Sahakari Bank Ltd", label: "Charada Nagarik Sahakari Bank Ltd" },
  { value: "Charan & J.K Finance Co.", label: "Charan & J.K Finance Co." },
  { value: "Charat Singh Finance Co.", label: "Charat Singh Finance Co." },
  { value: "Charatilahati Ltd", label: "Charatilahati Ltd" },
  { value: "Charel Seva Sahakari Mandli Ltd.", label: "Charel Seva Sahakari Mandli Ltd." },
  { value: "Charithra Finance Chennai 1", label: "Charithra Finance Chennai 1" },
  { value: "Charminar Auto Finance", label: "Charminar Auto Finance" },
  { value: "Charminar Co-Op Bank", label: "Charminar Co-Op Bank" },
  { value: "Charotar Co Op Credit Society Ltd", label: "Charotar Co Op Credit Society Ltd" },
  { value: "Charotar Nagarik Bank Ltd.Anand", label: "Charotar Nagarik Bank Ltd.Anand" },
  { value: "Charotar Nagarik Sahakari Bank Ltd", label: "Charotar Nagarik Sahakari Bank Ltd" },
  { value: "Charoter Co -Op Bank", label: "Charoter Co -Op Bank" },
  { value: "Chartered Leasing Pvt Ltd", label: "Chartered Leasing Pvt Ltd" },
  { value: "Chartered Sahakari Bank", label: "Chartered Sahakari Bank" },
  { value: "Chatha Financial Services Ltd", label: "Chatha Financial Services Ltd" },
  { value: "Chatha Financiers Ltd", label: "Chatha Financiers Ltd" },
  { value: "Chatrapati Motors Pvt Ltd", label: "Chatrapati Motors Pvt Ltd" },
  { value: "Chatrapati Nagari Sahakari Path Sanshta", label: "Chatrapati Nagari Sahakari Path Sanshta" },
  { value: "Chatrapati Nasik Zilha Nag Sah Pat S", label: "Chatrapati Nasik Zilha Nag Sah Pat S" },
  { value: "Chatrapati Sambhaji Sahakari Patsanstha Mydt", label: "Chatrapati Sambhaji Sahakari Patsanstha Mydt" },
  { value: "Chatrapati Shivaji Co-Operative Credit Society Ltd.", label: "Chatrapati Shivaji Co-Operative Credit Society Ltd." },
  { value: "Chatrapati Shivaji Nagari Sahakari Pat Sanstha Maryadit", label: "Chatrapati Shivaji Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Chatrapati Shivaji Ur Co-Op Bank", label: "Chatrapati Shivaji Ur Co-Op Bank" },
  { value: "Chatrapati Shivray Gramin Shah Pat Sanstha", label: "Chatrapati Shivray Gramin Shah Pat Sanstha" },
  { value: "Chatrath Hire & Purchase", label: "Chatrath Hire & Purchase" },
  { value: "Chatree Gramin Bank", label: "Chatree Gramin Bank" },
  { value: "Chattinadu Auto Service", label: "Chattinadu Auto Service" },
  { value: "Chattisgarh Gramin Bank", label: "Chattisgarh Gramin Bank" },
  { value: "Chattisgarh Gramin Bank,", label: "Chattisgarh Gramin Bank," },
  { value: "Chattisgarh State Tourism Development Corporation Ltd.", label: "Chattisgarh State Tourism Development Corporation Ltd." },
  { value: "Chaudhary Auto Finance Pvt Ltd", label: "Chaudhary Auto Finance Pvt Ltd" },
  { value: "Chaudhary Fin. And Investment Pvt. Ltd.", label: "Chaudhary Fin. And Investment Pvt. Ltd." },
  { value: "Chaudhary Finance Company", label: "Chaudhary Finance Company" },
  { value: "Chaudhry Finance And Investment Pvt.Ltd", label: "Chaudhry Finance And Investment Pvt.Ltd" },
  { value: "Chaundeshwari Co-Op. Bank Ltd., Kolhapur", label: "Chaundeshwari Co-Op. Bank Ltd., Kolhapur" },
  { value: "Chaundeshwari Nagri Sahakari Pat Sanstha", label: "Chaundeshwari Nagri Sahakari Pat Sanstha" },
  { value: "Chavassery Service Co-Operative Bank Ltd", label: "Chavassery Service Co-Operative Bank Ltd" },
  { value: "Chawla  Financier", label: "Chawla  Financier" },
  { value: "Chawla Instalment", label: "Chawla Instalment" },
  { value: "Cheema Finance", label: "Cheema Finance" },
  { value: "Cheers Financial Services Private Ltd", label: "Cheers Financial Services Private Ltd" },
  { value: "Chekhla Group Seva Sahakari Mandli Ltd", label: "Chekhla Group Seva Sahakari Mandli Ltd" },
  { value: "Cheleri Vanitha Sahakarana Sangham Ltd", label: "Cheleri Vanitha Sahakarana Sangham Ltd" },
  { value: "Chella Aruna Automobile Finance", label: "Chella Aruna Automobile Finance" },
  { value: "Chellam Finance", label: "Chellam Finance" },
  { value: "Chemm Finance Ltd", label: "Chemm Finance Ltd" },
  { value: "Chemnad Service Co-Op Bank Ltd,.", label: "Chemnad Service Co-Op Bank Ltd,." },
  { value: "Chenai Finance Co Ltd.", label: "Chenai Finance Co Ltd." },
  { value: "Chengalpattu Urban Co-Op Bank Ltd", label: "Chengalpattu Urban Co-Op Bank Ltd" },
  { value: "Chengara Service Co-Op. Bank Ltd.", label: "Chengara Service Co-Op. Bank Ltd." },
  { value: "Chengaroor Service Co-Op Bank Ltd,", label: "Chengaroor Service Co-Op Bank Ltd," },
  { value: "Chennai Auto Consultants Pvt Ltd", label: "Chennai Auto Consultants Pvt Ltd" },
  { value: "Chennai Auto Finance", label: "Chennai Auto Finance" },
  { value: "Chennai Auto Financiers", label: "Chennai Auto Financiers" },
  { value: "Chennai Central Co-Op Bank Ltd", label: "Chennai Central Co-Op Bank Ltd" },
  { value: "Chennai Central Co-Op Bank Ltd.", label: "Chennai Central Co-Op Bank Ltd." },
  { value: "Chennai Fertilizers Ltd.", label: "Chennai Fertilizers Ltd." },
  { value: "Chennai Finance Corporation", label: "Chennai Finance Corporation" },
  { value: "Chennai Institute Of Development Studies", label: "Chennai Institute Of Development Studies" },
  { value: "Chennai Port Trust", label: "Chennai Port Trust" },
  { value: "Chennai Refineries Ltd.", label: "Chennai Refineries Ltd." },
  { value: "Chennaiah Auto Consultancy", label: "Chennaiah Auto Consultancy" },
  { value: "Chennamangalam Service Co-Op Bank", label: "Chennamangalam Service Co-Op Bank" },
  { value: "Chennimalai Co-Op Urban Bank Ltd", label: "Chennimalai Co-Op Urban Bank Ltd" },
  { value: "Chennimalai Nandhu Auto Finance", label: "Chennimalai Nandhu Auto Finance" },
  { value: "Cheran Investments", label: "Cheran Investments" },
  { value: "Cheranadu Finance", label: "Cheranadu Finance" },
  { value: "Cherpalcheri Co-Op Urban Bank Ltd", label: "Cherpalcheri Co-Op Urban Bank Ltd" },
  { value: "Cherthala Co-Op Agricultural And Rural Development Bank Ltd", label: "Cherthala Co-Op Agricultural And Rural Development Bank Ltd" },
  { value: "Cherthala Govt:Servents Co-Operative Bank Ltd", label: "Cherthala Govt:Servents Co-Operative Bank Ltd" },
  { value: "Cherthala Govt:Services Co-Op Bank Ltd,", label: "Cherthala Govt:Services Co-Op Bank Ltd," },
  { value: "Cherthala Taluk Taxi Drivers Co-Op. Society Ltd", label: "Cherthala Taluk Taxi Drivers Co-Op. Society Ltd" },
  { value: "Cherukavu Co-Op. Rural Bank Ltd", label: "Cherukavu Co-Op. Rural Bank Ltd" },
  { value: "Cherukavu Panchayat Ser.Co-Op Bank Ltd.", label: "Cherukavu Panchayat Ser.Co-Op Bank Ltd." },
  { value: "Cheruvattoor Rural Co-Op Bk Ltd.", label: "Cheruvattoor Rural Co-Op Bk Ltd." },
  { value: "Chetak Motor Finance Pvt. Ltd.", label: "Chetak Motor Finance Pvt. Ltd." },
  { value: "Chetak Urban Co-Op Bank Ltd,", label: "Chetak Urban Co-Op Bank Ltd," },
  { value: "Chetak Urban Coop Bank Ltd.", label: "Chetak Urban Coop Bank Ltd." },
  { value: "Chetak Urban Operative Bank Ltd.", label: "Chetak Urban Operative Bank Ltd." },
  { value: "Chetan Chahal", label: "Chetan Chahal" },
  { value: "Chetan Finance Corporation", label: "Chetan Finance Corporation" },
  { value: "Chetan Nagari Sahakari Pat Sanstha Maryadit", label: "Chetan Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Chetana Sahakara Bank Niyamita  Sirsi", label: "Chetana Sahakara Bank Niyamita  Sirsi" },
  { value: "Chetana Sahakara Bank Niyamitha", label: "Chetana Sahakara Bank Niyamitha" },
  { value: "Chethan Prakash", label: "Chethan Prakash" },
  { value: "Chetna Bothra Finance", label: "Chetna Bothra Finance" },
  { value: "Chettarickal Enterprises", label: "Chettarickal Enterprises" },
  { value: "Chettinadu Auto Finance", label: "Chettinadu Auto Finance" },
  { value: "Chettinadu Finance Company", label: "Chettinadu Finance Company" },
  { value: "Chh. Shahu Graminbigar Sheti Sah Pat Snastha Maryadit", label: "Chh. Shahu Graminbigar Sheti Sah Pat Snastha Maryadit" },
  { value: "Chh.Shahu.G.B.S.S.P.S.Mrdt.", label: "Chh.Shahu.G.B.S.S.P.S.Mrdt." },
  { value: "Chhabra Finance Leasing Co", label: "Chhabra Finance Leasing Co" },
  { value: "Chhabra Motors", label: "Chhabra Motors" },
  { value: "Chhajco Auto Financiers", label: "Chhajco Auto Financiers" },
  { value: "Chhajed Auto Financiers", label: "Chhajed Auto Financiers" },
  { value: "Chhajer Finance Corp", label: "Chhajer Finance Corp" },
  { value: "Chhajer Finance Ltd.", label: "Chhajer Finance Ltd." },
  { value: "Chhajer Motors", label: "Chhajer Motors" },
  { value: "Chhala  Seva Sahakari Mandali", label: "Chhala  Seva Sahakari Mandali" },
  { value: "Chhalani Mahaveer Chand", label: "Chhalani Mahaveer Chand" },
  { value: "Chhallani Finance Corporation", label: "Chhallani Finance Corporation" },
  { value: "Chhatisgar Gadi Urban Credit Sahakari Sanstha", label: "Chhatisgar Gadi Urban Credit Sahakari Sanstha" },
  { value: "Chhatisgarh Gramin Bank", label: "Chhatisgarh Gramin Bank" },
  { value: "Chhatrapati Sambbhaji Maharaj Sahakari Patsanstha Maryadit", label: "Chhatrapati Sambbhaji Maharaj Sahakari Patsanstha Maryadit" },
  { value: "Chhatrasal Nagari Saha. Pat Sanstha Ltd.", label: "Chhatrasal Nagari Saha. Pat Sanstha Ltd." },
  { value: "Chhattisgarh Rajya Sahakari Bk Maryadit", label: "Chhattisgarh Rajya Sahakari Bk Maryadit" },
  { value: "Chhattisgarh State Civil Supply Co. Ltd.", label: "Chhattisgarh State Civil Supply Co. Ltd." },
  { value: "Chhattisgarh State Power Holding Co Ltd", label: "Chhattisgarh State Power Holding Co Ltd" },
  { value: "Chhavi Enterprises", label: "Chhavi Enterprises" },
  { value: "Chhaya Corner Nagri Sahakari Pathsanstha", label: "Chhaya Corner Nagri Sahakari Pathsanstha" },
  { value: "Chhikara Investments Limited", label: "Chhikara Investments Limited" },
  { value: "Chhindwara-Seoni Kshetriya Gramin Bank", label: "Chhindwara-Seoni Kshetriya Gramin Bank" },
  { value: "Chickamagalur District Central Co-Operative Bank Ltd.", label: "Chickamagalur District Central Co-Operative Bank Ltd." },
  { value: "Chickmagalur-Kodagu Grameena Bank", label: "Chickmagalur-Kodagu Grameena Bank" },
  { value: "Chicko Bank", label: "Chicko Bank" },
  { value: "Chief Commissioner For Disabilities", label: "Chief Commissioner For Disabilities" },
  { value: "Chief Engineer Gsecl Tps Gandhinagar", label: "Chief Engineer Gsecl Tps Gandhinagar" },
  { value: "Chief Officer Tmc", label: "Chief Officer Tmc" },
  { value: "Chief Post Master General", label: "Chief Post Master General" },
  { value: "Chief Workshop Manager Central Workshop Rly", label: "Chief Workshop Manager Central Workshop Rly" },
  { value: "Chief Workshop Manager Loce Works Southen Railway", label: "Chief Workshop Manager Loce Works Southen Railway" },
  { value: "Chief Workshop Manager Loco Works Southern Rlyway", label: "Chief Workshop Manager Loco Works Southern Rlyway" },
  { value: "Chikhli Co-Op Urban Bank Ltd", label: "Chikhli Co-Op Urban Bank Ltd" },
  { value: "Chikkerur Urban Co-Opcredit Socltd", label: "Chikkerur Urban Co-Opcredit Socltd" },
  { value: "Chikmagalur Jilla Mahila Sah Bk Niyamith", label: "Chikmagalur Jilla Mahila Sah Bk Niyamith" },
  { value: "Chikmagalur Pattana Sah. Bk. Niyamitha", label: "Chikmagalur Pattana Sah. Bk. Niyamitha" },
  { value: "Chikmagalur-Kodagu Grameena Bank", label: "Chikmagalur-Kodagu Grameena Bank" },
  { value: "Chikmanglur Gramina Kodagu Bank", label: "Chikmanglur Gramina Kodagu Bank" },
  { value: "Chikodi Co-Op Urban Bank Ltd", label: "Chikodi Co-Op Urban Bank Ltd" },
  { value: "Children`S Film Society", label: "Children`S Film Society" },
  { value: "Chimanraoji Kadam Gramin Bigar Sheti Sahakari Pathsanstha", label: "Chimanraoji Kadam Gramin Bigar Sheti Sahakari Pathsanstha" },
  { value: "Chinawal Vividh Karyakari Sahakari Soc.", label: "Chinawal Vividh Karyakari Sahakari Soc." },
  { value: "Chindanur Vysya Souharda Pathina Sahakara Niyamitha", label: "Chindanur Vysya Souharda Pathina Sahakara Niyamitha" },
  { value: "Chingoli Service Co-Operative Bank Ltd.", label: "Chingoli Service Co-Operative Bank Ltd." },
  { value: "Chinmai Hire Purchase", label: "Chinmai Hire Purchase" },
  { value: "Chinmay Finlease Pvt. Ltd.", label: "Chinmay Finlease Pvt. Ltd." },
  { value: "Chinmay Sah Pat Maryadit", label: "Chinmay Sah Pat Maryadit" },
  { value: "Chinna Pattin Souhard Sahakari Niyamit", label: "Chinna Pattin Souhard Sahakari Niyamit" },
  { value: "Chinna Vara Lakshmi Auto Mobiles Finance", label: "Chinna Vara Lakshmi Auto Mobiles Finance" },
  { value: "Chinna Varalakshmi Automobile Financiers", label: "Chinna Varalakshmi Automobile Financiers" },
  { value: "Chinnakumari Jain", label: "Chinnakumari Jain" },
  { value: "Chinnamalai Auto Finance", label: "Chinnamalai Auto Finance" },
  { value: "Chinnamalai Finance", label: "Chinnamalai Finance" },
  { value: "Chinnathirupathy Pri. Agrl Co-Op Bk.", label: "Chinnathirupathy Pri. Agrl Co-Op Bk." },
  { value: "Chinnok Finvest", label: "Chinnok Finvest" },
  { value: "Chintamani Co Op Society", label: "Chintamani Co Op Society" },
  { value: "Chintamani Gra Bigarshet Sahkari Maryadit", label: "Chintamani Gra Bigarshet Sahkari Maryadit" },
  { value: "Chintamani Nagari Sahakari Pat Ltd", label: "Chintamani Nagari Sahakari Pat Ltd" },
  { value: "Chintan Suketu Shah", label: "Chintan Suketu Shah" },
  { value: "Chintpurni Fin Co.", label: "Chintpurni Fin Co." },
  { value: "Chiplun Urban Co-Op Bank Ltd", label: "Chiplun Urban Co-Op Bank Ltd" },
  { value: "Chiplun Urban Co-Op Bank Ltd", label: "Chiplun Urban Co-Op Bank Ltd" },
  { value: "Chirag Auto Finance", label: "Chirag Auto Finance" },
  { value: "Chirag Bandari Financiers", label: "Chirag Bandari Financiers" },
  { value: "Chirag Investment", label: "Chirag Investment" },
  { value: "Chirakadavu Service Co Bank Ltd", label: "Chirakadavu Service Co Bank Ltd" },
  { value: "Chiranellur Services Cooperative Bank.", label: "Chiranellur Services Cooperative Bank." },
  { value: "Chiranjilal Rakhecha & Ors Huf", label: "Chiranjilal Rakhecha & Ors Huf" },
  { value: "Chital Nagarik Sahakari Bank Ltd", label: "Chital Nagarik Sahakari Bank Ltd" },
  { value: "Chitanavispura Friends Co-Op Bank Ltd", label: "Chitanavispura Friends Co-Op Bank Ltd" },
  { value: "Chitanavispura Friends Co-Op Bank Ltd.", label: "Chitanavispura Friends Co-Op Bank Ltd." },
  { value: "Chitntamani Co Op Society", label: "Chitntamani Co Op Society" },
  { value: "Chitra Finance", label: "Chitra Finance" },
  { value: "Chitradurga District Central Co-Operative Bank Ltd.", label: "Chitradurga District Central Co-Operative Bank Ltd." },
  { value: "Chitrakoot Motor Finance (P) Ltd", label: "Chitrakoot Motor Finance (P) Ltd" },
  { value: "Chitralekha Finance", label: "Chitralekha Finance" },
  { value: "Chitrali Chahwala", label: "Chitrali Chahwala" },
  { value: "Chitresh Investments", label: "Chitresh Investments" },
  { value: "Chitrkoo Finance Ltd.", label: "Chitrkoo Finance Ltd." },
  { value: "Chitrkoot Motor Finance Ltd", label: "Chitrkoot Motor Finance Ltd" },
  { value: "Chittarickal Enterprises", label: "Chittarickal Enterprises" },
  { value: "Chittattukara Service Co-Op Bank Ltd.", label: "Chittattukara Service Co-Op Bank Ltd." },
  { value: "Chittoor District Central Co-Operative Bank Ltd.", label: "Chittoor District Central Co-Operative Bank Ltd." },
  { value: "Chittoor Karthikeya Finance", label: "Chittoor Karthikeya Finance" },
  { value: "Chittoor Selvakumar Siddarth", label: "Chittoor Selvakumar Siddarth" },
  { value: "Chittor Co-Op Town Bank Ltd", label: "Chittor Co-Op Town Bank Ltd" },
  { value: "Chittorgarh District Central Co-Operative Bank Ltd.", label: "Chittorgarh District Central Co-Operative Bank Ltd." },
  { value: "Chittorgarh Kendriya Sahakari Bank Ltd.", label: "Chittorgarh Kendriya Sahakari Bank Ltd." },
  { value: "Chittorgarh Urban Co-Op Bank Ltd", label: "Chittorgarh Urban Co-Op Bank Ltd" },
  { value: "Chittorgarh Urban Co.Op.Bank Ltd.", label: "Chittorgarh Urban Co.Op.Bank Ltd." },
  { value: "Chohung Bank", label: "Chohung Bank" },
  { value: "Chola  Mandlam  Finance  & Investment  Co.Ltd", label: "Chola  Mandlam  Finance  & Investment  Co.Ltd" },
  { value: "Chola Financier", label: "Chola Financier" },
  { value: "Cholamamdalam Fin Co Ltd", label: "Cholamamdalam Fin Co Ltd" },
  { value: "Cholamandalam", label: "Cholamandalam" },
  { value: "Cholamandalam And Gbs Bank Ltd", label: "Cholamandalam And Gbs Bank Ltd" },
  { value: "Cholamandalam Consumer Finance Ltd", label: "Cholamandalam Consumer Finance Ltd" },
  { value: "Cholamandalam Dbs", label: "Cholamandalam Dbs" },
  { value: "Cholamandalam Dbs Bank", label: "Cholamandalam Dbs Bank" },
  { value: "Cholamandalam Dbs Fin(I) Ltd", label: "Cholamandalam Dbs Fin(I) Ltd" },
  { value: "Cholamandalam Dbs Finance Co Ltd", label: "Cholamandalam Dbs Finance Co Ltd" },
  { value: "Cholamandalam Dbs Finance Ltd.", label: "Cholamandalam Dbs Finance Ltd." },
  { value: "Cholamandalam Dbs Finance Pvt Ltd", label: "Cholamandalam Dbs Finance Pvt Ltd" },
  { value: "Cholamandalam Dbs Financial Services", label: "Cholamandalam Dbs Financial Services" },
  { value: "Cholamandalam Dbs Investment Finance Ltd", label: "Cholamandalam Dbs Investment Finance Ltd" },
  { value: "Cholamandalam Ddf Finance Ltd", label: "Cholamandalam Ddf Finance Ltd" },
  { value: "Cholamandalam Fin Pvt Ltd", label: "Cholamandalam Fin Pvt Ltd" },
  { value: "Cholamandalam Finance Corporation", label: "Cholamandalam Finance Corporation" },
  { value: "Cholamandalam Finance Ltd", label: "Cholamandalam Finance Ltd" },
  { value: "Cholamandalam G Finance Ltd", label: "Cholamandalam G Finance Ltd" },
  { value: "Cholamandalam Gen Ins", label: "Cholamandalam Gen Ins" },
  { value: "Cholamandalam Invest Fin Co Ltd", label: "Cholamandalam Invest Fin Co Ltd" },
  { value: "Cholamandalam Investment & Finance", label: "Cholamandalam Investment & Finance" },
  { value: "Cholamandalam Investment And Finance Co.", label: "Cholamandalam Investment And Finance Co." },
  { value: "Cholamandalam Investment And Finance Company Limited", label: "Cholamandalam Investment And Finance Company Limited" },
  { value: "Cholamandalam Investment Ltd", label: "Cholamandalam Investment Ltd" },
  { value: "Cholamandalam Nbs Finance Ltd", label: "Cholamandalam Nbs Finance Ltd" },
  { value: "Cholamandalam New Delhi", label: "Cholamandalam New Delhi" },
  { value: "Cholamandalam- Dbs Finance Ltd.", label: "Cholamandalam- Dbs Finance Ltd." },
  { value: "Cholamandalm Dbs Finance Services Ltd.", label: "Cholamandalm Dbs Finance Services Ltd." },
  { value: "Cholamandlam Bank", label: "Cholamandlam Bank" },
  { value: "Cholamandlam Inv & Fin Co Ltd", label: "Cholamandlam Inv & Fin Co Ltd" },
  { value: "Cholamandlam Inv & Fin Co Ltd", label: "Cholamandlam Inv & Fin Co Ltd" },
  { value: "Cholamandlam Investment Broker Pvt Ltd", label: "Cholamandlam Investment Broker Pvt Ltd" },
  { value: "Cholanadu Auto Associates", label: "Cholanadu Auto Associates" },
  { value: "Choolamandalam Hbs", label: "Choolamandalam Hbs" },
  { value: "Choparia Credit Corporation", label: "Choparia Credit Corporation" },
  { value: "Chopda Peoples Urban Co-Op Bank Ltd", label: "Chopda Peoples Urban Co-Op Bank Ltd" },
  { value: "Chopda Urban Co-Op. Bank Ltd.", label: "Chopda Urban Co-Op. Bank Ltd." },
  { value: "Chopra Automobile", label: "Chopra Automobile" },
  { value: "Chopra Credits Pvt Ltd", label: "Chopra Credits Pvt Ltd" },
  { value: "Chopra Enterprises", label: "Chopra Enterprises" },
  { value: "Chopra Hire Purchase Pvt Ltd.", label: "Chopra Hire Purchase Pvt Ltd." },
  { value: "Chopra Motors", label: "Chopra Motors" },
  { value: "Choudhary  Finance", label: "Choudhary  Finance" },
  { value: "Choudhary Auto Finance", label: "Choudhary Auto Finance" },
  { value: "Choudhary Chamb Pvt Ltd", label: "Choudhary Chamb Pvt Ltd" },
  { value: "Choudhry Fin. And Investments Pvt. Ltd.", label: "Choudhry Fin. And Investments Pvt. Ltd." },
  { value: "Choundeshwari Sahakari Bank Ltd.", label: "Choundeshwari Sahakari Bank Ltd." },
  { value: "Chowara Services Co-Op Bank", label: "Chowara Services Co-Op Bank" },
  { value: "Chowdary Financiers", label: "Chowdary Financiers" },
  { value: "Chozha Mandalam", label: "Chozha Mandalam" },
  { value: "Chozhamandalam Finance", label: "Chozhamandalam Finance" },
  { value: "Chpl Finance Ltd.", label: "Chpl Finance Ltd." },
  { value: "Chukkapalli Equipment Leasing And Finance Ltd.", label: "Chukkapalli Equipment Leasing And Finance Ltd." },
  { value: "Churaria Credit Corporation", label: "Churaria Credit Corporation" },
  { value: "Churu District Central Co-Operative Bank Ltd.", label: "Churu District Central Co-Operative Bank Ltd." },
  { value: "Churu Zila Urban Co Op Bank Ltd", label: "Churu Zila Urban Co Op Bank Ltd" },
  { value: "Ciba Speciality Chemical (India Ltd)", label: "Ciba Speciality Chemical (India Ltd)" },
  { value: "Cifco Finance", label: "Cifco Finance" },
  { value: "Cindicate Bank Ltd.", label: "Cindicate Bank Ltd." },
  { value: "Citi Bank", label: "Citi Bank" },
  { value: "Citi Bank Ltd.", label: "Citi Bank Ltd." },
  { value: "Citi Bank Maruti Finance Ltd.", label: "Citi Bank Maruti Finance Ltd." },
  { value: "Citi Bank N.A", label: "Citi Bank N.A" },
  { value: "Citi Cdrp India Ltd.", label: "Citi Cdrp India Ltd." },
  { value: "Citi Co Op Bank", label: "Citi Co Op Bank" },
  { value: "Citi Co Op Maruti Finance Ltd", label: "Citi Co Op Maruti Finance Ltd" },
  { value: "Citi Co-Op Bank Ltd,Ahmedabad", label: "Citi Co-Op Bank Ltd,Ahmedabad" },
  { value: "Citi Co-Op Credit Society Ltd.", label: "Citi Co-Op Credit Society Ltd." },
  { value: "Citi Cons Finance Service P Ltd", label: "Citi Cons Finance Service P Ltd" },
  { value: "Citi Corp Finance", label: "Citi Corp Finance" },
  { value: "Citi Corp Finance (P) Ltd.", label: "Citi Corp Finance (P) Ltd." },
  { value: "Citi Corp Finance Co Ltd", label: "Citi Corp Finance Co Ltd" },
  { value: "Citi Corp Finance India Ltd", label: "Citi Corp Finance India Ltd" },
  { value: "Citi Corp Finance India Ltd,   Cochin", label: "Citi Corp Finance India Ltd,   Cochin" },
  { value: "Citi Corp Finance Insurance", label: "Citi Corp Finance Insurance" },
  { value: "Citi Corp Finance Ltd", label: "Citi Corp Finance Ltd" },
  { value: "Citi Corp Finance Services Ltd", label: "Citi Corp Finance Services Ltd" },
  { value: "Citi Corp Financials (India) Ltd", label: "Citi Corp Financials (India) Ltd" },
  { value: "Citi Corp Maruti Finance", label: "Citi Corp Maruti Finance" },
  { value: "Citi Corp Maruti Pvt Ltd", label: "Citi Corp Maruti Pvt Ltd" },
  { value: "Citi Corp Motuli Financial", label: "Citi Corp Motuli Financial" },
  { value: "Citi Corp Trading Co", label: "Citi Corp Trading Co" },
  { value: "Citi Corp. Maruti Fin. Ltd.", label: "Citi Corp. Maruti Fin. Ltd." },
  { value: "Citi Corp.Maruti Finance Ltd", label: "Citi Corp.Maruti Finance Ltd" },
  { value: "Citi Corpn Finance (India) Ltd.", label: "Citi Corpn Finance (India) Ltd." },
  { value: "Citi Corporation Bank", label: "Citi Corporation Bank" },
  { value: "Citi Corporation Finance", label: "Citi Corporation Finance" },
  { value: "Citi Corporation Finance Ltd", label: "Citi Corporation Finance Ltd" },
  { value: "Citi Crop Finance India Ltd", label: "Citi Crop Finance India Ltd" },
  { value: "Citi Fin C.F.India", label: "Citi Fin C.F.India" },
  { value: "Citi Fin Con India Ltd", label: "Citi Fin Con India Ltd" },
  { value: "Citi Fin. Consumer Fin. India Ltd.", label: "Citi Fin. Consumer Fin. India Ltd." },
  { value: "Citi Finance", label: "Citi Finance" },
  { value: "Citi Finance Co. Ltd.", label: "Citi Finance Co. Ltd." },
  { value: "Citi Finance Consumer Finance India Ltd", label: "Citi Finance Consumer Finance India Ltd" },
  { value: "Citi Finance Consumer Finance Ltd", label: "Citi Finance Consumer Finance Ltd" },
  { value: "Citi Finance Ltd", label: "Citi Finance Ltd" },
  { value: "Citi Finance Pvt Ltd", label: "Citi Finance Pvt Ltd" },
  { value: "Citi Financial", label: "Citi Financial" },
  { value: "Citi Financial  Cf India Ltd", label: "Citi Financial  Cf India Ltd" },
  { value: "Citi Financial Cfi Ltd", label: "Citi Financial Cfi Ltd" },
  { value: "Citi Financial Cfs Ltd", label: "Citi Financial Cfs Ltd" },
  { value: "Citi Financial Consumer Fin. India Ltd", label: "Citi Financial Consumer Fin. India Ltd" },
  { value: "Citi Financial Consumer Finance India Ltd", label: "Citi Financial Consumer Finance India Ltd" },
  { value: "Citi Financial Consumer Finance Ltd", label: "Citi Financial Consumer Finance Ltd" },
  { value: "Citi Financial Consumer Finance Ltd (P) Ltd", label: "Citi Financial Consumer Finance Ltd (P) Ltd" },
  { value: "Citi Financial Consumer India Ltd", label: "Citi Financial Consumer India Ltd" },
  { value: "Citi Financial Corpn. I. Ltd.", label: "Citi Financial Corpn. I. Ltd." },
  { value: "Citi Financial India Ltd", label: "Citi Financial India Ltd" },
  { value: "Citi Financial Ltd", label: "Citi Financial Ltd" },
  { value: "Citi Financial Retail  S India Ltd", label: "Citi Financial Retail  S India Ltd" },
  { value: "Citi Financial Services Ltd", label: "Citi Financial Services Ltd" },
  { value: "Citi Fiscal Service (P) Ltd.", label: "Citi Fiscal Service (P) Ltd." },
  { value: "Citi Group", label: "Citi Group" },
  { value: "Citi Maruti Finance Ltd", label: "Citi Maruti Finance Ltd" },
  { value: "Citi Motor Financial Services Ltd", label: "Citi Motor Financial Services Ltd" },
  { value: "Citi Motors Finance (India) Ltd", label: "Citi Motors Finance (India) Ltd" },
  { value: "Citi Pattina Sahakara Sangha Niyamitha", label: "Citi Pattina Sahakara Sangha Niyamitha" },
  { value: "Citi Union Maruti Fin Ltd", label: "Citi Union Maruti Fin Ltd" },
  { value: "Citi. Fin. Con. Fin India Ltd", label: "Citi. Fin. Con. Fin India Ltd" },
  { value: "Citiauto Copr Ltd", label: "Citiauto Copr Ltd" },
  { value: "Citibank", label: "Citibank" },
  { value: "Citibank N A", label: "Citibank N A" },
  { value: "Citicorp Finance [I] Ltd.,Surat", label: "Citicorp Finance [I] Ltd.,Surat" },
  { value: "Citicorp Finance India Lted", label: "Citicorp Finance India Lted" },
  { value: "Citicorp Finance Ltd.", label: "Citicorp Finance Ltd." },
  { value: "Citicorp Financial Services Ltd", label: "Citicorp Financial Services Ltd" },
  { value: "Citicorp Financials (India) Ltd", label: "Citicorp Financials (India) Ltd" },
  { value: "Citicorp Maruti Finance Limited", label: "Citicorp Maruti Finance Limited" },
  { value: "Citicoys Finance (I) Pvt Ltd.", label: "Citicoys Finance (I) Pvt Ltd." },
  { value: "Citicrop Financial Ltd", label: "Citicrop Financial Ltd" },
  { value: "Citifinancial Cfil", label: "Citifinancial Cfil" },
  { value: "Citifinancial Con Fin I Ltd", label: "Citifinancial Con Fin I Ltd" },
  { value: "Citizen Bank Of Punjab", label: "Citizen Bank Of Punjab" },
  { value: "Citizen Co-Op Bank Ltd.", label: "Citizen Co-Op Bank Ltd." },
  { value: "Citizen Credit Coop Bank Ltd", label: "Citizen Credit Coop Bank Ltd" },
  { value: "Citizen Fin Ser", label: "Citizen Fin Ser" },
  { value: "Citizen Urban Co-Op Bank Ltd., Jullunder", label: "Citizen Urban Co-Op Bank Ltd., Jullunder" },
  { value: "Citizencredit Co-Op Bank Ltd", label: "Citizencredit Co-Op Bank Ltd" },
  { value: "Citizencredit Co-Op Bank Ltd.", label: "Citizencredit Co-Op Bank Ltd." },
  { value: "Citizens Co-Op Bank Ltd.", label: "Citizens Co-Op Bank Ltd." },
  { value: "City Auto Finance", label: "City Auto Finance" },
  { value: "City Bank", label: "City Bank" },
  { value: "City Bankers", label: "City Bankers" },
  { value: "City Co - Op Bank Ltd", label: "City Co - Op Bank Ltd" },
  { value: "City Co Op Bank", label: "City Co Op Bank" },
  { value: "City Co Op Credit Soc Ltd", label: "City Co Op Credit Soc Ltd" },
  { value: "City Co-Op Bank Ltd., Guwahati", label: "City Co-Op Bank Ltd., Guwahati" },
  { value: "City Co-Op. Bank Ltd., Lucknow", label: "City Co-Op. Bank Ltd., Lucknow" },
  { value: "City Co-Opration Finance Ltd", label: "City Co-Opration Finance Ltd" },
  { value: "City Commercial Credit Corp", label: "City Commercial Credit Corp" },
  { value: "City Consumer Financial Ltd", label: "City Consumer Financial Ltd" },
  { value: "City Corp", label: "City Corp" },
  { value: "City Corp Finance (P) Ltd.", label: "City Corp Finance (P) Ltd." },
  { value: "City Corp Finance Ltd", label: "City Corp Finance Ltd" },
  { value: "City Corp Maruti Fianance Ltd", label: "City Corp Maruti Fianance Ltd" },
  { value: "City Corp. Finance India Ltd", label: "City Corp. Finance India Ltd" },
  { value: "City Credit Co-Op. Bank Ltd.", label: "City Credit Co-Op. Bank Ltd." },
  { value: "City Crop Finance Ltd", label: "City Crop Finance Ltd" },
  { value: "City Finance", label: "City Finance" },
  { value: "City Finance - Udupi", label: "City Finance - Udupi" },
  { value: "City Finance Bank", label: "City Finance Bank" },
  { value: "City Finance Company - Udupi", label: "City Finance Company - Udupi" },
  { value: "City Finance India Ltd", label: "City Finance India Ltd" },
  { value: "City Finances Ltd", label: "City Finances Ltd" },
  { value: "City Financial Consumer Finance India Ltd", label: "City Financial Consumer Finance India Ltd" },
  { value: "City Financial Consumer Finance Ltd", label: "City Financial Consumer Finance Ltd" },
  { value: "City Financial Retail Services", label: "City Financial Retail Services" },
  { value: "City Financier Con India Ltd", label: "City Financier Con India Ltd" },
  { value: "City Group Finance Ltd", label: "City Group Finance Ltd" },
  { value: "City Maruthi Finance Ltd", label: "City Maruthi Finance Ltd" },
  { value: "City Maruti Corporation", label: "City Maruti Corporation" },
  { value: "City Minority Co-Operative Credit Society", label: "City Minority Co-Operative Credit Society" },
  { value: "City Motor Fin-Invest Pvt. Ltd.", label: "City Motor Fin-Invest Pvt. Ltd." },
  { value: "City Motor Finance", label: "City Motor Finance" },
  { value: "City Motors Finance", label: "City Motors Finance" },
  { value: "City Pathina Sahakara Sangha", label: "City Pathina Sahakara Sangha" },
  { value: "City Union Bank Limited", label: "City Union Bank Limited" },
  { value: "City Union Bank Ltd.", label: "City Union Bank Ltd." },
  { value: "City Union Finance", label: "City Union Finance" },
  { value: "City Union Finance Ltd", label: "City Union Finance Ltd" },
  { value: "City- Union Finance Ltd", label: "City- Union Finance Ltd" },
  { value: "Citycorp  Finance (I) Ltd", label: "Citycorp  Finance (I) Ltd" },
  { value: "Cityfort Finvest Ltd", label: "Cityfort Finvest Ltd" },
  { value: "Cj Finance Ltd", label: "Cj Finance Ltd" },
  { value: "Ck Motors", label: "Ck Motors" },
  { value: "Ck Motors Ltd", label: "Ck Motors Ltd" },
  { value: "Classic Finance", label: "Classic Finance" },
  { value: "Club 7 Holidays Pvt.Ltd", label: "Club 7 Holidays Pvt.Ltd" },
  { value: "Cmac Financial Services India Ltd", label: "Cmac Financial Services India Ltd" },
  { value: "Cmc Ltd", label: "Cmc Ltd" },
  { value: "Cmd Nhpc Pvt Ltd", label: "Cmd Nhpc Pvt Ltd" },
  { value: "Cmdeil", label: "Cmdeil" },
  { value: "Cmfc", label: "Cmfc" },
  { value: "Cmfl", label: "Cmfl" },
  { value: "Cmwss Board", label: "Cmwss Board" },
  { value: "Co - Op Bank Of Mehsana", label: "Co - Op Bank Of Mehsana" },
  { value: "Co Op Agricultural Rural Bank", label: "Co Op Agricultural Rural Bank" },
  { value: "Co Op Bank Talodhi", label: "Co Op Bank Talodhi" },
  { value: "Co Op Credit Society  Ltd", label: "Co Op Credit Society  Ltd" },
  { value: "Co Operative Bank", label: "Co Operative Bank" },
  { value: "Co Operative Bank Krushna Begade Nagari Sahakari Pathsanstha Maryadit", label: "Co Operative Bank Krushna Begade Nagari Sahakari Pathsanstha Maryadit" },
  { value: "Co Operative Bank Society Ltd", label: "Co Operative Bank Society Ltd" },
  { value: "Co- Operative Bank Of Baroda", label: "Co- Operative Bank Of Baroda" },
  { value: "Co-Op Bank Ltd", label: "Co-Op Bank Ltd" },
  { value: "Co-Op Bank Of Ahmedabad", label: "Co-Op Bank Of Ahmedabad" },
  { value: "Co-Op Bank Of Rajkot Ltd", label: "Co-Op Bank Of Rajkot Ltd" },
  { value: "Co-Op Society", label: "Co-Op Society" },
  { value: "Co-Op Urban Bank Ltd.", label: "Co-Op Urban Bank Ltd." },
  { value: "Co-Op. Bank Of Rajkot", label: "Co-Op. Bank Of Rajkot" },
  { value: "Co-Operative Agree Multipurpose Society Ltd", label: "Co-Operative Agree Multipurpose Society Ltd" },
  { value: "Co-Operative Agricultural Bank Ltd.", label: "Co-Operative Agricultural Bank Ltd." },
  { value: "Co-Operative Agriculture Bank Ltd", label: "Co-Operative Agriculture Bank Ltd" },
  { value: "Co-Operative Bank Baroda Ltd.", label: "Co-Operative Bank Baroda Ltd." },
  { value: "Co-Operative Bank Of Ahmedabad Ltd.", label: "Co-Operative Bank Of Ahmedabad Ltd." },
  { value: "Co-Operative Bank Of Baroda Ltd.", label: "Co-Operative Bank Of Baroda Ltd." },
  { value: "Co-Operative Bank Of Umreth Ltd.", label: "Co-Operative Bank Of Umreth Ltd." },
  { value: "Co-Operative Centrale Raiffesien Boerenleen Bank B.A., Singapore", label: "Co-Operative Centrale Raiffesien Boerenleen Bank B.A., Singapore" },
  { value: "Co-Operative City Bank", label: "Co-Operative City Bank" },
  { value: "Co-Operative Mini Bank", label: "Co-Operative Mini Bank" },
  { value: "Co.Operative Urban Bank Ltd", label: "Co.Operative Urban Bank Ltd" },
  { value: "Coal City Urban Co-Op Credit Society Ltd.", label: "Coal City Urban Co-Op Credit Society Ltd." },
  { value: "Coal India Limited", label: "Coal India Limited" },
  { value: "Coastal Bank", label: "Coastal Bank" },
  { value: "Coastal Gujarat Power Ltd", label: "Coastal Gujarat Power Ltd" },
  { value: "Coastal Local Area Bank Ltd.", label: "Coastal Local Area Bank Ltd." },
  { value: "Coastal Urban Co-Op Bank Ltd", label: "Coastal Urban Co-Op Bank Ltd" },
  { value: "Coca Cola", label: "Coca Cola" },
  { value: "Cocacola India Pvt Ltd", label: "Cocacola India Pvt Ltd" },
  { value: "Cochin Auto Finance", label: "Cochin Auto Finance" },
  { value: "Cochin Hire Purchase", label: "Cochin Hire Purchase" },
  { value: "Cochin Refineries Ltd.", label: "Cochin Refineries Ltd." },
  { value: "Cochin Shipyard Ltd.", label: "Cochin Shipyard Ltd." },
  { value: "Cochin University Of Science And Technology", label: "Cochin University Of Science And Technology" },
  { value: "Coconut Development Board", label: "Coconut Development Board" },
  { value: "Coffee Board", label: "Coffee Board" },
  { value: "Coimbatore City Co-Op Bank Ltd", label: "Coimbatore City Co-Op Bank Ltd" },
  { value: "Coimbatore District Central Co-Operative Bank Ltd.", label: "Coimbatore District Central Co-Operative Bank Ltd." },
  { value: "Col. R D Nikam Sainik Sahakari Bank Ltd", label: "Col. R D Nikam Sainik Sahakari Bank Ltd" },
  { value: "Colgate Palmolive India Ltd.", label: "Colgate Palmolive India Ltd." },
  { value: "Colour Gbk Garg Fin Services", label: "Colour Gbk Garg Fin Services" },
  { value: "Colour Mercantile Co-Operative Bank Ltd.", label: "Colour Mercantile Co-Operative Bank Ltd." },
  { value: "Colour Merchants Co-Op Bank Ltd.", label: "Colour Merchants Co-Op Bank Ltd." },
  { value: "Colour Merchants Co-Op. Bank Ltd", label: "Colour Merchants Co-Op. Bank Ltd" },
  { value: "Comet Leasing And Finance", label: "Comet Leasing And Finance" },
  { value: "Command Area Development Authority", label: "Command Area Development Authority" },
  { value: "Commercial Auto Products P Ltd", label: "Commercial Auto Products P Ltd" },
  { value: "Commercial Co-Op Bank Ltd.", label: "Commercial Co-Op Bank Ltd." },
  { value: "Commercial Co-Op. Bank Ltd.", label: "Commercial Co-Op. Bank Ltd." },
  { value: "Commercial Credit Corporation (1943) Pvt Ltd", label: "Commercial Credit Corporation (1943) Pvt Ltd" },
  { value: "Commercial Hire Purchase", label: "Commercial Hire Purchase" },
  { value: "Commercial Motors & General Finance Co(Regd.)", label: "Commercial Motors & General Finance Co(Regd.)" },
  { value: "Commerzbank  Ag", label: "Commerzbank  Ag" },
  { value: "Commissioner Of Customs", label: "Commissioner Of Customs" },
  { value: "Commissionerate Of Central Excise", label: "Commissionerate Of Central Excise" },
  { value: "Commissionerate Of Customs", label: "Commissionerate Of Customs" },
  { value: "Commisssion For Scientific And Technical Terminology", label: "Commisssion For Scientific And Technical Terminology" },
  { value: "Commonwealth Finance Co", label: "Commonwealth Finance Co" },
  { value: "Companions Co Op Credit Soc Ltd", label: "Companions Co Op Credit Soc Ltd" },
  { value: "Compeer Finance", label: "Compeer Finance" },
  { value: "Compeer Leasing And Investment Pvt Ltd", label: "Compeer Leasing And Investment Pvt Ltd" },
  { value: "Competent Finlease Pvt Ltd.", label: "Competent Finlease Pvt Ltd." },
  { value: "Competent Securities P. Ltd", label: "Competent Securities P. Ltd" },
  { value: "Complete Insurance Solutions", label: "Complete Insurance Solutions" },
  { value: "Comptroice University Of Agriculture Sciences G.K.V.K.", label: "Comptroice University Of Agriculture Sciences G.K.V.K." },
  { value: "Comptroller And Auditor General Of India", label: "Comptroller And Auditor General Of India" },
  { value: "Comptroller Uas - Dharwad", label: "Comptroller Uas - Dharwad" },
  { value: "Comptrollers Office Co-Op Bank Ltd", label: "Comptrollers Office Co-Op Bank Ltd" },
  { value: "Concord Credit Ltd", label: "Concord Credit Ltd" },
  { value: "Concord Marketing And Financiers Ltd.", label: "Concord Marketing And Financiers Ltd." },
  { value: "Concrd Credit Ltd", label: "Concrd Credit Ltd" },
  { value: "Consortium Finance And Leasing Limited", label: "Consortium Finance And Leasing Limited" },
  { value: "Consortium Of Bankers With Uco Bank As Leader", label: "Consortium Of Bankers With Uco Bank As Leader" },
  { value: "Consular Passport And Visa Division", label: "Consular Passport And Visa Division" },
  { value: "Contai Co-Op Bank Ltd", label: "Contai Co-Op Bank Ltd" },
  { value: "Contai Co-Operative Bank Pvt Ltd", label: "Contai Co-Operative Bank Pvt Ltd" },
  { value: "Contai Co. Op. Bank", label: "Contai Co. Op. Bank" },
  { value: "Container Corporation Of India Ltd.", label: "Container Corporation Of India Ltd." },
  { value: "Contintal Investments", label: "Contintal Investments" },
  { value: "Cooperative Bank Of Baroda", label: "Cooperative Bank Of Baroda" },
  { value: "Coral Enterprise", label: "Coral Enterprise" },
  { value: "Corporation Band - Tadari", label: "Corporation Band - Tadari" },
  { value: "Corporation Bank - Belman", label: "Corporation Bank - Belman" },
  { value: "Corporation Bank - Doopadakatte", label: "Corporation Bank - Doopadakatte" },
  { value: "Corporation Bank - Kuthpady", label: "Corporation Bank - Kuthpady" },
  { value: "Corporation Bank - Salmar - Karkala", label: "Corporation Bank - Salmar - Karkala" },
  { value: "Corporation Bank - Sirsi.", label: "Corporation Bank - Sirsi." },
  { value: "Corporation Bank - Uliyargoli", label: "Corporation Bank - Uliyargoli" },
  { value: "Corporation Bank - Varamballi", label: "Corporation Bank - Varamballi" },
  { value: "Corporation Bank Kalyanpur Santhekatte", label: "Corporation Bank Kalyanpur Santhekatte" },
  { value: "Corporation Bank Ltd", label: "Corporation Bank Ltd" },
  { value: "Corporation Bank Of India", label: "Corporation Bank Of India" },
  { value: "Corporation Bank Varambally  Brahmavara.", label: "Corporation Bank Varambally  Brahmavara." },
  { value: "Corporation For Scst Ltd", label: "Corporation For Scst Ltd" },
  { value: "Corporation Maruti Finance", label: "Corporation Maruti Finance" },
  { value: "Corpration Bank", label: "Corpration Bank" },
  { value: "Cosmos Bank", label: "Cosmos Bank" },
  { value: "Cosmos Co-Op Bank Ltd.", label: "Cosmos Co-Op Bank Ltd." },
  { value: "Cosmos Financial Services Limited", label: "Cosmos Financial Services Limited" },
  { value: "Cotton Corporation Of India Ltd.", label: "Cotton Corporation Of India Ltd." },
  { value: "Cotton Textiles Export Promotion Council", label: "Cotton Textiles Export Promotion Council" },
  { value: "Council For Advancement Of People`S Action And Rural Technology", label: "Council For Advancement Of People`S Action And Rural Technology" },
  { value: "Council For Metereology And Atmospheric Sciences", label: "Council For Metereology And Atmospheric Sciences" },
  { value: "Council Of Scientific And Industrial Research", label: "Council Of Scientific And Industrial Research" },
  { value: "Countriwide Finance Ltd", label: "Countriwide Finance Ltd" },
  { value: "Country Wide Auto Financier", label: "Country Wide Auto Financier" },
  { value: "Country Wide Consumer Financial", label: "Country Wide Consumer Financial" },
  { value: "Country Wide Finance", label: "Country Wide Finance" },
  { value: "Countwell  Management Ser Pvt Ltd", label: "Countwell  Management Ser Pvt Ltd" },
  { value: "Coyalmannam Service Co-Operative Bank Lt", label: "Coyalmannam Service Co-Operative Bank Lt" },
  { value: "Creative Tours And Travels", label: "Creative Tours And Travels" },
  { value: "Credent Insurance Services", label: "Credent Insurance Services" },
  { value: "Credit Agricole Indosuez", label: "Credit Agricole Indosuez" },
  { value: "Credit Analysis & Research Ltdv", label: "Credit Analysis & Research Ltdv" },
  { value: "Credit Care Finance", label: "Credit Care Finance" },
  { value: "Credit Care Finance & Investment", label: "Credit Care Finance & Investment" },
  { value: "Credit Factory Coop Bank Ltd", label: "Credit Factory Coop Bank Ltd" },
  { value: "Credit Lyonnais", label: "Credit Lyonnais" },
  { value: "Credit Lyonnais", label: "Credit Lyonnais" },
  { value: "Credit Society Ltd", label: "Credit Society Ltd" },
  { value: "Credit Suvidha India", label: "Credit Suvidha India" },
  { value: "Croda Chemicals India Pvt Ltd", label: "Croda Chemicals India Pvt Ltd" },
  { value: "Crystal Capital Corporation Ltd.", label: "Crystal Capital Corporation Ltd." },
  { value: "Crystal Credits Corporation Ltd.", label: "Crystal Credits Corporation Ltd." },
  { value: "Crystal Financial Services", label: "Crystal Financial Services" },
  { value: "Csa Motor Pvt Ltd", label: "Csa Motor Pvt Ltd" },
  { value: "Csc India Pvt Ltd", label: "Csc India Pvt Ltd" },
  { value: "Csi Credit Co-Operative Society", label: "Csi Credit Co-Operative Society" },
  { value: "Csir Centre For Mathematical Modelling And Computer Simulation", label: "Csir Centre For Mathematical Modelling And Computer Simulation" },
  { value: "Ctc Credit Co-Op Ltd", label: "Ctc Credit Co-Op Ltd" },
  { value: "Cucu Auto Center", label: "Cucu Auto Center" },
  { value: "Cuddalore And Villupuram D C C B E Co-Op Bank Ltd", label: "Cuddalore And Villupuram D C C B E Co-Op Bank Ltd" },
  { value: "Cuddalore District Central Co-Operative Bank Ltd.", label: "Cuddalore District Central Co-Operative Bank Ltd." },
  { value: "Cuddappah District Central Co-Operative Bank Ltd.", label: "Cuddappah District Central Co-Operative Bank Ltd." },
  { value: "Cuttack Central Co Operative Bank Ltd", label: "Cuttack Central Co Operative Bank Ltd" },
  { value: "Cuttack District Central Co-Operative Bank Ltd.", label: "Cuttack District Central Co-Operative Bank Ltd." },
  { value: "Cuttack Kendra Samabaya Bank Ltd,", label: "Cuttack Kendra Samabaya Bank Ltd," },
  { value: "Cvijaybabu", label: "Cvijaybabu" },
  { value: "Cycle Corporation Of India Ltd", label: "Cycle Corporation Of India Ltd" },
  { value: "D Babul Finance", label: "D Babul Finance" },
  { value: "D Babulal Finance", label: "D Babulal Finance" },
  { value: "D C Auto Finance", label: "D C Auto Finance" },
  { value: "D C B Bank", label: "D C B Bank" },
  { value: "D C Bokdia", label: "D C Bokdia" },
  { value: "D C C Bank", label: "D C C Bank" },
  { value: "D Chetan Prakash", label: "D Chetan Prakash" },
  { value: "D Chetan Prakash", label: "D Chetan Prakash" },
  { value: "D Chetan Prakash Financier", label: "D Chetan Prakash Financier" },
  { value: "D Devaraj Urs Back Class Development Corporation", label: "D Devaraj Urs Back Class Development Corporation" },
  { value: "D E P L Finance Company", label: "D E P L Finance Company" },
  { value: "D G Bank", label: "D G Bank" },
  { value: "D H Finance", label: "D H Finance" },
  { value: "D H P Leasing Pvt. Ltd", label: "D H P Leasing Pvt. Ltd" },
  { value: "D Hipparagi Co-Operative Credit Society Ltd.", label: "D Hipparagi Co-Operative Credit Society Ltd." },
  { value: "D J Auto Finance", label: "D J Auto Finance" },
  { value: "D M Auto Finance Co.", label: "D M Auto Finance Co." },
  { value: "D M Finance", label: "D M Finance" },
  { value: "D M K J Sahakari Bank Ltd", label: "D M K J Sahakari Bank Ltd" },
  { value: "D M K Jauoli Sahakari Bank Ltd (Ghatkopar)", label: "D M K Jauoli Sahakari Bank Ltd (Ghatkopar)" },
  { value: "D Mallikarjuna A/F", label: "D Mallikarjuna A/F" },
  { value: "D Mallikarjuna Auto Finance", label: "D Mallikarjuna Auto Finance" },
  { value: "D Malyadri Automobile Financier", label: "D Malyadri Automobile Financier" },
  { value: "D N Motors", label: "D N Motors" },
  { value: "D Rajesh Kumar", label: "D Rajesh Kumar" },
  { value: "D S Brar Finance Co. Pvt. Ltd", label: "D S Brar Finance Co. Pvt. Ltd" },
  { value: "D S Enterprises", label: "D S Enterprises" },
  { value: "D S Finance", label: "D S Finance" },
  { value: "D S Finance", label: "D S Finance" },
  { value: "D S T O Co Op T C And Ser Socl Ltd", label: "D S T O Co Op T C And Ser Socl Ltd" },
  { value: "D Sanjana Laiwani", label: "D Sanjana Laiwani" },
  { value: "D Santhosh Kawar Financier", label: "D Santhosh Kawar Financier" },
  { value: "D Santosh Kawar Financer", label: "D Santosh Kawar Financer" },
  { value: "D Suresh Kumar", label: "D Suresh Kumar" },
  { value: "D Uthamchand Jain", label: "D Uthamchand Jain" },
  { value: "D V G Cob Ltd.", label: "D V G Cob Ltd." },
  { value: "D Venkat Rao", label: "D Venkat Rao" },
  { value: "D Venkateswara Reddy Automobile Financiers", label: "D Venkateswara Reddy Automobile Financiers" },
  { value: "D. Anitha", label: "D. Anitha" },
  { value: "D. Padam Chand & Sons", label: "D. Padam Chand & Sons" },
  { value: "D. Praveen Kumar Financier", label: "D. Praveen Kumar Financier" },
  { value: "D. Priya", label: "D. Priya" },
  { value: "D. R. Motors", label: "D. R. Motors" },
  { value: "D.B. Auto Finance", label: "D.B. Auto Finance" },
  { value: "D.Babulal Finance", label: "D.Babulal Finance" },
  { value: "D.C. Auto Finance", label: "D.C. Auto Finance" },
  { value: "D.C.B.", label: "D.C.B." },
  { value: "D.C.B. Finance Ltd.", label: "D.C.B. Finance Ltd." },
  { value: "D.D.C.C. Bank", label: "D.D.C.C. Bank" },
  { value: "D.D.M. & Co", label: "D.D.M. & Co" },
  { value: "D.Dilip Kumar Financier", label: "D.Dilip Kumar Financier" },
  { value: "D.G. Fine Co Regd Tarn Taran", label: "D.G. Fine Co Regd Tarn Taran" },
  { value: "D.G.M.C (Pandhr) Mangalore Refineries And Petrochemicals Ltd.", label: "D.G.M.C (Pandhr) Mangalore Refineries And Petrochemicals Ltd." },
  { value: "D.Lalit Kumar Jain", label: "D.Lalit Kumar Jain" },
  { value: "D.M.Finance", label: "D.M.Finance" },
  { value: "D.M.H & F.D.C", label: "D.M.H & F.D.C" },
  { value: "D.N.Yadav Auto Deals", label: "D.N.Yadav Auto Deals" },
  { value: "D.P.V.S.S. Bank Ltd", label: "D.P.V.S.S. Bank Ltd" },
  { value: "D.Premalatha", label: "D.Premalatha" },
  { value: "D.R.D.A", label: "D.R.D.A" },
  { value: "D.R.G.B. Bank", label: "D.R.G.B. Bank" },
  { value: "D.S Enterprises", label: "D.S Enterprises" },
  { value: "D.S.M. Capitals Ltd.", label: "D.S.M. Capitals Ltd." },
  { value: "D.S.P Finance", label: "D.S.P Finance" },
  { value: "Dabhi Dharmendra Sinh K", label: "Dabhi Dharmendra Sinh K" },
  { value: "Dabhoi Nag.Sah.Bank Ltd.", label: "Dabhoi Nag.Sah.Bank Ltd." },
  { value: "Dabur Finance Limited", label: "Dabur Finance Limited" },
  { value: "Dada Dev Finance", label: "Dada Dev Finance" },
  { value: "Dada Guru Credit Corporation", label: "Dada Guru Credit Corporation" },
  { value: "Dada Guru Enterprise", label: "Dada Guru Enterprise" },
  { value: "Dada Guru Hire Purchase", label: "Dada Guru Hire Purchase" },
  { value: "Dada Guru Investments", label: "Dada Guru Investments" },
  { value: "Dada Guru Motor Finance Pvt. Ltd.", label: "Dada Guru Motor Finance Pvt. Ltd." },
  { value: "Dada Gurudev Finance", label: "Dada Gurudev Finance" },
  { value: "Dada Motors", label: "Dada Motors" },
  { value: "Dada Motors Pvt Ltd", label: "Dada Motors Pvt Ltd" },
  { value: "Dada Nagari Sahakari Pat Sanstha Maryadit", label: "Dada Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Dadadev Finance And Leasing Pvt. Ltd.", label: "Dadadev Finance And Leasing Pvt. Ltd." },
  { value: "Dadaji Auto Finance", label: "Dadaji Auto Finance" },
  { value: "Dadaji Finance", label: "Dadaji Finance" },
  { value: "Dadamani Finance Co.", label: "Dadamani Finance Co." },
  { value: "Dadaps Hq Wc", label: "Dadaps Hq Wc" },
  { value: "Dadasaheb Baluseth Vispute Gramin Bigersheti Sahkari Patpedhi Ltd.", label: "Dadasaheb Baluseth Vispute Gramin Bigersheti Sahkari Patpedhi Ltd." },
  { value: "Dadasaheb Dr. N. M. Kabre Nag.Sah.Bk Ltd", label: "Dadasaheb Dr. N. M. Kabre Nag.Sah.Bk Ltd" },
  { value: "Dadasaheb Ramrao Patil Co Op Bank Ltd.", label: "Dadasaheb Ramrao Patil Co Op Bank Ltd." },
  { value: "Dadasaheb Ramrao Patil Co-Op Bank Ltd", label: "Dadasaheb Ramrao Patil Co-Op Bank Ltd" },
  { value: "Dadasaheb Rawal Co-Op Bank Ltd", label: "Dadasaheb Rawal Co-Op Bank Ltd" },
  { value: "Dadasaheb Sathe Madha Taluka G.R.B.S.S.P.S.M.", label: "Dadasaheb Sathe Madha Taluka G.R.B.S.S.P.S.M." },
  { value: "Dafria Finance", label: "Dafria Finance" },
  { value: "Daga Investments", label: "Daga Investments" },
  { value: "Dagaria Finance Syndicate", label: "Dagaria Finance Syndicate" },
  { value: "Dahanu Road Janata Co-Op Bank Ltd", label: "Dahanu Road Janata Co-Op Bank Ltd" },
  { value: "Dahisar Co-Op Credit Bank Ltd.", label: "Dahisar Co-Op Credit Bank Ltd." },
  { value: "Dahiser Co Op Credit Society Ltd", label: "Dahiser Co Op Credit Society Ltd" },
  { value: "Dahison Automobiles", label: "Dahison Automobiles" },
  { value: "Dahiya Finance Leasing Pvt Ltd", label: "Dahiya Finance Leasing Pvt Ltd" },
  { value: "Dahod Merchantile Co-Op Bank Ltd", label: "Dahod Merchantile Co-Op Bank Ltd" },
  { value: "Dahod Urban Co-Op Bank Ltd", label: "Dahod Urban Co-Op Bank Ltd" },
  { value: "Daivadnya Sahakar Bank Niyamit", label: "Daivadnya Sahakar Bank Niyamit" },
  { value: "Daiwajna Credit Co-Op Society Ltd - Kundapura", label: "Daiwajna Credit Co-Op Society Ltd - Kundapura" },
  { value: "Daiwajna Credit Co.Op Society Ltd - Udupi", label: "Daiwajna Credit Co.Op Society Ltd - Udupi" },
  { value: "Daj Financial Services And Consultants", label: "Daj Financial Services And Consultants" },
  { value: "Dakhadevi Kumbhat", label: "Dakhadevi Kumbhat" },
  { value: "Daksh Auto Finance", label: "Daksh Auto Finance" },
  { value: "Dakshi Dinajpur Agricultural And Rural Development Bank Ltd", label: "Dakshi Dinajpur Agricultural And Rural Development Bank Ltd" },
  { value: "Dakshin Dinajpur Central Co-Op Bank Ltd", label: "Dakshin Dinajpur Central Co-Op Bank Ltd" },
  { value: "Dakshina Kannada Jilla Mahila Co-Op Bank Ltd", label: "Dakshina Kannada Jilla Mahila Co-Op Bank Ltd" },
  { value: "Daksum Finance Pvt. Ltd.", label: "Daksum Finance Pvt. Ltd." },
  { value: "Dalimbe Belegarar Pattin Sahakari Sangha Ltd", label: "Dalimbe Belegarar Pattin Sahakari Sangha Ltd" },
  { value: "Dalmia Finance", label: "Dalmia Finance" },
  { value: "Dalmiapuram Employees Co-Op Bank Ltd", label: "Dalmiapuram Employees Co-Op Bank Ltd" },
  { value: "Dama Nellore Pacs & Ndcc Bank Ltd", label: "Dama Nellore Pacs & Ndcc Bank Ltd" },
  { value: "Damdama Trading Co Op Society Ltd", label: "Damdama Trading Co Op Society Ltd" },
  { value: "Damoh Panna Sagar Kshetriya Gramin Bank", label: "Damoh Panna Sagar Kshetriya Gramin Bank" },
  { value: "Damoh-Panna Sagar Kshetriya Gramin Bank,", label: "Damoh-Panna Sagar Kshetriya Gramin Bank," },
  { value: "Danalaxmi Co-Op Bank", label: "Danalaxmi Co-Op Bank" },
  { value: "Danda Co Op Credit Society.Ltd", label: "Danda Co Op Credit Society.Ltd" },
  { value: "Dandapani Finance", label: "Dandapani Finance" },
  { value: "Dandeliyappa Kps Bank", label: "Dandeliyappa Kps Bank" },
  { value: "Dandona Finance Ltd", label: "Dandona Finance Ltd" },
  { value: "Danesh Motor Finance Company Pvt. Ltd.", label: "Danesh Motor Finance Company Pvt. Ltd." },
  { value: "Dangrwal Finance Ltd.", label: "Dangrwal Finance Ltd." },
  { value: "Dani Capital And Investments Company P Ltd.", label: "Dani Capital And Investments Company P Ltd." },
  { value: "Dani Enterprises P Ltd.", label: "Dani Enterprises P Ltd." },
  { value: "Dani Finance And Investments Company P Ltd.", label: "Dani Finance And Investments Company P Ltd." },
  { value: "Dani Finlease Ltd.", label: "Dani Finlease Ltd." },
  { value: "Dani Holding And Trading Company P Ltd.", label: "Dani Holding And Trading Company P Ltd." },
  { value: "Dani Trading And Investments Ltd.", label: "Dani Trading And Investments Ltd." },
  { value: "Danish Joy", label: "Danish Joy" },
  { value: "Dapoli Dwarka Nagri Sah. Pat Ltd", label: "Dapoli Dwarka Nagri Sah. Pat Ltd" },
  { value: "Dapoli Urban Co-Op Bank Ltd", label: "Dapoli Urban Co-Op Bank Ltd" },
  { value: "Dapoli Urban Co-Op Bank Ltd", label: "Dapoli Urban Co-Op Bank Ltd" },
  { value: "Dar Credit And Capital Ltd", label: "Dar Credit And Capital Ltd" },
  { value: "Daram Finance Limited.", label: "Daram Finance Limited." },
  { value: "Darana Kath Nag. S.P.S Marya", label: "Darana Kath Nag. S.P.S Marya" },
  { value: "Darjeeling Dist Central Co-Op Bank Ltd.", label: "Darjeeling Dist Central Co-Op Bank Ltd." },
  { value: "Darjeeling District Central Co-Operative Bank Ltd.", label: "Darjeeling District Central Co-Operative Bank Ltd." },
  { value: "Darjeeling Urban Co Credit (Bk) Soc Ltd.", label: "Darjeeling Urban Co Credit (Bk) Soc Ltd." },
  { value: "Darodi Utkarsh Sahkari Patpedhi Parner", label: "Darodi Utkarsh Sahkari Patpedhi Parner" },
  { value: "Darpan Nagri Sahakari Pathsanstha Ltd", label: "Darpan Nagri Sahakari Pathsanstha Ltd" },
  { value: "Darshan Auto Deal", label: "Darshan Auto Deal" },
  { value: "Darshan Finance Ltd", label: "Darshan Finance Ltd" },
  { value: "Darussalam Co-Op Bank Ltd", label: "Darussalam Co-Op Bank Ltd" },
  { value: "Daryasagar Sahakari Patpedhi Maryadit", label: "Daryasagar Sahakari Patpedhi Maryadit" },
  { value: "Das Leasing And Fin Pvt Ltd", label: "Das Leasing And Fin Pvt Ltd" },
  { value: "Dasalad Co Op Bank", label: "Dasalad Co Op Bank" },
  { value: "Dasari Padmavathi", label: "Dasari Padmavathi" },
  { value: "Dashmesh Finance Ltd.", label: "Dashmesh Finance Ltd." },
  { value: "Dashmesh Financeir Moga", label: "Dashmesh Financeir Moga" },
  { value: "Dashmesh Motors Finance Co", label: "Dashmesh Motors Finance Co" },
  { value: "Dashrath Appaji Patil Nagari Sahkari Patsanstha Ltd", label: "Dashrath Appaji Patil Nagari Sahkari Patsanstha Ltd" },
  { value: "Datasoft Infotech (India ) Ltd Mumbai", label: "Datasoft Infotech (India ) Ltd Mumbai" },
  { value: "Datex Finance Co.", label: "Datex Finance Co." },
  { value: "Datla Satyanarayana Raju", label: "Datla Satyanarayana Raju" },
  { value: "Datla Satyanarayana Raju Financiers", label: "Datla Satyanarayana Raju Financiers" },
  { value: "Datta Digambar Co-Op. Credit Soc. Ltd.", label: "Datta Digambar Co-Op. Credit Soc. Ltd." },
  { value: "Datta Motor Finance", label: "Datta Motor Finance" },
  { value: "Datta Patil Urban Co Op Credit Society  Ltd", label: "Datta Patil Urban Co Op Credit Society  Ltd" },
  { value: "Datta Seva Sahakari Pattapedhi Ltd", label: "Datta Seva Sahakari Pattapedhi Ltd" },
  { value: "Dattakripa Nag Sah Pat San", label: "Dattakripa Nag Sah Pat San" },
  { value: "Dattakrupa Gramin Bigar Sheti Sah. Pat.", label: "Dattakrupa Gramin Bigar Sheti Sah. Pat." },
  { value: "Dattakrupa Nagari Sah Patsanstha", label: "Dattakrupa Nagari Sah Patsanstha" },
  { value: "Dattaram Maharaj Kalambjaoli Sahakari Bank Ltd", label: "Dattaram Maharaj Kalambjaoli Sahakari Bank Ltd" },
  { value: "Dattaray Maharaj Kalambe Jaoli Sahakari Bank Ltd", label: "Dattaray Maharaj Kalambe Jaoli Sahakari Bank Ltd" },
  { value: "Dattatray Maharaj Kalambe Jaoli Sah. Bk", label: "Dattatray Maharaj Kalambe Jaoli Sah. Bk" },
  { value: "Dattguru Co Op Credit Soc Ltd", label: "Dattguru Co Op Credit Soc Ltd" },
  { value: "Dattseva Co-Credit Society Ltd", label: "Dattseva Co-Credit Society Ltd" },
  { value: "Daulat Nagari Sahakari Patsanstha", label: "Daulat Nagari Sahakari Patsanstha" },
  { value: "Daund Nagri Sah Patsanstha Maryadit", label: "Daund Nagri Sah Patsanstha Maryadit" },
  { value: "Daund Urban Co-Op. Bank Ltd.", label: "Daund Urban Co-Op. Bank Ltd." },
  { value: "Dausa Kendriya Sahakari Bk Ltd", label: "Dausa Kendriya Sahakari Bk Ltd" },
  { value: "Dausa Kshetriya Sahakari Bank Ltd", label: "Dausa Kshetriya Sahakari Bank Ltd" },
  { value: "Dausa Urban Co Bank Ltd,", label: "Dausa Urban Co Bank Ltd," },
  { value: "Dausa Urban Co-Op Bank Ltd", label: "Dausa Urban Co-Op Bank Ltd" },
  { value: "Dausa Urban Co-Op Bank Ltd.", label: "Dausa Urban Co-Op Bank Ltd." },
  { value: "Davangere District Valmiki Co-Operative Society", label: "Davangere District Valmiki Co-Operative Society" },
  { value: "Davangere Harihar Urban Co-Op Bank Ltd", label: "Davangere Harihar Urban Co-Op Bank Ltd" },
  { value: "Davangere Urban Co-Op Bank Ltd", label: "Davangere Urban Co-Op Bank Ltd" },
  { value: "Dawoodi Bohra Jamat Anjuman - E - Hatimi", label: "Dawoodi Bohra Jamat Anjuman - E - Hatimi" },
  { value: "Dayalpur Co.Op A/M/P/S Societ Ltd", label: "Dayalpur Co.Op A/M/P/S Societ Ltd" },
  { value: "Dbs Finance Ltd", label: "Dbs Finance Ltd" },
  { value: "Dcardb Ltd. Missing", label: "Dcardb Ltd. Missing" },
  { value: "Dcb Bank Ltd", label: "Dcb Bank Ltd" },
  { value: "Dcl Finance Ltd.", label: "Dcl Finance Ltd." },
  { value: "Dcm Finance Services", label: "Dcm Finance Services" },
  { value: "De-Novo Leasing And Investments Ltd.", label: "De-Novo Leasing And Investments Ltd." },
  { value: "Deardb Ltd.", label: "Deardb Ltd." },
  { value: "Debashri Baruah", label: "Debashri Baruah" },
  { value: "Decan Merchants Co-Op Bank Ltd.", label: "Decan Merchants Co-Op Bank Ltd." },
  { value: "Deccan Co-Operative Bank", label: "Deccan Co-Operative Bank" },
  { value: "Deccan Finance Ltd.", label: "Deccan Finance Ltd." },
  { value: "Deccan Gramin Bank", label: "Deccan Gramin Bank" },
  { value: "Deccan Leafin Services Ltd.", label: "Deccan Leafin Services Ltd." },
  { value: "Deccan Mercantile Co Op Bank Ltd", label: "Deccan Mercantile Co Op Bank Ltd" },
  { value: "Deccan Urban Co-Op Bank Ltd", label: "Deccan Urban Co-Op Bank Ltd" },
  { value: "Decent Enterprise", label: "Decent Enterprise" },
  { value: "Decent Junction Service Co-Op Bank Ltd", label: "Decent Junction Service Co-Op Bank Ltd" },
  { value: "Dediyasan Industrial Co Operative Credit Society Ltd", label: "Dediyasan Industrial Co Operative Credit Society Ltd" },
  { value: "Dee. Key Finlease", label: "Dee. Key Finlease" },
  { value: "Deedi Hire Purchase And Leasing Ltd.", label: "Deedi Hire Purchase And Leasing Ltd." },
  { value: "Deelip Kumar Mukth Finance", label: "Deelip Kumar Mukth Finance" },
  { value: "Deen Dayal Nagarik Sahakari Bank Ltd", label: "Deen Dayal Nagarik Sahakari Bank Ltd" },
  { value: "Deendayal Nagari Sahakari Bank Ltd.", label: "Deendayal Nagari Sahakari Bank Ltd." },
  { value: "Deendayal Nagari Sahakari Path Sanstha Maryadit", label: "Deendayal Nagari Sahakari Path Sanstha Maryadit" },
  { value: "Deendayal Nagari Sahakari Patsanstha Myd", label: "Deendayal Nagari Sahakari Patsanstha Myd" },
  { value: "Deendayal Nagrik Sahakari Bank Mydt", label: "Deendayal Nagrik Sahakari Bank Mydt" },
  { value: "Deep  Hire Purchase Ltd", label: "Deep  Hire Purchase Ltd" },
  { value: "Deep Automobiles", label: "Deep Automobiles" },
  { value: "Deep Chand & Sons", label: "Deep Chand & Sons" },
  { value: "Deep Dhalleke Auto Financiers Pvt. Ltd", label: "Deep Dhalleke Auto Financiers Pvt. Ltd" },
  { value: "Deep Finance And Trading Co.", label: "Deep Finance And Trading Co." },
  { value: "Deep Finance Ltd.", label: "Deep Finance Ltd." },
  { value: "Deep Fincap Pvt. Ltd", label: "Deep Fincap Pvt. Ltd" },
  { value: "Deep Finvest Pvt. Ltd", label: "Deep Finvest Pvt. Ltd" },
  { value: "Deep Jyoti Corporation", label: "Deep Jyoti Corporation" },
  { value: "Deep Mala Auto Centre", label: "Deep Mala Auto Centre" },
  { value: "Deep Motor Finance Pvt. Ltd.", label: "Deep Motor Finance Pvt. Ltd." },
  { value: "Deep Nagar Primary Agriculture Credit Society Ltd.", label: "Deep Nagar Primary Agriculture Credit Society Ltd." },
  { value: "Deep Sagar Auto Deals", label: "Deep Sagar Auto Deals" },
  { value: "Deep Shikha Co. Op(U)", label: "Deep Shikha Co. Op(U)" },
  { value: "Deepa Finance Corporation", label: "Deepa Finance Corporation" },
  { value: "Deepak Auto Investments", label: "Deepak Auto Investments" },
  { value: "Deepak Bankers", label: "Deepak Bankers" },
  { value: "Deepak Deposits And Adv. Pvt. Ltd.", label: "Deepak Deposits And Adv. Pvt. Ltd." },
  { value: "Deepak Enterprises", label: "Deepak Enterprises" },
  { value: "Deepak Finance", label: "Deepak Finance" },
  { value: "Deepak Finance & Leasing Company", label: "Deepak Finance & Leasing Company" },
  { value: "Deepak Finance And Credits", label: "Deepak Finance And Credits" },
  { value: "Deepak Kumar", label: "Deepak Kumar" },
  { value: "Deepak Kumar Jain", label: "Deepak Kumar Jain" },
  { value: "Deepak Kumarr Billaiya", label: "Deepak Kumarr Billaiya" },
  { value: "Deepak S Bank Ltd.", label: "Deepak S Bank Ltd." },
  { value: "Deepak Sahakari Bank Ltd.", label: "Deepak Sahakari Bank Ltd." },
  { value: "Deepali Dhakan", label: "Deepali Dhakan" },
  { value: "Deepam Auto Finance", label: "Deepam Auto Finance" },
  { value: "Deepam Investments", label: "Deepam Investments" },
  { value: "Deepika Auto Finance", label: "Deepika Auto Finance" },
  { value: "Deepika Auto Financier", label: "Deepika Auto Financier" },
  { value: "Deepika Finance", label: "Deepika Finance" },
  { value: "Deepika Leasing And Finance Ltd.", label: "Deepika Leasing And Finance Ltd." },
  { value: "Deeplaxmi Nagari Sahakari Sanstha Maryadit", label: "Deeplaxmi Nagari Sahakari Sanstha Maryadit" },
  { value: "Deepmalika", label: "Deepmalika" },
  { value: "Deepthi Santhoshkumar", label: "Deepthi Santhoshkumar" },
  { value: "Deepti Finlease", label: "Deepti Finlease" },
  { value: "Defece Accounts Co-Op Bank Ltd", label: "Defece Accounts Co-Op Bank Ltd" },
  { value: "Defence Accounts Co-Op Bank Ltd", label: "Defence Accounts Co-Op Bank Ltd" },
  { value: "Defence Research And Development Organisation", label: "Defence Research And Development Organisation" },
  { value: "Degamber Cap Fin Ltd & Borm & Road", label: "Degamber Cap Fin Ltd & Borm & Road" },
  { value: "Delhi Co Bank Ltd", label: "Delhi Co Bank Ltd" },
  { value: "Delhi Co-Operative Society Bank Ltd", label: "Delhi Co-Operative Society Bank Ltd" },
  { value: "Delhi Metro Rail Corporation Limited", label: "Delhi Metro Rail Corporation Limited" },
  { value: "Delhi Nagarik Sah. Bank Ltd.", label: "Delhi Nagarik Sah. Bank Ltd." },
  { value: "Delhi Punjab Finance Co. Pvt. Ltd.", label: "Delhi Punjab Finance Co. Pvt. Ltd." },
  { value: "Delhi Sch Castes Fin & Dev Corpn", label: "Delhi Sch Castes Fin & Dev Corpn" },
  { value: "Delhi State Co-Op Bank Ltd.", label: "Delhi State Co-Op Bank Ltd." },
  { value: "Delhi State Taxi Operator Co-Operative Thrift Credit & Service Society Ltd", label: "Delhi State Taxi Operator Co-Operative Thrift Credit & Service Society Ltd" },
  { value: "Delhi State Taxi Operator Cooperative Theft & Credit Society", label: "Delhi State Taxi Operator Cooperative Theft & Credit Society" },
  { value: "Delhi State Taxi Operators Co.Op", label: "Delhi State Taxi Operators Co.Op" },
  { value: "Delhi Swastik Co-Op Urban Society Ltd", label: "Delhi Swastik Co-Op Urban Society Ltd" },
  { value: "Delhi Taxi Co-Oprative Society", label: "Delhi Taxi Co-Oprative Society" },
  { value: "Delhi Taxi Co-Oprative Society Bank Ltd", label: "Delhi Taxi Co-Oprative Society Bank Ltd" },
  { value: "Delhi Tourism And Transportation Development Corporation", label: "Delhi Tourism And Transportation Development Corporation" },
  { value: "Delta Finance Company", label: "Delta Finance Company" },
  { value: "Deluxe Leasing Pvt. Ltd", label: "Deluxe Leasing Pvt. Ltd" },
  { value: "Dena-Akot1", label: "Dena-Akot1" },
  { value: "Dennis M Joseph", label: "Dennis M Joseph" },
  { value: "Denso Haryana Pvt. Ltd.", label: "Denso Haryana Pvt. Ltd." },
  { value: "Deogad Urban Co-Op Bank Ltd", label: "Deogad Urban Co-Op Bank Ltd" },
  { value: "Deogaon S. C. S. Ltd Mini Bank,", label: "Deogaon S. C. S. Ltd Mini Bank," },
  { value: "Deoghar-Jamtara Cent. Co.Op. Bank Ltd.", label: "Deoghar-Jamtara Cent. Co.Op. Bank Ltd." },
  { value: "Deogiri Nagari Sahakari Bank Ltd.", label: "Deogiri Nagari Sahakari Bank Ltd." },
  { value: "Deogiri Nagarik Sahakari Bank Ltd", label: "Deogiri Nagarik Sahakari Bank Ltd" },
  { value: "Deoki Automobiles.", label: "Deoki Automobiles." },
  { value: "Deola Merchants Co-Op Bank Ltd", label: "Deola Merchants Co-Op Bank Ltd" },
  { value: "Deonandi Khore Bigar Seti Sahakari Patsanstha Maryadit", label: "Deonandi Khore Bigar Seti Sahakari Patsanstha Maryadit" },
  { value: "Deoria-Kasia Zila Sahkari Bank Ltd.", label: "Deoria-Kasia Zila Sahkari Bank Ltd." },
  { value: "Department Credit Bank Ltd.", label: "Department Credit Bank Ltd." },
  { value: "Department Of Administrative Reforms And Public Grievances", label: "Department Of Administrative Reforms And Public Grievances" },
  { value: "Department Of Agricultural Research And Education", label: "Department Of Agricultural Research And Education" },
  { value: "Department Of Agriculture And Co-Operation", label: "Department Of Agriculture And Co-Operation" },
  { value: "Department Of Animal Husbandry And Dairying", label: "Department Of Animal Husbandry And Dairying" },
  { value: "Department Of Atomic Energy", label: "Department Of Atomic Energy" },
  { value: "Department Of Bio-Technology", label: "Department Of Bio-Technology" },
  { value: "Department Of Chemicals And Petrochemicals", label: "Department Of Chemicals And Petrochemicals" },
  { value: "Department Of Coal", label: "Department Of Coal" },
  { value: "Department Of Commerce", label: "Department Of Commerce" },
  { value: "Department Of Company Affairs", label: "Department Of Company Affairs" },
  { value: "Department Of Consumer Affairs", label: "Department Of Consumer Affairs" },
  { value: "Department Of Culture", label: "Department Of Culture" },
  { value: "Department Of Economic Affairs", label: "Department Of Economic Affairs" },
  { value: "Department Of Education", label: "Department Of Education" },
  { value: "Department Of Electronics, Cochin University Of Science And Technology", label: "Department Of Electronics, Cochin University Of Science And Technology" },
  { value: "Department Of Expenditure", label: "Department Of Expenditure" },
  { value: "Department Of Explosives", label: "Department Of Explosives" },
  { value: "Department Of Family Welfare", label: "Department Of Family Welfare" },
  { value: "Department Of Fertilizers", label: "Department Of Fertilizers" },
  { value: "Department Of Food And Public Distribution", label: "Department Of Food And Public Distribution" },
  { value: "Department Of Health", label: "Department Of Health" },
  { value: "Department Of Heavy Industry", label: "Department Of Heavy Industry" },
  { value: "Department Of Home", label: "Department Of Home" },
  { value: "Department Of Indian Systems Of Medicines And Homeopathy", label: "Department Of Indian Systems Of Medicines And Homeopathy" },
  { value: "Department Of Industrial Development", label: "Department Of Industrial Development" },
  { value: "Department Of Industrial Policy And Promotion", label: "Department Of Industrial Policy And Promotion" },
  { value: "Department Of Information Technology", label: "Department Of Information Technology" },
  { value: "Department Of Internal Security", label: "Department Of Internal Security" },
  { value: "Department Of Justice", label: "Department Of Justice" },
  { value: "Department Of Legal Affairs", label: "Department Of Legal Affairs" },
  { value: "Department Of Mines", label: "Department Of Mines" },
  { value: "Department Of Ocean Development", label: "Department Of Ocean Development" },
  { value: "Department Of Official Language", label: "Department Of Official Language" },
  { value: "Department Of Personnel And Training", label: "Department Of Personnel And Training" },
  { value: "Department Of Police", label: "Department Of Police" },
  { value: "Department Of Posts", label: "Department Of Posts" },
  { value: "Department Of Programme Implementation", label: "Department Of Programme Implementation" },
  { value: "Department Of Public Enterprises", label: "Department Of Public Enterprises" },
  { value: "Department Of Revenue", label: "Department Of Revenue" },
  { value: "Department Of Science And Technology", label: "Department Of Science And Technology" },
  { value: "Department Of Scientific And Industrial Research", label: "Department Of Scientific And Industrial Research" },
  { value: "Department Of Space", label: "Department Of Space" },
  { value: "Department Of States", label: "Department Of States" },
  { value: "Department Of Statistics", label: "Department Of Statistics" },
  { value: "Department Of Supply", label: "Department Of Supply" },
  { value: "Department Of Telecommunications", label: "Department Of Telecommunications" },
  { value: "Department Of Tourism", label: "Department Of Tourism" },
  { value: "Department Of Women And Child Development", label: "Department Of Women And Child Development" },
  { value: "Deposit Insurance & Credit Guarantee Corporation", label: "Deposit Insurance & Credit Guarantee Corporation" },
  { value: "Derik Motors", label: "Derik Motors" },
  { value: "Desaiganj Nagari Co-Op Bank Maryadit", label: "Desaiganj Nagari Co-Op Bank Maryadit" },
  { value: "Deshastha Urban Co-Op Cre. Soc. Ltd.", label: "Deshastha Urban Co-Op Cre. Soc. Ltd." },
  { value: "Destimoney Enterprises Private Limited", label: "Destimoney Enterprises Private Limited" },
  { value: "Det Norske Veritas As", label: "Det Norske Veritas As" },
  { value: "Detroj Sahkari Mandli Pvt Ltd", label: "Detroj Sahkari Mandli Pvt Ltd" },
  { value: "Deulgaon Raje Vikas Society", label: "Deulgaon Raje Vikas Society" },
  { value: "Deulgaon Taluka Nag Sah Pat Sanstha", label: "Deulgaon Taluka Nag Sah Pat Sanstha" },
  { value: "Dev Finance", label: "Dev Finance" },
  { value: "Dev Finance Corporation", label: "Dev Finance Corporation" },
  { value: "Deva Varapu Nageswara Rao Automobile Financiers", label: "Deva Varapu Nageswara Rao Automobile Financiers" },
  { value: "Deval Co Op Credit Society", label: "Deval Co Op Credit Society" },
  { value: "Devar Hipparagi Co-Op Credit Society Ltd.", label: "Devar Hipparagi Co-Op Credit Society Ltd." },
  { value: "Devaraj And Sons", label: "Devaraj And Sons" },
  { value: "Devaraj Arasu Lindulida Uasgada Abirudi Nigama", label: "Devaraj Arasu Lindulida Uasgada Abirudi Nigama" },
  { value: "Devatha Finance", label: "Devatha Finance" },
  { value: "Devayani Sahakari Bank Ltd.", label: "Devayani Sahakari Bank Ltd." },
  { value: "Devda Juth Seva Sahakari Mandali Ltd.", label: "Devda Juth Seva Sahakari Mandali Ltd." },
  { value: "Development  Credit  Bank Ltd,  Mumbai", label: "Development  Credit  Bank Ltd,  Mumbai" },
  { value: "Development Co-Op Bank", label: "Development Co-Op Bank" },
  { value: "Development Co-Op. Bank Ltd., Mumbai", label: "Development Co-Op. Bank Ltd., Mumbai" },
  { value: "Development Commissioner For Iron And Steel", label: "Development Commissioner For Iron And Steel" },
  { value: "Development Cooperative Bank", label: "Development Cooperative Bank" },
  { value: "Development Credit Banck", label: "Development Credit Banck" },
  { value: "Development Credit Bank Ltd.", label: "Development Credit Bank Ltd." },
  { value: "Development Credit Bank Ltd.", label: "Development Credit Bank Ltd." },
  { value: "Development Credit Bank.", label: "Development Credit Bank." },
  { value: "Devendra Kumar Lunawat And Sons.", label: "Devendra Kumar Lunawat And Sons." },
  { value: "Devendra Sardanagri Sah. Pat Maryadit", label: "Devendra Sardanagri Sah. Pat Maryadit" },
  { value: "Devendranath Nagari Sahakari Patsaunstha Maryadit", label: "Devendranath Nagari Sahakari Patsaunstha Maryadit" },
  { value: "Deverhippargi Co-Op Credit", label: "Deverhippargi Co-Op Credit" },
  { value: "Devgad Urban Co Op Bank Ltd", label: "Devgad Urban Co Op Bank Ltd" },
  { value: "Devgan Financers", label: "Devgan Financers" },
  { value: "Devgiri Nagri Sahakari Bank", label: "Devgiri Nagri Sahakari Bank" },
  { value: "Devi Abirami Finance", label: "Devi Abirami Finance" },
  { value: "Devi Finance Syndicate", label: "Devi Finance Syndicate" },
  { value: "Devi Hire Purchase", label: "Devi Hire Purchase" },
  { value: "Devi Patan Kshetria Gramin Bank", label: "Devi Patan Kshetria Gramin Bank" },
  { value: "Devi Patan Kshetriya Gramin Bank", label: "Devi Patan Kshetriya Gramin Bank" },
  { value: "Devika Motor Finance (P) Ltd.", label: "Devika Motor Finance (P) Ltd." },
  { value: "Devika Motor Finance Co. Ltd.", label: "Devika Motor Finance Co. Ltd." },
  { value: "Devikulam Taluk Co-Operative Development Bank", label: "Devikulam Taluk Co-Operative Development Bank" },
  { value: "Devkrupa Nagari Sahari Pat", label: "Devkrupa Nagari Sahari Pat" },
  { value: "Devlali Vyapari Bank Ltd", label: "Devlali Vyapari Bank Ltd" },
  { value: "Devlopment Credits Bank Ltd", label: "Devlopment Credits Bank Ltd" },
  { value: "Devnodi Khore Gramin Bigar Sheti Sah Path Ltd", label: "Devnodi Khore Gramin Bigar Sheti Sah Path Ltd" },
  { value: "Dewan Motors Investment And Finance Ltd.", label: "Dewan Motors Investment And Finance Ltd." },
  { value: "Dewanti Enterprises Pvt. Ltd.", label: "Dewanti Enterprises Pvt. Ltd." },
  { value: "Dewas Shajapur Kshet Gramin Bank", label: "Dewas Shajapur Kshet Gramin Bank" },
  { value: "Dewas Shajapur Kshetriya Framin Bank", label: "Dewas Shajapur Kshetriya Framin Bank" },
  { value: "Dewas Shajapur Kshetriya Gramin Bank Ltd", label: "Dewas Shajapur Kshetriya Gramin Bank Ltd" },
  { value: "Dewas-Shajapur Kshetriya Gramin Bank", label: "Dewas-Shajapur Kshetriya Gramin Bank" },
  { value: "Deweshmoto - Fin Services", label: "Deweshmoto - Fin Services" },
  { value: "Dfc", label: "Dfc" },
  { value: "Dfl Finance Ltd.", label: "Dfl Finance Ltd." },
  { value: "Dhad Jhabua Gramin Bank", label: "Dhad Jhabua Gramin Bank" },
  { value: "Dhakad Agro Agency", label: "Dhakad Agro Agency" },
  { value: "Dhakadheri Co. Mini Bk. Ltd.", label: "Dhakadheri Co. Mini Bk. Ltd." },
  { value: "Dhakuria Co-Op Bank Ltd", label: "Dhakuria Co-Op Bank Ltd" },
  { value: "Dhakuria Co-Operative Bank Ltd.", label: "Dhakuria Co-Operative Bank Ltd." },
  { value: "Dhaliwal Finance Private Limited", label: "Dhaliwal Finance Private Limited" },
  { value: "Dhamija Finance And Leasing", label: "Dhamija Finance And Leasing" },
  { value: "Dhamija Finance And Leasing Ltd", label: "Dhamija Finance And Leasing Ltd" },
  { value: "Dhan Laxmi Bank Ltd", label: "Dhan Laxmi Bank Ltd" },
  { value: "Dhan Laxmi Gramin Bigar Sheti Sah Pathsanstha", label: "Dhan Laxmi Gramin Bigar Sheti Sah Pathsanstha" },
  { value: "Dhana Co-Op. Urban Bank Ltd.", label: "Dhana Co-Op. Urban Bank Ltd." },
  { value: "Dhana Lakshmi Auto Mobile Financiers", label: "Dhana Lakshmi Auto Mobile Financiers" },
  { value: "Dhana Laxmi A/F", label: "Dhana Laxmi A/F" },
  { value: "Dhana Priya Chits & Auto Finance", label: "Dhana Priya Chits & Auto Finance" },
  { value: "Dhana Sri Hire Purchase Finance", label: "Dhana Sri Hire Purchase Finance" },
  { value: "Dhanachakra Leasing And Hire Purchase Co Pvt Ltd", label: "Dhanachakra Leasing And Hire Purchase Co Pvt Ltd" },
  { value: "Dhanalakshmi Auto Finance Ltd", label: "Dhanalakshmi Auto Finance Ltd" },
  { value: "Dhanalakshmi Bank Ltd.", label: "Dhanalakshmi Bank Ltd." },
  { value: "Dhanalakshmi Syndicate", label: "Dhanalakshmi Syndicate" },
  { value: "Dhanalaskhmi Associates", label: "Dhanalaskhmi Associates" },
  { value: "Dhanalaxmi Co Op Bank", label: "Dhanalaxmi Co Op Bank" },
  { value: "Dhanalaxmi Co-Op Credit Society", label: "Dhanalaxmi Co-Op Credit Society" },
  { value: "Dhanalaxmi Hp & Finance", label: "Dhanalaxmi Hp & Finance" },
  { value: "Dhanalaxmi Motor Finance Pvt Ltd Gulbarga", label: "Dhanalaxmi Motor Finance Pvt Ltd Gulbarga" },
  { value: "Dhanalaxmi Motors Finance Pvt Ltd", label: "Dhanalaxmi Motors Finance Pvt Ltd" },
  { value: "Dhanam Investments", label: "Dhanam Investments" },
  { value: "Dhanapirya Chits & Auto Finance", label: "Dhanapirya Chits & Auto Finance" },
  { value: "Dhanashilpi Finance&Hire Purchase Pvt Ltd", label: "Dhanashilpi Finance&Hire Purchase Pvt Ltd" },
  { value: "Dhanashree Co-Op Credit Society Ltd Angol Belgaum", label: "Dhanashree Co-Op Credit Society Ltd Angol Belgaum" },
  { value: "Dhanashree Urban Co-Op Bank Ltd.", label: "Dhanashree Urban Co-Op Bank Ltd." },
  { value: "Dhanashree Urban Co-Operative Bank Ltd", label: "Dhanashree Urban Co-Operative Bank Ltd" },
  { value: "Dhanashri Bigarsheti Nagari Sahakari Patsanstha", label: "Dhanashri Bigarsheti Nagari Sahakari Patsanstha" },
  { value: "Dhanashri Mahila Gramin Biger Sheti Pat Sanstha", label: "Dhanashri Mahila Gramin Biger Sheti Pat Sanstha" },
  { value: "Dhanashri Mahila Patsanshta", label: "Dhanashri Mahila Patsanshta" },
  { value: "Dhanashri Mahila Sahakari Bank L", label: "Dhanashri Mahila Sahakari Bank L" },
  { value: "Dhanashry Bachat Dhiran Sah Mandali", label: "Dhanashry Bachat Dhiran Sah Mandali" },
  { value: "Dhanavantari Gramin Biger Sheti Sahkari Pathpeti Ltd", label: "Dhanavantari Gramin Biger Sheti Sahkari Pathpeti Ltd" },
  { value: "Dhandapani Finance", label: "Dhandapani Finance" },
  { value: "Dhandapani Finance Ltd.", label: "Dhandapani Finance Ltd." },
  { value: "Dhanera Merchantile Co-Op Bank Ltd", label: "Dhanera Merchantile Co-Op Bank Ltd" },
  { value: "Dhanesh Auto Finance", label: "Dhanesh Auto Finance" },
  { value: "Dhanesh Finance And  Leasing", label: "Dhanesh Finance And  Leasing" },
  { value: "Dhaneshwari Co-Op Cr Society Ltd", label: "Dhaneshwari Co-Op Cr Society Ltd" },
  { value: "Dhanju Finance", label: "Dhanju Finance" },
  { value: "Dhanlakshmi Associates", label: "Dhanlakshmi Associates" },
  { value: "Dhanlaxmi  Patsansta Takli Dhakeshwar", label: "Dhanlaxmi  Patsansta Takli Dhakeshwar" },
  { value: "Dhanlaxmi Auto Finance", label: "Dhanlaxmi Auto Finance" },
  { value: "Dhanlaxmi Co Op Bank", label: "Dhanlaxmi Co Op Bank" },
  { value: "Dhanlaxmi Co Op Bank Ltd", label: "Dhanlaxmi Co Op Bank Ltd" },
  { value: "Dhanlaxmi Gramin Bigar Sheti Sahakari Bank Ltd", label: "Dhanlaxmi Gramin Bigar Sheti Sahakari Bank Ltd" },
  { value: "Dhanlaxmi Gramin Bigar Sheti Sahakari Pathsanstha Ltd.", label: "Dhanlaxmi Gramin Bigar Sheti Sahakari Pathsanstha Ltd." },
  { value: "Dhanlaxmi Motors Finance Pvt Ltd", label: "Dhanlaxmi Motors Finance Pvt Ltd" },
  { value: "Dhanlaxmi Nagari Sahakari Pat Sanstha", label: "Dhanlaxmi Nagari Sahakari Pat Sanstha" },
  { value: "Dhanlaxmi Sahakari Patsanstha Maryadit", label: "Dhanlaxmi Sahakari Patsanstha Maryadit" },
  { value: "Dhanraj Bafna Finance", label: "Dhanraj Bafna Finance" },
  { value: "Dhanraj Co Op Credit Society Ltd.", label: "Dhanraj Co Op Credit Society Ltd." },
  { value: "Dhansampada Gramin Bigar Sheti Sah", label: "Dhansampada Gramin Bigar Sheti Sah" },
  { value: "Dhansampada Nag. Sah. Path. San. Maryadit", label: "Dhansampada Nag. Sah. Path. San. Maryadit" },
  { value: "Dhanshree Co-Op Cr Society Ltd", label: "Dhanshree Co-Op Cr Society Ltd" },
  { value: "Dhanshree Mahila Bank", label: "Dhanshree Mahila Bank" },
  { value: "Dhanshree Mahila G. B. S Br Machnur", label: "Dhanshree Mahila G. B. S Br Machnur" },
  { value: "Dhanshree Mahila Nagari Sahakari Pathsanstha", label: "Dhanshree Mahila Nagari Sahakari Pathsanstha" },
  { value: "Dhanshree Sec. Pvt. Ltd.", label: "Dhanshree Sec. Pvt. Ltd." },
  { value: "Dhansura Peoples Co-Op Bank Ltd", label: "Dhansura Peoples Co-Op Bank Ltd" },
  { value: "Dhanvanthiri Finance", label: "Dhanvanthiri Finance" },
  { value: "Dhanvardhini Gramin Bigar Sheti Sahakar Patpedhi Ltd.", label: "Dhanvardhini Gramin Bigar Sheti Sahakar Patpedhi Ltd." },
  { value: "Dhanvarsha Finance", label: "Dhanvarsha Finance" },
  { value: "Dhanvika Co-Op Credit Society", label: "Dhanvika Co-Op Credit Society" },
  { value: "Dhanvruddhi Nag. Sah. Pat San. Mary.", label: "Dhanvruddhi Nag. Sah. Pat San. Mary." },
  { value: "Dhanwantari Nagari Sah Pat Sanstha Maryadit", label: "Dhanwantari Nagari Sah Pat Sanstha Maryadit" },
  { value: "Dhanwardhini Gramin B Sah Pat Maryadit", label: "Dhanwardhini Gramin B Sah Pat Maryadit" },
  { value: "Dhanya Vyapara Pattina Sahakara Sangha", label: "Dhanya Vyapara Pattina Sahakara Sangha" },
  { value: "Dhanya Vyaparigala Pattina Sahakara Sangha (R)", label: "Dhanya Vyaparigala Pattina Sahakara Sangha (R)" },
  { value: "Dhapma Chand Bafina Financiers", label: "Dhapma Chand Bafina Financiers" },
  { value: "Dhar Disctrict Central Co-Op Bank Ltd", label: "Dhar Disctrict Central Co-Op Bank Ltd" },
  { value: "Dharam Chand Finance", label: "Dharam Chand Finance" },
  { value: "Dharam Chand Mehta", label: "Dharam Chand Mehta" },
  { value: "Dharam Finance", label: "Dharam Finance" },
  { value: "Dharam Trading Company", label: "Dharam Trading Company" },
  { value: "Dharamanna Fakkirappa Gadad", label: "Dharamanna Fakkirappa Gadad" },
  { value: "Dharamchand Metha", label: "Dharamchand Metha" },
  { value: "Dharampuri District Central Co-Operative Bank Ltd.", label: "Dharampuri District Central Co-Operative Bank Ltd." },
  { value: "Dharamvir Sambhaji Nagari Sah Pat", label: "Dharamvir Sambhaji Nagari Sah Pat" },
  { value: "Dharamvir Sambhaji Urban Co-Op Bank Ltd", label: "Dharamvir Sambhaji Urban Co-Op Bank Ltd" },
  { value: "Dharan Chand", label: "Dharan Chand" },
  { value: "Dharanee Auto Finance", label: "Dharanee Auto Finance" },
  { value: "Dharangadara Peoples Co-Op Bank Ltd", label: "Dharangadara Peoples Co-Op Bank Ltd" },
  { value: "Dharangadhra Peoples Co-Op Bank Ltd", label: "Dharangadhra Peoples Co-Op Bank Ltd" },
  { value: "Dharani Finance Ltd.", label: "Dharani Finance Ltd." },
  { value: "Dharati C-Op. Bank Ltd.", label: "Dharati C-Op. Bank Ltd." },
  { value: "Dhariwal Industries Ltd.", label: "Dhariwal Industries Ltd." },
  { value: "Dharmadom Service Co-Op Bank", label: "Dharmadom Service Co-Op Bank" },
  { value: "Dharmapriya Financiers Pvt Ltd", label: "Dharmapriya Financiers Pvt Ltd" },
  { value: "Dharmaveer Sadashivrao Mane Patil Gramin Biger S.S.P.M", label: "Dharmaveer Sadashivrao Mane Patil Gramin Biger S.S.P.M" },
  { value: "Dharmaveer Sambhaji Urban Co-Op Bank Ltd", label: "Dharmaveer Sambhaji Urban Co-Op Bank Ltd" },
  { value: "Dharmendra Bangar", label: "Dharmendra Bangar" },
  { value: "Dharmendra Rathore", label: "Dharmendra Rathore" },
  { value: "Dharmi Chand", label: "Dharmi Chand" },
  { value: "Dharmichand Kothari", label: "Dharmichand Kothari" },
  { value: "Dharmraj Co-Op Credit Society", label: "Dharmraj Co-Op Credit Society" },
  { value: "Dharmveer Sadashivrao Mane Patil Bigar Sheti Sah Path San.", label: "Dharmveer Sadashivrao Mane Patil Bigar Sheti Sah Path San." },
  { value: "Dharti Bank.", label: "Dharti Bank." },
  { value: "Dharti Lease Finance", label: "Dharti Lease Finance" },
  { value: "Dharwad Dist Govt Employees Co-Op Bank Ltd", label: "Dharwad Dist Govt Employees Co-Op Bank Ltd" },
  { value: "Dhauli Muhan Service Co Operative Mini Bank", label: "Dhauli Muhan Service Co Operative Mini Bank" },
  { value: "Dhaulri Enterprises- Hire Purchase", label: "Dhaulri Enterprises- Hire Purchase" },
  { value: "Dhaulri Enterprises.", label: "Dhaulri Enterprises." },
  { value: "Dhedhiya Finance & Investment Corporation", label: "Dhedhiya Finance & Investment Corporation" },
  { value: "Dheeran Finance", label: "Dheeran Finance" },
  { value: "Dhen Shree Securities P. Ltd.", label: "Dhen Shree Securities P. Ltd." },
  { value: "Dhenkanal Gramya Bank", label: "Dhenkanal Gramya Bank" },
  { value: "Dhenkanal Gramya Bank.", label: "Dhenkanal Gramya Bank." },
  { value: "Dhenkanal Urban Co-Op Bank Ltd", label: "Dhenkanal Urban Co-Op Bank Ltd" },
  { value: "Dhenoj Nagarik Sahakari Bank Ltd", label: "Dhenoj Nagarik Sahakari Bank Ltd" },
  { value: "Dheraj Motor Finance", label: "Dheraj Motor Finance" },
  { value: "Dheraj Motor Finance", label: "Dheraj Motor Finance" },
  { value: "Dheraj Traders", label: "Dheraj Traders" },
  { value: "Dhilip Gora Finance", label: "Dhilip Gora Finance" },
  { value: "Dhilip Kumar Jain", label: "Dhilip Kumar Jain" },
  { value: "Dhillan Finance", label: "Dhillan Finance" },
  { value: "Dhillon Financer", label: "Dhillon Financer" },
  { value: "Dhilp Kumar Financier", label: "Dhilp Kumar Financier" },
  { value: "Dhiran Mandali", label: "Dhiran Mandali" },
  { value: "Dhokeshwar Gramin Bigar Sheti Sahkari Pathsanstha  Ahmednagar", label: "Dhokeshwar Gramin Bigar Sheti Sahkari Pathsanstha  Ahmednagar" },
  { value: "Dholpur Sahkari Bhoomi Vikas Bank", label: "Dholpur Sahkari Bhoomi Vikas Bank" },
  { value: "Dhoramangalam Primary Agricultural Co Bk", label: "Dhoramangalam Primary Agricultural Co Bk" },
  { value: "Dhru Agency", label: "Dhru Agency" },
  { value: "Dhruv Motors Ltd", label: "Dhruv Motors Ltd" },
  { value: "Dhruv Quality Services", label: "Dhruv Quality Services" },
  { value: "Dhu Dheriya Auto Finance", label: "Dhu Dheriya Auto Finance" },
  { value: "Dhul Bank", label: "Dhul Bank" },
  { value: "Dhule & Nandurbar Jilha Sark Nok. Sah Bk", label: "Dhule & Nandurbar Jilha Sark Nok. Sah Bk" },
  { value: "Dhule District Central Co-Operative Bank Ltd.", label: "Dhule District Central Co-Operative Bank Ltd." },
  { value: "Dhule Jilla Sarkari Nokaranchi Sahakari Bank Ltd", label: "Dhule Jilla Sarkari Nokaranchi Sahakari Bank Ltd" },
  { value: "Dhule Urban Co-Op Bank Ltd", label: "Dhule Urban Co-Op Bank Ltd" },
  { value: "Dhule Urban Co-Op. Bank Ltd.", label: "Dhule Urban Co-Op. Bank Ltd." },
  { value: "Dhule Vikas Sahari Bank Ltd", label: "Dhule Vikas Sahari Bank Ltd" },
  { value: "Dhule Vikas Sahari Bank Ltd", label: "Dhule Vikas Sahari Bank Ltd" },
  { value: "Dhyansagar Pathsanstha", label: "Dhyansagar Pathsanstha" },
  { value: "Dhynashri Mahila Gramin Bigar Sheti Sah. Patsantha Mangalwedha Br.Tandor", label: "Dhynashri Mahila Gramin Bigar Sheti Sah. Patsantha Mangalwedha Br.Tandor" },
  { value: "Diamand Hiro Purchase", label: "Diamand Hiro Purchase" },
  { value: "Diamond Co Op Credit Soc", label: "Diamond Co Op Credit Soc" },
  { value: "Diamond Finance Co.", label: "Diamond Finance Co." },
  { value: "Diamond Fincap Pvt. Ltd", label: "Diamond Fincap Pvt. Ltd" },
  { value: "Diamond Jubilee Co-Op Bank Ltd.", label: "Diamond Jubilee Co-Op Bank Ltd." },
  { value: "Diamond Self Help Group", label: "Diamond Self Help Group" },
  { value: "Digambar Bagal Sah Pet", label: "Digambar Bagal Sah Pet" },
  { value: "Digambar Ragi Bagal Patsanstha Maryadit", label: "Digambar Ragi Bagal Patsanstha Maryadit" },
  { value: "Diganta Investments And Finance Co. Pvt. Ltd.", label: "Diganta Investments And Finance Co. Pvt. Ltd." },
  { value: "Dignity Motor Finance Ltd.", label: "Dignity Motor Finance Ltd." },
  { value: "Dilip Auto Financiers", label: "Dilip Auto Financiers" },
  { value: "Dilip Finance", label: "Dilip Finance" },
  { value: "Dilip Keshavlalshah", label: "Dilip Keshavlalshah" },
  { value: "Dilip Kumar & Sons", label: "Dilip Kumar & Sons" },
  { value: "Dilip Kumar And Sons Financiers - Chennai", label: "Dilip Kumar And Sons Financiers - Chennai" },
  { value: "Dilip Kumar Bagrecha", label: "Dilip Kumar Bagrecha" },
  { value: "Dilip Kumar Finance", label: "Dilip Kumar Finance" },
  { value: "Dilip Kumar Gupta H U F", label: "Dilip Kumar Gupta H U F" },
  { value: "Dilip Kumar Osiwal", label: "Dilip Kumar Osiwal" },
  { value: "Dilip Nagari Sah Pat", label: "Dilip Nagari Sah Pat" },
  { value: "Dilip Urban Co-Op Bank", label: "Dilip Urban Co-Op Bank" },
  { value: "Dilip Urban Co-Op. Bank Ltd.", label: "Dilip Urban Co-Op. Bank Ltd." },
  { value: "Dilipkumar Finance", label: "Dilipkumar Finance" },
  { value: "Dilipkumar Nahar", label: "Dilipkumar Nahar" },
  { value: "Dilkush Finance", label: "Dilkush Finance" },
  { value: "Dimco Finance Ltd.", label: "Dimco Finance Ltd." },
  { value: "Dimple Bathia Financiers", label: "Dimple Bathia Financiers" },
  { value: "Dinabandu Micro Credit Co-Operative Society Ltd.", label: "Dinabandu Micro Credit Co-Operative Society Ltd." },
  { value: "Dindigul District Central Co-Operative Bank Ltd.", label: "Dindigul District Central Co-Operative Bank Ltd." },
  { value: "Dindigul Urban Co-Op Bank Ltd", label: "Dindigul Urban Co-Op Bank Ltd" },
  { value: "Dinendra Vaish", label: "Dinendra Vaish" },
  { value: "Dinesh Auto Finance", label: "Dinesh Auto Finance" },
  { value: "Dinesh Chander", label: "Dinesh Chander" },
  { value: "Dinesh Commercial Finance Ltd.", label: "Dinesh Commercial Finance Ltd." },
  { value: "Dinesh Finance", label: "Dinesh Finance" },
  { value: "Dinesh Finance Syndicate", label: "Dinesh Finance Syndicate" },
  { value: "Dinesh Investment", label: "Dinesh Investment" },
  { value: "Dinesh Kadian", label: "Dinesh Kadian" },
  { value: "Dinesh Kumar Jain", label: "Dinesh Kumar Jain" },
  { value: "Dinesh Kumar Kothari", label: "Dinesh Kumar Kothari" },
  { value: "Dinesh Singh", label: "Dinesh Singh" },
  { value: "Dinkanal Urban Co-Op Bank Ltd", label: "Dinkanal Urban Co-Op Bank Ltd" },
  { value: "Dipaksinh M Zala", label: "Dipaksinh M Zala" },
  { value: "Dipran Finance Pvt. Ltd.", label: "Dipran Finance Pvt. Ltd." },
  { value: "Director General Of Shipping", label: "Director General Of Shipping" },
  { value: "Director, Central Board For Workers Education", label: "Director, Central Board For Workers Education" },
  { value: "Directorate General Of Civil Aviation", label: "Directorate General Of Civil Aviation" },
  { value: "Directorate General Of Employment And Training", label: "Directorate General Of Employment And Training" },
  { value: "Directorate General Of Foreign Trade", label: "Directorate General Of Foreign Trade" },
  { value: "Directorate General Of Health Services", label: "Directorate General Of Health Services" },
  { value: "Directorate General Of Supplies And Disposals", label: "Directorate General Of Supplies And Disposals" },
  { value: "Directorate General Resettlement", label: "Directorate General Resettlement" },
  { value: "Directorate General, Factory Advice Service And Labour", label: "Directorate General, Factory Advice Service And Labour" },
  { value: "Directorate Of Advertising And Visual Publicity", label: "Directorate Of Advertising And Visual Publicity" },
  { value: "Directorate Of Census Operations", label: "Directorate Of Census Operations" },
  { value: "Directorate Of Estates", label: "Directorate Of Estates" },
  { value: "Directorate Of Extension", label: "Directorate Of Extension" },
  { value: "Directorate Of Field Publicity", label: "Directorate Of Field Publicity" },
  { value: "Directorate Of Film Festivals", label: "Directorate Of Film Festivals" },
  { value: "Directorate Of Marketing And Inspection", label: "Directorate Of Marketing And Inspection" },
  { value: "Directorate Of Public Grievances", label: "Directorate Of Public Grievances" },
  { value: "Directorate Of Quality Assurance", label: "Directorate Of Quality Assurance" },
  { value: "Directorate Of Wheat Research", label: "Directorate Of Wheat Research" },
  { value: "Disani Enterprise", label: "Disani Enterprise" },
  { value: "Disaster Mitigation And Vulnerability Atlas Of India", label: "Disaster Mitigation And Vulnerability Atlas Of India" },
  { value: "Dist Co-Operative Agriculture And Rural Devlopment Bank Mydt Satna", label: "Dist Co-Operative Agriculture And Rural Devlopment Bank Mydt Satna" },
  { value: "Dist Director Karnataka Scst Developement Board", label: "Dist Director Karnataka Scst Developement Board" },
  { value: "Dist Manager Maharashtra State Nbc Finance And Development Corporation Ltd", label: "Dist Manager Maharashtra State Nbc Finance And Development Corporation Ltd" },
  { value: "Dist Scheduled  Caste Co-Op Society Ltd", label: "Dist Scheduled  Caste Co-Op Society Ltd" },
  { value: "Dist Scheduled Castes Services Co-Op Society", label: "Dist Scheduled Castes Services Co-Op Society" },
  { value: "District Central Co Op Bank Ltd", label: "District Central Co Op Bank Ltd" },
  { value: "District Co-Op Bank Ltd., Varanasi", label: "District Co-Op Bank Ltd., Varanasi" },
  { value: "District Co-Operative Bank", label: "District Co-Operative Bank" },
  { value: "District Co-Operative Bank Ltd.", label: "District Co-Operative Bank Ltd." },
  { value: "District Graduates Credits Co-0Perative Society Tumkur.", label: "District Graduates Credits Co-0Perative Society Tumkur." },
  { value: "District Managar Tahdio", label: "District Managar Tahdio" },
  { value: "District Manager C.G.", label: "District Manager C.G." },
  { value: "District Manager Orissa State Civil Supply Corporation Ltd.", label: "District Manager Orissa State Civil Supply Corporation Ltd." },
  { value: "District Manager Tahdco", label: "District Manager Tahdco" },
  { value: "District Police Credit Co Society Ltd", label: "District Police Credit Co Society Ltd" },
  { value: "District Primary Agricuture Co.Op Society", label: "District Primary Agricuture Co.Op Society" },
  { value: "Distt. Cooperative Agriculture & Rural Development Bank", label: "Distt. Cooperative Agriculture & Rural Development Bank" },
  { value: "Divas Gupta", label: "Divas Gupta" },
  { value: "Divisional Manager F D C M Ltd", label: "Divisional Manager F D C M Ltd" },
  { value: "Divya Auto Financiers", label: "Divya Auto Financiers" },
  { value: "Divya Finance", label: "Divya Finance" },
  { value: "Divya Financial Services", label: "Divya Financial Services" },
  { value: "Divya Financiers", label: "Divya Financiers" },
  { value: "Divya Jyothi Vividhuddesha Sahakari Sangha Niyamitha", label: "Divya Jyothi Vividhuddesha Sahakari Sangha Niyamitha" },
  { value: "Divyaa Investments", label: "Divyaa Investments" },
  { value: "Divyalakshmi Leasing City Gulbarga", label: "Divyalakshmi Leasing City Gulbarga" },
  { value: "Diwakar Fiannce", label: "Diwakar Fiannce" },
  { value: "Diwakar Finance", label: "Diwakar Finance" },
  { value: "Dixit Financial Associates", label: "Dixit Financial Associates" },
  { value: "Dk Finance", label: "Dk Finance" },
  { value: "Dlehi Transco Ltd", label: "Dlehi Transco Ltd" },
  { value: "Dn Finance", label: "Dn Finance" },
  { value: "Dn Yadav Auto Deals", label: "Dn Yadav Auto Deals" },
  { value: "Dnhdd Scstobc & Mino", label: "Dnhdd Scstobc & Mino" },
  { value: "Dnr Finance Ltd", label: "Dnr Finance Ltd" },
  { value: "Dnsb Ltd", label: "Dnsb Ltd" },
  { value: "Dnyandeep Co Op Credit S.L.", label: "Dnyandeep Co Op Credit S.L." },
  { value: "Dnyandeo Gramin Bigar Sheti", label: "Dnyandeo Gramin Bigar Sheti" },
  { value: "Dnyandeo Gramin Bigar Sheti Co-Op Pat", label: "Dnyandeo Gramin Bigar Sheti Co-Op Pat" },
  { value: "Dnyaneshwar Mauli Gramin Bigar Sheti Sahkari Pathsanstha", label: "Dnyaneshwar Mauli Gramin Bigar Sheti Sahkari Pathsanstha" },
  { value: "Dnyaneshwar Mauli Sahakari Patpedhi Ltd", label: "Dnyaneshwar Mauli Sahakari Patpedhi Ltd" },
  { value: "Dnyansagar Gramin Bank", label: "Dnyansagar Gramin Bank" },
  { value: "Dnyavardhini Gramin Bigar Sheti Path Sanstha Ltd", label: "Dnyavardhini Gramin Bigar Sheti Path Sanstha Ltd" },
  { value: "Dnyndeep Co Credit Soc Bank", label: "Dnyndeep Co Credit Soc Bank" },
  { value: "Dnyndeep Co Op Credit Soc Bank Ltd", label: "Dnyndeep Co Op Credit Soc Bank Ltd" },
  { value: "Doabu Financers Rege Co", label: "Doabu Financers Rege Co" },
  { value: "Dodaji Finance", label: "Dodaji Finance" },
  { value: "Dolphin Financial Services", label: "Dolphin Financial Services" },
  { value: "Dombivali Nagri Sahkari Bank Ltd", label: "Dombivali Nagri Sahkari Bank Ltd" },
  { value: "Dombivli Nag. Sah. Bank Ltd.", label: "Dombivli Nag. Sah. Bank Ltd." },
  { value: "Dombivli Nagari Sahakari Bank Ltd.", label: "Dombivli Nagari Sahakari Bank Ltd." },
  { value: "Don Motors (P) Ltd", label: "Don Motors (P) Ltd" },
  { value: "Dondaicha Nagari Sahakari Pat Sanstha", label: "Dondaicha Nagari Sahakari Pat Sanstha" },
  { value: "Donyi Polo Ashok Hotel Corporation Ltd.", label: "Donyi Polo Ashok Hotel Corporation Ltd." },
  { value: "Doon Valley Insurances", label: "Doon Valley Insurances" },
  { value: "Doon Valley Urban Co.Op. Bank Ltd.", label: "Doon Valley Urban Co.Op. Bank Ltd." },
  { value: "Doordarshan", label: "Doordarshan" },
  { value: "Doshi Finance", label: "Doshi Finance" },
  { value: "Dossa Insurance Services", label: "Dossa Insurance Services" },
  { value: "Dost Fincap India Ltd", label: "Dost Fincap India Ltd" },
  { value: "Dosthi Hire Purchase & Finance", label: "Dosthi Hire Purchase & Finance" },
  { value: "Dosti Leasing And Investment (Bom) Ltd.", label: "Dosti Leasing And Investment (Bom) Ltd." },
  { value: "Dove Finance Ltd.", label: "Dove Finance Ltd." },
  { value: "Dr Annasaheb Chougule Urban Co-Op Bank Ltd Peth Vadgaon", label: "Dr Annasaheb Chougule Urban Co-Op Bank Ltd Peth Vadgaon" },
  { value: "Dr Babasaheb  Ambedkar Pra Co Op Credit", label: "Dr Babasaheb  Ambedkar Pra Co Op Credit" },
  { value: "Dr Babasaheb Ambedkar Sah Bk L,", label: "Dr Babasaheb Ambedkar Sah Bk L," },
  { value: "Dr Babasaheb Ambedkar Sahakari Bk Ltd.", label: "Dr Babasaheb Ambedkar Sahakari Bk Ltd." },
  { value: "Dr Babasaheb Ambedkar Urban Co-Op Bank Ltd", label: "Dr Babasaheb Ambedkar Urban Co-Op Bank Ltd" },
  { value: "Dr Brothers Finance", label: "Dr Brothers Finance" },
  { value: "Dr Daulat Ahire Gramin Bigirsheti Sahakari Pathsanstha Ltd", label: "Dr Daulat Ahire Gramin Bigirsheti Sahakari Pathsanstha Ltd" },
  { value: "Dr Hedgewar Gramin Bigar Sheties Sah Pat Sanstha Mary", label: "Dr Hedgewar Gramin Bigar Sheties Sah Pat Sanstha Mary" },
  { value: "Dr Hegdewar Hagri Sahakari Pathsanstha.", label: "Dr Hegdewar Hagri Sahakari Pathsanstha." },
  { value: "Dr Jaiprakash Mundada Urban Co Op Bank Ltd.", label: "Dr Jaiprakash Mundada Urban Co Op Bank Ltd." },
  { value: "Dr Manibhai Desai Patsantha Ltd", label: "Dr Manibhai Desai Patsantha Ltd" },
  { value: "Dr P D Sahakari Bank Ltd", label: "Dr P D Sahakari Bank Ltd" },
  { value: "Dr Punjabrao Deshmukh Urban Co-Op Bank Ltd", label: "Dr Punjabrao Deshmukh Urban Co-Op Bank Ltd" },
  { value: "Dr. Ambedkar Nagarik Sahakari Bank Mydt,", label: "Dr. Ambedkar Nagarik Sahakari Bank Mydt," },
  { value: "Dr. Annasaheb Chougle Urban Co Op Bank Ltd", label: "Dr. Annasaheb Chougle Urban Co Op Bank Ltd" },
  { value: "Dr. Annasaheb Chougule Urban Coop Bank L", label: "Dr. Annasaheb Chougule Urban Coop Bank L" },
  { value: "Dr. B. R. Ambedkar Open University", label: "Dr. B. R. Ambedkar Open University" },
  { value: "Dr. B..R. Ambedkar Abiwruddi Niyam Niyamith", label: "Dr. B..R. Ambedkar Abiwruddi Niyam Niyamith" },
  { value: "Dr. B.R. Ambedkar Development Corporation", label: "Dr. B.R. Ambedkar Development Corporation" },
  { value: "Dr. Babasahab Ambedkar Urban Co-Op. Bank", label: "Dr. Babasahab Ambedkar Urban Co-Op. Bank" },
  { value: "Dr. Babasaheb Ambedkar Nagari Sah.Bk Ltd", label: "Dr. Babasaheb Ambedkar Nagari Sah.Bk Ltd" },
  { value: "Dr.Annasaheb Chougule Urban Co-Op Bank L", label: "Dr.Annasaheb Chougule Urban Co-Op Bank L" },
  { value: "Dr.Babasaheb Ambedkar Ur.Co.Op.Bank Ltd.", label: "Dr.Babasaheb Ambedkar Ur.Co.Op.Bank Ltd." },
  { value: "Dr.Br Ambedkar Development Corporation Ltd", label: "Dr.Br Ambedkar Development Corporation Ltd" },
  { value: "Dr.Jaiprakash Mundada Ur.C.P.Bk Ltd", label: "Dr.Jaiprakash Mundada Ur.C.P.Bk Ltd" },
  { value: "Dr.Patangrao Kadam Nagari S.Patasansha", label: "Dr.Patangrao Kadam Nagari S.Patasansha" },
  { value: "Dr.Shivajirao Patil Nilangekar Ur Cp B L", label: "Dr.Shivajirao Patil Nilangekar Ur Cp B L" },
  { value: "Dr.Vijayrao Borawake Nagari Sahakari Patsanstha", label: "Dr.Vijayrao Borawake Nagari Sahakari Patsanstha" },
  { value: "Draper International Venture Capital", label: "Draper International Venture Capital" },
  { value: "Drda Nagar Nigam Karoli", label: "Drda Nagar Nigam Karoli" },
  { value: "Dreams Protectors", label: "Dreams Protectors" },
  { value: "Dredging Corporation Of India Ltd.", label: "Dredging Corporation Of India Ltd." },
  { value: "Dristi Sec P Ltd", label: "Dristi Sec P Ltd" },
  { value: "Drix Auto And Business Solution Limited", label: "Drix Auto And Business Solution Limited" },
  { value: "Drix Auto Finance Ltd.", label: "Drix Auto Finance Ltd." },
  { value: "Ds & D (Mumbai)Employees Co Op Soc Ltd", label: "Ds & D (Mumbai)Employees Co Op Soc Ltd" },
  { value: "Ds Ap Transco", label: "Ds Ap Transco" },
  { value: "Dsc Motors Pvt Ltd", label: "Dsc Motors Pvt Ltd" },
  { value: "Dsk Motors Ltd.", label: "Dsk Motors Ltd." },
  { value: "Dsp Emp Co-Op Society Ltd", label: "Dsp Emp Co-Op Society Ltd" },
  { value: "Dsto Coptc And Ser. Soc. Ltd", label: "Dsto Coptc And Ser. Soc. Ltd" },
  { value: "Dtsc Service Co Op Society", label: "Dtsc Service Co Op Society" },
  { value: "Dua Motor Finance Pvt.Ltd", label: "Dua Motor Finance Pvt.Ltd" },
  { value: "Ducon Technologies (I) Pvt Ltd", label: "Ducon Technologies (I) Pvt Ltd" },
  { value: "Dudh Sahakari Bank Ltd", label: "Dudh Sahakari Bank Ltd" },
  { value: "Dudhaganga Gramin Bigar Sheti Patsanstha", label: "Dudhaganga Gramin Bigar Sheti Patsanstha" },
  { value: "Dudhaganga Vedganga Khore Nagri Sahkari Patsanstha Maryadit", label: "Dudhaganga Vedganga Khore Nagri Sahkari Patsanstha Maryadit" },
  { value: "Dudheriya Auto Finance", label: "Dudheriya Auto Finance" },
  { value: "Dudheriya Finance", label: "Dudheriya Finance" },
  { value: "Dudheswar Nagarik Sahakari Patsanstha Ltd", label: "Dudheswar Nagarik Sahakari Patsanstha Ltd" },
  { value: "Dugar Enterprises", label: "Dugar Enterprises" },
  { value: "Dugar Finance & Investments Ltd", label: "Dugar Finance & Investments Ltd" },
  { value: "Dugar Finance & Investments Ltd & Sbi", label: "Dugar Finance & Investments Ltd & Sbi" },
  { value: "Dugar Investments", label: "Dugar Investments" },
  { value: "Dugar Motor Finance", label: "Dugar Motor Finance" },
  { value: "Dugar Motors", label: "Dugar Motors" },
  { value: "Dugda Pax", label: "Dugda Pax" },
  { value: "Duheriya Finance", label: "Duheriya Finance" },
  { value: "Duli Chand Baghmar", label: "Duli Chand Baghmar" },
  { value: "Dulichand Chatajer", label: "Dulichand Chatajer" },
  { value: "Dulichand Chhajer", label: "Dulichand Chhajer" },
  { value: "Dulichand Financier & Leasing Ltd.", label: "Dulichand Financier & Leasing Ltd." },
  { value: "Dulichand Financier & Leasing Ltd.", label: "Dulichand Financier & Leasing Ltd." },
  { value: "Dummy", label: "Dummy" },
  { value: "Dungarka Seva Sahakari Mandali Ltd", label: "Dungarka Seva Sahakari Mandali Ltd" },
  { value: "Dungarpur Banswara Kshetriya Gramin Bank", label: "Dungarpur Banswara Kshetriya Gramin Bank" },
  { value: "Dungarpur District Central Co-Operative Bank Ltd.", label: "Dungarpur District Central Co-Operative Bank Ltd." },
  { value: "Dungarwal And Co Automative Financers", label: "Dungarwal And Co Automative Financers" },
  { value: "Dungarwal Finance Corporation", label: "Dungarwal Finance Corporation" },
  { value: "Dunhill Co Op Credit Bank", label: "Dunhill Co Op Credit Bank" },
  { value: "Durbar Trading Co.Opp Society Ltd", label: "Durbar Trading Co.Opp Society Ltd" },
  { value: "Durg Rajnandgaon Gramin Bank", label: "Durg Rajnandgaon Gramin Bank" },
  { value: "Durg-Rajnandgaon Grameena Bank", label: "Durg-Rajnandgaon Grameena Bank" },
  { value: "Durga Auto Finance", label: "Durga Auto Finance" },
  { value: "Durga Co Operative Credit Soc Ltd", label: "Durga Co Operative Credit Soc Ltd" },
  { value: "Durga Eicher", label: "Durga Eicher" },
  { value: "Durga Ganapathy Auto Finance", label: "Durga Ganapathy Auto Finance" },
  { value: "Durga Leasing And Finance", label: "Durga Leasing And Finance" },
  { value: "Durga Motor And General Finance Co.", label: "Durga Motor And General Finance Co." },
  { value: "Durga Motor Finance", label: "Durga Motor Finance" },
  { value: "Durga Nagari Sahkari Pat Sanstha Ltd", label: "Durga Nagari Sahkari Pat Sanstha Ltd" },
  { value: "Durgamata Co-Op Credit Society Ltd.", label: "Durgamata Co-Op Credit Society Ltd." },
  { value: "Durgapur Mahila Co-Op. Bank Ltd", label: "Durgapur Mahila Co-Op. Bank Ltd" },
  { value: "Durgapur Steel Peoples Co.Op. Bank Ltd.", label: "Durgapur Steel Peoples Co.Op. Bank Ltd." },
  { value: "Durgapur Steel Peoploes Co-Op Bank Ltd", label: "Durgapur Steel Peoploes Co-Op Bank Ltd" },
  { value: "Durgar Finance & Investment Ltd", label: "Durgar Finance & Investment Ltd" },
  { value: "Durgesh Trade Credit Pvt Ltd", label: "Durgesh Trade Credit Pvt Ltd" },
  { value: "Durnawadi Bank", label: "Durnawadi Bank" },
  { value: "Dutta Financiers India Ltd", label: "Dutta Financiers India Ltd" },
  { value: "Duzzan Auto Finance Pvt Ltd", label: "Duzzan Auto Finance Pvt Ltd" },
  { value: "Dwaraka Credit Co-Operative Society", label: "Dwaraka Credit Co-Operative Society" },
  { value: "Dwaraka Soudarda Credit Sahakari Ltd - Ankola", label: "Dwaraka Soudarda Credit Sahakari Ltd - Ankola" },
  { value: "Dwarika Dhish Motor Finance Pvt Ltd.", label: "Dwarika Dhish Motor Finance Pvt Ltd." },
  { value: "Dwarkadas Mantri Bank,", label: "Dwarkadas Mantri Bank," },
  { value: "Dwarkadas Mantri Nagari Sahakari Bank Lt", label: "Dwarkadas Mantri Nagari Sahakari Bank Lt" },
  { value: "Dwarkadas Mantri Nagarik Sahakaribank Ltd", label: "Dwarkadas Mantri Nagarik Sahakaribank Ltd" },
  { value: "Dwarkaprasad H.A.N.Sah Sangh Ltd.", label: "Dwarkaprasad H.A.N.Sah Sangh Ltd." },
  { value: "Dyandeep Co-Op Credit Soc. Ltd.", label: "Dyandeep Co-Op Credit Soc. Ltd." },
  { value: "Dynswardhini Gramin Bigar Sheti Sah Pathsanstha", label: "Dynswardhini Gramin Bigar Sheti Sah Pathsanstha" },
  { value: "Dystar India Pvt Ltd", label: "Dystar India Pvt Ltd" },
  { value: "E-D Bank Hyderpora", label: "E-D Bank Hyderpora" },
  { value: "E.D.D.A.L", label: "E.D.D.A.L" },
  { value: "Eagle Hire Purchase", label: "Eagle Hire Purchase" },
  { value: "East Co - Operate Bank", label: "East Co - Operate Bank" },
  { value: "East Coast Trade Fin Ltd", label: "East Coast Trade Fin Ltd" },
  { value: "East Coast Trading Ltd", label: "East Coast Trading Ltd" },
  { value: "East Cost Tradefi Ltd", label: "East Cost Tradefi Ltd" },
  { value: "East India Portfolios Ltd.", label: "East India Portfolios Ltd." },
  { value: "East India Securities Ltd.", label: "East India Securities Ltd." },
  { value: "Eastern Auto Pvt. Ltd.", label: "Eastern Auto Pvt. Ltd." },
  { value: "Eastern Coalfields Ltd.", label: "Eastern Coalfields Ltd." },
  { value: "Easwarar Finance", label: "Easwarar Finance" },
  { value: "Easy Fleet Solution ( I ) Pvt Ltd", label: "Easy Fleet Solution ( I ) Pvt Ltd" },
  { value: "Easy Hire Purchase", label: "Easy Hire Purchase" },
  { value: "Easy Way Solutions", label: "Easy Way Solutions" },
  { value: "Eazhumalaiyan Credits", label: "Eazhumalaiyan Credits" },
  { value: "Eazhumalayan Finance", label: "Eazhumalayan Finance" },
  { value: "Eco Securities & Credits (P) Ltd.", label: "Eco Securities & Credits (P) Ltd." },
  { value: "Eco Star Securities & Credits P. L", label: "Eco Star Securities & Credits P. L" },
  { value: "Ecomark General Finance&Leasing", label: "Ecomark General Finance&Leasing" },
  { value: "Economic Development Corporation Bank Ltd", label: "Economic Development Corporation Bank Ltd" },
  { value: "Economic Development Corporation Of Dadra And Nagar Havelli Limited", label: "Economic Development Corporation Of Dadra And Nagar Havelli Limited" },
  { value: "Economic Development Corporation Of Daman And Diu Limited", label: "Economic Development Corporation Of Daman And Diu Limited" },
  { value: "Economic Development Corporation Of Goa Limited", label: "Economic Development Corporation Of Goa Limited" },
  { value: "Ed Dsccs Ltd", label: "Ed Dsccs Ltd" },
  { value: "Edacochi Co-Operative Society Ltd,", label: "Edacochi Co-Operative Society Ltd," },
  { value: "Edakkadappan Service Co-Op Bank Ltd.", label: "Edakkadappan Service Co-Op Bank Ltd." },
  { value: "Edakkattuvayal Ser Co-Op Bank Ltd,", label: "Edakkattuvayal Ser Co-Op Bank Ltd," },
  { value: "Edakkatuvayal Ser Co-Op Bk, Arakkunnam", label: "Edakkatuvayal Ser Co-Op Bk, Arakkunnam" },
  { value: "Edapally Service Co-Op Bank Ltd.", label: "Edapally Service Co-Op Bank Ltd." },
  { value: "Edappal Panchayath Ser Co-Op Bk. Ltd.", label: "Edappal Panchayath Ser Co-Op Bk. Ltd." },
  { value: "Edappally Vadakkumbagom Sr Coop Bank Ltd", label: "Edappally Vadakkumbagom Sr Coop Bank Ltd" },
  { value: "Edarikode Ser. Co-Op. Bk.L.", label: "Edarikode Ser. Co-Op. Bk.L." },
  { value: "Edathala Ser Co-Op Bank Ltd,", label: "Edathala Ser Co-Op Bank Ltd," },
  { value: "Edathala Service Co-Op Bank Ltd", label: "Edathala Service Co-Op Bank Ltd" },
  { value: "Edathirinji Service Co-Operative Bank Lt", label: "Edathirinji Service Co-Operative Bank Lt" },
  { value: "Edayannur Vanitha Co-Operative Society", label: "Edayannur Vanitha Co-Operative Society" },
  { value: "Edb Nai Basti Jammu", label: "Edb Nai Basti Jammu" },
  { value: "Edelweiss Ins. Brokers Ltd.", label: "Edelweiss Ins. Brokers Ltd." },
  { value: "Edinburge Trading Co Pvt Ltd", label: "Edinburge Trading Co Pvt Ltd" },
  { value: "Educational Consultants (India) Ltd.", label: "Educational Consultants (India) Ltd." },
  { value: "Educational Media Research Center", label: "Educational Media Research Center" },
  { value: "Eew Ltd", label: "Eew Ltd" },
  { value: "Ef Insurance Broking", label: "Ef Insurance Broking" },
  { value: "Eg Finance", label: "Eg Finance" },
  { value: "Eicher Motors", label: "Eicher Motors" },
  { value: "Eistiam Finance", label: "Eistiam Finance" },
  { value: "Ek Jot Advances Ltd", label: "Ek Jot Advances Ltd" },
  { value: "Ekm Dist Police Credit Co-Op Society Ltd", label: "Ekm Dist Police Credit Co-Op Society Ltd" },
  { value: "Ekm Dist Police Credit Co-Operative Bank", label: "Ekm Dist Police Credit Co-Operative Bank" },
  { value: "Ekta", label: "Ekta" },
  { value: "Ekta Co-Op Credit Society", label: "Ekta Co-Op Credit Society" },
  { value: "Ekta Finance Co. Regd", label: "Ekta Finance Co. Regd" },
  { value: "Ekta Sahakari Pathpedhi", label: "Ekta Sahakari Pathpedhi" },
  { value: "Ektha Finance", label: "Ektha Finance" },
  { value: "Ekveera Nagari Sahakari Pat Sanstha Ltd", label: "Ekveera Nagari Sahakari Pat Sanstha Ltd" },
  { value: "Elahi Saving Investments", label: "Elahi Saving Investments" },
  { value: "Elahi Union Co-Operative Society Ltd", label: "Elahi Union Co-Operative Society Ltd" },
  { value: "Elamadu Service Co.Op. Bk Ltd.", label: "Elamadu Service Co.Op. Bk Ltd." },
  { value: "Elampal Services Coop Bank Ltd.", label: "Elampal Services Coop Bank Ltd." },
  { value: "Elayoor Service Co-Op Bank Ltd", label: "Elayoor Service Co-Op Bank Ltd" },
  { value: "Elcon Finlease And Industries Ltd.", label: "Elcon Finlease And Industries Ltd." },
  { value: "Election Commission Of India", label: "Election Commission Of India" },
  { value: "Electronic Corporation Of India Limited", label: "Electronic Corporation Of India Limited" },
  { value: "Electronica Leasing And Finance Limited", label: "Electronica Leasing And Finance Limited" },
  { value: "Electronics And Computer Software Export Promotion Council", label: "Electronics And Computer Software Export Promotion Council" },
  { value: "Electronics Research And Development Center Of India", label: "Electronics Research And Development Center Of India" },
  { value: "Electronics Trade And Technology Dev. Corporation Ltd.", label: "Electronics Trade And Technology Dev. Corporation Ltd." },
  { value: "Elegant Finvest Pvt Ltd.", label: "Elegant Finvest Pvt Ltd." },
  { value: "Elenthikara Vanitha Co-Op. Society Bank", label: "Elenthikara Vanitha Co-Op. Society Bank" },
  { value: "Elgin Mills Co. Ltd.", label: "Elgin Mills Co. Ltd." },
  { value: "Elias K.V", label: "Elias K.V" },
  { value: "Elite Leasing Ltd", label: "Elite Leasing Ltd" },
  { value: "Ellahi Dehati Bank", label: "Ellahi Dehati Bank" },
  { value: "Ellisbridge Co-Op Bank Ltd", label: "Ellisbridge Co-Op Bank Ltd" },
  { value: "Eloor Service Co-Op Bank Ltd.", label: "Eloor Service Co-Op Bank Ltd." },
  { value: "Elumaliyan Finance", label: "Elumaliyan Finance" },
  { value: "Elumathur Primary Agricultural Co-Op Bk", label: "Elumathur Primary Agricultural Co-Op Bk" },
  { value: "Eluru District Central Co-Operative Bank Ltd.", label: "Eluru District Central Co-Operative Bank Ltd." },
  { value: "Em Pee Motor Ltd", label: "Em Pee Motor Ltd" },
  { value: "Emeskay Financial Services Ltd", label: "Emeskay Financial Services Ltd" },
  { value: "Empire Motors Pvt Ltd", label: "Empire Motors Pvt Ltd" },
  { value: "Empire Securities And Capital Ltd.", label: "Empire Securities And Capital Ltd." },
  { value: "Enam Insurance Broker Pvt Ltd", label: "Enam Insurance Broker Pvt Ltd" },
  { value: "Endeavour Insurance Broking Pvt Ltd", label: "Endeavour Insurance Broking Pvt Ltd" },
  { value: "Energy Development Agency", label: "Energy Development Agency" },
  { value: "Engineering Projects (India) Ltd.", label: "Engineering Projects (India) Ltd." },
  { value: "Engineers India Ltd.", label: "Engineers India Ltd." },
  { value: "Enn Financial Services Ltd", label: "Enn Financial Services Ltd" },
  { value: "Ennem Motor Credit (P)Ltd", label: "Ennem Motor Credit (P)Ltd" },
  { value: "Ennoble  Leasing ( India) Limited", label: "Ennoble  Leasing ( India) Limited" },
  { value: "Ennoble India Savings And Investment Co. Ltd.", label: "Ennoble India Savings And Investment Co. Ltd." },
  { value: "Ennovative Finanz", label: "Ennovative Finanz" },
  { value: "Ensure Financial Services", label: "Ensure Financial Services" },
  { value: "Entero Finance", label: "Entero Finance" },
  { value: "Entrust Overseas Pvt Ltd", label: "Entrust Overseas Pvt Ltd" },
  { value: "Eqitas Micro Finance Pvt. Ltd.", label: "Eqitas Micro Finance Pvt. Ltd." },
  { value: "Equitas Finance Pvt Ltd", label: "Equitas Finance Pvt Ltd" },
  { value: "Equitas Finance Pvt. Ltd.", label: "Equitas Finance Pvt. Ltd." },
  { value: "Erandaiyravilagam Primary Agri Cp Bk Ltd", label: "Erandaiyravilagam Primary Agri Cp Bk Ltd" },
  { value: "Erattupetta Service Coop Bank Ltd", label: "Erattupetta Service Coop Bank Ltd" },
  { value: "Eriyad Services Co-Operative Bank Ltd", label: "Eriyad Services Co-Operative Bank Ltd" },
  { value: "Ernakulam District Central Co-Operative Bank Ltd.", label: "Ernakulam District Central Co-Operative Bank Ltd." },
  { value: "Ernakulam District Police Credit Co-Op Society", label: "Ernakulam District Police Credit Co-Op Society" },
  { value: "Ernet India Society", label: "Ernet India Society" },
  { value: "Erode Abirami Finance", label: "Erode Abirami Finance" },
  { value: "Erode Devi Abiraami Finance", label: "Erode Devi Abiraami Finance" },
  { value: "Erode District Central Co-Operative Bank Ltd.", label: "Erode District Central Co-Operative Bank Ltd." },
  { value: "Eruvessy Service Co.Op Bank Ltd", label: "Eruvessy Service Co.Op Bank Ltd" },
  { value: "Esanda Finanz And Leasing Limited", label: "Esanda Finanz And Leasing Limited" },
  { value: "Esbee Growth Funds Pvt Ltd", label: "Esbee Growth Funds Pvt Ltd" },
  { value: "Escorts Consumer Financial Credit Ltd", label: "Escorts Consumer Financial Credit Ltd" },
  { value: "Escorts Consumers Financial", label: "Escorts Consumers Financial" },
  { value: "Escorts Dealer Development Association", label: "Escorts Dealer Development Association" },
  { value: "Escorts Employees Welfare Ltd", label: "Escorts Employees Welfare Ltd" },
  { value: "Escorts Finance Limited", label: "Escorts Finance Limited" },
  { value: "Eshwari Enterprises", label: "Eshwari Enterprises" },
  { value: "Esn Finance And Capital Services Limited", label: "Esn Finance And Capital Services Limited" },
  { value: "Espee Finsec Ltd", label: "Espee Finsec Ltd" },
  { value: "Ess Aay Investment Co. Ltd.", label: "Ess Aay Investment Co. Ltd." },
  { value: "Ess Bee Growth Funds Pvt Ltd.", label: "Ess Bee Growth Funds Pvt Ltd." },
  { value: "Ess Ess Preet Fin Pvt Ltd.", label: "Ess Ess Preet Fin Pvt Ltd." },
  { value: "Ess Gee Financing Co. (P) Ltd.", label: "Ess Gee Financing Co. (P) Ltd." },
  { value: "Ess Jay Enterprises", label: "Ess Jay Enterprises" },
  { value: "Ess Kay Auto Finance Pvt Ltd", label: "Ess Kay Auto Finance Pvt Ltd" },
  { value: "Essanpi Auto Mobiles", label: "Essanpi Auto Mobiles" },
  { value: "Essco Finance", label: "Essco Finance" },
  { value: "Estar Industries Ltd", label: "Estar Industries Ltd" },
  { value: "Esteem Credit And Investment", label: "Esteem Credit And Investment" },
  { value: "Esteem Merchent Ltd.", label: "Esteem Merchent Ltd." },
  { value: "Esvar Finance", label: "Esvar Finance" },
  { value: "Esvee Financers Ltd", label: "Esvee Financers Ltd" },
  { value: "Eswara Finance", label: "Eswara Finance" },
  { value: "Eswarar Corporation", label: "Eswarar Corporation" },
  { value: "Eswarar Finance", label: "Eswarar Finance" },
  { value: "Eswarar Leasing & Finance", label: "Eswarar Leasing & Finance" },
  { value: "Eswari Auto Finance", label: "Eswari Auto Finance" },
  { value: "Etah Dist Co-Op Bank Ltd", label: "Etah Dist Co-Op Bank Ltd" },
  { value: "Etah Urban Co-Operative Bank Ltd.", label: "Etah Urban Co-Operative Bank Ltd." },
  { value: "Etah Zila Sahkari Bank,", label: "Etah Zila Sahkari Bank," },
  { value: "Etawah Chetriya Gramin Bank Ltd", label: "Etawah Chetriya Gramin Bank Ltd" },
  { value: "Etawah District Co-Op Bank Ltd", label: "Etawah District Co-Op Bank Ltd" },
  { value: "Etawah District Co-Op Bank Ltd,", label: "Etawah District Co-Op Bank Ltd," },
  { value: "Etawah Distrinet Coaprative Bank Ltd", label: "Etawah Distrinet Coaprative Bank Ltd" },
  { value: "Etawah Urban Co-Op Bank Ltd", label: "Etawah Urban Co-Op Bank Ltd" },
  { value: "Etawah Urban Co-Op. Bank Ltd.", label: "Etawah Urban Co-Op. Bank Ltd." },
  { value: "Ever Green Motors", label: "Ever Green Motors" },
  { value: "Ever Trust Finance (India) Ltd.", label: "Ever Trust Finance (India) Ltd." },
  { value: "Everest Finance", label: "Everest Finance" },
  { value: "Everglow Investments (P) Ltd.", label: "Everglow Investments (P) Ltd." },
  { value: "Evergreen Fin Lease And Estates Ltd", label: "Evergreen Fin Lease And Estates Ltd" },
  { value: "Ewart Investments Ltd.", label: "Ewart Investments Ltd." },
  { value: "Ex Servicemen Financiers", label: "Ex Servicemen Financiers" },
  { value: "Ex- Servicemen Hire Purchase Finance Pvt Ltd", label: "Ex- Servicemen Hire Purchase Finance Pvt Ltd" },
  { value: "Excell Investment", label: "Excell Investment" },
  { value: "Excellent Agro Product Pvt Ltd.  Delhi", label: "Excellent Agro Product Pvt Ltd.  Delhi" },
  { value: "Excellent Co-Op Bank Ltd", label: "Excellent Co-Op Bank Ltd" },
  { value: "Excellent Co-Op.Bank Ltd", label: "Excellent Co-Op.Bank Ltd" },
  { value: "Excellent Fin - Invest Ltd", label: "Excellent Fin - Invest Ltd" },
  { value: "Excellent Finance Invest Ltd", label: "Excellent Finance Invest Ltd" },
  { value: "Excellent Hire Purchasing Co", label: "Excellent Hire Purchasing Co" },
  { value: "Excise Customs Off Ltd", label: "Excise Customs Off Ltd" },
  { value: "Excutive Director Co-Op Society Ltd", label: "Excutive Director Co-Op Society Ltd" },
  { value: "Executive Director Dist Co Op Services Ltd", label: "Executive Director Dist Co Op Services Ltd" },
  { value: "Executive Director Mdscscs Ltd", label: "Executive Director Mdscscs Ltd" },
  { value: "Executive Eng Pgvcl Contraction Division", label: "Executive Eng Pgvcl Contraction Division" },
  { value: "Exim Bank Of India", label: "Exim Bank Of India" },
  { value: "Export - Import Bank Of India", label: "Export - Import Bank Of India" },
  { value: "Export Credit Guarantee Crop. Of India Ltd.", label: "Export Credit Guarantee Crop. Of India Ltd." },
  { value: "Export Processing Zone", label: "Export Processing Zone" },
  { value: "Export Promotion Council For Handicrafts", label: "Export Promotion Council For Handicrafts" },
  { value: "F K G B", label: "F K G B" },
  { value: "F M Finanace", label: "F M Finanace" },
  { value: "F.I.C Co Ltd", label: "F.I.C Co Ltd" },
  { value: "F.I.C.Ltd", label: "F.I.C.Ltd" },
  { value: "F.K.G.B", label: "F.K.G.B" },
  { value: "F.M. Enterprises", label: "F.M. Enterprises" },
  { value: "F.M. Finance", label: "F.M. Finance" },
  { value: "F.N.S Shree Co Ltd", label: "F.N.S Shree Co Ltd" },
  { value: "Facilitation Centre For Industrial Plasma Technologies", label: "Facilitation Centre For Industrial Plasma Technologies" },
  { value: "Faddang Seva Sahakari Mandli Ltd", label: "Faddang Seva Sahakari Mandli Ltd" },
  { value: "Fair Deal Finance Co.", label: "Fair Deal Finance Co." },
  { value: "Fair Deal Finance Co.(Regd.)", label: "Fair Deal Finance Co.(Regd.)" },
  { value: "Fair Deal Finance Company Ltd", label: "Fair Deal Finance Company Ltd" },
  { value: "Fair Deal Gen. Finance Pvt. Ltd", label: "Fair Deal Gen. Finance Pvt. Ltd" },
  { value: "Fair Deal Ins. Brokers Pvt. Ltd.", label: "Fair Deal Ins. Brokers Pvt. Ltd." },
  { value: "Fair Wealth Securities Pvt Ltd", label: "Fair Wealth Securities Pvt Ltd" },
  { value: "Fairdeal Finance Corporation", label: "Fairdeal Finance Corporation" },
  { value: "Fairdeal Leasing Ltd", label: "Fairdeal Leasing Ltd" },
  { value: "Fairdill Finance Corpo Sneh Nagar", label: "Fairdill Finance Corpo Sneh Nagar" },
  { value: "Fairwell Finance Ltd", label: "Fairwell Finance Ltd" },
  { value: "Faizabad District Sahkari Bank Ltd.", label: "Faizabad District Sahkari Bank Ltd." },
  { value: "Faizabad Kshetriya Gramin Bank", label: "Faizabad Kshetriya Gramin Bank" },
  { value: "Faizabad Sahakari Zila Bank Ltd", label: "Faizabad Sahakari Zila Bank Ltd" },
  { value: "Fallerton India Credit Co Ltd.", label: "Fallerton India Credit Co Ltd." },
  { value: "Family Credit", label: "Family Credit" },
  { value: "Family Credit Bank", label: "Family Credit Bank" },
  { value: "Family Credit Corporation", label: "Family Credit Corporation" },
  { value: "Family Credit Finance", label: "Family Credit Finance" },
  { value: "Family Credits", label: "Family Credits" },
  { value: "Famous Auto Financier", label: "Famous Auto Financier" },
  { value: "Fancy Financiers", label: "Fancy Financiers" },
  { value: "Fantasy Hire Purchase & Investments", label: "Fantasy Hire Purchase & Investments" },
  { value: "Fapdi Sahakar Niyamith Bank", label: "Fapdi Sahakar Niyamith Bank" },
  { value: "Farakka Credit Co-Op Society Ltd", label: "Farakka Credit Co-Op Society Ltd" },
  { value: "Fare Deal Finance Co.(Regd)", label: "Fare Deal Finance Co.(Regd)" },
  { value: "Faridabad District Central Co-Operative Bank Ltd.", label: "Faridabad District Central Co-Operative Bank Ltd." },
  { value: "Faridkot Bathinda Kshetriya Gramin Bank", label: "Faridkot Bathinda Kshetriya Gramin Bank" },
  { value: "Faridkot District Central Co-Operative Bank Ltd.", label: "Faridkot District Central Co-Operative Bank Ltd." },
  { value: "Faridkot-Bathinda Kshetriya Gramin Bank.", label: "Faridkot-Bathinda Kshetriya Gramin Bank." },
  { value: "Farmers Ser.Co-Op. Bank Ltd.", label: "Farmers Ser.Co-Op. Bank Ltd." },
  { value: "Farmers Co-Op Bank Of India Ltd", label: "Farmers Co-Op Bank Of India Ltd" },
  { value: "Farrukhabad Gramin Bank.", label: "Farrukhabad Gramin Bank." },
  { value: "Farry Industries Ltd", label: "Farry Industries Ltd" },
  { value: "Fastfin Leasing & Credit Pvt Ltd", label: "Fastfin Leasing & Credit Pvt Ltd" },
  { value: "Fatehgarh District Central Co-Operative Bank Ltd.", label: "Fatehgarh District Central Co-Operative Bank Ltd." },
  { value: "Fatehgarh Sahib Co-Op Bank Ltd", label: "Fatehgarh Sahib Co-Op Bank Ltd" },
  { value: "Fatehpur Disr Co-Op Bank L", label: "Fatehpur Disr Co-Op Bank L" },
  { value: "Fatehpur Dist Co-Op Bank L", label: "Fatehpur Dist Co-Op Bank L" },
  { value: "Fatehpur Dist Co-Op Bank Ltd,", label: "Fatehpur Dist Co-Op Bank Ltd," },
  { value: "Fatimanagar Co-Op. Bank Ltd", label: "Fatimanagar Co-Op. Bank Ltd" },
  { value: "Fazilka District Central Co-Operative Bank Ltd.", label: "Fazilka District Central Co-Operative Bank Ltd." },
  { value: "Fcm Travel Solutions", label: "Fcm Travel Solutions" },
  { value: "Fcm Travel Solutions", label: "Fcm Travel Solutions" },
  { value: "Fear Dill Finance Corporation Indore", label: "Fear Dill Finance Corporation Indore" },
  { value: "Feardill Finance Corporation", label: "Feardill Finance Corporation" },
  { value: "Fedbank Financial Services Ltd", label: "Fedbank Financial Services Ltd" },
  { value: "Federal Bank Ltd", label: "Federal Bank Ltd" },
  { value: "Federation Of Indian Export Organisations", label: "Federation Of Indian Export Organisations" },
  { value: "Fedral Bank Ltd.", label: "Fedral Bank Ltd." },
  { value: "Feroke Co-Op Urban Bank Ltd", label: "Feroke Co-Op Urban Bank Ltd" },
  { value: "Feroke Co-Op. Urban Bank Ltd", label: "Feroke Co-Op. Urban Bank Ltd" },
  { value: "Ferozepur District Central Co-Operative Bank Ltd.", label: "Ferozepur District Central Co-Operative Bank Ltd." },
  { value: "Ferro Scrap Nigam Limited", label: "Ferro Scrap Nigam Limited" },
  { value: "Fertilisers And Chemicals Travancore Limited", label: "Fertilisers And Chemicals Travancore Limited" },
  { value: "Fertilizers Crop. Of India Ltd.", label: "Fertilizers Crop. Of India Ltd." },
  { value: "Fetish Impex Pvt Ltd", label: "Fetish Impex Pvt Ltd" },
  { value: "Fiat India Ltd.", label: "Fiat India Ltd." },
  { value: "Fiat Sundram Auto Finance Ltd", label: "Fiat Sundram Auto Finance Ltd" },
  { value: "Films Division", label: "Films Division" },
  { value: "Fin Corp Ltd", label: "Fin Corp Ltd" },
  { value: "Finance Co.", label: "Finance Co." },
  { value: "Finance House (India) Pvt. Ltd.", label: "Finance House (India) Pvt. Ltd." },
  { value: "Finance The People Co Op Credit Society Ltd", label: "Finance The People Co Op Credit Society Ltd" },
  { value: "Financer Tata Motor Ltd", label: "Financer Tata Motor Ltd" },
  { value: "Financial Co-Operative Bank Ltd., Surat", label: "Financial Co-Operative Bank Ltd., Surat" },
  { value: "Financial Details Not Available In Rpas(Chief Workshop Manager Loce Works Southen Railway", label: "Financial Details Not Available In Rpas(Chief Workshop Manager Loce Works Southen Railway" },
  { value: "Financial Enterprise", label: "Financial Enterprise" },
  { value: "Financial Service Team", label: "Financial Service Team" },
  { value: "Financial Services Ltd", label: "Financial Services Ltd" },
  { value: "Financier Kunal Kumar Nahar", label: "Financier Kunal Kumar Nahar" },
  { value: "Fincap Financial Corporation Limited", label: "Fincap Financial Corporation Limited" },
  { value: "Fine Auto Leasing And Finance Pvt Ltd", label: "Fine Auto Leasing And Finance Pvt Ltd" },
  { value: "Fine Dining (India) Pvt. Ltd.", label: "Fine Dining (India) Pvt. Ltd." },
  { value: "Fine Leasing & Finance", label: "Fine Leasing & Finance" },
  { value: "Fine Leasing Finance Services", label: "Fine Leasing Finance Services" },
  { value: "Finsere Sales And Services Ltd", label: "Finsere Sales And Services Ltd" },
  { value: "Firozabad District Coop Bk Ltd.", label: "Firozabad District Coop Bk Ltd." },
  { value: "Firozabad District Sahkari Bank Ltd", label: "Firozabad District Sahkari Bank Ltd" },
  { value: "Firozabad Jila Sahakari Bank Ltd.", label: "Firozabad Jila Sahakari Bank Ltd." },
  { value: "First Choice", label: "First Choice" },
  { value: "First Choice Financial Services P Ltd", label: "First Choice Financial Services P Ltd" },
  { value: "First Leasing Company Of India Ltd.", label: "First Leasing Company Of India Ltd." },
  { value: "Five Star Business Credits Ltd.", label: "Five Star Business Credits Ltd." },
  { value: "Five Star Credit", label: "Five Star Credit" },
  { value: "Flutron India", label: "Flutron India" },
  { value: "Focus Brand Trading India", label: "Focus Brand Trading India" },
  { value: "Focuz On Life", label: "Focuz On Life" },
  { value: "Food Corporation Of India", label: "Food Corporation Of India" },
  { value: "Footwear Design And Development Institute", label: "Footwear Design And Development Institute" },
  { value: "For Pratik Overseas Corporation", label: "For Pratik Overseas Corporation" },
  { value: "Forbes Campbell Holdings Ltd", label: "Forbes Campbell Holdings Ltd" },
  { value: "Ford Credit India", label: "Ford Credit India" },
  { value: "Ford Credit Kotak Mahindra Ltd", label: "Ford Credit Kotak Mahindra Ltd" },
  { value: "Ford Credit Kotak Mahindra Ltd.", label: "Ford Credit Kotak Mahindra Ltd." },
  { value: "Ford Credit Kotak Mahindra Prime Ltd", label: "Ford Credit Kotak Mahindra Prime Ltd" },
  { value: "Ford India Ltd.", label: "Ford India Ltd." },
  { value: "Ford India Pvt Ltd-Ford Credit", label: "Ford India Pvt Ltd-Ford Credit" },
  { value: "Foreign Employment And Manpower Export Bureau", label: "Foreign Employment And Manpower Export Bureau" },
  { value: "Forest Development Corporation", label: "Forest Development Corporation" },
  { value: "Fortis Financial Services Ltd.", label: "Fortis Financial Services Ltd." },
  { value: "Fortress Financial Services Limited.", label: "Fortress Financial Services Limited." },
  { value: "Fortune Auto And General Solutions", label: "Fortune Auto And General Solutions" },
  { value: "Fortune Portfolio Pvt Ltd", label: "Fortune Portfolio Pvt Ltd" },
  { value: "Forward Securities Ltd.", label: "Forward Securities Ltd." },
  { value: "Four Square Credits Ltd", label: "Four Square Credits Ltd" },
  { value: "Foursquare Leasing And Finance Ltd.", label: "Foursquare Leasing And Finance Ltd." },
  { value: "Fpi-Padmakshi Insurance Broker Private Limited", label: "Fpi-Padmakshi Insurance Broker Private Limited" },
  { value: "Fram Godawan Motrs Fzr", label: "Fram Godawan Motrs Fzr" },
  { value: "Francis Zeviers Gramin Big. Sheti Sah. Path San.", label: "Francis Zeviers Gramin Big. Sheti Sah. Path San." },
  { value: "Franciscan Sisters Trust For Education", label: "Franciscan Sisters Trust For Education" },
  { value: "Fraternal Credit And Financial Services Ltd", label: "Fraternal Credit And Financial Services Ltd" },
  { value: "Friend Finance", label: "Friend Finance" },
  { value: "Friends Auto Marketing Center", label: "Friends Auto Marketing Center" },
  { value: "Friends Automobiles", label: "Friends Automobiles" },
  { value: "Friends Co Op Credit Society Ltd", label: "Friends Co Op Credit Society Ltd" },
  { value: "Friends Co-Op Bank Ltd", label: "Friends Co-Op Bank Ltd" },
  { value: "Friends Hire Purchase", label: "Friends Hire Purchase" },
  { value: "Frito Lay India Ltd.", label: "Frito Lay India Ltd." },
  { value: "Fst Finance", label: "Fst Finance" },
  { value: "Fuchs Lubricants India Pvt Ltd", label: "Fuchs Lubricants India Pvt Ltd" },
  { value: "Fullerton  Finance Ltd", label: "Fullerton  Finance Ltd" },
  { value: "Fullerton India", label: "Fullerton India" },
  { value: "Fullerton India Credit Finance Ltd", label: "Fullerton India Credit Finance Ltd" },
  { value: "Fullerton India Credit Ltd", label: "Fullerton India Credit Ltd" },
  { value: "Fullerton India Credit Nco Ltd", label: "Fullerton India Credit Nco Ltd" },
  { value: "Fullerton India Finance", label: "Fullerton India Finance" },
  { value: "Fullerton India Ltd.", label: "Fullerton India Ltd." },
  { value: "Fullerton India Pvt Ltd", label: "Fullerton India Pvt Ltd" },
  { value: "Fulletron", label: "Fulletron" },
  { value: "Fundamental Finvest Pvt Ltd", label: "Fundamental Finvest Pvt Ltd" },
  { value: "Fundamental Finvest Pvt Ltd  Ghaziabad", label: "Fundamental Finvest Pvt Ltd  Ghaziabad" },
  { value: "Fusion Micro Finance (P) Ltd", label: "Fusion Micro Finance (P) Ltd" },
  { value: "Futura Portfulio Cansaltant (P) Ltd", label: "Futura Portfulio Cansaltant (P) Ltd" },
  { value: "Future Capital Financial Ser Ltd", label: "Future Capital Financial Ser Ltd" },
  { value: "Future Finance Ltd", label: "Future Finance Ltd" },
  { value: "G & R Enterprises", label: "G & R Enterprises" },
  { value: "G A C Finance", label: "G A C Finance" },
  { value: "G Amit Kankaria", label: "G Amit Kankaria" },
  { value: "G Amit Kankaria Finace", label: "G Amit Kankaria Finace" },
  { value: "G B Finance Ltd", label: "G B Finance Ltd" },
  { value: "G B R Enterprises", label: "G B R Enterprises" },
  { value: "G B R Finvest Pvt Ltd", label: "G B R Finvest Pvt Ltd" },
  { value: "G B R Motors", label: "G B R Motors" },
  { value: "G C Commercial", label: "G C Commercial" },
  { value: "G C Finance Corp", label: "G C Finance Corp" },
  { value: "G C Karunawal", label: "G C Karunawal" },
  { value: "G D Finance", label: "G D Finance" },
  { value: "G D Financers Pvt Ltd", label: "G D Financers Pvt Ltd" },
  { value: "G D R Finance", label: "G D R Finance" },
  { value: "G Dharamchand Jain Auto Financier", label: "G Dharamchand Jain Auto Financier" },
  { value: "G Dinesh Kumar Jain", label: "G Dinesh Kumar Jain" },
  { value: "G E  Capital Tfs Ltd", label: "G E  Capital Tfs Ltd" },
  { value: "G E Capital Tfc Ltd", label: "G E Capital Tfc Ltd" },
  { value: "G E Capital Tfs Capital Ltd.", label: "G E Capital Tfs Capital Ltd." },
  { value: "G E Capital Tsr", label: "G E Capital Tsr" },
  { value: "G E Captial Tfs Ltd", label: "G E Captial Tfs Ltd" },
  { value: "G E Countrywide Consumer Finance Service Ltd", label: "G E Countrywide Consumer Finance Service Ltd" },
  { value: "G E Countrywide F S Ltd", label: "G E Countrywide F S Ltd" },
  { value: "G E Countywide Ltd", label: "G E Countywide Ltd" },
  { value: "G E Financial", label: "G E Financial" },
  { value: "G E Mec", label: "G E Mec" },
  { value: "G E Money", label: "G E Money" },
  { value: "G G B Indri.", label: "G G B Indri." },
  { value: "G G B Pataudi", label: "G G B Pataudi" },
  { value: "G G Credits", label: "G G Credits" },
  { value: "G G Nahar And Co Automobile Financier", label: "G G Nahar And Co Automobile Financier" },
  { value: "G Grace Leasing Co. Pvt Ltd", label: "G Grace Leasing Co. Pvt Ltd" },
  { value: "G H Enterprises", label: "G H Enterprises" },
  { value: "G International", label: "G International" },
  { value: "G J Investment", label: "G J Investment" },
  { value: "G K Chaandhok Finance", label: "G K Chaandhok Finance" },
  { value: "G K Garg", label: "G K Garg" },
  { value: "G K Investment", label: "G K Investment" },
  { value: "G Kamal Chand", label: "G Kamal Chand" },
  { value: "G Kiran Kumari", label: "G Kiran Kumari" },
  { value: "G Kuldeep Kumar Jain", label: "G Kuldeep Kumar Jain" },
  { value: "G M Souharda Pattina Sahakari Niyamitha", label: "G M Souharda Pattina Sahakari Niyamitha" },
  { value: "G Mac Financial Services India Ltd", label: "G Mac Financial Services India Ltd" },
  { value: "G Mac Financial Services Ltd", label: "G Mac Financial Services Ltd" },
  { value: "G Max Finance Ltd", label: "G Max Finance Ltd" },
  { value: "G Neck Financial Services India Ltd", label: "G Neck Financial Services India Ltd" },
  { value: "G Padmavathi Automobile Financiers", label: "G Padmavathi Automobile Financiers" },
  { value: "G R Finance", label: "G R Finance" },
  { value: "G R Sudhindra", label: "G R Sudhindra" },
  { value: "G S Finance Co.", label: "G S Finance Co." },
  { value: "G Srinivas Reddy Automobile Financiers", label: "G Srinivas Reddy Automobile Financiers" },
  { value: "G Srinivasa Reddy Auto Finance", label: "G Srinivasa Reddy Auto Finance" },
  { value: "G Suresh Kumar Jain Automobile Finance", label: "G Suresh Kumar Jain Automobile Finance" },
  { value: "G Tansukh Chand", label: "G Tansukh Chand" },
  { value: "G Thansukh Chand Bandari", label: "G Thansukh Chand Bandari" },
  { value: "G Tonsukh Chand", label: "G Tonsukh Chand" },
  { value: "G. C. Auto Co.", label: "G. C. Auto Co." },
  { value: "G. D. Betala Finance Pvt Ltd", label: "G. D. Betala Finance Pvt Ltd" },
  { value: "G. D. Finance", label: "G. D. Finance" },
  { value: "G. E. Auto Co.", label: "G. E. Auto Co." },
  { value: "G. E. Commercial", label: "G. E. Commercial" },
  { value: "G. E. Countrywide", label: "G. E. Countrywide" },
  { value: "G. K. Trading Company", label: "G. K. Trading Company" },
  { value: "G. Kushal Kumar Auto Financier", label: "G. Kushal Kumar Auto Financier" },
  { value: "G. Leela Bai", label: "G. Leela Bai" },
  { value: "G. S. Investment", label: "G. S. Investment" },
  { value: "G. S. Society Ltd.", label: "G. S. Society Ltd." },
  { value: "G. Vardhaman Finance", label: "G. Vardhaman Finance" },
  { value: "G.A.S. Traders", label: "G.A.S. Traders" },
  { value: "G.Ajithraj", label: "G.Ajithraj" },
  { value: "G.B Nahar Finance", label: "G.B Nahar Finance" },
  { value: "G.C Finance", label: "G.C Finance" },
  { value: "G.C.F.S Ltd", label: "G.C.F.S Ltd" },
  { value: "G.Commercial", label: "G.Commercial" },
  { value: "G.Dharamchand Jain,Auto Financier", label: "G.Dharamchand Jain,Auto Financier" },
  { value: "G.E Financial Service Pvt. Ltd", label: "G.E Financial Service Pvt. Ltd" },
  { value: "G.E. Capital Tfs Ltd.", label: "G.E. Capital Tfs Ltd." },
  { value: "G.E. Captial Ltd", label: "G.E. Captial Ltd" },
  { value: "G.E. Money", label: "G.E. Money" },
  { value: "G.E. Money Financial Services Ltd", label: "G.E. Money Financial Services Ltd" },
  { value: "G.E.B Wtps", label: "G.E.B Wtps" },
  { value: "G.E.Capital Transportation", label: "G.E.Capital Transportation" },
  { value: "G.E.Financial Services India Ltd", label: "G.E.Financial Services India Ltd" },
  { value: "G.E.Money Financial Sarivices Pvt. L.T.D", label: "G.E.Money Financial Sarivices Pvt. L.T.D" },
  { value: "G.F.S.C.S", label: "G.F.S.C.S" },
  { value: "G.K. Trading Co.", label: "G.K. Trading Co." },
  { value: "G.K. Trading Company", label: "G.K. Trading Company" },
  { value: "G.K.S.G.B", label: "G.K.S.G.B" },
  { value: "G.M India Ltd.", label: "G.M India Ltd." },
  { value: "G.M.A.L. Financial Service India Ltd", label: "G.M.A.L. Financial Service India Ltd" },
  { value: "G.M.P.S.S. Ni", label: "G.M.P.S.S. Ni" },
  { value: "G.M.T.D.", label: "G.M.T.D." },
  { value: "G.N.B.Cl", label: "G.N.B.Cl" },
  { value: "G.Navneeth Kankaria", label: "G.Navneeth Kankaria" },
  { value: "G.P.Auto Finance", label: "G.P.Auto Finance" },
  { value: "G.R.S. Finance", label: "G.R.S. Finance" },
  { value: "G.S Auto Sales", label: "G.S Auto Sales" },
  { value: "G.S. Auto Sales", label: "G.S. Auto Sales" },
  { value: "G.S. Auto Sales", label: "G.S. Auto Sales" },
  { value: "G.S.F.C. Fiber Unit", label: "G.S.F.C. Fiber Unit" },
  { value: "G.S.S.S. Co-Operative Society", label: "G.S.S.S. Co-Operative Society" },
  { value: "G.Thanrusha Chand", label: "G.Thanrusha Chand" },
  { value: "G.Thansukh Chand", label: "G.Thansukh Chand" },
  { value: "Gaathre Auto Finance", label: "Gaathre Auto Finance" },
  { value: "Gaba Motors", label: "Gaba Motors" },
  { value: "Gadchiroli District Central Co-Operative Bank Ltd.", label: "Gadchiroli District Central Co-Operative Bank Ltd." },
  { value: "Gadchiroli Nagarik Sahakari Bank Maryadit", label: "Gadchiroli Nagarik Sahakari Bank Maryadit" },
  { value: "Gaddam Naveen", label: "Gaddam Naveen" },
  { value: "Gadha Group Seva Sahkari Mandali & Sabarkantha Bank Ltd", label: "Gadha Group Seva Sahkari Mandali & Sabarkantha Bank Ltd" },
  { value: "Gadhinglaj Urban Co-Op. Bank Ltd.", label: "Gadhinglaj Urban Co-Op. Bank Ltd." },
  { value: "Gadhinglaj Vyapari Nagari Sah Path. S. Myd.", label: "Gadhinglaj Vyapari Nagari Sah Path. S. Myd." },
  { value: "Gadhka Juth Seva Sahakari Mandali", label: "Gadhka Juth Seva Sahakari Mandali" },
  { value: "Gagan Fin Corp", label: "Gagan Fin Corp" },
  { value: "Gagan Trading Company", label: "Gagan Trading Company" },
  { value: "Gaihar Auto Finance", label: "Gaihar Auto Finance" },
  { value: "Gail India Ltd", label: "Gail India Ltd" },
  { value: "Gajanan Co-Op Credit Society Ltd", label: "Gajanan Co-Op Credit Society Ltd" },
  { value: "Gajanan Gr Path Sanstha Maryadit", label: "Gajanan Gr Path Sanstha Maryadit" },
  { value: "Gajanan Maharaj Gramin Bigar Sah Pat", label: "Gajanan Maharaj Gramin Bigar Sah Pat" },
  { value: "Gajanan Mhetre Nagari Sah Pat San Mar", label: "Gajanan Mhetre Nagari Sah Pat San Mar" },
  { value: "Gajanan Nag Sah Pat Mar", label: "Gajanan Nag Sah Pat Mar" },
  { value: "Gajanan Nagarik Sahakari Bank Ltd", label: "Gajanan Nagarik Sahakari Bank Ltd" },
  { value: "Gajanan Sahkari Padpethi Ltd", label: "Gajanan Sahkari Padpethi Ltd" },
  { value: "Gajanan Urban Co Op Bank Ltd", label: "Gajanan Urban Co Op Bank Ltd" },
  { value: "Gajendra Finance", label: "Gajendra Finance" },
  { value: "Gajendran Finance", label: "Gajendran Finance" },
  { value: "Gajet Financial Ser(P) Ltd", label: "Gajet Financial Ser(P) Ltd" },
  { value: "Gajraj Motor & Gen Fin.", label: "Gajraj Motor & Gen Fin." },
  { value: "Galada Finance Ltd.", label: "Galada Finance Ltd." },
  { value: "Galaxy Co Op Bank Ltd", label: "Galaxy Co Op Bank Ltd" },
  { value: "Galhotra Finance Co.", label: "Galhotra Finance Co." },
  { value: "Gambhir Leasing & Finance Pvt Ltd", label: "Gambhir Leasing & Finance Pvt Ltd" },
  { value: "Gambit Leasing And Fin P Ltd", label: "Gambit Leasing And Fin P Ltd" },
  { value: "Gamc Financial Serv India Ltd", label: "Gamc Financial Serv India Ltd" },
  { value: "Gamc Financial Services India Ltd", label: "Gamc Financial Services India Ltd" },
  { value: "Ganapathi Auto Finance", label: "Ganapathi Auto Finance" },
  { value: "Ganapathi Urban Co-Operative Bank Ltd.", label: "Ganapathi Urban Co-Operative Bank Ltd." },
  { value: "Ganapathy Co-Op Agricultural Bank L", label: "Ganapathy Co-Op Agricultural Bank L" },
  { value: "Ganapathy Finance", label: "Ganapathy Finance" },
  { value: "Ganapati Hp Co.", label: "Ganapati Hp Co." },
  { value: "Ganbhir Higher Purchase Pvt Ltd", label: "Ganbhir Higher Purchase Pvt Ltd" },
  { value: "Gandevi Peoples Co-Op Bank Ltd", label: "Gandevi Peoples Co-Op Bank Ltd" },
  { value: "Gandhi And Company", label: "Gandhi And Company" },
  { value: "Gandhi Auto Mobile", label: "Gandhi Auto Mobile" },
  { value: "Gandhi Co-Op Urban Bank Ltd", label: "Gandhi Co-Op Urban Bank Ltd" },
  { value: "Gandhi Enterprises", label: "Gandhi Enterprises" },
  { value: "Gandhi Gunj Co-Operative Bank Ltd.", label: "Gandhi Gunj Co-Operative Bank Ltd." },
  { value: "Gandhi Gunj Primary Co-Op Bank Ltd", label: "Gandhi Gunj Primary Co-Op Bank Ltd" },
  { value: "Gandhi Service Co. Bank Ltd.", label: "Gandhi Service Co. Bank Ltd." },
  { value: "Gandhi Smarak S.C.O.B.L", label: "Gandhi Smarak S.C.O.B.L" },
  { value: "Gandhibag Sahakari Bank Ltd.", label: "Gandhibag Sahakari Bank Ltd." },
  { value: "Gandhibag Sahakari Bank Ltd., Nagpur", label: "Gandhibag Sahakari Bank Ltd., Nagpur" },
  { value: "Gandhidham Co Op Bank", label: "Gandhidham Co Op Bank" },
  { value: "Gandhidham Merchantileco-Op Bank Ltd", label: "Gandhidham Merchantileco-Op Bank Ltd" },
  { value: "Gandhigram Rural Institute", label: "Gandhigram Rural Institute" },
  { value: "Gandhinagar Co Op Bank", label: "Gandhinagar Co Op Bank" },
  { value: "Gandhinagar Nagarik Co-Op Bank Ltd", label: "Gandhinagar Nagarik Co-Op Bank Ltd" },
  { value: "Gandhinagar Nagrik Co-Op Bank Ltd", label: "Gandhinagar Nagrik Co-Op Bank Ltd" },
  { value: "Gandhinagar Nagrik Sahakari Bank", label: "Gandhinagar Nagrik Sahakari Bank" },
  { value: "Gandhinglaj Vypari Nagari Sahakari Patsanstha", label: "Gandhinglaj Vypari Nagari Sahakari Patsanstha" },
  { value: "Ganesh Auto Finance", label: "Ganesh Auto Finance" },
  { value: "Ganesh Auto Point", label: "Ganesh Auto Point" },
  { value: "Ganesh Bank Of Kurundwad Ltd., Pune", label: "Ganesh Bank Of Kurundwad Ltd., Pune" },
  { value: "Ganesh Capfin Ltd", label: "Ganesh Capfin Ltd" },
  { value: "Ganesh Finance Corporation", label: "Ganesh Finance Corporation" },
  { value: "Ganesh Gramin Bigar Sheti Sahkari Patsanstha", label: "Ganesh Gramin Bigar Sheti Sahkari Patsanstha" },
  { value: "Ganesh Investments", label: "Ganesh Investments" },
  { value: "Ganesh Kumar Sancheti", label: "Ganesh Kumar Sancheti" },
  { value: "Ganesh Motor Finance", label: "Ganesh Motor Finance" },
  { value: "Ganesh Murugan Auto Finance", label: "Ganesh Murugan Auto Finance" },
  { value: "Ganesh Nagarik Path Sanstha", label: "Ganesh Nagarik Path Sanstha" },
  { value: "Ganesh Ratna N.S.P Br Sangola", label: "Ganesh Ratna N.S.P Br Sangola" },
  { value: "Ganesh Sahakari Bank Ltd", label: "Ganesh Sahakari Bank Ltd" },
  { value: "Ganesh Swami Samarth Sah Patsanstha Ltd", label: "Ganesh Swami Samarth Sah Patsanstha Ltd" },
  { value: "Ganesh Syndicate", label: "Ganesh Syndicate" },
  { value: "Ganesh Vyapari Gramin Bigar Sheti Sahakari Pathsanstha Maryadit", label: "Ganesh Vyapari Gramin Bigar Sheti Sahakari Pathsanstha Maryadit" },
  { value: "Ganga Auto Finance", label: "Ganga Auto Finance" },
  { value: "Ganga Capitals", label: "Ganga Capitals" },
  { value: "Ganga Co-Op Credit Societies Ltd", label: "Ganga Co-Op Credit Societies Ltd" },
  { value: "Ganga General Finance Ltd.", label: "Ganga General Finance Ltd." },
  { value: "Ganga Hire Purchase", label: "Ganga Hire Purchase" },
  { value: "Ganga Trading Co", label: "Ganga Trading Co" },
  { value: "Ganga Yamuna Gramin Bank", label: "Ganga Yamuna Gramin Bank" },
  { value: "Ganga Yamuna Gramin Bank", label: "Ganga Yamuna Gramin Bank" },
  { value: "Gangadhar Lease Finance", label: "Gangadhar Lease Finance" },
  { value: "Ganganagar District Central Co-Operative Bank Ltd.", label: "Ganganagar District Central Co-Operative Bank Ltd." },
  { value: "Ganganagar Financiers", label: "Ganganagar Financiers" },
  { value: "Ganganagar Keshtriya Gramin Bank Ltd,", label: "Ganganagar Keshtriya Gramin Bank Ltd," },
  { value: "Gangavathi Patina Sahakara Bank", label: "Gangavathi Patina Sahakara Bank" },
  { value: "Gangoh Leasing & Finance Pvt Ltd", label: "Gangoh Leasing & Finance Pvt Ltd" },
  { value: "Gangothri Finance", label: "Gangothri Finance" },
  { value: "Gangothri Souharda Credit Co Op Bank Ltd.", label: "Gangothri Souharda Credit Co Op Bank Ltd." },
  { value: "Gangothri Sowharata Credit Co-Op Ltd", label: "Gangothri Sowharata Credit Co-Op Ltd" },
  { value: "Gangotri Nagari Sahkari Patsanstha, Nashik", label: "Gangotri Nagari Sahkari Patsanstha, Nashik" },
  { value: "Ganguli Town Souharda Co-Op L", label: "Ganguli Town Souharda Co-Op L" },
  { value: "Ganpat Lal Agarwal", label: "Ganpat Lal Agarwal" },
  { value: "Ganpati Finance Co.", label: "Ganpati Finance Co." },
  { value: "Ganpati Nagri Sahakari Pathsanstha Ltd", label: "Ganpati Nagri Sahakari Pathsanstha Ltd" },
  { value: "Ganrai Nagri Sahakari Bank Ltd", label: "Ganrai Nagri Sahakari Bank Ltd" },
  { value: "Ganraj Nagri Sah. Bank Ltd", label: "Ganraj Nagri Sah. Bank Ltd" },
  { value: "Gantantra B Bafna (Huf)", label: "Gantantra B Bafna (Huf)" },
  { value: "Garamin Bank Chirra", label: "Garamin Bank Chirra" },
  { value: "Garden Reach Ship Builders And Engineers Ltd.", label: "Garden Reach Ship Builders And Engineers Ltd." },
  { value: "Gareshwargramin Biger Shati Sahkari", label: "Gareshwargramin Biger Shati Sahkari" },
  { value: "Garg Enterprises", label: "Garg Enterprises" },
  { value: "Garg Fin Services", label: "Garg Fin Services" },
  { value: "Garg Finance Co Ltd.", label: "Garg Finance Co Ltd." },
  { value: "Garg Motor Finance Co", label: "Garg Motor Finance Co" },
  { value: "Garh Pratapnagar Ghatal Nischindipur Skus Ltdvv", label: "Garh Pratapnagar Ghatal Nischindipur Skus Ltdvv" },
  { value: "Garha Co-Op Bank Ltd", label: "Garha Co-Op Bank Ltd" },
  { value: "Garha Co.Operative Bank Ltd.", label: "Garha Co.Operative Bank Ltd." },
  { value: "Garhwal Co-Operative Bank Ltd.", label: "Garhwal Co-Operative Bank Ltd." },
  { value: "Garima Enterprise", label: "Garima Enterprise" },
  { value: "Garnet Finance Ltd.", label: "Garnet Finance Ltd." },
  { value: "Gas Authority Of India Limited", label: "Gas Authority Of India Limited" },
  { value: "Gas Authority Of India Ltd.", label: "Gas Authority Of India Ltd." },
  { value: "Gate Way Auto Finance", label: "Gate Way Auto Finance" },
  { value: "Gatesh Swami Samarth Sah Patsantha Ltd", label: "Gatesh Swami Samarth Sah Patsantha Ltd" },
  { value: "Gateway Auto Finance", label: "Gateway Auto Finance" },
  { value: "Gateway Motor Finance", label: "Gateway Motor Finance" },
  { value: "Gati Finance India Ltd", label: "Gati Finance India Ltd" },
  { value: "Gauhati Co-Operative Urban Bank Ltd,", label: "Gauhati Co-Operative Urban Bank Ltd," },
  { value: "Gaurav", label: "Gaurav" },
  { value: "Gaurav Auto Finance", label: "Gaurav Auto Finance" },
  { value: "Gaurav Automobiles", label: "Gaurav Automobiles" },
  { value: "Gaurav Dugar", label: "Gaurav Dugar" },
  { value: "Gaurav Enterprise", label: "Gaurav Enterprise" },
  { value: "Gaurav Finance Co", label: "Gaurav Finance Co" },
  { value: "Gaurav Investment", label: "Gaurav Investment" },
  { value: "Gaurdian Souharda Sahakari Bank", label: "Gaurdian Souharda Sahakari Bank" },
  { value: "Gautam & Conageur", label: "Gautam & Conageur" },
  { value: "Gautam Auto Finance Corporation", label: "Gautam Auto Finance Corporation" },
  { value: "Gautam Auto Finance Ltd.", label: "Gautam Auto Finance Ltd." },
  { value: "Gautam Chand Betala Huf", label: "Gautam Chand Betala Huf" },
  { value: "Gautam Chand Jain", label: "Gautam Chand Jain" },
  { value: "Gautam Chand Kankarta", label: "Gautam Chand Kankarta" },
  { value: "Gautam Chand Surana & Son", label: "Gautam Chand Surana & Son" },
  { value: "Gautam Finance Co", label: "Gautam Finance Co" },
  { value: "Gautam Investment Co.", label: "Gautam Investment Co." },
  { value: "Gautam Motors", label: "Gautam Motors" },
  { value: "Gautam Motors Limited", label: "Gautam Motors Limited" },
  { value: "Gautamchand Lalwani & Sons", label: "Gautamchand Lalwani & Sons" },
  { value: "Gautamchand Nemichand And Co.", label: "Gautamchand Nemichand And Co." },
  { value: "Gautham & Co Chennai", label: "Gautham & Co Chennai" },
  { value: "Gautham Chand Suran", label: "Gautham Chand Suran" },
  { value: "Gautham Investments", label: "Gautham Investments" },
  { value: "Gautham Ji Karanawat", label: "Gautham Ji Karanawat" },
  { value: "Gautum Finance Co", label: "Gautum Finance Co" },
  { value: "Gawalinagar Nagri Sah. Pat. Marya.", label: "Gawalinagar Nagri Sah. Pat. Marya." },
  { value: "Gaya Prasad Hiralal Ltd Allahabad Bank", label: "Gaya Prasad Hiralal Ltd Allahabad Bank" },
  { value: "Gaya Prasad Hiralal/Bank Of India", label: "Gaya Prasad Hiralal/Bank Of India" },
  { value: "Gayaprasad Hiralal Ltd", label: "Gayaprasad Hiralal Ltd" },
  { value: "Gayathri Associates", label: "Gayathri Associates" },
  { value: "Gayathri Credit Co Op Society Ltd.", label: "Gayathri Credit Co Op Society Ltd." },
  { value: "Gayatri Co Op Urban Bank Ltd", label: "Gayatri Co Op Urban Bank Ltd" },
  { value: "Gayatri Co-Op Bank Ltd.", label: "Gayatri Co-Op Bank Ltd." },
  { value: "Gayatri Finance", label: "Gayatri Finance" },
  { value: "Gayatri Finance Ltd Karur", label: "Gayatri Finance Ltd Karur" },
  { value: "Gayatri Finance Registered", label: "Gayatri Finance Registered" },
  { value: "Gayatri Finance Service Ltd", label: "Gayatri Finance Service Ltd" },
  { value: "Gayatri Pattina Sahakari Sangh Niyamit", label: "Gayatri Pattina Sahakari Sangh Niyamit" },
  { value: "Gayatri Ur Cr Co-Op Bank Ltd", label: "Gayatri Ur Cr Co-Op Bank Ltd" },
  { value: "Gaylord Saving", label: "Gaylord Saving" },
  { value: "Gazai Investment And Hire", label: "Gazai Investment And Hire" },
  { value: "Gazal Finance Co Ltd.", label: "Gazal Finance Co Ltd." },
  { value: "Gazipur Urban Co-Op Bank Ltd", label: "Gazipur Urban Co-Op Bank Ltd" },
  { value: "Gb Finance", label: "Gb Finance" },
  { value: "Gbr Finvest Pvt Ltd", label: "Gbr Finvest Pvt Ltd" },
  { value: "Gbs Finance", label: "Gbs Finance" },
  { value: "Gdcc Bank", label: "Gdcc Bank" },
  { value: "Gdm Finance", label: "Gdm Finance" },
  { value: "Ge Capital", label: "Ge Capital" },
  { value: "Ge Capital Finanance (I) Ltd", label: "Ge Capital Finanance (I) Ltd" },
  { value: "Ge Capital Financial Services Ltd.", label: "Ge Capital Financial Services Ltd." },
  { value: "Ge Capital Jfs Ltd", label: "Ge Capital Jfs Ltd" },
  { value: "Ge Capital Loan Of Financial Services Ltd", label: "Ge Capital Loan Of Financial Services Ltd" },
  { value: "Ge Capital Tfc Ltd.", label: "Ge Capital Tfc Ltd." },
  { value: "Ge Capital Tff Ltd", label: "Ge Capital Tff Ltd" },
  { value: "Ge Capital Tfs India Ltd", label: "Ge Capital Tfs India Ltd" },
  { value: "Ge Capital Tfs Ltd", label: "Ge Capital Tfs Ltd" },
  { value: "Ge Capital Transport Finance Services Ltd.", label: "Ge Capital Transport Finance Services Ltd." },
  { value: "Ge Capital Transportation And Finance", label: "Ge Capital Transportation And Finance" },
  { value: "Ge Capital Transportation Fin.Co.Ltd.", label: "Ge Capital Transportation Fin.Co.Ltd." },
  { value: "Ge Capital Transportation Finacial Services Ltd", label: "Ge Capital Transportation Finacial Services Ltd" },
  { value: "Ge Capital Transportation Services Ltd", label: "Ge Capital Transportation Services Ltd" },
  { value: "Ge Caps Transp & Financial Srvs Ltd", label: "Ge Caps Transp & Financial Srvs Ltd" },
  { value: "Ge Ccf Ltd", label: "Ge Ccf Ltd" },
  { value: "Ge Commercial Finance", label: "Ge Commercial Finance" },
  { value: "Ge Consumer Finance Ltd", label: "Ge Consumer Finance Ltd" },
  { value: "Ge Contrywide Finance", label: "Ge Contrywide Finance" },
  { value: "Ge Countriwide Finance", label: "Ge Countriwide Finance" },
  { value: "Ge Country Wide", label: "Ge Country Wide" },
  { value: "Ge Countrywide Auto Fin Ser Ltd", label: "Ge Countrywide Auto Fin Ser Ltd" },
  { value: "Ge Countrywide Bank", label: "Ge Countrywide Bank" },
  { value: "Ge Countrywide C F S Ltd", label: "Ge Countrywide C F S Ltd" },
  { value: "Ge Countrywide Cons Financial Services Ltd", label: "Ge Countrywide Cons Financial Services Ltd" },
  { value: "Ge Countrywide Consumer Finance Service Ltd.", label: "Ge Countrywide Consumer Finance Service Ltd." },
  { value: "Ge Countrywide Consumer Financial Services Ltd", label: "Ge Countrywide Consumer Financial Services Ltd" },
  { value: "Ge Countrywide Financial Services Ltd", label: "Ge Countrywide Financial Services Ltd" },
  { value: "Ge Finance Ltd.", label: "Ge Finance Ltd." },
  { value: "Ge International Services Ltd", label: "Ge International Services Ltd" },
  { value: "Ge Money", label: "Ge Money" },
  { value: "Ge Money Consumer Service Ltd", label: "Ge Money Consumer Service Ltd" },
  { value: "Ge Money Fin Ser Ltd", label: "Ge Money Fin Ser Ltd" },
  { value: "Ge Money Finance Ltd", label: "Ge Money Finance Ltd" },
  { value: "Ge Money Financial Service Ltd", label: "Ge Money Financial Service Ltd" },
  { value: "Ge Transport Finance Ltd", label: "Ge Transport Finance Ltd" },
  { value: "Gecaps Transp & Financial Srvs Ltd", label: "Gecaps Transp & Financial Srvs Ltd" },
  { value: "Gecis", label: "Gecis" },
  { value: "Gecountrywide Consumer Finance Serices Ltd", label: "Gecountrywide Consumer Finance Serices Ltd" },
  { value: "Gee Amol Leasing And Finance Ltd", label: "Gee Amol Leasing And Finance Ltd" },
  { value: "Gee Cee Advance Finance Ltd", label: "Gee Cee Advance Finance Ltd" },
  { value: "Geeta Pasricha", label: "Geeta Pasricha" },
  { value: "Geethanjali Auto Finance", label: "Geethanjali Auto Finance" },
  { value: "Gemac Financial Services", label: "Gemac Financial Services" },
  { value: "Gemaney Financial", label: "Gemaney Financial" },
  { value: "Gemc Financial Services", label: "Gemc Financial Services" },
  { value: "General Auto Finance", label: "General Auto Finance" },
  { value: "General Co-Op Bank Ltd.", label: "General Co-Op Bank Ltd." },
  { value: "General Finance", label: "General Finance" },
  { value: "General Finance Ltd", label: "General Finance Ltd" },
  { value: "General Insurance Corporation Of India", label: "General Insurance Corporation Of India" },
  { value: "General Insurance Employees Co-Op Cr Soc", label: "General Insurance Employees Co-Op Cr Soc" },
  { value: "General Investment Co", label: "General Investment Co" },
  { value: "General Investment Pvt Ltd", label: "General Investment Pvt Ltd" },
  { value: "General Leasing Finance Co Ltd", label: "General Leasing Finance Co Ltd" },
  { value: "General Manager Telecom", label: "General Manager Telecom" },
  { value: "General Motor Finance", label: "General Motor Finance" },
  { value: "General Motors", label: "General Motors" },
  { value: "General Post Office", label: "General Post Office" },
  { value: "Geninc", label: "Geninc" },
  { value: "Geological Survey Of India", label: "Geological Survey Of India" },
  { value: "George Kuriakore", label: "George Kuriakore" },
  { value: "George Town Co-Op. Bank Ltd", label: "George Town Co-Op. Bank Ltd" },
  { value: "Geverchand Bhanwarlal & Co", label: "Geverchand Bhanwarlal & Co" },
  { value: "Ggb Silani", label: "Ggb Silani" },
  { value: "Ggb Tigaon", label: "Ggb Tigaon" },
  { value: "Ghabua - Dhar Kshetriya Gramin Bank", label: "Ghabua - Dhar Kshetriya Gramin Bank" },
  { value: "Ghadhinglaj Urban Co Op Bank Ltd", label: "Ghadhinglaj Urban Co Op Bank Ltd" },
  { value: "Ghagga Finance Company", label: "Ghagga Finance Company" },
  { value: "Ghanshyam Lease Finance", label: "Ghanshyam Lease Finance" },
  { value: "Ghatal Co-Operative Agri And Rural Dev", label: "Ghatal Co-Operative Agri And Rural Dev" },
  { value: "Ghatal Peoples Co-Operative Bank Ltd", label: "Ghatal Peoples Co-Operative Bank Ltd" },
  { value: "Ghataz Peoples Co. Operative Bank Ltd.", label: "Ghataz Peoples Co. Operative Bank Ltd." },
  { value: "Ghaverchand Bhanwarlal & Co", label: "Ghaverchand Bhanwarlal & Co" },
  { value: "Ghaziabad Urban Co-Op Bank Limited", label: "Ghaziabad Urban Co-Op Bank Limited" },
  { value: "Ghaziabad Zila Sahakari Bank", label: "Ghaziabad Zila Sahakari Bank" },
  { value: "Ghazipur Urban Co-Op Bank Ltd", label: "Ghazipur Urban Co-Op Bank Ltd" },
  { value: "Gheverchand Bhanwantlal And Co", label: "Gheverchand Bhanwantlal And Co" },
  { value: "Ghewarchand Bhanwarlal And Co.", label: "Ghewarchand Bhanwarlal And Co." },
  { value: "Ghewarchand Bhanwarlal Finance", label: "Ghewarchand Bhanwarlal Finance" },
  { value: "Ghiya Mamodiya Finance Ltd", label: "Ghiya Mamodiya Finance Ltd" },
  { value: "Ghobipettipalayam Co-Op Urban Bank Ltd", label: "Ghobipettipalayam Co-Op Urban Bank Ltd" },
  { value: "Ghogaraj Nagari Sahkari Path Sanstha", label: "Ghogaraj Nagari Sahkari Path Sanstha" },
  { value: "Ghoti Merchants Co-Op Bank Ltd", label: "Ghoti Merchants Co-Op Bank Ltd" },
  { value: "Ghungrala Co-Op Society Ltd.", label: "Ghungrala Co-Op Society Ltd." },
  { value: "Gic Of India", label: "Gic Of India" },
  { value: "Gifco Finance Ltd", label: "Gifco Finance Ltd" },
  { value: "Gilada Finance And Investments Ltd", label: "Gilada Finance And Investments Ltd" },
  { value: "Gilada Finance And Leasing", label: "Gilada Finance And Leasing" },
  { value: "Gill And Company", label: "Gill And Company" },
  { value: "Gill And Virk Finance Co. Ltd.", label: "Gill And Virk Finance Co. Ltd." },
  { value: "Gill Finance", label: "Gill Finance" },
  { value: "Gill Financers", label: "Gill Financers" },
  { value: "Gillete  India Ltd", label: "Gillete  India Ltd" },
  { value: "Gimni Investments", label: "Gimni Investments" },
  { value: "Gingee Co.Op. Agricultural Bank Ltd.", label: "Gingee Co.Op. Agricultural Bank Ltd." },
  { value: "Gingee Coop Agricultural Bank Ltd", label: "Gingee Coop Agricultural Bank Ltd" },
  { value: "Gingee Primary Agricultural Co Bk L,", label: "Gingee Primary Agricultural Co Bk L," },
  { value: "Ginni Auto Deals", label: "Ginni Auto Deals" },
  { value: "Ginni Filaments Ltd", label: "Ginni Filaments Ltd" },
  { value: "Girant And Sons", label: "Girant And Sons" },
  { value: "Girdhar Auto Finance.", label: "Girdhar Auto Finance." },
  { value: "Girdhar Fin Lease Pvt Ltd", label: "Girdhar Fin Lease Pvt Ltd" },
  { value: "Girdhar Finance", label: "Girdhar Finance" },
  { value: "Girdhar Finance Nachangaon", label: "Girdhar Finance Nachangaon" },
  { value: "Girdhar Investment", label: "Girdhar Investment" },
  { value: "Girdhar Investment Finance", label: "Girdhar Investment Finance" },
  { value: "Girdhar Motor Finance", label: "Girdhar Motor Finance" },
  { value: "Girdhari Finlease Pvt Ltd", label: "Girdhari Finlease Pvt Ltd" },
  { value: "Giri Raj Associates", label: "Giri Raj Associates" },
  { value: "Giridhar Fin Nichan Gaon", label: "Giridhar Fin Nichan Gaon" },
  { value: "Giridhar Investment", label: "Giridhar Investment" },
  { value: "Giridhar Motor Finance", label: "Giridhar Motor Finance" },
  { value: "Giridi Kshetriya Gramin Bank Ltd", label: "Giridi Kshetriya Gramin Bank Ltd" },
  { value: "Giridih Keshtriya Gramin Bank", label: "Giridih Keshtriya Gramin Bank" },
  { value: "Girijatmak Gramin Sah. Pathsanstha", label: "Girijatmak Gramin Sah. Pathsanstha" },
  { value: "Girijatmak Nag Sah Pat Sanstha", label: "Girijatmak Nag Sah Pat Sanstha" },
  { value: "Giriraj Finance Service", label: "Giriraj Finance Service" },
  { value: "Giriraj Gramin Bifarsheti Sahakar Patsanstha Ltd", label: "Giriraj Gramin Bifarsheti Sahakar Patsanstha Ltd" },
  { value: "Giriraj Gramin Bingarsheti Sahakari Patsanstha Maryadit", label: "Giriraj Gramin Bingarsheti Sahakari Patsanstha Maryadit" },
  { value: "Girish Investments", label: "Girish Investments" },
  { value: "Girish Investments", label: "Girish Investments" },
  { value: "Girish Kumar K", label: "Girish Kumar K" },
  { value: "Girish Urban Co-Op Credit Society Ltd", label: "Girish Urban Co-Op Credit Society Ltd" },
  { value: "Girnar Finance Corporation", label: "Girnar Finance Corporation" },
  { value: "Girnar Finance Corporation Madras", label: "Girnar Finance Corporation Madras" },
  { value: "Gitanjali Finance Corporation", label: "Gitanjali Finance Corporation" },
  { value: "Githa Finance", label: "Githa Finance" },
  { value: "Gk Trading Company", label: "Gk Trading Company" },
  { value: "Gl Finance Corporation", label: "Gl Finance Corporation" },
  { value: "Gl Financiers", label: "Gl Financiers" },
  { value: "Glamost Cast Dist", label: "Glamost Cast Dist" },
  { value: "Glaxo Smith Kline Consumer Health Care Ltd.", label: "Glaxo Smith Kline Consumer Health Care Ltd." },
  { value: "Glaxosmithkline Consumer Health Care Ltd", label: "Glaxosmithkline Consumer Health Care Ltd" },
  { value: "Glden Motors", label: "Glden Motors" },
  { value: "Global Asset Finance Co.Ltd.", label: "Global Asset Finance Co.Ltd." },
  { value: "Global Auto Finance", label: "Global Auto Finance" },
  { value: "Global Capital Credits", label: "Global Capital Credits" },
  { value: "Global Commercial Service", label: "Global Commercial Service" },
  { value: "Global Credit Co-Op. Society Ltd.", label: "Global Credit Co-Op. Society Ltd." },
  { value: "Global Gallarie Agencies Pvt Ltd", label: "Global Gallarie Agencies Pvt Ltd" },
  { value: "Global Insurance & Financial Servic", label: "Global Insurance & Financial Servic" },
  { value: "Global Trust Bank Limited", label: "Global Trust Bank Limited" },
  { value: "Global Trust Bank Limited.", label: "Global Trust Bank Limited." },
  { value: "Global Trust Bank Ltd.", label: "Global Trust Bank Ltd." },
  { value: "Globe Enterprises", label: "Globe Enterprises" },
  { value: "Globe Finance", label: "Globe Finance" },
  { value: "Gm Finance Corporation", label: "Gm Finance Corporation" },
  { value: "Gmac 20Th Century Finance Corporation", label: "Gmac 20Th Century Finance Corporation" },
  { value: "Gmac Consumer Fin Ser India P Ltd", label: "Gmac Consumer Fin Ser India P Ltd" },
  { value: "Gmac Fin Ser India Ltd", label: "Gmac Fin Ser India Ltd" },
  { value: "Gmac Finance Ltd", label: "Gmac Finance Ltd" },
  { value: "Gmac Finance Ltd", label: "Gmac Finance Ltd" },
  { value: "Gmac Finance Service", label: "Gmac Finance Service" },
  { value: "Gmac Financeial Service Ltd", label: "Gmac Financeial Service Ltd" },
  { value: "Gmac Financial India Ltd", label: "Gmac Financial India Ltd" },
  { value: "Gmac Financial Services India Ltd", label: "Gmac Financial Services India Ltd" },
  { value: "Gmac Financial Services Ltd", label: "Gmac Financial Services Ltd" },
  { value: "Gmac-Tcfc Finance Ltd", label: "Gmac-Tcfc Finance Ltd" },
  { value: "Gmal Financial Services India Ltd", label: "Gmal Financial Services India Ltd" },
  { value: "Gmca Financial Services", label: "Gmca Financial Services" },
  { value: "Gmr Finance Ltd", label: "Gmr Finance Ltd" },
  { value: "Gmr Vasavi Infotech Limited", label: "Gmr Vasavi Infotech Limited" },
  { value: "Gmvc Bank Ltd.", label: "Gmvc Bank Ltd." },
  { value: "Gn Vasavi Finance Ltd.", label: "Gn Vasavi Finance Ltd." },
  { value: "Gnanam Auto Finance Ltd", label: "Gnanam Auto Finance Ltd" },
  { value: "Gnfc Ltd", label: "Gnfc Ltd" },
  { value: "Goa Bank Ltd", label: "Goa Bank Ltd" },
  { value: "Goa Shipyard Ltd.", label: "Goa Shipyard Ltd." },
  { value: "Goa State Co-Op. Bank Ltd., Panaji", label: "Goa State Co-Op. Bank Ltd., Panaji" },
  { value: "Goa State Scheduled Castes And Other Backward Classes, Finance And Development Corporation Ltd", label: "Goa State Scheduled Castes And Other Backward Classes, Finance And Development Corporation Ltd" },
  { value: "Goa State Tourism Development Corporation Ltd.", label: "Goa State Tourism Development Corporation Ltd." },
  { value: "Goa Urban Co-Op. Bank Ltd., Panaji", label: "Goa Urban Co-Op. Bank Ltd., Panaji" },
  { value: "Goakah Sah Mandali", label: "Goakah Sah Mandali" },
  { value: "Goan Peoples Urban Co-Op. Bank Ltd.", label: "Goan Peoples Urban Co-Op. Bank Ltd." },
  { value: "Goan Peoples Urban Co-Op.Bank Ltd., Panaji, Goa", label: "Goan Peoples Urban Co-Op.Bank Ltd., Panaji, Goa" },
  { value: "Gobind Finance And Properties Pvt. Ltd.", label: "Gobind Finance And Properties Pvt. Ltd." },
  { value: "God Gift Finvest & Lease", label: "God Gift Finvest & Lease" },
  { value: "God Gift Finvest & Leasing Ltd", label: "God Gift Finvest & Leasing Ltd" },
  { value: "Godaka Finance Company", label: "Godaka Finance Company" },
  { value: "Godavari Finance", label: "Godavari Finance" },
  { value: "Godavari Grameena Bank", label: "Godavari Grameena Bank" },
  { value: "Godavari Gramin Bank", label: "Godavari Gramin Bank" },
  { value: "Godavari Laxmi Co-Op. Bank Ltd", label: "Godavari Laxmi Co-Op. Bank Ltd" },
  { value: "Godavari Leasing & Finance Co", label: "Godavari Leasing & Finance Co" },
  { value: "Godavari Leasing & Finance Ltd", label: "Godavari Leasing & Finance Ltd" },
  { value: "Godavari Urban Co P Bank Ltd", label: "Godavari Urban Co P Bank Ltd" },
  { value: "Godavari Urban Co-Op Bank Ltd.", label: "Godavari Urban Co-Op Bank Ltd." },
  { value: "Godavari Urban Co-Op.Bank Ltd., Nasik", label: "Godavari Urban Co-Op.Bank Ltd., Nasik" },
  { value: "Godawari Motors P Ltd", label: "Godawari Motors P Ltd" },
  { value: "Godfrey Philips India Ltd", label: "Godfrey Philips India Ltd" },
  { value: "Godhra City Co-Op Bank Ltd", label: "Godhra City Co-Op Bank Ltd" },
  { value: "Godhra Urban Co-Op Bank Ltd", label: "Godhra Urban Co-Op Bank Ltd" },
  { value: "Goel Finance Company", label: "Goel Finance Company" },
  { value: "Goel Motor", label: "Goel Motor" },
  { value: "Goenka & Co", label: "Goenka & Co" },
  { value: "Goetze (India) Financial Services Ltd.", label: "Goetze (India) Financial Services Ltd." },
  { value: "Goghamba Vikas Nagarik Sahkari Bank Ltd", label: "Goghamba Vikas Nagarik Sahkari Bank Ltd" },
  { value: "Goghamba Vikas Nagarik Sahkari Bank Ltd", label: "Goghamba Vikas Nagarik Sahkari Bank Ltd" },
  { value: "Goinjwal Hire Purchase & Co.", label: "Goinjwal Hire Purchase & Co." },
  { value: "Gojaria Nagrik Sahakari Bank Ltd.", label: "Gojaria Nagrik Sahakari Bank Ltd." },
  { value: "Gokak Urban Co-Opcredit Bank Ltd", label: "Gokak Urban Co-Opcredit Bank Ltd" },
  { value: "Gokalpura Sewa Sahkari Mandli Ltd", label: "Gokalpura Sewa Sahkari Mandli Ltd" },
  { value: "Gokarn Urban Bank", label: "Gokarn Urban Bank" },
  { value: "Gokarnanath Co-Op Bank L", label: "Gokarnanath Co-Op Bank L" },
  { value: "Gokarnath Co-Operative Bank", label: "Gokarnath Co-Operative Bank" },
  { value: "Gokul Auto Credit Pvt Ltd", label: "Gokul Auto Credit Pvt Ltd" },
  { value: "Gokul Finance", label: "Gokul Finance" },
  { value: "Gokul Finance And Investment Company Ltd.", label: "Gokul Finance And Investment Company Ltd." },
  { value: "Gokul Motor Finance Corp", label: "Gokul Motor Finance Corp" },
  { value: "Gokul Urban Co-Op Credit Soceity Ltd", label: "Gokul Urban Co-Op Credit Soceity Ltd" },
  { value: "Gokula Finance", label: "Gokula Finance" },
  { value: "Gokuldas Amichand Gujrathi Gramin Pathsanstha", label: "Gokuldas Amichand Gujrathi Gramin Pathsanstha" },
  { value: "Golconda Grameena  Bank", label: "Golconda Grameena  Bank" },
  { value: "Golden Credits And Investments", label: "Golden Credits And Investments" },
  { value: "Golden Finance", label: "Golden Finance" },
  { value: "Golden Finance Co Ltd", label: "Golden Finance Co Ltd" },
  { value: "Golden Loan Park Investors", label: "Golden Loan Park Investors" },
  { value: "Gomatta Gramin Bigarsheti Sah Path San Maryadit", label: "Gomatta Gramin Bigarsheti Sah Path San Maryadit" },
  { value: "Gomta Juth Seva Sahakari Mandals Ltd", label: "Gomta Juth Seva Sahakari Mandals Ltd" },
  { value: "Gomti Gramin Bank,", label: "Gomti Gramin Bank," },
  { value: "Gomti Kashi Sayukt Gramin Bank", label: "Gomti Kashi Sayukt Gramin Bank" },
  { value: "Gondal Nagrik Sahakari Bank Ltd.", label: "Gondal Nagrik Sahakari Bank Ltd." },
  { value: "Gondwana Leasing And Finance P Ltd", label: "Gondwana Leasing And Finance P Ltd" },
  { value: "Gonikopal Rural Co-Op. Bank Ltd.", label: "Gonikopal Rural Co-Op. Bank Ltd." },
  { value: "Good Day Finance", label: "Good Day Finance" },
  { value: "Good Luck Auto Finance", label: "Good Luck Auto Finance" },
  { value: "Good Luck Finannce Corporation", label: "Good Luck Finannce Corporation" },
  { value: "Goodluck Advances And Finlease Pvt. Ltd.", label: "Goodluck Advances And Finlease Pvt. Ltd." },
  { value: "Goodluck Lease & Finance Company Ltd", label: "Goodluck Lease & Finance Company Ltd" },
  { value: "Goodwill Credit Co-Op Society Ltd", label: "Goodwill Credit Co-Op Society Ltd" },
  { value: "Goodwill Finance Corpn", label: "Goodwill Finance Corpn" },
  { value: "Goodwill India Ltd", label: "Goodwill India Ltd" },
  { value: "Gooty Town Co-Op Bank Ltd", label: "Gooty Town Co-Op Bank Ltd" },
  { value: "Gopal And Co. Bus - Truck Financer", label: "Gopal And Co. Bus - Truck Financer" },
  { value: "Gopal Auto Finance", label: "Gopal Auto Finance" },
  { value: "Gopal Finance", label: "Gopal Finance" },
  { value: "Gopalganj Central Co Op Bank Ltd", label: "Gopalganj Central Co Op Bank Ltd" },
  { value: "Gopalganj Kshetriya Gramin Bank", label: "Gopalganj Kshetriya Gramin Bank" },
  { value: "Gopalka Auto Finance Ltd.", label: "Gopalka Auto Finance Ltd." },
  { value: "Gopalka Credit Corporation", label: "Gopalka Credit Corporation" },
  { value: "Gopalka Motor Finance Ltd", label: "Gopalka Motor Finance Ltd" },
  { value: "Gopalkrishna Panchkroshi Gramin Bigarsheti Patsanstha", label: "Gopalkrishna Panchkroshi Gramin Bigarsheti Patsanstha" },
  { value: "Gopalkrishna Vividhaddesha Sahakari Sangha Novunda", label: "Gopalkrishna Vividhaddesha Sahakari Sangha Novunda" },
  { value: "Gopi Krishna Auto Finance", label: "Gopi Krishna Auto Finance" },
  { value: "Gopichand Motiram Jagwani", label: "Gopichand Motiram Jagwani" },
  { value: "Gora Singh", label: "Gora Singh" },
  { value: "Gorai Nagarik Pathasanstha", label: "Gorai Nagarik Pathasanstha" },
  { value: "Gorakhpur Kshetriya Gramin Bank", label: "Gorakhpur Kshetriya Gramin Bank" },
  { value: "Goregaon Co-Op Urban Bank Ltd", label: "Goregaon Co-Op Urban Bank Ltd" },
  { value: "Goreshwar Gramin Bigar Sheti Pat San. Ltd.", label: "Goreshwar Gramin Bigar Sheti Pat San. Ltd." },
  { value: "Goreshwar Nagari Sahakari Patsanstha", label: "Goreshwar Nagari Sahakari Patsanstha" },
  { value: "Gotham Investment", label: "Gotham Investment" },
  { value: "Gothi Finance & Invests", label: "Gothi Finance & Invests" },
  { value: "Gounder Auto Finance And Ins", label: "Gounder Auto Finance And Ins" },
  { value: "Gour Gramin Bank Ltd", label: "Gour Gramin Bank Ltd" },
  { value: "Gourav Finances Co. Hathin", label: "Gourav Finances Co. Hathin" },
  { value: "Gourin Shankar Chowdhury And Sons", label: "Gourin Shankar Chowdhury And Sons" },
  { value: "Gourwad Gramin Bigar Sheti Sah. Path Sanstha Maryadit", label: "Gourwad Gramin Bigar Sheti Sah. Path Sanstha Maryadit" },
  { value: "Goutam Chand Abar", label: "Goutam Chand Abar" },
  { value: "Goutam Enterprises", label: "Goutam Enterprises" },
  { value: "Goutam Enterprises & Mahavir Enterprises", label: "Goutam Enterprises & Mahavir Enterprises" },
  { value: "Goutam Finance Ltd", label: "Goutam Finance Ltd" },
  { value: "Goutam Sahakari Bank Ltd", label: "Goutam Sahakari Bank Ltd" },
  { value: "Goutamchand Praveenchand", label: "Goutamchand Praveenchand" },
  { value: "Goutham & Co.Chennai", label: "Goutham & Co.Chennai" },
  { value: "Goutham Auto  Finance", label: "Goutham Auto  Finance" },
  { value: "Goutham Chand Auto Mobile Finance", label: "Goutham Chand Auto Mobile Finance" },
  { value: "Goutham Chand Chordia", label: "Goutham Chand Chordia" },
  { value: "Goutham Chand Jain & Sons", label: "Goutham Chand Jain & Sons" },
  { value: "Goutham Chanda Kothari", label: "Goutham Chanda Kothari" },
  { value: "Goutham Finance Corp", label: "Goutham Finance Corp" },
  { value: "Goutham Investments", label: "Goutham Investments" },
  { value: "Govardhan Finance", label: "Govardhan Finance" },
  { value: "Govardhan Gramin Bagershite Sahakari Path Sanstha", label: "Govardhan Gramin Bagershite Sahakari Path Sanstha" },
  { value: "Govardhan Gramin Big Sheti Saha. Path San.", label: "Govardhan Gramin Big Sheti Saha. Path San." },
  { value: "Gove Investment And Finance Co Ltd.", label: "Gove Investment And Finance Co Ltd." },
  { value: "Gove Investment&Finance Co.Ltd Vijaya Bank", label: "Gove Investment&Finance Co.Ltd Vijaya Bank" },
  { value: "Goverdhan Gramin Bigar Sheti Sahkari Path Sanstha", label: "Goverdhan Gramin Bigar Sheti Sahkari Path Sanstha" },
  { value: "Governer Of Goa", label: "Governer Of Goa" },
  { value: "Governer Of Haryana", label: "Governer Of Haryana" },
  { value: "Government College Of Engineering", label: "Government College Of Engineering" },
  { value: "Government Employees Co-Op. Bank", label: "Government Employees Co-Op. Bank" },
  { value: "Government Medical College And Hospital", label: "Government Medical College And Hospital" },
  { value: "Government Of Goa Secretariat", label: "Government Of Goa Secretariat" },
  { value: "Government Of Tripura", label: "Government Of Tripura" },
  { value: "Government Of Uttar Predesh", label: "Government Of Uttar Predesh" },
  { value: "Governor Of Goa", label: "Governor Of Goa" },
  { value: "Governor Of Haryana", label: "Governor Of Haryana" },
  { value: "Governor Of Tamilnadu", label: "Governor Of Tamilnadu" },
  { value: "Govind Financiers", label: "Govind Financiers" },
  { value: "Govind Motor & Gen Financiers Mukund Ngar", label: "Govind Motor & Gen Financiers Mukund Ngar" },
  { value: "Govind Ram Finance Company", label: "Govind Ram Finance Company" },
  { value: "Govind Transport Finance Co Pvt Ltd", label: "Govind Transport Finance Co Pvt Ltd" },
  { value: "Govinda Auto Finance", label: "Govinda Auto Finance" },
  { value: "Govt Of Haryana", label: "Govt Of Haryana" },
  { value: "Govt. School  Teachers Co-Op Bank Ltd", label: "Govt. School  Teachers Co-Op Bank Ltd" },
  { value: "Gowe Investment Finance Ltd Vijaya Bank", label: "Gowe Investment Finance Ltd Vijaya Bank" },
  { value: "Gowra Leasing And Finance Ltd.", label: "Gowra Leasing And Finance Ltd." },
  { value: "Gowraw Investment", label: "Gowraw Investment" },
  { value: "Gowtham Finance", label: "Gowtham Finance" },
  { value: "Gowthami Automobile Finance", label: "Gowthami Automobile Finance" },
  { value: "Gowthami Finance", label: "Gowthami Finance" },
  { value: "Gowthmchad Nahar Financier", label: "Gowthmchad Nahar Financier" },
  { value: "Goyal & Hiralal/Bank Of India", label: "Goyal & Hiralal/Bank Of India" },
  { value: "Goyal Auto Point", label: "Goyal Auto Point" },
  { value: "Goyal Automotive Ltd.", label: "Goyal Automotive Ltd." },
  { value: "Goyal Finance Company", label: "Goyal Finance Company" },
  { value: "Gozaria Nagarik Shakari Bank Ltd", label: "Gozaria Nagarik Shakari Bank Ltd" },
  { value: "Gp Finance Auto Financeirs Jain Market Bellary", label: "Gp Finance Auto Financeirs Jain Market Bellary" },
  { value: "Gr Syndicate", label: "Gr Syndicate" },
  { value: "Graamika Vanitha Co-Op Society", label: "Graamika Vanitha Co-Op Society" },
  { value: "Grace Auto Finance", label: "Grace Auto Finance" },
  { value: "Gradutates Co-Op Bank Ltd", label: "Gradutates Co-Op Bank Ltd" },
  { value: "Grain Merchants Co-Op Bank Ltd.", label: "Grain Merchants Co-Op Bank Ltd." },
  { value: "Gram Seva Sahakari  Samiti Ltd.", label: "Gram Seva Sahakari  Samiti Ltd." },
  { value: "Gram Seva Sahakari Samiti Ltd.", label: "Gram Seva Sahakari Samiti Ltd." },
  { value: "Grameen Vyavasaya Sahakri Bank Ltd", label: "Grameen Vyavasaya Sahakri Bank Ltd" },
  { value: "Gramin B Sheti Sah Pat San", label: "Gramin B Sheti Sah Pat San" },
  { value: "Gramin Bachat Bank,", label: "Gramin Bachat Bank," },
  { value: "Gramin Bank", label: "Gramin Bank" },
  { value: "Gramin Bank Of Baroda", label: "Gramin Bank Of Baroda" },
  { value: "Gramin Vikas Co-Operative  Samiti", label: "Gramin Vikas Co-Operative  Samiti" },
  { value: "Gramina Bank", label: "Gramina Bank" },
  { value: "Gramvikas Gramin Bigar Seti Pat Sanstha", label: "Gramvikas Gramin Bigar Seti Pat Sanstha" },
  { value: "Grand Motor Finance", label: "Grand Motor Finance" },
  { value: "Graphtek Finanace Co", label: "Graphtek Finanace Co" },
  { value: "Graphteth  Finance  Co.", label: "Graphteth  Finance  Co." },
  { value: "Great Fort Minorities Credit Co-Op Society", label: "Great Fort Minorities Credit Co-Op Society" },
  { value: "Greater Lyca Finance", label: "Greater Lyca Finance" },
  { value: "Greater Mumbai Co-Op Bank Ltd.", label: "Greater Mumbai Co-Op Bank Ltd." },
  { value: "Greater Noida Industrial Development Authority", label: "Greater Noida Industrial Development Authority" },
  { value: "Greatfort Minorities Credit C Society Lt", label: "Greatfort Minorities Credit C Society Lt" },
  { value: "Greatfort Minorities Credit Co-Op.Soc.Lt", label: "Greatfort Minorities Credit Co-Op.Soc.Lt" },
  { value: "Greatfort Minorities Credit Co-Operative Society Ltd", label: "Greatfort Minorities Credit Co-Operative Society Ltd" },
  { value: "Green Finance", label: "Green Finance" },
  { value: "Green Finance Co.", label: "Green Finance Co." },
  { value: "Green Land Finance & Leasing P Ltd", label: "Green Land Finance & Leasing P Ltd" },
  { value: "Greenland Finance & Leasing Pvt Ltd", label: "Greenland Finance & Leasing Pvt Ltd" },
  { value: "Grewal Motor Fin.", label: "Grewal Motor Fin." },
  { value: "Groz Beckert Asia Pvt. Ltd.", label: "Groz Beckert Asia Pvt. Ltd." },
  { value: "Grp Finance", label: "Grp Finance" },
  { value: "Gruh Finance Ltd.", label: "Gruh Finance Ltd." },
  { value: "Gruhalaxmi Mahila Nagrik Sahkari Sanstha", label: "Gruhalaxmi Mahila Nagrik Sahkari Sanstha" },
  { value: "Gs Enterprise", label: "Gs Enterprise" },
  { value: "Gs Investment", label: "Gs Investment" },
  { value: "Gscsc", label: "Gscsc" },
  { value: "Gtp Finance Ltd", label: "Gtp Finance Ltd" },
  { value: "Gtr Finance", label: "Gtr Finance" },
  { value: "Guardian Sahakara Bank Niyamita", label: "Guardian Sahakara Bank Niyamita" },
  { value: "Gubera Auto Finance", label: "Gubera Auto Finance" },
  { value: "Gudalur Primary Agrucultural Co-Op Bk Lt", label: "Gudalur Primary Agrucultural Co-Op Bk Lt" },
  { value: "Gudivada Co-Op Urban Bank Ltd", label: "Gudivada Co-Op Urban Bank Ltd" },
  { value: "Guhya Agastheshwara Seva Sah. Vya. Bank", label: "Guhya Agastheshwara Seva Sah. Vya. Bank" },
  { value: "Guj Ahusuchit Jati Vikas Corporation", label: "Guj Ahusuchit Jati Vikas Corporation" },
  { value: "Guj She Cast Dev Corp", label: "Guj She Cast Dev Corp" },
  { value: "Gujarat  Minority Commu Fin And Development Corpo  Ltd", label: "Gujarat  Minority Commu Fin And Development Corpo  Ltd" },
  { value: "Gujarat Ambuja Co-Op Bank Ltd", label: "Gujarat Ambuja Co-Op Bank Ltd" },
  { value: "Gujarat Ambuja Co-Op Bank Ltd.", label: "Gujarat Ambuja Co-Op Bank Ltd." },
  { value: "Gujarat Anusuchit Jati Vikas Nigam", label: "Gujarat Anusuchit Jati Vikas Nigam" },
  { value: "Gujarat Anusuchit Jati Vikash Corporation", label: "Gujarat Anusuchit Jati Vikash Corporation" },
  { value: "Gujarat Anusuchit Jativikas Corporation", label: "Gujarat Anusuchit Jativikas Corporation" },
  { value: "Gujarat Backward Class Devlp Corporation", label: "Gujarat Backward Class Devlp Corporation" },
  { value: "Gujarat Backward Class Nigam Ltd", label: "Gujarat Backward Class Nigam Ltd" },
  { value: "Gujarat Backward Co Op Bank", label: "Gujarat Backward Co Op Bank" },
  { value: "Gujarat Commercial Bank Ltd", label: "Gujarat Commercial Bank Ltd" },
  { value: "Gujarat Commercial Co-Operative Bank Ltd", label: "Gujarat Commercial Co-Operative Bank Ltd" },
  { value: "Gujarat Cooperative Milk Marketing Federation Ltd. (Amul)", label: "Gujarat Cooperative Milk Marketing Federation Ltd. (Amul)" },
  { value: "Gujarat Electricity Board,Utran", label: "Gujarat Electricity Board,Utran" },
  { value: "Gujarat Energy Transmission Corp. Ltd.", label: "Gujarat Energy Transmission Corp. Ltd." },
  { value: "Gujarat Gas Co Op Credit Society", label: "Gujarat Gas Co Op Credit Society" },
  { value: "Gujarat Gas Finance Co Op Credit Soc", label: "Gujarat Gas Finance Co Op Credit Soc" },
  { value: "Gujarat Gopalak Development Corporation Ltd", label: "Gujarat Gopalak Development Corporation Ltd" },
  { value: "Gujarat Gopalak Vikas Corporation Ltd", label: "Gujarat Gopalak Vikas Corporation Ltd" },
  { value: "Gujarat Gopalak Vikas Nigam Ltd", label: "Gujarat Gopalak Vikas Nigam Ltd" },
  { value: "Gujarat Gramin Bank", label: "Gujarat Gramin Bank" },
  { value: "Gujarat Industrial Co-Op Bank Ltd.", label: "Gujarat Industrial Co-Op Bank Ltd." },
  { value: "Gujarat Industrial Investment Corporation", label: "Gujarat Industrial Investment Corporation" },
  { value: "Gujarat Infrastructure Development Board", label: "Gujarat Infrastructure Development Board" },
  { value: "Gujarat Lease Finacial Service", label: "Gujarat Lease Finacial Service" },
  { value: "Gujarat Lease Financing Ltd.", label: "Gujarat Lease Financing Ltd." },
  { value: "Gujarat Mercantile Bank Ltd", label: "Gujarat Mercantile Bank Ltd" },
  { value: "Gujarat Mercantile Co-Op. Bank Ltd.", label: "Gujarat Mercantile Co-Op. Bank Ltd." },
  { value: "Gujarat Mercantile Credit Co-Op Bank Ltd", label: "Gujarat Mercantile Credit Co-Op Bank Ltd" },
  { value: "Gujarat Narmada Fertilizer Corporation Ltd.", label: "Gujarat Narmada Fertilizer Corporation Ltd." },
  { value: "Gujarat Pachhat Varg Vikas Nigam Ltd", label: "Gujarat Pachhat Varg Vikas Nigam Ltd" },
  { value: "Gujarat Power Corporation Ltd.", label: "Gujarat Power Corporation Ltd." },
  { value: "Gujarat Sachivalaya Employee Co Op New Credit Society Ltd.", label: "Gujarat Sachivalaya Employee Co Op New Credit Society Ltd." },
  { value: "Gujarat Safai Kamdar Nigam", label: "Gujarat Safai Kamdar Nigam" },
  { value: "Gujarat Safai Kamdar Vikas Nigam", label: "Gujarat Safai Kamdar Vikas Nigam" },
  { value: "Gujarat Sahakari Cast Psu Corporation", label: "Gujarat Sahakari Cast Psu Corporation" },
  { value: "Gujarat Scheduled Caste Development Bank", label: "Gujarat Scheduled Caste Development Bank" },
  { value: "Gujarat State Co Op Development Corporation", label: "Gujarat State Co Op Development Corporation" },
  { value: "Gujarat State Co Op Mut Feb Ltd", label: "Gujarat State Co Op Mut Feb Ltd" },
  { value: "Gujarat State Co-Op Bank Ltd", label: "Gujarat State Co-Op Bank Ltd" },
  { value: "Gujarat State Co. Agree & Rural Development Bank", label: "Gujarat State Co. Agree & Rural Development Bank" },
  { value: "Gujarat State Coop. Marketing Federation Ltd.", label: "Gujarat State Coop. Marketing Federation Ltd." },
  { value: "Gujarat State Electric Corporation Ltd", label: "Gujarat State Electric Corporation Ltd" },
  { value: "Gujarat State Fertilisers Cooperative Ltd.", label: "Gujarat State Fertilisers Cooperative Ltd." },
  { value: "Gujarat State Fertilizers And Chemicals Ltd", label: "Gujarat State Fertilizers And Chemicals Ltd" },
  { value: "Gujarat State Fertilizers Limited", label: "Gujarat State Fertilizers Limited" },
  { value: "Gujarat State Financial Corporation", label: "Gujarat State Financial Corporation" },
  { value: "Gujarat State Financial Services Ltd.", label: "Gujarat State Financial Services Ltd." },
  { value: "Gujarat State Scheduled Cast Devlopment Corporation", label: "Gujarat State Scheduled Cast Devlopment Corporation" },
  { value: "Gujarat State Seeds Corporation Ltd", label: "Gujarat State Seeds Corporation Ltd" },
  { value: "Gujarat State Tourism Development Corporation Ltd.", label: "Gujarat State Tourism Development Corporation Ltd." },
  { value: "Gujarat Venture Finance Limited", label: "Gujarat Venture Finance Limited" },
  { value: "Gujarath Agricultural And Rural Development Bank Ltd", label: "Gujarath Agricultural And Rural Development Bank Ltd" },
  { value: "Gujarath Urban Co-Op Bank Ltd", label: "Gujarath Urban Co-Op Bank Ltd" },
  { value: "Gujrat Gas Financial Services Ltd", label: "Gujrat Gas Financial Services Ltd" },
  { value: "Gujrat Industrial Power Co.Ltd", label: "Gujrat Industrial Power Co.Ltd" },
  { value: "Gujrat Mercantile Credit Co Op S.Ltd", label: "Gujrat Mercantile Credit Co Op S.Ltd" },
  { value: "Gujrat Narmada Valley Fertilizer Co. Ltd", label: "Gujrat Narmada Valley Fertilizer Co. Ltd" },
  { value: "Gujrat Rajya Co Krushi Gramin Vikas Bank Ltd", label: "Gujrat Rajya Co Krushi Gramin Vikas Bank Ltd" },
  { value: "Gujrat State Agriculture & Rural Development Bank", label: "Gujrat State Agriculture & Rural Development Bank" },
  { value: "Gujrat State Fertilizers And Chemicals Ltd.", label: "Gujrat State Fertilizers And Chemicals Ltd." },
  { value: "Gujrat Trible Development  Corporation", label: "Gujrat Trible Development  Corporation" },
  { value: "Gujrath Industrial Co-Op Bank Ltd.", label: "Gujrath Industrial Co-Op Bank Ltd." },
  { value: "Gujrath Industrial Co-Op Bank Ltd.", label: "Gujrath Industrial Co-Op Bank Ltd." },
  { value: "Gujrath State Co-Op Bank Ltd.", label: "Gujrath State Co-Op Bank Ltd." },
  { value: "Gulab And Co", label: "Gulab And Co" },
  { value: "Gulab Auto Finance", label: "Gulab Auto Finance" },
  { value: "Gulab Chand Pagaria And Sons", label: "Gulab Chand Pagaria And Sons" },
  { value: "Gulab Finance", label: "Gulab Finance" },
  { value: "Gulab Investments", label: "Gulab Investments" },
  { value: "Gulbarga District Central Co-Operative Bank Ltd.", label: "Gulbarga District Central Co-Operative Bank Ltd." },
  { value: "Gulbarga Janata Sahakari Bank Niyamita", label: "Gulbarga Janata Sahakari Bank Niyamita" },
  { value: "Gulbarga Janath Sahakari Bank Niyamith", label: "Gulbarga Janath Sahakari Bank Niyamith" },
  { value: "Gulbarga Urban Co-Op. Bank Ltd.", label: "Gulbarga Urban Co-Op. Bank Ltd." },
  { value: "Guledguddha Laxmi Co-Op Bank Ltd", label: "Guledguddha Laxmi Co-Op Bank Ltd" },
  { value: "Gulmohar Nagari Sahakari Pathsanstha Ltd", label: "Gulmohar Nagari Sahakari Pathsanstha Ltd" },
  { value: "Gulshan Auto Finance", label: "Gulshan Auto Finance" },
  { value: "Gulshan Finance Company", label: "Gulshan Finance Company" },
  { value: "Gulshan Mer Urban Co-Of Bank", label: "Gulshan Mer Urban Co-Of Bank" },
  { value: "Gulshan Mercantile Co Opertaive Bank", label: "Gulshan Mercantile Co Opertaive Bank" },
  { value: "Gulshan Mercantile Urban Co-Op Bank Ltd", label: "Gulshan Mercantile Urban Co-Op Bank Ltd" },
  { value: "Guman Singh Lodha And Sons Financiers", label: "Guman Singh Lodha And Sons Financiers" },
  { value: "Gumbar Finance Corporation", label: "Gumbar Finance Corporation" },
  { value: "Guna Auto Finance", label: "Guna Auto Finance" },
  { value: "Guna Nagarik Sahakari Bank Maryadit", label: "Guna Nagarik Sahakari Bank Maryadit" },
  { value: "Gunawant Chand", label: "Gunawant Chand" },
  { value: "Gunawant Chand Kahariwal", label: "Gunawant Chand Kahariwal" },
  { value: "Gunawant Chand Khariwal", label: "Gunawant Chand Khariwal" },
  { value: "Gundecha & Co", label: "Gundecha & Co" },
  { value: "Gundecha Finance", label: "Gundecha Finance" },
  { value: "Gundecha Finance Corporation", label: "Gundecha Finance Corporation" },
  { value: "Gundecha Finlease", label: "Gundecha Finlease" },
  { value: "Gundecha Finlease Finance", label: "Gundecha Finlease Finance" },
  { value: "Gundecha Motor Finance", label: "Gundecha Motor Finance" },
  { value: "Gunore Gramin Big Sheti", label: "Gunore Gramin Big Sheti" },
  { value: "Gunore Gramin Biger Sheti Sahakari Pat Sanstha Mydt", label: "Gunore Gramin Biger Sheti Sahakari Pat Sanstha Mydt" },
  { value: "Guntur District Central Co-Operative Bank Ltd.", label: "Guntur District Central Co-Operative Bank Ltd." },
  { value: "Gupta Auto Deals", label: "Gupta Auto Deals" },
  { value: "Gupta Auto Finance", label: "Gupta Auto Finance" },
  { value: "Gupta General Finance Company", label: "Gupta General Finance Company" },
  { value: "Gupta Instalment Supply (P) Ltd.", label: "Gupta Instalment Supply (P) Ltd." },
  { value: "Gupta Motors", label: "Gupta Motors" },
  { value: "Gura Basava Auto General Finance Regt", label: "Gura Basava Auto General Finance Regt" },
  { value: "Gurdaspur-Amritsar Kshetriya Gra Vikas B", label: "Gurdaspur-Amritsar Kshetriya Gra Vikas B" },
  { value: "Gurdaspur-Amritsar Kshetriya Gramin Vikas Bank", label: "Gurdaspur-Amritsar Kshetriya Gramin Vikas Bank" },
  { value: "Gurdeep Singh", label: "Gurdeep Singh" },
  { value: "Gurgaon Central Co-Op. Bank Ltd.", label: "Gurgaon Central Co-Op. Bank Ltd." },
  { value: "Gurgaon District Central Co-Operative Bank Ltd.", label: "Gurgaon District Central Co-Operative Bank Ltd." },
  { value: "Gurlahosur Urban Co-Op Credit Society Ltd", label: "Gurlahosur Urban Co-Op Credit Society Ltd" },
  { value: "Gurlahosur Urban Co-Operative Society Ltd.", label: "Gurlahosur Urban Co-Operative Society Ltd." },
  { value: "Gurmeet Finansiars", label: "Gurmeet Finansiars" },
  { value: "Gurpal Auto Deals", label: "Gurpal Auto Deals" },
  { value: "Gurram Manjula Automobile Finance", label: "Gurram Manjula Automobile Finance" },
  { value: "Gurram Uma Maheswari Devi Automobile Financiers", label: "Gurram Uma Maheswari Devi Automobile Financiers" },
  { value: "Gurram Venkatraju Automobile Financiers", label: "Gurram Venkatraju Automobile Financiers" },
  { value: "Guru Auto Deals", label: "Guru Auto Deals" },
  { value: "Guru Bankers", label: "Guru Bankers" },
  { value: "Guru Basava Auto", label: "Guru Basava Auto" },
  { value: "Guru Dev Finance", label: "Guru Dev Finance" },
  { value: "Guru Ganesh Finance", label: "Guru Ganesh Finance" },
  { value: "Guru Govindsing Sahakari Credit Maryadit", label: "Guru Govindsing Sahakari Credit Maryadit" },
  { value: "Guru Har Kishan Leasing & Finance", label: "Guru Har Kishan Leasing & Finance" },
  { value: "Guru Har Kishan Leasing And Finance Ltd.", label: "Guru Har Kishan Leasing And Finance Ltd." },
  { value: "Guru Hasti Finance", label: "Guru Hasti Finance" },
  { value: "Guru Hasti Investments", label: "Guru Hasti Investments" },
  { value: "Guru Kirpa Spare Parts", label: "Guru Kirpa Spare Parts" },
  { value: "Guru Misri Finance", label: "Guru Misri Finance" },
  { value: "Guru Nanak Enterprises", label: "Guru Nanak Enterprises" },
  { value: "Guru Nanak Fin And Investment Corpn", label: "Guru Nanak Fin And Investment Corpn" },
  { value: "Guru Nanak Finance", label: "Guru Nanak Finance" },
  { value: "Guru Nanak Motors & General Finance Company", label: "Guru Nanak Motors & General Finance Company" },
  { value: "Guru Nithyananda Credit Co Op Society Ltd", label: "Guru Nithyananda Credit Co Op Society Ltd" },
  { value: "Guru Nittyananda Credit Co-Op Society Ltd", label: "Guru Nittyananda Credit Co-Op Society Ltd" },
  { value: "Guru Nityanand Credit Coop Soc Ltd", label: "Guru Nityanand Credit Coop Soc Ltd" },
  { value: "Guru Raj Vidhya Sakh Sahakarita Mydt", label: "Guru Raj Vidhya Sakh Sahakarita Mydt" },
  { value: "Guru Tegh Motors And Trading Co.", label: "Guru Tegh Motors And Trading Co." },
  { value: "Guruaasra Finance Co", label: "Guruaasra Finance Co" },
  { value: "Gurudaspur District Central Co-Operative Bank Ltd.", label: "Gurudaspur District Central Co-Operative Bank Ltd." },
  { value: "Gurudaspur- Amritsar K.G.Vikas Bank", label: "Gurudaspur- Amritsar K.G.Vikas Bank" },
  { value: "Gurudatt Finance", label: "Gurudatt Finance" },
  { value: "Gurudatta Gramin Bigar Sheti", label: "Gurudatta Gramin Bigar Sheti" },
  { value: "Gurudev Brahmanand Pattan Sahakari Bank Niyamit", label: "Gurudev Brahmanand Pattan Sahakari Bank Niyamit" },
  { value: "Gurudev Investment", label: "Gurudev Investment" },
  { value: "Gurudev Motors", label: "Gurudev Motors" },
  { value: "Gurukirpa Finvest Ltd", label: "Gurukirpa Finvest Ltd" },
  { value: "Gurukirpa Hire Purchase", label: "Gurukirpa Hire Purchase" },
  { value: "Gurukripa Haire Purechase", label: "Gurukripa Haire Purechase" },
  { value: "Gurukrupa Auto Finance", label: "Gurukrupa Auto Finance" },
  { value: "Gurukrupa Finance", label: "Gurukrupa Finance" },
  { value: "Gurukrupa Nagri Sahakari Pathpedi Ltd", label: "Gurukrupa Nagri Sahakari Pathpedi Ltd" },
  { value: "Gurumaauli Nagari Sahakari Patsanstha", label: "Gurumaauli Nagari Sahakari Patsanstha" },
  { value: "Gururaj Vidhya Shakh Sahakarita Maryadit", label: "Gururaj Vidhya Shakh Sahakarita Maryadit" },
  { value: "Gurusiddeshwar Co-Op Credit Society", label: "Gurusiddeshwar Co-Op Credit Society" },
  { value: "Guruvayar Co-Operative Urban Bank Ltd.", label: "Guruvayar Co-Operative Urban Bank Ltd." },
  { value: "Guruvayur Co-Op Urban Bank Ltd", label: "Guruvayur Co-Op Urban Bank Ltd" },
  { value: "Guruvayur Co-Op Urban Bank Ltd", label: "Guruvayur Co-Op Urban Bank Ltd" },
  { value: "Guruwarya Gr Big She S P S", label: "Guruwarya Gr Big She S P S" },
  { value: "Guthigar Primary Agrl C.O.B.L,", label: "Guthigar Primary Agrl C.O.B.L," },
  { value: "Guthigodarara Credit Co-Op. Soc Ltd.", label: "Guthigodarara Credit Co-Op. Soc Ltd." },
  { value: "Gutthigedarara Credit Co-Operative Society Ltd.", label: "Gutthigedarara Credit Co-Operative Society Ltd." },
  { value: "Guttigedarara Credit Co-Op. Bank", label: "Guttigedarara Credit Co-Op. Bank" },
  { value: "Guttinadu Vyavasaya Seva Sahakara Bank (N)", label: "Guttinadu Vyavasaya Seva Sahakara Bank (N)" },
  { value: "Guwahati Co-0Perative Urban Bank", label: "Guwahati Co-0Perative Urban Bank" },
  { value: "Guwahati Co-Op Urban Bank Ltd.", label: "Guwahati Co-Op Urban Bank Ltd." },
  { value: "Gvk Capital And Finance Ltd.", label: "Gvk Capital And Finance Ltd." },
  { value: "Gwalior Datia Kshetriya Gramin Bank", label: "Gwalior Datia Kshetriya Gramin Bank" },
  { value: "Gwalior Datia Kshetriya Gramin Bk", label: "Gwalior Datia Kshetriya Gramin Bk" },
  { value: "Gyan Chand Mehata And Sons", label: "Gyan Chand Mehata And Sons" },
  { value: "Gyan Chand Mulasm Chand Finance Co", label: "Gyan Chand Mulasm Chand Finance Co" },
  { value: "Gyan Finance", label: "Gyan Finance" },
  { value: "Gyan Sagar Co-Op Credit Society Ltd.", label: "Gyan Sagar Co-Op Credit Society Ltd." },
  { value: "H . Chanchal Kavar Sisodia", label: "H . Chanchal Kavar Sisodia" },
  { value: "H B Auto Finance", label: "H B Auto Finance" },
  { value: "H Bannalal And Sons", label: "H Bannalal And Sons" },
  { value: "H Chaganlal Chajjed", label: "H Chaganlal Chajjed" },
  { value: "H Chanchal Kavar Sisodia", label: "H Chanchal Kavar Sisodia" },
  { value: "H Chandmal And Sons", label: "H Chandmal And Sons" },
  { value: "H D Auto Hire Purchase Co.", label: "H D Auto Hire Purchase Co." },
  { value: "H D Shroff", label: "H D Shroff" },
  { value: "H K Commercial Serviceas", label: "H K Commercial Serviceas" },
  { value: "H K Finvest", label: "H K Finvest" },
  { value: "H K Singhal", label: "H K Singhal" },
  { value: "H M Auto Deals", label: "H M Auto Deals" },
  { value: "H M Finance", label: "H M Finance" },
  { value: "H M Sales Corporation", label: "H M Sales Corporation" },
  { value: "H N R Credit Corparation", label: "H N R Credit Corparation" },
  { value: "H P A To M D C C Bank Kadabahalli", label: "H P A To M D C C Bank Kadabahalli" },
  { value: "H P Dugar Finance", label: "H P Dugar Finance" },
  { value: "H P Finance And Leasing Co.", label: "H P Finance And Leasing Co." },
  { value: "H P M K Auto Finance", label: "H P M K Auto Finance" },
  { value: "H P Terminated", label: "H P Terminated" },
  { value: "H R D Fincap Ltd", label: "H R D Fincap Ltd" },
  { value: "H Rathan & Sons", label: "H Rathan & Sons" },
  { value: "H Rathan Chand", label: "H Rathan Chand" },
  { value: "H Rathan Chand & Sons", label: "H Rathan Chand & Sons" },
  { value: "H Rathan Chand Finance", label: "H Rathan Chand Finance" },
  { value: "H S F & D C", label: "H S F & D C" },
  { value: "H S Finance Corp", label: "H S Finance Corp" },
  { value: "H Sanjay Sisodia", label: "H Sanjay Sisodia" },
  { value: "H Sanjay Sisodia & Sons", label: "H Sanjay Sisodia & Sons" },
  { value: "H Sujith Sisodia", label: "H Sujith Sisodia" },
  { value: "H Sujith Sisodia & Sons", label: "H Sujith Sisodia & Sons" },
  { value: "H V Dileep", label: "H V Dileep" },
  { value: "H V P N L", label: "H V P N L" },
  { value: "H V Patel And Co", label: "H V Patel And Co" },
  { value: "H. Chunnilal Bagmar Financier", label: "H. Chunnilal Bagmar Financier" },
  { value: "H. Manish Kumar Jain", label: "H. Manish Kumar Jain" },
  { value: "H. P.- Md Eil", label: "H. P.- Md Eil" },
  { value: "H. Rajesh Kumar", label: "H. Rajesh Kumar" },
  { value: "H.Chanchal Kavar Sisodia", label: "H.Chanchal Kavar Sisodia" },
  { value: "H.D Auto Auto Hirf Purchase Co.", label: "H.D Auto Auto Hirf Purchase Co." },
  { value: "H.K.G.B. Dug", label: "H.K.G.B. Dug" },
  { value: "H.L.Shantilal Jain", label: "H.L.Shantilal Jain" },
  { value: "H.M & Co", label: "H.M & Co" },
  { value: "H.M.T. (International Ltd.)", label: "H.M.T. (International Ltd.)" },
  { value: "H.Manish Kumar Jain", label: "H.Manish Kumar Jain" },
  { value: "H.N.Prakash Chand And Confirm", label: "H.N.Prakash Chand And Confirm" },
  { value: "H.P State  Agri & Ruler Dev. Bank Ltd", label: "H.P State  Agri & Ruler Dev. Bank Ltd" },
  { value: "H.P State Agro Agri & Ruler Dev. Bank Ltd", label: "H.P State Agro Agri & Ruler Dev. Bank Ltd" },
  { value: "H.P. & Sons", label: "H.P. & Sons" },
  { value: "H.P.State Co-Op Agriculture & Rural Developement Bank Ltd.", label: "H.P.State Co-Op Agriculture & Rural Developement Bank Ltd." },
  { value: "H.R Motors", label: "H.R Motors" },
  { value: "H.R.Finance Co", label: "H.R.Finance Co" },
  { value: "H.S.C. Finance And D.M. Ltd", label: "H.S.C. Finance And D.M. Ltd" },
  { value: "H.S.F. & D.C.", label: "H.S.F. & D.C." },
  { value: "H/P Bee Pee Jay Finance Ltd Sbi Ssi Branch", label: "H/P Bee Pee Jay Finance Ltd Sbi Ssi Branch" },
  { value: "Hadagali Urban Co-Op. Bank Ltd", label: "Hadagali Urban Co-Op. Bank Ltd" },
  { value: "Hadgali Urban Co-Operative Bank Ltd", label: "Hadgali Urban Co-Operative Bank Ltd" },
  { value: "Hafij Khankhalil Khan", label: "Hafij Khankhalil Khan" },
  { value: "Haji Abdula Mahaldar Nagari Sah. Bank.", label: "Haji Abdula Mahaldar Nagari Sah. Bank." },
  { value: "Haji Abdulla Mahaldor Gbs Pat Maryadit", label: "Haji Abdulla Mahaldor Gbs Pat Maryadit" },
  { value: "Hal Employee Co-Op Credit Soc Ltd, Ozar Township, Nashik", label: "Hal Employee Co-Op Credit Soc Ltd, Ozar Township, Nashik" },
  { value: "Halccs", label: "Halccs" },
  { value: "Haliyal Urban Co-Op Bank Ltd", label: "Haliyal Urban Co-Op Bank Ltd" },
  { value: "Haliyal Urban Co-Operative Cr. Bank Ltd.", label: "Haliyal Urban Co-Operative Cr. Bank Ltd." },
  { value: "Hallmark Leasing And Finance Ltd.", label: "Hallmark Leasing And Finance Ltd." },
  { value: "Halol Urban Co-Op Bank Ltd", label: "Halol Urban Co-Op Bank Ltd" },
  { value: "Hamal Kamagar Nagari Sahakari Patsanstha Maryadit", label: "Hamal Kamagar Nagari Sahakari Patsanstha Maryadit" },
  { value: "Hamirpur District Co-Operatove Bank Ltd.", label: "Hamirpur District Co-Operatove Bank Ltd." },
  { value: "Hamirpur District Cooperative Bank Ltd", label: "Hamirpur District Cooperative Bank Ltd" },
  { value: "Hamland Finance", label: "Hamland Finance" },
  { value: "Hamukhbhai K. Patel,Surat", label: "Hamukhbhai K. Patel,Surat" },
  { value: "Hanamasagar Urban Co-Op Bank Ltd", label: "Hanamasagar Urban Co-Op Bank Ltd" },
  { value: "Hanamasagar Urban Co-Op Bank Ltd", label: "Hanamasagar Urban Co-Op Bank Ltd" },
  { value: "Handicapped Finance & Development Corp. Ltd", label: "Handicapped Finance & Development Corp. Ltd" },
  { value: "Handicrafts And Handlooms Exports Corporation Of India Ltd.", label: "Handicrafts And Handlooms Exports Corporation Of India Ltd." },
  { value: "Handli Vyavasaya Seva Sahakara Bank Nmt.", label: "Handli Vyavasaya Seva Sahakara Bank Nmt." },
  { value: "Handloom Export Promotion Council", label: "Handloom Export Promotion Council" },
  { value: "Hangal Urban Co-Op Bank Ltd", label: "Hangal Urban Co-Op Bank Ltd" },
  { value: "Hanli Man Ji", label: "Hanli Man Ji" },
  { value: "Hans Auto Investments", label: "Hans Auto Investments" },
  { value: "Hans Motor Financers", label: "Hans Motor Financers" },
  { value: "Hansa Investment", label: "Hansa Investment" },
  { value: "Hansha Auto Finance", label: "Hansha Auto Finance" },
  { value: "Hanshitha Enterprises", label: "Hanshitha Enterprises" },
  { value: "Hansot Nagrik Sahakari Bank Ltd.", label: "Hansot Nagrik Sahakari Bank Ltd." },
  { value: "Hansraj Sethia & Sons", label: "Hansraj Sethia & Sons" },
  { value: "Hansraj Uttam Chand Kumbhat", label: "Hansraj Uttam Chand Kumbhat" },
  { value: "Hanuman Co-Op Credit Society Ltd", label: "Hanuman Co-Op Credit Society Ltd" },
  { value: "Hanuman Finance", label: "Hanuman Finance" },
  { value: "Hanuman Hire Purchase Finance", label: "Hanuman Hire Purchase Finance" },
  { value: "Hanuman Multipurpose Co-Op Society Ltd", label: "Hanuman Multipurpose Co-Op Society Ltd" },
  { value: "Hanuman Pd Sharma", label: "Hanuman Pd Sharma" },
  { value: "Hanuman Sai Hp Finance", label: "Hanuman Sai Hp Finance" },
  { value: "Hanuman Sri Hire Purchase & Financiers", label: "Hanuman Sri Hire Purchase & Financiers" },
  { value: "Hanumangarh Kendriya Sahakari Bank,", label: "Hanumangarh Kendriya Sahakari Bank," },
  { value: "Hanumanthanagar Co-Op Bank Ltd.", label: "Hanumanthanagar Co-Op Bank Ltd." },
  { value: "Hanumanthnagar C-O Bank Ltd,", label: "Hanumanthnagar C-O Bank Ltd," },
  { value: "Hanumantrao Choudhari Kal Bhairavnath Nag Sah Pat San", label: "Hanumantrao Choudhari Kal Bhairavnath Nag Sah Pat San" },
  { value: "Hanumashetty Finance Ltd", label: "Hanumashetty Finance Ltd" },
  { value: "Happy Investors", label: "Happy Investors" },
  { value: "Har Milap Motors & Finance Co", label: "Har Milap Motors & Finance Co" },
  { value: "Haranath Investment", label: "Haranath Investment" },
  { value: "Harashitha Enterpricess", label: "Harashitha Enterpricess" },
  { value: "Harayana Scheduled Caste Finance Dovelopment Corpration", label: "Harayana Scheduled Caste Finance Dovelopment Corpration" },
  { value: "Harayana State Co-Op Bank Ltd.", label: "Harayana State Co-Op Bank Ltd." },
  { value: "Harbatiyali Juth Seva Sahkari Mandali Ltd.", label: "Harbatiyali Juth Seva Sahkari Mandali Ltd." },
  { value: "Hardeep Bhullar Finance Co", label: "Hardeep Bhullar Finance Co" },
  { value: "Hardik Auto Finance", label: "Hardik Auto Finance" },
  { value: "Hardoi Urban Co-Operative Bank Limited", label: "Hardoi Urban Co-Operative Bank Limited" },
  { value: "Hare Krishna Finance", label: "Hare Krishna Finance" },
  { value: "Hare Krishna Investment", label: "Hare Krishna Investment" },
  { value: "Harendr Finance Co.", label: "Harendr Finance Co." },
  { value: "Harendra Auto Finance", label: "Harendra Auto Finance" },
  { value: "Hareshwar Co-Op Society", label: "Hareshwar Co-Op Society" },
  { value: "Hargobind Fin. Narwal", label: "Hargobind Fin. Narwal" },
  { value: "Hargobind Sahib Hire Purchase Pvt Ltd", label: "Hargobind Sahib Hire Purchase Pvt Ltd" },
  { value: "Hari  Financial Services", label: "Hari  Financial Services" },
  { value: "Hari Auto Associates", label: "Hari Auto Associates" },
  { value: "Hari Enterprises", label: "Hari Enterprises" },
  { value: "Hari Finance", label: "Hari Finance" },
  { value: "Hari Hara Finance Corporation Ltd", label: "Hari Hara Finance Corporation Ltd" },
  { value: "Hari Haran Finance", label: "Hari Haran Finance" },
  { value: "Hari Kishan Singal", label: "Hari Kishan Singal" },
  { value: "Hari Om", label: "Hari Om" },
  { value: "Hari Om Finance", label: "Hari Om Finance" },
  { value: "Hari Priya Auto Financiers", label: "Hari Priya Auto Financiers" },
  { value: "Harihareshwar Sah Bank", label: "Harihareshwar Sah Bank" },
  { value: "Harihareshwar Sahakari Bank Ltd.", label: "Harihareshwar Sahakari Bank Ltd." },
  { value: "Harika Finance", label: "Harika Finance" },
  { value: "Harinder Hire Purchase Pvt. Ltd", label: "Harinder Hire Purchase Pvt. Ltd" },
  { value: "Harinder Investments Pvt. Ltd", label: "Harinder Investments Pvt. Ltd" },
  { value: "Harinder Leasing Ltd", label: "Harinder Leasing Ltd" },
  { value: "Harindra Investment Ltd", label: "Harindra Investment Ltd" },
  { value: "Hariom Auto Consutls", label: "Hariom Auto Consutls" },
  { value: "Hariom Urban Co Op Bank Ltd", label: "Hariom Urban Co Op Bank Ltd" },
  { value: "Hariram Investment & Finance Co", label: "Hariram Investment & Finance Co" },
  { value: "Harish Finance", label: "Harish Finance" },
  { value: "Harish Investment", label: "Harish Investment" },
  { value: "Harish Kumar", label: "Harish Kumar" },
  { value: "Harish Kumar Khurana", label: "Harish Kumar Khurana" },
  { value: "Harish Kumar Surana", label: "Harish Kumar Surana" },
  { value: "Harisha Enterpricess", label: "Harisha Enterpricess" },
  { value: "Harisha Enterpricess", label: "Harisha Enterpricess" },
  { value: "Harishit Auto Finance", label: "Harishit Auto Finance" },
  { value: "Harisiddh Co-Op Bank Ltd.", label: "Harisiddh Co-Op Bank Ltd." },
  { value: "Harisiddhi Co Op Credit Soc Ltd", label: "Harisiddhi Co Op Credit Soc Ltd" },
  { value: "Harita Finance Ltd", label: "Harita Finance Ltd" },
  { value: "Harita Finance Ltd.", label: "Harita Finance Ltd." },
  { value: "Hariyali Urban Coop Cr. Soc. Ltd", label: "Hariyali Urban Coop Cr. Soc. Ltd" },
  { value: "Hariyana Kshetriya Gramin Bank", label: "Hariyana Kshetriya Gramin Bank" },
  { value: "Harjinder Kumar", label: "Harjinder Kumar" },
  { value: "Harkrishan Finance Co. Ltd", label: "Harkrishan Finance Co. Ltd" },
  { value: "Harmilap Motors & Finance", label: "Harmilap Motors & Finance" },
  { value: "Harpal Singh I Sethi", label: "Harpal Singh I Sethi" },
  { value: "Harsh Auto Finance", label: "Harsh Auto Finance" },
  { value: "Harsh Enterprise", label: "Harsh Enterprise" },
  { value: "Harsh Kumar Jain", label: "Harsh Kumar Jain" },
  { value: "Harsha Auto Finance", label: "Harsha Auto Finance" },
  { value: "Harshiddeshwar Gramin Bigar Sheti Pat Sanstha Maryadit", label: "Harshiddeshwar Gramin Bigar Sheti Pat Sanstha Maryadit" },
  { value: "Harshit Auto Finance", label: "Harshit Auto Finance" },
  { value: "Harshit Investment", label: "Harshit Investment" },
  { value: "Harshita Enterpricess", label: "Harshita Enterpricess" },
  { value: "Harshita Enterprises", label: "Harshita Enterprises" },
  { value: "Harsolia Finance Ltd.", label: "Harsolia Finance Ltd." },
  { value: "Harugar Group Gramagala Seva Sahakari Sangha Niyamitha - Goli", label: "Harugar Group Gramagala Seva Sahakari Sangha Niyamitha - Goli" },
  { value: "Harugeri U C C Bank Ltd", label: "Harugeri U C C Bank Ltd" },
  { value: "Harvinder Finance Co Pvt Ltd", label: "Harvinder Finance Co Pvt Ltd" },
  { value: "Harvinder Kaur Sethi", label: "Harvinder Kaur Sethi" },
  { value: "Harvinder Moter Finance Ltd", label: "Harvinder Moter Finance Ltd" },
  { value: "Haryana Backward Classes & Economically Weeker Section", label: "Haryana Backward Classes & Economically Weeker Section" },
  { value: "Haryana Finance & Leasing Co.", label: "Haryana Finance & Leasing Co." },
  { value: "Haryana Finance Private Limited", label: "Haryana Finance Private Limited" },
  { value: "Haryana Financial Corporation", label: "Haryana Financial Corporation" },
  { value: "Haryana Gramin Bank, Rampur ( Ha)", label: "Haryana Gramin Bank, Rampur ( Ha)" },
  { value: "Haryana Haryana Financial Corporation", label: "Haryana Haryana Financial Corporation" },
  { value: "Haryana Institute Of Rural", label: "Haryana Institute Of Rural" },
  { value: "Haryana Kshetriya Gramin Bank", label: "Haryana Kshetriya Gramin Bank" },
  { value: "Haryana Schedule Caste Fin & Dev Co.", label: "Haryana Schedule Caste Fin & Dev Co." },
  { value: "Haryana State Co-Operative Supply And Federation Limited", label: "Haryana State Co-Operative Supply And Federation Limited" },
  { value: "Haryana State Co.Op.Apex Bank Ltd.", label: "Haryana State Co.Op.Apex Bank Ltd." },
  { value: "Haryana State Industrial Development Corp.", label: "Haryana State Industrial Development Corp." },
  { value: "Haryana State Industrial Development Corporation", label: "Haryana State Industrial Development Corporation" },
  { value: "Haryana State Industrial Development Corporation", label: "Haryana State Industrial Development Corporation" },
  { value: "Haryana State Tourism Development Corporation Ltd.", label: "Haryana State Tourism Development Corporation Ltd." },
  { value: "Haryana Warehousing Corporation", label: "Haryana Warehousing Corporation" },
  { value: "Hassan District Central Co-Operative Bank Ltd.", label: "Hassan District Central Co-Operative Bank Ltd." },
  { value: "Hassan District Credit Co Operative Bank Ltd (Hdcc Bank)", label: "Hassan District Credit Co Operative Bank Ltd (Hdcc Bank)" },
  { value: "Hassan District Credit Co-Op Bank", label: "Hassan District Credit Co-Op Bank" },
  { value: "Hastah Finances Ltd.", label: "Hastah Finances Ltd." },
  { value: "Hasthimal Finance", label: "Hasthimal Finance" },
  { value: "Hasti Co-Op Bank Ltd", label: "Hasti Co-Op Bank Ltd" },
  { value: "Hastimal Sisodia", label: "Hastimal Sisodia" },
  { value: "Hastimal Sisodia & Sons", label: "Hastimal Sisodia & Sons" },
  { value: "Hatton National Bank Ltd.", label: "Hatton National Bank Ltd." },
  { value: "Haveli Co Op Bank Ltd", label: "Haveli Co Op Bank Ltd" },
  { value: "Haveli Sahakari Bank Ltd. Moshi", label: "Haveli Sahakari Bank Ltd. Moshi" },
  { value: "Haveri Urban Co-Op Bank Ltd", label: "Haveri Urban Co-Op Bank Ltd" },
  { value: "Havyaka Finance Ltd", label: "Havyaka Finance Ltd" },
  { value: "Hazaribag Kshetriya Gramin Bank", label: "Hazaribag Kshetriya Gramin Bank" },
  { value: "Hbc Fdc", label: "Hbc Fdc" },
  { value: "Hbc Finance And Leasing Ltd.", label: "Hbc Finance And Leasing Ltd." },
  { value: "Hbckn", label: "Hbckn" },
  { value: "Hbfc Bank.", label: "Hbfc Bank." },
  { value: "Hdb Financial Service Ltd", label: "Hdb Financial Service Ltd" },
  { value: "Hdbf Financail Services Ltd", label: "Hdbf Financail Services Ltd" },
  { value: "Hdfc Bank  Ltd.", label: "Hdfc Bank  Ltd." },
  { value: "Hdfc Bank Consumer Loan", label: "Hdfc Bank Consumer Loan" },
  { value: "Hdfc Bank Limited", label: "Hdfc Bank Limited" },
  { value: "Hdfc Bank Ltd - Mangalore", label: "Hdfc Bank Ltd - Mangalore" },
  { value: "Hdfc Bank Ltd.", label: "Hdfc Bank Ltd." },
  { value: "Hdfc Chubb General Insurance Company Ltd", label: "Hdfc Chubb General Insurance Company Ltd" },
  { value: "Hdfc Ergo Gic Limited", label: "Hdfc Ergo Gic Limited" },
  { value: "Hdfc Finanance Ltd.", label: "Hdfc Finanance Ltd." },
  { value: "Hdfc Financail Services Ltd", label: "Hdfc Financail Services Ltd" },
  { value: "Hdfc Finance Pvt Ltd", label: "Hdfc Finance Pvt Ltd" },
  { value: "Hdfc Ltd", label: "Hdfc Ltd" },
  { value: "Health Credit Co-Op Ltd.", label: "Health Credit Co-Op Ltd." },
  { value: "Health Credit Souhardha Sahakari Bank", label: "Health Credit Souhardha Sahakari Bank" },
  { value: "Heavy Engineering Corporation Ltd.", label: "Heavy Engineering Corporation Ltd." },
  { value: "Heavy Water Board", label: "Heavy Water Board" },
  { value: "Heda Finance", label: "Heda Finance" },
  { value: "Hedayot Ali", label: "Hedayot Ali" },
  { value: "Hedgavav Gramin Bigar Sheti Sahakari Patsanstha", label: "Hedgavav Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Heena Finance", label: "Heena Finance" },
  { value: "Heera Finance", label: "Heera Finance" },
  { value: "Heera Financial Services Ltd", label: "Heera Financial Services Ltd" },
  { value: "Heera Investments", label: "Heera Investments" },
  { value: "Heera Mansion", label: "Heera Mansion" },
  { value: "Heeras Auto Mobile Finance", label: "Heeras Auto Mobile Finance" },
  { value: "Hegdwar Gramin Bigari Sheti Sahkari Pathsanstha", label: "Hegdwar Gramin Bigari Sheti Sahkari Pathsanstha" },
  { value: "Helpline Finance Corp", label: "Helpline Finance Corp" },
  { value: "Hem Finance Chennai", label: "Hem Finance Chennai" },
  { value: "Hem Finance Ltd", label: "Hem Finance Ltd" },
  { value: "Hem Finance Nagpur", label: "Hem Finance Nagpur" },
  { value: "Hema Sai Financiers", label: "Hema Sai Financiers" },
  { value: "Hemalatha Chordia", label: "Hemalatha Chordia" },
  { value: "Hemambika Hire Purchasing & Leasing Pvt Ltd", label: "Hemambika Hire Purchasing & Leasing Pvt Ltd" },
  { value: "Hemang Sureshbhai Shah", label: "Hemang Sureshbhai Shah" },
  { value: "Hemant And Co", label: "Hemant And Co" },
  { value: "Hemant Auto Finance", label: "Hemant Auto Finance" },
  { value: "Hemant Finance Company", label: "Hemant Finance Company" },
  { value: "Hemant Kumar Ulsoor", label: "Hemant Kumar Ulsoor" },
  { value: "Hemareddy Mallamma Cr.Co-Op Society Ltd", label: "Hemareddy Mallamma Cr.Co-Op Society Ltd" },
  { value: "Hemkunt Auto Deals", label: "Hemkunt Auto Deals" },
  { value: "Hemkunt Motors", label: "Hemkunt Motors" },
  { value: "Hemreddy Mallamma Pattina Sahakari Niyamiti", label: "Hemreddy Mallamma Pattina Sahakari Niyamiti" },
  { value: "Hena Investment Pvt Ltd", label: "Hena Investment Pvt Ltd" },
  { value: "Heradal Urban Sacherd Bank", label: "Heradal Urban Sacherd Bank" },
  { value: "Heramba Leasing Ltd", label: "Heramba Leasing Ltd" },
  { value: "Herga Sahakari Vyavasaya Bank Ltd", label: "Herga Sahakari Vyavasaya Bank Ltd" },
  { value: "Heri Enterprises", label: "Heri Enterprises" },
  { value: "Heritage Finlease Limited", label: "Heritage Finlease Limited" },
  { value: "Hero Corporate Service Ltd.", label: "Hero Corporate Service Ltd." },
  { value: "Hero Financeial Serveces Ltd.", label: "Hero Financeial Serveces Ltd." },
  { value: "Hero Financial Services Ltd.", label: "Hero Financial Services Ltd." },
  { value: "Hero Honda Fin Lease", label: "Hero Honda Fin Lease" },
  { value: "Hero Honda Motors Ltd", label: "Hero Honda Motors Ltd" },
  { value: "Hewlett Packard Financial Services India Pvt Ltd.", label: "Hewlett Packard Financial Services India Pvt Ltd." },
  { value: "Hgb Bahadurgarh", label: "Hgb Bahadurgarh" },
  { value: "Hi Tech Chits And Finance Corporation", label: "Hi Tech Chits And Finance Corporation" },
  { value: "Hi Tech Tractors", label: "Hi Tech Tractors" },
  { value: "High Grade Auto Finance", label: "High Grade Auto Finance" },
  { value: "Highgate Ventures", label: "Highgate Ventures" },
  { value: "Hill View Hire Purchase Pvt. Ltd.", label: "Hill View Hire Purchase Pvt. Ltd." },
  { value: "Hillson And Dinshaw Pvt Ltd", label: "Hillson And Dinshaw Pvt Ltd" },
  { value: "Him Sikhar Saving And Finance (P) Ltd", label: "Him Sikhar Saving And Finance (P) Ltd" },
  { value: "Himachal Cultural Association Co Op Credit Soc Ltd", label: "Himachal Cultural Association Co Op Credit Soc Ltd" },
  { value: "Himachal Mitra Mandal Co Op Credit Society Ltd.", label: "Himachal Mitra Mandal Co Op Credit Society Ltd." },
  { value: "Himachal Pradesh Financial Corporation", label: "Himachal Pradesh Financial Corporation" },
  { value: "Himachal Pradesh Horticulture Produce Marketing And Processing Corporation Ltd", label: "Himachal Pradesh Horticulture Produce Marketing And Processing Corporation Ltd" },
  { value: "Himachal Pradesh State Co-Op Bank Ltd.", label: "Himachal Pradesh State Co-Op Bank Ltd." },
  { value: "Himachal Pradesh State Coop Bk.", label: "Himachal Pradesh State Coop Bk." },
  { value: "Himachal Pradesh State Electricity Board", label: "Himachal Pradesh State Electricity Board" },
  { value: "Himachal Pradesh State Environment Protection And Pollution Control Board", label: "Himachal Pradesh State Environment Protection And Pollution Control Board" },
  { value: "Himachal Pradesh State Handicrafts And Handloom Corporation Ltd.", label: "Himachal Pradesh State Handicrafts And Handloom Corporation Ltd." },
  { value: "Himachal Pradesh State Industrial Development Corporation", label: "Himachal Pradesh State Industrial Development Corporation" },
  { value: "Himachal Pradesh State Industrial Development Corporation Ltd", label: "Himachal Pradesh State Industrial Development Corporation Ltd" },
  { value: "Himachal Pradesh Tourism Development Corporation Ltd.", label: "Himachal Pradesh Tourism Development Corporation Ltd." },
  { value: "Himachal Road Transport Corporation", label: "Himachal Road Transport Corporation" },
  { value: "Himalaya Finance", label: "Himalaya Finance" },
  { value: "Himalaya Stone And Suppliers", label: "Himalaya Stone And Suppliers" },
  { value: "Himalaya Trading Co. Op.So.Ltd", label: "Himalaya Trading Co. Op.So.Ltd" },
  { value: "Himanshu Associates", label: "Himanshu Associates" },
  { value: "Himanshu Finance", label: "Himanshu Finance" },
  { value: "Himanshu Motors", label: "Himanshu Motors" },
  { value: "Himashu Finance", label: "Himashu Finance" },
  { value: "Himatnagar Nagarik Sahakari Bank Ltd.", label: "Himatnagar Nagarik Sahakari Bank Ltd." },
  { value: "Himatsingka Motors Works Ltd", label: "Himatsingka Motors Works Ltd" },
  { value: "Himgiri Hire Purchase Ltd.", label: "Himgiri Hire Purchase Ltd." },
  { value: "Himland Fin. & Leasing Ltd.", label: "Himland Fin. & Leasing Ltd." },
  { value: "Himmat Singka Brothers", label: "Himmat Singka Brothers" },
  { value: "Hina Financier", label: "Hina Financier" },
  { value: "Hina Shahin Co-Operative Urban Bank Ltd", label: "Hina Shahin Co-Operative Urban Bank Ltd" },
  { value: "Hind Finance Corporation", label: "Hind Finance Corporation" },
  { value: "Hindhu Auto Finance", label: "Hindhu Auto Finance" },
  { value: "Hindmata Nagri Shahkari Pathsanstha Maryadit", label: "Hindmata Nagri Shahkari Pathsanstha Maryadit" },
  { value: "Hindu Nag.Sah.Bank Ltd., Indore", label: "Hindu Nag.Sah.Bank Ltd., Indore" },
  { value: "Hindu State Co-Op. Bank", label: "Hindu State Co-Op. Bank" },
  { value: "Hinduja Leyland Finance P Ltd", label: "Hinduja Leyland Finance P Ltd" },
  { value: "Hindus Fan Fin Co", label: "Hindus Fan Fin Co" },
  { value: "Hindustan Aeronautics Limited", label: "Hindustan Aeronautics Limited" },
  { value: "Hindustan Antibiotics Ltd.", label: "Hindustan Antibiotics Ltd." },
  { value: "Hindustan Auto Finance Limited", label: "Hindustan Auto Finance Limited" },
  { value: "Hindustan Autohouse", label: "Hindustan Autohouse" },
  { value: "Hindustan Co Op Credit Soc Ltd", label: "Hindustan Co Op Credit Soc Ltd" },
  { value: "Hindustan Commercial Investment Trust Ltd.", label: "Hindustan Commercial Investment Trust Ltd." },
  { value: "Hindustan Copper Ltd.", label: "Hindustan Copper Ltd." },
  { value: "Hindustan Fertilizers Crop. Ltd.", label: "Hindustan Fertilizers Crop. Ltd." },
  { value: "Hindustan Fluorocarbon Ltd.", label: "Hindustan Fluorocarbon Ltd." },
  { value: "Hindustan Insecticides Ltd.", label: "Hindustan Insecticides Ltd." },
  { value: "Hindustan Latex Ltd.", label: "Hindustan Latex Ltd." },
  { value: "Hindustan Leyland Finance", label: "Hindustan Leyland Finance" },
  { value: "Hindustan Liver Corporation Ltd.", label: "Hindustan Liver Corporation Ltd." },
  { value: "Hindustan Newsprint Ltd.", label: "Hindustan Newsprint Ltd." },
  { value: "Hindustan Organic Chemicals Ltd.", label: "Hindustan Organic Chemicals Ltd." },
  { value: "Hindustan Paper Corporation Ltd.", label: "Hindustan Paper Corporation Ltd." },
  { value: "Hindustan Petroleum Corporation Ltd.", label: "Hindustan Petroleum Corporation Ltd." },
  { value: "Hindustan Petrolium Ltd.", label: "Hindustan Petrolium Ltd." },
  { value: "Hindustan Photo Films Mfg Crop. Ltd.", label: "Hindustan Photo Films Mfg Crop. Ltd." },
  { value: "Hindustan Prefab Ltd.", label: "Hindustan Prefab Ltd." },
  { value: "Hindustan Salts Ltd.", label: "Hindustan Salts Ltd." },
  { value: "Hindustan Shipyards Ltd.", label: "Hindustan Shipyards Ltd." },
  { value: "Hindustan Steelworks Construction Co. Ltd.", label: "Hindustan Steelworks Construction Co. Ltd." },
  { value: "Hindustan Teleprinters Ltd.", label: "Hindustan Teleprinters Ltd." },
  { value: "Hindustan Vegetables Oils Crop. Ltd.", label: "Hindustan Vegetables Oils Crop. Ltd." },
  { value: "Hindustan Wires Ltd", label: "Hindustan Wires Ltd" },
  { value: "Hindustan Zinc Ltd.", label: "Hindustan Zinc Ltd." },
  { value: "Hindusthan Co-Op Bank Ltd", label: "Hindusthan Co-Op Bank Ltd" },
  { value: "Hindusthan Credit Corporation.", label: "Hindusthan Credit Corporation." },
  { value: "Hindusthan Petro Corp Ltd", label: "Hindusthan Petro Corp Ltd" },
  { value: "Hinganghat Nagari", label: "Hinganghat Nagari" },
  { value: "Hinglajmata Nagri Sah. Pat. Sans. Mry", label: "Hinglajmata Nagri Sah. Pat. Sans. Mry" },
  { value: "Hinjawadi Nagari Sahakari Path San Maryadit", label: "Hinjawadi Nagari Sahakari Path San Maryadit" },
  { value: "Hira Enterprise", label: "Hira Enterprise" },
  { value: "Hira Hire Purchase", label: "Hira Hire Purchase" },
  { value: "Hira Sugar Employees Co-Op Credit Bank Ltd", label: "Hira Sugar Employees Co-Op Credit Bank Ltd" },
  { value: "Hirakhadi Group Service Sahakari Mandali", label: "Hirakhadi Group Service Sahakari Mandali" },
  { value: "Hiran Finance Co", label: "Hiran Finance Co" },
  { value: "Hire Purchase Saraswati Sugar Mill Ltd.", label: "Hire Purchase Saraswati Sugar Mill Ltd." },
  { value: "Hire Purchase Shiva Hire Lease Ltd", label: "Hire Purchase Shiva Hire Lease Ltd" },
  { value: "Hirebagewadi Raiytha Seva Sahakari Bank Niyamit", label: "Hirebagewadi Raiytha Seva Sahakari Bank Niyamit" },
  { value: "Hirekerur Urban Co-Op Bank Ltd", label: "Hirekerur Urban Co-Op Bank Ltd" },
  { value: "Hiren Motors", label: "Hiren Motors" },
  { value: "Hiriyur Urban Co-Op Bank Ltd", label: "Hiriyur Urban Co-Op Bank Ltd" },
  { value: "Hisar-Sirsa Kshetriya Gramin Bank", label: "Hisar-Sirsa Kshetriya Gramin Bank" },
  { value: "Hissar District Central Co-Operative Bank Ltd.", label: "Hissar District Central Co-Operative Bank Ltd." },
  { value: "Hitesh Amrutlal Chhadva", label: "Hitesh Amrutlal Chhadva" },
  { value: "Hitesh Enterprise", label: "Hitesh Enterprise" },
  { value: "Hitesh Finance", label: "Hitesh Finance" },
  { value: "Hitin Motor & General Finance Company Ltd", label: "Hitin Motor & General Finance Company Ltd" },
  { value: "Hlrdc", label: "Hlrdc" },
  { value: "Hlshantilal Jain Financiers", label: "Hlshantilal Jain Financiers" },
  { value: "Hmw Ltd", label: "Hmw Ltd" },
  { value: "Hole Hucheshwar Co-Op Credit Society Ltd", label: "Hole Hucheshwar Co-Op Credit Society Ltd" },
  { value: "Holy Grace Foundation", label: "Holy Grace Foundation" },
  { value: "Home Shine Hire Purchasing Co Ltd", label: "Home Shine Hire Purchasing Co Ltd" },
  { value: "Homi Bhabha Centre For Science Education", label: "Homi Bhabha Centre For Science Education" },
  { value: "Honavar Urban Co-Op Bank Ltd", label: "Honavar Urban Co-Op Bank Ltd" },
  { value: "Honda Hands India Ltd", label: "Honda Hands India Ltd" },
  { value: "Honda Motorcycle & Scooter Pvt. Ltd.", label: "Honda Motorcycle & Scooter Pvt. Ltd." },
  { value: "Honda Siel Cars India Ltd", label: "Honda Siel Cars India Ltd" },
  { value: "Honey Portfolio (P) Ltd", label: "Honey Portfolio (P) Ltd" },
  { value: "Hongkong Shanghai Bkg.Corp.", label: "Hongkong Shanghai Bkg.Corp." },
  { value: "Honhar Invetment Ltd", label: "Honhar Invetment Ltd" },
  { value: "Honnavally Rythara Seva Sah B L", label: "Honnavally Rythara Seva Sah B L" },
  { value: "Hooghly Coopardb Ltd", label: "Hooghly Coopardb Ltd" },
  { value: "Hooghly District Central Co-Op Bank Ltd.", label: "Hooghly District Central Co-Op Bank Ltd." },
  { value: "Hooghly District Central Co-Op. Bank Ltd", label: "Hooghly District Central Co-Op. Bank Ltd" },
  { value: "Hooghly District Central Co-Operative Bank Ltd.", label: "Hooghly District Central Co-Operative Bank Ltd." },
  { value: "The Hoogly Co Op Credit Bank Ltd", label: "The Hoogly Co Op Credit Bank Ltd" },
  { value: "Hoogly Dock And Port Engineers Ltd.", label: "Hoogly Dock And Port Engineers Ltd." },
  { value: "Hoogly Printing Co. Ltd", label: "Hoogly Printing Co. Ltd" },
  { value: "Hoshiarpur Central Cooperative Bank", label: "Hoshiarpur Central Cooperative Bank" },
  { value: "Hoshiarpur District Central Co-Operative Bank Ltd.", label: "Hoshiarpur District Central Co-Operative Bank Ltd." },
  { value: "Hospet City Co-Op Bank Ltd", label: "Hospet City Co-Op Bank Ltd" },
  { value: "Hospital Services Consultancy Corporation (India) Ltd.", label: "Hospital Services Consultancy Corporation (India) Ltd." },
  { value: "Hotel & Sambandhit Vyavsayik Nagari Sahakari Patsanstha Maryadit", label: "Hotel & Sambandhit Vyavsayik Nagari Sahakari Patsanstha Maryadit" },
  { value: "Hotel Corporation Of India Ltd.", label: "Hotel Corporation Of India Ltd." },
  { value: "Hotel Industries Co-Op Bank Ltd", label: "Hotel Industries Co-Op Bank Ltd" },
  { value: "Hotel Shreemaya", label: "Hotel Shreemaya" },
  { value: "Housing And Urban Development Corporation Ltd.", label: "Housing And Urban Development Corporation Ltd." },
  { value: "Housing Board", label: "Housing Board" },
  { value: "Housing Development Finance Corporation Ltd", label: "Housing Development Finance Corporation Ltd" },
  { value: "Howra Gramin Bank Ltd", label: "Howra Gramin Bank Ltd" },
  { value: "Howrah District Central Co-Operative Bank Ltd.", label: "Howrah District Central Co-Operative Bank Ltd." },
  { value: "Hoysala Credit Souharda Cooperative Ltd.", label: "Hoysala Credit Souharda Cooperative Ltd." },
  { value: "Hp Dugar Finance", label: "Hp Dugar Finance" },
  { value: "Hp Financial Services India Pvt. Ltd.", label: "Hp Financial Services India Pvt. Ltd." },
  { value: "Hp M/S Manjunath Finance And Leasing Co", label: "Hp M/S Manjunath Finance And Leasing Co" },
  { value: "Hp State Co-Op & Rural Devlopment Bank Ltd.", label: "Hp State Co-Op & Rural Devlopment Bank Ltd." },
  { value: "Hp State Co-Op Bank Ltd", label: "Hp State Co-Op Bank Ltd" },
  { value: "Hpcl", label: "Hpcl" },
  { value: "Hpgcl", label: "Hpgcl" },
  { value: "Hr Financier", label: "Hr Financier" },
  { value: "Hrc Advisory", label: "Hrc Advisory" },
  { value: "Hridhi Sidhi Vinayak Bigar Sheti Gramin Sahakari Pathsanstha", label: "Hridhi Sidhi Vinayak Bigar Sheti Gramin Sahakari Pathsanstha" },
  { value: "Hsbc Bank Ltd", label: "Hsbc Bank Ltd" },
  { value: "Hsbc Banking Corp", label: "Hsbc Banking Corp" },
  { value: "Hsbc Cash Deposit", label: "Hsbc Cash Deposit" },
  { value: "Hsbc Private Equity Management Mauritius Limited", label: "Hsbc Private Equity Management Mauritius Limited" },
  { value: "Hscc India Ltd", label: "Hscc India Ltd" },
  { value: "Hsfdc", label: "Hsfdc" },
  { value: "Hsfdc Rewari", label: "Hsfdc Rewari" },
  { value: "Hubli Dharwad Urban Co-Op Bank Ltd", label: "Hubli Dharwad Urban Co-Op Bank Ltd" },
  { value: "Hubli Saraku Saganikedarar Sahakari Pattina Sangha Nyamit", label: "Hubli Saraku Saganikedarar Sahakari Pattina Sangha Nyamit" },
  { value: "Hudco", label: "Hudco" },
  { value: "Hukkeri Urban Co-Op Bank Ltd", label: "Hukkeri Urban Co-Op Bank Ltd" },
  { value: "Hukmichand & Co", label: "Hukmichand & Co" },
  { value: "Hulash Finance Co", label: "Hulash Finance Co" },
  { value: "Human Welfare Kuries&Loans", label: "Human Welfare Kuries&Loans" },
  { value: "Humtsman Inter (I) Pvt Ltd", label: "Humtsman Inter (I) Pvt Ltd" },
  { value: "Hunnur Souhard Co-Op Society", label: "Hunnur Souhard Co-Op Society" },
  { value: "Hunnur Souhard Credit Sahakari Nymt, Hunnur", label: "Hunnur Souhard Credit Sahakari Nymt, Hunnur" },
  { value: "Hunnur Souhardh Credit Sahakari Niyamit", label: "Hunnur Souhardh Credit Sahakari Niyamit" },
  { value: "Huntsman Intemational In Pvt Ltd", label: "Huntsman Intemational In Pvt Ltd" },
  { value: "Hussain Auto Finance Pvt Ltd", label: "Hussain Auto Finance Pvt Ltd" },
  { value: "Hutatma Sahakari Bank Ltd", label: "Hutatma Sahakari Bank Ltd" },
  { value: "Hutatma Sahakari Bank Ltd.", label: "Hutatma Sahakari Bank Ltd." },
  { value: "Huthur Hubali S.F.S.C.Bank Ltd", label: "Huthur Hubali S.F.S.C.Bank Ltd" },
  { value: "Hyd Mutual Benefit Soc", label: "Hyd Mutual Benefit Soc" },
  { value: "Hyderabad Dist. Co-Op Central Bank Ltd.", label: "Hyderabad Dist. Co-Op Central Bank Ltd." },
  { value: "Hypothecation T.C.L", label: "Hypothecation T.C.L" },
  { value: "Hypothication  Fullerton India Credit Nco Ltd", label: "Hypothication  Fullerton India Credit Nco Ltd" },
  { value: "Hyson Motors (P) Ltd", label: "Hyson Motors (P) Ltd" },
  { value: "Hyundai Motor India Ltd", label: "Hyundai Motor India Ltd" },
  { value: "I C D S Ltd", label: "I C D S Ltd" },
  { value: "I C Finance And Investments", label: "I C Finance And Investments" },
  { value: "I G Finance", label: "I G Finance" },
  { value: "I-Matrix Insurance Solutions Pvt Ltd", label: "I-Matrix Insurance Solutions Pvt Ltd" },
  { value: "I. B. L", label: "I. B. L" },
  { value: "I.A.F.L. Hoshiarpur", label: "I.A.F.L. Hoshiarpur" },
  { value: "Ibl", label: "Ibl" },
  { value: "Ibm Daksh", label: "Ibm Daksh" },
  { value: "Ibp Co. Ltd.", label: "Ibp Co. Ltd." },
  { value: "Ibp.Co.Ltd.", label: "Ibp.Co.Ltd." },
  { value: "Icc Finance", label: "Icc Finance" },
  { value: "Icf Finance Ltd", label: "Icf Finance Ltd" },
  { value: "Icf Ventures Private Limited", label: "Icf Ventures Private Limited" },
  { value: "Ichalkarangi Mahila Sahakari Bank Ltd", label: "Ichalkarangi Mahila Sahakari Bank Ltd" },
  { value: "Ichalkarangi Merchants Co-Op Bank Ltd", label: "Ichalkarangi Merchants Co-Op Bank Ltd" },
  { value: "Ichalkarangi Urban Co-Op Bank Ltd", label: "Ichalkarangi Urban Co-Op Bank Ltd" },
  { value: "Ichalkaranji Co-Op Bank", label: "Ichalkaranji Co-Op Bank" },
  { value: "Ichalkaranji Janata Sahakari Bank Ltd.", label: "Ichalkaranji Janata Sahakari Bank Ltd." },
  { value: "Ichalkaranji Janata Sahakari Bank Ltd., Kolhapur", label: "Ichalkaranji Janata Sahakari Bank Ltd., Kolhapur" },
  { value: "Ichalkaranji Janta Co-Op Bank Ltd", label: "Ichalkaranji Janta Co-Op Bank Ltd" },
  { value: "Ichalkaranji Mahila Sahakari Bank Ltd.", label: "Ichalkaranji Mahila Sahakari Bank Ltd." },
  { value: "Ichalkaranji Sahkari Bank", label: "Ichalkaranji Sahkari Bank" },
  { value: "Ichalkaranji Vyapari Sahakari Pathsanstha Ltd", label: "Ichalkaranji Vyapari Sahakari Pathsanstha Ltd" },
  { value: "Icici Bank", label: "Icici Bank" },
  { value: "Icici Bank Ltd - Bangalore", label: "Icici Bank Ltd - Bangalore" },
  { value: "Icici Bank Ltd R.V Road Mysore", label: "Icici Bank Ltd R.V Road Mysore" },
  { value: "Icici Bank Ltd., Mumbai", label: "Icici Bank Ltd., Mumbai" },
  { value: "Icici Bank Ramavilas Road Mysore", label: "Icici Bank Ramavilas Road Mysore" },
  { value: "Icici Banking Corporation Ltd", label: "Icici Banking Corporation Ltd" },
  { value: "Icici Banking Corporation Ltd", label: "Icici Banking Corporation Ltd" },
  { value: "Icici Finance", label: "Icici Finance" },
  { value: "Icici Financials Ltd", label: "Icici Financials Ltd" },
  { value: "Icici Home Finance Ltd.", label: "Icici Home Finance Ltd." },
  { value: "Icici Lombard", label: "Icici Lombard" },
  { value: "Icici Lombard Insurance", label: "Icici Lombard Insurance" },
  { value: "Icici Ltd., Mumbai", label: "Icici Ltd., Mumbai" },
  { value: "Icici Securities And Finance Company Limited", label: "Icici Securities And Finance Company Limited" },
  { value: "Icici Two Wheeler Finance", label: "Icici Two Wheeler Finance" },
  { value: "Icici Two Wheels Finance", label: "Icici Two Wheels Finance" },
  { value: "Icici Venture Funds Management Company Limited", label: "Icici Venture Funds Management Company Limited" },
  { value: "Icici West Bengal Infrastructure Development Corporation", label: "Icici West Bengal Infrastructure Development Corporation" },
  { value: "Icra Ltd", label: "Icra Ltd" },
  { value: "Idbi Bank Ltd.", label: "Idbi Bank Ltd." },
  { value: "Idbi Bank,Vyahad", label: "Idbi Bank,Vyahad" },
  { value: "Idbi Finance Ltd", label: "Idbi Finance Ltd" },
  { value: "Idbi Home Finance Ltd", label: "Idbi Home Finance Ltd" },
  { value: "Idbi/Idbi-Deb.Trustees", label: "Idbi/Idbi-Deb.Trustees" },
  { value: "Iddanth Syndicate", label: "Iddanth Syndicate" },
  { value: "Ideal Financing Corporation Ltd", label: "Ideal Financing Corporation Ltd" },
  { value: "Idmc Bank Ltd", label: "Idmc Bank Ltd" },
  { value: "Idukki District Central Co-Operative Bank Ltd.", label: "Idukki District Central Co-Operative Bank Ltd." },
  { value: "If Finance Limited", label: "If Finance Limited" },
  { value: "Ifb Venture Capital Finance Limited", label: "Ifb Venture Capital Finance Limited" },
  { value: "Ifc Washington", label: "Ifc Washington" },
  { value: "Ifci Limited(The Industrial Finance Corporation Of India Ltd)", label: "Ifci Limited(The Industrial Finance Corporation Of India Ltd)" },
  { value: "Ifcl & Uti Bank", label: "Ifcl & Uti Bank" },
  { value: "Ifco Ltd", label: "Ifco Ltd" },
  { value: "Iffco Aonla", label: "Iffco Aonla" },
  { value: "Iffco Ltd", label: "Iffco Ltd" },
  { value: "Iffco Tokyo", label: "Iffco Tokyo" },
  { value: "Iffco Unit", label: "Iffco Unit" },
  { value: "Ifl Finance Service Ltd", label: "Ifl Finance Service Ltd" },
  { value: "Ig Finance", label: "Ig Finance" },
  { value: "Iit Capital Services Ltd.", label: "Iit Capital Services Ltd." },
  { value: "Ik Finance Ltd", label: "Ik Finance Ltd" },
  { value: "Ikf Finance Limited", label: "Ikf Finance Limited" },
  { value: "Ikf Finance Limited", label: "Ikf Finance Limited" },
  { value: "Il And Fs Investment Managers Ltd.", label: "Il And Fs Investment Managers Ltd." },
  { value: "Il And Fs Transporatation Networks Ltd", label: "Il And Fs Transporatation Networks Ltd" },
  { value: "Il And Fs Venture Corporation Limited", label: "Il And Fs Venture Corporation Limited" },
  { value: "Ilaxiben Janakkumar Modi", label: "Ilaxiben Janakkumar Modi" },
  { value: "Ilfs", label: "Ilfs" },
  { value: "Ilkal Co-Op Bank Ltd", label: "Ilkal Co-Op Bank Ltd" },
  { value: "Ilkal Co-Operative Bank Ltd", label: "Ilkal Co-Operative Bank Ltd" },
  { value: "Ilkal Urban Co-Op Bank Ltd", label: "Ilkal Urban Co-Op Bank Ltd" },
  { value: "Impact Leasing Pvt. Ltd", label: "Impact Leasing Pvt. Ltd" },
  { value: "Imperial Urban Co Operative Ltd", label: "Imperial Urban Co Operative Ltd" },
  { value: "Imperial Urban Co-Op. Bank", label: "Imperial Urban Co-Op. Bank" },
  { value: "Imperial Urban Co-Op. Bank Ltd.", label: "Imperial Urban Co-Op. Bank Ltd." },
  { value: "Imperial Urban Cooperative Bank", label: "Imperial Urban Cooperative Bank" },
  { value: "Imphal Urban Co-Op Bank Ltd", label: "Imphal Urban Co-Op Bank Ltd" },
  { value: "Inchakkundu Services Co-Operative Bank", label: "Inchakkundu Services Co-Operative Bank" },
  { value: "Inchakundu Service Co-Op Bank Ltd", label: "Inchakundu Service Co-Op Bank Ltd" },
  { value: "Income Tax Department", label: "Income Tax Department" },
  { value: "Income Tax Dept Co-Op Bank Ltd", label: "Income Tax Dept Co-Op Bank Ltd" },
  { value: "Ind Bank Merchant Banking Services Ltd", label: "Ind Bank Merchant Banking Services Ltd" },
  { value: "Indapur Urban Co-Op Bank Ltd.", label: "Indapur Urban Co-Op Bank Ltd." },
  { value: "Independence Co-Op Bank Ltd.", label: "Independence Co-Op Bank Ltd." },
  { value: "Index Chits & Finance", label: "Index Chits & Finance" },
  { value: "Index Chits And Finance Ltd", label: "Index Chits And Finance Ltd" },
  { value: "Index Chits", label: "Index Chits" },
  { value: "Indhira Sharmik Mahila Nagarik Bank Ltd", label: "Indhira Sharmik Mahila Nagarik Bank Ltd" },
  { value: "India Auto Sales", label: "India Auto Sales" },
  { value: "India Bulles Finance Ltd", label: "India Bulles Finance Ltd" },
  { value: "India Bulls", label: "India Bulls" },
  { value: "India Bulls Bank Ltd", label: "India Bulls Bank Ltd" },
  { value: "India Bulls Credit Finance Ltd", label: "India Bulls Credit Finance Ltd" },
  { value: "India Bulls Credit Service Ltd", label: "India Bulls Credit Service Ltd" },
  { value: "India Bulls Credit Services Ltd", label: "India Bulls Credit Services Ltd" },
  { value: "India Bulls Finance Ltd.", label: "India Bulls Finance Ltd." },
  { value: "India Bulls Finance Service Ltd", label: "India Bulls Finance Service Ltd" },
  { value: "India Bulls Financial Services Ltd", label: "India Bulls Financial Services Ltd" },
  { value: "India Cements Capital And Finance Ltd.", label: "India Cements Capital And Finance Ltd." },
  { value: "India Cements Capital Finance Limited", label: "India Cements Capital Finance Limited" },
  { value: "India Development Information Network", label: "India Development Information Network" },
  { value: "India Fruits Finance", label: "India Fruits Finance" },
  { value: "India Garage", label: "India Garage" },
  { value: "India Infoline Insurance Brokers Ltd", label: "India Infoline Insurance Brokers Ltd" },
  { value: "India Infoline Investment Services Ltd", label: "India Infoline Investment Services Ltd" },
  { value: "India Infrastructures Finance Company Ltd", label: "India Infrastructures Finance Company Ltd" },
  { value: "India Institute Of Management", label: "India Institute Of Management" },
  { value: "India Lease Development Limited", label: "India Lease Development Limited" },
  { value: "India Meteorological Department", label: "India Meteorological Department" },
  { value: "India Overseas Bank", label: "India Overseas Bank" },
  { value: "India Securities Ltd", label: "India Securities Ltd" },
  { value: "India Tourism Dev. Crop. Ltd.", label: "India Tourism Dev. Crop. Ltd." },
  { value: "India Trade Promotion Organisation", label: "India Trade Promotion Organisation" },
  { value: "India Trader Promotion Organat", label: "India Trader Promotion Organat" },
  { value: "Indiabulls Financial Credit Co Ltd", label: "Indiabulls Financial Credit Co Ltd" },
  { value: "Indiabulls Financial Credit Services Ltd", label: "Indiabulls Financial Credit Services Ltd" },
  { value: "Indiabulls Financial Services Limited.", label: "Indiabulls Financial Services Limited." },
  { value: "Indian  Overseas Bank", label: "Indian  Overseas Bank" },
  { value: "Indian Additives Ltd.", label: "Indian Additives Ltd." },
  { value: "Indian Agencies Fighting Economic Crimes", label: "Indian Agencies Fighting Economic Crimes" },
  { value: "Indian Agricultural Research Institute", label: "Indian Agricultural Research Institute" },
  { value: "Indian Agricultural Statistics Research Institute", label: "Indian Agricultural Statistics Research Institute" },
  { value: "Indian Air Force", label: "Indian Air Force" },
  { value: "Indian Airlines Ltd.", label: "Indian Airlines Ltd." },
  { value: "Indian Armed Forces", label: "Indian Armed Forces" },
  { value: "Indian Asian", label: "Indian Asian" },
  { value: "Indian Association For The Cultivation Of Science", label: "Indian Association For The Cultivation Of Science" },
  { value: "Indian Association Of Social Science Institutions", label: "Indian Association Of Social Science Institutions" },
  { value: "Indian Bank - Chithara", label: "Indian Bank - Chithara" },
  { value: "Indian Bank And Its Consortiums Leaders", label: "Indian Bank And Its Consortiums Leaders" },
  { value: "Indian Bank,", label: "Indian Bank," },
  { value: "Indian Bank`S Association", label: "Indian Bank`S Association" },
  { value: "Indian Bureau Of Mines", label: "Indian Bureau Of Mines" },
  { value: "Indian Cements Capital & Finance Ltd", label: "Indian Cements Capital & Finance Ltd" },
  { value: "Indian Co-Operative Development Bank Ltd", label: "Indian Co-Operative Development Bank Ltd" },
  { value: "Indian Corporation Bank", label: "Indian Corporation Bank" },
  { value: "Indian Council For Cultural Relations", label: "Indian Council For Cultural Relations" },
  { value: "Indian Council Of Agriculture Research", label: "Indian Council Of Agriculture Research" },
  { value: "Indian Council Of Medical Research", label: "Indian Council Of Medical Research" },
  { value: "Indian Council Of Philosophical Research", label: "Indian Council Of Philosophical Research" },
  { value: "Indian Courts", label: "Indian Courts" },
  { value: "Indian Credit Co-Op Society Ltd In Rpas", label: "Indian Credit Co-Op Society Ltd In Rpas" },
  { value: "Indian Diamond Institute", label: "Indian Diamond Institute" },
  { value: "Indian Drugs And Pharmaceuticals Ltd.", label: "Indian Drugs And Pharmaceuticals Ltd." },
  { value: "Indian Farmers Fertilisers Cooperative Limited", label: "Indian Farmers Fertilisers Cooperative Limited" },
  { value: "Indian Finance And Factors Ltd.", label: "Indian Finance And Factors Ltd." },
  { value: "Indian Hire Purchase", label: "Indian Hire Purchase" },
  { value: "Indian Institure Of Science", label: "Indian Institure Of Science" },
  { value: "Indian Institure Of Technology", label: "Indian Institure Of Technology" },
  { value: "Indian Institute Of Advanced Study", label: "Indian Institute Of Advanced Study" },
  { value: "Indian Institute Of Chemical Biology", label: "Indian Institute Of Chemical Biology" },
  { value: "Indian Institute Of Chemical Technology", label: "Indian Institute Of Chemical Technology" },
  { value: "Indian Institute Of Coal Management", label: "Indian Institute Of Coal Management" },
  { value: "Indian Institute Of Entrepreneurship", label: "Indian Institute Of Entrepreneurship" },
  { value: "Indian Institute Of Foreign Trade", label: "Indian Institute Of Foreign Trade" },
  { value: "Indian Institute Of Forest Management (Iifm)", label: "Indian Institute Of Forest Management (Iifm)" },
  { value: "Indian Institute Of Horticultural Research", label: "Indian Institute Of Horticultural Research" },
  { value: "Indian Institute Of Information Technology", label: "Indian Institute Of Information Technology" },
  { value: "Indian Institute Of Mass Communication", label: "Indian Institute Of Mass Communication" },
  { value: "Indian Institute Of Packaging", label: "Indian Institute Of Packaging" },
  { value: "Indian Institute Of Petroleum", label: "Indian Institute Of Petroleum" },
  { value: "Indian Institute Of Pulses Research", label: "Indian Institute Of Pulses Research" },
  { value: "Indian Institute Of Quality Management", label: "Indian Institute Of Quality Management" },
  { value: "Indian Institute Of Social Welfare And Business Management", label: "Indian Institute Of Social Welfare And Business Management" },
  { value: "Indian Institute Of Soil Science", label: "Indian Institute Of Soil Science" },
  { value: "Indian Institute Of Vegetable Research", label: "Indian Institute Of Vegetable Research" },
  { value: "Indian Investment Centre", label: "Indian Investment Centre" },
  { value: "Indian Iron And Steel Co. Ltd.", label: "Indian Iron And Steel Co. Ltd." },
  { value: "Indian Jute Industries` Research Association", label: "Indian Jute Industries` Research Association" },
  { value: "Indian Medical Council", label: "Indian Medical Council" },
  { value: "Indian Medicines Pharmaceuticals Corp Ltd.", label: "Indian Medicines Pharmaceuticals Corp Ltd." },
  { value: "Indian Medlars Centre", label: "Indian Medlars Centre" },
  { value: "Indian Mercantile Co-Op. Bank Ltd., Lucknow", label: "Indian Mercantile Co-Op. Bank Ltd., Lucknow" },
  { value: "Indian Mercantile Cooperative Bank Ltd.", label: "Indian Mercantile Cooperative Bank Ltd." },
  { value: "Indian National Scientific Documentation Centre", label: "Indian National Scientific Documentation Centre" },
  { value: "Indian Oil Blending Ltd.", label: "Indian Oil Blending Ltd." },
  { value: "Indian Oil Corporation", label: "Indian Oil Corporation" },
  { value: "Indian Oil Corporation Ltd.", label: "Indian Oil Corporation Ltd." },
  { value: "Indian Overseas Bank,", label: "Indian Overseas Bank," },
  { value: "Indian Petrochemical Corporation Ltd", label: "Indian Petrochemical Corporation Ltd" },
  { value: "Indian Ports Association", label: "Indian Ports Association" },
  { value: "Indian Railway Finance Crop. Ltd.", label: "Indian Railway Finance Crop. Ltd." },
  { value: "Indian Railways", label: "Indian Railways" },
  { value: "Indian Rare Earths Limited", label: "Indian Rare Earths Limited" },
  { value: "Indian Renewable Energy Development Agency Ltd.", label: "Indian Renewable Energy Development Agency Ltd." },
  { value: "Indian Road Const. Corporation Ltd.", label: "Indian Road Const. Corporation Ltd." },
  { value: "Indian School Of Mines", label: "Indian School Of Mines" },
  { value: "Indian Seamless Financial Services Ltd", label: "Indian Seamless Financial Services Ltd" },
  { value: "Indian Society For Medical Statistics", label: "Indian Society For Medical Statistics" },
  { value: "Indian Sources", label: "Indian Sources" },
  { value: "Indian Space Research Organization", label: "Indian Space Research Organization" },
  { value: "Indian Statistical Institute", label: "Indian Statistical Institute" },
  { value: "Indian Telephone Industries Limited", label: "Indian Telephone Industries Limited" },
  { value: "Indian Toners And Developers Ltd", label: "Indian Toners And Developers Ltd" },
  { value: "Indira Bai Financier", label: "Indira Bai Financier" },
  { value: "Indira Gandhi Centre For Atomic Research", label: "Indira Gandhi Centre For Atomic Research" },
  { value: "Indira Gandhi Institute Of Development Research", label: "Indira Gandhi Institute Of Development Research" },
  { value: "Indira Gandhi National Centre For The Arts", label: "Indira Gandhi National Centre For The Arts" },
  { value: "Indira Gandhi Rashtriya Uran Academy", label: "Indira Gandhi Rashtriya Uran Academy" },
  { value: "Indira Gandhi Sahakari Patsanstha", label: "Indira Gandhi Sahakari Patsanstha" },
  { value: "Indira Investments", label: "Indira Investments" },
  { value: "Indira Mahila Sahakari Bank Ltd", label: "Indira Mahila Sahakari Bank Ltd" },
  { value: "Indira Mahila Sahakari Bank Ltd.", label: "Indira Mahila Sahakari Bank Ltd." },
  { value: "Indira Ostwal", label: "Indira Ostwal" },
  { value: "Indira Sahakari Bank Limited", label: "Indira Sahakari Bank Limited" },
  { value: "Indira Shramik Mahila Nagari Sahakari Bank Niy.Solapur", label: "Indira Shramik Mahila Nagari Sahakari Bank Niy.Solapur" },
  { value: "Indirakumari S", label: "Indirakumari S" },
  { value: "Indiyar Varass Bank", label: "Indiyar Varass Bank" },
  { value: "Indland Bank", label: "Indland Bank" },
  { value: "Indo Asian Finance", label: "Indo Asian Finance" },
  { value: "Indo Asian Finance Ltd.", label: "Indo Asian Finance Ltd." },
  { value: "Indo Tibetan Border Police", label: "Indo Tibetan Border Police" },
  { value: "Indocent Bank Ltd", label: "Indocent Bank Ltd" },
  { value: "Indocount Finance Ltd.", label: "Indocount Finance Ltd." },
  { value: "Indore Cloth Market Co-Op Bank Ltd, Indore", label: "Indore Cloth Market Co-Op Bank Ltd, Indore" },
  { value: "Indore Cloth Market Co-Op. Bank Ltd.", label: "Indore Cloth Market Co-Op. Bank Ltd." },
  { value: "Indore Paraspara Sah. Bank Ltd., Indore", label: "Indore Paraspara Sah. Bank Ltd., Indore" },
  { value: "Indore Paraspara Sahakari Bank Ltd.", label: "Indore Paraspara Sahakari Bank Ltd." },
  { value: "Indore Premier Co-Op Bank Ltd., Indore", label: "Indore Premier Co-Op Bank Ltd., Indore" },
  { value: "Indore Premier Co-Op.Bank Ltd.", label: "Indore Premier Co-Op.Bank Ltd." },
  { value: "Indore Swayam Siddh Mahila Co-Op Bank Ltd", label: "Indore Swayam Siddh Mahila Co-Op Bank Ltd" },
  { value: "Indore Swayamsiddh Mahila Co-Op Bank Ltd", label: "Indore Swayamsiddh Mahila Co-Op Bank Ltd" },
  { value: "Indore Ujjai Kshetriya Gramin Bank Ltd", label: "Indore Ujjai Kshetriya Gramin Bank Ltd" },
  { value: "Indore Ujjain Kshetriya Gramin Bank Ltd", label: "Indore Ujjain Kshetriya Gramin Bank Ltd" },
  { value: "Indore-Ujjain Kshetriya Gramin Bank", label: "Indore-Ujjain Kshetriya Gramin Bank" },
  { value: "Indosant Co Ltd", label: "Indosant Co Ltd" },
  { value: "Indosinl Bank Ltd", label: "Indosinl Bank Ltd" },
  { value: "Indra Devi Mehta", label: "Indra Devi Mehta" },
  { value: "Indra Finance", label: "Indra Finance" },
  { value: "Indra Investments", label: "Indra Investments" },
  { value: "Indrajeet M Vernekar", label: "Indrajeet M Vernekar" },
  { value: "Indrakhila Finance Co Ltd", label: "Indrakhila Finance Co Ltd" },
  { value: "Indraprastha Hire Purchase And Investments", label: "Indraprastha Hire Purchase And Investments" },
  { value: "Indraprastha Hp& Leasing Ltd", label: "Indraprastha Hp& Leasing Ltd" },
  { value: "Indraprastha Sah. Bank Ltd.", label: "Indraprastha Sah. Bank Ltd." },
  { value: "Indrayani Co-Op. Bank Ltd.", label: "Indrayani Co-Op. Bank Ltd." },
  { value: "Indrayani Mahila G B S S", label: "Indrayani Mahila G B S S" },
  { value: "Indu Auto Finance", label: "Indu Auto Finance" },
  { value: "Indus Bank", label: "Indus Bank" },
  { value: "Indus Bank - Karwar", label: "Indus Bank - Karwar" },
  { value: "Indus Bank Ltd", label: "Indus Bank Ltd" },
  { value: "Indus Ind Bank Ltd", label: "Indus Ind Bank Ltd" },
  { value: "Indus Motors Co P Ltd", label: "Indus Motors Co P Ltd" },
  { value: "Indus Venture Management Limited", label: "Indus Venture Management Limited" },
  { value: "Indus-Ind Bank Ltd.", label: "Indus-Ind Bank Ltd." },
  { value: "Indus-Ind Leasing Ltd.", label: "Indus-Ind Leasing Ltd." },
  { value: "Indusand Bank Ltd", label: "Indusand Bank Ltd" },
  { value: "Indusian Bank Ltd", label: "Indusian Bank Ltd" },
  { value: "Indusind Bank Finance P Ltd.", label: "Indusind Bank Finance P Ltd." },
  { value: "Indusind Bank Limited", label: "Indusind Bank Limited" },
  { value: "Indusind Bank Ltd - Karwar", label: "Indusind Bank Ltd - Karwar" },
  { value: "Indusind Bank Ltd -Pune", label: "Indusind Bank Ltd -Pune" },
  { value: "Indusind Finance Services Ltd", label: "Indusind Finance Services Ltd" },
  { value: "Indusint Ind Financer", label: "Indusint Ind Financer" },
  { value: "Indusland Bank", label: "Indusland Bank" },
  { value: "Indusland Financial Services", label: "Indusland Financial Services" },
  { value: "Indust Ind Finance", label: "Indust Ind Finance" },
  { value: "Industrial Co-Op Bank Ltd.", label: "Industrial Co-Op Bank Ltd." },
  { value: "Industrial Credit And Development Syndicate Bank Ltd", label: "Industrial Credit And Development Syndicate Bank Ltd" },
  { value: "Industrial Credit And Investment Corporation Of India", label: "Industrial Credit And Investment Corporation Of India" },
  { value: "Industrial Development Bank Of India", label: "Industrial Development Bank Of India" },
  { value: "Industrial Development Bank Of India", label: "Industrial Development Bank Of India" },
  { value: "Industrial Devlopment Bank Of India Ltd", label: "Industrial Devlopment Bank Of India Ltd" },
  { value: "Industrial Finance Corporation Limited", label: "Industrial Finance Corporation Limited" },
  { value: "Industrial Finance Crop. Of India", label: "Industrial Finance Crop. Of India" },
  { value: "Industrial Financial Corporation Of India", label: "Industrial Financial Corporation Of India" },
  { value: "Industrial Investment Bank Of India Ltd.", label: "Industrial Investment Bank Of India Ltd." },
  { value: "Industrial Reconstruction Bank Of India", label: "Industrial Reconstruction Bank Of India" },
  { value: "Industrial Toxicology Research Centre", label: "Industrial Toxicology Research Centre" },
  { value: "Industrial Venture Capital Limited", label: "Industrial Venture Capital Limited" },
  { value: "Infomedia India Ltd", label: "Infomedia India Ltd" },
  { value: "Information And Library Network Centre", label: "Information And Library Network Centre" },
  { value: "Infosys Technologies Ltd", label: "Infosys Technologies Ltd" },
  { value: "Infotech Trustiship Services Ltd.", label: "Infotech Trustiship Services Ltd." },
  { value: "Infrastructure Finance Ltd.", label: "Infrastructure Finance Ltd." },
  { value: "Infrastructure Finance Ltd. Consortium Of Bankerl", label: "Infrastructure Finance Ltd. Consortium Of Bankerl" },
  { value: "Infrastructure Leasing & Financial Services Ltd", label: "Infrastructure Leasing & Financial Services Ltd" },
  { value: "Infrastructure Leasing And Financial Services Ltd", label: "Infrastructure Leasing And Financial Services Ltd" },
  { value: "Infretructure Les Finance Service Ltd", label: "Infretructure Les Finance Service Ltd" },
  { value: "Ing Vysya Financial Service Pvt Ltd", label: "Ing Vysya Financial Service Pvt Ltd" },
  { value: "Ing Vysya Life Insurance", label: "Ing Vysya Life Insurance" },
  { value: "Ingle Automobile Finance", label: "Ingle Automobile Finance" },
  { value: "Iniyamurugan Finance", label: "Iniyamurugan Finance" },
  { value: "Innar Vyapari Sahakari Bank Ltd", label: "Innar Vyapari Sahakari Bank Ltd" },
  { value: "Inniya Murugan Investments", label: "Inniya Murugan Investments" },
  { value: "Innova Securities Ltd", label: "Innova Securities Ltd" },
  { value: "Innovative Co-Operative Urban Bank Ltd.", label: "Innovative Co-Operative Urban Bank Ltd." },
  { value: "Insol Ins. Solution", label: "Insol Ins. Solution" },
  { value: "Instanex Ins.Brokers Pvt. Ltd.", label: "Instanex Ins.Brokers Pvt. Ltd." },
  { value: "Instatment Supply 46", label: "Instatment Supply 46" },
  { value: "Institue For Development & Research In Banking Technologies", label: "Institue For Development & Research In Banking Technologies" },
  { value: "Institute For Plasma Research", label: "Institute For Plasma Research" },
  { value: "Institute For Studies In Industrial Development", label: "Institute For Studies In Industrial Development" },
  { value: "Institute Of Chartered Accountants Of India", label: "Institute Of Chartered Accountants Of India" },
  { value: "Institute Of Child Health And Hospital For Children", label: "Institute Of Child Health And Hospital For Children" },
  { value: "Institute Of Company Secretaries Of India", label: "Institute Of Company Secretaries Of India" },
  { value: "Institute Of Cost And Works Accountants Of India", label: "Institute Of Cost And Works Accountants Of India" },
  { value: "Institute Of Economic Growth", label: "Institute Of Economic Growth" },
  { value: "Institute Of Himalayan Bioresource Technology", label: "Institute Of Himalayan Bioresource Technology" },
  { value: "Institute Of Hotel Management, Catering Technology And Applied Nutrition", label: "Institute Of Hotel Management, Catering Technology And Applied Nutrition" },
  { value: "Institute Of Judicial Training And Research", label: "Institute Of Judicial Training And Research" },
  { value: "Institute Of Mathematical Sciences", label: "Institute Of Mathematical Sciences" },
  { value: "Institute Of Physics", label: "Institute Of Physics" },
  { value: "Institute Of Public Administration", label: "Institute Of Public Administration" },
  { value: "Institute Of Rural Management", label: "Institute Of Rural Management" },
  { value: "Institute Of Secretariat Training And Management", label: "Institute Of Secretariat Training And Management" },
  { value: "Instrumentation Ltd.", label: "Instrumentation Ltd." },
  { value: "Insund Bank Ltd", label: "Insund Bank Ltd" },
  { value: "Insurance Corporation Of India", label: "Insurance Corporation Of India" },
  { value: "Insurance Regulatory And Development Authority", label: "Insurance Regulatory And Development Authority" },
  { value: "Integra Capital Management Limited", label: "Integra Capital Management Limited" },
  { value: "Integral Urban Co-Op Bank Ltd.", label: "Integral Urban Co-Op Bank Ltd." },
  { value: "Integrated Finance Co Ltd.", label: "Integrated Finance Co Ltd." },
  { value: "Integrated Fisheries Project", label: "Integrated Fisheries Project" },
  { value: "Inter Product Bank", label: "Inter Product Bank" },
  { value: "Inter State Finance Limited", label: "Inter State Finance Limited" },
  { value: "Interact Leasing And Finance Ltd.", label: "Interact Leasing And Finance Ltd." },
  { value: "Intergrated Child Development Services", label: "Intergrated Child Development Services" },
  { value: "International Auto Fianance Ltd", label: "International Auto Fianance Ltd" },
  { value: "International Auto Finance Ltd.", label: "International Auto Finance Ltd." },
  { value: "International Auto Trac. Ltd.", label: "International Auto Trac. Ltd." },
  { value: "International Autotrac Finance Ltd", label: "International Autotrac Finance Ltd" },
  { value: "International Cars & Motors Ltd", label: "International Cars & Motors Ltd" },
  { value: "International Institute For Population Sciences", label: "International Institute For Population Sciences" },
  { value: "International Venture Capital Management Ltd", label: "International Venture Capital Management Ltd" },
  { value: "Intervet India Pvt Ltd", label: "Intervet India Pvt Ltd" },
  { value: "Intrepid Finance & Leasing Private Ltd", label: "Intrepid Finance & Leasing Private Ltd" },
  { value: "Invest Shoppe India Ltd", label: "Invest Shoppe India Ltd" },
  { value: "Investment Ideas", label: "Investment Ideas" },
  { value: "Ioc(Mkt)", label: "Ioc(Mkt)" },
  { value: "Ipsa Credit Ltd", label: "Ipsa Credit Ltd" },
  { value: "Ircon International Ltd.", label: "Ircon International Ltd." },
  { value: "Ireda Ltd", label: "Ireda Ltd" },
  { value: "Irikkur Service Co-Op Bank Ltd", label: "Irikkur Service Co-Op Bank Ltd" },
  { value: "Irikkur Urban Coop Society Ltd.", label: "Irikkur Urban Coop Society Ltd." },
  { value: "Irimbiliyam Service Co-Operative Bank Lt", label: "Irimbiliyam Service Co-Operative Bank Lt" },
  { value: "Irinave Service Co-Op Bank Ltd.", label: "Irinave Service Co-Op Bank Ltd." },
  { value: "Irinjalakuda Co-Op Agricultural & Rural Development Bank Ltd.", label: "Irinjalakuda Co-Op Agricultural & Rural Development Bank Ltd." },
  { value: "Irinjalakuda Town Co-Op Bank Ltd", label: "Irinjalakuda Town Co-Op Bank Ltd" },
  { value: "Irukuvajjala Viswanath", label: "Irukuvajjala Viswanath" },
  { value: "Ishan Finlease Pvt. Ltd.", label: "Ishan Finlease Pvt. Ltd." },
  { value: "Ishaprabhu Credit Souharda Sahakari Ltd", label: "Ishaprabhu Credit Souharda Sahakari Ltd" },
  { value: "Ishu Jain", label: "Ishu Jain" },
  { value: "Ishwar Auto", label: "Ishwar Auto" },
  { value: "Ishwar Co-Op Credit Society Ltd", label: "Ishwar Co-Op Credit Society Ltd" },
  { value: "Ishwar Krupa Auto Leasing ( R )", label: "Ishwar Krupa Auto Leasing ( R )" },
  { value: "Ishwar Leasing", label: "Ishwar Leasing" },
  { value: "Ishwar Motors & General Finance Company", label: "Ishwar Motors & General Finance Company" },
  { value: "Ishwarya Auto Financiers", label: "Ishwarya Auto Financiers" },
  { value: "Islampur Co-Op Peoples Bank Ltd", label: "Islampur Co-Op Peoples Bank Ltd" },
  { value: "Islampur Urban Co-Op Bank Ltd", label: "Islampur Urban Co-Op Bank Ltd" },
  { value: "Island Leasing Pvt. Ltd.", label: "Island Leasing Pvt. Ltd." },
  { value: "Iswarya Finance", label: "Iswarya Finance" },
  { value: "Iswarya Lakshmi Auto Finance", label: "Iswarya Lakshmi Auto Finance" },
  { value: "Ithithanam Janatha Service Co-Op Bank Lt", label: "Ithithanam Janatha Service Co-Op Bank Lt" },
  { value: "Ithithanam Janatha Service Co-Operative", label: "Ithithanam Janatha Service Co-Operative" },
  { value: "Iti Employees Co-Operative Credit Society Ltd", label: "Iti Employees Co-Operative Credit Society Ltd" },
  { value: "Itrust Financial Advisors Pvt Ltd", label: "Itrust Financial Advisors Pvt Ltd" },
  { value: "J & K  S/C S/T Wobc Dev. Corporation", label: "J & K  S/C S/T Wobc Dev. Corporation" },
  { value: "J & K Ellaqui Dehati", label: "J & K Ellaqui Dehati" },
  { value: "J & K Scbc Development Copartion Of India", label: "J & K Scbc Development Copartion Of India" },
  { value: "J & K Scs-Sts & Bcs Decelopment Corp Ltd.", label: "J & K Scs-Sts & Bcs Decelopment Corp Ltd." },
  { value: "J & K/Sc/St/Bc/Devlopment. Co. Ltd.", label: "J & K/Sc/St/Bc/Devlopment. Co. Ltd." },
  { value: "J And K Grameen Bank Ltd", label: "J And K Grameen Bank Ltd" },
  { value: "J And K State Co-Op Agriculture And Rural Development Bank", label: "J And K State Co-Op Agriculture And Rural Development Bank" },
  { value: "J Anilkumar Bothra", label: "J Anilkumar Bothra" },
  { value: "J Arun Nahar And Sons", label: "J Arun Nahar And Sons" },
  { value: "J Ashok Kumar Bagmar Finance", label: "J Ashok Kumar Bagmar Finance" },
  { value: "J B Auto Finance", label: "J B Auto Finance" },
  { value: "J C Bank", label: "J C Bank" },
  { value: "J C Credit Corporation", label: "J C Credit Corporation" },
  { value: "J C Investments", label: "J C Investments" },
  { value: "J D C C Bank Ltd", label: "J D C C Bank Ltd" },
  { value: "J D Motors Finance Ltd", label: "J D Motors Finance Ltd" },
  { value: "J Deepchand Bokdia And Sons", label: "J Deepchand Bokdia And Sons" },
  { value: "J Dilip Kumar Bothra And Sons", label: "J Dilip Kumar Bothra And Sons" },
  { value: "J Dilip Kumar Finance", label: "J Dilip Kumar Finance" },
  { value: "J J Finance Corporation Ltd", label: "J J Finance Corporation Ltd" },
  { value: "J K Auto Deal", label: "J K Auto Deal" },
  { value: "J K Auto Finance", label: "J K Auto Finance" },
  { value: "J K Auto Mobiles", label: "J K Auto Mobiles" },
  { value: "J K C Finance", label: "J K C Finance" },
  { value: "J K Corporation", label: "J K Corporation" },
  { value: "J K Enterprises", label: "J K Enterprises" },
  { value: "J K Finance", label: "J K Finance" },
  { value: "J K Industries Ltd", label: "J K Industries Ltd" },
  { value: "J K Investments", label: "J K Investments" },
  { value: "J K Mehta Auto Finance", label: "J K Mehta Auto Finance" },
  { value: "J K Prime Investment & Leasing India Ltd", label: "J K Prime Investment & Leasing India Ltd" },
  { value: "J Keshrimal Karnawat", label: "J Keshrimal Karnawat" },
  { value: "J Laburam And Sons", label: "J Laburam And Sons" },
  { value: "J Loan Karan Bafua", label: "J Loan Karan Bafua" },
  { value: "J Loo Karan Bafna (Huf)", label: "J Loo Karan Bafna (Huf)" },
  { value: "J M Mehta Travel And Tour", label: "J M Mehta Travel And Tour" },
  { value: "J M Minority Credit Co-Op Society Ltd", label: "J M Minority Credit Co-Op Society Ltd" },
  { value: "J Manohar Finance", label: "J Manohar Finance" },
  { value: "J N Ajeeth Financier", label: "J N Ajeeth Financier" },
  { value: "J Navaratanmal Jain", label: "J Navaratanmal Jain" },
  { value: "J P Finance", label: "J P Finance" },
  { value: "J P Machines", label: "J P Machines" },
  { value: "J P Motor Finance", label: "J P Motor Finance" },
  { value: "J R Bohra", label: "J R Bohra" },
  { value: "J R Bohra & Sons", label: "J R Bohra & Sons" },
  { value: "J Rekha Devi Seliya", label: "J Rekha Devi Seliya" },
  { value: "J S Auto Financiers", label: "J S Auto Financiers" },
  { value: "J Sapna Finacier", label: "J Sapna Finacier" },
  { value: "J Sarja Devi Bagmar Finance", label: "J Sarja Devi Bagmar Finance" },
  { value: "J Tarun Nahar And Sons", label: "J Tarun Nahar And Sons" },
  { value: "J Ugamchand And Sons", label: "J Ugamchand And Sons" },
  { value: "J V N S Bank Ltd", label: "J V N S Bank Ltd" },
  { value: "J. Bhawarlal", label: "J. Bhawarlal" },
  { value: "J. C. Motor And General Finance Co (P) Ltd", label: "J. C. Motor And General Finance Co (P) Ltd" },
  { value: "J. K. Auto Finance", label: "J. K. Auto Finance" },
  { value: "J. K. Motors (R)", label: "J. K. Motors (R)" },
  { value: "J. P. Auto Sales", label: "J. P. Auto Sales" },
  { value: "J. P. More & Sons", label: "J. P. More & Sons" },
  { value: "J. R. Bohra & Sons", label: "J. R. Bohra & Sons" },
  { value: "J. Rekha Devi Sethiya", label: "J. Rekha Devi Sethiya" },
  { value: "J. S. Hire Purchase Pvt. Ltd.", label: "J. S. Hire Purchase Pvt. Ltd." },
  { value: "J. Sovanlal", label: "J. Sovanlal" },
  { value: "J.B. Finance Co.", label: "J.B. Finance Co." },
  { value: "J.Bhawarlal Finance", label: "J.Bhawarlal Finance" },
  { value: "J.D.R.L Food Industries Pvt. Ltd", label: "J.D.R.L Food Industries Pvt. Ltd" },
  { value: "J.F.C. Leasing Pvt Ltd.", label: "J.F.C. Leasing Pvt Ltd." },
  { value: "J.Gill Finance Company", label: "J.Gill Finance Company" },
  { value: "J.H Hire Purchase Co.", label: "J.H Hire Purchase Co." },
  { value: "J.K. Corparation", label: "J.K. Corparation" },
  { value: "J.K. Traders", label: "J.K. Traders" },
  { value: "J.Manohar Finance", label: "J.Manohar Finance" },
  { value: "J.R Bohra And Sons", label: "J.R Bohra And Sons" },
  { value: "J.R. Finance", label: "J.R. Finance" },
  { value: "J.Ratan Chand  Bothra", label: "J.Ratan Chand  Bothra" },
  { value: "J.S Finance Corporation", label: "J.S Finance Corporation" },
  { value: "Jaap Finance & Investment P. Ltd.", label: "Jaap Finance & Investment P. Ltd." },
  { value: "Jabak Finance", label: "Jabak Finance" },
  { value: "Jabath Finance", label: "Jabath Finance" },
  { value: "Jadhav Tractors", label: "Jadhav Tractors" },
  { value: "Jaga Finance", label: "Jaga Finance" },
  { value: "Jagadamba Auto Finance", label: "Jagadamba Auto Finance" },
  { value: "Jagadamba Credit", label: "Jagadamba Credit" },
  { value: "Jagadamba Gramin Bigar Sheti Sahakari Patsanstha", label: "Jagadamba Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Jagadamba Motors", label: "Jagadamba Motors" },
  { value: "Jagadish Jakaty Finance Corporation Belgaum", label: "Jagadish Jakaty Finance Corporation Belgaum" },
  { value: "Jagadish Lease Finance Ltd", label: "Jagadish Lease Finance Ltd" },
  { value: "Jagajoti Shree Basaveashwar Credt Soubard Sahakari Sankeshwar", label: "Jagajoti Shree Basaveashwar Credt Soubard Sahakari Sankeshwar" },
  { value: "Jagajoti Shree Basaveashwar Credt Soubard Sahakari Sankeshwar", label: "Jagajoti Shree Basaveashwar Credt Soubard Sahakari Sankeshwar" },
  { value: "Jagajyothi Basaveshwar Pattin Sou Sahakari.", label: "Jagajyothi Basaveshwar Pattin Sou Sahakari." },
  { value: "Jagajyoti Shree Basaveshwar Credit Souhard Sahakari Ltd", label: "Jagajyoti Shree Basaveshwar Credit Souhard Sahakari Ltd" },
  { value: "Jagan Nath Din Dayal Finance", label: "Jagan Nath Din Dayal Finance" },
  { value: "Jagannath Co Op Credit Society Ltd", label: "Jagannath Co Op Credit Society Ltd" },
  { value: "Jagannath Sahakari Pathpedi Ltd", label: "Jagannath Sahakari Pathpedi Ltd" },
  { value: "Jaganthi Bai", label: "Jaganthi Bai" },
  { value: "Jagat Leasing Pvt. Ltd", label: "Jagat Leasing Pvt. Ltd" },
  { value: "Jagdamba Enterprises", label: "Jagdamba Enterprises" },
  { value: "Jagdamba Finance Ltd", label: "Jagdamba Finance Ltd" },
  { value: "Jagdamba Gramin Bigar Sheti Sahakar Patasanstha Maryadit", label: "Jagdamba Gramin Bigar Sheti Sahakar Patasanstha Maryadit" },
  { value: "Jagdamba Sah Path Soc", label: "Jagdamba Sah Path Soc" },
  { value: "Jagdambay Finance And Hire Purchase Co", label: "Jagdambay Finance And Hire Purchase Co" },
  { value: "Jagdish Chand Kapur & Sons.", label: "Jagdish Chand Kapur & Sons." },
  { value: "Jagdish Enterprises", label: "Jagdish Enterprises" },
  { value: "Jagdish Sharma Motor Sales", label: "Jagdish Sharma Motor Sales" },
  { value: "Jagmeet Enterprises", label: "Jagmeet Enterprises" },
  { value: "Jagrathi Urban Credit Souhadri Co Op Bank", label: "Jagrathi Urban Credit Souhadri Co Op Bank" },
  { value: "Jagrati Urban Co-Operative Credit Society Harogeri", label: "Jagrati Urban Co-Operative Credit Society Harogeri" },
  { value: "Jagriti Enterprises", label: "Jagriti Enterprises" },
  { value: "Jagruti Co Op Credit Society Ltd", label: "Jagruti Co Op Credit Society Ltd" },
  { value: "Jagruti Co-Op Bank Ltd", label: "Jagruti Co-Op Bank Ltd" },
  { value: "Jagruti Yuvak Co Op Credit Soc Ltd", label: "Jagruti Yuvak Co Op Credit Soc Ltd" },
  { value: "Jagvar Hire Purchase P Ltd", label: "Jagvar Hire Purchase P Ltd" },
  { value: "Jahnavi A/F", label: "Jahnavi A/F" },
  { value: "Jahnavi Automobile Finance", label: "Jahnavi Automobile Finance" },
  { value: "Jahnavi Automobile Financiers", label: "Jahnavi Automobile Financiers" },
  { value: "Jai Ambey Auto Center", label: "Jai Ambey Auto Center" },
  { value: "Jai Ambey Automobiles", label: "Jai Ambey Automobiles" },
  { value: "Jai Auto Finance.", label: "Jai Auto Finance." },
  { value: "Jai Baba Finance Co. Ltd.", label: "Jai Baba Finance Co. Ltd." },
  { value: "Jai Bajrang Bgr Sheti Sah P S Mdt", label: "Jai Bajrang Bgr Sheti Sah P S Mdt" },
  { value: "Jai Bajrang Gramin Bigar Path Sanstha Ltd.", label: "Jai Bajrang Gramin Bigar Path Sanstha Ltd." },
  { value: "Jai Bajrang Gramin Bigar Sheti Sahakari Pathsanstha", label: "Jai Bajrang Gramin Bigar Sheti Sahakari Pathsanstha" },
  { value: "Jai Bala Jai Finance Co", label: "Jai Bala Jai Finance Co" },
  { value: "Jai Balaji Finance Co", label: "Jai Balaji Finance Co" },
  { value: "Jai Bharat Co-Op Credit Society Ltd", label: "Jai Bharat Co-Op Credit Society Ltd" },
  { value: "Jai Bhavani Auto Finance", label: "Jai Bhavani Auto Finance" },
  { value: "Jai Bhavani Auto Leasing Finance Corporation", label: "Jai Bhavani Auto Leasing Finance Corporation" },
  { value: "Jai Bhavani Gramin Big Sheti Sah Pat San", label: "Jai Bhavani Gramin Big Sheti Sah Pat San" },
  { value: "Jai Bhavani Sahakari Bank Ltd", label: "Jai Bhavani Sahakari Bank Ltd" },
  { value: "Jai Bhawani Sahakari Bank Ltd.", label: "Jai Bhawani Sahakari Bank Ltd." },
  { value: "Jai Bholenath Co Op Credit Soc Ltd", label: "Jai Bholenath Co Op Credit Soc Ltd" },
  { value: "Jai Bholenath Co-Op Cr. Soc.", label: "Jai Bholenath Co-Op Cr. Soc." },
  { value: "Jai Bholenath Coop Soc Ltd", label: "Jai Bholenath Coop Soc Ltd" },
  { value: "Jai Datti Pvt. Ltd.", label: "Jai Datti Pvt. Ltd." },
  { value: "Jai Enterpries Finances", label: "Jai Enterpries Finances" },
  { value: "Jai Finance", label: "Jai Finance" },
  { value: "Jai Finance (India) Ltd", label: "Jai Finance (India) Ltd" },
  { value: "Jai Finance Corporation", label: "Jai Finance Corporation" },
  { value: "Jai Financier And Transort Co.", label: "Jai Financier And Transort Co." },
  { value: "Jai Ganesh Nagari Sahakari Path San.", label: "Jai Ganesh Nagari Sahakari Path San." },
  { value: "Jai Guru Dev Finance", label: "Jai Guru Dev Finance" },
  { value: "Jai Gurudatta Nagari Sah Pat Sanstha", label: "Jai Gurudatta Nagari Sah Pat Sanstha" },
  { value: "Jai Gurudev Auto Finance", label: "Jai Gurudev Auto Finance" },
  { value: "Jai Hanuman Co-Operative Society Ltd Kankanwadi", label: "Jai Hanuman Co-Operative Society Ltd Kankanwadi" },
  { value: "Jai Hanuman Multipurpose Souhard Sahakari Ltd.", label: "Jai Hanuman Multipurpose Souhard Sahakari Ltd." },
  { value: "Jai Hanuman Vividdodeshayagal Souhard Niymit", label: "Jai Hanuman Vividdodeshayagal Souhard Niymit" },
  { value: "Jai Hari Big Sheti Sah Pat Sanstha Maryadit", label: "Jai Hari Big Sheti Sah Pat Sanstha Maryadit" },
  { value: "Jai Hind Co-Op Bank Ltd", label: "Jai Hind Co-Op Bank Ltd" },
  { value: "Jai Hind Souhard Pattin Sahakari Niyamit", label: "Jai Hind Souhard Pattin Sahakari Niyamit" },
  { value: "Jai Ind  Finance", label: "Jai Ind  Finance" },
  { value: "Jai Invesmant", label: "Jai Invesmant" },
  { value: "Jai Jagdambey Auto Centre", label: "Jai Jagdambey Auto Centre" },
  { value: "Jai Jawan Nagrik Patpedhi  Ltd", label: "Jai Jawan Nagrik Patpedhi  Ltd" },
  { value: "Jai Jawan Pat Sanstha Maryadit", label: "Jai Jawan Pat Sanstha Maryadit" },
  { value: "Jai Jeemendra Co-Op Society", label: "Jai Jeemendra Co-Op Society" },
  { value: "Jai Kalimata Mahila Urban Co Op Bank Ltd", label: "Jai Kalimata Mahila Urban Co Op Bank Ltd" },
  { value: "Jai Karoli Maa Associate.", label: "Jai Karoli Maa Associate." },
  { value: "Jai Kisan Credits Auto Finance", label: "Jai Kisan Credits Auto Finance" },
  { value: "Jai Kisan Gramin Bigar Sheti Bank", label: "Jai Kisan Gramin Bigar Sheti Bank" },
  { value: "Jai Kondeshvar Nagari Sah. Pat. Sanstha Maryadit", label: "Jai Kondeshvar Nagari Sah. Pat. Sanstha Maryadit" },
  { value: "Jai Laxmi Co Op Cre Soc Ltd", label: "Jai Laxmi Co Op Cre Soc Ltd" },
  { value: "Jai Laxmi Enterprises", label: "Jai Laxmi Enterprises" },
  { value: "Jai Laxmi Finance", label: "Jai Laxmi Finance" },
  { value: "Jai Laxmi Finance Co", label: "Jai Laxmi Finance Co" },
  { value: "Jai Maa Ambe Finanace Pvt Co", label: "Jai Maa Ambe Finanace Pvt Co" },
  { value: "Jai Mahalxmi Automobiles", label: "Jai Mahalxmi Automobiles" },
  { value: "Jai Malhar Nagri Sahakari Patsanstha Maryadit", label: "Jai Malhar Nagri Sahakari Patsanstha Maryadit" },
  { value: "Jai Maruthi Auto Finance", label: "Jai Maruthi Auto Finance" },
  { value: "Jai Mata Ji Co-Op Soc", label: "Jai Mata Ji Co-Op Soc" },
  { value: "Jai Nakoda Auto Finance", label: "Jai Nakoda Auto Finance" },
  { value: "Jai Nanda Mataji Co Op Credit Society Ltd", label: "Jai Nanda Mataji Co Op Credit Society Ltd" },
  { value: "Jai Pal Auto Deals", label: "Jai Pal Auto Deals" },
  { value: "Jai Prakash", label: "Jai Prakash" },
  { value: "Jai Rishab Finance", label: "Jai Rishab Finance" },
  { value: "Jai Savings And Hire Purchase Co.(India) Ltd.", label: "Jai Savings And Hire Purchase Co.(India) Ltd." },
  { value: "Jai Shadharam Brokrage", label: "Jai Shadharam Brokrage" },
  { value: "Jai Shanakr Finance Co", label: "Jai Shanakr Finance Co" },
  { value: "Jai Shivrai Nagari Sahakari Bank M.", label: "Jai Shivrai Nagari Sahakari Bank M." },
  { value: "Jai Shri Maa Durga Finance Co", label: "Jai Shri Maa Durga Finance Co" },
  { value: "Jai Shri Ram Finance", label: "Jai Shri Ram Finance" },
  { value: "Jai Shriram Gramin Bigar Sheti Sahakari Patsanstha", label: "Jai Shriram Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Jai Singh Tempo Transport Service", label: "Jai Singh Tempo Transport Service" },
  { value: "Jai Tulja Bhavani Patapedi", label: "Jai Tulja Bhavani Patapedi" },
  { value: "Jai Tulja Bhawani Gramin Big She Path Sat", label: "Jai Tulja Bhawani Gramin Big She Path Sat" },
  { value: "Jai Tuljabhawani Urban Co-Operative Bank Ltd", label: "Jai Tuljabhawani Urban Co-Operative Bank Ltd" },
  { value: "Jaibharat Credit Ltd", label: "Jaibharat Credit Ltd" },
  { value: "Jaibhavani Auto Leasing", label: "Jaibhavani Auto Leasing" },
  { value: "Jaidadaji Auto Finance", label: "Jaidadaji Auto Finance" },
  { value: "Jaihind Urban Co-Op Bank Ltd.", label: "Jaihind Urban Co-Op Bank Ltd." },
  { value: "Jaika Finance Ltd.", label: "Jaika Finance Ltd." },
  { value: "Jailaxmi Finance", label: "Jailaxmi Finance" },
  { value: "Jaimani Sahakari Patpedhi Ltd", label: "Jaimani Sahakari Patpedhi Ltd" },
  { value: "Jaimuni Sahakari Patpedhi Ltd", label: "Jaimuni Sahakari Patpedhi Ltd" },
  { value: "Jain Associates", label: "Jain Associates" },
  { value: "Jain Auto", label: "Jain Auto" },
  { value: "Jain Auto & Finance Co.", label: "Jain Auto & Finance Co." },
  { value: "Jain Auto Enterprises", label: "Jain Auto Enterprises" },
  { value: "Jain Auto Finance And Investment.", label: "Jain Auto Finance And Investment." },
  { value: "Jain Auto Finance Co. Ltd.", label: "Jain Auto Finance Co. Ltd." },
  { value: "Jain Co-Op Bank Ltd., New Delhi", label: "Jain Co-Op Bank Ltd., New Delhi" },
  { value: "Jain Co-Operative Bank Ltd.", label: "Jain Co-Operative Bank Ltd." },
  { value: "Jain Entervestment", label: "Jain Entervestment" },
  { value: "Jain Finacial Service", label: "Jain Finacial Service" },
  { value: "Jain Finance Associates", label: "Jain Finance Associates" },
  { value: "Jain Finance Co. Pvt Ltd", label: "Jain Finance Co. Pvt Ltd" },
  { value: "Jain Gem Finance Company", label: "Jain Gem Finance Company" },
  { value: "Jain Gen Fin Co", label: "Jain Gen Fin Co" },
  { value: "Jain General Finance Co.", label: "Jain General Finance Co." },
  { value: "Jain Impex", label: "Jain Impex" },
  { value: "Jain Investments", label: "Jain Investments" },
  { value: "Jain Motor Corporation", label: "Jain Motor Corporation" },
  { value: "Jain Motor Finance", label: "Jain Motor Finance" },
  { value: "Jain Motor Finance And Leasing Private Limited", label: "Jain Motor Finance And Leasing Private Limited" },
  { value: "Jain Motor Finance Co. Ltd.", label: "Jain Motor Finance Co. Ltd." },
  { value: "Jain Motors And Travels- Sanjay Jain", label: "Jain Motors And Travels- Sanjay Jain" },
  { value: "Jain Oswal Nagari Sah Pat San", label: "Jain Oswal Nagari Sah Pat San" },
  { value: "Jain Sahakari Bank Ltd., Mumbai", label: "Jain Sahakari Bank Ltd., Mumbai" },
  { value: "Jain Son Auto Finance Ltd.", label: "Jain Son Auto Finance Ltd." },
  { value: "Jain Son Enterprises", label: "Jain Son Enterprises" },
  { value: "Jain Tractors", label: "Jain Tractors" },
  { value: "Jain Vest Finance Co Ltd", label: "Jain Vest Finance Co Ltd" },
  { value: "Jainson Enterprices", label: "Jainson Enterprices" },
  { value: "Jainul Farook Vora", label: "Jainul Farook Vora" },
  { value: "Jaiprakash Narayan Nagari Sahakari Bank Ltd", label: "Jaiprakash Narayan Nagari Sahakari Bank Ltd" },
  { value: "Jaiprakash Narayan Nagri Sahakari Bank L", label: "Jaiprakash Narayan Nagri Sahakari Bank L" },
  { value: "Jaipur Central Bank Ltd.", label: "Jaipur Central Bank Ltd." },
  { value: "Jaipur District Central Co-Operative Bank Ltd.", label: "Jaipur District Central Co-Operative Bank Ltd." },
  { value: "Jaipur Finvest Pvt Ltd", label: "Jaipur Finvest Pvt Ltd" },
  { value: "Jaipur Nagaur Aanchalik Gramin Bank.", label: "Jaipur Nagaur Aanchalik Gramin Bank." },
  { value: "Jaipur Nagaur Aanchlik Gramin Bank, Jaipur", label: "Jaipur Nagaur Aanchlik Gramin Bank, Jaipur" },
  { value: "Jaipur Thar Gramin Bank,", label: "Jaipur Thar Gramin Bank," },
  { value: "Jaipur-Nagaur Aanchalik Gramin Bank", label: "Jaipur-Nagaur Aanchalik Gramin Bank" },
  { value: "Jairam Finance Corporation", label: "Jairam Finance Corporation" },
  { value: "Jaisalmer Central Co-Op Bank Ltd.", label: "Jaisalmer Central Co-Op Bank Ltd." },
  { value: "Jaisalmer District Central Co-Operative Bank Ltd.", label: "Jaisalmer District Central Co-Operative Bank Ltd." },
  { value: "Jaisingpur Vyapari Nagari Sahkari Pathsanstha Maryadit", label: "Jaisingpur Vyapari Nagari Sahkari Pathsanstha Maryadit" },
  { value: "Jaisingpur Vyapari Nagari Sahkari Pathsanstha Maryadit", label: "Jaisingpur Vyapari Nagari Sahkari Pathsanstha Maryadit" },
  { value: "Jaisons Investment & Finance Ltd.", label: "Jaisons Investment & Finance Ltd." },
  { value: "Jakati Finance", label: "Jakati Finance" },
  { value: "Jalandar District Central Co-Operative Bank Ltd.", label: "Jalandar District Central Co-Operative Bank Ltd." },
  { value: "Jalandhar Cargo Advances (P) Ltd.", label: "Jalandhar Cargo Advances (P) Ltd." },
  { value: "Jalandhar Cent Co. Op.Bank", label: "Jalandhar Cent Co. Op.Bank" },
  { value: "Jalaram Auto Finance", label: "Jalaram Auto Finance" },
  { value: "Jalaram Finance", label: "Jalaram Finance" },
  { value: "Jalaun District Co-Op Bank Ltd.", label: "Jalaun District Co-Op Bank Ltd." },
  { value: "Jalaun District Co-Operative Bank Ltd.", label: "Jalaun District Co-Operative Bank Ltd." },
  { value: "Jalgaon District Central Co-Op Bank Ltd.", label: "Jalgaon District Central Co-Op Bank Ltd." },
  { value: "Jalgaon District Central Co-Operative Bank Ltd.", label: "Jalgaon District Central Co-Operative Bank Ltd." },
  { value: "Jalgaon Merchant Sahakari Bank Ltd.", label: "Jalgaon Merchant Sahakari Bank Ltd." },
  { value: "Jalgaon Merchants Sahakari Bank Ltd", label: "Jalgaon Merchants Sahakari Bank Ltd" },
  { value: "Jalgaon Peoples Co-Op Bank Ltd", label: "Jalgaon Peoples Co-Op Bank Ltd" },
  { value: "Jalna District Central Co-Operative Bank Ltd.", label: "Jalna District Central Co-Operative Bank Ltd." },
  { value: "Jalna Merchant Co-Op Bank Ltd", label: "Jalna Merchant Co-Op Bank Ltd" },
  { value: "Jalna Merchants Co-Op Bank Ltd", label: "Jalna Merchants Co-Op Bank Ltd" },
  { value: "Jalna Peoples Co-Op Bank Ltd", label: "Jalna Peoples Co-Op Bank Ltd" },
  { value: "Jalore District Central Co-Operative Bank Ltd.", label: "Jalore District Central Co-Operative Bank Ltd." },
  { value: "Jalore Nagarik Sahakari Bank Ltd", label: "Jalore Nagarik Sahakari Bank Ltd" },
  { value: "Jalore Nagrik Sahakari Bank Ltd.", label: "Jalore Nagrik Sahakari Bank Ltd." },
  { value: "Jalpaiguri Central Co-Operative Bank Ltd", label: "Jalpaiguri Central Co-Operative Bank Ltd" },
  { value: "Jalpaiguri District Central Co-Operative Bank Ltd.", label: "Jalpaiguri District Central Co-Operative Bank Ltd." },
  { value: "Jalsika Juth Seva Sahakari Mandali Ltd", label: "Jalsika Juth Seva Sahakari Mandali Ltd" },
  { value: "Jam Timbdi Juth Seva Sahakari Mandali", label: "Jam Timbdi Juth Seva Sahakari Mandali" },
  { value: "Jamboti Co-Operative Credit Society Ltd.", label: "Jamboti Co-Operative Credit Society Ltd." },
  { value: "Jambusar Peoples Co-Op Bank Ltd", label: "Jambusar Peoples Co-Op Bank Ltd" },
  { value: "Jamdadar Juth Seva Sah Mandli Ltd", label: "Jamdadar Juth Seva Sah Mandli Ltd" },
  { value: "Jamia Co-Op. Bank Ltd., New Delhi", label: "Jamia Co-Op. Bank Ltd., New Delhi" },
  { value: "Jamia Co-Operative Bank Ltd.", label: "Jamia Co-Operative Bank Ltd." },
  { value: "Jamia Millia Islamia", label: "Jamia Millia Islamia" },
  { value: "Jamin Vikas Bank Ltd.", label: "Jamin Vikas Bank Ltd." },
  { value: "Jamkandorna Juth Seva Sahkari Mandali", label: "Jamkandorna Juth Seva Sahkari Mandali" },
  { value: "Jamkhandi Minority Urban Co-Op Credit Society Ltd.", label: "Jamkhandi Minority Urban Co-Op Credit Society Ltd." },
  { value: "Jamkhandi Urban Co-Op Bank Ltd", label: "Jamkhandi Urban Co-Op Bank Ltd" },
  { value: "Jamkhed Merchant Co-Op Bank Ltd.", label: "Jamkhed Merchant Co-Op Bank Ltd." },
  { value: "Jamkhed Nagari Sahakari Patsanstha", label: "Jamkhed Nagari Sahakari Patsanstha" },
  { value: "Jamkhed Urban Co-Op Bank Ltd", label: "Jamkhed Urban Co-Op Bank Ltd" },
  { value: "Jammanahalli Primary Agri Credit Co Bank", label: "Jammanahalli Primary Agri Credit Co Bank" },
  { value: "Jammu And Kashmir Industrial Development Corporation", label: "Jammu And Kashmir Industrial Development Corporation" },
  { value: "Jammu And Kashmir State Central Co-Operative Bank Ltd.", label: "Jammu And Kashmir State Central Co-Operative Bank Ltd." },
  { value: "Jammu And Kashmir State Financial Corporation", label: "Jammu And Kashmir State Financial Corporation" },
  { value: "Jammu And Kashmir State Tourism Development Corporation Ltd.", label: "Jammu And Kashmir State Tourism Development Corporation Ltd." },
  { value: "Jammu Central Co-Op Bank Ltd", label: "Jammu Central Co-Op Bank Ltd" },
  { value: "Jammu District Central Co-Operative Bank Ltd.", label: "Jammu District Central Co-Operative Bank Ltd." },
  { value: "Jammu Ess Iee Finance Pvt Ltd.", label: "Jammu Ess Iee Finance Pvt Ltd." },
  { value: "Jamnagar Dist. Co-Operative Bank Ltd.", label: "Jamnagar Dist. Co-Operative Bank Ltd." },
  { value: "Jamnagar District Central Co-Operative Bank Ltd.", label: "Jamnagar District Central Co-Operative Bank Ltd." },
  { value: "Jamnagar District Co-Operative Bank Ltd", label: "Jamnagar District Co-Operative Bank Ltd" },
  { value: "Jamnagar Mahila Sahakari Bank Ltd", label: "Jamnagar Mahila Sahakari Bank Ltd" },
  { value: "Jamnagar Peoples Co-Op Bank Ltd", label: "Jamnagar Peoples Co-Op Bank Ltd" },
  { value: "Jamnagar Rajkot Gramin Bank", label: "Jamnagar Rajkot Gramin Bank" },
  { value: "Jamnagar Rajkot Gramin Bank", label: "Jamnagar Rajkot Gramin Bank" },
  { value: "Jamngar Rajkot Gramin Bank Ltd", label: "Jamngar Rajkot Gramin Bank Ltd" },
  { value: "Jamo Pay Jamobazar Agrco Society Ltd", label: "Jamo Pay Jamobazar Agrco Society Ltd" },
  { value: "Jampala Srinivasa Rao Automobile Financiers", label: "Jampala Srinivasa Rao Automobile Financiers" },
  { value: "Jampeta Co-Op Urban Bank Ltd", label: "Jampeta Co-Op Urban Bank Ltd" },
  { value: "Jamshedpur Urban Co-Op. Bank Ltd", label: "Jamshedpur Urban Co-Op. Bank Ltd" },
  { value: "Jamuna Gramin Bank Shahganj Agra", label: "Jamuna Gramin Bank Shahganj Agra" },
  { value: "Jamunna Gramin Bank", label: "Jamunna Gramin Bank" },
  { value: "Jan Samruddhi Co Op Credit Soc Ltd", label: "Jan Samruddhi Co Op Credit Soc Ltd" },
  { value: "Jan Seva Co Op Bank Ltd", label: "Jan Seva Co Op Bank Ltd" },
  { value: "Jana Utkarsh Urban Co Op Credit Society", label: "Jana Utkarsh Urban Co Op Credit Society" },
  { value: "Janadhar Gramin Bigarsheth Saha Pat Ltd", label: "Janadhar Gramin Bigarsheth Saha Pat Ltd" },
  { value: "Janajeevhala Sahakari Patsanstha Ltd", label: "Janajeevhala Sahakari Patsanstha Ltd" },
  { value: "Janakalyan Co Op Bank Ltd", label: "Janakalyan Co Op Bank Ltd" },
  { value: "Janakalyan Co.Op.Bank Ltd", label: "Janakalyan Co.Op.Bank Ltd" },
  { value: "Janakalyan Sahakari Bank Ltd.", label: "Janakalyan Sahakari Bank Ltd." },
  { value: "Janakalyan Sahakari Bank Ltd.,", label: "Janakalyan Sahakari Bank Ltd.," },
  { value: "Janakalyan Sahakari Bank Ltd., Mumbai", label: "Janakalyan Sahakari Bank Ltd., Mumbai" },
  { value: "Janakalyan Sahkari Bank", label: "Janakalyan Sahkari Bank" },
  { value: "Janakeeya Sahakarana Urban Bank Ltd", label: "Janakeeya Sahakarana Urban Bank Ltd" },
  { value: "Janaki Security Ltd.", label: "Janaki Security Ltd." },
  { value: "Janalakshmi Financial Services Pvt Ltd", label: "Janalakshmi Financial Services Pvt Ltd" },
  { value: "Janalakshmi Social Services", label: "Janalakshmi Social Services" },
  { value: "Janalaxmi Co-Op. Bank Ltd., Nasik", label: "Janalaxmi Co-Op. Bank Ltd., Nasik" },
  { value: "Janamangal Nagari Sah Pat Sanstha", label: "Janamangal Nagari Sah Pat Sanstha" },
  { value: "Janaseva Bank", label: "Janaseva Bank" },
  { value: "Janaseva Co-Op. Bank Limited", label: "Janaseva Co-Op. Bank Limited" },
  { value: "Janaseva Nagari Sahakari Bank Ltd", label: "Janaseva Nagari Sahakari Bank Ltd" },
  { value: "Janaseva Sahakari Bank Ltd,Pune", label: "Janaseva Sahakari Bank Ltd,Pune" },
  { value: "Janaseva Sahakari Bank Ltd.", label: "Janaseva Sahakari Bank Ltd." },
  { value: "Janashakti Byabaraj Samabai Samitee Ltd.", label: "Janashakti Byabaraj Samabai Samitee Ltd." },
  { value: "Janata Co Op Bank Ltd.", label: "Janata Co Op Bank Ltd." },
  { value: "Janata Co-Op. Bank Ltd., Delhi", label: "Janata Co-Op. Bank Ltd., Delhi" },
  { value: "Janata Co-Operative Bank Ltd.", label: "Janata Co-Operative Bank Ltd." },
  { value: "Janata Co-Operative Credit Society", label: "Janata Co-Operative Credit Society" },
  { value: "Janata Commercialco-Op Bank Ltd", label: "Janata Commercialco-Op Bank Ltd" },
  { value: "Janata Sah Bank", label: "Janata Sah Bank" },
  { value: "Janata Sah. Bank Ltd.,Mumbai", label: "Janata Sah. Bank Ltd.,Mumbai" },
  { value: "Janata Sahakari Bank Limited", label: "Janata Sahakari Bank Limited" },
  { value: "Janata Sahakari Bank Ltd., Ajara, Kolhapur", label: "Janata Sahakari Bank Ltd., Ajara, Kolhapur" },
  { value: "Janata Urban Co-Op Bank Ltd", label: "Janata Urban Co-Op Bank Ltd" },
  { value: "Janata Urban Co.Op. Bank Ltd.", label: "Janata Urban Co.Op. Bank Ltd." },
  { value: "Janatha Co-Op Bank Ltd", label: "Janatha Co-Op Bank Ltd" },
  { value: "Janatha Credit Co-Op.Society. Ltd.,", label: "Janatha Credit Co-Op.Society. Ltd.," },
  { value: "Janatha Finance", label: "Janatha Finance" },
  { value: "Janatha Seva Co-Op Bank Ltd", label: "Janatha Seva Co-Op Bank Ltd" },
  { value: "Janatha Seva Co.Op. Bank Ltd", label: "Janatha Seva Co.Op. Bank Ltd" },
  { value: "Jandekurbar Urban Co-Operative Credit Society Ltd.", label: "Jandekurbar Urban Co-Operative Credit Society Ltd." },
  { value: "Janga Reddy Godala", label: "Janga Reddy Godala" },
  { value: "Jangamakote S F S C Bank Ltd.", label: "Jangamakote S F S C Bank Ltd." },
  { value: "Jangipur Urban Co-Op.Credit Socuety Ltd", label: "Jangipur Urban Co-Op.Credit Socuety Ltd" },
  { value: "Janhit Nagari Sahakari Pat Sanstha", label: "Janhit Nagari Sahakari Pat Sanstha" },
  { value: "Janjivhal Sah Pat Ltd", label: "Janjivhal Sah Pat Ltd" },
  { value: "Jankalyan Gramin Bigar Sheti Pat Sant", label: "Jankalyan Gramin Bigar Sheti Pat Sant" },
  { value: "Jankalyan Nagari Sah Pat Sanstha Mary", label: "Jankalyan Nagari Sah Pat Sanstha Mary" },
  { value: "Jankalyan Nagari Sah. Patsanstha Marya", label: "Jankalyan Nagari Sah. Patsanstha Marya" },
  { value: "Jankalyan Nagari Sahakari Patsanstha", label: "Jankalyan Nagari Sahakari Patsanstha" },
  { value: "Jankalyan Nagari Sahkari Bank Ltd.", label: "Jankalyan Nagari Sahkari Bank Ltd." },
  { value: "Jankalyan Sahakari Bank Ltd", label: "Jankalyan Sahakari Bank Ltd" },
  { value: "Jankalyan Sahakari Pathpedhi Maryadit.", label: "Jankalyan Sahakari Pathpedhi Maryadit." },
  { value: "Jankalyan Sahakari Patpedhi (Ltd.)", label: "Jankalyan Sahakari Patpedhi (Ltd.)" },
  { value: "Jankalyan Urban Co-Op Bank Ltd", label: "Jankalyan Urban Co-Op Bank Ltd" },
  { value: "Jankalyan Urban Coop Bank Ltd", label: "Jankalyan Urban Coop Bank Ltd" },
  { value: "Janlaxmi Finance", label: "Janlaxmi Finance" },
  { value: "Janlaxmi Gramin Bigar Sheti Patsanstha", label: "Janlaxmi Gramin Bigar Sheti Patsanstha" },
  { value: "Janlaxmi Gramin Bigar Sheti Sah Patsanstha Ltd.", label: "Janlaxmi Gramin Bigar Sheti Sah Patsanstha Ltd." },
  { value: "Janmangal Nagari Sahakari Pat Santha Ltd.", label: "Janmangal Nagari Sahakari Pat Santha Ltd." },
  { value: "Janseva Gramin Bigar Sheti Sahkari Patsanstha", label: "Janseva Gramin Bigar Sheti Sahkari Patsanstha" },
  { value: "Janseva Nag Sah Pat Sant", label: "Janseva Nag Sah Pat Sant" },
  { value: "Janseva Nagri Co-Op Bank", label: "Janseva Nagri Co-Op Bank" },
  { value: "Janseva Sahakari Bank (Borivali) Ltd Dahisar E", label: "Janseva Sahakari Bank (Borivali) Ltd Dahisar E" },
  { value: "Janseva Sahkari Bank Ltd", label: "Janseva Sahkari Bank Ltd" },
  { value: "Jansewa Urban Co-Op Bank Ltd", label: "Jansewa Urban Co-Op Bank Ltd" },
  { value: "Janshanti Sah Pat Sanstha Ltd", label: "Janshanti Sah Pat Sanstha Ltd" },
  { value: "Janshanti Sahakari Patsanstha Maryadit", label: "Janshanti Sahakari Patsanstha Maryadit" },
  { value: "Janta Co-Op. Bank Ltd", label: "Janta Co-Op. Bank Ltd" },
  { value: "Janta Co-Operative Bank Ltd.", label: "Janta Co-Operative Bank Ltd." },
  { value: "Janta Comercial Co-Op Bank Ltd", label: "Janta Comercial Co-Op Bank Ltd" },
  { value: "Janta Commercial Bank", label: "Janta Commercial Bank" },
  { value: "Janta Commercial Co.Op Bank", label: "Janta Commercial Co.Op Bank" },
  { value: "Janta Machinary Store", label: "Janta Machinary Store" },
  { value: "Janta Nagari Sahkari Pathsanstha Maryadit", label: "Janta Nagari Sahkari Pathsanstha Maryadit" },
  { value: "Janta Nagrik Credit Soc. Ltd.", label: "Janta Nagrik Credit Soc. Ltd." },
  { value: "Janta Nagrik Sahakari Sakh Samiti Myt", label: "Janta Nagrik Sahakari Sakh Samiti Myt" },
  { value: "Janta Raja Nagri Sah Pat Sanstha", label: "Janta Raja Nagri Sah Pat Sanstha" },
  { value: "Janta Sahakari Bank", label: "Janta Sahakari Bank" },
  { value: "Janta Sahakari Bank Ltd", label: "Janta Sahakari Bank Ltd" },
  { value: "Janta Sahakari Bank Ltd.", label: "Janta Sahakari Bank Ltd." },
  { value: "Janta Sahakari Sarafi Mandali Ltd", label: "Janta Sahakari Sarafi Mandali Ltd" },
  { value: "Jaoli Sahakari Bank Ltd", label: "Jaoli Sahakari Bank Ltd" },
  { value: "Jarandeshwar Sahakari Bank Ltd", label: "Jarandeshwar Sahakari Bank Ltd" },
  { value: "Jarandeshwar Sahakari Bk. Ltd.", label: "Jarandeshwar Sahakari Bk. Ltd." },
  { value: "Jaryal Motor Finance Company  Ltd", label: "Jaryal Motor Finance Company  Ltd" },
  { value: "Jashan Finance Company", label: "Jashan Finance Company" },
  { value: "Jasleen Kaur Kohli", label: "Jasleen Kaur Kohli" },
  { value: "Jasmen Finance Ltd", label: "Jasmen Finance Ltd" },
  { value: "Jasodha Auto P Ltd", label: "Jasodha Auto P Ltd" },
  { value: "Jaspal Auto Deals", label: "Jaspal Auto Deals" },
  { value: "Jaspal Finance", label: "Jaspal Finance" },
  { value: "Jassi Hire Purchase Pvt. Ltd.", label: "Jassi Hire Purchase Pvt. Ltd." },
  { value: "Jat Urban Co-Op Bank L", label: "Jat Urban Co-Op Bank L" },
  { value: "Jath Urban Co-Op Bank Ltd", label: "Jath Urban Co-Op Bank Ltd" },
  { value: "Jath Urban Co-Operative Bank Ltd", label: "Jath Urban Co-Operative Bank Ltd" },
  { value: "Jatin Kiritbhai Patel", label: "Jatin Kiritbhai Patel" },
  { value: "Jawahar Finance", label: "Jawahar Finance" },
  { value: "Jawahar Gottipati", label: "Jawahar Gottipati" },
  { value: "Jawahar Sahakari Bank Ltd", label: "Jawahar Sahakari Bank Ltd" },
  { value: "Jawaharlal And Sons", label: "Jawaharlal And Sons" },
  { value: "Jawaharlal Institute Of Postgraduate Medical Education And Research", label: "Jawaharlal Institute Of Postgraduate Medical Education And Research" },
  { value: "Jawaharlal Nehru Centre For Advanced Scientific Research", label: "Jawaharlal Nehru Centre For Advanced Scientific Research" },
  { value: "Jawaharlal Nehru Port Trust", label: "Jawaharlal Nehru Port Trust" },
  { value: "Jawal Investment", label: "Jawal Investment" },
  { value: "Jawantraj & Sons", label: "Jawantraj & Sons" },
  { value: "Jawarilal Jain (Huf)", label: "Jawarilal Jain (Huf)" },
  { value: "Jawhar Urban Co-Op Bank Ltd", label: "Jawhar Urban Co-Op Bank Ltd" },
  { value: "Jawhar Urban Co-Op Bank Ltd", label: "Jawhar Urban Co-Op Bank Ltd" },
  { value: "Jay Ambey Finance", label: "Jay Ambey Finance" },
  { value: "Jay Ambey Tractors", label: "Jay Ambey Tractors" },
  { value: "Jay Bharat Credit Ltd", label: "Jay Bharat Credit Ltd" },
  { value: "Jay Bhavani Gramin Big Sheti Sah Pat San", label: "Jay Bhavani Gramin Big Sheti Sah Pat San" },
  { value: "Jay Bhavani Nagari Sahakari Path Sanstha Maryadit", label: "Jay Bhavani Nagari Sahakari Path Sanstha Maryadit" },
  { value: "Jay Bhawani Nagari Sahkari Patsanstha Mydt", label: "Jay Bhawani Nagari Sahkari Patsanstha Mydt" },
  { value: "Jay Bholenath Auto Finance", label: "Jay Bholenath Auto Finance" },
  { value: "Jay Cee Finance Pvt. Ltd.", label: "Jay Cee Finance Pvt. Ltd." },
  { value: "Jay Corporation", label: "Jay Corporation" },
  { value: "Jay Dadaji Enterprise", label: "Jay Dadaji Enterprise" },
  { value: "Jay Dadaji Finance Ltd", label: "Jay Dadaji Finance Ltd" },
  { value: "Jay Ganesh Instalments (P) Ltd", label: "Jay Ganesh Instalments (P) Ltd" },
  { value: "Jay Guru Dev Finance Company Ltd", label: "Jay Guru Dev Finance Company Ltd" },
  { value: "Jay Hanuman G.B.S.S.P.S Mydt", label: "Jay Hanuman G.B.S.S.P.S Mydt" },
  { value: "Jay Hanuman Nagari Sah Pat Sanstha Ltd", label: "Jay Hanuman Nagari Sah Pat Sanstha Ltd" },
  { value: "Jay J.J Mata Finance.", label: "Jay J.J Mata Finance." },
  { value: "Jay Jagdambey Auto Center", label: "Jay Jagdambey Auto Center" },
  { value: "Jay Jalaram Enterprise", label: "Jay Jalaram Enterprise" },
  { value: "Jay Jalindar Nath Gra Big Sah Path Santha", label: "Jay Jalindar Nath Gra Big Sah Path Santha" },
  { value: "Jay Javan G B S S P S Mryadit", label: "Jay Javan G B S S P S Mryadit" },
  { value: "Jay Jawan Gramin Bigarsheti Sah Pat Sansta Maryadit", label: "Jay Jawan Gramin Bigarsheti Sah Pat Sansta Maryadit" },
  { value: "Jay Jeet Finance", label: "Jay Jeet Finance" },
  { value: "Jay Kay Finlease (P) Ltd", label: "Jay Kay Finlease (P) Ltd" },
  { value: "Jay Kisan Gramin Beger Sheti Sah Patsanstha", label: "Jay Kisan Gramin Beger Sheti Sah Patsanstha" },
  { value: "Jay Maharaj Auto Finance", label: "Jay Maharaj Auto Finance" },
  { value: "Jay Malhar Shayadri Nagri Sah Path Mar", label: "Jay Malhar Shayadri Nagri Sah Path Mar" },
  { value: "Jay Sainath Corporation", label: "Jay Sainath Corporation" },
  { value: "Jay Tuljabhavani Urban Co-Op Bk L", label: "Jay Tuljabhavani Urban Co-Op Bk L" },
  { value: "Jay Udgaon Co  Op Bank Ltd", label: "Jay Udgaon Co  Op Bank Ltd" },
  { value: "Jay Udgaon Co-Op Bank Ltd", label: "Jay Udgaon Co-Op Bank Ltd" },
  { value: "Jay Udgaon Sahakari Bank Ltd", label: "Jay Udgaon Sahakari Bank Ltd" },
  { value: "Jay Yogeshwar Nagari Sahkari Pathsansta Ltd", label: "Jay Yogeshwar Nagari Sahkari Pathsansta Ltd" },
  { value: "Jaya Balaji  Finance", label: "Jaya Balaji  Finance" },
  { value: "Jaya Bharat Credit Ltd.", label: "Jaya Bharat Credit Ltd." },
  { value: "Jaya Dharani Finance", label: "Jaya Dharani Finance" },
  { value: "Jaya Gayathri Two Wheeler Finance", label: "Jaya Gayathri Two Wheeler Finance" },
  { value: "Jaya Gayatri Two Wheeler Finance & Consultancy", label: "Jaya Gayatri Two Wheeler Finance & Consultancy" },
  { value: "Jaya Himatsingka", label: "Jaya Himatsingka" },
  { value: "Jaya Investments", label: "Jaya Investments" },
  { value: "Jaya Lakshmi Auto Finance", label: "Jaya Lakshmi Auto Finance" },
  { value: "Jaya Lea Finance", label: "Jaya Lea Finance" },
  { value: "Jaya Murugan Finance", label: "Jaya Murugan Finance" },
  { value: "Jaya Sai Auto Finance", label: "Jaya Sai Auto Finance" },
  { value: "Jaya Sree Investments", label: "Jaya Sree Investments" },
  { value: "Jaya Tagde", label: "Jaya Tagde" },
  { value: "Jayabharat Credit Ltd", label: "Jayabharat Credit Ltd" },
  { value: "Jayalakshmi Auto Finance", label: "Jayalakshmi Auto Finance" },
  { value: "Jayalekshmi Finance", label: "Jayalekshmi Finance" },
  { value: "Jayam Finance", label: "Jayam Finance" },
  { value: "Jayam Investments", label: "Jayam Investments" },
  { value: "Jayamal Syndicate", label: "Jayamal Syndicate" },
  { value: "Jayamaruthi Finance", label: "Jayamaruthi Finance" },
  { value: "Jayamaruti Finance", label: "Jayamaruti Finance" },
  { value: "Jayanthi Auto Finance", label: "Jayanthi Auto Finance" },
  { value: "Jayanthi Bai", label: "Jayanthi Bai" },
  { value: "Jayaprakash N S", label: "Jayaprakash N S" },
  { value: "Jayasai Hire Purchase", label: "Jayasai Hire Purchase" },
  { value: "Jayashankar Kalkundrikar Multipurpose Souhard Sahakari Niyamit", label: "Jayashankar Kalkundrikar Multipurpose Souhard Sahakari Niyamit" },
  { value: "Jayashree Investments", label: "Jayashree Investments" },
  { value: "Jayashree Mehta", label: "Jayashree Mehta" },
  { value: "Jayashree Surana", label: "Jayashree Surana" },
  { value: "Jayasri Auto Finance Corporation", label: "Jayasri Auto Finance Corporation" },
  { value: "Jayathi Bai", label: "Jayathi Bai" },
  { value: "Jaybharat Credit Ltd", label: "Jaybharat Credit Ltd" },
  { value: "Jaybhavani Nag Sah Pat San Mydt-Kolhapur", label: "Jaybhavani Nag Sah Pat San Mydt-Kolhapur" },
  { value: "Jaylaal Finance", label: "Jaylaal Finance" },
  { value: "Jaylaxmi Co Op Credit Society Ltd", label: "Jaylaxmi Co Op Credit Society Ltd" },
  { value: "Jaylaxmi Credit Company Limited", label: "Jaylaxmi Credit Company Limited" },
  { value: "Jaylaxmi Enterprise", label: "Jaylaxmi Enterprise" },
  { value: "Jayotirling Gar Big Sah Path", label: "Jayotirling Gar Big Sah Path" },
  { value: "Jayprakash Narayan Nagarik Sahakari Bank Ltd", label: "Jayprakash Narayan Nagarik Sahakari Bank Ltd" },
  { value: "Jayshree Finance", label: "Jayshree Finance" },
  { value: "Jaysinghpur Udgaon Sahakari Bank Ltd", label: "Jaysinghpur Udgaon Sahakari Bank Ltd" },
  { value: "Jaysingpur Kirana Bhusar Vyapari Nagri Sahakari Pathsanstha Maryadit", label: "Jaysingpur Kirana Bhusar Vyapari Nagri Sahakari Pathsanstha Maryadit" },
  { value: "Jaysingpur Udgaon Co Bank Ltd.", label: "Jaysingpur Udgaon Co Bank Ltd." },
  { value: "Jaysingpur Udgaon Co-Op Bank Ltd", label: "Jaysingpur Udgaon Co-Op Bank Ltd" },
  { value: "Jaysingpur Udgaon Co-Op Bank, Ltd,", label: "Jaysingpur Udgaon Co-Op Bank, Ltd," },
  { value: "Jaysingpur Vyapari Nagari Sahakari Pat Sanstha", label: "Jaysingpur Vyapari Nagari Sahakari Pat Sanstha" },
  { value: "Jaysingpur Vyapari Sahakari Pathsanstha Maryadit", label: "Jaysingpur Vyapari Sahakari Pathsanstha Maryadit" },
  { value: "Jaysingpur-Udgaon Co-Op. Bank Ltd.", label: "Jaysingpur-Udgaon Co-Op. Bank Ltd." },
  { value: "Jayveer Auto Finance", label: "Jayveer Auto Finance" },
  { value: "Jayveer Lease Finance", label: "Jayveer Lease Finance" },
  { value: "Jbl Bank", label: "Jbl Bank" },
  { value: "Jcc Bank", label: "Jcc Bank" },
  { value: "Jccb", label: "Jccb" },
  { value: "Jeba Finance Ltd.", label: "Jeba Finance Ltd." },
  { value: "Jeeneshwar Auto  Finance", label: "Jeeneshwar Auto  Finance" },
  { value: "Jeeneshwar Auto Finance", label: "Jeeneshwar Auto Finance" },
  { value: "Jeetendra And Sons", label: "Jeetendra And Sons" },
  { value: "Jeevan Suraksha Insurance", label: "Jeevan Suraksha Insurance" },
  { value: "Jeevan Vikas Co-Op Credit Society Ltd.", label: "Jeevan Vikas Co-Op Credit Society Ltd." },
  { value: "Jeevan Vikas Nagari Sahakari Pathpedi", label: "Jeevan Vikas Nagari Sahakari Pathpedi" },
  { value: "Jeeveshwar Urban Co-Operative Credit Society Ltd", label: "Jeeveshwar Urban Co-Operative Credit Society Ltd" },
  { value: "Jeevo Credit & Finance Ltd", label: "Jeevo Credit & Finance Ltd" },
  { value: "Jenson And Nicholson Financial Services Limited", label: "Jenson And Nicholson Financial Services Limited" },
  { value: "Jessop And Co. Ltd.", label: "Jessop And Co. Ltd." },
  { value: "Jessy J", label: "Jessy J" },
  { value: "Jestin", label: "Jestin" },
  { value: "Jethmal Sethiya Financier", label: "Jethmal Sethiya Financier" },
  { value: "Jetpur Kheti V.V. Ka . Sahkari Mandli Ltd .", label: "Jetpur Kheti V.V. Ka . Sahkari Mandli Ltd ." },
  { value: "Jeya Murugan Finance", label: "Jeya Murugan Finance" },
  { value: "Jeyam Financiers", label: "Jeyam Financiers" },
  { value: "Jeyam Investments", label: "Jeyam Investments" },
  { value: "Jeyam Murugam", label: "Jeyam Murugam" },
  { value: "Jf Electra Advisors (India) Limited", label: "Jf Electra Advisors (India) Limited" },
  { value: "Jfc Finance", label: "Jfc Finance" },
  { value: "Jfc Leasing Pvt Ltd", label: "Jfc Leasing Pvt Ltd" },
  { value: "Jhabak Finance", label: "Jhabak Finance" },
  { value: "Jhabak Finance & Investment", label: "Jhabak Finance & Investment" },
  { value: "Jhabhak Auto Finance Corporation", label: "Jhabhak Auto Finance Corporation" },
  { value: "Jhabua Dhar Kshet Gramin Bank", label: "Jhabua Dhar Kshet Gramin Bank" },
  { value: "Jhabua Dhar Kshetriya Gram Bank", label: "Jhabua Dhar Kshetriya Gram Bank" },
  { value: "Jhabua Dhar Kshetriya Gramin Bank Ltd", label: "Jhabua Dhar Kshetriya Gramin Bank Ltd" },
  { value: "Jhabua-Dhar Keshetriya Gramin Bank,", label: "Jhabua-Dhar Keshetriya Gramin Bank," },
  { value: "Jhabua-Dhar Kshetriya Gramin Bank", label: "Jhabua-Dhar Kshetriya Gramin Bank" },
  { value: "Jhakwada Seva Sahakari Mandli Ltd", label: "Jhakwada Seva Sahakari Mandli Ltd" },
  { value: "Jhalawar District Central Co-Operative Bank Ltd.", label: "Jhalawar District Central Co-Operative Bank Ltd." },
  { value: "Jhalawar Kendriya Sahakari Bank Ltd.", label: "Jhalawar Kendriya Sahakari Bank Ltd." },
  { value: "Jhalawar Nagarik Sahakari Bank Ltd", label: "Jhalawar Nagarik Sahakari Bank Ltd" },
  { value: "Jhalawar Nagrik Sahakari Bank Ltd.", label: "Jhalawar Nagrik Sahakari Bank Ltd." },
  { value: "Jhane Gramin Bank", label: "Jhane Gramin Bank" },
  { value: "Jhargram Co Operative Agricultural Rural Development Bank Ltd", label: "Jhargram Co Operative Agricultural Rural Development Bank Ltd" },
  { value: "Jhargram Co-Op Land Development Bank Ltd", label: "Jhargram Co-Op Land Development Bank Ltd" },
  { value: "Jhargram Peoples Co-Operative Bank Ltd.", label: "Jhargram Peoples Co-Operative Bank Ltd." },
  { value: "Jharkhand Rajya Anusuchit Jati Sahkari Vikas Nigam", label: "Jharkhand Rajya Anusuchit Jati Sahkari Vikas Nigam" },
  { value: "Jharkhand State Tourism Development Corporation Ltd.", label: "Jharkhand State Tourism Development Corporation Ltd." },
  { value: "Jharneshwar Nag.Sah.Bank Mydt.,Bhopal", label: "Jharneshwar Nag.Sah.Bank Mydt.,Bhopal" },
  { value: "Jharneshwar Nagrik Sahkari Bank Limited.", label: "Jharneshwar Nagrik Sahkari Bank Limited." },
  { value: "Jhaveri Credits And Capital  Ltd.", label: "Jhaveri Credits And Capital  Ltd." },
  { value: "Jhunjhunu District Central Co-Operative Bank Ltd.", label: "Jhunjhunu District Central Co-Operative Bank Ltd." },
  { value: "Jhunjhunu Kendriya Sah Bk Ltd,", label: "Jhunjhunu Kendriya Sah Bk Ltd," },
  { value: "Jhunjhunu Kendriya Sahakari Bank Ltd.", label: "Jhunjhunu Kendriya Sahakari Bank Ltd." },
  { value: "Jiaganj Co-Operative Bank Ltd", label: "Jiaganj Co-Operative Bank Ltd" },
  { value: "Jian Financeir", label: "Jian Financeir" },
  { value: "Jiaram Auto Mobiles (P) Ltd", label: "Jiaram Auto Mobiles (P) Ltd" },
  { value: "Jigani Vyavasaya Seva Sah Bank Niyamitha", label: "Jigani Vyavasaya Seva Sah Bank Niyamitha" },
  { value: "Jigar Negandhi", label: "Jigar Negandhi" },
  { value: "Jihweshwar Urban Credit Co-Operative Society Ltd.", label: "Jihweshwar Urban Credit Co-Operative Society Ltd." },
  { value: "Jijamata Mahila Co-Op Cr Society Ltd.", label: "Jijamata Mahila Co-Op Cr Society Ltd." },
  { value: "Jijamata Mahila Nagari Pathsanstha", label: "Jijamata Mahila Nagari Pathsanstha" },
  { value: "Jijamata Mahila Nagari Sah. Bank Ltd.", label: "Jijamata Mahila Nagari Sah. Bank Ltd." },
  { value: "Jijamata Mahila Sahakari Bank Ltd.", label: "Jijamata Mahila Sahakari Bank Ltd." },
  { value: "Jijamata Mahila Sahakari Bank Ltd., Pune", label: "Jijamata Mahila Sahakari Bank Ltd., Pune" },
  { value: "Jijamata Mahila Urban Co-Op Bank Ltd", label: "Jijamata Mahila Urban Co-Op Bank Ltd" },
  { value: "Jijamata Mahila Urban Co-Op. Bank Ltd.", label: "Jijamata Mahila Urban Co-Op. Bank Ltd." },
  { value: "Jijamata Nagari Sah. Path Santha Maryadit", label: "Jijamata Nagari Sah. Path Santha Maryadit" },
  { value: "Jijamata Sahakari Bank Ltd.", label: "Jijamata Sahakari Bank Ltd." },
  { value: "Jijav Commercial Co Op Bank Ltd.", label: "Jijav Commercial Co Op Bank Ltd." },
  { value: "Jijo George", label: "Jijo George" },
  { value: "Jila Antayawasayi Sahakari Vikas Samiti", label: "Jila Antayawasayi Sahakari Vikas Samiti" },
  { value: "Jila Antayawasayi Sahakari Vikas Samiti Maryadit", label: "Jila Antayawasayi Sahakari Vikas Samiti Maryadit" },
  { value: "Jila Anthi Vyasayi", label: "Jila Anthi Vyasayi" },
  { value: "Jila Sah. Kendriys Bank M.", label: "Jila Sah. Kendriys Bank M." },
  { value: "Jila Sahakari Bank Ltd.", label: "Jila Sahakari Bank Ltd." },
  { value: "Jila Sahakari Kandriya Bank Maryadit", label: "Jila Sahakari Kandriya Bank Maryadit" },
  { value: "Jila Sahakari Ken. Bank Maryadit", label: "Jila Sahakari Ken. Bank Maryadit" },
  { value: "Jila Sahakari Kendriya Bank Mydt.", label: "Jila Sahakari Kendriya Bank Mydt." },
  { value: "Jila Sahakari Kendriya Bank Myt. Jabalpu", label: "Jila Sahakari Kendriya Bank Myt. Jabalpu" },
  { value: "Jila Sahakari Kendriya Bk Maryadit,Raipu", label: "Jila Sahakari Kendriya Bk Maryadit,Raipu" },
  { value: "Jila Sahakari Krishi And Gramin Vikas Bank", label: "Jila Sahakari Krishi And Gramin Vikas Bank" },
  { value: "Jila Sahakari Maryadit Bank", label: "Jila Sahakari Maryadit Bank" },
  { value: "Jila Sahkari Krishi And Gramin Vikas Bank", label: "Jila Sahkari Krishi And Gramin Vikas Bank" },
  { value: "Jimson John", label: "Jimson John" },
  { value: "Jind District Central Co-Operative Bank Ltd.", label: "Jind District Central Co-Operative Bank Ltd." },
  { value: "Jindal Financer", label: "Jindal Financer" },
  { value: "Jindal Fincap Ltd", label: "Jindal Fincap Ltd" },
  { value: "Jindal Instalment", label: "Jindal Instalment" },
  { value: "Jindal Instalment Supply Pvt Ltd", label: "Jindal Instalment Supply Pvt Ltd" },
  { value: "Jinder Finance Pvt Ltd", label: "Jinder Finance Pvt Ltd" },
  { value: "Jindesha Madar Alpsankhyatar Pattin Sahakari Sangh Niyamit", label: "Jindesha Madar Alpsankhyatar Pattin Sahakari Sangh Niyamit" },
  { value: "Jindutt Finance", label: "Jindutt Finance" },
  { value: "Jinender Auto Finance", label: "Jinender Auto Finance" },
  { value: "Jinendra Trading Co", label: "Jinendra Trading Co" },
  { value: "Jinesh Finance", label: "Jinesh Finance" },
  { value: "Jinesh Kumar Jain", label: "Jinesh Kumar Jain" },
  { value: "Jineshwar Auto Finance", label: "Jineshwar Auto Finance" },
  { value: "Jineswar Finance Corporation", label: "Jineswar Finance Corporation" },
  { value: "Jintur Urban Co-Op Bank Ltd", label: "Jintur Urban Co-Op Bank Ltd" },
  { value: "Jitendra Dharewa", label: "Jitendra Dharewa" },
  { value: "Jitendra Investments", label: "Jitendra Investments" },
  { value: "Jitendra Kumar Baid", label: "Jitendra Kumar Baid" },
  { value: "Jithendra Investments", label: "Jithendra Investments" },
  { value: "Jitwal  Finance  Ahmedgarh", label: "Jitwal  Finance  Ahmedgarh" },
  { value: "Jitwal Finance", label: "Jitwal Finance" },
  { value: "Jivaji Sahakari Bank Ltd.", label: "Jivaji Sahakari Bank Ltd." },
  { value: "Jivan Com. Co-Op Bk Ltd", label: "Jivan Com. Co-Op Bk Ltd" },
  { value: "Jivan Commercial Co Op Bank Ltd", label: "Jivan Commercial Co Op Bank Ltd" },
  { value: "Jivan Commercial Co-Op. Bank Ltd., Rajkot", label: "Jivan Commercial Co-Op. Bank Ltd., Rajkot" },
  { value: "Jivandhara Nagari Sah Pat Sanstha", label: "Jivandhara Nagari Sah Pat Sanstha" },
  { value: "Jivhala Nagari Sahakari Patsanstha", label: "Jivhala Nagari Sahakari Patsanstha" },
  { value: "Jiwan Jyoti Deposits And Advances Pvt Ltd", label: "Jiwan Jyoti Deposits And Advances Pvt Ltd" },
  { value: "Jiwan Vikas Nagarik Sahakari Patpedhi", label: "Jiwan Vikas Nagarik Sahakari Patpedhi" },
  { value: "Jiyana Juth Seva Sahkari Mandli", label: "Jiyana Juth Seva Sahkari Mandli" },
  { value: "Jk Prime Investment Co.", label: "Jk Prime Investment Co." },
  { value: "Jk Risk Managers & Insurance Brokers Ltd.", label: "Jk Risk Managers & Insurance Brokers Ltd." },
  { value: "Jkb Fruit Complex Jammu", label: "Jkb Fruit Complex Jammu" },
  { value: "Jks Enterprises", label: "Jks Enterprises" },
  { value: "Jmd Financial Services", label: "Jmd Financial Services" },
  { value: "Jmd Marketing Pvt Ltd", label: "Jmd Marketing Pvt Ltd" },
  { value: "Jodhpur District Central Co-Operative Bank Ltd.", label: "Jodhpur District Central Co-Operative Bank Ltd." },
  { value: "Jodhpur Nagrik Sahakari Bank Ltd.", label: "Jodhpur Nagrik Sahakari Bank Ltd." },
  { value: "Joginder Arora", label: "Joginder Arora" },
  { value: "Joginder Auto", label: "Joginder Auto" },
  { value: "Joginder Kaur", label: "Joginder Kaur" },
  { value: "Jogindra Bank Baddi", label: "Jogindra Bank Baddi" },
  { value: "Jogindra Centtral", label: "Jogindra Centtral" },
  { value: "Jogindra District Central Co-Operative Bank Ltd.", label: "Jogindra District Central Co-Operative Bank Ltd." },
  { value: "Johal Finance Company", label: "Johal Finance Company" },
  { value: "Johal Investment Pvt. Ltd.", label: "Johal Investment Pvt. Ltd." },
  { value: "Johar Finance", label: "Johar Finance" },
  { value: "Johar Financiers Ltd", label: "Johar Financiers Ltd" },
  { value: "Johar Mani Motors", label: "Johar Mani Motors" },
  { value: "Johnson & Johnson", label: "Johnson & Johnson" },
  { value: "Johnson V M", label: "Johnson V M" },
  { value: "Jokhiram Finance Cop", label: "Jokhiram Finance Cop" },
  { value: "Jolarpet Co-Operative Urban Bank Ltd", label: "Jolarpet Co-Operative Urban Bank Ltd" },
  { value: "Jorapokhar Packs Co-Oprative Bank", label: "Jorapokhar Packs Co-Oprative Bank" },
  { value: "Jose T K", label: "Jose T K" },
  { value: "Joshi Finance Co. Ltd", label: "Joshi Finance Co. Ltd" },
  { value: "Jothivel Murugan Investments", label: "Jothivel Murugan Investments" },
  { value: "Joy Bharat Credit Ltd", label: "Joy Bharat Credit Ltd" },
  { value: "Jpee Finance", label: "Jpee Finance" },
  { value: "Jpmorgan Chase Bank", label: "Jpmorgan Chase Bank" },
  { value: "Jsk Credit Corporation", label: "Jsk Credit Corporation" },
  { value: "Ju -Ju Finance And Leasing Ltd", label: "Ju -Ju Finance And Leasing Ltd" },
  { value: "Jubilee Auto Hire Purchase", label: "Jubilee Auto Hire Purchase" },
  { value: "Jubilee Hills Merc Co-Op Urban Bank Ltd.", label: "Jubilee Hills Merc Co-Op Urban Bank Ltd." },
  { value: "Jubillee Hills Merchantile Co-Op Urban Bank Ltd", label: "Jubillee Hills Merchantile Co-Op Urban Bank Ltd" },
  { value: "Judhraj Finassist Pvt Ltd", label: "Judhraj Finassist Pvt Ltd" },
  { value: "Jugraj Daga Investment", label: "Jugraj Daga Investment" },
  { value: "Jugraj Traders Transport", label: "Jugraj Traders Transport" },
  { value: "Juhie India Pvt Ltd", label: "Juhie India Pvt Ltd" },
  { value: "Jullunder Central Co-Op Bank Ltd., Jullunder", label: "Jullunder Central Co-Op Bank Ltd., Jullunder" },
  { value: "Jumar Bal Bagmar Huf", label: "Jumar Bal Bagmar Huf" },
  { value: "Jumar Mal Rajendra Kumar", label: "Jumar Mal Rajendra Kumar" },
  { value: "Junagadh Amreli Gramin Bank", label: "Junagadh Amreli Gramin Bank" },
  { value: "Junagadh Commercial Co-Op Bank Ltd", label: "Junagadh Commercial Co-Op Bank Ltd" },
  { value: "Junagadh District Central Co-Operative Bank Ltd.", label: "Junagadh District Central Co-Operative Bank Ltd." },
  { value: "Junagadh Jilla Sahakari Bank Ltd.", label: "Junagadh Jilla Sahakari Bank Ltd." },
  { value: "Junagadh Vibhagiy Nagrik Sahakari Bank L", label: "Junagadh Vibhagiy Nagrik Sahakari Bank L" },
  { value: "Junagadh Vibhagiya Sahakari Bank Ltd.", label: "Junagadh Vibhagiya Sahakari Bank Ltd." },
  { value: "Juneja Finance Co.", label: "Juneja Finance Co." },
  { value: "Junnar Nagari Sahakari Pat Sanstha Maryadit", label: "Junnar Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Junner Taluka Nagarik Sah Pathpedhi", label: "Junner Taluka Nagarik Sah Pathpedhi" },
  { value: "Juno Credit & Investment Ltd.", label: "Juno Credit & Investment Ltd." },
  { value: "Jupiter Finance Company Ltd", label: "Jupiter Finance Company Ltd" },
  { value: "Jupiter Investment", label: "Jupiter Investment" },
  { value: "Jupitor Finance", label: "Jupitor Finance" },
  { value: "Jute Corporation Of India Ltd.", label: "Jute Corporation Of India Ltd." },
  { value: "Jute Manufacturers Development Council", label: "Jute Manufacturers Development Council" },
  { value: "Juth Seva Sahkari Mandali Ramnagar", label: "Juth Seva Sahkari Mandali Ramnagar" },
  { value: "Jyothi Daga Invesment", label: "Jyothi Daga Invesment" },
  { value: "Jyothy .T.R", label: "Jyothy .T.R" },
  { value: "Jyoti Awaz Gramin Biger Sheti Sah Pat San Maryadit", label: "Jyoti Awaz Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Jyoti Co-Op Non Agri Socity Ltd", label: "Jyoti Co-Op Non Agri Socity Ltd" },
  { value: "Jyoti Co-Operative Credit Society Ltd.", label: "Jyoti Co-Operative Credit Society Ltd." },
  { value: "Jyoti Finance Corp", label: "Jyoti Finance Corp" },
  { value: "Jyoti Karanti Gramin Bigarsheti Sahahakari Patsansta", label: "Jyoti Karanti Gramin Bigarsheti Sahahakari Patsansta" },
  { value: "Jyoti Parkash", label: "Jyoti Parkash" },
  { value: "Jyoti Pattin Sahakari Bank Niyamit", label: "Jyoti Pattin Sahakari Bank Niyamit" },
  { value: "Jyoti Pattina Sahakari Sangh N", label: "Jyoti Pattina Sahakari Sangh N" },
  { value: "Jyoti Sahkari Pat Sanstha Maryadit", label: "Jyoti Sahkari Pat Sanstha Maryadit" },
  { value: "Jyoti Souharda Paththina Sahakari Niyamit", label: "Jyoti Souharda Paththina Sahakari Niyamit" },
  { value: "Jyotirling Gramin Bigar Sheti Sah Path Sanstha Marya", label: "Jyotirling Gramin Bigar Sheti Sah Path Sanstha Marya" },
  { value: "Jyotirling Nagari Sahakari Pathsansta", label: "Jyotirling Nagari Sahakari Pathsansta" },
  { value: "K & A Securities (P) Ltd", label: "K & A Securities (P) Ltd" },
  { value: "K 1247 Thaiak Karai Scoultay", label: "K 1247 Thaiak Karai Scoultay" },
  { value: "K 1247 Thalakkarai Co Operative Bank", label: "K 1247 Thalakkarai Co Operative Bank" },
  { value: "K 1434 Senjeri Malayadi Palayam Fac", label: "K 1434 Senjeri Malayadi Palayam Fac" },
  { value: "K 442 Pethampalayam Pacb Ltd", label: "K 442 Pethampalayam Pacb Ltd" },
  { value: "K A Bank Nongyndongri", label: "K A Bank Nongyndongri" },
  { value: "K A Radha Krishna", label: "K A Radha Krishna" },
  { value: "K B C D C", label: "K B C D C" },
  { value: "K B Dham Juth V K S Mandali", label: "K B Dham Juth V K S Mandali" },
  { value: "K B Finance", label: "K B Finance" },
  { value: "K B Investments", label: "K B Investments" },
  { value: "K B Patil Zilha Sahakari Patsanstha Mydt", label: "K B Patil Zilha Sahakari Patsanstha Mydt" },
  { value: "K C And Sons", label: "K C And Sons" },
  { value: "K C Investments", label: "K C Investments" },
  { value: "K C K Finance", label: "K C K Finance" },
  { value: "K C S Auto Finance", label: "K C S Auto Finance" },
  { value: "K Chinna Mala Kondaiah Auto Mobile Financiers", label: "K Chinna Mala Kondaiah Auto Mobile Financiers" },
  { value: "K Chinna Mallakondaiah A/F", label: "K Chinna Mallakondaiah A/F" },
  { value: "K Chinnamala Financiers", label: "K Chinnamala Financiers" },
  { value: "K D C C Bank - Dandeli", label: "K D C C Bank - Dandeli" },
  { value: "K D Finance Corporation", label: "K D Finance Corporation" },
  { value: "K D Jimmy", label: "K D Jimmy" },
  { value: "K Deepak Finance", label: "K Deepak Finance" },
  { value: "K Devendra Sarda Nagari Sah Pathsanstha Ltd.", label: "K Devendra Sarda Nagari Sah Pathsanstha Ltd." },
  { value: "K Dilip Burad", label: "K Dilip Burad" },
  { value: "K Ds Leasing And Finance Co", label: "K Ds Leasing And Finance Co" },
  { value: "K G Auto Finance", label: "K G Auto Finance" },
  { value: "K G Finance", label: "K G Finance" },
  { value: "K G Janardhanan", label: "K G Janardhanan" },
  { value: "K G S G B", label: "K G S G B" },
  { value: "K G Santhosh", label: "K G Santhosh" },
  { value: "K G Valasu Pacp Bank", label: "K G Valasu Pacp Bank" },
  { value: "K H Finance", label: "K H Finance" },
  { value: "K H Konda Reddy", label: "K H Konda Reddy" },
  { value: "K Hari Prasad", label: "K Hari Prasad" },
  { value: "K J B Ltd", label: "K J B Ltd" },
  { value: "K J Finance", label: "K J Finance" },
  { value: "K K Enterprise Financiers", label: "K K Enterprise Financiers" },
  { value: "K K G Bank", label: "K K G Bank" },
  { value: "K K Motor Financer", label: "K K Motor Financer" },
  { value: "K Kamal Chand Bhandari Gyan Bagh Colony", label: "K Kamal Chand Bhandari Gyan Bagh Colony" },
  { value: "K Kondala Rao Auto Mobile Financier", label: "K Kondala Rao Auto Mobile Financier" },
  { value: "K Leena Nahar", label: "K Leena Nahar" },
  { value: "K M M L Ltd Chavara", label: "K M M L Ltd Chavara" },
  { value: "K M S Finance Ltd", label: "K M S Finance Ltd" },
  { value: "K Malakondaiah Auto Mobile Finance", label: "K Malakondaiah Auto Mobile Finance" },
  { value: "K Nanha Gyan Finance", label: "K Nanha Gyan Finance" },
  { value: "K Nanitalalmora Kshetriya Gramin Bank", label: "K Nanitalalmora Kshetriya Gramin Bank" },
  { value: "K P Finance Money", label: "K P Finance Money" },
  { value: "K P Investment", label: "K P Investment" },
  { value: "K P S S Ltd", label: "K P S S Ltd" },
  { value: "K Prasan Chand Bandari Finance", label: "K Prasan Chand Bandari Finance" },
  { value: "K Prasanna", label: "K Prasanna" },
  { value: "K R B", label: "K R B" },
  { value: "K R C Finance", label: "K R C Finance" },
  { value: "K R C Finance Company", label: "K R C Finance Company" },
  { value: "K R Credit Corporation", label: "K R Credit Corporation" },
  { value: "K R M Finance", label: "K R M Finance" },
  { value: "K Rajat & Co", label: "K Rajat & Co" },
  { value: "K Rajat And Company", label: "K Rajat And Company" },
  { value: "K Rajendar Kumar Sankhala", label: "K Rajendar Kumar Sankhala" },
  { value: "K S And Company", label: "K S And Company" },
  { value: "K S Auto T.P Nagar", label: "K S Auto T.P Nagar" },
  { value: "K S Motor", label: "K S Motor" },
  { value: "K S R Finance P Ltd", label: "K S R Finance P Ltd" },
  { value: "K Sarada Kawar Financier", label: "K Sarada Kawar Financier" },
  { value: "K Shoba Auto Finance", label: "K Shoba Auto Finance" },
  { value: "K Shoba Autp Finance", label: "K Shoba Autp Finance" },
  { value: "K Sohanlal Ostwal & Sons", label: "K Sohanlal Ostwal & Sons" },
  { value: "K Suresh And Co", label: "K Suresh And Co" },
  { value: "K T C Automobile", label: "K T C Automobile" },
  { value: "K Ugamraj Park", label: "K Ugamraj Park" },
  { value: "K Usha Rani Automobile Finance", label: "K Usha Rani Automobile Finance" },
  { value: "K Usharani Finance", label: "K Usharani Finance" },
  { value: "K V B Finance", label: "K V B Finance" },
  { value: "K V G Bank", label: "K V G Bank" },
  { value: "K V Leasing And Finance Co.", label: "K V Leasing And Finance Co." },
  { value: "K Varalaxmi Automobile Financier", label: "K Varalaxmi Automobile Financier" },
  { value: "K Vinay Chand Kothari Huf", label: "K Vinay Chand Kothari Huf" },
  { value: "K Vishnu Mhasu Borgude Gramin Bigar Sheti Sanstha", label: "K Vishnu Mhasu Borgude Gramin Bigar Sheti Sanstha" },
  { value: "K. Brahma Naidu", label: "K. Brahma Naidu" },
  { value: "K. C. Fincap Pvt. Ltd.", label: "K. C. Fincap Pvt. Ltd." },
  { value: "K. Motor Finance Company", label: "K. Motor Finance Company" },
  { value: "K. R. T. Finance Pvt Ltd.", label: "K. R. T. Finance Pvt Ltd." },
  { value: "K. S. Capital Services Ltd", label: "K. S. Capital Services Ltd" },
  { value: "K. Vikas Kumar Choradia", label: "K. Vikas Kumar Choradia" },
  { value: "K. Vikashchand Financiers", label: "K. Vikashchand Financiers" },
  { value: "K. Y. Credit Corporation", label: "K. Y. Credit Corporation" },
  { value: "K.2049 P.Npalayam Agri .Co-Op.Bank", label: "K.2049 P.Npalayam Agri .Co-Op.Bank" },
  { value: "K.A.S.Auto Finance", label: "K.A.S.Auto Finance" },
  { value: "K.Arumugam Financier", label: "K.Arumugam Financier" },
  { value: "K.Ashok Nahar", label: "K.Ashok Nahar" },
  { value: "K.C . Enterprises.", label: "K.C . Enterprises." },
  { value: "K.C.C Bank", label: "K.C.C Bank" },
  { value: "K.C.C. Bank", label: "K.C.C. Bank" },
  { value: "K.C.Motors (Unit Of K C Mall & Estates Pvtltd)", label: "K.C.Motors (Unit Of K C Mall & Estates Pvtltd)" },
  { value: "K.C.S. Auto Finance", label: "K.C.S. Auto Finance" },
  { value: "K.Chinna Mala Kondaiah Auto Financiers", label: "K.Chinna Mala Kondaiah Auto Financiers" },
  { value: "K.D. Finance", label: "K.D. Finance" },
  { value: "K.D. Finance Corp", label: "K.D. Finance Corp" },
  { value: "K.D. Motor Finance Co.", label: "K.D. Motor Finance Co." },
  { value: "K.D.C.C. Bank", label: "K.D.C.C. Bank" },
  { value: "K.D.M.C Ltd", label: "K.D.M.C Ltd" },
  { value: "K.D.S.Leasing & Finance Co Ltd", label: "K.D.S.Leasing & Finance Co Ltd" },
  { value: "K.G. Enterprises", label: "K.G. Enterprises" },
  { value: "K.G.B. Kunchavaram", label: "K.G.B. Kunchavaram" },
  { value: "K.G.B.S. State Bank", label: "K.G.B.S. State Bank" },
  { value: "K.G.Enterprises", label: "K.G.Enterprises" },
  { value: "K.J.Enterprises", label: "K.J.Enterprises" },
  { value: "K.K. Motor Finance Co.", label: "K.K. Motor Finance Co." },
  { value: "K.K. Tyres", label: "K.K. Tyres" },
  { value: "K.K.Finance & Investments", label: "K.K.Finance & Investments" },
  { value: "K.K.R Finance", label: "K.K.R Finance" },
  { value: "K.M. Dastur Reinsurance Brokers Pvt. Ltd.", label: "K.M. Dastur Reinsurance Brokers Pvt. Ltd." },
  { value: "K.M.B.C", label: "K.M.B.C" },
  { value: "K.M.D.C", label: "K.M.D.C" },
  { value: "K.Narayanan", label: "K.Narayanan" },
  { value: "K.P Auto Motives (P) Ltd", label: "K.P Auto Motives (P) Ltd" },
  { value: "K.P Finance", label: "K.P Finance" },
  { value: "K.P. Enterprise", label: "K.P. Enterprise" },
  { value: "K.P. Finance", label: "K.P. Finance" },
  { value: "K.P. Traders", label: "K.P. Traders" },
  { value: "K.R And Company", label: "K.R And Company" },
  { value: "K.R Finance", label: "K.R Finance" },
  { value: "K.R.B", label: "K.R.B" },
  { value: "K.R.C. Finance Company Ramanathapuram", label: "K.R.C. Finance Company Ramanathapuram" },
  { value: "K.Raj Trading Co.", label: "K.Raj Trading Co." },
  { value: "K.Rajendra Sankhala Sons", label: "K.Rajendra Sankhala Sons" },
  { value: "K.S .Motors", label: "K.S .Motors" },
  { value: "K.S Auto Sale", label: "K.S Auto Sale" },
  { value: "K.S. Motors", label: "K.S. Motors" },
  { value: "K.S.F.C . Ltd.", label: "K.S.F.C . Ltd." },
  { value: "K.S.Motors", label: "K.S.Motors" },
  { value: "K.Sajjan Raj Jain", label: "K.Sajjan Raj Jain" },
  { value: "K.V.Enterpries", label: "K.V.Enterpries" },
  { value: "K.Vikas Kumar Chorodia", label: "K.Vikas Kumar Chorodia" },
  { value: "K.Z.Leasing And Finance Ltd.", label: "K.Z.Leasing And Finance Ltd." },
  { value: "K2069 Veerakeralam Pacb", label: "K2069 Veerakeralam Pacb" },
  { value: "Ka Bank Nongkyndong Ri Khasi Jaintia", label: "Ka Bank Nongkyndong Ri Khasi Jaintia" },
  { value: "Kabsons Finance Ltd.", label: "Kabsons Finance Ltd." },
  { value: "Kachappilly Finance&Investments", label: "Kachappilly Finance&Investments" },
  { value: "Kadadi Auto Finance And Leasing", label: "Kadadi Auto Finance And Leasing" },
  { value: "Kadadra Nagrik Mandli", label: "Kadadra Nagrik Mandli" },
  { value: "Kadakampally Service Co.Op. Bank Ltd", label: "Kadakampally Service Co.Op. Bank Ltd" },
  { value: "Kadam Financiers", label: "Kadam Financiers" },
  { value: "Kadamba Credit Co-Op Ltd", label: "Kadamba Credit Co-Op Ltd" },
  { value: "Kadamba Credit Souharda Co-Op Ltd - Sirsi", label: "Kadamba Credit Souharda Co-Op Ltd - Sirsi" },
  { value: "Kadannappalli Panapuzha Ser.Co.Op.Bank", label: "Kadannappalli Panapuzha Ser.Co.Op.Bank" },
  { value: "Kadannappally Panapuzha Service Co-Op Bank", label: "Kadannappally Panapuzha Service Co-Op Bank" },
  { value: "Kadaplamattom Service Co Bank Ltd.", label: "Kadaplamattom Service Co Bank Ltd." },
  { value: "Kadasiddeshwar Leasing", label: "Kadasiddeshwar Leasing" },
  { value: "Kadasiddeshwar Pattan Sahakari Bank Niyamit", label: "Kadasiddeshwar Pattan Sahakari Bank Niyamit" },
  { value: "Kadayampatty Pri. Agricultural Coop Bk L", label: "Kadayampatty Pri. Agricultural Coop Bk L" },
  { value: "Kadepathar Gramin Bigar Sheti Sah Path Sanstha.", label: "Kadepathar Gramin Bigar Sheti Sah Path Sanstha." },
  { value: "Kadodra Nagrik Mandli", label: "Kadodra Nagrik Mandli" },
  { value: "Kadshiddeshwar Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit", label: "Kadshiddeshwar Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit" },
  { value: "Kadungallor Services Coop Bank Ltd,", label: "Kadungallor Services Coop Bank Ltd," },
  { value: "Kadur Primary Agri. Co-Op. B.L.", label: "Kadur Primary Agri. Co-Op. B.L." },
  { value: "Kagal Co-Op Bank Ltd", label: "Kagal Co-Op Bank Ltd" },
  { value: "Kagal Taluka Co Op Credit Soc Ltd", label: "Kagal Taluka Co Op Credit Soc Ltd" },
  { value: "Kagungalloor Service Co-Op Bank Ltd,", label: "Kagungalloor Service Co-Op Bank Ltd," },
  { value: "Kahanur Pathar Nagari Sahakari Patsanstha", label: "Kahanur Pathar Nagari Sahakari Patsanstha" },
  { value: "Kai Dharamvir Jagdish Bhosale Mori Sah Pat", label: "Kai Dharamvir Jagdish Bhosale Mori Sah Pat" },
  { value: "Kai Panditrao Nagargoje Sahakari Path Sanstha Ltd Barshi", label: "Kai Panditrao Nagargoje Sahakari Path Sanstha Ltd Barshi" },
  { value: "Kai Subhashchandji Parakh Nagari Sah Path Ltd", label: "Kai Subhashchandji Parakh Nagari Sah Path Ltd" },
  { value: "Kai Tatyasaheb Madhavrao Patil Gramin Bigar Sheti Sah Pat Sanstha Maryadit", label: "Kai Tatyasaheb Madhavrao Patil Gramin Bigar Sheti Sah Pat Sanstha Maryadit" },
  { value: "Kai. Adv. D.Y. Wanepatil Rahuri Taluka", label: "Kai. Adv. D.Y. Wanepatil Rahuri Taluka" },
  { value: "Kaila Auto Finance", label: "Kaila Auto Finance" },
  { value: "Kailash Auto Finance Ltd.", label: "Kailash Auto Finance Ltd." },
  { value: "Kailash Chand Chorasia", label: "Kailash Chand Chorasia" },
  { value: "Kailash Chand Surana", label: "Kailash Chand Surana" },
  { value: "Kailash Dhut & Company", label: "Kailash Dhut & Company" },
  { value: "Kailash Finance Cor.", label: "Kailash Finance Cor." },
  { value: "Kailash Hire Purchase", label: "Kailash Hire Purchase" },
  { value: "Kailash Motors", label: "Kailash Motors" },
  { value: "Kailash Nagari Sahakari Patsanstha", label: "Kailash Nagari Sahakari Patsanstha" },
  { value: "Kailsh Chand Surana", label: "Kailsh Chand Surana" },
  { value: "Kairali Hire Purchase", label: "Kairali Hire Purchase" },
  { value: "Kairasi Finance", label: "Kairasi Finance" },
  { value: "Kaithal District Central Co-Operative Bank Ltd.", label: "Kaithal District Central Co-Operative Bank Ltd." },
  { value: "Kaithamalai Kumaran Finance", label: "Kaithamalai Kumaran Finance" },
  { value: "Kaitharam Service Co. Bk Ltd", label: "Kaitharam Service Co. Bk Ltd" },
  { value: "Kaivara S.F.S.C. Bank Limited", label: "Kaivara S.F.S.C. Bank Limited" },
  { value: "Kajal Goyal", label: "Kajal Goyal" },
  { value: "Kajekar C. A Bank N,", label: "Kajekar C. A Bank N," },
  { value: "Kajeria Auto Finance", label: "Kajeria Auto Finance" },
  { value: "Kaka Auto Deals", label: "Kaka Auto Deals" },
  { value: "Kaka Motors", label: "Kaka Motors" },
  { value: "Kakar Auto Centre", label: "Kakar Auto Centre" },
  { value: "Kakariya Finance Co", label: "Kakariya Finance Co" },
  { value: "Kakasaheb Thorat Gramin Bigar Sheti Pathsanstha", label: "Kakasaheb Thorat Gramin Bigar Sheti Pathsanstha" },
  { value: "Kakathiya Grameena Bank", label: "Kakathiya Grameena Bank" },
  { value: "Kakatiya Grameena Bank", label: "Kakatiya Grameena Bank" },
  { value: "Kakinada Co-Op Town Bank Ltd", label: "Kakinada Co-Op Town Bank Ltd" },
  { value: "Kakinada(E.G District Central Co-Operative Bank Ltd.", label: "Kakinada(E.G District Central Co-Operative Bank Ltd." },
  { value: "Kakoo Motor Finance P Ltd", label: "Kakoo Motor Finance P Ltd" },
  { value: "Kakrapara Atomic Power Station", label: "Kakrapara Atomic Power Station" },
  { value: "Kala-Ratan Co Op Credit Society Ltd", label: "Kala-Ratan Co Op Credit Society Ltd" },
  { value: "Kalady Farmers Co-Op Bank Ltd", label: "Kalady Farmers Co-Op Bank Ltd" },
  { value: "Kalahandi Anchalik Gramya Bank", label: "Kalahandi Anchalik Gramya Bank" },
  { value: "Kalahandi Anchalika  Gramya Bank", label: "Kalahandi Anchalika  Gramya Bank" },
  { value: "Kalai Finance Ltd", label: "Kalai Finance Ltd" },
  { value: "Kalai Investments", label: "Kalai Investments" },
  { value: "Kalai Puli Finance", label: "Kalai Puli Finance" },
  { value: "Kalaipulai Finance", label: "Kalaipulai Finance" },
  { value: "Kalaipuli  Finance", label: "Kalaipuli  Finance" },
  { value: "Kalaiselvi Finance", label: "Kalaiselvi Finance" },
  { value: "Kalakkodu Ser Co Bank Ltd.", label: "Kalakkodu Ser Co Bank Ltd." },
  { value: "Kalani Enterprises", label: "Kalani Enterprises" },
  { value: "Kalani Financer", label: "Kalani Financer" },
  { value: "Kalapandi Anchalika Gramin Bank", label: "Kalapandi Anchalika Gramin Bank" },
  { value: "Kalapet Primary Agri. Co-Op. Bk.L.", label: "Kalapet Primary Agri. Co-Op. Bk.L." },
  { value: "Kalapur Com Co Op Bank Ltd", label: "Kalapur Com Co Op Bank Ltd" },
  { value: "Kalarcode Service Co-Op. Bank Ltd.", label: "Kalarcode Service Co-Op. Bank Ltd." },
  { value: "Kalas Utkarsh Sahakari Patpedhi Ltd.", label: "Kalas Utkarsh Sahakari Patpedhi Ltd." },
  { value: "Kalasa Cooperative Agricultural Bank Ltd", label: "Kalasa Cooperative Agricultural Bank Ltd" },
  { value: "Kalasar Juth Seva Sahkari Mandli Ltd", label: "Kalasar Juth Seva Sahkari Mandli Ltd" },
  { value: "Kalash Chand Surana", label: "Kalash Chand Surana" },
  { value: "Kalawad Seva Sahakari Mandli Ltd", label: "Kalawad Seva Sahakari Mandli Ltd" },
  { value: "Kalayani Mahila Nagari Path Sanstha", label: "Kalayani Mahila Nagari Path Sanstha" },
  { value: "Kalbhairav Nagari Sahakari Patsanstha", label: "Kalbhairav Nagari Sahakari Patsanstha" },
  { value: "Kalbhairavnath Gramin Bigar Sheti Sah Pat", label: "Kalbhairavnath Gramin Bigar Sheti Sah Pat" },
  { value: "Kalbhairavnath Sahakari Pathsanshta", label: "Kalbhairavnath Sahakari Pathsanshta" },
  { value: "Kalgatgi Urban Co-Op Bank Ltd", label: "Kalgatgi Urban Co-Op Bank Ltd" },
  { value: "Kalgidhar Finance Company", label: "Kalgidhar Finance Company" },
  { value: "Kalgihdar Finance Company Ltd", label: "Kalgihdar Finance Company Ltd" },
  { value: "Kaliara S.K.U.S. Ltd.", label: "Kaliara S.K.U.S. Ltd." },
  { value: "Kalidaikuruchi Co-Op Urban Bank Ltd", label: "Kalidaikuruchi Co-Op Urban Bank Ltd" },
  { value: "Kalidas Co-Op Credit Society Ltd.", label: "Kalidas Co-Op Credit Society Ltd." },
  { value: "Kalidasa Co-Op Bank Ltd", label: "Kalidasa Co-Op Bank Ltd" },
  { value: "Kalikamata Gramin Bigarsheti Sahakari Bank", label: "Kalikamata Gramin Bigarsheti Sahakari Bank" },
  { value: "Kalinga Associates", label: "Kalinga Associates" },
  { value: "Kalinga Gramin Bank", label: "Kalinga Gramin Bank" },
  { value: "Kalinga Gramya Bank,", label: "Kalinga Gramya Bank," },
  { value: "Kalladikode Sevice Co-Op Bank Ltd.", label: "Kalladikode Sevice Co-Op Bank Ltd." },
  { value: "Kallakurichi P.A.C.B Ltd", label: "Kallakurichi P.A.C.B Ltd" },
  { value: "Kallamundkur Vyavasaya Seva Sah Bank Ltd", label: "Kallamundkur Vyavasaya Seva Sah Bank Ltd" },
  { value: "Kallamundkur Vyavasaya Seva Saha Bk, Niy", label: "Kallamundkur Vyavasaya Seva Saha Bk, Niy" },
  { value: "Kallappaanna Awade Ichalkaranji Janta Sahakari Bank", label: "Kallappaanna Awade Ichalkaranji Janta Sahakari Bank" },
  { value: "Kallappanna Awade Ichalkaranji Janata Sah Bank Ltd Solapur", label: "Kallappanna Awade Ichalkaranji Janata Sah Bank Ltd Solapur" },
  { value: "Kallappanna Awade Ichalkaranji Janata Sahakari Bank Ltd", label: "Kallappanna Awade Ichalkaranji Janata Sahakari Bank Ltd" },
  { value: "Kallel Auto Finance", label: "Kallel Auto Finance" },
  { value: "Kallettumkara Service Co-Op Bank Ltd", label: "Kallettumkara Service Co-Op Bank Ltd" },
  { value: "Kalliyoor Service Co-Op Bank Ltd", label: "Kalliyoor Service Co-Op Bank Ltd" },
  { value: "Kallupur Co-Op Bank", label: "Kallupur Co-Op Bank" },
  { value: "Kallur Chits & Finance Pvt Ltd", label: "Kallur Chits & Finance Pvt Ltd" },
  { value: "Kalol Urban Co-Op Bank Ltd", label: "Kalol Urban Co-Op Bank Ltd" },
  { value: "Kalpa Taru Investments And Financiers", label: "Kalpa Taru Investments And Financiers" },
  { value: "Kalpady Primary Agricultural Co-Op Bk L.", label: "Kalpady Primary Agricultural Co-Op Bk L." },
  { value: "Kalpaiguri Cen Co Op Bk Ltd", label: "Kalpaiguri Cen Co Op Bk Ltd" },
  { value: "Kalpaka Motors", label: "Kalpaka Motors" },
  { value: "Kalpana Sethiya", label: "Kalpana Sethiya" },
  { value: "Kalpataru Enterprise", label: "Kalpataru Enterprise" },
  { value: "Kalpataru Finance Corporation", label: "Kalpataru Finance Corporation" },
  { value: "Kalpataru Finance Financiers", label: "Kalpataru Finance Financiers" },
  { value: "Kalpataru Grameen Bank", label: "Kalpataru Grameen Bank" },
  { value: "Kalpataru Gramina Branch", label: "Kalpataru Gramina Branch" },
  { value: "Kalpataru Vividdodeshagala Sahakari Sangh Niyamit", label: "Kalpataru Vividdodeshagala Sahakari Sangh Niyamit" },
  { value: "Kalpatharu Grameena Bank", label: "Kalpatharu Grameena Bank" },
  { value: "Kalpavaruiksha Co-Op Bank Ltd", label: "Kalpavaruiksha Co-Op Bank Ltd" },
  { value: "Kalpavriksha Nagri Sah Path Sanshta Marya", label: "Kalpavriksha Nagri Sah Path Sanshta Marya" },
  { value: "Kalpavruksha Co-Op Bank Ltd,", label: "Kalpavruksha Co-Op Bank Ltd," },
  { value: "Kalpvrussha Gramin Biger Sh Sah Pat San", label: "Kalpvrussha Gramin Biger Sh Sah Pat San" },
  { value: "Kalra Finance Co", label: "Kalra Finance Co" },
  { value: "Kalra Hire Purchase Finance Co. Pvt.Ltd.", label: "Kalra Hire Purchase Finance Co. Pvt.Ltd." },
  { value: "Kalupur Commercial Co-Op Bank Ltd.", label: "Kalupur Commercial Co-Op Bank Ltd." },
  { value: "Kalupur Nagarik Sahakari Bank Ltd.", label: "Kalupur Nagarik Sahakari Bank Ltd." },
  { value: "Kalvakuri Ramanayya Automobile Fiinancier", label: "Kalvakuri Ramanayya Automobile Fiinancier" },
  { value: "Kalwa-Belapur Bank Ltd", label: "Kalwa-Belapur Bank Ltd" },
  { value: "Kalwan Merchants Co-Op Bank Ltd", label: "Kalwan Merchants Co-Op Bank Ltd" },
  { value: "Kalyan Co-Op Bank Ltd", label: "Kalyan Co-Op Bank Ltd" },
  { value: "Kalyan Finance Co Ltd", label: "Kalyan Finance Co Ltd" },
  { value: "Kalyan Financer", label: "Kalyan Financer" },
  { value: "Kalyan Financial Services Ltd.", label: "Kalyan Financial Services Ltd." },
  { value: "Kalyan Janata Sahakari Bank Ltd., Kalyan", label: "Kalyan Janata Sahakari Bank Ltd., Kalyan" },
  { value: "Kalyani Chits & Finance", label: "Kalyani Chits & Finance" },
  { value: "Kalyani Mahila Nagari Sah Path Sanstha Ltd", label: "Kalyani Mahila Nagari Sah Path Sanstha Ltd" },
  { value: "Kalyani Mahila Sahkari Patsantha Maryadit", label: "Kalyani Mahila Sahkari Patsantha Maryadit" },
  { value: "Kalyani Mahila Sahkari Patsantha Maryadit", label: "Kalyani Mahila Sahkari Patsantha Maryadit" },
  { value: "Kalyani Nag Saha Pat Sanstha", label: "Kalyani Nag Saha Pat Sanstha" },
  { value: "Kalyani Roy", label: "Kalyani Roy" },
  { value: "Kalyansagar Bank", label: "Kalyansagar Bank" },
  { value: "Kalyansagar Urban C.O.B.L,", label: "Kalyansagar Urban C.O.B.L," },
  { value: "Kalyansagar Urban Co-Op Bank Ltd", label: "Kalyansagar Urban Co-Op Bank Ltd" },
  { value: "Kamadenu Investments", label: "Kamadenu Investments" },
  { value: "Kamadhenu Auto Finance", label: "Kamadhenu Auto Finance" },
  { value: "Kamadhenu Corporation", label: "Kamadhenu Corporation" },
  { value: "Kamadhenu Finance", label: "Kamadhenu Finance" },
  { value: "Kamadhenu Financing Corp", label: "Kamadhenu Financing Corp" },
  { value: "Kamadhenu Investments", label: "Kamadhenu Investments" },
  { value: "Kamakhia Finance Leasing Ltd.", label: "Kamakhia Finance Leasing Ltd." },
  { value: "Kamal & Co Finance", label: "Kamal & Co Finance" },
  { value: "Kamal & Company", label: "Kamal & Company" },
  { value: "Kamal And Co.Finance", label: "Kamal And Co.Finance" },
  { value: "Kamal Auto Finance Ltd.", label: "Kamal Auto Finance Ltd." },
  { value: "Kamal Chand Kothari", label: "Kamal Chand Kothari" },
  { value: "Kamal Deep Auto Finance", label: "Kamal Deep Auto Finance" },
  { value: "Kamal Deep Finance", label: "Kamal Deep Finance" },
  { value: "Kamal Enterprises", label: "Kamal Enterprises" },
  { value: "Kamal Finance Co.", label: "Kamal Finance Co." },
  { value: "Kamal Hire Purchase", label: "Kamal Hire Purchase" },
  { value: "Kamal Investments", label: "Kamal Investments" },
  { value: "Kamal Kumar Jain", label: "Kamal Kumar Jain" },
  { value: "Kamal Mahila Nagar Sah Patpedhi Ltd", label: "Kamal Mahila Nagar Sah Patpedhi Ltd" },
  { value: "Kamal Nagari Sahakari Patsanstha Maryadit", label: "Kamal Nagari Sahakari Patsanstha Maryadit" },
  { value: "Kamala Auto Finance", label: "Kamala Auto Finance" },
  { value: "Kamala Bai Finance", label: "Kamala Bai Finance" },
  { value: "Kamala Co-Op. Bank Ltd.", label: "Kamala Co-Op. Bank Ltd." },
  { value: "Kamala Kanvarjain", label: "Kamala Kanvarjain" },
  { value: "Kamala Kumari N Banthia", label: "Kamala Kumari N Banthia" },
  { value: "Kamala Motor Finance", label: "Kamala Motor Finance" },
  { value: "Kamala Priya Finance", label: "Kamala Priya Finance" },
  { value: "Kamaladevi", label: "Kamaladevi" },
  { value: "Kamalambika Co-Op Urban Bank Ltd", label: "Kamalambika Co-Op Urban Bank Ltd" },
  { value: "Kamaldeep Auto Finance", label: "Kamaldeep Auto Finance" },
  { value: "Kamaldeep Enterprise", label: "Kamaldeep Enterprise" },
  { value: "Kamaldeep Singh", label: "Kamaldeep Singh" },
  { value: "Kamaldeep Traders", label: "Kamaldeep Traders" },
  { value: "Kamalini Singhal", label: "Kamalini Singhal" },
  { value: "Kamalja Devi Gramin Bigar Sheti Sahakari", label: "Kamalja Devi Gramin Bigar Sheti Sahakari" },
  { value: "Kamalsethiya", label: "Kamalsethiya" },
  { value: "Kamaraj Co-Op Town Bank Ltd", label: "Kamaraj Co-Op Town Bank Ltd" },
  { value: "Kamarajar District Central Co-Op Bank Lt", label: "Kamarajar District Central Co-Op Bank Lt" },
  { value: "Kamarez Vibhag Nagrik Bachat Ane Dhiran Karnari Sahakari Mandali Ltd", label: "Kamarez Vibhag Nagrik Bachat Ane Dhiran Karnari Sahakari Mandali Ltd" },
  { value: "Kamayagoundanpatty Pri. Agri.C.B.Ltd.", label: "Kamayagoundanpatty Pri. Agri.C.B.Ltd." },
  { value: "Kamayagoundanpatty Primary Agri. Bk Ltd", label: "Kamayagoundanpatty Primary Agri. Bk Ltd" },
  { value: "Kamdhenu Auto Finance", label: "Kamdhenu Auto Finance" },
  { value: "Kamdhenu Gramin B Shethi", label: "Kamdhenu Gramin B Shethi" },
  { value: "Kamdhenu Gramin B Shethi", label: "Kamdhenu Gramin B Shethi" },
  { value: "Kamdhenu Hire Purchase Co", label: "Kamdhenu Hire Purchase Co" },
  { value: "Kamkhia Finance & Leasing Ltd", label: "Kamkhia Finance & Leasing Ltd" },
  { value: "Kamlchand Kothan", label: "Kamlchand Kothan" },
  { value: "Kamlesh Finance", label: "Kamlesh Finance" },
  { value: "Kamlesh Kumar Surana", label: "Kamlesh Kumar Surana" },
  { value: "Kammavari Credit Co-Op. Society Ltd.", label: "Kammavari Credit Co-Op. Society Ltd." },
  { value: "Kamod Commercial And Finance Ltd.", label: "Kamod Commercial And Finance Ltd." },
  { value: "Kamra Associates Regd", label: "Kamra Associates Regd" },
  { value: "Kamra Financiers", label: "Kamra Financiers" },
  { value: "Kamraz Rural Bank,", label: "Kamraz Rural Bank," },
  { value: "Kamrej Vibhag Nagrik Bachat Dhiran Karnari Mandali Ltd", label: "Kamrej Vibhag Nagrik Bachat Dhiran Karnari Mandali Ltd" },
  { value: "Kamrej Vibhag Nagrik Sahakari Bank", label: "Kamrej Vibhag Nagrik Sahakari Bank" },
  { value: "Kamrup Trading Co Opp Society Ltd", label: "Kamrup Trading Co Opp Society Ltd" },
  { value: "Kan Ins. Brokers Pvt. Ltd.", label: "Kan Ins. Brokers Pvt. Ltd." },
  { value: "Kanagadhara Finance", label: "Kanagadhara Finance" },
  { value: "Kanagavel Finance", label: "Kanagavel Finance" },
  { value: "Kanagavel Finance", label: "Kanagavel Finance" },
  { value: "Kanagavel Raja Finance", label: "Kanagavel Raja Finance" },
  { value: "Kanak Instalments (P) Ltd.", label: "Kanak Instalments (P) Ltd." },
  { value: "Kanak Pattin Sahakari Sangha Niyamit", label: "Kanak Pattin Sahakari Sangha Niyamit" },
  { value: "Kanaka Co Op Bank", label: "Kanaka Co Op Bank" },
  { value: "Kanaka Durga Financiers", label: "Kanaka Durga Financiers" },
  { value: "Kanaka Durga Leasing And Finance Ltd.", label: "Kanaka Durga Leasing And Finance Ltd." },
  { value: "Kanaka Mahalaxmi Auto Finance", label: "Kanaka Mahalaxmi Auto Finance" },
  { value: "Kanaka Pattana Sahakara Bank Niyamitya", label: "Kanaka Pattana Sahakara Bank Niyamitya" },
  { value: "Kanaka Sahakara Co-Op Society", label: "Kanaka Sahakara Co-Op Society" },
  { value: "Kanakadas Urban Co-Operative Credit Society Ltd.", label: "Kanakadas Urban Co-Operative Credit Society Ltd." },
  { value: "Kanakadhara Financiers", label: "Kanakadhara Financiers" },
  { value: "Kanakadurga Finance", label: "Kanakadurga Finance" },
  { value: "Kanakadurga Leasing And Finance Ltd.", label: "Kanakadurga Leasing And Finance Ltd." },
  { value: "Kanakamahalakshi Co-Operative Bank Ltd", label: "Kanakamahalakshi Co-Operative Bank Ltd" },
  { value: "Kanakamajalu Vysvasaya Sev Sah Bk Ni", label: "Kanakamajalu Vysvasaya Sev Sah Bk Ni" },
  { value: "Kanakavelraja Finance", label: "Kanakavelraja Finance" },
  { value: "Kanakdas Urban Co-Operative Society", label: "Kanakdas Urban Co-Operative Society" },
  { value: "Kanara Finance(R)", label: "Kanara Finance(R)" },
  { value: "Kanara Saraswat Credit Souharda Sahakari Niyamita", label: "Kanara Saraswat Credit Souharda Sahakari Niyamita" },
  { value: "Kanayannur Co Operative Agri-Rural Developement Bank", label: "Kanayannur Co Operative Agri-Rural Developement Bank" },
  { value: "Kanayannur Service Co-Op Bk Ltd.", label: "Kanayannur Service Co-Op Bk Ltd." },
  { value: "Kanchan  Investment  Nagpur", label: "Kanchan  Investment  Nagpur" },
  { value: "Kanchan Auto Finance", label: "Kanchan Auto Finance" },
  { value: "Kanchan Credit Sowharda Sahakari Ltd", label: "Kanchan Credit Sowharda Sahakari Ltd" },
  { value: "Kanchan Enterprises", label: "Kanchan Enterprises" },
  { value: "Kanchan Finance", label: "Kanchan Finance" },
  { value: "Kanchan Kanwar Bagnar", label: "Kanchan Kanwar Bagnar" },
  { value: "Kanchan Kawar", label: "Kanchan Kawar" },
  { value: "Kanchan Kawar", label: "Kanchan Kawar" },
  { value: "Kanchan Monika Finance Co", label: "Kanchan Monika Finance Co" },
  { value: "Kanchan Motor Finance", label: "Kanchan Motor Finance" },
  { value: "Kanchana Automobiles Pvt Ltd", label: "Kanchana Automobiles Pvt Ltd" },
  { value: "Kanchana Bai Chavelia", label: "Kanchana Bai Chavelia" },
  { value: "Kanchana Bai Chordia", label: "Kanchana Bai Chordia" },
  { value: "Kanchandevi", label: "Kanchandevi" },
  { value: "Kancheepuram Central Co-Op Bank Ltd.", label: "Kancheepuram Central Co-Op Bank Ltd." },
  { value: "Kancheepuram Central Co-Op. Bank Ltd.", label: "Kancheepuram Central Co-Op. Bank Ltd." },
  { value: "Kancheepuram District Central Co-Operative Bank Ltd.", label: "Kancheepuram District Central Co-Operative Bank Ltd." },
  { value: "Kanda Finance", label: "Kanda Finance" },
  { value: "Kandi Ravi Sankar", label: "Kandi Ravi Sankar" },
  { value: "Kandla Port Trust", label: "Kandla Port Trust" },
  { value: "Kandra Lamps Ltd.", label: "Kandra Lamps Ltd." },
  { value: "Kandy Finlease Limited", label: "Kandy Finlease Limited" },
  { value: "Kanfin Leasing And Finance (P) Ltd", label: "Kanfin Leasing And Finance (P) Ltd" },
  { value: "Kangra Co-Op Bank Ltd.", label: "Kangra Co-Op Bank Ltd." },
  { value: "Kangra Co-Operative Bank", label: "Kangra Co-Operative Bank" },
  { value: "Kangra District Central Co-Operative Bank Ltd.", label: "Kangra District Central Co-Operative Bank Ltd." },
  { value: "Kanhaa Enterprises", label: "Kanhaa Enterprises" },
  { value: "Kanhaiya Lal Anurag Kumar", label: "Kanhaiya Lal Anurag Kumar" },
  { value: "Kanhar Pathar Nagari Sah Pat Sanstha", label: "Kanhar Pathar Nagari Sah Pat Sanstha" },
  { value: "Kanhoor Pathar Nagari Sah Patsanstha", label: "Kanhoor Pathar Nagari Sah Patsanstha" },
  { value: "Kanhur Nagari Sahakari Pathsanstha", label: "Kanhur Nagari Sahakari Pathsanstha" },
  { value: "Kanhur Pathar Co.Op Credit Soc.Ltd", label: "Kanhur Pathar Co.Op Credit Soc.Ltd" },
  { value: "Kanhur Pathar Patsanstha", label: "Kanhur Pathar Patsanstha" },
  { value: "Kanika Bagaria", label: "Kanika Bagaria" },
  { value: "Kanika Enterprisess", label: "Kanika Enterprisess" },
  { value: "Kanisha Auto Finance", label: "Kanisha Auto Finance" },
  { value: "Kanishka Motor Finance", label: "Kanishka Motor Finance" },
  { value: "Kanishka Motor Finance P Ltd", label: "Kanishka Motor Finance P Ltd" },
  { value: "Kaniyambetta Services Co-Operative Bank.", label: "Kaniyambetta Services Co-Operative Bank." },
  { value: "Kanjan Finance And Lease Pvt Ltd", label: "Kanjan Finance And Lease Pvt Ltd" },
  { value: "Kanjikode Service Co Operative Bank Ltd", label: "Kanjikode Service Co Operative Bank Ltd" },
  { value: "Kanjilkuzhy Service Co.Op Bank Ltd.", label: "Kanjilkuzhy Service Co.Op Bank Ltd." },
  { value: "Kanjiramattom Ser. Co-Op. Bk. L.", label: "Kanjiramattom Ser. Co-Op. Bk. L." },
  { value: "Kanjirapally Cent. Service Co Op. Bank", label: "Kanjirapally Cent. Service Co Op. Bank" },
  { value: "Kanjirapally Service Co-Op Bank Ltd.", label: "Kanjirapally Service Co-Op Bank Ltd." },
  { value: "Kankaliya Finance", label: "Kankaliya Finance" },
  { value: "Kankaria & Co", label: "Kankaria & Co" },
  { value: "Kankaria Finance", label: "Kankaria Finance" },
  { value: "Kankaria Investments", label: "Kankaria Investments" },
  { value: "Kankaria Maninagar Nag.Sah.Bank Ltd., Ahmedabad-8", label: "Kankaria Maninagar Nag.Sah.Bank Ltd., Ahmedabad-8" },
  { value: "Kankaria Maninagar Nagrik Sah.  Bank Ltd", label: "Kankaria Maninagar Nagrik Sah.  Bank Ltd" },
  { value: "Kankaria Motor Finance Co.", label: "Kankaria Motor Finance Co." },
  { value: "Kankaria Motor Finance Pvt.Ltd", label: "Kankaria Motor Finance Pvt.Ltd" },
  { value: "Kankariya Finance Co", label: "Kankariya Finance Co" },
  { value: "Kankot Seva Sahkari Mandli Ltd", label: "Kankot Seva Sahkari Mandli Ltd" },
  { value: "Kankrol Seva Sahkari Mandali Ltd", label: "Kankrol Seva Sahkari Mandali Ltd" },
  { value: "Kanmanam Service Co-Op Bank Ltd.", label: "Kanmanam Service Co-Op Bank Ltd." },
  { value: "Kannabiran Auto Finance", label: "Kannabiran Auto Finance" },
  { value: "Kannan Devan Hills Plantation Co (P) Ltd", label: "Kannan Devan Hills Plantation Co (P) Ltd" },
  { value: "Kannapuram Service Co-Op. Bk. Ltd", label: "Kannapuram Service Co-Op. Bk. Ltd" },
  { value: "Kannika Finance", label: "Kannika Finance" },
  { value: "Kannika Parameshwar Pattan Souhard Sahakari Niyamitha", label: "Kannika Parameshwar Pattan Souhard Sahakari Niyamitha" },
  { value: "Kannur Block Agricultural I.C.O.S", label: "Kannur Block Agricultural I.C.O.S" },
  { value: "Kannur Dis.Pvt.Bus.Tra.Ope.Co-Op.S.L.", label: "Kannur Dis.Pvt.Bus.Tra.Ope.Co-Op.S.L." },
  { value: "Kannur Dist. Bus Transport Employees C.S", label: "Kannur Dist. Bus Transport Employees C.S" },
  { value: "Kannur District Central Co-Operative Bank Ltd.", label: "Kannur District Central Co-Operative Bank Ltd." },
  { value: "Kannur Primary Co-Operative Agricultural And Rural Development Bank Ltd", label: "Kannur Primary Co-Operative Agricultural And Rural Development Bank Ltd" },
  { value: "Kanoor Vyavasaga Seva Sahakari Bank", label: "Kanoor Vyavasaga Seva Sahakari Bank" },
  { value: "Kanpar Juth Seva Sahkari Mandali Ltd", label: "Kanpar Juth Seva Sahkari Mandali Ltd" },
  { value: "Kanta Devi Bafna", label: "Kanta Devi Bafna" },
  { value: "Kanta Kanwar Bothra Automobile Financiers", label: "Kanta Kanwar Bothra Automobile Financiers" },
  { value: "Kanted Finance Ltd", label: "Kanted Finance Ltd" },
  { value: "Kantha Chajjed", label: "Kantha Chajjed" },
  { value: "Kantha Devi Jain", label: "Kantha Devi Jain" },
  { value: "Kantha Kanwar Kothari", label: "Kantha Kanwar Kothari" },
  { value: "Kanthadevi Financier", label: "Kanthadevi Financier" },
  { value: "Kanthakavar Kothari", label: "Kanthakavar Kothari" },
  { value: "Kantham Commercials", label: "Kantham Commercials" },
  { value: "Kanthan Sri Finance", label: "Kanthan Sri Finance" },
  { value: "Kanwar Finance Co.", label: "Kanwar Finance Co." },
  { value: "Kanwarlal Jain Financiar", label: "Kanwarlal Jain Financiar" },
  { value: "Kanyaka Auto Finance", label: "Kanyaka Auto Finance" },
  { value: "Kanyaka Nagari Sahakari Bank", label: "Kanyaka Nagari Sahakari Bank" },
  { value: "Kanyaka Nagari Sahakari Bank Ltd.", label: "Kanyaka Nagari Sahakari Bank Ltd." },
  { value: "Kanyakumari District Central Co-Operative Bank Ltd.", label: "Kanyakumari District Central Co-Operative Bank Ltd." },
  { value: "Kapil Enterprise", label: "Kapil Enterprise" },
  { value: "Kapish Enterprise", label: "Kapish Enterprise" },
  { value: "Kapole Co-Op Bank Ltd., Mumbai", label: "Kapole Co-Op Bank Ltd., Mumbai" },
  { value: "Kapoor Kanchanbai", label: "Kapoor Kanchanbai" },
  { value: "Kapurthala Central Co Operation Bank Ltd", label: "Kapurthala Central Co Operation Bank Ltd" },
  { value: "Kapurthala District Central Co-Operative Bank Ltd.", label: "Kapurthala District Central Co-Operative Bank Ltd." },
  { value: "Kapurthala Ferozepur Kshetriya Gramin Bk", label: "Kapurthala Ferozepur Kshetriya Gramin Bk" },
  { value: "Kapurthala Firozpur Kshet Gramin Bk", label: "Kapurthala Firozpur Kshet Gramin Bk" },
  { value: "Kapus Kamghar Sahakari Bank Ltd", label: "Kapus Kamghar Sahakari Bank Ltd" },
  { value: "Kar City", label: "Kar City" },
  { value: "Karad Co Op Credit Soc Ltd", label: "Karad Co Op Credit Soc Ltd" },
  { value: "Karad Janata Sahakari Bank Ltd", label: "Karad Janata Sahakari Bank Ltd" },
  { value: "Karad Merchant Co Op Credit Soc. Maryadit", label: "Karad Merchant Co Op Credit Soc. Maryadit" },
  { value: "Karad Merchant Co-Op Bank Ltd", label: "Karad Merchant Co-Op Bank Ltd" },
  { value: "Karad Satara Bank", label: "Karad Satara Bank" },
  { value: "Karad Urban Co-Op Bank Ltd", label: "Karad Urban Co-Op Bank Ltd" },
  { value: "Karama Co-Operative Urban Bank", label: "Karama Co-Operative Urban Bank" },
  { value: "Karamadai Sri Karpagam Finance", label: "Karamadai Sri Karpagam Finance" },
  { value: "Karamadai Sri Sriram Finance", label: "Karamadai Sri Sriram Finance" },
  { value: "Karamana Co-Operative Urban Bank Ltd", label: "Karamana Co-Operative Urban Bank Ltd" },
  { value: "Karamana Co-Operative Urban Bank Ltd.", label: "Karamana Co-Operative Urban Bank Ltd." },
  { value: "Karamsad Urban Co-Op Bank Ltd", label: "Karamsad Urban Co-Op Bank Ltd" },
  { value: "Karan Motors", label: "Karan Motors" },
  { value: "Karan Urban Co-Op Bank Ltd", label: "Karan Urban Co-Op Bank Ltd" },
  { value: "Karandi Gramin Bigar Sheti Patsastha", label: "Karandi Gramin Bigar Sheti Patsastha" },
  { value: "Karani Auto Finance", label: "Karani Auto Finance" },
  { value: "Karanje Gramin Bigarsheti Sahakari Patsanstha Ltd", label: "Karanje Gramin Bigarsheti Sahakari Patsanstha Ltd" },
  { value: "Kararvyas Bank", label: "Kararvyas Bank" },
  { value: "Karassery Panchayath Vanitha Sa.Sangam L", label: "Karassery Panchayath Vanitha Sa.Sangam L" },
  { value: "Karassery Service Co-Op. Bank (Ltd)", label: "Karassery Service Co-Op. Bank (Ltd)" },
  { value: "Karassery Service Co-Operative Bank Ltd", label: "Karassery Service Co-Operative Bank Ltd" },
  { value: "Karavali Capital And Leasing (P) Ltd", label: "Karavali Capital And Leasing (P) Ltd" },
  { value: "Karavali Capital And Leasing Pvt Ltd", label: "Karavali Capital And Leasing Pvt Ltd" },
  { value: "Karayamparambu Ser Co Bk L", label: "Karayamparambu Ser Co Bk L" },
  { value: "Kare India Ins. Brokers Pvt. Ltd.", label: "Kare India Ins. Brokers Pvt. Ltd." },
  { value: "Kareepra Panchagat Services Coop Bankltd", label: "Kareepra Panchagat Services Coop Bankltd" },
  { value: "Karemiya Urban Co Op Credit Soc Ltd", label: "Karemiya Urban Co Op Credit Soc Ltd" },
  { value: "Kareshwar Gramin Bigar Sheti Sah Pat Sah", label: "Kareshwar Gramin Bigar Sheti Sah Pat Sah" },
  { value: "Karhur Pathar Nagari Sah Patsanstha", label: "Karhur Pathar Nagari Sah Patsanstha" },
  { value: "Karibasaveshwar Shivacharya Pattina Sang", label: "Karibasaveshwar Shivacharya Pattina Sang" },
  { value: "Karimia Urban Co-Op Credit Society Ltd", label: "Karimia Urban Co-Op Credit Society Ltd" },
  { value: "Karimnagar District Central Co-Operative Bank Ltd.", label: "Karimnagar District Central Co-Operative Bank Ltd." },
  { value: "Karimnagar Vijay Hire Purchase And Leasing (P) Ltd.", label: "Karimnagar Vijay Hire Purchase And Leasing (P) Ltd." },
  { value: "Karinje Service Coop Bank Taccode L", label: "Karinje Service Coop Bank Taccode L" },
  { value: "Karivali Nagri Sahakari Pathsanstha Ltd.", label: "Karivali Nagri Sahakari Pathsanstha Ltd." },
  { value: "Kariwali Nagarik Shakari Pat Sawstha Ltd. Bhiwandi", label: "Kariwali Nagarik Shakari Pat Sawstha Ltd. Bhiwandi" },
  { value: "Karjan Nagarik Sahakari Bank Ltd", label: "Karjan Nagarik Sahakari Bank Ltd" },
  { value: "Karkala Co-Operative Service Bank Ltd Karkala", label: "Karkala Co-Operative Service Bank Ltd Karkala" },
  { value: "Karkala T A P C M S Ltd - Karkala", label: "Karkala T A P C M S Ltd - Karkala" },
  { value: "Karla Auto Finance", label: "Karla Auto Finance" },
  { value: "Karla Auto Finance Agra", label: "Karla Auto Finance Agra" },
  { value: "Karmala Urban Co-Op Bank Ltd", label: "Karmala Urban Co-Op Bank Ltd" },
  { value: "Karmali Corlim V K S Society Ltd", label: "Karmali Corlim V K S Society Ltd" },
  { value: "Karmaveer Bhaurao Patil Zilha Sahakari Patsanstha Maryadit", label: "Karmaveer Bhaurao Patil Zilha Sahakari Patsanstha Maryadit" },
  { value: "Karmaveer Bhaurao Patil Zillha Sahakari Pathsanstha Maryadit", label: "Karmaveer Bhaurao Patil Zillha Sahakari Pathsanstha Maryadit" },
  { value: "Karmavir Sardar Urben Co Op Credit Soc Ltd", label: "Karmavir Sardar Urben Co Op Credit Soc Ltd" },
  { value: "Karmegam Auto Finance", label: "Karmegam Auto Finance" },
  { value: "Karmveer Gramin  Bigar Sheti Sahakari Patsanstha Maryadit", label: "Karmveer Gramin  Bigar Sheti Sahakari Patsanstha Maryadit" },
  { value: "Karmvir Bhaurao Patil Nag Sah Pat Sanstha Maryadit", label: "Karmvir Bhaurao Patil Nag Sah Pat Sanstha Maryadit" },
  { value: "Karmyogi Gramin Bank", label: "Karmyogi Gramin Bank" },
  { value: "Karnal District Central Co-Operative Bank Ltd.", label: "Karnal District Central Co-Operative Bank Ltd." },
  { value: "Karnala Nagari Sahakari Bank Ltd.", label: "Karnala Nagari Sahakari Bank Ltd." },
  { value: "Karnala Nagarik Sahakari Bank Ltd", label: "Karnala Nagarik Sahakari Bank Ltd" },
  { value: "Karnatak District Central Co-Operative Bank Ltd.", label: "Karnatak District Central Co-Operative Bank Ltd." },
  { value: "Karnataka Accidental Care", label: "Karnataka Accidental Care" },
  { value: "Karnataka Agri Equipments", label: "Karnataka Agri Equipments" },
  { value: "Karnataka Antibiotics And Pharmaceuticals Ltd.", label: "Karnataka Antibiotics And Pharmaceuticals Ltd." },
  { value: "Karnataka Apex Co-Operative Ltd.", label: "Karnataka Apex Co-Operative Ltd." },
  { value: "Karnataka Auto Finance ®", label: "Karnataka Auto Finance ®" },
  { value: "Karnataka Backward Classes Development Corporation Ltd", label: "Karnataka Backward Classes Development Corporation Ltd" },
  { value: "Karnataka Bank - Karkala", label: "Karnataka Bank - Karkala" },
  { value: "Karnataka Bank Ltd - Adi Udupi", label: "Karnataka Bank Ltd - Adi Udupi" },
  { value: "Karnataka Bank Ltd - Siddapura", label: "Karnataka Bank Ltd - Siddapura" },
  { value: "Karnataka Bank Ltd, Sunticopa", label: "Karnataka Bank Ltd, Sunticopa" },
  { value: "Karnataka Bank Ltd.", label: "Karnataka Bank Ltd." },
  { value: "Karnataka Bank Sc/St Dev.Corp Ltd", label: "Karnataka Bank Sc/St Dev.Corp Ltd" },
  { value: "Karnataka City Graduate Bank", label: "Karnataka City Graduate Bank" },
  { value: "Karnataka Co Op Bank Ltd", label: "Karnataka Co Op Bank Ltd" },
  { value: "Karnataka Contractors Sahakara B Ni", label: "Karnataka Contractors Sahakara B Ni" },
  { value: "Karnataka Contractors Sahakara B Niyamit", label: "Karnataka Contractors Sahakara B Niyamit" },
  { value: "Karnataka Credit Capital Corporation", label: "Karnataka Credit Capital Corporation" },
  { value: "Karnataka Electricity Regulatory Commission", label: "Karnataka Electricity Regulatory Commission" },
  { value: "Karnataka Finance", label: "Karnataka Finance" },
  { value: "Karnataka Forest Development Corporation Limited", label: "Karnataka Forest Development Corporation Limited" },
  { value: "Karnataka Grameena Bank", label: "Karnataka Grameena Bank" },
  { value: "Karnataka Industrial Co-Op Bank Ltd.", label: "Karnataka Industrial Co-Op Bank Ltd." },
  { value: "Karnataka K.M.P. Cr.Coop.Bk L.,Banglore", label: "Karnataka K.M.P. Cr.Coop.Bk L.,Banglore" },
  { value: "Karnataka Kerivali Co-Operative Credit Society Ltd.", label: "Karnataka Kerivali Co-Operative Credit Society Ltd." },
  { value: "Karnataka Keshtriya Maratha P.C.C.O.Ltd", label: "Karnataka Keshtriya Maratha P.C.C.O.Ltd" },
  { value: "Karnataka Minority Development Co-Operation", label: "Karnataka Minority Development Co-Operation" },
  { value: "Karnataka Power Corporation Limited", label: "Karnataka Power Corporation Limited" },
  { value: "Karnataka Rajya Kaigarika S.Bk Niyamitha", label: "Karnataka Rajya Kaigarika S.Bk Niyamitha" },
  { value: "Karnataka Renewable Energy Development Limited", label: "Karnataka Renewable Energy Development Limited" },
  { value: "Karnataka Road Development Corporation Limited", label: "Karnataka Road Development Corporation Limited" },
  { value: "Karnataka Sc And St Development Corporation", label: "Karnataka Sc And St Development Corporation" },
  { value: "Karnataka Souharda Pattin Sahakari Bank Niyamiti", label: "Karnataka Souharda Pattin Sahakari Bank Niyamiti" },
  { value: "Karnataka State Co-Op Apex Bank Ltd.", label: "Karnataka State Co-Op Apex Bank Ltd." },
  { value: "Karnataka State Financial Corporation", label: "Karnataka State Financial Corporation" },
  { value: "Karnataka State Ind. Co-Op. Bank Ltd", label: "Karnataka State Ind. Co-Op. Bank Ltd" },
  { value: "Karnataka State Industrial Investment And Development Corporation", label: "Karnataka State Industrial Investment And Development Corporation" },
  { value: "Karnataka State Pollution Control Board", label: "Karnataka State Pollution Control Board" },
  { value: "Karnataka State Sc St Bank Ltd", label: "Karnataka State Sc St Bank Ltd" },
  { value: "Karnataka State Tourism Development Corporation Ltd.", label: "Karnataka State Tourism Development Corporation Ltd." },
  { value: "Karnataka Urban Cr Souhard Sahakari Ltd", label: "Karnataka Urban Cr Souhard Sahakari Ltd" },
  { value: "Karnataka Vikas Grameena Bank", label: "Karnataka Vikas Grameena Bank" },
  { value: "Karnataka Vikas Grameena Bank", label: "Karnataka Vikas Grameena Bank" },
  { value: "Karnataka Vikas Grameena Bank - Brahmavar", label: "Karnataka Vikas Grameena Bank - Brahmavar" },
  { value: "Karnataka Vikas Grameena Bank - Hillur", label: "Karnataka Vikas Grameena Bank - Hillur" },
  { value: "Karnataka Vikas Grameena Bank - Sirsi", label: "Karnataka Vikas Grameena Bank - Sirsi" },
  { value: "Karnataka Vikas Gramena Bank", label: "Karnataka Vikas Gramena Bank" },
  { value: "Karnataka Vikas Grami Bank ,Hunashikatti", label: "Karnataka Vikas Grami Bank ,Hunashikatti" },
  { value: "Karnataka Vivbha Finance", label: "Karnataka Vivbha Finance" },
  { value: "Karnati Ramakrishna Automobile Financier", label: "Karnati Ramakrishna Automobile Financier" },
  { value: "Karnati Ravi Kumar Automobile Financiers", label: "Karnati Ravi Kumar Automobile Financiers" },
  { value: "Karnavati Auto Finance", label: "Karnavati Auto Finance" },
  { value: "Karnavati Co-Op Bank Ltd", label: "Karnavati Co-Op Bank Ltd" },
  { value: "Karnavati Trading Company", label: "Karnavati Trading Company" },
  { value: "Karnawat Hire Purchase Pvt Ltd", label: "Karnawat Hire Purchase Pvt Ltd" },
  { value: "Karnawati Finance", label: "Karnawati Finance" },
  { value: "Karnawati Trading Co", label: "Karnawati Trading Co" },
  { value: "Karni Auto Finance", label: "Karni Auto Finance" },
  { value: "Karni Auto Finance", label: "Karni Auto Finance" },
  { value: "Karnveer Bhaurao Patil Zilla Sah. Path Sanstha", label: "Karnveer Bhaurao Patil Zilla Sah. Path Sanstha" },
  { value: "Karoor Vysya Bank", label: "Karoor Vysya Bank" },
  { value: "Karpagam Credits", label: "Karpagam Credits" },
  { value: "Karpagavinayaga Auto Finance", label: "Karpagavinayaga Auto Finance" },
  { value: "Karpgam Auto Finance", label: "Karpgam Auto Finance" },
  { value: "Kartar Auto Deal", label: "Kartar Auto Deal" },
  { value: "Kartar Leasing Pvt. Ltd.", label: "Kartar Leasing Pvt. Ltd." },
  { value: "Kartar Motor Fin Co.", label: "Kartar Motor Fin Co." },
  { value: "Karthedom Rural Co Operative Society", label: "Karthedom Rural Co Operative Society" },
  { value: "Karthedom Ser.Co-Op. Bank Ltd.", label: "Karthedom Ser.Co-Op. Bank Ltd." },
  { value: "Karthic Credits Ltd", label: "Karthic Credits Ltd" },
  { value: "Karthik Finance Ltd", label: "Karthik Finance Ltd" },
  { value: "Karthikeya Finance Ltd", label: "Karthikeya Finance Ltd" },
  { value: "Karumalloor Ser Co-Op Bank Ltd", label: "Karumalloor Ser Co-Op Bank Ltd" },
  { value: "Karumbalai Sri Bharathi Finance", label: "Karumbalai Sri Bharathi Finance" },
  { value: "Karunagappally Service Co-Op Bank Ltd", label: "Karunagappally Service Co-Op Bank Ltd" },
  { value: "Karunthattankudi Dravidian Co-Op Urban Bank Ltd", label: "Karunthattankudi Dravidian Co-Op Urban Bank Ltd" },
  { value: "Karupar Investment And Finance Pvt Ltd", label: "Karupar Investment And Finance Pvt Ltd" },
  { value: "Karupar Investments Finance", label: "Karupar Investments Finance" },
  { value: "Karuppar Finance", label: "Karuppar Finance" },
  { value: "Karur Gayathri Finance Ltd.", label: "Karur Gayathri Finance Ltd." },
  { value: "Karur Sakthi Kumaran Crediter", label: "Karur Sakthi Kumaran Crediter" },
  { value: "Karur Sakthi Kumaran Credits", label: "Karur Sakthi Kumaran Credits" },
  { value: "Karur Vyasa Bank", label: "Karur Vyasa Bank" },
  { value: "Karur Vysya Bank Ltd.", label: "Karur Vysya Bank Ltd." },
  { value: "Karuvannur Service Co-Op Bank Ltd,", label: "Karuvannur Service Co-Op Bank Ltd," },
  { value: "Karvy Consultants Ltd.", label: "Karvy Consultants Ltd." },
  { value: "Karwar Urban Co-Op Bank Ltd", label: "Karwar Urban Co-Op Bank Ltd" },
  { value: "Karwar Urban Co-Op Bank Ltd", label: "Karwar Urban Co-Op Bank Ltd" },
  { value: "Kasaragod Taluk Bustransport Ownersco S", label: "Kasaragod Taluk Bustransport Ownersco S" },
  { value: "Kasargod District Central Co-Operative Bank Ltd.", label: "Kasargod District Central Co-Operative Bank Ltd." },
  { value: "Kasee Annapurneswari Financiers", label: "Kasee Annapurneswari Financiers" },
  { value: "Kashi Annapurneshwari Finance", label: "Kashi Annapurneshwari Finance" },
  { value: "Kashi Gomati Sayukat Gramin Bank", label: "Kashi Gomati Sayukat Gramin Bank" },
  { value: "Kashi Gomti Gramin Bank", label: "Kashi Gomti Gramin Bank" },
  { value: "Kashi Gomti Gramin Bank", label: "Kashi Gomti Gramin Bank" },
  { value: "Kashi Gomti Samyud Kshetriya Gramin Bank.", label: "Kashi Gomti Samyud Kshetriya Gramin Bank." },
  { value: "Kashi Gomti Sanyukt Gramin Bank", label: "Kashi Gomti Sanyukt Gramin Bank" },
  { value: "Kashi Gomti Sayukta Gramin Bank.", label: "Kashi Gomti Sayukta Gramin Bank." },
  { value: "Kashi Samyut Gramin Bank", label: "Kashi Samyut Gramin Bank" },
  { value: "Kashi Vishweswar Sahkari Patsanstha", label: "Kashi Vishweswar Sahkari Patsanstha" },
  { value: "Kashinath Anna Charati Pathsanstha Maryadit Ajara", label: "Kashinath Anna Charati Pathsanstha Maryadit Ajara" },
  { value: "Kashipur Finance", label: "Kashipur Finance" },
  { value: "Kashipur Urban Co-Op Bank Ltd", label: "Kashipur Urban Co-Op Bank Ltd" },
  { value: "Kashipur Urban Coop Bk Ltd.", label: "Kashipur Urban Coop Bk Ltd." },
  { value: "Kashmir Merchantile Co-Op Bank Ltd", label: "Kashmir Merchantile Co-Op Bank Ltd" },
  { value: "Kasthuri Investment", label: "Kasthuri Investment" },
  { value: "Kasturi Capital And Financial Services Ltd.", label: "Kasturi Capital And Financial Services Ltd." },
  { value: "Kasturi Finance Chenni", label: "Kasturi Finance Chenni" },
  { value: "Kasturi Nagri Sahkari Patsanstha Maryadit", label: "Kasturi Nagri Sahkari Patsanstha Maryadit" },
  { value: "Kat Investments", label: "Kat Investments" },
  { value: "Katampazhipuram Service Co-Op Bank Ltd.", label: "Katampazhipuram Service Co-Op Bank Ltd." },
  { value: "Katapally Satya Reddy", label: "Katapally Satya Reddy" },
  { value: "Katarale Vyapari Gramin Bigar Sheti Sahkari Pat Sanstha Maryadit", label: "Katarale Vyapari Gramin Bigar Sheti Sahkari Pat Sanstha Maryadit" },
  { value: "Kataria Finlease", label: "Kataria Finlease" },
  { value: "Katariya Finance", label: "Katariya Finance" },
  { value: "Katariya Finlease Company", label: "Katariya Finlease Company" },
  { value: "Katariya Finlease Corp", label: "Katariya Finlease Corp" },
  { value: "Katariya Investment", label: "Katariya Investment" },
  { value: "Katariya Investment And Finance Company", label: "Katariya Investment And Finance Company" },
  { value: "Katariya Phinlish Company", label: "Katariya Phinlish Company" },
  { value: "Katcos Finance", label: "Katcos Finance" },
  { value: "Katholick Syrin Bank Ltd", label: "Katholick Syrin Bank Ltd" },
  { value: "Kathor Vibhag Nagrik Sahakari Dhiran Mandli Ltd", label: "Kathor Vibhag Nagrik Sahakari Dhiran Mandli Ltd" },
  { value: "Kathor Vibhag Nagrik Sahakari Dhiran Mandli Ltd", label: "Kathor Vibhag Nagrik Sahakari Dhiran Mandli Ltd" },
  { value: "Kathuria Finance Company", label: "Kathuria Finance Company" },
  { value: "Katkeel Co-Op Bank Ltd", label: "Katkeel Co-Op Bank Ltd" },
  { value: "Katkol Co-Op Bank Ltd", label: "Katkol Co-Op Bank Ltd" },
  { value: "Katkol Co-Op. Bank Ltd.", label: "Katkol Co-Op. Bank Ltd." },
  { value: "Katkol Krushi Sahakari Bank Ltd", label: "Katkol Krushi Sahakari Bank Ltd" },
  { value: "Katlari Kariyana Sahkari Mandali", label: "Katlari Kariyana Sahkari Mandali" },
  { value: "Katraia Finance", label: "Katraia Finance" },
  { value: "Kattakampal Services Coop Bank", label: "Kattakampal Services Coop Bank" },
  { value: "Kattakode Ser Co-Op Bank Ltd,", label: "Kattakode Ser Co-Op Bank Ltd," },
  { value: "Kattangal Co-Operative Urban Bk Limited", label: "Kattangal Co-Operative Urban Bk Limited" },
  { value: "Kattappan Urban Co-Op Bank Ltd", label: "Kattappan Urban Co-Op Bank Ltd" },
  { value: "Kattoor Service Co Bank", label: "Kattoor Service Co Bank" },
  { value: "Kaujalagi Urban Co-Op Bank Ltd", label: "Kaujalagi Urban Co-Op Bank Ltd" },
  { value: "Kaushik Securities Ltd.", label: "Kaushik Securities Ltd." },
  { value: "Kaushlya Verma", label: "Kaushlya Verma" },
  { value: "Kauvery Kalpatharu Gramin Bank", label: "Kauvery Kalpatharu Gramin Bank" },
  { value: "Kavalkinaru Prim. Agricultural C P Bk Lt", label: "Kavalkinaru Prim. Agricultural C P Bk Lt" },
  { value: "Kaveri Credits ( India) Private Ltd", label: "Kaveri Credits ( India) Private Ltd" },
  { value: "Kaveri Hire Purchasing", label: "Kaveri Hire Purchasing" },
  { value: "Kaveri Investments", label: "Kaveri Investments" },
  { value: "Kaveri Kalpataru Grameena Bank", label: "Kaveri Kalpataru Grameena Bank" },
  { value: "Kaveri Seeds Company Ltd", label: "Kaveri Seeds Company Ltd" },
  { value: "Kaveripattanam Co-Op Town Bank Ltd", label: "Kaveripattanam Co-Op Town Bank Ltd" },
  { value: "Kavi Finance", label: "Kavi Finance" },
  { value: "Kavirathna Kalidas Credit Co-Operative Society Ltd", label: "Kavirathna Kalidas Credit Co-Operative Society Ltd" },
  { value: "Kavita Auto Leasing", label: "Kavita Auto Leasing" },
  { value: "Kavita Singhal", label: "Kavita Singhal" },
  { value: "Kavital Pattin Souhard Sahakari Niyamit", label: "Kavital Pattin Souhard Sahakari Niyamit" },
  { value: "Kavital Pattina Sahakari Sangha Ni.", label: "Kavital Pattina Sahakari Sangha Ni." },
  { value: "Kavital Pattina Sahakari Sangha Niyamita", label: "Kavital Pattina Sahakari Sangha Niyamita" },
  { value: "Kavitha Finance", label: "Kavitha Finance" },
  { value: "Kavivary Moropant Nagari Sah. Pathsansia Mar", label: "Kavivary Moropant Nagari Sah. Pathsansia Mar" },
  { value: "Kavoor Vyavasaya Seva Sahakari Bank,", label: "Kavoor Vyavasaya Seva Sahakari Bank," },
  { value: "Kavya Finance", label: "Kavya Finance" },
  { value: "Kay Aar Deco Build. P. Ltd.", label: "Kay Aar Deco Build. P. Ltd." },
  { value: "Kay Ar Finance", label: "Kay Ar Finance" },
  { value: "Kay Ess Hire Purchase Pvt. Ltd.", label: "Kay Ess Hire Purchase Pvt. Ltd." },
  { value: "Kay Kay Hire Purchase (P) Ltd.", label: "Kay Kay Hire Purchase (P) Ltd." },
  { value: "Kayak Finance ® Jeevargi", label: "Kayak Finance ® Jeevargi" },
  { value: "Kayak Leasing", label: "Kayak Leasing" },
  { value: "Kayaka Auto Leasing", label: "Kayaka Auto Leasing" },
  { value: "Kayaka Leasing Finance", label: "Kayaka Leasing Finance" },
  { value: "Kayakkody Service Co-Operative Bank Ltd", label: "Kayakkody Service Co-Operative Bank Ltd" },
  { value: "Kayal Auto Consultants & Financiers", label: "Kayal Auto Consultants & Financiers" },
  { value: "Kayal Auto Finance", label: "Kayal Auto Finance" },
  { value: "Kayarambedu Primary Agr. Co. Bk Ltd", label: "Kayarambedu Primary Agr. Co. Bk Ltd" },
  { value: "Kayathar Primary Agricultural C. Bk. Ltd", label: "Kayathar Primary Agricultural C. Bk. Ltd" },
  { value: "Kaykay Finance Company", label: "Kaykay Finance Company" },
  { value: "Kazhakuttom Service Co-Op Bk Ltd.,", label: "Kazhakuttom Service Co-Op Bk Ltd.," },
  { value: "Kaziranga Finance Pvt Ltd", label: "Kaziranga Finance Pvt Ltd" },
  { value: "Kb Finance", label: "Kb Finance" },
  { value: "Kbc Bank N. V. India & South Asia", label: "Kbc Bank N. V. India & South Asia" },
  { value: "Kbs Securities Regd", label: "Kbs Securities Regd" },
  { value: "Kc Investment", label: "Kc Investment" },
  { value: "Kcs Transport Finance", label: "Kcs Transport Finance" },
  { value: "Kdcc Bank Ltd", label: "Kdcc Bank Ltd" },
  { value: "Kdhp Company Ltd", label: "Kdhp Company Ltd" },
  { value: "Kedaranath Finance", label: "Kedaranath Finance" },
  { value: "Kedarnath Urban Co-Op. Bank Ltd.", label: "Kedarnath Urban Co-Op. Bank Ltd." },
  { value: "Keechery Service Co-Op Bank Ltd.", label: "Keechery Service Co-Op Bank Ltd." },
  { value: "Keerambur Finance", label: "Keerambur Finance" },
  { value: "Keerampara Service Co-Op Bk Ltd", label: "Keerampara Service Co-Op Bk Ltd" },
  { value: "Keerthana Auto Finance", label: "Keerthana Auto Finance" },
  { value: "Keerthi Auto Finance", label: "Keerthi Auto Finance" },
  { value: "Keerthi Finance", label: "Keerthi Finance" },
  { value: "Keerti Motors And Auto Finance", label: "Keerti Motors And Auto Finance" },
  { value: "Keezhallur Service Co-Op. Bank Ltd", label: "Keezhallur Service Co-Op. Bank Ltd" },
  { value: "Kelachandra Leasing And Finance Ltd", label: "Kelachandra Leasing And Finance Ltd" },
  { value: "Kelas Sudharson", label: "Kelas Sudharson" },
  { value: "Kempe Gowda Pattana Sowhardha Sahakara Bank", label: "Kempe Gowda Pattana Sowhardha Sahakara Bank" },
  { value: "Kempegowda Pattana Sahakara Bank Niyamit", label: "Kempegowda Pattana Sahakara Bank Niyamit" },
  { value: "Kempegowda Pattana Sowharda Sahakara Bank Niyamitha", label: "Kempegowda Pattana Sowharda Sahakara Bank Niyamitha" },
  { value: "Kencharaya Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit", label: "Kencharaya Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit" },
  { value: "Kendrapara Urban Co-Op Bank Ltd", label: "Kendrapara Urban Co-Op Bank Ltd" },
  { value: "Kendrapara Urban Co-Operative Bank", label: "Kendrapara Urban Co-Operative Bank" },
  { value: "Kendriya Hindi Sansthan", label: "Kendriya Hindi Sansthan" },
  { value: "Kendriya Vidyalaya Sangathan", label: "Kendriya Vidyalaya Sangathan" },
  { value: "Kengel Credit Co Operative Bank Ltd", label: "Kengel Credit Co Operative Bank Ltd" },
  { value: "Kenukumata Nagari Pat Santh", label: "Kenukumata Nagari Pat Santh" },
  { value: "Keonjhar District Central Co-Operative Bank Ltd.", label: "Keonjhar District Central Co-Operative Bank Ltd." },
  { value: "Kerala Agro Machinery Corpa Ltd", label: "Kerala Agro Machinery Corpa Ltd" },
  { value: "Kerala Auto Finance Finaciers", label: "Kerala Auto Finance Finaciers" },
  { value: "Kerala Fin Cap", label: "Kerala Fin Cap" },
  { value: "Kerala Financial Corporation", label: "Kerala Financial Corporation" },
  { value: "Kerala Minerals And Mettals Ltd", label: "Kerala Minerals And Mettals Ltd" },
  { value: "Kerala Permanent Benefit Ltd Fund", label: "Kerala Permanent Benefit Ltd Fund" },
  { value: "Kerala Sdcscst Ltd", label: "Kerala Sdcscst Ltd" },
  { value: "Kerala State Backward Classed Development Corporation Ltd.", label: "Kerala State Backward Classed Development Corporation Ltd." },
  { value: "Kerala State Backward Classes Development Corporation Ltd.", label: "Kerala State Backward Classes Development Corporation Ltd." },
  { value: "Kerala State Co Operative Federation For Fisheries Development", label: "Kerala State Co Operative Federation For Fisheries Development" },
  { value: "Kerala State Co-Op Bank Ltd", label: "Kerala State Co-Op Bank Ltd" },
  { value: "Kerala State Development", label: "Kerala State Development" },
  { value: "Kerala State Development Corporation For Christian Converts From Scheduled", label: "Kerala State Development Corporation For Christian Converts From Scheduled" },
  { value: "Kerala State Financial Enterprise", label: "Kerala State Financial Enterprise" },
  { value: "Kerala State Industrial Development Corporation", label: "Kerala State Industrial Development Corporation" },
  { value: "Kerala State Tourism Development Corporation Ltd.", label: "Kerala State Tourism Development Corporation Ltd." },
  { value: "Kerala Transport Development Finance Corporation", label: "Kerala Transport Development Finance Corporation" },
  { value: "Kerala Transport Development Financial Corporation (Ktdfc)", label: "Kerala Transport Development Financial Corporation (Ktdfc)" },
  { value: "Kerala Travels Interserve Ltd", label: "Kerala Travels Interserve Ltd" },
  { value: "Kerala Water Aut. Emp.Cre.Co.So.L.", label: "Kerala Water Aut. Emp.Cre.Co.So.L." },
  { value: "Kesari Finance", label: "Kesari Finance" },
  { value: "Kesariya Investments", label: "Kesariya Investments" },
  { value: "Kesariyo Investment", label: "Kesariyo Investment" },
  { value: "Kesavaya Finance Ltd.", label: "Kesavaya Finance Ltd." },
  { value: "Keshar Fianace & Leasing (P) Ltd", label: "Keshar Fianace & Leasing (P) Ltd" },
  { value: "Keshar Fin And Leasing Pvt Ltd", label: "Keshar Fin And Leasing Pvt Ltd" },
  { value: "Kesharwani Auto Finance", label: "Kesharwani Auto Finance" },
  { value: "Keshav Finance", label: "Keshav Finance" },
  { value: "Keshav Finance Corp. Bank Of India", label: "Keshav Finance Corp. Bank Of India" },
  { value: "Keshav Finance Corp/Punjab & Sind Bank", label: "Keshav Finance Corp/Punjab & Sind Bank" },
  { value: "Keshav Finance Corpn A/C Bank Of India", label: "Keshav Finance Corpn A/C Bank Of India" },
  { value: "Keshav Finance Corpn.", label: "Keshav Finance Corpn." },
  { value: "Keshav Madhav Nagari Sahakari Pat Sanstha", label: "Keshav Madhav Nagari Sahakari Pat Sanstha" },
  { value: "Keshav Motors", label: "Keshav Motors" },
  { value: "Keshav Sahakari Bank Ltd", label: "Keshav Sahakari Bank Ltd" },
  { value: "Keshav Sehkari Bank Ltd", label: "Keshav Sehkari Bank Ltd" },
  { value: "Keshav Sehkari Bank Ltd.", label: "Keshav Sehkari Bank Ltd." },
  { value: "Keshod Co.Op. Credit Society", label: "Keshod Co.Op. Credit Society" },
  { value: "Keshod Cooperative Credit Society", label: "Keshod Cooperative Credit Society" },
  { value: "Keshri Finlease", label: "Keshri Finlease" },
  { value: "Keshria Finance Corporation", label: "Keshria Finance Corporation" },
  { value: "Keshwasmruti Nasari Sahakari Pathpedhi", label: "Keshwasmruti Nasari Sahakari Pathpedhi" },
  { value: "Keshwausmruti Nasan Sahakan Pathpedhi", label: "Keshwausmruti Nasan Sahakan Pathpedhi" },
  { value: "Kesiya Reji", label: "Kesiya Reji" },
  { value: "Ketan Co-Op Credit Society", label: "Ketan Co-Op Credit Society" },
  { value: "Ketankumar B Shah", label: "Ketankumar B Shah" },
  { value: "Kevdabaug Navapura Co Operative Credit Society Ltd", label: "Kevdabaug Navapura Co Operative Credit Society Ltd" },
  { value: "Kewal Chand", label: "Kewal Chand" },
  { value: "Kewal Chand Mishrimall", label: "Kewal Chand Mishrimall" },
  { value: "Kewal Chand Mishriwall", label: "Kewal Chand Mishriwall" },
  { value: "Kewal Chand Rajesh Kumar Huf", label: "Kewal Chand Rajesh Kumar Huf" },
  { value: "Kewal Investment Co", label: "Kewal Investment Co" },
  { value: "Key Corp Ltd", label: "Key Corp Ltd" },
  { value: "Key Corporation Ltd.", label: "Key Corporation Ltd." },
  { value: "Kfic Financial Services Ltd.", label: "Kfic Financial Services Ltd." },
  { value: "Kfm Company", label: "Kfm Company" },
  { value: "Kgk Finance Ltd.", label: "Kgk Finance Ltd." },
  { value: "Kgn Auto Consultancy Finance", label: "Kgn Auto Consultancy Finance" },
  { value: "Kgsg Bank", label: "Kgsg Bank" },
  { value: "Khabiya Motor Finance", label: "Khabiya Motor Finance" },
  { value: "Khada Leasing And Finance Co.", label: "Khada Leasing And Finance Co." },
  { value: "Khadak Malai Nag Sah Pat San Ltd", label: "Khadak Malai Nag Sah Pat San Ltd" },
  { value: "Khadaki Nagari Sahakari Patsanstha Maryadit", label: "Khadaki Nagari Sahakari Patsanstha Maryadit" },
  { value: "Khadi And Village Indutries Commission", label: "Khadi And Village Indutries Commission" },
  { value: "Khalilabad Nagar Sahkari Bank Ltd.", label: "Khalilabad Nagar Sahkari Bank Ltd." },
  { value: "Khalsa Credit Co-Op Soc. Ltd", label: "Khalsa Credit Co-Op Soc. Ltd" },
  { value: "Khalsa Finance Co.", label: "Khalsa Finance Co." },
  { value: "Khamakhia Finance & Leasing Ltd", label: "Khamakhia Finance & Leasing Ltd" },
  { value: "Khambhat Nagarik Sahakari Bank Ltd", label: "Khambhat Nagarik Sahakari Bank Ltd" },
  { value: "Khamgaon Urban Co-Op Bank Ltd", label: "Khamgaon Urban Co-Op Bank Ltd" },
  { value: "Khamidana Group Seva Sahakari Mandli", label: "Khamidana Group Seva Sahakari Mandli" },
  { value: "Khammam Dis. Coop Central Bank Ltd.", label: "Khammam Dis. Coop Central Bank Ltd." },
  { value: "Khammam District Central Co-Operative Bank Ltd.", label: "Khammam District Central Co-Operative Bank Ltd." },
  { value: "Khanapur Ur Co-Op Bank Ltd", label: "Khanapur Ur Co-Op Bank Ltd" },
  { value: "Khanapur Urban Co-Op Credit Bank Ltd", label: "Khanapur Urban Co-Op Credit Bank Ltd" },
  { value: "Khandesh Rashtriya Vidyut Karmachari Sh. Pat Santha Ltd", label: "Khandesh Rashtriya Vidyut Karmachari Sh. Pat Santha Ltd" },
  { value: "Khandesh Vidarbh Nagrik Sahkari Patsanshta Maryadit", label: "Khandesh Vidarbh Nagrik Sahkari Patsanshta Maryadit" },
  { value: "Khandeshwar Gramin Bigar Sahakari Pathsanstha", label: "Khandeshwar Gramin Bigar Sahakari Pathsanstha" },
  { value: "Khandwa District Central Co-Op Bank Ltd", label: "Khandwa District Central Co-Op Bank Ltd" },
  { value: "Khandwa District Krushi Gramin Bank Ltd", label: "Khandwa District Krushi Gramin Bank Ltd" },
  { value: "Khanjan Finance And Lease Pvt Ltd", label: "Khanjan Finance And Lease Pvt Ltd" },
  { value: "Khanna Finance Company", label: "Khanna Finance Company" },
  { value: "Khanuja Finance", label: "Khanuja Finance" },
  { value: "Khardah Co-Operative Bank Ltd.", label: "Khardah Co-Operative Bank Ltd." },
  { value: "Khargone Nagarik Sahakari Bank Ltd", label: "Khargone Nagarik Sahakari Bank Ltd" },
  { value: "Khargone Nagrik Sahakari Bank Ltd", label: "Khargone Nagrik Sahakari Bank Ltd" },
  { value: "Khariwal Finance", label: "Khariwal Finance" },
  { value: "Khariwal Finance (P) Ltd", label: "Khariwal Finance (P) Ltd" },
  { value: "Khariwal Finance P Ltd", label: "Khariwal Finance P Ltd" },
  { value: "Khatav Taluka Co-Op Credit Soc.Ltd", label: "Khatav Taluka Co-Op Credit Soc.Ltd" },
  { value: "Khatra Peoples Co-Op Bank Ltd", label: "Khatra Peoples Co-Op Bank Ltd" },
  { value: "Khatrah Finance Pvt Ltd", label: "Khatrah Finance Pvt Ltd" },
  { value: "Khatri Co-Op Urban Bank Ltd.", label: "Khatri Co-Op Urban Bank Ltd." },
  { value: "Khatri Co-Operative Ltd", label: "Khatri Co-Operative Ltd" },
  { value: "Khattri Co-Operative Bank", label: "Khattri Co-Operative Bank" },
  { value: "Khayali Lal Mehta", label: "Khayali Lal Mehta" },
  { value: "Khed Urban Co-Op Bank Ltd", label: "Khed Urban Co-Op Bank Ltd" },
  { value: "Kheda District Central Co-Operative Bank Ltd.", label: "Kheda District Central Co-Operative Bank Ltd." },
  { value: "Kheda Leasing Finance Pvt Ltd", label: "Kheda Leasing Finance Pvt Ltd" },
  { value: "Kheda Peoples Co-Op Bank Ltd", label: "Kheda Peoples Co-Op Bank Ltd" },
  { value: "Khedbrahma Urban Co-Op Bank Ltd", label: "Khedbrahma Urban Co-Op Bank Ltd" },
  { value: "Khera Leasing Co.", label: "Khera Leasing Co." },
  { value: "Kheralu Nagrik Sahakari Bank Ltd.", label: "Kheralu Nagrik Sahakari Bank Ltd." },
  { value: "Kherva Seva Sahkari Mandli", label: "Kherva Seva Sahkari Mandli" },
  { value: "Khetan Credit Corporation", label: "Khetan Credit Corporation" },
  { value: "Khetan Credit Corporation And Bank Of India", label: "Khetan Credit Corporation And Bank Of India" },
  { value: "Kheti Badi Finance", label: "Kheti Badi Finance" },
  { value: "Kheti Vikas Seva Sahkari Mandli Ltd", label: "Kheti Vikas Seva Sahkari Mandli Ltd" },
  { value: "Khicha Estates And Finance", label: "Khicha Estates And Finance" },
  { value: "Khirasra Juth Seva Sahakari Mandali Ltd", label: "Khirasra Juth Seva Sahakari Mandali Ltd" },
  { value: "Khirasra Sahkari Mandali", label: "Khirasra Sahkari Mandali" },
  { value: "Khiwasara Finance", label: "Khiwasara Finance" },
  { value: "Khiwasara Finance And Leasing Ltd.", label: "Khiwasara Finance And Leasing Ltd." },
  { value: "Khodakpurna Urban Co.Op. Credit Society", label: "Khodakpurna Urban Co.Op. Credit Society" },
  { value: "Khodiyar Auto Consultant", label: "Khodiyar Auto Consultant" },
  { value: "Khubsoorat Leasing & Fin (P) Ltd", label: "Khubsoorat Leasing & Fin (P) Ltd" },
  { value: "Khuleshwer Gramin Bigarsheti Sahakari", label: "Khuleshwer Gramin Bigarsheti Sahakari" },
  { value: "Khurana Leasing And Finance Co.", label: "Khurana Leasing And Finance Co." },
  { value: "Khurda District Central Co-Operative Bank Ltd.", label: "Khurda District Central Co-Operative Bank Ltd." },
  { value: "Khusboo  Auto Finance", label: "Khusboo  Auto Finance" },
  { value: "Khusboo Finlease Pvt. Ltd.", label: "Khusboo Finlease Pvt. Ltd." },
  { value: "Khusbu Auto Finance Ltd", label: "Khusbu Auto Finance Ltd" },
  { value: "Khushal Auto Finance", label: "Khushal Auto Finance" },
  { value: "Khushal Auto Finance", label: "Khushal Auto Finance" },
  { value: "Khushal Finance", label: "Khushal Finance" },
  { value: "Khushal Money Lenders", label: "Khushal Money Lenders" },
  { value: "Khushboo Finlease Pvt Ltd", label: "Khushboo Finlease Pvt Ltd" },
  { value: "Khushbu Auto Finance Ltd", label: "Khushbu Auto Finance Ltd" },
  { value: "Khushbu Enterprises Surat", label: "Khushbu Enterprises Surat" },
  { value: "Khushi Associates", label: "Khushi Associates" },
  { value: "Khushmeet Fin Co", label: "Khushmeet Fin Co" },
  { value: "Kichha Financiers (Regd)", label: "Kichha Financiers (Regd)" },
  { value: "Kidwai Memorial Institute Of Oncology", label: "Kidwai Memorial Institute Of Oncology" },
  { value: "Kikkeri Vyavasaya Seva Sh.Sangha Bk Br L", label: "Kikkeri Vyavasaya Seva Sh.Sangha Bk Br L" },
  { value: "Kim Hyundai Finance", label: "Kim Hyundai Finance" },
  { value: "Kinetic Finance Ltd.", label: "Kinetic Finance Ltd." },
  { value: "Kinetic Fincap Ltd.", label: "Kinetic Fincap Ltd." },
  { value: "Kinetic Lease And Finance Ltd", label: "Kinetic Lease And Finance Ltd" },
  { value: "Kinjal Finance", label: "Kinjal Finance" },
  { value: "Kinjal Motors", label: "Kinjal Motors" },
  { value: "Kinker Auto Sales", label: "Kinker Auto Sales" },
  { value: "Kinnigoli Vyavasaya Seva Sahakari Bank Ltd", label: "Kinnigoli Vyavasaya Seva Sahakari Bank Ltd" },
  { value: "Kiran", label: "Kiran" },
  { value: "Kiran & Co", label: "Kiran & Co" },
  { value: "Kiran Auto Finance", label: "Kiran Auto Finance" },
  { value: "Kiran Co Op Credit Soc", label: "Kiran Co Op Credit Soc" },
  { value: "Kiran Enterprises", label: "Kiran Enterprises" },
  { value: "Kiran Finance (R)", label: "Kiran Finance (R)" },
  { value: "Kiran Finance And Investments", label: "Kiran Finance And Investments" },
  { value: "Kiran Gramin Biger Sheti Sah Pat San", label: "Kiran Gramin Biger Sheti Sah Pat San" },
  { value: "Kiran Hire Purchase And Leasing Ltd", label: "Kiran Hire Purchase And Leasing Ltd" },
  { value: "Kiran Investments", label: "Kiran Investments" },
  { value: "Kiran Kumar", label: "Kiran Kumar" },
  { value: "Kiran Kumari", label: "Kiran Kumari" },
  { value: "Kiran Motors", label: "Kiran Motors" },
  { value: "Kirpekar Eng Pvt Ltd", label: "Kirpekar Eng Pvt Ltd" },
  { value: "Kisan Finance", label: "Kisan Finance" },
  { value: "Kisan Gramin Bank", label: "Kisan Gramin Bank" },
  { value: "Kisan Gramin Bigar Sheti Sahkari Patsanstha", label: "Kisan Gramin Bigar Sheti Sahkari Patsanstha" },
  { value: "Kisan Nagari Bigar Sheti Sah Pat Sanstha", label: "Kisan Nagari Bigar Sheti Sah Pat Sanstha" },
  { value: "Kisan Ratilal Choksey And Company", label: "Kisan Ratilal Choksey And Company" },
  { value: "Kisanlalji Sancheti Co- Op Credit Society", label: "Kisanlalji Sancheti Co- Op Credit Society" },
  { value: "Kisanrao Varal Patil Patsanstha", label: "Kisanrao Varal Patil Patsanstha" },
  { value: "Kishakbhari  Co Op Pvt Ltd", label: "Kishakbhari  Co Op Pvt Ltd" },
  { value: "Kishan Gramin Bank", label: "Kishan Gramin Bank" },
  { value: "Kishan Gramin Bigar Sheti Sahakari Patsanstha", label: "Kishan Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Kishore Investment", label: "Kishore Investment" },
  { value: "Kishore Kumar Dagaliya", label: "Kishore Kumar Dagaliya" },
  { value: "Kishore Kumar Dagaliya", label: "Kishore Kumar Dagaliya" },
  { value: "Kishore Kumar Nahar", label: "Kishore Kumar Nahar" },
  { value: "Kishore Motor Corporate", label: "Kishore Motor Corporate" },
  { value: "Kishore Motor Corporate", label: "Kishore Motor Corporate" },
  { value: "Kissan Finvest Pvt Ltd", label: "Kissan Finvest Pvt Ltd" },
  { value: "Kit Karmachari Sahakari Pat San Mary", label: "Kit Karmachari Sahakari Pat San Mary" },
  { value: "Kitariwal Finance (P) Ltd", label: "Kitariwal Finance (P) Ltd" },
  { value: "Kittur Rani Chennamma Mahila Co-Op Bank Ltd", label: "Kittur Rani Chennamma Mahila Co-Op Bank Ltd" },
  { value: "Kizhuparamba Service Co-Op Bank Ltd.", label: "Kizhuparamba Service Co-Op Bank Ltd." },
  { value: "Kjmc Financial Services Ltd", label: "Kjmc Financial Services Ltd" },
  { value: "Kkgb", label: "Kkgb" },
  { value: "Kkgb Mainpuri", label: "Kkgb Mainpuri" },
  { value: "Klf Ltd", label: "Klf Ltd" },
  { value: "Klm Credit Syndicate", label: "Klm Credit Syndicate" },
  { value: "Klub Holidays & Marketing Services", label: "Klub Holidays & Marketing Services" },
  { value: "Kml Credit Syndicate", label: "Kml Credit Syndicate" },
  { value: "Kmpl", label: "Kmpl" },
  { value: "Kms Finlease Ltd", label: "Kms Finlease Ltd" },
  { value: "Kns Bank Ltd", label: "Kns Bank Ltd" },
  { value: "Knshu Securities", label: "Knshu Securities" },
  { value: "Kocher Finance Ltd", label: "Kocher Finance Ltd" },
  { value: "Kocheta Finance Corporation", label: "Kocheta Finance Corporation" },
  { value: "Kochin Co-Operative Society Ltd", label: "Kochin Co-Operative Society Ltd" },
  { value: "Kodagu District Central Co-Operative Bank Ltd.", label: "Kodagu District Central Co-Operative Bank Ltd." },
  { value: "Kodagu Grameena Bank", label: "Kodagu Grameena Bank" },
  { value: "Kodagu Jilla Vanijyodyanigala Vividhodes", label: "Kodagu Jilla Vanijyodyanigala Vividhodes" },
  { value: "Kodanad Service Cooperative Bank", label: "Kodanad Service Cooperative Bank" },
  { value: "Kodassery Elinjiora Service C.O.B.Ltd", label: "Kodassery Elinjiora Service C.O.B.Ltd" },
  { value: "Kodilpet Vysavasaya Seva Sahakara Bank L", label: "Kodilpet Vysavasaya Seva Sahakara Bank L" },
  { value: "Kodinar Tlk District Central Co-Operative Bank Ltd.", label: "Kodinar Tlk District Central Co-Operative Bank Ltd." },
  { value: "Kodiyathur Co Op Urban Bank Ltd", label: "Kodiyathur Co Op Urban Bank Ltd" },
  { value: "Kodoli Nagari Sahakari Patsanstha Maryadit", label: "Kodoli Nagari Sahakari Patsanstha Maryadit" },
  { value: "Kodoli Urban Co. Op. Bank Ltd.", label: "Kodoli Urban Co. Op. Bank Ltd." },
  { value: "Kodoli Vyapari Gramin Sah Pat San Ltd.", label: "Kodoli Vyapari Gramin Sah Pat San Ltd." },
  { value: "Kodungallur Town Co-Op Bank Ltd", label: "Kodungallur Town Co-Op Bank Ltd" },
  { value: "Kodur Service Co-Op Bank Ltd.", label: "Kodur Service Co-Op Bank Ltd." },
  { value: "Kogta Financial (India) Ltd", label: "Kogta Financial (India) Ltd" },
  { value: "Kohinoor Enterprises", label: "Kohinoor Enterprises" },
  { value: "Kohinoor Hire Purchasing Co", label: "Kohinoor Hire Purchasing Co" },
  { value: "Kohli Auto Company", label: "Kohli Auto Company" },
  { value: "Kohli Leasing Finance Co", label: "Kohli Leasing Finance Co" },
  { value: "Kohli Motors", label: "Kohli Motors" },
  { value: "Koila Auto Finance.", label: "Koila Auto Finance." },
  { value: "Koina Sahakari Bank Ltd", label: "Koina Sahakari Bank Ltd" },
  { value: "Kokan Darshan Sahakari Patsanstha Ltd", label: "Kokan Darshan Sahakari Patsanstha Ltd" },
  { value: "Kokan Dhangarwadi Nagri Co Op Bank Ltd", label: "Kokan Dhangarwadi Nagri Co Op Bank Ltd" },
  { value: "Kokan Mercantile Co-Operative Bank Ltd", label: "Kokan Mercantile Co-Operative Bank Ltd" },
  { value: "Kokan Prant Sahakari Bank Ltd", label: "Kokan Prant Sahakari Bank Ltd" },
  { value: "Kokila Mahesh Sanghvi", label: "Kokila Mahesh Sanghvi" },
  { value: "Kolakkad Service Co-Op.Bank Ltd.", label: "Kolakkad Service Co-Op.Bank Ltd." },
  { value: "Kolar District Central Co-Operative Bank Ltd.", label: "Kolar District Central Co-Operative Bank Ltd." },
  { value: "Kolar Kasaba South Sericul.C.F.C.O.B.Ltd", label: "Kolar Kasaba South Sericul.C.F.C.O.B.Ltd" },
  { value: "Kolhapur District Central Co-Op Bank Ltd", label: "Kolhapur District Central Co-Op Bank Ltd" },
  { value: "Kolhapur District Central Co-Op. Bank Ltd., Kolhapur", label: "Kolhapur District Central Co-Op. Bank Ltd., Kolhapur" },
  { value: "Kolhapur District Central Coop. Bank Ltd", label: "Kolhapur District Central Coop. Bank Ltd" },
  { value: "Kolhapur Janata Sahakari Bank Ltd.", label: "Kolhapur Janata Sahakari Bank Ltd." },
  { value: "Kolhapur Janata Sahakari Bank Ltd., Kolhapur", label: "Kolhapur Janata Sahakari Bank Ltd., Kolhapur" },
  { value: "Kolhapur Mahila Co-Operative Bank Ltd", label: "Kolhapur Mahila Co-Operative Bank Ltd" },
  { value: "Kolhapur Mahila Sahakari Bank Ltd,", label: "Kolhapur Mahila Sahakari Bank Ltd," },
  { value: "Kolhapur Mahila Sahakari Bank Ltd.", label: "Kolhapur Mahila Sahakari Bank Ltd." },
  { value: "Kolhapur Mahila Sahakari Bank Ltd., Kolhapur", label: "Kolhapur Mahila Sahakari Bank Ltd., Kolhapur" },
  { value: "Kolhapur Maratha Co-Op. Bank Ltd., Kolhapur", label: "Kolhapur Maratha Co-Op. Bank Ltd., Kolhapur" },
  { value: "Kolhapur Urban Co-Op. Bank Ltd., Kolhapur", label: "Kolhapur Urban Co-Op. Bank Ltd., Kolhapur" },
  { value: "Kolhapur Vyapari Nagari Sahakari Pat Sanstha Maryadit", label: "Kolhapur Vyapari Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Kolhapur Zila Govt Service Co-Op Bank Ltd", label: "Kolhapur Zila Govt Service Co-Op Bank Ltd" },
  { value: "Kolhapur Zila Janata Sahakari Bank Ltd", label: "Kolhapur Zila Janata Sahakari Bank Ltd" },
  { value: "Kolhapur Zila Madyamik Shiskshak & Karmahari Sah. Datpedhi Ltd", label: "Kolhapur Zila Madyamik Shiskshak & Karmahari Sah. Datpedhi Ltd" },
  { value: "Kolhapur Zilha Mahsul Khate Karmachari Sahkari Patsanstha Mydt", label: "Kolhapur Zilha Mahsul Khate Karmachari Sahkari Patsanstha Mydt" },
  { value: "Kolhapur Zilla Madyamik Shikshak & Karmachari Sah. Pathpedhi Maryadit.", label: "Kolhapur Zilla Madyamik Shikshak & Karmachari Sah. Pathpedhi Maryadit." },
  { value: "Kolkata Port Trust", label: "Kolkata Port Trust" },
  { value: "Kolkkalam Service Co-Op Bank Ltd.", label: "Kolkkalam Service Co-Op Bank Ltd." },
  { value: "Kollad Service Co.Op. Bank Ltd", label: "Kollad Service Co.Op. Bank Ltd" },
  { value: "Kollam District Central Co-Operative Bank Ltd.", label: "Kollam District Central Co-Operative Bank Ltd." },
  { value: "Kollamogru Harihara Co-Op. Agrl. Bank Ni", label: "Kollamogru Harihara Co-Op. Agrl. Bank Ni" },
  { value: "Kollapalur Primary Agrl. Co-Op. Bank Ltd", label: "Kollapalur Primary Agrl. Co-Op. Bank Ltd" },
  { value: "Kollur Shri Mookambika Vyasya Seva Sahakari Niyamitha", label: "Kollur Shri Mookambika Vyasya Seva Sahakari Niyamitha" },
  { value: "Komal Auto Finance Ltd", label: "Komal Auto Finance Ltd" },
  { value: "Komal Chand", label: "Komal Chand" },
  { value: "Komal Investment", label: "Komal Investment" },
  { value: "Komalasri Automobile Financiers", label: "Komalasri Automobile Financiers" },
  { value: "Komban Antony Antosh", label: "Komban Antony Antosh" },
  { value: "Konda Chinna Vara Lakshmi Auto Finance", label: "Konda Chinna Vara Lakshmi Auto Finance" },
  { value: "Konda Mala Kondaiah Auto Finance", label: "Konda Mala Kondaiah Auto Finance" },
  { value: "Konda Vara Lakshmi Auto Finance", label: "Konda Vara Lakshmi Auto Finance" },
  { value: "Kondal Rao Financiers", label: "Kondal Rao Financiers" },
  { value: "Kone Elevator India Pvt Ltd", label: "Kone Elevator India Pvt Ltd" },
  { value: "Kongampattu Primary Agricultural Co-Op Bank Ltd", label: "Kongampattu Primary Agricultural Co-Op Bank Ltd" },
  { value: "Kongorpilly Farmers Services Co-Op. Bk.L", label: "Kongorpilly Farmers Services Co-Op. Bk.L" },
  { value: "Kongu Finance", label: "Kongu Finance" },
  { value: "Konkan Mercantile Co-Op. Bank Ltd., Mumbai", label: "Konkan Mercantile Co-Op. Bank Ltd., Mumbai" },
  { value: "Konkan Railway Corporation Ltd.", label: "Konkan Railway Corporation Ltd." },
  { value: "Konnagar Samabaya Bank Ltd", label: "Konnagar Samabaya Bank Ltd" },
  { value: "Konoklota Mahila Urban Co-Op. Bank Ltd.", label: "Konoklota Mahila Urban Co-Op. Bank Ltd." },
  { value: "Koodali Public Servants Co-Op Society Lt", label: "Koodali Public Servants Co-Op Society Lt" },
  { value: "Koorkenchery Vanitha Co-Operative Service Society", label: "Koorkenchery Vanitha Co-Operative Service Society" },
  { value: "Koothanallir Prim. Agrl. Cp Bank Ltd.", label: "Koothanallir Prim. Agrl. Cp Bank Ltd." },
  { value: "Koovappady Service Co-Operative Bank", label: "Koovappady Service Co-Operative Bank" },
  { value: "Koovappady Services Coperative Bk Ltd", label: "Koovappady Services Coperative Bk Ltd" },
  { value: "Koovappally Service Co-Op. Bank Ltd.", label: "Koovappally Service Co-Op. Bank Ltd." },
  { value: "Kopargaon Taluka Merchants Co-Op Bank Ltd", label: "Kopargaon Taluka Merchants Co-Op Bank Ltd" },
  { value: "Koparkhairne Nagrik Sahakari Soc Ltd", label: "Koparkhairne Nagrik Sahakari Soc Ltd" },
  { value: "Kopergaon Peoples Co-Op Bank Ltd", label: "Kopergaon Peoples Co-Op Bank Ltd" },
  { value: "Korangrapady C A Bank Ltd", label: "Korangrapady C A Bank Ltd" },
  { value: "Korangrapady C.A. Bank Ltd.", label: "Korangrapady C.A. Bank Ltd." },
  { value: "Koraput District Central Co-Operative Bank Ltd.", label: "Koraput District Central Co-Operative Bank Ltd." },
  { value: "Koraput Panchabati Gramya Bank Ltd", label: "Koraput Panchabati Gramya Bank Ltd" },
  { value: "Koraty Hire Purchase", label: "Koraty Hire Purchase" },
  { value: "Koraty Service Co-Op Bank Ltd,", label: "Koraty Service Co-Op Bank Ltd," },
  { value: "Koregaon Co-Oppeoples Bank Ltd", label: "Koregaon Co-Oppeoples Bank Ltd" },
  { value: "Koruthodu Service Co.Op Bank Ltd.", label: "Koruthodu Service Co.Op Bank Ltd." },
  { value: "Kosamba Merchantile Co-Op Bank Ltd", label: "Kosamba Merchantile Co-Op Bank Ltd" },
  { value: "Koshal Enterprises", label: "Koshal Enterprises" },
  { value: "Kosi Kshetriya Gramin Bank", label: "Kosi Kshetriya Gramin Bank" },
  { value: "Kosi Kshetriya Gramin Bank", label: "Kosi Kshetriya Gramin Bank" },
  { value: "Kosmba Purva Vibhag Society", label: "Kosmba Purva Vibhag Society" },
  { value: "Kosmo Autos Pvt.Ltd.", label: "Kosmo Autos Pvt.Ltd." },
  { value: "Kosmo Plastics Exports Pvt Ltd", label: "Kosmo Plastics Exports Pvt Ltd" },
  { value: "Kota Auto Finance Pvt Ltd.", label: "Kota Auto Finance Pvt Ltd." },
  { value: "Kota District Central Co-Operative Bank Ltd.", label: "Kota District Central Co-Operative Bank Ltd." },
  { value: "Kota Karmchari Sehkari Samiti Ltd", label: "Kota Karmchari Sehkari Samiti Ltd" },
  { value: "Kota Mahila Nagarik Sahakari Bank Ltd", label: "Kota Mahila Nagarik Sahakari Bank Ltd" },
  { value: "Kota Mahila Nagrik", label: "Kota Mahila Nagrik" },
  { value: "Kota Mahila Nagrik Sahkari Bank Ltd,", label: "Kota Mahila Nagrik Sahkari Bank Ltd," },
  { value: "Kota Nagarik Sahakari Bank", label: "Kota Nagarik Sahakari Bank" },
  { value: "Kota Nagarik Sahkari Bank Ltd", label: "Kota Nagarik Sahkari Bank Ltd" },
  { value: "Kota Nagrik Sahkari Bank Ltd.", label: "Kota Nagrik Sahkari Bank Ltd." },
  { value: "Kotaiah Swamy Automobile Finance", label: "Kotaiah Swamy Automobile Finance" },
  { value: "Kotak", label: "Kotak" },
  { value: "Kotak Mahendra Primus Ltd.", label: "Kotak Mahendra Primus Ltd." },
  { value: "Kotak Mahindra", label: "Kotak Mahindra" },
  { value: "Kotak Mahindra Bank Ltd.", label: "Kotak Mahindra Bank Ltd." },
  { value: "Kotak Mahindra Capital Co Ltd", label: "Kotak Mahindra Capital Co Ltd" },
  { value: "Kotak Mahindra Fin Ltd.", label: "Kotak Mahindra Fin Ltd." },
  { value: "Kotak Mahindra Finance Ltd", label: "Kotak Mahindra Finance Ltd" },
  { value: "Kotak Mahindra Finance Ltd.", label: "Kotak Mahindra Finance Ltd." },
  { value: "Kotak Mahindra Ltd", label: "Kotak Mahindra Ltd" },
  { value: "Kotak Mahindra Prime", label: "Kotak Mahindra Prime" },
  { value: "Kotak Mahindra Prime Finace Service Limited", label: "Kotak Mahindra Prime Finace Service Limited" },
  { value: "Kotak Mahindra Primius", label: "Kotak Mahindra Primius" },
  { value: "Kotak Mahindra Primus Limited", label: "Kotak Mahindra Primus Limited" },
  { value: "Kotak Mahindra Primus Ltd", label: "Kotak Mahindra Primus Ltd" },
  { value: "Kotak Mahindra Private Ltd", label: "Kotak Mahindra Private Ltd" },
  { value: "Kotak Mahindra Privur P Ltd", label: "Kotak Mahindra Privur P Ltd" },
  { value: "Kotal Bank", label: "Kotal Bank" },
  { value: "Kotari Enterprises", label: "Kotari Enterprises" },
  { value: "Kotekar Vyavasaya Sev.Sah.Sangha Niyamit", label: "Kotekar Vyavasaya Sev.Sah.Sangha Niyamit" },
  { value: "Koteshwara Sahakari Bank", label: "Koteshwara Sahakari Bank" },
  { value: "Koteswaramma Auto Finance", label: "Koteswaramma Auto Finance" },
  { value: "Kothai & Co", label: "Kothai & Co" },
  { value: "Kothai Credits", label: "Kothai Credits" },
  { value: "Kothai Investments", label: "Kothai Investments" },
  { value: "Kothali V.K.Sah.Seva.Soc.Ltd", label: "Kothali V.K.Sah.Seva.Soc.Ltd" },
  { value: "Kothamangalam Service Co-Op Bank Ltd", label: "Kothamangalam Service Co-Op Bank Ltd" },
  { value: "Kothari & Co", label: "Kothari & Co" },
  { value: "Kothari Auto Finance", label: "Kothari Auto Finance" },
  { value: "Kothari Auto Investments", label: "Kothari Auto Investments" },
  { value: "Kothari Corporation", label: "Kothari Corporation" },
  { value: "Kothari Enterprises", label: "Kothari Enterprises" },
  { value: "Kothari Finance Corp", label: "Kothari Finance Corp" },
  { value: "Kothari Financiers", label: "Kothari Financiers" },
  { value: "Kothari Hire Purchase", label: "Kothari Hire Purchase" },
  { value: "Kothari Investments", label: "Kothari Investments" },
  { value: "Kothari Motor Finance", label: "Kothari Motor Finance" },
  { value: "Kothari Motor Finance Corporation", label: "Kothari Motor Finance Corporation" },
  { value: "Kothari Safe Deposits Ltd.", label: "Kothari Safe Deposits Ltd." },
  { value: "Kothari Trade Finance", label: "Kothari Trade Finance" },
  { value: "Kothari Trade Finance Corporation", label: "Kothari Trade Finance Corporation" },
  { value: "Kothariya Seva Shakari Mandali", label: "Kothariya Seva Shakari Mandali" },
  { value: "Kothati And Son", label: "Kothati And Son" },
  { value: "Kothavara Service Co Operative Bank", label: "Kothavara Service Co Operative Bank" },
  { value: "Kothi Seva Sahakari Mandli", label: "Kothi Seva Sahakari Mandli" },
  { value: "Kotoor Vyavasaya Seva Sah Bk Ni.", label: "Kotoor Vyavasaya Seva Sah Bk Ni." },
  { value: "Kottaiyar Primary Agriculture Co.Bk.Ltd", label: "Kottaiyar Primary Agriculture Co.Bk.Ltd" },
  { value: "Kottakkal Co-Op Urban Bank Ltd", label: "Kottakkal Co-Op Urban Bank Ltd" },
  { value: "Kottakkal Co-Op Urban Bank Ltd.", label: "Kottakkal Co-Op Urban Bank Ltd." },
  { value: "Kottakkal Service Co-Op Bank Ltd.", label: "Kottakkal Service Co-Op Bank Ltd." },
  { value: "Kottappady East Service Co-Op Bank Ltd,", label: "Kottappady East Service Co-Op Bank Ltd," },
  { value: "Kottayam Co-Op. Urban Bank Ltd.", label: "Kottayam Co-Op. Urban Bank Ltd." },
  { value: "Kottayam District Central Co-Operative Bank Ltd.", label: "Kottayam District Central Co-Operative Bank Ltd." },
  { value: "Kovilpathu Primary Agricultural C.B.L.", label: "Kovilpathu Primary Agricultural C.B.L." },
  { value: "Kovur Primary Agr.Co-Op Bank Ltd.,", label: "Kovur Primary Agr.Co-Op Bank Ltd.," },
  { value: "Koyana Sahakari Bank Ltd", label: "Koyana Sahakari Bank Ltd" },
  { value: "Koyana Sahakari Bank Ltd", label: "Koyana Sahakari Bank Ltd" },
  { value: "Koylanchal Saving And Credit Self Sup Co-Oppretive", label: "Koylanchal Saving And Credit Self Sup Co-Oppretive" },
  { value: "Koyyur Service Co-Op Bank Ltd.", label: "Koyyur Service Co-Op Bank Ltd." },
  { value: "Kozhikode District Central Co-Operative Bank Ltd.", label: "Kozhikode District Central Co-Operative Bank Ltd." },
  { value: "Kozhikode District Co-Operative Bank Ltd", label: "Kozhikode District Co-Operative Bank Ltd" },
  { value: "Kp Finance", label: "Kp Finance" },
  { value: "Kp Traders", label: "Kp Traders" },
  { value: "Kpr Investment P Ltd", label: "Kpr Investment P Ltd" },
  { value: "Kr & Company", label: "Kr & Company" },
  { value: "Kranti Gramin Bigar Sheti Shakari Pat Santha Mydt", label: "Kranti Gramin Bigar Sheti Shakari Pat Santha Mydt" },
  { value: "Kranti Motors & Leasing", label: "Kranti Motors & Leasing" },
  { value: "Krati Hemnani", label: "Krati Hemnani" },
  { value: "Kribhcio ,Hajira", label: "Kribhcio ,Hajira" },
  { value: "Kribhko", label: "Kribhko" },
  { value: "Kripashankar Sidhanath Upadhyay", label: "Kripashankar Sidhanath Upadhyay" },
  { value: "Krish Fin Inc", label: "Krish Fin Inc" },
  { value: "Krish Finance", label: "Krish Finance" },
  { value: "Krishak Bharati Co-Operative Limited", label: "Krishak Bharati Co-Operative Limited" },
  { value: "Krishak Sewa Sahakari Samiti Maryadit", label: "Krishak Sewa Sahakari Samiti Maryadit" },
  { value: "Krishan Finance", label: "Krishan Finance" },
  { value: "Krishan Kumar", label: "Krishan Kumar" },
  { value: "Krishan Kumar Awana", label: "Krishan Kumar Awana" },
  { value: "Krishanan Finance", label: "Krishanan Finance" },
  { value: "Krishi Gramin Vikas Bank", label: "Krishi Gramin Vikas Bank" },
  { value: "Krishi Pandit Mohanlal Lodha Gramin Pathsantha", label: "Krishi Pandit Mohanlal Lodha Gramin Pathsantha" },
  { value: "Krishi Vigyan Kendra", label: "Krishi Vigyan Kendra" },
  { value: "Krishna Auto Finance", label: "Krishna Auto Finance" },
  { value: "Krishna Automobiles", label: "Krishna Automobiles" },
  { value: "Krishna Bhima Samruddhi Local Area Bk.L.", label: "Krishna Bhima Samruddhi Local Area Bk.L." },
  { value: "Krishna Bhims Samrudhi (Lab)", label: "Krishna Bhims Samrudhi (Lab)" },
  { value: "Krishna Co-Op Credit Society Ltd.", label: "Krishna Co-Op Credit Society Ltd." },
  { value: "Krishna Corporation", label: "Krishna Corporation" },
  { value: "Krishna District Central Co-Operative Bank Ltd.", label: "Krishna District Central Co-Operative Bank Ltd." },
  { value: "Krishna Enterprise", label: "Krishna Enterprise" },
  { value: "Krishna Finance Co.", label: "Krishna Finance Co." },
  { value: "Krishna Finance Corporation", label: "Krishna Finance Corporation" },
  { value: "Krishna Finance Services", label: "Krishna Finance Services" },
  { value: "Krishna Financiers", label: "Krishna Financiers" },
  { value: "Krishna Govind Finance Ltd", label: "Krishna Govind Finance Ltd" },
  { value: "Krishna Grameen Bank Chincholi Branch", label: "Krishna Grameen Bank Chincholi Branch" },
  { value: "Krishna Institute Of Management And Technology Prop Kanha Charitable Trust", label: "Krishna Institute Of Management And Technology Prop Kanha Charitable Trust" },
  { value: "Krishna Kumari Auto Finance", label: "Krishna Kumari Auto Finance" },
  { value: "Krishna Marketing", label: "Krishna Marketing" },
  { value: "Krishna Mercantile Co-Op Bank Ltd., Bhopal", label: "Krishna Mercantile Co-Op Bank Ltd., Bhopal" },
  { value: "Krishna Mercantile Co-Op. Bank Ltd.", label: "Krishna Mercantile Co-Op. Bank Ltd." },
  { value: "Krishna Motor Finance (P) Ltd", label: "Krishna Motor Finance (P) Ltd" },
  { value: "Krishna Motors", label: "Krishna Motors" },
  { value: "Krishna Motros", label: "Krishna Motros" },
  { value: "Krishna Pattan Sahakar Bank", label: "Krishna Pattan Sahakar Bank" },
  { value: "Krishna Pharmacuticals", label: "Krishna Pharmacuticals" },
  { value: "Krishna Sai Auto Finance", label: "Krishna Sai Auto Finance" },
  { value: "Krishna Sai Finance Corporation", label: "Krishna Sai Finance Corporation" },
  { value: "Krishna Shree Finance", label: "Krishna Shree Finance" },
  { value: "Krishna Trading Co", label: "Krishna Trading Co" },
  { value: "Krishna Urban Co Op Bank Ltd", label: "Krishna Urban Co Op Bank Ltd" },
  { value: "Krishna Urban Co-Operative Bank M", label: "Krishna Urban Co-Operative Bank M" },
  { value: "Krishna Valley Co-Op. Bank Ltd", label: "Krishna Valley Co-Op. Bank Ltd" },
  { value: "Krishnagiri Urban Co-Op Bank Ltd", label: "Krishnagiri Urban Co-Op Bank Ltd" },
  { value: "Krishnamai Gra Big She Sah Pat San", label: "Krishnamai Gra Big She Sah Pat San" },
  { value: "Krishnanagar Co-Op Credit Society", label: "Krishnanagar Co-Op Credit Society" },
  { value: "Krishnanand Kamath", label: "Krishnanand Kamath" },
  { value: "Krl Finlease Pvt Ltd", label: "Krl Finlease Pvt Ltd" },
  { value: "Krm Financiers", label: "Krm Financiers" },
  { value: "Kroshan Grameena Bank", label: "Kroshan Grameena Bank" },
  { value: "Krunal Shah", label: "Krunal Shah" },
  { value: "Krung Thai Bank Public Co. Ltd.", label: "Krung Thai Bank Public Co. Ltd." },
  { value: "Krushi And Gramin Vikas Bank Ltd.", label: "Krushi And Gramin Vikas Bank Ltd." },
  { value: "Krushi Co-Op Urban Bank Ltd", label: "Krushi Co-Op Urban Bank Ltd" },
  { value: "Krushi Co-Operative Urban Bank Ltd.", label: "Krushi Co-Operative Urban Bank Ltd." },
  { value: "Krushiseva Urban Co-Op Bank Ltd", label: "Krushiseva Urban Co-Op Bank Ltd" },
  { value: "Krushna Gramin Bigar Sheti Sahkari Pathsanstha", label: "Krushna Gramin Bigar Sheti Sahkari Pathsanstha" },
  { value: "Krushna Sahakari Bank Ltd", label: "Krushna Sahakari Bank Ltd" },
  { value: "Krushnai Nagari Pat Sansta", label: "Krushnai Nagari Pat Sansta" },
  { value: "Ksb Finance Corporation", label: "Ksb Finance Corporation" },
  { value: "Ksbcdc Ltd", label: "Ksbcdc Ltd" },
  { value: "Ksbcdl", label: "Ksbcdl" },
  { value: "Kscs", label: "Kscs" },
  { value: "Kshetriya Gramin Bank", label: "Kshetriya Gramin Bank" },
  { value: "Kshetriya Gramin Bank Ltd", label: "Kshetriya Gramin Bank Ltd" },
  { value: "Kshetriya Gramin Bank,", label: "Kshetriya Gramin Bank," },
  { value: "Kshetriya Gramni Bank Hoshangabad", label: "Kshetriya Gramni Bank Hoshangabad" },
  { value: "Kshetriya Kisan Gramin Bank", label: "Kshetriya Kisan Gramin Bank" },
  { value: "Kshipra Motors Pvt.Ltd.", label: "Kshipra Motors Pvt.Ltd." },
  { value: "Kst Finance", label: "Kst Finance" },
  { value: "Kt Finance", label: "Kt Finance" },
  { value: "Kuber Co-Operative Bank Ltd,", label: "Kuber Co-Operative Bank Ltd," },
  { value: "Kuber Enterprises", label: "Kuber Enterprises" },
  { value: "Kuber Finance Co.Ltd", label: "Kuber Finance Co.Ltd" },
  { value: "Kubera Auto Financiers", label: "Kubera Auto Financiers" },
  { value: "Kubera Financing Co", label: "Kubera Financing Co" },
  { value: "Kuchch District Central Co-Operative Bank Ltd.", label: "Kuchch District Central Co-Operative Bank Ltd." },
  { value: "Kuchhal Finance & Traders", label: "Kuchhal Finance & Traders" },
  { value: "Kuchhal Finance Co", label: "Kuchhal Finance Co" },
  { value: "Kuchhal Finance Co Shamli Ltd", label: "Kuchhal Finance Co Shamli Ltd" },
  { value: "Kuchhal Motor & Gen. Fin.", label: "Kuchhal Motor & Gen. Fin." },
  { value: "Kudakkachira Ser Co-Op Bank Ltd.", label: "Kudakkachira Ser Co-Op Bank Ltd." },
  { value: "Kudremukh Iron Ore Co. Ltd", label: "Kudremukh Iron Ore Co. Ltd" },
  { value: "Kujada Sahakari Mandli Ltg", label: "Kujada Sahakari Mandli Ltg" },
  { value: "Kukarvada Nagrik Co Op Bank Ltd", label: "Kukarvada Nagrik Co Op Bank Ltd" },
  { value: "Kukarvada Nagrik Sahakari Bank Ltd.", label: "Kukarvada Nagrik Sahakari Bank Ltd." },
  { value: "Kukarwada Nagrik Sahakari Bank Ltd., Ahmedabad", label: "Kukarwada Nagrik Sahakari Bank Ltd., Ahmedabad" },
  { value: "Kukarwada Nagrik Sahkari Bank", label: "Kukarwada Nagrik Sahkari Bank" },
  { value: "Kukoonoor Finance Private Limited", label: "Kukoonoor Finance Private Limited" },
  { value: "Kulasekhara Mangalam Ser Co Op Bank", label: "Kulasekhara Mangalam Ser Co Op Bank" },
  { value: "Kulasekharapuram Ser Co-Op Bk Ltd,", label: "Kulasekharapuram Ser Co-Op Bk Ltd," },
  { value: "Kulathoor Service Co.Op.Bank Ltd.", label: "Kulathoor Service Co.Op.Bank Ltd." },
  { value: "Kulathupuzha Service Co-Op Bank Ltd", label: "Kulathupuzha Service Co-Op Bank Ltd" },
  { value: "Kuldeep Kaushal", label: "Kuldeep Kaushal" },
  { value: "Kuldeep Singh Yadav", label: "Kuldeep Singh Yadav" },
  { value: "Kuldip Finance Pvt. Ltd.", label: "Kuldip Finance Pvt. Ltd." },
  { value: "Kuleshwar Gramin Biggar S.S . Pat N", label: "Kuleshwar Gramin Biggar S.S . Pat N" },
  { value: "Kulipirai Ulaganayagi Amman Pri.Agr.Co.B", label: "Kulipirai Ulaganayagi Amman Pri.Agr.Co.B" },
  { value: "Kullu Central Co-Operative Bank", label: "Kullu Central Co-Operative Bank" },
  { value: "Shri Kulswami Co Op Credit Socy Ltd", label: "Shri Kulswami Co Op Credit Socy Ltd" },
  { value: "Kulswamini Ambika Gramin Bigar Sahakari Pathsanstha", label: "Kulswamini Ambika Gramin Bigar Sahakari Pathsanstha" },
  { value: "Kulswamini Co-Op Credit Soc Ltd.", label: "Kulswamini Co-Op Credit Soc Ltd." },
  { value: "Kulwant Instalment", label: "Kulwant Instalment" },
  { value: "Kumad Finance", label: "Kumad Finance" },
  { value: "Kumad Finance", label: "Kumad Finance" },
  { value: "Kuman Urban Co-Op Cr. Soc", label: "Kuman Urban Co-Op Cr. Soc" },
  { value: "Kumar Finance", label: "Kumar Finance" },
  { value: "Kumar Financiers Regd", label: "Kumar Financiers Regd" },
  { value: "Kumar Finaniers Regd", label: "Kumar Finaniers Regd" },
  { value: "Kumar Gramin Pat Sanstha", label: "Kumar Gramin Pat Sanstha" },
  { value: "Kumaran Motor Finance", label: "Kumaran Motor Finance" },
  { value: "Kumaranalloor Service Co-Op. Bank Ltd.", label: "Kumaranalloor Service Co-Op. Bank Ltd." },
  { value: "Kumarapuram Service Co. Bank Ltd.", label: "Kumarapuram Service Co. Bank Ltd." },
  { value: "Kumaravel Auto Finance", label: "Kumaravel Auto Finance" },
  { value: "Kumaravel Finance", label: "Kumaravel Finance" },
  { value: "Kumarpal M Jain", label: "Kumarpal M Jain" },
  { value: "Kumbakonam District Central Co-Operative Bank Ltd.", label: "Kumbakonam District Central Co-Operative Bank Ltd." },
  { value: "Kumbar Multi Purpose Souhard Sahakari Bank Ltd", label: "Kumbar Multi Purpose Souhard Sahakari Bank Ltd" },
  { value: "Kumbhat Auto Finance", label: "Kumbhat Auto Finance" },
  { value: "Kumbhi Kasari Sahakari Bank Ltd", label: "Kumbhi Kasari Sahakari Bank Ltd" },
  { value: "Kumbhi Kasari Sahakari Sakhar Karkhana Ltd", label: "Kumbhi Kasari Sahakari Sakhar Karkhana Ltd" },
  { value: "Kumbra Vyashya Seva Sahakari Bank Ltd", label: "Kumbra Vyashya Seva Sahakari Bank Ltd" },
  { value: "Kumta Urban Co-Op Bank Ltd", label: "Kumta Urban Co-Op Bank Ltd" },
  { value: "Kumud Bafna", label: "Kumud Bafna" },
  { value: "Kumud Bafra", label: "Kumud Bafra" },
  { value: "Kumutham Finance", label: "Kumutham Finance" },
  { value: "Kun United Cartrax Private Limited", label: "Kun United Cartrax Private Limited" },
  { value: "Kunal Bhalla", label: "Kunal Bhalla" },
  { value: "Kunal Fin Corp.  New Delhi", label: "Kunal Fin Corp.  New Delhi" },
  { value: "Kunal Fin. Corp.", label: "Kunal Fin. Corp." },
  { value: "Kunal Kumar Nahar", label: "Kunal Kumar Nahar" },
  { value: "Kunal Sharma", label: "Kunal Sharma" },
  { value: "Kunbi Sh Bank", label: "Kunbi Sh Bank" },
  { value: "Kundam Finance", label: "Kundam Finance" },
  { value: "Kundan  Rakesh And Company", label: "Kundan  Rakesh And Company" },
  { value: "Kundan Finance", label: "Kundan Finance" },
  { value: "Kundan Finance Investment Co Ltd", label: "Kundan Finance Investment Co Ltd" },
  { value: "Kundan Investment Finance Co Ltd", label: "Kundan Investment Finance Co Ltd" },
  { value: "Kundapura Taluk Agri Produce Co Mk Soc L", label: "Kundapura Taluk Agri Produce Co Mk Soc L" },
  { value: "Kunduthode Co-Op Ur Bk Ltd.", label: "Kunduthode Co-Op Ur Bk Ltd." },
  { value: "Kunnamangalam Co-Op. Urban Bank Ltd.", label: "Kunnamangalam Co-Op. Urban Bank Ltd." },
  { value: "Kunnamthanam Ser Co-Op Bank Ltd.", label: "Kunnamthanam Ser Co-Op Bank Ltd." },
  { value: "Kunnathunad Taluk Primary Co-Operative Agricultural And Rural Development Bank Ltd", label: "Kunnathunad Taluk Primary Co-Operative Agricultural And Rural Development Bank Ltd" },
  { value: "Kunnathunadu Taluk Pri Co-Operative Bank Ltd", label: "Kunnathunadu Taluk Pri Co-Operative Bank Ltd" },
  { value: "Kunnaukara Service Co-Op Bk Ltd,", label: "Kunnaukara Service Co-Op Bk Ltd," },
  { value: "Kuntashi Juth Seva Sahakari Mandali Ltd", label: "Kuntashi Juth Seva Sahakari Mandali Ltd" },
  { value: "Kuoni Business Travel India Pvt Ltd", label: "Kuoni Business Travel India Pvt Ltd" },
  { value: "Kuoni Travel (I) Pvt Ltd", label: "Kuoni Travel (I) Pvt Ltd" },
  { value: "Kupwad Urban Co-Op Bank Ltd", label: "Kupwad Urban Co-Op Bank Ltd" },
  { value: "Kupwad Urban Co-Op.Bank Ltd.", label: "Kupwad Urban Co-Op.Bank Ltd." },
  { value: "Kuravilanga Service Co-Op.Bk.Ltd.", label: "Kuravilanga Service Co-Op.Bk.Ltd." },
  { value: "Kurla Nagarik Sah. Bank Ltd., Mumbai", label: "Kurla Nagarik Sah. Bank Ltd., Mumbai" },
  { value: "Kurmanchal Nagar Sahkari Bank Ltd.", label: "Kurmanchal Nagar Sahkari Bank Ltd." },
  { value: "Kurnool District Central Co-Operative Bank Ltd.", label: "Kurnool District Central Co-Operative Bank Ltd." },
  { value: "Kurubin Shetty Urban Co-Op Credit Society Ltd", label: "Kurubin Shetty Urban Co-Op Credit Society Ltd" },
  { value: "Kuruchi Finance", label: "Kuruchi Finance" },
  { value: "Kuruhi Nashetty  Co-Op  Credit Society Ltd", label: "Kuruhi Nashetty  Co-Op  Credit Society Ltd" },
  { value: "Kuruhin Shetty Uraban Co-Op Credit Society Ltd", label: "Kuruhin Shetty Uraban Co-Op Credit Society Ltd" },
  { value: "Kuruhin Shetty Urban Co-Op Credit Society Ltd", label: "Kuruhin Shetty Urban Co-Op Credit Society Ltd" },
  { value: "Kuruhina Shetty Co Op Society Ltd", label: "Kuruhina Shetty Co Op Society Ltd" },
  { value: "Kuruhinshetti Urban Co-Op Credit Society Ltd", label: "Kuruhinshetti Urban Co-Op Credit Society Ltd" },
  { value: "Kurukshetra Central Co-Op Bank", label: "Kurukshetra Central Co-Op Bank" },
  { value: "Kurukshetra District Central Co-Operative Bank Ltd.", label: "Kurukshetra District Central Co-Operative Bank Ltd." },
  { value: "Kurukshetra Urban C-O Bk L", label: "Kurukshetra Urban C-O Bk L" },
  { value: "Kurumachal Sehkari Bank", label: "Kurumachal Sehkari Bank" },
  { value: "Kurumanchal Nagar Sahakari Bank Ltd", label: "Kurumanchal Nagar Sahakari Bank Ltd" },
  { value: "Kurumbur Primary Agrl. Co-Op Bank Ltd.", label: "Kurumbur Primary Agrl. Co-Op Bank Ltd." },
  { value: "Kurundwad Urban Co-Op Bank Ltd", label: "Kurundwad Urban Co-Op Bank Ltd" },
  { value: "Kusal Financiers", label: "Kusal Financiers" },
  { value: "Kusalchand Sisodia & Sons", label: "Kusalchand Sisodia & Sons" },
  { value: "Kush Finance", label: "Kush Finance" },
  { value: "Kushal Investments", label: "Kushal Investments" },
  { value: "Kushal Money Lander", label: "Kushal Money Lander" },
  { value: "Kushal Raj Finance", label: "Kushal Raj Finance" },
  { value: "Kushalava Finance", label: "Kushalava Finance" },
  { value: "Kushbu Auto Finance Ltd", label: "Kushbu Auto Finance Ltd" },
  { value: "Kushi Finance", label: "Kushi Finance" },
  { value: "Kushtagi Pattana Sahakara Bank N", label: "Kushtagi Pattana Sahakara Bank N" },
  { value: "Kusum Syndicate", label: "Kusum Syndicate" },
  { value: "Kusum Vijaykumar Mohata", label: "Kusum Vijaykumar Mohata" },
  { value: "Kusumbai Park Town", label: "Kusumbai Park Town" },
  { value: "Kusumgar Finance & Marketing Co. Ltd", label: "Kusumgar Finance & Marketing Co. Ltd" },
  { value: "Kusumgar Finance Pvt Ltd", label: "Kusumgar Finance Pvt Ltd" },
  { value: "Kutch Co-Op Bank Ltd", label: "Kutch Co-Op Bank Ltd" },
  { value: "Kutch Co-Op. Bank Ltd.", label: "Kutch Co-Op. Bank Ltd." },
  { value: "Kutch Gramin Bank", label: "Kutch Gramin Bank" },
  { value: "Kutchi Oswal Mutually Aided Co-Op Credit Society Ltd", label: "Kutchi Oswal Mutually Aided Co-Op Credit Society Ltd" },
  { value: "Kuthuparamba Co-Op Urban Bank Ltd", label: "Kuthuparamba Co-Op Urban Bank Ltd" },
  { value: "Kutikol Urban Co-Op. Bank Ltd.", label: "Kutikol Urban Co-Op. Bank Ltd." },
  { value: "Kuttapuzha Service Co-Op. Bank Ltd.", label: "Kuttapuzha Service Co-Op. Bank Ltd." },
  { value: "Kuttapuzha Service Coop Bank Ltd", label: "Kuttapuzha Service Coop Bank Ltd" },
  { value: "Kuttiachankm", label: "Kuttiachankm" },
  { value: "Kuttiady Panchayat Service Coop Bank Ltd", label: "Kuttiady Panchayat Service Coop Bank Ltd" },
  { value: "Kuttikkatoor Co-Op. Urban Society Ltd.", label: "Kuttikkatoor Co-Op. Urban Society Ltd." },
  { value: "Kuttikol Urban Co-Op. Soc. Ltd.", label: "Kuttikol Urban Co-Op. Soc. Ltd." },
  { value: "Kuttilanji Service Co-Op Bank", label: "Kuttilanji Service Co-Op Bank" },
  { value: "Kuttilanji Service Co-Operative Bank", label: "Kuttilanji Service Co-Operative Bank" },
  { value: "Kuwadua Sahkari Mandali", label: "Kuwadua Sahkari Mandali" },
  { value: "Kuzhuppilly Service Co-Op Bank Ltd", label: "Kuzhuppilly Service Co-Op Bank Ltd" },
  { value: "Kvr Auto Finance", label: "Kvr Auto Finance" },
  { value: "Kvs Leasing And Finance Co.", label: "Kvs Leasing And Finance Co." },
  { value: "Kwality Finance", label: "Kwality Finance" },
  { value: "Kwality General Finance", label: "Kwality General Finance" },
  { value: "Kz Finance", label: "Kz Finance" },
  { value: "L & F S Investment Managers Ltd", label: "L & F S Investment Managers Ltd" },
  { value: "L And T Finance", label: "L And T Finance" },
  { value: "L And T Finance Co Ltd", label: "L And T Finance Co Ltd" },
  { value: "L And T Finance Ltd.", label: "L And T Finance Ltd." },
  { value: "L And T Financial Services", label: "L And T Financial Services" },
  { value: "L Ashok Kumar", label: "L Ashok Kumar" },
  { value: "L Bharath Nahar", label: "L Bharath Nahar" },
  { value: "L C Auto Finance", label: "L C Auto Finance" },
  { value: "L Finance Corporation", label: "L Finance Corporation" },
  { value: "L G Enterprise", label: "L G Enterprise" },
  { value: "L I C Employees Co-Op Bank Ltd", label: "L I C Employees Co-Op Bank Ltd" },
  { value: "L K F Finance Ltd", label: "L K F Finance Ltd" },
  { value: "L Maharaj", label: "L Maharaj" },
  { value: "L Maharaj Kocher", label: "L Maharaj Kocher" },
  { value: "L R N Finance", label: "L R N Finance" },
  { value: "L Sanjay Bafna (Huf)", label: "L Sanjay Bafna (Huf)" },
  { value: "L Shoba Kankariya", label: "L Shoba Kankariya" },
  { value: "L&T Finance", label: "L&T Finance" },
  { value: "L&T Finance Ltd", label: "L&T Finance Ltd" },
  { value: "L&T Financial Service Ltd.", label: "L&T Financial Service Ltd." },
  { value: "L. Uttam Chand Financiers", label: "L. Uttam Chand Financiers" },
  { value: "L.A.C.S", label: "L.A.C.S" },
  { value: "L.Ashok Kumar", label: "L.Ashok Kumar" },
  { value: "L.C.Finance", label: "L.C.Finance" },
  { value: "L.Dilip Kumar Finance", label: "L.Dilip Kumar Finance" },
  { value: "L.G Traders", label: "L.G Traders" },
  { value: "L.G. Traders", label: "L.G. Traders" },
  { value: "L.G.V.B", label: "L.G.V.B" },
  { value: "L.I.C. Employees Co-Operative Bank Ltd", label: "L.I.C. Employees Co-Operative Bank Ltd" },
  { value: "L.I.C. Of India", label: "L.I.C. Of India" },
  { value: "L.M.B. Finance Pvt. Ltd.", label: "L.M.B. Finance Pvt. Ltd." },
  { value: "L.N Finance", label: "L.N Finance" },
  { value: "L.P.Traders", label: "L.P.Traders" },
  { value: "L.R.N. Finance Ltd.", label: "L.R.N. Finance Ltd." },
  { value: "L.T Finance Ltd", label: "L.T Finance Ltd" },
  { value: "Laat Saab Fast Finance", label: "Laat Saab Fast Finance" },
  { value: "Labdhi Finance", label: "Labdhi Finance" },
  { value: "Labh Auto Finance", label: "Labh Auto Finance" },
  { value: "Labh Finance", label: "Labh Finance" },
  { value: "Labh Leasing Finance", label: "Labh Leasing Finance" },
  { value: "Labham Finance Ltd.", label: "Labham Finance Ltd." },
  { value: "Labhuram And Sons", label: "Labhuram And Sons" },
  { value: "Laduram Dungarwal", label: "Laduram Dungarwal" },
  { value: "Laduram Dungarwal Financiers", label: "Laduram Dungarwal Financiers" },
  { value: "Lagan Jute Machinery Co. Ltd.", label: "Lagan Jute Machinery Co. Ltd." },
  { value: "Laghuudyojak Nagarik Sahakari Pathsanstha Maryadit", label: "Laghuudyojak Nagarik Sahakari Pathsanstha Maryadit" },
  { value: "Lahoti Shriniwas Suresh", label: "Lahoti Shriniwas Suresh" },
  { value: "Laila Finance Ltd.", label: "Laila Finance Ltd." },
  { value: "Lakhi Migaonlia Bank", label: "Lakhi Migaonlia Bank" },
  { value: "Lakhim Pur Finvest Co Ltd", label: "Lakhim Pur Finvest Co Ltd" },
  { value: "Lakhimi Gaonlia Bank", label: "Lakhimi Gaonlia Bank" },
  { value: "Lakhimpur Auto And General Finance", label: "Lakhimpur Auto And General Finance" },
  { value: "Lakhvinder Singh Sony", label: "Lakhvinder Singh Sony" },
  { value: "Lakshmi Bank", label: "Lakshmi Bank" },
  { value: "Lakshmi Chenna Keshava Finance", label: "Lakshmi Chenna Keshava Finance" },
  { value: "Lakshmi Enterprises &Co", label: "Lakshmi Enterprises &Co" },
  { value: "Lakshmi Finance", label: "Lakshmi Finance" },
  { value: "Lakshmi Finance And Industrial Corporation Ltd.", label: "Lakshmi Finance And Industrial Corporation Ltd." },
  { value: "Lakshmi Finance Corporation", label: "Lakshmi Finance Corporation" },
  { value: "Lakshmi Ganapathi Auto Finance", label: "Lakshmi Ganapathi Auto Finance" },
  { value: "Lakshmi General Finance Ltd.", label: "Lakshmi General Finance Ltd." },
  { value: "Lakshmi Leasing", label: "Lakshmi Leasing" },
  { value: "Lakshmi Narasimma Finance", label: "Lakshmi Narasimma Finance" },
  { value: "Lakshmi Prasanna Finance Corporation", label: "Lakshmi Prasanna Finance Corporation" },
  { value: "Lakshmi Rama Krishna Finance", label: "Lakshmi Rama Krishna Finance" },
  { value: "Lakshmi Sai Motors", label: "Lakshmi Sai Motors" },
  { value: "Lakshmi Saraswathi  Motor And General Finance (Pvt) Ltd", label: "Lakshmi Saraswathi  Motor And General Finance (Pvt) Ltd" },
  { value: "Lakshmi Shiri Auto Finance", label: "Lakshmi Shiri Auto Finance" },
  { value: "Lakshmi Sri Finance", label: "Lakshmi Sri Finance" },
  { value: "Lakshmi Srinivasa Auto Finance", label: "Lakshmi Srinivasa Auto Finance" },
  { value: "Lakshmi Syndicate", label: "Lakshmi Syndicate" },
  { value: "Lakshmi Tirupatamma Auto Finance", label: "Lakshmi Tirupatamma Auto Finance" },
  { value: "Lakshmi Vengamamba Finance", label: "Lakshmi Vengamamba Finance" },
  { value: "Lakshmi Venkateshwara Finance", label: "Lakshmi Venkateshwara Finance" },
  { value: "Lakshmi Vigneshwara Automobile Finance", label: "Lakshmi Vigneshwara Automobile Finance" },
  { value: "Lakshmi Vilas Bank", label: "Lakshmi Vilas Bank" },
  { value: "Lakshmibai National Institute Of Physical Education", label: "Lakshmibai National Institute Of Physical Education" },
  { value: "Lakshmipura Vyavasaya Seva Sah Bank Ltd.", label: "Lakshmipura Vyavasaya Seva Sah Bank Ltd." },
  { value: "Lal Bahadur Shastri National Academy Of Administration", label: "Lal Bahadur Shastri National Academy Of Administration" },
  { value: "Lal Krishna Advani Nagari Sah Path", label: "Lal Krishna Advani Nagari Sah Path" },
  { value: "Lala Urban Co-Op Bank Ltd", label: "Lala Urban Co-Op Bank Ltd" },
  { value: "Lala Urban Co-Op. Bank Ltd", label: "Lala Urban Co-Op. Bank Ltd" },
  { value: "Lalbahadur Shastri Nagari Sah Pat.", label: "Lalbahadur Shastri Nagari Sah Pat." },
  { value: "Lalbaug Co-Operative Bank Ltd.", label: "Lalbaug Co-Operative Bank Ltd." },
  { value: "Lalesh Kumar Jain", label: "Lalesh Kumar Jain" },
  { value: "Lalit Kumar & Sons", label: "Lalit Kumar & Sons" },
  { value: "Lalita Devi Ramdev Bhavan", label: "Lalita Devi Ramdev Bhavan" },
  { value: "Lalita Investment", label: "Lalita Investment" },
  { value: "Lalitayadav", label: "Lalitayadav" },
  { value: "Lalith Finance", label: "Lalith Finance" },
  { value: "Lalith Finance Corporation", label: "Lalith Finance Corporation" },
  { value: "Lalith Kumar Chordiya", label: "Lalith Kumar Chordiya" },
  { value: "Lalitha Auto Finance", label: "Lalitha Auto Finance" },
  { value: "Lalitha Bokdia Financier", label: "Lalitha Bokdia Financier" },
  { value: "Lalitraj Nagari Sahakari Path Sanstha Maryadit", label: "Lalitraj Nagari Sahakari Path Sanstha Maryadit" },
  { value: "Lally Automobiles Pvt Ltd", label: "Lally Automobiles Pvt Ltd" },
  { value: "Lalu Ganpati Kamgar Gr Bigarsheti Sah Path Mary Ajara", label: "Lalu Ganpati Kamgar Gr Bigarsheti Sah Path Mary Ajara" },
  { value: "Lalu Urban Coop Bank Ltd", label: "Lalu Urban Coop Bank Ltd" },
  { value: "Lalwani Finance Corporation", label: "Lalwani Finance Corporation" },
  { value: "Lalwani Investment", label: "Lalwani Investment" },
  { value: "Lalwani Syndicate", label: "Lalwani Syndicate" },
  { value: "Lamba Finance Co", label: "Lamba Finance Co" },
  { value: "Lamba Investment Finance Pvt .Ltd", label: "Lamba Investment Finance Pvt .Ltd" },
  { value: "Lamba Motors Finance Co", label: "Lamba Motors Finance Co" },
  { value: "Lamina Leasing And Finance Pvt Ltd", label: "Lamina Leasing And Finance Pvt Ltd" },
  { value: "Lamps", label: "Lamps" },
  { value: "Land Developement Bank", label: "Land Developement Bank" },
  { value: "Land Finance Ltd", label: "Land Finance Ltd" },
  { value: "Land T Finance Ltd", label: "Land T Finance Ltd" },
  { value: "Landmark  Financial Consultant", label: "Landmark  Financial Consultant" },
  { value: "Landmark Motors", label: "Landmark Motors" },
  { value: "Langpi Dehangi Rural Bank", label: "Langpi Dehangi Rural Bank" },
  { value: "Langpi Dehangi Rural Bank.", label: "Langpi Dehangi Rural Bank." },
  { value: "Larsen And Turbo", label: "Larsen And Turbo" },
  { value: "Lasalgaon Merchant Co Op Bank Ltd", label: "Lasalgaon Merchant Co Op Bank Ltd" },
  { value: "Lata Lunawat", label: "Lata Lunawat" },
  { value: "Latala Finance Co", label: "Latala Finance Co" },
  { value: "Late - S. S. Sonaje Pathsanstha Ltd", label: "Late - S. S. Sonaje Pathsanstha Ltd" },
  { value: "Late Ashokrao Bankar Nagari Sahakari Pathsanstha Maryadit", label: "Late Ashokrao Bankar Nagari Sahakari Pathsanstha Maryadit" },
  { value: "Late Ganpati Khamkar Gr Bigar Sheti Sah Path Mary Ajara", label: "Late Ganpati Khamkar Gr Bigar Sheti Sah Path Mary Ajara" },
  { value: "Late R G Rathi Nagri Sah Pat Santha", label: "Late R G Rathi Nagri Sah Pat Santha" },
  { value: "Late Tukaram Kayande Urban Co-Op Credit Soc Ltd", label: "Late Tukaram Kayande Urban Co-Op Credit Soc Ltd" },
  { value: "Late Venkatrao Patil Kawle Bigersheti Sah Path Sanstha Ltd Sindhi", label: "Late Venkatrao Patil Kawle Bigersheti Sah Path Sanstha Ltd Sindhi" },
  { value: "Latha Devi Ramadev Bharam", label: "Latha Devi Ramadev Bharam" },
  { value: "Latha Finance", label: "Latha Finance" },
  { value: "Latur Dist. Central Co-Op Bank Ltd,", label: "Latur Dist. Central Co-Op Bank Ltd," },
  { value: "Latur District Central Co-Operative Bank Ltd.", label: "Latur District Central Co-Operative Bank Ltd." },
  { value: "Latur Jilla Madhyavarthi Sahakari Bnk Lt", label: "Latur Jilla Madhyavarthi Sahakari Bnk Lt" },
  { value: "Latur Peoples Co-Op Bank Ltd", label: "Latur Peoples Co-Op Bank Ltd" },
  { value: "Latur Zila Madhyavatri Saha Bank Ltd.", label: "Latur Zila Madhyavatri Saha Bank Ltd." },
  { value: "Lavanya Auto Finance", label: "Lavanya Auto Finance" },
  { value: "Lavanya Enterprises", label: "Lavanya Enterprises" },
  { value: "Lavesh Singhvi", label: "Lavesh Singhvi" },
  { value: "Law Commission Of India", label: "Law Commission Of India" },
  { value: "Laxman Auto Delas", label: "Laxman Auto Delas" },
  { value: "Laxman Leafin Ltd.", label: "Laxman Leafin Ltd." },
  { value: "Laxman Singh Yadav", label: "Laxman Singh Yadav" },
  { value: "Laxmeshar Urban Co-Op Bank Ltd", label: "Laxmeshar Urban Co-Op Bank Ltd" },
  { value: "Laxmi  Sahakari Bank Niyamit", label: "Laxmi  Sahakari Bank Niyamit" },
  { value: "Laxmi & Company Balsad", label: "Laxmi & Company Balsad" },
  { value: "Laxmi Agarwal", label: "Laxmi Agarwal" },
  { value: "Laxmi Associates", label: "Laxmi Associates" },
  { value: "Laxmi Auto Finance", label: "Laxmi Auto Finance" },
  { value: "Laxmi Auto Finance", label: "Laxmi Auto Finance" },
  { value: "Laxmi Bachat Sharafi Sahakari Mandali Ltd.", label: "Laxmi Bachat Sharafi Sahakari Mandali Ltd." },
  { value: "Laxmi Baug Sah Pat", label: "Laxmi Baug Sah Pat" },
  { value: "Laxmi Co-Op- Sev & Cr Soc Ltd", label: "Laxmi Co-Op- Sev & Cr Soc Ltd" },
  { value: "Laxmi Corporation", label: "Laxmi Corporation" },
  { value: "Laxmi Devi Finance Ltd", label: "Laxmi Devi Finance Ltd" },
  { value: "Laxmi Finance", label: "Laxmi Finance" },
  { value: "Laxmi Finance Co.", label: "Laxmi Finance Co." },
  { value: "Laxmi Financer", label: "Laxmi Financer" },
  { value: "Laxmi Financial Associates", label: "Laxmi Financial Associates" },
  { value: "Laxmi Finlease", label: "Laxmi Finlease" },
  { value: "Laxmi Ganapathi Auto Finance", label: "Laxmi Ganapathi Auto Finance" },
  { value: "Laxmi Ganesh Finance", label: "Laxmi Ganesh Finance" },
  { value: "Laxmi General Finance Ltd", label: "Laxmi General Finance Ltd" },
  { value: "Laxmi Gramin Bigar Sheti Patsanstha", label: "Laxmi Gramin Bigar Sheti Patsanstha" },
  { value: "Laxmi Investment", label: "Laxmi Investment" },
  { value: "Laxmi Investors", label: "Laxmi Investors" },
  { value: "Laxmi Leasing & Finance", label: "Laxmi Leasing & Finance" },
  { value: "Laxmi Leasing Finance", label: "Laxmi Leasing Finance" },
  { value: "Laxmi Mahila Nagarik Sahakari Bank Maryadit", label: "Laxmi Mahila Nagarik Sahakari Bank Maryadit" },
  { value: "Laxmi Mahila Nagrik Sahakari Bank Mydt", label: "Laxmi Mahila Nagrik Sahakari Bank Mydt" },
  { value: "Laxmi Motors", label: "Laxmi Motors" },
  { value: "Laxmi Narasimha Swamy Automobile Financiers", label: "Laxmi Narasimha Swamy Automobile Financiers" },
  { value: "Laxmi Nivas Hire Purchase", label: "Laxmi Nivas Hire Purchase" },
  { value: "Laxmi Prasanna Automboile Finance", label: "Laxmi Prasanna Automboile Finance" },
  { value: "Laxmi Sai Finance", label: "Laxmi Sai Finance" },
  { value: "Laxmi Sai Hp And Finance", label: "Laxmi Sai Hp And Finance" },
  { value: "Laxmi Srinivas Finance", label: "Laxmi Srinivas Finance" },
  { value: "Laxmi Srinivasa Sai Auto Finance", label: "Laxmi Srinivasa Sai Auto Finance" },
  { value: "Laxmi Trading Company", label: "Laxmi Trading Company" },
  { value: "Laxmi Urban Co-Operative Bank Ltd", label: "Laxmi Urban Co-Operative Bank Ltd" },
  { value: "Laxmi Urban Credit Co-Op Bank Ltd", label: "Laxmi Urban Credit Co-Op Bank Ltd" },
  { value: "Laxmi Venkata Sai Finance", label: "Laxmi Venkata Sai Finance" },
  { value: "Laxmi Venkateswara Auto Finance", label: "Laxmi Venkateswara Auto Finance" },
  { value: "Laxmi Venketshwara Motors", label: "Laxmi Venketshwara Motors" },
  { value: "Laxmi Vishnu Sahakari Bank Ltd", label: "Laxmi Vishnu Sahakari Bank Ltd" },
  { value: "Laxmi Vishnu Sahakari Bank Ltd", label: "Laxmi Vishnu Sahakari Bank Ltd" },
  { value: "Laxmibai Mahila Nagrik Sahakari Bank Ltd", label: "Laxmibai Mahila Nagrik Sahakari Bank Ltd" },
  { value: "Laxmidevi Gramin Bigar Sheti", label: "Laxmidevi Gramin Bigar Sheti" },
  { value: "Laxmidhaiwadi Gramin Bigar Sheti Sahakari Pathsanstha", label: "Laxmidhaiwadi Gramin Bigar Sheti Sahakari Pathsanstha" },
  { value: "Laxminarayan Gramin Biger Sheti Sahakari Pathsanshtha.", label: "Laxminarayan Gramin Biger Sheti Sahakari Pathsanshtha." },
  { value: "Laxminarayan Sakh Shkarita Maryadit", label: "Laxminarayan Sakh Shkarita Maryadit" },
  { value: "Laxminarayan Urben Co Op Cr Soc Ltd,Surat", label: "Laxminarayan Urben Co Op Cr Soc Ltd,Surat" },
  { value: "Laxminivas Finance", label: "Laxminivas Finance" },
  { value: "Laxmivenkateshwara Finance", label: "Laxmivenkateshwara Finance" },
  { value: "Laxmivishnu Nagarik Sahakari Bank Ltd", label: "Laxmivishnu Nagarik Sahakari Bank Ltd" },
  { value: "Laxshmi Srinivasa Auto Finance", label: "Laxshmi Srinivasa Auto Finance" },
  { value: "Lct Finance Ltd", label: "Lct Finance Ltd" },
  { value: "Ldb", label: "Ldb" },
  { value: "Ldb", label: "Ldb" },
  { value: "Le Travel World Pvt Ltd", label: "Le Travel World Pvt Ltd" },
  { value: "Lease Agreement", label: "Lease Agreement" },
  { value: "Lease Agreement Tranzlease Holdings I Pvt Ltd", label: "Lease Agreement Tranzlease Holdings I Pvt Ltd" },
  { value: "Lease Loan Hp Agreement With Srei Equip Fin Pvt Ltd And Consortium Of Bankers With Uco Bank As Leader", label: "Lease Loan Hp Agreement With Srei Equip Fin Pvt Ltd And Consortium Of Bankers With Uco Bank As Leader" },
  { value: "Lease Plan", label: "Lease Plan" },
  { value: "Lease Plan India Pvt Ltd", label: "Lease Plan India Pvt Ltd" },
  { value: "Lee Na Investment", label: "Lee Na Investment" },
  { value: "Leela Bhaskar Auto Consultants", label: "Leela Bhaskar Auto Consultants" },
  { value: "Leela Bhaskar Auto Consultants & Finance", label: "Leela Bhaskar Auto Consultants & Finance" },
  { value: "Leela Krishna", label: "Leela Krishna" },
  { value: "Leela Sri Krishna Finance & Chits", label: "Leela Sri Krishna Finance & Chits" },
  { value: "Leelam Auto Finance", label: "Leelam Auto Finance" },
  { value: "Leena Investment", label: "Leena Investment" },
  { value: "Leenahanish", label: "Leenahanish" },
  { value: "Legal Services Authority", label: "Legal Services Authority" },
  { value: "Legend Travels Pvt Ltd", label: "Legend Travels Pvt Ltd" },
  { value: "Lekha", label: "Lekha" },
  { value: "Lenyadri Sah Pat. Maryadit", label: "Lenyadri Sah Pat. Maryadit" },
  { value: "Lenyadri Sahakari Pat Ltd.", label: "Lenyadri Sahakari Pat Ltd." },
  { value: "Leo Motors", label: "Leo Motors" },
  { value: "Lessor  L & T Fin Ltd", label: "Lessor  L & T Fin Ltd" },
  { value: "Lessor Ald Automotive Pvt Ltd", label: "Lessor Ald Automotive Pvt Ltd" },
  { value: "Lessor Ge Capital Tpt Finance", label: "Lessor Ge Capital Tpt Finance" },
  { value: "Lessor Ge Money Financial Services Ltd", label: "Lessor Ge Money Financial Services Ltd" },
  { value: "Lessor Infrastructure Leasing And Financial Services Ltd", label: "Lessor Infrastructure Leasing And Financial Services Ltd" },
  { value: "Lessor Lease Plan India Ltd", label: "Lessor Lease Plan India Ltd" },
  { value: "Lessor Orix Auto And Business", label: "Lessor Orix Auto And Business" },
  { value: "Lessor Orix Auto Infrs.", label: "Lessor Orix Auto Infrs." },
  { value: "Lessor Orixawo Infrastructure Service Ltd.", label: "Lessor Orixawo Infrastructure Service Ltd." },
  { value: "Lessor Sundaram Finance Ltd", label: "Lessor Sundaram Finance Ltd" },
  { value: "Lexicon Commercial Enterprises Ltd", label: "Lexicon Commercial Enterprises Ltd" },
  { value: "Lexicon Finance Ltd", label: "Lexicon Finance Ltd" },
  { value: "Lfc Hire Purchase Company Limited", label: "Lfc Hire Purchase Company Limited" },
  { value: "Lfc Hire Purchase Company Pvt.Ltd.", label: "Lfc Hire Purchase Company Pvt.Ltd." },
  { value: "Lhasha", label: "Lhasha" },
  { value: "Liberal Co-Op Bank Ltd", label: "Liberal Co-Op Bank Ltd" },
  { value: "Libra Auto And General Finance Ltd", label: "Libra Auto And General Finance Ltd" },
  { value: "Libra Finance Pvt. Ltd.", label: "Libra Finance Pvt. Ltd." },
  { value: "Libra Leasing Ltd.", label: "Libra Leasing Ltd." },
  { value: "Libra Transport Finance Company", label: "Libra Transport Finance Company" },
  { value: "Lic Cbo I", label: "Lic Cbo I" },
  { value: "Lic Finance", label: "Lic Finance" },
  { value: "Lic Housing Finance Ltd.", label: "Lic Housing Finance Ltd." },
  { value: "Lic Of  India", label: "Lic Of  India" },
  { value: "Lic Of India Staff Co-Op Babk Ltd", label: "Lic Of India Staff Co-Op Babk Ltd" },
  { value: "Lie India Corporation", label: "Lie India Corporation" },
  { value: "Life Gain Multi Trade", label: "Life Gain Multi Trade" },
  { value: "Life Insurance Corporation Ltd", label: "Life Insurance Corporation Ltd" },
  { value: "Life Insurance Corporation Of India", label: "Life Insurance Corporation Of India" },
  { value: "Life Insurance Corporation Of India", label: "Life Insurance Corporation Of India" },
  { value: "Life Rising Finance Co-Op Ltd.", label: "Life Rising Finance Co-Op Ltd." },
  { value: "Lijo George", label: "Lijo George" },
  { value: "Liluah Co-Op Bank Ltd.", label: "Liluah Co-Op Bank Ltd." },
  { value: "Liluah Co-Op. Bank Ltd", label: "Liluah Co-Op. Bank Ltd" },
  { value: "Limbi Urban Co-Op Bank Ltd", label: "Limbi Urban Co-Op Bank Ltd" },
  { value: "Limdi Urban Co-Operative Bank Ltd", label: "Limdi Urban Co-Operative Bank Ltd" },
  { value: "Lingam Insurance Services", label: "Lingam Insurance Services" },
  { value: "Lingavant Kayak Sajeevini Pattin Sangh Niyamit", label: "Lingavant Kayak Sajeevini Pattin Sangh Niyamit" },
  { value: "Lingawanta Kayaka Sahakari Niyamitha Bank Ltd Ilkal", label: "Lingawanta Kayaka Sahakari Niyamitha Bank Ltd Ilkal" },
  { value: "Lingawanth Kayaka Sanjeevani Co-Operative Society", label: "Lingawanth Kayaka Sanjeevani Co-Operative Society" },
  { value: "Lingeshwar Gramin Bigar Sheti Sahkari Pat Sansta Ltd", label: "Lingeshwar Gramin Bigar Sheti Sahkari Pat Sansta Ltd" },
  { value: "Lion Enterprises", label: "Lion Enterprises" },
  { value: "Lion Financial Services Ltd.", label: "Lion Financial Services Ltd." },
  { value: "Lionel India Ltd.", label: "Lionel India Ltd." },
  { value: "Lipi Finstock Ltd", label: "Lipi Finstock Ltd" },
  { value: "Liya Finance Service", label: "Liya Finance Service" },
  { value: "Lkg Bank", label: "Lkg Bank" },
  { value: "Lkgb Dhaurahra Lakhimpur Kheri", label: "Lkgb Dhaurahra Lakhimpur Kheri" },
  { value: "Lmb Ins. Brokers Pvt. Ltd.", label: "Lmb Ins. Brokers Pvt. Ltd." },
  { value: "Lmt Financier Services Ltd", label: "Lmt Financier Services Ltd" },
  { value: "Lmt Prayag Udyog Ltd", label: "Lmt Prayag Udyog Ltd" },
  { value: "Lmuthukrishnan", label: "Lmuthukrishnan" },
  { value: "Ln Finance", label: "Ln Finance" },
  { value: "Lns Tejasri Finance", label: "Lns Tejasri Finance" },
  { value: "Loan Advances Section", label: "Loan Advances Section" },
  { value: "Loco Works Sourthern Railway", label: "Loco Works Sourthern Railway" },
  { value: "Lodhi Motors", label: "Lodhi Motors" },
  { value: "Lodhika Seva Sahakari Mandali", label: "Lodhika Seva Sahakari Mandali" },
  { value: "Lodra Nagarik Sahakari Bank", label: "Lodra Nagarik Sahakari Bank" },
  { value: "Loha Urban Co Op Bank Ltd", label: "Loha Urban Co Op Bank Ltd" },
  { value: "Loha Urban Co.Op. Bank Ltd.", label: "Loha Urban Co.Op. Bank Ltd." },
  { value: "Lohagaon Nagari Sahakari Path Sanstha", label: "Lohagaon Nagari Sahakari Path Sanstha" },
  { value: "Lohandi Primary Agricul.Credit Co-Op B.L", label: "Lohandi Primary Agricul.Credit Co-Op B.L" },
  { value: "Lohia Finance Ltd", label: "Lohia Finance Ltd" },
  { value: "Lohia Leasing & Finance Co.", label: "Lohia Leasing & Finance Co." },
  { value: "Lohiya Tractor Saundad", label: "Lohiya Tractor Saundad" },
  { value: "Lok Vikas Gbssps Marya Br", label: "Lok Vikas Gbssps Marya Br" },
  { value: "Lok Vilas Nagarik Sahakari Bank Ltd", label: "Lok Vilas Nagarik Sahakari Bank Ltd" },
  { value: "Lokamanya Credit Co-Op Society Limited", label: "Lokamanya Credit Co-Op Society Limited" },
  { value: "Lokapavani Mahila Sahakari Bk Niyamitha", label: "Lokapavani Mahila Sahakari Bk Niyamitha" },
  { value: "Lokapriya Leasing Ltd.", label: "Lokapriya Leasing Ltd." },
  { value: "Lokesh Auto Finance", label: "Lokesh Auto Finance" },
  { value: "Lokesh Auto Finance", label: "Lokesh Auto Finance" },
  { value: "Lokesh Hire Purchase Ltd", label: "Lokesh Hire Purchase Ltd" },
  { value: "Lokesh Singh Finance", label: "Lokesh Singh Finance" },
  { value: "Lokeswara Auto Finance", label: "Lokeswara Auto Finance" },
  { value: "Lokhit Urban Co-Op Credit Society", label: "Lokhit Urban Co-Op Credit Society" },
  { value: "Lokhith Urban Co-Op. Credit Society Ltd.", label: "Lokhith Urban Co-Op. Credit Society Ltd." },
  { value: "Lokkalyan Urban Co Bk L,", label: "Lokkalyan Urban Co Bk L," },
  { value: "Lokmangal Bank", label: "Lokmangal Bank" },
  { value: "Lokmangal Co-Op  Bank Ltd  Solapur", label: "Lokmangal Co-Op  Bank Ltd  Solapur" },
  { value: "Lokmangal Co-Op. Bank Ltd.", label: "Lokmangal Co-Op. Bank Ltd." },
  { value: "Lokmangal Multistate Co Op Society", label: "Lokmangal Multistate Co Op Society" },
  { value: "Lokmangal Nagari Sah Path Sanstha", label: "Lokmangal Nagari Sah Path Sanstha" },
  { value: "Lokmangal Urban Co Op Bank Ltd", label: "Lokmangal Urban Co Op Bank Ltd" },
  { value: "Lokmangal Urban Co.Op. Bank Ltd", label: "Lokmangal Urban Co.Op. Bank Ltd" },
  { value: "Lokmanya Co Op Credit Society", label: "Lokmanya Co Op Credit Society" },
  { value: "Lokmanya Co-Op Credit Soc. Ltd, Belgam", label: "Lokmanya Co-Op Credit Soc. Ltd, Belgam" },
  { value: "Lokmanya Gramin Bigar Sheti Patsanstha", label: "Lokmanya Gramin Bigar Sheti Patsanstha" },
  { value: "Lokmanya Multi Purpose Co-Op Soc Ltd", label: "Lokmanya Multi Purpose Co-Op Soc Ltd" },
  { value: "Lokmanya Nagari Sah. Patsanstha Mydt.", label: "Lokmanya Nagari Sah. Patsanstha Mydt." },
  { value: "Lokmanya Nagari Sahahakari Patsanstha", label: "Lokmanya Nagari Sahahakari Patsanstha" },
  { value: "Lokneta Dattaji Patil Sahakari Bank Ltd.", label: "Lokneta Dattaji Patil Sahakari Bank Ltd." },
  { value: "Loknete  Dattaji  Patil  Sah. Bank  Ltd", label: "Loknete  Dattaji  Patil  Sah. Bank  Ltd" },
  { value: "Loknete Dr J B Pawar Gramin Bigar Sheti Sah Path Maryadit", label: "Loknete Dr J B Pawar Gramin Bigar Sheti Sah Path Maryadit" },
  { value: "Loknete Dr J D Pawar Sah Pathsanstha Maryadit", label: "Loknete Dr J D Pawar Sah Pathsanstha Maryadit" },
  { value: "Loknete Kashinath Nivrutti Bombale Gramin Big Sheti Sahakari Pathsanstha Maryadit", label: "Loknete Kashinath Nivrutti Bombale Gramin Big Sheti Sahakari Pathsanstha Maryadit" },
  { value: "Loknete Shankarrao Balaj Waje Br Sinnar", label: "Loknete Shankarrao Balaj Waje Br Sinnar" },
  { value: "Loknete Shankarrao Balaji Waje Gramin Bigar Sheti Sahakari Pathsanstha Maryadit", label: "Loknete Shankarrao Balaji Waje Gramin Bigar Sheti Sahakari Pathsanstha Maryadit" },
  { value: "Loknete Shankarrao Balaji Waje Nagri Sahakari Patsanstha Maryadit, Dubere", label: "Loknete Shankarrao Balaji Waje Nagri Sahakari Patsanstha Maryadit, Dubere" },
  { value: "Loknete Waje Sah Patbanitha", label: "Loknete Waje Sah Patbanitha" },
  { value: "Lokseva Sah Bank", label: "Lokseva Sah Bank" },
  { value: "Loksewa Sahakari Pat Ltd", label: "Loksewa Sahakari Pat Ltd" },
  { value: "Lokshahir Annabhau Sathe Devp. Corp.", label: "Lokshahir Annabhau Sathe Devp. Corp." },
  { value: "Lokshahir Annabhau Sathe Vikas Mahamandal Ltd", label: "Lokshahir Annabhau Sathe Vikas Mahamandal Ltd" },
  { value: "Lokvikas Nagari Sahakari Bank Ltd.", label: "Lokvikas Nagari Sahakari Bank Ltd." },
  { value: "Lola Urban Co-Op Bank Ltd", label: "Lola Urban Co-Op Bank Ltd" },
  { value: "Lonavala Sahakari Bank Ltd.", label: "Lonavala Sahakari Bank Ltd." },
  { value: "Lonavala Sahakari Bank Maryadit", label: "Lonavala Sahakari Bank Maryadit" },
  { value: "Lonavala Sahakari Bank Maryadit", label: "Lonavala Sahakari Bank Maryadit" },
  { value: "Longpi Dehangi", label: "Longpi Dehangi" },
  { value: "Lord Balaji Finance", label: "Lord Balaji Finance" },
  { value: "Lord Fincap", label: "Lord Fincap" },
  { value: "Lord Krishna Bank Ltd.", label: "Lord Krishna Bank Ltd." },
  { value: "Lord Krishna Finance Co", label: "Lord Krishna Finance Co" },
  { value: "Lord Shiva Tex Exports Pvt Ltd", label: "Lord Shiva Tex Exports Pvt Ltd" },
  { value: "Lorry Owners & Suppliers V Co-Op Soc Ny", label: "Lorry Owners & Suppliers V Co-Op Soc Ny" },
  { value: "Lorry Owners And Suppliers Multipurpose Co-Operative Society Ltd Gadag", label: "Lorry Owners And Suppliers Multipurpose Co-Operative Society Ltd Gadag" },
  { value: "Lotus Capital", label: "Lotus Capital" },
  { value: "Lotus Credit Pvt. Ltd.", label: "Lotus Credit Pvt. Ltd." },
  { value: "Lotus Motor Service", label: "Lotus Motor Service" },
  { value: "Loyal Auto Investments", label: "Loyal Auto Investments" },
  { value: "Loyal Finance", label: "Loyal Finance" },
  { value: "Loyal Hire Purchase Co", label: "Loyal Hire Purchase Co" },
  { value: "Loyal Insurance Services Pvt Ltd", label: "Loyal Insurance Services Pvt Ltd" },
  { value: "Lrt Finance Limited", label: "Lrt Finance Limited" },
  { value: "Lscs Finance", label: "Lscs Finance" },
  { value: "Lsp Finance Ltd.", label: "Lsp Finance Ltd." },
  { value: "Ltml Financial Services Ltd", label: "Ltml Financial Services Ltd" },
  { value: "Lubrizol India Ltd.", label: "Lubrizol India Ltd." },
  { value: "Lucas Indian Services Ltd.", label: "Lucas Indian Services Ltd." },
  { value: "Luckno Urban Co-Op Bank Ltd.", label: "Luckno Urban Co-Op Bank Ltd." },
  { value: "Lucknow Kehetriya Gramin Bank", label: "Lucknow Kehetriya Gramin Bank" },
  { value: "Lucknow Kshetriya Bank,", label: "Lucknow Kshetriya Bank," },
  { value: "Lucky Door Hire Purchase Finance Pvt Ltd.", label: "Lucky Door Hire Purchase Finance Pvt Ltd." },
  { value: "Lucky Investments", label: "Lucky Investments" },
  { value: "Lucky Investments Finance Ltd.", label: "Lucky Investments Finance Ltd." },
  { value: "Ludhiana Central Co-Op. Bank Ltd., Ludhiana", label: "Ludhiana Central Co-Op. Bank Ltd., Ludhiana" },
  { value: "Lunawada Nagriik Sahkari Bank", label: "Lunawada Nagriik Sahkari Bank" },
  { value: "Lunawada Peoples Co-Op Bank Ltd", label: "Lunawada Peoples Co-Op Bank Ltd" },
  { value: "Lunawat Auto Finance Ltd", label: "Lunawat Auto Finance Ltd" },
  { value: "Luxmi Financers", label: "Luxmi Financers" },
  { value: "Luxmi Financial Associates", label: "Luxmi Financial Associates" },
  { value: "Lyca Finance Limited", label: "Lyca Finance Limited" },
  { value: "M & M Finance Co.Ltd", label: "M & M Finance Co.Ltd" },
  { value: "M & M Finance Services Bank", label: "M & M Finance Services Bank" },
  { value: "M & M Financial Services", label: "M & M Financial Services" },
  { value: "M & M Financil Services Ltd, Belgaum", label: "M & M Financil Services Ltd, Belgaum" },
  { value: "M A Shaikhsanadi", label: "M A Shaikhsanadi" },
  { value: "M A Trading And Fin Pvt Ltd", label: "M A Trading And Fin Pvt Ltd" },
  { value: "M Anand Sancheti", label: "M Anand Sancheti" },
  { value: "M B K Gramin Bank", label: "M B K Gramin Bank" },
  { value: "M C Finance & Investment", label: "M C Finance & Investment" },
  { value: "M D Investements", label: "M D Investements" },
  { value: "M F S L", label: "M F S L" },
  { value: "M G B Gramin Bank", label: "M G B Gramin Bank" },
  { value: "M G Finance", label: "M G Finance" },
  { value: "M G Hussain & Sons", label: "M G Hussain & Sons" },
  { value: "M G M Finance", label: "M G M Finance" },
  { value: "M G Srinivasan", label: "M G Srinivasan" },
  { value: "M Gajendra Finance", label: "M Gajendra Finance" },
  { value: "M Gajraj Jain Automobile Finance", label: "M Gajraj Jain Automobile Finance" },
  { value: "M J Cargo Finance", label: "M J Cargo Finance" },
  { value: "M Jaychand Financier", label: "M Jaychand Financier" },
  { value: "M Jeetu Kumar", label: "M Jeetu Kumar" },
  { value: "M K Auto Finance", label: "M K Auto Finance" },
  { value: "M K Finance And Investment Co.", label: "M K Finance And Investment Co." },
  { value: "M K Finance Corporation", label: "M K Finance Corporation" },
  { value: "M K Investments", label: "M K Investments" },
  { value: "M K M Investments", label: "M K M Investments" },
  { value: "M Kamal Chand Kothari", label: "M Kamal Chand Kothari" },
  { value: "M Kamal Chand Kothari", label: "M Kamal Chand Kothari" },
  { value: "M Kamlesh Chand Choradia", label: "M Kamlesh Chand Choradia" },
  { value: "M Kamlesh Chand Choradiya", label: "M Kamlesh Chand Choradiya" },
  { value: "M Kamleshchand Chordia", label: "M Kamleshchand Chordia" },
  { value: "M L Charodia And Sons", label: "M L Charodia And Sons" },
  { value: "M L Financial Services", label: "M L Financial Services" },
  { value: "M L Soni & Sons", label: "M L Soni & Sons" },
  { value: "M L Soni & Sons", label: "M L Soni & Sons" },
  { value: "M M E S Ltd", label: "M M E S Ltd" },
  { value: "M M F S L", label: "M M F S L" },
  { value: "M M Finance", label: "M M Finance" },
  { value: "M M Investments", label: "M M Investments" },
  { value: "M M T C Co Ltd", label: "M M T C Co Ltd" },
  { value: "M Mahindra Kumar Kothari", label: "M Mahindra Kumar Kothari" },
  { value: "M Maina Bai Finance", label: "M Maina Bai Finance" },
  { value: "M Misrilal Auto Financier", label: "M Misrilal Auto Financier" },
  { value: "M N Narendira Kumar Finance", label: "M N Narendira Kumar Finance" },
  { value: "M P Adiwasi Vitt", label: "M P Adiwasi Vitt" },
  { value: "M P Enterprise", label: "M P Enterprise" },
  { value: "M P Ponnusamy And Co", label: "M P Ponnusamy And Co" },
  { value: "M P Portfolio", label: "M P Portfolio" },
  { value: "M P R Finance Ltd", label: "M P R Finance Ltd" },
  { value: "M P Rajya Sahakari Bank", label: "M P Rajya Sahakari Bank" },
  { value: "M P Sanghavi Investment Pvt Ltd", label: "M P Sanghavi Investment Pvt Ltd" },
  { value: "M Padam Chand Zalwani Financier", label: "M Padam Chand Zalwani Financier" },
  { value: "M Parashmall Pagal", label: "M Parashmall Pagal" },
  { value: "M Parasmal Gothi", label: "M Parasmal Gothi" },
  { value: "M Prakash Chand Financier", label: "M Prakash Chand Financier" },
  { value: "M Pushpadevi Choradia", label: "M Pushpadevi Choradia" },
  { value: "M Puspa Devi Choradiya", label: "M Puspa Devi Choradiya" },
  { value: "M R Seshagiri Rao L A Financiers", label: "M R Seshagiri Rao L A Financiers" },
  { value: "M Rajkumari Finance", label: "M Rajkumari Finance" },
  { value: "M Rekha Finance", label: "M Rekha Finance" },
  { value: "M Rekhraj Jain", label: "M Rekhraj Jain" },
  { value: "M Rohit Jain", label: "M Rohit Jain" },
  { value: "M S Auto Finance", label: "M S Auto Finance" },
  { value: "M S B N Ltd", label: "M S B N Ltd" },
  { value: "M S Finance", label: "M S Finance" },
  { value: "M S Suresh Kumar Surana", label: "M S Suresh Kumar Surana" },
  { value: "M Sanjay Kumar Singhvi", label: "M Sanjay Kumar Singhvi" },
  { value: "M Sardar Mal Nahar", label: "M Sardar Mal Nahar" },
  { value: "M Surish", label: "M Surish" },
  { value: "M Thrishilla Devi", label: "M Thrishilla Devi" },
  { value: "M Thrishla Devi", label: "M Thrishla Devi" },
  { value: "M Vimal Kochar", label: "M Vimal Kochar" },
  { value: "M&M Financial Services Ltd", label: "M&M Financial Services Ltd" },
  { value: "M. A. Trading & Financing Pvt Ltd", label: "M. A. Trading & Financing Pvt Ltd" },
  { value: "M. D. Motor Finance", label: "M. D. Motor Finance" },
  { value: "M. D. Pawar Peoples Co-Op Bank Ltd.", label: "M. D. Pawar Peoples Co-Op Bank Ltd." },
  { value: "M. P. Raiya Sahakari Bank Maryadit.", label: "M. P. Raiya Sahakari Bank Maryadit." },
  { value: "M. P. Rajya Sahakari Bank Maryadit", label: "M. P. Rajya Sahakari Bank Maryadit" },
  { value: "M. Pushpadevi Chouradia", label: "M. Pushpadevi Chouradia" },
  { value: "M. Rajendra Kumar Financier", label: "M. Rajendra Kumar Financier" },
  { value: "M. Rekha", label: "M. Rekha" },
  { value: "M. S. Co-Op. Bank Ltd", label: "M. S. Co-Op. Bank Ltd" },
  { value: "M. Santosh Kumar", label: "M. Santosh Kumar" },
  { value: "M. Sathi Reddy", label: "M. Sathi Reddy" },
  { value: "M. Trishala Devi", label: "M. Trishala Devi" },
  { value: "M. Venugopala Rao", label: "M. Venugopala Rao" },
  { value: "M.A.F.L", label: "M.A.F.L" },
  { value: "M.Anand Kumar", label: "M.Anand Kumar" },
  { value: "M.B Association", label: "M.B Association" },
  { value: "M.B.Finleas", label: "M.B.Finleas" },
  { value: "M.C.A.F.S. Ltd.", label: "M.C.A.F.S. Ltd." },
  { value: "M.C.Jain", label: "M.C.Jain" },
  { value: "M.C.W. Auto Finance", label: "M.C.W. Auto Finance" },
  { value: "M.Chandrasekar", label: "M.Chandrasekar" },
  { value: "M.D.C.C Bank", label: "M.D.C.C Bank" },
  { value: "M.Dilip", label: "M.Dilip" },
  { value: "M.G. Bank", label: "M.G. Bank" },
  { value: "M.G. Hussain & Sons", label: "M.G. Hussain & Sons" },
  { value: "M.G. Nahar Trading Co", label: "M.G. Nahar Trading Co" },
  { value: "M.G.Bank", label: "M.G.Bank" },
  { value: "M.G.F. (India) Limited", label: "M.G.F. (India) Limited" },
  { value: "M.G.F. Services Limited", label: "M.G.F. Services Limited" },
  { value: "M.Gajendran", label: "M.Gajendran" },
  { value: "M.Govind Raj", label: "M.Govind Raj" },
  { value: "M.H. Bafna & Co", label: "M.H. Bafna & Co" },
  { value: "M.I.F.Ltd", label: "M.I.F.Ltd" },
  { value: "M.K.Finance", label: "M.K.Finance" },
  { value: "M.K.International", label: "M.K.International" },
  { value: "M.L. Sony And Sons", label: "M.L. Sony And Sons" },
  { value: "M.M Auto Finance", label: "M.M Auto Finance" },
  { value: "M.M Finance", label: "M.M Finance" },
  { value: "M.M. Associates", label: "M.M. Associates" },
  { value: "M.M. Auto Finance Co", label: "M.M. Auto Finance Co" },
  { value: "M.M. Fince Ltd Agra", label: "M.M. Fince Ltd Agra" },
  { value: "M.M.T.C. Ltd.", label: "M.M.T.C. Ltd." },
  { value: "M.Mahendrakumar", label: "M.Mahendrakumar" },
  { value: "M.Mohamed Ali Naina", label: "M.Mohamed Ali Naina" },
  { value: "M.N.S.B. Ltd,Kailash Nagar", label: "M.N.S.B. Ltd,Kailash Nagar" },
  { value: "M.P. Enterprise", label: "M.P. Enterprise" },
  { value: "M.P. St. Finance & Development Corporation", label: "M.P. St. Finance & Development Corporation" },
  { value: "M.P.Rajya Sah.Bank Ltd., Indore", label: "M.P.Rajya Sah.Bank Ltd., Indore" },
  { value: "M.R.C. Financial Ltd", label: "M.R.C. Financial Ltd" },
  { value: "M.Roopchand Sisodia Financier", label: "M.Roopchand Sisodia Financier" },
  { value: "M.S. Co Op Bank Ltd", label: "M.S. Co Op Bank Ltd" },
  { value: "M.Sugumar", label: "M.Sugumar" },
  { value: "M.Thrishladevi", label: "M.Thrishladevi" },
  { value: "M.Utham Chand Surana", label: "M.Utham Chand Surana" },
  { value: "M.Wedha Urban Co.Op Bank", label: "M.Wedha Urban Co.Op Bank" },
  { value: "M/S Agrim Finance", label: "M/S Agrim Finance" },
  { value: "M/S Archana Investments", label: "M/S Archana Investments" },
  { value: "M/S Ashish Financer & Boi Ranchi", label: "M/S Ashish Financer & Boi Ranchi" },
  { value: "M/S Banka Commercial Corporation", label: "M/S Banka Commercial Corporation" },
  { value: "M/S Brehmavara Agri Service Co-Op Bank Ltd", label: "M/S Brehmavara Agri Service Co-Op Bank Ltd" },
  { value: "M/S Chavan Financial Services.", label: "M/S Chavan Financial Services." },
  { value: "M/S Dayal Finlease Ltd. - Gurgaon", label: "M/S Dayal Finlease Ltd. - Gurgaon" },
  { value: "M/S Dinesh Finance", label: "M/S Dinesh Finance" },
  { value: "M/S Gajanand Finance And Investment Pvt Ltd", label: "M/S Gajanand Finance And Investment Pvt Ltd" },
  { value: "M/S Hari Krishna H.P Fin", label: "M/S Hari Krishna H.P Fin" },
  { value: "M/S Harita Finance Ltd", label: "M/S Harita Finance Ltd" },
  { value: "M/S Himachal Mitra Mandal", label: "M/S Himachal Mitra Mandal" },
  { value: "M/S Hind Electrical & General Industries Ltd", label: "M/S Hind Electrical & General Industries Ltd" },
  { value: "M/S J H Fianance Corp", label: "M/S J H Fianance Corp" },
  { value: "M/S Jayshree Ram Finance Madras", label: "M/S Jayshree Ram Finance Madras" },
  { value: "M/S Jineshwar Auto Finance", label: "M/S Jineshwar Auto Finance" },
  { value: "M/S Jogeshwar Leasing (R)", label: "M/S Jogeshwar Leasing (R)" },
  { value: "M/S K R And Co", label: "M/S K R And Co" },
  { value: "M/S K R And Co Financers & Money Lenders", label: "M/S K R And Co Financers & Money Lenders" },
  { value: "M/S K.R. & Co.", label: "M/S K.R. & Co." },
  { value: "M/S Kadadi Auto Finance And Leasing", label: "M/S Kadadi Auto Finance And Leasing" },
  { value: "M/S Kanyka  Auto Finance Ltd", label: "M/S Kanyka  Auto Finance Ltd" },
  { value: "M/S Kranti Motors & Leasing Basavakalyan", label: "M/S Kranti Motors & Leasing Basavakalyan" },
  { value: "M/S Kranti Motors And Leasing", label: "M/S Kranti Motors And Leasing" },
  { value: "M/S Lakshmi Investors", label: "M/S Lakshmi Investors" },
  { value: "M/S Lakshmi Narasimma Auto Finance", label: "M/S Lakshmi Narasimma Auto Finance" },
  { value: "M/S N S Jain", label: "M/S N S Jain" },
  { value: "M/S Nandi Leasing", label: "M/S Nandi Leasing" },
  { value: "M/S Nandi Leasing", label: "M/S Nandi Leasing" },
  { value: "M/S Nandi Leasing", label: "M/S Nandi Leasing" },
  { value: "M/S Nandi Leasing", label: "M/S Nandi Leasing" },
  { value: "M/S Narbheram Finance Co Ltd", label: "M/S Narbheram Finance Co Ltd" },
  { value: "M/S Navpad Investments", label: "M/S Navpad Investments" },
  { value: "M/S Ongc Ltd Cauvery Asset Karaikal", label: "M/S Ongc Ltd Cauvery Asset Karaikal" },
  { value: "M/S Ongl Ltd-Cauvery Asset Karaikal", label: "M/S Ongl Ltd-Cauvery Asset Karaikal" },
  { value: "M/S Pawan Putra Group", label: "M/S Pawan Putra Group" },
  { value: "M/S Prabu Traders", label: "M/S Prabu Traders" },
  { value: "M/S Pragati Agencies", label: "M/S Pragati Agencies" },
  { value: "M/S R.C.M Finance And Leasing Pvt. Ltd.  Delhi", label: "M/S R.C.M Finance And Leasing Pvt. Ltd.  Delhi" },
  { value: "M/S Ramesh Chand Kothari", label: "M/S Ramesh Chand Kothari" },
  { value: "M/S Real Appreciation Finance(P) Ltd.", label: "M/S Real Appreciation Finance(P) Ltd." },
  { value: "M/S Regal Finance & Inv Co.", label: "M/S Regal Finance & Inv Co." },
  { value: "M/S S.B.I Boileauganj", label: "M/S S.B.I Boileauganj" },
  { value: "M/S Safe Finlease Pvt Ltd", label: "M/S Safe Finlease Pvt Ltd" },
  { value: "M/S Sampada Leasing", label: "M/S Sampada Leasing" },
  { value: "M/S Seetha Raghava Souharda Sahakara Bank", label: "M/S Seetha Raghava Souharda Sahakara Bank" },
  { value: "M/S Shiromanifin Ltd.", label: "M/S Shiromanifin Ltd." },
  { value: "M/S Shivashri Souhardha Credit Co-Op Society", label: "M/S Shivashri Souhardha Credit Co-Op Society" },
  { value: "M/S Shree Ganesh Motors Surat", label: "M/S Shree Ganesh Motors Surat" },
  { value: "M/S Siddharudh Motors Khatak Chincoli", label: "M/S Siddharudh Motors Khatak Chincoli" },
  { value: "M/S Sowbhagaya Finance And Investments", label: "M/S Sowbhagaya Finance And Investments" },
  { value: "M/S Sristhi  Motors", label: "M/S Sristhi  Motors" },
  { value: "M/S Subramanya Co-Op Bank", label: "M/S Subramanya Co-Op Bank" },
  { value: "M/S Suraksha Enterprises.", label: "M/S Suraksha Enterprises." },
  { value: "M/S Suvarnna Jyothi Leasing", label: "M/S Suvarnna Jyothi Leasing" },
  { value: "M/S Tenkasi Motor Finance", label: "M/S Tenkasi Motor Finance" },
  { value: "M/S Tiic Ltd", label: "M/S Tiic Ltd" },
  { value: "M/S Trade India Associates", label: "M/S Trade India Associates" },
  { value: "M/S Uae Exchange & Financial Services Ltd", label: "M/S Uae Exchange & Financial Services Ltd" },
  { value: "M/S Udupi Co-Op House Building Society Ltd.", label: "M/S Udupi Co-Op House Building Society Ltd." },
  { value: "M/S Universal Insurance Broker Services Pvt Ltd", label: "M/S Universal Insurance Broker Services Pvt Ltd" },
  { value: "M/S Ushodaya Enterprises", label: "M/S Ushodaya Enterprises" },
  { value: "M/S V V Motors Finance Co.", label: "M/S V V Motors Finance Co." },
  { value: "M/S Vishnoi Traders", label: "M/S Vishnoi Traders" },
  { value: "M/S Win Finance", label: "M/S Win Finance" },
  { value: "M/S Yashwanth Finance", label: "M/S Yashwanth Finance" },
  { value: "M/S. Alex Baby ( Alex)", label: "M/S. Alex Baby ( Alex)" },
  { value: "M/S. Gowtham Chand Kothari", label: "M/S. Gowtham Chand Kothari" },
  { value: "M/S. Indo Asian Finance Limited", label: "M/S. Indo Asian Finance Limited" },
  { value: "M/S. Kavita Auto Leasing", label: "M/S. Kavita Auto Leasing" },
  { value: "M/S. Om Enterprises", label: "M/S. Om Enterprises" },
  { value: "M/S. Perfect Insurance Solution", label: "M/S. Perfect Insurance Solution" },
  { value: "M/S. Pioneer Investment Co. Ahmedabad", label: "M/S. Pioneer Investment Co. Ahmedabad" },
  { value: "M/S. Sms Travel Assurance Pvt Ltd", label: "M/S. Sms Travel Assurance Pvt Ltd" },
  { value: "M/S. Sri Keerthi Chandra Finance", label: "M/S. Sri Keerthi Chandra Finance" },
  { value: "M/S. Stalwart Enterprises", label: "M/S. Stalwart Enterprises" },
  { value: "M/S.Coromandel Bank Ltd", label: "M/S.Coromandel Bank Ltd" },
  { value: "M/S.Dinesh Singh Bohra Finance", label: "M/S.Dinesh Singh Bohra Finance" },
  { value: "M/S.Om Pb Finance & Leasing Corpn{R}", label: "M/S.Om Pb Finance & Leasing Corpn{R}" },
  { value: "M/S.Trust Strategic Finance", label: "M/S.Trust Strategic Finance" },
  { value: "Ma A.Co.Babasaheb Parner", label: "Ma A.Co.Babasaheb Parner" },
  { value: "Ma Shri Digamberravaji Bagal Sah Pat Morya", label: "Ma Shri Digamberravaji Bagal Sah Pat Morya" },
  { value: "Ma. A .Co .Babasaheb", label: "Ma. A .Co .Babasaheb" },
  { value: "Ma. A .Co .Babasaheb", label: "Ma. A .Co .Babasaheb" },
  { value: "Maa Avantika Motors", label: "Maa Avantika Motors" },
  { value: "Maa Contract", label: "Maa Contract" },
  { value: "Maa Durga Trade & Associates", label: "Maa Durga Trade & Associates" },
  { value: "Maa Investments", label: "Maa Investments" },
  { value: "Maa Jagadamba Finance Co.Deeper Marketting", label: "Maa Jagadamba Finance Co.Deeper Marketting" },
  { value: "Maa Jagdamba Nagari Sah. Path Sanstha Ltd", label: "Maa Jagdamba Nagari Sah. Path Sanstha Ltd" },
  { value: "Maa Kamakhya Finance Co (P) Ltd.", label: "Maa Kamakhya Finance Co (P) Ltd." },
  { value: "Maa Kripa Auto Deal", label: "Maa Kripa Auto Deal" },
  { value: "Maa Motors Ltd", label: "Maa Motors Ltd" },
  { value: "Maa Saheb Mahila Nagri S. Patha. M", label: "Maa Saheb Mahila Nagri S. Patha. M" },
  { value: "Maa Santhoshi Maa", label: "Maa Santhoshi Maa" },
  { value: "Maa Sharadha Nagarik Sahakari Bank Ltd", label: "Maa Sharadha Nagarik Sahakari Bank Ltd" },
  { value: "Maa Sharda Mahila Nagrik Sah. Bank Mydt", label: "Maa Sharda Mahila Nagrik Sah. Bank Mydt" },
  { value: "Maan Sharda Mahila Nagrik Sahakari B Myd", label: "Maan Sharda Mahila Nagrik Sahakari B Myd" },
  { value: "Maansarovar Credit Corporation", label: "Maansarovar Credit Corporation" },
  { value: "Maarwad Gramin Bank", label: "Maarwad Gramin Bank" },
  { value: "Maatruka Mac Society Ltd", label: "Maatruka Mac Society Ltd" },
  { value: "Machindra Varal Patil Pat", label: "Machindra Varal Patil Pat" },
  { value: "Machindra Vyavsaya Sewa Sah Bank", label: "Machindra Vyavsaya Sewa Sah Bank" },
  { value: "Macma Shrachi Finance Ltd", label: "Macma Shrachi Finance Ltd" },
  { value: "Madaan Hire Purchase", label: "Madaan Hire Purchase" },
  { value: "Madai Co-Op Rural Bank Ltd", label: "Madai Co-Op Rural Bank Ltd" },
  { value: "Madai Co-Operation Rural Bank Ltd", label: "Madai Co-Operation Rural Bank Ltd" },
  { value: "Madan Finance Corporation", label: "Madan Finance Corporation" },
  { value: "Madan Financial Services Ltd.", label: "Madan Financial Services Ltd." },
  { value: "Madan Mohan Kaul", label: "Madan Mohan Kaul" },
  { value: "Madani Co Op Credit Society", label: "Madani Co Op Credit Society" },
  { value: "Madanlal Finance", label: "Madanlal Finance" },
  { value: "Madanlal Khabiya And Sons", label: "Madanlal Khabiya And Sons" },
  { value: "Madanlal Kothari And Sons", label: "Madanlal Kothari And Sons" },
  { value: "Madanpalle Co-Op Bank Ltd", label: "Madanpalle Co-Op Bank Ltd" },
  { value: "Madappally Service Co-Op.Bank Ltd.", label: "Madappally Service Co-Op.Bank Ltd." },
  { value: "Madar Co-Op Agri M/P Ser Soc Ltd", label: "Madar Co-Op Agri M/P Ser Soc Ltd" },
  { value: "Madasana Grup Seva Sahakari Mandali Ltd", label: "Madasana Grup Seva Sahakari Mandali Ltd" },
  { value: "Madavoor Service Co.Op Bank Ltd", label: "Madavoor Service Co.Op Bank Ltd" },
  { value: "Madeena Co Op Credit Socity Ltd", label: "Madeena Co Op Credit Socity Ltd" },
  { value: "Madeena Co-Op Society Ltd.", label: "Madeena Co-Op Society Ltd." },
  { value: "Madeshwari Urban Devlopment Co-Op Bank Ltd", label: "Madeshwari Urban Devlopment Co-Op Bank Ltd" },
  { value: "Madgaum Urban Co-Op. Bank Ltd., Panaji", label: "Madgaum Urban Co-Op. Bank Ltd., Panaji" },
  { value: "Madhanlal Gundecha And Sons", label: "Madhanlal Gundecha And Sons" },
  { value: "Madhav Finance", label: "Madhav Finance" },
  { value: "Madhav Investment", label: "Madhav Investment" },
  { value: "Madhav Nagarik Sahakari Bank Ltd", label: "Madhav Nagarik Sahakari Bank Ltd" },
  { value: "Madhav Nagrik Sahakari Bank Ltd.", label: "Madhav Nagrik Sahakari Bank Ltd." },
  { value: "Madhav Nagrik Sahkari Gramin Bank", label: "Madhav Nagrik Sahkari Gramin Bank" },
  { value: "Madhavapur Co-Op Bank Ltd.", label: "Madhavapur Co-Op Bank Ltd." },
  { value: "Madhavpura Merc.Co-Op Bank Ltd., Bombay", label: "Madhavpura Merc.Co-Op Bank Ltd., Bombay" },
  { value: "Madhavrao Pahilwan Jadhav Gramin Sahakari Patsanstha Maryadit", label: "Madhavrao Pahilwan Jadhav Gramin Sahakari Patsanstha Maryadit" },
  { value: "Madhayabharat Gramin Bank", label: "Madhayabharat Gramin Bank" },
  { value: "Madheshwari Urban Dep. Co-Op Bank Ltd,", label: "Madheshwari Urban Dep. Co-Op Bank Ltd," },
  { value: "Madhi Nagrik Sahkari Mandali Ltd", label: "Madhi Nagrik Sahkari Mandali Ltd" },
  { value: "Madhra Bihar Gramin Bank, Barh", label: "Madhra Bihar Gramin Bank, Barh" },
  { value: "Madhu Ahuja", label: "Madhu Ahuja" },
  { value: "Madhu Auto Finance", label: "Madhu Auto Finance" },
  { value: "Madhu Bala", label: "Madhu Bala" },
  { value: "Madhu Enterprises", label: "Madhu Enterprises" },
  { value: "Madhu Motors Financial Services Ltd", label: "Madhu Motors Financial Services Ltd" },
  { value: "Madhu Pattina Sahakara Sangha (N)", label: "Madhu Pattina Sahakara Sangha (N)" },
  { value: "Madhubala Finance", label: "Madhubala Finance" },
  { value: "Madhubani Kshetriya Gramin Bank", label: "Madhubani Kshetriya Gramin Bank" },
  { value: "Madhuhan Financial Service Ltd", label: "Madhuhan Financial Service Ltd" },
  { value: "Madhukan Trading Company", label: "Madhukan Trading Company" },
  { value: "Madhumita Auto Centre", label: "Madhumita Auto Centre" },
  { value: "Madhupura Merc.Co-Op Bank Ltd.", label: "Madhupura Merc.Co-Op Bank Ltd." },
  { value: "Madhur Instalments Ltd", label: "Madhur Instalments Ltd" },
  { value: "Madhur Motor Finance Co.", label: "Madhur Motor Finance Co." },
  { value: "Madhuri Anil Katave", label: "Madhuri Anil Katave" },
  { value: "Madhusudhan Shankar", label: "Madhusudhan Shankar" },
  { value: "Madhvi Verma", label: "Madhvi Verma" },
  { value: "Madhya Bharat Gramin Bank,", label: "Madhya Bharat Gramin Bank," },
  { value: "Madhya Bharat Gramin Kshetriya Bank", label: "Madhya Bharat Gramin Kshetriya Bank" },
  { value: "Madhya Bharat Kshetriya Gramin Bank", label: "Madhya Bharat Kshetriya Gramin Bank" },
  { value: "Madhya Pradesh Ashok Hotel Crop. Ltd.", label: "Madhya Pradesh Ashok Hotel Crop. Ltd." },
  { value: "Madhya Pradesh Financial Corporation", label: "Madhya Pradesh Financial Corporation" },
  { value: "Madhya Pradesh Jila Adivasi Antarvyavsai Samiti", label: "Madhya Pradesh Jila Adivasi Antarvyavsai Samiti" },
  { value: "Madhya Pradesh State Central Co-Oprative Bank Ltd.", label: "Madhya Pradesh State Central Co-Oprative Bank Ltd." },
  { value: "Madhya Pradesh State Industrial Development Corporation Ltd", label: "Madhya Pradesh State Industrial Development Corporation Ltd" },
  { value: "Madhya Pradesh State Tourism Development Corporation Ltd.", label: "Madhya Pradesh State Tourism Development Corporation Ltd." },
  { value: "Madhyawarti Sahakari Bank Ltd", label: "Madhyawarti Sahakari Bank Ltd" },
  { value: "Madi Kanwar- 23 Chandrappa Chennai", label: "Madi Kanwar- 23 Chandrappa Chennai" },
  { value: "Madikeri Town Co-Op Bank Ltd", label: "Madikeri Town Co-Op Bank Ltd" },
  { value: "Madikeri Town Co-Op Bank Ltd,", label: "Madikeri Town Co-Op Bank Ltd," },
  { value: "Madina Co-Operative Society Ltd", label: "Madina Co-Operative Society Ltd" },
  { value: "Madni Co Op Bank Ltd", label: "Madni Co Op Bank Ltd" },
  { value: "Madras Cements Ltd", label: "Madras Cements Ltd" },
  { value: "Madras Central Co-Operative Bank Ltd.", label: "Madras Central Co-Operative Bank Ltd." },
  { value: "Madras Credit And Investments Ltd.", label: "Madras Credit And Investments Ltd." },
  { value: "Maduha Nagarik Sahakari Bank Ltd", label: "Maduha Nagarik Sahakari Bank Ltd" },
  { value: "Madui Co-Op Bank Ltd", label: "Madui Co-Op Bank Ltd" },
  { value: "Madura Sourashtra Co-Op Bank Ltd., Madurai", label: "Madura Sourashtra Co-Op Bank Ltd., Madurai" },
  { value: "Madurai District Central Co-Op Bank Ltd., Madurai", label: "Madurai District Central Co-Op Bank Ltd., Madurai" },
  { value: "Madurai District Central Co-Op.Bank Ltd.", label: "Madurai District Central Co-Op.Bank Ltd." },
  { value: "Madurai Urban Co-Op. Bank Ltd., Madurai", label: "Madurai Urban Co-Op. Bank Ltd., Madurai" },
  { value: "Mafco India", label: "Mafco India" },
  { value: "Mag Finserv Company Ltd", label: "Mag Finserv Company Ltd" },
  { value: "Magadh Central Co-Operative", label: "Magadh Central Co-Operative" },
  { value: "Magadh Gramin Bank", label: "Magadh Gramin Bank" },
  { value: "Magalam Finance Co", label: "Magalam Finance Co" },
  { value: "Magana Finance", label: "Magana Finance" },
  { value: "Magasvargiya Nagri Sah Patsantha Junnar", label: "Magasvargiya Nagri Sah Patsantha Junnar" },
  { value: "Magasvargiya Sahakari Pat Sanstha", label: "Magasvargiya Sahakari Pat Sanstha" },
  { value: "Maggu Finance Co", label: "Maggu Finance Co" },
  { value: "Maggy Finance (P) Ltd. ( Maggy Chits And Finance (P) Ltd.)", label: "Maggy Finance (P) Ltd. ( Maggy Chits And Finance (P) Ltd.)" },
  { value: "Magha Shrachi Finance Ltd", label: "Magha Shrachi Finance Ltd" },
  { value: "Maghamala Auto Mobile Finance", label: "Maghamala Auto Mobile Finance" },
  { value: "Magharvada Juth Seva Sahakari Mandali", label: "Magharvada Juth Seva Sahakari Mandali" },
  { value: "Magharvada Juth Seva Sahakari Mandli Ltd", label: "Magharvada Juth Seva Sahakari Mandli Ltd" },
  { value: "Magharwada Juth Seva Sahakari Mandali Ltd.", label: "Magharwada Juth Seva Sahakari Mandali Ltd." },
  { value: "Magma Cell Ltd", label: "Magma Cell Ltd" },
  { value: "Magma Converge Solutions Ltd", label: "Magma Converge Solutions Ltd" },
  { value: "Magma Converge Solutions Ltd", label: "Magma Converge Solutions Ltd" },
  { value: "Magma Fin Corp Ltd & Pnb & All Ocmb", label: "Magma Fin Corp Ltd & Pnb & All Ocmb" },
  { value: "Magma Fin Corp Ltd.", label: "Magma Fin Corp Ltd." },
  { value: "Magma Finance Ltd", label: "Magma Finance Ltd" },
  { value: "Magma Financial Coporation Ltd", label: "Magma Financial Coporation Ltd" },
  { value: "Magma Financial Services Ltd", label: "Magma Financial Services Ltd" },
  { value: "Magma Fincore Pvt Ltd.", label: "Magma Fincore Pvt Ltd." },
  { value: "Magma Itl Finance Ltd", label: "Magma Itl Finance Ltd" },
  { value: "Magma Itl Finance Ltd, Pnb And Members Of Other Consortium Banks", label: "Magma Itl Finance Ltd, Pnb And Members Of Other Consortium Banks" },
  { value: "Magma Leasing Co.", label: "Magma Leasing Co." },
  { value: "Magma Leasing Finance", label: "Magma Leasing Finance" },
  { value: "Magma Leasing Finance Co Ltd.", label: "Magma Leasing Finance Co Ltd." },
  { value: "Magma Leasing Ltd", label: "Magma Leasing Ltd" },
  { value: "Magma Leasing Ltd & All Other Consortium Members Bank Ltd.", label: "Magma Leasing Ltd & All Other Consortium Members Bank Ltd." },
  { value: "Magma Leasing Ltd Pnb & Ocmb", label: "Magma Leasing Ltd Pnb & Ocmb" },
  { value: "Magma Leasing Ltd, Pnb & All Consortium Members Bank", label: "Magma Leasing Ltd, Pnb & All Consortium Members Bank" },
  { value: "Magma Leasing Ltd. P&B & Ocmb", label: "Magma Leasing Ltd. P&B & Ocmb" },
  { value: "Magma Leasing Primus Ltd", label: "Magma Leasing Primus Ltd" },
  { value: "Magma Leasing Private Limited", label: "Magma Leasing Private Limited" },
  { value: "Magma Sarachi Financial Services Ltd.", label: "Magma Sarachi Financial Services Ltd." },
  { value: "Magma Sarachi Pvt Ltd", label: "Magma Sarachi Pvt Ltd" },
  { value: "Magma Sarchi Leasing Co. Ltd", label: "Magma Sarchi Leasing Co. Ltd" },
  { value: "Magma Sarching Ltd", label: "Magma Sarching Ltd" },
  { value: "Magma Schrachi", label: "Magma Schrachi" },
  { value: "Magma Seraj", label: "Magma Seraj" },
  { value: "Magma Sharachi Finance Ltd.", label: "Magma Sharachi Finance Ltd." },
  { value: "Magma Sharchi Finance Ltd", label: "Magma Sharchi Finance Ltd" },
  { value: "Magma Sharchi Finance Ltd & Pnb & Ocmb", label: "Magma Sharchi Finance Ltd & Pnb & Ocmb" },
  { value: "Magma Shrachi Fin. Corp. Ltd", label: "Magma Shrachi Fin. Corp. Ltd" },
  { value: "Magma Shrachi Finance Ltd And Pnb And Other Banks Of Consortium", label: "Magma Shrachi Finance Ltd And Pnb And Other Banks Of Consortium" },
  { value: "Magma Shrachi Finance Ltd Pnb & All Ocmb", label: "Magma Shrachi Finance Ltd Pnb & All Ocmb" },
  { value: "Magma Shrachi Finance Ltd.", label: "Magma Shrachi Finance Ltd." },
  { value: "Magma Shrachi Securties Ltd", label: "Magma Shrachi Securties Ltd" },
  { value: "Magma Shruchi Finance Ltd", label: "Magma Shruchi Finance Ltd" },
  { value: "Magna Finance Corp Ltd", label: "Magna Finance Corp Ltd" },
  { value: "Magna Financial Ltd", label: "Magna Financial Ltd" },
  { value: "Magna Sarachi Finance Ltd", label: "Magna Sarachi Finance Ltd" },
  { value: "Magnise Ore (India) Ltd", label: "Magnise Ore (India) Ltd" },
  { value: "Magnum Finance Ltd", label: "Magnum Finance Ltd" },
  { value: "Mah & Mah Fin Ser Ltd", label: "Mah & Mah Fin Ser Ltd" },
  { value: "Mah Rajya Obc Fin & Dev Mah Ltd", label: "Mah Rajya Obc Fin & Dev Mah Ltd" },
  { value: "Maha Akshaya Finance Corp", label: "Maha Akshaya Finance Corp" },
  { value: "Maha Ngr Sank Cre Soceity Vijay Nagar", label: "Maha Ngr Sank Cre Soceity Vijay Nagar" },
  { value: "Maha Rashtra Apex Corporation Ltd", label: "Maha Rashtra Apex Corporation Ltd" },
  { value: "Mahaan Hire Purchase And Finance Ltd.", label: "Mahaan Hire Purchase And Finance Ltd." },
  { value: "Mahabad Bank", label: "Mahabad Bank" },
  { value: "Mahaballeshwara Urban Co-Operative Bank Ltd", label: "Mahaballeshwara Urban Co-Operative Bank Ltd" },
  { value: "Mahabhairab Co Operative Urban Bank Ltd", label: "Mahabhairab Co Operative Urban Bank Ltd" },
  { value: "Mahabir Corporation", label: "Mahabir Corporation" },
  { value: "Mahabir Credit Corp.", label: "Mahabir Credit Corp." },
  { value: "Mahabir Enterprises", label: "Mahabir Enterprises" },
  { value: "Mahabir Prasad Padam Kumar", label: "Mahabir Prasad Padam Kumar" },
  { value: "Mahableshwar Urban Co-Op Bank Ltd", label: "Mahableshwar Urban Co-Op Bank Ltd" },
  { value: "Mahad Co Op Urban Bank", label: "Mahad Co Op Urban Bank" },
  { value: "Mahadev Enteprises", label: "Mahadev Enteprises" },
  { value: "Mahadev Finance Services Pvt Ltd", label: "Mahadev Finance Services Pvt Ltd" },
  { value: "Mahadevrao Mahadik Gramin Bigar Sheti Sahakari Patsansta", label: "Mahadevrao Mahadik Gramin Bigar Sheti Sahakari Patsansta" },
  { value: "Mahadevrao Mahadik Gramin Bigarsheti Sahahakari Patsanstha", label: "Mahadevrao Mahadik Gramin Bigarsheti Sahahakari Patsanstha" },
  { value: "Mahadhanam Finance - Thillai Nagar Trichy.", label: "Mahadhanam Finance - Thillai Nagar Trichy." },
  { value: "Mahajan Finance", label: "Mahajan Finance" },
  { value: "Mahakali Sahakari Patpedhi Maryadit", label: "Mahakali Sahakari Patpedhi Maryadit" },
  { value: "Mahalakshmi Auto Finance", label: "Mahalakshmi Auto Finance" },
  { value: "Mahalakshmi Co-Op Bank Ltd - Gangolli", label: "Mahalakshmi Co-Op Bank Ltd - Gangolli" },
  { value: "Mahalakshmi Co-Op Bank Ltd - Malpe", label: "Mahalakshmi Co-Op Bank Ltd - Malpe" },
  { value: "Mahalakshmi Co-Op Bank Ltd., Udipi", label: "Mahalakshmi Co-Op Bank Ltd., Udipi" },
  { value: "Mahalakshmi Finance", label: "Mahalakshmi Finance" },
  { value: "Mahalakshmi Investment", label: "Mahalakshmi Investment" },
  { value: "Mahalakshmi Pattina Sahakari Co-Operative Ltd", label: "Mahalakshmi Pattina Sahakari Co-Operative Ltd" },
  { value: "Mahalakshmi Souharda Co-Operative Ltd", label: "Mahalakshmi Souharda Co-Operative Ltd" },
  { value: "Mahalaxmi Auto Agency", label: "Mahalaxmi Auto Agency" },
  { value: "Mahalaxmi Auto And Employees Finance", label: "Mahalaxmi Auto And Employees Finance" },
  { value: "Mahalaxmi Auto Fiance And Leasing Bhalki", label: "Mahalaxmi Auto Fiance And Leasing Bhalki" },
  { value: "Mahalaxmi Auto Finance", label: "Mahalaxmi Auto Finance" },
  { value: "Mahalaxmi Auto Finance And Leasing", label: "Mahalaxmi Auto Finance And Leasing" },
  { value: "Mahalaxmi Auto Finance Corporation", label: "Mahalaxmi Auto Finance Corporation" },
  { value: "Mahalaxmi Auto Finance Ltd", label: "Mahalaxmi Auto Finance Ltd" },
  { value: "Mahalaxmi Bigar Sheti Sah Patsanstha", label: "Mahalaxmi Bigar Sheti Sah Patsanstha" },
  { value: "Mahalaxmi Co-Op Bank Ltd", label: "Mahalaxmi Co-Op Bank Ltd" },
  { value: "Mahalaxmi Co-Op Bank Ltd - Kundapura.", label: "Mahalaxmi Co-Op Bank Ltd - Kundapura." },
  { value: "Mahalaxmi Co-Operative Bank Ltd", label: "Mahalaxmi Co-Operative Bank Ltd" },
  { value: "Mahalaxmi Corporation", label: "Mahalaxmi Corporation" },
  { value: "Mahalaxmi Enterprise", label: "Mahalaxmi Enterprise" },
  { value: "Mahalaxmi Financiers", label: "Mahalaxmi Financiers" },
  { value: "Mahalaxmi Gramin Bigar Sheti Patsanstha", label: "Mahalaxmi Gramin Bigar Sheti Patsanstha" },
  { value: "Mahalaxmi Gramin Bigar Sheti Sahakari Patsanstha Maryadit", label: "Mahalaxmi Gramin Bigar Sheti Sahakari Patsanstha Maryadit" },
  { value: "Mahalaxmi Hp Finance", label: "Mahalaxmi Hp Finance" },
  { value: "Mahalaxmi Leasing", label: "Mahalaxmi Leasing" },
  { value: "Mahalaxmi Mahila Pattin Sahakari Sang.", label: "Mahalaxmi Mahila Pattin Sahakari Sang." },
  { value: "Mahalaxmi Multi Purpose Co-Operative Society Limited", label: "Mahalaxmi Multi Purpose Co-Operative Society Limited" },
  { value: "Mahalaxmi Shroff", label: "Mahalaxmi Shroff" },
  { value: "Mahalaxmi Shroff And Financer", label: "Mahalaxmi Shroff And Financer" },
  { value: "Mahalaxmi Souharda Co Ltd", label: "Mahalaxmi Souharda Co Ltd" },
  { value: "Mahalaxmi U C C S", label: "Mahalaxmi U C C S" },
  { value: "Mahalaxmi Urban Co-Op Soc L", label: "Mahalaxmi Urban Co-Op Soc L" },
  { value: "Mahalingpur Urabn Co-Op Bank Ltd", label: "Mahalingpur Urabn Co-Op Bank Ltd" },
  { value: "Mahamati Finance Corporation Himatnagar", label: "Mahamati Finance Corporation Himatnagar" },
  { value: "Mahamaya Motor And Gen Finance Co", label: "Mahamaya Motor And Gen Finance Co" },
  { value: "Mahamedha Urban Co-Operative Bank Ltd.", label: "Mahamedha Urban Co-Operative Bank Ltd." },
  { value: "Mahanadi Coalfields Limited", label: "Mahanadi Coalfields Limited" },
  { value: "Mahanagar Co-Op Urban Bank Ltd", label: "Mahanagar Co-Op Urban Bank Ltd" },
  { value: "Mahanagar Nagrik Sahakari Bank Maryadit", label: "Mahanagar Nagrik Sahakari Bank Maryadit" },
  { value: "Mahanagar Nagrik Sahakari Bank Maryadit", label: "Mahanagar Nagrik Sahakari Bank Maryadit" },
  { value: "Mahanagar Telephone Nigam Limited", label: "Mahanagar Telephone Nigam Limited" },
  { value: "Mahanidhi Finance", label: "Mahanidhi Finance" },
  { value: "Mahankali Narayya Financiers", label: "Mahankali Narayya Financiers" },
  { value: "Mahantesh Auto Finance", label: "Mahantesh Auto Finance" },
  { value: "Mahantesh Co-Op Credit Society", label: "Mahantesh Co-Op Credit Society" },
  { value: "Mahar Financier  Chennai", label: "Mahar Financier  Chennai" },
  { value: "Maharaja Agrsen College", label: "Maharaja Agrsen College" },
  { value: "Maharaja Finance", label: "Maharaja Finance" },
  { value: "Maharashtra Antibiotics And Pharmaceuticals Ltd.", label: "Maharashtra Antibiotics And Pharmaceuticals Ltd." },
  { value: "Maharashtra Auto Finance Corporation", label: "Maharashtra Auto Finance Corporation" },
  { value: "Maharashtra Auto Finance Pvt Ltd", label: "Maharashtra Auto Finance Pvt Ltd" },
  { value: "Maharashtra Bank", label: "Maharashtra Bank" },
  { value: "Maharashtra Brahman Sah.Bank Ltd., Indore", label: "Maharashtra Brahman Sah.Bank Ltd., Indore" },
  { value: "Maharashtra Central Co-Op Bank", label: "Maharashtra Central Co-Op Bank" },
  { value: "Maharashtra Co-Op Bank Ltd", label: "Maharashtra Co-Op Bank Ltd" },
  { value: "Maharashtra Co-Op Credit Soc. Ltd.", label: "Maharashtra Co-Op Credit Soc. Ltd." },
  { value: "Maharashtra Elektrosmelt Ltd.", label: "Maharashtra Elektrosmelt Ltd." },
  { value: "Maharashtra Godawari Gramin Bank", label: "Maharashtra Godawari Gramin Bank" },
  { value: "Maharashtra Mahila Urban Co.Op. Credit Society", label: "Maharashtra Mahila Urban Co.Op. Credit Society" },
  { value: "Maharashtra Mantralaya And  Allied Offices Co-Op Bank Ltd", label: "Maharashtra Mantralaya And  Allied Offices Co-Op Bank Ltd" },
  { value: "Maharashtra Nagari Sah Patsanstha", label: "Maharashtra Nagari Sah Patsanstha" },
  { value: "Maharashtra Nagari Sahakari Bk Maryadit", label: "Maharashtra Nagari Sahakari Bk Maryadit" },
  { value: "Maharashtra Nagri Sahakari Bank Ltd.", label: "Maharashtra Nagri Sahakari Bank Ltd." },
  { value: "Maharashtra Rajya Apang Vitta Ani Vikas Mahamandal", label: "Maharashtra Rajya Apang Vitta Ani Vikas Mahamandal" },
  { value: "Maharashtra Remote Sensing Applications Center", label: "Maharashtra Remote Sensing Applications Center" },
  { value: "Maharashtra State Central Co-Oprative Bank Ltd.", label: "Maharashtra State Central Co-Oprative Bank Ltd." },
  { value: "Maharashtra State Co-Op Bank Ltd., Mumbai.", label: "Maharashtra State Co-Op Bank Ltd., Mumbai." },
  { value: "Maharashtra State Electricity Board", label: "Maharashtra State Electricity Board" },
  { value: "Maharashtra State Financial Corporation", label: "Maharashtra State Financial Corporation" },
  { value: "Maharashtra State Handicaped Fin & Development Corporation Ltd", label: "Maharashtra State Handicaped Fin & Development Corporation Ltd" },
  { value: "Maharashtra State Road Development Corporation Ltd.", label: "Maharashtra State Road Development Corporation Ltd." },
  { value: "Maharashtra State Seeds Crop. Limited", label: "Maharashtra State Seeds Crop. Limited" },
  { value: "Maharashtra Tourism Development Corporation Ltd", label: "Maharashtra Tourism Development Corporation Ltd" },
  { value: "Maharashtra Urban Co-Op. Credit Soc. Ltd", label: "Maharashtra Urban Co-Op. Credit Soc. Ltd" },
  { value: "Maharashtra Urban Credit Society", label: "Maharashtra Urban Credit Society" },
  { value: "Maharashtriya Ekyavardhak Sahakari Mandal Patpedhi Ltd", label: "Maharashtriya Ekyavardhak Sahakari Mandal Patpedhi Ltd" },
  { value: "Maharishi Dayanand University", label: "Maharishi Dayanand University" },
  { value: "Maharshtra Auto Finance Ltd", label: "Maharshtra Auto Finance Ltd" },
  { value: "Mahasakthi Finace", label: "Mahasakthi Finace" },
  { value: "Mahashakthi Finance", label: "Mahashakthi Finance" },
  { value: "Mahatama Fule Co Op Credit Society Ltd", label: "Mahatama Fule Co Op Credit Society Ltd" },
  { value: "Mahatma Fule Grahmin Non Agri Sah Pat Sanstha", label: "Mahatma Fule Grahmin Non Agri Sah Pat Sanstha" },
  { value: "Mahatma Fule Gramin Sah Ltd", label: "Mahatma Fule Gramin Sah Ltd" },
  { value: "Mahatma Fule Nagari Sah", label: "Mahatma Fule Nagari Sah" },
  { value: "Mahatma Fule Nagari Sah Pat San Ltd. Bhingar", label: "Mahatma Fule Nagari Sah Pat San Ltd. Bhingar" },
  { value: "Mahatma Fule Nagari Sah. Path Sanstha", label: "Mahatma Fule Nagari Sah. Path Sanstha" },
  { value: "Mahatma Fule Urban Co.Bank Ltd", label: "Mahatma Fule Urban Co.Bank Ltd" },
  { value: "Mahatma Gandhi Co Op Credit Society", label: "Mahatma Gandhi Co Op Credit Society" },
  { value: "Mahatma Gandhi State Institute Of Public Administration", label: "Mahatma Gandhi State Institute Of Public Administration" },
  { value: "Mahatma Jotirao Fule Garmin B. Pat Barlow.", label: "Mahatma Jotirao Fule Garmin B. Pat Barlow." },
  { value: "Mahatma Jyotirao Fule Sah Patsanstha", label: "Mahatma Jyotirao Fule Sah Patsanstha" },
  { value: "Mahatma Jyotirao Phule Gramin Bigar Sheti Sah. Patsanstha Ltd.", label: "Mahatma Jyotirao Phule Gramin Bigar Sheti Sah. Patsanstha Ltd." },
  { value: "Mahatma Phule Backward Class Dev Corpn Ltd", label: "Mahatma Phule Backward Class Dev Corpn Ltd" },
  { value: "Mahatma Phule Co-Op Credit Society Ltd.", label: "Mahatma Phule Co-Op Credit Society Ltd." },
  { value: "Mahatma Phule G. B. S .S. Path Sanstha Mydt.", label: "Mahatma Phule G. B. S .S. Path Sanstha Mydt." },
  { value: "Mahatma Phule Gramin Bigar Sheti Sah.Path Sanstha Mydt .", label: "Mahatma Phule Gramin Bigar Sheti Sah.Path Sanstha Mydt ." },
  { value: "Mahatma Phule Magasvargiya Vikas Mahamandal", label: "Mahatma Phule Magasvargiya Vikas Mahamandal" },
  { value: "Mahatma Phule Pathsanstha", label: "Mahatma Phule Pathsanstha" },
  { value: "Mahatma Phule Urban Co-Op. Bank Ltd.", label: "Mahatma Phule Urban Co-Op. Bank Ltd." },
  { value: "Mahatma Phule Vikas Sahakari Seva Sanstha", label: "Mahatma Phule Vikas Sahakari Seva Sanstha" },
  { value: "Mahaveer Auto Enterprise", label: "Mahaveer Auto Enterprise" },
  { value: "Mahaveer Auto Finance", label: "Mahaveer Auto Finance" },
  { value: "Mahaveer Auto Finance(Madras)Ltd", label: "Mahaveer Auto Finance(Madras)Ltd" },
  { value: "Mahaveer Auto Investments", label: "Mahaveer Auto Investments" },
  { value: "Mahaveer Chan Khariwal", label: "Mahaveer Chan Khariwal" },
  { value: "Mahaveer Chand", label: "Mahaveer Chand" },
  { value: "Mahaveer Chand Chordia", label: "Mahaveer Chand Chordia" },
  { value: "Mahaveer Chand Khariwal", label: "Mahaveer Chand Khariwal" },
  { value: "Mahaveer Chand Kothari", label: "Mahaveer Chand Kothari" },
  { value: "Mahaveer Co-Op Credit Soc. Ltd.", label: "Mahaveer Co-Op Credit Soc. Ltd." },
  { value: "Mahaveer Co-Op. Urban Bank Ltd.", label: "Mahaveer Co-Op. Urban Bank Ltd." },
  { value: "Mahaveer Co-Operative Society", label: "Mahaveer Co-Operative Society" },
  { value: "Mahaveer Credit Co-Operative Society", label: "Mahaveer Credit Co-Operative Society" },
  { value: "Mahaveer Credit Corporation", label: "Mahaveer Credit Corporation" },
  { value: "Mahaveer Enterprise", label: "Mahaveer Enterprise" },
  { value: "Mahaveer Finance - Chennai", label: "Mahaveer Finance - Chennai" },
  { value: "Mahaveer Finance India Ltd With Lead Bank Sbi", label: "Mahaveer Finance India Ltd With Lead Bank Sbi" },
  { value: "Mahaveer Finance India Ltd.", label: "Mahaveer Finance India Ltd." },
  { value: "Mahaveer Finvest", label: "Mahaveer Finvest" },
  { value: "Mahaveer Krupa", label: "Mahaveer Krupa" },
  { value: "Mahaveer Kumar Jain Huf", label: "Mahaveer Kumar Jain Huf" },
  { value: "Mahaveer Leafin And Holdings Ltd.", label: "Mahaveer Leafin And Holdings Ltd." },
  { value: "Mahaveer Leafin Automobile Finance", label: "Mahaveer Leafin Automobile Finance" },
  { value: "Mahaveer Motor Finance", label: "Mahaveer Motor Finance" },
  { value: "Mahaveer Multi Purpose Souhard Sahakari Niyamit", label: "Mahaveer Multi Purpose Souhard Sahakari Niyamit" },
  { value: "Mahaveer Nagari Sahkari Pathsanstha", label: "Mahaveer Nagari Sahkari Pathsanstha" },
  { value: "Mahaveer Nahar", label: "Mahaveer Nahar" },
  { value: "Mahaveer P Financers", label: "Mahaveer P Financers" },
  { value: "Mahaveer Syndicate", label: "Mahaveer Syndicate" },
  { value: "Mahaveer Traders", label: "Mahaveer Traders" },
  { value: "Mahaveer Urban Co-Op Credit Bank Ltd", label: "Mahaveer Urban Co-Op Credit Bank Ltd" },
  { value: "Mahaveera Co-Op Bank", label: "Mahaveera Co-Op Bank" },
  { value: "Mahaveera Finance Corporation", label: "Mahaveera Finance Corporation" },
  { value: "Mahaveerchand & Family", label: "Mahaveerchand & Family" },
  { value: "Mahaveerchand Jain Financial Ltd", label: "Mahaveerchand Jain Financial Ltd" },
  { value: "Mahaveet Krupa", label: "Mahaveet Krupa" },
  { value: "Mahaver Finance", label: "Mahaver Finance" },
  { value: "Mahavir Auto Finance Co", label: "Mahavir Auto Finance Co" },
  { value: "Mahavir Auto Point", label: "Mahavir Auto Point" },
  { value: "Mahavir Co Op Credit Society", label: "Mahavir Co Op Credit Society" },
  { value: "Mahavir Co-Op Credit Society", label: "Mahavir Co-Op Credit Society" },
  { value: "Mahavir Enterprises", label: "Mahavir Enterprises" },
  { value: "Mahavir Finance", label: "Mahavir Finance" },
  { value: "Mahavir Finvest", label: "Mahavir Finvest" },
  { value: "Mahavir Gramin Bigarsheti Sah Patsanstha", label: "Mahavir Gramin Bigarsheti Sah Patsanstha" },
  { value: "Mahavir Gramin Sahakari Path Sanstha Marya", label: "Mahavir Gramin Sahakari Path Sanstha Marya" },
  { value: "Mahavir Investments", label: "Mahavir Investments" },
  { value: "Mahavir Mitra Mandal", label: "Mahavir Mitra Mandal" },
  { value: "Mahavir Singh", label: "Mahavir Singh" },
  { value: "Mahavir Traders", label: "Mahavir Traders" },
  { value: "Mahavir Trading Company", label: "Mahavir Trading Company" },
  { value: "Mahavira Finance Corporation", label: "Mahavira Finance Corporation" },
  { value: "Mahek Corporation", label: "Mahek Corporation" },
  { value: "Mahek Finance Corporation", label: "Mahek Finance Corporation" },
  { value: "Mahemadavad Urban Peoples Co. Bank Ltd.", label: "Mahemadavad Urban Peoples Co. Bank Ltd." },
  { value: "Mahendra Auto Finance", label: "Mahendra Auto Finance" },
  { value: "Mahendra Auto Mobiles", label: "Mahendra Auto Mobiles" },
  { value: "Mahendra Finance Corporation", label: "Mahendra Finance Corporation" },
  { value: "Mahendra Instalment Supply (P) Ltd", label: "Mahendra Instalment Supply (P) Ltd" },
  { value: "Mahendra Mitthulal Achaliya", label: "Mahendra Mitthulal Achaliya" },
  { value: "Mahendra Pal Singh", label: "Mahendra Pal Singh" },
  { value: "Mahendra Singh Gulati", label: "Mahendra Singh Gulati" },
  { value: "Mahendragarh District Central Co-Operative Bank Ltd.", label: "Mahendragarh District Central Co-Operative Bank Ltd." },
  { value: "Mahesana Samaj Co-Op Bank Ltd", label: "Mahesana Samaj Co-Op Bank Ltd" },
  { value: "Mahesana Samaj Co-Op Bank Ltd.", label: "Mahesana Samaj Co-Op Bank Ltd." },
  { value: "Mahesana Urban Co-Op Bank Ltd", label: "Mahesana Urban Co-Op Bank Ltd" },
  { value: "Mahesh Chand Agarwal", label: "Mahesh Chand Agarwal" },
  { value: "Mahesh Finance", label: "Mahesh Finance" },
  { value: "Mahesh Gilda", label: "Mahesh Gilda" },
  { value: "Mahesh Gramin Bigar Sheti Sahakari Pathsanstha Maryadit", label: "Mahesh Gramin Bigar Sheti Sahakari Pathsanstha Maryadit" },
  { value: "Mahesh Kumar", label: "Mahesh Kumar" },
  { value: "Mahesh Mahila Nagari Sahkari Path Sanstha Maryadit", label: "Mahesh Mahila Nagari Sahkari Path Sanstha Maryadit" },
  { value: "Mahesh Merchant Nagari Sah Pat.", label: "Mahesh Merchant Nagari Sah Pat." },
  { value: "Mahesh Nagari Sahkari Path Sanstha Maryadit", label: "Mahesh Nagari Sahkari Path Sanstha Maryadit" },
  { value: "Mahesh Nagri Sahkari Bank Ltd.", label: "Mahesh Nagri Sahkari Bank Ltd." },
  { value: "Mahesh Sah. Bank Ltd.", label: "Mahesh Sah. Bank Ltd." },
  { value: "Mahesh Sahakari Bank Ltd.", label: "Mahesh Sahakari Bank Ltd." },
  { value: "Mahesh Singvi", label: "Mahesh Singvi" },
  { value: "Mahesh Urban Co-Op B L", label: "Mahesh Urban Co-Op B L" },
  { value: "Mahesh Urban Co-Op Bank Ltd.", label: "Mahesh Urban Co-Op Bank Ltd." },
  { value: "Mahesh Urban Co-Op Credit Society Ltd", label: "Mahesh Urban Co-Op Credit Society Ltd" },
  { value: "Mahesh Urban Co-Operative Bank Ltd.", label: "Mahesh Urban Co-Operative Bank Ltd." },
  { value: "Maheshwar Sahakari Patsanstha", label: "Maheshwar Sahakari Patsanstha" },
  { value: "Maheshwari And Co", label: "Maheshwari And Co" },
  { value: "Maheshwari Auto Finance", label: "Maheshwari Auto Finance" },
  { value: "Maheshwari Finance", label: "Maheshwari Finance" },
  { value: "Maheshwari Finance Betul", label: "Maheshwari Finance Betul" },
  { value: "Maheshwari Lease Finance", label: "Maheshwari Lease Finance" },
  { value: "Mahhad Urban Co-Op Bank Ltd", label: "Mahhad Urban Co-Op Bank Ltd" },
  { value: "Mahidharpura Urben Co Op Cr Soc Ltd", label: "Mahidharpura Urben Co Op Cr Soc Ltd" },
  { value: "Mahila Banking Branch", label: "Mahila Banking Branch" },
  { value: "Mahila Co Op Nagrik Bank Ltd.", label: "Mahila Co Op Nagrik Bank Ltd." },
  { value: "Mahila Co-Op Bank Ltd.", label: "Mahila Co-Op Bank Ltd." },
  { value: "Mahila Co-Op Nagrik Bank Ltd", label: "Mahila Co-Op Nagrik Bank Ltd" },
  { value: "Mahila Co-Operative Bank Limited", label: "Mahila Co-Operative Bank Limited" },
  { value: "Mahila Merchant Nagari Sah Pat Sanstha M", label: "Mahila Merchant Nagari Sah Pat Sanstha M" },
  { value: "Mahila Mhasai Mata Pat San", label: "Mahila Mhasai Mata Pat San" },
  { value: "Mahila Nagrik Sahakari Bank", label: "Mahila Nagrik Sahakari Bank" },
  { value: "Mahila Nagrik Sahkari Bank Marya", label: "Mahila Nagrik Sahkari Bank Marya" },
  { value: "Mahila Sahakari Bank Ltd.", label: "Mahila Sahakari Bank Ltd." },
  { value: "Mahila Shakti Co-Op Bank Ltd", label: "Mahila Shakti Co-Op Bank Ltd" },
  { value: "Mahila Urban Sahakari Bank Ltd.", label: "Mahila Urban Sahakari Bank Ltd." },
  { value: "Mahila Utkarsh Nagarik Sahakari Bank Ltd", label: "Mahila Utkarsh Nagarik Sahakari Bank Ltd" },
  { value: "Mahila Utkarsha Nagrik Sah. Bank Ltd.", label: "Mahila Utkarsha Nagrik Sah. Bank Ltd." },
  { value: "Mahila Vikas Co-Op. Bank Ltd.", label: "Mahila Vikas Co-Op. Bank Ltd." },
  { value: "Mahila Vikas Nagar Sah Path Maryadit", label: "Mahila Vikas Nagar Sah Path Maryadit" },
  { value: "Mahilpur Primary Agriculture Bank Ltd", label: "Mahilpur Primary Agriculture Bank Ltd" },
  { value: "Mahima Finance Services", label: "Mahima Finance Services" },
  { value: "Mahind Mahind Financial Services Ltd", label: "Mahind Mahind Financial Services Ltd" },
  { value: "Mahindari Automobile Finance", label: "Mahindari Automobile Finance" },
  { value: "Mahinder Singh Tyagi", label: "Mahinder Singh Tyagi" },
  { value: "Mahindra & Mahindar Finance Services Ltd, Bijapur", label: "Mahindra & Mahindar Finance Services Ltd, Bijapur" },
  { value: "Mahindra & Mahindra Cfs Ltd", label: "Mahindra & Mahindra Cfs Ltd" },
  { value: "Mahindra & Mahindra Co Ltd", label: "Mahindra & Mahindra Co Ltd" },
  { value: "Mahindra & Mahindra Finance Co Ltd", label: "Mahindra & Mahindra Finance Co Ltd" },
  { value: "Mahindra & Mahindra Finance Ltd.", label: "Mahindra & Mahindra Finance Ltd." },
  { value: "Mahindra & Mahindra Finance Service Ltd.", label: "Mahindra & Mahindra Finance Service Ltd." },
  { value: "Mahindra & Mahindra Financial Services Ltd", label: "Mahindra & Mahindra Financial Services Ltd" },
  { value: "Mahindra & Mahindra Pri Financial Services Ltd .", label: "Mahindra & Mahindra Pri Financial Services Ltd ." },
  { value: "Mahindra & Mahindra Prime Ltd", label: "Mahindra & Mahindra Prime Ltd" },
  { value: "Mahindra & Mahindra Pvt Ltd", label: "Mahindra & Mahindra Pvt Ltd" },
  { value: "Mahindra & Mahindra Ser Ltd", label: "Mahindra & Mahindra Ser Ltd" },
  { value: "Mahindra & Mahindra Service", label: "Mahindra & Mahindra Service" },
  { value: "Mahindra And Mahindra", label: "Mahindra And Mahindra" },
  { value: "Mahindra And Mahindra Bank Ltd", label: "Mahindra And Mahindra Bank Ltd" },
  { value: "Mahindra And Mahindra Finance Limited", label: "Mahindra And Mahindra Finance Limited" },
  { value: "Mahindra And Mahindra Financial Services Ltd", label: "Mahindra And Mahindra Financial Services Ltd" },
  { value: "Mahindra And Mahindra Financing Co Ltd", label: "Mahindra And Mahindra Financing Co Ltd" },
  { value: "Mahindra And Mahindra Primus Ltd", label: "Mahindra And Mahindra Primus Ltd" },
  { value: "Mahindra And Mahindra Services Ltd", label: "Mahindra And Mahindra Services Ltd" },
  { value: "Mahindra Bank Ltd", label: "Mahindra Bank Ltd" },
  { value: "Mahindra Finanace Ltd", label: "Mahindra Finanace Ltd" },
  { value: "Mahindra Finance", label: "Mahindra Finance" },
  { value: "Mahindra Finance Corporation", label: "Mahindra Finance Corporation" },
  { value: "Mahindra Finance Service Ltd", label: "Mahindra Finance Service Ltd" },
  { value: "Mahindra Finance Service Ltd", label: "Mahindra Finance Service Ltd" },
  { value: "Mahindra Finance Services Pvt Ltd", label: "Mahindra Finance Services Pvt Ltd" },
  { value: "Mahindra Hire Purchase Finance", label: "Mahindra Hire Purchase Finance" },
  { value: "Mahindra Kotak Ltd.Surat", label: "Mahindra Kotak Ltd.Surat" },
  { value: "Mahindra Primas Ltd", label: "Mahindra Primas Ltd" },
  { value: "Mahindra Prime Ltd", label: "Mahindra Prime Ltd" },
  { value: "Mahindra&Mahindra Financial Ltd", label: "Mahindra&Mahindra Financial Ltd" },
  { value: "Mahipuram General And Leasing Limited", label: "Mahipuram General And Leasing Limited" },
  { value: "Mahishmati Nagrik Sahakari Bank Ltd,", label: "Mahishmati Nagrik Sahakari Bank Ltd," },
  { value: "Mahishmati Nagrik Sahakari Bk.Ltd.", label: "Mahishmati Nagrik Sahakari Bk.Ltd." },
  { value: "Mahnedra & Mahendra Finacial Services,Surat", label: "Mahnedra & Mahendra Finacial Services,Surat" },
  { value: "Mahoba Urban Coop Bank Ltd", label: "Mahoba Urban Coop Bank Ltd" },
  { value: "Mahrajya Obc Finance & Dev Maharashtra Ltd", label: "Mahrajya Obc Finance & Dev Maharashtra Ltd" },
  { value: "Mahrana Pratap Nagari Sahakari Pat Pedi Ltd", label: "Mahrana Pratap Nagari Sahakari Pat Pedi Ltd" },
  { value: "Mahrastra Auto Finc. Pvt. Ltd.", label: "Mahrastra Auto Finc. Pvt. Ltd." },
  { value: "Mahrastra Auto Finc. Pvt. Ltd.", label: "Mahrastra Auto Finc. Pvt. Ltd." },
  { value: "Mahuva Nagrik Sahakari Bank Limited", label: "Mahuva Nagrik Sahakari Bank Limited" },
  { value: "Mahuva Vibhag Peoples Co Op Cr.Soc.Ltd", label: "Mahuva Vibhag Peoples Co Op Cr.Soc.Ltd" },
  { value: "Maina Motors", label: "Maina Motors" },
  { value: "Mainabai Finance", label: "Mainabai Finance" },
  { value: "Mainadevi Jain", label: "Mainadevi Jain" },
  { value: "Mainpuri Zila Sahakari Bank Ltd", label: "Mainpuri Zila Sahakari Bank Ltd" },
  { value: "Mairashtra Auto Fin.", label: "Mairashtra Auto Fin." },
  { value: "Mairasktx Auto Finance Ltd", label: "Mairasktx Auto Finance Ltd" },
  { value: "Majha Doaba Consultants And Finvest Pvt. Ltd.", label: "Majha Doaba Consultants And Finvest Pvt. Ltd." },
  { value: "Majha Doaba Consultents And Finvest Ltd", label: "Majha Doaba Consultents And Finvest Ltd" },
  { value: "Majha Finance Pvt. Ltd.", label: "Majha Finance Pvt. Ltd." },
  { value: "Majha Investment Ltd.", label: "Majha Investment Ltd." },
  { value: "Maji Nyaymurti B.G Kolse Patil Bigarsheti Gramin Pathasanstha", label: "Maji Nyaymurti B.G Kolse Patil Bigarsheti Gramin Pathasanstha" },
  { value: "Majotra Finance Co Ltd", label: "Majotra Finance Co Ltd" },
  { value: "Majra Seva Sahkari Mandali Ltd", label: "Majra Seva Sahkari Mandali Ltd" },
  { value: "Majula Auto Finance Ltd", label: "Majula Auto Finance Ltd" },
  { value: "Makaljyoti Leasing", label: "Makaljyoti Leasing" },
  { value: "Makarpura Indl.Est.Co-Op Bank Ltd.", label: "Makarpura Indl.Est.Co-Op Bank Ltd." },
  { value: "Makarya Capital Ltd", label: "Makarya Capital Ltd" },
  { value: "Makhan Shah Lobana Finance Pvt.Ltd.", label: "Makhan Shah Lobana Finance Pvt.Ltd." },
  { value: "Makhija Auto Consultants", label: "Makhija Auto Consultants" },
  { value: "Makhija Auto Finance", label: "Makhija Auto Finance" },
  { value: "Makhmalabad Vividh Karya Sah Vikash Seva Sanstha Maryadit", label: "Makhmalabad Vividh Karya Sah Vikash Seva Sanstha Maryadit" },
  { value: "Makhmalabad Vividh Karya Sahakari Vikash Seva Sanstha Maryadit", label: "Makhmalabad Vividh Karya Sahakari Vikash Seva Sanstha Maryadit" },
  { value: "Makind India Private Ltd", label: "Makind India Private Ltd" },
  { value: "Makino India Private Ltd", label: "Makino India Private Ltd" },
  { value: "Makkaraparamba Service Co-Op Bank Ltd", label: "Makkaraparamba Service Co-Op Bank Ltd" },
  { value: "Mala Block Town Co-Operative Bank Ltd", label: "Mala Block Town Co-Operative Bank Ltd" },
  { value: "Mala Bohra/ Bohra House,", label: "Mala Bohra/ Bohra House," },
  { value: "Mala Kervashe Vyavasaya Ser Co-Op Bk L", label: "Mala Kervashe Vyavasaya Ser Co-Op Bk L" },
  { value: "Malabar Finance Corporation", label: "Malabar Finance Corporation" },
  { value: "Malabohra Finance", label: "Malabohra Finance" },
  { value: "Malad Sahakari Bank", label: "Malad Sahakari Bank" },
  { value: "Malaidevi Nagari Patsanstha Maryadit", label: "Malaidevi Nagari Patsanstha Maryadit" },
  { value: "Malakapur Nagari Sahkari Pat Santha", label: "Malakapur Nagari Sahkari Pat Santha" },
  { value: "Malanad Co Op Agricultural Development Bank Ltd", label: "Malanad Co Op Agricultural Development Bank Ltd" },
  { value: "Malanad Co Op Bank", label: "Malanad Co Op Bank" },
  { value: "Malanadu Development Bank", label: "Malanadu Development Bank" },
  { value: "Malanpur Seva Sahakri Mandali", label: "Malanpur Seva Sahakri Mandali" },
  { value: "Malappuram Co Op Urban Bank Ltd", label: "Malappuram Co Op Urban Bank Ltd" },
  { value: "Malappuram District Central Co-Operative Bank Ltd.", label: "Malappuram District Central Co-Operative Bank Ltd." },
  { value: "Malappuram Service Co-Op. Bank Ltd.", label: "Malappuram Service Co-Op. Bank Ltd." },
  { value: "Malappuram Service Coop Bank Ltd.", label: "Malappuram Service Coop Bank Ltd." },
  { value: "Malaprabha Grameena  Bank", label: "Malaprabha Grameena  Bank" },
  { value: "Malaprabha Grameena Bank , Sangur", label: "Malaprabha Grameena Bank , Sangur" },
  { value: "Malapuram Dist Co Op Motor Vechile Ltd", label: "Malapuram Dist Co Op Motor Vechile Ltd" },
  { value: "Malapuram Dist Co-Op Mvs Ltd", label: "Malapuram Dist Co-Op Mvs Ltd" },
  { value: "Malataj Cooppretive Credit Society", label: "Malataj Cooppretive Credit Society" },
  { value: "Malayalam Hire Purchase And Leasing Pvt Ltd.", label: "Malayalam Hire Purchase And Leasing Pvt Ltd." },
  { value: "Malda Co Op Agri And Rural Dev Bank", label: "Malda Co Op Agri And Rural Dev Bank" },
  { value: "Malda District Central Co-Operative Bank Ltd.", label: "Malda District Central Co-Operative Bank Ltd." },
  { value: "Maldare Badaga Ser. Co-Op. Bank Ltd", label: "Maldare Badaga Ser. Co-Op. Bank Ltd" },
  { value: "Malegaon Merchants Co-Op Bank Ltd", label: "Malegaon Merchants Co-Op Bank Ltd" },
  { value: "Malemaleshwar Co-Op Credit Society", label: "Malemaleshwar Co-Op Credit Society" },
  { value: "Malenadu Credit Co-Operative Society", label: "Malenadu Credit Co-Operative Society" },
  { value: "Malganga Nagari Sahkari Pathsanstha Ltd.", label: "Malganga Nagari Sahkari Pathsanstha Ltd." },
  { value: "Malgangadevi Sah Pat Pet Mary.", label: "Malgangadevi Sah Pat Pet Mary." },
  { value: "Malhotra Auto Sales", label: "Malhotra Auto Sales" },
  { value: "Malhotra Fincap Ltd", label: "Malhotra Fincap Ltd" },
  { value: "Malik Fin. Cap. (P) Ltd.", label: "Malik Fin. Cap. (P) Ltd." },
  { value: "Malik Finance Co", label: "Malik Finance Co" },
  { value: "Malik Finance Pvt Ltd", label: "Malik Finance Pvt Ltd" },
  { value: "Malik Motor & General", label: "Malik Motor & General" },
  { value: "Malik Sincap Pvt Ltd", label: "Malik Sincap Pvt Ltd" },
  { value: "Malippara Service Co-Operative Bank Ltd.", label: "Malippara Service Co-Operative Bank Ltd." },
  { value: "Malkapur Co Op Credit Society", label: "Malkapur Co Op Credit Society" },
  { value: "Malkapur Urabn Co-Op Credit Society Ltd", label: "Malkapur Urabn Co-Op Credit Society Ltd" },
  { value: "Malkapur Urban Co-Op Bank Ltd", label: "Malkapur Urban Co-Op Bank Ltd" },
  { value: "Mallai Sasthra", label: "Mallai Sasthra" },
  { value: "Mallapur Urban Co-Op Bank Ltd", label: "Mallapur Urban Co-Op Bank Ltd" },
  { value: "Malleshwari Urban Development Co-Op Bank Ltd", label: "Malleshwari Urban Development Co-Op Bank Ltd" },
  { value: "Malleswaran Co-Op Bank", label: "Malleswaran Co-Op Bank" },
  { value: "Mallik Financiers", label: "Mallik Financiers" },
  { value: "Mallikarjun Multipurpose Souhard Sahakari Limited", label: "Mallikarjun Multipurpose Souhard Sahakari Limited" },
  { value: "Mallikarjun Urban Co-Operative Credit Society Ltd", label: "Mallikarjun Urban Co-Operative Credit Society Ltd" },
  { value: "Mallikarjuna Auto Finance", label: "Mallikarjuna Auto Finance" },
  { value: "Maloo Finance Co, Nagpur", label: "Maloo Finance Co, Nagpur" },
  { value: "Maloo Motor Finance", label: "Maloo Motor Finance" },
  { value: "Malshej Nagari Sah Pat Maryadit", label: "Malshej Nagari Sah Pat Maryadit" },
  { value: "Malti Salhotra", label: "Malti Salhotra" },
  { value: "Malviya Urban Co Opreative Bank", label: "Malviya Urban Co Opreative Bank" },
  { value: "Malviya Urban Co. Bank Ltd.", label: "Malviya Urban Co. Bank Ltd." },
  { value: "Malwa (Ludhiana) Motor Finance Pvt. Ltd", label: "Malwa (Ludhiana) Motor Finance Pvt. Ltd" },
  { value: "Malwa Automobiles", label: "Malwa Automobiles" },
  { value: "Malwa Commercial Co-Op Bank", label: "Malwa Commercial Co-Op Bank" },
  { value: "Malwa Fin Co", label: "Malwa Fin Co" },
  { value: "Malwa Gramin Bank", label: "Malwa Gramin Bank" },
  { value: "Malwa Gramin Bank", label: "Malwa Gramin Bank" },
  { value: "Malwa Leasing And Credits (P) Ltd.", label: "Malwa Leasing And Credits (P) Ltd." },
  { value: "Malyadri Auto Financiers", label: "Malyadri Auto Financiers" },
  { value: "Mama Finance Corp Ltd", label: "Mama Finance Corp Ltd" },
  { value: "Mama Saheb Khandage Pathsanstha", label: "Mama Saheb Khandage Pathsanstha" },
  { value: "Mamala Ser.Co-Op Bank Ltd,", label: "Mamala Ser.Co-Op Bank Ltd," },
  { value: "Mamala Service Co-Operative Bank Ltd", label: "Mamala Service Co-Operative Bank Ltd" },
  { value: "Mamasaheb Khandage Path Sanstha Maryadit", label: "Mamasaheb Khandage Path Sanstha Maryadit" },
  { value: "Mamatha Hi Purchase", label: "Mamatha Hi Purchase" },
  { value: "Mamidi Niranjan", label: "Mamidi Niranjan" },
  { value: "Mampad Panchayat Vanitha Sahak Sangham L", label: "Mampad Panchayat Vanitha Sahak Sangham L" },
  { value: "Mamta Finance", label: "Mamta Finance" },
  { value: "Mamta Gupta", label: "Mamta Gupta" },
  { value: "Mamta Singh", label: "Mamta Singh" },
  { value: "Managing Director Guj Spl Co Div Corp.", label: "Managing Director Guj Spl Co Div Corp." },
  { value: "Manak Financier", label: "Manak Financier" },
  { value: "Manal Moi Ads", label: "Manal Moi Ads" },
  { value: "Manali Primary Agriculture Coop Bkl.", label: "Manali Primary Agriculture Coop Bkl." },
  { value: "Manamalar Nithi Yagam", label: "Manamalar Nithi Yagam" },
  { value: "Manampathy Primary Agl. Coop Bank,", label: "Manampathy Primary Agl. Coop Bank," },
  { value: "Manampoor Service Coop Bank Ltd.,", label: "Manampoor Service Coop Bank Ltd.," },
  { value: "Manan Enterprise", label: "Manan Enterprise" },
  { value: "Mananje V S S Bank Ltd", label: "Mananje V S S Bank Ltd" },
  { value: "Manappuram Asset Finance Ltd", label: "Manappuram Asset Finance Ltd" },
  { value: "Manappuram Finance", label: "Manappuram Finance" },
  { value: "Manappuram Finance And Leasing Ltd.", label: "Manappuram Finance And Leasing Ltd." },
  { value: "Manappuram General Finance And Leasing Ltd.", label: "Manappuram General Finance And Leasing Ltd." },
  { value: "Manappuram General Finance Ltd", label: "Manappuram General Finance Ltd" },
  { value: "Manappuram Insurance Broker", label: "Manappuram Insurance Broker" },
  { value: "Manapuram General Finance And Leasing Ltd", label: "Manapuram General Finance And Leasing Ltd" },
  { value: "Manapuram General Finance Leasing  Ltd", label: "Manapuram General Finance Leasing  Ltd" },
  { value: "Manasa Bank", label: "Manasa Bank" },
  { value: "Manasa Co-Op Urban Bank Ltd", label: "Manasa Co-Op Urban Bank Ltd" },
  { value: "Manasi Enterprises", label: "Manasi Enterprises" },
  { value: "Manasi Finance", label: "Manasi Finance" },
  { value: "Manav Motors", label: "Manav Motors" },
  { value: "Manba Finance Pvt Ltd.", label: "Manba Finance Pvt Ltd." },
  { value: "Manba Finance Pvt Td", label: "Manba Finance Pvt Td" },
  { value: "Mand Finance Ltd", label: "Mand Finance Ltd" },
  { value: "Mand M Fin Service Ltd", label: "Mand M Fin Service Ltd" },
  { value: "Mandal Nagrik Sahakari Bank", label: "Mandal Nagrik Sahakari Bank" },
  { value: "Mandala Balaghat Khetriya G Bank, Sadra", label: "Mandala Balaghat Khetriya G Bank, Sadra" },
  { value: "Mandalgram", label: "Mandalgram" },
  { value: "Mandapeta Co-Optown Bank Ltd", label: "Mandapeta Co-Optown Bank Ltd" },
  { value: "Mandavgan Farata Karyakari Seva Sahakari Sanstha", label: "Mandavgan Farata Karyakari Seva Sahakari Sanstha" },
  { value: "Mandeshi Co-Operative Mahila Bank", label: "Mandeshi Co-Operative Mahila Bank" },
  { value: "Mandhana Financiers", label: "Mandhana Financiers" },
  { value: "Mandhana Leasing Ltd", label: "Mandhana Leasing Ltd" },
  { value: "Mandi Urban Co-Op. Bank Ltd.", label: "Mandi Urban Co-Op. Bank Ltd." },
  { value: "Mandla Balaghat Kshetriya Gra Bk", label: "Mandla Balaghat Kshetriya Gra Bk" },
  { value: "Mandla Balaghat Kshetriya Gramin Bank Ltd", label: "Mandla Balaghat Kshetriya Gramin Bank Ltd" },
  { value: "Mandla-Balaghat Kshetriya Gramin Bank", label: "Mandla-Balaghat Kshetriya Gramin Bank" },
  { value: "Mandm Finser Ltd", label: "Mandm Finser Ltd" },
  { value: "Mandot Finance Co", label: "Mandot Finance Co" },
  { value: "Mandsaur District Central Co-Op Bank Ltd", label: "Mandsaur District Central Co-Op Bank Ltd" },
  { value: "Mandvi Co-Op Bank", label: "Mandvi Co-Op Bank" },
  { value: "Mandvi Co-Op Bank Ltd., Mumbai", label: "Mandvi Co-Op Bank Ltd., Mumbai" },
  { value: "Mandvi Co-Op. Bank", label: "Mandvi Co-Op. Bank" },
  { value: "Mandvi Merchantile Co-Op Bank Ltd", label: "Mandvi Merchantile Co-Op Bank Ltd" },
  { value: "Mandya City Co-Op Bank Ltd", label: "Mandya City Co-Op Bank Ltd" },
  { value: "Mandya Dist. Cent. Co. Op. Bank Ltd", label: "Mandya Dist. Cent. Co. Op. Bank Ltd" },
  { value: "Mandya District Central Co-Operative Bank Ltd.", label: "Mandya District Central Co-Operative Bank Ltd." },
  { value: "Mandya National Paper Mills Ltd.", label: "Mandya National Paper Mills Ltd." },
  { value: "Mane Master Co-Op Credit Society Ltd", label: "Mane Master Co-Op Credit Society Ltd" },
  { value: "Maneed Service Co Bank Ltd,", label: "Maneed Service Co Bank Ltd," },
  { value: "Maneesha Auto Financiers", label: "Maneesha Auto Financiers" },
  { value: "Manekchowk Co-Op Bank Ltd.", label: "Manekchowk Co-Op Bank Ltd." },
  { value: "Manekvada Seva Sahkari Mandli Ltd", label: "Manekvada Seva Sahkari Mandli Ltd" },
  { value: "Manekwada Seva Sahakari Mandali Ltd", label: "Manekwada Seva Sahakari Mandali Ltd" },
  { value: "Mangaidevi Sahakari Pat Ltd", label: "Mangaidevi Sahakari Pat Ltd" },
  { value: "Mangal Bank", label: "Mangal Bank" },
  { value: "Mangal Chand Bohra", label: "Mangal Chand Bohra" },
  { value: "Mangal Co-Op Bank Ltd", label: "Mangal Co-Op Bank Ltd" },
  { value: "Mangal Co-Op Bank Ltd.", label: "Mangal Co-Op Bank Ltd." },
  { value: "Mangal Finance", label: "Mangal Finance" },
  { value: "Mangal Murti Nagri Sah Pat Sanstha", label: "Mangal Murti Nagri Sah Pat Sanstha" },
  { value: "Mangal Tractors", label: "Mangal Tractors" },
  { value: "Mangal Wedha Uran Bank", label: "Mangal Wedha Uran Bank" },
  { value: "Mangala Credit Co-Operative Society Ltd.", label: "Mangala Credit Co-Operative Society Ltd." },
  { value: "Mangala Finance", label: "Mangala Finance" },
  { value: "Mangalam Finance", label: "Mangalam Finance" },
  { value: "Mangalam Motors", label: "Mangalam Motors" },
  { value: "Mangalambiga Auto Finance", label: "Mangalambiga Auto Finance" },
  { value: "Mangalambiga Motor Finance", label: "Mangalambiga Motor Finance" },
  { value: "Mangalambika Finance", label: "Mangalambika Finance" },
  { value: "Mangalambikai Finance", label: "Mangalambikai Finance" },
  { value: "Mangalchand & Sons", label: "Mangalchand & Sons" },
  { value: "Mangaldeep Enterprises", label: "Mangaldeep Enterprises" },
  { value: "Mangaldeep Fin.Pvt.Ltd.", label: "Mangaldeep Fin.Pvt.Ltd." },
  { value: "Mangaldevi Sahakari Patsantha Ltd", label: "Mangaldevi Sahakari Patsantha Ltd" },
  { value: "Mangalore Catholic Co-Op Bank Ltd., Mangalore", label: "Mangalore Catholic Co-Op Bank Ltd., Mangalore" },
  { value: "Mangalore Catholic Co-Operative Bank Ltd", label: "Mangalore Catholic Co-Operative Bank Ltd" },
  { value: "Mangalwedha Lokmangal Gramin Bigarsheti Sah. Pat. Sanstha.", label: "Mangalwedha Lokmangal Gramin Bigarsheti Sah. Pat. Sanstha." },
  { value: "Mangalwedha Urban Co-Op Bank Ltd", label: "Mangalwedha Urban Co-Op Bank Ltd" },
  { value: "Mangalya Motors", label: "Mangalya Motors" },
  { value: "Manganese Ore (India) Ltd.", label: "Manganese Ore (India) Ltd." },
  { value: "Manganga Sahakari Pathpedhi Ltd", label: "Manganga Sahakari Pathpedhi Ltd" },
  { value: "Manganur Sawantwadi Co Op Credit Soc", label: "Manganur Sawantwadi Co Op Credit Soc" },
  { value: "Mangaon Nag Sah Pat Sanstha Maryadit Mangaon", label: "Mangaon Nag Sah Pat Sanstha Maryadit Mangaon" },
  { value: "Manglam Enterprises (P) Ltd", label: "Manglam Enterprises (P) Ltd" },
  { value: "Manglam Finance Corporation", label: "Manglam Finance Corporation" },
  { value: "Mango Securities Ltd.", label: "Mango Securities Ltd." },
  { value: "Mangotra Motor Finance Co.", label: "Mangotra Motor Finance Co." },
  { value: "Manhar Finance", label: "Manhar Finance" },
  { value: "Manhar Finance Pvt. Ltd", label: "Manhar Finance Pvt. Ltd" },
  { value: "Mani Bhadra Investment", label: "Mani Bhadra Investment" },
  { value: "Mani Venkata Sai Finance", label: "Mani Venkata Sai Finance" },
  { value: "Manibhadra  Enterprise", label: "Manibhadra  Enterprise" },
  { value: "Manibhadra Enterprise.", label: "Manibhadra Enterprise." },
  { value: "Manibhai Desai Gramin Sahakari Path Sanstha Maryadit", label: "Manibhai Desai Gramin Sahakari Path Sanstha Maryadit" },
  { value: "Manickpur Urban Co-Op Credit Soc Ltd", label: "Manickpur Urban Co-Op Credit Soc Ltd" },
  { value: "Manickpur Urban Co-Op Credit Society Ltd", label: "Manickpur Urban Co-Op Credit Society Ltd" },
  { value: "Manidhari & Manidhari Finance", label: "Manidhari & Manidhari Finance" },
  { value: "Manidhari Automobile Finance", label: "Manidhari Automobile Finance" },
  { value: "Manidhari Automobiles Finance", label: "Manidhari Automobiles Finance" },
  { value: "Manikanath Leasing ( R )", label: "Manikanath Leasing ( R )" },
  { value: "Manikandan B", label: "Manikandan B" },
  { value: "Manikant Mator Credits", label: "Manikant Mator Credits" },
  { value: "Manikanta Auto Finance", label: "Manikanta Auto Finance" },
  { value: "Manikanta Hair Purches", label: "Manikanta Hair Purches" },
  { value: "Manikanta Hp And Finance", label: "Manikanta Hp And Finance" },
  { value: "Manikanta Hp And Leasing Finance", label: "Manikanta Hp And Leasing Finance" },
  { value: "Manikanta Motor Credits", label: "Manikanta Motor Credits" },
  { value: "Manikpur Urban Co Op Cr Soc Ltd", label: "Manikpur Urban Co Op Cr Soc Ltd" },
  { value: "Manikpur Urban Co-Op Bank Ltd", label: "Manikpur Urban Co-Op Bank Ltd" },
  { value: "Manimajra Motor Finance Ltd.", label: "Manimajra Motor Finance Ltd." },
  { value: "Maninagar Co Op Bank Ltd", label: "Maninagar Co Op Bank Ltd" },
  { value: "Maninagar Co-Operative Bank Ltd.", label: "Maninagar Co-Operative Bank Ltd." },
  { value: "Maniokpur Urban Coop Credit Society Ltd", label: "Maniokpur Urban Coop Credit Society Ltd" },
  { value: "Manipal Co-Op Bank Ltd", label: "Manipal Co-Op Bank Ltd" },
  { value: "Manipal Co-Op. Bank Ltd", label: "Manipal Co-Op. Bank Ltd" },
  { value: "Manipal Finance Corporation Ltd", label: "Manipal Finance Corporation Ltd" },
  { value: "Manipur Industrial Development Corporation", label: "Manipur Industrial Development Corporation" },
  { value: "Manipur Rural Bank", label: "Manipur Rural Bank" },
  { value: "Manipur State Central Co-Oprative Bank Ltd.", label: "Manipur State Central Co-Oprative Bank Ltd." },
  { value: "Manipur State Co - Op Bank Ltd", label: "Manipur State Co - Op Bank Ltd" },
  { value: "Manipur State Drugs And Pharmaceuticals Ltd.", label: "Manipur State Drugs And Pharmaceuticals Ltd." },
  { value: "Manipur State Tourism Development Corporation Ltd.", label: "Manipur State Tourism Development Corporation Ltd." },
  { value: "Manipur Urban Development Agency", label: "Manipur Urban Development Agency" },
  { value: "Maniraja Investment", label: "Maniraja Investment" },
  { value: "Manish Auto Finance", label: "Manish Auto Finance" },
  { value: "Manish Auto Lines", label: "Manish Auto Lines" },
  { value: "Manish Baid Financier", label: "Manish Baid Financier" },
  { value: "Manish Enterprises", label: "Manish Enterprises" },
  { value: "Manish Finance & Co", label: "Manish Finance & Co" },
  { value: "Manish Finance Company", label: "Manish Finance Company" },
  { value: "Manish Financial Services", label: "Manish Financial Services" },
  { value: "Manish Goel", label: "Manish Goel" },
  { value: "Manish Kumar", label: "Manish Kumar" },
  { value: "Manish Kumar Kothari", label: "Manish Kumar Kothari" },
  { value: "Manish Ostwal", label: "Manish Ostwal" },
  { value: "Manisha Financiers", label: "Manisha Financiers" },
  { value: "Manisha Jain", label: "Manisha Jain" },
  { value: "Manjapra Auto Finance", label: "Manjapra Auto Finance" },
  { value: "Manjara Mahila Co-Op Bank Ltd", label: "Manjara Mahila Co-Op Bank Ltd" },
  { value: "Manjeet Auto Consultant & Finance", label: "Manjeet Auto Consultant & Finance" },
  { value: "Manjeet Auto Sales", label: "Manjeet Auto Sales" },
  { value: "Manjeri Co-Op Urban Bank Ltd", label: "Manjeri Co-Op Urban Bank Ltd" },
  { value: "Manjeri Co-Op Urban Bank Ltd,", label: "Manjeri Co-Op Urban Bank Ltd," },
  { value: "Manjira Finance Ltd.", label: "Manjira Finance Ltd." },
  { value: "Manjit Agro Works", label: "Manjit Agro Works" },
  { value: "Manjra Mahila Urban Co-Op Bank L", label: "Manjra Mahila Urban Co-Op Bank L" },
  { value: "Manju", label: "Manju" },
  { value: "Manju B Williams", label: "Manju B Williams" },
  { value: "Manju Enterprise", label: "Manju Enterprise" },
  { value: "Manju Finance", label: "Manju Finance" },
  { value: "Manju Investements", label: "Manju Investements" },
  { value: "Manju Jain", label: "Manju Jain" },
  { value: "Manju Jain Finance", label: "Manju Jain Finance" },
  { value: "Manju Jain Financiers", label: "Manju Jain Financiers" },
  { value: "Manju Juneja", label: "Manju Juneja" },
  { value: "Manju Kocher", label: "Manju Kocher" },
  { value: "Manjudevi Bhagmar", label: "Manjudevi Bhagmar" },
  { value: "Manjula Auto Finance Co", label: "Manjula Auto Finance Co" },
  { value: "Manjula Chordia", label: "Manjula Chordia" },
  { value: "Manjunath Hire Purchase Company", label: "Manjunath Hire Purchase Company" },
  { value: "Manjunath Y Mashetty", label: "Manjunath Y Mashetty" },
  { value: "Manjushri Finance", label: "Manjushri Finance" },
  { value: "Mankulam Service Co-Op Bank Ltd", label: "Mankulam Service Co-Op Bank Ltd" },
  { value: "Manmad Urban Co-Op Bank Ltd", label: "Manmad Urban Co-Op Bank Ltd" },
  { value: "Manmandir Co-Op Bank Ltd", label: "Manmandir Co-Op Bank Ltd" },
  { value: "Manmandir Nagari Sahakari Pat San Maryadit", label: "Manmandir Nagari Sahakari Pat San Maryadit" },
  { value: "Manmohan", label: "Manmohan" },
  { value: "Mann Corporation,Nanpura, Surat", label: "Mann Corporation,Nanpura, Surat" },
  { value: "Mann Deshi Mahila Sahakari Bank Ltd.", label: "Mann Deshi Mahila Sahakari Bank Ltd." },
  { value: "Mann Desi Mahila Sahakari Bank Ltd", label: "Mann Desi Mahila Sahakari Bank Ltd" },
  { value: "Mann Finance Leasing (P)", label: "Mann Finance Leasing (P)" },
  { value: "Mann Finance Ltd", label: "Mann Finance Ltd" },
  { value: "Mannargudi Co-Op Urban Ltd", label: "Mannargudi Co-Op Urban Ltd" },
  { value: "Manniya B J Kolse Patil Gramin Biger Sheti Sahakari Pathsantha", label: "Manniya B J Kolse Patil Gramin Biger Sheti Sahakari Pathsantha" },
  { value: "Mannpuram  General Finance", label: "Mannpuram  General Finance" },
  { value: "Mano Finance Ltd", label: "Mano Finance Ltd" },
  { value: "Manohar Co Op Credit Soc. Ltd", label: "Manohar Co Op Credit Soc. Ltd" },
  { value: "Manohar Credit & Growth Fund Ltd", label: "Manohar Credit & Growth Fund Ltd" },
  { value: "Manohar Credits", label: "Manohar Credits" },
  { value: "Manohar Credits Fin Pvt Ltd", label: "Manohar Credits Fin Pvt Ltd" },
  { value: "Manohar Finance India Ltd", label: "Manohar Finance India Ltd" },
  { value: "Manohar Shri Syndicate", label: "Manohar Shri Syndicate" },
  { value: "Manoj Auto Finance", label: "Manoj Auto Finance" },
  { value: "Manoj Finance", label: "Manoj Finance" },
  { value: "Manoj Finance Corporation", label: "Manoj Finance Corporation" },
  { value: "Manoj Investments", label: "Manoj Investments" },
  { value: "Manoj Kumar Jain Himayath Nagar", label: "Manoj Kumar Jain Himayath Nagar" },
  { value: "Manoj Kumari Finance", label: "Manoj Kumari Finance" },
  { value: "Manoj Kymar Jain Himayath", label: "Manoj Kymar Jain Himayath" },
  { value: "Manoj Motor Finance Co", label: "Manoj Motor Finance Co" },
  { value: "Manoram Auto", label: "Manoram Auto" },
  { value: "Manoram Auto", label: "Manoram Auto" },
  { value: "Manoram Auto Finance Co. Ltd.", label: "Manoram Auto Finance Co. Ltd." },
  { value: "Manoram Bajaj", label: "Manoram Bajaj" },
  { value: "Manorama Bank", label: "Manorama Bank" },
  { value: "Manorama Co-Op Bank Ltd Solapur", label: "Manorama Co-Op Bank Ltd Solapur" },
  { value: "Manorama Co-Operative Bank Ltd.", label: "Manorama Co-Operative Bank Ltd." },
  { value: "Manorama Urban Co-Op Bank Ltd.", label: "Manorama Urban Co-Op Bank Ltd." },
  { value: "Manpreet Enterprises", label: "Manpreet Enterprises" },
  { value: "Manpreet Finance Co.", label: "Manpreet Finance Co." },
  { value: "Mansa District Central Co-Operative Bank Ltd.", label: "Mansa District Central Co-Operative Bank Ltd." },
  { value: "Mansandesh Sahakari Pat. Ltd.", label: "Mansandesh Sahakari Pat. Ltd." },
  { value: "Mansar Finance Pvt Ltd", label: "Mansar Finance Pvt Ltd" },
  { value: "Mansarovar Deposit Advance Ltd", label: "Mansarovar Deposit Advance Ltd" },
  { value: "Manshi Syndicate", label: "Manshi Syndicate" },
  { value: "Mansi Finance", label: "Mansi Finance" },
  { value: "Mansimahila Nagari Sahakari Path Sanstha Maryadit", label: "Mansimahila Nagari Sahakari Path Sanstha Maryadit" },
  { value: "Mansing Co-Op Bank Ltd", label: "Mansing Co-Op Bank Ltd" },
  { value: "Mansingh Co-Op Bank Ltd.", label: "Mansingh Co-Op Bank Ltd." },
  { value: "Mansukh Financiers", label: "Mansukh Financiers" },
  { value: "Manthan Finance", label: "Manthan Finance" },
  { value: "Mantrana Finlease Pvt. Ltd.", label: "Mantrana Finlease Pvt. Ltd." },
  { value: "Manugraph Employees Cr Co Op Soc Ltd", label: "Manugraph Employees Cr Co Op Soc Ltd" },
  { value: "Manusamruti Co Op Credit Soc", label: "Manusamruti Co Op Credit Soc" },
  { value: "Manvi Pattana Sahakari Bank Ni", label: "Manvi Pattana Sahakari Bank Ni" },
  { value: "Manvi Pattana Souharda Sahakari Bank", label: "Manvi Pattana Souharda Sahakari Bank" },
  { value: "Manvi Pattana Souharda Sahakari Bank Ni.", label: "Manvi Pattana Souharda Sahakari Bank Ni." },
  { value: "Manvi-Pattana Sahakari Bank Niyamith", label: "Manvi-Pattana Sahakari Bank Niyamith" },
  { value: "Map Credit And Capital Limited", label: "Map Credit And Capital Limited" },
  { value: "Mapusa Urban Co-Op. Bank Ltd., Mapusa, Goa", label: "Mapusa Urban Co-Op. Bank Ltd., Mapusa, Goa" },
  { value: "Mar Ivanios College Co-Operative Society", label: "Mar Ivanios College Co-Operative Society" },
  { value: "Maradu Service Co-Operative Bank Ltd.", label: "Maradu Service Co-Operative Bank Ltd." },
  { value: "Marampally Services Coop Bank Ltd.", label: "Marampally Services Coop Bank Ltd." },
  { value: "Marana Lease And Finance", label: "Marana Lease And Finance" },
  { value: "Maranalloor Ksheera Vyavasa Sahaksanghom", label: "Maranalloor Ksheera Vyavasa Sahaksanghom" },
  { value: "Marangattupally Service Co-Op Bank", label: "Marangattupally Service Co-Op Bank" },
  { value: "Maratha Co-Op Urban Bank Ltd", label: "Maratha Co-Op Urban Bank Ltd" },
  { value: "Maratha Co-Op. Urban Bank Ltd.", label: "Maratha Co-Op. Urban Bank Ltd." },
  { value: "Maratha Sahkari Bank Ltd", label: "Maratha Sahkari Bank Ltd" },
  { value: "Marathwada Gramin Bank Ltd", label: "Marathwada Gramin Bank Ltd" },
  { value: "Marathwada Gramin Sahkari Pathsanstha", label: "Marathwada Gramin Sahkari Pathsanstha" },
  { value: "Marathwada Nagari Sahakari Bank Ltd", label: "Marathwada Nagari Sahakari Bank Ltd" },
  { value: "Marathwada Nagri Sahakari Bank Ltd", label: "Marathwada Nagri Sahakari Bank Ltd" },
  { value: "Marathwada Vibhag Nag Sahakari Patsantha Ltd", label: "Marathwada Vibhag Nag Sahakari Patsantha Ltd" },
  { value: "Marathwada Vibhag Nagari Sahakari Pathpedhi Ltd", label: "Marathwada Vibhag Nagari Sahakari Pathpedhi Ltd" },
  { value: "Maratwada Vibhag Nagari Sahakari Patpedhi Maryadit", label: "Maratwada Vibhag Nagari Sahakari Patpedhi Maryadit" },
  { value: "Maravanthe Badakere Vyavasa Seva Sha Bk", label: "Maravanthe Badakere Vyavasa Seva Sha Bk" },
  { value: "Maraya Nagari Sah. Patsanstha Maliwada", label: "Maraya Nagari Sah. Patsanstha Maliwada" },
  { value: "Marchant Bank", label: "Marchant Bank" },
  { value: "Marchant Co-Op Credit  Society", label: "Marchant Co-Op Credit  Society" },
  { value: "Marchants Co-Op Credit Society Ltd", label: "Marchants Co-Op Credit Society Ltd" },
  { value: "Mardhar Finance", label: "Mardhar Finance" },
  { value: "Mardhur Finance", label: "Mardhur Finance" },
  { value: "Margadarshini Finance", label: "Margadarshini Finance" },
  { value: "Margi Finance", label: "Margi Finance" },
  { value: "Margi Rudradutt Pandya", label: "Margi Rudradutt Pandya" },
  { value: "Marichi Finance And Leasing Ltd.", label: "Marichi Finance And Leasing Ltd." },
  { value: "Marigold Capital Management Limited", label: "Marigold Capital Management Limited" },
  { value: "Mario Two Wheeler Finance", label: "Mario Two Wheeler Finance" },
  { value: "Mariyal Finance", label: "Mariyal Finance" },
  { value: "Markanday Nagari Sah Bank Ltd", label: "Markanday Nagari Sah Bank Ltd" },
  { value: "Markandey Nagari Sahakari Bank Ltd", label: "Markandey Nagari Sahakari Bank Ltd" },
  { value: "Markandeya Co-Op Cr Society Ltd.", label: "Markandeya Co-Op Cr Society Ltd." },
  { value: "Markandeya Mahila Multiperpose Co Op Society Ltd", label: "Markandeya Mahila Multiperpose Co Op Society Ltd" },
  { value: "Markanja Sahakari Vyavasaika Bank Ltd", label: "Markanja Sahakari Vyavasaika Bank Ltd" },
  { value: "Market Yard Commercial Co-Op Bank Ltd", label: "Market Yard Commercial Co-Op Bank Ltd" },
  { value: "Marketyard Commercial Co-Op. Bank Ltd.", label: "Marketyard Commercial Co-Op. Bank Ltd." },
  { value: "Markhandeshwara Co-Operative Credit Society", label: "Markhandeshwara Co-Operative Credit Society" },
  { value: "Marlecha Financiers", label: "Marlecha Financiers" },
  { value: "Mars Films Pvt Ltd", label: "Mars Films Pvt Ltd" },
  { value: "Marss Finance", label: "Marss Finance" },
  { value: "Martand Nag Sah Pat Sah Ltd", label: "Martand Nag Sah Pat Sah Ltd" },
  { value: "Martha Sena Sangh Nagari Sah Patsanstha Maryadit", label: "Martha Sena Sangh Nagari Sah Patsanstha Maryadit" },
  { value: "Maru Enterpricess", label: "Maru Enterpricess" },
  { value: "Marudhar Enterprises", label: "Marudhar Enterprises" },
  { value: "Marudhar Finance", label: "Marudhar Finance" },
  { value: "Marudhar Finance", label: "Marudhar Finance" },
  { value: "Marudhar Keshetriya Gramin Bank", label: "Marudhar Keshetriya Gramin Bank" },
  { value: "Marudhar Kshetriya Gramin Bank", label: "Marudhar Kshetriya Gramin Bank" },
  { value: "Marudhar Trading Co", label: "Marudhar Trading Co" },
  { value: "Maruthar Finance", label: "Maruthar Finance" },
  { value: "Maruthi Enterprises", label: "Maruthi Enterprises" },
  { value: "Maruthi Finance", label: "Maruthi Finance" },
  { value: "Maruthi Finance Corporation", label: "Maruthi Finance Corporation" },
  { value: "Maruthi Financial Services", label: "Maruthi Financial Services" },
  { value: "Maruthi Investments", label: "Maruthi Investments" },
  { value: "Maruthi Motor Finance", label: "Maruthi Motor Finance" },
  { value: "Maruthi Primary Agriculture Co.Op Credit Society Ltd", label: "Maruthi Primary Agriculture Co.Op Credit Society Ltd" },
  { value: "Maruthi Suzuki India Ltd.", label: "Maruthi Suzuki India Ltd." },
  { value: "Maruthia Enterprises", label: "Maruthia Enterprises" },
  { value: "Maruthu Group Auto Finance", label: "Maruthu Group Auto Finance" },
  { value: "Maruti And Maruti Financial Services Ltd.", label: "Maruti And Maruti Financial Services Ltd." },
  { value: "Maruti Auto Finance", label: "Maruti Auto Finance" },
  { value: "Maruti Auto Financial Services", label: "Maruti Auto Financial Services" },
  { value: "Maruti Bank", label: "Maruti Bank" },
  { value: "Maruti Con Fin. Ser. Ltd.", label: "Maruti Con Fin. Ser. Ltd." },
  { value: "Maruti Cont. Auto. Fin. P. Ltd", label: "Maruti Cont. Auto. Fin. P. Ltd" },
  { value: "Maruti Contrywide Auto Fnancial Ltd", label: "Maruti Contrywide Auto Fnancial Ltd" },
  { value: "Maruti Country Wide Ltd.", label: "Maruti Country Wide Ltd." },
  { value: "Maruti Countrywide Auto Finance", label: "Maruti Countrywide Auto Finance" },
  { value: "Maruti Countrywide Auto Financial Services Ltd", label: "Maruti Countrywide Auto Financial Services Ltd" },
  { value: "Maruti Countrywide Auto Financial Services Pvt Ltd", label: "Maruti Countrywide Auto Financial Services Pvt Ltd" },
  { value: "Maruti Countrywide Consumer Finance Srevices Ltd", label: "Maruti Countrywide Consumer Finance Srevices Ltd" },
  { value: "Maruti Countrywide Financial Services Ltd", label: "Maruti Countrywide Financial Services Ltd" },
  { value: "Maruti Finance Ltd.", label: "Maruti Finance Ltd." },
  { value: "Maruti Financier", label: "Maruti Financier" },
  { value: "Maruti Gramin Big Sheti Sahakari Path Sanstha", label: "Maruti Gramin Big Sheti Sahakari Path Sanstha" },
  { value: "Maruti Gramin Bigar Sheti Sahakari Pathsastha Ltd", label: "Maruti Gramin Bigar Sheti Sahakari Pathsastha Ltd" },
  { value: "Maruti Group", label: "Maruti Group" },
  { value: "Maruti Lease Finance", label: "Maruti Lease Finance" },
  { value: "Maruti Malvahak Sahakari Mandali Ltd", label: "Maruti Malvahak Sahakari Mandali Ltd" },
  { value: "Maruti Sakh Sahkarita", label: "Maruti Sakh Sahkarita" },
  { value: "Maruti Udyog Ltd", label: "Maruti Udyog Ltd" },
  { value: "Maruty Udyog Ltd", label: "Maruty Udyog Ltd" },
  { value: "Marwad Ganganagar Bikaner Gramin Bank", label: "Marwad Ganganagar Bikaner Gramin Bank" },
  { value: "Marwad Ganganagar Bikaner Gramin Bank,", label: "Marwad Ganganagar Bikaner Gramin Bank," },
  { value: "Marwad Ganganager Bikaner Gramin Bank", label: "Marwad Ganganager Bikaner Gramin Bank" },
  { value: "Marwar Gramin Bank", label: "Marwar Gramin Bank" },
  { value: "Marwar Gramin Bank", label: "Marwar Gramin Bank" },
  { value: "Mary", label: "Mary" },
  { value: "Mas Finance", label: "Mas Finance" },
  { value: "Mas Finance  Services Ltd", label: "Mas Finance  Services Ltd" },
  { value: "Mas Finance Pvt Ltd", label: "Mas Finance Pvt Ltd" },
  { value: "Mas Financial Ltd", label: "Mas Financial Ltd" },
  { value: "Mas Financial Services Ltd.", label: "Mas Financial Services Ltd." },
  { value: "Mascot Project Pvt Ltd", label: "Mascot Project Pvt Ltd" },
  { value: "Mashreq Bank", label: "Mashreq Bank" },
  { value: "Maskara Credit Corp.", label: "Maskara Credit Corp." },
  { value: "Maskara Finvest Pvt Ltd.", label: "Maskara Finvest Pvt Ltd." },
  { value: "Masma Fin Corp Ltd.", label: "Masma Fin Corp Ltd." },
  { value: "Master Co-Op Credit Society Ltd", label: "Master Co-Op Credit Society Ltd" },
  { value: "Master Leasing Limited", label: "Master Leasing Limited" },
  { value: "Master Trust Ltd", label: "Master Trust Ltd" },
  { value: "Master World Travel And Forex Company Ltd", label: "Master World Travel And Forex Company Ltd" },
  { value: "Masti Leasing And Financing Co.Pvt.Ltd.", label: "Masti Leasing And Financing Co.Pvt.Ltd." },
  { value: "Mastya Feid", label: "Mastya Feid" },
  { value: "Mata Premkuvardavi B Sheti Sah. Sah Patsantha", label: "Mata Premkuvardavi B Sheti Sah. Sah Patsantha" },
  { value: "Mata Vaishno Finance", label: "Mata Vaishno Finance" },
  { value: "Matar Nagrik Sahakari Bank Ltd", label: "Matar Nagrik Sahakari Bank Ltd" },
  { value: "Matawadi Urben Co Op Cr So Ltd", label: "Matawadi Urben Co Op Cr So Ltd" },
  { value: "Matawadi Urben Co Op Cr So.Ltd", label: "Matawadi Urben Co Op Cr So.Ltd" },
  { value: "Matel Lease Finance", label: "Matel Lease Finance" },
  { value: "Mateshwari And Co", label: "Mateshwari And Co" },
  { value: "Matha Finance", label: "Matha Finance" },
  { value: "Mathaji Auto Financeesr", label: "Mathaji Auto Financeesr" },
  { value: "Mathirappilly Service Co-Op. Bank Ltd.", label: "Mathirappilly Service Co-Op. Bank Ltd." },
  { value: "Mathruboomi Bank", label: "Mathruboomi Bank" },
  { value: "Mathura District Co-Op Bank Ltd", label: "Mathura District Co-Op Bank Ltd" },
  { value: "Mathura Distriet Co-Op Bank L", label: "Mathura Distriet Co-Op Bank L" },
  { value: "Mathura Zila Sahakari Bank Ltd.", label: "Mathura Zila Sahakari Bank Ltd." },
  { value: "Matoshree Mahila Bank", label: "Matoshree Mahila Bank" },
  { value: "Matoshri Bank", label: "Matoshri Bank" },
  { value: "Matoshri Mahila Sah. Pat. Sanstha", label: "Matoshri Mahila Sah. Pat. Sanstha" },
  { value: "Matrix Insurance Services", label: "Matrix Insurance Services" },
  { value: "Matruka Finance", label: "Matruka Finance" },
  { value: "Matsya Feed", label: "Matsya Feed" },
  { value: "Matsyafed", label: "Matsyafed" },
  { value: "Mattancherry Mahajanik Co-Op Bank Ltd", label: "Mattancherry Mahajanik Co-Op Bank Ltd" },
  { value: "Mattannur Co-Operative Rural Bank Ltd", label: "Mattannur Co-Operative Rural Bank Ltd" },
  { value: "Maulana Azad Alpasankhyank Arthik Vikas Mahamandal Maryadit", label: "Maulana Azad Alpasankhyank Arthik Vikas Mahamandal Maryadit" },
  { value: "Mauli Gramin Bigar Sheti Sah Path", label: "Mauli Gramin Bigar Sheti Sah Path" },
  { value: "Mauli Kripa Co Op Bank", label: "Mauli Kripa Co Op Bank" },
  { value: "Mauli Krupa Gramin Bigarsheti Sahakari Patsanstha Maryadit", label: "Mauli Krupa Gramin Bigarsheti Sahakari Patsanstha Maryadit" },
  { value: "Mauli Nagri Sahakari Pathsanstha Maryadit", label: "Mauli Nagri Sahakari Pathsanstha Maryadit" },
  { value: "Maullana Azad Nagari Sahkari Path Sanstha", label: "Maullana Azad Nagari Sahkari Path Sanstha" },
  { value: "Mautha Finance", label: "Mautha Finance" },
  { value: "Maval Nag Sah Pat Sanstha", label: "Maval Nag Sah Pat Sanstha" },
  { value: "Mavanna Finance Company", label: "Mavanna Finance Company" },
  { value: "Max New York Life Insurance", label: "Max New York Life Insurance" },
  { value: "Max Project India Limited", label: "Max Project India Limited" },
  { value: "Maya Financer", label: "Maya Financer" },
  { value: "Mayani Urban Co-Op Bank Ltd", label: "Mayani Urban Co-Op Bank Ltd" },
  { value: "Mayank Financiers", label: "Mayank Financiers" },
  { value: "Mayar Finance Limited", label: "Mayar Finance Limited" },
  { value: "Mayrashtra Auto Fin Ltd", label: "Mayrashtra Auto Fin Ltd" },
  { value: "Mayur Finance Corporation", label: "Mayur Finance Corporation" },
  { value: "Mayur Financiers", label: "Mayur Financiers" },
  { value: "Mayur Gr Bigar Sheti Sah Patsanstha", label: "Mayur Gr Bigar Sheti Sah Patsanstha" },
  { value: "Mayur Nagri  Sahakari Pathsanstha", label: "Mayur Nagri  Sahakari Pathsanstha" },
  { value: "Mayurakashi Gramin Bank Ltd", label: "Mayurakashi Gramin Bank Ltd" },
  { value: "Mayurakshi Gramin Bank", label: "Mayurakshi Gramin Bank" },
  { value: "Mayuram Associates", label: "Mayuram Associates" },
  { value: "Mayurbhanj District Central Co-Operative Bank Ltd.", label: "Mayurbhanj District Central Co-Operative Bank Ltd." },
  { value: "Mayureshwar Sahakari Pathasanstha", label: "Mayureshwar Sahakari Pathasanstha" },
  { value: "Mayyanad Regional Co-Op Bank Ltd.", label: "Mayyanad Regional Co-Op Bank Ltd." },
  { value: "Mazagon Dock Ltd.", label: "Mazagon Dock Ltd." },
  { value: "Mc Dowell And Co Ltd", label: "Mc Dowell And Co Ltd" },
  { value: "Mcc Bank", label: "Mcc Bank" },
  { value: "Mcf Finlease P. Ltd", label: "Mcf Finlease P. Ltd" },
  { value: "Mcrbm", label: "Mcrbm" },
  { value: "Mcsl", label: "Mcsl" },
  { value: "Md Gujarat Backward Class Development Corporation", label: "Md Gujarat Backward Class Development Corporation" },
  { value: "Mdcc Bank", label: "Mdcc Bank" },
  { value: "Meda Finance Ltd.", label: "Meda Finance Ltd." },
  { value: "Medak District Central Co-Operative Bank Ltd.", label: "Medak District Central Co-Operative Bank Ltd." },
  { value: "Medpat Finance Ltd.", label: "Medpat Finance Ltd." },
  { value: "Meena Auto Finance", label: "Meena Auto Finance" },
  { value: "Meena Auto Finance & Leasing", label: "Meena Auto Finance & Leasing" },
  { value: "Meena Toshniwal", label: "Meena Toshniwal" },
  { value: "Meenabai Auto Finance", label: "Meenabai Auto Finance" },
  { value: "Meenachil Co:Op Agri:&Rural Development Bank Ltd", label: "Meenachil Co:Op Agri:&Rural Development Bank Ltd" },
  { value: "Meenachil East Urban Co-Op Bank", label: "Meenachil East Urban Co-Op Bank" },
  { value: "Meenachil East Urban Co-Op Bank Ltd", label: "Meenachil East Urban Co-Op Bank Ltd" },
  { value: "Meenakshi And Co", label: "Meenakshi And Co" },
  { value: "Meenakshi Finance Corporation", label: "Meenakshi Finance Corporation" },
  { value: "Meenakshi Jain", label: "Meenakshi Jain" },
  { value: "Meenam Auto Finance", label: "Meenam Auto Finance" },
  { value: "Meenaxi Finance Corp", label: "Meenaxi Finance Corp" },
  { value: "Meet Auto Deals", label: "Meet Auto Deals" },
  { value: "Meet Finance Co.", label: "Meet Finance Co." },
  { value: "Megha Finance", label: "Megha Finance" },
  { value: "Megha Mala Auto Mobile Finance", label: "Megha Mala Auto Mobile Finance" },
  { value: "Meghalaya State Central Co-Oprative Bank Ltd.", label: "Meghalaya State Central Co-Oprative Bank Ltd." },
  { value: "Meghalaya State Electricity Board", label: "Meghalaya State Electricity Board" },
  { value: "Meghalaya State Tourism Development Corporation Ltd.", label: "Meghalaya State Tourism Development Corporation Ltd." },
  { value: "Meghdooth Motor", label: "Meghdooth Motor" },
  { value: "Meghna Investments", label: "Meghna Investments" },
  { value: "Megma Finance", label: "Megma Finance" },
  { value: "Megma Finance Corp Ltd", label: "Megma Finance Corp Ltd" },
  { value: "Megma Finance Ltd", label: "Megma Finance Ltd" },
  { value: "Megma India Finance Ltd.", label: "Megma India Finance Ltd." },
  { value: "Megma Leasing Ltd", label: "Megma Leasing Ltd" },
  { value: "Megma Sharachi Fin. Ltd.", label: "Megma Sharachi Fin. Ltd." },
  { value: "Mehaboob Chullikkal", label: "Mehaboob Chullikkal" },
  { value: "Mehak Finance Co", label: "Mehak Finance Co" },
  { value: "Mehanati Kishan Samabay Krishi Unnayan Samiti Ltd", label: "Mehanati Kishan Samabay Krishi Unnayan Samiti Ltd" },
  { value: "Mehandra&Mehandra", label: "Mehandra&Mehandra" },
  { value: "Mehar Auto Finance", label: "Mehar Auto Finance" },
  { value: "Mehar Gramin Bigar Sheti Sahakari Pat Sanstha", label: "Mehar Gramin Bigar Sheti Sahakari Pat Sanstha" },
  { value: "Mehar Nagari Bigar Sheti Sahakari", label: "Mehar Nagari Bigar Sheti Sahakari" },
  { value: "Mehar Nagari Sahakari Pathsanstha Ltd", label: "Mehar Nagari Sahakari Pathsanstha Ltd" },
  { value: "Mehar Singh", label: "Mehar Singh" },
  { value: "Mehatha Finance", label: "Mehatha Finance" },
  { value: "Mehbubnagar District Central Co-Operative Bank Ltd.", label: "Mehbubnagar District Central Co-Operative Bank Ltd." },
  { value: "Mehendra Kumar Roy", label: "Mehendra Kumar Roy" },
  { value: "Meher Nagari Gramin Bigg", label: "Meher Nagari Gramin Bigg" },
  { value: "Mehkar Urban Co-Op Bank Ltd", label: "Mehkar Urban Co-Op Bank Ltd" },
  { value: "Mehmara Urban Co-Op Bank Ltd", label: "Mehmara Urban Co-Op Bank Ltd" },
  { value: "Mehnaz Faizan", label: "Mehnaz Faizan" },
  { value: "Mehsana Co Op Bank", label: "Mehsana Co Op Bank" },
  { value: "Mehsana District Central Co-Operative Bank Ltd.", label: "Mehsana District Central Co-Operative Bank Ltd." },
  { value: "Mehsana Gramin Bank Ltd", label: "Mehsana Gramin Bank Ltd" },
  { value: "Mehsana Mahila Sahakari Bank Ltd", label: "Mehsana Mahila Sahakari Bank Ltd" },
  { value: "Mehsana Nagrik Sahakari Bank Ltd", label: "Mehsana Nagrik Sahakari Bank Ltd" },
  { value: "Mehsana Urban Co Op Bank Ltd", label: "Mehsana Urban Co Op Bank Ltd" },
  { value: "Mehsana Urban Co Op Bank Ltd", label: "Mehsana Urban Co Op Bank Ltd" },
  { value: "Mehsana Urban Co-Op Bank Ltd., Ahmedabad", label: "Mehsana Urban Co-Op Bank Ltd., Ahmedabad" },
  { value: "Mehta Auto Finance", label: "Mehta Auto Finance" },
  { value: "Mehta Enterprise", label: "Mehta Enterprise" },
  { value: "Mehta Finance", label: "Mehta Finance" },
  { value: "Mehta Hp Jain Financial Services Ltd", label: "Mehta Hp Jain Financial Services Ltd" },
  { value: "Mehta Syndicate", label: "Mehta Syndicate" },
  { value: "Mehul Investments", label: "Mehul Investments" },
  { value: "Meil Jammu", label: "Meil Jammu" },
  { value: "Mekkadampu Service Co-Op. Banl Ltd.", label: "Mekkadampu Service Co-Op. Banl Ltd." },
  { value: "Mel Karmachari Sah Pat Sans Marya", label: "Mel Karmachari Sah Pat Sans Marya" },
  { value: "Melody Hire Purchase & Leasing Company Limited Kottayam", label: "Melody Hire Purchase & Leasing Company Limited Kottayam" },
  { value: "Meloor Service Co-Op Bank Ltd.", label: "Meloor Service Co-Op Bank Ltd." },
  { value: "Melur Co-Op. Urban Bank Ltd. Melur", label: "Melur Co-Op. Urban Bank Ltd. Melur" },
  { value: "Melur Urban Co-Op Bank Ltd", label: "Melur Urban Co-Op Bank Ltd" },
  { value: "Memon Co-Op Bank Ltd", label: "Memon Co-Op Bank Ltd" },
  { value: "Memon Co-Op Bank Ltd., Mumbai", label: "Memon Co-Op Bank Ltd., Mumbai" },
  { value: "Memon Co-Op. Bank Ltd.", label: "Memon Co-Op. Bank Ltd." },
  { value: "Mena Investments", label: "Mena Investments" },
  { value: "Menchus Finance Pvt Ltd", label: "Menchus Finance Pvt Ltd" },
  { value: "Mendhar Kumar Bajaj", label: "Mendhar Kumar Bajaj" },
  { value: "Menom Bank.", label: "Menom Bank." },
  { value: "Menson Finance Company", label: "Menson Finance Company" },
  { value: "Mentor India Ltd", label: "Mentor India Ltd" },
  { value: "Merbanc Financial Services Ltd", label: "Merbanc Financial Services Ltd" },
  { value: "Mercantile Co-Op Bank Ltd", label: "Mercantile Co-Op Bank Ltd" },
  { value: "Merchant  Sohardha  Bank Niyamitha", label: "Merchant  Sohardha  Bank Niyamitha" },
  { value: "Merchant Co-Operative Bank Ltd", label: "Merchant Co-Operative Bank Ltd" },
  { value: "Merchant Souharda Sahakara Bank Ltd", label: "Merchant Souharda Sahakara Bank Ltd" },
  { value: "Merchants Co-Op Bank Ltd", label: "Merchants Co-Op Bank Ltd" },
  { value: "Merchants Co-Op Credit Society Ltd", label: "Merchants Co-Op Credit Society Ltd" },
  { value: "Merchants Liberal Co-Op Bank Ltd", label: "Merchants Liberal Co-Op Bank Ltd" },
  { value: "Merchants Urban Co-Op Bank Ltd", label: "Merchants Urban Co-Op Bank Ltd" },
  { value: "Mercury Associates", label: "Mercury Associates" },
  { value: "Mercury Insurance And Financial Services", label: "Mercury Insurance And Financial Services" },
  { value: "Mercury Risk Management Services", label: "Mercury Risk Management Services" },
  { value: "Metallurgical And Engineering Consultancy (India) Ltd.", label: "Metallurgical And Engineering Consultancy (India) Ltd." },
  { value: "Metha Finance", label: "Metha Finance" },
  { value: "Metha Syndicate", label: "Metha Syndicate" },
  { value: "Methala Service Co-Op Bank,", label: "Methala Service Co-Op Bank," },
  { value: "Metro Finance", label: "Metro Finance" },
  { value: "Metro Motor & Finance Co", label: "Metro Motor & Finance Co" },
  { value: "Metro Railway, Kolkata", label: "Metro Railway, Kolkata" },
  { value: "Metroford", label: "Metroford" },
  { value: "Metropolitan Co-Op Bank Ltd., Mumbai", label: "Metropolitan Co-Op Bank Ltd., Mumbai" },
  { value: "Metropolitan Development Authority", label: "Metropolitan Development Authority" },
  { value: "Mettamalai Primary Agrl.Co-Op.Bk.", label: "Mettamalai Primary Agrl.Co-Op.Bk." },
  { value: "Mettur Finance Ltd.", label: "Mettur Finance Ltd." },
  { value: "Mewar Anchalik Gramin Bank", label: "Mewar Anchalik Gramin Bank" },
  { value: "Mewar Finance", label: "Mewar Finance" },
  { value: "Mewar Finance Pvt. Ltd", label: "Mewar Finance Pvt. Ltd" },
  { value: "Mexx Investments", label: "Mexx Investments" },
  { value: "Meyor Kuber Motor Finance Ltd", label: "Meyor Kuber Motor Finance Ltd" },
  { value: "Mezhuvely Service Co-Op. Bank Ltd,", label: "Mezhuvely Service Co-Op. Bank Ltd," },
  { value: "Mgb Finlease Pvt Ltd", label: "Mgb Finlease Pvt Ltd" },
  { value: "Mgf Investment", label: "Mgf Investment" },
  { value: "Mgs Investments Pvt Ltd.", label: "Mgs Investments Pvt Ltd." },
  { value: "Mhashe Mata Mahila Gramin Patshnsta Nizampur", label: "Mhashe Mata Mahila Gramin Patshnsta Nizampur" },
  { value: "Mhs Urban Co Op Bank", label: "Mhs Urban Co Op Bank" },
  { value: "Mico Bosch", label: "Mico Bosch" },
  { value: "Micro Finance Ltd.", label: "Micro Finance Ltd." },
  { value: "Microsoft Corporation India", label: "Microsoft Corporation India" },
  { value: "Midas Motors", label: "Midas Motors" },
  { value: "Midland Financiers (Doaba) Pvt. Ltd", label: "Midland Financiers (Doaba) Pvt. Ltd" },
  { value: "Midland Motor Finance Co. Pvt. Ltd.", label: "Midland Motor Finance Co. Pvt. Ltd." },
  { value: "Midnapore Peoples Co-Op Bank Ltd.", label: "Midnapore Peoples Co-Op Bank Ltd." },
  { value: "Mik Agencies (P) Ltd.", label: "Mik Agencies (P) Ltd." },
  { value: "Miksham Rephael Reuben", label: "Miksham Rephael Reuben" },
  { value: "Milab Chand Surana & Sons", label: "Milab Chand Surana & Sons" },
  { value: "Milap Chand Surana & Sons", label: "Milap Chand Surana & Sons" },
  { value: "Milap Enterprises", label: "Milap Enterprises" },
  { value: "Milap Trading Company", label: "Milap Trading Company" },
  { value: "Milapchand Hiralal", label: "Milapchand Hiralal" },
  { value: "Milapchand Hiralall", label: "Milapchand Hiralall" },
  { value: "Millanth Co-Op. Bank Ltd.", label: "Millanth Co-Op. Bank Ltd." },
  { value: "Millat Co-Op Credit Society Ltd.", label: "Millat Co-Op Credit Society Ltd." },
  { value: "Millath Co-Op Bank Ltd", label: "Millath Co-Op Bank Ltd" },
  { value: "Millath Co-Op. Bank Ltd.", label: "Millath Co-Op. Bank Ltd." },
  { value: "Milleenium Auto Fiance", label: "Milleenium Auto Fiance" },
  { value: "Millenium Credit Co-Op Bank", label: "Millenium Credit Co-Op Bank" },
  { value: "Minalben Shivangkumar Patel", label: "Minalben Shivangkumar Patel" },
  { value: "Minar Leasing Pvt Ltd", label: "Minar Leasing Pvt Ltd" },
  { value: "Minaxiben Y Jadeja", label: "Minaxiben Y Jadeja" },
  { value: "Minda Finance Limited", label: "Minda Finance Limited" },
  { value: "Mineral Exploration Corporation Ltd.", label: "Mineral Exploration Corporation Ltd." },
  { value: "Minerals And Metals Trading Corporation Ltd.", label: "Minerals And Metals Trading Corporation Ltd." },
  { value: "Mini Auto Deals", label: "Mini Auto Deals" },
  { value: "Mini Auto Sales", label: "Mini Auto Sales" },
  { value: "Mini Bank", label: "Mini Bank" },
  { value: "Mini Bank Gram Seva Sah Sam Ltd", label: "Mini Bank Gram Seva Sah Sam Ltd" },
  { value: "Mini Sahakari Bank", label: "Mini Sahakari Bank" },
  { value: "Mining And Allied Machinery Co. Ltd.", label: "Mining And Allied Machinery Co. Ltd." },
  { value: "Ministry Of Agriculture", label: "Ministry Of Agriculture" },
  { value: "Ministry Of Chemicals And Fertilizers", label: "Ministry Of Chemicals And Fertilizers" },
  { value: "Ministry Of Civil Aviation", label: "Ministry Of Civil Aviation" },
  { value: "Ministry Of Coal And Mines", label: "Ministry Of Coal And Mines" },
  { value: "Ministry Of Commerce And Industry", label: "Ministry Of Commerce And Industry" },
  { value: "Ministry Of Communications And Information Technology", label: "Ministry Of Communications And Information Technology" },
  { value: "Ministry Of Consumer Affairs, Food And Public Distribution", label: "Ministry Of Consumer Affairs, Food And Public Distribution" },
  { value: "Ministry Of Defence", label: "Ministry Of Defence" },
  { value: "Ministry Of Disinvestment", label: "Ministry Of Disinvestment" },
  { value: "Ministry Of Environment And Forests", label: "Ministry Of Environment And Forests" },
  { value: "Ministry Of External Affairs", label: "Ministry Of External Affairs" },
  { value: "Ministry Of Finance", label: "Ministry Of Finance" },
  { value: "Ministry Of Food Processing Industries", label: "Ministry Of Food Processing Industries" },
  { value: "Ministry Of Health And Family Welfare", label: "Ministry Of Health And Family Welfare" },
  { value: "Ministry Of Heavy Industries And Public Enterprises", label: "Ministry Of Heavy Industries And Public Enterprises" },
  { value: "Ministry Of Home Affairs", label: "Ministry Of Home Affairs" },
  { value: "Ministry Of Human Resource Development", label: "Ministry Of Human Resource Development" },
  { value: "Ministry Of Information And Broadcasting", label: "Ministry Of Information And Broadcasting" },
  { value: "Ministry Of Labour", label: "Ministry Of Labour" },
  { value: "Ministry Of Law, Justice And Company Affairs", label: "Ministry Of Law, Justice And Company Affairs" },
  { value: "Ministry Of Non-Conventional Energy Sources", label: "Ministry Of Non-Conventional Energy Sources" },
  { value: "Ministry Of Parliamentary Affairs", label: "Ministry Of Parliamentary Affairs" },
  { value: "Ministry Of Personnel, Public Grievances And Pension", label: "Ministry Of Personnel, Public Grievances And Pension" },
  { value: "Ministry Of Petroleum And Natural Gas", label: "Ministry Of Petroleum And Natural Gas" },
  { value: "Ministry Of Power", label: "Ministry Of Power" },
  { value: "Ministry Of Railways", label: "Ministry Of Railways" },
  { value: "Ministry Of Road Transport And Highways", label: "Ministry Of Road Transport And Highways" },
  { value: "Ministry Of Rural Development", label: "Ministry Of Rural Development" },
  { value: "Ministry Of Science And Technology", label: "Ministry Of Science And Technology" },
  { value: "Ministry Of Shipping", label: "Ministry Of Shipping" },
  { value: "Ministry Of Small Scale Industries And Agro And Rural Industries", label: "Ministry Of Small Scale Industries And Agro And Rural Industries" },
  { value: "Ministry Of Social Justice And Empowerment", label: "Ministry Of Social Justice And Empowerment" },
  { value: "Ministry Of Statistics And Programme Implementation", label: "Ministry Of Statistics And Programme Implementation" },
  { value: "Ministry Of Steel", label: "Ministry Of Steel" },
  { value: "Ministry Of Textiles", label: "Ministry Of Textiles" },
  { value: "Ministry Of Tourism And Culture", label: "Ministry Of Tourism And Culture" },
  { value: "Ministry Of Tribal Affairs", label: "Ministry Of Tribal Affairs" },
  { value: "Ministry Of Urban Development And Poverty Alleviation", label: "Ministry Of Urban Development And Poverty Alleviation" },
  { value: "Ministry Of Water Resources", label: "Ministry Of Water Resources" },
  { value: "Ministry Of Youth Affairs And Sports", label: "Ministry Of Youth Affairs And Sports" },
  { value: "Minority Co-Op Bank", label: "Minority Co-Op Bank" },
  { value: "Minority Credit Co-Operative Ltd.", label: "Minority Credit Co-Operative Ltd." },
  { value: "Minority Credit Coop Society Ltd.", label: "Minority Credit Coop Society Ltd." },
  { value: "Mintu Finance", label: "Mintu Finance" },
  { value: "Mintu Finance Corporation In", label: "Mintu Finance Corporation In" },
  { value: "Miraj Urban Co-Op Bank Ltd", label: "Miraj Urban Co-Op Bank Ltd" },
  { value: "Miral S Patel", label: "Miral S Patel" },
  { value: "Mishra Dhatu Nigam Ltd.", label: "Mishra Dhatu Nigam Ltd." },
  { value: "Mishri Finance - Chennai", label: "Mishri Finance - Chennai" },
  { value: "Mishri Investment", label: "Mishri Investment" },
  { value: "Mishris Finance India", label: "Mishris Finance India" },
  { value: "Misrilal Kushal Chand", label: "Misrilal Kushal Chand" },
  { value: "Misrilal Mahaveer", label: "Misrilal Mahaveer" },
  { value: "Misrilal Mahaveer Chand Financier", label: "Misrilal Mahaveer Chand Financier" },
  { value: "Mit Accreditation Of Computer Courses", label: "Mit Accreditation Of Computer Courses" },
  { value: "Mitesh Finance", label: "Mitesh Finance" },
  { value: "Mitesh Patel", label: "Mitesh Patel" },
  { value: "Mithalal Atulkumar And Co", label: "Mithalal Atulkumar And Co" },
  { value: "Mithila Kshetriya Gramin Bank", label: "Mithila Kshetriya Gramin Bank" },
  { value: "Mithra Associates", label: "Mithra Associates" },
  { value: "Mithra Finance", label: "Mithra Finance" },
  { value: "Mithra Hi Per Financiers", label: "Mithra Hi Per Financiers" },
  { value: "Mithun Chakraborty Urban Co Cr.So Ltd,", label: "Mithun Chakraborty Urban Co Cr.So Ltd," },
  { value: "Mithust Cradit & Holding Investment", label: "Mithust Cradit & Holding Investment" },
  { value: "Mitra A/F", label: "Mitra A/F" },
  { value: "Mitra Auto Finance", label: "Mitra Auto Finance" },
  { value: "Mitra-Mandal Sahakari Bank Ltd., Indore", label: "Mitra-Mandal Sahakari Bank Ltd., Indore" },
  { value: "Mitsui Bank Ltd.", label: "Mitsui Bank Ltd." },
  { value: "Mittal Finance Co", label: "Mittal Finance Co" },
  { value: "Mittal Finsec Pvt Ltd", label: "Mittal Finsec Pvt Ltd" },
  { value: "Mitter Finance Co. Pvt. Ltd", label: "Mitter Finance Co. Pvt. Ltd" },
  { value: "Mitushi Credits Holdings Pvt Ltd", label: "Mitushi Credits Holdings Pvt Ltd" },
  { value: "Mitushi Traders And Fin Pvt Ltd", label: "Mitushi Traders And Fin Pvt Ltd" },
  { value: "Miyasaheb Gramin Big Saheti Sah Pat Santha", label: "Miyasaheb Gramin Big Saheti Sah Pat Santha" },
  { value: "Miyasaheb Gramin Big.Saheti Sah .Pat Sansth", label: "Miyasaheb Gramin Big.Saheti Sah .Pat Sansth" },
  { value: "Mizoram Co-Op Apex Bank Ltd.", label: "Mizoram Co-Op Apex Bank Ltd." },
  { value: "Mizoram Industrial Development Corporation", label: "Mizoram Industrial Development Corporation" },
  { value: "Mizoram Rural Bank", label: "Mizoram Rural Bank" },
  { value: "Mizoram State Central Co-Oprative Bank Ltd.", label: "Mizoram State Central Co-Oprative Bank Ltd." },
  { value: "Mizoram State Tourism Development Corporation Ltd.", label: "Mizoram State Tourism Development Corporation Ltd." },
  { value: "Mizoram Urban Co-Op Bank Ltd", label: "Mizoram Urban Co-Op Bank Ltd" },
  { value: "Mizoram Urban Coop Devlopment Bank Ltd", label: "Mizoram Urban Coop Devlopment Bank Ltd" },
  { value: "Mizuho Corporate Bank Ltd.", label: "Mizuho Corporate Bank Ltd." },
  { value: "Mjs Invesment", label: "Mjs Invesment" },
  { value: "Mkg Financial Services", label: "Mkg Financial Services" },
  { value: "Ml Soni & Sons", label: "Ml Soni & Sons" },
  { value: "Mll Pnb And Member Banks Of Consortium", label: "Mll Pnb And Member Banks Of Consortium" },
  { value: "Mlm Finance", label: "Mlm Finance" },
  { value: "Mlm Financial Service Ltd", label: "Mlm Financial Service Ltd" },
  { value: "Mm Finance", label: "Mm Finance" },
  { value: "Mmfsc", label: "Mmfsc" },
  { value: "Mms Finance Services Ltd", label: "Mms Finance Services Ltd" },
  { value: "Mmtc Ltd.", label: "Mmtc Ltd." },
  { value: "Mn Narendira Kumar Finance", label: "Mn Narendira Kumar Finance" },
  { value: "Mnba Finance", label: "Mnba Finance" },
  { value: "Moda Finance", label: "Moda Finance" },
  { value: "Modasa Nagarik Sahakari Bank Ltd", label: "Modasa Nagarik Sahakari Bank Ltd" },
  { value: "Modasa Road Transport Co-Op Society Ltd", label: "Modasa Road Transport Co-Op Society Ltd" },
  { value: "Mode Line Finance Ltd.", label: "Mode Line Finance Ltd." },
  { value: "Model Co-Op Bank Ltd", label: "Model Co-Op Bank Ltd" },
  { value: "Model Co-Op. Bank Ltd.", label: "Model Co-Op. Bank Ltd." },
  { value: "Model Contessa Classic", label: "Model Contessa Classic" },
  { value: "Model Financial Corporation Ltd.", label: "Model Financial Corporation Ltd." },
  { value: "Modern Auto Finance", label: "Modern Auto Finance" },
  { value: "Modern Food Industries (India) Limited", label: "Modern Food Industries (India) Limited" },
  { value: "Modern General Financiers Ltd", label: "Modern General Financiers Ltd" },
  { value: "Modern Home Credit And Capital Limited", label: "Modern Home Credit And Capital Limited" },
  { value: "Modi Finance Khokhra Ahmedabad", label: "Modi Finance Khokhra Ahmedabad" },
  { value: "Modi Tractors", label: "Modi Tractors" },
  { value: "Modin M Bepari", label: "Modin M Bepari" },
  { value: "Modline Finlease Co.Pvt.Ltd.", label: "Modline Finlease Co.Pvt.Ltd." },
  { value: "Moga Deposits And Advances Pvt. Ltd", label: "Moga Deposits And Advances Pvt. Ltd" },
  { value: "Moga District Central Co-Operative Bank Ltd.", label: "Moga District Central Co-Operative Bank Ltd." },
  { value: "Moga Finance Pvt. Ltd", label: "Moga Finance Pvt. Ltd" },
  { value: "Mogal Fin. Pvt. Ltd.", label: "Mogal Fin. Pvt. Ltd." },
  { value: "Mogaveera Co-Op Bank Ltd., Mumbai", label: "Mogaveera Co-Op Bank Ltd., Mumbai" },
  { value: "Mogul Finance Pvt Ltd", label: "Mogul Finance Pvt Ltd" },
  { value: "Mohan Finance", label: "Mohan Finance" },
  { value: "Mohan Finance Ltd.", label: "Mohan Finance Ltd." },
  { value: "Mohan Motors", label: "Mohan Motors" },
  { value: "Mohan Sai Finance", label: "Mohan Sai Finance" },
  { value: "Mohan Sai Motors", label: "Mohan Sai Motors" },
  { value: "Mohana Sai Motors", label: "Mohana Sai Motors" },
  { value: "Mohd Rafiq Padder", label: "Mohd Rafiq Padder" },
  { value: "Mohini Chordia", label: "Mohini Chordia" },
  { value: "Mohini Finance", label: "Mohini Finance" },
  { value: "Mohini Vinod Patel", label: "Mohini Vinod Patel" },
  { value: "Mohiniraj Finance Corporation", label: "Mohiniraj Finance Corporation" },
  { value: "Mohit Auto Finance", label: "Mohit Auto Finance" },
  { value: "Mohit Finance Corp", label: "Mohit Finance Corp" },
  { value: "Mohit Mahajan", label: "Mohit Mahajan" },
  { value: "Mohit Textiles Ltd.", label: "Mohit Textiles Ltd." },
  { value: "Mohith Investments", label: "Mohith Investments" },
  { value: "Mohol Nagari Sah Path Puravatha Santha", label: "Mohol Nagari Sah Path Puravatha Santha" },
  { value: "Mohol Nagari Sah. Patsanstha", label: "Mohol Nagari Sah. Patsanstha" },
  { value: "Mohol Nagari Sahkari Patishania Mohol", label: "Mohol Nagari Sahkari Patishania Mohol" },
  { value: "Mohol Urban Co-Op Bank L", label: "Mohol Urban Co-Op Bank L" },
  { value: "Mohol Urban Co-Op Bank Ltd.", label: "Mohol Urban Co-Op Bank Ltd." },
  { value: "Mohta Finance And Leasing Co. Ltd", label: "Mohta Finance And Leasing Co. Ltd" },
  { value: "Mohteej Finance", label: "Mohteej Finance" },
  { value: "Mohteej Investment", label: "Mohteej Investment" },
  { value: "Mohur Ins. Advisory Services Pvt. Ltd.", label: "Mohur Ins. Advisory Services Pvt. Ltd." },
  { value: "Mokery Service Co-Op Bank Ltd", label: "Mokery Service Co-Op Bank Ltd" },
  { value: "Molana Azad Nagari Sah.Path.", label: "Molana Azad Nagari Sah.Path." },
  { value: "Momin Co Op Society  Ltd", label: "Momin Co Op Society  Ltd" },
  { value: "Mona Finance", label: "Mona Finance" },
  { value: "Monarch Co Op Cr Soc Ltd", label: "Monarch Co Op Cr Soc Ltd" },
  { value: "Monark Lease And Finance", label: "Monark Lease And Finance" },
  { value: "Money Enterprise", label: "Money Enterprise" },
  { value: "Money Financial Service", label: "Money Financial Service" },
  { value: "Money Master Co-Op Credit Soc Ltd.", label: "Money Master Co-Op Credit Soc Ltd." },
  { value: "Money Master Finance Ltd", label: "Money Master Finance Ltd" },
  { value: "Money Masters Co - Op Credit Soc", label: "Money Masters Co - Op Credit Soc" },
  { value: "Money Masters Leasing & Finance Ltd", label: "Money Masters Leasing & Finance Ltd" },
  { value: "Money Masters Leasing And Finance Ltd", label: "Money Masters Leasing And Finance Ltd" },
  { value: "Money Masters Ltd", label: "Money Masters Ltd" },
  { value: "Money Masters, Leasing And Finance Ltd.", label: "Money Masters, Leasing And Finance Ltd." },
  { value: "Monghyr Kshetriya Gramin Bank", label: "Monghyr Kshetriya Gramin Bank" },
  { value: "Monica Finance", label: "Monica Finance" },
  { value: "Monica Investments", label: "Monica Investments" },
  { value: "Monica Motors And Housing Finance Pvt Ltd", label: "Monica Motors And Housing Finance Pvt Ltd" },
  { value: "Monika Auto Finance", label: "Monika Auto Finance" },
  { value: "Mont Blac Financial Services Ltd.", label: "Mont Blac Financial Services Ltd." },
  { value: "Moodbidri Co-Op Service Bank", label: "Moodbidri Co-Op Service Bank" },
  { value: "Mookambika Auto Finance", label: "Mookambika Auto Finance" },
  { value: "Mookkannur Service Co-Operative Bank Ltd", label: "Mookkannur Service Co-Operative Bank Ltd" },
  { value: "Moolraj Finance", label: "Moolraj Finance" },
  { value: "Moon Financial Services", label: "Moon Financial Services" },
  { value: "Moonshine Finvest Pvt. Ltd.", label: "Moonshine Finvest Pvt. Ltd." },
  { value: "Moopraj Finance", label: "Moopraj Finance" },
  { value: "Moorkkanikkara Service Co Op Bank Ltd", label: "Moorkkanikkara Service Co Op Bank Ltd" },
  { value: "Moospet Service Co-Op Bank Ltd,", label: "Moospet Service Co-Op Bank Ltd," },
  { value: "Moothedam Services Coop Bank Ltd", label: "Moothedam Services Coop Bank Ltd" },
  { value: "Morazha Kaliassery Service Coop Bk Ltd", label: "Morazha Kaliassery Service Coop Bk Ltd" },
  { value: "Morbi Urban Co-Op Bank Ltd", label: "Morbi Urban Co-Op Bank Ltd" },
  { value: "Morde Sah Pathsanstha Maryadit Borivali E", label: "Morde Sah Pathsanstha Maryadit Borivali E" },
  { value: "Morinda Hire Purchase Pvt Ltd", label: "Morinda Hire Purchase Pvt Ltd" },
  { value: "Mormugao Port Trust", label: "Mormugao Port Trust" },
  { value: "Morna Warna Shakari Pathsanthna Ltd.", label: "Morna Warna Shakari Pathsanthna Ltd." },
  { value: "Mota Dhhisara Juth Seva Sahakari Mandli Ltd", label: "Mota Dhhisara Juth Seva Sahakari Mandli Ltd" },
  { value: "Mota Rampar Seva Sahkari Mandali", label: "Mota Rampar Seva Sahkari Mandali" },
  { value: "Motheej Finance", label: "Motheej Finance" },
  { value: "Motherland Finand", label: "Motherland Finand" },
  { value: "Mothilal Bharat Kumar", label: "Mothilal Bharat Kumar" },
  { value: "Moti Transport  Service", label: "Moti Transport  Service" },
  { value: "Motichand Kondiya N.S.P", label: "Motichand Kondiya N.S.P" },
  { value: "Motilal Jain", label: "Motilal Jain" },
  { value: "Motor And General Finance Limited", label: "Motor And General Finance Limited" },
  { value: "Motor Finance Corporation Ltd", label: "Motor Finance Corporation Ltd" },
  { value: "Motor Financial Services", label: "Motor Financial Services" },
  { value: "Motor Industries", label: "Motor Industries" },
  { value: "Mouneshwar Co-Op Cr. Society Ltd.", label: "Mouneshwar Co-Op Cr. Society Ltd." },
  { value: "Mounika & Deepthi Associates", label: "Mounika & Deepthi Associates" },
  { value: "Mount Intra Finance Pvt Ltd", label: "Mount Intra Finance Pvt Ltd" },
  { value: "Mount Shikhar Financial", label: "Mount Shikhar Financial" },
  { value: "Mountain Leasing Co. Pvt. Ltd.", label: "Mountain Leasing Co. Pvt. Ltd." },
  { value: "Mourya Finance", label: "Mourya Finance" },
  { value: "Mp Karmchari Sahkari Sakh Sanstha Maryadit", label: "Mp Karmchari Sahkari Sakh Sanstha Maryadit" },
  { value: "Mpseb Karamchari", label: "Mpseb Karamchari" },
  { value: "Mr. George Jacob", label: "Mr. George Jacob" },
  { value: "Mrau Enterprises", label: "Mrau Enterprises" },
  { value: "Mrc Finance", label: "Mrc Finance" },
  { value: "Mrf Limited", label: "Mrf Limited" },
  { value: "Mrg Finance", label: "Mrg Finance" },
  { value: "Mrs Agency", label: "Mrs Agency" },
  { value: "Mrs Auto Agency", label: "Mrs Auto Agency" },
  { value: "Mrs Sushila Singhi", label: "Mrs Sushila Singhi" },
  { value: "Ms Handicapped Fin. & Dev Cor. Ltd.", label: "Ms Handicapped Fin. & Dev Cor. Ltd." },
  { value: "Ms Mangal Finance", label: "Ms Mangal Finance" },
  { value: "Ms P Sangeetha Devi", label: "Ms P Sangeetha Devi" },
  { value: "Msk Motor Credit", label: "Msk Motor Credit" },
  { value: "Mstc Limited", label: "Mstc Limited" },
  { value: "Mtl Finance Ltd.", label: "Mtl Finance Ltd." },
  { value: "Mudalagi Uraban Co-Op Bank Ltd", label: "Mudalagi Uraban Co-Op Bank Ltd" },
  { value: "Mudalgi Co-Operative Bank Ltd.", label: "Mudalgi Co-Operative Bank Ltd." },
  { value: "Mudavanmukal Ser. Co-Op. Bank Ltd.", label: "Mudavanmukal Ser. Co-Op. Bank Ltd." },
  { value: "Mudavoor Finacial Services Ltd", label: "Mudavoor Finacial Services Ltd" },
  { value: "Mudgal Urban Co-0Perative Bank Ltd.", label: "Mudgal Urban Co-0Perative Bank Ltd." },
  { value: "Mudgal Urban Co-Op Bank Ltd", label: "Mudgal Urban Co-Op Bank Ltd" },
  { value: "Mudhol Urban Co-Op Bank Ltd", label: "Mudhol Urban Co-Op Bank Ltd" },
  { value: "Mudit Hire Purchase", label: "Mudit Hire Purchase" },
  { value: "Mudra Finance And Investments", label: "Mudra Finance And Investments" },
  { value: "Mudra Invesments", label: "Mudra Invesments" },
  { value: "Mudrakara Souharda Sahakari Niyamitha Bank - Udupi", label: "Mudrakara Souharda Sahakari Niyamitha Bank - Udupi" },
  { value: "Mudugal Vyaparasthara Pattina Sahakara Sanga Ni.", label: "Mudugal Vyaparasthara Pattina Sahakara Sanga Ni." },
  { value: "Mugamraj Chordia And Sons", label: "Mugamraj Chordia And Sons" },
  { value: "Mugberia District Central Co-Operative Bank Ltd.", label: "Mugberia District Central Co-Operative Bank Ltd." },
  { value: "Muggala Lscs Ltd", label: "Muggala Lscs Ltd" },
  { value: "Muhamma Service Coop Bank Ltd,", label: "Muhamma Service Coop Bank Ltd," },
  { value: "Mukanraj Jain", label: "Mukanraj Jain" },
  { value: "Mukat Finance Ltd.", label: "Mukat Finance Ltd." },
  { value: "Mukesh Enterprise", label: "Mukesh Enterprise" },
  { value: "Mukesh Finance", label: "Mukesh Finance" },
  { value: "Mukesh Genral Finance Co.", label: "Mukesh Genral Finance Co." },
  { value: "Mukesh Gupta", label: "Mukesh Gupta" },
  { value: "Mukesh Jain. P", label: "Mukesh Jain. P" },
  { value: "Mukesh Kumar Kankariya", label: "Mukesh Kumar Kankariya" },
  { value: "Mukeshkumar Ramanlal Patel", label: "Mukeshkumar Ramanlal Patel" },
  { value: "Mukhram Saran", label: "Mukhram Saran" },
  { value: "Mukta Saha", label: "Mukta Saha" },
  { value: "Muktadevi Gramin Bigar Sheti Sah Pat", label: "Muktadevi Gramin Bigar Sheti Sah Pat" },
  { value: "Muktai Co-Op. Bank Ltd.", label: "Muktai Co-Op. Bank Ltd." },
  { value: "Muktai Gramin Bigar Sheti Sah Pat San Mar", label: "Muktai Gramin Bigar Sheti Sah Pat San Mar" },
  { value: "Muktha Mishri Finance", label: "Muktha Mishri Finance" },
  { value: "Muktha Mishri Finance", label: "Muktha Mishri Finance" },
  { value: "Muktsar District Central Co-Operative Bank Ltd.", label: "Muktsar District Central Co-Operative Bank Ltd." },
  { value: "Mukunda Industrial Finance Ltd", label: "Mukunda Industrial Finance Ltd" },
  { value: "Mula Sahakari Bank Ltd.", label: "Mula Sahakari Bank Ltd." },
  { value: "Mulabagalu Vyavasaya Seva Sah Bk Ltd", label: "Mulabagalu Vyavasaya Seva Sah Bk Ltd" },
  { value: "Mulakulam Panchayat Ser Co Bank Ltd,", label: "Mulakulam Panchayat Ser Co Bank Ltd," },
  { value: "Mulakulam Service Co-Op Bank Ltd", label: "Mulakulam Service Co-Op Bank Ltd" },
  { value: "Mulakuzha Village Ser.Co-Op.Bk.Ltd.", label: "Mulakuzha Village Ser.Co-Op.Bk.Ltd." },
  { value: "Mulammoottil Finance", label: "Mulammoottil Finance" },
  { value: "Mulamoottil Consumer Credits Ltd.", label: "Mulamoottil Consumer Credits Ltd." },
  { value: "Mulamoottil Finance And Kuries", label: "Mulamoottil Finance And Kuries" },
  { value: "Mulamoottil Leasing And Hire Purchase Pvt Ltd.", label: "Mulamoottil Leasing And Hire Purchase Pvt Ltd." },
  { value: "Mulanthuruthy Service Co-Op. Bank Ltd.", label: "Mulanthuruthy Service Co-Op. Bank Ltd." },
  { value: "Mulganga Gramin Bigar Sheti Sahakari Pathsanstha Maryadit", label: "Mulganga Gramin Bigar Sheti Sahakari Pathsanstha Maryadit" },
  { value: "Mulgund Urban Co-Op Bank Ltd", label: "Mulgund Urban Co-Op Bank Ltd" },
  { value: "Mulkanur Cooperative Rural Bank And Marketing Society", label: "Mulkanur Cooperative Rural Bank And Marketing Society" },
  { value: "Mullai", label: "Mullai" },
  { value: "Mullai Finance", label: "Mullai Finance" },
  { value: "Mullankolly Services Coop Bank Ltd", label: "Mullankolly Services Coop Bank Ltd" },
  { value: "Multani Hire Purchase Leasing Co.", label: "Multani Hire Purchase Leasing Co." },
  { value: "Mulund Vikas Sah Pat Ltd", label: "Mulund Vikas Sah Pat Ltd" },
  { value: "Mumbai Dist.Central Co-Op Bank Ltd.", label: "Mumbai Dist.Central Co-Op Bank Ltd." },
  { value: "Mumbai Mahanagar Palika Shikshan Vibhag Sahakari Bank Ltd", label: "Mumbai Mahanagar Palika Shikshan Vibhag Sahakari Bank Ltd" },
  { value: "Mumbai Mercantile Co-Op Bank Ltd.", label: "Mumbai Mercantile Co-Op Bank Ltd." },
  { value: "Mumbai Port Trust", label: "Mumbai Port Trust" },
  { value: "Mumbai Taxi Association Co Op Credit Society Ltd", label: "Mumbai Taxi Association Co Op Credit Society Ltd" },
  { value: "Mumbai Taxi Association Pathare Bhuvan Kennedy", label: "Mumbai Taxi Association Pathare Bhuvan Kennedy" },
  { value: "Mumbai Vyapari Sah Patpedhi Ltd", label: "Mumbai Vyapari Sah Patpedhi Ltd" },
  { value: "Mummat Motor Finnace", label: "Mummat Motor Finnace" },
  { value: "Munavalli Prathamik Krushi Pattin Sahakari Bank Ltd", label: "Munavalli Prathamik Krushi Pattin Sahakari Bank Ltd" },
  { value: "Mundakayam Service Co-Op Bank Ltd", label: "Mundakayam Service Co-Op Bank Ltd" },
  { value: "Mundra Finance & Investments", label: "Mundra Finance & Investments" },
  { value: "Mundur Service Coop Bank Ltd,", label: "Mundur Service Coop Bank Ltd," },
  { value: "Mungavavdi Juth Seva Sahakari Mandli", label: "Mungavavdi Juth Seva Sahakari Mandli" },
  { value: "Mungsaji Maharaj Nagri Sahakari Patsanstha", label: "Mungsaji Maharaj Nagri Sahakari Patsanstha" },
  { value: "Municipal Corporation Of Nashik", label: "Municipal Corporation Of Nashik" },
  { value: "Munish Gupta", label: "Munish Gupta" },
  { value: "Munjal Showa Ltd", label: "Munjal Showa Ltd" },
  { value: "Munjuntha Finance", label: "Munjuntha Finance" },
  { value: "Munnar Auto Credit", label: "Munnar Auto Credit" },
  { value: "Munoakayam Services Cooperative Bank Ltd", label: "Munoakayam Services Cooperative Bank Ltd" },
  { value: "Mupliyam Anjar Prises", label: "Mupliyam Anjar Prises" },
  { value: "Muppathadam Service Cooperative Bank", label: "Muppathadam Service Cooperative Bank" },
  { value: "Murali Krishna Auto Finance", label: "Murali Krishna Auto Finance" },
  { value: "Murali Motors", label: "Murali Motors" },
  { value: "Murari Motor Commercial Finance Pvt Ltd", label: "Murari Motor Commercial Finance Pvt Ltd" },
  { value: "Murari Motor Finance Co.", label: "Murari Motor Finance Co." },
  { value: "Muraura Paes", label: "Muraura Paes" },
  { value: "Murde Sahkari Patsanstha Maryadit", label: "Murde Sahkari Patsanstha Maryadit" },
  { value: "Murgendra Urban Co-Op Society", label: "Murgendra Urban Co-Op Society" },
  { value: "Murgharajendra Sahakari Bank Ltd", label: "Murgharajendra Sahakari Bank Ltd" },
  { value: "Murgharajendra Sahakari Bank Ltd", label: "Murgharajendra Sahakari Bank Ltd" },
  { value: "Murgod Shri Mahant Shivayogi Co-Op Bank Ltd", label: "Murgod Shri Mahant Shivayogi Co-Op Bank Ltd" },
  { value: "Murli Krupa Co Op Credit Society Ltd", label: "Murli Krupa Co Op Credit Society Ltd" },
  { value: "Murnad Vyavasaya Seva Sahakari Bank Ltd", label: "Murnad Vyavasaya Seva Sahakari Bank Ltd" },
  { value: "Murshidabad Agricultural And Rural Development Bank Ltd", label: "Murshidabad Agricultural And Rural Development Bank Ltd" },
  { value: "Murshidabad District Central Co-Operative Bank Ltd.", label: "Murshidabad District Central Co-Operative Bank Ltd." },
  { value: "Murshidabad Gramin Bank Ltd", label: "Murshidabad Gramin Bank Ltd" },
  { value: "Murude Sahakari Path Sanstha Maryadit", label: "Murude Sahakari Path Sanstha Maryadit" },
  { value: "Murudeshwar Finance And Leasing Ltd", label: "Murudeshwar Finance And Leasing Ltd" },
  { value: "Murudhor Enterprises", label: "Murudhor Enterprises" },
  { value: "Murudi Sahakari Pat Maryadit", label: "Murudi Sahakari Pat Maryadit" },
  { value: "Murugan Finance", label: "Murugan Finance" },
  { value: "Murugan Illam Finance Company", label: "Murugan Illam Finance Company" },
  { value: "Murugud Sahakari Bank Ltd", label: "Murugud Sahakari Bank Ltd" },
  { value: "Murunthal Service Co-Operative Bank Ltd", label: "Murunthal Service Co-Operative Bank Ltd" },
  { value: "Museum", label: "Museum" },
  { value: "Mushidabad District Central Co-Op.Bank", label: "Mushidabad District Central Co-Op.Bank" },
  { value: "Musiri Town Co-Op. Bank Ltd", label: "Musiri Town Co-Op. Bank Ltd" },
  { value: "Muskaan Enterprises", label: "Muskaan Enterprises" },
  { value: "Muskan Enterprises", label: "Muskan Enterprises" },
  { value: "Muslim Co-Operative Bank", label: "Muslim Co-Operative Bank" },
  { value: "Muslim Minority Co-Op. Credit Society Lt", label: "Muslim Minority Co-Op. Credit Society Lt" },
  { value: "Musyandra Agricultural Seva Sah Bk Ltd", label: "Musyandra Agricultural Seva Sah Bk Ltd" },
  { value: "Mutha Finance Corp", label: "Mutha Finance Corp" },
  { value: "Muthalapuram Service Co-Opp Bank Ltd", label: "Muthalapuram Service Co-Opp Bank Ltd" },
  { value: "Muthalapuram Services Co-Operative Bank", label: "Muthalapuram Services Co-Operative Bank" },
  { value: "Muthamman Finance", label: "Muthamman Finance" },
  { value: "Mutholapuram Service Co.Op Bank Ltd.", label: "Mutholapuram Service Co.Op Bank Ltd." },
  { value: "Mutholy Service Co-Operative Bank Ltd", label: "Mutholy Service Co-Operative Bank Ltd" },
  { value: "Muthoot Chits And Finance", label: "Muthoot Chits And Finance" },
  { value: "Muthoot Consumer Credits Ltd", label: "Muthoot Consumer Credits Ltd" },
  { value: "Muthoot Fin Corp Ltd", label: "Muthoot Fin Corp Ltd" },
  { value: "Muthoot Finance Ltd", label: "Muthoot Finance Ltd" },
  { value: "Muthoot Financial Services", label: "Muthoot Financial Services" },
  { value: "Muthoot Fincorp Ltd.", label: "Muthoot Fincorp Ltd." },
  { value: "Muthoot Leasing And Finance Ltd.", label: "Muthoot Leasing And Finance Ltd." },
  { value: "Muthoot Leasing Finance Corp", label: "Muthoot Leasing Finance Corp" },
  { value: "Muthoot Leasing Pvt Ltd", label: "Muthoot Leasing Pvt Ltd" },
  { value: "Muthoot Motors Pvt Ltd", label: "Muthoot Motors Pvt Ltd" },
  { value: "Muthoot Vehicle Finance", label: "Muthoot Vehicle Finance" },
  { value: "Muthsandra Vyavasaya Seva Sah Bk Niy", label: "Muthsandra Vyavasaya Seva Sah Bk Niy" },
  { value: "Muthu Amman Finance", label: "Muthu Amman Finance" },
  { value: "Muthu Corporation", label: "Muthu Corporation" },
  { value: "Muthu Finance", label: "Muthu Finance" },
  { value: "Muthu Finance And Corporation", label: "Muthu Finance And Corporation" },
  { value: "Muthu Financier", label: "Muthu Financier" },
  { value: "Muthukuviyal Finance Corporation", label: "Muthukuviyal Finance Corporation" },
  { value: "Muthukuviyal Investments", label: "Muthukuviyal Investments" },
  { value: "Muttathala Ser. Co-Op. Bank Ltd", label: "Muttathala Ser. Co-Op. Bank Ltd" },
  { value: "Muvattupuzha Kothamangalm Co-Operative Agri&Rural Development Bank Ltd", label: "Muvattupuzha Kothamangalm Co-Operative Agri&Rural Development Bank Ltd" },
  { value: "Muvattupuzha Service Co-Op Bank Ltd", label: "Muvattupuzha Service Co-Op Bank Ltd" },
  { value: "Muzaffar Nagar Dis Co-Op Bk L", label: "Muzaffar Nagar Dis Co-Op Bk L" },
  { value: "Muzaffarnagar District Co-Op Bank Ltd", label: "Muzaffarnagar District Co-Op Bank Ltd" },
  { value: "Muzaffarnagar Urban Co-Op. Bank Ltd", label: "Muzaffarnagar Urban Co-Op. Bank Ltd" },
  { value: "Muzaffarpur Central Co. Op. Bank Ltd.", label: "Muzaffarpur Central Co. Op. Bank Ltd." },
  { value: "Muzhaffarnagar Kshetriya Bank", label: "Muzhaffarnagar Kshetriya Bank" },
  { value: "Muzzafarnagar Urban Co-Op Bank Ltd", label: "Muzzafarnagar Urban Co-Op Bank Ltd" },
  { value: "Mvr Auto Finance", label: "Mvr Auto Finance" },
  { value: "Mynakam General Finance Pvt Ltd", label: "Mynakam General Finance Pvt Ltd" },
  { value: "Mysore Co-Op Bank Ltd", label: "Mysore Co-Op Bank Ltd" },
  { value: "Mysore District Central Co-Operative Bank Ltd.", label: "Mysore District Central Co-Operative Bank Ltd." },
  { value: "Mysore District Co-Op. Central Bank Ltd", label: "Mysore District Co-Op. Central Bank Ltd" },
  { value: "Mysore District Credit Co-Operative Bank", label: "Mysore District Credit Co-Operative Bank" },
  { value: "Mysore District Credit Co-Operative Bank Ltd", label: "Mysore District Credit Co-Operative Bank Ltd" },
  { value: "Mysore Fin Lease (P) Ltd.", label: "Mysore Fin Lease (P) Ltd." },
  { value: "Mysore Leasing And Finance Ltd", label: "Mysore Leasing And Finance Ltd" },
  { value: "Mysore Merchants Co-Op Bank Ltd", label: "Mysore Merchants Co-Op Bank Ltd" },
  { value: "Mysore Zilla Mahila Sahakari Bank Ltd", label: "Mysore Zilla Mahila Sahakari Bank Ltd" },
  { value: "Mythili Finance", label: "Mythili Finance" },
  { value: "N A A Dt Central Co-Op Bank Ltd", label: "N A A Dt Central Co-Op Bank Ltd" },
  { value: "N B R Associates", label: "N B R Associates" },
  { value: "N C Finance", label: "N C Finance" },
  { value: "N D Singh", label: "N D Singh" },
  { value: "N H P C Ltd.", label: "N H P C Ltd." },
  { value: "N J Finance", label: "N J Finance" },
  { value: "N K Financiers", label: "N K Financiers" },
  { value: "N K Kusumgar Fin Co Ltd", label: "N K Kusumgar Fin Co Ltd" },
  { value: "N Kantha Chajjed", label: "N Kantha Chajjed" },
  { value: "N Kantha Chajjed Financier", label: "N Kantha Chajjed Financier" },
  { value: "N Kantha Chand", label: "N Kantha Chand" },
  { value: "N Kantha Chassed", label: "N Kantha Chassed" },
  { value: "N L Finance Chennai", label: "N L Finance Chennai" },
  { value: "N M B C Bank", label: "N M B C Bank" },
  { value: "N M C B Bank", label: "N M C B Bank" },
  { value: "N M Jain", label: "N M Jain" },
  { value: "N Mahaveer Raj H U F", label: "N Mahaveer Raj H U F" },
  { value: "N N Finance Dhamtari", label: "N N Finance Dhamtari" },
  { value: "N N K Finance", label: "N N K Finance" },
  { value: "N Navin Banthia", label: "N Navin Banthia" },
  { value: "N P R Finance Ltd", label: "N P R Finance Ltd" },
  { value: "N Prakash Dugar Huf", label: "N Prakash Dugar Huf" },
  { value: "N R D V S Bank Ltd", label: "N R D V S Bank Ltd" },
  { value: "N Rajendra Prasad H U F", label: "N Rajendra Prasad H U F" },
  { value: "N Ramachandran", label: "N Ramachandran" },
  { value: "N Rekha Devi Dugar Finance", label: "N Rekha Devi Dugar Finance" },
  { value: "N Rekhadevi Dugar", label: "N Rekhadevi Dugar" },
  { value: "N S P C L", label: "N S P C L" },
  { value: "N S S Finance", label: "N S S Finance" },
  { value: "N S T F D C", label: "N S T F D C" },
  { value: "N. A. A. Dt. Central Co-Op. Bank Ltd.", label: "N. A. A. Dt. Central Co-Op. Bank Ltd." },
  { value: "N. Naresh Kumar Jain", label: "N. Naresh Kumar Jain" },
  { value: "N. P. Automobile And Finance Limited", label: "N. P. Automobile And Finance Limited" },
  { value: "N. Ravi Kumar Finance", label: "N. Ravi Kumar Finance" },
  { value: "N. S. Hire Purchase Pvt Ltd", label: "N. S. Hire Purchase Pvt Ltd" },
  { value: "N. S. Jain Financer & Money Lenders", label: "N. S. Jain Financer & Money Lenders" },
  { value: "N. Sonica Financiers", label: "N. Sonica Financiers" },
  { value: "N.B.B  Auto Finance", label: "N.B.B  Auto Finance" },
  { value: "N.B.S. Iinternational Ltd.", label: "N.B.S. Iinternational Ltd." },
  { value: "N.C.Dhanasekaran", label: "N.C.Dhanasekaran" },
  { value: "N.H.P.C", label: "N.H.P.C" },
  { value: "N.H.P.C. Ltd", label: "N.H.P.C. Ltd" },
  { value: "N.I.A Co.Ltd", label: "N.I.A Co.Ltd" },
  { value: "N.K.G.S.B.Co-Op Bank Ltd., Mumbai", label: "N.K.G.S.B.Co-Op Bank Ltd., Mumbai" },
  { value: "N.K.G.S.B.Ltd", label: "N.K.G.S.B.Ltd" },
  { value: "N.Kiran Kumari Finance Ltd", label: "N.Kiran Kumari Finance Ltd" },
  { value: "N.N. Enterprise", label: "N.N. Enterprise" },
  { value: "N.N. Finance", label: "N.N. Finance" },
  { value: "N.N.Enterprise", label: "N.N.Enterprise" },
  { value: "N.N.Syndicate", label: "N.N.Syndicate" },
  { value: "N.Ravindhra", label: "N.Ravindhra" },
  { value: "N.Renu Manjusha", label: "N.Renu Manjusha" },
  { value: "N.S. Motor Finance Co. Ltd.", label: "N.S. Motor Finance Co. Ltd." },
  { value: "N.S.S.O. - C.S.B.", label: "N.S.S.O. - C.S.B." },
  { value: "N.T.P.C Ltd Dadri", label: "N.T.P.C Ltd Dadri" },
  { value: "N.T.P.C. Kawas,Surat", label: "N.T.P.C. Kawas,Surat" },
  { value: "Nabagram Peoples Co-Op Credit Bank Ltd.", label: "Nabagram Peoples Co-Op Credit Bank Ltd." },
  { value: "Nabanagara Urban Co-Op Bank Ltd.", label: "Nabanagara Urban Co-Op Bank Ltd." },
  { value: "Nabapalli Co-Op Bank Ltd", label: "Nabapalli Co-Op Bank Ltd" },
  { value: "Nabard", label: "Nabard" },
  { value: "Nabard Bank", label: "Nabard Bank" },
  { value: "Nadapuram Co-Op. Urban Bank Ltd.", label: "Nadapuram Co-Op. Urban Bank Ltd." },
  { value: "Nadia District Central Co-Op. Bank Ltd.", label: "Nadia District Central Co-Op. Bank Ltd." },
  { value: "Nadia District Central Co-Operative Bank Ltd.", label: "Nadia District Central Co-Operative Bank Ltd." },
  { value: "Nadia Gramin Bank", label: "Nadia Gramin Bank" },
  { value: "Nadiad Mercantile Co-Op Bank Ltd", label: "Nadiad Mercantile Co-Op Bank Ltd" },
  { value: "Nadiad Peoples Co Op Bank", label: "Nadiad Peoples Co Op Bank" },
  { value: "Naga Laxmi Hire Purchase", label: "Naga Laxmi Hire Purchase" },
  { value: "Naga Ratnamma Financiers", label: "Naga Ratnamma Financiers" },
  { value: "Nagaland Industrial Development Corporation Ltd", label: "Nagaland Industrial Development Corporation Ltd" },
  { value: "Nagaland Pulp Paper Crop. Ltd.", label: "Nagaland Pulp Paper Crop. Ltd." },
  { value: "Nagaland Rural Bank", label: "Nagaland Rural Bank" },
  { value: "Nagaland State Central Co-Oprative Bank Ltd.", label: "Nagaland State Central Co-Oprative Bank Ltd." },
  { value: "Nagaland State Tourism Development Corporation Ltd.", label: "Nagaland State Tourism Development Corporation Ltd." },
  { value: "Nagalingeshwar Ur Co-Op Credit Suc Ltd", label: "Nagalingeshwar Ur Co-Op Credit Suc Ltd" },
  { value: "Nagalkulam Primary Agri Co-Op. Bank Ltd.", label: "Nagalkulam Primary Agri Co-Op. Bank Ltd." },
  { value: "Nagamani Auto Financiers", label: "Nagamani Auto Financiers" },
  { value: "Naganjani Finance", label: "Naganjani Finance" },
  { value: "Naganur Urban Co-Op Credit Society Ltd", label: "Naganur Urban Co-Op Credit Society Ltd" },
  { value: "Nagaon V.K.S Vilas Seva Sanstha Maryadit Nagaon", label: "Nagaon V.K.S Vilas Seva Sanstha Maryadit Nagaon" },
  { value: "Nagaon Vks Vikas Seva Santha Maryadit", label: "Nagaon Vks Vikas Seva Santha Maryadit" },
  { value: "Nagar Co-Op Bank Ltd.", label: "Nagar Co-Op Bank Ltd." },
  { value: "Nagar Dist. Urban Co-Op Bank Ltd.", label: "Nagar Dist. Urban Co-Op Bank Ltd." },
  { value: "Nagar Nagri Sahakari Patsanstha", label: "Nagar Nagri Sahakari Patsanstha" },
  { value: "Nagar Prathmik Shikshan Samiti", label: "Nagar Prathmik Shikshan Samiti" },
  { value: "Nagar Samabai Bank Ltd", label: "Nagar Samabai Bank Ltd" },
  { value: "Nagar Urban Co Op Bank Ltd", label: "Nagar Urban Co Op Bank Ltd" },
  { value: "Nagar Urban Co Op Bank Ltd.", label: "Nagar Urban Co Op Bank Ltd." },
  { value: "Nagar Urban Co-Op. Bank Ltd.", label: "Nagar Urban Co-Op. Bank Ltd." },
  { value: "Nagar Vikas Sahkari Bank Ltd", label: "Nagar Vikas Sahkari Bank Ltd" },
  { value: "Nagaraja Finance", label: "Nagaraja Finance" },
  { value: "Nagargao V K S Society Ltd", label: "Nagargao V K S Society Ltd" },
  { value: "Nagari Sahakari Bank Ltd.", label: "Nagari Sahakari Bank Ltd." },
  { value: "Nagaria Sahakari Bank Ltd., Varanasi", label: "Nagaria Sahakari Bank Ltd., Varanasi" },
  { value: "Nagarik Sahakari Bank Maryadit", label: "Nagarik Sahakari Bank Maryadit" },
  { value: "Nagarjuna Agro Services", label: "Nagarjuna Agro Services" },
  { value: "Nagarjuna Financiers", label: "Nagarjuna Financiers" },
  { value: "Nagarjuna Grameena Bank", label: "Nagarjuna Grameena Bank" },
  { value: "Nagarjuna Grameena Bank", label: "Nagarjuna Grameena Bank" },
  { value: "Nagaroor Service Co-Op. Bk.L.", label: "Nagaroor Service Co-Op. Bk.L." },
  { value: "Nagaur District Central Co-Operative Bank Ltd.", label: "Nagaur District Central Co-Operative Bank Ltd." },
  { value: "Nagaur Urban Co-Operative Bank Ltd.", label: "Nagaur Urban Co-Operative Bank Ltd." },
  { value: "Nagavalli Finance", label: "Nagavalli Finance" },
  { value: "Nagdev Finance", label: "Nagdev Finance" },
  { value: "Nageswar Auto Financiers", label: "Nageswar Auto Financiers" },
  { value: "Nagina Urban Co-Operative Bank Ltd", label: "Nagina Urban Co-Operative Bank Ltd" },
  { value: "Nagnath Finance", label: "Nagnath Finance" },
  { value: "Nagnath Urban Co-Op. Bank Ltd.", label: "Nagnath Urban Co-Op. Bank Ltd." },
  { value: "Nagnur Ur Cr Souhard Sahakari Ltd.", label: "Nagnur Ur Cr Souhard Sahakari Ltd." },
  { value: "Nagothane Viyapari Sahakari Pathsanstha Mard", label: "Nagothane Viyapari Sahakari Pathsanstha Mard" },
  { value: "Nagpur Dist. Central Co-Op Bank Ltd.", label: "Nagpur Dist. Central Co-Op Bank Ltd." },
  { value: "Nagpur Lorry Trade And Fin Corp", label: "Nagpur Lorry Trade And Fin Corp" },
  { value: "Nagpur Mahanagar Palika Karmachari Sah. Bank Ltd", label: "Nagpur Mahanagar Palika Karmachari Sah. Bank Ltd" },
  { value: "Nagpur Mahila Nag. Sah. Bank Ltd.", label: "Nagpur Mahila Nag. Sah. Bank Ltd." },
  { value: "Nagpur Nag. Sah. Bank Ltd.", label: "Nagpur Nag. Sah. Bank Ltd." },
  { value: "Nagpur Nagarik Sahakari Bank Ltd.", label: "Nagpur Nagarik Sahakari Bank Ltd." },
  { value: "Nagpur Nagrik Karmchari Bank", label: "Nagpur Nagrik Karmchari Bank" },
  { value: "Nagpur Nagrik Sahakari Bank", label: "Nagpur Nagrik Sahakari Bank" },
  { value: "Nagpur Sahakari Bank Ltd", label: "Nagpur Sahakari Bank Ltd" },
  { value: "Nagpur Urban Co-Op Bank Ltd.", label: "Nagpur Urban Co-Op Bank Ltd." },
  { value: "Nagraj Finance", label: "Nagraj Finance" },
  { value: "Nagrik Sah. Bank Ltd., Indore", label: "Nagrik Sah. Bank Ltd., Indore" },
  { value: "Nagrik Sahakari Bank Maryadit", label: "Nagrik Sahakari Bank Maryadit" },
  { value: "Nagrik Sahkari Bank Ltd", label: "Nagrik Sahkari Bank Ltd" },
  { value: "Nagrik Sehakari Kshetriye Gramin Bank", label: "Nagrik Sehakari Kshetriye Gramin Bank" },
  { value: "Nagrota Co-Op Urban T & C Soc Ltd.", label: "Nagrota Co-Op Urban T & C Soc Ltd." },
  { value: "Nagu Financiers", label: "Nagu Financiers" },
  { value: "Naguri Vyavasaya Seva Sah. Bk, Niya", label: "Naguri Vyavasaya Seva Sah. Bk, Niya" },
  { value: "Nahar Credit Co", label: "Nahar Credit Co" },
  { value: "Nahar Finance Ltd.", label: "Nahar Finance Ltd." },
  { value: "Nahar Motor Finance", label: "Nahar Motor Finance" },
  { value: "Nahata Motor Finance", label: "Nahata Motor Finance" },
  { value: "Nahidzohra M Bukhari", label: "Nahidzohra M Bukhari" },
  { value: "Naik Leasing Finance Ltd", label: "Naik Leasing Finance Ltd" },
  { value: "Naincy Khatod", label: "Naincy Khatod" },
  { value: "Naini Fincap Limited", label: "Naini Fincap Limited" },
  { value: "Nainital Almora Kshitkjya Gramin Bank", label: "Nainital Almora Kshitkjya Gramin Bank" },
  { value: "Nainital Bank Ltd.", label: "Nainital Bank Ltd." },
  { value: "Nainital Distt Co-Operative Bank", label: "Nainital Distt Co-Operative Bank" },
  { value: "Nainital Distt. Co-Operative Bank Ltd.", label: "Nainital Distt. Co-Operative Bank Ltd." },
  { value: "Nainital-Almora Kshetriya Gramin Bank", label: "Nainital-Almora Kshetriya Gramin Bank" },
  { value: "Naiv Hind Co Op Society Belgaum", label: "Naiv Hind Co Op Society Belgaum" },
  { value: "Naivhind Co Op Society", label: "Naivhind Co Op Society" },
  { value: "Najeeb T S", label: "Najeeb T S" },
  { value: "Nak G Bank", label: "Nak G Bank" },
  { value: "Nakoda Auto", label: "Nakoda Auto" },
  { value: "Nakoda Auto Finance", label: "Nakoda Auto Finance" },
  { value: "Nakoda Auto Mobile Finance", label: "Nakoda Auto Mobile Finance" },
  { value: "Nakoda Finance", label: "Nakoda Finance" },
  { value: "Nakoda Finance Co.", label: "Nakoda Finance Co." },
  { value: "Nakoda Investment", label: "Nakoda Investment" },
  { value: "Nakoda Motors", label: "Nakoda Motors" },
  { value: "Nakodar Hindu Urban Co-Op Bank Ltd", label: "Nakodar Hindu Urban Co-Op Bank Ltd" },
  { value: "Nalanda Central Co-Op Bank Ltd.", label: "Nalanda Central Co-Op Bank Ltd." },
  { value: "Nalanda Gramin Bank", label: "Nalanda Gramin Bank" },
  { value: "Nalbari Urban Co-Op Bank Ltd", label: "Nalbari Urban Co-Op Bank Ltd" },
  { value: "Nalbari Urban Coop Bk Ltd.", label: "Nalbari Urban Coop Bk Ltd." },
  { value: "Nalco Damanjodi", label: "Nalco Damanjodi" },
  { value: "Naleen Auto Lees", label: "Naleen Auto Lees" },
  { value: "Nalgonda District Central Co-Operative Bank Ltd.", label: "Nalgonda District Central Co-Operative Bank Ltd." },
  { value: "Nalin Lease Finance Ltd.", label: "Nalin Lease Finance Ltd." },
  { value: "Nalini Siddaraj Urs", label: "Nalini Siddaraj Urs" },
  { value: "Nallam Co-Op Urban Bank Ltd.", label: "Nallam Co-Op Urban Bank Ltd." },
  { value: "Nallapati Ratnaiah Auto Mobile Finance", label: "Nallapati Ratnaiah Auto Mobile Finance" },
  { value: "Namakkal Andavar Business Credit Ltd.", label: "Namakkal Andavar Business Credit Ltd." },
  { value: "Naman Auto Finance", label: "Naman Auto Finance" },
  { value: "Naman Gupta", label: "Naman Gupta" },
  { value: "Namdev Leasing & Finance Pvt Ltd", label: "Namdev Leasing & Finance Pvt Ltd" },
  { value: "Namma Reliance Associates", label: "Namma Reliance Associates" },
  { value: "Namo Auto Finance", label: "Namo Auto Finance" },
  { value: "Namokar Fincap", label: "Namokar Fincap" },
  { value: "Namokkar Finance", label: "Namokkar Finance" },
  { value: "Namrata Gramin Bigar Sheti Sah Pat", label: "Namrata Gramin Bigar Sheti Sah Pat" },
  { value: "Namrata Investment", label: "Namrata Investment" },
  { value: "Nana Vada Juth Seva Sahakari Mandli", label: "Nana Vada Juth Seva Sahakari Mandli" },
  { value: "Nana Vada Juth Seva Sahkari Mandli", label: "Nana Vada Juth Seva Sahkari Mandli" },
  { value: "Nanak Finance Co.", label: "Nanak Finance Co." },
  { value: "Nancy Khatod", label: "Nancy Khatod" },
  { value: "Nanda Automobile Financiers", label: "Nanda Automobile Financiers" },
  { value: "Nanda Finance", label: "Nanda Finance" },
  { value: "Nanda Nagar Sahakari Sakhsansthan Maryadit", label: "Nanda Nagar Sahakari Sakhsansthan Maryadit" },
  { value: "Nandambakkam Finance Co Pvt. Ltd.", label: "Nandambakkam Finance Co Pvt. Ltd." },
  { value: "Nandani Sahakari Bank Ltd.", label: "Nandani Sahakari Bank Ltd." },
  { value: "Nanded District Central Co-Operative Bank Ltd.", label: "Nanded District Central Co-Operative Bank Ltd." },
  { value: "Nanded Merchants Co-Op Bank Ltd", label: "Nanded Merchants Co-Op Bank Ltd" },
  { value: "Nandeep Co-Op. Bank", label: "Nandeep Co-Op. Bank" },
  { value: "Nandgaon Merchants Co-Op Bank Ltd", label: "Nandgaon Merchants Co-Op Bank Ltd" },
  { value: "Nandha Auto Finance", label: "Nandha Auto Finance" },
  { value: "Nandha Auto Leasing", label: "Nandha Auto Leasing" },
  { value: "Nandhi Finance", label: "Nandhi Finance" },
  { value: "Nandhini V K", label: "Nandhini V K" },
  { value: "Nandhu Auto Leasing", label: "Nandhu Auto Leasing" },
  { value: "Nandhu Auto Leasing", label: "Nandhu Auto Leasing" },
  { value: "Nandi Auto Finance", label: "Nandi Auto Finance" },
  { value: "Nandi Devi Bhandari", label: "Nandi Devi Bhandari" },
  { value: "Nandi Finance And Leasing", label: "Nandi Finance And Leasing" },
  { value: "Nandi Vehicle Finance", label: "Nandi Vehicle Finance" },
  { value: "Nandini Finance Private Limited", label: "Nandini Finance Private Limited" },
  { value: "Nandini Sahakari Bank Ltd", label: "Nandini Sahakari Bank Ltd" },
  { value: "Nandkumar Zaware Gramin Bigar Sheti Sah Pat", label: "Nandkumar Zaware Gramin Bigar Sheti Sah Pat" },
  { value: "Nandni Sahakari Bank Ltd", label: "Nandni Sahakari Bank Ltd" },
  { value: "Nandni Sahkari Bank Ltd", label: "Nandni Sahkari Bank Ltd" },
  { value: "Nandol Seva Sahkari Mandali", label: "Nandol Seva Sahkari Mandali" },
  { value: "Nandura Urban Co-Op Bank Ltd", label: "Nandura Urban Co-Op Bank Ltd" },
  { value: "Nandurbar Merchants Co-Op Bank Ltd", label: "Nandurbar Merchants Co-Op Bank Ltd" },
  { value: "Nanduri Sahakari Bank Ltd", label: "Nanduri Sahakari Bank Ltd" },
  { value: "Nanesh Corporation", label: "Nanesh Corporation" },
  { value: "Nanesh Finance", label: "Nanesh Finance" },
  { value: "Nangavalli Parimary Agricultural C.B.L.", label: "Nangavalli Parimary Agricultural C.B.L." },
  { value: "Nanidevi Bandari", label: "Nanidevi Bandari" },
  { value: "Nanital District Co Operative Bank Ltd.", label: "Nanital District Co Operative Bank Ltd." },
  { value: "Nanjarayapatana Vyavasaya Seva Sah Bk Lt", label: "Nanjarayapatana Vyavasaya Seva Sah Bk Lt" },
  { value: "Nanjeet Auto Consultant & Finance", label: "Nanjeet Auto Consultant & Finance" },
  { value: "Nanminda Co -Op Rural Bank Ltd", label: "Nanminda Co -Op Rural Bank Ltd" },
  { value: "Nannambra Service Co-Op Bank Ltd.", label: "Nannambra Service Co-Op Bank Ltd." },
  { value: "Nantha Auto Finance", label: "Nantha Auto Finance" },
  { value: "Nantha Finance", label: "Nantha Finance" },
  { value: "Nanthaa Finance", label: "Nanthaa Finance" },
  { value: "Napoklu Vyavasaya Seva Sahakari Bank", label: "Napoklu Vyavasaya Seva Sahakari Bank" },
  { value: "Naptha Jhakari Power Corporation Ltd.", label: "Naptha Jhakari Power Corporation Ltd." },
  { value: "Narang Auto Consult", label: "Narang Auto Consult" },
  { value: "Narang Leasing & Finance", label: "Narang Leasing & Finance" },
  { value: "Narayan Guru Urban Co-Operative Bank  Ltd", label: "Narayan Guru Urban Co-Operative Bank  Ltd" },
  { value: "Narayana  Investments", label: "Narayana  Investments" },
  { value: "Narayana Guru Urban Co-Op Bank Ltd - Udupi", label: "Narayana Guru Urban Co-Op Bank Ltd - Udupi" },
  { value: "Narayana Swamy Automobile Finance", label: "Narayana Swamy Automobile Finance" },
  { value: "Narayangad Nagari Sahakari Pat Sanstha", label: "Narayangad Nagari Sahakari Pat Sanstha" },
  { value: "Narayanrao Pawar Nagari Sah Patsanstha Maryadit", label: "Narayanrao Pawar Nagari Sah Patsanstha Maryadit" },
  { value: "Narbheram Finance Co Ltd", label: "Narbheram Finance Co Ltd" },
  { value: "Narbheram Finance Co. Ltd. A/C L & T Finance Ltd", label: "Narbheram Finance Co. Ltd. A/C L & T Finance Ltd" },
  { value: "Narender Singh Bisht", label: "Narender Singh Bisht" },
  { value: "Narendra Fin Co", label: "Narendra Fin Co" },
  { value: "Narendra Finance", label: "Narendra Finance" },
  { value: "Narendra Finance Company", label: "Narendra Finance Company" },
  { value: "Narendra Investments", label: "Narendra Investments" },
  { value: "Narendra Keshavrao Patil", label: "Narendra Keshavrao Patil" },
  { value: "Narendra Kumar Choradia", label: "Narendra Kumar Choradia" },
  { value: "Narendra Kumar Choradia", label: "Narendra Kumar Choradia" },
  { value: "Narendra Shantilal Nahar Mandsaur", label: "Narendra Shantilal Nahar Mandsaur" },
  { value: "Naresh Auto Finance", label: "Naresh Auto Finance" },
  { value: "Naresh Auto Finance Investment", label: "Naresh Auto Finance Investment" },
  { value: "Naresh Auto Investment", label: "Naresh Auto Investment" },
  { value: "Naresh Finance", label: "Naresh Finance" },
  { value: "Naresh Jain", label: "Naresh Jain" },
  { value: "Naresh Kumar Bokdia Finance", label: "Naresh Kumar Bokdia Finance" },
  { value: "Naresh Mehta & Co", label: "Naresh Mehta & Co" },
  { value: "Naresh Motor Finance Co", label: "Naresh Motor Finance Co" },
  { value: "Nargis Naqi Khan", label: "Nargis Naqi Khan" },
  { value: "Narinder Singh And Sons Pvt Ltd", label: "Narinder Singh And Sons Pvt Ltd" },
  { value: "Narindera Finance Corporation", label: "Narindera Finance Corporation" },
  { value: "Narlapor Finance And Investments", label: "Narlapor Finance And Investments" },
  { value: "Narmada Control Authority", label: "Narmada Control Authority" },
  { value: "Narmada Gining Factory", label: "Narmada Gining Factory" },
  { value: "Narmada Hire Purchase", label: "Narmada Hire Purchase" },
  { value: "Narmada Hydroelectric Dev Corp", label: "Narmada Hydroelectric Dev Corp" },
  { value: "Narmada Malava Gramin Bank", label: "Narmada Malava Gramin Bank" },
  { value: "Narmada Malwa Gramin Bank", label: "Narmada Malwa Gramin Bank" },
  { value: "Narmada Malwa Gramin Bank , Khujner", label: "Narmada Malwa Gramin Bank , Khujner" },
  { value: "Narmada Malwa Kshetriya Gramin Bank", label: "Narmada Malwa Kshetriya Gramin Bank" },
  { value: "Narmda Malva Gramin Bank", label: "Narmda Malva Gramin Bank" },
  { value: "Naroda Industrial Co-Op Bank Ltd", label: "Naroda Industrial Co-Op Bank Ltd" },
  { value: "Naroda Industrial Co.Op. Bank Ltd.", label: "Naroda Industrial Co.Op. Bank Ltd." },
  { value: "Naroda Nagrik Co-Op Bank Ltd., Naroda,Ahmedabad", label: "Naroda Nagrik Co-Op Bank Ltd., Naroda,Ahmedabad" },
  { value: "Naroda Union Co Op Bank Ltd", label: "Naroda Union Co Op Bank Ltd" },
  { value: "Narsingh G B S Patsanstha Ranjangaon", label: "Narsingh G B S Patsanstha Ranjangaon" },
  { value: "Narsinh Sahakari Pat", label: "Narsinh Sahakari Pat" },
  { value: "Narula Finance Co", label: "Narula Finance Co" },
  { value: "Nascent Retails Ltd", label: "Nascent Retails Ltd" },
  { value: "Nashik Dist. Girana Co-Op. Bank, Ltd.", label: "Nashik Dist. Girana Co-Op. Bank, Ltd." },
  { value: "Nashik Marchants Co Op Bank Ltd", label: "Nashik Marchants Co Op Bank Ltd" },
  { value: "Nashik Municipal Corporation", label: "Nashik Municipal Corporation" },
  { value: "Nasik Dist Industrial & Mercantile Cp Bk", label: "Nasik Dist Industrial & Mercantile Cp Bk" },
  { value: "Nasik District Central Co-Op. Bank Ltd., Nasik", label: "Nasik District Central Co-Op. Bank Ltd., Nasik" },
  { value: "Nasik District Girana Sahakari Bank Ltd", label: "Nasik District Girana Sahakari Bank Ltd" },
  { value: "Nasik District Industrial And Mercantile Co-Op. Bank Ltd., Nasik", label: "Nasik District Industrial And Mercantile Co-Op. Bank Ltd., Nasik" },
  { value: "Nasik Jilha Mahila Sahakari Bank Ltd., Nasik", label: "Nasik Jilha Mahila Sahakari Bank Ltd., Nasik" },
  { value: "Nasik Jilha Mahila Vikas Sahakari Bank Ltd., Nasik", label: "Nasik Jilha Mahila Vikas Sahakari Bank Ltd., Nasik" },
  { value: "Nasik Jillah Mahilla Vikas Sah. Bank Ltd", label: "Nasik Jillah Mahilla Vikas Sah. Bank Ltd" },
  { value: "Nasik Merchants Co-Op. Bank Ltd., Nasik", label: "Nasik Merchants Co-Op. Bank Ltd., Nasik" },
  { value: "Nasik Peoples Co-Op.Bank Ltd., Nasik", label: "Nasik Peoples Co-Op.Bank Ltd., Nasik" },
  { value: "Nasik Road Deolali Vyapari Sah.Bank Ltd., Nasik Road", label: "Nasik Road Deolali Vyapari Sah.Bank Ltd., Nasik Road" },
  { value: "Nasik Road Nagari Sahakari Pathsanstha Ltd", label: "Nasik Road Nagari Sahakari Pathsanstha Ltd" },
  { value: "Natakala Tripura Balu", label: "Natakala Tripura Balu" },
  { value: "Nathaji Naik Gramin Sahakari Bigar Sheti Path Sanstha Mary", label: "Nathaji Naik Gramin Sahakari Bigar Sheti Path Sanstha Mary" },
  { value: "Nathalal Mohanlal", label: "Nathalal Mohanlal" },
  { value: "Natham Primary Agricultural Co.Op Bk", label: "Natham Primary Agricultural Co.Op Bk" },
  { value: "Natin Lease Finance Ltd", label: "Natin Lease Finance Ltd" },
  { value: "Nation Wide Finance Ltd.", label: "Nation Wide Finance Ltd." },
  { value: "National Academy Of Customs, Excise And Narcotics", label: "National Academy Of Customs, Excise And Narcotics" },
  { value: "National Aerospace Laboratories", label: "National Aerospace Laboratories" },
  { value: "National Agricultural Cooperative Marketing Federation Of India", label: "National Agricultural Cooperative Marketing Federation Of India" },
  { value: "National Aids Control Organisation", label: "National Aids Control Organisation" },
  { value: "National Aluminum Company Ltd", label: "National Aluminum Company Ltd" },
  { value: "National Archives Of India", label: "National Archives Of India" },
  { value: "National Auto Sales", label: "National Auto Sales" },
  { value: "National Auto Sales", label: "National Auto Sales" },
  { value: "National Automobiles", label: "National Automobiles" },
  { value: "National Backward Classes Finance And Development Co", label: "National Backward Classes Finance And Development Co" },
  { value: "National Bank", label: "National Bank" },
  { value: "National Bank For Agriculture And Rural Development", label: "National Bank For Agriculture And Rural Development" },
  { value: "National Bicycle Corporation Of India Ltd.", label: "National Bicycle Corporation Of India Ltd." },
  { value: "National Board Of Examinations", label: "National Board Of Examinations" },
  { value: "National Building Construction Corporation Ltd.", label: "National Building Construction Corporation Ltd." },
  { value: "National Buildings Construction Corporation Limited", label: "National Buildings Construction Corporation Limited" },
  { value: "National Bureau Of Animal Genetic Resources", label: "National Bureau Of Animal Genetic Resources" },
  { value: "National Bureau Of Plant Genetic Resources", label: "National Bureau Of Plant Genetic Resources" },
  { value: "National Bureau Of Plant Genetic Resources (Nbpgr)", label: "National Bureau Of Plant Genetic Resources (Nbpgr)" },
  { value: "National Cadet Corps", label: "National Cadet Corps" },
  { value: "National Capital Region Planning Board", label: "National Capital Region Planning Board" },
  { value: "National Centre For Agricultural Economics And Policy Research", label: "National Centre For Agricultural Economics And Policy Research" },
  { value: "National Centre For Biological Sciences", label: "National Centre For Biological Sciences" },
  { value: "National Centre For Electronic Switching", label: "National Centre For Electronic Switching" },
  { value: "National Centre For Medium Range Weather Forecasting", label: "National Centre For Medium Range Weather Forecasting" },
  { value: "National Centre For Software Technology", label: "National Centre For Software Technology" },
  { value: "National Centre For The Jute Diversification", label: "National Centre For The Jute Diversification" },
  { value: "National Centre For Trade Information", label: "National Centre For Trade Information" },
  { value: "National Chemical Laboratory", label: "National Chemical Laboratory" },
  { value: "National Co Bank", label: "National Co Bank" },
  { value: "National Co Op Bank Ltd", label: "National Co Op Bank Ltd" },
  { value: "National Co-Op Bank Ltd", label: "National Co-Op Bank Ltd" },
  { value: "National Co-Operative Consumers` Federation Of India Limited", label: "National Co-Operative Consumers` Federation Of India Limited" },
  { value: "National Commission For Minorities", label: "National Commission For Minorities" },
  { value: "National Commission For Scs And Sts", label: "National Commission For Scs And Sts" },
  { value: "National Commission On Population", label: "National Commission On Population" },
  { value: "National Consumer Disputes Redressal Commission", label: "National Consumer Disputes Redressal Commission" },
  { value: "National Cooperative Development Corporation", label: "National Cooperative Development Corporation" },
  { value: "National Cooperative Union Of India", label: "National Cooperative Union Of India" },
  { value: "National Council Of Educational Research And Training", label: "National Council Of Educational Research And Training" },
  { value: "National Credit Corporation", label: "National Credit Corporation" },
  { value: "National Culture Fund", label: "National Culture Fund" },
  { value: "National Dairy Research Institute", label: "National Dairy Research Institute" },
  { value: "National Design Centre", label: "National Design Centre" },
  { value: "National Environmental Engineering Research Institute", label: "National Environmental Engineering Research Institute" },
  { value: "National Family Health Survey", label: "National Family Health Survey" },
  { value: "National Fertilizers Limited", label: "National Fertilizers Limited" },
  { value: "National Film Development Corporation Limited", label: "National Film Development Corporation Limited" },
  { value: "National Finance Co.", label: "National Finance Co." },
  { value: "National Geophysical Research Institute", label: "National Geophysical Research Institute" },
  { value: "National Handicapped Finance And Development Co", label: "National Handicapped Finance And Development Co" },
  { value: "National Handloom Development Corporation Limited", label: "National Handloom Development Corporation Limited" },
  { value: "National Horticulture Board", label: "National Horticulture Board" },
  { value: "National Housing Bank", label: "National Housing Bank" },
  { value: "National Human Rights Commission", label: "National Human Rights Commission" },
  { value: "National Hydroelectric Power Corporation Ltd.", label: "National Hydroelectric Power Corporation Ltd." },
  { value: "National Hyoroelectric Development Corp", label: "National Hyoroelectric Development Corp" },
  { value: "National Industrial Development Corporation Ltd.", label: "National Industrial Development Corporation Ltd." },
  { value: "National Informatics Centre", label: "National Informatics Centre" },
  { value: "National Institute For Small Industries Extension Training", label: "National Institute For Small Industries Extension Training" },
  { value: "National Institute Of Agricultural Extension Management", label: "National Institute Of Agricultural Extension Management" },
  { value: "National Institute Of Bank Management", label: "National Institute Of Bank Management" },
  { value: "National Institute Of Design", label: "National Institute Of Design" },
  { value: "National Institute Of Educational Planning And Administration", label: "National Institute Of Educational Planning And Administration" },
  { value: "National Institute Of Fashion Technology", label: "National Institute Of Fashion Technology" },
  { value: "National Institute Of Financial Management", label: "National Institute Of Financial Management" },
  { value: "National Institute Of Hydrology", label: "National Institute Of Hydrology" },
  { value: "National Institute Of Industrial Engineering", label: "National Institute Of Industrial Engineering" },
  { value: "National Institute Of Ocean Technology", label: "National Institute Of Ocean Technology" },
  { value: "National Institute Of Pharmaceutical Education And Research", label: "National Institute Of Pharmaceutical Education And Research" },
  { value: "National Institute Of Rural Development", label: "National Institute Of Rural Development" },
  { value: "National Institute Of Science Communication", label: "National Institute Of Science Communication" },
  { value: "National Institute Of Science, Technology And Development Studies", label: "National Institute Of Science, Technology And Development Studies" },
  { value: "National Institute Of Urban Affairs", label: "National Institute Of Urban Affairs" },
  { value: "National Instruments Ltd.", label: "National Instruments Ltd." },
  { value: "National Insurance Company Limited", label: "National Insurance Company Limited" },
  { value: "National Jute Manufactures Corp.. Ltd.", label: "National Jute Manufactures Corp.. Ltd." },
  { value: "National Law School Of India", label: "National Law School Of India" },
  { value: "National Law University, Jodhpur", label: "National Law University, Jodhpur" },
  { value: "National Mercantile Co-Op. Bank Ltd", label: "National Mercantile Co-Op. Bank Ltd" },
  { value: "National Mineral Devolopement Corporation", label: "National Mineral Devolopement Corporation" },
  { value: "National Minorities Development And Finance Corporation Limited", label: "National Minorities Development And Finance Corporation Limited" },
  { value: "National Minorities Multipurpose C.B.L.", label: "National Minorities Multipurpose C.B.L." },
  { value: "National Pharmaceutical Pricing Authority", label: "National Pharmaceutical Pricing Authority" },
  { value: "National Productivity Council", label: "National Productivity Council" },
  { value: "National Project Construction Corporation Ltd.", label: "National Project Construction Corporation Ltd." },
  { value: "National Remote Sensing Agency", label: "National Remote Sensing Agency" },
  { value: "National Research Centre For Agroforestory", label: "National Research Centre For Agroforestory" },
  { value: "National Research Centre For Cashew", label: "National Research Centre For Cashew" },
  { value: "National Research Centre For Mushroom", label: "National Research Centre For Mushroom" },
  { value: "National Research Centre For Oil Palm", label: "National Research Centre For Oil Palm" },
  { value: "National Research Centre On Yak", label: "National Research Centre On Yak" },
  { value: "National Research Development Corp..", label: "National Research Development Corp.." },
  { value: "National Resource Centre For Value Education", label: "National Resource Centre For Value Education" },
  { value: "National Safai Karacharis Finance And Development", label: "National Safai Karacharis Finance And Development" },
  { value: "National Sc/St Finance And Development Corp.", label: "National Sc/St Finance And Development Corp." },
  { value: "National Seeds Corporation Ltd.", label: "National Seeds Corporation Ltd." },
  { value: "National Small Industries Corporation Limited", label: "National Small Industries Corporation Limited" },
  { value: "National Test House", label: "National Test House" },
  { value: "National Textile Corporation Ltd.", label: "National Textile Corporation Ltd." },
  { value: "National Textiles Corporation (A.P., Karnataka, Kerala And Mahe) Ltd.", label: "National Textiles Corporation (A.P., Karnataka, Kerala And Mahe) Ltd." },
  { value: "National Textiles Corporation (Delhi Punjab, Rajasthan) Ltd.", label: "National Textiles Corporation (Delhi Punjab, Rajasthan) Ltd." },
  { value: "National Textiles Corporation (Gujrat) Ltd.", label: "National Textiles Corporation (Gujrat) Ltd." },
  { value: "National Textiles Corporation (Holding Co.) Ltd", label: "National Textiles Corporation (Holding Co.) Ltd" },
  { value: "National Textiles Corporation (Madhya Pradesh) Ltd.", label: "National Textiles Corporation (Madhya Pradesh) Ltd." },
  { value: "National Textiles Corporation (Maharashtra North) Ltd.", label: "National Textiles Corporation (Maharashtra North) Ltd." },
  { value: "National Textiles Corporation (South Maharashtra) Ltd.", label: "National Textiles Corporation (South Maharashtra) Ltd." },
  { value: "National Textiles Corporation (Tamilnadu, Pondicherry) Ltd.", label: "National Textiles Corporation (Tamilnadu, Pondicherry) Ltd." },
  { value: "National Textiles Corporation (Uttar Pradesh) Ltd.", label: "National Textiles Corporation (Uttar Pradesh) Ltd." },
  { value: "National Textiles Corporation (West Bengal, Assam, Bihar And Orissa)", label: "National Textiles Corporation (West Bengal, Assam, Bihar And Orissa)" },
  { value: "National Thermal Power Corporation Ltd.", label: "National Thermal Power Corporation Ltd." },
  { value: "National Tuberculosis Institute", label: "National Tuberculosis Institute" },
  { value: "National Urban Co-Operative Bk Ltd.", label: "National Urban Co-Operative Bk Ltd." },
  { value: "Nationwide Investments Ltd.", label: "Nationwide Investments Ltd." },
  { value: "Natpur Co-Op Bank Ltd", label: "Natpur Co-Op Bank Ltd" },
  { value: "Natraj Finance", label: "Natraj Finance" },
  { value: "Natraj Finance", label: "Natraj Finance" },
  { value: "Natural Disaster Management", label: "Natural Disaster Management" },
  { value: "Natvarlal Vepari & Co.Nanpura ,Surat", label: "Natvarlal Vepari & Co.Nanpura ,Surat" },
  { value: "Nau-Nidh Finance Ltd", label: "Nau-Nidh Finance Ltd" },
  { value: "Nav Durga Co-Oprative", label: "Nav Durga Co-Oprative" },
  { value: "Nav Jeevan Co-Op Bank Ltd", label: "Nav Jeevan Co-Op Bank Ltd" },
  { value: "Nav Sandesh Co Op Cr Soc Ltd", label: "Nav Sandesh Co Op Cr Soc Ltd" },
  { value: "Nav Sandesh Sahakari Patpedhi Ltd", label: "Nav Sandesh Sahakari Patpedhi Ltd" },
  { value: "Nava Karnataka Credit Co Op Society Ltd", label: "Nava Karnataka Credit Co Op Society Ltd" },
  { value: "Nava Karnataka Swa Sahaya Sangha", label: "Nava Karnataka Swa Sahaya Sangha" },
  { value: "Nava Sakthi Auto Financiers", label: "Nava Sakthi Auto Financiers" },
  { value: "Nava Vada Juth Seva Sah. Mandli Ltd.", label: "Nava Vada Juth Seva Sah. Mandli Ltd." },
  { value: "Nava Yuga Hire Purchase And Finance", label: "Nava Yuga Hire Purchase And Finance" },
  { value: "Navadaya Leasing ( R )", label: "Navadaya Leasing ( R )" },
  { value: "Navajyothi Finance Corporation  Vijayawada.", label: "Navajyothi Finance Corporation  Vijayawada." },
  { value: "Navakar Finance Ltd", label: "Navakar Finance Ltd" },
  { value: "Navakaranataka Pattein Souharda Sahakari Ltd", label: "Navakaranataka Pattein Souharda Sahakari Ltd" },
  { value: "Navakarnataka Credit Co-Operative Society Ltd", label: "Navakarnataka Credit Co-Operative Society Ltd" },
  { value: "Naval Dockyard Co-Op. Bank", label: "Naval Dockyard Co-Op. Bank" },
  { value: "Naval Group Insu. Scheme", label: "Naval Group Insu. Scheme" },
  { value: "Naval Group Insurance", label: "Naval Group Insurance" },
  { value: "Naval Group Insurance Fund", label: "Naval Group Insurance Fund" },
  { value: "Navaladiyan Auto Finance", label: "Navaladiyan Auto Finance" },
  { value: "Navanagar Co Op Bank", label: "Navanagar Co Op Bank" },
  { value: "Navanagar Urban Co-Op B L.", label: "Navanagar Urban Co-Op B L." },
  { value: "Navanagar Urban Co-Op Bank Ltd", label: "Navanagar Urban Co-Op Bank Ltd" },
  { value: "Navanagar Urban Co-Operative Bank", label: "Navanagar Urban Co-Operative Bank" },
  { value: "Navanagar Urban Coop Bank Ltd.", label: "Navanagar Urban Coop Bank Ltd." },
  { value: "Navanidhi Finance", label: "Navanidhi Finance" },
  { value: "Navapura Urben Co Op Ce. So Ltd", label: "Navapura Urben Co Op Ce. So Ltd" },
  { value: "Navarang Investment", label: "Navarang Investment" },
  { value: "Navaratanmal Jain Automobiles", label: "Navaratanmal Jain Automobiles" },
  { value: "Navarathanamal Jain Finance", label: "Navarathanamal Jain Finance" },
  { value: "Navarathna Credit Co-Op Society Ltd.", label: "Navarathna Credit Co-Op Society Ltd." },
  { value: "Navaratra Gramin Bigar Sah Pat Sanstha Maryadit Branch Malkapur", label: "Navaratra Gramin Bigar Sah Pat Sanstha Maryadit Branch Malkapur" },
  { value: "Navas Automobiles", label: "Navas Automobiles" },
  { value: "Navatha Auto Finance", label: "Navatha Auto Finance" },
  { value: "Navbharat Co- Op Urban Bank Ltd", label: "Navbharat Co- Op Urban Bank Ltd" },
  { value: "Navbharat Urban Co-Op Credit Soc Ltd", label: "Navbharat Urban Co-Op Credit Soc Ltd" },
  { value: "Navdeep Automobile Financiers", label: "Navdeep Automobile Financiers" },
  { value: "Navdeep Budhiraja", label: "Navdeep Budhiraja" },
  { value: "Navdeep Co-Op Credit Soc Ltd", label: "Navdeep Co-Op Credit Soc Ltd" },
  { value: "Navdurga Nagari Sahakari Pat San", label: "Navdurga Nagari Sahakari Pat San" },
  { value: "Naveen Finance", label: "Naveen Finance" },
  { value: "Naveen Investments", label: "Naveen Investments" },
  { value: "Naveen Kumar Financier", label: "Naveen Kumar Financier" },
  { value: "Navel Software India Pvt. Ltd.", label: "Navel Software India Pvt. Ltd." },
  { value: "Navhind Co Op Credit Society Ltd", label: "Navhind Co Op Credit Society Ltd" },
  { value: "Navhind Co Op Credit Society Ltd", label: "Navhind Co Op Credit Society Ltd" },
  { value: "Navhind Multistate Co-Operative Credit Society Ltd.", label: "Navhind Multistate Co-Operative Credit Society Ltd." },
  { value: "Navi Krupa Co-Op Credit Soc Ltd", label: "Navi Krupa Co-Op Credit Soc Ltd" },
  { value: "Navi Mumbai Co-Op Bank Ltd", label: "Navi Mumbai Co-Op Bank Ltd" },
  { value: "Navi Mumbai Co-Operative Bank Ltd", label: "Navi Mumbai Co-Operative Bank Ltd" },
  { value: "Navi Mumbai Municipal Corporation.", label: "Navi Mumbai Municipal Corporation." },
  { value: "Navi Mumbai Nagari Sahakari Bank Ltd.", label: "Navi Mumbai Nagari Sahakari Bank Ltd." },
  { value: "Navi Mumbai Nagarik Sahakari Bank Ltd", label: "Navi Mumbai Nagarik Sahakari Bank Ltd" },
  { value: "Navin Finance", label: "Navin Finance" },
  { value: "Navin Kumar", label: "Navin Kumar" },
  { value: "Navin Kumar Nahar Financier", label: "Navin Kumar Nahar Financier" },
  { value: "Navin Kumar Nahr", label: "Navin Kumar Nahr" },
  { value: "Navin Kumar Nayar", label: "Navin Kumar Nayar" },
  { value: "Navin Kumar Nhar", label: "Navin Kumar Nhar" },
  { value: "Navin Motors Limited", label: "Navin Motors Limited" },
  { value: "Navin Motors Ltd", label: "Navin Motors Ltd" },
  { value: "Navin Syndicate", label: "Navin Syndicate" },
  { value: "Navinbhai Babubhai Patel,Surat", label: "Navinbhai Babubhai Patel,Surat" },
  { value: "Navjeevan Associates", label: "Navjeevan Associates" },
  { value: "Navjeevan Auto Finance", label: "Navjeevan Auto Finance" },
  { value: "Navjeevan Gramin Biger Sheti Sahkari Patsanstha Mydt", label: "Navjeevan Gramin Biger Sheti Sahkari Patsanstha Mydt" },
  { value: "Navjivan Auto Finance", label: "Navjivan Auto Finance" },
  { value: "Navjivan Auto Loans", label: "Navjivan Auto Loans" },
  { value: "Navjivan Co-Op Credit Soc Ltd", label: "Navjivan Co-Op Credit Soc Ltd" },
  { value: "Navjivan Industrial Co Op Bank Ltd", label: "Navjivan Industrial Co Op Bank Ltd" },
  { value: "Navjivan Nagari Sahakari Bank Maryadit,", label: "Navjivan Nagari Sahakari Bank Maryadit," },
  { value: "Navjivan Nagarik Sahakari Bank Ltd", label: "Navjivan Nagarik Sahakari Bank Ltd" },
  { value: "Navjivan Sahakari Pantsanshtha Maryadit  Financial City", label: "Navjivan Sahakari Pantsanshtha Maryadit  Financial City" },
  { value: "Navkalyan Co-Op Bank Ltd", label: "Navkalyan Co-Op Bank Ltd" },
  { value: "Navkar Auto Mobile And Finance", label: "Navkar Auto Mobile And Finance" },
  { value: "Navkar Finance", label: "Navkar Finance" },
  { value: "Navkar Finance Corporation", label: "Navkar Finance Corporation" },
  { value: "Navkar Investment", label: "Navkar Investment" },
  { value: "Navkar Morots ,Surat", label: "Navkar Morots ,Surat" },
  { value: "Navkar Share & Stock Brokers", label: "Navkar Share & Stock Brokers" },
  { value: "Navkar Syndicate Chennai", label: "Navkar Syndicate Chennai" },
  { value: "Navlai Gramin Bigarshet Sahakari Patsanstha Ltd", label: "Navlai Gramin Bigarshet Sahakari Patsanstha Ltd" },
  { value: "Navlaukik Co Op Credit Soc Ltd", label: "Navlaukik Co Op Credit Soc Ltd" },
  { value: "Navlokik Co-Op Credit Society", label: "Navlokik Co-Op Credit Society" },
  { value: "Navmurti B J Kolse Patil Gramin Bigar", label: "Navmurti B J Kolse Patil Gramin Bigar" },
  { value: "Navnath Gramin Bigarsheti Sahakari Path Sanstha", label: "Navnath Gramin Bigarsheti Sahakari Path Sanstha" },
  { value: "Navneet Enterprises", label: "Navneet Enterprises" },
  { value: "Navnirman Bank", label: "Navnirman Bank" },
  { value: "Navnirman Co-Op Bank Ltd.", label: "Navnirman Co-Op Bank Ltd." },
  { value: "Navodaya  Leasing", label: "Navodaya  Leasing" },
  { value: "Navodaya Auto Finance", label: "Navodaya Auto Finance" },
  { value: "Navodaya Auto Finance Chits", label: "Navodaya Auto Finance Chits" },
  { value: "Navodaya Sahakari Bank Ltd", label: "Navodaya Sahakari Bank Ltd" },
  { value: "Navodaya Urban Co-Op Bank", label: "Navodaya Urban Co-Op Bank" },
  { value: "Navodaya Urban Co-Op Bank", label: "Navodaya Urban Co-Op Bank" },
  { value: "Navodaya Vidyalaya Samiti", label: "Navodaya Vidyalaya Samiti" },
  { value: "Navodit Sahakari Patpedhi Ltd", label: "Navodit Sahakari Patpedhi Ltd" },
  { value: "Navodit Shakari Patpedhi Ltd", label: "Navodit Shakari Patpedhi Ltd" },
  { value: "Navpad Investments", label: "Navpad Investments" },
  { value: "Navrang Finance Financie", label: "Navrang Finance Financie" },
  { value: "Navratan Finance", label: "Navratan Finance" },
  { value: "Navratan Mal Jain Automobiles", label: "Navratan Mal Jain Automobiles" },
  { value: "Navrathan Investment Bank", label: "Navrathan Investment Bank" },
  { value: "Navratna Insurance Broking", label: "Navratna Insurance Broking" },
  { value: "Navsandesh Sahakari Pathsantha Ltd", label: "Navsandesh Sahakari Pathsantha Ltd" },
  { value: "Navsandish Sahakari Pathsantha Marayadit", label: "Navsandish Sahakari Pathsantha Marayadit" },
  { value: "Navsari Peoples Co-Op Bank Ltd", label: "Navsari Peoples Co-Op Bank Ltd" },
  { value: "Navsarjan Industrial Co-Op Bank Ltd", label: "Navsarjan Industrial Co-Op Bank Ltd" },
  { value: "Navsarjan Industrial Co-Op Bank Ltd", label: "Navsarjan Industrial Co-Op Bank Ltd" },
  { value: "Navsarjan Industrial Co-Op Bank Ltd,", label: "Navsarjan Industrial Co-Op Bank Ltd," },
  { value: "Navsarjan Industrial Co-Op Bank Ltd.", label: "Navsarjan Industrial Co-Op Bank Ltd." },
  { value: "Navshakti Financial Services", label: "Navshakti Financial Services" },
  { value: "Navshandesh Sahakari Patsanstha Maryadit", label: "Navshandesh Sahakari Patsanstha Maryadit" },
  { value: "Navya Sri Automobile Financiers", label: "Navya Sri Automobile Financiers" },
  { value: "Navyatha Auto Finance", label: "Navyatha Auto Finance" },
  { value: "Nawal Enterprises", label: "Nawal Enterprises" },
  { value: "Nawanshahar District Central Co-Operative Bank Ltd.", label: "Nawanshahar District Central Co-Operative Bank Ltd." },
  { value: "Nawanshahar Finance Pvt. Ltd", label: "Nawanshahar Finance Pvt. Ltd" },
  { value: "Nawapur Mercantile Co-Op.Bank Ltd.,", label: "Nawapur Mercantile Co-Op.Bank Ltd.," },
  { value: "Nawashahr Central Co.Op Bank", label: "Nawashahr Central Co.Op Bank" },
  { value: "Nawaz Auto Consultant And Finance", label: "Nawaz Auto Consultant And Finance" },
  { value: "Nayagarh District Central Co-Operative Bank Ltd.", label: "Nayagarh District Central Co-Operative Bank Ltd." },
  { value: "Nayakanahatty Resource Finance", label: "Nayakanahatty Resource Finance" },
  { value: "Nayaki Finance", label: "Nayaki Finance" },
  { value: "Nazar Financial", label: "Nazar Financial" },
  { value: "Nazar M H", label: "Nazar M H" },
  { value: "Ncc Finance Ltd.", label: "Ncc Finance Ltd." },
  { value: "Nck Finance", label: "Nck Finance" },
  { value: "Nddcc Bank", label: "Nddcc Bank" },
  { value: "Nedfi Limited", label: "Nedfi Limited" },
  { value: "Nedumangad Co-Operative Urban Bank Ltd", label: "Nedumangad Co-Operative Urban Bank Ltd" },
  { value: "Nedumangad Co-Operative Urban Bank Ltd.", label: "Nedumangad Co-Operative Urban Bank Ltd." },
  { value: "Nedumkandam Service Co-Op. Bank Ltd.", label: "Nedumkandam Service Co-Op. Bank Ltd." },
  { value: "Nedungadi Bank Ltd.", label: "Nedungadi Bank Ltd." },
  { value: "Needs Of Life Co-Op Bank Ltd", label: "Needs Of Life Co-Op Bank Ltd" },
  { value: "Neel Kanth Financer Corp", label: "Neel Kanth Financer Corp" },
  { value: "Neelachal Gramya Bank,", label: "Neelachal Gramya Bank," },
  { value: "Neelachal Ispat Nigam Limited", label: "Neelachal Ispat Nigam Limited" },
  { value: "Neelakanteshwara Sou Pattina Sahakara Niyamitha", label: "Neelakanteshwara Sou Pattina Sahakara Niyamitha" },
  { value: "Neelakanteshwara Souharda Pattina Sahakari Niyamitha Ltd", label: "Neelakanteshwara Souharda Pattina Sahakari Niyamitha Ltd" },
  { value: "Neelakanteshwara Swamy Pattina Sahakara Sangha", label: "Neelakanteshwara Swamy Pattina Sahakara Sangha" },
  { value: "Neelakhanta Co-Op. Urban Bank Ltd", label: "Neelakhanta Co-Op. Urban Bank Ltd" },
  { value: "Neelakrishna Leafins Ltd.", label: "Neelakrishna Leafins Ltd." },
  { value: "Neelam Chand", label: "Neelam Chand" },
  { value: "Neelam Chand Surana Financier", label: "Neelam Chand Surana Financier" },
  { value: "Neelam Motor & General Finance", label: "Neelam Motor & General Finance" },
  { value: "Neelam S Mehta", label: "Neelam S Mehta" },
  { value: "Neelam Sahay", label: "Neelam Sahay" },
  { value: "Neelamber Financers", label: "Neelamber Financers" },
  { value: "Neelkanth Co-Op Soc", label: "Neelkanth Co-Op Soc" },
  { value: "Neema Leasing", label: "Neema Leasing" },
  { value: "Neemarga Service Co Operativa Bank Ltd", label: "Neemarga Service Co Operativa Bank Ltd" },
  { value: "Neena Leasing Allahabad", label: "Neena Leasing Allahabad" },
  { value: "Neendoor Service Co-Op.Bank Ltd.", label: "Neendoor Service Co-Op.Bank Ltd." },
  { value: "Neeraj Auto", label: "Neeraj Auto" },
  { value: "Neerav Singhal", label: "Neerav Singhal" },
  { value: "Neerikode Service Co Operative Bank", label: "Neerikode Service Co Operative Bank" },
  { value: "Neeru Mohini Sareen", label: "Neeru Mohini Sareen" },
  { value: "Neeta S Mehta", label: "Neeta S Mehta" },
  { value: "Neetha Bohara Financier", label: "Neetha Bohara Financier" },
  { value: "Neha Auto Finance", label: "Neha Auto Finance" },
  { value: "Neha Enterprise", label: "Neha Enterprise" },
  { value: "Neha Enterprises Finance & Leasing", label: "Neha Enterprises Finance & Leasing" },
  { value: "Neha Finance & Leasings", label: "Neha Finance & Leasings" },
  { value: "Neha Finance Co", label: "Neha Finance Co" },
  { value: "Neha Financer P Ltd", label: "Neha Financer P Ltd" },
  { value: "Neha Financial Com Ltd", label: "Neha Financial Com Ltd" },
  { value: "Neha Leasing & Finance Company", label: "Neha Leasing & Finance Company" },
  { value: "Neha Magaji", label: "Neha Magaji" },
  { value: "Nehal Rajeshbhai Shah", label: "Nehal Rajeshbhai Shah" },
  { value: "Nehru Gramin Big S S Pat San Mydt", label: "Nehru Gramin Big S S Pat San Mydt" },
  { value: "Nehrunagar Co-Op Bank Ltd", label: "Nehrunagar Co-Op Bank Ltd" },
  { value: "Neimnath Finance", label: "Neimnath Finance" },
  { value: "Nelcast Finance Ltd.", label: "Nelcast Finance Ltd." },
  { value: "Nelipuppam Primary Agl Co Op Bank", label: "Nelipuppam Primary Agl Co Op Bank" },
  { value: "Nellai Nagar Cooperative Urban Bk Ltd", label: "Nellai Nagar Cooperative Urban Bk Ltd" },
  { value: "Nellainagar Urban Co-Op Bank Ltd", label: "Nellainagar Urban Co-Op Bank Ltd" },
  { value: "Nellikar Vyavasaya Seva Sahakari Bk Niy.", label: "Nellikar Vyavasaya Seva Sahakari Bk Niy." },
  { value: "Nellore District Central Co-Operative Bank Ltd.", label: "Nellore District Central Co-Operative Bank Ltd." },
  { value: "Nema Finance And Leasing", label: "Nema Finance And Leasing" },
  { value: "Neminath Gramin Bin Sat Pat Mary", label: "Neminath Gramin Bin Sat Pat Mary" },
  { value: "Nepa Ltd.", label: "Nepa Ltd." },
  { value: "Nepal Investment Bank Ltd", label: "Nepal Investment Bank Ltd" },
  { value: "Neralkatte Co-Op.Agri. Bank Ltd.", label: "Neralkatte Co-Op.Agri. Bank Ltd." },
  { value: "Nere Vibhag Nagari Sahakari Pat San Mar", label: "Nere Vibhag Nagari Sahakari Pat San Mar" },
  { value: "Nesargi Urban Co-Op Bank Ltd", label: "Nesargi Urban Co-Op Bank Ltd" },
  { value: "Nestle India Ltd", label: "Nestle India Ltd" },
  { value: "Netaji Subhas Institute Of Technology", label: "Netaji Subhas Institute Of Technology" },
  { value: "Netaji Subhash Chandra Bose Co-Op Cr Soc Ltd", label: "Netaji Subhash Chandra Bose Co-Op Cr Soc Ltd" },
  { value: "Netaji Subhashchandra Bose Patsanstha", label: "Netaji Subhashchandra Bose Patsanstha" },
  { value: "Netaji Urban Co-Operative Credit Society", label: "Netaji Urban Co-Operative Credit Society" },
  { value: "Netambit Infosource & Eservices P L", label: "Netambit Infosource & Eservices P L" },
  { value: "Netha Co-Op Urban Bank Ltd.", label: "Netha Co-Op Urban Bank Ltd." },
  { value: "Nethaji Credit Co Op Society Ltd", label: "Nethaji Credit Co Op Society Ltd" },
  { value: "Nethaji Purushula Podhupu Sangam", label: "Nethaji Purushula Podhupu Sangam" },
  { value: "Networking Of Social Scientists", label: "Networking Of Social Scientists" },
  { value: "New Age Fin Lease Pvt Ltd", label: "New Age Fin Lease Pvt Ltd" },
  { value: "New Age Financie (P) Ltd", label: "New Age Financie (P) Ltd" },
  { value: "New Agency Financerr (Shamli)", label: "New Agency Financerr (Shamli)" },
  { value: "New Agra Urban Co-Op Bank Ltd", label: "New Agra Urban Co-Op Bank Ltd" },
  { value: "New Bank Of India", label: "New Bank Of India" },
  { value: "New Bharat Finance Co.", label: "New Bharat Finance Co." },
  { value: "New Bombay Co-Op Society", label: "New Bombay Co-Op Society" },
  { value: "New Capital", label: "New Capital" },
  { value: "New Chaitanya Auto Leasing & Finance", label: "New Chaitanya Auto Leasing & Finance" },
  { value: "New Chandrika Auto Finance", label: "New Chandrika Auto Finance" },
  { value: "New Dawn Insurance Risks Consulting Service", label: "New Dawn Insurance Risks Consulting Service" },
  { value: "New Enterprise,Surat", label: "New Enterprise,Surat" },
  { value: "New Era Ins. Services Ltd.", label: "New Era Ins. Services Ltd." },
  { value: "New Fin Lease Pvt Ltd", label: "New Fin Lease Pvt Ltd" },
  { value: "New Gaylord Fin Com", label: "New Gaylord Fin Com" },
  { value: "New Goyal Enterprises", label: "New Goyal Enterprises" },
  { value: "New India Co-Op Bank Ltd., Mumbai", label: "New India Co-Op Bank Ltd., Mumbai" },
  { value: "New India Co-Operative Bank Ltd.", label: "New India Co-Operative Bank Ltd." },
  { value: "New India Financial Services Ltd", label: "New India Financial Services Ltd" },
  { value: "New India Gic Ltd", label: "New India Gic Ltd" },
  { value: "New Jammu City Financer", label: "New Jammu City Financer" },
  { value: "New Kishan Fin Co.", label: "New Kishan Fin Co." },
  { value: "New Lands Finance", label: "New Lands Finance" },
  { value: "New Link Overseas Ltd.", label: "New Link Overseas Ltd." },
  { value: "New Mangalore Port Trust", label: "New Mangalore Port Trust" },
  { value: "New Mohan Sai Finance Company", label: "New Mohan Sai Finance Company" },
  { value: "New Okhla Industrial Development Authority", label: "New Okhla Industrial Development Authority" },
  { value: "New Panjab Hire Purchase", label: "New Panjab Hire Purchase" },
  { value: "New Pune Zila Co-Op Credit Soc", label: "New Pune Zila Co-Op Credit Soc" },
  { value: "New Puneztla Co-Op Credit Soc", label: "New Puneztla Co-Op Credit Soc" },
  { value: "New Raj Fiananciers Regd", label: "New Raj Fiananciers Regd" },
  { value: "New Rissam Trading Corp", label: "New Rissam Trading Corp" },
  { value: "New Satguru Motor Finance Co", label: "New Satguru Motor Finance Co" },
  { value: "New Sethi Finance Co (P) Ltd", label: "New Sethi Finance Co (P) Ltd" },
  { value: "New Sewak Trading Corp", label: "New Sewak Trading Corp" },
  { value: "New Sharda Co Op Cr Soc Ltd", label: "New Sharda Co Op Cr Soc Ltd" },
  { value: "New Shiva Financer Co Ltd", label: "New Shiva Financer Co Ltd" },
  { value: "New Shivprabha Nagri Sahakari Pathsanstha", label: "New Shivprabha Nagri Sahakari Pathsanstha" },
  { value: "New Shree Mahalaxmi Leasing ( R )", label: "New Shree Mahalaxmi Leasing ( R )" },
  { value: "New Shri Mahalaxmi Leasing ( R )", label: "New Shri Mahalaxmi Leasing ( R )" },
  { value: "New Sri Adhitya Auto Finance", label: "New Sri Adhitya Auto Finance" },
  { value: "New Sri Raghava Auto Finance Corporation", label: "New Sri Raghava Auto Finance Corporation" },
  { value: "New Style Feb Satwari", label: "New Style Feb Satwari" },
  { value: "New Subedar Co. Op. Bank", label: "New Subedar Co. Op. Bank" },
  { value: "New Sumanjali Auto Finance", label: "New Sumanjali Auto Finance" },
  { value: "Suvidha Co Opp Credit Society Ltd", label: "Suvidha Co Opp Credit Society Ltd" },
  { value: "New Tek Enterprises.", label: "New Tek Enterprises." },
  { value: "New Tresure Auto", label: "New Tresure Auto" },
  { value: "New Tri Matha Auto Finance", label: "New Tri Matha Auto Finance" },
  { value: "New Venkateswara Finance", label: "New Venkateswara Finance" },
  { value: "New Vijaydasmi Finance & Leasing ( R )", label: "New Vijaydasmi Finance & Leasing ( R )" },
  { value: "New Vishal Co Op Credit Society", label: "New Vishal Co Op Credit Society" },
  { value: "New World Chit And Leasing Co. Ltd", label: "New World Chit And Leasing Co. Ltd" },
  { value: "Newline Finance Ltd.", label: "Newline Finance Ltd." },
  { value: "Newlink Overseas Finance Ltd.", label: "Newlink Overseas Finance Ltd." },
  { value: "Newtek Enterprises Finance Leasing", label: "Newtek Enterprises Finance Leasing" },
  { value: "Neyvali Lignite Corpn. Ltd.", label: "Neyvali Lignite Corpn. Ltd." },
  { value: "Neyyattinkara Co-Op Bank Ltd", label: "Neyyattinkara Co-Op Bank Ltd" },
  { value: "Ngif", label: "Ngif" },
  { value: "Nic Magma", label: "Nic Magma" },
  { value: "Nic Of India", label: "Nic Of India" },
  { value: "Nicco Uco Alliance Credit Ltd(Nicco Uco Financial Services Ltd.)", label: "Nicco Uco Alliance Credit Ltd(Nicco Uco Financial Services Ltd.)" },
  { value: "Nice Finance Co", label: "Nice Finance Co" },
  { value: "Nicson Jacob C", label: "Nicson Jacob C" },
  { value: "Nidhi Co-Op. Bank Ltd", label: "Nidhi Co-Op. Bank Ltd" },
  { value: "Nidhi Corp & Co", label: "Nidhi Corp & Co" },
  { value: "Nidhi Corporation", label: "Nidhi Corporation" },
  { value: "Nidhi Enterprise", label: "Nidhi Enterprise" },
  { value: "Nidtha Vyavasaya Seva Sahakari Bank,", label: "Nidtha Vyavasaya Seva Sahakari Bank," },
  { value: "Nighoj Gramin Bigar Sheti Sah Pat", label: "Nighoj Gramin Bigar Sheti Sah Pat" },
  { value: "Nighoj Gramin Bigar Sheti Sahakari Pat Sanstha", label: "Nighoj Gramin Bigar Sheti Sahakari Pat Sanstha" },
  { value: "Nighoj Gramin Bigar Sheti Sahkari Pathsanstha", label: "Nighoj Gramin Bigar Sheti Sahkari Pathsanstha" },
  { value: "Nighose Gramin Bigar Sheti Sahkari Pathsanstha", label: "Nighose Gramin Bigar Sheti Sahkari Pathsanstha" },
  { value: "Nighotwadi Gramin Bigaersheti Sahakari Patsanstha Mydt", label: "Nighotwadi Gramin Bigaersheti Sahakari Patsanstha Mydt" },
  { value: "Nigoj Gramin Bigar Sheti Sah", label: "Nigoj Gramin Bigar Sheti Sah" },
  { value: "Nihal Chand & Sons", label: "Nihal Chand & Sons" },
  { value: "Nihal Motor Financier", label: "Nihal Motor Financier" },
  { value: "Niidhii Krupa", label: "Niidhii Krupa" },
  { value: "Nikesh Finance Coporations", label: "Nikesh Finance Coporations" },
  { value: "Nikitha Finance", label: "Nikitha Finance" },
  { value: "Nikitha Investments", label: "Nikitha Investments" },
  { value: "Nikunj Enterprise", label: "Nikunj Enterprise" },
  { value: "Nilachal Gramya Bank", label: "Nilachal Gramya Bank" },
  { value: "Nilakantha Urban Co-Op Bank Ltd", label: "Nilakantha Urban Co-Op Bank Ltd" },
  { value: "Nilambur Urban Co-Op Bank Ltd", label: "Nilambur Urban Co-Op Bank Ltd" },
  { value: "Nilemore Nagari Sahakari Pathsansta Ltd", label: "Nilemore Nagari Sahakari Pathsansta Ltd" },
  { value: "Nilesh & Co", label: "Nilesh & Co" },
  { value: "Nilesh Financiers", label: "Nilesh Financiers" },
  { value: "Nilesh Kumar Kochar", label: "Nilesh Kumar Kochar" },
  { value: "Nilesh Pravinchadra Shah", label: "Nilesh Pravinchadra Shah" },
  { value: "Nilgiris District Central Co-Operative Bank Ltd.", label: "Nilgiris District Central Co-Operative Bank Ltd." },
  { value: "Nilkanth Urban Co-Op Bank Ltd", label: "Nilkanth Urban Co-Op Bank Ltd" },
  { value: "Nilkanth Urban Co-Op Bank Ltd", label: "Nilkanth Urban Co-Op Bank Ltd" },
  { value: "Nima Leasing Finance Co (P) Ltd", label: "Nima Leasing Finance Co (P) Ltd" },
  { value: "Nimba Finance And Leasing Pvt. Ltd.", label: "Nimba Finance And Leasing Pvt. Ltd." },
  { value: "Nimjaymata Dudh Utpadak Grdhkrishi Nagari Sahkari Patsanstha Ltd", label: "Nimjaymata Dudh Utpadak Grdhkrishi Nagari Sahkari Patsanstha Ltd" },
  { value: "Nimod Gram Sewa Sahakari Samiti Ltd.", label: "Nimod Gram Sewa Sahakari Samiti Ltd." },
  { value: "Ninaidevi Nagari Sahakari Pat Sanstha Maryadit", label: "Ninaidevi Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Nipad Urban Co-Op Bank Ltd", label: "Nipad Urban Co-Op Bank Ltd" },
  { value: "Nipani Urban Co-Op Bank Ltd", label: "Nipani Urban Co-Op Bank Ltd" },
  { value: "Niphad Nagari Sahkari Patsanstha Ltd", label: "Niphad Nagari Sahkari Patsanstha Ltd" },
  { value: "Niranjan Credit Co-Op Soc Ltd.", label: "Niranjan Credit Co-Op Soc Ltd." },
  { value: "Nirantara Community Services", label: "Nirantara Community Services" },
  { value: "Niravan Nivesh", label: "Niravan Nivesh" },
  { value: "Nirmal And Co", label: "Nirmal And Co" },
  { value: "Nirmal Auto Finance", label: "Nirmal Auto Finance" },
  { value: "Nirmal Finance", label: "Nirmal Finance" },
  { value: "Nirmal Kumar Hup", label: "Nirmal Kumar Hup" },
  { value: "Nirmal Ujjwal Co. Op. Society", label: "Nirmal Ujjwal Co. Op. Society" },
  { value: "Nirmal Ujjwal Sahakari Pat Sanstha", label: "Nirmal Ujjwal Sahakari Pat Sanstha" },
  { value: "Nirmal Ujwal Sah Pats Maryadit", label: "Nirmal Ujwal Sah Pats Maryadit" },
  { value: "Nirmal Urban Co-Op. Bank Ltd.", label: "Nirmal Urban Co-Op. Bank Ltd." },
  { value: "Nirmala Auto Finance", label: "Nirmala Auto Finance" },
  { value: "Nirmala Finance Corporation", label: "Nirmala Finance Corporation" },
  { value: "Nirmala Motors.", label: "Nirmala Motors." },
  { value: "Nirmangar Gramin Bigar Sheti Sah Pat", label: "Nirmangar Gramin Bigar Sheti Sah Pat" },
  { value: "Nirumarga Seva Sahakari Bank", label: "Nirumarga Seva Sahakari Bank" },
  { value: "Nischal Samnotra", label: "Nischal Samnotra" },
  { value: "Nisha Auto Finance", label: "Nisha Auto Finance" },
  { value: "Nisha Kaushik", label: "Nisha Kaushik" },
  { value: "Nisha Negi", label: "Nisha Negi" },
  { value: "Nisham.P.M", label: "Nisham.P.M" },
  { value: "Nishant Finance", label: "Nishant Finance" },
  { value: "Nishant Saha Gram Patpurwatha Sanstha Lt", label: "Nishant Saha Gram Patpurwatha Sanstha Lt" },
  { value: "Nishant Sahakari Gramin Pat Purvatha Sanstha", label: "Nishant Sahakari Gramin Pat Purvatha Sanstha" },
  { value: "Nishant Sahkari Gramin Patsanstha", label: "Nishant Sahkari Gramin Patsanstha" },
  { value: "Nishant Sakari Path Sanstha", label: "Nishant Sakari Path Sanstha" },
  { value: "Nishanth Kumar Bafna", label: "Nishanth Kumar Bafna" },
  { value: "Nishhok Finance", label: "Nishhok Finance" },
  { value: "Nishigandha Sahakari Bank L", label: "Nishigandha Sahakari Bank L" },
  { value: "Nishigandha Sahakari Bank Ltd", label: "Nishigandha Sahakari Bank Ltd" },
  { value: "Nishit Shreyasbhai Pandya", label: "Nishit Shreyasbhai Pandya" },
  { value: "Nishra Choraria Finance", label: "Nishra Choraria Finance" },
  { value: "Nitesh Auto Finance", label: "Nitesh Auto Finance" },
  { value: "Nitesh Finance Co.", label: "Nitesh Finance Co." },
  { value: "Nitesh Huf", label: "Nitesh Huf" },
  { value: "Nitesh Investment", label: "Nitesh Investment" },
  { value: "Nithin Enterprises", label: "Nithin Enterprises" },
  { value: "Nithiyalakshmi Auto Finance", label: "Nithiyalakshmi Auto Finance" },
  { value: "Nithyalakshmi Auto Finance", label: "Nithyalakshmi Auto Finance" },
  { value: "Nitin Auto Deals", label: "Nitin Auto Deals" },
  { value: "Nitin Daguji Wakchoure", label: "Nitin Daguji Wakchoure" },
  { value: "Nitin Kumar Nashine", label: "Nitin Kumar Nashine" },
  { value: "Nitin Shivaji Mali", label: "Nitin Shivaji Mali" },
  { value: "Nitin Sogani", label: "Nitin Sogani" },
  { value: "Nitu Navdeep", label: "Nitu Navdeep" },
  { value: "Nitya Auto Finance", label: "Nitya Auto Finance" },
  { value: "Nitya Finance & Chits", label: "Nitya Finance & Chits" },
  { value: "Nizamabad District Central Co-Operative Bank Ltd.", label: "Nizamabad District Central Co-Operative Bank Ltd." },
  { value: "Nm Auto Finance", label: "Nm Auto Finance" },
  { value: "Nmdc Ltd.", label: "Nmdc Ltd." },
  { value: "Nobal Urban Co Op Cr.So.Ltd", label: "Nobal Urban Co Op Cr.So.Ltd" },
  { value: "Noble Auto Finance", label: "Noble Auto Finance" },
  { value: "Noble Co Operative Bank Ltd", label: "Noble Co Operative Bank Ltd" },
  { value: "Noble Finance Company", label: "Noble Finance Company" },
  { value: "Noble Financers Regd", label: "Noble Financers Regd" },
  { value: "Noida Commercial Co-Operative Bank Ltd", label: "Noida Commercial Co-Operative Bank Ltd" },
  { value: "Nokha Finance", label: "Nokha Finance" },
  { value: "Nokoda Finance", label: "Nokoda Finance" },
  { value: "None", label: "None" },
  { value: "Noorani Old Motor Parts Dealers Co-Op Credit Society", label: "Noorani Old Motor Parts Dealers Co-Op Credit Society" },
  { value: "Norathmal Jain Huf", label: "Norathmal Jain Huf" },
  { value: "North Canara Co-Op. Bank", label: "North Canara Co-Op. Bank" },
  { value: "North Delhi Credit And Investment Ltd", label: "North Delhi Credit And Investment Ltd" },
  { value: "North East Securities Ltd.", label: "North East Securities Ltd." },
  { value: "North Eastern Development Finance Corporation", label: "North Eastern Development Finance Corporation" },
  { value: "North Eastern Electric Power Corporation", label: "North Eastern Electric Power Corporation" },
  { value: "North Eastern Handicrafts And Handlooms Development Corpn. Ltd.", label: "North Eastern Handicrafts And Handlooms Development Corpn. Ltd." },
  { value: "North Eastern Regional Agricultural Marketing Corpn. Ltd.", label: "North Eastern Regional Agricultural Marketing Corpn. Ltd." },
  { value: "North India Finance Corporation", label: "North India Finance Corporation" },
  { value: "North Kanara G S B Co-Op Bank Ltd", label: "North Kanara G S B Co-Op Bank Ltd" },
  { value: "North Link Fiscal And Capital Services Ltd", label: "North Link Fiscal And Capital Services Ltd" },
  { value: "North Malabar Grameen Bank", label: "North Malabar Grameen Bank" },
  { value: "North Ridge Finance Pvt. Ltd.", label: "North Ridge Finance Pvt. Ltd." },
  { value: "North Zone Cultural Centre", label: "North Zone Cultural Centre" },
  { value: "Northern Coal Fields Ltd.", label: "Northern Coal Fields Ltd." },
  { value: "Northern Coalfields Limited", label: "Northern Coalfields Limited" },
  { value: "Northern India Textile Research Association", label: "Northern India Textile Research Association" },
  { value: "Northern Railway Co-Operative Bank Ltd.", label: "Northern Railway Co-Operative Bank Ltd." },
  { value: "Northpole Finance Ltd.", label: "Northpole Finance Ltd." },
  { value: "Not To Be Used - Grindlays Bank", label: "Not To Be Used - Grindlays Bank" },
  { value: "Nova Auto Finance", label: "Nova Auto Finance" },
  { value: "Nova Leasing Pvt Ltd", label: "Nova Leasing Pvt Ltd" },
  { value: "Noval Finance Co.", label: "Noval Finance Co." },
  { value: "Novell Software Development India Pvt Ltd", label: "Novell Software Development India Pvt Ltd" },
  { value: "Novelty Finance Ltd.", label: "Novelty Finance Ltd." },
  { value: "Np Finance", label: "Np Finance" },
  { value: "Npr Finance Ltd", label: "Npr Finance Ltd" },
  { value: "Nr Investments", label: "Nr Investments" },
  { value: "Nrsa Govt Of India Dept Of Space", label: "Nrsa Govt Of India Dept Of Space" },
  { value: "Nsfdc", label: "Nsfdc" },
  { value: "Nsfdc And Dsfds", label: "Nsfdc And Dsfds" },
  { value: "Ntpc Kahalgaon", label: "Ntpc Kahalgaon" },
  { value: "Nuclear Fuel Complex", label: "Nuclear Fuel Complex" },
  { value: "Nuclear Power Corporation Of India Limited", label: "Nuclear Power Corporation Of India Limited" },
  { value: "Nuclear Pwer Corporation Of India", label: "Nuclear Pwer Corporation Of India" },
  { value: "Numaligarh Refinery Limited Oil India Ltd.", label: "Numaligarh Refinery Limited Oil India Ltd." },
  { value: "Numoligarh Refinery Ltd", label: "Numoligarh Refinery Ltd" },
  { value: "Nunhem India Pvt Ltd", label: "Nunhem India Pvt Ltd" },
  { value: "Nutam Auto Finance", label: "Nutam Auto Finance" },
  { value: "Nutan Auto Finance", label: "Nutan Auto Finance" },
  { value: "Nutan Finance", label: "Nutan Finance" },
  { value: "Nutan Mahila Urban Co-Operative  Credit Society Limited", label: "Nutan Mahila Urban Co-Operative  Credit Society Limited" },
  { value: "Nutan Nagarik Co Op Bank Ltd", label: "Nutan Nagarik Co Op Bank Ltd" },
  { value: "Nutan Sahakari Bank Ltd.", label: "Nutan Sahakari Bank Ltd." },
  { value: "Nutan Sahakari Bank Ltd.", label: "Nutan Sahakari Bank Ltd." },
  { value: "Nutech Finance Co.", label: "Nutech Finance Co." },
  { value: "Nyamitra Sahakar Bank Niyamita", label: "Nyamitra Sahakar Bank Niyamita" },
  { value: "Nyamurti Bj Kolase Patil Gramin Bigar Sheti Patsanstha", label: "Nyamurti Bj Kolase Patil Gramin Bigar Sheti Patsanstha" },
  { value: "Nyara Seva Sahkari Mandli", label: "Nyara Seva Sahkari Mandli" },
  { value: "O A I S Ltd", label: "O A I S Ltd" },
  { value: "O Santhosh Kawar Financier", label: "O Santhosh Kawar Financier" },
  { value: "O.G. Finance", label: "O.G. Finance" },
  { value: "O.K. Finance Co", label: "O.K. Finance Co" },
  { value: "Oachira Services Cooperative Bank Ltd", label: "Oachira Services Cooperative Bank Ltd" },
  { value: "Oais Auto Financial Services Ltd.", label: "Oais Auto Financial Services Ltd." },
  { value: "Oasis Auto Financial Services Ltd", label: "Oasis Auto Financial Services Ltd" },
  { value: "Oasis Enterprises", label: "Oasis Enterprises" },
  { value: "Oax Auto Infrastructure Services Ltd", label: "Oax Auto Infrastructure Services Ltd" },
  { value: "Obroi Auto Sales", label: "Obroi Auto Sales" },
  { value: "Obt", label: "Obt" },
  { value: "Ochanthuruth Service Co-Operative Bank L", label: "Ochanthuruth Service Co-Operative Bank L" },
  { value: "Ohm Ganapathy Hire Purchase", label: "Ohm Ganapathy Hire Purchase" },
  { value: "Ohm Maruthi Broking & Ins. Services Pvt. Ltd.", label: "Ohm Maruthi Broking & Ins. Services Pvt. Ltd." },
  { value: "Ohm Muruga Financiers", label: "Ohm Muruga Financiers" },
  { value: "Ohm Sakthi Auto Fin", label: "Ohm Sakthi Auto Fin" },
  { value: "Ohm Sakthi Auto Finance", label: "Ohm Sakthi Auto Finance" },
  { value: "Oic Ltd", label: "Oic Ltd" },
  { value: "Oic Tvs", label: "Oic Tvs" },
  { value: "Oil & Natural Gas Co.Ltd,Hajira", label: "Oil & Natural Gas Co.Ltd,Hajira" },
  { value: "Oil And Natural Gas Corporation Ltd", label: "Oil And Natural Gas Corporation Ltd" },
  { value: "Oil India Limited", label: "Oil India Limited" },
  { value: "Old Goa Urban Co-Op Credit Society Ltd.", label: "Old Goa Urban Co-Op Credit Society Ltd." },
  { value: "Olety Finance Pvt Ltd", label: "Olety Finance Pvt Ltd" },
  { value: "Olimpia Associates", label: "Olimpia Associates" },
  { value: "Ollur Service Co-Operative Bank Ltd", label: "Ollur Service Co-Operative Bank Ltd" },
  { value: "Olympika Finance", label: "Olympika Finance" },
  { value: "Om Auto & General Finance Co.", label: "Om Auto & General Finance Co." },
  { value: "Om Auto Leasing ( R )", label: "Om Auto Leasing ( R )" },
  { value: "Om Chintamani Co Op Credit Society", label: "Om Chintamani Co Op Credit Society" },
  { value: "Om Finance Co", label: "Om Finance Co" },
  { value: "Om Finance Pvt. India Ltd", label: "Om Finance Pvt. India Ltd" },
  { value: "Om Financial Services", label: "Om Financial Services" },
  { value: "Om Financial Services India Limited", label: "Om Financial Services India Limited" },
  { value: "Om Financials", label: "Om Financials" },
  { value: "Om Fincap Pvt Ltd", label: "Om Fincap Pvt Ltd" },
  { value: "Om Ganapathy Auto Finance", label: "Om Ganapathy Auto Finance" },
  { value: "Om Ganapathy Bankers", label: "Om Ganapathy Bankers" },
  { value: "Om Gaurai Nagarik Sahakari Patsantha Maryadit", label: "Om Gaurai Nagarik Sahakari Patsantha Maryadit" },
  { value: "Om Gorai Nagari Saha Patsanstha", label: "Om Gorai Nagari Saha Patsanstha" },
  { value: "Om Hire Purchase", label: "Om Hire Purchase" },
  { value: "Om K V R Auto Finance", label: "Om K V R Auto Finance" },
  { value: "Om K V R Finance", label: "Om K V R Finance" },
  { value: "Om Knr Finance Ltd", label: "Om Knr Finance Ltd" },
  { value: "Om Kur Auto Finance", label: "Om Kur Auto Finance" },
  { value: "Om Kur Finance", label: "Om Kur Finance" },
  { value: "Om Kvr", label: "Om Kvr" },
  { value: "Om Leasing And Finance Co.", label: "Om Leasing And Finance Co." },
  { value: "Om Manikanth Leasing", label: "Om Manikanth Leasing" },
  { value: "Om Manikantha Leasing", label: "Om Manikantha Leasing" },
  { value: "Om Motor Consultant", label: "Om Motor Consultant" },
  { value: "Om Motor Finance (P) Ltd", label: "Om Motor Finance (P) Ltd" },
  { value: "Om Motor Finance Company Ltd", label: "Om Motor Finance Company Ltd" },
  { value: "Om Muruga Finance", label: "Om Muruga Finance" },
  { value: "Om Nagari Sah Dat San", label: "Om Nagari Sah Dat San" },
  { value: "Om Parkash Narula", label: "Om Parkash Narula" },
  { value: "Om Prakash And Sons", label: "Om Prakash And Sons" },
  { value: "Om Rajlaxmi Finance ( R )", label: "Om Rajlaxmi Finance ( R )" },
  { value: "Om Sai  Co Op Credit Soc Ltd", label: "Om Sai  Co Op Credit Soc Ltd" },
  { value: "Om Sai Auto Constultant", label: "Om Sai Auto Constultant" },
  { value: "Om Sai Auto Finance", label: "Om Sai Auto Finance" },
  { value: "Om Sai Co -Op. Credit Soc. Ltd", label: "Om Sai Co -Op. Credit Soc. Ltd" },
  { value: "Om Sai Dewa Co Op Credit Soc Ltd", label: "Om Sai Dewa Co Op Credit Soc Ltd" },
  { value: "Om Sai Eshwar Finance & Leasing ( R )", label: "Om Sai Eshwar Finance & Leasing ( R )" },
  { value: "Om Sai Finlease Pvt Ltd", label: "Om Sai Finlease Pvt Ltd" },
  { value: "Om Sai Finlease(P) Ltd", label: "Om Sai Finlease(P) Ltd" },
  { value: "Om Sai Leasing", label: "Om Sai Leasing" },
  { value: "Om Sai Motors", label: "Om Sai Motors" },
  { value: "Om Sai Ram Co-Op Credit Soc Ltd", label: "Om Sai Ram Co-Op Credit Soc Ltd" },
  { value: "Om Shakthi Finance", label: "Om Shakthi Finance" },
  { value: "Om Shanti Finance", label: "Om Shanti Finance" },
  { value: "Om Shanti Motor & General Finance", label: "Om Shanti Motor & General Finance" },
  { value: "Om Shivshakti Nagari Pat Sanstha Mydt", label: "Om Shivshakti Nagari Pat Sanstha Mydt" },
  { value: "Om Shree Eshwar Finance Leasing", label: "Om Shree Eshwar Finance Leasing" },
  { value: "Om Shree Manikanta Leasing ( R )", label: "Om Shree Manikanta Leasing ( R )" },
  { value: "Om Shri Guru Auto Leasing", label: "Om Shri Guru Auto Leasing" },
  { value: "Om Shridevi Finance And Leasing Ltd", label: "Om Shridevi Finance And Leasing Ltd" },
  { value: "Om Shubham Invest And Finance(P) Ltd", label: "Om Shubham Invest And Finance(P) Ltd" },
  { value: "Om Sree Sai Hp And General Finance", label: "Om Sree Sai Hp And General Finance" },
  { value: "Om Sri Annaverbadreshwara Leasing", label: "Om Sri Annaverbadreshwara Leasing" },
  { value: "Om Sri Ayyappa Hire Purchase & Finance", label: "Om Sri Ayyappa Hire Purchase & Finance" },
  { value: "Om Sri Manikanta Auto Finance", label: "Om Sri Manikanta Auto Finance" },
  { value: "Om Sri Sai General Finance", label: "Om Sri Sai General Finance" },
  { value: "Om Sri Shiva Motors", label: "Om Sri Shiva Motors" },
  { value: "Om Tours & Travels", label: "Om Tours & Travels" },
  { value: "Oman International Bank", label: "Oman International Bank" },
  { value: "Oman International Bank Saog", label: "Oman International Bank Saog" },
  { value: "Omega Finance", label: "Omega Finance" },
  { value: "Omerga Janata Sahakari Bank Ltd", label: "Omerga Janata Sahakari Bank Ltd" },
  { value: "Omkar Auto Finance", label: "Omkar Auto Finance" },
  { value: "Omkar Co-Op  Credit Socity", label: "Omkar Co-Op  Credit Socity" },
  { value: "Omkar Finance Nagpur", label: "Omkar Finance Nagpur" },
  { value: "Omkar Financiers", label: "Omkar Financiers" },
  { value: "Omkar Nagreeya Sahakari Bank Ltd.", label: "Omkar Nagreeya Sahakari Bank Ltd." },
  { value: "Omkareshwar Nag.Sah.Pat.Sanstha.Maryadit", label: "Omkareshwar Nag.Sah.Pat.Sanstha.Maryadit" },
  { value: "Omnibus Industrial Development Corporation", label: "Omnibus Industrial Development Corporation" },
  { value: "Omnibus Industrial Development Corporation Of Dadra And Nagar Havelli Limited", label: "Omnibus Industrial Development Corporation Of Dadra And Nagar Havelli Limited" },
  { value: "Omnibus Industrial Development Corporation Of Daman And Diu Limited", label: "Omnibus Industrial Development Corporation Of Daman And Diu Limited" },
  { value: "Omprakash Verma", label: "Omprakash Verma" },
  { value: "Omprakash Verma", label: "Omprakash Verma" },
  { value: "Omshanti Nagari Sahakari Patsanstha Ltd", label: "Omshanti Nagari Sahakari Patsanstha Ltd" },
  { value: "Onake Obawa Mahila Co-Op Bank Ltd", label: "Onake Obawa Mahila Co-Op Bank Ltd" },
  { value: "Onchiyam Service Co. Bank Ltd.", label: "Onchiyam Service Co. Bank Ltd." },
  { value: "Ongc", label: "Ongc" },
  { value: "Ongc Videsh Ltd.", label: "Ongc Videsh Ltd." },
  { value: "Ongole Co-Op Bank Ltd", label: "Ongole Co-Op Bank Ltd" },
  { value: "Onkar Mull Shankar Lal", label: "Onkar Mull Shankar Lal" },
  { value: "Ooruttambalam Service Co Bank Ltd.", label: "Ooruttambalam Service Co Bank Ltd." },
  { value: "Optima Financial Service", label: "Optima Financial Service" },
  { value: "Optimum Insurance Broking Services Pvt.Ltd", label: "Optimum Insurance Broking Services Pvt.Ltd" },
  { value: "Orange Auto Private Ltd", label: "Orange Auto Private Ltd" },
  { value: "Orchid Finance Corpn", label: "Orchid Finance Corpn" },
  { value: "Orex Auto Infrastructrure Ser. Ltd.", label: "Orex Auto Infrastructrure Ser. Ltd." },
  { value: "Orgella Financial Services Pvt Ltd", label: "Orgella Financial Services Pvt Ltd" },
  { value: "Oriental Agencies", label: "Oriental Agencies" },
  { value: "Oriental Bank", label: "Oriental Bank" },
  { value: "Oriental Bank Of Commerce(Obc)", label: "Oriental Bank Of Commerce(Obc)" },
  { value: "Oriental Financial Ltd", label: "Oriental Financial Ltd" },
  { value: "Oriental Insurance Company Limited", label: "Oriental Insurance Company Limited" },
  { value: "Oriflame India Pvt Ltd", label: "Oriflame India Pvt Ltd" },
  { value: "Orin Auto Infrastructure Finance Service Ltd", label: "Orin Auto Infrastructure Finance Service Ltd" },
  { value: "Orion Autmb P Ltd", label: "Orion Autmb P Ltd" },
  { value: "Orion Inbound P Ltd", label: "Orion Inbound P Ltd" },
  { value: "Orissa Backward Classes Finance And Development Corporation Ltd", label: "Orissa Backward Classes Finance And Development Corporation Ltd" },
  { value: "Orissa Drugs And Chemicals Ltd.", label: "Orissa Drugs And Chemicals Ltd." },
  { value: "Orissa State Co-Op. Bank Ltd", label: "Orissa State Co-Op. Bank Ltd" },
  { value: "Orissa State Co-Operative Bank Ltd.", label: "Orissa State Co-Operative Bank Ltd." },
  { value: "Orissa State Financial Corporation", label: "Orissa State Financial Corporation" },
  { value: "Orissa State Financial Development Corporation", label: "Orissa State Financial Development Corporation" },
  { value: "Orissa State Tourism Development Corporation Ltd.", label: "Orissa State Tourism Development Corporation Ltd." },
  { value: "Orix Auto & Bank", label: "Orix Auto & Bank" },
  { value: "Orix Auto And Business Solutions Ltd", label: "Orix Auto And Business Solutions Ltd" },
  { value: "Orix Auto And S.T Ltd", label: "Orix Auto And S.T Ltd" },
  { value: "Orix Auto Fin Ltd", label: "Orix Auto Fin Ltd" },
  { value: "Orix Auto Finleas (I) Ltd", label: "Orix Auto Finleas (I) Ltd" },
  { value: "Orix Auto Infrastructure", label: "Orix Auto Infrastructure" },
  { value: "Orix Auto Infrastructure Finance Service Ltd", label: "Orix Auto Infrastructure Finance Service Ltd" },
  { value: "Orix Auto L.B.S. Ltd", label: "Orix Auto L.B.S. Ltd" },
  { value: "Orix Auto Limi Infrastructure Services Ltd", label: "Orix Auto Limi Infrastructure Services Ltd" },
  { value: "Orix Finance", label: "Orix Finance" },
  { value: "Orix Infrastructure Ser.Ltd.", label: "Orix Infrastructure Ser.Ltd." },
  { value: "Orx Auto End Bus Sol Ltd", label: "Orx Auto End Bus Sol Ltd" },
  { value: "Osmanabad District Central Co-Operative Bank Ltd.", label: "Osmanabad District Central Co-Operative Bank Ltd." },
  { value: "Osmanabad Janata Sahakari Bank Ltd.", label: "Osmanabad Janata Sahakari Bank Ltd." },
  { value: "Ostwal Finance", label: "Ostwal Finance" },
  { value: "Ostwal Trading Co", label: "Ostwal Trading Co" },
  { value: "Osur Primary Agricalture Co-Op Bank Ltd,", label: "Osur Primary Agricalture Co-Op Bank Ltd," },
  { value: "Oswal Finance", label: "Oswal Finance" },
  { value: "Oswal Motors", label: "Oswal Motors" },
  { value: "Oswal Trading Co", label: "Oswal Trading Co" },
  { value: "Othecation Cholamandlam Dbs Finance Ltd", label: "Othecation Cholamandlam Dbs Finance Ltd" },
  { value: "Ottapalam Ser.Co.Operative Bank Ltd", label: "Ottapalam Ser.Co.Operative Bank Ltd" },
  { value: "Ottapalam Urban Co-Op Bank Ltd", label: "Ottapalam Urban Co-Op Bank Ltd" },
  { value: "Ottor Serbice Co-Op Bank Ltd", label: "Ottor Serbice Co-Op Bank Ltd" },
  { value: "Our Lady Of Milagres Urban Co Op Credit Society Ltd", label: "Our Lady Of Milagres Urban Co Op Credit Society Ltd" },
  { value: "Our Lady Of Milagres Urban Cp Cr Soc Lt", label: "Our Lady Of Milagres Urban Cp Cr Soc Lt" },
  { value: "Oversea-Chinese Banking Corporation Ltd.", label: "Oversea-Chinese Banking Corporation Ltd." },
  { value: "Overseas Manpower Corporation", label: "Overseas Manpower Corporation" },
  { value: "Overseas Sanmar Financial Ltd.", label: "Overseas Sanmar Financial Ltd." },
  { value: "Overseas United Finance", label: "Overseas United Finance" },
  { value: "Own Funds", label: "Own Funds" },
  { value: "Ozar Nagri Sahakari Path Sanstha", label: "Ozar Nagri Sahakari Path Sanstha" },
  { value: "Ozhar Merchants Co-Op Bank Ltd", label: "Ozhar Merchants Co-Op Bank Ltd" },
  { value: "P A Ashoka Finance Co", label: "P A Ashoka Finance Co" },
  { value: "P A Balan", label: "P A Balan" },
  { value: "P A C S  Secretary", label: "P A C S  Secretary" },
  { value: "P A R D B Hathin", label: "P A R D B Hathin" },
  { value: "P Ajiet (Huf) Finance", label: "P Ajiet (Huf) Finance" },
  { value: "P Anantha Raman", label: "P Anantha Raman" },
  { value: "P Anitha", label: "P Anitha" },
  { value: "P Anitha Kanwar Bagmar", label: "P Anitha Kanwar Bagmar" },
  { value: "P B Hiralal", label: "P B Hiralal" },
  { value: "P Bhaiva Singh", label: "P Bhaiva Singh" },
  { value: "P C Auto Finance", label: "P C Auto Finance" },
  { value: "P C Choudary And Sons", label: "P C Choudary And Sons" },
  { value: "P Chandra Kanwar Bagmar Financier", label: "P Chandra Kanwar Bagmar Financier" },
  { value: "P D Bikes", label: "P D Bikes" },
  { value: "P D Investments", label: "P D Investments" },
  { value: "P D Vincent", label: "P D Vincent" },
  { value: "P G Auto Finance", label: "P G Auto Finance" },
  { value: "P G B", label: "P G B" },
  { value: "P G Tyre", label: "P G Tyre" },
  { value: "P H And Co", label: "P H And Co" },
  { value: "P H F Corporation", label: "P H F Corporation" },
  { value: "P H F Leasing Ltd", label: "P H F Leasing Ltd" },
  { value: "P J Jain Finance", label: "P J Jain Finance" },
  { value: "P K F Finance Ltd", label: "P K F Finance Ltd" },
  { value: "P K Finance Corporation", label: "P K Finance Corporation" },
  { value: "P K Finance Ltd", label: "P K Finance Ltd" },
  { value: "P K Investments Financier", label: "P K Investments Financier" },
  { value: "P K Management Solution Pvt Ltd", label: "P K Management Solution Pvt Ltd" },
  { value: "P K P S Bank Ltd", label: "P K P S Bank Ltd" },
  { value: "P K Syndicate", label: "P K Syndicate" },
  { value: "P Kamala Bai Kothari", label: "P Kamala Bai Kothari" },
  { value: "P Kanchana Kanwar", label: "P Kanchana Kanwar" },
  { value: "P Kiran Bohra Financiers", label: "P Kiran Bohra Financiers" },
  { value: "P L D B  Sangod", label: "P L D B  Sangod" },
  { value: "P L D B Choumehla", label: "P L D B Choumehla" },
  { value: "P L Financiers & Money Lenders", label: "P L Financiers & Money Lenders" },
  { value: "P L Jain Financiers", label: "P L Jain Financiers" },
  { value: "P N Finance", label: "P N Finance" },
  { value: "P N Lease Finance", label: "P N Lease Finance" },
  { value: "P Nandakankarin", label: "P Nandakankarin" },
  { value: "P Narayanan Nair", label: "P Narayanan Nair" },
  { value: "P Narendra Kumar", label: "P Narendra Kumar" },
  { value: "P Navarathan Mull Finance", label: "P Navarathan Mull Finance" },
  { value: "P P Automotive", label: "P P Automotive" },
  { value: "P P Holdings Ltd.", label: "P P Holdings Ltd." },
  { value: "P Padamchand Financiers", label: "P Padamchand Financiers" },
  { value: "P Pamdam Chand Chennai", label: "P Pamdam Chand Chennai" },
  { value: "P Prakash Chand And Sons", label: "P Prakash Chand And Sons" },
  { value: "P R Dugar", label: "P R Dugar" },
  { value: "P R Simon", label: "P R Simon" },
  { value: "P Rajendra Kumar Bhandari Financier", label: "P Rajendra Kumar Bhandari Financier" },
  { value: "P Rajshree", label: "P Rajshree" },
  { value: "P Rakesh Kothari", label: "P Rakesh Kothari" },
  { value: "P Rakhee Kawar Financier", label: "P Rakhee Kawar Financier" },
  { value: "P Ramesh Chand", label: "P Ramesh Chand" },
  { value: "P Ramesh Chand Financier", label: "P Ramesh Chand Financier" },
  { value: "P Ramesh Kumar Lunkar Financier", label: "P Ramesh Kumar Lunkar Financier" },
  { value: "P Rameshchand & Sons", label: "P Rameshchand & Sons" },
  { value: "P Rameshkumar & Sons", label: "P Rameshkumar & Sons" },
  { value: "P Ravinder Chajjed", label: "P Ravinder Chajjed" },
  { value: "P S B Race Course", label: "P S B Race Course" },
  { value: "P S C C L", label: "P S C C L" },
  { value: "P S Gill Financiers Mandi Mullanpur", label: "P S Gill Financiers Mandi Mullanpur" },
  { value: "P S Hamza", label: "P S Hamza" },
  { value: "P S Motors", label: "P S Motors" },
  { value: "P S P Finance", label: "P S P Finance" },
  { value: "P Sangeetha Devi", label: "P Sangeetha Devi" },
  { value: "P Saroja Devi", label: "P Saroja Devi" },
  { value: "P Sumer Chand Challani", label: "P Sumer Chand Challani" },
  { value: "P Sureshchand Chhllani", label: "P Sureshchand Chhllani" },
  { value: "P Tara Kumari & Karur Vysya Bank", label: "P Tara Kumari & Karur Vysya Bank" },
  { value: "P Uttam Chand Doshi", label: "P Uttam Chand Doshi" },
  { value: "P V K Finance", label: "P V K Finance" },
  { value: "P V K Investments", label: "P V K Investments" },
  { value: "P. A. R. D. B.", label: "P. A. R. D. B." },
  { value: "P. Deepak Nahar", label: "P. Deepak Nahar" },
  { value: "P. K. Anna Patil Janata Sah Bank", label: "P. K. Anna Patil Janata Sah Bank" },
  { value: "P. K. H. B.", label: "P. K. H. B." },
  { value: "P. Kalippatty Prim Agri Co-Op Bank Ltd.", label: "P. Kalippatty Prim Agri Co-Op Bank Ltd." },
  { value: "P. Kanchan Kawar", label: "P. Kanchan Kawar" },
  { value: "P. Lalith", label: "P. Lalith" },
  { value: "P. M. Chordiya", label: "P. M. Chordiya" },
  { value: "P. M. Chordiya", label: "P. M. Chordiya" },
  { value: "P. Navarathan Mull Financier", label: "P. Navarathan Mull Financier" },
  { value: "P. Naveriathanmull", label: "P. Naveriathanmull" },
  { value: "P. Nirmala", label: "P. Nirmala" },
  { value: "P. Padam Chand Chennai", label: "P. Padam Chand Chennai" },
  { value: "P. Prakashchand Finance", label: "P. Prakashchand Finance" },
  { value: "P. Pramod Kumar Gothi", label: "P. Pramod Kumar Gothi" },
  { value: "P. Pu. Gagangiri Mah. Nag.Sah.Patsanstha", label: "P. Pu. Gagangiri Mah. Nag.Sah.Patsanstha" },
  { value: "P. Rajshree", label: "P. Rajshree" },
  { value: "P. Sanjana Lalwani", label: "P. Sanjana Lalwani" },
  { value: "P.A.C.C.B.", label: "P.A.C.C.B." },
  { value: "P.A.C.S", label: "P.A.C.S" },
  { value: "P.A.C.S. Dhannasary", label: "P.A.C.S. Dhannasary" },
  { value: "P.A.D.B", label: "P.A.D.B" },
  { value: "P.A.R.D.B.", label: "P.A.R.D.B." },
  { value: "P.A.R.D.B. Gohana", label: "P.A.R.D.B. Gohana" },
  { value: "P.C.A. & R.O. Bank", label: "P.C.A. & R.O. Bank" },
  { value: "P.C.B.L", label: "P.C.B.L" },
  { value: "P.C.B.L.", label: "P.C.B.L." },
  { value: "P.E.C. Ltd.", label: "P.E.C. Ltd." },
  { value: "P.G.B.", label: "P.G.B." },
  { value: "P.G.B. Bank", label: "P.G.B. Bank" },
  { value: "P.Goutham Chand", label: "P.Goutham Chand" },
  { value: "P.J.B.", label: "P.J.B." },
  { value: "P.K. Investment", label: "P.K. Investment" },
  { value: "P.K.H.B Nalayangang", label: "P.K.H.B Nalayangang" },
  { value: "P.K.H.B.", label: "P.K.H.B." },
  { value: "P.K.K Auto Finance", label: "P.K.K Auto Finance" },
  { value: "P.K.P.S. Hanagondi", label: "P.K.P.S. Hanagondi" },
  { value: "P.L.A  Palaniappan", label: "P.L.A  Palaniappan" },
  { value: "P.L.D.B", label: "P.L.D.B" },
  { value: "P.M Chordiya", label: "P.M Chordiya" },
  { value: "P.M.Credits", label: "P.M.Credits" },
  { value: "P.Murugan", label: "P.Murugan" },
  { value: "P.N. Finance", label: "P.N. Finance" },
  { value: "P.N.P", label: "P.N.P" },
  { value: "P.P. Premchand Chouradiya Finance", label: "P.P. Premchand Chouradiya Finance" },
  { value: "P.P.Finance", label: "P.P.Finance" },
  { value: "P.Padam Chand  29, Chandrappa Mudali St Sowcarpet", label: "P.Padam Chand  29, Chandrappa Mudali St Sowcarpet" },
  { value: "P.Padamchand And Sons", label: "P.Padamchand And Sons" },
  { value: "P.Padmachand Financers", label: "P.Padmachand Financers" },
  { value: "P.Prakashchand", label: "P.Prakashchand" },
  { value: "P.Ramesh Chand, Chennai", label: "P.Ramesh Chand, Chennai" },
  { value: "P.S. Agencies", label: "P.S. Agencies" },
  { value: "P.S.C.C.L.", label: "P.S.C.C.L." },
  { value: "P.S.S Leasing & Finance (P) Ltd.", label: "P.S.S Leasing & Finance (P) Ltd." },
  { value: "P.Sunilkumar Jain", label: "P.Sunilkumar Jain" },
  { value: "P.V. Baldas Krupa Mahadevrao Mahadik  Gramin Bigar Sheti Sahkari Patsanstha Ltd", label: "P.V. Baldas Krupa Mahadevrao Mahadik  Gramin Bigar Sheti Sahkari Patsanstha Ltd" },
  { value: "P.V.G.Finance", label: "P.V.G.Finance" },
  { value: "P.Vinod Kumar Jain", label: "P.Vinod Kumar Jain" },
  { value: "Pacb", label: "Pacb" },
  { value: "Pacb Bank Ltd", label: "Pacb Bank Ltd" },
  { value: "Pachalam Auto Finance", label: "Pachalam Auto Finance" },
  { value: "Pachhapur Urban Co-Op Bank Ltd", label: "Pachhapur Urban Co-Op Bank Ltd" },
  { value: "Pachora Peoples Co-Op Bk Ltd.", label: "Pachora Peoples Co-Op Bk Ltd." },
  { value: "Pachora Peoples Co-Op Bank Ltd", label: "Pachora Peoples Co-Op Bank Ltd" },
  { value: "Pacp Bank", label: "Pacp Bank" },
  { value: "Pacs Co Bank Ltd", label: "Pacs Co Bank Ltd" },
  { value: "Pact Securities And Financial Services Ltd.", label: "Pact Securities And Financial Services Ltd." },
  { value: "Padaki Sowharda Credit Co-Operative Ltd.", label: "Padaki Sowharda Credit Co-Operative Ltd." },
  { value: "Padali Darya Gramin Bigar Sheti", label: "Padali Darya Gramin Bigar Sheti" },
  { value: "Padam Auto Finance", label: "Padam Auto Finance" },
  { value: "Padam Chand And Sons", label: "Padam Chand And Sons" },
  { value: "Padam Chand Chhallani Huf", label: "Padam Chand Chhallani Huf" },
  { value: "Padam Chand Singvi And Sons", label: "Padam Chand Singvi And Sons" },
  { value: "Padam Chand Surana", label: "Padam Chand Surana" },
  { value: "Padam Finance Corporation", label: "Padam Finance Corporation" },
  { value: "Padam Kamal Finance", label: "Padam Kamal Finance" },
  { value: "Padam Motors", label: "Padam Motors" },
  { value: "Padam Motors", label: "Padam Motors" },
  { value: "Padam Shree Finance", label: "Padam Shree Finance" },
  { value: "Padam Son Kothari", label: "Padam Son Kothari" },
  { value: "Padamavathi And Co", label: "Padamavathi And Co" },
  { value: "Padamavathi Co-Op Urban Bank Ltd", label: "Padamavathi Co-Op Urban Bank Ltd" },
  { value: "Padamawati Gramin Bigar Sheti Sahakari Path Sanstha", label: "Padamawati Gramin Bigar Sheti Sahakari Path Sanstha" },
  { value: "Padamchand Financier", label: "Padamchand Financier" },
  { value: "Padamchand& Sons", label: "Padamchand& Sons" },
  { value: "Padamraj", label: "Padamraj" },
  { value: "Padavu Vyavasaya Seva Sah. Bk Niyamitha", label: "Padavu Vyavasaya Seva Sah. Bk Niyamitha" },
  { value: "Padma Auto Mobile Finance", label: "Padma Auto Mobile Finance" },
  { value: "Padma Dr.Vitthalrao Vikhe Patil Cp Bk L", label: "Padma Dr.Vitthalrao Vikhe Patil Cp Bk L" },
  { value: "Padma Finance Corporation", label: "Padma Finance Corporation" },
  { value: "Padma Investments", label: "Padma Investments" },
  { value: "Padma Kothari Fin Nagpur", label: "Padma Kothari Fin Nagpur" },
  { value: "Padma Kothari Nagpur", label: "Padma Kothari Nagpur" },
  { value: "Padma Motors", label: "Padma Motors" },
  { value: "Padma Priya Finance Corporation", label: "Padma Priya Finance Corporation" },
  { value: "Padma Priya Financiers", label: "Padma Priya Financiers" },
  { value: "Padma Pujitha Finance", label: "Padma Pujitha Finance" },
  { value: "Padma Sai Auto Finance", label: "Padma Sai Auto Finance" },
  { value: "Padma Sai Finance", label: "Padma Sai Finance" },
  { value: "Padmachand Singhvi And Sons Huf", label: "Padmachand Singhvi And Sons Huf" },
  { value: "Padmaja Ventures Ltd.", label: "Padmaja Ventures Ltd." },
  { value: "Padmalaya Finance", label: "Padmalaya Finance" },
  { value: "Padmalaya Finance & Consultants", label: "Padmalaya Finance & Consultants" },
  { value: "Padmaraj Finance", label: "Padmaraj Finance" },
  { value: "Padmashree Credit Co-Operative Society", label: "Padmashree Credit Co-Operative Society" },
  { value: "Padmashree Vitthalrao Vikhe Patil Co. Op.Bank Ltd", label: "Padmashree Vitthalrao Vikhe Patil Co. Op.Bank Ltd" },
  { value: "Padmasree Finance Corporation", label: "Padmasree Finance Corporation" },
  { value: "Padmasri Automobile Financiers", label: "Padmasri Automobile Financiers" },
  { value: "Padmavathi Auto Finance Corporation", label: "Padmavathi Auto Finance Corporation" },
  { value: "Padmavathi Co-Operative Urgan Bank Ltd", label: "Padmavathi Co-Operative Urgan Bank Ltd" },
  { value: "Padmavathi Finance", label: "Padmavathi Finance" },
  { value: "Padmavathi Investments", label: "Padmavathi Investments" },
  { value: "Padmavathi Leasing(R) Humnabad", label: "Padmavathi Leasing(R) Humnabad" },
  { value: "Padmavathi Syndicate", label: "Padmavathi Syndicate" },
  { value: "Padmavati Credit Corporation", label: "Padmavati Credit Corporation" },
  { value: "Padmavati Finance Co", label: "Padmavati Finance Co" },
  { value: "Padmavati Financial Services Ltd.", label: "Padmavati Financial Services Ltd." },
  { value: "Padmavati Gramin Bigar Sheti Sah Pat", label: "Padmavati Gramin Bigar Sheti Sah Pat" },
  { value: "Padmavati Investment Ltd", label: "Padmavati Investment Ltd" },
  { value: "Padmavati Investments", label: "Padmavati Investments" },
  { value: "Padmawati Finance", label: "Padmawati Finance" },
  { value: "Padmawati Gramin Biger Sheti Sah Path Santh.", label: "Padmawati Gramin Biger Sheti Sah Path Santh." },
  { value: "Padmawati Investment", label: "Padmawati Investment" },
  { value: "Padmodaya Investment", label: "Padmodaya Investment" },
  { value: "Padshala Ur Co Op Cr So Ltd", label: "Padshala Ur Co Op Cr So Ltd" },
  { value: "Padubidri C. A. Bank Ltd.", label: "Padubidri C. A. Bank Ltd." },
  { value: "Padupanambur Co Op Agr Bank Ltd  Haleangadi", label: "Padupanambur Co Op Agr Bank Ltd  Haleangadi" },
  { value: "Pagaria Finance Syndicate", label: "Pagaria Finance Syndicate" },
  { value: "Pagaria Investments", label: "Pagaria Investments" },
  { value: "Pahedar Finance (P) Ltd", label: "Pahedar Finance (P) Ltd" },
  { value: "Paipra Ser Co-Op Bk L", label: "Paipra Ser Co-Op Bk L" },
  { value: "Paipra Service Co-Oprative Bk Ltd No.679", label: "Paipra Service Co-Oprative Bk Ltd No.679" },
  { value: "Paisa Fund Shetaki Sah Bank", label: "Paisa Fund Shetaki Sah Bank" },
  { value: "Paivalike Service Co-Op Bank", label: "Paivalike Service Co-Op Bank" },
  { value: "Pal Auto Deals", label: "Pal Auto Deals" },
  { value: "Pal Credits And Capital Ltd", label: "Pal Credits And Capital Ltd" },
  { value: "Pal Finance Co.", label: "Pal Finance Co." },
  { value: "Pal Finleasevest (India) Limited", label: "Pal Finleasevest (India) Limited" },
  { value: "Pal Trading Company", label: "Pal Trading Company" },
  { value: "Pala Urban Co-Operative Bank Ltd.", label: "Pala Urban Co-Operative Bank Ltd." },
  { value: "Palakkad District Central Co-Operative Bank Ltd.", label: "Palakkad District Central Co-Operative Bank Ltd." },
  { value: "Palakkurichi Primary Agr. Co. Bank Ltd.", label: "Palakkurichi Primary Agr. Co. Bank Ltd." },
  { value: "Palamau Kshetriya Gramin Bank", label: "Palamau Kshetriya Gramin Bank" },
  { value: "Palamcottah Urban Co-Op Bank Ltd", label: "Palamcottah Urban Co-Op Bank Ltd" },
  { value: "Palani Andavar Auto Finance", label: "Palani Andavar Auto Finance" },
  { value: "Palani Co.Op. Urban Bank Ltd", label: "Palani Co.Op. Urban Bank Ltd" },
  { value: "Palaniappa Finance", label: "Palaniappa Finance" },
  { value: "Palaniappa Financial Ltd", label: "Palaniappa Financial Ltd" },
  { value: "Palanimalai Murugan Finance", label: "Palanimalai Murugan Finance" },
  { value: "Palaniraja Credits", label: "Palaniraja Credits" },
  { value: "Palaniraja Finance", label: "Palaniraja Finance" },
  { value: "Palaniraja Finance Company", label: "Palaniraja Finance Company" },
  { value: "Palanivel Murugan Finance", label: "Palanivel Murugan Finance" },
  { value: "Pali District Central Co-Operative Bank Ltd.", label: "Pali District Central Co-Operative Bank Ltd." },
  { value: "Pali Urban Co-Op Bank Ltd", label: "Pali Urban Co-Op Bank Ltd" },
  { value: "Pali Urban Co-Op. Bank Ltd", label: "Pali Urban Co-Op. Bank Ltd" },
  { value: "Palit International", label: "Palit International" },
  { value: "Palladam Fscs", label: "Palladam Fscs" },
  { value: "Pallathur Primary Agricultural Co-Op. B.", label: "Pallathur Primary Agricultural Co-Op. B." },
  { value: "Pallavan Finance", label: "Pallavan Finance" },
  { value: "Pallavan Grama Bank,", label: "Pallavan Grama Bank," },
  { value: "Pallichal Co-Op.Agri.Imp.Society Ltd", label: "Pallichal Co-Op.Agri.Imp.Society Ltd" },
  { value: "Pallippuram Service Co-Op Bank Ltd.", label: "Pallippuram Service Co-Op Bank Ltd." },
  { value: "Pallipuram Service Co-Op Bank Ltd", label: "Pallipuram Service Co-Op Bank Ltd" },
  { value: "Pallonji Leasing Pvt Ltd", label: "Pallonji Leasing Pvt Ltd" },
  { value: "Palluruthy Mandalam Service Co.Op Bk Ltd", label: "Palluruthy Mandalam Service Co.Op Bk Ltd" },
  { value: "Palode Co-Operative Agricultural And Rural Development Bank Ltd.", label: "Palode Co-Operative Agricultural And Rural Development Bank Ltd." },
  { value: "Palus Sahakari Bank Ltd.", label: "Palus Sahakari Bank Ltd." },
  { value: "Pampady Service Co-Op. Bank Ltd", label: "Pampady Service Co-Op. Bank Ltd" },
  { value: "Pampagouda Malipatil", label: "Pampagouda Malipatil" },
  { value: "Pampakuda Ser Co-Op Bank Ltd.", label: "Pampakuda Ser Co-Op Bank Ltd." },
  { value: "Panaji Finance Pvt. Ltd.", label: "Panaji Finance Pvt. Ltd." },
  { value: "Panangad Service Co Op Bank Ltd", label: "Panangad Service Co Op Bank Ltd" },
  { value: "Panavally Village Service Co-Op Bank Ltd", label: "Panavally Village Service Co-Op Bank Ltd" },
  { value: "Panayal Service Co. Op. Bank Ltd.", label: "Panayal Service Co. Op. Bank Ltd." },
  { value: "Panchachayaga Sah Pattin Sangh Niyamit", label: "Panchachayaga Sah Pattin Sangh Niyamit" },
  { value: "Panchaganga Raithara Seva Sahakari Bank Ltd.", label: "Panchaganga Raithara Seva Sahakari Bank Ltd." },
  { value: "Panchganga Nagari Sah.Bank", label: "Panchganga Nagari Sah.Bank" },
  { value: "Panchkula District Central Co-Operative Bank Ltd.", label: "Panchkula District Central Co-Operative Bank Ltd." },
  { value: "Panchkula Mahalaxmi Finance Co", label: "Panchkula Mahalaxmi Finance Co" },
  { value: "Panchmahal District Central Co-Operative Bank Ltd.", label: "Panchmahal District Central Co-Operative Bank Ltd." },
  { value: "Panchmahal Gramin Gujarat Bank", label: "Panchmahal Gramin Gujarat Bank" },
  { value: "Panchratna Nagri Sahakari Patsanstha Maryadit", label: "Panchratna Nagri Sahakari Patsanstha Maryadit" },
  { value: "Panchsheel Mercantile Co-Op Bank Ltd.", label: "Panchsheel Mercantile Co-Op Bank Ltd." },
  { value: "Panchvati Nagri  Sahakari  Path Sast", label: "Panchvati Nagri  Sahakari  Path Sast" },
  { value: "Panda Co Op Credit Society Ltd", label: "Panda Co Op Credit Society Ltd" },
  { value: "Pandavapura Co-Operative Agricultural & Rural Development Bank Ltd.", label: "Pandavapura Co-Operative Agricultural & Rural Development Bank Ltd." },
  { value: "Pandey Rajkapoor Ramkaran", label: "Pandey Rajkapoor Ramkaran" },
  { value: "Pandharpur Merchants Co-Op Bank Ltd", label: "Pandharpur Merchants Co-Op Bank Ltd" },
  { value: "Pandharpur Urban Co-Op Bank Ltd", label: "Pandharpur Urban Co-Op Bank Ltd" },
  { value: "Pandian Auto Finance Thirunelveli", label: "Pandian Auto Finance Thirunelveli" },
  { value: "Pandian Grama Bank Ltd", label: "Pandian Grama Bank Ltd" },
  { value: "Pandit Dindayal Nagari Sahakati  Patsanstha", label: "Pandit Dindayal Nagari Sahakati  Patsanstha" },
  { value: "Pandit Dindayal Path Sanstha", label: "Pandit Dindayal Path Sanstha" },
  { value: "Pandit Financial Associates", label: "Pandit Financial Associates" },
  { value: "Pandurang Bigar Gramin S. P. S. Maryadit.", label: "Pandurang Bigar Gramin S. P. S. Maryadit." },
  { value: "Pandurang Shastri Athawale Gramin Bigar Sheti Sahkari Patsanstha Maryadit", label: "Pandurang Shastri Athawale Gramin Bigar Sheti Sahkari Patsanstha Maryadit" },
  { value: "Panduranga Co Op Society Ltd", label: "Panduranga Co Op Society Ltd" },
  { value: "Pandyan Grama Bank", label: "Pandyan Grama Bank" },
  { value: "Pandyan Grama Bank, Madurai", label: "Pandyan Grama Bank, Madurai" },
  { value: "Panemangalore Rythara Seva Sah Bank Ltd", label: "Panemangalore Rythara Seva Sah Bank Ltd" },
  { value: "Panipat District Central Co-Operative Bank Ltd.", label: "Panipat District Central Co-Operative Bank Ltd." },
  { value: "Panipat Urban Co-Op Bank Ltd", label: "Panipat Urban Co-Op Bank Ltd" },
  { value: "Panjab National Bank,", label: "Panjab National Bank," },
  { value: "Panjabrao Deshmukh Urban Co Op Bank Ltd", label: "Panjabrao Deshmukh Urban Co Op Bank Ltd" },
  { value: "Pankaj Arora", label: "Pankaj Arora" },
  { value: "Pankaj Ashok Chaudhari", label: "Pankaj Ashok Chaudhari" },
  { value: "Pankaj Auto Finance Co", label: "Pankaj Auto Finance Co" },
  { value: "Pankaj Chauhan", label: "Pankaj Chauhan" },
  { value: "Pankaj Finance Corporation", label: "Pankaj Finance Corporation" },
  { value: "Pankaj Financers", label: "Pankaj Financers" },
  { value: "Pankaj Financers", label: "Pankaj Financers" },
  { value: "Pankaj Financial Services Ltd", label: "Pankaj Financial Services Ltd" },
  { value: "Pankaj Kumar Manish Kumar", label: "Pankaj Kumar Manish Kumar" },
  { value: "Pankaj Kumar Manish Kumar Ahmedabad", label: "Pankaj Kumar Manish Kumar Ahmedabad" },
  { value: "Pankaj Kumar Sarkar", label: "Pankaj Kumar Sarkar" },
  { value: "Pankaj Leasing", label: "Pankaj Leasing" },
  { value: "Pankaj Nandkishore Nyati", label: "Pankaj Nandkishore Nyati" },
  { value: "Pankaj Srivastava", label: "Pankaj Srivastava" },
  { value: "Pankaj Surana S", label: "Pankaj Surana S" },
  { value: "Panna Finance Ltd", label: "Panna Finance Ltd" },
  { value: "Pannalal & Co", label: "Pannalal & Co" },
  { value: "Pannalal & Sons", label: "Pannalal & Sons" },
  { value: "Pannalal Singhv", label: "Pannalal Singhv" },
  { value: "Pannappatti Primary Agri.Co.Bank Ltd.", label: "Pannappatti Primary Agri.Co.Bank Ltd." },
  { value: "Pannivizha Service Co-Op. Bank Ltd", label: "Pannivizha Service Co-Op. Bank Ltd" },
  { value: "Panvel Urban Co-Op Bank Ltd", label: "Panvel Urban Co-Op Bank Ltd" },
  { value: "Papanasam Co-Op. Urban Bank Ltd.", label: "Papanasam Co-Op. Urban Bank Ltd." },
  { value: "Pappinissery Co-Op. Ruban Bank Ltd", label: "Pappinissery Co-Op. Ruban Bank Ltd" },
  { value: "Pappinivattam Service Co-Op. Bank Ltd", label: "Pappinivattam Service Co-Op. Bank Ltd" },
  { value: "Paradeep Phosphate Limited", label: "Paradeep Phosphate Limited" },
  { value: "Paradiese Interpises Ltd.", label: "Paradiese Interpises Ltd." },
  { value: "Paradip Port Trust", label: "Paradip Port Trust" },
  { value: "Paradise Finance Co. Ltd.", label: "Paradise Finance Co. Ltd." },
  { value: "Paradrampuria Financial Services", label: "Paradrampuria Financial Services" },
  { value: "Parakode Services Co-Op Bk Ltd", label: "Parakode Services Co-Op Bk Ltd" },
  { value: "Parameshwar Auto Finance", label: "Parameshwar Auto Finance" },
  { value: "Parameswari Finance Corporation Ltd", label: "Parameswari Finance Corporation Ltd" },
  { value: "Paramount Enterprises", label: "Paramount Enterprises" },
  { value: "Paras Auto Finance Corporation", label: "Paras Auto Finance Corporation" },
  { value: "Paras Auto Financiers", label: "Paras Auto Financiers" },
  { value: "Paras Credit Corporation", label: "Paras Credit Corporation" },
  { value: "Paras Enterprise", label: "Paras Enterprise" },
  { value: "Paras Finance Corporation", label: "Paras Finance Corporation" },
  { value: "Paras Financiers Ltd", label: "Paras Financiers Ltd" },
  { value: "Paras H.P. Co (I) Pvt. Ltd.", label: "Paras H.P. Co (I) Pvt. Ltd." },
  { value: "Paras Hire Purchase", label: "Paras Hire Purchase" },
  { value: "Paras Investments", label: "Paras Investments" },
  { value: "Paras Jain Gramin Bigar Sheti Co Op Patsantha Maryadit", label: "Paras Jain Gramin Bigar Sheti Co Op Patsantha Maryadit" },
  { value: "Paras Kannar Financier", label: "Paras Kannar Financier" },
  { value: "Paras Mahavir Coop Cr Soc Ltd", label: "Paras Mahavir Coop Cr Soc Ltd" },
  { value: "Paras Nath Associates", label: "Paras Nath Associates" },
  { value: "Paras Nath Financier", label: "Paras Nath Financier" },
  { value: "Paras Padmavati", label: "Paras Padmavati" },
  { value: "Paras Padmavati Investment", label: "Paras Padmavati Investment" },
  { value: "Paras Trading & Co", label: "Paras Trading & Co" },
  { value: "Parasawalkam Co - Op Bnak Ltd", label: "Parasawalkam Co - Op Bnak Ltd" },
  { value: "Parashar Rushi Gramin B S Pathsanstha", label: "Parashar Rushi Gramin B S Pathsanstha" },
  { value: "Parashwant Co-Operative Bank Ltd", label: "Parashwant Co-Operative Bank Ltd" },
  { value: "Parasmal Jain", label: "Parasmal Jain" },
  { value: "Parasmal Suresh Kumar Betala.", label: "Parasmal Suresh Kumar Betala." },
  { value: "Parasmani Envesments", label: "Parasmani Envesments" },
  { value: "Parasmani Group Of Finance", label: "Parasmani Group Of Finance" },
  { value: "Parasnath Auto Financiers", label: "Parasnath Auto Financiers" },
  { value: "Parasnath Finance Corporation", label: "Parasnath Finance Corporation" },
  { value: "Paraspar Sahayak Co-Op. Bank Ltd.", label: "Paraspar Sahayak Co-Op. Bank Ltd." },
  { value: "Paraspar Sahayak Sahakari Mandali", label: "Paraspar Sahayak Sahakari Mandali" },
  { value: "Paraspara Sahayak Co-Op Bank Ltd., Indore", label: "Paraspara Sahayak Co-Op Bank Ltd., Indore" },
  { value: "Parasram Maharaj Gramin Bigar Sheti Sahakari Pathsantha Maryadit", label: "Parasram Maharaj Gramin Bigar Sheti Sahakari Pathsantha Maryadit" },
  { value: "Parasuvaikal Service Coop Bank Ltd.,", label: "Parasuvaikal Service Coop Bank Ltd.," },
  { value: "Paravoor Co-Operative Bank Ltd", label: "Paravoor Co-Operative Bank Ltd" },
  { value: "Paravur Auto Centre Finance & Investments", label: "Paravur Auto Centre Finance & Investments" },
  { value: "Parbhani District Central Co-Operative Bank Ltd.", label: "Parbhani District Central Co-Operative Bank Ltd." },
  { value: "Parbhani Peoples Co-Op Bank Ltd", label: "Parbhani Peoples Co-Op Bank Ltd" },
  { value: "Pard Bank Ltd", label: "Pard Bank Ltd" },
  { value: "Pardb Ltd", label: "Pardb Ltd" },
  { value: "Pardb Palwal", label: "Pardb Palwal" },
  { value: "Pardha Saradhi Auto Finance", label: "Pardha Saradhi Auto Finance" },
  { value: "Parekh Finance", label: "Parekh Finance" },
  { value: "Paresh Finance Co.", label: "Paresh Finance Co." },
  { value: "Parichay Financiers", label: "Parichay Financiers" },
  { value: "Paridhi Finance Ltd", label: "Paridhi Finance Ltd" },
  { value: "Parijat Co Opcre Soci Bank Ltd", label: "Parijat Co Opcre Soci Bank Ltd" },
  { value: "Parika Investment Pvt Ltd", label: "Parika Investment Pvt Ltd" },
  { value: "Parimalam Finance", label: "Parimalam Finance" },
  { value: "Parinder Singh Bhatiya", label: "Parinder Singh Bhatiya" },
  { value: "Pariporan Leasing Finance Pvt Ltd", label: "Pariporan Leasing Finance Pvt Ltd" },
  { value: "Paritosh Mondal", label: "Paritosh Mondal" },
  { value: "Parivar Credit Co-Op Society Ltd", label: "Parivar Credit Co-Op Society Ltd" },
  { value: "Parivar Credit Co-Op Society Manipal", label: "Parivar Credit Co-Op Society Manipal" },
  { value: "Pariwar Credit Co-Op Society Ltd - Udupi", label: "Pariwar Credit Co-Op Society Ltd - Udupi" },
  { value: "Pariyojana Prabandhar Rscdc", label: "Pariyojana Prabandhar Rscdc" },
  { value: "Parmatma Ek Sevak Nagrik Sahakari Bank Ltd., Nagpur", label: "Parmatma Ek Sevak Nagrik Sahakari Bank Ltd., Nagpur" },
  { value: "Parmatma Ek Sewak Nagrik Sahakari Bk Ltd", label: "Parmatma Ek Sewak Nagrik Sahakari Bk Ltd" },
  { value: "Parminder Bhatia", label: "Parminder Bhatia" },
  { value: "Parnami Credits Limited", label: "Parnami Credits Limited" },
  { value: "Parnami Finance Ltd", label: "Parnami Finance Ltd" },
  { value: "Parner Gamin Bigar Sheti Sahakari Patsanstha", label: "Parner Gamin Bigar Sheti Sahakari Patsanstha" },
  { value: "Parner Gramin Bigar Sheti Sah Putsanstha Alkukuti", label: "Parner Gramin Bigar Sheti Sah Putsanstha Alkukuti" },
  { value: "Parner Gramin Biger Shakari Sansta Pat", label: "Parner Gramin Biger Shakari Sansta Pat" },
  { value: "Parner Gramin Pathsanstha", label: "Parner Gramin Pathsanstha" },
  { value: "Parner Saink Bank", label: "Parner Saink Bank" },
  { value: "Parner Taluka Sainik Sahakari Bank Ltd", label: "Parner Taluka Sainik Sahakari Bank Ltd" },
  { value: "Parner Taluka Sainik Sahakari Bank Ltd.", label: "Parner Taluka Sainik Sahakari Bank Ltd." },
  { value: "Parner Taluka Sainik Sahakari Patsanstha", label: "Parner Taluka Sainik Sahakari Patsanstha" },
  { value: "Parnerkar Nagari Saha Pat", label: "Parnerkar Nagari Saha Pat" },
  { value: "Paroda Vividha Kariyakari Sahakari Society Ltd", label: "Paroda Vividha Kariyakari Sahakari Society Ltd" },
  { value: "Parola Urban Co Bank Ltd,", label: "Parola Urban Co Bank Ltd," },
  { value: "Parshavnath Finance Company", label: "Parshavnath Finance Company" },
  { value: "Parshuram Jadhav Nagari Sahakari Pat Sanstha Mar", label: "Parshuram Jadhav Nagari Sahakari Pat Sanstha Mar" },
  { value: "Parshwa Finance", label: "Parshwa Finance" },
  { value: "Parshwanath Co-Op Bank Ltd", label: "Parshwanath Co-Op Bank Ltd" },
  { value: "Parshwanath Co-Op Bank Ltd.", label: "Parshwanath Co-Op Bank Ltd." },
  { value: "Parshwanath Minority Souhard Co-Op Credit Society", label: "Parshwanath Minority Souhard Co-Op Credit Society" },
  { value: "Parshwanatha Sahakara Bank Niyamitha ( R )", label: "Parshwanatha Sahakara Bank Niyamitha ( R )" },
  { value: "Parsik Janata Co-Op Bank Ltd", label: "Parsik Janata Co-Op Bank Ltd" },
  { value: "Parsik Janata Sah.Bank Ltd., Kalwa-Thane", label: "Parsik Janata Sah.Bank Ltd., Kalwa-Thane" },
  { value: "Parsik Janata Sahakari Bank Ltd", label: "Parsik Janata Sahakari Bank Ltd" },
  { value: "Parsik Janta Sahkari Bank Ltd", label: "Parsik Janta Sahkari Bank Ltd" },
  { value: "Parswanth Finance", label: "Parswanth Finance" },
  { value: "Parteel Finance", label: "Parteel Finance" },
  { value: "Parth Enterprise", label: "Parth Enterprise" },
  { value: "Partur Peoples Co-Op Bank Ltd", label: "Partur Peoples Co-Op Bank Ltd" },
  { value: "Parul Vividh Sanstha E.Co-Op Cr.Soc.Ltd.", label: "Parul Vividh Sanstha E.Co-Op Cr.Soc.Ltd." },
  { value: "Parur Central Bank Ltd.", label: "Parur Central Bank Ltd." },
  { value: "Parv Financiers  (P) Ltd", label: "Parv Financiers  (P) Ltd" },
  { value: "Parvanchal Gramin Bank", label: "Parvanchal Gramin Bank" },
  { value: "Parvathanent Sudha Rani", label: "Parvathanent Sudha Rani" },
  { value: "Parvati Co. Op. Cr. Soc", label: "Parvati Co. Op. Cr. Soc" },
  { value: "Parveen Kumar", label: "Parveen Kumar" },
  { value: "Parvindar Singh Bhatiya", label: "Parvindar Singh Bhatiya" },
  { value: "Parwanoo Urban Co Bank Ltd,", label: "Parwanoo Urban Co Bank Ltd," },
  { value: "Parwanoo Urban Co-Op Bank", label: "Parwanoo Urban Co-Op Bank" },
  { value: "Parwanoo Urban Co-Op.Bank Ltd.", label: "Parwanoo Urban Co-Op.Bank Ltd." },
  { value: "Pas Investments", label: "Pas Investments" },
  { value: "Paschapur Urban Co-Op Cr Bank Ltd", label: "Paschapur Urban Co-Op Cr Bank Ltd" },
  { value: "Paschimbanga Gramin Bank", label: "Paschimbanga Gramin Bank" },
  { value: "Pasupati Arcylon Ltd", label: "Pasupati Arcylon Ltd" },
  { value: "Pataleshwar Gramin Big Sheti Saha", label: "Pataleshwar Gramin Big Sheti Saha" },
  { value: "Patan Co-Op Bank Ltd. Mumbai", label: "Patan Co-Op Bank Ltd. Mumbai" },
  { value: "Patan Co-Operative Bank Ltd.", label: "Patan Co-Operative Bank Ltd." },
  { value: "Patan Nagarik Sahakari Bank Ltd.", label: "Patan Nagarik Sahakari Bank Ltd." },
  { value: "Patan Nagrik Sahkari Bank  Ltd", label: "Patan Nagrik Sahkari Bank  Ltd" },
  { value: "Patas Sahakari Bank Ltd", label: "Patas Sahakari Bank Ltd" },
  { value: "Patel Group", label: "Patel Group" },
  { value: "Patel Leasing Finance Co.", label: "Patel Leasing Finance Co." },
  { value: "Patel Rohan Dineshbhai", label: "Patel Rohan Dineshbhai" },
  { value: "Patent Facilitating Centre", label: "Patent Facilitating Centre" },
  { value: "Patharwadi Bigar Sheti Sahakari Patsanstha", label: "Patharwadi Bigar Sheti Sahakari Patsanstha" },
  { value: "Pathfinder Investment Company Private Limited", label: "Pathfinder Investment Company Private Limited" },
  { value: "Patiala District Central Co-Operative Bank Ltd.", label: "Patiala District Central Co-Operative Bank Ltd." },
  { value: "Patidar Auto Finance", label: "Patidar Auto Finance" },
  { value: "Patidar Enterprise", label: "Patidar Enterprise" },
  { value: "Patidar Financiers", label: "Patidar Financiers" },
  { value: "Patliputra Gramine Bank", label: "Patliputra Gramine Bank" },
  { value: "Patna S.K.U.S. Ltd.", label: "Patna S.K.U.S. Ltd." },
  { value: "Patni Co-Op Bank Ltd.", label: "Patni Co-Op Bank Ltd." },
  { value: "Patran Finance Ltd", label: "Patran Finance Ltd" },
  { value: "Pattanakkad Service Co-Operative Bank", label: "Pattanakkad Service Co-Operative Bank" },
  { value: "Pattanamthitta District Central Co-Operative Bank Ltd.", label: "Pattanamthitta District Central Co-Operative Bank Ltd." },
  { value: "Pattankot Hindu Urban Co-Op Bank Ltd", label: "Pattankot Hindu Urban Co-Op Bank Ltd" },
  { value: "Pattom Service Co-Op Bank Ltd.", label: "Pattom Service Co-Op Bank Ltd." },
  { value: "Paul Merchants Limited", label: "Paul Merchants Limited" },
  { value: "Pauls Auto Finance", label: "Pauls Auto Finance" },
  { value: "Pav Financial Services", label: "Pav Financial Services" },
  { value: "Pavagada Sahakara Credit Co-Operative Ltd..", label: "Pavagada Sahakara Credit Co-Operative Ltd.." },
  { value: "Pavalam And Co", label: "Pavalam And Co" },
  { value: "Pavan Auto Finance", label: "Pavan Auto Finance" },
  { value: "Pavan Auto Finance Corporation", label: "Pavan Auto Finance Corporation" },
  { value: "Pavan Finance", label: "Pavan Finance" },
  { value: "Pavan Finance Corporation", label: "Pavan Finance Corporation" },
  { value: "Pavan Financial Services", label: "Pavan Financial Services" },
  { value: "Pavan Financiers", label: "Pavan Financiers" },
  { value: "Pavan Investments", label: "Pavan Investments" },
  { value: "Pavan Kawar", label: "Pavan Kawar" },
  { value: "Pavan Kumar", label: "Pavan Kumar" },
  { value: "Pavan Kumar Auto Finance", label: "Pavan Kumar Auto Finance" },
  { value: "Pavan Kumar Enterprises", label: "Pavan Kumar Enterprises" },
  { value: "Pavan Kumar Finance Corporation", label: "Pavan Kumar Finance Corporation" },
  { value: "Pavan Sai Auto Finance", label: "Pavan Sai Auto Finance" },
  { value: "Pavan Trade Finance Corporation", label: "Pavan Trade Finance Corporation" },
  { value: "Pavana Putra Financial Investments Ltd", label: "Pavana Putra Financial Investments Ltd" },
  { value: "Pavana Sah Bank Ltd", label: "Pavana Sah Bank Ltd" },
  { value: "Pavana Sahakari Bank Ltd.", label: "Pavana Sahakari Bank Ltd." },
  { value: "Pavani Auto Finance", label: "Pavani Auto Finance" },
  { value: "Pavansut Auto Finance", label: "Pavansut Auto Finance" },
  { value: "Pavilam & Co", label: "Pavilam & Co" },
  { value: "Pavilam & Co Finance", label: "Pavilam & Co Finance" },
  { value: "Pavin Chand", label: "Pavin Chand" },
  { value: "Pavnamai Nagar Sahakari Pat Sanstha Mar", label: "Pavnamai Nagar Sahakari Pat Sanstha Mar" },
  { value: "Pawamana Credit Co Op Society Ltd", label: "Pawamana Credit Co Op Society Ltd" },
  { value: "Pawan Associates", label: "Pawan Associates" },
  { value: "Pawan Associats Mahaver Nagar Firozabad", label: "Pawan Associats Mahaver Nagar Firozabad" },
  { value: "Pawan Automobiles Finance", label: "Pawan Automobiles Finance" },
  { value: "Pawan Co-Op Credit Soc Ltd", label: "Pawan Co-Op Credit Soc Ltd" },
  { value: "Pawan Finance Ltd.", label: "Pawan Finance Ltd." },
  { value: "Pawan Finlest Pvt Ltd", label: "Pawan Finlest Pvt Ltd" },
  { value: "Pawan Finvest Ltd.", label: "Pawan Finvest Ltd." },
  { value: "Pawan Hans Helicopters Ltd.", label: "Pawan Hans Helicopters Ltd." },
  { value: "Pawan Investment", label: "Pawan Investment" },
  { value: "Pawan Kawar", label: "Pawan Kawar" },
  { value: "Pawan Kumar Mehta", label: "Pawan Kumar Mehta" },
  { value: "Pawan Kumar Mehta And Sons", label: "Pawan Kumar Mehta And Sons" },
  { value: "Pawan Putra Group", label: "Pawan Putra Group" },
  { value: "Pawana Saha Bank Ltd", label: "Pawana Saha Bank Ltd" },
  { value: "Pawna Shakari Bank Ltd", label: "Pawna Shakari Bank Ltd" },
  { value: "Payal Financer Service Ltd", label: "Payal Financer Service Ltd" },
  { value: "Payangadi Urban Co-Op Bank Ltd", label: "Payangadi Urban Co-Op Bank Ltd" },
  { value: "Payyanur Co-Optown Bank Ltd", label: "Payyanur Co-Optown Bank Ltd" },
  { value: "Payyavoor Service Co-Operative Bank Ltd.", label: "Payyavoor Service Co-Operative Bank Ltd." },
  { value: "Payyoli Urban Co-Op Bank Ltd", label: "Payyoli Urban Co-Op Bank Ltd" },
  { value: "Pazhambalacode Ser.Co-Op Bank Ltd,", label: "Pazhambalacode Ser.Co-Op Bank Ltd," },
  { value: "Pazhassi Services Cooperative Bank Ltd.", label: "Pazhassi Services Cooperative Bank Ltd." },
  { value: "Pazhavangadikara Services Co-Op Bank Ltd", label: "Pazhavangadikara Services Co-Op Bank Ltd" },
  { value: "Pazhaya Kunnummel Co-Operative Bank", label: "Pazhaya Kunnummel Co-Operative Bank" },
  { value: "Pazhayannur Farmers Service Co-Op Bank L", label: "Pazhayannur Farmers Service Co-Op Bank L" },
  { value: "Pbg Leasing And Credit Ltd", label: "Pbg Leasing And Credit Ltd" },
  { value: "Pca & Rd Bank Limited", label: "Pca & Rd Bank Limited" },
  { value: "Pcard Bank", label: "Pcard Bank" },
  { value: "Pcaxrd Bank", label: "Pcaxrd Bank" },
  { value: "Pcbl", label: "Pcbl" },
  { value: "Pd Finance", label: "Pd Finance" },
  { value: "Pearl Met Arc White", label: "Pearl Met Arc White" },
  { value: "Pearls Hire Purch Com Ltd", label: "Pearls Hire Purch Com Ltd" },
  { value: "Pebco Finance", label: "Pebco Finance" },
  { value: "Pedne Taluka Farmers Service Co Op Society Ltd", label: "Pedne Taluka Farmers Service Co Op Society Ltd" },
  { value: "Peejay Finance Co Ltd", label: "Peejay Finance Co Ltd" },
  { value: "Peerumade Taluk Co-Op Agricultural And Rural Development Bank Ltd", label: "Peerumade Taluk Co-Op Agricultural And Rural Development Bank Ltd" },
  { value: "Pelican Insurance Brokers Pvt. Ltd", label: "Pelican Insurance Brokers Pvt. Ltd" },
  { value: "Pembroke Leasing And Finance", label: "Pembroke Leasing And Finance" },
  { value: "Pen Co Op Urban Bank Ltd", label: "Pen Co Op Urban Bank Ltd" },
  { value: "Pen Urban Co-Op Bank Ltd", label: "Pen Urban Co-Op Bank Ltd" },
  { value: "Penacia Finance Com", label: "Penacia Finance Com" },
  { value: "People Co-Operative Ban", label: "People Co-Operative Ban" },
  { value: "People Co-Operative Bank Arya Nagar Kanpur", label: "People Co-Operative Bank Arya Nagar Kanpur" },
  { value: "People Urban Co Operative Bank", label: "People Urban Co Operative Bank" },
  { value: "Peoples Urban Co-Op. Bank Ltd.", label: "Peoples Urban Co-Op. Bank Ltd." },
  { value: "Peoples Co-Op Bank L", label: "Peoples Co-Op Bank L" },
  { value: "Peoples Co-Op Bank Ltd.", label: "Peoples Co-Op Bank Ltd." },
  { value: "Peoples Cooperative Bank Ltd", label: "Peoples Cooperative Bank Ltd" },
  { value: "Peoples Services Coop Bank Ltd.", label: "Peoples Services Coop Bank Ltd." },
  { value: "Pepsons Leasing Finance Pvt Ltd", label: "Pepsons Leasing Finance Pvt Ltd" },
  { value: "Perady V.S.S.B.", label: "Perady V.S.S.B." },
  { value: "Peralasseri Service Co-Op Bank Ltd", label: "Peralasseri Service Co-Op Bank Ltd" },
  { value: "Perambra Co-Op. Urban Bank Ltd.", label: "Perambra Co-Op. Urban Bank Ltd." },
  { value: "Perfect Auto Finance", label: "Perfect Auto Finance" },
  { value: "Perfect Capital Services Ltd", label: "Perfect Capital Services Ltd" },
  { value: "Perfect Fin Sec. P. Ltd", label: "Perfect Fin Sec. P. Ltd" },
  { value: "Perfect Insurance Solutions", label: "Perfect Insurance Solutions" },
  { value: "Perfect Service Capital Ltd", label: "Perfect Service Capital Ltd" },
  { value: "Perfect Urban Co-Op Society Ltd", label: "Perfect Urban Co-Op Society Ltd" },
  { value: "Perfetti Van Melle India Pvt Ltd", label: "Perfetti Van Melle India Pvt Ltd" },
  { value: "Perils Hire Purchase Ltd", label: "Perils Hire Purchase Ltd" },
  { value: "Perinjanam Ser Co-Op Bank,", label: "Perinjanam Ser Co-Op Bank," },
  { value: "Perintalmanna Service Co-Op Bank Ltd", label: "Perintalmanna Service Co-Op Bank Ltd" },
  { value: "Perinthalmanna Rural Agricultural Bank", label: "Perinthalmanna Rural Agricultural Bank" },
  { value: "Perinthalmanna Urban Co-Op Bank Ltd.", label: "Perinthalmanna Urban Co-Op Bank Ltd." },
  { value: "Perinthalnam Co-Op Bank", label: "Perinthalnam Co-Op Bank" },
  { value: "Periyakulam Co-Op Urban Bank Ltd", label: "Periyakulam Co-Op Urban Bank Ltd" },
  { value: "Periyamampattu Primary Agri Cp Bank Ltd.", label: "Periyamampattu Primary Agri Cp Bank Ltd." },
  { value: "Periyapalayam Primary Co Ag Bk", label: "Periyapalayam Primary Co Ag Bk" },
  { value: "Periyaputheri Primary Agri Co-Op Bank", label: "Periyaputheri Primary Agri Co-Op Bank" },
  { value: "Periyavar Finance", label: "Periyavar Finance" },
  { value: "Peroorkada Service Co-Op Bank Ltd.", label: "Peroorkada Service Co-Op Bank Ltd." },
  { value: "Peroorkada Service Co-Op. Bank Ltd.", label: "Peroorkada Service Co-Op. Bank Ltd." },
  { value: "Pertech Associates", label: "Pertech Associates" },
  { value: "Perumbavoor Auto Finance", label: "Perumbavoor Auto Finance" },
  { value: "Perumpilly Co-Operative Society Ltd.", label: "Perumpilly Co-Operative Society Ltd." },
  { value: "Peruvai Vyavasaya Seva Sahakari Bank,", label: "Peruvai Vyavasaya Seva Sahakari Bank," },
  { value: "Peruvallur Service Co-Op Banl Ltd.", label: "Peruvallur Service Co-Op Banl Ltd." },
  { value: "Pesala Financial Services Ltd.", label: "Pesala Financial Services Ltd." },
  { value: "Petlad Commco-Op Bank Ltd", label: "Petlad Commco-Op Bank Ltd" },
  { value: "Petrofils Co-Operative Limited", label: "Petrofils Co-Operative Limited" },
  { value: "Pgb Kauri Ram Grp", label: "Pgb Kauri Ram Grp" },
  { value: "Pgb Mohaddipur Grk", label: "Pgb Mohaddipur Grk" },
  { value: "Phagwara Financers", label: "Phagwara Financers" },
  { value: "Phaltan Urban Co-Op Bank Ltd", label: "Phaltan Urban Co-Op Bank Ltd" },
  { value: "Phf Finance Ltd", label: "Phf Finance Ltd" },
  { value: "Phf Hire Purchase Pvt.Ltd", label: "Phf Hire Purchase Pvt.Ltd" },
  { value: "Phf Investment Pvt.Ltd", label: "Phf Investment Pvt.Ltd" },
  { value: "Phf Leasing Ltd", label: "Phf Leasing Ltd" },
  { value: "Phool Chan Nahar & Sons", label: "Phool Chan Nahar & Sons" },
  { value: "Phulambri Urban Co Op Bank Ltd", label: "Phulambri Urban Co Op Bank Ltd" },
  { value: "Phynix Business Group", label: "Phynix Business Group" },
  { value: "Physical Research Laboratory", label: "Physical Research Laboratory" },
  { value: "Piaggio Grlayes Vehicles Ltd.", label: "Piaggio Grlayes Vehicles Ltd." },
  { value: "Pij Peoples Co-Op Bank Ltd", label: "Pij Peoples Co-Op Bank Ltd" },
  { value: "Pilibhit Finance Ltd", label: "Pilibhit Finance Ltd" },
  { value: "Pillibhit Zila Sahkari Bank Ltd.,", label: "Pillibhit Zila Sahkari Bank Ltd.," },
  { value: "Pilmbar Finance Co", label: "Pilmbar Finance Co" },
  { value: "Pilot Auto Finance", label: "Pilot Auto Finance" },
  { value: "Pimpari Chinchwad Co-Op Bank Ltd", label: "Pimpari Chinchwad Co-Op Bank Ltd" },
  { value: "Pimpri Chinchwad Muncipal Coporation", label: "Pimpri Chinchwad Muncipal Coporation" },
  { value: "Pimpri Chinchwad Sahakari Bank Ltd.", label: "Pimpri Chinchwad Sahakari Bank Ltd." },
  { value: "Pimpri-Chinchwad Sahakari Bank Ltd", label: "Pimpri-Chinchwad Sahakari Bank Ltd" },
  { value: "Pinakapani Finance Corporation", label: "Pinakapani Finance Corporation" },
  { value: "Pincha Finance Corporation", label: "Pincha Finance Corporation" },
  { value: "Pindi Fin. Co", label: "Pindi Fin. Co" },
  { value: "Pinki Bajrang Agarwal", label: "Pinki Bajrang Agarwal" },
  { value: "Pinki Engineering Works", label: "Pinki Engineering Works" },
  { value: "Pinku Bagmar", label: "Pinku Bagmar" },
  { value: "Pinky & Co", label: "Pinky & Co" },
  { value: "Pinky Investment", label: "Pinky Investment" },
  { value: "Pinnacle Finance Ltd.", label: "Pinnacle Finance Ltd." },
  { value: "Pioneer Auto & Credit Pvt. Ltd.", label: "Pioneer Auto & Credit Pvt. Ltd." },
  { value: "Pioneer Financing Corporation", label: "Pioneer Financing Corporation" },
  { value: "Pioneer Investnent Co", label: "Pioneer Investnent Co" },
  { value: "Pioneer Overseas Finance Ltd.", label: "Pioneer Overseas Finance Ltd." },
  { value: "Pioneer Urban Co- Op Bank Ltd", label: "Pioneer Urban Co- Op Bank Ltd" },
  { value: "Pioneers Insurance Services", label: "Pioneers Insurance Services" },
  { value: "Pipal Gaon Merchants Co-Op Bank Ltd", label: "Pipal Gaon Merchants Co-Op Bank Ltd" },
  { value: "Pipra Ser Co-Op Bank L.", label: "Pipra Ser Co-Op Bank L." },
  { value: "Pirsai Gr. Big. Sheti Sah. Patsanstha Maryadit Kalwadi", label: "Pirsai Gr. Big. Sheti Sah. Patsanstha Maryadit Kalwadi" },
  { value: "Pisedev Co-Op Credit Society Ltd", label: "Pisedev Co-Op Credit Society Ltd" },
  { value: "Pitaliya Finance", label: "Pitaliya Finance" },
  { value: "Pitam Pura Leasing And Housing Finance Ltd.", label: "Pitam Pura Leasing And Housing Finance Ltd." },
  { value: "Pithoragarh Kshetriya Gramin Bank", label: "Pithoragarh Kshetriya Gramin Bank" },
  { value: "Pithoragarh Zila Sahakari Bank Ltd", label: "Pithoragarh Zila Sahakari Bank Ltd" },
  { value: "Piyush Enterprise", label: "Piyush Enterprise" },
  { value: "Piyush Enterprise, Surat", label: "Piyush Enterprise, Surat" },
  { value: "Piyush Enterprises", label: "Piyush Enterprises" },
  { value: "Pkf Finance", label: "Pkf Finance" },
  { value: "Pkf Finance Ltd", label: "Pkf Finance Ltd" },
  { value: "Pkf Securities Pvt Ltd.", label: "Pkf Securities Pvt Ltd." },
  { value: "Pkg Auto Finance", label: "Pkg Auto Finance" },
  { value: "Pl Fund Advisors Pvt Ltd", label: "Pl Fund Advisors Pvt Ltd" },
  { value: "Pla & R D Bank", label: "Pla & R D Bank" },
  { value: "Plan India Ltd", label: "Plan India Ltd" },
  { value: "Planning Commission", label: "Planning Commission" },
  { value: "Platinumone Wealth Advisors", label: "Platinumone Wealth Advisors" },
  { value: "Pld & Rd Bank", label: "Pld & Rd Bank" },
  { value: "Pldb", label: "Pldb" },
  { value: "Plus Finance Ltd", label: "Plus Finance Ltd" },
  { value: "Pmc Bank Ltd", label: "Pmc Bank Ltd" },
  { value: "Pml Financial Services (P) Ltd", label: "Pml Financial Services (P) Ltd" },
  { value: "Pms Credites", label: "Pms Credites" },
  { value: "Pms Credits", label: "Pms Credits" },
  { value: "Pms Finance", label: "Pms Finance" },
  { value: "Pms Finance", label: "Pms Finance" },
  { value: "Pmt District Central Co-Op Bank Ltd.", label: "Pmt District Central Co-Op Bank Ltd." },
  { value: "Pnb - Dugri Ludhiana", label: "Pnb - Dugri Ludhiana" },
  { value: "Pnb And Other Consortium Member Banks", label: "Pnb And Other Consortium Member Banks" },
  { value: "Pnb Capital Services Ltd.", label: "Pnb Capital Services Ltd." },
  { value: "Pnb Dugri Ludhiana", label: "Pnb Dugri Ludhiana" },
  { value: "Pnf Leasing & Finance Ltd.", label: "Pnf Leasing & Finance Ltd." },
  { value: "Pochampally Urban Co-Op Bank Ltd", label: "Pochampally Urban Co-Op Bank Ltd" },
  { value: "Pohegaon Gramin Bigar Sheti Sah Path Maryadit", label: "Pohegaon Gramin Bigar Sheti Sah Path Maryadit" },
  { value: "Pohegaon Gramin Bigr Sheti Sah Path Sanstha Br", label: "Pohegaon Gramin Bigr Sheti Sah Path Sanstha Br" },
  { value: "Pokharana Financiers", label: "Pokharana Financiers" },
  { value: "Pollibetta Vyavasaya Seva S B Niyamitha", label: "Pollibetta Vyavasaya Seva S B Niyamitha" },
  { value: "Pollution Control Board", label: "Pollution Control Board" },
  { value: "Polyspin Investments And Finance Co. Ltd.", label: "Polyspin Investments And Finance Co. Ltd." },
  { value: "Ponani Co-Op Urban Bank Ltd", label: "Ponani Co-Op Urban Bank Ltd" },
  { value: "Ponani Co-Op. Urban Bank Ltd", label: "Ponani Co-Op. Urban Bank Ltd" },
  { value: "Ponani Co-Op. Urban Bank Ltd.", label: "Ponani Co-Op. Urban Bank Ltd." },
  { value: "Ponani Service Co-Op Bank Ltd.", label: "Ponani Service Co-Op Bank Ltd." },
  { value: "Pondicherry Ashok Hotel Corpn. Ltd.", label: "Pondicherry Ashok Hotel Corpn. Ltd." },
  { value: "Pondicherry Central Co-Oprative Bank Ltd.", label: "Pondicherry Central Co-Oprative Bank Ltd." },
  { value: "Pondicherry Co-Op Central Land Development Bank Ltd", label: "Pondicherry Co-Op Central Land Development Bank Ltd" },
  { value: "Pondicherry Co-Operative Urban Bank Ltd", label: "Pondicherry Co-Operative Urban Bank Ltd" },
  { value: "Pondicherry Industrial Promotion Development And Investment Corporation Limited", label: "Pondicherry Industrial Promotion Development And Investment Corporation Limited" },
  { value: "Pondicherry Industrial Promotion Development And Investment Corporation Ltd", label: "Pondicherry Industrial Promotion Development And Investment Corporation Ltd" },
  { value: "Pondicherry Industrial Promotion, Development And Investment Corporation Ltd", label: "Pondicherry Industrial Promotion, Development And Investment Corporation Ltd" },
  { value: "Pondicherry Nidhi Ltd.", label: "Pondicherry Nidhi Ltd." },
  { value: "Pondicherry Urban Co-Op Bank Ltd", label: "Pondicherry Urban Co-Op Bank Ltd" },
  { value: "Pondichery Agro Service And Industries Corporation", label: "Pondichery Agro Service And Industries Corporation" },
  { value: "Pongalur Primary Agricultural Corporation", label: "Pongalur Primary Agricultural Corporation" },
  { value: "Ponkunnam Co-Op Bank", label: "Ponkunnam Co-Op Bank" },
  { value: "Ponmagal Finance", label: "Ponmagal Finance" },
  { value: "Ponmundam Co-Op Urban Bank", label: "Ponmundam Co-Op Urban Bank" },
  { value: "Ponnani Urban Co-Op Bank Ltd", label: "Ponnani Urban Co-Op Bank Ltd" },
  { value: "Ponnapet Town Co-Op Bank Ltd", label: "Ponnapet Town Co-Op Bank Ltd" },
  { value: "Ponni Auto Finance", label: "Ponni Auto Finance" },
  { value: "Pontiac Leasing Ltd", label: "Pontiac Leasing Ltd" },
  { value: "Ponuguti Kishan Rao", label: "Ponuguti Kishan Rao" },
  { value: "Pooja Auto Finance", label: "Pooja Auto Finance" },
  { value: "Pooja Chaudhary", label: "Pooja Chaudhary" },
  { value: "Pooja Finance", label: "Pooja Finance" },
  { value: "Pooja Finelease Ltd", label: "Pooja Finelease Ltd" },
  { value: "Pooja Investment", label: "Pooja Investment" },
  { value: "Pooja Investments", label: "Pooja Investments" },
  { value: "Pooja Sharna Basava Pattina Soudhar Sahakari Niyamitha  Maski", label: "Pooja Sharna Basava Pattina Soudhar Sahakari Niyamitha  Maski" },
  { value: "Poojitha Auto Finance", label: "Poojitha Auto Finance" },
  { value: "Poojya Shri Mahantappagalavar U.C.O.S.N", label: "Poojya Shri Mahantappagalavar U.C.O.S.N" },
  { value: "Poojya Shri Mohantappa Golavar Urban Co-Op Credit Society Ltd.", label: "Poojya Shri Mohantappa Golavar Urban Co-Op Credit Society Ltd." },
  { value: "Poomkudy Force", label: "Poomkudy Force" },
  { value: "Poona Contractor Co-Op Bank Ltd.", label: "Poona Contractor Co-Op Bank Ltd." },
  { value: "Poonam Finance", label: "Poonam Finance" },
  { value: "Poonam Rupesh Loddha", label: "Poonam Rupesh Loddha" },
  { value: "Poonithura Service Co-Operative Bank Ltd", label: "Poonithura Service Co-Operative Bank Ltd" },
  { value: "Poonjar East Urban Co-Op Bank Ltd", label: "Poonjar East Urban Co-Op Bank Ltd" },
  { value: "Poornavadi Nagarik Sahakari Bank Ltd.", label: "Poornavadi Nagarik Sahakari Bank Ltd." },
  { value: "Poornawadi Nagarik Sahakari Bank Ltd", label: "Poornawadi Nagarik Sahakari Bank Ltd" },
  { value: "Poothrikka Service Co. Op. Bank Ltd.", label: "Poothrikka Service Co. Op. Bank Ltd." },
  { value: "Poovani Primary Agrl. Co-Op Bank", label: "Poovani Primary Agrl. Co-Op Bank" },
  { value: "Poovarany Service Co.Operative Bank Ltd.", label: "Poovarany Service Co.Operative Bank Ltd." },
  { value: "Pooyappally Service Co-Op Bank Ltd", label: "Pooyappally Service Co-Op Bank Ltd" },
  { value: "Popular Auto Finance", label: "Popular Auto Finance" },
  { value: "Popular Car World Pvt Ltd", label: "Popular Car World Pvt Ltd" },
  { value: "Popular Finance", label: "Popular Finance" },
  { value: "Popular Mega Motors", label: "Popular Mega Motors" },
  { value: "Poral Financiers", label: "Poral Financiers" },
  { value: "Porbandar Commercial Co-Op.Bank Ltd.", label: "Porbandar Commercial Co-Op.Bank Ltd." },
  { value: "Porbandar Vibhagiya Nagrik Saha Bank Lt", label: "Porbandar Vibhagiya Nagrik Saha Bank Lt" },
  { value: "Porbandhar Vibhgiya Nagarik Sahakari Bank Ltd", label: "Porbandhar Vibhgiya Nagarik Sahakari Bank Ltd" },
  { value: "Port Trust", label: "Port Trust" },
  { value: "Post Graduate Institute Of Medical Education And Research", label: "Post Graduate Institute Of Medical Education And Research" },
  { value: "Postal And R M S Employees Co-Op Bank Ltd", label: "Postal And R M S Employees Co-Op Bank Ltd" },
  { value: "Pothanicad Farmers Co Operative Bank Ltd", label: "Pothanicad Farmers Co Operative Bank Ltd" },
  { value: "Pothanirad Farmers Co-Op. Bk.L.", label: "Pothanirad Farmers Co-Op. Bk.L." },
  { value: "Pothigaai And Co", label: "Pothigaai And Co" },
  { value: "Potters Cottege Industrial Co Op Society", label: "Potters Cottege Industrial Co Op Society" },
  { value: "Power Finance Corporation Ltd", label: "Power Finance Corporation Ltd" },
  { value: "Powerloom Development And Export Promotion Council", label: "Powerloom Development And Export Promotion Council" },
  { value: "Pra.Pathina Sahakari Bank Ltd", label: "Pra.Pathina Sahakari Bank Ltd" },
  { value: "Prabanjan Finance Ltd.", label: "Prabanjan Finance Ltd." },
  { value: "Prabha Paliwal", label: "Prabha Paliwal" },
  { value: "Prabha Paliwal", label: "Prabha Paliwal" },
  { value: "Prabhagh Motor Finance Co (P) Ltd.", label: "Prabhagh Motor Finance Co (P) Ltd." },
  { value: "Prabhat Malik", label: "Prabhat Malik" },
  { value: "Prabhdeep Finance Co Ltd.", label: "Prabhdeep Finance Co Ltd." },
  { value: "Prabhu Finance Corporation", label: "Prabhu Finance Corporation" },
  { value: "Prabhu Shanker Financiers", label: "Prabhu Shanker Financiers" },
  { value: "Pradeep Choradia Financier", label: "Pradeep Choradia Financier" },
  { value: "Pradeep Chordia", label: "Pradeep Chordia" },
  { value: "Pradeep Co Op Credit Ltd", label: "Pradeep Co Op Credit Ltd" },
  { value: "Pradeep Finance Co.", label: "Pradeep Finance Co." },
  { value: "Pradeep Kothari", label: "Pradeep Kothari" },
  { value: "Pradeep Kumar", label: "Pradeep Kumar" },
  { value: "Pradeep Kumar Dingurual Finance", label: "Pradeep Kumar Dingurual Finance" },
  { value: "Pradeep Kumar Financier", label: "Pradeep Kumar Financier" },
  { value: "Pradeep Kundanlal Wasan", label: "Pradeep Kundanlal Wasan" },
  { value: "Pradeep Motor Finance Corporation", label: "Pradeep Motor Finance Corporation" },
  { value: "Pradeep Motors", label: "Pradeep Motors" },
  { value: "Pradeepan K . N", label: "Pradeepan K . N" },
  { value: "Praduyt Prakash Baruah", label: "Praduyt Prakash Baruah" },
  { value: "Praficient Leasing And Finance(Ltd.)", label: "Praficient Leasing And Finance(Ltd.)" },
  { value: "Praful Auto Finance", label: "Praful Auto Finance" },
  { value: "Praful Investments", label: "Praful Investments" },
  { value: "Prafulla Chand Bandari", label: "Prafulla Chand Bandari" },
  { value: "Praga Tools Ltd.", label: "Praga Tools Ltd." },
  { value: "Pragathi Auto Finance", label: "Pragathi Auto Finance" },
  { value: "Pragathi Gramena Bank", label: "Pragathi Gramena Bank" },
  { value: "Pragathi Gramin Bank", label: "Pragathi Gramin Bank" },
  { value: "Pragathi Gramin Bank", label: "Pragathi Gramin Bank" },
  { value: "Pragathi Gramin Bank,", label: "Pragathi Gramin Bank," },
  { value: "Pragathi Gramina Bank Aralihalli", label: "Pragathi Gramina Bank Aralihalli" },
  { value: "Pragathi Womens Co Operative Thirift And Credit Society Ltd", label: "Pragathi Womens Co Operative Thirift And Credit Society Ltd" },
  { value: "Pragati  Enterprises", label: "Pragati  Enterprises" },
  { value: "Pragati Co-Op Bank Ltd.", label: "Pragati Co-Op Bank Ltd." },
  { value: "Pragati Co-Op-Cr Society Ltd", label: "Pragati Co-Op-Cr Society Ltd" },
  { value: "Pragati Finance Co.", label: "Pragati Finance Co." },
  { value: "Pragati Grameen Bank", label: "Pragati Grameen Bank" },
  { value: "Pragati Gramin Bank", label: "Pragati Gramin Bank" },
  { value: "Pragati Mahila Co-Op Bank Ltd", label: "Pragati Mahila Co-Op Bank Ltd" },
  { value: "Pragati Mahila Nag. Sah. Bank Mydt.", label: "Pragati Mahila Nag. Sah. Bank Mydt." },
  { value: "Pragati Mahila Nagrik Bank", label: "Pragati Mahila Nagrik Bank" },
  { value: "Pragati Mahila Nagrik Sahakari Bank Maryadit", label: "Pragati Mahila Nagrik Sahakari Bank Maryadit" },
  { value: "Pragati Nagari Sah.Pat Sanstha Ltd.", label: "Pragati Nagari Sah.Pat Sanstha Ltd." },
  { value: "Pragati Pattin Sahakari Bank Niyamit", label: "Pragati Pattin Sahakari Bank Niyamit" },
  { value: "Pragati Sahakari Bank Ltd.", label: "Pragati Sahakari Bank Ltd." },
  { value: "Pragati Sahakari Path Sanstha Maryadit", label: "Pragati Sahakari Path Sanstha Maryadit" },
  { value: "Pragati Urban Co-Op Bank Ltd,", label: "Pragati Urban Co-Op Bank Ltd," },
  { value: "Pragjyotish Gaonlya Bank Ltd.", label: "Pragjyotish Gaonlya Bank Ltd." },
  { value: "Pragjyotish Hire Purchase Co (I) Pvt Ltd", label: "Pragjyotish Hire Purchase Co (I) Pvt Ltd" },
  { value: "Pragya Finance Ltd", label: "Pragya Finance Ltd" },
  { value: "Pragya Motor Finance Co Ltd", label: "Pragya Motor Finance Co Ltd" },
  { value: "Prahlad Rai Chachan", label: "Prahlad Rai Chachan" },
  { value: "Prakasam Dist Sc Op Society", label: "Prakasam Dist Sc Op Society" },
  { value: "Prakasam District Central Co-Operative Bank Ltd.", label: "Prakasam District Central Co-Operative Bank Ltd." },
  { value: "Prakasapuram Co-Op Urban Bank L", label: "Prakasapuram Co-Op Urban Bank L" },
  { value: "Prakasapuram Urban Co-Op Bank Ltd", label: "Prakasapuram Urban Co-Op Bank Ltd" },
  { value: "Prakash Chand", label: "Prakash Chand" },
  { value: "Prakash Chand & Sons", label: "Prakash Chand & Sons" },
  { value: "Prakash Chand Chaijed", label: "Prakash Chand Chaijed" },
  { value: "Prakash Chand Chaijed", label: "Prakash Chand Chaijed" },
  { value: "Prakash Chand Golechha", label: "Prakash Chand Golechha" },
  { value: "Prakash Devi Baid", label: "Prakash Devi Baid" },
  { value: "Prakash Finance And Investments", label: "Prakash Finance And Investments" },
  { value: "Prakash Finance Corporation", label: "Prakash Finance Corporation" },
  { value: "Prakash Financial Credits", label: "Prakash Financial Credits" },
  { value: "Prakash Kothari", label: "Prakash Kothari" },
  { value: "Prakash Kumar", label: "Prakash Kumar" },
  { value: "Prakash Kumar & Sons", label: "Prakash Kumar & Sons" },
  { value: "Prakash Nagari Path Sanstha", label: "Prakash Nagari Path Sanstha" },
  { value: "Prakashaa Finance", label: "Prakashaa Finance" },
  { value: "Prakashbhai Keshavlal Shah", label: "Prakashbhai Keshavlal Shah" },
  { value: "Prakeshkumar Bantha", label: "Prakeshkumar Bantha" },
  { value: "Prakruthi Mahila Pattina Sahakara Sangha (N)", label: "Prakruthi Mahila Pattina Sahakara Sangha (N)" },
  { value: "Praksh Kumar", label: "Praksh Kumar" },
  { value: "Praksh Kumar", label: "Praksh Kumar" },
  { value: "Praman Allianz Technologies Pvt.Ltd", label: "Praman Allianz Technologies Pvt.Ltd" },
  { value: "Pramarthi Finance", label: "Pramarthi Finance" },
  { value: "Pramod Chaudhry", label: "Pramod Chaudhry" },
  { value: "Pramod Finance", label: "Pramod Finance" },
  { value: "Pramod K R", label: "Pramod K R" },
  { value: "Pramod Kumar A .P", label: "Pramod Kumar A .P" },
  { value: "Pramod Kumar Jain Huf", label: "Pramod Kumar Jain Huf" },
  { value: "Pramod Sharma", label: "Pramod Sharma" },
  { value: "Prana Arban Co Op Credit Soc Ltd", label: "Prana Arban Co Op Credit Soc Ltd" },
  { value: "Pranabananda Co-Op Bank Ltd", label: "Pranabananda Co-Op Bank Ltd" },
  { value: "Pranath Kumar", label: "Pranath Kumar" },
  { value: "Pranathi Financiers", label: "Pranathi Financiers" },
  { value: "Pranati Saxena", label: "Pranati Saxena" },
  { value: "Pranav Agencies", label: "Pranav Agencies" },
  { value: "Pranav Kumar Finance", label: "Pranav Kumar Finance" },
  { value: "Prantij Urban Co-Op Bank Ltd", label: "Prantij Urban Co-Op Bank Ltd" },
  { value: "Prarthmik Sahkari Bhumi Vikas Bank", label: "Prarthmik Sahkari Bhumi Vikas Bank" },
  { value: "Prasan Anchaliya", label: "Prasan Anchaliya" },
  { value: "Prasan Chand & Sons", label: "Prasan Chand & Sons" },
  { value: "Prasan Investments", label: "Prasan Investments" },
  { value: "Prasana Kumari Finance", label: "Prasana Kumari Finance" },
  { value: "Prasanath Finance Ltd", label: "Prasanath Finance Ltd" },
  { value: "Prasanmal Gautamchand Lalwani", label: "Prasanmal Gautamchand Lalwani" },
  { value: "Prasanna Anjaneya Auto Finance", label: "Prasanna Anjaneya Auto Finance" },
  { value: "Prasanna Laxmi Finance", label: "Prasanna Laxmi Finance" },
  { value: "Prasanna Nagari Sahakari Pat Sanstha Maryadit", label: "Prasanna Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Prasannanjaneya A/F", label: "Prasannanjaneya A/F" },
  { value: "Prasannanjaneya Auto  Finance", label: "Prasannanjaneya Auto  Finance" },
  { value: "Prasannanjaneya Finance", label: "Prasannanjaneya Finance" },
  { value: "Prasannchand Kankaria Huf", label: "Prasannchand Kankaria Huf" },
  { value: "Prasasta Co-Op Credit Society Ltd.", label: "Prasasta Co-Op Credit Society Ltd." },
  { value: "Prashant & Co", label: "Prashant & Co" },
  { value: "Prashant Chandrakant Bafna", label: "Prashant Chandrakant Bafna" },
  { value: "Prashant Co Sreenivas", label: "Prashant Co Sreenivas" },
  { value: "Prashant Credits", label: "Prashant Credits" },
  { value: "Prashant Finance", label: "Prashant Finance" },
  { value: "Prashant Finlease", label: "Prashant Finlease" },
  { value: "Prashant Investment", label: "Prashant Investment" },
  { value: "Prashant P Singhavi", label: "Prashant P Singhavi" },
  { value: "Prashant Syndicate", label: "Prashant Syndicate" },
  { value: "Prashant Syndicate Investment", label: "Prashant Syndicate Investment" },
  { value: "Prashwanath Finance", label: "Prashwanath Finance" },
  { value: "Pratamika Krushi Pattina Sahakari Bank Ltd.", label: "Pratamika Krushi Pattina Sahakari Bank Ltd." },
  { value: "Pratap Co-Op Bank Ltd", label: "Pratap Co-Op Bank Ltd" },
  { value: "Pratap Fincon Pvt Ltd", label: "Pratap Fincon Pvt Ltd" },
  { value: "Pratap Malhotra Company", label: "Pratap Malhotra Company" },
  { value: "Pratap Shree Finance Company Limited", label: "Pratap Shree Finance Company Limited" },
  { value: "Prateek Finance Corporation", label: "Prateek Finance Corporation" },
  { value: "Pratham Financiers", label: "Pratham Financiers" },
  { value: "Prathamik Krishi Sakha Sahyog Samiti Bank", label: "Prathamik Krishi Sakha Sahyog Samiti Bank" },
  { value: "Prathamik Shiksha K Sahakari Bank Ltd", label: "Prathamik Shiksha K Sahakari Bank Ltd" },
  { value: "Prathamik Shikshak Sah Pat Senstha", label: "Prathamik Shikshak Sah Pat Senstha" },
  { value: "Prathamik Shikshak Sahakari Bank Ltd", label: "Prathamik Shikshak Sahakari Bank Ltd" },
  { value: "Prathamika Krushi Patina Sahakara Bank Ltd", label: "Prathamika Krushi Patina Sahakara Bank Ltd" },
  { value: "Prathamika Krushi Pattina Sahakari Bkltd", label: "Prathamika Krushi Pattina Sahakari Bkltd" },
  { value: "Prathamika Krushi Pattina Sahakari Sangha", label: "Prathamika Krushi Pattina Sahakari Sangha" },
  { value: "Prathiba Auto Finance", label: "Prathiba Auto Finance" },
  { value: "Prathiba Housing Leasing Finance", label: "Prathiba Housing Leasing Finance" },
  { value: "Prathik Motors", label: "Prathik Motors" },
  { value: "Prathma Bank", label: "Prathma Bank" },
  { value: "Pratibha Auto Finance", label: "Pratibha Auto Finance" },
  { value: "Pratibha Financer Motor Ltd", label: "Pratibha Financer Motor Ltd" },
  { value: "Pratibha Mahila Sahakari Bank Ltd", label: "Pratibha Mahila Sahakari Bank Ltd" },
  { value: "Pratik Finance", label: "Pratik Finance" },
  { value: "Pratik Janata Sahakari Bank", label: "Pratik Janata Sahakari Bank" },
  { value: "Pratima Bank", label: "Pratima Bank" },
  { value: "Pratistruti Group And Associates", label: "Pratistruti Group And Associates" },
  { value: "Pratmik Krushi Pattin Sahakari Bank Niyamit", label: "Pratmik Krushi Pattin Sahakari Bank Niyamit" },
  { value: "Pravara Gramin Biger Sheti Sahkari Pat Sanstha", label: "Pravara Gramin Biger Sheti Sahkari Pat Sanstha" },
  { value: "Praveen Auto Fin (R)", label: "Praveen Auto Fin (R)" },
  { value: "Praveen Capital Pvt Ltd", label: "Praveen Capital Pvt Ltd" },
  { value: "Praveen Chand Bhandari", label: "Praveen Chand Bhandari" },
  { value: "Praveen Chordia", label: "Praveen Chordia" },
  { value: "Praveen Credit Souharda Sahakari Niyamitha", label: "Praveen Credit Souharda Sahakari Niyamitha" },
  { value: "Praveen Finance", label: "Praveen Finance" },
  { value: "Praveen Finance Lease", label: "Praveen Finance Lease" },
  { value: "Praveen Investments", label: "Praveen Investments" },
  { value: "Praveen Kumar Darda", label: "Praveen Kumar Darda" },
  { value: "Praveen Kumar Jain", label: "Praveen Kumar Jain" },
  { value: "Praveen Kumar Nahar", label: "Praveen Kumar Nahar" },
  { value: "Praveena Kothari Auto Finance", label: "Praveena Kothari Auto Finance" },
  { value: "Pravesh Credit And Securities Ltd", label: "Pravesh Credit And Securities Ltd" },
  { value: "Pravesh Finance", label: "Pravesh Finance" },
  { value: "Pravin Babulal Karnavat", label: "Pravin Babulal Karnavat" },
  { value: "Pravin Chand", label: "Pravin Chand" },
  { value: "Pravin Finance", label: "Pravin Finance" },
  { value: "Pravinaben Vinubhai Patel", label: "Pravinaben Vinubhai Patel" },
  { value: "Pravira Nagari Sah Pat Sanstha", label: "Pravira Nagari Sah Pat Sanstha" },
  { value: "Prayag Capital India Ltd", label: "Prayag Capital India Ltd" },
  { value: "Prayag Motor Finance Ltd", label: "Prayag Motor Finance Ltd" },
  { value: "Precions Investments", label: "Precions Investments" },
  { value: "Precipal Civil Aviation Training College", label: "Precipal Civil Aviation Training College" },
  { value: "Preet  Finance  Khanna -Ldh", label: "Preet  Finance  Khanna -Ldh" },
  { value: "Preet Motors", label: "Preet Motors" },
  { value: "Preeti Finance", label: "Preeti Finance" },
  { value: "Prem Auto Associates", label: "Prem Auto Associates" },
  { value: "Prem Auto Deal", label: "Prem Auto Deal" },
  { value: "Prem Auto Finance", label: "Prem Auto Finance" },
  { value: "Prem Automobile Finance", label: "Prem Automobile Finance" },
  { value: "Prem Chand", label: "Prem Chand" },
  { value: "Prem Chand Jain", label: "Prem Chand Jain" },
  { value: "Prem Chand Kothari Af", label: "Prem Chand Kothari Af" },
  { value: "Prem Chand Kothari Huf", label: "Prem Chand Kothari Huf" },
  { value: "Prem Chand Lakhanpal", label: "Prem Chand Lakhanpal" },
  { value: "Prem Chand Sethiya", label: "Prem Chand Sethiya" },
  { value: "Prem Chand Sinohyi & Sons", label: "Prem Chand Sinohyi & Sons" },
  { value: "Prem Enterprises", label: "Prem Enterprises" },
  { value: "Prem Finance", label: "Prem Finance" },
  { value: "Prem Finance Corp", label: "Prem Finance Corp" },
  { value: "Prem Finsec Pvt Ltd", label: "Prem Finsec Pvt Ltd" },
  { value: "Prem Hire Purchase Ltd.", label: "Prem Hire Purchase Ltd." },
  { value: "Prem Investment", label: "Prem Investment" },
  { value: "Prem Kanwar Chajjed", label: "Prem Kanwar Chajjed" },
  { value: "Prem Lata Kanwar", label: "Prem Lata Kanwar" },
  { value: "Prem Latha Ostwal", label: "Prem Latha Ostwal" },
  { value: "Prem Motor Hire Purchase", label: "Prem Motor Hire Purchase" },
  { value: "Prem Sai Finance", label: "Prem Sai Finance" },
  { value: "Prem Syndicate", label: "Prem Syndicate" },
  { value: "Premchand Nahar", label: "Premchand Nahar" },
  { value: "Premchand Singui&Sons", label: "Premchand Singui&Sons" },
  { value: "Premier Auto Leasing", label: "Premier Auto Leasing" },
  { value: "Premier Automobiles Employees Co-Op Bank Ltd", label: "Premier Automobiles Employees Co-Op Bank Ltd" },
  { value: "Premier Car Sales Ltd", label: "Premier Car Sales Ltd" },
  { value: "Premier Leasing", label: "Premier Leasing" },
  { value: "Premier Traders", label: "Premier Traders" },
  { value: "Premium Ins. Brokers", label: "Premium Ins. Brokers" },
  { value: "Premlatha Ostwal", label: "Premlatha Ostwal" },
  { value: "Premraj Jain Financier", label: "Premraj Jain Financier" },
  { value: "Prena Bank Shah Mydt Ltd", label: "Prena Bank Shah Mydt Ltd" },
  { value: "Prerana Co-Op. Bank Ltd", label: "Prerana Co-Op. Bank Ltd" },
  { value: "Prerana Sahakari Pat Ltd", label: "Prerana Sahakari Pat Ltd" },
  { value: "Prerna Co Op Bank Ltd", label: "Prerna Co Op Bank Ltd" },
  { value: "Prerna Gramin Bigar Sheti Sah Patsanstha Ltd", label: "Prerna Gramin Bigar Sheti Sah Patsanstha Ltd" },
  { value: "Prerna Sahakari Pat.Ltd", label: "Prerna Sahakari Pat.Ltd" },
  { value: "Prerna Urban Co-Op Credit Soc. Ltd.", label: "Prerna Urban Co-Op Credit Soc. Ltd." },
  { value: "Prescient Insurance Broking", label: "Prescient Insurance Broking" },
  { value: "President Of India", label: "President Of India" },
  { value: "President Of India & Bsnl", label: "President Of India & Bsnl" },
  { value: "Presidents Investments", label: "Presidents Investments" },
  { value: "Presindent Of India &Bsnl", label: "Presindent Of India &Bsnl" },
  { value: "Press Council Of India", label: "Press Council Of India" },
  { value: "Press Information Bureau", label: "Press Information Bureau" },
  { value: "Prestige Financial Services Ltd", label: "Prestige Financial Services Ltd" },
  { value: "Pricol Finance Ltd.", label: "Pricol Finance Ltd." },
  { value: "Prifin Investments Pvt Ltd.", label: "Prifin Investments Pvt Ltd." },
  { value: "Primary Agricultural Co-Op.Bank Ltd.", label: "Primary Agricultural Co-Op.Bank Ltd." },
  { value: "Primary Agricultural Co-Operative Bank Ltd", label: "Primary Agricultural Co-Operative Bank Ltd" },
  { value: "Primary Agricultural Development Bank Ltd", label: "Primary Agricultural Development Bank Ltd" },
  { value: "Primary Agriculture And Rural Development Bank", label: "Primary Agriculture And Rural Development Bank" },
  { value: "Primary Agro Co.Op.Society", label: "Primary Agro Co.Op.Society" },
  { value: "Primary Aricultural Credit Co-Operative Bank Ltd.", label: "Primary Aricultural Credit Co-Operative Bank Ltd." },
  { value: "Primary Co Operative Agriculture &Rural Development Bank Ltd Gulbarga", label: "Primary Co Operative Agriculture &Rural Development Bank Ltd Gulbarga" },
  { value: "Primary Co-Operative Agricultural & Rural Dept Bank", label: "Primary Co-Operative Agricultural & Rural Dept Bank" },
  { value: "Primary Co-Operative Agricultural & Rural Development Bank Ltd", label: "Primary Co-Operative Agricultural & Rural Development Bank Ltd" },
  { value: "Primary Co-Operative Bank", label: "Primary Co-Operative Bank" },
  { value: "Primary Krashi Pattin Sahakari", label: "Primary Krashi Pattin Sahakari" },
  { value: "Primary Land Development Bank Ltd", label: "Primary Land Development Bank Ltd" },
  { value: "Primary Teachers Co-Op Credit Bank Ltd", label: "Primary Teachers Co-Op Credit Bank Ltd" },
  { value: "Primasonic Systems P Ltd", label: "Primasonic Systems P Ltd" },
  { value: "Prime Co-Op Bank Ltd.", label: "Prime Co-Op Bank Ltd." },
  { value: "Prime Co-Operative Bank Ltd.", label: "Prime Co-Operative Bank Ltd." },
  { value: "Prime Co.Op. Bank", label: "Prime Co.Op. Bank" },
  { value: "Prime Finance Co.Ltd.", label: "Prime Finance Co.Ltd." },
  { value: "Prime Incorporated", label: "Prime Incorporated" },
  { value: "Primex Investments", label: "Primex Investments" },
  { value: "Prince Auto Deals", label: "Prince Auto Deals" },
  { value: "Prince Auto Financier", label: "Prince Auto Financier" },
  { value: "Prince Finance Ltd", label: "Prince Finance Ltd" },
  { value: "Prince Finc. & Investment", label: "Prince Finc. & Investment" },
  { value: "Prince Investments", label: "Prince Investments" },
  { value: "Prince Motor Finance Co Ltd.", label: "Prince Motor Finance Co Ltd." },
  { value: "Principal Civil Aviation Training College", label: "Principal Civil Aviation Training College" },
  { value: "Pritamsingh Bhatia", label: "Pritamsingh Bhatia" },
  { value: "Priti Co Op Credit Society Ltd", label: "Priti Co Op Credit Society Ltd" },
  { value: "Pritisangam Sahakari Bank Ltd", label: "Pritisangam Sahakari Bank Ltd" },
  { value: "Privilege Finance And Leasing Ltd.", label: "Privilege Finance And Leasing Ltd." },
  { value: "Priya & Co", label: "Priya & Co" },
  { value: "Priya Auto Finance", label: "Priya Auto Finance" },
  { value: "Priya Darshini Narhind C C S Ltd Belgaum", label: "Priya Darshini Narhind C C S Ltd Belgaum" },
  { value: "Priya Finance Corporation", label: "Priya Finance Corporation" },
  { value: "Priya Finance Services Pvt. Ltd.", label: "Priya Finance Services Pvt. Ltd." },
  { value: "Priya Investment", label: "Priya Investment" },
  { value: "Priya Kothari", label: "Priya Kothari" },
  { value: "Priyadarshani Gramin Bigarsheti Sah. Patsanstha Maryadit", label: "Priyadarshani Gramin Bigarsheti Sah. Patsanstha Maryadit" },
  { value: "Priyadarshani Mahila Co-Op Bank Ltd", label: "Priyadarshani Mahila Co-Op Bank Ltd" },
  { value: "Priyadarshani Mahila Nag.Sah.Bk Ltd", label: "Priyadarshani Mahila Nag.Sah.Bk Ltd" },
  { value: "Priyadarshani Mahila Nag.Sah.Bk.L.", label: "Priyadarshani Mahila Nag.Sah.Bk.L." },
  { value: "Priyadarshani Nagari Sah. Pathsanstha", label: "Priyadarshani Nagari Sah. Pathsanstha" },
  { value: "Priyadarshani Nagari Sah.Bk.L.", label: "Priyadarshani Nagari Sah.Bk.L." },
  { value: "Priyadarshani Urban Co-Operative Bank Lt", label: "Priyadarshani Urban Co-Operative Bank Lt" },
  { value: "Priyadarshini Co-Op. Bank", label: "Priyadarshini Co-Op. Bank" },
  { value: "Priyadarshini Finance Corporation", label: "Priyadarshini Finance Corporation" },
  { value: "Priyadarshini Mahila Nagar Patsanstha Sinnar", label: "Priyadarshini Mahila Nagar Patsanstha Sinnar" },
  { value: "Priyadarshini Mahila Nagari Sah Path Sanstha Maryadit", label: "Priyadarshini Mahila Nagari Sah Path Sanstha Maryadit" },
  { value: "Priyadarshini Urban Co-Op. Bank Ltd.", label: "Priyadarshini Urban Co-Op. Bank Ltd." },
  { value: "Priyadarsini Agricultu Market Federation", label: "Priyadarsini Agricultu Market Federation" },
  { value: "Priyal Finance Corp", label: "Priyal Finance Corp" },
  { value: "Priyam Finance", label: "Priyam Finance" },
  { value: "Priyam Finance", label: "Priyam Finance" },
  { value: "Priyam Marketing", label: "Priyam Marketing" },
  { value: "Priyanka Financiers", label: "Priyanka Financiers" },
  { value: "Priyanka Investments", label: "Priyanka Investments" },
  { value: "Priyanka Jain", label: "Priyanka Jain" },
  { value: "Priysdsrdhni Mahila Sahakari Bk Ltd.", label: "Priysdsrdhni Mahila Sahakari Bk Ltd." },
  { value: "Procter & Gamble Home Products Ltd.", label: "Procter & Gamble Home Products Ltd." },
  { value: "Procter & Gamble India Ltd", label: "Procter & Gamble India Ltd" },
  { value: "Procter And Gamble Hygiene Health Care", label: "Procter And Gamble Hygiene Health Care" },
  { value: "Professional Assistance For Development Action.", label: "Professional Assistance For Development Action." },
  { value: "Professional Investment And Securities Ltd.", label: "Professional Investment And Securities Ltd." },
  { value: "Proficient Leasing And Finance Ltd", label: "Proficient Leasing And Finance Ltd" },
  { value: "Profit Makers", label: "Profit Makers" },
  { value: "Progressive Co-Op Bank Ltd", label: "Progressive Co-Op Bank Ltd" },
  { value: "Progressive Co-Op. Bank Ltd.", label: "Progressive Co-Op. Bank Ltd." },
  { value: "Progressive Merc.Co-Op Bank Ltd.", label: "Progressive Merc.Co-Op Bank Ltd." },
  { value: "Progressive Mercantile Co-Op Bank Ltd.", label: "Progressive Mercantile Co-Op Bank Ltd." },
  { value: "Progressive Motors", label: "Progressive Motors" },
  { value: "Progressive Urban Co-Op, Bank Ltd.", label: "Progressive Urban Co-Op, Bank Ltd." },
  { value: "Project Officer", label: "Project Officer" },
  { value: "Projects And Developement India Ltd.", label: "Projects And Developement India Ltd." },
  { value: "Prominent Profiles", label: "Prominent Profiles" },
  { value: "Promise Leasing And Finance Co", label: "Promise Leasing And Finance Co" },
  { value: "Prompt Finance Ltd.", label: "Prompt Finance Ltd." },
  { value: "Protect Insurance Services India", label: "Protect Insurance Services India" },
  { value: "Prudential Co-Op Urban Bank Ltd", label: "Prudential Co-Op Urban Bank Ltd" },
  { value: "Psp Finance", label: "Psp Finance" },
  { value: "Pss Leasing And Finance Pvt Ltd", label: "Pss Leasing And Finance Pvt Ltd" },
  { value: "Pu Acharya Anandrisi Nagari Saha Pathsnstha", label: "Pu Acharya Anandrisi Nagari Saha Pathsnstha" },
  { value: "Pubilc Finance & Co", label: "Pubilc Finance & Co" },
  { value: "Pubilc Finance & Co.", label: "Pubilc Finance & Co." },
  { value: "Public Finance", label: "Public Finance" },
  { value: "Public Motor General Finance Company", label: "Public Motor General Finance Company" },
  { value: "Public Motor General Finance Ltd", label: "Public Motor General Finance Ltd" },
  { value: "Pudukad Finance Company", label: "Pudukad Finance Company" },
  { value: "Pudukkottai Dist Cen Co - Op Bank L", label: "Pudukkottai Dist Cen Co - Op Bank L" },
  { value: "Pudukottai District Central Co-Operative Bank Ltd.", label: "Pudukottai District Central Co-Operative Bank Ltd." },
  { value: "Pudupatty Primary Agricultural C.B.L.", label: "Pudupatty Primary Agricultural C.B.L." },
  { value: "Pujjya Sharanabasaveshwar Pattin Sowhard Sahakari Niymit Maski", label: "Pujjya Sharanabasaveshwar Pattin Sowhard Sahakari Niymit Maski" },
  { value: "Pukhraj Dharewa", label: "Pukhraj Dharewa" },
  { value: "Pukhraj Surana & Sons (Huf)", label: "Pukhraj Surana & Sons (Huf)" },
  { value: "Pukkattupady Auto Financiers", label: "Pukkattupady Auto Financiers" },
  { value: "Pukraj Bagmar & Sons", label: "Pukraj Bagmar & Sons" },
  { value: "Puliangudi West Primary Agri Co-Op Bank", label: "Puliangudi West Primary Agri Co-Op Bank" },
  { value: "Pullukulangara Ser. Co-Op. Bank Ltd.", label: "Pullukulangara Ser. Co-Op. Bank Ltd." },
  { value: "Pullur Service Co-Op Bank.", label: "Pullur Service Co-Op Bank." },
  { value: "Punam Projects (P) Ltd.", label: "Punam Projects (P) Ltd." },
  { value: "Punamchand Chandmal & Co", label: "Punamchand Chandmal & Co" },
  { value: "Pune Cantonment Sahakari Bank Ltd.", label: "Pune Cantonment Sahakari Bank Ltd." },
  { value: "Pune District Central", label: "Pune District Central" },
  { value: "Pune District Central  Co-Op Bank Ltd.", label: "Pune District Central  Co-Op Bank Ltd." },
  { value: "Pune District Central Co-Op. Bank Ltd.", label: "Pune District Central Co-Op. Bank Ltd." },
  { value: "Pune District Central Co-Operative Bank Ltd.", label: "Pune District Central Co-Operative Bank Ltd." },
  { value: "Pune Head Post Office", label: "Pune Head Post Office" },
  { value: "Pune Heenganga Nsts Ltd", label: "Pune Heenganga Nsts Ltd" },
  { value: "Pune Janta Sahakari Bank Ltd", label: "Pune Janta Sahakari Bank Ltd" },
  { value: "Pune Merchants Co-Op Bank Ltd", label: "Pune Merchants Co-Op Bank Ltd" },
  { value: "Pune Merchants Co-Op. Bank Ltd", label: "Pune Merchants Co-Op. Bank Ltd" },
  { value: "Pune Muncipal Corpservants Urban Co-Op Bank Ltd", label: "Pune Muncipal Corpservants Urban Co-Op Bank Ltd" },
  { value: "Pune Municipal Corp. Servants C.U.B.L.", label: "Pune Municipal Corp. Servants C.U.B.L." },
  { value: "Pune Peoples Co-Op Bank Ltd.", label: "Pune Peoples Co-Op Bank Ltd." },
  { value: "Pune Peoples Co-Op Bank Ltd.", label: "Pune Peoples Co-Op Bank Ltd." },
  { value: "Pune Post And Tele Co Op Cre", label: "Pune Post And Tele Co Op Cre" },
  { value: "Pune Urban Co-Op Bank Ltd.", label: "Pune Urban Co-Op Bank Ltd." },
  { value: "Pune Urban Co-Operative Bank Ltd.", label: "Pune Urban Co-Operative Bank Ltd." },
  { value: "Pune Zilla Madhya. Sah. Bank Maryadit", label: "Pune Zilla Madhya. Sah. Bank Maryadit" },
  { value: "Pune Zilla Madhyawarti Sahk.Bk. Maryadit", label: "Pune Zilla Madhyawarti Sahk.Bk. Maryadit" },
  { value: "Puneet Finance Co.", label: "Puneet Finance Co." },
  { value: "Puneet Leasing & Fin. Co. Ltd", label: "Puneet Leasing & Fin. Co. Ltd" },
  { value: "Punia Finance Co Ltd", label: "Punia Finance Co Ltd" },
  { value: "Punit Enterprise", label: "Punit Enterprise" },
  { value: "Punit Trading Co", label: "Punit Trading Co" },
  { value: "Punithavathi Finance", label: "Punithavathi Finance" },
  { value: "Punjab & Maharashtra Co-Op Bank Ltd.", label: "Punjab & Maharashtra Co-Op Bank Ltd." },
  { value: "Punjab & Sind Bank", label: "Punjab & Sind Bank" },
  { value: "Punjab And Haryana High Court", label: "Punjab And Haryana High Court" },
  { value: "Punjab And Maharashtra Co-Op Bank Ltd., Mumbai", label: "Punjab And Maharashtra Co-Op Bank Ltd., Mumbai" },
  { value: "Punjab Automobiles", label: "Punjab Automobiles" },
  { value: "Punjab Automobiles India Private Ltd", label: "Punjab Automobiles India Private Ltd" },
  { value: "Punjab Co-Op Bank Ltd.", label: "Punjab Co-Op Bank Ltd." },
  { value: "Punjab Finance Co", label: "Punjab Finance Co" },
  { value: "Punjab Financers Regd.", label: "Punjab Financers Regd." },
  { value: "Punjab Financial Corporation", label: "Punjab Financial Corporation" },
  { value: "Punjab Gramin Bank,", label: "Punjab Gramin Bank," },
  { value: "Punjab Haryana Auto Finance Pvt. Ltd", label: "Punjab Haryana Auto Finance Pvt. Ltd" },
  { value: "Punjab Kashmir Finance Pvt. Ltd", label: "Punjab Kashmir Finance Pvt. Ltd" },
  { value: "Punjab Leasing Pvt. Ltd", label: "Punjab Leasing Pvt. Ltd" },
  { value: "Punjab Motors Finance Co Ltd.", label: "Punjab Motors Finance Co Ltd." },
  { value: "Punjab National Bank ,Ferozpur City (Pb)", label: "Punjab National Bank ,Ferozpur City (Pb)" },
  { value: "Punjab National Bank,Nangal Bihalan Pb", label: "Punjab National Bank,Nangal Bihalan Pb" },
  { value: "Punjab National Bank,Sheikhpur", label: "Punjab National Bank,Sheikhpur" },
  { value: "Punjab Reliable Investments Pvt. Ltd", label: "Punjab Reliable Investments Pvt. Ltd" },
  { value: "Punjab Sind Bank", label: "Punjab Sind Bank" },
  { value: "Punjab State Agricultural Marketing Board", label: "Punjab State Agricultural Marketing Board" },
  { value: "Punjab State Central Co Op Bank Ltd", label: "Punjab State Central Co Op Bank Ltd" },
  { value: "Punjab State Co-Operative Milk Producers` Federation Limited", label: "Punjab State Co-Operative Milk Producers` Federation Limited" },
  { value: "Punjab State Co.Op.Bank Ltd.", label: "Punjab State Co.Op.Bank Ltd." },
  { value: "Punjab State Electricity Board", label: "Punjab State Electricity Board" },
  { value: "Punjab State Industrial Developmnet Corporation", label: "Punjab State Industrial Developmnet Corporation" },
  { value: "Punjab Tourism Development Corporation", label: "Punjab Tourism Development Corporation" },
  { value: "Punjab Urban Planning And Development Authority", label: "Punjab Urban Planning And Development Authority" },
  { value: "Punjabi Bagh Motors", label: "Punjabi Bagh Motors" },
  { value: "Punmia Finance", label: "Punmia Finance" },
  { value: "Punnapara Service Co-Op Bank Ltd.", label: "Punnapara Service Co-Op Bank Ltd." },
  { value: "Punnayur Panchayath Ser.Co-Op.Bk.L.", label: "Punnayur Panchayath Ser.Co-Op.Bk.L." },
  { value: "Punya Associates", label: "Punya Associates" },
  { value: "Punyahlik Ahilabai Holkar Nagari Sahkari Path Sanstha", label: "Punyahlik Ahilabai Holkar Nagari Sahkari Path Sanstha" },
  { value: "Punyanagari Nagari Sah Bank Ltd", label: "Punyanagari Nagari Sah Bank Ltd" },
  { value: "Punyashlok Ahilyadevi Holkar Nag Sah Path Sansth  Mar Dandwa", label: "Punyashlok Ahilyadevi Holkar Nag Sah Path Sansth  Mar Dandwa" },
  { value: "Purandar Nagari Sahakari Pat San Maryadit", label: "Purandar Nagari Sahakari Pat San Maryadit" },
  { value: "Purasawalkam Co-Operative Bank Ltd.", label: "Purasawalkam Co-Operative Bank Ltd." },
  { value: "Purba-Dwarakapur Sebayan Sangha", label: "Purba-Dwarakapur Sebayan Sangha" },
  { value: "Purbanchal Bank Ltd.", label: "Purbanchal Bank Ltd." },
  { value: "Purbanchal Financer Co", label: "Purbanchal Financer Co" },
  { value: "Puri Urban Co-Op Bank Ltd", label: "Puri Urban Co-Op Bank Ltd" },
  { value: "Puri Urban Co-Op Bank Ltd", label: "Puri Urban Co-Op Bank Ltd" },
  { value: "Purna Gramin Non Agr Sahkari Pat Sanstha", label: "Purna Gramin Non Agr Sahkari Pat Sanstha" },
  { value: "Purna Nagarik Sahakari Bank Ltd", label: "Purna Nagarik Sahakari Bank Ltd" },
  { value: "Purna Nagri Sah. Bank Ltd", label: "Purna Nagri Sah. Bank Ltd" },
  { value: "Purnawadi Nagari Sahkari Pathsanstha", label: "Purnawadi Nagari Sahkari Pathsanstha" },
  { value: "Purnea District Central Coop Bank Ltd", label: "Purnea District Central Coop Bank Ltd" },
  { value: "Pursottam Miglani ( H.U.F)", label: "Pursottam Miglani ( H.U.F)" },
  { value: "Puruanchal Gramin Bank", label: "Puruanchal Gramin Bank" },
  { value: "Purulia Central Co-Op Bank Ltd", label: "Purulia Central Co-Op Bank Ltd" },
  { value: "Purulia District Central Co-Operative Bank Ltd.", label: "Purulia District Central Co-Operative Bank Ltd." },
  { value: "Purvanchal Finance Company", label: "Purvanchal Finance Company" },
  { value: "Purvanchal Gramin Bank,", label: "Purvanchal Gramin Bank," },
  { value: "Purvanchal Kshetriya Gramin Bank", label: "Purvanchal Kshetriya Gramin Bank" },
  { value: "Purvi Baroda Gramin Bank", label: "Purvi Baroda Gramin Bank" },
  { value: "Purvi Harshvardhan Dave", label: "Purvi Harshvardhan Dave" },
  { value: "Purvi U.P Kshtry Gramin Bank.", label: "Purvi U.P Kshtry Gramin Bank." },
  { value: "Pusad Urban Co-Op Bank Ltd", label: "Pusad Urban Co-Op Bank Ltd" },
  { value: "Pusad Urban Co-Op. Bank Ltd.", label: "Pusad Urban Co-Op. Bank Ltd." },
  { value: "Pushp Enterprise", label: "Pushp Enterprise" },
  { value: "Pushpa Auto Finance", label: "Pushpa Auto Finance" },
  { value: "Pushpa Dugar", label: "Pushpa Dugar" },
  { value: "Pushpa Finance", label: "Pushpa Finance" },
  { value: "Pushpa Investment", label: "Pushpa Investment" },
  { value: "Pushpa Kanwar Chhallani", label: "Pushpa Kanwar Chhallani" },
  { value: "Pushpa Kawar", label: "Pushpa Kawar" },
  { value: "Pushpa Kawer", label: "Pushpa Kawer" },
  { value: "Pushpa Kawer", label: "Pushpa Kawer" },
  { value: "Pushpa Kewar", label: "Pushpa Kewar" },
  { value: "Pushpak Investments", label: "Pushpak Investments" },
  { value: "Puspak Investments", label: "Puspak Investments" },
  { value: "Puthenchira Service Co- Op Bank Ltd", label: "Puthenchira Service Co- Op Bank Ltd" },
  { value: "Puthenvelikara Services Co-Op Bank Ltd", label: "Puthenvelikara Services Co-Op Bank Ltd" },
  { value: "Puthiyangadi Ser Co-Op B L,Puthiyangadi", label: "Puthiyangadi Ser Co-Op B L,Puthiyangadi" },
  { value: "Puttur Town Co-Op Bank Ltd", label: "Puttur Town Co-Op Bank Ltd" },
  { value: "Puvvada Malakondaiah A/F", label: "Puvvada Malakondaiah A/F" },
  { value: "Pyar Finance Ltd.", label: "Pyar Finance Ltd." },
  { value: "Pyarelal Financier", label: "Pyarelal Financier" },
  { value: "Pyrites, Phosphates And Chemicals Limited", label: "Pyrites, Phosphates And Chemicals Limited" },
  { value: "Q T Finance Pvt Ltd.", label: "Q T Finance Pvt Ltd." },
  { value: "R & R Company", label: "R & R Company" },
  { value: "R & T Finance Ltd", label: "R & T Finance Ltd" },
  { value: "R A  Ranka", label: "R A  Ranka" },
  { value: "R A Finance & Leasing Co ,Pvt.Ltd", label: "R A Finance & Leasing Co ,Pvt.Ltd" },
  { value: "R B Credits (P) Ltd.", label: "R B Credits (P) Ltd." },
  { value: "R B Finance", label: "R B Finance" },
  { value: "R B Finances.", label: "R B Finances." },
  { value: "R B Motors Finance", label: "R B Motors Finance" },
  { value: "R B Motors Finance", label: "R B Motors Finance" },
  { value: "R C Surana", label: "R C Surana" },
  { value: "R D Leasing And Fin Ltd", label: "R D Leasing And Fin Ltd" },
  { value: "R J Finance", label: "R J Finance" },
  { value: "R K Auto Consultation", label: "R K Auto Consultation" },
  { value: "R K Auto Finance", label: "R K Auto Finance" },
  { value: "R K Bohra", label: "R K Bohra" },
  { value: "R K D Financial Services", label: "R K D Financial Services" },
  { value: "R K Finance", label: "R K Finance" },
  { value: "R K Finance Company Ltd", label: "R K Finance Company Ltd" },
  { value: "R K G B", label: "R K G B" },
  { value: "R K Gramin Bigar Sheti Nagari Sahakari Patsanstha Maryadit", label: "R K Gramin Bigar Sheti Nagari Sahakari Patsanstha Maryadit" },
  { value: "R K Investment", label: "R K Investment" },
  { value: "R K Investments Auto Mobile Financers", label: "R K Investments Auto Mobile Financers" },
  { value: "R K Motor And General Finance", label: "R K Motor And General Finance" },
  { value: "R K Securities", label: "R K Securities" },
  { value: "R K Syndicate", label: "R K Syndicate" },
  { value: "R Krishna Veni Automobile Finance", label: "R Krishna Veni Automobile Finance" },
  { value: "R Krishna Veni Automobile Finance", label: "R Krishna Veni Automobile Finance" },
  { value: "R Lalitha Kothari", label: "R Lalitha Kothari" },
  { value: "R Latha", label: "R Latha" },
  { value: "R M & Co", label: "R M & Co" },
  { value: "R M Jain", label: "R M Jain" },
  { value: "R M Muthaiah", label: "R M Muthaiah" },
  { value: "R Madanlal Financier", label: "R Madanlal Financier" },
  { value: "R Mahendra Kumar", label: "R Mahendra Kumar" },
  { value: "R Manjudevi Bagmar", label: "R Manjudevi Bagmar" },
  { value: "R Manjuladevi", label: "R Manjuladevi" },
  { value: "R Manjuladevi Bagmar", label: "R Manjuladevi Bagmar" },
  { value: "R Maya Gadwani Automobile Finance", label: "R Maya Gadwani Automobile Finance" },
  { value: "R Navamani Corporation", label: "R Navamani Corporation" },
  { value: "R P Credit Corporation", label: "R P Credit Corporation" },
  { value: "R Padam Chand Bagmar Financier", label: "R Padam Chand Bagmar Financier" },
  { value: "R Padamchand Bagmar Financier", label: "R Padamchand Bagmar Financier" },
  { value: "R Padmabai Osthwal Financier", label: "R Padmabai Osthwal Financier" },
  { value: "R Pyarelal Financier", label: "R Pyarelal Financier" },
  { value: "R R Agrawal", label: "R R Agrawal" },
  { value: "R R Associates", label: "R R Associates" },
  { value: "R R B Kunaira", label: "R R B Kunaira" },
  { value: "R R Enterprises", label: "R R Enterprises" },
  { value: "R R Finance", label: "R R Finance" },
  { value: "R R Finance", label: "R R Finance" },
  { value: "R Ramarao", label: "R Ramarao" },
  { value: "R Ramesh Chand", label: "R Ramesh Chand" },
  { value: "R S Co-Op Bank Ltd", label: "R S Co-Op Bank Ltd" },
  { value: "R S Financiers", label: "R S Financiers" },
  { value: "R S Motors", label: "R S Motors" },
  { value: "R S Motors Finance", label: "R S Motors Finance" },
  { value: "R S Sharma Commercial Pvt Ltd", label: "R S Sharma Commercial Pvt Ltd" },
  { value: "R Sampath Kumar", label: "R Sampath Kumar" },
  { value: "R Saravanan", label: "R Saravanan" },
  { value: "R Saravanan Huf", label: "R Saravanan Huf" },
  { value: "R Uttamchand Bagmar", label: "R Uttamchand Bagmar" },
  { value: "R. B. Financiers", label: "R. B. Financiers" },
  { value: "R. Bhanwarlal Surana Automobile Finance", label: "R. Bhanwarlal Surana Automobile Finance" },
  { value: "R. D. & Co.", label: "R. D. & Co." },
  { value: "R. H. Finance Corporation", label: "R. H. Finance Corporation" },
  { value: "R. K .Stock Holding", label: "R. K .Stock Holding" },
  { value: "R. K Securites", label: "R. K Securites" },
  { value: "R. K. Investment/P. Sind Bank", label: "R. K. Investment/P. Sind Bank" },
  { value: "R. K. Investment/Punjab & Sind Bank", label: "R. K. Investment/Punjab & Sind Bank" },
  { value: "R. K. Moorthy Finance", label: "R. K. Moorthy Finance" },
  { value: "R. Latha Finance", label: "R. Latha Finance" },
  { value: "R. Manjuladevi", label: "R. Manjuladevi" },
  { value: "R. Pyarelal Financier", label: "R. Pyarelal Financier" },
  { value: "R. Roopadevi Chordia Finance", label: "R. Roopadevi Chordia Finance" },
  { value: "R. S. Fin.Cap", label: "R. S. Fin.Cap" },
  { value: "R.B. Motors Financer", label: "R.B. Motors Financer" },
  { value: "R.B.Auto Deals.", label: "R.B.Auto Deals." },
  { value: "R.B.Enterprises", label: "R.B.Enterprises" },
  { value: "R.B.Motor Finance", label: "R.B.Motor Finance" },
  { value: "R.C.C.B.B.O. (Meham)", label: "R.C.C.B.B.O. (Meham)" },
  { value: "R.D Finance", label: "R.D Finance" },
  { value: "R.D. Enterprise", label: "R.D. Enterprise" },
  { value: "R.D. Finance", label: "R.D. Finance" },
  { value: "R.D.C.C.", label: "R.D.C.C." },
  { value: "R.D.C.C. Bank", label: "R.D.C.C. Bank" },
  { value: "R.D.C.I.S Sail", label: "R.D.C.I.S Sail" },
  { value: "R.G Finance", label: "R.G Finance" },
  { value: "R.G Motors(P) Ltd", label: "R.G Motors(P) Ltd" },
  { value: "R.G.Trading Company", label: "R.G.Trading Company" },
  { value: "R.G.Trading Company", label: "R.G.Trading Company" },
  { value: "R.G.Trading Company", label: "R.G.Trading Company" },
  { value: "R.H Finance Corp. Ltd", label: "R.H Finance Corp. Ltd" },
  { value: "R.H.I.", label: "R.H.I." },
  { value: "R.K Enterprises", label: "R.K Enterprises" },
  { value: "R.K Finance", label: "R.K Finance" },
  { value: "R.K Udyog", label: "R.K Udyog" },
  { value: "R.K. Finance Company", label: "R.K. Finance Company" },
  { value: "R.K. Investment", label: "R.K. Investment" },
  { value: "R.K. Jain And Sons", label: "R.K. Jain And Sons" },
  { value: "R.K. Singhi (Huf)", label: "R.K. Singhi (Huf)" },
  { value: "R.L.B Urban Co-Oprative Bank", label: "R.L.B Urban Co-Oprative Bank" },
  { value: "R.L.B Urban Cooprative", label: "R.L.B Urban Cooprative" },
  { value: "R.L.B.Urban Co. Oprative Bank", label: "R.L.B.Urban Co. Oprative Bank" },
  { value: "R.L.T.L.B", label: "R.L.T.L.B" },
  { value: "R.Manjukumari", label: "R.Manjukumari" },
  { value: "R.R.T. Investments", label: "R.R.T. Investments" },
  { value: "R.Rajendar Kumar Betala Financier", label: "R.Rajendar Kumar Betala Financier" },
  { value: "R.S Financier", label: "R.S Financier" },
  { value: "R.S. Finance", label: "R.S. Finance" },
  { value: "R.S. Motors", label: "R.S. Motors" },
  { value: "R.S.S.N.", label: "R.S.S.N." },
  { value: "R.Saravanan", label: "R.Saravanan" },
  { value: "R.V.R Finance", label: "R.V.R Finance" },
  { value: "R.Venkatachalam", label: "R.Venkatachalam" },
  { value: "R1615 Kosalapurai Pacb Ltd", label: "R1615 Kosalapurai Pacb Ltd" },
  { value: "Raaj Finance Co", label: "Raaj Finance Co" },
  { value: "Raajalakshmi Finance", label: "Raajalakshmi Finance" },
  { value: "Raakesh Finance", label: "Raakesh Finance" },
  { value: "Raali Investment", label: "Raali Investment" },
  { value: "Raasi Finance And Investment Ltd.", label: "Raasi Finance And Investment Ltd." },
  { value: "Raasula Securities Ltd.", label: "Raasula Securities Ltd." },
  { value: "Rabakavi Urban Co-Op Bank Ltd", label: "Rabakavi Urban Co-Op Bank Ltd" },
  { value: "Rabindra Chhajar", label: "Rabindra Chhajar" },
  { value: "Rachana Finance Corporation", label: "Rachana Finance Corporation" },
  { value: "Rachecha Financiers", label: "Rachecha Financiers" },
  { value: "Rachit Fin. Services(P) Ltd.", label: "Rachit Fin. Services(P) Ltd." },
  { value: "Rachit Financial Pvt Ltd", label: "Rachit Financial Pvt Ltd" },
  { value: "Raddi Co-Op Credit Bank Ltd", label: "Raddi Co-Op Credit Bank Ltd" },
  { value: "Raddi Sahakari Bank", label: "Raddi Sahakari Bank" },
  { value: "Raddi Sahakari Bank Niyamit Dharwad", label: "Raddi Sahakari Bank Niyamit Dharwad" },
  { value: "Radey Radey Financer", label: "Radey Radey Financer" },
  { value: "Radha Finance", label: "Radha Finance" },
  { value: "Radha Krishna Financiers", label: "Radha Krishna Financiers" },
  { value: "Radha Madhav Automobiles Pvt Ltd", label: "Radha Madhav Automobiles Pvt Ltd" },
  { value: "Radhakrishna Nagari Sah Pat Mary,", label: "Radhakrishna Nagari Sah Pat Mary," },
  { value: "Radhani Auto Finance", label: "Radhani Auto Finance" },
  { value: "Radhasoami Urban Co-Op Bank Ltd", label: "Radhasoami Urban Co-Op Bank Ltd" },
  { value: "Radhaswami Nagri Sah Path San Ltd-Amravati", label: "Radhaswami Nagri Sah Path San Ltd-Amravati" },
  { value: "Radhe Gair Shetki Sah Pat Sanstha", label: "Radhe Gair Shetki Sah Pat Sanstha" },
  { value: "Radhe Krishna Auto Center", label: "Radhe Krishna Auto Center" },
  { value: "Radhe Krishna Enterprise", label: "Radhe Krishna Enterprise" },
  { value: "Radhe Motors", label: "Radhe Motors" },
  { value: "Radhe Shyam Agarwala(Huf)", label: "Radhe Shyam Agarwala(Huf)" },
  { value: "Radhika Motors Financer", label: "Radhika Motors Financer" },
  { value: "Radhika Shroff", label: "Radhika Shroff" },
  { value: "Radiant Finance Corporation", label: "Radiant Finance Corporation" },
  { value: "Raebareli Kshetriya Gramin Bank", label: "Raebareli Kshetriya Gramin Bank" },
  { value: "Raeshwar Pat Sanstha Maryadit", label: "Raeshwar Pat Sanstha Maryadit" },
  { value: "Ragava Finance", label: "Ragava Finance" },
  { value: "Ragavendra Auto Finance", label: "Ragavendra Auto Finance" },
  { value: "Raghav Enterprises", label: "Raghav Enterprises" },
  { value: "Raghav Finance Co", label: "Raghav Finance Co" },
  { value: "Raghav Hire Purchase And Leasing Co.", label: "Raghav Hire Purchase And Leasing Co." },
  { value: "Raghav Sharma  Hoshiarpur", label: "Raghav Sharma  Hoshiarpur" },
  { value: "Raghavendra Auto Finance Bhalki", label: "Raghavendra Auto Finance Bhalki" },
  { value: "Raghavendra Finance", label: "Raghavendra Finance" },
  { value: "Raghoji Finance & Auto Finance ( R )", label: "Raghoji Finance & Auto Finance ( R )" },
  { value: "Raghoji Finance & Leasing Manushree Complex, Gulbarga", label: "Raghoji Finance & Leasing Manushree Complex, Gulbarga" },
  { value: "Raghoji Finance And Leasing", label: "Raghoji Finance And Leasing" },
  { value: "Raghu Associates", label: "Raghu Associates" },
  { value: "Raghu Auto Finance", label: "Raghu Auto Finance" },
  { value: "Raghu Ram & Sons", label: "Raghu Ram & Sons" },
  { value: "Raghuji Finance", label: "Raghuji Finance" },
  { value: "Raghunandan Gramin Biger Sheti Sah Pat Sanstha Mar", label: "Raghunandan Gramin Biger Sheti Sah Pat Sanstha Mar" },
  { value: "Raghunath Hari Amrutkaar", label: "Raghunath Hari Amrutkaar" },
  { value: "Raghunath Motor", label: "Raghunath Motor" },
  { value: "Raghuram Finance", label: "Raghuram Finance" },
  { value: "Raghurama Financiers", label: "Raghurama Financiers" },
  { value: "Raghuvanshi Co-Op Bank Ltd", label: "Raghuvanshi Co-Op Bank Ltd" },
  { value: "Raghuvanshi Sarafi Sahkari Man", label: "Raghuvanshi Sarafi Sahkari Man" },
  { value: "Raghuvar Automolbils And Generalfinance Co. Ltd", label: "Raghuvar Automolbils And Generalfinance Co. Ltd" },
  { value: "Ragini", label: "Ragini" },
  { value: "Raguram Finance", label: "Raguram Finance" },
  { value: "Rahata Merchant Nagari Sah Pat Ltd", label: "Rahata Merchant Nagari Sah Pat Ltd" },
  { value: "Rahimatpur Sahakari Bank Ltd", label: "Rahimatpur Sahakari Bank Ltd" },
  { value: "Rahul & Company", label: "Rahul & Company" },
  { value: "Rahul Aggarwal", label: "Rahul Aggarwal" },
  { value: "Rahul Auto Financiers", label: "Rahul Auto Financiers" },
  { value: "Rahul Auto Leasing", label: "Rahul Auto Leasing" },
  { value: "Rahul Coop Cr Soc Ltd", label: "Rahul Coop Cr Soc Ltd" },
  { value: "Rahul Fin And Investment Co", label: "Rahul Fin And Investment Co" },
  { value: "Rahul Finance", label: "Rahul Finance" },
  { value: "Rahul Finance And Investmen", label: "Rahul Finance And Investmen" },
  { value: "Rahul Finance Co", label: "Rahul Finance Co" },
  { value: "Rahul Gramin Sah Path Sanstha Marya", label: "Rahul Gramin Sah Path Sanstha Marya" },
  { value: "Rahul Investment", label: "Rahul Investment" },
  { value: "Rahul Motors", label: "Rahul Motors" },
  { value: "Rahul Trading Co", label: "Rahul Trading Co" },
  { value: "Rahul Urban Co Op Credit Soc Ltd", label: "Rahul Urban Co Op Credit Soc Ltd" },
  { value: "Rahul V Mehta", label: "Rahul V Mehta" },
  { value: "Rahuram Finance", label: "Rahuram Finance" },
  { value: "Rahuri Peoples Co-Op Bank Ltd", label: "Rahuri Peoples Co-Op Bank Ltd" },
  { value: "Rahuri Tal-Doctors Ch Urban Co Op Rahuri", label: "Rahuri Tal-Doctors Ch Urban Co Op Rahuri" },
  { value: "Rahuri Taluka Doctors & Chemist Urban Cooprative", label: "Rahuri Taluka Doctors & Chemist Urban Cooprative" },
  { value: "Rai Finance", label: "Rai Finance" },
  { value: "Rai Motors", label: "Rai Motors" },
  { value: "Raibag Urban Co-Op Bank Ltd", label: "Raibag Urban Co-Op Bank Ltd" },
  { value: "Raibareli Kshetriya Gramin Bank", label: "Raibareli Kshetriya Gramin Bank" },
  { value: "Raichur City Urban Co-Op Bank Ltd", label: "Raichur City Urban Co-Op Bank Ltd" },
  { value: "Raichur City Urban Co-Op. Bank Ltd.", label: "Raichur City Urban Co-Op. Bank Ltd." },
  { value: "Raichur Dist Coop Central Bank Ltd", label: "Raichur Dist Coop Central Bank Ltd" },
  { value: "Raichur Distmahila Co-Op Bank Ltd", label: "Raichur Distmahila Co-Op Bank Ltd" },
  { value: "Raichur District Central Co-Operative Bank Ltd.", label: "Raichur District Central Co-Operative Bank Ltd." },
  { value: "Raichur District Co-Operative Central Bank Ltd.", label: "Raichur District Co-Operative Central Bank Ltd." },
  { value: "Raigad District Central Co-Operative Bank Ltd.", label: "Raigad District Central Co-Operative Bank Ltd." },
  { value: "Raigad Sahakari Bank Ltd.", label: "Raigad Sahakari Bank Ltd." },
  { value: "Raiganj Central Co-Operative Bank Ltd.", label: "Raiganj Central Co-Operative Bank Ltd." },
  { value: "Raiganj Co. Op. A.R.D. Bank Ltd.", label: "Raiganj Co. Op. A.R.D. Bank Ltd." },
  { value: "Raiganj District Central Co-Operative Bank Ltd.", label: "Raiganj District Central Co-Operative Bank Ltd." },
  { value: "Raigarh Nagarik Sahakari Bank Mydt.", label: "Raigarh Nagarik Sahakari Bank Mydt." },
  { value: "Raikesh Kotheri", label: "Raikesh Kotheri" },
  { value: "Rail India Technical And Economic Services Ltd.", label: "Rail India Technical And Economic Services Ltd." },
  { value: "Railway Co-Op Bank Ltd", label: "Railway Co-Op Bank Ltd" },
  { value: "Railway Emplyees Co-Op Bank Ltd", label: "Railway Emplyees Co-Op Bank Ltd" },
  { value: "Rainbow Auto Finance", label: "Rainbow Auto Finance" },
  { value: "Rais Ahmed Tyagi", label: "Rais Ahmed Tyagi" },
  { value: "Raithara Seva Sahakari Bank Ltd", label: "Raithara Seva Sahakari Bank Ltd" },
  { value: "Raithara Swa Sahaya Sangha Niyamitha", label: "Raithara Swa Sahaya Sangha Niyamitha" },
  { value: "Raithu Mithra Podupu Sangam", label: "Raithu Mithra Podupu Sangam" },
  { value: "Raj Auto Consultants", label: "Raj Auto Consultants" },
  { value: "Raj Auto Finance", label: "Raj Auto Finance" },
  { value: "Raj Auto Traders Pvt Ltd", label: "Raj Auto Traders Pvt Ltd" },
  { value: "Raj Baroda Gramin Bank Rawatbhata", label: "Raj Baroda Gramin Bank Rawatbhata" },
  { value: "Raj Enterprises", label: "Raj Enterprises" },
  { value: "Raj Finance Company", label: "Raj Finance Company" },
  { value: "Raj Finance Corporation", label: "Raj Finance Corporation" },
  { value: "Raj Hire Purchase Ltd.", label: "Raj Hire Purchase Ltd." },
  { value: "Raj Investment", label: "Raj Investment" },
  { value: "Raj Kamal Finance", label: "Raj Kamal Finance" },
  { value: "Raj Kamal Finance Automobiles", label: "Raj Kamal Finance Automobiles" },
  { value: "Raj Kanwar", label: "Raj Kanwar" },
  { value: "Raj Khatri Finance Limited", label: "Raj Khatri Finance Limited" },
  { value: "Raj Kiran Auto Hirf Purchase Co.", label: "Raj Kiran Auto Hirf Purchase Co." },
  { value: "Raj Kumar", label: "Raj Kumar" },
  { value: "Raj Kumar Verma", label: "Raj Kumar Verma" },
  { value: "Raj Laxmi Finance", label: "Raj Laxmi Finance" },
  { value: "Raj Motors", label: "Raj Motors" },
  { value: "Raj Rani", label: "Raj Rani" },
  { value: "Raj Singh & Co.", label: "Raj Singh & Co." },
  { value: "Raj Trading Co.", label: "Raj Trading Co." },
  { value: "Raj Trading Company", label: "Raj Trading Company" },
  { value: "Raj-Hans Finance", label: "Raj-Hans Finance" },
  { value: "Raj. Sc. St. Corp. Finance Ltd", label: "Raj. Sc. St. Corp. Finance Ltd" },
  { value: "Raj. Sc. St. Dev. Corporation Ltd D.R.D.A.", label: "Raj. Sc. St. Dev. Corporation Ltd D.R.D.A." },
  { value: "Raj. Sc/St Fin.& Development Corp.Ltd.", label: "Raj. Sc/St Fin.& Development Corp.Ltd." },
  { value: "Raja Auto Finance", label: "Raja Auto Finance" },
  { value: "Raja Auto Mobile Batala Road Mehta Chowk", label: "Raja Auto Mobile Batala Road Mehta Chowk" },
  { value: "Raja Auto Traders", label: "Raja Auto Traders" },
  { value: "Raja Finance", label: "Raja Finance" },
  { value: "Raja Gopal Finance", label: "Raja Gopal Finance" },
  { value: "Raja Investment", label: "Raja Investment" },
  { value: "Raja Rajeshwar Finance Corporation", label: "Raja Rajeshwar Finance Corporation" },
  { value: "Rajabal Bhosale Gra Big Sheti Sah Pat San", label: "Rajabal Bhosale Gra Big Sheti Sah Pat San" },
  { value: "Rajabhau Tungar Gramin Bigar Sheti Sah Patsanstha", label: "Rajabhau Tungar Gramin Bigar Sheti Sah Patsanstha" },
  { value: "Rajadhani Finance", label: "Rajadhani Finance" },
  { value: "Rajadhani Financial & Investment Corp", label: "Rajadhani Financial & Investment Corp" },
  { value: "Rajadhani Financial & Investment Corp", label: "Rajadhani Financial & Investment Corp" },
  { value: "Rajadhani Financial And Investment Corporation Ltd", label: "Rajadhani Financial And Investment Corporation Ltd" },
  { value: "Rajadhiraj Auto Finance Co Ltd", label: "Rajadhiraj Auto Finance Co Ltd" },
  { value: "Rajadhiraj Auto Finance Co. Pvt. Ltd.", label: "Rajadhiraj Auto Finance Co. Pvt. Ltd." },
  { value: "Rajadhiraj Auto Finance Co. Pvt. Ltd.", label: "Rajadhiraj Auto Finance Co. Pvt. Ltd." },
  { value: "Rajaguru Finance", label: "Rajaguru Finance" },
  { value: "Rajajinagar Co-Op Bank Ltd", label: "Rajajinagar Co-Op Bank Ltd" },
  { value: "Rajajinagar Co-Op Bank Ltd.", label: "Rajajinagar Co-Op Bank Ltd." },
  { value: "Rajakulam Primary Agricultural Co Bank L", label: "Rajakulam Primary Agricultural Co Bank L" },
  { value: "Rajakumary Services Coop Bank Ltd.", label: "Rajakumary Services Coop Bank Ltd." },
  { value: "Rajalakshmi Finance And Services Ltd.", label: "Rajalakshmi Finance And Services Ltd." },
  { value: "Rajalakshmi Investment", label: "Rajalakshmi Investment" },
  { value: "Rajalaxmi Finance", label: "Rajalaxmi Finance" },
  { value: "Rajam Finance", label: "Rajam Finance" },
  { value: "Rajam Finance", label: "Rajam Finance" },
  { value: "Rajammal Rangasamy Finance", label: "Rajammal Rangasamy Finance" },
  { value: "Rajammal Rangasamy Finance Ltd", label: "Rajammal Rangasamy Finance Ltd" },
  { value: "Rajan Auto Deals", label: "Rajan Auto Deals" },
  { value: "Rajapalayam Co-Op. Urban Bank Ltd.", label: "Rajapalayam Co-Op. Urban Bank Ltd." },
  { value: "Rajapur Sahakari Bank", label: "Rajapur Sahakari Bank" },
  { value: "Rajapur Urban Co-Op Bank Ltd", label: "Rajapur Urban Co-Op Bank Ltd" },
  { value: "Rajapur Urban Co-Op Bank Ltd,", label: "Rajapur Urban Co-Op Bank Ltd," },
  { value: "Rajapura Saraswath Credit Co-Op Society Ltd", label: "Rajapura Saraswath Credit Co-Op Society Ltd" },
  { value: "Rajapura Saraswatha Credit Co-Op Society Ltd Bailur", label: "Rajapura Saraswatha Credit Co-Op Society Ltd Bailur" },
  { value: "Rajarajeswari Finance", label: "Rajarajeswari Finance" },
  { value: "Rajaram Corporation", label: "Rajaram Corporation" },
  { value: "Rajarambabu Sahkari Bank Ltd", label: "Rajarambabu Sahkari Bank Ltd" },
  { value: "Rajareswari Finance", label: "Rajareswari Finance" },
  { value: "Rajarshee Sahu Co-Operative Credit Society", label: "Rajarshee Sahu Co-Operative Credit Society" },
  { value: "Rajarshee Shahu Co-Operative Credit Society Ltd.", label: "Rajarshee Shahu Co-Operative Credit Society Ltd." },
  { value: "Rajarshi Shahu Sahakari Bank Ltd.", label: "Rajarshi Shahu Sahakari Bank Ltd." },
  { value: "Rajarshi Shahu Sahakari Bank Maryadit, Pune", label: "Rajarshi Shahu Sahakari Bank Maryadit, Pune" },
  { value: "Rajashree  Shahu Co-Op Credit Society", label: "Rajashree  Shahu Co-Op Credit Society" },
  { value: "Rajashree Benefit Ltd", label: "Rajashree Benefit Ltd" },
  { value: "Rajashree Shahu Sah Bank Ltd", label: "Rajashree Shahu Sah Bank Ltd" },
  { value: "Rajastan Gramin Bank Ltd,", label: "Rajastan Gramin Bank Ltd," },
  { value: "Rajasthan Anusuchit Jati Jantati", label: "Rajasthan Anusuchit Jati Jantati" },
  { value: "Rajasthan Bank Ltd.", label: "Rajasthan Bank Ltd." },
  { value: "Rajasthan Drugs And Pharmaceuticals Ltd.", label: "Rajasthan Drugs And Pharmaceuticals Ltd." },
  { value: "Rajasthan Electronics And Instruments Ltd.", label: "Rajasthan Electronics And Instruments Ltd." },
  { value: "Rajasthan Financial Corporation", label: "Rajasthan Financial Corporation" },
  { value: "Rajasthan Leasing Pvt. Ltd.", label: "Rajasthan Leasing Pvt. Ltd." },
  { value: "Rajasthan Motor Finance Ltd", label: "Rajasthan Motor Finance Ltd" },
  { value: "Rajasthan Sc. St. Development Sehkari Corporation.", label: "Rajasthan Sc. St. Development Sehkari Corporation." },
  { value: "Rajasthan State Co-Op Bank Ltd.", label: "Rajasthan State Co-Op Bank Ltd." },
  { value: "Rajasthan State Indl. Co-Op Bank Ltd.", label: "Rajasthan State Indl. Co-Op Bank Ltd." },
  { value: "Rajasthan State Industrial And Investment Corporation", label: "Rajasthan State Industrial And Investment Corporation" },
  { value: "Rajasthan State Mines And Minerals Limited", label: "Rajasthan State Mines And Minerals Limited" },
  { value: "Rajasthan State Tourism Development Corporation Ltd.", label: "Rajasthan State Tourism Development Corporation Ltd." },
  { value: "Rajasthan Tractor", label: "Rajasthan Tractor" },
  { value: "Rajasthan Tractor Machinery", label: "Rajasthan Tractor Machinery" },
  { value: "Rajat Fin Cap (P) Ltd", label: "Rajat Fin Cap (P) Ltd" },
  { value: "Rajat Leasing & Finance", label: "Rajat Leasing & Finance" },
  { value: "Rajath Finance Ltd.", label: "Rajath Finance Ltd." },
  { value: "Rajawade Mandal Peoples Co-Op Bank Ltd", label: "Rajawade Mandal Peoples Co-Op Bank Ltd" },
  { value: "Rajchand Chopra", label: "Rajchand Chopra" },
  { value: "Rajdeep Co-Op Credit Soc. Ltd.", label: "Rajdeep Co-Op Credit Soc. Ltd." },
  { value: "Rajdhani Co-Op Urban Bank Ltd.", label: "Rajdhani Co-Op Urban Bank Ltd." },
  { value: "Rajdhani Leasing And Industries Limited", label: "Rajdhani Leasing And Industries Limited" },
  { value: "Rajdhani Nagar Sahakari Bank Ltd., Lucknow", label: "Rajdhani Nagar Sahakari Bank Ltd., Lucknow" },
  { value: "Rajdhani Nagar Sahkari Bank Ltd.", label: "Rajdhani Nagar Sahkari Bank Ltd." },
  { value: "Rajdhani Tractors & Agencies", label: "Rajdhani Tractors & Agencies" },
  { value: "Rajdhiraj Auto Finance Pvt Ltd", label: "Rajdhiraj Auto Finance Pvt Ltd" },
  { value: "Raje Shivaji Gramin Bigar Sheti Sah Pat", label: "Raje Shivaji Gramin Bigar Sheti Sah Pat" },
  { value: "Raje Shivaji Gramin Bigar Sheti Sahakari Patsanstha", label: "Raje Shivaji Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Rajeet R Yadav", label: "Rajeet R Yadav" },
  { value: "Rajeev K", label: "Rajeev K" },
  { value: "Rajender Finance Corporation", label: "Rajender Finance Corporation" },
  { value: "Rajender Sandhu", label: "Rajender Sandhu" },
  { value: "Rajendra Finance", label: "Rajendra Finance" },
  { value: "Rajendra Finance Service Ltd.", label: "Rajendra Finance Service Ltd." },
  { value: "Rajendra Investment", label: "Rajendra Investment" },
  { value: "Rajendra Kumar Dharewa", label: "Rajendra Kumar Dharewa" },
  { value: "Rajendra Kumar Finance", label: "Rajendra Kumar Finance" },
  { value: "Rajendra Kumar Jain", label: "Rajendra Kumar Jain" },
  { value: "Rajendra Kumar Surendra Kumar Kolkata", label: "Rajendra Kumar Surendra Kumar Kolkata" },
  { value: "Rajendra Misha (Huf)", label: "Rajendra Misha (Huf)" },
  { value: "Rajendra Suri Nagarik Sahakari Bank Ltd", label: "Rajendra Suri Nagarik Sahakari Bank Ltd" },
  { value: "Rajendrakumar Bhanwarlal", label: "Rajendrakumar Bhanwarlal" },
  { value: "Rajershri Shahu Mahavir Sahakari Path Sanstha Ltd.", label: "Rajershri Shahu Mahavir Sahakari Path Sanstha Ltd." },
  { value: "Rajesh & Co.", label: "Rajesh & Co." },
  { value: "Rajesh & Co/Bank Of India", label: "Rajesh & Co/Bank Of India" },
  { value: "Rajesh Bohra Finance", label: "Rajesh Bohra Finance" },
  { value: "Rajesh Finance", label: "Rajesh Finance" },
  { value: "Rajesh Finance Corporation", label: "Rajesh Finance Corporation" },
  { value: "Rajesh Financier", label: "Rajesh Financier" },
  { value: "Rajesh Gundecha & Sons", label: "Rajesh Gundecha & Sons" },
  { value: "Rajesh Investment", label: "Rajesh Investment" },
  { value: "Rajesh Investments", label: "Rajesh Investments" },
  { value: "Rajesh Jagdish Prajapati", label: "Rajesh Jagdish Prajapati" },
  { value: "Rajesh Kumar (Lunawat)", label: "Rajesh Kumar (Lunawat)" },
  { value: "Rajesh Kumar Dubey", label: "Rajesh Kumar Dubey" },
  { value: "Rajesh Kumar Lodha", label: "Rajesh Kumar Lodha" },
  { value: "Rajesh Kumar Lunawat", label: "Rajesh Kumar Lunawat" },
  { value: "Rajesh Kumar Sharma", label: "Rajesh Kumar Sharma" },
  { value: "Rajesh Kumar Surana", label: "Rajesh Kumar Surana" },
  { value: "Rajesh Motor Finance", label: "Rajesh Motor Finance" },
  { value: "Rajesh Nahar. G", label: "Rajesh Nahar. G" },
  { value: "Rajesh Narula", label: "Rajesh Narula" },
  { value: "Rajesh Radheshyam Agrawal", label: "Rajesh Radheshyam Agrawal" },
  { value: "Rajesh Singhvi", label: "Rajesh Singhvi" },
  { value: "Rajesh Surana", label: "Rajesh Surana" },
  { value: "Rajeshkumar Bafna", label: "Rajeshkumar Bafna" },
  { value: "Rajeshree Shahu Sah Bank", label: "Rajeshree Shahu Sah Bank" },
  { value: "Rajeshri Shahu Gramin Sahakari Path Sanstha", label: "Rajeshri Shahu Gramin Sahakari Path Sanstha" },
  { value: "Rajeshwar Yuvak Vikas Sah.Bank.Ltd.", label: "Rajeshwar Yuvak Vikas Sah.Bank.Ltd." },
  { value: "Rajeshwar Yuvak Vikas Sahakari Bank Ltd", label: "Rajeshwar Yuvak Vikas Sahakari Bank Ltd" },
  { value: "Rajeshwari Gramin Bigar Sheti Sahakari Patasanstha Ltd (Pimparkhed)", label: "Rajeshwari Gramin Bigar Sheti Sahakari Patasanstha Ltd (Pimparkhed)" },
  { value: "Rajgad Co Op Cr Soc Ltd", label: "Rajgad Co Op Cr Soc Ltd" },
  { value: "Rajgarh Nagrik Sakh Sahakarita Mydy.", label: "Rajgarh Nagrik Sakh Sahakarita Mydy." },
  { value: "Rajgarh Sehore Kshetriya Gramina Bank", label: "Rajgarh Sehore Kshetriya Gramina Bank" },
  { value: "Rajgarhia Commercial Co.", label: "Rajgarhia Commercial Co." },
  { value: "Rajgarhia Financial Services (P) Ltd", label: "Rajgarhia Financial Services (P) Ltd" },
  { value: "Rajgaria Finance Co", label: "Rajgaria Finance Co" },
  { value: "Rajgharia Finance Ltd", label: "Rajgharia Finance Ltd" },
  { value: "Rajguru Urban Co-Op Credit Society Ltd.", label: "Rajguru Urban Co-Op Credit Society Ltd." },
  { value: "Rajgurunagar Sahakari Bank Ltd", label: "Rajgurunagar Sahakari Bank Ltd" },
  { value: "Rajgurunagar Sahakari Bank Ltd.", label: "Rajgurunagar Sahakari Bank Ltd." },
  { value: "Rajhans Gramin Big. Sheti Sah. Patsanstha", label: "Rajhans Gramin Big. Sheti Sah. Patsanstha" },
  { value: "Rajinder Enterprises", label: "Rajinder Enterprises" },
  { value: "Rajinder Kumar", label: "Rajinder Kumar" },
  { value: "Rajindra Hire Purchase And Leasing Co.Ltd.", label: "Rajindra Hire Purchase And Leasing Co.Ltd." },
  { value: "Rajiv Gandhi Nagari Sahkari Patsanstha Ltd", label: "Rajiv Gandhi Nagari Sahkari Patsanstha Ltd" },
  { value: "Rajiv Gandhi Patsanstha Ltd", label: "Rajiv Gandhi Patsanstha Ltd" },
  { value: "Rajiv Gandhi Sahakari Bank Ltd,", label: "Rajiv Gandhi Sahakari Bank Ltd," },
  { value: "Rajiv Gilani Financial Services Unlimited", label: "Rajiv Gilani Financial Services Unlimited" },
  { value: "Rajiv Jain", label: "Rajiv Jain" },
  { value: "Rajiv Kumar Kothari H U F", label: "Rajiv Kumar Kothari H U F" },
  { value: "Rajiv Yuva Shakthi Syndicate Bank", label: "Rajiv Yuva Shakthi Syndicate Bank" },
  { value: "Rajivgandhi Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit", label: "Rajivgandhi Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit" },
  { value: "Rajkot Commercial Co-Op. Bank Ltd.", label: "Rajkot Commercial Co-Op. Bank Ltd." },
  { value: "Rajkot District Central Co-Operative Bank Ltd.", label: "Rajkot District Central Co-Operative Bank Ltd." },
  { value: "Rajkot Mahila Nagarik Co Op.Bank Ltd", label: "Rajkot Mahila Nagarik Co Op.Bank Ltd" },
  { value: "Rajkot Mahila Nagrik Sahakari Bank Ltd.", label: "Rajkot Mahila Nagrik Sahakari Bank Ltd." },
  { value: "Rajkot Mahila Nagrik Sahakari Bank Ltd., Rajkot", label: "Rajkot Mahila Nagrik Sahakari Bank Ltd., Rajkot" },
  { value: "Rajkot Municipal Corporation", label: "Rajkot Municipal Corporation" },
  { value: "Rajkot Nagar Sahakari Bank", label: "Rajkot Nagar Sahakari Bank" },
  { value: "Rajkot Nagarik Sahakari Bank Limited", label: "Rajkot Nagarik Sahakari Bank Limited" },
  { value: "Rajkot Nagrik Sah. Bank Ltd.", label: "Rajkot Nagrik Sah. Bank Ltd." },
  { value: "Rajkot Peoples Co-Op.Bank Ltd.", label: "Rajkot Peoples Co-Op.Bank Ltd." },
  { value: "Rajkot Peoples Co-Op. Bank Ltd., Rajkot", label: "Rajkot Peoples Co-Op. Bank Ltd., Rajkot" },
  { value: "Rajkumar Bagmar Financiers", label: "Rajkumar Bagmar Financiers" },
  { value: "Rajlaxmi Co Op Credit Society Ltd", label: "Rajlaxmi Co Op Credit Society Ltd" },
  { value: "Rajlaxmi Co-Op Credit Society Ltd", label: "Rajlaxmi Co-Op Credit Society Ltd" },
  { value: "Rajlaxmi Nagari Sahakari Patsanstha", label: "Rajlaxmi Nagari Sahakari Patsanstha" },
  { value: "Rajlaxmi Nagri Sahkari Bank Ltd.", label: "Rajlaxmi Nagri Sahkari Bank Ltd." },
  { value: "Rajlaxmi Satish Lodha", label: "Rajlaxmi Satish Lodha" },
  { value: "Rajlaxmi Urban Co-Op Bank Ltd", label: "Rajlaxmi Urban Co-Op Bank Ltd" },
  { value: "Rajluxmi Financers", label: "Rajluxmi Financers" },
  { value: "Rajmata Jijau Mahila Nagari Patsanstha", label: "Rajmata Jijau Mahila Nagari Patsanstha" },
  { value: "Rajmata Urban Co-Operative Bank Ltd", label: "Rajmata Urban Co-Operative Bank Ltd" },
  { value: "Rajouri Hire Purchase And Finance (P)Ltd.", label: "Rajouri Hire Purchase And Finance (P)Ltd." },
  { value: "Rajpal Auto Finance", label: "Rajpal Auto Finance" },
  { value: "Rajpipla Nagarik Sahakari Bank Ltd", label: "Rajpipla Nagarik Sahakari Bank Ltd" },
  { value: "Rajprit Enterprises", label: "Rajprit Enterprises" },
  { value: "Rajpura  Saraswatha Credit Co-Op Society Ltd - Bailur.", label: "Rajpura  Saraswatha Credit Co-Op Society Ltd - Bailur." },
  { value: "Rajput Associates", label: "Rajput Associates" },
  { value: "Rajputna Mahila Urban Co-Op Bank Ltd", label: "Rajputna Mahila Urban Co-Op Bank Ltd" },
  { value: "Rajrishi Shahu Nagari Sahkari Pathsanstha", label: "Rajrishi Shahu Nagari Sahkari Pathsanstha" },
  { value: "Rajrishi Shahu Nagari Sahkari Pathsanstha", label: "Rajrishi Shahu Nagari Sahkari Pathsanstha" },
  { value: "Rajrshi Shahu Taluka Gramin Nagri Bigar Sheti", label: "Rajrshi Shahu Taluka Gramin Nagri Bigar Sheti" },
  { value: "Rajsamand Sahkari Bhumi Vikas Bank Ltd", label: "Rajsamand Sahkari Bhumi Vikas Bank Ltd" },
  { value: "Rajshree Enterprise", label: "Rajshree Enterprise" },
  { value: "Rajsthan Gramin Bank", label: "Rajsthan Gramin Bank" },
  { value: "Raju Finance", label: "Raju Finance" },
  { value: "Raju Machinary & Tractors", label: "Raju Machinary & Tractors" },
  { value: "Raju Verma", label: "Raju Verma" },
  { value: "Rajulal B Khengar", label: "Rajulal B Khengar" },
  { value: "Rajumachinarie Tractor", label: "Rajumachinarie Tractor" },
  { value: "Rajura Nagri Sahakari Pat Sanstha Maryadit", label: "Rajura Nagri Sahakari Pat Sanstha Maryadit" },
  { value: "Rakesh", label: "Rakesh" },
  { value: "Rakesh Auto Enterprise", label: "Rakesh Auto Enterprise" },
  { value: "Rakesh Auto Financiers", label: "Rakesh Auto Financiers" },
  { value: "Rakesh Credits Limited", label: "Rakesh Credits Limited" },
  { value: "Rakesh Enterprise", label: "Rakesh Enterprise" },
  { value: "Rakesh Finance", label: "Rakesh Finance" },
  { value: "Rakesh Finance Corp", label: "Rakesh Finance Corp" },
  { value: "Rakesh Kumar Goyal Finance", label: "Rakesh Kumar Goyal Finance" },
  { value: "Rakesh Kumar Surana", label: "Rakesh Kumar Surana" },
  { value: "Rakesh Kundan Finance Co", label: "Rakesh Kundan Finance Co" },
  { value: "Rakesh.V.B", label: "Rakesh.V.B" },
  { value: "Rakeshdevi S Ethiya", label: "Rakeshdevi S Ethiya" },
  { value: "Rakhi  Gupta", label: "Rakhi  Gupta" },
  { value: "Rakhwala Auto Associates", label: "Rakhwala Auto Associates" },
  { value: "Rakshak Nagari Sahakari Patsantsha Mary", label: "Rakshak Nagari Sahakari Patsantsha Mary" },
  { value: "Rakshit Motor And General Finance  Pvt. Ltd", label: "Rakshit Motor And General Finance  Pvt. Ltd" },
  { value: "Ram Finance", label: "Ram Finance" },
  { value: "Ram G Moryani", label: "Ram G Moryani" },
  { value: "Ram Investments", label: "Ram Investments" },
  { value: "Ram Lal Mehta", label: "Ram Lal Mehta" },
  { value: "Ram Mohan Financial Services Limited", label: "Ram Mohan Financial Services Limited" },
  { value: "Ram Nagar Udhaya Finance", label: "Ram Nagar Udhaya Finance" },
  { value: "Ram Nath And Co. Financiers (P)Ltd.", label: "Ram Nath And Co. Financiers (P)Ltd." },
  { value: "Ram Pajwani & Kasan Lal Pajwani", label: "Ram Pajwani & Kasan Lal Pajwani" },
  { value: "Ram Panjwani Krishan Lal Panjwani", label: "Ram Panjwani Krishan Lal Panjwani" },
  { value: "Ram Prakash Handa", label: "Ram Prakash Handa" },
  { value: "Ram Rahim Urban Credit Souhard Sahakari Niyamit", label: "Ram Rahim Urban Credit Souhard Sahakari Niyamit" },
  { value: "Ram Raj Traders", label: "Ram Raj Traders" },
  { value: "Ram Singh And Sons", label: "Ram Singh And Sons" },
  { value: "Ram Transport Company Ltd.", label: "Ram Transport Company Ltd." },
  { value: "Rama Auto Leasing And Finance Company Ltd", label: "Rama Auto Leasing And Finance Company Ltd" },
  { value: "Rama Finance Co. Ltd", label: "Rama Finance Co. Ltd" },
  { value: "Rama Krishna Credit", label: "Rama Krishna Credit" },
  { value: "Rama Krishna Swamy Finance", label: "Rama Krishna Swamy Finance" },
  { value: "Rama Motors Pvt. Ltd.", label: "Rama Motors Pvt. Ltd." },
  { value: "Rama Sai Auto Finance", label: "Rama Sai Auto Finance" },
  { value: "Rama Sai Finance", label: "Rama Sai Finance" },
  { value: "Rama Sai Finance & Auto Consultancy", label: "Rama Sai Finance & Auto Consultancy" },
  { value: "Rama Sai Finance & Consultancy", label: "Rama Sai Finance & Consultancy" },
  { value: "Ramachandran K", label: "Ramachandran K" },
  { value: "Ramadevi Auto Finance", label: "Ramadevi Auto Finance" },
  { value: "Ramah Nahar", label: "Ramah Nahar" },
  { value: "Ramakrishna Co-Operative Society", label: "Ramakrishna Co-Operative Society" },
  { value: "Ramakrishna Credit Co Op Bank Ltd", label: "Ramakrishna Credit Co Op Bank Ltd" },
  { value: "Ramakrishna Investments", label: "Ramakrishna Investments" },
  { value: "Ramakrishnan Finance", label: "Ramakrishnan Finance" },
  { value: "Ramakrishnapur Co-Operative Bank Ltd.", label: "Ramakrishnapur Co-Operative Bank Ltd." },
  { value: "Raman Finance Co", label: "Raman Finance Co" },
  { value: "Raman Jeet Singh Johar", label: "Raman Jeet Singh Johar" },
  { value: "Ramana Auto Finance", label: "Ramana Auto Finance" },
  { value: "Ramanagaram Urban Co-Op Bank Ltd.", label: "Ramanagaram Urban Co-Op Bank Ltd." },
  { value: "Ramanan Rajan", label: "Ramanan Rajan" },
  { value: "Ramandeep Financier Corporation", label: "Ramandeep Financier Corporation" },
  { value: "Ramani Finance", label: "Ramani Finance" },
  { value: "Ramanjaneya Auto Finance", label: "Ramanjaneya Auto Finance" },
  { value: "Ramanjaneya Finance Corporation ( R )", label: "Ramanjaneya Finance Corporation ( R )" },
  { value: "Ramanna Finance", label: "Ramanna Finance" },
  { value: "Ramanuj Leasing Ltd.", label: "Ramanuj Leasing Ltd." },
  { value: "Ramar Finance", label: "Ramar Finance" },
  { value: "Ramaraja Kshatriya Co-Op Cr. Society Ltd.", label: "Ramaraja Kshatriya Co-Op Cr. Society Ltd." },
  { value: "Ramayan Auto Financiers  (P) Ltd", label: "Ramayan Auto Financiers  (P) Ltd" },
  { value: "Rambabu Finance", label: "Rambabu Finance" },
  { value: "Rambhauji Lingade Nagri Sahkari Path Sanstha", label: "Rambhauji Lingade Nagri Sahkari Path Sanstha" },
  { value: "Ramchandra  Auto Finance", label: "Ramchandra  Auto Finance" },
  { value: "Ramchandraji Karnawat Gramin Biger Shethi Sakhari Pat", label: "Ramchandraji Karnawat Gramin Biger Shethi Sakhari Pat" },
  { value: "Ramco Global Finance Ltd", label: "Ramco Global Finance Ltd" },
  { value: "Ramdev Baba Motors", label: "Ramdev Baba Motors" },
  { value: "Ramdev Finance", label: "Ramdev Finance" },
  { value: "Ramdev Finance Corporation", label: "Ramdev Finance Corporation" },
  { value: "Ramesh & Co Finance", label: "Ramesh & Co Finance" },
  { value: "Ramesh Auto Finance", label: "Ramesh Auto Finance" },
  { value: "Ramesh Babu Sunkara Financier", label: "Ramesh Babu Sunkara Financier" },
  { value: "Ramesh Chand Finance", label: "Ramesh Chand Finance" },
  { value: "Ramesh Chand Surana", label: "Ramesh Chand Surana" },
  { value: "Ramesh Chand Surana Finance", label: "Ramesh Chand Surana Finance" },
  { value: "Ramesh Detani", label: "Ramesh Detani" },
  { value: "Ramesh H Jain", label: "Ramesh H Jain" },
  { value: "Ramesh Kumar", label: "Ramesh Kumar" },
  { value: "Ramesh Nahar", label: "Ramesh Nahar" },
  { value: "Rameshchand Nahar And Sons", label: "Rameshchand Nahar And Sons" },
  { value: "Rameshchand Pavanchand", label: "Rameshchand Pavanchand" },
  { value: "Rameshchand& Sons", label: "Rameshchand& Sons" },
  { value: "Rameshchandra & Sons", label: "Rameshchandra & Sons" },
  { value: "Rameshwar Co-Op. Bank Ltd", label: "Rameshwar Co-Op. Bank Ltd" },
  { value: "Rameshwar Financiers", label: "Rameshwar Financiers" },
  { value: "Rameshwar Nagari Sahakari Pathsantha Ltd", label: "Rameshwar Nagari Sahakari Pathsantha Ltd" },
  { value: "Rameshwar Prasad & Maneshwar Prasad", label: "Rameshwar Prasad & Maneshwar Prasad" },
  { value: "Rameswari Finance Corporation", label: "Rameswari Finance Corporation" },
  { value: "Rameswari Financiers", label: "Rameswari Financiers" },
  { value: "Ramgarhia Co-Op Bank Ltd", label: "Ramgarhia Co-Op Bank Ltd" },
  { value: "Ramgarhia Co-Operative Bank Ltd", label: "Ramgarhia Co-Operative Bank Ltd" },
  { value: "Ramir Finance And Leasing Co. Pvt. Ltd.", label: "Ramir Finance And Leasing Co. Pvt. Ltd." },
  { value: "Ramjanak Gupta", label: "Ramjanak Gupta" },
  { value: "Ramjanak Ramsunder Gupta", label: "Ramjanak Ramsunder Gupta" },
  { value: "Ramjeevan Finance", label: "Ramjeevan Finance" },
  { value: "Ramji Motors", label: "Ramji Motors" },
  { value: "Ramkrishna Hari Patasanstha Maryadit", label: "Ramkrishna Hari Patasanstha Maryadit" },
  { value: "Ramkrishnahari Gramin Bigarsheti Sah Path Sanstha Ltd", label: "Ramkrishnahari Gramin Bigarsheti Sah Path Sanstha Ltd" },
  { value: "Ramkrishnapur Co Operative Bank Ltd", label: "Ramkrishnapur Co Operative Bank Ltd" },
  { value: "Ramkrishnapuram Co-Op. Bank Ltd.", label: "Ramkrishnapuram Co-Op. Bank Ltd." },
  { value: "Ramlal Betala", label: "Ramlal Betala" },
  { value: "Ramling Gramin Bigar Sheti", label: "Ramling Gramin Bigar Sheti" },
  { value: "Ramling Nagari Sah Pat San", label: "Ramling Nagari Sah Pat San" },
  { value: "Ramnagar Co-Operative Credit Society Ltd", label: "Ramnagar Co-Operative Credit Society Ltd" },
  { value: "Ramnagaram Urban Co-Op Bank Ltd", label: "Ramnagaram Urban Co-Op Bank Ltd" },
  { value: "Ramnathpuram District Central Co-Operative Bank Ltd.", label: "Ramnathpuram District Central Co-Operative Bank Ltd." },
  { value: "Ramraiya Sahakari Bank Ltd", label: "Ramraiya Sahakari Bank Ltd" },
  { value: "Ramrajya Sahakari Bank", label: "Ramrajya Sahakari Bank" },
  { value: "Rams Enterprises", label: "Rams Enterprises" },
  { value: "Rana Assocites", label: "Rana Assocites" },
  { value: "Rana Finance Co.", label: "Rana Finance Co." },
  { value: "Ranaghat Peoples Bank Limited", label: "Ranaghat Peoples Bank Limited" },
  { value: "Ranbaxy", label: "Ranbaxy" },
  { value: "Ranbaxy Lab Ltd.", label: "Ranbaxy Lab Ltd." },
  { value: "Ranchi Finance Co Pvt Ltd .", label: "Ranchi Finance Co Pvt Ltd ." },
  { value: "Ranchi Kshetriya Gramin Bank", label: "Ranchi Kshetriya Gramin Bank" },
  { value: "Ranchi Kshetriya Gramin Bank", label: "Ranchi Kshetriya Gramin Bank" },
  { value: "Ranchit Financial Services Pvt.Ltd.", label: "Ranchit Financial Services Pvt.Ltd." },
  { value: "Randahwa Finance Co.", label: "Randahwa Finance Co." },
  { value: "Randhawa  Hire Purchase Fin Ltd.", label: "Randhawa  Hire Purchase Fin Ltd." },
  { value: "Randheja Commercial Co-Op Bank Ltd", label: "Randheja Commercial Co-Op Bank Ltd" },
  { value: "Randhir Finance Corporation", label: "Randhir Finance Corporation" },
  { value: "Rang Nagarik", label: "Rang Nagarik" },
  { value: "Rang Nagarik Sahkari Bank", label: "Rang Nagarik Sahkari Bank" },
  { value: "Ranga Auto Finance", label: "Ranga Auto Finance" },
  { value: "Ranganathapuram Pri.Agricultural C.P.B.L", label: "Ranganathapuram Pri.Agricultural C.P.B.L" },
  { value: "Rangiya Gramin Vikash Bank.", label: "Rangiya Gramin Vikash Bank." },
  { value: "Rangudyan Trading And Investment Co Ltd.", label: "Rangudyan Trading And Investment Co Ltd." },
  { value: "Rani Lakshmibai Urban Co - Op Bank Ltd", label: "Rani Lakshmibai Urban Co - Op Bank Ltd" },
  { value: "Rani Laxmi Bai Co Operative Bank", label: "Rani Laxmi Bai Co Operative Bank" },
  { value: "Rani Leasing Finance Ltd", label: "Rani Leasing Finance Ltd" },
  { value: "Ranichannamma Credit Co-Op.Society Ltd.", label: "Ranichannamma Credit Co-Op.Society Ltd." },
  { value: "Raniganj Co-Op Bank Ltd", label: "Raniganj Co-Op Bank Ltd" },
  { value: "Raniganj Co-Op. Bank Ltd", label: "Raniganj Co-Op. Bank Ltd" },
  { value: "Raningpar Seva Sahkari Mandli", label: "Raningpar Seva Sahkari Mandli" },
  { value: "Ranip Nagrik Sahakari Sharafi Mandali Ltd.", label: "Ranip Nagrik Sahakari Sharafi Mandali Ltd." },
  { value: "Ranipet Town Co-Op Bank Ltd", label: "Ranipet Town Co-Op Bank Ltd" },
  { value: "Ranjana Bansal", label: "Ranjana Bansal" },
  { value: "Ranjana Bhandari", label: "Ranjana Bhandari" },
  { value: "Ranjana S", label: "Ranjana S" },
  { value: "Ranjeeth  Finance", label: "Ranjeeth  Finance" },
  { value: "Ranjeeth Kumar Finance", label: "Ranjeeth Kumar Finance" },
  { value: "Ranjeeth Kumar Investments", label: "Ranjeeth Kumar Investments" },
  { value: "Ranjit Bros Taxi Services", label: "Ranjit Bros Taxi Services" },
  { value: "Ranjit Finance Corp. Regd.", label: "Ranjit Finance Corp. Regd." },
  { value: "Ranjit Kumar Dey", label: "Ranjit Kumar Dey" },
  { value: "Ranjitha Bohra Financier", label: "Ranjitha Bohra Financier" },
  { value: "Ranju Srivastava", label: "Ranju Srivastava" },
  { value: "Ranka Enterprises", label: "Ranka Enterprises" },
  { value: "Ranka Finance", label: "Ranka Finance" },
  { value: "Ranka Finance And Service Ltd", label: "Ranka Finance And Service Ltd" },
  { value: "Ranka Financiers", label: "Ranka Financiers" },
  { value: "Ranka Investments", label: "Ranka Investments" },
  { value: "Ranni General Finance And Hire Purchase(P) Ltd", label: "Ranni General Finance And Hire Purchase(P) Ltd" },
  { value: "Ranu Malik", label: "Ranu Malik" },
  { value: "Ranuj Nagarik Sahakari Bank Ltd", label: "Ranuj Nagarik Sahakari Bank Ltd" },
  { value: "Rany Leasing Finance", label: "Rany Leasing Finance" },
  { value: "Ranzor Leasing Fin. Com (P) Ltd", label: "Ranzor Leasing Fin. Com (P) Ltd" },
  { value: "Ranzunzar Co-Op Credit Society Ltd.", label: "Ranzunzar Co-Op Credit Society Ltd." },
  { value: "Ranzunzar Gramin  Bigar Sheti Sahakari Patsanstha Maryadit", label: "Ranzunzar Gramin  Bigar Sheti Sahakari Patsanstha Maryadit" },
  { value: "Raol Finance Co. Ltd", label: "Raol Finance Co. Ltd" },
  { value: "Raosaheb Deshmukh Patsanstha Maryadit", label: "Raosaheb Deshmukh Patsanstha Maryadit" },
  { value: "Raosheb Patwardhan Nagari Sahakari Pathsantha Maryadit", label: "Raosheb Patwardhan Nagari Sahakari Pathsantha Maryadit" },
  { value: "Raps Securities Pvt Ltd", label: "Raps Securities Pvt Ltd" },
  { value: "Rashi And Co", label: "Rashi And Co" },
  { value: "Rashi Tyres", label: "Rashi Tyres" },
  { value: "Rashmi Chugh 2000", label: "Rashmi Chugh 2000" },
  { value: "Rashtra Sant Acharya Anant Rushiji Maharaj Nagari Sah Pathsanstha Ltd", label: "Rashtra Sant Acharya Anant Rushiji Maharaj Nagari Sah Pathsanstha Ltd" },
  { value: "Rashtriya Chemicals And Fertilizers Limited", label: "Rashtriya Chemicals And Fertilizers Limited" },
  { value: "Rashtriya Ispat Nigam Ltd.", label: "Rashtriya Ispat Nigam Ltd." },
  { value: "Rashtriya Mahila Kosh", label: "Rashtriya Mahila Kosh" },
  { value: "Rashtsant Tukodoji Maharaj Urban Credit Co-Op Soc Ltd", label: "Rashtsant Tukodoji Maharaj Urban Credit Co-Op Soc Ltd" },
  { value: "Rasi Auto Finance Ltd", label: "Rasi Auto Finance Ltd" },
  { value: "Rasi Auto Finance Services.", label: "Rasi Auto Finance Services." },
  { value: "Rasi Co", label: "Rasi Co" },
  { value: "Rasi Finance", label: "Rasi Finance" },
  { value: "Rasipuram Urban Co-Op Bank Ltd", label: "Rasipuram Urban Co-Op Bank Ltd" },
  { value: "Rastriya Kishan Vikash Yojna", label: "Rastriya Kishan Vikash Yojna" },
  { value: "Rastriya Krishi Vikash Yojana", label: "Rastriya Krishi Vikash Yojana" },
  { value: "Ratalam Mandsaur Kshetriya Grameen Bank", label: "Ratalam Mandsaur Kshetriya Grameen Bank" },
  { value: "Ratan Auto Finance", label: "Ratan Auto Finance" },
  { value: "Ratan Chand Kothari Amf", label: "Ratan Chand Kothari Amf" },
  { value: "Ratan Corporation", label: "Ratan Corporation" },
  { value: "Ratan Devi Bokadia Indore", label: "Ratan Devi Bokadia Indore" },
  { value: "Ratan Finance", label: "Ratan Finance" },
  { value: "Ratan Finance Company", label: "Ratan Finance Company" },
  { value: "Ratan Jyoti Finance Co", label: "Ratan Jyoti Finance Co" },
  { value: "Ratan Mala Motor And Finance Ltd", label: "Ratan Mala Motor And Finance Ltd" },
  { value: "Ratan Motors", label: "Ratan Motors" },
  { value: "Ratan Shah Sah Bank Ltd", label: "Ratan Shah Sah Bank Ltd" },
  { value: "Ratan Shree Finance Corp", label: "Ratan Shree Finance Corp" },
  { value: "Ratanchand Shah Co -Op Bank Ltd.", label: "Ratanchand Shah Co -Op Bank Ltd." },
  { value: "Ratanchand Shah Sahkari Bank Ltd", label: "Ratanchand Shah Sahkari Bank Ltd" },
  { value: "Ratanchand Shah Urban Bank", label: "Ratanchand Shah Urban Bank" },
  { value: "Ratanchand Shahu Sahakari Bank Ltd", label: "Ratanchand Shahu Sahakari Bank Ltd" },
  { value: "Ratanchandra Shah Sah.Bank Ltd", label: "Ratanchandra Shah Sah.Bank Ltd" },
  { value: "Ratandal Mill", label: "Ratandal Mill" },
  { value: "Ratandeep Finance Corpn", label: "Ratandeep Finance Corpn" },
  { value: "Rathan & Sons", label: "Rathan & Sons" },
  { value: "Rathan Chand", label: "Rathan Chand" },
  { value: "Rathan Chand & Sons", label: "Rathan Chand & Sons" },
  { value: "Rathi Finance", label: "Rathi Finance" },
  { value: "Rathna Bankers", label: "Rathna Bankers" },
  { value: "Rathnashika Finance", label: "Rathnashika Finance" },
  { value: "Rathore & Sons", label: "Rathore & Sons" },
  { value: "Rathore & Sons", label: "Rathore & Sons" },
  { value: "Rathore Finance", label: "Rathore Finance" },
  { value: "Rathore Traders", label: "Rathore Traders" },
  { value: "Ratlam District Central Co-Op Bank Ltd", label: "Ratlam District Central Co-Op Bank Ltd" },
  { value: "Ratlam Urban Co-Op Bank Ltd", label: "Ratlam Urban Co-Op Bank Ltd" },
  { value: "Ratna Dungarwala", label: "Ratna Dungarwala" },
  { value: "Ratna Finance Corporation", label: "Ratna Finance Corporation" },
  { value: "Ratna Kuber", label: "Ratna Kuber" },
  { value: "Ratnachandra Shah Urban Co Op Bank Ltd", label: "Ratnachandra Shah Urban Co Op Bank Ltd" },
  { value: "Ratnadeep Urben Co-Op Cr.Soc.Ltd.", label: "Ratnadeep Urben Co-Op Cr.Soc.Ltd." },
  { value: "Ratnagiri Dist Central Co-Op Bank Ltd.", label: "Ratnagiri Dist Central Co-Op Bank Ltd." },
  { value: "Ratnagiri District Central Co-Operative Bank Ltd.", label: "Ratnagiri District Central Co-Operative Bank Ltd." },
  { value: "Ratnagiri Gas Powertech Ltd", label: "Ratnagiri Gas Powertech Ltd" },
  { value: "Ratnagiri Sindhudurg Gramin Bank", label: "Ratnagiri Sindhudurg Gramin Bank" },
  { value: "Ratnagiri Sindhudurga Gramin Bank Ltd", label: "Ratnagiri Sindhudurga Gramin Bank Ltd" },
  { value: "Ratnagiri Urban Co-Operative Bank Ltd.", label: "Ratnagiri Urban Co-Operative Bank Ltd." },
  { value: "Ratnakar Bank Ltd.", label: "Ratnakar Bank Ltd." },
  { value: "Ratnakar Gramin Bigar Sheti Sah. Patsanstha Maryadit", label: "Ratnakar Gramin Bigar Sheti Sah. Patsanstha Maryadit" },
  { value: "Ratnatray Gramin Bigar Sheti Sah. Patsanstha", label: "Ratnatray Gramin Bigar Sheti Sah. Patsanstha" },
  { value: "Rattak Finance Co", label: "Rattak Finance Co" },
  { value: "Rattak Finance Co.", label: "Rattak Finance Co." },
  { value: "Rattan Hire Purchase Pvt. Ltd", label: "Rattan Hire Purchase Pvt. Ltd" },
  { value: "Rattan Lal Garg", label: "Rattan Lal Garg" },
  { value: "Raungodlu Uyavasaya Seva Sahakara Bank N", label: "Raungodlu Uyavasaya Seva Sahakara Bank N" },
  { value: "Raver Peoples Co-Op Bank Ltd", label: "Raver Peoples Co-Op Bank Ltd" },
  { value: "Ravi & Co", label: "Ravi & Co" },
  { value: "Ravi Auto Finance Corporation", label: "Ravi Auto Finance Corporation" },
  { value: "Ravi Chandra Finance", label: "Ravi Chandra Finance" },
  { value: "Ravi Comm Urban Co-Op Bank Ltd", label: "Ravi Comm Urban Co-Op Bank Ltd" },
  { value: "Ravi Enterprise", label: "Ravi Enterprise" },
  { value: "Ravi Gupta", label: "Ravi Gupta" },
  { value: "Ravi Hire Purchase Co.", label: "Ravi Hire Purchase Co." },
  { value: "Ravi Hirepurchase", label: "Ravi Hirepurchase" },
  { value: "Ravi Kumar Jain", label: "Ravi Kumar Jain" },
  { value: "Ravikiran Urban Co-Op Bank Ltd", label: "Ravikiran Urban Co-Op Bank Ltd" },
  { value: "Ravikumar Finance", label: "Ravikumar Finance" },
  { value: "Ravinder Bagga Finance Co", label: "Ravinder Bagga Finance Co" },
  { value: "Ravinder Chajjed", label: "Ravinder Chajjed" },
  { value: "Ravindra Investments", label: "Ravindra Investments" },
  { value: "Ravindra Motors Finance", label: "Ravindra Motors Finance" },
  { value: "Ravindra Multi Purpose Co-Op Society Ltd - Kukkundoor", label: "Ravindra Multi Purpose Co-Op Society Ltd - Kukkundoor" },
  { value: "Ravisagar Auto Finance", label: "Ravisagar Auto Finance" },
  { value: "Ravish Finance", label: "Ravish Finance" },
  { value: "Ravivarpeth Co-Op Bank Ltd", label: "Ravivarpeth Co-Op Bank Ltd" },
  { value: "Raviwar Karanjya Siddhivinyak Nagari Sahkari Path Sanstha", label: "Raviwar Karanjya Siddhivinyak Nagari Sahkari Path Sanstha" },
  { value: "Raviwar Karanjya Siddhivinyak Nagari Sahkari Path Sanstha", label: "Raviwar Karanjya Siddhivinyak Nagari Sahkari Path Sanstha" },
  { value: "Rawal Investment", label: "Rawal Investment" },
  { value: "Rawalnath Nag Sah Pat Sanstha", label: "Rawalnath Nag Sah Pat Sanstha" },
  { value: "Rawat Impex", label: "Rawat Impex" },
  { value: "Rawatmal Bhikamchand Pagaria", label: "Rawatmal Bhikamchand Pagaria" },
  { value: "Ray Ray Finance Company", label: "Ray Ray Finance Company" },
  { value: "Rayal Motor Finance", label: "Rayal Motor Finance" },
  { value: "Rayalaseema Grameena Bank", label: "Rayalaseema Grameena Bank" },
  { value: "Rayalaseema Grameena Bank", label: "Rayalaseema Grameena Bank" },
  { value: "Rayat Sahakari Pathpethi Ltd.", label: "Rayat Sahakari Pathpethi Ltd." },
  { value: "Rayat Sevak Co-Op Bank Ltd", label: "Rayat Sevak Co-Op Bank Ltd" },
  { value: "Rayatbhushan Sharad Nagari Sah Pat Sanstha Mydt", label: "Rayatbhushan Sharad Nagari Sah Pat Sanstha Mydt" },
  { value: "Raychand Chopra & Sons", label: "Raychand Chopra & Sons" },
  { value: "Raymac Financiers", label: "Raymac Financiers" },
  { value: "Raytara Sahakari Bank Ltd.", label: "Raytara Sahakari Bank Ltd." },
  { value: "Razdhani Motor Finance Co Ltd", label: "Razdhani Motor Finance Co Ltd" },
  { value: "Rb Enterprise", label: "Rb Enterprise" },
  { value: "Rba Finance P. Ltd.", label: "Rba Finance P. Ltd." },
  { value: "Rbafinance Ltd", label: "Rbafinance Ltd" },
  { value: "Rbg Leasing And Credit Ltd", label: "Rbg Leasing And Credit Ltd" },
  { value: "Rbi", label: "Rbi" },
  { value: "Rbi Employees Co Operative Credit Bank", label: "Rbi Employees Co Operative Credit Bank" },
  { value: "Rbi India Ltd", label: "Rbi India Ltd" },
  { value: "Rc Finance", label: "Rc Finance" },
  { value: "Rcc Bank", label: "Rcc Bank" },
  { value: "Rck Leasing & Finance (P) Ltd", label: "Rck Leasing & Finance (P) Ltd" },
  { value: "Rcm Finance & Leasing Pvt Ltd", label: "Rcm Finance & Leasing Pvt Ltd" },
  { value: "Reach Out Leasing And Finance Pvt Ltd", label: "Reach Out Leasing And Finance Pvt Ltd" },
  { value: "Realnce Finance And Leasing", label: "Realnce Finance And Leasing" },
  { value: "Rebali Finance", label: "Rebali Finance" },
  { value: "Rec Ltd", label: "Rec Ltd" },
  { value: "Recter Investment Pvt Ltd", label: "Recter Investment Pvt Ltd" },
  { value: "Red Aai", label: "Red Aai" },
  { value: "Reddy Motors", label: "Reddy Motors" },
  { value: "Reddy Sahakari Niyamita Bank Ltd", label: "Reddy Sahakari Niyamita Bank Ltd" },
  { value: "Reena Jain", label: "Reena Jain" },
  { value: "Reetu Investments", label: "Reetu Investments" },
  { value: "Regal Finance And Inv Co.", label: "Regal Finance And Inv Co." },
  { value: "Regional Computer Centre", label: "Regional Computer Centre" },
  { value: "Regional Engineering College", label: "Regional Engineering College" },
  { value: "Regional Passport Office", label: "Regional Passport Office" },
  { value: "Regional Research Laboratory", label: "Regional Research Laboratory" },
  { value: "Regional Services Co-Op Bank Ltd.,", label: "Regional Services Co-Op Bank Ltd.," },
  { value: "Register Anna University Chennai", label: "Register Anna University Chennai" },
  { value: "Registrar Gju University", label: "Registrar Gju University" },
  { value: "Registrar M.D.U", label: "Registrar M.D.U" },
  { value: "Registrar Of Companies", label: "Registrar Of Companies" },
  { value: "Registrar Of Newspapers For India", label: "Registrar Of Newspapers For India" },
  { value: "Rehabilitation Council Of India", label: "Rehabilitation Council Of India" },
  { value: "Rehabilitation Industries Corpn. Ltd.", label: "Rehabilitation Industries Corpn. Ltd." },
  { value: "Rekh Dhoka", label: "Rekh Dhoka" },
  { value: "Rekha Devi Jain", label: "Rekha Devi Jain" },
  { value: "Rekha Devi Jain Automobile Finance", label: "Rekha Devi Jain Automobile Finance" },
  { value: "Rekha Devi Sethiya", label: "Rekha Devi Sethiya" },
  { value: "Rekha Gupta", label: "Rekha Gupta" },
  { value: "Reliable Agro Engg Services Pvt Ltd", label: "Reliable Agro Engg Services Pvt Ltd" },
  { value: "Reliable Finance Co", label: "Reliable Finance Co" },
  { value: "Reliance Associates", label: "Reliance Associates" },
  { value: "Reliance Auto Finance", label: "Reliance Auto Finance" },
  { value: "Reliance Bank Ltd", label: "Reliance Bank Ltd" },
  { value: "Reliance Cansumer Finance Pvt Ltd", label: "Reliance Cansumer Finance Pvt Ltd" },
  { value: "Reliance Capital Finance Ltd.", label: "Reliance Capital Finance Ltd." },
  { value: "Reliance Capital India Ltd", label: "Reliance Capital India Ltd" },
  { value: "Reliance Capital Services", label: "Reliance Capital Services" },
  { value: "Reliance Capital Services Private Ltd", label: "Reliance Capital Services Private Ltd" },
  { value: "Reliance Captial Ltd", label: "Reliance Captial Ltd" },
  { value: "Reliance Consumer Finance Pvt Ltd", label: "Reliance Consumer Finance Pvt Ltd" },
  { value: "Reliance Finance & Leasing", label: "Reliance Finance & Leasing" },
  { value: "Reliance Finance Ltd", label: "Reliance Finance Ltd" },
  { value: "Reliance Financial Services", label: "Reliance Financial Services" },
  { value: "Reliance General Insurance Chembur", label: "Reliance General Insurance Chembur" },
  { value: "Reliance General Insurance Ltd", label: "Reliance General Insurance Ltd" },
  { value: "Reliance General Insurance St. Johns Road Bangalore", label: "Reliance General Insurance St. Johns Road Bangalore" },
  { value: "Reliance Gic Ltd.", label: "Reliance Gic Ltd." },
  { value: "Reliance Industries Ltd", label: "Reliance Industries Ltd" },
  { value: "Reliance Infocomm Ltd", label: "Reliance Infocomm Ltd" },
  { value: "Reliance Money", label: "Reliance Money" },
  { value: "Reliance Motor Finance", label: "Reliance Motor Finance" },
  { value: "Reliant Hire Purchase Co. Pvt. Ltd.", label: "Reliant Hire Purchase Co. Pvt. Ltd." },
  { value: "Religare Commercial Financer", label: "Religare Commercial Financer" },
  { value: "Religare Finvest Ltd.", label: "Religare Finvest Ltd." },
  { value: "Relleno Insurance Broking Services Pvt Ltd", label: "Relleno Insurance Broking Services Pvt Ltd" },
  { value: "Rendal Sahakari Bank Ltd.", label: "Rendal Sahakari Bank Ltd." },
  { value: "Renu Manjusha", label: "Renu Manjusha" },
  { value: "Renuka Auto Leasing Fin Co", label: "Renuka Auto Leasing Fin Co" },
  { value: "Renuka Auto Leasing Fin Co Ltd", label: "Renuka Auto Leasing Fin Co Ltd" },
  { value: "Renuka Co-Op Credit Society", label: "Renuka Co-Op Credit Society" },
  { value: "Renuka Gramin Bigar Sheti Sah Pat San Maryadit", label: "Renuka Gramin Bigar Sheti Sah Pat San Maryadit" },
  { value: "Renuka Kanodia", label: "Renuka Kanodia" },
  { value: "Renuka Mata Nag Sah Pat", label: "Renuka Mata Nag Sah Pat" },
  { value: "Renuka Motors", label: "Renuka Motors" },
  { value: "Renuka Nagari Sahakari Path Shanstha Maryadit", label: "Renuka Nagari Sahakari Path Shanstha Maryadit" },
  { value: "Renuka Nagrik Sahakari Bank Mydt", label: "Renuka Nagrik Sahakari Bank Mydt" },
  { value: "Renuka Yellammadevi Credit Co Ltd", label: "Renuka Yellammadevi Credit Co Ltd" },
  { value: "Repatriates Co-Op Bank Ltd", label: "Repatriates Co-Op Bank Ltd" },
  { value: "Repco Bank Ltd.", label: "Repco Bank Ltd." },
  { value: "Research Reference And Training Division", label: "Research Reference And Training Division" },
  { value: "Reserve Bank Emploees Co Op Bank Ltd", label: "Reserve Bank Emploees Co Op Bank Ltd" },
  { value: "Reserve Bank Employees Co-Op Bank Ltd., Banglore", label: "Reserve Bank Employees Co-Op Bank Ltd., Banglore" },
  { value: "Reshma Finance Corporation", label: "Reshma Finance Corporation" },
  { value: "Reshme Belegarar & Raithura Seva Saha Bk", label: "Reshme Belegarar & Raithura Seva Saha Bk" },
  { value: "Resmi Hire Purchase", label: "Resmi Hire Purchase" },
  { value: "Resmi Hire Purchase And Leasing Finance Pvt. Ltd.", label: "Resmi Hire Purchase And Leasing Finance Pvt. Ltd." },
  { value: "Retrro Finance", label: "Retrro Finance" },
  { value: "Revanasiddeshwar Co-Op Credit Society", label: "Revanasiddeshwar Co-Op Credit Society" },
  { value: "Revansiddeshwar  Pattin Sahakari Bank Ltd.", label: "Revansiddeshwar  Pattin Sahakari Bank Ltd." },
  { value: "Revathi Investments", label: "Revathi Investments" },
  { value: "Revathi Investments", label: "Revathi Investments" },
  { value: "Revathy Finance", label: "Revathy Finance" },
  { value: "Revdanda Urban Co-Op Bank Ltd", label: "Revdanda Urban Co-Op Bank Ltd" },
  { value: "Revdanda Urban Co-Op Bank Ltd", label: "Revdanda Urban Co-Op Bank Ltd" },
  { value: "Rewa District Central Co-Op Bank Ltd", label: "Rewa District Central Co-Op Bank Ltd" },
  { value: "Rewa Sidhi Gramin Bank Ltd", label: "Rewa Sidhi Gramin Bank Ltd" },
  { value: "Rewa-Sidhi Gramin Bank", label: "Rewa-Sidhi Gramin Bank" },
  { value: "Rewari District Central Co-Operative Bank Ltd.", label: "Rewari District Central Co-Operative Bank Ltd." },
  { value: "Reza Abbas Foundation", label: "Reza Abbas Foundation" },
  { value: "Rf Motors Pvt Ltd", label: "Rf Motors Pvt Ltd" },
  { value: "Richardson And Cruddas (1972) Ltd.", label: "Richardson And Cruddas (1972) Ltd." },
  { value: "Richline Finance Ltd.", label: "Richline Finance Ltd." },
  { value: "Riddhi Tranding Co.", label: "Riddhi Tranding Co." },
  { value: "Riddi Siddhi Syndicate", label: "Riddi Siddhi Syndicate" },
  { value: "Ridhi Investments", label: "Ridhi Investments" },
  { value: "Ridhi Sidhi Finance Ltd", label: "Ridhi Sidhi Finance Ltd" },
  { value: "Ridhi Sidhi Investments", label: "Ridhi Sidhi Investments" },
  { value: "Ridhi Sidhi Tradinco", label: "Ridhi Sidhi Tradinco" },
  { value: "Ridhi Sidhi Trading Co", label: "Ridhi Sidhi Trading Co" },
  { value: "Ridhishiddi Finance", label: "Ridhishiddi Finance" },
  { value: "Ridhkaran Jitendra Kumar", label: "Ridhkaran Jitendra Kumar" },
  { value: "Ridi Sidi Finance", label: "Ridi Sidi Finance" },
  { value: "Right Leasing Hire Purchase Company Pvt Ltd.", label: "Right Leasing Hire Purchase Company Pvt Ltd." },
  { value: "Rigister Sk University", label: "Rigister Sk University" },
  { value: "Riidhii Krupa", label: "Riidhii Krupa" },
  { value: "Riidhii Krupa Finance", label: "Riidhii Krupa Finance" },
  { value: "Rikab Kanwar Jothi", label: "Rikab Kanwar Jothi" },
  { value: "Rilesh N", label: "Rilesh N" },
  { value: "Rimpy Auto Credits", label: "Rimpy Auto Credits" },
  { value: "Rimy Antony", label: "Rimy Antony" },
  { value: "Rini Financial Service", label: "Rini Financial Service" },
  { value: "Rinkesh Finance Company", label: "Rinkesh Finance Company" },
  { value: "Rinl Vsp Steel Plant", label: "Rinl Vsp Steel Plant" },
  { value: "Risab Auto Trading", label: "Risab Auto Trading" },
  { value: "Risab Motors", label: "Risab Motors" },
  { value: "Risaldar Fayozoddin N", label: "Risaldar Fayozoddin N" },
  { value: "Rishab Auto Finance", label: "Rishab Auto Finance" },
  { value: "Rishab Auto Finance Chennai", label: "Rishab Auto Finance Chennai" },
  { value: "Rishab Finance & Investment", label: "Rishab Finance & Investment" },
  { value: "Rishab Finance Corporation", label: "Rishab Finance Corporation" },
  { value: "Rishabh Auto Trading", label: "Rishabh Auto Trading" },
  { value: "Rishabh Finance", label: "Rishabh Finance" },
  { value: "Rishabh Investment", label: "Rishabh Investment" },
  { value: "Rishabh Motors Private Limited", label: "Rishabh Motors Private Limited" },
  { value: "Rishi Markendeshwar Finance Co", label: "Rishi Markendeshwar Finance Co" },
  { value: "Rishik Investment", label: "Rishik Investment" },
  { value: "Risk Capital And Technology Finance Corporation Ltd.", label: "Risk Capital And Technology Finance Corporation Ltd." },
  { value: "Rita Kumari Sadh", label: "Rita Kumari Sadh" },
  { value: "Rites Ltd", label: "Rites Ltd" },
  { value: "Ritesh Auto Finance", label: "Ritesh Auto Finance" },
  { value: "Rithak Auto Finance Company", label: "Rithak Auto Finance Company" },
  { value: "Rithik Auto Finance", label: "Rithik Auto Finance" },
  { value: "Rithvik Commercial Vehicle Finance", label: "Rithvik Commercial Vehicle Finance" },
  { value: "Rithwik Commercial Vehicle Finance", label: "Rithwik Commercial Vehicle Finance" },
  { value: "Rithwik Vehicle Finance", label: "Rithwik Vehicle Finance" },
  { value: "Ritiha Motors", label: "Ritiha Motors" },
  { value: "Ritika  Motors", label: "Ritika  Motors" },
  { value: "Ritika Finance", label: "Ritika Finance" },
  { value: "Ritika Finance", label: "Ritika Finance" },
  { value: "Ritu Shree Enterprises", label: "Ritu Shree Enterprises" },
  { value: "Ritushree Enterprise", label: "Ritushree Enterprise" },
  { value: "Rivaz Finance Ltd.", label: "Rivaz Finance Ltd." },
  { value: "Riya Motors", label: "Riya Motors" },
  { value: "Riya Travel And Tours (India) Pvt Ltd", label: "Riya Travel And Tours (India) Pvt Ltd" },
  { value: "Rjnl", label: "Rjnl" },
  { value: "Rk Enterprises", label: "Rk Enterprises" },
  { value: "Rkd Financial Services Ltd.", label: "Rkd Financial Services Ltd." },
  { value: "Rks Finance Automobile Finance", label: "Rks Finance Automobile Finance" },
  { value: "Rmsi Pvt Ltd.", label: "Rmsi Pvt Ltd." },
  { value: "Rohe Asthami Sahakari Urban Bank Ltd", label: "Rohe Asthami Sahakari Urban Bank Ltd" },
  { value: "Rohilkhand Financiers Ltd.", label: "Rohilkhand Financiers Ltd." },
  { value: "Rohin Finance Ltd", label: "Rohin Finance Ltd" },
  { value: "Rohini Finance", label: "Rohini Finance" },
  { value: "Rohini Financiers", label: "Rohini Financiers" },
  { value: "Rohit Auto", label: "Rohit Auto" },
  { value: "Rohit Enterprise", label: "Rohit Enterprise" },
  { value: "Rohit Finance", label: "Rohit Finance" },
  { value: "Rohit Finance Corporation", label: "Rohit Finance Corporation" },
  { value: "Rohit Kalra", label: "Rohit Kalra" },
  { value: "Rohit Pratap Mangal", label: "Rohit Pratap Mangal" },
  { value: "Rohith Auto Finance", label: "Rohith Auto Finance" },
  { value: "Rohtak District Central Co-Operative Bank Ltd.", label: "Rohtak District Central Co-Operative Bank Ltd." },
  { value: "Romee Investment", label: "Romee Investment" },
  { value: "Ron Taluk Primary Techersco-Op Bank Ltd", label: "Ron Taluk Primary Techersco-Op Bank Ltd" },
  { value: "Ronak Auto Finance", label: "Ronak Auto Finance" },
  { value: "Ronak Enterprizes", label: "Ronak Enterprizes" },
  { value: "Ronak Finance", label: "Ronak Finance" },
  { value: "Ronak Investment", label: "Ronak Investment" },
  { value: "Roonwal Investments Private Limited", label: "Roonwal Investments Private Limited" },
  { value: "Roop Shree Finance", label: "Roop Shree Finance" },
  { value: "Roopam Enterprises", label: "Roopam Enterprises" },
  { value: "Roopam Financiers Pvt Ltd", label: "Roopam Financiers Pvt Ltd" },
  { value: "Roopmala Auto Finance", label: "Roopmala Auto Finance" },
  { value: "Roopmilan Enterprise", label: "Roopmilan Enterprise" },
  { value: "Ropar Central Co.Op.Bank Ltd.,Ropar, Chandigarh", label: "Ropar Central Co.Op.Bank Ltd.,Ropar, Chandigarh" },
  { value: "Ropar District Central Co-Operative Bank Ltd.", label: "Ropar District Central Co-Operative Bank Ltd." },
  { value: "Roshan Auto Finance", label: "Roshan Auto Finance" },
  { value: "Roshan Pravinchandra Patel", label: "Roshan Pravinchandra Patel" },
  { value: "Roshan Thomas", label: "Roshan Thomas" },
  { value: "Roshni Enterprise,Surat", label: "Roshni Enterprise,Surat" },
  { value: "Rosy Finance Co", label: "Rosy Finance Co" },
  { value: "Rosy Jose", label: "Rosy Jose" },
  { value: "Royal Auto Finance", label: "Royal Auto Finance" },
  { value: "Royal Co-Op. Bank Ltd", label: "Royal Co-Op. Bank Ltd" },
  { value: "Royal Co-Op. Bank Ltd., Ahmedabad", label: "Royal Co-Op. Bank Ltd., Ahmedabad" },
  { value: "Royal Credit&Investments", label: "Royal Credit&Investments" },
  { value: "Royal Finance", label: "Royal Finance" },
  { value: "Royal Finance Corporation", label: "Royal Finance Corporation" },
  { value: "Royal Gmac  Financial( I) Ltd", label: "Royal Gmac  Financial( I) Ltd" },
  { value: "Royal Hire Purchase (P) Ltd.", label: "Royal Hire Purchase (P) Ltd." },
  { value: "Royal Instalment Company", label: "Royal Instalment Company" },
  { value: "Royal Instalment Company", label: "Royal Instalment Company" },
  { value: "Royal Motor Finance", label: "Royal Motor Finance" },
  { value: "Royal Sundaram Finance", label: "Royal Sundaram Finance" },
  { value: "Royal Trading Co Ltd.", label: "Royal Trading Co Ltd." },
  { value: "Royale Co-Op. Bank Ltd., Surat", label: "Royale Co-Op. Bank Ltd., Surat" },
  { value: "Royson Vincent", label: "Royson Vincent" },
  { value: "Rrdist Scscs Ltd", label: "Rrdist Scscs Ltd" },
  { value: "Rs Auto Finance", label: "Rs Auto Finance" },
  { value: "Rsb Finlease Pvt Ltd", label: "Rsb Finlease Pvt Ltd" },
  { value: "Rsm Mcgladrey Fpo India P L", label: "Rsm Mcgladrey Fpo India P L" },
  { value: "Rsp Finance And Investments", label: "Rsp Finance And Investments" },
  { value: "Rtc Financial Services Ltd.", label: "Rtc Financial Services Ltd." },
  { value: "Rubber Board", label: "Rubber Board" },
  { value: "Ruby Stella Mary", label: "Ruby Stella Mary" },
  { value: "Ruchi Enterprises", label: "Ruchi Enterprises" },
  { value: "Rudhu Finance", label: "Rudhu Finance" },
  { value: "Rudraksha Investment", label: "Rudraksha Investment" },
  { value: "Rudreshwar Sahakari Pattin Sangh Niyamit", label: "Rudreshwar Sahakari Pattin Sangh Niyamit" },
  { value: "Rui Yuva Gramin Sahakari Patsanstha Maryadit", label: "Rui Yuva Gramin Sahakari Patsanstha Maryadit" },
  { value: "Rukamini Sahkari Bank Ltd", label: "Rukamini Sahkari Bank Ltd" },
  { value: "Rukmini Bank Ltd", label: "Rukmini Bank Ltd" },
  { value: "Rukmini Gramin Bigarsheti Sahakari Pat Maryadit", label: "Rukmini Gramin Bigarsheti Sahakari Pat Maryadit" },
  { value: "Rukmini Gramin Bigarsheti Sahakari Pat Maryadit", label: "Rukmini Gramin Bigarsheti Sahakari Pat Maryadit" },
  { value: "Rukmini Nagarik Sahakari Bank Ltd", label: "Rukmini Nagarik Sahakari Bank Ltd" },
  { value: "Rukmini Sah. Bank Ltd", label: "Rukmini Sah. Bank Ltd" },
  { value: "Rupal Co Op Credit And Supply Soc", label: "Rupal Co Op Credit And Supply Soc" },
  { value: "Rupal Kampa Group", label: "Rupal Kampa Group" },
  { value: "Rupam Finance", label: "Rupam Finance" },
  { value: "Rupayan Finance & Industrial Corp", label: "Rupayan Finance & Industrial Corp" },
  { value: "Rupee Co-Op Bank Ltd", label: "Rupee Co-Op Bank Ltd" },
  { value: "Rupee Co-Op Bank Ltd.", label: "Rupee Co-Op Bank Ltd." },
  { value: "Rupesh Finance", label: "Rupesh Finance" },
  { value: "Rupinder Singh", label: "Rupinder Singh" },
  { value: "Rural Electrification Corporation Ltd", label: "Rural Electrification Corporation Ltd" },
  { value: "Ruruanchal Gramin Bank", label: "Ruruanchal Gramin Bank" },
  { value: "Rushab Financiers", label: "Rushab Financiers" },
  { value: "Rushi Finance", label: "Rushi Finance" },
  { value: "Rushika Mahila Nagrik Co-Op Bank Ltd", label: "Rushika Mahila Nagrik Co-Op Bank Ltd" },
  { value: "Rushubh  Enterprise", label: "Rushubh  Enterprise" },
  { value: "Rvb Associates", label: "Rvb Associates" },
  { value: "Rvb Associates", label: "Rvb Associates" },
  { value: "Rvr Finance", label: "Rvr Finance" },
  { value: "Ryathara Seva Sahakara Sangha Niyamitha", label: "Ryathara Seva Sahakara Sangha Niyamitha" },
  { value: "Rytha Seava Sahakara Bank Ltd", label: "Rytha Seava Sahakara Bank Ltd" },
  { value: "Rythara Seva Sahakari Bank", label: "Rythara Seva Sahakari Bank" },
  { value: "S A Auto Finance", label: "S A Auto Finance" },
  { value: "S A Auto Services", label: "S A Auto Services" },
  { value: "S A Finance", label: "S A Finance" },
  { value: "S A Finance", label: "S A Finance" },
  { value: "S A Prasad", label: "S A Prasad" },
  { value: "S Anand Balecha Financier", label: "S Anand Balecha Financier" },
  { value: "S B Associates", label: "S B Associates" },
  { value: "S B I Bhilai", label: "S B I Bhilai" },
  { value: "S B S Krishi Vikas", label: "S B S Krishi Vikas" },
  { value: "S Basant Kumar Jain", label: "S Basant Kumar Jain" },
  { value: "S C D C C Bank Ltd - Karkala", label: "S C D C C Bank Ltd - Karkala" },
  { value: "S C Dev Corporation", label: "S C Dev Corporation" },
  { value: "S C U F Sindhanur", label: "S C U F Sindhanur" },
  { value: "S D Enterprise", label: "S D Enterprise" },
  { value: "S D Finance", label: "S D Finance" },
  { value: "S Deepika Dugai", label: "S Deepika Dugai" },
  { value: "S Dharmichand", label: "S Dharmichand" },
  { value: "S Dilip Kumar Chordiya", label: "S Dilip Kumar Chordiya" },
  { value: "S Dinesh Kumar", label: "S Dinesh Kumar" },
  { value: "S Dinesh Kumar Financier", label: "S Dinesh Kumar Financier" },
  { value: "S Gautamchand Jain", label: "S Gautamchand Jain" },
  { value: "S H Enterprises", label: "S H Enterprises" },
  { value: "S I B I Auto Finance", label: "S I B I Auto Finance" },
  { value: "S J Finance", label: "S J Finance" },
  { value: "S J M Credit Co-Op Society Ltd", label: "S J M Credit Co-Op Society Ltd" },
  { value: "S J Nahar & Sons (Huf)", label: "S J Nahar & Sons (Huf)" },
  { value: "S Jai Kumar", label: "S Jai Kumar" },
  { value: "S K Automobiles Finance Pvt Ltd", label: "S K Automobiles Finance Pvt Ltd" },
  { value: "S K Enterprises", label: "S K Enterprises" },
  { value: "S K Finance", label: "S K Finance" },
  { value: "S K Finance And Investment Co.", label: "S K Finance And Investment Co." },
  { value: "S K Finance And Leasing Co.", label: "S K Finance And Leasing Co." },
  { value: "S K Financiers", label: "S K Financiers" },
  { value: "S K G Bank", label: "S K G Bank" },
  { value: "S K G Leasing & Finance", label: "S K G Leasing & Finance" },
  { value: "S K Goldsmith And Indu Co-Op Society Ltd - Udupi", label: "S K Goldsmith And Indu Co-Op Society Ltd - Udupi" },
  { value: "S K Goldsmith Indlco-Op Bank Ltd", label: "S K Goldsmith Indlco-Op Bank Ltd" },
  { value: "S K Investments", label: "S K Investments" },
  { value: "S K Munna Mohame Finance", label: "S K Munna Mohame Finance" },
  { value: "S K Syndicate", label: "S K Syndicate" },
  { value: "S K Thirumurgan Auto Finance", label: "S K Thirumurgan Auto Finance" },
  { value: "S K Thirumurugan Auto Finance", label: "S K Thirumurugan Auto Finance" },
  { value: "S K Thirumurugan Auto Services", label: "S K Thirumurugan Auto Services" },
  { value: "S L Finance", label: "S L Finance" },
  { value: "S L K Financier", label: "S L K Financier" },
  { value: "S L Puram Service Co-Op Bank Ltd", label: "S L Puram Service Co-Op Bank Ltd" },
  { value: "S M Auto Finance", label: "S M Auto Finance" },
  { value: "S M Enterprises", label: "S M Enterprises" },
  { value: "S M Finance", label: "S M Finance" },
  { value: "S M Investment", label: "S M Investment" },
  { value: "S M R Finance", label: "S M R Finance" },
  { value: "S Madanlal Gundecha And So Financier", label: "S Madanlal Gundecha And So Financier" },
  { value: "S Mahaveer", label: "S Mahaveer" },
  { value: "S Manak Chand Jain", label: "S Manak Chand Jain" },
  { value: "S Mandalik B Dham Juth V K S Mandali", label: "S Mandalik B Dham Juth V K S Mandali" },
  { value: "S Meena Bai", label: "S Meena Bai" },
  { value: "S Mendhar Kumar Bajaj", label: "S Mendhar Kumar Bajaj" },
  { value: "S N Finance", label: "S N Finance" },
  { value: "S N G B", label: "S N G B" },
  { value: "S N Hire Purchase Pvt. Ltd", label: "S N Hire Purchase Pvt. Ltd" },
  { value: "S Neelam Chand", label: "S Neelam Chand" },
  { value: "S Neelam Chand Financiers", label: "S Neelam Chand Financiers" },
  { value: "S Nemichand & Sons Financiers", label: "S Nemichand & Sons Financiers" },
  { value: "S Nirmal Kumar Financier", label: "S Nirmal Kumar Financier" },
  { value: "S Nirmalkumar & Sons", label: "S Nirmalkumar & Sons" },
  { value: "S P Auto Finance", label: "S P Auto Finance" },
  { value: "S P K Radar Finance", label: "S P K Radar Finance" },
  { value: "S P R Finance Ltd.", label: "S P R Finance Ltd." },
  { value: "S P S Anandhan M A Financier", label: "S P S Anandhan M A Financier" },
  { value: "S Padam Chand", label: "S Padam Chand" },
  { value: "S Pankajkumar And Com.", label: "S Pankajkumar And Com." },
  { value: "S Pradipkumar And Company", label: "S Pradipkumar And Company" },
  { value: "S Prakash Chand", label: "S Prakash Chand" },
  { value: "S Prakash Chand Chajjed", label: "S Prakash Chand Chajjed" },
  { value: "S Prem Chand", label: "S Prem Chand" },
  { value: "S Pushpadevi Jamad", label: "S Pushpadevi Jamad" },
  { value: "S R  Finance", label: "S R  Finance" },
  { value: "S R Auto Finance", label: "S R Auto Finance" },
  { value: "S R Auto Finance Corporation", label: "S R Auto Finance Corporation" },
  { value: "S R Autos", label: "S R Autos" },
  { value: "S R Finance Company", label: "S R Finance Company" },
  { value: "S R Jain And Sons", label: "S R Jain And Sons" },
  { value: "S R M Finance", label: "S R M Finance" },
  { value: "S R Motors", label: "S R Motors" },
  { value: "S Rajesh Jain H U F", label: "S Rajesh Jain H U F" },
  { value: "S Ramesh Kumar", label: "S Ramesh Kumar" },
  { value: "S Ramesh Nahar And Sons", label: "S Ramesh Nahar And Sons" },
  { value: "S Ranjeet Kumar Jain", label: "S Ranjeet Kumar Jain" },
  { value: "S Ranjeet Kumar Jain Automobile Finance", label: "S Ranjeet Kumar Jain Automobile Finance" },
  { value: "S S Auto Finance", label: "S S Auto Finance" },
  { value: "S S B P S S N I", label: "S S B P S S N I" },
  { value: "S S Chandok Leasing & Finance Co.", label: "S S Chandok Leasing & Finance Co." },
  { value: "S S Enterprise", label: "S S Enterprise" },
  { value: "S S Finance", label: "S S Finance" },
  { value: "S S Financiers (Regd)", label: "S S Financiers (Regd)" },
  { value: "S S Investment", label: "S S Investment" },
  { value: "S S K Co-Op Bank Ltd", label: "S S K Co-Op Bank Ltd" },
  { value: "S S K Co-Op C S Ltd", label: "S S K Co-Op C S Ltd" },
  { value: "S S M B Finance And Investments", label: "S S M B Finance And Investments" },
  { value: "S S M P Finance", label: "S S M P Finance" },
  { value: "S S Maniyar", label: "S S Maniyar" },
  { value: "S Sajjan Raj Jain & Sons Auto", label: "S Sajjan Raj Jain & Sons Auto" },
  { value: "S Sampath Raj & Sons", label: "S Sampath Raj & Sons" },
  { value: "S Sampathraj", label: "S Sampathraj" },
  { value: "S Sanjai Kumar Finance", label: "S Sanjai Kumar Finance" },
  { value: "S Santhana Krishna Financier", label: "S Santhana Krishna Financier" },
  { value: "S Santhana Krishnan", label: "S Santhana Krishnan" },
  { value: "S Santhosh Devi", label: "S Santhosh Devi" },
  { value: "S Santhosh Kumari Finance", label: "S Santhosh Kumari Finance" },
  { value: "S Sardar Kumari", label: "S Sardar Kumari" },
  { value: "S Sasikala Dugar", label: "S Sasikala Dugar" },
  { value: "S Shanthilal &Sons", label: "S Shanthilal &Sons" },
  { value: "S Shanthilal Financier", label: "S Shanthilal Financier" },
  { value: "S Somana Sisodia", label: "S Somana Sisodia" },
  { value: "S Sridhar Rao", label: "S Sridhar Rao" },
  { value: "S Subendra Kumar", label: "S Subendra Kumar" },
  { value: "S Suresh Chand", label: "S Suresh Chand" },
  { value: "S T C Of India Ltd", label: "S T C Of India Ltd" },
  { value: "S T F C L", label: "S T F C L" },
  { value: "S T P Krushi & Grameen Abhiruddi Bank Niyamita", label: "S T P Krushi & Grameen Abhiruddi Bank Niyamita" },
  { value: "S Thanmal", label: "S Thanmal" },
  { value: "S Ugam Raj And Sons", label: "S Ugam Raj And Sons" },
  { value: "S Ugam Raj Bohara Financier", label: "S Ugam Raj Bohara Financier" },
  { value: "S V Finance", label: "S V Finance" },
  { value: "S V Jain And Sons", label: "S V Jain And Sons" },
  { value: "S V K Chits And Finance", label: "S V K Chits And Finance" },
  { value: "S V Ramana Reddy A/F", label: "S V Ramana Reddy A/F" },
  { value: "S V Syndicate", label: "S V Syndicate" },
  { value: "S Vasanthi Devi Dugar", label: "S Vasanthi Devi Dugar" },
  { value: "S. B. I Darbargadh", label: "S. B. I Darbargadh" },
  { value: "S. B.P Co-Op Bank Ltd", label: "S. B.P Co-Op Bank Ltd" },
  { value: "S. Devi Visalakshmi Finance", label: "S. Devi Visalakshmi Finance" },
  { value: "S. G. Bank", label: "S. G. Bank" },
  { value: "S. J. M. Credit Co-Op. Society Ltd", label: "S. J. M. Credit Co-Op. Society Ltd" },
  { value: "S. K. Trading Co.", label: "S. K. Trading Co." },
  { value: "S. M. Auto Finance", label: "S. M. Auto Finance" },
  { value: "S. Mahaveer Financier", label: "S. Mahaveer Financier" },
  { value: "S. Narendra Chajjed", label: "S. Narendra Chajjed" },
  { value: "S. Pankaj Fin- Co", label: "S. Pankaj Fin- Co" },
  { value: "S. Premchand Singhvi & Sons (Huf)", label: "S. Premchand Singhvi & Sons (Huf)" },
  { value: "S. S. J. Enterprise", label: "S. S. J. Enterprise" },
  { value: "S. Sanjay Kumar Financier", label: "S. Sanjay Kumar Financier" },
  { value: "S.A.M.M. Co-Operative Bank Ltd", label: "S.A.M.M. Co-Operative Bank Ltd" },
  { value: "S.A.M.M. Co-Operative Bank Ltd.", label: "S.A.M.M. Co-Operative Bank Ltd." },
  { value: "S.A.Services Limited", label: "S.A.Services Limited" },
  { value: "S.Arumugham", label: "S.Arumugham" },
  { value: "S.B.B.J", label: "S.B.B.J" },
  { value: "S.B.I Fund Management Ltd", label: "S.B.I Fund Management Ltd" },
  { value: "S.B.I Fund Management System", label: "S.B.I Fund Management System" },
  { value: "S.B.L Pvt Ltd", label: "S.B.L Pvt Ltd" },
  { value: "S.B.P.P. Co-Op Bank Ltd", label: "S.B.P.P. Co-Op Bank Ltd" },
  { value: "S.B.P.S.S.N.", label: "S.B.P.S.S.N." },
  { value: "S.Bhumi Vikas Bank Ltd", label: "S.Bhumi Vikas Bank Ltd" },
  { value: "S.Budhmal ( Huf )", label: "S.Budhmal ( Huf )" },
  { value: "S.C. Surana And Sons", label: "S.C. Surana And Sons" },
  { value: "S.D.C.C Bank Ltd.", label: "S.D.C.C Bank Ltd." },
  { value: "S.D.S. Corporate", label: "S.D.S. Corporate" },
  { value: "S.D.Sivamalai Murugan Motor Finance", label: "S.D.Sivamalai Murugan Motor Finance" },
  { value: "S.Deepike Dugar", label: "S.Deepike Dugar" },
  { value: "S.E. Investments Ltd.", label: "S.E. Investments Ltd." },
  { value: "S.E.C.F.", label: "S.E.C.F." },
  { value: "S.F.C. Finance", label: "S.F.C. Finance" },
  { value: "S.G.R. Financial Services Ltd.", label: "S.G.R. Financial Services Ltd." },
  { value: "S.G.S.P.S.N.", label: "S.G.S.P.S.N." },
  { value: "S.Hasthimull Chordia", label: "S.Hasthimull Chordia" },
  { value: "S.I.D.B.I", label: "S.I.D.B.I" },
  { value: "S.J.M Credit Co-Op Society Ltd", label: "S.J.M Credit Co-Op Society Ltd" },
  { value: "S.K  Bussiness Co. Op", label: "S.K  Bussiness Co. Op" },
  { value: "S.K Chamba", label: "S.K Chamba" },
  { value: "S.K. Auto Center", label: "S.K. Auto Center" },
  { value: "S.K. Auto Deals", label: "S.K. Auto Deals" },
  { value: "S.K. Cororation Finance Srinagar", label: "S.K. Cororation Finance Srinagar" },
  { value: "S.K. Finance Corp", label: "S.K. Finance Corp" },
  { value: "S.K.Finance", label: "S.K.Finance" },
  { value: "S.K.G. Leasing & Finance", label: "S.K.G. Leasing & Finance" },
  { value: "S.K.G.B", label: "S.K.G.B" },
  { value: "S.K.Goldsmith Indus Co-Operative Society", label: "S.K.Goldsmith Indus Co-Operative Society" },
  { value: "S.K.Govt. Officers Co-Op Bank Ltd", label: "S.K.Govt. Officers Co-Op Bank Ltd" },
  { value: "S.L.V.V.Auto Finance Ltd", label: "S.L.V.V.Auto Finance Ltd" },
  { value: "S.M.Apparels Pvt Ltd", label: "S.M.Apparels Pvt Ltd" },
  { value: "S.M.Investments", label: "S.M.Investments" },
  { value: "S.M.L. Finance Ltd.", label: "S.M.L. Finance Ltd." },
  { value: "S.M.Motor", label: "S.M.Motor" },
  { value: "S.Mahipal Chand", label: "S.Mahipal Chand" },
  { value: "S.N.Finance Ltd", label: "S.N.Finance Ltd" },
  { value: "S.N.P.S. Sangha", label: "S.N.P.S. Sangha" },
  { value: "S.Neetu Dugar", label: "S.Neetu Dugar" },
  { value: "S.P Motor", label: "S.P Motor" },
  { value: "S.P Sethiya And Sons", label: "S.P Sethiya And Sons" },
  { value: "S.P. Finance", label: "S.P. Finance" },
  { value: "S.P.R. Finance Ltd.", label: "S.P.R. Finance Ltd." },
  { value: "S.P.S.S. Bank", label: "S.P.S.S. Bank" },
  { value: "S.Prashaut Jain  Sawkarpeth Chennai", label: "S.Prashaut Jain  Sawkarpeth Chennai" },
  { value: "S.Premchand Nahar", label: "S.Premchand Nahar" },
  { value: "S.Purusothaman", label: "S.Purusothaman" },
  { value: "S.R Auto", label: "S.R Auto" },
  { value: "S.R Kothari Finance (P) Ltd", label: "S.R Kothari Finance (P) Ltd" },
  { value: "S.R.Associaies", label: "S.R.Associaies" },
  { value: "S.S.M.S. Urban Co-Operative Bank Ltd.", label: "S.S.M.S. Urban Co-Operative Bank Ltd." },
  { value: "S.Sugam Raj And Sons Financiers", label: "S.Sugam Raj And Sons Financiers" },
  { value: "S.Sumer Chand", label: "S.Sumer Chand" },
  { value: "S.Sureshkumar", label: "S.Sureshkumar" },
  { value: "S.T.C Of India Ltd", label: "S.T.C Of India Ltd" },
  { value: "S.Uttam Chand Jain", label: "S.Uttam Chand Jain" },
  { value: "S.V.R. Finance And Leasing (P) Ltd.", label: "S.V.R. Finance And Leasing (P) Ltd." },
  { value: "S.V.V.S Auto Consultancy", label: "S.V.V.S Auto Consultancy" },
  { value: "S.Y. Co-Op Bank", label: "S.Y. Co-Op Bank" },
  { value: "Sabari Auto Finance", label: "Sabari Auto Finance" },
  { value: "Sabari Auto Finances", label: "Sabari Auto Finances" },
  { value: "Sabari Financiers", label: "Sabari Financiers" },
  { value: "Sabarkantha  Bank Ltd And Vathod S S M Ltd", label: "Sabarkantha  Bank Ltd And Vathod S S M Ltd" },
  { value: "Sabarkantha - Gandhinagar Gramin Bank", label: "Sabarkantha - Gandhinagar Gramin Bank" },
  { value: "Sabarkantha Bank", label: "Sabarkantha Bank" },
  { value: "Sabarkantha Bank Ltd", label: "Sabarkantha Bank Ltd" },
  { value: "Sabarkantha Co Op Bank Ltd", label: "Sabarkantha Co Op Bank Ltd" },
  { value: "Sabarkantha Dist. Central Co-Op Bank Ltd", label: "Sabarkantha Dist. Central Co-Op Bank Ltd" },
  { value: "Sabarkantha District Central Co-Operative Bank Ltd.", label: "Sabarkantha District Central Co-Operative Bank Ltd." },
  { value: "Sabarkantha District Co-Operative Bank", label: "Sabarkantha District Co-Operative Bank" },
  { value: "Sabarkantha Gandhinagar Gramin Bank Ltd", label: "Sabarkantha Gandhinagar Gramin Bank Ltd" },
  { value: "Sabarmati Co-Operative Bank Ltd.", label: "Sabarmati Co-Operative Bank Ltd." },
  { value: "Sabee Fin Pvt Ltd", label: "Sabee Fin Pvt Ltd" },
  { value: "Sabri Traders", label: "Sabri Traders" },
  { value: "Sabs Exports", label: "Sabs Exports" },
  { value: "Sabu Idikkula Edikkula", label: "Sabu Idikkula Edikkula" },
  { value: "Sachdeva Auto Deals", label: "Sachdeva Auto Deals" },
  { value: "Sachdeva Fast Finance Co.", label: "Sachdeva Fast Finance Co." },
  { value: "Sachdeva Finance", label: "Sachdeva Finance" },
  { value: "Sachida Nanda Finance", label: "Sachida Nanda Finance" },
  { value: "Sachin Finance Corporation", label: "Sachin Finance Corporation" },
  { value: "Sachin Industrial Co-Op. Bank Ltd., Surat", label: "Sachin Industrial Co-Op. Bank Ltd., Surat" },
  { value: "Sachin Kapoor", label: "Sachin Kapoor" },
  { value: "Sachin Lifestyle Insurance Brokers Pvt.Ltd", label: "Sachin Lifestyle Insurance Brokers Pvt.Ltd" },
  { value: "Sachiyay Investment", label: "Sachiyay Investment" },
  { value: "Sachkhand Travels Pvt Ltd", label: "Sachkhand Travels Pvt Ltd" },
  { value: "Sachsons Leasing & Finance Pvt. Ltd", label: "Sachsons Leasing & Finance Pvt. Ltd" },
  { value: "Sacred Heart Credit Co Op Society Ltd", label: "Sacred Heart Credit Co Op Society Ltd" },
  { value: "Sadalaga Sauharda Sahakari Bank Niyamitha", label: "Sadalaga Sauharda Sahakari Bank Niyamitha" },
  { value: "Sadalga Urban Co-Op Bank Ltd", label: "Sadalga Urban Co-Op Bank Ltd" },
  { value: "Sadalga Urban Souharda Sahakari Bank", label: "Sadalga Urban Souharda Sahakari Bank" },
  { value: "Sadalga Urban Souharda Sahakari Bank Niy", label: "Sadalga Urban Souharda Sahakari Bank Niy" },
  { value: "Sadanand Gramin Bigarsheti Sah Path Sanstha Maryadit", label: "Sadanand Gramin Bigarsheti Sah Path Sanstha Maryadit" },
  { value: "Sadashiv Nag Sah Pat Sanstha Mydt", label: "Sadashiv Nag Sah Pat Sanstha Mydt" },
  { value: "Sadashiv Nag Sah Pat Sanstha Mydt.", label: "Sadashiv Nag Sah Pat Sanstha Mydt." },
  { value: "Sadashiv Shejal Nagari Sah Pat Sanstha", label: "Sadashiv Shejal Nagari Sah Pat Sanstha" },
  { value: "Sadbhav Nagrik Sahakari Bank Ltd.", label: "Sadbhav Nagrik Sahakari Bank Ltd." },
  { value: "Sadbhav Sanmitra Gramin Biger Krushi Sahakari Pathsanstha", label: "Sadbhav Sanmitra Gramin Biger Krushi Sahakari Pathsanstha" },
  { value: "Sadbhawana Motor Hire Purchase", label: "Sadbhawana Motor Hire Purchase" },
  { value: "Sadguru Auto  And  General Finance", label: "Sadguru Auto  And  General Finance" },
  { value: "Sadguru Chaure Maharaj Gramin Sahakari Patsastha.", label: "Sadguru Chaure Maharaj Gramin Sahakari Patsastha." },
  { value: "Sadguru Co Op Credit Soc. Ltd.", label: "Sadguru Co Op Credit Soc. Ltd." },
  { value: "Sadguru Finance", label: "Sadguru Finance" },
  { value: "Sadguru Gahininath Urban Co-Op Bank Ltd Akluj", label: "Sadguru Gahininath Urban Co-Op Bank Ltd Akluj" },
  { value: "Sadguru Gahininath Urban Co-Op. Bank Ltd", label: "Sadguru Gahininath Urban Co-Op. Bank Ltd" },
  { value: "Sadguru Haribhau Maharaj Nagari Sah. Pat San.", label: "Sadguru Haribhau Maharaj Nagari Sah. Pat San." },
  { value: "Sadguru Nag.Sah.Bank Mydt., Bhopal", label: "Sadguru Nag.Sah.Bank Mydt., Bhopal" },
  { value: "Sadguru Nagrik Sahakari Bank Ltd.", label: "Sadguru Nagrik Sahakari Bank Ltd." },
  { value: "Sadguru Sakh Sahakarita Ltd", label: "Sadguru Sakh Sahakarita Ltd" },
  { value: "Sadgurur Gramina Bigar Shri Sahi Path", label: "Sadgurur Gramina Bigar Shri Sahi Path" },
  { value: "Sadhana Auto Finance", label: "Sadhana Auto Finance" },
  { value: "Sadhana Auto Finance", label: "Sadhana Auto Finance" },
  { value: "Sadhana Co-Op. Bank Ltd.", label: "Sadhana Co-Op. Bank Ltd." },
  { value: "Sadhana Investments", label: "Sadhana Investments" },
  { value: "Sadhana Mahila Gramin Biger Sheti Sah Pat Sanstha", label: "Sadhana Mahila Gramin Biger Sheti Sah Pat Sanstha" },
  { value: "Sadhana Sah Pathpedhi Vasai", label: "Sadhana Sah Pathpedhi Vasai" },
  { value: "Sadhana Sah.Pathpedhi", label: "Sadhana Sah.Pathpedhi" },
  { value: "Sadhana Sahakari Bank Ltd.", label: "Sadhana Sahakari Bank Ltd." },
  { value: "Sadhana Sahakari Bank Ltd.", label: "Sadhana Sahakari Bank Ltd." },
  { value: "Sadhana Sahakari Bank Ltd.", label: "Sadhana Sahakari Bank Ltd." },
  { value: "Sadhana Sahakari Pathpedhi Maryadit", label: "Sadhana Sahakari Pathpedhi Maryadit" },
  { value: "Sadhana Sahakari Pathpedhi Maryadit  Holi Branch", label: "Sadhana Sahakari Pathpedhi Maryadit  Holi Branch" },
  { value: "Sadhana Securities Pvt Ltd", label: "Sadhana Securities Pvt Ltd" },
  { value: "Sadhbhawna Finance Pvt Ltd", label: "Sadhbhawna Finance Pvt Ltd" },
  { value: "Sadhna Singhal", label: "Sadhna Singhal" },
  { value: "Sadhu Waswani Urban Credit Co-Op Bank", label: "Sadhu Waswani Urban Credit Co-Op Bank" },
  { value: "Sadichcha Co Op Credit Society Ltd", label: "Sadichcha Co Op Credit Society Ltd" },
  { value: "Safal And Co", label: "Safal And Co" },
  { value: "Safal And Coaudi Manor G Floor", label: "Safal And Coaudi Manor G Floor" },
  { value: "Safari Kamdar Vikas Nigam Gandhinagar", label: "Safari Kamdar Vikas Nigam Gandhinagar" },
  { value: "Sagar Auto Leasing", label: "Sagar Auto Leasing" },
  { value: "Sagar Auto Leasing", label: "Sagar Auto Leasing" },
  { value: "Sagar Automobiles", label: "Sagar Automobiles" },
  { value: "Sagar Deposits And Advances Pvt. Ltd.", label: "Sagar Deposits And Advances Pvt. Ltd." },
  { value: "Sagar Fin Corporation", label: "Sagar Fin Corporation" },
  { value: "Sagar Fin. Ltd.", label: "Sagar Fin. Ltd." },
  { value: "Sagar Gramin Bank Ltd", label: "Sagar Gramin Bank Ltd" },
  { value: "Sagar Investment", label: "Sagar Investment" },
  { value: "Sagar Lease Fin Ltd", label: "Sagar Lease Fin Ltd" },
  { value: "Sagar Motors", label: "Sagar Motors" },
  { value: "Sagara Auto Finance", label: "Sagara Auto Finance" },
  { value: "Sagarmal & Sons", label: "Sagarmal & Sons" },
  { value: "Sagarmal Kothari", label: "Sagarmal Kothari" },
  { value: "Sagarmal Kothari Huf", label: "Sagarmal Kothari Huf" },
  { value: "Saguthalam Finance", label: "Saguthalam Finance" },
  { value: "Sah Mah Shivajirao Kale Malvahatok Sanstha", label: "Sah Mah Shivajirao Kale Malvahatok Sanstha" },
  { value: "Saha Institute Of Nuclear Physics", label: "Saha Institute Of Nuclear Physics" },
  { value: "Sahaja Motors And General Finance Co", label: "Sahaja Motors And General Finance Co" },
  { value: "Sahajanand Urben", label: "Sahajanand Urben" },
  { value: "Sahakar Maharshi Kisanrao Warul Pat", label: "Sahakar Maharshi Kisanrao Warul Pat" },
  { value: "Sahakar Maharshi Shivajirao Kale Sah.Sanstha Maryadit", label: "Sahakar Maharshi Shivajirao Kale Sah.Sanstha Maryadit" },
  { value: "Sahakar Mahorshi Trambakrao Ghuge Guruji Bhagyashri Grahmin Bigarsheti Sah Pat Sanstha", label: "Sahakar Mahorshi Trambakrao Ghuge Guruji Bhagyashri Grahmin Bigarsheti Sah Pat Sanstha" },
  { value: "Sahakari Bachat Bank", label: "Sahakari Bachat Bank" },
  { value: "Sahakari Bachat Bank,", label: "Sahakari Bachat Bank," },
  { value: "Sahakari Bhoomi Vikas Bank Ltd", label: "Sahakari Bhoomi Vikas Bank Ltd" },
  { value: "Sahakari Kendriya Bank Maryadit.", label: "Sahakari Kendriya Bank Maryadit." },
  { value: "Sahakari Mandli", label: "Sahakari Mandli" },
  { value: "Sahara Alpasankyatara Vividodesha S S N", label: "Sahara Alpasankyatara Vividodesha S S N" },
  { value: "Sahara Alpha Sankyatara Vividoddesha Sahakara Sangha Niyamit", label: "Sahara Alpha Sankyatara Vividoddesha Sahakara Sangha Niyamit" },
  { value: "Sahara India Financial Corporation Ltd.", label: "Sahara India Financial Corporation Ltd." },
  { value: "Sahasarjun Somavamsa Khaspriya Co Op Society", label: "Sahasarjun Somavamsa Khaspriya Co Op Society" },
  { value: "Sahastrarjun Nag. Sah. Pathsanstha Solapur", label: "Sahastrarjun Nag. Sah. Pathsanstha Solapur" },
  { value: "Sahayadri Nagari Sahkari Patsanstha Maryadit", label: "Sahayadri Nagari Sahkari Patsanstha Maryadit" },
  { value: "Sahayog Co Op Cr Soc Ltd", label: "Sahayog Co Op Cr Soc Ltd" },
  { value: "Sahayta Financeandleasing (P) Ltd.", label: "Sahayta Financeandleasing (P) Ltd." },
  { value: "Saheba Properties Ltd", label: "Saheba Properties Ltd" },
  { value: "Sahej Finance Ltd", label: "Sahej Finance Ltd" },
  { value: "Sahib Finance Co.", label: "Sahib Finance Co." },
  { value: "Sahil Finance", label: "Sahil Finance" },
  { value: "Sahil Motor And Finance", label: "Sahil Motor And Finance" },
  { value: "Sahkar Mah. Kisanrao Varl Patil Patsanstha", label: "Sahkar Mah. Kisanrao Varl Patil Patsanstha" },
  { value: "Sahkar Maharshi Trambakrao Ghuge ( Guruji ) Bhagyashri Gr Bi Sah Pat San Ltd", label: "Sahkar Maharshi Trambakrao Ghuge ( Guruji ) Bhagyashri Gr Bi Sah Pat San Ltd" },
  { value: "Sahkari Maharshi Late Bhaskarrao Shingne Nag. Sah. P. Sa. M.", label: "Sahkari Maharshi Late Bhaskarrao Shingne Nag. Sah. P. Sa. M." },
  { value: "Sahni Motors Pvt Ltd", label: "Sahni Motors Pvt Ltd" },
  { value: "Sahu Dhanlaxmi Financers Regd", label: "Sahu Dhanlaxmi Financers Regd" },
  { value: "Sahul Finance Ltd", label: "Sahul Finance Ltd" },
  { value: "Sahyadari Sahkari Bank Ltd", label: "Sahyadari Sahkari Bank Ltd" },
  { value: "Sahyaderi Co-Op Credit Society Ltd", label: "Sahyaderi Co-Op Credit Society Ltd" },
  { value: "Sahyadri  Nagri Sahakari Patsanstha  Maryadit", label: "Sahyadri  Nagri Sahakari Patsanstha  Maryadit" },
  { value: "Sahyadri Co Operative Credit Society Ltd", label: "Sahyadri Co Operative Credit Society Ltd" },
  { value: "Sahyadri Gramin Bigar", label: "Sahyadri Gramin Bigar" },
  { value: "Sahyadri Gramin Bigar Sheti Patsanstha Maryadit", label: "Sahyadri Gramin Bigar Sheti Patsanstha Maryadit" },
  { value: "Sahyadri Gramina Bank", label: "Sahyadri Gramina Bank" },
  { value: "Sahyadri Sah. Bank Ltd., Mumbai", label: "Sahyadri Sah. Bank Ltd., Mumbai" },
  { value: "Sahyadri Sahakari Bank Ltd.", label: "Sahyadri Sahakari Bank Ltd." },
  { value: "Sahyadri Sahakari Patsanstha Ltd", label: "Sahyadri Sahakari Patsanstha Ltd" },
  { value: "Sahyadri Seva Sahakari Bank", label: "Sahyadri Seva Sahakari Bank" },
  { value: "Sahyadri Shakari Bank Ltd.", label: "Sahyadri Shakari Bank Ltd." },
  { value: "Sahyog Co Op Bank", label: "Sahyog Co Op Bank" },
  { value: "Sahyog Co-Op Credit Society Ltd", label: "Sahyog Co-Op Credit Society Ltd" },
  { value: "Sahyog Credit Cop Society", label: "Sahyog Credit Cop Society" },
  { value: "Sahyog Enterprises", label: "Sahyog Enterprises" },
  { value: "Sahyog Financial Company", label: "Sahyog Financial Company" },
  { value: "Sahyog Lease Finance", label: "Sahyog Lease Finance" },
  { value: "Sahyog Sahakari Pat Sanstha", label: "Sahyog Sahakari Pat Sanstha" },
  { value: "Sahyog Urban Co-Op Bank Ltd", label: "Sahyog Urban Co-Op Bank Ltd" },
  { value: "Sai Anand Auto Finance", label: "Sai Anand Auto Finance" },
  { value: "Sai Ashish Auto Finance", label: "Sai Ashish Auto Finance" },
  { value: "Sai Auto Consult", label: "Sai Auto Consult" },
  { value: "Sai Auto Finance & Leasing (R)", label: "Sai Auto Finance & Leasing (R)" },
  { value: "Sai Auto General Finance", label: "Sai Auto General Finance" },
  { value: "Sai Automobiles", label: "Sai Automobiles" },
  { value: "Sai Baba Automobile Financiers", label: "Sai Baba Automobile Financiers" },
  { value: "Sai Baba Hire Purchese Ltd", label: "Sai Baba Hire Purchese Ltd" },
  { value: "Sai Balaji Automobile Finance", label: "Sai Balaji Automobile Finance" },
  { value: "Sai Balaji Finance", label: "Sai Balaji Finance" },
  { value: "Sai Balaji Hp", label: "Sai Balaji Hp" },
  { value: "Sai Balaji Hp And Finance", label: "Sai Balaji Hp And Finance" },
  { value: "Sai Bhagaya Lakshmi Auto Finance", label: "Sai Bhagaya Lakshmi Auto Finance" },
  { value: "Sai Bhavani", label: "Sai Bhavani" },
  { value: "Sai Bhavani Finance Ltd", label: "Sai Bhavani Finance Ltd" },
  { value: "Sai Bhavani Hp", label: "Sai Bhavani Hp" },
  { value: "Sai Bhavani Hp & Finance", label: "Sai Bhavani Hp & Finance" },
  { value: "Sai Consumer Promoters Ltd Tosham", label: "Sai Consumer Promoters Ltd Tosham" },
  { value: "Sai Darshan Co Credit So Ltd", label: "Sai Darshan Co Credit So Ltd" },
  { value: "Sai Datta Nagari Sahakari Pat Sanstha Maryadit", label: "Sai Datta Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Sai Durga Finance", label: "Sai Durga Finance" },
  { value: "Sai Finance And Leasing (R.) Sulepeth", label: "Sai Finance And Leasing (R.) Sulepeth" },
  { value: "Sai Finance Corporation", label: "Sai Finance Corporation" },
  { value: "Sai Financial Services", label: "Sai Financial Services" },
  { value: "Sai Ganesh Auto Financiers", label: "Sai Ganesh Auto Financiers" },
  { value: "Sai Ganesh Credit Co-Operative Soc.Ltd.", label: "Sai Ganesh Credit Co-Operative Soc.Ltd." },
  { value: "Sai Ganesh Enterprises", label: "Sai Ganesh Enterprises" },
  { value: "Sai Ganesh Sowharda Credit Co-Op Ltd", label: "Sai Ganesh Sowharda Credit Co-Op Ltd" },
  { value: "Sai Gokul Auto And General Finance", label: "Sai Gokul Auto And General Finance" },
  { value: "Sai Gokul Finance", label: "Sai Gokul Finance" },
  { value: "Sai Gokul Motors ( R )", label: "Sai Gokul Motors ( R )" },
  { value: "Sai Hardhik", label: "Sai Hardhik" },
  { value: "Sai Infrastructure Finance Limited", label: "Sai Infrastructure Finance Limited" },
  { value: "Sai Krishna A/F", label: "Sai Krishna A/F" },
  { value: "Sai Krishna Auto Finance", label: "Sai Krishna Auto Finance" },
  { value: "Sai Krishna Finance", label: "Sai Krishna Finance" },
  { value: "Sai Krishna Finance Corporation", label: "Sai Krishna Finance Corporation" },
  { value: "Sai Krupa Automatives Bhalki", label: "Sai Krupa Automatives Bhalki" },
  { value: "Sai Krupa Financial Investment Corporation", label: "Sai Krupa Financial Investment Corporation" },
  { value: "Sai Lakshmi Finance Company", label: "Sai Lakshmi Finance Company" },
  { value: "Sai Laxmi Prasanna Hire Purchace Finance", label: "Sai Laxmi Prasanna Hire Purchace Finance" },
  { value: "Sai Manikanta Finance", label: "Sai Manikanta Finance" },
  { value: "Sai Motors Multi Service", label: "Sai Motors Multi Service" },
  { value: "Sai Pooja Finance Corporation", label: "Sai Pooja Finance Corporation" },
  { value: "Sai Prerana Gr Br Sheti Sah Patsanstha", label: "Sai Prerana Gr Br Sheti Sah Patsanstha" },
  { value: "Sai Prerna Co Op Credit Society Ltd", label: "Sai Prerna Co Op Credit Society Ltd" },
  { value: "Sai Prerna Nag Sah Pathsanstha Ltd", label: "Sai Prerna Nag Sah Pathsanstha Ltd" },
  { value: "Sai Raj Auto Finance", label: "Sai Raj Auto Finance" },
  { value: "Sai Ram Auto And General Finance", label: "Sai Ram Auto And General Finance" },
  { value: "Sai Ram Auto Finance", label: "Sai Ram Auto Finance" },
  { value: "Sai Rama Hi Pur Finance", label: "Sai Rama Hi Pur Finance" },
  { value: "Sai Roja Financiers", label: "Sai Roja Financiers" },
  { value: "Sai Sadguru Finance Corporation", label: "Sai Sadguru Finance Corporation" },
  { value: "Sai Sakthi Auto Finance", label: "Sai Sakthi Auto Finance" },
  { value: "Sai Samarth Nagari Sahakari Patsanstha", label: "Sai Samarth Nagari Sahakari Patsanstha" },
  { value: "Sai Samarth Nagri Sahakari Patsanstha Maryadit Shirdi", label: "Sai Samarth Nagri Sahakari Patsanstha Maryadit Shirdi" },
  { value: "Sai Sangameshwara Auto Finance", label: "Sai Sangameshwara Auto Finance" },
  { value: "Sai Saran Associates", label: "Sai Saran Associates" },
  { value: "Sai Sarvani Financiers", label: "Sai Sarvani Financiers" },
  { value: "Sai Savali Sahakari Patpethi Maryadit", label: "Sai Savali Sahakari Patpethi Maryadit" },
  { value: "Sai Shakti Gramin Bigar Sheti Sah. Pat. Sanstha", label: "Sai Shakti Gramin Bigar Sheti Sah. Pat. Sanstha" },
  { value: "Sai Shivani Auto Finance", label: "Sai Shivani Auto Finance" },
  { value: "Sai Sri Auto Mobile Financier", label: "Sai Sri Auto Mobile Financier" },
  { value: "Sai Sri Hire Purchase & Finance", label: "Sai Sri Hire Purchase & Finance" },
  { value: "Sai Srinivasa Automobile Finance", label: "Sai Srinivasa Automobile Finance" },
  { value: "Sai Teja Auto Finance", label: "Sai Teja Auto Finance" },
  { value: "Sai Urban Co-Operative Bank Ltd", label: "Sai Urban Co-Operative Bank Ltd" },
  { value: "Sai Utakarsh Patsanstha", label: "Sai Utakarsh Patsanstha" },
  { value: "Sai Vaishnavi Auto Finance", label: "Sai Vaishnavi Auto Finance" },
  { value: "Sai Vaishnavi Auto Finance Co Ltd", label: "Sai Vaishnavi Auto Finance Co Ltd" },
  { value: "Sai Venkata Varalakshmi Auto Finance", label: "Sai Venkata Varalakshmi Auto Finance" },
  { value: "Sai Venkateshwara Auto Finance", label: "Sai Venkateshwara Auto Finance" },
  { value: "Sai Vinayaka Enterprises", label: "Sai Vinayaka Enterprises" },
  { value: "Saibaba Nagari Sahakari Bank Ltd.", label: "Saibaba Nagari Sahakari Bank Ltd." },
  { value: "Saidapet Co-Operative Bank Ltd.", label: "Saidapet Co-Operative Bank Ltd." },
  { value: "Saihaj Enterprises", label: "Saihaj Enterprises" },
  { value: "Saikrapa Gramin Bigar Sheti Sah Pat Sanstha", label: "Saikrapa Gramin Bigar Sheti Sah Pat Sanstha" },
  { value: "Saikripa Sahakari Pat Ltd", label: "Saikripa Sahakari Pat Ltd" },
  { value: "Saikruapa Gramin Bigar Sheti Sati Pat Sanstha", label: "Saikruapa Gramin Bigar Sheti Sati Pat Sanstha" },
  { value: "Saikrupa Gramin Bigar Sheti Sati Pat Sanstha", label: "Saikrupa Gramin Bigar Sheti Sati Pat Sanstha" },
  { value: "Saikrupa Patsanstha", label: "Saikrupa Patsanstha" },
  { value: "Sail Alloy Steel Plant", label: "Sail Alloy Steel Plant" },
  { value: "Sail B. S. L", label: "Sail B. S. L" },
  { value: "Sail B.S.P", label: "Sail B.S.P" },
  { value: "Sail Bhilai", label: "Sail Bhilai" },
  { value: "Sail Refractory Unit", label: "Sail Refractory Unit" },
  { value: "Sail/Dsp", label: "Sail/Dsp" },
  { value: "Sailaxmi Gramin Bigarsheti Sahakari Patsanstha Maryadit", label: "Sailaxmi Gramin Bigarsheti Sahakari Patsanstha Maryadit" },
  { value: "Sailes Investments", label: "Sailes Investments" },
  { value: "Sailesh Investments", label: "Sailesh Investments" },
  { value: "Sailesh Kumar Bokariya", label: "Sailesh Kumar Bokariya" },
  { value: "Sailila Group", label: "Sailila Group" },
  { value: "Sainath Auto Finance", label: "Sainath Auto Finance" },
  { value: "Sainath Finance Co", label: "Sainath Finance Co" },
  { value: "Sainath Hp And Finance", label: "Sainath Hp And Finance" },
  { value: "Sainath Nagari Patsnstha.", label: "Sainath Nagari Patsnstha." },
  { value: "Saineshwar Pathsanstha", label: "Saineshwar Pathsanstha" },
  { value: "Saini Auto Care", label: "Saini Auto Care" },
  { value: "Saini Auto Care", label: "Saini Auto Care" },
  { value: "Saini Trading Co", label: "Saini Trading Co" },
  { value: "Sainik Finance And Industri", label: "Sainik Finance And Industri" },
  { value: "Sainik Sahakari Bank Ltd.", label: "Sainik Sahakari Bank Ltd." },
  { value: "Saint Gobain Glass India Ltd", label: "Saint Gobain Glass India Ltd" },
  { value: "Sairaj Gramin Bigar Sheti Sah Pat San", label: "Sairaj Gramin Bigar Sheti Sah Pat San" },
  { value: "Saiseva Gramin Bigersheti Sah Pat Sanstha", label: "Saiseva Gramin Bigersheti Sah Pat Sanstha" },
  { value: "Saitomo Fin Ser Ltd", label: "Saitomo Fin Ser Ltd" },
  { value: "Sajan Credit Corporation", label: "Sajan Credit Corporation" },
  { value: "Sajan Credit Finance", label: "Sajan Credit Finance" },
  { value: "Sajan Hire Purchase Pvt. Ltd.", label: "Sajan Hire Purchase Pvt. Ltd." },
  { value: "Sajjan Finance", label: "Sajjan Finance" },
  { value: "Sajjan Kaj Singvi", label: "Sajjan Kaj Singvi" },
  { value: "Sajjan Raj Anchalia Automobiles Finance", label: "Sajjan Raj Anchalia Automobiles Finance" },
  { value: "Sajjan Raj Singvi", label: "Sajjan Raj Singvi" },
  { value: "Sajjan Roonwal", label: "Sajjan Roonwal" },
  { value: "Sajjanraj Singvi And Sons", label: "Sajjanraj Singvi And Sons" },
  { value: "Sakambari Finance Corporation", label: "Sakambari Finance Corporation" },
  { value: "Sakiya Finance", label: "Sakiya Finance" },
  { value: "Saklecha Motor Finance", label: "Saklecha Motor Finance" },
  { value: "Sakosa Nagari Sah. Pathasanstha Ltd", label: "Sakosa Nagari Sah. Pathasanstha Ltd" },
  { value: "Sakshi Auto Finance", label: "Sakshi Auto Finance" },
  { value: "Sakthan Hire Purchase", label: "Sakthan Hire Purchase" },
  { value: "Sakthi  Finance", label: "Sakthi  Finance" },
  { value: "Sakthi Auto Finance", label: "Sakthi Auto Finance" },
  { value: "Sakthi Auto Financiers", label: "Sakthi Auto Financiers" },
  { value: "Sakthi Bankers", label: "Sakthi Bankers" },
  { value: "Sakthi Finance Corporation", label: "Sakthi Finance Corporation" },
  { value: "Sakthi Finance Ltd.", label: "Sakthi Finance Ltd." },
  { value: "Sakthi Financier", label: "Sakthi Financier" },
  { value: "Sakthi Ganapathi Auto Financiers", label: "Sakthi Ganapathi Auto Financiers" },
  { value: "Sakthi Leasing & Co", label: "Sakthi Leasing & Co" },
  { value: "Sakthi Murugan Finance", label: "Sakthi Murugan Finance" },
  { value: "Sakthi Priya Auto Financiers", label: "Sakthi Priya Auto Financiers" },
  { value: "Saktinagar S K U S Ltd,", label: "Saktinagar S K U S Ltd," },
  { value: "Sakura Bank Ltd", label: "Sakura Bank Ltd" },
  { value: "Sakura Bank Ltd.", label: "Sakura Bank Ltd." },
  { value: "Salabatpura Urben Co Op Ce Soc Ltd", label: "Salabatpura Urben Co Op Ce Soc Ltd" },
  { value: "Salasar Finance Corporation", label: "Salasar Finance Corporation" },
  { value: "Salasar Financiers", label: "Salasar Financiers" },
  { value: "Salem District Central Co-Operative Bank Ltd.", label: "Salem District Central Co-Operative Bank Ltd." },
  { value: "Salem Sri Senthil Andavar Finance", label: "Salem Sri Senthil Andavar Finance" },
  { value: "Salem Srikongu Finance", label: "Salem Srikongu Finance" },
  { value: "Salem Urban Co-Op Bank Ltd", label: "Salem Urban Co-Op Bank Ltd" },
  { value: "Salgaon Sanmitra Sahakari Patsanshta Ltd", label: "Salgaon Sanmitra Sahakari Patsanshta Ltd" },
  { value: "Salgaon Sanmitra Sahakari Patsanstha", label: "Salgaon Sanmitra Sahakari Patsanstha" },
  { value: "Salma Lathii Halik", label: "Salma Lathii Halik" },
  { value: "Saloni Enterprises", label: "Saloni Enterprises" },
  { value: "Saluja Finance Services Ltd", label: "Saluja Finance Services Ltd" },
  { value: "Saluja Traders & Finance", label: "Saluja Traders & Finance" },
  { value: "Saluja Traders Finance", label: "Saluja Traders Finance" },
  { value: "Samaja Seva Sahakari Bank Ltd", label: "Samaja Seva Sahakari Bank Ltd" },
  { value: "Samaja Seva Sahakari Bank Niyamitha", label: "Samaja Seva Sahakari Bank Niyamitha" },
  { value: "Samar Bahadur Singh", label: "Samar Bahadur Singh" },
  { value: "Samarth Finance", label: "Samarth Finance" },
  { value: "Samarth Gramin Bigar Shati Sahkari Pathsastha", label: "Samarth Gramin Bigar Shati Sahkari Pathsastha" },
  { value: "Samarth Gurukrupa Co-Op Credit Soc Ltd", label: "Samarth Gurukrupa Co-Op Credit Soc Ltd" },
  { value: "Samarth Mahila Gramin Bigar Sheti Sah. Patsanstha", label: "Samarth Mahila Gramin Bigar Sheti Sah. Patsanstha" },
  { value: "Samarth Nagari Sah. Pratyay Sanstha Maryadit.", label: "Samarth Nagari Sah. Pratyay Sanstha Maryadit." },
  { value: "Samarth Nagarik Sahakari Bank Ltd", label: "Samarth Nagarik Sahakari Bank Ltd" },
  { value: "Samarth Raghuveer Sah Pat Ltd", label: "Samarth Raghuveer Sah Pat Ltd" },
  { value: "Samarth Raghuveer Sahakari Patsanstha Ltd", label: "Samarth Raghuveer Sahakari Patsanstha Ltd" },
  { value: "Samarth Raghuveer Sahakari Patsanstha Maryadit", label: "Samarth Raghuveer Sahakari Patsanstha Maryadit" },
  { value: "Samarth Sahakari Bank Ltd Solapur", label: "Samarth Sahakari Bank Ltd Solapur" },
  { value: "Samarth Sahakari Bank Ltd.", label: "Samarth Sahakari Bank Ltd." },
  { value: "Samarth U Co-Op Credit Society Ltd", label: "Samarth U Co-Op Credit Society Ltd" },
  { value: "Samarth Urban Co-Operative Credit Society Ltd.", label: "Samarth Urban Co-Operative Credit Society Ltd." },
  { value: "Samartha Raghuveer Co-Op Soc Ltd", label: "Samartha Raghuveer Co-Op Soc Ltd" },
  { value: "Samasta Nagar Co-Op Bank Ltd", label: "Samasta Nagar Co-Op Bank Ltd" },
  { value: "Samata Finance", label: "Samata Finance" },
  { value: "Samata Finance Shiv Leela", label: "Samata Finance Shiv Leela" },
  { value: "Samata Gramin Bigarsheti Sah Pat Maryadit", label: "Samata Gramin Bigarsheti Sah Pat Maryadit" },
  { value: "Samata Nagari Sah Patsanstha Maryadit", label: "Samata Nagari Sah Patsanstha Maryadit" },
  { value: "Samata Nagari Sahakari Pat San Bank Ltd", label: "Samata Nagari Sahakari Pat San Bank Ltd" },
  { value: "Samata Sahakari Bank Ltd...", label: "Samata Sahakari Bank Ltd..." },
  { value: "Samatha Hire Purchase", label: "Samatha Hire Purchase" },
  { value: "Samatha Hire Purchase Finance", label: "Samatha Hire Purchase Finance" },
  { value: "Samatha Mahila Co.Operative Urban Bk.Ltd", label: "Samatha Mahila Co.Operative Urban Bk.Ltd" },
  { value: "Sambahv Auto Finance", label: "Sambahv Auto Finance" },
  { value: "Sambalpur District Central Co-Operative Bank Ltd.", label: "Sambalpur District Central Co-Operative Bank Ltd." },
  { value: "Sambandam Investment And Leasing Ltd.", label: "Sambandam Investment And Leasing Ltd." },
  { value: "Sambhaji Bhujbal Gramin Biger Sheti Sah Pat Sanstha Mydt", label: "Sambhaji Bhujbal Gramin Biger Sheti Sah Pat Sanstha Mydt" },
  { value: "Sambhar Salts Ltd.", label: "Sambhar Salts Ltd." },
  { value: "Sambhav Finance", label: "Sambhav Finance" },
  { value: "Sambhavnath Finance", label: "Sambhavnath Finance" },
  { value: "Sambok Leasing & Finance Co.", label: "Sambok Leasing & Finance Co." },
  { value: "Saminan & Co", label: "Saminan & Co" },
  { value: "Samir N Mehta", label: "Samir N Mehta" },
  { value: "Samm Co-Op Bank Ltd", label: "Samm Co-Op Bank Ltd" },
  { value: "Samnith & Co", label: "Samnith & Co" },
  { value: "Sampa Seva Sahkari Mandali Ltd", label: "Sampa Seva Sahkari Mandali Ltd" },
  { value: "Sampada Leasing", label: "Sampada Leasing" },
  { value: "Sampada Nagari Co-Op Path Mary", label: "Sampada Nagari Co-Op Path Mary" },
  { value: "Sampada Nagri Sahakari Patsanstha", label: "Sampada Nagri Sahakari Patsanstha" },
  { value: "Sampat Auto Finance", label: "Sampat Auto Finance" },
  { value: "Sampat Enterprises", label: "Sampat Enterprises" },
  { value: "Sampat Finance Corporation", label: "Sampat Finance Corporation" },
  { value: "Sampath Auto Finance", label: "Sampath Auto Finance" },
  { value: "Sampath Finance", label: "Sampath Finance" },
  { value: "Sampath Finance Corporation", label: "Sampath Finance Corporation" },
  { value: "Sampath Raj & Sons", label: "Sampath Raj & Sons" },
  { value: "Sampath Raj Jain", label: "Sampath Raj Jain" },
  { value: "Sampoorna Enterprises", label: "Sampoorna Enterprises" },
  { value: "Sampushta Kuries Pvt Ltd", label: "Sampushta Kuries Pvt Ltd" },
  { value: "Samra & Company", label: "Samra & Company" },
  { value: "Samra Finance And Properties Pvt. Ltd", label: "Samra Finance And Properties Pvt. Ltd" },
  { value: "Samra Leasing Ltd", label: "Samra Leasing Ltd" },
  { value: "Samrath Investments", label: "Samrath Investments" },
  { value: "Samro & Co", label: "Samro & Co" },
  { value: "Samruddhi Co-Operative Bank Ltd", label: "Samruddhi Co-Operative Bank Ltd" },
  { value: "Samrudhhi Sahakari Patsanstha", label: "Samrudhhi Sahakari Patsanstha" },
  { value: "Samta Gramin Bigar Sheti Sah. Path. Mangalwedha", label: "Samta Gramin Bigar Sheti Sah. Path. Mangalwedha" },
  { value: "Samta Motors Pvt Ltd", label: "Samta Motors Pvt Ltd" },
  { value: "Samta Nagari B.Sheti Sah. Patsantha", label: "Samta Nagari B.Sheti Sah. Patsantha" },
  { value: "Samta Sahakari Bank Seemit", label: "Samta Sahakari Bank Seemit" },
  { value: "Samta Surana", label: "Samta Surana" },
  { value: "Samudra Vividhoddesah Sahakara Sangha Lt", label: "Samudra Vividhoddesah Sahakara Sangha Lt" },
  { value: "Samyak Corporation", label: "Samyak Corporation" },
  { value: "Samyud Kshetriya Gramin Bank.", label: "Samyud Kshetriya Gramin Bank." },
  { value: "Samyut Kshetriya Gramin Bk,", label: "Samyut Kshetriya Gramin Bk," },
  { value: "Sanaay Kumar Financier", label: "Sanaay Kumar Financier" },
  { value: "Sanand Urban Co-Op Bank Ltd", label: "Sanand Urban Co-Op Bank Ltd" },
  { value: "Sanand Urban Co-Operative Bank Ltd", label: "Sanand Urban Co-Operative Bank Ltd" },
  { value: "Sanawd Nagarik Sah.Bank Mydt", label: "Sanawd Nagarik Sah.Bank Mydt" },
  { value: "Sanbok Leasing And Finance", label: "Sanbok Leasing And Finance" },
  { value: "Sanchar Gramin B S P S", label: "Sanchar Gramin B S P S" },
  { value: "Sancheti Finance", label: "Sancheti Finance" },
  { value: "Sandeep Auto Care", label: "Sandeep Auto Care" },
  { value: "Sandeep Auto Finance", label: "Sandeep Auto Finance" },
  { value: "Sandeep Bajaj", label: "Sandeep Bajaj" },
  { value: "Sandeep Bhatu Hadap", label: "Sandeep Bhatu Hadap" },
  { value: "Sandeep Finance", label: "Sandeep Finance" },
  { value: "Sandeep Lalwani Huf", label: "Sandeep Lalwani Huf" },
  { value: "Sandeep Sethi", label: "Sandeep Sethi" },
  { value: "Sandesh Finance", label: "Sandesh Finance" },
  { value: "Sandesh Finance Amar Kutty", label: "Sandesh Finance Amar Kutty" },
  { value: "Sandhu Auto Deposits Pvt. Ltd.", label: "Sandhu Auto Deposits Pvt. Ltd." },
  { value: "Sandhu Fin And Leasing Co Pvt Ltd", label: "Sandhu Fin And Leasing Co Pvt Ltd" },
  { value: "Sandhu Finance", label: "Sandhu Finance" },
  { value: "Sandhu Motor Financier", label: "Sandhu Motor Financier" },
  { value: "Sandhya Motors And Financers", label: "Sandhya Motors And Financers" },
  { value: "Sandi Reddy Adi Lakshmi Financiers", label: "Sandi Reddy Adi Lakshmi Financiers" },
  { value: "Sandi Reddy Narayana Automobile Finance", label: "Sandi Reddy Narayana Automobile Finance" },
  { value: "Sandi Reddy Raja Narayana Automobile Financiers", label: "Sandi Reddy Raja Narayana Automobile Financiers" },
  { value: "Sandi Reddy Venkata Lakshmi Auto Mobile Financiers", label: "Sandi Reddy Venkata Lakshmi Auto Mobile Financiers" },
  { value: "Sandip Kumar Bansal", label: "Sandip Kumar Bansal" },
  { value: "Sandireddy Venkata Lakshmi A/F", label: "Sandireddy Venkata Lakshmi A/F" },
  { value: "Sandur Pattan Souharda Sahakari Bank Niyamitha", label: "Sandur Pattan Souharda Sahakari Bank Niyamitha" },
  { value: "Sandur Pattana Sahakara Bank Ltd.", label: "Sandur Pattana Sahakara Bank Ltd." },
  { value: "Sandur Pattana Sahakara Bank Niyamitha", label: "Sandur Pattana Sahakara Bank Niyamitha" },
  { value: "Sandur Pattana Sohharda Sahakari Bank Ni", label: "Sandur Pattana Sohharda Sahakari Bank Ni" },
  { value: "Sandur Pattana Souharda Bank Niyamat", label: "Sandur Pattana Souharda Bank Niyamat" },
  { value: "Sandur Pattana Souharda Sahakari Bank", label: "Sandur Pattana Souharda Sahakari Bank" },
  { value: "Sandy Leasing Pvt Ltd", label: "Sandy Leasing Pvt Ltd" },
  { value: "Sane Guruji Housing Fin Co Ltd", label: "Sane Guruji Housing Fin Co Ltd" },
  { value: "Sane Guruji Nagari Patsanstha", label: "Sane Guruji Nagari Patsanstha" },
  { value: "Sangali Vaibhav Co Op Credit Soc Ltd", label: "Sangali Vaibhav Co Op Credit Soc Ltd" },
  { value: "Sangam Auto", label: "Sangam Auto" },
  { value: "Sangam Car Bazar", label: "Sangam Car Bazar" },
  { value: "Sangam Co Op Credit Society Ltd.", label: "Sangam Co Op Credit Society Ltd." },
  { value: "Sangam Finance", label: "Sangam Finance" },
  { value: "Sangam Financing And Trading Corporation", label: "Sangam Financing And Trading Corporation" },
  { value: "Sangam Hire Purchase Co.Ltd.", label: "Sangam Hire Purchase Co.Ltd." },
  { value: "Sangam Investment", label: "Sangam Investment" },
  { value: "Sangam Motor Fin.", label: "Sangam Motor Fin." },
  { value: "Sangameshwar Auto Finance", label: "Sangameshwar Auto Finance" },
  { value: "Sangameshwar Gramin Big Sheti Path Santh", label: "Sangameshwar Gramin Big Sheti Path Santh" },
  { value: "Sangameshwar Motors", label: "Sangameshwar Motors" },
  { value: "Sangameshwar Multipurpose Co-Operative Society", label: "Sangameshwar Multipurpose Co-Operative Society" },
  { value: "Sangameshwar Souharda Pattin Sahakari Niyamit", label: "Sangameshwar Souharda Pattin Sahakari Niyamit" },
  { value: "Sangamner Merchants Co-Op Bank Ltd", label: "Sangamner Merchants Co-Op Bank Ltd" },
  { value: "Sangat Motor", label: "Sangat Motor" },
  { value: "Sangati Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit", label: "Sangati Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit" },
  { value: "Sangeeta Devi", label: "Sangeeta Devi" },
  { value: "Sangeeta Finance", label: "Sangeeta Finance" },
  { value: "Sangeeth Finance (Tn) Ltd.", label: "Sangeeth Finance (Tn) Ltd." },
  { value: "Sangeetha Auto Finance", label: "Sangeetha Auto Finance" },
  { value: "Sangeetha Bhandari", label: "Sangeetha Bhandari" },
  { value: "Sangeetha Investments", label: "Sangeetha Investments" },
  { value: "Sangeetha Jain Automobile Finance", label: "Sangeetha Jain Automobile Finance" },
  { value: "Sangeetha Singhi", label: "Sangeetha Singhi" },
  { value: "Sanghamithra Finance", label: "Sanghamithra Finance" },
  { value: "Sanghamithra Rural Financial Services", label: "Sanghamithra Rural Financial Services" },
  { value: "Sanghamitra Bank", label: "Sanghamitra Bank" },
  { value: "Sanghamitra Bank", label: "Sanghamitra Bank" },
  { value: "Sanghavi Finance", label: "Sanghavi Finance" },
  { value: "Sanghavi Kalpana Pathik", label: "Sanghavi Kalpana Pathik" },
  { value: "Sanghrsh Financial Corporation", label: "Sanghrsh Financial Corporation" },
  { value: "Sanghvi Investment Company Ltd", label: "Sanghvi Investment Company Ltd" },
  { value: "Sanghvi Investments And Leasing Ltd", label: "Sanghvi Investments And Leasing Ltd" },
  { value: "Sangita Bhartia", label: "Sangita Bhartia" },
  { value: "Sangli Bank Ltd.", label: "Sangli Bank Ltd." },
  { value: "Sangli Dist Primary Teachers Co-Op Bk Lt", label: "Sangli Dist Primary Teachers Co-Op Bk Lt" },
  { value: "Sangli Dist. Cent. Co-Op. Bank Ltd.", label: "Sangli Dist. Cent. Co-Op. Bank Ltd." },
  { value: "Sangli Dist. Central Co-Op. Bank Ltd.", label: "Sangli Dist. Central Co-Op. Bank Ltd." },
  { value: "Sangli Distprimary Teachers Co-Op Bank Ltd", label: "Sangli Distprimary Teachers Co-Op Bank Ltd" },
  { value: "Sangli District Central Co-Operative Bank Ltd.", label: "Sangli District Central Co-Operative Bank Ltd." },
  { value: "Sangli Janata Sahakari Bank Ltd", label: "Sangli Janata Sahakari Bank Ltd" },
  { value: "Sangli Janata Sahakari Bank Ltd.", label: "Sangli Janata Sahakari Bank Ltd." },
  { value: "Sangli Sahakari Bank Ltd.", label: "Sangli Sahakari Bank Ltd." },
  { value: "Sangli Timber Merchants N S P Maryadit", label: "Sangli Timber Merchants N S P Maryadit" },
  { value: "Sangli Urban Co-Op Bank Ltd", label: "Sangli Urban Co-Op Bank Ltd" },
  { value: "Sangli Urban Coop Bank Ltd", label: "Sangli Urban Coop Bank Ltd" },
  { value: "Sangli Vaibhav Co Op Bank Ltd", label: "Sangli Vaibhav Co Op Bank Ltd" },
  { value: "Sangli Vaibhav Co Op Credit  Soc", label: "Sangli Vaibhav Co Op Credit  Soc" },
  { value: "Sangli Zilla Maratha Nagari Sahakari Path Sanstha", label: "Sangli Zilla Maratha Nagari Sahakari Path Sanstha" },
  { value: "Sangola Urban Co-Op Bank Ltd", label: "Sangola Urban Co-Op Bank Ltd" },
  { value: "Sangola Urban Co-Op Bank Ltd", label: "Sangola Urban Co-Op Bank Ltd" },
  { value: "Sangola Urban Co-Op.Bank Ltd Sangola", label: "Sangola Urban Co-Op.Bank Ltd Sangola" },
  { value: "Sangram Motor Finance Co", label: "Sangram Motor Finance Co" },
  { value: "Sangrila Financial Services Ltd", label: "Sangrila Financial Services Ltd" },
  { value: "Sangroma Motor Financer", label: "Sangroma Motor Financer" },
  { value: "Sangroma Motors Finance India Ltd.", label: "Sangroma Motors Finance India Ltd." },
  { value: "Sangrur District Central Co-Operative Bank Ltd.", label: "Sangrur District Central Co-Operative Bank Ltd." },
  { value: "Sangyo Impex", label: "Sangyo Impex" },
  { value: "Sanikrupa Auto Finance", label: "Sanikrupa Auto Finance" },
  { value: "Sanjai Finance", label: "Sanjai Finance" },
  { value: "Sanjai Finance", label: "Sanjai Finance" },
  { value: "Sanjana Finance", label: "Sanjana Finance" },
  { value: "Sanjana Finance Corporation", label: "Sanjana Finance Corporation" },
  { value: "Sanjawani Nagari Sh.Pat Sanstha.", label: "Sanjawani Nagari Sh.Pat Sanstha." },
  { value: "Sanjay Auto Finance", label: "Sanjay Auto Finance" },
  { value: "Sanjay Bahuguna", label: "Sanjay Bahuguna" },
  { value: "Sanjay Bhansali", label: "Sanjay Bhansali" },
  { value: "Sanjay Dungarwal", label: "Sanjay Dungarwal" },
  { value: "Sanjay Finance", label: "Sanjay Finance" },
  { value: "Sanjay Finance Corporation", label: "Sanjay Finance Corporation" },
  { value: "Sanjay Gandhi Post Graduate Institute Of Medical Sciences", label: "Sanjay Gandhi Post Graduate Institute Of Medical Sciences" },
  { value: "Sanjay Goyal", label: "Sanjay Goyal" },
  { value: "Sanjay Jain", label: "Sanjay Jain" },
  { value: "Sanjay Kumar & Sunil Kumar", label: "Sanjay Kumar & Sunil Kumar" },
  { value: "Sanjay Kumar Bhardwaj", label: "Sanjay Kumar Bhardwaj" },
  { value: "Sanjay Kumar Bodadiya", label: "Sanjay Kumar Bodadiya" },
  { value: "Sanjay Kumar Bokadiya", label: "Sanjay Kumar Bokadiya" },
  { value: "Sanjay Kumar Singhvi", label: "Sanjay Kumar Singhvi" },
  { value: "Sanjay M Mehta Huf", label: "Sanjay M Mehta Huf" },
  { value: "Sanjay Mehta (Huf)", label: "Sanjay Mehta (Huf)" },
  { value: "Sanjeev Finance", label: "Sanjeev Finance" },
  { value: "Sanjeev Kumar", label: "Sanjeev Kumar" },
  { value: "Sanjeev Kumar Finance", label: "Sanjeev Kumar Finance" },
  { value: "Sanjeev Motors", label: "Sanjeev Motors" },
  { value: "Sanjeevani Urban Co-Op Bank Ltd", label: "Sanjeevani Urban Co-Op Bank Ltd" },
  { value: "Sanjivanee Nagari Sah Pat Ltd", label: "Sanjivanee Nagari Sah Pat Ltd" },
  { value: "Sanjivani Gramin Bigar Shetti Sahakari Pathsanstha Maryadit", label: "Sanjivani Gramin Bigar Shetti Sahakari Pathsanstha Maryadit" },
  { value: "Sanjog Auto Finance", label: "Sanjog Auto Finance" },
  { value: "Sanju Kumar Finance", label: "Sanju Kumar Finance" },
  { value: "Sankala Finance", label: "Sankala Finance" },
  { value: "Sankali Credit Society", label: "Sankali Credit Society" },
  { value: "Sankeshwar Finance", label: "Sankeshwar Finance" },
  { value: "Sankeshwara Finance", label: "Sankeshwara Finance" },
  { value: "Sankeshwara Investment", label: "Sankeshwara Investment" },
  { value: "Sanket Financeri Reud", label: "Sanket Financeri Reud" },
  { value: "Sanket Financers Rud", label: "Sanket Financers Rud" },
  { value: "Sanket Financiers", label: "Sanket Financiers" },
  { value: "Sanket Leasing Gulbarga", label: "Sanket Leasing Gulbarga" },
  { value: "Sankheda Nag.Sah. Bank Ltd.", label: "Sankheda Nag.Sah. Bank Ltd." },
  { value: "Sankheshwar Finance", label: "Sankheshwar Finance" },
  { value: "Sankheshwar Nagari Sahkari Pathsanstha", label: "Sankheshwar Nagari Sahkari Pathsanstha" },
  { value: "Sanmathi Credit Op Society Ltd", label: "Sanmathi Credit Op Society Ltd" },
  { value: "Sanmathi Pattina Sahakara Sangha", label: "Sanmathi Pattina Sahakara Sangha" },
  { value: "Sanmati Credit Co Op Society", label: "Sanmati Credit Co Op Society" },
  { value: "Sanmati Sahakari Bank Ltd.,", label: "Sanmati Sahakari Bank Ltd.," },
  { value: "Sanmitra Co Op Credit Society Ltd", label: "Sanmitra Co Op Credit Society Ltd" },
  { value: "Sanmitra Mahila Nagri Sahakari Bank Mydt", label: "Sanmitra Mahila Nagri Sahakari Bank Mydt" },
  { value: "Sanmitra Nagari Sahakari Pat Sanstha Myd", label: "Sanmitra Nagari Sahakari Pat Sanstha Myd" },
  { value: "Sanmitra Nagri Sahakari Pat Sanstha Maryadit", label: "Sanmitra Nagri Sahakari Pat Sanstha Maryadit" },
  { value: "Sanmitra Urban Co-Op. Bank Ltd.", label: "Sanmitra Urban Co-Op. Bank Ltd." },
  { value: "Sanmllra Sahkari Bank Ltd", label: "Sanmllra Sahkari Bank Ltd" },
  { value: "Sanosra Juth Seva Sahakari Mandali", label: "Sanosra Juth Seva Sahakari Mandali" },
  { value: "Sansthanic Estate Finance Ltd", label: "Sansthanic Estate Finance Ltd" },
  { value: "Sansui Leasing And Fin (P) Ltd.", label: "Sansui Leasing And Fin (P) Ltd." },
  { value: "Sant Balaji Baba Gramin Bigar Sheti Pat Sanstha", label: "Sant Balaji Baba Gramin Bigar Sheti Pat Sanstha" },
  { value: "Sant Dadge Maharaj Pat Sanstha Maryadit", label: "Sant Dadge Maharaj Pat Sanstha Maryadit" },
  { value: "Sant Dnyaneshwar Nagari Sah Pat.", label: "Sant Dnyaneshwar Nagari Sah Pat." },
  { value: "Sant Dyaneshwar Sah. Patsanstha", label: "Sant Dyaneshwar Sah. Patsanstha" },
  { value: "Sant Eknath Maharaja Gramin Biger Sheti Sahakari Pathsanstha", label: "Sant Eknath Maharaja Gramin Biger Sheti Sahakari Pathsanstha" },
  { value: "Sant Gadge Baba Path Sanstha.", label: "Sant Gadge Baba Path Sanstha." },
  { value: "Sant Goraba Kaka Sahkari Pathsanstha", label: "Sant Goraba Kaka Sahkari Pathsanstha" },
  { value: "Sant Goraba Pat. San.", label: "Sant Goraba Pat. San." },
  { value: "Sant Gorobakaka Sahakari", label: "Sant Gorobakaka Sahakari" },
  { value: "Sant Investment Co.", label: "Sant Investment Co." },
  { value: "Sant Janardan Swami Nag. Sahkari Patsanstha Maryadit", label: "Sant Janardan Swami Nag. Sahkari Patsanstha Maryadit" },
  { value: "Sant Joseph Co-Op Credit Soc. Ltd.", label: "Sant Joseph Co-Op Credit Soc. Ltd." },
  { value: "Sant Nagebaba Pat Sanstha Pathardi", label: "Sant Nagebaba Pat Sanstha Pathardi" },
  { value: "Sant Nagebaba Sah Pat Sanstha", label: "Sant Nagebaba Sah Pat Sanstha" },
  { value: "Sant Namdev Nagari Sahakari Pat Sanstha", label: "Sant Namdev Nagari Sahakari Pat Sanstha" },
  { value: "Sant Rohidas Charma Udyog And Charmakar Mahamandal Maryadit", label: "Sant Rohidas Charma Udyog And Charmakar Mahamandal Maryadit" },
  { value: "Sant Rohidas Leather Inds & Charmakar Dep Co Ltd", label: "Sant Rohidas Leather Inds & Charmakar Dep Co Ltd" },
  { value: "Sant Sopankaka Sahakari Bank Ltd,", label: "Sant Sopankaka Sahakari Bank Ltd," },
  { value: "Sant Tukaram Nagari Sahkari Path Sanstha", label: "Sant Tukaram Nagari Sahkari Path Sanstha" },
  { value: "Sant Wamanbhav Sah Path Sanstha", label: "Sant Wamanbhav Sah Path Sanstha" },
  { value: "Santa Ananda Dhumal Patsanstha", label: "Santa Ananda Dhumal Patsanstha" },
  { value: "Santh Dnyaneshwar Gramin Bigar Sheti", label: "Santh Dnyaneshwar Gramin Bigar Sheti" },
  { value: "Santha Devi Financier", label: "Santha Devi Financier" },
  { value: "Santhal Pargana Gramin Bank", label: "Santhal Pargana Gramin Bank" },
  { value: "Santhal Parganas Gramin Bank", label: "Santhal Parganas Gramin Bank" },
  { value: "Santhom Auto Finance", label: "Santhom Auto Finance" },
  { value: "Santhos Surana", label: "Santhos Surana" },
  { value: "Santhosh Enterprises", label: "Santhosh Enterprises" },
  { value: "Santhosh Finance Corporation", label: "Santhosh Finance Corporation" },
  { value: "Santhosh Kanwar Kothari", label: "Santhosh Kanwar Kothari" },
  { value: "Santhoush Finance", label: "Santhoush Finance" },
  { value: "Santkrupa Auto Finance", label: "Santkrupa Auto Finance" },
  { value: "Santkrupa Co-Op Credit Society Ltd", label: "Santkrupa Co-Op Credit Society Ltd" },
  { value: "Santosh Dev Dungarwal", label: "Santosh Dev Dungarwal" },
  { value: "Santosh Devi Finance", label: "Santosh Devi Finance" },
  { value: "Santosh Devi Lunawat", label: "Santosh Devi Lunawat" },
  { value: "Santosh Finance", label: "Santosh Finance" },
  { value: "Santosh Finvest Pvt Ltd", label: "Santosh Finvest Pvt Ltd" },
  { value: "Santosh Kanwar Kothari Financear", label: "Santosh Kanwar Kothari Financear" },
  { value: "Santosh Mahadeo Talapalli", label: "Santosh Mahadeo Talapalli" },
  { value: "Santosh Motors", label: "Santosh Motors" },
  { value: "Santosh Sham Saka", label: "Santosh Sham Saka" },
  { value: "Santosh Shrivastava", label: "Santosh Shrivastava" },
  { value: "Santosh V Sawant", label: "Santosh V Sawant" },
  { value: "Santosh Verma", label: "Santosh Verma" },
  { value: "Santosh Vishnu Sinkar", label: "Santosh Vishnu Sinkar" },
  { value: "Santoshdevi Dungarwala", label: "Santoshdevi Dungarwala" },
  { value: "Santragachi Cooperative Bank Ltd", label: "Santragachi Cooperative Bank Ltd" },
  { value: "Santrampur Urban Coop Bank Ltd", label: "Santrampur Urban Coop Bank Ltd" },
  { value: "Sanumuka Finance", label: "Sanumuka Finance" },
  { value: "Sanwa Bank Ltd.", label: "Sanwa Bank Ltd." },
  { value: "Sanyakt Sahayak Sahakari Mandli Ltd", label: "Sanyakt Sahayak Sahakari Mandli Ltd" },
  { value: "Sanyut Kshetriya Gramin Bank", label: "Sanyut Kshetriya Gramin Bank" },
  { value: "Sapanak Kumar Finance", label: "Sapanak Kumar Finance" },
  { value: "Sapna Bagmar", label: "Sapna Bagmar" },
  { value: "Sapna Fabricator And Finance Co Ltd", label: "Sapna Fabricator And Finance Co Ltd" },
  { value: "Sapta Shrunegi Co Op Credit Soc Ltd", label: "Sapta Shrunegi Co Op Credit Soc Ltd" },
  { value: "Saptagiri  Pattana Souharda Sahakari Niyamita", label: "Saptagiri  Pattana Souharda Sahakari Niyamita" },
  { value: "Saptagiri Grameena Bank,", label: "Saptagiri Grameena Bank," },
  { value: "Saptagiri Gramin Bank,", label: "Saptagiri Gramin Bank," },
  { value: "Saptagiri Nagri Sahakari Patasanstha", label: "Saptagiri Nagri Sahakari Patasanstha" },
  { value: "Saptashringi Mata Mahila Gramin Big. Sheti Sah. Path. Ltd.", label: "Saptashringi Mata Mahila Gramin Big. Sheti Sah. Path. Ltd." },
  { value: "Saptashringi Nagari Sahakari Pat Sanstha", label: "Saptashringi Nagari Sahakari Pat Sanstha" },
  { value: "Saptashrungi Cooperative Credit Society Ltd", label: "Saptashrungi Cooperative Credit Society Ltd" },
  { value: "Saptashrungi Mahila Nagari Sahakari", label: "Saptashrungi Mahila Nagari Sahakari" },
  { value: "Saptashrungi Nagari Sahakari Patsanstha", label: "Saptashrungi Nagari Sahakari Patsanstha" },
  { value: "Sapthagiri Auto Finance", label: "Sapthagiri Auto Finance" },
  { value: "Sapthagiri Finance", label: "Sapthagiri Finance" },
  { value: "Sapthgiri Nagari Sahkari Pathsanstha", label: "Sapthgiri Nagari Sahkari Pathsanstha" },
  { value: "Sapthshrungi Nagri Sahakari Pathsanstha Maryadit", label: "Sapthshrungi Nagri Sahakari Pathsanstha Maryadit" },
  { value: "Saptshrungi Gramin Bigar Sahakari Path Sanstha", label: "Saptshrungi Gramin Bigar Sahakari Path Sanstha" },
  { value: "Sarachi Infrastructure Finance Ltd Kolkata..", label: "Sarachi Infrastructure Finance Ltd Kolkata.." },
  { value: "Saradha Associates", label: "Saradha Associates" },
  { value: "Saradha Financial", label: "Saradha Financial" },
  { value: "Saran Kshetriya Gramin Bank", label: "Saran Kshetriya Gramin Bank" },
  { value: "Sarangpura Co-Op Bank Ltd.", label: "Sarangpura Co-Op Bank Ltd." },
  { value: "Saransh Finance Co.", label: "Saransh Finance Co." },
  { value: "Saranya Auto Finance", label: "Saranya Auto Finance" },
  { value: "Saraogi Insurance Brokers Limited", label: "Saraogi Insurance Brokers Limited" },
  { value: "Saras Arban Co Op Credit Soc Ltd", label: "Saras Arban Co Op Credit Soc Ltd" },
  { value: "Saras Finance Company Ltd", label: "Saras Finance Company Ltd" },
  { value: "Saraspur Nagrik Co-Op Bank L", label: "Saraspur Nagrik Co-Op Bank L" },
  { value: "Saraspur Nagrik Co-Op Bank Ltd", label: "Saraspur Nagrik Co-Op Bank Ltd" },
  { value: "Saraspur Nagrik Co-Op. Bank Ltd", label: "Saraspur Nagrik Co-Op. Bank Ltd" },
  { value: "Saraspur Nagrik Sah. Bank", label: "Saraspur Nagrik Sah. Bank" },
  { value: "Saraswat Bank Ltd", label: "Saraswat Bank Ltd" },
  { value: "Saraswat Co Op Bank  Ltd", label: "Saraswat Co Op Bank  Ltd" },
  { value: "Saraswat Co-Op Bank Ltd", label: "Saraswat Co-Op Bank Ltd" },
  { value: "Saraswathy Ganesan Finance Co Ltd", label: "Saraswathy Ganesan Finance Co Ltd" },
  { value: "Saraswati  Coop Bank Ltd", label: "Saraswati  Coop Bank Ltd" },
  { value: "Saraswati Co-Op Credit Soc Ltd.", label: "Saraswati Co-Op Credit Soc Ltd." },
  { value: "Saraswati Finance Co", label: "Saraswati Finance Co" },
  { value: "Saraswati Industrial Syndicate", label: "Saraswati Industrial Syndicate" },
  { value: "Saraswati Leasing Finance", label: "Saraswati Leasing Finance" },
  { value: "Saraswati Sahakari Bank Ltd", label: "Saraswati Sahakari Bank Ltd" },
  { value: "Saraswati Sugar Mill Ltd.", label: "Saraswati Sugar Mill Ltd." },
  { value: "Saraswati Tractor", label: "Saraswati Tractor" },
  { value: "Sarathy Finance", label: "Sarathy Finance" },
  { value: "Saravana And Co", label: "Saravana And Co" },
  { value: "Saravana Auto Finance", label: "Saravana Auto Finance" },
  { value: "Saravana Credit Corporation", label: "Saravana Credit Corporation" },
  { value: "Saravana Finance", label: "Saravana Finance" },
  { value: "Saravana Finance Pvt Ltd", label: "Saravana Finance Pvt Ltd" },
  { value: "Sarawathi Kawar Financier", label: "Sarawathi Kawar Financier" },
  { value: "Sarayu Gramin Bank", label: "Sarayu Gramin Bank" },
  { value: "Sarayu Gramin Bank.", label: "Sarayu Gramin Bank." },
  { value: "Sarda Motor Finance", label: "Sarda Motor Finance" },
  { value: "Sarda Resources Pvt Ltd", label: "Sarda Resources Pvt Ltd" },
  { value: "Sarda Resources Pvt. Ltd.", label: "Sarda Resources Pvt. Ltd." },
  { value: "Sardana Leasing Of Finance Pvt Ltd", label: "Sardana Leasing Of Finance Pvt Ltd" },
  { value: "Sardar Bank Ltd", label: "Sardar Bank Ltd" },
  { value: "Sardar Bhiladawala Pardi Peoples Co-Operative Bank Ltd", label: "Sardar Bhiladawala Pardi Peoples Co-Operative Bank Ltd" },
  { value: "Sardar Bhiladwala Co Op Bank Ltd", label: "Sardar Bhiladwala Co Op Bank Ltd" },
  { value: "Sardar Bhiladwala Pardi People", label: "Sardar Bhiladwala Pardi People" },
  { value: "Sardar Bhiladwala Pardi Peoples Co Op Bank Ltd", label: "Sardar Bhiladwala Pardi Peoples Co Op Bank Ltd" },
  { value: "Sardar Bhiladwalla Pardi Peo. Cp. Bk Ltd", label: "Sardar Bhiladwalla Pardi Peo. Cp. Bk Ltd" },
  { value: "Sardar Bhilladwalla Pardi Peoples Co-Op Bank Ltd", label: "Sardar Bhilladwalla Pardi Peoples Co-Op Bank Ltd" },
  { value: "Sardar Bhilwada Pardi Peoples Cp.Bk.Ltd", label: "Sardar Bhilwada Pardi Peoples Cp.Bk.Ltd" },
  { value: "Sardar Co Op Bank Soc Ltd", label: "Sardar Co Op Bank Soc Ltd" },
  { value: "Sardar Cycle Store", label: "Sardar Cycle Store" },
  { value: "Sardar Finance Co", label: "Sardar Finance Co" },
  { value: "Sardar Kumari Finance Gandhinagar Bangalore", label: "Sardar Kumari Finance Gandhinagar Bangalore" },
  { value: "Sardar Patel Co-Operative Credit Society Ltd.", label: "Sardar Patel Co-Operative Credit Society Ltd." },
  { value: "Sardar Patel Seva Samaj Mandali Ltd.", label: "Sardar Patel Seva Samaj Mandali Ltd." },
  { value: "Sardar Peoples Co Op Bank", label: "Sardar Peoples Co Op Bank" },
  { value: "Sardar Singh Nagrik Sehkari Bank Marya.", label: "Sardar Singh Nagrik Sehkari Bank Marya." },
  { value: "Sardar Vallabhai Patel Nag Sah Path Maryadit", label: "Sardar Vallabhai Patel Nag Sah Path Maryadit" },
  { value: "Sardar Vallabhai Sahakari Bank Ltd", label: "Sardar Vallabhai Sahakari Bank Ltd" },
  { value: "Sardar Vallabhbhai Patel National Police Academy", label: "Sardar Vallabhbhai Patel National Police Academy" },
  { value: "Sardar Vallabhbhai Sahakari Bank Ltd.", label: "Sardar Vallabhbhai Sahakari Bank Ltd." },
  { value: "Sardarganj Mercantile Co Op Bank Ltd", label: "Sardarganj Mercantile Co Op Bank Ltd" },
  { value: "Sardarganj Mercantile Co-Op. Bank Ltd", label: "Sardarganj Mercantile Co-Op. Bank Ltd" },
  { value: "Sardarganj Merchants Co-Op Bank Ltd", label: "Sardarganj Merchants Co-Op Bank Ltd" },
  { value: "Sardarsingh Nagrik Sahakari Bank Mydt.", label: "Sardarsingh Nagrik Sahakari Bank Mydt." },
  { value: "Sardhar Juth Vikas Karya Sah Mandli Ltd", label: "Sardhar Juth Vikas Karya Sah Mandli Ltd" },
  { value: "Sardhar Juth Vividh Karyakari Sahkari Mandli Ltd", label: "Sardhar Juth Vividh Karyakari Sahkari Mandli Ltd" },
  { value: "Sare International Ltd", label: "Sare International Ltd" },
  { value: "Sarika Upadhyay", label: "Sarika Upadhyay" },
  { value: "Sarita Dungarwal", label: "Sarita Dungarwal" },
  { value: "Sarita Finance Ltd.", label: "Sarita Finance Ltd." },
  { value: "Saritha .P.R", label: "Saritha .P.R" },
  { value: "Saritha Auto Finance", label: "Saritha Auto Finance" },
  { value: "Saritha Devi Sisodia", label: "Saritha Devi Sisodia" },
  { value: "Saritha Surana", label: "Saritha Surana" },
  { value: "Sarjan Urban Co Op Bank", label: "Sarjan Urban Co Op Bank" },
  { value: "Sarjan Urban Co Op Credit And Consumer Finance", label: "Sarjan Urban Co Op Credit And Consumer Finance" },
  { value: "Sarjerao Dada Naik Shirala Sah Bank Ltd", label: "Sarjerao Dada Naik Shirala Sah Bank Ltd" },
  { value: "Sarjerao Naik Shirala Sahakari Bank Ltd.", label: "Sarjerao Naik Shirala Sahakari Bank Ltd." },
  { value: "Sarkar Maharshi Bhaskarrao Shingne Nagri Sahakari Pathsanstha", label: "Sarkar Maharshi Bhaskarrao Shingne Nagri Sahakari Pathsanstha" },
  { value: "Sarmik Sah Bank Ltd", label: "Sarmik Sah Bank Ltd" },
  { value: "Sarnath Finance Ltd.", label: "Sarnath Finance Ltd." },
  { value: "Saroj Devi", label: "Saroj Devi" },
  { value: "Saroj Jain", label: "Saroj Jain" },
  { value: "Saroj Kankaria Finance", label: "Saroj Kankaria Finance" },
  { value: "Saroj Tulsyan", label: "Saroj Tulsyan" },
  { value: "Saroja Bai Finance", label: "Saroja Bai Finance" },
  { value: "Saroja Investments", label: "Saroja Investments" },
  { value: "Saroja Kotari Investments Co Ltd", label: "Saroja Kotari Investments Co Ltd" },
  { value: "Sarsa Peoples Co-Op Bank Ltd", label: "Sarsa Peoples Co-Op Bank Ltd" },
  { value: "Sarsenapati Umbai Dabhade Nagari Sah Pathsanstha", label: "Sarsenapati Umbai Dabhade Nagari Sah Pathsanstha" },
  { value: "Sarswat Co-Op Bank Ltd", label: "Sarswat Co-Op Bank Ltd" },
  { value: "Sarswati Co-Op Creidt Soc Ltd", label: "Sarswati Co-Op Creidt Soc Ltd" },
  { value: "Sarswati Nagari Sah Path Sanstha", label: "Sarswati Nagari Sah Path Sanstha" },
  { value: "Sarswati Nagari Sahakari Pathsanstha", label: "Sarswati Nagari Sahakari Pathsanstha" },
  { value: "Sarv Up Gramin Bank", label: "Sarv Up Gramin Bank" },
  { value: "Sarva U.P. Gramin Bank", label: "Sarva U.P. Gramin Bank" },
  { value: "Sarva Uttar Pradesh Gramin Bank", label: "Sarva Uttar Pradesh Gramin Bank" },
  { value: "Sarvajanik Co Op Credit Soc Ltd", label: "Sarvajanik Co Op Credit Soc Ltd" },
  { value: "Sarvamangal Fin. Pvt Ltd", label: "Sarvamangal Fin. Pvt Ltd" },
  { value: "Sarvesh Automobiles Co.Ltd.", label: "Sarvesh Automobiles Co.Ltd." },
  { value: "Sarvesh Automobiles Finance", label: "Sarvesh Automobiles Finance" },
  { value: "Sarveshwar Auto Finance", label: "Sarveshwar Auto Finance" },
  { value: "Sarvoday Co Op Bank", label: "Sarvoday Co Op Bank" },
  { value: "Sarvoday Comm Co Op Bank", label: "Sarvoday Comm Co Op Bank" },
  { value: "Sarvoday Gramin Bigar Sheti Path Sanstha", label: "Sarvoday Gramin Bigar Sheti Path Sanstha" },
  { value: "Sarvoday Nagrik Co Op Bank Ltd", label: "Sarvoday Nagrik Co Op Bank Ltd" },
  { value: "Sarvoday Urben Co Op Cr Soc Ltd", label: "Sarvoday Urben Co Op Cr Soc Ltd" },
  { value: "Sarvodaya Commercial Co-Op Bank Ltd", label: "Sarvodaya Commercial Co-Op Bank Ltd" },
  { value: "Sarvodaya Finance", label: "Sarvodaya Finance" },
  { value: "Sarvodaya Mahil Co-Op Bank Ltd", label: "Sarvodaya Mahil Co-Op Bank Ltd" },
  { value: "Sarvodaya Nagari Sahakari Pathsanstha Maryadit", label: "Sarvodaya Nagari Sahakari Pathsanstha Maryadit" },
  { value: "Sarvodaya Sahakari Bank Ltd.", label: "Sarvodaya Sahakari Bank Ltd." },
  { value: "Sarvodaya Sahakari Saraphi Manadli", label: "Sarvodaya Sahakari Saraphi Manadli" },
  { value: "Sasaram-Bhabua Central Co-Op Bank Ltd", label: "Sasaram-Bhabua Central Co-Op Bank Ltd" },
  { value: "Sasi Rekha Finance", label: "Sasi Rekha Finance" },
  { value: "Sasikumar K S", label: "Sasikumar K S" },
  { value: "Sasirekha Finance", label: "Sasirekha Finance" },
  { value: "Saska Nagari Sahkari Path Sanstha", label: "Saska Nagari Sahkari Path Sanstha" },
  { value: "Saskantha Guru Leasing", label: "Saskantha Guru Leasing" },
  { value: "Sastha Leasings", label: "Sastha Leasings" },
  { value: "Sasthamkotta Co-Op Agri & Rural Devp.Bank", label: "Sasthamkotta Co-Op Agri & Rural Devp.Bank" },
  { value: "Sasundock Matsyanganga Co Op Society", label: "Sasundock Matsyanganga Co Op Society" },
  { value: "Sat Simran Finance Co.", label: "Sat Simran Finance Co." },
  { value: "Satabdi Finance Pvt Ltd", label: "Satabdi Finance Pvt Ltd" },
  { value: "Satabdi Finlease", label: "Satabdi Finlease" },
  { value: "Satana Merchants Co-Op Bank Ltd", label: "Satana Merchants Co-Op Bank Ltd" },
  { value: "Satara Dist Merchants Co-Op Bank Ltd", label: "Satara Dist Merchants Co-Op Bank Ltd" },
  { value: "Satara District Central Co-Operative Bank Ltd.", label: "Satara District Central Co-Operative Bank Ltd." },
  { value: "Satara Mahila Co-Operative Bank", label: "Satara Mahila Co-Operative Bank" },
  { value: "Satara Sahakari Bank", label: "Satara Sahakari Bank" },
  { value: "Satara Sahakari Co Op Bank Ltd", label: "Satara Sahakari Co Op Bank Ltd" },
  { value: "Satayam Finstock Pvt Ltd", label: "Satayam Finstock Pvt Ltd" },
  { value: "Satbhavana Finance Pvt Ltd", label: "Satbhavana Finance Pvt Ltd" },
  { value: "Sate Bank Of India", label: "Sate Bank Of India" },
  { value: "Satguru Auto Finance", label: "Satguru Auto Finance" },
  { value: "Satguru Gramin Bigar Sheti Sah Pat San", label: "Satguru Gramin Bigar Sheti Sah Pat San" },
  { value: "Satguru Insurance Services", label: "Satguru Insurance Services" },
  { value: "Satguru Leasing And Finance Ltd", label: "Satguru Leasing And Finance Ltd" },
  { value: "Sathamba Peoples Co-Op Bank Ltd", label: "Sathamba Peoples Co-Op Bank Ltd" },
  { value: "Sathaneshwar Finance Co.", label: "Sathaneshwar Finance Co." },
  { value: "Satheesh Kumar", label: "Satheesh Kumar" },
  { value: "Sathiyam & Co", label: "Sathiyam & Co" },
  { value: "Sathya Investments And Finance", label: "Sathya Investments And Finance" },
  { value: "Satin Creditcare Network Ltd(Satin Leasing And Finance Ltd)", label: "Satin Creditcare Network Ltd(Satin Leasing And Finance Ltd)" },
  { value: "Satish Kumar Jain", label: "Satish Kumar Jain" },
  { value: "Satish Singhal", label: "Satish Singhal" },
  { value: "Satkar Auto Finance", label: "Satkar Auto Finance" },
  { value: "Satkar Motor Finance Co", label: "Satkar Motor Finance Co" },
  { value: "Satluj Jal Vidyut Nigam Ltd", label: "Satluj Jal Vidyut Nigam Ltd" },
  { value: "Satnam Co Op Credit Bank", label: "Satnam Co Op Credit Bank" },
  { value: "Satnam Co-Op Credit Soc.Ltd", label: "Satnam Co-Op Credit Soc.Ltd" },
  { value: "Satnam Fiancer", label: "Satnam Fiancer" },
  { value: "Satnam Finacers Saharanpur", label: "Satnam Finacers Saharanpur" },
  { value: "Satnam Motor Finance", label: "Satnam Motor Finance" },
  { value: "Satpal And Company", label: "Satpal And Company" },
  { value: "Satpuda Khsetriya Gramin Bank", label: "Satpuda Khsetriya Gramin Bank" },
  { value: "Satpuda Narmda Kshetriya Gramin Bank", label: "Satpuda Narmda Kshetriya Gramin Bank" },
  { value: "Satpura Kshtriya Gramin Bank", label: "Satpura Kshtriya Gramin Bank" },
  { value: "Satqura Auto Finance", label: "Satqura Auto Finance" },
  { value: "Sattari Urban Co-Op Cr Sty Ltd", label: "Sattari Urban Co-Op Cr Sty Ltd" },
  { value: "Satwant Singh Bammi", label: "Satwant Singh Bammi" },
  { value: "Saty Prakash Verma", label: "Saty Prakash Verma" },
  { value: "Satya Finance", label: "Satya Finance" },
  { value: "Satya Investment", label: "Satya Investment" },
  { value: "Satya Prakash Capital Investment Ltd", label: "Satya Prakash Capital Investment Ltd" },
  { value: "Satya Sai Auto Finance", label: "Satya Sai Auto Finance" },
  { value: "Satyam Co Op Cr Soc", label: "Satyam Co Op Cr Soc" },
  { value: "Satyam Fin Stock Pvt Ltd.", label: "Satyam Fin Stock Pvt Ltd." },
  { value: "Satyam Finance Company", label: "Satyam Finance Company" },
  { value: "Satyam Finance Pvt Ltd", label: "Satyam Finance Pvt Ltd" },
  { value: "Satyam Motor General Financier", label: "Satyam Motor General Financier" },
  { value: "Satyanarayan Kamalkumar", label: "Satyanarayan Kamalkumar" },
  { value: "Satyanarayana Swamy Auto Finance", label: "Satyanarayana Swamy Auto Finance" },
  { value: "Satyashodak Sahakari Bank Ltd", label: "Satyashodak Sahakari Bank Ltd" },
  { value: "Satyavati Co Op Credit Society.", label: "Satyavati Co Op Credit Society." },
  { value: "Satyavijay Nag Sah Pat Sanstha Maryadit", label: "Satyavijay Nag Sah Pat Sanstha Maryadit" },
  { value: "Satyawati Co Op Credit Soc Ltd", label: "Satyawati Co Op Credit Soc Ltd" },
  { value: "Satyawati Co Op Credit Soc Ltd", label: "Satyawati Co Op Credit Soc Ltd" },
  { value: "Saugat Sahakaripat Sanstha Maryadit Mumbai Bank", label: "Saugat Sahakaripat Sanstha Maryadit Mumbai Bank" },
  { value: "Saundatti Urban Co-Op. Credit Society Ltd", label: "Saundatti Urban Co-Op. Credit Society Ltd" },
  { value: "Saurabh Betala", label: "Saurabh Betala" },
  { value: "Saurabh Enterprise", label: "Saurabh Enterprise" },
  { value: "Saurabh Finance", label: "Saurabh Finance" },
  { value: "Saurashtra Co-Op Bank Ltd", label: "Saurashtra Co-Op Bank Ltd" },
  { value: "Saurastra Urban Co Op Cr Con Soc", label: "Saurastra Urban Co Op Cr Con Soc" },
  { value: "Savadi Kisan Seva Sahakari Mandali", label: "Savadi Kisan Seva Sahakari Mandali" },
  { value: "Savandapoor Finance And Leasing Co Ltd.", label: "Savandapoor Finance And Leasing Co Ltd." },
  { value: "Savani Financial Ltd", label: "Savani Financial Ltd" },
  { value: "Savanur Urban Co-Op Bank Ltd", label: "Savanur Urban Co-Op Bank Ltd" },
  { value: "Savanur Urban Co-Op Bank Ltd.", label: "Savanur Urban Co-Op Bank Ltd." },
  { value: "Savarkundla Nagarik Sahakari Bank Ltd", label: "Savarkundla Nagarik Sahakari Bank Ltd" },
  { value: "Savatamali Nagari Patsanstha Maryadit", label: "Savatamali Nagari Patsanstha Maryadit" },
  { value: "Savda Merchants Co Op Credit Society Maryadit.", label: "Savda Merchants Co Op Credit Society Maryadit." },
  { value: "Saveetha Investments", label: "Saveetha Investments" },
  { value: "Savera Credits (P) Ltd", label: "Savera Credits (P) Ltd" },
  { value: "Savery Transport Finance Co Pvt. Ltd.", label: "Savery Transport Finance Co Pvt. Ltd." },
  { value: "Savita Gupta", label: "Savita Gupta" },
  { value: "Savitha Investments", label: "Savitha Investments" },
  { value: "Savithri Auto Finance", label: "Savithri Auto Finance" },
  { value: "Savitribai Fule Pat Sanstha", label: "Savitribai Fule Pat Sanstha" },
  { value: "Savla Nagarik Sahakari Bank Ltd", label: "Savla Nagarik Sahakari Bank Ltd" },
  { value: "Savli Nagarik Sahakari Bank Ltd", label: "Savli Nagarik Sahakari Bank Ltd" },
  { value: "Savorit Finances Ltd.", label: "Savorit Finances Ltd." },
  { value: "Savrang Finance", label: "Savrang Finance" },
  { value: "Savta Mali Sahakari Patpedhi", label: "Savta Mali Sahakari Patpedhi" },
  { value: "Savtamali Nagrik Sahakari Patsanstha Maryadit", label: "Savtamali Nagrik Sahakari Patsanstha Maryadit" },
  { value: "Savtamali Pathosavstha Maryadit", label: "Savtamali Pathosavstha Maryadit" },
  { value: "Sawai Madhopur District Central Co-Operative Bank Ltd.", label: "Sawai Madhopur District Central Co-Operative Bank Ltd." },
  { value: "Sawai Madhopur K.S.B", label: "Sawai Madhopur K.S.B" },
  { value: "Sawai Madhopur Kendriya Sahakari Bank Lt", label: "Sawai Madhopur Kendriya Sahakari Bank Lt" },
  { value: "Sawai Madhopur Kendriya Sahakari Bk Ltd.", label: "Sawai Madhopur Kendriya Sahakari Bk Ltd." },
  { value: "Sawai Madhopur Urban Co-Op Bank L", label: "Sawai Madhopur Urban Co-Op Bank L" },
  { value: "Sawajan Co.Op Bank", label: "Sawajan Co.Op Bank" },
  { value: "Sawan Financers", label: "Sawan Financers" },
  { value: "Sawan Investments", label: "Sawan Investments" },
  { value: "Sawantwadi Urban Co-Op Bank Ltd", label: "Sawantwadi Urban Co-Op Bank Ltd" },
  { value: "Sawantwadi Urban Co-Op. Bank Ltd.", label: "Sawantwadi Urban Co-Op. Bank Ltd." },
  { value: "Sawao Madhopur Urban Co-Op. Bank Ltd.", label: "Sawao Madhopur Urban Co-Op. Bank Ltd." },
  { value: "Sawata Maharaj Gramin Bigar Sheti Pathsanstha", label: "Sawata Maharaj Gramin Bigar Sheti Pathsanstha" },
  { value: "Sawata Mali Nagari Co-Op Mary", label: "Sawata Mali Nagari Co-Op Mary" },
  { value: "Sawhney Financiers Jammu (P)Ltd.", label: "Sawhney Financiers Jammu (P)Ltd." },
  { value: "Sawraj Enterprises", label: "Sawraj Enterprises" },
  { value: "Sawta Mali Nagari Sah Pathsanstha", label: "Sawta Mali Nagari Sah Pathsanstha" },
  { value: "Say Ar Chand Kataria", label: "Say Ar Chand Kataria" },
  { value: "Sayam Finance", label: "Sayam Finance" },
  { value: "Sayan Ur Co Op", label: "Sayan Ur Co Op" },
  { value: "Sayanarayan Kamal Kumar", label: "Sayanarayan Kamal Kumar" },
  { value: "Sayar Chand Kataria", label: "Sayar Chand Kataria" },
  { value: "Sayar Finance", label: "Sayar Finance" },
  { value: "Sayar Motor Finance", label: "Sayar Motor Finance" },
  { value: "Sayarchand Jain", label: "Sayarchand Jain" },
  { value: "Sayed Sariff Finance", label: "Sayed Sariff Finance" },
  { value: "Sayed Shariat Finance Ltd", label: "Sayed Shariat Finance Ltd" },
  { value: "Sayhadri Seva Shakari Bank", label: "Sayhadri Seva Shakari Bank" },
  { value: "Sbh Adb", label: "Sbh Adb" },
  { value: "Sbi  Cepz", label: "Sbi  Cepz" },
  { value: "Sbi (Smeccc)", label: "Sbi (Smeccc)" },
  { value: "Sbi - Adb Bank", label: "Sbi - Adb Bank" },
  { value: "Sbi Attur", label: "Sbi Attur" },
  { value: "Sbi Bagayam", label: "Sbi Bagayam" },
  { value: "Sbi Birpuri", label: "Sbi Birpuri" },
  { value: "Sbi Capital Markets Limited", label: "Sbi Capital Markets Limited" },
  { value: "Sbi Commercial And International Bank Lt", label: "Sbi Commercial And International Bank Lt" },
  { value: "Sbi Commerical And International Bank", label: "Sbi Commerical And International Bank" },
  { value: "Sbi Consortium", label: "Sbi Consortium" },
  { value: "Sbi Ektiasal", label: "Sbi Ektiasal" },
  { value: "Sbi Finance", label: "Sbi Finance" },
  { value: "Sbi Gilts Limited", label: "Sbi Gilts Limited" },
  { value: "Sbi L.H.S.F Ltd", label: "Sbi L.H.S.F Ltd" },
  { value: "Sbi Racpc", label: "Sbi Racpc" },
  { value: "Sbi Rasecc Cum Sarc", label: "Sbi Rasecc Cum Sarc" },
  { value: "Sbi Rrl Br", label: "Sbi Rrl Br" },
  { value: "Sbi Sme Ccc Mumbai", label: "Sbi Sme Ccc Mumbai" },
  { value: "Sbm Nagathi", label: "Sbm Nagathi" },
  { value: "Sbpp Co Op Bank", label: "Sbpp Co Op Bank" },
  { value: "Sbt Personal And Services", label: "Sbt Personal And Services" },
  { value: "Sc Co Op Society Ltd Nsfdc", label: "Sc Co Op Society Ltd Nsfdc" },
  { value: "Scared Heart Credit Co Op Society Ltd", label: "Scared Heart Credit Co Op Society Ltd" },
  { value: "Scdcc Bank", label: "Scdcc Bank" },
  { value: "Scdcc Bank Ltd  Bajagoli", label: "Scdcc Bank Ltd  Bajagoli" },
  { value: "Scdcc Bank Ltd Ujire", label: "Scdcc Bank Ltd Ujire" },
  { value: "Scg Bank Ltd.", label: "Scg Bank Ltd." },
  { value: "Schdule Cast And Tirbe Cast Bank", label: "Schdule Cast And Tirbe Cast Bank" },
  { value: "Schedule Caste Development Board", label: "Schedule Caste Development Board" },
  { value: "Schedule Caste Development Board", label: "Schedule Caste Development Board" },
  { value: "Schedule Tribe Dev. Fin .Corpn.", label: "Schedule Tribe Dev. Fin .Corpn." },
  { value: "Science And Engineering Research Council", label: "Science And Engineering Research Council" },
  { value: "Scientist D", label: "Scientist D" },
  { value: "Scooters India", label: "Scooters India" },
  { value: "Scotiabank", label: "Scotiabank" },
  { value: "Scrap Merchants Credit Co-Op Society Ltd", label: "Scrap Merchants Credit Co-Op Society Ltd" },
  { value: "Scrap Merchants Credit Co.Op. Soc. Ltd", label: "Scrap Merchants Credit Co.Op. Soc. Ltd" },
  { value: "Scuf Ltd", label: "Scuf Ltd" },
  { value: "Sdc Bank Ltd.", label: "Sdc Bank Ltd." },
  { value: "Sea Lord Hire Purchase Pvt Ltd", label: "Sea Lord Hire Purchase Pvt Ltd" },
  { value: "Secretariat For Industrial Assistance", label: "Secretariat For Industrial Assistance" },
  { value: "Secretary Bhel Eccs Ltd", label: "Secretary Bhel Eccs Ltd" },
  { value: "Secured Assets Division Development", label: "Secured Assets Division Development" },
  { value: "Security First Fcu", label: "Security First Fcu" },
  { value: "Seema Finance", label: "Seema Finance" },
  { value: "Seema Motor And Credits", label: "Seema Motor And Credits" },
  { value: "Seeta Portfolio Management Limited", label: "Seeta Portfolio Management Limited" },
  { value: "Seethal", label: "Seethal" },
  { value: "Sehgal Leasing And Investment Ltd.", label: "Sehgal Leasing And Investment Ltd." },
  { value: "Sehgal Motor Financial Services", label: "Sehgal Motor Financial Services" },
  { value: "Sekhon Motor  Finance (P) Ltd", label: "Sekhon Motor  Finance (P) Ltd" },
  { value: "Sekon Motors Fin Ltd", label: "Sekon Motors Fin Ltd" },
  { value: "Sel Vaganpathy Leasing And Finance Pvt. Ltd.", label: "Sel Vaganpathy Leasing And Finance Pvt. Ltd." },
  { value: "Select Vacations Private Limited", label: "Select Vacations Private Limited" },
  { value: "Self Employers Leasing And Finance Ltd.", label: "Self Employers Leasing And Finance Ltd." },
  { value: "Selva Ganapathy Auto Finance", label: "Selva Ganapathy Auto Finance" },
  { value: "Selva Ganapathy Leasing And Finance Ltd", label: "Selva Ganapathy Leasing And Finance Ltd" },
  { value: "Selva Vinayaga Finance", label: "Selva Vinayaga Finance" },
  { value: "Selvakumaran Auto Finance", label: "Selvakumaran Auto Finance" },
  { value: "Selvalakshmi Investments", label: "Selvalakshmi Investments" },
  { value: "Selvam Auto Finance", label: "Selvam Auto Finance" },
  { value: "Selvamurugan Finance", label: "Selvamurugan Finance" },
  { value: "Semi-Conductors Complex Ltd.", label: "Semi-Conductors Complex Ltd." },
  { value: "Senapati Bapat Co-Op Credit Society Ltd", label: "Senapati Bapat Co-Op Credit Society Ltd" },
  { value: "Sendamaram Primary Agricultrual Co. Bank", label: "Sendamaram Primary Agricultrual Co. Bank" },
  { value: "Senkeswara Investment", label: "Senkeswara Investment" },
  { value: "Senthamarai Auto Finance", label: "Senthamarai Auto Finance" },
  { value: "Senthil Hire Purchase Finance Pvt Ltd", label: "Senthil Hire Purchase Finance Pvt Ltd" },
  { value: "Senthil Murugan Finance", label: "Senthil Murugan Finance" },
  { value: "Senthilvel Finance", label: "Senthilvel Finance" },
  { value: "Senthoor Motor Finance", label: "Senthoor Motor Finance" },
  { value: "Senthur Finance", label: "Senthur Finance" },
  { value: "Senthur Finance", label: "Senthur Finance" },
  { value: "Sentinal Capital Ltd.", label: "Sentinal Capital Ltd." },
  { value: "Seri Equipments Finance Ltd", label: "Seri Equipments Finance Ltd" },
  { value: "Seri Infrastructure Finance Ltd.", label: "Seri Infrastructure Finance Ltd." },
  { value: "Seria Finance Pvt Ltd", label: "Seria Finance Pvt Ltd" },
  { value: "Sericulturists C. Farmers S.Co-Op Bk L.", label: "Sericulturists C. Farmers S.Co-Op Bk L." },
  { value: "Service Co-Op Bank Ltd", label: "Service Co-Op Bank Ltd" },
  { value: "Service Co-Operative Bank Limited", label: "Service Co-Operative Bank Limited" },
  { value: "Service Co-Operative Society", label: "Service Co-Operative Society" },
  { value: "Services Cooperatives Bank Ltd", label: "Services Cooperatives Bank Ltd" },
  { value: "Seshu Auto Financiers", label: "Seshu Auto Financiers" },
  { value: "Seth Brothers", label: "Seth Brothers" },
  { value: "Sethi Auto Center", label: "Sethi Auto Center" },
  { value: "Sethi Auto Deals", label: "Sethi Auto Deals" },
  { value: "Sethi Auto Sales", label: "Sethi Auto Sales" },
  { value: "Sethi Finance Corpration", label: "Sethi Finance Corpration" },
  { value: "Sethi Leasing & Finance", label: "Sethi Leasing & Finance" },
  { value: "Sethi Motors", label: "Sethi Motors" },
  { value: "Sethia Finance", label: "Sethia Finance" },
  { value: "Sethia Global Finance Ltd.", label: "Sethia Global Finance Ltd." },
  { value: "Sethiya Finance Corporation", label: "Sethiya Finance Corporation" },
  { value: "Sethiya Investments", label: "Sethiya Investments" },
  { value: "Setia Automobiles Pvt Ltd", label: "Setia Automobiles Pvt Ltd" },
  { value: "Setia Investors", label: "Setia Investors" },
  { value: "Setu Co-Op Credit Soc Ltd.", label: "Setu Co-Op Credit Soc Ltd." },
  { value: "Seva Bhave Brahman Nagari Sahakari Path Sanstha", label: "Seva Bhave Brahman Nagari Sahakari Path Sanstha" },
  { value: "Seva Sahakari Bank Ltd.", label: "Seva Sahakari Bank Ltd." },
  { value: "Seva Sahakari Mandli Bordi Samihiyala", label: "Seva Sahakari Mandli Bordi Samihiyala" },
  { value: "Seva Sahkari Mandali", label: "Seva Sahkari Mandali" },
  { value: "Seva Sahkari Mandli", label: "Seva Sahkari Mandli" },
  { value: "Seva Sakha Sahakarita", label: "Seva Sakha Sahakarita" },
  { value: "Seva Singh Saluja", label: "Seva Singh Saluja" },
  { value: "Seva Vikas Co-Operative Bank", label: "Seva Vikas Co-Operative Bank" },
  { value: "Sevak Gramin Bigar Sheti Patsanstha", label: "Sevak Gramin Bigar Sheti Patsanstha" },
  { value: "Sevalal Urban Co-Op. Bank Ltd", label: "Sevalal Urban Co-Op. Bank Ltd" },
  { value: "Sevaliya Urban Co Op Bank", label: "Sevaliya Urban Co Op Bank" },
  { value: "Sevan Hilles Auto Finance", label: "Sevan Hilles Auto Finance" },
  { value: "Seve Vruht. Sahakari Sanstha Myd.", label: "Seve Vruht. Sahakari Sanstha Myd." },
  { value: "Seven Hills Auto Finance", label: "Seven Hills Auto Finance" },
  { value: "Seven Hills Co-Op. Urban Bank Ltd", label: "Seven Hills Co-Op. Urban Bank Ltd" },
  { value: "Seven Seas Syndicate", label: "Seven Seas Syndicate" },
  { value: "Seven Star Auto Finance", label: "Seven Star Auto Finance" },
  { value: "Sevni Sahakari Mandali Ltd", label: "Sevni Sahakari Mandali Ltd" },
  { value: "Sewa Sahakari Mandli Ltd", label: "Sewa Sahakari Mandli Ltd" },
  { value: "Sewa Shree Sai Sahkari Path Sanstha", label: "Sewa Shree Sai Sahkari Path Sanstha" },
  { value: "Sewa Singh Waraich", label: "Sewa Singh Waraich" },
  { value: "Sewak Auto Finance", label: "Sewak Auto Finance" },
  { value: "Seyad Shariat Finance Ltd.", label: "Seyad Shariat Finance Ltd." },
  { value: "Sfl", label: "Sfl" },
  { value: "Sha Kamlesh Kimar Pukhara", label: "Sha Kamlesh Kimar Pukhara" },
  { value: "Sha Manarathmal Jeethmal Kothari", label: "Sha Manarathmal Jeethmal Kothari" },
  { value: "Shaan Finance Pvt Ltd", label: "Shaan Finance Pvt Ltd" },
  { value: "Shaarathi Finance", label: "Shaarathi Finance" },
  { value: "Shaarathy Finance", label: "Shaarathy Finance" },
  { value: "Shabanur Farmers Ser Co-Op Bank Ltd,", label: "Shabanur Farmers Ser Co-Op Bank Ltd," },
  { value: "Shabari Adivasi Vitt & Vikas Mahamandal Maryadit Ltd", label: "Shabari Adivasi Vitt & Vikas Mahamandal Maryadit Ltd" },
  { value: "Shabari Auto Finance", label: "Shabari Auto Finance" },
  { value: "Shabnam Leasing And Finance Limited", label: "Shabnam Leasing And Finance Limited" },
  { value: "Shabri Adivasi Vitta-Ya-Vikas Mah Maryadit", label: "Shabri Adivasi Vitta-Ya-Vikas Mah Maryadit" },
  { value: "Shabu P S", label: "Shabu P S" },
  { value: "Shah Finance", label: "Shah Finance" },
  { value: "Shah Investment Co.", label: "Shah Investment Co." },
  { value: "Shah Mishrilal Finance", label: "Shah Mishrilal Finance" },
  { value: "Shah Mishrilal Parasmal & Co", label: "Shah Mishrilal Parasmal & Co" },
  { value: "Shahada Peoples Co-Op Bank Ltd", label: "Shahada Peoples Co-Op Bank Ltd" },
  { value: "Shahdol Kshetriya Gramin Bank", label: "Shahdol Kshetriya Gramin Bank" },
  { value: "Shahenshah Finance Co Ltd", label: "Shahenshah Finance Co Ltd" },
  { value: "Shahenyamat Urban Co-Operative Credit Souharda Ltd.", label: "Shahenyamat Urban Co-Operative Credit Souharda Ltd." },
  { value: "Shahji Financers", label: "Shahji Financers" },
  { value: "Shahu Nagari Sahakari Path Sanstha Maryadit", label: "Shahu Nagari Sahakari Path Sanstha Maryadit" },
  { value: "Shahupuri Nagari Sahakari Bank Ltd", label: "Shahupuri Nagari Sahakari Bank Ltd" },
  { value: "Shaikh Rais Shaikh Rafiq", label: "Shaikh Rais Shaikh Rafiq" },
  { value: "Shailesh .B. Trivedi", label: "Shailesh .B. Trivedi" },
  { value: "Shailesh Auto Finance", label: "Shailesh Auto Finance" },
  { value: "Shailesh Trading Co.", label: "Shailesh Trading Co." },
  { value: "Shaire Plan Management Pvt Ltd.", label: "Shaire Plan Management Pvt Ltd." },
  { value: "Shaivai Nagarik Sahakari Patpedhi Maryadit", label: "Shaivai Nagarik Sahakari Patpedhi Maryadit" },
  { value: "Shajapur Nagarik Sahakari Bank Ltd", label: "Shajapur Nagarik Sahakari Bank Ltd" },
  { value: "Shajapur Nagarik Sahakari Bk Mydt.,", label: "Shajapur Nagarik Sahakari Bk Mydt.," },
  { value: "Shaji George", label: "Shaji George" },
  { value: "Shakari Bhumi Vikash Bank Ltd. Ajmer", label: "Shakari Bhumi Vikash Bank Ltd. Ajmer" },
  { value: "Shakri Motors Finance", label: "Shakri Motors Finance" },
  { value: "Shakthi Auto Financiers", label: "Shakthi Auto Financiers" },
  { value: "Shakthi Finance", label: "Shakthi Finance" },
  { value: "Shakthi General Finance", label: "Shakthi General Finance" },
  { value: "Shakthi Priya Auto Finance", label: "Shakthi Priya Auto Finance" },
  { value: "Shakti Auloling Humnabad", label: "Shakti Auloling Humnabad" },
  { value: "Shakti Auto Leasing Humanbed", label: "Shakti Auto Leasing Humanbed" },
  { value: "Shakti Finance", label: "Shakti Finance" },
  { value: "Shakti Finance Corporation", label: "Shakti Finance Corporation" },
  { value: "Shakti Motor Finance", label: "Shakti Motor Finance" },
  { value: "Shakti Trade And Finance Corp.", label: "Shakti Trade And Finance Corp." },
  { value: "Shakumbhari Fin. & Investment", label: "Shakumbhari Fin. & Investment" },
  { value: "Shakun Holdings Pvt. Ltd.", label: "Shakun Holdings Pvt. Ltd." },
  { value: "Shakuntala Chhallani", label: "Shakuntala Chhallani" },
  { value: "Shakuntalam Investment And Leasing Ltd", label: "Shakuntalam Investment And Leasing Ltd" },
  { value: "Shakuntlam Securities (P) Ltd", label: "Shakuntlam Securities (P) Ltd" },
  { value: "Shalibhadra Finance Ltd.", label: "Shalibhadra Finance Ltd." },
  { value: "Shalimar Finance And Leasing Co.", label: "Shalimar Finance And Leasing Co." },
  { value: "Shalini  K . S", label: "Shalini  K . S" },
  { value: "Shalini Jain", label: "Shalini Jain" },
  { value: "Shalivahan Nagari Sahkari Pat Sanstha", label: "Shalivahan Nagari Sahkari Pat Sanstha" },
  { value: "Sham Leasing And Finance Corporation", label: "Sham Leasing And Finance Corporation" },
  { value: "Sham Rao Vittal Co-Op Bank", label: "Sham Rao Vittal Co-Op Bank" },
  { value: "Sham Sher Singh", label: "Sham Sher Singh" },
  { value: "Shama Gupta", label: "Shama Gupta" },
  { value: "Shambhavnath Finance", label: "Shambhavnath Finance" },
  { value: "Shamina Ashique", label: "Shamina Ashique" },
  { value: "Shamrao Patil Nagri Sah Pathsanstha", label: "Shamrao Patil Nagri Sah Pathsanstha" },
  { value: "Shamrao Vithal Co-Op Bank Ltd., Mumbai.", label: "Shamrao Vithal Co-Op Bank Ltd., Mumbai." },
  { value: "Shan Gari Motor Fin.", label: "Shan Gari Motor Fin." },
  { value: "Shaneshwar Gramin Big. Sheti Pathsanstha", label: "Shaneshwar Gramin Big. Sheti Pathsanstha" },
  { value: "Shang & Bros", label: "Shang & Bros" },
  { value: "Shangari Motor Fin", label: "Shangari Motor Fin" },
  { value: "Shangari Motor Financers", label: "Shangari Motor Financers" },
  { value: "Shani Samarth Pat Sanstha", label: "Shani Samarth Pat Sanstha" },
  { value: "Shankar Associates", label: "Shankar Associates" },
  { value: "Shankar Auo Finance", label: "Shankar Auo Finance" },
  { value: "Shankar Gramin Bigar Sheti Sahakari Pat Sanstha", label: "Shankar Gramin Bigar Sheti Sahakari Pat Sanstha" },
  { value: "Shankar Nagari Sahakari Bank Ltd.", label: "Shankar Nagari Sahakari Bank Ltd." },
  { value: "Shankar Nagari Sahakari Bank Ltd.", label: "Shankar Nagari Sahakari Bank Ltd." },
  { value: "Shankar Rao Mohite Patil Bigar Sheti Sah. Path Sanstha.", label: "Shankar Rao Mohite Patil Bigar Sheti Sah. Path Sanstha." },
  { value: "Shankar Traders & D (P) Ltd", label: "Shankar Traders & D (P) Ltd" },
  { value: "Shankara Chits & Finance", label: "Shankara Chits & Finance" },
  { value: "Shankaranarayana Sahakari Vyavasayika Bank Niyamita - Shankarnarayana Branch - Kundapura Taluk", label: "Shankaranarayana Sahakari Vyavasayika Bank Niyamita - Shankarnarayana Branch - Kundapura Taluk" },
  { value: "Shankarrao Chavan Nagari Sah Bank", label: "Shankarrao Chavan Nagari Sah Bank" },
  { value: "Shankarrao Mohite - Patil Sah. Bank Ltd.", label: "Shankarrao Mohite - Patil Sah. Bank Ltd." },
  { value: "Shankarrao Mohite Patil Nagari Sah. Pat. San.", label: "Shankarrao Mohite Patil Nagari Sah. Pat. San." },
  { value: "Shankarrao Mohite Patil Sahakari Bank Ltd", label: "Shankarrao Mohite Patil Sahakari Bank Ltd" },
  { value: "Shankarswami Nagri Sah Pat Sanstha", label: "Shankarswami Nagri Sah Pat Sanstha" },
  { value: "Shankeshwar Finance", label: "Shankeshwar Finance" },
  { value: "Shanmuga Finance", label: "Shanmuga Finance" },
  { value: "Shanmuga Investments", label: "Shanmuga Investments" },
  { value: "Shanmukha Hp & Finance", label: "Shanmukha Hp & Finance" },
  { value: "Shanmukha Nayana Finance", label: "Shanmukha Nayana Finance" },
  { value: "Shanmukha Sai Finance", label: "Shanmukha Sai Finance" },
  { value: "Shanmukha Sai Hp & Finance", label: "Shanmukha Sai Hp & Finance" },
  { value: "Shantha Kanwar Financier", label: "Shantha Kanwar Financier" },
  { value: "Shantheneketan Leasing ( R )", label: "Shantheneketan Leasing ( R )" },
  { value: "Shanthi Devi Roop Arad", label: "Shanthi Devi Roop Arad" },
  { value: "Shanthi Finance", label: "Shanthi Finance" },
  { value: "Shanthi Finance Corporation", label: "Shanthi Finance Corporation" },
  { value: "Shanthi Finance Corporation", label: "Shanthi Finance Corporation" },
  { value: "Shanthika Souhardha Ankola", label: "Shanthika Souhardha Ankola" },
  { value: "Shanthilal And Sons", label: "Shanthilal And Sons" },
  { value: "Shanthilal Karnawat", label: "Shanthilal Karnawat" },
  { value: "Shanthilal Kataria Cheenai", label: "Shanthilal Kataria Cheenai" },
  { value: "Shanthinath Auto Finance", label: "Shanthinath Auto Finance" },
  { value: "Shanti Auto Leasing", label: "Shanti Auto Leasing" },
  { value: "Shanti Auto Lessing", label: "Shanti Auto Lessing" },
  { value: "Shanti Fiannce", label: "Shanti Fiannce" },
  { value: "Shanti Finance", label: "Shanti Finance" },
  { value: "Shanti Finance Co.", label: "Shanti Finance Co." },
  { value: "Shanti Investment Surat", label: "Shanti Investment Surat" },
  { value: "Shanti Motors ,Surat", label: "Shanti Motors ,Surat" },
  { value: "Shanti Roadlines", label: "Shanti Roadlines" },
  { value: "Shantichand Motor Finance Co", label: "Shantichand Motor Finance Co" },
  { value: "Shantika Credit Co-Operative Society", label: "Shantika Credit Co-Operative Society" },
  { value: "Shantilal Bagmar", label: "Shantilal Bagmar" },
  { value: "Shantilal Finance", label: "Shantilal Finance" },
  { value: "Shantilal Gulecha Financiers", label: "Shantilal Gulecha Financiers" },
  { value: "Shantilal Jain", label: "Shantilal Jain" },
  { value: "Shantilal Jain Financiers", label: "Shantilal Jain Financiers" },
  { value: "Shantinath Finance Ltd", label: "Shantinath Finance Ltd" },
  { value: "Shantinath Vyapari Nagari Sahkari Patpedhi Ltd.", label: "Shantinath Vyapari Nagari Sahkari Patpedhi Ltd." },
  { value: "Shapoorji Pallonji Finance Ltd.", label: "Shapoorji Pallonji Finance Ltd." },
  { value: "Shara Finicial Service Pvt. Ltd", label: "Shara Finicial Service Pvt. Ltd" },
  { value: "Sharachi Securities Ltd", label: "Sharachi Securities Ltd" },
  { value: "Sharad Finlease", label: "Sharad Finlease" },
  { value: "Sharad Gramin Biger Sheti Sah Pat San Maryadit", label: "Sharad Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Sharad Hire Purchase Co.", label: "Sharad Hire Purchase Co." },
  { value: "Sharad Nagari Sah Patsanstha Maryadit", label: "Sharad Nagari Sah Patsanstha Maryadit" },
  { value: "Sharad Nagari Sahakari Bank Ltd.", label: "Sharad Nagari Sahakari Bank Ltd." },
  { value: "Sharad Sahakari Bank Ltd.", label: "Sharad Sahakari Bank Ltd." },
  { value: "Sharad Sahakari Credit Society", label: "Sharad Sahakari Credit Society" },
  { value: "Sharad Shroff Finance", label: "Sharad Shroff Finance" },
  { value: "Sharada Finance Corporation", label: "Sharada Finance Corporation" },
  { value: "Sharada Gramin Bank Ltd", label: "Sharada Gramin Bank Ltd" },
  { value: "Sharadchandra Bigar Sheti Sahakari Patasanstha Maryadit", label: "Sharadchandra Bigar Sheti Sahakari Patasanstha Maryadit" },
  { value: "Sharadha Kocher", label: "Sharadha Kocher" },
  { value: "Sharan Veereshwar Co-Op Bank Ltd", label: "Sharan Veereshwar Co-Op Bank Ltd" },
  { value: "Sharanabasaveshwar Auto Finance", label: "Sharanabasaveshwar Auto Finance" },
  { value: "Sharanpur Gramin Bigar Sheti Sahakari Patsanstha", label: "Sharanpur Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Sharanpuri Maharaj Gramin Biger Sheti Path Sanstha", label: "Sharanpuri Maharaj Gramin Biger Sheti Path Sanstha" },
  { value: "Sharanpuri Nagari Sah. Pat. San", label: "Sharanpuri Nagari Sah. Pat. San" },
  { value: "Sharat Finance Corporation", label: "Sharat Finance Corporation" },
  { value: "Sharath Shah Bank Ltd", label: "Sharath Shah Bank Ltd" },
  { value: "Sharatha Auto Finance", label: "Sharatha Auto Finance" },
  { value: "Sharavati Leasing", label: "Sharavati Leasing" },
  { value: "Sharaya Infacater Finance", label: "Sharaya Infacater Finance" },
  { value: "Shard Gramin Bigar Sheti Sahakari Path Santha Maryadit", label: "Shard Gramin Bigar Sheti Sahakari Path Santha Maryadit" },
  { value: "Sharda Co Bank", label: "Sharda Co Bank" },
  { value: "Sharda Finance", label: "Sharda Finance" },
  { value: "Sharda Nagari Sahakari Patsantha Ltd", label: "Sharda Nagari Sahakari Patsantha Ltd" },
  { value: "Sharda Sah. Bank Ltd.", label: "Sharda Sah. Bank Ltd." },
  { value: "Shardamai Nagari Sahakari Pat San Maryadit", label: "Shardamai Nagari Sahakari Pat San Maryadit" },
  { value: "Share Micro Finance", label: "Share Micro Finance" },
  { value: "Share Wealth Financial Service Ltd", label: "Share Wealth Financial Service Ltd" },
  { value: "Sharee Microfin Ltd, Banahalli", label: "Sharee Microfin Ltd, Banahalli" },
  { value: "Sharma Scooter Center", label: "Sharma Scooter Center" },
  { value: "Sharmitha Finance", label: "Sharmitha Finance" },
  { value: "Sharnira Nagari Sahakari Pat Sanstha Ltd.", label: "Sharnira Nagari Sahakari Pat Sanstha Ltd." },
  { value: "Sharp Finicial Service Pvt. Ltd", label: "Sharp Finicial Service Pvt. Ltd" },
  { value: "Sharun Trading Corpn", label: "Sharun Trading Corpn" },
  { value: "Sharyutai Nagari Shakari Path Sanstha Ltd", label: "Sharyutai Nagari Shakari Path Sanstha Ltd" },
  { value: "Shashank P Kochhar", label: "Shashank P Kochhar" },
  { value: "Shashi Finance Corporation", label: "Shashi Finance Corporation" },
  { value: "Shashi Jain", label: "Shashi Jain" },
  { value: "Shasirimal Surajmal", label: "Shasirimal Surajmal" },
  { value: "Shatabdi Mahila Sahakari Bank Ltd.", label: "Shatabdi Mahila Sahakari Bank Ltd." },
  { value: "Shathavahana Gramina Bank", label: "Shathavahana Gramina Bank" },
  { value: "Shaukla Fin", label: "Shaukla Fin" },
  { value: "Shaw & Shaw Co", label: "Shaw & Shaw Co" },
  { value: "Sheba Properties Ltd", label: "Sheba Properties Ltd" },
  { value: "Shedbal Urban Co-Op Bank Ltd", label: "Shedbal Urban Co-Op Bank Ltd" },
  { value: "Shee Anjaneya Vivedodesha Sahakari Niyamitha", label: "Shee Anjaneya Vivedodesha Sahakari Niyamitha" },
  { value: "Shee Sai Ashirwad Auto Point ,Amroli", label: "Shee Sai Ashirwad Auto Point ,Amroli" },
  { value: "Sheeba Properties Ltd.", label: "Sheeba Properties Ltd." },
  { value: "Sheen Finance And Investment India Ltd.", label: "Sheen Finance And Investment India Ltd." },
  { value: "Sheetal Auto Finance", label: "Sheetal Auto Finance" },
  { value: "Sheetal Credit Co.Op. Society Ltd", label: "Sheetal Credit Co.Op. Society Ltd" },
  { value: "Sheetal Finance", label: "Sheetal Finance" },
  { value: "Sheetal Finance Corporation", label: "Sheetal Finance Corporation" },
  { value: "Shefali Grouth Fund", label: "Shefali Grouth Fund" },
  { value: "Shegeon Urban Credit Co-Op.Society Ltd.", label: "Shegeon Urban Credit Co-Op.Society Ltd." },
  { value: "Shekhon Motors Finance Ltd", label: "Shekhon Motors Finance Ltd" },
  { value: "Shelly Jain", label: "Shelly Jain" },
  { value: "Shelvi .P.P", label: "Shelvi .P.P" },
  { value: "Sher Nagrik Sahakari Bank Ltd.", label: "Sher Nagrik Sahakari Bank Ltd." },
  { value: "Sheri E V", label: "Sheri E V" },
  { value: "Sherly Salin", label: "Sherly Salin" },
  { value: "Sherly.M.T", label: "Sherly.M.T" },
  { value: "Sheryas Co-Op Bank Ltd", label: "Sheryas Co-Op Bank Ltd" },
  { value: "Sheth Bb Shroff Bulasar People C P Bk Lt", label: "Sheth Bb Shroff Bulasar People C P Bk Lt" },
  { value: "Shetkari Sahakari Bank Ltd", label: "Shetkari Sahakari Bank Ltd" },
  { value: "Shetkari Sahakari Bank Ltd", label: "Shetkari Sahakari Bank Ltd" },
  { value: "Shi Ganesh Co-Op Bank Ltd", label: "Shi Ganesh Co-Op Bank Ltd" },
  { value: "Shibpore Cooperative Bank Ltd", label: "Shibpore Cooperative Bank Ltd" },
  { value: "Shibsagar District Central Co-Operative Bank Ltd.", label: "Shibsagar District Central Co-Operative Bank Ltd." },
  { value: "Shidhhi Vinayak Enterprises", label: "Shidhhi Vinayak Enterprises" },
  { value: "Shikar Finance", label: "Shikar Finance" },
  { value: "Shikha Sharma", label: "Shikha Sharma" },
  { value: "Shikshak Sah. Bank Ltd.", label: "Shikshak Sah. Bank Ltd." },
  { value: "Shikshak Sahakari Bank", label: "Shikshak Sahakari Bank" },
  { value: "Shikshak Sahakari Bank Ltd., Nagpur", label: "Shikshak Sahakari Bank Ltd., Nagpur" },
  { value: "Shillong Urban Co-Op Bank Ltd", label: "Shillong Urban Co-Op Bank Ltd" },
  { value: "Shilpa Enterprises", label: "Shilpa Enterprises" },
  { value: "Shilpa Jain", label: "Shilpa Jain" },
  { value: "Shilpa Jain Finance", label: "Shilpa Jain Finance" },
  { value: "Shilpha Investment", label: "Shilpha Investment" },
  { value: "Shilpi  Leasing Pvt Ltd.", label: "Shilpi  Leasing Pvt Ltd." },
  { value: "Shimla Chordia", label: "Shimla Chordia" },
  { value: "Shimla Chordia", label: "Shimla Chordia" },
  { value: "Shimoga Arecanut Mandi Merchants Co-Op Bank Ltd", label: "Shimoga Arecanut Mandi Merchants Co-Op Bank Ltd" },
  { value: "Shimoga District Central Co-Operative Bank Ltd.", label: "Shimoga District Central Co-Operative Bank Ltd." },
  { value: "Shimsha Shakara Bank Niyamita", label: "Shimsha Shakara Bank Niyamita" },
  { value: "Shine Blue Hire Purchase Ltd", label: "Shine Blue Hire Purchase Ltd" },
  { value: "Shine Blue Hire Purchase Pvt. Ltd", label: "Shine Blue Hire Purchase Pvt. Ltd" },
  { value: "Shine Financial Services Bhogpur", label: "Shine Financial Services Bhogpur" },
  { value: "Shinhan Bank,", label: "Shinhan Bank," },
  { value: "Shipping Corporation Of India Ltd.", label: "Shipping Corporation Of India Ltd." },
  { value: "Shir Gurudatt Nagari Sahakari Path Sanstha", label: "Shir Gurudatt Nagari Sahakari Path Sanstha" },
  { value: "Shiral Taluka Co-Op Bank Ltd", label: "Shiral Taluka Co-Op Bank Ltd" },
  { value: "Shirala Taluka Co Op Credit Soc Ltd.", label: "Shirala Taluka Co Op Credit Soc Ltd." },
  { value: "Shirala Taluka Urban Co-Op. Bank Ltd.", label: "Shirala Taluka Urban Co-Op. Bank Ltd." },
  { value: "Shiralkoppa Urban Co-Op Bank Ltd", label: "Shiralkoppa Urban Co-Op Bank Ltd" },
  { value: "Shiram Surynmalji Vyapari Marydit Patsanstha", label: "Shiram Surynmalji Vyapari Marydit Patsanstha" },
  { value: "Shirani Motors Pvt Ltd", label: "Shirani Motors Pvt Ltd" },
  { value: "Shirdi Merchants Nag Sah Pathsanstha Ltd", label: "Shirdi Merchants Nag Sah Pathsanstha Ltd" },
  { value: "Shirdi Sai Auto & Employees Finance", label: "Shirdi Sai Auto & Employees Finance" },
  { value: "Shirdi Sai Auto Finance", label: "Shirdi Sai Auto Finance" },
  { value: "Shiridi Sai Auto Employees Finance", label: "Shiridi Sai Auto Employees Finance" },
  { value: "Shiridi Sainadh Finance", label: "Shiridi Sainadh Finance" },
  { value: "Shiriyara Co-Op Agricultural Bank L", label: "Shiriyara Co-Op Agricultural Bank L" },
  { value: "Shiriyara Vyavasaya Sahakari Bank Ltd", label: "Shiriyara Vyavasaya Sahakari Bank Ltd" },
  { value: "Shiriyara Vyavasaya Sahakari Bank Niyamitha", label: "Shiriyara Vyavasaya Sahakari Bank Niyamitha" },
  { value: "Shiroli Krushak Seva Sahakari Pathsanstha", label: "Shiroli Krushak Seva Sahakari Pathsanstha" },
  { value: "Shiroli Vyapari Nagari Sahakari Pat Sanstha Maryadit", label: "Shiroli Vyapari Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Shiromani Leasing & Finance Ltd", label: "Shiromani Leasing & Finance Ltd" },
  { value: "Shirpur Merchants Co-Op Bank Ltd", label: "Shirpur Merchants Co-Op Bank Ltd" },
  { value: "Shirpur Peoples Co-Op Bank Ltd", label: "Shirpur Peoples Co-Op Bank Ltd" },
  { value: "Shirsoli Sahakari Patpedhi Ltd", label: "Shirsoli Sahakari Patpedhi Ltd" },
  { value: "Shiv Chatrapati Path Sanstha", label: "Shiv Chatrapati Path Sanstha" },
  { value: "Shiv Dev Raj Sharma", label: "Shiv Dev Raj Sharma" },
  { value: "Shiv Enterprises", label: "Shiv Enterprises" },
  { value: "Shiv Finance", label: "Shiv Finance" },
  { value: "Shiv Ganga Co-Op Credit Soc City", label: "Shiv Ganga Co-Op Credit Soc City" },
  { value: "Shiv Kant Soni", label: "Shiv Kant Soni" },
  { value: "Shiv Krupa Auto Finance", label: "Shiv Krupa Auto Finance" },
  { value: "Shiv Krupa Sahkari Path Pedhi Ltd", label: "Shiv Krupa Sahkari Path Pedhi Ltd" },
  { value: "Shiv Krushna Nagari Sah Pat San Maryadit", label: "Shiv Krushna Nagari Sah Pat San Maryadit" },
  { value: "Shiv Motor Finance", label: "Shiv Motor Finance" },
  { value: "Shiv Shahi Nagari Sahakari Pat Sanstha", label: "Shiv Shahi Nagari Sahakari Pat Sanstha" },
  { value: "Shiv Shakti Auto Finance", label: "Shiv Shakti Auto Finance" },
  { value: "Shiv Shakti Financial Service", label: "Shiv Shakti Financial Service" },
  { value: "Shiv Shakti Nagri Sah Soc Ltd", label: "Shiv Shakti Nagri Sah Soc Ltd" },
  { value: "Shiv Shambho Sahakari Patsanstha Maryadit", label: "Shiv Shambho Sahakari Patsanstha Maryadit" },
  { value: "Shiv Shankar  Nagari Sahakari Pat Santha", label: "Shiv Shankar  Nagari Sahakari Pat Santha" },
  { value: "Shiv Shankar Auto Deal", label: "Shiv Shankar Auto Deal" },
  { value: "Shiv Shankar Finance Co.", label: "Shiv Shankar Finance Co." },
  { value: "Shiv Shriram Transport Finance Co. Ltd.", label: "Shiv Shriram Transport Finance Co. Ltd." },
  { value: "Shiva Auto Money Lenders", label: "Shiva Auto Money Lenders" },
  { value: "Shiva Bhodra Urban Co-Operative Society Ltd.", label: "Shiva Bhodra Urban Co-Operative Society Ltd." },
  { value: "Shiva Co-Op Bank Ltd", label: "Shiva Co-Op Bank Ltd" },
  { value: "Shiva Co-Operative Bank Ltd.", label: "Shiva Co-Operative Bank Ltd." },
  { value: "Shiva Credit Co-Op Society Ltd Honnalli", label: "Shiva Credit Co-Op Society Ltd Honnalli" },
  { value: "Shiva Credit Co-Operative", label: "Shiva Credit Co-Operative" },
  { value: "Shiva Finance Company", label: "Shiva Finance Company" },
  { value: "Shiva Financer Co", label: "Shiva Financer Co" },
  { value: "Shiva Motors", label: "Shiva Motors" },
  { value: "Shiva Sahakara Bank Niyamitha", label: "Shiva Sahakara Bank Niyamitha" },
  { value: "Shiva Sahakari Bank Niyamita", label: "Shiva Sahakari Bank Niyamita" },
  { value: "Shiva Sai Auto Finance", label: "Shiva Sai Auto Finance" },
  { value: "Shiva Sai Finance", label: "Shiva Sai Finance" },
  { value: "Shiva Sai Motors", label: "Shiva Sai Motors" },
  { value: "Shiva Shree Soutarda Credit Co-Op Society", label: "Shiva Shree Soutarda Credit Co-Op Society" },
  { value: "Shiva Souharda Sahakari Bank Belgaum", label: "Shiva Souharda Sahakari Bank Belgaum" },
  { value: "Shiva Vividoddesha Sahakara Sangha Niyamitha", label: "Shiva Vividoddesha Sahakara Sangha Niyamitha" },
  { value: "Shivabodarang Urban Co-Operative Credit Society Ltd", label: "Shivabodarang Urban Co-Operative Credit Society Ltd" },
  { value: "Shivagiri Co - Op Cr Society Ltd.", label: "Shivagiri Co - Op Cr Society Ltd." },
  { value: "Shivai Nagari Sahakari Pathpedhi Ltd", label: "Shivai Nagari Sahakari Pathpedhi Ltd" },
  { value: "Shivai Nagari Sahakari Patsanstha Maryadit Pune", label: "Shivai Nagari Sahakari Patsanstha Maryadit Pune" },
  { value: "Shivai Nagrik Sahakari Patsanstha Ltd.", label: "Shivai Nagrik Sahakari Patsanstha Ltd." },
  { value: "Shivaji Auto Finance", label: "Shivaji Auto Finance" },
  { value: "Shivaji Maharaj Co-Operative Credit Society Ltd.", label: "Shivaji Maharaj Co-Operative Credit Society Ltd." },
  { value: "Shivaji Nagari Sahakari Bank Ltd", label: "Shivaji Nagari Sahakari Bank Ltd" },
  { value: "Shivaji Nagari Sahakari Pathpedhi Ltd", label: "Shivaji Nagari Sahakari Pathpedhi Ltd" },
  { value: "Shivaji Patil G B S S P S Halkarni Fata", label: "Shivaji Patil G B S S P S Halkarni Fata" },
  { value: "Shivaji Pattina Sahakara Sangha", label: "Shivaji Pattina Sahakara Sangha" },
  { value: "Shivaji U Co-Op Bank Ltd", label: "Shivaji U Co-Op Bank Ltd" },
  { value: "Shivajinagar Sahakari Bank Ltd", label: "Shivajinagar Sahakari Bank Ltd" },
  { value: "Shivajirao Bhosale Sahakari Bank Ltd.", label: "Shivajirao Bhosale Sahakari Bank Ltd." },
  { value: "Shivalik Finance Co.", label: "Shivalik Finance Co." },
  { value: "Shivalik Kshetriya Gramin Bank", label: "Shivalik Kshetriya Gramin Bank" },
  { value: "Shivalik Mercantile Co-Op.Bank Ltd.", label: "Shivalik Mercantile Co-Op.Bank Ltd." },
  { value: "Shivalik Mercantile Distt. Coperative Bank", label: "Shivalik Mercantile Distt. Coperative Bank" },
  { value: "Shivalik Merchants Co-Op Bank Ltd", label: "Shivalik Merchants Co-Op Bank Ltd" },
  { value: "Shivam Advances Co. Regd.", label: "Shivam Advances Co. Regd." },
  { value: "Shivam Agro Tech Pvt Ltd.", label: "Shivam Agro Tech Pvt Ltd." },
  { value: "Shivam Associated Ltd", label: "Shivam Associated Ltd" },
  { value: "Shivam Auto Finance", label: "Shivam Auto Finance" },
  { value: "Shivam Co Op Society Ltd", label: "Shivam Co Op Society Ltd" },
  { value: "Shivam Credit Corporation", label: "Shivam Credit Corporation" },
  { value: "Shivam Enterprise :Chandkheda Ahmedabad", label: "Shivam Enterprise :Chandkheda Ahmedabad" },
  { value: "Shivam Finance & Leasing Co", label: "Shivam Finance & Leasing Co" },
  { value: "Shivam Finance Co.", label: "Shivam Finance Co." },
  { value: "Shivam Finance Co. Bank Of India", label: "Shivam Finance Co. Bank Of India" },
  { value: "Shivam Finance Company", label: "Shivam Finance Company" },
  { value: "Shivam Hire Purchase", label: "Shivam Hire Purchase" },
  { value: "Shivam Hire Purchase & Finvest Pvt Ltd.", label: "Shivam Hire Purchase & Finvest Pvt Ltd." },
  { value: "Shivam Lease Finance Co Ltd", label: "Shivam Lease Finance Co Ltd" },
  { value: "Shivam Sahakari Bank L", label: "Shivam Sahakari Bank L" },
  { value: "Shivam Sahakari Bank Ltd,", label: "Shivam Sahakari Bank Ltd," },
  { value: "Shivanand Co-Operative Credit Society", label: "Shivanand Co-Operative Credit Society" },
  { value: "Shivani Nagari Sah Patsanstha", label: "Shivani Nagari Sah Patsanstha" },
  { value: "Shivaniben Vinubhai Patel", label: "Shivaniben Vinubhai Patel" },
  { value: "Shivaprasad Auto Finance", label: "Shivaprasad Auto Finance" },
  { value: "Shivaprasad Auto Finance", label: "Shivaprasad Auto Finance" },
  { value: "Shivapriya Auto Investments", label: "Shivapriya Auto Investments" },
  { value: "Shivashree Souharda Credit Co-Op Society", label: "Shivashree Souharda Credit Co-Op Society" },
  { value: "Shivashree Sowhardha Credit Co-Op Ltd", label: "Shivashree Sowhardha Credit Co-Op Ltd" },
  { value: "Shivayogi Sahakari Bank Ltd", label: "Shivayogi Sahakari Bank Ltd" },
  { value: "Shivchhya Nagri Sahakari Patpedhi Ltd", label: "Shivchhya Nagri Sahakari Patpedhi Ltd" },
  { value: "Shivdualat Sahakari Bank Ltd", label: "Shivdualat Sahakari Bank Ltd" },
  { value: "Shive Shakti Co Op Credit Society Ltd", label: "Shive Shakti Co Op Credit Society Ltd" },
  { value: "Shivjyot Gramin Sahakari Pat Sanstha Ltd.", label: "Shivjyot Gramin Sahakari Pat Sanstha Ltd." },
  { value: "Shivjyoti Sahakari Patpedi Mydt.", label: "Shivjyoti Sahakari Patpedi Mydt." },
  { value: "Shivkiran Auto Finance", label: "Shivkiran Auto Finance" },
  { value: "Shivkripa Nagric Sahakari Pat Ltd", label: "Shivkripa Nagric Sahakari Pat Ltd" },
  { value: "Shivkrupa Co Op Pat Pedhi Ltd", label: "Shivkrupa Co Op Pat Pedhi Ltd" },
  { value: "Shivkrupa Enterprises", label: "Shivkrupa Enterprises" },
  { value: "Shivkrupa Gramin Big Sheti Sah Path San Marya", label: "Shivkrupa Gramin Big Sheti Sah Path San Marya" },
  { value: "Shivkrupa Nagri Sahakari Patpedti Ltd", label: "Shivkrupa Nagri Sahakari Patpedti Ltd" },
  { value: "Shivkrupa Sahakari Path Ltd Mumbai", label: "Shivkrupa Sahakari Path Ltd Mumbai" },
  { value: "Shivner Nsgsri Sah Pat Maryadit", label: "Shivner Nsgsri Sah Pat Maryadit" },
  { value: "Shivner Sahakari Patsantha Ltd", label: "Shivner Sahakari Patsantha Ltd" },
  { value: "Shivneri Nagari Sahakari Path Sanstha", label: "Shivneri Nagari Sahakari Path Sanstha" },
  { value: "Shivneri Sahakari Bank Ltd.", label: "Shivneri Sahakari Bank Ltd." },
  { value: "Shivpuri Guna Kshetriya Gramin Bank", label: "Shivpuri Guna Kshetriya Gramin Bank" },
  { value: "Shivpuri Guna Kshetriya Gramin Bank.", label: "Shivpuri Guna Kshetriya Gramin Bank." },
  { value: "Shivrai Gramin Biger Sheti Sah Pat Sanstha", label: "Shivrai Gramin Biger Sheti Sah Pat Sanstha" },
  { value: "Shivraj Maratha Sahakari Pathpedi", label: "Shivraj Maratha Sahakari Pathpedi" },
  { value: "Shivraj Nagari Pat Sanstha Maryadit", label: "Shivraj Nagari Pat Sanstha Maryadit" },
  { value: "Shivraj Nagari Pat. Sanstha Maryadit", label: "Shivraj Nagari Pat. Sanstha Maryadit" },
  { value: "Shivraj Sahakari Patsanstha Ltd", label: "Shivraj Sahakari Patsanstha Ltd" },
  { value: "Shivrana Nagari Sahakari Pat Sanshta", label: "Shivrana Nagari Sahakari Pat Sanshta" },
  { value: "Shivratna Gramin Biger Sheti Sah Pat San Maryadit", label: "Shivratna Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Shivratna Sahakari Patpedhi", label: "Shivratna Sahakari Patpedhi" },
  { value: "Shivsahyadri Sahakari Patpedhi Maryadit", label: "Shivsahyadri Sahakari Patpedhi Maryadit" },
  { value: "Shivsamarth Sahakar Patsantha Ltd.", label: "Shivsamarth Sahakar Patsantha Ltd." },
  { value: "Shivsfurti Gramin Bigarsheti Patsansthan", label: "Shivsfurti Gramin Bigarsheti Patsansthan" },
  { value: "Shivshakti Co-Op Credit Soc. Ltd.", label: "Shivshakti Co-Op Credit Soc. Ltd." },
  { value: "Shivshakti Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit", label: "Shivshakti Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit" },
  { value: "Shivshakti Nagari Path Sanstha Maryadit", label: "Shivshakti Nagari Path Sanstha Maryadit" },
  { value: "Shivshakti Nagrik Credit Soc Ltd", label: "Shivshakti Nagrik Credit Soc Ltd" },
  { value: "Shivshakti Urban Co-Bank Ltd", label: "Shivshakti Urban Co-Bank Ltd" },
  { value: "Shivshakti Urban Co-Op Bank Ltd,", label: "Shivshakti Urban Co-Op Bank Ltd," },
  { value: "Shivshambo Co Op Credit Society Ltd", label: "Shivshambo Co Op Credit Society Ltd" },
  { value: "Shivshraddha Co Op Credit Society Ltd", label: "Shivshraddha Co Op Credit Society Ltd" },
  { value: "Shivshradha Co Op Credit Soc Ltd", label: "Shivshradha Co Op Credit Soc Ltd" },
  { value: "Shoba Auto Finance", label: "Shoba Auto Finance" },
  { value: "Shoba Auto Finance Corporation", label: "Shoba Auto Finance Corporation" },
  { value: "Shoba Credit Corporation", label: "Shoba Credit Corporation" },
  { value: "Shoba Enterprises", label: "Shoba Enterprises" },
  { value: "Shoba Fianance Corporation", label: "Shoba Fianance Corporation" },
  { value: "Shoba Mehta", label: "Shoba Mehta" },
  { value: "Shobhana Ganesh", label: "Shobhana Ganesh" },
  { value: "Shola Merchants Co-Op Bank Ltd", label: "Shola Merchants Co-Op Bank Ltd" },
  { value: "Sholapur Dist. Cent. Coop Bank", label: "Sholapur Dist. Cent. Coop Bank" },
  { value: "Sholapur Janata Sahakari Bank L,", label: "Sholapur Janata Sahakari Bank L," },
  { value: "Sholapur Janata Sahakari Bank Ltd", label: "Sholapur Janata Sahakari Bank Ltd" },
  { value: "Sholapur Social Urban Co-Op Bank Ltd", label: "Sholapur Social Urban Co-Op Bank Ltd" },
  { value: "Sholapur Zilla Mahila Sahakari Bank Ltd", label: "Sholapur Zilla Mahila Sahakari Bank Ltd" },
  { value: "Sholingur Urban Co-Op Bank Ltd", label: "Sholingur Urban Co-Op Bank Ltd" },
  { value: "Shon Bai", label: "Shon Bai" },
  { value: "Shoranur Co-Op Urban Bank Ltd.", label: "Shoranur Co-Op Urban Bank Ltd." },
  { value: "Shoranur Urban Co-Op Bank Ltd", label: "Shoranur Urban Co-Op Bank Ltd" },
  { value: "Shorpur Urban Co-Operative Bank Ltd", label: "Shorpur Urban Co-Operative Bank Ltd" },
  { value: "Shrachi Finance Ltd.", label: "Shrachi Finance Ltd." },
  { value: "Shrachi Infrastructure Finance", label: "Shrachi Infrastructure Finance" },
  { value: "Shrachi Infrastructure Finance Ltd.", label: "Shrachi Infrastructure Finance Ltd." },
  { value: "Shrachi Securities Finance Ltd", label: "Shrachi Securities Finance Ltd" },
  { value: "Shrachi Securities Ltd", label: "Shrachi Securities Ltd" },
  { value: "Shrachi Securities Ltd", label: "Shrachi Securities Ltd" },
  { value: "Shraddha Gramin Biger Sheti Sahakari Pat San Mar", label: "Shraddha Gramin Biger Sheti Sahakari Pat San Mar" },
  { value: "Shraddha Seva Sahakari Mandali", label: "Shraddha Seva Sahakari Mandali" },
  { value: "Shradha Co Op Credit Society Ltd", label: "Shradha Co Op Credit Society Ltd" },
  { value: "Shradha Dugar", label: "Shradha Dugar" },
  { value: "Shradha Finance Ltd", label: "Shradha Finance Ltd" },
  { value: "Shradha Motors", label: "Shradha Motors" },
  { value: "Shradha Saburi Gramin Bigar Sheti Sah Path", label: "Shradha Saburi Gramin Bigar Sheti Sah Path" },
  { value: "Shramik Nagrik Sahakari Bank Ltd", label: "Shramik Nagrik Sahakari Bank Ltd" },
  { value: "Shramik Ramdas Co Op Credit Society Ltd", label: "Shramik Ramdas Co Op Credit Society Ltd" },
  { value: "Shramik Sahakari  Bank Ltd", label: "Shramik Sahakari  Bank Ltd" },
  { value: "Shramik Sahakari Bank Ltd., Mumbai", label: "Shramik Sahakari Bank Ltd., Mumbai" },
  { value: "Shramjivi Nagri Sahakari Patsanstha Maryadit", label: "Shramjivi Nagri Sahakari Patsanstha Maryadit" },
  { value: "Shre Anaapurna Enterprises", label: "Shre Anaapurna Enterprises" },
  { value: "Shre Birdar Finance Pvt Ltd", label: "Shre Birdar Finance Pvt Ltd" },
  { value: "Shre Dhruvnagar Ssm Ltd", label: "Shre Dhruvnagar Ssm Ltd" },
  { value: "Shre Gajanana Urban Co-Operative Bank Ltd", label: "Shre Gajanana Urban Co-Operative Bank Ltd" },
  { value: "Shre Kankariya Teh And Fin Ltd", label: "Shre Kankariya Teh And Fin Ltd" },
  { value: "Shre Kanthavelan Finaance", label: "Shre Kanthavelan Finaance" },
  { value: "Shree Aashraya Souhard Credit Society Ltd", label: "Shree Aashraya Souhard Credit Society Ltd" },
  { value: "Shree Adinath Co Op Savings And Credit Soc. Ltd", label: "Shree Adinath Co Op Savings And Credit Soc. Ltd" },
  { value: "Shree Adinath Co-Op Bank Ltd", label: "Shree Adinath Co-Op Bank Ltd" },
  { value: "Shree Adinath Sahakari Bank Ltd", label: "Shree Adinath Sahakari Bank Ltd" },
  { value: "Shree Agarwal Finance Co Ltd", label: "Shree Agarwal Finance Co Ltd" },
  { value: "Shree Agarwal Finance Co Ltd", label: "Shree Agarwal Finance Co Ltd" },
  { value: "Shree Agrasen Co-Op Bank Ltd.", label: "Shree Agrasen Co-Op Bank Ltd." },
  { value: "Shree Agrasen Co.Op. Credit Soc. Ltd", label: "Shree Agrasen Co.Op. Credit Soc. Ltd" },
  { value: "Shree Agrasen Maharaj Nagari Sah Paths May", label: "Shree Agrasen Maharaj Nagari Sah Paths May" },
  { value: "Shree Agrasen Maharaj Sahakari Panthsanstha Maryadit", label: "Shree Agrasen Maharaj Sahakari Panthsanstha Maryadit" },
  { value: "Shree Ahimasa Cr. Souharda Sahakari Ltd", label: "Shree Ahimasa Cr. Souharda Sahakari Ltd" },
  { value: "Shree Amar Financers", label: "Shree Amar Financers" },
  { value: "Shree Amardevi Nagari Sah Pat Sanstha Ltd", label: "Shree Amardevi Nagari Sah Pat Sanstha Ltd" },
  { value: "Shree Ambaji Motors", label: "Shree Ambaji Motors" },
  { value: "Shree Ambica Co-Op Credit Society Ltd", label: "Shree Ambica Co-Op Credit Society Ltd" },
  { value: "Shree Angalamman Financiers", label: "Shree Angalamman Financiers" },
  { value: "Shree Anjaneya Pathina Sahakara Sangha Niyamitha", label: "Shree Anjaneya Pathina Sahakara Sangha Niyamitha" },
  { value: "Shree Anjaneya Pthina Sahakara Sangha Niyamitha ( R )", label: "Shree Anjaneya Pthina Sahakara Sangha Niyamitha ( R )" },
  { value: "Shree Anyanya Sahayak Sahkari Mandali Ltd", label: "Shree Anyanya Sahayak Sahkari Mandali Ltd" },
  { value: "Shree Arihant Auto Finance", label: "Shree Arihant Auto Finance" },
  { value: "Shree Arihant Co-Op. Bank", label: "Shree Arihant Co-Op. Bank" },
  { value: "Shree Auto Finance", label: "Shree Auto Finance" },
  { value: "Shree Automobile Finance", label: "Shree Automobile Finance" },
  { value: "Shree Avdhoot Nagri Sahakari Path Sanstha Ltd", label: "Shree Avdhoot Nagri Sahakari Path Sanstha Ltd" },
  { value: "Shree Balaji Auto Finance", label: "Shree Balaji Auto Finance" },
  { value: "Shree Balaji Co-Op Bank Ltd.", label: "Shree Balaji Co-Op Bank Ltd." },
  { value: "Shree Balaji Enterprises", label: "Shree Balaji Enterprises" },
  { value: "Shree Balaji Fin. Com.", label: "Shree Balaji Fin. Com." },
  { value: "Shree Balaji Finvest Ltd.", label: "Shree Balaji Finvest Ltd." },
  { value: "Shree Balaji Mitramandal Finance", label: "Shree Balaji Mitramandal Finance" },
  { value: "Shree Balaji Motors And General Finance", label: "Shree Balaji Motors And General Finance" },
  { value: "Shree Bapupura Juth Co Op Credit Soc. Ltd", label: "Shree Bapupura Juth Co Op Credit Soc. Ltd" },
  { value: "Shree Basavashri Navkara Patt. S.S.Niyam", label: "Shree Basavashri Navkara Patt. S.S.Niyam" },
  { value: "Shree Basaveshwar Co-Op. Bank", label: "Shree Basaveshwar Co-Op. Bank" },
  { value: "Shree Basaveshwar Co-Op. Bank Ltd.", label: "Shree Basaveshwar Co-Op. Bank Ltd." },
  { value: "Shree Basaveshwar Co-Operative Credit Bank Ltd.", label: "Shree Basaveshwar Co-Operative Credit Bank Ltd." },
  { value: "Shree Basaveshwar Ur. Co-Op. Bank Ltd.", label: "Shree Basaveshwar Ur. Co-Op. Bank Ltd." },
  { value: "Shree Basaveshwara Urban Co-Op Bank Ltd", label: "Shree Basaveshwara Urban Co-Op Bank Ltd" },
  { value: "Shree Basvashree Nodkal Patti Sangha Niyamit Manavi", label: "Shree Basvashree Nodkal Patti Sangha Niyamit Manavi" },
  { value: "Shree Bhabhar Vibhag Nagrik Sah Bk Ltd", label: "Shree Bhabhar Vibhag Nagrik Sah Bk Ltd" },
  { value: "Shree Bhadran Merchants Co-Op Bank Ltd", label: "Shree Bhadran Merchants Co-Op Bank Ltd" },
  { value: "Shree Bhailalbhai Contr. Samarak Cp Bk L", label: "Shree Bhailalbhai Contr. Samarak Cp Bk L" },
  { value: "Shree Bhairav Finance Services", label: "Shree Bhairav Finance Services" },
  { value: "Shree Bhairavnath Laxmi Sahakari Patsanstha Ltd.", label: "Shree Bhairavnath Laxmi Sahakari Patsanstha Ltd." },
  { value: "Shree Bhairavnath Nag Saha Path Marya Sinnar", label: "Shree Bhairavnath Nag Saha Path Marya Sinnar" },
  { value: "Shree Bhairavnath Nagari Sahakari Panthsanstha Maryadit", label: "Shree Bhairavnath Nagari Sahakari Panthsanstha Maryadit" },
  { value: "Shree Bhairavnath Vikas Sah Pat San Ltd", label: "Shree Bhairavnath Vikas Sah Pat San Ltd" },
  { value: "Shree Bharat Co-Op Bank Ltd", label: "Shree Bharat Co-Op Bank Ltd" },
  { value: "Shree Bharat Co-Op. Bank Ltd", label: "Shree Bharat Co-Op. Bank Ltd" },
  { value: "Shree Bharat Motors Ltd", label: "Shree Bharat Motors Ltd" },
  { value: "Shree Bharat Urban Co Op Bank Ltd", label: "Shree Bharat Urban Co Op Bank Ltd" },
  { value: "Shree Bhavani Krupa Finance  Service", label: "Shree Bhavani Krupa Finance  Service" },
  { value: "Shree Bhavawnath Nagari Sahakari Path Sanstha", label: "Shree Bhavawnath Nagari Sahakari Path Sanstha" },
  { value: "Shree Bhavnagar Nagrik Sahakari Bank Ltd", label: "Shree Bhavnagar Nagrik Sahakari Bank Ltd" },
  { value: "Shree Bhimashankar Sahakari Patsansta Ltd.", label: "Shree Bhimashankar Sahakari Patsansta Ltd." },
  { value: "Shree Bhumi Credit Co-Op Soc Ltd", label: "Shree Bhumi Credit Co-Op Soc Ltd" },
  { value: "Shree Bhutnath Co-Operative Credit Society", label: "Shree Bhutnath Co-Operative Credit Society" },
  { value: "Shree Bhuvanam Finance", label: "Shree Bhuvanam Finance" },
  { value: "Shree Bike House", label: "Shree Bike House" },
  { value: "Shree Birdeva Auto Finance Ltd", label: "Shree Birdeva Auto Finance Ltd" },
  { value: "Shree Bodka Seva Sahakari Mandali Ltd.", label: "Shree Bodka Seva Sahakari Mandali Ltd." },
  { value: "Shree Botad Mercantile Co-Op. Bank Ltd", label: "Shree Botad Mercantile Co-Op. Bank Ltd" },
  { value: "Shree Botad Merchants Co-Op Bank Ltd", label: "Shree Botad Merchants Co-Op Bank Ltd" },
  { value: "Shree Brahmling Grameen Biger Sheti Sahakari Pattin Sanstha Maryadit Surute", label: "Shree Brahmling Grameen Biger Sheti Sahakari Pattin Sanstha Maryadit Surute" },
  { value: "Shree Bramaramb Pattin Souhard Sahakari Niyamit", label: "Shree Bramaramb Pattin Souhard Sahakari Niyamit" },
  { value: "Shree Channesh Urban Co-Op Credit Societ", label: "Shree Channesh Urban Co-Op Credit Societ" },
  { value: "Shree Charan Co-Operative Bank Ltd.", label: "Shree Charan Co-Operative Bank Ltd." },
  { value: "Shree Chavandi Juth Seva Sahakari Mandali Ltd", label: "Shree Chavandi Juth Seva Sahakari Mandali Ltd" },
  { value: "Shree Chhani Leuva Pattidar Co-Op Credit Society", label: "Shree Chhani Leuva Pattidar Co-Op Credit Society" },
  { value: "Shree Chhani Nagrik Sahakari Bank Ltd.", label: "Shree Chhani Nagrik Sahakari Bank Ltd." },
  { value: "Shree Chhatrapati Urban Co-Op Bank Ltd", label: "Shree Chhatrapati Urban Co-Op Bank Ltd" },
  { value: "Shree Co-Op Bank Ltd.", label: "Shree Co-Op Bank Ltd." },
  { value: "Shree Co-Op. Bank Ltd.", label: "Shree Co-Op. Bank Ltd." },
  { value: "Shree Credits", label: "Shree Credits" },
  { value: "Shree Crystal Co-Op Cr. Soc.;Ltd.", label: "Shree Crystal Co-Op Cr. Soc.;Ltd." },
  { value: "Shree Daiya S.S.M. Ltd", label: "Shree Daiya S.S.M. Ltd" },
  { value: "Shree Dantaleshwar Gramin Bigersheti Sahakari Maryadit", label: "Shree Dantaleshwar Gramin Bigersheti Sahakari Maryadit" },
  { value: "Shree Deepak Finance", label: "Shree Deepak Finance" },
  { value: "Shree Deesa Nagrik Sahakari Bank Ltd.", label: "Shree Deesa Nagrik Sahakari Bank Ltd." },
  { value: "Shree Devnadhi Khore G B S S P S Maryadit", label: "Shree Devnadhi Khore G B S S P S Maryadit" },
  { value: "Shree Dhaman V V K S Mandal Ltd", label: "Shree Dhaman V V K S Mandal Ltd" },
  { value: "Shree Dhanalakshmi (Agri) Co-Op Bank Ltd", label: "Shree Dhanalakshmi (Agri) Co-Op Bank Ltd" },
  { value: "Shree Dhanalaxmi Pattin Sahakar Sangha Niyamit", label: "Shree Dhanalaxmi Pattin Sahakar Sangha Niyamit" },
  { value: "Shree Dhandhuka Janta Sahakari Bank Ltd.", label: "Shree Dhandhuka Janta Sahakari Bank Ltd." },
  { value: "Shree Dhanduka Janata Sahakari Bank Ltd", label: "Shree Dhanduka Janata Sahakari Bank Ltd" },
  { value: "Shree Dhanlaxmi Fiannce", label: "Shree Dhanlaxmi Fiannce" },
  { value: "Shree Dhanlaxmi Finance", label: "Shree Dhanlaxmi Finance" },
  { value: "Shree Dhansobhavak Coop Credit Society Ltd", label: "Shree Dhansobhavak Coop Credit Society Ltd" },
  { value: "Shree Dharati Co-Op. Bank Ltd.", label: "Shree Dharati Co-Op. Bank Ltd." },
  { value: "Shree Dharati Co-Op. Bank Ltd., Rajkot", label: "Shree Dharati Co-Op. Bank Ltd., Rajkot" },
  { value: "Shree Dhuldeo Gramin Biger Sheti Sah Pat San Mar", label: "Shree Dhuldeo Gramin Biger Sheti Sah Pat San Mar" },
  { value: "Shree Dnyaneshwar Urban Credit Soc. Ltd", label: "Shree Dnyaneshwar Urban Credit Soc. Ltd" },
  { value: "Shree Doodhganga Multipurpose Co Op Credit Society Ltd", label: "Shree Doodhganga Multipurpose Co Op Credit Society Ltd" },
  { value: "Shree Durga Leasing Financiers", label: "Shree Durga Leasing Financiers" },
  { value: "Shree Durga Motors", label: "Shree Durga Motors" },
  { value: "Shree Durgadevi Nagar Sahakari Patsanstha Maryadit Nashik", label: "Shree Durgadevi Nagar Sahakari Patsanstha Maryadit Nashik" },
  { value: "Shree Dwarika Dheesh Kasganj Lease & Hire Co. Ltd", label: "Shree Dwarika Dheesh Kasganj Lease & Hire Co. Ltd" },
  { value: "Shree Dwarika Dheesh Kasganj Lease And Hire Co Ltd", label: "Shree Dwarika Dheesh Kasganj Lease And Hire Co Ltd" },
  { value: "Shree Finance", label: "Shree Finance" },
  { value: "Shree Gajanan Lokseva S.Bank Maryadit", label: "Shree Gajanan Lokseva S.Bank Maryadit" },
  { value: "Shree Gajanan Lokseva Sah Bank Ltd", label: "Shree Gajanan Lokseva Sah Bank Ltd" },
  { value: "Shree Gajanan Lokseva Sah Bank May", label: "Shree Gajanan Lokseva Sah Bank May" },
  { value: "Shree Gajanan Maharaj Gramin Bigar Sheti Patsanstha", label: "Shree Gajanan Maharaj Gramin Bigar Sheti Patsanstha" },
  { value: "Shree Gajanan Maharaj Nagari Sahakari Patsanstha", label: "Shree Gajanan Maharaj Nagari Sahakari Patsanstha" },
  { value: "Shree Gajanan Nagari Sahakari Pathsanstha", label: "Shree Gajanan Nagari Sahakari Pathsanstha" },
  { value: "Shree Gajanan Nagri Sahakari Bank Ltd.", label: "Shree Gajanan Nagri Sahakari Bank Ltd." },
  { value: "Shree Gajanan Urban Co - Op Bank Ltd", label: "Shree Gajanan Urban Co - Op Bank Ltd" },
  { value: "Shree Gajanan Urban Co-Op Bank Ltd", label: "Shree Gajanan Urban Co-Op Bank Ltd" },
  { value: "Shree Gali Anjaneya Swamy Credit Co-Op Society", label: "Shree Gali Anjaneya Swamy Credit Co-Op Society" },
  { value: "Shree Ganesh Auto Finance", label: "Shree Ganesh Auto Finance" },
  { value: "Shree Ganesh Co Op Credit Society", label: "Shree Ganesh Co Op Credit Society" },
  { value: "Shree Ganesh Enterprises", label: "Shree Ganesh Enterprises" },
  { value: "Shree Ganesh Finance Corporation", label: "Shree Ganesh Finance Corporation" },
  { value: "Shree Ganesh Financial Pvt Ltd", label: "Shree Ganesh Financial Pvt Ltd" },
  { value: "Shree Ganesh Fincap", label: "Shree Ganesh Fincap" },
  { value: "Shree Ganesh G.B.S.P.S.", label: "Shree Ganesh G.B.S.P.S." },
  { value: "Shree Ganesh Hire Purchase", label: "Shree Ganesh Hire Purchase" },
  { value: "Shree Ganesh Investment", label: "Shree Ganesh Investment" },
  { value: "Shree Ganesh Motor", label: "Shree Ganesh Motor" },
  { value: "Shree Ganesh Nagari Sah", label: "Shree Ganesh Nagari Sah" },
  { value: "Shree Ganesh Sah Patsanstha", label: "Shree Ganesh Sah Patsanstha" },
  { value: "Shree Ganesh Sah Patsanstha", label: "Shree Ganesh Sah Patsanstha" },
  { value: "Shree Ganesh Sahakari Bank Ltd.", label: "Shree Ganesh Sahakari Bank Ltd." },
  { value: "Shree Ganesh Sahakari Patasanstha Mar", label: "Shree Ganesh Sahakari Patasanstha Mar" },
  { value: "Shree Ganga Finance Pvt Ltd", label: "Shree Ganga Finance Pvt Ltd" },
  { value: "Shree Ganyani Finance", label: "Shree Ganyani Finance" },
  { value: "Shree Gaudidad Seva Sah Mandali Ltd", label: "Shree Gaudidad Seva Sah Mandali Ltd" },
  { value: "Shree Gayani Finance", label: "Shree Gayani Finance" },
  { value: "Shree Gayatri Sales Corporation", label: "Shree Gayatri Sales Corporation" },
  { value: "Shree Ghuntu Seva Sahakari Mandali Ltd", label: "Shree Ghuntu Seva Sahakari Mandali Ltd" },
  { value: "Shree Giriraj Finance Services", label: "Shree Giriraj Finance Services" },
  { value: "Shree Goga Group", label: "Shree Goga Group" },
  { value: "Shree Gogababa Nagri Sahakari Pat Sanstha Maryadit", label: "Shree Gogababa Nagri Sahakari Pat Sanstha Maryadit" },
  { value: "Shree Govindam Enterprises", label: "Shree Govindam Enterprises" },
  { value: "Shree Griraj Financial", label: "Shree Griraj Financial" },
  { value: "Shree Gundala S S M Ltd.", label: "Shree Gundala S S M Ltd." },
  { value: "Shree Gundasara Seva Sah Mandali Ltd", label: "Shree Gundasara Seva Sah Mandali Ltd" },
  { value: "Shree Guru Finance", label: "Shree Guru Finance" },
  { value: "Shree Guru Finance Leasing And Investments Corp ( R )", label: "Shree Guru Finance Leasing And Investments Corp ( R )" },
  { value: "Shree Guru Misri Finance", label: "Shree Guru Misri Finance" },
  { value: "Shree Gurudatta Nagri Sahakari Pathsanstha Maryadit", label: "Shree Gurudatta Nagri Sahakari Pathsanstha Maryadit" },
  { value: "Shree Gurudeo Finance Co", label: "Shree Gurudeo Finance Co" },
  { value: "Shree Gurusideshwar Co-Op Bank Ltd", label: "Shree Gurusideshwar Co-Op Bank Ltd" },
  { value: "Shree Hadmatiya (Golida) Juth Vikas Mandali", label: "Shree Hadmatiya (Golida) Juth Vikas Mandali" },
  { value: "Shree Hanuman Kripa Co-Op Cre. Soc. Ltd", label: "Shree Hanuman Kripa Co-Op Cre. Soc. Ltd" },
  { value: "Shree Hardatyali Juth Seva Sahakari Mandali Ltd", label: "Shree Hardatyali Juth Seva Sahakari Mandali Ltd" },
  { value: "Shree Hareshwar Sahakari Pathpedhi Ltd", label: "Shree Hareshwar Sahakari Pathpedhi Ltd" },
  { value: "Shree Honnavadevi Co -Op Credit Society Ltd", label: "Shree Honnavadevi Co -Op Credit Society Ltd" },
  { value: "Shree International Finance Ltd", label: "Shree International Finance Ltd" },
  { value: "Shree Investments", label: "Shree Investments" },
  { value: "Shree Jagannath Finvest Ltd", label: "Shree Jagannath Finvest Ltd" },
  { value: "Shree Jagdamba Mata Gramin Bigarisheti Sahkari Patsanstha Maryadi", label: "Shree Jagdamba Mata Gramin Bigarisheti Sahkari Patsanstha Maryadi" },
  { value: "Shree Jaihind Nagari Sah Pat Maryadit", label: "Shree Jaihind Nagari Sah Pat Maryadit" },
  { value: "Shree Jain Finance Co", label: "Shree Jain Finance Co" },
  { value: "Shree Jain Motor Finance", label: "Shree Jain Motor Finance" },
  { value: "Shree Jain Motors Finance", label: "Shree Jain Motors Finance" },
  { value: "Shree Jalaram Finance", label: "Shree Jalaram Finance" },
  { value: "Shree Jalaram Mercantile Co Operative Credit Society", label: "Shree Jalaram Mercantile Co Operative Credit Society" },
  { value: "Shree Jamkandorna J.S.S.M Ltd", label: "Shree Jamkandorna J.S.S.M Ltd" },
  { value: "Shree Jamnagar Nagrik Sahakari Bank Ltd.", label: "Shree Jamnagar Nagrik Sahakari Bank Ltd." },
  { value: "Shree Janata Sahakari Bank Ltd", label: "Shree Janata Sahakari Bank Ltd" },
  { value: "Shree Janta Sahkari Co Op Bank Ltd", label: "Shree Janta Sahkari Co Op Bank Ltd" },
  { value: "Shree Jarera Seva Sahkari Mandli Ltd", label: "Shree Jarera Seva Sahkari Mandli Ltd" },
  { value: "Shree Jaymil Shroff", label: "Shree Jaymil Shroff" },
  { value: "Shree Joyiling Nagari Sahakari Patsanstha Ltd", label: "Shree Joyiling Nagari Sahakari Patsanstha Ltd" },
  { value: "Shree Juni Sankli Seva Sahkari Mandali Ltd", label: "Shree Juni Sankli Seva Sahkari Mandali Ltd" },
  { value: "Shree Jyotiba Sahakari Bank Maryadit.", label: "Shree Jyotiba Sahakari Bank Maryadit." },
  { value: "Shree Jyotirling Auto Leasing ( R )", label: "Shree Jyotirling Auto Leasing ( R )" },
  { value: "Shree Kadi Nagarik Sahakari Bank Ltd.", label: "Shree Kadi Nagarik Sahakari Bank Ltd." },
  { value: "Shree Kailas Nagari Sah Pathsantha Maryadit", label: "Shree Kailas Nagari Sah Pathsantha Maryadit" },
  { value: "Shree Kalbhairavnath Sahakari Path Sanstha Ltd", label: "Shree Kalbhairavnath Sahakari Path Sanstha Ltd" },
  { value: "Shree Kalika Urban Co Credit Society Ltd", label: "Shree Kalika Urban Co Credit Society Ltd" },
  { value: "Shree Kalika Urban Co-Op Credit Society", label: "Shree Kalika Urban Co-Op Credit Society" },
  { value: "Shree Kalikadevi Co Op Society Niyamit", label: "Shree Kalikadevi Co Op Society Niyamit" },
  { value: "Shree Kalikamata Gramin B Sah Patpedhi Ltd", label: "Shree Kalikamata Gramin B Sah Patpedhi Ltd" },
  { value: "Shree Kalikamata Path Sanstha", label: "Shree Kalikamata Path Sanstha" },
  { value: "Shree Kalikamba P.S.S.", label: "Shree Kalikamba P.S.S." },
  { value: "Shree Kalka Investment", label: "Shree Kalka Investment" },
  { value: "Shree Kalmeshwar Co-Op Credit Society Ltd", label: "Shree Kalmeshwar Co-Op Credit Society Ltd" },
  { value: "Shree Kalmeshwar Sah Pat Ltd", label: "Shree Kalmeshwar Sah Pat Ltd" },
  { value: "Shree Kalyani Co-Op Bank Ltd", label: "Shree Kalyani Co-Op Bank Ltd" },
  { value: "Shree Kalyani Leasing And Finance Pvt. Ltd.", label: "Shree Kalyani Leasing And Finance Pvt. Ltd." },
  { value: "Shree Kaniya Motors", label: "Shree Kaniya Motors" },
  { value: "Shree Kankari A Finance", label: "Shree Kankari A Finance" },
  { value: "Shree Kankatya In 8-Fin Co", label: "Shree Kankatya In 8-Fin Co" },
  { value: "Shree Kannan Financiers", label: "Shree Kannan Financiers" },
  { value: "Shree Kanta Finance", label: "Shree Kanta Finance" },
  { value: "Shree Kanyaka Nagari Sahakari Bank Ltd.", label: "Shree Kanyaka Nagari Sahakari Bank Ltd." },
  { value: "Shree Katlary Kariyana Marchant Sahakari Sharafi Mandli Ltd.", label: "Shree Katlary Kariyana Marchant Sahakari Sharafi Mandli Ltd." },
  { value: "Shree Khamta Juth Seva Sahkari Mandali Ltd", label: "Shree Khamta Juth Seva Sahkari Mandali Ltd" },
  { value: "Shree Khandeshwar Sahakari Patsantha Maryadit", label: "Shree Khandeshwar Sahakari Patsantha Maryadit" },
  { value: "Shree Kherva Co-Op Credit Society Ltd", label: "Shree Kherva Co-Op Credit Society Ltd" },
  { value: "Shree Khijadiya Juth Seva Sah Mandali Ltd", label: "Shree Khijadiya Juth Seva Sah Mandali Ltd" },
  { value: "Shree Khirasara Juth Seva Sahakari Mandli Ltd", label: "Shree Khirasara Juth Seva Sahakari Mandli Ltd" },
  { value: "Shree Khirasara Juth Seva Sahkari Mandli Ltd", label: "Shree Khirasara Juth Seva Sahkari Mandli Ltd" },
  { value: "Shree Khodiyar Trading Co.", label: "Shree Khodiyar Trading Co." },
  { value: "Shree Kishan Seva Sahkari Mandali Ltd", label: "Shree Kishan Seva Sahkari Mandali Ltd" },
  { value: "Shree Klarana Co Op Bank Ltd", label: "Shree Klarana Co Op Bank Ltd" },
  { value: "Shree Kothariya Seva Sahkari Mandali Ltd", label: "Shree Kothariya Seva Sahkari Mandali Ltd" },
  { value: "Shree Krishna Enterprises", label: "Shree Krishna Enterprises" },
  { value: "Shree Krishna Hire Purchase", label: "Shree Krishna Hire Purchase" },
  { value: "Shree Krishna Motors", label: "Shree Krishna Motors" },
  { value: "Shree Kristal Co Op Cr Soc Ltd", label: "Shree Kristal Co Op Cr Soc Ltd" },
  { value: "Shree Krupa", label: "Shree Krupa" },
  { value: "Shree Kuber Agency", label: "Shree Kuber Agency" },
  { value: "Shree Kuber Auto Finance Corporation", label: "Shree Kuber Auto Finance Corporation" },
  { value: "Shree Kulswami Co-Op Credit Soc. Ltd", label: "Shree Kulswami Co-Op Credit Soc. Ltd" },
  { value: "Shree Kulswami Co-Op Credit Soc. Ltd.", label: "Shree Kulswami Co-Op Credit Soc. Ltd." },
  { value: "Shree Kulswami Doodh Udpadakanchi Gramin Bigarsheti Patsanstha Maryadit", label: "Shree Kulswami Doodh Udpadakanchi Gramin Bigarsheti Patsanstha Maryadit" },
  { value: "Shree Lathi Vibhagiya Nagrik Saha Bk Ltd", label: "Shree Lathi Vibhagiya Nagrik Saha Bk Ltd" },
  { value: "Shree Laxman Nagari Sah Path Sanstha Ltd", label: "Shree Laxman Nagari Sah Path Sanstha Ltd" },
  { value: "Shree Laxmi Financial Associate", label: "Shree Laxmi Financial Associate" },
  { value: "Shree Laxmi Gra. Big Sheti Sah", label: "Shree Laxmi Gra. Big Sheti Sah" },
  { value: "Shree Laxmikrupa Urban Co-Operative Bank Ltd", label: "Shree Laxmikrupa Urban Co-Operative Bank Ltd" },
  { value: "Shree Laxminarayan Ur Co Op Cr So Ltd", label: "Shree Laxminarayan Ur Co Op Cr So Ltd" },
  { value: "Shree Limbdi Vibhagiya Nagrik Sahak.Bank", label: "Shree Limbdi Vibhagiya Nagrik Sahak.Bank" },
  { value: "Shree Lodha Nag Sahakari Bank", label: "Shree Lodha Nag Sahakari Bank" },
  { value: "Shree Lodra Nagarik Sah. Bk Ltd.", label: "Shree Lodra Nagarik Sah. Bk Ltd." },
  { value: "Shree Lodra Nagrik Sahkari Bank", label: "Shree Lodra Nagrik Sahkari Bank" },
  { value: "Shree Madhi  S.V.Ng.M.Ltd", label: "Shree Madhi  S.V.Ng.M.Ltd" },
  { value: "Shree Mahabaleshwar Co-Op Bank Ltd,", label: "Shree Mahabaleshwar Co-Op Bank Ltd," },
  { value: "Shree Mahableshwar Urban Co-Op Bank Ltd", label: "Shree Mahableshwar Urban Co-Op Bank Ltd" },
  { value: "Shree Mahadeshwar Sahakari Patpadhi Ltd", label: "Shree Mahadeshwar Sahakari Patpadhi Ltd" },
  { value: "Shree Mahadev Leasing ( R )", label: "Shree Mahadev Leasing ( R )" },
  { value: "Shree Mahaganapati Multipurpose Co-Op Cr Society", label: "Shree Mahaganapati Multipurpose Co-Op Cr Society" },
  { value: "Shree Mahalaxmi Gramin Bigarsheti Patsanstha Ltd.", label: "Shree Mahalaxmi Gramin Bigarsheti Patsanstha Ltd." },
  { value: "Shree Mahalaxmi Mer. Co-Op Bank Ltd.", label: "Shree Mahalaxmi Mer. Co-Op Bank Ltd." },
  { value: "Shree Mahalaxmi Mercantile", label: "Shree Mahalaxmi Mercantile" },
  { value: "Shree Mahalaxmi Mercantile Co. Bank Ltd", label: "Shree Mahalaxmi Mercantile Co. Bank Ltd" },
  { value: "Shree Mahalaxmi Mercantile Co. Op. Bank", label: "Shree Mahalaxmi Mercantile Co. Op. Bank" },
  { value: "Shree Mahalaxmi Multi State Co Op Credit Society Ltd", label: "Shree Mahalaxmi Multi State Co Op Credit Society Ltd" },
  { value: "Shree Mahalaxmi Urban Co-Op Bank Ltd", label: "Shree Mahalaxmi Urban Co-Op Bank Ltd" },
  { value: "Shree Mahaveer Gramin  Bigar Sheti Sahakari Patsanstha Maryadit", label: "Shree Mahaveer Gramin  Bigar Sheti Sahakari Patsanstha Maryadit" },
  { value: "Shree Mahavir Co-Op.Bank", label: "Shree Mahavir Co-Op.Bank" },
  { value: "Shree Mahavir Sahakari Bank Ltd.", label: "Shree Mahavir Sahakari Bank Ltd." },
  { value: "Shree Mahesh Sahakari Bank Ltd", label: "Shree Mahesh Sahakari Bank Ltd" },
  { value: "Shree Maheshwari Gramin Bigarsheti Patsanstha Ltd  Pen Vashi", label: "Shree Maheshwari Gramin Bigarsheti Patsanstha Ltd  Pen Vashi" },
  { value: "Shree Mahila Vikas Ur Co Op So Ltd", label: "Shree Mahila Vikas Ur Co Op So Ltd" },
  { value: "Shree Mahila Vikas Ur.Co.Op.Co.Cr.So", label: "Shree Mahila Vikas Ur.Co.Op.Co.Cr.So" },
  { value: "Shree Mahuva Nagrik Sahakari Bank Ltd.", label: "Shree Mahuva Nagrik Sahakari Bank Ltd." },
  { value: "Shree Malekarni Co Op Cr Soc Ltd", label: "Shree Malekarni Co Op Cr Soc Ltd" },
  { value: "Shree Maruthi Credit Co Operatice  Society Ltd", label: "Shree Maruthi Credit Co Operatice  Society Ltd" },
  { value: "Shree Mata Co-Op Credit Society Ltd", label: "Shree Mata Co-Op Credit Society Ltd" },
  { value: "Shree Mataji Enterprises", label: "Shree Mataji Enterprises" },
  { value: "Shree Mesariya Juth Seva Sahakari Mandli Ltd", label: "Shree Mesariya Juth Seva Sahakari Mandli Ltd" },
  { value: "Shree Mevvasa Jvssm Ltd", label: "Shree Mevvasa Jvssm Ltd" },
  { value: "Shree Milap Credit Co-Op Society", label: "Shree Milap Credit Co-Op Society" },
  { value: "Shree Morbi Nagarik Sahakari Bank Ltd.", label: "Shree Morbi Nagarik Sahakari Bank Ltd." },
  { value: "Shree Mota Mava Seva Sahkari Mandli Ltd", label: "Shree Mota Mava Seva Sahkari Mandli Ltd" },
  { value: "Shree Murli Manoar Nagari Sah Pat Myd", label: "Shree Murli Manoar Nagari Sah Pat Myd" },
  { value: "Shree Murugharajendra Co-Op Bank Ltd", label: "Shree Murugharajendra Co-Op Bank Ltd" },
  { value: "Shree Nagari Sahakari Patsanstha Maryadit", label: "Shree Nagari Sahakari Patsanstha Maryadit" },
  { value: "Shree Nagaric Sahakari Pat Santha", label: "Shree Nagaric Sahakari Pat Santha" },
  { value: "Shree Namokar Finance Co", label: "Shree Namokar Finance Co" },
  { value: "Shree Nani Marad Seva Sahakari Mandali Ltd.", label: "Shree Nani Marad Seva Sahakari Mandali Ltd." },
  { value: "Shree Narayan Guru Co Op Bank Ltd", label: "Shree Narayan Guru Co Op Bank Ltd" },
  { value: "Shree Nasitpar Juth Seva Sahkari Mandali Ltd.", label: "Shree Nasitpar Juth Seva Sahkari Mandali Ltd." },
  { value: "Shree Navdurga Nagri Sahakari Pat Sanstha", label: "Shree Navdurga Nagri Sahakari Pat Sanstha" },
  { value: "Shree Navyug Ur Co Op Cr So Ltd", label: "Shree Navyug Ur Co Op Cr So Ltd" },
  { value: "Shree Om Invest Surat", label: "Shree Om Invest Surat" },
  { value: "Shree Omkar Co-Op Credit Soc Ltd", label: "Shree Omkar Co-Op Credit Soc Ltd" },
  { value: "Shree Padmavati Finance Corporation", label: "Shree Padmavati Finance Corporation" },
  { value: "Shree Paisafund Shetaki Sahakari Bank Ltd", label: "Shree Paisafund Shetaki Sahakari Bank Ltd" },
  { value: "Shree Palchand Kothari", label: "Shree Palchand Kothari" },
  { value: "Shree Panchasar Seva Sahakari Mandli Ltd", label: "Shree Panchasar Seva Sahakari Mandli Ltd" },
  { value: "Shree Panchganga Nagari Saha. Bank Ltd., Kolhapur", label: "Shree Panchganga Nagari Saha. Bank Ltd., Kolhapur" },
  { value: "Shree Paras Padmavati", label: "Shree Paras Padmavati" },
  { value: "Shree Paras Sahakari Pat Sanstha Ltd,", label: "Shree Paras Sahakari Pat Sanstha Ltd," },
  { value: "Shree Parshuram Nagari Sahakari Pathsanstha", label: "Shree Parshuram Nagari Sahakari Pathsanstha" },
  { value: "Shree Parshwanath G. B. S. S. P. S.", label: "Shree Parshwanath G. B. S. S. P. S." },
  { value: "Shree Parshwanath N.Sah.Patsanstha Marya", label: "Shree Parshwanath N.Sah.Patsanstha Marya" },
  { value: "Shree Parshwanath Nagari Sah Pat Sanstha Maryadit", label: "Shree Parshwanath Nagari Sah Pat Sanstha Maryadit" },
  { value: "Shree Pathrakali Finance Co Ltd.", label: "Shree Pathrakali Finance Co Ltd." },
  { value: "Shree Patneshwar Urban Co-Op Bank", label: "Shree Patneshwar Urban Co-Op Bank" },
  { value: "Shree Patneshwar Urban Co-Op Bank Ltd.", label: "Shree Patneshwar Urban Co-Op Bank Ltd." },
  { value: "Shree Patni Co-Op Bank Ltd", label: "Shree Patni Co-Op Bank Ltd" },
  { value: "Shree Patni Co-Op. Bank Ltd.", label: "Shree Patni Co-Op. Bank Ltd." },
  { value: "Shree Pipardi Seva Sahakari Mandli Ltd", label: "Shree Pipardi Seva Sahakari Mandli Ltd" },
  { value: "Shree Pragati Pattan Sahakari Bank Niya", label: "Shree Pragati Pattan Sahakari Bank Niya" },
  { value: "Shree Pravira Nagari Sah Bandhakam Sahitya Vyav Pat Sanstha Maryadit", label: "Shree Pravira Nagari Sah Bandhakam Sahitya Vyav Pat Sanstha Maryadit" },
  { value: "Shree Prem Finance", label: "Shree Prem Finance" },
  { value: "Shree Premier Leasing ( R )", label: "Shree Premier Leasing ( R )" },
  { value: "Shree Puja Jyoti Co Op. Credit Society Ltd.", label: "Shree Puja Jyoti Co Op. Credit Society Ltd." },
  { value: "Shree Raajaganapathi Aout Finance", label: "Shree Raajaganapathi Aout Finance" },
  { value: "Shree Raj Co-Op-Credit Society Ltd.", label: "Shree Raj Co-Op-Credit Society Ltd." },
  { value: "Shree Raj Finance", label: "Shree Raj Finance" },
  { value: "Shree Rajendra Suri Nagrik Sahakari Bank", label: "Shree Rajendra Suri Nagrik Sahakari Bank" },
  { value: "Shree Rajhans Nagari Sah Patsanshta Sangamner", label: "Shree Rajhans Nagari Sah Patsanshta Sangamner" },
  { value: "Shree Rajkot Dist. Co.Op B. L, Khareda.", label: "Shree Rajkot Dist. Co.Op B. L, Khareda." },
  { value: "Shree Rajkot Dist. Co.Op B. L, Pipariya", label: "Shree Rajkot Dist. Co.Op B. L, Pipariya" },
  { value: "Shree Rajkot District Co Op Bank", label: "Shree Rajkot District Co Op Bank" },
  { value: "Shree Rajkot District Co-Op. Bank Ltd", label: "Shree Rajkot District Co-Op. Bank Ltd" },
  { value: "Shree Ram Agency", label: "Shree Ram Agency" },
  { value: "Shree Ram City Union Finance Ltd", label: "Shree Ram City Union Finance Ltd" },
  { value: "Shree Ram Co Op Credit Soc", label: "Shree Ram Co Op Credit Soc" },
  { value: "Shree Ram Co-Op Credit Bank Ltd", label: "Shree Ram Co-Op Credit Bank Ltd" },
  { value: "Shree Ram Consultancy", label: "Shree Ram Consultancy" },
  { value: "Shree Ram Credit Co Op Society", label: "Shree Ram Credit Co Op Society" },
  { value: "Shree Ram Fainance", label: "Shree Ram Fainance" },
  { value: "Shree Ram Sahakari Bank Ltd", label: "Shree Ram Sahakari Bank Ltd" },
  { value: "Shree Ram Sahakari Bank Ltd,", label: "Shree Ram Sahakari Bank Ltd," },
  { value: "Shree Ram Transport Finance Company Ltd", label: "Shree Ram Transport Finance Company Ltd" },
  { value: "Shree Ram Transport Financial Services Ltd", label: "Shree Ram Transport Financial Services Ltd" },
  { value: "Shree Ram Transport Union Finance Co Ltd", label: "Shree Ram Transport Union Finance Co Ltd" },
  { value: "Shree Ram Urban Co-Op Bank Ltd.", label: "Shree Ram Urban Co-Op Bank Ltd." },
  { value: "Shree Ramchandra Nagari Sah Patsanstha", label: "Shree Ramchandra Nagari Sah Patsanstha" },
  { value: "Shree Rameshwar Nagari Sah Pat San Mydt", label: "Shree Rameshwar Nagari Sah Pat San Mydt" },
  { value: "Shree Rangdas Swami Sah Path Ltd", label: "Shree Rangdas Swami Sah Path Ltd" },
  { value: "Shree Ransiki Ssm Ltd", label: "Shree Ransiki Ssm Ltd" },
  { value: "Shree Raosaheb Sitaram Patil Nag Panth", label: "Shree Raosaheb Sitaram Patil Nag Panth" },
  { value: "Shree Ratneshwari Co-Op Credit Society", label: "Shree Ratneshwari Co-Op Credit Society" },
  { value: "Shree Renuka Co-Op Credit Society Belgaum", label: "Shree Renuka Co-Op Credit Society Belgaum" },
  { value: "Shree Renuka Parameswari Finance", label: "Shree Renuka Parameswari Finance" },
  { value: "Shree Rukmini Sahakari Bank Ltd.", label: "Shree Rukmini Sahakari Bank Ltd." },
  { value: "Shree Sadguru Gramin Bigar Shetti Sahakari Path S. Ltd.", label: "Shree Sadguru Gramin Bigar Shetti Sahakari Path S. Ltd." },
  { value: "Shree Sadguru Jangali Maharaj Sahakari Bank Ltd., Pune", label: "Shree Sadguru Jangali Maharaj Sahakari Bank Ltd., Pune" },
  { value: "Shree Sadguru Jangli Maharaj Sah. Bk Ltd", label: "Shree Sadguru Jangli Maharaj Sah. Bk Ltd" },
  { value: "Shree Sadguru Narayan Finance Co", label: "Shree Sadguru Narayan Finance Co" },
  { value: "Shree Sai Auto Finance", label: "Shree Sai Auto Finance" },
  { value: "Shree Sai Nagari Sah Patsanstha Ltd", label: "Shree Sai Nagari Sah Patsanstha Ltd" },
  { value: "Shree Sai Seva Co-Op Credit Soc. Ltd", label: "Shree Sai Seva Co-Op Credit Soc. Ltd" },
  { value: "Shree Sai Ur Co - Op Cr Society Ltd", label: "Shree Sai Ur Co - Op Cr Society Ltd" },
  { value: "Shree Saikrupa Enterprise", label: "Shree Saikrupa Enterprise" },
  { value: "Shree Sainath Gramin Big.Sah.Pat.L.", label: "Shree Sainath Gramin Big.Sah.Pat.L." },
  { value: "Shree Saipariwar Co Op Cr Soc Ltd", label: "Shree Saipariwar Co Op Cr Soc Ltd" },
  { value: "Shree Sairam Enterprise", label: "Shree Sairam Enterprise" },
  { value: "Shree Sajadiyadi Seva Sahakari Mandli Ltd", label: "Shree Sajadiyadi Seva Sahakari Mandli Ltd" },
  { value: "Shree Salsidheswar Nagri Sahakari Ptpedhi", label: "Shree Salsidheswar Nagri Sahakari Ptpedhi" },
  { value: "Shree Samarath Leesing, Gulbarga", label: "Shree Samarath Leesing, Gulbarga" },
  { value: "Shree Samarath Leesing, Gulbarga", label: "Shree Samarath Leesing, Gulbarga" },
  { value: "Shree Samarth Gramin Bigar Sheti Sahakari Pathsanstha", label: "Shree Samarth Gramin Bigar Sheti Sahakari Pathsanstha" },
  { value: "Shree Samarth Sadguru Co Op Credit Soc Ltd", label: "Shree Samarth Sadguru Co Op Credit Soc Ltd" },
  { value: "Shree Samarth Sahakari Bank Ltd., Nasik", label: "Shree Samarth Sahakari Bank Ltd., Nasik" },
  { value: "Shree Sangam Finance And Leasing", label: "Shree Sangam Finance And Leasing" },
  { value: "Shree Sant Nagebaba Gramin Bigar Sheti", label: "Shree Sant Nagebaba Gramin Bigar Sheti" },
  { value: "Shree Sant Nagebaba Gramin Patsanstha", label: "Shree Sant Nagebaba Gramin Patsanstha" },
  { value: "Shree Santaji Maharaj Nagari Sahkari Patsanstha Maryadit, Nashik", label: "Shree Santaji Maharaj Nagari Sahkari Patsanstha Maryadit, Nashik" },
  { value: "Shree Sapttashringi Co-Op Soc Ltd.", label: "Shree Sapttashringi Co-Op Soc Ltd." },
  { value: "Shree Saraswathi Credit", label: "Shree Saraswathi Credit" },
  { value: "Shree Sardar Patel Co Op Credit Society Ltd", label: "Shree Sardar Patel Co Op Credit Society Ltd" },
  { value: "Shree Sardar Patel Co-Operative Bank Ltd", label: "Shree Sardar Patel Co-Operative Bank Ltd" },
  { value: "Shree Sarvoday Ur Co Op So Ltd", label: "Shree Sarvoday Ur Co Op So Ltd" },
  { value: "Shree Savarkundla Nagrik Sah. Bank Ltd", label: "Shree Savarkundla Nagrik Sah. Bank Ltd" },
  { value: "Shree Savli Nagrik Sahakari Bank Ltd.", label: "Shree Savli Nagrik Sahakari Bank Ltd." },
  { value: "Shree Savli Nagrik Sahakari Co Op Bank", label: "Shree Savli Nagrik Sahakari Co Op Bank" },
  { value: "Shree Sawaliya Trading Company", label: "Shree Sawaliya Trading Company" },
  { value: "Shree Sayona Leasing And Finance", label: "Shree Sayona Leasing And Finance" },
  { value: "Shree Senthil Marthi Finance", label: "Shree Senthil Marthi Finance" },
  { value: "Shree Shaheed Kharachiya Juth Seva Sahakari Mandali Ltd", label: "Shree Shaheed Kharachiya Juth Seva Sahakari Mandali Ltd" },
  { value: "Shree Shakti Co Op Credit Society Ltd.", label: "Shree Shakti Co Op Credit Society Ltd." },
  { value: "Shree Shakti Coop Credit Society Ltd", label: "Shree Shakti Coop Credit Society Ltd" },
  { value: "Shree Shakti Sarafi Sarkari Mandli Limited", label: "Shree Shakti Sarafi Sarkari Mandli Limited" },
  { value: "Shree Shambhavi Auto Leasing", label: "Shree Shambhavi Auto Leasing" },
  { value: "Shree Shampar Madhapar Juth Seva Sahkari Mandli Ltd", label: "Shree Shampar Madhapar Juth Seva Sahkari Mandli Ltd" },
  { value: "Shree Shankeshwar Nagari Sah Pat Ltd", label: "Shree Shankeshwar Nagari Sah Pat Ltd" },
  { value: "Shree Shanteshwar Pattin Shakari Sangh Niyamit Horti", label: "Shree Shanteshwar Pattin Shakari Sangh Niyamit Horti" },
  { value: "Shree Shanthi", label: "Shree Shanthi" },
  { value: "Shree Shanthi And Company", label: "Shree Shanthi And Company" },
  { value: "Shree Shanthi Finance", label: "Shree Shanthi Finance" },
  { value: "Shree Shanti Suri Finance Corporation", label: "Shree Shanti Suri Finance Corporation" },
  { value: "Shree Sharan Sagar Auto Leasing {R}", label: "Shree Sharan Sagar Auto Leasing {R}" },
  { value: "Shree Sharda Sahakari Bank Ltd., Pune", label: "Shree Sharda Sahakari Bank Ltd., Pune" },
  { value: "Shree Shawariya Trading Co", label: "Shree Shawariya Trading Co" },
  { value: "Shree Sheshadri Leasing And Finance Company Ltd", label: "Shree Sheshadri Leasing And Finance Company Ltd" },
  { value: "Shree Shiddhivinayak Nagari Sah. Bank Lt", label: "Shree Shiddhivinayak Nagari Sah. Bank Lt" },
  { value: "Shree Shihori Nagrik Sahakari Bk Ltd.,", label: "Shree Shihori Nagrik Sahakari Bk Ltd.," },
  { value: "Shree Shiva Sai Financial Services", label: "Shree Shiva Sai Financial Services" },
  { value: "Shree Shivaji Souharda P.S.S", label: "Shree Shivaji Souharda P.S.S" },
  { value: "Shree Shivam Manglam Finance Company", label: "Shree Shivam Manglam Finance Company" },
  { value: "Shree Shivkrisna Gramin Bigar Sheti Path Sanstha", label: "Shree Shivkrisna Gramin Bigar Sheti Path Sanstha" },
  { value: "Shree Shivneri Sahakari Bank Ltd.", label: "Shree Shivneri Sahakari Bank Ltd." },
  { value: "Shree Shramdeep Urban Co,Op, Credit Soc, Ltd", label: "Shree Shramdeep Urban Co,Op, Credit Soc, Ltd" },
  { value: "Shree Shramdeep Urben Co Op Cr Soc Ltd", label: "Shree Shramdeep Urben Co Op Cr Soc Ltd" },
  { value: "Shree Siddeshwar  Co-Op Bank Ltd", label: "Shree Siddeshwar  Co-Op Bank Ltd" },
  { value: "Shree Siddh Finance", label: "Shree Siddh Finance" },
  { value: "Shree Siddh Finance Corporation", label: "Shree Siddh Finance Corporation" },
  { value: "Shree Siddharath Sahkari Patpedhi Maryadit", label: "Shree Siddharath Sahkari Patpedhi Maryadit" },
  { value: "Shree Siddharth Sahkari Patpethi Mayurdit", label: "Shree Siddharth Sahkari Patpethi Mayurdit" },
  { value: "Shree Siddhi Finance", label: "Shree Siddhi Finance" },
  { value: "Shree Siddhivinayak Nagari Sahakari Bank Ltd", label: "Shree Siddhivinayak Nagari Sahakari Bank Ltd" },
  { value: "Shree Sidhashri Souharad Sahakari Niyamit", label: "Shree Sidhashri Souharad Sahakari Niyamit" },
  { value: "Shree Sinnar Vyapari Sahakari Bank Ltd.", label: "Shree Sinnar Vyapari Sahakari Bank Ltd." },
  { value: "Shree Sinnar Vyapari Sahakari Bank, Ltd., Sinnar", label: "Shree Sinnar Vyapari Sahakari Bank, Ltd., Sinnar" },
  { value: "Shree Someshwar Nagri Sahakari Pathsanstha", label: "Shree Someshwar Nagri Sahakari Pathsanstha" },
  { value: "Shree Subhlaxmi Co-Op Credit So. Ltd", label: "Shree Subhlaxmi Co-Op Credit So. Ltd" },
  { value: "Shree Subramanya Sahakara Sangh (R)", label: "Shree Subramanya Sahakara Sangh (R)" },
  { value: "Shree Suganmalaji Swarna Pathsanstha Vyapari Sahakari Pathsastha Maryadit", label: "Shree Suganmalaji Swarna Pathsanstha Vyapari Sahakari Pathsastha Maryadit" },
  { value: "Shree Suswani Investment", label: "Shree Suswani Investment" },
  { value: "Shree Suvarna Sah. Bank Ltd.", label: "Shree Suvarna Sah. Bank Ltd." },
  { value: "Shree Suvarna Sahakari Bank Ltd", label: "Shree Suvarna Sahakari Bank Ltd" },
  { value: "Shree Swaminarayan Co-Op Bank", label: "Shree Swaminarayan Co-Op Bank" },
  { value: "Shree T S Gramin Biger Shei Sahakari Patsanstha Maryadit", label: "Shree T S Gramin Biger Shei Sahakari Patsanstha Maryadit" },
  { value: "Shree Tatyasaheb Mohite Nagari Sahakari Pathpedi Ltd", label: "Shree Tatyasaheb Mohite Nagari Sahakari Pathpedi Ltd" },
  { value: "Shree Thanagalol Sarvoday Seva Sah. Mandli Ltd", label: "Shree Thanagalol Sarvoday Seva Sah. Mandli Ltd" },
  { value: "Shree Thirumala Auto In Fin Private Ltd", label: "Shree Thirumala Auto In Fin Private Ltd" },
  { value: "Shree Thyagaraja Co-Op Bank Ltd.", label: "Shree Thyagaraja Co-Op Bank Ltd." },
  { value: "Shree Tirupati Credit Sahakari Mandli Ltd", label: "Shree Tirupati Credit Sahakari Mandli Ltd" },
  { value: "Shree Trading Corp Narwal", label: "Shree Trading Corp Narwal" },
  { value: "Shree Tukaram Co-Op Bank Ltd", label: "Shree Tukaram Co-Op Bank Ltd" },
  { value: "Shree Tukaram Co-Op. Bank Ltd.", label: "Shree Tukaram Co-Op. Bank Ltd." },
  { value: "Shree Tuljabhavani Nagari Sanstha Maryadit", label: "Shree Tuljabhavani Nagari Sanstha Maryadit" },
  { value: "Shree Uma Bavan Co Op Credit Society Ltd", label: "Shree Uma Bavan Co Op Credit Society Ltd" },
  { value: "Shree Umiya Co Op Credit Society Ltd", label: "Shree Umiya Co Op Credit Society Ltd" },
  { value: "Shree Uttung Auto Financier ( R )", label: "Shree Uttung Auto Financier ( R )" },
  { value: "Shree Vaghasiya Juth Seva Sahkari Mandli Ltd", label: "Shree Vaghasiya Juth Seva Sahkari Mandli Ltd" },
  { value: "Shree Vaishnavi Auto Finance Corporation", label: "Shree Vaishnavi Auto Finance Corporation" },
  { value: "Shree Vardhaman Co-Op. Bank Ltd", label: "Shree Vardhaman Co-Op. Bank Ltd" },
  { value: "Shree Vardhaman Sahakari Bank Ltd.", label: "Shree Vardhaman Sahakari Bank Ltd." },
  { value: "Shree Vardhman Co Op Bank", label: "Shree Vardhman Co Op Bank" },
  { value: "Shree Variya Co Op Credit Soc", label: "Shree Variya Co Op Credit Soc" },
  { value: "Shree Varna Sah Bank Ltd", label: "Shree Varna Sah Bank Ltd" },
  { value: "Shree Vasundhara Finance", label: "Shree Vasundhara Finance" },
  { value: "Shree Veershaiv Co Op Bank Ltd.", label: "Shree Veershaiv Co Op Bank Ltd." },
  { value: "Shree Vejalporevibhag Seva Sahakari Mandali Ltd", label: "Shree Vejalporevibhag Seva Sahakari Mandali Ltd" },
  { value: "Shree Veraval Juth Seva Sahkari Mandli Ltd", label: "Shree Veraval Juth Seva Sahkari Mandli Ltd" },
  { value: "Shree Vijay Shanti Finance", label: "Shree Vijay Shanti Finance" },
  { value: "Shree Vikas Co.Op.Bank Ltd., Surat", label: "Shree Vikas Co.Op.Bank Ltd., Surat" },
  { value: "Shree Vinayak Sahakari Bank Ltd", label: "Shree Vinayak Sahakari Bank Ltd" },
  { value: "Shree Vinayak Sowharda Credit Co-Op Siddapur", label: "Shree Vinayak Sowharda Credit Co-Op Siddapur" },
  { value: "Shree Vinayaka Multipurpose Socieety Sahakari Ltd Belgaum", label: "Shree Vinayaka Multipurpose Socieety Sahakari Ltd Belgaum" },
  { value: "Shree Vir Transport Co Op Credit  And Service Soc Ltd.", label: "Shree Vir Transport Co Op Credit  And Service Soc Ltd." },
  { value: "Shree Virpur Urban Sahakari Bank Ltd", label: "Shree Virpur Urban Sahakari Bank Ltd" },
  { value: "Shree Virshiva Co-Op Bank Ltd", label: "Shree Virshiva Co-Op Bank Ltd" },
  { value: "Shree Vishnu Financiers", label: "Shree Vishnu Financiers" },
  { value: "Shree Vishvanath Motor Finance", label: "Shree Vishvanath Motor Finance" },
  { value: "Shree Vithal Rakhumai Co-Operative Society Ltd", label: "Shree Vithal Rakhumai Co-Operative Society Ltd" },
  { value: "Shree Vitthal Rakhumai Co-Operative Society Ltd", label: "Shree Vitthal Rakhumai Co-Operative Society Ltd" },
  { value: "Shree Vyas Dhanvarsha Sahakari Bank Ltd", label: "Shree Vyas Dhanvarsha Sahakari Bank Ltd" },
  { value: "Shree Warana S. Bank Ltd.", label: "Shree Warana S. Bank Ltd." },
  { value: "Shree Warana Sahakari Bank Ltd.", label: "Shree Warana Sahakari Bank Ltd." },
  { value: "Shree Warna Co-Op Bank Ltd.", label: "Shree Warna Co-Op Bank Ltd." },
  { value: "Shreedhan Auto Finance", label: "Shreedhan Auto Finance" },
  { value: "Shreee Mahaganapati Multipurpose Co-Op Cr Society", label: "Shreee Mahaganapati Multipurpose Co-Op Cr Society" },
  { value: "Shreehak P. Ltd.", label: "Shreehak P. Ltd." },
  { value: "Shreeji Bhatia Co-Op Bank Ltd", label: "Shreeji Bhatia Co-Op Bank Ltd" },
  { value: "Shreeji Finance", label: "Shreeji Finance" },
  { value: "Shreejivapar Seva Sahakari Mandli Ltd", label: "Shreejivapar Seva Sahakari Mandli Ltd" },
  { value: "Shreejivilla Cooperative Housing Society Ltd", label: "Shreejivilla Cooperative Housing Society Ltd" },
  { value: "Shreekrishna Nagrik Sahakari Pathpethi Ltd", label: "Shreekrishna Nagrik Sahakari Pathpethi Ltd" },
  { value: "Shreekunj Securities Pvt Ltd", label: "Shreekunj Securities Pvt Ltd" },
  { value: "Shreeman Babulalji Bramecha Gra.Shesha Path", label: "Shreeman Babulalji Bramecha Gra.Shesha Path" },
  { value: "Shreenath Automobiles", label: "Shreenath Automobiles" },
  { value: "Shreenath Co-Op Credit Society Ltd", label: "Shreenath Co-Op Credit Society Ltd" },
  { value: "Shreenath Co-Operative Bank Ltd.", label: "Shreenath Co-Operative Bank Ltd." },
  { value: "Shreenath Finance", label: "Shreenath Finance" },
  { value: "Shreepal Chand Kothari", label: "Shreepal Chand Kothari" },
  { value: "Shreepal Enterprises", label: "Shreepal Enterprises" },
  { value: "Shreepal Lalwani & Sons Huf", label: "Shreepal Lalwani & Sons Huf" },
  { value: "Shreepantkrupa Co-Op Credit Soc Ltd", label: "Shreepantkrupa Co-Op Credit Soc Ltd" },
  { value: "Shreeraj Travels And Tours Limited", label: "Shreeraj Travels And Tours Limited" },
  { value: "Shreeram City Finance", label: "Shreeram City Finance" },
  { value: "Shreeram Gramin Bigar Sheti Sahakari Path Sanstha Maryadit", label: "Shreeram Gramin Bigar Sheti Sahakari Path Sanstha Maryadit" },
  { value: "Shreeram Gramin Biger Sheti Sah Pat San Maryadit", label: "Shreeram Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Shreeram Nagari Sahakari Patsantha Ltd", label: "Shreeram Nagari Sahakari Patsantha Ltd" },
  { value: "Shreeram Sah Pat Ltd", label: "Shreeram Sah Pat Ltd" },
  { value: "Shreerama Credit Co-Operative Ltd.", label: "Shreerama Credit Co-Operative Ltd." },
  { value: "Shreeshanthi And Co", label: "Shreeshanthi And Co" },
  { value: "Shregajanan Soursha Ltd, Sankeshwar", label: "Shregajanan Soursha Ltd, Sankeshwar" },
  { value: "Shregajanan Soursha Ltd, Sankeshwar", label: "Shregajanan Soursha Ltd, Sankeshwar" },
  { value: "Shrei Infracture Finance Ltd", label: "Shrei Infracture Finance Ltd" },
  { value: "Shresya Gramin Bank", label: "Shresya Gramin Bank" },
  { value: "Shreya Enterprise", label: "Shreya Enterprise" },
  { value: "Shreya Finance Co. Ltd", label: "Shreya Finance Co. Ltd" },
  { value: "Shreyans Enterprises", label: "Shreyans Enterprises" },
  { value: "Shreyans Finance And Capital Services Ltd", label: "Shreyans Finance And Capital Services Ltd" },
  { value: "Shreyansh Auto Finance", label: "Shreyansh Auto Finance" },
  { value: "Shreyansh Investment", label: "Shreyansh Investment" },
  { value: "Shreyas Co-Operative Bank Ltd.", label: "Shreyas Co-Operative Bank Ltd." },
  { value: "Shreyas Grameen Bank", label: "Shreyas Grameen Bank" },
  { value: "Shreyas Gramin Mehdu", label: "Shreyas Gramin Mehdu" },
  { value: "Shri Adinath Co-Op.Sav.& Cre. So. Ltd.", label: "Shri Adinath Co-Op.Sav.& Cre. So. Ltd." },
  { value: "Shri Adishetty Pattan Sou Sahakari Niyamit", label: "Shri Adishetty Pattan Sou Sahakari Niyamit" },
  { value: "Shri Agrasen Sahakari Patsanstha Mydt", label: "Shri Agrasen Sahakari Patsanstha Mydt" },
  { value: "Shri Ahinsa Credit Souhard Sahakari Ltd", label: "Shri Ahinsa Credit Souhard Sahakari Ltd" },
  { value: "Shri Amar Gramin Bigar Sheti Sahakari Patsanstha", label: "Shri Amar Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Shri Amareshwar Leasing (R)", label: "Shri Amareshwar Leasing (R)" },
  { value: "Shri Ambabhavani Urban Co-Op Bank Ltd", label: "Shri Ambabhavani Urban Co-Op Bank Ltd" },
  { value: "Shri Ambika Urban Co-Op Credit Soc. Ltd.", label: "Shri Ambika Urban Co-Op Credit Soc. Ltd." },
  { value: "Shri Amman Auto Finance", label: "Shri Amman Auto Finance" },
  { value: "Shri Amman Finance", label: "Shri Amman Finance" },
  { value: "Shri Anand Co-Op Bank Ltd", label: "Shri Anand Co-Op Bank Ltd" },
  { value: "Shri Anand Nagarik Sahakari Bank Ltd", label: "Shri Anand Nagarik Sahakari Bank Ltd" },
  { value: "Shri Ank Finhold Pvt Ltd.", label: "Shri Ank Finhold Pvt Ltd." },
  { value: "Shri Ankhiri Devi Gramin Big Sheti", label: "Shri Ankhiri Devi Gramin Big Sheti" },
  { value: "Shri Annasaheb Lonari Path Ltd", label: "Shri Annasaheb Lonari Path Ltd" },
  { value: "Shri Arihant Co-Operative Bank Limited", label: "Shri Arihant Co-Operative Bank Limited" },
  { value: "Shri Arihant Merchant(P) Ltd.", label: "Shri Arihant Merchant(P) Ltd." },
  { value: "Shri Arihant Urban Co-Op Society Ltd.", label: "Shri Arihant Urban Co-Op Society Ltd." },
  { value: "Shri Arungarai Amman Auto Finance", label: "Shri Arungarai Amman Auto Finance" },
  { value: "Shri Arya Chanakya Nag Sah Path San", label: "Shri Arya Chanakya Nag Sah Path San" },
  { value: "Shri Auto Finance", label: "Shri Auto Finance" },
  { value: "Shri Avani Auto Financiers", label: "Shri Avani Auto Financiers" },
  { value: "Shri Avdhoot Swami Nagri Sahkari Pat Sanstha", label: "Shri Avdhoot Swami Nagri Sahkari Pat Sanstha" },
  { value: "Shri B J Khatyal Janata Sahakari Bank Ltd", label: "Shri B J Khatyal Janata Sahakari Bank Ltd" },
  { value: "Shri B Y Patil Nagari Sah Pat Sanshta", label: "Shri B Y Patil Nagari Sah Pat Sanshta" },
  { value: "Shri B. J. Khatal Janata Sah. Bank Ltd.", label: "Shri B. J. Khatal Janata Sah. Bank Ltd." },
  { value: "Shri Bagasara Nagarik Sahakari Bank Ltd", label: "Shri Bagasara Nagarik Sahakari Bank Ltd" },
  { value: "Shri Bagasara Vavaniya Juth Seva Sahakari Mandli Ltd", label: "Shri Bagasara Vavaniya Juth Seva Sahakari Mandli Ltd" },
  { value: "Shri Bagasra Nagrik Sahakari Bank Ltd", label: "Shri Bagasra Nagrik Sahakari Bank Ltd" },
  { value: "Shri Balaji Arban Co-Op. Credit Society Ltd.", label: "Shri Balaji Arban Co-Op. Credit Society Ltd." },
  { value: "Shri Balaji Finance Co.", label: "Shri Balaji Finance Co." },
  { value: "Shri Balaji Mitra Mandal Finance", label: "Shri Balaji Mitra Mandal Finance" },
  { value: "Shri Balajishuharda Pattina Sahakari Niyamit Bank", label: "Shri Balajishuharda Pattina Sahakari Niyamit Bank" },
  { value: "Shri Balbhim Co-Op Bank Ltd", label: "Shri Balbhim Co-Op Bank Ltd" },
  { value: "Shri Balbhim Co-Op. Bank Ltd., Kolhapur", label: "Shri Balbhim Co-Op. Bank Ltd., Kolhapur" },
  { value: "Shri Balkishan Finance Co.", label: "Shri Balkishan Finance Co." },
  { value: "Shri Baria Nagrik Sahkari Bank", label: "Shri Baria Nagrik Sahkari Bank" },
  { value: "Shri Basavanna Mahadev Co-Op Credit Society", label: "Shri Basavanna Mahadev Co-Op Credit Society" },
  { value: "Shri Basaveshwar  Sou Sahakari Pathin Sangh", label: "Shri Basaveshwar  Sou Sahakari Pathin Sangh" },
  { value: "Shri Basaveshwar Co Op Bank Ltd .", label: "Shri Basaveshwar Co Op Bank Ltd ." },
  { value: "Shri Basaveshwar Coperative Credit Society Ltd", label: "Shri Basaveshwar Coperative Credit Society Ltd" },
  { value: "Shri Basaveshwar Gramin Sah Pat Sanstha Maryadit", label: "Shri Basaveshwar Gramin Sah Pat Sanstha Maryadit" },
  { value: "Shri Basaveshwar Sahakari Bank Niyamit", label: "Shri Basaveshwar Sahakari Bank Niyamit" },
  { value: "Shri Basaveshwar Sahakari Bank Niyamit", label: "Shri Basaveshwar Sahakari Bank Niyamit" },
  { value: "Shri Basaveshwar Urban Co-Op Bank Ltd", label: "Shri Basaveshwar Urban Co-Op Bank Ltd" },
  { value: "Shri Basaveswar Pattana Sahakari B.N", label: "Shri Basaveswar Pattana Sahakari B.N" },
  { value: "Shri Bedi  Seva Sahakari Mandli Ltd", label: "Shri Bedi  Seva Sahakari Mandli Ltd" },
  { value: "Shri Beereshwar Co-Op. Credit Society Lt", label: "Shri Beereshwar Co-Op. Credit Society Lt" },
  { value: "Shri Beereshwar Souhard Credit Sah Ltd", label: "Shri Beereshwar Souhard Credit Sah Ltd" },
  { value: "Shri Beereshwar Souhard Credit Sahakari Ltd.", label: "Shri Beereshwar Souhard Credit Sahakari Ltd." },
  { value: "Shri Beereshwar Souhardcredit Sahakari Ltd, Examba, Br, Hirekerur", label: "Shri Beereshwar Souhardcredit Sahakari Ltd, Examba, Br, Hirekerur" },
  { value: "Shri Bhadran Mercantile Co-Op Bank Ltd,", label: "Shri Bhadran Mercantile Co-Op Bank Ltd," },
  { value: "Shri Bhadran Mercantile Co-Op Bank Ltd.", label: "Shri Bhadran Mercantile Co-Op Bank Ltd." },
  { value: "Shri Bhadreshwar G B S S P S Mary", label: "Shri Bhadreshwar G B S S P S Mary" },
  { value: "Shri Bhagavathi Co Op Bank Ltd", label: "Shri Bhagavathi Co Op Bank Ltd" },
  { value: "Shri Bhagawati Finance", label: "Shri Bhagawati Finance" },
  { value: "Shri Bhagyalaxmi Co-Op.Cr.Society Ltd.", label: "Shri Bhagyalaxmi Co-Op.Cr.Society Ltd." },
  { value: "Shri Bhairavnath Co Op Cr Soc Ltd", label: "Shri Bhairavnath Co Op Cr Soc Ltd" },
  { value: "Shri Bharat Urban Co.Op Bank Ltd,", label: "Shri Bharat Urban Co.Op Bank Ltd," },
  { value: "Shri Bharatmata Mahila B. S. S. Pathsanstha Marayadit", label: "Shri Bharatmata Mahila B. S. S. Pathsanstha Marayadit" },
  { value: "Shri Bhavani Financial Services", label: "Shri Bhavani Financial Services" },
  { value: "Shri Bhaveshwari Gramin Biger Sheti Sahakari Pat Sanstha Maryadit", label: "Shri Bhaveshwari Gramin Biger Sheti Sahakari Pat Sanstha Maryadit" },
  { value: "Shri Bhayunda Domda Ssm Ltd.", label: "Shri Bhayunda Domda Ssm Ltd." },
  { value: "Shri Bhramarambha Pattan Souharda Sahakari Niyamita", label: "Shri Bhramarambha Pattan Souharda Sahakari Niyamita" },
  { value: "Shri Bhutnath Co-Op Cr Soc", label: "Shri Bhutnath Co-Op Cr Soc" },
  { value: "Shri Billur Gurubasava Pattina Sahakari Sangh Niyamit", label: "Shri Billur Gurubasava Pattina Sahakari Sangh Niyamit" },
  { value: "Shri Bilur Gurubasava Pattin Sah Sangh N", label: "Shri Bilur Gurubasava Pattin Sah Sangh N" },
  { value: "Shri Birdev Finance Pvt Ltd", label: "Shri Birdev Finance Pvt Ltd" },
  { value: "Shri Brabmanath Credit Souharada Sah Maryadit", label: "Shri Brabmanath Credit Souharada Sah Maryadit" },
  { value: "Shri Brahmanath Cr Sou Sahakari Niyamit", label: "Shri Brahmanath Cr Sou Sahakari Niyamit" },
  { value: "Shri Bramaramb Pattin Souhard Sahakari Niyamit", label: "Shri Bramaramb Pattin Souhard Sahakari Niyamit" },
  { value: "Shri Bravaramba Auto Finance", label: "Shri Bravaramba Auto Finance" },
  { value: "Shri Brmhanand Swami Gramin Bigar Sheti Sahkari Path Sanstha Maryadit", label: "Shri Brmhanand Swami Gramin Bigar Sheti Sahkari Path Sanstha Maryadit" },
  { value: "Shri Chaitnya Nagari Sah Patsanstha Ltd", label: "Shri Chaitnya Nagari Sah Patsanstha Ltd" },
  { value: "Shri Chakeshwari Finance", label: "Shri Chakeshwari Finance" },
  { value: "Shri Chakkara Finance & Ltd", label: "Shri Chakkara Finance & Ltd" },
  { value: "Shri Chakra Finance & Credits Pvt . Ltd", label: "Shri Chakra Finance & Credits Pvt . Ltd" },
  { value: "Shri Chanakya Nagari Sahakari Patsanstha Ltd.", label: "Shri Chanakya Nagari Sahakari Patsanstha Ltd." },
  { value: "Shri Channab Souhardha Pattana Sah Bk Ni", label: "Shri Channab Souhardha Pattana Sah Bk Ni" },
  { value: "Shri Channabasava Swamy Pattana Sahakari Bank Ltd", label: "Shri Channabasava Swamy Pattana Sahakari Bank Ltd" },
  { value: "Shri Channabasavaswamy Sou.Patt.Sah.Bk.", label: "Shri Channabasavaswamy Sou.Patt.Sah.Bk." },
  { value: "Shri Chatrapati Shivaji Maharaj Sahakar Bank Niyamitha", label: "Shri Chatrapati Shivaji Maharaj Sahakar Bank Niyamitha" },
  { value: "Shri Chavdeshwari Nag. Sah. Pat.", label: "Shri Chavdeshwari Nag. Sah. Pat." },
  { value: "Shri Chh Shivaji Vahan Dharak Nag Sah Pat Sanstha Marya  Bank", label: "Shri Chh Shivaji Vahan Dharak Nag Sah Pat Sanstha Marya  Bank" },
  { value: "Shri Chh. Rajarshi Shahu Ur.Co-Op.Bk.Lt", label: "Shri Chh. Rajarshi Shahu Ur.Co-Op.Bk.Lt" },
  { value: "Shri Chhani Nag. Sah Bank Ltd., Baroda", label: "Shri Chhani Nag. Sah Bank Ltd., Baroda" },
  { value: "Shri Chhartrapati Rajaram Nagari Sahakari Patha Santha", label: "Shri Chhartrapati Rajaram Nagari Sahakari Patha Santha" },
  { value: "Shri Chhatrapati Nag Sah Bank", label: "Shri Chhatrapati Nag Sah Bank" },
  { value: "Shri Chhatrapati Raj Shahu Urban Co-Op Bank Ltd", label: "Shri Chhatrapati Raj Shahu Urban Co-Op Bank Ltd" },
  { value: "Shri Chhatrapati Rajaram Timber Sahakari Patsanstha Maryadit", label: "Shri Chhatrapati Rajaram Timber Sahakari Patsanstha Maryadit" },
  { value: "Shri Chhatrapati Rajarshi Shahu U.C.B.L.", label: "Shri Chhatrapati Rajarshi Shahu U.C.B.L." },
  { value: "Shri Chital Nagarik Sahakari Bank Ltd", label: "Shri Chital Nagarik Sahakari Bank Ltd" },
  { value: "Shri Choudeshwar Togatveer Nagari Sah Pathashanstha", label: "Shri Choudeshwar Togatveer Nagari Sah Pathashanstha" },
  { value: "Shri Dadasaheb Gajmal Co-Op. Bank Ltd.", label: "Shri Dadasaheb Gajmal Co-Op. Bank Ltd." },
  { value: "Shri Data Auto Finance", label: "Shri Data Auto Finance" },
  { value: "Shri Datta Co Op Credit  Society Ltd", label: "Shri Datta Co Op Credit  Society Ltd" },
  { value: "Shri Datta Digambar Gramin Bigar Sheti Sah Patsanstha Maryadit", label: "Shri Datta Digambar Gramin Bigar Sheti Sah Patsanstha Maryadit" },
  { value: "Shri Datta Gra. Big. Sheti Sah. Pat.", label: "Shri Datta Gra. Big. Sheti Sah. Pat." },
  { value: "Shri Dattakrupa Gramin Bigar Sheti Sahkari Pathsastha", label: "Shri Dattakrupa Gramin Bigar Sheti Sahkari Pathsastha" },
  { value: "Shri Dattatray Gramin Nagri Shakari Patsanstha Maryadit", label: "Shri Dattatray Gramin Nagri Shakari Patsanstha Maryadit" },
  { value: "Shri Deonadi Khare Gramin Bigarsheti Sahkari Patsanstha Maryadit, Sonambe", label: "Shri Deonadi Khare Gramin Bigarsheti Sahkari Patsanstha Maryadit, Sonambe" },
  { value: "Shri Deonadi Khore Bigar Shethi Sahkari Patsanstha", label: "Shri Deonadi Khore Bigar Shethi Sahkari Patsanstha" },
  { value: "Shri Dhanalakshmi Finance", label: "Shri Dhanalakshmi Finance" },
  { value: "Shri Dhanalakshmi Investments", label: "Shri Dhanalakshmi Investments" },
  { value: "Shri Dhanalaxmi Nagari Shakari Patsanstha Maryadit", label: "Shri Dhanalaxmi Nagari Shakari Patsanstha Maryadit" },
  { value: "Shri Dhanduka Janta Sahakari Bank Ltd.", label: "Shri Dhanduka Janta Sahakari Bank Ltd." },
  { value: "Shri Dhank Juth Vividh Sahakari Mandli", label: "Shri Dhank Juth Vividh Sahakari Mandli" },
  { value: "Shri Dhanlaxmi Co-Op Credit Society Ltd", label: "Shri Dhanlaxmi Co-Op Credit Society Ltd" },
  { value: "Shri Dhokaleshwar Bigar Sah. Pat", label: "Shri Dhokaleshwar Bigar Sah. Pat" },
  { value: "Shri Dhruvnagar S S M Ltd", label: "Shri Dhruvnagar S S M Ltd" },
  { value: "Shri Dhuldev Gramin Biger Sheti Sah Pat San Mary", label: "Shri Dhuldev Gramin Biger Sheti Sah Pat San Mary" },
  { value: "Shri Dilip Auto Finance", label: "Shri Dilip Auto Finance" },
  { value: "Shri Drugadevi Mahila Sahakara Bank N", label: "Shri Drugadevi Mahila Sahakara Bank N" },
  { value: "Shri Dudhganga Puja G. S. P. S. Mrdt", label: "Shri Dudhganga Puja G. S. P. S. Mrdt" },
  { value: "Shri Duradundeshwar Ur Cr Souhard Sahakari Ltd.", label: "Shri Duradundeshwar Ur Cr Souhard Sahakari Ltd." },
  { value: "Shri Durga Mata Co Op Cr Soc Ltd", label: "Shri Durga Mata Co Op Cr Soc Ltd" },
  { value: "Shri Durudundeshwar Urban Co-Operative Credit Society", label: "Shri Durudundeshwar Urban Co-Operative Credit Society" },
  { value: "Shri Enterprises", label: "Shri Enterprises" },
  { value: "Shri Equipment Finance Pvt Ltd", label: "Shri Equipment Finance Pvt Ltd" },
  { value: "Shri Eshwar Seva Urban Co-Opco-Op Bank Ltd", label: "Shri Eshwar Seva Urban Co-Opco-Op Bank Ltd" },
  { value: "Shri Finance Co Ltd", label: "Shri Finance Co Ltd" },
  { value: "Shri Finvest", label: "Shri Finvest" },
  { value: "Shri Gadhaka Juth Seva Sahakari Mandali Ltd", label: "Shri Gadhaka Juth Seva Sahakari Mandali Ltd" },
  { value: "Shri Gajanan G.B.S.S. Path San. Maryadit.", label: "Shri Gajanan G.B.S.S. Path San. Maryadit." },
  { value: "Shri Gajanan Lokseva Sah Bank Ltd", label: "Shri Gajanan Lokseva Sah Bank Ltd" },
  { value: "Shri Gajanan Maharaj Urban Co.Op.Bank Lt", label: "Shri Gajanan Maharaj Urban Co.Op.Bank Lt" },
  { value: "Shri Gajanan Multipurpose Souhard Sahakari Niyamit", label: "Shri Gajanan Multipurpose Souhard Sahakari Niyamit" },
  { value: "Shri Gajanan Nagari Sahakari Pat San Mydt", label: "Shri Gajanan Nagari Sahakari Pat San Mydt" },
  { value: "Shri Gajanan Urban Credit Co Soc L,", label: "Shri Gajanan Urban Credit Co Soc L," },
  { value: "Shri Gajanan Urban Credit Co Society Ltd", label: "Shri Gajanan Urban Credit Co Society Ltd" },
  { value: "Shri Galaxy Credit Co-Op Society", label: "Shri Galaxy Credit Co-Op Society" },
  { value: "Shri Ganapati Urban Co-Op Co-Op Bank Ltd", label: "Shri Ganapati Urban Co-Op Co-Op Bank Ltd" },
  { value: "Shri Gandhi Gramin Bigar Sheti Sahakari Pat San", label: "Shri Gandhi Gramin Bigar Sheti Sahakari Pat San" },
  { value: "Shri Ganesh Auto & Finance", label: "Shri Ganesh Auto & Finance" },
  { value: "Shri Ganesh Finance", label: "Shri Ganesh Finance" },
  { value: "Shri Ganesh Finance", label: "Shri Ganesh Finance" },
  { value: "Shri Ganesh Finnce & Investment", label: "Shri Ganesh Finnce & Investment" },
  { value: "Shri Ganesh Sah Bank Ltd", label: "Shri Ganesh Sah Bank Ltd" },
  { value: "Shri Ganesh Sahakari Bank Maryadit", label: "Shri Ganesh Sahakari Bank Maryadit" },
  { value: "Shri Ganga Gramin B. S. Sahakari Pathsanstha Maryadit", label: "Shri Ganga Gramin B. S. Sahakari Pathsanstha Maryadit" },
  { value: "Shri Ganpati Envestment", label: "Shri Ganpati Envestment" },
  { value: "Shri Garnala Seva Sahakari Mandali Ltd.", label: "Shri Garnala Seva Sahakari Mandali Ltd." },
  { value: "Shri Gauridad Seva Sahakari Mandli Ltd", label: "Shri Gauridad Seva Sahakari Mandli Ltd" },
  { value: "Shri Gavisiddeshwar Co Urban Bank", label: "Shri Gavisiddeshwar Co Urban Bank" },
  { value: "Shri Gayathri Ur Coop Cr Society", label: "Shri Gayathri Ur Coop Cr Society" },
  { value: "Shri Gayathri Urban Co-Op Credit Society Ltd", label: "Shri Gayathri Urban Co-Op Credit Society Ltd" },
  { value: "Shri Gayatri Ur Co-Op Cr Soc", label: "Shri Gayatri Ur Co-Op Cr Soc" },
  { value: "Shri Gayatri Urban Co-Op Bank Ltd", label: "Shri Gayatri Urban Co-Op Bank Ltd" },
  { value: "Shri Gayatri Urban Credit Souhard Sahakari Niyamit", label: "Shri Gayatri Urban Credit Souhard Sahakari Niyamit" },
  { value: "Shri Giriraj Finance Services", label: "Shri Giriraj Finance Services" },
  { value: "Shri Gomanth Co-Op Bank Ltd", label: "Shri Gomanth Co-Op Bank Ltd" },
  { value: "Shri Gopal Krishna Grameen Bigarsheti Sahakari Patsanstha Ltd", label: "Shri Gopal Krishna Grameen Bigarsheti Sahakari Patsanstha Ltd" },
  { value: "Shri Gourishankar Souhard Pattin Sahakari Niyamit", label: "Shri Gourishankar Souhard Pattin Sahakari Niyamit" },
  { value: "Shri Govardhansinghji Raghuvanshi Sah Bk", label: "Shri Govardhansinghji Raghuvanshi Sah Bk" },
  { value: "Shri Gramin B Sheti Sah Pat San", label: "Shri Gramin B Sheti Sah Pat San" },
  { value: "Shri Guru Teg Bahadur Finance Co.", label: "Shri Guru Teg Bahadur Finance Co." },
  { value: "Shri Gurudeo Nag. Sah. Pata Sanstha Maryadit", label: "Shri Gurudeo Nag. Sah. Pata Sanstha Maryadit" },
  { value: "Shri Gurudev Brahmanand Pattana Sahakara Bank Niyamit", label: "Shri Gurudev Brahmanand Pattana Sahakara Bank Niyamit" },
  { value: "Shri Guruling Shivacharya Financiers", label: "Shri Guruling Shivacharya Financiers" },
  { value: "Shri Gurushanteshwar Vividha Uddeshgala Souhard Sahakari Niyamit", label: "Shri Gurushanteshwar Vividha Uddeshgala Souhard Sahakari Niyamit" },
  { value: "Shri Gurusiddeshwar Pattin Sahakari Sanga Niyamit", label: "Shri Gurusiddeshwar Pattin Sahakari Sanga Niyamit" },
  { value: "Shri Gurusiddheshwar Co-Op Bank Ltd.", label: "Shri Gurusiddheshwar Co-Op Bank Ltd." },
  { value: "Shri Hanuman Gra Big She Sah Pat San Mydt", label: "Shri Hanuman Gra Big She Sah Pat San Mydt" },
  { value: "Shri Hanuman Gramin Bigarsheti Sahakari Patsansta", label: "Shri Hanuman Gramin Bigarsheti Sahakari Patsansta" },
  { value: "Shri Hanuman Nagari Sahakari Pathpedi Maryadit", label: "Shri Hanuman Nagari Sahakari Pathpedi Maryadit" },
  { value: "Shri Hari Finance Company", label: "Shri Hari Finance Company" },
  { value: "Shri Harihareswara Urban Co-Op Bank Ltd", label: "Shri Harihareswara Urban Co-Op Bank Ltd" },
  { value: "Shri Harishwar Sahakari Patpedhi Ltd", label: "Shri Harishwar Sahakari Patpedhi Ltd" },
  { value: "Shri Honnavadevi Multi Purpose Sahakari Sangh", label: "Shri Honnavadevi Multi Purpose Sahakari Sangh" },
  { value: "Shri Honnavadevi Multipurpose Sahakari Sangh Ltd", label: "Shri Honnavadevi Multipurpose Sahakari Sangh Ltd" },
  { value: "Shri Infrastructure Finance Limited", label: "Shri Infrastructure Finance Limited" },
  { value: "Shri Infrastructure Finance Ltd With Contorium Of Bank Uco Bank As Lead Bank Bangalore", label: "Shri Infrastructure Finance Ltd With Contorium Of Bank Uco Bank As Lead Bank Bangalore" },
  { value: "Shri International Finance Ltd", label: "Shri International Finance Ltd" },
  { value: "Shri Jagadguru Murugarajendra Credit Co-Op Society", label: "Shri Jagadguru Murugarajendra Credit Co-Op Society" },
  { value: "Shri Jagdamba Finance", label: "Shri Jagdamba Finance" },
  { value: "Shri Jagdamba Mata Gramin Bigar Sheti", label: "Shri Jagdamba Mata Gramin Bigar Sheti" },
  { value: "Shri Jai Bhavani Multipurpose Co-Op Society Ltd.", label: "Shri Jai Bhavani Multipurpose Co-Op Society Ltd." },
  { value: "Shri Jaibhawani Urban Cooprative Credit Society", label: "Shri Jaibhawani Urban Cooprative Credit Society" },
  { value: "Shri Jain Chakan Gramin Biger Sheti Sah Pat San Mar", label: "Shri Jain Chakan Gramin Biger Sheti Sah Pat San Mar" },
  { value: "Shri Jain Motor Finance", label: "Shri Jain Motor Finance" },
  { value: "Shri Jalida Juth Seva Sahakari Mandali Ltd", label: "Shri Jalida Juth Seva Sahakari Mandali Ltd" },
  { value: "Shri Jalida Juth Seva Sahakari Mandli Ltd", label: "Shri Jalida Juth Seva Sahakari Mandli Ltd" },
  { value: "Shri Jay Ambey Automobiles", label: "Shri Jay Ambey Automobiles" },
  { value: "Shri Jay Bajrang G.B.S.S. Path San. Maryadit.", label: "Shri Jay Bajrang G.B.S.S. Path San. Maryadit." },
  { value: "Shri Jay Bajrang G.B.S.S.P.S. Maryadit", label: "Shri Jay Bajrang G.B.S.S.P.S. Maryadit" },
  { value: "Shri Jaybhavni Urban Cooprative Credit Society Ltd.", label: "Shri Jaybhavni Urban Cooprative Credit Society Ltd." },
  { value: "Shri Jaymil Sharafi", label: "Shri Jaymil Sharafi" },
  { value: "Shri Jayshivrai Nag Sah Pat Sanstha Mydt", label: "Shri Jayshivrai Nag Sah Pat Sanstha Mydt" },
  { value: "Shri Jiyana Group Co-Operative Soc Ltd.", label: "Shri Jiyana Group Co-Operative Soc Ltd." },
  { value: "Shri Jogeshwari Marchant Sah Path", label: "Shri Jogeshwari Marchant Sah Path" },
  { value: "Shri Jotiling Gramin Bigar Seti Patsanstha", label: "Shri Jotiling Gramin Bigar Seti Patsanstha" },
  { value: "Shri Juna Pipaliya Seva Sahkari Mandli Ltd", label: "Shri Juna Pipaliya Seva Sahkari Mandli Ltd" },
  { value: "Shri Junagadh Vibhagiya Nagrik Sah.Bk.Lt", label: "Shri Junagadh Vibhagiya Nagrik Sah.Bk.Lt" },
  { value: "Shri Jyotirling G B S S Path", label: "Shri Jyotirling G B S S Path" },
  { value: "Shri Jyotirling Multi Purpose Co-Op-So-Ni", label: "Shri Jyotirling Multi Purpose Co-Op-So-Ni" },
  { value: "Shri Kadashiddeshwar Vivid Udeshgal  Sahakari  Sangh Niyamit", label: "Shri Kadashiddeshwar Vivid Udeshgal  Sahakari  Sangh Niyamit" },
  { value: "Shri Kadasiddeshwar Pattan Sahakari Bank Niyamitha Banahatti", label: "Shri Kadasiddeshwar Pattan Sahakari Bank Niyamitha Banahatti" },
  { value: "Shri Kalidas Co-Op. Credit Society Ltd.", label: "Shri Kalidas Co-Op. Credit Society Ltd." },
  { value: "Shri Kalmeshwar Co-Op Credit Society Ltd", label: "Shri Kalmeshwar Co-Op Credit Society Ltd" },
  { value: "Shri Kameshwar Urban Co-Op Bank Ltd", label: "Shri Kameshwar Urban Co-Op Bank Ltd" },
  { value: "Shri Kanakadas Urban Co-Operative Society Ltd Saunadatti", label: "Shri Kanakadas Urban Co-Operative Society Ltd Saunadatti" },
  { value: "Shri Kanaleadasurban Co-Operative Credit Society", label: "Shri Kanaleadasurban Co-Operative Credit Society" },
  { value: "Shri Kaneka Parmeshwara Urban Co-Op Bank Ltd", label: "Shri Kaneka Parmeshwara Urban Co-Op Bank Ltd" },
  { value: "Shri Kankaria Tea And Finance Co Ltd", label: "Shri Kankaria Tea And Finance Co Ltd" },
  { value: "Shri Kanyaka Nagarik Sahakari Bank Ltd", label: "Shri Kanyaka Nagarik Sahakari Bank Ltd" },
  { value: "Shri Kanyaka Parmeshwar Co-Op Socbank Ltd", label: "Shri Kanyaka Parmeshwar Co-Op Socbank Ltd" },
  { value: "Shri Kanyka Nagri Sahakari Bank Ltd", label: "Shri Kanyka Nagri Sahakari Bank Ltd" },
  { value: "Shri Kedareshwar Nagari Pat Sanstha Maryadit.", label: "Shri Kedareshwar Nagari Pat Sanstha Maryadit." },
  { value: "Shri Kedarling G.B.S.S Path S. Mydt", label: "Shri Kedarling G.B.S.S Path S. Mydt" },
  { value: "Shri Keshav Urban Co-Op.Society Mydt.", label: "Shri Keshav Urban Co-Op.Society Mydt." },
  { value: "Shri Keshetradhramastalarava Ltd", label: "Shri Keshetradhramastalarava Ltd" },
  { value: "Shri Keshiya Seva Sahakari Mandali Ltd.", label: "Shri Keshiya Seva Sahakari Mandali Ltd." },
  { value: "Shri Khambhala Juth Seva Sahakari Mandali", label: "Shri Khambhala Juth Seva Sahakari Mandali" },
  { value: "Shri Khandheri Seva Sahkari Mandli", label: "Shri Khandheri Seva Sahkari Mandli" },
  { value: "Shri Kheteshwar Sahakari Pat Sanstha Ltd.", label: "Shri Kheteshwar Sahakari Pat Sanstha Ltd." },
  { value: "Shri Khirsra Juth Seva Sahkari Mandli Ltd", label: "Shri Khirsra Juth Seva Sahkari Mandli Ltd" },
  { value: "Shri Kishan Govind Fin Co.", label: "Shri Kishan Govind Fin Co." },
  { value: "Shri Kodasiddeshwar Pattan Sahakari Bank Niyamit", label: "Shri Kodasiddeshwar Pattan Sahakari Bank Niyamit" },
  { value: "Shri Kolithad Jvssm Ltd", label: "Shri Kolithad Jvssm Ltd" },
  { value: "Shri Krishan Finance Company", label: "Shri Krishan Finance Company" },
  { value: "Shri Krishna Auto Deals", label: "Shri Krishna Auto Deals" },
  { value: "Shri Krishna Co Op Bank Ltd", label: "Shri Krishna Co Op Bank Ltd" },
  { value: "Shri Krishna Co-Op Bank Ltd,", label: "Shri Krishna Co-Op Bank Ltd," },
  { value: "Shri Krishna Ent.", label: "Shri Krishna Ent." },
  { value: "Shri Krishna Finance Limited", label: "Shri Krishna Finance Limited" },
  { value: "Shri Krishna Financer And Traders", label: "Shri Krishna Financer And Traders" },
  { value: "Shri Krishna Financial Associate", label: "Shri Krishna Financial Associate" },
  { value: "Shri Krishna Rajendra Co-Op Bank Ltd", label: "Shri Krishna Rajendra Co-Op Bank Ltd" },
  { value: "Shri Krishna Sah. Bank Ltd., Baroda", label: "Shri Krishna Sah. Bank Ltd., Baroda" },
  { value: "Shri Krishna Sahakari Bank Ltd.", label: "Shri Krishna Sahakari Bank Ltd." },
  { value: "Shri Krishnam Fin Company", label: "Shri Krishnam Fin Company" },
  { value: "Shri Krushna Gramin Bigar Sheti", label: "Shri Krushna Gramin Bigar Sheti" },
  { value: "Shri Kshetra  Dharmasthala Gramabhivriddi Yojana", label: "Shri Kshetra  Dharmasthala Gramabhivriddi Yojana" },
  { value: "Shri Kuberraj Mata Mahila Nagari Sah Pat San Mar", label: "Shri Kuberraj Mata Mahila Nagari Sah Pat San Mar" },
  { value: "Shri Kumaravel Finance", label: "Shri Kumaravel Finance" },
  { value: "Shri Lakshmi Transport", label: "Shri Lakshmi Transport" },
  { value: "Shri Lalithamba Pattina Souhardha Sahakari Ltd Gadag", label: "Shri Lalithamba Pattina Souhardha Sahakari Ltd Gadag" },
  { value: "Shri Laxmi Co-Op Bank Ltd.", label: "Shri Laxmi Co-Op Bank Ltd." },
  { value: "Shri Laxmi Co-Op. Bank Limited", label: "Shri Laxmi Co-Op. Bank Limited" },
  { value: "Shri Laxmi Co-Operative Credit Society", label: "Shri Laxmi Co-Operative Credit Society" },
  { value: "Shri Laxmi Credit Souharda Sahakari Bank Ltd  Mangeri Chandur", label: "Shri Laxmi Credit Souharda Sahakari Bank Ltd  Mangeri Chandur" },
  { value: "Shri Laxmi Gramin Bigar Sheti Sahakari Pathsanstha Maryadit", label: "Shri Laxmi Gramin Bigar Sheti Sahakari Pathsanstha Maryadit" },
  { value: "Shri Laxmi Mahila Sahakari Bank Limited.", label: "Shri Laxmi Mahila Sahakari Bank Limited." },
  { value: "Shri Laxmi Mata Co-Operative Credit Society", label: "Shri Laxmi Mata Co-Operative Credit Society" },
  { value: "Shri Laxmi Nagari Sahakari Pat Sanstha Maryadit", label: "Shri Laxmi Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Shri Laxmi Narayan Co Op Cr Soc Ltd", label: "Shri Laxmi Narayan Co Op Cr Soc Ltd" },
  { value: "Shri Laxmi Narayan Nagari Sahakari Patsanstha Maryadit", label: "Shri Laxmi Narayan Nagari Sahakari Patsanstha Maryadit" },
  { value: "Shri Laxmi Narayan Nagari Sahkari Pat Sanstha Maryadit", label: "Shri Laxmi Narayan Nagari Sahkari Pat Sanstha Maryadit" },
  { value: "Shri Laxmi Narayan Nagari Sahkari Patsanstha", label: "Shri Laxmi Narayan Nagari Sahkari Patsanstha" },
  { value: "Shri Laxmi Sahakari Sarafi Mandli Ltd", label: "Shri Laxmi Sahakari Sarafi Mandli Ltd" },
  { value: "Shri Laxmi Souhardha Credit Co Op Ltd.", label: "Shri Laxmi Souhardha Credit Co Op Ltd." },
  { value: "Shri Laxmi V.K. Seva San", label: "Shri Laxmi V.K. Seva San" },
  { value: "Shri Laxmi Venkateshwar Souhar Pattina S", label: "Shri Laxmi Venkateshwar Souhar Pattina S" },
  { value: "Shri Laxmi Venkateshwara Society Sangha Patana", label: "Shri Laxmi Venkateshwara Society Sangha Patana" },
  { value: "Shri Laxmikrupa Urban Co-Op Bank Ltd.", label: "Shri Laxmikrupa Urban Co-Op Bank Ltd." },
  { value: "Shri Laxmikrupa Urban Co-Op Bk L", label: "Shri Laxmikrupa Urban Co-Op Bk L" },
  { value: "Shri Laxminarayan Nagari Sahkari Patsanstha Mydt", label: "Shri Laxminarayan Nagari Sahkari Patsanstha Mydt" },
  { value: "Shri Luxmi Financial Association", label: "Shri Luxmi Financial Association" },
  { value: "Shri M. Visvesvasraya Co-Op Bank Ltd.", label: "Shri M. Visvesvasraya Co-Op Bank Ltd." },
  { value: "Shri Madhukar Investment", label: "Shri Madhukar Investment" },
  { value: "Shri Madhukar Investment Ltd", label: "Shri Madhukar Investment Ltd" },
  { value: "Shri Mahalaxmi Bigar Sheti Sahakari Pat Sanstha Maryadit", label: "Shri Mahalaxmi Bigar Sheti Sahakari Pat Sanstha Maryadit" },
  { value: "Shri Mahalaxmi Co-Op Credit Society Ltd", label: "Shri Mahalaxmi Co-Op Credit Society Ltd" },
  { value: "Shri Mahalaxmi Co-Op. Bank Ltd., Kolhapur", label: "Shri Mahalaxmi Co-Op. Bank Ltd., Kolhapur" },
  { value: "Shri Mahalaxmi Credit Society Ltd.", label: "Shri Mahalaxmi Credit Society Ltd." },
  { value: "Shri Mahalaxmi Finance And Investment Corporation", label: "Shri Mahalaxmi Finance And Investment Corporation" },
  { value: "Shri Mahalaxmi Multi Purpose Co-Op Ltd", label: "Shri Mahalaxmi Multi Purpose Co-Op Ltd" },
  { value: "Shri Mahalaxmi Pattana Sahakara Bank Ltd", label: "Shri Mahalaxmi Pattana Sahakara Bank Ltd" },
  { value: "Shri Mahalaxmi Vyavasaya Seva Sahakari Sangh Niyamit", label: "Shri Mahalaxmi Vyavasaya Seva Sahakari Sangh Niyamit" },
  { value: "Shri Mahant Shivayogi Sahakari Bank Ltd", label: "Shri Mahant Shivayogi Sahakari Bank Ltd" },
  { value: "Shri Mahasiddh Urban Co-Op Credit So. Ltd", label: "Shri Mahasiddh Urban Co-Op Credit So. Ltd" },
  { value: "Shri Mahatma Basaveshwar Coop Bk Ltd.", label: "Shri Mahatma Basaveshwar Coop Bk Ltd." },
  { value: "Shri Mahaveer Nagari Sahakari Pat Sanstha Maryadit", label: "Shri Mahaveer Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Shri Mahaveer Urban Co-Op.Bank", label: "Shri Mahaveer Urban Co-Op.Bank" },
  { value: "Shri Mahaveer Viveedhodesha Souhardh Sahakari Ltd", label: "Shri Mahaveer Viveedhodesha Souhardh Sahakari Ltd" },
  { value: "Shri Mahavir Co-Op. Bank Ltd", label: "Shri Mahavir Co-Op. Bank Ltd" },
  { value: "Shri Mahavir Ji Finlease (P) Ltd", label: "Shri Mahavir Ji Finlease (P) Ltd" },
  { value: "Shri Mahavir Ji Finlease Pvt Ltd", label: "Shri Mahavir Ji Finlease Pvt Ltd" },
  { value: "Shri Mahavir Urban Co- Op Bank Ltd", label: "Shri Mahavir Urban Co- Op Bank Ltd" },
  { value: "Shri Mahavir Urban Co-Op Bank Ltd", label: "Shri Mahavir Urban Co-Op Bank Ltd" },
  { value: "Shri Mahavir Urban Co-Op Bank Ltd Solapur", label: "Shri Mahavir Urban Co-Op Bank Ltd Solapur" },
  { value: "Shri Mahendra Auto Finance", label: "Shri Mahendra Auto Finance" },
  { value: "Shri Mahesh Urban C. Bk Aurangabad Ltd.", label: "Shri Mahesh Urban C. Bk Aurangabad Ltd." },
  { value: "Shri Malikarjun Auto Finance", label: "Shri Malikarjun Auto Finance" },
  { value: "Shri Mallikarjun Auto Finance (R).", label: "Shri Mallikarjun Auto Finance (R)." },
  { value: "Shri Mallikarjun Urban Credit Souhard Sahakari Niyamitha", label: "Shri Mallikarjun Urban Credit Souhard Sahakari Niyamitha" },
  { value: "Shri Marudhar Finance", label: "Shri Marudhar Finance" },
  { value: "Shri Marudhar Inverstment", label: "Shri Marudhar Inverstment" },
  { value: "Shri Maruti Motor Finance", label: "Shri Maruti Motor Finance" },
  { value: "Shri Matha Auto Finance", label: "Shri Matha Auto Finance" },
  { value: "Shri Matha Co-Op Credit Society Limited", label: "Shri Matha Co-Op Credit Society Limited" },
  { value: "Shri Maulimulti State Credit Co-Op Society Maryadit", label: "Shri Maulimulti State Credit Co-Op Society Maryadit" },
  { value: "Shri Milap Credit Co Op Society", label: "Shri Milap Credit Co Op Society" },
  { value: "Shri Mishrilal Parasmal And Co.", label: "Shri Mishrilal Parasmal And Co." },
  { value: "Shri Mota Dahisar Juth Seva Sahakari Mandali Ltd", label: "Shri Mota Dahisar Juth Seva Sahakari Mandali Ltd" },
  { value: "Shri Moviya Juth Seva Sahakari Mandli Ltd", label: "Shri Moviya Juth Seva Sahakari Mandli Ltd" },
  { value: "Shri Mss Bank Ltd", label: "Shri Mss Bank Ltd" },
  { value: "Shri Murugendra Ur Co Op Bank Ltd", label: "Shri Murugendra Ur Co Op Bank Ltd" },
  { value: "Shri Musaleshwar Gramin Bigarsheti Sah Path Sanstha", label: "Shri Musaleshwar Gramin Bigarsheti Sah Path Sanstha" },
  { value: "Shri Nagalingeshwar Ur Co - Op Credit Society Ltd", label: "Shri Nagalingeshwar Ur Co - Op Credit Society Ltd" },
  { value: "Shri Nagari Sah Path Sanstha", label: "Shri Nagari Sah Path Sanstha" },
  { value: "Shri Nagnath Co-Operative Credit Society", label: "Shri Nagnath Co-Operative Credit Society" },
  { value: "Shri Nakoda Credit Corporation", label: "Shri Nakoda Credit Corporation" },
  { value: "Shri Nakoda Enterprises", label: "Shri Nakoda Enterprises" },
  { value: "Shri Namokar Investment", label: "Shri Namokar Investment" },
  { value: "Shri Narasimha Credit Souhard Sahakari Ltd", label: "Shri Narasimha Credit Souhard Sahakari Ltd" },
  { value: "Shri Nari Co Op Credit Soc Ltd", label: "Shri Nari Co Op Credit Soc Ltd" },
  { value: "Shri Narshiha Gramin Biger Sheti Sah Pat San Maryadit", label: "Shri Narshiha Gramin Biger Sheti Sah Pat San Maryadit" },
  { value: "Shri Narsinha Saraswati Sahakari Bank Ltd", label: "Shri Narsinha Saraswati Sahakari Bank Ltd" },
  { value: "Shri Nath Krupa Mirajgaon Parisar Gramin Bigar Sheti Sahakari Path Sanstha", label: "Shri Nath Krupa Mirajgaon Parisar Gramin Bigar Sheti Sahakari Path Sanstha" },
  { value: "Shri Neelkanteshwar Co Op Credit Society", label: "Shri Neelkanteshwar Co Op Credit Society" },
  { value: "Shri Neminath Ur Co-Op Cr Souhard Sahakari Niyamith", label: "Shri Neminath Ur Co-Op Cr Souhard Sahakari Niyamith" },
  { value: "Shri Omkar Mahila Nagari Sah", label: "Shri Omkar Mahila Nagari Sah" },
  { value: "Shri Onkar Mahila Sah Pat Sansatha", label: "Shri Onkar Mahila Sah Pat Sansatha" },
  { value: "Shri P K Anna Patil Janata Sahakari Bk.L", label: "Shri P K Anna Patil Janata Sahakari Bk.L" },
  { value: "Shri P K Annapatil Janata Sahakari Bank Ltd", label: "Shri P K Annapatil Janata Sahakari Bank Ltd" },
  { value: "Shri Padamprabhu Nagari Sah Patasanstha Ltd", label: "Shri Padamprabhu Nagari Sah Patasanstha Ltd" },
  { value: "Shri Paisafund Shetaki Sah. Bk Ltd,", label: "Shri Paisafund Shetaki Sah. Bk Ltd," },
  { value: "Shri Panchaganga Nagari Sah. Bank Ltd", label: "Shri Panchaganga Nagari Sah. Bank Ltd" },
  { value: "Shri Panchganga Nagri Sahakari Bank Ltd", label: "Shri Panchganga Nagri Sahakari Bank Ltd" },
  { value: "Shri Paras Padmavati", label: "Shri Paras Padmavati" },
  { value: "Shri Paras Sahkari Path Sanstha Ltd.", label: "Shri Paras Sahkari Path Sanstha Ltd." },
  { value: "Shri Parasram Holding Pvt Ltd", label: "Shri Parasram Holding Pvt Ltd" },
  { value: "Shri Parola Urban Co Op Bank Ltd", label: "Shri Parola Urban Co Op Bank Ltd" },
  { value: "Shri Parshwanath Co-Op. Bank Ltd.", label: "Shri Parshwanath Co-Op. Bank Ltd." },
  { value: "Shri Prabhakar Anna Kore Cr Souhard Sahakar Ltd.", label: "Shri Prabhakar Anna Kore Cr Souhard Sahakar Ltd." },
  { value: "Shri Pragati Pattan Sahakari Bank", label: "Shri Pragati Pattan Sahakari Bank" },
  { value: "Shri Prakash Nagari Sahkari Patsanstha Mrdt", label: "Shri Prakash Nagari Sahkari Patsanstha Mrdt" },
  { value: "Shri Presidev Co-Op Credit Society Ltd.", label: "Shri Presidev Co-Op Credit Society Ltd." },
  { value: "Shri R I Finance Ltd Chennai", label: "Shri R I Finance Ltd Chennai" },
  { value: "Shri Radhakrishna Nagari Sah Pat", label: "Shri Radhakrishna Nagari Sah Pat" },
  { value: "Shri Radhe Finance", label: "Shri Radhe Finance" },
  { value: "Shri Raj Financial Services", label: "Shri Raj Financial Services" },
  { value: "Shri Rajarshi Sahkari Nagari Sahkari Patsanstha Mrdt", label: "Shri Rajarshi Sahkari Nagari Sahkari Patsanstha Mrdt" },
  { value: "Shri Rajashri Shahu Nagari Sahkari Patsanstha Mrdt", label: "Shri Rajashri Shahu Nagari Sahkari Patsanstha Mrdt" },
  { value: "Shri Rajkot Dist.Co-Op.Bank Ltd.", label: "Shri Rajkot Dist.Co-Op.Bank Ltd." },
  { value: "Shri Rajkot District Co Op Bank Ltd", label: "Shri Rajkot District Co Op Bank Ltd" },
  { value: "Shri Rajkot District Co-Op Bank", label: "Shri Rajkot District Co-Op Bank" },
  { value: "Shri Ram", label: "Shri Ram" },
  { value: "Shri Ram Auto Deals", label: "Shri Ram Auto Deals" },
  { value: "Shri Ram Chits & Finance", label: "Shri Ram Chits & Finance" },
  { value: "Shri Ram City Finance", label: "Shri Ram City Finance" },
  { value: "Shri Ram City Union Finance", label: "Shri Ram City Union Finance" },
  { value: "Shri Ram City Union Finance Corporation", label: "Shri Ram City Union Finance Corporation" },
  { value: "Shri Ram City Union Prime Ltd", label: "Shri Ram City Union Prime Ltd" },
  { value: "Shri Ram Commercial", label: "Shri Ram Commercial" },
  { value: "Shri Ram Commercial Vehicle Finance", label: "Shri Ram Commercial Vehicle Finance" },
  { value: "Shri Ram Credit Co Op Soc Ltd", label: "Shri Ram Credit Co Op Soc Ltd" },
  { value: "Shri Ram Credit Corporation", label: "Shri Ram Credit Corporation" },
  { value: "Shri Ram Equipment Fin. Co. Ltd", label: "Shri Ram Equipment Fin. Co. Ltd" },
  { value: "Shri Ram Finance & Investment", label: "Shri Ram Finance & Investment" },
  { value: "Shri Ram Finance Co.Ltd.", label: "Shri Ram Finance Co.Ltd." },
  { value: "Shri Ram Finance Service Ltd", label: "Shri Ram Finance Service Ltd" },
  { value: "Shri Ram Fortune", label: "Shri Ram Fortune" },
  { value: "Shri Ram Fortune Solution Ltd", label: "Shri Ram Fortune Solution Ltd" },
  { value: "Shri Ram Investment Ltd", label: "Shri Ram Investment Ltd" },
  { value: "Shri Ram Investments Finance Ltd", label: "Shri Ram Investments Finance Ltd" },
  { value: "Shri Ram Motors", label: "Shri Ram Motors" },
  { value: "Shri Ram Motors Financial Services Ltd", label: "Shri Ram Motors Financial Services Ltd" },
  { value: "Shri Ram Sharnam Finasncial Co", label: "Shri Ram Sharnam Finasncial Co" },
  { value: "Shri Ram Trade Finance Co Ltd", label: "Shri Ram Trade Finance Co Ltd" },
  { value: "Shri Ram Transport Co.", label: "Shri Ram Transport Co." },
  { value: "Shri Ram Transport Co. Ltd.", label: "Shri Ram Transport Co. Ltd." },
  { value: "Shri Ram Transport Finance Company Ltd", label: "Shri Ram Transport Finance Company Ltd" },
  { value: "Shri Ram Transport Services Ltd", label: "Shri Ram Transport Services Ltd" },
  { value: "Shri Ram Transporter Finance Co Ltd", label: "Shri Ram Transporter Finance Co Ltd" },
  { value: "Shri Ram Union Ltd", label: "Shri Ram Union Ltd" },
  { value: "Shri Rama Grameena Bank", label: "Shri Rama Grameena Bank" },
  { value: "Shri Ramalingeshwar Credit Co-Op Society Ltd.", label: "Shri Ramalingeshwar Credit Co-Op Society Ltd." },
  { value: "Shri Ramkrushna Gramin Sahkari Patsanstha Mydt", label: "Shri Ramkrushna Gramin Sahkari Patsanstha Mydt" },
  { value: "Shri Ramnagar Juth Seva Sahakari Mandali Ltd.", label: "Shri Ramnagar Juth Seva Sahakari Mandali Ltd." },
  { value: "Shri Ravalnath Gramin Bigar Sahakari Patsanstha Maryadit", label: "Shri Ravalnath Gramin Bigar Sahakari Patsanstha Maryadit" },
  { value: "Shri Renuka Auto Finance (R)", label: "Shri Renuka Auto Finance (R)" },
  { value: "Shri Revansiddehswar Sahakari Bank Ni,", label: "Shri Revansiddehswar Sahakari Bank Ni," },
  { value: "Shri Rukmini Nagari Sahkari Bank Ltd.", label: "Shri Rukmini Nagari Sahkari Bank Ltd." },
  { value: "Shri S K Patil Co-Op Bank Ltd", label: "Shri S K Patil Co-Op Bank Ltd" },
  { value: "Shri S.K.Patil Co-Op Bank Ltd.", label: "Shri S.K.Patil Co-Op Bank Ltd." },
  { value: "Shri Sadguru Haribuva Maharaj Pat Sanstha", label: "Shri Sadguru Haribuva Maharaj Pat Sanstha" },
  { value: "Shri Sadguru Jangali Maharaj Sahakari Bank", label: "Shri Sadguru Jangali Maharaj Sahakari Bank" },
  { value: "Shri Sahastrarjun Nagari Sahakari Patsanstha Maryadit", label: "Shri Sahastrarjun Nagari Sahakari Patsanstha Maryadit" },
  { value: "Shri Sahyadri Nagari Sahakari Patsanstha", label: "Shri Sahyadri Nagari Sahakari Patsanstha" },
  { value: "Shri Sai Arihant Nagari Sahakari Patsanstha Maryadit", label: "Shri Sai Arihant Nagari Sahakari Patsanstha Maryadit" },
  { value: "Shri Sai Co-Op Credit Society Ltd", label: "Shri Sai Co-Op Credit Society Ltd" },
  { value: "Shri Sai Enterprises", label: "Shri Sai Enterprises" },
  { value: "Shri Sai Ganesh Co-Op Society", label: "Shri Sai Ganesh Co-Op Society" },
  { value: "Shri Sai Nagari Sahkari Patsansths Maryadit", label: "Shri Sai Nagari Sahkari Patsansths Maryadit" },
  { value: "Shri Sai Samarth Nagari Sah Pathsanstha Ltd Shirdi", label: "Shri Sai Samarth Nagari Sah Pathsanstha Ltd Shirdi" },
  { value: "Shri Sai Siddhi Co. Op. Credit Soc.", label: "Shri Sai Siddhi Co. Op. Credit Soc." },
  { value: "Shri Sai Siddhivinayak Nagri Sahakari Patsanstha Maryadit", label: "Shri Sai Siddhivinayak Nagri Sahakari Patsanstha Maryadit" },
  { value: "Shri Sai Urban Co-Op Bank Ltd", label: "Shri Sai Urban Co-Op Bank Ltd" },
  { value: "Shri Sai Urban Co-Op Bank Ltd", label: "Shri Sai Urban Co-Op Bank Ltd" },
  { value: "Shri Saibaba Gramin Bigarsheti Sahakari Pat Sanstha Ltd.", label: "Shri Saibaba Gramin Bigarsheti Sahakari Pat Sanstha Ltd." },
  { value: "Shri Saikrupa Gramin Biger Sheti Sah Pat San", label: "Shri Saikrupa Gramin Biger Sheti Sah Pat San" },
  { value: "Shri Sainath Co Op Credit Soc.Ltd", label: "Shri Sainath Co Op Credit Soc.Ltd" },
  { value: "Shri Sainath Nagari Sah Pat", label: "Shri Sainath Nagari Sah Pat" },
  { value: "Shri Sairam Finance", label: "Shri Sairam Finance" },
  { value: "Shri Saleshwar Urban Co Op Credit Society Ltd", label: "Shri Saleshwar Urban Co Op Credit Society Ltd" },
  { value: "Shri Samarth Co Op Bank Ltd", label: "Shri Samarth Co Op Bank Ltd" },
  { value: "Shri Samarth Haridas Baba Pat", label: "Shri Samarth Haridas Baba Pat" },
  { value: "Shri Samarth Sahakari Bank Ltd", label: "Shri Samarth Sahakari Bank Ltd" },
  { value: "Shri Samartha Sahakari Bank", label: "Shri Samartha Sahakari Bank" },
  { value: "Shri Sambhavnath Nagri Sah Patsanstha", label: "Shri Sambhavnath Nagri Sah Patsanstha" },
  { value: "Shri Sangam Nagari Sah. Patsanstha Maryadit", label: "Shri Sangam Nagari Sah. Patsanstha Maryadit" },
  { value: "Shri Sangemeshwar Leasing Finance", label: "Shri Sangemeshwar Leasing Finance" },
  { value: "Shri Sangram Nagari Sahakari Patsanstha Maryadit", label: "Shri Sangram Nagari Sahakari Patsanstha Maryadit" },
  { value: "Shri Sant Balaji Babag Path San.", label: "Shri Sant Balaji Babag Path San." },
  { value: "Shri Sant Dnyndeo Mauli Nagari Sahakari Path Sanstha", label: "Shri Sant Dnyndeo Mauli Nagari Sahakari Path Sanstha" },
  { value: "Shri Sant Gadge Maharaj Nagari Sah Pat San Maryadit.", label: "Shri Sant Gadge Maharaj Nagari Sah Pat San Maryadit." },
  { value: "Shri Sant Gadgebaba Nag.Sah.Pat San.Mary", label: "Shri Sant Gadgebaba Nag.Sah.Pat San.Mary" },
  { value: "Shri Sant Gajanan Pat Sanstha Mirajgaon", label: "Shri Sant Gajanan Pat Sanstha Mirajgaon" },
  { value: "Shri Sant Gramin Bigar Sheti Sah.", label: "Shri Sant Gramin Bigar Sheti Sah." },
  { value: "Shri Sant Nagebaba Pat Sanstha", label: "Shri Sant Nagebaba Pat Sanstha" },
  { value: "Shri Sant Savata Maharaj Nagari Pat Sanstha Maryadit.", label: "Shri Sant Savata Maharaj Nagari Pat Sanstha Maryadit." },
  { value: "Shri Sant Wamanbhau Pat San Pat  Ahmednagar", label: "Shri Sant Wamanbhau Pat San Pat  Ahmednagar" },
  { value: "Shri Santa Sena Sahkari Pathsanstha Ltd Maryadit", label: "Shri Santa Sena Sahkari Pathsanstha Ltd Maryadit" },
  { value: "Shri Santaji Maharaj Nagari Sahakari Pat Sanstha", label: "Shri Santaji Maharaj Nagari Sahakari Pat Sanstha" },
  { value: "Shri Sapt Srungi Co Op Credit Soc Ltd", label: "Shri Sapt Srungi Co Op Credit Soc Ltd" },
  { value: "Shri Saptshrungi Mata Mahila Gramin Musalgaon Bigar Sheti Sanstha", label: "Shri Saptshrungi Mata Mahila Gramin Musalgaon Bigar Sheti Sanstha" },
  { value: "Shri Sarapadad Juth Seva Sahakari Mandli", label: "Shri Sarapadad Juth Seva Sahakari Mandli" },
  { value: "Shri Saraswathi Credit Sowharda Sahakari Ltd", label: "Shri Saraswathi Credit Sowharda Sahakari Ltd" },
  { value: "Shri Sardar Patel Co Op Credit Society Ltd", label: "Shri Sardar Patel Co Op Credit Society Ltd" },
  { value: "Shri Sardar Seva Sahkari Mandali Ltd", label: "Shri Sardar Seva Sahkari Mandali Ltd" },
  { value: "Shri Sateridevi Gra. Big. Sah. Pat Sanstha Maryadit", label: "Shri Sateridevi Gra. Big. Sah. Pat Sanstha Maryadit" },
  { value: "Shri Satyavijay Sahakari Bank Ltd.", label: "Shri Satyavijay Sahakari Bank Ltd." },
  { value: "Shri Savdi Kishan Seva Sahakari", label: "Shri Savdi Kishan Seva Sahakari" },
  { value: "Shri Savitri Co Op Credit Society Ltd", label: "Shri Savitri Co Op Credit Society Ltd" },
  { value: "Shri Sevagiri Gramin Bigar Sheti Sah Pat Sanstha Maryadit", label: "Shri Sevagiri Gramin Bigar Sheti Sah Pat Sanstha Maryadit" },
  { value: "Shri Shadhashei Souharda Shakari Niyamit", label: "Shri Shadhashei Souharda Shakari Niyamit" },
  { value: "Shri Shahastraji Arban Co Op Cr", label: "Shri Shahastraji Arban Co Op Cr" },
  { value: "Shri Shahu Co- Op Bank Ltd", label: "Shri Shahu Co- Op Bank Ltd" },
  { value: "Shri Shahu Co-Op.Bank Ltd., Kolhapur", label: "Shri Shahu Co-Op.Bank Ltd., Kolhapur" },
  { value: "Shri Shahu Nagari Sahakari Patsanstha Maryadit", label: "Shri Shahu Nagari Sahakari Patsanstha Maryadit" },
  { value: "Shri Shakambari Mahik Pattina Sahakari Sangha Niyamith", label: "Shri Shakambari Mahik Pattina Sahakari Sangha Niyamith" },
  { value: "Shri Shakat Shanala Seva Sahakari  Mandali Ltd", label: "Shri Shakat Shanala Seva Sahakari  Mandali Ltd" },
  { value: "Shri Shani Vyankatesh Nagari Path Sanstha Bank", label: "Shri Shani Vyankatesh Nagari Path Sanstha Bank" },
  { value: "Shri Shankarling Cr Souhard Sahakari Ltd.", label: "Shri Shankarling Cr Souhard Sahakari Ltd." },
  { value: "Shri Shankarling Ur Co-Op Cr Society Ltd.", label: "Shri Shankarling Ur Co-Op Cr Society Ltd." },
  { value: "Shri Shantappa Miraji Ur Co-Op Bank Ltd", label: "Shri Shantappa Miraji Ur Co-Op Bank Ltd" },
  { value: "Shri Shantappanna Miraji Urban Co Op Bank Ltd", label: "Shri Shantappanna Miraji Urban Co Op Bank Ltd" },
  { value: "Shri Shantappanna Miraji Urban Cp B L,", label: "Shri Shantappanna Miraji Urban Cp B L," },
  { value: "Shri Shanteshwar Multi Purpose Co Op Society Niyamit", label: "Shri Shanteshwar Multi Purpose Co Op Society Niyamit" },
  { value: "Shri Shanteshwar Pattin Sahakari Sangh Niyamit", label: "Shri Shanteshwar Pattin Sahakari Sangh Niyamit" },
  { value: "Shri Sharada Mahila Co Op Bank Ltd", label: "Shri Sharada Mahila Co Op Bank Ltd" },
  { value: "Shri Sharada Mahila Gramin Bigar Sheti Sahakari Path Sanstha Maryadit", label: "Shri Sharada Mahila Gramin Bigar Sheti Sahakari Path Sanstha Maryadit" },
  { value: "Shri Sharana Veereshwar Pattan Sahakari Bank Ltd", label: "Shri Sharana Veereshwar Pattan Sahakari Bank Ltd" },
  { value: "Shri Shateshwar Pattin Sahakari Sangh Niyamit", label: "Shri Shateshwar Pattin Sahakari Sangh Niyamit" },
  { value: "Shri Shidheshwar Patsantha Maryadit", label: "Shri Shidheshwar Patsantha Maryadit" },
  { value: "Shri Shirdi Sai Finance", label: "Shri Shirdi Sai Finance" },
  { value: "Shri Shiva Chidambareshwara Urban Co-Operative Society", label: "Shri Shiva Chidambareshwara Urban Co-Operative Society" },
  { value: "Shri Shivabodharang Urban Co-Oprative Credit Society Ltd, Mudalgi", label: "Shri Shivabodharang Urban Co-Oprative Credit Society Ltd, Mudalgi" },
  { value: "Shri Shivaji Sahakari Bank Ltd.", label: "Shri Shivaji Sahakari Bank Ltd." },
  { value: "Shri Shivaji Shahu Bank Ltd", label: "Shri Shivaji Shahu Bank Ltd" },
  { value: "Shri Shivajiraje Vyapari Nag Sah Pat Sanstha Maryadit", label: "Shri Shivajiraje Vyapari Nag Sah Pat Sanstha Maryadit" },
  { value: "Shri Shivam & Mangalam Fin.Co.", label: "Shri Shivam & Mangalam Fin.Co." },
  { value: "Shri Shivashakti Urban Co-Operative Society Ltd", label: "Shri Shivashakti Urban Co-Operative Society Ltd" },
  { value: "Shri Shivashankar Ur Co-Op Credit Society Ltd.", label: "Shri Shivashankar Ur Co-Op Credit Society Ltd." },
  { value: "Shri Shivayogi Mugendraswami Urban Co-Op Bank Ltd", label: "Shri Shivayogi Mugendraswami Urban Co-Op Bank Ltd" },
  { value: "Shri Shivayogi Murughendraswami Ur Cp Bl", label: "Shri Shivayogi Murughendraswami Ur Cp Bl" },
  { value: "Shri Shivayogi Ur Co Op Cr Society Ltd.", label: "Shri Shivayogi Ur Co Op Cr Society Ltd." },
  { value: "Shri Shiveshwar Nagari Sah.Bank Ltd", label: "Shri Shiveshwar Nagari Sah.Bank Ltd" },
  { value: "Shri Shiveswar Urban Co-Op Bank Ltd", label: "Shri Shiveswar Urban Co-Op Bank Ltd" },
  { value: "Shri Shivneri G.B.S.S.P.S. Mydt", label: "Shri Shivneri G.B.S.S.P.S. Mydt" },
  { value: "Shri Shivneri Gramin Bigar Sheti Sahakari Patsantha", label: "Shri Shivneri Gramin Bigar Sheti Sahakari Patsantha" },
  { value: "Shri Shivsahyadri Nagari Sah Pat Sanstha Maryadit", label: "Shri Shivsahyadri Nagari Sah Pat Sanstha Maryadit" },
  { value: "Shri Shivshakti Co Operative Credit Society Ltd", label: "Shri Shivshakti Co Operative Credit Society Ltd" },
  { value: "Shri Shivshakti Gramin Bigarsheti Sah Pat", label: "Shri Shivshakti Gramin Bigarsheti Sah Pat" },
  { value: "Shri Shran Veerashwar Shakari Bank N.", label: "Shri Shran Veerashwar Shakari Bank N." },
  { value: "Shri Shreemal Auto Finance", label: "Shri Shreemal Auto Finance" },
  { value: "Shri Shri Mal Auto Finance", label: "Shri Shri Mal Auto Finance" },
  { value: "Shri Shubhlaxmi Co Op Credit Soc Ltd", label: "Shri Shubhlaxmi Co Op Credit Soc Ltd" },
  { value: "Shri Shyam Auto Deals", label: "Shri Shyam Auto Deals" },
  { value: "Shri Siddeshwar Co Op Bank", label: "Shri Siddeshwar Co Op Bank" },
  { value: "Shri Siddeshwar Co-Operative Credit Society Ltd.", label: "Shri Siddeshwar Co-Operative Credit Society Ltd." },
  { value: "Shri Siddeshwar Souhardh Sahakari Niyamit", label: "Shri Siddeshwar Souhardh Sahakari Niyamit" },
  { value: "Shri Siddh Finance Co", label: "Shri Siddh Finance Co" },
  { value: "Shri Siddha Krupa Finance Co", label: "Shri Siddha Krupa Finance Co" },
  { value: "Shri Siddhalingeshwar Co Op Nandeshwara Credit Society Ltd", label: "Shri Siddhalingeshwar Co Op Nandeshwara Credit Society Ltd" },
  { value: "Shri Siddharudh Nagari Sahakari Pat. Ltd", label: "Shri Siddharudh Nagari Sahakari Pat. Ltd" },
  { value: "Shri Siddhbali Motors Gen Hire Pur", label: "Shri Siddhbali Motors Gen Hire Pur" },
  { value: "Shri Siddheshwar Co-Op. Bank Ltd", label: "Shri Siddheshwar Co-Op. Bank Ltd" },
  { value: "Shri Siddheshwar Gra Big Sheti Sah Pat", label: "Shri Siddheshwar Gra Big Sheti Sah Pat" },
  { value: "Shri Siddhivinayak Nagarik Sahakari Bank Ltd", label: "Shri Siddhivinayak Nagarik Sahakari Bank Ltd" },
  { value: "Shri Siddvant Sahakari Patpedhi Maryadit Branch", label: "Shri Siddvant Sahakari Patpedhi Maryadit Branch" },
  { value: "Shri Sidha Krupa Finance Corporation ( R )", label: "Shri Sidha Krupa Finance Corporation ( R )" },
  { value: "Shri Sidhbali Motor And Gen. Hire Purchase", label: "Shri Sidhbali Motor And Gen. Hire Purchase" },
  { value: "Shri Sidhbaly Motor And Geneal Hire Purchase", label: "Shri Sidhbaly Motor And Geneal Hire Purchase" },
  { value: "Shri Sidhi Venkatesh Sahakari Bank Ltd.", label: "Shri Sidhi Venkatesh Sahakari Bank Ltd." },
  { value: "Shri Sidhnath Nagari Sahakari Patsanstha Mayadit", label: "Shri Sidhnath Nagari Sahakari Patsanstha Mayadit" },
  { value: "Shri Sinnar Nagsah Path Sanstha", label: "Shri Sinnar Nagsah Path Sanstha" },
  { value: "Shri Sonya Maruti Nagari Sahkari Patsanstha Maryadit", label: "Shri Sonya Maruti Nagari Sahkari Patsanstha Maryadit" },
  { value: "Shri Suarna Pattina Souharda Sahakari Niyamit", label: "Shri Suarna Pattina Souharda Sahakari Niyamit" },
  { value: "Shri Subramanya Sahakari Sangh", label: "Shri Subramanya Sahakari Sangh" },
  { value: "Shri Suguna Finance", label: "Shri Suguna Finance" },
  { value: "Shri Suguna Finance", label: "Shri Suguna Finance" },
  { value: "Shri Surati Modhvanik Urben Co Op Soc Ltd", label: "Shri Surati Modhvanik Urben Co Op Soc Ltd" },
  { value: "Shri Swami Sahakari Bank Ltd", label: "Shri Swami Sahakari Bank Ltd" },
  { value: "Shri Swami Samaratha Gramin Bigar Seti Sahakari Patha Sanstha", label: "Shri Swami Samaratha Gramin Bigar Seti Sahakari Patha Sanstha" },
  { value: "Shri Swami Samarth Co Op Bank Ltd", label: "Shri Swami Samarth Co Op Bank Ltd" },
  { value: "Shri Swami Samarth Co Op C.", label: "Shri Swami Samarth Co Op C." },
  { value: "Shri Swami Samarth Gramin Bigar", label: "Shri Swami Samarth Gramin Bigar" },
  { value: "Shri Swami Samarth Sahkari Patsanstha", label: "Shri Swami Samarth Sahkari Patsanstha" },
  { value: "Shri Swami Samarth Urban Co-Op. Bank Ltd", label: "Shri Swami Samarth Urban Co-Op. Bank Ltd" },
  { value: "Shri Swami Samarth Urban Coop Bk Ltd,", label: "Shri Swami Samarth Urban Coop Bk Ltd," },
  { value: "Shri Swami Samrath Society", label: "Shri Swami Samrath Society" },
  { value: "Shri Swami Vivekanand Nagari Sahakari Patsanstha Maryadit", label: "Shri Swami Vivekanand Nagari Sahakari Patsanstha Maryadit" },
  { value: "Shri Swaminarayan Co-Op Bank Ltd.", label: "Shri Swaminarayan Co-Op Bank Ltd." },
  { value: "Shri Swamy Samrath Laesing Finance Ltd", label: "Shri Swamy Samrath Laesing Finance Ltd" },
  { value: "Shri Tankara Juth Seva Shahkari Mandli Ltd", label: "Shri Tankara Juth Seva Shahkari Mandli Ltd" },
  { value: "Shri Tarvada Juth Seva Sahkari Mandali Ltd", label: "Shri Tarvada Juth Seva Sahkari Mandali Ltd" },
  { value: "Shri Tembalai Nagari Sahakari Pat Sanstha Maryadit", label: "Shri Tembalai Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Shri Thaarani Finance", label: "Shri Thaarani Finance" },
  { value: "Shri Thirumala Auto Finance Corporation", label: "Shri Thirumala Auto Finance Corporation" },
  { value: "Shri Tirupati Nagari Sahakari Bank Ltd", label: "Shri Tirupati Nagari Sahakari Bank Ltd" },
  { value: "Shri Tirupati Nagari Sahakari Patsansatha", label: "Shri Tirupati Nagari Sahakari Patsansatha" },
  { value: "Shri Transport Finance Co Ltd", label: "Shri Transport Finance Co Ltd" },
  { value: "Shri Tulshi Nagari Sahakari Patsantha Maryadit", label: "Shri Tulshi Nagari Sahakari Patsantha Maryadit" },
  { value: "Shri Tumsarshwar Mahaganpati Sah Path Sanstha", label: "Shri Tumsarshwar Mahaganpati Sah Path Sanstha" },
  { value: "Shri Ukarda Juth Seva Sahkari Mandali Ltd", label: "Shri Ukarda Juth Seva Sahkari Mandali Ltd" },
  { value: "Shri Umiya Finance Lease Pvt Ltd.", label: "Shri Umiya Finance Lease Pvt Ltd." },
  { value: "Shri Umta Nagaraik Sahakari Dhiran Mandali Ltd", label: "Shri Umta Nagaraik Sahakari Dhiran Mandali Ltd" },
  { value: "Shri Union Finance", label: "Shri Union Finance" },
  { value: "Shri Urban Sahakari Bank Ltd", label: "Shri Urban Sahakari Bank Ltd" },
  { value: "Shri Vadala Seva Sahakari Mandli Ltd", label: "Shri Vadala Seva Sahakari Mandli Ltd" },
  { value: "Shri Vadali Seva Sahakari Mandali", label: "Shri Vadali Seva Sahakari Mandali" },
  { value: "Shri Vallabha Nagari Sah Pat Sanstha", label: "Shri Vallabha Nagari Sah Pat Sanstha" },
  { value: "Shri Vallabha Nagri Sahakari Pathsanstha", label: "Shri Vallabha Nagri Sahakari Pathsanstha" },
  { value: "Shri Vanthali V Nagarik Sah Bank Ltd", label: "Shri Vanthali V Nagarik Sah Bank Ltd" },
  { value: "Shri Vanthali V Nagarik Sahakari Bank Lt", label: "Shri Vanthali V Nagarik Sahakari Bank Lt" },
  { value: "Shri Vardhavinayak Gramin Bigar Sheti Sahakari Pathsanstha Ltd", label: "Shri Vardhavinayak Gramin Bigar Sheti Sahakari Pathsanstha Ltd" },
  { value: "Shri Vasantrao Chougule Nag Sah Pat Sanstha Mydt", label: "Shri Vasantrao Chougule Nag Sah Pat Sanstha Mydt" },
  { value: "Shri Ved Bajaj", label: "Shri Ved Bajaj" },
  { value: "Shri Veer Pulikeshi Co-Op Bank Ltd", label: "Shri Veer Pulikeshi Co-Op Bank Ltd" },
  { value: "Shri Veer Pulikeshi Co-Op Bank Ltd.", label: "Shri Veer Pulikeshi Co-Op Bank Ltd." },
  { value: "Shri Veerbhadreshwar Vividoddeshagal Souhard Sahakari Ltd.", label: "Shri Veerbhadreshwar Vividoddeshagal Souhard Sahakari Ltd." },
  { value: "Shri Veershaiv Co-Op. Bank Ltd. Kolhapur", label: "Shri Veershaiv Co-Op. Bank Ltd. Kolhapur" },
  { value: "Shri Veershaiv Co.Op. Bank Ltd", label: "Shri Veershaiv Co.Op. Bank Ltd" },
  { value: "Shri Vejalpal Juth Seva Sahkari Mandali", label: "Shri Vejalpal Juth Seva Sahkari Mandali" },
  { value: "Shri Vejalpar Juth Seva Sahkari Mandali", label: "Shri Vejalpar Juth Seva Sahkari Mandali" },
  { value: "Shri Venkatesh Gramin Bigar Sheti Sahakari Pathsanstha", label: "Shri Venkatesh Gramin Bigar Sheti Sahakari Pathsanstha" },
  { value: "Shri Venkateshwar Urban Credit Souhard Sahakari Ltd.", label: "Shri Venkateshwar Urban Credit Souhard Sahakari Ltd." },
  { value: "Shri Venkateshwara Motors", label: "Shri Venkateshwara Motors" },
  { value: "Shri Venkateswara Grameena Bank.", label: "Shri Venkateswara Grameena Bank." },
  { value: "Shri Vigneswara Auto Finance", label: "Shri Vigneswara Auto Finance" },
  { value: "Shri Vijay Mahantesh Coop Bank Ltd.", label: "Shri Vijay Mahantesh Coop Bank Ltd." },
  { value: "Shri Vijaya Mahantesh Co-Op Bank Ltd", label: "Shri Vijaya Mahantesh Co-Op Bank Ltd" },
  { value: "Shri Vilur Guru Vasava Pattin Sah. San.N", label: "Shri Vilur Guru Vasava Pattin Sah. San.N" },
  { value: "Shri Vinayak Finance", label: "Shri Vinayak Finance" },
  { value: "Shri Virdhaval Jagdale Sah Motor Vahatuk San Mar", label: "Shri Virdhaval Jagdale Sah Motor Vahatuk San Mar" },
  { value: "Shri Virparda Seva Sahakari Mandli Ltd", label: "Shri Virparda Seva Sahakari Mandli Ltd" },
  { value: "Shri Virshaiv Nagari Bigar Sheti Sah Patsansta Ltd", label: "Shri Virshaiv Nagari Bigar Sheti Sah Patsansta Ltd" },
  { value: "Shri Vishnu Finance", label: "Shri Vishnu Finance" },
  { value: "Shri Vishnu Nagari Sah Pat Sanstha Maryadit", label: "Shri Vishnu Nagari Sah Pat Sanstha Maryadit" },
  { value: "Shri Vishu Nagari Sah Pat Sanstha Maryadit", label: "Shri Vishu Nagari Sah Pat Sanstha Maryadit" },
  { value: "Shri Vishvnath Motor Finance", label: "Shri Vishvnath Motor Finance" },
  { value: "Shri Vishweshwarayya Ur Co-Op Cr Society Ltd", label: "Shri Vishweshwarayya Ur Co-Op Cr Society Ltd" },
  { value: "Shri Vithalai Gramin Bigar Sheti Sah Pat Sanstha Maryadit", label: "Shri Vithalai Gramin Bigar Sheti Sah Pat Sanstha Maryadit" },
  { value: "Shri Vittal Souharda Pattina Sahakari Niyamitha", label: "Shri Vittal Souharda Pattina Sahakari Niyamitha" },
  { value: "Shri Vitthal Rakhumai G B S Sah Pat Sanstha Mydt", label: "Shri Vitthal Rakhumai G B S Sah Pat Sanstha Mydt" },
  { value: "Shri Vitthal Rakhumai G B S Sah Pat Sanstha Mydt.", label: "Shri Vitthal Rakhumai G B S Sah Pat Sanstha Mydt." },
  { value: "Shri Vyankatesh Co-Op Bank Ltd.", label: "Shri Vyankatesh Co-Op Bank Ltd." },
  { value: "Shri Vyankatesh Sahakari Bank Ltd", label: "Shri Vyankatesh Sahakari Bank Ltd" },
  { value: "Shri Vyas Dhanvarsha Sah.Bank Ltd.", label: "Shri Vyas Dhanvarsha Sah.Bank Ltd." },
  { value: "Shri Vypari Nag Sah.Pat.Sanstha", label: "Shri Vypari Nag Sah.Pat.Sanstha" },
  { value: "Shri Wagheshwar Maharaj Nag Sah Pat Ltd", label: "Shri Wagheshwar Maharaj Nag Sah Pat Ltd" },
  { value: "Shri Wagjai Co Op Cr Soc Ltd", label: "Shri Wagjai Co Op Cr Soc Ltd" },
  { value: "Shri Warana Gramin Sahakari Pat Sanstha Kolhapur", label: "Shri Warana Gramin Sahakari Pat Sanstha Kolhapur" },
  { value: "Shri Warana Mahila Sahakari Pathsanstha Maryadit", label: "Shri Warana Mahila Sahakari Pathsanstha Maryadit" },
  { value: "Shri Warana Sah. Bank Ltd. Warana Nagar", label: "Shri Warana Sah. Bank Ltd. Warana Nagar" },
  { value: "Shri Yashvant Bhau Patil Gr Vi Sah", label: "Shri Yashvant Bhau Patil Gr Vi Sah" },
  { value: "Shri Yashvant Sahakari Bank Maryadir", label: "Shri Yashvant Sahakari Bank Maryadir" },
  { value: "Shri Yashwant Nagari Sahakari Patsanstha Maryadit", label: "Shri Yashwant Nagari Sahakari Patsanstha Maryadit" },
  { value: "Shri Yashwant Sahakari Bank Ltd", label: "Shri Yashwant Sahakari Bank Ltd" },
  { value: "Shri Yugprabhav Sahakari Bank Ltd.", label: "Shri Yugprabhav Sahakari Bank Ltd." },
  { value: "Shri. P.K.Anna Patil Janata Sah.Bank Ltd", label: "Shri. P.K.Anna Patil Janata Sah.Bank Ltd" },
  { value: "Shridatta Pilkhede Vividh Karyakari Sahakari Society", label: "Shridatta Pilkhede Vividh Karyakari Sahakari Society" },
  { value: "Shridhar Co-Op Soc.", label: "Shridhar Co-Op Soc." },
  { value: "Shridher Co-Op Credit Society Ltd", label: "Shridher Co-Op Credit Society Ltd" },
  { value: "Shridi Sai Auto And Empolyee Finance", label: "Shridi Sai Auto And Empolyee Finance" },
  { value: "Shriji Co Op Credit Soc Ltd", label: "Shriji Co Op Credit Soc Ltd" },
  { value: "Shrikrishna Co-Op. Bank Ltd", label: "Shrikrishna Co-Op. Bank Ltd" },
  { value: "Shrilekha Hire Purchase Finance Ltd.", label: "Shrilekha Hire Purchase Finance Ltd." },
  { value: "Shrilekha Nagri Sahkari Path Sanstha Maryadit", label: "Shrilekha Nagri Sahkari Path Sanstha Maryadit" },
  { value: "Shrimanji Thorale Bajirao Nagari Pathsanstha Marya", label: "Shrimanji Thorale Bajirao Nagari Pathsanstha Marya" },
  { value: "Shrimant Malojiraje Sahakari Bank Ltd.", label: "Shrimant Malojiraje Sahakari Bank Ltd." },
  { value: "Shrimant Saibai Maharaj Sah.Patsanstha M", label: "Shrimant Saibai Maharaj Sah.Patsanstha M" },
  { value: "Shrimant Thorale Bajirao Nagari Patsanstha Mary", label: "Shrimant Thorale Bajirao Nagari Patsanstha Mary" },
  { value: "Shrimant Thorle Bajirao Peshve Path Sanstha", label: "Shrimant Thorle Bajirao Peshve Path Sanstha" },
  { value: "Shrimanth Thorale Bajirao Peshave Patsanstha Dubere", label: "Shrimanth Thorale Bajirao Peshave Patsanstha Dubere" },
  { value: "Shrimat Thorole Baji Rao Peshwe Nag Sah Path San.", label: "Shrimat Thorole Baji Rao Peshwe Nag Sah Path San." },
  { value: "Shrimati Koushalyabai Anna Nimbalkar Nagari Sahkari Patsanstha Mrdt", label: "Shrimati Koushalyabai Anna Nimbalkar Nagari Sahkari Patsanstha Mrdt" },
  { value: "Shrinath Auto Center", label: "Shrinath Auto Center" },
  { value: "Shrinath Co Op Bank Ltd", label: "Shrinath Co Op Bank Ltd" },
  { value: "Shrinath Co Op Credit Society", label: "Shrinath Co Op Credit Society" },
  { value: "Shrinath Credit Co Op Bank", label: "Shrinath Credit Co Op Bank" },
  { value: "Shrinath Gramin Biger Shati Shakari Pat", label: "Shrinath Gramin Biger Shati Shakari Pat" },
  { value: "Shrinath Krupa Nagri Pat", label: "Shrinath Krupa Nagri Pat" },
  { value: "Shrinath Nag Sah Pat Ltd", label: "Shrinath Nag Sah Pat Ltd" },
  { value: "Shrinath Nag. Sah. Pat Sanstha Anr", label: "Shrinath Nag. Sah. Pat Sanstha Anr" },
  { value: "Shrinath Path San", label: "Shrinath Path San" },
  { value: "Shrinath Trading Co", label: "Shrinath Trading Co" },
  { value: "Shrinathji Co-Op Bank Ltd", label: "Shrinathji Co-Op Bank Ltd" },
  { value: "Shrinidhi Co Op Credit Society Ltd.", label: "Shrinidhi Co Op Credit Society Ltd." },
  { value: "Shrinivas Anant Pawar", label: "Shrinivas Anant Pawar" },
  { value: "Shripatrao Dada Sah Bank Ltd", label: "Shripatrao Dada Sah Bank Ltd" },
  { value: "Shripatrao Dada Sahakari Bank Ltd., Kolhapur", label: "Shripatrao Dada Sahakari Bank Ltd., Kolhapur" },
  { value: "Shripatraodada Sahakari Bank Ltd.", label: "Shripatraodada Sahakari Bank Ltd." },
  { value: "Shriram", label: "Shriram" },
  { value: "Shriram Auto & General Financial Services", label: "Shriram Auto & General Financial Services" },
  { value: "Shriram Auto Finance Company", label: "Shriram Auto Finance Company" },
  { value: "Shriram Ceity Union Finance Ltd", label: "Shriram Ceity Union Finance Ltd" },
  { value: "Shriram Chits", label: "Shriram Chits" },
  { value: "Shriram Chits Pvt Ltd", label: "Shriram Chits Pvt Ltd" },
  { value: "Shriram Chits Tamilnadu Ltd", label: "Shriram Chits Tamilnadu Ltd" },
  { value: "Shriram Citi Finance Ltd.", label: "Shriram Citi Finance Ltd." },
  { value: "Shriram Citi Union Finnace Ltd", label: "Shriram Citi Union Finnace Ltd" },
  { value: "Shriram City Union Bank", label: "Shriram City Union Bank" },
  { value: "Shriram City Union Fainance Ltd.", label: "Shriram City Union Fainance Ltd." },
  { value: "Shriram City Union Fin Ser Ltd.", label: "Shriram City Union Fin Ser Ltd." },
  { value: "Shriram City Union Fin.Ltd", label: "Shriram City Union Fin.Ltd" },
  { value: "Shriram City Union Finance Co Ltd", label: "Shriram City Union Finance Co Ltd" },
  { value: "Shriram City Union Finance Ltd - Kumta", label: "Shriram City Union Finance Ltd - Kumta" },
  { value: "Shriram City Union Finance Ltd.", label: "Shriram City Union Finance Ltd." },
  { value: "Shriram City Union Finance Pvt Ltd", label: "Shriram City Union Finance Pvt Ltd" },
  { value: "Shriram Commercial Finance Ltd", label: "Shriram Commercial Finance Ltd" },
  { value: "Shriram Dbf Finance Ltd", label: "Shriram Dbf Finance Ltd" },
  { value: "Shriram Dbf Finance Ltd", label: "Shriram Dbf Finance Ltd" },
  { value: "Shriram Enterprise", label: "Shriram Enterprise" },
  { value: "Shriram Equipment Finance Co. Ltd", label: "Shriram Equipment Finance Co. Ltd" },
  { value: "Shriram Finance", label: "Shriram Finance" },
  { value: "Shriram Finance & Services Ltd", label: "Shriram Finance & Services Ltd" },
  { value: "Shriram Finance Co.", label: "Shriram Finance Co." },
  { value: "Shriram Financial", label: "Shriram Financial" },
  { value: "Shriram Fortune Finance Pvt Ltd", label: "Shriram Fortune Finance Pvt Ltd" },
  { value: "Shriram Gram Big Shetti Sah Pat Sanjtha Marg Sonari.", label: "Shriram Gram Big Shetti Sah Pat Sanjtha Marg Sonari." },
  { value: "Shriram Gramin Bigar Shetti Sahkari Patsanstha Maryadit, Nashik", label: "Shriram Gramin Bigar Shetti Sahkari Patsanstha Maryadit, Nashik" },
  { value: "Shriram Insight Share Broker Ltd.", label: "Shriram Insight Share Broker Ltd." },
  { value: "Shriram Insight Share Brokers Ltd.", label: "Shriram Insight Share Brokers Ltd." },
  { value: "Shriram Insurance Co Ltd", label: "Shriram Insurance Co Ltd" },
  { value: "Shriram Investments Ltd.", label: "Shriram Investments Ltd." },
  { value: "Shriram Nagri Sahakari Pathsanstha Maryadit", label: "Shriram Nagri Sahakari Pathsanstha Maryadit" },
  { value: "Shriram Pat. S. P. Br Barlone", label: "Shriram Pat. S. P. Br Barlone" },
  { value: "Shriram Pat. S.P Br Barlome", label: "Shriram Pat. S.P Br Barlome" },
  { value: "Shriram Phatpedi Maryadit", label: "Shriram Phatpedi Maryadit" },
  { value: "Shriram Sah. Pat.Ltd.", label: "Shriram Sah. Pat.Ltd." },
  { value: "Shriram Sahakari Bank Ltd", label: "Shriram Sahakari Bank Ltd" },
  { value: "Shriram Sahakari Bank Maryadit, Nasik", label: "Shriram Sahakari Bank Maryadit, Nasik" },
  { value: "Shriram Sahakari Pat Ltd.", label: "Shriram Sahakari Pat Ltd." },
  { value: "Shriram Transport", label: "Shriram Transport" },
  { value: "Shriram Transport Fin.Co.Ltd", label: "Shriram Transport Fin.Co.Ltd" },
  { value: "Shriram Transport Finance", label: "Shriram Transport Finance" },
  { value: "Shriram Transport Finance Chits", label: "Shriram Transport Finance Chits" },
  { value: "Shriram Transport Finance Co. Ltd.", label: "Shriram Transport Finance Co. Ltd." },
  { value: "Shriram Transport Finance Company", label: "Shriram Transport Finance Company" },
  { value: "Shriram Transport Finance Ltd", label: "Shriram Transport Finance Ltd" },
  { value: "Shriram Transport Financial Services Ltd", label: "Shriram Transport Financial Services Ltd" },
  { value: "Shriram Transport Investment Co.", label: "Shriram Transport Investment Co." },
  { value: "Shriram Truck Finance Co Ltd", label: "Shriram Truck Finance Co Ltd" },
  { value: "Shriram Union Finance Ltd", label: "Shriram Union Finance Ltd" },
  { value: "Shriram Urban Co-Op. Bank Ltd., Nagpur", label: "Shriram Urban Co-Op. Bank Ltd., Nagpur" },
  { value: "Shriram Urban Co-Operative Bank Ltd.", label: "Shriram Urban Co-Operative Bank Ltd." },
  { value: "Shriram Urban Co.Op Bank Ltd", label: "Shriram Urban Co.Op Bank Ltd" },
  { value: "Shrirampur Peoples Co-Op Bank Ltd", label: "Shrirampur Peoples Co-Op Bank Ltd" },
  { value: "Shrisaidatta Nagari Sahkari Pat Sanstha", label: "Shrisaidatta Nagari Sahkari Pat Sanstha" },
  { value: "Shrisantaji Maharaj Nagari Patsanstha Maryadit, Nashik", label: "Shrisantaji Maharaj Nagari Patsanstha Maryadit, Nashik" },
  { value: "Shrishti Motors", label: "Shrishti Motors" },
  { value: "Shrishubh Investment", label: "Shrishubh Investment" },
  { value: "Shriyara Sahakari Vyavasayika Bank", label: "Shriyara Sahakari Vyavasayika Bank" },
  { value: "Shroff Capital And Finance Pvt Ltd", label: "Shroff Capital And Finance Pvt Ltd" },
  { value: "Shrram City Union Finance Bangalkot", label: "Shrram City Union Finance Bangalkot" },
  { value: "Shrree Ganesh Eterprise", label: "Shrree Ganesh Eterprise" },
  { value: "Shruthi Finance", label: "Shruthi Finance" },
  { value: "Shruthi Jain Financier", label: "Shruthi Jain Financier" },
  { value: "Shruthi M Chordia", label: "Shruthi M Chordia" },
  { value: "Shrutika Finance", label: "Shrutika Finance" },
  { value: "Shubh Credit Finance", label: "Shubh Credit Finance" },
  { value: "Shubh Finance", label: "Shubh Finance" },
  { value: "Shubh Investment", label: "Shubh Investment" },
  { value: "Shubh Laxmi Mahila Co-Op Bank Ltd", label: "Shubh Laxmi Mahila Co-Op Bank Ltd" },
  { value: "Shubh Wheeler Finance", label: "Shubh Wheeler Finance" },
  { value: "Shubh-Laxmi Mahila Co-Op Bank Ltd., Indore", label: "Shubh-Laxmi Mahila Co-Op Bank Ltd., Indore" },
  { value: "Shubha Motors", label: "Shubha Motors" },
  { value: "Shubha Shrushti Nagrik Sah Pathpedhi Maryadi", label: "Shubha Shrushti Nagrik Sah Pathpedhi Maryadi" },
  { value: "Shubhalakshmi Mahila Co-Op.Bank Ltd", label: "Shubhalakshmi Mahila Co-Op.Bank Ltd" },
  { value: "Shubham Corporation", label: "Shubham Corporation" },
  { value: "Shubham Finance", label: "Shubham Finance" },
  { value: "Shubham Finance", label: "Shubham Finance" },
  { value: "Shubham Finance Corporation", label: "Shubham Finance Corporation" },
  { value: "Shubham Financial Services", label: "Shubham Financial Services" },
  { value: "Shubham Lease & Finance Co.", label: "Shubham Lease & Finance Co." },
  { value: "Shubham Leasing Finance Co. Ltd.", label: "Shubham Leasing Finance Co. Ltd." },
  { value: "Shubham Motor Consultant", label: "Shubham Motor Consultant" },
  { value: "Shubham Motor Finance", label: "Shubham Motor Finance" },
  { value: "Shubham Nagari Sah Patpedhi Maryadit", label: "Shubham Nagari Sah Patpedhi Maryadit" },
  { value: "Shubham Sah Pat Ltd", label: "Shubham Sah Pat Ltd" },
  { value: "Shubhangi Nagri Sahakari Path Sanstha Maryadit Ltd", label: "Shubhangi Nagri Sahakari Path Sanstha Maryadit Ltd" },
  { value: "Shubhchola Raipur", label: "Shubhchola Raipur" },
  { value: "Shubhlaxmi Finance", label: "Shubhlaxmi Finance" },
  { value: "Shubhlaxmi Investment", label: "Shubhlaxmi Investment" },
  { value: "Shubhodaya Leasing ( R )", label: "Shubhodaya Leasing ( R )" },
  { value: "Shudhaleshwar Bigar Sheti Sahakari Patsanstha Maryadit", label: "Shudhaleshwar Bigar Sheti Sahakari Patsanstha Maryadit" },
  { value: "Shukla Mahajan", label: "Shukla Mahajan" },
  { value: "Shurthi M Chordia", label: "Shurthi M Chordia" },
  { value: "Shurusti Sahakari Bank Niyamith", label: "Shurusti Sahakari Bank Niyamith" },
  { value: "Shushil Hire Purchase", label: "Shushil Hire Purchase" },
  { value: "Shushruti Co-Op Bank Ltd.", label: "Shushruti Co-Op Bank Ltd." },
  { value: "Shushruti Sahakara Bank Niyamitha", label: "Shushruti Sahakara Bank Niyamitha" },
  { value: "Shushruti Souharda Sahakara Bank Niyamit", label: "Shushruti Souharda Sahakara Bank Niyamit" },
  { value: "Shweta Fincap", label: "Shweta Fincap" },
  { value: "Shyam Enterprise", label: "Shyam Enterprise" },
  { value: "Shyam Finance Corporation", label: "Shyam Finance Corporation" },
  { value: "Shyam Rao Co Op Bank", label: "Shyam Rao Co Op Bank" },
  { value: "Shyam S Sharma", label: "Shyam S Sharma" },
  { value: "Shyam Sundar Sadani", label: "Shyam Sundar Sadani" },
  { value: "Shyamal Banerjee", label: "Shyamal Banerjee" },
  { value: "Shyamal Trading Corporation", label: "Shyamal Trading Corporation" },
  { value: "Shyamrao Vittal Co-Operative Bank Ltd", label: "Shyamrao Vittal Co-Operative Bank Ltd" },
  { value: "Sibar Finance Ltd.", label: "Sibar Finance Ltd." },
  { value: "Sibi Auto Finance", label: "Sibi Auto Finance" },
  { value: "Sibi P Varghese", label: "Sibi P Varghese" },
  { value: "Sibsagar District Central Co-Op Bank Ltd", label: "Sibsagar District Central Co-Op Bank Ltd" },
  { value: "Sicom Limited", label: "Sicom Limited" },
  { value: "Sidak Automobiles Pvt. Ltd.", label: "Sidak Automobiles Pvt. Ltd." },
  { value: "Sidanth Finance", label: "Sidanth Finance" },
  { value: "Sidarath Syndicate", label: "Sidarath Syndicate" },
  { value: "Sidbi Bank", label: "Sidbi Bank" },
  { value: "Sidda Ganga Urban Co-Op Bank Ltd", label: "Sidda Ganga Urban Co-Op Bank Ltd" },
  { value: "Sidda Krupa Finance Corporation", label: "Sidda Krupa Finance Corporation" },
  { value: "Sidda Pravatna P P N", label: "Sidda Pravatna P P N" },
  { value: "Sidda Siri Souharda Sah Nig", label: "Sidda Siri Souharda Sah Nig" },
  { value: "Siddaganga Urban Co-Operative Bank Ltd.", label: "Siddaganga Urban Co-Operative Bank Ltd." },
  { value: "Siddanth Finance", label: "Siddanth Finance" },
  { value: "Siddarameshwara Cr.Co-Op.Society Niyami.", label: "Siddarameshwara Cr.Co-Op.Society Niyami." },
  { value: "Siddarameshwara Sahakara Bank Niyamitha", label: "Siddarameshwara Sahakara Bank Niyamitha" },
  { value: "Siddarth Motors", label: "Siddarth Motors" },
  { value: "Siddeshwar Leasing (R)", label: "Siddeshwar Leasing (R)" },
  { value: "Siddeshwar Saha Bank Ltd", label: "Siddeshwar Saha Bank Ltd" },
  { value: "Siddeshwar Urban Co-Op Bank Ltd", label: "Siddeshwar Urban Co-Op Bank Ltd" },
  { value: "Siddh Auto Finance", label: "Siddh Auto Finance" },
  { value: "Siddh Shree Enterprise", label: "Siddh Shree Enterprise" },
  { value: "Siddha Shri Finance", label: "Siddha Shri Finance" },
  { value: "Siddhanath Nagari Sah Path San Mary Br.Phaltan", label: "Siddhanath Nagari Sah Path San Mary Br.Phaltan" },
  { value: "Siddharth Finance", label: "Siddharth Finance" },
  { value: "Siddharth Finance Corporation", label: "Siddharth Finance Corporation" },
  { value: "Siddharth Motors", label: "Siddharth Motors" },
  { value: "Siddharth Sahakari Bank Ltd.", label: "Siddharth Sahakari Bank Ltd." },
  { value: "Siddharth Syndicate", label: "Siddharth Syndicate" },
  { value: "Siddhartha Sahakari Bank Ltd", label: "Siddhartha Sahakari Bank Ltd" },
  { value: "Siddharudh Motors K Chincholi", label: "Siddharudh Motors K Chincholi" },
  { value: "Siddheshwar Gramin Bigarsheti Sah. Patsanstha", label: "Siddheshwar Gramin Bigarsheti Sah. Patsanstha" },
  { value: "Siddheshwar Nagari Sahkari Pathsanstha", label: "Siddheshwar Nagari Sahkari Pathsanstha" },
  { value: "Siddheshwar Sahakari Bank Ltd.", label: "Siddheshwar Sahakari Bank Ltd." },
  { value: "Siddheshwar Ur. Co-Op. Bk.Maryadit", label: "Siddheshwar Ur. Co-Op. Bk.Maryadit" },
  { value: "Siddheshwar Urban Co-Op Bank Myt", label: "Siddheshwar Urban Co-Op Bank Myt" },
  { value: "Siddheshwari Finance", label: "Siddheshwari Finance" },
  { value: "Siddhi Co-Op Bank Ltd", label: "Siddhi Co-Op Bank Ltd" },
  { value: "Siddhi Co. Op. Bank Ltd", label: "Siddhi Co. Op. Bank Ltd" },
  { value: "Siddhi Enterprises", label: "Siddhi Enterprises" },
  { value: "Siddhi Enterprises", label: "Siddhi Enterprises" },
  { value: "Siddhi Finance", label: "Siddhi Finance" },
  { value: "Siddhi Vinayak Enterprise", label: "Siddhi Vinayak Enterprise" },
  { value: "Siddhi Vinayaka Souhardha Pattina Sahakari Niyamith", label: "Siddhi Vinayaka Souhardha Pattina Sahakari Niyamith" },
  { value: "Siddhivinayak Co-Op Credit Soc Maryadit", label: "Siddhivinayak Co-Op Credit Soc Maryadit" },
  { value: "Siddhivinayak Gramin Sahkari Pathsanstha Maryadit", label: "Siddhivinayak Gramin Sahkari Pathsanstha Maryadit" },
  { value: "Siddhivinayak Nagri Sah Patsanstha Maryadit", label: "Siddhivinayak Nagri Sah Patsanstha Maryadit" },
  { value: "Siddhsiri Patin Souhardha Sahakari Ltd.", label: "Siddhsiri Patin Souhardha Sahakari Ltd." },
  { value: "Siddi Co-Op Bank Ltd, Ahmedabad", label: "Siddi Co-Op Bank Ltd, Ahmedabad" },
  { value: "Siddi Finance", label: "Siddi Finance" },
  { value: "Siddi Vinayaka Auto Finance", label: "Siddi Vinayaka Auto Finance" },
  { value: "Siddi Vinayaka Hp And Finance", label: "Siddi Vinayaka Hp And Finance" },
  { value: "Siddi Vinayaka Souhardha Pattin Sahakari Niyamitha", label: "Siddi Vinayaka Souhardha Pattin Sahakari Niyamitha" },
  { value: "Siddnath Nagari Sah Pat Sanstha", label: "Siddnath Nagari Sah Pat Sanstha" },
  { value: "Sidh Finance", label: "Sidh Finance" },
  { value: "Sidh Finance Corporation", label: "Sidh Finance Corporation" },
  { value: "Sidh Kiran Jain", label: "Sidh Kiran Jain" },
  { value: "Sidh Vinayak Motor &  Gererl Finance", label: "Sidh Vinayak Motor &  Gererl Finance" },
  { value: "Sidh Vinayak Motor & Gererl Finance", label: "Sidh Vinayak Motor & Gererl Finance" },
  { value: "Sidhant Finance Corporation", label: "Sidhant Finance Corporation" },
  { value: "Sidhanth Auto Finance", label: "Sidhanth Auto Finance" },
  { value: "Sidhanth Syndicate", label: "Sidhanth Syndicate" },
  { value: "Sidhanth Trade Finance Corporation", label: "Sidhanth Trade Finance Corporation" },
  { value: "Sidharth Investment", label: "Sidharth Investment" },
  { value: "Sidharth Motor & General Finance", label: "Sidharth Motor & General Finance" },
  { value: "Sidharth Syndicate", label: "Sidharth Syndicate" },
  { value: "Sidharth Trade Finance Corporation", label: "Sidharth Trade Finance Corporation" },
  { value: "Sidhasiri Pattin Sahakari Niyamit", label: "Sidhasiri Pattin Sahakari Niyamit" },
  { value: "Sidhbali Motor And General Finance", label: "Sidhbali Motor And General Finance" },
  { value: "Sidhi District Central Co-Op Bank Ltd", label: "Sidhi District Central Co-Op Bank Ltd" },
  { value: "Sidhi Vinayar Finance", label: "Sidhi Vinayar Finance" },
  { value: "Sidhivinayak Nagari Sahakari Patsanstha", label: "Sidhivinayak Nagari Sahakari Patsanstha" },
  { value: "Sidhu Investment Pvt. Ltd.", label: "Sidhu Investment Pvt. Ltd." },
  { value: "Sidhu Leasing And Finance Co. Ltd.", label: "Sidhu Leasing And Finance Co. Ltd." },
  { value: "Signature Finance Pvt Ltd", label: "Signature Finance Pvt Ltd" },
  { value: "Sihdhi Vinayak Finance", label: "Sihdhi Vinayak Finance" },
  { value: "Sihor Mercantile Co-Operative Bank Ltd.", label: "Sihor Mercantile Co-Operative Bank Ltd." },
  { value: "Sihor Mercco-Op Bank Ltd", label: "Sihor Mercco-Op Bank Ltd" },
  { value: "Sikar District Central Co-Operative Bank Ltd.", label: "Sikar District Central Co-Operative Bank Ltd." },
  { value: "Sikar Kendriya Sahakari Bank Ltd", label: "Sikar Kendriya Sahakari Bank Ltd" },
  { value: "Sikar Urban Co-Op Bank Ltd", label: "Sikar Urban Co-Op Bank Ltd" },
  { value: "Sikar Urban Co-Operative Bank Ltd", label: "Sikar Urban Co-Operative Bank Ltd" },
  { value: "Sikker", label: "Sikker" },
  { value: "Sikkim Bank Ltd.", label: "Sikkim Bank Ltd." },
  { value: "Sikkim Industrial Development And Investment Corporation", label: "Sikkim Industrial Development And Investment Corporation" },
  { value: "Sikkim State Central Co-Oprative Bank Ltd.", label: "Sikkim State Central Co-Oprative Bank Ltd." },
  { value: "Sikkim State Co-Op Bank Ltd", label: "Sikkim State Co-Op Bank Ltd" },
  { value: "Sikkim State Tourism Development Corporation Ltd.", label: "Sikkim State Tourism Development Corporation Ltd." },
  { value: "Silk City Ur Co Op Cr So Ltd", label: "Silk City Ur Co Op Cr So Ltd" },
  { value: "Silver Jubilee Co-Operative", label: "Silver Jubilee Co-Operative" },
  { value: "Silver Sky Finance Ltd", label: "Silver Sky Finance Ltd" },
  { value: "Simer Financers", label: "Simer Financers" },
  { value: "Simer Hire Pruchase Ltd", label: "Simer Hire Pruchase Ltd" },
  { value: "Simhapuri Finance", label: "Simhapuri Finance" },
  { value: "Simiadevi Bhandari", label: "Simiadevi Bhandari" },
  { value: "Simla Deci Bhandari", label: "Simla Deci Bhandari" },
  { value: "Simmham Finance", label: "Simmham Finance" },
  { value: "Simmham Finance & Investments", label: "Simmham Finance & Investments" },
  { value: "Simple Bothra Finance", label: "Simple Bothra Finance" },
  { value: "Simpson And General Finance Co Ltd.", label: "Simpson And General Finance Co Ltd." },
  { value: "Simran Fin.", label: "Simran Fin." },
  { value: "Simran Finance Co.", label: "Simran Finance Co." },
  { value: "Simran Hire Purchase, Allapalli", label: "Simran Hire Purchase, Allapalli" },
  { value: "Simran Transport Finance Ltd.", label: "Simran Transport Finance Ltd." },
  { value: "Sinajivi Finance", label: "Sinajivi Finance" },
  { value: "Sincere Syndicate", label: "Sincere Syndicate" },
  { value: "Sindgi Urban Co-Op Bank Ltd", label: "Sindgi Urban Co-Op Bank Ltd" },
  { value: "Sindhanur Urban Co-Op Bank Ltd", label: "Sindhanur Urban Co-Op Bank Ltd" },
  { value: "Sindhanur Urban Co-Op. Bank Ltd", label: "Sindhanur Urban Co-Op. Bank Ltd" },
  { value: "Sindhanur Urban Souhardha Co-Op Bank Ltd", label: "Sindhanur Urban Souhardha Co-Op Bank Ltd" },
  { value: "Sindhi Bank Ltd", label: "Sindhi Bank Ltd" },
  { value: "Sindhu  K .T", label: "Sindhu  K .T" },
  { value: "Sindhu Finance", label: "Sindhu Finance" },
  { value: "Sindhu Kokan Vikas Sahakari Pathpedi Maryadit", label: "Sindhu Kokan Vikas Sahakari Pathpedi Maryadit" },
  { value: "Sindhu Nagari Sahakari Patsanstha", label: "Sindhu Nagari Sahakari Patsanstha" },
  { value: "Sindhu Trade Links Limited", label: "Sindhu Trade Links Limited" },
  { value: "Sindhudurg Co-Operative Credit Society Ltd", label: "Sindhudurg Co-Operative Credit Society Ltd" },
  { value: "Sindhudurg Dist. Central Co-Op. Bank Ltd", label: "Sindhudurg Dist. Central Co-Op. Bank Ltd" },
  { value: "Sindhudurg District Central Co-Operative Bank Ltd.", label: "Sindhudurg District Central Co-Operative Bank Ltd." },
  { value: "Sindhudurg Sahakari Bank Ltd.", label: "Sindhudurg Sahakari Bank Ltd." },
  { value: "Sindhudurg Zilla Vaisha Samaj Sah Pat.Sansthan", label: "Sindhudurg Zilla Vaisha Samaj Sah Pat.Sansthan" },
  { value: "Sindhura Auto Finance", label: "Sindhura Auto Finance" },
  { value: "Singh Auto Deals", label: "Singh Auto Deals" },
  { value: "Singh Finance (Pvt)Ltd.", label: "Singh Finance (Pvt)Ltd." },
  { value: "Singh Financers", label: "Singh Financers" },
  { value: "Singh Man Finet Pvt Ltd", label: "Singh Man Finet Pvt Ltd" },
  { value: "Singh Motor Hire Purchase (P) Ltd", label: "Singh Motor Hire Purchase (P) Ltd" },
  { value: "Singh Motors", label: "Singh Motors" },
  { value: "Singh Motors Finance Pvt Ltd", label: "Singh Motors Finance Pvt Ltd" },
  { value: "Singhal Associates", label: "Singhal Associates" },
  { value: "Singhal Trading Company", label: "Singhal Trading Company" },
  { value: "Singhania Fin Corporation", label: "Singhania Fin Corporation" },
  { value: "Singhbhum Kshetriya Gramin Bank", label: "Singhbhum Kshetriya Gramin Bank" },
  { value: "Singhbjum Shetriya Gramin Bank Ltd", label: "Singhbjum Shetriya Gramin Bank Ltd" },
  { value: "Singhman Finex Pvt. Ltd.", label: "Singhman Finex Pvt. Ltd." },
  { value: "Singhvi Credit Corporation", label: "Singhvi Credit Corporation" },
  { value: "Singhvi Credit Transport", label: "Singhvi Credit Transport" },
  { value: "Singhvi Finance", label: "Singhvi Finance" },
  { value: "Singhvi Finlease (India) Ltd", label: "Singhvi Finlease (India) Ltd" },
  { value: "Singhvi Investment", label: "Singhvi Investment" },
  { value: "Singhvi Motor Finance", label: "Singhvi Motor Finance" },
  { value: "Singhvi Motors Finance", label: "Singhvi Motors Finance" },
  { value: "Singhvi Syndicate", label: "Singhvi Syndicate" },
  { value: "Singla Finance Company", label: "Singla Finance Company" },
  { value: "Singur Farmers` Service Co-Op. Society L", label: "Singur Farmers` Service Co-Op. Society L" },
  { value: "Sinhagad Nagri Sahakari Patsanstha", label: "Sinhagad Nagri Sahakari Patsanstha" },
  { value: "Sinhagad Urban Coop Bank Ltd", label: "Sinhagad Urban Coop Bank Ltd" },
  { value: "Sinnamani Lecesing Co Ltd", label: "Sinnamani Lecesing Co Ltd" },
  { value: "Sinnar Sah Bank Ltd.", label: "Sinnar Sah Bank Ltd." },
  { value: "Sinor Nagarik Sahakari Bank Ltd", label: "Sinor Nagarik Sahakari Bank Ltd" },
  { value: "Sinthu Finance", label: "Sinthu Finance" },
  { value: "Sir M Vishveshwaraiah Sahakari Bank Niyamitha", label: "Sir M Vishveshwaraiah Sahakari Bank Niyamitha" },
  { value: "Sir M Vishweshwarayya Vividh Uddeshagala Souhard Sahakar", label: "Sir M Vishweshwarayya Vividh Uddeshagala Souhard Sahakar" },
  { value: "Sir M Visvesvaraya Co Operative Bank Ltd", label: "Sir M Visvesvaraya Co Operative Bank Ltd" },
  { value: "Sir M Visvesvaraya Co-Op. Bank Ltd", label: "Sir M Visvesvaraya Co-Op. Bank Ltd" },
  { value: "Sir M. Vishweshwaraiah Sahakar Bank Ltd.", label: "Sir M. Vishweshwaraiah Sahakar Bank Ltd." },
  { value: "Sir M. Vishweshwaraiah Sahakar Bank Niy.", label: "Sir M. Vishweshwaraiah Sahakar Bank Niy." },
  { value: "Sir Vidya Finance (R) - Udupi", label: "Sir Vidya Finance (R) - Udupi" },
  { value: "Sir. M. Vishweshwaraiah Sah B. Niyamitha", label: "Sir. M. Vishweshwaraiah Sah B. Niyamitha" },
  { value: "Sira Town Co Op Bank Ltd", label: "Sira Town Co Op Bank Ltd" },
  { value: "Sircilla Co-Op Urban Bank Ltd", label: "Sircilla Co-Op Urban Bank Ltd" },
  { value: "Siri Financiers", label: "Siri Financiers" },
  { value: "Sirigere Vyavasaya Seva Sahakara Bank (N)", label: "Sirigere Vyavasaya Seva Sahakara Bank (N)" },
  { value: "Sirkali Co-Op Urbanbank Ltd", label: "Sirkali Co-Op Urbanbank Ltd" },
  { value: "Sirm Visvesvaraya Co-Operative Bank Ltd", label: "Sirm Visvesvaraya Co-Operative Bank Ltd" },
  { value: "Sirohi District Central Co-Operative Bank Ltd.", label: "Sirohi District Central Co-Operative Bank Ltd." },
  { value: "Sirohi Home Products P Ltd", label: "Sirohi Home Products P Ltd" },
  { value: "Sirohi Sehkari Bhoomi Vikas Bank Ltd", label: "Sirohi Sehkari Bhoomi Vikas Bank Ltd" },
  { value: "Sirsa Deposits And Advances Pvt. Ltd.", label: "Sirsa Deposits And Advances Pvt. Ltd." },
  { value: "Sirsa District Central Co-Operative Bank Ltd.", label: "Sirsa District Central Co-Operative Bank Ltd." },
  { value: "Sirsi Urban Co-Op Bank Ltd", label: "Sirsi Urban Co-Op Bank Ltd" },
  { value: "Sirsi Urban Sahakari Bank", label: "Sirsi Urban Sahakari Bank" },
  { value: "Sirsi Urban Soudarda Sahakari Bank Ltd - Sirsi.", label: "Sirsi Urban Soudarda Sahakari Bank Ltd - Sirsi." },
  { value: "Sirsi Urban Souharda Sahakari Bank Ltd Dandeli", label: "Sirsi Urban Souharda Sahakari Bank Ltd Dandeli" },
  { value: "Sirumalar Auto Finance", label: "Sirumalar Auto Finance" },
  { value: "Sirumugai Annamalaiar Finance", label: "Sirumugai Annamalaiar Finance" },
  { value: "Sisco Bank And Central Pandam Mpcs Ltd", label: "Sisco Bank And Central Pandam Mpcs Ltd" },
  { value: "Sisodia Finance Syndicate", label: "Sisodia Finance Syndicate" },
  { value: "Sisodra Arak Vibhag Vividh Karyakari Sahkari Mandali Ltd", label: "Sisodra Arak Vibhag Vividh Karyakari Sahkari Mandali Ltd" },
  { value: "Sita Auto Finance", label: "Sita Auto Finance" },
  { value: "Sita Finance Company", label: "Sita Finance Company" },
  { value: "Sitamarhi Central Co-Op Bank,", label: "Sitamarhi Central Co-Op Bank," },
  { value: "Sitapur Financers Greek Ganj Sitapur", label: "Sitapur Financers Greek Ganj Sitapur" },
  { value: "Sithi Vinayaka Finance", label: "Sithi Vinayaka Finance" },
  { value: "Siva Auto Finance", label: "Siva Auto Finance" },
  { value: "Siva Auto Finance Ltd", label: "Siva Auto Finance Ltd" },
  { value: "Siva Durga Finance", label: "Siva Durga Finance" },
  { value: "Siva Gayathri Auto Finance", label: "Siva Gayathri Auto Finance" },
  { value: "Siva Jyothi Finance", label: "Siva Jyothi Finance" },
  { value: "Siva Priya Auto Investment", label: "Siva Priya Auto Investment" },
  { value: "Siva Sai Auto Finance", label: "Siva Sai Auto Finance" },
  { value: "Siva Sakthi Auto Investment", label: "Siva Sakthi Auto Investment" },
  { value: "Siva Sakthi Finance", label: "Siva Sakthi Finance" },
  { value: "Siva Sakthi Hire Purphase", label: "Siva Sakthi Hire Purphase" },
  { value: "Siva Sakthi Leasing & Co", label: "Siva Sakthi Leasing & Co" },
  { value: "Siva Sankara Auto Finance", label: "Siva Sankara Auto Finance" },
  { value: "Siva Sukraa Finance", label: "Siva Sukraa Finance" },
  { value: "Sivadas A.P", label: "Sivadas A.P" },
  { value: "Sivagami Finance And Investments Ltd.", label: "Sivagami Finance And Investments Ltd." },
  { value: "Sivagangai District Central Co-Operative Bank Ltd.", label: "Sivagangai District Central Co-Operative Bank Ltd." },
  { value: "Sivagiri Sakthi Sri Finance", label: "Sivagiri Sakthi Sri Finance" },
  { value: "Sivam Finance", label: "Sivam Finance" },
  { value: "Sivananda Finance", label: "Sivananda Finance" },
  { value: "Sivasakthi And Sivasakthi Finance", label: "Sivasakthi And Sivasakthi Finance" },
  { value: "Sivasakthi Hire Purchase", label: "Sivasakthi Hire Purchase" },
  { value: "Siwach Motors Financiers", label: "Siwach Motors Financiers" },
  { value: "Siwan Kshetriya Gramin Bank", label: "Siwan Kshetriya Gramin Bank" },
  { value: "Six Sense Financial Serives Pvt. Ltd.", label: "Six Sense Financial Serives Pvt. Ltd." },
  { value: "Six Senses Insurance Services", label: "Six Senses Insurance Services" },
  { value: "Siyaram Finance Co.", label: "Siyaram Finance Co." },
  { value: "Sjp Enterprises", label: "Sjp Enterprises" },
  { value: "Sk Auto Deals", label: "Sk Auto Deals" },
  { value: "Sk Credit Corporation", label: "Sk Credit Corporation" },
  { value: "Sk Dungarwal", label: "Sk Dungarwal" },
  { value: "Sk Fayaz Auto Consultancy", label: "Sk Fayaz Auto Consultancy" },
  { value: "Sk Finance", label: "Sk Finance" },
  { value: "Sk Fiyaz Auto Finance", label: "Sk Fiyaz Auto Finance" },
  { value: "Sk Thirumurugan Auto Service", label: "Sk Thirumurugan Auto Service" },
  { value: "Skand Auto And General Finance Ltd.", label: "Skand Auto And General Finance Ltd." },
  { value: "Skanthaguru Leasing", label: "Skanthaguru Leasing" },
  { value: "Skb Finance Corp", label: "Skb Finance Corp" },
  { value: "Skg Leasing And Finance", label: "Skg Leasing And Finance" },
  { value: "Skm Finance", label: "Skm Finance" },
  { value: "Sks Micro Finance Pvt Ltd", label: "Sks Micro Finance Pvt Ltd" },
  { value: "Sky Blue Finace", label: "Sky Blue Finace" },
  { value: "Sky Blue Finance", label: "Sky Blue Finance" },
  { value: "Sky Land Consultancy", label: "Sky Land Consultancy" },
  { value: "Skylark Deposits And Advances Pvt. Ltd", label: "Skylark Deposits And Advances Pvt. Ltd" },
  { value: "Skylark Leasing Ltd", label: "Skylark Leasing Ltd" },
  { value: "Skylark Motor And General Finance Pvt. Ltd", label: "Skylark Motor And General Finance Pvt. Ltd" },
  { value: "Skylink Travels Pvt. Ltd.", label: "Skylink Travels Pvt. Ltd." },
  { value: "Sl Bpssn Gangavathi", label: "Sl Bpssn Gangavathi" },
  { value: "Slk Marketing", label: "Slk Marketing" },
  { value: "Sln Finance", label: "Sln Finance" },
  { value: "Sls Auto Finance Ltd", label: "Sls Auto Finance Ltd" },
  { value: "Slv Associates", label: "Slv Associates" },
  { value: "Slv Auto Finance", label: "Slv Auto Finance" },
  { value: "Slvr Finance", label: "Slvr Finance" },
  { value: "Slvr Investment And Auto Finance", label: "Slvr Investment And Auto Finance" },
  { value: "Sm Investments", label: "Sm Investments" },
  { value: "Sm Motors", label: "Sm Motors" },
  { value: "Small Industries Development Bank Of India.", label: "Small Industries Development Bank Of India." },
  { value: "Small Industries Development Bank Of India", label: "Small Industries Development Bank Of India" },
  { value: "Small Industries Service Institute", label: "Small Industries Service Institute" },
  { value: "Smart Auto Finance", label: "Smart Auto Finance" },
  { value: "Smart Financing Corporation", label: "Smart Financing Corporation" },
  { value: "Smart Line Assurance", label: "Smart Line Assurance" },
  { value: "Smart Loanz Inc.", label: "Smart Loanz Inc." },
  { value: "Smc Office Emp.Co Op Cr.Soc Ltd", label: "Smc Office Emp.Co Op Cr.Soc Ltd" },
  { value: "Sme", label: "Sme" },
  { value: "Smg Bank", label: "Smg Bank" },
  { value: "Smit Chit X Finance Corporation", label: "Smit Chit X Finance Corporation" },
  { value: "Smita Finance Services Pvt Ltd", label: "Smita Finance Services Pvt Ltd" },
  { value: "Smitha Auto Finance", label: "Smitha Auto Finance" },
  { value: "Smitha Selby", label: "Smitha Selby" },
  { value: "Smk Finance Pvt. Ltd.", label: "Smk Finance Pvt. Ltd." },
  { value: "Smrati Nagrik Sahkari Bank Maryadit", label: "Smrati Nagrik Sahkari Bank Maryadit" },
  { value: "Smriti Nagarik Sahakari Bank Ltd", label: "Smriti Nagarik Sahakari Bank Ltd" },
  { value: "Smriti Nagrik Sahakari Bank Mydt.", label: "Smriti Nagrik Sahakari Bank Mydt." },
  { value: "Smt Chanda Devi Jesansaria Sds", label: "Smt Chanda Devi Jesansaria Sds" },
  { value: "Smt Kaushalya Devi", label: "Smt Kaushalya Devi" },
  { value: "Smt Mohini Amarlal", label: "Smt Mohini Amarlal" },
  { value: "Smt. Munni Devi Singhal", label: "Smt. Munni Devi Singhal" },
  { value: "Smt. Rekha Khabiya", label: "Smt. Rekha Khabiya" },
  { value: "Sneh Dugar", label: "Sneh Dugar" },
  { value: "Sneh Finance Co", label: "Sneh Finance Co" },
  { value: "Sneha Auto Finance", label: "Sneha Auto Finance" },
  { value: "Sneha Latha Nahar", label: "Sneha Latha Nahar" },
  { value: "Sneha Sangama Pattina Sahakara Sangha", label: "Sneha Sangama Pattina Sahakara Sangha" },
  { value: "Sneha Sangama Sowharda Credit Co Op Ltd", label: "Sneha Sangama Sowharda Credit Co Op Ltd" },
  { value: "Snehadeep Gramin Bigarsheti Sahkari Pat Sansthan", label: "Snehadeep Gramin Bigarsheti Sahkari Pat Sansthan" },
  { value: "Snehal Enterprises", label: "Snehal Enterprises" },
  { value: "Snehalata Finance Ltd.", label: "Snehalata Finance Ltd." },
  { value: "Snehi Co-Op Cr. Soc. Ltd.", label: "Snehi Co-Op Cr. Soc. Ltd." },
  { value: "Snehi Sahakari Patsanstha Ltd", label: "Snehi Sahakari Patsanstha Ltd" },
  { value: "Snelatha Nahar", label: "Snelatha Nahar" },
  { value: "Soban Bai", label: "Soban Bai" },
  { value: "Sobhagmal Khabiya And Sons", label: "Sobhagmal Khabiya And Sons" },
  { value: "Sobti Financers", label: "Sobti Financers" },
  { value: "Social Co-Op Bank Ltd.", label: "Social Co-Op Bank Ltd." },
  { value: "Social Leasing (India) Ltd.", label: "Social Leasing (India) Ltd." },
  { value: "Societe Generale", label: "Societe Generale" },
  { value: "Societe Generale", label: "Societe Generale" },
  { value: "Society For Applied Microwave Electronic Engineering And Research", label: "Society For Applied Microwave Electronic Engineering And Research" },
  { value: "Society Motors Ltd", label: "Society Motors Ltd" },
  { value: "Society Of Seva Missionaries", label: "Society Of Seva Missionaries" },
  { value: "Sodhi Motors.", label: "Sodhi Motors." },
  { value: "Sohan Bai", label: "Sohan Bai" },
  { value: "Sohan Raj Finance", label: "Sohan Raj Finance" },
  { value: "Sohanlal Jain", label: "Sohanlal Jain" },
  { value: "Sohanraj Chhajed", label: "Sohanraj Chhajed" },
  { value: "Sojjan Raj Singhi And Sons", label: "Sojjan Raj Singhi And Sons" },
  { value: "Solapur Dist District Central Co-Operative Bank Ltd.", label: "Solapur Dist District Central Co-Operative Bank Ltd." },
  { value: "Solapur Dist Industrial Co-Op Bank Ltd", label: "Solapur Dist Industrial Co-Op Bank Ltd" },
  { value: "Solapur District Central Co-Operative Bank Ltd.", label: "Solapur District Central Co-Operative Bank Ltd." },
  { value: "Solapur Janata Sahakari Bank Ltd.", label: "Solapur Janata Sahakari Bank Ltd." },
  { value: "Solapur Janta Bank Ltd", label: "Solapur Janta Bank Ltd" },
  { value: "Solapur Merchants Co-Op Bank Ltd", label: "Solapur Merchants Co-Op Bank Ltd" },
  { value: "Solapur Nagari Audyogik Sahakari Bank Niyt", label: "Solapur Nagari Audyogik Sahakari Bank Niyt" },
  { value: "Solapur Nagaudyogik Sahakari Bank Ltd", label: "Solapur Nagaudyogik Sahakari Bank Ltd" },
  { value: "Solapur Siddheshwar Sahakari Bank Ltd Solapur", label: "Solapur Siddheshwar Sahakari Bank Ltd Solapur" },
  { value: "Solapur Zilha Janta Nagri Sahakari Patsanstha Maryadit", label: "Solapur Zilha Janta Nagri Sahakari Patsanstha Maryadit" },
  { value: "Solasar Finance Corporation", label: "Solasar Finance Corporation" },
  { value: "Soleti Finance", label: "Soleti Finance" },
  { value: "Solleti  Finance", label: "Solleti  Finance" },
  { value: "Solution Point", label: "Solution Point" },
  { value: "Som Datt Finance Corporation Ltd", label: "Som Datt Finance Corporation Ltd" },
  { value: "Soma Vounsha Sasarjuna Kshatriya Co-Op Society", label: "Soma Vounsha Sasarjuna Kshatriya Co-Op Society" },
  { value: "Somana Sisodia", label: "Somana Sisodia" },
  { value: "Somanath Urban Co-Op Credit Society Ltd", label: "Somanath Urban Co-Op Credit Society Ltd" },
  { value: "Somershwar Bachat And Dhiran Shahakari Mandli", label: "Somershwar Bachat And Dhiran Shahakari Mandli" },
  { value: "Someshwar Bachatand Dhiren Shahalean Mandli", label: "Someshwar Bachatand Dhiren Shahalean Mandli" },
  { value: "Someshwar Credit Co-Op Society Ltd", label: "Someshwar Credit Co-Op Society Ltd" },
  { value: "Someshwar Gramin Bigar Sheti Patsauntha", label: "Someshwar Gramin Bigar Sheti Patsauntha" },
  { value: "Someshwara Hp & Finance", label: "Someshwara Hp & Finance" },
  { value: "Somnath Nag Sah Pat Sanshta Maryadit", label: "Somnath Nag Sah Pat Sanshta Maryadit" },
  { value: "Somwarpet Vyavasaya S.Sah.San.Niyamitha", label: "Somwarpet Vyavasaya S.Sah.San.Niyamitha" },
  { value: "Sona Finance Ltd.", label: "Sona Finance Ltd." },
  { value: "Sonal Jaiswal", label: "Sonal Jaiswal" },
  { value: "Sonal Motors", label: "Sonal Motors" },
  { value: "Sonali Bank Ltd.", label: "Sonali Bank Ltd." },
  { value: "Sonali Bank,", label: "Sonali Bank," },
  { value: "Sonalika Finance", label: "Sonalika Finance" },
  { value: "Sonbhadra Nagar Sahakari Bank Ltd.", label: "Sonbhadra Nagar Sahakari Bank Ltd." },
  { value: "Sonepat District Central Co-Operative Bank Ltd.", label: "Sonepat District Central Co-Operative Bank Ltd." },
  { value: "Soni Finance Corporation", label: "Soni Finance Corporation" },
  { value: "Soni Moni Credit Centre Pvt Ltd", label: "Soni Moni Credit Centre Pvt Ltd" },
  { value: "Sonmitra Sah.Bank Ltd", label: "Sonmitra Sah.Bank Ltd" },
  { value: "Sonmuck Chand Huf", label: "Sonmuck Chand Huf" },
  { value: "Sonpeth Nagari Sah. Bank Ltd.", label: "Sonpeth Nagari Sah. Bank Ltd." },
  { value: "Sonu Hire Purchase", label: "Sonu Hire Purchase" },
  { value: "Sony Auto Finance", label: "Sony Auto Finance" },
  { value: "Sony Corporation", label: "Sony Corporation" },
  { value: "Sony Hire Purchase", label: "Sony Hire Purchase" },
  { value: "Sonyaji Gandhi Nagari Sahakari Path Sanstha", label: "Sonyaji Gandhi Nagari Sahakari Path Sanstha" },
  { value: "Sopankaka Sahakari Bank Limited", label: "Sopankaka Sahakari Bank Limited" },
  { value: "Sopankaka Sahakari Bank Ltd,", label: "Sopankaka Sahakari Bank Ltd," },
  { value: "Soubhagya Mahila Souharda Sah Bank Nymt,", label: "Soubhagya Mahila Souharda Sah Bank Nymt," },
  { value: "Soukarya Co-Op Credit Ltd", label: "Soukarya Co-Op Credit Ltd" },
  { value: "Soukarya Malia Purapose Souharti Sahakari Ltd", label: "Soukarya Malia Purapose Souharti Sahakari Ltd" },
  { value: "Soukarya Multipurpose Souhard Sahakari Ltd.", label: "Soukarya Multipurpose Souhard Sahakari Ltd." },
  { value: "Soumya Transport Pvt Ltd", label: "Soumya Transport Pvt Ltd" },
  { value: "Sound Finance Pvt Ltd.", label: "Sound Finance Pvt Ltd." },
  { value: "Sourabh Enterprise", label: "Sourabh Enterprise" },
  { value: "Sourabh Finance", label: "Sourabh Finance" },
  { value: "Sourav Motor Finance", label: "Sourav Motor Finance" },
  { value: "South Canara Dist. Central Co-Op Bank L.", label: "South Canara Dist. Central Co-Op Bank L." },
  { value: "South Canara Finance And Investment (R)", label: "South Canara Finance And Investment (R)" },
  { value: "South Coorg Muslims Co Op Bank Ltd", label: "South Coorg Muslims Co Op Bank Ltd" },
  { value: "South Eastern Coalfields Limited", label: "South Eastern Coalfields Limited" },
  { value: "South Gujarat Local Area Bank Ltd.", label: "South Gujarat Local Area Bank Ltd." },
  { value: "South India Textile Research Assocaition", label: "South India Textile Research Assocaition" },
  { value: "South Indian Bank Ltd.", label: "South Indian Bank Ltd." },
  { value: "South Kanara Govt Officer Co-Op Bank Ltd", label: "South Kanara Govt Officer Co-Op Bank Ltd" },
  { value: "South Urban Co-Operative Bank", label: "South Urban Co-Operative Bank" },
  { value: "Southern Motor  Finance", label: "Southern Motor  Finance" },
  { value: "Southern Pesticides Corporation Ltd.", label: "Southern Pesticides Corporation Ltd." },
  { value: "Southern Star Co-Op", label: "Southern Star Co-Op" },
  { value: "Southern Star Co-Op (U) T/C Society Ltd", label: "Southern Star Co-Op (U) T/C Society Ltd" },
  { value: "Sozhanganallur Primary Agri Co Bank Ltd", label: "Sozhanganallur Primary Agri Co Bank Ltd" },
  { value: "Sp Finance", label: "Sp Finance" },
  { value: "Sp. Spl. 60, Mettamalai Prim Agrl Cp Bk", label: "Sp. Spl. 60, Mettamalai Prim Agrl Cp Bk" },
  { value: "Spa Ins. Services Ltd.", label: "Spa Ins. Services Ltd." },
  { value: "Spaan Advisory Services", label: "Spaan Advisory Services" },
  { value: "Spac Application Center", label: "Spac Application Center" },
  { value: "Spandana Spoorthy Financial Ltd", label: "Spandana Spoorthy Financial Ltd" },
  { value: "Spandana Spoorthy Innovative Financial Services Ltd", label: "Spandana Spoorthy Innovative Financial Services Ltd" },
  { value: "Spandhana Finance", label: "Spandhana Finance" },
  { value: "Sparkling Diamond Family Credit Bank", label: "Sparkling Diamond Family Credit Bank" },
  { value: "Spbs Investments", label: "Spbs Investments" },
  { value: "Special Economic Zones", label: "Special Economic Zones" },
  { value: "Speciment Signature Of Financer", label: "Speciment Signature Of Financer" },
  { value: "Spectacular Finance Ltd", label: "Spectacular Finance Ltd" },
  { value: "Speed Wheels Cars & Marketing", label: "Speed Wheels Cars & Marketing" },
  { value: "Speel Finance Ltd", label: "Speel Finance Ltd" },
  { value: "Spg & Associates", label: "Spg & Associates" },
  { value: "Spg Associate", label: "Spg Associate" },
  { value: "Sphurti Mahila Nagari Sahakari Patsanstha Maryadit", label: "Sphurti Mahila Nagari Sahakari Patsanstha Maryadit" },
  { value: "Spices Board Of India", label: "Spices Board Of India" },
  { value: "Spices Trading Corporation Ltd.", label: "Spices Trading Corporation Ltd." },
  { value: "Spk Bankers", label: "Spk Bankers" },
  { value: "Spk Radar  Finance", label: "Spk Radar  Finance" },
  { value: "Spk Rodar Two Wheeler Finance", label: "Spk Rodar Two Wheeler Finance" },
  { value: "Sponge Iron India Ltd.", label: "Sponge Iron India Ltd." },
  { value: "Sports Authority Of India", label: "Sports Authority Of India" },
  { value: "Spot Finance", label: "Spot Finance" },
  { value: "Sps Bank Ltd.", label: "Sps Bank Ltd." },
  { value: "Sr Amman Finance", label: "Sr Amman Finance" },
  { value: "Sr Velavan Finance", label: "Sr Velavan Finance" },
  { value: "Sr. Inf. Fin. Agent Ofwti Bank", label: "Sr. Inf. Fin. Agent Ofwti Bank" },
  { value: "Srachi Infrastructure Finance Ltd", label: "Srachi Infrastructure Finance Ltd" },
  { value: "Sravan Kumar Tungani", label: "Sravan Kumar Tungani" },
  { value: "Sravya Co-Operative Urban Bank Ltd.", label: "Sravya Co-Operative Urban Bank Ltd." },
  { value: "Srcv Finance Ltd", label: "Srcv Finance Ltd" },
  { value: "Sre Kaadai Eswarar Auto Finance", label: "Sre Kaadai Eswarar Auto Finance" },
  { value: "Sre Ram Finance Ltd", label: "Sre Ram Finance Ltd" },
  { value: "Sre Venus Enterprises", label: "Sre Venus Enterprises" },
  { value: "Sree  Radhika Sroff", label: "Sree  Radhika Sroff" },
  { value: "Sree Abhilash Finance", label: "Sree Abhilash Finance" },
  { value: "Sree Adarsha Finance", label: "Sree Adarsha Finance" },
  { value: "Sree Adinath Co-Op. Bank Ltd", label: "Sree Adinath Co-Op. Bank Ltd" },
  { value: "Sree Alagu Lakshmi Auto Finance", label: "Sree Alagu Lakshmi Auto Finance" },
  { value: "Sree Ambal Finance", label: "Sree Ambal Finance" },
  { value: "Sree Anantha Grameena Bank", label: "Sree Anantha Grameena Bank" },
  { value: "Sree Anjaneya Co- Op Bank Ltd", label: "Sree Anjaneya Co- Op Bank Ltd" },
  { value: "Sree Annapurna Auto Financiers", label: "Sree Annapurna Auto Financiers" },
  { value: "Sree Atchaya Finance", label: "Sree Atchaya Finance" },
  { value: "Sree Auto Mobile Finance", label: "Sree Auto Mobile Finance" },
  { value: "Sree Auto Services Bam", label: "Sree Auto Services Bam" },
  { value: "Sree Auto Services Ltd.", label: "Sree Auto Services Ltd." },
  { value: "Sree Ayyappa Auto Finance", label: "Sree Ayyappa Auto Finance" },
  { value: "Sree Balaji Financiers", label: "Sree Balaji Financiers" },
  { value: "Sree Balaji Investments", label: "Sree Balaji Investments" },
  { value: "Sree Banashankari Mahila Co-Op Bank Ltd", label: "Sree Banashankari Mahila Co-Op Bank Ltd" },
  { value: "Sree Basaveswara Finance Corporation", label: "Sree Basaveswara Finance Corporation" },
  { value: "Sree Bhyraveshwara Sahakari Bank Ltd", label: "Sree Bhyraveshwara Sahakari Bank Ltd" },
  { value: "Sree Bhyraveshwara Sahakari Bank Niyamit", label: "Sree Bhyraveshwara Sahakari Bank Niyamit" },
  { value: "Sree Budha Investments.", label: "Sree Budha Investments." },
  { value: "Sree Byraveshwara Co-Operative Bank", label: "Sree Byraveshwara Co-Operative Bank" },
  { value: "Sree Chakra Finance & Investment", label: "Sree Chakra Finance & Investment" },
  { value: "Sree Charan Co-Operative Bank Ltd.", label: "Sree Charan Co-Operative Bank Ltd." },
  { value: "Sree Charan Sahakari Bank Ltd", label: "Sree Charan Sahakari Bank Ltd" },
  { value: "Sree Charan Souharda Co-Op. Bank Ltd", label: "Sree Charan Souharda Co-Op. Bank Ltd" },
  { value: "Sree City Union Finance Limited", label: "Sree City Union Finance Limited" },
  { value: "Sree Co-Operative Urban Bank Ltd.", label: "Sree Co-Operative Urban Bank Ltd." },
  { value: "Sree Deepak Finance", label: "Sree Deepak Finance" },
  { value: "Sree Dhanam Investments", label: "Sree Dhanam Investments" },
  { value: "Sree Durga Automobile Financiers", label: "Sree Durga Automobile Financiers" },
  { value: "Sree Durgadevi Mahila Urban Co-Op Credit Society Ltd.", label: "Sree Durgadevi Mahila Urban Co-Op Credit Society Ltd." },
  { value: "Sree Equipment Finance Pvt Ltd", label: "Sree Equipment Finance Pvt Ltd" },
  { value: "Sree Finance", label: "Sree Finance" },
  { value: "Sree Gangabhavani Finance", label: "Sree Gangabhavani Finance" },
  { value: "Sree Harihareshwara Urban Co.Op Bank Ltd", label: "Sree Harihareshwara Urban Co.Op Bank Ltd" },
  { value: "Sree Harinatha Auto Finance", label: "Sree Harinatha Auto Finance" },
  { value: "Sree Hemanth Finance", label: "Sree Hemanth Finance" },
  { value: "Sree Infrastructure Fin Ltd Kolkatta", label: "Sree Infrastructure Fin Ltd Kolkatta" },
  { value: "Sree Infrastructure Finance Ltd", label: "Sree Infrastructure Finance Ltd" },
  { value: "Sree Kadaeshwarar Auto Finance", label: "Sree Kadaeshwarar Auto Finance" },
  { value: "Sree Kadi Nagarik Sahakari Bank Ltd.", label: "Sree Kadi Nagarik Sahakari Bank Ltd." },
  { value: "Sree Kamadhenu Finaance", label: "Sree Kamadhenu Finaance" },
  { value: "Sree Kannikaparameswari Co-Op Bank Ltd.", label: "Sree Kannikaparameswari Co-Op Bank Ltd." },
  { value: "Sree Kirthi Auto Finance", label: "Sree Kirthi Auto Finance" },
  { value: "Sree Krishna Finance", label: "Sree Krishna Finance" },
  { value: "Sree Lakshmi Auto Finance", label: "Sree Lakshmi Auto Finance" },
  { value: "Sree Lakshmi Finance", label: "Sree Lakshmi Finance" },
  { value: "Sree Lalitamba Auto And General Finance (P) Ltd.", label: "Sree Lalitamba Auto And General Finance (P) Ltd." },
  { value: "Sree Madheswara Finance Company", label: "Sree Madheswara Finance Company" },
  { value: "Sree Mahayogi Lakshmamma Co-Op Bank Ltd", label: "Sree Mahayogi Lakshmamma Co-Op Bank Ltd" },
  { value: "Sree Mahayogi Lakshmamma Co-Op Town Bk L", label: "Sree Mahayogi Lakshmamma Co-Op Town Bk L" },
  { value: "Sree Mahayogi Lakshmamma Co.Op. Bank Ltd", label: "Sree Mahayogi Lakshmamma Co.Op. Bank Ltd" },
  { value: "Sree Malleshwara Financiers", label: "Sree Malleshwara Financiers" },
  { value: "Sree Manikanta Finance Corporation", label: "Sree Manikanta Finance Corporation" },
  { value: "Sree Marudhar Finance", label: "Sree Marudhar Finance" },
  { value: "Sree Muneendra Financiers", label: "Sree Muneendra Financiers" },
  { value: "Sree Murugan Auto Finance", label: "Sree Murugan Auto Finance" },
  { value: "Sree Narayana Dharma Sabha", label: "Sree Narayana Dharma Sabha" },
  { value: "Sree Narayana Guru Co-Op Bank Ltd", label: "Sree Narayana Guru Co-Op Bank Ltd" },
  { value: "Sree Neelakanteshwara Auto Finance Ltd", label: "Sree Neelakanteshwara Auto Finance Ltd" },
  { value: "Sree Nidhi Souharda Sahakara", label: "Sree Nidhi Souharda Sahakara" },
  { value: "Sree Nithya Lakshmi Auto Investment", label: "Sree Nithya Lakshmi Auto Investment" },
  { value: "Sree Ponmani Enterprises", label: "Sree Ponmani Enterprises" },
  { value: "Sree Raghavendra Auto Finance", label: "Sree Raghavendra Auto Finance" },
  { value: "Sree Rajalinga Moorthy Finance", label: "Sree Rajalinga Moorthy Finance" },
  { value: "Sree Ram City Union Bank Finance", label: "Sree Ram City Union Bank Finance" },
  { value: "Sree Ram Finance", label: "Sree Ram Finance" },
  { value: "Sree Rampalayam", label: "Sree Rampalayam" },
  { value: "Sree Renuka Devi Financiers", label: "Sree Renuka Devi Financiers" },
  { value: "Sree Sakthi Abirami Finance", label: "Sree Sakthi Abirami Finance" },
  { value: "Sree Sakthi Auto Finance", label: "Sree Sakthi Auto Finance" },
  { value: "Sree Saravana Financiers", label: "Sree Saravana Financiers" },
  { value: "Sree Seetharama Mandiram Co-Op. So. Ltd.", label: "Sree Seetharama Mandiram Co-Op. So. Ltd." },
  { value: "Sree Selvakaumaran Finance", label: "Sree Selvakaumaran Finance" },
  { value: "Sree Senthil Murthy Finance", label: "Sree Senthil Murthy Finance" },
  { value: "Sree Shankari Pathina Sahakara Sanghat", label: "Sree Shankari Pathina Sahakara Sanghat" },
  { value: "Sree Shanthi And Co Sowcorpet Chennai", label: "Sree Shanthi And Co Sowcorpet Chennai" },
  { value: "Sree Siddarameshwara Sahakara Bank", label: "Sree Siddarameshwara Sahakara Bank" },
  { value: "Sree Siva Baalan Motor Finance", label: "Sree Siva Baalan Motor Finance" },
  { value: "Sree Sivalalitha Auto Financier", label: "Sree Sivalalitha Auto Financier" },
  { value: "Sree Skand Auto General Finance", label: "Sree Skand Auto General Finance" },
  { value: "Sree Skanda Auto & General Finance Ltd", label: "Sree Skanda Auto & General Finance Ltd" },
  { value: "Sree Subramanyeshwar Co-Op Bank Ltd", label: "Sree Subramanyeshwar Co-Op Bank Ltd" },
  { value: "Sree Subramanyeswara Co-Op. Bank Ltd.", label: "Sree Subramanyeswara Co-Op. Bank Ltd." },
  { value: "Sree Thendral Finance", label: "Sree Thendral Finance" },
  { value: "Sree Thyagaraja Co. Op. Bank Ltd", label: "Sree Thyagaraja Co. Op. Bank Ltd" },
  { value: "Sree Tyagraja Co-Op Bank Ltd", label: "Sree Tyagraja Co-Op Bank Ltd" },
  { value: "Sree Vara Lakshmi Auto Finance", label: "Sree Vara Lakshmi Auto Finance" },
  { value: "Sree Vara Laxmi Auto Finance Corporation", label: "Sree Vara Laxmi Auto Finance Corporation" },
  { value: "Sree Varalakshmi Auto Finance", label: "Sree Varalakshmi Auto Finance" },
  { value: "Sree Varalakshmi Auto Finance Corporation", label: "Sree Varalakshmi Auto Finance Corporation" },
  { value: "Sree Vengamamba Finance", label: "Sree Vengamamba Finance" },
  { value: "Sree Venkataraya Investments And Finance Company (P) Ltd.", label: "Sree Venkataraya Investments And Finance Company (P) Ltd." },
  { value: "Sreedhar  Mothukur", label: "Sreedhar  Mothukur" },
  { value: "Sreedhar Reddy", label: "Sreedhar Reddy" },
  { value: "Sreeja St Ltd", label: "Sreeja St Ltd" },
  { value: "Sreekala Auto Financiers", label: "Sreekala Auto Financiers" },
  { value: "Sreekandamangalam Service Co-Op Bank Ltd", label: "Sreekandamangalam Service Co-Op Bank Ltd" },
  { value: "Sreenidhi Sahakari Bank Ltd", label: "Sreenidhi Sahakari Bank Ltd" },
  { value: "Sreenidhi Sahakari Bank Niyamitha", label: "Sreenidhi Sahakari Bank Niyamitha" },
  { value: "Sreenidhi Sahakari Bank Niyamitha", label: "Sreenidhi Sahakari Bank Niyamitha" },
  { value: "Sreenidhi Souharda Sahakari Niyamitha Bank Ltd", label: "Sreenidhi Souharda Sahakari Niyamitha Bank Ltd" },
  { value: "Sreenivasa Finance", label: "Sreenivasa Finance" },
  { value: "Sreenivasa Finance Corporation", label: "Sreenivasa Finance Corporation" },
  { value: "Sreeragh General Finance Ltd", label: "Sreeragh General Finance Ltd" },
  { value: "Sreeram City Union Bank", label: "Sreeram City Union Bank" },
  { value: "Sreeram City Union Bank Finance", label: "Sreeram City Union Bank Finance" },
  { value: "Sreeram City Union Finance", label: "Sreeram City Union Finance" },
  { value: "Sreeram City Union Finance Ltd", label: "Sreeram City Union Finance Ltd" },
  { value: "Sreeram Finance Ltd", label: "Sreeram Finance Ltd" },
  { value: "Sreeram General Finance Ltd", label: "Sreeram General Finance Ltd" },
  { value: "Sreeram Investments Ltd", label: "Sreeram Investments Ltd" },
  { value: "Sreeram Transport Finance Co Ltd", label: "Sreeram Transport Finance Co Ltd" },
  { value: "Sreesanthi&Co. Sawcarpet", label: "Sreesanthi&Co. Sawcarpet" },
  { value: "Srei", label: "Srei" },
  { value: "Srel Infrastructure Finance Ltd", label: "Srel Infrastructure Finance Ltd" },
  { value: "Sreshtha Motors", label: "Sreshtha Motors" },
  { value: "Sreyas Industrial Trans Fin", label: "Sreyas Industrial Trans Fin" },
  { value: "Sreyinfra Finance Ltd", label: "Sreyinfra Finance Ltd" },
  { value: "Srhee Finance", label: "Srhee Finance" },
  { value: "Sri Abarna Auto Finance", label: "Sri Abarna Auto Finance" },
  { value: "Sri Abi Auto Financier", label: "Sri Abi Auto Financier" },
  { value: "Sri Abirami Auto Financiers", label: "Sri Abirami Auto Financiers" },
  { value: "Sri Abirami Investments", label: "Sri Abirami Investments" },
  { value: "Sri Achuthan Auto Finance", label: "Sri Achuthan Auto Finance" },
  { value: "Sri Adiya Finance", label: "Sri Adiya Finance" },
  { value: "Sri Alangar Finance", label: "Sri Alangar Finance" },
  { value: "Sri Amar Finance Corporation", label: "Sri Amar Finance Corporation" },
  { value: "Sri Amarjothi Finance", label: "Sri Amarjothi Finance" },
  { value: "Sri Ambabhavani Urban Co-Op Bank Ltd,", label: "Sri Ambabhavani Urban Co-Op Bank Ltd," },
  { value: "Sri Ambica Finance", label: "Sri Ambica Finance" },
  { value: "Sri Amman  Investments", label: "Sri Amman  Investments" },
  { value: "Sri Amman Finance", label: "Sri Amman Finance" },
  { value: "Sri Amrutha Sai A/F", label: "Sri Amrutha Sai A/F" },
  { value: "Sri Amrutha Sai Enterprises", label: "Sri Amrutha Sai Enterprises" },
  { value: "Sri Amutham  Motor Finance", label: "Sri Amutham  Motor Finance" },
  { value: "Sri Amutham Auto Finance", label: "Sri Amutham Auto Finance" },
  { value: "Sri Anand Auto Finance", label: "Sri Anand Auto Finance" },
  { value: "Sri Anbu Oli Auto Finance", label: "Sri Anbu Oli Auto Finance" },
  { value: "Sri Andavapperumal Auto Finance", label: "Sri Andavapperumal Auto Finance" },
  { value: "Sri Andavar Enterprises", label: "Sri Andavar Enterprises" },
  { value: "Sri Anjanadri Credit Co-Op Society Ltd.", label: "Sri Anjanadri Credit Co-Op Society Ltd." },
  { value: "Sri Anjanadri Enterprises", label: "Sri Anjanadri Enterprises" },
  { value: "Sri Anjaneya Auto Finance", label: "Sri Anjaneya Auto Finance" },
  { value: "Sri Anjaneya Co-Op Bank Ltd", label: "Sri Anjaneya Co-Op Bank Ltd" },
  { value: "Sri Anjaneya Vividoddesha Souharda Sahakar Niyamith", label: "Sri Anjaneya Vividoddesha Souharda Sahakar Niyamith" },
  { value: "Sri Annamalaiyar Auto Finance", label: "Sri Annamalaiyar Auto Finance" },
  { value: "Sri Annapurani Auto Finance", label: "Sri Annapurani Auto Finance" },
  { value: "Sri Anugraha Auto Financiers", label: "Sri Anugraha Auto Financiers" },
  { value: "Sri Anusha A/F", label: "Sri Anusha A/F" },
  { value: "Sri Aparana Auto Finance", label: "Sri Aparana Auto Finance" },
  { value: "Sri Arihant Co-Operative Bank Ltd.", label: "Sri Arihant Co-Operative Bank Ltd." },
  { value: "Sri Arul Murugan Investment", label: "Sri Arul Murugan Investment" },
  { value: "Sri Arulganapathi Auto Finance", label: "Sri Arulganapathi Auto Finance" },
  { value: "Sri Aruljothi Murugan Finance", label: "Sri Aruljothi Murugan Finance" },
  { value: "Sri Arunachala Auto Finance", label: "Sri Arunachala Auto Finance" },
  { value: "Sri Arunachalaa Consults", label: "Sri Arunachalaa Consults" },
  { value: "Sri Arunai Bankers", label: "Sri Arunai Bankers" },
  { value: "Sri Arupadaiyappaa Auto Investments", label: "Sri Arupadaiyappaa Auto Investments" },
  { value: "Sri Asta Lakshmi Auto Finance", label: "Sri Asta Lakshmi Auto Finance" },
  { value: "Sri Athisakthi Credits", label: "Sri Athisakthi Credits" },
  { value: "Sri Ayyappa Finance", label: "Sri Ayyappa Finance" },
  { value: "Sri Ayyappa Finance", label: "Sri Ayyappa Finance" },
  { value: "Sri Ayyappa Motors", label: "Sri Ayyappa Motors" },
  { value: "Sri Ayyappa Swamy Finance", label: "Sri Ayyappa Swamy Finance" },
  { value: "Sri Baba Auto Finance", label: "Sri Baba Auto Finance" },
  { value: "Sri Bagavathi Auto Finance", label: "Sri Bagavathi Auto Finance" },
  { value: "Sri Bakkiyalakshmi Auto Finance", label: "Sri Bakkiyalakshmi Auto Finance" },
  { value: "Sri Bala Sai Auto Finance", label: "Sri Bala Sai Auto Finance" },
  { value: "Sri Balaji A/F", label: "Sri Balaji A/F" },
  { value: "Sri Balaji Auto Consulting", label: "Sri Balaji Auto Consulting" },
  { value: "Sri Balaji Auto Finance, Badvel", label: "Sri Balaji Auto Finance, Badvel" },
  { value: "Sri Balaji Auto Financiers", label: "Sri Balaji Auto Financiers" },
  { value: "Sri Balaji Financiers", label: "Sri Balaji Financiers" },
  { value: "Sri Balaji Motors Credits", label: "Sri Balaji Motors Credits" },
  { value: "Sri Balaji Souharda Pattin Sahakari Niyamit", label: "Sri Balaji Souharda Pattin Sahakari Niyamit" },
  { value: "Sri Balaji Urban Co-Operative Bank Ltd", label: "Sri Balaji Urban Co-Operative Bank Ltd" },
  { value: "Sri Balamurugan Finance", label: "Sri Balamurugan Finance" },
  { value: "Sri Banashankari Credit Co-Op. Soc Niyam", label: "Sri Banashankari Credit Co-Op. Soc Niyam" },
  { value: "Sri Bannari Amman Finance", label: "Sri Bannari Amman Finance" },
  { value: "Sri Basavasri Emp. Welfare Co-Op Soc Ltd", label: "Sri Basavasri Emp. Welfare Co-Op Soc Ltd" },
  { value: "Sri Basaveshwar Co-Op Bank Ltd", label: "Sri Basaveshwar Co-Op Bank Ltd" },
  { value: "Sri Basaveshwar Pattin Sahakari Sangha Niyamita", label: "Sri Basaveshwar Pattin Sahakari Sangha Niyamita" },
  { value: "Sri Basaveshwar Sahakari Bank Niyamitha Banahatti", label: "Sri Basaveshwar Sahakari Bank Niyamitha Banahatti" },
  { value: "Sri Basaveshwar Souhardha Credit Society Ltd", label: "Sri Basaveshwar Souhardha Credit Society Ltd" },
  { value: "Sri Basaveshwar Urban Co-Operative Credit Society Ltd.", label: "Sri Basaveshwar Urban Co-Operative Credit Society Ltd." },
  { value: "Sri Basaveshwara Cre.Co-Op. Soc.Ltd.", label: "Sri Basaveshwara Cre.Co-Op. Soc.Ltd." },
  { value: "Sri Basaveshwara Credit Co-Op Society Ltd", label: "Sri Basaveshwara Credit Co-Op Society Ltd" },
  { value: "Sri Basaveshwara Pattina Souharda Sahakara Bank", label: "Sri Basaveshwara Pattina Souharda Sahakara Bank" },
  { value: "Sri Basaveshwara Vividoddesha Sah Sagha", label: "Sri Basaveshwara Vividoddesha Sah Sagha" },
  { value: "Sri Beereshwar Souharda Credit Sahakari Ltd", label: "Sri Beereshwar Souharda Credit Sahakari Ltd" },
  { value: "Sri Bhagavti Co-Op Bank Ltd., Mangalore", label: "Sri Bhagavti Co-Op Bank Ltd., Mangalore" },
  { value: "Sri Bhagvathi Co- Op Bank Ltd", label: "Sri Bhagvathi Co- Op Bank Ltd" },
  { value: "Sri Bhagya Lakshmi Auto Finance", label: "Sri Bhagya Lakshmi Auto Finance" },
  { value: "Sri Bharath Finance", label: "Sri Bharath Finance" },
  { value: "Sri Bharathi Co-Op. Urban Bank Ltd.", label: "Sri Bharathi Co-Op. Urban Bank Ltd." },
  { value: "Sri Bhavan Finance", label: "Sri Bhavan Finance" },
  { value: "Sri Bhaveshwari  Gramin Bigar Sheti Path Sanstha", label: "Sri Bhaveshwari  Gramin Bigar Sheti Path Sanstha" },
  { value: "Sri Bhuvanam Finance", label: "Sri Bhuvanam Finance" },
  { value: "Sri Bhuvaneshwari P Souhardha Sah. Niy.", label: "Sri Bhuvaneshwari P Souhardha Sah. Niy." },
  { value: "Sri Birdev Finance Pvt Ltd Gulbarga", label: "Sri Birdev Finance Pvt Ltd Gulbarga" },
  { value: "Sri Bramaramba Finance", label: "Sri Bramaramba Finance" },
  { value: "Sri Bramaramba Pattina Sourdha Sahakari Niyamita", label: "Sri Bramaramba Pattina Sourdha Sahakari Niyamita" },
  { value: "Sri Bramaramba Pattini Sahakari Sanga Niyamitha Bank", label: "Sri Bramaramba Pattini Sahakari Sanga Niyamitha Bank" },
  { value: "Sri Chaitanya A/F", label: "Sri Chaitanya A/F" },
  { value: "Sri Chakhara Finance", label: "Sri Chakhara Finance" },
  { value: "Sri Chakra Auto Finance", label: "Sri Chakra Auto Finance" },
  { value: "Sri Chakra Auto Leasing & Finance", label: "Sri Chakra Auto Leasing & Finance" },
  { value: "Sri Chakra Hire Purchase Finance", label: "Sri Chakra Hire Purchase Finance" },
  { value: "Sri Chandrika Auto Finance", label: "Sri Chandrika Auto Finance" },
  { value: "Sri Channabasava Patina Souhardha Sahakari Niyamitha", label: "Sri Channabasava Patina Souhardha Sahakari Niyamitha" },
  { value: "Sri Channabasava Pattina Souhardha Sahakari Niyamitha", label: "Sri Channabasava Pattina Souhardha Sahakari Niyamitha" },
  { value: "Sri Channabasavaswamy S.P.Sahakari B.Ni.", label: "Sri Channabasavaswamy S.P.Sahakari B.Ni." },
  { value: "Sri Channabasavaswamy Urban C.P. Bk Ltd", label: "Sri Channabasavaswamy Urban C.P. Bk Ltd" },
  { value: "Sri Charan Auto Financiers", label: "Sri Charan Auto Financiers" },
  { value: "Sri Chenna Kesava Swamy Finance", label: "Sri Chenna Kesava Swamy Finance" },
  { value: "Sri Chinnamalai Finance", label: "Sri Chinnamalai Finance" },
  { value: "Sri Chitti Finance", label: "Sri Chitti Finance" },
  { value: "Sri Chola Financiers", label: "Sri Chola Financiers" },
  { value: "Sri Choudeshwari Jogatveer K.N.Sa.P.Pedhi", label: "Sri Choudeshwari Jogatveer K.N.Sa.P.Pedhi" },
  { value: "Sri D.K.K Finance", label: "Sri D.K.K Finance" },
  { value: "Sri Dashmesh Finance Co", label: "Sri Dashmesh Finance Co" },
  { value: "Sri Dasmesh Finance Co.", label: "Sri Dasmesh Finance Co." },
  { value: "Sri Datha Sai Auto Finance", label: "Sri Datha Sai Auto Finance" },
  { value: "Sri Deepam Auto Finance", label: "Sri Deepam Auto Finance" },
  { value: "Sri Devi Auto Finance", label: "Sri Devi Auto Finance" },
  { value: "Sri Devi Auto Finance Co Operation", label: "Sri Devi Auto Finance Co Operation" },
  { value: "Sri Devi Finance", label: "Sri Devi Finance" },
  { value: "Sri Devi Finance Investment", label: "Sri Devi Finance Investment" },
  { value: "Sri Dhana Laxmi Hp Af", label: "Sri Dhana Laxmi Hp Af" },
  { value: "Sri Dhana Laxmi Hp Finance & Chits", label: "Sri Dhana Laxmi Hp Finance & Chits" },
  { value: "Sri Dhanalakshmi Vividhoddhesh Souvardha Sahakari Ni", label: "Sri Dhanalakshmi Vividhoddhesh Souvardha Sahakari Ni" },
  { value: "Sri Dhanalaxami Pahinna Sahakari Sangha Niyamit", label: "Sri Dhanalaxami Pahinna Sahakari Sangha Niyamit" },
  { value: "Sri Dhanalaxmi Auto Financiers", label: "Sri Dhanalaxmi Auto Financiers" },
  { value: "Sri Dhanalaxmi Pattan Sahakari Sangha Niyamit", label: "Sri Dhanalaxmi Pattan Sahakari Sangha Niyamit" },
  { value: "Sri Dhanya Finance", label: "Sri Dhanya Finance" },
  { value: "Sri Dhanya Lakshmi Finance", label: "Sri Dhanya Lakshmi Finance" },
  { value: "Sri Dharam Finance", label: "Sri Dharam Finance" },
  { value: "Sri Dharmasthalal Service Coop Bk L,", label: "Sri Dharmasthalal Service Coop Bk L," },
  { value: "Sri Dharni Finance", label: "Sri Dharni Finance" },
  { value: "Sri Dkk Finance", label: "Sri Dkk Finance" },
  { value: "Sri Durga Auto Finance", label: "Sri Durga Auto Finance" },
  { value: "Sri Durga Finance", label: "Sri Durga Finance" },
  { value: "Sri Durga Ganapathy Auto Finance", label: "Sri Durga Ganapathy Auto Finance" },
  { value: "Sri Durga Motors", label: "Sri Durga Motors" },
  { value: "Sri Durgamatha Co-Operative Credit Society Ltd", label: "Sri Durgamatha Co-Operative Credit Society Ltd" },
  { value: "Sri Dutta Sai Finance & Leasing", label: "Sri Dutta Sai Finance & Leasing" },
  { value: "Sri Eaalumalai Finance", label: "Sri Eaalumalai Finance" },
  { value: "Sri Ettukaiamman Investments", label: "Sri Ettukaiamman Investments" },
  { value: "Sri Ezumalayan Finance", label: "Sri Ezumalayan Finance" },
  { value: "Sri Finance", label: "Sri Finance" },
  { value: "Sri G.S.P.S. Ni", label: "Sri G.S.P.S. Ni" },
  { value: "Sri Gajanana Sauhardha Credit Co-Operative Bank", label: "Sri Gajanana Sauhardha Credit Co-Operative Bank" },
  { value: "Sri Ganapathi Finance", label: "Sri Ganapathi Finance" },
  { value: "Sri Ganapathy Finance", label: "Sri Ganapathy Finance" },
  { value: "Sri Ganesh Anand Leasing And Finance Ltd.", label: "Sri Ganesh Anand Leasing And Finance Ltd." },
  { value: "Sri Ganesh Auto Finance", label: "Sri Ganesh Auto Finance" },
  { value: "Sri Ganesh Co-Operative Bank Ltd", label: "Sri Ganesh Co-Operative Bank Ltd" },
  { value: "Sri Ganesh Co-Operative Bank Ltd.", label: "Sri Ganesh Co-Operative Bank Ltd." },
  { value: "Sri Ganesh Enterprises", label: "Sri Ganesh Enterprises" },
  { value: "Sri Ganesh Finance", label: "Sri Ganesh Finance" },
  { value: "Sri Ganesh Finance Corp0Ration", label: "Sri Ganesh Finance Corp0Ration" },
  { value: "Sri Ganesh Investment", label: "Sri Ganesh Investment" },
  { value: "Sri Ganesha Auto Finance", label: "Sri Ganesha Auto Finance" },
  { value: "Sri Ganga Bhavani Finance", label: "Sri Ganga Bhavani Finance" },
  { value: "Sri Ganganagar Kshetriya Gramin Bank", label: "Sri Ganganagar Kshetriya Gramin Bank" },
  { value: "Sri Gavisiddheshwar Urban Co-Op.Bank.Lt.", label: "Sri Gavisiddheshwar Urban Co-Op.Bank.Lt." },
  { value: "Sri Gayathri Auto Finance", label: "Sri Gayathri Auto Finance" },
  { value: "Sri Gayathri Auto Finance Corporation", label: "Sri Gayathri Auto Finance Corporation" },
  { value: "Sri Gayathri Chits & Finance", label: "Sri Gayathri Chits & Finance" },
  { value: "Sri Gayathri Credit Co-Operative Society Ltd.", label: "Sri Gayathri Credit Co-Operative Society Ltd." },
  { value: "Sri Gayatri A/F", label: "Sri Gayatri A/F" },
  { value: "Sri Gayatri Urban Co-Operative Society", label: "Sri Gayatri Urban Co-Operative Society" },
  { value: "Sri Giri Leasing (R)", label: "Sri Giri Leasing (R)" },
  { value: "Sri Giridharan Finance", label: "Sri Giridharan Finance" },
  { value: "Sri Gokarnanath Co-Op Bank Ltd", label: "Sri Gokarnanath Co-Op Bank Ltd" },
  { value: "Sri Gokarnanath Co-Op Bank Ltd - Karkala", label: "Sri Gokarnanath Co-Op Bank Ltd - Karkala" },
  { value: "Sri Gokarnanath Co-Op Bank Ltd - Kundapura", label: "Sri Gokarnanath Co-Op Bank Ltd - Kundapura" },
  { value: "Sri Gokarnanath Co-Op Bank Ltd., Mangalore", label: "Sri Gokarnanath Co-Op Bank Ltd., Mangalore" },
  { value: "Sri Gokul Auto And General Finance", label: "Sri Gokul Auto And General Finance" },
  { value: "Sri Gokul Motors", label: "Sri Gokul Motors" },
  { value: "Sri Gokulam Auto Finance", label: "Sri Gokulam Auto Finance" },
  { value: "Sri Gopikrishna Finance", label: "Sri Gopikrishna Finance" },
  { value: "Sri Gowri Chits & Finance", label: "Sri Gowri Chits & Finance" },
  { value: "Sri Guru Auto Finance", label: "Sri Guru Auto Finance" },
  { value: "Sri Guru Auto Finance", label: "Sri Guru Auto Finance" },
  { value: "Sri Guru Opp. Path. Sah. San. San. Niya.", label: "Sri Guru Opp. Path. Sah. San. San. Niya." },
  { value: "Sri Guru Oppathina Swamy Pathina Sahakara Sangha Niyamitha", label: "Sri Guru Oppathina Swamy Pathina Sahakara Sangha Niyamitha" },
  { value: "Sri Guru Oppathinaswamy P.S.S. Niyamitha", label: "Sri Guru Oppathinaswamy P.S.S. Niyamitha" },
  { value: "Sri Guru Raghavendra Auto Finance", label: "Sri Guru Raghavendra Auto Finance" },
  { value: "Sri Guru Raghavendra Auto Finance", label: "Sri Guru Raghavendra Auto Finance" },
  { value: "Sri Guru Raghavendra Sahakara Bank N.", label: "Sri Guru Raghavendra Sahakara Bank N." },
  { value: "Sri Guru Vap Swa Pathina Sah Sangha Niya", label: "Sri Guru Vap Swa Pathina Sah Sangha Niya" },
  { value: "Sri Gurudev Finance", label: "Sri Gurudev Finance" },
  { value: "Sri Gurukula Sahakara Sangha", label: "Sri Gurukula Sahakara Sangha" },
  { value: "Sri Hari Finance", label: "Sri Hari Finance" },
  { value: "Sri Haridev Finance Corporation", label: "Sri Haridev Finance Corporation" },
  { value: "Sri Himja Auto Financiers", label: "Sri Himja Auto Financiers" },
  { value: "Sri Infrastructure Fin.Ltd.", label: "Sri Infrastructure Fin.Ltd." },
  { value: "Sri Ingle Automobile Finance", label: "Sri Ingle Automobile Finance" },
  { value: "Sri Jadaguru Panch Charya Co Op Cr. Society Ltd", label: "Sri Jadaguru Panch Charya Co Op Cr. Society Ltd" },
  { value: "Sri Jagadamba Enterprises", label: "Sri Jagadamba Enterprises" },
  { value: "Sri Jagadguru Panchacharyaha Credit Co-Operative Society Ltd.", label: "Sri Jagadguru Panchacharyaha Credit Co-Operative Society Ltd." },
  { value: "Sri Jai Suriya Auto Finance", label: "Sri Jai Suriya Auto Finance" },
  { value: "Sri Jai Venktaeshwara Finance", label: "Sri Jai Venktaeshwara Finance" },
  { value: "Sri Jaya Shankar Finance", label: "Sri Jaya Shankar Finance" },
  { value: "Sri Jayam Auto Investment", label: "Sri Jayam Auto Investment" },
  { value: "Sri Jenukal Souhardha Sahakara Niyamitha", label: "Sri Jenukal Souhardha Sahakara Niyamitha" },
  { value: "Sri Jeyam Motor Finance", label: "Sri Jeyam Motor Finance" },
  { value: "Sri Jihveshwara Credit Co-Op. Soc. Ltd.", label: "Sri Jihveshwara Credit Co-Op. Soc. Ltd." },
  { value: "Sri Jothi Auto Finance", label: "Sri Jothi Auto Finance" },
  { value: "Sri Jothi Murugan Credit Corp", label: "Sri Jothi Murugan Credit Corp" },
  { value: "Sri Jothi Murugan Investment", label: "Sri Jothi Murugan Investment" },
  { value: "Sri Jyothi Auto Finance - Trichy.", label: "Sri Jyothi Auto Finance - Trichy." },
  { value: "Sri Kalai Auto Finance", label: "Sri Kalai Auto Finance" },
  { value: "Sri Kalaimagal Corporation", label: "Sri Kalaimagal Corporation" },
  { value: "Sri Kalaimagal Finance", label: "Sri Kalaimagal Finance" },
  { value: "Sri Kalki A/F Tenali", label: "Sri Kalki A/F Tenali" },
  { value: "Sri Kalki Auto Finance", label: "Sri Kalki Auto Finance" },
  { value: "Sri Kalmeshwar Co-Op Credit Society Ltd", label: "Sri Kalmeshwar Co-Op Credit Society Ltd" },
  { value: "Sri Kalyani Co-Operative Urban Bank Ltd", label: "Sri Kalyani Co-Operative Urban Bank Ltd" },
  { value: "Sri Kamakshi Auto Finance", label: "Sri Kamakshi Auto Finance" },
  { value: "Sri Kamala Priya Finance", label: "Sri Kamala Priya Finance" },
  { value: "Sri Kamalambika Co-Op. Urban Bank Ltd.", label: "Sri Kamalambika Co-Op. Urban Bank Ltd." },
  { value: "Sri Kamatchi A Credits", label: "Sri Kamatchi A Credits" },
  { value: "Sri Kanagadhara Finance", label: "Sri Kanagadhara Finance" },
  { value: "Sri Kanaka Credit C.O.S.Ltd", label: "Sri Kanaka Credit C.O.S.Ltd" },
  { value: "Sri Kanaka Credit Co-Operative Society Ltd.", label: "Sri Kanaka Credit Co-Operative Society Ltd." },
  { value: "Sri Kanakadurga A/F", label: "Sri Kanakadurga A/F" },
  { value: "Sri Kandhamurugan Investment", label: "Sri Kandhamurugan Investment" },
  { value: "Sri Kandhan Auto Finance", label: "Sri Kandhan Auto Finance" },
  { value: "Sri Kangadhara Finance", label: "Sri Kangadhara Finance" },
  { value: "Sri Kanika Credit Co Op Society Ltd", label: "Sri Kanika Credit Co Op Society Ltd" },
  { value: "Sri Kankariya Tea And Finance Ltd", label: "Sri Kankariya Tea And Finance Ltd" },
  { value: "Sri Kannan Corporation", label: "Sri Kannan Corporation" },
  { value: "Sri Kannan Finance", label: "Sri Kannan Finance" },
  { value: "Sri Kannika Parameswari Co.Op Bank Ltd", label: "Sri Kannika Parameswari Co.Op Bank Ltd" },
  { value: "Sri Kannikap Arameshwari Co Op Bank Ltd", label: "Sri Kannikap Arameshwari Co Op Bank Ltd" },
  { value: "Sri Kannikaparameshwari Co Operative Bank Ltd.", label: "Sri Kannikaparameshwari Co Operative Bank Ltd." },
  { value: "Sri Kanth Finance Corporation", label: "Sri Kanth Finance Corporation" },
  { value: "Sri Kantha Malai Murugan Finance", label: "Sri Kantha Malai Murugan Finance" },
  { value: "Sri Kantha Malai Murugan Finance", label: "Sri Kantha Malai Murugan Finance" },
  { value: "Sri Kanthamurugan Investments", label: "Sri Kanthamurugan Investments" },
  { value: "Sri Kanyaka Parameshwari Finance", label: "Sri Kanyaka Parameshwari Finance" },
  { value: "Sri Kanyakaparameshwari Co-Op Soc Ltd", label: "Sri Kanyakaparameshwari Co-Op Soc Ltd" },
  { value: "Sri Kanyakaparameswari Co-Op Bank Ltd", label: "Sri Kanyakaparameswari Co-Op Bank Ltd" },
  { value: "Sri Karabasav Shivacharya Pattina Sahakar", label: "Sri Karabasav Shivacharya Pattina Sahakar" },
  { value: "Sri Karpagam Finance", label: "Sri Karpagam Finance" },
  { value: "Sri Karpagam Finance", label: "Sri Karpagam Finance" },
  { value: "Sri Karpagamoorthy Finance", label: "Sri Karpagamoorthy Finance" },
  { value: "Sri Karpagavinayaga Finance", label: "Sri Karpagavinayaga Finance" },
  { value: "Sri Karpagavinayagar Auto Finance", label: "Sri Karpagavinayagar Auto Finance" },
  { value: "Sri Kasi Lakshmi Financiers", label: "Sri Kasi Lakshmi Financiers" },
  { value: "Sri Kayaka Laeasing Jewargi", label: "Sri Kayaka Laeasing Jewargi" },
  { value: "Sri Kedarinath Finance", label: "Sri Kedarinath Finance" },
  { value: "Sri Kedarnath Auto Finance", label: "Sri Kedarnath Auto Finance" },
  { value: "Sri Keerthi Auto Finance", label: "Sri Keerthi Auto Finance" },
  { value: "Sri Kethaki Visweswara Auto Finance", label: "Sri Kethaki Visweswara Auto Finance" },
  { value: "Sri Khandoba Auto Finance", label: "Sri Khandoba Auto Finance" },
  { value: "Sri Kirthi Chandra Finance", label: "Sri Kirthi Chandra Finance" },
  { value: "Sri Krishna Auto Finance", label: "Sri Krishna Auto Finance" },
  { value: "Sri Krishna Bhagarat", label: "Sri Krishna Bhagarat" },
  { value: "Sri Krishna Finance", label: "Sri Krishna Finance" },
  { value: "Sri Krishna Finance Corporation", label: "Sri Krishna Finance Corporation" },
  { value: "Sri Krishna Industrial Co-Operative Society Ltd", label: "Sri Krishna Industrial Co-Operative Society Ltd" },
  { value: "Sri Krishna Pattina Sahakara Sangha Niyamitha", label: "Sri Krishna Pattina Sahakara Sangha Niyamitha" },
  { value: "Sri Krishnarajendra Co-Op Bank Ltd", label: "Sri Krishnarajendra Co-Op Bank Ltd" },
  { value: "Sri Krupa Automoties Bhalki", label: "Sri Krupa Automoties Bhalki" },
  { value: "Sri Kuber Agencys", label: "Sri Kuber Agencys" },
  { value: "Sri Kuber Auto Finance Corporation", label: "Sri Kuber Auto Finance Corporation" },
  { value: "Sri Kuber Finance", label: "Sri Kuber Finance" },
  { value: "Sri Kuberalakshmi Auto Finance", label: "Sri Kuberalakshmi Auto Finance" },
  { value: "Sri Kumaraguru Capital", label: "Sri Kumaraguru Capital" },
  { value: "Sri Kumaran Auto Finance", label: "Sri Kumaran Auto Finance" },
  { value: "Sri Kumudha Finance", label: "Sri Kumudha Finance" },
  { value: "Sri L S Auto Finance", label: "Sri L S Auto Finance" },
  { value: "Sri Lakshmi Auto Finance Agencies", label: "Sri Lakshmi Auto Finance Agencies" },
  { value: "Sri Lakshmi Auto Finance Corporation", label: "Sri Lakshmi Auto Finance Corporation" },
  { value: "Sri Lakshmi Automobile Finance", label: "Sri Lakshmi Automobile Finance" },
  { value: "Sri Lakshmi Balaji Finance", label: "Sri Lakshmi Balaji Finance" },
  { value: "Sri Lakshmi Chennakesava Auto Finance Corporation", label: "Sri Lakshmi Chennakesava Auto Finance Corporation" },
  { value: "Sri Lakshmi Chennakesava Auto Mobile Finance", label: "Sri Lakshmi Chennakesava Auto Mobile Finance" },
  { value: "Sri Lakshmi Devi Auto Finance", label: "Sri Lakshmi Devi Auto Finance" },
  { value: "Sri Lakshmi Finance And Investments", label: "Sri Lakshmi Finance And Investments" },
  { value: "Sri Lakshmi Finance Co Ltd", label: "Sri Lakshmi Finance Co Ltd" },
  { value: "Sri Lakshmi Finance Company", label: "Sri Lakshmi Finance Company" },
  { value: "Sri Lakshmi Financiers", label: "Sri Lakshmi Financiers" },
  { value: "Sri Lakshmi Ganesh Auto Finance", label: "Sri Lakshmi Ganesh Auto Finance" },
  { value: "Sri Lakshmi Ganesh Auto Finance & Auto Leasing", label: "Sri Lakshmi Ganesh Auto Finance & Auto Leasing" },
  { value: "Sri Lakshmi Ganesh Finance Ltd", label: "Sri Lakshmi Ganesh Finance Ltd" },
  { value: "Sri Lakshmi Jothi Finance", label: "Sri Lakshmi Jothi Finance" },
  { value: "Sri Lakshmi Mahila Sah. Bank Niyamitha", label: "Sri Lakshmi Mahila Sah. Bank Niyamitha" },
  { value: "Sri Lakshmi Motors", label: "Sri Lakshmi Motors" },
  { value: "Sri Lakshmi Narashimma Finance", label: "Sri Lakshmi Narashimma Finance" },
  { value: "Sri Lakshmi Narasimha Auto Finance", label: "Sri Lakshmi Narasimha Auto Finance" },
  { value: "Sri Lakshmi Narasimha Auto Mobile Financiers", label: "Sri Lakshmi Narasimha Auto Mobile Financiers" },
  { value: "Sri Lakshmi Narayana Auto Financiers", label: "Sri Lakshmi Narayana Auto Financiers" },
  { value: "Sri Lakshmi Sai Teja Auto Finance", label: "Sri Lakshmi Sai Teja Auto Finance" },
  { value: "Sri Lakshmi Saraswathi Auto Finance", label: "Sri Lakshmi Saraswathi Auto Finance" },
  { value: "Sri Lakshmi Sharashwathi Auto Finance", label: "Sri Lakshmi Sharashwathi Auto Finance" },
  { value: "Sri Lakshmi Sharwathi Auto Finance", label: "Sri Lakshmi Sharwathi Auto Finance" },
  { value: "Sri Lakshmi Srinivasa A/F", label: "Sri Lakshmi Srinivasa A/F" },
  { value: "Sri Lakshmi Srinivasa Auto Finance", label: "Sri Lakshmi Srinivasa Auto Finance" },
  { value: "Sri Lakshmi Srinivasa Finance Corporation", label: "Sri Lakshmi Srinivasa Finance Corporation" },
  { value: "Sri Lakshmi Tirumala Finance", label: "Sri Lakshmi Tirumala Finance" },
  { value: "Sri Lakshmi Transport", label: "Sri Lakshmi Transport" },
  { value: "Sri Lakshmi Tulasi & Co", label: "Sri Lakshmi Tulasi & Co" },
  { value: "Sri Lakshmi Vara Durga Automobile Financiers", label: "Sri Lakshmi Vara Durga Automobile Financiers" },
  { value: "Sri Lakshmi Venkata Rama Auto Finance Corporation", label: "Sri Lakshmi Venkata Rama Auto Finance Corporation" },
  { value: "Sri Lakshmi Venkateshwara Financiers", label: "Sri Lakshmi Venkateshwara Financiers" },
  { value: "Sri Lakshmi Vigneshwara Automobile Finance", label: "Sri Lakshmi Vigneshwara Automobile Finance" },
  { value: "Sri Lakshmi Vilas Investments", label: "Sri Lakshmi Vilas Investments" },
  { value: "Sri Lakshmi Vinayaka Auto Financiers", label: "Sri Lakshmi Vinayaka Auto Financiers" },
  { value: "Sri Lakshmin Narayana Urban Co-Op Bank Ltd", label: "Sri Lakshmin Narayana Urban Co-Op Bank Ltd" },
  { value: "Sri Lakshminarasimma", label: "Sri Lakshminarasimma" },
  { value: "Sri Lakshminarayana Co-Op. Urban Bank Lt", label: "Sri Lakshminarayana Co-Op. Urban Bank Lt" },
  { value: "Sri Lakshminarsimma Auto Finance", label: "Sri Lakshminarsimma Auto Finance" },
  { value: "Sri Laksmi Mahila Co-Op Bank Ltd", label: "Sri Laksmi Mahila Co-Op Bank Ltd" },
  { value: "Sri Lalitha Finance", label: "Sri Lalitha Finance" },
  { value: "Sri Latha", label: "Sri Latha" },
  { value: "Sri Latha Auto Finance", label: "Sri Latha Auto Finance" },
  { value: "Sri Laxmananda Multi Purpose Co-Op Society", label: "Sri Laxmananda Multi Purpose Co-Op Society" },
  { value: "Sri Laxmi Auto Finance & Consultancy", label: "Sri Laxmi Auto Finance & Consultancy" },
  { value: "Sri Laxmi Chennakesava Auto Finance Corp", label: "Sri Laxmi Chennakesava Auto Finance Corp" },
  { value: "Sri Laxmi Chits", label: "Sri Laxmi Chits" },
  { value: "Sri Laxmi Finance", label: "Sri Laxmi Finance" },
  { value: "Sri Laxmi Ganapathi Auto & General Finance", label: "Sri Laxmi Ganapathi Auto & General Finance" },
  { value: "Sri Laxmi Ganapathi Finance", label: "Sri Laxmi Ganapathi Finance" },
  { value: "Sri Laxmi Ganapathi Hp & Finance", label: "Sri Laxmi Ganapathi Hp & Finance" },
  { value: "Sri Laxmi Hire Purchase Finance", label: "Sri Laxmi Hire Purchase Finance" },
  { value: "Sri Laxmi Narasimha Swamy Auto Finance", label: "Sri Laxmi Narasimha Swamy Auto Finance" },
  { value: "Sri Laxmi Narasimha Swamy Auto Finance Corporation", label: "Sri Laxmi Narasimha Swamy Auto Finance Corporation" },
  { value: "Sri Laxmi Narayana Chitfunds", label: "Sri Laxmi Narayana Chitfunds" },
  { value: "Sri Laxmi Narayana Chits & Finance", label: "Sri Laxmi Narayana Chits & Finance" },
  { value: "Sri Laxmi Narayana Finance", label: "Sri Laxmi Narayana Finance" },
  { value: "Sri Laxmi Narsimha Swamy", label: "Sri Laxmi Narsimha Swamy" },
  { value: "Sri Laxmi Sahakari Bank Ltd", label: "Sri Laxmi Sahakari Bank Ltd" },
  { value: "Sri Laxmi Saraswathi Finance Corporation", label: "Sri Laxmi Saraswathi Finance Corporation" },
  { value: "Sri Laxmi Urban Co-Op Cr Society Ltd", label: "Sri Laxmi Urban Co-Op Cr Society Ltd" },
  { value: "Sri Laxmi Vara Durga Auto Mobile Finance", label: "Sri Laxmi Vara Durga Auto Mobile Finance" },
  { value: "Sri Laxmi Venakteswara Swamy Finance", label: "Sri Laxmi Venakteswara Swamy Finance" },
  { value: "Sri Laxmi Venkata Rama Auto Finance", label: "Sri Laxmi Venkata Rama Auto Finance" },
  { value: "Sri Laxmi Venkata Rama Finance Corporation", label: "Sri Laxmi Venkata Rama Finance Corporation" },
  { value: "Sri Laxmi Venkateshwara Auto Finance", label: "Sri Laxmi Venkateshwara Auto Finance" },
  { value: "Sri Laxmi Venkateshwara Automobile Finance", label: "Sri Laxmi Venkateshwara Automobile Finance" },
  { value: "Sri Laxmi Venkateshwara Finance", label: "Sri Laxmi Venkateshwara Finance" },
  { value: "Sri Laxmi Venkateswara Auto Finance", label: "Sri Laxmi Venkateswara Auto Finance" },
  { value: "Sri Laxmi Vinayaka Hp & Finance", label: "Sri Laxmi Vinayaka Hp & Finance" },
  { value: "Sri Laya Auto Finance", label: "Sri Laya Auto Finance" },
  { value: "Sri Lingam Auto Deal", label: "Sri Lingam Auto Deal" },
  { value: "Sri M R H Finance", label: "Sri M R H Finance" },
  { value: "Sri M Visveswaraya Co-Operative Bank Ltd.", label: "Sri M Visveswaraya Co-Operative Bank Ltd." },
  { value: "Sri Madheswara Auto Finance", label: "Sri Madheswara Auto Finance" },
  { value: "Sri Maha Finance", label: "Sri Maha Finance" },
  { value: "Sri Maha Finance", label: "Sri Maha Finance" },
  { value: "Sri Maha Susmitha", label: "Sri Maha Susmitha" },
  { value: "Sri Mahalakshmi Auto Finance", label: "Sri Mahalakshmi Auto Finance" },
  { value: "Sri Mahalakshmi Finance Corporation", label: "Sri Mahalakshmi Finance Corporation" },
  { value: "Sri Mahalakshmi Vehicle Finance Co", label: "Sri Mahalakshmi Vehicle Finance Co" },
  { value: "Sri Mahalaxmi Financiers", label: "Sri Mahalaxmi Financiers" },
  { value: "Sri Mahant Shivayogi Sahakari Bank Ltd", label: "Sri Mahant Shivayogi Sahakari Bank Ltd" },
  { value: "Sri Mahashakti Finance And Investments", label: "Sri Mahashakti Finance And Investments" },
  { value: "Sri Mahaveer Auto Finance", label: "Sri Mahaveer Auto Finance" },
  { value: "Sri Mahaveer Co-Op Bank Ltd", label: "Sri Mahaveer Co-Op Bank Ltd" },
  { value: "Sri Mahaveer Co-Operative Credit Society , Alagur", label: "Sri Mahaveer Co-Operative Credit Society , Alagur" },
  { value: "Sri Maheshwar Auto Finance", label: "Sri Maheshwar Auto Finance" },
  { value: "Sri Mahuva Nagrik Sahakari Bank Ltd.", label: "Sri Mahuva Nagrik Sahakari Bank Ltd." },
  { value: "Sri Mallikarjuna Pattana Sahakari Bank N", label: "Sri Mallikarjuna Pattana Sahakari Bank N" },
  { value: "Sri Mangalambiga Auto Finance", label: "Sri Mangalambiga Auto Finance" },
  { value: "Sri Manidhar Finance", label: "Sri Manidhar Finance" },
  { value: "Sri Manik Prabhu Leasing", label: "Sri Manik Prabhu Leasing" },
  { value: "Sri Manjunatha Auto Financiers", label: "Sri Manjunatha Auto Financiers" },
  { value: "Sri Manjunatha Financiers", label: "Sri Manjunatha Financiers" },
  { value: "Sri Manjunatha Hp & Finance", label: "Sri Manjunatha Hp & Finance" },
  { value: "Sri Manjunatha Traders", label: "Sri Manjunatha Traders" },
  { value: "Sri Manoj Auto Finance", label: "Sri Manoj Auto Finance" },
  { value: "Sri Mariamman Hire Purchasing", label: "Sri Mariamman Hire Purchasing" },
  { value: "Sri Maruthi Hp And Finance", label: "Sri Maruthi Hp And Finance" },
  { value: "Sri Matheswara Finance", label: "Sri Matheswara Finance" },
  { value: "Sri Mathi Auto Finance", label: "Sri Mathi Auto Finance" },
  { value: "Sri Mayavan Finance", label: "Sri Mayavan Finance" },
  { value: "Sri Meenachi Amman Auto Finance", label: "Sri Meenachi Amman Auto Finance" },
  { value: "Sri Meenakshi Auto Finance", label: "Sri Meenakshi Auto Finance" },
  { value: "Sri Meenam Auto Finance", label: "Sri Meenam Auto Finance" },
  { value: "Sri Meenaxi Auto Finance Corporation", label: "Sri Meenaxi Auto Finance Corporation" },
  { value: "Sri Meghna Findnace", label: "Sri Meghna Findnace" },
  { value: "Sri Mithra Auto And General Finance", label: "Sri Mithra Auto And General Finance" },
  { value: "Sri Mithra Hp & Financiers", label: "Sri Mithra Hp & Financiers" },
  { value: "Sri Mithra Services", label: "Sri Mithra Services" },
  { value: "Sri Mjs Investments", label: "Sri Mjs Investments" },
  { value: "Sri Mkv Motors", label: "Sri Mkv Motors" },
  { value: "Sri Mm Finance", label: "Sri Mm Finance" },
  { value: "Sri Mokambigai Auto Finance", label: "Sri Mokambigai Auto Finance" },
  { value: "Sri Moogambigai Investments", label: "Sri Moogambigai Investments" },
  { value: "Sri Mounica Auto Finance", label: "Sri Mounica Auto Finance" },
  { value: "Sri Muga Finance", label: "Sri Muga Finance" },
  { value: "Sri Mukambigai Auto Finance", label: "Sri Mukambigai Auto Finance" },
  { value: "Sri Muneshwaraswamy Credit C-P Society L", label: "Sri Muneshwaraswamy Credit C-P Society L" },
  { value: "Sri Murali Krishna Auto Finance", label: "Sri Murali Krishna Auto Finance" },
  { value: "Sri Murali Krishna Finance", label: "Sri Murali Krishna Finance" },
  { value: "Sri Murali Krishna Financier", label: "Sri Murali Krishna Financier" },
  { value: "Sri Murgugharajendra Co Op Bank Ltd", label: "Sri Murgugharajendra Co Op Bank Ltd" },
  { value: "Sri Murugam Auto Finance", label: "Sri Murugam Auto Finance" },
  { value: "Sri Murugan And Co", label: "Sri Murugan And Co" },
  { value: "Sri Murugan Arul Finance", label: "Sri Murugan Arul Finance" },
  { value: "Sri Murugan Finance Company", label: "Sri Murugan Finance Company" },
  { value: "Sri Murugan Illam Finance", label: "Sri Murugan Illam Finance" },
  { value: "Sri Murugan Investments", label: "Sri Murugan Investments" },
  { value: "Sri Murugharajendra Co -Op Bank Ltd", label: "Sri Murugharajendra Co -Op Bank Ltd" },
  { value: "Sri Muthu Finance", label: "Sri Muthu Finance" },
  { value: "Sri Naga Devata Financiers", label: "Sri Naga Devata Financiers" },
  { value: "Sri Nakoda Auto Finance", label: "Sri Nakoda Auto Finance" },
  { value: "Sri Nallathai Finance", label: "Sri Nallathai Finance" },
  { value: "Sri Namana Finance", label: "Sri Namana Finance" },
  { value: "Sri Nandi Auto Finance", label: "Sri Nandi Auto Finance" },
  { value: "Sri Nantha Auto Finance", label: "Sri Nantha Auto Finance" },
  { value: "Sri Narayana Swamy Auto Financiers", label: "Sri Narayana Swamy Auto Financiers" },
  { value: "Sri Narayana Swamy Traders", label: "Sri Narayana Swamy Traders" },
  { value: "Sri Narayana Swamy Traders Automobile Finance", label: "Sri Narayana Swamy Traders Automobile Finance" },
  { value: "Sri Narayanadri Auto Finance", label: "Sri Narayanadri Auto Finance" },
  { value: "Sri Navaladiyan Auto Finance", label: "Sri Navaladiyan Auto Finance" },
  { value: "Sri Naveen Finance", label: "Sri Naveen Finance" },
  { value: "Sri Neelakanteshwara Swamy Credit  Co-Op Society Ltd.", label: "Sri Neelakanteshwara Swamy Credit  Co-Op Society Ltd." },
  { value: "Sri Netaji Credit Co-Op Socity", label: "Sri Netaji Credit Co-Op Socity" },
  { value: "Sri New Vijayalakshmi Financiers", label: "Sri New Vijayalakshmi Financiers" },
  { value: "Sri Nidhi Urban Co-Operative Credit Society", label: "Sri Nidhi Urban Co-Operative Credit Society" },
  { value: "Sri Nskt Finance", label: "Sri Nskt Finance" },
  { value: "Sri Om Ganapathy Auto Finance", label: "Sri Om Ganapathy Auto Finance" },
  { value: "Sri Padma Finance Co", label: "Sri Padma Finance Co" },
  { value: "Sri Padma Priya Finance", label: "Sri Padma Priya Finance" },
  { value: "Sri Padma Priya Finance Corp", label: "Sri Padma Priya Finance Corp" },
  { value: "Sri Padmalaya Finance", label: "Sri Padmalaya Finance" },
  { value: "Sri Padmavathi Auto Finance Corporation", label: "Sri Padmavathi Auto Finance Corporation" },
  { value: "Sri Palani Vilas Capital", label: "Sri Palani Vilas Capital" },
  { value: "Sri Parasnath Finance", label: "Sri Parasnath Finance" },
  { value: "Sri Parolaurban Co-Op Bank Ltd", label: "Sri Parolaurban Co-Op Bank Ltd" },
  { value: "Sri Parshwanatha Sahakari Bank Niyamitha", label: "Sri Parshwanatha Sahakari Bank Niyamitha" },
  { value: "Sri Pathanjali Souhardha Credit Co Op Bank Ltd. Pavagada", label: "Sri Pathanjali Souhardha Credit Co Op Bank Ltd. Pavagada" },
  { value: "Sri Paul Auto Finance", label: "Sri Paul Auto Finance" },
  { value: "Sri Perumal Auto Finance", label: "Sri Perumal Auto Finance" },
  { value: "Sri Pisedev Co-Op Credit Society", label: "Sri Pisedev Co-Op Credit Society" },
  { value: "Sri Pon Amman Finance", label: "Sri Pon Amman Finance" },
  { value: "Sri Pon Financiers", label: "Sri Pon Financiers" },
  { value: "Sri Ponmani Investment", label: "Sri Ponmani Investment" },
  { value: "Sri Pooja Auto Finance", label: "Sri Pooja Auto Finance" },
  { value: "Sri Pooja Finance Corporation", label: "Sri Pooja Finance Corporation" },
  { value: "Sri Poojitha Hp & Finance", label: "Sri Poojitha Hp & Finance" },
  { value: "Sri Poomani Investment", label: "Sri Poomani Investment" },
  { value: "Sri Prabhulingeshwar Souhard Cr Co-Op Ltd", label: "Sri Prabhulingeshwar Souhard Cr Co-Op Ltd" },
  { value: "Sri Prasanna Ganapathi Finance Corporation", label: "Sri Prasanna Ganapathi Finance Corporation" },
  { value: "Sri Prasanna Laxmi Finance", label: "Sri Prasanna Laxmi Finance" },
  { value: "Sri Prasanna Venkateshwara Finance", label: "Sri Prasanna Venkateshwara Finance" },
  { value: "Sri Prem Sai Auto Finance", label: "Sri Prem Sai Auto Finance" },
  { value: "Sri Priya Enterprises", label: "Sri Priya Enterprises" },
  { value: "Sri Priyanka Finance", label: "Sri Priyanka Finance" },
  { value: "Sri Prj Finance", label: "Sri Prj Finance" },
  { value: "Sri R I Finance", label: "Sri R I Finance" },
  { value: "Sri Raaja Ganapathy Finance", label: "Sri Raaja Ganapathy Finance" },
  { value: "Sri Radha Krishna Financiers", label: "Sri Radha Krishna Financiers" },
  { value: "Sri Ragavendra Auto Financiers", label: "Sri Ragavendra Auto Financiers" },
  { value: "Sri Raghavendra Automobile Finance", label: "Sri Raghavendra Automobile Finance" },
  { value: "Sri Raj Finance", label: "Sri Raj Finance" },
  { value: "Sri Raja Matha Devi Automobile Financiers", label: "Sri Raja Matha Devi Automobile Financiers" },
  { value: "Sri Raja Rajeshwari Auto Finance - A P T M", label: "Sri Raja Rajeshwari Auto Finance - A P T M" },
  { value: "Sri Raja Rajeswari Auto Finance Company", label: "Sri Raja Rajeswari Auto Finance Company" },
  { value: "Sri Raja Rajeswari Auto Finance Corporation", label: "Sri Raja Rajeswari Auto Finance Corporation" },
  { value: "Sri Rajarajeswari A/F", label: "Sri Rajarajeswari A/F" },
  { value: "Sri Rajareswara Finance Company", label: "Sri Rajareswara Finance Company" },
  { value: "Sri Rajdeo Pall", label: "Sri Rajdeo Pall" },
  { value: "Sri Rajya Lakshmi Finance Corporation", label: "Sri Rajya Lakshmi Finance Corporation" },
  { value: "Sri Ram", label: "Sri Ram" },
  { value: "Sri Ram Auto Agency", label: "Sri Ram Auto Agency" },
  { value: "Sri Ram Auto Finance Co Ltd", label: "Sri Ram Auto Finance Co Ltd" },
  { value: "Sri Ram Bank Ltd", label: "Sri Ram Bank Ltd" },
  { value: "Sri Ram City Union Bank Ltd", label: "Sri Ram City Union Bank Ltd" },
  { value: "Sri Ram City Union Finance Co Ltd", label: "Sri Ram City Union Finance Co Ltd" },
  { value: "Sri Ram City Union Finance Ltd - Hubli", label: "Sri Ram City Union Finance Ltd - Hubli" },
  { value: "Sri Ram City Union Finance Ltd - Moodubidri", label: "Sri Ram City Union Finance Ltd - Moodubidri" },
  { value: "Sri Ram City Union Finance Pvt. Ltd", label: "Sri Ram City Union Finance Pvt. Ltd" },
  { value: "Sri Ram Dity Union Finance (P) Ltd", label: "Sri Ram Dity Union Finance (P) Ltd" },
  { value: "Sri Ram Finance", label: "Sri Ram Finance" },
  { value: "Sri Ram Finance Corporation", label: "Sri Ram Finance Corporation" },
  { value: "Sri Ram Finance Limited", label: "Sri Ram Finance Limited" },
  { value: "Sri Ram Investment Ltd", label: "Sri Ram Investment Ltd" },
  { value: "Sri Ram Krishan Finance", label: "Sri Ram Krishan Finance" },
  { value: "Sri Ram Krishna Credit Co-Op Society Ltd - Hebri", label: "Sri Ram Krishna Credit Co-Op Society Ltd - Hebri" },
  { value: "Sri Ram Motor Finance Ltd", label: "Sri Ram Motor Finance Ltd" },
  { value: "Sri Ram P A C", label: "Sri Ram P A C" },
  { value: "Sri Ram Sai Finance", label: "Sri Ram Sai Finance" },
  { value: "Sri Ram Souharda Patthina Sahakari Niyamitha", label: "Sri Ram Souharda Patthina Sahakari Niyamitha" },
  { value: "Sri Ram Trade Finance Corp Ltd", label: "Sri Ram Trade Finance Corp Ltd" },
  { value: "Sri Ram Transport Finance Co Ltd", label: "Sri Ram Transport Finance Co Ltd" },
  { value: "Sri Ram Transport Finance Co Ltd - Basavakalyan", label: "Sri Ram Transport Finance Co Ltd - Basavakalyan" },
  { value: "Sri Ram Transport Finance Co.Ltd-Kumta", label: "Sri Ram Transport Finance Co.Ltd-Kumta" },
  { value: "Sri Ram Transport Finance Corp", label: "Sri Ram Transport Finance Corp" },
  { value: "Sri Ram Transport Financial Services Ltd", label: "Sri Ram Transport Financial Services Ltd" },
  { value: "Sri Ram Transport Pvt Ltd.", label: "Sri Ram Transport Pvt Ltd." },
  { value: "Sri Rama Chandra Auto Finance", label: "Sri Rama Chandra Auto Finance" },
  { value: "Sri Rama Co-Op Bank Ltd", label: "Sri Rama Co-Op Bank Ltd" },
  { value: "Sri Rama Credit Co-Operative Society Ltd", label: "Sri Rama Credit Co-Operative Society Ltd" },
  { value: "Sri Rama Devi Auto Finance", label: "Sri Rama Devi Auto Finance" },
  { value: "Sri Rama Finance India Limited", label: "Sri Rama Finance India Limited" },
  { value: "Sri Rama Hi-Pur Finance Co Ltd", label: "Sri Rama Hi-Pur Finance Co Ltd" },
  { value: "Sri Rama Mens Thrift Co Operative Society", label: "Sri Rama Mens Thrift Co Operative Society" },
  { value: "Sri Rama Transport Finance Co Ltd", label: "Sri Rama Transport Finance Co Ltd" },
  { value: "Sri Ramakrishna Credit Co-Op Society Ltd - Byndoor", label: "Sri Ramakrishna Credit Co-Op Society Ltd - Byndoor" },
  { value: "Sri Ramakrishna Credit Co-Op Society Ltd - Hebri Branch", label: "Sri Ramakrishna Credit Co-Op Society Ltd - Hebri Branch" },
  { value: "Sri Ramakrishna Credit Co-Op. Society Lt", label: "Sri Ramakrishna Credit Co-Op. Society Lt" },
  { value: "Sri Ramakrishna Credit Co-Operative Society Ltd", label: "Sri Ramakrishna Credit Co-Operative Society Ltd" },
  { value: "Sri Ramakrishna Finance", label: "Sri Ramakrishna Finance" },
  { value: "Sri Ramana Auto Finance", label: "Sri Ramana Auto Finance" },
  { value: "Sri Ramana Finance", label: "Sri Ramana Finance" },
  { value: "Sri Ramana Two Wheeler Finance", label: "Sri Ramana Two Wheeler Finance" },
  { value: "Sri Rammmalingeswara Leasing And Autofin Private Ltd", label: "Sri Rammmalingeswara Leasing And Autofin Private Ltd" },
  { value: "Sri Ramulu Union Finance Ltd", label: "Sri Ramulu Union Finance Ltd" },
  { value: "Sri Ranga Auto Finance", label: "Sri Ranga Auto Finance" },
  { value: "Sri Ravi Teja Finance", label: "Sri Ravi Teja Finance" },
  { value: "Sri Renugambal Auto Finance", label: "Sri Renugambal Auto Finance" },
  { value: "Sri Renuka Devi Finance", label: "Sri Renuka Devi Finance" },
  { value: "Sri Renukamba Credit Co Operative Society Ltd", label: "Sri Renukamba Credit Co Operative Society Ltd" },
  { value: "Sri Revanasiddeshwar Co-Op Society", label: "Sri Revanasiddeshwar Co-Op Society" },
  { value: "Sri Revanasiddeshwar Pattana Sahakari Bank", label: "Sri Revanasiddeshwar Pattana Sahakari Bank" },
  { value: "Sri Revansiddeshwar Credit Co-Op Society", label: "Sri Revansiddeshwar Credit Co-Op Society" },
  { value: "Sri Revansiddeshwar Pattan Sahakari Bank Niyamit", label: "Sri Revansiddeshwar Pattan Sahakari Bank Niyamit" },
  { value: "Sri Revansiddheshwar Auto Finance", label: "Sri Revansiddheshwar Auto Finance" },
  { value: "Sri Rishab Finance", label: "Sri Rishab Finance" },
  { value: "Sri Rr Finance", label: "Sri Rr Finance" },
  { value: "Sri Rubera Ganapathy Bankers", label: "Sri Rubera Ganapathy Bankers" },
  { value: "Sri S Adhi Lakshmi Automobile Financiers", label: "Sri S Adhi Lakshmi Automobile Financiers" },
  { value: "Sri Sabari Auto Finance", label: "Sri Sabari Auto Finance" },
  { value: "Sri Sabari Finance", label: "Sri Sabari Finance" },
  { value: "Sri Sadayappa Finance", label: "Sri Sadayappa Finance" },
  { value: "Sri Sai A/F", label: "Sri Sai A/F" },
  { value: "Sri Sai Amitesh Financiers", label: "Sri Sai Amitesh Financiers" },
  { value: "Sri Sai Auto Finance Corporation", label: "Sri Sai Auto Finance Corporation" },
  { value: "Sri Sai Balaji Auto & General Finance", label: "Sri Sai Balaji Auto & General Finance" },
  { value: "Sri Sai Balaji Auto Finance", label: "Sri Sai Balaji Auto Finance" },
  { value: "Sri Sai Enterprises", label: "Sri Sai Enterprises" },
  { value: "Sri Sai Finance", label: "Sri Sai Finance" },
  { value: "Sri Sai Ganesh Co-Operative Credit Society Ltd.", label: "Sri Sai Ganesh Co-Operative Credit Society Ltd." },
  { value: "Sri Sai Hanuman Auto Finance", label: "Sri Sai Hanuman Auto Finance" },
  { value: "Sri Sai Hire Purchase", label: "Sri Sai Hire Purchase" },
  { value: "Sri Sai Hire Purchase & Finance", label: "Sri Sai Hire Purchase & Finance" },
  { value: "Sri Sai Jyothsna Finance", label: "Sri Sai Jyothsna Finance" },
  { value: "Sri Sai Kalki Auto Finance", label: "Sri Sai Kalki Auto Finance" },
  { value: "Sri Sai Krishna Auto Finance", label: "Sri Sai Krishna Auto Finance" },
  { value: "Sri Sai Krishna Sales & Services", label: "Sri Sai Krishna Sales & Services" },
  { value: "Sri Sai Lakshmi A/F", label: "Sri Sai Lakshmi A/F" },
  { value: "Sri Sai Lakshmi Narasimha Finance", label: "Sri Sai Lakshmi Narasimha Finance" },
  { value: "Sri Sai Lalitha Services Corporation", label: "Sri Sai Lalitha Services Corporation" },
  { value: "Sri Sai Laxmi Auto Finance", label: "Sri Sai Laxmi Auto Finance" },
  { value: "Sri Sai Laxmi Ganapathi Auto Finance", label: "Sri Sai Laxmi Ganapathi Auto Finance" },
  { value: "Sri Sai Maruthi Enterprises", label: "Sri Sai Maruthi Enterprises" },
  { value: "Sri Sai Motor Finance", label: "Sri Sai Motor Finance" },
  { value: "Sri Sai Murali Krishna Finance", label: "Sri Sai Murali Krishna Finance" },
  { value: "Sri Sai Priya Auto Finance", label: "Sri Sai Priya Auto Finance" },
  { value: "Sri Sai Ram Auto Finance", label: "Sri Sai Ram Auto Finance" },
  { value: "Sri Sai Ram Enterprises", label: "Sri Sai Ram Enterprises" },
  { value: "Sri Sai Ram Traders Automobile Financiers", label: "Sri Sai Ram Traders Automobile Financiers" },
  { value: "Sri Sai Ratna Finance", label: "Sri Sai Ratna Finance" },
  { value: "Sri Sai Rishita Financiers", label: "Sri Sai Rishita Financiers" },
  { value: "Sri Sai Roja Financers", label: "Sri Sai Roja Financers" },
  { value: "Sri Sai Sangameshwara Auto Fin", label: "Sri Sai Sangameshwara Auto Fin" },
  { value: "Sri Sai Santhoshi Enterprises", label: "Sri Sai Santhoshi Enterprises" },
  { value: "Sri Sai Shiva Finance", label: "Sri Sai Shiva Finance" },
  { value: "Sri Sai Shiva Hire Purchase & Finance", label: "Sri Sai Shiva Hire Purchase & Finance" },
  { value: "Sri Sai Siri Finance And Leasing Ltd.", label: "Sri Sai Siri Finance And Leasing Ltd." },
  { value: "Sri Sai Srinivasa Finance", label: "Sri Sai Srinivasa Finance" },
  { value: "Sri Sai Sudha General Finance", label: "Sri Sai Sudha General Finance" },
  { value: "Sri Sai Surya Teja Hire Purchase", label: "Sri Sai Surya Teja Hire Purchase" },
  { value: "Sri Sai Teja Auto And General Finance", label: "Sri Sai Teja Auto And General Finance" },
  { value: "Sri Sai Tejeswara A/F", label: "Sri Sai Tejeswara A/F" },
  { value: "Sri Sai Tulasi Auto Finance", label: "Sri Sai Tulasi Auto Finance" },
  { value: "Sri Sai Urban Co-Operative Credit Society Ltd", label: "Sri Sai Urban Co-Operative Credit Society Ltd" },
  { value: "Sri Sai Vaishnavi Auto Finance & General", label: "Sri Sai Vaishnavi Auto Finance & General" },
  { value: "Sri Sai Vaishnavi Automobile & General Finance", label: "Sri Sai Vaishnavi Automobile & General Finance" },
  { value: "Sri Sai Venkata Financiers", label: "Sri Sai Venkata Financiers" },
  { value: "Sri Sai Vinayaka Enterprises", label: "Sri Sai Vinayaka Enterprises" },
  { value: "Sri Sairam Traders", label: "Sri Sairam Traders" },
  { value: "Sri Sairatna Finance", label: "Sri Sairatna Finance" },
  { value: "Sri Sakth Bankers", label: "Sri Sakth Bankers" },
  { value: "Sri Sakthi Amman Auto Finance", label: "Sri Sakthi Amman Auto Finance" },
  { value: "Sri Sakthi Auto Finance", label: "Sri Sakthi Auto Finance" },
  { value: "Sri Sakthi Auto Finance", label: "Sri Sakthi Auto Finance" },
  { value: "Sri Sakthi Credit Corporation", label: "Sri Sakthi Credit Corporation" },
  { value: "Sri Sakthi Fianciers", label: "Sri Sakthi Fianciers" },
  { value: "Sri Sakthi Kumaran Auto Finance", label: "Sri Sakthi Kumaran Auto Finance" },
  { value: "Sri Sakthi Selvan Enterprises", label: "Sri Sakthi Selvan Enterprises" },
  { value: "Sri Sakthi Sivan Enterprises", label: "Sri Sakthi Sivan Enterprises" },
  { value: "Sri Sakthi Vinayagar Auto Finance", label: "Sri Sakthi Vinayagar Auto Finance" },
  { value: "Sri Sakthimurughan Finance", label: "Sri Sakthimurughan Finance" },
  { value: "Sri Sakthy Finance", label: "Sri Sakthy Finance" },
  { value: "Sri Salem Finance", label: "Sri Salem Finance" },
  { value: "Sri Samarth Leasing", label: "Sri Samarth Leasing" },
  { value: "Sri Samatha Finance", label: "Sri Samatha Finance" },
  { value: "Sri Sampige Siddheswara Urban Co Bk Ltd.", label: "Sri Sampige Siddheswara Urban Co Bk Ltd." },
  { value: "Sri Sampige Sideshwara Urban Co-Op Bank Ltd", label: "Sri Sampige Sideshwara Urban Co-Op Bank Ltd" },
  { value: "Sri Sangameshwar Co Op Credit Society Niyamit", label: "Sri Sangameshwar Co Op Credit Society Niyamit" },
  { value: "Sri Sangameshwar Pattin Sahakari Sangh", label: "Sri Sangameshwar Pattin Sahakari Sangh" },
  { value: "Sri Sanjeevani Hi Pur Finance", label: "Sri Sanjeevani Hi Pur Finance" },
  { value: "Sri Sanjivi Finance", label: "Sri Sanjivi Finance" },
  { value: "Sri Sankar Financial Services", label: "Sri Sankar Financial Services" },
  { value: "Sri Sankara Financial Services", label: "Sri Sankara Financial Services" },
  { value: "Sri Santhra Auto Finance", label: "Sri Santhra Auto Finance" },
  { value: "Sri Sarada Finance", label: "Sri Sarada Finance" },
  { value: "Sri Saradha Auto Finance", label: "Sri Saradha Auto Finance" },
  { value: "Sri Saraswathi Auto Finance", label: "Sri Saraswathi Auto Finance" },
  { value: "Sri Saraswati Co-Op Credit Society Ltd", label: "Sri Saraswati Co-Op Credit Society Ltd" },
  { value: "Sri Saravana Finance", label: "Sri Saravana Finance" },
  { value: "Sri Satya Deva Finance Corporation", label: "Sri Satya Deva Finance Corporation" },
  { value: "Sri Satya Devi Finance Corporation", label: "Sri Satya Devi Finance Corporation" },
  { value: "Sri Satya Sai Chits & Finance", label: "Sri Satya Sai Chits & Finance" },
  { value: "Sri Satya Sai Nagarik Sahakari Bank M", label: "Sri Satya Sai Nagarik Sahakari Bank M" },
  { value: "Sri Satya Sai Nagarik Sahakari Bank Mydt", label: "Sri Satya Sai Nagarik Sahakari Bank Mydt" },
  { value: "Sri Satya Sai Nagrik Sahakari Bank Maryadit, Bhopal", label: "Sri Satya Sai Nagrik Sahakari Bank Maryadit, Bhopal" },
  { value: "Sri Satyanarayana Auto Finance", label: "Sri Satyanarayana Auto Finance" },
  { value: "Sri Satyanarayana Automobile Finance Ltd", label: "Sri Satyanarayana Automobile Finance Ltd" },
  { value: "Sri Satyanarayana Swamy Automobile Finance", label: "Sri Satyanarayana Swamy Automobile Finance" },
  { value: "Sri Satyasai Bank", label: "Sri Satyasai Bank" },
  { value: "Sri Satyasai Co-Op Urban Bank Ltd.", label: "Sri Satyasai Co-Op Urban Bank Ltd." },
  { value: "Sri Seetaraghava Sahakara Bank Niyamitha", label: "Sri Seetaraghava Sahakara Bank Niyamitha" },
  { value: "Sri Seetha Ramanjaneya Auto Finance", label: "Sri Seetha Ramanjaneya Auto Finance" },
  { value: "Sri Seetharaghava Sahakara Bk Niyamitha", label: "Sri Seetharaghava Sahakara Bk Niyamitha" },
  { value: "Sri Seetharaghava Souharda Sahakara Bank Niyamitha", label: "Sri Seetharaghava Souharda Sahakara Bank Niyamitha" },
  { value: "Sri Seetharghava Souharda Sahakara Bank.", label: "Sri Seetharghava Souharda Sahakara Bank." },
  { value: "Sri Seethataghava Souharda Sah Bk Niy", label: "Sri Seethataghava Souharda Sah Bk Niy" },
  { value: "Sri Selvakumaran Finance", label: "Sri Selvakumaran Finance" },
  { value: "Sri Selvalakshmi Auto Finance", label: "Sri Selvalakshmi Auto Finance" },
  { value: "Sri Selvam Group Auto Finance", label: "Sri Selvam Group Auto Finance" },
  { value: "Sri Selvanagapathy Finance", label: "Sri Selvanagapathy Finance" },
  { value: "Sri Selvanarayana Finance Ltd", label: "Sri Selvanarayana Finance Ltd" },
  { value: "Sri Selvavinayaga Auto  Finance", label: "Sri Selvavinayaga Auto  Finance" },
  { value: "Sri Selvavinayaga Finance", label: "Sri Selvavinayaga Finance" },
  { value: "Sri Senthil Andavar Finance", label: "Sri Senthil Andavar Finance" },
  { value: "Sri Senthil Muthu Finance", label: "Sri Senthil Muthu Finance" },
  { value: "Sri Shaila Cerdit Co-Op Society", label: "Sri Shaila Cerdit Co-Op Society" },
  { value: "Sri Shakthi Vinayagar Auto Finance", label: "Sri Shakthi Vinayagar Auto Finance" },
  { value: "Sri Shankar Finance And Investment Corporation", label: "Sri Shankar Finance And Investment Corporation" },
  { value: "Sri Shankar Finance And Investment Corporation", label: "Sri Shankar Finance And Investment Corporation" },
  { value: "Sri Shankara Chits And Finance", label: "Sri Shankara Chits And Finance" },
  { value: "Sri Shankari Credit Co-Operative Society Ltd", label: "Sri Shankari Credit Co-Operative Society Ltd" },
  { value: "Sri Shanmuga Auto Finance", label: "Sri Shanmuga Auto Finance" },
  { value: "Sri Shanmuga Finance", label: "Sri Shanmuga Finance" },
  { value: "Sri Shantappanna Mirji Urban Co-Op Bank Ltd.", label: "Sri Shantappanna Mirji Urban Co-Op Bank Ltd." },
  { value: "Sri Shanteshwara Pattina Sahakari Niyamit", label: "Sri Shanteshwara Pattina Sahakari Niyamit" },
  { value: "Sri Shanthi Guru Finance", label: "Sri Shanthi Guru Finance" },
  { value: "Sri Shanthi Vijay Credit Co", label: "Sri Shanthi Vijay Credit Co" },
  { value: "Sri Sharada Finance", label: "Sri Sharada Finance" },
  { value: "Sri Sharada Mahila Co-Op Bank Ltd", label: "Sri Sharada Mahila Co-Op Bank Ltd" },
  { value: "Sri Sharadamba Mahila Co-Op. Urban Bk.L.", label: "Sri Sharadamba Mahila Co-Op. Urban Bk.L." },
  { value: "Sri Sharan Basaveshwar Co-Operative Credit Society Niyamitha", label: "Sri Sharan Basaveshwar Co-Operative Credit Society Niyamitha" },
  { value: "Sri Sharana Basveshwara Pattan Sahakar Bank Niyamith", label: "Sri Sharana Basveshwara Pattan Sahakar Bank Niyamith" },
  { value: "Sri Sharanabasaveshwara Pattan Sa B N.,", label: "Sri Sharanabasaveshwara Pattan Sa B N.," },
  { value: "Sri Sharanaveereswara Co-Op Bank Ltd Naltwad Branch", label: "Sri Sharanaveereswara Co-Op Bank Ltd Naltwad Branch" },
  { value: "Sri Sharanbasaveshwar Pattan Sah Bk Niya", label: "Sri Sharanbasaveshwar Pattan Sah Bk Niya" },
  { value: "Sri Sharanveereshwar Sahakari Bank Ltd", label: "Sri Sharanveereshwar Sahakari Bank Ltd" },
  { value: "Sri Shidhi Finance", label: "Sri Shidhi Finance" },
  { value: "Sri Shiridi Sai Auto Finance", label: "Sri Shiridi Sai Auto Finance" },
  { value: "Sri Shiridi Sai Finance", label: "Sri Shiridi Sai Finance" },
  { value: "Sri Shiva Auto Finance", label: "Sri Shiva Auto Finance" },
  { value: "Sri Shivaji Souharda Pattina Sahakari Niyamitha", label: "Sri Shivaji Souharda Pattina Sahakari Niyamitha" },
  { value: "Sri Shivashakthi Souhardha Pattina Sahakari Niyamit", label: "Sri Shivashakthi Souhardha Pattina Sahakari Niyamit" },
  { value: "Sri Shivashree Souharda Credit Co-Op Ltd", label: "Sri Shivashree Souharda Credit Co-Op Ltd" },
  { value: "Sri Shivayogi Murugendra Swami Urban Co-Op Bank", label: "Sri Shivayogi Murugendra Swami Urban Co-Op Bank" },
  { value: "Sri Shivneri Sahakari Bank Ltd", label: "Sri Shivneri Sahakari Bank Ltd" },
  { value: "Sri Shyam Auto Finance", label: "Sri Shyam Auto Finance" },
  { value: "Sri Siddalingeshwara Finance & Investment", label: "Sri Siddalingeshwara Finance & Investment" },
  { value: "Sri Siddaparavatha Pattina Sah In", label: "Sri Siddaparavatha Pattina Sah In" },
  { value: "Sri Siddaparavatha Pattina Souharda S N", label: "Sri Siddaparavatha Pattina Souharda S N" },
  { value: "Sri Siddaparvatha Pattina Souharda Sahka", label: "Sri Siddaparvatha Pattina Souharda Sahka" },
  { value: "Sri Siddarameswara Sahakara Bank Niyamit", label: "Sri Siddarameswara Sahakara Bank Niyamit" },
  { value: "Sri Siddeshwar Pattin Sahakari Sangh Ltd.", label: "Sri Siddeshwar Pattin Sahakari Sangh Ltd." },
  { value: "Sri Siddeswara Auto Finance", label: "Sri Siddeswara Auto Finance" },
  { value: "Sri Siddhaparvatha Pattina Souhard Sahakari Niyamit", label: "Sri Siddhaparvatha Pattina Souhard Sahakari Niyamit" },
  { value: "Sri Sidhbali Motor General", label: "Sri Sidhbali Motor General" },
  { value: "Sri Sidhi Finance", label: "Sri Sidhi Finance" },
  { value: "Sri Sinnar Vyapari Sahakari Bank Ltd", label: "Sri Sinnar Vyapari Sahakari Bank Ltd" },
  { value: "Sri Siri Finance", label: "Sri Siri Finance" },
  { value: "Sri Sita Rama General Finance", label: "Sri Sita Rama General Finance" },
  { value: "Sri Sity Union Finance Ltd", label: "Sri Sity Union Finance Ltd" },
  { value: "Sri Siva  Auto Finance", label: "Sri Siva  Auto Finance" },
  { value: "Sri Siva  Auto Finance", label: "Sri Siva  Auto Finance" },
  { value: "Sri Siva Jyothi Finance", label: "Sri Siva Jyothi Finance" },
  { value: "Sri Siva Sai Auto Finance", label: "Sri Siva Sai Auto Finance" },
  { value: "Sri Siva Sakthi Auto Finance", label: "Sri Siva Sakthi Auto Finance" },
  { value: "Sri Sivan Malai Andavar Finance", label: "Sri Sivan Malai Andavar Finance" },
  { value: "Sri Solayappa Finance", label: "Sri Solayappa Finance" },
  { value: "Sri Sri Hire Purchase And Finance Ltd.", label: "Sri Sri Hire Purchase And Finance Ltd." },
  { value: "Sri Srimal Finance", label: "Sri Srimal Finance" },
  { value: "Sri Srinivasa A/F", label: "Sri Srinivasa A/F" },
  { value: "Sri Srinivasa Auto Mobile Financiers Co", label: "Sri Srinivasa Auto Mobile Financiers Co" },
  { value: "Sri Srinivasa Finance", label: "Sri Srinivasa Finance" },
  { value: "Sri Srinivasa Finance Corporation", label: "Sri Srinivasa Finance Corporation" },
  { value: "Sri Srishaila Credit Co-Op. Society Ltd.", label: "Sri Srishaila Credit Co-Op. Society Ltd." },
  { value: "Sri Subhab Lakshmi Auto Finance", label: "Sri Subhab Lakshmi Auto Finance" },
  { value: "Sri Subrahmanyeshwara Finance", label: "Sri Subrahmanyeshwara Finance" },
  { value: "Sri Sudar Auto Finance", label: "Sri Sudar Auto Finance" },
  { value: "Sri Sudarsan Auto Finance", label: "Sri Sudarsan Auto Finance" },
  { value: "Sri Sudha Auto Fin Corporation", label: "Sri Sudha Auto Fin Corporation" },
  { value: "Sri Sudha Co-Op Bank Ltd", label: "Sri Sudha Co-Op Bank Ltd" },
  { value: "Sri Sudha Co-Op Bank Ltd", label: "Sri Sudha Co-Op Bank Ltd" },
  { value: "Sri Suganeswara Finance", label: "Sri Suganeswara Finance" },
  { value: "Sri Sundara Ganapathy Auto Finance", label: "Sri Sundara Ganapathy Auto Finance" },
  { value: "Sri Sundaraganapathy Auto Finance", label: "Sri Sundaraganapathy Auto Finance" },
  { value: "Sri Surya Auto & General Finance", label: "Sri Surya Auto & General Finance" },
  { value: "Sri Surya Auto And General Finance", label: "Sri Surya Auto And General Finance" },
  { value: "Sri Surya Auto Finance", label: "Sri Surya Auto Finance" },
  { value: "Sri Surya Finance", label: "Sri Surya Finance" },
  { value: "Sri Susavani Finance", label: "Sri Susavani Finance" },
  { value: "Sri Suswani Finance", label: "Sri Suswani Finance" },
  { value: "Sri Svms Finance", label: "Sri Svms Finance" },
  { value: "Sri Swastik Hire Purchase", label: "Sri Swastik Hire Purchase" },
  { value: "Sri Swathi Finance Ltd", label: "Sri Swathi Finance Ltd" },
  { value: "Sri Tharana Financiers", label: "Sri Tharana Financiers" },
  { value: "Sri Thimmanavara Vividhodesha Sowharda Sahakari Niyamitha", label: "Sri Thimmanavara Vividhodesha Sowharda Sahakari Niyamitha" },
  { value: "Sri Thirumala Auto Finance", label: "Sri Thirumala Auto Finance" },
  { value: "Sri Thirumala Finance", label: "Sri Thirumala Finance" },
  { value: "Sri Thirumala Venkateshwara Finance", label: "Sri Thirumala Venkateshwara Finance" },
  { value: "Sri Thirumalai Auto Finance", label: "Sri Thirumalai Auto Finance" },
  { value: "Sri Thirumalai Bahavan Finance", label: "Sri Thirumalai Bahavan Finance" },
  { value: "Sri Thiruppathi Auto Finance", label: "Sri Thiruppathi Auto Finance" },
  { value: "Sri Thiruthani Murugan Auto Finance", label: "Sri Thiruthani Murugan Auto Finance" },
  { value: "Sri Thulasinada Auto Finance", label: "Sri Thulasinada Auto Finance" },
  { value: "Sri Tilak Financiers", label: "Sri Tilak Financiers" },
  { value: "Sri Tirumala Auto Finance", label: "Sri Tirumala Auto Finance" },
  { value: "Sri Tirumula Finance Company", label: "Sri Tirumula Finance Company" },
  { value: "Sri Tulasi Nand Auto Financiers", label: "Sri Tulasi Nand Auto Financiers" },
  { value: "Sri Udaya Ravi Souhardha Credit Co-Operative Ltd.", label: "Sri Udaya Ravi Souhardha Credit Co-Operative Ltd." },
  { value: "Sri Uma Credit Souhard Sahakari Niyamit", label: "Sri Uma Credit Souhard Sahakari Niyamit" },
  { value: "Sri Urishabhanath Alpsanyatar Sahakari Pattin Sangh Niyamith", label: "Sri Urishabhanath Alpsanyatar Sahakari Pattin Sangh Niyamith" },
  { value: "Sri V K Finance", label: "Sri V K Finance" },
  { value: "Sri Vadivel Finance Corporation", label: "Sri Vadivel Finance Corporation" },
  { value: "Sri Vadivudaiyamman Auto Financiers", label: "Sri Vadivudaiyamman Auto Financiers" },
  { value: "Sri Vaishanavi Auto Finance", label: "Sri Vaishanavi Auto Finance" },
  { value: "Sri Valli Credit Corporation", label: "Sri Valli Credit Corporation" },
  { value: "Sri Valli Investments", label: "Sri Valli Investments" },
  { value: "Sri Valmiki Credit Co-Op Society Ltd", label: "Sri Valmiki Credit Co-Op Society Ltd" },
  { value: "Sri Valmiki Pattina Sahakara Sanga", label: "Sri Valmiki Pattina Sahakara Sanga" },
  { value: "Sri Vandana Auto Finance", label: "Sri Vandana Auto Finance" },
  { value: "Sri Vankateshwara Auto", label: "Sri Vankateshwara Auto" },
  { value: "Sri Vara Siddi Vinanayaka Finance", label: "Sri Vara Siddi Vinanayaka Finance" },
  { value: "Sri Varasiddi Vinayaka Auto & General Finance", label: "Sri Varasiddi Vinayaka Auto & General Finance" },
  { value: "Sri Varasiddi Vinayaka Auto Finance", label: "Sri Varasiddi Vinayaka Auto Finance" },
  { value: "Sri Vari Auto Finance", label: "Sri Vari Auto Finance" },
  { value: "Sri Vari Finance", label: "Sri Vari Finance" },
  { value: "Sri Vari Finance", label: "Sri Vari Finance" },
  { value: "Sri Vasamba Co-Op Bank Ltd,", label: "Sri Vasamba Co-Op Bank Ltd," },
  { value: "Sri Vasantham Finance", label: "Sri Vasantham Finance" },
  { value: "Sri Vasavamba Co-Op Bank Ltd", label: "Sri Vasavamba Co-Op Bank Ltd" },
  { value: "Sri Vasavi Pattina Sahakara Sa.Niyamitha", label: "Sri Vasavi Pattina Sahakara Sa.Niyamitha" },
  { value: "Sri Vasavi Pattina Sahakara Sangha", label: "Sri Vasavi Pattina Sahakara Sangha" },
  { value: "Sri Vasudeva Auto Finance", label: "Sri Vasudeva Auto Finance" },
  { value: "Sri Veera Brahmendra Swamy Enterprises", label: "Sri Veera Brahmendra Swamy Enterprises" },
  { value: "Sri Veerapulakeshi Co-Op Bank Ltd", label: "Sri Veerapulakeshi Co-Op Bank Ltd" },
  { value: "Sri Veerashiva Pattina Sahakara Sangha", label: "Sri Veerashiva Pattina Sahakara Sangha" },
  { value: "Sri Vekateswara Financiers", label: "Sri Vekateswara Financiers" },
  { value: "Sri Vela Financiers", label: "Sri Vela Financiers" },
  { value: "Sri Velvan Auto Finance", label: "Sri Velvan Auto Finance" },
  { value: "Sri Vengamamba Auto Finance", label: "Sri Vengamamba Auto Finance" },
  { value: "Sri Vengamamba Automobile Finance", label: "Sri Vengamamba Automobile Finance" },
  { value: "Sri Vengamamba Finance", label: "Sri Vengamamba Finance" },
  { value: "Sri Vengateshwara Auto Finance", label: "Sri Vengateshwara Auto Finance" },
  { value: "Sri Venkaiah Swamy Finance", label: "Sri Venkaiah Swamy Finance" },
  { value: "Sri Venkata Kanaka Durga Finance", label: "Sri Venkata Kanaka Durga Finance" },
  { value: "Sri Venkata Krishna A/F", label: "Sri Venkata Krishna A/F" },
  { value: "Sri Venkata Krishna Finance", label: "Sri Venkata Krishna Finance" },
  { value: "Sri Venkata Laxmi Auto Finance", label: "Sri Venkata Laxmi Auto Finance" },
  { value: "Sri Venkata Laxmi Sai Financiers & Chits", label: "Sri Venkata Laxmi Sai Financiers & Chits" },
  { value: "Sri Venkata Rama Auto Finance Corporation", label: "Sri Venkata Rama Auto Finance Corporation" },
  { value: "Sri Venkata Ramana Auto Finance", label: "Sri Venkata Ramana Auto Finance" },
  { value: "Sri Venkata Ramana Auto Mobile Finance", label: "Sri Venkata Ramana Auto Mobile Finance" },
  { value: "Sri Venkata Ramana Auto Mobile Financier", label: "Sri Venkata Ramana Auto Mobile Financier" },
  { value: "Sri Venkata Ramana Finance", label: "Sri Venkata Ramana Finance" },
  { value: "Sri Venkata Ramana Finance Corporation", label: "Sri Venkata Ramana Finance Corporation" },
  { value: "Sri Venkata Ramanjaneya Financiers.", label: "Sri Venkata Ramanjaneya Financiers." },
  { value: "Sri Venkata Sai Auto Finance", label: "Sri Venkata Sai Auto Finance" },
  { value: "Sri Venkata Sai Finance", label: "Sri Venkata Sai Finance" },
  { value: "Sri Venkata Sai Hp & General Finance", label: "Sri Venkata Sai Hp & General Finance" },
  { value: "Sri Venkata Sai Krishna Auto Finance", label: "Sri Venkata Sai Krishna Auto Finance" },
  { value: "Sri Venkata Sai Laxmi Auto & Employees Finance", label: "Sri Venkata Sai Laxmi Auto & Employees Finance" },
  { value: "Sri Venkata Sai Laxmi Auto Finance", label: "Sri Venkata Sai Laxmi Auto Finance" },
  { value: "Sri Venkata Satya Sai Finance", label: "Sri Venkata Satya Sai Finance" },
  { value: "Sri Venkata Seetha Ramanjaneya Auto Finance", label: "Sri Venkata Seetha Ramanjaneya Auto Finance" },
  { value: "Sri Venkata Siva A/F", label: "Sri Venkata Siva A/F" },
  { value: "Sri Venkata Siva Auto Mobile Finance", label: "Sri Venkata Siva Auto Mobile Finance" },
  { value: "Sri Venkata Surya Sri Finance", label: "Sri Venkata Surya Sri Finance" },
  { value: "Sri Venkata Varalaxmi Auto Finance", label: "Sri Venkata Varalaxmi Auto Finance" },
  { value: "Sri Venkatachalapathy Finance", label: "Sri Venkatachalapathy Finance" },
  { value: "Sri Venkatadri Financiers", label: "Sri Venkatadri Financiers" },
  { value: "Sri Venkatanaga Lakshmi A/F", label: "Sri Venkatanaga Lakshmi A/F" },
  { value: "Sri Venkatanagalakshmi A/F", label: "Sri Venkatanagalakshmi A/F" },
  { value: "Sri Venkatasiva A/F", label: "Sri Venkatasiva A/F" },
  { value: "Sri Venkatesa Auto Finance Ltd", label: "Sri Venkatesa Auto Finance Ltd" },
  { value: "Sri Venkatesa Auto Financiers", label: "Sri Venkatesa Auto Financiers" },
  { value: "Sri Venkatesh Finance", label: "Sri Venkatesh Finance" },
  { value: "Sri Venkateshwar Krupa Financiers", label: "Sri Venkateshwar Krupa Financiers" },
  { value: "Sri Venkateshwara Auto And General Finance", label: "Sri Venkateshwara Auto And General Finance" },
  { value: "Sri Venkateshwara Enter Prises", label: "Sri Venkateshwara Enter Prises" },
  { value: "Sri Venkateshwara Finance Corporation", label: "Sri Venkateshwara Finance Corporation" },
  { value: "Sri Venkateshwara Leasing", label: "Sri Venkateshwara Leasing" },
  { value: "Sri Venkateshwara Swamy Auto Finance", label: "Sri Venkateshwara Swamy Auto Finance" },
  { value: "Sri Venkateswara A/F", label: "Sri Venkateswara A/F" },
  { value: "Sri Venkateswara Auo Finance", label: "Sri Venkateswara Auo Finance" },
  { value: "Sri Venkateswara Auto Finance", label: "Sri Venkateswara Auto Finance" },
  { value: "Sri Venkateswara Auto Finance Corporation", label: "Sri Venkateswara Auto Finance Corporation" },
  { value: "Sri Venkateswara Automobile Finance", label: "Sri Venkateswara Automobile Finance" },
  { value: "Sri Venkateswara Credits", label: "Sri Venkateswara Credits" },
  { value: "Sri Venkateswara Enterprises", label: "Sri Venkateswara Enterprises" },
  { value: "Sri Venkateswara Investments", label: "Sri Venkateswara Investments" },
  { value: "Sri Venkateswara Lesing", label: "Sri Venkateswara Lesing" },
  { value: "Sri Venkateswara Motor Finance", label: "Sri Venkateswara Motor Finance" },
  { value: "Sri Venkteswara Hire Purchase", label: "Sri Venkteswara Hire Purchase" },
  { value: "Sri Venus Finance", label: "Sri Venus Finance" },
  { value: "Sri Vidya Finance", label: "Sri Vidya Finance" },
  { value: "Sri Vigneshwara Auto Finance", label: "Sri Vigneshwara Auto Finance" },
  { value: "Sri Vigneshwara Chits & Finance", label: "Sri Vigneshwara Chits & Finance" },
  { value: "Sri Vigneshwara Financiers", label: "Sri Vigneshwara Financiers" },
  { value: "Sri Vigneshwara Investments", label: "Sri Vigneshwara Investments" },
  { value: "Sri Vigneswara Auto Finance", label: "Sri Vigneswara Auto Finance" },
  { value: "Sri Vigneswara Leasing", label: "Sri Vigneswara Leasing" },
  { value: "Sri Vijaya Durga Finance (P) Ltd.", label: "Sri Vijaya Durga Finance (P) Ltd." },
  { value: "Sri Vijaya Lakshmi Venkateswar Auto Financiers", label: "Sri Vijaya Lakshmi Venkateswar Auto Financiers" },
  { value: "Sri Vijaya Sai Auto Financiers", label: "Sri Vijaya Sai Auto Financiers" },
  { value: "Sri Vijayalakshmi Auto Consultancy And Finance", label: "Sri Vijayalakshmi Auto Consultancy And Finance" },
  { value: "Sri Vijayalakshmi Auto Investment", label: "Sri Vijayalakshmi Auto Investment" },
  { value: "Sri Vijayaraj Sangameshwara Credit Co-Operative  Society Ltd", label: "Sri Vijayaraj Sangameshwara Credit Co-Operative  Society Ltd" },
  { value: "Sri Vijayaram Hire Purchase", label: "Sri Vijayaram Hire Purchase" },
  { value: "Sri Vijayaram Hire Purchase And Lsg Finance Pvt. Ltd.", label: "Sri Vijayaram Hire Purchase And Lsg Finance Pvt. Ltd." },
  { value: "Sri Vijayaraya Sangameshwara Credit Co-Operative Society(R)", label: "Sri Vijayaraya Sangameshwara Credit Co-Operative Society(R)" },
  { value: "Sri Vijayeswari Finance International Ltd", label: "Sri Vijayeswari Finance International Ltd" },
  { value: "Sri Vinayaga Finance", label: "Sri Vinayaga Finance" },
  { value: "Sri Vinayak Finance", label: "Sri Vinayak Finance" },
  { value: "Sri Vinayak Sowharda Credit Co-Op Society  Siddapur", label: "Sri Vinayak Sowharda Credit Co-Op Society  Siddapur" },
  { value: "Sri Vinayaka Associates", label: "Sri Vinayaka Associates" },
  { value: "Sri Vinyaaka Nagar Credit Co-Op Bank. Davangere", label: "Sri Vinyaaka Nagar Credit Co-Op Bank. Davangere" },
  { value: "Sri Virat Finance", label: "Sri Virat Finance" },
  { value: "Sri Virat Hp & Finance", label: "Sri Virat Hp & Finance" },
  { value: "Sri Visakha Grameena Bank", label: "Sri Visakha Grameena Bank" },
  { value: "Sri Vishnu Finance", label: "Sri Vishnu Finance" },
  { value: "Sri Vishnu Motors", label: "Sri Vishnu Motors" },
  { value: "Sri Vishnu Priya Finance", label: "Sri Vishnu Priya Finance" },
  { value: "Sri Vishnulakshmi Fiance", label: "Sri Vishnulakshmi Fiance" },
  { value: "Sri Vishwabandhu Credit Co-Op Society", label: "Sri Vishwabandhu Credit Co-Op Society" },
  { value: "Sri Vishweshwaraya Urban Co-Op Credit Society Ltd", label: "Sri Vishweshwaraya Urban Co-Op Credit Society Ltd" },
  { value: "Sri Visweswara Finance Corporation", label: "Sri Visweswara Finance Corporation" },
  { value: "Sri Visweswra Hire Purchase Company", label: "Sri Visweswra Hire Purchase Company" },
  { value: "Sri Vittal Souharda Credit Co Op Ltd", label: "Sri Vittal Souharda Credit Co Op Ltd" },
  { value: "Sri Vyankatesh Co-Op Bank Ltd", label: "Sri Vyankatesh Co-Op Bank Ltd" },
  { value: "Sri Yogambiga Auto Financier", label: "Sri Yogambiga Auto Financier" },
  { value: "Sridevi Finance", label: "Sridevi Finance" },
  { value: "Srie Infrastructure Finance Ltd.", label: "Srie Infrastructure Finance Ltd." },
  { value: "Sriguru Ramalingeswar Pallin Sah.San.Niy", label: "Sriguru Ramalingeswar Pallin Sah.San.Niy" },
  { value: "Srijan Mahila Vikas Sahyog Samiti.", label: "Srijan Mahila Vikas Sahyog Samiti." },
  { value: "Srijan Mahila Vikash Shayog Samiti Ltd,", label: "Srijan Mahila Vikash Shayog Samiti Ltd," },
  { value: "Srikakula Dist Co-Op Cen Bank Ltd,", label: "Srikakula Dist Co-Op Cen Bank Ltd," },
  { value: "Srikakulam District Central Co-Operative Bank Ltd.", label: "Srikakulam District Central Co-Operative Bank Ltd." },
  { value: "Srikalahasti Co-Op Bank Ltd", label: "Srikalahasti Co-Op Bank Ltd" },
  { value: "Srikanth Finance Corporation", label: "Srikanth Finance Corporation" },
  { value: "Srikrishna Fin And Leasing Co", label: "Srikrishna Fin And Leasing Co" },
  { value: "Srikrishnapuram Services Co-Op Bank Ltd", label: "Srikrishnapuram Services Co-Op Bank Ltd" },
  { value: "Srilakshmi Varadurga A/F", label: "Srilakshmi Varadurga A/F" },
  { value: "Srilaxmi Venkateshwara Auto Finance", label: "Srilaxmi Venkateshwara Auto Finance" },
  { value: "Srimangala Vyavasay Seva-Sah. Bk L.", label: "Srimangala Vyavasay Seva-Sah. Bk L." },
  { value: "Srinath Finance", label: "Srinath Finance" },
  { value: "Srindhi Credit Co-Operative Society Ltd", label: "Srindhi Credit Co-Operative Society Ltd" },
  { value: "Srinidhi Finance", label: "Srinidhi Finance" },
  { value: "Srinidhi Leasing ( R )", label: "Srinidhi Leasing ( R )" },
  { value: "Srinidhi Sahakara", label: "Srinidhi Sahakara" },
  { value: "Sriniketan Finance Ltd", label: "Sriniketan Finance Ltd" },
  { value: "Sriniketana Finance Company", label: "Sriniketana Finance Company" },
  { value: "Srinikethah Finance Co.", label: "Srinikethah Finance Co." },
  { value: "Srinikethan Fainance Corporation", label: "Srinikethan Fainance Corporation" },
  { value: "Srinikethana Finance Co", label: "Srinikethana Finance Co" },
  { value: "Srinivasa A/F", label: "Srinivasa A/F" },
  { value: "Srinivasa Auto Finance", label: "Srinivasa Auto Finance" },
  { value: "Srinivasa Auto Finance Co", label: "Srinivasa Auto Finance Co" },
  { value: "Srinivasa Automobile Finance", label: "Srinivasa Automobile Finance" },
  { value: "Srinivasa Finance", label: "Srinivasa Finance" },
  { value: "Srinivasa Finance Company Limited", label: "Srinivasa Finance Company Limited" },
  { value: "Srinivasa Financiers", label: "Srinivasa Financiers" },
  { value: "Srinivasa Sai Finance", label: "Srinivasa Sai Finance" },
  { value: "Srinivasan Financier", label: "Srinivasan Financier" },
  { value: "Sripal Sethia", label: "Sripal Sethia" },
  { value: "Sripal V Kothari", label: "Sripal V Kothari" },
  { value: "Sripujitha Hp & Finance", label: "Sripujitha Hp & Finance" },
  { value: "Sriraj Financial Services", label: "Sriraj Financial Services" },
  { value: "Sriram", label: "Sriram" },
  { value: "Sriram  Transport Company Ltd.", label: "Sriram  Transport Company Ltd." },
  { value: "Sriram Citi Finance  Ltd", label: "Sriram Citi Finance  Ltd" },
  { value: "Sriram City Union Bank", label: "Sriram City Union Bank" },
  { value: "Sriram City Union Finance Co Ltd", label: "Sriram City Union Finance Co Ltd" },
  { value: "Sriram City Union Finance Ltd - Branch Kumta", label: "Sriram City Union Finance Ltd - Branch Kumta" },
  { value: "Sriram City Union Ltd", label: "Sriram City Union Ltd" },
  { value: "Sriram Finance", label: "Sriram Finance" },
  { value: "Sriram Finance Co Ltd", label: "Sriram Finance Co Ltd" },
  { value: "Sriram Finance Pvt Ltd", label: "Sriram Finance Pvt Ltd" },
  { value: "Sriram Finance Transport P Ltd", label: "Sriram Finance Transport P Ltd" },
  { value: "Sriram Nagari Co Op Society Ltd", label: "Sriram Nagari Co Op Society Ltd" },
  { value: "Sriram Trade Finance Co Ltd", label: "Sriram Trade Finance Co Ltd" },
  { value: "Sriram Transport Company", label: "Sriram Transport Company" },
  { value: "Sriram Transport Corporation", label: "Sriram Transport Corporation" },
  { value: "Sriram Transport Finance Co. Ltd.", label: "Sriram Transport Finance Co. Ltd." },
  { value: "Sriram Union Finance Ltd", label: "Sriram Union Finance Ltd" },
  { value: "Srirama Chits", label: "Srirama Chits" },
  { value: "Srirama Chits Union Finance Ltd", label: "Srirama Chits Union Finance Ltd" },
  { value: "Srirama Grameena Bank, Ranjarala", label: "Srirama Grameena Bank, Ranjarala" },
  { value: "Sriramakrishna Credit Co Op Society Ltd", label: "Sriramakrishna Credit Co Op Society Ltd" },
  { value: "Sriramanagar Pattana Sahakara Bk Niyamit", label: "Sriramanagar Pattana Sahakara Bk Niyamit" },
  { value: "Sriramayakrishna Credit Co-Op Society Ltd - Surathkal", label: "Sriramayakrishna Credit Co-Op Society Ltd - Surathkal" },
  { value: "Srisharada Credit Co-Op Society Ltd.", label: "Srisharada Credit Co-Op Society Ltd." },
  { value: "Srishti Motor", label: "Srishti Motor" },
  { value: "Srivadivelan Finance", label: "Srivadivelan Finance" },
  { value: "Srivaru Auto Investments ( R )", label: "Srivaru Auto Investments ( R )" },
  { value: "Srivelavaa Auto Finances", label: "Srivelavaa Auto Finances" },
  { value: "Srivihayamahantesh Co-Op Bank Ltd", label: "Srivihayamahantesh Co-Op Bank Ltd" },
  { value: "Srp Hi-Pur Finance", label: "Srp Hi-Pur Finance" },
  { value: "Srta", label: "Srta" },
  { value: "Ss Motors", label: "Ss Motors" },
  { value: "Ssbp Ssni Karatagi", label: "Ssbp Ssni Karatagi" },
  { value: "Ssifsl Limited", label: "Ssifsl Limited" },
  { value: "Ssmb Finance & Investments Pvt Ltd", label: "Ssmb Finance & Investments Pvt Ltd" },
  { value: "Ssppss Niyamita", label: "Ssppss Niyamita" },
  { value: "St Antonys Motors India Ltd", label: "St Antonys Motors India Ltd" },
  { value: "St Milagres Co-Operative Credit Society Ltd", label: "St Milagres Co-Operative Credit Society Ltd" },
  { value: "St Milagrise Co-Op Credit Society Ltd", label: "St Milagrise Co-Op Credit Society Ltd" },
  { value: "St. Joseph Co-Op Credit Soc Ltd", label: "St. Joseph Co-Op Credit Soc Ltd" },
  { value: "Staff Selection Commission", label: "Staff Selection Commission" },
  { value: "Standard B Arsekar", label: "Standard B Arsekar" },
  { value: "Standard Charted Bank Ltd", label: "Standard Charted Bank Ltd" },
  { value: "Standard Charted Financial", label: "Standard Charted Financial" },
  { value: "Standard Charterde Bank", label: "Standard Charterde Bank" },
  { value: "Standard Chartered Grindlays Bank", label: "Standard Chartered Grindlays Bank" },
  { value: "Standard Co-Op. Bank Ltd", label: "Standard Co-Op. Bank Ltd" },
  { value: "Standard Co-Op. Bank Ltd., Ahmedabad", label: "Standard Co-Op. Bank Ltd., Ahmedabad" },
  { value: "Standard Credit Co-Operative Society Ltd", label: "Standard Credit Co-Operative Society Ltd" },
  { value: "Standard Finance Company", label: "Standard Finance Company" },
  { value: "Standard Finance Pvt. Ltd.", label: "Standard Finance Pvt. Ltd." },
  { value: "Standardisation, Testing And Quality Cerification", label: "Standardisation, Testing And Quality Cerification" },
  { value: "Standared Financial Services", label: "Standared Financial Services" },
  { value: "Standerd Chartered Bank Ltd.", label: "Standerd Chartered Bank Ltd." },
  { value: "Stanrose Mafatlal Investments And Finance Ltd.", label: "Stanrose Mafatlal Investments And Finance Ltd." },
  { value: "Star Associates", label: "Star Associates" },
  { value: "Star Auto Consult", label: "Star Auto Consult" },
  { value: "Star Auto Finanier", label: "Star Auto Finanier" },
  { value: "Star Finance Corporation", label: "Star Finance Corporation" },
  { value: "Star Instalment Supply Co. Ltd.", label: "Star Instalment Supply Co. Ltd." },
  { value: "Star Insurance Services", label: "Star Insurance Services" },
  { value: "Star Line Travels", label: "Star Line Travels" },
  { value: "Star Motors", label: "Star Motors" },
  { value: "State Apex Co-Op.Bank Ltd.", label: "State Apex Co-Op.Bank Ltd." },
  { value: "State Bank Ltd", label: "State Bank Ltd" },
  { value: "State Bank Of  Travancore", label: "State Bank Of  Travancore" },
  { value: "State Bank Of Barauda", label: "State Bank Of Barauda" },
  { value: "State Bank Of Bikaner", label: "State Bank Of Bikaner" },
  { value: "State Bank Of Bikaner & Jaipur,Barodiya", label: "State Bank Of Bikaner & Jaipur,Barodiya" },
  { value: "State Bank Of Hyderabad Agriculture Development Bank", label: "State Bank Of Hyderabad Agriculture Development Bank" },
  { value: "State Bank Of Hyderabd, Tandur (Adb)", label: "State Bank Of Hyderabd, Tandur (Adb)" },
  { value: "State Bank Of Indi", label: "State Bank Of Indi" },
  { value: "State Bank Of India - Bhatkal", label: "State Bank Of India - Bhatkal" },
  { value: "State Bank Of India(Adb)", label: "State Bank Of India(Adb)" },
  { value: "State Bank Of India, Commercial Branch - Via", label: "State Bank Of India, Commercial Branch - Via" },
  { value: "State Bank Of India, Nandesari,", label: "State Bank Of India, Nandesari," },
  { value: "State Bank Of Indian , Ashoknagar (M P)", label: "State Bank Of Indian , Ashoknagar (M P)" },
  { value: "State Bank Of Madura", label: "State Bank Of Madura" },
  { value: "State Bank Of Maharashtra", label: "State Bank Of Maharashtra" },
  { value: "State Bank Of Mauritius Ltd.", label: "State Bank Of Mauritius Ltd." },
  { value: "State Bank Of Misurs", label: "State Bank Of Misurs" },
  { value: "State Bank Of Mysore - Bellary", label: "State Bank Of Mysore - Bellary" },
  { value: "State Bank Of Mysore - Mundgod", label: "State Bank Of Mysore - Mundgod" },
  { value: "State Bank Of Mysore Dandeli", label: "State Bank Of Mysore Dandeli" },
  { value: "State Bank Of Nandyal", label: "State Bank Of Nandyal" },
  { value: "State Bank Of Nellore", label: "State Bank Of Nellore" },
  { value: "State Bank Of Punjab", label: "State Bank Of Punjab" },
  { value: "State Bank Of Rajasthan Ltd", label: "State Bank Of Rajasthan Ltd" },
  { value: "State Bank Or Hyderabad", label: "State Bank Or Hyderabad" },
  { value: "State Co-Op Agriculture Development Bank", label: "State Co-Op Agriculture Development Bank" },
  { value: "State Co-Operative Bank", label: "State Co-Operative Bank" },
  { value: "State Council For Science, Technology And Environment", label: "State Council For Science, Technology And Environment" },
  { value: "State Dept. Credit Union", label: "State Dept. Credit Union" },
  { value: "State Farms Corporation Of India Ltd.", label: "State Farms Corporation Of India Ltd." },
  { value: "State Institute Of Education Technology", label: "State Institute Of Education Technology" },
  { value: "State Institute Of Public Administration And Rural Development", label: "State Institute Of Public Administration And Rural Development" },
  { value: "State Trading Corporation Of India Ltd.", label: "State Trading Corporation Of India Ltd." },
  { value: "State Transport Co-Op. Bank Ltd., Mumbai", label: "State Transport Co-Op. Bank Ltd., Mumbai" },
  { value: "State Transport Co-Operative Bank Ltd.", label: "State Transport Co-Operative Bank Ltd." },
  { value: "State Trieal Devl Ltd", label: "State Trieal Devl Ltd" },
  { value: "Steel Authority Of India Limited", label: "Steel Authority Of India Limited" },
  { value: "Stellar Insurance", label: "Stellar Insurance" },
  { value: "Sterling Motors", label: "Sterling Motors" },
  { value: "Sterling Urban Co-Op Bank Ltd.", label: "Sterling Urban Co-Op Bank Ltd." },
  { value: "Sterling Urban Co-Op. Bank Ltd., Jaipur", label: "Sterling Urban Co-Op. Bank Ltd., Jaipur" },
  { value: "Stfc", label: "Stfc" },
  { value: "Stfc Chennai", label: "Stfc Chennai" },
  { value: "Stic Marketing Pvt Ltd", label: "Stic Marketing Pvt Ltd" },
  { value: "Stic Synthetics Pvt Ltd", label: "Stic Synthetics Pvt Ltd" },
  { value: "Stock Holding Corporation Of India Ltd", label: "Stock Holding Corporation Of India Ltd" },
  { value: "Structural Engineering Research Centre", label: "Structural Engineering Research Centre" },
  { value: "Stya Sai Auto & General Finance", label: "Stya Sai Auto & General Finance" },
  { value: "Su Teja Sahakari Pat San", label: "Su Teja Sahakari Pat San" },
  { value: "Sub Divisional Agni Off", label: "Sub Divisional Agni Off" },
  { value: "Subalays Finance.", label: "Subalays Finance." },
  { value: "Subansiri Gaonlia Bank", label: "Subansiri Gaonlia Bank" },
  { value: "Subash And Co", label: "Subash And Co" },
  { value: "Subash Auto Finance", label: "Subash Auto Finance" },
  { value: "Subash Finance", label: "Subash Finance" },
  { value: "Subdaram Finance Ltd.", label: "Subdaram Finance Ltd." },
  { value: "Subh Fincap", label: "Subh Fincap" },
  { value: "Subha Mankalaa Finance", label: "Subha Mankalaa Finance" },
  { value: "Subhadra Local Area Bank Ltd.", label: "Subhadra Local Area Bank Ltd." },
  { value: "Subham Auto Finance", label: "Subham Auto Finance" },
  { value: "Subham Investment", label: "Subham Investment" },
  { value: "Subham Syndicate", label: "Subham Syndicate" },
  { value: "Subhamangalaa Finance", label: "Subhamangalaa Finance" },
  { value: "Subhash Auto Finance Co.", label: "Subhash Auto Finance Co." },
  { value: "Subhash Automobiles", label: "Subhash Automobiles" },
  { value: "Subhash Chandra Choraria Huf", label: "Subhash Chandra Choraria Huf" },
  { value: "Subhash Finance Company", label: "Subhash Finance Company" },
  { value: "Subhash Financers", label: "Subhash Financers" },
  { value: "Subhash Motor Finance", label: "Subhash Motor Finance" },
  { value: "Subidha Broker Insurance", label: "Subidha Broker Insurance" },
  { value: "Subidha Nagari Path Sansatha Ltd", label: "Subidha Nagari Path Sansatha Ltd" },
  { value: "Subitha Leasing Co Ltd", label: "Subitha Leasing Co Ltd" },
  { value: "Subobh Finance", label: "Subobh Finance" },
  { value: "Subodh Finance", label: "Subodh Finance" },
  { value: "Subodh Sahakari Path Sanstha Maryadit", label: "Subodh Sahakari Path Sanstha Maryadit" },
  { value: "Subody Finance", label: "Subody Finance" },
  { value: "Subramaniya Siva Co,", label: "Subramaniya Siva Co," },
  { value: "Subramanyeswara Co-Operative Bank Ltd", label: "Subramanyeswara Co-Operative Bank Ltd" },
  { value: "Subrat Sharma", label: "Subrat Sharma" },
  { value: "Sucharitha Lea Fin & Chits", label: "Sucharitha Lea Fin & Chits" },
  { value: "Sucharma Finance", label: "Sucharma Finance" },
  { value: "Suco Bank", label: "Suco Bank" },
  { value: "Sudakshan Motor Fin. Co.", label: "Sudakshan Motor Fin. Co." },
  { value: "Sudarashan Finance", label: "Sudarashan Finance" },
  { value: "Sudarshan Auto And General Finance Pvt Ltd", label: "Sudarshan Auto And General Finance Pvt Ltd" },
  { value: "Sudarshan Khobiya Sowcarpet", label: "Sudarshan Khobiya Sowcarpet" },
  { value: "Sudarshan Lease Finance", label: "Sudarshan Lease Finance" },
  { value: "Sudarshan Motor Investment Co", label: "Sudarshan Motor Investment Co" },
  { value: "Sudarshan Nag Sah Pat Sanstha Mydt", label: "Sudarshan Nag Sah Pat Sanstha Mydt" },
  { value: "Sudeer Chand", label: "Sudeer Chand" },
  { value: "Sudershan Hire Purchase Pvt Ltd", label: "Sudershan Hire Purchase Pvt Ltd" },
  { value: "Sudershan Motor Investment Co.", label: "Sudershan Motor Investment Co." },
  { value: "Sudershan Nag Sah Pat Sanstha Mydt", label: "Sudershan Nag Sah Pat Sanstha Mydt" },
  { value: "Sudershan Nag Sah Pat Sanstha Mydt", label: "Sudershan Nag Sah Pat Sanstha Mydt" },
  { value: "Suderson Finance", label: "Suderson Finance" },
  { value: "Sudha Automobile Financiers", label: "Sudha Automobile Financiers" },
  { value: "Sudha Coopertive Urban Bank Ltd", label: "Sudha Coopertive Urban Bank Ltd" },
  { value: "Sudha Finance", label: "Sudha Finance" },
  { value: "Sudharlal Swagi Urban Co Op Bank", label: "Sudharlal Swagi Urban Co Op Bank" },
  { value: "Sudharma Finance", label: "Sudharma Finance" },
  { value: "Sudharson Finance", label: "Sudharson Finance" },
  { value: "Sugal And Damani Finlease Ltd.", label: "Sugal And Damani Finlease Ltd." },
  { value: "Sugal Finance Ltd.", label: "Sugal Finance Ltd." },
  { value: "Sugan Chand Bothra", label: "Sugan Chand Bothra" },
  { value: "Sugan Investments", label: "Sugan Investments" },
  { value: "Suganchand Bardia Sons", label: "Suganchand Bardia Sons" },
  { value: "Sugandhi Automobiles", label: "Sugandhi Automobiles" },
  { value: "Sugat Sahakari Patsanstha  Maryadit Ltd.", label: "Sugat Sahakari Patsanstha  Maryadit Ltd." },
  { value: "Sugat Sahakari Patsanstha Ltd.", label: "Sugat Sahakari Patsanstha Ltd." },
  { value: "Sugatur S.F.S.C.Bank Ltd,", label: "Sugatur S.F.S.C.Bank Ltd," },
  { value: "Sugawaneshwara Financiers", label: "Sugawaneshwara Financiers" },
  { value: "Suguna Auto Finance", label: "Suguna Auto Finance" },
  { value: "Suguna Finance", label: "Suguna Finance" },
  { value: "Suhani Mahila Nagari Sahakari Pat Sanstha Ltd.", label: "Suhani Mahila Nagari Sahakari Pat Sanstha Ltd." },
  { value: "Suhel Finance Company", label: "Suhel Finance Company" },
  { value: "Suhishel Investments Pvt. Ltd.", label: "Suhishel Investments Pvt. Ltd." },
  { value: "Sujal Investment", label: "Sujal Investment" },
  { value: "Sujana Credit Corporation", label: "Sujana Credit Corporation" },
  { value: "Sujatha Investments", label: "Sujatha Investments" },
  { value: "Sujatha P", label: "Sujatha P" },
  { value: "Sujinraj Jain Finance", label: "Sujinraj Jain Finance" },
  { value: "Sujit Bhawal", label: "Sujit Bhawal" },
  { value: "Sujith A S", label: "Sujith A S" },
  { value: "Sukan Lize Finance", label: "Sukan Lize Finance" },
  { value: "Sukh Auto Centre", label: "Sukh Auto Centre" },
  { value: "Sukh Chain Finance Company Pvt Ltd", label: "Sukh Chain Finance Company Pvt Ltd" },
  { value: "Sukhbans Finance Co. Pvt. Ltd.", label: "Sukhbans Finance Co. Pvt. Ltd." },
  { value: "Sukhdam Financers", label: "Sukhdam Financers" },
  { value: "Sukhdham Financiers", label: "Sukhdham Financiers" },
  { value: "Sukhjit Finance Ltd", label: "Sukhjit Finance Ltd" },
  { value: "Sukhmani Enterprises", label: "Sukhmani Enterprises" },
  { value: "Sukhmani Motors", label: "Sukhmani Motors" },
  { value: "Sukkraa Finance", label: "Sukkraa Finance" },
  { value: "Sulaimani Co-Op Banking Society Ltd.", label: "Sulaimani Co-Op Banking Society Ltd." },
  { value: "Sulaimani Co.Op. Bank Ltd", label: "Sulaimani Co.Op. Bank Ltd" },
  { value: "Sulamani Co-Operative Bank Ltd.", label: "Sulamani Co-Operative Bank Ltd." },
  { value: "Sulkerimogru Sahakari Vyavasayika Bk Ltd", label: "Sulkerimogru Sahakari Vyavasayika Bk Ltd" },
  { value: "Sullanpur Jilla Sahakari Bank Ltd", label: "Sullanpur Jilla Sahakari Bank Ltd" },
  { value: "Sultan Bathery Thaluk Vyapari V.C.S", label: "Sultan Bathery Thaluk Vyapari V.C.S" },
  { value: "Sultanpur Zila Sahkari Bank Ltd.", label: "Sultanpur Zila Sahkari Bank Ltd." },
  { value: "Sultans Battery Co-Op Bank Ltd", label: "Sultans Battery Co-Op Bank Ltd" },
  { value: "Suma Priya Finance", label: "Suma Priya Finance" },
  { value: "Suman Associates", label: "Suman Associates" },
  { value: "Suman Co-Op Cr. Soc Ltd", label: "Suman Co-Op Cr. Soc Ltd" },
  { value: "Suman Finance", label: "Suman Finance" },
  { value: "Suman Sumit Jain", label: "Suman Sumit Jain" },
  { value: "Suman Syndicate", label: "Suman Syndicate" },
  { value: "Sumangal Enterprise", label: "Sumangal Enterprise" },
  { value: "Sumangal Enterprises", label: "Sumangal Enterprises" },
  { value: "Sumangal Finance", label: "Sumangal Finance" },
  { value: "Sumangal Finance & Investment", label: "Sumangal Finance & Investment" },
  { value: "Sumangal Fort Polio Pvt Ltd", label: "Sumangal Fort Polio Pvt Ltd" },
  { value: "Sumangal Port Polio Pvt Ltd", label: "Sumangal Port Polio Pvt Ltd" },
  { value: "Sumangali Finance And Investments", label: "Sumangali Finance And Investments" },
  { value: "Sumarmal Velumal & Sons", label: "Sumarmal Velumal & Sons" },
  { value: "Sumask Finance", label: "Sumask Finance" },
  { value: "Sumeet Investment", label: "Sumeet Investment" },
  { value: "Sumeet Investment Finance", label: "Sumeet Investment Finance" },
  { value: "Sumeet Motors", label: "Sumeet Motors" },
  { value: "Sumeet Tractors", label: "Sumeet Tractors" },
  { value: "Sumeetha P P", label: "Sumeetha P P" },
  { value: "Sumer Chand Mutha Fianancier", label: "Sumer Chand Mutha Fianancier" },
  { value: "Sumerchand Dungarwala Finance Ltd", label: "Sumerchand Dungarwala Finance Ltd" },
  { value: "Sumerpur Mer.Urban Co-Op Bank Ltd.", label: "Sumerpur Mer.Urban Co-Op Bank Ltd." },
  { value: "Sumesh .K.C", label: "Sumesh .K.C" },
  { value: "Sumesh Parakh", label: "Sumesh Parakh" },
  { value: "Sumit Auto Deal", label: "Sumit Auto Deal" },
  { value: "Sumit Enterprises", label: "Sumit Enterprises" },
  { value: "Sumit Finance", label: "Sumit Finance" },
  { value: "Sumit Jain", label: "Sumit Jain" },
  { value: "Sumit Motor Finance", label: "Sumit Motor Finance" },
  { value: "Sumit Sahakari Path Sanstha Ltd", label: "Sumit Sahakari Path Sanstha Ltd" },
  { value: "Sumitha Bai", label: "Sumitha Bai" },
  { value: "Sumitomo Mitsui Banking Corporation.", label: "Sumitomo Mitsui Banking Corporation." },
  { value: "Sumitomo Mitsui Banking Corporation", label: "Sumitomo Mitsui Banking Corporation" },
  { value: "Sumitra Finance", label: "Sumitra Finance" },
  { value: "Sumitra Gramin Biger Sheti Sah Pat San Mar", label: "Sumitra Gramin Biger Sheti Sah Pat San Mar" },
  { value: "Sumitra Patsanstha", label: "Sumitra Patsanstha" },
  { value: "Sumitra Traders", label: "Sumitra Traders" },
  { value: "Sun Auto Finance", label: "Sun Auto Finance" },
  { value: "Sun Auto Finance", label: "Sun Auto Finance" },
  { value: "Sun Enterprise", label: "Sun Enterprise" },
  { value: "Sun India Finance", label: "Sun India Finance" },
  { value: "Sunadholi Mahila Credit Souharda Sahakari Niyamit", label: "Sunadholi Mahila Credit Souharda Sahakari Niyamit" },
  { value: "Sunaina Finance & Credit Corporation", label: "Sunaina Finance & Credit Corporation" },
  { value: "Sunanda Devi Surana", label: "Sunanda Devi Surana" },
  { value: "Sunask Finance Corportion", label: "Sunask Finance Corportion" },
  { value: "Sunask Finance Limited", label: "Sunask Finance Limited" },
  { value: "Sunauto Finance", label: "Sunauto Finance" },
  { value: "Sunav Nagarik Sahakari Bank Ltd", label: "Sunav Nagarik Sahakari Bank Ltd" },
  { value: "Sunayana Finance", label: "Sunayana Finance" },
  { value: "Sunayana Finance & Credit Corporation", label: "Sunayana Finance & Credit Corporation" },
  { value: "Sundar Dev Dugar", label: "Sundar Dev Dugar" },
  { value: "Sundar Finance", label: "Sundar Finance" },
  { value: "Sundara Finance Corprate Automobil Financies", label: "Sundara Finance Corprate Automobil Financies" },
  { value: "Sundaram  Finance Ltd", label: "Sundaram  Finance Ltd" },
  { value: "Sundaram Auto Finance Ltd.", label: "Sundaram Auto Finance Ltd." },
  { value: "Sundaram Bnp Paribas Finance", label: "Sundaram Bnp Paribas Finance" },
  { value: "Sundaram City Union Finance", label: "Sundaram City Union Finance" },
  { value: "Sundaram Enterprise", label: "Sundaram Enterprise" },
  { value: "Sundaram Finance", label: "Sundaram Finance" },
  { value: "Sundaram Finance Co. Ltd.", label: "Sundaram Finance Co. Ltd." },
  { value: "Sundaram Finance Ltd-Udupi", label: "Sundaram Finance Ltd-Udupi" },
  { value: "Sundaram Finance Ltd.", label: "Sundaram Finance Ltd." },
  { value: "Sundaram Finance Services Ltd.", label: "Sundaram Finance Services Ltd." },
  { value: "Sundaray Finance Ltd", label: "Sundaray Finance Ltd" },
  { value: "Sundargarh District Central Co-Operative Bank Ltd.", label: "Sundargarh District Central Co-Operative Bank Ltd." },
  { value: "Sundarlal Sahuji Co Op Bank Ltd Br Nanded", label: "Sundarlal Sahuji Co Op Bank Ltd Br Nanded" },
  { value: "Sundarlal Sawajan Co. Op. Bank", label: "Sundarlal Sawajan Co. Op. Bank" },
  { value: "Sundarlal Sawji Urban Co-Op Bank Ltd", label: "Sundarlal Sawji Urban Co-Op Bank Ltd" },
  { value: "Sundarlal Sawji Urban Cooprative Bank", label: "Sundarlal Sawji Urban Cooprative Bank" },
  { value: "Sunder Darsan Finance Co. Ltd.", label: "Sunder Darsan Finance Co. Ltd." },
  { value: "Sunder Devi Dugar", label: "Sunder Devi Dugar" },
  { value: "Sunderlal Savji Urban Co Op Bank Ltd", label: "Sunderlal Savji Urban Co Op Bank Ltd" },
  { value: "Sundram Auto Finance Ltd", label: "Sundram Auto Finance Ltd" },
  { value: "Sundram Finanace", label: "Sundram Finanace" },
  { value: "Sundram Finance Ltd", label: "Sundram Finance Ltd" },
  { value: "Sundram Financial Ltd", label: "Sundram Financial Ltd" },
  { value: "Sundram Motors", label: "Sundram Motors" },
  { value: "Sundram Motors", label: "Sundram Motors" },
  { value: "Sundram Tinang", label: "Sundram Tinang" },
  { value: "Sundran Finance", label: "Sundran Finance" },
  { value: "Suneet Finman Pvt Ltd", label: "Suneet Finman Pvt Ltd" },
  { value: "Suneet Finman Pvt. Ltd.", label: "Suneet Finman Pvt. Ltd." },
  { value: "Suneeta Devi Dhariwal", label: "Suneeta Devi Dhariwal" },
  { value: "Sungam Investments", label: "Sungam Investments" },
  { value: "Sunil & Co.", label: "Sunil & Co." },
  { value: "Sunil A S", label: "Sunil A S" },
  { value: "Sunil And Company", label: "Sunil And Company" },
  { value: "Sunil Auto Finance Co", label: "Sunil Auto Finance Co" },
  { value: "Sunil Enterprises", label: "Sunil Enterprises" },
  { value: "Sunil Finance", label: "Sunil Finance" },
  { value: "Sunil Hi - Tech Engineering Ltd", label: "Sunil Hi - Tech Engineering Ltd" },
  { value: "Sunil Investment", label: "Sunil Investment" },
  { value: "Sunil Karnawat", label: "Sunil Karnawat" },
  { value: "Sunil Kashiramji Patil", label: "Sunil Kashiramji Patil" },
  { value: "Sunil Kumar", label: "Sunil Kumar" },
  { value: "Sunil Kumar Chelani", label: "Sunil Kumar Chelani" },
  { value: "Sunil Kumar Jain", label: "Sunil Kumar Jain" },
  { value: "Sunil Kumar Sankala", label: "Sunil Kumar Sankala" },
  { value: "Sunil Madhyani", label: "Sunil Madhyani" },
  { value: "Sunil Motor Finance", label: "Sunil Motor Finance" },
  { value: "Sunil Scooters Pvt Ltd", label: "Sunil Scooters Pvt Ltd" },
  { value: "Sunilkumar Sankala Finance", label: "Sunilkumar Sankala Finance" },
  { value: "Sunita Chaplot", label: "Sunita Chaplot" },
  { value: "Sunita Devi", label: "Sunita Devi" },
  { value: "Sunita Investment", label: "Sunita Investment" },
  { value: "Sunita Sharma", label: "Sunita Sharma" },
  { value: "Sunith Roath", label: "Sunith Roath" },
  { value: "Sunitha Jain", label: "Sunitha Jain" },
  { value: "Sunitha Jain Automobile Financers", label: "Sunitha Jain Automobile Financers" },
  { value: "Sunitha Rathod", label: "Sunitha Rathod" },
  { value: "Sunitha Rathod", label: "Sunitha Rathod" },
  { value: "Sunitha Rotha", label: "Sunitha Rotha" },
  { value: "Sunitha Sisodia", label: "Sunitha Sisodia" },
  { value: "Sunkara Lakshmi Kumari Financiers", label: "Sunkara Lakshmi Kumari Financiers" },
  { value: "Sunkara Ramesh Babu", label: "Sunkara Ramesh Babu" },
  { value: "Sunlight Finance Company", label: "Sunlight Finance Company" },
  { value: "Sunmia Finance", label: "Sunmia Finance" },
  { value: "Sunny Finance Auto Deal", label: "Sunny Finance Auto Deal" },
  { value: "Sunny Jethnand Makhija", label: "Sunny Jethnand Makhija" },
  { value: "Sunny Motor Finance Co", label: "Sunny Motor Finance Co" },
  { value: "Sunrise Auto Pvt Ltd", label: "Sunrise Auto Pvt Ltd" },
  { value: "Sunrise Investments", label: "Sunrise Investments" },
  { value: "Sunshine Auto Enterprises", label: "Sunshine Auto Enterprises" },
  { value: "Super Auto Center", label: "Super Auto Center" },
  { value: "Super Auto Finance & Co", label: "Super Auto Finance & Co" },
  { value: "Super Finance", label: "Super Finance" },
  { value: "Super Finance &Investments Corporation", label: "Super Finance &Investments Corporation" },
  { value: "Super Finance (Cbe) Ltd.", label: "Super Finance (Cbe) Ltd." },
  { value: "Super Hire Purchasing Co", label: "Super Hire Purchasing Co" },
  { value: "Super Investments", label: "Super Investments" },
  { value: "Superior Investments", label: "Superior Investments" },
  { value: "Supra Finance Co Ltd", label: "Supra Finance Co Ltd" },
  { value: "Suprabha Sahakari Pathasanstha Maryadit", label: "Suprabha Sahakari Pathasanstha Maryadit" },
  { value: "Suprabhat Finance Company", label: "Suprabhat Finance Company" },
  { value: "Suprabhat Fincon  Private", label: "Suprabhat Fincon  Private" },
  { value: "Suprabhata Credit Co Op Society Ltd.", label: "Suprabhata Credit Co Op Society Ltd." },
  { value: "Supreet Finance Co", label: "Supreet Finance Co" },
  { value: "Supreme Auto Care", label: "Supreme Auto Care" },
  { value: "Supreme Court Of India", label: "Supreme Court Of India" },
  { value: "Supreme Financial Services", label: "Supreme Financial Services" },
  { value: "Suprenig Engg Cedc Metro Tneb", label: "Suprenig Engg Cedc Metro Tneb" },
  { value: "Supriya Enterprise,Surat", label: "Supriya Enterprise,Surat" },
  { value: "Supriya Enterprises", label: "Supriya Enterprises" },
  { value: "Surabhi And Co Investments", label: "Surabhi And Co Investments" },
  { value: "Surabhi Corporation", label: "Surabhi Corporation" },
  { value: "Surabhi Hire Purchasing Co Pvt Ltd", label: "Surabhi Hire Purchasing Co Pvt Ltd" },
  { value: "Surainder Cloth House", label: "Surainder Cloth House" },
  { value: "Suraj  Jain", label: "Suraj  Jain" },
  { value: "Suraj Co Op Credit Society", label: "Suraj Co Op Credit Society" },
  { value: "Suraj Enterprises", label: "Suraj Enterprises" },
  { value: "Suraj Finance", label: "Suraj Finance" },
  { value: "Suraj Finance Corporation", label: "Suraj Finance Corporation" },
  { value: "Suraj Hire Purchase Af", label: "Suraj Hire Purchase Af" },
  { value: "Suraj Kawar", label: "Suraj Kawar" },
  { value: "Suraksha Auto Deals", label: "Suraksha Auto Deals" },
  { value: "Suraksha Hire Purchase", label: "Suraksha Hire Purchase" },
  { value: "Suraksha Vividoddeshagala Sahakari Sangh Niyamit", label: "Suraksha Vividoddeshagala Sahakari Sangh Niyamit" },
  { value: "Surama Multitrade Pvt Ltd", label: "Surama Multitrade Pvt Ltd" },
  { value: "Surana Brothers Finance", label: "Surana Brothers Finance" },
  { value: "Surana Enterprise", label: "Surana Enterprise" },
  { value: "Surana Finance Ltd", label: "Surana Finance Ltd" },
  { value: "Surana Finance Sicandarabad", label: "Surana Finance Sicandarabad" },
  { value: "Surana Financial Corporation (India) Ltd.", label: "Surana Financial Corporation (India) Ltd." },
  { value: "Surana Financiers", label: "Surana Financiers" },
  { value: "Surana Investments", label: "Surana Investments" },
  { value: "Surana Motors", label: "Surana Motors" },
  { value: "Surat  National Co-Op Bank Ltd", label: "Surat  National Co-Op Bank Ltd" },
  { value: "Surat - Bharuch Gramin Bank", label: "Surat - Bharuch Gramin Bank" },
  { value: "Surat Corporation", label: "Surat Corporation" },
  { value: "Surat Dist. Co-Op. Bank Ltd.", label: "Surat Dist. Co-Op. Bank Ltd." },
  { value: "Surat District Co-Op Bank Ltd.", label: "Surat District Co-Op Bank Ltd." },
  { value: "Surat Gujarat Gramin Bank", label: "Surat Gujarat Gramin Bank" },
  { value: "Surat Mahila Nag. Sah. Bank Ltd.", label: "Surat Mahila Nag. Sah. Bank Ltd." },
  { value: "Surat Mercantile Co-Op Bank Ltd.", label: "Surat Mercantile Co-Op Bank Ltd." },
  { value: "Surat Modh Vanik Urben Co Op", label: "Surat Modh Vanik Urben Co Op" },
  { value: "Surat Motor Finance", label: "Surat Motor Finance" },
  { value: "Surat Munciciple Corporation", label: "Surat Munciciple Corporation" },
  { value: "Surat Nagrik Sahakari Bank Ltd.", label: "Surat Nagrik Sahakari Bank Ltd." },
  { value: "Surat Nagrik Sahakari Bank Ltd.", label: "Surat Nagrik Sahakari Bank Ltd." },
  { value: "Surat National Bank Ltd", label: "Surat National Bank Ltd" },
  { value: "Surat National Co-Op Bank Ltd.", label: "Surat National Co-Op Bank Ltd." },
  { value: "Surat National Co.Op.Bank", label: "Surat National Co.Op.Bank" },
  { value: "Surat Peoples Co-Op Bank Ltd.", label: "Surat Peoples Co-Op Bank Ltd." },
  { value: "Surat Textile Traders Co-Op Bank Ltd.", label: "Surat Textile Traders Co-Op Bank Ltd." },
  { value: "Surat Textile Traders Co.Op. Bank", label: "Surat Textile Traders Co.Op. Bank" },
  { value: "Surbhi Automobiles", label: "Surbhi Automobiles" },
  { value: "Surbhi Automobiles", label: "Surbhi Automobiles" },
  { value: "Surbhi Finance", label: "Surbhi Finance" },
  { value: "Surbhi Jain", label: "Surbhi Jain" },
  { value: "Surdaran Fin Ltd", label: "Surdaran Fin Ltd" },
  { value: "Surekha Hire Purchase", label: "Surekha Hire Purchase" },
  { value: "Sureksha Hire Purchase", label: "Sureksha Hire Purchase" },
  { value: "Surender Finance", label: "Surender Finance" },
  { value: "Surendra Chowdhari", label: "Surendra Chowdhari" },
  { value: "Surendra Finance Co.", label: "Surendra Finance Co." },
  { value: "Surendra Hp Financiers", label: "Surendra Hp Financiers" },
  { value: "Surendra Kr Bansal", label: "Surendra Kr Bansal" },
  { value: "Surendra Kumar Bothra", label: "Surendra Kumar Bothra" },
  { value: "Surendrakumar (Huf)", label: "Surendrakumar (Huf)" },
  { value: "Surendranagar District Central Co-Operative Bank Ltd.", label: "Surendranagar District Central Co-Operative Bank Ltd." },
  { value: "Surendranagar Saurashtra Gramin Bank", label: "Surendranagar Saurashtra Gramin Bank" },
  { value: "Surendrnagar Merchants Co-Op Bank Ltd", label: "Surendrnagar Merchants Co-Op Bank Ltd" },
  { value: "Surendrnagar Peoples Co-Op Bank Ltd", label: "Surendrnagar Peoples Co-Op Bank Ltd" },
  { value: "Suresh & Co", label: "Suresh & Co" },
  { value: "Suresh Auto Finance", label: "Suresh Auto Finance" },
  { value: "Suresh Chand Surana", label: "Suresh Chand Surana" },
  { value: "Suresh Finance Corpn", label: "Suresh Finance Corpn" },
  { value: "Suresh Hire Purchase Co", label: "Suresh Hire Purchase Co" },
  { value: "Suresh Hyerpurch Co.Indore", label: "Suresh Hyerpurch Co.Indore" },
  { value: "Suresh Hyper Purchase Co.", label: "Suresh Hyper Purchase Co." },
  { value: "Suresh Jain", label: "Suresh Jain" },
  { value: "Suresh Kothari", label: "Suresh Kothari" },
  { value: "Suresh Kumar Baid", label: "Suresh Kumar Baid" },
  { value: "Suresh Kumar Bokdia", label: "Suresh Kumar Bokdia" },
  { value: "Suresh Kumar Khabzya & Son", label: "Suresh Kumar Khabzya & Son" },
  { value: "Suresh Kumar Lunawat", label: "Suresh Kumar Lunawat" },
  { value: "Suresh Kumar Surana (Huf)", label: "Suresh Kumar Surana (Huf)" },
  { value: "Sureshbaba Deshmukh Nag.Sah.Pat San Ltd", label: "Sureshbaba Deshmukh Nag.Sah.Pat San Ltd" },
  { value: "Sureshbaba Deshmukh Nagari S.P.L.", label: "Sureshbaba Deshmukh Nagari S.P.L." },
  { value: "Sureshchand Khabiya", label: "Sureshchand Khabiya" },
  { value: "Sureshchandra M Mehta", label: "Sureshchandra M Mehta" },
  { value: "Sureshchandra M Mehta Huf", label: "Sureshchandra M Mehta Huf" },
  { value: "Sureshchandra Mehta", label: "Sureshchandra Mehta" },
  { value: "Surinder  Finance Ltd.", label: "Surinder  Finance Ltd." },
  { value: "Surindra Auto Deals", label: "Surindra Auto Deals" },
  { value: "Surindra Auto Traders", label: "Surindra Auto Traders" },
  { value: "Suriya Finance", label: "Suriya Finance" },
  { value: "Suriyavil Finance", label: "Suriyavil Finance" },
  { value: "Surjeet Trading Co", label: "Surjeet Trading Co" },
  { value: "Surjit Hire Purchase Pvt. Ltd", label: "Surjit Hire Purchase Pvt. Ltd" },
  { value: "Suruchi Finance", label: "Suruchi Finance" },
  { value: "Surv U.P. Gramin Bank", label: "Surv U.P. Gramin Bank" },
  { value: "Survanna Jyothi Leasing (R)", label: "Survanna Jyothi Leasing (R)" },
  { value: "Survarnlaxmi Sahakari Palapeddhi Maryadit", label: "Survarnlaxmi Sahakari Palapeddhi Maryadit" },
  { value: "Surya Bohra", label: "Surya Bohra" },
  { value: "Surya Chandra Hire Purchase Finance", label: "Surya Chandra Hire Purchase Finance" },
  { value: "Surya Enterprise", label: "Surya Enterprise" },
  { value: "Surya Finance", label: "Surya Finance" },
  { value: "Surya Finance (P) Ltd.", label: "Surya Finance (P) Ltd." },
  { value: "Surya Finlease Pvt. Ltd.", label: "Surya Finlease Pvt. Ltd." },
  { value: "Surya Mithra Finance", label: "Surya Mithra Finance" },
  { value: "Surya Mithra Hire Purchase Finance", label: "Surya Mithra Hire Purchase Finance" },
  { value: "Surya Motor Credits", label: "Surya Motor Credits" },
  { value: "Surya Motor Finance", label: "Surya Motor Finance" },
  { value: "Surya Teja Hp And Finance Ltd", label: "Surya Teja Hp And Finance Ltd" },
  { value: "Suryadeep Enterprise", label: "Suryadeep Enterprise" },
  { value: "Suryapur Co-Op Bank Ltd., Surat", label: "Suryapur Co-Op Bank Ltd., Surat" },
  { value: "Suryodaya Auto Finance", label: "Suryodaya Auto Finance" },
  { value: "Suryodaynagri Sah Pat Ltd", label: "Suryodaynagri Sah Pat Ltd" },
  { value: "Susee Finance", label: "Susee Finance" },
  { value: "Susee Finance (P) Ltd", label: "Susee Finance (P) Ltd" },
  { value: "Susee Finance And Leasing Pvt Ltd", label: "Susee Finance And Leasing Pvt Ltd" },
  { value: "Susheel Finance And Investment", label: "Susheel Finance And Investment" },
  { value: "Susheel Investments", label: "Susheel Investments" },
  { value: "Susheel Leasing And Finance", label: "Susheel Leasing And Finance" },
  { value: "Susheela Kandwar Financiers", label: "Susheela Kandwar Financiers" },
  { value: "Sushil Auto Finance", label: "Sushil Auto Finance" },
  { value: "Sushil Bajaj", label: "Sushil Bajaj" },
  { value: "Sushil Finance Corporation", label: "Sushil Finance Corporation" },
  { value: "Sushil Hirepurchase Co", label: "Sushil Hirepurchase Co" },
  { value: "Sushil Investment", label: "Sushil Investment" },
  { value: "Sushil Kumar And Sons Financiers", label: "Sushil Kumar And Sons Financiers" },
  { value: "Sushil Kumar Bhandari Huf", label: "Sushil Kumar Bhandari Huf" },
  { value: "Sushil Kumar Kantilal", label: "Sushil Kumar Kantilal" },
  { value: "Sushil Kumar Pipara", label: "Sushil Kumar Pipara" },
  { value: "Sushil Sahakari Path Sanstha", label: "Sushil Sahakari Path Sanstha" },
  { value: "Sushil Sharma", label: "Sushil Sharma" },
  { value: "Sushila Kanwar Mehta", label: "Sushila Kanwar Mehta" },
  { value: "Sushila Singhi", label: "Sushila Singhi" },
  { value: "Sushilkumar Mehta Urban Co-Op Bank Ltd", label: "Sushilkumar Mehta Urban Co-Op Bank Ltd" },
  { value: "Sushilkumar Nahata Urban Co-Op Bank Ltd.", label: "Sushilkumar Nahata Urban Co-Op Bank Ltd." },
  { value: "Sushula Bothra", label: "Sushula Bothra" },
  { value: "Susila Kawar Matha", label: "Susila Kawar Matha" },
  { value: "Suswami Enterprises", label: "Suswami Enterprises" },
  { value: "Suswani Enterprises", label: "Suswani Enterprises" },
  { value: "Sutaliya Finance Pvt Ltd", label: "Sutaliya Finance Pvt Ltd" },
  { value: "Sutex Co Op Bank", label: "Sutex Co Op Bank" },
  { value: "Sutex Co-Oprative Bank Ltd,", label: "Sutex Co-Oprative Bank Ltd," },
  { value: "Sutex Traders Coop Bank Ltd", label: "Sutex Traders Coop Bank Ltd" },
  { value: "Suvaranyug Nagari Sah. Path", label: "Suvaranyug Nagari Sah. Path" },
  { value: "Suvarna Auto Finance", label: "Suvarna Auto Finance" },
  { value: "Suvarna Bharat", label: "Suvarna Bharat" },
  { value: "Suvarna Co.Operative Bank Ltd", label: "Suvarna Co.Operative Bank Ltd" },
  { value: "Suvarna Finance", label: "Suvarna Finance" },
  { value: "Suvarna Finance & Leasing", label: "Suvarna Finance & Leasing" },
  { value: "Suvarna Jyothi Leasing", label: "Suvarna Jyothi Leasing" },
  { value: "Suvarna Lakshmi Co-Op Credit Society Ltd.", label: "Suvarna Lakshmi Co-Op Credit Society Ltd." },
  { value: "Suvarna Mangal Mahila Sah. Bank Ltd", label: "Suvarna Mangal Mahila Sah. Bank Ltd" },
  { value: "Suvarna Mangal Mahila Sahakari Bank Ltd", label: "Suvarna Mangal Mahila Sahakari Bank Ltd" },
  { value: "Suvarna Mangal Mahila Sahakari Bank Ltd.", label: "Suvarna Mangal Mahila Sahakari Bank Ltd." },
  { value: "Suvarna Nagari Sahakari Bank  Maryadit", label: "Suvarna Nagari Sahakari Bank  Maryadit" },
  { value: "Suvarna Sagar Finance And Leasing", label: "Suvarna Sagar Finance And Leasing" },
  { value: "Suvarnakar Gramin Biger Sheti Saha", label: "Suvarnakar Gramin Biger Sheti Saha" },
  { value: "Suvarnalaxmi Sahakari Patapeddhi Maryadit", label: "Suvarnalaxmi Sahakari Patapeddhi Maryadit" },
  { value: "Suvarnaurban Co-Op Bank Ltd", label: "Suvarnaurban Co-Op Bank Ltd" },
  { value: "Suvarnayug Sahakari Bank Ltd.", label: "Suvarnayug Sahakari Bank Ltd." },
  { value: "Suvarnayug Sahakari Bank Ltd., Pune", label: "Suvarnayug Sahakari Bank Ltd., Pune" },
  { value: "Suvarnlaxmi Sahakari Patpedhi Maryadit", label: "Suvarnlaxmi Sahakari Patpedhi Maryadit" },
  { value: "Suvichar Sahkari Sakh Sanstha", label: "Suvichar Sahkari Sakh Sanstha" },
  { value: "Suvidha Finance Corp", label: "Suvidha Finance Corp" },
  { value: "Suvidha Nagari Sah Pat Sanstha", label: "Suvidha Nagari Sah Pat Sanstha" },
  { value: "Suvidha Nagari Sah Pat.", label: "Suvidha Nagari Sah Pat." },
  { value: "Suvidha Sewa Sahakari Mandli", label: "Suvidha Sewa Sahakari Mandli" },
  { value: "Suvidha Traders", label: "Suvidha Traders" },
  { value: "Suyash Finance Corp", label: "Suyash Finance Corp" },
  { value: "Suyog Nagari Sah Pat San Maryadit", label: "Suyog Nagari Sah Pat San Maryadit" },
  { value: "Svc Bank Ltd", label: "Svc Bank Ltd" },
  { value: "Svc Finance", label: "Svc Finance" },
  { value: "Svmpcs Co-Op Ltd", label: "Svmpcs Co-Op Ltd" },
  { value: "Svr Finance", label: "Svr Finance" },
  { value: "Svr Finance Company", label: "Svr Finance Company" },
  { value: "Svs Auto Investment", label: "Svs Auto Investment" },
  { value: "Svs Auto Investment & Reseller", label: "Svs Auto Investment & Reseller" },
  { value: "Svs Finance Ltd", label: "Svs Finance Ltd" },
  { value: "Swabhiman G.B.S.S. Pathsanstha Maryadit", label: "Swabhiman G.B.S.S. Pathsanstha Maryadit" },
  { value: "Swadhan Co Op Credit Soc Ltd.", label: "Swadhan Co Op Credit Soc Ltd." },
  { value: "Swagath Finance & Auto Finance", label: "Swagath Finance & Auto Finance" },
  { value: "Swajay Investments", label: "Swajay Investments" },
  { value: "Swami Deposits And Investments Pvt. Ltd.", label: "Swami Deposits And Investments Pvt. Ltd." },
  { value: "Swami Hire Purchase Pvt Ltd", label: "Swami Hire Purchase Pvt Ltd" },
  { value: "Swami Samarth Credit Cooprative Bank", label: "Swami Samarth Credit Cooprative Bank" },
  { value: "Swami Samarth Path San.", label: "Swami Samarth Path San." },
  { value: "Swami Samarth Sahakari Bank Ltd.", label: "Swami Samarth Sahakari Bank Ltd." },
  { value: "Swami Samartha Gramin Bigarsheti Saha Patsansta", label: "Swami Samartha Gramin Bigarsheti Saha Patsansta" },
  { value: "Swami Vivekanad Nagar Sah Pat San Maydit", label: "Swami Vivekanad Nagar Sah Pat San Maydit" },
  { value: "Swami Vivekanand Co-Op. Credit Soc. Ltd.", label: "Swami Vivekanand Co-Op. Credit Soc. Ltd." },
  { value: "Swami Vivekanand Gramin B. S. Sah. Pathsanstha", label: "Swami Vivekanand Gramin B. S. Sah. Pathsanstha" },
  { value: "Swami Vivekanand Pattina Sahakari Sangha Niyamit", label: "Swami Vivekanand Pattina Sahakari Sangha Niyamit" },
  { value: "Swami Vivekanand Urbco-Op Bank Ltd", label: "Swami Vivekanand Urbco-Op Bank Ltd" },
  { value: "Swaminarayan Finance", label: "Swaminarayan Finance" },
  { value: "Swaminath R. Yadav", label: "Swaminath R. Yadav" },
  { value: "Swamy Auto Finance", label: "Swamy Auto Finance" },
  { value: "Swamy Vivekananda Credit Co-Operative Society Ltd.", label: "Swamy Vivekananda Credit Co-Operative Society Ltd." },
  { value: "Swamy Vivekananda Purushula Podupu Sangam", label: "Swamy Vivekananda Purushula Podupu Sangam" },
  { value: "Swapnasahakari Gramin Sheti Sahakari Patsanstha", label: "Swapnasahakari Gramin Sheti Sahakari Patsanstha" },
  { value: "Swaraj Enterprises", label: "Swaraj Enterprises" },
  { value: "Swaraj Urban Co-Op Bank Ltd", label: "Swaraj Urban Co-Op Bank Ltd" },
  { value: "Swarajya Nagari Sahakari Patsanstha Maryadit", label: "Swarajya Nagari Sahakari Patsanstha Maryadit" },
  { value: "Swaran & Co.", label: "Swaran & Co." },
  { value: "Swaran Bhoomi Financial Services", label: "Swaran Bhoomi Financial Services" },
  { value: "Swaran Finance Pvt. Ltd.", label: "Swaran Finance Pvt. Ltd." },
  { value: "Swarge Rambhauji Lingade Nagari Sak Path Sant Ltd", label: "Swarge Rambhauji Lingade Nagari Sak Path Sant Ltd" },
  { value: "Swarn Motor Trading Corp", label: "Swarn Motor Trading Corp" },
  { value: "Swarna  Pattina Souharda Sahakari Niyamit", label: "Swarna  Pattina Souharda Sahakari Niyamit" },
  { value: "Swarna Financiers", label: "Swarna Financiers" },
  { value: "Swarna Jyoti Leasing Finance Gulbarga", label: "Swarna Jyoti Leasing Finance Gulbarga" },
  { value: "Swarna Securities Limited", label: "Swarna Securities Limited" },
  { value: "Swastik Auto Finance", label: "Swastik Auto Finance" },
  { value: "Swastik Co Op Credit Soc Ltd", label: "Swastik Co Op Credit Soc Ltd" },
  { value: "Swastik Janata Sah. Bank Ltd., Mumbai", label: "Swastik Janata Sah. Bank Ltd., Mumbai" },
  { value: "Swastik Leasing Co.", label: "Swastik Leasing Co." },
  { value: "Swastik Leasing Co.", label: "Swastik Leasing Co." },
  { value: "Swatantra Deo Enterprise", label: "Swatantra Deo Enterprise" },
  { value: "Swatantrya Senani Late Shripal Alase Kaka Kurundwad Urban Co-Op Bank Ltd", label: "Swatantrya Senani Late Shripal Alase Kaka Kurundwad Urban Co-Op Bank Ltd" },
  { value: "Swathi And Suruthi Finance", label: "Swathi And Suruthi Finance" },
  { value: "Swathi Auto & General Finance", label: "Swathi Auto & General Finance" },
  { value: "Swathi Auto Finance", label: "Swathi Auto Finance" },
  { value: "Swathi Bothra Auto Mobile Finance", label: "Swathi Bothra Auto Mobile Finance" },
  { value: "Swathi Investments", label: "Swathi Investments" },
  { value: "Swathi Kiran Hire Purchase", label: "Swathi Kiran Hire Purchase" },
  { value: "Swathi Kiran Hire Purchase Finance", label: "Swathi Kiran Hire Purchase Finance" },
  { value: "Swathy Investments", label: "Swathy Investments" },
  { value: "Swayambhunagar Vikas Karyakari Seva Sanstha Maryad", label: "Swayambhunagar Vikas Karyakari Seva Sanstha Maryad" },
  { value: "Sweet Investment", label: "Sweet Investment" },
  { value: "Sweta Auto Agency", label: "Sweta Auto Agency" },
  { value: "Swetha Bhatra Automobile Finance", label: "Swetha Bhatra Automobile Finance" },
  { value: "Swift Finlease (I) Ltd.", label: "Swift Finlease (I) Ltd." },
  { value: "Swraj  Urban Co Bk", label: "Swraj  Urban Co Bk" },
  { value: "Syal Finance Pvt. Ltd", label: "Syal Finance Pvt. Ltd" },
  { value: "Syal Finlease Ltd.", label: "Syal Finlease Ltd." },
  { value: "Syal Investments Ltd", label: "Syal Investments Ltd" },
  { value: "Syal Leasing Ltd", label: "Syal Leasing Ltd" },
  { value: "Syed Finance", label: "Syed Finance" },
  { value: "Syed Sared Finance", label: "Syed Sared Finance" },
  { value: "Syed Sayej Ali", label: "Syed Sayej Ali" },
  { value: "Syed Shariat Finance", label: "Syed Shariat Finance" },
  { value: "Syed Sharief Finance", label: "Syed Sharief Finance" },
  { value: "Symphony Enterprise", label: "Symphony Enterprise" },
  { value: "Syndicate Bank  Palli", label: "Syndicate Bank  Palli" },
  { value: "Syndicate Bank - Ankola", label: "Syndicate Bank - Ankola" },
  { value: "Syndicate Bank - Ankola.", label: "Syndicate Bank - Ankola." },
  { value: "Syndicate Bank - Bidkalkatte", label: "Syndicate Bank - Bidkalkatte" },
  { value: "Syndicate Bank - Main Branch Manipal.", label: "Syndicate Bank - Main Branch Manipal." },
  { value: "Syndicate Bank - Nakre Branch.", label: "Syndicate Bank - Nakre Branch." },
  { value: "Syndicate Bank - Navunda", label: "Syndicate Bank - Navunda" },
  { value: "Syndicate Bank - Nitte", label: "Syndicate Bank - Nitte" },
  { value: "Syndicate Bank - Sibm Branch Manipal.", label: "Syndicate Bank - Sibm Branch Manipal." },
  { value: "Syndicate Bank -Kanjarakate", label: "Syndicate Bank -Kanjarakate" },
  { value: "Syndicate Bank Catholic Centre", label: "Syndicate Bank Catholic Centre" },
  { value: "Syndicate Bank Karkala", label: "Syndicate Bank Karkala" },
  { value: "Syndicate Bank Pethri", label: "Syndicate Bank Pethri" },
  { value: "Syndicate Bank,", label: "Syndicate Bank," },
  { value: "Syndicate Bank, Mangalore", label: "Syndicate Bank, Mangalore" },
  { value: "Syndicate Credit Co-Op Ltd", label: "Syndicate Credit Co-Op Ltd" },
  { value: "Syndicate Far Ser Co-Op Society Ltd", label: "Syndicate Far Ser Co-Op Society Ltd" },
  { value: "Syndicate Finance Co.", label: "Syndicate Finance Co." },
  { value: "Syndicate Qyathara Seva Sahakari Bank Ni", label: "Syndicate Qyathara Seva Sahakari Bank Ni" },
  { value: "Syndicate Ryathara Seva Sahakari Bank Ni", label: "Syndicate Ryathara Seva Sahakari Bank Ni" },
  { value: "Syndicate Ryathara Seve Sahakari Bank N.", label: "Syndicate Ryathara Seve Sahakari Bank N." },
  { value: "Syndicate Ryt. Seva.S. Bk. Ltd.", label: "Syndicate Ryt. Seva.S. Bk. Ltd." },
  { value: "Syneix Finance Ltd", label: "Syneix Finance Ltd" },
  { value: "Synthetic And Art Silk Mills` Research Association", label: "Synthetic And Art Silk Mills` Research Association" },
  { value: "Sysheeya Kumar", label: "Sysheeya Kumar" },
  { value: "T Amit Kumar", label: "T Amit Kumar" },
  { value: "T Amit Kumar Jain Financiers", label: "T Amit Kumar Jain Financiers" },
  { value: "T Balaji Associates", label: "T Balaji Associates" },
  { value: "T C Parthapmal", label: "T C Parthapmal" },
  { value: "T Co-Operative Bank Ltd.", label: "T Co-Operative Bank Ltd." },
  { value: "T D K Auto Finance Co. Ltd.", label: "T D K Auto Finance Co. Ltd." },
  { value: "T D K Finance Co Ltd", label: "T D K Finance Co Ltd" },
  { value: "T Dharam Chand", label: "T Dharam Chand" },
  { value: "T Dulachand Nahar Finance", label: "T Dulachand Nahar Finance" },
  { value: "T G Bank", label: "T G Bank" },
  { value: "T G Investments", label: "T G Investments" },
  { value: "T G M C Bank Ltd", label: "T G M C Bank Ltd" },
  { value: "T J Finance", label: "T J Finance" },
  { value: "T Jayaraman Finance Ltd", label: "T Jayaraman Finance Ltd" },
  { value: "T K Binoy", label: "T K Binoy" },
  { value: "T K M Ltd", label: "T K M Ltd" },
  { value: "T M B Ltd", label: "T M B Ltd" },
  { value: "T Mahaveerchand Bhandari", label: "T Mahaveerchand Bhandari" },
  { value: "T P S S N", label: "T P S S N" },
  { value: "T S Palanisamy Financier", label: "T S Palanisamy Financier" },
  { value: "T Sikker Bhandari", label: "T Sikker Bhandari" },
  { value: "T Srinivas Financiers", label: "T Srinivas Financiers" },
  { value: "T Sumith Jain", label: "T Sumith Jain" },
  { value: "T Sundeep Chajjed", label: "T Sundeep Chajjed" },
  { value: "T V Malai Dist C C Bank", label: "T V Malai Dist C C Bank" },
  { value: "T. Hansukh Chand", label: "T. Hansukh Chand" },
  { value: "T. S. C. C. D. C. Ltd.", label: "T. S. C. C. D. C. Ltd." },
  { value: "T. S. T. Co-Operative Development Corporation Ltd", label: "T. S. T. Co-Operative Development Corporation Ltd" },
  { value: "T.A.F.L", label: "T.A.F.L" },
  { value: "T.C Jain & Sons", label: "T.C Jain & Sons" },
  { value: "T.C.L", label: "T.C.L" },
  { value: "T.D.C Bank Ltd", label: "T.D.C Bank Ltd" },
  { value: "T.D.C. Bank", label: "T.D.C. Bank" },
  { value: "T.D.C.C. Bank Ltd.", label: "T.D.C.C. Bank Ltd." },
  { value: "T.F Co.", label: "T.F Co." },
  { value: "T.G Investments", label: "T.G Investments" },
  { value: "T.Indara Bai Financier", label: "T.Indara Bai Financier" },
  { value: "T.K Co-Opeative Bank Limited", label: "T.K Co-Opeative Bank Limited" },
  { value: "T.K.G.B. Jakhora", label: "T.K.G.B. Jakhora" },
  { value: "T.M.F.S Ltd", label: "T.M.F.S Ltd" },
  { value: "T.M.S.C.C. Ltd.", label: "T.M.S.C.C. Ltd." },
  { value: "T.Shettigeri Vyasasaya Seva Sah.Sangha", label: "T.Shettigeri Vyasasaya Seva Sah.Sangha" },
  { value: "T.T. Central Co-Op Bank Ltd T.T", label: "T.T. Central Co-Op Bank Ltd T.T" },
  { value: "T.V.Puram Farmers Service C-Op Bank Ltd,", label: "T.V.Puram Farmers Service C-Op Bank Ltd," },
  { value: "T.W.L. Financial Services  Ltd", label: "T.W.L. Financial Services  Ltd" },
  { value: "Tada-Tekla Sakari Mini Bank,", label: "Tada-Tekla Sakari Mini Bank," },
  { value: "Tadco Bank", label: "Tadco Bank" },
  { value: "Tahdco Chennai", label: "Tahdco Chennai" },
  { value: "Tahdco Dharmapuri", label: "Tahdco Dharmapuri" },
  { value: "Taheam Financiers", label: "Taheam Financiers" },
  { value: "Taico Bank Ltd", label: "Taico Bank Ltd" },
  { value: "Taipan Traders Limited", label: "Taipan Traders Limited" },
  { value: "Taj Automobile", label: "Taj Automobile" },
  { value: "Taj Financers", label: "Taj Financers" },
  { value: "Taj Malabar W/Island", label: "Taj Malabar W/Island" },
  { value: "Tajinder Enterprises Dinanagr", label: "Tajinder Enterprises Dinanagr" },
  { value: "Tajindra Singh Gandhi", label: "Tajindra Singh Gandhi" },
  { value: "Takeda Finance", label: "Takeda Finance" },
  { value: "Talaja Nagrik Sahakari Bank Ltd.", label: "Talaja Nagrik Sahakari Bank Ltd." },
  { value: "Talaja Nagrik Sahkari Bank Ltd.", label: "Talaja Nagrik Sahkari Bank Ltd." },
  { value: "Talala Taluk Co-Op Bank Ltd", label: "Talala Taluk Co-Op Bank Ltd" },
  { value: "Talathi Va Mandal Adhikari Sah Pat Sanstha Mary", label: "Talathi Va Mandal Adhikari Sah Pat Sanstha Mary" },
  { value: "Taleda Financiers", label: "Taleda Financiers" },
  { value: "Talera Enterprise", label: "Talera Enterprise" },
  { value: "Talikothi Urban Co-Operative Bank Ltd", label: "Talikothi Urban Co-Operative Bank Ltd" },
  { value: "Talikoti Sahakari Bank Niyamit.", label: "Talikoti Sahakari Bank Niyamit." },
  { value: "Talikoti Urban Co-Operative Bank", label: "Talikoti Urban Co-Operative Bank" },
  { value: "Taliparamba Service Co-Operative Bank Ltd", label: "Taliparamba Service Co-Operative Bank Ltd" },
  { value: "Taluk Co-Op Agriculture And Rural Development Bank Ltd.", label: "Taluk Co-Op Agriculture And Rural Development Bank Ltd." },
  { value: "Talvinder  Kour", label: "Talvinder  Kour" },
  { value: "Tameer Co-Op Credit Society", label: "Tameer Co-Op Credit Society" },
  { value: "Tamil Nadu Bus Operators Finance Corpn. Ltd.", label: "Tamil Nadu Bus Operators Finance Corpn. Ltd." },
  { value: "Tamil Nadu Corporation For Development Of Women Ltd", label: "Tamil Nadu Corporation For Development Of Women Ltd" },
  { value: "Tamil Nadu Electricity Board", label: "Tamil Nadu Electricity Board" },
  { value: "Tamil Nadu Electricity Regulatory Commission", label: "Tamil Nadu Electricity Regulatory Commission" },
  { value: "Tamil Nadu Industrial Development Corporation", label: "Tamil Nadu Industrial Development Corporation" },
  { value: "Tamil Nadu Industrial Development Corporation Ltd", label: "Tamil Nadu Industrial Development Corporation Ltd" },
  { value: "Tamil Nadu Industrial Investment Corporation", label: "Tamil Nadu Industrial Investment Corporation" },
  { value: "Tamil Nadu Medical Services Corporation", label: "Tamil Nadu Medical Services Corporation" },
  { value: "Tamil Nadu Petro Products Ltd", label: "Tamil Nadu Petro Products Ltd" },
  { value: "Tamil Nadu Power Finance And Infra Devp. Corpn Ltd.", label: "Tamil Nadu Power Finance And Infra Devp. Corpn Ltd." },
  { value: "Tamil Nadu State Aids Control Society", label: "Tamil Nadu State Aids Control Society" },
  { value: "Tamil Nadu State Industrial Promotion Corporation Of Tamil Nadu Ltd", label: "Tamil Nadu State Industrial Promotion Corporation Of Tamil Nadu Ltd" },
  { value: "Tamil Nadu State Tourism Development Corporation Ltd.", label: "Tamil Nadu State Tourism Development Corporation Ltd." },
  { value: "Tamiland Mercantile Bank Ltd", label: "Tamiland Mercantile Bank Ltd" },
  { value: "Tamilnad Mercantile Bank Ltd.", label: "Tamilnad Mercantile Bank Ltd." },
  { value: "Tamilnadu Circle Postal Co-Op. Bank Ltd.", label: "Tamilnadu Circle Postal Co-Op. Bank Ltd." },
  { value: "Tamilnadu Industrial Development Corpn. Ltd.", label: "Tamilnadu Industrial Development Corpn. Ltd." },
  { value: "Tamilnadu Industrial Investment Corporation Ltd", label: "Tamilnadu Industrial Investment Corporation Ltd" },
  { value: "Tamilnadu Mercantile Bank Ltd.", label: "Tamilnadu Mercantile Bank Ltd." },
  { value: "Tamilnadu Merchantile Bank Ltd", label: "Tamilnadu Merchantile Bank Ltd" },
  { value: "Tamilnadu Minerals Ltd", label: "Tamilnadu Minerals Ltd" },
  { value: "Tamilnadu Pollution Control Board", label: "Tamilnadu Pollution Control Board" },
  { value: "Tamilnadu State Apex Co Operative Bank Limited", label: "Tamilnadu State Apex Co Operative Bank Limited" },
  { value: "Tamilnadu State Co-Op Bank Ltd.", label: "Tamilnadu State Co-Op Bank Ltd." },
  { value: "Tamilnadu Transport Devp. Finance Corpn. Ltd.", label: "Tamilnadu Transport Devp. Finance Corpn. Ltd." },
  { value: "Tamluk Ghata District Central Co-Operative Bank Ltd.", label: "Tamluk Ghata District Central Co-Operative Bank Ltd." },
  { value: "Tamluk Ghatal Central Co-Op.Bank Ltd.", label: "Tamluk Ghatal Central Co-Op.Bank Ltd." },
  { value: "Tan Man Finance Corporation", label: "Tan Man Finance Corporation" },
  { value: "Tandalwadi Bigarsheti Sah. Pata Sanstha", label: "Tandalwadi Bigarsheti Sah. Pata Sanstha" },
  { value: "Tandalwadi Gramin Sah. Pat. Mar", label: "Tandalwadi Gramin Sah. Pat. Mar" },
  { value: "Tandur Mahila Co Urban Bank", label: "Tandur Mahila Co Urban Bank" },
  { value: "Taneja Finance Company", label: "Taneja Finance Company" },
  { value: "Tanik Enterprises", label: "Tanik Enterprises" },
  { value: "Tanik Enterprises", label: "Tanik Enterprises" },
  { value: "Tannery And Footwear Corpn. Of India Ltd.", label: "Tannery And Footwear Corpn. Of India Ltd." },
  { value: "Tansukh Chand", label: "Tansukh Chand" },
  { value: "Tanuja Sunil Mahajan", label: "Tanuja Sunil Mahajan" },
  { value: "Tanur Co-Operative Urban Bank Ltd", label: "Tanur Co-Operative Urban Bank Ltd" },
  { value: "Tanwar Finance Co.", label: "Tanwar Finance Co." },
  { value: "Tanya Finance Co", label: "Tanya Finance Co" },
  { value: "Tapi Co-Op. Bank Ltd, Surat", label: "Tapi Co-Op. Bank Ltd, Surat" },
  { value: "Tapi Co-Operative Bank Ltd.", label: "Tapi Co-Operative Bank Ltd." },
  { value: "Tapi Co.Op.Bank.Ltd", label: "Tapi Co.Op.Bank.Ltd" },
  { value: "Tapi Sahkari Patpethi Ltd", label: "Tapi Sahkari Patpethi Ltd" },
  { value: "Tapindu Urban Co-Oparetive Bank Ltd", label: "Tapindu Urban Co-Oparetive Bank Ltd" },
  { value: "Tara Credit Corporation", label: "Tara Credit Corporation" },
  { value: "Tara Investments", label: "Tara Investments" },
  { value: "Taran & Sons", label: "Taran & Sons" },
  { value: "Taran Taran Central Co-Operative Bank Ltd", label: "Taran Taran Central Co-Operative Bank Ltd" },
  { value: "Tarang Financers (Regd)", label: "Tarang Financers (Regd)" },
  { value: "Taranga Finance Corporation", label: "Taranga Finance Corporation" },
  { value: "Taranga Investments", label: "Taranga Investments" },
  { value: "Taranpreet Singh", label: "Taranpreet Singh" },
  { value: "Tarapur Co Op Credit Soc Ltd", label: "Tarapur Co Op Credit Soc Ltd" },
  { value: "Tarapur Co-Op Bank Ltd", label: "Tarapur Co-Op Bank Ltd" },
  { value: "Tarapur Urban Co Op Bank", label: "Tarapur Urban Co Op Bank" },
  { value: "Tardal Gramin Biger Sheti Sahkari Patsanstha Mydt", label: "Tardal Gramin Biger Sheti Sahkari Patsanstha Mydt" },
  { value: "Target Assurance Services", label: "Target Assurance Services" },
  { value: "Target Credit & Capital P. Ltd.", label: "Target Credit & Capital P. Ltd." },
  { value: "Tariff Advisory Committee", label: "Tariff Advisory Committee" },
  { value: "Tariq Enterprises", label: "Tariq Enterprises" },
  { value: "Tarminated Cide Artos Nsk", label: "Tarminated Cide Artos Nsk" },
  { value: "Tarn Taran District Central Co-Operative Bank Ltd.", label: "Tarn Taran District Central Co-Operative Bank Ltd." },
  { value: "Taru Agencies", label: "Taru Agencies" },
  { value: "Taru Agencies & Investment Pvt Ltd", label: "Taru Agencies & Investment Pvt Ltd" },
  { value: "Tarun & Sons", label: "Tarun & Sons" },
  { value: "Tarun Associates", label: "Tarun Associates" },
  { value: "Tarun Auto Finance", label: "Tarun Auto Finance" },
  { value: "Tarun Finance And Leasing Co", label: "Tarun Finance And Leasing Co" },
  { value: "Tarun Finance Co Ltd", label: "Tarun Finance Co Ltd" },
  { value: "Tarun Investment", label: "Tarun Investment" },
  { value: "Tasgaon Urban Co-Op Bank Ltd", label: "Tasgaon Urban Co-Op Bank Ltd" },
  { value: "Tata  Finance Company  Ltd.", label: "Tata  Finance Company  Ltd." },
  { value: "Tata Bhpc", label: "Tata Bhpc" },
  { value: "Tata Capital Financiers Ltd", label: "Tata Capital Financiers Ltd" },
  { value: "Tata Capital Ltd", label: "Tata Capital Ltd" },
  { value: "Tata Capital Ltd", label: "Tata Capital Ltd" },
  { value: "Tata Captial Ltd.", label: "Tata Captial Ltd." },
  { value: "Tata Eng.& Locomotive Co.Ltd", label: "Tata Eng.& Locomotive Co.Ltd" },
  { value: "Tata Engg Bhpc", label: "Tata Engg Bhpc" },
  { value: "Tata Engineering & Locomotive Ltd", label: "Tata Engineering & Locomotive Ltd" },
  { value: "Tata Fin. Ser. Ltd", label: "Tata Fin. Ser. Ltd" },
  { value: "Tata Finance And Bharat Petroleum Corporation Ltd.", label: "Tata Finance And Bharat Petroleum Corporation Ltd." },
  { value: "Tata Finance Limited", label: "Tata Finance Limited" },
  { value: "Tata Finance Pvt Ltd", label: "Tata Finance Pvt Ltd" },
  { value: "Tata Institute Of Fundamental Research", label: "Tata Institute Of Fundamental Research" },
  { value: "Tata Institute Of Social Sciences", label: "Tata Institute Of Social Sciences" },
  { value: "Tata Investment Corporation Ltd", label: "Tata Investment Corporation Ltd" },
  { value: "Tata Memorial Centre Employees Chs Ltd", label: "Tata Memorial Centre Employees Chs Ltd" },
  { value: "Tata Mercantile Bank Ltd", label: "Tata Mercantile Bank Ltd" },
  { value: "Tata Motor Fin Ltd", label: "Tata Motor Fin Ltd" },
  { value: "Tata Motor Finance", label: "Tata Motor Finance" },
  { value: "Tata Motor Finance Co Ltd", label: "Tata Motor Finance Co Ltd" },
  { value: "Tata Motor Finance Ltd", label: "Tata Motor Finance Ltd" },
  { value: "Tata Motor Finance Ltd/Magma Shrachi Finance Ltd", label: "Tata Motor Finance Ltd/Magma Shrachi Finance Ltd" },
  { value: "Tata Motor Finance Services Ltd", label: "Tata Motor Finance Services Ltd" },
  { value: "Tata Motors", label: "Tata Motors" },
  { value: "Tata Motors Finance Co Ltd", label: "Tata Motors Finance Co Ltd" },
  { value: "Tata Motors Finance Ltd Chennai", label: "Tata Motors Finance Ltd Chennai" },
  { value: "Tata Motors Finance Ltd.", label: "Tata Motors Finance Ltd." },
  { value: "Tata Motors Finance Ltd. (Bhpc)", label: "Tata Motors Finance Ltd. (Bhpc)" },
  { value: "Tata Motors Financer Ltd", label: "Tata Motors Financer Ltd" },
  { value: "Tata Motors Financial Serivce Ltd", label: "Tata Motors Financial Serivce Ltd" },
  { value: "Tata Motors Financing Ltd.", label: "Tata Motors Financing Ltd." },
  { value: "Tata Motors Ltd (Bhpc)", label: "Tata Motors Ltd (Bhpc)" },
  { value: "Tata Motors Ltd Financial Services", label: "Tata Motors Ltd Financial Services" },
  { value: "Tata Motors Ltd.", label: "Tata Motors Ltd." },
  { value: "Tata Motors Premises", label: "Tata Motors Premises" },
  { value: "Tata Safat19 Str 2400 Cc", label: "Tata Safat19 Str 2400 Cc" },
  { value: "Tata Steel Ltd", label: "Tata Steel Ltd" },
  { value: "Tata Tea Ltd.", label: "Tata Tea Ltd." },
  { value: "Tata Tfl Finance Ltd", label: "Tata Tfl Finance Ltd" },
  { value: "Tata Tml Financial Services Ltd", label: "Tata Tml Financial Services Ltd" },
  { value: "Tatapur Co-Operative Urban Bank Ltdtatapur Co-Operative Urban Bank Ltd", label: "Tatapur Co-Operative Urban Bank Ltdtatapur Co-Operative Urban Bank Ltd" },
  { value: "Tatpar Resources Pvt. Ltd", label: "Tatpar Resources Pvt. Ltd" },
  { value: "Tatya Saheb Mohite Nag Sah Patpedhi Ltd", label: "Tatya Saheb Mohite Nag Sah Patpedhi Ltd" },
  { value: "Taurus Earthmovers Ltd.", label: "Taurus Earthmovers Ltd." },
  { value: "Tavargera Pattan Souharda Sahakara Bank", label: "Tavargera Pattan Souharda Sahakara Bank" },
  { value: "Taxtile Traders Co Op Bank Ltd.", label: "Taxtile Traders Co Op Bank Ltd." },
  { value: "Tayal India Motors Pvt Ltd", label: "Tayal India Motors Pvt Ltd" },
  { value: "Tce Consucting Engineers Limited", label: "Tce Consucting Engineers Limited" },
  { value: "Tci Finance Ltd.", label: "Tci Finance Ltd." },
  { value: "Tcr Finance", label: "Tcr Finance" },
  { value: "Tdcc Bank Ltd.", label: "Tdcc Bank Ltd." },
  { value: "Tdk Finance Co. Ltd Gd Financer Pvt Ltd", label: "Tdk Finance Co. Ltd Gd Financer Pvt Ltd" },
  { value: "Tdsc Bank Ltd", label: "Tdsc Bank Ltd" },
  { value: "Tea Board, India", label: "Tea Board, India" },
  { value: "Tea Trading Corporation Of India Ltd.", label: "Tea Trading Corporation Of India Ltd." },
  { value: "Teachers Co-Op. Bank Ltd", label: "Teachers Co-Op. Bank Ltd" },
  { value: "Teachers Co-Op Bank Ltd", label: "Teachers Co-Op Bank Ltd" },
  { value: "Technology Development Board", label: "Technology Development Board" },
  { value: "Technology Development For Indian Languages", label: "Technology Development For Indian Languages" },
  { value: "Technology Information, Forecasting And Assessment Council", label: "Technology Information, Forecasting And Assessment Council" },
  { value: "Technology Innovation Management And Entrepreneurship Information Service", label: "Technology Innovation Management And Entrepreneurship Information Service" },
  { value: "Tee Iye Auto Finance", label: "Tee Iye Auto Finance" },
  { value: "Tehri Garhwal Zila Sahkari Bank Ltd.", label: "Tehri Garhwal Zila Sahkari Bank Ltd." },
  { value: "Tehri Garwal Distt.Co.Op.Bank Ltd", label: "Tehri Garwal Distt.Co.Op.Bank Ltd" },
  { value: "Teja Aut Finance", label: "Teja Aut Finance" },
  { value: "Teja Finance", label: "Teja Finance" },
  { value: "Tejasvi Finance Co", label: "Tejasvi Finance Co" },
  { value: "Tejraj Investment", label: "Tejraj Investment" },
  { value: "Telco", label: "Telco" },
  { value: "Telco Bhpc", label: "Telco Bhpc" },
  { value: "Telecom Regulatory Authority Of India", label: "Telecom Regulatory Authority Of India" },
  { value: "Telecommunications Consultants India Limited", label: "Telecommunications Consultants India Limited" },
  { value: "Telewadi Vividha Karyakari Seva Society", label: "Telewadi Vividha Karyakari Seva Society" },
  { value: "Telta Capital Ltd", label: "Telta Capital Ltd" },
  { value: "Temathani Small Traders Co-Operative Credit Society Ltd.", label: "Temathani Small Traders Co-Operative Credit Society Ltd." },
  { value: "Tempo Fin. (North) P. Ltd.", label: "Tempo Fin. (North) P. Ltd." },
  { value: "Tempo Finance Pvt Ltd", label: "Tempo Finance Pvt Ltd" },
  { value: "Tenco Hire Purchase And Leasing Co Ltd", label: "Tenco Hire Purchase And Leasing Co Ltd" },
  { value: "Teradal Urban Souhard Credit Co-Op Bank Ltd", label: "Teradal Urban Souhard Credit Co-Op Bank Ltd" },
  { value: "Terana Nagari Sahakari Bank Ltd", label: "Terana Nagari Sahakari Bank Ltd" },
  { value: "Terdal Minority Co Operative Credit Society Ltd", label: "Terdal Minority Co Operative Credit Society Ltd" },
  { value: "Terdal Souharda Bank Ltd, Hanagandi", label: "Terdal Souharda Bank Ltd, Hanagandi" },
  { value: "Teri  Ott  Finance Company", label: "Teri  Ott  Finance Company" },
  { value: "Texcity Capitals", label: "Texcity Capitals" },
  { value: "Textile Co-Op Bank Ltd", label: "Textile Co-Op Bank Ltd" },
  { value: "Textile Co-Operative Bank Ltd.", label: "Textile Co-Operative Bank Ltd." },
  { value: "Textile Manufacturer Co-Op Bank Ltd", label: "Textile Manufacturer Co-Op Bank Ltd" },
  { value: "Textile Processor Co-Op Bank Ltd", label: "Textile Processor Co-Op Bank Ltd" },
  { value: "Textile Traders Co-Op Bank Ltd.", label: "Textile Traders Co-Op Bank Ltd." },
  { value: "Textile Traders Co-Op.Bank Ltd.", label: "Textile Traders Co-Op.Bank Ltd." },
  { value: "Tfsl Ltd", label: "Tfsl Ltd" },
  { value: "Tha Kaiwal Co-Opretive Credite Society Ltd", label: "Tha Kaiwal Co-Opretive Credite Society Ltd" },
  { value: "Tha Kalavamkodam Ser Cp Bk Ltd", label: "Tha Kalavamkodam Ser Cp Bk Ltd" },
  { value: "Thachinganadam Services Coop Bk Ltd", label: "Thachinganadam Services Coop Bk Ltd" },
  { value: "Thahira .T", label: "Thahira .T" },
  { value: "Thai Finance", label: "Thai Finance" },
  { value: "Thai Moogambigai Auto Finance", label: "Thai Moogambigai Auto Finance" },
  { value: "Thaikkattukara Service Co-Op Bank Ltd.", label: "Thaikkattukara Service Co-Op Bank Ltd." },
  { value: "Thakore Automobile", label: "Thakore Automobile" },
  { value: "Thakral Fin. Co", label: "Thakral Fin. Co" },
  { value: "Thakur Finance Corporation", label: "Thakur Finance Corporation" },
  { value: "Thakur Investments Pvt. Ltd.", label: "Thakur Investments Pvt. Ltd." },
  { value: "Thakurji Leasing And Finance Ltd.", label: "Thakurji Leasing And Finance Ltd." },
  { value: "Thalassery Taluk Merchant`S Wel. C.S.L.", label: "Thalassery Taluk Merchant`S Wel. C.S.L." },
  { value: "Thalavady Primary Agriculture C.O.B.L,", label: "Thalavady Primary Agriculture C.O.B.L," },
  { value: "Thandur Mahila Co Urban Bank", label: "Thandur Mahila Co Urban Bank" },
  { value: "Thane Bharat Sahakari Bank Ltd.", label: "Thane Bharat Sahakari Bank Ltd." },
  { value: "Thane Dist  Coop Bank Ltd", label: "Thane Dist  Coop Bank Ltd" },
  { value: "Thane Dist Madhyavarti Sah Bank Ltd", label: "Thane Dist Madhyavarti Sah Bank Ltd" },
  { value: "Thane District Central Co-Op Bank Ltd.", label: "Thane District Central Co-Op Bank Ltd." },
  { value: "Thane District Central Co-Operative Bank Ltd.", label: "Thane District Central Co-Operative Bank Ltd." },
  { value: "Thane Janata Sahakari Bank Ltd., Mumbai.", label: "Thane Janata Sahakari Bank Ltd., Mumbai." },
  { value: "Thane Janta Sahakari Bank Ltd", label: "Thane Janta Sahakari Bank Ltd" },
  { value: "Thane Peoples Co-Op Bank Ltd., Mumbai", label: "Thane Peoples Co-Op Bank Ltd., Mumbai" },
  { value: "Thaneeshwaran Finance", label: "Thaneeshwaran Finance" },
  { value: "Thangam Auto Finance", label: "Thangam Auto Finance" },
  { value: "Thangam Finance", label: "Thangam Finance" },
  { value: "Thanigaivel Auto Finance", label: "Thanigaivel Auto Finance" },
  { value: "Thanjavur Central Co-Op. Bank Ltd.", label: "Thanjavur Central Co-Op. Bank Ltd." },
  { value: "Thanjavur Commerce Pvt Ltd.", label: "Thanjavur Commerce Pvt Ltd." },
  { value: "Thanjavur District Central Co-Operative Bank Ltd.", label: "Thanjavur District Central Co-Operative Bank Ltd." },
  { value: "Thanusri Investment", label: "Thanusri Investment" },
  { value: "Thar Mfg And Exports P Ltd", label: "Thar Mfg And Exports P Ltd" },
  { value: "Thara Kavara Finance", label: "Thara Kavara Finance" },
  { value: "Tharun Jain Financier", label: "Tharun Jain Financier" },
  { value: "Thasra Peoples Co-Operative Bank Ltd", label: "Thasra Peoples Co-Operative Bank Ltd" },
  { value: "Thatha Auto Finance", label: "Thatha Auto Finance" },
  { value: "Thatha Finance Corporation", label: "Thatha Finance Corporation" },
  { value: "Thayer Overseas Finance Ltd.", label: "Thayer Overseas Finance Ltd." },
  { value: "Thazhava Service Co-Op Bank Ltd.", label: "Thazhava Service Co-Op Bank Ltd." },
  { value: "Thdc Ltd", label: "Thdc Ltd" },
  { value: "The  Jyoti Co. Op. Socity", label: "The  Jyoti Co. Op. Socity" },
  { value: "The  Nazareth Ur Coop Bk Ltd", label: "The  Nazareth Ur Coop Bk Ltd" },
  { value: "The  Vazhakkad Service Co-Op Bank Ltd", label: "The  Vazhakkad Service Co-Op Bank Ltd" },
  { value: "The A.B.E. Co.Op. Bank Ltd.", label: "The A.B.E. Co.Op. Bank Ltd." },
  { value: "The A.P. Mahesh Co-Op. Urban Bank Ltd.", label: "The A.P. Mahesh Co-Op. Urban Bank Ltd." },
  { value: "The A.P. Vardhaman(Mahila) Cp.Ur.Bk Ltd.", label: "The A.P. Vardhaman(Mahila) Cp.Ur.Bk Ltd." },
  { value: "The A.P.Raja Rajeswari Mah.Co.Urb.Bk.Ltd", label: "The A.P.Raja Rajeswari Mah.Co.Urb.Bk.Ltd" },
  { value: "The Abdul Rahiman S Coop Bk L", label: "The Abdul Rahiman S Coop Bk L" },
  { value: "The Abdurahiman Nagar Service Co. Bk Ltd", label: "The Abdurahiman Nagar Service Co. Bk Ltd" },
  { value: "The Abhinandan Sahakari Bank Niyamit", label: "The Abhinandan Sahakari Bank Niyamit" },
  { value: "The Accountant Generals Off.Emp.Co.Bk L", label: "The Accountant Generals Off.Emp.Co.Bk L" },
  { value: "The Accountant Generals Office Employees Co-Op Bank", label: "The Accountant Generals Office Employees Co-Op Bank" },
  { value: "The Achalpur Urban Co-Op.Bank Ltd.", label: "The Achalpur Urban Co-Op.Bank Ltd." },
  { value: "The Acharapakkam Co-Operative Rural Bk L", label: "The Acharapakkam Co-Operative Rural Bk L" },
  { value: "The Adarsh Co-Op. Urban Bank Ltd.", label: "The Adarsh Co-Op. Urban Bank Ltd." },
  { value: "The Adarsha Co-Operative Society Ltd", label: "The Adarsha Co-Operative Society Ltd" },
  { value: "The Adat Farmers Service Co-Op Bank Ltd.", label: "The Adat Farmers Service Co-Op Bank Ltd." },
  { value: "The Adhyapaka Co-Op Bank Ltd", label: "The Adhyapaka Co-Op Bank Ltd" },
  { value: "The Adichanalloor Farmers Sr Co-Op Bk Lt", label: "The Adichanalloor Farmers Sr Co-Op Bk Lt" },
  { value: "The Adilabad Dist. Co-Op Central Bank Lt", label: "The Adilabad Dist. Co-Op Central Bank Lt" },
  { value: "The Adinath Co Op Bank Ltd", label: "The Adinath Co Op Bank Ltd" },
  { value: "The Adinath Co-Op. Bank Ltd", label: "The Adinath Co-Op. Bank Ltd" },
  { value: "The Adoor Co-Op. Urban Bank Ltd.", label: "The Adoor Co-Op. Urban Bank Ltd." },
  { value: "The Adoor Co-Operative Urban Bank Ltd.", label: "The Adoor Co-Operative Urban Bank Ltd." },
  { value: "The Adoor Service Co-Operative Bank Ltd", label: "The Adoor Service Co-Operative Bank Ltd" },
  { value: "The Aechoor Service Co-Op Bank Ltd", label: "The Aechoor Service Co-Op Bank Ltd" },
  { value: "The Agra District Co-Op Bank Ltd.,", label: "The Agra District Co-Op Bank Ltd.," },
  { value: "The Agrasen Co-Op Urban Bank Ltd.", label: "The Agrasen Co-Op Urban Bank Ltd." },
  { value: "The Agrasen Nagari Sahakari Bank Ltd.", label: "The Agrasen Nagari Sahakari Bank Ltd." },
  { value: "The Agriculture Produce M.K.T. Commitee", label: "The Agriculture Produce M.K.T. Commitee" },
  { value: "The Ahmedabad Dist. Co-Op. Bank Ltd.", label: "The Ahmedabad Dist. Co-Op. Bank Ltd." },
  { value: "The Ahmedabad Maharashtria Co Op Credit Society", label: "The Ahmedabad Maharashtria Co Op Credit Society" },
  { value: "The Ahmedabad Mercantile Co-Op Bank Ltd.", label: "The Ahmedabad Mercantile Co-Op Bank Ltd." },
  { value: "The Ahmedabad Merchant Co Op Bank Ltd", label: "The Ahmedabad Merchant Co Op Bank Ltd" },
  { value: "The Ahmedabad Peoples Co-Op.Bank Ltd.", label: "The Ahmedabad Peoples Co-Op.Bank Ltd." },
  { value: "The Ahmednagar Dist. Cent. Co-Op. Bk Ltd", label: "The Ahmednagar Dist. Cent. Co-Op. Bk Ltd" },
  { value: "The Ahmednagar District Central Co-Op Bank Ltd", label: "The Ahmednagar District Central Co-Op Bank Ltd" },
  { value: "The Ahmednagar Merchants Co-Op Bank Ltd", label: "The Ahmednagar Merchants Co-Op Bank Ltd" },
  { value: "The Ahmednagar Sahakari Bank Ltd.", label: "The Ahmednagar Sahakari Bank Ltd." },
  { value: "The Ajara Arban Co Op Bank", label: "The Ajara Arban Co Op Bank" },
  { value: "The Ajara Urban Co-Op. Bank Ltd", label: "The Ajara Urban Co-Op. Bank Ltd" },
  { value: "The Ajara Urban Co-Op. Bank Ltd. Ajara,Kolhapur", label: "The Ajara Urban Co-Op. Bank Ltd. Ajara,Kolhapur" },
  { value: "The Ajara Urban Co.Op Bank Ltd", label: "The Ajara Urban Co.Op Bank Ltd" },
  { value: "The Ajmer Co- Op Bank Ltd", label: "The Ajmer Co- Op Bank Ltd" },
  { value: "The Ajmer Urban Co-Op Bank Ltd.", label: "The Ajmer Urban Co-Op Bank Ltd." },
  { value: "The Ajra Urban Co-Op. Bank Ltd.", label: "The Ajra Urban Co-Op. Bank Ltd." },
  { value: "The Akashganga Nagarik Seva Sahkari Mandali Ltd", label: "The Akashganga Nagarik Seva Sahkari Mandali Ltd" },
  { value: "The Akola Dis. Cen. Co-Op. Bk. Ltd.", label: "The Akola Dis. Cen. Co-Op. Bk. Ltd." },
  { value: "The Akola District Cent.Co-Op.Bank Ltd.", label: "The Akola District Cent.Co-Op.Bank Ltd." },
  { value: "The Akola Janata Commercial Co-Op Bank L", label: "The Akola Janata Commercial Co-Op Bank L" },
  { value: "The Akola Janta Com.Coop.B.L.", label: "The Akola Janta Com.Coop.B.L." },
  { value: "The Akola Janta Commercial Co-Op Bank Ltd.", label: "The Akola Janta Commercial Co-Op Bank Ltd." },
  { value: "The Akola Janta Commercial Co-Op Bk Ltd", label: "The Akola Janta Commercial Co-Op Bk Ltd" },
  { value: "The Akola Urban Co Bank Ltd,", label: "The Akola Urban Co Bank Ltd," },
  { value: "The Akola Urban Coop Bank Ltd, Akola", label: "The Akola Urban Coop Bank Ltd, Akola" },
  { value: "The Akot Urban Co-Op. Bank Ltd.", label: "The Akot Urban Co-Op. Bank Ltd." },
  { value: "The Akshaya Co Op Credit Society Ltd", label: "The Akshaya Co Op Credit Society Ltd" },
  { value: "The Alakode Co-Op Urban Bank Ltd", label: "The Alakode Co-Op Urban Bank Ltd" },
  { value: "The Alakode Service Co-Op Bank Ltd.", label: "The Alakode Service Co-Op Bank Ltd." },
  { value: "The Alanallur Service Co-Op Bank Ltd.", label: "The Alanallur Service Co-Op Bank Ltd." },
  { value: "The Alangulam Co-Op. Agricultural Bank L", label: "The Alangulam Co-Op. Agricultural Bank L" },
  { value: "The Alangulam Primary Agri.Co-Op.Bank Lt", label: "The Alangulam Primary Agri.Co-Op.Bank Lt" },
  { value: "The Alappuzha Dist. Co-Op. Bank Ltd.", label: "The Alappuzha Dist. Co-Op. Bank Ltd." },
  { value: "The Alappuzha District Co-Op. Bank Ltd.", label: "The Alappuzha District Co-Op. Bank Ltd." },
  { value: "The Alathur Primary Co-Op Agri Bank", label: "The Alathur Primary Co-Op Agri Bank" },
  { value: "The Alathur Service Co-Op Bank Ltd,", label: "The Alathur Service Co-Op Bank Ltd," },
  { value: "The Alibag Co-Op. Urban Bank Ltd.", label: "The Alibag Co-Op. Urban Bank Ltd." },
  { value: "The Aliparamba Service Co-Op. Bank Ltd.", label: "The Aliparamba Service Co-Op. Bank Ltd." },
  { value: "The Alleppey District Co-Op. Bank Ltd.", label: "The Alleppey District Co-Op. Bank Ltd." },
  { value: "The Alleppey Urban Co-Op. Bank Ltd.", label: "The Alleppey Urban Co-Op. Bank Ltd." },
  { value: "The Almel Urban Co-Op. Bank Ltd.", label: "The Almel Urban Co-Op. Bank Ltd." },
  { value: "The Almora Urban Co-Op. Bank Ltd.", label: "The Almora Urban Co-Op. Bank Ltd." },
  { value: "The Alnavar Urban Co-Op Bank Ltd", label: "The Alnavar Urban Co-Op Bank Ltd" },
  { value: "The Aluva Desabhivardhini Serv Coop Bk L", label: "The Aluva Desabhivardhini Serv Coop Bk L" },
  { value: "The Alwar Central Co-Op. Bank Ltd", label: "The Alwar Central Co-Op. Bank Ltd" },
  { value: "The Alwaye Urban Co-Op Bank Ltd", label: "The Alwaye Urban Co-Op Bank Ltd" },
  { value: "The Alwaye Urban Co-Op. Bank Ltd.", label: "The Alwaye Urban Co-Op. Bank Ltd." },
  { value: "The Amalapuram Co-Op. Town Bank Ltd.", label: "The Amalapuram Co-Op. Town Bank Ltd." },
  { value: "The Amalner Co-Op Urban Bank Ltd", label: "The Amalner Co-Op Urban Bank Ltd" },
  { value: "The Aman Minerals Co-Op Credit Ltd.", label: "The Aman Minerals Co-Op Credit Ltd." },
  { value: "The Amarambalam Ser. Co-Op. Bank Ltd.", label: "The Amarambalam Ser. Co-Op. Bank Ltd." },
  { value: "The Amaravathy Service Co-Oper Bank Ltd.", label: "The Amaravathy Service Co-Oper Bank Ltd." },
  { value: "The Ambala Central Cooperative Bank Ltd.", label: "The Ambala Central Cooperative Bank Ltd." },
  { value: "The Ambalapara Service Co-Op. Bank Ltd", label: "The Ambalapara Service Co-Op. Bank Ltd" },
  { value: "The Ambalavayal Ser.Co-Op. Bk.L.", label: "The Ambalavayal Ser.Co-Op. Bk.L." },
  { value: "The Amballur Ser Co-Op Bank Ltd,", label: "The Amballur Ser Co-Op Bank Ltd," },
  { value: "The Ambasamudram Co-Op.Urban Bank Ltd.", label: "The Ambasamudram Co-Op.Urban Bank Ltd." },
  { value: "The Amloh Primary Co Op Agricultural  Development Bank Ltd", label: "The Amloh Primary Co Op Agricultural  Development Bank Ltd" },
  { value: "The Ammadam Service Co-Op. Bank Ltd", label: "The Ammadam Service Co-Op. Bank Ltd" },
  { value: "The Ammapet Urban Co-Op Bank Ltd.,", label: "The Ammapet Urban Co-Op Bank Ltd.," },
  { value: "The Ammapet Urban Co.Op. Bank Ltd", label: "The Ammapet Urban Co.Op. Bank Ltd" },
  { value: "The Ammapet Urban Coop Bank Ltd", label: "The Ammapet Urban Coop Bank Ltd" },
  { value: "The Amod Nagric Co-Op.Bank Ltd.", label: "The Amod Nagric Co-Op.Bank Ltd." },
  { value: "The Amrambala Traders Devep C-Op Soc. Lt", label: "The Amrambala Traders Devep C-Op Soc. Lt" },
  { value: "The Amravati Dist. Cent. Co-Op Bank Ltd.", label: "The Amravati Dist. Cent. Co-Op Bank Ltd." },
  { value: "The Amravati District Cent Co-Op Bank Lt", label: "The Amravati District Cent Co-Op Bank Lt" },
  { value: "The Amravati Merchants Co-Op. Bank Ltd", label: "The Amravati Merchants Co-Op. Bank Ltd" },
  { value: "The Amravati Peoples Co-Op. Bank Ltd.", label: "The Amravati Peoples Co-Op. Bank Ltd." },
  { value: "The Amravati Zilla P.S.S.B.Maryadit", label: "The Amravati Zilla P.S.S.B.Maryadit" },
  { value: "The Amravati Zp Shikshak Sahakari Bank Ltd", label: "The Amravati Zp Shikshak Sahakari Bank Ltd" },
  { value: "The Amritsar Central Co-Op Bank Ltd", label: "The Amritsar Central Co-Op Bank Ltd" },
  { value: "The Amruta Mahila Co-Op. Bank Ltd.", label: "The Amruta Mahila Co-Op. Bank Ltd." },
  { value: "The Anad Farmers Service Co-Op Bank Ltd,", label: "The Anad Farmers Service Co-Op Bank Ltd," },
  { value: "The Anakkayam Service Co-Op Bank Ltd.", label: "The Anakkayam Service Co-Op Bank Ltd." },
  { value: "The Anamallais Estates Worker`S C.U.B.L.", label: "The Anamallais Estates Worker`S C.U.B.L." },
  { value: "The Anamangad Ser.Co-Op.Bk.L.", label: "The Anamangad Ser.Co-Op.Bk.L." },
  { value: "The Anand Mercantile Co Op Bank", label: "The Anand Mercantile Co Op Bank" },
  { value: "The Anand Mercantile Co-Op Bank Ltd", label: "The Anand Mercantile Co-Op Bank Ltd" },
  { value: "The Anand Peoples Co-Op.Bank Ltd.", label: "The Anand Peoples Co-Op.Bank Ltd." },
  { value: "The Anant Sahkari Sharafi Mandali Ltd", label: "The Anant Sahkari Sharafi Mandali Ltd" },
  { value: "The Anantapur Co-Operative Town Bank Ltd", label: "The Anantapur Co-Operative Town Bank Ltd" },
  { value: "The Anantapur District Co-Op Central B.L", label: "The Anantapur District Co-Op Central B.L" },
  { value: "The Ananthapuram Co-Op. Society Ltd.", label: "The Ananthapuram Co-Op. Society Ltd." },
  { value: "The Ananthavoor Services Coop Bk Ltd.", label: "The Ananthavoor Services Coop Bk Ltd." },
  { value: "The Anantnag Central Co-Op.Bk.L.", label: "The Anantnag Central Co-Op.Bk.L." },
  { value: "The Anapanthy Service Co-Op Bank Ltd", label: "The Anapanthy Service Co-Op Bank Ltd" },
  { value: "The Anavilasam Service Co-Op.Bank Ltd.", label: "The Anavilasam Service Co-Op.Bank Ltd." },
  { value: "The Andaman And Nicobar Islands Integrated Industrial Development Corporation", label: "The Andaman And Nicobar Islands Integrated Industrial Development Corporation" },
  { value: "The Andathode Service Co-Op Bank Ltd.,", label: "The Andathode Service Co-Op Bank Ltd.," },
  { value: "The Andhra Bank Employees Co-Op. Bank Ltd., Hyderabad", label: "The Andhra Bank Employees Co-Op. Bank Ltd., Hyderabad" },
  { value: "The Andhra Bk.Farm.Ser.Cop.Society Ltd", label: "The Andhra Bk.Farm.Ser.Cop.Society Ltd" },
  { value: "The Andhra Pradesh State Co-Op Bank Ltd.", label: "The Andhra Pradesh State Co-Op Bank Ltd." },
  { value: "The Angadipuram Ser.Co. Bk.L.", label: "The Angadipuram Ser.Co. Bk.L." },
  { value: "The Angul United Cet.Co-Op.B.L.,", label: "The Angul United Cet.Co-Op.B.L.," },
  { value: "The Angul Unitedcentral Coperative Bk L", label: "The Angul Unitedcentral Coperative Bk L" },
  { value: "The Anicad Service Co Op Bank Ltd", label: "The Anicad Service Co Op Bank Ltd" },
  { value: "The Anicadu Service Co-Op Bank Ltd.", label: "The Anicadu Service Co-Op Bank Ltd." },
  { value: "The Anicadu Service Co-Op.Bank Ltd.", label: "The Anicadu Service Co-Op.Bank Ltd." },
  { value: "The Anickad Regional Far.Serv.Co.Bank Lt", label: "The Anickad Regional Far.Serv.Co.Bank Lt" },
  { value: "The Anjangaon Surji Nagari S.Bank Ltd", label: "The Anjangaon Surji Nagari S.Bank Ltd" },
  { value: "The Anjangaon Surji Sahakari Bank Ltd.", label: "The Anjangaon Surji Sahakari Bank Ltd." },
  { value: "The Anjarakandy Farmers Ser Co. B", label: "The Anjarakandy Farmers Ser Co. B" },
  { value: "The Anjarakandy Farmers Service Cp Bk L", label: "The Anjarakandy Farmers Service Cp Bk L" },
  { value: "The Anjukunnu Service Co-Op Bank Ltd", label: "The Anjukunnu Service Co-Op Bank Ltd" },
  { value: "The Anjur Service C.O.S.L", label: "The Anjur Service C.O.S.L" },
  { value: "The Ankleshwar Udyognagar Co-Op Bank Ltd", label: "The Ankleshwar Udyognagar Co-Op Bank Ltd" },
  { value: "The Ankola Janata Commercial Co-Op Bank", label: "The Ankola Janata Commercial Co-Op Bank" },
  { value: "The Ankola Urban Co-Operative Bank Ltd.", label: "The Ankola Urban Co-Operative Bank Ltd." },
  { value: "The Annamanada Ser Co-Op Bank Ltd", label: "The Annamanada Ser Co-Op Bank Ltd" },
  { value: "The Annapurna Mahila Co-Op.Urban Bank Lt", label: "The Annapurna Mahila Co-Op.Urban Bank Lt" },
  { value: "The Annasaheb Savant Co Op Bank Ltd", label: "The Annasaheb Savant Co Op Bank Ltd" },
  { value: "The Annasaheb Sawant Co Op Urban Bank Mahad Ltd", label: "The Annasaheb Sawant Co Op Urban Bank Mahad Ltd" },
  { value: "The Ap Mahesh Co Operation Bank", label: "The Ap Mahesh Co Operation Bank" },
  { value: "The Ap State Co Operative  Bank Limited", label: "The Ap State Co Operative  Bank Limited" },
  { value: "The Ap Vardhaman (Mahila) Co-Op Bank Ltd", label: "The Ap Vardhaman (Mahila) Co-Op Bank Ltd" },
  { value: "The Arackal Ser. Co. Op. Bank Ltd", label: "The Arackal Ser. Co. Op. Bank Ltd" },
  { value: "The Arakkonam Co Op Urban Finance", label: "The Arakkonam Co Op Urban Finance" },
  { value: "The Arakkuparamba Service Co-Op Bank Ltd", label: "The Arakkuparamba Service Co-Op Bank Ltd" },
  { value: "The Arakulam Farmers Serv. C-P Bank Ltd", label: "The Arakulam Farmers Serv. C-P Bank Ltd" },
  { value: "The Arakuzha Service Co-Op Bank Ltd", label: "The Arakuzha Service Co-Op Bank Ltd" },
  { value: "The Aralam Service Co-Op. Bank Ltd", label: "The Aralam Service Co-Op. Bank Ltd" },
  { value: "The Arantangi Co-Op. Town Bank Ltd.", label: "The Arantangi Co-Op. Town Bank Ltd." },
  { value: "The Aranthodu Thodikana V. Seva S Sang N", label: "The Aranthodu Thodikana V. Seva S Sang N" },
  { value: "The Aranthodu Thodikana Vyaava Sev Sh B", label: "The Aranthodu Thodikana Vyaava Sev Sh B" },
  { value: "The Aravenu Primary Agrl Coop Bank Ltd", label: "The Aravenu Primary Agrl Coop Bank Ltd" },
  { value: "The Arbuda Bachat And Finance Shakari Mandali Ltd", label: "The Arbuda Bachat And Finance Shakari Mandali Ltd" },
  { value: "The Archarapakkam Co-Op Rural Bank Ltd.", label: "The Archarapakkam Co-Op Rural Bank Ltd." },
  { value: "The Arcot Co-Op Rural Bank Ltd", label: "The Arcot Co-Op Rural Bank Ltd" },
  { value: "The Arcot Co.Op. Urban Bank Ltd", label: "The Arcot Co.Op. Urban Bank Ltd" },
  { value: "The Areacode Service Co-Op. Bank Ltd.", label: "The Areacode Service Co-Op. Bank Ltd." },
  { value: "The Ariyur Service Co-Operative Bank Ltd", label: "The Ariyur Service Co-Operative Bank Ltd" },
  { value: "The Arkonam Co-Op Urban Bank Ltd.", label: "The Arkonam Co-Op Urban Bank Ltd." },
  { value: "The Arni Co-Op Town Bank Ltd.,", label: "The Arni Co-Op Town Bank Ltd.," },
  { value: "The Aroor Central Srevice Co-Op Bank Ltd", label: "The Aroor Central Srevice Co-Op Bank Ltd" },
  { value: "The Arpookara Service Co-Op Bank", label: "The Arpookara Service Co-Op Bank" },
  { value: "The Arsikere Urban Coop Bank Ltd", label: "The Arsikere Urban Coop Bank Ltd" },
  { value: "The Arsikere Urban Coop Bk Ltd,", label: "The Arsikere Urban Coop Bk Ltd," },
  { value: "The Arunachal Pradesh State Cp Apex Bk L", label: "The Arunachal Pradesh State Cp Apex Bk L" },
  { value: "The Aruvappulam Farmers Ser.Co.Bank Ltd.", label: "The Aruvappulam Farmers Ser.Co.Bank Ltd." },
  { value: "The Aruvappulam Farmers Service C.O.B.L,", label: "The Aruvappulam Farmers Service C.O.B.L," },
  { value: "The Arvind Mill", label: "The Arvind Mill" },
  { value: "The Arya Vaishya Co-Op. Bank Ltd.", label: "The Arya Vaishya Co-Op. Bank Ltd." },
  { value: "The Aryanad Service Coop Bank Ltd", label: "The Aryanad Service Coop Bank Ltd" },
  { value: "The Aryankavu Co-Op Bank Ltd,", label: "The Aryankavu Co-Op Bank Ltd," },
  { value: "The Aryapuram Co-Op. Urban Bank Ltd.", label: "The Aryapuram Co-Op. Urban Bank Ltd." },
  { value: "The Ashapuri Credit Society Ltd", label: "The Ashapuri Credit Society Ltd" },
  { value: "The Ashta Peoples Co-Op Bank Ltd", label: "The Ashta Peoples Co-Op Bank Ltd" },
  { value: "The Ashta Peoples Co-Op. Bank Ltd.", label: "The Ashta Peoples Co-Op. Bank Ltd." },
  { value: "The Ashta Urban Co.Op. Bank Ltd.", label: "The Ashta Urban Co.Op. Bank Ltd." },
  { value: "The Aska Co - Operative Central Bank Ltd", label: "The Aska Co - Operative Central Bank Ltd" },
  { value: "The Asodar Pardesh Co-Op Credit Society", label: "The Asodar Pardesh Co-Op Credit Society" },
  { value: "The Assam City Co-Operative Bank Ltd", label: "The Assam City Co-Operative Bank Ltd" },
  { value: "The Assam Co-Op. Apex Bank Ltd", label: "The Assam Co-Op. Apex Bank Ltd" },
  { value: "The Assam State Co-Op Agr & Ru Dev Bk", label: "The Assam State Co-Op Agr & Ru Dev Bk" },
  { value: "The Assistant Commissioner & Central Excise & Customs, Surat.", label: "The Assistant Commissioner & Central Excise & Customs, Surat." },
  { value: "The Associate India Fin Service", label: "The Associate India Fin Service" },
  { value: "The Athani Co-Operative Credit Society Ltd.", label: "The Athani Co-Operative Credit Society Ltd." },
  { value: "The Athavanad Service Co-Op Bank Ltd.", label: "The Athavanad Service Co-Op Bank Ltd." },
  { value: "The Athirampuzha Regional Ser. Cp. Bk. L", label: "The Athirampuzha Regional Ser. Cp. Bk. L" },
  { value: "The Athola Co-Op Agricultural Multipurpose Socity Ltd", label: "The Athola Co-Op Agricultural Multipurpose Socity Ltd" },
  { value: "The Attappady Services Co-Op Bank Ltd", label: "The Attappady Services Co-Op Bank Ltd" },
  { value: "The Attur Town Co-Op. Bank Ltd", label: "The Attur Town Co-Op. Bank Ltd" },
  { value: "The Aurangabad Dist Indust & U.C.O.B.Ltd", label: "The Aurangabad Dist Indust & U.C.O.B.Ltd" },
  { value: "The Aurangabad Dist. Cent. Co. Bank Ltd", label: "The Aurangabad Dist. Cent. Co. Bank Ltd" },
  { value: "The Aurangabad District Cen.Co-Op.Bk Ltd", label: "The Aurangabad District Cen.Co-Op.Bk Ltd" },
  { value: "The Aurangabad District Cent Cp Bank Ltd", label: "The Aurangabad District Cent Cp Bank Ltd" },
  { value: "The Aurangabad Dt.C.Co.B.L.,Sillod", label: "The Aurangabad Dt.C.Co.B.L.,Sillod" },
  { value: "The Avaneeswaram Service Co-Op. Bank Ltd", label: "The Avaneeswaram Service Co-Op. Bank Ltd" },
  { value: "The Avilora Services Co-Operative Bank L", label: "The Avilora Services Co-Operative Bank L" },
  { value: "The Avittathur Service Co-Op.Bank Ltd.", label: "The Avittathur Service Co-Op.Bank Ltd." },
  { value: "The Avoly Ser Co-Op Bank Ltd,", label: "The Avoly Ser Co-Op Bank Ltd," },
  { value: "The Ayiroopara Farmers Serv Co-Op Bank L", label: "The Ayiroopara Farmers Serv Co-Op Bank L" },
  { value: "The Ayiroor Ser Co-Op Bk Lt,", label: "The Ayiroor Ser Co-Op Bk Lt," },
  { value: "The Azad Co-Op. Bank Ltd.", label: "The Azad Co-Op. Bank Ltd." },
  { value: "The Azad Urban Co-Op Bank Ltd.", label: "The Azad Urban Co-Op Bank Ltd." },
  { value: "The Azad Urban Co-Op. Bank Ltd.", label: "The Azad Urban Co-Op. Bank Ltd." },
  { value: "The Azhiyoor Vanitha Co-Op.Society Ltd", label: "The Azhiyoor Vanitha Co-Op.Society Ltd" },
  { value: "The B.Komarapalayam Co-Op.Urban Bank Ltd", label: "The B.Komarapalayam Co-Op.Urban Bank Ltd" },
  { value: "The Badagara Co-Op. Uraban Bank Ltd", label: "The Badagara Co-Op. Uraban Bank Ltd" },
  { value: "The Bagalkot Co-Op. Bank Ltd.", label: "The Bagalkot Co-Op. Bank Ltd." },
  { value: "The Bagalkot District Central Co-Op Bank", label: "The Bagalkot District Central Co-Op Bank" },
  { value: "The Bagayatdar Urban Co. Credit Soc.Ltd", label: "The Bagayatdar Urban Co. Credit Soc.Ltd" },
  { value: "The Baghat Urban Co.Op. Bank Ltd", label: "The Baghat Urban Co.Op. Bank Ltd" },
  { value: "The Bahadarpur Urban Co-Operative Bank L", label: "The Bahadarpur Urban Co-Operative Bank L" },
  { value: "The Baidyabati Sheoraphuli Co-Op Bank Lt", label: "The Baidyabati Sheoraphuli Co-Op Bank Lt" },
  { value: "The Bailhongal Merchants Co-Op.Bank Ltd", label: "The Bailhongal Merchants Co-Op.Bank Ltd" },
  { value: "The Bailhongal Urban Co - Op Bank Ltd.", label: "The Bailhongal Urban Co - Op Bank Ltd." },
  { value: "The Bailhongal Urban Co-Op. Bank Ltd.", label: "The Bailhongal Urban Co-Op. Bank Ltd." },
  { value: "The Bailhongal Urban Co.Op. Bank Ltd", label: "The Bailhongal Urban Co.Op. Bank Ltd" },
  { value: "The Balagram Services Cooperatives Bankl", label: "The Balagram Services Cooperatives Bankl" },
  { value: "The Balangir District Central Co-Op Bank", label: "The Balangir District Central Co-Op Bank" },
  { value: "The Balangir District Central Coop B Ltd", label: "The Balangir District Central Coop B Ltd" },
  { value: "The Balaramapuram Service Co-Op. Bank L.", label: "The Balaramapuram Service Co-Op. Bank L." },
  { value: "The Balasore Bhadark C Co-Op Bk Ltd", label: "The Balasore Bhadark C Co-Op Bk Ltd" },
  { value: "The Balasore Bhadrak Central Coop Bank L", label: "The Balasore Bhadrak Central Coop Bank L" },
  { value: "The Balasore Co-Op. Urban Bank Ltd.", label: "The Balasore Co-Op. Urban Bank Ltd." },
  { value: "The Balasore Dist. Co. Cent. Bk Ltd", label: "The Balasore Dist. Co. Cent. Bk Ltd" },
  { value: "The Balele Center Rural Co.Op. Bank Ltd.", label: "The Balele Center Rural Co.Op. Bank Ltd." },
  { value: "The Balsore Dist. Co-Op.Central Bank Ltd", label: "The Balsore Dist. Co-Op.Central Bank Ltd" },
  { value: "The Balurghat Central Co-Op. Bank Ltd.", label: "The Balurghat Central Co-Op. Bank Ltd." },
  { value: "The Balusseri Co-Op. Urban Bank Ltd.", label: "The Balusseri Co-Op. Urban Bank Ltd." },
  { value: "The Banaskantha Dist. Cen. Cp. Bank Ltd.", label: "The Banaskantha Dist. Cen. Cp. Bank Ltd." },
  { value: "The Banaskantha Mehsana Gramin Bank", label: "The Banaskantha Mehsana Gramin Bank" },
  { value: "The Banaskantha Mercantile Co Op Bank Ltd", label: "The Banaskantha Mercantile Co Op Bank Ltd" },
  { value: "The Banaskantha Mercantile Co-Op. Bank L", label: "The Banaskantha Mercantile Co-Op. Bank L" },
  { value: "The Bandadka Vanitha Service Co-Op Bank", label: "The Bandadka Vanitha Service Co-Op Bank" },
  { value: "The Bandtra Cooperatives Bank Ltd", label: "The Bandtra Cooperatives Bank Ltd" },
  { value: "The Bangalore Central C-Op Bank Ltd", label: "The Bangalore Central C-Op Bank Ltd" },
  { value: "The Bangalore City Co-Operative Bank Ltd", label: "The Bangalore City Co-Operative Bank Ltd" },
  { value: "The Bangalore Commercial Transporters Credit Co-Op Society Ltd.", label: "The Bangalore Commercial Transporters Credit Co-Op Society Ltd." },
  { value: "The Bangalore District Co-Operative Bk.L", label: "The Bangalore District Co-Operative Bk.L" },
  { value: "The Bangalore Dit.& Bang.Rur D.Co Cen Bk", label: "The Bangalore Dit.& Bang.Rur D.Co Cen Bk" },
  { value: "The Bangalore Dt.Coop.Bk.L.,Banglore", label: "The Bangalore Dt.Coop.Bk.L.,Banglore" },
  { value: "The Bangalore Dt.Coop.Ct.B.L.,Bangalore", label: "The Bangalore Dt.Coop.Ct.B.L.,Bangalore" },
  { value: "The Banhatti Minority Urban Co-Op Credit Society Ltd", label: "The Banhatti Minority Urban Co-Op Credit Society Ltd" },
  { value: "The Bank Employees Cooperative Bank Ltd", label: "The Bank Employees Cooperative Bank Ltd" },
  { value: "The Bank Of Jodhpur", label: "The Bank Of Jodhpur" },
  { value: "The Bank Of Nova Scotia", label: "The Bank Of Nova Scotia" },
  { value: "The Bank Of Nova Scotia", label: "The Bank Of Nova Scotia" },
  { value: "The Bank Of Nova Soctia", label: "The Bank Of Nova Soctia" },
  { value: "The Bank Of Rajasthan Ltd.", label: "The Bank Of Rajasthan Ltd." },
  { value: "The Bank Of Tokya-Mitsubishi Ltd", label: "The Bank Of Tokya-Mitsubishi Ltd" },
  { value: "The Bank Of Tokyo Ltd.", label: "The Bank Of Tokyo Ltd." },
  { value: "The Banki Central Co-Operative Bank Ltd", label: "The Banki Central Co-Operative Bank Ltd" },
  { value: "The Bankra Co-Operative Credit Society Ltd.", label: "The Bankra Co-Operative Credit Society Ltd." },
  { value: "The Banswara Central Co-Op. Bank Ltd", label: "The Banswara Central Co-Op. Bank Ltd" },
  { value: "The Bantra Co.Oerative Bank Ltd.", label: "The Bantra Co.Oerative Bank Ltd." },
  { value: "The Bapunagar Mahila Co-Op Bank Ltd", label: "The Bapunagar Mahila Co-Op Bank Ltd" },
  { value: "The Baramati Sahakari Bank Ltd", label: "The Baramati Sahakari Bank Ltd" },
  { value: "The Baramati Urban Co-Op. Bank Ltd", label: "The Baramati Urban Co-Op. Bank Ltd" },
  { value: "The Baramulla Central Co-Op Bank Ltd.", label: "The Baramulla Central Co-Op Bank Ltd." },
  { value: "The Bardoli Nagarik Sahakari Bank Ltd", label: "The Bardoli Nagarik Sahakari Bank Ltd" },
  { value: "The Bardoli Nagrik Sahakari Bank Ltd.", label: "The Bardoli Nagrik Sahakari Bank Ltd." },
  { value: "The Baripada Urban Co-Operative Bank Ltd", label: "The Baripada Urban Co-Operative Bank Ltd" },
  { value: "The Barmer Central Co-Operative Bank Ltd", label: "The Barmer Central Co-Operative Bank Ltd" },
  { value: "The Barner Co-Op Bank Ltd", label: "The Barner Co-Op Bank Ltd" },
  { value: "The Baroda Central Co-Op Bank L", label: "The Baroda Central Co-Op Bank L" },
  { value: "The Baroda Central Co-Op Bank Ltd.", label: "The Baroda Central Co-Op Bank Ltd." },
  { value: "The Baroda City Coop Bank Ltd.", label: "The Baroda City Coop Bank Ltd." },
  { value: "The Baroda Peolpes Co-Op Bank Ltd.", label: "The Baroda Peolpes Co-Op Bank Ltd." },
  { value: "The Baroda Traders Co-Op. Bank Ltd.", label: "The Baroda Traders Co-Op. Bank Ltd." },
  { value: "The Batlagundu Co-Op.Urban Bank Ltd.", label: "The Batlagundu Co-Op.Urban Bank Ltd." },
  { value: "The Bavahir Sahkari Sharafi Mandali Ltd", label: "The Bavahir Sahkari Sharafi Mandali Ltd" },
  { value: "The Bayad Nagarik Sahakari Bank Ltd.", label: "The Bayad Nagarik Sahakari Bank Ltd." },
  { value: "The Bdcc Bank", label: "The Bdcc Bank" },
  { value: "The Bechraji Nagarik Sahakari Bank Ltd.", label: "The Bechraji Nagarik Sahakari Bank Ltd." },
  { value: "The Bedadka Farmers Service Co-Op. Bk Lt", label: "The Bedadka Farmers Service Co-Op. Bk Lt" },
  { value: "The Bedkihal Urban Co-Op Bank  Ltd", label: "The Bedkihal Urban Co-Op Bank  Ltd" },
  { value: "The Bedkihal Urban Co-Op Bank Ltd.", label: "The Bedkihal Urban Co-Op Bank Ltd." },
  { value: "The Beed District Central Coop. Bank Ltd", label: "The Beed District Central Coop. Bank Ltd" },
  { value: "The Begpore Service Co-Op. Bank Ltd.", label: "The Begpore Service Co-Op. Bank Ltd." },
  { value: "The Begusarai Central Co-Op Bank Ltd", label: "The Begusarai Central Co-Op Bank Ltd" },
  { value: "The Belgaum Bakers Co-Op Credit Society Ltd", label: "The Belgaum Bakers Co-Op Credit Society Ltd" },
  { value: "The Belgaum Catholic Co-Op Bank Ltd", label: "The Belgaum Catholic Co-Op Bank Ltd" },
  { value: "The Belgaum Catholic Co.Op Bank Ltd", label: "The Belgaum Catholic Co.Op Bank Ltd" },
  { value: "The Belgaum Dist. Revenue Emp. Co.Bk Ltd", label: "The Belgaum Dist. Revenue Emp. Co.Bk Ltd" },
  { value: "The Belgaum District Central Co-Operative Bank Ltd", label: "The Belgaum District Central Co-Operative Bank Ltd" },
  { value: "The Belgaum District Central Co. Bk Ltd", label: "The Belgaum District Central Co. Bk Ltd" },
  { value: "The Belgaum District Vegetables Growers Purchasers And Sellers Co-Op Society Ltd.", label: "The Belgaum District Vegetables Growers Purchasers And Sellers Co-Op Society Ltd." },
  { value: "The Belgaum Industrial Co-Operative Bank Ltd", label: "The Belgaum Industrial Co-Operative Bank Ltd" },
  { value: "The Belgaum Merchant Co-Op Cr Society Ltd.", label: "The Belgaum Merchant Co-Op Cr Society Ltd." },
  { value: "The Belgaum Pioneer Urban Co-Op Credit Bank Ltd", label: "The Belgaum Pioneer Urban Co-Op Credit Bank Ltd" },
  { value: "The Belgaum Pioneer Urban Co. Cr.Bk Ltd.", label: "The Belgaum Pioneer Urban Co. Cr.Bk Ltd." },
  { value: "The Belgaum Revenue Employees Co. Bk Ltd", label: "The Belgaum Revenue Employees Co. Bk Ltd" },
  { value: "The Belgaum Western Zone Co-Operative Credit Society Ltd.", label: "The Belgaum Western Zone Co-Operative Credit Society Ltd." },
  { value: "The Belgaum Zilla Rani Channamma Mahila Sahakari Bank Niyamit.", label: "The Belgaum Zilla Rani Channamma Mahila Sahakari Bank Niyamit." },
  { value: "The Belgaum-Zilla Rani Channamma Mah Sbn", label: "The Belgaum-Zilla Rani Channamma Mah Sbn" },
  { value: "The Belguam Industrial Co-Op. Bank Ltd.", label: "The Belguam Industrial Co-Op. Bank Ltd." },
  { value: "The Bellary District Co-Op.Central Bank", label: "The Bellary District Co-Op.Central Bank" },
  { value: "The Benares State Bank Limited", label: "The Benares State Bank Limited" },
  { value: "The Berhampore Co-Op. Central Bank Ltd.", label: "The Berhampore Co-Op. Central Bank Ltd." },
  { value: "The Bgm Dist Vegetables G.P. & Soco-Op Sc Ltd.", label: "The Bgm Dist Vegetables G.P. & Soco-Op Sc Ltd." },
  { value: "The Bhabhar Vibhag Nagrik Sahakari Bk.L", label: "The Bhabhar Vibhag Nagrik Sahakari Bk.L" },
  { value: "The Bhadgaon Peoples Co-Op Bank Ltd.", label: "The Bhadgaon Peoples Co-Op Bank Ltd." },
  { value: "The Bhadgaon Peoples Co-Op. Bank Ltd", label: "The Bhadgaon Peoples Co-Op. Bank Ltd" },
  { value: "The Bhadrak Coop Urban Bank Ltd", label: "The Bhadrak Coop Urban Bank Ltd" },
  { value: "The Bhadran People`S Co-Op. Bank Ltd.", label: "The Bhadran People`S Co-Op. Bank Ltd." },
  { value: "The Bhadresar Group Seva Sahkari Mandli Ltd", label: "The Bhadresar Group Seva Sahkari Mandli Ltd" },
  { value: "The Bhagalpur Central Co-Op Bank Ltd", label: "The Bhagalpur Central Co-Op Bank Ltd" },
  { value: "The Bhagyalakshmi Mahila Sahakari Bank L", label: "The Bhagyalakshmi Mahila Sahakari Bank L" },
  { value: "The Bhagyalaxmi Mahila Sahkari Bank Ltd.", label: "The Bhagyalaxmi Mahila Sahkari Bank Ltd." },
  { value: "The Bhagyoday Safai Sahakari Mandali Ltd", label: "The Bhagyoday Safai Sahakari Mandali Ltd" },
  { value: "The Bhagyodaya Co-Operative Bank Ltd", label: "The Bhagyodaya Co-Operative Bank Ltd" },
  { value: "The Bhagyodaya Mahila Co - Op Credit Society Ltd.", label: "The Bhagyodaya Mahila Co - Op Credit Society Ltd." },
  { value: "The Bhagyodaya Sharafi Sah. Mandli Ltd.", label: "The Bhagyodaya Sharafi Sah. Mandli Ltd." },
  { value: "The Bhandara Dist. Central Co Op Bank Lt", label: "The Bhandara Dist. Central Co Op Bank Lt" },
  { value: "The Bhandara Dist.Central Co-Op. Bank Lt", label: "The Bhandara Dist.Central Co-Op. Bank Lt" },
  { value: "The Bhandara District Central Co.Bk Ltd.", label: "The Bhandara District Central Co.Bk Ltd." },
  { value: "The Bhandara Mahila Nag Sahakari Pathsanstha", label: "The Bhandara Mahila Nag Sahakari Pathsanstha" },
  { value: "The Bhandara Urban Co-Op Bank Ltd", label: "The Bhandara Urban Co-Op Bank Ltd" },
  { value: "The Bhandara Urban Co-Op Bank Ltd.", label: "The Bhandara Urban Co-Op Bank Ltd." },
  { value: "The Bharanickauoo Services Coop Bank.", label: "The Bharanickauoo Services Coop Bank." },
  { value: "The Bharat Cent Coop Bank Ltd", label: "The Bharat Cent Coop Bank Ltd" },
  { value: "The Bharat Co-Op Bank Ltd.", label: "The Bharat Co-Op Bank Ltd." },
  { value: "The Bharat Co-Op Bank Ltd., Banglore", label: "The Bharat Co-Op Bank Ltd., Banglore" },
  { value: "The Bharat Co-Op. Bank (Mumbai) Ltd.", label: "The Bharat Co-Op. Bank (Mumbai) Ltd." },
  { value: "The Bharat Co-Op. Bank Ltd.", label: "The Bharat Co-Op. Bank Ltd." },
  { value: "The Bharat Finance And Industries Pvt Ltd", label: "The Bharat Finance And Industries Pvt Ltd" },
  { value: "The Bharat Petrolium Corporation Ltd", label: "The Bharat Petrolium Corporation Ltd" },
  { value: "The Bharat Sahakari Bank Ltd.", label: "The Bharat Sahakari Bank Ltd." },
  { value: "The Bharathi Co Op Credit Society", label: "The Bharathi Co Op Credit Society" },
  { value: "The Bharatpur Cent. Co-Op. Bank Ltd", label: "The Bharatpur Cent. Co-Op. Bank Ltd" },
  { value: "The Bharatpur Central Co-Op Bank Ltd,", label: "The Bharatpur Central Co-Op Bank Ltd," },
  { value: "The Bharatpur Central Co-Op Bank Ltd.", label: "The Bharatpur Central Co-Op Bank Ltd." },
  { value: "The Bharatpur Urban Co-Op. Bank Ltd.", label: "The Bharatpur Urban Co-Op. Bank Ltd." },
  { value: "The Bharuch District Central Co Op Bank", label: "The Bharuch District Central Co Op Bank" },
  { value: "The Bharuch Nagaric Sah Bk L", label: "The Bharuch Nagaric Sah Bk L" },
  { value: "The Bharuch Nagarik Sahakari Bank Ltd.", label: "The Bharuch Nagarik Sahakari Bank Ltd." },
  { value: "The Bhatinda Central Co-Op Bank Ltd.", label: "The Bhatinda Central Co-Op Bank Ltd." },
  { value: "The Bhatkal Urban Co-Op Bank - Bhatkal", label: "The Bhatkal Urban Co-Op Bank - Bhatkal" },
  { value: "The Bhatkal Urban Co-Op. Bank Ltd", label: "The Bhatkal Urban Co-Op. Bank Ltd" },
  { value: "The Bhavana Rishi Co.Op. Urban Bank Ltd.", label: "The Bhavana Rishi Co.Op. Urban Bank Ltd." },
  { value: "The Bhavani Sahakari Bank Ltd", label: "The Bhavani Sahakari Bank Ltd" },
  { value: "The Bhavanikudal Co-Op Urban Bank Ltd", label: "The Bhavanikudal Co-Op Urban Bank Ltd" },
  { value: "The Bhavanikudal Co-Op. Urban Bank Ltd", label: "The Bhavanikudal Co-Op. Urban Bank Ltd" },
  { value: "The Bhavasara Kshatriya Co-Op Bank Ltd", label: "The Bhavasara Kshatriya Co-Op Bank Ltd" },
  { value: "The Bhavasara Kshatriya Co-Op.Bank Ltd.", label: "The Bhavasara Kshatriya Co-Op.Bank Ltd." },
  { value: "The Bhavnagar Welfare Co-Op.Bank Ltd", label: "The Bhavnagar Welfare Co-Op.Bank Ltd" },
  { value: "The Bhawanipatna Central Co-Op. Bank Ltd", label: "The Bhawanipatna Central Co-Op. Bank Ltd" },
  { value: "The Bhiloda Nagrik Sharati Sahakari Mandli Ltd", label: "The Bhiloda Nagrik Sharati Sahakari Mandli Ltd" },
  { value: "The Bhiwani Central Co Operative Bank", label: "The Bhiwani Central Co Operative Bank" },
  { value: "The Bhiwani Central Co-Op. Bank Ltd.", label: "The Bhiwani Central Co-Op. Bank Ltd." },
  { value: "The Bhuj Commercial Co-Op. Bank Ltd.", label: "The Bhuj Commercial Co-Op. Bank Ltd." },
  { value: "The Bhuj Mercantile Co-Op Bank Ltd.", label: "The Bhuj Mercantile Co-Op Bank Ltd." },
  { value: "The Bhusawal Peoples Co-Op Bank Ltd", label: "The Bhusawal Peoples Co-Op Bank Ltd" },
  { value: "The Bhusawal Peoples Co.B.L.,Muktainagar", label: "The Bhusawal Peoples Co.B.L.,Muktainagar" },
  { value: "The Bhuvaneshwari Mahila Sangh", label: "The Bhuvaneshwari Mahila Sangh" },
  { value: "The Bicholim Urban Co.Op. Bank Ltd", label: "The Bicholim Urban Co.Op. Bank Ltd" },
  { value: "The Bidar Urban Co-Op. Bk Ltd.", label: "The Bidar Urban Co-Op. Bk Ltd." },
  { value: "The Big Kanchipuram Co-Op. Town Bank Ltd", label: "The Big Kanchipuram Co-Op. Town Bank Ltd" },
  { value: "The Bihar Awami Co Operative Bank", label: "The Bihar Awami Co Operative Bank" },
  { value: "The Bihar Awami Co- Op Bank Ltd", label: "The Bihar Awami Co- Op Bank Ltd" },
  { value: "The Bihar State Co-Operative Bank Ltd.", label: "The Bihar State Co-Operative Bank Ltd." },
  { value: "The Biharawami Co-Operative Bank Ltd", label: "The Biharawami Co-Operative Bank Ltd" },
  { value: "The Bijapur Dist. Central Co-Op.Bank Ltd", label: "The Bijapur Dist. Central Co-Op.Bank Ltd" },
  { value: "The Bijapur District Mahila Co-Op Bk Ltd", label: "The Bijapur District Mahila Co-Op Bk Ltd" },
  { value: "The Bijnor Urban Co-Operative Bank Ltd.", label: "The Bijnor Urban Co-Operative Bank Ltd." },
  { value: "The Birbhum Dist. Central Co-Op. Bank Lt", label: "The Birbhum Dist. Central Co-Op. Bank Lt" },
  { value: "The Birikulam Service Co-Op Bank Ltd.", label: "The Birikulam Service Co-Op Bank Ltd." },
  { value: "The Bishnupur Town Co Operative Bank Ltd", label: "The Bishnupur Town Co Operative Bank Ltd" },
  { value: "The Board Of Trustees Of The Part Of Mumbai", label: "The Board Of Trustees Of The Part Of Mumbai" },
  { value: "The Bobbili Co-Op Urban Bank Ltd.", label: "The Bobbili Co-Op Urban Bank Ltd." },
  { value: "The Bodeli Urban Co.Op Bank Ltd", label: "The Bodeli Urban Co.Op Bank Ltd" },
  { value: "The Bodigama Sahakari Mini Bank", label: "The Bodigama Sahakari Mini Bank" },
  { value: "The Boral Union Co-Op. Bank Ltd.", label: "The Boral Union Co-Op. Bank Ltd." },
  { value: "The Boriavi Peoples Co-Op Bank Ltd", label: "The Boriavi Peoples Co-Op Bank Ltd" },
  { value: "The Boriavi Peoples Co-Op Bank Ltd.", label: "The Boriavi Peoples Co-Op Bank Ltd." },
  { value: "The Boripur Co-Op.Cre.Ser.Soc.Ltd.", label: "The Boripur Co-Op.Cre.Ser.Soc.Ltd." },
  { value: "The Borsad Ind Co Op Credit Soc", label: "The Borsad Ind Co Op Credit Soc" },
  { value: "The Botad Peoples Co-Op. Bank Ltd", label: "The Botad Peoples Co-Op. Bank Ltd" },
  { value: "The Boudh Co-Op. Central Bk Ltd.", label: "The Boudh Co-Op. Central Bk Ltd." },
  { value: "The British Bank Of The Middle East", label: "The British Bank Of The Middle East" },
  { value: "The Broach Dist. Central Co-Op. Bank Ltd", label: "The Broach Dist. Central Co-Op. Bank Ltd" },
  { value: "The Buldana District Central Cp Bank Ltd", label: "The Buldana District Central Cp Bank Ltd" },
  { value: "The Bundi Central Co-Operative Bank Ltd,", label: "The Bundi Central Co-Operative Bank Ltd," },
  { value: "The Bundi Urban Co-Op Bank Ltd", label: "The Bundi Urban Co-Op Bank Ltd" },
  { value: "The Bundi Urban Co-Op.Bank Ltd.", label: "The Bundi Urban Co-Op.Bank Ltd." },
  { value: "The Bundi Urban Cop. Bank. Bundi", label: "The Bundi Urban Cop. Bank. Bundi" },
  { value: "The Burdwan Central Co Operative Bank Lt", label: "The Burdwan Central Co Operative Bank Lt" },
  { value: "The Burdwan Central Co-Op Bank Ltd.", label: "The Burdwan Central Co-Op Bank Ltd." },
  { value: "The Burdwan Central Co-Op. Bank Ltd", label: "The Burdwan Central Co-Op. Bank Ltd" },
  { value: "The Business Coop Bank Ltd", label: "The Business Coop Bank Ltd" },
  { value: "The C.D. Co-Op Credit Society Ltd", label: "The C.D. Co-Op Credit Society Ltd" },
  { value: "The C.K.P. Co-Operative Bank Ltd", label: "The C.K.P. Co-Operative Bank Ltd" },
  { value: "The Calicut Co-Operative Urban Bank Ltd", label: "The Calicut Co-Operative Urban Bank Ltd" },
  { value: "The Calicut North Service Co.Op Bank Ltd", label: "The Calicut North Service Co.Op Bank Ltd" },
  { value: "The Cambay Hindu Merchants Co-Op Bank Lt", label: "The Cambay Hindu Merchants Co-Op Bank Lt" },
  { value: "The Cambay Mercantile Co Op Credit Soc Ltd", label: "The Cambay Mercantile Co Op Credit Soc Ltd" },
  { value: "The Cannanore Co-Op.Urban Bank Ltd.", label: "The Cannanore Co-Op.Urban Bank Ltd." },
  { value: "The Capital Credit Co.Op. Ltd.", label: "The Capital Credit Co.Op. Ltd." },
  { value: "The Capital Transportation Financial Services Ltd", label: "The Capital Transportation Financial Services Ltd" },
  { value: "The Cardamom Merchants Co-Op Bank Ltd", label: "The Cardamom Merchants Co-Op Bank Ltd" },
  { value: "The Catholic Co-Op. Urban Bank Ltd.", label: "The Catholic Co-Op. Urban Bank Ltd." },
  { value: "The Catholic Syrian Bank Ltd.", label: "The Catholic Syrian Bank Ltd." },
  { value: "The Catholic Syrian Bank Ltd.", label: "The Catholic Syrian Bank Ltd." },
  { value: "The Central Co-Op. Bank Ltd.", label: "The Central Co-Op. Bank Ltd." },
  { value: "The Central Services Co-Op Society Ltd", label: "The Central Services Co-Op Society Ltd" },
  { value: "The Centrals Farmers Service Co Op Society Ltd", label: "The Centrals Farmers Service Co Op Society Ltd" },
  { value: "The Centtiamparamba Service Co-Op Bank L", label: "The Centtiamparamba Service Co-Op Bank L" },
  { value: "The Century Co Op Banl Ltd", label: "The Century Co Op Banl Ltd" },
  { value: "The Century Co-Op. Bank Ltd., Surat", label: "The Century Co-Op. Bank Ltd., Surat" },
  { value: "The Chachar Krupa Vikas Co-Op Credit & Supply Soc Ltd", label: "The Chachar Krupa Vikas Co-Op Credit & Supply Soc Ltd" },
  { value: "The Chachinganadam Services Co. Bk L", label: "The Chachinganadam Services Co. Bk L" },
  { value: "The Chadayamangalam Service Co-Op Bk Ltd", label: "The Chadayamangalam Service Co-Op Bk Ltd" },
  { value: "The Chadchan Shree Sangameshwar Urb Cp B", label: "The Chadchan Shree Sangameshwar Urb Cp B" },
  { value: "The Chairman Tneb", label: "The Chairman Tneb" },
  { value: "The Chaitanya Co-Op Urban Bank Ltd.", label: "The Chaitanya Co-Op Urban Bank Ltd." },
  { value: "The Chaitanya Malula Co Op Bank Ltd", label: "The Chaitanya Malula Co Op Bank Ltd" },
  { value: "The Chaitanya Malula Co-Op Bank Ltd", label: "The Chaitanya Malula Co-Op Bank Ltd" },
  { value: "The Chakkarakal Co-Op. Urban Bank Ltd.", label: "The Chakkarakal Co-Op. Urban Bank Ltd." },
  { value: "The Chakkarakkal Urban Co-Op. Society Lt", label: "The Chakkarakkal Urban Co-Op. Society Lt" },
  { value: "The Chakkittapara Service Co-Op Bank Ltd", label: "The Chakkittapara Service Co-Op Bank Ltd" },
  { value: "The Chakkupallam S.Co-Op Bank Ltd.", label: "The Chakkupallam S.Co-Op Bank Ltd." },
  { value: "The Chalakudy Service Co-Op. Bank Ltd.", label: "The Chalakudy Service Co-Op. Bank Ltd." },
  { value: "The Chalakudy Town Co-Op Bank Ltd,", label: "The Chalakudy Town Co-Op Bank Ltd," },
  { value: "The Chalissery Service Co.Op Bank Ltd.", label: "The Chalissery Service Co.Op Bank Ltd." },
  { value: "The Chaliyar Service Co-Op. Bank Ltd", label: "The Chaliyar Service Co-Op. Bank Ltd" },
  { value: "The Challakere Urban Co-Op. Bank Ltd.", label: "The Challakere Urban Co-Op. Bank Ltd." },
  { value: "The Chamba Urban Co-Op Bank Ltd,", label: "The Chamba Urban Co-Op Bank Ltd," },
  { value: "The Champakara Service Co-Op. Bank Ltd.", label: "The Champakara Service Co-Op. Bank Ltd." },
  { value: "The Champakulam Service Co-Op Bank Ltd", label: "The Champakulam Service Co-Op Bank Ltd" },
  { value: "The Chanasamka Nagrik Sahakari Bank Ltd", label: "The Chanasamka Nagrik Sahakari Bank Ltd" },
  { value: "The Chanasma Commercial Co Op Bank Ltd", label: "The Chanasma Commercial Co Op Bank Ltd" },
  { value: "The Chanasma Commercial Co-Op. Bk.Ltd", label: "The Chanasma Commercial Co-Op. Bk.Ltd" },
  { value: "The Chanasma Nagarik Sahakari Bank Ltd.", label: "The Chanasma Nagarik Sahakari Bank Ltd." },
  { value: "The Chanasma Nagrik Co -Op Bank Ltd", label: "The Chanasma Nagrik Co -Op Bank Ltd" },
  { value: "The Chandanpur Central Co-Op Bank Ltd,", label: "The Chandanpur Central Co-Op Bank Ltd," },
  { value: "The Chandgad Urban Co-Op Bank Ltd.", label: "The Chandgad Urban Co-Op Bank Ltd." },
  { value: "The Chandigarh State Co-Op. Bank Ltd", label: "The Chandigarh State Co-Op. Bank Ltd" },
  { value: "The Chandiroor Service Co-Op Bank Ltd.", label: "The Chandiroor Service Co-Op Bank Ltd." },
  { value: "The Chandrapur Dist. Cent. Co-Op. Bk.L.", label: "The Chandrapur Dist. Cent. Co-Op. Bk.L." },
  { value: "The Chandrapur District Central Co Op Bank", label: "The Chandrapur District Central Co Op Bank" },
  { value: "The Chandrapur Dt Cent.Co Op. Bk Ltd.", label: "The Chandrapur Dt Cent.Co Op. Bk Ltd." },
  { value: "The Chandwad Merchants Co-Op Bank Ltd", label: "The Chandwad Merchants Co-Op Bank Ltd" },
  { value: "The Changanacherry Co-Op Urban Bank Ltd.", label: "The Changanacherry Co-Op Urban Bank Ltd." },
  { value: "The Changanacherry Taluk V V Cp Soc Ltd", label: "The Changanacherry Taluk V V Cp Soc Ltd" },
  { value: "The Changaramkulam Service Co-Op Bank Lt", label: "The Changaramkulam Service Co-Op Bank Lt" },
  { value: "The Charada Nagrik Sahakari Bank Ltd.", label: "The Charada Nagrik Sahakari Bank Ltd." },
  { value: "The Charminar Co-Operative Urban Bank Lt", label: "The Charminar Co-Operative Urban Bank Lt" },
  { value: "The Chase Manhattan Bank", label: "The Chase Manhattan Bank" },
  { value: "The Chathamangalam Service Co.Op.Bank Lt", label: "The Chathamangalam Service Co.Op.Bank Lt" },
  { value: "The Chathannoor Co.Op. Urban Bank Ltd.", label: "The Chathannoor Co.Op. Urban Bank Ltd." },
  { value: "The Chattachal Co-Op. Urban Society Ltd", label: "The Chattachal Co-Op. Urban Society Ltd" },
  { value: "The Chavakkad Firka Co-Op Rural Bank Ltd", label: "The Chavakkad Firka Co-Op Rural Bank Ltd" },
  { value: "The Chavara Service Co-Op. Bank Ltd", label: "The Chavara Service Co-Op. Bank Ltd" },
  { value: "The Chayam Ser. Co-Op. Bk Lt.", label: "The Chayam Ser. Co-Op. Bk Lt." },
  { value: "The Cheeranchira Service Co-Op Bank Ltd.", label: "The Cheeranchira Service Co-Op Bank Ltd." },
  { value: "The Cheercode Panchayat Ser Co-Op Bank L", label: "The Cheercode Panchayat Ser Co-Op Bank L" },
  { value: "The Chelannur Kakkodi Ser. C. P. Bk. Ltd", label: "The Chelannur Kakkodi Ser. C. P. Bk. Ltd" },
  { value: "The Chelannur Service Co-Op. Bank Ltd.", label: "The Chelannur Service Co-Op. Bank Ltd." },
  { value: "The Chelembra Co-Op Urban Bank Ltd", label: "The Chelembra Co-Op Urban Bank Ltd" },
  { value: "The Chelembra Service Co. Bank Ltd", label: "The Chelembra Service Co. Bank Ltd" },
  { value: "The Chembrasseri Service Co-Op Bk Ltd", label: "The Chembrasseri Service Co-Op Bk Ltd" },
  { value: "The Chemmaruthy Service Co-Op Bank Ltd,", label: "The Chemmaruthy Service Co-Op Bank Ltd," },
  { value: "The Chemnad Service Co-Op. Bank Ltd", label: "The Chemnad Service Co-Op. Bank Ltd" },
  { value: "The Chendamangalam Service Co Op Bank Ltd", label: "The Chendamangalam Service Co Op Bank Ltd" },
  { value: "The Chengala Services Co-Op. Bank Ltd.", label: "The Chengala Services Co-Op. Bank Ltd." },
  { value: "The Chengalayi Service Co-Op. Bk. Lt.", label: "The Chengalayi Service Co-Op. Bk. Lt." },
  { value: "The Chengannur Co-Op. Bank Ltd.", label: "The Chengannur Co-Op. Bank Ltd." },
  { value: "The Chengannur Service Co-Op Bank Ltd.", label: "The Chengannur Service Co-Op Bank Ltd." },
  { value: "The Chengaroor Service Co - Op Bank Ltd", label: "The Chengaroor Service Co - Op Bank Ltd" },
  { value: "The Chenkara Service Co.Op. Bank Ltd", label: "The Chenkara Service Co.Op. Bank Ltd" },
  { value: "The Chennagali Service Co-Op Bank Ltd", label: "The Chennagali Service Co-Op Bank Ltd" },
  { value: "The Chennai Auto Financers", label: "The Chennai Auto Financers" },
  { value: "The Chennimalai Co Op Urban Bank", label: "The Chennimalai Co Op Urban Bank" },
  { value: "The Chennimalai Co-Op Urban Bk Ltd.", label: "The Chennimalai Co-Op Urban Bk Ltd." },
  { value: "The Chennimalai Coop Urban Bank Ltd,", label: "The Chennimalai Coop Urban Bank Ltd," },
  { value: "The Chentrappinni Service Co-Op Bank Ltd", label: "The Chentrappinni Service Co-Op Bank Ltd" },
  { value: "The Cheranelloor Service Co-Op.Bank Ltd.", label: "The Cheranelloor Service Co-Op.Bank Ltd." },
  { value: "The Cheriayamundam Service Co-Op Bank Lt", label: "The Cheriayamundam Service Co-Op Bank Lt" },
  { value: "The Cherpalcheri Co.Ur.Bank Ltd.", label: "The Cherpalcheri Co.Ur.Bank Ltd." },
  { value: "The Cherpalcheri Services Co-Op Bank Ltd", label: "The Cherpalcheri Services Co-Op Bank Ltd" },
  { value: "The Cherpu Service Co-Op. Bank Ltd.", label: "The Cherpu Service Co-Op. Bank Ltd." },
  { value: "The Cherthala Service Co-Op. Bank Ltd.", label: "The Cherthala Service Co-Op. Bank Ltd." },
  { value: "The Cherukunnu Service Co.Op Bank Ltd.", label: "The Cherukunnu Service Co.Op Bank Ltd." },
  { value: "The Cherumulli Primary Agrl.Co-Op.Bk.L.", label: "The Cherumulli Primary Agrl.Co-Op.Bk.L." },
  { value: "The Cheruthazam Service Co. Bank Ltd", label: "The Cheruthazam Service Co. Bank Ltd" },
  { value: "The Cheruthazham Service Co-Op. Bank Ltd", label: "The Cheruthazham Service Co-Op. Bank Ltd" },
  { value: "The Chethallur Service Co-Op Bank Ltd.", label: "The Chethallur Service Co-Op Bank Ltd." },
  { value: "The Chevayur Services Cooperatives Bk Lt", label: "The Chevayur Services Cooperatives Bk Lt" },
  { value: "The Chhaniyar Seva Sah Madali Ltd", label: "The Chhaniyar Seva Sah Madali Ltd" },
  { value: "The Chhapi Nagarik Sahakari Bank Ltd.", label: "The Chhapi Nagarik Sahakari Bank Ltd." },
  { value: "The Chhappi Nagrik Sah Bank Ltd", label: "The Chhappi Nagrik Sah Bank Ltd" },
  { value: "The Chidambaram Co-Op. Urban Bank Ltd", label: "The Chidambaram Co-Op. Urban Bank Ltd" },
  { value: "The Chidambaranar Dt.Cent.Co.Op. Bank Lt", label: "The Chidambaranar Dt.Cent.Co.Op. Bank Lt" },
  { value: "The Chikhli Urban Co-Op Bank Ltd", label: "The Chikhli Urban Co-Op Bank Ltd" },
  { value: "The Chikhli Urban Co-Op. Bank Ltd.", label: "The Chikhli Urban Co-Op. Bank Ltd." },
  { value: "The Chikmagalur Dist. Co-Op Cent. Bk Ltd", label: "The Chikmagalur Dist. Co-Op Cent. Bk Ltd" },
  { value: "The Chikmagalur District Co-Op. Bank Ltd", label: "The Chikmagalur District Co-Op. Bank Ltd" },
  { value: "The Chikmagalur District Co-Operative Ba", label: "The Chikmagalur District Co-Operative Ba" },
  { value: "The Chikodi Urban Co.Op. Bank Ltd.", label: "The Chikodi Urban Co.Op. Bank Ltd." },
  { value: "The Chingleput Co Op Urban Bank", label: "The Chingleput Co Op Urban Bank" },
  { value: "The Chingleput Co-Op Urban Bank Ltd.", label: "The Chingleput Co-Op Urban Bank Ltd." },
  { value: "The Chinnakanal Service Co-Op Bank Ltd", label: "The Chinnakanal Service Co-Op Bank Ltd" },
  { value: "The Chintakunta Lscs", label: "The Chintakunta Lscs" },
  { value: "The Chirakkal Service Co-Op. Bank Ltd", label: "The Chirakkal Service Co-Op. Bank Ltd" },
  { value: "The Chirayinkil Ser. Co-Op Bank Ltd.", label: "The Chirayinkil Ser. Co-Op Bank Ltd." },
  { value: "The Chitanvispura Sah Bank Ltd", label: "The Chitanvispura Sah Bank Ltd" },
  { value: "The Chitanvispura Sahakari Bank Ltd.", label: "The Chitanvispura Sahakari Bank Ltd." },
  { value: "The Chithara Service Co-Op. Bank Ltd.", label: "The Chithara Service Co-Op. Bank Ltd." },
  { value: "The Chitnavispura Friends Co-Op Bank Lt", label: "The Chitnavispura Friends Co-Op Bank Lt" },
  { value: "The Chitnavispura Sah Bank Ltd", label: "The Chitnavispura Sah Bank Ltd" },
  { value: "The Chitradurga Dist. Co-Op Cen.Bk. Ltd.", label: "The Chitradurga Dist. Co-Op Cen.Bk. Ltd." },
  { value: "The Chittoor Dist Cp Central Bank Ltd", label: "The Chittoor Dist Cp Central Bank Ltd" },
  { value: "The Chittur Service Co-Op Bank Ltd.", label: "The Chittur Service Co-Op Bank Ltd." },
  { value: "The Chombal Service Co-Op. Bank Ltd", label: "The Chombal Service Co-Op. Bank Ltd" },
  { value: "The Choondacherry Service Co-Op Bank Ltd", label: "The Choondacherry Service Co-Op Bank Ltd" },
  { value: "The Chopda Peoples Co-Op. Bank Ltd", label: "The Chopda Peoples Co-Op. Bank Ltd" },
  { value: "The Chopda Peoples Coop Bank Ltd", label: "The Chopda Peoples Coop Bank Ltd" },
  { value: "The Chorivad Bachat", label: "The Chorivad Bachat" },
  { value: "The Chorode Services Coop Bank Ltd.", label: "The Chorode Services Coop Bank Ltd." },
  { value: "The Chovva Co.Operative Rural Bank Ltd.", label: "The Chovva Co.Operative Rural Bank Ltd." },
  { value: "The Chungathara Ser Co-Op Bank Ltd.", label: "The Chungathara Ser Co-Op Bank Ltd." },
  { value: "The Churu Central Co-Op. Bank Ltd", label: "The Churu Central Co-Op. Bank Ltd" },
  { value: "The Chururu Baheri Co Op Agr Ser Society Ltd", label: "The Chururu Baheri Co Op Agr Ser Society Ltd" },
  { value: "The Cipla Urban Co-Op. Bank Ltd", label: "The Cipla Urban Co-Op. Bank Ltd" },
  { value: "The Citizen Co-Op. Bank Ltd., New Delhi", label: "The Citizen Co-Op. Bank Ltd., New Delhi" },
  { value: "The Citizen Co-Operative Bank Akhnoor Jammu.", label: "The Citizen Co-Operative Bank Akhnoor Jammu." },
  { value: "The Citizen Co. Op. Bank  Ltd.", label: "The Citizen Co. Op. Bank  Ltd." },
  { value: "The Citizen Urban Co.Op Bank Ltd", label: "The Citizen Urban Co.Op Bank Ltd" },
  { value: "The Citizens Co-Op. Credit Society Ltd.", label: "The Citizens Co-Op. Credit Society Ltd." },
  { value: "The Citizens Urban Co-Op Bank Ltd.", label: "The Citizens Urban Co-Op Bank Ltd." },
  { value: "The Citizens Co-Op. Bank Ltd", label: "The Citizens Co-Op. Bank Ltd" },
  { value: "The City Co- Op Bank Ltd", label: "The City Co- Op Bank Ltd" },
  { value: "The City Co-Op Bank Ltd., Mumbai", label: "The City Co-Op Bank Ltd., Mumbai" },
  { value: "The City Co.Op.Bank Ltd,Surat", label: "The City Co.Op.Bank Ltd,Surat" },
  { value: "The City Minorities Co Op Credit Society", label: "The City Minorities Co Op Credit Society" },
  { value: "The Classic Co-Operative Bank Ltd.", label: "The Classic Co-Operative Bank Ltd." },
  { value: "The Co - Op City Bank Ltd", label: "The Co - Op City Bank Ltd" },
  { value: "The Co Op Bank Of Mehsana", label: "The Co Op Bank Of Mehsana" },
  { value: "The Co Operative Bank Of Rajkot Ltd", label: "The Co Operative Bank Of Rajkot Ltd" },
  { value: "The Co-Op Bank Of Rajkot Ltd", label: "The Co-Op Bank Of Rajkot Ltd" },
  { value: "The Co-Op. Central Bank Ltd.", label: "The Co-Op. Central Bank Ltd." },
  { value: "The Co-Op. Urban Bank. Ltd.", label: "The Co-Op. Urban Bank. Ltd." },
  { value: "The Co-Operative Bank Ltd.", label: "The Co-Operative Bank Ltd." },
  { value: "The Co-Operative Bank Of Mehsana Ltd.", label: "The Co-Operative Bank Of Mehsana Ltd." },
  { value: "The Co-Operative Bank Of Rajkot Ltd", label: "The Co-Operative Bank Of Rajkot Ltd" },
  { value: "The Co-Operative City Bank Ltd.", label: "The Co-Operative City Bank Ltd." },
  { value: "The Co-Operative Urban Bank Ltd.", label: "The Co-Operative Urban Bank Ltd." },
  { value: "The Coastal Urban Co-Op. Bank Ltd.", label: "The Coastal Urban Co-Op. Bank Ltd." },
  { value: "The Coimbatore City Co.Op Bank Ltd.", label: "The Coimbatore City Co.Op Bank Ltd." },
  { value: "The Coimbatore Dist.Cen. Co-Op. Bk Ltd.", label: "The Coimbatore Dist.Cen. Co-Op. Bk Ltd." },
  { value: "The Coimbatore District Central Co. Bank", label: "The Coimbatore District Central Co. Bank" },
  { value: "The Commercial Co Op Credit Society Ltd", label: "The Commercial Co Op Credit Society Ltd" },
  { value: "The Commercial Co-Op Credit Soc Ltd", label: "The Commercial Co-Op Credit Soc Ltd" },
  { value: "The Commercial Co-Op. Bank Ltd.", label: "The Commercial Co-Op. Bank Ltd." },
  { value: "The Commercial Co-Op. Bank Ltd., Kolhapur", label: "The Commercial Co-Op. Bank Ltd., Kolhapur" },
  { value: "The Commercial Co-Operative Bank Ltd.", label: "The Commercial Co-Operative Bank Ltd." },
  { value: "The Commercial Credit Corp. {1943} Pvt Ltd.", label: "The Commercial Credit Corp. {1943} Pvt Ltd." },
  { value: "The Comptroller U.A.S.", label: "The Comptroller U.A.S." },
  { value: "The Comptrollers Office Co-Op. Bank Ltd", label: "The Comptrollers Office Co-Op. Bank Ltd" },
  { value: "The Contai Co-Operative Bank Ltd", label: "The Contai Co-Operative Bank Ltd" },
  { value: "The Coonoor Co-Operative Urban Bank Ltd", label: "The Coonoor Co-Operative Urban Bank Ltd" },
  { value: "The Cordite Factory C-Operative Bkltd", label: "The Cordite Factory C-Operative Bkltd" },
  { value: "The Cosmos Co-Operative Bank Ltd.", label: "The Cosmos Co-Operative Bank Ltd." },
  { value: "The Cosmos Coop Bank Ltd", label: "The Cosmos Coop Bank Ltd" },
  { value: "The Cuddalore Dist.Cent. Co-Op. Bank Ltd", label: "The Cuddalore Dist.Cent. Co-Op. Bank Ltd" },
  { value: "The Cuddapah District Co-Op Central Bank", label: "The Cuddapah District Co-Op Central Bank" },
  { value: "The Cumbum Co-Operative Town Bank Ltd", label: "The Cumbum Co-Operative Town Bank Ltd" },
  { value: "The Cuttack Central Co-Op Bank Ltd", label: "The Cuttack Central Co-Op Bank Ltd" },
  { value: "The Cuttack Credit Co-Op. Society Ltd.", label: "The Cuttack Credit Co-Op. Society Ltd." },
  { value: "The Cuttack Credit Co-Operative Ltdthe Cuttack Credit Co-Operative Ltd", label: "The Cuttack Credit Co-Operative Ltdthe Cuttack Credit Co-Operative Ltd" },
  { value: "The D.C.C. Bank Ltd.,", label: "The D.C.C. Bank Ltd.," },
  { value: "The Dadasaheb Rawal Co Bk Of Dondaicha L", label: "The Dadasaheb Rawal Co Bk Of Dondaicha L" },
  { value: "The Dahanu Road Janata Co-Op. Bank Ltd", label: "The Dahanu Road Janata Co-Op. Bank Ltd" },
  { value: "The Dahanu Road Janatha Co-Op. Bank Ltd.", label: "The Dahanu Road Janatha Co-Op. Bank Ltd." },
  { value: "The Dahod  Merccentile Co-Op Bank Ltd", label: "The Dahod  Merccentile Co-Op Bank Ltd" },
  { value: "The Dahod Urban Co-Op. Bank Ltd", label: "The Dahod Urban Co-Op. Bank Ltd" },
  { value: "The Daivadnya Co-Op Credit Soc Ltd,", label: "The Daivadnya Co-Op Credit Soc Ltd," },
  { value: "The Daivadnya Mahila Co Op Cr Society Ltd", label: "The Daivadnya Mahila Co Op Cr Society Ltd" },
  { value: "The Dakor Mahila Nagrik Sahakari Bk Ltd.", label: "The Dakor Mahila Nagrik Sahakari Bk Ltd." },
  { value: "The Dakshin Dinajpur Dist Cen Co Bk L,", label: "The Dakshin Dinajpur Dist Cen Co Bk L," },
  { value: "The Dalmiapuram Employees Co-Op Bank Ltd", label: "The Dalmiapuram Employees Co-Op Bank Ltd" },
  { value: "The Darussalam Co-Op. Urban Bank Ltd.", label: "The Darussalam Co-Op. Urban Bank Ltd." },
  { value: "The Davanagere Dist.Central Co-Op.Bnk Lt", label: "The Davanagere Dist.Central Co-Op.Bnk Lt" },
  { value: "The Davangere Harihar Urban Co-Op Bank Ltd", label: "The Davangere Harihar Urban Co-Op Bank Ltd" },
  { value: "The Davangere Harihar Urban Sahakara B N", label: "The Davangere Harihar Urban Sahakara B N" },
  { value: "The Davangere Urban Co-Op Bank Ltd", label: "The Davangere Urban Co-Op Bank Ltd" },
  { value: "The Davangere-Harihar Urban Co. Bank Ltd", label: "The Davangere-Harihar Urban Co. Bank Ltd" },
  { value: "The Davangeri Harihar Urban Sahakari Bank Niyamita", label: "The Davangeri Harihar Urban Sahakari Bank Niyamita" },
  { value: "The Deccan Credit Co-Op Bank Ltd", label: "The Deccan Credit Co-Op Bank Ltd" },
  { value: "The Deccan Mercantile Co-Op Bank Ltd.", label: "The Deccan Mercantile Co-Op Bank Ltd." },
  { value: "The Deccan Merchant Co-Op Bank", label: "The Deccan Merchant Co-Op Bank" },
  { value: "The Deccan Merchants Co Op Bank Ltd", label: "The Deccan Merchants Co Op Bank Ltd" },
  { value: "The Deccan Merchants Co-Op Bank", label: "The Deccan Merchants Co-Op Bank" },
  { value: "The Deccan Urban Co-Op Bank Ltd", label: "The Deccan Urban Co-Op Bank Ltd" },
  { value: "The Deccan Urban Co-Op. Bank Ltd", label: "The Deccan Urban Co-Op. Bank Ltd" },
  { value: "The Degam Karayav Sahakari Mandali Ltd", label: "The Degam Karayav Sahakari Mandali Ltd" },
  { value: "The Dehenkanal Urban Co-Op Bank Ltd", label: "The Dehenkanal Urban Co-Op Bank Ltd" },
  { value: "The Delhi Nagrik Sehkari Bank Ltd.", label: "The Delhi Nagrik Sehkari Bank Ltd." },
  { value: "The Delhi Safe Deposit Company Ltd.", label: "The Delhi Safe Deposit Company Ltd." },
  { value: "The Delhi State Co Operat", label: "The Delhi State Co Operat" },
  { value: "The Delhi State Co-Operative Bank", label: "The Delhi State Co-Operative Bank" },
  { value: "The Delhi State Co-Operative Bank Ltd.", label: "The Delhi State Co-Operative Bank Ltd." },
  { value: "The Deola Merchants Co-Op Bank Ltd", label: "The Deola Merchants Co-Op Bank Ltd" },
  { value: "Deputy General Manager And Project Officer", label: "Deputy General Manager And Project Officer" },
  { value: "The Derol Gssm Ltd", label: "The Derol Gssm Ltd" },
  { value: "The Desamangalam Service Co-Op. Bank Ltd", label: "The Desamangalam Service Co-Op. Bank Ltd" },
  { value: "The Devadi Sewa Sahakari Mandli Ltd.", label: "The Devadi Sewa Sahakari Mandli Ltd." },
  { value: "The Devaraj Urs Back Word Development Corporation", label: "The Devaraj Urs Back Word Development Corporation" },
  { value: "The Development Bank Of Singapore Ltd.", label: "The Development Bank Of Singapore Ltd." },
  { value: "The Devgad Urban Co-Op. Bank Ltd.", label: "The Devgad Urban Co-Op. Bank Ltd." },
  { value: "The Dhanalakshmi Bank Ltd", label: "The Dhanalakshmi Bank Ltd" },
  { value: "The Dhanalakshmi Bank Ltd, Aryanad.", label: "The Dhanalakshmi Bank Ltd, Aryanad." },
  { value: "The Dhanalakshmi Bank Ltd.", label: "The Dhanalakshmi Bank Ltd." },
  { value: "The Dhanalaxmi Bank Ltd", label: "The Dhanalaxmi Bank Ltd" },
  { value: "The Dhanalaxmi Finance Ltd", label: "The Dhanalaxmi Finance Ltd" },
  { value: "The Dhanbad Central Co-Op Bank Ltd", label: "The Dhanbad Central Co-Op Bank Ltd" },
  { value: "The Dhanera Mercantile Co-Op Bank Ltd", label: "The Dhanera Mercantile Co-Op Bank Ltd" },
  { value: "The Dhansura Peoples Co - Op Bank Ltd", label: "The Dhansura Peoples Co - Op Bank Ltd" },
  { value: "The Dharampeth Mahila Urben Credit Co Op Soc Ltd", label: "The Dharampeth Mahila Urben Credit Co Op Soc Ltd" },
  { value: "The Dharmadam Service Co-Op. Bk Ltd", label: "The Dharmadam Service Co-Op. Bk Ltd" },
  { value: "The Dharmaj  Peoples Co-Op Bank", label: "The Dharmaj  Peoples Co-Op Bank" },
  { value: "The Dharmaj Co Op Credit Soc Ltd", label: "The Dharmaj Co Op Credit Soc Ltd" },
  { value: "The Dharmaj Peoples Co-Op Bank Ltd.", label: "The Dharmaj Peoples Co-Op Bank Ltd." },
  { value: "The Dharmapuri Co-Op. Town Bank Ltd", label: "The Dharmapuri Co-Op. Town Bank Ltd" },
  { value: "The Dharmapuri Dist Central Co-Op Bank L", label: "The Dharmapuri Dist Central Co-Op Bank L" },
  { value: "The Dharmeda Group Seva Sahkari Mandli Ltd", label: "The Dharmeda Group Seva Sahkari Mandli Ltd" },
  { value: "The Dhinoj Nagarik Sahakari Bank Ltd", label: "The Dhinoj Nagarik Sahakari Bank Ltd" },
  { value: "The Dhinoj Nagrik Sahakari Bank", label: "The Dhinoj Nagrik Sahakari Bank" },
  { value: "The Dhiraj Nagrik Sahakari Bank", label: "The Dhiraj Nagrik Sahakari Bank" },
  { value: "The Dholpur Urban Co-Op. Bank Ltd.", label: "The Dholpur Urban Co-Op. Bank Ltd." },
  { value: "The Dhrangadhra Peoples Co-Op. Bank Ltd", label: "The Dhrangadhra Peoples Co-Op. Bank Ltd" },
  { value: "The Dhule District Cental Co-Op Bank Ltd", label: "The Dhule District Cental Co-Op Bank Ltd" },
  { value: "The Dhulia Dist. Cen. Co.Op Bank Ltd", label: "The Dhulia Dist. Cen. Co.Op Bank Ltd" },
  { value: "The Diamond Jubilee Co-Op Bank Ltd.", label: "The Diamond Jubilee Co-Op Bank Ltd." },
  { value: "The Dindigul Central Co-Op. Bank Ltd", label: "The Dindigul Central Co-Op. Bank Ltd" },
  { value: "The Dindigul Urban Co-Op Bank Ltd", label: "The Dindigul Urban Co-Op Bank Ltd" },
  { value: "The Director Of Agriculture", label: "The Director Of Agriculture" },
  { value: "The Dist Co-Op Central Bank Ltd,", label: "The Dist Co-Op Central Bank Ltd," },
  { value: "The Dist Sc Ser Co-Op Society Ltd Nsfdc", label: "The Dist Sc Ser Co-Op Society Ltd Nsfdc" },
  { value: "The Dist. Govt. Officials Co. Bank Ltd", label: "The Dist. Govt. Officials Co. Bank Ltd" },
  { value: "The District Central Co Operative Bank Ltd", label: "The District Central Co Operative Bank Ltd" },
  { value: "The District Co Operative Central Bank", label: "The District Co Operative Central Bank" },
  { value: "The District Co-Op Bank Ltd", label: "The District Co-Op Bank Ltd" },
  { value: "The District Co-Op. Bank Ltd.", label: "The District Co-Op. Bank Ltd." },
  { value: "The District Co-Op. Central Bank Ltd.", label: "The District Co-Op. Central Bank Ltd." },
  { value: "The District Coop Central Bank Ltd,", label: "The District Coop Central Bank Ltd," },
  { value: "The Dombivili Nagar Sahakari Bank Ltd", label: "The Dombivili Nagar Sahakari Bank Ltd" },
  { value: "The Dombivli Nagari Sahakari Bank Ltd", label: "The Dombivli Nagari Sahakari Bank Ltd" },
  { value: "The Dr.Panjabrao Deshmukh Ur Co-Op.Bk Lt", label: "The Dr.Panjabrao Deshmukh Ur Co-Op.Bk Lt" },
  { value: "The Dungarpur Central Co-Op. Bank Ltd", label: "The Dungarpur Central Co-Op. Bank Ltd" },
  { value: "The Dungarpur Kendriya Sahakari Bank Ltd", label: "The Dungarpur Kendriya Sahakari Bank Ltd" },
  { value: "The East Eleri Service Co-Op. Bank Ltd.", label: "The East Eleri Service Co-Op. Bank Ltd." },
  { value: "The East Ernad Service Co-Op. Bank Ltd", label: "The East Ernad Service Co-Op. Bank Ltd" },
  { value: "The East Kallada Service Co-Op Bank Ltd", label: "The East Kallada Service Co-Op Bank Ltd" },
  { value: "The Edacheri Service Co-Op Bank Ltd.", label: "The Edacheri Service Co-Op Bank Ltd." },
  { value: "The Edamon Service Co-Op. Bank Ltd.", label: "The Edamon Service Co-Op. Bank Ltd." },
  { value: "The Edamulackal Service Co-Op Bank Ltd", label: "The Edamulackal Service Co-Op Bank Ltd" },
  { value: "The Edamuttam Service Co-Op Bank Ltd.", label: "The Edamuttam Service Co-Op Bank Ltd." },
  { value: "The Edanad Service Co-Op Bank Ltd.", label: "The Edanad Service Co-Op Bank Ltd." },
  { value: "The Edapatta Service Co-Op Bank Ltd.", label: "The Edapatta Service Co-Op Bank Ltd." },
  { value: "The Edavanakad Ser Co-Op Bank Ltd,", label: "The Edavanakad Ser Co-Op Bank Ltd," },
  { value: "The Edavanna Service Co-Op. Bank Ltd", label: "The Edavanna Service Co-Op. Bank Ltd" },
  { value: "The Ednad Kannur Ser Co-Op Bk Ltd.", label: "The Ednad Kannur Ser Co-Op Bk Ltd." },
  { value: "The Eenadu Co-Op. Urban Bank Ltd.", label: "The Eenadu Co-Op. Urban Bank Ltd." },
  { value: "The Elamgulam Service C-Op Bank Ltd", label: "The Elamgulam Service C-Op Bank Ltd" },
  { value: "The Elamkulam Service Co-Op Bank Ltd.", label: "The Elamkulam Service Co-Op Bank Ltd." },
  { value: "The Elankur Service Co-Op. Bank Ltd.", label: "The Elankur Service Co-Op. Bank Ltd." },
  { value: "The Elappully Service Co-Op. Bank Ltd", label: "The Elappully Service Co-Op. Bank Ltd" },
  { value: "The Elavamalai Co-Op Agricultural Bk Ltd", label: "The Elavamalai Co-Op Agricultural Bk Ltd" },
  { value: "The Elavanchery Service Co-Op Bank Ltd.", label: "The Elavanchery Service Co-Op Bank Ltd." },
  { value: "The Elavoor Services Co-Op Bank Ltd,", label: "The Elavoor Services Co-Op Bank Ltd," },
  { value: "The Elayavoor Service Co-Op. Bank Ltd", label: "The Elayavoor Service Co-Op. Bank Ltd" },
  { value: "The Elikulam Ser. Co-Op. Bank Ltd.", label: "The Elikulam Ser. Co-Op. Bank Ltd." },
  { value: "The Ellakal Service Co-Op Bank Ltd.", label: "The Ellakal Service Co-Op Bank Ltd." },
  { value: "The Eluri Co-Operative Urban Bank Ltd", label: "The Eluri Co-Operative Urban Bank Ltd" },
  { value: "The Enadimangalam Service Co Op Bank Ltd", label: "The Enadimangalam Service Co Op Bank Ltd" },
  { value: "The Enanalloor Service Co-Oper Bank Ltd", label: "The Enanalloor Service Co-Oper Bank Ltd" },
  { value: "The Energy And Resources Institute", label: "The Energy And Resources Institute" },
  { value: "The Engandiyur Farmers Service Co-Op B L", label: "The Engandiyur Farmers Service Co-Op B L" },
  { value: "The Eramala Service Co-Op Bank Ltd", label: "The Eramala Service Co-Op Bank Ltd" },
  { value: "The Eramalloor Sercive Co-Op Bank Ltd", label: "The Eramalloor Sercive Co-Op Bank Ltd" },
  { value: "The Eramam-Kuttur Service Co-Op Bk Ltd", label: "The Eramam-Kuttur Service Co-Op Bk Ltd" },
  { value: "The Eriyad Service Co-Op Bank Ltd", label: "The Eriyad Service Co-Op Bank Ltd" },
  { value: "The Ernakulam Agricultural & Rural Devt Bank", label: "The Ernakulam Agricultural & Rural Devt Bank" },
  { value: "The Ernakulam Co Operative Agricultural Development Bank Ltd", label: "The Ernakulam Co Operative Agricultural Development Bank Ltd" },
  { value: "The Ernakulam District Co-Op Bank Ltd", label: "The Ernakulam District Co-Op Bank Ltd" },
  { value: "The Ernakulam District Co-Op Bank Ltd.", label: "The Ernakulam District Co-Op Bank Ltd." },
  { value: "The Erode Co- Op Urban Bank Ltd", label: "The Erode Co- Op Urban Bank Ltd" },
  { value: "The Erode Dist Central Co-Op Bank Ltd.", label: "The Erode Dist Central Co-Op Bank Ltd." },
  { value: "The Erode District Central Co-Op.Bank Lt", label: "The Erode District Central Co-Op.Bank Lt" },
  { value: "The Erumad Primary Agrl Co-Op Bk Ltd", label: "The Erumad Primary Agrl Co-Op Bk Ltd" },
  { value: "The Erumapetty Panchayat Ser C-P Bk. Ltd", label: "The Erumapetty Panchayat Ser C-P Bk. Ltd" },
  { value: "The Ettumanoor Service Co-Op Bank Ltd.", label: "The Ettumanoor Service Co-Op Bank Ltd." },
  { value: "The Ezacherry Service Co-Op Bank Ltd.", label: "The Ezacherry Service Co-Op Bank Ltd." },
  { value: "The Ezhukone Service Co-Operat Bank Ltd", label: "The Ezhukone Service Co-Operat Bank Ltd" },
  { value: "The Faiz Mercantile Co-Op Bank Ltd", label: "The Faiz Mercantile Co-Op Bank Ltd" },
  { value: "The Faiz Merchantile Co-Op Bank Ltd", label: "The Faiz Merchantile Co-Op Bank Ltd" },
  { value: "The Faizabad Co-Op Dist Bank Ltd", label: "The Faizabad Co-Op Dist Bank Ltd" },
  { value: "The Faridabad Central Co-Op Bank Ltd.", label: "The Faridabad Central Co-Op Bank Ltd." },
  { value: "The Faridkot Central Co.Op. Bank Ltd.", label: "The Faridkot Central Co.Op. Bank Ltd." },
  { value: "The Farrukhabad Dist. Co Op Bank Ltd", label: "The Farrukhabad Dist. Co Op Bank Ltd" },
  { value: "The Farrukhabad Dt Cent Co-Op Bank Ltd", label: "The Farrukhabad Dt Cent Co-Op Bank Ltd" },
  { value: "The Fatehabad Central Co-Op Bank Ltd,", label: "The Fatehabad Central Co-Op Bank Ltd," },
  { value: "The Fatehgarh Sahib Central Co-Op Bank L", label: "The Fatehgarh Sahib Central Co-Op Bank L" },
  { value: "The Fazilka Central Co-Op Bank Ltd.", label: "The Fazilka Central Co-Op Bank Ltd." },
  { value: "The Federal Bank Ltd", label: "The Federal Bank Ltd" },
  { value: "The Federal Bank Ltd.", label: "The Federal Bank Ltd." },
  { value: "The Fedral Bank Ltd - Udupi", label: "The Fedral Bank Ltd - Udupi" },
  { value: "The Fedral Bank Ltd.", label: "The Fedral Bank Ltd." },
  { value: "The Fedral Bank Of India", label: "The Fedral Bank Of India" },
  { value: "The Feroke Service Co-Operative Bank Ltd", label: "The Feroke Service Co-Operative Bank Ltd" },
  { value: "The Ferozpur Central Co-Op Bank Ltd,", label: "The Ferozpur Central Co-Op Bank Ltd," },
  { value: "The Fertilizer Association Of India (Fai)", label: "The Fertilizer Association Of India (Fai)" },
  { value: "The Financial Co Op Bank Ltd", label: "The Financial Co Op Bank Ltd" },
  { value: "The Financial Co-Operative Bank Ltd.", label: "The Financial Co-Operative Bank Ltd." },
  { value: "The Financial Service Ltd.", label: "The Financial Service Ltd." },
  { value: "The French And International Bank", label: "The French And International Bank" },
  { value: "The Friends Co-Op. Bank Ltd.", label: "The Friends Co-Op. Bank Ltd." },
  { value: "The Fuji Bank Ltd", label: "The Fuji Bank Ltd" },
  { value: "The Gabat Nagrik Sarathi Sahakari Bank Ltd", label: "The Gabat Nagrik Sarathi Sahakari Bank Ltd" },
  { value: "The Gadchiroli Dist. Central Co.Bank Ltd", label: "The Gadchiroli Dist. Central Co.Bank Ltd" },
  { value: "The Gadchiroli District C Co-Op. Bank L", label: "The Gadchiroli District C Co-Op. Bank L" },
  { value: "The Gadchiroli Nagari Sahakari Bk.Mydt.", label: "The Gadchiroli Nagari Sahakari Bk.Mydt." },
  { value: "The Gadhinglaj Urban Co-Op. Bank Ltd.", label: "The Gadhinglaj Urban Co-Op. Bank Ltd." },
  { value: "The Gadhinglaj Urban Co. Op. Bank Ltd.", label: "The Gadhinglaj Urban Co. Op. Bank Ltd." },
  { value: "The Gandevi Peoples Co Op Bank Ltd", label: "The Gandevi Peoples Co Op Bank Ltd" },
  { value: "The Gandevi Peoples Co-Op.Bank Ltd.", label: "The Gandevi Peoples Co-Op.Bank Ltd." },
  { value: "The Gandhi Co-Operative Urban Bank Ltd.", label: "The Gandhi Co-Operative Urban Bank Ltd." },
  { value: "The Gandhi Gunj Co Bank Ltd", label: "The Gandhi Gunj Co Bank Ltd" },
  { value: "The Gandhi Gunj Co-Op Bank Ltd,", label: "The Gandhi Gunj Co-Op Bank Ltd," },
  { value: "The Gandhi Gunj Co-Op. Bank Ltd.", label: "The Gandhi Gunj Co-Op. Bank Ltd." },
  { value: "The Gandhi Gunj Primary Co-Op. Bank Ltd", label: "The Gandhi Gunj Primary Co-Op. Bank Ltd" },
  { value: "The Gandhi Gunj Primary Co-Op. Bank Ltd.", label: "The Gandhi Gunj Primary Co-Op. Bank Ltd." },
  { value: "The Gandhidammer Co-Op Bank", label: "The Gandhidammer Co-Op Bank" },
  { value: "The Gandhidham Co-Op Bank Ltd", label: "The Gandhidham Co-Op Bank Ltd" },
  { value: "The Gandhidham Mercantile Co Op Bank", label: "The Gandhidham Mercantile Co Op Bank" },
  { value: "The Gandhidham Mercantile Co Op Bank", label: "The Gandhidham Mercantile Co Op Bank" },
  { value: "The Gandhidham Mercantile Co-Op Bank Ltd", label: "The Gandhidham Mercantile Co-Op Bank Ltd" },
  { value: "The Gandhinagar Nagarik Co.Op. Bank Ltd.", label: "The Gandhinagar Nagarik Co.Op. Bank Ltd." },
  { value: "The Gandhinagar Urban Co Op Bank Ltd", label: "The Gandhinagar Urban Co Op Bank Ltd" },
  { value: "The Gandhinagar Urban Co.Op. Bank Ltd", label: "The Gandhinagar Urban Co.Op. Bank Ltd" },
  { value: "The Ganesh Bank Of Kurundwad Ltd,.", label: "The Ganesh Bank Of Kurundwad Ltd,." },
  { value: "The Ganesh Bank Of Kurundwad Ltd.", label: "The Ganesh Bank Of Kurundwad Ltd." },
  { value: "The Ganga Mercantile Co-Op Bank Ltd.", label: "The Ganga Mercantile Co-Op Bank Ltd." },
  { value: "The Ganga Mercantile Urban Co Bank Ltd.", label: "The Ganga Mercantile Urban Co Bank Ltd." },
  { value: "The Ganga Mercantile Urban Co-Op Bank", label: "The Ganga Mercantile Urban Co-Op Bank" },
  { value: "The Ganga Merchantile Urban Cooperative Bank Ltd.", label: "The Ganga Merchantile Urban Cooperative Bank Ltd." },
  { value: "The Ganganagar Kendriya Sahakari Bank Lt", label: "The Ganganagar Kendriya Sahakari Bank Lt" },
  { value: "The Gangavathi Souharda Pattina Sah. Niy", label: "The Gangavathi Souharda Pattina Sah. Niy" },
  { value: "The Gauhati Co-Operative Urban Bank Ltd.", label: "The Gauhati Co-Operative Urban Bank Ltd." },
  { value: "The Gayatri Co Op Urban Bank Ltd", label: "The Gayatri Co Op Urban Bank Ltd" },
  { value: "The General Co-Op. Bank Ltd.", label: "The General Co-Op. Bank Ltd." },
  { value: "The General Investment And Commercial Corpration Ltd", label: "The General Investment And Commercial Corpration Ltd" },
  { value: "The Ghatkesar Farmers Services Co-Op Ltd", label: "The Ghatkesar Farmers Services Co-Op Ltd" },
  { value: "The Ghoda Seva Sahakari Mandali Ltd", label: "The Ghoda Seva Sahakari Mandali Ltd" },
  { value: "The Ghogamba Vibhag Nagrik Sahakari Bank Ltd", label: "The Ghogamba Vibhag Nagrik Sahakari Bank Ltd" },
  { value: "The Ghoghamba Vibhag Nagrik Sahakari Bk", label: "The Ghoghamba Vibhag Nagrik Sahakari Bk" },
  { value: "The Ghoti Merchant Co Op Bank Ltd.", label: "The Ghoti Merchant Co Op Bank Ltd." },
  { value: "The Ghoti Merchants Co-Op. Bank Ltd", label: "The Ghoti Merchants Co-Op. Bank Ltd" },
  { value: "The Goa Co Op Bank Ltd", label: "The Goa Co Op Bank Ltd" },
  { value: "The Goa State Co Op Bank Ltd", label: "The Goa State Co Op Bank Ltd" },
  { value: "The Goa State Co-Operative Bank Ltd", label: "The Goa State Co-Operative Bank Ltd" },
  { value: "The Goa State Coop Bankltd.", label: "The Goa State Coop Bankltd." },
  { value: "The Goa Urban Co-Op Bank Ltd", label: "The Goa Urban Co-Op Bank Ltd" },
  { value: "The Goan Peoples Urban Co-Op.Bank Ltd.", label: "The Goan Peoples Urban Co-Op.Bank Ltd." },
  { value: "The Gobichettipalaym Co-Op. Urban Bk Ltd", label: "The Gobichettipalaym Co-Op. Urban Bk Ltd" },
  { value: "The Godhra City Co-Op Bank Ltd.", label: "The Godhra City Co-Op Bank Ltd." },
  { value: "The Godhra Transport Co Op Credit Society", label: "The Godhra Transport Co Op Credit Society" },
  { value: "The Godhra Urban Co-Op. Bank Ltd.", label: "The Godhra Urban Co-Op. Bank Ltd." },
  { value: "The Gokak Urban Co.Op. Credit Bank Ltd", label: "The Gokak Urban Co.Op. Credit Bank Ltd" },
  { value: "The Gokak Urban Coop Credit Bank Ltd", label: "The Gokak Urban Coop Credit Bank Ltd" },
  { value: "The Gokul Urban Co-Op.Credit Society Ltd", label: "The Gokul Urban Co-Op.Credit Society Ltd" },
  { value: "The Gondia Dist Co Op Bank Ltd", label: "The Gondia Dist Co Op Bank Ltd" },
  { value: "The Gondia District Cen.Co.Op.Bank Ltd", label: "The Gondia District Cen.Co.Op.Bank Ltd" },
  { value: "The Gopalganj Central Co-Op Bank Ltd.", label: "The Gopalganj Central Co-Op Bank Ltd." },
  { value: "The Goregaon Co-Operative Urban Bank Ltd", label: "The Goregaon Co-Operative Urban Bank Ltd" },
  { value: "The Government Servants Co Op Bank Ltd", label: "The Government Servants Co Op Bank Ltd" },
  { value: "The Gozaria Nagarik Sahakari Bank Ltd.", label: "The Gozaria Nagarik Sahakari Bank Ltd." },
  { value: "The Gozaria Nagrik Sahakari Bank Ltd.", label: "The Gozaria Nagrik Sahakari Bank Ltd." },
  { value: "The Gozaria Peoples Co Op Credit Society Ltd", label: "The Gozaria Peoples Co Op Credit Society Ltd" },
  { value: "The Gozariya Nagrik Sahakari Bank Ltd", label: "The Gozariya Nagrik Sahakari Bank Ltd" },
  { value: "The Gpr Co-Op. Urban Bank Ltd.", label: "The Gpr Co-Op. Urban Bank Ltd." },
  { value: "The Graduates Co-Op. Society Ltd.", label: "The Graduates Co-Op. Society Ltd." },
  { value: "The Grain Merchants Co-Op Bank Ltd.", label: "The Grain Merchants Co-Op Bank Ltd." },
  { value: "The Greater Bombay Cooperative Bank Ltd.", label: "The Greater Bombay Cooperative Bank Ltd." },
  { value: "The Gsp Central Co-Operative Bank Ltd.", label: "The Gsp Central Co-Operative Bank Ltd." },
  { value: "The Gudivada Co-Op Urban Bank Ltd.", label: "The Gudivada Co-Op Urban Bank Ltd." },
  { value: "The Gudiyattam Co-Op.Urban Bank Ltd.", label: "The Gudiyattam Co-Op.Urban Bank Ltd." },
  { value: "The Gujarat Hou Umarwada Urban Co-Op. Soc.", label: "The Gujarat Hou Umarwada Urban Co-Op. Soc." },
  { value: "The Gujarat Industrial Co Op Bank", label: "The Gujarat Industrial Co Op Bank" },
  { value: "The Gujarat Industrial Co-Op Bank Ltd.", label: "The Gujarat Industrial Co-Op Bank Ltd." },
  { value: "The Gujarat Industrial Co-Op. Bank Ltd.", label: "The Gujarat Industrial Co-Op. Bank Ltd." },
  { value: "The Gujarat State Co Op Agri & Rural Developement Bank Ltd", label: "The Gujarat State Co Op Agri & Rural Developement Bank Ltd" },
  { value: "The Gujarat State Co Op Leand Gramin Bank Ltd", label: "The Gujarat State Co Op Leand Gramin Bank Ltd" },
  { value: "The Gujarat State Co-Operative Bank Ltd", label: "The Gujarat State Co-Operative Bank Ltd" },
  { value: "The Gulbarga Dist. Co-Op Central Bank Lt", label: "The Gulbarga Dist. Co-Op Central Bank Lt" },
  { value: "The Gulbarga Dist. Co. Op. Central Bk Lt", label: "The Gulbarga Dist. Co. Op. Central Bk Lt" },
  { value: "The Gumla Simdega Central Coop Bk Ltd", label: "The Gumla Simdega Central Coop Bk Ltd" },
  { value: "The Guna Nagarik Sahakari Bank Mydt.", label: "The Guna Nagarik Sahakari Bank Mydt." },
  { value: "The Guntur Co-Op. Urban Bank Ltd.", label: "The Guntur Co-Op. Urban Bank Ltd." },
  { value: "The Guntur Dist. Co-Op. Central Bank Ltd", label: "The Guntur Dist. Co-Op. Central Bank Ltd" },
  { value: "The Gurdaspur Cent.Co.Op. Bank Ltd", label: "The Gurdaspur Cent.Co.Op. Bank Ltd" },
  { value: "The Gurdaspur District Central Co Op Bank", label: "The Gurdaspur District Central Co Op Bank" },
  { value: "The Gurgaon Central Co-Op. Bank Ltd.", label: "The Gurgaon Central Co-Op. Bank Ltd." },
  { value: "The Guruvayur Township Service Co-Op B.L", label: "The Guruvayur Township Service Co-Op B.L" },
  { value: "The Gwalior Citizen Sakha Sahkari Mydt,", label: "The Gwalior Citizen Sakha Sahkari Mydt," },
  { value: "The H P State Co-Op Bank Ltd", label: "The H P State Co-Op Bank Ltd" },
  { value: "The Hadgud Co Op Credit Soc Ltd", label: "The Hadgud Co Op Credit Soc Ltd" },
  { value: "The Halol Mercantile Co-Op. Bank Ltd", label: "The Halol Mercantile Co-Op. Bank Ltd" },
  { value: "The Halol Urban Co-Op. Bank Ltd.", label: "The Halol Urban Co-Op. Bank Ltd." },
  { value: "The Hamdard Credit Co-Op. Society Ltd.", label: "The Hamdard Credit Co-Op. Society Ltd." },
  { value: "The Hangal Urban Co. Bank Ltd", label: "The Hangal Urban Co. Bank Ltd" },
  { value: "The Hansot Nagaric Sahakari Bank Ltd.", label: "The Hansot Nagaric Sahakari Bank Ltd." },
  { value: "The Hanumantnagar Cooperative Bank Ltd", label: "The Hanumantnagar Cooperative Bank Ltd" },
  { value: "The Harij Nagarik Sahakari Bank Ltd", label: "The Harij Nagarik Sahakari Bank Ltd" },
  { value: "The Harijnag Sahakari Bank Ltd", label: "The Harijnag Sahakari Bank Ltd" },
  { value: "The Haryana State Co-Op Apex Bank Ltd.", label: "The Haryana State Co-Op Apex Bank Ltd." },
  { value: "The Haryana State Coop Land Devel. Bkltd", label: "The Haryana State Coop Land Devel. Bkltd" },
  { value: "The Hasli Coop Bank Dhule", label: "The Hasli Coop Bank Dhule" },
  { value: "The Hassan Dis. Co-Op. Cen. Bank Ltd.", label: "The Hassan Dis. Co-Op. Cen. Bank Ltd." },
  { value: "The Hassan Dist. Co-Op. Central Bank Ltd", label: "The Hassan Dist. Co-Op. Central Bank Ltd" },
  { value: "The Hassan District Co Operative Bank Ltd.", label: "The Hassan District Co Operative Bank Ltd." },
  { value: "The Hasti Co-Operative Bank Ltd.", label: "The Hasti Co-Operative Bank Ltd." },
  { value: "The Hathyadka Co-Op Agricultural Bk Ltd.", label: "The Hathyadka Co-Op Agricultural Bk Ltd." },
  { value: "The Hatkeshvar And Dhiran Karnari Sahakari Mandli Ltd", label: "The Hatkeshvar And Dhiran Karnari Sahakari Mandli Ltd" },
  { value: "The Hatkeshwar Bachat & Dhiran K S M Ltd", label: "The Hatkeshwar Bachat & Dhiran K S M Ltd" },
  { value: "The Hatkeshwar Bachatane Dhiran Karan Sahakari Mandali Ltd", label: "The Hatkeshwar Bachatane Dhiran Karan Sahakari Mandali Ltd" },
  { value: "The Haveri Urban Co-Op Bank Ltd", label: "The Haveri Urban Co-Op Bank Ltd" },
  { value: "The Hazaribag Central Co-Op. Bank Ltd.", label: "The Hazaribag Central Co-Op. Bank Ltd." },
  { value: "The Hccb", label: "The Hccb" },
  { value: "The Himachal Pradesh State Co. Bank Ltd", label: "The Himachal Pradesh State Co. Bank Ltd" },
  { value: "The Hindu Co Op Bank Ltd", label: "The Hindu Co Op Bank Ltd" },
  { value: "The Hindustan Coop Bank Ltd", label: "The Hindustan Coop Bank Ltd" },
  { value: "The Hindustan Coop Bank Ltd Kalamboli Branch", label: "The Hindustan Coop Bank Ltd Kalamboli Branch" },
  { value: "The Hindustan Shipyad Staff C.O.B.Ltdthe", label: "The Hindustan Shipyad Staff C.O.B.Ltdthe" },
  { value: "The Hirekerur Urban Coop Bank Ltd", label: "The Hirekerur Urban Coop Bank Ltd" },
  { value: "The Hiriyur Urban Co.Op. Bank Ltd", label: "The Hiriyur Urban Co.Op. Bank Ltd" },
  { value: "The Hisar Dist. Central Co-Op. Bank Ltd", label: "The Hisar Dist. Central Co-Op. Bank Ltd" },
  { value: "The Hissar Urban Co-Op. Bank Ltd.", label: "The Hissar Urban Co-Op. Bank Ltd." },
  { value: "The Honavar Urban Co-Op. Bank Ltd.", label: "The Honavar Urban Co-Op. Bank Ltd." },
  { value: "The Hongkong & Shanghai Banking Corp Ltd", label: "The Hongkong & Shanghai Banking Corp Ltd" },
  { value: "The Hongkong & Shanghai Banking Corp.Ltd", label: "The Hongkong & Shanghai Banking Corp.Ltd" },
  { value: "The Hooghly Co Op. Credit Bank Ltd.", label: "The Hooghly Co Op. Credit Bank Ltd." },
  { value: "The Hooseini Coop Credit Society Ltd", label: "The Hooseini Coop Credit Society Ltd" },
  { value: "The Hosdurg Service Co-Op Bank Ltd.", label: "The Hosdurg Service Co-Op Bank Ltd." },
  { value: "The Hoshiarpur Central Co Op Bank Pvt Ltd", label: "The Hoshiarpur Central Co Op Bank Pvt Ltd" },
  { value: "The Hoshiarpur Central Co-Op. Bank Ltd.", label: "The Hoshiarpur Central Co-Op. Bank Ltd." },
  { value: "The Hospet Co.Op City Bank Ltd", label: "The Hospet Co.Op City Bank Ltd" },
  { value: "The Hotel Industrialists Co-Op Bank Ltd.", label: "The Hotel Industrialists Co-Op Bank Ltd." },
  { value: "The Howrah Dist. Cen. Co-Op Bank Ltd", label: "The Howrah Dist. Cen. Co-Op Bank Ltd" },
  { value: "The Howrah District Central Co-Op Bk Ltd", label: "The Howrah District Central Co-Op Bk Ltd" },
  { value: "The Hubli Urban Co-Op. Bank Ltd.", label: "The Hubli Urban Co-Op. Bank Ltd." },
  { value: "The Hubli Urban Co-Operative Bank Ltd", label: "The Hubli Urban Co-Operative Bank Ltd" },
  { value: "The Hukeri Urban Co-Operative Bank Ltd", label: "The Hukeri Urban Co-Operative Bank Ltd" },
  { value: "The Hukka Sewa Sahakari Mandali Ltd.", label: "The Hukka Sewa Sahakari Mandali Ltd." },
  { value: "The Hundu Urban Co Op Bank", label: "The Hundu Urban Co Op Bank" },
  { value: "The Hyderabad Dist.Co-Op Central Bank Lt", label: "The Hyderabad Dist.Co-Op Central Bank Lt" },
  { value: "The Ichalkaranji Merchants Co-Op. Bk.L.,", label: "The Ichalkaranji Merchants Co-Op. Bk.L.," },
  { value: "The Ichalkaranji Merchants Co.Op Bank Ltd", label: "The Ichalkaranji Merchants Co.Op Bank Ltd" },
  { value: "The Ichalkaranji Urban Co-Op. Bank Ltd., Ichalkaranji", label: "The Ichalkaranji Urban Co-Op. Bank Ltd., Ichalkaranji" },
  { value: "The Ichalkaranji Urban Co.Op. Bank Ltd.", label: "The Ichalkaranji Urban Co.Op. Bank Ltd." },
  { value: "The Idalhond Prathmik Krishi Pattin Sahakari Bank Ltd", label: "The Idalhond Prathmik Krishi Pattin Sahakari Bank Ltd" },
  { value: "The Idar Nagarik Sahakari Bank Ltd.", label: "The Idar Nagarik Sahakari Bank Ltd." },
  { value: "The Idukki Dis: Co-Op Bank Ltd", label: "The Idukki Dis: Co-Op Bank Ltd" },
  { value: "The Idukki District Co.Op. Bank Ltd", label: "The Idukki District Co.Op. Bank Ltd" },
  { value: "The Idukki-Kanjikuzhi Service Co-Op. B.L", label: "The Idukki-Kanjikuzhi Service Co-Op. B.L" },
  { value: "The Ilayangudi Co-Op. Urban Bank Ltd.", label: "The Ilayangudi Co-Op. Urban Bank Ltd." },
  { value: "The Ilkal Urban Co-Op Bank Ltd.", label: "The Ilkal Urban Co-Op Bank Ltd." },
  { value: "The Imphal Urban Co-Op. Bank Ltd.", label: "The Imphal Urban Co-Op. Bank Ltd." },
  { value: "The Income Tax Dept. Co-Op. Bank Ltd.", label: "The Income Tax Dept. Co-Op. Bank Ltd." },
  { value: "The India Cements Ltd", label: "The India Cements Ltd" },
  { value: "The India Security Press Employees Co Op Soc Ltd", label: "The India Security Press Employees Co Op Soc Ltd" },
  { value: "The Indukurpet Co-Op. Rural Bank Ltd.", label: "The Indukurpet Co-Op. Rural Bank Ltd." },
  { value: "The Industrial Co-Op. Bank Ltd", label: "The Industrial Co-Op. Bank Ltd" },
  { value: "The Industrial Credit And Investments Corpn. Of India Ltd.", label: "The Industrial Credit And Investments Corpn. Of India Ltd." },
  { value: "The Inst. Of Chartered Account Of India", label: "The Inst. Of Chartered Account Of India" },
  { value: "The Instalment Supply Limited", label: "The Instalment Supply Limited" },
  { value: "The Investment Trust Of India Ltd.", label: "The Investment Trust Of India Ltd." },
  { value: "The Irinjalakuda Town Co-Op. Bank Ltd.", label: "The Irinjalakuda Town Co-Op. Bank Ltd." },
  { value: "The Iritty Co-Op Rural Bank Ltd.", label: "The Iritty Co-Op Rural Bank Ltd." },
  { value: "The Iron And Steel Company Ltd.", label: "The Iron And Steel Company Ltd." },
  { value: "The Irumbuzhi Service Co-Op Bank Ltd.", label: "The Irumbuzhi Service Co-Op Bank Ltd." },
  { value: "The Islampur Urban Co-Op.Bank Ltd.", label: "The Islampur Urban Co-Op.Bank Ltd." },
  { value: "The Ismailia Urban Credit Co - Op Society Ltd", label: "The Ismailia Urban Credit Co - Op Society Ltd" },
  { value: "The J & K Bank Ltd.", label: "The J & K Bank Ltd." },
  { value: "The Jagruti Co-Op Bank Ltd,", label: "The Jagruti Co-Op Bank Ltd," },
  { value: "The Jagruti Co-Op Urban Bank Ltd", label: "The Jagruti Co-Op Urban Bank Ltd" },
  { value: "The Jahlod Urban Co-Op Bank Ltd", label: "The Jahlod Urban Co-Op Bank Ltd" },
  { value: "The Jain Sahakari Bank Ltd", label: "The Jain Sahakari Bank Ltd" },
  { value: "The Jain Sahakari Bank Ltd.", label: "The Jain Sahakari Bank Ltd." },
  { value: "The Jaipur Central Co Bk L,", label: "The Jaipur Central Co Bk L," },
  { value: "The Jaipur Central Co-Operative Bank Ltd", label: "The Jaipur Central Co-Operative Bank Ltd" },
  { value: "The Jaisalmer Central Co-Operative Bk Lt", label: "The Jaisalmer Central Co-Operative Bk Lt" },
  { value: "The Jal Central Co Op. Bank Ltd", label: "The Jal Central Co Op. Bank Ltd" },
  { value: "The Jalandhar Central Co-Op Bk Ltd", label: "The Jalandhar Central Co-Op Bk Ltd" },
  { value: "The Jalandhar Central Co-Op. Bank Ltd.", label: "The Jalandhar Central Co-Op. Bank Ltd." },
  { value: "The Jalandhar Co. Op. Bank Pvt Ltd", label: "The Jalandhar Co. Op. Bank Pvt Ltd" },
  { value: "The Jalandhar, Punjab Central Co.Op Bank", label: "The Jalandhar, Punjab Central Co.Op Bank" },
  { value: "The Jalgaon Dt.Cent. Co-Op Bank Ltd.", label: "The Jalgaon Dt.Cent. Co-Op Bank Ltd." },
  { value: "The Jalgaon Peoples Co-Op. Bank Ltd.", label: "The Jalgaon Peoples Co-Op. Bank Ltd." },
  { value: "The Jalna District Central Co-Op Bankltd", label: "The Jalna District Central Co-Op Bankltd" },
  { value: "The Jalna District Central Co-Op.Bank Lt", label: "The Jalna District Central Co-Op.Bank Lt" },
  { value: "The Jalna Peoples Co-Op Bank Ltd.", label: "The Jalna Peoples Co-Op Bank Ltd." },
  { value: "The Jalore Central Co-Op Bank Ltd.", label: "The Jalore Central Co-Op Bank Ltd." },
  { value: "The Jamakhand A/P Sankyatar Urban Co-Op Society", label: "The Jamakhand A/P Sankyatar Urban Co-Op Society" },
  { value: "The Jamakhandi Alphasankyatara Urban Co-Operative Society Ltd", label: "The Jamakhandi Alphasankyatara Urban Co-Operative Society Ltd" },
  { value: "The Jamakhandi Minority Co Operative Credit Society Jamkhandi", label: "The Jamakhandi Minority Co Operative Credit Society Jamkhandi" },
  { value: "The Jamakhandi Urban Co-Operative Bank", label: "The Jamakhandi Urban Co-Operative Bank" },
  { value: "The Jamboti Co-Operative Credit Society Ltd.", label: "The Jamboti Co-Operative Credit Society Ltd." },
  { value: "The Jambusar Peoples Co-Op Bank Ltd", label: "The Jambusar Peoples Co-Op Bank Ltd" },
  { value: "The Jamkhandi Urban Co-Op Bank Ltd.", label: "The Jamkhandi Urban Co-Op Bank Ltd." },
  { value: "The Jammalamadugu Co-Op Town Bank Ltd.,", label: "The Jammalamadugu Co-Op Town Bank Ltd.," },
  { value: "The Jammu & Kashmir Bank Ltd", label: "The Jammu & Kashmir Bank Ltd" },
  { value: "The Jammu & Kashmir Bank Ltd.", label: "The Jammu & Kashmir Bank Ltd." },
  { value: "The Jammu & Kashmir State Co-Op B.L.", label: "The Jammu & Kashmir State Co-Op B.L." },
  { value: "The Jammu Central Co-Op Bank Ltd.", label: "The Jammu Central Co-Op Bank Ltd." },
  { value: "The Jamnagar Co Op Bank Ltd", label: "The Jamnagar Co Op Bank Ltd" },
  { value: "The Jamnagar Dist. Co.Op Bank Ltd", label: "The Jamnagar Dist. Co.Op Bank Ltd" },
  { value: "The Jamnagar Mahila Sahkari Bank Ltd.", label: "The Jamnagar Mahila Sahkari Bank Ltd." },
  { value: "The Jamnagar Peoples Co.Op. Bank Ltd.", label: "The Jamnagar Peoples Co.Op. Bank Ltd." },
  { value: "The Janalaxmi Bank Ltd", label: "The Janalaxmi Bank Ltd" },
  { value: "The Janalaxmi Co-Op Bank Ltd", label: "The Janalaxmi Co-Op Bank Ltd" },
  { value: "The Janaseva Urban Co-Op Credit Society Ltd.", label: "The Janaseva Urban Co-Op Credit Society Ltd." },
  { value: "The Janata Co-Operative Bank", label: "The Janata Co-Operative Bank" },
  { value: "The Janata Co-Operative Bank Ltd.", label: "The Janata Co-Operative Bank Ltd." },
  { value: "The Janata Commercial Co-Op. Bank Ltd.", label: "The Janata Commercial Co-Op. Bank Ltd." },
  { value: "The Janata Sahakari Bank Ltd", label: "The Janata Sahakari Bank Ltd" },
  { value: "The Janatha Co-Op. Bank Ltd", label: "The Janatha Co-Op. Bank Ltd" },
  { value: "The Jankalyan Urban Co-Op Bank Ltd.", label: "The Jankalyan Urban Co-Op Bank Ltd." },
  { value: "The Janlaxmi Co Op Bank Ltd", label: "The Janlaxmi Co Op Bank Ltd" },
  { value: "The Janseva Credit Society", label: "The Janseva Credit Society" },
  { value: "The Janta Co Op Bank Ltd", label: "The Janta Co Op Bank Ltd" },
  { value: "The Janta Commercial Co-Op Bank Ltd,", label: "The Janta Commercial Co-Op Bank Ltd," },
  { value: "The Janta Sahkari Sharafi Mandali Ltd", label: "The Janta Sahkari Sharafi Mandali Ltd" },
  { value: "The Janta Sarafi Sahakari Mandali Ltd", label: "The Janta Sarafi Sahakari Mandali Ltd" },
  { value: "The Janta Seva. Sah. Mandali Ltd", label: "The Janta Seva. Sah. Mandali Ltd" },
  { value: "The Jaoli Sahakari Bank Ltd.", label: "The Jaoli Sahakari Bank Ltd." },
  { value: "The Jaoli Sahakari Bk Ltd.", label: "The Jaoli Sahakari Bk Ltd." },
  { value: "The Jawahar Urban Co Op Bank", label: "The Jawahar Urban Co Op Bank" },
  { value: "The Jawhar  Aurban Coop Bank", label: "The Jawhar  Aurban Coop Bank" },
  { value: "The Jawhar Urban Co-Op Bank Ltd.", label: "The Jawhar Urban Co-Op Bank Ltd." },
  { value: "The Jay Madi Co-Op Credit Society Ltd", label: "The Jay Madi Co-Op Credit Society Ltd" },
  { value: "The Jcc Bank", label: "The Jcc Bank" },
  { value: "The Jeypore Co-Op Urban Bank Ltd", label: "The Jeypore Co-Op Urban Bank Ltd" },
  { value: "The Jhagadia Vikash Bachat Dhiran Karnari Sahakari Mandli Ltd", label: "The Jhagadia Vikash Bachat Dhiran Karnari Sahakari Mandli Ltd" },
  { value: "The Jhajjar Central Co - Op Bank Ltd", label: "The Jhajjar Central Co - Op Bank Ltd" },
  { value: "The Jhajjar Central Co.Op. Bank Ltd", label: "The Jhajjar Central Co.Op. Bank Ltd" },
  { value: "The Jhalod Urban Co-Op Bank Ltd", label: "The Jhalod Urban Co-Op Bank Ltd" },
  { value: "The Jhalod Urban Coop Bank Ltd", label: "The Jhalod Urban Coop Bank Ltd" },
  { value: "The Jind Central Co-Operative Bank Ltd", label: "The Jind Central Co-Operative Bank Ltd" },
  { value: "The Jintur Urban Co Op Bank Ltd", label: "The Jintur Urban Co Op Bank Ltd" },
  { value: "The Jintur Urban Co-Op Bank Ltd", label: "The Jintur Urban Co-Op Bank Ltd" },
  { value: "The Jintur Urban Co.Op. Bank Ltd", label: "The Jintur Urban Co.Op. Bank Ltd" },
  { value: "The Jkd Minority Urban Co-Operative Credit Society", label: "The Jkd Minority Urban Co-Operative Credit Society" },
  { value: "The Jodhpur Central Co-Op. Bank Ltd", label: "The Jodhpur Central Co-Op. Bank Ltd" },
  { value: "The Jodhpur Central Co-Op. Bank Ltd.", label: "The Jodhpur Central Co-Op. Bank Ltd." },
  { value: "The Jodhpur Central Co-Opretive Bank Ltd.", label: "The Jodhpur Central Co-Opretive Bank Ltd." },
  { value: "The Jogindra Central Co Op. Bank Ltd", label: "The Jogindra Central Co Op. Bank Ltd" },
  { value: "The Jowai Co-Operative Urban Bank Ltd", label: "The Jowai Co-Operative Urban Bank Ltd" },
  { value: "The Jowai Co-Operative Urban Bank Ltd,", label: "The Jowai Co-Operative Urban Bank Ltd," },
  { value: "The Junagadh Commercial Co-Op Bank L,", label: "The Junagadh Commercial Co-Op Bank L," },
  { value: "The Junagadh Commercial Co-Op.Bank Ltd.", label: "The Junagadh Commercial Co-Op.Bank Ltd." },
  { value: "The Junagadh Dist.Cen.Co-Op.Bank Ltd.", label: "The Junagadh Dist.Cen.Co-Op.Bank Ltd." },
  { value: "The Jyoti Cooperative Agriculture", label: "The Jyoti Cooperative Agriculture" },
  { value: "The Jyoti Cp Non-Agri Thrift & Cre Soc.", label: "The Jyoti Cp Non-Agri Thrift & Cre Soc." },
  { value: "The Kabilpore People Co Op Credit Soc Ltd", label: "The Kabilpore People Co Op Credit Soc Ltd" },
  { value: "The Kachchh District Cent.Co-Op Bank Ltd", label: "The Kachchh District Cent.Co-Op Bank Ltd" },
  { value: "The Kadaba Co-Op Agricultural Bank Ltd.", label: "The Kadaba Co-Op Agricultural Bank Ltd." },
  { value: "The Kadachira Service Co. Bank Ltd", label: "The Kadachira Service Co. Bank Ltd" },
  { value: "The Kadakam Service Co-Operative Bank Lt", label: "The Kadakam Service Co-Operative Bank Lt" },
  { value: "The Kadakkal Services Cooperative Bank L", label: "The Kadakkal Services Cooperative Bank L" },
  { value: "The Kadali Urban Co Op Bank Ltd", label: "The Kadali Urban Co Op Bank Ltd" },
  { value: "The Kadamboor Service Coop Bank Ltd", label: "The Kadamboor Service Coop Bank Ltd" },
  { value: "The Kadanad Service Co-Op. Bank Ltd.", label: "The Kadanad Service Co-Op. Bank Ltd." },
  { value: "The Kadannamanna Service Co-Op Bank Ltd", label: "The Kadannamanna Service Co-Op Bank Ltd" },
  { value: "The Kadannamanna Service Co-Op Bk Ltd.", label: "The Kadannamanna Service Co-Op Bk Ltd." },
  { value: "The Kadapa District Coop Central Bank Lt", label: "The Kadapa District Coop Central Bank Lt" },
  { value: "The Kadavallur Services Coop Bank Ltd", label: "The Kadavallur Services Coop Bank Ltd" },
  { value: "The Kadavathur Service Co-Op. Bank Ltd", label: "The Kadavathur Service Co-Op. Bank Ltd" },
  { value: "The Kadavoor Ser.Co-Op.Bank Ltd.", label: "The Kadavoor Ser.Co-Op.Bank Ltd." },
  { value: "The Kadirur Service Co-Op. Bank Ltd.", label: "The Kadirur Service Co-Op. Bank Ltd." },
  { value: "The Kadiyadra Seva Sahkari Mandali Ltd", label: "The Kadiyadra Seva Sahkari Mandali Ltd" },
  { value: "The Kadodra Vibhag Sahakari Bachat And Dhiran Karnari Mandli Ltd.", label: "The Kadodra Vibhag Sahakari Bachat And Dhiran Karnari Mandli Ltd." },
  { value: "The Kadungalloor Ser. Co-Op. Bank Ltd.", label: "The Kadungalloor Ser. Co-Op. Bank Ltd." },
  { value: "The Kadungalloor Service Coop Bank Ltd", label: "The Kadungalloor Service Coop Bank Ltd" },
  { value: "The Kadungallor Service Co-Op Bk Ltd", label: "The Kadungallor Service Co-Op Bk Ltd" },
  { value: "The Kaduthuruthy Regional Serv.Co-Op Bk.", label: "The Kaduthuruthy Regional Serv.Co-Op Bk." },
  { value: "The Kaduthuruthy Urban Co- Op Bank Ltd", label: "The Kaduthuruthy Urban Co- Op Bank Ltd" },
  { value: "The Kaduthuruthy Urban Co-Operative Bank Ltd", label: "The Kaduthuruthy Urban Co-Operative Bank Ltd" },
  { value: "The Kaipattoor Service Co-Operative Bank", label: "The Kaipattoor Service Co-Operative Bank" },
  { value: "The Kaira Dist. Central Co-Op Bank Ltd.", label: "The Kaira Dist. Central Co-Op Bank Ltd." },
  { value: "The Kaira District Central Coop Bank Ltd", label: "The Kaira District Central Coop Bank Ltd" },
  { value: "The Kaithal Central Co-Op Bank Ltd.", label: "The Kaithal Central Co-Op Bank Ltd." },
  { value: "The Kakinada Co-Op. Town Bank Ltd.", label: "The Kakinada Co-Op. Town Bank Ltd." },
  { value: "The Kakkattil Co-Op. Rural Bank Ltd", label: "The Kakkattil Co-Op. Rural Bank Ltd" },
  { value: "The Kakkoor Service Co-Op Bank", label: "The Kakkoor Service Co-Op Bank" },
  { value: "The Kakkoor Service Co-Operative Bank", label: "The Kakkoor Service Co-Operative Bank" },
  { value: "The Kakkur Service Co-Operative Bank Ltd", label: "The Kakkur Service Co-Operative Bank Ltd" },
  { value: "The Kakuda Lamps Ltd.", label: "The Kakuda Lamps Ltd." },
  { value: "The Kalanad Service Co-Op. Bank Ltd", label: "The Kalanad Service Co-Op. Bank Ltd" },
  { value: "The Kalapet Primary Agri. Co-Op Bank Ltd", label: "The Kalapet Primary Agri. Co-Op Bank Ltd" },
  { value: "The Kalghatgi Urban Co-Op. Bank Ltd.", label: "The Kalghatgi Urban Co-Op. Bank Ltd." },
  { value: "The Kalikavu Service Co-Op Bank Ltd.", label: "The Kalikavu Service Co-Op Bank Ltd." },
  { value: "The Kalka Town Credit Co-Op. Bank Ltd.", label: "The Kalka Town Credit Co-Op. Bank Ltd." },
  { value: "The Kallanode Service Co-Opera Bank Ltd", label: "The Kallanode Service Co-Opera Bank Ltd" },
  { value: "The Kallar Service Coop Bank Ltd", label: "The Kallar Service Coop Bank Ltd" },
  { value: "The Kallara Village Service Co-Op Bk Ltd", label: "The Kallara Village Service Co-Op Bk Ltd" },
  { value: "The Kalliyoor Service Co-Op Bank Ltd", label: "The Kalliyoor Service Co-Op Bank Ltd" },
  { value: "The Kalloli Souharda Sahakari Ltd", label: "The Kalloli Souharda Sahakari Ltd" },
  { value: "The Kalmeshwar Co-Op Credit Basavan Kudachi Society Ltd.", label: "The Kalmeshwar Co-Op Credit Basavan Kudachi Society Ltd." },
  { value: "The Kalna Credit Cooperative Bank Ltd", label: "The Kalna Credit Cooperative Bank Ltd" },
  { value: "The Kalna Town Credit Co-Op. Bank Ltd.", label: "The Kalna Town Credit Co-Op. Bank Ltd." },
  { value: "The Kalol Nagrik Sahakari Bank Ltd", label: "The Kalol Nagrik Sahakari Bank Ltd" },
  { value: "The Kalol Peoples Co-Op Bank Ltd.", label: "The Kalol Peoples Co-Op Bank Ltd." },
  { value: "The Kalol Urban Co - Op Bank Ltd", label: "The Kalol Urban Co - Op Bank Ltd" },
  { value: "The Kalpathur Ser Co-Op Bank Lt,", label: "The Kalpathur Ser Co-Op Bank Lt," },
  { value: "The Kalpetta Service Co-Op Bank Ltd", label: "The Kalpetta Service Co-Op Bank Ltd" },
  { value: "The Kalupur Commercial Co-Op Bank Ltd", label: "The Kalupur Commercial Co-Op Bank Ltd" },
  { value: "The Kalwan Marchants Co-Op Bank Ltd.", label: "The Kalwan Marchants Co-Op Bank Ltd." },
  { value: "The Kalwan Merchants Co-Op. Bk.L.", label: "The Kalwan Merchants Co-Op. Bk.L." },
  { value: "The Kalyan Co-Operative Urban Bank Ltd.", label: "The Kalyan Co-Operative Urban Bank Ltd." },
  { value: "The Kalyan Janata Co-Op Bank Ltd", label: "The Kalyan Janata Co-Op Bank Ltd" },
  { value: "The Kambil Co-Op. Ur. Bank Ltd.", label: "The Kambil Co-Op. Ur. Bank Ltd." },
  { value: "The Kamuthi Co-Op Urban Bank Ltd.", label: "The Kamuthi Co-Op Urban Bank Ltd." },
  { value: "The Kanaka Pattana Sahakari Bank Niyamit", label: "The Kanaka Pattana Sahakari Bank Niyamit" },
  { value: "The Kanakamahalakshmi Co-Operative Bk.L", label: "The Kanakamahalakshmi Co-Operative Bk.L" },
  { value: "The Kanakkary Service Co-Op Bank Ltd", label: "The Kanakkary Service Co-Op Bank Ltd" },
  { value: "The Kanamala Service Co.Op.Bank Ltd.", label: "The Kanamala Service Co.Op.Bank Ltd." },
  { value: "The Kanara Dist Central Co-Op Bank Ltd", label: "The Kanara Dist Central Co-Op Bank Ltd" },
  { value: "The Kanara Dist. Central Co.Op. Bk Ltd", label: "The Kanara Dist. Central Co.Op. Bk Ltd" },
  { value: "The Kanara Dist.Central Co-Op.Bank Ltd.", label: "The Kanara Dist.Central Co-Op.Bank Ltd." },
  { value: "The Kancheepuram Central Co-Op Bank Ltd", label: "The Kancheepuram Central Co-Op Bank Ltd" },
  { value: "The Kangra Central Co-Op Bank Ltd.", label: "The Kangra Central Co-Op Bank Ltd." },
  { value: "The Kangra Co-Operative Bank Ltd", label: "The Kangra Co-Operative Bank Ltd" },
  { value: "The Kangra Corp Bank Ltd", label: "The Kangra Corp Bank Ltd" },
  { value: "The Kanhikulam Services Cooperative Bk L", label: "The Kanhikulam Services Cooperative Bk L" },
  { value: "The Kanhirode Service Co-Op Bank Ltd.", label: "The Kanhirode Service Co-Op Bank Ltd." },
  { value: "The Kanichukulangara Service Co-Op Bk Lt", label: "The Kanichukulangara Service Co-Op Bk Lt" },
  { value: "The Kaniyambetta Ser. Co-Op. Bank Ltd.", label: "The Kaniyambetta Ser. Co-Op. Bank Ltd." },
  { value: "The Kanjikode Service Co-Op Bank Ltd.", label: "The Kanjikode Service Co-Op Bank Ltd." },
  { value: "The Kannadi Service Co-Op. Bank Ltd.", label: "The Kannadi Service Co-Op. Bank Ltd." },
  { value: "The Kannadiparamba Ser. C.B.L.", label: "The Kannadiparamba Ser. C.B.L." },
  { value: "The Kannambra Co.Op. Service Bank Ltd.", label: "The Kannambra Co.Op. Service Bank Ltd." },
  { value: "The Kannapuram Service Co-Oper Bank Ltd", label: "The Kannapuram Service Co-Oper Bank Ltd" },
  { value: "The Kannur District Co-Op. Bank Limited", label: "The Kannur District Co-Op. Bank Limited" },
  { value: "The Kannur District Co-Operative Bank Lt", label: "The Kannur District Co-Operative Bank Lt" },
  { value: "The Kannur Service Co-Op. Bk. Ltd.", label: "The Kannur Service Co-Op. Bk. Ltd." },
  { value: "The Kannur Town Service Co-Op.Bank Ltd", label: "The Kannur Town Service Co-Op.Bank Ltd" },
  { value: "The Kanodar Co Op Credit Soc Ltd", label: "The Kanodar Co Op Credit Soc Ltd" },
  { value: "The Kanyakumari Dist. Cent. C.P. Bank Lt", label: "The Kanyakumari Dist. Cent. C.P. Bank Lt" },
  { value: "The Kaododra Vibhag Bachal Dhiran Karnai Sahkari Mandli, Ltd.", label: "The Kaododra Vibhag Bachal Dhiran Karnai Sahkari Mandli, Ltd." },
  { value: "The Kapadwanj Peoples Co-Op. Bank Ltd.", label: "The Kapadwanj Peoples Co-Op. Bank Ltd." },
  { value: "The Kapil Co Op Bank Ltd", label: "The Kapil Co Op Bank Ltd" },
  { value: "The Kapol Co-Op Bank Ltd", label: "The Kapol Co-Op Bank Ltd" },
  { value: "The Kappad Service Co-Op Bank Ltd.", label: "The Kappad Service Co-Op Bank Ltd." },
  { value: "The Kappumthala Service Co-Op Bank Ltd", label: "The Kappumthala Service Co-Op Bank Ltd" },
  { value: "The Kapur Vysya Bank Ltd", label: "The Kapur Vysya Bank Ltd" },
  { value: "The Kapurthala Central Co-Op Bank Ltd", label: "The Kapurthala Central Co-Op Bank Ltd" },
  { value: "The Kapurthala Central Co-Operation Bank Ltd", label: "The Kapurthala Central Co-Operation Bank Ltd" },
  { value: "The Karad Janata Sahakari Bank Ltd.", label: "The Karad Janata Sahakari Bank Ltd." },
  { value: "The Karad Merchant Sah.Cr. Sanstha Mydt.", label: "The Karad Merchant Sah.Cr. Sanstha Mydt." },
  { value: "The Karad Urban Co-Op Bank Ltd.", label: "The Karad Urban Co-Op Bank Ltd." },
  { value: "The Karainakam Ser. Co. Bank Ltd", label: "The Karainakam Ser. Co. Bank Ltd" },
  { value: "The Karakulam Service Co-Op Bank Ltd.", label: "The Karakulam Service Co-Op Bank Ltd." },
  { value: "The Karakurussi Service Co-Op Bk Ltd.", label: "The Karakurussi Service Co-Op Bk Ltd." },
  { value: "The Karamsad Urban Co-Op Bank Ltd.", label: "The Karamsad Urban Co-Op Bank Ltd." },
  { value: "The Karan Urban Co-Op Bank", label: "The Karan Urban Co-Op Bank" },
  { value: "The Karannur Service Co-Op.Bank Ltd.", label: "The Karannur Service Co-Op.Bank Ltd." },
  { value: "The Karanthur Service Co-Op Bank Ltd", label: "The Karanthur Service Co-Op Bank Ltd" },
  { value: "The Karapuzha Ser.Co.Op. Bank Ltd", label: "The Karapuzha Ser.Co.Op. Bank Ltd" },
  { value: "The Kararinakam Services Co-Op. Bk Ltd,", label: "The Kararinakam Services Co-Op. Bk Ltd," },
  { value: "The Karavali Credit Co-Op Society Ltd", label: "The Karavali Credit Co-Op Society Ltd" },
  { value: "The Karimkunnam Service Co-Op Bank Ltd.", label: "The Karimkunnam Service Co-Op Bank Ltd." },
  { value: "The Karimnagar Co-Op. Urban Bank Ltd.", label: "The Karimnagar Co-Op. Urban Bank Ltd." },
  { value: "The Karimnagar Dist. Co-Op.Cen. Bank Ltd", label: "The Karimnagar Dist. Co-Op.Cen. Bank Ltd" },
  { value: "The Karimpuzha Co-Operative Urban Bank L", label: "The Karimpuzha Co-Operative Urban Bank L" },
  { value: "The Karindalam Service Co-Op, Bank Ltd", label: "The Karindalam Service Co-Op, Bank Ltd" },
  { value: "The Karjan Nagrik Sahakari Bank Ltd.", label: "The Karjan Nagrik Sahakari Bank Ltd." },
  { value: "The Karkala C-Operative Service Bank Ltd", label: "The Karkala C-Operative Service Bank Ltd" },
  { value: "The Karkala Co-Operative Service Bank Lt", label: "The Karkala Co-Operative Service Bank Lt" },
  { value: "The Karkala Co-Operative Service Bank Ltd", label: "The Karkala Co-Operative Service Bank Ltd" },
  { value: "The Karmachari Co-Op Bank Ltd", label: "The Karmachari Co-Op Bank Ltd" },
  { value: "The Karmala Co-Op Bank Ltd", label: "The Karmala Co-Op Bank Ltd" },
  { value: "The Karmala Co-Op Bank Ltd Karmala", label: "The Karmala Co-Op Bank Ltd Karmala" },
  { value: "The Karmala Urban Co-Op Bank Ltd.", label: "The Karmala Urban Co-Op Bank Ltd." },
  { value: "The Karnal Central C-Operative Bank Ltd.", label: "The Karnal Central C-Operative Bank Ltd." },
  { value: "The Karnal Central Co-Op Bank Ltd,", label: "The Karnal Central Co-Op Bank Ltd," },
  { value: "The Karnal Central Co-Op Bank, Ltd", label: "The Karnal Central Co-Op Bank, Ltd" },
  { value: "The Karnal Co-Op Bank Ltd", label: "The Karnal Co-Op Bank Ltd" },
  { value: "The Karnatak Central Co-Op. Bank Ltd.", label: "The Karnatak Central Co-Op. Bank Ltd." },
  { value: "The Karnatak Central Coop Bank Ltd,", label: "The Karnatak Central Coop Bank Ltd," },
  { value: "The Karnataka Alp Sankyatara Pattin Sahakari Sangh Niyamit", label: "The Karnataka Alp Sankyatara Pattin Sahakari Sangh Niyamit" },
  { value: "The Karnataka Bank Ltd", label: "The Karnataka Bank Ltd" },
  { value: "The Karnataka Bank Ltd.", label: "The Karnataka Bank Ltd." },
  { value: "The Karnataka Co-Operative Apex Bank Ltd.", label: "The Karnataka Co-Operative Apex Bank Ltd." },
  { value: "The Karnataka Co-Operative Bank Ltd", label: "The Karnataka Co-Operative Bank Ltd" },
  { value: "The Karnataka Co-Operative Bank Ltd Muddebihal Bijapur Dist", label: "The Karnataka Co-Operative Bank Ltd Muddebihal Bijapur Dist" },
  { value: "The Karnataka Contractors Cr. Cp Soc Lt", label: "The Karnataka Contractors Cr. Cp Soc Lt" },
  { value: "The Karnataka District Central Co-Operative Bank Ltd.", label: "The Karnataka District Central Co-Operative Bank Ltd." },
  { value: "The Karnataka Industrial Co-Op.Bank Ltd.", label: "The Karnataka Industrial Co-Op.Bank Ltd." },
  { value: "The Karnataka Minority Credit Co-Operative Society", label: "The Karnataka Minority Credit Co-Operative Society" },
  { value: "The Karnataka Multi Purpose Co - Op Society Ltd", label: "The Karnataka Multi Purpose Co - Op Society Ltd" },
  { value: "The Karnataka State Co-Op Apex Bank Ltd", label: "The Karnataka State Co-Op Apex Bank Ltd" },
  { value: "The Karnataka State Ind.  Co-Op Bank Ltd", label: "The Karnataka State Ind.  Co-Op Bank Ltd" },
  { value: "The Karnavati Co-Op Bank Ltd.", label: "The Karnavati Co-Op Bank Ltd." },
  { value: "The Karomia Urban Co Op Credit Soc Ltd", label: "The Karomia Urban Co Op Credit Soc Ltd" },
  { value: "The Karukutty Service Co-Op Bk Ltd", label: "The Karukutty Service Co-Op Bk Ltd" },
  { value: "The Karulai Service Co Op Bk Ltd,", label: "The Karulai Service Co Op Bk Ltd," },
  { value: "The Karulutty Bank Ltd.,", label: "The Karulutty Bank Ltd.," },
  { value: "The Karunagappally Taluk Ur.Co-Op.Bk.L.", label: "The Karunagappally Taluk Ur.Co-Op.Bk.L." },
  { value: "The Karuntattankudi Dravidian Cp Ur Bk L", label: "The Karuntattankudi Dravidian Cp Ur Bk L" },
  { value: "The Karur Town Co-Op. Bank Ltd", label: "The Karur Town Co-Op. Bank Ltd" },
  { value: "Srei Equipment Finance Ltd. And The Karur Vysya Bank Limited", label: "Srei Equipment Finance Ltd. And The Karur Vysya Bank Limited" },
  { value: "The Karur Vysya Bank Ltd", label: "The Karur Vysya Bank Ltd" },
  { value: "The Karuvarakundu Co-Op Service Bank Ltd", label: "The Karuvarakundu Co-Op Service Bank Ltd" },
  { value: "The Karuvarakundu Service Co-Op Bank Ltd", label: "The Karuvarakundu Service Co-Op Bank Ltd" },
  { value: "The Karvr Vyasa Bank Ltd", label: "The Karvr Vyasa Bank Ltd" },
  { value: "The Karyara Co-Op. Agr. Serv. Soceity Ltd.", label: "The Karyara Co-Op. Agr. Serv. Soceity Ltd." },
  { value: "The Kasaragod Co-Op Town Bank Ltd", label: "The Kasaragod Co-Op Town Bank Ltd" },
  { value: "The Kasaragod District Co-Op.Bank Ltd.", label: "The Kasaragod District Co-Op.Bank Ltd." },
  { value: "The Kasaragod Service Co Op.Bank Ltd", label: "The Kasaragod Service Co Op.Bank Ltd" },
  { value: "The Kasargod Co Op Town Bank Ltd", label: "The Kasargod Co Op Town Bank Ltd" },
  { value: "The Katlari Kariyana Merchant Sahkari Sarafi Mandali", label: "The Katlari Kariyana Merchant Sahkari Sarafi Mandali" },
  { value: "The Kattappana Service Co. Bank Ltd", label: "The Kattappana Service Co. Bank Ltd" },
  { value: "The Kattappana Urban Co-Op. Bank Ltd", label: "The Kattappana Urban Co-Op. Bank Ltd" },
  { value: "The Kattappana Urban Co-Op. Bank Ltd.", label: "The Kattappana Urban Co-Op. Bank Ltd." },
  { value: "The Kattumannarkoil Rural Co-Op. Bk.L.", label: "The Kattumannarkoil Rural Co-Op. Bk.L." },
  { value: "The Kaup Co-Op. Agricultural Bank Ltd.", label: "The Kaup Co-Op. Agricultural Bank Ltd." },
  { value: "The Kaur Vyas Bank Ltd", label: "The Kaur Vyas Bank Ltd" },
  { value: "The Kavalangad Ser. Co-Op. Bank Ltd", label: "The Kavalangad Ser. Co-Op. Bank Ltd" },
  { value: "The Kaveripatnam Co-Op Town Bank Ltd.", label: "The Kaveripatnam Co-Op Town Bank Ltd." },
  { value: "The Kaveripatnam Co-Op.Bank Ltd.", label: "The Kaveripatnam Co-Op.Bank Ltd." },
  { value: "The Kavil Service Co-Op Bank Ltd,", label: "The Kavil Service Co-Op Bank Ltd," },
  { value: "The Kavitha Co Op Credit Society Ltd.", label: "The Kavitha Co Op Credit Society Ltd." },
  { value: "The Kayyur Service Co-Operative Bank Ltd", label: "The Kayyur Service Co-Operative Bank Ltd" },
  { value: "The Kazhani Services Cooperative Bank Lt", label: "The Kazhani Services Cooperative Bank Lt" },
  { value: "The Kcc Bank", label: "The Kcc Bank" },
  { value: "The Keezhariyoor Services Co-Op Bank Ltd", label: "The Keezhariyoor Services Co-Op Bank Ltd" },
  { value: "The Keezhillam Service C. P. Bk Ltd.", label: "The Keezhillam Service C. P. Bk Ltd." },
  { value: "The Keezhillam Services Coop Bank Ltd.", label: "The Keezhillam Services Coop Bank Ltd." },
  { value: "The Keezhmad Service Co.Op. Bank Ltd", label: "The Keezhmad Service Co.Op. Bank Ltd" },
  { value: "The Keezhoor Service Co-Operative Bank L", label: "The Keezhoor Service Co-Operative Bank L" },
  { value: "The Keezhriyoor Services Co-Op Bank Ltd.", label: "The Keezhriyoor Services Co-Op Bank Ltd." },
  { value: "The Kelakam Service Co-Op. Bank Ltd.", label: "The Kelakam Service Co-Op. Bank Ltd." },
  { value: "The Keonjhar Central Co Op Bank Ltd", label: "The Keonjhar Central Co Op Bank Ltd" },
  { value: "The Kerala Mercantile Co-Op. Bank Ltd.", label: "The Kerala Mercantile Co-Op. Bank Ltd." },
  { value: "The Kerala State Backward Classes Development Corporation Ltd", label: "The Kerala State Backward Classes Development Corporation Ltd" },
  { value: "The Kerala State Development Corporation For Scheduled Casts And Scheduled Tribes Ltd", label: "The Kerala State Development Corporation For Scheduled Casts And Scheduled Tribes Ltd" },
  { value: "The Kerla State Co-Operative Bank Ltd.", label: "The Kerla State Co-Operative Bank Ltd." },
  { value: "The Keyur Vysya Bank Ltd", label: "The Keyur Vysya Bank Ltd" },
  { value: "The Khagaria Dist Cen Co-Op Bank Ltd,", label: "The Khagaria Dist Cen Co-Op Bank Ltd," },
  { value: "The Khambhat Nagrik Sahakari Bank Ltd.", label: "The Khambhat Nagrik Sahakari Bank Ltd." },
  { value: "The Khamgaon Urban Co-Op. Bank Ltd", label: "The Khamgaon Urban Co-Op. Bank Ltd" },
  { value: "The Khanapur Co-Op Bank Ltd", label: "The Khanapur Co-Op Bank Ltd" },
  { value: "The Khanapur Co-Op Bank Ltd, Khanapur", label: "The Khanapur Co-Op Bank Ltd, Khanapur" },
  { value: "The Khanapur Coop Bank Ltd,", label: "The Khanapur Coop Bank Ltd," },
  { value: "The Khanapur Urban Co-Op.Credit Bank Ltd", label: "The Khanapur Urban Co-Op.Credit Bank Ltd" },
  { value: "The Khatri Co Op Urban Bank Ltd", label: "The Khatri Co Op Urban Bank Ltd" },
  { value: "The Khatri Co-Operative Bank Ltd", label: "The Khatri Co-Operative Bank Ltd" },
  { value: "The Khattr", label: "The Khattr" },
  { value: "The Khattri Co-Op. Urban Bank Ltd", label: "The Khattri Co-Op. Urban Bank Ltd" },
  { value: "The Kheda Peoples Co-Op.Bank Ltd.", label: "The Kheda Peoples Co-Op.Bank Ltd." },
  { value: "The Kheda Peoples Co Op Bank", label: "The Kheda Peoples Co Op Bank" },
  { value: "The Kheraju Co Op Bank Ltd", label: "The Kheraju Co Op Bank Ltd" },
  { value: "The Kheralu Nagarik Sahakari Bank Ltd.", label: "The Kheralu Nagarik Sahakari Bank Ltd." },
  { value: "The Kheralu Urban Co Op Bank Ltd", label: "The Kheralu Urban Co Op Bank Ltd" },
  { value: "The Khurda Central Co - Op Bank Ltd", label: "The Khurda Central Co - Op Bank Ltd" },
  { value: "The Khurda Central Co Operative Bank Ltd.", label: "The Khurda Central Co Operative Bank Ltd." },
  { value: "The Khurda Co Op Agricultural & Rural Development Bank Ltd.", label: "The Khurda Co Op Agricultural & Rural Development Bank Ltd." },
  { value: "The Kidangoor Service Co-Op Bank Ltd.", label: "The Kidangoor Service Co-Op Bank Ltd." },
  { value: "The Kilikollor Ser. Co. Op. Bk Ltd", label: "The Kilikollor Ser. Co. Op. Bk Ltd" },
  { value: "The Kiliyanthara Service Co-Op. Bank Ltd", label: "The Kiliyanthara Service Co-Op. Bank Ltd" },
  { value: "The Killimangalam Farmers Ser.C.Bank Ltd", label: "The Killimangalam Farmers Ser.C.Bank Ltd" },
  { value: "The Kizakkoth Ser. Co-Op Bank Ltd.", label: "The Kizakkoth Ser. Co-Op Bank Ltd." },
  { value: "The Kizhakkenchery Service Co-Op Bank Lt", label: "The Kizhakkenchery Service Co-Op Bank Lt" },
  { value: "The Kizhathadiyoor Service Co-Op. Bk. L.", label: "The Kizhathadiyoor Service Co-Op. Bk. L." },
  { value: "The Kizhuvilam Service Co-Operative Bank", label: "The Kizhuvilam Service Co-Operative Bank" },
  { value: "The Kle Society Staff Co - Op Cr Soc Ltd.", label: "The Kle Society Staff Co - Op Cr Soc Ltd." },
  { value: "The Kochadai Primary Agri. Co-Op. Bank L", label: "The Kochadai Primary Agri. Co-Op. Bank L" },
  { value: "The Kodagu District Co-Op.Central Bk Ltd", label: "The Kodagu District Co-Op.Central Bk Ltd" },
  { value: "The Kodaikanal Co-Op Urban Bank Ltd.", label: "The Kodaikanal Co-Op Urban Bank Ltd." },
  { value: "The Kodali Urban Co.Op. Bank Ltd.", label: "The Kodali Urban Co.Op. Bank Ltd." },
  { value: "The Kodanchery Service Co-Op Bank Ltd.", label: "The Kodanchery Service Co-Op Bank Ltd." },
  { value: "The Kodinar Nagarik Sah Bk L", label: "The Kodinar Nagarik Sah Bk L" },
  { value: "The Kodinar Nagarik Sahakari Bank Ltd", label: "The Kodinar Nagarik Sahakari Bank Ltd" },
  { value: "The Kodinar Taluka Co-Op Banking Union L", label: "The Kodinar Taluka Co-Op Banking Union L" },
  { value: "The Kodiyathoor Service Co-Op Bank Ltd.", label: "The Kodiyathoor Service Co-Op Bank Ltd." },
  { value: "The Kodlamogaru-Pathar Ser. Co-Op Bk", label: "The Kodlamogaru-Pathar Ser. Co-Op Bk" },
  { value: "The Kodoli Urban Co-Op Bank Ltd", label: "The Kodoli Urban Co-Op Bank Ltd" },
  { value: "The Kodoli Urban Co-Op Bank Ltd", label: "The Kodoli Urban Co-Op Bank Ltd" },
  { value: "The Kodoli Urban Co-Op Bank Ltd.", label: "The Kodoli Urban Co-Op Bank Ltd." },
  { value: "The Kodungallur Town Co-Op Bank Ltd.", label: "The Kodungallur Town Co-Op Bank Ltd." },
  { value: "The Koduvally Service Co-Op. Bank Ltd.", label: "The Koduvally Service Co-Op. Bank Ltd." },
  { value: "The Koduvayur Co-Op Urban Credit Soc Ltd", label: "The Koduvayur Co-Op Urban Credit Soc Ltd" },
  { value: "The Kolacheri Service Co-Op Bank Ltd", label: "The Kolacheri Service Co-Op Bank Ltd" },
  { value: "The Kolacheri Service Co-Op Bk Ltd,", label: "The Kolacheri Service Co-Op Bk Ltd," },
  { value: "The Kolar District Co-Op Central Bk Ltd", label: "The Kolar District Co-Op Central Bk Ltd" },
  { value: "The Kolari Service Co-Op. Bk. Ltd.", label: "The Kolari Service Co-Op. Bk. Ltd." },
  { value: "The Kolayad Service Co-Op. Bank Ltd.", label: "The Kolayad Service Co-Op. Bank Ltd." },
  { value: "The Kolazhy Service Co-Op Bank Ltd", label: "The Kolazhy Service Co-Op Bank Ltd" },
  { value: "The Kolhapur Maratha Co-Op. Bank Ltd", label: "The Kolhapur Maratha Co-Op. Bank Ltd" },
  { value: "The Kolhapur Urban Co-Op. Bank Ltd.", label: "The Kolhapur Urban Co-Op. Bank Ltd." },
  { value: "The Kolhapur Zilla Govt. Serv Cp Bk Ltd,", label: "The Kolhapur Zilla Govt. Serv Cp Bk Ltd," },
  { value: "The Kollam District Co-Op. Bank Ltd.", label: "The Kollam District Co-Op. Bank Ltd." },
  { value: "The Kollengode Agri Serv Co-Op Bank Ltd.", label: "The Kollengode Agri Serv Co-Op Bank Ltd." },
  { value: "The Kollengode Co-Op Urban Credit Soc.Lt", label: "The Kollengode Co-Op Urban Credit Soc.Lt" },
  { value: "The Kondotty Service Co-Operat Bank Ltd", label: "The Kondotty Service Co-Operat Bank Ltd" },
  { value: "The Kongad Co-Op. Bank Ltd.", label: "The Kongad Co-Op. Bank Ltd." },
  { value: "The Kongampatu Primary Agr.Co-Op. Bank L", label: "The Kongampatu Primary Agr.Co-Op. Bank L" },
  { value: "The Konkan Prant Sahakari Bank Ltd.", label: "The Konkan Prant Sahakari Bank Ltd." },
  { value: "The Koodali Service Co.Op. Bank Ltd", label: "The Koodali Service Co.Op. Bank Ltd" },
  { value: "The Kooroppada Ser.Co-Op. Bank Ltd.", label: "The Kooroppada Ser.Co-Op. Bank Ltd." },
  { value: "The Koothali Ser Co-Op Bank Ltd,", label: "The Koothali Ser Co-Op Bank Ltd," },
  { value: "The Koottar Services Co-Op Bank  Ltd.", label: "The Koottar Services Co-Op Bank  Ltd." },
  { value: "The Kooveri Service Co-Op Bank Ltd.", label: "The Kooveri Service Co-Op Bank Ltd." },
  { value: "The Kopargaon Co.Op. Peoples Bank Ltd.", label: "The Kopargaon Co.Op. Peoples Bank Ltd." },
  { value: "The Kopargaon Taluka Merchants Co.Bk Ltd", label: "The Kopargaon Taluka Merchants Co.Bk Ltd" },
  { value: "The Kopergaon Peoples Co-Op. Bank Ltd.", label: "The Kopergaon Peoples Co-Op. Bank Ltd." },
  { value: "The Koppam Service Co-Op. Bank Ltd", label: "The Koppam Service Co-Op. Bank Ltd" },
  { value: "The Korangrapady Co-Operative Agri Bank Ltd", label: "The Korangrapady Co-Operative Agri Bank Ltd" },
  { value: "The Korangrapady Co-Operative Agri Bank Ltd", label: "The Korangrapady Co-Operative Agri Bank Ltd" },
  { value: "The Koraput Central Co-Op.Bank.Ltd.", label: "The Koraput Central Co-Op.Bank.Ltd." },
  { value: "The Koregaon Co-Op People Bk Ltd.", label: "The Koregaon Co-Op People Bk Ltd." },
  { value: "The Korom Service Co-Operative Bank", label: "The Korom Service Co-Operative Bank" },
  { value: "The Kosamba Mercantile Co.Op. Bank Ltd", label: "The Kosamba Mercantile Co.Op. Bank Ltd" },
  { value: "The Kosamba Mercentile Co Op Bank Ltd", label: "The Kosamba Mercentile Co Op Bank Ltd" },
  { value: "The Kosamba Purva Vibhag Co Op Credit Soc Ltd", label: "The Kosamba Purva Vibhag Co Op Credit Soc Ltd" },
  { value: "The Kota Central Co-Operative Bank Ltd.", label: "The Kota Central Co-Operative Bank Ltd." },
  { value: "The Kota Co-Operative Agricultural Bank Ltd - Kundapur", label: "The Kota Co-Operative Agricultural Bank Ltd - Kundapur" },
  { value: "The Kotachery Service Co-Op. Bank Ltd", label: "The Kotachery Service Co-Op. Bank Ltd" },
  { value: "The Kotachery Services Co.Op. Bank Ltd", label: "The Kotachery Services Co.Op. Bank Ltd" },
  { value: "The Kotagiti Co-Op. Urban Bank Ltd.,", label: "The Kotagiti Co-Op. Urban Bank Ltd.," },
  { value: "The Kothakurssi Service Co-Op Bank Ltd.", label: "The Kothakurssi Service Co-Op Bank Ltd." },
  { value: "The Kothamangalam Service Co-Op Bank Ltd", label: "The Kothamangalam Service Co-Op Bank Ltd" },
  { value: "The Kothavara Services Co-Op Bank Ltd", label: "The Kothavara Services Co-Op Bank Ltd" },
  { value: "The Kottakkal Co-Op. Urban Bank Ltd.", label: "The Kottakkal Co-Op. Urban Bank Ltd." },
  { value: "The Kottanad Service Co-Op Bank Ltd", label: "The Kottanad Service Co-Op Bank Ltd" },
  { value: "The Kottappady Service Co-Op. Bank Ltd.", label: "The Kottappady Service Co-Op. Bank Ltd." },
  { value: "The Kottappally Service Co-Op Bank Ltd,", label: "The Kottappally Service Co-Op Bank Ltd," },
  { value: "The Kottayam Co-Operative Agricultural And Rural Devleopment Bank Ltd", label: "The Kottayam Co-Operative Agricultural And Rural Devleopment Bank Ltd" },
  { value: "The Kottayam Co-Operative Urban Bank Ltd", label: "The Kottayam Co-Operative Urban Bank Ltd" },
  { value: "The Kottayam District Co-Op.Bank Ltd.", label: "The Kottayam District Co-Op.Bank Ltd." },
  { value: "The Kottayam Services Cooperative Bank", label: "The Kottayam Services Cooperative Bank" },
  { value: "The Koujalgi Ur Co-Op Bank Ltd,", label: "The Koujalgi Ur Co-Op Bank Ltd," },
  { value: "The Kovur Primary Agri. Co-Op. Bank Ltd", label: "The Kovur Primary Agri. Co-Op. Bank Ltd" },
  { value: "The Kovvur Co- Op.Urban Bank Ltd", label: "The Kovvur Co- Op.Urban Bank Ltd" },
  { value: "The Koylanchal Urban Co Op Bank Ltd.", label: "The Koylanchal Urban Co Op Bank Ltd." },
  { value: "The Koylanchal Urban Co-Op. Bank Ltd.", label: "The Koylanchal Urban Co-Op. Bank Ltd." },
  { value: "The Koyyode Service Co-Operative Bk Ltd", label: "The Koyyode Service Co-Operative Bk Ltd" },
  { value: "The Kozhikode Dist. Co.Op. Bank Ltd", label: "The Kozhikode Dist. Co.Op. Bank Ltd" },
  { value: "The Kozhikode District Co-Op. Bank Ltd.", label: "The Kozhikode District Co-Op. Bank Ltd." },
  { value: "The Kranthi Co Op Urban Bank Ltd.", label: "The Kranthi Co Op Urban Bank Ltd." },
  { value: "The Kranthi Co-Op Urban Bank Ltd", label: "The Kranthi Co-Op Urban Bank Ltd" },
  { value: "The Krishna Dist. Co-Op. Central Bk Ltd", label: "The Krishna Dist. Co-Op. Central Bk Ltd" },
  { value: "The Krishna Dist. Co-Op.Central Bank Ltd", label: "The Krishna Dist. Co-Op.Central Bank Ltd" },
  { value: "The Krishnagar City Co-Op. Bank Ltd.", label: "The Krishnagar City Co-Op. Bank Ltd." },
  { value: "The Krishnagiri Urban Co-Op Bank Ltd.", label: "The Krishnagiri Urban Co-Op Bank Ltd." },
  { value: "The Krishnarajapet Tal Agr Co-Op Mkt Soc", label: "The Krishnarajapet Tal Agr Co-Op Mkt Soc" },
  { value: "The Kshetrapal Fin Pvt Ltd", label: "The Kshetrapal Fin Pvt Ltd" },
  { value: "The Kudamaloor Service Co.Op. Bank Ltd", label: "The Kudamaloor Service Co.Op. Bank Ltd" },
  { value: "The Kudol Arbuda Bachat And Dhiran Sahkari Mandali Limited.", label: "The Kudol Arbuda Bachat And Dhiran Sahkari Mandali Limited." },
  { value: "The Kukarwada Nagarik Sahakari Bank Ltd.", label: "The Kukarwada Nagarik Sahakari Bank Ltd." },
  { value: "The Kukarwada Nagrik Sahakari Bank", label: "The Kukarwada Nagrik Sahakari Bank" },
  { value: "The Kulasekhara Mangalam Ser Co Op Bank Ltd", label: "The Kulasekhara Mangalam Ser Co Op Bank Ltd" },
  { value: "The Kulasekharamangalam Ser Co-Op Bank L", label: "The Kulasekharamangalam Ser Co-Op Bank L" },
  { value: "The Kulikkiliyad Service Co-Op Bank Ltd", label: "The Kulikkiliyad Service Co-Op Bank Ltd" },
  { value: "The Kumarakom Regional Serv Co-Op Bk Ltd", label: "The Kumarakom Regional Serv Co-Op Bk Ltd" },
  { value: "The Kumaramangalam Service Co-Op Bank", label: "The Kumaramangalam Service Co-Op Bank" },
  { value: "The Kumaramputhur Co-Op. Housing Soc Ltd", label: "The Kumaramputhur Co-Op. Housing Soc Ltd" },
  { value: "The Kumaranellur Co-Op. Service Bank Ltd", label: "The Kumaranellur Co-Op. Service Bank Ltd" },
  { value: "The Kumaranputhur Service Co-Op.Bank Ltd", label: "The Kumaranputhur Service Co-Op.Bank Ltd" },
  { value: "The Kumbakonam Central Co-Op. Bank Ltd", label: "The Kumbakonam Central Co-Op. Bank Ltd" },
  { value: "The Kumbakonam Central Co-Op. Bank Ltd.", label: "The Kumbakonam Central Co-Op. Bank Ltd." },
  { value: "The Kumbazha Service Co-Op. Bank Ltd.", label: "The Kumbazha Service Co-Op. Bank Ltd." },
  { value: "The Kumbla Service Co-Op.Bank Ltd.", label: "The Kumbla Service Co-Op.Bank Ltd." },
  { value: "The Kumta Urban Co-Op.Bank Ltd.", label: "The Kumta Urban Co-Op.Bank Ltd." },
  { value: "The Kumuthi Co-Op Urban Bank Ltd.,", label: "The Kumuthi Co-Op Urban Bank Ltd.," },
  { value: "The Kunbi Sahakari Bank Ltd", label: "The Kunbi Sahakari Bank Ltd" },
  { value: "The Kundapura Taluk Agri Produce Co Mktg", label: "The Kundapura Taluk Agri Produce Co Mktg" },
  { value: "The Kunhimangalam Service Co-Op Bank Ltd", label: "The Kunhimangalam Service Co-Op Bank Ltd" },
  { value: "The Kunnamkulam Co-Op Urban Bank Ltd.", label: "The Kunnamkulam Co-Op Urban Bank Ltd." },
  { value: "The Kunnirikka Service Co-Op.Bank Ltd.", label: "The Kunnirikka Service Co-Op.Bank Ltd." },
  { value: "The Kuppam Co-Op. Town Bank Ltd", label: "The Kuppam Co-Op. Town Bank Ltd" },
  { value: "The Kuriachira Service Cooperative Bankl", label: "The Kuriachira Service Cooperative Bankl" },
  { value: "The Kurkarwada Nagarik Sah. Bank Ltd.", label: "The Kurkarwada Nagarik Sah. Bank Ltd." },
  { value: "The Kurla Nagarik Sahakari Bank Ltd", label: "The Kurla Nagarik Sahakari Bank Ltd" },
  { value: "The Kurmanchal Nagar Sahkari Bank Ltd", label: "The Kurmanchal Nagar Sahkari Bank Ltd" },
  { value: "The Kurnool Urban Co-Op. Credit Bank Ltd", label: "The Kurnool Urban Co-Op. Credit Bank Ltd" },
  { value: "The Kurukshetra Central Co-Op. Bank Ltd.", label: "The Kurukshetra Central Co-Op. Bank Ltd." },
  { value: "The Kurukshetra Central Co. Bank Ltd", label: "The Kurukshetra Central Co. Bank Ltd" },
  { value: "The Kurukshetra Urban Co-Op. Bank Ltd.", label: "The Kurukshetra Urban Co-Op. Bank Ltd." },
  { value: "The Kurukshetra Urban Coop Bank", label: "The Kurukshetra Urban Coop Bank" },
  { value: "The Kurumathur Service Co-Op. Bank Ltd.", label: "The Kurumathur Service Co-Op. Bank Ltd." },
  { value: "The Kurumbathur Service C-Operative Bank", label: "The Kurumbathur Service C-Operative Bank" },
  { value: "The Kurund Urban Coop Bank Ltd", label: "The Kurund Urban Coop Bank Ltd" },
  { value: "The Kurund Urban Coop Bank Ltd", label: "The Kurund Urban Coop Bank Ltd" },
  { value: "The Kurundwad Urban Co-Op Bank Ltd", label: "The Kurundwad Urban Co-Op Bank Ltd" },
  { value: "The Kurundwad Urban Co-Op Bank Ltd.", label: "The Kurundwad Urban Co-Op Bank Ltd." },
  { value: "The Kurunshetty Urban Co Op Credit Society Ltd.", label: "The Kurunshetty Urban Co Op Credit Society Ltd." },
  { value: "The Kuruppathal Service Co-Op. Bank Ltd", label: "The Kuruppathal Service Co-Op. Bank Ltd" },
  { value: "The Kuruvambalam Ser. Co-Op. Bank Ltd.", label: "The Kuruvambalam Ser. Co-Op. Bank Ltd." },
  { value: "The Kutch District Central Co.Op Bankltd", label: "The Kutch District Central Co.Op Bankltd" },
  { value: "The Kutch Mecantile C-O B L", label: "The Kutch Mecantile C-O B L" },
  { value: "The Kutch Mercantile Co-Op.Bank Ltd.", label: "The Kutch Mercantile Co-Op.Bank Ltd." },
  { value: "The Kuthiari Co-Op Agri. Services Society", label: "The Kuthiari Co-Op Agri. Services Society" },
  { value: "The Kuthuparamba Co-Op. Rural Bank", label: "The Kuthuparamba Co-Op. Rural Bank" },
  { value: "The Kuthuparamba Coop Rural Bank.", label: "The Kuthuparamba Coop Rural Bank." },
  { value: "The Kuttamassery Ser Co-Op Bank Ltd", label: "The Kuttamassery Ser Co-Op Bank Ltd" },
  { value: "The Kuttiady Co-Op. Urban Bank Ltd.", label: "The Kuttiady Co-Op. Urban Bank Ltd." },
  { value: "The Kuttiattoor Panchayath Ser.Co Bk Ltd", label: "The Kuttiattoor Panchayath Ser.Co Bk Ltd" },
  { value: "The Kuttiattoor Panchayath Ser.Co.Bk Ltd", label: "The Kuttiattoor Panchayath Ser.Co.Bk Ltd" },
  { value: "The Kuttikkakam Service Co-Op. Bk.Ltd.", label: "The Kuttikkakam Service Co-Op. Bk.Ltd." },
  { value: "The Kuttikol Service Co-Op Bank Ltd.", label: "The Kuttikol Service Co-Op Bank Ltd." },
  { value: "The Kuttikol Service Co-Op. Bank Ltd.", label: "The Kuttikol Service Co-Op. Bank Ltd." },
  { value: "The Kuttippuram Service Co.Op. Bank Ltd", label: "The Kuttippuram Service Co.Op. Bank Ltd" },
  { value: "The Kuttipputam Service Co-Op Bank Ltd", label: "The Kuttipputam Service Co-Op Bank Ltd" },
  { value: "The Kuzhimanna Service Co-Op Bank Ltd.", label: "The Kuzhimanna Service Co-Op Bank Ltd." },
  { value: "The Kuzhuvilam Service Co-Op Bank Ltd,", label: "The Kuzhuvilam Service Co-Op Bank Ltd," },
  { value: "The L.I.C.Of India Staff Co-Op. Bank Ltd", label: "The L.I.C.Of India Staff Co-Op. Bank Ltd" },
  { value: "The Ladol Maltipurpose Co.Operative Society Ltd", label: "The Ladol Maltipurpose Co.Operative Society Ltd" },
  { value: "The Lagalgaon Merchant Coop Bank Ltd", label: "The Lagalgaon Merchant Coop Bank Ltd" },
  { value: "The Lakhvad Nagarik Sahakari Bank Ltd.", label: "The Lakhvad Nagarik Sahakari Bank Ltd." },
  { value: "The Lakhwad Nagrik Sahakari Bank Ltd", label: "The Lakhwad Nagrik Sahakari Bank Ltd" },
  { value: "The Lakshmi Vilas Bank Limited", label: "The Lakshmi Vilas Bank Limited" },
  { value: "The Laksmi Vilas Bank Limited", label: "The Laksmi Vilas Bank Limited" },
  { value: "The Lalgudi Co-Op. Urban Bank Ltd", label: "The Lalgudi Co-Op. Urban Bank Ltd" },
  { value: "The Lambi Primary Co-Op Agridevlopment Bank Ltd.", label: "The Lambi Primary Co-Op Agridevlopment Bank Ltd." },
  { value: "The Lambra Kangri Co Op M/P Ser Soc Ltd", label: "The Lambra Kangri Co Op M/P Ser Soc Ltd" },
  { value: "The Lasalgaon Merchant Co Op Bank Ltd", label: "The Lasalgaon Merchant Co Op Bank Ltd" },
  { value: "The Lasalgaon Merchants Co-Op. Bank Ltd.", label: "The Lasalgaon Merchants Co-Op. Bank Ltd." },
  { value: "The Latur District Central Co-Op Bk Ltd.", label: "The Latur District Central Co-Op Bk Ltd." },
  { value: "The Latur Urban Co Op Bank Ltd", label: "The Latur Urban Co Op Bank Ltd" },
  { value: "The Latur Urban Co-Op. Bank Ltd", label: "The Latur Urban Co-Op. Bank Ltd" },
  { value: "The Laxmi Co Op Bank Ltd Solapur", label: "The Laxmi Co Op Bank Ltd Solapur" },
  { value: "The Laxmi Co Op Bank Ltd. Solapur", label: "The Laxmi Co Op Bank Ltd. Solapur" },
  { value: "The Laxmi Co Operative Bank Ltd.", label: "The Laxmi Co Operative Bank Ltd." },
  { value: "The Laxmi Sahakari Co-Op Bank", label: "The Laxmi Sahakari Co-Op Bank" },
  { value: "The Laxmipura Gssm Ltd", label: "The Laxmipura Gssm Ltd" },
  { value: "The Laxmipura Kampa Group Sevasahakari Mandal Ltd", label: "The Laxmipura Kampa Group Sevasahakari Mandal Ltd" },
  { value: "The Libaral Co Op Bank Ltd", label: "The Libaral Co Op Bank Ltd" },
  { value: "The Liberal Co-Op. Bank Ltd.", label: "The Liberal Co-Op. Bank Ltd." },
  { value: "The Limbasi Urban Co-Operative Bank Ltd", label: "The Limbasi Urban Co-Operative Bank Ltd" },
  { value: "The Limdi Urban Co-Op Bank Ltd", label: "The Limdi Urban Co-Op Bank Ltd" },
  { value: "The Limdi Urban Co-Op. Bank Ltd.", label: "The Limdi Urban Co-Op. Bank Ltd." },
  { value: "The Little Conjeeveram Co Urban Bk L", label: "The Little Conjeeveram Co Urban Bk L" },
  { value: "The Little Kancheepuram Co-Op. Urban B.L", label: "The Little Kancheepuram Co-Op. Urban B.L" },
  { value: "The Lokamaleswaram Service Co-Op Bank L.", label: "The Lokamaleswaram Service Co-Op Bank L." },
  { value: "The Lokamaleswaram Service Co-Op Bank Lt", label: "The Lokamaleswaram Service Co-Op Bank Lt" },
  { value: "The Lonar Urban Co-Op Credit Co Ltd", label: "The Lonar Urban Co-Op Credit Co Ltd" },
  { value: "The Ludhiana Central Co-Op Bank Ltd.", label: "The Ludhiana Central Co-Op Bank Ltd." },
  { value: "The Lunawada Nagarik Sahakari Bank Ltd.", label: "The Lunawada Nagarik Sahakari Bank Ltd." },
  { value: "The Lunawada Nagrik Sahkari Bank Ltd", label: "The Lunawada Nagrik Sahkari Bank Ltd" },
  { value: "The Lunawada Peoples Co-Op Bank Ltd.", label: "The Lunawada Peoples Co-Op Bank Ltd." },
  { value: "The M.Wedha Urban Bank Branch", label: "The M.Wedha Urban Bank Branch" },
  { value: "The Madai Co-Op. Rural Bank Ltd", label: "The Madai Co-Op. Rural Bank Ltd" },
  { value: "The Madakkimala Service Co-Op. Bank Ltd.", label: "The Madakkimala Service Co-Op. Bank Ltd." },
  { value: "The Madanapalle Co-Op.Town Bank Ltd.", label: "The Madanapalle Co-Op.Town Bank Ltd." },
  { value: "The Madar Co-Op Agri M/P Ser. Soc Ltd.", label: "The Madar Co-Op Agri M/P Ser. Soc Ltd." },
  { value: "The Madavoor Ser. Co-Op. Bank Ltd.", label: "The Madavoor Ser. Co-Op. Bank Ltd." },
  { value: "The Madeena Co-Op Credit Soc Ltd", label: "The Madeena Co-Op Credit Soc Ltd" },
  { value: "The Madeena Co-Op Credit Society Limited", label: "The Madeena Co-Op Credit Society Limited" },
  { value: "The Madgaon Urban Co Op Bank", label: "The Madgaon Urban Co Op Bank" },
  { value: "The Madgaum Urban Co-Op Bank Ltd.", label: "The Madgaum Urban Co-Op Bank Ltd." },
  { value: "The Madhavpura Mercantile Co-Op Bank Ltd", label: "The Madhavpura Mercantile Co-Op Bank Ltd" },
  { value: "The Madhavpura Mercantile Co-Op. B.L.", label: "The Madhavpura Mercantile Co-Op. B.L." },
  { value: "The Madikai Service Co- Op Bank Ltd", label: "The Madikai Service Co- Op Bank Ltd" },
  { value: "The Madivaleshwar Pkps Bank Ltd", label: "The Madivaleshwar Pkps Bank Ltd" },
  { value: "The Madura Sourashtra Co-Op Bank Ltd.", label: "The Madura Sourashtra Co-Op Bank Ltd." },
  { value: "The Madurai District Central Co-Op.Bk Lt", label: "The Madurai District Central Co-Op.Bk Lt" },
  { value: "The Madurai Urban Co-Op.Bank Ltd.", label: "The Madurai Urban Co-Op.Bank Ltd." },
  { value: "The Madurantakam C.O.U.B.Ltd", label: "The Madurantakam C.O.U.B.Ltd" },
  { value: "The Madurantakam Co-Op. Urban Bank Ltd", label: "The Madurantakam Co-Op. Urban Bank Ltd" },
  { value: "The Magadh Cet. Co-Op. Bk.L.,", label: "The Magadh Cet. Co-Op. Bk.L.," },
  { value: "The Mahabaleshwar Urban Co-Op Bank Ltd.", label: "The Mahabaleshwar Urban Co-Op Bank Ltd." },
  { value: "The Mahabaleshwar Urban Coop Bank L", label: "The Mahabaleshwar Urban Coop Bank L" },
  { value: "The Mahad Co-Operative Urban Bank Ltd", label: "The Mahad Co-Operative Urban Bank Ltd" },
  { value: "The Mahad Co-Operative Urban Bank Ltd.", label: "The Mahad Co-Operative Urban Bank Ltd." },
  { value: "The Mahad Coop Urban Bank Ltd", label: "The Mahad Coop Urban Bank Ltd" },
  { value: "The Mahalingpur Urban Coop Bank Ltd.", label: "The Mahalingpur Urban Coop Bank Ltd." },
  { value: "The Mahanagar Co-Op Urban Bank Ltd.", label: "The Mahanagar Co-Op Urban Bank Ltd." },
  { value: "The Mahanagar Coop Bank Ltd", label: "The Mahanagar Coop Bank Ltd" },
  { value: "The Mahar.Mantralay & Allied Off.C.P.B.L", label: "The Mahar.Mantralay & Allied Off.C.P.B.L" },
  { value: "The Maharashtra Co Opcredit Soc", label: "The Maharashtra Co Opcredit Soc" },
  { value: "The Maharashtra State Co-Op Bank Ltd.", label: "The Maharashtra State Co-Op Bank Ltd." },
  { value: "The Mahaveer Co-Op. Credit Bank Ltd", label: "The Mahaveer Co-Op. Credit Bank Ltd" },
  { value: "The Mahaveer Co. Op. Bank Ltd", label: "The Mahaveer Co. Op. Bank Ltd" },
  { value: "The Mahe Service Co. Bank Ltd", label: "The Mahe Service Co. Bank Ltd" },
  { value: "The Mahemadavad Urban People Co Bank Ltd", label: "The Mahemadavad Urban People Co Bank Ltd" },
  { value: "The Mahendergarh Cent. Co-O. Bank Ltd", label: "The Mahendergarh Cent. Co-O. Bank Ltd" },
  { value: "The Mahendragarh Central Co-Op.Bank Ltd.", label: "The Mahendragarh Central Co-Op.Bank Ltd." },
  { value: "The Mahesana Urban Co Op Bank Ltd", label: "The Mahesana Urban Co Op Bank Ltd" },
  { value: "The Mahila Urban Co-Op Bank Ltd.", label: "The Mahila Urban Co-Op Bank Ltd." },
  { value: "The Mahila Vikas Co Op Bank Ltd", label: "The Mahila Vikas Co Op Bank Ltd" },
  { value: "The Mahraraja Co-Op Urban Bank Ltd,", label: "The Mahraraja Co-Op Urban Bank Ltd," },
  { value: "The Mahudha Nagarik Sahakari Bank Ltd.", label: "The Mahudha Nagarik Sahakari Bank Ltd." },
  { value: "The Makarpura Industrial Estate Cp.Bk Lt", label: "The Makarpura Industrial Estate Cp.Bk Lt" },
  { value: "The Malabar Co-Op. Central Bank Ltd.", label: "The Malabar Co-Op. Central Bank Ltd." },
  { value: "The Malad Sahakari Bank Ltd.", label: "The Malad Sahakari Bank Ltd." },
  { value: "The Malad Sahakari Bank Ltd., Mumbai", label: "The Malad Sahakari Bank Ltd., Mumbai" },
  { value: "The Malanad Ser.Co-Op.Bk.L.", label: "The Malanad Ser.Co-Op.Bk.L." },
  { value: "The Malappattam Service Coop Bank Ltd.,", label: "The Malappattam Service Coop Bank Ltd.," },
  { value: "The Malappuram District Co-Op.Bank Ltd.", label: "The Malappuram District Co-Op.Bank Ltd." },
  { value: "The Malapur Co-Op Bank Ltd", label: "The Malapur Co-Op Bank Ltd" },
  { value: "The Malapur Urban Co-Operative Bank Limited.", label: "The Malapur Urban Co-Operative Bank Limited." },
  { value: "The Malayattoor - Neeleswaram Ser.C.B.L.", label: "The Malayattoor - Neeleswaram Ser.C.B.L." },
  { value: "The Malayidamthuruth Service Cp Bank Ltd", label: "The Malayidamthuruth Service Cp Bank Ltd" },
  { value: "The Malayinkil Serv. Co-Op Bank Ltd", label: "The Malayinkil Serv. Co-Op Bank Ltd" },
  { value: "The Malda Dist. Central Co-Op Bank Ltd.", label: "The Malda Dist. Central Co-Op Bank Ltd." },
  { value: "The Malegaon Merchant Co Op Bank Ltd. Malegaon", label: "The Malegaon Merchant Co Op Bank Ltd. Malegaon" },
  { value: "The Malegaon Merchants Co-Op Bank Ltd", label: "The Malegaon Merchants Co-Op Bank Ltd" },
  { value: "The Malkapur Urban Co-Op Bank Ltd.", label: "The Malkapur Urban Co-Op Bank Ltd." },
  { value: "The Malleswaram Co-Operative Bank Ltd", label: "The Malleswaram Co-Operative Bank Ltd" },
  { value: "The Maloth Service Co-Op. Bank Ltd", label: "The Maloth Service Co-Op. Bank Ltd" },
  { value: "The Malpur Nagarik Sahakari Bank Ltd", label: "The Malpur Nagarik Sahakari Bank Ltd" },
  { value: "The Malpur Nagrik Sahkari Bank", label: "The Malpur Nagrik Sahkari Bank" },
  { value: "The Manager V.G.B.", label: "The Manager V.G.B." },
  { value: "The Manakad Service Co-Op Bank Ltd,", label: "The Manakad Service Co-Op Bank Ltd," },
  { value: "The Mananje Vyasaya Seva Sahakari Bank", label: "The Mananje Vyasaya Seva Sahakari Bank" },
  { value: "The Manantoddy Farmers Serv.Coop Bank L", label: "The Manantoddy Farmers Serv.Coop Bank L" },
  { value: "The Manathana Service Co-Op Bank Ltd.", label: "The Manathana Service Co-Op Bank Ltd." },
  { value: "The Mandal Nagarik Sahakari Bank Ltd.", label: "The Mandal Nagarik Sahakari Bank Ltd." },
  { value: "The Mandal Nagrik Sahakari  Bank Ltd", label: "The Mandal Nagrik Sahakari  Bank Ltd" },
  { value: "The Mandapeta Co-Op Town Bank Ltd", label: "The Mandapeta Co-Op Town Bank Ltd" },
  { value: "The Mandsaur Commercial Co.Op. Bk Ltd", label: "The Mandsaur Commercial Co.Op. Bk Ltd" },
  { value: "The Mandvi Mercantile Co-Op. Bank Ltd.", label: "The Mandvi Mercantile Co-Op. Bank Ltd." },
  { value: "The Mandvi Nagrik Sahakari Bank Ltd", label: "The Mandvi Nagrik Sahakari Bank Ltd" },
  { value: "The Mandvi Nagrik Sahkari Bank Ltd.", label: "The Mandvi Nagrik Sahkari Bank Ltd." },
  { value: "The Mandya City Co-Op. Bank Ltd.", label: "The Mandya City Co-Op. Bank Ltd." },
  { value: "The Mandya Dist Co-Op. Central Bank Ltd.", label: "The Mandya Dist Co-Op. Central Bank Ltd." },
  { value: "The Mandya District Co-Op Central Bank L", label: "The Mandya District Co-Op Central Bank L" },
  { value: "The Manekchowk Co-Operative Bank Ltd.", label: "The Manekchowk Co-Operative Bank Ltd." },
  { value: "The Mangad Service Co-Operative Bank Ltd", label: "The Mangad Service Co-Operative Bank Ltd" },
  { value: "The Mangalam Service Co-Op Bank Ltd.", label: "The Mangalam Service Co-Op Bank Ltd." },
  { value: "The Mangaldai Nagar Samabai Bank Ltd.", label: "The Mangaldai Nagar Samabai Bank Ltd." },
  { value: "The Mangalm Services Coop Bank Ltd.", label: "The Mangalm Services Coop Bank Ltd." },
  { value: "The Mangalore Catholic Co Op Bank Ltd", label: "The Mangalore Catholic Co Op Bank Ltd" },
  { value: "The Mangalore Catholic Co-Op Bank Ltd - Karkala", label: "The Mangalore Catholic Co-Op Bank Ltd - Karkala" },
  { value: "The Mangalore Catholic Co-Op. Bank Ltd", label: "The Mangalore Catholic Co-Op. Bank Ltd" },
  { value: "The Mangalore Co-Op Town Bank Ltd., Mangalore", label: "The Mangalore Co-Op Town Bank Ltd., Mangalore" },
  { value: "The Mangalore Co-Operative Town Bank Ltd", label: "The Mangalore Co-Operative Town Bank Ltd" },
  { value: "The Mangalpady Service Co-Op Bank Ltd.", label: "The Mangalpady Service Co-Op Bank Ltd." },
  { value: "The Mangalwedha Urban Bank", label: "The Mangalwedha Urban Bank" },
  { value: "The Mangalwedha Urban Co-Op Bank Ltd", label: "The Mangalwedha Urban Co-Op Bank Ltd" },
  { value: "The Mangalwedha Urban Co-Op.Bank Ltd Mangalwedha", label: "The Mangalwedha Urban Co-Op.Bank Ltd Mangalwedha" },
  { value: "The Mangalwedha Urban Co-Op.Bank Ltd.", label: "The Mangalwedha Urban Co-Op.Bank Ltd." },
  { value: "The Manganam Service Co-Op. Bank Ltd", label: "The Manganam Service Co-Op. Bank Ltd" },
  { value: "The Mangarasuvalayapalayam Primary Agricultural Co Op Credit Society", label: "The Mangarasuvalayapalayam Primary Agricultural Co Op Credit Society" },
  { value: "The Mangode Service Co-Op. Bank", label: "The Mangode Service Co-Op. Bank" },
  { value: "The Manhalur Service Co-Op. Bank Ltd.", label: "The Manhalur Service Co-Op. Bank Ltd." },
  { value: "The Manikanta Co Op Urban Bank Ltd", label: "The Manikanta Co Op Urban Bank Ltd" },
  { value: "The Manipur State Co-Op Bank Ltd", label: "The Manipur State Co-Op Bank Ltd" },
  { value: "The Maniyat Service Co-Op. Bank Ltd", label: "The Maniyat Service Co-Op. Bank Ltd" },
  { value: "The Manjapra Serivce Co-Op. Bank Ltd", label: "The Manjapra Serivce Co-Op. Bank Ltd" },
  { value: "The Manjeri Co-Op. Urban Bank Ltd", label: "The Manjeri Co-Op. Urban Bank Ltd" },
  { value: "The Manjeshwar Service Co-Op Bank Ltd", label: "The Manjeshwar Service Co-Op Bank Ltd" },
  { value: "The Manjoor Service Co-Op Bank Ltd,", label: "The Manjoor Service Co-Op Bank Ltd," },
  { value: "The Mankada Service Co-Op Bank Ltd.", label: "The Mankada Service Co-Op Bank Ltd." },
  { value: "The Mankadapalliprom Serv.Co-Op Bank Ltd", label: "The Mankadapalliprom Serv.Co-Op Bank Ltd" },
  { value: "The Manmandir Co-Op. Bank Ltd.", label: "The Manmandir Co-Op. Bank Ltd." },
  { value: "The Mannanam Service Co-Op Bank Ltd", label: "The Mannanam Service Co-Op Bank Ltd" },
  { value: "The Mannargudi Co.Op.Urban Bank Limited", label: "The Mannargudi Co.Op.Urban Bank Limited" },
  { value: "The Mannarkkad Co Operative Agricultural And Rural Development Bank", label: "The Mannarkkad Co Operative Agricultural And Rural Development Bank" },
  { value: "The Mannarkkad Rural Ser. C.P. Bk Ltd", label: "The Mannarkkad Rural Ser. C.P. Bk Ltd" },
  { value: "The Mannur Service Co-Op.Rural Bank Ltd.", label: "The Mannur Service Co-Op.Rural Bank Ltd." },
  { value: "The Mansa Central Co-Op Bank Ltd,", label: "The Mansa Central Co-Op Bank Ltd," },
  { value: "The Mansa Central Coop Bk Ltd", label: "The Mansa Central Coop Bk Ltd" },
  { value: "The Mansa Nagrik Sahakari Bank", label: "The Mansa Nagrik Sahakari Bank" },
  { value: "The Mansa Nagrik Sahakari Bank Ltd", label: "The Mansa Nagrik Sahakari Bank Ltd" },
  { value: "The Mantha Urban Co.Op. Bank Ltd.", label: "The Mantha Urban Co.Op. Bank Ltd." },
  { value: "The Mantharathur Co-Op Rural Bank Ltd", label: "The Mantharathur Co-Op Rural Bank Ltd" },
  { value: "The Mapusa Merchants Urban Co Op Credit Society Ltd", label: "The Mapusa Merchants Urban Co Op Credit Society Ltd" },
  { value: "The Mapusa Urban Co.Op. Bank Ltd", label: "The Mapusa Urban Co.Op. Bank Ltd" },
  { value: "The Mapusa Urban Co.Op.Bk Ltd", label: "The Mapusa Urban Co.Op.Bk Ltd" },
  { value: "The Mapusa Urban Coop Bank Ltd", label: "The Mapusa Urban Coop Bank Ltd" },
  { value: "The Mapusa Urban Coop Bank Of Goa Ltd", label: "The Mapusa Urban Coop Bank Of Goa Ltd" },
  { value: "The Marady Service Co-Op Bank Ltd.", label: "The Marady Service Co-Op Bank Ltd." },
  { value: "The Maranchery Service Co-Op. Bank Ltd.", label: "The Maranchery Service Co-Op. Bank Ltd." },
  { value: "The Maratha Co-Op. Bank Ltd.", label: "The Maratha Co-Op. Bank Ltd." },
  { value: "The Maratha Mandir Co-Op Bank Ltd.", label: "The Maratha Mandir Co-Op Bank Ltd." },
  { value: "The Maratha Mandir Co-Op Bank Ltd., Mumbai", label: "The Maratha Mandir Co-Op Bank Ltd., Mumbai" },
  { value: "The Maravanthuruthu Ser Co-Op Bank Ltd", label: "The Maravanthuruthu Ser Co-Op Bank Ltd" },
  { value: "The Maravanthuruthu Service Co-Op Bk Ltd", label: "The Maravanthuruthu Service Co-Op Bk Ltd" },
  { value: "The Marchant Souhardha Sahakara Bank Ltd", label: "The Marchant Souhardha Sahakara Bank Ltd" },
  { value: "The Marine Products Export Development Authority", label: "The Marine Products Export Development Authority" },
  { value: "The Maruthonkara Panchayat Ser.Co.Bk Ltd", label: "The Maruthonkara Panchayat Ser.Co.Bk Ltd" },
  { value: "The Mathil Service Co -Op Bank Ltd", label: "The Mathil Service Co -Op Bank Ltd" },
  { value: "The Mathra Service Co-Op Bank Ltd.", label: "The Mathra Service Co-Op Bank Ltd." },
  { value: "The Mattancherry Mahajanik Co-Op. Bk Ltd", label: "The Mattancherry Mahajanik Co-Op. Bk Ltd" },
  { value: "The Mattancherry Sarvajanik Co-Op. B.L.", label: "The Mattancherry Sarvajanik Co-Op. B.L." },
  { value: "The Mattanchery Sarvajenik Co-Operative Bank Ltd", label: "The Mattanchery Sarvajenik Co-Operative Bank Ltd" },
  { value: "The Mattanur Co-Op Urban Bank Ltd.", label: "The Mattanur Co-Op Urban Bank Ltd." },
  { value: "The Mattanur Co-Operative Rural Bank Ltd", label: "The Mattanur Co-Operative Rural Bank Ltd" },
  { value: "The Mattom Service Co-Op Bank Ltd", label: "The Mattom Service Co-Op Bank Ltd" },
  { value: "The Mattul Service Co-Op.Bk.L.,", label: "The Mattul Service Co-Op.Bk.L.," },
  { value: "The Mavilayi Service Co-Operative Bank L", label: "The Mavilayi Service Co-Operative Bank L" },
  { value: "The Mavoor Ser. Co-Op. Bank Ltd.,", label: "The Mavoor Ser. Co-Op. Bank Ltd.," },
  { value: "The Mayani Urban Co-Op Bank Ltd.", label: "The Mayani Urban Co-Op Bank Ltd." },
  { value: "The Mayuram Co-Op. Urban Bank Ltd.", label: "The Mayuram Co-Op. Urban Bank Ltd." },
  { value: "The Mayurbhanj Cen. Coop. Bank Ltd.", label: "The Mayurbhanj Cen. Coop. Bank Ltd." },
  { value: "The Mayurbhanj Central Co-Operative Bank", label: "The Mayurbhanj Central Co-Operative Bank" },
  { value: "The Mayyanad Services Coop Bank Ltd.", label: "The Mayyanad Services Coop Bank Ltd." },
  { value: "The Mayyil Co Operative Bank", label: "The Mayyil Co Operative Bank" },
  { value: "The Mayyil Service Co-Op. Bank Ltd.", label: "The Mayyil Service Co-Op. Bank Ltd." },
  { value: "The Mcrb & Ms", label: "The Mcrb & Ms" },
  { value: "The Meenachil East Co-Operative Bank Ltd", label: "The Meenachil East Co-Operative Bank Ltd" },
  { value: "The Meenachil East Urban Co-Op.Bk.L.", label: "The Meenachil East Urban Co-Op.Bk.L." },
  { value: "The Meenachil Taluk Co. Emp. Co. Soc Ltd", label: "The Meenachil Taluk Co. Emp. Co. Soc Ltd" },
  { value: "The Meenangadi Service Co-Op. Bank Ltd", label: "The Meenangadi Service Co-Op. Bank Ltd" },
  { value: "The Megaacity Co-Op Urban Bank Ltd.", label: "The Megaacity Co-Op Urban Bank Ltd." },
  { value: "The Meghalaya Co-Op Apex Bank Ltd.", label: "The Meghalaya Co-Op Apex Bank Ltd." },
  { value: "The Meghalaya Co-Operative Apex Bank Ltd.", label: "The Meghalaya Co-Operative Apex Bank Ltd." },
  { value: "The Meghalaya Industrial Development Corporation", label: "The Meghalaya Industrial Development Corporation" },
  { value: "The Meghraj Nagarik Sahakari Bank Ltd.", label: "The Meghraj Nagarik Sahakari Bank Ltd." },
  { value: "The Meghraj Nagrik Sahakari Bank Ltd", label: "The Meghraj Nagrik Sahakari Bank Ltd" },
  { value: "The Mehasana Nagrik Co Op Bank Ltd.", label: "The Mehasana Nagrik Co Op Bank Ltd." },
  { value: "The Mehkar Urban Co-Op Bank Ltd.", label: "The Mehkar Urban Co-Op Bank Ltd." },
  { value: "The Mehkar Urban Co-Op. Bank Ltd.", label: "The Mehkar Urban Co-Op. Bank Ltd." },
  { value: "The Mehkar Urban Co-Op.Credit.Soc.Ltd", label: "The Mehkar Urban Co-Op.Credit.Soc.Ltd" },
  { value: "The Mehlav Peoples Co Op Credit Soc Ltd", label: "The Mehlav Peoples Co Op Credit Soc Ltd" },
  { value: "The Mehlav Peoples Co-Op Cradit Society Ltd", label: "The Mehlav Peoples Co-Op Cradit Society Ltd" },
  { value: "The Mehmadabad Urban Peoples Cp.Bk. Ltd.", label: "The Mehmadabad Urban Peoples Cp.Bk. Ltd." },
  { value: "The Mehsana Dist. Cent. Coop Bank", label: "The Mehsana Dist. Cent. Coop Bank" },
  { value: "The Mehsana District Central Co-Op.Bk Lt", label: "The Mehsana District Central Co-Op.Bk Lt" },
  { value: "The Mehsana District Central Co.Bank Ltd", label: "The Mehsana District Central Co.Bank Ltd" },
  { value: "The Mehsana Jilla Panchayat Karmchari Co Op Bank Ltd", label: "The Mehsana Jilla Panchayat Karmchari Co Op Bank Ltd" },
  { value: "The Mehsana Mahila Sahakari Bank Ltd.", label: "The Mehsana Mahila Sahakari Bank Ltd." },
  { value: "The Mehsana Nagarik Sahakari Bank Ltd", label: "The Mehsana Nagarik Sahakari Bank Ltd" },
  { value: "The Mehsana Nagrik Sahakari Bank Ltd", label: "The Mehsana Nagrik Sahakari Bank Ltd" },
  { value: "The Mehsana Urban Co-Op Bank Ltd", label: "The Mehsana Urban Co-Op Bank Ltd" },
  { value: "The Mehsana Urban Co Op Bank Ltd.", label: "The Mehsana Urban Co Op Bank Ltd." },
  { value: "The Melampara Service Co-Op Bank Ltd", label: "The Melampara Service Co-Op Bank Ltd" },
  { value: "The Melattur Service Co-Op Bank Ltd.", label: "The Melattur Service Co-Op Bank Ltd." },
  { value: "The Melila Service Coop Bank Ltd.", label: "The Melila Service Coop Bank Ltd." },
  { value: "The Melukavu Service Co-Operative Bank Ltd", label: "The Melukavu Service Co-Operative Bank Ltd" },
  { value: "The Melukavu Services Cooperative Banklt", label: "The Melukavu Services Cooperative Banklt" },
  { value: "The Memon Co-Op Bank Ltd,Surat", label: "The Memon Co-Op Bank Ltd,Surat" },
  { value: "The Memon Co-Operative Bank Ltd.", label: "The Memon Co-Operative Bank Ltd." },
  { value: "The Meppayur Ser. Co-Op. Bank Ltd.", label: "The Meppayur Ser. Co-Op. Bank Ltd." },
  { value: "The Mercantile Co-Op. Bank Ltd.", label: "The Mercantile Co-Op. Bank Ltd." },
  { value: "The Merchant Liberal Co-Op Bank Ltd", label: "The Merchant Liberal Co-Op Bank Ltd" },
  { value: "The Merchant Liberut Co-Op Bank Ltd", label: "The Merchant Liberut Co-Op Bank Ltd" },
  { value: "The Merchant Souharda Sahakari Bank Ltd", label: "The Merchant Souharda Sahakari Bank Ltd" },
  { value: "The Merchant`S Souhardha Sah. Bank Ni.", label: "The Merchant`S Souhardha Sah. Bank Ni." },
  { value: "The Merchantile Co-Op Credit Society Ltd", label: "The Merchantile Co-Op Credit Society Ltd" },
  { value: "The Merchants Co Op Bank Ltd", label: "The Merchants Co Op Bank Ltd" },
  { value: "The Merchants Cooperative Bank Ltd", label: "The Merchants Cooperative Bank Ltd" },
  { value: "The Merchants Credit Co-Op Society Ltd", label: "The Merchants Credit Co-Op Society Ltd" },
  { value: "The Merchants Souhardha Sahakari B.Ltd", label: "The Merchants Souhardha Sahakari B.Ltd" },
  { value: "The Merchants Co-Operative Bank Ltd.", label: "The Merchants Co-Operative Bank Ltd." },
  { value: "The Merchants Urban Co-Op. Bank Ltd.", label: "The Merchants Urban Co-Op. Bank Ltd." },
  { value: "The Meshana Jilla Panch.Karmachari C.B.L", label: "The Meshana Jilla Panch.Karmachari C.B.L" },
  { value: "The Metro Co-Op. Bank Ltd., Surat", label: "The Metro Co-Op. Bank Ltd., Surat" },
  { value: "The Mettupalayam Co Op Urban Bank Ltd.", label: "The Mettupalayam Co Op Urban Bank Ltd." },
  { value: "The Mini Bank Chikhli Lamps", label: "The Mini Bank Chikhli Lamps" },
  { value: "The Miraj Urban Co.Op. Bank Ltd", label: "The Miraj Urban Co.Op. Bank Ltd" },
  { value: "The Mirzapur Urban Co-Op. Bank Ltd.", label: "The Mirzapur Urban Co-Op. Bank Ltd." },
  { value: "The Modasa Nagrik Sahakari Bank Ltd.", label: "The Modasa Nagrik Sahakari Bank Ltd." },
  { value: "The Modasa Road Transport Co Op Soc Ltd", label: "The Modasa Road Transport Co Op Soc Ltd" },
  { value: "The Modasa Roadtransport Co Op Society Ltd Modasa", label: "The Modasa Roadtransport Co Op Society Ltd Modasa" },
  { value: "The Model Co-Oprative Bank Ltd.", label: "The Model Co-Oprative Bank Ltd." },
  { value: "The Model Co-Oprative Urban Bank Ltd.", label: "The Model Co-Oprative Urban Bank Ltd." },
  { value: "The Modern Co-Op Bank Ltd", label: "The Modern Co-Op Bank Ltd" },
  { value: "The Moga Central Co-Op. Bank Ltd.", label: "The Moga Central Co-Op. Bank Ltd." },
  { value: "The Mogaveera Co-Operative Bank Ltd.", label: "The Mogaveera Co-Operative Bank Ltd." },
  { value: "The Mohanur Co-Operative Bank Ltd.", label: "The Mohanur Co-Operative Bank Ltd." },
  { value: "The Mokkam Service Co-Op Bank Ltd.", label: "The Mokkam Service Co-Op Bank Ltd." },
  { value: "The Mongam Co-Op Urban Bank Ltd", label: "The Mongam Co-Op Urban Bank Ltd" },
  { value: "The Mongam Co-Op. Urban Bank  (Ul) Ltd", label: "The Mongam Co-Op. Urban Bank  (Ul) Ltd" },
  { value: "The Mongher-Jnmui Central Co-Op Bank Ltd", label: "The Mongher-Jnmui Central Co-Op Bank Ltd" },
  { value: "The Moodadi Service Co-Op Bank Ltd.", label: "The Moodadi Service Co-Op Bank Ltd." },
  { value: "The Moodbidri Co-Op. Service Bank Ltd.", label: "The Moodbidri Co-Op. Service Bank Ltd." },
  { value: "The Moongilmada Service Co-Op Bank Ltd.", label: "The Moongilmada Service Co-Op Bank Ltd." },
  { value: "The Moonnilavu Ser Co-Op Bk Lt,", label: "The Moonnilavu Ser Co-Op Bk Lt," },
  { value: "The Morayur Service Co-Op Bank Ltd.", label: "The Morayur Service Co-Op Bank Ltd." },
  { value: "The Morazha-Kalliasseri Sc Bk. Lt.", label: "The Morazha-Kalliasseri Sc Bk. Lt." },
  { value: "The Moti Urban Co-Op Bank Ltd", label: "The Moti Urban Co-Op Bank Ltd" },
  { value: "The Motihari Central Co-Op. Bank Ltd.", label: "The Motihari Central Co-Op. Bank Ltd." },
  { value: "The Mouli Urban Co Op Cr Society Ltd", label: "The Mouli Urban Co Op Cr Society Ltd" },
  { value: "The Mowanchery C.Op. Rural Bank Ltd", label: "The Mowanchery C.Op. Rural Bank Ltd" },
  { value: "The Mowanchery Co-Op. Rural Bk Ltd.", label: "The Mowanchery Co-Op. Rural Bk Ltd." },
  { value: "The Mowanchery Co:Op Rural Bank Ltd", label: "The Mowanchery Co:Op Rural Bank Ltd" },
  { value: "The Mubarak Co Op Credit Soc.Ltd", label: "The Mubarak Co Op Credit Soc.Ltd" },
  { value: "The Mudai Co-Op. Rural Bank Ltd.", label: "The Mudai Co-Op. Rural Bank Ltd." },
  { value: "The Mudakuzha Service Co-Op Bank Ltd.", label: "The Mudakuzha Service Co-Op Bank Ltd." },
  { value: "The Mudalagi Co-Op Bank Ltd Mudalagi", label: "The Mudalagi Co-Op Bank Ltd Mudalagi" },
  { value: "The Mudalgi Co-Op. Bank Ltd", label: "The Mudalgi Co-Op. Bank Ltd" },
  { value: "The Mudalgi Co-Operative Bank Ltd.", label: "The Mudalgi Co-Operative Bank Ltd." },
  { value: "The Mudhol Co-Op Bank Ltd", label: "The Mudhol Co-Op Bank Ltd" },
  { value: "The Mudhol Urban Co-Op Bank Ltd.", label: "The Mudhol Urban Co-Op Bank Ltd." },
  { value: "The Mugberia Central Co-Op Bank Ltd.", label: "The Mugberia Central Co-Op Bank Ltd." },
  { value: "The Mugu Service Co-Operative Bk Ltd.,", label: "The Mugu Service Co-Operative Bk Ltd.," },
  { value: "The Mukkam Services Co-Op. Bank Ltd.", label: "The Mukkam Services Co-Op. Bank Ltd." },
  { value: "The Mukkuper Co Op Urban Bank Ltd", label: "The Mukkuper Co Op Urban Bank Ltd" },
  { value: "The Mukkuperi Co - Operative Bank Ltd", label: "The Mukkuperi Co - Operative Bank Ltd" },
  { value: "The Mukteswar Central Co-Op Bank", label: "The Mukteswar Central Co-Op Bank" },
  { value: "The Muktsar Central Co.Operative Bk. Ltd", label: "The Muktsar Central Co.Operative Bk. Ltd" },
  { value: "The Mulgund Urban Co-Op Bank Ltd.", label: "The Mulgund Urban Co-Op Bank Ltd." },
  { value: "The Mulgund Urban Southarda Co-Op Bk Ltd", label: "The Mulgund Urban Southarda Co-Op Bk Ltd" },
  { value: "The Mullakkodi Co-Op. Rural Bank Ltd", label: "The Mullakkodi Co-Op. Rural Bank Ltd" },
  { value: "The Mullankolly Service Co-Op. Bank Ltd.", label: "The Mullankolly Service Co-Op. Bank Ltd." },
  { value: "The Mumbai Dist. Central Co-Op Bk Ltd.", label: "The Mumbai Dist. Central Co-Op Bk Ltd." },
  { value: "The Munai Group Shakari Mandali Ltd", label: "The Munai Group Shakari Mandali Ltd" },
  { value: "The Munavalli Urban Co-Op Cr Sdc Ltd", label: "The Munavalli Urban Co-Op Cr Sdc Ltd" },
  { value: "The Mundela Serivice Co-Operative Bank Ltd", label: "The Mundela Serivice Co-Operative Bank Ltd" },
  { value: "The Mundiappally Ser. Co-Op. Bk.L", label: "The Mundiappally Ser. Co-Op. Bk.L" },
  { value: "The Mundur Service Co-Op. Bank Ltd.", label: "The Mundur Service Co-Op. Bank Ltd." },
  { value: "The Municipal Co Op Bank Ltd", label: "The Municipal Co Op Bank Ltd" },
  { value: "The Municipal Co Op Bank Ltd", label: "The Municipal Co Op Bank Ltd" },
  { value: "The Municipal Co-Op Bank Ltd., Mumbai", label: "The Municipal Co-Op Bank Ltd., Mumbai" },
  { value: "The Murickassery Service Co-Op Bk Ltd.", label: "The Murickassery Service Co-Op Bk Ltd." },
  { value: "The Murukkumpuzha Service Co-Op Bank Ltd", label: "The Murukkumpuzha Service Co-Op Bank Ltd" },
  { value: "The Muslim Co-Op Bank Ltd.", label: "The Muslim Co-Op Bank Ltd." },
  { value: "The Muslim Co-Operative Bank Ltd.", label: "The Muslim Co-Operative Bank Ltd." },
  { value: "The Muslim Mehdavia Co-Op Bank Ltd", label: "The Muslim Mehdavia Co-Op Bank Ltd" },
  { value: "The Muthalakodam Service Co-Op Bank Ltd", label: "The Muthalakodam Service Co-Op Bank Ltd" },
  { value: "The Muthalakodam Services Coop Bank Ltd", label: "The Muthalakodam Services Coop Bank Ltd" },
  { value: "The Muthalamada Service Co-Op Bank Ltd.", label: "The Muthalamada Service Co-Op Bank Ltd." },
  { value: "The Muthalapuram Service Co.Op Bank Ltd", label: "The Muthalapuram Service Co.Op Bank Ltd" },
  { value: "The Muthukulam Service Co-Op Bank Ltd", label: "The Muthukulam Service Co-Op Bank Ltd" },
  { value: "The Muttathody Service Co-Op Bank Ltd.", label: "The Muttathody Service Co-Op Bank Ltd." },
  { value: "The Muttom Co.Operative Bank Ltd", label: "The Muttom Co.Operative Bank Ltd" },
  { value: "The Muvattupuzha Agricultural Cp Bank Lt", label: "The Muvattupuzha Agricultural Cp Bank Lt" },
  { value: "The Muvattupuzha Urban Co Op Bank Ltd", label: "The Muvattupuzha Urban Co Op Bank Ltd" },
  { value: "The Muvattupuzha Urban Co-Op Bank", label: "The Muvattupuzha Urban Co-Op Bank" },
  { value: "The Muzaffarpur Central Co Op Bank Ltd", label: "The Muzaffarpur Central Co Op Bank Ltd" },
  { value: "The Muzhappilangad Service Co-Op Bank Lt", label: "The Muzhappilangad Service Co-Op Bank Lt" },
  { value: "The Mylapra Service Co-Op Bank Ltd.", label: "The Mylapra Service Co-Op Bank Ltd." },
  { value: "The Mynagappally Village Serv Cop Bank L", label: "The Mynagappally Village Serv Cop Bank L" },
  { value: "The Mysore & Chamarajanagar D.Co.Cet.B.L", label: "The Mysore & Chamarajanagar D.Co.Cet.B.L" },
  { value: "The Mysore Co-Operative Bank Ltd.", label: "The Mysore Co-Operative Bank Ltd." },
  { value: "The Mysore District Co-Op Central Bk Ltd", label: "The Mysore District Co-Op Central Bk Ltd" },
  { value: "The Mysore Merchants Co-Op. Bank Ltd", label: "The Mysore Merchants Co-Op. Bank Ltd" },
  { value: "The Mysore Silk Cloth Mercants Co-Op Bank Ltd.", label: "The Mysore Silk Cloth Mercants Co-Op Bank Ltd." },
  { value: "The Mysore Silk Cloth Merchants C.Bank L", label: "The Mysore Silk Cloth Merchants C.Bank L" },
  { value: "The N R D U S Bank Ltd", label: "The N R D U S Bank Ltd" },
  { value: "The N. A. Dt. Central Co-Op. Bank Ltd", label: "The N. A. Dt. Central Co-Op. Bank Ltd" },
  { value: "The N.Road Deolali Vyaparis Bank Ltd", label: "The N.Road Deolali Vyaparis Bank Ltd" },
  { value: "The Nabadwip Co-Op. Credit Bank Ltd.", label: "The Nabadwip Co-Op. Credit Bank Ltd." },
  { value: "The Nabapalli Co-Operative Bank Ltd.", label: "The Nabapalli Co-Operative Bank Ltd." },
  { value: "The Nadapuram Service Co-Op. Bank Ltd.", label: "The Nadapuram Service Co-Op. Bank Ltd." },
  { value: "The Nadiad Peoples Co-Op Bank Ltd", label: "The Nadiad Peoples Co-Op Bank Ltd" },
  { value: "The Naduvannur Regional Co-Op Bank Ltd.", label: "The Naduvannur Regional Co-Op Bank Ltd." },
  { value: "The Naduvil Service Co-Op. Bank Ltd", label: "The Naduvil Service Co-Op. Bank Ltd" },
  { value: "The Nagaland State Co-Op Bank Ltd.", label: "The Nagaland State Co-Op Bank Ltd." },
  { value: "The Nagalssery Ser. Co-Op Bank Ltd,", label: "The Nagalssery Ser. Co-Op Bank Ltd," },
  { value: "The Nagaur Central Co-Op. Bank Ltd", label: "The Nagaur Central Co-Op. Bank Ltd" },
  { value: "The Nagpur Dist. Central Co-Op. Bank Ltd", label: "The Nagpur Dist. Central Co-Op. Bank Ltd" },
  { value: "The Nagpur Mahanagarpalika Karmachari Sb", label: "The Nagpur Mahanagarpalika Karmachari Sb" },
  { value: "The Nagpur Mahila Nagari Sahakari Bk Ltd", label: "The Nagpur Mahila Nagari Sahakari Bk Ltd" },
  { value: "The Nagrota Bagwan Co-Op Bank Ltd", label: "The Nagrota Bagwan Co-Op Bank Ltd" },
  { value: "The Nainital Bank Ltd,", label: "The Nainital Bank Ltd," },
  { value: "The Nainital Bank Ltd.", label: "The Nainital Bank Ltd." },
  { value: "The Nainital Bank Ltd.", label: "The Nainital Bank Ltd." },
  { value: "The Nakodar Hindu Co-Op Bank Ltd", label: "The Nakodar Hindu Co-Op Bank Ltd" },
  { value: "The Nakodar Hindu Urban Coop Bank Ltd", label: "The Nakodar Hindu Urban Coop Bank Ltd" },
  { value: "The Nalgonda Dist. Co-Op Central Bk Ltd", label: "The Nalgonda Dist. Co-Op Central Bk Ltd" },
  { value: "The Nallepilly Ser Co-Op Bank Ltd,", label: "The Nallepilly Ser Co-Op Bank Ltd," },
  { value: "The Nallurnad Service Co-Op Bank Ltd", label: "The Nallurnad Service Co-Op Bank Ltd" },
  { value: "The Nalunnakkal Service Co-Op.Bank Ltd", label: "The Nalunnakkal Service Co-Op.Bank Ltd" },
  { value: "The Namakkal Co-Op. Urban Bank Ltd", label: "The Namakkal Co-Op. Urban Bank Ltd" },
  { value: "The Namaskar Sahakari Sharafi Mandali L.", label: "The Namaskar Sahakari Sharafi Mandali L." },
  { value: "The Namco Bank Ltd.", label: "The Namco Bank Ltd." },
  { value: "The Nanded Dist. Central Co-Op Bank Ltd.", label: "The Nanded Dist. Central Co-Op Bank Ltd." },
  { value: "The Nanded Marchant Co Op Bank Ltd", label: "The Nanded Marchant Co Op Bank Ltd" },
  { value: "The Nanded Merchants Co-Op. Bank Ltd", label: "The Nanded Merchants Co-Op. Bank Ltd" },
  { value: "The Nanded Merchants Co-Op.Bank Ltd.", label: "The Nanded Merchants Co-Op.Bank Ltd." },
  { value: "The Nandgaon Urban Co.-Op Bank Ltd.", label: "The Nandgaon Urban Co.-Op Bank Ltd." },
  { value: "The Nandipulam Service Co-Op Bank Ltd,", label: "The Nandipulam Service Co-Op Bank Ltd," },
  { value: "The Nandura Urban Co-Op. Bank Ltd.", label: "The Nandura Urban Co-Op. Bank Ltd." },
  { value: "The Nandurbar Merchants Co-Op. Bank Ltd", label: "The Nandurbar Merchants Co-Op. Bank Ltd" },
  { value: "The Napad Mercantile Co-Op Credit Society", label: "The Napad Mercantile Co-Op Credit Society" },
  { value: "The Napad Wanta Mercantile Co Op Bank Ltd", label: "The Napad Wanta Mercantile Co Op Bank Ltd" },
  { value: "The Narakal Service Co-Operative Bk,Ltd,", label: "The Narakal Service Co-Operative Bk,Ltd," },
  { value: "The Naranammoozhy Ser.Co-Op.Bank L.", label: "The Naranammoozhy Ser.Co-Op.Bank L." },
  { value: "The Narath Service Co-Op Bk Ltd.", label: "The Narath Service Co-Op Bk Ltd." },
  { value: "The Naroda Nagrik Co-Operative Bank Ltd.", label: "The Naroda Nagrik Co-Operative Bank Ltd." },
  { value: "The Nashik Dist. Co-Op Bank Ltd", label: "The Nashik Dist. Co-Op Bank Ltd" },
  { value: "The Nashik Jilha Mahila Sahkari Bank Ltd", label: "The Nashik Jilha Mahila Sahkari Bank Ltd" },
  { value: "The Nashik Merchants Co-Op Bank Ltd", label: "The Nashik Merchants Co-Op Bank Ltd" },
  { value: "The Nashik Municipal Co-Op Bank", label: "The Nashik Municipal Co-Op Bank" },
  { value: "The Nashik Peoples Co-Op Bk L", label: "The Nashik Peoples Co-Op Bk L" },
  { value: "The Nashik Road Deolali Vyapari Sahakari Bank", label: "The Nashik Road Deolali Vyapari Sahakari Bank" },
  { value: "The Nashik Road Deolali Vyapari Sahakari Bank Ltd", label: "The Nashik Road Deolali Vyapari Sahakari Bank Ltd" },
  { value: "The Nasik District Central Co-Op Bank Lt", label: "The Nasik District Central Co-Op Bank Lt" },
  { value: "The Nasik Jilha Mahila Sahakari Bank Ltd", label: "The Nasik Jilha Mahila Sahakari Bank Ltd" },
  { value: "The Nasik Merchant Coop Bank Br Vani", label: "The Nasik Merchant Coop Bank Br Vani" },
  { value: "The Nasik Merchants Co-Operative Bank Lt", label: "The Nasik Merchants Co-Operative Bank Lt" },
  { value: "The Nasik Rd Deo Vyp Sah Bank Ltd", label: "The Nasik Rd Deo Vyp Sah Bank Ltd" },
  { value: "The Nasik Road Deolali Vyapari Sah.Bk L.", label: "The Nasik Road Deolali Vyapari Sah.Bk L." },
  { value: "The National Bank", label: "The National Bank" },
  { value: "The National Co-Op Bank Ltd", label: "The National Co-Op Bank Ltd" },
  { value: "The National Co-Op Bank Ltd., Banglore", label: "The National Co-Op Bank Ltd., Banglore" },
  { value: "The National Co-Op. Bank Ltd., Mumbai", label: "The National Co-Op. Bank Ltd., Mumbai" },
  { value: "The National Commecial Bank", label: "The National Commecial Bank" },
  { value: "The Nattakom Service Co-Op. Bank Ltd.", label: "The Nattakom Service Co-Op. Bank Ltd." },
  { value: "The Nattika Firaka Coop Rural Bank Ltd.", label: "The Nattika Firaka Coop Rural Bank Ltd." },
  { value: "The Nattika Service Co.Op.Bank Ltd", label: "The Nattika Service Co.Op.Bank Ltd" },
  { value: "The Nav Jeevan Co-Op. Bank Ltd", label: "The Nav Jeevan Co-Op. Bank Ltd" },
  { value: "The Navakalyan Co-Operative Bank Ltd.", label: "The Navakalyan Co-Operative Bank Ltd." },
  { value: "The Naval Dockyard Co Op Bank Ltd", label: "The Naval Dockyard Co Op Bank Ltd" },
  { value: "The Navanagar Co Op Bank Ltd", label: "The Navanagar Co Op Bank Ltd" },
  { value: "The Navanirman Co-Op. Urban Bank Ltd.", label: "The Navanirman Co-Op. Urban Bank Ltd." },
  { value: "The Navapur Mercantile Co-Op Bank Ltd", label: "The Navapur Mercantile Co-Op Bank Ltd" },
  { value: "The Navhind Multistate Co-Operative Credit Society Ltd.", label: "The Navhind Multistate Co-Operative Credit Society Ltd." },
  { value: "The Navjeevan Co Op Bank Ltd", label: "The Navjeevan Co Op Bank Ltd" },
  { value: "The Navjivan Sahakari Sarafi Mandali Ltd", label: "The Navjivan Sahakari Sarafi Mandali Ltd" },
  { value: "The Navkar Sahkari Sarafi Mandali Ltd", label: "The Navkar Sahkari Sarafi Mandali Ltd" },
  { value: "The Navnirman Co Op H Soc Ltd.", label: "The Navnirman Co Op H Soc Ltd." },
  { value: "The Navnirman Co-Operative Bank Ltd.", label: "The Navnirman Co-Operative Bank Ltd." },
  { value: "The Navnirman Commercial Co Op Bank Ltd", label: "The Navnirman Commercial Co Op Bank Ltd" },
  { value: "The Navoday Sarafi Sahkari Mandali Ltd.", label: "The Navoday Sarafi Sahkari Mandali Ltd." },
  { value: "The Navodaya Urban Co-Operative Bank Ltd", label: "The Navodaya Urban Co-Operative Bank Ltd" },
  { value: "The Navsari Peoples Co Op Bank Ltd", label: "The Navsari Peoples Co Op Bank Ltd" },
  { value: "The Nawada Central Co. Bk Ltd", label: "The Nawada Central Co. Bk Ltd" },
  { value: "The Nawanagar Co-Operative Bank Limited", label: "The Nawanagar Co-Operative Bank Limited" },
  { value: "The Nawanagar Cooperative Bank Ltd.", label: "The Nawanagar Cooperative Bank Ltd." },
  { value: "The Nawanshahr Central Co-Op Bank Ltd.", label: "The Nawanshahr Central Co-Op Bank Ltd." },
  { value: "The Nayagarah District Ceal Co-Op Ba Ltd", label: "The Nayagarah District Ceal Co-Op Ba Ltd" },
  { value: "The Nayaka Mercantile Co-Op Bank Ltd", label: "The Nayaka Mercantile Co-Op Bank Ltd" },
  { value: "The Nayanagar Group Seva Sahakari Mandli Ltd", label: "The Nayanagar Group Seva Sahakari Mandli Ltd" },
  { value: "The Nazareth Urban Co-Op Bank Ltd", label: "The Nazareth Urban Co-Op Bank Ltd" },
  { value: "The Ndcc Bank Ltd", label: "The Ndcc Bank Ltd" },
  { value: "The Nediyanga Service Co-Op. Bank Ltd", label: "The Nediyanga Service Co-Op. Bank Ltd" },
  { value: "The Nediyiruppu S. Co.B.L.Musliyarangadi", label: "The Nediyiruppu S. Co.B.L.Musliyarangadi" },
  { value: "The Nediyiruppu Service Co-Op Bank Ltd.", label: "The Nediyiruppu Service Co-Op Bank Ltd." },
  { value: "The Nedumattam Service Co-Oper Bank Ltd", label: "The Nedumattam Service Co-Oper Bank Ltd" },
  { value: "The Nedumbassery Panchayat S.C.B.Ltd", label: "The Nedumbassery Panchayat S.C.B.Ltd" },
  { value: "The Nedumkandam Co-Op Urban Bank Ltd.", label: "The Nedumkandam Co-Op Urban Bank Ltd." },
  { value: "The Nedumkandam Service Co-Op.B.L.", label: "The Nedumkandam Service Co-Op.B.L." },
  { value: "The Nedumon Service Co-Op Bank Ltd.", label: "The Nedumon Service Co-Op Bank Ltd." },
  { value: "The Nedumpana Village Ser.Co.Op. Bank", label: "The Nedumpana Village Ser.Co.Op. Bank" },
  { value: "The Nedungadi Bank Limited", label: "The Nedungadi Bank Limited" },
  { value: "The Nedungadi Bank Ltd", label: "The Nedungadi Bank Ltd" },
  { value: "The Nedungolam Service Co-Op Bank Ltd.", label: "The Nedungolam Service Co-Op Bank Ltd." },
  { value: "The Neduvathoor Service Co Op Bk Ltd", label: "The Neduvathoor Service Co Op Bk Ltd" },
  { value: "The Neduyanga Service Co-Op Bank Ltd.", label: "The Neduyanga Service Co-Op Bank Ltd." },
  { value: "The Needs Of Life Co.Op. Bank Ltd", label: "The Needs Of Life Co.Op. Bank Ltd" },
  { value: "The Neela Krishna Co-Op. Urban Bank Ltd.", label: "The Neela Krishna Co-Op. Urban Bank Ltd." },
  { value: "The Neelagiri Co-Op. Urban Bank Ltd.", label: "The Neelagiri Co-Op. Urban Bank Ltd." },
  { value: "The Neendakara Ser Coop Bank Ltd.", label: "The Neendakara Ser Coop Bank Ltd." },
  { value: "The Neericode Service Co.Op. Bank", label: "The Neericode Service Co.Op. Bank" },
  { value: "The Neezhoor Service Co-Op. Bank Ltd.", label: "The Neezhoor Service Co-Op. Bank Ltd." },
  { value: "The Nehru Nagar Co Op.Bank Ltd", label: "The Nehru Nagar Co Op.Bank Ltd" },
  { value: "The Nekraje Service Co-Op. Bank Ltd.", label: "The Nekraje Service Co-Op. Bank Ltd." },
  { value: "The Nellakkottai Primary Agricul. Co Bk", label: "The Nellakkottai Primary Agricul. Co Bk" },
  { value: "The Nellayi Parappukara Ser Co-Op Bank", label: "The Nellayi Parappukara Ser Co-Op Bank" },
  { value: "The Nellore Dist.Co-Op.Cent.Bk.L.", label: "The Nellore Dist.Co-Op.Cent.Bk.L." },
  { value: "The Nellore District C-P Central Bank Lt", label: "The Nellore District C-P Central Bank Lt" },
  { value: "The Nesargi Ur.Co-Op.Cr.B.L.", label: "The Nesargi Ur.Co-Op.Cr.B.L." },
  { value: "The Nesargi Urban Co-Op Credit B L", label: "The Nesargi Urban Co-Op Credit B L" },
  { value: "The New Agra Urban Co-Op. Bank Ltd", label: "The New Agra Urban Co-Op. Bank Ltd" },
  { value: "The New Globe Financers (P) Ltd.", label: "The New Globe Financers (P) Ltd." },
  { value: "The New India Assurance Company Limited", label: "The New India Assurance Company Limited" },
  { value: "The New India Co-Op Bank Ltd", label: "The New India Co-Op Bank Ltd" },
  { value: "The New Indian Industries Ltd.", label: "The New Indian Industries Ltd." },
  { value: "The New Urban Co-Op Bank Ltd.", label: "The New Urban Co-Op Bank Ltd." },
  { value: "The New Urban Co-Operative Bank Ltd.", label: "The New Urban Co-Operative Bank Ltd." },
  { value: "The Neyyattinkara Co-Op. Bank Ltd", label: "The Neyyattinkara Co-Op. Bank Ltd" },
  { value: "The Neyyoor Agricultural Co-Op Bank Ltd", label: "The Neyyoor Agricultural Co-Op Bank Ltd" },
  { value: "The Nicholson  Co-Operative Town Bank Lt", label: "The Nicholson  Co-Operative Town Bank Lt" },
  { value: "The Nilambur Co-Op. Urban Bank Ltd", label: "The Nilambur Co-Op. Urban Bank Ltd" },
  { value: "The Nilambur Merchant Co-Op Rrl Bank Ltd", label: "The Nilambur Merchant Co-Op Rrl Bank Ltd" },
  { value: "The Nilambur Service Co-Op Bank Ltd.", label: "The Nilambur Service Co-Op Bank Ltd." },
  { value: "The Nilamel Service Co-Op Bank Ltd.", label: "The Nilamel Service Co-Op Bank Ltd." },
  { value: "The Nileshwar Co-Op Urban Bank Ltd.", label: "The Nileshwar Co-Op Urban Bank Ltd." },
  { value: "The Nileshwar Service Co-Op. Bank Ltd.", label: "The Nileshwar Service Co-Op. Bank Ltd." },
  { value: "The Nilgiris Central Co-Op. Bank Ltd.", label: "The Nilgiris Central Co-Op. Bank Ltd." },
  { value: "The Nilgiris Dist.Central Co-Op.Bank Ltd", label: "The Nilgiris Dist.Central Co-Op.Bank Ltd" },
  { value: "The Nipani Urban Souharda Sah. Bank Ni.,", label: "The Nipani Urban Souharda Sah. Bank Ni.," },
  { value: "The Niphad Urban Co-Op Bank Ltd.", label: "The Niphad Urban Co-Op Bank Ltd." },
  { value: "The Niphad Urban Co-Op Bank Ltd. Niphad", label: "The Niphad Urban Co-Op Bank Ltd. Niphad" },
  { value: "The Nirmal Ujwal Credit Co-Operative", label: "The Nirmal Ujwal Credit Co-Operative" },
  { value: "The Nirmarga Service Co-Op Bank Ltd,", label: "The Nirmarga Service Co-Op Bank Ltd," },
  { value: "The Nizamabad Dist. Co-Op Cen. Bank Ltd.", label: "The Nizamabad Dist. Co-Op Cen. Bank Ltd." },
  { value: "The North Arcot Dt.C.C.O.Bank Ltd", label: "The North Arcot Dt.C.C.O.Bank Ltd" },
  { value: "The North Canara G.S.B Banl Ltd", label: "The North Canara G.S.B Banl Ltd" },
  { value: "The North Canara Gsb Group", label: "The North Canara Gsb Group" },
  { value: "The North Kanara Bank Ltd", label: "The North Kanara Bank Ltd" },
  { value: "The North Kanara G S R Co Op Bank Ltd", label: "The North Kanara G S R Co Op Bank Ltd" },
  { value: "The North Kanara G. S. B. Co-Op. Bank Lt", label: "The North Kanara G. S. B. Co-Op. Bank Lt" },
  { value: "The Nsk Rd Deo Vya Sah Bank Ltd", label: "The Nsk Rd Deo Vya Sah Bank Ltd" },
  { value: "The Nsk Road Devlali Vyapari Bank Ltd Bhagur", label: "The Nsk Road Devlali Vyapari Bank Ltd Bhagur" },
  { value: "The Nutan Transport Co-Operative Society Ltd.", label: "The Nutan Transport Co-Operative Society Ltd." },
  { value: "The Ode Nagrik Credit Co Op Society Ltd", label: "The Ode Nagrik Credit Co Op Society Ltd" },
  { value: "The Ode Nagrik Credit Co- Op Bank Ltd", label: "The Ode Nagrik Credit Co- Op Bank Ltd" },
  { value: "The Ode Urban Co-Operative Bank Ltd", label: "The Ode Urban Co-Operative Bank Ltd" },
  { value: "The Ode Urban Co-Operative Bank Ltd", label: "The Ode Urban Co-Operative Bank Ltd" },
  { value: "The Ojar Merchant Co Bank", label: "The Ojar Merchant Co Bank" },
  { value: "The Ojhar Merchants Co-Op Bank Ltd", label: "The Ojhar Merchants Co-Op Bank Ltd" },
  { value: "The Olavanna Co-Operative Urban Bank Ltd", label: "The Olavanna Co-Operative Urban Bank Ltd" },
  { value: "The Olavanna Service Co-Operative Bk Ltd", label: "The Olavanna Service Co-Operative Bk Ltd" },
  { value: "The Omalloor Service Co-Op. Bank Ltd", label: "The Omalloor Service Co-Op. Bank Ltd" },
  { value: "The Omalur Urban Co-Op Bank Ltd", label: "The Omalur Urban Co-Op Bank Ltd" },
  { value: "The Omkar Co-Op Soc Ltd", label: "The Omkar Co-Op Soc Ltd" },
  { value: "The Omkar Urban Co-Operative Credit Society Ltd.", label: "The Omkar Urban Co-Operative Credit Society Ltd." },
  { value: "The Oonnukal Service Co-Op Bank Ltd.", label: "The Oonnukal Service Co-Op Bank Ltd." },
  { value: "The Oorakam Service Co-Op. Bank Ltd.", label: "The Oorakam Service Co-Op. Bank Ltd." },
  { value: "The Oriental  Insurance  Co. Ltd", label: "The Oriental  Insurance  Co. Ltd" },
  { value: "The Oriental Insurance Company Limited", label: "The Oriental Insurance Company Limited" },
  { value: "The Orissa  State Co-Op. Bank Ltd", label: "The Orissa  State Co-Op. Bank Ltd" },
  { value: "The Osmanabad Dist. Cent. Co-Op Bank Ltd", label: "The Osmanabad Dist. Cent. Co-Op Bank Ltd" },
  { value: "The Osmanabad Dist.Cent.Coop.Bank", label: "The Osmanabad Dist.Cent.Coop.Bank" },
  { value: "The Osmanabad Dist.Central Co-Op.Bank Lt", label: "The Osmanabad Dist.Central Co-Op.Bank Lt" },
  { value: "The Othakkadai Co-Operative Rural B Ltd", label: "The Othakkadai Co-Operative Rural B Ltd" },
  { value: "The Othukkungal Ser. Co-Op. Bk.L.", label: "The Othukkungal Ser. Co-Op. Bk.L." },
  { value: "The Ottapalam Co-Op. Urban Bank Ltd.", label: "The Ottapalam Co-Op. Urban Bank Ltd." },
  { value: "The Ottapalam Service Co-Op Bank Ltd.", label: "The Ottapalam Service Co-Op Bank Ltd." },
  { value: "The Ottasekharamangalam Sr Co.Op Bk Lt", label: "The Ottasekharamangalam Sr Co.Op Bk Lt" },
  { value: "The Ottassekharamangalam Service Cop B L", label: "The Ottassekharamangalam Service Cop B L" },
  { value: "The Ozar Merchant Co Op Bank", label: "The Ozar Merchant Co Op Bank" },
  { value: "The Pachapuri Co Op Bank", label: "The Pachapuri Co Op Bank" },
  { value: "The Pachora Peoples Co.Op Bk Ltd", label: "The Pachora Peoples Co.Op Bk Ltd" },
  { value: "The Padinharathara Service Co-Op Bank", label: "The Padinharathara Service Co-Op Bank" },
  { value: "The Padiyotchal Service Co-Op. Bank Ltd.", label: "The Padiyotchal Service Co-Op. Bank Ltd." },
  { value: "The Padmavathi Co-Op Urban Bank Ltd.", label: "The Padmavathi Co-Op Urban Bank Ltd." },
  { value: "The Padne Service Co-Op Bank Ltd.", label: "The Padne Service Co-Op Bank Ltd." },
  { value: "The Padra Nagar Nagrik Sah Bank Ltd", label: "The Padra Nagar Nagrik Sah Bank Ltd" },
  { value: "The Palakkad District Co-Op Bank Ltd.", label: "The Palakkad District Co-Op Bank Ltd." },
  { value: "The Palamoor C-Op Urban Bank Ltd.", label: "The Palamoor C-Op Urban Bank Ltd." },
  { value: "The Palamoor Co-Op Bank Ltd.", label: "The Palamoor Co-Op Bank Ltd." },
  { value: "The Palanpur Peoples Co-Op. Bank Ltd", label: "The Palanpur Peoples Co-Op. Bank Ltd" },
  { value: "The Palanpur Peoples Co Op Bank", label: "The Palanpur Peoples Co Op Bank" },
  { value: "The Palghat Co-Op Central Bank Ltd.", label: "The Palghat Co-Op Central Bank Ltd." },
  { value: "The Palghat Co-Op Urban Bank Ltd.", label: "The Palghat Co-Op Urban Bank Ltd." },
  { value: "The Palghat Service Co-Op Bank Ltd.", label: "The Palghat Service Co-Op Bank Ltd." },
  { value: "The Pali Central Co-Operative Bank Ltd", label: "The Pali Central Co-Operative Bank Ltd" },
  { value: "The Pali Central Co-Operative Bank Ltd.", label: "The Pali Central Co-Operative Bank Ltd." },
  { value: "The Palladam Farmers Service", label: "The Palladam Farmers Service" },
  { value: "The Pallickal Farmers Service Co Bank", label: "The Pallickal Farmers Service Co Bank" },
  { value: "The Pallikere Service Co-Op Bank Ltd.", label: "The Pallikere Service Co-Op Bank Ltd." },
  { value: "The Pallikkal Service Co-Oper Bank Ltd", label: "The Pallikkal Service Co-Oper Bank Ltd" },
  { value: "The Pallikunnu Service Co-Op Bank Ltd", label: "The Pallikunnu Service Co-Op Bank Ltd" },
  { value: "The Pallippuram Service Co-Op. Bank Ltd.", label: "The Pallippuram Service Co-Op. Bank Ltd." },
  { value: "The Pambanar Ser.Co. Bank Ltd.", label: "The Pambanar Ser.Co. Bank Ltd." },
  { value: "The Pampavalley Service Co-Op Bank Ltd.", label: "The Pampavalley Service Co-Op Bank Ltd." },
  { value: "The Panachikad Regional Ser. C.B.L.", label: "The Panachikad Regional Ser. C.B.L." },
  { value: "The Panamaram Service Co-Op Bank Ltd.", label: "The Panamaram Service Co-Op Bank Ltd." },
  { value: "The Panangad Service Co-Op Bank Ltd.", label: "The Panangad Service Co-Op Bank Ltd." },
  { value: "The Panathady Ser. Co-Op. Bank Ltd.", label: "The Panathady Ser. Co-Op. Bank Ltd." },
  { value: "The Panayal Service Co-Op. Bank Ltd", label: "The Panayal Service Co-Op. Bank Ltd" },
  { value: "The Panchkula Cen Co-Op Bank Ltd.", label: "The Panchkula Cen Co-Op Bank Ltd." },
  { value: "The Panchkula Urban Co-Op. Bank Ltd", label: "The Panchkula Urban Co-Op. Bank Ltd" },
  { value: "The Panchmahals District Coop Bank Ltd", label: "The Panchmahals District Coop Bank Ltd" },
  { value: "The Panchsheel Mercantile Co-Op.Bank Ltd", label: "The Panchsheel Mercantile Co-Op.Bank Ltd" },
  { value: "The Panchshil Mercantile Co. Op Bank Ltd.", label: "The Panchshil Mercantile Co. Op Bank Ltd." },
  { value: "The Pandharpur Merchants Co-Op Bank Ltd", label: "The Pandharpur Merchants Co-Op Bank Ltd" },
  { value: "The Pandharpur Merchants Co-Op Bk Ltd.", label: "The Pandharpur Merchants Co-Op Bk Ltd." },
  { value: "The Pandharpur Merchants Co-Op.Bank Ltd", label: "The Pandharpur Merchants Co-Op.Bank Ltd" },
  { value: "The Pandharpur Merchants Co Op Bank", label: "The Pandharpur Merchants Co Op Bank" },
  { value: "The Pandharpur Urban Co-Op Bank Ltd", label: "The Pandharpur Urban Co-Op Bank Ltd" },
  { value: "The Pandharpur Urban Co-Op Bank Ltd.", label: "The Pandharpur Urban Co-Op Bank Ltd." },
  { value: "The Pang Service Co-Operative Bank Ltd", label: "The Pang Service Co-Operative Bank Ltd" },
  { value: "The Panihati Co-Op Bank Ltd.", label: "The Panihati Co-Op Bank Ltd." },
  { value: "The Panipat Central Co-Op. Bank Ltd", label: "The Panipat Central Co-Op. Bank Ltd" },
  { value: "The Panipat Urban Co-Op Bank Ltd", label: "The Panipat Urban Co-Op Bank Ltd" },
  { value: "The Panja Co-Op. Agrl. Bank Ltd", label: "The Panja Co-Op. Agrl. Bank Ltd" },
  { value: "The Panmana Ser Co-Op Bank Ltd,", label: "The Panmana Ser Co-Op Bank Ltd," },
  { value: "The Panniyannur Service Co-Op Bank Ltd,", label: "The Panniyannur Service Co-Op Bank Ltd," },
  { value: "The Panthalur Service Co-Op. Bank", label: "The Panthalur Service Co-Op. Bank" },
  { value: "The Panvel Co-Op Urban Bank Ltd", label: "The Panvel Co-Op Urban Bank Ltd" },
  { value: "The Pappinisseri Co-Op Rural Bank Ltd", label: "The Pappinisseri Co-Op Rural Bank Ltd" },
  { value: "The Pappinisseri Ser.Co-Op. Bank Ltd.", label: "The Pappinisseri Ser.Co-Op. Bank Ltd." },
  { value: "The Paramakudi Co-Op. Urban Bank Ltd", label: "The Paramakudi Co-Op. Urban Bank Ltd" },
  { value: "The Parappanangadi Co-Op. Ser. Bk.L.,", label: "The Parappanangadi Co-Op. Ser. Bk.L.," },
  { value: "The Parappur Service Co-Operative Bank", label: "The Parappur Service Co-Operative Bank" },
  { value: "The Parathode Service Co-Operative Bank", label: "The Parathode Service Co-Operative Bank" },
  { value: "The Paravur S.N.V. Regional Co-Op. Bk Ld", label: "The Paravur S.N.V. Regional Co-Op. Bk Ld" },
  { value: "The Paravur Urban Co.Op. Bank Ltd", label: "The Paravur Urban Co.Op. Bank Ltd" },
  { value: "The Parayakad Ser Co-Op Bank Ltd,", label: "The Parayakad Ser Co-Op Bank Ltd," },
  { value: "The Parbhani Dist.Central Co-Op Bank Ltd", label: "The Parbhani Dist.Central Co-Op Bank Ltd" },
  { value: "The Parbhani Peoples Co-Op Bank Ltd.", label: "The Parbhani Peoples Co-Op Bank Ltd." },
  { value: "The Pariyaram Service Co-Op Bank Ltd", label: "The Pariyaram Service Co-Op Bank Ltd" },
  { value: "The Parur Co-Op. Bank Ltd.", label: "The Parur Co-Op. Bank Ltd." },
  { value: "The Pasumpon Muthur.Ther. Dt. Ct. Cp Bk.", label: "The Pasumpon Muthur.Ther. Dt. Ct. Cp Bk." },
  { value: "The Pasumpon Muthuram.Thevar Dist.Bk Ltd", label: "The Pasumpon Muthuram.Thevar Dist.Bk Ltd" },
  { value: "The Patan Urban C.O.B.L", label: "The Patan Urban C.O.B.L" },
  { value: "The Patdi Nagarik Sahakari Bank Ltd", label: "The Patdi Nagarik Sahakari Bank Ltd" },
  { value: "The Pathanamthitta Dist Co-Op. Bank Ltd.", label: "The Pathanamthitta Dist Co-Op. Bank Ltd." },
  { value: "The Pathanamthitta Dist. Co-Op Bank Ltd.", label: "The Pathanamthitta Dist. Co-Op Bank Ltd." },
  { value: "The Pathanapuram Service Co-Op Bank Ltd.", label: "The Pathanapuram Service Co-Op Bank Ltd." },
  { value: "The Pathankot Hindu Urban Co-Op. Bank Lt", label: "The Pathankot Hindu Urban Co-Op. Bank Lt" },
  { value: "The Pathanmthitta Service Co-Op Bank Ltd", label: "The Pathanmthitta Service Co-Op Bank Ltd" },
  { value: "The Pathiriyad Service Co-Op Bank Ltd", label: "The Pathiriyad Service Co-Op Bank Ltd" },
  { value: "The Pathiyoor Farmers Service Co-Op.Bk.L", label: "The Pathiyoor Farmers Service Co-Op.Bk.L" },
  { value: "The Patiala Central Co Op Bank Ltd", label: "The Patiala Central Co Op Bank Ltd" },
  { value: "The Patil Multi Purpose Souhard Sahakari Ltd", label: "The Patil Multi Purpose Souhard Sahakari Ltd" },
  { value: "The Patil Multi Purpose Souhard Sahakari Ltd", label: "The Patil Multi Purpose Souhard Sahakari Ltd" },
  { value: "The Patil Multi Purpose Souhard Sahakari Ltd", label: "The Patil Multi Purpose Souhard Sahakari Ltd" },
  { value: "The Patliputra Central Co-Op Bank Ltd", label: "The Patliputra Central Co-Op Bank Ltd" },
  { value: "The Patliputra Central Co.Op Bank Ltd", label: "The Patliputra Central Co.Op Bank Ltd" },
  { value: "The Pattam Service Co-Op Bank,", label: "The Pattam Service Co-Op Bank," },
  { value: "The Pattambi Service Co-Op Bank Ltd.", label: "The Pattambi Service Co-Op Bank Ltd." },
  { value: "The Pattanakkad Se.Co-Op.Bk.L.", label: "The Pattanakkad Se.Co-Op.Bk.L." },
  { value: "The Pattanur Service Co.Op. Bank Ltd", label: "The Pattanur Service Co.Op. Bank Ltd" },
  { value: "The Pattathanam Service Co Bk Ltd.", label: "The Pattathanam Service Co Bk Ltd." },
  { value: "The Pattikkad Service Co-Op Bank Ltd.", label: "The Pattikkad Service Co-Op Bank Ltd." },
  { value: "The Pattithara Service Co-Op Bank Ltd.", label: "The Pattithara Service Co-Op Bank Ltd." },
  { value: "The Pattukkottai Co-Op. Urban Bk. Ltd.", label: "The Pattukkottai Co-Op. Urban Bk. Ltd." },
  { value: "The Pattuvam Service Co-Op. Bank Ltd", label: "The Pattuvam Service Co-Op. Bank Ltd" },
  { value: "The Pavagada Souharda Pattina Sahakari Niyamitha", label: "The Pavagada Souharda Pattina Sahakari Niyamitha" },
  { value: "The Pavaratty Service Co.Op Bank Ltd", label: "The Pavaratty Service Co.Op Bank Ltd" },
  { value: "The Payam Ser.Co-Op. Bank Ltd.", label: "The Payam Ser.Co-Op. Bank Ltd." },
  { value: "The Payangadi Urban Co-Operative Bank Lt", label: "The Payangadi Urban Co-Operative Bank Lt" },
  { value: "The Payyanad Service Co-Op. Bk.L.,", label: "The Payyanad Service Co-Op. Bk.L.," },
  { value: "The Payyannur Co-Op. Rural Bank Ltd.", label: "The Payyannur Co-Op. Rural Bank Ltd." },
  { value: "The Payyanur Co.Op Town Bank Ltd", label: "The Payyanur Co.Op Town Bank Ltd" },
  { value: "The Payyanur Service Co-Op Bank Ltd", label: "The Payyanur Service Co-Op Bank Ltd" },
  { value: "The Payyoli Co-Op. Urban Bank Ltd.", label: "The Payyoli Co-Op. Urban Bank Ltd." },
  { value: "The Payyoli Service Co-Op. Bank Ltd.", label: "The Payyoli Service Co-Op. Bank Ltd." },
  { value: "The Pazhayakunnumal Servic Co-Op Bk Ltd,", label: "The Pazhayakunnumal Servic Co-Op Bk Ltd," },
  { value: "The Pen Co-Op Urban Bank Ltd.", label: "The Pen Co-Op Urban Bank Ltd." },
  { value: "The Pen Co-Operative Urban Bank Ltd.", label: "The Pen Co-Operative Urban Bank Ltd." },
  { value: "The People Sahkari Sarafi Mandali Ltd Modasa", label: "The People Sahkari Sarafi Mandali Ltd Modasa" },
  { value: "The Perakam Service Co-Op Bank Ltd.", label: "The Perakam Service Co-Op Bank Ltd." },
  { value: "The Perambra Regional Co-Operative Bk L", label: "The Perambra Regional Co-Operative Bk L" },
  { value: "The Peravoor Co-Op Urban Bank Ltd.", label: "The Peravoor Co-Op Urban Bank Ltd." },
  { value: "The Peringandoor Servc. Coop Bank Ltd.", label: "The Peringandoor Servc. Coop Bank Ltd." },
  { value: "The Peringome Service Co-Op.Bank Ltd.", label: "The Peringome Service Co-Op.Bank Ltd." },
  { value: "The Perintalmanna Service Co-Op Bank Ltd", label: "The Perintalmanna Service Co-Op Bank Ltd" },
  { value: "The Perinthalmanna Co-Op. Urban Bank Ltd", label: "The Perinthalmanna Co-Op. Urban Bank Ltd" },
  { value: "The Periya Kodivery P.A.C Bk L,", label: "The Periya Kodivery P.A.C Bk L," },
  { value: "The Periyar Dist Central Co-Op Bank Ltd.", label: "The Periyar Dist Central Co-Op Bank Ltd." },
  { value: "The Periye Service Co-Op Bank", label: "The Periye Service Co-Op Bank" },
  { value: "The Perumanna Klari Service Co-Op Bank L", label: "The Perumanna Klari Service Co-Op Bank L" },
  { value: "The Perumanna Service Co-Op Bank Ltd.", label: "The Perumanna Service Co-Op Bank Ltd." },
  { value: "The Perumatty Service Cooperative Bk Ltd", label: "The Perumatty Service Cooperative Bk Ltd" },
  { value: "The Perumbala Service Co-Op Bank Ltd,", label: "The Perumbala Service Co-Op Bank Ltd," },
  { value: "The Perumbavoor Urban Coop Bk Ltd", label: "The Perumbavoor Urban Coop Bk Ltd" },
  { value: "The Perumpuzha Gramodharana Ser Cp Bk L", label: "The Perumpuzha Gramodharana Ser Cp Bk L" },
  { value: "The Perunad Services Cooperative Bank Lt", label: "The Perunad Services Cooperative Bank Lt" },
  { value: "The Peruvanthanam Service Co-Op Bank Ltd", label: "The Peruvanthanam Service Co-Op Bank Ltd" },
  { value: "The Peruvayal Service Co-Op.Bank Ltd.", label: "The Peruvayal Service Co-Op.Bank Ltd." },
  { value: "The Peruvemba Service Co-Op Bank Ltd.", label: "The Peruvemba Service Co-Op Bank Ltd." },
  { value: "The Petlad Nagarik Co-Op. Bank Ltd.", label: "The Petlad Nagarik Co-Op. Bank Ltd." },
  { value: "The Phaltan Urban Co-Op Bank Ltd.", label: "The Phaltan Urban Co-Op Bank Ltd." },
  { value: "The Pij Peoples Co-Operative Bank Ltd.", label: "The Pij Peoples Co-Operative Bank Ltd." },
  { value: "The Pij Peoples Cooperative Bank Ltd", label: "The Pij Peoples Cooperative Bank Ltd" },
  { value: "The Pimpalgaon Merchants Co.Op. Bk Ltd", label: "The Pimpalgaon Merchants Co.Op. Bk Ltd" },
  { value: "The Pinarayi Service Co-Op Bank Ltd.", label: "The Pinarayi Service Co-Op Bank Ltd." },
  { value: "The Piranalat Seva Sahkari Mandli Ltd", label: "The Piranalat Seva Sahkari Mandli Ltd" },
  { value: "The Pissurlem Urban Co-Op Credit Society Ltd", label: "The Pissurlem Urban Co-Op Credit Society Ltd" },
  { value: "The Plus Urban Credit Souhardha Saha Nyt", label: "The Plus Urban Credit Souhardha Saha Nyt" },
  { value: "The Pochampally Co-Operative Urban Bk Lt", label: "The Pochampally Co-Operative Urban Bk Lt" },
  { value: "The Pollachi Co-Op. Urban Bank Ltd.", label: "The Pollachi Co-Op. Urban Bank Ltd." },
  { value: "The Polpully Services Co. Operative Bank", label: "The Polpully Services Co. Operative Bank" },
  { value: "The Pondicherry Co-Op Urban Bank Ltd,", label: "The Pondicherry Co-Op Urban Bank Ltd," },
  { value: "The Pondicherry State Co-Op Bank Ltd", label: "The Pondicherry State Co-Op Bank Ltd" },
  { value: "The Ponkunnam Service Co-Op.Bank Ltd.", label: "The Ponkunnam Service Co-Op.Bank Ltd." },
  { value: "The Ponmundam Ser. Co-Op. Bank Ltd", label: "The Ponmundam Ser. Co-Op. Bank Ltd" },
  { value: "The Ponnani Co Operative Agricultural And Rural Department", label: "The Ponnani Co Operative Agricultural And Rural Department" },
  { value: "The Ponneri Co-Op Rural Bank Ltd,", label: "The Ponneri Co-Op Rural Bank Ltd," },
  { value: "The Pookkottur Service Cooperative Bank", label: "The Pookkottur Service Cooperative Bank" },
  { value: "The Poona Contractors Co-Op. Bank Ltd", label: "The Poona Contractors Co-Op. Bank Ltd" },
  { value: "The Poonjar Service Co-Op. Bank Ltd.", label: "The Poonjar Service Co-Op. Bank Ltd." },
  { value: "The Poothadi Services Bank Ltd.", label: "The Poothadi Services Bank Ltd." },
  { value: "The Poothady Ser Co-Op Bk Lt,", label: "The Poothady Ser Co-Op Bk Lt," },
  { value: "The Poottai Primary Agricultural C B L", label: "The Poottai Primary Agricultural C B L" },
  { value: "The Poovathumkadavu Far.Ser.Co-Op.B.L", label: "The Poovathumkadavu Far.Ser.Co-Op.B.L" },
  { value: "The Popullg Service Co-Op Bank Ltd.", label: "The Popullg Service Co-Op Bank Ltd." },
  { value: "The Porur Service Co. Bank Ltd", label: "The Porur Service Co. Bank Ltd" },
  { value: "The Postal & R.M.S.Employees Co-Op Bank", label: "The Postal & R.M.S.Employees Co-Op Bank" },
  { value: "The Pothady Service Co-Op Bank Ltd.", label: "The Pothady Service Co-Op Bank Ltd." },
  { value: "The Pothukal Service Co-Op Bank Ltd.", label: "The Pothukal Service Co-Op Bank Ltd." },
  { value: "The Pottankad Service Co-Op Bank Ltd.", label: "The Pottankad Service Co-Op Bank Ltd." },
  { value: "The Pottassery Service Co-Op. Bank Ltd", label: "The Pottassery Service Co-Op. Bank Ltd" },
  { value: "The Poyya Service Co-Op Bank Ltd", label: "The Poyya Service Co-Op Bank Ltd" },
  { value: "The Pradeshiya Industrial And Investment Corporation Of Uttar Pradesh Ltd", label: "The Pradeshiya Industrial And Investment Corporation Of Uttar Pradesh Ltd" },
  { value: "The Pragathi Gramin Bank", label: "The Pragathi Gramin Bank" },
  { value: "The Pragati Co Op Bank Ltd (Thara)", label: "The Pragati Co Op Bank Ltd (Thara)" },
  { value: "The Pragati Co-Op Credit & Sup. Soc Ltd.", label: "The Pragati Co-Op Credit & Sup. Soc Ltd." },
  { value: "The Pragati Urban Co-Op Bank Ltd", label: "The Pragati Urban Co-Op Bank Ltd" },
  { value: "The Pragati Urban Co-Operative Bank Ltd.", label: "The Pragati Urban Co-Operative Bank Ltd." },
  { value: "The Pragati Urban Co.Op Bank Ltd", label: "The Pragati Urban Co.Op Bank Ltd" },
  { value: "The Pragpur Co Op Agri Ser Society Ltd.", label: "The Pragpur Co Op Agri Ser Society Ltd." },
  { value: "The Prakasam Dist Co-Op Central Bk Ltd", label: "The Prakasam Dist Co-Op Central Bk Ltd" },
  { value: "The Prakasapuram Co-Op Urban Bank Ltd", label: "The Prakasapuram Co-Op Urban Bank Ltd" },
  { value: "The Prakasapuram Co-Op. Urban Bank Ltd.", label: "The Prakasapuram Co-Op. Urban Bank Ltd." },
  { value: "The Prantij Nagarik Sahakari Bank Ltd.", label: "The Prantij Nagarik Sahakari Bank Ltd." },
  { value: "The Prasad Co Op Cr Soc Ltd", label: "The Prasad Co Op Cr Soc Ltd" },
  { value: "The Pratap Co-Operative Bank Ltd.", label: "The Pratap Co-Operative Bank Ltd." },
  { value: "The Premier Co Op Urban Bank Ltd", label: "The Premier Co Op Urban Bank Ltd" },
  { value: "The President Of India Loksabha Secretariat", label: "The President Of India Loksabha Secretariat" },
  { value: "The Primary Agrl. Co-Op.Credit Soc.Ltd.", label: "The Primary Agrl. Co-Op.Credit Soc.Ltd." },
  { value: "The Primea Automobiles Financers", label: "The Primea Automobiles Financers" },
  { value: "The Printers House Pvt. Ltd.", label: "The Printers House Pvt. Ltd." },
  { value: "The Pritisangam Sahakari Bank Ltd.", label: "The Pritisangam Sahakari Bank Ltd." },
  { value: "The Proddatur Co-Op.Town Bank Ltd.", label: "The Proddatur Co-Op.Town Bank Ltd." },
  { value: "The Progressive And Bachat Dheran Karanari Sahakari Mandali Ltd.", label: "The Progressive And Bachat Dheran Karanari Sahakari Mandali Ltd." },
  { value: "The Pudukad Service Co-Op Bank Ltd.", label: "The Pudukad Service Co-Op Bank Ltd." },
  { value: "The Pudukkottai Central Co-Op Bank Ltd.", label: "The Pudukkottai Central Co-Op Bank Ltd." },
  { value: "The Pudukkottai Co-Op Town Bank Ltd.", label: "The Pudukkottai Co-Op Town Bank Ltd." },
  { value: "The Pudukkottai Dist. Central C P Bk Ltd", label: "The Pudukkottai Dist. Central C P Bk Ltd" },
  { value: "The Pudukottai Co-Operative Town Bk Ltd,", label: "The Pudukottai Co-Operative Town Bk Ltd," },
  { value: "The Pudunagaram Service Co-Op Bank Ltd.", label: "The Pudunagaram Service Co-Op Bank Ltd." },
  { value: "The Puduppariyaram Service Co-Op. Bank L", label: "The Puduppariyaram Service Co-Op. Bank L" },
  { value: "The Pulamanthole Service Co-Op Bank Ltd", label: "The Pulamanthole Service Co-Op Bank Ltd" },
  { value: "The Pulappatta Co-Op. Urban Bank Ltd", label: "The Pulappatta Co-Op. Urban Bank Ltd" },
  { value: "The Pulgaon Urban Credit Co-Op.San Ltd", label: "The Pulgaon Urban Credit Co-Op.San Ltd" },
  { value: "The Pulikkal Service Co-Op Bank Ltd.", label: "The Pulikkal Service Co-Op Bank Ltd." },
  { value: "The Pullad Service Co-Operative Bank Ltd", label: "The Pullad Service Co-Operative Bank Ltd" },
  { value: "The Pulpally Service Co-Operat Bank Ltd", label: "The Pulpally Service Co-Operat Bank Ltd" },
  { value: "The Pulpatta Service Co-Op. Bank Ltd.", label: "The Pulpatta Service Co-Op. Bank Ltd." },
  { value: "The Punalur Sebice Co-Op Bank Ltd", label: "The Punalur Sebice Co-Op Bank Ltd" },
  { value: "The Punjab State Co-Operative Bank Ltd.", label: "The Punjab State Co-Operative Bank Ltd." },
  { value: "The Punnad Service Co-Op Bank Ltd.", label: "The Punnad Service Co-Op Bank Ltd." },
  { value: "The Punnappala Ser.Co-Op.Bk.L.", label: "The Punnappala Ser.Co-Op.Bk.L." },
  { value: "The Punnayurkulam Ser Co-Op B L", label: "The Punnayurkulam Ser Co-Op B L" },
  { value: "The Punnol Service Co-Op Bank Ltd", label: "The Punnol Service Co-Op Bank Ltd" },
  { value: "The Punnol Service Co-Op Bank Ltd.", label: "The Punnol Service Co-Op Bank Ltd." },
  { value: "The Purameri Service Co-Op. Bank Ltd", label: "The Purameri Service Co-Op. Bank Ltd" },
  { value: "The Purathur Service Co- Operative Bk Ld", label: "The Purathur Service Co- Operative Bk Ld" },
  { value: "The Puri Urban Co-Op Bank Ltd.", label: "The Puri Urban Co-Op Bank Ltd." },
  { value: "The Puthuperiyaram Service Co-Op Bank,", label: "The Puthuperiyaram Service Co-Op Bank," },
  { value: "The Puthuppally Village Ser Co.Bk Ltd", label: "The Puthuppally Village Ser Co.Bk Ltd" },
  { value: "The Puttur Co-Operative Town Bank Ltd.", label: "The Puttur Co-Operative Town Bank Ltd." },
  { value: "The Puttur Rubber Grow Mktg & Proc C.S.L", label: "The Puttur Rubber Grow Mktg & Proc C.S.L" },
  { value: "The Puzhathi Service Co-Op Bk L", label: "The Puzhathi Service Co-Op Bk L" },
  { value: "The Puzhathi Service Co-Op. Bank Ltd.", label: "The Puzhathi Service Co-Op. Bank Ltd." },
  { value: "The Quepem Ur. Co. Op. Cr. Sty. Ltd", label: "The Quepem Ur. Co. Op. Cr. Sty. Ltd" },
  { value: "The Quepem Urbam Co-Op. Cr. Sty. Ltd.", label: "The Quepem Urbam Co-Op. Cr. Sty. Ltd." },
  { value: "The Quilandy Service Co-Op Bank Ltd.", label: "The Quilandy Service Co-Op Bank Ltd." },
  { value: "The Quilon Co-Operative Urban Bk.Ltd.", label: "The Quilon Co-Operative Urban Bk.Ltd." },
  { value: "The Quilon District Co-Op. Bank Ltd.", label: "The Quilon District Co-Op. Bank Ltd." },
  { value: "The R. S. Co-Operative Bank Ltd.", label: "The R. S. Co-Operative Bank Ltd." },
  { value: "The Rachna Co Op Credit & Sons.So.", label: "The Rachna Co Op Credit & Sons.So." },
  { value: "The Raddi Co.Op Credit Bank Ltd", label: "The Raddi Co.Op Credit Bank Ltd" },
  { value: "The Raddi Sahakar Bank Niyamit,", label: "The Raddi Sahakar Bank Niyamit," },
  { value: "The Raddi Sahakara Bank Niyamit", label: "The Raddi Sahakara Bank Niyamit" },
  { value: "The Raddi Sahakara Bank Niyamitha", label: "The Raddi Sahakara Bank Niyamitha" },
  { value: "The Radhasoami Urban Co-Op Bank Ltd", label: "The Radhasoami Urban Co-Op Bank Ltd" },
  { value: "The Radhu Mercantile Co -Op Credit Society", label: "The Radhu Mercantile Co -Op Credit Society" },
  { value: "The Radhu Mercantile Co Op Credit Society", label: "The Radhu Mercantile Co Op Credit Society" },
  { value: "The Raghunayaka Co-Op Rural Bank Ltd,", label: "The Raghunayaka Co-Op Rural Bank Ltd," },
  { value: "The Raghuraj Co-Op Credit Society Ltd", label: "The Raghuraj Co-Op Credit Society Ltd" },
  { value: "The Rahuri Peoples Co-Op Bank Ltd.", label: "The Rahuri Peoples Co-Op Bank Ltd." },
  { value: "The Rahuta Union Co - Op Bank Ltd", label: "The Rahuta Union Co - Op Bank Ltd" },
  { value: "The Raigad District Central Coop Bank Lt", label: "The Raigad District Central Coop Bank Lt" },
  { value: "The Raiganj Central Co-Op. Bank Ltd", label: "The Raiganj Central Co-Op. Bank Ltd" },
  { value: "The Railway Empolyees Co-Op Bank Ltd.", label: "The Railway Empolyees Co-Op Bank Ltd." },
  { value: "The Raipur Urban Mercantile Co-Op Bank", label: "The Raipur Urban Mercantile Co-Op Bank" },
  { value: "The Raj Laxmi Mahila Urban Co-Op. Bk Ltd", label: "The Raj Laxmi Mahila Urban Co-Op. Bk Ltd" },
  { value: "The Rajajinagar Co-Op Bank Ltd", label: "The Rajajinagar Co-Op Bank Ltd" },
  { value: "The Rajajinagar Co-Op. Bank Ltd.", label: "The Rajajinagar Co-Op. Bank Ltd." },
  { value: "The Rajakad Service Co-Op Bank Ltd.", label: "The Rajakad Service Co-Op Bank Ltd." },
  { value: "The Rajasthan Bank Ltd", label: "The Rajasthan Bank Ltd" },
  { value: "The Rajasthan Sahkari Sarafi Mandali Ltd.", label: "The Rajasthan Sahkari Sarafi Mandali Ltd." },
  { value: "The Rajasthan State Co-Op.Bank Ltd.", label: "The Rajasthan State Co-Op.Bank Ltd." },
  { value: "The Rajasthan State Ind. Co-Op.Bk. Ltd.", label: "The Rajasthan State Ind. Co-Op.Bk. Ltd." },
  { value: "The Rajpipla Nagric Sahakari Bank Ltd", label: "The Rajpipla Nagric Sahakari Bank Ltd" },
  { value: "The Rajputana Mahila Urban Co. Bank Ltd.", label: "The Rajputana Mahila Urban Co. Bank Ltd." },
  { value: "The Rajsamand Urban Co Operative Bank Ltd", label: "The Rajsamand Urban Co Operative Bank Ltd" },
  { value: "The Rajsamand Urban Co-Op Bank Ltd", label: "The Rajsamand Urban Co-Op Bank Ltd" },
  { value: "The Rajula Nagarik Sahakari Bank Ltd.", label: "The Rajula Nagarik Sahakari Bank Ltd." },
  { value: "The Rajwade Mandal Peoples Co-Op Bank Lt", label: "The Rajwade Mandal Peoples Co-Op Bank Lt" },
  { value: "The Ram Sahakari Bank Ltd.", label: "The Ram Sahakari Bank Ltd." },
  { value: "The Ramakrishna Co-Op Urban Bank Ltd", label: "The Ramakrishna Co-Op Urban Bank Ltd" },
  { value: "The Ramakrishna Mutually A. Co U. Bk Lt", label: "The Ramakrishna Mutually A. Co U. Bk Lt" },
  { value: "The Ramamangalam Service C O B Ltd", label: "The Ramamangalam Service C O B Ltd" },
  { value: "The Ramanand Urban Co Op Credit Soc", label: "The Ramanand Urban Co Op Credit Soc" },
  { value: "The Ramanathapuram Dist. Cent. C P Bk Lt", label: "The Ramanathapuram Dist. Cent. C P Bk Lt" },
  { value: "The Ramanattukara Ser Co-Op Bank Ltd.", label: "The Ramanattukara Ser Co-Op Bank Ltd." },
  { value: "The Ramanattukara Service Co-Op.Bank Ltd", label: "The Ramanattukara Service Co-Op.Bank Ltd" },
  { value: "The Ramapuram Ser.Co-Op.B.L.", label: "The Ramapuram Ser.Co-Op.B.L." },
  { value: "The Ramnathpuram Co-Op Urban Bank Ltd", label: "The Ramnathpuram Co-Op Urban Bank Ltd" },
  { value: "The Ramol Nagrik Co-Op Credit Soc Ltd", label: "The Ramol Nagrik Co-Op Credit Soc Ltd" },
  { value: "The Rampur People Co Op Credit Society Ltd", label: "The Rampur People Co Op Credit Society Ltd" },
  { value: "The Rampur Seva Shahakari Mandli", label: "The Rampur Seva Shahakari Mandli" },
  { value: "The Ranchi-Khunti Central Co-Op Bank Ltd", label: "The Ranchi-Khunti Central Co-Op Bank Ltd" },
  { value: "The Rander Peolples Co Op Bank Ltd", label: "The Rander Peolples Co Op Bank Ltd" },
  { value: "The Rander Peoples Co-Op Bank Ltd", label: "The Rander Peoples Co-Op Bank Ltd" },
  { value: "The Rander Peoples Co-Op Bank Ltd.", label: "The Rander Peoples Co-Op Bank Ltd." },
  { value: "The Randheja Commercial Co-Op Bank Ltd.", label: "The Randheja Commercial Co-Op Bank Ltd." },
  { value: "The Ranga Reddy Co-Op. Urban Bank Ltd.", label: "The Ranga Reddy Co-Op. Urban Bank Ltd." },
  { value: "The Ranipet Town Co-Op. Bank Ltd", label: "The Ranipet Town Co-Op. Bank Ltd" },
  { value: "The Ranpur Seva Shahkari Mandli", label: "The Ranpur Seva Shahkari Mandli" },
  { value: "The Ranuj Nagarik Sahakari Bank Ltd.", label: "The Ranuj Nagarik Sahakari Bank Ltd." },
  { value: "The Rasipuram Co-Operative Urban Bank Lt", label: "The Rasipuram Co-Operative Urban Bank Lt" },
  { value: "The Ratnakar Bank Ltd.", label: "The Ratnakar Bank Ltd." },
  { value: "The Raver Peoples Co-Op. Bk.Ltd.", label: "The Raver Peoples Co-Op. Bk.Ltd." },
  { value: "The Ravi Co-Op. Bank Ltd., Kolhapur", label: "The Ravi Co-Op. Bank Ltd., Kolhapur" },
  { value: "The Rayat Sevak Co-Operative Bank Ltd", label: "The Rayat Sevak Co-Operative Bank Ltd" },
  { value: "The Reddi Sahakari Bank Niyamit", label: "The Reddi Sahakari Bank Niyamit" },
  { value: "The Reddy Co-Op Sahakari Bank", label: "The Reddy Co-Op Sahakari Bank" },
  { value: "The Registar University Of Madras", label: "The Registar University Of Madras" },
  { value: "The Relief Mercantile Co-Op. Bank Ltd", label: "The Relief Mercantile Co-Op. Bank Ltd" },
  { value: "The Revdanda Co-Op Urban Bk Ltd", label: "The Revdanda Co-Op Urban Bk Ltd" },
  { value: "The Rewari Central Co-Op. Bank Ltd", label: "The Rewari Central Co-Op. Bank Ltd" },
  { value: "The Rifle Factory Co-Operative Society Ltd", label: "The Rifle Factory Co-Operative Society Ltd" },
  { value: "The Rishiwat Urban Co Op Credit Society Ltd", label: "The Rishiwat Urban Co Op Credit Society Ltd" },
  { value: "The Rohe Ashtami Sahakari Urban Bank Ltd", label: "The Rohe Ashtami Sahakari Urban Bank Ltd" },
  { value: "The Rohika Central Coop Bank Ltd", label: "The Rohika Central Coop Bank Ltd" },
  { value: "The Rohtak Central Co.Op. Bank Ltd.", label: "The Rohtak Central Co.Op. Bank Ltd." },
  { value: "The Ropar Central Co-Op. Bank Ltd", label: "The Ropar Central Co-Op. Bank Ltd" },
  { value: "The S A S Nagar Central Co-Op Bank Ltd", label: "The S A S Nagar Central Co-Op Bank Ltd" },
  { value: "The S S K Co.Operative Bank Ltd", label: "The S S K Co.Operative Bank Ltd" },
  { value: "The S.K.Goldsmiths Ind. Co-Op. Soc.Ltd", label: "The S.K.Goldsmiths Ind. Co-Op. Soc.Ltd" },
  { value: "The Sabarkantha Dis. Co-Op. Bank Ltd.", label: "The Sabarkantha Dis. Co-Op. Bank Ltd." },
  { value: "The Sabarkantha Dist Cent Co-Op Bk.Ltd.", label: "The Sabarkantha Dist Cent Co-Op Bk.Ltd." },
  { value: "The Sabarkantha Dist Co Op Bank", label: "The Sabarkantha Dist Co Op Bank" },
  { value: "The Sabarkantha Dist. Cent. Coop Bk.", label: "The Sabarkantha Dist. Cent. Coop Bk." },
  { value: "The Sabarkantha Dist. Central Co-Op Bank", label: "The Sabarkantha Dist. Central Co-Op Bank" },
  { value: "The Sabarkantha Jilla Madhyastha Sahakari Bank Ltd", label: "The Sabarkantha Jilla Madhyastha Sahakari Bank Ltd" },
  { value: "The Sabarmati Co-Op Bank Ltd.", label: "The Sabarmati Co-Op Bank Ltd." },
  { value: "The Sacheshver Nag Sharafi Sah Mandali Ltd", label: "The Sacheshver Nag Sharafi Sah Mandali Ltd" },
  { value: "The Sachin Industrial Co-Op. Bank Ltd", label: "The Sachin Industrial Co-Op. Bank Ltd" },
  { value: "The Sadalga Urban Co-Op. Bank Ltd.", label: "The Sadalga Urban Co-Op. Bank Ltd." },
  { value: "The Saebrao Deshmukh Co Op Bank", label: "The Saebrao Deshmukh Co Op Bank" },
  { value: "The Safe Co-Op Bank Ltd", label: "The Safe Co-Op Bank Ltd" },
  { value: "The Safe Co-Op Bank Ltd., Mumbai", label: "The Safe Co-Op Bank Ltd., Mumbai" },
  { value: "The Sahakari Mini Bank", label: "The Sahakari Mini Bank" },
  { value: "The Sahara ( Minority ) Co-Op Credit Society Ltd.", label: "The Sahara ( Minority ) Co-Op Credit Society Ltd." },
  { value: "The Sahayog Urban Co-Op. Bank Ltd.", label: "The Sahayog Urban Co-Op. Bank Ltd." },
  { value: "The Sahebrao Deshmukh Co Op Bank Ltd", label: "The Sahebrao Deshmukh Co Op Bank Ltd" },
  { value: "The Sahebrao Deshmukh Co Op Hsg Soc", label: "The Sahebrao Deshmukh Co Op Hsg Soc" },
  { value: "The Sahebrao Deshmukh Co-Op Bank Ltd", label: "The Sahebrao Deshmukh Co-Op Bank Ltd" },
  { value: "The Sahu Cooperative Urban Thrift", label: "The Sahu Cooperative Urban Thrift" },
  { value: "The Sahyadri Co-Op Credit Society Ltd Belgaum", label: "The Sahyadri Co-Op Credit Society Ltd Belgaum" },
  { value: "The Sahyadri Multi State Co-Operative Credit Society Ltd", label: "The Sahyadri Multi State Co-Operative Credit Society Ltd" },
  { value: "The Sahyadri Sahakari Bank Ltd.", label: "The Sahyadri Sahakari Bank Ltd." },
  { value: "The Sahyog Coop Credit Soc Ltd", label: "The Sahyog Coop Credit Soc Ltd" },
  { value: "The Sahyog Credit And Supply Sahakari Mandali Ltd", label: "The Sahyog Credit And Supply Sahakari Mandali Ltd" },
  { value: "The Sai Co.Op Urban Bank Ltd", label: "The Sai Co.Op Urban Bank Ltd" },
  { value: "The Sakthi Primary Agricultural Co-Op Bk", label: "The Sakthi Primary Agricultural Co-Op Bk" },
  { value: "The Sakura Bank Limited", label: "The Sakura Bank Limited" },
  { value: "The Salal S. Nagarik Sahakari Bank Ltd", label: "The Salal S. Nagarik Sahakari Bank Ltd" },
  { value: "The Salem Central Co-Op.Bank Ltd.", label: "The Salem Central Co-Op.Bank Ltd." },
  { value: "The Salem Dist. Central Co-Op. Bank Ltd.", label: "The Salem Dist. Central Co-Op. Bank Ltd." },
  { value: "The Salem Urban Co-Op. Bank Ltd.", label: "The Salem Urban Co-Op. Bank Ltd." },
  { value: "The Salgli Bank Ltd", label: "The Salgli Bank Ltd" },
  { value: "The Salur Co-Operative Urban Bank Ltd.", label: "The Salur Co-Operative Urban Bank Ltd." },
  { value: "The Samarth Urban Co-Op Bank Ltd", label: "The Samarth Urban Co-Op Bank Ltd" },
  { value: "The Samastipur Dis. Central Co-Op Bak.Lt", label: "The Samastipur Dis. Central Co-Op Bak.Lt" },
  { value: "The Samata Sahakari Bank Ltd.", label: "The Samata Sahakari Bank Ltd." },
  { value: "The Sambalpur Dist C.O.C.B.L,", label: "The Sambalpur Dist C.O.C.B.L," },
  { value: "The Sametri Seva Shakari Mandali Limited", label: "The Sametri Seva Shakari Mandali Limited" },
  { value: "The Samrao Vithal Co-Op Bank Ltd", label: "The Samrao Vithal Co-Op Bank Ltd" },
  { value: "The Sangamner Merchants Co-Op. Bank Ltd", label: "The Sangamner Merchants Co-Op. Bank Ltd" },
  { value: "The Sanghamitra Co-Op. Ur. Bank Ltd.", label: "The Sanghamitra Co-Op. Ur. Bank Ltd." },
  { value: "The Sangli Bank Ltd.", label: "The Sangli Bank Ltd." },
  { value: "The Sangli Dist. Central Co-Op Bank Ltd.", label: "The Sangli Dist. Central Co-Op Bank Ltd." },
  { value: "The Sangrur Central Co-Op. Bank", label: "The Sangrur Central Co-Op. Bank" },
  { value: "The Sangrur Central Co. Op Bank", label: "The Sangrur Central Co. Op Bank" },
  { value: "The Sanjeevani Gra Shetti Sah Pat Sanstha Marg", label: "The Sanjeevani Gra Shetti Sah Pat Sanstha Marg" },
  { value: "The Sanjeli Urban Co Op Credit Society Ltd", label: "The Sanjeli Urban Co Op Credit Society Ltd" },
  { value: "The Sankari Co-Op. Urban Bank Ltd.", label: "The Sankari Co-Op. Urban Bank Ltd." },
  { value: "The Sankeshwar Souhand Credit Sahakar Ltd", label: "The Sankeshwar Souhand Credit Sahakar Ltd" },
  { value: "The Sankeshwara Souharda Credit Sahakar Ltd", label: "The Sankeshwara Souharda Credit Sahakar Ltd" },
  { value: "The Sankheda Nagarik Sahakari Bank Ltd.", label: "The Sankheda Nagarik Sahakari Bank Ltd." },
  { value: "The Santhigram Service Co.Op. Bank Ltd.", label: "The Santhigram Service Co.Op. Bank Ltd." },
  { value: "The Santragachi Co-Op Bank Ltd", label: "The Santragachi Co-Op Bank Ltd" },
  { value: "The Santrampur Urban Co Op Bank Ltd", label: "The Santrampur Urban Co Op Bank Ltd" },
  { value: "The Santrampur Urban Co-Op. Bank Ltd", label: "The Santrampur Urban Co-Op. Bank Ltd" },
  { value: "The Santrampur Urban Co-Operative Bank Ltd", label: "The Santrampur Urban Co-Operative Bank Ltd" },
  { value: "The Santrampur Urban Coop Bank Ltd,", label: "The Santrampur Urban Coop Bank Ltd," },
  { value: "The Sanwa Bank Limited", label: "The Sanwa Bank Limited" },
  { value: "The Sanwa Bank Ltd.", label: "The Sanwa Bank Ltd." },
  { value: "The Sarangpur Co-Operative Bank Ltd", label: "The Sarangpur Co-Operative Bank Ltd" },
  { value: "The Saraspur Nagarik Shakari Bank Ltd", label: "The Saraspur Nagarik Shakari Bank Ltd" },
  { value: "The Saraswat Co-Op Bank Ltd", label: "The Saraswat Co-Op Bank Ltd" },
  { value: "The Saraswat Coop Bank Ltd", label: "The Saraswat Coop Bank Ltd" },
  { value: "The Saraswati Indl Syndicate Ltd", label: "The Saraswati Indl Syndicate Ltd" },
  { value: "The Sardar Co-Op Credit Society Ltd", label: "The Sardar Co-Op Credit Society Ltd" },
  { value: "The Sardar Patel Co.Op Society Ltd.", label: "The Sardar Patel Co.Op Society Ltd." },
  { value: "The Sardargunj Mercantile Co-Op Bank Ltd", label: "The Sardargunj Mercantile Co-Op Bank Ltd" },
  { value: "The Sarmik Sahakari Bank", label: "The Sarmik Sahakari Bank" },
  { value: "The Sarsa Peoples Co-Op Bank Ltd.", label: "The Sarsa Peoples Co-Op Bank Ltd." },
  { value: "The Sarvoday Nagarik Sahakari Bank Ltd.", label: "The Sarvoday Nagarik Sahakari Bank Ltd." },
  { value: "The Sarvoday Nagrik Sha Bank", label: "The Sarvoday Nagrik Sha Bank" },
  { value: "The Sarvoday Sahakari Mandli Ltd", label: "The Sarvoday Sahakari Mandli Ltd" },
  { value: "The Sarvoday Sahkari Sharafi Mandli Ltd", label: "The Sarvoday Sahkari Sharafi Mandli Ltd" },
  { value: "The Sarvodaya Co-Op. Bank Ltd", label: "The Sarvodaya Co-Op. Bank Ltd" },
  { value: "The Sarvodaya Nagrik Sahakari Bank Ltd", label: "The Sarvodaya Nagrik Sahakari Bank Ltd" },
  { value: "The Sarvodaya Sahakari Bank Ltd.", label: "The Sarvodaya Sahakari Bank Ltd." },
  { value: "The Sarvodya Co Op Credit Society Ltd", label: "The Sarvodya Co Op Credit Society Ltd" },
  { value: "The Sasik Road Deo  Vya Sah Bank Ltdthe Sasik Road Deo  Vya Sah Bank Ltd", label: "The Sasik Road Deo  Vya Sah Bank Ltdthe Sasik Road Deo  Vya Sah Bank Ltd" },
  { value: "The Satana Merchants Co-Op. Bank Ltd.", label: "The Satana Merchants Co-Op. Bank Ltd." },
  { value: "The Satara Dist Merchants Co-Op Bank Ltd", label: "The Satara Dist Merchants Co-Op Bank Ltd" },
  { value: "The Satara Dist. Central Co-Op. Bank Ltd", label: "The Satara Dist. Central Co-Op. Bank Ltd" },
  { value: "The Satara Dist. Merchants Co-Op.Bk.Ltd", label: "The Satara Dist. Merchants Co-Op.Bk.Ltd" },
  { value: "The Satara District Co Op Bank", label: "The Satara District Co Op Bank" },
  { value: "The Satara Merchant`S Co-Op. Bank Ltd.", label: "The Satara Merchant`S Co-Op. Bank Ltd." },
  { value: "The Satara Merchants Co-Op. Bank Ltd", label: "The Satara Merchants Co-Op. Bank Ltd" },
  { value: "The Satara Sah Bank Ltd", label: "The Satara Sah Bank Ltd" },
  { value: "The Satara Sahakari Bank Ltd.", label: "The Satara Sahakari Bank Ltd." },
  { value: "The Satara Sahakari Bank Ltd., Mumbai", label: "The Satara Sahakari Bank Ltd., Mumbai" },
  { value: "The Sathamba Peoples Co-Operative Bk.L,", label: "The Sathamba Peoples Co-Operative Bk.L," },
  { value: "The Sathyamangalam Co-Op. Urban Bank Ltd", label: "The Sathyamangalam Co-Op. Urban Bank Ltd" },
  { value: "The Saudia British Bank", label: "The Saudia British Bank" },
  { value: "The Saurashtra Co-Op Bank Ltd", label: "The Saurashtra Co-Op Bank Ltd" },
  { value: "The Savanur Urban Co Op Bk Ltd,", label: "The Savanur Urban Co Op Bk Ltd," },
  { value: "The Savanur Urban Co-Op Bank Ltd", label: "The Savanur Urban Co-Op Bank Ltd" },
  { value: "The Secretary Lalit  Kala Akademi", label: "The Secretary Lalit  Kala Akademi" },
  { value: "The Secretary Pca And Rd Bank", label: "The Secretary Pca And Rd Bank" },
  { value: "The Secunderabad Mercantile Co. Ur. Bk L", label: "The Secunderabad Mercantile Co. Ur. Bk L" },
  { value: "The Seethathode Service Co-Op. Bank Ltd.", label: "The Seethathode Service Co-Op. Bank Ltd." },
  { value: "The Senapati Bapat Co-Op Soc Ltd.", label: "The Senapati Bapat Co-Op Soc Ltd." },
  { value: "The Serunderabad Co-Opera Urban Bank Ltd", label: "The Serunderabad Co-Opera Urban Bank Ltd" },
  { value: "The Service Co-Op Bank Ltd.", label: "The Service Co-Op Bank Ltd." },
  { value: "The Seva Co.Op Urban Bank Ltd", label: "The Seva Co.Op Urban Bank Ltd" },
  { value: "The Seva Vikas Co-Op. Bank Ltd.", label: "The Seva Vikas Co-Op. Bank Ltd." },
  { value: "The Seva Vikas Co-Op. Bank Ltd., Pune", label: "The Seva Vikas Co-Op. Bank Ltd., Pune" },
  { value: "The Sevalia Urban C-Op Bank Ltd.", label: "The Sevalia Urban C-Op Bank Ltd." },
  { value: "The Sevaliya Nagarik Co-Op.Cre. Soc.Ltd.", label: "The Sevaliya Nagarik Co-Op.Cre. Soc.Ltd." },
  { value: "The Seyoor Co-Operative Agri Bank Ltd", label: "The Seyoor Co-Operative Agri Bank Ltd" },
  { value: "The Shahada Peoples Co-Op Bank Ltd.", label: "The Shahada Peoples Co-Op Bank Ltd." },
  { value: "The Shamkar Comps City", label: "The Shamkar Comps City" },
  { value: "The Shamrao Vithal Chs Bank Ltd.", label: "The Shamrao Vithal Chs Bank Ltd." },
  { value: "The Shamrao Vithal Co-Op Bank Ltd", label: "The Shamrao Vithal Co-Op Bank Ltd" },
  { value: "The Shamrao Vithal Coop Bk Ltd", label: "The Shamrao Vithal Coop Bk Ltd" },
  { value: "The Sharamdeep Urban Co Op Credit Society", label: "The Sharamdeep Urban Co Op Credit Society" },
  { value: "The Sharamdeep Urban Co-Op Credit Society Ltd", label: "The Sharamdeep Urban Co-Op Credit Society Ltd" },
  { value: "The Shedbal Urban Co-Operative Credit Bank Ltd.", label: "The Shedbal Urban Co-Operative Credit Bank Ltd." },
  { value: "The Shegaon Agrasen Co. Op. Socity Ltd.", label: "The Shegaon Agrasen Co. Op. Socity Ltd." },
  { value: "The Shegaon Shree Agresen Sahakari Path Santha Mar", label: "The Shegaon Shree Agresen Sahakari Path Santha Mar" },
  { value: "The Shegoan Shree Agrasen Co-Op. Cred.So", label: "The Shegoan Shree Agrasen Co-Op. Cred.So" },
  { value: "The Shertallay Co-Op. Urban Bank Ltd.", label: "The Shertallay Co-Op. Urban Bank Ltd." },
  { value: "The Shertally Co-Op. Urban Bank Ltd.", label: "The Shertally Co-Op. Urban Bank Ltd." },
  { value: "The Shevapet Urban Co-Operative Bank Ltd", label: "The Shevapet Urban Co-Operative Bank Ltd" },
  { value: "The Shibpur Co-Operative Bank Ltd.", label: "The Shibpur Co-Operative Bank Ltd." },
  { value: "The Shiggaon Urban Co-Op.Bank Ltd.", label: "The Shiggaon Urban Co-Op.Bank Ltd." },
  { value: "Shihori Nagrik Sahakari Bank Ltd.", label: "Shihori Nagrik Sahakari Bank Ltd." },
  { value: "The Shillong Co-Op Urban Bank Ltd.", label: "The Shillong Co-Op Urban Bank Ltd." },
  { value: "The Shimoga Arecanut Merch. Co. Bk  Ltd", label: "The Shimoga Arecanut Merch. Co. Bk  Ltd" },
  { value: "The Shimoga Dist. Co-Op Central Bank Ltd", label: "The Shimoga Dist. Co-Op Central Bank Ltd" },
  { value: "The Shimoga Old Motors Parts Dealers Industrial Co- Operative Society", label: "The Shimoga Old Motors Parts Dealers Industrial Co- Operative Society" },
  { value: "The Shipping Corporation India Ltd", label: "The Shipping Corporation India Ltd" },
  { value: "The Shiroda Urban Co-Op.Credit Soc. Ltd.", label: "The Shiroda Urban Co-Op.Credit Soc. Ltd." },
  { value: "The Shirpur Merchant`S Co-Op Bank Ltd.", label: "The Shirpur Merchant`S Co-Op Bank Ltd." },
  { value: "The Shirpur Peoples Co.Op. Bank Ltd", label: "The Shirpur Peoples Co.Op. Bank Ltd" },
  { value: "The Shivaji-Nagar Co-Op Bank Ltd., Pune", label: "The Shivaji-Nagar Co-Op Bank Ltd., Pune" },
  { value: "The Sholapur Dist.Cent.Co-Op.Bank Ltd.", label: "The Sholapur Dist.Cent.Co-Op.Bank Ltd." },
  { value: "The Sholapur District Central Co-Op. Bank Ltd  Solapur", label: "The Sholapur District Central Co-Op. Bank Ltd  Solapur" },
  { value: "The Sholapur Social Urban C.O.B.L,", label: "The Sholapur Social Urban C.O.B.L," },
  { value: "The Sholapur Social Urban Co-Op Bank Ltd Solapur", label: "The Sholapur Social Urban Co-Op Bank Ltd Solapur" },
  { value: "The Sholapur Social Urban Co-Op.Bank Ltd", label: "The Sholapur Social Urban Co-Op.Bank Ltd" },
  { value: "The Sholinghur Co-Op Urban Bk Ltd,", label: "The Sholinghur Co-Op Urban Bk Ltd," },
  { value: "The Sholinghur Co-Op. Urban Bank Ltd", label: "The Sholinghur Co-Op. Urban Bank Ltd" },
  { value: "The Shoranur Co-Op. Service Bank Ltd.", label: "The Shoranur Co-Op. Service Bank Ltd." },
  { value: "The Shoranur Co-Op. Urban Bank Ltd.", label: "The Shoranur Co-Op. Urban Bank Ltd." },
  { value: "The Shramjivi Vividharmi Dhiwan", label: "The Shramjivi Vividharmi Dhiwan" },
  { value: "The Shramjivi Vividharmi Dhiwan And Goakah Sah Mandali", label: "The Shramjivi Vividharmi Dhiwan And Goakah Sah Mandali" },
  { value: "The Shramjivi Vividhlaxi Dhiran And Grahak Sahkari Mandli Ltd", label: "The Shramjivi Vividhlaxi Dhiran And Grahak Sahkari Mandli Ltd" },
  { value: "The Shree Suvarna Sahakari Bank Ltd.", label: "The Shree Suvarna Sahakari Bank Ltd." },
  { value: "The Shriram Sahakari Bank Ltd.", label: "The Shriram Sahakari Bank Ltd." },
  { value: "The Shrirampur Peoples Co-Op Bank Ltd.", label: "The Shrirampur Peoples Co-Op Bank Ltd." },
  { value: "The Shrumik Co-Op Cr Society Ltd", label: "The Shrumik Co-Op Cr Society Ltd" },
  { value: "The Shrumjivi Vividhaxi And Sun Mandli Ltd.", label: "The Shrumjivi Vividhaxi And Sun Mandli Ltd." },
  { value: "The Siam Commercial Bank", label: "The Siam Commercial Bank" },
  { value: "The Sidhpur Commercial Co.Op. Bank Ltd", label: "The Sidhpur Commercial Co.Op. Bank Ltd" },
  { value: "The Simla Urban Co Operative Bank Ltd", label: "The Simla Urban Co Operative Bank Ltd" },
  { value: "The Simla Urban Co.Op Bank Ltd", label: "The Simla Urban Co.Op Bank Ltd" },
  { value: "The Sind Co-Operative Urban Bank Ltd", label: "The Sind Co-Operative Urban Bank Ltd" },
  { value: "The Sindgi Urban Co-Op. Bank Ltd", label: "The Sindgi Urban Co-Op. Bank Ltd" },
  { value: "The Sindh Mercantile Co-Op Bank Ltd.", label: "The Sindh Mercantile Co-Op Bank Ltd." },
  { value: "The Sindh Mercantile Co-Operative Bank L", label: "The Sindh Mercantile Co-Operative Bank L" },
  { value: "The Singhbhum Dist Cent Coop Bank Ltd.", label: "The Singhbhum Dist Cent Coop Bank Ltd." },
  { value: "The Singhbhum Dist Central Co-Op Bank", label: "The Singhbhum Dist Central Co-Op Bank" },
  { value: "The Sinnar Vyapari Sah Bank Ltd", label: "The Sinnar Vyapari Sah Bank Ltd" },
  { value: "The Sinor Nagarik Sahakari Bank Ltd.", label: "The Sinor Nagarik Sahakari Bank Ltd." },
  { value: "The Sircilla Co- Op Urban Bank Ltd", label: "The Sircilla Co- Op Urban Bank Ltd" },
  { value: "The Sirohi Central Co-Op. Bank Ltd.", label: "The Sirohi Central Co-Op. Bank Ltd." },
  { value: "The Sirohi Dist Commercial Co-Op Bank Lt", label: "The Sirohi Dist Commercial Co-Op Bank Lt" },
  { value: "The Sirsa Central Co-Op. Bank Ltd.", label: "The Sirsa Central Co-Op. Bank Ltd." },
  { value: "The Sirsi Araban Sohardha Bank Ltd", label: "The Sirsi Araban Sohardha Bank Ltd" },
  { value: "The Sirsi Urban Co-Operative Bank Ltd", label: "The Sirsi Urban Co-Operative Bank Ltd" },
  { value: "The Sirsi Urban Sahakari Bank Ltd", label: "The Sirsi Urban Sahakari Bank Ltd" },
  { value: "The Sirsi Urban Sosharda Sahakari Bk Ltd", label: "The Sirsi Urban Sosharda Sahakari Bk Ltd" },
  { value: "The Sirsi Urban Souharda Sahkari Bk L,", label: "The Sirsi Urban Souharda Sahkari Bk L," },
  { value: "The Sitamarhi Central Co-Op Bank Ltd.", label: "The Sitamarhi Central Co-Op Bank Ltd." },
  { value: "The Sitara Co-Operative Urban Bank Ltd.", label: "The Sitara Co-Operative Urban Bank Ltd." },
  { value: "The Sivagangai Dist. Cent. Co.Op. Bk Ltd", label: "The Sivagangai Dist. Cent. Co.Op. Bk Ltd" },
  { value: "The Sivakasi Co.Op. Urban Bank Ltd.", label: "The Sivakasi Co.Op. Urban Bank Ltd." },
  { value: "The Smruti Multi Purpose Co Op Ltd.", label: "The Smruti Multi Purpose Co Op Ltd." },
  { value: "The Social Co-Op Bank Ltd.", label: "The Social Co-Op Bank Ltd." },
  { value: "The Sojitra Co-Op Bank Ltd.", label: "The Sojitra Co-Op Bank Ltd." },
  { value: "The Somanathapura Vyavasaya Seva Sh Bk L", label: "The Somanathapura Vyavasaya Seva Sh Bk L" },
  { value: "The Somavamsha Sahasrarjuna Ks.Co So.Ltd", label: "The Somavamsha Sahasrarjuna Ks.Co So.Ltd" },
  { value: "The Sonepat Central Co-Op. Bank Ltd", label: "The Sonepat Central Co-Op. Bank Ltd" },
  { value: "The Sonepat Urabn Co-Op. Bank Ltd.", label: "The Sonepat Urabn Co-Op. Bank Ltd." },
  { value: "The South Arcot Dist Cent.Co-Op.Bank Ltd", label: "The South Arcot Dist Cent.Co-Op.Bank Ltd" },
  { value: "The South Arcot Dist. Central Co.Bk Ltd", label: "The South Arcot Dist. Central Co.Bk Ltd" },
  { value: "The South Canara Dist Central Co-Op Bank", label: "The South Canara Dist Central Co-Op Bank" },
  { value: "The South Canara Dist.Central Co-Op Bank Ltd., Mangalore", label: "The South Canara Dist.Central Co-Op Bank Ltd., Mangalore" },
  { value: "The South Canara District Central Co-Op Bank Ltd - Belvai", label: "The South Canara District Central Co-Op Bank Ltd - Belvai" },
  { value: "The South Canara District Central Co-Op Bank Ltd - Karkala Branch.", label: "The South Canara District Central Co-Op Bank Ltd - Karkala Branch." },
  { value: "The South India Textile Research Association", label: "The South India Textile Research Association" },
  { value: "The South Indian Bank", label: "The South Indian Bank" },
  { value: "The South Indian Bank Ltd.", label: "The South Indian Bank Ltd." },
  { value: "The Srivilliputtur Co-Op Urban Bank Ltd", label: "The Srivilliputtur Co-Op Urban Bank Ltd" },
  { value: "The Srivilliputtur Co-Op. Urban Bank Ltd", label: "The Srivilliputtur Co-Op. Urban Bank Ltd" },
  { value: "The Stambhadri Co-Op Urban Bank Ltd.", label: "The Stambhadri Co-Op Urban Bank Ltd." },
  { value: "The State Co-Operative Bank - Nahan", label: "The State Co-Operative Bank - Nahan" },
  { value: "The Stc Of India Ltd.", label: "The Stc Of India Ltd." },
  { value: "The Subramanianagar Co-Op Urban Bank Ltd", label: "The Subramanianagar Co-Op Urban Bank Ltd" },
  { value: "The Sukhsagar Co-Op Credit Soc Ltd", label: "The Sukhsagar Co-Op Credit Soc Ltd" },
  { value: "The Sullai Co-Op Agricultural Bank", label: "The Sullai Co-Op Agricultural Bank" },
  { value: "The Sullia Co-Op. Agricultural Bank Ltd.", label: "The Sullia Co-Op. Agricultural Bank Ltd." },
  { value: "The Sultans Battery Co-Op Urban Bank Lt", label: "The Sultans Battery Co-Op Urban Bank Lt" },
  { value: "The Sultanpur District Co-Op Bank Ltd", label: "The Sultanpur District Co-Op Bank Ltd" },
  { value: "The Sulthan Bathery Co-Operative Urban Bank Ltd", label: "The Sulthan Bathery Co-Operative Urban Bank Ltd" },
  { value: "The Sulthan Bathery Service Co-Op Bank L", label: "The Sulthan Bathery Service Co-Op Bank L" },
  { value: "The Sulthan Bathery Service Co. Bk Ltd.", label: "The Sulthan Bathery Service Co. Bk Ltd." },
  { value: "The Sumitomo Bank Limited", label: "The Sumitomo Bank Limited" },
  { value: "The Sunav Nagrik Sahakari Bank Ltd", label: "The Sunav Nagrik Sahakari Bank Ltd" },
  { value: "The Sundargarh Dist Cen Co-Op Bank Ltd.", label: "The Sundargarh Dist Cen Co-Op Bank Ltd." },
  { value: "The Supreme Co Op Credit Soc Ltd", label: "The Supreme Co Op Credit Soc Ltd" },
  { value: "The Surat Dist. Coop. Bank Ltd", label: "The Surat Dist. Coop. Bank Ltd" },
  { value: "The Surat District Co-Op. Bank Ltd", label: "The Surat District Co-Op. Bank Ltd" },
  { value: "The Surat District Co-Op. Bank Ltd.", label: "The Surat District Co-Op. Bank Ltd." },
  { value: "The Surat Elec Co Staff Co-Op Credit Ltd", label: "The Surat Elec Co Staff Co-Op Credit Ltd" },
  { value: "The Surat Electricity Co Staff Co", label: "The Surat Electricity Co Staff Co" },
  { value: "The Surat Mahila Nagrik Sahakari Bank Lt", label: "The Surat Mahila Nagrik Sahakari Bank Lt" },
  { value: "The Surat Mercantile Co-Op. Bank Ltd.", label: "The Surat Mercantile Co-Op. Bank Ltd." },
  { value: "The Surat Mercentile Co Op Bank Ltd", label: "The Surat Mercentile Co Op Bank Ltd" },
  { value: "The Surat Municipal Corporation Credit Society Ltd", label: "The Surat Municipal Corporation Credit Society Ltd" },
  { value: "The Surat Peoples Co-Op Bank Ltd.", label: "The Surat Peoples Co-Op Bank Ltd." },
  { value: "The Surat Textiletraders Co-Op Bank Ltd", label: "The Surat Textiletraders Co-Op Bank Ltd" },
  { value: "The Surendranagar Dist Co-Op Bank Ltd.", label: "The Surendranagar Dist Co-Op Bank Ltd." },
  { value: "The Surendranagar Dist. Co.Op. Bank Ltd", label: "The Surendranagar Dist. Co.Op. Bank Ltd" },
  { value: "The Surendranagar Mercantile Coop Bk Ltd", label: "The Surendranagar Mercantile Coop Bk Ltd" },
  { value: "The Surendranagar Peoples Co. Bank Ltd", label: "The Surendranagar Peoples Co. Bank Ltd" },
  { value: "The Sushil Co Op Credit Society Ltd.", label: "The Sushil Co Op Credit Society Ltd." },
  { value: "The Sutex Co-Operative Bank Ltd.", label: "The Sutex Co-Operative Bank Ltd." },
  { value: "The Suvarna Karanataka Credit Souhardha Co-Operative Ltd", label: "The Suvarna Karanataka Credit Souhardha Co-Operative Ltd" },
  { value: "The Suvikas Peoples Co-Op. Bank Ltd.", label: "The Suvikas Peoples Co-Op. Bank Ltd." },
  { value: "The Swaraj Urban Co-Op. Bank", label: "The Swaraj Urban Co-Op. Bank" },
  { value: "The Swarna Co-Op Urban Bank Ltd", label: "The Swarna Co-Op Urban Bank Ltd" },
  { value: "The Swasakthi Mercantile Co.Urban Bk Ltd", label: "The Swasakthi Mercantile Co.Urban Bk Ltd" },
  { value: "The Swaty Electricity Comapany Staff Co-Op Credit Soc", label: "The Swaty Electricity Comapany Staff Co-Op Credit Soc" },
  { value: "The T.S.Dt. Cental Co-Op Bank Ltd.", label: "The T.S.Dt. Cental Co-Op Bank Ltd." },
  { value: "The T.S.Dt. Central Co-Op.Bank Ltd.", label: "The T.S.Dt. Central Co-Op.Bank Ltd." },
  { value: "The Talikoti Sahakari Bank Ltd", label: "The Talikoti Sahakari Bank Ltd" },
  { value: "The Talikoti Urban Co Op Bank", label: "The Talikoti Urban Co Op Bank" },
  { value: "The Taliparamba Govt. Employ C Society L", label: "The Taliparamba Govt. Employ C Society L" },
  { value: "The Taliparmba C.O.U.B.L", label: "The Taliparmba C.O.U.B.L" },
  { value: "The Talod Nagarik Sahakari Bank Ltd.", label: "The Talod Nagarik Sahakari Bank Ltd." },
  { value: "The Tambaram Co-Op. Urban Bank Ltd.", label: "The Tambaram Co-Op. Urban Bank Ltd." },
  { value: "The Tamil Nadu Ind Co-Op Bk L", label: "The Tamil Nadu Ind Co-Op Bk L" },
  { value: "The Tamil Nadu Industrial Co-Op.Bank Ltd", label: "The Tamil Nadu Industrial Co-Op.Bank Ltd" },
  { value: "The Tamil Nadu State Apex Co-Op Bank Ltd", label: "The Tamil Nadu State Apex Co-Op Bank Ltd" },
  { value: "The Tamil Nadu State Co-Op. Bank Ltd.", label: "The Tamil Nadu State Co-Op. Bank Ltd." },
  { value: "The Tamilnadu Industrial Co-Op. Bk. Ltd., Chennai", label: "The Tamilnadu Industrial Co-Op. Bk. Ltd., Chennai" },
  { value: "The Tamilnadu State Apex Co Operative Bank Ltdc", label: "The Tamilnadu State Apex Co Operative Bank Ltdc" },
  { value: "The Tamilnadu State Apex Coop Bank Ltd", label: "The Tamilnadu State Apex Coop Bank Ltd" },
  { value: "The Tanalur Ser. Co-Op. Bank Ltd.,", label: "The Tanalur Ser. Co-Op. Bank Ltd.," },
  { value: "The Tanur Service Co-Op Bank Ltd.", label: "The Tanur Service Co-Op Bank Ltd." },
  { value: "The Tapindu Urban Co-Op Bank Ltd.", label: "The Tapindu Urban Co-Op Bank Ltd." },
  { value: "The Taran Taran Central Co-Op Bank Ltd", label: "The Taran Taran Central Co-Op Bank Ltd" },
  { value: "The Tarapur Co-Op Urban Bank Ltd.", label: "The Tarapur Co-Op Urban Bank Ltd." },
  { value: "The Tarapur Mahila Utkarsh Cre & Cons Co Op Soc Ltd", label: "The Tarapur Mahila Utkarsh Cre & Cons Co Op Soc Ltd" },
  { value: "The Tarapur Nagrik Co Op Bank", label: "The Tarapur Nagrik Co Op Bank" },
  { value: "The Tarapur Nagrik Co Op Credit Soc", label: "The Tarapur Nagrik Co Op Credit Soc" },
  { value: "The Tasgaon Urban Co-Op Bank Ltd.", label: "The Tasgaon Urban Co-Op Bank Ltd." },
  { value: "The Tattamangalam Service Co-Op Bank Ltd", label: "The Tattamangalam Service Co-Op Bank Ltd" },
  { value: "The Tcy District Co Operative Bank Ltd", label: "The Tcy District Co Operative Bank Ltd" },
  { value: "The Tellicherry Co-Op. Urban Bank Ltd.", label: "The Tellicherry Co-Op. Urban Bank Ltd." },
  { value: "The Tellicherry Co.Op.Town Bank Ltd.", label: "The Tellicherry Co.Op.Town Bank Ltd." },
  { value: "The Tellichery Town Service Co-Op Bank L", label: "The Tellichery Town Service Co-Op Bank L" },
  { value: "The Teradal Minority Cooperation Society", label: "The Teradal Minority Cooperation Society" },
  { value: "The Terdal Souharda Co-Operative Bank Ltd, Hanagandi Branch", label: "The Terdal Souharda Co-Operative Bank Ltd, Hanagandi Branch" },
  { value: "The Textile Co-Op Bank Ltd.", label: "The Textile Co-Op Bank Ltd." },
  { value: "The Textile Co-Op Bank Of Surat Ltd", label: "The Textile Co-Op Bank Of Surat Ltd" },
  { value: "The Textile Co-Op. Bank Of Surat Ltd", label: "The Textile Co-Op. Bank Of Surat Ltd" },
  { value: "The Textile Manufacturers Coop.Bank Ltd", label: "The Textile Manufacturers Coop.Bank Ltd" },
  { value: "The Textile Processors Co-Operative Bk.L", label: "The Textile Processors Co-Operative Bk.L" },
  { value: "The Thachampara Service Co-Op Bank Ltd.", label: "The Thachampara Service Co-Op Bank Ltd." },
  { value: "The Thadikkadavu Service Co-Op. Bank Ltd", label: "The Thadikkadavu Service Co-Op. Bank Ltd" },
  { value: "The Thadukkassery Service Co-Op Bank Ltd", label: "The Thadukkassery Service Co-Op Bank Ltd" },
  { value: "The Thalakkad Service Co - Op Bank Ltd", label: "The Thalakkad Service Co - Op Bank Ltd" },
  { value: "The Thalappalam Ser. Co-Op Bank Ltd,", label: "The Thalappalam Ser. Co-Op Bank Ltd," },
  { value: "The Thalappalam Service Co-Op Bank Ltd", label: "The Thalappalam Service Co-Op Bank Ltd" },
  { value: "The Thalayazhom Service Co-Op Bank Ltd.", label: "The Thalayazhom Service Co-Op Bank Ltd." },
  { value: "The Thalore Services Co-Op Bank", label: "The Thalore Services Co-Op Bank" },
  { value: "The Thamarassery Service Co-Op. Bank Ltd", label: "The Thamarassery Service Co-Op. Bank Ltd" },
  { value: "The Thane Bharat Co-Op Bank Ltd.", label: "The Thane Bharat Co-Op Bank Ltd." },
  { value: "The Thane Dist Central Coop Bank Ltd", label: "The Thane Dist Central Coop Bank Ltd" },
  { value: "The Thane Dist Co Op Bank Ltd", label: "The Thane Dist Co Op Bank Ltd" },
  { value: "The Thane District Central Co-Op Bank Lt", label: "The Thane District Central Co-Op Bank Lt" },
  { value: "The Thane District Central Coop Bank Ltd", label: "The Thane District Central Coop Bank Ltd" },
  { value: "The Thane Janata Sahakari Bank Ltd.", label: "The Thane Janata Sahakari Bank Ltd." },
  { value: "The Thane Janta Sahakari Bank Ltd", label: "The Thane Janta Sahakari Bank Ltd" },
  { value: "The Thanjavur Central Co-Op. Bank Ltd.", label: "The Thanjavur Central Co-Op. Bank Ltd." },
  { value: "The Thanjavur Dist. Cent.Co.Bk.Ltd.", label: "The Thanjavur Dist. Cent.Co.Bk.Ltd." },
  { value: "The Thariyode Services Cooperatives Bank", label: "The Thariyode Services Cooperatives Bank" },
  { value: "The Tharuvana Service Co. Bank Ltd", label: "The Tharuvana Service Co. Bank Ltd" },
  { value: "The Thavanur Service Co-Op Bank Ltd.", label: "The Thavanur Service Co-Op Bank Ltd." },
  { value: "The Thavinhal Services Co-Op Bank,", label: "The Thavinhal Services Co-Op Bank," },
  { value: "The Thazhekode Ser.Co-Op.Bk.L.", label: "The Thazhekode Ser.Co-Op.Bk.L." },
  { value: "The Thenhipalam Service Co-Op. Bank Ltd.", label: "The Thenhipalam Service Co-Op. Bank Ltd." },
  { value: "The Thidanad Service Co-Operative Bk Ltd", label: "The Thidanad Service Co-Operative Bk Ltd" },
  { value: "The Thimiri Services Co-Op Bank Ltd", label: "The Thimiri Services Co-Op Bank Ltd" },
  { value: "The Thindal Malai Pacb Ltd", label: "The Thindal Malai Pacb Ltd" },
  { value: "The Thirumarayoor Ser.C-P.Bk.Ltd,", label: "The Thirumarayoor Ser.C-P.Bk.Ltd," },
  { value: "The Thirumittacode Service Co-Op Bank Lt", label: "The Thirumittacode Service Co-Op Bank Lt" },
  { value: "The Thirunelly Service Co-Op Bank Ltd", label: "The Thirunelly Service Co-Op Bank Ltd" },
  { value: "The Thiruvalla Service Co-Op Bank Ltd", label: "The Thiruvalla Service Co-Op Bank Ltd" },
  { value: "The Thiruvambady Services Co-Op Bank Ltd", label: "The Thiruvambady Services Co-Op Bank Ltd" },
  { value: "The Thiruvananthapuram Ser. Co-Op.Bk Ltd", label: "The Thiruvananthapuram Ser. Co-Op.Bk Ltd" },
  { value: "The Thiruvananthapuram Ser.Co-Op.B.L.", label: "The Thiruvananthapuram Ser.Co-Op.B.L." },
  { value: "The Thiruvanchoor Service Co-Op Bank Ltd", label: "The Thiruvanchoor Service Co-Op Bank Ltd" },
  { value: "The Thiruvathra Service Co-Op Bank Ltd", label: "The Thiruvathra Service Co-Op Bank Ltd" },
  { value: "The Tho[Rankudy Ser.Cop-Op.Bank Ltd.", label: "The Tho[Rankudy Ser.Cop-Op.Bank Ltd." },
  { value: "The Thodiyil Services Cooperative Bank L", label: "The Thodiyil Services Cooperative Bank L" },
  { value: "The Thodiyoor Service Co-Op Bank Ltd.", label: "The Thodiyoor Service Co-Op Bank Ltd." },
  { value: "The Thodupuzha Primary Co-Operative Ugricultural And Rural Development Bank Ltd", label: "The Thodupuzha Primary Co-Operative Ugricultural And Rural Development Bank Ltd" },
  { value: "The Thodupuzha Ser Co-Op Bk L", label: "The Thodupuzha Ser Co-Op Bk L" },
  { value: "The Thodupuzha Service Co-Opera Bank Ltd", label: "The Thodupuzha Service Co-Opera Bank Ltd" },
  { value: "The Thondiyil Vanitha Co-Op Society Ltd", label: "The Thondiyil Vanitha Co-Op Society Ltd" },
  { value: "The Thoothukudi Dist Cent Co Bk L,", label: "The Thoothukudi Dist Cent Co Bk L," },
  { value: "The Thoothukudi Dt Cen Co Bk L,", label: "The Thoothukudi Dt Cen Co Bk L," },
  { value: "The Thoothukudi Dt Central Co Bank Ltd", label: "The Thoothukudi Dt Central Co Bank Ltd" },
  { value: "The Thoothukudi Dt. Central Co. Bank Ltd", label: "The Thoothukudi Dt. Central Co. Bank Ltd" },
  { value: "The Thoothukudi Dt. Cr. Co.Op. Bank Ltd.", label: "The Thoothukudi Dt. Cr. Co.Op. Bank Ltd." },
  { value: "The Thottapuzhassery R S Co-Op Bk L", label: "The Thottapuzhassery R S Co-Op Bk L" },
  { value: "The Thurayur Ser Co-Op Bank Ltd.", label: "The Thurayur Ser Co-Op Bank Ltd." },
  { value: "The Tibetan Service Coop Bank Ltd", label: "The Tibetan Service Coop Bank Ltd" },
  { value: "The Tindivanam Co-Op. Urban Bank Ltd.", label: "The Tindivanam Co-Op. Urban Bank Ltd." },
  { value: "The Tiruchengode Co-Op. Urban Bank Ltd.", label: "The Tiruchengode Co-Op. Urban Bank Ltd." },
  { value: "The Tiruchirapalli Dist Cen Co-Op Bk Ltd", label: "The Tiruchirapalli Dist Cen Co-Op Bk Ltd" },
  { value: "The Tiruchirapalli Dist Cent C-P Bank L", label: "The Tiruchirapalli Dist Cent C-P Bank L" },
  { value: "The Tirukoilur Co-Op. Bank Ltd.", label: "The Tirukoilur Co-Op. Bank Ltd." },
  { value: "The Tirukoilur Co. Op. Urban Bank Ltd.", label: "The Tirukoilur Co. Op. Urban Bank Ltd." },
  { value: "The Tirunelveli Central Co-Op. Bank Ltd.", label: "The Tirunelveli Central Co-Op. Bank Ltd." },
  { value: "The Tirunelveli Dist.Central Coop.Bk Ltd", label: "The Tirunelveli Dist.Central Coop.Bk Ltd" },
  { value: "The Tirunelveli Jn. Coop Urban Bank Ltd.", label: "The Tirunelveli Jn. Coop Urban Bank Ltd." },
  { value: "The Tirunelveli Kattab.Distcent.Co B.Ltd", label: "The Tirunelveli Kattab.Distcent.Co B.Ltd" },
  { value: "The Tirunelveli Kattabomman Dt.Ct.Co.B.L", label: "The Tirunelveli Kattabomman Dt.Ct.Co.B.L" },
  { value: "The Tirupati Co-Op. Bank Ltd.", label: "The Tirupati Co-Op. Bank Ltd." },
  { value: "The Tirupattur Urban Co-Op. Bank Ltd.", label: "The Tirupattur Urban Co-Op. Bank Ltd." },
  { value: "The Tiruppur Co-Op. Urban Bank Ltd.", label: "The Tiruppur Co-Op. Urban Bank Ltd." },
  { value: "The Tirur Co-Operative Rural Bank Ltd,", label: "The Tirur Co-Operative Rural Bank Ltd," },
  { value: "The Tirur Service Co-Operative Bank Ltd.", label: "The Tirur Service Co-Operative Bank Ltd." },
  { value: "The Tirur Urban Co-Operative Bank Ltd", label: "The Tirur Urban Co-Operative Bank Ltd" },
  { value: "The Tiruturaipundi Co-Op Urban Bank Ltd", label: "The Tiruturaipundi Co-Op Urban Bank Ltd" },
  { value: "The Tiruvalla East Co-Op. Bank Ltd.", label: "The Tiruvalla East Co-Op. Bank Ltd." },
  { value: "The Tiruvalla Urban Co-Op Bank Ltd,", label: "The Tiruvalla Urban Co-Op Bank Ltd," },
  { value: "The Tiruvallur Co-Op Urban Bank Ltd", label: "The Tiruvallur Co-Op Urban Bank Ltd" },
  { value: "The Tiruvannamalai Co-Op Urban Bank Ltd", label: "The Tiruvannamalai Co-Op Urban Bank Ltd" },
  { value: "The Tiruvannamalai Dt Cen Co Bk L", label: "The Tiruvannamalai Dt Cen Co Bk L" },
  { value: "The Tiruvannamalai Dt. Central Co.Bk Ltd", label: "The Tiruvannamalai Dt. Central Co.Bk Ltd" },
  { value: "The Tiruvathipuram Co-Op Urban Bank Ltd.", label: "The Tiruvathipuram Co-Op Urban Bank Ltd." },
  { value: "The Tiruwalla Urban Cooperative Bank Ltd", label: "The Tiruwalla Urban Cooperative Bank Ltd" },
  { value: "The Totagar Co-Op Cradit Society Sirsi", label: "The Totagar Co-Op Cradit Society Sirsi" },
  { value: "The Totagar Co-Op Credit Society", label: "The Totagar Co-Op Credit Society" },
  { value: "The Town Co-Op Bank Ltd.", label: "The Town Co-Op Bank Ltd." },
  { value: "The Town Co-Operative Bank Ltd Sira", label: "The Town Co-Operative Bank Ltd Sira" },
  { value: "Town Co Operative Bank Ltd", label: "Town Co Operative Bank Ltd" },
  { value: "The Travancore Cements Emp. Co. Bank Ltd", label: "The Travancore Cements Emp. Co. Bank Ltd" },
  { value: "The Trichur District Co-Op Bank Ltd.", label: "The Trichur District Co-Op Bank Ltd." },
  { value: "The Trichur Urban Co. Op. Bank Ltd", label: "The Trichur Urban Co. Op. Bank Ltd" },
  { value: "The Trithala Service Co-Op. Bank Ltd", label: "The Trithala Service Co-Op. Bank Ltd" },
  { value: "The Trivandrum Co-Operative Urban Bank L", label: "The Trivandrum Co-Operative Urban Bank L" },
  { value: "The Trivandrum Dist Co - Op Bank Ltd", label: "The Trivandrum Dist Co - Op Bank Ltd" },
  { value: "The Tudiyalur Co-Op Agri Service Ltd.", label: "The Tudiyalur Co-Op Agri Service Ltd." },
  { value: "The Tumkur Dist. Co-Op Central Bank Ltd.", label: "The Tumkur Dist. Co-Op Central Bank Ltd." },
  { value: "The Tumkur District Co-Op. Cr. Bank Ltd.", label: "The Tumkur District Co-Op. Cr. Bank Ltd." },
  { value: "The Tura Urban Co Operative Bank Ltd", label: "The Tura Urban Co Operative Bank Ltd" },
  { value: "The Turaiyur Co - Op Rural Bank Ltd", label: "The Turaiyur Co - Op Rural Bank Ltd" },
  { value: "The Tuticorin Co-Op. Urban Bank Ltd.", label: "The Tuticorin Co-Op. Urban Bank Ltd." },
  { value: "The Tuticorin Melur Co-Op. Bank Ltd", label: "The Tuticorin Melur Co-Op. Bank Ltd" },
  { value: "The Ucha Co-Op Agri Multipurpose S", label: "The Ucha Co-Op Agri Multipurpose S" },
  { value: "The Udaipur Central Co-Operative Bk Ltd", label: "The Udaipur Central Co-Operative Bk Ltd" },
  { value: "The Udaipur Central Coop Bank Ltd", label: "The Udaipur Central Coop Bank Ltd" },
  { value: "The Udaipur Mahila Samridh Urban Cp Bk L", label: "The Udaipur Mahila Samridh Urban Cp Bk L" },
  { value: "The Udaipur Mahila Urban Co-Op Bk Lt", label: "The Udaipur Mahila Urban Co-Op Bk Lt" },
  { value: "The Udaipur Urban Coop Bank Ltd.", label: "The Udaipur Urban Coop Bank Ltd." },
  { value: "The Udamalpet Cooperative Urban Bank Ltd", label: "The Udamalpet Cooperative Urban Bank Ltd" },
  { value: "The Udayagiri Service Co-Op. Bank Ltd.", label: "The Udayagiri Service Co-Op. Bank Ltd." },
  { value: "The Udayanapuram Service Co-Op Bank Ltd", label: "The Udayanapuram Service Co-Op Bank Ltd" },
  { value: "The Udgir Urban Co-Op Bank Ltd.", label: "The Udgir Urban Co-Op Bank Ltd." },
  { value: "The Udgir Urban Co-Operative Bank Ltd", label: "The Udgir Urban Co-Operative Bank Ltd" },
  { value: "The Udhagamandalam Co-Op. Urban Bank Ltd", label: "The Udhagamandalam Co-Op. Urban Bank Ltd" },
  { value: "The Udhana Citizen Co-Op Bank Ltd., Surat", label: "The Udhana Citizen Co-Op Bank Ltd., Surat" },
  { value: "The Udhana Sachin Co Op Bank Ltd.", label: "The Udhana Sachin Co Op Bank Ltd." },
  { value: "The Udhna Citizen Co-Op. Bank Ltd", label: "The Udhna Citizen Co-Op. Bank Ltd" },
  { value: "The Udma Service Co-Operative Bank", label: "The Udma Service Co-Operative Bank" },
  { value: "The Udumbanchola Service C0-Op Bank Ltd.", label: "The Udumbanchola Service C0-Op Bank Ltd." },
  { value: "The Udupi Co-Op Town Bank Ltd", label: "The Udupi Co-Op Town Bank Ltd" },
  { value: "The Udupi Taluk Industrial C. Society L.", label: "The Udupi Taluk Industrial C. Society L." },
  { value: "The Udupi Taluk Protestant Christian Credit Co-Op Society Ltd - Udupi", label: "The Udupi Taluk Protestant Christian Credit Co-Op Society Ltd - Udupi" },
  { value: "The Udupi Tq Industrial Co-Op Society Ltd- Brahmawara", label: "The Udupi Tq Industrial Co-Op Society Ltd- Brahmawara" },
  { value: "The Uellikulangara Service Coop Bank Ltd", label: "The Uellikulangara Service Coop Bank Ltd" },
  { value: "The Ulikkal Service Co-Operativ Bank Ltd", label: "The Ulikkal Service Co-Operativ Bank Ltd" },
  { value: "The Uliyacovil Service Co-Op Bank Ltd.", label: "The Uliyacovil Service Co-Op Bank Ltd." },
  { value: "The Ullala Service Co-Operative Bank Ltd", label: "The Ullala Service Co-Operative Bank Ltd" },
  { value: "The Ulloor Service Co-Op. Bank Ltd.", label: "The Ulloor Service Co-Op. Bank Ltd." },
  { value: "The Uma Sahakari Sarafi Mandali", label: "The Uma Sahakari Sarafi Mandali" },
  { value: "The Uma Sahakari Sarafi Mandali", label: "The Uma Sahakari Sarafi Mandali" },
  { value: "The Umayattukara Service Co-Op.Bank Ltd.", label: "The Umayattukara Service Co-Op.Bank Ltd." },
  { value: "The Umreth Peoples Co-Op.Bank Ltd.", label: "The Umreth Peoples Co-Op.Bank Ltd." },
  { value: "The Umreth Urban Co-Op Bank", label: "The Umreth Urban Co-Op Bank" },
  { value: "The Umreth Urban Co-Op. Bank Ltd", label: "The Umreth Urban Co-Op. Bank Ltd" },
  { value: "The Umreth Urban Co-Op. Bank Ltd.", label: "The Umreth Urban Co-Op. Bank Ltd." },
  { value: "The Una Peoples Co-Op Bank Ltd.", label: "The Una Peoples Co-Op Bank Ltd." },
  { value: "The Unava Nagarik Sahakari Bank Ltd.", label: "The Unava Nagarik Sahakari Bank Ltd." },
  { value: "The Union Co-Op Bank Ltd.", label: "The Union Co-Op Bank Ltd." },
  { value: "The Union Commercial Co.Op.Bank Ltd", label: "The Union Commercial Co.Op.Bank Ltd" },
  { value: "The United Co-Op Bank Ltd.", label: "The United Co-Op Bank Ltd." },
  { value: "The United Co-Operative Bank Ltd.", label: "The United Co-Operative Bank Ltd." },
  { value: "The United Mercantile Co-Op. Bank Ltd.", label: "The United Mercantile Co-Op. Bank Ltd." },
  { value: "The United Puri-Nimapara Central Cop Bkl", label: "The United Puri-Nimapara Central Cop Bkl" },
  { value: "The United Puri-Nimpura Cent. Co-Op. B.L", label: "The United Puri-Nimpura Cent. Co-Op. B.L" },
  { value: "The United Western Bank Limited", label: "The United Western Bank Limited" },
  { value: "The Unity Co Op Credit Soc  Ltd", label: "The Unity Co Op Credit Soc  Ltd" },
  { value: "The Upputhara Service Co-Operative Bank", label: "The Upputhara Service Co-Operative Bank" },
  { value: "The Urban Co Op Bank", label: "The Urban Co Op Bank" },
  { value: "The Urban Co Operative Bank Ltd", label: "The Urban Co Operative Bank Ltd" },
  { value: "The Urban Co-Op Bank Ltd", label: "The Urban Co-Op Bank Ltd" },
  { value: "The Urban Co-Op Bank Ltd Perinthalmanna", label: "The Urban Co-Op Bank Ltd Perinthalmanna" },
  { value: "The Urban Co-Op Bank Ltd, Siddapur", label: "The Urban Co-Op Bank Ltd, Siddapur" },
  { value: "The Urban Co-Op Bank Ltd,Akot", label: "The Urban Co-Op Bank Ltd,Akot" },
  { value: "The Urban Co-Op Bank Ltd,Saharanpur", label: "The Urban Co-Op Bank Ltd,Saharanpur" },
  { value: "The Urban Co-Op Bank Ltd.", label: "The Urban Co-Op Bank Ltd." },
  { value: "The Urban Co-Op. Bank Ltd, Bhandara", label: "The Urban Co-Op. Bank Ltd, Bhandara" },
  { value: "The Urban Co-Op. Bank Ltd,Perinthalmanna", label: "The Urban Co-Op. Bank Ltd,Perinthalmanna" },
  { value: "The Urban Co-Op. Bank Ltd,Rourkela", label: "The Urban Co-Op. Bank Ltd,Rourkela" },
  { value: "The Urban Co-Op. Bank Ltd. Bhubaneswar", label: "The Urban Co-Op. Bank Ltd. Bhubaneswar" },
  { value: "The Urban Co-Op. Bank Ltd. Dharangaon", label: "The Urban Co-Op. Bank Ltd. Dharangaon" },
  { value: "The Urban Co-Op. Bank Ltd. Shikaripur", label: "The Urban Co-Op. Bank Ltd. Shikaripur" },
  { value: "The Urban Co-Op. Bank Ltd., Cuttack", label: "The Urban Co-Op. Bank Ltd., Cuttack" },
  { value: "The Urban Co-Op. Bank Ltd., Jaipur", label: "The Urban Co-Op. Bank Ltd., Jaipur" },
  { value: "The Urban Co-Operative Bank Ltd Cuttack", label: "The Urban Co-Operative Bank Ltd Cuttack" },
  { value: "The Urban Co-Operative Bk.L,Bhubaneswar", label: "The Urban Co-Operative Bk.L,Bhubaneswar" },
  { value: "The Urban Coop Bank Ltd", label: "The Urban Coop Bank Ltd" },
  { value: "The Urban Development Co-Op. Bank Ltd.", label: "The Urban Development Co-Op. Bank Ltd." },
  { value: "The Urukunnu Service Co-Op Bank Ltd,", label: "The Urukunnu Service Co-Op Bank Ltd," },
  { value: "The Utarsanda Peo Coop.B.L.,Uttarsanda", label: "The Utarsanda Peo Coop.B.L.,Uttarsanda" },
  { value: "The Utarsanda Peoples Co Op Bank Ltd", label: "The Utarsanda Peoples Co Op Bank Ltd" },
  { value: "The Utarsanda Peopless Co-Op Bank Ltd", label: "The Utarsanda Peopless Co-Op Bank Ltd" },
  { value: "The Utkal Co-Op. Banking Society Ltd.", label: "The Utkal Co-Op. Banking Society Ltd." },
  { value: "The Uttarpara Co-Operative Bank Ltd.", label: "The Uttarpara Co-Operative Bank Ltd." },
  { value: "The Uyankateshwara Sahakari Bank Ltd.", label: "The Uyankateshwara Sahakari Bank Ltd." },
  { value: "The Uzhavoor Service Co Op Bank Ltd", label: "The Uzhavoor Service Co Op Bank Ltd" },
  { value: "The Uzhavoor Services Coop Bank Ltd", label: "The Uzhavoor Services Coop Bank Ltd" },
  { value: "The Uzhuva Service Co-Op Bk Ltd,", label: "The Uzhuva Service Co-Op Bk Ltd," },
  { value: "The V V C Co Op Bank Ltd", label: "The V V C Co Op Bank Ltd" },
  { value: "The Vadakkekad Service Co-Op Bank Ltd", label: "The Vadakkekad Service Co-Op Bank Ltd" },
  { value: "The Vadakkumbad Service Co-Op Bank Ltd", label: "The Vadakkumbad Service Co-Op Bank Ltd" },
  { value: "The Vadakkumpad Service Co Op Bank Ltd", label: "The Vadakkumpad Service Co Op Bank Ltd" },
  { value: "The Vadakkumpuram Ser. Co-Op. B.L.", label: "The Vadakkumpuram Ser. Co-Op. B.L." },
  { value: "The Vadakumbad Service Co-Op Bank Ltd.", label: "The Vadakumbad Service Co-Op Bank Ltd." },
  { value: "The Vadali Nagarik Sahakari Bank Ltd.", label: "The Vadali Nagarik Sahakari Bank Ltd." },
  { value: "The Vadavannur Service Co-Op. Bank Ltd", label: "The Vadavannur Service Co-Op. Bank Ltd" },
  { value: "The Vadavucode Farmers Service Cp Bk Ltd", label: "The Vadavucode Farmers Service Cp Bk Ltd" },
  { value: "The Vadgaon Janata Co.Op. Credit Soc. Ltd.", label: "The Vadgaon Janata Co.Op. Credit Soc. Ltd." },
  { value: "The Vadgaon Urban Co-Op Bank Ltd.", label: "The Vadgaon Urban Co-Op Bank Ltd." },
  { value: "The Vadgaon Urban Co-Op. Bank Ltd., Kolhapur", label: "The Vadgaon Urban Co-Op. Bank Ltd., Kolhapur" },
  { value: "The Vadnagar Nagrik Sahakari Bank Ltd", label: "The Vadnagar Nagrik Sahakari Bank Ltd" },
  { value: "The Vadodara Gramin Bank", label: "The Vadodara Gramin Bank" },
  { value: "The Vaidyanath Urban Co-Op. Bank Ltd", label: "The Vaidyanath Urban Co-Op. Bank Ltd" },
  { value: "The Vaijapur Merchants Co.Op Bank Ltd", label: "The Vaijapur Merchants Co.Op Bank Ltd" },
  { value: "The Vaikom Palliprethussery Ser Cb Bk L,", label: "The Vaikom Palliprethussery Ser Cb Bk L," },
  { value: "The Vaikom Urban Co-Op Bank Ltd", label: "The Vaikom Urban Co-Op Bank Ltd" },
  { value: "The Vaish Co-Op New Bank Ltd.", label: "The Vaish Co-Op New Bank Ltd." },
  { value: "The Vaish Co-Op New Bank Ltd.", label: "The Vaish Co-Op New Bank Ltd." },
  { value: "The Vaish Co-Op. Adarsh Bank Ltd", label: "The Vaish Co-Op. Adarsh Bank Ltd" },
  { value: "The Vaish Co-Op. Commercial Bank Ltd", label: "The Vaish Co-Op. Commercial Bank Ltd" },
  { value: "The Vaishali District Cent Co-Op Bank Lt", label: "The Vaishali District Cent Co-Op Bank Lt" },
  { value: "The Vaishali Shahri Vikas Co.Op Bank Ltd", label: "The Vaishali Shahri Vikas Co.Op Bank Ltd" },
  { value: "The Vaishya Urban Co-Op Credit Society Ltd", label: "The Vaishya Urban Co-Op Credit Society Ltd" },
  { value: "The Vajipura Comra Gssm Ltd", label: "The Vajipura Comra Gssm Ltd" },
  { value: "The Vakahtanam Service Co-Op Bank Ltd.", label: "The Vakahtanam Service Co-Op Bank Ltd." },
  { value: "The Vakayar Service Co-Op Bank Ltd,", label: "The Vakayar Service Co-Op Bank Ltd," },
  { value: "The Valapattanam Ser. Co. Op. Bank Ltd", label: "The Valapattanam Ser. Co. Op. Bank Ltd" },
  { value: "The Valavannur Service Co-Op Bank Ltd.", label: "The Valavannur Service Co-Op Bank Ltd." },
  { value: "The Valavannur Service Co-Op Bk", label: "The Valavannur Service Co-Op Bk" },
  { value: "The Valavoor Service Cooperative Bank Lt", label: "The Valavoor Service Cooperative Bank Lt" },
  { value: "The Valiyaparambil Service Co-Op Bank L", label: "The Valiyaparambil Service Co-Op Bank L" },
  { value: "The Vallabh Vidyanagar Comm. Co-Op. Bk.L", label: "The Vallabh Vidyanagar Comm. Co-Op. Bk.L" },
  { value: "The Vallabh Vidyanagar Comm.Co-Op.Bk.Ltd", label: "The Vallabh Vidyanagar Comm.Co-Op.Bk.Ltd" },
  { value: "The Vallapuzha Service Co-Op Bank Ltd", label: "The Vallapuzha Service Co-Op Bank Ltd" },
  { value: "The Vallicode Service Co-Op Bank Ltd.", label: "The Vallicode Service Co-Op Bank Ltd." },
  { value: "The Vallikunnu Service Coop Bank Ltd.,", label: "The Vallikunnu Service Coop Bank Ltd.," },
  { value: "The Valluvambram Service Co-Op Bank Ltd", label: "The Valluvambram Service Co-Op Bank Ltd" },
  { value: "The Valparai Co-Op. Urban Bank Ltd", label: "The Valparai Co-Op. Urban Bank Ltd" },
  { value: "The Vamadapadavu Ser. Co-Op. Bk L.", label: "The Vamadapadavu Ser. Co-Op. Bk L." },
  { value: "The Vandanmettu Service Co-Op. Bank Ltd", label: "The Vandanmettu Service Co-Op. Bank Ltd" },
  { value: "The Vandiperiyar Service Co-Op Bk Ltd", label: "The Vandiperiyar Service Co-Op Bk Ltd" },
  { value: "The Vani Merchants Co.Op. Bk Ltd", label: "The Vani Merchants Co.Op. Bk Ltd" },
  { value: "The Vaniamkulam Ser. Co. Bk Ltd", label: "The Vaniamkulam Ser. Co. Bk Ltd" },
  { value: "The Vaniyambadi Town Co-Op.Bk Ltd", label: "The Vaniyambadi Town Co-Op.Bk Ltd" },
  { value: "The Vaniyambalam Vanitha Co Op Soc. Ltd", label: "The Vaniyambalam Vanitha Co Op Soc. Ltd" },
  { value: "The Varacha Co. Op. Bank Ltd", label: "The Varacha Co. Op. Bank Ltd" },
  { value: "The Varachha Co-Op Bank Ltd.", label: "The Varachha Co-Op Bank Ltd." },
  { value: "The Varachha Co-Op Bank Ltd., Surat", label: "The Varachha Co-Op Bank Ltd., Surat" },
  { value: "The Varanad Service Co - Op Bank", label: "The Varanad Service Co - Op Bank" },
  { value: "The Varapuzha Service Co-Op Bank Ltd,", label: "The Varapuzha Service Co-Op Bank Ltd," },
  { value: "The Vardhman Co-Operative Bank Ltd.", label: "The Vardhman Co-Operative Bank Ltd." },
  { value: "The Vasco Co-Op Bank Ltd.", label: "The Vasco Co-Op Bank Ltd." },
  { value: "The Vaso Co-Op. Bank Ltd.", label: "The Vaso Co-Op. Bank Ltd." },
  { value: "The Vayala Service Co-Op Bank Ltd.", label: "The Vayala Service Co-Op Bank Ltd." },
  { value: "The Vazhakulam Service Co-Op Bank Ltd.", label: "The Vazhakulam Service Co-Op Bank Ltd." },
  { value: "The Vazhathope Service Co-Op. Bank Ltd", label: "The Vazhathope Service Co-Op. Bank Ltd" },
  { value: "The Vazhayoor Services Co-Op Bk Ltd,", label: "The Vazhayoor Services Co-Op Bk Ltd," },
  { value: "The Vazhilkkadavu Ser. Co. Bank Ltd", label: "The Vazhilkkadavu Ser. Co. Bank Ltd" },
  { value: "The Vazhoor Farmers Ser. Co-Op. Bk.Ltd", label: "The Vazhoor Farmers Ser. Co-Op. Bk.Ltd" },
  { value: "The Veerashaiva Co-Op.Bank Ltd.", label: "The Veerashaiva Co-Op.Bank Ltd." },
  { value: "The Vejalpur Nagrik Sahakari Bank Ltd.", label: "The Vejalpur Nagrik Sahakari Bank Ltd." },
  { value: "The Veliyanoor Service Co-Op Bank Ltd.", label: "The Veliyanoor Service Co-Op Bank Ltd." },
  { value: "The Vellamunda Service Co-Op Bank Ltd", label: "The Vellamunda Service Co-Op Bank Ltd" },
  { value: "The Vellamunda Service Co. Op Bank Ltd", label: "The Vellamunda Service Co. Op Bank Ltd" },
  { value: "The Vellathooval Ser.Co-Op.Bk.L.", label: "The Vellathooval Ser.Co-Op.Bk.L." },
  { value: "The Vellavoor Service Co-Op Bank,", label: "The Vellavoor Service Co-Op Bank," },
  { value: "The Velliamattam Ser. Co. Bank Ltd.", label: "The Velliamattam Ser. Co. Bank Ltd." },
  { value: "The Vellikulangara Service Coop Bank Ltd", label: "The Vellikulangara Service Coop Bank Ltd" },
  { value: "The Velloor Ser. Co-Operative Bank Ltd.", label: "The Velloor Ser. Co-Operative Bank Ltd." },
  { value: "The Vellore Dt. Central Co-Op. Bank Ltd", label: "The Vellore Dt. Central Co-Op. Bank Ltd" },
  { value: "The Vellur Ser Co Op Bank Ltd", label: "The Vellur Ser Co Op Bank Ltd" },
  { value: "The Vellur Service Co-Op. Bank Ltd", label: "The Vellur Service Co-Op. Bank Ltd" },
  { value: "The Velur Co-Op Urban Bank Ltd", label: "The Velur Co-Op Urban Bank Ltd" },
  { value: "The Velur Service Co Operative Bank", label: "The Velur Service Co Operative Bank" },
  { value: "The Vemana Souhardha Credit Co Op Bank Ltd", label: "The Vemana Souhardha Credit Co Op Bank Ltd" },
  { value: "The Vengad Service Co-Operative Bank Ltd", label: "The Vengad Service Co-Operative Bank Ltd" },
  { value: "The Vengalloor Service Co-Op. Bank", label: "The Vengalloor Service Co-Op. Bank" },
  { value: "The Vengappally Ser. Co-Op. Bank Ltd.", label: "The Vengappally Ser. Co-Op. Bank Ltd." },
  { value: "The Vengara Service Co. Rural Bank Ltd", label: "The Vengara Service Co. Rural Bank Ltd" },
  { value: "The Vengeri Service Co-Op Bank Ltd", label: "The Vengeri Service Co-Op Bank Ltd" },
  { value: "The Venginissery Service Co-Op Bank Ltd.", label: "The Venginissery Service Co-Op Bank Ltd." },
  { value: "The Veraval Mercantile Co-Op Bank Ltd.", label: "The Veraval Mercantile Co-Op Bank Ltd." },
  { value: "The Veraval Peoples Co-Op. Bank Ltd.", label: "The Veraval Peoples Co-Op. Bank Ltd." },
  { value: "The Veraval Peoples Co-Op Bank Ltd", label: "The Veraval Peoples Co-Op Bank Ltd" },
  { value: "The Veraval Peoples Coop Bank", label: "The Veraval Peoples Coop Bank" },
  { value: "The Vettakkal Service Co-Op Bank Ltd,", label: "The Vettakkal Service Co-Op Bank Ltd," },
  { value: "The Vettathur Service Co-Op Bank Ltd.", label: "The Vettathur Service Co-Op Bank Ltd." },
  { value: "The Vettikkattiri Service Co-Op Bank Ltd", label: "The Vettikkattiri Service Co-Op Bank Ltd" },
  { value: "The Vettom Service Co-Operative Bank Ltd", label: "The Vettom Service Co-Operative Bank Ltd" },
  { value: "The Vidarbha Urban Co Op Bank Ltd", label: "The Vidarbha Urban Co Op Bank Ltd" },
  { value: "The Vidarbha Urban Co-Op Bank Ltd.", label: "The Vidarbha Urban Co-Op Bank Ltd." },
  { value: "The Vijapur Nagarik Sahakari Bank Ltd.", label: "The Vijapur Nagarik Sahakari Bank Ltd." },
  { value: "The Vijay Co-Operative Bank Ltd.", label: "The Vijay Co-Operative Bank Ltd." },
  { value: "The Vijay Credit & Saplay Co Op Soc Ltd", label: "The Vijay Credit & Saplay Co Op Soc Ltd" },
  { value: "The Vijay Urban Co Op Bank Ltd", label: "The Vijay Urban Co Op Bank Ltd" },
  { value: "The Vijaynagar Nagarik Sarafi Shakari Mandali Ltd", label: "The Vijaynagar Nagarik Sarafi Shakari Mandali Ltd" },
  { value: "The Villupuram Co-Op. Urban Bank Ltd", label: "The Villupuram Co-Op. Urban Bank Ltd" },
  { value: "The Villupuram Dist. Central Co.Bank Ltd", label: "The Villupuram Dist. Central Co.Bank Ltd" },
  { value: "The Villupuram Rama Padaya Dt Ct Cp Bk L", label: "The Villupuram Rama Padaya Dt Ct Cp Bk L" },
  { value: "The Viramgam Mercantile Co Op Bank", label: "The Viramgam Mercantile Co Op Bank" },
  { value: "The Viramgam Mercantile Co-Op Bank Ltd", label: "The Viramgam Mercantile Co-Op Bank Ltd" },
  { value: "The Viramgam Mercantile Co-Op Bank Ltd", label: "The Viramgam Mercantile Co-Op Bank Ltd" },
  { value: "The Viramgam Mercantile Co-Oper Bank Ltd", label: "The Viramgam Mercantile Co-Oper Bank Ltd" },
  { value: "The Virudhunagar Dist.Cen.Co-Op.Bk Ltd.", label: "The Virudhunagar Dist.Cen.Co-Op.Bk Ltd." },
  { value: "The Visakhapatnam Co-Operative Bank Ltd.", label: "The Visakhapatnam Co-Operative Bank Ltd." },
  { value: "The Visakhapatnam Dt Cp Central Bank Ltd", label: "The Visakhapatnam Dt Cp Central Bank Ltd" },
  { value: "The Vishnagar Nagrik Sahakari Bank Ltd", label: "The Vishnagar Nagrik Sahakari Bank Ltd" },
  { value: "The Vishweshwar Bank Ltd", label: "The Vishweshwar Bank Ltd" },
  { value: "The Vishweshwar Sahakari Bank Ltd Barshi", label: "The Vishweshwar Sahakari Bank Ltd Barshi" },
  { value: "The Vishweshwar Sahakari Bank Ltd Pune", label: "The Vishweshwar Sahakari Bank Ltd Pune" },
  { value: "The Visnagar Nagarik Sahakari Bank Ltd", label: "The Visnagar Nagarik Sahakari Bank Ltd" },
  { value: "The Vita Merchants Co-Op. Bank Ltd", label: "The Vita Merchants Co-Op. Bank Ltd" },
  { value: "The Vita Urban Co-Op Bank Ltd.", label: "The Vita Urban Co-Op Bank Ltd." },
  { value: "The Vividha Co-Op Credit Society Ltd", label: "The Vividha Co-Op Credit Society Ltd" },
  { value: "The Vizianagaram Co-Op.Urban Bank Ltd.", label: "The Vizianagaram Co-Op.Urban Bank Ltd." },
  { value: "The Vorkady Service Co-Op Bank Ltd.", label: "The Vorkady Service Co-Op Bank Ltd." },
  { value: "The Vpk Urban Co-Op Credit Sty. Ltd.", label: "The Vpk Urban Co-Op Credit Sty. Ltd." },
  { value: "The Vriddhachalam Co-Op. Urban Bk. L.", label: "The Vriddhachalam Co-Op. Urban Bk. L." },
  { value: "The Vrishivat Urban Co Op Credit Society Ltd", label: "The Vrishivat Urban Co Op Credit Society Ltd" },
  { value: "The Vyankateshwara Sahakri Bank Ltd.", label: "The Vyankateshwara Sahakri Bank Ltd." },
  { value: "The Vyavsayik Sahakari Bank Ltd.", label: "The Vyavsayik Sahakari Bank Ltd." },
  { value: "The Vysya Bank L", label: "The Vysya Bank L" },
  { value: "The Vysya Bank Ltd.", label: "The Vysya Bank Ltd." },
  { value: "The Waghodia Urban Co. Bank Ltd", label: "The Waghodia Urban Co. Bank Ltd" },
  { value: "The Wai Urban Co-Op. Bank Ltd.", label: "The Wai Urban Co-Op. Bank Ltd." },
  { value: "The Wai Urban Coop Bank Ltd", label: "The Wai Urban Coop Bank Ltd" },
  { value: "The Wandoor Service Co-Op Bank Ltd.", label: "The Wandoor Service Co-Op Bank Ltd." },
  { value: "The Wani Merchant Co Op Bank", label: "The Wani Merchant Co Op Bank" },
  { value: "The Warangal Dist.Co-Op.Central Bank Ltd", label: "The Warangal Dist.Co-Op.Central Bank Ltd" },
  { value: "The Wardha Dt.Cent.C.P. Bk Ltd", label: "The Wardha Dt.Cent.C.P. Bk Ltd" },
  { value: "The Washim Urban Co-Op Bank Ltd Washim", label: "The Washim Urban Co-Op Bank Ltd Washim" },
  { value: "The Washim Urban Co-Op. Bank Ltd", label: "The Washim Urban Co-Op. Bank Ltd" },
  { value: "The Washim Urban Coop Bnak Ltd", label: "The Washim Urban Coop Bnak Ltd" },
  { value: "The Watrap Co-Op. Rural Bk.Ltd", label: "The Watrap Co-Op. Rural Bk.Ltd" },
  { value: "The Wayanad District Co-Op. Bank Ltd.", label: "The Wayanad District Co-Op. Bank Ltd." },
  { value: "The Wayanad District Drivers Co-Op Society Ltd", label: "The Wayanad District Drivers Co-Op Society Ltd" },
  { value: "The West Bengal State Co-Op Bank Ltd.", label: "The West Bengal State Co-Op Bank Ltd." },
  { value: "The West Bengal State Co-Operative Bank", label: "The West Bengal State Co-Operative Bank" },
  { value: "The West Eleri Service Co-Op. Bank Ltd", label: "The West Eleri Service Co-Op. Bank Ltd" },
  { value: "The West Godavari Dist Cscs Ltd", label: "The West Godavari Dist Cscs Ltd" },
  { value: "The West Kallada Ser. Co-Op.Bk.Ltd.", label: "The West Kallada Ser. Co-Op.Bk.Ltd." },
  { value: "The Wheels", label: "The Wheels" },
  { value: "The Womens Co-Operative Bank Ltd.", label: "The Womens Co-Operative Bank Ltd." },
  { value: "The Yadagiri Lakshmi Narsimha S.C.P.Ur.B", label: "The Yadagiri Lakshmi Narsimha S.C.P.Ur.B" },
  { value: "The Yamakanmardi Laxmi Ur Cr Sou Sahakari Niyamith", label: "The Yamakanmardi Laxmi Ur Cr Sou Sahakari Niyamith" },
  { value: "The Yamuna Nagar Central Co-Op Bank Ltd.", label: "The Yamuna Nagar Central Co-Op Bank Ltd." },
  { value: "The Yamuna Nagar Urban Co-Op. Bank Ltd.", label: "The Yamuna Nagar Urban Co-Op. Bank Ltd." },
  { value: "The Yaragatti Urban Co-Op. Credit Bank L", label: "The Yaragatti Urban Co-Op. Credit Bank L" },
  { value: "The Yaragatti Urban Co.Op. Credit Bk Ltd", label: "The Yaragatti Urban Co.Op. Credit Bk Ltd" },
  { value: "The Yashwant Co-Op. Bank Ltd.", label: "The Yashwant Co-Op. Bank Ltd." },
  { value: "The Yashwant Coop Bank Ltd", label: "The Yashwant Coop Bank Ltd" },
  { value: "The Yavatmal Gramin Bank", label: "The Yavatmal Gramin Bank" },
  { value: "The Yavatmal Urban Co-Op Bank Ltd", label: "The Yavatmal Urban Co-Op Bank Ltd" },
  { value: "The Yavatmal Urban Co-Op. Bank Ltd.", label: "The Yavatmal Urban Co-Op. Bank Ltd." },
  { value: "The Yemmiganur Co-Op Town Bank Ltd.", label: "The Yemmiganur Co-Op Town Bank Ltd." },
  { value: "The Yeola Merchants Co-Op Bank Ltd.", label: "The Yeola Merchants Co-Op Bank Ltd." },
  { value: "The Yeotmal District Cen. Co-Op. Bk. Ltd", label: "The Yeotmal District Cen. Co-Op. Bk. Ltd" },
  { value: "The Yogeshwar Credit Sahakari Mandali Ltd", label: "The Yogeshwar Credit Sahakari Mandali Ltd" },
  { value: "The Zalod Co Op Bank", label: "The Zalod Co Op Bank" },
  { value: "The Zalud Urban Co-Op Bank Ltd", label: "The Zalud Urban Co-Op Bank Ltd" },
  { value: "The Zoroastrian Co Operative Bank Ltd.", label: "The Zoroastrian Co Operative Bank Ltd." },
  { value: "The Zorostrian Co-Op Bank Ltd", label: "The Zorostrian Co-Op Bank Ltd" },
  { value: "Thecated Mentioned Bank", label: "Thecated Mentioned Bank" },
  { value: "Thejus Vehicle Financiers", label: "Thejus Vehicle Financiers" },
  { value: "Thekkattur Pacb. Munasandai", label: "Thekkattur Pacb. Munasandai" },
  { value: "Thekkattur Prim Agri Cp Bank", label: "Thekkattur Prim Agri Cp Bank" },
  { value: "Thekkumbhagam Service Cop Bk Ltd.", label: "Thekkumbhagam Service Cop Bk Ltd." },
  { value: "Theni Urban Co-Op Bank Ltd", label: "Theni Urban Co-Op Bank Ltd" },
  { value: "Thenmelpakkam Prim. Agri. Co-Op Bank Ltd", label: "Thenmelpakkam Prim. Agri. Co-Op Bank Ltd" },
  { value: "Thenmozhi Financiers", label: "Thenmozhi Financiers" },
  { value: "Thennala Ser. Co-Op. Bk.L.", label: "Thennala Ser. Co-Op. Bk.L." },
  { value: "Thenpandian Auto Finance", label: "Thenpandian Auto Finance" },
  { value: "Thermax Capital Ltd.", label: "Thermax Capital Ltd." },
  { value: "Thethakudi South Prim Agri Co-Op Bank Lt", label: "Thethakudi South Prim Agri Co-Op Bank Lt" },
  { value: "Thevalakara Farmers Service Coop.Bank Lt", label: "Thevalakara Farmers Service Coop.Bank Lt" },
  { value: "Thillenkery Serv. Co-Op. Bk Ltd", label: "Thillenkery Serv. Co-Op. Bk Ltd" },
  { value: "Thind Finance Pvt. Ltd.", label: "Thind Finance Pvt. Ltd." },
  { value: "Thindalmurugan Finance", label: "Thindalmurugan Finance" },
  { value: "Thiroor Service Co-Operative Bank Ltd", label: "Thiroor Service Co-Operative Bank Ltd" },
  { value: "Thiru Finance", label: "Thiru Finance" },
  { value: "Thirumagal Auto Finance", label: "Thirumagal Auto Finance" },
  { value: "Thirumagal Auto Finance", label: "Thirumagal Auto Finance" },
  { value: "Thirumala Auto Finance P Ltd", label: "Thirumala Auto Finance P Ltd" },
  { value: "Thirumala Auto Infin P Ltd", label: "Thirumala Auto Infin P Ltd" },
  { value: "Thirumala Finance", label: "Thirumala Finance" },
  { value: "Thirumala Venkateswarasamy Auto Finance", label: "Thirumala Venkateswarasamy Auto Finance" },
  { value: "Thirumalai Auto Finance", label: "Thirumalai Auto Finance" },
  { value: "Thirumalai Finance", label: "Thirumalai Finance" },
  { value: "Thirumalaivasan Auto Finance", label: "Thirumalaivasan Auto Finance" },
  { value: "Thirumayam Primary Agri.Co-Op.Bank Ltd.", label: "Thirumayam Primary Agri.Co-Op.Bank Ltd." },
  { value: "Thirumurugan Finance", label: "Thirumurugan Finance" },
  { value: "Thirumurugan Group Auto Finance", label: "Thirumurugan Group Auto Finance" },
  { value: "Thirunagaru Yashodhara", label: "Thirunagaru Yashodhara" },
  { value: "Thirunageswaram Pri.Ar.Co-Op.Bk.L.", label: "Thirunageswaram Pri.Ar.Co-Op.Bk.L." },
  { value: "Thirunalloor Service Co Operative Bank Ltd", label: "Thirunalloor Service Co Operative Bank Ltd" },
  { value: "Thirunalloor Service Co-Op. Bank Ltd.", label: "Thirunalloor Service Co-Op. Bank Ltd." },
  { value: "Thirunindra Narayanan Finance And Invest. (I) Ltd.", label: "Thirunindra Narayanan Finance And Invest. (I) Ltd." },
  { value: "Thirupathi Auto Finance", label: "Thirupathi Auto Finance" },
  { value: "Thiruthangal Primary Agri.Co-Op.Bank Ltd", label: "Thiruthangal Primary Agri.Co-Op.Bank Ltd" },
  { value: "Thiruvali Service Co-Op Bank Ltd.", label: "Thiruvali Service Co-Op Bank Ltd." },
  { value: "Thiruvall East Co-Op Bank Ltd", label: "Thiruvall East Co-Op Bank Ltd" },
  { value: "Thiruvalla East Co-Op Bank Ltd", label: "Thiruvalla East Co-Op Bank Ltd" },
  { value: "Thiruvalluvar Finance Ltd", label: "Thiruvalluvar Finance Ltd" },
  { value: "Thiruvalluvar Town Co-Operative Bank Ltd", label: "Thiruvalluvar Town Co-Operative Bank Ltd" },
  { value: "Thiruvananthapuram City Ser.Co-Op Bank L", label: "Thiruvananthapuram City Ser.Co-Op Bank L" },
  { value: "Thiruvananthapuram Dist. Co- Op Bank Ltd", label: "Thiruvananthapuram Dist. Co- Op Bank Ltd" },
  { value: "Thiruvananthapuram Dt E.B.E. Co-Op S Ltd", label: "Thiruvananthapuram Dt E.B.E. Co-Op S Ltd" },
  { value: "Thiruvananthapuram Taluk Co Society Ltd.", label: "Thiruvananthapuram Taluk Co Society Ltd." },
  { value: "Thiruvarppu Village Ser Co-Op Bank", label: "Thiruvarppu Village Ser Co-Op Bank" },
  { value: "Thiruvauyaru C.O.Rurak B.Ltd", label: "Thiruvauyaru C.O.Rurak B.Ltd" },
  { value: "Thiruvazhiyad Kayaradi Co-Op Bank Ltd.", label: "Thiruvazhiyad Kayaradi Co-Op Bank Ltd." },
  { value: "Thiruvonam Finance", label: "Thiruvonam Finance" },
  { value: "Thithimathi V.S.S.B.N", label: "Thithimathi V.S.S.B.N" },
  { value: "Thl Fsl", label: "Thl Fsl" },
  { value: "Thodupuza Town Service Co-Op Bank", label: "Thodupuza Town Service Co-Op Bank" },
  { value: "Thodupuzha Urban Co-Op Bank Ltd", label: "Thodupuzha Urban Co-Op Bank Ltd" },
  { value: "Thodupuzha Urban Co-Op Bank Ltd.", label: "Thodupuzha Urban Co-Op Bank Ltd." },
  { value: "Thodupuzha Urban Co-Op. Bank Ltd.", label: "Thodupuzha Urban Co-Op. Bank Ltd." },
  { value: "Tholudur Primary Agricultural Co-Op Bank", label: "Tholudur Primary Agricultural Co-Op Bank" },
  { value: "Thomas C A", label: "Thomas C A" },
  { value: "Thomas Cook Insurance Service(I) Ltd", label: "Thomas Cook Insurance Service(I) Ltd" },
  { value: "Thomson Press India Ltd.", label: "Thomson Press India Ltd." },
  { value: "Thondahal Service Co-Op Bank Ltd.", label: "Thondahal Service Co-Op Bank Ltd." },
  { value: "Thonnakkal Service Coop Bank Ltd,", label: "Thonnakkal Service Coop Bank Ltd," },
  { value: "Thoothukudi District Central Co-Operative Bank Ltd.", label: "Thoothukudi District Central Co-Operative Bank Ltd." },
  { value: "Thottakom Service Co-Op Bank Ltd,", label: "Thottakom Service Co-Op Bank Ltd," },
  { value: "Thrikkalangode Service Co-Op Bank Ltd.", label: "Thrikkalangode Service Co-Op Bank Ltd." },
  { value: "Thrikkunnapuzha Kizhakkekara Ser.Co.B.L.", label: "Thrikkunnapuzha Kizhakkekara Ser.Co.B.L." },
  { value: "Thrimakeshwari Motors", label: "Thrimakeshwari Motors" },
  { value: "Thrissur Auto Finance", label: "Thrissur Auto Finance" },
  { value: "Thrissur Dist. Co-Op Bank Ltd.", label: "Thrissur Dist. Co-Op Bank Ltd." },
  { value: "Thrissur District Motor Transport Owners Co-Operative Society Ltd", label: "Thrissur District Motor Transport Owners Co-Operative Society Ltd" },
  { value: "Thrissur Hire Purchase", label: "Thrissur Hire Purchase" },
  { value: "Thrissur Service Co-Op Bank Ltd", label: "Thrissur Service Co-Op Bank Ltd" },
  { value: "Thuduppathi Pacb Ltd.", label: "Thuduppathi Pacb Ltd." },
  { value: "Thulasi Investments", label: "Thulasi Investments" },
  { value: "Thulasinanda Auto Finance", label: "Thulasinanda Auto Finance" },
  { value: "Thuravoor Service Co-Op. Bank Ltd", label: "Thuravoor Service Co-Op. Bank Ltd" },
  { value: "Thuvarankurichi Primary Agri Co-Op Bk L", label: "Thuvarankurichi Primary Agri Co-Op Bk L" },
  { value: "Thyagrayanagar Co-Op Bank Ltd", label: "Thyagrayanagar Co-Op Bank Ltd" },
  { value: "Tiara Co-Op Society Ltd.", label: "Tiara Co-Op Society Ltd." },
  { value: "Tiger Finance And Leasing Co. Ltd.", label: "Tiger Finance And Leasing Co. Ltd." },
  { value: "Tiic Ltd", label: "Tiic Ltd" },
  { value: "Tikkoti Service Co-Op. Bank Ltd", label: "Tikkoti Service Co-Op. Bank Ltd" },
  { value: "Tilak Auto Consult", label: "Tilak Auto Consult" },
  { value: "Times Bank Ltd.", label: "Times Bank Ltd." },
  { value: "Times Leasing (India) Ltd.", label: "Times Leasing (India) Ltd." },
  { value: "Timesbank", label: "Timesbank" },
  { value: "Tina Motors", label: "Tina Motors" },
  { value: "Tindivanam Finance Corporation", label: "Tindivanam Finance Corporation" },
  { value: "Tippu Co Op Society Ltd", label: "Tippu Co Op Society Ltd" },
  { value: "Tiru Shree Finance Co", label: "Tiru Shree Finance Co" },
  { value: "Tiruchendur Co-Op Urban Bank Ltd", label: "Tiruchendur Co-Op Urban Bank Ltd" },
  { value: "Tiruchirapalli City Co-Op. Bank Ltd", label: "Tiruchirapalli City Co-Op. Bank Ltd" },
  { value: "Tiruchirapalli Dist. Central Co-Op Bank", label: "Tiruchirapalli Dist. Central Co-Op Bank" },
  { value: "Tiruchirapalli District Central Co-Operative Bank Ltd.", label: "Tiruchirapalli District Central Co-Operative Bank Ltd." },
  { value: "Tiruchirapalli Hirudayapuram Co-Op Bank Ltd", label: "Tiruchirapalli Hirudayapuram Co-Op Bank Ltd" },
  { value: "Tiruchngodu Urban Co-Op Bank Ltd", label: "Tiruchngodu Urban Co-Op Bank Ltd" },
  { value: "Tirukoilur Co-Op Urban Bank Ltd", label: "Tirukoilur Co-Op Urban Bank Ltd" },
  { value: "Tirumala A/F", label: "Tirumala A/F" },
  { value: "Tirumala Co-Operative Urban Bank Ltd.", label: "Tirumala Co-Operative Urban Bank Ltd." },
  { value: "Tirumala Finance Corporation", label: "Tirumala Finance Corporation" },
  { value: "Tirumala Motors", label: "Tirumala Motors" },
  { value: "Tirumala Venkata Sai Auto Finance", label: "Tirumala Venkata Sai Auto Finance" },
  { value: "Tirumangalam Co-Operative Urban Bank Ltd", label: "Tirumangalam Co-Operative Urban Bank Ltd" },
  { value: "Tirunavaya Service Co-Op Bank Ltd", label: "Tirunavaya Service Co-Op Bank Ltd" },
  { value: "Tirunelveli District Central Co-Operative Bank Ltd.", label: "Tirunelveli District Central Co-Operative Bank Ltd." },
  { value: "Tirupathi Auto Finance", label: "Tirupathi Auto Finance" },
  { value: "Tirupathi Investments", label: "Tirupathi Investments" },
  { value: "Tirupati Arban Co-Op Bank Ltd", label: "Tirupati Arban Co-Op Bank Ltd" },
  { value: "Tirupati Balaji B.S.S.P", label: "Tirupati Balaji B.S.S.P" },
  { value: "Tirupati Balaji Finance Co Pvt Ltd", label: "Tirupati Balaji Finance Co Pvt Ltd" },
  { value: "Tirupati Co Op Bank Ltd", label: "Tirupati Co Op Bank Ltd" },
  { value: "Tirupati Corporation", label: "Tirupati Corporation" },
  { value: "Tirupati Finance", label: "Tirupati Finance" },
  { value: "Tirupati Finance Co", label: "Tirupati Finance Co" },
  { value: "Tirupati Group,Udhna,Surat", label: "Tirupati Group,Udhna,Surat" },
  { value: "Tirupati Motor Finance", label: "Tirupati Motor Finance" },
  { value: "Tirupati Nagari Sahakari Patsantha Maryadit", label: "Tirupati Nagari Sahakari Patsantha Maryadit" },
  { value: "Tirupati Nagari Sahakari Patsantha Maryadit.", label: "Tirupati Nagari Sahakari Patsantha Maryadit." },
  { value: "Tirupati Propriters  Finances", label: "Tirupati Propriters  Finances" },
  { value: "Tirupati Services Limited", label: "Tirupati Services Limited" },
  { value: "Tirupati Traders", label: "Tirupati Traders" },
  { value: "Tirupati Urban Co-Op.Bank Ltd.", label: "Tirupati Urban Co-Op.Bank Ltd." },
  { value: "Tirupati Urban Co.Op.Bank Ltd. ,Nagpur", label: "Tirupati Urban Co.Op.Bank Ltd. ,Nagpur" },
  { value: "Tirupattur Urban Co-Op Bank Ltd", label: "Tirupattur Urban Co-Op Bank Ltd" },
  { value: "Tirupur Srimahal Finance", label: "Tirupur Srimahal Finance" },
  { value: "Tirurangadi Service Co-Operative Bank Lt", label: "Tirurangadi Service Co-Operative Bank Lt" },
  { value: "Tiruvall East Co-Op Bank Ltd", label: "Tiruvall East Co-Op Bank Ltd" },
  { value: "Tiruvalla Urban Co-Op Bank Ltd", label: "Tiruvalla Urban Co-Op Bank Ltd" },
  { value: "Tiruvannamalai District Central Co-Operative Bank Ltd.", label: "Tiruvannamalai District Central Co-Operative Bank Ltd." },
  { value: "Tiruvathipuram Co-O0P Urban Bank Ltd", label: "Tiruvathipuram Co-O0P Urban Bank Ltd" },
  { value: "Titan", label: "Titan" },
  { value: "Titan Industries Ltd.", label: "Titan Industries Ltd." },
  { value: "Tiwana & Company", label: "Tiwana & Company" },
  { value: "Tje Sircilla Co-Op. Urban Bank Ltd.", label: "Tje Sircilla Co-Op. Urban Bank Ltd." },
  { value: "Tjr Upathi Investments", label: "Tjr Upathi Investments" },
  { value: "Tkf Finance Ltd", label: "Tkf Finance Ltd" },
  { value: "Tkhuleshwar Gramin Bigar S. S Pat", label: "Tkhuleshwar Gramin Bigar S. S Pat" },
  { value: "Tm Financial Services Ltd.", label: "Tm Financial Services Ltd." },
  { value: "Tmb Maihapuram", label: "Tmb Maihapuram" },
  { value: "Tmc Financial Services", label: "Tmc Financial Services" },
  { value: "Tme Fin. Ser. Ltd", label: "Tme Fin. Ser. Ltd" },
  { value: "Tmf  Ltd", label: "Tmf  Ltd" },
  { value: "Tmfl Pvt Ltd.", label: "Tmfl Pvt Ltd." },
  { value: "Tmfs Ltd", label: "Tmfs Ltd" },
  { value: "Tmftml Financial Services Ltd", label: "Tmftml Financial Services Ltd" },
  { value: "Tml  Financial Services Ltd", label: "Tml  Financial Services Ltd" },
  { value: "Tml Fin. Ber. Ltd.", label: "Tml Fin. Ber. Ltd." },
  { value: "Tml Finance Company Ltd", label: "Tml Finance Company Ltd" },
  { value: "Tml Finance Ltd", label: "Tml Finance Ltd" },
  { value: "Tml Finance Service Ltd", label: "Tml Finance Service Ltd" },
  { value: "Tml Finance Services Ltd", label: "Tml Finance Services Ltd" },
  { value: "Tml Financial Ltd", label: "Tml Financial Ltd" },
  { value: "Tml Financial Services", label: "Tml Financial Services" },
  { value: "Tml Financial Services Ltd.", label: "Tml Financial Services Ltd." },
  { value: "Tml Financiers Ltd", label: "Tml Financiers Ltd" },
  { value: "Tml Finlease Ltd", label: "Tml Finlease Ltd" },
  { value: "Tml Grancial Services Ltd.", label: "Tml Grancial Services Ltd." },
  { value: "Tml Prayag Udyog Ltd.", label: "Tml Prayag Udyog Ltd." },
  { value: "Tmlsfl Lucknow.", label: "Tmlsfl Lucknow." },
  { value: "Tmr Financial Services Ltd", label: "Tmr Financial Services Ltd" },
  { value: "Tms Service Ltd", label: "Tms Service Ltd" },
  { value: "Tmsl", label: "Tmsl" },
  { value: "Tncsc Ltd", label: "Tncsc Ltd" },
  { value: "Tobacco Board", label: "Tobacco Board" },
  { value: "Todi Hire Purchase And Lease Finance Ltd", label: "Todi Hire Purchase And Lease Finance Ltd" },
  { value: "Tokas Leasing Finance Co", label: "Tokas Leasing Finance Co" },
  { value: "Tol Seva Sahkari Mandali Ltd", label: "Tol Seva Sahkari Mandali Ltd" },
  { value: "Tomar Motor And Gen. Finance", label: "Tomar Motor And Gen. Finance" },
  { value: "Tonk District Central Co-Operative Bank Ltd.", label: "Tonk District Central Co-Operative Bank Ltd." },
  { value: "Toor Finance Co. Pvt. Ltd.", label: "Toor Finance Co. Pvt. Ltd." },
  { value: "Toraniya Seva Sahakari Mandali", label: "Toraniya Seva Sahakari Mandali" },
  { value: "Torrent Pharmaceuticals Ltd.", label: "Torrent Pharmaceuticals Ltd." },
  { value: "Totagars Rural Co-Op Credit Bank Ltd  Sirsi", label: "Totagars Rural Co-Op Credit Bank Ltd  Sirsi" },
  { value: "Total Fina Elf India Ltd", label: "Total Fina Elf India Ltd" },
  { value: "Total Financial Services Ltd", label: "Total Financial Services Ltd" },
  { value: "Total Insurance Solutions", label: "Total Insurance Solutions" },
  { value: "Total Lubricants India Ltd", label: "Total Lubricants India Ltd" },
  { value: "Total Transport Finance Ser Ltd", label: "Total Transport Finance Ser Ltd" },
  { value: "Totsol Financial Service Ltd.  New Delhi", label: "Totsol Financial Service Ltd.  New Delhi" },
  { value: "Tour Pack", label: "Tour Pack" },
  { value: "Tourism Finance Corpn. Of India", label: "Tourism Finance Corpn. Of India" },
  { value: "Toursim Finance Corporation Of India", label: "Toursim Finance Corporation Of India" },
  { value: "Town Co-Op Bank Ltd", label: "Town Co-Op Bank Ltd" },
  { value: "Town Co-Op. Bank Ltd", label: "Town Co-Op. Bank Ltd" },
  { value: "Town Vivedoddesha Sahakari Bhandara Ni", label: "Town Vivedoddesha Sahakari Bhandara Ni" },
  { value: "Toyato Financial Services India Limited", label: "Toyato Financial Services India Limited" },
  { value: "Toyota Kirloskar Motor Ltd", label: "Toyota Kirloskar Motor Ltd" },
  { value: "Toyota Tsusho Insurance Broker(India)Pvt Ltd", label: "Toyota Tsusho Insurance Broker(India)Pvt Ltd" },
  { value: "Tph Travel Services", label: "Tph Travel Services" },
  { value: "Tractor Center", label: "Tractor Center" },
  { value: "Trans America Apple Finance", label: "Trans America Apple Finance" },
  { value: "Trans Asia Auto And General Finance Ltd.", label: "Trans Asia Auto And General Finance Ltd." },
  { value: "Trans Asia Auto Fin Ltd.", label: "Trans Asia Auto Fin Ltd." },
  { value: "Trans India Financial Services Ltd.", label: "Trans India Financial Services Ltd." },
  { value: "Trans Warranty Finance Ltd.", label: "Trans Warranty Finance Ltd." },
  { value: "Transcity Finance", label: "Transcity Finance" },
  { value: "Transpek Finance Ltd.", label: "Transpek Finance Ltd." },
  { value: "Transporation Financial Services Ltd", label: "Transporation Financial Services Ltd" },
  { value: "Transport Co-Op Bank Ltd., Indore", label: "Transport Co-Op Bank Ltd., Indore" },
  { value: "Transport Co-Op. Bank Ltd.", label: "Transport Co-Op. Bank Ltd." },
  { value: "Transport Office Finance", label: "Transport Office Finance" },
  { value: "Transportation Financial Ser Ltd", label: "Transportation Financial Ser Ltd" },
  { value: "Transworld Hire Purchase India Ltd.", label: "Transworld Hire Purchase India Ltd." },
  { value: "Transworld Leasing Ltd.", label: "Transworld Leasing Ltd." },
  { value: "Trapti Finance Company", label: "Trapti Finance Company" },
  { value: "Travel Express Limited", label: "Travel Express Limited" },
  { value: "Travel Moments", label: "Travel Moments" },
  { value: "Trc Financial Services Ltd.", label: "Trc Financial Services Ltd." },
  { value: "Trcmpu  Ltd", label: "Trcmpu  Ltd" },
  { value: "Treassury Officer", label: "Treassury Officer" },
  { value: "Treasure Trust Pvt. Ltd.", label: "Treasure Trust Pvt. Ltd." },
  { value: "Treasury", label: "Treasury" },
  { value: "Trenty Finance", label: "Trenty Finance" },
  { value: "Trichur District Motor Transport Owners Co Operative Society Ltd", label: "Trichur District Motor Transport Owners Co Operative Society Ltd" },
  { value: "Trichur Urban Co-Op. Bank Ltd., Trichur", label: "Trichur Urban Co-Op. Bank Ltd., Trichur" },
  { value: "Tricom Finance Ltd.", label: "Tricom Finance Ltd." },
  { value: "Trident Automobiles", label: "Trident Automobiles" },
  { value: "Trident Finance Ltd.", label: "Trident Finance Ltd." },
  { value: "Trikaal Leasing And Finance Ltd", label: "Trikaal Leasing And Finance Ltd" },
  { value: "Trikal Finance Ltd", label: "Trikal Finance Ltd" },
  { value: "Trikal Leasing & Finance Co.Ltd", label: "Trikal Leasing & Finance Co.Ltd" },
  { value: "Trikarpur Farmers` Service Co-Op. Bank L", label: "Trikarpur Farmers` Service Co-Op. Bank L" },
  { value: "Trikuph Auto Sales Pvt Ltd.", label: "Trikuph Auto Sales Pvt Ltd." },
  { value: "Trilocknath Co Op Credit Socety", label: "Trilocknath Co Op Credit Socety" },
  { value: "Triloknath Co Op Cr.Soc Ltd", label: "Triloknath Co Op Cr.Soc Ltd" },
  { value: "Triloknath Co-Op Soc Ltd", label: "Triloknath Co-Op Soc Ltd" },
  { value: "Trimoorti Sahakari Bank Ltd", label: "Trimoorti Sahakari Bank Ltd" },
  { value: "Trimurti Financers", label: "Trimurti Financers" },
  { value: "Trimurti Gramin Bigar Sheti Sah Patsanstha", label: "Trimurti Gramin Bigar Sheti Sah Patsanstha" },
  { value: "Trinayan Hire Purchase Co", label: "Trinayan Hire Purchase Co" },
  { value: "Trinity Co-Op Urban Bank Ltd", label: "Trinity Co-Op Urban Bank Ltd" },
  { value: "Tripati Finance Corporation", label: "Tripati Finance Corporation" },
  { value: "Tripund Motor & General Finance Ltd", label: "Tripund Motor & General Finance Ltd" },
  { value: "Tripura Agricultural Urban Bank Ltd", label: "Tripura Agricultural Urban Bank Ltd" },
  { value: "Tripura Forest Development And Plantation Corporation Limited", label: "Tripura Forest Development And Plantation Corporation Limited" },
  { value: "Tripura Gramin Bank", label: "Tripura Gramin Bank" },
  { value: "Tripura Gramin Bank Ltd", label: "Tripura Gramin Bank Ltd" },
  { value: "Tripura Industrial Development Corporation", label: "Tripura Industrial Development Corporation" },
  { value: "Tripura Industrial Development Corporation Limited", label: "Tripura Industrial Development Corporation Limited" },
  { value: "Tripura S T Co Operative Dev", label: "Tripura S T Co Operative Dev" },
  { value: "Tripura State Central Co-Oprative Bank Ltd.", label: "Tripura State Central Co-Oprative Bank Ltd." },
  { value: "Tripura State Co-Op. Bank Ltd", label: "Tripura State Co-Op. Bank Ltd" },
  { value: "Tripura State Tourism Development Corporation Ltd.", label: "Tripura State Tourism Development Corporation Ltd." },
  { value: "Tripura Tea Development Corporation Limited", label: "Tripura Tea Development Corporation Limited" },
  { value: "Trishul Motors & General Finance Ltd", label: "Trishul Motors & General Finance Ltd" },
  { value: "Trissur District Central Co-Operative Bank Ltd.", label: "Trissur District Central Co-Operative Bank Ltd." },
  { value: "Tristar Trading Pvt Ltd", label: "Tristar Trading Pvt Ltd" },
  { value: "Trivandrum Co-Op Urban Bank Ltd.", label: "Trivandrum Co-Op Urban Bank Ltd." },
  { value: "Trivandrum Dist. Co-Op Bank Ltd.", label: "Trivandrum Dist. Co-Op Bank Ltd." },
  { value: "Trivani K Gramin Bank.", label: "Trivani K Gramin Bank." },
  { value: "Trivani Motors And Co.", label: "Trivani Motors And Co." },
  { value: "Triveni Gramin Bank", label: "Triveni Gramin Bank" },
  { value: "Triveni Structurals Ltd.", label: "Triveni Structurals Ltd." },
  { value: "True Blue Finlease Limited", label: "True Blue Finlease Limited" },
  { value: "True Link Finance Pvt. Ltd", label: "True Link Finance Pvt. Ltd" },
  { value: "Truelife Insurance Consultants Pvt Ltd", label: "Truelife Insurance Consultants Pvt Ltd" },
  { value: "Tubeknit Fashions Ltd", label: "Tubeknit Fashions Ltd" },
  { value: "Tudiye Gramin Bigar Sheti Patsanstha Maryadit", label: "Tudiye Gramin Bigar Sheti Patsanstha Maryadit" },
  { value: "Tukkanatti P K P Sahakari Niyamit", label: "Tukkanatti P K P Sahakari Niyamit" },
  { value: "Tulasi Auto", label: "Tulasi Auto" },
  { value: "Tulasi Motors Gangavathi", label: "Tulasi Motors Gangavathi" },
  { value: "Tuli Finance Co.", label: "Tuli Finance Co." },
  { value: "Tulshibag Nagari Sah Pat Sanstha Maryadit", label: "Tulshibag Nagari Sah Pat Sanstha Maryadit" },
  { value: "Tulsi  Gramin Bank", label: "Tulsi  Gramin Bank" },
  { value: "Tulsi Auto Finance", label: "Tulsi Auto Finance" },
  { value: "Tulsi Enterprise", label: "Tulsi Enterprise" },
  { value: "Tulsi Finance Corporation", label: "Tulsi Finance Corporation" },
  { value: "Tulsi Kshetriya Gramin Bank", label: "Tulsi Kshetriya Gramin Bank" },
  { value: "Tulunadu Finance And Developements Ltd", label: "Tulunadu Finance And Developements Ltd" },
  { value: "Tumba Auto Humnabad", label: "Tumba Auto Humnabad" },
  { value: "Tumkur District Central Co-Operative Bank Ltd.", label: "Tumkur District Central Co-Operative Bank Ltd." },
  { value: "Tumkur Grain Merchants Co-Op Bank Ltd", label: "Tumkur Grain Merchants Co-Op Bank Ltd" },
  { value: "Tumkur Grain Merchants Co Operative Bank", label: "Tumkur Grain Merchants Co Operative Bank" },
  { value: "Tumkur Grain Merchants Co-Op Bank Ltd", label: "Tumkur Grain Merchants Co-Op Bank Ltd" },
  { value: "Tumkur Merchants Souharda Co-Operative", label: "Tumkur Merchants Souharda Co-Operative" },
  { value: "Tumkur Merchants Sowhards Credit Co-Op Ltd", label: "Tumkur Merchants Sowhards Credit Co-Op Ltd" },
  { value: "Tumkur Pattana Sahakara Bank Niyamitha", label: "Tumkur Pattana Sahakara Bank Niyamitha" },
  { value: "Tumkur Veerashaiva Co-Op Bank Ltd", label: "Tumkur Veerashaiva Co-Op Bank Ltd" },
  { value: "Tumkur Veerashaiva Co-Operative Bank Ltd", label: "Tumkur Veerashaiva Co-Operative Bank Ltd" },
  { value: "Tuneri Block Employees Co-Op Society", label: "Tuneri Block Employees Co-Op Society" },
  { value: "Tungabhadra Credit Co-Op Society Ltd.", label: "Tungabhadra Credit Co-Op Society Ltd." },
  { value: "Tungabhadra Pattina Sahakara Sangha Ni", label: "Tungabhadra Pattina Sahakara Sangha Ni" },
  { value: "Tungabhadra Steel Products Ltd.", label: "Tungabhadra Steel Products Ltd." },
  { value: "Tura Urban Co-Op Bank Ltd", label: "Tura Urban Co-Op Bank Ltd" },
  { value: "Tura Urban Co-Operative Bank", label: "Tura Urban Co-Operative Bank" },
  { value: "Tusar Intra Pvt Ltd", label: "Tusar Intra Pvt Ltd" },
  { value: "Tushar Bharatbhai Shah", label: "Tushar Bharatbhai Shah" },
  { value: "Tuticorin Co Operative Bank Ltd.", label: "Tuticorin Co Operative Bank Ltd." },
  { value: "Tuticorin Port Trust", label: "Tuticorin Port Trust" },
  { value: "Tv Sundram Iyengaar & Sons Ltd", label: "Tv Sundram Iyengaar & Sons Ltd" },
  { value: "Tvc Bank Ltd Tumkur", label: "Tvc Bank Ltd Tumkur" },
  { value: "Tvs Auto Finance", label: "Tvs Auto Finance" },
  { value: "Tvs Auto Finance", label: "Tvs Auto Finance" },
  { value: "Tvs Finance & Services Ltd", label: "Tvs Finance & Services Ltd" },
  { value: "Tvs Finance Ltd.", label: "Tvs Finance Ltd." },
  { value: "Tvs Sundaram", label: "Tvs Sundaram" },
  { value: "Twin Cities Co-Op. Urban Bank Ltd.", label: "Twin Cities Co-Op. Urban Bank Ltd." },
  { value: "Twin Cities Investments And Finances Ltd.", label: "Twin Cities Investments And Finances Ltd." },
  { value: "Twinkle Car Center", label: "Twinkle Car Center" },
  { value: "Two Friends Fin Company", label: "Two Friends Fin Company" },
  { value: "Ty.Spl.191, Primary Agricultural Cp Bk L", label: "Ty.Spl.191, Primary Agricultural Cp Bk L" },
  { value: "Tyre Corporation Of India Ltd.", label: "Tyre Corporation Of India Ltd." },
  { value: "U A K V V Nigam Pauri", label: "U A K V V Nigam Pauri" },
  { value: "U F Z Port Folio Pvt Ltd", label: "U F Z Port Folio Pvt Ltd" },
  { value: "U G B", label: "U G B" },
  { value: "U K Co-Operative Credit Society Ltd", label: "U K Co-Operative Credit Society Ltd" },
  { value: "U K Finance Co", label: "U K Finance Co" },
  { value: "U K Finance Ltd", label: "U K Finance Ltd" },
  { value: "U Mahendra Kumar Bohara", label: "U Mahendra Kumar Bohara" },
  { value: "U N Credits Ltd.", label: "U N Credits Ltd." },
  { value: "U P Instalments Ltd.", label: "U P Instalments Ltd." },
  { value: "U P S C", label: "U P S C" },
  { value: "U P S G V Bank Ltd", label: "U P S G V Bank Ltd" },
  { value: "U P S Gram Vikas Bank Ltd.", label: "U P S Gram Vikas Bank Ltd." },
  { value: "U P Sahakari Gramin Vikas Bank Ltd", label: "U P Sahakari Gramin Vikas Bank Ltd" },
  { value: "U P Sahkari Gramin Vikas Bank Ltd.", label: "U P Sahkari Gramin Vikas Bank Ltd." },
  { value: "U Ranjeet Munoth", label: "U Ranjeet Munoth" },
  { value: "U S Enterprise", label: "U S Enterprise" },
  { value: "U Surender Kumar Bohara Financier", label: "U Surender Kumar Bohara Financier" },
  { value: "U T Enterprise", label: "U T Enterprise" },
  { value: "U T Leasingh Ltd", label: "U T Leasingh Ltd" },
  { value: "U T Motors", label: "U T Motors" },
  { value: "U Tharun Jain And Sons Financier", label: "U Tharun Jain And Sons Financier" },
  { value: "U Vinod Kumar Jain Financier", label: "U Vinod Kumar Jain Financier" },
  { value: "U. Akash Kumar Bokadia And Sons", label: "U. Akash Kumar Bokadia And Sons" },
  { value: "U. Nirmala Kumari Financiers", label: "U. Nirmala Kumari Financiers" },
  { value: "U.C Co-Op Bank Ltd", label: "U.C Co-Op Bank Ltd" },
  { value: "U.K Finance & Investment Ltd", label: "U.K Finance & Investment Ltd" },
  { value: "U.Mahendra Kumar And Sons", label: "U.Mahendra Kumar And Sons" },
  { value: "U.P Ansuchit Jativitta And Vikas Nigam Ltd", label: "U.P Ansuchit Jativitta And Vikas Nigam Ltd" },
  { value: "U.P Drugs And Pharmaceuticals Ltd.", label: "U.P Drugs And Pharmaceuticals Ltd." },
  { value: "U.P. Co-Op Bank Ltd.", label: "U.P. Co-Op Bank Ltd." },
  { value: "U.P. Financiers Hapur", label: "U.P. Financiers Hapur" },
  { value: "U.P.R.S. Land Development Bank", label: "U.P.R.S. Land Development Bank" },
  { value: "U.P.S.G.V Bank Ltd", label: "U.P.S.G.V Bank Ltd" },
  { value: "U.P.S.T. Fd. Corp Ltd", label: "U.P.S.T. Fd. Corp Ltd" },
  { value: "U.R.Prakash", label: "U.R.Prakash" },
  { value: "U.T.I.Auto", label: "U.T.I.Auto" },
  { value: "Uae Exchange & Financial", label: "Uae Exchange & Financial" },
  { value: "Uahaya Finance", label: "Uahaya Finance" },
  { value: "Udai Finance", label: "Udai Finance" },
  { value: "Udaipur District Central Co-Operative Bank Ltd.", label: "Udaipur District Central Co-Operative Bank Ltd." },
  { value: "Udaipur Mahila Samridhi Urban Co-Op Bank Ltd", label: "Udaipur Mahila Samridhi Urban Co-Op Bank Ltd" },
  { value: "Udaipur Mahila Urban Co-Op Bank Ltd", label: "Udaipur Mahila Urban Co-Op Bank Ltd" },
  { value: "Udaipur Urban Co-Op Bank Ltd", label: "Udaipur Urban Co-Op Bank Ltd" },
  { value: "Udaiyaar Finance", label: "Udaiyaar Finance" },
  { value: "Uday Nagari Sah Patsanstha", label: "Uday Nagari Sah Patsanstha" },
  { value: "Uday V Doshi", label: "Uday V Doshi" },
  { value: "Udaya Auto Finance", label: "Udaya Auto Finance" },
  { value: "Udaya Finance", label: "Udaya Finance" },
  { value: "Udaya Hire Purchasing Co.", label: "Udaya Hire Purchasing Co." },
  { value: "Udaya Sahakari Bank Niyamit", label: "Udaya Sahakari Bank Niyamit" },
  { value: "Udayaa Two Wheeler Finance", label: "Udayaa Two Wheeler Finance" },
  { value: "Udayam Auto Finance", label: "Udayam Auto Finance" },
  { value: "Udayapatty Primary Agri. Co.Op. Bank Ltd", label: "Udayapatty Primary Agri. Co.Op. Bank Ltd" },
  { value: "Udham Singh Nagar Dist Co-Op Bank Ltd,", label: "Udham Singh Nagar Dist Co-Op Bank Ltd," },
  { value: "Udham Singh Nagar District Co- Operative Bank Ltd", label: "Udham Singh Nagar District Co- Operative Bank Ltd" },
  { value: "Udhana Citizen Bank", label: "Udhana Citizen Bank" },
  { value: "Udhna Citizen Co Op Bank Ltd,Surat", label: "Udhna Citizen Co Op Bank Ltd,Surat" },
  { value: "Udhna Citizen Co-Op Bank Ltd", label: "Udhna Citizen Co-Op Bank Ltd" },
  { value: "Udita Finance & Leasing Co Ltd.", label: "Udita Finance & Leasing Co Ltd." },
  { value: "Udupi Catholic Credit Co-Op Society Ltd", label: "Udupi Catholic Credit Co-Op Society Ltd" },
  { value: "Udupi Co-Op Town Bank Ltd", label: "Udupi Co-Op Town Bank Ltd" },
  { value: "Udupi Co-Op Town Bank Ltd - Main Branch Udupi", label: "Udupi Co-Op Town Bank Ltd - Main Branch Udupi" },
  { value: "Udupi Credit Co-Op Society Ltd", label: "Udupi Credit Co-Op Society Ltd" },
  { value: "Udupi Mudrakara Souharda Sahakari", label: "Udupi Mudrakara Souharda Sahakari" },
  { value: "Udupi Taluk Hindu Co Operative Society Ltd.", label: "Udupi Taluk Hindu Co Operative Society Ltd." },
  { value: "Udupi Taluk Indu Co-Op Society Ltd Main Branch Moodubelle", label: "Udupi Taluk Indu Co-Op Society Ltd Main Branch Moodubelle" },
  { value: "Udupi Taluk Indu Co-Op Society Ltd Main Branch Udupi", label: "Udupi Taluk Indu Co-Op Society Ltd Main Branch Udupi" },
  { value: "Udupi Taluq Indu Co-Op Society Ltd - Kinnimulky", label: "Udupi Taluq Indu Co-Op Society Ltd - Kinnimulky" },
  { value: "Udupi Taluq Prostestant Christian Credit Co-Op Ltd - Bailur - Udupi", label: "Udupi Taluq Prostestant Christian Credit Co-Op Ltd - Bailur - Udupi" },
  { value: "Udupi Tq Industrial Co-Op Society Ltd", label: "Udupi Tq Industrial Co-Op Society Ltd" },
  { value: "Udyam Vikas Sahakari Bank Ltd., Pune", label: "Udyam Vikas Sahakari Bank Ltd., Pune" },
  { value: "Ue Countrywide Consumer Fin Ser Ltd", label: "Ue Countrywide Consumer Fin Ser Ltd" },
  { value: "Uf Japan Bank Ltd", label: "Uf Japan Bank Ltd" },
  { value: "Ufj Bank Ltd", label: "Ufj Bank Ltd" },
  { value: "Ufl Port Folio Ltd", label: "Ufl Port Folio Ltd" },
  { value: "Ugargol Prathamika Krishi Pattina Sahakari Sangh Niyamit", label: "Ugargol Prathamika Krishi Pattina Sahakari Sangh Niyamit" },
  { value: "Uiic", label: "Uiic" },
  { value: "Uij Bank", label: "Uij Bank" },
  { value: "Ujagar Advances Pvt. Ltd", label: "Ujagar Advances Pvt. Ltd" },
  { value: "Ujala Finance", label: "Ujala Finance" },
  { value: "Ujjain Audhyogik Vikas Nag.Sah.Bank Ltd.", label: "Ujjain Audhyogik Vikas Nag.Sah.Bank Ltd." },
  { value: "Ujjain Audhyogik Vikas Nagarik Shakari Bank Ltd", label: "Ujjain Audhyogik Vikas Nagarik Shakari Bank Ltd" },
  { value: "Ujjain District Central Co-Op Bank Ltd", label: "Ujjain District Central Co-Op Bank Ltd" },
  { value: "Ujjain Nagarik Sahakari Bank Ltd", label: "Ujjain Nagarik Sahakari Bank Ltd" },
  { value: "Ujjain Nagarik Sahakari Bank Maryadit", label: "Ujjain Nagarik Sahakari Bank Maryadit" },
  { value: "Ujjain Nagarik Sahakari Pedhi Mydt.", label: "Ujjain Nagarik Sahakari Pedhi Mydt." },
  { value: "Ujjain Paraspur Sahakari Bank Ltd", label: "Ujjain Paraspur Sahakari Bank Ltd" },
  { value: "Ujjain Parspar Sahakari Bank Ltd", label: "Ujjain Parspar Sahakari Bank Ltd" },
  { value: "Ujval Finance Corporation", label: "Ujval Finance Corporation" },
  { value: "Ujvar Co-Operative Bank Ltd", label: "Ujvar Co-Operative Bank Ltd" },
  { value: "Ujwala Hire Purchase & Leasing", label: "Ujwala Hire Purchase & Leasing" },
  { value: "Ujwala Jyothi Hire Purchase  Finance Ltd", label: "Ujwala Jyothi Hire Purchase  Finance Ltd" },
  { value: "Ujwala Mens Thrifts Co Operative Society", label: "Ujwala Mens Thrifts Co Operative Society" },
  { value: "Ujwar Co-Operative Bank Ltd", label: "Ujwar Co-Operative Bank Ltd" },
  { value: "Uklana Finanace And Leasing", label: "Uklana Finanace And Leasing" },
  { value: "Ul Investments", label: "Ul Investments" },
  { value: "Ulavan Co Finance", label: "Ulavan Co Finance" },
  { value: "Ulavan Finance", label: "Ulavan Finance" },
  { value: "Ulimangalam Primary Agrl.Co-Op.Bk.", label: "Ulimangalam Primary Agrl.Co-Op.Bk." },
  { value: "Ulliyeri Service Co-Operative Bank Ltd.", label: "Ulliyeri Service Co-Operative Bank Ltd." },
  { value: "Ulloor Services Co-Operative Bank Ltd", label: "Ulloor Services Co-Operative Bank Ltd" },
  { value: "Ultimate Flex Ltd", label: "Ultimate Flex Ltd" },
  { value: "Ultra Finance Ltd.", label: "Ultra Finance Ltd." },
  { value: "Uma Co-Op Bank Ltd.", label: "Uma Co-Op Bank Ltd." },
  { value: "Uma Co-Operative Bank Ltd", label: "Uma Co-Operative Bank Ltd" },
  { value: "Uma Finance", label: "Uma Finance" },
  { value: "Uma Ghosh", label: "Uma Ghosh" },
  { value: "Uma Investment", label: "Uma Investment" },
  { value: "Uma Prakash", label: "Uma Prakash" },
  { value: "Umang Leasing And Credit Co. Ltd.", label: "Umang Leasing And Credit Co. Ltd." },
  { value: "Umasa Chavan Nag. Pat. Sanstha", label: "Umasa Chavan Nag. Pat. Sanstha" },
  { value: "Umayanalloor Service Co-Op Bank Ltd.", label: "Umayanalloor Service Co-Op Bank Ltd." },
  { value: "Umed Finance Ltd", label: "Umed Finance Ltd" },
  { value: "Umedpuri Auto Finance", label: "Umedpuri Auto Finance" },
  { value: "Umeed Investments  (P)  Ltd", label: "Umeed Investments  (P)  Ltd" },
  { value: "Umeed Kanwar Financier", label: "Umeed Kanwar Financier" },
  { value: "Umeed Leasing And Finance Pvt Ltd.", label: "Umeed Leasing And Finance Pvt Ltd." },
  { value: "Umesh Auto Finance", label: "Umesh Auto Finance" },
  { value: "Umiya Co Op Finance", label: "Umiya Co Op Finance" },
  { value: "Umiya Enterprises", label: "Umiya Enterprises" },
  { value: "Umiya Finance", label: "Umiya Finance" },
  { value: "Umiya Finance Corporation", label: "Umiya Finance Corporation" },
  { value: "Umiya Leasing & Finance Corporation", label: "Umiya Leasing & Finance Corporation" },
  { value: "Umiya Urban Co-Operative Bank Mydt.", label: "Umiya Urban Co-Operative Bank Mydt." },
  { value: "Umreth Urban Co-Op Bank Ltd", label: "Umreth Urban Co-Op Bank Ltd" },
  { value: "Unava Nagarik Sahakari Bank Ltd", label: "Unava Nagarik Sahakari Bank Ltd" },
  { value: "Uncle Auto Deal", label: "Uncle Auto Deal" },
  { value: "Und Vereins Bank Ag", label: "Und Vereins Bank Ag" },
  { value: "Under Finance Agreement With Magma Leasing Ltd", label: "Under Finance Agreement With Magma Leasing Ltd" },
  { value: "Undri Gramin Bigar Krushi Sahakari Patsanstha", label: "Undri Gramin Bigar Krushi Sahakari Patsanstha" },
  { value: "Union Bank", label: "Union Bank" },
  { value: "Union Bank Ltd", label: "Union Bank Ltd" },
  { value: "Union Bank Of India - Udupi", label: "Union Bank Of India - Udupi" },
  { value: "Union Bank Of Jhunjunu", label: "Union Bank Of Jhunjunu" },
  { value: "Union Co-Op Bank Ltd.", label: "Union Co-Op Bank Ltd." },
  { value: "Union Commercial Co-Op Bank Ltd", label: "Union Commercial Co-Op Bank Ltd" },
  { value: "Union Investments", label: "Union Investments" },
  { value: "Union Public Service Commission", label: "Union Public Service Commission" },
  { value: "Unique Finance", label: "Unique Finance" },
  { value: "Unique Finance & Company", label: "Unique Finance & Company" },
  { value: "Unique General Insurance", label: "Unique General Insurance" },
  { value: "Unique Vehicle Financiers", label: "Unique Vehicle Financiers" },
  { value: "Unit Trust Of India", label: "Unit Trust Of India" },
  { value: "Unit Trust Of India", label: "Unit Trust Of India" },
  { value: "Unitara Finance Ltd.", label: "Unitara Finance Ltd." },
  { value: "United", label: "United" },
  { value: "United Auto Finance", label: "United Auto Finance" },
  { value: "United Bank Of India Ltd.", label: "United Bank Of India Ltd." },
  { value: "United Co-Op Natc Society Ltd", label: "United Co-Op Natc Society Ltd" },
  { value: "United Co-Op. Bank Ltd., Ahmedabad", label: "United Co-Op. Bank Ltd., Ahmedabad" },
  { value: "United Commercial Bank", label: "United Commercial Bank" },
  { value: "United Commercial Bank", label: "United Commercial Bank" },
  { value: "United Commercial Co-Op Bank Ltd.", label: "United Commercial Co-Op Bank Ltd." },
  { value: "United Credit Ltd.", label: "United Credit Ltd." },
  { value: "United Enterprises", label: "United Enterprises" },
  { value: "United Finance & Investment", label: "United Finance & Investment" },
  { value: "United Finance Co.", label: "United Finance Co." },
  { value: "United Finance India Pvt Ltd.", label: "United Finance India Pvt Ltd." },
  { value: "United Finance(India)", label: "United Finance(India)" },
  { value: "United Financial Services", label: "United Financial Services" },
  { value: "United Hire Purchasing", label: "United Hire Purchasing" },
  { value: "United India Co-Operative Bank Ltd.", label: "United India Co-Operative Bank Ltd." },
  { value: "United India Insurance Co. Limited", label: "United India Insurance Co. Limited" },
  { value: "United India Insurance Co.Ltd", label: "United India Insurance Co.Ltd" },
  { value: "United Industrial Bank Ltd", label: "United Industrial Bank Ltd" },
  { value: "United Industrial Bank Ltd.", label: "United Industrial Bank Ltd." },
  { value: "United Mercantile Bank", label: "United Mercantile Bank" },
  { value: "United Mercantile Co-Op Bank Ltd", label: "United Mercantile Co-Op Bank Ltd" },
  { value: "United Mercantile Co-Op. Bank Ltd.", label: "United Mercantile Co-Op. Bank Ltd." },
  { value: "United Mercantile Co-Op.Bank Ltd.", label: "United Mercantile Co-Op.Bank Ltd." },
  { value: "United Nations Educational Scientific And Cultural Organization", label: "United Nations Educational Scientific And Cultural Organization" },
  { value: "United Overseas Finance Ltd", label: "United Overseas Finance Ltd" },
  { value: "United Puri And Nimtara District Central Co-Operative Bank Ltd.", label: "United Puri And Nimtara District Central Co-Operative Bank Ltd." },
  { value: "United Risk Insurance Broking Co.Pv T.Ltd", label: "United Risk Insurance Broking Co.Pv T.Ltd" },
  { value: "United Scooters", label: "United Scooters" },
  { value: "United Sprit", label: "United Sprit" },
  { value: "United Utility Services", label: "United Utility Services" },
  { value: "United Western Bank Ltd.", label: "United Western Bank Ltd." },
  { value: "Unitek Enterprises", label: "Unitek Enterprises" },
  { value: "Unitrra Finance Ltd", label: "Unitrra Finance Ltd" },
  { value: "Unity Securities & Finance  Ltd", label: "Unity Securities & Finance  Ltd" },
  { value: "Unity Securities And Finance Ltd", label: "Unity Securities And Finance Ltd" },
  { value: "Unity Urben Co Op Credit Soc Ltd", label: "Unity Urben Co Op Credit Soc Ltd" },
  { value: "Universal Auto Investment", label: "Universal Auto Investment" },
  { value: "Universal Capital", label: "Universal Capital" },
  { value: "Universal Co-Op Bank Ltd", label: "Universal Co-Op Bank Ltd" },
  { value: "Universal Co-Operative Urban Bank Ltd.", label: "Universal Co-Operative Urban Bank Ltd." },
  { value: "Universal Finance", label: "Universal Finance" },
  { value: "Universal Insurance Consultant", label: "Universal Insurance Consultant" },
  { value: "Universal Malt Purpose Co-Operative Society Ltd.", label: "Universal Malt Purpose Co-Operative Society Ltd." },
  { value: "Universal Sompo General Insurance Co", label: "Universal Sompo General Insurance Co" },
  { value: "University - Indira Gandhi National Open", label: "University - Indira Gandhi National Open" },
  { value: "University - Jawaharlal Nehru Technological", label: "University - Jawaharlal Nehru Technological" },
  { value: "University - Maharaja Sayajirao University Of Baroda", label: "University - Maharaja Sayajirao University Of Baroda" },
  { value: "University - Mohan Lal Sukhadia University, Udaipur", label: "University - Mohan Lal Sukhadia University, Udaipur" },
  { value: "University - Punjab Technical", label: "University - Punjab Technical" },
  { value: "University - Punjabi", label: "University - Punjabi" },
  { value: "University - Rajiv Gandhi University Of Health Sciences", label: "University - Rajiv Gandhi University Of Health Sciences" },
  { value: "University - Swami Ramanand Teerth Marathawada", label: "University - Swami Ramanand Teerth Marathawada" },
  { value: "University - Tamil Nadu Dr. M.G.R. Medical", label: "University - Tamil Nadu Dr. M.G.R. Medical" },
  { value: "University - Tamil Nadu Veterinary And Animal Sciences", label: "University - Tamil Nadu Veterinary And Animal Sciences" },
  { value: "University - Visveswaraiah Technological", label: "University - Visveswaraiah Technological" },
  { value: "University Grants Commission", label: "University Grants Commission" },
  { value: "University Of Agricultural Sciences", label: "University Of Agricultural Sciences" },
  { value: "University Of Delhi", label: "University Of Delhi" },
  { value: "University Of Gujarat Agricultural", label: "University Of Gujarat Agricultural" },
  { value: "University Of Gulbarga", label: "University Of Gulbarga" },
  { value: "University Of Guru Gobind Singh Indraprastha", label: "University Of Guru Gobind Singh Indraprastha" },
  { value: "University Of Himachal Pradesh", label: "University Of Himachal Pradesh" },
  { value: "University Of Hyderabad", label: "University Of Hyderabad" },
  { value: "University Of Jadavpur", label: "University Of Jadavpur" },
  { value: "University Of Kerala", label: "University Of Kerala" },
  { value: "University Of Mumbai", label: "University Of Mumbai" },
  { value: "University Of Mysore", label: "University Of Mysore" },
  { value: "University Of Osmania", label: "University Of Osmania" },
  { value: "University Of Pondicherry", label: "University Of Pondicherry" },
  { value: "University Of Pune", label: "University Of Pune" },
  { value: "University Of Rajasthan", label: "University Of Rajasthan" },
  { value: "University Of Roorkee", label: "University Of Roorkee" },
  { value: "Unjha Nagrik Sahakari  Bank", label: "Unjha Nagrik Sahakari  Bank" },
  { value: "Unnati Co-Op Bank Ltd.", label: "Unnati Co-Op Bank Ltd." },
  { value: "Unnati Co-Op. Bank Ltd", label: "Unnati Co-Op. Bank Ltd" },
  { value: "Unnati Sauharda Sahakari Niyamit", label: "Unnati Sauharda Sahakari Niyamit" },
  { value: "Unnikulam Service Co-Op. Bank", label: "Unnikulam Service Co-Op. Bank" },
  { value: "Unntee Seva Sahakari Mandalee Ltd", label: "Unntee Seva Sahakari Mandalee Ltd" },
  { value: "Up Gramin Bank", label: "Up Gramin Bank" },
  { value: "Up Motor Financier", label: "Up Motor Financier" },
  { value: "Up R.S Alnd Devlopment Bank", label: "Up R.S Alnd Devlopment Bank" },
  { value: "Up Rajaya Sah Gram Vikash Bank", label: "Up Rajaya Sah Gram Vikash Bank" },
  { value: "Up Sahkari Bhumi Vikas Bank", label: "Up Sahkari Bhumi Vikas Bank" },
  { value: "Up Schedule Cast Finnace Ltd", label: "Up Schedule Cast Finnace Ltd" },
  { value: "Up Schedule.Tribe.Fin.Development Cor", label: "Up Schedule.Tribe.Fin.Development Cor" },
  { value: "Up Shedule Tribe Finance Development Cor.", label: "Up Shedule Tribe Finance Development Cor." },
  { value: "Up State Co-Op Bank Ltd", label: "Up State Co-Op Bank Ltd" },
  { value: "Up State Spinning Company Limited", label: "Up State Spinning Company Limited" },
  { value: "Upasana Finance Ltd.", label: "Upasana Finance Ltd." },
  { value: "Upasi Property Mart Pvt Ltd", label: "Upasi Property Mart Pvt Ltd" },
  { value: "Updhyan Chand Nahar", label: "Updhyan Chand Nahar" },
  { value: "Uphar Finvest Ltd.", label: "Uphar Finvest Ltd." },
  { value: "Upkar Finance Corporation", label: "Upkar Finance Corporation" },
  { value: "Upkar Finance Ltd.", label: "Upkar Finance Ltd." },
  { value: "Upkar Installment Ltd", label: "Upkar Installment Ltd" },
  { value: "Upkar Instalment Ltd.", label: "Upkar Instalment Ltd." },
  { value: "Uppar Assam Finance Co", label: "Uppar Assam Finance Co" },
  { value: "Upvan Leasing (P) Ltd.", label: "Upvan Leasing (P) Ltd." },
  { value: "Uranium Corporation Of India Limited", label: "Uranium Corporation Of India Limited" },
  { value: "Urban C.O.B.L,Bhalki", label: "Urban C.O.B.L,Bhalki" },
  { value: "Urban Co Op Bank Ganeshpur", label: "Urban Co Op Bank Ganeshpur" },
  { value: "Urban Co Op Santrampur Bank", label: "Urban Co Op Santrampur Bank" },
  { value: "Urban Co-Op Bank Ltd.", label: "Urban Co-Op Bank Ltd." },
  { value: "Urban Co-Op Bank Ltd.", label: "Urban Co-Op Bank Ltd." },
  { value: "Urban Co-Op Credit Society Ltd.", label: "Urban Co-Op Credit Society Ltd." },
  { value: "Urban Co-Op. Bank Ltd., Bareilly", label: "Urban Co-Op. Bank Ltd., Bareilly" },
  { value: "Urban Co-Op. Bank Ltd., Budaun", label: "Urban Co-Op. Bank Ltd., Budaun" },
  { value: "Urban Co-Op. Bank Ltd., Dehradun", label: "Urban Co-Op. Bank Ltd., Dehradun" },
  { value: "Urban Co-Operative Bank Ltd.", label: "Urban Co-Operative Bank Ltd." },
  { value: "Urban Co-Operative Bank Ltd.,Basti", label: "Urban Co-Operative Bank Ltd.,Basti" },
  { value: "Urban Co-Operative Credit Society Ltd.", label: "Urban Co-Operative Credit Society Ltd." },
  { value: "Urban Co.Op Bank Ltd", label: "Urban Co.Op Bank Ltd" },
  { value: "Urban Development Authority", label: "Urban Development Authority" },
  { value: "Urgent Finvest Ltd", label: "Urgent Finvest Ltd" },
  { value: "Urvashai Motors And General Finance", label: "Urvashai Motors And General Finance" },
  { value: "Usae India", label: "Usae India" },
  { value: "Usha", label: "Usha" },
  { value: "Usha & Co", label: "Usha & Co" },
  { value: "Usha Autospring Seat Pvt Ltd", label: "Usha Autospring Seat Pvt Ltd" },
  { value: "Usha Finance", label: "Usha Finance" },
  { value: "Usha Finance Corp", label: "Usha Finance Corp" },
  { value: "Usha International Ltd", label: "Usha International Ltd" },
  { value: "Usha Jagwani", label: "Usha Jagwani" },
  { value: "Usha Kiran Aggarwal", label: "Usha Kiran Aggarwal" },
  { value: "Usha Kumari", label: "Usha Kumari" },
  { value: "Usha Martin Finance Ltd (Summit Usha Martin Finance Ltd)", label: "Usha Martin Finance Ltd (Summit Usha Martin Finance Ltd)" },
  { value: "Ushabh Traders", label: "Ushabh Traders" },
  { value: "Ushabha Investments", label: "Ushabha Investments" },
  { value: "Ushatai Bade Gramin Sheth Pat Sant", label: "Ushatai Bade Gramin Sheth Pat Sant" },
  { value: "Ushbh Finance", label: "Ushbh Finance" },
  { value: "Usilampatti Co-Operative Urban Bank Ltd", label: "Usilampatti Co-Operative Urban Bank Ltd" },
  { value: "Uslabh Finance", label: "Uslabh Finance" },
  { value: "Usma Finlease Ltd", label: "Usma Finlease Ltd" },
  { value: "Usmanabad Janta Sahakari Bank", label: "Usmanabad Janta Sahakari Bank" },
  { value: "Ut Motors", label: "Ut Motors" },
  { value: "Uti Amc Ltd", label: "Uti Amc Ltd" },
  { value: "Uti Asset Management", label: "Uti Asset Management" },
  { value: "Uti Bank A/C Apeejay Finance Group Ltd", label: "Uti Bank A/C Apeejay Finance Group Ltd" },
  { value: "Uti Bank Acc Fin App Bank", label: "Uti Bank Acc Fin App Bank" },
  { value: "Uti Bank Focused Retail", label: "Uti Bank Focused Retail" },
  { value: "Uti Bank Ltd.", label: "Uti Bank Ltd." },
  { value: "Uti Bank Ltd.", label: "Uti Bank Ltd." },
  { value: "Uti Bank Ltd. A/C Appejay Fin. Group Ltd", label: "Uti Bank Ltd. A/C Appejay Fin. Group Ltd" },
  { value: "Utkai Ahhok Hotel Corpn. Ltd.", label: "Utkai Ahhok Hotel Corpn. Ltd." },
  { value: "Utkal Co-Op Banking Society", label: "Utkal Co-Op Banking Society" },
  { value: "Utkarsha Nagari Patsansta", label: "Utkarsha Nagari Patsansta" },
  { value: "Utkarsha Nagari Patsanstha Br.Yashwant Nagar", label: "Utkarsha Nagari Patsanstha Br.Yashwant Nagar" },
  { value: "Uttam", label: "Uttam" },
  { value: "Uttam And Company", label: "Uttam And Company" },
  { value: "Uttam Auto Finance", label: "Uttam Auto Finance" },
  { value: "Uttam Chand Arora Leasing & Finance Ltd", label: "Uttam Chand Arora Leasing & Finance Ltd" },
  { value: "Uttam Chand Bokdia", label: "Uttam Chand Bokdia" },
  { value: "Uttam Finance", label: "Uttam Finance" },
  { value: "Uttam Kr Kothary & Sons", label: "Uttam Kr Kothary & Sons" },
  { value: "Uttam Leasing And Capital Services Ltd.", label: "Uttam Leasing And Capital Services Ltd." },
  { value: "Uttamrao Dhikale Gra. Big. Sheti.", label: "Uttamrao Dhikale Gra. Big. Sheti." },
  { value: "Uttar Banga Kshetriya Gramin Bank", label: "Uttar Banga Kshetriya Gramin Bank" },
  { value: "Uttar Bihar Keshetriya Gramin Bank,", label: "Uttar Bihar Keshetriya Gramin Bank," },
  { value: "Uttar Bihar Kshetriya Gramin Bank", label: "Uttar Bihar Kshetriya Gramin Bank" },
  { value: "Uttar Gujarat Vij Co Ltd", label: "Uttar Gujarat Vij Co Ltd" },
  { value: "Uttar Kannad District Central Co-Operative Bank Ltd.", label: "Uttar Kannad District Central Co-Operative Bank Ltd." },
  { value: "Uttar Khestriya Gramin Bank", label: "Uttar Khestriya Gramin Bank" },
  { value: "Uttar Pradesh Co-Op Bank Ltd", label: "Uttar Pradesh Co-Op Bank Ltd" },
  { value: "Uttar Pradesh Financial Corporation", label: "Uttar Pradesh Financial Corporation" },
  { value: "Uttar Pradesh Gramin Bank Kiratpur", label: "Uttar Pradesh Gramin Bank Kiratpur" },
  { value: "Uttar Pradesh Gramya Vikas Bank Ltd", label: "Uttar Pradesh Gramya Vikas Bank Ltd" },
  { value: "Uttar Pradesh Sihkari Gram Vikas", label: "Uttar Pradesh Sihkari Gram Vikas" },
  { value: "Uttar Pradesh State Industrial Development Corporation", label: "Uttar Pradesh State Industrial Development Corporation" },
  { value: "Uttar Pradesh State Industrial Development Corporation Limited", label: "Uttar Pradesh State Industrial Development Corporation Limited" },
  { value: "Uttar Pradesh State Tourism Development Corporation Ltd.", label: "Uttar Pradesh State Tourism Development Corporation Ltd." },
  { value: "Uttar Purvi Baroda Bank", label: "Uttar Purvi Baroda Bank" },
  { value: "Uttaranchal Forest Development Corporation", label: "Uttaranchal Forest Development Corporation" },
  { value: "Uttaranchal Gramin Bank", label: "Uttaranchal Gramin Bank" },
  { value: "Uttaranchal Gramin Bank", label: "Uttaranchal Gramin Bank" },
  { value: "Uttaranchal State Tourism Development Corporation Ltd.", label: "Uttaranchal State Tourism Development Corporation Ltd." },
  { value: "Uttarbanga Shetriya Gramin Bank Ltd", label: "Uttarbanga Shetriya Gramin Bank Ltd" },
  { value: "Uttarkashi Zila Sahakari Bank Ltd.", label: "Uttarkashi Zila Sahakari Bank Ltd." },
  { value: "Uttarkhand Co-Op. Bank Ltd.", label: "Uttarkhand Co-Op. Bank Ltd." },
  { value: "Uttarpara Co-Op Bank Ltd", label: "Uttarpara Co-Op Bank Ltd" },
  { value: "Uttarsanda Peoples Co Op Bank", label: "Uttarsanda Peoples Co Op Bank" },
  { value: "Uttiramerur Primary C.O.Agricultural B.L", label: "Uttiramerur Primary C.O.Agricultural B.L" },
  { value: "Uwupgb", label: "Uwupgb" },
  { value: "V  K R Finance", label: "V  K R Finance" },
  { value: "V A P Finance Ltd.", label: "V A P Finance Ltd." },
  { value: "V And C Vaults And Finance Pvt Ltd", label: "V And C Vaults And Finance Pvt Ltd" },
  { value: "V And H Patel Finance Pvt Ltd", label: "V And H Patel Finance Pvt Ltd" },
  { value: "V C B Ltd", label: "V C B Ltd" },
  { value: "V D Investments", label: "V D Investments" },
  { value: "V K Enterprises", label: "V K Enterprises" },
  { value: "V K Finance", label: "V K Finance" },
  { value: "V K Finance And Development", label: "V K Finance And Development" },
  { value: "V K Finance And Investment Co", label: "V K Finance And Investment Co" },
  { value: "V K Finance Services Ltd", label: "V K Finance Services Ltd" },
  { value: "V Lalithamma", label: "V Lalithamma" },
  { value: "V Leasing Pvt Ltd", label: "V Leasing Pvt Ltd" },
  { value: "V M Finance Chennai", label: "V M Finance Chennai" },
  { value: "V M Financiers", label: "V M Financiers" },
  { value: "V Mohanlal Bohra Finance", label: "V Mohanlal Bohra Finance" },
  { value: "V N Credits Ltd", label: "V N Credits Ltd" },
  { value: "V N Motors", label: "V N Motors" },
  { value: "V N R Financers", label: "V N R Financers" },
  { value: "V P Auto Finance", label: "V P Auto Finance" },
  { value: "V P Finance Co.", label: "V P Finance Co." },
  { value: "V R Finance", label: "V R Finance" },
  { value: "V R Ramakrishnan", label: "V R Ramakrishnan" },
  { value: "V S K", label: "V S K" },
  { value: "V S S Bank Ltd", label: "V S S Bank Ltd" },
  { value: "V S S N", label: "V S S N" },
  { value: "V V D Finance And Leasing Ltd.", label: "V V D Finance And Leasing Ltd." },
  { value: "V V R Finance Corporation", label: "V V R Finance Corporation" },
  { value: "V Value4U Wealth Creators Private Limited", label: "V Value4U Wealth Creators Private Limited" },
  { value: "V.C Sivaprasad", label: "V.C Sivaprasad" },
  { value: "V.F.Finance", label: "V.F.Finance" },
  { value: "V.G.B Bank", label: "V.G.B Bank" },
  { value: "V.K. International", label: "V.K. International" },
  { value: "V.M.K. Financial Ltd", label: "V.M.K. Financial Ltd" },
  { value: "V.Mahendrakumar &Co.", label: "V.Mahendrakumar &Co." },
  { value: "V.S.S.B.N.", label: "V.S.S.B.N." },
  { value: "V.V Motors Finance Co", label: "V.V Motors Finance Co" },
  { value: "V.V.Giri National Labour Institute", label: "V.V.Giri National Labour Institute" },
  { value: "Vadagaon Urban Co-Op Bank Ltd", label: "Vadagaon Urban Co-Op Bank Ltd" },
  { value: "Vadakara Co-Op Bank Ltd", label: "Vadakara Co-Op Bank Ltd" },
  { value: "Vadakkanandal Pacb. Ltd", label: "Vadakkanandal Pacb. Ltd" },
  { value: "Vadakkandal P A C B Ltd,", label: "Vadakkandal P A C B Ltd," },
  { value: "Vadakkenchery Co-Op. Service Bank Ltd", label: "Vadakkenchery Co-Op. Service Bank Ltd" },
  { value: "Vadakkevila Service Co.Op. Bank Ltd", label: "Vadakkevila Service Co.Op. Bank Ltd" },
  { value: "Vadasada Seva Sahkari Mandli", label: "Vadasada Seva Sahkari Mandli" },
  { value: "Vadaserikara Ser Co-Op. Bank Ltd", label: "Vadaserikara Ser Co-Op. Bank Ltd" },
  { value: "Vadayar Service Co-Op. Bank Ltd", label: "Vadayar Service Co-Op. Bank Ltd" },
  { value: "Vadde Ashok", label: "Vadde Ashok" },
  { value: "Vadgaon Saraf Vnsps Maryadit", label: "Vadgaon Saraf Vnsps Maryadit" },
  { value: "Vadi Velan Finance", label: "Vadi Velan Finance" },
  { value: "Vadivel Auto Finance", label: "Vadivel Auto Finance" },
  { value: "Vadivelan Investments", label: "Vadivelan Investments" },
  { value: "Vadnagar Nagarik Sahakari Bank L.", label: "Vadnagar Nagarik Sahakari Bank L." },
  { value: "Vadnagar Nagarik Sahakari Bk.L.", label: "Vadnagar Nagarik Sahakari Bk.L." },
  { value: "Vadodara District Central Co-Operative Bank Ltd.", label: "Vadodara District Central Co-Operative Bank Ltd." },
  { value: "Vadodra Gujarat Gramin Bank", label: "Vadodra Gujarat Gramin Bank" },
  { value: "Vaghmare Finance Pvt Ltd.", label: "Vaghmare Finance Pvt Ltd." },
  { value: "Vahuthacaud Service Co-Op Bank Ltd", label: "Vahuthacaud Service Co-Op Bank Ltd" },
  { value: "Vaibhav Auto Finance", label: "Vaibhav Auto Finance" },
  { value: "Vaibhav Co Op Credit Society Ltd", label: "Vaibhav Co Op Credit Society Ltd" },
  { value: "Vaibhav Enterprise", label: "Vaibhav Enterprise" },
  { value: "Vaibhav Finance", label: "Vaibhav Finance" },
  { value: "Vaibhav Laxmi Motor Finance", label: "Vaibhav Laxmi Motor Finance" },
  { value: "Vaibhav Nagari Sahkari Pathsanstha Maryadit", label: "Vaibhav Nagari Sahkari Pathsanstha Maryadit" },
  { value: "Vaidehi Auto And Employees Finance", label: "Vaidehi Auto And Employees Finance" },
  { value: "Vaidyanath Urban Co-Op Bank Ltd", label: "Vaidyanath Urban Co-Op Bank Ltd" },
  { value: "Vaigai Finance", label: "Vaigai Finance" },
  { value: "Vaijapur Merchant Co-Op Bank Ltd", label: "Vaijapur Merchant Co-Op Bank Ltd" },
  { value: "Vaijay Jyothi Financiers (P) Ltd", label: "Vaijay Jyothi Financiers (P) Ltd" },
  { value: "Vaijayanthi Anchalia", label: "Vaijayanthi Anchalia" },
  { value: "Vaijpur Service Co-Op Bank Ltd.,", label: "Vaijpur Service Co-Op Bank Ltd.," },
  { value: "Vaikom Taluk Co Operative Agricultural And Rural Development Bank Ltd", label: "Vaikom Taluk Co Operative Agricultural And Rural Development Bank Ltd" },
  { value: "Vaikom Town Services Co-Op Bank Ltd", label: "Vaikom Town Services Co-Op Bank Ltd" },
  { value: "Vainganga Gramin Kshetriya Bank", label: "Vainganga Gramin Kshetriya Bank" },
  { value: "Vainganga Krishna Gramin Bank", label: "Vainganga Krishna Gramin Bank" },
  { value: "Vainganga Kshtirya Gramin Bank Ltd,", label: "Vainganga Kshtirya Gramin Bank Ltd," },
  { value: "Vairams Finance", label: "Vairams Finance" },
  { value: "Vairams Hire Purchase Pvt Ltd.", label: "Vairams Hire Purchase Pvt Ltd." },
  { value: "Vaish Co-Op Adarsh Bank Ltd.", label: "Vaish Co-Op Adarsh Bank Ltd." },
  { value: "Vaish Co-Op Commercial Bank Ltd", label: "Vaish Co-Op Commercial Bank Ltd" },
  { value: "Vaish Co-Op New Bank Ltd", label: "Vaish Co-Op New Bank Ltd" },
  { value: "Vaishai Urban Co-Op Bank Ltd", label: "Vaishai Urban Co-Op Bank Ltd" },
  { value: "Vaishali Bihar Kshetriya Gramin Bank", label: "Vaishali Bihar Kshetriya Gramin Bank" },
  { value: "Vaishali Kshetriya Gramin Bank", label: "Vaishali Kshetriya Gramin Bank" },
  { value: "Vaishali Motors Compny Ltd", label: "Vaishali Motors Compny Ltd" },
  { value: "Vaishali Motors Finance Ltd", label: "Vaishali Motors Finance Ltd" },
  { value: "Vaishali Sahari Vikash Co-Operative Bank", label: "Vaishali Sahari Vikash Co-Operative Bank" },
  { value: "Vaishali Urban Co-Op Bank Ltd", label: "Vaishali Urban Co-Op Bank Ltd" },
  { value: "Vaishali Urban Co.Op.Bank Ltd., Jaipur", label: "Vaishali Urban Co.Op.Bank Ltd., Jaipur" },
  { value: "Vaishnav Co Op Credit Society Ltd", label: "Vaishnav Co Op Credit Society Ltd" },
  { value: "Vaishnavi Finance Ltd", label: "Vaishnavi Finance Ltd" },
  { value: "Vaishnavi Nagrik Sahakari Pathsanstha Ltd", label: "Vaishnavi Nagrik Sahakari Pathsanstha Ltd" },
  { value: "Vaishno Motor And Finance", label: "Vaishno Motor And Finance" },
  { value: "Vaishno Motors.", label: "Vaishno Motors." },
  { value: "Vaishya Junner Sah. Pat. Maryadit", label: "Vaishya Junner Sah. Pat. Maryadit" },
  { value: "Vaishya Nag Sah Pat San Mydt", label: "Vaishya Nag Sah Pat San Mydt" },
  { value: "Vaishya Sahakari Bank Ltd.", label: "Vaishya Sahakari Bank Ltd." },
  { value: "Vaishya Sahakari Bank Ltd.", label: "Vaishya Sahakari Bank Ltd." },
  { value: "Vakharbhag Nag Sah Sanstha", label: "Vakharbhag Nag Sah Sanstha" },
  { value: "Vakkom Farmers Ser.C.P.Bank Ltd", label: "Vakkom Farmers Ser.C.P.Bank Ltd" },
  { value: "Valampuri Auto Finance", label: "Valampuri Auto Finance" },
  { value: "Valampuri Capitals", label: "Valampuri Capitals" },
  { value: "Valapad Service Co-Op Bank Ltd", label: "Valapad Service Co-Op Bank Ltd" },
  { value: "Valiyakuzhi Service Co-Operative Bk Ltd", label: "Valiyakuzhi Service Co-Operative Bk Ltd" },
  { value: "Vallabh Finance", label: "Vallabh Finance" },
  { value: "Vallabh Vidyanagar Commercial Co-Op Bank Ltd", label: "Vallabh Vidyanagar Commercial Co-Op Bank Ltd" },
  { value: "Vallalar Grama Bank", label: "Vallalar Grama Bank" },
  { value: "Vallamkulam Services Co-Op. Bank Ltd.", label: "Vallamkulam Services Co-Op. Bank Ltd." },
  { value: "Vallanghy Vithanassery Serv Cp Bank Ltd", label: "Vallanghy Vithanassery Serv Cp Bank Ltd" },
  { value: "Valli Murugan Finance", label: "Valli Murugan Finance" },
  { value: "Valluramma Auto Finance", label: "Valluramma Auto Finance" },
  { value: "Valluvar Finance Ltd.", label: "Valluvar Finance Ltd." },
  { value: "Valmiki Urban Coop Bank Ltd", label: "Valmiki Urban Coop Bank Ltd" },
  { value: "Valsad Dang Gramin Bank", label: "Valsad Dang Gramin Bank" },
  { value: "Valsad Dist Cen Co-Op Bank L", label: "Valsad Dist Cen Co-Op Bank L" },
  { value: "Valsad Dist. Central Co.Op. Bank Ltd", label: "Valsad Dist. Central Co.Op. Bank Ltd" },
  { value: "Valsad District Central Co-Op Bank Ltd", label: "Valsad District Central Co-Op Bank Ltd" },
  { value: "Valsad District Central Co-Op Bk L,", label: "Valsad District Central Co-Op Bk L," },
  { value: "Valsad District Central Co-Operative Bank Ltd.", label: "Valsad District Central Co-Operative Bank Ltd." },
  { value: "Valsad Jilla Sahakari Bank Ltd", label: "Valsad Jilla Sahakari Bank Ltd" },
  { value: "Valsad Mahila Nagarik Sahakari Bank Ltd", label: "Valsad Mahila Nagarik Sahakari Bank Ltd" },
  { value: "Valsad Mahila Nagrik Sahakari Bank Ltd.", label: "Valsad Mahila Nagrik Sahakari Bank Ltd." },
  { value: "Valsad-Dangs Gramin Bank", label: "Valsad-Dangs Gramin Bank" },
  { value: "Vamanapuram Service Co-Op. Bank Ltd.", label: "Vamanapuram Service Co-Op. Bank Ltd." },
  { value: "Vamsi Krishna A/F", label: "Vamsi Krishna A/F" },
  { value: "Vamsi Krishna Automobile Finance", label: "Vamsi Krishna Automobile Finance" },
  { value: "Vamsi Krishna Finance Corporation", label: "Vamsi Krishna Finance Corporation" },
  { value: "Vanchal Finvest Pvt Ltd", label: "Vanchal Finvest Pvt Ltd" },
  { value: "Vanchinad Finance (P) Ltd", label: "Vanchinad Finance (P) Ltd" },
  { value: "Vandana", label: "Vandana" },
  { value: "Vandana Enterprises", label: "Vandana Enterprises" },
  { value: "Vandana Finance", label: "Vandana Finance" },
  { value: "Vandana Finance Corporation", label: "Vandana Finance Corporation" },
  { value: "Vandanmettu Service Co-Operative Bank Lt", label: "Vandanmettu Service Co-Operative Bank Lt" },
  { value: "Vandavasi Primary Agrl. Co-Op. Bank. Ltd", label: "Vandavasi Primary Agrl. Co-Op. Bank. Ltd" },
  { value: "Vandemataram Nagari Sahakari Patsanstha", label: "Vandemataram Nagari Sahakari Patsanstha" },
  { value: "Vandhana Finance", label: "Vandhana Finance" },
  { value: "Vani Financiers", label: "Vani Financiers" },
  { value: "Vani Merchants Co-Op Bank Ltd", label: "Vani Merchants Co-Op Bank Ltd" },
  { value: "Vani Vilas Credit Co Operative Society Ltd", label: "Vani Vilas Credit Co Operative Society Ltd" },
  { value: "Vaniaha Finance", label: "Vaniaha Finance" },
  { value: "Vanita Mahila Gramin Sahakari Pat Sanstha", label: "Vanita Mahila Gramin Sahakari Pat Sanstha" },
  { value: "Vanitha Finance", label: "Vanitha Finance" },
  { value: "Vanitha Surana", label: "Vanitha Surana" },
  { value: "Vaniyambadi Town Co-Op Bank Ltd", label: "Vaniyambadi Town Co-Op Bank Ltd" },
  { value: "Vankineni Hire Purchase And Leasing Pvt. Ltd.", label: "Vankineni Hire Purchase And Leasing Pvt. Ltd." },
  { value: "Vannappuram Service Co-Op Bank", label: "Vannappuram Service Co-Op Bank" },
  { value: "Vara Associates", label: "Vara Associates" },
  { value: "Vara Siddi Vinayaka Auto Finance", label: "Vara Siddi Vinayaka Auto Finance" },
  { value: "Vara Siddi Vinayaka Finance", label: "Vara Siddi Vinayaka Finance" },
  { value: "Varachha Co-Op Bank Ltd", label: "Varachha Co-Op Bank Ltd" },
  { value: "Varachha Urban Co.Op.Credit Society Ltd", label: "Varachha Urban Co.Op.Credit Society Ltd" },
  { value: "Varad Vinayak Nagri Sah Pat San Ahmednagar", label: "Varad Vinayak Nagri Sah Pat San Ahmednagar" },
  { value: "Varad Vinayak Sahkari Patsanstha Maryadit", label: "Varad Vinayak Sahkari Patsanstha Maryadit" },
  { value: "Varada Co-Op. Bank Ltd.", label: "Varada Co-Op. Bank Ltd." },
  { value: "Varada Grameena Bank", label: "Varada Grameena Bank" },
  { value: "Varadhan Syndicate", label: "Varadhan Syndicate" },
  { value: "Varadvinayak Sahkari Patsanstha Maryadit", label: "Varadvinayak Sahkari Patsanstha Maryadit" },
  { value: "Varal Patil Sahakari Patsanstha Parner", label: "Varal Patil Sahakari Patsanstha Parner" },
  { value: "Varalakshmi Autofinance", label: "Varalakshmi Autofinance" },
  { value: "Varalakshmi Credit Sahakara Sangha", label: "Varalakshmi Credit Sahakara Sangha" },
  { value: "Varalaxmi Credit Co-Op Bank Ltd.", label: "Varalaxmi Credit Co-Op Bank Ltd." },
  { value: "Varan Shhakari Bank", label: "Varan Shhakari Bank" },
  { value: "Varanam Service Co-Op Bank Ltd,", label: "Varanam Service Co-Op Bank Ltd," },
  { value: "Varappetty Service Co Bank Ltd,", label: "Varappetty Service Co Bank Ltd," },
  { value: "Vardan Co Op Credit Soc Ltd", label: "Vardan Co Op Credit Soc Ltd" },
  { value: "Vardayini Sahakari Pathsanstha Maryadit", label: "Vardayini Sahakari Pathsanstha Maryadit" },
  { value: "Vardha Urban Co-Operative Bank", label: "Vardha Urban Co-Operative Bank" },
  { value: "Vardhaman Automibles,Surat", label: "Vardhaman Automibles,Surat" },
  { value: "Vardhaman Bank", label: "Vardhaman Bank" },
  { value: "Vardhaman Credit Corporation", label: "Vardhaman Credit Corporation" },
  { value: "Vardhaman Finance Co Ltd", label: "Vardhaman Finance Co Ltd" },
  { value: "Vardhaman Finance Corporation", label: "Vardhaman Finance Corporation" },
  { value: "Vardhaman Investments", label: "Vardhaman Investments" },
  { value: "Vardhaman Ngari Sahakari Patsanstha Ltd.", label: "Vardhaman Ngari Sahakari Patsanstha Ltd." },
  { value: "Vardhaman Sah. Bank Ltd.", label: "Vardhaman Sah. Bank Ltd." },
  { value: "Vardhaman Textiles Ltd", label: "Vardhaman Textiles Ltd" },
  { value: "Vardhamn Syndicate", label: "Vardhamn Syndicate" },
  { value: "Vardhan Enterprises", label: "Vardhan Enterprises" },
  { value: "Vardhan Finance", label: "Vardhan Finance" },
  { value: "Vardhan Properties And Investment Ltd.", label: "Vardhan Properties And Investment Ltd." },
  { value: "Vardhman Bank", label: "Vardhman Bank" },
  { value: "Vardhman Credit Co Op Soc Ltd", label: "Vardhman Credit Co Op Soc Ltd" },
  { value: "Vardhman Enterprises", label: "Vardhman Enterprises" },
  { value: "Vardhman Finance", label: "Vardhman Finance" },
  { value: "Vardhman Investments,Surat", label: "Vardhman Investments,Surat" },
  { value: "Vardhman Motor Finance", label: "Vardhman Motor Finance" },
  { value: "Vardhman Motors", label: "Vardhman Motors" },
  { value: "Vardhman Nagari Sahakari Pathsanstha.", label: "Vardhman Nagari Sahakari Pathsanstha." },
  { value: "Vardhman Yarns Finance", label: "Vardhman Yarns Finance" },
  { value: "Vardvinayak Co Op Credit Society Ltd", label: "Vardvinayak Co Op Credit Society Ltd" },
  { value: "Varghese P A", label: "Varghese P A" },
  { value: "Variable Energy Cyclotron Centre", label: "Variable Energy Cyclotron Centre" },
  { value: "Varinder Finance (P) Ltd.", label: "Varinder Finance (P) Ltd." },
  { value: "Varlakshmi Auto Finance", label: "Varlakshmi Auto Finance" },
  { value: "Varma Ford Credit", label: "Varma Ford Credit" },
  { value: "Varsha Financiers", label: "Varsha Financiers" },
  { value: "Varun Auto Finance", label: "Varun Auto Finance" },
  { value: "Varun Finance", label: "Varun Finance" },
  { value: "Varun Leasing", label: "Varun Leasing" },
  { value: "Vasai Janata Sahakari Bank Ltd.", label: "Vasai Janata Sahakari Bank Ltd." },
  { value: "Vasai Pragati Co Op Soc", label: "Vasai Pragati Co Op Soc" },
  { value: "Vasai Pragati Co-Op Credit Soc.", label: "Vasai Pragati Co-Op Credit Soc." },
  { value: "Vasai Pragati Credit Soc Ltd", label: "Vasai Pragati Credit Soc Ltd" },
  { value: "Vasai Sahakari Bank", label: "Vasai Sahakari Bank" },
  { value: "Vasai Talukaa Sagari Masemari Sahakari Patpedhi Maryadit", label: "Vasai Talukaa Sagari Masemari Sahakari Patpedhi Maryadit" },
  { value: "Vasai Urban Co Op Bank Ltd", label: "Vasai Urban Co Op Bank Ltd" },
  { value: "Vasai Urban Coop Credit Soc Ltd", label: "Vasai Urban Coop Credit Soc Ltd" },
  { value: "Vasai Vikas Co Op Bank Ltd", label: "Vasai Vikas Co Op Bank Ltd" },
  { value: "Vasai Vikas Sahakari Bank", label: "Vasai Vikas Sahakari Bank" },
  { value: "Vasai Vikas Sahkari Bank Ltd.", label: "Vasai Vikas Sahkari Bank Ltd." },
  { value: "Vasant Dada Gramin Bigar Sheti Co-Op Pth", label: "Vasant Dada Gramin Bigar Sheti Co-Op Pth" },
  { value: "Vasant Dada Patil Nagari Sahakari Path. San. Maryadit", label: "Vasant Dada Patil Nagari Sahakari Path. San. Maryadit" },
  { value: "Vasant Sahakari Bank Ltd", label: "Vasant Sahakari Bank Ltd" },
  { value: "Vasant Urban Co-Op Credit Society Ltd.", label: "Vasant Urban Co-Op Credit Society Ltd." },
  { value: "Vasanta Auto Finance", label: "Vasanta Auto Finance" },
  { value: "Vasantdada Nagari Sahakari Bank Ltd  Osmanabad", label: "Vasantdada Nagari Sahakari Bank Ltd  Osmanabad" },
  { value: "Vasantdada Patil Nagari Sahakari Pathsanstha Maryadit", label: "Vasantdada Patil Nagari Sahakari Pathsanstha Maryadit" },
  { value: "Vasantdada Shetkari Sahakari Bank Ltd.", label: "Vasantdada Shetkari Sahakari Bank Ltd." },
  { value: "Vasantdada Sugar Institute", label: "Vasantdada Sugar Institute" },
  { value: "Vasanth Devi Baghmar", label: "Vasanth Devi Baghmar" },
  { value: "Vasanth Finance R", label: "Vasanth Finance R" },
  { value: "Vasantha", label: "Vasantha" },
  { value: "Vasantha Bai", label: "Vasantha Bai" },
  { value: "Vasantha Bhandari", label: "Vasantha Bhandari" },
  { value: "Vasantham Financiers", label: "Vasantham Financiers" },
  { value: "Vasantraj Co Op Credit Society Ltd.", label: "Vasantraj Co Op Credit Society Ltd." },
  { value: "Vasantrao Chougule Nagari Sah Patsanstha", label: "Vasantrao Chougule Nagari Sah Patsanstha" },
  { value: "Vasantrao Kakade Patil Gbs Pat San", label: "Vasantrao Kakade Patil Gbs Pat San" },
  { value: "Vasantrao Naik Nagari Sah Path Sanstha Ltd", label: "Vasantrao Naik Nagari Sah Path Sanstha Ltd" },
  { value: "Vasavamba Co Operative Bank Ltd", label: "Vasavamba Co Operative Bank Ltd" },
  { value: "Vasavi Co-Op Urban Bank Ltd.", label: "Vasavi Co-Op Urban Bank Ltd." },
  { value: "Vasavi Co-Operative Urban Bank Ltd.", label: "Vasavi Co-Operative Urban Bank Ltd." },
  { value: "Vasavi Finance And Commercials", label: "Vasavi Finance And Commercials" },
  { value: "Vasavi Finance Corporation", label: "Vasavi Finance Corporation" },
  { value: "Vasavi Financial Services Ltd.", label: "Vasavi Financial Services Ltd." },
  { value: "Vasavi Souharda Pattina Shakari Niyamitha", label: "Vasavi Souharda Pattina Shakari Niyamitha" },
  { value: "Vasavi Vividoddesha Sahakara Sangha Ltd", label: "Vasavi Vividoddesha Sahakara Sangha Ltd" },
  { value: "Vasavilaxmi Finance Co Ltd.", label: "Vasavilaxmi Finance Co Ltd." },
  { value: "Vasu Co-Op Bank Ltd", label: "Vasu Co-Op Bank Ltd" },
  { value: "Vasudev Enterprise", label: "Vasudev Enterprise" },
  { value: "Vasudev Enterprise", label: "Vasudev Enterprise" },
  { value: "Vasudev Trading Co", label: "Vasudev Trading Co" },
  { value: "Vasudha Enterpricess", label: "Vasudha Enterpricess" },
  { value: "Vasuke Auto Finance", label: "Vasuke Auto Finance" },
  { value: "Vasundhara Agencies Pvt Ltd", label: "Vasundhara Agencies Pvt Ltd" },
  { value: "Vasundhara Mahila Nagri Sah Bk L", label: "Vasundhara Mahila Nagri Sah Bk L" },
  { value: "Vasupujaya Investment", label: "Vasupujaya Investment" },
  { value: "Vatakara Co-Op. Rural Bank Ltd", label: "Vatakara Co-Op. Rural Bank Ltd" },
  { value: "Vats Enterprises", label: "Vats Enterprises" },
  { value: "Vattamkulam Panchayath Service C-P Bk L", label: "Vattamkulam Panchayath Service C-P Bk L" },
  { value: "Vattiyoorkavu Service Co-Op Bank L", label: "Vattiyoorkavu Service Co-Op Bank L" },
  { value: "Vattur Primary Agricultural Co-Op Bank L", label: "Vattur Primary Agricultural Co-Op Bank L" },
  { value: "Vavradvinayak Nagari Sah Pat", label: "Vavradvinayak Nagari Sah Pat" },
  { value: "Vavsaya Seva Saharaki Sangha Bank,", label: "Vavsaya Seva Saharaki Sangha Bank," },
  { value: "Vayyattypuzha Service Co-Op Bank Ltd", label: "Vayyattypuzha Service Co-Op Bank Ltd" },
  { value: "Vazhakulam Service Co-Operative Bank", label: "Vazhakulam Service Co-Operative Bank" },
  { value: "Vazhakulam Services Coperative Society L", label: "Vazhakulam Services Coperative Society L" },
  { value: "Vazhappally Service Co-Op. Bank Ltd.", label: "Vazhappally Service Co-Op. Bank Ltd." },
  { value: "Vbc Finance And Leasing Ltd.", label: "Vbc Finance And Leasing Ltd." },
  { value: "Vebhav Enterprises", label: "Vebhav Enterprises" },
  { value: "Veda Credit Co-Op Socity Ltd", label: "Veda Credit Co-Op Socity Ltd" },
  { value: "Vedaranyam Urban Co-Op Credit Soc. Ltd.", label: "Vedaranyam Urban Co-Op Credit Soc. Ltd." },
  { value: "Vedganga Sahakari Pat Ltd", label: "Vedganga Sahakari Pat Ltd" },
  { value: "Vedika Credit Capital Ltd.", label: "Vedika Credit Capital Ltd." },
  { value: "Vee Ess Capfin Pvt. Ltd.", label: "Vee Ess Capfin Pvt. Ltd." },
  { value: "Veena Rani", label: "Veena Rani" },
  { value: "Veer Finance & Leasing Pvt. Ltd.", label: "Veer Finance & Leasing Pvt. Ltd." },
  { value: "Veer Prabhu Marketing Ltd", label: "Veer Prabhu Marketing Ltd" },
  { value: "Veer Rani Kittur Channamma Urban C S S L", label: "Veer Rani Kittur Channamma Urban C S S L" },
  { value: "Veera Securities & Finlease Pvt Ltd.", label: "Veera Securities & Finlease Pvt Ltd." },
  { value: "Veera Shaiva Co.Op Bank", label: "Veera Shaiva Co.Op Bank" },
  { value: "Veeraj Invest & Fin. Co. Ltd.", label: "Veeraj Invest & Fin. Co. Ltd." },
  { value: "Veeram Finance And Leasing Pvt Ltd", label: "Veeram Finance And Leasing Pvt Ltd" },
  { value: "Veeramaheshwara Pattina Souhardha Sahakari Niyamitha", label: "Veeramaheshwara Pattina Souhardha Sahakari Niyamitha" },
  { value: "Veeran Finance & Leasing (P) Ltd", label: "Veeran Finance & Leasing (P) Ltd" },
  { value: "Veeranjaneya Auto Finance", label: "Veeranjaneya Auto Finance" },
  { value: "Veerapulkishee Co-Op Bank Kerur", label: "Veerapulkishee Co-Op Bank Kerur" },
  { value: "Veerashaiva Co-Op Bank Ltd.", label: "Veerashaiva Co-Op Bank Ltd." },
  { value: "Veerashaiva Credit Co-Op Society Ltd.", label: "Veerashaiva Credit Co-Op Society Ltd." },
  { value: "Veerashaiva Sahakari Bank Ltd.", label: "Veerashaiva Sahakari Bank Ltd." },
  { value: "Veerashiva Credit Co-Operative Society Ltd.", label: "Veerashiva Credit Co-Operative Society Ltd." },
  { value: "Veerashiva Urban Credit Co-Op Society Ltd", label: "Veerashiva Urban Credit Co-Op Society Ltd" },
  { value: "Veerbadreshwar Credit Souhard Sahakari Nigam", label: "Veerbadreshwar Credit Souhard Sahakari Nigam" },
  { value: "Veerbhadreshwara Motors", label: "Veerbhadreshwara Motors" },
  { value: "Veerbhadreshwara Motors", label: "Veerbhadreshwara Motors" },
  { value: "Veermata Jijabai Technological Institute (Vjti)", label: "Veermata Jijabai Technological Institute (Vjti)" },
  { value: "Veernagar Seva Sahkari Mandali", label: "Veernagar Seva Sahkari Mandali" },
  { value: "Veerpulikeshi Co -Op Bank", label: "Veerpulikeshi Co -Op Bank" },
  { value: "Veersaiva Pattan Shahakar Sanga Gulbarga", label: "Veersaiva Pattan Shahakar Sanga Gulbarga" },
  { value: "Veetrag Motor Finance", label: "Veetrag Motor Finance" },
  { value: "Vehicles & General Finance Co Ltd", label: "Vehicles & General Finance Co Ltd" },
  { value: "Vehicles & General Finance Co. Pvt. Ltd.", label: "Vehicles & General Finance Co. Pvt. Ltd." },
  { value: "Vehicles General Finance Co Ltd", label: "Vehicles General Finance Co Ltd" },
  { value: "Veisheli Motor Fnc Ltd", label: "Veisheli Motor Fnc Ltd" },
  { value: "Vejalpore Seva Sahkari Mandali", label: "Vejalpore Seva Sahkari Mandali" },
  { value: "Vejalpur Nagrik Sah. Bank Ltd", label: "Vejalpur Nagrik Sah. Bank Ltd" },
  { value: "Vejapur Nagarik Sahakari Bank Ltd", label: "Vejapur Nagarik Sahakari Bank Ltd" },
  { value: "Vekas Grameen Bank", label: "Vekas Grameen Bank" },
  { value: "Velanganni Primary Agricultural C-P Bk L", label: "Velanganni Primary Agricultural C-P Bk L" },
  { value: "Velangattu Valasu  P A C B Ltd", label: "Velangattu Valasu  P A C B Ltd" },
  { value: "Velavan Auto Finance", label: "Velavan Auto Finance" },
  { value: "Velavan Investments", label: "Velavan Investments" },
  { value: "Velimukku Service Co-Op Bank Ltd.", label: "Velimukku Service Co-Op Bank Ltd." },
  { value: "Vellangallur Service Co-Op Bank Ltd,", label: "Vellangallur Service Co-Op Bank Ltd," },
  { value: "Vellathooval Service Co-Operative Bank L", label: "Vellathooval Service Co-Operative Bank L" },
  { value: "Vellattanjur Service Co-Op. Bank", label: "Vellattanjur Service Co-Op. Bank" },
  { value: "Vellattanjur Service Co-Op. Bank Ltd", label: "Vellattanjur Service Co-Op. Bank Ltd" },
  { value: "Vellore District Central Co-Operative Bank Ltd.", label: "Vellore District Central Co-Operative Bank Ltd." },
  { value: "Velmurugan Auto", label: "Velmurugan Auto" },
  { value: "Veman Co-Operative Society", label: "Veman Co-Operative Society" },
  { value: "Vemuri Kumari Auto Mobile Finance", label: "Vemuri Kumari Auto Mobile Finance" },
  { value: "Vengachalam Finance", label: "Vengachalam Finance" },
  { value: "Vengai Finance And Investments", label: "Vengai Finance And Investments" },
  { value: "Vengola Service Co-Op. Bank Ltd", label: "Vengola Service Co-Op. Bank Ltd" },
  { value: "Venkat Sai Finance Corporation", label: "Venkat Sai Finance Corporation" },
  { value: "Venkat Sai Motors Credit", label: "Venkat Sai Motors Credit" },
  { value: "Venkata Anjaneya Auto & Employee Finance", label: "Venkata Anjaneya Auto & Employee Finance" },
  { value: "Venkata Anjaneya Auto Employee Finance", label: "Venkata Anjaneya Auto Employee Finance" },
  { value: "Venkata Anjaneya Finance", label: "Venkata Anjaneya Finance" },
  { value: "Venkata Dhana Lakshmi Finance", label: "Venkata Dhana Lakshmi Finance" },
  { value: "Venkata Kalyani A/F", label: "Venkata Kalyani A/F" },
  { value: "Venkata Krishna Auto Finance", label: "Venkata Krishna Auto Finance" },
  { value: "Venkata Lakshmi Finance", label: "Venkata Lakshmi Finance" },
  { value: "Venkata Mallikarjuna Auto Finance", label: "Venkata Mallikarjuna Auto Finance" },
  { value: "Venkata Rama Finance", label: "Venkata Rama Finance" },
  { value: "Venkata Ramana Auto Finance", label: "Venkata Ramana Auto Finance" },
  { value: "Venkata Ramana Finance", label: "Venkata Ramana Finance" },
  { value: "Venkata Sai Auto Finance", label: "Venkata Sai Auto Finance" },
  { value: "Venkata Sai Finance", label: "Venkata Sai Finance" },
  { value: "Venkata Sai Financiers", label: "Venkata Sai Financiers" },
  { value: "Venkata Sai Lakshmi Financiers", label: "Venkata Sai Lakshmi Financiers" },
  { value: "Venkata Sai Laxmi Auto & Employee Finance", label: "Venkata Sai Laxmi Auto & Employee Finance" },
  { value: "Venkata Sai Ragavendra Auto Finance", label: "Venkata Sai Ragavendra Auto Finance" },
  { value: "Venkata Sai Raghavendra Auto Finance", label: "Venkata Sai Raghavendra Auto Finance" },
  { value: "Venkata Siva Automobile Financiers", label: "Venkata Siva Automobile Financiers" },
  { value: "Venkata Siva Finance", label: "Venkata Siva Finance" },
  { value: "Venkatachalam Finance", label: "Venkatachalam Finance" },
  { value: "Venkatachalam Financial Services", label: "Venkatachalam Financial Services" },
  { value: "Venkatachalam Financial Services P Ltd", label: "Venkatachalam Financial Services P Ltd" },
  { value: "Venkatadri Financial Services Ltd.", label: "Venkatadri Financial Services Ltd." },
  { value: "Venkatagiri Motor Finance", label: "Venkatagiri Motor Finance" },
  { value: "Venkataraya Finance Pvt Ltd", label: "Venkataraya Finance Pvt Ltd" },
  { value: "Venkatasai A/F", label: "Venkatasai A/F" },
  { value: "Venkatesa Auto & General Finance", label: "Venkatesa Auto & General Finance" },
  { value: "Venkatesh Auto Finance Co. Gadchandur", label: "Venkatesh Auto Finance Co. Gadchandur" },
  { value: "Venkatesh Financers", label: "Venkatesh Financers" },
  { value: "Venkatesh Nagari Sah Patsanstha Maryadit", label: "Venkatesh Nagari Sah Patsanstha Maryadit" },
  { value: "Venkateshwara  Motor Credit", label: "Venkateshwara  Motor Credit" },
  { value: "Venkateshwara Auto Finance", label: "Venkateshwara Auto Finance" },
  { value: "Venkateshwara Finance Corporation", label: "Venkateshwara Finance Corporation" },
  { value: "Venkateshwara Finance Investments", label: "Venkateshwara Finance Investments" },
  { value: "Venkateshwara Hp & Finance", label: "Venkateshwara Hp & Finance" },
  { value: "Venkateshwara Insurance Services", label: "Venkateshwara Insurance Services" },
  { value: "Venkateswara Auto Fin", label: "Venkateswara Auto Fin" },
  { value: "Venkateswara Financiers", label: "Venkateswara Financiers" },
  { value: "Venkateswara Investments", label: "Venkateswara Investments" },
  { value: "Vennala Service Co-Operative Bank Ltd.", label: "Vennala Service Co-Operative Bank Ltd." },
  { value: "Venus Investments", label: "Venus Investments" },
  { value: "Venus Investments", label: "Venus Investments" },
  { value: "Venutai Chavan Sah Patsantha Ltd.", label: "Venutai Chavan Sah Patsantha Ltd." },
  { value: "Vepar Udhyog Vikas Sahakari Bank Ltd.", label: "Vepar Udhyog Vikas Sahakari Bank Ltd." },
  { value: "Vepar Udyog Vikas Sahakari Bank Ltd", label: "Vepar Udyog Vikas Sahakari Bank Ltd" },
  { value: "Vepar Vikas Co-Op Bank Ltd", label: "Vepar Vikas Co-Op Bank Ltd" },
  { value: "Vepar Vikas Co-Op Bank Ltd.", label: "Vepar Vikas Co-Op Bank Ltd." },
  { value: "Vepur Udhyog Vikas Sahakari Bank Ltd", label: "Vepur Udhyog Vikas Sahakari Bank Ltd" },
  { value: "Veraval Mercantile Co-Op Bank Ltd.", label: "Veraval Mercantile Co-Op Bank Ltd." },
  { value: "Veraval Peoples Co-Op Bank Ltd.", label: "Veraval Peoples Co-Op Bank Ltd." },
  { value: "Verma Associates", label: "Verma Associates" },
  { value: "Vernutai Chavan Sah Pat Ltd", label: "Vernutai Chavan Sah Pat Ltd" },
  { value: "Vetri Investment", label: "Vetri Investment" },
  { value: "Vetrimurugan Finance", label: "Vetrimurugan Finance" },
  { value: "Vetrivel Auto Financiers", label: "Vetrivel Auto Financiers" },
  { value: "Vetrivel Bankers", label: "Vetrivel Bankers" },
  { value: "Vetrivel Finance", label: "Vetrivel Finance" },
  { value: "Vettikavala Service Coop Bank Ltd", label: "Vettikavala Service Coop Bank Ltd" },
  { value: "Vettrivel Motor Finance", label: "Vettrivel Motor Finance" },
  { value: "Veva Saya Seva Sahakara Bank,", label: "Veva Saya Seva Sahakara Bank," },
  { value: "Vgp Solutions", label: "Vgp Solutions" },
  { value: "Vhannavvadevi Co-Op Credit Society Ltd", label: "Vhannavvadevi Co-Op Credit Society Ltd" },
  { value: "Vibha Financiers", label: "Vibha Financiers" },
  { value: "Vibhuti Financiers", label: "Vibhuti Financiers" },
  { value: "Viceroy Travels Private Limited", label: "Viceroy Travels Private Limited" },
  { value: "Vicky Bhandari", label: "Vicky Bhandari" },
  { value: "Vicky Enterprises", label: "Vicky Enterprises" },
  { value: "Vicky Fiter", label: "Vicky Fiter" },
  { value: "Victoria Memorial Hall, Kolkata", label: "Victoria Memorial Hall, Kolkata" },
  { value: "Victory Auto Finance", label: "Victory Auto Finance" },
  { value: "Victory Finance", label: "Victory Finance" },
  { value: "Vidarbha  Kashithiya Gramin Bank", label: "Vidarbha  Kashithiya Gramin Bank" },
  { value: "Videsh Sanchar Nigam Limited", label: "Videsh Sanchar Nigam Limited" },
  { value: "Vidharbha Merchants Urban Co.Op. Bank Lt", label: "Vidharbha Merchants Urban Co.Op. Bank Lt" },
  { value: "Vidhi Financiers", label: "Vidhi Financiers" },
  { value: "Vidhur Gramin Bank", label: "Vidhur Gramin Bank" },
  { value: "Vidhya Bharti Co-Op Credit Soc. Ltd.", label: "Vidhya Bharti Co-Op Credit Soc. Ltd." },
  { value: "Vidisha-Bhopal Kshetriya Gramin Bank", label: "Vidisha-Bhopal Kshetriya Gramin Bank" },
  { value: "Vidisha-Bhopal Kshetriya Gramin Bank, Bhopal", label: "Vidisha-Bhopal Kshetriya Gramin Bank, Bhopal" },
  { value: "Vidur Gramin Bank", label: "Vidur Gramin Bank" },
  { value: "Vidur Gramin Bank Ltd.", label: "Vidur Gramin Bank Ltd." },
  { value: "Vidya Finance Co Ltd", label: "Vidya Finance Co Ltd" },
  { value: "Vidya Investments", label: "Vidya Investments" },
  { value: "Vidya Sagar Co-Op Central Bank Ltd,", label: "Vidya Sagar Co-Op Central Bank Ltd," },
  { value: "Vidya Sah. Bank Ltd,Pune", label: "Vidya Sah. Bank Ltd,Pune" },
  { value: "Vidya Sahakari Bank Ltd", label: "Vidya Sahakari Bank Ltd" },
  { value: "Vidyanand Bank", label: "Vidyanand Bank" },
  { value: "Vidyanand Co-Op. Bank Ltd Solapur", label: "Vidyanand Co-Op. Bank Ltd Solapur" },
  { value: "Vidyanand Co-Op. Bank Ltd.", label: "Vidyanand Co-Op. Bank Ltd." },
  { value: "Vidyanand Urban Co-Op. Bank Ltd.", label: "Vidyanand Urban Co-Op. Bank Ltd." },
  { value: "Vidyasagar Central Co-Op. Bank Ltd.", label: "Vidyasagar Central Co-Op. Bank Ltd." },
  { value: "Vidyasagar District Central Co-Operative Bank Ltd.", label: "Vidyasagar District Central Co-Operative Bank Ltd." },
  { value: "Vigfin Holdings Pvt. Ltd.", label: "Vigfin Holdings Pvt. Ltd." },
  { value: "Vighnahar Sahakari Pat Ltd", label: "Vighnahar Sahakari Pat Ltd" },
  { value: "Vighnaharta Gramin Bigar Sheti Pathsanstha", label: "Vighnaharta Gramin Bigar Sheti Pathsanstha" },
  { value: "Vighnaharta Nagari Sahkari Pathsanstha Maryadit", label: "Vighnaharta Nagari Sahkari Pathsanstha Maryadit" },
  { value: "Vignahar Co Op Cr Soc Ltd", label: "Vignahar Co Op Cr Soc Ltd" },
  { value: "Vignahar Gar Bigarsheti Sahakari Patsanstha", label: "Vignahar Gar Bigarsheti Sahakari Patsanstha" },
  { value: "Vignesh Finance", label: "Vignesh Finance" },
  { value: "Vigneshwara Auto Finance", label: "Vigneshwara Auto Finance" },
  { value: "Vigneshwara Finance", label: "Vigneshwara Finance" },
  { value: "Vigneswara Leasing", label: "Vigneswara Leasing" },
  { value: "Vigneswara Leasing", label: "Vigneswara Leasing" },
  { value: "Vigneswari Automobiles", label: "Vigneswari Automobiles" },
  { value: "Vignyan Industries Ltd.", label: "Vignyan Industries Ltd." },
  { value: "Vijapur Merchants Co-Op Bank Ltd", label: "Vijapur Merchants Co-Op Bank Ltd" },
  { value: "Vijapur Nagrik Sahkari Bank Ltd", label: "Vijapur Nagrik Sahkari Bank Ltd" },
  { value: "Vijay And Co.", label: "Vijay And Co." },
  { value: "Vijay Auto Finance", label: "Vijay Auto Finance" },
  { value: "Vijay Bansilalji Kasat", label: "Vijay Bansilalji Kasat" },
  { value: "Vijay Bharat Auto Finance", label: "Vijay Bharat Auto Finance" },
  { value: "Vijay Co-Op Bank Ltd.", label: "Vijay Co-Op Bank Ltd." },
  { value: "Vijay Co-Op Credit Soc Ltd", label: "Vijay Co-Op Credit Soc Ltd" },
  { value: "Vijay Commercial Co-Op Bank Ltd", label: "Vijay Commercial Co-Op Bank Ltd" },
  { value: "Vijay Commercial Co-Op. Bank Ltd.", label: "Vijay Commercial Co-Op. Bank Ltd." },
  { value: "Vijay Finance", label: "Vijay Finance" },
  { value: "Vijay Finance Corporation Financier", label: "Vijay Finance Corporation Financier" },
  { value: "Vijay Finance Syndicate", label: "Vijay Finance Syndicate" },
  { value: "Vijay Hemant", label: "Vijay Hemant" },
  { value: "Vijay Hemant Finance", label: "Vijay Hemant Finance" },
  { value: "Vijay Hemant Finance And Estates Ltd.", label: "Vijay Hemant Finance And Estates Ltd." },
  { value: "Vijay Hire Purchase And Leasing Ltd.", label: "Vijay Hire Purchase And Leasing Ltd." },
  { value: "Vijay Investments", label: "Vijay Investments" },
  { value: "Vijay Jyothi Financiers P Ltd", label: "Vijay Jyothi Financiers P Ltd" },
  { value: "Vijay Kanth Finance", label: "Vijay Kanth Finance" },
  { value: "Vijay Laxmi Auto Finance", label: "Vijay Laxmi Auto Finance" },
  { value: "Vijay Laxmi Bank", label: "Vijay Laxmi Bank" },
  { value: "Vijay Laxmi Co-Op Credit Society", label: "Vijay Laxmi Co-Op Credit Society" },
  { value: "Vijay Laxmi Credit Co", label: "Vijay Laxmi Credit Co" },
  { value: "Vijay Leasing ( R )", label: "Vijay Leasing ( R )" },
  { value: "Vijay Mahantesh Co-Op Credit Society", label: "Vijay Mahantesh Co-Op Credit Society" },
  { value: "Vijay Mahantesh Sahakar   Bank", label: "Vijay Mahantesh Sahakar   Bank" },
  { value: "Vijay Motor Fin. P. Ltd.", label: "Vijay Motor Fin. P. Ltd." },
  { value: "Vijay Motors", label: "Vijay Motors" },
  { value: "Vijay Raj Chopda", label: "Vijay Raj Chopda" },
  { value: "Vijay Shanthi Investments", label: "Vijay Shanthi Investments" },
  { value: "Vijay Singh Singhi Huf", label: "Vijay Singh Singhi Huf" },
  { value: "Vijay Souhard Cr Sahakara Ltd", label: "Vijay Souhard Cr Sahakara Ltd" },
  { value: "Vijay Wargia Finance", label: "Vijay Wargia Finance" },
  { value: "Vijaya  Bank Industrial Finance", label: "Vijaya  Bank Industrial Finance" },
  { value: "Vijaya Auto Finance", label: "Vijaya Auto Finance" },
  { value: "Vijaya Bai", label: "Vijaya Bai" },
  { value: "Vijaya Bank - Iswaramangala Branch", label: "Vijaya Bank - Iswaramangala Branch" },
  { value: "Vijaya Bank - Saibrakatte", label: "Vijaya Bank - Saibrakatte" },
  { value: "Vijaya Bank - Shirva", label: "Vijaya Bank - Shirva" },
  { value: "Vijaya Bank - Sirsi", label: "Vijaya Bank - Sirsi" },
  { value: "Vijaya Bank - Sooda", label: "Vijaya Bank - Sooda" },
  { value: "Vijaya Bank Ltd", label: "Vijaya Bank Ltd" },
  { value: "Vijaya Bank Saibrakatte Branch.", label: "Vijaya Bank Saibrakatte Branch." },
  { value: "Vijaya Credit Co-Operative Society Niyamitha", label: "Vijaya Credit Co-Operative Society Niyamitha" },
  { value: "Vijaya Durga Auto Finance", label: "Vijaya Durga Auto Finance" },
  { value: "Vijaya Durga Finance", label: "Vijaya Durga Finance" },
  { value: "Vijaya Finance Syndicate", label: "Vijaya Finance Syndicate" },
  { value: "Vijaya Ganapathi Auto Finance", label: "Vijaya Ganapathi Auto Finance" },
  { value: "Vijaya Investments", label: "Vijaya Investments" },
  { value: "Vijaya Jyothi Finance.", label: "Vijaya Jyothi Finance." },
  { value: "Vijaya Krishna Chitfin Ltd", label: "Vijaya Krishna Chitfin Ltd" },
  { value: "Vijaya Laxmi Auto Finance", label: "Vijaya Laxmi Auto Finance" },
  { value: "Vijaya Laxmi Finance & Investments", label: "Vijaya Laxmi Finance & Investments" },
  { value: "Vijaya Laxmi Finance & Investments Auto Mobile Financiers", label: "Vijaya Laxmi Finance & Investments Auto Mobile Financiers" },
  { value: "Vijaya Nagara Co-Op Society", label: "Vijaya Nagara Co-Op Society" },
  { value: "Vijaya Sai Finance", label: "Vijaya Sai Finance" },
  { value: "Vijaya Sai Hp And Finance", label: "Vijaya Sai Hp And Finance" },
  { value: "Vijaya Sindicate", label: "Vijaya Sindicate" },
  { value: "Vijaya Sree Finance", label: "Vijaya Sree Finance" },
  { value: "Vijaya Srinivasa Financiers", label: "Vijaya Srinivasa Financiers" },
  { value: "Vijaya Vargiya Auto Finance", label: "Vijaya Vargiya Auto Finance" },
  { value: "Vijaya Vargiya Finance", label: "Vijaya Vargiya Finance" },
  { value: "Vijayakumar Financier", label: "Vijayakumar Financier" },
  { value: "Vijayalakshmi", label: "Vijayalakshmi" },
  { value: "Vijayalakshmi Finance", label: "Vijayalakshmi Finance" },
  { value: "Vijayalakshmi Finance & Investments", label: "Vijayalakshmi Finance & Investments" },
  { value: "Vijayalaxmi Credit Corporation", label: "Vijayalaxmi Credit Corporation" },
  { value: "Vijayalaxmi Motor Finance", label: "Vijayalaxmi Motor Finance" },
  { value: "Vijayanth Anchalia Automobile Finances", label: "Vijayanth Anchalia Automobile Finances" },
  { value: "Vijayawada Auto Consultants", label: "Vijayawada Auto Consultants" },
  { value: "Vijaylakshmi Credit C-Operative Gulbarga", label: "Vijaylakshmi Credit C-Operative Gulbarga" },
  { value: "Vijaylaxmi Co-Op Credit Society Ltd", label: "Vijaylaxmi Co-Op Credit Society Ltd" },
  { value: "Vijaylaxmi Credit Corporation ( R )", label: "Vijaylaxmi Credit Corporation ( R )" },
  { value: "Vijaylaxmi Financial", label: "Vijaylaxmi Financial" },
  { value: "Vijaymahantesh Co-Op Bank", label: "Vijaymahantesh Co-Op Bank" },
  { value: "Vijayraj Kothari", label: "Vijayraj Kothari" },
  { value: "Vijayshanti Finance & Invest", label: "Vijayshanti Finance & Invest" },
  { value: "Vijayshanti Finance & Invest Automobile Financiers", label: "Vijayshanti Finance & Invest Automobile Financiers" },
  { value: "Vijetha Auto Finance", label: "Vijetha Auto Finance" },
  { value: "Viji Finance", label: "Viji Finance" },
  { value: "Vijya Laxmi Mahila Gramin Bigar Sheti Sahakari Path Sanstha", label: "Vijya Laxmi Mahila Gramin Bigar Sheti Sahakari Path Sanstha" },
  { value: "Vikas  Vadhawan Vikas  Vadhawan", label: "Vikas  Vadhawan Vikas  Vadhawan" },
  { value: "Vikas Auto Finance", label: "Vikas Auto Finance" },
  { value: "Vikas Bank Ltd", label: "Vikas Bank Ltd" },
  { value: "Vikas Co-Operative Bank Ltd.", label: "Vikas Co-Operative Bank Ltd." },
  { value: "Vikas Co.Op Bank Ltd.", label: "Vikas Co.Op Bank Ltd." },
  { value: "Vikas Coop Credit Society  Ltd", label: "Vikas Coop Credit Society  Ltd" },
  { value: "Vikas Finance Corporation", label: "Vikas Finance Corporation" },
  { value: "Vikas Goyal", label: "Vikas Goyal" },
  { value: "Vikas Gupta", label: "Vikas Gupta" },
  { value: "Vikas Leasing Limited", label: "Vikas Leasing Limited" },
  { value: "Vikas Nigam Betul", label: "Vikas Nigam Betul" },
  { value: "Vikas Sahakari Bank Ltd.", label: "Vikas Sahakari Bank Ltd." },
  { value: "Vikas Sahakari Pathpedhi Ltd", label: "Vikas Sahakari Pathpedhi Ltd" },
  { value: "Vikas Society (J.D.C.C)", label: "Vikas Society (J.D.C.C)" },
  { value: "Vikas Souhar Co-Operative Bank Ltd", label: "Vikas Souhar Co-Operative Bank Ltd" },
  { value: "Vikas Souharda Co-Operative Bank", label: "Vikas Souharda Co-Operative Bank" },
  { value: "Vikas Souharda Co-Operative Bank Ltd", label: "Vikas Souharda Co-Operative Bank Ltd" },
  { value: "Vikas Souharda Co-Operative Bank Ltd", label: "Vikas Souharda Co-Operative Bank Ltd" },
  { value: "Vikas Sourdha Co-Op.Bank Ltd", label: "Vikas Sourdha Co-Op.Bank Ltd" },
  { value: "Vikas Surana Finance", label: "Vikas Surana Finance" },
  { value: "Vikas Urban Co-Op Bank Ltd", label: "Vikas Urban Co-Op Bank Ltd" },
  { value: "Vikas Urban Co-Op. Bank Ltd.", label: "Vikas Urban Co-Op. Bank Ltd." },
  { value: "Vikash Auto Finance", label: "Vikash Auto Finance" },
  { value: "Vikash Fincap", label: "Vikash Fincap" },
  { value: "Vikash N Jain", label: "Vikash N Jain" },
  { value: "Vikhayat Securities Pvt Ltd.", label: "Vikhayat Securities Pvt Ltd." },
  { value: "Vikram Auto Consultant Finance", label: "Vikram Auto Consultant Finance" },
  { value: "Vikram Auto Finance", label: "Vikram Auto Finance" },
  { value: "Vikram Finance", label: "Vikram Finance" },
  { value: "Vikram Finance & Investment", label: "Vikram Finance & Investment" },
  { value: "Vikram Finance Corporation", label: "Vikram Finance Corporation" },
  { value: "Vikram Motor Finance", label: "Vikram Motor Finance" },
  { value: "Vikram Motor Finance Corporation", label: "Vikram Motor Finance Corporation" },
  { value: "Vikramaditya Nagarik Sahakari Bank Ltd.", label: "Vikramaditya Nagarik Sahakari Bank Ltd." },
  { value: "Vikramashila Security & Real Estate", label: "Vikramashila Security & Real Estate" },
  { value: "Vikramashila Security Finance", label: "Vikramashila Security Finance" },
  { value: "Vikramashila Security Finance & Real Estate", label: "Vikramashila Security Finance & Real Estate" },
  { value: "Vikrant Finance Corporation", label: "Vikrant Finance Corporation" },
  { value: "Vilappil Service Co-Op Bank Ltd", label: "Vilappil Service Co-Op Bank Ltd" },
  { value: "Village Wefare Society", label: "Village Wefare Society" },
  { value: "Villages In Partnership", label: "Villages In Partnership" },
  { value: "Villiappalli Panchayath Ser.Co.Bank Ltd", label: "Villiappalli Panchayath Ser.Co.Bank Ltd" },
  { value: "Villupuram District Central Co-Operative Bank Ltd.", label: "Villupuram District Central Co-Operative Bank Ltd." },
  { value: "Vima Kamgar Co-Op Bank Ltd", label: "Vima Kamgar Co-Op Bank Ltd" },
  { value: "Vima Kamgar Co-Op. Bank Ltd", label: "Vima Kamgar Co-Op. Bank Ltd" },
  { value: "Vimal Associates", label: "Vimal Associates" },
  { value: "Vimal Chand Bhandari", label: "Vimal Chand Bhandari" },
  { value: "Vimal Credit Corporation", label: "Vimal Credit Corporation" },
  { value: "Vimal Dhariwal Financier", label: "Vimal Dhariwal Financier" },
  { value: "Vimal Enterprise", label: "Vimal Enterprise" },
  { value: "Vimal Finance", label: "Vimal Finance" },
  { value: "Vimal Hire Purchase", label: "Vimal Hire Purchase" },
  { value: "Vimal Hire Purchase And Investments", label: "Vimal Hire Purchase And Investments" },
  { value: "Vimal Infin (Pvt) Ltd.", label: "Vimal Infin (Pvt) Ltd." },
  { value: "Vimal Investments", label: "Vimal Investments" },
  { value: "Vimal Kumar", label: "Vimal Kumar" },
  { value: "Vimal Mahila Souhard Credit Co-Operative Ltd.", label: "Vimal Mahila Souhard Credit Co-Operative Ltd." },
  { value: "Vimal Viajy Agency", label: "Vimal Viajy Agency" },
  { value: "Vimala Finance", label: "Vimala Finance" },
  { value: "Vimalben Premabhai Tandel", label: "Vimalben Premabhai Tandel" },
  { value: "Vimla Finance", label: "Vimla Finance" },
  { value: "Vimlesh Investment", label: "Vimlesh Investment" },
  { value: "Vinata Gramin Bigar Sheti Sahkari Patsanstha Ltd", label: "Vinata Gramin Bigar Sheti Sahkari Patsanstha Ltd" },
  { value: "Vinay Chand", label: "Vinay Chand" },
  { value: "Vinay Chandra & Co", label: "Vinay Chandra & Co" },
  { value: "Vinay Investments", label: "Vinay Investments" },
  { value: "Vinay Km Singh", label: "Vinay Km Singh" },
  { value: "Vinay Ramdas Nayak", label: "Vinay Ramdas Nayak" },
  { value: "Vinay Surana Huf Financer", label: "Vinay Surana Huf Financer" },
  { value: "Vinayak Auto Finance", label: "Vinayak Auto Finance" },
  { value: "Vinayaka Finance", label: "Vinayaka Finance" },
  { value: "Vinayaka Investments", label: "Vinayaka Investments" },
  { value: "Vinayaka Jewellers And Bankers", label: "Vinayaka Jewellers And Bankers" },
  { value: "Vinder Auto Deals", label: "Vinder Auto Deals" },
  { value: "Vineeth Finance", label: "Vineeth Finance" },
  { value: "Vinganga Kshetriya Gramin Bank Ltd", label: "Vinganga Kshetriya Gramin Bank Ltd" },
  { value: "Vinita Katariya", label: "Vinita Katariya" },
  { value: "Vinjam Saikumar", label: "Vinjam Saikumar" },
  { value: "Vinod & Co Financiers", label: "Vinod & Co Financiers" },
  { value: "Vinod Enerprise", label: "Vinod Enerprise" },
  { value: "Vinod Enterprise", label: "Vinod Enterprise" },
  { value: "Vinod Finance", label: "Vinod Finance" },
  { value: "Vinod Finance Corporation", label: "Vinod Finance Corporation" },
  { value: "Vinod Gundecha & Sons", label: "Vinod Gundecha & Sons" },
  { value: "Vinod Kumar Khabiya", label: "Vinod Kumar Khabiya" },
  { value: "Vinoth Finance Ltd", label: "Vinoth Finance Ltd" },
  { value: "Vinoth Khabiya", label: "Vinoth Khabiya" },
  { value: "Vipan Kumar", label: "Vipan Kumar" },
  { value: "Vipin Banthia Finance", label: "Vipin Banthia Finance" },
  { value: "Vipul Financiers", label: "Vipul Financiers" },
  { value: "Viraganur Primary Agrl Co-Op Bk Ltd", label: "Viraganur Primary Agrl Co-Op Bk Ltd" },
  { value: "Virajpet Pattana Sahakara Bank (N)", label: "Virajpet Pattana Sahakara Bank (N)" },
  { value: "Viral Narendra Bhadoria", label: "Viral Narendra Bhadoria" },
  { value: "Virandhar Kumar Surana", label: "Virandhar Kumar Surana" },
  { value: "Virapet Co-Op Town Bank Ltd", label: "Virapet Co-Op Town Bank Ltd" },
  { value: "Virat Finance", label: "Virat Finance" },
  { value: "Viravanallur Co-Op Urban Bank Ltd", label: "Viravanallur Co-Op Urban Bank Ltd" },
  { value: "Virender Finance Ltd", label: "Virender Finance Ltd" },
  { value: "Virender Kapil", label: "Virender Kapil" },
  { value: "Virendera Auto Deals", label: "Virendera Auto Deals" },
  { value: "Virendra Finance Corporation", label: "Virendra Finance Corporation" },
  { value: "Virendra Gupta", label: "Virendra Gupta" },
  { value: "Virji And Co Finance Pvt Ltd.", label: "Virji And Co Finance Pvt Ltd." },
  { value: "Virk Hire Purchase Pvt. Ltd", label: "Virk Hire Purchase Pvt. Ltd" },
  { value: "Viroba Gramin Bigar Sheti Sahakari Path Sanstha", label: "Viroba Gramin Bigar Sheti Sahakari Path Sanstha" },
  { value: "Virudhunagar Co-Op Urban Bank Ltd", label: "Virudhunagar Co-Op Urban Bank Ltd" },
  { value: "Virudhunagar District Central Co-Operative Bank Ltd.", label: "Virudhunagar District Central Co-Operative Bank Ltd." },
  { value: "Virupakshewara Pattin Soudhadra Sah Bank", label: "Virupakshewara Pattin Soudhadra Sah Bank" },
  { value: "Visakha Grameena Bank", label: "Visakha Grameena Bank" },
  { value: "Visakhapatnam District Central Co-Operative Bank Ltd.", label: "Visakhapatnam District Central Co-Operative Bank Ltd." },
  { value: "Visesh Financiers", label: "Visesh Financiers" },
  { value: "Vishakhapatnam Co-Op Bank Ltd", label: "Vishakhapatnam Co-Op Bank Ltd" },
  { value: "Vishakhapatnam Steel Plant", label: "Vishakhapatnam Steel Plant" },
  { value: "Vishakhapatnam Steel Plant Visvesvarya Iron And Steel Ltd.", label: "Vishakhapatnam Steel Plant Visvesvarya Iron And Steel Ltd." },
  { value: "Vishal  Syndicate", label: "Vishal  Syndicate" },
  { value: "Vishal Agencies Finance Division", label: "Vishal Agencies Finance Division" },
  { value: "Vishal Deep Co Op Bank", label: "Vishal Deep Co Op Bank" },
  { value: "Vishal Finance Pvt Ltd", label: "Vishal Finance Pvt Ltd" },
  { value: "Vishal Financiers", label: "Vishal Financiers" },
  { value: "Vishal Finlease P. Ltd.", label: "Vishal Finlease P. Ltd." },
  { value: "Vishal Hire Purchase", label: "Vishal Hire Purchase" },
  { value: "Vishal Investment", label: "Vishal Investment" },
  { value: "Vishal Janseva Sah Pathsanshta Maryadit", label: "Vishal Janseva Sah Pathsanshta Maryadit" },
  { value: "Vishal Kumar Aakash Kumar", label: "Vishal Kumar Aakash Kumar" },
  { value: "Vishal Kumar Bafna", label: "Vishal Kumar Bafna" },
  { value: "Vishal Kumar Champalal", label: "Vishal Kumar Champalal" },
  { value: "Vishal Kumar Champalal  Jain Market", label: "Vishal Kumar Champalal  Jain Market" },
  { value: "Vishal Purandar Nagari Pathsanstha Maryadit", label: "Vishal Purandar Nagari Pathsanstha Maryadit" },
  { value: "Vishal Sahakari Pathpedhi (M)", label: "Vishal Sahakari Pathpedhi (M)" },
  { value: "Vishal Syndicate", label: "Vishal Syndicate" },
  { value: "Vishala Bandu Urban Credit Co-Op Soc Ltd .", label: "Vishala Bandu Urban Credit Co-Op Soc Ltd ." },
  { value: "Vishaldeep Co Op Bank", label: "Vishaldeep Co Op Bank" },
  { value: "Vishaldeep Sahakari Patsansatha Ltd", label: "Vishaldeep Sahakari Patsansatha Ltd" },
  { value: "Vishaldeep Sahakari Patsantha", label: "Vishaldeep Sahakari Patsantha" },
  { value: "Visheteshwar Sahakari Bank Ltd", label: "Visheteshwar Sahakari Bank Ltd" },
  { value: "Vishmakarma Sahakara Bank Niyamitha", label: "Vishmakarma Sahakara Bank Niyamitha" },
  { value: "Vishnath Motor Finance", label: "Vishnath Motor Finance" },
  { value: "Vishnath Motor Finance (R)", label: "Vishnath Motor Finance (R)" },
  { value: "Vishnu Auto Financiers", label: "Vishnu Auto Financiers" },
  { value: "Vishnu Finance", label: "Vishnu Finance" },
  { value: "Vishnu Prasad Agrawal", label: "Vishnu Prasad Agrawal" },
  { value: "Vishnu Priya Finance", label: "Vishnu Priya Finance" },
  { value: "Vishnuvilas Gramin Bigar Sheti Pat Sansthan", label: "Vishnuvilas Gramin Bigar Sheti Pat Sansthan" },
  { value: "Vishvaprabha Nagari Sahkari Patsanstha Ltd", label: "Vishvaprabha Nagari Sahkari Patsanstha Ltd" },
  { value: "Vishvas Finance", label: "Vishvas Finance" },
  { value: "Vishveshraya Grameena Bank", label: "Vishveshraya Grameena Bank" },
  { value: "Vishveshwar Co Op Bank Ltd", label: "Vishveshwar Co Op Bank Ltd" },
  { value: "Vishveshwar Sahkari Bank Ltd", label: "Vishveshwar Sahkari Bank Ltd" },
  { value: "Vishwa Bandhu Pattina Sahakara Sangha", label: "Vishwa Bandhu Pattina Sahakara Sangha" },
  { value: "Vishwabandhu Credit Co-Op Society Ltd", label: "Vishwabandhu Credit Co-Op Society Ltd" },
  { value: "Vishwabharti Paysantha Ltd Ghodap Deo", label: "Vishwabharti Paysantha Ltd Ghodap Deo" },
  { value: "Vishwabharti Sahakari Pat Ltd", label: "Vishwabharti Sahakari Pat Ltd" },
  { value: "Vishwakalyan Sahakara Bank Niyamith - Hubli", label: "Vishwakalyan Sahakara Bank Niyamith - Hubli" },
  { value: "Vishwakama Hire Purchase Co. Pvt Ltd", label: "Vishwakama Hire Purchase Co. Pvt Ltd" },
  { value: "Vishwakarma Nagari Sahakari Bank Ltd.", label: "Vishwakarma Nagari Sahakari Bank Ltd." },
  { value: "Vishwakarma Sahakara Bank(N)", label: "Vishwakarma Sahakara Bank(N)" },
  { value: "Vishwakarma Sahkari Bank N", label: "Vishwakarma Sahkari Bank N" },
  { value: "Vishwakarma Ur Co-Op Cr Society Ltd", label: "Vishwakarma Ur Co-Op Cr Society Ltd" },
  { value: "Vishwanath Motor Finance", label: "Vishwanath Motor Finance" },
  { value: "Vishwanath Singh Bayas Nagri Sahakri Path Sanstha", label: "Vishwanath Singh Bayas Nagri Sahakri Path Sanstha" },
  { value: "Vishwanath Singh Bayas Nagri Sahakri Path Sanstha P", label: "Vishwanath Singh Bayas Nagri Sahakri Path Sanstha P" },
  { value: "Vishwanathrao Bhegade Nagari Sahahakari Patsansta", label: "Vishwanathrao Bhegade Nagari Sahahakari Patsansta" },
  { value: "Vishwanathrao Patil Murgud Coop Bank Ltd", label: "Vishwanathrao Patil Murgud Coop Bank Ltd" },
  { value: "Vishwar Nagari Sahakari Pat Sanstha Maryadit", label: "Vishwar Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Vishwaroopa Vividodesha Patina Souhardha Niyamitha", label: "Vishwaroopa Vividodesha Patina Souhardha Niyamitha" },
  { value: "Vishwas Autumobiles Finance", label: "Vishwas Autumobiles Finance" },
  { value: "Vishwas Co-Op Bank Ltd", label: "Vishwas Co-Op Bank Ltd" },
  { value: "Vishwas Credit Co Op Ltd.", label: "Vishwas Credit Co Op Ltd." },
  { value: "Vishwas Financiers", label: "Vishwas Financiers" },
  { value: "Vishwas Gramin Bigar Sheti Sahakari Patsanstha", label: "Vishwas Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Vishwas Nagari Sahakari Pat Sanstha", label: "Vishwas Nagari Sahakari Pat Sanstha" },
  { value: "Vishwas Souhardha Credit Co Op Society", label: "Vishwas Souhardha Credit Co Op Society" },
  { value: "Vishweshwar Sahakari Bank Ltd., Pune", label: "Vishweshwar Sahakari Bank Ltd., Pune" },
  { value: "Vishweshwaraiah Co-Operative Bank", label: "Vishweshwaraiah Co-Operative Bank" },
  { value: "Vishwnath Nagari Pat Sath", label: "Vishwnath Nagari Pat Sath" },
  { value: "Visl Employees Co-Op. Bank Ltd.", label: "Visl Employees Co-Op. Bank Ltd." },
  { value: "Visl Sail Bhadravathi", label: "Visl Sail Bhadravathi" },
  { value: "Visnagar Finance", label: "Visnagar Finance" },
  { value: "Visnagar Malvahan Vyvhar Sahkari Mandali Ltd", label: "Visnagar Malvahan Vyvhar Sahkari Mandali Ltd" },
  { value: "Visnagar Nagrik Sah. Bank Ltd.", label: "Visnagar Nagrik Sah. Bank Ltd." },
  { value: "Visvesvaraya Iron And Steel Ltd", label: "Visvesvaraya Iron And Steel Ltd" },
  { value: "Viswa Karmana Auto Finance", label: "Viswa Karmana Auto Finance" },
  { value: "Viswanathan Investment", label: "Viswanathan Investment" },
  { value: "Viswanathar Auto Finance", label: "Viswanathar Auto Finance" },
  { value: "Viswanathar Investments", label: "Viswanathar Investments" },
  { value: "Vita Merchants Co-Operative Bank", label: "Vita Merchants Co-Operative Bank" },
  { value: "Vita Urban Co-Op Credit Society Ltd Vita", label: "Vita Urban Co-Op Credit Society Ltd Vita" },
  { value: "Vitai Bank", label: "Vitai Bank" },
  { value: "Vithai Gramin Bigar Sheti Sahakari", label: "Vithai Gramin Bigar Sheti Sahakari" },
  { value: "Vithal Co Op Credit Soc Ltd", label: "Vithal Co Op Credit Soc Ltd" },
  { value: "Vithayathil Enterprises", label: "Vithayathil Enterprises" },
  { value: "Vitin Finance", label: "Vitin Finance" },
  { value: "Vittahl Rukhmini Patsanstha", label: "Vittahl Rukhmini Patsanstha" },
  { value: "Vittal Grameena Sahakari Bank", label: "Vittal Grameena Sahakari Bank" },
  { value: "Vittal Grameena Sahakari Bank Niyamitha", label: "Vittal Grameena Sahakari Bank Niyamitha" },
  { value: "Vittal Vyavasaya Seva Sahakari Bank, Niy", label: "Vittal Vyavasaya Seva Sahakari Bank, Niy" },
  { value: "Vitthal Nagari Sahakari Bank Ltd", label: "Vitthal Nagari Sahakari Bank Ltd" },
  { value: "Vittrag Co Op Bank Ltd", label: "Vittrag Co Op Bank Ltd" },
  { value: "Viva Holidays Tours & Travels Pvt Ltd.", label: "Viva Holidays Tours & Travels Pvt Ltd." },
  { value: "Vivek Agarwal Huf", label: "Vivek Agarwal Huf" },
  { value: "Vivek Finance Corporation", label: "Vivek Finance Corporation" },
  { value: "Vivek Gramin Bigar Sheti Sahkari Patsanstha Ltd.", label: "Vivek Gramin Bigar Sheti Sahkari Patsanstha Ltd." },
  { value: "Vivek Hire Purchase And Leasing Ltd.", label: "Vivek Hire Purchase And Leasing Ltd." },
  { value: "Vivek Investment", label: "Vivek Investment" },
  { value: "Vivek Kulkarni", label: "Vivek Kulkarni" },
  { value: "Vivek Motor Fin Ltd", label: "Vivek Motor Fin Ltd" },
  { value: "Vivek Sharma", label: "Vivek Sharma" },
  { value: "Vivekanand Gramin Bigar Sahkari Pat San", label: "Vivekanand Gramin Bigar Sahkari Pat San" },
  { value: "Vivekanand Nagrik Sahakari Bank Mydt", label: "Vivekanand Nagrik Sahakari Bank Mydt" },
  { value: "Vivekanand Sahakari Bank Niyamita", label: "Vivekanand Sahakari Bank Niyamita" },
  { value: "Vivekananda Nagarik Sahakari Bank Maryadit", label: "Vivekananda Nagarik Sahakari Bank Maryadit" },
  { value: "Vivekanandji Sahakari Patpedhi Maryadit", label: "Vivekanandji Sahakari Patpedhi Maryadit" },
  { value: "Vividh Karyakari Sahakari Society", label: "Vividh Karyakari Sahakari Society" },
  { value: "Vividh Karyakari Seva Sahakari Sociaty Phalawani", label: "Vividh Karyakari Seva Sahakari Sociaty Phalawani" },
  { value: "Vivya Bank Branch Gangavati", label: "Vivya Bank Branch Gangavati" },
  { value: "Viyyur Service Co-Op. Bank Ltd.", label: "Viyyur Service Co-Op. Bank Ltd." },
  { value: "Vizianagaram District Central Co-Operative Bank Ltd.", label: "Vizianagaram District Central Co-Operative Bank Ltd." },
  { value: "Vk Lease Finance.", label: "Vk Lease Finance." },
  { value: "Vkc Credit And Forex Services P Ltd", label: "Vkc Credit And Forex Services P Ltd" },
  { value: "Vlm Enterprises", label: "Vlm Enterprises" },
  { value: "Vmk Finance", label: "Vmk Finance" },
  { value: "Vohra Motors & Finance", label: "Vohra Motors & Finance" },
  { value: "Volkas Wagen Finance Pvt Ltd", label: "Volkas Wagen Finance Pvt Ltd" },
  { value: "Voltas Ltd.", label: "Voltas Ltd." },
  { value: "Volvo India Pvt Ltd", label: "Volvo India Pvt Ltd" },
  { value: "Vora Siddharth", label: "Vora Siddharth" },
  { value: "Vpk Motors Pvt Ltd", label: "Vpk Motors Pvt Ltd" },
  { value: "Vpk Urban Co - Op Credit Society.", label: "Vpk Urban Co - Op Credit Society." },
  { value: "Vpk Urban Co Op Bank Ltd", label: "Vpk Urban Co Op Bank Ltd" },
  { value: "Vpk Urban Co Op Credit Soc Ltd", label: "Vpk Urban Co Op Credit Soc Ltd" },
  { value: "Vrah Sahakari Saakh Samiti Maryadit", label: "Vrah Sahakari Saakh Samiti Maryadit" },
  { value: "Vrundavan Credit Co-Op Society", label: "Vrundavan Credit Co-Op Society" },
  { value: "Vrushabhavathinagara Cre.Co-Op.Soc.Ltd.", label: "Vrushabhavathinagara Cre.Co-Op.Soc.Ltd." },
  { value: "Vsp Steel Plant", label: "Vsp Steel Plant" },
  { value: "Vssbn Gudadur", label: "Vssbn Gudadur" },
  { value: "Vssbn Nandi Nathapura", label: "Vssbn Nandi Nathapura" },
  { value: "Vssn Bank", label: "Vssn Bank" },
  { value: "Vst Motors Ltd.", label: "Vst Motors Ltd." },
  { value: "Vst Service Station.", label: "Vst Service Station." },
  { value: "Vulcan Leasing And Investments Ltd", label: "Vulcan Leasing And Investments Ltd" },
  { value: "Vvfsl", label: "Vvfsl" },
  { value: "Vvr Auto Finance", label: "Vvr Auto Finance" },
  { value: "Vyankatapati Kaule Bigarsheti Sahakari Patsanstha", label: "Vyankatapati Kaule Bigarsheti Sahakari Patsanstha" },
  { value: "Vyankatesh Co-Op Bank Ltd", label: "Vyankatesh Co-Op Bank Ltd" },
  { value: "Vyankatesh Nagari Sahakari Patsanstha Ltd Chandwad", label: "Vyankatesh Nagari Sahakari Patsanstha Ltd Chandwad" },
  { value: "Vyapara Vijayam Trades", label: "Vyapara Vijayam Trades" },
  { value: "Vyaparavijayam Hire Purcahses &Leasing Ltd", label: "Vyaparavijayam Hire Purcahses &Leasing Ltd" },
  { value: "Vyapari Gramin Bigar Sheti Sahkari Pat San", label: "Vyapari Gramin Bigar Sheti Sahkari Pat San" },
  { value: "Vyapari Nagari Sahakari Path Sanstha", label: "Vyapari Nagari Sahakari Path Sanstha" },
  { value: "Vyapari Sahakari Bank Maryadit Solapur", label: "Vyapari Sahakari Bank Maryadit Solapur" },
  { value: "Vyapari Sahakari Bank Maryadits.", label: "Vyapari Sahakari Bank Maryadits." },
  { value: "Vyapari Sahakari Pat Sansthan Maryadit", label: "Vyapari Sahakari Pat Sansthan Maryadit" },
  { value: "Vyaparik Au. Sa. Bank Ltd.", label: "Vyaparik Au. Sa. Bank Ltd." },
  { value: "Vyaparik Audhyogik Sah.Bank Ltd., Indore", label: "Vyaparik Audhyogik Sah.Bank Ltd., Indore" },
  { value: "Vyavasaya Seva Sahakara Bank Niyamitha- Haunsabhavi", label: "Vyavasaya Seva Sahakara Bank Niyamitha- Haunsabhavi" },
  { value: "Vyavasaya Seva Sahakari Bank Ltd", label: "Vyavasaya Seva Sahakari Bank Ltd" },
  { value: "Vyavasayik Evam Audhyogik Sah. Bank Ltd", label: "Vyavasayik Evam Audhyogik Sah. Bank Ltd" },
  { value: "Vyavasya Seva Sahakara Bank Ltd.", label: "Vyavasya Seva Sahakara Bank Ltd." },
  { value: "Vyavsayik Sahkari Bank Ltd.", label: "Vyavsayik Sahkari Bank Ltd." },
  { value: "Vyavsayik Sahkari Bank Ltd.", label: "Vyavsayik Sahkari Bank Ltd." },
  { value: "Vypari Sahakari Bank Ltd", label: "Vypari Sahakari Bank Ltd" },
  { value: "Vysu Auto Finnaace", label: "Vysu Auto Finnaace" },
  { value: "Vysya Bank Ltd.", label: "Vysya Bank Ltd." },
  { value: "Vysya Bank Ltd.", label: "Vysya Bank Ltd." },
  { value: "Vysya Co-Operative Bank Ltd", label: "Vysya Co-Operative Bank Ltd" },
  { value: "Vysya Co-Operative Bank Ltd", label: "Vysya Co-Operative Bank Ltd" },
  { value: "Vysya Sahakari Bank Ltd", label: "Vysya Sahakari Bank Ltd" },
  { value: "W. C. L Employees Co Op Society Ltd", label: "W. C. L Employees Co Op Society Ltd" },
  { value: "W.B. Minority Dev. & Fin. Corpn.", label: "W.B. Minority Dev. & Fin. Corpn." },
  { value: "W.B. State Co-Op Agriculture & Rural Dev Bank", label: "W.B. State Co-Op Agriculture & Rural Dev Bank" },
  { value: "W.B.S.C And S.T.Dev And Finc Corpn.", label: "W.B.S.C And S.T.Dev And Finc Corpn." },
  { value: "W.B.S.C.A.R.D. Bank Ltd", label: "W.B.S.C.A.R.D. Bank Ltd" },
  { value: "Wada Nagari Sah Patsantha Mdt", label: "Wada Nagari Sah Patsantha Mdt" },
  { value: "Wada Nagri Sahakari Patsanstha", label: "Wada Nagri Sahakari Patsanstha" },
  { value: "Wagle Estate Co-Op Credit Society Ltd", label: "Wagle Estate Co-Op Credit Society Ltd" },
  { value: "Wahguru Truck Services", label: "Wahguru Truck Services" },
  { value: "Wai Urban Co-Op Bank Ltd", label: "Wai Urban Co-Op Bank Ltd" },
  { value: "Waigai Co-Op Cre. Soc. Ltd.", label: "Waigai Co-Op Cre. Soc. Ltd." },
  { value: "Wainganga Krushi Gram Bank Ltd", label: "Wainganga Krushi Gram Bank Ltd" },
  { value: "Wainganga Kshetriya Gramin Bank", label: "Wainganga Kshetriya Gramin Bank" },
  { value: "Wainganga Kshetriya Gramin Bank", label: "Wainganga Kshetriya Gramin Bank" },
  { value: "Walchand Hindustan Limited", label: "Walchand Hindustan Limited" },
  { value: "Walchandanagar Sahakari Bank Ltd", label: "Walchandanagar Sahakari Bank Ltd" },
  { value: "Walchandnagar Sahakari Bank Ltd.", label: "Walchandnagar Sahakari Bank Ltd." },
  { value: "Walden Nikko India Management Company Limited", label: "Walden Nikko India Management Company Limited" },
  { value: "Walia Motors", label: "Walia Motors" },
  { value: "Wall Street Finance Ltd.", label: "Wall Street Finance Ltd." },
  { value: "Walmiki Urban Co Op Bank Ltd", label: "Walmiki Urban Co Op Bank Ltd" },
  { value: "Walton Finance P. Ltd.", label: "Walton Finance P. Ltd." },
  { value: "Wana Nagrik Sahakari Bank Ltd.", label: "Wana Nagrik Sahakari Bank Ltd." },
  { value: "Wandavasi Kasba Co-Op. Society Ltd.", label: "Wandavasi Kasba Co-Op. Society Ltd." },
  { value: "Wani Nagari Sahakari Bank Ltd", label: "Wani Nagari Sahakari Bank Ltd" },
  { value: "Warangal Auto Finance Corporation", label: "Warangal Auto Finance Corporation" },
  { value: "Warangal District Central Co-Operative Bank Ltd.", label: "Warangal District Central Co-Operative Bank Ltd." },
  { value: "Warangal Urban Co-Op Bank Limited", label: "Warangal Urban Co-Op Bank Limited" },
  { value: "Warangal Urban Co-Operative Bank Ltd.", label: "Warangal Urban Co-Operative Bank Ltd." },
  { value: "Wardha District Central Co-Operative Bank Ltd.", label: "Wardha District Central Co-Operative Bank Ltd." },
  { value: "Wardha Nagari Sahakari Adhikosh Bk Ltd", label: "Wardha Nagari Sahakari Adhikosh Bk Ltd" },
  { value: "Wardha Nagri Sahakari Adhikosh Mydt", label: "Wardha Nagri Sahakari Adhikosh Mydt" },
  { value: "Wardha Urban & Rural Co Op Credit Society Maryadit", label: "Wardha Urban & Rural Co Op Credit Society Maryadit" },
  { value: "Wardha Zilla Parishad Employees U Co B L", label: "Wardha Zilla Parishad Employees U Co B L" },
  { value: "Wardhaman Finance Co. Ltd.", label: "Wardhaman Finance Co. Ltd." },
  { value: "Wardhaman Urban Co-Op. Bank Ltd.", label: "Wardhaman Urban Co-Op. Bank Ltd." },
  { value: "Warisila India Ltd", label: "Warisila India Ltd" },
  { value: "Warna Sahakari Bank", label: "Warna Sahakari Bank" },
  { value: "Warrior (Investment ) Ltd", label: "Warrior (Investment ) Ltd" },
  { value: "Warriyar Finance", label: "Warriyar Finance" },
  { value: "Warud Urban Co-Op. Bank Ltd.", label: "Warud Urban Co-Op. Bank Ltd." },
  { value: "Wasan & Sons", label: "Wasan & Sons" },
  { value: "Wasan Automobiles", label: "Wasan Automobiles" },
  { value: "Wasan Motors", label: "Wasan Motors" },
  { value: "Wasson Motors", label: "Wasson Motors" },
  { value: "Water And Power Consultancy Services (India) Ltd.", label: "Water And Power Consultancy Services (India) Ltd." },
  { value: "Water Technology Centre For Eastern Region", label: "Water Technology Centre For Eastern Region" },
  { value: "Waton Finance Pvt Ltd", label: "Waton Finance Pvt Ltd" },
  { value: "Watsagulma Urban Coop Credit Society Ltd", label: "Watsagulma Urban Coop Credit Society Ltd" },
  { value: "Waynad District Central Co-Operative Bank Ltd.", label: "Waynad District Central Co-Operative Bank Ltd." },
  { value: "Wb Sc/St Dev & Finance Corp", label: "Wb Sc/St Dev & Finance Corp" },
  { value: "Wb.Backward Dev.Fin.Corpn.", label: "Wb.Backward Dev.Fin.Corpn." },
  { value: "Wbtidc", label: "Wbtidc" },
  { value: "Weighbird (India) Ltd.", label: "Weighbird (India) Ltd." },
  { value: "Weizmann Home Ltd", label: "Weizmann Home Ltd" },
  { value: "Welcome Finance Co", label: "Welcome Finance Co" },
  { value: "Welcome Investments", label: "Welcome Investments" },
  { value: "Welkin Commercial (P) Ltd", label: "Welkin Commercial (P) Ltd" },
  { value: "Wella India Haircosmetics Pvt Ltd", label: "Wella India Haircosmetics Pvt Ltd" },
  { value: "Wellshine Investments And Financial Services Ltd.", label: "Wellshine Investments And Financial Services Ltd." },
  { value: "Welton Finance P. Ltd", label: "Welton Finance P. Ltd" },
  { value: "West Bengal Electronics Industry Development Corporation Limited.", label: "West Bengal Electronics Industry Development Corporation Limited." },
  { value: "West Bengal Financial Corporation", label: "West Bengal Financial Corporation" },
  { value: "West Bengal Industrial Development Corporation", label: "West Bengal Industrial Development Corporation" },
  { value: "West Bengal Industrial Development Corporation", label: "West Bengal Industrial Development Corporation" },
  { value: "West Bengal Infrastructure Development Finance Corpn Ltd", label: "West Bengal Infrastructure Development Finance Corpn Ltd" },
  { value: "West Bengal Schedule Caste", label: "West Bengal Schedule Caste" },
  { value: "West Bengal State Co-Op Bank Ltd.", label: "West Bengal State Co-Op Bank Ltd." },
  { value: "West Bengal State Tourism Development Corporation Ltd.", label: "West Bengal State Tourism Development Corporation Ltd." },
  { value: "West Bengal University Of Animal And Fishery Sciences", label: "West Bengal University Of Animal And Fishery Sciences" },
  { value: "West Line Auto Hire Purchase", label: "West Line Auto Hire Purchase" },
  { value: "West Quilon Service Co-Op Bank Ltd.", label: "West Quilon Service Co-Op Bank Ltd." },
  { value: "Western Coalfields Limited", label: "Western Coalfields Limited" },
  { value: "Western Maharashtraindustrial Development Corporation", label: "Western Maharashtraindustrial Development Corporation" },
  { value: "Western Railway", label: "Western Railway" },
  { value: "Western Railway Rajkot", label: "Western Railway Rajkot" },
  { value: "Wheel And Axle Plant", label: "Wheel And Axle Plant" },
  { value: "Wheel Business And Leisure Travels Pvt Ltd", label: "Wheel Business And Leisure Travels Pvt Ltd" },
  { value: "Wheel Finance", label: "Wheel Finance" },
  { value: "Wheel Finance", label: "Wheel Finance" },
  { value: "Wheel Finance & Investments", label: "Wheel Finance & Investments" },
  { value: "Win Finance", label: "Win Finance" },
  { value: "Wincost Kuries P Ltd", label: "Wincost Kuries P Ltd" },
  { value: "Windpipe Finvest Pvt Ltd", label: "Windpipe Finvest Pvt Ltd" },
  { value: "Windsor Banks", label: "Windsor Banks" },
  { value: "Wiseman Finance Private Limited", label: "Wiseman Finance Private Limited" },
  { value: "Womani Co Op Bank Ltd", label: "Womani Co Op Bank Ltd" },
  { value: "Womans Welfare Co Op Socity", label: "Womans Welfare Co Op Socity" },
  { value: "Womens Co-Op Bank Ltd", label: "Womens Co-Op Bank Ltd" },
  { value: "Wonderful Finance And Inv Ltd", label: "Wonderful Finance And Inv Ltd" },
  { value: "Word Portfolio Finance And Leasing Ltd.", label: "Word Portfolio Finance And Leasing Ltd." },
  { value: "World Wide Finance", label: "World Wide Finance" },
  { value: "Worth Capital Fin Co Ltd", label: "Worth Capital Fin Co Ltd" },
  { value: "Worth Capital, Nagpur", label: "Worth Capital, Nagpur" },
  { value: "Wyeth Limited", label: "Wyeth Limited" },
  { value: "Y And H Patel Finance Ltd.", label: "Y And H Patel Finance Ltd." },
  { value: "Y- 48 Mylaudy Primary Agricultural Co - Operative Bank Ltd", label: "Y- 48 Mylaudy Primary Agricultural Co - Operative Bank Ltd" },
  { value: "Y.V. Chits & Finance", label: "Y.V. Chits & Finance" },
  { value: "Y.Vijaya Chandrababu Auto Mobile Financiers", label: "Y.Vijaya Chandrababu Auto Mobile Financiers" },
  { value: "Y2K Patel Finance Ltd", label: "Y2K Patel Finance Ltd" },
  { value: "Yadav Tractor", label: "Yadav Tractor" },
  { value: "Yadgiri Lakshmi Narsimha Swamy Co-Op Urban Bank Ltd", label: "Yadgiri Lakshmi Narsimha Swamy Co-Op Urban Bank Ltd" },
  { value: "Yadrav Co-Op Bank Ltd", label: "Yadrav Co-Op Bank Ltd" },
  { value: "Yadrav Co-Op Bank Ltd", label: "Yadrav Co-Op Bank Ltd" },
  { value: "Yadray Co-Op Bank Ltd", label: "Yadray Co-Op Bank Ltd" },
  { value: "Yamakanmardi Balakrishna Souharda Pattina Sahakari Ni", label: "Yamakanmardi Balakrishna Souharda Pattina Sahakari Ni" },
  { value: "Yamakanmardi Co-Op Society", label: "Yamakanmardi Co-Op Society" },
  { value: "Yamuna Automobiles Jagadhri", label: "Yamuna Automobiles Jagadhri" },
  { value: "Yamuna Nagar District Central Co-Operative Bank Ltd.", label: "Yamuna Nagar District Central Co-Operative Bank Ltd." },
  { value: "Yandh Patel Finance", label: "Yandh Patel Finance" },
  { value: "Yantra Nirman Nagari Sah Path Sanstha Maryadit", label: "Yantra Nirman Nagari Sah Path Sanstha Maryadit" },
  { value: "Yaragatti Urban Co-Op Bank Ltd", label: "Yaragatti Urban Co-Op Bank Ltd" },
  { value: "Yaseera Asad", label: "Yaseera Asad" },
  { value: "Yash Auto Deal", label: "Yash Auto Deal" },
  { value: "Yash Co-Op Credit Soc Ltd", label: "Yash Co-Op Credit Soc Ltd" },
  { value: "Yash Hier & Purchases", label: "Yash Hier & Purchases" },
  { value: "Yash Nagari Patsanstha Ltd.", label: "Yash Nagari Patsanstha Ltd." },
  { value: "Yashanjali Gramin Bigar Sheti Sahkari Pathsanstha", label: "Yashanjali Gramin Bigar Sheti Sahkari Pathsanstha" },
  { value: "Yashaswi Leasing And Finance Ltd", label: "Yashaswi Leasing And Finance Ltd" },
  { value: "Yashaswi Mahila Urban Credit Sahakari Niyamit", label: "Yashaswi Mahila Urban Credit Sahakari Niyamit" },
  { value: "Yashaswi Mahila Urban Credit Saihard Sahakari Niyalamta", label: "Yashaswi Mahila Urban Credit Saihard Sahakari Niyalamta" },
  { value: "Yashica Reddy", label: "Yashica Reddy" },
  { value: "Yashideep Pathpedi", label: "Yashideep Pathpedi" },
  { value: "Yashik Finance Company", label: "Yashik Finance Company" },
  { value: "Yashika Leasing And Financial Services Ltd.", label: "Yashika Leasing And Financial Services Ltd." },
  { value: "Yashoda Gramin Bigarsheti Sah Pat Sanstha", label: "Yashoda Gramin Bigarsheti Sah Pat Sanstha" },
  { value: "Yashoda Gramin Sahakari Path Sanstha", label: "Yashoda Gramin Sahakari Path Sanstha" },
  { value: "Yashodeep Co Op Cre Soc Ltd", label: "Yashodeep Co Op Cre Soc Ltd" },
  { value: "Yashodeep Co Op Cre Soc Ltd", label: "Yashodeep Co Op Cre Soc Ltd" },
  { value: "Yashodeep Sahakari Patkodi", label: "Yashodeep Sahakari Patkodi" },
  { value: "Yashodeep Sahakari Patpedi Ltd", label: "Yashodeep Sahakari Patpedi Ltd" },
  { value: "Yashodha Sahakari Patpedhi  Maryadit Financial", label: "Yashodha Sahakari Patpedhi  Maryadit Financial" },
  { value: "Yashomandir Sahakari Patpedhi Maryadit", label: "Yashomandir Sahakari Patpedhi Maryadit" },
  { value: "Yashomandir Sahakari Patpedhi Maryadit Ltd", label: "Yashomandir Sahakari Patpedhi Maryadit Ltd" },
  { value: "Yashpal Singh Bhatia", label: "Yashpal Singh Bhatia" },
  { value: "Yashwant Gramin Bigar Sheti Sahakari Pathsanstha", label: "Yashwant Gramin Bigar Sheti Sahakari Pathsanstha" },
  { value: "Yashwant Sahakari Bank Ltd.", label: "Yashwant Sahakari Bank Ltd." },
  { value: "Yashwantrao Chavan Gramin Biger Sh Sah Pat Mar", label: "Yashwantrao Chavan Gramin Biger Sh Sah Pat Mar" },
  { value: "Yaval Peoples Co-Op Bank Ltd", label: "Yaval Peoples Co-Op Bank Ltd" },
  { value: "Yavatmal Dist Mahesh Nag Pat.San.Mar.", label: "Yavatmal Dist Mahesh Nag Pat.San.Mar." },
  { value: "Yavatmal Dist. Central Co-Op.Bank Ltd.", label: "Yavatmal Dist. Central Co-Op.Bank Ltd." },
  { value: "Yavatmal District Central Co-Operative Bank Ltd.", label: "Yavatmal District Central Co-Operative Bank Ltd." },
  { value: "Yavatmal Gramin Bank", label: "Yavatmal Gramin Bank" },
  { value: "Yavatmal Mahila Sahakari Bank Ltd", label: "Yavatmal Mahila Sahakari Bank Ltd" },
  { value: "Yavatmal Urban Co-Op Bank Ltd", label: "Yavatmal Urban Co-Op Bank Ltd" },
  { value: "Yeda Merchants Co-Op Bank Ltd", label: "Yeda Merchants Co-Op Bank Ltd" },
  { value: "Yeduguri Enterprises Ltd.", label: "Yeduguri Enterprises Ltd." },
  { value: "Yeola Merchants Co-Op Bank Ltd", label: "Yeola Merchants Co-Op Bank Ltd" },
  { value: "Yepthomi Motors", label: "Yepthomi Motors" },
  { value: "Yeroor Service Co-Op Bank Ltd.", label: "Yeroor Service Co-Op Bank Ltd." },
  { value: "Yes Bank Limited.", label: "Yes Bank Limited." },
  { value: "Yeshangali Gramin Biger Sheti Co Op Gospuri", label: "Yeshangali Gramin Biger Sheti Co Op Gospuri" },
  { value: "Yeshwant Nagari Sahakari Bank Ltd", label: "Yeshwant Nagari Sahakari Bank Ltd" },
  { value: "Yeshwant Sahakari Bank Ltd", label: "Yeshwant Sahakari Bank Ltd" },
  { value: "Yeshwant Sahakari Kamgar Bank Ltd.", label: "Yeshwant Sahakari Kamgar Bank Ltd." },
  { value: "Yeshwant Urban Co.Op. Bank Ltd", label: "Yeshwant Urban Co.Op. Bank Ltd" },
  { value: "Yeshwantrao Chavan Nagari Sahakari Patsanstha", label: "Yeshwantrao Chavan Nagari Sahakari Patsanstha" },
  { value: "Yeshwantrao Nagari Sahakari Patpedhi Maryadit", label: "Yeshwantrao Nagari Sahakari Patpedhi Maryadit" },
  { value: "Yesmauli Gramin Bigar Sheti Sah Path", label: "Yesmauli Gramin Bigar Sheti Sah Path" },
  { value: "Yhpl", label: "Yhpl" },
  { value: "Yoga Nandini Auto Finance", label: "Yoga Nandini Auto Finance" },
  { value: "Yogambiga Finance", label: "Yogambiga Finance" },
  { value: "Yogesh A Udani", label: "Yogesh A Udani" },
  { value: "Yogesh Kumar Saxena", label: "Yogesh Kumar Saxena" },
  { value: "Yogeshwar Gramin Bigar Sheti Sah. Patsanstha", label: "Yogeshwar Gramin Bigar Sheti Sah. Patsanstha" },
  { value: "Yogeshwar Patsanstha", label: "Yogeshwar Patsanstha" },
  { value: "Yogeshwar Sah Pathpedi Ltd", label: "Yogeshwar Sah Pathpedi Ltd" },
  { value: "Yogi Enterprises", label: "Yogi Enterprises" },
  { value: "Yogindera Finance Limited", label: "Yogindera Finance Limited" },
  { value: "Yogiraj Nagari Sahkari", label: "Yogiraj Nagari Sahkari" },
  { value: "Yogiraj Sah Patsanstha Maryadit", label: "Yogiraj Sah Patsanstha Maryadit" },
  { value: "Yoosaf A P", label: "Yoosaf A P" },
  { value: "Yougeshwari Nagri Patsanshtha", label: "Yougeshwari Nagri Patsanshtha" },
  { value: "Youth Development Co-Op. Bank Ltd", label: "Youth Development Co-Op. Bank Ltd" },
  { value: "Youth Development Co-Op.Bank Ltd., Kolhapur", label: "Youth Development Co-Op.Bank Ltd., Kolhapur" },
  { value: "Youth Foundation Nagari Sah Pathsanstha Maryadit", label: "Youth Foundation Nagari Sah Pathsanstha Maryadit" },
  { value: "Yugandhar Mothukuri", label: "Yugandhar Mothukuri" },
  { value: "Yugraj Traders", label: "Yugraj Traders" },
  { value: "Yuktha Finance Ltd", label: "Yuktha Finance Ltd" },
  { value: "Yuva Jagruti Sah Path Sanstha", label: "Yuva Jagruti Sah Path Sanstha" },
  { value: "Yuva Vikas Co-Op Credit Soc Ltd", label: "Yuva Vikas Co-Op Credit Soc Ltd" },
  { value: "Yuva Vikas Sahakari Patpedhi Maryadit", label: "Yuva Vikas Sahakari Patpedhi Maryadit" },
  { value: "Yuvak Sah Path Pedhi Marya", label: "Yuvak Sah Path Pedhi Marya" },
  { value: "Yuvak Sah Patsanstha Maryadit", label: "Yuvak Sah Patsanstha Maryadit" },
  { value: "Zaman Group Finance", label: "Zaman Group Finance" },
  { value: "Zamintara Investment Consultancy", label: "Zamintara Investment Consultancy" },
  { value: "Zee Finance", label: "Zee Finance" },
  { value: "Zen Insurance Brokers Pvt. Ltd.", label: "Zen Insurance Brokers Pvt. Ltd." },
  { value: "Zen Motor Finance", label: "Zen Motor Finance" },
  { value: "Zenith Credit Corporation", label: "Zenith Credit Corporation" },
  { value: "Zenith Credit Corporation Ltd", label: "Zenith Credit Corporation Ltd" },
  { value: "Zenith Credit Ltd", label: "Zenith Credit Ltd" },
  { value: "Zenith Leisure Holidays", label: "Zenith Leisure Holidays" },
  { value: "Zenon Insurance Brokers Pvt Ltd", label: "Zenon Insurance Brokers Pvt Ltd" },
  { value: "Zicom Electronic Security Systems Ltd", label: "Zicom Electronic Security Systems Ltd" },
  { value: "Zila Basik Siksha Parishad", label: "Zila Basik Siksha Parishad" },
  { value: "Zila Sahakari Bank", label: "Zila Sahakari Bank" },
  { value: "Zonal Manager Apiic Ltd", label: "Zonal Manager Apiic Ltd" },
  { value: "Zoom Insurance Brokers Private Limi", label: "Zoom Insurance Brokers Private Limi" },
  { value: "Zorastrian Co Op Bank", label: "Zorastrian Co Op Bank" },
  { value: "Zorastrian Coop Bank Ltd", label: "Zorastrian Coop Bank Ltd" },
  { value: "Zuari Industries Ltd", label: "Zuari Industries Ltd" },
  { value: "Zuari Leasing And Finance Corporation Ltd.", label: "Zuari Leasing And Finance Corporation Ltd." },
  { value: "Zwirn Pragati Capfin Pvt Ltd", label: "Zwirn Pragati Capfin Pvt Ltd" },
  { value: "Zwrain Pragathi Caf Fin Pvt Ltd", label: "Zwrain Pragathi Caf Fin Pvt Ltd" },
  { value: "Zwrin Pragathi Sink Corp Ltd.", label: "Zwrin Pragathi Sink Corp Ltd." },
  { value: "Sri Basaveshwar Ur Cr Ss Nyt", label: "Sri Basaveshwar Ur Cr Ss Nyt" },
  { value: "Jinaral Ur Cr Sou Sahakari Nyt", label: "Jinaral Ur Cr Sou Sahakari Nyt" },
  { value: "Narnada Jhabua Gramin Bank", label: "Narnada Jhabua Gramin Bank" },
  { value: "Jaylakshmi Credit Company Ltd", label: "Jaylakshmi Credit Company Ltd" },
  { value: "Shree Ganesh Finance", label: "Shree Ganesh Finance" },
  { value: "Supreme Securities Ltd", label: "Supreme Securities Ltd" },
  { value: "Jai Hanuman Vivdhoddeshagal Saou Sah Niy", label: "Jai Hanuman Vivdhoddeshagal Saou Sah Niy" },
  { value: "Aryachankya Nagari Sah. Pat. Sanstha Marya", label: "Aryachankya Nagari Sah. Pat. Sanstha Marya" },
  { value: "Ess Kay Auto Finance Ltd", label: "Ess Kay Auto Finance Ltd" },
  { value: "Adhi Laxmi Hi Pur Finance", label: "Adhi Laxmi Hi Pur Finance" },
  { value: "The Sahyadri M .P .Co.Op.Soc.Ltd", label: "The Sahyadri M .P .Co.Op.Soc.Ltd" },
  { value: "Ankush Nag Sah. Path.Sanstha.Mydt", label: "Ankush Nag Sah. Path.Sanstha.Mydt" },
  { value: "Shri Akash Vividuddeshgal Sah.San.Nymt", label: "Shri Akash Vividuddeshgal Sah.San.Nymt" },
  { value: "Udgaon G.B.S.S.P.S.Mydt", label: "Udgaon G.B.S.S.P.S.Mydt" },
  { value: "Shri Mahavir G.S.B.S.P.S.Mydt", label: "Shri Mahavir G.S.B.S.P.S.Mydt" },
  { value: "Melody Hire Purchase And Leasing Company", label: "Melody Hire Purchase And Leasing Company" },
  { value: "Param Pujj Sane Guruji Gramin Bigarsheti Sahakari Pat Santha Maryya", label: "Param Pujj Sane Guruji Gramin Bigarsheti Sahakari Pat Santha Maryya" },
  { value: "Godara Solution India", label: "Godara Solution India" },
  { value: "Narshimha Anand Financer", label: "Narshimha Anand Financer" },
  { value: "R.D.Finance", label: "R.D.Finance" },
  { value: "The Anand B.S.N.L Emp. Co.Op.Credit Soceity Ltd", label: "The Anand B.S.N.L Emp. Co.Op.Credit Soceity Ltd" },
  { value: "Shri Ganesh Co Op Credit Society Ltd", label: "Shri Ganesh Co Op Credit Society Ltd" },
  { value: "Shri Shantappanna Mirji Urban", label: "Shri Shantappanna Mirji Urban" },
  { value: "Sri Velavan Auto Finance", label: "Sri Velavan Auto Finance" },
  { value: "The Vettackal Sevice Co Operative Bank Ltd", label: "The Vettackal Sevice Co Operative Bank Ltd" },
  { value: "The Cherthala Co Operative Agricultural And Rural Development Bank Ltd", label: "The Cherthala Co Operative Agricultural And Rural Development Bank Ltd" },
  { value: "Mangalore Refinery And Petrochemicals Ltd", label: "Mangalore Refinery And Petrochemicals Ltd" },
  { value: "Mallika Enterprises", label: "Mallika Enterprises" },
  { value: "Hanuman Multi Purpose Co Op Society Ltd", label: "Hanuman Multi Purpose Co Op Society Ltd" },
  { value: "Shri Naganath Co Op Credit Society Ltd", label: "Shri Naganath Co Op Credit Society Ltd" },
  { value: "Dhanlakshmi Enterprises", label: "Dhanlakshmi Enterprises" },
  { value: "Capital Motor Investment Co", label: "Capital Motor Investment Co" },
  { value: "M.Roshankumar", label: "M.Roshankumar" },
  { value: "Sri Siva Investments", label: "Sri Siva Investments" },
  { value: "Sri Auto Finance", label: "Sri Auto Finance" },
  { value: "Veliyathunad Service Co Operative Bank Ltd", label: "Veliyathunad Service Co Operative Bank Ltd" },
  { value: "Nirmal Ujjwal Credit Co Op Society Ltd", label: "Nirmal Ujjwal Credit Co Op Society Ltd" },
  { value: "Shri Ganesh Investment", label: "Shri Ganesh Investment" },
  { value: "Kartar Fin Ease Ltd", label: "Kartar Fin Ease Ltd" },
  { value: "Vipra Vividodesha Souharda Sahakari Niyamit", label: "Vipra Vividodesha Souharda Sahakari Niyamit" },
  { value: "Vadasinor Pragati Samaj Co Op Csl", label: "Vadasinor Pragati Samaj Co Op Csl" },
  { value: "Shri Adinath Multipurpose Sou Sah Ltd", label: "Shri Adinath Multipurpose Sou Sah Ltd" },
  { value: "Kittur Rani Channamma Ur Cre Soun Sah Ltd", label: "Kittur Rani Channamma Ur Cre Soun Sah Ltd" },
  { value: "Baid Leasing And Finance Co Ltd", label: "Baid Leasing And Finance Co Ltd" },
  { value: "Agaram Finance", label: "Agaram Finance" },
  { value: "Arul Murugan Auto Finance", label: "Arul Murugan Auto Finance" },
  { value: "Sri Murugan Finance", label: "Sri Murugan Finance" },
  { value: "Sri Vyshnavi Hi Pur Finance", label: "Sri Vyshnavi Hi Pur Finance" },
  { value: "P K P S", label: "P K P S" },
  { value: "Shri Jyotirling Nagari Sah Pat Sanstha Maryadit", label: "Shri Jyotirling Nagari Sah Pat Sanstha Maryadit" },
  { value: "Marathavaibhav Sahakari Patpedhi Ltd", label: "Marathavaibhav Sahakari Patpedhi Ltd" },
  { value: "Pathardi Vividh Karya Sah. Seva Sanstha Maryadit", label: "Pathardi Vividh Karya Sah. Seva Sanstha Maryadit" },
  { value: "The Mettur Chemicals Employees Co Op Ltd", label: "The Mettur Chemicals Employees Co Op Ltd" },
  { value: "Boparai Finance Corp", label: "Boparai Finance Corp" },
  { value: "Belgaum Maratha Mitra Mandal Nagari Sah Pat Sanstha Ltd", label: "Belgaum Maratha Mitra Mandal Nagari Sah Pat Sanstha Ltd" },
  { value: "Patil Multi Purpose Sou Sah Niy", label: "Patil Multi Purpose Sou Sah Niy" },
  { value: "Sri Hari Hara Motors", label: "Sri Hari Hara Motors" },
  { value: "The Mettur Chemicals Employees Co Op Ltd S736", label: "The Mettur Chemicals Employees Co Op Ltd S736" },
  { value: "Nanbargal Finance", label: "Nanbargal Finance" },
  { value: "Citicrop Finance India Ltd", label: "Citicrop Finance India Ltd" },
  { value: "Pavilam And Co", label: "Pavilam And Co" },
  { value: "Sai Balaji Hire Purchase And Finance", label: "Sai Balaji Hire Purchase And Finance" },
  { value: "Leon Wheels India P Ltd", label: "Leon Wheels India P Ltd" },
  { value: "Ravisagar Auto Finance Reg", label: "Ravisagar Auto Finance Reg" },
  { value: "Shri Shyam Finance", label: "Shri Shyam Finance" },
  { value: "Dnyanaradha Multi State Credit Co Op Society Ltd", label: "Dnyanaradha Multi State Credit Co Op Society Ltd" },
  { value: "Shri Laxmi Nagari Sah Pat Marya", label: "Shri Laxmi Nagari Sah Pat Marya" },
  { value: "Finvent Finance And Investment Ltd Kottayam", label: "Finvent Finance And Investment Ltd Kottayam" },
  { value: "The Nalunnakal Sevice Cooperative Bank Ltd No 296", label: "The Nalunnakal Sevice Cooperative Bank Ltd No 296" },
  { value: "Tehri Garhwal District Co Operative Bank", label: "Tehri Garhwal District Co Operative Bank" },
  { value: "Epk Associates", label: "Epk Associates" },
  { value: "Thirumala Venkateswar Samy Auto Finance", label: "Thirumala Venkateswar Samy Auto Finance" },
  { value: "A.A.528 Vazhithottam Primary Agri Co Operative", label: "A.A.528 Vazhithottam Primary Agri Co Operative" },
  { value: "Sri Seenivasa Financiers", label: "Sri Seenivasa Financiers" },
  { value: "Maha Maruthi Auto Finance", label: "Maha Maruthi Auto Finance" },
  { value: "Aa521 Cheeniangirivalasu Primary Agricultural Co Operative", label: "Aa521 Cheeniangirivalasu Primary Agricultural Co Operative" },
  { value: "Uttarakhand Gramin Bank", label: "Uttarakhand Gramin Bank" },
  { value: "Sri Anna Lakshmi Auto Finance", label: "Sri Anna Lakshmi Auto Finance" },
  { value: "Uae Xchange And Financial Services Ltd", label: "Uae Xchange And Financial Services Ltd" },
  { value: "Shreyans Khabiya", label: "Shreyans Khabiya" },
  { value: "Shashwat Financiers", label: "Shashwat Financiers" },
  { value: "Sunrise Sou Pa Sa Sa Ni", label: "Sunrise Sou Pa Sa Sa Ni" },
  { value: "Shri Aadinath Finance", label: "Shri Aadinath Finance" },
  { value: "Siddh Enterprise", label: "Siddh Enterprise" },
  { value: "Vagrech Vibhag Bachat And Dhiran Karanari Sahkari Mandali Ltd", label: "Vagrech Vibhag Bachat And Dhiran Karanari Sahkari Mandali Ltd" },
  { value: "Shri Moti Barad Juth Seva Sahkari Mandli Ltd", label: "Shri Moti Barad Juth Seva Sahkari Mandli Ltd" },
  { value: "Kurinji Finance", label: "Kurinji Finance" },
  { value: "The Dediyasan Seva Mandali Ltd", label: "The Dediyasan Seva Mandali Ltd" },
  { value: "Core Finance", label: "Core Finance" },
  { value: "Sri Senthilmurugan Auto Finance", label: "Sri Senthilmurugan Auto Finance" },
  { value: "Ms Jai Balaji Finance Company", label: "Ms Jai Balaji Finance Company" },
  { value: "Vijaya Durga Auto And Gen Finance", label: "Vijaya Durga Auto And Gen Finance" },
  { value: "Sakshi Financial Services Pvt Ltd", label: "Sakshi Financial Services Pvt Ltd" },
  { value: "Dnyandeep Co Op Credit Society Ltd", label: "Dnyandeep Co Op Credit Society Ltd" },
  { value: "Kolahpur Zilha Mah.Kh.Kar.S.P.S Mydt", label: "Kolahpur Zilha Mah.Kh.Kar.S.P.S Mydt" },
  { value: "Karmveer Bhaurao Patil Multistate Co Op Credit Soc Ltd Jaysingpur", label: "Karmveer Bhaurao Patil Multistate Co Op Credit Soc Ltd Jaysingpur" },
  { value: "Shri Ganga G.B.S.S.Sah.Pat.San.Myt Chandur", label: "Shri Ganga G.B.S.S.Sah.Pat.San.Myt Chandur" },
  { value: "Bhedasgaon N.S.P.S. Mar Bhedsgaon", label: "Bhedasgaon N.S.P.S. Mar Bhedsgaon" },
  { value: "Jai Shri Hanuman Gramin Bigar Sheti Sha Pat Sanstha", label: "Jai Shri Hanuman Gramin Bigar Sheti Sha Pat Sanstha" },
  { value: "Saikrupa Sahkari Patpedhi Maryadit", label: "Saikrupa Sahkari Patpedhi Maryadit" },
  { value: "Ritika Financial Corporation", label: "Ritika Financial Corporation" },
  { value: "Thuravoor South Coperative Bank Ltd", label: "Thuravoor South Coperative Bank Ltd" },
  { value: "Kantha Kawar Kothar", label: "Kantha Kawar Kothar" },
  { value: "Sagarmal Sons", label: "Sagarmal Sons" },
  { value: "Dhoraji Memon Jamat Mumbai", label: "Dhoraji Memon Jamat Mumbai" },
  { value: "Axis Trustee Services Limited", label: "Axis Trustee Services Limited" },
  { value: "Sidhanth Trade Finance", label: "Sidhanth Trade Finance" },
  { value: "Pooja Finelease Pvt Ltd", label: "Pooja Finelease Pvt Ltd" },
  { value: "Rahul Enterprise", label: "Rahul Enterprise" },
  { value: "Shree Pisedev Multipurpose Co Op Society Ltd", label: "Shree Pisedev Multipurpose Co Op Society Ltd" },
  { value: "Winner Auto Finance", label: "Winner Auto Finance" },
  { value: "Rajo Pacs Ltd", label: "Rajo Pacs Ltd" },
  { value: "Empire Capitals", label: "Empire Capitals" },
  { value: "P.B", label: "P.B" },
  { value: "The Tamilnadu Industrial Investment Corporation Ltd", label: "The Tamilnadu Industrial Investment Corporation Ltd" },
  { value: "Hanuman Auto Finance", label: "Hanuman Auto Finance" },
  { value: "Inter Province Finance Corp", label: "Inter Province Finance Corp" },
  { value: "R D Commercial", label: "R D Commercial" },
  { value: "Laxmi Sai Hire Purchase And Finance", label: "Laxmi Sai Hire Purchase And Finance" },
  { value: "Sri Balaji Auto Finance", label: "Sri Balaji Auto Finance" },
  { value: "Navodaya Finance Corporation R", label: "Navodaya Finance Corporation R" },
  { value: "Siddivinayaka Souharda Pattina", label: "Siddivinayaka Souharda Pattina" },
  { value: "Jyoti Pooja Money Lander", label: "Jyoti Pooja Money Lander" },
  { value: "Anand Sons Auto Finance Co", label: "Anand Sons Auto Finance Co" },
  { value: "Com Parvat Manaji Rahane Gramin Bigar Sheti Sanpat Sanstha Maryadit", label: "Com Parvat Manaji Rahane Gramin Bigar Sheti Sanpat Sanstha Maryadit" },
  { value: "Purvanchal Bank", label: "Purvanchal Bank" },
  { value: "Mbi Finance", label: "Mbi Finance" },
  { value: "Nishant Multi State Co Op Credit Society", label: "Nishant Multi State Co Op Credit Society" },
  { value: "The Thindal Malai Pa Co Op Cs", label: "The Thindal Malai Pa Co Op Cs" },
  { value: "Manganur Sawantwadi Co Op Credit Soc Ltd", label: "Manganur Sawantwadi Co Op Credit Soc Ltd" },
  { value: "The Baroda City Co Operative Bank Ltd", label: "The Baroda City Co Operative Bank Ltd" },
  { value: "Shree Ganesh Automobiles", label: "Shree Ganesh Automobiles" },
  { value: "Singla Finance Co", label: "Singla Finance Co" },
  { value: "Pimpri Chinchwad Municipal Corporation", label: "Pimpri Chinchwad Municipal Corporation" },
  { value: "Jh Finance Corp", label: "Jh Finance Corp" },
  { value: "Jp Morgan Chase Bank", label: "Jp Morgan Chase Bank" },
  { value: "The Markhanday Mahila Multipurpose Co Op Society Ltd", label: "The Markhanday Mahila Multipurpose Co Op Society Ltd" },
  { value: "Pms Finance", label: "Pms Finance" },
  { value: "The Dodar Seva Shakari Man Ltd", label: "The Dodar Seva Shakari Man Ltd" },
  { value: "Simran Enterprises", label: "Simran Enterprises" },
  { value: "M Rajesh Kumar Lunkar", label: "M Rajesh Kumar Lunkar" },
  { value: "Sumangal Motors", label: "Sumangal Motors" },
  { value: "S.S.K.B.S.D.G.B.S.Path Santha", label: "S.S.K.B.S.D.G.B.S.Path Santha" },
  { value: "Sagar Financiers", label: "Sagar Financiers" },
  { value: "Sri Sai Srk Auto Finance", label: "Sri Sai Srk Auto Finance" },
  { value: "Ms Shri Mookambika V.S.S.N", label: "Ms Shri Mookambika V.S.S.N" },
  { value: "M Rajeshkumar Lunkar", label: "M Rajeshkumar Lunkar" },
  { value: "Karthik Auto Finances", label: "Karthik Auto Finances" },
  { value: "Samir Financial Services", label: "Samir Financial Services" },
  { value: "Ratan Financecorp", label: "Ratan Financecorp" },
  { value: "Mahaveer Bike Plaza", label: "Mahaveer Bike Plaza" },
  { value: "Mahaveer Finance Corp", label: "Mahaveer Finance Corp" },
  { value: "Sajini Finance", label: "Sajini Finance" },
  { value: "Shree Kolithad Juth Seva Sah.Ma.Ltd", label: "Shree Kolithad Juth Seva Sah.Ma.Ltd" },
  { value: "Ms Rhino Enterprise", label: "Ms Rhino Enterprise" },
  { value: "Kulswamini Sahakari Patsanstha Maryadit", label: "Kulswamini Sahakari Patsanstha Maryadit" },
  { value: "Lessor Arval India Pvt Ltd", label: "Lessor Arval India Pvt Ltd" },
  { value: "Shriram Finance Corporation Pvt Ltd", label: "Shriram Finance Corporation Pvt Ltd" },
  { value: "Ganesh Cap Fin Ltd", label: "Ganesh Cap Fin Ltd" },
  { value: "Navjivan Co Op Credit Soc Ltd", label: "Navjivan Co Op Credit Soc Ltd" },
  { value: "M. P Sanghvi And Co Finance", label: "M. P Sanghvi And Co Finance" },
  { value: "Shri Bhairavnath Nagari Sah Pat Sanstha", label: "Shri Bhairavnath Nagari Sah Pat Sanstha" },
  { value: "Malkapur Urban Co Op Bank Ltd", label: "Malkapur Urban Co Op Bank Ltd" },
  { value: "Shri Hanuman Vikas Seva Sanstha", label: "Shri Hanuman Vikas Seva Sanstha" },
  { value: "Shri Gurukrupa Gramin Bigar Sheti Sah Pat Sanstha", label: "Shri Gurukrupa Gramin Bigar Sheti Sah Pat Sanstha" },
  { value: "Ex Servicemen Finance Co", label: "Ex Servicemen Finance Co" },
  { value: "Harsul Gramin Big Sheti Sah Patsanstha Maryadit", label: "Harsul Gramin Big Sheti Sah Patsanstha Maryadit" },
  { value: "Shubhodaya Leasing", label: "Shubhodaya Leasing" },
  { value: "Vijayalaxmi Credit Corporation Regd", label: "Vijayalaxmi Credit Corporation Regd" },
  { value: "Navodaya Leasing R", label: "Navodaya Leasing R" },
  { value: "Ms Shri Nandi Leasing R", label: "Ms Shri Nandi Leasing R" },
  { value: "Shri Bandelingeshwar Leasing Finance And Investment Corporation", label: "Shri Bandelingeshwar Leasing Finance And Investment Corporation" },
  { value: "Shree Samarth Leasing R", label: "Shree Samarth Leasing R" },
  { value: "The Bhavasar Kshatriya Co Operative Bank Ltd", label: "The Bhavasar Kshatriya Co Operative Bank Ltd" },
  { value: "Dhanalaxmi Motors Finance Pvt.Ltd", label: "Dhanalaxmi Motors Finance Pvt.Ltd" },
  { value: "Shiv Finance Co", label: "Shiv Finance Co" },
  { value: "Ssis Relations", label: "Ssis Relations" },
  { value: "Shree Ganesh Finance Co", label: "Shree Ganesh Finance Co" },
  { value: "Shri Renuka Mata Nagri Saha Patsanstha Maryadit", label: "Shri Renuka Mata Nagri Saha Patsanstha Maryadit" },
  { value: "Karam Auto Finance", label: "Karam Auto Finance" },
  { value: "Fullerton India Company Ltd", label: "Fullerton India Company Ltd" },
  { value: "Rajmangal Finance", label: "Rajmangal Finance" },
  { value: "Kajupada Rahiwashi Sangh Coop Credit Society Ltd", label: "Kajupada Rahiwashi Sangh Coop Credit Society Ltd" },
  { value: "Sai Adharsh Multi Co Op Credit Soc Ltd", label: "Sai Adharsh Multi Co Op Credit Soc Ltd" },
  { value: "Gambhir Hire Purchase Ltd", label: "Gambhir Hire Purchase Ltd" },
  { value: "Mayur Properties And Financial S", label: "Mayur Properties And Financial S" },
  { value: "Eesh Finance Co", label: "Eesh Finance Co" },
  { value: "Preet Finance Co. Regd", label: "Preet Finance Co. Regd" },
  { value: "Mithram Combines", label: "Mithram Combines" },
  { value: "Shri Durgamata Credit Soharda Sahkari N", label: "Shri Durgamata Credit Soharda Sahkari N" },
  { value: "Deeplaxmi Co Op Credit Soc Ltd", label: "Deeplaxmi Co Op Credit Soc Ltd" },
  { value: "Sumangal Portfolio Pvt Ltd", label: "Sumangal Portfolio Pvt Ltd" },
  { value: "Shree Dhan Auto Finance", label: "Shree Dhan Auto Finance" },
  { value: "Hotel And Sambadhit Vyavsaik Nagari Sah Pat Sanatha Maryadit", label: "Hotel And Sambadhit Vyavsaik Nagari Sah Pat Sanatha Maryadit" },
  { value: "Nathkrupa Sah Pat Maryadit", label: "Nathkrupa Sah Pat Maryadit" },
  { value: "Pannalal And Company", label: "Pannalal And Company" },
  { value: "Mayur Fincone", label: "Mayur Fincone" },
  { value: "Cochin Shipyard Ltd", label: "Cochin Shipyard Ltd" },
  { value: "Shri Gurudatta Gamin Bigar Sheti Sah Pat Sanstha", label: "Shri Gurudatta Gamin Bigar Sheti Sah Pat Sanstha" },
  { value: "Rajasthan Motor Deoli", label: "Rajasthan Motor Deoli" },
  { value: "Sri Amrutha Sai Auto Finance", label: "Sri Amrutha Sai Auto Finance" },
  { value: "Shri Vishwakarma Gramin Bigarsheti Sahakari Pat Sanstha Ltd", label: "Shri Vishwakarma Gramin Bigarsheti Sahakari Pat Sanstha Ltd" },
  { value: "Sri Vasavi Motor Finance", label: "Sri Vasavi Motor Finance" },
  { value: "Sree Chinthamani Leasing", label: "Sree Chinthamani Leasing" },
  { value: "Insurance Institute Of India", label: "Insurance Institute Of India" },
  { value: "Navya Santhi Auto Finance", label: "Navya Santhi Auto Finance" },
  { value: "Bhatia Trading Co", label: "Bhatia Trading Co" },
  { value: "Shree Mahesh Nagri Sah Patsanstha Mar", label: "Shree Mahesh Nagri Sah Patsanstha Mar" },
  { value: "Bhardwaj Motor Finance And Co", label: "Bhardwaj Motor Finance And Co" },
  { value: "The Halol Mahila Co Op Credit Society Ltd", label: "The Halol Mahila Co Op Credit Society Ltd" },
  { value: "Dhan Shree Laxmi Finance", label: "Dhan Shree Laxmi Finance" },
  { value: "Dadaji Auto Entreprises", label: "Dadaji Auto Entreprises" },
  { value: "Shriram Transport Co Ltd", label: "Shriram Transport Co Ltd" },
  { value: "Akaram Auto Financies", label: "Akaram Auto Financies" },
  { value: "Sri Venkatesa And Co Finance", label: "Sri Venkatesa And Co Finance" },
  { value: "Mirjabati Skus Ltd", label: "Mirjabati Skus Ltd" },
  { value: "Daimler Financial Services Pvt Ltd", label: "Daimler Financial Services Pvt Ltd" },
  { value: "Shivshraddha Coop Credit Society Ltd", label: "Shivshraddha Coop Credit Society Ltd" },
  { value: "Shree Vishweshwar Sahakari Pathpedhi Maryadit", label: "Shree Vishweshwar Sahakari Pathpedhi Maryadit" },
  { value: "Sainikara Vividoddesha Sou Sha Ni", label: "Sainikara Vividoddesha Sou Sha Ni" },
  { value: "South Dum Dum Co Op Society Ltd", label: "South Dum Dum Co Op Society Ltd" },
  { value: "Shri Chatrapati Rajashri Shahu Urban Co Op Bank Ltd", label: "Shri Chatrapati Rajashri Shahu Urban Co Op Bank Ltd" },
  { value: "Pandurang Shashri Athawale Gramin Bigar Sheti Shah Patsanstha Maryadit", label: "Pandurang Shashri Athawale Gramin Bigar Sheti Shah Patsanstha Maryadit" },
  { value: "Sri Siva Jyothi Auto Finance", label: "Sri Siva Jyothi Auto Finance" },
  { value: "Sri Thindalmurugan Auto Finance", label: "Sri Thindalmurugan Auto Finance" },
  { value: "A.A.520,Veerappam Palayam Primary Agricultural Co Op Bank", label: "A.A.520,Veerappam Palayam Primary Agricultural Co Op Bank" },
  { value: "Navrang Finance Co", label: "Navrang Finance Co" },
  { value: "Tata Capital Finance Co Ltd", label: "Tata Capital Finance Co Ltd" },
  { value: "The Memadpura Heerapura Seva Sahkari Mandali Ltd", label: "The Memadpura Heerapura Seva Sahkari Mandali Ltd" },
  { value: "Service Co Op Bank Ltd", label: "Service Co Op Bank Ltd" },
  { value: "Ex Servicemen Financer", label: "Ex Servicemen Financer" },
  { value: "Ms Goodwill Investor", label: "Ms Goodwill Investor" },
  { value: "Anand Finance Co", label: "Anand Finance Co" },
  { value: "Sowntharanayaki Finance", label: "Sowntharanayaki Finance" },
  { value: "Sri Amman Auto Finance", label: "Sri Amman Auto Finance" },
  { value: "Amaravathi Group Auto Finance", label: "Amaravathi Group Auto Finance" },
  { value: "Sewa Sahakari Samiti Maryadit", label: "Sewa Sahakari Samiti Maryadit" },
  { value: "Katlari Kariyana Merchant Sah Sharafi Mandali Ltd", label: "Katlari Kariyana Merchant Sah Sharafi Mandali Ltd" },
  { value: "Devendra Sarda Nagari Sahakari Patsanstha Ltd", label: "Devendra Sarda Nagari Sahakari Patsanstha Ltd" },
  { value: "Lakshmi Venkateswara Auto Financiers", label: "Lakshmi Venkateswara Auto Financiers" },
  { value: "Sri Senthoor Auto Finance", label: "Sri Senthoor Auto Finance" },
  { value: "Sri Narayana Finance", label: "Sri Narayana Finance" },
  { value: "Mudra Finance", label: "Mudra Finance" },
  { value: "Purna Sai Balaji Auto Financiers", label: "Purna Sai Balaji Auto Financiers" },
  { value: "Belgaum Dist Central Co Op Bank", label: "Belgaum Dist Central Co Op Bank" },
  { value: "Shri Veer Pulikeshi Co Op Bank Ltd", label: "Shri Veer Pulikeshi Co Op Bank Ltd" },
  { value: "Shree Saptashrungi Mata Mahila Gramin Biger Sheti Path Marayadit", label: "Shree Saptashrungi Mata Mahila Gramin Biger Sheti Path Marayadit" },
  { value: "Bharatha Credit Co Op Society Ltd", label: "Bharatha Credit Co Op Society Ltd" },
  { value: "Sri Kaasi Lakshmi Financiers", label: "Sri Kaasi Lakshmi Financiers" },
  { value: "Anjan Auto Finance", label: "Anjan Auto Finance" },
  { value: "Chamundeswari Finance", label: "Chamundeswari Finance" },
  { value: "Mahalaxmi Auto And Gen Finance", label: "Mahalaxmi Auto And Gen Finance" },
  { value: "Jiwhala Co Op Credit Society Ltd", label: "Jiwhala Co Op Credit Society Ltd" },
  { value: "Bharat Sahakari Pathpedhi Ltd", label: "Bharat Sahakari Pathpedhi Ltd" },
  { value: "Subh Shrushti Nagari Sahakari Patasanstha Ltd", label: "Subh Shrushti Nagari Sahakari Patasanstha Ltd" },
  { value: "Sai Sewa Co. Op Credit Society Ltd", label: "Sai Sewa Co. Op Credit Society Ltd" },
  { value: "Shree Bhairavnath Co. Op. Credit Society Ltd", label: "Shree Bhairavnath Co. Op. Credit Society Ltd" },
  { value: "Sharad Sahakari Credit Society Ltd.", label: "Sharad Sahakari Credit Society Ltd." },
  { value: "Shivshambho Co. Op. Credit Society Ltd", label: "Shivshambho Co. Op. Credit Society Ltd" },
  { value: "Mulund Vikas Sahakari Pathpedhi Ltd", label: "Mulund Vikas Sahakari Pathpedhi Ltd" },
  { value: "Shree Pooja Jyoti Co. Opp. Credit Society Ltd", label: "Shree Pooja Jyoti Co. Opp. Credit Society Ltd" },
  { value: "Sri Kalaimagal Corparation", label: "Sri Kalaimagal Corparation" },
  { value: "Bardoli Gram Vikas Co Op Credit Society Bank Ltd", label: "Bardoli Gram Vikas Co Op Credit Society Bank Ltd" },
  { value: "Sri Venkata Siva Sai Auto Financiers", label: "Sri Venkata Siva Sai Auto Financiers" },
  { value: "Bardoli Vibhag Gram Vikash Co Op Credit Soc Ltd", label: "Bardoli Vibhag Gram Vikash Co Op Credit Soc Ltd" },
  { value: "Javli Sahakari Patpedhi Maryadit", label: "Javli Sahakari Patpedhi Maryadit" },
  { value: "Shree Bhuvanendra Swamiji Vividhoddesha Souharda Sahakari N", label: "Shree Bhuvanendra Swamiji Vividhoddesha Souharda Sahakari N" },
  { value: "Satguru Leasing And Finance", label: "Satguru Leasing And Finance" },
  { value: "Sri Selva Vinayagar Finance", label: "Sri Selva Vinayagar Finance" },
  { value: "Janshakti Patsanstha Maryadit", label: "Janshakti Patsanstha Maryadit" },
  { value: "Shri Wagjai Co Op Credit Soc Ltd", label: "Shri Wagjai Co Op Credit Soc Ltd" },
  { value: "Jaimataji Co Op Credit Soc Ltd", label: "Jaimataji Co Op Credit Soc Ltd" },
  { value: "Dhanalaxmi Sahakari Patpedhi Ltd", label: "Dhanalaxmi Sahakari Patpedhi Ltd" },
  { value: "Chakravarthy Co Op Credit Society Ltd", label: "Chakravarthy Co Op Credit Society Ltd" },
  { value: "Samarth Raghuveer Sahakari Patasanstha Ltd", label: "Samarth Raghuveer Sahakari Patasanstha Ltd" },
  { value: "Sainik Gramin Bigar Sheti Sah Pat Sanstha", label: "Sainik Gramin Bigar Sheti Sah Pat Sanstha" },
  { value: "Mahesh Nagari Sah Pat Sanstha Maryadit", label: "Mahesh Nagari Sah Pat Sanstha Maryadit" },
  { value: "Begampura Nagrik Dhiran Sahakari Mandli Ltd", label: "Begampura Nagrik Dhiran Sahakari Mandli Ltd" },
  { value: "Ubkg Bank", label: "Ubkg Bank" },
  { value: "Dhosthi Hire Purchase And Loans", label: "Dhosthi Hire Purchase And Loans" },
  { value: "Rani Leasings And Finance Ltd", label: "Rani Leasings And Finance Ltd" },
  { value: "Vishnu Auto Finance", label: "Vishnu Auto Finance" },
  { value: "M V Auto Finance", label: "M V Auto Finance" },
  { value: "Kogta Financial India Ltd", label: "Kogta Financial India Ltd" },
  { value: "Sri Sri Sri Lalitha Sai Auto Financiers", label: "Sri Sri Sri Lalitha Sai Auto Financiers" },
  { value: "Shree Karni Finance", label: "Shree Karni Finance" },
  { value: "The Urban Santram Pur Co Op Bank", label: "The Urban Santram Pur Co Op Bank" },
  { value: "Jimmy Auto Finance", label: "Jimmy Auto Finance" },
  { value: "Shri Beereshwa Co Op Credit Society Ltd", label: "Shri Beereshwa Co Op Credit Society Ltd" },
  { value: "Mahesh Gramin Bigar Sah Pat Marayadit", label: "Mahesh Gramin Bigar Sah Pat Marayadit" },
  { value: "Samaja Seva Sahakari Bank Niyamith", label: "Samaja Seva Sahakari Bank Niyamith" },
  { value: "Shree Gavisiddheshwar Urban Co Op Bank Ltd", label: "Shree Gavisiddheshwar Urban Co Op Bank Ltd" },
  { value: "Ravikiran Co Op Credit Society Ltd", label: "Ravikiran Co Op Credit Society Ltd" },
  { value: "Sri Sakthisivan Enterprises", label: "Sri Sakthisivan Enterprises" },
  { value: "V V R Finance", label: "V V R Finance" },
  { value: "Siri Ganesh Auto Finance", label: "Siri Ganesh Auto Finance" },
  { value: "Kamal Auto Finance", label: "Kamal Auto Finance" },
  { value: "Teja Auto Finance", label: "Teja Auto Finance" },
  { value: "Vardvinayak Co Op Credit Soc Ltd", label: "Vardvinayak Co Op Credit Soc Ltd" },
  { value: "New Krishna Motor Finance Corporation", label: "New Krishna Motor Finance Corporation" },
  { value: "Kalikamata Nagri Sahakari Patsanstha Maryadit", label: "Kalikamata Nagri Sahakari Patsanstha Maryadit" },
  { value: "Maa Ambe Auto Consult", label: "Maa Ambe Auto Consult" },
  { value: "Shri Sairam Finance Co", label: "Shri Sairam Finance Co" },
  { value: "Shree Sahajanand Co Operative Credit Society Ltd", label: "Shree Sahajanand Co Operative Credit Society Ltd" },
  { value: "Premium Finance Private Limited", label: "Premium Finance Private Limited" },
  { value: "Lord Fincap Ltd", label: "Lord Fincap Ltd" },
  { value: "Mekkadampu Service Co Operative Bank Ltd", label: "Mekkadampu Service Co Operative Bank Ltd" },
  { value: "Global Investment", label: "Global Investment" },
  { value: "Shree Ramdas Financial Services", label: "Shree Ramdas Financial Services" },
  { value: "G K Finance", label: "G K Finance" },
  { value: "Shgun Auto Finance", label: "Shgun Auto Finance" },
  { value: "Venkatachala Finance Corporation", label: "Venkatachala Finance Corporation" },
  { value: "Suganem Investments", label: "Suganem Investments" },
  { value: "Shree Balaji Finance", label: "Shree Balaji Finance" },
  { value: "Sanchiti Finance", label: "Sanchiti Finance" },
  { value: "Param Finance", label: "Param Finance" },
  { value: "Sahaj Finance", label: "Sahaj Finance" },
  { value: "Kuwar Finance", label: "Kuwar Finance" },
  { value: "Aditya Finance", label: "Aditya Finance" },
  { value: "Shree Kushal Finance", label: "Shree Kushal Finance" },
  { value: "Sudarshan Finance", label: "Sudarshan Finance" },
  { value: "Vaidhy Finance", label: "Vaidhy Finance" },
  { value: "Goyal Finance", label: "Goyal Finance" },
  { value: "Osho Finance", label: "Osho Finance" },
  { value: "Shruti Finance", label: "Shruti Finance" },
  { value: "Welkin Finance", label: "Welkin Finance" },
  { value: "Jaysingpur Vyapari Nagari Sah Pat Sanstha Jaysingur", label: "Jaysingpur Vyapari Nagari Sah Pat Sanstha Jaysingur" },
  { value: "Chinmay Sahakari Pathpeti Maryadit", label: "Chinmay Sahakari Pathpeti Maryadit" },
  { value: "Nandhu Auto Leaseing", label: "Nandhu Auto Leaseing" },
  { value: "Madhavnath Gar Bigar Sheti Sah Pat Sanstha Marya Pangari", label: "Madhavnath Gar Bigar Sheti Sah Pat Sanstha Marya Pangari" },
  { value: "Khalsa Enterprises", label: "Khalsa Enterprises" },
  { value: "Elahi Savings And Investments Ltd", label: "Elahi Savings And Investments Ltd" },
  { value: "Shree Pardi Juth Seva Sahkari Mandli Ltd", label: "Shree Pardi Juth Seva Sahkari Mandli Ltd" },
  { value: "Harshada Co Op Credit Soc Ltd", label: "Harshada Co Op Credit Soc Ltd" },
  { value: "Guru Tech Bahadur Co Op Credit Ltd", label: "Guru Tech Bahadur Co Op Credit Ltd" },
  { value: "The Akki Alur Urban Co Operative Bank Ltd", label: "The Akki Alur Urban Co Operative Bank Ltd" },
  { value: "Rajabhau Tungar Gramin Bigar She Sat Pat", label: "Rajabhau Tungar Gramin Bigar She Sat Pat" },
  { value: "Sree Raj Finance", label: "Sree Raj Finance" },
  { value: "Burdwan Co Operative Agriculture And Rural Dev.Bank Ltd", label: "Burdwan Co Operative Agriculture And Rural Dev.Bank Ltd" },
  { value: "Keerthi Shree Auto Finances", label: "Keerthi Shree Auto Finances" },
  { value: "Piramal Finance Private Limited", label: "Piramal Finance Private Limited" },
  { value: "Piramal Enterprises Limited", label: "Piramal Enterprises Limited" },
  { value: "Manganga Sahakari Patsanstha Ltd", label: "Manganga Sahakari Patsanstha Ltd" },
  { value: "Tata Capital Serv Ltd", label: "Tata Capital Serv Ltd" },
  { value: "Aa 189, Ingur Primary Agri Co Op Credit Society Ltd", label: "Aa 189, Ingur Primary Agri Co Op Credit Society Ltd" },
  { value: "Dadvi Seva Sahakari Mandali Ltd", label: "Dadvi Seva Sahakari Mandali Ltd" },
  { value: "The Vepar Udhyog Vikas Sahakari Bank Ltd", label: "The Vepar Udhyog Vikas Sahakari Bank Ltd" },
  { value: "Miya Mahamed Moh. Yusuf Fazalwala", label: "Miya Mahamed Moh. Yusuf Fazalwala" },
  { value: "Sri Aanjaneya Auto Finance", label: "Sri Aanjaneya Auto Finance" },
  { value: "Venkata Siva Sai Auto Finance", label: "Venkata Siva Sai Auto Finance" },
  { value: "Colgate Global Business Services Pvt Ltd", label: "Colgate Global Business Services Pvt Ltd" },
  { value: "Sri Saduguru Enterprises", label: "Sri Saduguru Enterprises" },
  { value: "Shree Chakravarthy Co Op Credit Society Ltd", label: "Shree Chakravarthy Co Op Credit Society Ltd" },
  { value: "Self Style Finance", label: "Self Style Finance" },
  { value: "Shivneri Gramin Bigar Sheti Sahakari Pat Sanstha", label: "Shivneri Gramin Bigar Sheti Sahakari Pat Sanstha" },
  { value: "Navi Mengani Juth Seva Sahakari Mandali", label: "Navi Mengani Juth Seva Sahakari Mandali" },
  { value: "Sri Shivayogi Pattina Sahakara Sang Ha Ni", label: "Sri Shivayogi Pattina Sahakara Sang Ha Ni" },
  { value: "Lakshmi Credit Co Op Society Ltd", label: "Lakshmi Credit Co Op Society Ltd" },
  { value: "Shree Pareva Veer Co Operative Credit Society Ltd", label: "Shree Pareva Veer Co Operative Credit Society Ltd" },
  { value: "Maa Ambe Auto", label: "Maa Ambe Auto" },
  { value: "Naminath Enterprise", label: "Naminath Enterprise" },
  { value: "Essel Finance Business Loans Ltd", label: "Essel Finance Business Loans Ltd" },
  { value: "Tera Motors", label: "Tera Motors" },
  { value: "Singhman Finex Pvt Ltd", label: "Singhman Finex Pvt Ltd" },
  { value: "Shilpi Leasing Pvt Ltd", label: "Shilpi Leasing Pvt Ltd" },
  { value: "Bd Credits Ltd", label: "Bd Credits Ltd" },
  { value: "Neminath Enterprises", label: "Neminath Enterprises" },
  { value: "Apna Nagri Co Op Credit Society Ltd", label: "Apna Nagri Co Op Credit Society Ltd" },
  { value: "Laxmibaug Sahakari Pathpedi Maryadit", label: "Laxmibaug Sahakari Pathpedi Maryadit" },
  { value: "Shree Bela Seva Sahkari Mandali Ltd", label: "Shree Bela Seva Sahkari Mandali Ltd" },
  { value: "Shree Mauli Co Op Society Ltd", label: "Shree Mauli Co Op Society Ltd" },
  { value: "Shri Veman Co Op Credit Society Ltd", label: "Shri Veman Co Op Credit Society Ltd" },
  { value: "Jagdamba Auto Finance", label: "Jagdamba Auto Finance" },
  { value: "Shree Associates", label: "Shree Associates" },
  { value: "Sri Basaveshvara Credit Co Op Society Ltd", label: "Sri Basaveshvara Credit Co Op Society Ltd" },
  { value: "Gangothri Souharda Credit Co Op Ltd", label: "Gangothri Souharda Credit Co Op Ltd" },
  { value: "Goutam Auto Finance", label: "Goutam Auto Finance" },
  { value: "Vasavi Auto Financiers", label: "Vasavi Auto Financiers" },
  { value: "Town Co Op Bank Ltd", label: "Town Co Op Bank Ltd" },
  { value: "Shrinath Motors Ahmedabad", label: "Shrinath Motors Ahmedabad" },
  { value: "The Gunjar Seva Sahakari Mandli Ltd", label: "The Gunjar Seva Sahakari Mandli Ltd" },
  { value: "Panchi Seva Sahakari Mandli Ltd", label: "Panchi Seva Sahakari Mandli Ltd" },
  { value: "The Devaliya Seva Sahakari Mandli Ltd", label: "The Devaliya Seva Sahakari Mandli Ltd" },
  { value: "The Malanpur Seva Sahakari Mandli Ltd", label: "The Malanpur Seva Sahakari Mandli Ltd" },
  { value: "Shree Balaji Multipurpose Souhard Sahakari Niyamit", label: "Shree Balaji Multipurpose Souhard Sahakari Niyamit" },
  { value: "Shri Namdev Nag Sah Path Sanstha Maryadit", label: "Shri Namdev Nag Sah Path Sanstha Maryadit" },
  { value: "Imperial Finance", label: "Imperial Finance" },
  { value: "Bonus Developers Pvt Ltd", label: "Bonus Developers Pvt Ltd" },
  { value: "Cholamandalam Fin Co Ltd", label: "Cholamandalam Fin Co Ltd" },
  { value: "L And T Infrastructure Finance Co Ltd", label: "L And T Infrastructure Finance Co Ltd" },
  { value: "Puzhakkal Block Motor Vehicle Owners Multipurpose Co Operative Society Ltd", label: "Puzhakkal Block Motor Vehicle Owners Multipurpose Co Operative Society Ltd" },
  { value: "Magma Cf Services Pvt Ltd", label: "Magma Cf Services Pvt Ltd" },
  { value: "J H Finance", label: "J H Finance" },
  { value: "Bhagyoday Sahakari Patpedhi Maryadit", label: "Bhagyoday Sahakari Patpedhi Maryadit" },
  { value: "India Finance Corporation Of India Ifci Ltd", label: "India Finance Corporation Of India Ifci Ltd" },
  { value: "Auto Hi Link Pvt Ltd", label: "Auto Hi Link Pvt Ltd" },
  { value: "Paschim Maharashtra Shramik Nagari Shakari Patsanstha Ltd", label: "Paschim Maharashtra Shramik Nagari Shakari Patsanstha Ltd" },
  { value: "Venkata Sai Ganesh Auto Finance", label: "Venkata Sai Ganesh Auto Finance" },
  { value: "Deep Finance Corporation", label: "Deep Finance Corporation" },
  { value: "A G Finance And Investment", label: "A G Finance And Investment" },
  { value: "Wakharbhag Nagari Sah Pat Santha Maryadit", label: "Wakharbhag Nagari Sah Pat Santha Maryadit" },
  { value: "Bajrang Investments Pvt Ltd", label: "Bajrang Investments Pvt Ltd" },
  { value: "Sarvada Multipurpose Co Op Soc", label: "Sarvada Multipurpose Co Op Soc" },
  { value: "Rabobank International", label: "Rabobank International" },
  { value: "Kuber Auto Leasing", label: "Kuber Auto Leasing" },
  { value: "Daimler Financial Services India Private Ltd", label: "Daimler Financial Services India Private Ltd" },
  { value: "The Vinayak Sharafi Sahakari Mandali Ltd", label: "The Vinayak Sharafi Sahakari Mandali Ltd" },
  { value: "Sln Investments", label: "Sln Investments" },
  { value: "D A Enterprises", label: "D A Enterprises" },
  { value: "Akme Fintrade India Ltd", label: "Akme Fintrade India Ltd" },
  { value: "The Maharashtriya Aikyavardhak Paraspar Sahakari Mandal Patpedhi Ltd", label: "The Maharashtriya Aikyavardhak Paraspar Sahakari Mandal Patpedhi Ltd" },
  { value: "Saraswat Vidyalaya Staff Co Op Credit Society Ltd", label: "Saraswat Vidyalaya Staff Co Op Credit Society Ltd" },
  { value: "Khushbu Motors", label: "Khushbu Motors" },
  { value: "Shri Malekarani Cr Sou Sah Niy", label: "Shri Malekarani Cr Sou Sah Niy" },
  { value: "Indian Renewable Energy Development Agency Limited", label: "Indian Renewable Energy Development Agency Limited" },
  { value: "Sanmathi Pattina Sahakara Sanga Ni", label: "Sanmathi Pattina Sahakara Sanga Ni" },
  { value: "Malappattam Service Co Operative Bank Ltd", label: "Malappattam Service Co Operative Bank Ltd" },
  { value: "Dhanvikas Co Op Credit Society Ltd", label: "Dhanvikas Co Op Credit Society Ltd" },
  { value: "Delhi Co Operative Housing Finance Corporation Ltd", label: "Delhi Co Operative Housing Finance Corporation Ltd" },
  { value: "Rajmata Nagari Sahakari Pat Sanstha Mar", label: "Rajmata Nagari Sahakari Pat Sanstha Mar" },
  { value: "Sri Kubera Auto Financiers", label: "Sri Kubera Auto Financiers" },
  { value: "Southern Railway", label: "Southern Railway" },
  { value: "Shree Maulidevi Multi Purpose Co Op Soc Ltd", label: "Shree Maulidevi Multi Purpose Co Op Soc Ltd" },
  { value: "Shaw And Shaw Co", label: "Shaw And Shaw Co" },
  { value: "Sushil Enterpriese", label: "Sushil Enterpriese" },
  { value: "Prakash Pstp", label: "Prakash Pstp" },
  { value: "Mauli Urban Co Op Cr Soc Ltd", label: "Mauli Urban Co Op Cr Soc Ltd" },
  { value: "Seva Sahkari Mandali Ltd", label: "Seva Sahkari Mandali Ltd" },
  { value: "Gandhi Financial Solutions", label: "Gandhi Financial Solutions" },
  { value: "Dhanlakshmi Gramin Bigar Sheti Sah Path Santha", label: "Dhanlakshmi Gramin Bigar Sheti Sah Path Santha" },
  { value: "Ge Money Financial Services Pvt Ltd", label: "Ge Money Financial Services Pvt Ltd" },
  { value: "The Idalhonda Prathamik Krishi Pattin Saha Sangh Ltd", label: "The Idalhonda Prathamik Krishi Pattin Saha Sangh Ltd" },
  { value: "Sri Ambrini Vidhyesha Sou Cr Co Op Ltd", label: "Sri Ambrini Vidhyesha Sou Cr Co Op Ltd" },
  { value: "Sneha Enterprises And Auto Finance", label: "Sneha Enterprises And Auto Finance" },
  { value: "The Ksc Apex Bank Ltd", label: "The Ksc Apex Bank Ltd" },
  { value: "The Mundgod Urban Co Op Cerdit Society Ltd", label: "The Mundgod Urban Co Op Cerdit Society Ltd" },
  { value: "The Chitroda Group Seva Sahakari Mandali Ltd", label: "The Chitroda Group Seva Sahakari Mandali Ltd" },
  { value: "Mauli Multipurpose Co Op Credit Ltd", label: "Mauli Multipurpose Co Op Credit Ltd" },
  { value: "Chhtrapati Nashik Jilha Nagari Sahkari Pat Mary", label: "Chhtrapati Nashik Jilha Nagari Sahkari Pat Mary" },
  { value: "The Loliya Seva Sahakari Mandali Ltd", label: "The Loliya Seva Sahakari Mandali Ltd" },
  { value: "Gujarat Narmada Valley Fertilizers And Chemicals Ltd", label: "Gujarat Narmada Valley Fertilizers And Chemicals Ltd" },
  { value: "Nagpur Mahila Vikas Credit Co Op So", label: "Nagpur Mahila Vikas Credit Co Op So" },
  { value: "Sri Pathmavathi Finance", label: "Sri Pathmavathi Finance" },
  { value: "Himachal Pradesh Gramin Bank", label: "Himachal Pradesh Gramin Bank" },
  { value: "Rani Leasings And Finance Pvt Ltd", label: "Rani Leasings And Finance Pvt Ltd" },
  { value: "Bhartiya Fincom Pvt Ltd", label: "Bhartiya Fincom Pvt Ltd" },
  { value: "Yadrav Co Op Bank Ltd", label: "Yadrav Co Op Bank Ltd" },
  { value: "Bholebaba Gramin Bigar Sheti Sahakari Path Satha", label: "Bholebaba Gramin Bigar Sheti Sahakari Path Satha" },
  { value: "Mahalaxmi Multi Purp Co Op Soc Ltd", label: "Mahalaxmi Multi Purp Co Op Soc Ltd" },
  { value: "Varvand Vikas Seva Sah Society", label: "Varvand Vikas Seva Sah Society" },
  { value: "Shri Pardi Juth Seva Sahkari Mandli Ltd", label: "Shri Pardi Juth Seva Sahkari Mandli Ltd" },
  { value: "Leela Krishna Auto Finanace", label: "Leela Krishna Auto Finanace" },
  { value: "Shri Kamaljadevi Gramin Biger Sheti Sah Pat San Ma", label: "Shri Kamaljadevi Gramin Biger Sheti Sah Pat San Ma" },
  { value: "Bhadvan Sahakari Patpedhi Ltd", label: "Bhadvan Sahakari Patpedhi Ltd" },
  { value: "The Hccs Urban Co Op Credit Society Ltd", label: "The Hccs Urban Co Op Credit Society Ltd" },
  { value: "Naunidh Enterprises", label: "Naunidh Enterprises" },
  { value: "Jodhpur Nagrik Sahakari Bank Ltd", label: "Jodhpur Nagrik Sahakari Bank Ltd" },
  { value: "Bhole Shanker Motor Finance Co", label: "Bhole Shanker Motor Finance Co" },
  { value: "C.D. Co Op Credit Society Ltd", label: "C.D. Co Op Credit Society Ltd" },
  { value: "Kaveri Auto Finance", label: "Kaveri Auto Finance" },
  { value: "Shri Ram Motor And General Finance", label: "Shri Ram Motor And General Finance" },
  { value: "Vishal Junnar Patsanstha", label: "Vishal Junnar Patsanstha" },
  { value: "The Makroti Agriculture Service Society Ltd", label: "The Makroti Agriculture Service Society Ltd" },
  { value: "Atwal Finance Company", label: "Atwal Finance Company" },
  { value: "Reliance Captial Finance", label: "Reliance Captial Finance" },
  { value: "The Sunvala Seva Shakari Mandli Ltd", label: "The Sunvala Seva Shakari Mandli Ltd" },
  { value: "Madavi Kinara Patsanstha", label: "Madavi Kinara Patsanstha" },
  { value: "Nigot Wadi Gramin Big Sheti Sah Patsanstha", label: "Nigot Wadi Gramin Big Sheti Sah Patsanstha" },
  { value: "Dharani Auto Finance", label: "Dharani Auto Finance" },
  { value: "The Vasna Seva Sahkari Mandali Ltd", label: "The Vasna Seva Sahkari Mandali Ltd" },
  { value: "The Simej Seva Shakari Mandli Ltd", label: "The Simej Seva Shakari Mandli Ltd" },
  { value: "Milap Finance Corp", label: "Milap Finance Corp" },
  { value: "New Satara Zilha Nagrik Sah Pat Ltd", label: "New Satara Zilha Nagrik Sah Pat Ltd" },
  { value: "Shree Sanganva Seva Sahakari Mandali", label: "Shree Sanganva Seva Sahakari Mandali" },
  { value: "Happy Auto Finance", label: "Happy Auto Finance" },
  { value: "Shri Shetrapaleshwar Sahakari Pathpedi Maryadit", label: "Shri Shetrapaleshwar Sahakari Pathpedi Maryadit" },
  { value: "Shri Narsinha Gramin Biger Sheti Sah Pat San Marya", label: "Shri Narsinha Gramin Biger Sheti Sah Pat San Marya" },
  { value: "Shri Datta Krupa Nagari Sahakari Pat Sanstha Marya", label: "Shri Datta Krupa Nagari Sahakari Pat Sanstha Marya" },
  { value: "Harminder Finance Co", label: "Harminder Finance Co" },
  { value: "Magam Fincrop Ltd", label: "Magam Fincrop Ltd" },
  { value: "Saffron Financial Services Pvt Ltd", label: "Saffron Financial Services Pvt Ltd" },
  { value: "Srishti Enterprises", label: "Srishti Enterprises" },
  { value: "Sajan Traders", label: "Sajan Traders" },
  { value: "Mr Group Of Company", label: "Mr Group Of Company" },
  { value: "Shri Laxmidevi Urban Credit Souhard Sah Nyt", label: "Shri Laxmidevi Urban Credit Souhard Sah Nyt" },
  { value: "Sri Selva Ganapathi Finance", label: "Sri Selva Ganapathi Finance" },
  { value: "Bhartiya Mahila Bank Ltd", label: "Bhartiya Mahila Bank Ltd" },
  { value: "New Shraddha Co Op Credit Society Ltd", label: "New Shraddha Co Op Credit Society Ltd" },
  { value: "The Laxminarayan Sahakari Sarafi Mandali", label: "The Laxminarayan Sahakari Sarafi Mandali" },
  { value: "Malganga Gramin Big Sheti Shakari Patsanstha Maryadit", label: "Malganga Gramin Big Sheti Shakari Patsanstha Maryadit" },
  { value: "Pnb Housing Finance Limited", label: "Pnb Housing Finance Limited" },
  { value: "Aditya Birla Housing Finance Ltd", label: "Aditya Birla Housing Finance Ltd" },
  { value: "The Patil Vividhoddeshagala Souhard Sah Nyt", label: "The Patil Vividhoddeshagala Souhard Sah Nyt" },
  { value: "Dr Babasaheb Ambedkar Sahakari Bank Ltd", label: "Dr Babasaheb Ambedkar Sahakari Bank Ltd" },
  { value: "Salamath Credit Coop Ltd", label: "Salamath Credit Coop Ltd" },
  { value: "Chintamanrao Mormare Gramin Biger Sheti Sah Pat Sa", label: "Chintamanrao Mormare Gramin Biger Sheti Sah Pat Sa" },
  { value: "Threestar Enterprises", label: "Threestar Enterprises" },
  { value: "Saideep Co Op Cr Soc Ltd", label: "Saideep Co Op Cr Soc Ltd" },
  { value: "Sundaram Finance Pvt Ltd", label: "Sundaram Finance Pvt Ltd" },
  { value: "The Cd Co Operative Credit Society Ltd", label: "The Cd Co Operative Credit Society Ltd" },
  { value: "Aathi Auto Finance", label: "Aathi Auto Finance" },
  { value: "Shivneri Prathamik Krushi Pattin Sahakari Sangh Ltd", label: "Shivneri Prathamik Krushi Pattin Sahakari Sangh Ltd" },
  { value: "India Bulls Housing Finance Ltd", label: "India Bulls Housing Finance Ltd" },
  { value: "Jain Auto Fin Pvt Ltd", label: "Jain Auto Fin Pvt Ltd" },
  { value: "Aadarniya P D Patil Saheb Sahakari Bank Ltd", label: "Aadarniya P D Patil Saheb Sahakari Bank Ltd" },
  { value: "Dr Manibhai Desai Gramin Biger Sheti Sah Pat San M", label: "Dr Manibhai Desai Gramin Biger Sheti Sah Pat San M" },
  { value: "Jai Hind Irban Co Op Bank Ltd", label: "Jai Hind Irban Co Op Bank Ltd" },
  { value: "Neelakanta Auto Finance", label: "Neelakanta Auto Finance" },
  { value: "Vishwabharti Sahakari Patsantha Ltd", label: "Vishwabharti Sahakari Patsantha Ltd" },
  { value: "Naigaon Shramik Sahakari Pat Ltd", label: "Naigaon Shramik Sahakari Pat Ltd" },
  { value: "Pushpanjali Co Op Credit Soc Ltd", label: "Pushpanjali Co Op Credit Soc Ltd" },
  { value: "Karnataka Kalal Co Operative Credit Society Limited", label: "Karnataka Kalal Co Operative Credit Society Limited" },
  { value: "Bilagi Pattana Sahakari Bank Niyamit", label: "Bilagi Pattana Sahakari Bank Niyamit" },
  { value: "Au Housing Finance Ltd", label: "Au Housing Finance Ltd" },
  { value: "Shri D J Patil Co Operative Bank", label: "Shri D J Patil Co Operative Bank" },
  { value: "Veershaiv Gramin Bigar Sheti Sahakari Pat Sanstha Ltd", label: "Veershaiv Gramin Bigar Sheti Sahakari Pat Sanstha Ltd" },
  { value: "Shri Mauli Devi Multi Purpose Co Op Society Ltd", label: "Shri Mauli Devi Multi Purpose Co Op Society Ltd" },
  { value: "Rajlaxmi Sah Bank Ltd", label: "Rajlaxmi Sah Bank Ltd" },
  { value: "The Parra Verla Canca Urban Co Op Credit Society Ltd", label: "The Parra Verla Canca Urban Co Op Credit Society Ltd" },
  { value: "Ajmani And Co", label: "Ajmani And Co" },
  { value: "Shree Bhavnatha Investment", label: "Shree Bhavnatha Investment" },
  { value: "Haveli Nagari Sah Patsanstha Mary", label: "Haveli Nagari Sah Patsanstha Mary" },
  { value: "Nissan Renault Financial Services India Pvt Ltd", label: "Nissan Renault Financial Services India Pvt Ltd" },
  { value: "Dakshina Finance", label: "Dakshina Finance" },
  { value: "Shree Kalbhairav Nagrik Sahakari Patpedhi Ltd", label: "Shree Kalbhairav Nagrik Sahakari Patpedhi Ltd" },
  { value: "Cholamandalam Investment Fin Ltd", label: "Cholamandalam Investment Fin Ltd" },
  { value: "Kalaivani Auto Finance", label: "Kalaivani Auto Finance" },
  { value: "Way Servey Finance", label: "Way Servey Finance" },
  { value: "Bantwal Catholic Credit Co Op Soceity Ltd", label: "Bantwal Catholic Credit Co Op Soceity Ltd" },
  { value: "Bhargavi Finance And Auto Financiers", label: "Bhargavi Finance And Auto Financiers" },
  { value: "Ashok Agencies Ltd", label: "Ashok Agencies Ltd" },
  { value: "Krishna Koyana Sah Pat Sanstha Maryadit", label: "Krishna Koyana Sah Pat Sanstha Maryadit" },
  { value: "Aris Capital Private Limited", label: "Aris Capital Private Limited" },
  { value: "Cholamandalam Finance", label: "Cholamandalam Finance" },
  { value: "Akash Finance Ltd", label: "Akash Finance Ltd" },
  { value: "West Bengal Infrastructure Development Finance Corporation Ltd", label: "West Bengal Infrastructure Development Finance Corporation Ltd" },
  { value: "J K M M Auto Consultancy P Ltd", label: "J K M M Auto Consultancy P Ltd" },
  { value: "Sai Point Finance Corporation Ltd", label: "Sai Point Finance Corporation Ltd" },
  { value: "P M Rajkumar And Sons", label: "P M Rajkumar And Sons" },
  { value: "Sri Bhagawan Financiers", label: "Sri Bhagawan Financiers" },
  { value: "Sri Lakshmi Sai Krishna Auto Finance", label: "Sri Lakshmi Sai Krishna Auto Finance" },
  { value: "Kim Infrastructure And Developer Ltd", label: "Kim Infrastructure And Developer Ltd" },
  { value: "Janata Gramin Bigar Sheti Path Sanstha", label: "Janata Gramin Bigar Sheti Path Sanstha" },
  { value: "M H Finance Corp Regd", label: "M H Finance Corp Regd" },
  { value: "Mas Rural Housing And Mortgage Finance Ltd", label: "Mas Rural Housing And Mortgage Finance Ltd" },
  { value: "Shri Sai Vividedeshgal Sou Sah Nyt", label: "Shri Sai Vividedeshgal Sou Sah Nyt" },
  { value: "Punjab Mercantile Credit Sah Ltd", label: "Punjab Mercantile Credit Sah Ltd" },
  { value: "Fosroc Chemicals India Pvt Ltd", label: "Fosroc Chemicals India Pvt Ltd" },
  { value: "Aarsh Bachat Dhiran Supplay Sahakari Mandli Limited", label: "Aarsh Bachat Dhiran Supplay Sahakari Mandli Limited" },
  { value: "Padmavati Tradingcompany", label: "Padmavati Tradingcompany" },
  { value: "Belekari Seva Sahakari Sangha Ltd", label: "Belekari Seva Sahakari Sangha Ltd" },
  { value: "Manappuram Finance Ltd", label: "Manappuram Finance Ltd" },
  { value: "Rahul Motors Finvest", label: "Rahul Motors Finvest" },
  { value: "Ajay Financial Associates", label: "Ajay Financial Associates" },
  { value: "Shreeji Co Op Credit Society Ltd", label: "Shreeji Co Op Credit Society Ltd" },
  { value: "Uttrakhand Gramin Bank", label: "Uttrakhand Gramin Bank" },
  { value: "Sree Ranganatha Credit Coop So Ltd", label: "Sree Ranganatha Credit Coop So Ltd" },
  { value: "Veeramanikanta Financiers", label: "Veeramanikanta Financiers" },
  { value: "Ctbc Bank", label: "Ctbc Bank" },
  { value: "Tumkur Basaveshwara Credit Co Op So Ltd", label: "Tumkur Basaveshwara Credit Co Op So Ltd" },
  { value: "Kalpa Vriksh Finance", label: "Kalpa Vriksh Finance" },
  { value: "Rajasthan Marudhara Gramin Bank", label: "Rajasthan Marudhara Gramin Bank" },
  { value: "New Keerthi Enter Prises And Auto Finance", label: "New Keerthi Enter Prises And Auto Finance" },
  { value: "Bhagyalaxmi Sahakari Bank", label: "Bhagyalaxmi Sahakari Bank" },
  { value: "Devgiri Nagri Sahakari Bank Ltd", label: "Devgiri Nagri Sahakari Bank Ltd" },
  { value: "Poornwad Nagrik Sahakari Bank Ltd", label: "Poornwad Nagrik Sahakari Bank Ltd" },
  { value: "The Shyamrao Vitthal Sahakari Bank", label: "The Shyamrao Vitthal Sahakari Bank" },
  { value: "The Vaidyanath Urban Co Op Bank Ltd", label: "The Vaidyanath Urban Co Op Bank Ltd" },
  { value: "Kallada General Finance Pvt Ltd", label: "Kallada General Finance Pvt Ltd" },
  { value: "Shri Mahalaxmi Urban Co Op Cr Bank Ltd", label: "Shri Mahalaxmi Urban Co Op Cr Bank Ltd" },
  { value: "Shantadurga Multi Purpose Souhard Sahakari Niyamit", label: "Shantadurga Multi Purpose Souhard Sahakari Niyamit" },
  { value: "Varadvinayak Co Op Credit Soc Ltd", label: "Varadvinayak Co Op Credit Soc Ltd" },
  { value: "Mpmc Leasing And Finance Ltd", label: "Mpmc Leasing And Finance Ltd" },
  { value: "Jadid C.A.F Ltd", label: "Jadid C.A.F Ltd" },
  { value: "Hdb Finance Services Ltd", label: "Hdb Finance Services Ltd" },
  { value: "Shree Vaibhavlaxmi Finance And Investment Pvt Ltd", label: "Shree Vaibhavlaxmi Finance And Investment Pvt Ltd" },
  { value: "Nadaf Nagari Sah Patsanstha Mary", label: "Nadaf Nagari Sah Patsanstha Mary" },
  { value: "Pragathi Krishna Gramin Bank", label: "Pragathi Krishna Gramin Bank" },
  { value: "Attar Finance Corp", label: "Attar Finance Corp" },
  { value: "Hara Vividoddesha Souharda Sahakari Niyamitha", label: "Hara Vividoddesha Souharda Sahakari Niyamitha" },
  { value: "Dmi Housing Finance Private Limited", label: "Dmi Housing Finance Private Limited" },
  { value: "Chif Executive Officers Sindhanur Pattina Souharda Sahakari Ni", label: "Chif Executive Officers Sindhanur Pattina Souharda Sahakari Ni" },
  { value: "G Venkata Ratnam Automobile Financier", label: "G Venkata Ratnam Automobile Financier" },
  { value: "Demand Investo", label: "Demand Investo" },
  { value: "Shree Rangdas Swami Co Op Credit Mumbai", label: "Shree Rangdas Swami Co Op Credit Mumbai" },
  { value: "Pandoh Traders", label: "Pandoh Traders" },
  { value: "M D Trading Co", label: "M D Trading Co" },
  { value: "Shri Dnyaneshwar Sha Pat Maryadit Nimblak", label: "Shri Dnyaneshwar Sha Pat Maryadit Nimblak" },
  { value: "Swastik Finance Company", label: "Swastik Finance Company" },
  { value: "Jatinder Finance Pvt Ltd", label: "Jatinder Finance Pvt Ltd" },
  { value: "Metro Finance Corporation", label: "Metro Finance Corporation" },
  { value: "Sai Priyanka Financier", label: "Sai Priyanka Financier" },
  { value: "Mahendra Motors", label: "Mahendra Motors" },
  { value: "Avani Lease Finance Pvt Ltd", label: "Avani Lease Finance Pvt Ltd" },
  { value: "Roopa Sona Investments And Trading Company Pvt Ltd", label: "Roopa Sona Investments And Trading Company Pvt Ltd" },
  { value: "U P Bhoomi Vikas Bank", label: "U P Bhoomi Vikas Bank" },
  { value: "New Delhi Finance And Investment", label: "New Delhi Finance And Investment" },
  { value: "Sarswat Bank", label: "Sarswat Bank" },
  { value: "Shantadurga Multi Purpose Souhard Sahakari Niyamit", label: "Shantadurga Multi Purpose Souhard Sahakari Niyamit" },
  { value: "Fortune Integrated Assets Fin Ltd", label: "Fortune Integrated Assets Fin Ltd" },
  { value: "Business Bank", label: "Business Bank" },
  { value: "Chhattisgarh Rajya Gramin Bank", label: "Chhattisgarh Rajya Gramin Bank" },
  { value: "Shri Ram Fin Cor Pvt Ltd", label: "Shri Ram Fin Cor Pvt Ltd" },
  { value: "Shreenath Gramin Bigersheti Sah Patsanstha Maryadit", label: "Shreenath Gramin Bigersheti Sah Patsanstha Maryadit" },
  { value: "Reliance Commercial Finance", label: "Reliance Commercial Finance" },
  { value: "The H C C S Urban Co Op Credit Society Ltd", label: "The H C C S Urban Co Op Credit Society Ltd" },
  { value: "Ess Kay Auto Finance Co", label: "Ess Kay Auto Finance Co" },
  { value: "Kamraj Co Op Credit Society Ltd", label: "Kamraj Co Op Credit Society Ltd" },
  { value: "Electronica Finance Ltd", label: "Electronica Finance Ltd" },
  { value: "Sri Parshwanatha Sa Bk Ni", label: "Sri Parshwanatha Sa Bk Ni" },
  { value: "Hero Fin Corp", label: "Hero Fin Corp" },
  { value: "The Mahabaleshwar Urban Co Op Bank Ltd", label: "The Mahabaleshwar Urban Co Op Bank Ltd" },
  { value: "Sri Lakshmi Prasanna Auto Finance", label: "Sri Lakshmi Prasanna Auto Finance" },
  { value: "The Belagaum Taluka Rural Industries Co Op Credit Soc Ltd", label: "The Belagaum Taluka Rural Industries Co Op Credit Soc Ltd" },
  { value: "Pragati Krishna Gramin Bank", label: "Pragati Krishna Gramin Bank" },
  { value: "Maharastra Gramin Bank", label: "Maharastra Gramin Bank" },
  { value: "Kashmir Mercantile Bank", label: "Kashmir Mercantile Bank" },
  { value: "International Asset Reconstruction Company Pvt Ltd", label: "International Asset Reconstruction Company Pvt Ltd" },
  { value: "Dgm Administration Mrpl", label: "Dgm Administration Mrpl" },
  { value: "The Bhadana Seva Sahakari Mandali Ltd", label: "The Bhadana Seva Sahakari Mandali Ltd" },
  { value: "Pravinchandra Keshrichand And Co", label: "Pravinchandra Keshrichand And Co" },
  { value: "Madni Co Op Credit Soc Ltd", label: "Madni Co Op Credit Soc Ltd" },
  { value: "Kumbhoj Urban Co Op Credit Society Ltd", label: "Kumbhoj Urban Co Op Credit Society Ltd" },
  { value: "Rajhans Motor Investment Co", label: "Rajhans Motor Investment Co" },
  { value: "Jaibharat Nagari Sahakari Patsanstha", label: "Jaibharat Nagari Sahakari Patsanstha" },
  { value: "V K Investment Services Pvt Ltd", label: "V K Investment Services Pvt Ltd" },
  { value: "Konda China Vara Lakshmi Automobile Financier", label: "Konda China Vara Lakshmi Automobile Financier" },
  { value: "Aditya Birla Housing Finance Limited", label: "Aditya Birla Housing Finance Limited" },
  { value: "Reno Finance", label: "Reno Finance" },
  { value: "Sharun Trading Corp", label: "Sharun Trading Corp" },
  { value: "Vishaldeep Sahakari Patsanstha Maryadit", label: "Vishaldeep Sahakari Patsanstha Maryadit" },
  { value: "Bharatiya Fincom Pvt Ltd", label: "Bharatiya Fincom Pvt Ltd" },
  { value: "Manmohan Auto Financial Services", label: "Manmohan Auto Financial Services" },
  { value: "Keshav Fincap Private Ltd", label: "Keshav Fincap Private Ltd" },
  { value: "The Janata Co Op Bank Ltd", label: "The Janata Co Op Bank Ltd" },
  { value: "Sree Kanaka Patina Sahaka Sanga", label: "Sree Kanaka Patina Sahaka Sanga" },
  { value: "Gda Trusteeship Ltd", label: "Gda Trusteeship Ltd" },
  { value: "Shreenath Co Op Bank Ltd", label: "Shreenath Co Op Bank Ltd" },
  { value: "Jsw Steel Employees Co Op", label: "Jsw Steel Employees Co Op" },
  { value: "Purandar Nagari Sahakari Patsanstha", label: "Purandar Nagari Sahakari Patsanstha" },
  { value: "National Investment", label: "National Investment" },
  { value: "Tam Auto Finance", label: "Tam Auto Finance" },
  { value: "K Z Finance", label: "K Z Finance" },
  { value: "Daimler Finances Services Indian Pvt Ltd", label: "Daimler Finances Services Indian Pvt Ltd" },
  { value: "Almora Co Bank", label: "Almora Co Bank" },
  { value: "Shri Veeracharya Minorities Souh Multipurpose Sah Niy Ltd", label: "Shri Veeracharya Minorities Souh Multipurpose Sah Niy Ltd" },
  { value: "Sri Sai Ganesh Auto Finance", label: "Sri Sai Ganesh Auto Finance" },
  { value: "Sri Hanuman Financiers", label: "Sri Hanuman Financiers" },
  { value: "Bharathu Investment And Fin I Ltd", label: "Bharathu Investment And Fin I Ltd" },
  { value: "Vasundhara Mahila Nagari Sahakari Bank Ltd", label: "Vasundhara Mahila Nagari Sahakari Bank Ltd" },
  { value: "Raghuvanshi Co Op Cr Soc Ltd", label: "Raghuvanshi Co Op Cr Soc Ltd" },
  { value: "Shri Arihant Credit Souhard Sah Ltd", label: "Shri Arihant Credit Souhard Sah Ltd" },
  { value: "Pec Ltd A Premier Indian International Trading Company", label: "Pec Ltd A Premier Indian International Trading Company" },
  { value: "Dakkan Co Op Credit Soceity Ltd", label: "Dakkan Co Op Credit Soceity Ltd" },
  { value: "Raigad Co Operative Soc Ltd", label: "Raigad Co Operative Soc Ltd" },
  { value: "Shree Gurudatta Multi State Co Op", label: "Shree Gurudatta Multi State Co Op" },
  { value: "Uttam Chand Chordia Huf", label: "Uttam Chand Chordia Huf" },
  { value: "Dhanguru Nanak Enterprises", label: "Dhanguru Nanak Enterprises" },
  { value: "Au Financers India Ltd", label: "Au Financers India Ltd" },
  { value: "Purna Anjaneya Finance", label: "Purna Anjaneya Finance" },
  { value: "Madhu Automobile Financiers", label: "Madhu Automobile Financiers" },
  { value: "Vijaya Lakshmi Finance", label: "Vijaya Lakshmi Finance" },
  { value: "Reliance Home Finance Ltd", label: "Reliance Home Finance Ltd" },
  { value: "The Bantai Seva Sahakari Mandli Ltd", label: "The Bantai Seva Sahakari Mandli Ltd" },
  { value: "Marathavaibahv Sahakari Patpedhi Ltd", label: "Marathavaibahv Sahakari Patpedhi Ltd" },
  { value: "The New Kujad Seva Sahkari Mandli Ltd", label: "The New Kujad Seva Sahkari Mandli Ltd" },
  { value: "Shramik Ekta Nagari Sahakari Patsanstha Maryadit", label: "Shramik Ekta Nagari Sahakari Patsanstha Maryadit" },
  { value: "Ms Kanrattan Investment And Finance Ltd", label: "Ms Kanrattan Investment And Finance Ltd" },
  { value: "Devi Auto Finance", label: "Devi Auto Finance" },
  { value: "Cholamandalam Investment", label: "Cholamandalam Investment" },
  { value: "Sai Ganapathi Auto Finance", label: "Sai Ganapathi Auto Finance" },
  { value: "The Bajwa Arbun Co Op Credit Sahkari Mandali Limited", label: "The Bajwa Arbun Co Op Credit Sahkari Mandali Limited" },
  { value: "Nava Karnataka Souharda Credit Co", label: "Nava Karnataka Souharda Credit Co" },
  { value: "Sri Vigneshvaraa Finance", label: "Sri Vigneshvaraa Finance" },
  { value: "Akme Fincon Ltd", label: "Akme Fincon Ltd" },
  { value: "Mah And Mah Financial Ltd", label: "Mah And Mah Financial Ltd" },
  { value: "Easy Financial Services", label: "Easy Financial Services" },
  { value: "Rajat Investment", label: "Rajat Investment" },
  { value: "Chaitanya Ur Co Op Cre Soc Ltd", label: "Chaitanya Ur Co Op Cre Soc Ltd" },
  { value: "Marathavaibhav Sahakari Patpedi Ltd", label: "Marathavaibhav Sahakari Patpedi Ltd" },
  { value: "Siddaganga Sri Vividoddesha Souharda Sahakari Ni", label: "Siddaganga Sri Vividoddesha Souharda Sahakari Ni" },
  { value: "Golden India Leasing And Finance Ltd", label: "Golden India Leasing And Finance Ltd" },
  { value: "Maa Shardha Co Op Credit Society Ltd", label: "Maa Shardha Co Op Credit Society Ltd" },
  { value: "Shree Renuka Urban Coop Credit Society Ltd", label: "Shree Renuka Urban Coop Credit Society Ltd" },
  { value: "Ssk Pattina Sahakara Sangha Niyamit", label: "Ssk Pattina Sahakara Sangha Niyamit" },
  { value: "Paravur Taluk Co Op Agricultural And Rural Development Bank", label: "Paravur Taluk Co Op Agricultural And Rural Development Bank" },
  { value: "Lasalgaon Bigarsheti Sah Pat Mar", label: "Lasalgaon Bigarsheti Sah Pat Mar" },
  { value: "Self Style Fin Corp", label: "Self Style Fin Corp" },
  { value: "The Ghataprabha Urban Co Op Credit Society Ltd", label: "The Ghataprabha Urban Co Op Credit Society Ltd" },
  { value: "Dakshinya Auto Finance", label: "Dakshinya Auto Finance" },
  { value: "Sri Raghavendra Finance", label: "Sri Raghavendra Finance" },
  { value: "Sri Kesari Corporation", label: "Sri Kesari Corporation" },
  { value: "Sri Sugan Investment", label: "Sri Sugan Investment" },
  { value: "Bhagyodya Nag Sah Path Sanstha Mary", label: "Bhagyodya Nag Sah Path Sanstha Mary" },
  { value: "Shivshanti Co Op Credit Society", label: "Shivshanti Co Op Credit Society" },
  { value: "Lease With Apra Cabs India Private Limited", label: "Lease With Apra Cabs India Private Limited" },
  { value: "Kushal Nagri Sahakari Patsantha Limited", label: "Kushal Nagri Sahakari Patsantha Limited" },
  { value: "Manager Akshambara Pattina Souharda Sahakari Ni", label: "Manager Akshambara Pattina Souharda Sahakari Ni" },
  { value: "Swaran Finance Corp", label: "Swaran Finance Corp" },
  { value: "Vardayini Co Op Credit Soc Ltd", label: "Vardayini Co Op Credit Soc Ltd" },
  { value: "Pnb Investment Services Limited", label: "Pnb Investment Services Limited" },
  { value: "Lessor Kotak Mahindra Prime Ltd", label: "Lessor Kotak Mahindra Prime Ltd" },
  { value: "Kanur Pathar Multistate Co Operative Society Ltd", label: "Kanur Pathar Multistate Co Operative Society Ltd" },
  { value: "The Amritsar Central Co Op Bank Ltd", label: "The Amritsar Central Co Op Bank Ltd" },
  { value: "Rameshwaram Auto Finance", label: "Rameshwaram Auto Finance" },
  { value: "Shivner Sahakari Patsantha Maryadit", label: "Shivner Sahakari Patsantha Maryadit" },
  { value: "Navbharat Co.Op.Credit Society Ltd", label: "Navbharat Co.Op.Credit Society Ltd" },
  { value: "Telangana Grameena Bank", label: "Telangana Grameena Bank" },
  { value: "Housing Development Finance Co Operative Bank Ltd", label: "Housing Development Finance Co Operative Bank Ltd" },
  { value: "Shree Gurunanak Co Op Cre Soc Ltd", label: "Shree Gurunanak Co Op Cre Soc Ltd" },
  { value: "Jharkhand State Co Operative Bank Ltd", label: "Jharkhand State Co Operative Bank Ltd" },
  { value: "Venus Star Investment Pvt Ltd", label: "Venus Star Investment Pvt Ltd" },
  { value: "Edelweiss Retail Finance Ltd", label: "Edelweiss Retail Finance Ltd" },
  { value: "Nadakuthazhe Service Co Operative Bank Ltd", label: "Nadakuthazhe Service Co Operative Bank Ltd" },
  { value: "B Vimal Chand Dugar", label: "B Vimal Chand Dugar" },
  { value: "Jansevak Sahakari Patpedhi Ltd", label: "Jansevak Sahakari Patpedhi Ltd" },
  { value: "Sunita Finlease Ltd", label: "Sunita Finlease Ltd" },
  { value: "Bhagodaya Sah Pathpedi", label: "Bhagodaya Sah Pathpedi" },
  { value: "New Delhi Hire Purchase Corp Regd", label: "New Delhi Hire Purchase Corp Regd" },
  { value: "Sri Rama Auto Finance", label: "Sri Rama Auto Finance" },
  { value: "Sri Anjana Auto Finance", label: "Sri Anjana Auto Finance" },
  { value: "Shri Mahatma Basaveshwar Cre Sou Sah Nyt Nipani", label: "Shri Mahatma Basaveshwar Cre Sou Sah Nyt Nipani" },
  { value: "Rsd Finance Company", label: "Rsd Finance Company" },
  { value: "Rameswari Auto Finance", label: "Rameswari Auto Finance" },
  { value: "Guru Inder Financier", label: "Guru Inder Financier" },
  { value: "Ganeshprasad Sahakari Pathpadi Marayadit", label: "Ganeshprasad Sahakari Pathpadi Marayadit" },
  { value: "Renault Finance", label: "Renault Finance" },
  { value: "Vishal Sahakari Path Sanstha", label: "Vishal Sahakari Path Sanstha" },
  { value: "Kashyap Finance", label: "Kashyap Finance" },
  { value: "Cholamandalam Invst And Fin Ser", label: "Cholamandalam Invst And Fin Ser" },
  { value: "Jivhala Co Op Cr Soc Ltd", label: "Jivhala Co Op Cr Soc Ltd" },
  { value: "St Helen S Nominees India Private Limited", label: "St Helen S Nominees India Private Limited" },
  { value: "Panchdevi Auto Fiancance", label: "Panchdevi Auto Fiancance" },
  { value: "Suzuki Motorcycle India Pvt Ltd", label: "Suzuki Motorcycle India Pvt Ltd" },
  { value: "Mahindra And Mahindra Financial Service Ltd", label: "Mahindra And Mahindra Financial Service Ltd" },
  { value: "Metro Motors Enter Prises", label: "Metro Motors Enter Prises" },
  { value: "Hinduja Leyland Finance Co Ltd", label: "Hinduja Leyland Finance Co Ltd" },
  { value: "Sl Enterprises Regd", label: "Sl Enterprises Regd" },
  { value: "Bandhan Pvt Bank Ltd", label: "Bandhan Pvt Bank Ltd" },
  { value: "Top Gain Finance Pvt Ltd", label: "Top Gain Finance Pvt Ltd" },
  { value: "Gramin Bank Of Aryavart Ronchi Bangar", label: "Gramin Bank Of Aryavart Ronchi Bangar" },
  { value: "Kothari Financiers Automobile Financiers", label: "Kothari Financiers Automobile Financiers" },
  { value: "Axis Finance Ltd", label: "Axis Finance Ltd" },
  { value: "Venus Financiers Regd", label: "Venus Financiers Regd" },
  { value: "Sri Sathya Sai Trust", label: "Sri Sathya Sai Trust" },
  { value: "Kamdhenu Auto Enterprise", label: "Kamdhenu Auto Enterprise" },
  { value: "The Corporation Bank", label: "The Corporation Bank" },
  { value: "Tata Motors Finance Solutions Ltd", label: "Tata Motors Finance Solutions Ltd" },
  { value: "Premjee Truck Finance Co Pvt Ltd", label: "Premjee Truck Finance Co Pvt Ltd" },
  { value: "The Prathamik Shikshak Sahakari Bank Ltd", label: "The Prathamik Shikshak Sahakari Bank Ltd" },
  { value: "India Motor Trading Cororation", label: "India Motor Trading Cororation" },
  { value: "Kedar Sahakari Pathpedi Maryadit", label: "Kedar Sahakari Pathpedi Maryadit" },
  { value: "Dist Co Operative Bank", label: "Dist Co Operative Bank" },
  { value: "Sangam Co Operative Credit Socity", label: "Sangam Co Operative Credit Socity" },
  { value: "Kavathemahankal Credit Co Op Society", label: "Kavathemahankal Credit Co Op Society" },
  { value: "The Belgaum Dist Vegetable Growers Purchasers And Sellers C Op Soc Ltd", label: "The Belgaum Dist Vegetable Growers Purchasers And Sellers C Op Soc Ltd" },
  { value: "Decimius Financial Ltd", label: "Decimius Financial Ltd" },
  { value: "The Vardhaman Nagrik Sahakari Bank Ltd", label: "The Vardhaman Nagrik Sahakari Bank Ltd" },
  { value: "Jammu Financiers Pvt Ltd", label: "Jammu Financiers Pvt Ltd" },
  { value: "Kotilya Nagari Sahakari Pathsanstha Maryadit", label: "Kotilya Nagari Sahakari Pathsanstha Maryadit" },
  { value: "Jvg Finance", label: "Jvg Finance" },
  { value: "Shriram Tpt Finance Co Ltd", label: "Shriram Tpt Finance Co Ltd" },
  { value: "Samgam Hire Purchase Co Ltd", label: "Samgam Hire Purchase Co Ltd" },
  { value: "Mahalakshmi Nithiyagam", label: "Mahalakshmi Nithiyagam" },
  { value: "Aichuki Kavar Financier", label: "Aichuki Kavar Financier" },
  { value: "Dahiwadi Credit Co Op Soc Ltd", label: "Dahiwadi Credit Co Op Soc Ltd" },
  { value: "Rural Electrification Corporation Limited", label: "Rural Electrification Corporation Limited" },
  { value: "Humdum Finance Co Pvt Ltd", label: "Humdum Finance Co Pvt Ltd" },
  { value: "Shivpratap Nagari Sahakari Pat Sanstha Ltd", label: "Shivpratap Nagari Sahakari Pat Sanstha Ltd" },
  { value: "The Citizen Co Op Bank Ltd", label: "The Citizen Co Op Bank Ltd" },
  { value: "Capital First", label: "Capital First" },
  { value: "Icici Securities Ltd", label: "Icici Securities Ltd" },
  { value: "Lovely Trading Corporation", label: "Lovely Trading Corporation" },
  { value: "The Kaligadara Seva Sah Mandali Ltd", label: "The Kaligadara Seva Sah Mandali Ltd" },
  { value: "Nandeeshwara Enterprises", label: "Nandeeshwara Enterprises" },
  { value: "National Insurance Co Ltd", label: "National Insurance Co Ltd" },
  { value: "Shree Virdhawal Jagdale Sah Motor Tr Sanstha Ltd", label: "Shree Virdhawal Jagdale Sah Motor Tr Sanstha Ltd" },
  { value: "Sindur Credits And Investments", label: "Sindur Credits And Investments" },
  { value: "The Pune Posts And Telecom Co Op Credit Soc Ltd", label: "The Pune Posts And Telecom Co Op Credit Soc Ltd" },
  { value: "Shivkalyan Nagari Sahakari Patsanstha Maryadit", label: "Shivkalyan Nagari Sahakari Patsanstha Maryadit" },
  { value: "T S Leasing And Fin Co", label: "T S Leasing And Fin Co" },
  { value: "Thrissur Dt Autoconsultants Service Co Op Society Ltd", label: "Thrissur Dt Autoconsultants Service Co Op Society Ltd" },
  { value: "Canfin Homes Ltd", label: "Canfin Homes Ltd" },
  { value: "Eqyutas Finance Pvt Ltd", label: "Eqyutas Finance Pvt Ltd" },
  { value: "A A Auto Financierr", label: "A A Auto Financierr" },
  { value: "Bardoli Janta Co Op Credit Soc", label: "Bardoli Janta Co Op Credit Soc" },
  { value: "Neel Gagan Finance", label: "Neel Gagan Finance" },
  { value: "Sheth Auto Finance", label: "Sheth Auto Finance" },
  { value: "Dhanshree Motor Finance P Ltd", label: "Dhanshree Motor Finance P Ltd" },
  { value: "Newtec Steels Pvt Ltd", label: "Newtec Steels Pvt Ltd" },
  { value: "Sri Venkataramana Credit Co Operative Society Ltd", label: "Sri Venkataramana Credit Co Operative Society Ltd" },
  { value: "Amman Group Sri Navaladiyan Auto Finance", label: "Amman Group Sri Navaladiyan Auto Finance" },
  { value: "Dhanalakshmi Auto Finance", label: "Dhanalakshmi Auto Finance" },
  { value: "Manjeet Secr And Finance Ltd", label: "Manjeet Secr And Finance Ltd" },
  { value: "The Shamrao Vitthal Co Op Bank Ltd", label: "The Shamrao Vitthal Co Op Bank Ltd" },
  { value: "L P Traders", label: "L P Traders" },
  { value: "Sharma Traders", label: "Sharma Traders" },
  { value: "Pnb Housing Finance Ltd", label: "Pnb Housing Finance Ltd" },
  { value: "Shri Sai Ganesh Krupa Gramin Biger Sheti Sah Pat S", label: "Shri Sai Ganesh Krupa Gramin Biger Sheti Sah Pat S" },
  { value: "Adilakshmi Auto Finanace", label: "Adilakshmi Auto Finanace" },
  { value: "Unique Auto Fin And Leasing P Ltd", label: "Unique Auto Fin And Leasing P Ltd" },
  { value: "Laxmi Auto Finance Ieeja", label: "Laxmi Auto Finance Ieeja" },
  { value: "Kaak Fin And Leasing P Ltd", label: "Kaak Fin And Leasing P Ltd" },
  { value: "Dc Bank Ltd", label: "Dc Bank Ltd" },
  { value: "Hinduja Leyland Fin", label: "Hinduja Leyland Fin" },
  { value: "Central Co Operative Bank Ltd", label: "Central Co Operative Bank Ltd" },
  { value: "M J Finvest", label: "M J Finvest" },
  { value: "Sehju Traders", label: "Sehju Traders" },
  { value: "General Manager Admin", label: "General Manager Admin" },
  { value: "Shivambika Leasing Pvt Ltd", label: "Shivambika Leasing Pvt Ltd" },
  { value: "Sri Venkateswara Hirepurchasee And Fin", label: "Sri Venkateswara Hirepurchasee And Fin" },
  { value: "Narsimha Anand Finance Regd", label: "Narsimha Anand Finance Regd" },
  { value: "Ptc India Financial Services Limited", label: "Ptc India Financial Services Limited" },
  { value: "Rajaya Laxmi Auto Finance", label: "Rajaya Laxmi Auto Finance" },
  { value: "Swami Samarth Nagari Sah Patsanstha", label: "Swami Samarth Nagari Sah Patsanstha" },
  { value: "Laxmi Ganapathi Motors", label: "Laxmi Ganapathi Motors" },
  { value: "Tata Capital Housing Finance Limited", label: "Tata Capital Housing Finance Limited" },
  { value: "Abhudaya Co Op Bank Ltd", label: "Abhudaya Co Op Bank Ltd" },
  { value: "Shree Kalimata Nagari Sahkari Patsanstha Maryadit", label: "Shree Kalimata Nagari Sahkari Patsanstha Maryadit" },
  { value: "Maa Laxmi Enterprises", label: "Maa Laxmi Enterprises" },
  { value: "Shakuntlam Securities P Ltd", label: "Shakuntlam Securities P Ltd" },
  { value: "Mahindra And Mahindra Finance Services Ltd", label: "Mahindra And Mahindra Finance Services Ltd" },
  { value: "Au Financiers Ltd", label: "Au Financiers Ltd" },
  { value: "Jeemy Auto Consultant", label: "Jeemy Auto Consultant" },
  { value: "Mag Wheels India Pvt Ltd", label: "Mag Wheels India Pvt Ltd" },
  { value: "Siwan Central Co Op Bank", label: "Siwan Central Co Op Bank" },
  { value: "Shrinath Trading", label: "Shrinath Trading" },
  { value: "Sai Viraj Co Op Credit Society Ltd", label: "Sai Viraj Co Op Credit Society Ltd" },
  { value: "Adarsh Seva Sahakari Patpedhi Ltd", label: "Adarsh Seva Sahakari Patpedhi Ltd" },
  { value: "Cod Harsiddhi Association", label: "Cod Harsiddhi Association" },
  { value: "Bhavani Auto Finance", label: "Bhavani Auto Finance" },
  { value: "Sahyog Trading Corp", label: "Sahyog Trading Corp" },
  { value: "Diamond Capfin Pvt Ltd", label: "Diamond Capfin Pvt Ltd" },
  { value: "Parijat Co Op Credit Society Ltd", label: "Parijat Co Op Credit Society Ltd" },
  { value: "Vanavil Finance", label: "Vanavil Finance" },
  { value: "Pmc Servants Co Op Bank Ltd", label: "Pmc Servants Co Op Bank Ltd" },
  { value: "Shiridi Sai Finance Corporation", label: "Shiridi Sai Finance Corporation" },
  { value: "Kallappanna Awade Ichalkaranji Janata Sahkari Bank Ltd", label: "Kallappanna Awade Ichalkaranji Janata Sahkari Bank Ltd" },
  { value: "Bandhan Bank", label: "Bandhan Bank" },
  { value: "Froniter Leasing And Fin Co Ltd", label: "Froniter Leasing And Fin Co Ltd" },
  { value: "Yogakshemam Loans Limited", label: "Yogakshemam Loans Limited" },
  { value: "Force Motors Ltd", label: "Force Motors Ltd" },
  { value: "Thiru Vadivelan Finance", label: "Thiru Vadivelan Finance" },
  { value: "Solapur Siddheshwar Sah Bank Ltd", label: "Solapur Siddheshwar Sah Bank Ltd" },
  { value: "Ess Aas Motor Finance Corp", label: "Ess Aas Motor Finance Corp" },
  { value: "Matoshri Sah Path Maryadit", label: "Matoshri Sah Path Maryadit" },
  { value: "Jain Vehicle Finance", label: "Jain Vehicle Finance" },
  { value: "Sohanlal Surana N Sons", label: "Sohanlal Surana N Sons" },
  { value: "Shrinath Nagari Sahkari Path Sanstha Maryadit", label: "Shrinath Nagari Sahkari Path Sanstha Maryadit" },
  { value: "Veer Traders", label: "Veer Traders" },
  { value: "Jeevan Rekha Nagari Pat Sanstha", label: "Jeevan Rekha Nagari Pat Sanstha" },
  { value: "Shri Jagadguru Renukacharya Co Op Cr Society Ltd", label: "Shri Jagadguru Renukacharya Co Op Cr Society Ltd" },
  { value: "The Vsv Co Operative Bank Ltd", label: "The Vsv Co Operative Bank Ltd" },
  { value: "Green Valley Finance And Leasing Pvt Ltd", label: "Green Valley Finance And Leasing Pvt Ltd" },
  { value: "Balvir Fin Corp", label: "Balvir Fin Corp" },
  { value: "Jaya Ganapathi Auto Finance", label: "Jaya Ganapathi Auto Finance" },
  { value: "Aruna Finance Limited", label: "Aruna Finance Limited" },
  { value: "Sri Meenachi Investment", label: "Sri Meenachi Investment" },
  { value: "Dmi Finance Ltd", label: "Dmi Finance Ltd" },
  { value: "Gabakunda Service Cooperative Society", label: "Gabakunda Service Cooperative Society" },
  { value: "Divya Motors", label: "Divya Motors" },
  { value: "Jaiswal Motors", label: "Jaiswal Motors" },
  { value: "Vijay Nagari Sahakari Pat Sanstha Maryadit", label: "Vijay Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Sivabalan Transport Finance", label: "Sivabalan Transport Finance" },
  { value: "A G Fin And Investment Pvt Ltd", label: "A G Fin And Investment Pvt Ltd" },
  { value: "Dmi Finance Pvt Ltd", label: "Dmi Finance Pvt Ltd" },
  { value: "Shri Modheshvari Grahak Sahakari Bhandar Ltd", label: "Shri Modheshvari Grahak Sahakari Bhandar Ltd" },
  { value: "Autechtra Services Pvt Ltd", label: "Autechtra Services Pvt Ltd" },
  { value: "Bgf Finance", label: "Bgf Finance" },
  { value: "Equitas Finance Ltd", label: "Equitas Finance Ltd" },
  { value: "S B H", label: "S B H" },
  { value: "Suvidha Motors", label: "Suvidha Motors" },
  { value: "Sri Pavana Ganga Auto Finance", label: "Sri Pavana Ganga Auto Finance" },
  { value: "Machhimar Co Op Credit Soc Ltd", label: "Machhimar Co Op Credit Soc Ltd" },
  { value: "Jasmeen Finance Corp", label: "Jasmeen Finance Corp" },
  { value: "Durgapur Steel Peoples Co Operative Bank Ltd", label: "Durgapur Steel Peoples Co Operative Bank Ltd" },
  { value: "A N Finance", label: "A N Finance" },
  { value: "The Kpt Central Coop Bank Ltd", label: "The Kpt Central Coop Bank Ltd" },
  { value: "Sri Bharat Motor Finance", label: "Sri Bharat Motor Finance" },
  { value: "The Mahantesh Urban Co Op Credit Socity Ltd", label: "The Mahantesh Urban Co Op Credit Socity Ltd" },
  { value: "Kai Swa Shamrao Patil Nagri Sah Pat Sanstha", label: "Kai Swa Shamrao Patil Nagri Sah Pat Sanstha" },
  { value: "Vasai Taluka Sagari Masemar Sahakari Patpedi Ltd", label: "Vasai Taluka Sagari Masemar Sahakari Patpedi Ltd" },
  { value: "Vallabha Finance", label: "Vallabha Finance" },
  { value: "Aj Finance Co Regd", label: "Aj Finance Co Regd" },
  { value: "Khandesh Sahakari Patpedhi Maryadit", label: "Khandesh Sahakari Patpedhi Maryadit" },
  { value: "Cholamandalam Finance Invst Co Ltd", label: "Cholamandalam Finance Invst Co Ltd" },
  { value: "Sri Sakthi Cars And Finance", label: "Sri Sakthi Cars And Finance" },
  { value: "Sree Akshaya Bankers", label: "Sree Akshaya Bankers" },
  { value: "Vallabha Auto Finance", label: "Vallabha Auto Finance" },
  { value: "Kavita Urban Co Operative Bank Ltd", label: "Kavita Urban Co Operative Bank Ltd" },
  { value: "Kisan Union Patsanstha", label: "Kisan Union Patsanstha" },
  { value: "Dhanlaxmi Patsanstha", label: "Dhanlaxmi Patsanstha" },
  { value: "Shri Sadguru Multistate Co Op Cre Soc Ltd", label: "Shri Sadguru Multistate Co Op Cre Soc Ltd" },
  { value: "Sant Rihidas Charmdhog And Charmkgl Dev Cor Ltd", label: "Sant Rihidas Charmdhog And Charmkgl Dev Cor Ltd" },
  { value: "Sundaram Finvest Company", label: "Sundaram Finvest Company" },
  { value: "Nittur Prathamik Krushi Pattin Sahakari Sangh Nyt", label: "Nittur Prathamik Krushi Pattin Sahakari Sangh Nyt" },
  { value: "Sangeshawar Co Op Credit Soc Nyt", label: "Sangeshawar Co Op Credit Soc Nyt" },
  { value: "Asmita Mahila Gramin Bigar Sheti Sah Pat Sanstha", label: "Asmita Mahila Gramin Bigar Sheti Sah Pat Sanstha" },
  { value: "The Kalupur Commercial Co Operative Bank Ltd", label: "The Kalupur Commercial Co Operative Bank Ltd" },
  { value: "Sidbi Bank.", label: "Sidbi Bank." },
  { value: "Shree Eswar Auto Leasing R", label: "Shree Eswar Auto Leasing R" },
  { value: "Reliance Capital Limited Commercial Finance", label: "Reliance Capital Limited Commercial Finance" },
  { value: "Janasahakarya Co Op Credi Society Ltd", label: "Janasahakarya Co Op Credi Society Ltd" },
  { value: "Ford Credit India Pvt Ltd", label: "Ford Credit India Pvt Ltd" },
  { value: "Althaea Finance And Leasing Pvt", label: "Althaea Finance And Leasing Pvt" },
  { value: "Charan Automobile Financiers", label: "Charan Automobile Financiers" },
  { value: "Sai Rama Auto Finance", label: "Sai Rama Auto Finance" },
  { value: "Ms Decimums Financial Ltd", label: "Ms Decimums Financial Ltd" },
  { value: "Yadnyavalkya Nagari Sah Patsanstha Maryadit", label: "Yadnyavalkya Nagari Sah Patsanstha Maryadit" },
  { value: "Abhinandan Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit", label: "Abhinandan Gramin Bigar Sheti Sahakari Pat Sanstha Maryadit" },
  { value: "Sabir Gramin Big Sheti Sahkari Patsanstha Maryadit", label: "Sabir Gramin Big Sheti Sahkari Patsanstha Maryadit" },
  { value: "Kk Auto Finance", label: "Kk Auto Finance" },
  { value: "Shri Raj Rajeshwar Nagari Sahakari Pat San Maryadi", label: "Shri Raj Rajeshwar Nagari Sahakari Pat San Maryadi" },
  { value: "Janapriya Sahakari Patapedhi Ltd", label: "Janapriya Sahakari Patapedhi Ltd" },
  { value: "J K M Sund And Sons Finance", label: "J K M Sund And Sons Finance" },
  { value: "Jivan Vikas", label: "Jivan Vikas" },
  { value: "Punai Fin Co", label: "Punai Fin Co" },
  { value: "Tvs Credit Service Ltd", label: "Tvs Credit Service Ltd" },
  { value: "Raj Kamal Finance Co", label: "Raj Kamal Finance Co" },
  { value: "Prabhat Co Op Credit Society", label: "Prabhat Co Op Credit Society" },
  { value: "Ggrace Leasing Co Pvt Ltd", label: "Ggrace Leasing Co Pvt Ltd" },
  { value: "Sharadrao Pawar Nagari Sahakari Patsanstha Maryadit", label: "Sharadrao Pawar Nagari Sahakari Patsanstha Maryadit" },
  { value: "Kalpataru Nagari Sahakari Patsanstha Maryadit", label: "Kalpataru Nagari Sahakari Patsanstha Maryadit" },
  { value: "Saptashrugi Mahila Nagari Sahakari Patsanstha Maryadit", label: "Saptashrugi Mahila Nagari Sahakari Patsanstha Maryadit" },
  { value: "Sanjivni Sahakari Patsanstha Maryadit", label: "Sanjivni Sahakari Patsanstha Maryadit" },
  { value: "Loknete Kanayyalal Nahar Nagari Sahakari Patsanstha", label: "Loknete Kanayyalal Nahar Nagari Sahakari Patsanstha" },
  { value: "Manmad Urban Co Op Bank Ltd", label: "Manmad Urban Co Op Bank Ltd" },
  { value: "Shri Swami Samartha Nagari Sahakari Patsanstha Maryadit", label: "Shri Swami Samartha Nagari Sahakari Patsanstha Maryadit" },
  { value: "Amrut Nagari Sahakari Patsanstha Maryadit", label: "Amrut Nagari Sahakari Patsanstha Maryadit" },
  { value: "Shri Gurudeo Datta Nagari Sahakari Patsanstha Maryadit", label: "Shri Gurudeo Datta Nagari Sahakari Patsanstha Maryadit" },
  { value: "Samruddhi Sahakari Bank Ltd", label: "Samruddhi Sahakari Bank Ltd" },
  { value: "Jai Datti Financial Service", label: "Jai Datti Financial Service" },
  { value: "Dewesh Auto Creative Services Pvt Ltd", label: "Dewesh Auto Creative Services Pvt Ltd" },
  { value: "Bolgarh Scs Ltd", label: "Bolgarh Scs Ltd" },
  { value: "Sarv Gramin Bank Dhampur", label: "Sarv Gramin Bank Dhampur" },
  { value: "The Deola Merchants Co Op Bank", label: "The Deola Merchants Co Op Bank" },
  { value: "State Bank Of Jammu And Kashmir", label: "State Bank Of Jammu And Kashmir" },
  { value: "A K Capital Finance Pvt Ltd", label: "A K Capital Finance Pvt Ltd" },
  { value: "Pune Cantonment Co Op Bank", label: "Pune Cantonment Co Op Bank" },
  { value: "Shree Biradev Finance Ltd", label: "Shree Biradev Finance Ltd" },
  { value: "Ponsurya Investments", label: "Ponsurya Investments" },
  { value: "Mahindra And Mahindra Ltd", label: "Mahindra And Mahindra Ltd" },
  { value: "Tirupati Arban Co Op Credit Society Ltd", label: "Tirupati Arban Co Op Credit Society Ltd" },
  { value: "Sber Bank", label: "Sber Bank" },
  { value: "Nsbvb Ltd", label: "Nsbvb Ltd" },
  { value: "Tirupati Urban Co Op Credit Society Ltd", label: "Tirupati Urban Co Op Credit Society Ltd" },
  { value: "Bharatiya Mahila Bank Ltd", label: "Bharatiya Mahila Bank Ltd" },
  { value: "Shri Patil Baba Bigar Sheti Sahakari Path Sanstha Marya", label: "Shri Patil Baba Bigar Sheti Sahakari Path Sanstha Marya" },
  { value: "Ing Vysya Bank Limited", label: "Ing Vysya Bank Limited" },
  { value: "Keshri Motors", label: "Keshri Motors" },
  { value: "Mahaveer Finance Corporation Auto Mobiles Financiers", label: "Mahaveer Finance Corporation Auto Mobiles Financiers" },
  { value: "Saptagiri Auto Finance", label: "Saptagiri Auto Finance" },
  { value: "Agro Indus Finance And Leasing I Ltd", label: "Agro Indus Finance And Leasing I Ltd" },
  { value: "Harinder Investment Ltd", label: "Harinder Investment Ltd" },
  { value: "S P Fin And Trading Corp", label: "S P Fin And Trading Corp" },
  { value: "Agro Indas Finance And Leasing", label: "Agro Indas Finance And Leasing" },
  { value: "Shree Balaji Finance And Leasing", label: "Shree Balaji Finance And Leasing" },
  { value: "Siddharth M Patil Finance", label: "Siddharth M Patil Finance" },
  { value: "Cathalic Urban Credit Society Ltd", label: "Cathalic Urban Credit Society Ltd" },
  { value: "The Gulbarga Citizens Welfare,Credit Co Op Society Ltd", label: "The Gulbarga Citizens Welfare,Credit Co Op Society Ltd" },
  { value: "Mamta Co Op Credit Soc Ltd", label: "Mamta Co Op Credit Soc Ltd" },
  { value: "Yashoda Nagari Saha Pat Sanstha Maryadit", label: "Yashoda Nagari Saha Pat Sanstha Maryadit" },
  { value: "Tata Capital Fin Service Ltd", label: "Tata Capital Fin Service Ltd" },
  { value: "Singhi Finance", label: "Singhi Finance" },
  { value: "Shree Nani Vavdi Seva Sahakari Mandali Ltd", label: "Shree Nani Vavdi Seva Sahakari Mandali Ltd" },
  { value: "Kokan Bhangarwade Nagri Co Op Credit Society Ltd", label: "Kokan Bhangarwade Nagri Co Op Credit Society Ltd" },
  { value: "Geo Vpl Finance Pvt Ltd", label: "Geo Vpl Finance Pvt Ltd" },
  { value: "Manappuruam Finance Ltd", label: "Manappuruam Finance Ltd" },
  { value: "Gilda Finance And Investments Ltd", label: "Gilda Finance And Investments Ltd" },
  { value: "The Vision Co Op U T C Society Ltd", label: "The Vision Co Op U T C Society Ltd" },
  { value: "Gramin Bank Of Aryavart", label: "Gramin Bank Of Aryavart" },
  { value: "Mercury Car Rentals Pvt Ltd", label: "Mercury Car Rentals Pvt Ltd" },
  { value: "Shree Shakti Sainik Sahakari Pat Sanstha", label: "Shree Shakti Sainik Sahakari Pat Sanstha" },
  { value: "Punjab Motor Fin Co", label: "Punjab Motor Fin Co" },
  { value: "Uco Bank Ltd", label: "Uco Bank Ltd" },
  { value: "Shree Mahuva Nagrik Sahakari Bak Ltd", label: "Shree Mahuva Nagrik Sahakari Bak Ltd" },
  { value: "Vijayraj Nagari Sahakari Pat San Maryadit", label: "Vijayraj Nagari Sahakari Pat San Maryadit" },
  { value: "Ashutosh Sercurity Pvt Ltd", label: "Ashutosh Sercurity Pvt Ltd" },
  { value: "Udupi Taluk Protestant Christian Credit Cooperative Society Ltd", label: "Udupi Taluk Protestant Christian Credit Cooperative Society Ltd" },
  { value: "Merry Time Credits Pvt Ltd", label: "Merry Time Credits Pvt Ltd" },
  { value: "Ellaqui Dehati Bank", label: "Ellaqui Dehati Bank" },
  { value: "Mahadev Urban Co Op Credit Soc Ltd", label: "Mahadev Urban Co Op Credit Soc Ltd" },
  { value: "The Hoshiarpur Central Co Operative", label: "The Hoshiarpur Central Co Operative" },
  { value: "Kalpavruksha Multi Co Op Soc", label: "Kalpavruksha Multi Co Op Soc" },
  { value: "Shri Saibaba Nag.Saha.Patsanstha.Maryadit Manmad", label: "Shri Saibaba Nag.Saha.Patsanstha.Maryadit Manmad" },
  { value: "Yashshri Gramin Bigher Sheti Sahakari Pat San Marya", label: "Yashshri Gramin Bigher Sheti Sahakari Pat San Marya" },
  { value: "Jyoti Ur Co Op Cre Soc Ltd", label: "Jyoti Ur Co Op Cre Soc Ltd" },
  { value: "The Palamoor Co Operative Urban Bank Ltd", label: "The Palamoor Co Operative Urban Bank Ltd" },
  { value: "The Nabapalli Co Operative Bank Ltd", label: "The Nabapalli Co Operative Bank Ltd" },
  { value: "Tata Motors Finance Solutions Limited", label: "Tata Motors Finance Solutions Limited" },
  { value: "Shree Navjyoti Finance Company", label: "Shree Navjyoti Finance Company" },
  { value: "Pny Sabha Finance Ltd", label: "Pny Sabha Finance Ltd" },
  { value: "Devika Urban Co Operative Bank Ltd", label: "Devika Urban Co Operative Bank Ltd" },
  { value: "Malimala V Ka Sah Seva Sanstha Maryadit", label: "Malimala V Ka Sah Seva Sanstha Maryadit" },
  { value: "Nagaramunnoli Rural Credit Souhard Sahakari Niyamit", label: "Nagaramunnoli Rural Credit Souhard Sahakari Niyamit" },
  { value: "Vardhaman Gbss Pat Maryadit", label: "Vardhaman Gbss Pat Maryadit" },
  { value: "Laxmi Instalment Supply", label: "Laxmi Instalment Supply" },
  { value: "Sri Sai Krupa Financiers", label: "Sri Sai Krupa Financiers" },
  { value: "Hdb Financial Services", label: "Hdb Financial Services" },
  { value: "Punia Finance Co", label: "Punia Finance Co" },
  { value: "Panchavati Nagari Sahakari Patsanstha Maryadit", label: "Panchavati Nagari Sahakari Patsanstha Maryadit" },
  { value: "Ecl Finance Ltd", label: "Ecl Finance Ltd" },
  { value: "A.U Fiancies India Limited", label: "A.U Fiancies India Limited" },
  { value: "A Subramaniam And Sons", label: "A Subramaniam And Sons" },
  { value: "Au Finance India Ltd", label: "Au Finance India Ltd" },
  { value: "The Laxmi Co Operative Bank Ltd", label: "The Laxmi Co Operative Bank Ltd" },
  { value: "Chatrapati Gramin Big Sheti Patsanstha", label: "Chatrapati Gramin Big Sheti Patsanstha" },
  { value: "Deutsche Investitions Deg", label: "Deutsche Investitions Deg" },
  { value: "The Koregaon Mar.Gram.Begar Sheti.Sah.Pathsanstha", label: "The Koregaon Mar.Gram.Begar Sheti.Sah.Pathsanstha" },
  { value: "Kogta Financial Ltd", label: "Kogta Financial Ltd" },
  { value: "Kalanjiam Fincorp", label: "Kalanjiam Fincorp" },
  { value: "Shri Chhatrapati Rajashri Shahu Urban Cbl", label: "Shri Chhatrapati Rajashri Shahu Urban Cbl" },
  { value: "Diwakar Motors", label: "Diwakar Motors" },
  { value: "P L Jain And Money Landers", label: "P L Jain And Money Landers" },
  { value: "Nederlands Financierings", label: "Nederlands Financierings" },
  { value: "Daji Patil Nagari Sahakari Pat Maryadit", label: "Daji Patil Nagari Sahakari Pat Maryadit" },
  { value: "Sri Keerthi Finance", label: "Sri Keerthi Finance" },
  { value: "Laxmi Sai Motors", label: "Laxmi Sai Motors" },
  { value: "Bhavani Motors", label: "Bhavani Motors" },
  { value: "Sahiba Motors", label: "Sahiba Motors" },
  { value: "Jai Kisan Gramin Bigar Sheti Sah Pat Sanstha Marydt", label: "Jai Kisan Gramin Bigar Sheti Sah Pat Sanstha Marydt" },
  { value: "Dadasaheb Gajmal Co Op Bank Ltd", label: "Dadasaheb Gajmal Co Op Bank Ltd" },
  { value: "New Balaji Auto Finance", label: "New Balaji Auto Finance" },
  { value: "Mahindra And Mahindra Financial", label: "Mahindra And Mahindra Financial" },
  { value: "Sai Krupa Hire Purchase Finance", label: "Sai Krupa Hire Purchase Finance" },
  { value: "Gilada Finance And Investment Ltd", label: "Gilada Finance And Investment Ltd" },
  { value: "Srinivasa Hp And Finance", label: "Srinivasa Hp And Finance" },
  { value: "Elahi Credit Co Op Society Ltd", label: "Elahi Credit Co Op Society Ltd" },
  { value: "Family Credit Services Ltd", label: "Family Credit Services Ltd" },
  { value: "S K Fic Co Ltd", label: "S K Fic Co Ltd" },
  { value: "Saras Urban Co Op Credit Soc Ltd", label: "Saras Urban Co Op Credit Soc Ltd" },
  { value: "Sri Shiva Sai Auto Finance", label: "Sri Shiva Sai Auto Finance" },
  { value: "Shiva Shiva Auto Finance", label: "Shiva Shiva Auto Finance" },
  { value: "Sneha Co Op Credit Society Ltd", label: "Sneha Co Op Credit Society Ltd" },
  { value: "Swarnalaxmi Gramin Bigersheti Sa Pat Sanstha Maryadit Chandwad", label: "Swarnalaxmi Gramin Bigersheti Sa Pat Sanstha Maryadit Chandwad" },
  { value: "Syndicate Bank Ltd", label: "Syndicate Bank Ltd" },
  { value: "Mas Financial Services", label: "Mas Financial Services" },
  { value: "Ms Agarwal And Jain Finance", label: "Ms Agarwal And Jain Finance" },
  { value: "Madhukaar Pandurang Metkar Gramin Bigarsheti Sa Patsanstha Maryadit", label: "Madhukaar Pandurang Metkar Gramin Bigarsheti Sa Patsanstha Maryadit" },
  { value: "Challani Mahaveer Chand", label: "Challani Mahaveer Chand" },
  { value: "Jeevana Sou Vividoddesha Sahakari Niyamita", label: "Jeevana Sou Vividoddesha Sahakari Niyamita" },
  { value: "Tuljai Finance", label: "Tuljai Finance" },
  { value: "Decimus Financial Ltd", label: "Decimus Financial Ltd" },
  { value: "Diwan Finance", label: "Diwan Finance" },
  { value: "Jayantrao Bhosale Appa Nagari Sah Pat Sanstha", label: "Jayantrao Bhosale Appa Nagari Sah Pat Sanstha" },
  { value: "Jewel Associates", label: "Jewel Associates" },
  { value: "Shradha Saburi Nagari Sahakari Pat Sanstha Maryadit", label: "Shradha Saburi Nagari Sahakari Pat Sanstha Maryadit" },
  { value: "Ajit Multistate Co Op Cr Soc Ltd", label: "Ajit Multistate Co Op Cr Soc Ltd" },
  { value: "Sanchitha Credit Co Op Society Ltd", label: "Sanchitha Credit Co Op Society Ltd" },
  { value: "Sarada Associates", label: "Sarada Associates" },
  { value: "Aadarsha Nagri Co Oprative Soc Ltd", label: "Aadarsha Nagri Co Oprative Soc Ltd" },
  { value: "J And K Sc At And Obc Dev Corp Ltd", label: "J And K Sc At And Obc Dev Corp Ltd" },
  { value: "Raunaq Logistics", label: "Raunaq Logistics" },
  { value: "Nuthan Credit Co Op Soc Ltd", label: "Nuthan Credit Co Op Soc Ltd" },
  { value: "Chorivad Bachat And Dhiran Mandali Ltd", label: "Chorivad Bachat And Dhiran Mandali Ltd" },
  { value: "P.K.Jain Finance", label: "P.K.Jain Finance" },
  { value: "Lucky Finelease Pvt Ltd", label: "Lucky Finelease Pvt Ltd" },
  { value: "The Mandi Urban Co Op Bank", label: "The Mandi Urban Co Op Bank" },
  { value: "Shree Durga Mata Multipurpose Co Op Soc Ltd Khairwad Tal Khanapur", label: "Shree Durga Mata Multipurpose Co Op Soc Ltd Khairwad Tal Khanapur" },
  { value: "Savery Transport Finance Ltd", label: "Savery Transport Finance Ltd" },
  { value: "Sullkerimogru Primary Agriculture Coop Sciety Ltd", label: "Sullkerimogru Primary Agriculture Coop Sciety Ltd" },
  { value: "The Yamuna Bachat Dhiran Sahakari Mandali Ltd", label: "The Yamuna Bachat Dhiran Sahakari Mandali Ltd" },
  { value: "Jkedi", label: "Jkedi" },
  { value: "Neelkanth Trading Corp", label: "Neelkanth Trading Corp" },
  { value: "Ohm Adhi Vinayaga Finance", label: "Ohm Adhi Vinayaga Finance" },
  { value: "Tata Motors Fin Solution Ltd", label: "Tata Motors Fin Solution Ltd" },
  { value: "Datt Digambar Co Op Credit Soc Ltd", label: "Datt Digambar Co Op Credit Soc Ltd" },
  { value: "Rbl Bank", label: "Rbl Bank" },
  { value: "Bhiwandi Jankalyan Nagri Sah Pantsantha Ltd", label: "Bhiwandi Jankalyan Nagri Sah Pantsantha Ltd" },
  { value: "Tvs Credit Service Pvt Ltd", label: "Tvs Credit Service Pvt Ltd" },
  { value: "Ms Shivkala Urban Co Op Credit Society Ltd", label: "Ms Shivkala Urban Co Op Credit Society Ltd" },
  { value: "Pacs Ltd", label: "Pacs Ltd" },
  { value: "Sharda Leasing And Company", label: "Sharda Leasing And Company" },
  { value: "Asy Financial Service", label: "Asy Financial Service" },
  { value: "Kgta Financial Ltd", label: "Kgta Financial Ltd" },
  { value: "The Hansalpure Seva Shakari Mandali Ltd", label: "The Hansalpure Seva Shakari Mandali Ltd" },
  { value: "Gana Sree Datta Auto Finance", label: "Gana Sree Datta Auto Finance" },
  { value: "The Koth Grup Seva Sahkari Mandli Ltd", label: "The Koth Grup Seva Sahkari Mandli Ltd" },
  { value: "Samata Nagari Sah Pat Maryadit", label: "Samata Nagari Sah Pat Maryadit" },
  { value: "Hsjs Gandhi Enterprises", label: "Hsjs Gandhi Enterprises" },
  { value: "Jyoti Enterprises", label: "Jyoti Enterprises" },
  { value: "Mason Club Credit Co Op Soc Ltd", label: "Mason Club Credit Co Op Soc Ltd" },
  { value: "The Jakhora Seva Sahkari Mandali Ltd", label: "The Jakhora Seva Sahkari Mandali Ltd" },
  { value: "Vikas Hire Purchase Comapany Ltd", label: "Vikas Hire Purchase Comapany Ltd" },
  { value: "Ajana Patwa Wo Ashok Patwa", label: "Ajana Patwa Wo Ashok Patwa" },
  { value: "Baba Enterprises", label: "Baba Enterprises" },
  { value: "Sri Sri Lakshmi Venkateshwara Auto Finance", label: "Sri Sri Lakshmi Venkateshwara Auto Finance" },
  { value: "Sneh Nagari Sahakari Path Sanstha Maryadit", label: "Sneh Nagari Sahakari Path Sanstha Maryadit" },
  { value: "Anmol Finance", label: "Anmol Finance" },
  { value: "Belagavi Shree Basaveshwar Co Op Bank Ltd", label: "Belagavi Shree Basaveshwar Co Op Bank Ltd" },
  { value: "Sri Chankra Auto Finance", label: "Sri Chankra Auto Finance" },
  { value: "S Rajendra Kumar And Sons", label: "S Rajendra Kumar And Sons" },
  { value: "Maadhava Financial Services", label: "Maadhava Financial Services" },
  { value: "Shree Ram Fin Co Ltd", label: "Shree Ram Fin Co Ltd" },
  { value: "Sanket Co Op Credit Soc Ltd", label: "Sanket Co Op Credit Soc Ltd" },
  { value: "Mamasaheb Pawar Satyavijay Sah Bank Ltd", label: "Mamasaheb Pawar Satyavijay Sah Bank Ltd" },
  { value: "Mangaldeep Finance", label: "Mangaldeep Finance" },
  { value: "Lagwal Finance Co", label: "Lagwal Finance Co" },
  { value: "Sri Selvasri Capital", label: "Sri Selvasri Capital" },
  { value: "Khush Housing Finance Pvt Ltd", label: "Khush Housing Finance Pvt Ltd" },
  { value: "Sri Pavan Auto Financier", label: "Sri Pavan Auto Financier" },
  { value: "Prakash J Devi", label: "Prakash J Devi" },
  { value: "Reen Finance Corporation", label: "Reen Finance Corporation" },
  { value: "Jotirling Nagari Sahakari Pat Sanstha Ltd", label: "Jotirling Nagari Sahakari Pat Sanstha Ltd" },
  { value: "Cholamandal Inv And Fin Ser Ltd", label: "Cholamandal Inv And Fin Ser Ltd" },
  { value: "Jothi Murugan Finance", label: "Jothi Murugan Finance" },
  { value: "Shri Omsai Co Op Credit Society Ltd", label: "Shri Omsai Co Op Credit Society Ltd" },
  { value: "Australia And New Zealand Banking Group Limited", label: "Australia And New Zealand Banking Group Limited" },
  { value: "Sri Kaveri Finance", label: "Sri Kaveri Finance" },
  { value: "Shri Makhiyala Juth Vikas Sahakari Mandali Ltd", label: "Shri Makhiyala Juth Vikas Sahakari Mandali Ltd" },
  { value: "Primary Agriculture Co Operative Society", label: "Primary Agriculture Co Operative Society" },
  { value: "Dessmuss Finance", label: "Dessmuss Finance" },
  { value: "The Talai Gs Co Operative Society Ltd", label: "The Talai Gs Co Operative Society Ltd" },
  { value: "Khatri Finance Company", label: "Khatri Finance Company" },
  { value: "Shri Ganesh Gbss Pat Maryadit", label: "Shri Ganesh Gbss Pat Maryadit" },
  { value: "The Sankhali Urban Co Op Credit Society", label: "The Sankhali Urban Co Op Credit Society" },
  { value: "Aglow Fintrade Pvt Ltd", label: "Aglow Fintrade Pvt Ltd" },
  { value: "Yashshri Multistate Credit Co Op Scociety", label: "Yashshri Multistate Credit Co Op Scociety" },
  { value: "Sri Malakonda Swamy Automobile Financiers", label: "Sri Malakonda Swamy Automobile Financiers" },
  { value: "Simdra Finance Ltd", label: "Simdra Finance Ltd" },
  { value: "The Solgam Seva Sahakari Mandli Ltd.", label: "The Solgam Seva Sahakari Mandli Ltd." },
  { value: "The Zolapur Seva Sahkari Mandli Ltd.", label: "The Zolapur Seva Sahkari Mandli Ltd." },
  { value: "Shree Kshetra Paleshwar Sahakari Patpedhi Maryadit", label: "Shree Kshetra Paleshwar Sahakari Patpedhi Maryadit" },
  { value: "Dayal Traders", label: "Dayal Traders" },
  { value: "Orchid Wheels India Pvt Ltd", label: "Orchid Wheels India Pvt Ltd" },
  { value: "Ifk Finance Ltd", label: "Ifk Finance Ltd" },
  { value: "Mangaladevi Sahakari Pathpedhi Maryadit Ltd", label: "Mangaladevi Sahakari Pathpedhi Maryadit Ltd" },
  { value: "Mahatma Phule Co.Op Credit Soc Ltd", label: "Mahatma Phule Co.Op Credit Soc Ltd" },
  { value: "Siddivinayak Souhardha Pattina Sahakari Niyamit", label: "Siddivinayak Souhardha Pattina Sahakari Niyamit" },
  { value: "Shri Nandi Cre Sou Sah Nyt,", label: "Shri Nandi Cre Sou Sah Nyt," },
  { value: "Chiplun Taluka Co Op Cre Soc Ltd", label: "Chiplun Taluka Co Op Cre Soc Ltd" },
  { value: "Dsk Urban Bank", label: "Dsk Urban Bank" },
  { value: "The Mingalpur Group S S Mandali Ltd", label: "The Mingalpur Group S S Mandali Ltd" },
  { value: "Shriram Transport Fin Corp", label: "Shriram Transport Fin Corp" },
  { value: "Sri Balaji Hire Purchase Finance", label: "Sri Balaji Hire Purchase Finance" },
  { value: "Sudhakarrao Warpe Gramin Big Sheti Sah Path Mar", label: "Sudhakarrao Warpe Gramin Big Sheti Sah Path Mar" },
  { value: "Lok Sewak Leasning Investment P", label: "Lok Sewak Leasning Investment P" },
  { value: "Shree Dholeshwar Co Op Credit Society Ltd", label: "Shree Dholeshwar Co Op Credit Society Ltd" },
  { value: "Ambernath Jaihind Co Op Bank Ltd", label: "Ambernath Jaihind Co Op Bank Ltd" },
  { value: "Mauli Krupa Co Op Credit Soc Ltd", label: "Mauli Krupa Co Op Credit Soc Ltd" },
  { value: "Talai Agriculture Cooperartive Society", label: "Talai Agriculture Cooperartive Society" },
  { value: "The Talai Cooperative Society Ltd", label: "The Talai Cooperative Society Ltd" },
  { value: "Sri Sambasiva Finance Corporation", label: "Sri Sambasiva Finance Corporation" },
  { value: "The Nagrik Sahakari Mandali Ltd", label: "The Nagrik Sahakari Mandali Ltd" },
  { value: "Araban Bank", label: "Araban Bank" },
  { value: "Bhaskar Auto Finance", label: "Bhaskar Auto Finance" },
  { value: "Rameshwari Financiers", label: "Rameshwari Financiers" },
  { value: "Lic If India", label: "Lic If India" },
  { value: "Jai Shree Ram Nagri Patsantha", label: "Jai Shree Ram Nagri Patsantha" },
  { value: "Indiabulls Housing Finance Ltd", label: "Indiabulls Housing Finance Ltd" },
  { value: "Maa Mangala Mahila Multi Purpose Co Operative Society Ltd", label: "Maa Mangala Mahila Multi Purpose Co Operative Society Ltd" },
  { value: "Cholamandalalm Investment Finance Co Ltd", label: "Cholamandalalm Investment Finance Co Ltd" },
  { value: "Tata Capital Financial Services", label: "Tata Capital Financial Services" },
  { value: "Shri Ramrao Aher Nag.Sa.Pat.San.Maryadit.Deola", label: "Shri Ramrao Aher Nag.Sa.Pat.San.Maryadit.Deola" },
  { value: "Rangoli Lease And Finance Ltd", label: "Rangoli Lease And Finance Ltd" },
  { value: "Majotra Fin And Traders Ltd", label: "Majotra Fin And Traders Ltd" },
  { value: "Au Financiers Credit Limited", label: "Au Financiers Credit Limited" },
  { value: "Sri Kanaka Pattina Sahakara Sanga Niyamitha", label: "Sri Kanaka Pattina Sahakara Sanga Niyamitha" },
  { value: "Krishna Motor Traders", label: "Krishna Motor Traders" },
  { value: "Sanjay Co Op Credit Soc Ltd", label: "Sanjay Co Op Credit Soc Ltd" },
  { value: "Shiva Auto Finance", label: "Shiva Auto Finance" },
  { value: "Aris Capital Ltd", label: "Aris Capital Ltd" },
  { value: "Orishti Enterprises", label: "Orishti Enterprises" },
  { value: "Khaira District Central Co Op Bank", label: "Khaira District Central Co Op Bank" },
  { value: "B Bachhraj Bokadia And Sons", label: "B Bachhraj Bokadia And Sons" },
  { value: "Girnai Magari Sahakri Pathsanstha Ltd", label: "Girnai Magari Sahakri Pathsanstha Ltd" },
  { value: "Tripura State Co Operative Bank", label: "Tripura State Co Operative Bank" },
  { value: "Idfc Bank", label: "Idfc Bank" },
  { value: "Nav Sarita Dhara Madg Coop Credit Soc Ltd", label: "Nav Sarita Dhara Madg Coop Credit Soc Ltd" },
  { value: "Akme Finance Company", label: "Akme Finance Company" },
  { value: "Dhree Enterprises", label: "Dhree Enterprises" },
  { value: "K L Padmasree Prasad", label: "K L Padmasree Prasad" },
  { value: "Sri Sailakshmi Finance", label: "Sri Sailakshmi Finance" },
  { value: "L And T Infra Debt Fund", label: "L And T Infra Debt Fund" },
  { value: "Tata Cleantech Capital Pvt Ltd", label: "Tata Cleantech Capital Pvt Ltd" },
  { value: "L And T Infra Debt Fund.", label: "L And T Infra Debt Fund." },
  { value: "Tata Cleantech Capital P. Ltd", label: "Tata Cleantech Capital P. Ltd" },
  { value: "Lic", label: "Lic" },
  { value: "Jm Financial Services Ltd", label: "Jm Financial Services Ltd" },
  { value: "Sudan Enterprises", label: "Sudan Enterprises" },
  { value: "Shri Ram City Union Finance Service Ltd", label: "Shri Ram City Union Finance Service Ltd" },
  { value: "Jayasree Finance Corporation", label: "Jayasree Finance Corporation" },
  { value: "Om Shiv Co Op Credit Society Ltd", label: "Om Shiv Co Op Credit Society Ltd" },
  { value: "Pmk Finance", label: "Pmk Finance" },
  { value: "Venkata Kalyani Auto Finance", label: "Venkata Kalyani Auto Finance" },
  { value: "R D Finance", label: "R D Finance" },
  { value: "Kal Parvat Manaji Rahane Nana Gramin Priq Sheti Sah. Pa. May. Chandanapuri", label: "Kal Parvat Manaji Rahane Nana Gramin Priq Sheti Sah. Pa. May. Chandanapuri" },
  { value: "Shree Ashtavinayaka Ur Sou Sah Niy", label: "Shree Ashtavinayaka Ur Sou Sah Niy" },
  { value: "R.D And Co", label: "R.D And Co" },
  { value: "Hirubhau Gawali Nagari Sa Pat Maryadit", label: "Hirubhau Gawali Nagari Sa Pat Maryadit" },
  { value: "Lord Krishna Financiers", label: "Lord Krishna Financiers" },
  { value: "New Shiva Motor Traders", label: "New Shiva Motor Traders" },
  { value: "Mtl Instrument P Ltd", label: "Mtl Instrument P Ltd" },
  { value: "Rajendra Kumar Dharewar", label: "Rajendra Kumar Dharewar" },
  { value: "Galaxy Financial Services P Ltd", label: "Galaxy Financial Services P Ltd" },
  { value: "Satkar Finance Corp", label: "Satkar Finance Corp" },
  { value: "Uttamrao Dhikle Gramin Bigar Sheti Sah Pat Sanstha Maryadit", label: "Uttamrao Dhikle Gramin Bigar Sheti Sah Pat Sanstha Maryadit" },
  { value: "Nellad Veettor Service Co Operative Society Ltd", label: "Nellad Veettor Service Co Operative Society Ltd" },
  { value: "Marlecha Investments", label: "Marlecha Investments" },
  { value: "Cholamandlam Investment Co. Ltd.", label: "Cholamandlam Investment Co. Ltd." },
  { value: "Greatfort Minorities Multipurpose Souharda Co Op Ltd", label: "Greatfort Minorities Multipurpose Souharda Co Op Ltd" },
  { value: "Shree Mahavir Sahakari Bank Ltd", label: "Shree Mahavir Sahakari Bank Ltd" },
  { value: "Shri Sharanaveereshwar Sahakari Bank", label: "Shri Sharanaveereshwar Sahakari Bank" },
  { value: "Sumitomo Mitsui Banking Corporation", label: "Sumitomo Mitsui Banking Corporation" },
  { value: "Shri Vaibhavlaxmi C O Cr Soc Ltd", label: "Shri Vaibhavlaxmi C O Cr Soc Ltd" },
  { value: "Shrinidhi Mahila U Co Op Cr Soc Ltd", label: "Shrinidhi Mahila U Co Op Cr Soc Ltd" },
  { value: "Vayasaik Sahakari Bank Ltd", label: "Vayasaik Sahakari Bank Ltd" },
  { value: "Sree Panduranga Credit Co Op So Ltd", label: "Sree Panduranga Credit Co Op So Ltd" },
  { value: "The Thane City Police Employees Co Op Credit Soc", label: "The Thane City Police Employees Co Op Credit Soc" },
  { value: "Rayat Seva Nagari Sahakari", label: "Rayat Seva Nagari Sahakari" },
  { value: "Godavari Urban Multi State Credit Co Op Society Ltd", label: "Godavari Urban Multi State Credit Co Op Society Ltd" },
  { value: "Edelweiss Housing Finance Ltd", label: "Edelweiss Housing Finance Ltd" },
  { value: "Gulsan Finance Private Limited", label: "Gulsan Finance Private Limited" },
  { value: "Decent Securities Private Limited", label: "Decent Securities Private Limited" },
  { value: "S V Creditline Pvt. Ltd.", label: "S V Creditline Pvt. Ltd." },
  { value: "Bhagyalaxmi Nag Sahakari Pat Maryadit", label: "Bhagyalaxmi Nag Sahakari Pat Maryadit" },
  { value: "Jal Janta Sah Bank Ltd", label: "Jal Janta Sah Bank Ltd" },
  { value: "Shree Samarth Sah Bank Ltd", label: "Shree Samarth Sah Bank Ltd" },
  { value: "Solar Energy Corporation Of India", label: "Solar Energy Corporation Of India" },
  { value: "Aroma Biotech Pvt Ltd", label: "Aroma Biotech Pvt Ltd" },
  { value: "Sacred Heart Sou Cr Co Op Ltd", label: "Sacred Heart Sou Cr Co Op Ltd" },
  { value: "Balveer Ur Co Op Cr Soc Ltd", label: "Balveer Ur Co Op Cr Soc Ltd" },
  { value: "Yt Engineers India Pvt Ltd", label: "Yt Engineers India Pvt Ltd" },
  { value: "Cholamandalam Inv.Fin Ser Ltd", label: "Cholamandalam Inv.Fin Ser Ltd" },
  { value: "Sai Point Finance Ltd", label: "Sai Point Finance Ltd" },
  { value: "Aradhyaa Financiers", label: "Aradhyaa Financiers" },
  { value: "Doha Bank", label: "Doha Bank" },
  { value: "Sumeet Enterprises", label: "Sumeet Enterprises" },
  { value: "Home Credit India Finance Private Limited", label: "Home Credit India Finance Private Limited" },
  { value: "Kurukshetra University", label: "Kurukshetra University" },
  { value: "Venkat Ramana Auto Finance", label: "Venkat Ramana Auto Finance" },
  { value: "New India Finance Co.", label: "New India Finance Co." },
  { value: "Nagina Hire Puchase Finance", label: "Nagina Hire Puchase Finance" },
  { value: "Dheer Enterprises", label: "Dheer Enterprises" },
  { value: "Nissan Renault Financial Services India Pvt Ltd.", label: "Nissan Renault Financial Services India Pvt Ltd." },
  { value: "Toyota Financial Services Ltd", label: "Toyota Financial Services Ltd" },
  { value: "Saraswat Sahakari Bank Ltd", label: "Saraswat Sahakari Bank Ltd" },
  { value: "Saraswati Sahakari Bank Ltd.", label: "Saraswati Sahakari Bank Ltd." },
  { value: "Jai Kisan Gramin Big Sah Patsants Mrdt", label: "Jai Kisan Gramin Big Sah Patsants Mrdt" },
  { value: "Shri Veerabhadreshwar Cresit Souhard Sahakari Ltd", label: "Shri Veerabhadreshwar Cresit Souhard Sahakari Ltd" },
  { value: "Swami Swarupanand Sha Pat Ltd", label: "Swami Swarupanand Sha Pat Ltd" },
  { value: "Bmc", label: "Bmc" },
  { value: "Indusind Marketing And Financial Services Pvt Ltd", label: "Indusind Marketing And Financial Services Pvt Ltd" },
  { value: "Khodiyar Finance", label: "Khodiyar Finance" },
  { value: "Capital Small Finance Bank Ltd", label: "Capital Small Finance Bank Ltd" },
  { value: "Prem Hire Purchase", label: "Prem Hire Purchase" },
  { value: "Shri Kalmeshwar Vividoddeshagala Sou Sah Nyt", label: "Shri Kalmeshwar Vividoddeshagala Sou Sah Nyt" },
  { value: "Grover Auto Deals", label: "Grover Auto Deals" },
  { value: "Shivaji Nagari Sahakari Bank Ltd.", label: "Shivaji Nagari Sahakari Bank Ltd." },
  { value: "Mizoram Co Op Apex Bank Ltd", label: "Mizoram Co Op Apex Bank Ltd" },
  { value: "Anupam Nirman Pvt Ltd", label: "Anupam Nirman Pvt Ltd" },
  { value: "Vijaya Co Op Bank", label: "Vijaya Co Op Bank" },
  { value: "Sbi Consortium Sbi, Idbi, Axis Bank, Icici Bank, Hdfc Bank, Ubi,Kotak Mahindra Bank, Dena Bank", label: "Sbi Consortium Sbi, Idbi, Axis Bank, Icici Bank, Hdfc Bank, Ubi,Kotak Mahindra Bank, Dena Bank" },
  { value: "Hinduja Leyland Finance Ltd", label: "Hinduja Leyland Finance Ltd" },
  { value: "Kumaon Urban Co Op Credit Society Ltd", label: "Kumaon Urban Co Op Credit Society Ltd" },
  { value: "Shri Suganmalji Surana Vyapari Sahakari Patsanstha Maryadit", label: "Shri Suganmalji Surana Vyapari Sahakari Patsanstha Maryadit" },
  { value: "Priyadarshani Gramin Bigarsheti Sahkari Patsanstha Maryadit Rukadi", label: "Priyadarshani Gramin Bigarsheti Sahkari Patsanstha Maryadit Rukadi" },
  { value: "Wakhar Bhag Nagari Sah Pat Sanstha Maryadit", label: "Wakhar Bhag Nagari Sah Pat Sanstha Maryadit" },
  { value: "Madhuganga Nagri Pat Sahkari Sanstha Maryadit", label: "Madhuganga Nagri Pat Sahkari Sanstha Maryadit" },
  { value: "Shanti Bai Finance", label: "Shanti Bai Finance" },
  { value: "Idfc Infra Debt Fund Ltd", label: "Idfc Infra Debt Fund Ltd" },
  { value: "Hdfc Housing Finance Corporation Limited", label: "Hdfc Housing Finance Corporation Limited" },
  { value: "Anil Agarwal And Sons Huf", label: "Anil Agarwal And Sons Huf" },
  { value: "Micro Asset Management Services", label: "Micro Asset Management Services" },
  { value: "K.K Enterprise", label: "K.K Enterprise" },
  { value: "Saheb Enterprise", label: "Saheb Enterprise" },
  { value: "Kalchuri Co Op Credit Society Limited", label: "Kalchuri Co Op Credit Society Limited" },
  { value: "Prathmik Krushi Pattin Sahakari Sangh", label: "Prathmik Krushi Pattin Sahakari Sangh" },
  { value: "Kanaka Durga Finance Ltd", label: "Kanaka Durga Finance Ltd" },
  { value: "G.N.F.C. Ltd", label: "G.N.F.C. Ltd" },
  { value: "Icl Fincorp Ltd. Irinjalakuda Credits And Leasing Company Ltd", label: "Icl Fincorp Ltd. Irinjalakuda Credits And Leasing Company Ltd" },
  { value: "Malwa Ldh Motors Fin Ltd", label: "Malwa Ldh Motors Fin Ltd" },
  { value: "Shangrila Financial Services Pvt Ltd", label: "Shangrila Financial Services Pvt Ltd" },
  { value: "Sindhu Nagari Sah Pat Sanstha", label: "Sindhu Nagari Sah Pat Sanstha" },
  { value: "Shree Mahalaxmi Auto Agency", label: "Shree Mahalaxmi Auto Agency" },
  { value: "Jyoti Finance Services", label: "Jyoti Finance Services" },
  { value: "The Gabat Nagrik Sahkari Sharafi Mandali Ltd", label: "The Gabat Nagrik Sahkari Sharafi Mandali Ltd" },
  { value: "Shri Mahatma Basveshwar Credit Sohard Sah Niymit Nipani Chikodi Belgaum", label: "Shri Mahatma Basveshwar Credit Sohard Sah Niymit Nipani Chikodi Belgaum" },
  { value: "Padma Bhushan Vasantdada Patil Sahakari Pathsantha Maryadit", label: "Padma Bhushan Vasantdada Patil Sahakari Pathsantha Maryadit" },
  { value: "Volvo Asset Finance India Pvt Ltd", label: "Volvo Asset Finance India Pvt Ltd" },
  { value: "Singhati S.K.U. S Ltd", label: "Singhati S.K.U. S Ltd" },
  { value: "The Annasaheb Sawant Co Op Urban Bank Ltd", label: "The Annasaheb Sawant Co Op Urban Bank Ltd" },
  { value: "The Nagonda Dist.Co Op.Central Bank Ltd.", label: "The Nagonda Dist.Co Op.Central Bank Ltd." },
  { value: "Gazipur Gram Panchayat Skus Ltd.", label: "Gazipur Gram Panchayat Skus Ltd." },
  { value: "Chintamani Co Op Credit Society Ltd", label: "Chintamani Co Op Credit Society Ltd" },
  { value: "Sagari Masemari Sahakari Patpedhi Ltd", label: "Sagari Masemari Sahakari Patpedhi Ltd" },
  { value: "The Panol Group Seva Sah Mandali Bank Ltd", label: "The Panol Group Seva Sah Mandali Bank Ltd" },
  { value: "Navdeep Corporation", label: "Navdeep Corporation" },
  { value: "Janata Grameen Bigar Sheti Saha Pat Sanstha Mydt", label: "Janata Grameen Bigar Sheti Saha Pat Sanstha Mydt" },
  { value: "Manappuram Finance Limited", label: "Manappuram Finance Limited" },
  { value: "C J Finance Services Ltd", label: "C J Finance Services Ltd" },
  { value: "The Erode District Central Co Op Bank", label: "The Erode District Central Co Op Bank" },
  { value: "Kolindre Dhanlaxmi Co Op Credit Society Ltd Mumbai", label: "Kolindre Dhanlaxmi Co Op Credit Society Ltd Mumbai" },
  { value: "St.Milagres Credit Sou Co Op Ltd", label: "St.Milagres Credit Sou Co Op Ltd" },
  { value: "Somjai Nagri Co Oprative Credit Soc Ltd", label: "Somjai Nagri Co Oprative Credit Soc Ltd" },
  { value: "Modi And Sons Fin And Money Lenders", label: "Modi And Sons Fin And Money Lenders" },
  { value: "Krushna Koyana Nagari Sahakari Path Sanstha Mydt", label: "Krushna Koyana Nagari Sahakari Path Sanstha Mydt" },
  { value: "Niraj Co Op Credit Soc", label: "Niraj Co Op Credit Soc" },
  { value: "Valmiki Nagari Co Oprative Credit Soc Ltd", label: "Valmiki Nagari Co Oprative Credit Soc Ltd" },
  { value: "Anubandh Adivasi Nagari Patsanshtha Maryadit", label: "Anubandh Adivasi Nagari Patsanshtha Maryadit" },
  { value: "Srei Equipment Finance Ltd In Consortium Of Bankers With Uco Bank As A Lead Banker", label: "Srei Equipment Finance Ltd In Consortium Of Bankers With Uco Bank As A Lead Banker" },
  { value: "Karan Kamal Lalchandani", label: "Karan Kamal Lalchandani" },
  { value: "Omprakash Deora Peoples Co Op Bank Ltd", label: "Omprakash Deora Peoples Co Op Bank Ltd" },
  { value: "Poornima Co Oprative Credit Soc Ltd", label: "Poornima Co Oprative Credit Soc Ltd" },
  { value: "The Burdwan Central Cooperative Bank Ltd", label: "The Burdwan Central Cooperative Bank Ltd" },
  { value: "Airways Emp Co Op Credit Soc Ltd", label: "Airways Emp Co Op Credit Soc Ltd" },
  { value: "Sai Sanjivani Co Op Babk Ltd", label: "Sai Sanjivani Co Op Babk Ltd" },
  { value: "Equitas Small Finance Bank Ltd", label: "Equitas Small Finance Bank Ltd" },
  { value: "Shri D T Patil Co Op Bank Ltd", label: "Shri D T Patil Co Op Bank Ltd" },
  { value: "Metrocity Finance P Ltd", label: "Metrocity Finance P Ltd" },
  { value: "Optimus Finance Limited", label: "Optimus Finance Limited" },
  { value: "Chaplot Investments Pvt Ltd", label: "Chaplot Investments Pvt Ltd" },
  { value: "Chaitanya Rural Intermedation Dev Ser Pvt Ltd", label: "Chaitanya Rural Intermedation Dev Ser Pvt Ltd" },
  { value: "The Laxmanpura Gamni Seva Sahakari Man Ltd", label: "The Laxmanpura Gamni Seva Sahakari Man Ltd" },
  { value: "Balotra Urban Co Operative Bank Ltd", label: "Balotra Urban Co Operative Bank Ltd" },
  { value: "The Kalna Town Co Operative Bank Ltd", label: "The Kalna Town Co Operative Bank Ltd" },
  { value: "Om Datta Chaitanya Sahakari Bank Ltd.", label: "Om Datta Chaitanya Sahakari Bank Ltd." },
  { value: "Shree Mahalaxmi Nagri Co Oprative Credit Soc Ltd", label: "Shree Mahalaxmi Nagri Co Oprative Credit Soc Ltd" },
  { value: "Avanse Financial Service Ltd", label: "Avanse Financial Service Ltd" },
  { value: "Svc Co Operative Bank Ltd", label: "Svc Co Operative Bank Ltd" },
  { value: "The Greater Bombay Co Operative Bank Limited", label: "The Greater Bombay Co Operative Bank Limited" },
  { value: "Catalyst Trusteeship Limited", label: "Catalyst Trusteeship Limited" },
  { value: "Shri Bhausaheb Thorat Amrutvahini Sahakari Bank Ltd", label: "Shri Bhausaheb Thorat Amrutvahini Sahakari Bank Ltd" },
  { value: "Everon Power Ltd", label: "Everon Power Ltd" },
  { value: "Mid India Finance Corporation", label: "Mid India Finance Corporation" },
  { value: "The Jaipur Central Co Operative Bank Ltd", label: "The Jaipur Central Co Operative Bank Ltd" },
  { value: "Soubhagya Mahila Souhardha Sahakari Bank Niyamith", label: "Soubhagya Mahila Souhardha Sahakari Bank Niyamith" },
  { value: "Yuva Utkarsh Co Op Credit Society Ltd", label: "Yuva Utkarsh Co Op Credit Society Ltd" },
  { value: "Shiv Kala Urban Co Op Credit Society Ltd", label: "Shiv Kala Urban Co Op Credit Society Ltd" },
  { value: "Standard Chartered Investments And Loans India Ltd", label: "Standard Chartered Investments And Loans India Ltd" },
  { value: "Jogindra Central Co .Op Bank Ltd", label: "Jogindra Central Co .Op Bank Ltd" },
  { value: "Smartcity Souhard Sahakari Niyamit", label: "Smartcity Souhard Sahakari Niyamit" },
  { value: "Navjeevan Co Op Credit Society Ltd", label: "Navjeevan Co Op Credit Society Ltd" },
  { value: "Edelweiss Assets Reconstruction Company Limited", label: "Edelweiss Assets Reconstruction Company Limited" },
  { value: "Kundarnad Mahatma Gandhi Urban Co Op Credit Society Ltd", label: "Kundarnad Mahatma Gandhi Urban Co Op Credit Society Ltd" },
  { value: "The Bhuj Mercantile Co Op Bank Ltd.", label: "The Bhuj Mercantile Co Op Bank Ltd." },
  { value: "Manpreet Enterprises.", label: "Manpreet Enterprises." },
  { value: "Janata Samabai Krishi Unnayan Samiti Ltd", label: "Janata Samabai Krishi Unnayan Samiti Ltd" },
  { value: "Tab Capital Ltd", label: "Tab Capital Ltd" },
  { value: "Shri Gareshwar Sahakari Patsanstha Maryadit", label: "Shri Gareshwar Sahakari Patsanstha Maryadit" },
  { value: "Sangli District Primary Teachers Co Op Bank Ltd", label: "Sangli District Primary Teachers Co Op Bank Ltd" },
  { value: "Capital Small Finance Bank Ltd.", label: "Capital Small Finance Bank Ltd." },
  { value: "Mansa Urben Co Operative Bank Ltd", label: "Mansa Urben Co Operative Bank Ltd" },
  { value: "Xander Finance Private Ltd", label: "Xander Finance Private Ltd" },
  { value: "Ikb Deusche Industrl", label: "Ikb Deusche Industrl" },
  { value: "Lessor Ge Capital Services", label: "Lessor Ge Capital Services" },
  { value: "Nedumpillil Financial Company Ltd", label: "Nedumpillil Financial Company Ltd" },
  { value: "Highway Auto Finance", label: "Highway Auto Finance" },
  { value: "Dhanlaxmi Bank Ltd.", label: "Dhanlaxmi Bank Ltd." },
  { value: "The Arya Vaishya Co Operative Bank Ltd", label: "The Arya Vaishya Co Operative Bank Ltd" },
  { value: "Eqitas Small Finance Bank", label: "Eqitas Small Finance Bank" },
  { value: "Ess Kay Fincorp Private Limited", label: "Ess Kay Fincorp Private Limited" },
  { value: "The Revdanda Co Oprative Urban Bank Ltd", label: "The Revdanda Co Oprative Urban Bank Ltd" },
  { value: "Malwa Gramin Bank.", label: "Malwa Gramin Bank." },
  { value: "Jai Bhagwati Hire Purchase Company", label: "Jai Bhagwati Hire Purchase Company" },
  { value: "Shri Chatrapati Vikas Seva Sanstha Mydt", label: "Shri Chatrapati Vikas Seva Sanstha Mydt" },
  { value: "Swatantrata Senani Kai Shripal Alase K K Urban Co Op Bank Ltd", label: "Swatantrata Senani Kai Shripal Alase K K Urban Co Op Bank Ltd" },
  { value: "Shree Jogeshwari Marchant Co Oprative Credit Soc Ltd", label: "Shree Jogeshwari Marchant Co Oprative Credit Soc Ltd" },
  { value: "Assistance Divisonal Railway Manager", label: "Assistance Divisonal Railway Manager" },
  { value: "Shrinath Credit Co Op Soc Ltd", label: "Shrinath Credit Co Op Soc Ltd" },
  { value: "Shiva Credit Sou Sah Niy", label: "Shiva Credit Sou Sah Niy" },
  { value: "Kondavashi Kanadewadi Nagari Sahakari Pathsanstha Ltd", label: "Kondavashi Kanadewadi Nagari Sahakari Pathsanstha Ltd" },
  { value: "Sindgi Urban Co Operative Bank Ltd.", label: "Sindgi Urban Co Operative Bank Ltd." },
  { value: "Manaba Finance Ltd", label: "Manaba Finance Ltd" },
  { value: "S H Finance And Credit Pvt Ltd", label: "S H Finance And Credit Pvt Ltd" },
  { value: "Mizoram Rural Bank.", label: "Mizoram Rural Bank." },
  { value: "Lessor Leaseplan India Pvt Ltd", label: "Lessor Leaseplan India Pvt Ltd" },
  { value: "Maratha Mandir Nagari Sahakari Patpedhi Maryadit", label: "Maratha Mandir Nagari Sahakari Patpedhi Maryadit" },
  { value: "Mamasaheb Pawar Satyavijay Co Op Bank Ltd", label: "Mamasaheb Pawar Satyavijay Co Op Bank Ltd" },
  { value: "Reliance Commercial Finance Ltd", label: "Reliance Commercial Finance Ltd" },
  { value: "Jharkhand State Scheduled Caste Cooperative Development Corporation", label: "Jharkhand State Scheduled Caste Cooperative Development Corporation" },
  { value: "Busan Auto Finance Ltd", label: "Busan Auto Finance Ltd" },
  { value: "Omerga Janata Sahakari Bank Ltd.", label: "Omerga Janata Sahakari Bank Ltd." },
  { value: "Standard Urban Co Op Bank", label: "Standard Urban Co Op Bank" },
  { value: "Rajpriya Mahila Nagri Sah Patsanstha Ltd", label: "Rajpriya Mahila Nagri Sah Patsanstha Ltd" },
  { value: "Shree Veer Transport Co Op Credit And Service Society Ltd", label: "Shree Veer Transport Co Op Credit And Service Society Ltd" },
  { value: "Telangana State Co Operative Apex Bank Ltd", label: "Telangana State Co Operative Apex Bank Ltd" },
  { value: "Au Small Finance Bank", label: "Au Small Finance Bank" },
  { value: "Equitas Small Financier Bank", label: "Equitas Small Financier Bank" },
  { value: "Birwadi Gramin Bigarsheti Sahkari Patsanstha Ltd", label: "Birwadi Gramin Bigarsheti Sahkari Patsanstha Ltd" },
  { value: "Abba Saheb Datta Pawar Gbs Path Soc Ltd", label: "Abba Saheb Datta Pawar Gbs Path Soc Ltd" },
  { value: "Shivaji Bhosale Sahakari Bank Ltd", label: "Shivaji Bhosale Sahakari Bank Ltd" },
  { value: "Anand Rathi Global Financial Services Limited", label: "Anand Rathi Global Financial Services Limited" },
  { value: "Anand Rathi Global Finance Limited", label: "Anand Rathi Global Finance Limited" },
  { value: "Metro Multistate Urban Credit Co Op Society Ltd", label: "Metro Multistate Urban Credit Co Op Society Ltd" },
  { value: "Mavenir Systems Pvt Ltd", label: "Mavenir Systems Pvt Ltd" },
  { value: "Indira Mahila Sahakari Bank Ltd", label: "Indira Mahila Sahakari Bank Ltd" },
  { value: "Universal Fingrowth Private Limited", label: "Universal Fingrowth Private Limited" },
  { value: "The Ojhar Merchants Co Op. Bank Ltd", label: "The Ojhar Merchants Co Op. Bank Ltd" },
  { value: "Almora Zila Sahkari Bank Ltd", label: "Almora Zila Sahkari Bank Ltd" },
  { value: "Capsave Finance Private Limited", label: "Capsave Finance Private Limited" },
  { value: "Shobha Kanwar", label: "Shobha Kanwar" },
  { value: "The S.S.K. Co Operative Bank Ltd", label: "The S.S.K. Co Operative Bank Ltd" },
  { value: "Mann Deshi Mahila Sahkari Bank Ltd", label: "Mann Deshi Mahila Sahkari Bank Ltd" },
  { value: "Baramati Co Operative Bank", label: "Baramati Co Operative Bank" },
  { value: "Dynamic Fincap Private Limited", label: "Dynamic Fincap Private Limited" },
  { value: "The Gurdaspur Central Cooperative Bank Limited", label: "The Gurdaspur Central Cooperative Bank Limited" },
  { value: "Lessor Mercury Car Rentals Pvt Ltd", label: "Lessor Mercury Car Rentals Pvt Ltd" },
  { value: "Shree Seva Sahkari Mandali Limited", label: "Shree Seva Sahkari Mandali Limited" },
  { value: "Yugandhar Sahakari Patpedhi Maryadit", label: "Yugandhar Sahakari Patpedhi Maryadit" },
  { value: "Clix Finance India Unlimited", label: "Clix Finance India Unlimited" },
  { value: "Repco Home Finance", label: "Repco Home Finance" },
  { value: "Kasturi Investment", label: "Kasturi Investment" },
  { value: "Jagruti Sahakari Patpedhi Maryadit", label: "Jagruti Sahakari Patpedhi Maryadit" },
  { value: "The Kolar And Chikballapur District Co Operative Central Bank Ltd", label: "The Kolar And Chikballapur District Co Operative Central Bank Ltd" },
  { value: "The Malleswaram Co Operative Bank Ltd", label: "The Malleswaram Co Operative Bank Ltd" },
  { value: "Sanmathi Prathamik Krushi Pattin Sah Sangh Niy", label: "Sanmathi Prathamik Krushi Pattin Sah Sangh Niy" },
  { value: "Wheelsemi Pvt Ltd", label: "Wheelsemi Pvt Ltd" },
  { value: "Alphera Financial Services", label: "Alphera Financial Services" },
  { value: "Il And Fs Maritime Infra Company Lt", label: "Il And Fs Maritime Infra Company Lt" },
  { value: "Sane Guruji Gramin Sahakari Patsanstha", label: "Sane Guruji Gramin Sahakari Patsanstha" },
  { value: "The Bhadaran Pepole Co Op Bank Ltd", label: "The Bhadaran Pepole Co Op Bank Ltd" },
  { value: "The Rajula Nagarik Sahakari Bank Ltd", label: "The Rajula Nagarik Sahakari Bank Ltd" },
  { value: "Bhimashankar Sahkari Pathpedi", label: "Bhimashankar Sahkari Pathpedi" },
  { value: "Om Sairam Co Op Credit Society", label: "Om Sairam Co Op Credit Society" },
  { value: "Shri Rajkot District Co Oprative Bank Ltd", label: "Shri Rajkot District Co Oprative Bank Ltd" },
  { value: "Eco Fin", label: "Eco Fin" },
  { value: "Jay Gagangiri Pat Maryadit", label: "Jay Gagangiri Pat Maryadit" },
  { value: "India Infradebt Limited", label: "India Infradebt Limited" },
  { value: "Hsbc Bank Mauritius Limited", label: "Hsbc Bank Mauritius Limited" },
  { value: "The Mercantile Co Op Urban Bank Ltd", label: "The Mercantile Co Op Urban Bank Ltd" },
  { value: "Vistra Itcl India Limited.", label: "Vistra Itcl India Limited." },
  { value: "Maxvalue Credits And Investments Ltd", label: "Maxvalue Credits And Investments Ltd" },
  { value: "Ayavana Rural Co Operative Bank Ltd", label: "Ayavana Rural Co Operative Bank Ltd" },
  { value: "Ms Suraksha Asset", label: "Ms Suraksha Asset" },
  { value: "Ujjivan Small Finance Bank", label: "Ujjivan Small Finance Bank" },
  { value: "Vistra Itcl India Limited", label: "Vistra Itcl India Limited" },
  { value: "M/S Aeon Credit Service India Pvt Ltd", label: "M/S Aeon Credit Service India Pvt Ltd" },
  { value: "Vyapari Sahakari Bank Ltd", label: "Vyapari Sahakari Bank Ltd" },
  { value: "Veer Tr Co Op Credit Society", label: "Veer Tr Co Op Credit Society" },
  { value: "Fullerton India Home Finance Company Ltd", label: "Fullerton India Home Finance Company Ltd" },
  { value: "Reliable Enterprises", label: "Reliable Enterprises" },
  { value: "Dahisar Kalptaru Co Op Credit Soc Ltd", label: "Dahisar Kalptaru Co Op Credit Soc Ltd" },
  { value: "Mass Financial Service Ltd", label: "Mass Financial Service Ltd" },
  { value: "The Angul United Central Co Operative Bank Ltd", label: "The Angul United Central Co Operative Bank Ltd" },
  { value: "Indostar Capital Finance Limited", label: "Indostar Capital Finance Limited" },
  { value: "The C D Co Op Credit Society Ltd", label: "The C D Co Op Credit Society Ltd" },
  { value: "Tumkur Merchants Credit Co Operative Limited", label: "Tumkur Merchants Credit Co Operative Limited" },
  { value: "K.R.Enterprise", label: "K.R.Enterprise" },
  { value: "Rashtrasant Aacharya Anand Rhu.Ma.N", label: "Rashtrasant Aacharya Anand Rhu.Ma.N" },
  { value: "Kaular Co Op Credit Soc Ltd", label: "Kaular Co Op Credit Soc Ltd" },
  { value: "Krishna Lease And Finance Co", label: "Krishna Lease And Finance Co" },
  { value: "The Pavagada Souharda Multipurpose Co Op Ltd", label: "The Pavagada Souharda Multipurpose Co Op Ltd" },
  { value: "Punjab Reliable Investment Private Limited", label: "Punjab Reliable Investment Private Limited" },
  { value: "Vasantrao Naik Nagari Saha Patsanstha Maryadit", label: "Vasantrao Naik Nagari Saha Patsanstha Maryadit" },
  { value: "The Aurangabad District Industrial And Urban Co Op Bank Ltd", label: "The Aurangabad District Industrial And Urban Co Op Bank Ltd" },
  { value: "Mehta Trading Co", label: "Mehta Trading Co" },
  { value: "Esaf Small Finance Bank", label: "Esaf Small Finance Bank" },
  { value: "Ess Kay Fincorp Limited", label: "Ess Kay Fincorp Limited" },
  { value: "Adani Capital Pvt Ltd", label: "Adani Capital Pvt Ltd" },
  { value: "Indiabulls Commercial Credit Ltd", label: "Indiabulls Commercial Credit Ltd" },
  { value: "Sri Parasakthi Credit And Investments", label: "Sri Parasakthi Credit And Investments" },
  { value: "Jandaulat Co Op Crdit Soc Ltd", label: "Jandaulat Co Op Crdit Soc Ltd" },
  { value: "Shri Sai Gramin Bigarsheti Sahakari Pathsanstha Maryadit", label: "Shri Sai Gramin Bigarsheti Sahakari Pathsanstha Maryadit" },
  { value: "Jm Financial Credit Solutions", label: "Jm Financial Credit Solutions" },
  { value: "Jm Financial Product Limited", label: "Jm Financial Product Limited" },
  { value: "Saraswati Enterprises", label: "Saraswati Enterprises" },
  { value: "Dhyeypurti Sah Path Ltd", label: "Dhyeypurti Sah Path Ltd" },
  { value: "Shri Saikrupa Co Op Credit Soc Ltd", label: "Shri Saikrupa Co Op Credit Soc Ltd" },
  { value: "Devchaya Co Op Credit Soc. Ltd", label: "Devchaya Co Op Credit Soc. Ltd" },
  { value: "Piramal Trusteeship Services Private Limited", label: "Piramal Trusteeship Services Private Limited" },
  { value: "Orange Retail Finance India Pvt Ltd", label: "Orange Retail Finance India Pvt Ltd" },
  { value: "Dhubulia S.K.U.S Ltd", label: "Dhubulia S.K.U.S Ltd" },
  { value: "Visu Leasing And Financier Pvt Ltd", label: "Visu Leasing And Financier Pvt Ltd" },
  { value: "The Ganganagar Kendriya Sahakari Bank", label: "The Ganganagar Kendriya Sahakari Bank" },
  { value: "Bajirao Patil Nagari Pathsanstha Ltd", label: "Bajirao Patil Nagari Pathsanstha Ltd" },
  { value: "Maa Maiharwali Co Op Credit Soc Ltd", label: "Maa Maiharwali Co Op Credit Soc Ltd" },
  { value: "Mangaldeep Sahakari Path Ltd", label: "Mangaldeep Sahakari Path Ltd" },
  { value: "Visu Leasing And Finance Pvt Ltd", label: "Visu Leasing And Finance Pvt Ltd" },
  { value: "Barota Finance Ltd", label: "Barota Finance Ltd" },
  { value: "Shri Kanaka Noukarar Pattin Souhard Sahakari Ni", label: "Shri Kanaka Noukarar Pattin Souhard Sahakari Ni" },
  { value: "Shree Maruti Pattina Souharda Sahakari Niyamit", label: "Shree Maruti Pattina Souharda Sahakari Niyamit" },
  { value: "Kammavari Pattina Souharda Sahakari Ni", label: "Kammavari Pattina Souharda Sahakari Ni" },
  { value: "Shri Dharmasthala Manjunath Pattina Souharda Sahakari Ni", label: "Shri Dharmasthala Manjunath Pattina Souharda Sahakari Ni" },
  { value: "Vkg Auto Financiers", label: "Vkg Auto Financiers" },
  { value: "Pahal Financial Services Pvt Ltd", label: "Pahal Financial Services Pvt Ltd" },
  { value: "Lavanya Finance Corporation", label: "Lavanya Finance Corporation" },
  { value: "Shubham Co Op Credit Soc Ltd", label: "Shubham Co Op Credit Soc Ltd" },
  { value: "Laxmi Enterprises", label: "Laxmi Enterprises" },
  { value: "Mulund Vikas Co Op Credit Soc Ltd", label: "Mulund Vikas Co Op Credit Soc Ltd" },
  { value: "Baitun Nasr Urban Co Op Credit Soc Ltd", label: "Baitun Nasr Urban Co Op Credit Soc Ltd" },
  { value: "D S Integrated Finsec Pvt Ltd", label: "D S Integrated Finsec Pvt Ltd" },
  { value: "Ajinkya Sahakari Pathsanstha Maryadit", label: "Ajinkya Sahakari Pathsanstha Maryadit" },
  { value: "Mariyamman Auto Finance Ltd", label: "Mariyamman Auto Finance Ltd" },
  { value: "Visaline Finance", label: "Visaline Finance" },
  { value: "Sm Finance", label: "Sm Finance" },
  { value: "Dnyanraj Gramin Biger Sheti Sahakar Patsanstha Maryadit", label: "Dnyanraj Gramin Biger Sheti Sahakar Patsanstha Maryadit" },
  { value: "Vel Auto Finanace", label: "Vel Auto Finanace" },
  { value: "National Auto Finance", label: "National Auto Finance" },
  { value: "Wisdom Auto Finance", label: "Wisdom Auto Finance" },
  { value: "Sri Iyyanar Auto Finance", label: "Sri Iyyanar Auto Finance" },
  { value: "Sri Sakthi Finance", label: "Sri Sakthi Finance" },
  { value: "Panvel Peoples Nagari Sahakari Patsanstha Mary", label: "Panvel Peoples Nagari Sahakari Patsanstha Mary" },
  { value: "Evergreen Society Fekamari Finance", label: "Evergreen Society Fekamari Finance" },
  { value: "Deputy Director Of Fisheries", label: "Deputy Director Of Fisheries" },
  { value: "Assistant Director Of Fisheries", label: "Assistant Director Of Fisheries" },
  { value: "Abby Finance", label: "Abby Finance" },
  { value: "Manjali Service Co Operative Bank Ltd", label: "Manjali Service Co Operative Bank Ltd" },
  { value: "Shree Birju Co Op Credit Soc Ltd", label: "Shree Birju Co Op Credit Soc Ltd" },
  { value: "Ars Auto Finance", label: "Ars Auto Finance" },
  { value: "Guhan Auto Finance", label: "Guhan Auto Finance" },
  { value: "Pon Vanjiamman Auto Finance", label: "Pon Vanjiamman Auto Finance" },
  { value: "Atharva Finance", label: "Atharva Finance" },
  { value: "Subamangala Finance", label: "Subamangala Finance" },
  { value: "Sri Balaji Capitals", label: "Sri Balaji Capitals" },
  { value: "The Sangali Sahakari Pathpedhi Maryadit", label: "The Sangali Sahakari Pathpedhi Maryadit" },
  { value: "Kavad Fiance", label: "Kavad Fiance" },
  { value: "Evergreen Society Fekamari", label: "Evergreen Society Fekamari" },
  { value: "Au Small Finance Bank Ltd", label: "Au Small Finance Bank Ltd" },
  { value: "Sri Lasya Auto Finance", label: "Sri Lasya Auto Finance" },
  { value: "Moreshwar Saha. Pat.Ltd", label: "Moreshwar Saha. Pat.Ltd" },
  { value: "Shree Prathamesh Sahakari Pathpedhi Ltd", label: "Shree Prathamesh Sahakari Pathpedhi Ltd" },
  { value: "Akhil Kokan Sahakari Pathpedhi Ltd", label: "Akhil Kokan Sahakari Pathpedhi Ltd" },
  { value: "S M Syndicate", label: "S M Syndicate" },
  { value: "Kallappanna Wade Echlkaranji Janata Sahakari Bank Ltd", label: "Kallappanna Wade Echlkaranji Janata Sahakari Bank Ltd" },
  { value: "Siruguppa Souhardha Pattina Sahakari Niyamitha", label: "Siruguppa Souhardha Pattina Sahakari Niyamitha" },
  { value: "Aphelion Finance Pvt Ltd", label: "Aphelion Finance Pvt Ltd" },
  { value: "Krishna Urban Co Operative Bank Ltd", label: "Krishna Urban Co Operative Bank Ltd" },
  { value: "Gobindapur Panchpota S.K.U.S Ltd", label: "Gobindapur Panchpota S.K.U.S Ltd" },
  { value: "Mari Amman Banker", label: "Mari Amman Banker" },
  { value: "Chaamy Finance", label: "Chaamy Finance" },
  { value: "Sri Malai Naachi Amman Finance", label: "Sri Malai Naachi Amman Finance" },
  { value: "Sri Ambal Auto Finance", label: "Sri Ambal Auto Finance" },
  { value: "Sri Ram City Union Finance", label: "Sri Ram City Union Finance" },
  { value: "Muthoot Money Pvt Ltd", label: "Muthoot Money Pvt Ltd" },
  { value: "Shree Omsai Co Op Credit Soc Ltd", label: "Shree Omsai Co Op Credit Soc Ltd" },
  { value: "Fincare Small Finance Bank", label: "Fincare Small Finance Bank" },
  { value: "Vinayaga Group", label: "Vinayaga Group" },
  { value: "Mariamman Auto Finance", label: "Mariamman Auto Finance" },
  { value: "Vyavasayi Bachat Ev Am Sakh Swavlambi Nidhi Ltd", label: "Vyavasayi Bachat Ev Am Sakh Swavlambi Nidhi Ltd" },
  { value: "Mahant Vanijya Pvt. Ltd", label: "Mahant Vanijya Pvt. Ltd" },
  { value: "Loanzen Finance Pvt Ltd", label: "Loanzen Finance Pvt Ltd" },
  { value: "Toka Multipurpose Cooperative Society", label: "Toka Multipurpose Cooperative Society" },
  { value: "Gabarchar Skus Ltd", label: "Gabarchar Skus Ltd" },
  { value: "Kotharjai Gramin Big Sheti Sah Patsanstha Maryadit", label: "Kotharjai Gramin Big Sheti Sah Patsanstha Maryadit" },
  { value: "Neeraj Co Op Credit Society", label: "Neeraj Co Op Credit Society" },
  { value: "Rameshwar Sah Pathsanstha Maryadit", label: "Rameshwar Sah Pathsanstha Maryadit" },
  { value: "Ravi Fincorp", label: "Ravi Fincorp" },
  { value: "Sri Kongu Thirumagal Finance", label: "Sri Kongu Thirumagal Finance" },
  { value: "Zen Lefin Pvt Ltd", label: "Zen Lefin Pvt Ltd" },
  { value: "The Kerala State Pravasi Welfare Development Co Op Society Ltd", label: "The Kerala State Pravasi Welfare Development Co Op Society Ltd" },
  { value: "Oracle Marketing Private Limited", label: "Oracle Marketing Private Limited" },
  { value: "Dalal Finserve Private Limited", label: "Dalal Finserve Private Limited" },
  { value: "Vinayak Co Op Credit Society", label: "Vinayak Co Op Credit Society" },
  { value: "Sri Thirukumaran Auto Finance", label: "Sri Thirukumaran Auto Finance" },
  { value: "Aadhar Housing Finance Limited", label: "Aadhar Housing Finance Limited" },
  { value: "Yedeshwar G B S P Mar Yedgaon", label: "Yedeshwar G B S P Mar Yedgaon" },
  { value: "Adarsh Mahila Nagari Sahakari Bank Ltd", label: "Adarsh Mahila Nagari Sahakari Bank Ltd" },
  { value: "Daimler India Commercial Vehicles Pvt Ltd", label: "Daimler India Commercial Vehicles Pvt Ltd" },
  { value: "Dhannsri Investment", label: "Dhannsri Investment" },
  { value: "Shri Sadguru Maha Nagari Sah Pat Ma", label: "Shri Sadguru Maha Nagari Sah Pat Ma" },
  { value: "Govindraj Auto Finance", label: "Govindraj Auto Finance" },
  { value: "Sri Sai Maruthi Vehicle Finance", label: "Sri Sai Maruthi Vehicle Finance" },
  { value: "Chennai Investments", label: "Chennai Investments" },
  { value: "Panel Social Service Co Op", label: "Panel Social Service Co Op" },
  { value: "Intesa Sanpaolo S.P.A.", label: "Intesa Sanpaolo S.P.A." },
  { value: "Bharat Bank", label: "Bharat Bank" },
  { value: "Shri Naikba Sahakari Patsanstha Ltd", label: "Shri Naikba Sahakari Patsanstha Ltd" },
  { value: "The Zoroastrain Co Operative Bank Ltd", label: "The Zoroastrain Co Operative Bank Ltd" },
  { value: "Shri Adhavan Auto Finance", label: "Shri Adhavan Auto Finance" },
  { value: "Shrinath Samarth Nagari Sah Pat Ltd", label: "Shrinath Samarth Nagari Sah Pat Ltd" },
  { value: "M S Kanakadurga Finance Ltd", label: "M S Kanakadurga Finance Ltd" },
  { value: "Martin Co Op Society", label: "Martin Co Op Society" },
  { value: "Greshma Finvest Private Ltd", label: "Greshma Finvest Private Ltd" },
  { value: "Shri Hari Madhav Creidt Co Op Society Ltd", label: "Shri Hari Madhav Creidt Co Op Society Ltd" },
  { value: "T.R.B Auto Finance", label: "T.R.B Auto Finance" },
  { value: "Brahmanagar Skus Ltd", label: "Brahmanagar Skus Ltd" },
  { value: "Tattva Fincorp Limited", label: "Tattva Fincorp Limited" },
  { value: "Shree Shreeji Co Op Credit And Saplay Soc", label: "Shree Shreeji Co Op Credit And Saplay Soc" },
  { value: "Profectus Capital Pvt Ltd", label: "Profectus Capital Pvt Ltd" },
  { value: "Rbsg Capital Private Limited", label: "Rbsg Capital Private Limited" },
  { value: "Junnar Parner Sahakari Pathpedhi Maryadit", label: "Junnar Parner Sahakari Pathpedhi Maryadit" },
  { value: "The Maharashtra Aikya Paraspar Sahakari Mandal Pathpedhi Ltd", label: "The Maharashtra Aikya Paraspar Sahakari Mandal Pathpedhi Ltd" },
  { value: "The Texco Co Operative Bank Ltd", label: "The Texco Co Operative Bank Ltd" },
  { value: "Sojitra Co Op Credit Society Ltd", label: "Sojitra Co Op Credit Society Ltd" },
  { value: "Sri Mani Vigneswara Auto Finance", label: "Sri Mani Vigneswara Auto Finance" },
  { value: "Mulganga Gramin Bigarsheti Co Op Society Ltd", label: "Mulganga Gramin Bigarsheti Co Op Society Ltd" },
  { value: "Power Finance Corporation Limited", label: "Power Finance Corporation Limited" },
  { value: "Navchaitanya Nagari Sah Pat Sans May", label: "Navchaitanya Nagari Sah Pat Sans May" },
  { value: "Gic Housing Finance Ltd", label: "Gic Housing Finance Ltd" },
  { value: "Idfc Bank Limited", label: "Idfc Bank Limited" },
  { value: "Samba Siva Vehicle Finance", label: "Samba Siva Vehicle Finance" },
  { value: "Hinduja Finance Ltd", label: "Hinduja Finance Ltd" },
  { value: "Kanakadurga Finance Limited", label: "Kanakadurga Finance Limited" },
  { value: "Raksha Hire Purchase And Financial Services", label: "Raksha Hire Purchase And Financial Services" },
  { value: "Ar Associates", label: "Ar Associates" },
  { value: "Adhilakshmi Auto Finance Agencies", label: "Adhilakshmi Auto Finance Agencies" },
  { value: "Venakata Hanuman Auto Finance", label: "Venakata Hanuman Auto Finance" },
  { value: "Sai Aswartha Enterprise", label: "Sai Aswartha Enterprise" },
  { value: "Sant Sopankaka Sahakari Bank Limited", label: "Sant Sopankaka Sahakari Bank Limited" },
  { value: "Vijayalakshmi Auto Finance", label: "Vijayalakshmi Auto Finance" },
  { value: "Sri Sathya Finance Corporation", label: "Sri Sathya Finance Corporation" },
  { value: "Vikrant Nagari Sah Pat Maryadit", label: "Vikrant Nagari Sah Pat Maryadit" },
  { value: "Sawatamali Nagri Sah Pat Maryadit", label: "Sawatamali Nagri Sah Pat Maryadit" },
  { value: "Bajaj Finance Private Limited", label: "Bajaj Finance Private Limited" },
  { value: "Suryoday Small Finance Bank Limited", label: "Suryoday Small Finance Bank Limited" },
  { value: "Attur Thuluva Velalar Sangam", label: "Attur Thuluva Velalar Sangam" },
  { value: "Sadbhavana Co Operative Credit Society Ltd", label: "Sadbhavana Co Operative Credit Society Ltd" },
  { value: "Cholaa Auto Finance", label: "Cholaa Auto Finance" },
  { value: "Shiva Sagar Leasing", label: "Shiva Sagar Leasing" },
  { value: "Mera Nagari Sahakari Patsanta Maryadit", label: "Mera Nagari Sahakari Patsanta Maryadit" },
  { value: "Irb Invit Fund", label: "Irb Invit Fund" },
  { value: "Karur Finance Pvt Ltd", label: "Karur Finance Pvt Ltd" },
  { value: "Mallikharjuna Financier", label: "Mallikharjuna Financier" },
  { value: "Sree New Bhavani Auto Finance Agency", label: "Sree New Bhavani Auto Finance Agency" },
  { value: "Mayur Gramin Sahakari Patsansta Mar", label: "Mayur Gramin Sahakari Patsansta Mar" },
  { value: "New Siri Enterprises And Auto Finance", label: "New Siri Enterprises And Auto Finance" },
  { value: "Ruby Enterprises", label: "Ruby Enterprises" },
  { value: "Getco Division Dhoraji", label: "Getco Division Dhoraji" },
  { value: "Kundlika Nagari Sahakari Pathsanstha Maryadit", label: "Kundlika Nagari Sahakari Pathsanstha Maryadit" },
  { value: "Punjab Maharashtra Co Op Credit Society", label: "Punjab Maharashtra Co Op Credit Society" },
  { value: "Adharsh Seva Sahakari Pad", label: "Adharsh Seva Sahakari Pad" },
  { value: "Gp Parshik Janta Shahakari Bank Ltd", label: "Gp Parshik Janta Shahakari Bank Ltd" },
  { value: "Deepak Co Op Credit Society Ltd", label: "Deepak Co Op Credit Society Ltd" },
  { value: "Dolphin Money Private Limited", label: "Dolphin Money Private Limited" },
  { value: "Himatsingka Brothers Dumka", label: "Himatsingka Brothers Dumka" },
  { value: "Incred Financial Services Pvt Ltd", label: "Incred Financial Services Pvt Ltd" },
  { value: "Chuakhali S.K.U.S Ltd", label: "Chuakhali S.K.U.S Ltd" },
  { value: "Kalinagar S.K.U.S Ltd", label: "Kalinagar S.K.U.S Ltd" },
  { value: "Gp Parsik Sahakari Bank Ltd", label: "Gp Parsik Sahakari Bank Ltd" },
  { value: "Manoj Kumar Jain Automobile Fin", label: "Manoj Kumar Jain Automobile Fin" },
  { value: "Sree Saranya Finance", label: "Sree Saranya Finance" },
  { value: "Jaibhole Co Op Soc Ltd", label: "Jaibhole Co Op Soc Ltd" },
  { value: "Shri Sharada Sahakari Bank Ltd", label: "Shri Sharada Sahakari Bank Ltd" },
  { value: "Santkrupa Nagri Sahakari Path", label: "Santkrupa Nagri Sahakari Path" },
  { value: "Shripad Vallabh G B S P Mar", label: "Shripad Vallabh G B S P Mar" },
  { value: "Agarwal Finance", label: "Agarwal Finance" },
  { value: "Shalivahan Nagari Pat Sanstha Mary", label: "Shalivahan Nagari Pat Sanstha Mary" },
  { value: "Telangana State Fishermen Cooperative Federation Ltd", label: "Telangana State Fishermen Cooperative Federation Ltd" },
  { value: "Banamalipara Skus Ltd", label: "Banamalipara Skus Ltd" },
  { value: "Ranaghat Peoples Cooperative Bank Ltd", label: "Ranaghat Peoples Cooperative Bank Ltd" },
  { value: "Sarvoday Co Operative Credit Society Limited", label: "Sarvoday Co Operative Credit Society Limited" },
  { value: "Simandhar Finlease Private Limited", label: "Simandhar Finlease Private Limited" },
  { value: "Veyes Group And Finance", label: "Veyes Group And Finance" },
  { value: "Thiru Senthur Murugan Auto Finance", label: "Thiru Senthur Murugan Auto Finance" },
  { value: "Vidharbha Nagari Sahakari Path Sanstah Ltd", label: "Vidharbha Nagari Sahakari Path Sanstah Ltd" },
  { value: "Gayathri Financiers", label: "Gayathri Financiers" },
  { value: "Indiabulls Venture Limited", label: "Indiabulls Venture Limited" },
  { value: "Urmodi Sahakari Patsanstha Maryadit", label: "Urmodi Sahakari Patsanstha Maryadit" },
  { value: "Multimoney Mahila Gramin Bigar Sheti Sahakari Pathsanstha", label: "Multimoney Mahila Gramin Bigar Sheti Sahakari Pathsanstha" },
  { value: "Expressions Portfolio Pvt Ltd", label: "Expressions Portfolio Pvt Ltd" },
  { value: "Jugalkishor Tapdiya Shree Mahesh Urban Co Op Bank Ltd", label: "Jugalkishor Tapdiya Shree Mahesh Urban Co Op Bank Ltd" },
  { value: "Megha Finloan Private Limited", label: "Megha Finloan Private Limited" },
  { value: "Infinity Fincorp Solutions", label: "Infinity Fincorp Solutions" },
  { value: "Amman Fin Service", label: "Amman Fin Service" },
  { value: "Sri Ambal Finance", label: "Sri Ambal Finance" },
  { value: "Sri Thangam Auto Finance", label: "Sri Thangam Auto Finance" },
  { value: "B.V.S Credits", label: "B.V.S Credits" },
  { value: "Adhavan Auto Finances", label: "Adhavan Auto Finances" },
  { value: "Shri Vishwasai Co Op Credit Soc Ltd", label: "Shri Vishwasai Co Op Credit Soc Ltd" },
  { value: "Lokmaniye Tilak Nagari Sahakari Pathshanstha", label: "Lokmaniye Tilak Nagari Sahakari Pathshanstha" },
  { value: "Shri Prathmesh Sahakari Patsanstha", label: "Shri Prathmesh Sahakari Patsanstha" },
  { value: "Sri Kanyaka Auto Financial", label: "Sri Kanyaka Auto Financial" },
  { value: "Smas Auto Leasing India Pvt Ltd", label: "Smas Auto Leasing India Pvt Ltd" },
  { value: "Garsian Nagari Sahakari Patsanstha Maryadit", label: "Garsian Nagari Sahakari Patsanstha Maryadit" },
  { value: "A R Financial Services", label: "A R Financial Services" },
  { value: "B.S Khalshi Para S.K.U.S Ltd", label: "B.S Khalshi Para S.K.U.S Ltd" },
  { value: "Gs Mahanagar Co Operative Bank Ltd", label: "Gs Mahanagar Co Operative Bank Ltd" },
  { value: "Govind Namdev Dusane Nagari Sahkari Patsanstha Maryadit", label: "Govind Namdev Dusane Nagari Sahkari Patsanstha Maryadit" },
  { value: "Sri Gajalakshmi Finance", label: "Sri Gajalakshmi Finance" },
  { value: "Sri Amirtha Auto Finanace", label: "Sri Amirtha Auto Finanace" },
  { value: "Kurar Vaisya Bank Ltd", label: "Kurar Vaisya Bank Ltd" },
  { value: "Pandianad Finance Company Ltd", label: "Pandianad Finance Company Ltd" },
  { value: "Navarathna Financial Services Ltd", label: "Navarathna Financial Services Ltd" },
  { value: "Yasothai Finance", label: "Yasothai Finance" },
  { value: "Dhanushkodi Auto Finance", label: "Dhanushkodi Auto Finance" },
  { value: "Paschim Maligram S.K.U.S Ltd", label: "Paschim Maligram S.K.U.S Ltd" },
  { value: "Vivekananda S.K.U.S Ltd", label: "Vivekananda S.K.U.S Ltd" },
  { value: "Handole S.K.U.S Ltd", label: "Handole S.K.U.S Ltd" },
  { value: "Laxmi India Finleasecap Pvt Ltd", label: "Laxmi India Finleasecap Pvt Ltd" },
  { value: "Parel Social Service Co Op Bank Ltd", label: "Parel Social Service Co Op Bank Ltd" },
  { value: "Satguru Co Op Credit Soc Ltd", label: "Satguru Co Op Credit Soc Ltd" },
  { value: "D S Associates", label: "D S Associates" },
  { value: "Rajarshi Shahu Multi State Co Op Credit", label: "Rajarshi Shahu Multi State Co Op Credit" },
  { value: "Skylark Community Development Society", label: "Skylark Community Development Society" },
  { value: "Rajshri Shahu Multistate Co Op Credit Soc M Buldhana", label: "Rajshri Shahu Multistate Co Op Credit Soc M Buldhana" },
  { value: "Srichakra Finance And Credit Madras Pvt Ltd", label: "Srichakra Finance And Credit Madras Pvt Ltd" },
  { value: "Bhavadharini Finance", label: "Bhavadharini Finance" },
  { value: "Srv Auto Finance", label: "Srv Auto Finance" },
  { value: "Sri Vetri Vinayaga Finance", label: "Sri Vetri Vinayaga Finance" },
  { value: "Banagram S.K.U.S Ltd", label: "Banagram S.K.U.S Ltd" },
  { value: "Devika Urban Co Op Bank Ltd", label: "Devika Urban Co Op Bank Ltd" },
  { value: "Om Sahakari Path Sanstha Maryadit", label: "Om Sahakari Path Sanstha Maryadit" },
  { value: "Dahisar Gramin Bigar Sheti Sahakari Path Maryadit", label: "Dahisar Gramin Bigar Sheti Sahakari Path Maryadit" },
  { value: "Akan Auto Finance", label: "Akan Auto Finance" },
  { value: "Indian Auto Finance", label: "Indian Auto Finance" },
  { value: "Manju Auto Finance", label: "Manju Auto Finance" },
  { value: "Shri Dhokeshwar Gram Big S S P Mrdt", label: "Shri Dhokeshwar Gram Big S S P Mrdt" },
  { value: "Sanjivani Mahila Nagrik Sah Path Sanstha Ltd", label: "Sanjivani Mahila Nagrik Sah Path Sanstha Ltd" },
  { value: "Shri Channagireshwar Souhard Credit Sahakari Niyam", label: "Shri Channagireshwar Souhard Credit Sahakari Niyam" },
  { value: "Utkarsh Small Finance Bank", label: "Utkarsh Small Finance Bank" },
  { value: "Mannapuram Finance Limited", label: "Mannapuram Finance Limited" },
  { value: "Datta Guru Co Op Cr Soc.Ltd", label: "Datta Guru Co Op Cr Soc.Ltd" },
  { value: "Shri Nageshwar Gramin Bigar Sheti Sah Pat Sanstha Maryadt", label: "Shri Nageshwar Gramin Bigar Sheti Sah Pat Sanstha Maryadt" },
  { value: "Pon Sakthi Auto Finance", label: "Pon Sakthi Auto Finance" },
  { value: "Raja Iswar Sankarpur Skus Ltd", label: "Raja Iswar Sankarpur Skus Ltd" },
  { value: "Bir Automobile Finance Pvt Ltd", label: "Bir Automobile Finance Pvt Ltd" },
  { value: "Clix Finance India Pvt Ltd", label: "Clix Finance India Pvt Ltd" },
  { value: "Klm Finance", label: "Klm Finance" },
  { value: "Sitibida S.K.U.S Ltd", label: "Sitibida S.K.U.S Ltd" },
  { value: "Kunal Impex Pvt Ltd", label: "Kunal Impex Pvt Ltd" },
  { value: "Jdm Udyog Pvt Ltd", label: "Jdm Udyog Pvt Ltd" },
  { value: "Bgr Fin Services", label: "Bgr Fin Services" },
  { value: "H.U.D Memorial Ngo", label: "H.U.D Memorial Ngo" },
  { value: "Piramal Capital And Housing Finance", label: "Piramal Capital And Housing Finance" },
  { value: "H K Trading And Finance", label: "H K Trading And Finance" },
  { value: "Indel Money Private Ltd", label: "Indel Money Private Ltd" },
  { value: "The Mettur Chemicals Employees Co Op Society Ltd", label: "The Mettur Chemicals Employees Co Op Society Ltd" },
  { value: "Jammu And Kahmir Grameen Bank", label: "Jammu And Kahmir Grameen Bank" },
  { value: "Credit Wise Capital Private Limited", label: "Credit Wise Capital Private Limited" },
  { value: "Leaseplan India Pvt Ltd", label: "Leaseplan India Pvt Ltd" },
  { value: "Sri Akshaya Financers", label: "Sri Akshaya Financers" },
  { value: "Koraput Central Co Operative Bank Ltd", label: "Koraput Central Co Operative Bank Ltd" },
  { value: "Saptshrungi Mahila Nagari Saha Patsanstha Maryadit", label: "Saptshrungi Mahila Nagari Saha Patsanstha Maryadit" },
  { value: "Hb Financial Services Ltd", label: "Hb Financial Services Ltd" },
  { value: "Capri Global Capital Limited", label: "Capri Global Capital Limited" },
  { value: "The Sabarkantha District Central Co Op Bank Ltd", label: "The Sabarkantha District Central Co Op Bank Ltd" },
  { value: "Subarnabihar S.K.U.S Ltd", label: "Subarnabihar S.K.U.S Ltd" },
  { value: "Tripura Minorities Cooperative Development Corporation Ltd", label: "Tripura Minorities Cooperative Development Corporation Ltd" },
  { value: "Baid Housing Finance Pvt Ltd", label: "Baid Housing Finance Pvt Ltd" },
  { value: "Sethiya Brothers", label: "Sethiya Brothers" },
  { value: "C Pannalal And Co", label: "C Pannalal And Co" },
  { value: "Raj Credit Service", label: "Raj Credit Service" },
  { value: "Tnspl 136 Thenmalai Primary Agricultural Co Op Credit Society", label: "Tnspl 136 Thenmalai Primary Agricultural Co Op Credit Society" },
  { value: "Swa Sawarkar Nagar Nagri Sah Pathy Samaryadit", label: "Swa Sawarkar Nagar Nagri Sah Pathy Samaryadit" },
  { value: "Bharath Financial Solutions", label: "Bharath Financial Solutions" },
  { value: "Beacon Trusteeship Limited", label: "Beacon Trusteeship Limited" },
  { value: "Indryani Co Op Bank Ltd", label: "Indryani Co Op Bank Ltd" },
  { value: "Eswar Associates", label: "Eswar Associates" },
  { value: "Bihar Legislative Assembly", label: "Bihar Legislative Assembly" },
  { value: "Shri Mahakaleshwar Co Op Credit Soc Ltd", label: "Shri Mahakaleshwar Co Op Credit Soc Ltd" },
  { value: "The Panchmahal District Co Operative Bank Ltd", label: "The Panchmahal District Co Operative Bank Ltd" },
  { value: "Bhajan Singh", label: "Bhajan Singh" },
  { value: "Clix Capital Services Pvt Ltd", label: "Clix Capital Services Pvt Ltd" },
  { value: "Kotak Mahindra Investment Ltd", label: "Kotak Mahindra Investment Ltd" },
  { value: "Tripura Obc Cooperative Development Corporation Limited", label: "Tripura Obc Cooperative Development Corporation Limited" },
  { value: "Novartis Finance Limited", label: "Novartis Finance Limited" },
  { value: "Credit Access Grameen Limited", label: "Credit Access Grameen Limited" },
  { value: "Thulasi Krishna Finance", label: "Thulasi Krishna Finance" },
  { value: "Home First Finance Company", label: "Home First Finance Company" },
  { value: "Junner Parner Sah Path", label: "Junner Parner Sah Path" },
  { value: "Shradha Saburi Co Op Cr Soc Ltd", label: "Shradha Saburi Co Op Cr Soc Ltd" },
  { value: "Shankarrao Pujari Nutan Nagari Sahakari Bank Ltd", label: "Shankarrao Pujari Nutan Nagari Sahakari Bank Ltd" },
  { value: "Sri Kongu Auto Finance", label: "Sri Kongu Auto Finance" },
  { value: "Chinmaya Finance Capital Private Ltd", label: "Chinmaya Finance Capital Private Ltd" },
  { value: "Aravade Gramshtha Sahakari Patpedhi Maryadit", label: "Aravade Gramshtha Sahakari Patpedhi Maryadit" },
  { value: "Jana Small Finance Bank", label: "Jana Small Finance Bank" },
  { value: "Shivsharda Co Op Crd Soc Ltd", label: "Shivsharda Co Op Crd Soc Ltd" },
  { value: "Sri Velan Auto Finance", label: "Sri Velan Auto Finance" },
  { value: "Sri Sakthi Vinyaga Auto Finance", label: "Sri Sakthi Vinyaga Auto Finance" },
  { value: "Sri Vaari Auto Finance", label: "Sri Vaari Auto Finance" },
  { value: "Pratikar Bigar Sheti Sah Pat Maryadit", label: "Pratikar Bigar Sheti Sah Pat Maryadit" },
  { value: "Dyayapurti Sahakar Patpedhi Maryadit", label: "Dyayapurti Sahakar Patpedhi Maryadit" },
  { value: "Caparo Financial Solutions Ltd", label: "Caparo Financial Solutions Ltd" },
  { value: "Vasai Pragati Co Operative Credit Society Ltd", label: "Vasai Pragati Co Operative Credit Society Ltd" },
  { value: "Cnh Capital", label: "Cnh Capital" },
  { value: "Ramco Cements Ltd", label: "Ramco Cements Ltd" },
  { value: "Incred Financial Services Ltd", label: "Incred Financial Services Ltd" },
  { value: "Muktabai Gar Big Sheti Sah Path", label: "Muktabai Gar Big Sheti Sah Path" },
  { value: "S S A Finance", label: "S S A Finance" },
  { value: "Sri Senthur Finance", label: "Sri Senthur Finance" },
  { value: "Care Ratings Ltd", label: "Care Ratings Ltd" },
  { value: "Gadkhali S.K.U.S Ltd.", label: "Gadkhali S.K.U.S Ltd." },
  { value: "Edc Ltd", label: "Edc Ltd" },
  { value: "Thosha Finance R", label: "Thosha Finance R" },
  { value: "Idfc First Bank", label: "Idfc First Bank" },
  { value: "Creditwise Capital Pvt Ltd", label: "Creditwise Capital Pvt Ltd" },
  { value: "Dhyayapurti Sahakar Patpedhi Maryadit", label: "Dhyayapurti Sahakar Patpedhi Maryadit" },
  { value: "Kamma Mahila Souharda Credit Co Operative Ltd", label: "Kamma Mahila Souharda Credit Co Operative Ltd" },
  { value: "Sri Barani Auto Finance", label: "Sri Barani Auto Finance" },
  { value: "Csc E Governance Services India Ltd", label: "Csc E Governance Services India Ltd" },
  { value: "Marriott Hotels India Private Limited", label: "Marriott Hotels India Private Limited" },
  { value: "Thiyagikumaran Industrial Workers Co Operative House Construction Society Ltd", label: "Thiyagikumaran Industrial Workers Co Operative House Construction Society Ltd" },
  { value: "Nm Financiers", label: "Nm Financiers" },
  { value: "Government Of Maharashtra", label: "Government Of Maharashtra" },
  { value: "Jaina Mandali Pathina Sahakara Sanga Niyamitha", label: "Jaina Mandali Pathina Sahakara Sanga Niyamitha" },
  { value: "Pochampally Co Op Urban Bank Ltd", label: "Pochampally Co Op Urban Bank Ltd" },
  { value: "Repco Home Finance Limited", label: "Repco Home Finance Limited" },
  { value: "Dinkar Nalawade Nagari Sahkari Pat Santha", label: "Dinkar Nalawade Nagari Sahkari Pat Santha" },
  { value: "Rgicl Pis", label: "Rgicl Pis" },
  { value: "Vijapur Lease Finance", label: "Vijapur Lease Finance" },
  { value: "Commissioner Of Central Goods And Services Tax", label: "Commissioner Of Central Goods And Services Tax" },
  { value: "Amrit Malwa Capital Limited", label: "Amrit Malwa Capital Limited" },
  { value: "Droom Technology Pvt Ltd", label: "Droom Technology Pvt Ltd" },
  { value: "Bajaj Housing Finance Limited", label: "Bajaj Housing Finance Limited" },
  { value: "Venus Group Auto Finance", label: "Venus Group Auto Finance" },
  { value: "The Mumbai City Co Op Credit Soc Ltd", label: "The Mumbai City Co Op Credit Soc Ltd" },
  { value: "Sri Dhanalashmi Finance", label: "Sri Dhanalashmi Finance" },
  { value: "Bank Of India And Consortium", label: "Bank Of India And Consortium" },
  { value: "The Nabapally Co Operative Bank Ltd", label: "The Nabapally Co Operative Bank Ltd" },
  { value: "Asannagar S K U S Ltd", label: "Asannagar S K U S Ltd" },
  { value: "Setia Auto Finance Pvt Ltd", label: "Setia Auto Finance Pvt Ltd" },
  { value: "Jairam Credit Pvt Ltd", label: "Jairam Credit Pvt Ltd" },
  { value: "The Superintendent Of Police Railways Trichy", label: "The Superintendent Of Police Railways Trichy" },
  { value: "Palani Sri Amman Auto Finance", label: "Palani Sri Amman Auto Finance" },
  { value: "Cnh Industrial Capital India Pvt Ltd", label: "Cnh Industrial Capital India Pvt Ltd" },
  { value: "The Fatehgarh Sahib Central Cooperative Bank Ltd", label: "The Fatehgarh Sahib Central Cooperative Bank Ltd" },
  { value: "The Hanumangarh Kendirya Sahakari Bank Ltd", label: "The Hanumangarh Kendirya Sahakari Bank Ltd" },
  { value: "Majdia S.K.U.S Ltd", label: "Majdia S.K.U.S Ltd" },
  { value: "Dhanalekshmi Bank", label: "Dhanalekshmi Bank" },
  { value: "7 Vik Finvestment Pvt Ltd", label: "7 Vik Finvestment Pvt Ltd" },
  { value: "Rvr Invests", label: "Rvr Invests" },
  { value: "Prarthana Co Op Credit Soc Ltd", label: "Prarthana Co Op Credit Soc Ltd" },
  { value: "S S Souharda Co Operative Limited", label: "S S Souharda Co Operative Limited" },
  { value: "Can Fin Homes Ltd", label: "Can Fin Homes Ltd" },
  { value: "Idfc Infrastructure Finance Ltd.", label: "Idfc Infrastructure Finance Ltd." },
  { value: "L And T Infra Debt Fund Ltd", label: "L And T Infra Debt Fund Ltd" },
  { value: "The Patiala Central Cooperative Bank Limited", label: "The Patiala Central Cooperative Bank Limited" },
  { value: "The Annasaheb Co Op Urban Bank Mahad Ltd", label: "The Annasaheb Co Op Urban Bank Mahad Ltd" },
  { value: "S. K. Fincorp Ltd.", label: "S. K. Fincorp Ltd." },
  { value: "Mizuho Bank", label: "Mizuho Bank" },
  { value: "Dbs Bank India Ltd.", label: "Dbs Bank India Ltd." },
  { value: "Manapuram Finance Ltd", label: "Manapuram Finance Ltd" },
  { value: "Aristo Securities Private Ltd", label: "Aristo Securities Private Ltd" },
  { value: "The Raichur District Central Co Op Bank Ltd", label: "The Raichur District Central Co Op Bank Ltd" },
  { value: "Tapdiya Auto Finance", label: "Tapdiya Auto Finance" },
  { value: "Sm Auto Finance", label: "Sm Auto Finance" },
  { value: "Icici Bank Ltd And Srei Equipment Finance Ltd", label: "Icici Bank Ltd And Srei Equipment Finance Ltd" },
  { value: "Jay Ajey Investment Ltd", label: "Jay Ajey Investment Ltd" },
  { value: "Shiv Ratan Enterprises", label: "Shiv Ratan Enterprises" },
  { value: "Pon Selva Kumar Auto Finance", label: "Pon Selva Kumar Auto Finance" },
  { value: "Das Finance Corpoeration", label: "Das Finance Corpoeration" },
  { value: "Jai Maruthi Finance", label: "Jai Maruthi Finance" },
  { value: "Sri Vetrivel Auto Finance", label: "Sri Vetrivel Auto Finance" },
  { value: "The Ramanathapuram District Central Cooperative Bank Ltd", label: "The Ramanathapuram District Central Cooperative Bank Ltd" },
  { value: "The Sadashiv Saving Credit Coop Society Ltd", label: "The Sadashiv Saving Credit Coop Society Ltd" },
  { value: "Radhe Krishna Co Op Credit Society Ltd", label: "Radhe Krishna Co Op Credit Society Ltd" },
  { value: "Jayakrishna Finance", label: "Jayakrishna Finance" },
  { value: "Marathwada Urban Co Op Credit Society Ltd", label: "Marathwada Urban Co Op Credit Society Ltd" },
  { value: "Keshavsmruti Nagari Sahakari Patpedhi Maryadit", label: "Keshavsmruti Nagari Sahakari Patpedhi Maryadit" },
  { value: "The Mahudha Nagarik Sahakari Bank Ltd", label: "The Mahudha Nagarik Sahakari Bank Ltd" },
  { value: "Dnyanganga Sahakari Pathsanstha Ltd", label: "Dnyanganga Sahakari Pathsanstha Ltd" },
  { value: "V R Investments", label: "V R Investments" },
  { value: "Patel Urban Co Op Credit Society Ltd", label: "Patel Urban Co Op Credit Society Ltd" },
  { value: "Madura Finance And Co", label: "Madura Finance And Co" },
  { value: "Manchar Nagari Saha Pathsanstha Ltd", label: "Manchar Nagari Saha Pathsanstha Ltd" },
  { value: "Samarth Mahila Nagari Bigar Sheti Sah Pat San", label: "Samarth Mahila Nagari Bigar Sheti Sah Pat San" },
  { value: "Sri Kurinchi Auto Finance", label: "Sri Kurinchi Auto Finance" },
  { value: "Upmoney Pvt Ltd", label: "Upmoney Pvt Ltd" },
  { value: "M J S Credits", label: "M J S Credits" },
  { value: "Sri Mjs Finance", label: "Sri Mjs Finance" },
  { value: "Napa Vata Mercantile Co Op Credit Society Ltd", label: "Napa Vata Mercantile Co Op Credit Society Ltd" },
  { value: "Sri Shivanmalai Andavar Auto Finance", label: "Sri Shivanmalai Andavar Auto Finance" },
  { value: "R.A Auto Finance", label: "R.A Auto Finance" },
  { value: "Sree Dsk Auto Finance", label: "Sree Dsk Auto Finance" },
  { value: "Bhavnagar District Co Operative Bank Ltd", label: "Bhavnagar District Co Operative Bank Ltd" },
  { value: "Hiranandani Financial Services Pvt Ltd", label: "Hiranandani Financial Services Pvt Ltd" },
  { value: "Shri Om Sai Nagri Sahakari Pantsansta Maryadit", label: "Shri Om Sai Nagri Sahakari Pantsansta Maryadit" },
  { value: "Shree Kshetra Dharmasthala Rural Development Project R", label: "Shree Kshetra Dharmasthala Rural Development Project R" },
  { value: "Bhilai Institute Of Technology", label: "Bhilai Institute Of Technology" },
  { value: "Sri Jai Krishna Auto Finance", label: "Sri Jai Krishna Auto Finance" },
  { value: "Revfin Services Pvt Ltd", label: "Revfin Services Pvt Ltd" },
  { value: "Hp Minorities Fin Development Corporation", label: "Hp Minorities Fin Development Corporation" },
  { value: "Babaji Date Mahila Sahakari Bank Ltd Yavatmal", label: "Babaji Date Mahila Sahakari Bank Ltd Yavatmal" },
  { value: "Sri Gokulam Motor Finance", label: "Sri Gokulam Motor Finance" },
  { value: "Sai Krupa Auto Finance", label: "Sai Krupa Auto Finance" },
  { value: "Siddheshwar Finance", label: "Siddheshwar Finance" },
  { value: "The Karnataka Credit Co Operative Society Ltd", label: "The Karnataka Credit Co Operative Society Ltd" },
  { value: "Mahatma Fule Magasvarga Vikas Mahamandal Maryadit", label: "Mahatma Fule Magasvarga Vikas Mahamandal Maryadit" },
  { value: "The Karnataka Co Operative Credit Society Ltd", label: "The Karnataka Co Operative Credit Society Ltd" },
  { value: "Sarjeraodada Naik Shirala Sahakari Bank Ltd", label: "Sarjeraodada Naik Shirala Sahakari Bank Ltd" },
  { value: "Ruby And Co", label: "Ruby And Co" },
  { value: "Amar Finance", label: "Amar Finance" },
  { value: "Sri Veerabhadreshwar Co Operative Bank Ltd", label: "Sri Veerabhadreshwar Co Operative Bank Ltd" },
  { value: "Shri Guru Basavajyoti Credit Souharda Co Operative Ltd", label: "Shri Guru Basavajyoti Credit Souharda Co Operative Ltd" },
  { value: "Kamadhenu And Credits", label: "Kamadhenu And Credits" },
  { value: "Bramhanwada Sahakari Path Maryadit", label: "Bramhanwada Sahakari Path Maryadit" },
  { value: "Shri Ramalingeshwar Pattin Sahakari Sangha Niyamit", label: "Shri Ramalingeshwar Pattin Sahakari Sangha Niyamit" },
  { value: "Shri Daneshwari Co Op Credit Society Ltd", label: "Shri Daneshwari Co Op Credit Society Ltd" },
  { value: "Sakthi Abirami Auto Finance", label: "Sakthi Abirami Auto Finance" },
  { value: "Lakshmi Finance Pvt Ltd", label: "Lakshmi Finance Pvt Ltd" },
  { value: "Bajaj Finserv Ltd.", label: "Bajaj Finserv Ltd." },
  { value: "Rupesh Baghmar", label: "Rupesh Baghmar" },
  { value: "Shree Morbi Nagarik Sahakari Bank Ltd", label: "Shree Morbi Nagarik Sahakari Bank Ltd" },
  { value: "Parag Nagari Sahakari Pathsanstha Maryadit", label: "Parag Nagari Sahakari Pathsanstha Maryadit" },
  { value: "Bmw India Pvt Ltd", label: "Bmw India Pvt Ltd" },
  { value: "The President Of India", label: "The President Of India" },
  { value: "Shri Tulsi Sah Pat", label: "Shri Tulsi Sah Pat" },
  { value: "Shree Devli Co Op Society Ltd Devli", label: "Shree Devli Co Op Society Ltd Devli" },
  { value: "Shree Devli Seva Sahkari Mandali Ltd", label: "Shree Devli Seva Sahkari Mandali Ltd" },
  { value: "Vagula Associates Auto Finance", label: "Vagula Associates Auto Finance" },
  { value: "The Cheruvathr Vanitha Service Co Op Society", label: "The Cheruvathr Vanitha Service Co Op Society" },
  { value: "Ajmani Consultancy Services", label: "Ajmani Consultancy Services" },
  { value: "The Sivagangal District Central Co Op Bank Ltd", label: "The Sivagangal District Central Co Op Bank Ltd" },
  { value: "The Coastal Urban Co Operative Bank Ltd", label: "The Coastal Urban Co Operative Bank Ltd" },
  { value: "J K Finserv", label: "J K Finserv" },
  { value: "Narayanpur Boalia Arbandi S K U S Ltd", label: "Narayanpur Boalia Arbandi S K U S Ltd" },
  { value: "Cdcc Bank", label: "Cdcc Bank" },
  { value: "Oil And Natural Gas Corporation", label: "Oil And Natural Gas Corporation" },
  { value: "P Kantilal Doshi", label: "P Kantilal Doshi" },
  { value: "Manju Exports", label: "Manju Exports" },
  { value: "Es Kay Fincorp", label: "Es Kay Fincorp" },
  { value: "Shining Social Welfare Society", label: "Shining Social Welfare Society" },
  { value: "Lokkalyan Gbs Sahakari Patsanstha Mry", label: "Lokkalyan Gbs Sahakari Patsanstha Mry" },
  { value: "Sarthak Credit Coperative Soc Ltd", label: "Sarthak Credit Coperative Soc Ltd" },
  { value: "Mamjoan O Hatishala G.P.S.K.U.S Ltd", label: "Mamjoan O Hatishala G.P.S.K.U.S Ltd" },
  { value: "Yashodip Sahakari Patpedhi Maryadit", label: "Yashodip Sahakari Patpedhi Maryadit" },
  { value: "Yeshwant Urban Co Op Bank Ltd. Udgir", label: "Yeshwant Urban Co Op Bank Ltd. Udgir" },
  { value: "Kannur Co Op Urban Bank Ltd", label: "Kannur Co Op Urban Bank Ltd" },
  { value: "Sri Rathna Credit Co Operative Society Limited", label: "Sri Rathna Credit Co Operative Society Limited" },
  { value: "Shrisai Multistate Co Op Ag Soc Ltd", label: "Shrisai Multistate Co Op Ag Soc Ltd" },
  { value: "Loksuvidha Finance Ltd", label: "Loksuvidha Finance Ltd" },
  { value: "Lokenath Finance", label: "Lokenath Finance" },
  { value: "Prudential Trading", label: "Prudential Trading" },
  { value: "Thrissur District Police Co.Operative Society Ltd", label: "Thrissur District Police Co.Operative Society Ltd" },
  { value: "Citi Fincrop Pvt Ltd", label: "Citi Fincrop Pvt Ltd" },
  { value: "Suman Auto", label: "Suman Auto" },
  { value: "Kdj Financial Services", label: "Kdj Financial Services" },
  { value: "A Mohanlal Lunawath", label: "A Mohanlal Lunawath" },
  { value: "Narula And Co", label: "Narula And Co" },
  { value: "Bhagwan Co Op Credit Society Ltd Mumbai", label: "Bhagwan Co Op Credit Society Ltd Mumbai" },
  { value: "Global Fincap Limited", label: "Global Fincap Limited" },
  { value: "Globe Fincap Limited", label: "Globe Fincap Limited" },
  { value: "Dr Appasaheb Urf Sa Re Patil Jaysingpur Udgaon Sahakari Bank Ltd Jaysingpur", label: "Dr Appasaheb Urf Sa Re Patil Jaysingpur Udgaon Sahakari Bank Ltd Jaysingpur" },
  { value: "Skyvesterr Associates", label: "Skyvesterr Associates" },
  { value: "Quantum Marketing Private Limited", label: "Quantum Marketing Private Limited" },
  { value: "Sahyadri Mahila Urban Co Operative Bank Limited", label: "Sahyadri Mahila Urban Co Operative Bank Limited" },
  { value: "Shreyans Auto Fin", label: "Shreyans Auto Fin" },
  { value: "Eswar Auto Finance", label: "Eswar Auto Finance" },
  { value: "Shriram Sahakari Pathpedhi Maryadit", label: "Shriram Sahakari Pathpedhi Maryadit" },
  { value: "N K Bothra Huf", label: "N K Bothra Huf" },
  { value: "Cent Bank Home Finance Ltd", label: "Cent Bank Home Finance Ltd" },
  { value: "Shree Keshod Credit Co. Op. Society Ltd", label: "Shree Keshod Credit Co. Op. Society Ltd" },
  { value: "District Development Officer For Shaduled Castes Wayanad", label: "District Development Officer For Shaduled Castes Wayanad" },
  { value: "Poornawadi Nagarik Sahkari Bank", label: "Poornawadi Nagarik Sahkari Bank" },
  { value: "Thirupathi Associate", label: "Thirupathi Associate" },
  { value: "Shivshree Souharda Credit Co Operative Ltd", label: "Shivshree Souharda Credit Co Operative Ltd" },
  { value: "The Gandhinagar Nagrik Co Op Bank Ltd", label: "The Gandhinagar Nagrik Co Op Bank Ltd" },
  { value: "W B S C S T Development And Finance Corporation", label: "W B S C S T Development And Finance Corporation" },
  { value: "Muthoot Money Ltd", label: "Muthoot Money Ltd" },
  { value: "Panditpur S K U S Ltd", label: "Panditpur S K U S Ltd" },
  { value: "Tripura Sc Co Operative Development Corporation Limited", label: "Tripura Sc Co Operative Development Corporation Limited" },
  { value: "Tripura Sc Safai Cooperative Development Corporation Ltd", label: "Tripura Sc Safai Cooperative Development Corporation Ltd" },
  { value: "Bramhan Hitvardhini Nagari Sahakari Pathsanstha Maryadit Mahad", label: "Bramhan Hitvardhini Nagari Sahakari Pathsanstha Maryadit Mahad" },
  { value: "Phoenix Arc Pvt Ltd", label: "Phoenix Arc Pvt Ltd" },
  { value: "Mahashakti Coop Credit Soc Ltd", label: "Mahashakti Coop Credit Soc Ltd" },
  { value: "Shree Kedareshwar Nag Sah Path Ltd", label: "Shree Kedareshwar Nag Sah Path Ltd" },
  { value: "The Daman And Diu State Co Op Bank Ltd", label: "The Daman And Diu State Co Op Bank Ltd" },
  { value: "Csb Bank Ltd", label: "Csb Bank Ltd" },
  { value: "Shree Kherwa Co Op Credit Society Ltd", label: "Shree Kherwa Co Op Credit Society Ltd" },
  { value: "Sai Samarth Finance", label: "Sai Samarth Finance" },
  { value: "Saraswat Co Operative Bank Ltd Scheduled Bank", label: "Saraswat Co Operative Bank Ltd Scheduled Bank" },
  { value: "Uma Co Op Credit Society Ltd", label: "Uma Co Op Credit Society Ltd" },
  { value: "Aks Associates", label: "Aks Associates" },
  { value: "Hindustan Motor Credits", label: "Hindustan Motor Credits" },
  { value: "Sarita Enterprises", label: "Sarita Enterprises" },
  { value: "Kotak Mahindra Investmetns Limited", label: "Kotak Mahindra Investmetns Limited" },
  { value: "Sbi Intouch", label: "Sbi Intouch" },
  { value: "The Setu Co Oprative Credit Society Ltd", label: "The Setu Co Oprative Credit Society Ltd" },
  { value: "Thakkolkaran Hirepurchasing", label: "Thakkolkaran Hirepurchasing" },
  { value: "District Manager Kerala State Scst", label: "District Manager Kerala State Scst" },
  { value: "Madhya Pradesh Gramin Bank", label: "Madhya Pradesh Gramin Bank" },
  { value: "The Maharshatra Ajk Par Sah Ltd", label: "The Maharshatra Ajk Par Sah Ltd" },
  { value: "Dakshin Paikbarh Skus Ltd", label: "Dakshin Paikbarh Skus Ltd" },
  { value: "Bairavi Fianance Private Limited", label: "Bairavi Fianance Private Limited" },
  { value: "Shree Shakti Auto Finance", label: "Shree Shakti Auto Finance" },
  { value: "Shraddha Saburi Co Op Cr Soc Ltd", label: "Shraddha Saburi Co Op Cr Soc Ltd" },
  { value: "Sumerpur Mercantile Urban Cooperative Bank Ltd", label: "Sumerpur Mercantile Urban Cooperative Bank Ltd" },
  { value: "Vidyasagar Central Co Operative Bank", label: "Vidyasagar Central Co Operative Bank" },
  { value: "The Morva Nagrik Co Op Credit Society Ltd", label: "The Morva Nagrik Co Op Credit Society Ltd" },
  { value: "Mahalakshmi Enterprises", label: "Mahalakshmi Enterprises" },
  { value: "Shri Sangameshwar Pattin Sahakari Sanga Amigad", label: "Shri Sangameshwar Pattin Sahakari Sanga Amigad" },
  { value: "Prabhat Surana Huf", label: "Prabhat Surana Huf" },
  { value: "Karnataka Gramin Bank", label: "Karnataka Gramin Bank" },
  { value: "V D Finance", label: "V D Finance" },
  { value: "The Panchmahal Dist Co Op Bank Ltd", label: "The Panchmahal Dist Co Op Bank Ltd" },
  { value: "Rakesh Kumar Nahar Sons", label: "Rakesh Kumar Nahar Sons" },
  { value: "Damler Financial Services India Pvt Ltd", label: "Damler Financial Services India Pvt Ltd" },
  { value: "Chetan Enterprises", label: "Chetan Enterprises" },
  { value: "A J Auto Credits", label: "A J Auto Credits" },
  { value: "Hll Infra Tech Services Limited", label: "Hll Infra Tech Services Limited" },
  { value: "Shree Maruti Sahakari Pattin Sangha Niyamita", label: "Shree Maruti Sahakari Pattin Sangha Niyamita" },
  { value: "Kerala State Minorities Development Finance Corporation Ltd", label: "Kerala State Minorities Development Finance Corporation Ltd" },
  { value: "Sundaram Finance India", label: "Sundaram Finance India" },
  { value: "The Capital Financial Services Ltd", label: "The Capital Financial Services Ltd" },
  { value: "Moneywise Financial Services Pvt Ltd", label: "Moneywise Financial Services Pvt Ltd" },
  { value: "The Shiroda Progressive Urban", label: "The Shiroda Progressive Urban" },
  { value: "Bahujan Hitay Auto Chalak Malak Sewa", label: "Bahujan Hitay Auto Chalak Malak Sewa" },
  { value: "Gujrat Schedule Caste Devlopment Corporation Rajkot", label: "Gujrat Schedule Caste Devlopment Corporation Rajkot" },
  { value: "Lal Finance Corporation", label: "Lal Finance Corporation" },
  { value: "Siemens Factoring Pvt Ltd", label: "Siemens Factoring Pvt Ltd" },
  { value: "Wb Sc St Obc Devolopment Finance Corporation", label: "Wb Sc St Obc Devolopment Finance Corporation" },
  { value: "Wb Sc St Obc Devolopment Finance Corporation", label: "Wb Sc St Obc Devolopment Finance Corporation" },
  { value: "Ashwini Finance Pvt Ltd", label: "Ashwini Finance Pvt Ltd" },
  { value: "Sanjay Kumar Choraria Huf", label: "Sanjay Kumar Choraria Huf" },
  { value: "Sai Nirman Na Sah Pat San Mar", label: "Sai Nirman Na Sah Pat San Mar" },
  { value: "Kayanna Karshika Vikasana Thoyilali Shema Sahakarana Sangam", label: "Kayanna Karshika Vikasana Thoyilali Shema Sahakarana Sangam" },
  { value: "Cfm Asset Reconstruction Pvt Ltd", label: "Cfm Asset Reconstruction Pvt Ltd" },
  { value: "Kolenchery Hire Purchasing Co", label: "Kolenchery Hire Purchasing Co" },
  { value: "Tirth Finance", label: "Tirth Finance" },
  { value: "The Daman Diu State Co Op Bank Limited", label: "The Daman Diu State Co Op Bank Limited" },
  { value: "Mataji Auto Finance R", label: "Mataji Auto Finance R" },
  { value: "Ts Finance", label: "Ts Finance" },
  { value: "Priya Financial Services", label: "Priya Financial Services" },
  { value: "Shri Bhairavnath Gramin Bigar Sheti Sahakari Pathasanstha Maryadit", label: "Shri Bhairavnath Gramin Bigar Sheti Sahakari Pathasanstha Maryadit" },
  { value: "Shri Rodadeshwar Gramin Bigar Sheti Sahakari Patsanstha", label: "Shri Rodadeshwar Gramin Bigar Sheti Sahakari Patsanstha" },
  { value: "Ifl Housing Finance Ltd.", label: "Ifl Housing Finance Ltd." },
  { value: "Srng Finance Pvt Ltd", label: "Srng Finance Pvt Ltd" },
  { value: "Sri Varaladevi Nagari Sah Patsanstha", label: "Sri Varaladevi Nagari Sah Patsanstha" },
  { value: "Nakoda Enterprises", label: "Nakoda Enterprises" },
  { value: "India Infoline Housing Finance Limited", label: "India Infoline Housing Finance Limited" },
  { value: "Shri Ganpati Finance Co", label: "Shri Ganpati Finance Co" },
  { value: "Idfc First Bank Limited", label: "Idfc First Bank Limited" },
  { value: "Hichkad Seva Sahakari Sangha", label: "Hichkad Seva Sahakari Sangha" },
  { value: "Godavari Souhardha Credit Co Op Ltd", label: "Godavari Souhardha Credit Co Op Ltd" },
  { value: "Pchainraj Lalwani", label: "Pchainraj Lalwani" },
  { value: "Baid Enterprise", label: "Baid Enterprise" },
  { value: "Sudarshan Souhrda Credit Sahakari Niyamit Ankola", label: "Sudarshan Souhrda Credit Sahakari Niyamit Ankola" },
  { value: "Sarve Haryana Gramin Bank", label: "Sarve Haryana Gramin Bank" },
  { value: "The Ahmedabaad District Co Op Bank Ltd", label: "The Ahmedabaad District Co Op Bank Ltd" },
  { value: "Adv Shamraoji Shinde Satyashodhak Sahakari Bank", label: "Adv Shamraoji Shinde Satyashodhak Sahakari Bank" },
  { value: "Rajapur Urban Co Op Bank Ltd", label: "Rajapur Urban Co Op Bank Ltd" },
  { value: "Director Vikram Sarabhai Space Center", label: "Director Vikram Sarabhai Space Center" },
  { value: "Fincare Small Finance Bank Ltd", label: "Fincare Small Finance Bank Ltd" },
  { value: "Arun Kothari Consulting And Fin", label: "Arun Kothari Consulting And Fin" },
  { value: "Lead Lender L And T Infrastructure Finance Co Ltd", label: "Lead Lender L And T Infrastructure Finance Co Ltd" },
  { value: "Security Trustee Vistra Itcl India Ltd", label: "Security Trustee Vistra Itcl India Ltd" },
  { value: "The Milk City Co Op Credit Society Ltd", label: "The Milk City Co Op Credit Society Ltd" },
  { value: "Cseb Gratuity And Pension Fund Trust", label: "Cseb Gratuity And Pension Fund Trust" },
  { value: "Psb Bank", label: "Psb Bank" },
  { value: "Obc Bank", label: "Obc Bank" },
  { value: "Dcb Bank", label: "Dcb Bank" },
  { value: "Boi", label: "Boi" },
  { value: "Niraj Motor Finance", label: "Niraj Motor Finance" },
  { value: "Starlaa Finance And Hire Purchase Pvt Ltd", label: "Starlaa Finance And Hire Purchase Pvt Ltd" },
  { value: "Madakimala Service Co Bank", label: "Madakimala Service Co Bank" },
  { value: "Sahakarnagar Nag Sah Pat Maryadit", label: "Sahakarnagar Nag Sah Pat Maryadit" },
  { value: "Jarandeshwar Gramin Big Sahkari Path", label: "Jarandeshwar Gramin Big Sahkari Path" },
  { value: "Tata Capital Financial Services Limited", label: "Tata Capital Financial Services Limited" },
  { value: "Shrinithi Capital Finance Co", label: "Shrinithi Capital Finance Co" },
  { value: "Central Financial Credit And Investment Co Operative India Ltd", label: "Central Financial Credit And Investment Co Operative India Ltd" },
  { value: "Rajarashi Shahu Government Servants Co Op Bank Ltd", label: "Rajarashi Shahu Government Servants Co Op Bank Ltd" },
  { value: "Okkal Service Co Operative Bank", label: "Okkal Service Co Operative Bank" },
  { value: "Raiganj Central Co Operative Bank Ltd", label: "Raiganj Central Co Operative Bank Ltd" },
  { value: "Roha Housing Finance Pvt Ltd", label: "Roha Housing Finance Pvt Ltd" },
  { value: "The Am Mahesh Co Operative Urban Bank Ltd", label: "The Am Mahesh Co Operative Urban Bank Ltd" },
  { value: "Vilas Co Op Bank Ltd Latur", label: "Vilas Co Op Bank Ltd Latur" },
  { value: "Rec Limited", label: "Rec Limited" },
  { value: "Shree Balaji Gr B S S Pat San Ltd", label: "Shree Balaji Gr B S S Pat San Ltd" },
  { value: "Sumerpur Mercantile Urban Co Op Bank Ltd", label: "Sumerpur Mercantile Urban Co Op Bank Ltd" },
  { value: "Highway Hire Pre Co", label: "Highway Hire Pre Co" },
  { value: "Fino Payments Bank Limited", label: "Fino Payments Bank Limited" },
  { value: "Shri Vasantrao Chougule Nagari Sahkari Pat Sanstha", label: "Shri Vasantrao Chougule Nagari Sahkari Pat Sanstha" },
  { value: "Aryadhan Financial Solutions Pvt Ltd", label: "Aryadhan Financial Solutions Pvt Ltd" },
  { value: "Sri Manikanta Auto And Employees Finance", label: "Sri Manikanta Auto And Employees Finance" },
  { value: "Shri Rang Corporation", label: "Shri Rang Corporation" },
  { value: "Apolomunich Health Insurance Co Ltd", label: "Apolomunich Health Insurance Co Ltd" },
  { value: "Indlnfravit Trust", label: "Indlnfravit Trust" },
  { value: "Catalyst Trusteeship Ltd", label: "Catalyst Trusteeship Ltd" },
  { value: "A Simila Devi Financier", label: "A Simila Devi Financier" },
  { value: "Vimal Chand Bagmar", label: "Vimal Chand Bagmar" },
  { value: "Megha Holdings Pvt Ltd", label: "Megha Holdings Pvt Ltd" },
  { value: "Shri Virupaksheshwara Pattina Souhardasahakari Niyamita", label: "Shri Virupaksheshwara Pattina Souhardasahakari Niyamita" },
  { value: "Sri Basavasri Noukarara Pattina Sahakara Sangha", label: "Sri Basavasri Noukarara Pattina Sahakara Sangha" },
  { value: "Shree Shrinathgadh Juth S S M Ltd", label: "Shree Shrinathgadh Juth S S M Ltd" },
  { value: "Ramu Finance", label: "Ramu Finance" },
  { value: "The Paraspar Sahayak Sahkari Mandali Ltd", label: "The Paraspar Sahayak Sahkari Mandali Ltd" },
  { value: "Shree Pandurang Gramin Bigar Sheti", label: "Shree Pandurang Gramin Bigar Sheti" },
  { value: "Dakshin Bihar Gramin Bank", label: "Dakshin Bihar Gramin Bank" },
  { value: "M Naveen Munoth Financier", label: "M Naveen Munoth Financier" },
  { value: "Hud Memorial Ngo", label: "Hud Memorial Ngo" },
  { value: "Rita Investments", label: "Rita Investments" },
  { value: "Siddhartha Pattina Souharda Sahakari Niyamita", label: "Siddhartha Pattina Souharda Sahakari Niyamita" },
  { value: "S.Kushal Chand And Sons", label: "S.Kushal Chand And Sons" },
  { value: "Reliant Credits India Limited", label: "Reliant Credits India Limited" },
  { value: "True Blue Asset Services Private Limited", label: "True Blue Asset Services Private Limited" },
  { value: "Gondal Nagrik Sahakari Bank Limited", label: "Gondal Nagrik Sahakari Bank Limited" },
  { value: "Mani Teja Auto Finance", label: "Mani Teja Auto Finance" },
  { value: "P L Jain Financiers And Money Landers", label: "P L Jain Financiers And Money Landers" },
  { value: "Caterpillar Financial Services India Pvt Ltd", label: "Caterpillar Financial Services India Pvt Ltd" },
  { value: "S K Auto Centre", label: "S K Auto Centre" },
  { value: "Vastu Finserve India Private Limited", label: "Vastu Finserve India Private Limited" },
  { value: "Rajashri Shahu Government Servants Co Operative Bank Ltd Kolhapur", label: "Rajashri Shahu Government Servants Co Operative Bank Ltd Kolhapur" },
  { value: "Tata Cleantech Capital Limited", label: "Tata Cleantech Capital Limited" },
  { value: "Siddhi Vinayak Gramin Bigar She Sah", label: "Siddhi Vinayak Gramin Bigar She Sah" },
  { value: "Banwait Enterprises", label: "Banwait Enterprises" },
  { value: "The Koylanchal Urban Co Operative Bank Ltd", label: "The Koylanchal Urban Co Operative Bank Ltd" },
  { value: "Jalgaon District Policy Employee Co Op Credit Society Ltd", label: "Jalgaon District Policy Employee Co Op Credit Society Ltd" },
  { value: "Premchand Bethala And Sons", label: "Premchand Bethala And Sons" },
  { value: "Andani Capital Pvt Ltd", label: "Andani Capital Pvt Ltd" },
  { value: "Nehrej Finance", label: "Nehrej Finance" },
  { value: "Roha Housing Finance Pvt. Ltd.", label: "Roha Housing Finance Pvt. Ltd." },
  { value: "Pnbhfl", label: "Pnbhfl" },
  { value: "Lic Hfl", label: "Lic Hfl" },
  { value: "Ifco Aonla Unit", label: "Ifco Aonla Unit" },
  { value: "Gujarat Unreserved Education And Economic Development Corporation", label: "Gujarat Unreserved Education And Economic Development Corporation" },
  { value: "Jayabalaji Finance", label: "Jayabalaji Finance" },
  { value: "The Yamuna Nagar Central Co Op Bank Ltd", label: "The Yamuna Nagar Central Co Op Bank Ltd" },
  { value: "The Hasti Co Op Bank Ltd", label: "The Hasti Co Op Bank Ltd" },
  { value: "Mdfc Financiers Private Limited", label: "Mdfc Financiers Private Limited" },
  { value: "Ambit Finvest", label: "Ambit Finvest" },
  { value: "Ambit Finvest Pvt Ltd", label: "Ambit Finvest Pvt Ltd" },
  { value: "New Global Credits", label: "New Global Credits" },
  { value: "Rajapur Taluka Kunabi Sah Pat Pedhi", label: "Rajapur Taluka Kunabi Sah Pat Pedhi" },
  { value: "Iffco Kisan Finance Ltd", label: "Iffco Kisan Finance Ltd" },
  { value: "Jambaladinni Souharda Pattina Sahakari Niyamita", label: "Jambaladinni Souharda Pattina Sahakari Niyamita" },
  { value: "Ravikiran Gramin Bigarshoti Sahakari Patsanstha", label: "Ravikiran Gramin Bigarshoti Sahakari Patsanstha" },
  { value: "Rscdc", label: "Rscdc" },
  { value: "Vishawas Finvest Pvt Ltd", label: "Vishawas Finvest Pvt Ltd" },
  { value: "Sawai Madhopur Urban Co Operative Bank Ltd", label: "Sawai Madhopur Urban Co Operative Bank Ltd" },
  { value: "Vagyalakhmi Enterprise", label: "Vagyalakhmi Enterprise" },
  { value: "Matha Associates", label: "Matha Associates" },
  { value: "Magna Fincorp Ltd", label: "Magna Fincorp Ltd" },
  { value: "Matha Associated", label: "Matha Associated" },
  { value: "Vardhaman Hire Purchase And Finance", label: "Vardhaman Hire Purchase And Finance" },
  { value: "Basaveshwara Souharda Sahakari Niyamita", label: "Basaveshwara Souharda Sahakari Niyamita" },
  { value: "Kisan Rural Finance Ltd", label: "Kisan Rural Finance Ltd" },
  { value: "Pvr Auto Finance", label: "Pvr Auto Finance" },
  { value: "M Babulal", label: "M Babulal" },
  { value: "Prakash Chand Jain Financier", label: "Prakash Chand Jain Financier" },
  { value: "The Thonnakkal Service Co Operative Bank Ltd", label: "The Thonnakkal Service Co Operative Bank Ltd" },
  { value: "Sri Thangaratham Investments", label: "Sri Thangaratham Investments" },
  { value: "Sabari Motors", label: "Sabari Motors" },
  { value: "Sri Medhana Sai Auto Finance", label: "Sri Medhana Sai Auto Finance" },
  { value: "Ruby Motors", label: "Ruby Motors" },
  { value: "Suneet Motors", label: "Suneet Motors" },
  { value: "Shri Vithumauli Gramin Bigher Sheti Sahakari Pat Sansta Ltd", label: "Shri Vithumauli Gramin Bigher Sheti Sahakari Pat Sansta Ltd" },
  { value: "J Harish Kumar", label: "J Harish Kumar" },
  { value: "Dmk Jaoli Bank", label: "Dmk Jaoli Bank" },
  { value: "Basaveshwar Sahakari Bank Nyt", label: "Basaveshwar Sahakari Bank Nyt" },
  { value: "Mamatha Auto Finance", label: "Mamatha Auto Finance" },
  { value: "Sri Siri Mahalakshmi Pattina Souharda Sahakari Ni", label: "Sri Siri Mahalakshmi Pattina Souharda Sahakari Ni" },
  { value: "Satara Co Op Credit Society Ltd", label: "Satara Co Op Credit Society Ltd" },
  { value: "The Morva Arthxam Seva Sahakari Mandali Ltd", label: "The Morva Arthxam Seva Sahakari Mandali Ltd" },
  { value: "Somjai Nagari Sahkari Path Sanstha Maryadit", label: "Somjai Nagari Sahkari Path Sanstha Maryadit" },
  { value: "Laxmi India Finance Pvt Ltd", label: "Laxmi India Finance Pvt Ltd" },
  { value: "Namdev Finvest Pvt Ltd", label: "Namdev Finvest Pvt Ltd" },
  { value: "Shree Bhansali Motors", label: "Shree Bhansali Motors" },
  { value: "Uco Bank And Srei Equipmnt Fianance Ltd", label: "Uco Bank And Srei Equipmnt Fianance Ltd" },
  { value: "Sachin Motors", label: "Sachin Motors" },
  { value: "The Sahebrao Deshmukh Co. Op. Bank Ltd", label: "The Sahebrao Deshmukh Co. Op. Bank Ltd" },
  { value: "Sanguine India Nidhi Limited", label: "Sanguine India Nidhi Limited" },
  { value: "Kerala Statehandicapped Persons Welfare Corporation Ltd", label: "Kerala Statehandicapped Persons Welfare Corporation Ltd" },
  { value: "Shree Velavan Auto Finance", label: "Shree Velavan Auto Finance" },
  { value: "Supra Pacific Management Consultancy Limited", label: "Supra Pacific Management Consultancy Limited" },
  { value: "Viyan Auto Finance", label: "Viyan Auto Finance" },
  { value: "Venkata Sai Vehicle Finance", label: "Venkata Sai Vehicle Finance" },
  { value: "Swarkar Nagar Nagarik Sahakari Pathpedi", label: "Swarkar Nagar Nagarik Sahakari Pathpedi" },
  { value: "Skl Investments", label: "Skl Investments" },
  { value: "The Ankola Urban Cooperative Bank Ltd", label: "The Ankola Urban Cooperative Bank Ltd" },
  { value: "Sbicap Security Trustee Company", label: "Sbicap Security Trustee Company" },
  { value: "Divya Sai Auto Finance", label: "Divya Sai Auto Finance" },
  { value: "Religare Housing Development Finance Corporation Limited", label: "Religare Housing Development Finance Corporation Limited" },
  { value: "Sree Khirai Seva Sahkari Mandli Ltd", label: "Sree Khirai Seva Sahkari Mandli Ltd" },
  { value: "Sikkim State Co Oerative Bank Limited", label: "Sikkim State Co Oerative Bank Limited" },
  { value: "Bajrang Bali Auto Mobile", label: "Bajrang Bali Auto Mobile" },
  { value: "Shriram Transport Fin.Ser.Ltd", label: "Shriram Transport Fin.Ser.Ltd" },
  { value: "Pavan Hp Finance", label: "Pavan Hp Finance" },
  { value: "St Milagres Credit Souhardha Co Operative Ltd", label: "St Milagres Credit Souhardha Co Operative Ltd" },
  { value: "Suprabhat Credit Souhardha Ltd Ankola", label: "Suprabhat Credit Souhardha Ltd Ankola" },
  { value: "Ms Loknath Motor Finance", label: "Ms Loknath Motor Finance" },
  { value: "The Gadchiroli Nagari Sahakari Bank Ltd", label: "The Gadchiroli Nagari Sahakari Bank Ltd" },
  { value: "Suncity Finance", label: "Suncity Finance" },
  { value: "P Padamchand Nagar", label: "P Padamchand Nagar" },
  { value: "A Padamchand Nagar", label: "A Padamchand Nagar" },
  { value: "Om Sai Fincare", label: "Om Sai Fincare" },
  { value: "Om Auto Fin Care", label: "Om Auto Fin Care" },
  { value: "Anugraha Vividhoddesha Sahakari Sangha Ltd", label: "Anugraha Vividhoddesha Sahakari Sangha Ltd" },
  { value: "Poonam Enterprises", label: "Poonam Enterprises" },
  { value: "Vishavbharti Sha Pat Ltd", label: "Vishavbharti Sha Pat Ltd" },
  { value: "Jammu And Kashmir Bank", label: "Jammu And Kashmir Bank" },
  { value: "M P Warehousing And Logistic Corporation", label: "M P Warehousing And Logistic Corporation" },
  { value: "Radhey Motors", label: "Radhey Motors" },
  { value: "Ezhil Auto Finance", label: "Ezhil Auto Finance" },
  { value: "Parveen Finance Co", label: "Parveen Finance Co" },
  { value: "K Citi Vividoddesha Souhardha S Ni", label: "K Citi Vividoddesha Souhardha S Ni" },
  { value: "Hedge Finance Ltd", label: "Hedge Finance Ltd" },
  { value: "Sri Satya Auto Finance", label: "Sri Satya Auto Finance" },
  { value: "Vinayaga Auto Hub", label: "Vinayaga Auto Hub" },
  { value: "Texco Co Op Bank", label: "Texco Co Op Bank" },
  { value: "Ahilyadevi Holkar Saha. Pat. Sanstha Ltd", label: "Ahilyadevi Holkar Saha. Pat. Sanstha Ltd" },
  { value: "Om Shree Ram Finance", label: "Om Shree Ram Finance" },
  { value: "Idbi Security Trusteeship Services Limited As Security Truste", label: "Idbi Security Trusteeship Services Limited As Security Truste" },
  { value: "Megheswar Multipurpose", label: "Megheswar Multipurpose" },
  { value: "The Texco Co Op Bank Ltd", label: "The Texco Co Op Bank Ltd" },
  { value: "Sentur Velaa Auto Finances", label: "Sentur Velaa Auto Finances" },
  { value: "The Kakatiya Co Operative Urban Bank Ltd.", label: "The Kakatiya Co Operative Urban Bank Ltd." },
  { value: "Shree Vadasada Seva Sahakari Mandali Ltd", label: "Shree Vadasada Seva Sahakari Mandali Ltd" },
  { value: "Somajai Nagari Saha Patsanstha Mar Shrivardhan", label: "Somajai Nagari Saha Patsanstha Mar Shrivardhan" },
  { value: "Shri Samor Finance", label: "Shri Samor Finance" },
  { value: "Sree Nanda Finance", label: "Sree Nanda Finance" },
  { value: "Sethiya Creit Corporation", label: "Sethiya Creit Corporation" },
  { value: "Sre Senthur Auto Finance", label: "Sre Senthur Auto Finance" },
  { value: "Nabadwip Thana Agriculture Co Operative Marketing Society Ltd", label: "Nabadwip Thana Agriculture Co Operative Marketing Society Ltd" },
  { value: "Shiv Krupa Gramin Bigarsheti Sahakari Patsanstha Maryadit", label: "Shiv Krupa Gramin Bigarsheti Sahakari Patsanstha Maryadit" },
  { value: "Annapurna Vehicle Finance", label: "Annapurna Vehicle Finance" },
  { value: "Shri Hinglaj Credit Co Op Customer Credit Society", label: "Shri Hinglaj Credit Co Op Customer Credit Society" },
  { value: "Shree Kalikamata Nagari Sahakari Patsanstha Ltd", label: "Shree Kalikamata Nagari Sahakari Patsanstha Ltd" },
  { value: "Sai Gramin Bigarsheti Sahkaripatshantha Maryadit", label: "Sai Gramin Bigarsheti Sahkaripatshantha Maryadit" },
  { value: "Krantivir Vasantrao Naik N S Pat", label: "Krantivir Vasantrao Naik N S Pat" },
  { value: "Hdfc Limited", label: "Hdfc Limited" },
  { value: "Rcf Finance Private Lomited", label: "Rcf Finance Private Lomited" },
  { value: "National Bulk Handling Corporation Pvt Ltd", label: "National Bulk Handling Corporation Pvt Ltd" },
  { value: "The Panipat Urban Co Op Bank Ltd", label: "The Panipat Urban Co Op Bank Ltd" },
  { value: "The Panipat Urban Co Op Bank Ltd", label: "The Panipat Urban Co Op Bank Ltd" },
  { value: "The Panipat Urban Co Op Bank Ltd", label: "The Panipat Urban Co Op Bank Ltd" },
  { value: "Stci Finance Limited", label: "Stci Finance Limited" },
  { value: "Gujarat Sheduled Caste Devlop Corp", label: "Gujarat Sheduled Caste Devlop Corp" },
  { value: "Sindhudurg District Central Cooperative Bank Ltd", label: "Sindhudurg District Central Cooperative Bank Ltd" },
  { value: "Hypothecation Vistra Itcl India Limited Trustee Of Hdfc Capital Affordable Real Estate Fund", label: "Hypothecation Vistra Itcl India Limited Trustee Of Hdfc Capital Affordable Real Estate Fund" },
  { value: "Indostar Capital Finance Ltd", label: "Indostar Capital Finance Ltd" },
  { value: "Dr Appasaheb Urf Sa.Re.Patil Jaysingpur Udgaon Sahakari Bank Ltd", label: "Dr Appasaheb Urf Sa.Re.Patil Jaysingpur Udgaon Sahakari Bank Ltd" },
  { value: "Mee Padmasai Finance", label: "Mee Padmasai Finance" },
  { value: "Khivasara Finance", label: "Khivasara Finance" },
  { value: "P.S Auto Finance", label: "P.S Auto Finance" },
  { value: "Sri Skkandaa Fin Corp", label: "Sri Skkandaa Fin Corp" },
  { value: "Fincred Investments Limited", label: "Fincred Investments Limited" },
  { value: "Tamil Nadu Grama Bank", label: "Tamil Nadu Grama Bank" },
  { value: "H N Prakashchand", label: "H N Prakashchand" },
  { value: "Procter Gamble Health Limited", label: "Procter Gamble Health Limited" },
  { value: "Sri Maruthi Pattina Souharda", label: "Sri Maruthi Pattina Souharda" },
  { value: "The Central Co Operative Bank Ltd Tonk", label: "The Central Co Operative Bank Ltd Tonk" },
  { value: "The Dhanbad Central Co Op Bank Ltd", label: "The Dhanbad Central Co Op Bank Ltd" },
  { value: "Simens Financial Services Pvt Ltd", label: "Simens Financial Services Pvt Ltd" },
  { value: "Sri Krantiveera Sangolli Rayannavivdoddesh Sahakar Sangh Ni", label: "Sri Krantiveera Sangolli Rayannavivdoddesh Sahakar Sangh Ni" },
  { value: "Kai Krushanarao Aptil Gramin Bigarsheti Sahkari Pat Sanstha Maryadit", label: "Kai Krushanarao Aptil Gramin Bigarsheti Sahkari Pat Sanstha Maryadit" },
  { value: "Gove Finance Limited", label: "Gove Finance Limited" },
  { value: "Magma Fincorp Ltd", label: "Magma Fincorp Ltd" },
  { value: "The Salem Dist Central Co Operative Bank Ltd", label: "The Salem Dist Central Co Operative Bank Ltd" },
  { value: "Indian Bank E Allahabad Bank", label: "Indian Bank E Allahabad Bank" },
  { value: "Union Bank Of India E Corporation Bank", label: "Union Bank Of India E Corporation Bank" },
  { value: "Dena Bank Pension And Gratuity Fund", label: "Dena Bank Pension And Gratuity Fund" },
  { value: "Canara Bank E Syndicate Bank", label: "Canara Bank E Syndicate Bank" },
  { value: "Shivsamarth Malti State Co Op", label: "Shivsamarth Malti State Co Op" },
  { value: "The Bejoo Co Op Cr.Society Ltd", label: "The Bejoo Co Op Cr.Society Ltd" },
  { value: "C R Sou Pat Sah Ni", label: "C R Sou Pat Sah Ni" },
  { value: "Yellapur Urban Co Op Cr Soc Ltd", label: "Yellapur Urban Co Op Cr Soc Ltd" },
  { value: "Dhanashree Finance Corporation", label: "Dhanashree Finance Corporation" },
  { value: "Asa International India Microfinance Ltd", label: "Asa International India Microfinance Ltd" },
  { value: "The Panchmahal District Co. Op. Bank Ltd", label: "The Panchmahal District Co. Op. Bank Ltd" },
  { value: "Fullerton India Credit Company Ltd.", label: "Fullerton India Credit Company Ltd." },
  { value: "Cars24 Financial Services Pvt Ltd", label: "Cars24 Financial Services Pvt Ltd" },
  { value: "The Eramala Service Co Operative Bank Ltd", label: "The Eramala Service Co Operative Bank Ltd" },
  { value: "Raksha Hp And Finance", label: "Raksha Hp And Finance" },
  { value: "Sai Sudha Auto Finance", label: "Sai Sudha Auto Finance" },
  { value: "Kamaraj Co Operative Credit Society Ltd", label: "Kamaraj Co Operative Credit Society Ltd" },
  { value: "Jana Small Finance Bank Ltd", label: "Jana Small Finance Bank Ltd" },
  { value: "Sri Ponnalagu Auto Finance", label: "Sri Ponnalagu Auto Finance" },
  { value: "Fortune Intergraed Assets", label: "Fortune Intergraed Assets" },
  { value: "Siddharth Enterprises", label: "Siddharth Enterprises" },
  { value: "Mufg Bank", label: "Mufg Bank" },
  { value: "Rudraguppe Primary Agricultural Co Op Society Ltd", label: "Rudraguppe Primary Agricultural Co Op Society Ltd" },
  { value: "Raje Vikramsinh Ghatge Co Op Bank Ltd", label: "Raje Vikramsinh Ghatge Co Op Bank Ltd" },
  { value: "Sri Haridurga Manikanta Auto Finance", label: "Sri Haridurga Manikanta Auto Finance" },
  { value: "Jm Financial Asset Reconstruction Co Ltd", label: "Jm Financial Asset Reconstruction Co Ltd" },
  { value: "Sri Sivaselvi Auto Finance", label: "Sri Sivaselvi Auto Finance" },
  { value: "Shrimant Thorve Bajirao Peshve Nagri Sahakari Patsanstha Marya.", label: "Shrimant Thorve Bajirao Peshve Nagri Sahakari Patsanstha Marya." },
  { value: "Mpwlc", label: "Mpwlc" },
  { value: "Eclear Leasing Finance Pvt Ltd", label: "Eclear Leasing Finance Pvt Ltd" },
  { value: "Kogta Finance India Ltd", label: "Kogta Finance India Ltd" },
  { value: "Reddy Auto Finance", label: "Reddy Auto Finance" },
  { value: "Teja Motors", label: "Teja Motors" },
  { value: "Sri Satya Finance Corporaction", label: "Sri Satya Finance Corporaction" },
  { value: "Wheels Emi Pvt. Ltd", label: "Wheels Emi Pvt. Ltd" },
  { value: "Shrinithi Capital Private Limited", label: "Shrinithi Capital Private Limited" },
  { value: "State Bank Of India And Its Consortium", label: "State Bank Of India And Its Consortium" },
  { value: "Sbi Cap Trustee Company Limited", label: "Sbi Cap Trustee Company Limited" },
  { value: "Orix Leasing And Financial Services", label: "Orix Leasing And Financial Services" },
  { value: "Metro City Fin Pvt Ltd", label: "Metro City Fin Pvt Ltd" },
  { value: "Niif Infrastructure Finance Limited", label: "Niif Infrastructure Finance Limited" },
  { value: "Alchemist Assest Reconstruction Company Limited", label: "Alchemist Assest Reconstruction Company Limited" },
  { value: "The Solapur District Central Cooperative Bank Ltd", label: "The Solapur District Central Cooperative Bank Ltd" },
  { value: "Cholamandalam Inv And Fin Co Ltd", label: "Cholamandalam Inv And Fin Co Ltd" },
  { value: "Equitas Small Finance Bank", label: "Equitas Small Finance Bank" },
  { value: "Shivdhan Gramin Bigarsheti Sahakari Patpedhi", label: "Shivdhan Gramin Bigarsheti Sahakari Patpedhi" },
  { value: "Sree Matha Autolines", label: "Sree Matha Autolines" },
  { value: "The Kadapa District Co Op Central Bank Ltd", label: "The Kadapa District Co Op Central Bank Ltd" },
  { value: "Saisudha Auto Financiers", label: "Saisudha Auto Financiers" },
  { value: "The A.P Mahesh Co Operative Urban Bank Ltd", label: "The A.P Mahesh Co Operative Urban Bank Ltd" },
  { value: "Paytm Payments Bank", label: "Paytm Payments Bank" },
  { value: "Siri Auto And General Finance", label: "Siri Auto And General Finance" },
  { value: "Service Co Operative Bank", label: "Service Co Operative Bank" },
  { value: "Nivedeta Mahila Credit Co Operative Society", label: "Nivedeta Mahila Credit Co Operative Society" },
  { value: "Shri Gokuleshwar Gramin Bigarsheti Sahakari Patsavnstha Marya.", label: "Shri Gokuleshwar Gramin Bigarsheti Sahakari Patsavnstha Marya." },
  { value: "Chapra Dhantala Samabay Krishi Unnayan Samity Ltd", label: "Chapra Dhantala Samabay Krishi Unnayan Samity Ltd" },
  { value: "Viracharya Babasaheb Kuchnure Jilha Nagari Sah Patsantha Maryadit", label: "Viracharya Babasaheb Kuchnure Jilha Nagari Sah Patsantha Maryadit" },
  { value: "A. P. Patil Sarvoday Patsanstha Maryadit", label: "A. P. Patil Sarvoday Patsanstha Maryadit" },
  { value: "Shree Financial Services", label: "Shree Financial Services" },
  { value: "The United Co Operative Bank Ltd", label: "The United Co Operative Bank Ltd" },
  { value: "Mathoor And Co", label: "Mathoor And Co" },
  { value: "Sibdi Bank", label: "Sibdi Bank" },
  { value: "Ckers Finance Pvt Ltd", label: "Ckers Finance Pvt Ltd" },
  { value: "Bansal Finance", label: "Bansal Finance" },
  { value: "Shantappanna Mirji Ur Co Op Bank", label: "Shantappanna Mirji Ur Co Op Bank" },
  { value: "Sri Subam Auto Finance", label: "Sri Subam Auto Finance" },
  { value: "Hindustan Aeronautics Limited Inventory", label: "Hindustan Aeronautics Limited Inventory" },
  { value: "Hal Ardc Division", label: "Hal Ardc Division" },
  { value: "Lca Division", label: "Lca Division" },
  { value: "Brahmos Aerospace", label: "Brahmos Aerospace" },
  { value: "Akshaya Pathra Finacial Service", label: "Akshaya Pathra Finacial Service" },
  { value: "Sri Balavinayaga Auto Finance", label: "Sri Balavinayaga Auto Finance" },
  { value: "Vtc Leasing And Finance Ltd", label: "Vtc Leasing And Finance Ltd" },
  { value: "D.G.Transport Finance Co.", label: "D.G.Transport Finance Co." },
  { value: "Keezhmad Service Co Operative Bank Ltd", label: "Keezhmad Service Co Operative Bank Ltd" },
  { value: "Sridevipriya Finance", label: "Sridevipriya Finance" },
  { value: "Satyam Motors", label: "Satyam Motors" },
  { value: "Hal Engine Division", label: "Hal Engine Division" },
  { value: "Pavanraje Loksamrudhi Multi State Co Op Society Ltd", label: "Pavanraje Loksamrudhi Multi State Co Op Society Ltd" },
  { value: "Rbl Bank Ltd.", label: "Rbl Bank Ltd." },
  { value: "Hal Aerdc Division", label: "Hal Aerdc Division" },
  { value: "Metafin Cleantech Finance Private Limited", label: "Metafin Cleantech Finance Private Limited" },
  { value: "Siri Sanjeevini Pattina Souharda Sahakari Niyamit Sirwar", label: "Siri Sanjeevini Pattina Souharda Sahakari Niyamit Sirwar" },
  { value: "Sri Nandhini Vehicle Finance", label: "Sri Nandhini Vehicle Finance" },
  { value: "Vagmi Capital Servics Pvt .Ltd", label: "Vagmi Capital Servics Pvt .Ltd" },
  { value: "The Ahmedabad Mercantile Cooperative Bank Limited", label: "The Ahmedabad Mercantile Cooperative Bank Limited" },
  { value: "Tata Motor Finance Solutions Ltd", label: "Tata Motor Finance Solutions Ltd" },
  { value: "Pnb Investment Services Limited As Security Trustee On Behalf Of Project Lenders To Be Included As Additional Insured", label: "Pnb Investment Services Limited As Security Trustee On Behalf Of Project Lenders To Be Included As Additional Insured" },
  { value: "Swatantra Deo Enterprises", label: "Swatantra Deo Enterprises" },
  { value: "Sethiya Finance", label: "Sethiya Finance" },
  { value: "Centrum Financial Services Limited", label: "Centrum Financial Services Limited" },
  { value: "The Bhandara District Central Co Op Bank Ltd Bhandara", label: "The Bhandara District Central Co Op Bank Ltd Bhandara" },
  { value: "Pnb Investment Services Ltd As Security Trustee", label: "Pnb Investment Services Ltd As Security Trustee" },
  { value: "Ner Urban Co Op Credit Society", label: "Ner Urban Co Op Credit Society" },
  { value: "Akshya Cr Co Society Ltd", label: "Akshya Cr Co Society Ltd" },
  { value: "Cdccb Bank", label: "Cdccb Bank" },
  { value: "Lok Suvidha Finance Ltd.", label: "Lok Suvidha Finance Ltd." },
  { value: "Maski Naukarara Souharda Sahakari, Ni Maskiraichur", label: "Maski Naukarara Souharda Sahakari, Ni Maskiraichur" },
  { value: "Sri Satya Finance Corpopration", label: "Sri Satya Finance Corpopration" },
  { value: "Sri Satya Finance Corporation", label: "Sri Satya Finance Corporation" },
  { value: "Eclear Leasing And Finance Pvt Ltd", label: "Eclear Leasing And Finance Pvt Ltd" },
  { value: "Akash Finserv", label: "Akash Finserv" },
  { value: "Amudham Auto Finance Corportaion", label: "Amudham Auto Finance Corportaion" },
  { value: "Shri Panchacharya Co Op Credit Society Ltd", label: "Shri Panchacharya Co Op Credit Society Ltd" },
  { value: "Sangeetha Sethiya Investments", label: "Sangeetha Sethiya Investments" },
  { value: "The Rajapur Urban Co Op Bank Ltd", label: "The Rajapur Urban Co Op Bank Ltd" },
  { value: "Baroda Up Bank", label: "Baroda Up Bank" },
  { value: "Uv Asset Reconstruction Co. Ltd", label: "Uv Asset Reconstruction Co. Ltd" },
  { value: "The Zoroastrian Co Operative Bank Ltd", label: "The Zoroastrian Co Operative Bank Ltd" },
  { value: "Safari Industries India Ltd", label: "Safari Industries India Ltd" },
  { value: "Shri Basaveshwar Souharda Pattina Sahakari Niyamit Talikoti", label: "Shri Basaveshwar Souharda Pattina Sahakari Niyamit Talikoti" },
  { value: "Ark Auto Finance", label: "Ark Auto Finance" },
  { value: "The Karur Vysya Bank Limited", label: "The Karur Vysya Bank Limited" },
  { value: "Xander Finance Pvt Ltd", label: "Xander Finance Pvt Ltd" },
  { value: "The Cd Co. Operative Credit Society Ltd.", label: "The Cd Co. Operative Credit Society Ltd." },
  { value: "Jambhora Gramin Bigar Sheti Sah Patsanstha", label: "Jambhora Gramin Bigar Sheti Sah Patsanstha" },
  { value: "Sri Balaji Fincorp", label: "Sri Balaji Fincorp" },
  { value: "Hytone Merchants Pvt Ltd", label: "Hytone Merchants Pvt Ltd" },
  { value: "Sree Bhyraveshwara Sahakara Bank Niyamitha", label: "Sree Bhyraveshwara Sahakara Bank Niyamitha" },
  { value: "Jayabheri Finance", label: "Jayabheri Finance" },
  { value: "Surabi Hire Purchase", label: "Surabi Hire Purchase" },
  { value: "Yashshri Nagari Sah Pat Sanstha Ltd", label: "Yashshri Nagari Sah Pat Sanstha Ltd" },
  { value: "Ascend Bizcap Pvt.Ltd", label: "Ascend Bizcap Pvt.Ltd" },
  { value: "Vinayaga Credits", label: "Vinayaga Credits" },
  { value: "Formerly The Catholic Syrian Bank Ltd", label: "Formerly The Catholic Syrian Bank Ltd" },
  { value: "Jai Mahesh Multistate V S Co Op S", label: "Jai Mahesh Multistate V S Co Op S" },
  { value: "Adityaa Motor Finance", label: "Adityaa Motor Finance" },
  { value: "Padma Pujitha Auto Finance", label: "Padma Pujitha Auto Finance" },
  { value: "The Umreth Urban Co Op Bank Ltd.", label: "The Umreth Urban Co Op Bank Ltd." },
  { value: "Dhanalakshmi Vehicle Finance", label: "Dhanalakshmi Vehicle Finance" },
  { value: "Rajarshi Shahu Nag Sah Pat Sanstha", label: "Rajarshi Shahu Nag Sah Pat Sanstha" },
  { value: "A P Patil Sarvoday Path Santha Maishal", label: "A P Patil Sarvoday Path Santha Maishal" },
  { value: "Instafund Hyd", label: "Instafund Hyd" },
  { value: "Arka Fincap Limited", label: "Arka Fincap Limited" },
  { value: "Cnh Industrial Capital India Private Limited", label: "Cnh Industrial Capital India Private Limited" },
  { value: "Western Capital Advisory Pvt Ltd", label: "Western Capital Advisory Pvt Ltd" },
  { value: "Darjeeling District Central Co Operative Bank Ltd", label: "Darjeeling District Central Co Operative Bank Ltd" },
  { value: "The V.V.C.C. Bank Ltd", label: "The V.V.C.C. Bank Ltd" },
  { value: "State Bank Of India Universal Trusteeship Services Limited For Ecb Loan Of Usd 70 Million Max. Rs.500 Crores From State Bank Of India London Branch", label: "State Bank Of India Universal Trusteeship Services Limited For Ecb Loan Of Usd 70 Million Max. Rs.500 Crores From State Bank Of India London Branch" },
  { value: "Priyadarshani Urban Co Operative Bank Ltd", label: "Priyadarshani Urban Co Operative Bank Ltd" },
  { value: "Ahmednagar Shahar Sahakari Bank Maryadit", label: "Ahmednagar Shahar Sahakari Bank Maryadit" },
  { value: "Sany Heavy Industry India Pvt Ltd", label: "Sany Heavy Industry India Pvt Ltd" },
  { value: "Ms Western Capital Advisors Pvt Ltd", label: "Ms Western Capital Advisors Pvt Ltd" },
  { value: "Western Capital Advisors Private Limited", label: "Western Capital Advisors Private Limited" },
  { value: "India Bulls Pvt Ltd", label: "India Bulls Pvt Ltd" },
  { value: "Sbm Bank India Limited", label: "Sbm Bank India Limited" },
  { value: "Sri Vijaya Narasimha Vehicle Finance", label: "Sri Vijaya Narasimha Vehicle Finance" },
  { value: "Forest Department Territrial Gondia", label: "Forest Department Territrial Gondia" },
  { value: "Dr Keshav Nagari Sahakari Patsanstha Maryadit", label: "Dr Keshav Nagari Sahakari Patsanstha Maryadit" },
  { value: "Adarsha Souharda Sahakari Niyamit Branch", label: "Adarsha Souharda Sahakari Niyamit Branch" },
  { value: "The Nedumangad Co Operative Urban Bank Ltd", label: "The Nedumangad Co Operative Urban Bank Ltd" },
  { value: "Vijayapura Dcc Bank Ltd", label: "Vijayapura Dcc Bank Ltd" },
  { value: "The Kanara District Central C0 Op Bank Ltd", label: "The Kanara District Central C0 Op Bank Ltd" },
  { value: "Shimoga District Co Operative Central Bank Ltd", label: "Shimoga District Co Operative Central Bank Ltd" },
  { value: "Atirath Commercial Pvt Ltd", label: "Atirath Commercial Pvt Ltd" },
  { value: "Omkara Assets Reconstruction Pvt Ltd", label: "Omkara Assets Reconstruction Pvt Ltd" },
  { value: "Ms Seva Associates", label: "Ms Seva Associates" },
  { value: "Oto Capital", label: "Oto Capital" },
  { value: "1T9 Technology Private Limited", label: "1T9 Technology Private Limited" },
  { value: "Sk Finance Limited", label: "Sk Finance Limited" },
  { value: "Muthuvairam Finance", label: "Muthuvairam Finance" },
  { value: "The Kozhikode Primary Co Operative Agricultural And Rural Development Bank Ltd", label: "The Kozhikode Primary Co Operative Agricultural And Rural Development Bank Ltd" },
  { value: "N Sai Multistate Co Op Credit Society", label: "N Sai Multistate Co Op Credit Society" },
  { value: "Sree Sendhur Auto Finance", label: "Sree Sendhur Auto Finance" },
  { value: "Thendral Motor Finance", label: "Thendral Motor Finance" },
  { value: "Vedika Fincorp Private Limited", label: "Vedika Fincorp Private Limited" },
  { value: "Shamrao Peje Kokan Itar Magasvarg Aarthik Vikas Mahamandal Limited", label: "Shamrao Peje Kokan Itar Magasvarg Aarthik Vikas Mahamandal Limited" },
  { value: "L And T Housing Finance Ltd", label: "L And T Housing Finance Ltd" },
  { value: "Swami Samarth Mul", label: "Swami Samarth Mul" },
  { value: "Cholamandalam Inve Co Fin Ltd", label: "Cholamandalam Inve Co Fin Ltd" },
  { value: "The Gramin Vikas Multistste Co Op Credit Society Ltd", label: "The Gramin Vikas Multistste Co Op Credit Society Ltd" },
  { value: "Dhavam Auto Finance", label: "Dhavam Auto Finance" },
  { value: "Naroda Fintech", label: "Naroda Fintech" },
  { value: "Veera Lakshmana Finance And Auto Consultancy", label: "Veera Lakshmana Finance And Auto Consultancy" },
  { value: "Kerala Bank", label: "Kerala Bank" },
  { value: "Unity Small Finance Bank", label: "Unity Small Finance Bank" },
  { value: "St. Milagres Credi Souhardha Co Op.Ltd.Karwar", label: "St. Milagres Credi Souhardha Co Op.Ltd.Karwar" },
  { value: "Kholeshwar Multi State Co.Op", label: "Kholeshwar Multi State Co.Op" },
  { value: "Poonawalla Fincorp Ltd", label: "Poonawalla Fincorp Ltd" },
  { value: "The Modern Co Oprative Bank Ltd", label: "The Modern Co Oprative Bank Ltd" },
  { value: "Ashokraj Nag Sah Pat Mary", label: "Ashokraj Nag Sah Pat Mary" },
  { value: "Karnataka Finance And Investments", label: "Karnataka Finance And Investments" },
  { value: "Best Capital Services Ltd", label: "Best Capital Services Ltd" },
  { value: "Karamadai Sri Ganapathy Finance", label: "Karamadai Sri Ganapathy Finance" },
  { value: "Cent Bank Housing Finance Ltd", label: "Cent Bank Housing Finance Ltd" },
  { value: "Sri Garuda Finance And Associates", label: "Sri Garuda Finance And Associates" },
  { value: "Tamil Nadu Mercantile Bank", label: "Tamil Nadu Mercantile Bank" },
  { value: "Om Prakash Devra Peoples Co Bank", label: "Om Prakash Devra Peoples Co Bank" },
  { value: "Aravind Auto Finance", label: "Aravind Auto Finance" },
  { value: "Sri Devi Priya Finance", label: "Sri Devi Priya Finance" },
  { value: "Emeraald Fincorp", label: "Emeraald Fincorp" },
  { value: "Bandhan Bank Ltd", label: "Bandhan Bank Ltd" },
  { value: "Vivriti Capital Private Limited", label: "Vivriti Capital Private Limited" },
  { value: "Northern Arc Capital Limited", label: "Northern Arc Capital Limited" },
  { value: "Maa Jijau Urban Co Op Credit Soc Ltd Buldhana", label: "Maa Jijau Urban Co Op Credit Soc Ltd Buldhana" },
  { value: "Sri Adhavan Credits", label: "Sri Adhavan Credits" },
  { value: "Pune Zilla Sarvajanik Bandhkam Karmachari Sahakari Patsansta Pune", label: "Pune Zilla Sarvajanik Bandhkam Karmachari Sahakari Patsansta Pune" },
  { value: "Preet Motors And Co", label: "Preet Motors And Co" },
  { value: "Orix Leasing And Financial Services India Ltd", label: "Orix Leasing And Financial Services India Ltd" },
  { value: "Global Hire Purchase And Financial Services", label: "Global Hire Purchase And Financial Services" },
  { value: "Tata Capital", label: "Tata Capital" },
  { value: "Satya Deposits And Advance P Ltd", label: "Satya Deposits And Advance P Ltd" },
  { value: "Smt Auto Finance", label: "Smt Auto Finance" },
  { value: "Kamlesh Auto Finance", label: "Kamlesh Auto Finance" },
  { value: "Raosaheb Patwardhan Multi State Urbon Co Op Credit Society Ltd", label: "Raosaheb Patwardhan Multi State Urbon Co Op Credit Society Ltd" },
  { value: "Vijayam Auto Capital", label: "Vijayam Auto Capital" },
  { value: "Tjsb Bank Ltd", label: "Tjsb Bank Ltd" },
  { value: "Sriazhagumuruganauto Finance", label: "Sriazhagumuruganauto Finance" },
  { value: "Koutilya Vi So Sani", label: "Koutilya Vi So Sani" },
  { value: "Reliance Securities Ltd", label: "Reliance Securities Ltd" },
  { value: "Gajanankripa Micro Federation", label: "Gajanankripa Micro Federation" },
  { value: "Gosree Finance Limited", label: "Gosree Finance Limited" },
  { value: "Sbpp Co Operative Bank Ltd", label: "Sbpp Co Operative Bank Ltd" },
  { value: "Laxmi Bhavani Auto Finance", label: "Laxmi Bhavani Auto Finance" },
  { value: "Sai Durga Auto Finance", label: "Sai Durga Auto Finance" },
  { value: "Sri Lakshmi Venkata Sai Auto And Employee Finance", label: "Sri Lakshmi Venkata Sai Auto And Employee Finance" },
  { value: "The Sabarkantha Dist. Co Op Bank", label: "The Sabarkantha Dist. Co Op Bank" },
  { value: "Asian Development Bank", label: "Asian Development Bank" },
  { value: "Deg Deutsche Investitionsv Office In India", label: "Deg Deutsche Investitionsv Office In India" },
  { value: "Sri Ramtulasi Vehicle Finance", label: "Sri Ramtulasi Vehicle Finance" },
  { value: "Qatar National Bank Q. P. S. C", label: "Qatar National Bank Q. P. S. C" },
  { value: "European Investment Bank", label: "European Investment Bank" },
  { value: "State Bank Of Baroda", label: "State Bank Of Baroda" },
  { value: "Pacs", label: "Pacs" },
  { value: "S P K Sahakari Pathsanstha Maryadit", label: "S P K Sahakari Pathsanstha Maryadit" },
  { value: "Om Gramin Bigar Sheti Sahakari Pat Sanstha", label: "Om Gramin Bigar Sheti Sahakari Pat Sanstha" },
  { value: "Dholka Dakshin Vibhag Urban Sah Ban", label: "Dholka Dakshin Vibhag Urban Sah Ban" },
  { value: "Ss Two Wheeler Finance", label: "Ss Two Wheeler Finance" },
  { value: "Vasantraj Co Op Credit Society Ltd", label: "Vasantraj Co Op Credit Society Ltd" },
  { value: "Manjeri Housing Co Operative Society Ltd.No.M256", label: "Manjeri Housing Co Operative Society Ltd.No.M256" },
  { value: "Subhamangalaa Fin Corp", label: "Subhamangalaa Fin Corp" },
  { value: "Tuljabhavani Urb Mul St Co Op Sogl Td", label: "Tuljabhavani Urb Mul St Co Op Sogl Td" },
  { value: "Professor And Head Dept Of Environmental Science Tamilnadu Agricultural University", label: "Professor And Head Dept Of Environmental Science Tamilnadu Agricultural University" },
  { value: "Siddhnath Nag Sah Pat Sans Marya", label: "Siddhnath Nag Sah Pat Sans Marya" },
  { value: "District Scheduled Castes Service Coop Development Society Ltd", label: "District Scheduled Castes Service Coop Development Society Ltd" },
  { value: "Executive Director Dscscds Ltd Sangareddy", label: "Executive Director Dscscds Ltd Sangareddy" },
  { value: "Vaishnovi Fincare", label: "Vaishnovi Fincare" },
  { value: "Keral Gramin Bank", label: "Keral Gramin Bank" },
  { value: "Saunshi Urban Co Op Credit Society Ltd", label: "Saunshi Urban Co Op Credit Society Ltd" },
  { value: "Iifl Home Finance Ltd", label: "Iifl Home Finance Ltd" },
  { value: "Srinidhi Co Operative Limited Of Hyd", label: "Srinidhi Co Operative Limited Of Hyd" },
  { value: "Shree Ram Prazision Private Limited", label: "Shree Ram Prazision Private Limited" },
  { value: "Baalu Finance", label: "Baalu Finance" },
  { value: "Rare Asset Reconstruction Limited", label: "Rare Asset Reconstruction Limited" },
  { value: "Stree Nidhi Credit Co Operative Federation Ltd", label: "Stree Nidhi Credit Co Operative Federation Ltd" },
  { value: "Director Of Horticulture", label: "Director Of Horticulture" },
  { value: "North Eastern Development Finance Corporation Ltd", label: "North Eastern Development Finance Corporation Ltd" },
  { value: "1T9 Technology Pvt Ltd", label: "1T9 Technology Pvt Ltd" },
  { value: "Homeshree Housing Finance Limited", label: "Homeshree Housing Finance Limited" },
  { value: "Sri Venkata Sai Wheel Zone", label: "Sri Venkata Sai Wheel Zone" },
  { value: "Cholamandalam Inv Co Fin Ltd", label: "Cholamandalam Inv Co Fin Ltd" },
  { value: "Shivalik Small Finance Bank", label: "Shivalik Small Finance Bank" },
  { value: "Adani Finance Pvt Ltd", label: "Adani Finance Pvt Ltd" },
  { value: "Shree Vardhman Urban Co Op Creditsociety Ltd", label: "Shree Vardhman Urban Co Op Creditsociety Ltd" },
  { value: "Shree Yogeshari Multistate Co Op Bank Ltd", label: "Shree Yogeshari Multistate Co Op Bank Ltd" },
  { value: "Star Auto Finance", label: "Star Auto Finance" },
  { value: "Axis Finance Limited", label: "Axis Finance Limited" },
  { value: "Eduvanz Financing Private Limited", label: "Eduvanz Financing Private Limited" },
  { value: "Hinduja Leyland Finance", label: "Hinduja Leyland Finance" },
  { value: "Veerashiva Vikas Credit Souharda Sahakari Niyamitha", label: "Veerashiva Vikas Credit Souharda Sahakari Niyamitha" },
  { value: "Sumerpur Mercantile Urban Cooperative Bank Ltd.", label: "Sumerpur Mercantile Urban Cooperative Bank Ltd." },
  { value: "Akkmahadevi Mahila Vividodeshgalsa Sahakari Sanghmurgod", label: "Akkmahadevi Mahila Vividodeshgalsa Sahakari Sanghmurgod" },
  { value: "Induslnd Bank Ltd", label: "Induslnd Bank Ltd" },
  { value: "Chandrabhaga Urban Multistate Co Op", label: "Chandrabhaga Urban Multistate Co Op" },
  { value: "Girnar Capital Private Limited", label: "Girnar Capital Private Limited" },
  { value: "Mundhwa Vividha Karyakari Seva Shakari Society Ltd", label: "Mundhwa Vividha Karyakari Seva Shakari Society Ltd" },
  { value: "Hunnur Souhard Credit Sahakari Niyamita", label: "Hunnur Souhard Credit Sahakari Niyamita" },
  { value: "Shri Bramha Chaitanya Gondhlekar Maharaj Gramin Bigar Sheti Sahakari Pat Sanstha Marya Walwa", label: "Shri Bramha Chaitanya Gondhlekar Maharaj Gramin Bigar Sheti Sahakari Pat Sanstha Marya Walwa" },
  { value: "Veeracharya Babasaheb Kuchanure Zilla Nagari Sahakari Patsanstha Marya Sangli", label: "Veeracharya Babasaheb Kuchanure Zilla Nagari Sahakari Patsanstha Marya Sangli" },
  { value: "Executive Director Scscds Ltd Ranga Reddy Dist", label: "Executive Director Scscds Ltd Ranga Reddy Dist" },
  { value: "Naga Venkata Sai Auto Finance", label: "Naga Venkata Sai Auto Finance" },
  { value: "Sri Srinivasa Auto Finance", label: "Sri Srinivasa Auto Finance" },
  { value: "Sbm Bank India Ltd", label: "Sbm Bank India Ltd" },
  { value: "Edelweiss Credit Plus Fund", label: "Edelweiss Credit Plus Fund" },
  { value: "Sri Chitti Bike Zone", label: "Sri Chitti Bike Zone" },
  { value: "Sri Lakshmi Ganapathi Finance", label: "Sri Lakshmi Ganapathi Finance" },
  { value: "Shri Jay Ambey Auto Finance", label: "Shri Jay Ambey Auto Finance" },
  { value: "Utkarsh Small Finance Bank Ltd", label: "Utkarsh Small Finance Bank Ltd" },
  { value: "Indostar Capital Finance Co Ltd", label: "Indostar Capital Finance Co Ltd" },
  { value: "Kaveri Hp And Finance", label: "Kaveri Hp And Finance" },
  { value: "Sri Mahalaxmi Motor Credit", label: "Sri Mahalaxmi Motor Credit" },
  { value: "Volvo Financial Services", label: "Volvo Financial Services" },
  { value: "R K Reddy Finance", label: "R K Reddy Finance" },
  { value: "The Khanapur Pkps Sangh Ltd", label: "The Khanapur Pkps Sangh Ltd" },
  { value: "Suryakiran Urban Co Op Society Ltd", label: "Suryakiran Urban Co Op Society Ltd" },
  { value: "Sree Velavan Credit", label: "Sree Velavan Credit" },
  { value: "Pfc Green Energy Limited", label: "Pfc Green Energy Limited" },
  { value: "Caspian Impact Investments Private Limited Nbfc", label: "Caspian Impact Investments Private Limited Nbfc" },
  { value: "Srei Equipment Finance Ltd", label: "Srei Equipment Finance Ltd" },
  { value: "Uma Co Operative Bank Ltd", label: "Uma Co Operative Bank Ltd" },
  { value: "Ramanujar Auto Invesments", label: "Ramanujar Auto Invesments" },
  { value: "Bka Finance", label: "Bka Finance" },
  { value: "Ark Finance", label: "Ark Finance" },
  { value: "Agriwise Finserv Limited", label: "Agriwise Finserv Limited" },
  { value: "Kamadhenu Leasing", label: "Kamadhenu Leasing" },
  { value: "Gramadhar Finserve Private Limited", label: "Gramadhar Finserve Private Limited" },
  { value: "A P E P D C L", label: "A P E P D C L" },
  { value: "Hindusthan Finance", label: "Hindusthan Finance" },
  { value: "Shiv Parvati Mahila Nag.Sah.Bank", label: "Shiv Parvati Mahila Nag.Sah.Bank" },
  { value: "Gayathri Hire Purchase", label: "Gayathri Hire Purchase" },
  { value: "Sri Gayathri Hire Purchase", label: "Sri Gayathri Hire Purchase" },
  { value: "R K Siddhi Vinayak Nagri Sahakari Patsanstha Ltd", label: "R K Siddhi Vinayak Nagri Sahakari Patsanstha Ltd" },
  { value: "Jijau Maa Saheb Multistate Co Op", label: "Jijau Maa Saheb Multistate Co Op" },
  { value: "Sankalp Nagari Sahakari Patsanstha Marya", label: "Sankalp Nagari Sahakari Patsanstha Marya" },
  { value: "Shri Rukhmini Sahakari Bank Ltd", label: "Shri Rukhmini Sahakari Bank Ltd" },
  { value: "Shri Rukmini Sahakari Bank Ltd", label: "Shri Rukmini Sahakari Bank Ltd" },
  { value: "The Saraswat Co Op Bank Limited", label: "The Saraswat Co Op Bank Limited" },
  { value: "Indian F.Financer Ltd", label: "Indian F.Financer Ltd" },
  { value: "Chitegaon Gramin Bigar Sheti Patsanstha", label: "Chitegaon Gramin Bigar Sheti Patsanstha" },
  { value: "B 67 Lolla Pacs Ltd", label: "B 67 Lolla Pacs Ltd" },
  { value: "Mcv Vehicle Finance", label: "Mcv Vehicle Finance" },
  { value: "Umiya Automobile", label: "Umiya Automobile" },
  { value: "Guj Alpsankhyak Nana Andviaks Nigam", label: "Guj Alpsankhyak Nana Andviaks Nigam" },
  { value: "Exclusive Leasing And Finance Pvt Ltd", label: "Exclusive Leasing And Finance Pvt Ltd" },
  { value: "Ratnaafin Capital Pvt Ltd.", label: "Ratnaafin Capital Pvt Ltd." },
  { value: "Thirumurugan Investments", label: "Thirumurugan Investments" },
  { value: "Mangalam Enterprises Ltd", label: "Mangalam Enterprises Ltd" },
  { value: "Indoor Vyavasaya Sahakari Sangha Niyamit Indoor", label: "Indoor Vyavasaya Sahakari Sangha Niyamit Indoor" },
  { value: "Cholamandal Inv Co Fin Ltd", label: "Cholamandal Inv Co Fin Ltd" },
  { value: "The Sahu Seva Sahkari Mandali Ltd", label: "The Sahu Seva Sahkari Mandali Ltd" },
  { value: "Mangalam Enterprises P Ltd", label: "Mangalam Enterprises P Ltd" },
  { value: "Mangalam Enterprises Pvt Ltd", label: "Mangalam Enterprises Pvt Ltd" },
  { value: "Pushparaj Co.Op Credit Society", label: "Pushparaj Co.Op Credit Society" },
  { value: "Shree Santaji Gramin Big Sh Sah P S", label: "Shree Santaji Gramin Big Sh Sah P S" },
  { value: "Vijaya Mahila Gramin Bigar Sheti Sahakari Patsawantha Maryadit", label: "Vijaya Mahila Gramin Bigar Sheti Sahakari Patsawantha Maryadit" },
  { value: "Fullerton India Home Finance Ltd.", label: "Fullerton India Home Finance Ltd." },
  { value: "Manvat Urban Co Op Bank Ltd", label: "Manvat Urban Co Op Bank Ltd" },
  { value: "Alleywell Finserve Nidhi Limited", label: "Alleywell Finserve Nidhi Limited" },
  { value: "Kolhapur Parshwanath Sahakari Patsanstha Maryadit", label: "Kolhapur Parshwanath Sahakari Patsanstha Maryadit" },
  { value: "Aryavrat Bank", label: "Aryavrat Bank" },
  { value: "Vijetha Hp And Financiers", label: "Vijetha Hp And Financiers" },
  { value: "The Pisavar Vi.Ka. Se. Sah. Man. Ltd", label: "The Pisavar Vi.Ka. Se. Sah. Man. Ltd" },
  { value: "Bajaj Housing Finance Ltd", label: "Bajaj Housing Finance Ltd" },
  { value: "Shree Charel Seva Sahakari Mandali Ltd", label: "Shree Charel Seva Sahakari Mandali Ltd" },
  { value: "Ambareli Seva Sahkari Mandali Ltd", label: "Ambareli Seva Sahkari Mandali Ltd" },
  { value: "Shiva Pattina Sahakara Sangha N", label: "Shiva Pattina Sahakara Sangha N" },
  { value: "Shabri Investments Pvt Ltd", label: "Shabri Investments Pvt Ltd" },
  { value: "The Vyaval Seva Sahakari Mandali", label: "The Vyaval Seva Sahakari Mandali" },
  { value: "Shri Mangalnath Ms Co Op Credit Soc Ltd", label: "Shri Mangalnath Ms Co Op Credit Soc Ltd" },
  { value: "Shri Sadguru Urban Co Op Credit Society Ltd", label: "Shri Sadguru Urban Co Op Credit Society Ltd" },
  { value: "The Bhuldana Urban Co Op Cr Soc Ltd", label: "The Bhuldana Urban Co Op Cr Soc Ltd" },
  { value: "Sarada Vehicle Finance Serviecs", label: "Sarada Vehicle Finance Serviecs" },
  { value: "The Suvarna Nagri Sahakari Patsanstha Maryadit Nashik", label: "The Suvarna Nagri Sahakari Patsanstha Maryadit Nashik" },
  { value: "Zimidara Finance Company", label: "Zimidara Finance Company" },
  { value: "Shree Om Finance", label: "Shree Om Finance" },
  { value: "Al Khair Baitul Maal Co Operative", label: "Al Khair Baitul Maal Co Operative" },
  { value: "Shree Bhimora Seva Sahakari Mandli Ltd.", label: "Shree Bhimora Seva Sahakari Mandli Ltd." },
  { value: "Vardhaman Nagri Sahkari Path Sanstha", label: "Vardhaman Nagri Sahkari Path Sanstha" },
  { value: "Devgiri Nagari Sahkari Path Sanstha", label: "Devgiri Nagari Sahkari Path Sanstha" },
  { value: "First Abu Dhabi Bank Pjsc", label: "First Abu Dhabi Bank Pjsc" },
  { value: "Sri Venkata Satya Deva Finance", label: "Sri Venkata Satya Deva Finance" },
  { value: "Kothikampa Seva Sahkari Mandali", label: "Kothikampa Seva Sahkari Mandali" },
  { value: "The Cretive Brain Academy", label: "The Cretive Brain Academy" },
  { value: "Executive Director Vdscscds Ltd Vikarabad", label: "Executive Director Vdscscds Ltd Vikarabad" },
  { value: "Maratha Co Operative Bank Ltd", label: "Maratha Co Operative Bank Ltd" },
  { value: "Trillion Loans Fintech Pvt. Ltd", label: "Trillion Loans Fintech Pvt. Ltd" },
  { value: "Aurangabad Jilha Yashaswini Mahila Swayam Sahayata Gat Sahakari Patsanstha", label: "Aurangabad Jilha Yashaswini Mahila Swayam Sahayata Gat Sahakari Patsanstha" },
  { value: "Aundha Urban Co Op Credit Socitey Ltd", label: "Aundha Urban Co Op Credit Socitey Ltd" },
  { value: "Jagatguru Tukaram Maharaj Nagari Sahakari Patsanstha Maryadit", label: "Jagatguru Tukaram Maharaj Nagari Sahakari Patsanstha Maryadit" },
  { value: "Hingoli Urban Mahila Co Op Credit Society Ltd", label: "Hingoli Urban Mahila Co Op Credit Society Ltd" },
  { value: "Thi Javangadh Seva Sahkari Mandali", label: "Thi Javangadh Seva Sahkari Mandali" },
  { value: "Perpetuity Capital", label: "Perpetuity Capital" },
  { value: "Anand Sons Finance Pvt Ltd", label: "Anand Sons Finance Pvt Ltd" },
  { value: "Goldline Finance Private Limited", label: "Goldline Finance Private Limited" },
  { value: "Seva Mutually Aided Cooperative Credit Society Ltd", label: "Seva Mutually Aided Cooperative Credit Society Ltd" },
  { value: "Shri Padasan Juth Seva Sahkari Mandali", label: "Shri Padasan Juth Seva Sahkari Mandali" },
  { value: "Multiline Share Merchantile Pvt Ltd", label: "Multiline Share Merchantile Pvt Ltd" },
  { value: "Chaudhary Chhamb Hire Purchase", label: "Chaudhary Chhamb Hire Purchase" },
  { value: "Shree Amar Raj Finance Pvt Ltd", label: "Shree Amar Raj Finance Pvt Ltd" },
  { value: "Anr Finlease Pvt Ltd", label: "Anr Finlease Pvt Ltd" },
  { value: "Bhagyauday Sahakari Path Maryadit", label: "Bhagyauday Sahakari Path Maryadit" },
  { value: "Ramdas Auto", label: "Ramdas Auto" },
  { value: "Kamdhenu Auto Enterprises", label: "Kamdhenu Auto Enterprises" },
  { value: "Sangi District Central Co Op Bank Limited", label: "Sangi District Central Co Op Bank Limited" },
  { value: "Sri Siri Enterprises", label: "Sri Siri Enterprises" },
  { value: "The Harugeri Souharda Sahakari Niyamit", label: "The Harugeri Souharda Sahakari Niyamit" },
  { value: "Venugopal G B Ss Pat Sanstha Pat Sanstha Ltd", label: "Venugopal G B Ss Pat Sanstha Pat Sanstha Ltd" },
  { value: "The Sikanderpur Co.", label: "The Sikanderpur Co." },
  { value: "Jaunpur Co Op Credit Soc Ltd", label: "Jaunpur Co Op Credit Soc Ltd" },
  { value: "The Sikanderpur Co Op Agri Mp S. S. Ltd", label: "The Sikanderpur Co Op Agri Mp S. S. Ltd" },
  { value: "Jai Mahesh Multi State Ltd", label: "Jai Mahesh Multi State Ltd" },
  { value: "Jai Mahesh Multistate Vehicle", label: "Jai Mahesh Multistate Vehicle" },
  { value: "Amirtha Finance", label: "Amirtha Finance" },
  { value: "Navasakthi Group Auto Finance", label: "Navasakthi Group Auto Finance" },
  { value: "Ras Credit Services Pvt Ltd", label: "Ras Credit Services Pvt Ltd" },
  { value: "Ms Jndd Finance Co", label: "Ms Jndd Finance Co" },
  { value: "Nap Motors And Finance Pvt Ltd", label: "Nap Motors And Finance Pvt Ltd" },
  { value: "Jivdani Urban Co Op Credit Society Ltd", label: "Jivdani Urban Co Op Credit Society Ltd" },
  { value: "Jai Deogiri Nagari Sahakari Patsanstha Maryadit", label: "Jai Deogiri Nagari Sahakari Patsanstha Maryadit" },
  { value: "Navnath Rural Co Op Credit Society Ltd.", label: "Navnath Rural Co Op Credit Society Ltd." },
  { value: "Shri Channabasava Pattina Souharda", label: "Shri Channabasava Pattina Souharda" },
  { value: "Bank Of Baroda Ltd", label: "Bank Of Baroda Ltd" },
  { value: "Gs Mahanagar Co Operative Bank Limited", label: "Gs Mahanagar Co Operative Bank Limited" },
  { value: "Mgs Fincap Pvt Ltd", label: "Mgs Fincap Pvt Ltd" },
  { value: "Barashiv Hanuman Gramin Bigar Sheti Sah Pat Sanstha Maryadit", label: "Barashiv Hanuman Gramin Bigar Sheti Sah Pat Sanstha Maryadit" },
  { value: "Abhinandan Enterprise", label: "Abhinandan Enterprise" },
  { value: "Solapur Siddheshwar Sahakari Bank Ltd", label: "Solapur Siddheshwar Sahakari Bank Ltd" },
  { value: "Honeywell Finserv Pvt Ltd", label: "Honeywell Finserv Pvt Ltd" },
  { value: "Standard Chartered Capital Ltd", label: "Standard Chartered Capital Ltd" },
  { value: "Jassi Finance Private Limited", label: "Jassi Finance Private Limited" },
  { value: "Ghalla And Bhansali Securities Pvt Ltd", label: "Ghalla And Bhansali Securities Pvt Ltd" },
  { value: "Sai Roshni Capital Private Limited", label: "Sai Roshni Capital Private Limited" },
  { value: "Autotrac Finance Ltd", label: "Autotrac Finance Ltd" },
  { value: "Jay Bajrang Nagri Sahakari Patsanstha", label: "Jay Bajrang Nagri Sahakari Patsanstha" },
  { value: "Rcf Finance Pvt Ltd", label: "Rcf Finance Pvt Ltd" },
  { value: "Mercedes Benz Financial Services India Pvt Ltd", label: "Mercedes Benz Financial Services India Pvt Ltd" },
  { value: "A P Patil Sarvyoday Gramin Sahakari", label: "A P Patil Sarvyoday Gramin Sahakari" },
  { value: "R K Chandru Finance", label: "R K Chandru Finance" },
  { value: "Jai Maesh Multistate Co.So.Ltd", label: "Jai Maesh Multistate Co.So.Ltd" },
  { value: "Baid Finserv Limited", label: "Baid Finserv Limited" },
  { value: "Ratnaafin Capital Private Limited", label: "Ratnaafin Capital Private Limited" },
  { value: "Rajarajeswari Co Operative Bank Ltd", label: "Rajarajeswari Co Operative Bank Ltd" },
  { value: "Drda", label: "Drda" },
  { value: "Aseem Infrastructure Finance Co. Ltd", label: "Aseem Infrastructure Finance Co. Ltd" },
  { value: "Shriram Finance Limiter", label: "Shriram Finance Limiter" },
  { value: "Katavul Finance", label: "Katavul Finance" },
  { value: "Kholeshwar Multistate Co Operive Credit So Ltd", label: "Kholeshwar Multistate Co Operive Credit So Ltd" },
  { value: "Shriram Finance Ltd", label: "Shriram Finance Ltd" },
  { value: "Bantia Finance", label: "Bantia Finance" },
  { value: "Shriram Finance Limited", label: "Shriram Finance Limited" },
  { value: "The Akola Washim District Central Co Op Bank Ltd", label: "The Akola Washim District Central Co Op Bank Ltd" },
  { value: "D.V.S.Finance", label: "D.V.S.Finance" },
  { value: "Kalpesh Kumar Huf", label: "Kalpesh Kumar Huf" },
  { value: "Shreyans Bhandari", label: "Shreyans Bhandari" },
  { value: "Shree Chandrapur Seva Sahakari Mandali Ltd", label: "Shree Chandrapur Seva Sahakari Mandali Ltd" },
  { value: "Bothra Enterprises", label: "Bothra Enterprises" },
  { value: "Ed Dscs Co Operative Development Society Ltd", label: "Ed Dscs Co Operative Development Society Ltd" },
  { value: "Bramhadeo Dada Mane Sah Bank Ltd", label: "Bramhadeo Dada Mane Sah Bank Ltd" },
  { value: "Swami Samarth Multistate Co Op Credit Society Ltd", label: "Swami Samarth Multistate Co Op Credit Society Ltd" },
  { value: "Sree Murugan Nithiyagam", label: "Sree Murugan Nithiyagam" },
  { value: "Padadhara Juth Seva Sahakari Mandali Ltd.", label: "Padadhara Juth Seva Sahakari Mandali Ltd." },
  { value: "The Gadhinglaj Urban Cooperative Bank", label: "The Gadhinglaj Urban Cooperative Bank" },
  { value: "Saptshrungi Mata Bigar Sheti Sahakari Path Saustha Maryadit", label: "Saptshrungi Mata Bigar Sheti Sahakari Path Saustha Maryadit" },
  { value: "Sri Sairam Automobiles Ltd", label: "Sri Sairam Automobiles Ltd" },
  { value: "Ghitora Finance And Investment P Ltd", label: "Ghitora Finance And Investment P Ltd" },
  { value: "Sai Krupa Sahakari Patpedhi Maryadi", label: "Sai Krupa Sahakari Patpedhi Maryadi" },
  { value: "Ganesh Urban Co Op Credit Soc Ltd", label: "Ganesh Urban Co Op Credit Soc Ltd" },
  { value: "Mufin Green Finance Ltd", label: "Mufin Green Finance Ltd" },
  { value: "Prathama Up Gramin Bank", label: "Prathama Up Gramin Bank" },
  { value: "Apm Finvest Ltd", label: "Apm Finvest Ltd" },
  { value: "Sjvn Limited Himachal Pradesh", label: "Sjvn Limited Himachal Pradesh" },
  { value: "Mahindra And Mahindra Fin Ser Ltd", label: "Mahindra And Mahindra Fin Ser Ltd" },
  { value: "Adani Capital Finance Co Ltd", label: "Adani Capital Finance Co Ltd" },
  { value: "Utkal Grameen Bank", label: "Utkal Grameen Bank" },
  { value: "Aaradhya Capital Private Limited", label: "Aaradhya Capital Private Limited" },
  { value: "Protium Finance Limited", label: "Protium Finance Limited" },
  { value: "The Kottakkal Co Operative Urban Bank Ltd", label: "The Kottakkal Co Operative Urban Bank Ltd" },
  { value: "Paisabuddy Finance Ltd", label: "Paisabuddy Finance Ltd" },
  { value: "Shree Lunsar Juth Seva Sahkari Mandli Ltd.", label: "Shree Lunsar Juth Seva Sahkari Mandli Ltd." },
  { value: "Anu Auto Finance", label: "Anu Auto Finance" },
  { value: "Sai Siri Vehicle Finance", label: "Sai Siri Vehicle Finance" },
  { value: "Ugro Capital Ltd", label: "Ugro Capital Ltd" },
  { value: "Mmfsl", label: "Mmfsl" },
  { value: "Hua Nan Commercial Bank Ltd", label: "Hua Nan Commercial Bank Ltd" },
  { value: "Thanjavur Central Cooperative Bank Ltd.", label: "Thanjavur Central Cooperative Bank Ltd." },
  { value: "The Marine Products Export Development Authority Mpeda", label: "The Marine Products Export Development Authority Mpeda" },
  { value: "Nishant Gramin Bigar Shati Sah Pat Sanstha Maryadit", label: "Nishant Gramin Bigar Shati Sah Pat Sanstha Maryadit" },
  { value: "R K Thakre Sahakari Pathsantha", label: "R K Thakre Sahakari Pathsantha" },
  { value: "R K Thakre Sahakari Pathsantha Maryadit", label: "R K Thakre Sahakari Pathsantha Maryadit" },
  { value: "Oriental Bank Of Comerce", label: "Oriental Bank Of Comerce" },
  { value: "Priyadarshani Nagari Sahakari Bank Maryadit", label: "Priyadarshani Nagari Sahakari Bank Maryadit" },
  { value: "Laxmi Savings And Credit Co Operative Society Ltd", label: "Laxmi Savings And Credit Co Operative Society Ltd" },
  { value: "Siri Venkateswara Motors", label: "Siri Venkateswara Motors" },
  { value: "The Salem Central Co Operative Bank Ltd", label: "The Salem Central Co Operative Bank Ltd" },
  { value: "Wdcc Bank", label: "Wdcc Bank" },
  { value: "Gudaleshwar Gra Big She Sah Pat San", label: "Gudaleshwar Gra Big She Sah Pat San" },
  { value: "Shrimant Thorale Bajirao Peshve Nag", label: "Shrimant Thorale Bajirao Peshve Nag" },
  { value: "United Overseas Bank Limited", label: "United Overseas Bank Limited" },
  { value: "Mamta Projects Pvt Ltd", label: "Mamta Projects Pvt Ltd" },
  { value: "Ashokrao Bhau Shinde Gr.Big.Sah.Pat, Nashik", label: "Ashokrao Bhau Shinde Gr.Big.Sah.Pat, Nashik" },
  { value: "The Villupuram Dist. Central Co Op Bank Ltd", label: "The Villupuram Dist. Central Co Op Bank Ltd" },
  { value: "Bandhan Bank Ltd, Nashik", label: "Bandhan Bank Ltd, Nashik" },
  { value: "Makaram Finance", label: "Makaram Finance" },
  { value: "Aryavart Bank", label: "Aryavart Bank" },
  { value: "Assets Care And Reconstruction Enterprise Limited Acre", label: "Assets Care And Reconstruction Enterprise Limited Acre" },
  { value: "Assets Care And Reconstruction Enterprise Limited Acre", label: "Assets Care And Reconstruction Enterprise Limited Acre" },
  { value: "The Business Co Op .Bak.Ltd", label: "The Business Co Op .Bak.Ltd" },
  { value: "Vishuwamurti Co. Op Credit Society", label: "Vishuwamurti Co. Op Credit Society" },
  { value: "Vardhaman Nagari Sahakari Patsanstha", label: "Vardhaman Nagari Sahakari Patsanstha" },
  { value: "Vivek Vardhini Patsanstha", label: "Vivek Vardhini Patsanstha" },
  { value: "Hema All Types Of Car Finance", label: "Hema All Types Of Car Finance" },
  { value: "Kowsalya Vehicle Finance", label: "Kowsalya Vehicle Finance" },
  { value: "Lakhalgaon Vividh Kary.Sah.Vikas Seva Sanstha, Lakhalgaon", label: "Lakhalgaon Vividh Kary.Sah.Vikas Seva Sanstha, Lakhalgaon" },
  { value: "Icici Home Finance Co. Ltd", label: "Icici Home Finance Co. Ltd" },
  { value: "Mithra Auto Linars", label: "Mithra Auto Linars" },
  { value: "Aarsh Fincon Limited", label: "Aarsh Fincon Limited" },
  { value: "Sree Balajee Finance", label: "Sree Balajee Finance" },
  { value: "Capital India Finance Limited", label: "Capital India Finance Limited" },
  { value: "Shrenuj And Company Ltd Through Liqudator", label: "Shrenuj And Company Ltd Through Liqudator" },
  { value: "Mpee Finance Private Limited", label: "Mpee Finance Private Limited" },
  { value: "Jay Umiya Lizig Finance", label: "Jay Umiya Lizig Finance" },
  { value: "Alkhair Nagri Sahakari Patsanstha Ltd", label: "Alkhair Nagri Sahakari Patsanstha Ltd" },
  { value: "Shriram Finance Co Ltd", label: "Shriram Finance Co Ltd" },
  { value: "Ballaleshwar Co Op Credit Soc Ltd", label: "Ballaleshwar Co Op Credit Soc Ltd" },
  { value: "True Blue Asset Services Private Limiited", label: "True Blue Asset Services Private Limiited" },
  { value: "Tandon Building Material Pvt Ltd", label: "Tandon Building Material Pvt Ltd" },
  { value: "Paccs", label: "Paccs" },
  { value: "Shri Ram Finance Ltd", label: "Shri Ram Finance Ltd" },
  { value: "Dhanalakshmi Finance", label: "Dhanalakshmi Finance" },
  { value: "Sharat Slpa Sankyatara Vivi Vio Sahakarikakhandaki", label: "Sharat Slpa Sankyatara Vivi Vio Sahakarikakhandaki" },
  { value: "Shivashree Souhardha Patthina Sahakari Sangha Niyamitha", label: "Shivashree Souhardha Patthina Sahakari Sangha Niyamitha" },
  { value: "Granth Enterprises", label: "Granth Enterprises" },
  { value: "Gujarat Adijati Vikas Corporation", label: "Gujarat Adijati Vikas Corporation" },
  { value: "Samata Nagari Sahakari Pathsanstha Marya., Nashik", label: "Samata Nagari Sahakari Pathsanstha Marya., Nashik" },
  { value: "Dhanashri Nagari Sah Path San Maryadit", label: "Dhanashri Nagari Sah Path San Maryadit" },
  { value: "Shree Mahalaxmi Finance And Leasing", label: "Shree Mahalaxmi Finance And Leasing" },
  { value: "Shri Pimpleshwar Gramin Bigarsheti Sahakari Pathsawastha Marya", label: "Shri Pimpleshwar Gramin Bigarsheti Sahakari Pathsawastha Marya" },
  { value: "Grow Money Capital Pvt Ltd", label: "Grow Money Capital Pvt Ltd" },
  { value: "Supa Kurel Vibhag Seva Sahakari Mandli Ltd", label: "Supa Kurel Vibhag Seva Sahakari Mandli Ltd" },
  { value: "Small Industrial Development Bank Of India", label: "Small Industrial Development Bank Of India" },
  { value: "Bhola Auto Finlease Pvt Ltd", label: "Bhola Auto Finlease Pvt Ltd" },
  { value: "Aeon Credit Service India Pvt Ltd.Mumbai", label: "Aeon Credit Service India Pvt Ltd.Mumbai" },
  { value: "Sambalpur Dist. Cooperative Central Bank Ltd", label: "Sambalpur Dist. Cooperative Central Bank Ltd" },
  { value: "Idbi Trusteeship", label: "Idbi Trusteeship" },
  { value: "Yadrav Co Operative Bank Ltd, Yadrav", label: "Yadrav Co Operative Bank Ltd, Yadrav" },
  { value: "Yadrav Co Operative Bank Ltd,Yadrav", label: "Yadrav Co Operative Bank Ltd,Yadrav" },
  { value: "Ghatandevi Nagari Sah. Path Sanstha, Igatpuri", label: "Ghatandevi Nagari Sah. Path Sanstha, Igatpuri" },
  { value: "Shree Dhuva Juth Seva Sahakari Mandali Ltd", label: "Shree Dhuva Juth Seva Sahakari Mandali Ltd" },
  { value: "Clime Finance Private Limited", label: "Clime Finance Private Limited" },
  { value: "India Post Payment Bank", label: "India Post Payment Bank" },
  { value: "The Anant Sahakari Sharafi Mandali Limited", label: "The Anant Sahakari Sharafi Mandali Limited" },
  { value: "Patni Financial Advisors Private Limited", label: "Patni Financial Advisors Private Limited" },
  { value: "The Ndvs Bank Ltd", label: "The Ndvs Bank Ltd" },
  { value: "Bayfront Infrastructure Capital", label: "Bayfront Infrastructure Capital" },
  { value: "Bayfront Infrastructure Management Pte. Ltd", label: "Bayfront Infrastructure Management Pte. Ltd" },
  { value: "Bayfront Infrastructure Capital Ii Pte. Ltd", label: "Bayfront Infrastructure Capital Ii Pte. Ltd" },
  { value: "Bayfront Infrastructure Capital Iii Pte. Ltd", label: "Bayfront Infrastructure Capital Iii Pte. Ltd" },
  { value: "Shri Rokadeshwar Gra Big S S P M", label: "Shri Rokadeshwar Gra Big S S P M" },
  { value: "Cnhi Capital India Pvt. Ltd.", label: "Cnhi Capital India Pvt. Ltd." },
  { value: "Autotrac Finance Limited", label: "Autotrac Finance Limited" },
  { value: "Shree Nana Khijadiya Seva S M L", label: "Shree Nana Khijadiya Seva S M L" },
  { value: "The Chak Kalan Coop. Agriculture Multipurpose Society Ltd", label: "The Chak Kalan Coop. Agriculture Multipurpose Society Ltd" },
  { value: "Choice Finserv Private Limited", label: "Choice Finserv Private Limited" },
  { value: "Sri Nandi Souharda Sahakari Niyamitha", label: "Sri Nandi Souharda Sahakari Niyamitha" },
  { value: "Kopargaon Tal Auto Rickshaw Co Op", label: "Kopargaon Tal Auto Rickshaw Co Op" },
  { value: "Rajarambapu Patil Nagari Saha Pat Sanstha Maryadit Ashta", label: "Rajarambapu Patil Nagari Saha Pat Sanstha Maryadit Ashta" },
  { value: "Shri Sanmati Sagar Vividhoddeshagala Souhard Sahakari Sangh Nyt", label: "Shri Sanmati Sagar Vividhoddeshagala Souhard Sahakari Sangh Nyt" },
  { value: "Sai Vehicle Finance", label: "Sai Vehicle Finance" },
  { value: "Aditya Birla Health Insurance Company Ltd", label: "Aditya Birla Health Insurance Company Ltd" },
  { value: "Aditya Birla Health Insurance Co Ltd", label: "Aditya Birla Health Insurance Co Ltd" },
  { value: "Sahasra Auto Finance", label: "Sahasra Auto Finance" },
  { value: "Apgvb", label: "Apgvb" },
  { value: "Ez Finance", label: "Ez Finance" },
  { value: "Apti Financial Services", label: "Apti Financial Services" },
  { value: "Annapoorrnamatha Capital Services Llp", label: "Annapoorrnamatha Capital Services Llp" },
  { value: "S M F G India Credit", label: "S M F G India Credit" },
  { value: "Sri Maruthi Bankers", label: "Sri Maruthi Bankers" },
  { value: "Akasa Finance Limited", label: "Akasa Finance Limited" },
  { value: "Shrijeet Finance Pvt Ltd", label: "Shrijeet Finance Pvt Ltd" },
  { value: "The Nimbhora Vividh Karyakari Seva Sahakari Mandli Ltd.", label: "The Nimbhora Vividh Karyakari Seva Sahakari Mandli Ltd." },
  { value: "Shrikrishna Gra Bi Sah Pat", label: "Shrikrishna Gra Bi Sah Pat" },
  { value: "Accretive Cleantech Finance Private Limited", label: "Accretive Cleantech Finance Private Limited" },
  { value: "Mahavir Sharan Co Op Credit Sty Ltd", label: "Mahavir Sharan Co Op Credit Sty Ltd" },
  { value: "Smfg India Credit Co. Ltd", label: "Smfg India Credit Co. Ltd" },
  { value: "Re Finance Pvt Ltd", label: "Re Finance Pvt Ltd" },
  { value: "Smfg India Home Finance Co Ltd", label: "Smfg India Home Finance Co Ltd" },
  { value: "Lakshmi Ganapathi Motors", label: "Lakshmi Ganapathi Motors" },
  { value: "The Conmos Co Op Bank Ltd", label: "The Conmos Co Op Bank Ltd" },
  { value: "Priydarshani Sahakari Patsanstha Ltd", label: "Priydarshani Sahakari Patsanstha Ltd" },
  { value: "Kishor Suryawanshi Nagari Sahakari Patsanstha Ltd", label: "Kishor Suryawanshi Nagari Sahakari Patsanstha Ltd" },
  { value: "Loknete R.D. Appa Kshirsagar Sah.Bank Ltd, Niphad", label: "Loknete R.D. Appa Kshirsagar Sah.Bank Ltd, Niphad" },
  { value: "Shri Aappu Dada Neje Dudhganga G B S S Pat Sanstha", label: "Shri Aappu Dada Neje Dudhganga G B S S Pat Sanstha" },
  { value: "The Federal Bank Limited", label: "The Federal Bank Limited" },
  { value: "Kanifnath Gramin Biger Sheti Sahakari Pathsantha Maryadit", label: "Kanifnath Gramin Biger Sheti Sahakari Pathsantha Maryadit" },
  { value: "Cholamandalam Home Fin. Ltd", label: "Cholamandalam Home Fin. Ltd" },
  { value: "Kishor Suryawanshi Nagari Sah. Pat.", label: "Kishor Suryawanshi Nagari Sah. Pat." },
  { value: "Bhadli Juth Seva Sahakari Mandali", label: "Bhadli Juth Seva Sahakari Mandali" },
  { value: "Shri Mahesh Nag Sah Pat San Ltd", label: "Shri Mahesh Nag Sah Pat San Ltd" },
  { value: "Chatrapati Sambhaji Maharaj Sahakari Path Maryadit", label: "Chatrapati Sambhaji Maharaj Sahakari Path Maryadit" },
  { value: "The Municiple Co Op Bank Ltd", label: "The Municiple Co Op Bank Ltd" },
  { value: "Shree Pipaliya Raj Juth Seva Sahakari Mandli Ltd", label: "Shree Pipaliya Raj Juth Seva Sahakari Mandli Ltd" },
  { value: "Mahaprabhu Service Co Operative Society Ltd", label: "Mahaprabhu Service Co Operative Society Ltd" },
  { value: "National Asset Reconstruction Company Limited", label: "National Asset Reconstruction Company Limited" },
  { value: "N Sai Multi State Co Opp Credit Society Ltd", label: "N Sai Multi State Co Opp Credit Society Ltd" },
  { value: "Ganesh Mul State Co Op Cre So Ltd B", label: "Ganesh Mul State Co Op Cre So Ltd B" },
  { value: "Padma Pujitha Auto Consultancy", label: "Padma Pujitha Auto Consultancy" },
  { value: "The Nashik Dist. Central Co Op. Bank Ltd. Nashik", label: "The Nashik Dist. Central Co Op. Bank Ltd. Nashik" },
  { value: "The Maharashtra State Co Operative Bank Ltd", label: "The Maharashtra State Co Operative Bank Ltd" },
  { value: "Trillionloans Fintech Private Limited", label: "Trillionloans Fintech Private Limited" },
  { value: "Chattrapati Shivaji Maharaj Gram.Big.Sah.Pat.Marya, Wadali Bhoi", label: "Chattrapati Shivaji Maharaj Gram.Big.Sah.Pat.Marya, Wadali Bhoi" },
  { value: "Sri Gokul Auto Finance", label: "Sri Gokul Auto Finance" },
  { value: "Yuva Vikas Sahakaripatsanstha Ltd", label: "Yuva Vikas Sahakaripatsanstha Ltd" },
]



