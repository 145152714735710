import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { decryptData } from "../services/auth-service";


export default function authHeader() {
  const user = decryptData(localStorage.getItem('user'));
  if (user && user.jwtToken) {
    return { Authorization: 'Bearer ' + user.jwtToken };
  } else {
    return {};
  }
}