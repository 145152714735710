import axios from "axios";
import AuthHeader from "./auth-header";
import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';
import { decryptData } from "../services/auth-service";

const AGENCY_URL = process.env.REACT_APP_AGENCY_URL;
class AdminService {
  //AGENCY API CALLS
  //ADD & UPDATE
  addAgency(addAgencyRequest) {
    return axios.post(`${AGENCY_URL}/CreateUpdateAgency`, addAgencyRequest, {
      headers: AuthHeader(),
    });
  }

  //PAGINATION
  listAgencies(listAgenciesRequest) {
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/ListAgencies",
      listAgenciesRequest,
      { headers: AuthHeader() }
    );
  }

  //ALL
  listAgency() {
    return axios.post(process.env.REACT_APP_AGENCY_URL + "/GetAllAgency", 
    '',{
      headers: AuthHeader()
    });
  }

  //GET BY ID
  getAgencyById(getAgencyByIdRequest) {
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/GetAgencyById",
      getAgencyByIdRequest,
      { headers: AuthHeader ()}
    );
  }

  //BRANCH API CALLS

  //ADD & UPDATE
  addBranch(addBranchRequest) {
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/CreateUpdateBranch",
      addBranchRequest,
      { headers: AuthHeader() }
    );
  }

  //GET BY ID
  getBranchByID(getBranchByIdRequest) {
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/GetBranchById",
      getBranchByIdRequest,
      { headers: AuthHeader() }
    );
  }

  //PAGINATION
  listBranch(listBranchRequest) {
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/ListBranches",
      listBranchRequest,
      { headers: AuthHeader()}
    );
  }

  //BRANCHES UNDER AGENCY
  getAllBranchByAgencyId(getAllBranchRequest) {
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/GetAllBranch",
      getAllBranchRequest,
      { headers: AuthHeader() }
    );
  }

  //AGENT API CALL

  //LIST USER BY ROLE
  listUsersByRole(listUsersByRoleRequest) {
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/ListUsersByRole",
      listUsersByRoleRequest,
      { headers: AuthHeader() }
    );
  }

  createUpdateUser(createUpdateUserRequest) {
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/CreateUpdateUser",
      createUpdateUserRequest,
      { headers: AuthHeader() }
    );
  }

  //pagination
  listUsers(listUsersRequest) {
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/ListUsers",
      listUsersRequest,
      { headers: AuthHeader() }
    );
  }

  //GET USER BY MAIL
  getUserByEmail(getUserByEmailRequest) {

    console.log("AuthHeader1",AuthHeader())

    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/GetUserByEmail",
      getUserByEmailRequest,
      { headers: AuthHeader() }
    );
  }

  //GET USER INFO BU MAIL ID
  getUserInfoByEmail(getUserByEmailRequest) {
    console.log("AuthHeader",AuthHeader())
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/GetUserByEmail",
      getUserByEmailRequest,
      { headers: AuthHeader() }
    );
  }

  getUserByEmailPosp(getUserByEmailRequest) {
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/getPospuserByEmail",
      getUserByEmailRequest,
      { headers: AuthHeader() }
    );
  }

  listPosp() {
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/listPosp",
      { headers: AuthHeader() }
    );
  }

  uploadDocument (uploadDocumentRequest){
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/uploadDocument",uploadDocumentRequest,
     { headers: {
        'Content-Type': 'multipart/form-data'
      }}
    )
  }

  getDocument(getDocumentRequest){
    return axios.post(
      process.env.REACT_APP_AGENCY_URL + "/getDocument",getDocumentRequest,
      { headers: AuthHeader() }
    )
  }


}



export default new AdminService();
