import React, { useEffect, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import {
  Table,
  Button,
  Row,
  Pagination,
  Form,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import jsPDF from "jspdf";
import "./paginations.css";
//import jsPDF from '../../node_modules/jspdf/dist/jspdf.umd.min.js'
import "jspdf-autotable";
import { Link, useHistory } from "react-router-dom";
import AgentService from "../../../services/agent.service";
import AdminService from "../../../services/admin.service";
import { decryptData } from "../../../services/auth-service";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { IoIosMenu } from "react-icons/io";
import { FiMenu } from "react-icons/fi";

export default function MyCustomers() {
  const [data1, setData1] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [initialData, setInitialData] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState(data1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNo, setpageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      console.log("no user");
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  useEffect(() => {
  
    fetchData();
  }, [user, currentPage, pageSize]);

  const fetchData = async () => {
    try {
      const data = {
        pageSize,
        pageNo,
        callerName: sessionStorage.getItem("callerID"),
      };
      
      await AgentService.getCustomer(data).then((res) => {
        console.log("res", res);
        setData1(res.data.data.content);
        setData(res.data.data.content); // Assuming API response contains 'results' key with data
        setTotalPages(res.data.data.totalPages);
        // Assuming API response contains 'totalPages' key
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (page) => {
    console.log("page", page);
    setpageNo(page - 1);
    setCurrentPage(page);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value));
    fetchData();
  };

  const handleExportPDF = () => {
    // const doc = new jsPDF();
    const doc = new jsPDF("p", "pt", "a4");
    const table = document.getElementById("data-table");
    var res = doc.autoTableHtmlToJson(table);
    doc.text("List Of Customers", 300, 50, "center");
    let str = new Date().toLocaleDateString();
    doc.setFontSize(8);
    doc.text("Date:" + str, 14, 30);
    res.columns.pop();
    let columns = res.columns;
    let rows = [];
    data1.map((element) => rows.push(Object.values(element)));

    doc.autoTable(columns, rows, {
      theme: "grid",
      startY: 70,
      headStyles: { fillColor: "#f7d0d6" },
      showHead: "firstPage",
      styles: { overflow: "hidden", fontSize: 7 },
    });

    doc.save("test Report");
  };

  const filteredData = data1.filter((item) =>
    Object.values(item).some((value) =>
      typeof value === "string"
        ? value.toLowerCase().includes(searchTerm.toLowerCase())
        : typeof value === "number"
        ? value === parseFloat(searchTerm)
        : false
    )
  );

  useEffect(() => {
    setData(filteredData);
  }, filteredData);

  return (
    <div>
      <React.Fragment>
        <h4 className="mb-5 mt-5" style={{ alignItems: "left" }}>
          List Of Customers
        </h4>
        <Container>
          <Row>
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                marginBottom: "10px",
                width: "250px",
                marginRight: "41%",
              }}
            />

            <Button
              onClick={handleExportPDF}
              className="primary-btn float-end"
              size="sm"
              style={{
                marginBottom: "10px",
                marginLeft: "10px",
                width: "200px",
                alignItems: "right",
              }}
            >
              Export PDF
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.location.reload(false);
              }}
              className=" float-end "
              variant="secondary"
              size="sm"
              style={{
                marginBottom: "10px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              Refresh
            </Button>

            <Table
              className="basic-table"
              striped
              bordered
              hover
              id="data-table"
            >
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#f7d0d6" }}>ID</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Name</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Mobile Number</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>{item.customerName}</td>
                    <td>{item.mobileNumber}</td>
                    <td>
                      {" "}
                      <Button
                        className="primary-btn"
                        href={`/updateLead/${item.id}`}
                      >
                        Edit
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div>
              <label htmlFor="pageSizeDropdown">Show Records:</label>
              <select
                id="pageSizeDropdown"
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <Pagination className="justify-content-end">
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {[...Array(totalPages).keys()].map((page) => (
                <Pagination.Item
                  key={page + 1}
                  active={page + 1 === currentPage}
                  onClick={() => handlePageChange(page + 1)}
                  style={{ accentColor: "#f4909f" }}
                >
                  {page + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
}
