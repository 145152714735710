import React from "react";
import { Col, FloatingLabel, Form, Row } from "react-bootstrap";
import InputFieldCheckBox from "../../commonModules/InputFieldCheckBox";

const MemberDetailis = (props) => {
  const handleHeightFtChange = (event, memberId) => {
    props.formik.setFieldValue('familyDetails', props.formik.values.familyDetails.map((member) => {
      if (memberId === member.id) {
        member.heightFt = event.target.value;
      }
      return member;
    }));
  };

  const handleHeightInChange = (event, memberId) => {
    props.formik.setFieldValue('familyDetails', props.formik.values.familyDetails.map((member) => {
      if (memberId === member.id) {
        member.heightIn = event.target.value;
      }
      return member;
    }));
  };

  const handleWeightChange = (event, memberId) => {
    props.formik.setFieldValue('familyDetails', props.formik.values.familyDetails.map((member) => {
      if (memberId === member.id) {
        member.weight = event.target.value;
      }
      return member;
    }));
  };

  const handleDateOfBirthChange = (event, memberId) => {
    props.formik.setFieldValue('familyDetails', props.formik.values.familyDetails.map((member) => {
      if (memberId === member.id) {
        member.dateOfBirth = event.target.value;
      }
      return member;
    }));
  };

  const handleNameChange = (event, memberId) => {
    props.formik.setFieldValue('familyDetails', props.formik.values.familyDetails.map((member) => {
      if (memberId === member.id) {
        member.name = event.target.value;
      }
      return member;
    }));
  };

  return (
    <>
      <div className="bg-white p-3 radius-12  mt-3 mb-3" style={{ textAlign: "left" }}>
        {/* <div class="form-title pt-0 mb-3"><h6 class="form-sub-title">Tell us the details about members to be insured</h6></div>
        <label className="mb-1 fw-600">umesh Kolate</label>
        <p className="text-grey mb-0">Please provide us with some additional details</p>
        <Row>
          <Col sm={12} md={6} lg={6} className="pt-2">
            <FloatingLabel controlId="floatingInput"
              label="D.D.B(DD-MM-YYYY)"
              className=""
              aria-autocomplete="off">
              <Form.Control
                name="date"
                type="date"
                className="floating-input"
                placeholder="D.D.B(DD-MM-YYYY)"
              />
            </FloatingLabel>
          </Col>
          <Col md={12} lg={6} sm={12} className="pt-2">
            <Row>
              <Col md={6} lg={6} sm={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Height(Feet)"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Select
                    name="height in feet"
                    className="floating-input"
                  >
                    <option value="">Select...</option>
                    <option value="4 FEET">4 Feet</option>
                    <option value="5 FEET">5 Feet</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col md={6} lg={6} sm={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Height(Inches)"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Select
                    name="heightInches"
                    className="floating-input"
                  >
                    <option value="">Select...</option>
                    <option value="4 INCHES">4 Inches</option>
                    <option value="5 INCHES">5 Inches</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <FloatingLabel controlId="floatingInput"
              label="Weight(KG)"
              className=""
              aria-autocomplete="off">
              <Form.Control
                name="selfWeight"
                type="text"
                className="floating-input"
                placeholder="weight(KG)"
              />
            </FloatingLabel>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={12} lg={12} className="pt-4">
            <label className="mb-1 fw-600">Spouse</label>
            <p className="text-grey mb-0">get us the details for your spouse</p>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <FloatingLabel controlId="floatingInput"
              label="Enter Spouse's full name"
              className=""
              aria-autocomplete="off">
              <Form.Control
                name="Enter Spouse's full name"
                type="text"
                className="floating-input"
                placeholder="Enter Spouse's full name"
              />
            </FloatingLabel>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <FloatingLabel controlId="floatingInput"
              label="D.D.B(DD-MM-YYYY)"
              className=""
              aria-autocomplete="off">
              <Form.Control
                name="date"
                type="date"
                className="floating-input"
                placeholder="D.D.B(DD-MM-YYYY)"
              />
            </FloatingLabel>
          </Col>
          <Col md={12} lg={12} sm={12}>
            <div className="checkbox_wrap mt-2 mb-2">
              <InputFieldCheckBox formik={props.formik} formikFieldName="" label="Don't have a last name" />
            </div>
          </Col>
          <Col md={12} lg={6} sm={12}>
            <Row>
              <Col md={6} lg={6} sm={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Height(Feet)"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Select
                    name="spouseHeightFeet"
                    className="floating-input"
                  >
                    <option value="">Select...</option>
                    <option value="4 FEET">4 Feet</option>
                    <option value="5 FEET">5 Feet</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
              <Col md={6} lg={6} sm={12}>
                <FloatingLabel
                  controlId="floatingInput"
                  label="Height(Inches)"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Select
                    name="height inches"
                    className="floating-input"
                  >
                    <option value="">Select...</option>
                    <option value="4 INCHES">4 Inches</option>
                    <option value="5 INCHES">5 Inches</option>
                  </Form.Select>
                </FloatingLabel>
              </Col>
            </Row>
          </Col>
          <Col sm={12} md={6} lg={6}>
            <FloatingLabel controlId="floatingInput"
              label="Weight(KG)"
              className=""
              aria-autocomplete="off">
              <Form.Control
                name="weight"
                type="text"
                className="floating-input"
                placeholder="weight(KG)"
              />
            </FloatingLabel>
          </Col>
        </Row> */}
        {props.formik?.values?.familyDetails?.map((member) => {
          return (
            <Row>
              <Col sm={12} md={12} lg={12} className="pt-4">
                <label className="mb-1 fw-600">{member.relation === 'Self' ? member.name : member.relation}</label>
                <p className="text-grey mb-0">{`Get us the details for your ${member.relation.toLowerCase()}`}</p>
              </Col>
              {member.relation !== 'Self' && <Col sm={12} md={6} lg={6}>
                <FloatingLabel controlId="floatingInput"
                  label={`Enter ${member.relation}'s full name`}
                  className=""
                  aria-autocomplete="off">
                  <Form.Control
                    name={`Enter ${member.relation}'s full name`}
                    type="text"
                    className="floating-input"
                    placeholder={`Enter ${member.relation}'s full name`}
                    onChange={(event) => handleNameChange(event, member.id)}
                  />
                </FloatingLabel>
              </Col>}
              <Col sm={12} md={6} lg={6}>
                <FloatingLabel controlId="floatingInput"
                  label="D.O.B(DD-MM-YYYY)"
                  className=""
                  aria-autocomplete="off">
                  <Form.Control
                    name="date"
                    type="date"
                    className="floating-input"
                    placeholder="D.O.B(DD-MM-YYYY)"
                    value={member.dateOfBirth}
                    onChange={(event) => handleDateOfBirthChange(event, member.id)}
                    disabled
                  />
                </FloatingLabel>
              </Col>
              {/* <Col md={12} lg={12} sm={12}>
                <div className="checkbox_wrap mt-2 mb-2">
                  <InputFieldCheckBox formik={props.formik} formikFieldName="" label="Don't have a last name" />
                </div>
              </Col> */}
              <Col md={12} lg={6} sm={12}>
                <Row>
                  <Col md={6} lg={6} sm={12}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Height (Feet)"
                      className=""
                      aria-autocomplete="off"
                    >
                      <Form.Select
                        name="spouseHeightFeet"
                        className="floating-input"
                        value={member.heightFt}
                        onChange={(event) => handleHeightFtChange(event, member.id)}
                      >
                        <option value="">Select</option>
                        <option value="0">0 Foot</option>
                        <option value="1">1 Foot</option>
                        <option value="2">2 Feet</option>
                        <option value="3">3 Feet</option>
                        <option value="4">4 Feet</option>
                        <option value="5">5 Feet</option>
                        <option value="6">6 Feet</option>
                        <option value="7">7 Feet</option>
                        <option value="8">8 Feet</option>
                        <option value="9">9 Feet</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Height (Inches)"
                      className=""
                      aria-autocomplete="off"
                    >
                      <Form.Select
                        name="height inches"
                        className="floating-input"
                        value={member.heightIn}
                        onChange={(event) => handleHeightInChange(event, member.id)}
                      >
                        <option value="">Select</option>
                        <option value="0">0 Inch</option>
                        <option value="1">1 Inch</option>
                        <option value="2">2 Inches</option>
                        <option value="3">3 Inches</option>
                        <option value="4">4 Inches</option>
                        <option value="5">5 Inches</option>
                        <option value="6">6 Inches</option>
                        <option value="7">7 Inches</option>
                        <option value="8">8 Inches</option>
                        <option value="9">9 Inches</option>
                        <option value="10">10 Inches</option>
                        <option value="11">11 Inches</option>
                      </Form.Select>
                    </FloatingLabel>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <FloatingLabel controlId="floatingInput"
                  label="Weight (KG)"
                  className=""
                  aria-autocomplete="off">
                  <Form.Control
                    name="weight"
                    type="text"
                    className="floating-input"
                    placeholder="Weight (KG)"
                    value={member.weight}
                    onChange={(event) => handleWeightChange(event, member.id)}
                  />
                </FloatingLabel>
              </Col>
            </Row>
          )
        })}
      </div>
    </>
  );
};

export default MemberDetailis;