import axios from "axios";
import authService from "./auth-service";
import AuthHeader from "./auth-header";

const AGENCY_URL = process.env.REACT_APP_AGENCY_URL;

class CRMService {
  async createLeadInMotor(createLeadMotorRequest) {
    return axios.post(AGENCY_URL + "/CreateLeadMotor",createLeadMotorRequest,{
      headers: AuthHeader(),
    });
  }
  
  async createLeadInMotorLocal(createLeadMotorRequest){
    return axios.post(AGENCY_URL + "/CreateUpdateLead",createLeadMotorRequest,{
      headers: AuthHeader(),
    });
  }
  
  async createLeadInHealth(createLeadMotorRequest){
    return axios.post(AGENCY_URL + "/CreateLeadHealth",createLeadMotorRequest,{
      headers: AuthHeader(),
    });
  }

  async createLeadInLife(createLeadMotorRequest){
    return axios.post(AGENCY_URL + "/CreateLeadLife",createLeadMotorRequest,{
      headers: AuthHeader(),
    });
  }

  async getLeadInMotor(data){
    return axios.post(AGENCY_URL + "/GetCustomerInfo", data,{
      headers: AuthHeader(),
    })
  }

  async updateLeadMotor(data){
    return axios.patch(AGENCY_URL + "/UpdateInfo", data,{
      headers: AuthHeader(),
    })
  }

  async updateLeadLifeHealth(data){
    return axios.patch(AGENCY_URL + "/updateLifelead", data,{
      headers: AuthHeader(),
    })
  }
}

export default new CRMService();
 //   try {
    //     // Call CRMLogin to get the token
    //     await authService.CRMLogin();
    //     // Get the token from local storage
    //     const CRMtoken = JSON.parse(localStorage.getItem('CRMtoken'));
    //     // Check if token is available
    //     if (!CRMtoken || !CRMtoken.token) {
    //       throw new Error('No token available. Please log in.');
    //     }
    //     // Make the POST request with the token in the headers
    //     return axios.post(
    //       `${AGENCY_URL}/createleadinmotor`,
    //       createLeadMotorRequest,
    //       { headers: { Authorization: `Bearer ${CRMtoken.token}` } }
    //     );
    //   } catch (error) {
    //     console.error('API error:', error);
    //     throw error; // Rethrow the error for further handling
    //   }