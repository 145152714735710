// UserContext.js



import React from 'react';

const UserContext = React.createContext();

export default UserContext ;

//import React, { createContext, useState, useContext } from 'react';

//const UserContext = createContext();
//let userRole;

// export const UserProvider = ({ children }) => {
//   const [userRole, setUserRole] = useState(null);

//   const setUser = (role) => {
//     console.log("role::::",role)
//     //userRole=role;
//     setUserRole(role);
//   };

//   return (
//     <UserContext.Provider value={{ userRole, setUser }}>
//       {children}
//     </UserContext.Provider>
//   );
// };

//export default UserContext;
