// // QuoteList.js

// import React from 'react';

// const QuoteList = ({ quotes }) => {
//   return (
//     <div>
//       <h2>Quotes Generated</h2>
//       <table>
//         <thead>
//           <tr>
//             <th>Quote ID</th>
//             <th>Generated Date</th>
//             <th>RTO Number</th>
//             <th>Make</th>
//             <th>Model</th>
//             <th>Start Date</th>
//             <th>End Date</th>
//             <th>Action</th>
//           </tr>
//         </thead>
//         <tbody>
//           {quotes.map((quote, index) => (
//             <tr key={index}>
//               <td>{quote.id}</td>
//               <td>{quote.generatedDate}</td>
//               <td>{quote.rtoNumber}</td>
//               <td>{quote.make}</td>
//               <td>{quote.model}</td>
//               <td>{quote.startDate}</td>
//               <td>{quote.endDate}</td>
//               <td>
//                 {/* /<button onClick={() => handleAction(quote.id)}>View Details</button> */}
//               </td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//     </div>
//   );
// };

// export default QuoteList;

import React, { useEffect, useState } from "react";
import axios from "axios";
import Container from "react-bootstrap/Container";
import {
  Table,
  Button,
  Row,
  Pagination,
  Form,
  NavDropdown,
} from "react-bootstrap";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { Link, useHistory } from "react-router-dom";
import { decryptData } from "../../../services/auth-service";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

import "./paginations.css"; // Assuming this file contains custom styles

export default function QuoteList() {
//   const [quotes, setQuotes] = useState([]);

const [quotes, setQuotes] = useState([
    {
      id: 1,
      generationDate: "2024-06-20",
      rtoNumber: "ABC123",
      make: "Toyota",
      model: "Camry",
      startDate: "2024-07-01",
      endDate: "2025-07-01"
    },
    {
      id: 2,
      generationDate: "2024-06-21",
      rtoNumber: "XYZ456",
      make: "Honda",
      model: "Accord",
      startDate: "2024-07-05",
      endDate: "2025-07-05"
    },
    // Add more objects as needed
  ]);
  

  const [pageSize, setPageSize] = useState(5);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNo, setpageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      console.log("no user");
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  //   useEffect(() => {
  //     fetchData();
  //   }, [user, currentPage, pageSize]);

  //   const fetchData = async () => {
  //     try {
  //       const data = { pageSize, pageNo, createdBy: user.email };
  //       const response = await AgentService.getQuotes(data);
  //       setQuotes(response.data.data.content);
  //       setTotalPages(response.data.data.totalPages);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   const handlePageChange = (page) => {
  //     setpageNo(page - 1);
  //     setCurrentPage(page);
  //   };

  //   const handlePageSizeChange = (e) => {
  //     setPageSize(parseInt(e.target.value));
  //     fetchData();
  //   };

  //   const handleExportPDF = () => {
  //     const doc = new jsPDF("p", "pt", "a4");
  //     const table = document.getElementById("data-table");
  //     var res = doc.autoTableHtmlToJson(table);
  //     doc.text("List Of Quotes", 300, 50, "center");
  //     let str = new Date().toLocaleDateString();
  //     doc.setFontSize(8);
  //     doc.text("Date:" + str, 14, 30);
  //     res.columns.pop();
  //     let columns = res.columns;
  //     let rows = [];
  //     quotes.forEach((quote) => rows.push(Object.values(quote)));
  //     doc.autoTable(columns, rows, {
  //       theme: "grid",
  //       startY: 70,
  //       headStyles: { fillColor: "#f7d0d6" },
  //       showHead: "firstPage",
  //       styles: { overflow: "hidden", fontSize: 7 },
  //     });
  //     doc.save("quotes_report");
  //   };

  //   const filteredQuotes = quotes.filter((quote) =>
  //     Object.values(quote).some((value) =>
  //       typeof value === "string" ? value.toLowerCase().includes(searchTerm.toLowerCase()) :
  //         typeof value === "number" ? value === parseFloat(searchTerm) : false
  //     )
  //   );

  //   useEffect(() => {
  //     setQuotes(filteredQuotes);
  //   }, [filteredQuotes]);

  return (
    <div>
      <React.Fragment>
        {/* <h4 className="mb-5 mt-5" style={{ alignItems: "left" }}>
          List Of Quotes
        </h4> */}
        <Container>
          <Row>
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                marginBottom: "10px",
                width: "250px",
                marginRight: "41%",
              }}
            />

            <Button
              //   onClick={handleExportPDF}
              className="primary-btn float-end"
              size="sm"
              style={{
                marginBottom: "10px",
                marginLeft: "10px",
                width: "200px",
                alignItems: "right",
              }}
            >
              Export PDF
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.location.reload(false);
              }}
              className=" float-end "
              variant="secondary"
              size="sm"
              style={{
                marginBottom: "10px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              Refresh
            </Button>

            <Table
              className="basic-table"
              striped
              bordered
              hover
              id="data-table"
            >
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Quote ID</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Quote Generation Date</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>RTO Number</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Make</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Model</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Start Date</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>End Date</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {quotes.map((quote, index) => (
                  <tr key={index}>
                    <td>{quote.id}</td>
                    <td>{quote.generationDate}</td>
                    <td>{quote.rtoNumber}</td>
                    <td>{quote.make}</td>
                    <td>{quote.model}</td>
                    <td>{quote.startDate}</td>
                    <td>{quote.endDate}</td>
                    <td>
                        <a href="/">Resume</a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div>
              <label htmlFor="pageSizeDropdown">Show Records:</label>
              <select
                id="pageSizeDropdown"
                value={pageSize}
                // onChange={handlePageSizeChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <Pagination className="justify-content-end">
              <Pagination.Prev
                // onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {[...Array(totalPages).keys()].map((page) => (
                <Pagination.Item
                  key={page + 1}
                  active={page + 1 === currentPage}
                  //   onClick={() => handlePageChange(page + 1)}
                >
                  {page + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                // onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
}
