import React, { useEffect } from "react";
import axios from "axios";

export const HDFCQuotesPayment = () => {
  const queryParams = new URLSearchParams(window.location.search);
  console.log("queryParams", queryParams.get("parameters"));
  const valuesForPayload = {
    AppID: queryParams.get("Appid"),
    TransactionID: queryParams.get("Trnsno"),
    PremiumAmount: queryParams.get("Amt") + ".0",
    SubscriberID: queryParams.get("Subid"),
    SuccessURL: queryParams.get("Surl"),
    FailureURL: queryParams.get("Furl"),
    Soruce: queryParams.get("Src"),
    CheckSum: queryParams.get("Chksum"),
    paymentURL: queryParams.get("paymentURL"),
  };

  useEffect(() => {
    if (valuesForPayload.TransactionID) {
      console.log("valuesForPayload", valuesForPayload);
      const buttonId = document.getElementById("submitbutton");
      buttonId?.click();
    }
  }, [valuesForPayload.TransactionID]);

  return (
    <div className="payment-success-wrap" hidden={true}>
      <form
        name="form1"
        method="post"
        action={valuesForPayload?.paymentURL}
        id="form1"
      >
        {/* <form onSubmit={handleSubmit}> */}
        <input type="text" name="Appid" value={valuesForPayload?.AppID} />
        <input
          type="text"
          name="Trnsno"
          value={valuesForPayload?.TransactionID}
        />
        <input type="text" name="Amt" value={valuesForPayload?.PremiumAmount} />
        <input
          type="text"
          name="Subid"
          value={valuesForPayload?.SubscriberID}
        />
        <input type="text" name="Surl" value={valuesForPayload?.SuccessURL} />
        <input type="text" name="Furl" value={valuesForPayload?.FailureURL} />
        <input type="text" name="Src" value={valuesForPayload?.Soruce} />
        <input type="text" name="Chksum" value={valuesForPayload?.CheckSum} />
        <input type="submit" id="submitbutton" value="Submit" />
        <div>
          <h1>Processing request contains insufficient data.</h1>
        </div>
      </form>
    </div>
  );
};

//{valuesForPayload?.PremiumAmount}
