import axios from "axios"
import { convertDate } from "../../components/commonModules/CommonCode";
const { format, parseISO } = require('date-fns');

export function generateTokenForHdfcKyc() {
  return axios.get("https://ekyc-uat.hdfcergo.com/e-kyc/tgt/generate-token", {
    headers: {
      api_key: "595d52aa-fc66-4a",
    },
  });
}

export function hdfcKycVerify(token, values) {
  return axios.get("https://ekyc-uat.hdfcergo.com/e-kyc/primary/kyc-verified", {
    params: {
      pan: values?.kycNominePanNumber,
      dob: convertDate(values?.kycNomineDob, 1),
    },
    headers: {
      token: token,
    },
  });
}