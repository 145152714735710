import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import InputFieldRadio from "../../commonModules/InputFieldRadio";
import UseFormContext from "../../../context/UseFormContext";

const RiderSelection = (props) => {
  const formContext = UseFormContext();

  const hospitalLimitValues = [
    { label: "Select", value: "" },
    { label: "5 lakhs", value: "5" },
    { label: "7 lakhs", value: "7" },
    { label: "7.5 lakhs", value: "7.5" },
    { label: "10 lakhs", value: "10" },
    { label: "15 lakhs", value: "15" },
    { label: "20 lakhs", value: "20" },
    { label: "25 lakhs", value: "25" },
    { label: "30 lakhs", value: "30" },
    { label: "35 lakhs", value: "35" },
    { label: "40 lakhs", value: "40" },
    { label: "45 lakhs", value: "45" },
    { label: "50 lakhs", value: "50" },
    { label: "75 lakhs", value: "75" },
    { label: "1 crore", value: "100" },
    { label: "1.5 crores", value: "150" },
    { label: "2 crores", value: "200" },
  ];

  return <>
    <div>

      <div className="bg-white p-3 radius-12  mt-3 mb-3" style={{ textAlign: "left" }}>
        <label className="mb-1 fw-600">Cover Amount</label>
        <p className="text-grey mb-0">Is this cover amount sufficient?</p>
        <Row>
          <Col sm={12} md={6} lg={6} className="pt-0">
            <FloatingLabel
              controlId="floatingInput"
              label=""
              className=""
              aria-autocomplete="off"
            >
              <Form.Select
                name=""
                className="floating-input"
                value={props.formik.values.sumInsured}
                disabled
              >
                <option value="">Select...</option>
                {hospitalLimitValues.map(item => <option value={item.value}>{item.label}</option>)}
              </Form.Select>
              {/* <ErrorMessage formik={formik} fieldValue="gender" /> */}
              {/* <Form.Text className="text-danger">
                {formik.touched.gender && formik.errors.gender ? (
                  <div className="text-danger">{formik.errors.gender}</div>
                ) : null}
              </Form.Text> */}
            </FloatingLabel>
          </Col>
        </Row>
      </div>

      <div className="bg-white p-3 radius-12  mt-3 mb-3" style={{ textAlign: "left" }}>
        <label className="mb-1 fw-600">Policy Period</label>
        <p className="text-grey mb-0">Choosing multiyear plan saves your money and the trouble of remembering yearly renewals.</p>
        <Row>
          <Col sm={12} md={12} lg={12} className="pt-0">
            <InputFieldRadio
              formikFieldName="policyTerm"
              optionsArray={[
                {
                  value: "1", label: (
                    <>
                      1 year @ <strong>₹ 14,614</strong>
                    </>
                  ),
                },
                {
                  value: "2",
                  label: (
                    <>
                      2 year @ <strong>₹ 28,133</strong>
                    </>
                  ),
                },
                {
                  value: "3",
                  label: (
                    <>
                      3 year @ <strong>₹ 41,287</strong>
                    </>
                  ),
                },
              ]}
              formik={props.formik}
              checkedClassName="checkedLabel"
            />

          </Col>
        </Row>
      </div>

      <div className="bg-white p-3 radius-12  mt-3 mb-3" style={{ textAlign: "left" }}>
        <label className="mb-1 fw-600">Riders</label>
        <p className="text-grey mb-4">You should get these additional benefits to enhance your current plan.</p>
        <div className="scroll-box">
          <div className="border-wrap-box">
            <h6 className="bordered-title">Select any 1 of 2</h6>

            <div className="single-box">
              <Row className="align-items-center">
                <Col lg={7}>
                  <div className="policy-details">
                    <h6 className="mb-1 fs-13 fw-600">Instant Cover</h6>
                    <p className="text-grey mb-0">You should get these additional benefits to enhance your current plan.</p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="policy-cost">
                    <h6 className="mb-1 fs-13 text-grey">Premium</h6>
                    <p className="mb-0"><strong>₹2,798</strong></p>
                  </div>
                </Col>
                <Col lg={2}>
                  <Button
                    // onClick={handleExportPDF}
                    className="primary-btn float-end"
                    size="xs"
                    style={{
                      marginBottom: "10px",
                      marginLeft: "10px",
                      width: "100%",
                      float: "inline-end",
                      alignItems: "right",
                    }}
                  >
                    + Add
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="single-box">
              <Row className="align-items-center">
                <Col lg={7}>
                  <div className="policy-details">
                    <h6 className="mb-1 fs-13 fw-600">Reduction in PED</h6>
                    <Row className="align-items-center">
                      <Col sm={12} md={6} lg={9}>
                        <p className="text-grey mb-0">You should get these additional benefits to enhance your current plan.</p>
                      </Col>
                      <Col sm={12} md={6} lg={3}>
                        <FloatingLabel
                          controlId="floatingInput"
                          label="Waiting Period"
                          className="waiting-period-field"
                          aria-autocomplete="off"
                        >
                          <Form.Select
                            name="waitingPeriod"
                            className="floating-input"
                          >
                            <option value="">Select...</option>
                            <option value="1 year">1 year</option>
                            <option value="2 year">2 year</option>
                          </Form.Select>
                          {/* <ErrorMessage formik={formik} fieldValue="gender" /> */}
                          {/* <Form.Text className="text-danger">
                            {formik.touched.gender && formik.errors.gender ? (
                              <div className="text-danger">{formik.errors.gender}</div>
                            ) : null}
                          </Form.Text> */}
                        </FloatingLabel>
                      </Col>
                    </Row>
                  </div>

                </Col>
                <Col lg={3}>
                  <div className="policy-cost">
                    <h6 className="mb-1 fs-13 text-grey">Premium</h6>
                    <p className="mb-0"><strong>₹2,798</strong></p>
                  </div>
                </Col>
                <Col lg={2}>
                  <Button
                    // onClick={handleExportPDF}
                    className="primary-btn float-end"
                    size="xs"
                    style={{
                      marginBottom: "10px",
                      marginLeft: "10px",
                      width: "100%",
                      float: "inline-end",
                      alignItems: "right",
                    }}
                  >
                    + Add
                  </Button>
                </Col>
              </Row>
            </div>
          </div>

          <div className="border-wrap-box">
            <div className="single-box">
              <Row className="align-items-center">
                <Col lg={7}>
                  <div className="policy-details">
                    <h6 className="mb-1 fs-13 fw-600">Annual Health Check-up</h6>
                    <p className="text-grey mb-0">You should get these additional benefits to enhance your current plan.</p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="policy-cost">
                    <h6 className="mb-1 fs-13 text-grey">Premium</h6>
                    <p className="mb-0"><strong>₹2,798</strong></p>
                  </div>
                </Col>
                <Col lg={2}>
                  <Button
                    // onClick={handleExportPDF}
                    className="primary-btn float-end"
                    size="xs"
                    style={{
                      marginBottom: "10px",
                      marginLeft: "10px",
                      width: "100%",
                      float: "inline-end",
                      alignItems: "right",
                    }}
                  >
                    + Add
                  </Button>
                </Col>
              </Row>
            </div>
          </div>

          <div className="border-wrap-box">
            <div className="single-box">
              <Row className="align-items-center">
                <Col lg={7}>
                  <div className="policy-details">
                    <h6 className="mb-1 fs-13 fw-600">Claim Shield</h6>
                    <p className="text-grey mb-0">You should get these additional benefits to enhance your current plan.</p>
                  </div>
                </Col>
                <Col lg={3}>
                  <div className="policy-cost">
                    <h6 className="mb-1 fs-13 text-grey">Premium</h6>
                    <p className="mb-0"><strong>₹2,798</strong></p>
                  </div>
                </Col>
                <Col lg={2}>
                  <Button
                    // onClick={handleExportPDF}
                    className="primary-btn float-end"
                    size="xs"
                    style={{
                      marginBottom: "10px",
                      marginLeft: "10px",
                      width: "100%",
                      float: "inline-end",
                      alignItems: "right",
                    }}
                  >
                    + Add
                  </Button>
                </Col>
              </Row>
            </div>
          </div>
        </div>

      </div>
    </div>
  </>;
};

export default RiderSelection;
