import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { Table, Button, Row, Pagination, Form } from "react-bootstrap";
import jsPDF from "jspdf";
import "./paginations.css";
import "jspdf-autotable";
import { Link } from "react-router-dom";
import AgentService from "../../../services/agent.service";

export default function MyCustomers() {

  const [quoteId, setQuoteId] = useState(null)
  const [data1, setData1] = useState([]);
  const [pageSize, setPageSize] = useState(5);

  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState(data1);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNo, setpageNo] = useState(0);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchData();
  }, [currentPage, pageSize]);

  const fetchData = async () => {
    try {
      await AgentService.getLeadsListAgainstAgentID(data).then((res) => {
        console.log("res", res.data.data);
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handlePageChange = (page) => {
    console.log("page", page);
    setpageNo(page - 1);
    setCurrentPage(page);
  };

  const handlePageSizeChange = (e) => {
    setPageSize(parseInt(e.target.value));
    fetchData();
  };

  const handleExportPDF = () => {
    // const doc = new jsPDF();
    const doc = new jsPDF("p", "pt", "a4");
    const table = document.getElementById("data-table");
    var res = doc.autoTableHtmlToJson(table);
    doc.text("List Of Customers", 300, 50, "center");
    let str = new Date().toLocaleDateString();
    doc.setFontSize(8);
    doc.text("Date:" + str, 14, 30);
    res.columns.pop();
    let columns = res.columns;
    let rows = [];
    data1.map((element) => rows.push(Object.values(element)));

    doc.autoTable(columns, rows, {
      theme: "grid",
      startY: 70,
      headStyles: { fillColor: "#f7d0d6" },
      showHead: "firstPage",
      styles: { overflow: "hidden", fontSize: 7 },
    });

    doc.save("test Report");
  };

  const filteredData = data1.filter((item) =>
    Object.values(item).some((value) =>
      typeof value === "string"
        ? value.toLowerCase().includes(searchTerm.toLowerCase())
        : typeof value === "number"
        ? value === parseFloat(searchTerm)
        : false
    )
  );

  useEffect(() => {
    setData(filteredData);
  }, filteredData);

  return (
    <div>
      <React.Fragment>
        <h4 className="mb-5 mt-5" style={{ alignItems: "left" }}>
          Leads
        </h4>
        <Container>
          <Row>
            <Form.Control
              type="text"
              placeholder="Search"
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{
                marginBottom: "10px",
                width: "250px",
                marginRight: "41%",
              }}
            />

            <Button
              onClick={handleExportPDF}
              className="primary-btn float-end"
              size="sm"
              style={{
                marginBottom: "10px",
                marginLeft: "10px",
                width: "200px",
                alignItems: "right",
              }}
            >
              Export PDF
            </Button>
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.location.reload(false);
              }}
              className=" float-end "
              variant="secondary"
              size="sm"
              style={{
                marginBottom: "10px",
                marginLeft: "10px",
                width: "200px",
              }}
            >
              Refresh
            </Button>

            <Table
              className="basic-table"
              striped
              bordered
              hover
              id="data-table"
            >
              <thead>
                <tr>
                  <th style={{ backgroundColor: "#f7d0d6" }}>ID</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Name</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Email</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Phone Number</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>LOB</th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>
                    View Lead Details
                  </th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>
                    Show quote Details
                  </th>
                  <th style={{ backgroundColor: "#f7d0d6" }}>Status</th>
                </tr>
              </thead>
              {/* <tbody>
                <tr>
                  <td>1</td>
                  <td>John Doe</td>
                  <td>john.doe@example.com</td>
                  <td>123-456-7890</td>
                  <td>health</td>
                  <td>
                    {" "}
                    <Link to={`/getLeadInfo`}>View</Link>
                  </td>
                  <td>
                  {quoteId?<Link to={"/quotes?quoteID=CARQUOTE00344411"}>Show</Link> : "No Quote generated" }
                  </td>
                  <td>Active</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>Jane Smith</td>
                  <td>jane.smith@example.com</td>
                  <td>987-654-3210</td>
                  <td>life</td>
                  <td>
                    <Link to={`/getLeadInfo`}>View</Link>
                  </td>
                  <td>
                    <Link to={"/quotes?quoteID=CARQUOTE00344411"}>Show</Link>
                  </td>
                  <td>Inactive</td>
                </tr>

                {data.map((item, index) => (
                  <tr key={index}>
                    <td>{item.id}</td>
                    <td>
                      {item.firstName} {item.lastName}
                    </td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td>
                      <Link to={`/getLeadInfo/${JSON.stringify(item)}`}>View</Link>
                    </td>
                    <td>
                    {quoteId?<Link to={"/quotes?quoteID=CARQUOTE00344411"}>Show</Link> : "No Quote generated" }
                    </td>
                    <td>
                      <Link to={`/viewAgent/${item.email}`}></Link>
                    </td>
                  </tr>
                ))}
              </tbody> */}
            </Table>
            <div>
              <label htmlFor="pageSizeDropdown">Show Records:</label>
              <select
                id="pageSizeDropdown"
                value={pageSize}
                onChange={handlePageSizeChange}
              >
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
            <Pagination className="justify-content-end">
              <Pagination.Prev
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              />
              {[...Array(totalPages).keys()].map((page) => (
                <Pagination.Item
                  key={page + 1}
                  active={page + 1 === currentPage}
                  onClick={() => handlePageChange(page + 1)}
                  style={{ accentColor: "#f4909f" }}
                >
                  {page + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              />
            </Pagination>
          </Row>
        </Container>
      </React.Fragment>
    </div>
  );
}
