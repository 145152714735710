export const makeModelVarient = [
  {
    "make": "22KYMCO",
    "models": [
      {
        "model": "X-TOWN 300I",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "IFLOW",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "LIKE200",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "22MOTORS",
    "models": [
      {
        "model": "FLOW",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "A ONE ENERGIES PVT LTD",
    "models": [
      {
        "model": "HABIBI",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 20AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ENOA",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 20AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 60AH"
        ]
      }
    ]
  },
  {
    "make": "A1 SUREJA INDUSTRIES",
    "models": [
      {
        "model": "XPLOSIVE",
        "variants": [
          "60V 42AH"
        ]
      }
    ]
  },
  {
    "make": "AAHANA COMMERCE PVT LTD",
    "models": [
      {
        "model": "WOLF WARRIOR",
        "variants": [
          "LEAD 48V 25AH"
        ]
      }
    ]
  },
  {
    "make": "AARYA AUTOMOBILES",
    "models": [
      {
        "model": "AARYA",
        "variants": [
          "48V33AH"
        ]
      },
      {
        "model": "GC PRIME",
        "variants": [
          "LITHIUM 48V 26AH",
          "48V 26 AH",
          "LITHIUM BATTERY 48V 26AH"
        ]
      },
      {
        "model": "ASV",
        "variants": [
          "LITHIUM BATTERY 60V 24AH"
        ]
      }
    ]
  },
  {
    "make": "AAW",
    "models": [
      {
        "model": "PROTON",
        "variants": [
          "LITHIUM 60V 24AH"
        ]
      }
    ]
  },
  {
    "make": "ABMOTOSS",
    "models": [
      {
        "model": "RACE",
        "variants": [
          "E-SCOOTER"
        ]
      },
      {
        "model": "RACE PLUS",
        "variants": [
          "E-SCOOTER"
        ]
      },
      {
        "model": "RAPID",
        "variants": [
          "E-SCOOTER"
        ]
      },
      {
        "model": "RAYS",
        "variants": [
          "E-SCOOTER"
        ]
      },
      {
        "model": "VAAYU",
        "variants": [
          "E-BIKE"
        ]
      },
      {
        "model": "BOBBY",
        "variants": [
          "E-BIKE"
        ]
      },
      {
        "model": "LAMMA",
        "variants": [
          "E-SCOOTER LEAD ACID",
          "E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ACCURA",
    "models": [
      {
        "model": "YADI",
        "variants": [
          "E BIKE",
          "STD"
        ]
      },
      {
        "model": "ACTIVE PLUS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ACTIVE +",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GENX",
        "variants": [
          "STD",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "ACE",
    "models": [
      {
        "model": "E TREND",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ACE MOTORS",
    "models": [
      {
        "model": "EAZY",
        "variants": [
          "PLUS"
        ]
      }
    ]
  },
  {
    "make": "ACTIVE ELECTRIC",
    "models": [
      {
        "model": "EWAVE1",
        "variants": [
          "LITHIUM",
          "EV"
        ]
      },
      {
        "model": "EWAVE 1",
        "variants": [
          "LITHIUM 60V 40AH"
        ]
      }
    ]
  },
  {
    "make": "ADMS",
    "models": [
      {
        "model": "MANTARA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GTR",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DB - RED",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SATHI YELLOW J",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SATHI",
        "variants": [
          "SCOOTER 60 V"
        ]
      },
      {
        "model": "BOXER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MAEVEL",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "ADMS E BIKE",
    "models": [
      {
        "model": "ELECTRIC MECHANICAL",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GTR",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "LEGEND",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "MARVEL",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "RIDER",
        "variants": [
          "MANTRA DLX LITH BATTERY",
          "400 E BIKE",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "MANTRA",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "ROYAL",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "TTX",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "DB",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "YUG",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "MINI MARVEL",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "RACER",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "SATHI",
        "variants": [
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "EVA",
        "variants": [
          "LITHIUM 72V 48AH"
        ]
      }
    ]
  },
  {
    "make": "ADMS E BIKES",
    "models": [
      {
        "model": "LEGEND",
        "variants": [
          "LITHIUM BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GTR",
        "variants": [
          "LITHIUM BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "MANTRA",
        "variants": [
          "LITHIUM BATTERY",
          "LITHIUM BATTERY 60V 36AH",
          "LITHIUM BATTERY 48V 26AH"
        ]
      },
      {
        "model": "DB",
        "variants": [
          "LITHIUM BATTERY",
          "LITHIUM BATTERY 60V 36AH"
        ]
      },
      {
        "model": "TTX",
        "variants": [
          "LITHIUM BATTERY",
          "LITHIUM BATTERY 60V 36AH"
        ]
      },
      {
        "model": "MARVEL",
        "variants": [
          "60V",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "EVA",
        "variants": [
          "72 V"
        ]
      },
      {
        "model": "BRAVO",
        "variants": [
          "72V",
          "2.0 72V",
          "LITHIUM BATTERY 72V 45AH"
        ]
      },
      {
        "model": "RAME",
        "variants": [
          "60V",
          "LITHIUM BATTERY 60V 36AH"
        ]
      },
      {
        "model": "RACER",
        "variants": [
          "60V"
        ]
      },
      {
        "model": "EVA PLUS",
        "variants": [
          "LITHIUM BATTERY 72V 40AH"
        ]
      },
      {
        "model": "RIDER MANTRA DLX",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "YUG",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "ROYAL",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "MINI MARVEL",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "ADRIS ELECTRIC PRIVATE LIMITED",
    "models": [
      {
        "model": "KRIDN-R",
        "variants": [
          "KRIDN LITHIUM ION  70V 40AH"
        ]
      }
    ]
  },
  {
    "make": "AERORIDE",
    "models": [
      {
        "model": "ASTRO",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "AERO",
        "variants": [
          "LITHIUM",
          "LION",
          "LEAD ACID",
          "LITHIUM ION"
        ]
      },
      {
        "model": "COSMO",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "HAWK",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "HAWK XT",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "BRAVO",
        "variants": [
          "LITHIUM",
          "NM",
          "NM",
          "STANDARD"
        ]
      },
      {
        "model": "E SPARK",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "YB2000",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "YB",
        "variants": [
          "2000D",
          "2000 72V 26AH",
          "2000 72V 26AH WITH EXTRA BATTERY",
          "2000 72V 30AH",
          "2000 72V 36AH"
        ]
      },
      {
        "model": "E-SPARK",
        "variants": [
          "LION",
          "60V 28AH",
          "60V 30 AH WITH EXTRA BATTERY",
          "60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "AFTEK MOTORS",
    "models": [
      {
        "model": "ROYAL PLUS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ELMO",
        "variants": [
          "LITHIUM 60V 30AH",
          "72V E-SCOOTER"
        ]
      },
      {
        "model": "SCORPION",
        "variants": [
          "SR 170"
        ]
      },
      {
        "model": "TURBO",
        "variants": [
          "TR 170"
        ]
      },
      {
        "model": "KNIGHT RIDER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "KNIGHT RIDER KR 170",
        "variants": [
          "170 CC BIKE"
        ]
      },
      {
        "model": "SCORPION SR 170",
        "variants": [
          "170 CC BIKE"
        ]
      },
      {
        "model": "TURBO TR 170",
        "variants": [
          "170 CC BIKE"
        ]
      },
      {
        "model": "ELMO CITY",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "AGARWAL E VEHICLES",
    "models": [
      {
        "model": "CHEETAH E DEER",
        "variants": [
          "LITHIUM BATTARY 60V"
        ]
      },
      {
        "model": "CHEETAH E HORSE",
        "variants": [
          "LITHIUM BATTARY 48V"
        ]
      },
      {
        "model": "CHEETAH E KING",
        "variants": [
          "LITHIUM BATTARY 48V"
        ]
      }
    ]
  },
  {
    "make": "AIR VOICE INDIA ELECTRIC",
    "models": [
      {
        "model": "ASPA",
        "variants": [
          "48V 24AH"
        ]
      },
      {
        "model": "CHAMP",
        "variants": [
          "48V 24AH"
        ]
      },
      {
        "model": "ERO",
        "variants": [
          "48V 24AH"
        ]
      },
      {
        "model": "SHAFIRA",
        "variants": [
          "60V 30AH"
        ]
      },
      {
        "model": "SG",
        "variants": [
          "60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "AIRIER ELECTRIC",
    "models": [
      {
        "model": "MERAKI",
        "variants": [
          "SMF 60V 28AH",
          "LITHIUM 60V 36AH",
          "SUPER LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "CRAZY PRO",
        "variants": [
          "LITHIUM 60V 36AH",
          "LITHIUM DUAL 60V 36AH"
        ]
      },
      {
        "model": "HATHI X1",
        "variants": [
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "JONTY PLUS",
        "variants": [
          "LITHIUM 60V 36AH"
        ]
      }
    ]
  },
  {
    "make": "AIRIER MOTORS",
    "models": [
      {
        "model": "CRAZY",
        "variants": [
          "PRO"
        ]
      }
    ]
  },
  {
    "make": "AIRIER NATURA",
    "models": [
      {
        "model": "SOLASTA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "MERAKI",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "AIRYAN AUTOMOBILES PVT LTD",
    "models": [
      {
        "model": "SIGMA VX",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ALLWYN",
    "models": [
      {
        "model": "PUSHPAK",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "ALTIUS",
    "models": [
      {
        "model": "CROWN",
        "variants": [
          "60V/62AH"
        ]
      }
    ]
  },
  {
    "make": "ALTIUS EV TECH",
    "models": [
      {
        "model": "CROWN",
        "variants": [
          "LITHIUM BATTERY 60V 62AH"
        ]
      }
    ]
  },
  {
    "make": "ALTUS EV",
    "models": [
      {
        "model": "CROWN",
        "variants": [
          "60V E-BIKE"
        ]
      }
    ]
  },
  {
    "make": "ALTUX",
    "models": [
      {
        "model": "GRACIA",
        "variants": [
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 48V 24AH",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 60V 25AH",
          "LITHIUM BATTERY 60V 33AH"
        ]
      },
      {
        "model": "ABELIA",
        "variants": [
          "LITHIUM ION 60V / 30AH",
          "LIFEPO4 LITHIUM IRON  48V / 24AH",
          "LITHIUM ION 60V / 33AH",
          "LIFEPO4 LITHIUM IRON  48V / 30AH",
          "LITHIUM ION 60V / 25AH"
        ]
      }
    ]
  },
  {
    "make": "ALTUX INNOVATION PVT LTD",
    "models": [
      {
        "model": "GRACIA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "MUSCULAR",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "MUSCULAR PLUS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ABELIA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SCOUT",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "AMO",
    "models": [
      {
        "model": "FIESTY",
        "variants": [
          "LEAD ACID BATTERY 60 V 20 AH",
          "LA 60V 26AH 249WATT",
          "LEAD ACID BATTERY 72V 30AH"
        ]
      },
      {
        "model": "S PIN",
        "variants": [
          "LA 48V 24AH 249WATT"
        ]
      },
      {
        "model": "JAUNTY PLUS",
        "variants": [
          "LEAD ACID BATTERY 60V 48AH",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "INSPIRER",
        "variants": [
          "LEAD ACID BATTERY 60V 26AH"
        ]
      },
      {
        "model": "JAUNTY PRO",
        "variants": [
          "LEAD ACID BATTERY 72V 27AH",
          "LITHIUM BATTERY 72V 27AH"
        ]
      },
      {
        "model": "JAUNTY",
        "variants": [
          "LITHIUM BATTERY 60V 28AH",
          "LITHIUM BATTERY 48V 55AH",
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "BRISK",
        "variants": [
          "LA 60V 32AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "AMO MOBILITY",
    "models": [
      {
        "model": "JAUNTY",
        "variants": [
          "STANDARD",
          "60V 25AH",
          "60V 34AH",
          "PLUS STANDARD",
          "ELECTRIC",
          "PRO",
          "PRO LEAD ACID",
          "60V LEAD ACID",
          "E SCOOTER",
          "3W STANDARD"
        ]
      },
      {
        "model": "FEISTY",
        "variants": [
          "STANDARD",
          "ELECTRIC",
          "LITHIUM 60V 25AH",
          "LITHIUM 48V 30AH",
          "STANDARD"
        ]
      },
      {
        "model": "INSPIRER",
        "variants": [
          "STANDARD",
          "60V 25AH",
          "60V 34AH",
          "ELECTRIC",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "S-PIN",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "JAUNTY 3W",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "S PIN",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "JAUNTY PLUS",
        "variants": [
          "LITHIUM 60V 40AH",
          "E SCOOTER",
          "60 V E-SCOOTER"
        ]
      },
      {
        "model": "BRISK",
        "variants": [
          "LEAD ACID",
          "ELECTRIC SCTOOR"
        ]
      },
      {
        "model": "JAUNTY PRO",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "AMPERE",
    "models": [
      {
        "model": "3218",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "V48",
        "variants": [
          "STD",
          "LI",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "REO",
        "variants": [
          "STD",
          "LI",
          "LEAD ACID BATTERY",
          "PLUS LI",
          "PLUS LEAD ACID BATTERY",
          "LA E BIKE",
          "LI E BIKE",
          "PLUS LI E BIKE",
          "PLUS LA E BIKE",
          "STD",
          "PLUS LA",
          "PLUS LI",
          "ELITE LI TECHNO SWAG",
          "PLUS",
          "ELITE LI",
          "LEAD ACID BATTERY",
          "LEAD BATTERY",
          "LITHIUM BATTERY",
          "250W 48V LITHIUM PLUS"
        ]
      },
      {
        "model": "REO ELITE",
        "variants": [
          "LEAD ACID BATTERY",
          "LI BATTERY"
        ]
      },
      {
        "model": "MAGNUS",
        "variants": [
          "PRO - LI BATTERY",
          "LEAD ACID BATTERY",
          "EX LI 1200W 60V-GR BLACK",
          "EX",
          "60 E BIKE",
          "PRO E BIKE",
          "EX E BIKE",
          "EX LI E BIKE",
          "PRO LI CLASSIC E BIKE",
          "LI E BIKE",
          "LI",
          "EX",
          "60",
          "EX SCOOTER",
          "EX ELECTRIC",
          "LEAD BATTERY",
          "LITHIUM BATTERY",
          "EX LITHIUM BATTERY",
          "PRO LITHIUM BATTERY",
          "PRO LEAD BATTERY"
        ]
      },
      {
        "model": "V60",
        "variants": [
          "STD",
          "E BIKE"
        ]
      },
      {
        "model": "ZEAL",
        "variants": [
          "LI",
          "EX",
          "E BIKE",
          "EX E BIKE",
          "L2 E BIKE",
          "EX E HIGH SPEED",
          "CA EX LITHIUM 60V",
          "E BIKE",
          "STD",
          "EX",
          "CA EX",
          "EX LITHIUM BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "V 48",
        "variants": [
          "LA E BIKE",
          "LI E BIKE",
          "PLUS E BIKE",
          "E BIKE",
          "LEAD BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "ELITE",
        "variants": [
          "LI E BIKE",
          "E BIKE",
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "PRIMUS",
        "variants": [
          "LITHIUM ION",
          "LITHIUM 48V 61.92AH",
          "STANDARD",
          "ELECTRIC SCOOTER",
          "LIFEPO4 4KW"
        ]
      },
      {
        "model": "ELECTRIC T",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "AMPERE LXI",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "V",
        "variants": [
          "48",
          "48 PLUS",
          "60"
        ]
      },
      {
        "model": "MAGNUS PRO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ESPRINTO",
        "variants": [
          "60V 1800 W"
        ]
      },
      {
        "model": "REO PLUS",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "MAGNUS EX",
        "variants": [
          "LITHIUM BATTERY 60V 38.25AH"
        ]
      }
    ]
  },
  {
    "make": "AMWA MOTO LLP",
    "models": [
      {
        "model": "PRAKRITI SUPER",
        "variants": [
          "E-BIKE"
        ]
      },
      {
        "model": "PRAKRITI NEO",
        "variants": [
          "E-BIKE",
          "LITE ESPA"
        ]
      },
      {
        "model": "PRAKRITI ESPA",
        "variants": [
          "E-BIKE"
        ]
      }
    ]
  },
  {
    "make": "ANANT",
    "models": [
      {
        "model": "E2",
        "variants": [
          "SILVER 48V 26AH",
          "SILVER 48V 30AH",
          "SILVER 60V 26AH",
          "SILVER 60V 30AH",
          "SILVER 48V 26AH LA",
          "SILVER 60V 26AH LA",
          "GOLD 48V 26AH",
          "GOLD 48V 30AH",
          "GOLD 60V 26AH",
          "GOLD 60V 30AH",
          "GOLD 48V 26AH LA",
          "GOLD 60V 26AH LA",
          "DIAMOND 48V 26AH",
          "DIAMOND 48V 30AH",
          "DIAMOND 60V 26AH",
          "DIAMOND 60V 30AH",
          "DIAMOND 48V 26AH LA",
          "DIAMOND 60V 26AH LA"
        ]
      }
    ]
  },
  {
    "make": "ANANYA KOMAKI",
    "models": [
      {
        "model": "NEXZU",
        "variants": [
          "ELECRIC E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ANGELO",
    "models": [
      {
        "model": "FRANC",
        "variants": [
          "LITHIUM ION 60V 36AH"
        ]
      },
      {
        "model": "CARGO MODEL EXCER C80",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "VESPA",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "FRANC ELYX",
        "variants": [
          "LITHIUM ION 72V 45AH"
        ]
      },
      {
        "model": "MICHELLE",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "ZIANNA",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "ANGIRA MOTORS",
    "models": [
      {
        "model": "ROADSTAR",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ANK",
    "models": [
      {
        "model": "EV65",
        "variants": [
          "G"
        ]
      }
    ]
  },
  {
    "make": "ANK EV",
    "models": [
      {
        "model": "ANK 65",
        "variants": [
          "SCOOTY",
          "G E SCOOTY",
          "R E SCOOTY",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "ANK 65 G",
        "variants": [
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "ANK 65 R",
        "variants": [
          "LITHIUM BATTERY 60V 26AH"
        ]
      }
    ]
  },
  {
    "make": "API",
    "models": [
      {
        "model": "LAMBY",
        "variants": [
          "150 CC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "API LAMBRETTA",
    "models": [
      {
        "model": "LI 150",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "APONYX",
    "models": [
      {
        "model": "THE X ONE",
        "variants": [
          "LITHIUM 40V 26AH",
          "LITHIUM 60V 30AH",
          "LITHIUM DOUBLEBATTERY 60V 26AH"
        ]
      },
      {
        "model": "THE X2",
        "variants": [
          "LITHIUM 40V 26AH",
          "LITHIUM 60V 30AH",
          "LITHIUM DOUBLEBATTERY 60V 26AH"
        ]
      },
      {
        "model": "X PLUS",
        "variants": [
          "LITHIUM 40V 26AH",
          "LITHIUM 60V 30AH",
          "LITHIUM DOUBLEBATTERY 60V 26AH"
        ]
      },
      {
        "model": "ITALIA",
        "variants": [
          "LITHIUM 40V 26AH",
          "LITHIUM 60V 30AH",
          "LITHIUM DOUBLEBATTERY 60V 26AH"
        ]
      },
      {
        "model": "TRI",
        "variants": [
          "LITHIUM 60V 30AH",
          "LITHIUM DOUBLEBATTERY 60V 26AH"
        ]
      },
      {
        "model": "X ONE",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "APONYX EV",
    "models": [
      {
        "model": "RIDER",
        "variants": [
          "LITHIUM ION 48V 26AH"
        ]
      },
      {
        "model": "X ONE",
        "variants": [
          "LITHIUM ION 48V 26AH"
        ]
      },
      {
        "model": "X TWO",
        "variants": [
          "LITHIUM ION 48V 26AH"
        ]
      },
      {
        "model": "X PLUS",
        "variants": [
          "LITHIUM ION 48V 26AH"
        ]
      },
      {
        "model": "ITALIA",
        "variants": [
          "LITHIUM ION 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "APPKE",
    "models": [
      {
        "model": "POWER",
        "variants": [
          "E-SCOOTER 70 KM",
          "E-SCOOTER 90 KM",
          "E-SCOOTER 120 KM",
          "E-SCOOTER 240 KM"
        ]
      },
      {
        "model": "BOSS",
        "variants": [
          "E-SCOOTER 70 KM",
          "E-SCOOTER 100 KM"
        ]
      },
      {
        "model": "BIG BOSS",
        "variants": [
          "E-SCOOTER 70 KM",
          "E-SCOOTER 100 KM"
        ]
      }
    ]
  },
  {
    "make": "APPKE EV",
    "models": [
      {
        "model": "BIG BOSS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "BOSS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ROCKING",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "APRILIA",
    "models": [
      {
        "model": "CAPONORD",
        "variants": [
          "1200 ABS",
          "1200 ABS BSIV",
          "1200 RALLY ABS BSIV",
          "1200 ABS TRAVEL PACK",
          "1200 RALLY",
          "1200 ABS TRAVEL",
          "1200"
        ]
      },
      {
        "model": "SR150",
        "variants": [
          "STANDARD",
          "FACELIFT",
          "RACE NEW",
          "FACELIFT-ABS",
          "FACELIFT ABS BS VI FI",
          "CARBON ABS BS VI FI",
          "RACE ABS BS VI FI"
        ]
      },
      {
        "model": "STORM 125",
        "variants": [
          "STANDARD",
          "DISC"
        ]
      },
      {
        "model": "SR 160",
        "variants": [
          "RACE BS VI",
          "CARBON BS VI",
          "CARBON ABS BSVI",
          "MT ABS BSVI",
          "MOTARD ABS",
          "CARBON MY 21 MT",
          "RACE MY 21 MT",
          "RACE ABSÂ  BSVI",
          "ABSÂ  BSVI",
          "STD",
          "RACE",
          "CARBON"
        ]
      },
      {
        "model": "SXR 160",
        "variants": [
          "STANDARD",
          "ABSÂ  BSVI"
        ]
      },
      {
        "model": "DORSODURO 900",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MANA 850",
        "variants": [
          "STANDARD",
          "ABS"
        ]
      },
      {
        "model": "RSV4 RF",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "RSV4 RR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SR125",
        "variants": [
          "STANDARD",
          "BS VI FI"
        ]
      },
      {
        "model": "SRV 850",
        "variants": [
          "ATC-ABS"
        ]
      },
      {
        "model": "TUONO V4 APRC",
        "variants": [
          "V4 R",
          "STANDARD"
        ]
      },
      {
        "model": "SXR125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DORSODURO",
        "variants": [
          "900 STD",
          "1200 ABS",
          "900"
        ]
      },
      {
        "model": "HABANA",
        "variants": [
          "SCOOTER",
          "125 CC SCOOTER"
        ]
      },
      {
        "model": "CAPONORD 1200",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MANA",
        "variants": [
          "STD",
          "850 GT ABS",
          "850 GT ABS",
          "850 GT ABS",
          "850 ABS",
          "850"
        ]
      },
      {
        "model": "SR",
        "variants": [
          "125",
          "150 RACE",
          "125 CBS",
          "125 CBS BSVI",
          "125 STD BSVI",
          "150 ABS",
          "150 CARBON",
          "150 CARBON ABS",
          "150 RACE ABS",
          "160 CARBON ABS",
          "160 STD BS6",
          "150 STD",
          "160 FACELIFT BS VI",
          "150 RACE",
          "125 FACELIFT",
          "MT 160 RACE",
          "CARBON MT 160",
          "150 BS4",
          "SR 160 BSIV STD",
          "125 CBS ANALOG",
          "125 CBS DIGITAL",
          "160 RACE BS6",
          "160 CARBON BS6",
          "150"
        ]
      },
      {
        "model": "SR 125",
        "variants": [
          "STORM CBS DRUM BSVI",
          "STORM CBS DISC BSVI",
          "STD",
          "STD CBS",
          "STORM",
          "STORM CBS BSVI",
          "CBS BSVI",
          "CBS DIGITAL CLUSTER BSVI",
          "MY 21",
          "STD BSIV",
          "CBS BSVI DIGITAL INSTRUMENT PANEL",
          "CBS BSVI ANALOG INSTRUMENT PANEL",
          "125 CC SCOOTER",
          "CBS DIGITAL CLUSTER",
          "STORM CBS BSVI",
          "STORM CBS",
          "CBS 125 CC SCOOTER"
        ]
      },
      {
        "model": "SCARBED",
        "variants": [
          "STD",
          "150 STD"
        ]
      },
      {
        "model": "SR 150",
        "variants": [
          "150 STD",
          "DIGITAL",
          "BSIV",
          "STD ABS",
          "DIGITAL ABS",
          "ABS BSVI",
          "CARBON BSVI",
          "RACE ABS BSVI",
          "ABS",
          "RACE ABS",
          "CARBON",
          "DIGITAL",
          "STD"
        ]
      },
      {
        "model": "SXR 125",
        "variants": [
          "CBS BSVI",
          "STD"
        ]
      },
      {
        "model": "STORM",
        "variants": [
          "125 BS6",
          "125 CBS",
          "125 BS VI FI",
          "125 CBS DISC BS6"
        ]
      },
      {
        "model": "TUONO",
        "variants": [
          "V4 F APRC",
          "V4 1100 RR",
          "V4 1100 FACTORY",
          "V4 R APRC",
          "V4 R1000 APRC"
        ]
      },
      {
        "model": "SHIVER 900",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "RSV4",
        "variants": [
          "1100 FACTORY STANDARD",
          "SPORTS BIKE",
          "FACTORY",
          "R",
          "ATLANTIC",
          "RF",
          "RR",
          "R APRC",
          "FACTORY APRC",
          "FACTORY APRC SE"
        ]
      },
      {
        "model": "SXR",
        "variants": [
          "CARBON 125 CBS",
          "CARBON 160 ABS",
          "125 CBS BS6",
          "160 STD BS6"
        ]
      },
      {
        "model": "DORSODUORO",
        "variants": [
          "1200 ABS"
        ]
      },
      {
        "model": "LEONARDO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SHIVER",
        "variants": [
          "900 STD",
          "900"
        ]
      },
      {
        "model": "SR 150 (155 CC)",
        "variants": [
          "DIGITAL",
          "ABS",
          "RACE CONNECTIVITY ABS",
          "CARBON",
          "STD",
          "RACE"
        ]
      },
      {
        "model": "SONIC",
        "variants": [
          "50 STD"
        ]
      },
      {
        "model": "RS 125",
        "variants": [
          "125 CC MOTORCYCLE"
        ]
      },
      {
        "model": "SRV",
        "variants": [
          "850 ABS",
          "850 ABS"
        ]
      },
      {
        "model": "SR50",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "ARGON MOBILITY",
    "models": [
      {
        "model": "ARGON R",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LEO",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "MODEL G",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ARIEL",
    "models": [
      {
        "model": "1955",
        "variants": [
          "RED HUNTER"
        ]
      },
      {
        "model": "RED HUNTER",
        "variants": [
          "250 CC MOTORCYCLE",
          "350 CC MOTORCYCLE"
        ]
      }
    ]
  },
  {
    "make": "ARNA ELECTRIC AUTO  PVT LTD",
    "models": [
      {
        "model": "E-SCOOTY SQUARE",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "ARROW",
    "models": [
      {
        "model": "WOLF",
        "variants": [
          "48V-28AH"
        ]
      },
      {
        "model": "VESPAR",
        "variants": [
          "48V-28AH"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "48V-28AH"
        ]
      },
      {
        "model": "R2",
        "variants": [
          "48V-28AH"
        ]
      },
      {
        "model": "A1",
        "variants": [
          "48V-28AH"
        ]
      }
    ]
  },
  {
    "make": "ARUN AUTOMOBILE",
    "models": [
      {
        "model": "RUHANI",
        "variants": [
          "SL ADVANCE",
          "SL POWER",
          "SL EASY GO",
          "SL"
        ]
      }
    ]
  },
  {
    "make": "ARYAION ELECTRIC VEHICLES",
    "models": [
      {
        "model": "ZOO HOO 350",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ASHAIT AUTOMOBILES",
    "models": [
      {
        "model": "E BRIZU",
        "variants": [
          "LITHIUM"
        ]
      }
    ]
  },
  {
    "make": "ASHISH WHEELS KYOTO",
    "models": [
      {
        "model": "FUTURE",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ASHWAVEGA AUTOMOTIVE PVT LTD",
    "models": [
      {
        "model": "AV CLASSIC",
        "variants": [
          "LITHIUM ION",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "AV DLX",
        "variants": [
          "LITHIUM ION",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "AV SUPER",
        "variants": [
          "LITHIUM ION",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "AV SUPER PRO",
        "variants": [
          "LITHIUM ION",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "AV NEXA",
        "variants": [
          "LITHIUM ION",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "AV ENERGY LD",
        "variants": [
          "LITHIUM ION",
          "ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ASIS",
    "models": [
      {
        "model": "CLASSY",
        "variants": [
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "ASIS E MOTORS",
    "models": [
      {
        "model": "CLASSY",
        "variants": [
          "SCOOTY"
        ]
      },
      {
        "model": "HELICO",
        "variants": [
          "SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "ASTAR",
    "models": [
      {
        "model": "M PLUS",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "AFFAIR",
        "variants": [
          "LEAD ACID 60V 28AH",
          "VSP LEAD ACID 60V 28AH",
          "LITHIUM 60V 25AH",
          "VSP LITHIUM 60V 25AH",
          "LITHIUM 60V 30AH",
          "LEAD ACID 72V 28AH"
        ]
      },
      {
        "model": "ARGH",
        "variants": [
          "LEAD ACID 60V 28AH",
          "PRO LEAD ACID 60V 28AH",
          "LITHIUM 60V 25AH",
          "PRO LITHIUM 60V 25AH",
          "LEAD ACID 48V 24AH"
        ]
      },
      {
        "model": "I PRESH",
        "variants": [
          "LEAD ACID 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "ATHER",
    "models": [
      {
        "model": "340",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "450",
        "variants": [
          "X PLUS",
          "X",
          "X BOV",
          "STANDARD",
          "PLUS",
          "X PRO",
          "PLUS",
          "STD",
          "X"
        ]
      },
      {
        "model": "450X GEN 3",
        "variants": [
          "2.9 KWH CORE",
          "3.7 KWH CORE",
          "PLUS",
          "X"
        ]
      },
      {
        "model": "450S",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "ATHER 450",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "450 X",
        "variants": [
          "WITH SOFTWARE",
          "PLUS WITHOUT SOFTWARE",
          "GEN 3",
          "PLUS GEN 3",
          "PRO PACK BSVI",
          "STANDARD",
          "PRO PACK"
        ]
      },
      {
        "model": "450 S",
        "variants": [
          "ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ATHER ENERGY",
    "models": [
      {
        "model": "340",
        "variants": [
          "EBIKE",
          "E SCOOTER"
        ]
      },
      {
        "model": "450",
        "variants": [
          "EBIKE",
          "X E BIKE",
          "PLUS E BIKE",
          "STD E BIKE",
          "X 3RD GEN",
          "PLUS 3RD GEN",
          "X PRO",
          "X E BIKE",
          "PLUS E BIKE",
          "E SCOOTER"
        ]
      },
      {
        "model": "450 X",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "450 PLUS",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ATOMEX",
    "models": [
      {
        "model": "RAAVI",
        "variants": [
          "LITHIUM 60V 24AH",
          "LITHIUM ION BATTERY 60V 26AH"
        ]
      }
    ]
  },
  {
    "make": "ATOMEX E MOTORS",
    "models": [
      {
        "model": "RAAVI",
        "variants": [
          "1.0 E SCOOTERS"
        ]
      },
      {
        "model": "A.1",
        "variants": [
          "E BIKES"
        ]
      },
      {
        "model": "A.2",
        "variants": [
          "E BIKES"
        ]
      },
      {
        "model": "A.2.5",
        "variants": [
          "E BIKES"
        ]
      }
    ]
  },
  {
    "make": "ATTRACTIVE AUTO PART",
    "models": [
      {
        "model": "CUB PRO",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "HAWK PRO",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "OUTRUN PRO",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "OUTRUN PRO PLUS",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ATUMMOBILE",
    "models": [
      {
        "model": "ATUM 1.0",
        "variants": [
          "48V 250W",
          "LITHIUM 60V 24AH"
        ]
      }
    ]
  },
  {
    "make": "ATUMOBILE",
    "models": [
      {
        "model": "ATUM 1.0",
        "variants": [
          "ELECTRIC BIKE"
        ]
      }
    ]
  },
  {
    "make": "AUM AUTO WORLD",
    "models": [
      {
        "model": "ELECTRON",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "NEUTRON",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "PROTON",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "AVAN MOTORS",
    "models": [
      {
        "model": "XERO+",
        "variants": [
          "SINGLE BATTERY",
          "DUAL BATTERY"
        ]
      },
      {
        "model": "TREND E",
        "variants": [
          "SINGLE BATTERY",
          "DUAL BATTERY",
          "SINGLE BATTERY",
          "DUAL BATTERY",
          "HIGH SPEED SIBGLE BATTERY"
        ]
      },
      {
        "model": "XERO PLUS",
        "variants": [
          "SINGLE BATTERY",
          "DOUBLE BATTERY",
          "DUAL BATTERY",
          "SINGLE BATTERY",
          "DUAL BATTERY",
          "SINGLE BATTERY",
          "HIGH SPEED SIBGLE BATTERY",
          "E SCOOTER"
        ]
      },
      {
        "model": "XERO",
        "variants": [
          "LI ON",
          "LION SINGLE BATTERY",
          "E SCOOTER"
        ]
      },
      {
        "model": "TREND E (XERO LI-ION)",
        "variants": [
          "SINGLE BATTERY",
          "DOUBLE BATTERY"
        ]
      },
      {
        "model": "TREND XERO",
        "variants": [
          "SINGLE BATTERY",
          "DOUBLE BATTERY"
        ]
      }
    ]
  },
  {
    "make": "AVERA",
    "models": [
      {
        "model": "RETROSA",
        "variants": [
          "E SCOOTER",
          "II E SCOOTER",
          "LITE E",
          "LITHIUM BATTERY",
          "LITE E LIFEPO4 2.7KW"
        ]
      }
    ]
  },
  {
    "make": "AVERA ELECTRIC VEHICLE",
    "models": [
      {
        "model": "RETROSA",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "AVON",
    "models": [
      {
        "model": "E STAR",
        "variants": [
          "STD",
          "STD",
          "DX 60/24",
          "E SCOOTER"
        ]
      },
      {
        "model": "E-BIKE",
        "variants": [
          "VX",
          "STD"
        ]
      },
      {
        "model": "E-PLUS",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "E-LITE",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "E-SCOOT",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "E-MATE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "E-ZAP",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XERO",
        "variants": [
          "XERO PLUS"
        ]
      },
      {
        "model": "E BIKE",
        "variants": [
          "022 EB",
          "E SCOOTER"
        ]
      },
      {
        "model": "E MATE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "E ZAP",
        "variants": [
          "60V LITHIUM",
          "LITHIUM BATTERY 25 AH",
          "LITHIUM BATTERY 15 AH",
          "E SCOOTER"
        ]
      },
      {
        "model": "E RAHI",
        "variants": [
          "60V LITHIUM"
        ]
      },
      {
        "model": "E STAR DX",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 48V 24AH",
          "LITHIUM BATTERY 60V 20AH",
          "LITHIUM BATTERY 60V 24AH",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "E ZAP DX",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 60V 24AH",
          "LITHIUM BATTERY 60V 26AH",
          "LITHIUM BATTERY 48V 24AH",
          "LITHIUM BATTERY 60V 20AH"
        ]
      },
      {
        "model": "E SCOOT 504",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 48V 24AH",
          "LITHIUM BATTERY 60V 20AH",
          "LITHIUM BATTERY 60V 24AH",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "E SCOOT",
        "variants": [
          "LITHIUM BATTERY",
          "VRLA BATTERY",
          "E SCOOTER"
        ]
      },
      {
        "model": "E LITE DX",
        "variants": [
          "LITHIUM BATTERY",
          "VRLA BATTER"
        ]
      },
      {
        "model": "E MATE 306",
        "variants": [
          "LITHIUM BATTERY 48V 24AH",
          "LITHIUM BATTERY 60V 20AH",
          "LITHIUM BATTERY 60V 24AH",
          "LITHIUM BATTERY 60V 26AH"
        ]
      }
    ]
  },
  {
    "make": "AVON CYCLES LTD",
    "models": [
      {
        "model": "AVON E LITE DX",
        "variants": [
          "72V 12 AH LEAD ACID",
          "LI 72V 15 AH LITHIUM"
        ]
      },
      {
        "model": "AVON E ZAP",
        "variants": [
          "72V 14 AH LEAD ACID",
          "72V 20 AH LEAD ACID",
          "LI 72V 15 AH LITHIUM",
          "LI 72V 25 AH LITHIUM",
          "DX"
        ]
      },
      {
        "model": "AVON E SCOOT",
        "variants": [
          "207 48V 20AH LEAD ACID",
          "207 L1 48V 25AH LITHIUM",
          "504 L1 48V 25AH LITHIUM"
        ]
      },
      {
        "model": "AVON E STAR",
        "variants": [
          "48V 33AH LEAD ACID"
        ]
      },
      {
        "model": "AVON E ZAP DX",
        "variants": [
          "60V 28AH LA",
          "60V 20AH LI"
        ]
      },
      {
        "model": "AVON E MATE",
        "variants": [
          "LI"
        ]
      },
      {
        "model": "AVON E STAR DX",
        "variants": [
          "60V 24AH"
        ]
      },
      {
        "model": "AVON VX",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "AYAAN ELECTRONICS",
    "models": [
      {
        "model": "YUG M3",
        "variants": [
          "LITHIUM 72 V 30 AH",
          "LITHIUM 72 V 25 AH",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "YUG 400",
        "variants": [
          "LFP 60 V 60 H",
          "LITHIUM 60V 20 AH",
          "LITHIUM 60V 24 AH",
          "LITHIUM 60V 30 AH",
          "LFP 60 V 24 H",
          "LFP 60 V 30 H",
          "LEAD ACID 60V",
          "LEAD ACID 48V",
          "LITHIUM BATTERY",
          "LFP 60 V 50 H"
        ]
      },
      {
        "model": "YUG M7",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "AYAAN ELECTRONICS NON GICOUNCIL",
    "models": [
      {
        "model": "YUG",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YUG 400",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YUG M3",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "BAAZ BIKES",
    "models": [
      {
        "model": "BAAZ",
        "variants": [
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "BAHUBALI",
    "models": [
      {
        "model": "B10",
        "variants": [
          "E BIKE",
          "E BIKE"
        ]
      },
      {
        "model": "B20",
        "variants": [
          "E BIKE",
          "E BIKE"
        ]
      },
      {
        "model": "B30",
        "variants": [
          "E BIKE",
          "E BIKE"
        ]
      },
      {
        "model": "BAHUBALI SCOOTY",
        "variants": [
          "B 10",
          "B 20",
          "B 30"
        ]
      }
    ]
  },
  {
    "make": "BAJAJ",
    "models": [
      {
        "model": "KTM ADVENTURER",
        "variants": [
          "640"
        ]
      },
      {
        "model": "DISCOVER",
        "variants": [
          "125 ST - SELF START",
          "125 T - SELF START",
          "125 T - SELF START DISC BRAKE",
          "DTSI",
          "DTS SI BSIII",
          "135 SPORTS",
          "150 AS",
          "150 DTS-SI SELF START",
          "150 DTS-SI SELF START DISC BRAKE",
          "150 F",
          "5G",
          "DTS-SI ELEC ST DRUM BRAKE SW",
          "ELECTRIC START",
          "ELECTRIC START UG",
          "KICK START",
          "STD",
          "100 UG",
          "BS3",
          "100",
          "100 M DRUM",
          "100 T DRUM",
          "100 ULTIMATE",
          "100/ 4 GEAR DRES",
          "100T DR ES",
          "110 CBS",
          "110 DTSI ALLOY KS",
          "125",
          "125 DISC",
          "125 DISC BREAK",
          "STD",
          "125 DISK BREAK SS",
          "125 DRUM",
          "125 DRUM BREAK",
          "125 DRUM BREAK KS",
          "125 DRUM BREAK SS",
          "125 DTSI ALLOY SS",
          "125 DTSI ES",
          "125 DTSI KS",
          "125ST DISC BRK",
          "125T DISC",
          "150 S",
          "DISC CBS",
          "DRUM CBS",
          "DTS",
          "DTS SI ES",
          "DTS SI KS",
          "ES",
          "F DISC",
          "F DRUM",
          "H 150",
          "K 70",
          "M",
          "M 100-ES",
          "M 4G",
          "M KS",
          "S DISC",
          "S DRUM",
          "SPOKE KS",
          "SPOKE SS",
          "100T - SELF START",
          "100T - SELF START DISC BRAKE",
          "100 STD",
          "125 SELF START DISC BRAKE",
          "125 SELF START",
          "135 DTSI SELF START",
          "135 DTSI KICK START",
          "150 SELF START",
          "4 G",
          "150 S - SELF START DISC BRAKE",
          "150 STD",
          "110 DRUM CBS",
          "100 M DISC",
          "125T DRUM",
          "BS II",
          "D 105 A TX DISC",
          "DTS SI",
          "112",
          "100 ES 5 G",
          "100 T",
          "100 M",
          "100 T DISC",
          "125 M DRUM",
          "125 M DISC",
          "150 H",
          "104 DISCO /M UG ES",
          "102 C MX",
          "DTS SI KS(SPOKE)",
          "DTS SI ES(SPOKE)",
          "125 ST",
          "5 GEAR DRES",
          "DRES",
          "DIES",
          "150 DIES",
          "125 ST - DISC",
          "150",
          "125 KS",
          "150 ES DISC BRAKE",
          "100 ES",
          "100 KS",
          "135 DTSI KS",
          "135 DTSI ES",
          "110 SELF START DRUM BRAKE ALLOY WHEEL",
          "DTS-SI SPOKE WHEEL ELECTRIC START DRUM BRK",
          "DTS-SI ELECTRIC START",
          "DTS-SI KICK START",
          "5G - SELF START DRUM ALLOY WHEEL",
          "100 T - SELF START DISC BR ALLOY WHEEL",
          "100 M - SELF START DRUM BREAK ALLOY WHEEL",
          "100 M - SELF START DISC BREAK ALLOY WHEEL",
          "100 T - KICK START DRUM ALLOY WHEEL",
          "100 T - SELF START DRUM ALLOY WHEEL",
          "100 DTS-SI SELF START",
          "100 DTS-SI KICK START",
          "100 5G",
          "100 T - KICK START",
          "112 K 70",
          "112 STD",
          "125 KICK START",
          "125 M - SELF START DISC BRAKE",
          "125 M - SELF START"
        ]
      },
      {
        "model": "ASPIRE",
        "variants": [
          "STANDARD",
          "110",
          "110",
          "STD",
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "AVENGER STREET 180",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "BLACK",
        "variants": [
          "(S)"
        ]
      },
      {
        "model": "BOXER",
        "variants": [
          "BOXER AR",
          "CT 100",
          "CT DELUXE",
          "BM-150 ES",
          "AR",
          "CT",
          "STD",
          "150 BM",
          "AR",
          "AT",
          "AT- 112 CC",
          "BM 100",
          "BM150",
          "CT K-TECH",
          "CT RED",
          "K-TECH",
          "STD",
          "STD",
          "AT",
          "125 CC",
          "150",
          "99 CC",
          "100 STD",
          "150 STD",
          "125 STD"
        ]
      },
      {
        "model": "BRAVO",
        "variants": [
          "4-STROKE",
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "BYK",
        "variants": [
          "STANDARD",
          "M",
          "100",
          "125",
          "STD",
          "STD"
        ]
      },
      {
        "model": "CALIBER",
        "variants": [
          "115",
          "CROMA",
          "CALIBER 115",
          "CROMA",
          "STD",
          "115",
          "115 CC",
          "CROMA",
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "CHETAK",
        "variants": [
          "4-STROKE",
          "URBANE",
          "PREMIUM",
          "4 STROKE",
          "STD",
          "2 STROKE (3 WHEELS)",
          "PREMIUM",
          "URBAN",
          "2413 PREMIUM",
          "2423 PREMIUM",
          "PREMIUM 2023 EDITION",
          "STD",
          "METALLIC",
          "PREMIUM ELECTRIC",
          "URBANE",
          "PREMIUM",
          "PREMIUM",
          "URBANE",
          "2023 PREMIUM",
          "2423 PREMIUM",
          "2403 PREMIUM",
          "2413 PREMIUM",
          "4 STROKE SCOOTER 112 CC",
          "4 STROKE SCOOTER",
          "SCOOTER",
          "SCOOTER",
          "STD"
        ]
      },
      {
        "model": "AFL-500",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CLASSIC",
        "variants": [
          "SL",
          "STD",
          "SL METALLIC",
          "SL ORDINARY",
          "145 CC",
          "STD"
        ]
      },
      {
        "model": "CT 100",
        "variants": [
          "STD",
          "ES",
          "KS ALLOY BS VI",
          "ES ALLOY-CBS",
          "ES ALLOY BS VI",
          "SPOKE",
          "ALLOY",
          "B-CBS",
          "ALLOY-CBS",
          "KS ALLOY BS VI FACELIFT",
          "B",
          "DELUXE",
          "B BSIV",
          "SPOKE BSIV",
          "ALLOY BSIV",
          "ALLOY BSIV ES",
          "ALLOY KS UG ABS",
          "ALLOY ES UG ABS",
          "SPOKE UG CBS",
          "ALLOY ES UG CBS",
          "KS CBS",
          "KS CBS BSIV",
          "ALLOY KS UG CBS",
          "ALLOY KS CBS BSVI",
          "ES CBS BSVI",
          "BASIC",
          "KICK START ALLOY WHEEL",
          "ALLOY",
          "B",
          "ES ALLOY",
          "SPOKE",
          "ALLOY WHEEL DRUM BRAKE KICK START BS6",
          "ALLOY WHEEL DRUM BRAKE ELECTRIC START BS6",
          "ALLOY WHEEL DRUM BRAKE ELECTRIC START",
          "B",
          "ALLOY WHEEL DRUM BRAKE KICK START UPGRADE",
          "ALLOY WHEEL DRUM BRAKE KICK START UG BSVI",
          "DELUX",
          "SPOKE WHEEL KS CBS",
          "ALLOY WHEEL DRUM BRAKE ELECTRIC START CBS",
          "ALLOY WHEEL DRUM BRAKE KICK START CBS",
          "ALLOY WHEEL DRUM BRAKE KICK START",
          "SPOKE WHEEL DRUM BRAKE KICK START"
        ]
      },
      {
        "model": "CUB",
        "variants": [
          "STD",
          "100 METALLIC",
          "100 ORDINARY",
          "STD"
        ]
      },
      {
        "model": "DISCOVER 125 ST",
        "variants": [
          "STANDARD",
          "ELECTRIC START DISC BREAK ALLOY WHEEL"
        ]
      },
      {
        "model": "DISCOVER 110",
        "variants": [
          "STANDARD",
          "ELECTRIC START (110 CC)",
          "2018",
          "STD",
          "STD"
        ]
      },
      {
        "model": "PULSAR 150",
        "variants": [
          "DTSI UG2",
          "STANDARD",
          "DTSI UG3",
          "DTSI UG4",
          "SINGLE DISC ABS BS VI",
          "TWIN DISC",
          "KICK START",
          "CLASSIC",
          "STD",
          "ELECTRIC START",
          "NEON ABS",
          "SD ABS",
          "NEON",
          "P SD",
          "P TD"
        ]
      },
      {
        "model": "PULSAR 180",
        "variants": [
          "DTSI UG3",
          "DTSI UG4",
          "ABS",
          "STANDARD",
          "STD",
          "ELECTRIC START"
        ]
      },
      {
        "model": "ES",
        "variants": [
          "STD",
          "UG3"
        ]
      },
      {
        "model": "KAWASAKI",
        "variants": [
          "KB 100",
          "4S",
          "4S CHAMPION",
          "NINJA 650 R",
          "NINJA 300",
          "NINJA 250 R",
          "BOXER AR",
          "NINJA ZX 10R ABS",
          "NINJA Z800 ABS",
          "NINJA Z1000 ABS",
          "NINJA ZX 14R ABS",
          "KB 100 RTZ"
        ]
      },
      {
        "model": "KB",
        "variants": [
          "125",
          "RTZ",
          "100 STD",
          "125 RTZ STD"
        ]
      },
      {
        "model": "4S CHAMPION",
        "variants": [
          "100CC",
          "STD"
        ]
      },
      {
        "model": "KG RTZ",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LEGEND",
        "variants": [
          "STANDARD",
          "NXT",
          "STD",
          "STD"
        ]
      },
      {
        "model": "LUNA",
        "variants": [
          "SUPER STAR V2 80",
          "SUPER START TFR"
        ]
      },
      {
        "model": "M 80",
        "variants": [
          "STD",
          "4 STROKE",
          "CC",
          "4 S"
        ]
      },
      {
        "model": "PLATINA",
        "variants": [
          "KS ALLOY BS VI FACELIFT",
          "DTS-SI",
          "KICK START CBS",
          "125 CC ES",
          "ES DISC",
          "COMFORTEC ALLOY WHEEL ES",
          "ES ALLOY BS VI FACELIFT",
          "110 ES DRUM",
          "110 ES DISC ABS BSVI",
          "ALLOY WHEELS",
          "SPOKE WHEELS",
          "STD",
          "100 ES SPOKE BSIV",
          "100 ES COMFORTEC BSIV",
          "100 KS",
          "110 CBS",
          "110 ES COMFORTEC",
          "100 KS CBS BSVI",
          "100 ES CBS BSVI",
          "110 H ES DISC BSVI",
          "100 DB BSVI",
          "100 ES DRUM 4 SPEED BSVI",
          "110 5 SPEED DISC ABS BSVI",
          "DRUM 5 SPEED BSVI",
          "DRUM 5 SPEED",
          "100 ES DISC BSVI",
          "110 HDR",
          "110 ES DRUM BSVI",
          "100 SELF START",
          "STD",
          "100 CC",
          "110 CBC",
          "110 ES ALLOY CBS",
          "110 H GEAR DISC BS6",
          "110 H GEAR DISK CBS",
          "110 H GEAR DRUM CBS",
          "125",
          "ALLOY ES CBS",
          "ALLOY KS CBS",
          "COMFORTEC ES",
          "DRUM CBS",
          "STD",
          "100 KS CBS",
          "110 ES",
          "100 ES CBS",
          "ES DURM BS6",
          "100 ALLOY WHEEL",
          "100 ES",
          "100 STD",
          "110 DRUM 5G",
          "CHROME GRAPHICS",
          "ES ALLOY BS6",
          "KS ALLOY BS6",
          "H GEAR DISC BS6",
          "110 ABS BS6",
          "110 H GEAR ES DRUM BS6",
          "SPOKE",
          "ES",
          "A/W",
          "DRKS",
          "125 DTSI",
          "125 DTS-I KICK START",
          "125 DTS-I SELF START",
          "100 KS CBS BS6",
          "110 H GEAR DISC BS VI",
          "100 DISC BRAKE BS VI",
          "100 KS - BS6",
          "100 ES - BS6",
          "110 ES DISC ABS 5 SPEED BS VI",
          "110 H DISC BS VI",
          "100 ES CBS BS6",
          "110 DRUM CBS",
          "SPOKE WHEELS ELECTRIC START DRUM BRAKE",
          "110 ABS",
          "100 ES DRUM 4 SPEED BSVI",
          "110 ES DRUM 5 SPEED BS VI",
          "ALLOY WHEEL KICK START DISC BRAKE",
          "ALLOY WHEEL ELECTRIC START",
          "ALLOY  WHEEL",
          "110 H ES DRUM",
          "110 ES CBS",
          "110 H ES DISC",
          "ELECTRIC START DRUM BRAKE",
          "ALLOY  WHEEL DRUM BRAKE KICK START",
          "SPOKE KICK DRUM"
        ]
      },
      {
        "model": "PRIYA",
        "variants": [
          "STD",
          "STD",
          "125",
          "SIDE CAR",
          "STD"
        ]
      },
      {
        "model": "RAVE",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "SAFFIRE",
        "variants": [
          "STD",
          "75CC",
          "ZI",
          "100",
          "STD"
        ]
      },
      {
        "model": "SUNNY SPICE",
        "variants": [
          "STANDARD",
          "150"
        ]
      },
      {
        "model": "SPIRIT",
        "variants": [
          "STANDARD",
          "SELF",
          "60 CC",
          "60",
          "50",
          "70",
          "50 CC",
          "70 CC"
        ]
      },
      {
        "model": "STD",
        "variants": [
          "RED (M)"
        ]
      },
      {
        "model": "STRIDE",
        "variants": [
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "SUPER",
        "variants": [
          "STD",
          "HBSS STOP",
          "HBSS STOP-AD",
          "HBSS/DS STOP-AD",
          "FE",
          "STD",
          "EXCEL 100CC",
          "FE",
          "SCOOTER",
          "STD",
          "FE",
          "SIDE CAR",
          "STD (CC125)"
        ]
      },
      {
        "model": "WAVE",
        "variants": [
          "DTS-I",
          "110 CC",
          "STD"
        ]
      },
      {
        "model": "WIND",
        "variants": [
          "125",
          "DISC",
          "125 DISC BRAKE",
          "125 STD"
        ]
      },
      {
        "model": "DISCOVER 135",
        "variants": [
          "STANDARD",
          "DRUM"
        ]
      },
      {
        "model": "PULSAR AS200",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "PULSAR 220F",
        "variants": [
          "STANDARD",
          "BS VI"
        ]
      },
      {
        "model": "XCD",
        "variants": [
          "125CC",
          "125 SELF",
          "135",
          "135 DISC",
          "DRUM BRAKE",
          "ES W/O SG/LG",
          "KS W/O SG/LG",
          "STD",
          "125 ES",
          "135 DISC BRAKE",
          "135 ES",
          "135 KS",
          "EXCEED 125CC DT SSI",
          "SPRINT 135",
          "SPRINT 150",
          "135 DT SSI KICK START",
          "135 DT SSI SELF START",
          "125 KS",
          "125 DTSI KICK START",
          "135 DTSI SELF START",
          "125 DTSI SELF START",
          "135 DTSI SELF START DISC BRAKE",
          "125 DTSI SELF START DISC BRAKE",
          "135 DTSI KICK START"
        ]
      },
      {
        "model": "KRISTAL",
        "variants": [
          "95",
          "DTSI ELECTRIC START",
          "STD",
          "DTSI SELF START",
          "STD",
          "95 KS",
          "DT SI",
          "95 ES"
        ]
      },
      {
        "model": "AVENGER",
        "variants": [
          "200 DTS-I",
          "220 DTS-I",
          "150 STREET",
          "CRUISE",
          "STD",
          "STD",
          "STD",
          "STREET",
          "150 STREET BSIV",
          "180 STREET BSIV",
          "220 STREET BSIV",
          "220 CRUISE UG",
          "DTSI",
          "160 STREET ABS BSVI PD 31",
          "180 STD",
          "200 STD",
          "220 CRUISE",
          "220 STD",
          "160 STREET ABS",
          "160 STREET ABS BSVI",
          "160 STREET BSVI",
          "200",
          "220 CRUISE ABS BSVI",
          "220 STREET ABS BSVI",
          "CRUISE 220",
          "CRUISE 220 ABS",
          "CRUISE SP 220",
          "STREET",
          "STREET 220 ABS",
          "220 CRUISE BSIV",
          "STD",
          "220 STREET",
          "DIES (SF)",
          "220",
          "220 DTSI KS",
          "180",
          "220 DTSI ES"
        ]
      },
      {
        "model": "PULSAR 220 DTS-I",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "DISCOVER 100",
        "variants": [
          "4 SPEED",
          "5 SPEED",
          "5 SPEED SELF",
          "4G",
          "M - SELF START",
          "M - SELF START DISC BRAKE"
        ]
      },
      {
        "model": "PULSAR 135 LS",
        "variants": [
          "STANDARD",
          "135CC"
        ]
      },
      {
        "model": "PULSAR NS200",
        "variants": [
          "ABS",
          "STANDARD",
          "BS VI"
        ]
      },
      {
        "model": "DISCOVER 100 T",
        "variants": [
          "DRUM BRAKE",
          "DISC BRAKE"
        ]
      },
      {
        "model": "DISCOVER 150",
        "variants": [
          "STANDARD",
          "DISC BRAKE",
          "DRUM BRAKE",
          "DTS-SI ELEC START DISC BRAKE",
          "ELECTRIC START DISC BRAKE",
          "S - SELF START",
          "STD",
          "DTS-SI ELECTRIC START DISC BRK",
          "F DRUM BRAKE ELECTRIC START",
          "DTS-SI ELECTRIC START DRUM BRK",
          "S  DRUM BRAKE ELECTRIC START",
          "S  DISK BRAKE ELECTRIC START",
          "F  DISK BRAKE ELECTRIC START"
        ]
      },
      {
        "model": "DISCOVER 125 M",
        "variants": [
          "DRUM",
          "DISC"
        ]
      },
      {
        "model": "DISCOVER 150S",
        "variants": [
          "DRUM",
          "DISC"
        ]
      },
      {
        "model": "DISCOVER 150F",
        "variants": [
          "DRUM",
          "DISC"
        ]
      },
      {
        "model": "PULSAR RS200",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "PULSAR AS150",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "AVENGER 150 STREET",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "V15",
        "variants": [
          "STANDARD",
          "BSIV",
          "STD",
          "SIDE CAR",
          "ELECTRIC START ALLOY WHEEL FR. DISC"
        ]
      },
      {
        "model": "V12",
        "variants": [
          "DRUM",
          "DISC",
          "DRUM BSIV",
          "DISC BSIV",
          "STD",
          "4 STOKE",
          "DISC BRAKE",
          "STD",
          "ELECTRIC START ALLOY WHEEL FR. DRUM",
          "ELECTRIC START ALLOY WHEEL FR. DISC"
        ]
      },
      {
        "model": "AVENGER 220 STREET",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "DOMINAR 400",
        "variants": [
          "STANDARD BS VI",
          "ABS"
        ]
      },
      {
        "model": "PULSAR NS160",
        "variants": [
          "STANDARD",
          "TWIN DISC",
          "SINGLE DISC",
          "TWIN DISC ABS BS VI"
        ]
      },
      {
        "model": "AVENGER 220 CRUISE",
        "variants": [
          "STANDARD",
          "DESERT GOLD EDITION"
        ]
      },
      {
        "model": "PULSAR 125",
        "variants": [
          "DRUM-CBS",
          "DISC-CBS",
          "SPLIT SEAT",
          "SPLIT SEAT DRUM CBS",
          "DTS-I",
          "DISC BS VI",
          "NEON DISC",
          "SPLIT DRUM BRAKE ALLOY WHEEL BS VI",
          "SPLIT DISC BRAKE ALLOY WHEEL BS VI",
          "NEON DISC BRAKE BS6",
          "NEON DRUM BRAKE BS6",
          "NEON CBS",
          "CBS",
          "DRUM BRAKE",
          "CARBON DISC SINGLE SEAT",
          "CARBON DISC SPLIT SEAT",
          "NEON SPLIT DISC BRAKE ALLOY WHEEL",
          "NS DISC",
          "DISC BRAKE"
        ]
      },
      {
        "model": "AVENGER STREET 160",
        "variants": [
          "STANDARD",
          "BS VI"
        ]
      },
      {
        "model": "PLATINA 110",
        "variants": [
          "CBS",
          "ES DRUM BS6"
        ]
      },
      {
        "model": "CT 110",
        "variants": [
          "KS ALLOY BS VI",
          "ES ALLOY BS VI",
          "KS CBS",
          "KS ALLOY CBS BSVI",
          "ES ALLOY CBS BSVI",
          "X BSVI",
          "X ES BSVI",
          "X KS BSVI",
          "ES ALLOY",
          "ES ALLOY BS6",
          "ES CBS",
          "KS ALLOY BS6",
          "STD",
          "X",
          "KS CBS",
          "ELECTRIC START DRUM BREAK ALLOY WHEEL - BS6",
          "KICK START ALLOY WHEEL - BS6",
          "ELECTRIC START DRUM BREAK ALLOY WHEEL",
          "X ELECTRIC START DRUM BREAK ALLOY WHEEL BS6",
          "X KICK START DRUM BREAK ALLOY WHEEL BS6",
          "CBS KICK START",
          "CBS SELF START"
        ]
      },
      {
        "model": "DOMINAR 250",
        "variants": [
          "STANDARD",
          "ABS BS-VI"
        ]
      },
      {
        "model": "PULSAR 180F",
        "variants": [
          "NEON ABS BS VI",
          "STANDARD"
        ]
      },
      {
        "model": "PULSAR 220FI",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "PULSAR 200",
        "variants": [
          "STANDARD",
          "STD",
          "DTSI ELECTRIC START",
          "RS ABS DOUBLE DISC",
          "RS BS VI",
          "NS 4V DOUBLE DISC",
          "RS NON ABS",
          "AS"
        ]
      },
      {
        "model": "PULSAR 220S",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "BLADE",
        "variants": [
          "125CC",
          "125",
          "125 CC SCOTER"
        ]
      },
      {
        "model": "PLATINA 110 H-GEAR",
        "variants": [
          "DISC BS VI",
          "ABS",
          "DISC-CBS"
        ]
      },
      {
        "model": "PULSAR NS125",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "PULSAR",
        "variants": [
          "N250",
          "250 F",
          "S",
          "SF",
          "STD",
          "UG 4.5 ELECTRIC START",
          "UG4 ELECTRIC START",
          "135 LS UG DISC LG/SG BSIV",
          "150 ES UG-4.5 BSIV",
          "180 ES BSIV LG/SG",
          "220 SF LG/SG BSIV",
          "200 NS BSIV",
          "200 NS ABS BSIV",
          "200 RS W/O ABS BSIV",
          "200 RS ABS BSIV",
          "180 F",
          "F ABS",
          "160 NS TWIN DISC",
          "150 TWIN DISC ABS",
          "220 F TWIN DISC",
          "150 TD ABS",
          "180 HF ABS",
          "160 NS ABS",
          "180 F NEON ABS",
          "180 F ABS",
          "150 CLASSIC ABS",
          "150 C AND G",
          "125 NEON CBS DISC",
          "125 NEON CBS DRUM",
          "150 C AND G (ABS)",
          "125 NEON DRUM CBS BSVI",
          "125 TWIN DISC",
          "125 NEON DISC FI BSVI",
          "NS 125 OBD2 BSVI",
          "125 NEON DRUM SPLIT SEAT BSVI",
          "125 NEON DISC SPLIT SEAT BSVI",
          "N 160 SINGLE DISC BSVI",
          "N 160 DUAL DISC BSVI",
          "N 250 DUAL DISC BSVI",
          "F 250 DUAL DISC BSVI",
          "125 DISC SINGLE SEAT BSVI",
          "125 CARBON DISC SINGLE ST BSVI",
          "125 CARBON DISC SPLIT ST BSVI",
          "150 TWIN DISC DAGGER BSVI",
          "150 SINGLE DISC DAGGER BSVI",
          "P150 SINGLE DISC BSVI",
          "P150 TWIN DISC BSVI",
          "150 TD NEXT GEN BSVI",
          "ELECTRIC START STD",
          "150 DTS-I BSIII",
          "150 DTSI DISC CLASSIC",
          "180 BIKINI",
          "AS STD",
          "AS STD",
          "NS STD",
          "RS ABS",
          "RS NON ABS",
          "160 NS TD ABS BSVI",
          "180 HF ABS BSVI",
          "220 F ABS BSVI",
          "200 NS ABS BSVI",
          "200 RS TWIN ABS BSVI",
          "125 SPLIT DTSI BSVI",
          "125 DB SPLIT BSVI",
          "NS 125 DISC",
          "N 250 SINGLE DISC BSVI",
          "F 250 SINGLE DISC BSVI",
          "180CC DT SI",
          "125 DISC CBC",
          "125 DRUM CBC",
          "125 NEON",
          "125 NEON CBS DISC BSVI",
          "135",
          "135 DTSI KS",
          "150 BS IV",
          "150 DTSI ES",
          "150 DTSI UG 4",
          "150 NEON",
          "150 NEON ABS",
          "150 NEON ABS BSVI",
          "150 SINGLE DISC ABS",
          "150 STD ABS BSVI",
          "150 TWIN DISC",
          "150 TWIN DISC ABS",
          "150 TWIN DISC ABS BSVI",
          "150 UG4.5",
          "150CC DT SI",
          "180",
          "180 ABS",
          "180 DTS-I KS",
          "180 F",
          "180 HF",
          "180 NEON ABS BSVI",
          "180F NEON ABS",
          "200 4V REAR DISC",
          "200 DTSI KS",
          "200 NS ABS",
          "200 NS BSVI",
          "200CC DT SI",
          "220",
          "220 ABS",
          "220 BSVI",
          "220 DT SI",
          "220 DTSI KS",
          "220 F",
          "220 F ABS",
          "220 F BS6",
          "220 SF",
          "220 SF BSVI",
          "250",
          "AS 200",
          "DISC ABS",
          "DTSI UG3",
          "DTSI UG4",
          "LS",
          "NS 160 BSVI",
          "NS 160 DISC ABS",
          "NS 160 REAR DISC",
          "NS 200 BS6",
          "RS 200 ABS",
          "RS 200 BSVI",
          "RS200 TWIN DISC BS6",
          "STD ABS",
          "UG4 4.5 DIES",
          "UG4 DIES",
          "135 LS",
          "125 DRUM BSVI",
          "150 CLASSIC",
          "200 NS",
          "135 DTSI LS",
          "NS160 TWIN DISC ABS BS VI",
          "NS160 DUAL CHANNEL ABS",
          "125 CARBON SPLIT SEAT",
          "250 F DUAL CHANNEL",
          "250 N SINGLE CHANNEL",
          "F 250 SINGLE CHANNEL ABS",
          "P150 SINGLE DISC",
          "P150 TWIN DISC",
          "N160 DUAL CHANNEL ABS",
          "N160 SINGLE CHANNEL ABS",
          "135 DTSI SELF START",
          "NS200 DUAL CHANNEL ABS",
          "125 STANDARD DTSI",
          "N250 STANDARD",
          "F250 STANDARD",
          "N 160",
          "N250 DUAL CHANNEL ABS",
          "125 CARBON DISC SINGLE SEAT",
          "NEON CRB DISC",
          "N 160 DUAL CHANNEL",
          "NS 160",
          "180 DOUBLE DISC",
          "220 STD",
          "125 NEON SPLIT SEAT BS6",
          "NS 200 ABS BS6",
          "180 F BS6",
          "NS 125 BS6",
          "NEON DRUM SPLIT SEAT BS6",
          "NEON DISC SPLIT SEAT BS6",
          "NS160 TWIN DISC BS6",
          "150 BS6",
          "DAGGER EDGE EDITION",
          "220 OLD VERSION",
          "RS 200 STD",
          "150",
          "220 (OLD FARING)",
          "180 DTS-I ES",
          "220 DTS-I F",
          "DIES (SF)",
          "150 DTSI",
          "300",
          "150DTSIMAG-WHEEL SS",
          "135 DTSI ES",
          "150 AS",
          "150 DTSI KS",
          "180 DTSI ES MAG WHEEL",
          "180 DTSI KS MAG WHEEL",
          "200 DTSI ES",
          "220 DTSI ES",
          "150 DTSI SELF START",
          "150 NS",
          "180 DTSI SELF START",
          "180 NS",
          "200 AS SELF START",
          "200 RS ABS",
          "200 RS STD",
          "220 F SELF START",
          "SS 400",
          "150 KICK START",
          "150 DTSI KICK START",
          "160 NS",
          "180 SELF START",
          "150 SELF START",
          "220 DTSI SELF START",
          "200 NS SELF START",
          "200 STD",
          "220 SF SELF START",
          "200 DTSI SELF START",
          "220 DTS-FI SELF START",
          "220 S SELF START"
        ]
      },
      {
        "model": "CT 125",
        "variants": [
          "X",
          "X DISC BSVI",
          "X DRUM BSVI",
          "X BS6 DRUM",
          "X BS6 DISC"
        ]
      },
      {
        "model": "PULSAR N160",
        "variants": [
          "TWIN DISC"
        ]
      },
      {
        "model": "KTM DUKE",
        "variants": [
          "390",
          "SUPER 990",
          "200",
          "250",
          "STD",
          "690",
          "200 STANDARD",
          "390 ABS"
        ]
      },
      {
        "model": "KTM",
        "variants": [
          "125 STD",
          "1050 ADVENTURE",
          "200 DUKE",
          "RC 125",
          "390 DUKE ABS",
          "200",
          "200 4V WATERCOOL"
        ]
      },
      {
        "model": "AMPERE",
        "variants": [
          "V60 E"
        ]
      },
      {
        "model": "ELIMINATOR",
        "variants": [
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "SCOOTER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BOXER 150",
        "variants": [
          "ELEC ST DRUM BRAKE SPOKE WHEEL",
          "ELECTRIC START SPOKE WHEEL DRUM BRK"
        ]
      },
      {
        "model": "DISCOVER 125",
        "variants": [
          "ELECTRIC START DISC BRAKE",
          "ELECTRIC START DRUM BRAKE",
          "KICK START DISC BRAKE",
          "KICK START DRUM BRAKE",
          "ST ELEC ST DISC BRAKE AW",
          "CBS",
          "BSIII",
          "STD",
          "DRUM-CBS",
          "DISC-CBS",
          "KICK START DRUM BRAKE ALLOY WHEEL",
          "CBS SELF START DISC BRAKE ALLOY WHEEL",
          "CBS SELF START DRUM BRAKE ALLOY WHEEL",
          "ELECTRIC START",
          "ELECTRIC START DISK BREAK",
          "ELECTRIC START DISC BRAKE ALLOY WHEEL",
          "ELECTRIC START DRUM BRAKE ALLOY WHEEL",
          "M - SELF START DISC BREAK ALLOY WHEEL",
          "M - SELF START DRUM BREAK ALLOY WHEEL"
        ]
      },
      {
        "model": "DISCOVER F",
        "variants": [
          "ELECTRIC START DISK BRAKE"
        ]
      },
      {
        "model": "DISCOVER M",
        "variants": [
          "DISC BRAKE",
          "DRUM BRAKE",
          "ELEC ST DISC BRAKE AWS",
          "ELEC ST DRUM BRAKE AWS"
        ]
      },
      {
        "model": "DISCOVER S",
        "variants": [
          "DISC BRAKE",
          "DRUM BRAKE"
        ]
      },
      {
        "model": "DISCOVER ST",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DISCOVER T",
        "variants": [
          "ELEC ST DISC BRAKE ALLOY WHEEL",
          "ELEC ST DISC BRAKE AWS",
          "ELEC ST DRUM BRAKE ALLOY WHEEL",
          "ELEC ST DRUM BRAKE AWS",
          "KICK ST DRUM BRAKE AWS"
        ]
      },
      {
        "model": "PLATINA 125 DTS-I",
        "variants": [
          "ELECTRIC START",
          "BM SELF START"
        ]
      },
      {
        "model": "WIND 125",
        "variants": [
          "DRUM BRAKE",
          "STD",
          "DRUM BREAK",
          "DISC BREAK"
        ]
      },
      {
        "model": "XCD 125",
        "variants": [
          "ELECTRIC START",
          "KICK START",
          "STD"
        ]
      },
      {
        "model": "XCD 135",
        "variants": [
          "ELEC START DISK BRAKE",
          "KICK START DRUM BRAKE",
          "STD"
        ]
      },
      {
        "model": "XCD 135 DTSI",
        "variants": [
          "ELEC START DISC BRAKE",
          "ELECTRIC START",
          "ELECTRIC START DISC BREAK"
        ]
      },
      {
        "model": "DOMINAR",
        "variants": [
          "400RS ABS",
          "400RS DISC",
          "250 D BSVI",
          "400 D BSVI",
          "250 BS6",
          "400 STD",
          "400",
          "400 BS6",
          "D 400 BS6",
          "400 BS VI",
          "D 250",
          "D 250 BS VI",
          "400 ABS UG",
          "400 BS IV",
          "250 TWIN DISC BRAKE",
          "400 TWIN DISC BRAKE",
          "TWIN CHANNEL ABS",
          "DISC BRAKES"
        ]
      },
      {
        "model": "M",
        "variants": [
          "80 4 STROKE",
          "80 2 STROKE"
        ]
      },
      {
        "model": "AVENGER 180",
        "variants": [
          "STREET",
          "STREET ABS"
        ]
      },
      {
        "model": "PLATINA 100",
        "variants": [
          "ES",
          "SPOKE"
        ]
      },
      {
        "model": "PULSAR RS 400",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CT",
        "variants": [
          "ES ALLOY",
          "100 DELUXE",
          "100 DRUM KICK AND SPOKE",
          "STD"
        ]
      },
      {
        "model": "CHAMPION",
        "variants": [
          "4S"
        ]
      },
      {
        "model": "CT100",
        "variants": [
          "ALLOY ES CBS",
          "ALLOY KS BS6",
          "ALLOY KS CBS",
          "SPOKE CBS",
          "STD",
          "ALLOY ES CBS BSVI",
          "DLX"
        ]
      },
      {
        "model": "BAJAJ DISCOVER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "M80",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PULSAR DTS FI 220 CC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PULSAR 135",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SAFFIRE ZI",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SPIRIT ORDINARY",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CLASSIC SL",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BOXER CT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BAJAJ  CT 100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BAJAJ 4S CHAMPION",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SUNNY",
        "variants": [
          "STD",
          "SPICE ES",
          "STD",
          "SPICE",
          "ZIP"
        ]
      },
      {
        "model": "DISCOVER 135.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DISCOVER K 70",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PULSAR DTSI ES 200",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SPICE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "K B - 100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "K B - 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BAJAJ RAVE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "AVENGER 200CC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BRAVO.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KAWASAKI 4S",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PRIYA SCOOTERS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PLATINA 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BOXER AT.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "WAVE DTSI",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "AVENGER 220",
        "variants": [
          "STD",
          "STREET ABS",
          "CRUISE ABS",
          "CRUISE BS VI",
          "CRUISE FI DISC BRAKE"
        ]
      },
      {
        "model": "CALIBER.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VILAS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KTM 390 DUKE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PULSAR 150 DTS-I",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PULSAR RS200 ABS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PRIDE",
        "variants": [
          "FX"
        ]
      },
      {
        "model": "V-15",
        "variants": [
          "POWER UP",
          "150"
        ]
      },
      {
        "model": "PULSAR 150 DTSI",
        "variants": [
          "ABS",
          "ELECTRIC START",
          "CLASSIC BS VI",
          "TWIN DISC - BS6",
          "NEON BS 6",
          "SD ABS BS6",
          "TWIN DISC NEW C AND G ABS",
          "C & G",
          "CLASSIC NEON ABS",
          "TD NON ABS",
          "TWIN DISC NEW C AND G",
          "DISC BRAKE",
          "NEON DISC BRAKE",
          "TWIN DISC",
          "NEON ABS",
          "SD ABS",
          "CLASSIC",
          "TWIN DISC ABS"
        ]
      },
      {
        "model": "PULSAR 220 DTS-FI",
        "variants": [
          "ELECTRIC START",
          "TWIN DISC BRAKE"
        ]
      },
      {
        "model": "PULSAR 220 DTSI",
        "variants": [
          "ELECTRIC START",
          "ELECTRIC START BS VI",
          "SF C&G"
        ]
      },
      {
        "model": "PULSAR 135 DTSI",
        "variants": [
          "ELECTRIC START"
        ]
      },
      {
        "model": "DISCOVER 135 DTSI",
        "variants": [
          "ELECTRIC START",
          "SPORTS"
        ]
      },
      {
        "model": "PULSAR 180 DTSI",
        "variants": [
          "ELECTRIC START",
          "ABS BS6",
          "HF NEON ABS",
          "NEON BS VI",
          "TWIN DISC BRAKE",
          "HF ABS",
          "BIKING FAIRING",
          "HF NEON ABS BS VI",
          "HF",
          "ABS"
        ]
      },
      {
        "model": "AVENGER 160",
        "variants": [
          "STREET",
          "STREET FI BS VI",
          "STREET FI DISC BRAKE",
          "ABS STREET",
          "ABS"
        ]
      },
      {
        "model": "CT 125 X",
        "variants": [
          "DRUM",
          "DISC"
        ]
      },
      {
        "model": "V-12",
        "variants": [
          "125"
        ]
      },
      {
        "model": "KB-100",
        "variants": [
          "STD 100 CC"
        ]
      },
      {
        "model": "CALIBER CROMA",
        "variants": [
          "115"
        ]
      },
      {
        "model": "KB125",
        "variants": [
          "RTZ"
        ]
      },
      {
        "model": "KTM RC",
        "variants": [
          "200 STANDARD",
          "390 ABS"
        ]
      },
      {
        "model": "PROWLER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RTZ",
        "variants": [
          "125",
          "125 CC"
        ]
      },
      {
        "model": "SX ENDURO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SONIC",
        "variants": [
          "DTSI",
          "DTSI 100 CC MOTORCYCLE"
        ]
      },
      {
        "model": "WONDER GEAR",
        "variants": [
          "WONDER GEAR"
        ]
      },
      {
        "model": "PULSAR 160",
        "variants": [
          "NS TD - BS6",
          "NS DUAL CHANNEL ABS",
          "NS DUAL DISC ABS",
          "NS TD ABS",
          "NS DISC"
        ]
      },
      {
        "model": "PULSAR 220 F",
        "variants": [
          "ELECTRIC START DISC BREAK  BS6",
          "ELECTRIC START DISC BREAK"
        ]
      },
      {
        "model": "PULSAR 180 F",
        "variants": [
          "TWIN DISC BRAKE",
          "HF"
        ]
      },
      {
        "model": "PULSAR 125 DTSI",
        "variants": [
          "SPLIT DISC BRAKE ALLOY WHEEL BS VI"
        ]
      },
      {
        "model": "PULSAR 250 N",
        "variants": [
          "SINGLE CHANNEL",
          "DUAL CHANNEL",
          "ELECTRIC START"
        ]
      },
      {
        "model": "PULSAR 250 F",
        "variants": [
          "SINGLE CHANNEL",
          "DUAL CHANNEL",
          "ELECTRIC START"
        ]
      },
      {
        "model": "PULSAR N 160",
        "variants": [
          "SINGLE CHANNEL ABS",
          "DUAL CHANNEL ABS"
        ]
      },
      {
        "model": "PULSAR 150 P",
        "variants": [
          "DUAL DISK",
          "SINGLE DISK"
        ]
      },
      {
        "model": "XCD 125 DTSI",
        "variants": [
          "ELECTRIC START",
          "ELECTRIC START DISC BREAK"
        ]
      },
      {
        "model": "M50",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KAWASAKI NINJA",
        "variants": [
          "250 Z",
          "300 R",
          "ER 6N",
          "Z 1000 SX ABS"
        ]
      },
      {
        "model": "PULSAR 220 SF",
        "variants": [
          "ELECTRIC START DISC BREAK ALLOY WHEEL"
        ]
      },
      {
        "model": "PULSAR 220 S",
        "variants": [
          "ELECTRIC START DISC BREAK"
        ]
      },
      {
        "model": "DISCOVER 125 T",
        "variants": [
          "ELECTRIC START DISC BREAK ALLOY WHEEL",
          "ELECTRIC START DRUM BREAK ALLOY WHEEL"
        ]
      }
    ]
  },
  {
    "make": "BALAJI",
    "models": [
      {
        "model": "LEO",
        "variants": [
          "SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "BATTIRE",
    "models": [
      {
        "model": "LOVE",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "BATTRE",
    "models": [
      {
        "model": "ONE",
        "variants": [
          "STANDARD",
          "24AH",
          "30AH",
          "42AH",
          "26AH",
          "28AH",
          "TRONTEK NMC LFP 48V 29AH",
          "OKAYA LFP 48V 30AH",
          "OKAYA LFP 48V 24AH",
          "INVERTED NMC LFP 60V 52AH",
          "INVERTED NMC LFP 60V 42AH",
          "INVERTED NMC LFP 60V 28.6AH",
          "INVERTED NMC LFP 60V 24AH",
          "INVERTED NMC LFP 48V 26AH",
          "AKIRA LFP 48V 30AH",
          "AKIRA LFP 48V 24AH",
          "AKIRA LFP 60V 28AH",
          "AKIRA LFP 48V 29AH",
          "AKIRA LFP 48V 26AH"
        ]
      },
      {
        "model": "LO EV",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "IOT",
        "variants": [
          "STANDARD",
          "42AH"
        ]
      },
      {
        "model": "GPS IE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "STORIES",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BATTRE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "LOEV",
        "variants": [
          "24AH",
          "30AH",
          "LITHIUM 60V 42AH",
          "LITHIUM 60V 28AH",
          "LITHIUM 48V 26AH",
          "LITHIUM 48V 29AH",
          "TRONTEK NMC LFP 48V 29AH",
          "OKAYA LFP 48V 30AH",
          "OKAYA LFP 48V 24AH",
          "INVERTED NMC LFP 60V 52AH",
          "INVERTED NMC LFP 60V 42AH",
          "INVERTED NMC LFP 60V 28.6AH",
          "INVERTED NMC LFP 60V 24AH",
          "INVERTED NMC LFP 48V 26AH",
          "AKIRA LFP 48V 30AH",
          "AKIRA LFP 48V 24AH",
          "AKIRA LFP 60V 28AH",
          "AKIRA LFP 48V 29AH",
          "AKIRA LFP 48V 26AH",
          "STD"
        ]
      },
      {
        "model": "GASIE",
        "variants": [
          "48AH"
        ]
      },
      {
        "model": "STORIE",
        "variants": [
          "60V 52AH"
        ]
      },
      {
        "model": "B1",
        "variants": [
          "LITHIUM 48V 26AH",
          "LITHIUM 60V 28AH",
          "LITHIUM 60V 42AH"
        ]
      },
      {
        "model": "LOEV GPSIE",
        "variants": [
          "TRONTEK NMC LFP 48V 29AH",
          "OKAYA LFP 48V 30AH",
          "OKAYA LFP 48V 24AH",
          "INVERTED NMC LFP 60V 52AH",
          "INVERTED NMC LFP 60V 42AH",
          "INVERTED NMC LFP 60V 28.6AH",
          "INVERTED NMC LFP 60V 24AH",
          "INVERTED NMC LFP 48V 26AH",
          "AKIRA LFP 60V 28AH",
          "AKIRA LFP 48V 29AH",
          "AKIRA LFP 48V 26AH",
          "AKIRA LFP 48V 30AH",
          "AKIRA LFP 48V 24AH"
        ]
      },
      {
        "model": "ONE GPSIE",
        "variants": [
          "TRONTEK NMC LFP 48V 29AH",
          "OKAYA LFP 48V 30AH",
          "OKAYA LFP 48V 24AH",
          "INVERTED NMC LFP 60V 52AH",
          "INVERTED NMC LFP 60V 42AH",
          "INVERTED NMC LFP 60V 28.6AH",
          "INVERTED NMC LFP 60V 24AH",
          "INVERTED NMC LFP 48V 26AH",
          "AKIRA LFP 60V 28AH",
          "AKIRA LFP 48V 29AH",
          "AKIRA LFP 48V 26AH",
          "AKIRA LFP 48V 30AH",
          "AKIRA LFP 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "BATTRE ELECTRIC",
    "models": [
      {
        "model": "STORIE",
        "variants": [
          "60V",
          "60V 2000W",
          "E-SCOOTER"
        ]
      },
      {
        "model": "LOEV",
        "variants": [
          "60V",
          "LO EV 40AH",
          "STD",
          "LO EV 30AH"
        ]
      },
      {
        "model": "ONE",
        "variants": [
          "60V",
          "STD",
          "30 AH",
          "DUAL BATTERY"
        ]
      },
      {
        "model": "GPSIE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "IOT",
        "variants": [
          "STD",
          "24AH DUAL BATTERY"
        ]
      }
    ]
  },
  {
    "make": "BATTRE ELECTRIC MOBILITY",
    "models": [
      {
        "model": "BATTRE ONE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "BATTRE",
        "variants": [
          "LO EV BOX",
          "STORIE",
          "E BIKE",
          "LO EV"
        ]
      }
    ]
  },
  {
    "make": "BCI",
    "models": [
      {
        "model": "E BHARAT",
        "variants": [
          "LEAD ACID BATTERY 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "BENELLI",
    "models": [
      {
        "model": "TNT600I",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TNT 899",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "IMPERIALE 400",
        "variants": [
          "STANDARD",
          "DUAL CHANNEL ABS",
          "STD"
        ]
      },
      {
        "model": "302R",
        "variants": [
          "STANDARD",
          "BSVI",
          "STD"
        ]
      },
      {
        "model": "TNT 25",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TNT 600GT",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TNT R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TNT 300",
        "variants": [
          "ABS"
        ]
      },
      {
        "model": "LEONCINO 250",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TRK 502",
        "variants": [
          "STANDARD",
          "BSVI",
          "STD"
        ]
      },
      {
        "model": "LEONCINO",
        "variants": [
          "STANDARD",
          "250",
          "STD",
          "250 STD",
          "STD"
        ]
      },
      {
        "model": "TRK 502X",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "502C",
        "variants": [
          "STD",
          "STANDARD"
        ]
      },
      {
        "model": "IMPERIALE",
        "variants": [
          "400 SILVER",
          "400 RED/BLACK",
          "400 SILVER BSVI",
          "400 RED/BLACK BSVI",
          "400",
          "400 BS6"
        ]
      },
      {
        "model": "TNT 15",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TNT 135",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BX",
        "variants": [
          "250"
        ]
      },
      {
        "model": "TNT",
        "variants": [
          "25 STD",
          "300",
          "600 GT",
          "600 I",
          "600 I LIMITED EDITION",
          "899",
          "R",
          "25 PREMIUM",
          "600 GTS",
          "300 STANDARD",
          "600 I GOLD LE",
          "300 M",
          "899",
          "R 1130",
          "300 P"
        ]
      },
      {
        "model": "TRK",
        "variants": [
          "502 X"
        ]
      },
      {
        "model": "LEONCINO 500",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "502 C",
        "variants": [
          "CRUISER"
        ]
      }
    ]
  },
  {
    "make": "BENLING",
    "models": [
      {
        "model": "AURA",
        "variants": [
          "STANDARD",
          "LI",
          "LI ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "FALCON",
        "variants": [
          "LEAD ACID",
          "STANDARD"
        ]
      },
      {
        "model": "KRITI",
        "variants": [
          "LEAD ACID",
          "STANDARD"
        ]
      },
      {
        "model": "ICON",
        "variants": [
          "LEAD ACID",
          "STANDARD"
        ]
      },
      {
        "model": "BELIEVE",
        "variants": [
          "STANDARD",
          "SCOOTER ALLOY DISC",
          "ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "BENLING INDIA",
    "models": [
      {
        "model": "INDICA",
        "variants": [
          "AURA"
        ]
      },
      {
        "model": "KRITI",
        "variants": [
          "20AH (12X4 VRLA)",
          "LITHIUM 22AH (LI-ION)",
          "LEAD BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "FALCON",
        "variants": [
          "20AH (12X5 VRLA)",
          "LITHIUM 22AH (LI-ION)",
          "LITHIUM 24AH (LI-ION)",
          "LITHIUM 60V 30AH",
          "LITHIUM BATTERY",
          "LEAD BATTERY"
        ]
      },
      {
        "model": "ICON",
        "variants": [
          "20AH (12X5 VRLA)",
          "LITHIUM 22AH (LI-ION)",
          "LITHIUM BATTERY",
          "LEAD BATTERY"
        ]
      },
      {
        "model": "AURA",
        "variants": [
          "LITHIUM 72V 40AH",
          "LITHIUM 72V 42AH",
          "LITHIUM 72V 43AH",
          "LITHIUM BATTERY",
          "BELIEVE",
          "LITHIUM ION 72V 43AH",
          "LITHIUM ION 72V 40AH"
        ]
      },
      {
        "model": "BELIEVE",
        "variants": [
          "LITHIUM 72V 43AH",
          "LITHIUM ION 72V 43AH"
        ]
      }
    ]
  },
  {
    "make": "BENLING INDIA ENERGY AND TECHNOLOGY PVT LTD",
    "models": [
      {
        "model": "KRITI",
        "variants": [
          "STANDARD",
          "LEAD ACID",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "ICON",
        "variants": [
          "STANDARD",
          "LEAD ACID",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "FALCON",
        "variants": [
          "STANDARD",
          "LEAD ACID",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "AURA",
        "variants": [
          "72V/40AH",
          "ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "BGAUSS",
    "models": [
      {
        "model": "MIXER MODEL EL 2.2",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "7100-HARVESTER",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "B8",
        "variants": [
          "LEAD-ACID",
          "LITHIUM-ION",
          "LI",
          "LITHIUM ION",
          "LEAD ACID",
          "LI TECHNOLOGY",
          "LFP 60 30AH",
          "LITHIUM-ION",
          "LEAD ACID",
          "LPF",
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY WITH GUARD",
          "LF"
        ]
      },
      {
        "model": "A2",
        "variants": [
          "LEAD-ACID",
          "LITHIUM-ION",
          "SUPER LITHIUM ION",
          "SUPER LEAD ACID",
          "LITHIUM-ION",
          "LEAD-ACID",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY",
          "LITHIUM BATTERY WITH GUARD"
        ]
      },
      {
        "model": "C12I",
        "variants": [
          "MAX"
        ]
      },
      {
        "model": "D15 PRO",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "D15 I",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "BG C12I MAX",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "D15",
        "variants": [
          "I",
          "PRO",
          "I.."
        ]
      },
      {
        "model": "C12I MAX",
        "variants": [
          "FOLIAGE GREEN",
          "MATTE BGAUSS BLUE",
          "DT - RED BLACK",
          "BROOKLYN BLACK",
          "ELECTRIC"
        ]
      },
      {
        "model": "C12",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "C 12 I MAX",
        "variants": [
          "LI 60V 55AH 2.5 KW"
        ]
      },
      {
        "model": "D 15 I",
        "variants": [
          "LI 60V 55AH 3.1 KW IP65",
          "E BIKE"
        ]
      },
      {
        "model": "D 15 PRO",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "C 12",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "BHARATHI BUSINESS MACHINES",
    "models": [
      {
        "model": "VIBRANT",
        "variants": [
          "E BIKE",
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "BHOOTHNATH E BIKE",
    "models": [
      {
        "model": "NANDI ECO 10",
        "variants": [
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "SPUNK 09 NANDI",
        "variants": [
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "NANDI Z10",
        "variants": [
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "NANDI SPUNK",
        "variants": [
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "NANDI PRO",
        "variants": [
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "NANDI DEFENDER",
        "variants": [
          "LITHIUM ION 60V"
        ]
      }
    ]
  },
  {
    "make": "BIBHU MOTORS INDA (BMI)",
    "models": [
      {
        "model": "STROM",
        "variants": [
          "110"
        ]
      }
    ]
  },
  {
    "make": "BIBHU MOTORS INDIA",
    "models": [
      {
        "model": "JL 100 A CRAZY",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "BIG DOG",
    "models": [
      {
        "model": "K9",
        "variants": [
          "DINGLE DISC"
        ]
      }
    ]
  },
  {
    "make": "BIRLA E BIKE",
    "models": [
      {
        "model": "DMS",
        "variants": [
          "LITHIUM 100KM 60AH",
          "LITHIUM 125KM 75AH",
          "LITHIUM 150KM 90AH",
          "LITHIUM 200KM 105AH"
        ]
      },
      {
        "model": "V6",
        "variants": [
          "LITHIUM 100KM 60AH",
          "LITHIUM 125KM 75AH",
          "LITHIUM 150KM 90AH",
          "LITHIUM 200KM 105AH"
        ]
      },
      {
        "model": "JF",
        "variants": [
          "LITHIUM 100KM 60AH",
          "LITHIUM 125KM 75AH",
          "LITHIUM 150KM 90AH",
          "LITHIUM 200KM 105AH"
        ]
      },
      {
        "model": "A1 4",
        "variants": [
          "LITHIUM 100KM 60AH",
          "LITHIUM 125KM 75AH",
          "LITHIUM 150KM 90AH",
          "LITHIUM 200KM 105AH"
        ]
      },
      {
        "model": "KN1",
        "variants": [
          "LITHIUM 100KM 30AH",
          "LITHIUM 100KM 45AH"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "LEAD ACID 27AH",
          "LEAD ACID 28AH",
          "LITHIUM ION 15AH",
          "LITHIUM ION 72V 20AH",
          "LITHIUM ION 72V 25AH",
          "LITHIUM ION 30AH",
          "LITHIUM ION 35AH"
        ]
      },
      {
        "model": "QUANTO",
        "variants": [
          "LEAD ACID 27AH",
          "LEAD ACID 28AH",
          "LITHIUM ION 15AH",
          "LITHIUM ION 72V 20AH",
          "LITHIUM ION 72V 25AH",
          "LITHIUM ION 30AH",
          "LITHIUM ION 35AH"
        ]
      },
      {
        "model": "E SMART",
        "variants": [
          "LEAD ACID 27AH",
          "LEAD ACID 28AH",
          "LITHIUM ION 15AH",
          "LITHIUM ION 72V 20AH",
          "LITHIUM ION 72V 25AH",
          "LITHIUM ION 30AH",
          "LITHIUM ION 35AH"
        ]
      },
      {
        "model": "ELECTRO",
        "variants": [
          "LEAD ACID 27AH",
          "LEAD ACID 28AH",
          "LITHIUM ION 15AH",
          "LITHIUM ION 72V 20AH",
          "LITHIUM ION 72V 25AH",
          "LITHIUM ION 30AH",
          "LITHIUM ION 35AH"
        ]
      },
      {
        "model": "AMBITION",
        "variants": [
          "LEAD ACID 27AH",
          "LEAD ACID 28AH",
          "LITHIUM ION 15AH",
          "LITHIUM ION 72V 20AH",
          "LITHIUM ION 72V 25AH",
          "LITHIUM ION 30AH",
          "LITHIUM ION 35AH"
        ]
      },
      {
        "model": "XL",
        "variants": [
          "LITHIUM ION 36AH"
        ]
      }
    ]
  },
  {
    "make": "BIRLA E-BIKE",
    "models": [
      {
        "model": "A 1-4",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "AMBITION",
        "variants": [
          "LA",
          "LI 15 AH",
          "LI 20 AH",
          "LI 25 AH",
          "LI 30 AH",
          "LI 35 AH"
        ]
      }
    ]
  },
  {
    "make": "BLIX",
    "models": [
      {
        "model": "BRAVO HI-SPEED",
        "variants": [
          "72V 48AH",
          "3000 WATT"
        ]
      },
      {
        "model": "BREEZE",
        "variants": [
          "LI PH 24AH",
          "R W 48V 24AH",
          "LI 48V 24AH",
          "STANDARD"
        ]
      },
      {
        "model": "VICTORY",
        "variants": [
          "BLUE LI PH 24AH",
          "GREY 48V 24AH",
          "WHITE 48V 24AH",
          "SILVER 48V 48AH",
          "HS LI 60V 35AH",
          "STANDARD"
        ]
      },
      {
        "model": "SKYLER RED",
        "variants": [
          "48V 24AH"
        ]
      },
      {
        "model": "VOLTA",
        "variants": [
          "48V 24AH"
        ]
      },
      {
        "model": "SKYLER",
        "variants": [
          "60V 30AH"
        ]
      },
      {
        "model": "THUNDER",
        "variants": [
          "E-SCOOTER",
          "LI 48V 24AH"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "BLU W E SCOOTY",
          "LI 48V 24AH"
        ]
      },
      {
        "model": "BATT RE ONE",
        "variants": [
          "E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "BLIX CYCLES AND  E VEHICLES",
    "models": [
      {
        "model": "SKYLER",
        "variants": [
          "LITHIUM P 60V 30AH"
        ]
      },
      {
        "model": "VOLTA",
        "variants": [
          "LITHIUM 48V 24AH"
        ]
      },
      {
        "model": "BRAVO HIGH SPEED",
        "variants": [
          "LITHIUM P 72V 48AH"
        ]
      },
      {
        "model": "VICTORY",
        "variants": [
          "LITHIUM PO4",
          "ULTRA MAX X 2 BATTERIES LIFEPO4",
          "ULTRA MAX LIFEPO4",
          "MAX LIFEPO4",
          "ULTRA LIFEPO4",
          "MEGA LIFEPO4",
          "LIFEPO4"
        ]
      },
      {
        "model": "BREEZE",
        "variants": [
          "LITHIUM PO4",
          "ULTRA MAX LIFEPO4",
          "LIFEPO4",
          "MEGA LIFEPO4",
          "ULTRA LIFEPO4",
          "MAX LIFEPO4"
        ]
      },
      {
        "model": "BLIX 250",
        "variants": [
          "ELX MEGA LIFEPO4",
          "ELX LIFEPO4",
          "ELX MAX LIFEPO4",
          "ELX ULTRA MAX LIFEPO4",
          "LIFEPO4",
          "ELX ULTRA LIFEPO4"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "LIFEPO4",
          "MEGA LIFEPO4",
          "ULTRA LIFEPO4",
          "MAX LIFEPO4",
          "ULTRA MAX LIFEPO4"
        ]
      },
      {
        "model": "THUNDER",
        "variants": [
          "LIFEPO4",
          "MEGA LIFEPO4",
          "ULTRA LIFEPO4",
          "MAX LIFEPO4",
          "ULTRA MAX LIFEPO4"
        ]
      }
    ]
  },
  {
    "make": "BLIX ELECTRIC SCOOTER",
    "models": [
      {
        "model": "VICTORY BLUE",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "BMI",
    "models": [
      {
        "model": "RAPTOR",
        "variants": [
          "150 ALLOY WHEEL"
        ]
      }
    ]
  },
  {
    "make": "BMW",
    "models": [
      {
        "model": "K1300 S",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "K1300 R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "S1000 RR",
        "variants": [
          "STANDARD",
          "PRO M SPORT"
        ]
      },
      {
        "model": "S 1000 XR",
        "variants": [
          "STANDARD",
          "STD",
          "PRO",
          "PRO RACING RED"
        ]
      },
      {
        "model": "G 310 R",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "R1200 GS",
        "variants": [
          "STANDARD",
          "DYNAMIC",
          "PRO"
        ]
      },
      {
        "model": "K 1600 B",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "K1600 GT",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "R1200 RS",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "R1200 GS ADVENTURE",
        "variants": [
          "STANDARD",
          "DYNAMIC",
          "PRO"
        ]
      },
      {
        "model": "R1200 RT",
        "variants": [
          "STANDARD",
          "PRO"
        ]
      },
      {
        "model": "R1300",
        "variants": [
          "S",
          "R"
        ]
      },
      {
        "model": "S 1000 R",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "F750 GS",
        "variants": [
          "PRO LOW SUSPENSION",
          "PRO",
          "STANDARD"
        ]
      },
      {
        "model": "R 1250 GS",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "F900 R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "K1600 GTL",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "R18",
        "variants": [
          "FIRST EDITION",
          "CLASSIC",
          "STD",
          "STANDARD",
          "100 YEARS EDITION",
          "TRANSCONTINENTAL"
        ]
      },
      {
        "model": "G310GS",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "M 1000 RR",
        "variants": [
          "STANDARD",
          "COMPETITION"
        ]
      },
      {
        "model": "K 1600",
        "variants": [
          "GRAND AMERICA",
          "B",
          "BAGGER",
          "GTL PRO",
          "EXCLUSIVE",
          "B GRAND AMERICA PRO"
        ]
      },
      {
        "model": "R 1200",
        "variants": [
          "RS STD",
          "RS DYNAMIC",
          "R EXCLUSIVE",
          "R STYLE",
          "GS ADV",
          "DYNAMIC",
          "PRO",
          "GSA DYNAMIC",
          "GSA PRO",
          "R DYNAMIC",
          "R PRO",
          "RS PRO",
          "RT STANDARD PLUS",
          "RT DYNAMIC",
          "RS DYNAMIC PLUS"
        ]
      },
      {
        "model": "G 310",
        "variants": [
          "G 310 GS",
          "G 310 RR",
          "R",
          "RR",
          "RR SPROT",
          "GS"
        ]
      },
      {
        "model": "GS",
        "variants": [
          "F 650",
          "F750",
          "F850"
        ]
      },
      {
        "model": "R 50",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "F 850 GS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BMW 1000",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BMW 1300",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BMW  F 650",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "R 1150",
        "variants": [
          "STD",
          "R (2001)",
          "GS"
        ]
      },
      {
        "model": "BMW 1200 RT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "R 1200 RS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "S1000RR",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "R NINE T SCRAMBLER",
        "variants": [
          "STD",
          "STANDARD"
        ]
      },
      {
        "model": "R NINE T",
        "variants": [
          "STD",
          "STYLE",
          "SCRAMBLER STYLE",
          "SCRAMBLER",
          "RACER"
        ]
      },
      {
        "model": "R 1200 GS ADVENTURE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BMW 1200",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BMW 1600",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "R 1200 GS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "F 650",
        "variants": [
          "GS",
          "STD",
          "GS 798 CC"
        ]
      },
      {
        "model": "F 700",
        "variants": [
          "GS"
        ]
      },
      {
        "model": "K",
        "variants": [
          "1300 GT",
          "1200 LT",
          "1300 R",
          "1300 S",
          "1600 GT",
          "1600 GTL",
          "1200 GT",
          "1200 S",
          "1200 GTL",
          "1200 GT"
        ]
      },
      {
        "model": "R",
        "variants": [
          "1250 GS PRO",
          "1250 GS STD",
          "1200 GS ADVENTURE",
          "1150 R",
          "1200 GS",
          "1150 GS ADVENTURE",
          "1150 GS",
          "100 GS",
          "1150 RT",
          "1200 GS ADVENTURE",
          "1200 R",
          "1200 C MONTAUK",
          "1200 RS",
          "1200 RT",
          "NINE T STD"
        ]
      },
      {
        "model": "MOTORRAD",
        "variants": [
          "HP4 CARBON SPORTS",
          "HP4",
          "HP4 RACE"
        ]
      },
      {
        "model": "R 1250 RT",
        "variants": [
          "PRO",
          "STANDARD"
        ]
      },
      {
        "model": "R NINET",
        "variants": [
          "STANDARD",
          "100 YEARS EDITION"
        ]
      },
      {
        "model": "F850 GS",
        "variants": [
          "STANDARD",
          "PRO",
          "PRO LOW SUSPENSION"
        ]
      },
      {
        "model": "F850 GS ADVENTURE",
        "variants": [
          "PRO"
        ]
      },
      {
        "model": "F900 XR",
        "variants": [
          "PRO",
          "STANDARD"
        ]
      },
      {
        "model": "R1200 R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "R NINET RACER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "R 1250 GS ADVENTURE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "R 1250",
        "variants": [
          "GS ADVENTURE PRO",
          "GSA STYLE HP",
          "GS ADVENTURE"
        ]
      },
      {
        "model": "S 1000",
        "variants": [
          "RR PRO",
          "R PRO",
          "R SPORT"
        ]
      },
      {
        "model": "C 400 GT",
        "variants": [
          "STANDARD",
          "ALPINE WHITE",
          "STYLE TRIPLE BLACK"
        ]
      },
      {
        "model": "R 1250 R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "G310 RR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "G",
        "variants": [
          "GS 310"
        ]
      },
      {
        "model": "F",
        "variants": [
          "900 R",
          "650 GS"
        ]
      },
      {
        "model": "1.4",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "CR",
        "variants": [
          "1200 STD"
        ]
      },
      {
        "model": "HP2 SPORT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "S",
        "variants": [
          "1000 R",
          "1000 RR",
          "1000 XR"
        ]
      },
      {
        "model": "K 1200",
        "variants": [
          "S",
          "GT"
        ]
      },
      {
        "model": "HP",
        "variants": [
          "4"
        ]
      },
      {
        "model": "R 26",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "R 18",
        "variants": [
          "FIRST EDITION",
          "CLASSIC-FIRST EDITION"
        ]
      },
      {
        "model": "F 900",
        "variants": [
          "XR"
        ]
      },
      {
        "model": "F 850",
        "variants": [
          "GSA PRO",
          "GS"
        ]
      },
      {
        "model": "R 1100",
        "variants": [
          "RS"
        ]
      },
      {
        "model": "F 750",
        "variants": [
          "GS"
        ]
      }
    ]
  },
  {
    "make": "BNC ENERGY",
    "models": [
      {
        "model": "CHALLENGER S110",
        "variants": [
          "LITHIUM ION 51.2V 40AH"
        ]
      }
    ]
  },
  {
    "make": "BNC MOTORS",
    "models": [
      {
        "model": "CHALLENGER",
        "variants": [
          "S 110"
        ]
      }
    ]
  },
  {
    "make": "BOOM MOTORS",
    "models": [
      {
        "model": "CORBETT 14",
        "variants": [
          "STD",
          "EX",
          "STANDARD",
          "EX",
          "EX LITHIUM BATTERY 4KW",
          "LITHIUM BATTERY  57.6V 40AH"
        ]
      },
      {
        "model": "AVAN 1200",
        "variants": [
          "E-BIKE"
        ]
      },
      {
        "model": "ANAV 1200",
        "variants": [
          "E-BIKE"
        ]
      },
      {
        "model": "CHALLENGER",
        "variants": [
          "LITHIUM ION 51.2V 40AH"
        ]
      }
    ]
  },
  {
    "make": "BOUNCE",
    "models": [
      {
        "model": "INFINITY",
        "variants": [
          "E1 WITHOUT BATTERY BAAS 2",
          "E1 WITH BATTERY PACK",
          "E1 WITHOUT BATTERY BAAS 1",
          "E1 LIMITED EDITION",
          "E.1",
          "E1 LIMITED EDITION",
          "E1 BATTERY PRO",
          "E1 BATTERY PACK"
        ]
      }
    ]
  },
  {
    "make": "BQI ELECTRIC",
    "models": [
      {
        "model": "FLIT NEO",
        "variants": [
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "BRAZEN",
    "models": [
      {
        "model": "F PRIME",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "F-PRIME",
        "variants": [
          "ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "BRIGHT STAR",
    "models": [
      {
        "model": "W1",
        "variants": [
          "60V 250W"
        ]
      },
      {
        "model": "EW 110",
        "variants": [
          "60V 250W"
        ]
      },
      {
        "model": "W2-65",
        "variants": [
          "60V 250W"
        ]
      },
      {
        "model": "EW 85",
        "variants": [
          "60V 250W"
        ]
      }
    ]
  },
  {
    "make": "BROI",
    "models": [
      {
        "model": "SPRINT PLUS",
        "variants": [
          "60V 24AH",
          "M1 LITE 60V 24AH",
          "M2 ULTRA 60V 24AH",
          "60V 28AH",
          "M1 LITE 60V 28AH",
          "M2 ULTRA 60V 28AH",
          "60V 30AH",
          "M1 60V 30AH",
          "M1 LITE 60V 30AH",
          "M2 ULTRA 60V 30AH",
          "60V 34AH",
          "M1 LITE 60V 34AH",
          "M2 ULTRA 60V 34AH"
        ]
      },
      {
        "model": "HASTEN",
        "variants": [
          "60V 24AH",
          "60V 28AH",
          "60V 30AH",
          "60V 34AH",
          "72V 24AH",
          "72V 28AH VRLA BC",
          "72V 20AH LI-ION BC",
          "72V 28AH LI-ION BC",
          "72V 30AH LI-ION BC",
          "72V 34AH LI-ION BC"
        ]
      },
      {
        "model": "LIGHTER SL",
        "variants": [
          "60V 24AH",
          "60V 28AH",
          "60V 30AH",
          "60V 34AH",
          "48V 28AH VRLA BC",
          "48V 20AH \r\nLI-ION BC",
          "48V 24AH LI-ION BC",
          "48V 28AH LI-ION BC",
          "48V 30AH LI-ION BC",
          "48V 34AH LI-ION BC",
          "60V 28AH VRLA BC",
          "60V 20AH LI-ION BC"
        ]
      },
      {
        "model": "SPRINT",
        "variants": [
          "48V 28AH VRLA BC",
          "48V 20AH \r\nLI-ION BC",
          "48V 24AH LI-ION BC",
          "48V 28AH LI-ION BC",
          "48V 30AH LI-ION BC",
          "48V 34AH LI-ION BC",
          "60V 28AH VRLA BC",
          "60V 20AH LI-ION BC",
          "60V 24AH LI-ION BC",
          "60V 28AH LI-ION BC",
          "60V 30AH LI-ION BC",
          "60V 34AH LI-ION BC"
        ]
      },
      {
        "model": "SPRINT PLUS M1",
        "variants": [
          "48V 28AH VRLA BC",
          "48V 20AH \r\nLI-ION BC",
          "48V 24AH LI-ION BC",
          "48V 28AH LI-ION BC",
          "48V 30AH LI-ION BC",
          "48V 34AH LI-ION BC",
          "60V 28AH VRLA BC",
          "60V 20AH LI-ION BC",
          "60V 24AH LI-ION BC",
          "60V 28AH LI-ION BC",
          "60V 34AH LI-ION BC"
        ]
      },
      {
        "model": "SPRINT PLUS M2",
        "variants": [
          "48V 28AH VRLA BC",
          "48V 20AH \r\nLI-ION BC",
          "48V 24AH LI-ION BC",
          "48V 28AH LI-ION BC",
          "48V 30AH LI-ION BC",
          "48V 34AH LI-ION BC",
          "60V 28AH VRLA BC",
          "60V 20AH LI-ION BC",
          "60V 24AH LI-ION BC",
          "60V 28AH LI-ION BC",
          "60V 30AH LI-ION BC",
          "60V 34AH LI-ION BC"
        ]
      },
      {
        "model": "SPRINT M1 LITE",
        "variants": [
          "48V 28AH VRLA BC",
          "48V 20AH \r\nLI-ION BC",
          "48V 24AH LI-ION BC",
          "48V 28AH LI-ION BC",
          "48V 30AH LI-ION BC",
          "48V 34AH LI-ION BC",
          "60V 28AH VRLA BC",
          "60V 20AH LI-ION BC"
        ]
      },
      {
        "model": "SPRINT M2 LITE",
        "variants": [
          "48V 28AH VRLA BC",
          "48V 20AH \r\nLI-ION BC",
          "48V 24AH LI-ION BC",
          "48V 28AH LI-ION BC",
          "48V 30AH LI-ION BC",
          "48V 34AH LI-ION BC",
          "60V 28AH VRLA BC",
          "60V 20AH LI-ION BC",
          "60V 24AH LI-ION BC",
          "60V 28AH LI-ION BC",
          "60V 30AH LI-ION BC",
          "60V 34AH LI-ION BC"
        ]
      },
      {
        "model": "SPRINT M2 ULTRA",
        "variants": [
          "48V 28AH VRLA BC",
          "48V 20AH \r\nLI-ION BC",
          "48V 24AH LI-ION BC",
          "48V 28AH LI-ION BC",
          "48V 30AH LI-ION BC",
          "48V 34AH LI-ION BC",
          "60V 28AH VRLA BC",
          "60V 20AH LI-ION BC"
        ]
      },
      {
        "model": "SPRINT PLUS PLUS",
        "variants": [
          "48V 28AH VRLA BC",
          "48V 20AH \r\nLI-ION BC",
          "48V 24AH LI-ION BC",
          "48V 28AH LI-ION BC",
          "48V 30AH LI-ION BC",
          "48V 34AH LI-ION BC",
          "60V 28AH VRLA BC",
          "60V 20AH LI-ION BC"
        ]
      }
    ]
  },
  {
    "make": "BSA",
    "models": [
      {
        "model": "EDGE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ROAMER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ROAMER ABLE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ROAMER PLUS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GOLD STAR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SMILE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DIVA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STREET RIDER",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "BSA MOTORS",
    "models": [
      {
        "model": "BSA DIVYA",
        "variants": [
          "DIVYA"
        ]
      },
      {
        "model": "BSA SMILE",
        "variants": [
          "SMILE"
        ]
      },
      {
        "model": "BSA STREET",
        "variants": [
          "RIDER"
        ]
      },
      {
        "model": "C 15",
        "variants": [
          "MOTORCYCLE",
          "250 CC MOTORCYCLE"
        ]
      },
      {
        "model": "GOLD STAR",
        "variants": [
          "STD",
          "STD CC500"
        ]
      },
      {
        "model": "ROAMER",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ROAMER PLUS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SOLO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VINTAGE",
        "variants": [
          "VINTAGE",
          "VINTAGE"
        ]
      },
      {
        "model": "B 31",
        "variants": [
          "350 CC MOTORCYCL"
        ]
      },
      {
        "model": "ROAMER ABLE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "M 20",
        "variants": [
          "591 CC MOTORCYCL"
        ]
      },
      {
        "model": "SHOOTING STAR",
        "variants": [
          "STD 500 CC"
        ]
      },
      {
        "model": "ROYAL STAR",
        "variants": [
          "500 CC MOTORCYCLE"
        ]
      },
      {
        "model": "DIVA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "EDGE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SMILE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "STREET RIDER",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "MD 650",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "C M OVERSEAS",
    "models": [
      {
        "model": "SPORTZ",
        "variants": [
          "ZX PLUS",
          "63"
        ]
      },
      {
        "model": "STORM",
        "variants": [
          "ZX"
        ]
      }
    ]
  },
  {
    "make": "CAGIVA",
    "models": [
      {
        "model": "MITO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XTRA RAPTOR",
        "variants": [
          "1000"
        ]
      }
    ]
  },
  {
    "make": "CEEON INDIA",
    "models": [
      {
        "model": "KVR",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "TRUENO",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "MARENO PLUS",
        "variants": [
          "LA 72V 30AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "CEOL KING",
    "models": [
      {
        "model": "CUTE",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ALEXA",
        "variants": [
          "LITHIUM 48V 18AH"
        ]
      },
      {
        "model": "MASE",
        "variants": [
          "LITHIUM 48V 18AH"
        ]
      }
    ]
  },
  {
    "make": "CF MOTO",
    "models": [
      {
        "model": "300NK",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "650GT",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "650MT",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "650NK",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "CF 650",
        "variants": [
          "NK"
        ]
      }
    ]
  },
  {
    "make": "CHETAK TECHNOLOGY LIMITED",
    "models": [
      {
        "model": "CHETAK",
        "variants": [
          "2423 PREMIUM"
        ]
      }
    ]
  },
  {
    "make": "CHITRAKSH",
    "models": [
      {
        "model": "FREEDOM",
        "variants": [
          "CLASSIC LITHIUM 60V",
          "ORIENT LITHIUM 60V",
          "RETRO LITHIUM 60V"
        ]
      },
      {
        "model": "ORBITER",
        "variants": [
          "LITHIUM 48V"
        ]
      }
    ]
  },
  {
    "make": "CITADEL ENTERPRISES",
    "models": [
      {
        "model": "SIYARA GT",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SIYARA PRO",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SIYARA POWER",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "CLASSIC LEGENDS PRIVATE LIMITED",
    "models": [
      {
        "model": "JAWA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "JAWA XL 350",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "CLASSIC LEGENDS PVT LTD",
    "models": [
      {
        "model": "JAWA",
        "variants": [
          "DUAL CHANNEL ABS BS VI",
          "KW L2 BS4"
        ]
      },
      {
        "model": "YEZDI",
        "variants": [
          "ROADSTER STEEL BLUE"
        ]
      }
    ]
  },
  {
    "make": "CLEVELAND",
    "models": [
      {
        "model": "CYCLEWERKS",
        "variants": [
          "ACE",
          "MISFIT"
        ]
      },
      {
        "model": "CYCLEWERKS ACE",
        "variants": [
          "DELUXE"
        ]
      },
      {
        "model": "CYCLEWERKS MISFIT",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "CLEVELAND CYCLEWERKS",
    "models": [
      {
        "model": "ACE DELUXE",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "CLEVLAND",
    "models": [
      {
        "model": "MISFIT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACE",
        "variants": [
          "DELUXE"
        ]
      }
    ]
  },
  {
    "make": "CONFEDERATE MOTORS",
    "models": [
      {
        "model": "X132",
        "variants": [
          "HELLCAT"
        ]
      }
    ]
  },
  {
    "make": "COSBIKE",
    "models": [
      {
        "model": "F PRIME",
        "variants": [
          "60V 30AH",
          "DOUBLE BATTERY 60V 48AH"
        ]
      },
      {
        "model": "DAZE",
        "variants": [
          "60V 24AH",
          "60V 30AH"
        ]
      },
      {
        "model": "F PRO",
        "variants": [
          "60V 30AH",
          "DOUBLE BATTERY 60V 48AH"
        ]
      },
      {
        "model": "F PRO PLUS",
        "variants": [
          "60V 30AH"
        ]
      },
      {
        "model": "WINNER",
        "variants": [
          "60V 40AH",
          "LITHIUM BATTERY 60V 40AH"
        ]
      },
      {
        "model": "FUSION",
        "variants": [
          "LITHIUM 60V",
          "LITHIUM 60V 24AH",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "CHALO 1000V2",
        "variants": [
          "LITHIUM 60V 40AH"
        ]
      },
      {
        "model": "V ELECTRA",
        "variants": [
          "60 V 24AH LITHIUM BATTERY",
          "48 V 24AH LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "COSBIKE PVT LTD",
    "models": [
      {
        "model": "E1 PRO",
        "variants": [
          "60V 24 AH",
          "60V 30 AH",
          "STD",
          "STD LX"
        ]
      },
      {
        "model": "F PRO PLUS",
        "variants": [
          "60V 24 AH",
          "60V 30 AH"
        ]
      },
      {
        "model": "F PRIME",
        "variants": [
          "60V 24 AH",
          "60V 30 AH"
        ]
      },
      {
        "model": "WINNER",
        "variants": [
          "60V 40 AH"
        ]
      },
      {
        "model": "FUSION F PRO",
        "variants": [
          "60V/24AH",
          "60V/30AH",
          "60V/24AH X 2"
        ]
      },
      {
        "model": "FUSION DX",
        "variants": [
          "60V/24AH",
          "60V/30AH"
        ]
      },
      {
        "model": "ELECTRA",
        "variants": [
          "STD",
          "STD LX",
          "ER",
          "ER LX"
        ]
      },
      {
        "model": "FUSION",
        "variants": [
          "STD",
          "STD LX",
          "ER",
          "ER LX"
        ]
      },
      {
        "model": "FPRO",
        "variants": [
          "STD",
          "STD LX"
        ]
      }
    ]
  },
  {
    "make": "CRAYON",
    "models": [
      {
        "model": "ZEEZ",
        "variants": [
          "VRLA",
          "LITHIUM-ION"
        ]
      },
      {
        "model": "ENVY",
        "variants": [
          "VRLA",
          "LITHIUM-ION"
        ]
      }
    ]
  },
  {
    "make": "CRAYON MOTORS",
    "models": [
      {
        "model": "ENVY",
        "variants": [
          "VRLA",
          "LITHIUM",
          "60 VOLT 27 AH",
          "48 VOLT LITHIUM 24 AH",
          "48 VOLT 27 AH",
          "60 VOLT LITHIUM 35 AH",
          "LA 60V 28AH 250 WATT",
          "LITHIUM BATTERY 60V 24AH",
          "LI 60V 26AH 250 WATT"
        ]
      },
      {
        "model": "ZUMBA",
        "variants": [
          "VRLA",
          "LITHIUM",
          "48 VOLT 27 AH",
          "60 VOLT 27 AH",
          "48 VOLT LITHIUM 24 AH"
        ]
      },
      {
        "model": "SNOW PLUS",
        "variants": [
          "VRLA",
          "LITHIUM"
        ]
      },
      {
        "model": "ZEEZ PLUS",
        "variants": [
          "VRLA",
          "LITHIUM",
          "48 VOLT 27 AH",
          "60 VOLT 27 AH",
          "48 VOLT LITHIUM 24 AH",
          "LA 48V 28AH 250WATT",
          "LI 48V 24AH 250WATT DUAL BATTERY"
        ]
      },
      {
        "model": "ZEEZ",
        "variants": [
          "LITHIUM-ION",
          "VRLA"
        ]
      },
      {
        "model": "RINGO",
        "variants": [
          "60 VOLT 27 AH"
        ]
      },
      {
        "model": "SNOW",
        "variants": [
          "48 VOLT 27 AH",
          "60 VOLT 27 AH",
          "48 VOLT LITHIUM 24 AH"
        ]
      },
      {
        "model": "TING",
        "variants": [
          "48 VOLT 27 AH",
          "48 VOLT LITHIUM 20 AH"
        ]
      },
      {
        "model": "ROYAL CLASSIC",
        "variants": [
          "48 VOLT 24 AH LITHIUM",
          "60 VOLT 27 AH LEAD ACID"
        ]
      }
    ]
  },
  {
    "make": "CRAYON MOTORS EV PVT LTD",
    "models": [
      {
        "model": "ENVY",
        "variants": [
          "48 VOLTS 24 AH LEAD ACID",
          "48 VOLTS 24 AH LITHIUM ION",
          "60 VOLTS 24 AH LEAD ACID",
          "60 VOLTS 24 AH LITHIUM ION"
        ]
      },
      {
        "model": "ZEEZ",
        "variants": [
          "48 VOLT 24 AH",
          "60 VOLT 24 AH",
          "48 VOLT 24 AH LITHIUM",
          "60 VOLT 24 AH LITHIUM"
        ]
      },
      {
        "model": "ZEEZ PLUS",
        "variants": [
          "48 VOLT 24 AH",
          "60 VOLT 24 AH",
          "48 VOLT 24 AH LITHIUM",
          "60 VOLT 24 AH LITHIUM",
          "48V 25AH LITHIUM",
          "48V 25AH LITHIUM DUAL BATTERY"
        ]
      },
      {
        "model": "SNOW PLUS",
        "variants": [
          "60 V LEAD ACID",
          "60 V LITHIUM"
        ]
      },
      {
        "model": "ZUMBA",
        "variants": [
          "48 V LEAD ACID",
          "48 V LITHIUM"
        ]
      }
    ]
  },
  {
    "make": "CRAZY",
    "models": [
      {
        "model": "PRISM",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "CREATIVE IMPEX",
    "models": [
      {
        "model": "CRAZY PRO",
        "variants": [
          "LI 60V 31AH 1.4KW",
          "LI 60V 25AH 1.4KW",
          "LEAD ACID BATTERY 72V 28 AH",
          "LEAD ACID BATTERY 72V 20AH"
        ]
      },
      {
        "model": "CRAZY",
        "variants": [
          "LEAD ACID BATTERY 60V 41AH",
          "LI 60V 30AH 1KW",
          "LI 60V 25AH 1KW",
          "LEAD ACID BATTERY 60V 20AH",
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "CRAZY PLUS",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH",
          "LEAD ACID BATTERY 60V 20AH"
        ]
      }
    ]
  },
  {
    "make": "CREDENCE",
    "models": [
      {
        "model": "EUROPA EV 1.0",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "EUROPA",
        "variants": [
          "EV 1.0 LEAD ACID",
          "EV 1.0 LITHIUM"
        ]
      },
      {
        "model": "EMPIRE PLUS",
        "variants": [
          "ELECTRIC SCOOTER",
          "BMC ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "CYBORG",
    "models": [
      {
        "model": "YODA",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "BOB-E",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "CYNOSURE ENTERPRISES",
    "models": [
      {
        "model": "YASH",
        "variants": [
          "WHIZ",
          "DOCILE",
          "ACUTE 600",
          "PRETTY"
        ]
      }
    ]
  },
  {
    "make": "CYNOSURE ENTERPRISES LTD",
    "models": [
      {
        "model": "YASH",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "D D S ELECTRICAL INDIA",
    "models": [
      {
        "model": "THUNDER",
        "variants": [
          "LEAD ACID BATTERY 60V",
          "LEAD ACID BATTERY 72V",
          "LITHIUM BATTERY 60V",
          "LITHIUM BATTERY 48V"
        ]
      },
      {
        "model": "E TORQ",
        "variants": [
          "LITHIUM BATTERY 48V",
          "LITHIUM BATTERY 60V",
          "LEAD ACID BATTERY 60V"
        ]
      },
      {
        "model": "CLASSIC",
        "variants": [
          "LEAD ACID BATTERY 60V",
          "LITHIUM BATTERY 48V",
          "LITHIUM BATTERY 60V"
        ]
      },
      {
        "model": "EV JETS",
        "variants": [
          "ATHARV  LITHIUM BATTERY 60V",
          "ATHARV  LITHIUM BATTERY 48V",
          "ATHARV LEAD ACID BATTERY 60V",
          "MASSIVE  LITHIUM BATTERY 60V",
          "MASSIVE R LEAD ACID BATTERY 60V",
          "MASSIVE LEAD ACID BATTERY 60V",
          "MASSIVE R LITHIUM BATTERY 60V",
          "MASSIVE R LITHIUM BATTERY 48V",
          "MASSIVE  LITHIUM BATTERY 48V"
        ]
      }
    ]
  },
  {
    "make": "DACUS",
    "models": [
      {
        "model": "GOLD",
        "variants": [
          "LITHIUM ION 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "ELITE",
        "variants": [
          "LITHIUM ION 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "AUSTRA",
        "variants": [
          "LITHIUM ION 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "PRIME",
        "variants": [
          "LITHIUM ION 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "DAILYGOO",
    "models": [
      {
        "model": "JABARI",
        "variants": [
          "LITHIUM ION 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "DAO",
    "models": [
      {
        "model": "VIDYUT",
        "variants": [
          "108",
          "108"
        ]
      },
      {
        "model": "ZOR",
        "variants": [
          "405",
          "104 LITHIUM ION 60V",
          "405"
        ]
      },
      {
        "model": "MODEL",
        "variants": [
          "703",
          "703"
        ]
      }
    ]
  },
  {
    "make": "DAO EVTECH PRIVATE LIMITED",
    "models": [
      {
        "model": "DAO 703",
        "variants": [
          "LIFEPO4 72V 30AH 2.5KW"
        ]
      }
    ]
  },
  {
    "make": "DEE KAY PRODUCTS",
    "models": [
      {
        "model": "FERRANZA BOLD",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH",
          "LEAD ACID BATTERY 60V 30AH"
        ]
      },
      {
        "model": "FERRANZA WINDY",
        "variants": [
          "LITHIUM ION BATTERY 60V 30AH",
          "LITHIUM ION BATTERY 48V 30AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LEAD ACID BATTERY 48V 28AH"
        ]
      },
      {
        "model": "FERRANZA ACTIVE",
        "variants": [
          "LITHIUM ION BATTERY 60V 30AH",
          "LEAD ACID BATTERY 48V 28AH",
          "LEAD ACID BATTERY 60V 30AH",
          "LITHIUM ION BATTERY 48V 28AH"
        ]
      },
      {
        "model": "FERRANZA V PRO",
        "variants": [
          "LEAD ACID BATTERY 48V 28AH",
          "LITHIUM ION BATTERY 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "DEEDAV",
    "models": [
      {
        "model": "TOYA",
        "variants": [
          "LA 60V 28AH 250WATT",
          "LI 48V 26AH 250WATT",
          "LI 60V 34AH 250WATT",
          "LI 60V 26AH 250WATT"
        ]
      },
      {
        "model": "MIMI",
        "variants": [
          "LI 60V 34AH 250WATT",
          "LI 48V 26AH 250WATT",
          "LI 60V 26AH 250WATT",
          "LA 60V 28AH 250WATT"
        ]
      },
      {
        "model": "DRISH",
        "variants": [
          "LI 60V 26AH 250WATT",
          "LA 72V 28AH 250WATT",
          "LA 60V 28AH 250WATT",
          "LI 60V 34AH 250WATT"
        ]
      },
      {
        "model": "TOPER",
        "variants": [
          "LA 60V 28AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "DEJAVU EV",
    "models": [
      {
        "model": "MAX",
        "variants": [
          "48V 28AH",
          "60V 28AH"
        ]
      },
      {
        "model": "CABANA",
        "variants": [
          "48V 28AH",
          "60V 28AH"
        ]
      },
      {
        "model": "STAR",
        "variants": [
          "48V 28AH",
          "60V 28AH"
        ]
      },
      {
        "model": "THYJ",
        "variants": [
          "48V 28AH",
          "60V 28AH"
        ]
      },
      {
        "model": "APRO",
        "variants": [
          "LITHIUM 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "DELEVERY ELECTRIC",
    "models": [
      {
        "model": "LX 200",
        "variants": [
          "60 V 24 AH",
          "60 V 32 AH",
          "60 V 45 AH"
        ]
      }
    ]
  },
  {
    "make": "DELITE",
    "models": [
      {
        "model": "VICTORY",
        "variants": [
          "60V 27AH LEAD ACID",
          "60V 24AH LITHUM ION",
          "48V-28AH",
          "60V-28AH"
        ]
      },
      {
        "model": "SIMU",
        "variants": [
          "60V 27AH LITHUM ION"
        ]
      },
      {
        "model": "GRACE",
        "variants": [
          "60V 27AH LEAD ACID",
          "60V 24AH LITHUM ION",
          "48V-28AH",
          "60V-28AH"
        ]
      },
      {
        "model": "ROZI",
        "variants": [
          "48V-28AH",
          "60V-28AH"
        ]
      }
    ]
  },
  {
    "make": "DELTA SAKURA",
    "models": [
      {
        "model": "SONCHO",
        "variants": [
          "E SCOOTER",
          "E SCOOTER",
          "E SCOOTER"
        ]
      },
      {
        "model": "SUGOI",
        "variants": [
          "E SCOOTER",
          "E SCOOTER",
          "E SCOOTER"
        ]
      },
      {
        "model": "BIJIN",
        "variants": [
          "E SCOOTER",
          "E SCOOTER",
          "E SCOOTER"
        ]
      },
      {
        "model": "SONGEN",
        "variants": [
          "E SCOOTER",
          "E SCOOTER",
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "DELTIC",
    "models": [
      {
        "model": "DRIXX",
        "variants": [
          "E SCOOTY",
          "60V 26AH LI",
          "60V 34AH LEAD",
          "60V 29AH LEAD",
          "60V 28AH LEAD",
          "60V 26AH LEAD",
          "60V 34AH LITHIUM",
          "60V 26AH LITHIUM",
          "60V 34AH LEAD ACID",
          "LITHIUM BATTERY 60V 26AH",
          "LITHIUM BATTERY 60V 34AH",
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "COSTA",
        "variants": [
          "48V 26AH LI",
          "48V 28AH LEAD",
          "48V 26AH LITHIUM",
          "48V 28AH LEAD ACID",
          "LITHIUM BATTERY 60V 28AH",
          "LITHIUM BATTERY 60V 26AH",
          "LITHIUM BATTERY 48V 28AH"
        ]
      },
      {
        "model": "LEGION",
        "variants": [
          "E SCOOTY",
          "LFP",
          "E SCOOTER",
          "LEAD ACID BATTERY 60V 28AH",
          "LITHIUM BATTERY 60V 34AH",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "ZGS",
        "variants": [
          "LEAD ACID",
          "LITHIUM ION",
          "72V E-SCOOTER LEAD ACID",
          "72V E-SCOOTER",
          "STANDARD LI 72V 30AH 1.2KW"
        ]
      },
      {
        "model": "REGAL",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "TRENTO",
        "variants": [
          "LITHIUM ION 72V 40AH",
          "STANDARD",
          "STANDARD LI 72V 36AH 1.8KW"
        ]
      },
      {
        "model": "EZ",
        "variants": [
          "STANDARD",
          "E SCOOTER"
        ]
      },
      {
        "model": "DELTIC COSTA",
        "variants": [
          "E SCOOTER",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "DASH",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "DELTIC AUTOCORP LLP",
    "models": [
      {
        "model": "DRIXX",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "EZ",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "COSTA",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "DELTIK",
    "models": [
      {
        "model": "COSTA",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "DRIXX",
        "variants": [
          "SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "DEMAK",
    "models": [
      {
        "model": "DMX R",
        "variants": [
          "150"
        ]
      },
      {
        "model": "DEMAK PIRANHA",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "DEMAK DUTA",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "DEROZ",
    "models": [
      {
        "model": "VESPA",
        "variants": [
          "60V/30AH"
        ]
      },
      {
        "model": "BRAVO 6G",
        "variants": [
          "60V/30AH"
        ]
      },
      {
        "model": "SWIFT D 92",
        "variants": [
          "60V/30AH"
        ]
      },
      {
        "model": "FLY",
        "variants": [
          "60V/30AH"
        ]
      },
      {
        "model": "SNIPER",
        "variants": [
          "60V E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "DETEL",
    "models": [
      {
        "model": "EASY PLUS",
        "variants": [
          "LITHIUM 48V 20AH",
          "48V LITHIUM",
          "LITHIUM ION 48V 20AH"
        ]
      },
      {
        "model": "VEERU",
        "variants": [
          "LITHIUM 48V 40AH",
          "LITHIUM 48V 24AH",
          "STANDARD-NMC 48V 40AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "DETEL EV",
    "models": [
      {
        "model": "VEERU",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "DEXPRESS",
    "models": [
      {
        "model": "DEXPRESS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ELITE",
        "variants": [
          "CARGO 260V 30AH",
          "CARGO 60V 30AH",
          "60V 24AH",
          "60V 30AH",
          "60V 36AH",
          "60V 42AH",
          "CARGO 30 AH",
          "CARGO 36 AH",
          "CARGO 24 AH",
          "CARGO 40 AH"
        ]
      },
      {
        "model": "METTLE",
        "variants": [
          "CARGO 60V 30AH",
          "60V 24AH",
          "60V 30AH",
          "SEAT 24 AH",
          "CARGO 18 AH",
          "CARGO 24 AH",
          "SEAT 30 AH",
          "SEAT 18 AH",
          "CARGO 30 AH"
        ]
      },
      {
        "model": "U2",
        "variants": [
          "48V 18AH",
          "48V 24AH",
          "SEAT 24 AH",
          "SEAT 18 AH",
          "CARGO 24 AH",
          "CARGO 18 AH"
        ]
      },
      {
        "model": "LITE",
        "variants": [
          "E BIKE",
          "CARGO 36 AH",
          "CARGO 18 AH",
          "CARGO 24 AH",
          "CARGO 30 AH"
        ]
      },
      {
        "model": "DELITE",
        "variants": [
          "CARGO 24 AH",
          "CARGO 30 AH"
        ]
      }
    ]
  },
  {
    "make": "DHARUKIYAS ELECTRIC VEHICLE",
    "models": [
      {
        "model": "SPORT 3D",
        "variants": [
          "60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "DIAL E VAHAN",
    "models": [
      {
        "model": "DIAL MOON STAR",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "CHETAN",
        "variants": [
          "LEAD ACID",
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "100 EV",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "DIAL 100",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "DIAL E VAHAN PVT. LTD",
    "models": [
      {
        "model": "DIAL 100",
        "variants": [
          "CHETAN",
          "48V"
        ]
      }
    ]
  },
  {
    "make": "DILLI ELECTRIC AUTO",
    "models": [
      {
        "model": "CITY LIFE E SMART",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "DISHMISH AUTOMITIVE",
    "models": [
      {
        "model": "CRAZY",
        "variants": [
          "60V20AH",
          "60V28AH",
          "60V41AH"
        ]
      },
      {
        "model": "CEAZY PLUSH",
        "variants": [
          "60V20AH",
          "60V28AH"
        ]
      },
      {
        "model": "CRAZY PRO",
        "variants": [
          "72V20AH",
          "72V28AH"
        ]
      },
      {
        "model": "CRAZY PRO H TOP",
        "variants": [
          "72V20AH",
          "72V28AH"
        ]
      }
    ]
  },
  {
    "make": "DISHMISH AUTOMOTIVE",
    "models": [
      {
        "model": "CRAZY",
        "variants": [
          "60W 20AH LEAD",
          "60W 28AH LEAD"
        ]
      },
      {
        "model": "CRAZY PLUS",
        "variants": [
          "60W 20AH LEAD",
          "60W 28AH LEAD"
        ]
      },
      {
        "model": "CRAZY PRO",
        "variants": [
          "60W 20AH LEAD",
          "72W 20AH LEAD",
          "72W 28AH LEAD"
        ]
      }
    ]
  },
  {
    "make": "DIWA E VEHICLE",
    "models": [
      {
        "model": "RAPIDO",
        "variants": [
          "E SCOOTY",
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "DIWA E VEHICLES",
    "models": [
      {
        "model": "VGORE",
        "variants": [
          "LEAD ACID 28AH 60V",
          "LITHIUM BATTERY 24AH 60V"
        ]
      },
      {
        "model": "RAPIDO",
        "variants": [
          "LEAD ACID 28AH 48V",
          "LEAD ACID 28AH 60V",
          "E SCOOTER LEAD ACID 60 V 28AH AND LITHIUM NMC 27AH"
        ]
      }
    ]
  },
  {
    "make": "DLITE",
    "models": [
      {
        "model": "ROZI",
        "variants": [
          "48V 28AH",
          "60V 28AH"
        ]
      },
      {
        "model": "MERAKI RX100",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "NEO",
        "variants": [
          "PRO LITHIUM 60V 28AH",
          "PRO LITHIUM 72V 32AH"
        ]
      }
    ]
  },
  {
    "make": "DM ELECTRIC",
    "models": [
      {
        "model": "ROADSTAR",
        "variants": [
          "LITHIUM 48V 24AH",
          "60V-24AH"
        ]
      },
      {
        "model": "SPEEDKING",
        "variants": [
          "LITHIUM 48V 24AH",
          "60V-24AH"
        ]
      },
      {
        "model": "GALAXY",
        "variants": [
          "LITHIUM 48V 24AH",
          "60V-24AH",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "DISCOVERY",
        "variants": [
          "LITHIUM 48V 24AH",
          "60V-24AH",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "PANTHER PLUS",
        "variants": [
          "LIHTIUM ION"
        ]
      },
      {
        "model": "SPEED KING",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "ROAD STAR",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "DMW",
    "models": [
      {
        "model": "EARTH",
        "variants": [
          "E BIKE",
          "PLUS E BIKE",
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "DSK",
    "models": [
      {
        "model": "AQUILA PRO",
        "variants": [
          "LAVA RED",
          "SHADOW BLACK"
        ]
      }
    ]
  },
  {
    "make": "DSK BENELLI",
    "models": [
      {
        "model": "TNT",
        "variants": [
          "600 I STD",
          "600 I ABS",
          "135 STANDARD"
        ]
      },
      {
        "model": "302 R",
        "variants": [
          "STD (300 CC)"
        ]
      },
      {
        "model": "TNT 1130",
        "variants": [
          "R"
        ]
      },
      {
        "model": "320 R",
        "variants": [
          "STD (300 CC)"
        ]
      },
      {
        "model": "TNT 300",
        "variants": [
          "300"
        ]
      },
      {
        "model": "TNT 600",
        "variants": [
          "600 I",
          "600 GT"
        ]
      },
      {
        "model": "TNT 899",
        "variants": [
          "899"
        ]
      },
      {
        "model": "TNT R",
        "variants": [
          "R"
        ]
      },
      {
        "model": "TNT 25",
        "variants": [
          "25"
        ]
      },
      {
        "model": "TORNADO",
        "variants": [
          "302 ABS"
        ]
      },
      {
        "model": "TRK",
        "variants": [
          "502 ABS"
        ]
      }
    ]
  },
  {
    "make": "DSK MOTOWHEELS",
    "models": [
      {
        "model": "GV 250",
        "variants": [
          "AQUILA"
        ]
      }
    ]
  },
  {
    "make": "DUCATI",
    "models": [
      {
        "model": "848",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "899",
        "variants": [
          "PANIGALE"
        ]
      },
      {
        "model": "1299",
        "variants": [
          "PANIGALE",
          "PANIGALE R FINAL EDITION",
          "SUPERLEGGERA"
        ]
      },
      {
        "model": "MONSTER 795",
        "variants": [
          "STANDARD",
          "ABS"
        ]
      },
      {
        "model": "DIAVEL",
        "variants": [
          "STANDARD",
          "DIESEL",
          "STD",
          "STD",
          "BLACK",
          "SPORTS",
          "XDIAVEL",
          "XDIAVEL S",
          "CARBON",
          "DARK",
          "BLACK CARBON",
          "RED CARBON",
          "CARBON",
          "CARBON STAR WHITE",
          "RED BLACK",
          "TITANIUM"
        ]
      },
      {
        "model": "PANIGALE V4 S",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SUPERSPORT",
        "variants": [
          "STANDARD",
          "S",
          "STD"
        ]
      },
      {
        "model": "1198 S",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "1199 PANIGALE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "1299 PANIGALE",
        "variants": [
          "STANDARD",
          "S"
        ]
      },
      {
        "model": "1299 SUPERLEGGERA",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "848 EVO",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "899 PANIGALE",
        "variants": [
          "RED",
          "WHITE"
        ]
      },
      {
        "model": "HYPERMOTARD 821",
        "variants": [
          "1100",
          "1100 S",
          "SP",
          "STANDARD"
        ]
      },
      {
        "model": "HYPERMOTARD 939",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "HYPERMOTARD SP 821",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "HYPERSTRADA 939",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MONSTER 1100 EVO",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MONSTER 1200",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MONSTER 1200 R",
        "variants": [
          "RED",
          "BLACK"
        ]
      },
      {
        "model": "MONSTER 1200 S",
        "variants": [
          "RED",
          "GREY",
          "STANDARD"
        ]
      },
      {
        "model": "MONSTER 1200 S STRIPE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MONSTER 20TH ANNIVERSARY",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MONSTER 796",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "MONSTER 797",
        "variants": [
          "STANDARD",
          "PLUS"
        ]
      },
      {
        "model": "MONSTER 821 DARK",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MULTISTRADA 1200",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MULTISTRADA 1200 ENDURO",
        "variants": [
          "RED",
          "GREY"
        ]
      },
      {
        "model": "MULTISTRADA 1200 S",
        "variants": [
          "STANDARD",
          "SPORT",
          "TOURING"
        ]
      },
      {
        "model": "MULTISTRADA 950",
        "variants": [
          "STANDARD",
          "SPOKE WHEELS",
          "S"
        ]
      },
      {
        "model": "PANIGALE R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "PANIGALE V4",
        "variants": [
          "STANDARD",
          "SP2"
        ]
      },
      {
        "model": "SCRAMBLER CLASSIC",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SCRAMBLER DESERT SLED",
        "variants": [
          "BLACK",
          "WHITE",
          "RED DUSK",
          "STANDARD"
        ]
      },
      {
        "model": "SCRAMBLER ICON",
        "variants": [
          "SILVER",
          "STANDARD",
          "DARK",
          "NIGHT SHIFT",
          "RED",
          "YELLOW"
        ]
      },
      {
        "model": "SCRAMBLER MACH 2.0",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SCRAMBLER URBAN ENDURO",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "STREETFIGHTER",
        "variants": [
          "S",
          "STREETFIGHTER S",
          "STD",
          "848"
        ]
      },
      {
        "model": "XDIAVEL",
        "variants": [
          "STANDARD",
          "S"
        ]
      },
      {
        "model": "MONSTER",
        "variants": [
          "S2R",
          "STD",
          "1100 ABS",
          "696",
          "S 1200",
          "S 1200 STRIPE",
          "1100 S",
          "SP",
          "1200 R",
          "797",
          "795",
          "1100",
          "S4RS",
          "797",
          "821",
          "1200 S",
          "1200 S STRIPE",
          "1200",
          "796",
          "821 DARK",
          "821 STANDARD"
        ]
      },
      {
        "model": "MULTISTRADA 1260",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SCRAMBLER 1100",
        "variants": [
          "STANDARD",
          "PRO",
          "PRO SPORT",
          "DARK PRO",
          "TRIBUTE PRO"
        ]
      },
      {
        "model": "HYPER MOTARD",
        "variants": [
          "HM 796 MATT"
        ]
      },
      {
        "model": "959 PANIGALE",
        "variants": [
          "CORSE",
          "STANDARD"
        ]
      },
      {
        "model": "PANIGALE V4 R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SCRAMBLER FULL THROTTLE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SCRAMBLER CAFE RACER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "HYPERMOTARD 950",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MULTISTRADA 1260 ENDURO",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "DIAVEL 1260",
        "variants": [
          "STANDARD",
          "S"
        ]
      },
      {
        "model": "PANIGALE V2",
        "variants": [
          "STANDARD",
          "BAYLISS"
        ]
      },
      {
        "model": "SCRAMBLER NIGHTSHIFT",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MULTISTRADA V2",
        "variants": [
          "STANDARD",
          "S",
          "S STREET"
        ]
      },
      {
        "model": "STREETFIGHTER V2",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "DIAVEL V4",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SCRAMBLER",
        "variants": [
          "CAFE RACER 803",
          "VERSIONS STD",
          "1100",
          "1100 SPECIAL",
          "1100 SPORT",
          "SIXTY2",
          "ICON",
          "DESERT SLED FASTHOUSE",
          "DESERT SLED",
          "ITALIA INDEPENDENT",
          "CAFE RACER",
          "VERSIONS",
          "FLAT TRACK PRO",
          "DIAVEL1260S",
          "CAFÃ‰ RACER",
          "FULL THROTTLE",
          "800 ICON",
          "CLASSIC",
          "URBAN ENDURO"
        ]
      },
      {
        "model": "848 SUPER BIKE",
        "variants": [
          "EVO"
        ]
      },
      {
        "model": "STREET FIGHTER",
        "variants": [
          "STD",
          "STD",
          "STREET FIGHTER",
          "848",
          "STREET FIGHTER S"
        ]
      },
      {
        "model": "HYPER MOTARD 796",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "HYPER MOTARD 1100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MONSTER 1100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SBK 848",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SBK 1198",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MULTISTRADA",
        "variants": [
          "STD",
          "1200 PIKES PEAK",
          "1200 S SPORT",
          "STD",
          "1200 S GRANTURISMO",
          "1260 S",
          "1260 PIKES PEAK",
          "950",
          "1200",
          "1200 S TOURIN",
          "MTS 1200",
          "950 STD",
          "V4",
          "MTS 1200 ABS",
          "MTS 1200 S",
          "MTS 1200 T",
          "939",
          "1200 S DJAIR",
          "1200 ENDURO",
          "1200 PIKES PEAK",
          "1200 S"
        ]
      },
      {
        "model": "HYPERMOTARD",
        "variants": [
          "796",
          "939",
          "HM 796",
          "1100 EVO",
          "1100 EVO SP",
          "950 RVE",
          "950 SP",
          "1100",
          "1100 S",
          "HM 796",
          "HM 1100 EVO",
          "950",
          "HM 1100 EVO SP",
          "939",
          "939 SP",
          "HYPERSTRADA 939",
          "939 SP",
          "STANDARD"
        ]
      },
      {
        "model": "PANIGALE",
        "variants": [
          "V4",
          "899 RED",
          "959",
          "1299 SUPERLEGGERA"
        ]
      },
      {
        "model": "SUPERBIKE",
        "variants": [
          "1299 PANIGALE",
          "848",
          "848 EVO",
          "899 PANIGALE",
          "PANIGALE R",
          "1198 S",
          "1198 R",
          "1198 R CORSE",
          "1198 SP",
          "959 PANIGALE",
          "1198",
          "848",
          "1098R",
          "1299 PANIGALE S",
          "1198 R BAYLISS LE",
          "PANIGALE V4S",
          "PANIGALE 899",
          "PANIGALE 1299 S",
          "PANIGALE 1299",
          "PANIGALE 959",
          "1098",
          "1098 R TB",
          "1098 S",
          "PANIGALE V4"
        ]
      },
      {
        "model": "HYPERSTRADA 821",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MULTISTRADA V4",
        "variants": [
          "S"
        ]
      },
      {
        "model": "HYPERSTRADA",
        "variants": [
          "939",
          "821",
          "939",
          "STD"
        ]
      },
      {
        "model": "P-900",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SPORT CLASSIC",
        "variants": [
          "GT 1000",
          "SPORT 1000 BIPOSTO"
        ]
      },
      {
        "model": "SUPER SPORT",
        "variants": [
          "S 937",
          "939",
          "950"
        ]
      },
      {
        "model": "DESERT",
        "variants": [
          "X"
        ]
      }
    ]
  },
  {
    "make": "DURGA FILTERS",
    "models": [
      {
        "model": "FUTURE 2020",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "EASY GO",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "CUTIE",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "FIDATO 21",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "EASY GO PLUS",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "DYNAM EV",
    "models": [
      {
        "model": "WINGS",
        "variants": [
          "72V"
        ]
      },
      {
        "model": "FLASH",
        "variants": [
          "72V"
        ]
      },
      {
        "model": "TURBO",
        "variants": [
          "72V"
        ]
      },
      {
        "model": "GAZE",
        "variants": [
          "62V"
        ]
      },
      {
        "model": "DELTA",
        "variants": [
          "70V"
        ]
      }
    ]
  },
  {
    "make": "DYNAMO",
    "models": [
      {
        "model": "RX1",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RX2",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RX3",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "X2",
        "variants": [
          "ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "DYNAMO INDIA",
    "models": [
      {
        "model": "RX1",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "RX2",
        "variants": [
          "LITHIUM ION 48V 30AH"
        ]
      },
      {
        "model": "RX3",
        "variants": [
          "LITHIUM ION 60V 24AH"
        ]
      },
      {
        "model": "RX4",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "LX",
        "variants": [
          "LOADER LITHIUM ION"
        ]
      },
      {
        "model": "X4",
        "variants": [
          "LITHIUM ION 60V 42AH"
        ]
      }
    ]
  },
  {
    "make": "DYS IMPEX",
    "models": [
      {
        "model": "BASANTEE",
        "variants": [
          "WOW",
          "MAYA",
          "AMPHARO"
        ]
      }
    ]
  },
  {
    "make": "E ASHWA AUTOMOTIVE",
    "models": [
      {
        "model": "REGAL",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "DANGUS PRO",
        "variants": [
          "LITHIUM 60V 28AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM BATTERY 60V 25AH",
          "LITHIUM BATTERY 60V 24AH",
          "LITHIUM BATTERY 48V 25AH",
          "LITHIUM BATTERY 48V 24AH",
          "LITHIUM BATTERY 60V 31AH",
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 60V 34AH"
        ]
      },
      {
        "model": "DELITE ROZI",
        "variants": [
          "LITHIUM 60V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "REGAL ZX",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "DELITE GRACE",
        "variants": [
          "LITHIUM 60V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "BLOSSOM",
        "variants": [
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 25AH"
        ]
      },
      {
        "model": "VELOX PRO",
        "variants": [
          "LITHIUM BATTERY 60V 28AH"
        ]
      },
      {
        "model": "GLIDE",
        "variants": [
          "LITHIUM BATTERY 60V 27AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 60V 28AH",
          "LITHIUM BATTERY 60V 34AH"
        ]
      },
      {
        "model": "SIMU",
        "variants": [
          "LITHIUM BATTERY 60V 28 AH"
        ]
      },
      {
        "model": "VELOX PLUS",
        "variants": [
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 60V 28AH"
        ]
      },
      {
        "model": "VELOX",
        "variants": [
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 60V 28AH"
        ]
      },
      {
        "model": "DANGUS PLUS",
        "variants": [
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 60V 28AH",
          "LITHIUM 60V 31AH"
        ]
      },
      {
        "model": "DANGUS",
        "variants": [
          "LITHIUM BATTERY 60V 25AH",
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "RAAKIT 100",
        "variants": [
          "LITHIUM BATTERY 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "E ASHWA AUTOMOTIVE PVT LTD",
    "models": [
      {
        "model": "DANGUS PLUS",
        "variants": [
          "X1",
          "60V 34AH"
        ]
      },
      {
        "model": "SIMU",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "BLOSSOM",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "DANGUS PRO",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY",
          "60V 24AH",
          "LEAD 48V 24AH"
        ]
      },
      {
        "model": "VELOX PRO",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GLIDE",
        "variants": [
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "DANGUS",
        "variants": [
          "60V 30AH",
          "60V 28AH"
        ]
      },
      {
        "model": "CUTIE",
        "variants": [
          "60V 25AH"
        ]
      },
      {
        "model": "VELOX",
        "variants": [
          "60V 28AH"
        ]
      },
      {
        "model": "VELOX PLUS",
        "variants": [
          "60V 30AH"
        ]
      },
      {
        "model": "ROZI",
        "variants": [
          "60V 24AH"
        ]
      },
      {
        "model": "MAX",
        "variants": [
          "60V 24AH"
        ]
      },
      {
        "model": "RAAKIT 100",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "NEBO",
        "variants": [
          "LITHIUM"
        ]
      }
    ]
  },
  {
    "make": "E BIKE",
    "models": [
      {
        "model": "ATRIA LX LI",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "E BIKE BAZAAR",
    "models": [
      {
        "model": "IMAGE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "PRIDE",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "E BIKE GO",
    "models": [
      {
        "model": "RUGGED G1 PLUS",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "RUGGED G1",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "E BIKES",
    "models": [
      {
        "model": "BRENDA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LINDA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "QQ",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LORDS ZOOM",
        "variants": [
          "STD",
          "LI",
          "LZ60"
        ]
      },
      {
        "model": "BGAUSS",
        "variants": [
          "B8 LITHIUM SPARKLING WG",
          "A2 LITHIUM GLACIER WG",
          "B8 LEAD ACID",
          "A2 LEAD ACID"
        ]
      },
      {
        "model": "EVOLET",
        "variants": [
          "PONY CLASSIC",
          "POLO CLASSIC",
          "DERBY CLASSIC",
          "POLO VRL"
        ]
      }
    ]
  },
  {
    "make": "E CARBON",
    "models": [
      {
        "model": "STAR X",
        "variants": [
          "69",
          "79",
          "89",
          "99",
          "99",
          "89",
          "79",
          "69"
        ]
      }
    ]
  },
  {
    "make": "E CARBON NEUTRALITY",
    "models": [
      {
        "model": "ECN VIPRO",
        "variants": [
          "VIPRO-100",
          "VIPRO-80",
          "VIPRO-60"
        ]
      }
    ]
  },
  {
    "make": "E DRIVE",
    "models": [
      {
        "model": "E PRIME PLUS",
        "variants": [
          "LITHIUM 48V 18AH"
        ]
      },
      {
        "model": "E PRIDE PLUS",
        "variants": [
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "E PRIDE",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "E SMART PLUS",
        "variants": [
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "E SMART",
        "variants": [
          "LITHIUM 60V 24AH"
        ]
      },
      {
        "model": "E CROSS",
        "variants": [
          "LITHIUM 48V 24AH"
        ]
      },
      {
        "model": "E PRIME",
        "variants": [
          "LEAD ACID 48V 27AH"
        ]
      }
    ]
  },
  {
    "make": "E NEXT",
    "models": [
      {
        "model": "PRO PLUS",
        "variants": [
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "WING",
        "variants": [
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 48V 30AH",
          "LITHIUM ION 48V 24AH"
        ]
      },
      {
        "model": "PEGAZ",
        "variants": [
          "LITHIUM ION 48V 30AH",
          "LITHIUM ION 48V 24AH"
        ]
      },
      {
        "model": "ECO",
        "variants": [
          "LEAD 48V 28AH"
        ]
      },
      {
        "model": "PEGAZ PLUS",
        "variants": [
          "LITHIUM ION 60V 34AH"
        ]
      }
    ]
  },
  {
    "make": "E NEXT ELECTRIC TECHNOLOGIES",
    "models": [
      {
        "model": "PRO PLUS",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH",
          "LITHIUM ION BATTERY 60V 34AH 800WATT",
          "LITHIUM ION BATTERY 60V 28AH 800WATT"
        ]
      },
      {
        "model": "PEGAS",
        "variants": [
          "LITHIUM ION BATTERY 48V 24AH 800WATT"
        ]
      },
      {
        "model": "WING",
        "variants": [
          "LITHIUM ION BATTERY 48V 24AH 800WATT"
        ]
      },
      {
        "model": "ECO",
        "variants": [
          "LEAD ACID BATTERY 48V 28AH 800WATT"
        ]
      }
    ]
  },
  {
    "make": "E RISE",
    "models": [
      {
        "model": "E SPIN",
        "variants": [
          "60V-26AH",
          "60V-34AH",
          "48V-26AH",
          "48V-28AH",
          "60V-28AH"
        ]
      },
      {
        "model": "E1",
        "variants": [
          "60V-26AH",
          "48V-26AH",
          "48V-28AH",
          "60V-28AH"
        ]
      },
      {
        "model": "E5",
        "variants": [
          "60V-26AH",
          "60V-34AH",
          "60V-28AH"
        ]
      },
      {
        "model": "E7",
        "variants": [
          "60V-28AH",
          "60V-26AH",
          "60V-34AH"
        ]
      }
    ]
  },
  {
    "make": "E SPRINTO",
    "models": [
      {
        "model": "E SPRINTO BB",
        "variants": [
          "LI 60V 30AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "E- SPRINTO",
    "models": [
      {
        "model": "LS",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "BB",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "HS",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "E- SPRINTO",
        "variants": [
          "STANDARD",
          "BB"
        ]
      }
    ]
  },
  {
    "make": "E VISHWA",
    "models": [
      {
        "model": "SUPER EV",
        "variants": [
          "LITHIUM 48V 30AH",
          "E SCOOTER"
        ]
      },
      {
        "model": "ASTRO",
        "variants": [
          "LITHIUM 48V 30AH",
          "PLUS ZB LITHIUM 60V 30AH",
          "E SCOOTER"
        ]
      },
      {
        "model": "SWINGER",
        "variants": [
          "LITHIUM 48V 36AH",
          "E SCOOTER"
        ]
      },
      {
        "model": "PLUTO",
        "variants": [
          "LITHIUM 48V 24AH",
          "E SCOOTER"
        ]
      },
      {
        "model": "VESTA",
        "variants": [
          "LITHIUM 48V 30AH",
          "E SCOOTER"
        ]
      },
      {
        "model": "ASTRO+",
        "variants": [
          "72V"
        ]
      },
      {
        "model": "XRZ 3000",
        "variants": [
          "72V"
        ]
      }
    ]
  },
  {
    "make": "E WAY ELECTRIC PVT LTD",
    "models": [
      {
        "model": "EI",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 48V 28AH"
        ]
      },
      {
        "model": "EI PLUS",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 48V 28AH"
        ]
      },
      {
        "model": "EII",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 48V 28AH"
        ]
      },
      {
        "model": "EIII",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 48V 28AH"
        ]
      },
      {
        "model": "E MAX",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 72V 36AH"
        ]
      },
      {
        "model": "E PRO",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 72V 36AH"
        ]
      },
      {
        "model": "E RBW",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 48V 28AH"
        ]
      }
    ]
  },
  {
    "make": "E WENT",
    "models": [
      {
        "model": "MARIUM",
        "variants": [
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH",
          "LEAD 60V 28AH",
          "LEAD 72V 28AH"
        ]
      },
      {
        "model": "JV",
        "variants": [
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH",
          "LEAD 60V 28AH",
          "LEAD 72V 28AH"
        ]
      },
      {
        "model": "RABBITOR",
        "variants": [
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH",
          "LEAD 60V 28AH",
          "LEAD 72V 28AH",
          "DUAL BATTERY LI 60V 30AH"
        ]
      },
      {
        "model": "MIO",
        "variants": [
          "LITHIUM 60V 24AH",
          "LEAD 60V 28AH"
        ]
      },
      {
        "model": "UNAGI",
        "variants": [
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 72V 28AH",
          "LITHIUM WITH BMS 60V 24AH",
          "LITHIUM WITH BMS 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "E WHEELERS",
    "models": [
      {
        "model": "WIND",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "EAGLE MOTORS",
    "models": [
      {
        "model": "NOVA V4",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "E VISTA V4",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "AFLA V4",
        "variants": [
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "EAPIS",
    "models": [
      {
        "model": "72V 4BAH",
        "variants": [
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "E-APIS ELECTRIC",
    "models": [
      {
        "model": "SCOOTER",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "EARTH ENERGY",
    "models": [
      {
        "model": "E VOLVE R",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "E VOLVE X",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "GLYDE",
        "variants": [
          "E SCOOTY",
          "PLUS 52AH"
        ]
      },
      {
        "model": "EV GLYDE PLUS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "E VOLVE Z",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "E VOLVE",
        "variants": [
          "TRON",
          "ZEUS",
          "ARES",
          "R E BIKE"
        ]
      }
    ]
  },
  {
    "make": "EARTH ENERGY EV",
    "models": [
      {
        "model": "GLYDE PLUS",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "E-ASHWA",
    "models": [
      {
        "model": "CUTIE",
        "variants": [
          "60V 25AH"
        ]
      },
      {
        "model": "ROZI",
        "variants": [
          "60V 24AH"
        ]
      },
      {
        "model": "RAAKIT 100",
        "variants": [
          "60V 250W"
        ]
      },
      {
        "model": "CEO",
        "variants": [
          "60V 250W"
        ]
      },
      {
        "model": "NEBO",
        "variants": [
          "60V 250W"
        ]
      },
      {
        "model": "BLOSSOM",
        "variants": [
          "60V 24AH LITHUM ION",
          "60V 25AH LITHUM ION",
          "LITHUM ION 60V 26AH",
          "LITHUM ION 60V 28AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 48V 26AH",
          "LITHIUM ION 48V 28AH",
          "LITHIUM ION 48V 30AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "GLIDE",
        "variants": [
          "60V 28AH LITHUM ION",
          "60V 30AH LITHUM ION",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 26AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 48V 26AH",
          "LITHIUM ION 48V 28AH",
          "LITHIUM ION 48V 30AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "DANGUS",
        "variants": [
          "60V 24AH LITHUM ION",
          "60V 25AH LITHUM ION",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 26AH",
          "LITHIUM ION 60V 28AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 48V 26AH",
          "LITHIUM ION 48V 28AH",
          "LITHIUM ION 48V 30AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "DANGUS PLUS",
        "variants": [
          "60V 28AH LITHUM ION",
          "60V 30AH LITHUM ION",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 26AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 48V 26AH",
          "LITHIUM ION 48V 28AH",
          "LITHIUM ION 48V 30AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "DANGUS PRO",
        "variants": [
          "48V 24AH LITHUM ION",
          "48V 25AH LITHUM ION",
          "60V 24AH LITHUM ION",
          "60V 25AH LITHUM ION",
          "LITHIUM ION 60V 26AH",
          "LITHIUM ION 60V 28AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 48V 26AH",
          "LITHIUM ION 48V 28AH",
          "LITHIUM ION 48V 30AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "VELOX",
        "variants": [
          "60V 28AH LITHUM ION",
          "60V 30AH LITHUM ION",
          "LITHIUM ION 72V 28AH",
          "LITHIUM ION 72V 30AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 72V 28AH"
        ]
      },
      {
        "model": "VELOX PLUS",
        "variants": [
          "60V 28AH LITHUM ION",
          "60V 30AH LITHUM ION",
          "LITHIUM ION 60V 32AH",
          "LITHIUM ION 60V 34AH",
          "LITHIUM ION 72V 28AH",
          "LITHIUM ION 72V 30AH",
          "LITHIUM ION 72V 32AH",
          "LITHIUM ION 72V 34AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 72V 28AH"
        ]
      },
      {
        "model": "GLIDE PLUS",
        "variants": [
          "60V28AH LITHIUM ION",
          "60V28AH LEAD ACID",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 26AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 48V 26AH",
          "LITHIUM ION 48V 28AH",
          "LITHIUM ION 48V 30AH",
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "VELOX PRO",
        "variants": [
          "LITHIUM ION 60V 26AH",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 28AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 48V 26AH",
          "LITHIUM ION 48V 28AH",
          "LITHIUM ION 48V 30AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 48V 28AH"
        ]
      }
    ]
  },
  {
    "make": "E-BORN",
    "models": [
      {
        "model": "ATRIA",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "ACRUX",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "SPICA",
        "variants": [
          "ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "ECITY",
    "models": [
      {
        "model": "ZIP C40",
        "variants": [
          "E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ECLECTIC VEHICLES",
    "models": [
      {
        "model": "MILER",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "GALVANIC",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "CLASSIC PLUS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "RAVVI",
        "variants": [
          "72V 35AH"
        ]
      }
    ]
  },
  {
    "make": "ECO FUEL SYSTEM (I) PVT LTD",
    "models": [
      {
        "model": "HAWK PLUS",
        "variants": [
          "LITE LITHIUM BATTERY",
          "E SCOOTER"
        ]
      },
      {
        "model": "RACER",
        "variants": [
          "LITE LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "ECO FUEL SYSTEMS PVT LTD",
    "models": [
      {
        "model": "HAWK LEAD",
        "variants": [
          "LITHIUM SINGLE 24AH"
        ]
      },
      {
        "model": "HAWK PLUS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "HAWK LITE",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ECO MOTO",
    "models": [
      {
        "model": "STROM",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "WAVE PLUS",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "WAVE",
        "variants": [
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "ECOFUEL SYSTEMS",
    "models": [
      {
        "model": "HAWK LITE",
        "variants": [
          "E-SCOOTER"
        ]
      },
      {
        "model": "HAWK PLUS",
        "variants": [
          "E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ECOINDIA ELECTROMECH",
    "models": [
      {
        "model": "ECOTEEN",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "ECOWAVE E VEHICLE",
    "models": [
      {
        "model": "SUPER HARRY",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "GREEN HARRY",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "SUPER RACER NO 1",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "EDGEUP",
    "models": [
      {
        "model": "VESPA FLYEAH",
        "variants": [
          "LITHIUM 48V 36AH"
        ]
      }
    ]
  },
  {
    "make": "EDRIVE",
    "models": [
      {
        "model": "E CROSS",
        "variants": [
          "LITHIUM 48V 24AH",
          "I LITHIUM 48V 36AH"
        ]
      },
      {
        "model": "E SMART",
        "variants": [
          "LITHIUM 60V 24AH",
          "PLUS LITHIUM 60V 24AH",
          "PLUS LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "E PRIDE",
        "variants": [
          "LITHIUM 60V 30AH",
          "PLUS LITHIUM 60V 36AH",
          "I LITHIUM 60V 54AH",
          "I PRO LITHIUM 60V 54AH",
          "XL LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "E XL",
        "variants": [
          "LEAD ACID 48V 27AH"
        ]
      },
      {
        "model": "E PRIME",
        "variants": [
          "LEAD ACID 48V 27AH",
          "PLUS LITHIUM 48V 18AH",
          "LFP 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "EEVE",
    "models": [
      {
        "model": "ATREO",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "AHAVA",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "XENIAA",
        "variants": [
          "STANDARD",
          "PREMIUM",
          "STANDARD"
        ]
      },
      {
        "model": "4U",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "WIND",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "YOUR",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "EEVE XENIAA",
        "variants": [
          "60V 20AH"
        ]
      },
      {
        "model": "EEVE 4U",
        "variants": [
          "60V 20AH"
        ]
      },
      {
        "model": "EEVE YOUR",
        "variants": [
          "60V 27AH"
        ]
      },
      {
        "model": "EEVE WIND",
        "variants": [
          "70V 20AH"
        ]
      },
      {
        "model": "EEVE ATREO",
        "variants": [
          "72V 27AH"
        ]
      },
      {
        "model": "EEVE AHAVA",
        "variants": [
          "60V 27AH"
        ]
      },
      {
        "model": "EEVE SOUL",
        "variants": [
          "60V 36AH"
        ]
      },
      {
        "model": "SOUL",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "EEVE SCOOTERS",
    "models": [
      {
        "model": "AHAVA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SOUL",
        "variants": [
          "LITHIUM BATTERY 60V 36AH"
        ]
      },
      {
        "model": "4U",
        "variants": [
          "LI 60V 20AH 250WATT"
        ]
      },
      {
        "model": "ATREO",
        "variants": [
          "LA 72V 27AH 250WATT"
        ]
      },
      {
        "model": "WIND",
        "variants": [
          "LA 60V 27AH 250WATT"
        ]
      },
      {
        "model": "XENIAA",
        "variants": [
          "LI 60V 20AH 250WATT"
        ]
      },
      {
        "model": "XENIAA 2.0",
        "variants": [
          "LI 60V 20AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "EFLIGHT",
    "models": [
      {
        "model": "SUPER",
        "variants": [
          "LITHIUM BATTERY 60V"
        ]
      },
      {
        "model": "GOLD",
        "variants": [
          "LITHIUM BATTERY 60V"
        ]
      }
    ]
  },
  {
    "make": "E-FLIGHT",
    "models": [
      {
        "model": "GOLD",
        "variants": [
          "60V LITHIUM ION",
          "60V LEAD ACID"
        ]
      },
      {
        "model": "VACHEE",
        "variants": [
          "60V LITHIUM ION",
          "60V LEAD ACID"
        ]
      },
      {
        "model": "TEJAS",
        "variants": [
          "60V LITHIUM ION",
          "60V LEAD ACID"
        ]
      },
      {
        "model": "AMOGH",
        "variants": [
          "60V LITHIUM ION",
          "60V LEAD ACID"
        ]
      }
    ]
  },
  {
    "make": "EGE",
    "models": [
      {
        "model": "EGE",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "EIDER",
    "models": [
      {
        "model": "DRONZER",
        "variants": [
          "STD",
          "150CC"
        ]
      },
      {
        "model": "JETMAX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ZAARA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RUDDY",
        "variants": [
          "150CC",
          "STD"
        ]
      },
      {
        "model": "LEORA",
        "variants": [
          "125CC"
        ]
      },
      {
        "model": "DAVE",
        "variants": [
          "150CC"
        ]
      },
      {
        "model": "POLICE",
        "variants": [
          "650CC"
        ]
      },
      {
        "model": "STOIC",
        "variants": [
          "110CC",
          "STD"
        ]
      },
      {
        "model": "ETANIA",
        "variants": [
          "150CC",
          "STD"
        ]
      },
      {
        "model": "DAVE 150",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LEORA 150",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BIG BOY",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "EIDER MOTORS",
    "models": [
      {
        "model": "RUDDY",
        "variants": [
          "BASE"
        ]
      },
      {
        "model": "STOIC",
        "variants": [
          "BASE"
        ]
      },
      {
        "model": "VISRAH",
        "variants": [
          "BASE"
        ]
      },
      {
        "model": "LEORA",
        "variants": [
          "BASE"
        ]
      },
      {
        "model": "DRONZER",
        "variants": [
          "BASE"
        ]
      },
      {
        "model": "ETANIA",
        "variants": [
          "BASE"
        ]
      }
    ]
  },
  {
    "make": "EKO",
    "models": [
      {
        "model": "COSMIC",
        "variants": [
          "I",
          "STD"
        ]
      },
      {
        "model": "VELOCITI",
        "variants": [
          "VELOCITI"
        ]
      },
      {
        "model": "EKO COSMIC",
        "variants": [
          "ELETRIC"
        ]
      },
      {
        "model": "EKO STRIKE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ET-120",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "EV-60",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "EKO BIKES",
    "models": [
      {
        "model": "COSMIC",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "EKO TEJAS",
    "models": [
      {
        "model": "ERA",
        "variants": [
          "60V 28AH",
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 30AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LEAD ACID BATTERY 48V 28AH",
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 60V 24AH",
          "LITHIUM BATTERY 48V 25AH"
        ]
      },
      {
        "model": "ERA PRO",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 30AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LEAD ACID BATTERY 48V 28AH",
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 48V 25AH",
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "RUGD 12",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "AXEL",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "AXEL PRO",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "PRIVE",
        "variants": [
          "LITHIUM ION 72V 40AH"
        ]
      },
      {
        "model": "RECO",
        "variants": [
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "AXLE",
        "variants": [
          "LITHIUM BATTERY 60V 24AH",
          "LITHIUM BATTERY 60V 30AH",
          "LEAD ACID BATTERY 48V 28AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LITHIUM BATTERY 48V 25AH"
        ]
      },
      {
        "model": "AXLE PRO",
        "variants": [
          "LEAD ACID BATTERY 48V 28AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LITHIUM BATTERY 48V 25AH",
          "LITHIUM BATTERY 60V 24AH",
          "LITHIUM BATTERY 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "EKO VEHICLES",
    "models": [
      {
        "model": "ET 120",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "EV 60",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ELECTRECA VEHICLE",
    "models": [
      {
        "model": "RACER LITE",
        "variants": [
          "E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ELECTRECA VEHICLES",
    "models": [
      {
        "model": "RACER",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "RACER LITE",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ELECTREUS",
    "models": [
      {
        "model": "ULTRA ROVER",
        "variants": [
          "E-BIKE",
          "THOR"
        ]
      }
    ]
  },
  {
    "make": "ELECTRICA VEHICLES",
    "models": [
      {
        "model": "RACER BLACK",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "RACER LITE",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "ELECTRIQ",
    "models": [
      {
        "model": "MLR 25",
        "variants": [
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 29AH",
          "LITHIUM 60V 42AH",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "E SPRINTO",
        "variants": [
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 29AH",
          "LITHIUM 60V 42AH",
          "LITHIUM 60V 22AH"
        ]
      },
      {
        "model": "E-SPRINTO",
        "variants": [
          "LS"
        ]
      }
    ]
  },
  {
    "make": "ELECTRO ARO AUTOMOTIVE",
    "models": [
      {
        "model": "VESPER",
        "variants": [
          "48V 28AH LEAD",
          "60V 28AH LEAD",
          "48V 26AH LITHIUM",
          "48V 29AH LITHIUM",
          "60V 26AH LITHIUM"
        ]
      },
      {
        "model": "WOLF",
        "variants": [
          "48V 28AH LEAD",
          "60V 28AH LEAD",
          "48V 26AH LITHIUM",
          "48V 29AH LITHIUM",
          "60V 26AH LITHIUM",
          "LEAD ACID BATTERY 48V 30AH",
          "LEAD ACID BATTERY 60V 30AH",
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 48V 30AH"
        ]
      },
      {
        "model": "RX",
        "variants": [
          "48V 28AH LEAD",
          "LITHIUM BATTERY 60V 30AH",
          "LEAD ACID BATTERY 48V 30AH",
          "LEAD ACID BATTERY 60V 30AH",
          "LITHIUM BATTERY 48V 30AH"
        ]
      },
      {
        "model": "O4",
        "variants": [
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 60V 30AH",
          "LEAD ACID BATTERY 48V 30AH",
          "LEAD ACID BATTERY 60V 30AH"
        ]
      },
      {
        "model": "A1",
        "variants": [
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 60V 30AH",
          "LEAD ACID BATTERY 60V 30AH",
          "LEAD ACID BATTERY 48V 30AH"
        ]
      },
      {
        "model": "VESPA SQ",
        "variants": [
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 60V 30AH",
          "LEAD ACID BATTERY 48V 30AH",
          "LEAD ACID BATTERY 60V 30AH"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "LEAD ACID BATTERY 60V 30AH",
          "LEAD ACID BATTERY 48V 30AH",
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 48V 30AH"
        ]
      },
      {
        "model": "R2",
        "variants": [
          "LEAD ACID BATTERY 60V 30AH",
          "LEAD ACID BATTERY 48V 30AH",
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 48V 30AH"
        ]
      }
    ]
  },
  {
    "make": "ELECTRO TECH TRANSMISSION",
    "models": [
      {
        "model": "VOLT",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "CRESTA",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "SPRINT",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "VERV",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      }
    ]
  },
  {
    "make": "ELECTROECO",
    "models": [
      {
        "model": "ECOVESPA",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 48V 24AH",
          "LITHIUM BATTERY 48V 35AH",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "ECOTEEN",
        "variants": [
          "LITHIUM BATTERY 48V 24AH",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 48V 35AH"
        ]
      }
    ]
  },
  {
    "make": "ELECTROECO MOBILITY",
    "models": [
      {
        "model": "ECO VESPA SQUARE",
        "variants": [
          "48V 30AH",
          "LITHIUM 48V 36AH"
        ]
      },
      {
        "model": "ECO TEEN",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ECO VESPA",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "ECOTEEN",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "ELECTRORIDE",
    "models": [
      {
        "model": "ERISE E1",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LITHIUM ION 48V 26AH"
        ]
      },
      {
        "model": "ERISE E5",
        "variants": [
          "LITHIUM ION 60V 26AH"
        ]
      }
    ]
  },
  {
    "make": "ELECTROTHERM",
    "models": [
      {
        "model": "YO XPLOR",
        "variants": [
          "STD",
          "E BIKE"
        ]
      },
      {
        "model": "YO EXL",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "YO SPEED",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "YO ELECTRON",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YO EDGE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YO DRIFT",
        "variants": [
          "E BIKE",
          "DX E BIKE",
          "DX LITHIUM E BIKE"
        ]
      },
      {
        "model": "YO TREND",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YO STYLE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YO SMART",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YO SPARK",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ELECTROWHEELZ",
    "models": [
      {
        "model": "WIND 24",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "WIND P 24",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "V MAX 24",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "STORN 24",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "WIND 30",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "WIND P 30",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "V MAX 30",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "WIND",
        "variants": [
          "60 V E-SCOOTER"
        ]
      },
      {
        "model": "WIND PLUS",
        "variants": [
          "60 V E-SCOOTER"
        ]
      },
      {
        "model": "V-MAX",
        "variants": [
          "48 V E-SCOOTER"
        ]
      },
      {
        "model": "V-MAX PLUS",
        "variants": [
          "60 V E-SCOOTER"
        ]
      },
      {
        "model": "STORM",
        "variants": [
          "60 V E-SCOOTER"
        ]
      },
      {
        "model": "FX 350",
        "variants": [
          "72 V E-BIKE"
        ]
      },
      {
        "model": "WIND 60",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "BREEZE",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ELECTRTHERM INDIA PVT LTD",
    "models": [
      {
        "model": "YO EXL",
        "variants": [
          "ELECTRIC BIKE",
          "ER -ELECTRIC BIKE"
        ]
      },
      {
        "model": "YO SMART",
        "variants": [
          "ELECTRIC BIKE"
        ]
      },
      {
        "model": "YO SPEED",
        "variants": [
          "ELECTRIC BIKE"
        ]
      },
      {
        "model": "YO XPLOR",
        "variants": [
          "ER -ELECTRIC BIKE",
          "ELECTRIC BIKE"
        ]
      },
      {
        "model": "YO SPARK",
        "variants": [
          "ELECTRIC BIKE"
        ]
      },
      {
        "model": "YO STYLE",
        "variants": [
          "ELECTRIC BIKE"
        ]
      },
      {
        "model": "YO ELECTRON",
        "variants": [
          "ELECTRIC BIKE",
          "ER -ELECTRIC BIKE"
        ]
      },
      {
        "model": "YO SPIN",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "YO DRIFT",
        "variants": [
          "ELECTRIC BIKE",
          "DX"
        ]
      },
      {
        "model": "YO EDGE",
        "variants": [
          "ELECTRIC BIKE",
          "DX"
        ]
      },
      {
        "model": "YO TREND",
        "variants": [
          "ELECTRIC BIKE"
        ]
      },
      {
        "model": "YO BIKE",
        "variants": [
          "TUFF",
          "TRUST"
        ]
      }
    ]
  },
  {
    "make": "ELEGO",
    "models": [
      {
        "model": "1",
        "variants": [
          "E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ELEGO MOTORS",
    "models": [
      {
        "model": "ELEGO 1.0",
        "variants": [
          "LITHIUM BATTERY 60V 30AH",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 60V 26AH",
          "LITHIUM BATTERY 60V 24AH",
          "LITHIUM BATTERY 60V 27AH",
          "LITHIUM BATTERY 60V 33AH"
        ]
      },
      {
        "model": "ELEGO 2.0",
        "variants": [
          "LITHIUM BATTERY 60V 27AH",
          "LITHIUM BATTERY 60V 24AH",
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ELEGO MOTORS PVT LTD",
    "models": [
      {
        "model": "ELEGO 1.0",
        "variants": [
          "E SCOOTY",
          "OKAYA LI 60V 22AH",
          "OKAYA LI 60V 25AH",
          "OKAYA LI 60V 30AH",
          "OKAYA LI 60V 34AH",
          "WITHOUT BATTERY CHAGER",
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ELEGO 2.0",
        "variants": [
          "LEAD ACID",
          "OKAYA LI 60V 22AH",
          "OKAYA LI 60V 25AH",
          "OKAYA LI 60V 30AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "N1",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "N2",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "N3 GTR",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "ELESCO",
    "models": [
      {
        "model": "CLASSIC",
        "variants": [
          "LITHIUM",
          "LED",
          "E BIKE"
        ]
      },
      {
        "model": "E ROYAL",
        "variants": [
          "LED"
        ]
      },
      {
        "model": "THUNDER",
        "variants": [
          "LED"
        ]
      },
      {
        "model": "E TORQ",
        "variants": [
          "LED"
        ]
      },
      {
        "model": "E ROY X2",
        "variants": [
          "LED"
        ]
      }
    ]
  },
  {
    "make": "ELEXA",
    "models": [
      {
        "model": "EEVA",
        "variants": [
          "E-SCOOTER",
          "LITHIUM BATTERY 48V 30AH"
        ]
      },
      {
        "model": "EVEGA",
        "variants": [
          "E-SCOOTER",
          "LITHIUM BATTERY 48V 30AH"
        ]
      }
    ]
  },
  {
    "make": "ELEXA AUTO",
    "models": [
      {
        "model": "EVEGA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "EEVA",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ELITE AUTO",
    "models": [
      {
        "model": "GARUD",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 48V 30AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "GARUD PRO",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 48V 30AH",
          "LITHIUM 60V 24AH"
        ]
      },
      {
        "model": "GARUD ECO",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 48V 30AH",
          "LITHIUM 60V 36AH"
        ]
      }
    ]
  },
  {
    "make": "ELTHOR",
    "models": [
      {
        "model": "BRAVO",
        "variants": [
          "STD",
          "SINGLE BATTERY",
          "DOUBLE BATTERY",
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ELTHOR ELECTRIC",
    "models": [
      {
        "model": "ELTHOR BRAVO SB",
        "variants": [
          "E SCOOTY",
          "72V 31AH",
          "72V 36AH",
          "72V 43AH"
        ]
      },
      {
        "model": "ELTHOR BRAVO DB",
        "variants": [
          "E SCOOTY",
          "72V 62AH",
          "72V 72AH",
          "72V 86AH"
        ]
      }
    ]
  },
  {
    "make": "ELTHOR ENERGY",
    "models": [
      {
        "model": "ELTHOR BRAVO",
        "variants": [
          "LITHIUM ION 72V 42AH",
          "LITHIUM ION 72V 62AH"
        ]
      }
    ]
  },
  {
    "make": "ELYX MOBILITY",
    "models": [
      {
        "model": "ELYX XLR8R",
        "variants": [
          "ELECTRIC LOADER"
        ]
      },
      {
        "model": "LOW SPEED LOADER",
        "variants": [
          "ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "EMIC INDIA",
    "models": [
      {
        "model": "EV30",
        "variants": [
          "X",
          "Y",
          "Z"
        ]
      },
      {
        "model": "EV20",
        "variants": [
          "X",
          "Y",
          "Z"
        ]
      },
      {
        "model": "EV40",
        "variants": [
          "X",
          "Y",
          "Z"
        ]
      }
    ]
  },
  {
    "make": "EMOTORAD",
    "models": [
      {
        "model": "T-REX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "EMX",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "EMV WORKS",
    "models": [
      {
        "model": "MAYURA",
        "variants": [
          "LITHIUM ION 60V 42AH"
        ]
      }
    ]
  },
  {
    "make": "ENERGY AUTOMOBILE",
    "models": [
      {
        "model": "EV ONE",
        "variants": [
          "STD",
          "LEAD 60V 30AH",
          "LEAD ACID"
        ]
      }
    ]
  },
  {
    "make": "ENEXT",
    "models": [
      {
        "model": "WING",
        "variants": [
          "60 V LITHIUM",
          "48V 30A LI"
        ]
      },
      {
        "model": "PRO PLUS",
        "variants": [
          "60V LITHIUM ION"
        ]
      },
      {
        "model": "PEGAZ",
        "variants": [
          "60V",
          "48V 38A LI"
        ]
      }
    ]
  },
  {
    "make": "ENGTIAN ELECTRIC BIKE",
    "models": [
      {
        "model": "PRO",
        "variants": [
          "LEAD ACID BATTERY 48V 28 AH"
        ]
      }
    ]
  },
  {
    "make": "ENIGMA",
    "models": [
      {
        "model": "CRINK PLUS",
        "variants": [
          "60.9V 40AH",
          "ELECTRIC"
        ]
      },
      {
        "model": "N8 THUNDERSTROM",
        "variants": [
          "LITHIUM ION 72V 40AH"
        ]
      },
      {
        "model": "CRINK PRO",
        "variants": [
          "LITHIUM ION 72V 30AH",
          "ELECTRIC"
        ]
      },
      {
        "model": "CRINK V1",
        "variants": [
          "LITHIUM ION 72V 30AH"
        ]
      },
      {
        "model": "GT 450 PRO",
        "variants": [
          "LITHIUM ION 60V 40AH"
        ]
      },
      {
        "model": "AMBIER",
        "variants": [
          "N8 HS",
          "ELECTRIC"
        ]
      },
      {
        "model": "GT450",
        "variants": [
          "ELECTRIC",
          "PRO"
        ]
      },
      {
        "model": "N8",
        "variants": [
          "ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "ENIGMA AUTOMOBILES",
    "models": [
      {
        "model": "GT 450 PRO",
        "variants": [
          "LI 60V 40AH 1.5KW"
        ]
      },
      {
        "model": "N8",
        "variants": [
          "LI 72V 40AH 1.5KW"
        ]
      },
      {
        "model": "CRINK PRO",
        "variants": [
          "LEP 72V 30AH 3KW",
          "LI 72V 30AH 3KW"
        ]
      },
      {
        "model": "CRINK",
        "variants": [
          "V1",
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ENNES",
    "models": [
      {
        "model": "GREEN",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "ENNES ENGG PVT LTD",
    "models": [
      {
        "model": "DLX",
        "variants": [
          "DLX"
        ]
      },
      {
        "model": "STD",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "ENNES ENGINEERING PVT LTD",
    "models": [
      {
        "model": "ENGREEN",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "ERISE",
    "models": [
      {
        "model": "E1",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "E5",
        "variants": [
          "ELECTRIC",
          "LI 60V 26AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "E-ROYCE",
    "models": [
      {
        "model": "RS 90",
        "variants": [
          "60V 30AH LITHIUM"
        ]
      }
    ]
  },
  {
    "make": "ESPERA",
    "models": [
      {
        "model": "E CROSS",
        "variants": [
          "E1 LITHIUM 60V 37AH DUAL",
          "E2 LITHIUM 60V 26AH SINGLE"
        ]
      }
    ]
  },
  {
    "make": "ESSEL ENERGY",
    "models": [
      {
        "model": "ESSEL ENERGY GET 1",
        "variants": [
          "48V 11AH",
          "48V 13AH"
        ]
      },
      {
        "model": "ESSEL ENERGY GET 7",
        "variants": [
          "48V 11AH",
          "48V 13AH",
          "48V 16AH"
        ]
      },
      {
        "model": "BOLT",
        "variants": [
          "LITHIUM 48V 27AH",
          "LITHIUM 48V 24AH",
          "LITHIUM 48V 30AH",
          "E-SCOOTER"
        ]
      },
      {
        "model": "WIND",
        "variants": [
          "LITHIUM 48V 27AH",
          "LITHIUM 48V 24AH",
          "LITHIUM 48V 30AH",
          "E-SCOOTER"
        ]
      },
      {
        "model": "BOLT 2",
        "variants": [
          "LITHIUM 48V 27AH",
          "LITHIUM 48V 24AH",
          "LITHIUM 48V 30AH"
        ]
      },
      {
        "model": "GET 1",
        "variants": [
          "11AH LI",
          "13AH LI"
        ]
      },
      {
        "model": "GET 7",
        "variants": [
          "11AH LI",
          "13AH LI",
          "16AH LI"
        ]
      }
    ]
  },
  {
    "make": "ETERGO",
    "models": [
      {
        "model": "APP",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "EUREKA ELECTRIC VEHICLE",
    "models": [
      {
        "model": "BARCELONA",
        "variants": [
          "BREEZE"
        ]
      },
      {
        "model": "VALENCIA",
        "variants": [
          "VICTORY"
        ]
      },
      {
        "model": "PARIS",
        "variants": [
          "VOLTA"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "THUNDER",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "EURO ELECTRIC BIKES",
    "models": [
      {
        "model": "DERBY",
        "variants": [
          "DERBY"
        ]
      }
    ]
  },
  {
    "make": "EV AUTO",
    "models": [
      {
        "model": "BROCH",
        "variants": [
          "48V 28AH LA",
          "60V 28AH LA",
          "48V 48AH",
          "60V 36AH"
        ]
      },
      {
        "model": "ETORQ",
        "variants": [
          "48V 28AH LA",
          "60V 28AH LA",
          "48V 48AH",
          "60V 36AH"
        ]
      },
      {
        "model": "E-ROY",
        "variants": [
          "48V 28AH LA",
          "60V 28AH LA",
          "48V 48AH",
          "60V 36AH"
        ]
      },
      {
        "model": "CLASSIC",
        "variants": [
          "48V 28AH LA",
          "60V 28AH LA",
          "48V 48AH",
          "60V 36AH"
        ]
      },
      {
        "model": "E-ROY X2",
        "variants": [
          "48V 28AH LA",
          "60V 28AH LA",
          "48V 48AH",
          "60V 36AH"
        ]
      },
      {
        "model": "E-ROYAL",
        "variants": [
          "48V 28AH LA",
          "60V 28AH LA",
          "48V 48AH",
          "60V 36AH"
        ]
      },
      {
        "model": "THUNDER",
        "variants": [
          "60V 28AH LA",
          "72V 28AH LA"
        ]
      }
    ]
  },
  {
    "make": "EV FORCE",
    "models": [
      {
        "model": "EV JET",
        "variants": [
          "LEAD ACID 48V/25AH",
          "LITHIUM 48V/25AH",
          "LITHIUM 60V/30AH",
          "LITHIUM 48V/30AH"
        ]
      },
      {
        "model": "EV ONE ALPHA",
        "variants": [
          "LEAD ACID 48V/25AH",
          "LITHIUM 48V/25AH",
          "LITHIUM 60V/30AH",
          "LITHIUM 48V/30AH"
        ]
      },
      {
        "model": "EV ONE RAPID PLUS",
        "variants": [
          "LEAD ACID 60V/28AH",
          "LITHIUM 48V/25AH",
          "LITHIUM 60V/30AH",
          "LITHIUM 48V/30AH"
        ]
      },
      {
        "model": "EV ONE SUPER PLUS",
        "variants": [
          "LEAD ACID 48V/25AH",
          "LITHIUM 48V/25AH",
          "LITHIUM 60V/30AH",
          "LITHIUM 48V/30AH"
        ]
      }
    ]
  },
  {
    "make": "EV JETS",
    "models": [
      {
        "model": "THUNDER",
        "variants": [
          "NC 72V 32AH"
        ]
      },
      {
        "model": "MASSIVE",
        "variants": [
          "NC 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "EV KART",
    "models": [
      {
        "model": "V1",
        "variants": [
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "P1",
        "variants": [
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "EV MINDA",
    "models": [
      {
        "model": "GLIZER",
        "variants": [
          "48V/24AH",
          "PLUS",
          "3D",
          "F",
          "X",
          "G",
          "S",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "AZZURE",
        "variants": [
          "48V/24AH",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "RACITY",
        "variants": [
          "48V/24AH",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "VGLIDE",
        "variants": [
          "48V/24AH"
        ]
      },
      {
        "model": "ZOOM",
        "variants": [
          "48V/24AH",
          "60",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "PULSO",
        "variants": [
          "48V/24AH"
        ]
      },
      {
        "model": "RIDER",
        "variants": [
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "PULSO RIDER",
        "variants": [
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "V GLIDE",
        "variants": [
          "LITHIUM BATTERY 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "EVEE",
    "models": [
      {
        "model": "FLASH PLUS",
        "variants": [
          "60V ESCOOTER"
        ]
      }
    ]
  },
  {
    "make": "EVEI",
    "models": [
      {
        "model": "MAGESTIC",
        "variants": [
          "AURUM WITH USB SOUND"
        ]
      }
    ]
  },
  {
    "make": "EVEIUM",
    "models": [
      {
        "model": "COSMO",
        "variants": [
          "LITHIUM ION 72V 30AH",
          "SCOOTER",
          "LI 72V 30AH 2KW"
        ]
      },
      {
        "model": "COMET",
        "variants": [
          "LITHIUM ION 72V 50AH",
          "72V",
          "72 V..",
          "LI 72V 50AH 3 KW"
        ]
      },
      {
        "model": "CZAR",
        "variants": [
          "LITHIUM ION 72V 42AH",
          "72V",
          "LI 72V 42AH 4KW"
        ]
      }
    ]
  },
  {
    "make": "EVERA AUTO INDIA",
    "models": [
      {
        "model": "RAINA",
        "variants": [
          "LITHIUM BATTERY 48V 30AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LEAD ACID BATTERY 28 AH"
        ]
      },
      {
        "model": "ENKAY",
        "variants": [
          "LITHIUM BATTERY 48V 30AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LEAD ACID BATTERY 28 AH"
        ]
      },
      {
        "model": "WINNER PLUS",
        "variants": [
          "LITHIUM BATTERY 48V 30AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LEAD ACID BATTERY 28 AH"
        ]
      },
      {
        "model": "WINNER",
        "variants": [
          "LITHIUM BATTERY 48V 30AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LEAD ACID BATTERY 28AH"
        ]
      },
      {
        "model": "SPUNK",
        "variants": [
          "LEAD ACID BATTERY 28 AH"
        ]
      },
      {
        "model": "ROYAL",
        "variants": [
          "LEAD ACID BATTERY 28 AH"
        ]
      },
      {
        "model": "KING",
        "variants": [
          "LEAD ACID BATTERY 48V"
        ]
      },
      {
        "model": "MARINA",
        "variants": [
          "LEAD ACID BATTERY 48V"
        ]
      }
    ]
  },
  {
    "make": "EVEX INDIA",
    "models": [
      {
        "model": "SPRINT PLUS",
        "variants": [
          "E SCOOTY",
          "M2",
          "E SCOOTY"
        ]
      },
      {
        "model": "SPRINT",
        "variants": [
          "M1",
          "M1 LITE",
          "LITHIUM 48V 34AH",
          "LITHIUM 48V 28AH",
          "LITHIUM 48V 20AH",
          "LITHIUM 48V 24AH",
          "LEAD ACID 48V 28AH",
          "LITHIUM 48V 30AH",
          "LITHIUM ION 60V / 28 AH"
        ]
      },
      {
        "model": "SPRINT PLUS PLUS",
        "variants": [
          "LITHIUM ION 60V / 28 AH"
        ]
      }
    ]
  },
  {
    "make": "EVEY",
    "models": [
      {
        "model": "SPORT Z PRO",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "VEESPA R",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "MINNI",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "ELECTRIC"
        ]
      },
      {
        "model": "SPORT Z",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "VESPA SQUARE",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "MAGESTIC",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "SPORT PRO",
        "variants": [
          "LEAD ACID 72V 28AH",
          "ELECTRIC"
        ]
      },
      {
        "model": "VESPA",
        "variants": [
          "LEAD ACID 72V 28AH",
          "ELECTRIC",
          "PLUS DISC"
        ]
      },
      {
        "model": "SPORTZ",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "ELITE",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "H1 SPORTS",
        "variants": [
          "H1 SPORTS"
        ]
      }
    ]
  },
  {
    "make": "EVOLET",
    "models": [
      {
        "model": "DERBY CLASSIC",
        "variants": [
          "EZ"
        ]
      },
      {
        "model": "PONY CLASSIC",
        "variants": [
          "POLOCLASSIC EZ",
          "EZ",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "DERBY",
        "variants": [
          "EZ",
          "CLASSIC",
          "EZ (LEAD ACID)",
          "CLASSIC (LI-ION)",
          "CLASSIC 48 V",
          "CLASSIC",
          "60 VCL LITHIUM BATTERY",
          "CL LITHIUM BATTERY",
          "CLASSIC LITIHIUM ION"
        ]
      },
      {
        "model": "PONY",
        "variants": [
          "EZ",
          "CLASSIC",
          "CLASSIC 48 V",
          "CLASSIC 60 V",
          "EZ",
          "CLASSIC"
        ]
      },
      {
        "model": "POLO",
        "variants": [
          "EZ",
          "CLASSIC",
          "EZ (LEAD ACID)",
          "CLASSIC (LI-ION)",
          "CLASSIC 48 V",
          "CLASSIC 60 V",
          "EZ",
          "CLASSIC"
        ]
      },
      {
        "model": "POLO PONY",
        "variants": [
          "EZ (LEAD ACID)",
          "CLASSIC (LI-ION)"
        ]
      },
      {
        "model": "DHANNO",
        "variants": [
          "CLASSIC 48 V",
          "CLASSIC",
          "STANDARD"
        ]
      },
      {
        "model": "WARRIOR CLASSIC",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "WARRIOR EZ",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "POLO CLASSIC",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "PONY EZ",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "POLO EZ",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      }
    ]
  },
  {
    "make": "EVOLVE",
    "models": [
      {
        "model": "TRON",
        "variants": [
          "LEAD ACID",
          "E SCOOTER"
        ]
      },
      {
        "model": "ZEUS",
        "variants": [
          "LEAD ACID",
          "E SCOOTER"
        ]
      },
      {
        "model": "ARES",
        "variants": [
          "LEAD ACID",
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "EVRA",
    "models": [
      {
        "model": "EX-1",
        "variants": [
          "48V 24AH",
          "60V 22AH"
        ]
      },
      {
        "model": "EX-2",
        "variants": [
          "60V 22AH"
        ]
      },
      {
        "model": "EX-3",
        "variants": [
          "60V 22AH",
          "48V 24AH"
        ]
      },
      {
        "model": "EX2",
        "variants": [
          "60V"
        ]
      },
      {
        "model": "EX2 PLUS",
        "variants": [
          "60V",
          "48V"
        ]
      },
      {
        "model": "EX3 PLUS",
        "variants": [
          "60V"
        ]
      },
      {
        "model": "EX1",
        "variants": [
          "60V SCOOTER"
        ]
      },
      {
        "model": "EX3",
        "variants": [
          "60V SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "EVRA ENERGY INDIA",
    "models": [
      {
        "model": "EX1",
        "variants": [
          "LITHIUM ION 48V"
        ]
      }
    ]
  },
  {
    "make": "EVSUNDAK",
    "models": [
      {
        "model": "KEAGLE",
        "variants": [
          "48V/25AH",
          "60V/25AH",
          "60V/20AH"
        ]
      },
      {
        "model": "MARS",
        "variants": [
          "48V/25AH",
          "60V/25AH",
          "60V/20AH"
        ]
      }
    ]
  },
  {
    "make": "EVTRIC",
    "models": [
      {
        "model": "RIDE",
        "variants": [
          "E SCOOTER",
          "HS ELECTRIC SCOOTER",
          "LOW SPEED",
          "HIGH SPEED",
          "E BIKE",
          "LITHIUM BATTERY 48V 31 AH",
          "LITHIUM BATTERY 60V 31 AH",
          "LITHIUM BATTERY 48V 26 AH",
          "LITHIUM BATTERY 48V 34 AH",
          "LITHIUM BATTERY 52V 35AH",
          "LI 48V 39AH 250WATT"
        ]
      },
      {
        "model": "AXIS",
        "variants": [
          "E SCOOTER",
          "LOW SPEED",
          "E BIKE",
          "LITHIUM BATTERY 48V  31AH",
          "LITHIUM BATTERY 60V 26 AH",
          "LITHIUM BATTERY 48V 34 AH",
          "LITHIUM BATTERY 48V 26 AH"
        ]
      },
      {
        "model": "CONNECT",
        "variants": [
          "ELECTRIC SCOOTER",
          "STANDARD",
          "48 V",
          "48V / 24 AH",
          "LITHIUM BATTERY 60V",
          "LITHIUM BATTERY 48V"
        ]
      },
      {
        "model": "MIGHTY",
        "variants": [
          "E SCOOTER",
          "LOW SPEED",
          "LITHIUM BATTERY 60V"
        ]
      },
      {
        "model": "RISE",
        "variants": [
          "E SCOOTER",
          "HIGH SPEED",
          "72V / 40 AH",
          "LITHIUM BATTERY 72V 40AH"
        ]
      },
      {
        "model": "RIDE PRO",
        "variants": [
          "48V / 34 AH",
          "48V / 39 AH",
          "LITHIUM BATTERY 72V"
        ]
      },
      {
        "model": "CONNECT DUAL BATTERY",
        "variants": [
          "48V / 24 AH X 2"
        ]
      },
      {
        "model": "MIGHTY PRO",
        "variants": [
          "LITHIUM BATTERY 60V"
        ]
      }
    ]
  },
  {
    "make": "EVTRIC MOTORS",
    "models": [
      {
        "model": "RISE",
        "variants": [
          "STANDARD",
          "LITHIUM 72V 40AH",
          "LITHIUM ION 72V 40AH"
        ]
      },
      {
        "model": "MIGHTY",
        "variants": [
          "STANDARD",
          "62V 32AH",
          "48V 34AH"
        ]
      },
      {
        "model": "EVTRIC AXIS",
        "variants": [
          "48V 26AH",
          "60V 26AH",
          "48V 34AH"
        ]
      },
      {
        "model": "EVTRIC RIDE",
        "variants": [
          "48V 26AH",
          "48V 31AH",
          "48V 34AH",
          "48V 39AH",
          "HSS 72V 34AH",
          "HSS 72V 39AH",
          "60V 34AH"
        ]
      },
      {
        "model": "CONNECT",
        "variants": [
          "LITHIUM 60V 48AH"
        ]
      },
      {
        "model": "AXIS",
        "variants": [
          "48V34AH"
        ]
      }
    ]
  },
  {
    "make": "EWAY ELECTRIC PVT.LTD",
    "models": [
      {
        "model": "EI",
        "variants": [
          "STD (48 CC_LEAD)"
        ]
      },
      {
        "model": "E II",
        "variants": [
          "STD (60 CC_LEAD)",
          "STD (60 CC_LITHIUM)"
        ]
      },
      {
        "model": "E III",
        "variants": [
          "STD (60 CC_LEAD)",
          "STD (60 CC_LITHIUM)"
        ]
      },
      {
        "model": "E RBW",
        "variants": [
          "STD (60 CC_LEAD)",
          "STD (60 CC_LITHIUM)"
        ]
      },
      {
        "model": "E MAX",
        "variants": [
          "STD (60 CC_LEAD)",
          "STD (60 CC_LITHIUM)"
        ]
      },
      {
        "model": "E PRO",
        "variants": [
          "STD (60 CC)",
          "STD (72 CC)"
        ]
      },
      {
        "model": "E I",
        "variants": [
          "STD (48 CC_LITHIUM)"
        ]
      }
    ]
  },
  {
    "make": "EWAY ELECTRIC PVT.LTD.",
    "models": [
      {
        "model": "E I",
        "variants": [
          "STD 48V",
          "STD 60V"
        ]
      },
      {
        "model": "E I PLUS",
        "variants": [
          "STD 48V",
          "STD 60V"
        ]
      },
      {
        "model": "E II",
        "variants": [
          "STD 48V",
          "STD 60V"
        ]
      },
      {
        "model": "E III",
        "variants": [
          "STD 48V",
          "STD 60V"
        ]
      },
      {
        "model": "E MAX",
        "variants": [
          "STD 60V",
          "STD 72V"
        ]
      },
      {
        "model": "E PRO",
        "variants": [
          "STD 60V",
          "STD 72V"
        ]
      },
      {
        "model": "E RBW",
        "variants": [
          "STD 48V",
          "STD 60V"
        ]
      }
    ]
  },
  {
    "make": "EWHEELIE",
    "models": [
      {
        "model": "DESI",
        "variants": [
          "LITHIUM 48V 28AH"
        ]
      },
      {
        "model": "DESI PLUS",
        "variants": [
          "LITHIUM 48V 28AH"
        ]
      },
      {
        "model": "RIDER",
        "variants": [
          "LITHIUM 48V 28AH"
        ]
      },
      {
        "model": "SMARTY",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "SMARTY PLUS",
        "variants": [
          "LITHIUM 60V 30AH",
          "LPF 60V 30AH",
          "LPF 60V 42AH",
          "SMF 60V 28AH"
        ]
      },
      {
        "model": "RACER",
        "variants": [
          "LITHIUM 48V 28AH"
        ]
      },
      {
        "model": "RACER 3D",
        "variants": [
          "LITHIUM 48V 28AH"
        ]
      },
      {
        "model": "DESI VASPER",
        "variants": [
          "LFP 48V 28AH",
          "LPF 60V 30AH",
          "SMF 60V 28AH"
        ]
      },
      {
        "model": "DESI JAZE",
        "variants": [
          "LPF 48V 28AH",
          "LPF 48V 30AH",
          "SMF 60V 28AH"
        ]
      },
      {
        "model": "DESI ROLLER",
        "variants": [
          "LPF 48V 24AH",
          "LPF 60V 30AH",
          "SMF 60V 28AH"
        ]
      },
      {
        "model": "DESI NOVA",
        "variants": [
          "LPF 48V 28AH",
          "LPF 60V 30AH",
          "SMF 60V 28AH"
        ]
      },
      {
        "model": "DESI PHOENIX",
        "variants": [
          "LPF 48V 28AH",
          "LPF 48V 30AH",
          "SMF 60V 28AH"
        ]
      },
      {
        "model": "SMARTY FURY",
        "variants": [
          "LPF 48V 24AH",
          "LPF 48V 30AH",
          "SMF 60V 28AH"
        ]
      },
      {
        "model": "SMARTY RAVEN",
        "variants": [
          "LPF 60V 30AH",
          "LPF 60V 42AH",
          "SMF 60V 28AH"
        ]
      },
      {
        "model": "SMARTY RACER",
        "variants": [
          "LPF 48V 24AH",
          "LPF 48V 30AH",
          "SMF 60V 28AH"
        ]
      },
      {
        "model": "SMARTY RIDER",
        "variants": [
          "LPF 48V 24AH",
          "LPF 48V 30AH",
          "SMF 60V 28AH"
        ]
      },
      {
        "model": "SMARTY 3D",
        "variants": [
          "LPF 48V 24AH",
          "LPF 60V 36AH"
        ]
      }
    ]
  },
  {
    "make": "EXER",
    "models": [
      {
        "model": "V150",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "V-MODEL",
        "variants": [
          "V80",
          "V150"
        ]
      },
      {
        "model": "G-MODEL",
        "variants": [
          "G80",
          "G150"
        ]
      },
      {
        "model": "C-MODEL",
        "variants": [
          "C80"
        ]
      }
    ]
  },
  {
    "make": "EXERVAL",
    "models": [
      {
        "model": "G150",
        "variants": [
          "LITHIUM 60V 40AH"
        ]
      },
      {
        "model": "G80",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "V150",
        "variants": [
          "LITHIUM 60V 40AH"
        ]
      },
      {
        "model": "V80",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "EXER C80",
        "variants": [
          "LITHIUM 60V 40AH"
        ]
      },
      {
        "model": "EXER V 80",
        "variants": [
          "LITHIUM ION BATTERY 60V"
        ]
      },
      {
        "model": "EXER V 150",
        "variants": [
          "LITHIUM ION BATTERY 60V"
        ]
      },
      {
        "model": "EXER G 150",
        "variants": [
          "LITHIUM ION BATTERY 60V"
        ]
      },
      {
        "model": "EXER G 80",
        "variants": [
          "LITHIUM ION BATTERY 60V"
        ]
      }
    ]
  },
  {
    "make": "E-YALI",
    "models": [
      {
        "model": "SPAREN",
        "variants": [
          "LXI"
        ]
      }
    ]
  },
  {
    "make": "EZYRIDE",
    "models": [
      {
        "model": "SAPPHIRE",
        "variants": [
          "120",
          "60",
          "75",
          "100"
        ]
      },
      {
        "model": "OPAL",
        "variants": [
          "60",
          "80",
          "100"
        ]
      },
      {
        "model": "OPAL PLUS",
        "variants": [
          "100"
        ]
      }
    ]
  },
  {
    "make": "EZYRIDE E VEHICLES",
    "models": [
      {
        "model": "SAPPHIRE",
        "variants": [
          "VX",
          "ZX",
          "PLUS",
          "75"
        ]
      },
      {
        "model": "OPAL",
        "variants": [
          "VX",
          "PLUS",
          "75",
          "100 LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "GARNET",
        "variants": [
          "60",
          "75",
          "90",
          "95"
        ]
      },
      {
        "model": "PEARL",
        "variants": [
          "PUSA 45 LITHIUM ION"
        ]
      },
      {
        "model": "PULSE P45",
        "variants": [
          "LITHIUM ION 48V 50AH"
        ]
      }
    ]
  },
  {
    "make": "F B MONDIAL",
    "models": [
      {
        "model": "HPS 300",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "FAB MOTORS",
    "models": [
      {
        "model": "BOBBER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DDE 9B",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "FB MONDIAL",
    "models": [
      {
        "model": "HPS 300",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "FERRANZA",
    "models": [
      {
        "model": "BOLD",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 30AH"
        ]
      },
      {
        "model": "WINDY",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 30AH"
        ]
      },
      {
        "model": "VPRO",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 30AH"
        ]
      },
      {
        "model": "ACTIVE",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "FERRANZA ELECTRIC",
    "models": [
      {
        "model": "V.PRO",
        "variants": [
          "60V/28AH",
          "60V 30 AH"
        ]
      },
      {
        "model": "WINDY",
        "variants": [
          "60V/28AH",
          "60V 30 AH"
        ]
      },
      {
        "model": "BOLD",
        "variants": [
          "60V/28AH",
          "60V 30 AH"
        ]
      },
      {
        "model": "ACTIVE",
        "variants": [
          "60V/28AH",
          "60V 30 AH"
        ]
      }
    ]
  },
  {
    "make": "FIADO",
    "models": [
      {
        "model": "D1",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "D1 PLUS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "V1",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "V1 PLUS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ARCHER",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "FIDATO",
    "models": [
      {
        "model": "21",
        "variants": [
          "72V 30AH LITHUM ION"
        ]
      },
      {
        "model": "FUTURE 2020",
        "variants": [
          "E BIKE",
          "LFP 60V 30AH",
          "60V 24AH LITHUM ION",
          "60V 28AH LITHUM ION"
        ]
      },
      {
        "model": "CUTIE",
        "variants": [
          "LFP 60V 30AH",
          "60V 24AH LITHUM ION"
        ]
      },
      {
        "model": "EASY GO",
        "variants": [
          "LFP 60V 30AH",
          "60V 28AH LITHUM ION"
        ]
      },
      {
        "model": "FIDATO 21",
        "variants": [
          "LFP 72V 24AH"
        ]
      },
      {
        "model": "EASY GO PLUS",
        "variants": [
          "LFP 60V 30AH",
          "60V 34AH LITHUM ION"
        ]
      },
      {
        "model": "JUGNU",
        "variants": [
          "LFP 60V 24AH",
          "60V 27AH LEAD ACID"
        ]
      },
      {
        "model": "EASY GO PRO",
        "variants": [
          "LFP 60V 30AH",
          "60V 28AH LITHUM ION"
        ]
      },
      {
        "model": "LOADER",
        "variants": [
          "LFP 60V 30AH",
          "60V 30AH LITHUM ION",
          "60V 27AH LITHUM ION"
        ]
      },
      {
        "model": "SKYHAWK",
        "variants": [
          "LFP 72V 24AH",
          "E SCOOTER"
        ]
      },
      {
        "model": "ROCKET",
        "variants": [
          "LFP 60V 30AH",
          "60V 30AH LFP"
        ]
      }
    ]
  },
  {
    "make": "FIDATO EVTECH",
    "models": [
      {
        "model": "JUGNU",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "FUTURE 2020",
        "variants": [
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "CUTIE",
        "variants": [
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "EASY GO PRO",
        "variants": [
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "FIDATO 21",
        "variants": [
          "LITHIUM ION 72V / 30 AH"
        ]
      },
      {
        "model": "LODER",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "FIRST BIKE",
    "models": [
      {
        "model": "GLOB",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "WOLF",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "FLEETO",
    "models": [
      {
        "model": "MINI FLEET",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LITHIUM 48V 24AH",
          "LITHIUM 48V 26AH",
          "LITHIUM 48V 30AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "WOLF WARRIOR",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LITHIUM 48V 24AH",
          "LITHIUM 48V 26AH",
          "LITHIUM 48V 30AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "FLYCON",
    "models": [
      {
        "model": "BRIGHT 60V BRIGHT 60V LFP",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "FLYCON MOTORS",
    "models": [
      {
        "model": "EMPIRE",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GRAVE",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "BRIGHT",
        "variants": [
          "LITHIUM ION  60V"
        ]
      }
    ]
  },
  {
    "make": "FLYRIDES AUTOTECH",
    "models": [
      {
        "model": "STORK",
        "variants": [
          "LI 63V 34AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "FOODEZEE",
    "models": [
      {
        "model": "PENTA",
        "variants": [
          "LXI"
        ]
      }
    ]
  },
  {
    "make": "FORCETRIC",
    "models": [
      {
        "model": "ACTIVE",
        "variants": [
          "STANDARD",
          "PLUS",
          "PRO"
        ]
      }
    ]
  },
  {
    "make": "FORME",
    "models": [
      {
        "model": "F8",
        "variants": [
          "E SCOOTER 40 V 20 AH LEAD ACID"
        ]
      }
    ]
  },
  {
    "make": "FRANKLIN  EV",
    "models": [
      {
        "model": "NIX",
        "variants": [
          "LITHIUM ION  60V 35AH",
          "LITHIUM ION  60V 30AH",
          "LITHIUM ION  48V 30AH",
          "LITHIUM ION  60V 27AH"
        ]
      },
      {
        "model": "NIX DLX",
        "variants": [
          "TSL 4 LI 60V 30AH 2.5 KW",
          "LITHIUM ION  60V 35AH",
          "LITHIUM ION  60V 32AH"
        ]
      },
      {
        "model": "KORO",
        "variants": [
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "POWER PLUS",
        "variants": [
          "LITHIUM ION  60V 35AH",
          "LITHIUM ION  60V 32AH"
        ]
      },
      {
        "model": "NEO",
        "variants": [
          "LITHIUM ION  48V 30AH",
          "LITHIUM ION  60V 30AH",
          "LITHIUM ION  60V 27AH"
        ]
      }
    ]
  },
  {
    "make": "FRANKLIN EV",
    "models": [
      {
        "model": "NIX",
        "variants": [
          "EBIKE",
          "LITHIUM 60V 27AH",
          "LITHIUM 60V 32AH",
          "LITHIUM 60V 35AH"
        ]
      },
      {
        "model": "NIX DLX",
        "variants": [
          "LITHIUM 60V 32AH",
          "LITHIUM 60V 35AH"
        ]
      },
      {
        "model": "POWER PLUS",
        "variants": [
          "LITHIUM 60V 32AH",
          "LITHIUM 60V 35AH"
        ]
      },
      {
        "model": "KORO",
        "variants": [
          "60V"
        ]
      }
    ]
  },
  {
    "make": "FUTURE GREEN EV PRIVATE LIMITED",
    "models": [
      {
        "model": "TRACTION",
        "variants": [
          "LITHIUM ION  250WATT"
        ]
      }
    ]
  },
  {
    "make": "G AND D MOTORS",
    "models": [
      {
        "model": "SPIRIT",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "PLUTO",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "NOVA",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "MARVEL",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "VISTA",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "ALFA",
        "variants": [
          "LEAD ACID",
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "G BYKE",
    "models": [
      {
        "model": "ACTI GO",
        "variants": [
          "LEAD ACID",
          "LITHIUM"
        ]
      },
      {
        "model": "BIRDY GO",
        "variants": [
          "LEAD ACID",
          "LITHIUM"
        ]
      },
      {
        "model": "VES GO",
        "variants": [
          "LEAD ACID",
          "LITHIUM"
        ]
      },
      {
        "model": "VES GO PLUS",
        "variants": [
          "LEAD ACID",
          "LITHIUM"
        ]
      }
    ]
  },
  {
    "make": "G BYKE ELECTRIC VEHICLE",
    "models": [
      {
        "model": "BIRDY GO",
        "variants": [
          "LITHIUM BATTERY 60V  29AH"
        ]
      }
    ]
  },
  {
    "make": "G P BIKES",
    "models": [
      {
        "model": "BLAZE",
        "variants": [
          "60W 28AH LEAD"
        ]
      },
      {
        "model": "BLAZE MAX",
        "variants": [
          "60W 28AH LEAD"
        ]
      },
      {
        "model": "BLAZE PLUS",
        "variants": [
          "60W 28AH LEAD"
        ]
      },
      {
        "model": "BLAZE PRO",
        "variants": [
          "60W 28AH LEAD"
        ]
      },
      {
        "model": "BLAZE X",
        "variants": [
          "60W 28AH LEAD"
        ]
      }
    ]
  },
  {
    "make": "GAIA",
    "models": [
      {
        "model": "SPIRIT LITE",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "LEGEND LITE",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "LEGEND GRAND",
        "variants": [
          "LFP 60V 24AH",
          "LFP 60V 30AH"
        ]
      },
      {
        "model": "STORM LITE",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "STORM GRAND",
        "variants": [
          "LFP 60V 24AH",
          "LFP 60V 30AH"
        ]
      },
      {
        "model": "CLASSIC LITE",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "CLASSIC GRAND",
        "variants": [
          "LFP 60V 24AH",
          "LFP 60V 30AH"
        ]
      },
      {
        "model": "DYNAMIC",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 72V 28AH"
        ]
      }
    ]
  },
  {
    "make": "GALAXY ELECTRIC VEHICLE",
    "models": [
      {
        "model": "OPTIMA",
        "variants": [
          "LIFEPO4 60V 24AH BATTERY"
        ]
      },
      {
        "model": "DESTINY",
        "variants": [
          "LIFEPO4 60V 30AH BATTERY"
        ]
      }
    ]
  },
  {
    "make": "GALLANT",
    "models": [
      {
        "model": "GT",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "POWER",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "V PRO",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "GALLOPS",
    "models": [
      {
        "model": "EAGLE",
        "variants": [
          "E SCOOTY",
          "E SCOOTER"
        ]
      },
      {
        "model": "TIGER",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "VIONA",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "SPIN",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "UGO",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "V5",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "V7",
        "variants": [
          "E SCOOTER",
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "GALLOPS ELECTRIC",
    "models": [
      {
        "model": "VIKTOR S",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "VIONA",
        "variants": [
          "E SCOOTER",
          "PLUS E SCOOTER"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "SPIN",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "TIGER",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "V7",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "V5",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "UGO",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "GALVAN ELECTRIC VEHICLE",
    "models": [
      {
        "model": "BITS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "IRA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "JANK",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "E WAVE PLUS",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "GAURA",
    "models": [
      {
        "model": "SNIPPER 5G",
        "variants": [
          "28AH 60V LED ACID",
          "43AH 60V LED ACID",
          "30AH 60V LITHIUM"
        ]
      },
      {
        "model": "SNIPPER 5G PLUS",
        "variants": [
          "28AH 60V LED ACID",
          "43AH 60V LED ACID",
          "30AH 60V LITHIUM"
        ]
      },
      {
        "model": "WARRIOR 5G",
        "variants": [
          "28AH 60V LED ACID",
          "43AH 60V LED ACID",
          "30AH 60V LITHIUM"
        ]
      },
      {
        "model": "WARRIOR 5G PLUS",
        "variants": [
          "28AH 60V LED ACID",
          "43AH 60V LED ACID",
          "30AH 60V LITHIUM"
        ]
      },
      {
        "model": "WARRIOR 5G PRO",
        "variants": [
          "52AH 64V LITHIUM"
        ]
      },
      {
        "model": "WARRIOR",
        "variants": [
          "5G LI+",
          "5G LI PRO",
          "5G LA"
        ]
      },
      {
        "model": "SNIPER",
        "variants": [
          "5G",
          "5G LI+",
          "5G LI PRO"
        ]
      },
      {
        "model": "PARTNER",
        "variants": [
          "5G",
          "5G LI"
        ]
      }
    ]
  },
  {
    "make": "GAURA ELECTRIC",
    "models": [
      {
        "model": "WARRIOR 5G",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "GAUTHAM MOTORS",
    "models": [
      {
        "model": "GLOBY",
        "variants": [
          "LITHIUM ION BATTERY 64V 35AH"
        ]
      },
      {
        "model": "TRIXX",
        "variants": [
          "LITHIUM ION BATTERY 60V 28AH"
        ]
      },
      {
        "model": "LIONNE",
        "variants": [
          "LITHIUM ION BATTERY 60V 40AH"
        ]
      }
    ]
  },
  {
    "make": "GBIKE",
    "models": [
      {
        "model": "SNAZZGO",
        "variants": [
          "LITHIUM 72V"
        ]
      }
    ]
  },
  {
    "make": "GELIOSE",
    "models": [
      {
        "model": "GELIOSE HOPE",
        "variants": [
          "48V 30AH"
        ]
      }
    ]
  },
  {
    "make": "GELVIN INTERNATIONAL",
    "models": [
      {
        "model": "AERORIDE BRAVO",
        "variants": [
          "3KW (LITHIUM BATTERY)",
          "NM 3KW (LITHIUM BATTERY)"
        ]
      },
      {
        "model": "AERORIDE AERO",
        "variants": [
          "250 WATT (LEAD ACID BATTERY)",
          "250 WATT (LITHIUM BATTERY)"
        ]
      },
      {
        "model": "E SPARK",
        "variants": [
          "250 WATT (LEAD ACID BATTERY)",
          "250 WATT (LITHIUM BATTERY)",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "YB 2000",
        "variants": [
          "LITHIUM BATTERY 72V 30AH",
          "LITHIUM BATTERY 72V 36AH",
          "LITHIUM BATTERY 72V 26AH"
        ]
      }
    ]
  },
  {
    "make": "GEM MOTORS",
    "models": [
      {
        "model": "MARATHON",
        "variants": [
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "GEMOPAI",
    "models": [
      {
        "model": "RYDER",
        "variants": [
          "STD",
          "48 V 26 AH",
          "48 V 30 AH",
          "48 V 40 AH",
          "STANDARD",
          "SUPER MAX",
          "E BIKE"
        ]
      },
      {
        "model": "ASTRID LITE",
        "variants": [
          "STD",
          "72 V 24 AH",
          "72 V 30 AH",
          "72 V 40 AH",
          "STANDARD",
          "E BIKE"
        ]
      },
      {
        "model": "MISO",
        "variants": [
          "STANDARD",
          "48 V 17 AH",
          "21 AH",
          "21 AH WITHOUT CARRIER",
          "48 V 17 AH 48 KG",
          "STANDARD",
          "LITHIUM BATTERY 60V 17.5AH"
        ]
      },
      {
        "model": "RYDER SUPERMAX",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "GEMOPAI ELECTRIC SCOOTERS",
    "models": [
      {
        "model": "ASTRID",
        "variants": [
          "ELECTRIC",
          "30AH",
          "40AH",
          "LITE",
          "LITE 24AH",
          "LITE 72V 42AH",
          "LITHIUM 72V 40AH"
        ]
      },
      {
        "model": "RYDER",
        "variants": [
          "ELECTRIC",
          "48V 21AH",
          "48V 40AH",
          "48V 30AH",
          "SUPER MAX"
        ]
      },
      {
        "model": "MISO",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "GEO ALLIANCE",
    "models": [
      {
        "model": "GENIUS",
        "variants": [
          "LEAD ACID 60V 48AH"
        ]
      },
      {
        "model": "HURRY",
        "variants": [
          "LEAD ACID 60V 48AH"
        ]
      },
      {
        "model": "G PLUS PLUS PLUS",
        "variants": [
          "LEAD ACID 60V 48AH"
        ]
      },
      {
        "model": "JISOKU",
        "variants": [
          "LEAD ACID 60V 48AH"
        ]
      }
    ]
  },
  {
    "make": "GIANTCO",
    "models": [
      {
        "model": "HUNTER",
        "variants": [
          "CITY",
          "SPORT"
        ]
      }
    ]
  },
  {
    "make": "GKON",
    "models": [
      {
        "model": "ROADIES",
        "variants": [
          "VS 48V",
          "VS 60V",
          "JOY 48V",
          "JOY 60V",
          "LX 48V",
          "LX 60V",
          "NE 48V",
          "NE 60V",
          "60V/28AH LA"
        ]
      }
    ]
  },
  {
    "make": "GKON ELECTRIC VEHICLES PVT LTD",
    "models": [
      {
        "model": "GKON ROADIES",
        "variants": [
          "60V SCOOTY"
        ]
      },
      {
        "model": "STAR VESPA",
        "variants": [
          "LEAD ACID 72V"
        ]
      }
    ]
  },
  {
    "make": "GLADGURU",
    "models": [
      {
        "model": "BASIC",
        "variants": [
          "LITHIUM 48V 28AH",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "BASIC PLUS",
        "variants": [
          "LITHIUM 60V 30AH",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "SMART",
        "variants": [
          "LITHIUM 60V 34AH",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "PREMIUM",
        "variants": [
          "LITHIUM 60V 34AH",
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "ELECTER PRO",
        "variants": [
          "LITHIUM 60V 34AH",
          "LITHIUM 48V 24AH",
          "LITHIUM 60V 30AH",
          "LI 60V30AH 1KW"
        ]
      },
      {
        "model": "ELECTER",
        "variants": [
          "LITHIUM 60V 34AH",
          "LITHIUM 48V 24AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ELECTER MAX",
        "variants": [
          "LITHIUM 48V 28AH",
          "LITHIUM 48V 24AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 34AH"
        ]
      },
      {
        "model": "ELECTER PLUS",
        "variants": [
          "LITHIUM 48V 24AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 34AH"
        ]
      },
      {
        "model": "MANIA",
        "variants": [
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 34AH"
        ]
      },
      {
        "model": "MANIA PRO",
        "variants": [
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 34AH"
        ]
      },
      {
        "model": "ELECTER MAX PLUS",
        "variants": [
          "LITHIUM 60V 50AH"
        ]
      },
      {
        "model": "ELECTER CARRIER",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ELECTER PLUS PLUS",
        "variants": [
          "LITHIUM 60V 40AH",
          "LITHIUM 60V 50AH"
        ]
      },
      {
        "model": "RX 100",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ELECTER MAX PRO",
        "variants": [
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 34AH"
        ]
      }
    ]
  },
  {
    "make": "GLOBAL",
    "models": [
      {
        "model": "G 100",
        "variants": [
          "SELF START",
          "ELECTRIC START ALLOY WHEEL DRUM BRAKE"
        ]
      },
      {
        "model": "ROCK 100",
        "variants": [
          "MOTORCYCLE",
          "STD",
          "STD"
        ]
      },
      {
        "model": "UFO 0150",
        "variants": [
          "SCOOTER",
          "STD"
        ]
      },
      {
        "model": "XPRESSION PLUS",
        "variants": [
          "SELF START",
          "STD",
          "ELECTRIC START ALLOY WHEEL DISK BRACK"
        ]
      },
      {
        "model": "XPRESSION",
        "variants": [
          "STD",
          "STD"
        ]
      }
    ]
  },
  {
    "make": "GLOBAL AUTOMOBILES",
    "models": [
      {
        "model": "XPRESSION PLUS",
        "variants": [
          "124CC"
        ]
      },
      {
        "model": "ROCK 100",
        "variants": [
          "97.2 CC"
        ]
      },
      {
        "model": "UFO",
        "variants": [
          "150",
          "UFO 150"
        ]
      },
      {
        "model": "ROCK",
        "variants": [
          "ROCK 100"
        ]
      },
      {
        "model": "XPRESSION",
        "variants": [
          "PLUS"
        ]
      }
    ]
  },
  {
    "make": "GO ECO TRANS SOLUTIONS",
    "models": [
      {
        "model": "PRASINOS 250",
        "variants": [
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "PRASINOS EASY",
        "variants": [
          "LITHIUM BATTERY 60V 24AH"
        ]
      }
    ]
  },
  {
    "make": "GO GREEN",
    "models": [
      {
        "model": "KIMAYA",
        "variants": [
          "ELECTRIC BIKE"
        ]
      },
      {
        "model": "KAVACH",
        "variants": [
          "ELECTRIC BIKE"
        ]
      },
      {
        "model": "KOHRA",
        "variants": [
          "ELECTRIC BIKE"
        ]
      },
      {
        "model": "SUNOTI",
        "variants": [
          "ELECTRIC BIKE"
        ]
      }
    ]
  },
  {
    "make": "GO GREENBOV",
    "models": [
      {
        "model": "SUNOTI",
        "variants": [
          "V1",
          "V2"
        ]
      }
    ]
  },
  {
    "make": "GO WITH GREEN",
    "models": [
      {
        "model": "CHALO",
        "variants": [
          "1000 E BIKE",
          "LEAD 48V 28AH",
          "1000 V2 LITHIUM 60V 35AH",
          "V2 LITHIUM 48V 24AH",
          "1000 LEAD 72V 28AH"
        ]
      },
      {
        "model": "CHALO V2",
        "variants": [
          "LITHIUM BATTERY 60V 35AH 250WATTA"
        ]
      }
    ]
  },
  {
    "make": "GOEEN",
    "models": [
      {
        "model": "CHALO",
        "variants": [
          "48V",
          "V2",
          "1000",
          "1000V2"
        ]
      }
    ]
  },
  {
    "make": "GOREEN E MOBILITY PVT LTD",
    "models": [
      {
        "model": "TDR 360Z ASTRID LITE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "RYDER",
        "variants": [
          "48V/24AH",
          "48V/26AH",
          "48V/29AH"
        ]
      },
      {
        "model": "MISO",
        "variants": [
          "E BIKE",
          "01 E BIKE"
        ]
      }
    ]
  },
  {
    "make": "GOWEL",
    "models": [
      {
        "model": "GOWEL ZX",
        "variants": [
          "STD",
          "LI 48V 24AH"
        ]
      },
      {
        "model": "ZX",
        "variants": [
          "LI"
        ]
      }
    ]
  },
  {
    "make": "GOWEL ELECTRIC VEHICLES",
    "models": [
      {
        "model": "ZX",
        "variants": [
          "STD",
          "LI"
        ]
      }
    ]
  },
  {
    "make": "GRAVITY",
    "models": [
      {
        "model": "G LITE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "G RAZOR",
        "variants": [
          "48V 30 AH"
        ]
      },
      {
        "model": "G ONE",
        "variants": [
          "48V 30 AH"
        ]
      },
      {
        "model": "G-RAZOR",
        "variants": [
          "E-SCOOTER"
        ]
      },
      {
        "model": "G-ONE",
        "variants": [
          "E-SCOOTER"
        ]
      },
      {
        "model": "G-LITE",
        "variants": [
          "E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "GRAVITY ENERGY",
    "models": [
      {
        "model": "G LITE",
        "variants": [
          "LITHIUM BATTERY 48V 30AH"
        ]
      }
    ]
  },
  {
    "make": "GREEN ELECTRIC",
    "models": [
      {
        "model": "FLAMZ",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "GREEN POWER",
    "models": [
      {
        "model": "STAR X",
        "variants": [
          "SCOOTER",
          "PLUS",
          "PLUS LITHIUM",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "STAR 2020",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "EARTH",
        "variants": [
          "SCOOTER",
          "LITHIUM",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "STAR",
        "variants": [
          "PLUS",
          "Z",
          "Z 60V 30AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "LEAD ACID",
          "LITHIUM",
          "LITHIUM BATTERY 60V 28AH",
          "LEAD ACID BATTERY 60V 28 AH"
        ]
      },
      {
        "model": "STAR PLUS",
        "variants": [
          "60V + LEAD ACID (GEL)",
          "72V + LEAD ACID (GEL)",
          "60V + LITHIUM",
          "72V + LITHIUM",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "ULTRON",
        "variants": [
          "60V + LEAD ACID (GEL)",
          "60V + LITHIUM",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "STAR X PLUS",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "STAR Z",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "GREEN POWER EV",
    "models": [
      {
        "model": "EARTH",
        "variants": [
          "60V",
          "72V"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "60V",
          "72V"
        ]
      },
      {
        "model": "STAR",
        "variants": [
          "X PLUS",
          "X",
          "PLUS 60V",
          "PLUS 72V",
          "Z",
          "60V/28AH LI"
        ]
      },
      {
        "model": "ULTRON",
        "variants": [
          "E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "GREEN SHUTTLE TECHNOLOGY PVT LTD",
    "models": [
      {
        "model": "E APIS",
        "variants": [
          "LITHIUM ION  72V 30AH"
        ]
      }
    ]
  },
  {
    "make": "GREEN VECTOR MOTOR INDIA",
    "models": [
      {
        "model": "HARRY PLUS",
        "variants": [
          "LITHIUM ION  60V 25AH",
          "LITHIUM ION  48V 25AH"
        ]
      }
    ]
  },
  {
    "make": "GREEN VICTOR MOTOR",
    "models": [
      {
        "model": "IVIBE",
        "variants": [
          "LITHIUM ION 48V 26AH",
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "MAYUR",
        "variants": [
          "LITHIUM ION 48V 26AH",
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "HARRY",
        "variants": [
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 48V 26AH"
        ]
      }
    ]
  },
  {
    "make": "GRETA",
    "models": [
      {
        "model": "HARPER",
        "variants": [
          "V2 48V 24AH",
          "V3 48V 30AH",
          "V2 PLUS 60V 24AH",
          "V3 PLUS 60V 30AH",
          "ZX V2 48V 24AH",
          "ZX V3 48V 30AH",
          "ZX V2 PLUS 60V 24AH",
          "ZX V3 PLUS 60V 30AH",
          "V2 (48V-24AH)",
          "V3 (48V-30AH)",
          "V2+ (60V 24AH)",
          "V3+ (60V-30AH)",
          "ZX V2 (48V-24AH)",
          "ZX V3 (48V-30AH)",
          "ZX V2+ (60V-24AH)",
          "ZX V3+ (60V-30AH)",
          "ZX SERIES-I STD"
        ]
      },
      {
        "model": "EVESPA",
        "variants": [
          "V2 48V 24AH",
          "V3 48V 30AH",
          "V2 PLUS 60V 24AH",
          "V3 PLUS 60V 30AH",
          "V3 (48V-30AH)",
          "V3+ (60V-30AH)",
          "V2 (48V-24AH)",
          "V2+ (60V-24AH)"
        ]
      },
      {
        "model": "GLIDE",
        "variants": [
          "V2 48V 24AH",
          "V3 48V 30AH",
          "V2 PLUS 60V 24AH",
          "V3 PLUS 60V 30AH",
          "V2 (48V-24AH)",
          "V3 (48V-30AH)",
          "V2+ (60V-24AH)",
          "V3+ (60V-30AH)"
        ]
      }
    ]
  },
  {
    "make": "GRG SMART VEHICLE",
    "models": [
      {
        "model": "GOWEL MOPING",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "GOWEL DUAL",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "GOWEL ZX",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "GOWEL ZX LI",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "GOWEL ER PLUS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "GOWEL ECO",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "GRP",
    "models": [
      {
        "model": "11 PRO",
        "variants": [
          "LITHIUM 60V 30AH",
          "LITHIUM 48V 45AH"
        ]
      },
      {
        "model": "11 MINI",
        "variants": [
          "LITHIUM 48V 24AH",
          "LITHIUM 60V 26AH"
        ]
      },
      {
        "model": "11 MAXX",
        "variants": [
          "LITHIUM 48V 30AH",
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "GRP ELECTRIC VEHICLES",
    "models": [
      {
        "model": "GRP 11 MINI",
        "variants": [
          "LITHIUM ION 60V / 26AH"
        ]
      },
      {
        "model": "11 MAXX",
        "variants": [
          "LITHIUM BATERY 60V 30AH"
        ]
      },
      {
        "model": "11 PRO",
        "variants": [
          "LITHIUM BATERY 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "GRP EV",
    "models": [
      {
        "model": "11 MAXX",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "11 MINI",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "11 PRO",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "GRUSHIE ENERGY",
    "models": [
      {
        "model": "GLYDE",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "KRUZE",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "GT FORCE",
    "models": [
      {
        "model": "GT PRIME PLUS LITIUM ION 60V 26AH GREY",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "DRIVE PRO",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V",
          "LITHIUM ION 48V",
          "LITHIUM ION 60V",
          "48V LEAD ACID",
          "48V LITHIUM ION"
        ]
      },
      {
        "model": "GT FLYING",
        "variants": [
          "LEAD ACID BATTERY",
          "LEAD ACID (48V / 27AH)",
          "LITHIUM (48V / 24AH)",
          "60V 26AH",
          "52V 30AH"
        ]
      },
      {
        "model": "GT ONE",
        "variants": [
          "LEAD ACID BATTERY",
          "LEAD ACID (60V / 27AH)",
          "LITHIUM (60V / 24AH)",
          "LITHIUM (60V / 30AH)",
          "LITHIUM (48V / 26AH)",
          "LITHIUM (48V / 30AH)",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GT ONE PLUS",
        "variants": [
          "LEAD ACID BATTERY",
          "LEAD ACID (60V / 27AH)",
          "LITHIUM (60V / 24AH)",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GT SOUL",
        "variants": [
          "LEAD ACID 48V 27AH",
          "LITHIUM (60V / 30AH)",
          "LEAD ACID 60V28AH",
          "60 V 28 AH VRLA BATTERY"
        ]
      },
      {
        "model": "GT ONE PRIME",
        "variants": [
          "LEAD ACID 48V 27AH"
        ]
      },
      {
        "model": "GT ONE PRIME PLUS",
        "variants": [
          "LEAD ACID 48V 27AH"
        ]
      },
      {
        "model": "GT SPORT",
        "variants": [
          "LEAD ACID 48V 27AH",
          "LI ON 60V 30AH 17S"
        ]
      },
      {
        "model": "GT DRIVE PRO",
        "variants": [
          "LITHIUM ION 62.9V 30AH",
          "LITHIUM ION 60V 26AH"
        ]
      },
      {
        "model": "GT DRIVE PLUS",
        "variants": [
          "LITHIUM 60V 42AH"
        ]
      },
      {
        "model": "GT VEGAS",
        "variants": [
          "LITHIUM 60V 30AH",
          "LEAD 60V"
        ]
      },
      {
        "model": "ONE PLUS",
        "variants": [
          "PRO ELECTRIC",
          "LEAD ACID",
          "LI-ION"
        ]
      },
      {
        "model": "ONE PRO",
        "variants": [
          "ELECTRIC LOADER",
          "LITHIUM ION"
        ]
      },
      {
        "model": "DRIVE",
        "variants": [
          "60V LITHIUM ION",
          "PLUS 60V"
        ]
      },
      {
        "model": "GT5",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "SPORT",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "VEGAS",
        "variants": [
          "60V",
          "48V"
        ]
      },
      {
        "model": "FLYING",
        "variants": [
          "LEAD ACID",
          "LI-ION",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "SOUL",
        "variants": [
          "SOUL",
          "LI-ION"
        ]
      },
      {
        "model": "ONE",
        "variants": [
          "LEAD ACID",
          "LI-ION"
        ]
      },
      {
        "model": "PRIME PLUS",
        "variants": [
          "LEAD ACID",
          "LI-ION"
        ]
      },
      {
        "model": "GT PRIME PLUS",
        "variants": [
          "LITTIUM BATTERY 60V 42AH",
          "48 V 28 AH VRLA BATTERY"
        ]
      }
    ]
  },
  {
    "make": "GUJARAT NARMADA AUTO",
    "models": [
      {
        "model": "NARMADA",
        "variants": [
          "150 CC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "HARLEY DAVIDSON",
    "models": [
      {
        "model": "CVO",
        "variants": [
          "LIMITED",
          "LIMITED",
          "ULTRA LIMITED",
          "ELECTRA GLIDE",
          "ULTRA CLASSIC ELECTRA GLIDE",
          "FLHTKSE LIMITED",
          "ULTRA CLASSIC ELECTRA GUIDE"
        ]
      },
      {
        "model": "DYNA",
        "variants": [
          "FAT BOB",
          "STREET BOB",
          "SUPER GLIDE",
          "SUPER GLIDE",
          "FAT BOB",
          "STD",
          "FXDB STREET BOB",
          "FXDF FAT BOB",
          "CKD - FXDC SUPER GLIDE CUSTOM",
          "FXDC SUPER GLIDE CUSTOM"
        ]
      },
      {
        "model": "883 ROADSTER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "BREAKOUT",
        "variants": [
          "STANDARD",
          "STD",
          "103"
        ]
      },
      {
        "model": "CVO LIMITED",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "DELUXE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "FAT BOB",
        "variants": [
          "STANDARD",
          "1585CC",
          "STD"
        ]
      },
      {
        "model": "FAT BOY 114",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "FAT BOY",
        "variants": [
          "1690CC",
          "STANDARD",
          "FLSTF FAT BOY"
        ]
      },
      {
        "model": "HERITAGE CLASSIC",
        "variants": [
          "STANDARD",
          "1690CC",
          "STD",
          "CLASSIC"
        ]
      },
      {
        "model": "NIGHT ROD",
        "variants": [
          "SPECIAL"
        ]
      },
      {
        "model": "NIGHTSTER",
        "variants": [
          "STANDARD",
          "STD",
          "STANDARD"
        ]
      },
      {
        "model": "ROAD KING",
        "variants": [
          "STANDARD",
          "FLHR ROAD KING",
          "TOURING MOTORCYCLE"
        ]
      },
      {
        "model": "ROADSTER XL 883 R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SOFTTAIL FATBOY",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPORTSTER XL 1200 N",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPORTSTER XL 883 R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPORTSTER XR 1200X",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "STREET 750",
        "variants": [
          "ABS",
          "10TH ANNIVERSARY EDITION",
          "STANDARD"
        ]
      },
      {
        "model": "STREET BOB",
        "variants": [
          "STANDARD",
          "1585CC",
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "SUPER GLIDE CUSTOM",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "SUPERLOW",
        "variants": [
          "STANDARD",
          "XL883L"
        ]
      },
      {
        "model": "ULTRA CLASSIC ELECTRA GLIDE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "V ROD",
        "variants": [
          "STANDARD",
          "NIGHT ROD SPECIAL"
        ]
      },
      {
        "model": "STREET GLIDE SPECIAL",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "LOW RIDER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "FAT BOY SPECIAL",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "FORTY EIGHT SPECIAL",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "LOW RIDER S",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "FXD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STREET GLIDE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SOFTTAIL",
        "variants": [
          "BREAKOUT",
          "CLASSIC HERITAGE",
          "FAT BOY",
          "FATBOY SPECIAL",
          "SPRINGER CLASSIC",
          "FXSB BREAKOUT",
          "DELUXE",
          "FX LOW RIDER",
          "FLFB FATBOY",
          "FATBOY SPECIAL",
          "FXFB FAT BOB",
          "CBU - FLSTF FAT BOY",
          "CLASSIC FLSTC HERITAGE",
          "CKD - CLASSIC FLSTC HERITAGE",
          "CKD - FLSTF FAT BOY",
          "CKD - FATBOY SPECIAL",
          "FLSTF FAT BOY"
        ]
      },
      {
        "model": "SPORTSTER",
        "variants": [
          "1200 CUSTOM",
          "XL1200X FORTY EIGHT",
          "IRON 883",
          "SUPERLOW 1200T",
          "SUPERLOW XL 883",
          "XL 1200 C",
          "XL 883L",
          "XR 1200X",
          "IRON 883 BSIV",
          "XL 1200C CUSTOM",
          "XL 1200X FORTY-EIGHT ANV",
          "XL 1200X FORTY-EIGHT",
          "XL 1200CX ROADSTER",
          "XR 1200X",
          "883N IRON",
          "883L CKD",
          "XL1200N NIGHTSTER",
          "883R",
          "883R ROADSTER",
          "XL883 SUPERLOW",
          "SEVENTY-TWO",
          "XR 1200X",
          "XL 1200 C CUSTOM",
          "XL 883N 2 SEAT",
          "SPORT",
          "LOW",
          "XL 883 C",
          "XL883R ROADSTER",
          "XL 883N"
        ]
      },
      {
        "model": "STREET",
        "variants": [
          "750 STD",
          "BOB",
          "GLIDE",
          "750 PREMIUM BSIV",
          "750 BSIV",
          "ROD PREMIUM",
          "750",
          "ROD",
          "750",
          "ROD",
          "XG 750"
        ]
      },
      {
        "model": "TOURING",
        "variants": [
          "ROAD KING",
          "STREET GLIDE",
          "FLHX STREET GLIDE SPECIAL",
          "FLHR ROAD KING",
          "FLHX STREET GLIDE"
        ]
      },
      {
        "model": "V-ROD",
        "variants": [
          "NIGHT ROD SPECIAL"
        ]
      },
      {
        "model": "FORTY EIGHT",
        "variants": [
          "XL 1200 X",
          "STANDARD",
          "1200",
          "STD"
        ]
      },
      {
        "model": "FX STANDARD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SOFTAIL",
        "variants": [
          "FLSTC HERITAGE SOFTAIL CLASSIC",
          "SOFTAIL FLSTF FAT BOY",
          "STREET BOB PREMIUM",
          "STREET BOB",
          "FAT BOB",
          "FAT BOY PREMIUM",
          "FAT BOY",
          "FAT BOY ANNI 114",
          "SOFTAIL DELUXE",
          "HERITAGE CLASSIC BSIV",
          "LOW RIDER",
          "HERITAGE CLASSIC",
          "STANDARD",
          "FATBOB",
          "SOFTAIL FATBOY",
          "FXLRS LOW RIDER"
        ]
      },
      {
        "model": "ROAD GLIDE",
        "variants": [
          "STD SPECIAL",
          "WHITE PEARL",
          "SPECIAL"
        ]
      },
      {
        "model": "PAN AMERICA",
        "variants": [
          "ADVENTURE TOURING",
          "STD",
          "SPECIAL"
        ]
      },
      {
        "model": "ELECTRA GLIDE",
        "variants": [
          "TOURING ELECTRA GLIDE",
          "STD"
        ]
      },
      {
        "model": "STREET XG",
        "variants": [
          "STREET XG"
        ]
      },
      {
        "model": "VRSCD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VRSCA VROD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SONSTIGE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLSTC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ROCKER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLSTER HERITAGE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XL",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XL 883N",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XL883 L SPORTSTER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XL 1200 X",
        "variants": [
          "STD",
          "BSIII",
          "FORTY EIGHT"
        ]
      },
      {
        "model": "PROTO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FX DB STREET BOB",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FXDF FAT BOB",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLSTFB FAT BOY",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLSTC HERITAGE SOFTAIL CLASSIC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "IRON 883",
        "variants": [
          "STD",
          "STD",
          "STANDARD"
        ]
      },
      {
        "model": "FX DC SUPER GLIDE CUSTOM",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLHTCUSE ULTRA CLASSIC ELECTRA GLIDE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLHR ROAD KING",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XR1200 X",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VRSCDX NIGHT ROD SPECIAL",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XL1200 N NIGHTSTER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XL883 R ROADSTER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XL 883 R SPORTSTER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLSTF FATBOY",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLHX STRRET GLIDE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CHASS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLSTF FATBOT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "883 SUPERLOW",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CVO EDITION",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "FATBOB",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "FATBOY",
        "variants": [
          "STD 1690CC",
          "STD 1745 CC",
          "STD 1868CC",
          "STD",
          "MOTORCYCLE",
          "MILWAUKEE-EIGHT 114",
          "MILWAUKEE-EIGHT 107",
          "MOTORCYCLE (2007)"
        ]
      },
      {
        "model": "FLSTC HERITAGE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLSTF",
        "variants": [
          "FAT BOY"
        ]
      },
      {
        "model": "FXDC SUPER GLIDE",
        "variants": [
          "CUSTOM"
        ]
      },
      {
        "model": "IRON",
        "variants": [
          "883"
        ]
      },
      {
        "model": "NIGHTROD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ROADKING",
        "variants": [
          "STD",
          "1690 CC"
        ]
      },
      {
        "model": "SOFTAIL CLASSIC",
        "variants": [
          "FLSTF FAT BOY",
          "STD"
        ]
      },
      {
        "model": "STREETGLIDE",
        "variants": [
          "SPECIAL",
          "STD"
        ]
      },
      {
        "model": "SUPER GLIDE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VRSCA V-ROD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VRSCDX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XG750",
        "variants": [
          "STREET 750",
          "STREET ROD"
        ]
      },
      {
        "model": "XL 1200N NIGHTSTER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XL883R ROADSTER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XR1200X",
        "variants": [
          "STD",
          "STANDARD"
        ]
      },
      {
        "model": "XL1200",
        "variants": [
          "FORTY-EIGHT",
          "CX ROADSTER",
          "SPORTSTER",
          "NEIGHTSTER"
        ]
      },
      {
        "model": "ROAD GLIDE SPECIAL",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SOFTAIL DELUXE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SOFTAIL LOW RIDER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "HERITAGE SOFTAIL CLASSIC",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPORTSTER XL 883 L",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "STREET ROD",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "ROADSTER",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "1200 CUSTOM",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "X440",
        "variants": [
          "DENIM",
          "S",
          "VIVID"
        ]
      },
      {
        "model": "VFR",
        "variants": [
          "800 SRD"
        ]
      },
      {
        "model": "XR 1200",
        "variants": [
          "X"
        ]
      },
      {
        "model": "HERITAGE",
        "variants": [
          "CLASSIC"
        ]
      },
      {
        "model": "ROAD KING CLASSIC",
        "variants": [
          "TOURING MOTORCYCLE"
        ]
      }
    ]
  },
  {
    "make": "HAYASA",
    "models": [
      {
        "model": "IRA",
        "variants": [
          "EV SCOOTER",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "DAKSHA",
        "variants": [
          "EV SCOOTER",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "NIRBHAR",
        "variants": [
          "STANDARD",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "OJAS",
        "variants": [
          "STANDARD",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "VIJAY 2000",
        "variants": [
          "EV"
        ]
      },
      {
        "model": "VIJAY",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "HAYASA E MOBILITY",
    "models": [
      {
        "model": "IRA",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "DAKSHA",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "OJAS",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "NIRBHAR",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "HCD",
    "models": [
      {
        "model": "NPS CARGO",
        "variants": [
          "LITHIUM 48V 29AH"
        ]
      }
    ]
  },
  {
    "make": "HCD INDIA",
    "models": [
      {
        "model": "NPS CARGO",
        "variants": [
          "48V",
          "60V",
          "48V",
          "60V"
        ]
      }
    ]
  },
  {
    "make": "HERALD BIKE",
    "models": [
      {
        "model": "RIDER DLX",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 60V 34AH",
          "LITHIUM ION 51V 24AH",
          "LITHIUM ION 64V 24AH",
          "LITHIUM ION 64V 30AH",
          "HI SPEED LEAD 48V 28AH",
          "HI SPPED LEAD 60V 28AH",
          "HI SPEED LITHIUM 48V 24AH",
          "HI SPEED LITHIUM 60V 24AH",
          "HI SPEED LITHIUM 60V 30AH",
          "HI SPEED LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "LEGEND DLX",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 60V 34AH",
          "LITHIUM ION 51V 24AH",
          "LITHIUM ION 64V 24AH",
          "LITHIUM ION 64V 30AH",
          "HI SPPED LEAD 60V 28AH",
          "HI SPEED LITHIUM 48V 24AH",
          "HI SPEED LITHIUM 60V 24AH",
          "HI SPEED LITHIUM 60V 30AH",
          "HI SPEED LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "ROYAL",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 60V 34AH",
          "LITHIUM ION 51V 24AH",
          "LITHIUM ION 64V 24AH",
          "LITHIUM ION 64V 30AH"
        ]
      },
      {
        "model": "ROYAL PRO",
        "variants": [
          "HI SPPED LEAD 60V 28AH",
          "HI SPEED LITHIUM 48V 24AH",
          "HI SPEED LITHIUM 60V 24AH",
          "HI SPEED LITHIUM 60V 30AH",
          "HI SPEED LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "ROYAL NEW",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 34AH",
          "LITHIUM ION 51V 24AH",
          "LITHIUM ION 64V 24AH",
          "LITHIUM ION 64V 30AH"
        ]
      },
      {
        "model": "LEGEND SUPER",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 34AH",
          "LITHIUM ION 51V 24AH",
          "LITHIUM ION 64V 24AH",
          "LITHIUM ION 64V 30AH"
        ]
      },
      {
        "model": "LEGEND PRO",
        "variants": [
          "HI SPPED LEAD 60V 28AH",
          "HI SPEED LITHIUM 48V 24AH",
          "HI SPEED LITHIUM 60V 24AH",
          "HI SPEED LITHIUM 60V 30AH",
          "HI SPEED LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "ROYAL PRIME",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 60V 34AH",
          "LITHIUM ION 64V 24AH",
          "LITHIUM ION 64V 30AH"
        ]
      },
      {
        "model": "LEGEND PRIME",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 60V 34AH",
          "LITHIUM ION 64V 24AH",
          "LITHIUM ION 64V 30AH"
        ]
      }
    ]
  },
  {
    "make": "HERALD EBIKE",
    "models": [
      {
        "model": "LEGEND PRO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LEGEND PRIME",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ROYAL PRIME",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ROYAL PRO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ROYAL DLX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MARVEL",
        "variants": [
          "48V/28AH",
          "48V/24AH",
          "48V/30AH",
          "60V/24AH",
          "60V/30AH",
          "60V/34AH",
          "60V/28AH"
        ]
      },
      {
        "model": "RIDER",
        "variants": [
          "48V/28AH",
          "48V/24AH",
          "48V/30AH",
          "60V/24AH",
          "60V/30AH",
          "60V/34AH",
          "60V/28AH"
        ]
      },
      {
        "model": "ROYAL",
        "variants": [
          "48V/28AH",
          "60V/28AH",
          "48V/24AH",
          "60V/34AH"
        ]
      },
      {
        "model": "LEGEND DLX",
        "variants": [
          "48V/28AH",
          "60V/28AH",
          "48V/24AH",
          "48V/30AH",
          "60V/34AH"
        ]
      },
      {
        "model": "LEGEND SUPER",
        "variants": [
          "48V/28AH",
          "60V/28AH",
          "48V/24AH",
          "48V/30AH",
          "60V/34AH"
        ]
      },
      {
        "model": "H100",
        "variants": [
          "60V/24AH",
          "60V/30AH",
          "60V/34AH"
        ]
      }
    ]
  },
  {
    "make": "HERO HONDA",
    "models": [
      {
        "model": "STREET 100",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPLENDOR PLUS",
        "variants": [
          "STANDARD",
          "STD",
          "SPOKE",
          "MAC",
          "KICK START",
          "KICK START DISC BRAKE",
          "SELF START",
          "SPOKE DRUM KICK (97 CC)",
          "CAST DISC SELF",
          "SPOKE DRUM KICK",
          "SPOKE DRUM SELF",
          "SPOKE DISC KICK",
          "CAST DRUM KICK",
          "CAST DRUM SELF"
        ]
      },
      {
        "model": "CD DAWN",
        "variants": [
          "STANDARD",
          "STD",
          "DELUXE",
          "STD",
          "STD",
          "100 CC",
          "STD SPOKE",
          "KICK SPOKE DRUM"
        ]
      },
      {
        "model": "GLF",
        "variants": [
          "DRK",
          "DRS",
          "DSS"
        ]
      },
      {
        "model": "GLR",
        "variants": [
          "DRS",
          "SPOKE DRS"
        ]
      },
      {
        "model": "JOY",
        "variants": [
          "STD",
          "STD",
          "STD",
          "SPOKE DRUM KICK"
        ]
      },
      {
        "model": "PASSION",
        "variants": [
          "STANDARD",
          "DISC BRAKE",
          "STD",
          "STD",
          "PRO",
          "SPOKE KICK DRUM",
          "SPOKE KICK DISC"
        ]
      },
      {
        "model": "PSN",
        "variants": [
          "PLUS"
        ]
      },
      {
        "model": "SLEEK",
        "variants": [
          "STD",
          "KICK START",
          "STD",
          "DLX",
          "SPOKE DRUM KICK"
        ]
      },
      {
        "model": "SPLENDOR",
        "variants": [
          "STANDARD",
          "ISMART",
          "STD",
          "STD",
          "I SMART.",
          "KICK START DRUM BRAKE",
          "NXG",
          "NXG ALLOY WHEEL SELF",
          "NXG DRUM KICK CAST",
          "NXG DRUM SELF CAST",
          "NXG SPOKES WHEEL SELF",
          "PETROL",
          "PLUS",
          "SUPER",
          "SPOKE DRUM KICK"
        ]
      },
      {
        "model": "SPS",
        "variants": [
          ". DRS"
        ]
      },
      {
        "model": "VISOR",
        "variants": [
          "(DRM)",
          "(DRM SELF)",
          "(DSC)",
          "(DSC SELF)"
        ]
      },
      {
        "model": "DRM",
        "variants": [
          "SELF"
        ]
      },
      {
        "model": "DSC",
        "variants": [
          "SELF"
        ]
      },
      {
        "model": "F1",
        "variants": [
          "(DRM)",
          "(DSC SELF)"
        ]
      },
      {
        "model": "SELF",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SWP",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CNS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CBZ",
        "variants": [
          "STAR ES",
          "STAR ES",
          "XTREAM DSK",
          "KICK START",
          "SELF START",
          "STD",
          "XTREME KS",
          "XTREME SS",
          "X-TREME ES",
          "DISC",
          "X-TREME REAR DISC",
          "KICK START",
          "DRUM BREAK KICK START",
          "DISC BREAK SELF START"
        ]
      },
      {
        "model": "CD DELUXE",
        "variants": [
          "DRUM KICK CAST 10",
          "MAC",
          "KICK START",
          "SELF START",
          "STD",
          "ALLOY WHEEL",
          "ALLOY WHEEL SELF",
          "SPOKE WHEEL",
          "SPOKES WHEEL SELF",
          "SELF ALLOY",
          "STANDARD",
          "SPOKE",
          "SPOKE KICK DRUM",
          "CAST SELF DRUM",
          "SPOKE SELF DRUM",
          "CAST KICK DRUM"
        ]
      },
      {
        "model": "SUPER SPLENDOR",
        "variants": [
          "DRUM/SELF",
          "BSIII",
          "KICK START",
          "KICK START DISC BRAKE",
          "SELF START",
          "SELF START DISC BRAKE",
          "STD",
          "ALLOY WHEEL",
          "DRUM SELF CAST",
          "CAST DRUM KICK",
          "SPOKE DRUM KICK",
          "SPOKE DRUM SELF",
          "SPOKE DISC KICK",
          "SPOKE DISC SELF",
          "CAST DRUM SELF"
        ]
      },
      {
        "model": "PASSION PLUS",
        "variants": [
          "ALLOY WHEELS",
          "MAC",
          "ALLOY WHEEL",
          "DISC BRAKE",
          "DISC BRAKE ALLOY WHEEL",
          "STD",
          "SPOKE",
          "ALLOY",
          "ALLOY KICK DISC",
          "SPOKE KICK DRUM",
          "SPOKE KICK DISC",
          "ALLOY KICK DRUM"
        ]
      },
      {
        "model": "PASSION PRO",
        "variants": [
          "CAST SELF",
          "SELF START",
          "SELF START DISC BRAKE",
          "STD",
          "TR",
          "10 DRUM SELF CAST",
          "ALLOY WHEEL",
          "ALLOY WHEEL SELF",
          "DRUM KICK CAST",
          "DRUM KICK SPOKE",
          "DRUM SELF CAST",
          "SPOKES WHEEL",
          "SPOKES WHEEL SELF",
          "STANDARD",
          "CWG EDITION",
          "ELECTRIC START DISC BRAKE SPOKE WHEEL",
          "CAST - DRUM BRAKE ELECTRIC START",
          "SPOKE - DRUM BRAKE",
          "CAST - DRUM BRAKE",
          "SPOKE - DRUM BRAKE ELECTRIC START",
          "CAST - DISC BRAKE- ELECTRIC START"
        ]
      },
      {
        "model": "CD 100",
        "variants": [
          "SS",
          "STD",
          "DELUXE",
          "SS",
          "STD",
          "STD"
        ]
      },
      {
        "model": "SPLENDOR PRO",
        "variants": [
          "STD",
          "KICK START",
          "KICK START",
          "SELF START",
          "ALLOY WHEEL SELF",
          "DRUM KICK CAST",
          "DRUM KICK SPOKE",
          "DRUM SELF CAST",
          "DRUM SELF SPOKE",
          "SPOKES WHEEL",
          "STD",
          "CAST DRUM KICK",
          "CAST DRUM SELF",
          "SPOKE DRUM SELF"
        ]
      },
      {
        "model": "GLAMOUR",
        "variants": [
          "STANDARD",
          "KICK START DISC BRAKE",
          "PGM FI",
          "SELF START DISC BRAKE",
          "STD",
          "124",
          "ALLOY WHEEL SELF",
          "DISC",
          "DISC SELF FI",
          "DRUM KICK FI",
          "ES DISC BRAKE 135",
          "ES DRUM BRAKE",
          "KS DRUM BRAKE",
          "DISC SELF",
          "DRUM SELF",
          "DISK KICK",
          "DRUM KICK"
        ]
      },
      {
        "model": "ACHIEVER",
        "variants": [
          "KICK START DISC BRAKE",
          "SELF START DISC BRAKE",
          "STD",
          "150",
          "KS DISC BRAKE",
          "KS DRUM BRAKE",
          "DISC SELF",
          "DRUM KICK",
          "DISK KICK",
          "DRUM SELF",
          "DRUM KICK"
        ]
      },
      {
        "model": "AMBITION",
        "variants": [
          "KICK START",
          "KICK START DISC BRAKE",
          "SELF START",
          "SELF START DISC BRAKE",
          "STD",
          "DRUM SELF",
          "DISC SELF",
          "DISC KICK",
          "DRUM KICK",
          "DRUM KICK",
          "DISC KICK",
          "DISK SELF",
          "DRUM SELF"
        ]
      },
      {
        "model": "CBX",
        "variants": [
          "DISC BRAKE",
          "STD",
          "STD DISC",
          "STD DRUM"
        ]
      },
      {
        "model": "CBZ STAR",
        "variants": [
          "KICK START",
          "SELF START",
          "DRUM BREAK KICK START",
          "DISC BREAK SELF START"
        ]
      },
      {
        "model": "CBZ XTREME",
        "variants": [
          "KICK START",
          "SELF START"
        ]
      },
      {
        "model": "GLAMOUR FI",
        "variants": [
          "DISC BRAKE",
          "DRUM"
        ]
      },
      {
        "model": "HUNK",
        "variants": [
          "KICK START",
          "SELF START",
          "STD",
          "SELF START",
          "KICK START",
          "SINGLE DISC SELF START",
          "DOUBLE DISC SELF START"
        ]
      },
      {
        "model": "KARIZMA",
        "variants": [
          "ZMR PGM FI",
          "SELF DISC CARB",
          "ZMR DISC SELF-FI",
          "BLACK CAST",
          "RED CAST"
        ]
      },
      {
        "model": "PLEASURE",
        "variants": [
          "SELF START",
          "STD",
          "102",
          "SPECIAL EDITION",
          "ES"
        ]
      },
      {
        "model": "SPLENDOR NXG",
        "variants": [
          "KICK",
          "SELF START",
          "STD",
          "STD",
          "SPOKE DRUM KICK",
          "CAST DRUM KICK",
          "SPOKE DRUM SELF",
          "CAST DRUM SELF"
        ]
      },
      {
        "model": "STREET",
        "variants": [
          "KICK START",
          "SELF START",
          "SPOKE DRUM KICK",
          "SPOKE DRUM SELF"
        ]
      },
      {
        "model": "HERO HONDA SPLENDOR PLUS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CNS STD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PASSION 97CC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CD 100.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SPLENDOR.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STREET SMART",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CND3",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CRD100 SUPER DELUXE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CBZ-XTREME",
        "variants": [
          "156",
          "CBM",
          "DSS",
          "ES"
        ]
      },
      {
        "model": "CD",
        "variants": [
          "100",
          "SPOKE WHEEL"
        ]
      },
      {
        "model": "CD100",
        "variants": [
          "SS"
        ]
      },
      {
        "model": "CDN-3",
        "variants": [
          "DRUM KICK CAST",
          "DRUM KICK SPOKE",
          "DRUM SELF CAST",
          "DRUM SELF SPOKE"
        ]
      },
      {
        "model": "GLAMOUR F1",
        "variants": [
          "DISC BRAKE"
        ]
      },
      {
        "model": "SPLENDOR +",
        "variants": [
          "ALLOY WHEEL",
          "DRUM KICK CAST",
          "SPECIAL EDITION",
          "SPOKES WHEEL"
        ]
      },
      {
        "model": "CBZ X TREME",
        "variants": [
          "DISC KICK START (156 CC)",
          "DISC SELF START (156 CC)",
          "DISC BRAKE KICK START (156 CC)",
          "DISC KICK START",
          "DISC SELF START"
        ]
      }
    ]
  },
  {
    "make": "HERO MOTOCORP",
    "models": [
      {
        "model": "NYX",
        "variants": [
          "E5",
          "HX",
          "STANDARD",
          "ER",
          "E2",
          "HS 500 ER",
          "500 BATTERY E BIKE",
          "LIBATIERY E5 E BIKE",
          "LI (X2) E BIKE",
          "PLAIN E BIKE",
          "E5 EBIKE",
          "HS500 ER E BIKE",
          "N23A E BIKE",
          "PRO E BIKE",
          "E5 HX E BIKE",
          "N23A WITHOUT BATTERY",
          "N23 AX E BIKE",
          "N23 A LITHIUM",
          "CX ER",
          "CX",
          "HX E BIKE",
          "STD",
          "E5",
          "ER",
          "LX",
          "CX ER...",
          "HX N61A",
          "HX N23A",
          "CX ER",
          "HX E5 EBIKE",
          "HX",
          "HS500 ER",
          "LI BS6",
          "LI",
          "CX",
          "N51 A",
          "CX ER",
          "E2 LI 48V 28AH 250 WATT",
          "EBIKE",
          "E5 LITHIUM-ION",
          "PRO LITHIUM BATTERY",
          "HS 500 ER",
          "HS 500",
          "ER",
          "N23A"
        ]
      },
      {
        "model": "PHOTON",
        "variants": [
          "STANDARD",
          "HX",
          "LITHIUM-ION",
          "72V",
          "48V",
          "48V LI E BIKE",
          "72V LEAD BIKE",
          "STD",
          "LP E BIKE",
          "LP LITHIUM",
          "HX E BIKE",
          "LP",
          "STD",
          "72V",
          "LITHIUM ION",
          "HX E5 EBIKE",
          "OPTIMA PLUS",
          "72V LEAD",
          "48V LI",
          "OPTIMA",
          "LP",
          "LP LITHIUM BATTERY",
          "E BIKE"
        ]
      },
      {
        "model": "OPTIMA PLUS",
        "variants": [
          "STANDARD",
          "DX LI",
          "EBIKE"
        ]
      },
      {
        "model": "OPTIMA",
        "variants": [
          "HX DUAL BATTERY",
          "LX",
          "LITHIUM-ION",
          "ER",
          "LEAD ACID",
          "E5",
          "CX ER E BIKE",
          "CX E BIKE",
          "CX 2.0",
          "CX 5.0",
          "DX LI E BIKE",
          "WITH GEAR E BIKE",
          "DX E BIKE",
          "PLUS E BIKE",
          "E5 EBIKE",
          "E2 EBIKE",
          "HS 500 EBIKE",
          "HS 500 ER EBIKE",
          "E5",
          "LI",
          "HS 500 ER",
          "PRO",
          "CX DOUBLE BATTERY",
          "CX ER DOUBLE BATTERY",
          "LX",
          "CX SINGLE BATTERY",
          "CX ER SINGLE BATTERY",
          "CX 2.0 LI",
          "CX 5.0 LI",
          "HX E BIKE",
          "HS 500 ER GREY E BIKE",
          "HX DUAL E BIKE",
          "EBIKE",
          "E5",
          "PLUS",
          "STD",
          "DX",
          "HX DUAL BATTERY",
          "CX DUAL BATTERY",
          "CX SINGLE BATTERY",
          "LEAD ACID",
          "CX 2.0",
          "CX 5.0",
          "CX ER...",
          "PRO",
          "CX",
          "HX S12 A",
          "E2S",
          "HX E5 EBIKE",
          "HS 500 ER",
          "PLUS LI",
          "LX",
          "ER",
          "LITHIUM-ION",
          "DX LI",
          "E5 LITHIUM",
          "E2",
          "E5",
          "CX ER LITHIUM BATTERY IS",
          "PLUS",
          "DX",
          "CX LITHIUM BATTERY",
          "CX ER LITHIUM BATTERY",
          "CX 2.0 LITHIUM BATTERY",
          "CX 5.0 LITHIUM BATTERY",
          "PLUS LI",
          "PRO LITHIUM BATTERY",
          "VR LEAD ACID BATTERY",
          "HS 500 ER",
          "NYX  HS 5OO ER",
          "LEAD ACID BATTERY",
          "HS 500",
          "HX S12B LITHIUM ION 51.2V / 30 AH X 2",
          "ER HS 500"
        ]
      },
      {
        "model": "OPTIMA HS500 ER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "EFFY",
        "variants": [
          "STD",
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "MAJESTIC",
        "variants": [
          "STD",
          "STD",
          "KICK START",
          "ANKUR",
          "EFFY",
          "PANTHER",
          "STD"
        ]
      },
      {
        "model": "PANTHER",
        "variants": [
          "4S SUPER",
          "STD 50 CC",
          "4S DLX",
          "STD",
          "SUPER 60"
        ]
      },
      {
        "model": "PUCH",
        "variants": [
          "STD",
          "EZEE",
          "SHAKTI 2G",
          "SHAKTI 3G",
          "SHAKTI AG",
          "TURBO 2 G",
          "2G MANUAL",
          "STD",
          "STING",
          "EZ SX",
          "SHAKTI"
        ]
      },
      {
        "model": "SPLENDOR ISMART",
        "variants": [
          "SELF DRUM SPOKE",
          "ALLOY WHEEL",
          "SELF DRUM ALLOY",
          "STD",
          "110 BSIV",
          "DRUM BSVI",
          "DISC BSVI",
          "F1",
          "DRS",
          "STD"
        ]
      },
      {
        "model": "STEP MATIC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STING",
        "variants": [
          "STD",
          "STD",
          "DLX",
          "STD",
          "STD"
        ]
      },
      {
        "model": "STUDENT",
        "variants": [
          "STD",
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "WINNER",
        "variants": [
          "STD",
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "MAXI",
        "variants": [
          "STANDARD",
          "STD",
          "EBIKE"
        ]
      },
      {
        "model": "MAESTRO",
        "variants": [
          "STANDARD",
          "SPECIAL EDITION",
          "EDGE 125",
          "ELECTRIC START",
          "DLX",
          "DSS DX",
          "STD",
          "DELUXE",
          "110",
          "FWT",
          "109",
          "HAMATADSSDX",
          "DRS",
          "100 MILAN BS6",
          "ELECTRIC START - SCOOTER",
          "125 EDGE I3S SELF START DRUM BRAKE ALLOY WHEEL",
          "125 EDGE I3S SELF START DISC BRAKE ALLOY WHEEL",
          "125 EDGE SELF START DISC BRAKE ALLOY WHEEL FI",
          "EDGE - SELF START DRUM BRAKE",
          "EDGE - SELF START DRUM BRAKE ALLOY WHEEL",
          "SELF START DISC BRAKE ALLOY WHEEL FI BS VI",
          "SELF START DRUM BRAKE ALLOY WHEEL FI BS VI",
          "EDGE 125 DISC BRAKE ALLOY WHEEL BS VI",
          "EDGE 125 DRUM BRAKE ALLOY WHEEL BS VI",
          "EDGE 110 DRUM BRAKE ALLOY WHEEL BS VI",
          "STD",
          "EDGE ZX",
          "EDGE VX",
          "SELF START"
        ]
      },
      {
        "model": "SPLENDOR PRO",
        "variants": [
          "SELF DRUM SPOKE",
          "CLASSIC STANDARD",
          "KICK DRUM ALLOY",
          "SELF DRUM ALLOY",
          "KICK DRUM SPOKE",
          "SELF DRUM BLACK ALLOY",
          "ELEC ST DRUM BRAKE CAST WHEELS",
          "ELEC ST DRUM BRAKE SW",
          "KICK ST DRUM BRAKE CAST WHEELS",
          "KICK START BSIV",
          "SELF START BSIV",
          "CAST SELF I3S IBS FI BSVI",
          "CAST SELF 110 IBS FI BSVI",
          "CAST SELF ADDITION FI BSVI",
          "DRUM SELF I3S FI BSVI",
          "DISC SELF I3S FI BSVI",
          "DRS",
          "CAST",
          "CAST SELF",
          "SPOKE",
          "SPOKES WHEEL SELF",
          "GOLD SE",
          "KS",
          "ALLOY WHEEL SELF",
          "CAST KICK",
          "SPOKE KICK",
          "SPOKE SELF",
          "DRUM SELF",
          "SPOKE WHEEL KICK START DRUM",
          "BLACK ALLOY WHEEL SELF START DRUM",
          "CAST WHEEL SELF START DRUM",
          "CAST WHEEL SELF START",
          "SPOKE WHEEL SELFSTART",
          "CAST WHEEL KICK START DRUM",
          "I3S SELF DRUM CAST WHEEL",
          "CLASSIC KICK START SPOKE WHEEL DRUM BREAK",
          "CLASSIC SELFSTART SPOKE WHEEL DRUM BREAK"
        ]
      },
      {
        "model": "SPLENDOR NXG",
        "variants": [
          "KICK SPOKE",
          "KICK ALLOY",
          "SELF SPOKE",
          "ELECTRIC START",
          "ELEC ST DRUM BRAKE CAST WHEELS",
          "ELEC ST DRUM BRAKE SW",
          "KICK ST DRUM BRAKE CAST WHEELS",
          "KICK ST DRUM BRAKE SPOKE WHEEL",
          "ELEC ST DRUM BRAKE CAST WHEELS",
          "ELEC ST DRUM BRAKE SW",
          "KICK ST DRUM BRAKE CAST WHEELS",
          "KICK ST DRUM BRAKE SPOKE WHEEL",
          "SPOKE",
          "SPOKE DRS",
          "CAST DRS",
          "CAST KICK",
          "CAST SELF",
          "CAST WHEEL SELF START",
          "SPOKE WHEEL SELFSTART",
          "ALLOY WHEEL"
        ]
      },
      {
        "model": "GLAMOUR",
        "variants": [
          "PGM-FI",
          "ALLOY DISC",
          "DRUM BRAKE",
          "PGM-FI DISC SELF CAST",
          "DRUM BS VI",
          "DISC BS VI",
          "BLAZE EDITION - DRUM - BS VI",
          "BLAZE EDITION - DISC - BS VI",
          "DISC XTEC",
          "DISC SELF",
          "XTEC BS6 DRUM CAST FI VI",
          "SELF START",
          "KICK START",
          "ELECTRIC START DISC BRAKE",
          "ELEC START DISC BRAKE",
          "ELEC ST DISC BRAKE CAST WHEEL",
          "ELEC ST DISC BRAKE CAST WHEELS",
          "ELECTRIC START DRUM BRAKE",
          "ELEC ST DRUM BRAKE CAST WHEELS",
          "ELECTRIC START SPOKE WHEELS",
          "KICK ST DISC BRAKE CAST WHEEL",
          "KICK START DRUM BRAKE",
          "DRUM BRAKE BSIV",
          "DISC BRAKE BSIV",
          "I3S DRUM BRAKE",
          "I3S DISC BRAKE",
          "IBS DRUM CAST",
          "DISC BRAKE BSVI",
          "DRS AHO",
          "CAST DRS BSIII",
          "XTEC DRUM BT BS6",
          "XTEC DISC BT BS6",
          "125 STD",
          "125 FI DISC BS6",
          "125 FI DRUM BS6",
          "CAST DISK SELF",
          "CAST DRS",
          "CAST DRUM SELF",
          "CAST DSS",
          "DSS CCR I3S",
          "ES DISC BRAKE",
          "F1 DSS",
          "FI",
          "FI DISC",
          "FI DRS",
          "FI IBS",
          "STD",
          "FI KICK START DISC BRAKE",
          "DISC BRAKE",
          "DRUM",
          "DRUM 100 MILLION EDITION",
          "DISC 100 MILLION EDITION",
          "KS DISC BRAKE",
          "DRUM SELF CAST",
          "DISC SELF CAST",
          "BLAZE EDITION BS6",
          "DISC XTEC",
          "DRUM XTEC",
          "DRS CCR I3S",
          "SX DRS CAST SPORTS",
          "SELF START DISC BRAKE CAST WHEEL",
          "SELF START DRUM BREAK CAST WHEEL",
          "KICK START DISC BREAK CAST WHEEL",
          "I3S SELF DRUM CAST WHEEL",
          "I3S SELF DISC CAST WHEEL",
          "I3S IBS SELF DISC CAST WHEEL",
          "DISC BRAKE SELF START LIMITED EDITION",
          "DRUM BRAKE SELF START LIMITED EDITION",
          "SELF ALLOY DISC 100 MILLION EDITION BS VI",
          "SELF ALLOY DRUM 100 MILLION EDITION BS VI",
          "IBS DRUM SELF CAST",
          "NEW SELF START DRUM BRAKE ALLOY WHEEL",
          "NEW SELF START DISC BRAKE ALLOY WHEEL",
          "FI SELF START DISC BREAK BS VI",
          "BLAZE SELF START DISC BRAKE ALLOY WHEEL FI",
          "BLAZE SELF START DRUM BRAKE ALLOY WHEEL FI",
          "FI SELF START DRUM BREAK BS VI",
          "SELF START DISC BRAKE LIMITED EDITION BS VI",
          "SELF START DRUM BRAKE LIMITED EDITION BS VI",
          "I3S"
        ]
      },
      {
        "model": "KARIZMA ZMR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "HF DELUXE",
        "variants": [
          "SELF SPOKE",
          "SELF ALLOY BS VI",
          "SELF DRUM-CAST",
          "KICK SPOKE BS VI",
          "KICK ALLOY BS VI",
          "SELF-CAST WHEELS",
          "ECO",
          "ELEC ST DRUM BRAKE CAST WHEELS",
          "ELEC ST DRUM BRAKE SW",
          "KICK ST DRUM BRAKE CAST WHEEL",
          "KICK ST DRUM BRAKE SPOKE WHEEL",
          "ECO BSIV",
          "ELEC ST DRUM BRAKE",
          "ELEC ST DRUM BRAKE SW BSIV",
          "KICK ST DRUM BRAKE CAST BSIV",
          "KICK ST DRUM BRAKE SPOKE BSIV",
          "IBS DRUM BRAKE CAST BSIV",
          "I3S IBS DRUM BRAKE CAST BSIV",
          "IBS KICK ST DRUM BRAKE",
          "IBS DRUM BRAKE BSVI",
          "I3S DRUM BRAKE CAST BSVI",
          "KICK START CAST BSVI",
          "KICK CAST FI BSVI",
          "DRS IBS ALL AND BLACK FI BSVI",
          "DRS I3S IBS BLACK FI BSVI",
          "KICK SPOKE FI BSVI",
          "ADRS NON I3S FI BSVI",
          "AIRS I3S FI BSVI",
          "CDRS NON I3S FI BSVI",
          "DRS CS FI BSVI",
          "AHO",
          "SELF",
          "FI I3S DRS CAST",
          "FI DRS CAST",
          "FI DRK CS",
          "KICK DRUM BREAK CAST",
          "100 BLK F1 BSVI",
          "KICK START ALLOY WHEEL",
          "SELF START ALLOY WHEEL",
          "ECO",
          "KICK START",
          "SELF START",
          "ECO BSIV",
          "STD",
          "ALLOY SELF START",
          "ALLOY SELF START BS6",
          "ALLOY SELF START I3S BS6",
          "CAST",
          "CAST S S",
          "IBS I3S",
          "SPOKE",
          "I3S-SELF-DRUM-CAST",
          "KICK ALLOY-IBS",
          "SELF ALLOY-IBS",
          "KICK SPOKE-IBS",
          "SPOKE SS",
          "CAST DRS",
          "HFNDADRSCCR",
          "DRS CAST",
          "SPOKE KICK",
          "KICK CAST BS6",
          "IBS BS6",
          "IBS I3S BS6",
          "KICK START DRUM ALLOY",
          "ALLOY KICK START",
          "I3S"
        ]
      },
      {
        "model": "IGNITOR",
        "variants": [
          "DRUM BRAKE",
          "DISC BRAKE",
          "ELEC ST DISC BRAKE CAST WHEELS",
          "ELEC ST DRUM BRAKE CAST WHEELS",
          "DRS CAST BSIII",
          "SELF START",
          "STD",
          "STD",
          "CAST DISK SELF",
          "125",
          "DISC SELF",
          "CAST DRS",
          "DRS CAST SPORTS RED",
          "CAST SELF",
          "DRUM SELF CAST WHEEL",
          "DISC SELF CAST WHEEL"
        ]
      },
      {
        "model": "SUPER SPLENDOR",
        "variants": [
          "STANDARD",
          "ELECTRIC START",
          "SELF DRUM ALLOY BSVI",
          "SELF DISC ALLOY BS VI",
          "DRUM BLACK AND ACCENT",
          "DISC BLACK AND ACCENT",
          "ELECTIC ST DRUM BRAKE CAST WHL",
          "ELEC ST DRUM BRAKE CAST WHEELS",
          "ELEC ST DRUM BRAKE SW",
          "KICK ST DISC BRAKE SW",
          "KICK ST DRUM BRAKE CAST WHEELS",
          "KICK ST DRUM BRAKE SW",
          "SELF BSIV",
          "I3S SX BSIV",
          "I3S IBS DRUM BRAKE",
          "I3S IBS SX DRUM BRAKE",
          "IBS DRUM BRAKE",
          "DRS I3S IBS FI BSVI",
          "DRS SX I3S IBS FI BSVI",
          "DRUM SELF FI BSVI",
          "DISC SELF FI BSVI",
          "SELF BSIV",
          "DRS CCR BS III",
          "FI DRS BSVI",
          "XTECH DRUM BSVI",
          "XTECH DISC BSVI",
          "I3S DRUM ALLOY",
          "ISMART STANDARD",
          "ALLOY WHEEL SELF",
          "DRUM BS6",
          "I3S CBS",
          "IBS",
          "STD",
          "SPOKES WHEEL SELF",
          "DISC SELF CAST",
          "CAST SELF",
          "DISC BS6",
          "I3S",
          "CANVAS BLACK DISC BSVI",
          "XTEC DRUM",
          "XTEC DISC",
          "SELF START DRUM BREAK CAST WHEEL",
          "NEW IBS SELF START DRUM BRAKE ALLOY WHEEL",
          "I3S SELF START DRUM BREAK CAST WHEEL",
          "SX I3S IBS SELF START DRUM BREAK CAST WHEEL",
          "IBS I3S SELF START DRUM BREAK CAST WHEEL",
          "SELF START DISC BRAKE ALLOY WHEEL FI BSVI",
          "SELF START DRUM BRAKE ALLOY WHEEL FI BSVI",
          "XTEC SELF START DISC BRAKE",
          "XTEC SELF START DRUM BRAKE",
          "IBS SELF START DRUM BREAK CAST WHEEL",
          "CANVAS SELF START DRUM BREAK",
          "CANVAS SELF START DISC BREAK",
          "SELF START DRUM BRAKE ALLOY WHEEL SX",
          "SELF START DISC BRAKE ALLOY WHEEL BS VI",
          "SELF START DRUM BRAKE ALLOY WHEEL BS VI"
        ]
      },
      {
        "model": "SPLENDOR ISMART 110",
        "variants": [
          "STANDARD",
          "DISC FI BS VI",
          "FI BS VI"
        ]
      },
      {
        "model": "EZEE",
        "variants": [
          "SX",
          "SX AUTOMOTIC",
          "EX"
        ]
      },
      {
        "model": "E-SPRINT",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "FLASH",
        "variants": [
          "STANDARD",
          "LITHIUM-ION",
          "LEAD ACID",
          "LX",
          "E5 EBIKE",
          "E2 EBIKE",
          "STD",
          "E5 LI EBIKE",
          "LX",
          "HX E BIKE",
          "EBIKE",
          "STD",
          "LEAD",
          "LI",
          "STD",
          "LX",
          "E2S",
          "LX LITHIUM BATTERY",
          "E5 LITHIUM BATTERY",
          "E5",
          "EBIKE",
          "E2 LITHIUM BATTERY",
          "LI LITHIUM BATTERY"
        ]
      },
      {
        "model": "XTREME 200R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "ELECTRIC",
        "variants": [
          "DASH",
          "ADVANTA",
          "DASH",
          "EASY",
          "EXTRA",
          "FLASH",
          "HI-MOTO",
          "PHOTON",
          "ZIPPY"
        ]
      },
      {
        "model": "PASSION PRO 110",
        "variants": [
          "DRUM 100 MILLION EDITION",
          "DISC BS VI",
          "DRUM BRAKE ALLOY WHEEL",
          "DRUM-IBS",
          "DRUM BS VI",
          "DISC 100 MILLION EDITION"
        ]
      },
      {
        "model": "PLEASURE PLUS",
        "variants": [
          "CAST WHEEL BS VI",
          "XTEC CONNECTED",
          "SHEET METAL WHEEL BS VI",
          "PLATINUM",
          "FI DRS CASR ZCF BSVI",
          "XTEC"
        ]
      },
      {
        "model": "XTREME 160R",
        "variants": [
          "FRONT DISC",
          "STEALTH EDITION 2.0",
          "FI DDS SPORTS BS 6",
          "DUAL DISC"
        ]
      },
      {
        "model": "XPULSE 200",
        "variants": [
          "STANDARD",
          "4V SELF START DISC BRAKE",
          "4V RALLY EDITION",
          "T ABS SELF START DOUBLE DISC BRAKE ALLOY WHEEL",
          "SELF START DISC BRAKE ABS BS VI",
          "FI SELF START DOUBLE DISC BRAKE"
        ]
      },
      {
        "model": "MAESTRO EDGE 125",
        "variants": [
          "DRUM",
          "DISC",
          "ALLOY WHEEL DRUM BS VI",
          "ALLOY WHEEL DISC BS VI",
          "PRISMATIC COLOR TECH BS VI",
          "STEALTH EDITION BS VI"
        ]
      },
      {
        "model": "SPLENDOR",
        "variants": [
          "PLUS I3S",
          "PLUS I3S BSVI",
          "PLUS SELF DRUM CAST",
          "PLUS BS6 I3S DRS CAST",
          "ISMART BSIII",
          "PLUS XTEC",
          "CAST WHEELS",
          "ELECTRIC START DISC BRAKE",
          "KICK START DISC BRAKE",
          "PLUS DISC BRAKE",
          "SPECIAL EDITION",
          "PRO CLASSIC SELF START SPOKE",
          "PRO KICK START ALLOY WHEEL",
          "PRO KICK START SPOKE",
          "PRO SELF START ALLOY WHEEL",
          "ELECTRIC START DRUM BRAKE",
          "I SMART",
          "I SMART STD BS6",
          "NXG CAST",
          "NXG CAST SELF",
          "NXG SPOKES WHEEL",
          "PLUS I3S DRS CAST",
          "PRO CLASSIC",
          "NXG DRUM KICK SPOKE",
          "NXG DRUM SELF SPOKE",
          "I SMART CAST",
          "I SMART SPOKE DRS",
          "I SMART ALLOY",
          "I SMART CAST DRS",
          "I SMART DRUM BS6",
          "I SMART DISC BS6",
          "I SMART 110CC",
          "125"
        ]
      },
      {
        "model": "XPULSE",
        "variants": [
          "200 ABS BSVI",
          "200 4V BSVI",
          "200 CARB",
          "200 FUEL INJECTION",
          "200T",
          "200 4V",
          "200 T BS6",
          "200 SELF START ABS DISC BS6",
          "STANDARD"
        ]
      },
      {
        "model": "VIDA V1",
        "variants": [
          "PLUS",
          "PRO",
          "PRO",
          "PLUS"
        ]
      },
      {
        "model": "MAESTRO EDGE 110",
        "variants": [
          "VX",
          "DRUM BRAKE ALLOY WHEEL VX",
          "ALLOY WHEEL",
          "100 MILLION EDITION"
        ]
      },
      {
        "model": "PASSION PLUS",
        "variants": [
          "I3S",
          "I3S DRS",
          "DRUM BRAKE",
          "KICK ST DISC BRAKE AWS",
          "KICK ST DISC BRAKE SW",
          "KICK ST DRUM BRAKE AWS",
          "KICK ST DRUM BRAKE SW",
          "I3S SELF ST CAST",
          "I3S SELF ST DISC BRAKE",
          "I3S DRS CAST BSVI",
          "E20 BSVI",
          "KICK ST DRUM BRAKE AWS"
        ]
      },
      {
        "model": "XTREME 160R 4V",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "PASSION",
        "variants": [
          "XTEC DRUM ALLOY",
          "XTEC DISC ALLOY",
          "XTEC",
          "XTEC FI DRS",
          "SELF ST CAST",
          "SELF ST DISC BRAKE",
          "XTEC DRUM SELF CAST BSVI",
          "XTEC DISC SELF CAST BSVI",
          "PLUS ALLOY WHEEL",
          "PLUS",
          "STD",
          "PLUS",
          "X PRO",
          "DISC-IBS",
          "PLUS I3S DRS CAST",
          "XTEC DISC BS6",
          "XTEC DRUM BS6",
          "PLUS 100",
          "PLUS E20"
        ]
      },
      {
        "model": "DESTINI PRIME",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "HF DAWN",
        "variants": [
          "STANDARD",
          "97CC",
          "KICK ST DRUM BRAKE SPOKE WHEEL",
          "KICK ST DRUM BRAKE SPOKE BSIV",
          "KICK START",
          "SELF START",
          "STD",
          "97",
          "KICK START DRUM BREAK SPOKE WHEEL"
        ]
      },
      {
        "model": "IMPULSE",
        "variants": [
          "STANDARD",
          "STD",
          "STD",
          "149 CC",
          "SINGLE DISK",
          "HIMPADSSSC",
          "150",
          "DISC SELF SPOKE"
        ]
      },
      {
        "model": "PASSION XPRO",
        "variants": [
          "DISC",
          "DRUM",
          "(CAST DISC SELF)",
          "ELEC ST DISC BRAKE CAST WHEEL",
          "ELEC ST DRUM BRAKE CAST WHEEL",
          "KICK ST DRUM BRAKE SW",
          "CAST DRUM BRAKE ALLOY BSIV",
          "CAST DISC BRAKE ALLOY BSIV",
          "IBS CAST DRUM BRAKE",
          "IBS CAST DISC BRAKE",
          "CAST SELF IBS FI BSVI",
          "CAST DISC SELF IBS FI BSVI",
          "DISC SELF CAST",
          "DRUM KICK CAST",
          "KICK START",
          "SELF START",
          "DRUM SELF SPOKE",
          "DRUM KICK SPOKE",
          "DRUM KICK ALLOY",
          "DRUM SELF ALLOY",
          "DISC SELF ALLOY",
          "DRUM-IBS",
          "DISC-IBS",
          "CAST SELF",
          "CAST SS",
          "HPXPADRSSC",
          "DRS",
          "DRK SCR",
          "DRK CCR",
          "DRS SCR",
          "DRS CCR",
          "DSS CCR",
          "DSS CCR I3S IBS",
          "DRS IBS",
          "DSS IBS",
          "DRUM KICK SPOKE",
          "DRUM KICK CAST",
          "DRUM SELF SPOKE",
          "DRUM SELF CAST",
          "DISC SELF CAST",
          "IBS SELF START DRUM BREAK CAST WHEEL"
        ]
      },
      {
        "model": "PASSION PRO I3S",
        "variants": [
          "SELF DRUM SPOKE",
          "KICK DRUM ALLOY",
          "SELF DISC ALLOY"
        ]
      },
      {
        "model": "HF DELUXE I3S",
        "variants": [
          "IBS",
          "SELF START BS VI",
          "ELEC ST DRUM BRAKE"
        ]
      },
      {
        "model": "XTREME 200S",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPLENDOR PLUS I3S",
        "variants": [
          "STANDARD",
          "IBS",
          "SPECIAL EDITION-IBS"
        ]
      },
      {
        "model": "GLAMOUR I3S",
        "variants": [
          "DRUM BS VI",
          "DISC BS VI"
        ]
      },
      {
        "model": "ACHIEVER 150",
        "variants": [
          "DRUM",
          "DISC",
          "DISC SELF"
        ]
      },
      {
        "model": "GLAMOUR FI",
        "variants": [
          "STANDARD",
          "XTEC",
          "STD",
          "DRUM BRAKE",
          "ELECTRIC START DISC BRAKE",
          "ELEC ST DISC BRAKE CAST WHEELS",
          "FI DISC BRAKE BSIV",
          "IBS DISC BRAKE",
          "CAST SELF IBS FI BSVI",
          "CAST SELF SX IBS FI BSVI",
          "DISC CAST SELF SX IBS FI BSVI",
          "DISC CAST SELF FI IBS BSVI",
          "DISC CAST SELF FI BSVI",
          "I3S IBS FI 100 MILLION BS6",
          "IBS FI DISC 100 MILLION BS6",
          "DRS CAST MAT BSVI",
          "XTEC DRUM CAST IBS I3S",
          "XTEC DISC CAST IBS I3S",
          "DRUM SELF CAST BSVI",
          "DR BLACK ACCENT SS BSVI",
          "SELF START DISC BREAK CAST WHEEL",
          "IBS SELF START DISC BREAK CAST WHEEL",
          "XTEC SELF START DISC BRAKE ALLOY WHEEL",
          "XTEC SELF START DRUM BRAKE ALLOY WHEEL",
          "CANVAS SELF START DRUM BREAK CAST WHEEL",
          "CANVAS SELF START DISC BREAK CAST WHEEL"
        ]
      },
      {
        "model": "HF DELUXE ECO",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "HUNK",
        "variants": [
          "REAR DRUM BRAKE",
          "REAR DISC BRAKE",
          "DOUBLE DISC ELECTRIC START",
          "ELECTRIC START",
          "ELECTRIC START DISC BRAKE",
          "ELEC START FRONT AND REAR DISC",
          "SINGLE DISC ELECTRIC START",
          "DOUBLE DISC BRAKE",
          "STD",
          "DISC FR & RR",
          "DSS",
          "ES",
          "SPECIAL EDITION",
          "LIMITED EDITION",
          "DISC FR",
          "SELF CAST FR DISC",
          "SELF CAST RR DISC",
          "SPECIAL EDITION",
          "LIMITED EDITION",
          "DDS",
          "SINGLE DISK NEW",
          "DUBLE DISK NEW",
          "KS"
        ]
      },
      {
        "model": "PASSION PRO TR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "PLEASURE",
        "variants": [
          "DRUM BRAKE",
          "DRUM BRAKE ALLOY WHEEL",
          "PLUS",
          "ELECTRIC START",
          "ELEC ST DRUM BRAKE",
          "ELEC ST DRUM BRAKE SW",
          "NEW",
          "SHEET METAL ALLOY BSIV",
          "ALLOY WHEELS BSIV",
          "PLUS CAST WHEEL",
          "PLUS CAST METAL WHEEL",
          "IBS",
          "PLUS BSVI",
          "PLUS CAST WHEEL BSVI",
          "PLEASURE SHEET METAL FI BSVI",
          "PLEASURE CAST WHEEL FI BSVI",
          "PLUS SHEET METAL FI BSVI",
          "CAST WHEEL FI BSVI",
          "PLUS LX DRS SM FI BSVI",
          "PLUS VX DRS CAST FI BSVI",
          "PLUS DRS CAST FI LE",
          "PLUS DRS CAST SPORT BSVI",
          "PLUS XTEC CONNECT",
          "PLUS PLATINUM ZX BSVI",
          "STD",
          "PLUS PLATINUM EDITION",
          "STANDARD",
          "DRUM SELF",
          "DRS",
          "PLN DRS",
          "VX BS6",
          "LIMITED EDITION",
          "SELF START DRUM BRAKE ALLOY WHEEL",
          "SELF START DRUM BRAEK",
          "PLUS SELF START DRUM BRAKE SHEET METALWHEEL FI  BSVI",
          "PLUS SELF START DRUM BRAKE ZX BSVI",
          "PLUS XTEC FI SELF START DRUM BRAKE CAST WHEEL ZX PLUS BSVI",
          "PLUS XTEC FI SELF START JUBILANT YELLOW",
          "PLUS SELF START DRUM BRAKE VX BSVI",
          "PLUS SELF START DRUM BRAKE SHEET METAL WHEEL",
          "PLUS SELF START DRUM BRAKE",
          "PLUS SELF START DRUM BRAKE ALLOY WHEEL",
          "PLUS SELF START ALLOY WHEEL FI BS VI",
          "PLUS SELF START DRUM BRAKE LX BSVI",
          "PLUS XTEC FI SELF START DRUM BRAKE CAST"
        ]
      },
      {
        "model": "SPLENDOR PLUS",
        "variants": [
          "KICK DRUM SPOKE",
          "KICK DRUM ALLOY-IBS",
          "BLACK AND ACCENT EDITION",
          "ELEC ST DISC BRAKE SW",
          "ELECTRIC START DRUM BRAKE",
          "ELEC ST DRUM BRAKE CAST WHEELS",
          "KICK ST DISC BRAKE CAST WHEELS",
          "KICK START DRUM BRAKE",
          "KICK ST DRUM BRAKE CAST WHEELS",
          "KICK ST DRUM BRAKE SW",
          "STD",
          "KICK START ALLOY BSIV",
          "SELF START ALLOY BSIV",
          "I3S DRUM BRAKE CAST WHEEL",
          "I3S IBS DRUM BRAKE CAST WHEEL",
          "IBS DRUM BRAKE CAST WHEEL",
          "I3S DRS CAST SPECIAL EDITION",
          "LTD ADDITION NON UBS",
          "I3S CAST WHEEL BSVI",
          "CAST WHEEL BSVI",
          "CAST WHEEL KICK START BSVI",
          "CAST SELF IBS FI BSVI",
          "CAST SELF I3S IBS FI BSVI",
          "CAST SELF SP (USB) FI BSVI",
          "KICK CAST FI BSVI",
          "DRS CAST NON I3S FI BSVI",
          "DRS CAST I3S FI BSVI",
          "SPOCK",
          "CAST SELF DRUM",
          "I3S IBS BS6 FI 100 MILLION",
          "LTD EDITION",
          "BLACK AND ACCENT EDITION",
          "BS6 DRS CAST FI",
          "I3S BLACK AND ACCENT EDITION",
          "MAC",
          "I3S-USB",
          "I3S-USB ALL BLK",
          "XTECH BSVI",
          "KICK START ALLOY WHEEL",
          "SELF START ALLOY WHEEL",
          "KICK DRUM SPOKE-IBS",
          "SELF DRUM ALLOY-IBS",
          "KICK ALLOY BS VI",
          "SELF ALLOY BS VI",
          "SELF ALLOY I3S BS VI",
          "DRUM 100 MILLION EDITION",
          "SPOKE WHEEL KICK START DRUM BREAK",
          "CAST WHEEL KICK START",
          "CAST WHEEL SELF START",
          "SPOKE WHEEL SELFSTART",
          "IBS SELF START DRUM BREAK CAST WHEEL",
          "I3S SELF START DRUM BREAK CAST WHEEL",
          "I3S SELF DRUM CAST WHEEL SPECIAL EDITION",
          "I3S FI SELF START CAST WHEEL CANVAS EDITION BS VI",
          "I3S CUSTOMIZED STICKER",
          "SELF ALLOY DRUM 100 MILLION EDITION BS VI",
          "I3S SELF START DRUM BRAKE ALLOY WHEEL FI BSVI",
          "I3S SELF ALLOY DRUM 100 MILLION EDITION BS VI",
          "SELF START DRUM BRAKE ALLOY WHEEL FI  BSVI",
          "KICK START DRUM BRAKE ALLOY WHEEL FI  BSVI",
          "XTEC I3S DRUM SELF START CAST WHEEL",
          "SELF START DRUM BREAK CAST WHEEL",
          "IBS I3S SELF START DRUM BREAK CAST WHEEL",
          "I3S IBS SELF START DRUM BREAK CAST WHEEL E20",
          "I3S SELF START DRUM BRAKE ALLOY WHEEL",
          "I3S SELF START DISC BRAKE ALLOY WHEEL",
          "CAST WHEEL KICK START DRUM (97 CC)",
          "SELF START DRUM BREAK CAST FI BS VI",
          "I3S FI SELF START CAST WHEEL BS VI",
          "KICK START DRUM BREAK CAST FI BS VI",
          "I3S GOLD EDITION",
          "I3S"
        ]
      },
      {
        "model": "XTREME",
        "variants": [
          "SELF START DISC BRAKE ALLOY WHEEL",
          "SELF START DOUBLE DISC BRAKE ALLOY WHEEL",
          "REAR DISC BRAKE",
          "CBZ",
          "ELECTRIC START DISC BRAKE",
          "ELECTRIC START REAR DRUM BRAKE",
          "SPORTS- ELEC ST DISC BRAKE AWS",
          "SPRTS ELEC ST DOU DISC BRK AWS",
          "SPORTS SINGLE DISC BSIV",
          "SPORTS DOUBLE DISC BSIV",
          "ABS",
          "S REAR DISC",
          "SPORTS REAR DRUM",
          "SPORTS DSS FI BSVI",
          "SPORTS DDS FI BSVI",
          "200R FI BSVI",
          "200S FI BSVI",
          "160R FI DSS SPORTS BSVI",
          "160R FI DDS SPORTS BSVI",
          "FI ABS DDS CAST 100 MILLION",
          "SPORTS REAR DISC SEFF",
          "160R F1 ABS BSVI",
          "160R DISC DRUM CAST",
          "RR DRUM SELF BSIII",
          "160R STEALTH EDITION BSVI",
          "160R STEALTH EDITION 2.0 BSVI",
          "200 S",
          "DOUBLE DISC",
          "SINGLE DISC",
          "SELF START",
          "SPORTS",
          "200 R",
          "200R DISC ABS",
          "DDS",
          "SPORT DDS CCR",
          "160R 100 MILLION EDITION",
          "160R DOUBLE DISC BS6",
          "REAR DRUM BRAKE",
          "160R STEALTH EDITION",
          "160R 4V CONNECTED",
          "160R 4V PRO",
          "DSS",
          "SINGLE DISK",
          "DOUBLE DISK",
          "200 S BS6",
          "160R FRONT DISC BS6",
          "SPORT DSS CCR",
          "160R 4V BS6",
          "200S 4V STANDARD",
          "SPORTS SELF DOUBLE DISC ALLOY",
          "SPORTS SELF DISC ALLOY",
          "SELF CAST REAR DRUM",
          "DISC SELF CAST"
        ]
      },
      {
        "model": "XTREME SPORTS",
        "variants": [
          "FRONT DISC SELF",
          "REAR DISC SELF",
          "DOUBLE DISC",
          "SS DISC",
          "SS DOUBLE DISC"
        ]
      },
      {
        "model": "MAESTRO EDGE",
        "variants": [
          "LX",
          "ELECTRIC START BSIV",
          "DUAL TONE BSIV",
          "DRUM BRAKE",
          "DISC BRAKE",
          "FI",
          "I3S",
          "DISC BRAKE BSVI",
          "VX FI BSVI",
          "ZX ADITION FI BSVI",
          "EDGE 125 DRS FI BSVI",
          "125 DSS FI BSVI",
          "125 FI BSVI",
          "DRS FI CAST BSVI",
          "DSS FI DISC BSVI",
          "DRS FI VX BSVI",
          "DRS FI ZX BSVI",
          "ZX IBS FI 100 MILLION",
          "125 BT",
          "NEW DRUM",
          "EURO 3",
          "CONNECT",
          "125 DISC BS6",
          "125 FI",
          "LX",
          "125 DRUM I3S CARBURETOR",
          "125 DISC I3S CARBURETOR",
          "100 MILLION EDITION",
          "125 STEALTH EDITION BS6",
          "PRISMATIC COLOR TECH BS6",
          "125 MCR BS6",
          "125 VCR BS6",
          "110 ZX BS6",
          "110 VX BS6",
          "110 BSVI DRUM BRAKE ALLOY WHEEL FI",
          "110 BSVI ALLOY WHEEL FI",
          "125 DRUM BS6",
          "125 CON BS6",
          "125 CONNECTED",
          "ZX DRUM",
          "ZX DISC"
        ]
      },
      {
        "model": "PASSION PRO",
        "variants": [
          "KICK DRUM SPOKE",
          "KICK DRUM ALLOY",
          "KICK SPOKE",
          "KICK ALLOY",
          "SELF ALLOY",
          "XTEC",
          "DISC BRAKE",
          "DRUM BRAKE",
          "ELECTRIC START DISC BRAKE",
          "ELEC START DISC BRAKE",
          "ELEC ST DISC BRAKE CAST WHEELS",
          "ELEC ST DISC BRAKE SW",
          "ELEC ST DRUM BRAKE CAST WHEELS",
          "ELEC ST DRUM BRAKE SW",
          "KICK ST DRUM BRAKE CAST WHEELS",
          "KICK ST DRUM BRAKE CCR",
          "KICK ST DRUM BRAKE SPOKE WHEEL",
          "KICK ST DRUM BRAKE SW",
          "CAST DRUM BRAKE SPOKE BSIV",
          "CAST DRUM BRAKE ALLOY BSIV",
          "CAST DISC BRAKE ALLOY BSIV",
          "I3S SELF ST CAST",
          "I3S SELF ST DISC BRAKE",
          "I3S IBS DRUM BRAKE",
          "IBS DRUM BRAKE",
          "I3S IBS DRUM BRAKE",
          "I3S IBS DISC BRAKE",
          "DRUM BSVI",
          "DISC BSVI",
          "I3S DRUM BSVI",
          "I3S DISC BSVI",
          "I3S IBS FI 100 MILLION",
          "I3S IBS FI DISC 100 MILLION",
          "SELF START",
          "STD",
          "CAST SS",
          "FI DRS CAST BSVI",
          "SELF START DISC BRAKE ALLOY",
          "DRUM XTECH BSVI",
          "DISC XTECH BSVI",
          "KICK START DRUM ALLOY",
          "KICK START DRUM SPOKE",
          "KICK START",
          "SELF START DISC ALLOY",
          "I3S",
          "I3S DISK ALLOY",
          "SELF START DRUM ALLOY",
          "STD",
          "CAST SELF",
          "DISC 100 MILLION EDITION",
          "DRUM 100 MILLION EDITION",
          "LTD EDITION",
          "DRUM-SELF-CAST",
          "I3S SELF DRUM ALLOY IBS",
          "I3S SELF DRUM ALLOY",
          "110 DISC BRAKE ALLOY WHEEL",
          "CWG LIMITED EDITION",
          "DRUM SELF SPOKE",
          "DRUM SELF CAST",
          "DISC SELF CAST",
          "DRK SPOKE",
          "DRK CAST",
          "DRS SPOKE",
          "DRS CAST",
          "PASSION PRO HEAVY DRS BHG",
          "TR DS CAST",
          "CAST DISK SELF",
          "CAST KICK",
          "SPOKE KICK",
          "SPOKE SELF",
          "CAST",
          "CAST DISC SELF",
          "110 DRUM BS6",
          "110 DISC BS6",
          "DRUM IBS",
          "DRUM BRAKE ALLOY WHEEL",
          "DRS IBS",
          "110 DRS IBS",
          "DRS I3S",
          "I3S DISC ALLOY",
          "I3S DRUM ALLOY",
          "I3S DISC SELF",
          "XTEC DISC",
          "XTEC DRUM",
          "SELF DISC ALLOY",
          "TR SELF DISC ALLOY",
          "SELF START DRUM BREAK CAST WHEEL",
          "SPOKE KICK DRUM",
          "CAST KICK DRUM",
          "SPOKE SELF DRUM",
          "CAST SELF DRUM",
          "IBS I3S SELF START DRUM BRAKE ALLOY WHEEL",
          "110 CC I3S SELF START DISC BRAKE ALLOY WHEEL",
          "110 CC I3S SELF START DRUM BRAKE ALLOY WHEEL",
          "110 CC IBS SELF START DRUM BRAKE ALLOY WHEEL",
          "SELF START DRUM BREAK CAST WHEEL FI 113 CC",
          "I3S SELF START DRUM BRAKE ALLOY WHEEL",
          "I3S SELF START DISC BRAKE ALLOY WHEEL",
          "I3S SELF START DRUM BRAKE SPOKE WHEEL",
          "DISC BREAK SELF START BS VI",
          "DRUM BREAK SELF START BS VI",
          "100 M - SELF START DISC BRAKE ALLOY WHEEL FI",
          "DRUM ALLOY BSVI",
          "100 M - SELF START DRUM BREAK ALLOY WHEEL FI",
          "SELF START DRUM BREAK CAST WHEEL 97 CC"
        ]
      },
      {
        "model": "DESTINI 125",
        "variants": [
          "100 MILLION EDITION",
          "XTEC DRUM",
          "LX FI BSVI",
          "VX FI BSVI",
          "I3S IBS FI 100 MILLION BS6",
          "LIMITED EDITION",
          "100 MILLION",
          "XTEC STD BSVI",
          "XTEC LX BSVI",
          "XTEC ALLOY WHEEL BSVI",
          "XTEC VX BSVI",
          "LX OBD2 BSVI",
          "XTECH OBD2 BSVI",
          "PRIME DRS BSVI",
          "VX BS VI",
          "LX BS VI",
          "PLATINUM"
        ]
      },
      {
        "model": "XPULSE 200T",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CRUZ LI",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "WAVE DX",
        "variants": [
          "STANDARD",
          "EM E BIKE",
          "STD",
          "STD"
        ]
      },
      {
        "model": "ZION",
        "variants": [
          "STANDARD",
          "STD",
          "EBIKE",
          "STD",
          "STD",
          "E BIKE LITHIUM BATTERY",
          "E BIKE"
        ]
      },
      {
        "model": "DASH",
        "variants": [
          "STANDARD",
          "EBIKE",
          "LEAD ACID",
          "LI",
          "LITHIUM BATTERY",
          "DASH"
        ]
      },
      {
        "model": "HF 100",
        "variants": [
          "KICK START DRUM BRAKE ALLOY WHEEL - FI",
          "FI DRUM KICK CAST",
          "STD",
          "KICK START DRUM BRAKE ALLOY WHEEL - FI",
          "E20 BSVI",
          "FI KICK START DRUM BRAKE ALLOY WHEEL"
        ]
      },
      {
        "model": "XOOM",
        "variants": [
          "ZX DSSC",
          "VX DRSC",
          "LX DRS SM POLESTAR BLUE",
          "LX BSVI",
          "VX BSVI",
          "ZX BSVI",
          "ZX XTEC BSVI",
          "VX",
          "110 ZX",
          "ZX DISC",
          "LX DRUM",
          "LX",
          "ZX"
        ]
      },
      {
        "model": "THUNDERBIRD",
        "variants": [
          "TWINSPARK ELECTRIC"
        ]
      },
      {
        "model": "ACHIEVER",
        "variants": [
          "SELF START",
          "KICK START",
          "ELECTRIC START DISC BRAKE",
          "KICK START DRUM BRAKE",
          "SELF START DRUM BRAKE",
          "DRUM BRAKE BSIV",
          "DISC BRAKE BSIV",
          "DRUM CAST SELF FI BSVI",
          "DISC CAST SELF FI BSVI",
          "I3S",
          "I3S DISC",
          "150 KS",
          "CAST DSS",
          "DISK SELF",
          "ES DRUM BRAKE",
          "I3S 150 DRUM",
          "PLUS",
          "STD",
          "ES DISC BRAKE",
          "PLUS",
          "DISC KICK",
          "DISC SELF",
          "I3S 150 DISK",
          "I3 S"
        ]
      },
      {
        "model": "AMBITION",
        "variants": [
          "ELEC START DISC BRAKE",
          "ELEC START DRUM BRAKE",
          "KICK START DISC BRAKE",
          "KICK START DRUM BRAKE",
          "KS DISC BRAKE",
          "ES DISC BRAKE",
          "KS DRUM BRAKE",
          "ES DRUM BRAKE"
        ]
      },
      {
        "model": "CBX",
        "variants": [
          "ELECTRIC START DISC BRAKE",
          "STD DRUM BRAKE",
          "REAR DRUM",
          "REAR DISC",
          "150"
        ]
      },
      {
        "model": "CBZ",
        "variants": [
          "ELEC START DISC BRAKE",
          "KICK START DISC BRAKE",
          "EXTREME",
          "EXTREME",
          "FR DISC",
          "RR DISC",
          "STD",
          "STD"
        ]
      },
      {
        "model": "CBZ STAR",
        "variants": [
          "ELECTRIC START DISC BRAKE",
          "ELECTRIC START DRUM BRAKE",
          "KICK START DISC BRAKE",
          "KICK START DRUM BRAKE",
          "ELECTRIC START DRM",
          "K S DISC BRAKE",
          "ES DISC BRAKE",
          "K S DRUM BRAKE"
        ]
      },
      {
        "model": "CBZ X-TREME",
        "variants": [
          "ELECTRIC START DISC BRAKE",
          "KICK START DISC BRAKE",
          "REAR DISC BRAKE",
          "DOUBLE DISC SELF START",
          "KICK START DISC"
        ]
      },
      {
        "model": "CD 100",
        "variants": [
          "ELETRIC START"
        ]
      },
      {
        "model": "CD DAWN",
        "variants": [
          "KICK ST DISC BRAKE SW",
          "KICK ST DRUM BRAKE SW",
          "DELUX SPOKE WHEEL",
          "KICK START DRUM BREAK SPOKE WHEEL",
          "SELF START DRUM BREAK SPOKE WHEEL",
          "SELF START DRUM BREAK CAST WHEEL",
          "SELF START",
          "KICK START"
        ]
      },
      {
        "model": "CD DELUXE",
        "variants": [
          "CAST WHEELS",
          "ELECTRIC START",
          "KICK ST DRUM BRAKE CAST WHEEL",
          "KICK ST DRUM BRAKE CAST WHEELS",
          "KICK ST DRUM BRAKE SPOKE WHEEL",
          "KICK ST DRUM BRAKE SW",
          "SELF ST DRUM BRAKE CAST WHEEL",
          "SELF ST DRUM BRAKE SPOKE WHEEL",
          "SPOKE WHEELS",
          "CAST",
          "CAST KICK",
          "CAST SELF",
          "STD",
          "EURO II",
          "SPOKE KICK",
          "SPOKE SELF",
          "SELF START",
          "KICK START"
        ]
      },
      {
        "model": "GLAMOUR SX",
        "variants": [
          "DRUM BRAKE CAST",
          "DISC BRAKE",
          "DISC FI"
        ]
      },
      {
        "model": "KARIZMA",
        "variants": [
          "ELEC START DISK BRAKE",
          "DLX",
          "200 STD",
          "DOUBLE DISC BRAKE",
          "R",
          "STD",
          "ZMR",
          "STD",
          "ZMR PGM F1",
          "B & R",
          "ZMR FI",
          "BLACK WHEEL",
          "MR FI ZMR",
          "223",
          "SELF START DISC BRAKE ALLOY WHEEL",
          "13 CARB SELF START DISC BRAKE ALLOY WHEEL",
          "BLAC CAST",
          "DOUBLE DISC SELF START ALLOY WHEEL"
        ]
      },
      {
        "model": "SLEEK",
        "variants": [
          "STD",
          "STD 98 CC"
        ]
      },
      {
        "model": "X PULSE",
        "variants": [
          "T",
          "STD",
          "FI",
          "CARB",
          "DOUBLE DISC BSVI",
          "200T FI BSVI",
          "200 FI BSVI",
          "200 FI ABS BSVI",
          "200 4V FI BS6 DDS SPOKE",
          "200 4V RALLY EDTN BSVI"
        ]
      },
      {
        "model": "CRUZ",
        "variants": [
          "STD",
          "STD",
          "EBIKE",
          "E BIKE"
        ]
      },
      {
        "model": "MAXI LI",
        "variants": [
          "EBIKE",
          "EBIKE"
        ]
      },
      {
        "model": "E SPRINT",
        "variants": [
          "E BIKE",
          "STD",
          "EBIKE",
          "STD",
          "EBIKE",
          "E BIKE"
        ]
      },
      {
        "model": "MAX I",
        "variants": [
          "EBIKE",
          "EBIKE"
        ]
      },
      {
        "model": "MARATHON",
        "variants": [
          "EBIKE",
          "EBIKE"
        ]
      },
      {
        "model": "VELOCITY",
        "variants": [
          "EBIKE",
          "EBIKE",
          "STD"
        ]
      },
      {
        "model": "ZIPPY",
        "variants": [
          "STD",
          "STD",
          "ZIPPY"
        ]
      },
      {
        "model": "ATRIA",
        "variants": [
          "LX",
          "LX",
          "STD",
          "LITHIUM BATTERY",
          "LX"
        ]
      },
      {
        "model": "N 61A",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "DUET",
        "variants": [
          "LX BSIV",
          "VX BSIV",
          "VX FI BSVI",
          "E BIKE",
          "BSIII",
          "E",
          "VX",
          "LX",
          "VX",
          "LX"
        ]
      },
      {
        "model": "STREET",
        "variants": [
          "ELECTRIC START",
          "STD"
        ]
      },
      {
        "model": "ZIR",
        "variants": [
          "150"
        ]
      },
      {
        "model": "RNT",
        "variants": [
          "150 TDI"
        ]
      },
      {
        "model": "LEAP",
        "variants": [
          "125"
        ]
      },
      {
        "model": "DARE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SPLENDOR I SMART",
        "variants": [
          "SELF START",
          "110",
          "PLUS IBS SELF START DRUM BRAKE ALLOY WHEEL",
          "SELF START DRUM BREAK SPOKE WHEEL",
          "SELF START DRUM BREAK CAST WHEEL",
          "110 CC - SELF START DRUM BRAKE CAST WHEEL",
          "SELF START DRUM BRAKE ALLOY WHEEL FI BS VI",
          "SELF START DISC BRAKE ALLOY WHEEL FI BS VI"
        ]
      },
      {
        "model": "NYX 500 BATTERY",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "NYX LIBATIERY",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "NYX - LI (X2)",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "NYX PLAIN",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "OPTIMA DX LI",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "OPTIMA WITH GEAR",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "OPTIMA DX",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "PHOTON 72V LEAD",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "WAVE DX - EM",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "SMART",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "XTREME DSS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "EZEE SX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PANTHER 60 CC DLX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TURBO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PANTHER 2S",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PANTHER 4S",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "HERO STALLION 4S",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PLEASURE110 CAST WHEEL",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "POWER",
        "variants": [
          "STD",
          "AXL",
          "STD",
          "GXL"
        ]
      },
      {
        "model": "GIZMO",
        "variants": [
          "STD",
          "PLUS",
          "STD",
          "STD"
        ]
      },
      {
        "model": "PASSION PRO DRUM-SELF-CAST",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SPLENDOR PLUS DRUM",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PASSION XPRO DRUM SELF CAST",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PLEASURE PLUS ZX PLUS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SPLENDOR PLUS-DRUM",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SHAKTI",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CRUX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "WAVE",
        "variants": [
          "DX",
          "DX",
          "DX"
        ]
      },
      {
        "model": "CBZ-XTREME",
        "variants": [
          "ATFT",
          "CBM DDSS",
          "CBM DSS",
          "CBMDSS DUBLE DISK",
          "CBMDSS SINGLE DISK",
          "SELF START",
          "STD",
          "KICK START"
        ]
      },
      {
        "model": "DAWN",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DESTINI",
        "variants": [
          "125 LX",
          "125 LX BS6",
          "125 VX",
          "125 VX BS6",
          "125 VX MILLION BS6",
          "125 ZX BS6",
          "125 PLATINUM",
          "125 XTEC",
          "100 MILLION",
          "PRIME 125 NEW",
          "VX",
          "LIMITED EDITION",
          "DRUM BRAKE SELF START 100 MILLION BS VI",
          "FI DRUM BRAKE SELF START PLATINUM BS VI",
          "FI DRUM BRAKE SELF START BS VI",
          "FI DRUM BRAKE SELF START SM BS VI",
          "LX",
          "XTEC VX",
          "XTEC LX",
          "LX BS VI",
          "VX BS VI"
        ]
      },
      {
        "model": "PLEASURE PLUS 110",
        "variants": [
          "CAST WHEEL",
          "SHEET METAL WHEEL BS6",
          "PLATINUM BS6",
          "LX BS6",
          "CAST WHEEL BS6",
          "XTEC",
          "VX"
        ]
      },
      {
        "model": "SPLENDOR +",
        "variants": [
          "CAST KICK",
          "I3S IBS",
          "STD",
          "ALLOYS WHEEL",
          "DRUM KICK SPOKE",
          "SCR DRK SCR OBK",
          "SELF CAST",
          "SPOKE KICK",
          "FDR SPOKE",
          "FDR CAST",
          "KICK WITH ALLOY WHEEL BS6",
          "SELF WITH ALLOY WHEEL BS6",
          "BLACK AND ACCENT BS6",
          "SELF WITH ALLOY WHEEL AND I3S BS6",
          "100 MILAN BS6",
          "I3S BS6",
          "SELF DRUM ALLOY-IBS",
          "DRS CCR IBS",
          "XTEC"
        ]
      },
      {
        "model": "CD",
        "variants": [
          "100 ES"
        ]
      },
      {
        "model": "GLAMOUR F1",
        "variants": [
          "STD",
          "CA-DSS",
          "CAST DISK SELF",
          "DOUBLE DISK"
        ]
      },
      {
        "model": "JOY",
        "variants": [
          "ES"
        ]
      },
      {
        "model": "EDDY",
        "variants": [
          "STANDARD",
          "LITHIUM ION 52 V 30AH"
        ]
      },
      {
        "model": "VIDA",
        "variants": [
          "V1 PRO",
          "V1 PRO E-SCOOTER",
          "V1 PLUS"
        ]
      },
      {
        "model": "N61A",
        "variants": [
          "LITHIUM ION BATTERY 42 AH 2.175 KWH"
        ]
      },
      {
        "model": "ADVANTA",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "HF DELUX",
        "variants": [
          "KICK START DRUM BREAK SPOKE WHEEL",
          "KICK START DRUM BREAK CAST WHEEL",
          "SELF START DRUM BREAK SPOKE WHEEL",
          "SELF START DRUM BREAK CAST WHEEL",
          "IBS SELF START DRUM BREAK CAST  WHEEL",
          "ECO SELF START DRUM BREAK CAST WHEEL",
          "I3S SELF START DRUM BREAK CAST WHEEL BS VI",
          "IBS I3S SELF START DRUM BREAK CAST WHEEL",
          "I3S SELF START DRUM BRAKE ALLOY WHEEL",
          "KICK START DRUM BRAKE ALLOY WHEEL FI  BS VI",
          "SELF START DRUM BRAKE ALLOY WHEEL FI  BS VI",
          "IBS DRUM KICK CAST",
          "KICK START DRUM BRAKE CAST BS VI"
        ]
      },
      {
        "model": "CD DELUX",
        "variants": [
          "KICK START DRUM BREAK SPOKE WHEEL",
          "SELF START DRUM BREAK CAST WHEEL",
          "KICK START DRUM BREAK CAST WHEEL",
          "SELF START DRUM BREAK SPOKE WHEEL"
        ]
      },
      {
        "model": "ACHIVER",
        "variants": [
          "KICK START DISC",
          "SELF START DISC",
          "SELF START DRUM"
        ]
      },
      {
        "model": "PASSION XTEC",
        "variants": [
          "SELF START DRUM BRAKE ALLOY WHEEL",
          "SELF START DISC BRAKE ALLOY WHEEL"
        ]
      },
      {
        "model": "XTREME 200",
        "variants": [
          "S ABS SELF START DOUBLE DISC BRAKE ALLOY WHEEL",
          "R SELF START ABS DISC BRAKE ALLOY WHEEL",
          "S ABS SELF START DOUBLE DISC BRAKE ALLOY WHEEL BS VI"
        ]
      },
      {
        "model": "CBZ X TREME 156",
        "variants": [
          "DISC SELF START"
        ]
      },
      {
        "model": "XTREME 160 R",
        "variants": [
          "SELF START DISC BRAKE ALLOY WHEEL FI BS VI",
          "SELF START DOUBLE DISC BRAKE ALLOY WHEEL FI BS VI",
          "100 STEALTH EDITION"
        ]
      },
      {
        "model": "OPTIMA PLUS PHOTON",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ULTRA OPTIMA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "STEPMATIC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ULTRA MAXI",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ULTRA MARATHON",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SPORTS",
        "variants": [
          "TURBO"
        ]
      },
      {
        "model": "ULTRA VELOCITY",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "STALLION",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "HESTER",
    "models": [
      {
        "model": "E SCOOTER",
        "variants": [
          "POWER PLUS"
        ]
      }
    ]
  },
  {
    "make": "HI ENERGY LD",
    "models": [
      {
        "model": "BLX SHAKTI XL",
        "variants": [
          "LITHIUM 60V 35AH"
        ]
      }
    ]
  },
  {
    "make": "HILMAN ELECTRIC VEHICLE",
    "models": [
      {
        "model": "RAMBO COOL",
        "variants": [
          "LA 60V 28AH 250WATT"
        ]
      },
      {
        "model": "ROCK",
        "variants": [
          "LA 60V 28AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "HINDUSTAN POWER",
    "models": [
      {
        "model": "ALFA",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V",
          "LEAD ACID 96V",
          "LA 60V 1.2 KW"
        ]
      },
      {
        "model": "BOOSTER",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V"
        ]
      },
      {
        "model": "NOZZEL",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V",
          "LA 60V 1.2 KW"
        ]
      },
      {
        "model": "VESPRO",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V"
        ]
      },
      {
        "model": "PLENZER",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V"
        ]
      }
    ]
  },
  {
    "make": "HIRADO ELECTRIC",
    "models": [
      {
        "model": "G1",
        "variants": [
          "LITHIUM 60V 34AH",
          "LITHIUM 48V 26AH"
        ]
      }
    ]
  },
  {
    "make": "HONDA",
    "models": [
      {
        "model": "AVIATOR",
        "variants": [
          "DISC",
          "DELUXE (BS IV)",
          "ELECTRIC START DISC BRAKE",
          "ELECTRIC START DRUM BRAKE",
          "KICK START DISC BRAKE",
          "KICK START DRUM BRAKE",
          "DRUM",
          "DRUM ALLOY",
          "DISC",
          "ELECTRIC START",
          "BSIV STD",
          "BSIV STD ALLOY",
          "BSIV DLX",
          "STD",
          "DLX",
          "STD",
          "CBS DRUM",
          "DISC",
          "DRUM",
          "DRUM ALLOY",
          "DRUM HET 109 CC",
          "SCV110GC",
          "DRUM BRAKE",
          "SS",
          "DISC BRAKE",
          "ES DISC BRAKE",
          "DRM",
          "SDCA",
          "110 CC",
          "DELUXE",
          "DISC BRAKE (110 CC)",
          "DRUM BRAKE (110 CC)",
          "DRUM BRAKE ALLOY (110 CC)",
          "SELF START DRUM BREAK",
          "DLX 110 CC",
          "STD 110 CC",
          "SELF START DISC BREAK",
          "SELF START DISC BRAKE",
          "SELF START",
          "STD"
        ]
      },
      {
        "model": "CBF STUNNER",
        "variants": [
          "SELF",
          "KICK",
          "ELEC ST DISC BRAKE AWS",
          "ELEC ST DRUM BRAKE AWS",
          "KICK ST DRUM BRAKE AWS",
          "PGM-FI ELE ST DISC BRAKE AWS",
          "KICK START",
          "PGM - FI",
          "SELF START",
          "SELF START DISC BRAKE",
          "FI",
          "SELF DRUM ALLOY",
          "STD",
          "ES",
          "KS",
          "SELF DISC ALLOY",
          "PGM FI",
          "PGM-FI (SELF DISC ALLOY)",
          "SELF DISC ALLOY",
          "KICK DRUM ALLOY",
          "SELF DRUM ALLOY"
        ]
      },
      {
        "model": "ACTIVA",
        "variants": [
          "STANDARD",
          "DELUXE",
          "CBS BS III",
          "6G SPECIAL EDITION",
          "6G DLX LTD EDITION BSVI",
          "ELECTRIC START",
          "HET",
          "5G STD (BSIV)",
          "5G DLX (BSIV)",
          "5G STD LTD EDTN",
          "5G DLX LTD EDTN",
          "6G DLX (BSVI)",
          "CBS",
          "6G STD LTD EDITION BSVI",
          "6G DLX LTD EDITION BSVI",
          "SCV 110",
          "KICK START",
          "6G LED BSVI",
          "4G BSIV",
          "ALLOY DRUM LTD ED",
          "ALLOY DISC LTD ED",
          "125 DISC LIMITED EDITION",
          "6G PREMIUM ED BSVI",
          "OBD2 H SMART BSVI",
          "OBD2 STD BSVI",
          "OBD2 DLX BSVI",
          "5G",
          "5G DLX",
          "STD",
          "STD",
          "125 ALLOY BSVI",
          "125 DISC",
          "125 DISC BSVI",
          "125 DLX BSVI",
          "125 DRS BSVI",
          "125 DSS BSVI",
          "125 DURM ALLOY",
          "125 STD BSVI",
          "5G DLX LTD EDITION",
          "5G STD",
          "5G STD LTD EDITION",
          "6G DELUXE BSVI",
          "6G STD BSVI",
          "SCV110",
          "SCV110B",
          "125 DRUM ALLOY BSVI",
          "3G",
          "125 DRUM",
          "125 DRUM (BSVI)",
          "SMART",
          "6G H SMART",
          "DLX OBD2",
          "125 SMART OBD2",
          "125 DISC OBD2",
          "125 DRUM ALLOY OBD2",
          "6G PREMIUM",
          "125 H-SMART",
          "6G H SMART",
          "6G SMART KEY - ALLOY WHEEL",
          "125 OBD2",
          "6G SMART",
          "H SMART",
          "STD OBD",
          "DLX OBD",
          "PREMIUM EDITION 125 DRUM",
          "PREMIUM EDITION 125 DISC",
          "4G STD",
          "110",
          "125",
          "DELUXE",
          "DLX",
          "6G STD LTD EDITION BS6",
          "6G DLX LTD EDITION BS6",
          "5G DELUXE",
          "5G",
          "125 DRUM OBD2",
          "ELEC START 125 CC - 3 WHEELER FOR HANDICAPED",
          "5G DLX HANDICAPED",
          "STD ALLOY 125 CC",
          "5G DLX",
          "4G 110 CC",
          "H SMART",
          "STD OBD2",
          "6G 110 CC DLX PREMIUM BS VI HANDICAP",
          "SMART OBD2",
          "125 DISC PREMIUM (BSVI)",
          "125 SMART",
          "6G STD SPECIAL EDITION",
          "6G DLX SPECIAL EDITION",
          "6G STD HANDICAPED",
          "125 DRUM ALLOY BSVI LTD ED",
          "125 DISC  HANDICAPPED BSVI",
          "6G STD",
          "110 CC ELECTRIC START - HANDICAPED",
          "6G DLX",
          "3G 110 CC",
          "ELECTRIC START 110 CC",
          "DLX 110 CC",
          "ELEC START 110 CC - 3 WHEELER FOR HANDICAPED",
          "DLX 125 CC",
          "STD 125 CC",
          "5G DLX LIMITED EDITION",
          "STD (109 CC)",
          "5G STD LIMITED EDITION",
          "ELECTRIC START (102 CC)",
          "3G",
          "4G",
          "HET",
          "SELF START",
          "SELF START",
          "STD",
          "DLX",
          "DLX",
          "LPG",
          "STD",
          "SELF START - HANDICAP",
          "DX"
        ]
      },
      {
        "model": "CB TWISTER",
        "variants": [
          "DRUM/KICK",
          "DISC/ELECTRIC START",
          "DRUM/ELECTRIC START",
          "ELEC ST DISC BRAKE AWS",
          "ELEC ST DRUM BRAKE AWS",
          "KICK ST DRUM BRAKE AWS",
          "STD",
          "KICK START",
          "SELF START",
          "SELF START DISC BRAKE",
          "110 ES DRUM BRAKE",
          "110 ES DISC BRAKE",
          "110",
          "SDRA",
          "SDCA",
          "SELF START WITH DISC BRAKE",
          "KICK DRUM ALLOY",
          "SELF DISK ALLOY",
          "SELF DRUM ALLOY"
        ]
      },
      {
        "model": "CB",
        "variants": [
          "600 HORNET",
          "750",
          "400 STANDARD",
          "200 X",
          "1000R",
          "1000 RB",
          "1000 R 998CC",
          "350 RS MONOTONE BS6",
          "350 RS DUALTONE BS6",
          "1100 RB STD",
          "1300 X4",
          "400 SUPERFOUR"
        ]
      },
      {
        "model": "DREAM YUGA",
        "variants": [
          "ELECTRIC START/ALLOY",
          "KICK/NON ALLOY",
          "KICK/ALLOY",
          "ELEC ST DRUM BRAKE AWS",
          "KICK ST DRUM BRAKE AWS",
          "KICK ST DRUM BRAKE SW",
          "NEW BSIV",
          "CBS BSIV",
          "SELF START ALLOY WHEEL",
          "BSIII",
          "STD",
          "SELF START",
          "109",
          "CB110D",
          "CB110MD",
          "CBS",
          "KDS",
          "KDA",
          "SDA",
          "SELF DRUM CBS",
          "SELF START - DRUM BREAK - ALLOY WHEEL",
          "KICK START - DRUM BREAK - SPOKE WHEEL",
          "KICK START - DRUM BREAK - ALLOY WHEEL",
          "KICK START ALLOY WHEEL",
          "STD"
        ]
      },
      {
        "model": "CBR150 R",
        "variants": [
          "STANDARD",
          "DELUXE"
        ]
      },
      {
        "model": "DREAM NEO",
        "variants": [
          "SELF",
          "KICK START DRUM BRAKE SPOKE",
          "KICK START DRUM BRAKE ALLOY",
          "ELEC ST DRUM BRAKE AWS",
          "KICK ST DRUM BRAKE AWS",
          "KICK ST DRUM BRAKE SW",
          "SELF BSIV",
          "SELF START",
          "KICK DRUM SPOKE",
          "SELF CARRIER",
          "110",
          "DRUM KICK ALLOY",
          "SELF DRUM ALLOY",
          "NEW CARRIER",
          "KICK DRUM ALLOY",
          "SELF DRUM ALLOY",
          "KICK START ALLOY WHEEL",
          "STD"
        ]
      },
      {
        "model": "CD 110 DREAM",
        "variants": [
          "STANDARD",
          "STANDARD CARRIER CBS",
          "DELUXE CARRIER CBS",
          "SELF CARRIER",
          "KICK",
          "KICK CARRIER",
          "SELF",
          "DELUXE",
          "KICK ST DRUM BRAKE AWS",
          "SELF BSIV",
          "SELF DRUM ALLOY BSVI",
          "DLX BSVI",
          "STD BSVI",
          "CBS",
          "SELF DRUM ALLOY",
          "KICK DRUM ALLOY",
          "DX SELF DRUM"
        ]
      },
      {
        "model": "DIO",
        "variants": [
          "DELUXE BS VI",
          "STANDARD OBD2",
          "DELUXE OBD2",
          "SMART OBD2",
          "STANDARD BS VI",
          "BS IV",
          "REPSOL EDITION",
          "DELUXE - SPORTS LIMITED EDITION",
          "H-SMART",
          "DLX - ELECTRIC START",
          "DLX HET BSIV",
          "CBS",
          "DLX REPSOL BSVI SC",
          "DRUM REPSOL BSVI",
          "DRUM REPSOL BSIV",
          "SCV 110",
          "DLX LTD ED BSVI",
          "DX-EMBLEM",
          "DRUM LTD ED BSVI",
          "SPORT DRUM BSVI",
          "STD OBD2 BSVI",
          "DLX OBD2 BSVI",
          "SMART OBD2 BSVI",
          "125 STD OBD2 BSVI",
          "125 SMART OBD2 BSVI",
          "STD",
          "STD/110",
          "STD",
          "CBC",
          "DLX BS6",
          "DRUM BS6",
          "SCV110FBC",
          "SCV110FBC.",
          "SCV110FD",
          "DLX",
          "STD BS VI",
          "DLX BS VI",
          "STANDARD - SPORTS LIMITED EDITION",
          "125 STANDARD OBD2",
          "125 SMART OBD2",
          "125 STANDARD",
          "125 SMART",
          "SMART 3D EMBLEM",
          "STD OBD 2",
          "DLX OBD 2",
          "SMART OBD 2",
          "125 DLX OBD2",
          "DRUM SPORTS EDITION BSVI",
          "DLX SPORTS EDITION BSVI",
          "DLX 102 CC",
          "REPSOL EDITION BS6",
          "STD BS6",
          "STD.",
          "STD 110 CC",
          "DLX LIMITED EDITION",
          "LE STD BS VI",
          "LE DLX BS VI",
          "DLX REPSOL EDITION",
          "DLX - ELECTRIC START 110 CC",
          "STD",
          "DLX SELF START",
          "DLX"
        ]
      },
      {
        "model": "ACTIVA 5G",
        "variants": [
          "STANDARD-LIMITED EDITION",
          "DELUXE-LIMITED EDITION",
          "STANDARD",
          "DELUXE",
          "STD"
        ]
      },
      {
        "model": "ACTIVA 125",
        "variants": [
          "DRUM",
          "DRUM ALLOY",
          "DISC",
          "STANDARD (BS-IV)",
          "STANDARD ALLOY (BS-IV)",
          "DELUXE (BS-IV)",
          "DLX",
          "STD",
          "DRUM ALLOY",
          "CBS",
          "OBD2 DRUM BSVI",
          "OBD2 ALLOY BSVI",
          "OBD2 DISC BSVI",
          "OBD2 H SMART BSVI",
          "STD"
        ]
      },
      {
        "model": "GRAZIA",
        "variants": [
          "DELUXE",
          "STANDARD",
          "STD ALLOY",
          "SPORTS EDITION - DISC",
          "DRUM ALLOY",
          "DRUM",
          "STD BSVI",
          "DLX BSVI",
          "DISC LIMITED EDITION",
          "DRUM LIMITED EDITION",
          "SPORT EDITION",
          "REPSOL ARRIVED",
          "STD",
          "DLX",
          "ALLOY",
          "DISC",
          "DISC BS VI",
          "DRUM BS VI",
          "REPSOL EDITION BS6",
          "SPORTS EDITION BS6",
          "DISC BS6",
          "DRUM BS6",
          "DISC REPSOL",
          "DRUM SE",
          "DISC SE",
          "DISC LIMITED EDITION BS VI"
        ]
      },
      {
        "model": "CB SHINE",
        "variants": [
          "DRUM-CBS-LIMITED EDITION",
          "DISC-CBS-LIMITED EDITION",
          "CELEBRATION EDITION DRUM",
          "CELEBRATION EDITION DISC",
          "CBS",
          "DRUM OBD2",
          "DISC OBD2",
          "DRUM BS VI 4 ID",
          "ELEC ST DISC BRAKE AWS",
          "ELEC ST DISC BRAKE AWS",
          "ELEC ST DRUM BRAKE ALLOY WHEEL",
          "ELEC ST DRUM BRAKE ALLOY WHEEL",
          "KICK ST DRUM BRAKE SW",
          "KICK ST DRUM BRAKE SW",
          "SP DISC BRAKE",
          "SP DRUM BRAKE",
          "STD BSIV",
          "DLX BSIV",
          "DISC CBS",
          "DRUM CBS LTD EDTN",
          "DISC CBS LTD EDTN",
          "DRUM BSIV",
          "DLX BSVI",
          "CBF 125 MCC",
          "DRUM LIMITED EDITION BSVI",
          "DISK LIMITED EDITION BSVI",
          "DISC BRAKE CBS",
          "STD",
          "125 ES DRUM BRAKE",
          "DISC CBS LTD EDITION",
          "DRUM CBS LTD EDITION",
          "SELF DISK ALLOY",
          "SELF DISK CBS",
          "SELF DRUM BS IV",
          "SELF DRUM CBS",
          "SP DRUM CBS",
          "DISC BS VI",
          "DRUM BS VI",
          "SP - DLX",
          "SP - STD",
          "DRUM-CBS",
          "SP DISC",
          "KICK DRUM SPOKE",
          "SELF DRUM ALLOY",
          "125",
          "125 ES DISC BRAKE",
          "125 CBF",
          "DRUM BS6",
          "DISC BS6",
          "SP DISK CBS BS6",
          "SP DRUM CBS BS6",
          "SELF-DISC-ALLOY",
          "SELF DISC CBS LIMITED EDITION",
          "SELF DRUM CBS LIMITED EDITION",
          "SELF-DISC-ALLOY CBS",
          "KICK START",
          "SELF START ALLOY WHEEL",
          "SELF START DISC BRAKE"
        ]
      },
      {
        "model": "CB UNICORN 150",
        "variants": [
          "ABS",
          "STANDARD"
        ]
      },
      {
        "model": "CB HORNET 160R",
        "variants": [
          "ABS - STANDARD",
          "ABS - DELUXE",
          "STANDARD",
          "SPECIAL EDITION - STANDARD",
          "SPECIAL EDITION - CBS",
          "STD"
        ]
      },
      {
        "model": "CBR 250R",
        "variants": [
          "STANDARD",
          "SPORTS RED-STD",
          "250CC",
          "REPSOL-STD",
          "SPORTS RED-ABS",
          "250 ABS",
          "REPSOL-ABS",
          "ABS"
        ]
      },
      {
        "model": "ACTIVA 6G",
        "variants": [
          "DELUXE",
          "STANDARD",
          "20TH YEAR ANNIVERSARY DELUXE",
          "20TH YEAR ANNIVERSARY STANDARD",
          "PREMIUM EDITION DELUXE",
          "H-SMART OBD",
          "H-SMART OBD 2",
          "OBD DLX",
          "OBD STD"
        ]
      },
      {
        "model": "MEGAPRO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "UNICORN",
        "variants": [
          "STANDARD BS VI",
          "STANDARD OBD2",
          "ABS",
          "150",
          "ELECTRIC START",
          "ELEC ST DISC BRAKE AWS",
          "KICK ST DRUM BRAKE AWS",
          "STD",
          "STD",
          "160 STD BSIV",
          "160 CBS BSIV",
          "160 ABS BSVI",
          "160 CBF",
          "150 CBF",
          "163 BSIV",
          "160 OBD2 ABS BSVI",
          "STD",
          "149 CC",
          "150 SPOKE WHEEL",
          "ABS BSVI",
          "ALLOY",
          "GP 150 SPOKE WHEEL",
          "STD BS6",
          "CBF 160 DISC BS6",
          "160 OBD2",
          "150 KS",
          "150 ES",
          "150 ES CAST WHEEL",
          "STD BS VI",
          "KICK DRUM ALLOY",
          "SELF DISC ALLOY",
          "KICK DISC SPOKE",
          "SELF DISC SPOKE",
          "SELF START DISC BRAKE",
          "SELF START ALLOY WHEEL",
          "KICK START",
          "DISC BRAKE"
        ]
      },
      {
        "model": "ETERNO",
        "variants": [
          "STANDARD",
          "DLX",
          "STD",
          "STD",
          "102",
          "DELUX 150",
          "150",
          "STD",
          "DLX"
        ]
      },
      {
        "model": "CB UNICORN",
        "variants": [
          "KICK START",
          "DAZZLER",
          "ELEC ST DISC BRAKE AWS",
          "PREMIUM ABS",
          "STD BSVI",
          "160 ABS BSVI",
          "160 BSIV",
          "DRUM BREAK",
          "BSIV",
          "STD",
          "160 CBS",
          "160 SELF START ALLOY",
          "160 STD",
          "SELF START",
          "150",
          "150 ABS",
          "DISK",
          "STD",
          "CBS",
          "BS III",
          "GP E",
          "160 BS6",
          "STD BS6",
          "150 CBS",
          "PREMIUM ABS",
          "SELF-DISC-ALLOY",
          "DAZZLER",
          "DAZZLER DLX",
          "DAZZLER DELUXE",
          "DAZZLER BASIC"
        ]
      },
      {
        "model": "CB UNICORN DAZZLER",
        "variants": [
          "STANDARD",
          "150"
        ]
      },
      {
        "model": "CB TRIGGER",
        "variants": [
          "STANDARD",
          "DELUXE",
          "ABS",
          "CBS",
          "DLX",
          "STD",
          "CBS",
          "STD",
          "DLX"
        ]
      },
      {
        "model": "ACTIVA-I",
        "variants": [
          "STANDARD",
          "DELUXE"
        ]
      },
      {
        "model": "VALKYRIE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACTIVA 3G",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "SCV",
        "variants": [
          "100",
          "100"
        ]
      },
      {
        "model": "CB UNICORN 160",
        "variants": [
          "DISC (BS IV)",
          "CBS (BS IV)",
          "STD",
          "ABS BS VI",
          "STANDARD",
          "CBS"
        ]
      },
      {
        "model": "NAVI",
        "variants": [
          "STANDARD",
          "ADVENTURE",
          "CHROME",
          "CBS",
          "BSIV",
          "ADVENTURE EDITION",
          "OFF ROAD",
          "STREET",
          "STD",
          "STD",
          "ADVENTURE",
          "CHROME EDITION"
        ]
      },
      {
        "model": "ACTIVA 4G",
        "variants": [
          "STANDARD (BS IV)",
          "STD"
        ]
      },
      {
        "model": "CLIQ",
        "variants": [
          "STANDARD",
          "STD",
          "DLX",
          "DRUM NEW CARRIER",
          "SELF NEW CARRIER",
          "DELUXE",
          "STD"
        ]
      },
      {
        "model": "X-BLADE",
        "variants": [
          "STANDARD",
          "SINGLE DISC"
        ]
      },
      {
        "model": "ACTIVA 125 FI",
        "variants": [
          "STANDARD BS VI",
          "DELUXE BS VI",
          "ALLOY BS VI"
        ]
      },
      {
        "model": "HORNET 2.0",
        "variants": [
          "STANDARD",
          "REPSOL EDITION",
          "ABS OBD2",
          "STD BSVI",
          "ABS BSVI",
          "ABS",
          "STD",
          "RESPOL EDITION"
        ]
      },
      {
        "model": "HIGHNESS CB 350",
        "variants": [
          "DLX",
          "DLX PRO",
          "ANNIVERSARY EDITION",
          "DLX 1 OBD 2",
          "DLX 2 (DLX PRO) OBD 2",
          "DLX 3 (DLX PRO CHROME) OBD 2"
        ]
      },
      {
        "model": "AFRICA TWIN",
        "variants": [
          "ADVENTURE SPORTS - MANUAL",
          "ADVENTURE SPORTS - DCT",
          "STD",
          "STANDARD",
          "DCT",
          "ADVENTURE SPORTS MANUAL",
          "ADVENTURE SPORTS - DCT",
          "ABS",
          "DCT 1082 CC",
          "MANUAL",
          "DCT"
        ]
      },
      {
        "model": "CB SHINE SP",
        "variants": [
          "CBS (BS IV)",
          "STD",
          "CBS",
          "DISK",
          "DRUM",
          "DISC CBS BSVI",
          "DRUM CBS BSVI",
          "SELF DISC ALLOY",
          "SELF DRUM ALLOY",
          "DISC CBS",
          "DELUXE BSIV",
          "CBS DISC",
          "CBS DRUM"
        ]
      },
      {
        "model": "CB1000R",
        "variants": [
          "ABS",
          "STD",
          "BIG BIKE"
        ]
      },
      {
        "model": "CBR650F",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CBR 400RR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CBR 600RR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CBR1000RR FIREBLADE",
        "variants": [
          "C-ABS",
          "STANDARD",
          "SP"
        ]
      },
      {
        "model": "GOLD WING GL1800",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "VFR1200F",
        "variants": [
          "DCT",
          "STD"
        ]
      },
      {
        "model": "VT 1300CX",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MINI STREET XTREME",
        "variants": [
          "125CC"
        ]
      },
      {
        "model": "GOLD WING",
        "variants": [
          "DCT",
          "GL 1800 AIRBAG",
          "GL-1800",
          "STANDARD",
          "TOUR"
        ]
      },
      {
        "model": "CB1000R+",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CBR 650R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CB350RS",
        "variants": [
          "STANDARD",
          "DUAL TONE",
          "DLX",
          "DLX PRO DUAL TONE",
          "MONOTONE OBD2",
          "DUAL TONE OBD2",
          "SAB OBD2",
          "DLX PRO MONOTONE"
        ]
      },
      {
        "model": "CB500X",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CB350 RS",
        "variants": [
          "MONOTONE BS VI"
        ]
      },
      {
        "model": "SHINE",
        "variants": [
          "STD",
          "STD",
          "ELEC ST DISC BRAKE AWS",
          "ELEC ST DISC BRAKE AWS",
          "ELEC ST DRUM BRAKE AWS",
          "ELEC ST DRUM BRAKE AWS",
          "KICK ST DRUM BRAKE AWS",
          "KICK ST DRUM BRAKE SW",
          "KICK ST DRUM BRAKE SW",
          "ES DS ALLOY",
          "LTD ED DRUM BSVI",
          "LTD ED DISC BSVI",
          "CELEBRATION EDTN DRUM BSVI",
          "CELEBRATION EDTN DISC BSVI",
          "100 OBD2 BSVI",
          "125 DRUM OBD2 BSVI",
          "125 DISC OBD2 BSVI",
          "STD",
          "CBS",
          "SELF START DISC BRAKE",
          "SELF START ALLOY WHEEL",
          "125",
          "150",
          "150 ES DISC BRAKE",
          "150 ES DRUM BRAKE",
          "DISC BS6",
          "DISC SS CAST WHEEL",
          "DRUM BSVI",
          "KDS",
          "SDCA",
          "SDRA",
          "DRUM BS VI",
          "DISC BS VI",
          "125 DURM OBD2",
          "125 DISC OBD2",
          "100 OBD2",
          "STANDARD",
          "125 DISK-BSVI",
          "125 DRUM-BSVI",
          "125 DISK BS6",
          "125 DLX BSVI",
          "CELEBRATION EDITION DISC",
          "CELEBRATION EDITION DRUM",
          "DISC SS",
          "SPOKE",
          "DRUM ALLOY",
          "ALLOY",
          "DISK ALLOY",
          "DISK LIMITIED BS VI",
          "DRUM LIMITIED BS VI",
          "KICK DRUM & SPOKE",
          "KICK DRUM & ALLOY",
          "SELF DRUM & ALLOY",
          "SELF DISC & ALLOY",
          "SELF DRUM & SPOKE",
          "KICK DISCK & ALLOY",
          "DISC BRAKE ALLOY WHEEL",
          "KICK START",
          "SELF START",
          "ALLOY WHEEL"
        ]
      },
      {
        "model": "CB200X",
        "variants": [
          "DX"
        ]
      },
      {
        "model": "SP125",
        "variants": [
          "DC",
          "DISK OBD2",
          "DRUM BRAKE ALLOY WHEEL OBD2",
          "DISK BRAKE ALLOY WHEEL OBD2",
          "ELECTRIC START DRUM BRAKE ALLOY WHEEL",
          "ELECTRIC START DISC BRAKE ALLOY WHEEL"
        ]
      },
      {
        "model": "DIO 125",
        "variants": [
          "SMART OBD2",
          "STD"
        ]
      },
      {
        "model": "LIVO",
        "variants": [
          "DRUM",
          "DISC",
          "ELEC ST DISC BRAKE AW",
          "ELEC ST DRUM BRAKE AW",
          "STD BSIV",
          "DLX BSIV",
          "CBS BSIV",
          "DRUM CBS",
          "DISC CBS",
          "CBF 110 SG",
          "SELF DISC ALLOY",
          "SELF DRUM ALLOY",
          "DISC CBS",
          "DRUM CBS",
          "DRUM BS VI",
          "DISC BS VI",
          "DISC OBD2",
          "DRUM OBD2",
          "SELF DRUM ALLOY",
          "SELF DISC ALLOY",
          "DRUM BS6",
          "DISC BS6",
          "LIVO SELF DRUM BS VI",
          "LIVO SELF DISC BS VI",
          "LIVO SELF DRUM CBS",
          "SELF DISC CBS"
        ]
      },
      {
        "model": "SP160",
        "variants": [
          "DOUBLE DISC"
        ]
      },
      {
        "model": "SCOOTER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CB HORNET",
        "variants": [
          "R CBS",
          "R STD",
          "160R SPL EDTN STD",
          "160R SPL EDTN ABS",
          "2.0 ABS BSVI",
          "2.0 REPSOL EDITION",
          "160R DISC CBS",
          "2.0 DLX",
          "160R SPL EDTN CBS",
          "160R CBS",
          "160R SPECIAL EDITION CBS",
          "160R SPECIAL EDITION STANDARD",
          "160 R",
          "160 R ABS DLX",
          "160 R ABS STD",
          "160R CBS",
          "160 SPECIAL EDITION - CBS",
          "160R EAS W F DISC R DRUM BRAKE BSIV",
          "SPECIAL EDITION - STANDARD",
          "160 R STD",
          "160R STD",
          "160R SPL EDITION STD",
          "R SPL EDITION DLX",
          "160R SPL EDITION DLX"
        ]
      },
      {
        "model": "CB 1000R",
        "variants": [
          "ABS",
          "STD"
        ]
      },
      {
        "model": "CB 1000RR",
        "variants": [
          "FIRE BLADE"
        ]
      },
      {
        "model": "WING",
        "variants": [
          "VALKYRIE"
        ]
      },
      {
        "model": "XBLADE",
        "variants": [
          "DLX BSVI",
          "DUAL DISC ABS"
        ]
      },
      {
        "model": "CB 300R",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SHINE SP 125",
        "variants": [
          "DISC BSVI",
          "DRUM CBS BSVI",
          "DLX BSVI",
          "STD BSVI"
        ]
      },
      {
        "model": "SP 125",
        "variants": [
          "OBD2 DRUM BSVI",
          "OBD2 DISC BSVI",
          "DISC",
          "DISC BSVI",
          "DRUM",
          "DRUM BSVI",
          "DRUM OBD2",
          "DISC OBD2"
        ]
      },
      {
        "model": "H NESS CB 350",
        "variants": [
          "DLX BSVI",
          "DLX PRO BSVI",
          "MONO TONE BSVI",
          "DUAL TONE BSVI",
          "ANNIVERSARY ADDITION BSVI",
          "OBD2 DLX 1 BSVI",
          "OBD2 DLX 2 BSVI",
          "OBD2 DLX 3 BSVI"
        ]
      },
      {
        "model": "MINI STREET",
        "variants": [
          "XTREME 125"
        ]
      },
      {
        "model": "CB 350",
        "variants": [
          "RS DT BSVI",
          "RS MT BSVI",
          "RS SAB OBD2 BSVI",
          "RS"
        ]
      },
      {
        "model": "NXR 160",
        "variants": [
          "ESD"
        ]
      },
      {
        "model": "TMX 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CB 200X",
        "variants": [
          "STD",
          "DX BSVI"
        ]
      },
      {
        "model": "CBR",
        "variants": [
          "500 R",
          "STD",
          "1000R ABS",
          "1000R ABS PEARL SIENA RED",
          "1000 STD",
          "1000RR BLACK",
          "1000RR REPSOL",
          "1000RR SPECIAL EDITION",
          "1000RR STANDARD",
          "1000RR WHITE",
          "150R DLX",
          "150R STD",
          "150R ABS",
          "250R ABS TRICOLOR",
          "250R ABS REPSOL",
          "250R STD",
          "250R STANDARD TRICOLOR",
          "600 STD",
          "650 F",
          "1000RR SPORTS BIKE",
          "929RR SPORTS BIKE",
          "SPORTS BIKE",
          "900 FIREBLADE",
          "150STD",
          "125R",
          "250STD",
          "400 R",
          "500 CC",
          "600 RR",
          "600F",
          "650R",
          "ABS",
          "DLX",
          "1000RR",
          "STD",
          "150R",
          "250 R",
          "250STD TRI",
          "250ABS",
          "250ABS TRI",
          "929 RR"
        ]
      },
      {
        "model": "CB 500X",
        "variants": [
          "ADVENTURE"
        ]
      },
      {
        "model": "CB 300F",
        "variants": [
          "DLX BSVI",
          "DLX PRO BSVI"
        ]
      },
      {
        "model": "ACTIVA I",
        "variants": [
          "BSIV DLX",
          "STD",
          "DLX",
          "110",
          "STD"
        ]
      },
      {
        "model": "VISION",
        "variants": [
          "110"
        ]
      },
      {
        "model": "PCX",
        "variants": [
          "150",
          "125"
        ]
      },
      {
        "model": "BENLY E",
        "variants": [
          "BENLY E2"
        ]
      },
      {
        "model": "CB 500 X",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CBR 1000RR",
        "variants": [
          "SP"
        ]
      },
      {
        "model": "GOLDWING GL 1800",
        "variants": [
          "MOTORCYCLE"
        ]
      },
      {
        "model": "REBEL 300",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "X BLADE",
        "variants": [
          "STD",
          "ABS",
          "STD",
          "STD BS VI",
          "DUAL DISC",
          "DISC BSVI",
          "DUAL DISC BS6",
          "DISC",
          "ABS DLX BS VI"
        ]
      },
      {
        "model": "CB H NESS",
        "variants": [
          "DLX BSVI"
        ]
      },
      {
        "model": "HNESS CB350 DLX2 MONOTONE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "HONDA ACTIVA 4G",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PRATIK TW",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACTIVA 3G SCV110F",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACTIVA 125 - DISC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACTIVA 6G LED",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "HONDA DIO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CBR 150",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "AVIATOR 110",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "HONDA VTR",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VFR 1200 FDA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "HONDA HURRICANE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CBR.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "HONDA CBR",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VT 1300",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SCV 100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STUNNER",
        "variants": [
          "STD",
          "SDCA",
          "STD"
        ]
      },
      {
        "model": "ACTIVA 125 FDRDRMALLWHLL2 BS6",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACTIVA DLX",
        "variants": [
          "STD",
          "110"
        ]
      },
      {
        "model": "AVIATOR BS II",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACTIVA 3G EAS KS CBS BS3",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACTIVA 5G DLX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACTIVA 5G STD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DIO STD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CB HORNET 160R STD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CB HORNET 160R ABS DLX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CB HORNET 160R CBS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CB SHINE DISC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CB UNICORN 150 ABS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACTIVA 125 DISC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SP 125 DISK",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACTIVA 6G DLX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACTIVA ELECTIRC AS  AND KS  AN",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CB 750",
        "variants": [
          "STD",
          "STANDARD"
        ]
      },
      {
        "model": "CBR 650",
        "variants": [
          "STD",
          "F",
          "650 R"
        ]
      },
      {
        "model": "ACTIVA.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DIO.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CD",
        "variants": [
          "110 DREAM DX",
          "110 DREAM STD"
        ]
      },
      {
        "model": "CB DAZZLER",
        "variants": [
          "SPORTS",
          "5ID",
          "6ID"
        ]
      },
      {
        "model": "CB300R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CBF",
        "variants": [
          "250",
          "STD 150",
          "150 STD"
        ]
      },
      {
        "model": "CD-110",
        "variants": [
          "DLX",
          "DREAM CBS CARRIER",
          "DREAM CBS DLX CARRIER",
          "DREAM SELF DRUM CARRIER",
          "DREAM SELF DRUM GRAB RAIL",
          "DREAM DLX OBD2",
          "DREAM",
          "DREAM DLX BS6",
          "DREAM STD BS6"
        ]
      },
      {
        "model": "VFR",
        "variants": [
          "1200 F",
          "800",
          "800 STD"
        ]
      },
      {
        "model": "HONDA 1500",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "HORNET",
        "variants": [
          "900",
          "2.0 REPSOL EDITION BS6",
          "2.0 BS6",
          "900"
        ]
      },
      {
        "model": "INTERCEPTOR",
        "variants": [
          "VFR800"
        ]
      },
      {
        "model": "METROPOLITAN",
        "variants": [
          "50",
          "49 CC SCOOTER"
        ]
      },
      {
        "model": "RUNE",
        "variants": [
          "NRX 1800",
          "STD"
        ]
      },
      {
        "model": "VT",
        "variants": [
          "1300 CX",
          "1300CXA"
        ]
      },
      {
        "model": "VTR",
        "variants": [
          "250",
          "1000F SUPERHAWK"
        ]
      },
      {
        "model": "XL",
        "variants": [
          "600V",
          "10000",
          "1000V STD"
        ]
      },
      {
        "model": "X4",
        "variants": [
          "IMPORTED MOTORCYCLE",
          "STD"
        ]
      },
      {
        "model": "CB300F",
        "variants": [
          "DELUXE",
          "DELUXE PRO",
          "DLX..",
          "DLX PRO.."
        ]
      },
      {
        "model": "SP 160",
        "variants": [
          "SINGLE DISC",
          "DUAL DISC"
        ]
      },
      {
        "model": "HNESS CB",
        "variants": [
          "350 ANNIVERSARY EDITION",
          "350 DLX2 MONOTONE BSVI",
          "350 DLX PRO BS6",
          "350 DLX BS6",
          "350 DLX DUAL TONE BS6"
        ]
      },
      {
        "model": "CBX",
        "variants": [
          "DISC",
          "250 TWISTER",
          "125 CUSTOM"
        ]
      },
      {
        "model": "CB650R",
        "variants": [
          "STANDARD BSVI"
        ]
      },
      {
        "model": "CG",
        "variants": [
          "125",
          "125 CARGO"
        ]
      },
      {
        "model": "HNESS CB 350",
        "variants": [
          "DLX PRO CHROME",
          "DLX OBD2",
          "DLX",
          "DLX PRO"
        ]
      },
      {
        "model": "SHINE 100",
        "variants": [
          "SELF DRUM  ALLOY"
        ]
      },
      {
        "model": "CB 300",
        "variants": [
          "R"
        ]
      },
      {
        "model": "ST 70",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CB 900 HORNET",
        "variants": [
          "SPORT BIKE"
        ]
      },
      {
        "model": "CB 350 RS",
        "variants": [
          "DLX PRO MONOTONE",
          "DUALTONE",
          "MONOTONE"
        ]
      },
      {
        "model": "CB 650",
        "variants": [
          "R"
        ]
      },
      {
        "model": "GOLD WING TOUR",
        "variants": [
          "DCT AIRBAG"
        ]
      },
      {
        "model": "HONDA 150",
        "variants": [
          "C 95"
        ]
      },
      {
        "model": "CB 550",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CB 200 X",
        "variants": [
          "ABS BS VI"
        ]
      },
      {
        "model": "CB 400",
        "variants": [
          "R"
        ]
      },
      {
        "model": "XL 250 R",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FORZA",
        "variants": [
          "300"
        ]
      },
      {
        "model": "CBR1000RR",
        "variants": [
          "BIG BIKE"
        ]
      },
      {
        "model": "XL 1000 V",
        "variants": [
          "IMPORTED MOTORCYCLE"
        ]
      },
      {
        "model": "CBR 900",
        "variants": [
          "SPORTSBIKE - FIREBLADE"
        ]
      },
      {
        "model": "GIARNO",
        "variants": [
          "50 CC SCOOTER",
          "SCOOTER"
        ]
      },
      {
        "model": "CB 600 (HORNET)",
        "variants": [
          "SPORT BIKE"
        ]
      },
      {
        "model": "CBR 954 RR",
        "variants": [
          "SPORTS BIKE"
        ]
      },
      {
        "model": "XRV 750",
        "variants": [
          "TWIN"
        ]
      },
      {
        "model": "SILVER WING",
        "variants": [
          "400 CC SCOOTER",
          "SCOOTER"
        ]
      },
      {
        "model": "CBR 1100 XX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CB 600 HORNET",
        "variants": [
          "SPORTS BIKE"
        ]
      },
      {
        "model": "CX",
        "variants": [
          "1"
        ]
      },
      {
        "model": "TWISTER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "NXR",
        "variants": [
          "150 STD"
        ]
      },
      {
        "model": "SCV100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SHADOW SPIRIT",
        "variants": [
          "750 STD"
        ]
      },
      {
        "model": "NX",
        "variants": [
          "400 STD"
        ]
      },
      {
        "model": "X11",
        "variants": [
          "SPORTS BIKE"
        ]
      },
      {
        "model": "XR",
        "variants": [
          "650L STD"
        ]
      }
    ]
  },
  {
    "make": "HOP",
    "models": [
      {
        "model": "LEO BASIC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LEO STANDARD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LEO EXTENDED",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LYF 20 AH STD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LYF 34 AH DLX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "OXO",
        "variants": [
          "STANDARD",
          "X",
          "BIKE"
        ]
      },
      {
        "model": "LEO",
        "variants": [
          "72V 20 AH",
          "E 72V 34AH",
          "STANDARD",
          "EXTENDED"
        ]
      },
      {
        "model": "LYF",
        "variants": [
          "72V 20 AH",
          "E 72V 34AH",
          "STANDARD",
          "EXTENDED"
        ]
      },
      {
        "model": "OXO PRO",
        "variants": [
          "72 V 6200 W"
        ]
      }
    ]
  },
  {
    "make": "HOP ELECTRIC",
    "models": [
      {
        "model": "HOP ONE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "HOP LEO",
        "variants": [
          "EXTENDED -LITHIUM BATTERY 72V 34AH 2500 W",
          "EXTENDED -LITHIUM BATTERY 72V 20AH 2500 W",
          "EXTENDED -LITHIUM BATTERY 2500 W",
          "LITHIUM BATTERY 1500 W"
        ]
      },
      {
        "model": "HOP LYF",
        "variants": [
          "LITHIUM BATTERY 1200 W",
          "EXTENDED- LITHIUM BATTERY 2000 W"
        ]
      },
      {
        "model": "HOP NXT",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "OXO",
        "variants": [
          "X E-BIKE LITHIUM ION",
          "PRO PACKAGE",
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "HRE EV PVT LTD",
    "models": [
      {
        "model": "WINGZ",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "HRS ENTERPRISES",
    "models": [
      {
        "model": "BOLT",
        "variants": [
          "LIFEPO4 LITHIUM IRON  48V / 30AH",
          "LITHIUM ION 60V / 25AH",
          "LITHIUM ION 60V / 30AH",
          "LITHIUM ION 60V / 36AH",
          "LIFEPO4 LITHIUM IRON  48V / 24AH"
        ]
      },
      {
        "model": "PACE",
        "variants": [
          "LITHIUM ION 60V / 25AH",
          "LITHIUM ION 60V / 30AH",
          "LITHIUM ION 60V / 36AH",
          "LIFEPO4 LITHIUM IRON  48V / 24AH",
          "LIFEPO4 LITHIUM IRON  48V / 30AH"
        ]
      },
      {
        "model": "SPORTS",
        "variants": [
          "LITHIUM ION 60V / 25AH",
          "LITHIUM ION 60V / 30AH",
          "LITHIUM ION 60V / 36AH",
          "LIFEPO4 LITHIUM IRON  48V / 24AH",
          "LIFEPO4 LITHIUM IRON  48V / 30AH"
        ]
      },
      {
        "model": "WIND",
        "variants": [
          "LITHIUM ION 60V / 25AH",
          "LITHIUM ION 60V / 30AH",
          "LITHIUM ION 60V / 36AH",
          "LIFEPO4 LITHIUM IRON  48V / 24AH",
          "LIFEPO4 LITHIUM IRON  48V / 30AH"
        ]
      }
    ]
  },
  {
    "make": "HUCH MOTO CORP",
    "models": [
      {
        "model": "SRIZEE",
        "variants": [
          "LITHIUM 48V 26AH"
        ]
      }
    ]
  },
  {
    "make": "HUNDA ELECTRIC VEHICLE",
    "models": [
      {
        "model": "ELECTRO BLAZE",
        "variants": [
          "LI 48V 24AH",
          "PLUS LI 48V 24AH",
          "S COOL LI 48V 24AH",
          "SX LI 48V 24AH",
          "STRIM LI 48V 24AH",
          "LI 60V 24AH",
          "PLUS LI 60V 24AH",
          "S COOL LI 60V 24AH",
          "SX LI 60V 24AH",
          "STRIM LI 60V 24AH",
          "LI 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "HUSQVARNA",
    "models": [
      {
        "model": "SVARTPILEN 250",
        "variants": [
          "STD",
          "STANDARD"
        ]
      },
      {
        "model": "VITPILEN",
        "variants": [
          "250 BSVI",
          "250 STD",
          "250CC BIKE"
        ]
      },
      {
        "model": "NUDA",
        "variants": [
          "900 R",
          "900R"
        ]
      },
      {
        "model": "SVARTPILEN",
        "variants": [
          "250 STD BS6",
          "250CC BIKE"
        ]
      },
      {
        "model": "TC 125",
        "variants": [
          "125 CC"
        ]
      },
      {
        "model": "FE 450",
        "variants": [
          "450 CC BIKE"
        ]
      }
    ]
  },
  {
    "make": "HYOSUNG",
    "models": [
      {
        "model": "ST7",
        "variants": [
          "STANDARD",
          "STD",
          "ROYALE",
          "STD",
          "STD",
          "678"
        ]
      },
      {
        "model": "GT250R",
        "variants": [
          "STANDARD",
          "STD",
          "BIG BOY"
        ]
      },
      {
        "model": "GT650R",
        "variants": [
          "STANDARD",
          "NAKED",
          "SINGLE TONE",
          "STD"
        ]
      },
      {
        "model": "AQUILA PRO650",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "GT 650N",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "AQUILA GV250",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "GV 250",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "AQUILA",
        "variants": [
          "GV 650",
          "GV 250",
          "PRO GV 650"
        ]
      },
      {
        "model": "GV",
        "variants": [
          "250",
          "AQUILA",
          "AQUILA PRO"
        ]
      },
      {
        "model": "GT 250",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GT 650",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GT",
        "variants": [
          "250R",
          "650R",
          "650N"
        ]
      },
      {
        "model": "GT250",
        "variants": [
          "RN"
        ]
      },
      {
        "model": "GT25OR",
        "variants": [
          "BIG BOY"
        ]
      },
      {
        "model": "GT650",
        "variants": [
          "DUAL TONE",
          "STD",
          "N"
        ]
      },
      {
        "model": "GV250",
        "variants": [
          "CRUISER",
          "AQUILA"
        ]
      },
      {
        "model": "GV650",
        "variants": [
          "CRUISER",
          "AQUILA PRO"
        ]
      },
      {
        "model": "RT",
        "variants": [
          "125 D"
        ]
      },
      {
        "model": "GT 650 R",
        "variants": [
          "SINGLE COLOUR",
          "DUAL COLOUR",
          "STD"
        ]
      },
      {
        "model": "RT D",
        "variants": [
          "RT D"
        ]
      },
      {
        "model": "GT 650 N",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "I SCOOT",
    "models": [
      {
        "model": "SCOOT 1",
        "variants": [
          "LITHIUM 72V 30AH",
          "LITHIUM 72V 36AH"
        ]
      }
    ]
  },
  {
    "make": "I- SCOOT",
    "models": [
      {
        "model": "SCOOT 1",
        "variants": [
          "SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "I SCOOT MOTORS INDIA",
    "models": [
      {
        "model": "SCOOT 1",
        "variants": [
          "LP 72V 30AH 1.5KW",
          "LP 72V 24AH 1.5KW",
          "LP 72V 36AH 1.5 KW"
        ]
      }
    ]
  },
  {
    "make": "IBENT ENERGY",
    "models": [
      {
        "model": "PRIMAX LX",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "PRODIGY LX",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "BREEZ LX",
        "variants": [
          "LITHIUM 60V"
        ]
      }
    ]
  },
  {
    "make": "IDEAL JAWA LTD.",
    "models": [
      {
        "model": "YEZDI",
        "variants": [
          "STD."
        ]
      }
    ]
  },
  {
    "make": "IDIOT BOOTLEGGER",
    "models": [
      {
        "model": "ASHVETAK",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "IME",
    "models": [
      {
        "model": "RAPID",
        "variants": [
          "LITHIUM ION 60V 36AH",
          "LITHIUM ION 60V 52AH",
          "LITHIUM ION 60V 72AH"
        ]
      }
    ]
  },
  {
    "make": "IME VEHICLES PVT LTD",
    "models": [
      {
        "model": "I3",
        "variants": [
          "48V/28AH",
          "60V/28AH",
          "48V/24AH",
          "48V/30AH",
          "60V/30AH"
        ]
      },
      {
        "model": "I5",
        "variants": [
          "48V/28AH",
          "60V/28AH",
          "48V/24AH",
          "48V/30AH",
          "60V/30AH"
        ]
      },
      {
        "model": "M3",
        "variants": [
          "48V/28AH",
          "60V/28AH",
          "48V/24AH",
          "48V/30AH",
          "60V/30AH"
        ]
      },
      {
        "model": "M5",
        "variants": [
          "48V/28AH",
          "60V/28AH",
          "48V/24AH",
          "48V/30AH",
          "60V/30AH"
        ]
      },
      {
        "model": "RAPID",
        "variants": [
          "PRO",
          "ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "IMPRESS EV",
    "models": [
      {
        "model": "I WAVE",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "INDIAN",
    "models": [
      {
        "model": "CHIEF VINTAGE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ROADMASTER",
        "variants": [
          "DUAL TONE",
          "SOLID COLOR",
          "CLASSIC STD",
          "TOURING",
          "STD"
        ]
      },
      {
        "model": "SCOUT",
        "variants": [
          "RED",
          "SIXTY",
          "SMOKE BLACK",
          "SMOKE SILVER",
          "THUNDER BLACK",
          "1131",
          "ABS",
          "STD"
        ]
      },
      {
        "model": "CHIEF",
        "variants": [
          "CHIEF CLASSIC",
          "CHIEF VINTAGE",
          "CHIEFTAIN",
          "DARK HORSE"
        ]
      },
      {
        "model": "SCOUT SIXTY",
        "variants": [
          "SIXTY STD",
          "STD"
        ]
      },
      {
        "model": "SPRINGFIELD",
        "variants": [
          "ABS"
        ]
      },
      {
        "model": "CHIEF DARK HORSE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CHIEFTAIN",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CHIEF CLASSIC",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "INDIAN MOTORCYCLE",
    "models": [
      {
        "model": "CHIEF VINTAGE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CHIEF CLASSIC",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CHIEF DARK HORSE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CHIEFTAIN",
        "variants": [
          "STANDARD",
          "DARK HORSE"
        ]
      },
      {
        "model": "CHIEFTAIN DARK HORSE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "ROADMASTER",
        "variants": [
          "STANDARD",
          "STD 1811 CC"
        ]
      },
      {
        "model": "SCOUT",
        "variants": [
          "STANDARD",
          "STD",
          "SIXTY",
          "SIXTY (999 CC)"
        ]
      },
      {
        "model": "SCOUT BOBBER",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "SCOUT SIXTY",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPRINGFIELD",
        "variants": [
          "STANDARD",
          "STD",
          "SPRINGFIELD",
          "DARK HORSE"
        ]
      },
      {
        "model": "CHIEFTAIN ELITE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "FTR 1200",
        "variants": [
          "S",
          "RACE REPLICA",
          "STD"
        ]
      },
      {
        "model": "ROADMASTER CLASSIC",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "ROADMASTER ELITE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CHIEF",
        "variants": [
          "CLASSIC",
          "VINTAGE"
        ]
      }
    ]
  },
  {
    "make": "INDOM",
    "models": [
      {
        "model": "MOON",
        "variants": [
          "E SCOOTY",
          "STD",
          "STD BS6",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "MOVE",
        "variants": [
          "E SCOOTY",
          "STD",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "EASY",
        "variants": [
          "E SCOOTY",
          "STD",
          "PLUS E SCOOTY",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "WAR",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "EASY PLUS",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      }
    ]
  },
  {
    "make": "INDUS ELECTRIC",
    "models": [
      {
        "model": "BLITZ",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "E-COUPE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "E-STATUS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "EAZY",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "WHIZ",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "YO BIKE",
        "variants": [
          "EX",
          "SMART",
          "SPEED",
          "SPIN",
          "TREND",
          "TRUST",
          "TUFF",
          "SMART",
          "SPIN",
          "TREND",
          "TUFF"
        ]
      },
      {
        "model": "E-TRENDY",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "INFINITY",
    "models": [
      {
        "model": "E-1",
        "variants": [
          "ELECTRIC",
          "LIMITED EDITION"
        ]
      }
    ]
  },
  {
    "make": "INGO",
    "models": [
      {
        "model": "FLEE",
        "variants": [
          "ELECTRIC",
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "INNOVME",
    "models": [
      {
        "model": "I-3",
        "variants": [
          "STD",
          "PLUS"
        ]
      },
      {
        "model": "I-5",
        "variants": [
          "STD",
          "PLUS"
        ]
      },
      {
        "model": "M-3",
        "variants": [
          "STD",
          "PLUS"
        ]
      },
      {
        "model": "M-5",
        "variants": [
          "STD",
          "PLUS"
        ]
      }
    ]
  },
  {
    "make": "IPACIFIC",
    "models": [
      {
        "model": "ROADY",
        "variants": [
          "SPORT"
        ]
      }
    ]
  },
  {
    "make": "IVOOMI",
    "models": [
      {
        "model": "ECO",
        "variants": [
          "STD",
          "PLUS",
          "PLUS"
        ]
      },
      {
        "model": "CITY",
        "variants": [
          "STD",
          "ECO",
          "STANDARD"
        ]
      },
      {
        "model": "JEET",
        "variants": [
          "PRO",
          "X",
          "DUAL BATTERY 60V 35AH",
          "STANDARD",
          "PRO"
        ]
      },
      {
        "model": "S1",
        "variants": [
          "LITHIUM",
          "LITE LITHIUM 60V 25AH",
          "200 LITHIUM 60V 35AH",
          "ELECTRIC"
        ]
      },
      {
        "model": "CITY 1",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "JEETX",
        "variants": [
          "60V"
        ]
      }
    ]
  },
  {
    "make": "IVOOMI INNOVATION PVT LTD",
    "models": [
      {
        "model": "S1",
        "variants": [
          "LITHIUM ION  60 V 35AH",
          "80",
          "240"
        ]
      },
      {
        "model": "CITY ECO",
        "variants": [
          "LITHIUM ION  60 V 25AH"
        ]
      },
      {
        "model": "CITY ECO PLUS",
        "variants": [
          "LITHIUM ION  60 V 35AH"
        ]
      },
      {
        "model": "CITY",
        "variants": [
          "LITHIUM ION  60 V 35AH"
        ]
      },
      {
        "model": "JEET",
        "variants": [
          "LITHIUM ION  60 V 25AH"
        ]
      },
      {
        "model": "JEET PRO",
        "variants": [
          "LITHIUM ION  60 V 35AH"
        ]
      },
      {
        "model": "IVOOMI CITY 1",
        "variants": [
          "LITHIUM 60 V 35AH"
        ]
      },
      {
        "model": "IVOOMI CITY",
        "variants": [
          "LITHIUM 60 V 35AH"
        ]
      },
      {
        "model": "JEET X",
        "variants": [
          "LITHIUM ION  60V"
        ]
      }
    ]
  },
  {
    "make": "IZANAU ELECTRICALS",
    "models": [
      {
        "model": "GARNET 75",
        "variants": [
          "LI NMC 60V 30AH 3KW"
        ]
      },
      {
        "model": "GARNET 95",
        "variants": [
          "LI NMC 60V 35AH 3KW"
        ]
      },
      {
        "model": "GARNET",
        "variants": [
          "GARNET 60 LITHIUM BATTERY 60 V 40 AH"
        ]
      },
      {
        "model": "OPAL 80",
        "variants": [
          "LIFEPO4 60V 30AH 2KW"
        ]
      },
      {
        "model": "OPAL 60",
        "variants": [
          "LIFEPO4 60V 24AH 2KW",
          "E BIKE HANDICAPED",
          "E BIKE"
        ]
      },
      {
        "model": "SAPPHIRE 100",
        "variants": [
          "LI NMC 60V 30AH 2KW"
        ]
      },
      {
        "model": "SAPPHIRE 75",
        "variants": [
          "LIFEPO4 48V 30AH 2KW"
        ]
      },
      {
        "model": "PEARL PLUS P 45",
        "variants": [
          "LI NMC 48V 50AH 2KW"
        ]
      },
      {
        "model": "OPAL 100",
        "variants": [
          "LI NMC 60V 30AH 2KW"
        ]
      },
      {
        "model": "SAPPHIRE 60",
        "variants": [
          "LIFEPO4 48 V 24AH 2KW"
        ]
      },
      {
        "model": "OPAL 75 PLUS",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "J P MACHINERY",
    "models": [
      {
        "model": "SUPER PRO",
        "variants": [
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "SEMO",
        "variants": [
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "FANTASY",
        "variants": [
          "LITHIUM ION  60V 30AH",
          "LITHIUM ION  60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "JANGID",
    "models": [
      {
        "model": "ATEN",
        "variants": [
          "LITHIUM 60V/30AH",
          "LITHIUM 60V/26AH",
          "LEAD 60V/28AH"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "LITHIUM 60V/30AH",
          "LITHIUM 60V/26AH",
          "LEAD 60V/28AH"
        ]
      },
      {
        "model": "HI ENEREY",
        "variants": [
          "LITHIUM 60V/30AH",
          "LITHIUM 60V/26AH",
          "LEAD 60V/28AH"
        ]
      }
    ]
  },
  {
    "make": "JANGID MOTORS",
    "models": [
      {
        "model": "TRACTACTION",
        "variants": [
          "LEAD ACID 60 V 28 AH"
        ]
      },
      {
        "model": "HI  ENERGY PLUS",
        "variants": [
          "LEAD ACID 60 V 28 AH",
          "LITHIUM 60 V 26 AH",
          "LITHIUM 60 V 30 AH"
        ]
      },
      {
        "model": "HIP HOP PLUS",
        "variants": [
          "LEAD ACID 60 V 28 AH",
          "LITHIUM 60 V 26 AH",
          "LITHIUM 60 V 30 AH"
        ]
      },
      {
        "model": "SLEEK",
        "variants": [
          "LEAD ACID 60 V 28 AH"
        ]
      },
      {
        "model": "RISE",
        "variants": [
          "LEAD ACID 60 V 28 AH"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 26AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "HI ENEREY",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 26AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ATEN",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 26AH"
        ]
      },
      {
        "model": "ATEN PLUS",
        "variants": [
          "LEAD ACID 60 V 28 AH",
          "LITHIUM 60 V 26 AH",
          "LITHIUM 60 V 30 AH"
        ]
      },
      {
        "model": "HIP HOP",
        "variants": [
          "LEAD ACID 60 V 28 AH",
          "LITHIUM 60 V 26 AH",
          "LITHIUM 60 V 30 AH"
        ]
      },
      {
        "model": "HI ENERGY LD",
        "variants": [
          "LITHIUM 60 V 26 AH",
          "LITHIUM 60 V 30 AH",
          "LEAD ACID 60 V 28 AH"
        ]
      }
    ]
  },
  {
    "make": "JAWA",
    "models": [
      {
        "model": "300",
        "variants": [
          "SINGLE CHANNEL ABS BS VI",
          "DUAL CHANNEL ABS BS VI",
          "STANDARD",
          "SINGLE CHANNEL ABS BS6",
          "DUAL CHANNEL ABS BS6"
        ]
      },
      {
        "model": "FORTY TWO",
        "variants": [
          "SINGLE CHANNEL ABS BS VI",
          "DUAL CHANNEL ABS BS VI",
          "STANDARD",
          "VERSION 2.1",
          "COSMIC CARBON",
          "ABS",
          "STD",
          "BOBBER JASPER RED DUAL TONE",
          "BOBBER MYSTIC COPPER",
          "BOBBER MOONSTONE WHITE",
          "SINGLE CHANNEL ABS BS6",
          "DUAL CHANNEL ABS BS6",
          "BOBBER JASPER RED"
        ]
      },
      {
        "model": "PERAK",
        "variants": [
          "STANDARD",
          "STD",
          "DUAL CHANNEL ABS BS6"
        ]
      },
      {
        "model": "JAWA",
        "variants": [
          "SINGLE CHANEL ABS",
          "42 SINGLE CHANEL ABS",
          "DUAL CHANEL ABS",
          "42 DUAL CHANEL ABS",
          "OLD BIKE",
          "42 DUAL CHANEL ABS BS6",
          "SPECIAL EDITION BSVI",
          "42 BOBBER DUALCHANNEL ABS BSVI",
          "ROAD KING",
          "STD",
          "ABS",
          "STD",
          "42",
          "42 ABS",
          "42 BOBBER DUAL ABS",
          "42 DUAL ABS OBD2",
          "DUAL ABS",
          "PERAK",
          "42 DUAL ABS"
        ]
      },
      {
        "model": "CLASSIC",
        "variants": [
          "300 DUAL ABS",
          "SINGLE ABS"
        ]
      },
      {
        "model": "YEZDI",
        "variants": [
          "ROADSTER",
          "ADVENTURE",
          "SCRAMBLER"
        ]
      }
    ]
  },
  {
    "make": "JAWA MOTORS",
    "models": [
      {
        "model": "JAWA",
        "variants": [
          "293 CC MOTORCYCLE",
          "250 MOTORCYCLE"
        ]
      },
      {
        "model": "YEZDI 60",
        "variants": [
          "MOPED"
        ]
      },
      {
        "model": "YEZDI 175",
        "variants": [
          "175 CC MOTORCYCLE75"
        ]
      },
      {
        "model": "CZ 250",
        "variants": [
          "250 CC MOTORCYCLE"
        ]
      },
      {
        "model": "YEZDI 250",
        "variants": [
          "250 CC MOTORCYCLE"
        ]
      },
      {
        "model": "YEZDI 350",
        "variants": [
          "350 CC MOTORCYCLE"
        ]
      },
      {
        "model": "JAWA 250",
        "variants": [
          "250 CC MOTORCYCLE"
        ]
      },
      {
        "model": "YEZDI",
        "variants": [
          "250 MOTORCYCLE"
        ]
      },
      {
        "model": "CZ",
        "variants": [
          "250 MOTORCYCLE"
        ]
      }
    ]
  },
  {
    "make": "JAYNA AUTOMOBILES LLP",
    "models": [
      {
        "model": "PETRA DX LETHIUM",
        "variants": [
          "PETRA DX LETHIUM"
        ]
      },
      {
        "model": "RYNER LETHIUM",
        "variants": [
          "RYNER LETHIUM"
        ]
      },
      {
        "model": "RYNER DX LETHIUM",
        "variants": [
          "RYNER DX LETHIUM"
        ]
      },
      {
        "model": "CHARLIE",
        "variants": [
          "LETHIUM"
        ]
      }
    ]
  },
  {
    "make": "JIDOSHA",
    "models": [
      {
        "model": "MONK 21",
        "variants": [
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "MONK",
        "variants": [
          "LEAD ACID 60V/28AH"
        ]
      },
      {
        "model": "MAGNA SUPER",
        "variants": [
          "LITHIUM ION  60V 24AH"
        ]
      }
    ]
  },
  {
    "make": "JIDOSHA PVT LTD",
    "models": [
      {
        "model": "MONK 21",
        "variants": [
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "JITENDRA ELECTRIC VEHICLE",
    "models": [
      {
        "model": "JET 250",
        "variants": [
          "ECONOMY",
          "SUPER",
          "SUPERIOR",
          "XL PLUS",
          "XL 48 V LEAD ACID",
          "XL 60 V LEAD ACID",
          "XL PLUS 48 V LI-ION",
          "XL PLUS E BIKE"
        ]
      },
      {
        "model": "JET 320",
        "variants": [
          "48V 30AH",
          "60V 26AH"
        ]
      },
      {
        "model": "JMT 1000",
        "variants": [
          "E BIKE",
          "HS E BIKE",
          "STD E BIKE",
          "HS LITHIUM 60V 26AH",
          "HS LITHIUM 60V 34AH",
          "HS LITHIUM 48V 42AH",
          "3K LITHIUM 60V 26AH",
          "3K LITHIUM 60V 34AH"
        ]
      },
      {
        "model": "JMT CLASSIC",
        "variants": [
          "48V25AH LEAD ACID",
          "48V26AH LITHIUM ION",
          "60V26AH LITHIUM ION",
          "CITY LITHIUM ION 60V 26AH"
        ]
      },
      {
        "model": "DRIFT DX",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "JITENDRA EV",
    "models": [
      {
        "model": "JMT",
        "variants": [
          "1000 HS",
          "CLASSIC CITY",
          "1000 3K",
          "1000 HS 2K",
          "1000 HS 3K"
        ]
      },
      {
        "model": "JET",
        "variants": [
          "320"
        ]
      }
    ]
  },
  {
    "make": "JITENDRA NEW EV",
    "models": [
      {
        "model": "PVT LTD JMT1000HS",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "JITENDRA NEW EV TECH",
    "models": [
      {
        "model": "JET 320",
        "variants": [
          "LITHIUM ION 48 V 30 AH",
          "LITHIUM ION 60 V 26 AH"
        ]
      },
      {
        "model": "JMT 1000 HS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "JMT CLASSIC CITY",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "JET 250",
        "variants": [
          "XL PLUS LITHIUM",
          "XL 250 SUPERIOR",
          "SUPER M2 PGR LEAD ACID",
          "SUPER M1 PGR LEAD ACID",
          "XL 250",
          "ECONOMY",
          "XL PLUS",
          "SUPERIOR",
          "XL E BIKE"
        ]
      },
      {
        "model": "JMT 1000",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "JMT 1000 3K",
        "variants": [
          "LITHIUM ION 60 V 26 AH"
        ]
      }
    ]
  },
  {
    "make": "JIVA",
    "models": [
      {
        "model": "SNIPER",
        "variants": [
          "48V",
          "60V"
        ]
      },
      {
        "model": "TRIVO",
        "variants": [
          "48V",
          "60V"
        ]
      },
      {
        "model": "FUSION",
        "variants": [
          "60V",
          "48V"
        ]
      },
      {
        "model": "VISTA R",
        "variants": [
          "60V",
          "48V"
        ]
      },
      {
        "model": "AURUM",
        "variants": [
          "60V"
        ]
      }
    ]
  },
  {
    "make": "JIVA E BIKE",
    "models": [
      {
        "model": "VISTA",
        "variants": [
          "E BIKE",
          "BIG LITHIUM 60V 60AH"
        ]
      },
      {
        "model": "SNIPER",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "FUSION",
        "variants": [
          "LITHIUM 60V 60AH"
        ]
      }
    ]
  },
  {
    "make": "JIVA E BIKES",
    "models": [
      {
        "model": "AURUM",
        "variants": [
          "LITHIUM 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "JOY",
    "models": [
      {
        "model": "MIHOS",
        "variants": [
          "STANDARD LI 74V 40AH 1.5KW"
        ]
      },
      {
        "model": "WOLF ECO",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GENNEXT ECO",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "WOLFÂ (31AH)",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GEN NEXTÂ (31AH)",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "DELGO",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "BEAST",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "THUNDERBOLT",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "HURRICANE",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "SKYLINE",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "E MONSTER",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "NANU",
        "variants": [
          "HONEYBEE",
          "BUTTERFLY",
          "GEN NXT"
        ]
      },
      {
        "model": "WOLF",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GLOB",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "MONSTER",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GENNEXT",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "WOLF PLUS",
        "variants": [
          "LITHIUM ION 60V 35AH"
        ]
      },
      {
        "model": "GEN NXT PLUS",
        "variants": [
          "LITHIUM ION 60V 35AH"
        ]
      }
    ]
  },
  {
    "make": "JOY E BIKE",
    "models": [
      {
        "model": "3W",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "NANU",
        "variants": [
          "GEN NEXT"
        ]
      },
      {
        "model": "JP0066",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "COMPASS",
        "variants": [
          "2.0L MULTIJET II DIESEL MT (BS VI)LONGITUDE PLUS"
        ]
      },
      {
        "model": "MONSTER",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "E MONSTER",
        "variants": [
          "E BIKE",
          "E BIKE"
        ]
      },
      {
        "model": "BEAST",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SKYLINE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "HURRICANE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "THUNDERBOLT",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "GEN NXT NANU",
        "variants": [
          "60V 24AH"
        ]
      },
      {
        "model": "GLOB",
        "variants": [
          "60V 24AH"
        ]
      },
      {
        "model": "WOLF",
        "variants": [
          "60V 24AH",
          "60V 31AH",
          "ECO 60V 31AH"
        ]
      },
      {
        "model": "GEN NEXT",
        "variants": [
          "E BIKE",
          "60V 31AH",
          "ECO 60V 31AH"
        ]
      },
      {
        "model": "NANU HONEY BEE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "NANU BUTTERFLY",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "WOLF PLUS",
        "variants": [
          "LITHIUM 60V 35AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "GEN NEXT PLUS",
        "variants": [
          "LITHIUM 60V 35AH"
        ]
      },
      {
        "model": "MIHOS",
        "variants": [
          "LITHIUM 72V 40AH"
        ]
      }
    ]
  },
  {
    "make": "JOY E BIKE LTD",
    "models": [
      {
        "model": "E BIKE",
        "variants": [
          "WOLF PLUS",
          "GEN NEXT NANU PLUS",
          "MONSTER",
          "SKYLINE",
          "HURRICANE",
          "THUNDERBOLT",
          "BEAST",
          "E MONSTER",
          "GLOB",
          "WOLF",
          "GEN NEXT NANU"
        ]
      },
      {
        "model": "NANU",
        "variants": [
          "BUTTERFLY",
          "HONEYBEE"
        ]
      },
      {
        "model": "MIHOS",
        "variants": [
          "ELECTRIC",
          "STANDARD"
        ]
      },
      {
        "model": "GEN NXT NANU",
        "variants": [
          "ECO"
        ]
      },
      {
        "model": "WOLF",
        "variants": [
          "ECO"
        ]
      },
      {
        "model": "DEL GO",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "JOY EBIKE",
    "models": [
      {
        "model": "MONSTER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "E MONSTER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "GLOB",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "WOLF",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "GEN NXT NANU",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "NANU",
        "variants": [
          "BUTTERFLY",
          "HONEYBEE"
        ]
      },
      {
        "model": "HONEYBEE",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "JP MOTORS",
    "models": [
      {
        "model": "SEMO",
        "variants": [
          "LITHIUM ION 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "JYOVI",
    "models": [
      {
        "model": "CHOOPER",
        "variants": [
          "LITHIUM 60V 28AH"
        ]
      },
      {
        "model": "FUSION",
        "variants": [
          "LITHIUM 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "KABIRA MOBILITY",
    "models": [
      {
        "model": "INTERCITY NEO",
        "variants": [
          "LITHIUM-ION",
          "LITHIUM-ION",
          "LITHIUM ION BATTERY"
        ]
      },
      {
        "model": "KOLLEGIO NEO",
        "variants": [
          "LITHIUM-ION",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "KOLLEGIO PLUS",
        "variants": [
          "LITHIUM-ION",
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "AETOS 100",
        "variants": [
          "LITHIUM-ION",
          "60V35AH",
          "LITHIUM-ION",
          "LITHIUM-ION",
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "KOLLEGIO",
        "variants": [
          "LITHIUM-ION",
          "48V24AH",
          "NEO 48V24AH",
          "NEO PLUS 60V35AH",
          "48V24AH",
          "NEO",
          "PLUS",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "INTERCITY AEOLUS",
        "variants": [
          "LITHIUM-ION",
          "E-SCOOTER"
        ]
      },
      {
        "model": "KM 3000",
        "variants": [
          "LITHIUM-ION",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "KM 4000",
        "variants": [
          "STANDARD",
          "E-BIKE",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "INTERCITY",
        "variants": [
          "AEOLUS 60V35AH",
          "NEO 60V35AH",
          "300 60V35AH",
          "350 60V35AH"
        ]
      },
      {
        "model": "HERMES 75",
        "variants": [
          "60V35AH",
          "E-SCOOTER",
          "LITHIUM ION BATTERY"
        ]
      },
      {
        "model": "KM3000",
        "variants": [
          "60V35AH",
          "E-BIKE"
        ]
      },
      {
        "model": "KM4000",
        "variants": [
          "60V35AH"
        ]
      }
    ]
  },
  {
    "make": "KABIRDASS MOTOR COMPANY",
    "models": [
      {
        "model": "XITE",
        "variants": [
          "K 102",
          "K 101",
          "K 103"
        ]
      }
    ]
  },
  {
    "make": "KALSI",
    "models": [
      {
        "model": "PRIX EV 24L",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "PRIX",
        "variants": [
          "EV 24L",
          "CR LEAD ACID 60V",
          "CR LEAD ACID 48V"
        ]
      }
    ]
  },
  {
    "make": "KALSI INDUSTRIES",
    "models": [
      {
        "model": "ASTO",
        "variants": [
          "LITHIUM BATTERY 48V 30AH"
        ]
      }
    ]
  },
  {
    "make": "KANDA",
    "models": [
      {
        "model": "BRAVE",
        "variants": [
          "SELF START",
          "125",
          "SELF START ALLOY WHEEL DRUM BRK"
        ]
      },
      {
        "model": "MISSION",
        "variants": [
          "KICK START",
          "125",
          "KICK START SPOKE DRUM BRK"
        ]
      },
      {
        "model": "THUNDER",
        "variants": [
          "STD",
          "100"
        ]
      },
      {
        "model": "LOVELY",
        "variants": [
          "125"
        ]
      }
    ]
  },
  {
    "make": "KANDA MOTORS",
    "models": [
      {
        "model": "KD150",
        "variants": [
          "-11B"
        ]
      }
    ]
  },
  {
    "make": "KANDAA",
    "models": [
      {
        "model": "MISSION",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "THUNDER",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "KAWASAKI",
    "models": [
      {
        "model": "ELIMINATOR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "NINJA ZX-14R",
        "variants": [
          "STANDARD",
          "1441CC"
        ]
      },
      {
        "model": "NINJA 250R",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "NINJA 650",
        "variants": [
          "650CC",
          "STANDARD",
          "TCBI",
          "STD"
        ]
      },
      {
        "model": "NINJA",
        "variants": [
          "ZX-11",
          "350 R",
          "KRR ZX 150",
          "ZX 1400 CBF",
          "STD",
          "KLX 110",
          "1000",
          "300",
          "650 R",
          "H2",
          "ZX10R",
          "ZX14",
          "1000 SX",
          "H2 CARBON",
          "650 KRT EDITION",
          "H2 SX STANDARD",
          "H2 SX SE STANDARD",
          "400 KRT EDITION",
          "300 ABS",
          "650 STD BS6",
          "ZX 10 R MY",
          "ZX 10 RR SP",
          "H2 SX SE",
          "300 R",
          "ZX 1100",
          "1000 H2",
          "650 KRT",
          "Z 1000R",
          "1000",
          "Z 800 ABS",
          "Z 250",
          "Z 1000",
          "ZX 14 R",
          "ZX 14 R",
          "650 ER 6N",
          "650",
          "Z 900RS",
          "ZX 6 R",
          "400",
          "ZXR 750",
          "RR ZX 150",
          "ZX12R",
          "500R",
          "ZX 10RR",
          "ZX 1400 CBF",
          "250",
          "H2R"
        ]
      },
      {
        "model": "NINJA 300",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "VULCAN S",
        "variants": [
          "STANDARD",
          "BLACK",
          "ORANGE"
        ]
      },
      {
        "model": "NINJA ZX-10R",
        "variants": [
          "STANDARD",
          "998CC"
        ]
      },
      {
        "model": "ER-6N",
        "variants": [
          "STANDARD",
          "650"
        ]
      },
      {
        "model": "KLX 110",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "KX100F",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "KX250F",
        "variants": [
          "STANDARD",
          "250CC"
        ]
      },
      {
        "model": "NINJA H2",
        "variants": [
          "STANDARD",
          "SX"
        ]
      },
      {
        "model": "NINJA H2R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "NINJA ZX-10RR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "VERSYS X-300",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "Z1000",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "Z800",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "Z900",
        "variants": [
          "ACCESSORIES",
          "SPECIAL EDITION",
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "ZX14R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "NINJA 1000",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "KLX 140G",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "KLX 450R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "KX450F",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "NINJA ZX-6R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "VERSYS 1000",
        "variants": [
          "STANDARD",
          "BS VI"
        ]
      },
      {
        "model": "Z650",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "VERSYS 650",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "KX65",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "KLX 100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KDX",
        "variants": [
          "STD",
          "125"
        ]
      },
      {
        "model": "KLX 140",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SUZUKI 4X4",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "Z 800",
        "variants": [
          "STD (806 CC)"
        ]
      },
      {
        "model": "4S",
        "variants": [
          "CHAMPION"
        ]
      },
      {
        "model": "Z 900 RS",
        "variants": [
          "STD",
          "STANDARD"
        ]
      },
      {
        "model": "ER 6F",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KAWASAKI ZX600",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KLF",
        "variants": [
          "650"
        ]
      },
      {
        "model": "KLX",
        "variants": [
          "110",
          "450R",
          "KLX 140",
          "KLX 110",
          "140G"
        ]
      },
      {
        "model": "VERSYS",
        "variants": [
          "X 300",
          "1000",
          "650 ABS",
          "650 ABS",
          "1000 CBU"
        ]
      },
      {
        "model": "VULCAN",
        "variants": [
          "S",
          "S 649"
        ]
      },
      {
        "model": "Z",
        "variants": [
          "1000",
          "1000 R",
          "H2 SE",
          "H2 STANDARD",
          "250 STD",
          "800",
          "900",
          "650",
          "900 STD BS6",
          "650 STD BS6",
          "650 RS STANDARD"
        ]
      },
      {
        "model": "Z250",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "KLX 230RS",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "KX112",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "W800",
        "variants": [
          "STREET"
        ]
      },
      {
        "model": "ZZR1200",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "W 175",
        "variants": [
          "EBONY",
          "CANDY PERSIMMON RED"
        ]
      },
      {
        "model": "KZ-1000",
        "variants": [
          "990"
        ]
      },
      {
        "model": "KX",
        "variants": [
          "140",
          "250",
          "100"
        ]
      },
      {
        "model": "W175",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "Z H2",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KZ 1000",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "W",
        "variants": [
          "800"
        ]
      },
      {
        "model": "NINJA 150",
        "variants": [
          "RR"
        ]
      },
      {
        "model": "KE",
        "variants": [
          "175 STD"
        ]
      },
      {
        "model": "EX",
        "variants": [
          "250 H3",
          "250 H"
        ]
      },
      {
        "model": "KB",
        "variants": [
          "100 RTZ"
        ]
      },
      {
        "model": "KX100",
        "variants": [
          "F"
        ]
      },
      {
        "model": "KZ",
        "variants": [
          "1000 STD"
        ]
      },
      {
        "model": "KX250",
        "variants": [
          "F"
        ]
      }
    ]
  },
  {
    "make": "KEEWAY",
    "models": [
      {
        "model": "K-LIGHT 250V",
        "variants": [
          "STD",
          "STANDARD"
        ]
      },
      {
        "model": "VIESTE 300",
        "variants": [
          "STD",
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "SIXTIES 300I",
        "variants": [
          "STD",
          "MATTE LIGHT BLUE",
          "STANDARD"
        ]
      },
      {
        "model": "V302C",
        "variants": [
          "GLOSSY",
          "GLOSSY GREY"
        ]
      },
      {
        "model": "K300 R",
        "variants": [
          "STD",
          "ELECTRIC START",
          "GLOSSY WHITE"
        ]
      },
      {
        "model": "SR125",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "GLOSSY",
        "variants": [
          "V302C"
        ]
      },
      {
        "model": "K300 N",
        "variants": [
          "MATTE WHITE"
        ]
      },
      {
        "model": "SR250",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "KELVINATOR",
    "models": [
      {
        "model": "AVANTI",
        "variants": [
          "150 CC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "KETRON",
    "models": [
      {
        "model": "GT 90",
        "variants": [
          "LX E SCOOTY",
          "32AH",
          "VX LEAD ACID 60V 32AH",
          "R LITHIUM 60V 32AH",
          "VX 60V 32AH LITHUM ION",
          "R 60V 32AH LITHUM ION"
        ]
      },
      {
        "model": "GT 80",
        "variants": [
          "E SCOOTY",
          "52AH",
          "R LX LITHIUM 60V 26AH",
          "R PLUS LITHIUM 60V 26AH 12AH",
          "R VX LITHIUM 60V 26AH 26 AH",
          "R LITHIUM 60V 20AH",
          "VX"
        ]
      },
      {
        "model": "LEO 26 INCH",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "LEO 24 INCH",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "RIO",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "GT 80R",
        "variants": [
          "LX 60V 32AH LITHUM ION",
          "VX 60V 32AH LITHUM ION"
        ]
      }
    ]
  },
  {
    "make": "KETRON ELECTRIC SCOOTER",
    "models": [
      {
        "model": "GT80 LX",
        "variants": [
          "ELECTRICAL SCOOTER"
        ]
      },
      {
        "model": "GT80 VX",
        "variants": [
          "ELECTRICAL SCOOTER"
        ]
      },
      {
        "model": "GT90 LX",
        "variants": [
          "ELECTRICAL SCOOTER"
        ]
      },
      {
        "model": "GT90 VX",
        "variants": [
          "ELECTRICAL SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "KICK",
    "models": [
      {
        "model": "STALLION",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "KICK EV",
    "models": [
      {
        "model": "SMASSH",
        "variants": [
          "LITHIUM ION",
          "72V 35AH",
          "72V 51AH",
          "LI 72V 51AH 5KW",
          "LI 72V 35AH 5KW"
        ]
      }
    ]
  },
  {
    "make": "KICK-EV",
    "models": [
      {
        "model": "SMASSH",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STALLION",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "KINETIC",
    "models": [
      {
        "model": "HONDA",
        "variants": [
          "ZX",
          "DX",
          "ZX ZOOM SIDE CAR",
          "ZX ZOOM",
          "STD",
          "AX",
          "DX",
          "ZX",
          "ZX ZOOM WITH SIDE CAR"
        ]
      },
      {
        "model": "BOSS",
        "variants": [
          "MOTORCYCLE",
          "STANDARD",
          "STD",
          "100 CC MOTORCYCLE",
          "115 CC MOTORCYCLE",
          "MOTORCYCLE"
        ]
      },
      {
        "model": "COMET",
        "variants": [
          "250",
          "COMET",
          "STANDARD"
        ]
      },
      {
        "model": "GF",
        "variants": [
          "170 KICK START",
          "170 LASER KICK START DISC BRAKE",
          "125 STD",
          "170 LASER SELF START DISC BRAKE",
          "170 KICK START DISC BRAKE",
          "170 SELF START DISC BRAKE",
          "GF 125",
          "LASER",
          "170 LASER KICK START"
        ]
      },
      {
        "model": "CK",
        "variants": [
          "100 STD"
        ]
      },
      {
        "model": "CHALLENGER",
        "variants": [
          "STD",
          "STD",
          "STD 98CC"
        ]
      },
      {
        "model": "FLYTE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CAMPUS",
        "variants": [
          "CHILL",
          "STD"
        ]
      },
      {
        "model": "CVT",
        "variants": [
          "70"
        ]
      },
      {
        "model": "NOVA",
        "variants": [
          "135",
          "DLX",
          "EX",
          "DELUXE",
          "EX",
          "STD",
          "EX."
        ]
      },
      {
        "model": "LUNA",
        "variants": [
          "SUPER",
          "TFR",
          "SUPER 70",
          "STD",
          "CVT",
          "DOUBLE PLUS",
          "MAGNUM",
          "SUPER DASH",
          "SUPER STAR",
          "WINGS",
          "TFR PLUS"
        ]
      },
      {
        "model": "KINE",
        "variants": [
          "DLX",
          "STD",
          "STD",
          "DLX"
        ]
      },
      {
        "model": "LASER",
        "variants": [
          "STD",
          "STD",
          "KS",
          "ELECTRIC START",
          "KICK START"
        ]
      },
      {
        "model": "K4",
        "variants": [
          "100",
          "80",
          "100 ZX",
          "80",
          "100 DX",
          "100 SS",
          "STD",
          "KING 100"
        ]
      },
      {
        "model": "MARVEL",
        "variants": [
          "STD",
          "STD",
          "STD",
          "110 CC SCOOTER"
        ]
      },
      {
        "model": "KING",
        "variants": [
          "100",
          "100",
          "DLX",
          "KING 100",
          "DELUXE."
        ]
      },
      {
        "model": "V2",
        "variants": [
          "STD",
          "XL",
          "STD",
          "STD",
          "V2 80"
        ]
      },
      {
        "model": "Y2K",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "SHAKTI",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PRIDE",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "SAFARI",
        "variants": [
          "V2 XL",
          "V2 80",
          "V2 50",
          "STD",
          "SI"
        ]
      },
      {
        "model": "STYLE",
        "variants": [
          "SX",
          "STD",
          "STD"
        ]
      },
      {
        "model": "ZING",
        "variants": [
          "80",
          "ARMY",
          "ROCKIN",
          "KINE",
          "ROCKIN",
          "STD",
          "HSS+",
          "80 CC"
        ]
      },
      {
        "model": "STRYKER",
        "variants": [
          "STD",
          "STRYKER"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "G1",
          "VI"
        ]
      },
      {
        "model": "VELOCITY",
        "variants": [
          "STD",
          "STANDARD",
          "EX"
        ]
      },
      {
        "model": "ZX ZOOM",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "4S",
        "variants": [
          "STANDARD",
          "4 STROKE SCOOTER"
        ]
      },
      {
        "model": "AQUILA",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "GF 170",
        "variants": [
          "DISC",
          "DRUM",
          "SELF START DISC BREAK",
          "DISK KICK START",
          "DRUM KICK START",
          "DISK ELECTRIC START"
        ]
      },
      {
        "model": "MECURY",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "NOVA 135",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "AX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CHILL",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DX",
        "variants": [
          "STD",
          "98"
        ]
      },
      {
        "model": "SPORTS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ZOOM",
        "variants": [
          "STD",
          "STD",
          "ZX",
          "[TEAL] ELECTRIC SCOOTER",
          "ZX"
        ]
      },
      {
        "model": "ZX",
        "variants": [
          "STD",
          "ZOOM",
          "STD"
        ]
      },
      {
        "model": "SWIFT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ZING 80",
        "variants": [
          "72 CC",
          "STANDARD"
        ]
      },
      {
        "model": "SYM FLYTE",
        "variants": [
          "SYM FLYTE",
          "STD"
        ]
      },
      {
        "model": "GF - 170",
        "variants": [
          "ELEC START DISC BRAKE",
          "KICK START DRUM BRAKE"
        ]
      },
      {
        "model": "BLAZE",
        "variants": [
          "DRUM BRAKE",
          "STANDARD",
          "DISC",
          "DRUM",
          "STD"
        ]
      },
      {
        "model": "K4 80",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "EURO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MV AGUSTA",
        "variants": [
          "F3 800",
          "BRUTALE"
        ]
      },
      {
        "model": "GF 125",
        "variants": [
          "DRUM BREAK"
        ]
      }
    ]
  },
  {
    "make": "KINETIC GREEN",
    "models": [
      {
        "model": "ZING",
        "variants": [
          "LITHIUM 60V 22AH",
          "BIG B LITHIUM 60V 28AH",
          "HSS LITHIUM 60V 28AH",
          "HSS",
          "STD",
          "BIG B"
        ]
      },
      {
        "model": "ZOOM",
        "variants": [
          "LITHIUM 60V 22AH",
          "BIG B LITHIUM 60V 28AH",
          "STD",
          "BIG B"
        ]
      },
      {
        "model": "FALCON",
        "variants": [
          "LITHIUM 60V 28AH"
        ]
      },
      {
        "model": "FLEX",
        "variants": [
          "LITHIUM 60V 28AH",
          "LITHIUM 60V 42AH",
          "LITHIUM 72V 42AH",
          "X",
          "BATTERY SCOOTER"
        ]
      },
      {
        "model": "E LUNA",
        "variants": [
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "KINETIC GREEN ENERGY",
    "models": [
      {
        "model": "E LUNA",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "ZOOM",
        "variants": [
          "60V 28AH LITHIUM BATTERY",
          "60V 22AH LITHIUM BATTERY",
          "B TEAL 60V 28AH LITHIUM BATTERY",
          "LITHIUM BATERY 60V 29AH"
        ]
      },
      {
        "model": "ZOOM BIG B",
        "variants": [
          "60V 28AH LITHIUM BATTERY"
        ]
      },
      {
        "model": "ZING BIG B",
        "variants": [
          "60V 28AH LITHIUM BATTERY"
        ]
      },
      {
        "model": "ZING S",
        "variants": [
          "60V 22AH LITHIUM BATERY"
        ]
      },
      {
        "model": "ZING",
        "variants": [
          "60V 22AH LITHIUM BATERY",
          "HS LITHIUM BATERY 60V 28AH",
          "60V 28AH LITHIUM BATERY",
          "HSS",
          "LITHIUM BATERY 60V 29AH"
        ]
      },
      {
        "model": "ZOOM B",
        "variants": [
          "60V 28AH LITHIUM BATTERY"
        ]
      },
      {
        "model": "FLEX",
        "variants": [
          "72V 42AH LITHIUM BATTERY",
          "72V 47AH LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "KINETIC MOTOR",
    "models": [
      {
        "model": "STYLE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GF 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PRIDE  FX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SAFARI V2",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LUNA SUPER 70",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KINETIC 4S",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CK 100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CHILL",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GF LASER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ZX ZOOM",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GF 170  DISC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "NOVA 135",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KINETIC  V2",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BLAZE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BOSS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LUNA TFR  PLUS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STRYKER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "K4 - 100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VELOCITY",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SHAKTI.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "K4 - 80",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "HONDA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MARVEL",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KINE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "NOVA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KINETIC COMET",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CHALLENGER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ZING",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DX ZOOM",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLYTE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KING  STD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BOSS V2",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KINETIC  AQUILA",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "KKT AUTOMOTIVE",
    "models": [
      {
        "model": "FEATHER",
        "variants": [
          "PRO PLUS LEAD ACID 48V 30AH",
          "PRO LEAD ACID 48V 20AH",
          "LEAD ACID 48V 12AH"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "PRO PLUS LEAD ACID 48V 30AH",
          "PRO LEAD ACID 48V 20AH",
          ".LEAD ACID 48V 12AH"
        ]
      },
      {
        "model": "NICE",
        "variants": [
          "PRO PLUS LEAD ACID 48V 30AH",
          "PRO LEAD ACID 48V 20AH",
          "LEAD ACID 48V 12AH"
        ]
      },
      {
        "model": "SMILE",
        "variants": [
          "PRO PLUS LEAD ACID 48V 30AH",
          "PRO LEAD ACID 48V 20AH",
          "LEAD ACID 48V 12AH"
        ]
      },
      {
        "model": "ELECTRICO",
        "variants": [
          "PRO LEAD ACID 48V 6AH",
          "LEAD ACID 48V 6AH"
        ]
      },
      {
        "model": "BOXER",
        "variants": [
          "PRO PLUS LEAD ACID 60V 30AH",
          "PRO LEAD ACID 60V 20AH",
          "LEAD ACID 60V 12AH"
        ]
      }
    ]
  },
  {
    "make": "KLB KOMAKI PVT. LTD.",
    "models": [
      {
        "model": "XGT",
        "variants": [
          "X-ONE",
          "X2-VOGUE",
          "ICON",
          "X4",
          "VP",
          "CHARGE",
          "AFL-500",
          "X5"
        ]
      }
    ]
  },
  {
    "make": "KOMAKI",
    "models": [
      {
        "model": "XGT KM",
        "variants": [
          "STANDARD",
          "ADVANCE LITHIUM TECHNOLOGY",
          "48V 26 AH",
          "60V 26 AH",
          "GEL BATTERY",
          "E BIKE",
          "LITHIUM BATTERY 55 AH 48 V",
          "LITHIUM BATTERY 40 AH 60 V",
          "LITHIUM BATTERY 30 AH 60 V",
          "LITHIUM BATTERY 30 AH 48 V",
          "LITHIUM BATTERY",
          "GEL BATTERY 60V 28AH"
        ]
      },
      {
        "model": "XGT CLASSIC",
        "variants": [
          "STANDARD",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "XONE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "X2 VOGUE",
        "variants": [
          "STANDARD",
          "LITHIUM BATTERY",
          "GEL BATTERY",
          "E BIKE"
        ]
      },
      {
        "model": "SUPER",
        "variants": [
          "STANDARD",
          "STD",
          "STANDARD",
          "E BIKE"
        ]
      },
      {
        "model": "TN-95",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SE",
        "variants": [
          "STANDARD",
          "E BIKE",
          "ECO",
          "SPORT",
          "72V-40AH LION SPORTS",
          "SPORT 74 V 44 AH",
          "SPORT 74 V 50 AH",
          "72V 32 AH",
          "72V 40 AH",
          "SPORT LITHIUM BATTERY 74V 40AH",
          "LITHIUM BATTERY 74V 45AH",
          "LITHIUM BATTERY 74V 30AH"
        ]
      },
      {
        "model": "M5",
        "variants": [
          "STANDARD",
          "E BIKE",
          "SPORT",
          "ECO",
          "LITHIUM ION 74 / 32AH"
        ]
      },
      {
        "model": "MX3",
        "variants": [
          "LION",
          "72V 33AH",
          "STANDARD"
        ]
      },
      {
        "model": "XGT VP",
        "variants": [
          "STANDARD",
          "STANDARD",
          "LITHIUM BATTERY",
          "GEL BATTERY",
          "LITHIUM BATTERY 48V/40AH",
          "LITHIUM BATTERY 48V/30AH",
          "GEL BATTERY 60V 28AH"
        ]
      },
      {
        "model": "XGT X3",
        "variants": [
          "STANDARD",
          "LITHIUM BATTERY",
          "LITHIUM BATTERY 60V/40AH",
          "LITHIUM BATTERY 60V/30AH"
        ]
      },
      {
        "model": "VENICE",
        "variants": [
          "ECO",
          "72V 40AH",
          "ECO LFP 51.2V 33AH",
          "Y22 LFP",
          "72V ESCOOTER",
          "LITHIUM BATTERY 72V 40AH",
          "FLORA",
          "ECO"
        ]
      },
      {
        "model": "FLORA",
        "variants": [
          "STANDARD",
          "LFP 60V 28AH",
          "LFP 51V 35AH",
          "LFP 51V 33AH",
          "STANDARD",
          "XL",
          "LITHIUM PO4"
        ]
      },
      {
        "model": "CLASSIC",
        "variants": [
          "E BIKE",
          "E BIKE",
          "60V 30 AH",
          "72V 30 AH"
        ]
      },
      {
        "model": "RANGER",
        "variants": [
          "CRUISER",
          "72V 50AH LION",
          "72V 50AH",
          "LITHIUM BATTERY 72V 50AH"
        ]
      },
      {
        "model": "XGT",
        "variants": [
          "E BIKE",
          "X2 E BIKE",
          "X4 E BIKE",
          "X5 E BIKE",
          "60V36AH GEL",
          "VP 2.0 LION 62V 30AH",
          "VP 2.0 LION 62V 35AH",
          "VP 2.0 LION 62V 32AH",
          "KM 60V28AH GEL",
          "KM 48V26A LION",
          "KM 60V26AH LION",
          "KM ADV LITHUM TECHNOLOGY",
          "VP 60V28AH GEL",
          "VP 48V26A LION",
          "VP 60V26AH LION"
        ]
      },
      {
        "model": "VP",
        "variants": [
          "E BIKE",
          "48V 26 AH",
          "60V 26 AH",
          "GEL BATTERY 60V 28AH",
          "LITHIUM BATTERY 62V 30AH"
        ]
      },
      {
        "model": "CHARGE BIKE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "X1",
        "variants": [
          "E BIKE",
          "48V28AH GEL",
          "60V28AH GEL",
          "48V26AH LION",
          "60V26AH LION",
          "ADV LITHUM TECHNOLOGY",
          "51V28AH LION",
          "51V27AH LIPO4",
          "51V33AH LIPO4"
        ]
      },
      {
        "model": "X3",
        "variants": [
          "E BIKE",
          "60V32AH LION",
          "60V35AH LION",
          "60V 32 AH",
          "60V 35 AH"
        ]
      },
      {
        "model": "X4",
        "variants": [
          "E BIKE",
          "60V32AH LION",
          "60V35AH LION",
          "ADV LITHUM TECHNOLOGY",
          "60V 30 AH",
          "60V 35 AH",
          "LITHIUM BATTERY 72V/30AH",
          "LITHIUM BATTERY 72V/45AH"
        ]
      },
      {
        "model": "KM",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "CAT 2.0",
        "variants": [
          "E BIKE",
          "72V 33AH",
          "LITHIUM 72V 38AH",
          "60V/30AH",
          "72V 30 AH"
        ]
      },
      {
        "model": "SPORT",
        "variants": [
          "T95"
        ]
      },
      {
        "model": "ECO",
        "variants": [
          "T95"
        ]
      },
      {
        "model": "TN85",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TN95",
        "variants": [
          "STD",
          "72V-40AH LION SPORTS"
        ]
      },
      {
        "model": "X2",
        "variants": [
          "72V28AH GEL",
          "72V24AH LION",
          "VOGUE STANDARD",
          "72V 24 AH",
          "GEL BATTERY 72V 28AH"
        ]
      },
      {
        "model": "LY",
        "variants": [
          "SINGLE BATTERY 60V 34AH LI",
          "DUAL BATTERY 60V 34AH LI",
          "60V-34AH",
          "LITHIUM DUAL BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "DT3000",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "X5",
        "variants": [
          "LITHIUM ION 60V 35AH",
          "LITHIUM BATTERY 60V/40AH",
          "LITHIUM BATTERY 60V/30AH"
        ]
      },
      {
        "model": "DT 3000",
        "variants": [
          "E SCOOTER",
          "LITHIUM BATTERY 72V 52AH"
        ]
      },
      {
        "model": "ECO-VENICE",
        "variants": [
          "E-SCOOTER"
        ]
      },
      {
        "model": "XGT CAT 2.0",
        "variants": [
          "72 V 31 AH"
        ]
      },
      {
        "model": "TN 95",
        "variants": [
          "SPORT 74 V 44 AH",
          "SPORT 74 V 50 AH",
          "STANDARD",
          "72V 32 AH",
          "72V 40 AH",
          "LITHIUM BATTERY",
          "LITHIUM BATTERY 74V/55AH",
          "LITHIUM BATTERY 74V/45AH"
        ]
      },
      {
        "model": "XGT X4",
        "variants": [
          "60 V 35 AH",
          "LITHIUM BATTERY",
          "LITHIUM 62V 35AH"
        ]
      },
      {
        "model": "XGT X5",
        "variants": [
          "72 V 24 AH"
        ]
      },
      {
        "model": "M5 ECO",
        "variants": [
          "72V 32 AH"
        ]
      },
      {
        "model": "X ONE",
        "variants": [
          "48V 26 AH",
          "60V 26 AH",
          "LITHIUM BATTERY 62.9V/30AH",
          "LITHIUM BATTERY 62.9V/40AH",
          "E BIKE",
          "LITHIUM BATTERY 48V/55AH",
          "LITHIUM BATTERY 48V/30AH",
          "LITHIUM BATTERY 48V/40AH",
          "LITHIUM BATTERY 48V/20AH",
          "GEL BATTERY 60V 28AH",
          "GEL BATTERY 48V 28AH"
        ]
      },
      {
        "model": "VESPA",
        "variants": [
          "LITHIUM BATTERY 51.4V/30AH"
        ]
      },
      {
        "model": "XGT CHARGE",
        "variants": [
          "LITHIUM BATTERY",
          "GEL BATTERY"
        ]
      },
      {
        "model": "CAT",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "SUPER SPORT",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "MX 3",
        "variants": [
          "LITHIUM BATTERY 72V",
          "LITHIUM BATTERY 62V"
        ]
      }
    ]
  },
  {
    "make": "KOSMO",
    "models": [
      {
        "model": "BLASTER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KOSMO BRIGHT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BRIGHT",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "KROSSMARK",
    "models": [
      {
        "model": "SPECTRA",
        "variants": [
          "LITHIUM 48V"
        ]
      }
    ]
  },
  {
    "make": "KTM",
    "models": [
      {
        "model": "65",
        "variants": [
          "SX"
        ]
      },
      {
        "model": "125",
        "variants": [
          "STD",
          "DUKE ABS",
          "ABS",
          "DUKE ABS BS VI",
          "RC ABS",
          "RC BS VI",
          "DUKE BS VI"
        ]
      },
      {
        "model": "200",
        "variants": [
          "4V",
          "DUKE",
          "DUKE ABS",
          "DUKE BS VI",
          "RC",
          "RC BS VI",
          "RC ABS"
        ]
      },
      {
        "model": "250",
        "variants": [
          "SX-F",
          "DUKE ABS",
          "ADVENTURE",
          "STD",
          "ADVENTURE",
          "DUKE BS VI"
        ]
      },
      {
        "model": "390",
        "variants": [
          "ADVENTURE STD",
          "ADVENTURE X STANDARD",
          "DUKE",
          "RC",
          "RC ABS",
          "DUKE ABS",
          "RC BS VI",
          "ADVENTURE",
          "DUKE BS VI",
          "ADVENTURE BS VI"
        ]
      },
      {
        "model": "530",
        "variants": [
          "XC"
        ]
      },
      {
        "model": "640",
        "variants": [
          "SUPERMOTO"
        ]
      },
      {
        "model": "690",
        "variants": [
          "DUKE",
          "ENDURO",
          "ENDURO R",
          "SMC",
          "SUPERMOTO"
        ]
      },
      {
        "model": "990",
        "variants": [
          "SUPERMOTO",
          "SUPERMOTO R",
          "ADVENTURE",
          "ADVENTURE R",
          "SUPERDUKE"
        ]
      },
      {
        "model": "1050",
        "variants": [
          "ADVENTURE"
        ]
      },
      {
        "model": "1190",
        "variants": [
          "RC 8 R SUPERSPORT"
        ]
      },
      {
        "model": "200 DUKE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "250 DUKE",
        "variants": [
          "STANDARD",
          "BS VI"
        ]
      },
      {
        "model": "RC200",
        "variants": [
          "STANDARD",
          "ABS",
          "BS VI",
          "GP EDITION",
          "GP EDITION",
          "STANDARD"
        ]
      },
      {
        "model": "RC390",
        "variants": [
          "STANDARD",
          "373CC",
          "BS VI",
          "GP EDITION"
        ]
      },
      {
        "model": "390 ADVENTURE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "RC125",
        "variants": [
          "BS VI",
          "SINGLE CHANNEL ABS",
          "BS VI",
          "SINGLE CHANNEL ABS"
        ]
      },
      {
        "model": "390 DUKE",
        "variants": [
          "ABS",
          "BS VI",
          "STANDARD"
        ]
      },
      {
        "model": "RC8",
        "variants": [
          "1190R"
        ]
      },
      {
        "model": "250 ADVENTURE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "125 DUKE",
        "variants": [
          "BS VI FACELIFT",
          "STANDARD"
        ]
      },
      {
        "model": "DUKE",
        "variants": [
          "200 CC",
          "390 CC",
          "250 STD",
          "125 CC",
          "790 CC",
          "200 ABS",
          "200",
          "250",
          "390",
          "690",
          "200 V4",
          "350",
          "125 ABS",
          "390 ABS",
          "125",
          "250",
          "200 ABS",
          "125 BSVI",
          "200 BS6",
          "250 BS6",
          "ABS"
        ]
      },
      {
        "model": "RC",
        "variants": [
          "390 DUKE STD",
          "125 CC",
          "200",
          "390",
          "125 ABS",
          "200 ABS",
          "390 ABS"
        ]
      },
      {
        "model": "ADVENTURE",
        "variants": [
          "390",
          "250"
        ]
      },
      {
        "model": "HUSQVARNA",
        "variants": [
          "SVARTPILEN 250",
          "VIPILEN 250"
        ]
      },
      {
        "model": "ADVENTURER",
        "variants": [
          "640"
        ]
      },
      {
        "model": "SUPER DUKE",
        "variants": [
          "990"
        ]
      },
      {
        "model": "AVTAR",
        "variants": [
          "E1"
        ]
      },
      {
        "model": "RACE",
        "variants": [
          "125 CONCEPT"
        ]
      },
      {
        "model": "RC 8",
        "variants": [
          "1190",
          "STD"
        ]
      },
      {
        "model": "STUNT",
        "variants": [
          "125 CONCEPT"
        ]
      },
      {
        "model": "790 DUKE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SUPERMOTO",
        "variants": [
          "690 SMC R"
        ]
      },
      {
        "model": "FREERIDE",
        "variants": [
          "350",
          "250 R",
          "E XC"
        ]
      },
      {
        "model": "TRAVEL",
        "variants": [
          "1190 ADVENTURE",
          "1050 ADVENTURE",
          "1290 SUPER ADVENTURE",
          "1190 ADVENTURE R"
        ]
      },
      {
        "model": "DUKE 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DUKE 790",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "KUBERA MOTORS",
    "models": [
      {
        "model": "VEGA ENERGY",
        "variants": [
          "LITHIUM 60V"
        ]
      }
    ]
  },
  {
    "make": "KVHIND AUTOMOTIVE PVT LTD",
    "models": [
      {
        "model": "KV-DREAM",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "GLITTER",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "GLITTER PLUS",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "KV-RANGO",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "KV-GEL",
        "variants": [
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "KYTE",
    "models": [
      {
        "model": "MAGNUM PRO",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "MAGNUM X",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "KYTE E-BIKE",
    "models": [
      {
        "model": "MAGNUM",
        "variants": [
          "ELECTRIC BIKE",
          "PRO ELECTRIC BIKE"
        ]
      }
    ]
  },
  {
    "make": "KYTE ENERGY",
    "models": [
      {
        "model": "MAGNUM",
        "variants": [
          "E SCOOTER",
          "PRO 60V 30AH"
        ]
      },
      {
        "model": "STELLA",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "KYTE ENERGY PVT LTD",
    "models": [
      {
        "model": "MAGPRO",
        "variants": [
          "60V-30AH MAGNUM PRO"
        ]
      }
    ]
  },
  {
    "make": "LAMBRETTA",
    "models": [
      {
        "model": "MAC",
        "variants": [
          "175 SIDE CAR"
        ]
      },
      {
        "model": "SOLO",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "LAMBRETTA",
        "variants": [
          "500"
        ]
      },
      {
        "model": "LI 150",
        "variants": [
          "S1",
          "S3",
          "SPECIAL"
        ]
      },
      {
        "model": "GP 200",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "GP 150",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "LAMBRETTA 48",
        "variants": [
          "MOPED"
        ]
      },
      {
        "model": "SCOOTER",
        "variants": [
          "112 CC",
          "175 CC",
          "100 CC"
        ]
      },
      {
        "model": "CENTO",
        "variants": [
          "98 CC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "LAXMI E MOTORS",
    "models": [
      {
        "model": "THUKRAL E GEN",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "LEBU ELECTRONICS PVT LTD",
    "models": [
      {
        "model": "HOPE",
        "variants": [
          "LITHIUM 60V 30AH",
          "PLUS LITHIUM 60V 30AH",
          "DELUXE LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "STAR",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ROCK",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "JUMBO",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "LOADER",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "LECTRIX",
    "models": [
      {
        "model": "ECITY ZIP",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SX25",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "LXS",
        "variants": [
          "G3.0",
          "G2.0",
          "+",
          "48V",
          "E SCOOTER"
        ]
      },
      {
        "model": "E BIKE",
        "variants": [
          "E1"
        ]
      },
      {
        "model": "ECITY ZIP C40",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "E1",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "LECTRIX EV",
    "models": [
      {
        "model": "LXS",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "LECTRIX EV PVT LTD",
    "models": [
      {
        "model": "LX 25D",
        "variants": [
          "BLACK"
        ]
      },
      {
        "model": "CITY ZIP",
        "variants": [
          "LITHIUM 60V 40AH"
        ]
      },
      {
        "model": "LXS",
        "variants": [
          "LITHIUM 60V 40AH",
          "LITHIUM 48V 40AH"
        ]
      },
      {
        "model": "LX25",
        "variants": [
          "LEAD ACID 48V"
        ]
      }
    ]
  },
  {
    "make": "LEO AUTOCORP",
    "models": [
      {
        "model": "ACTIVE GRAND",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "PRIME GRAND",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "PRIME PLUS GRAND",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "PRIME PLUS PLUS GRAND",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "VISTA GRAND",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "LEO ELECTRIC",
    "models": [
      {
        "model": "E LORA",
        "variants": [
          "PLUS LEAD ACID",
          "DELUXE LEAD ACID",
          "SUPREME LEAD ACID",
          "PRIME LEAD ACID"
        ]
      },
      {
        "model": "ELORA DELUXE",
        "variants": [
          "LEAD ACID BATTERY 60V 24AH"
        ]
      },
      {
        "model": "ELORA SUPREME",
        "variants": [
          "LITHIUM BATTERY 72V 45AH"
        ]
      },
      {
        "model": "ELORA PRIME",
        "variants": [
          "LITHIUM BATERY"
        ]
      },
      {
        "model": "ELORA PLUS",
        "variants": [
          "LEAD ACID BATTERY 60V 24AH"
        ]
      }
    ]
  },
  {
    "make": "LETHEX",
    "models": [
      {
        "model": "SUMMER",
        "variants": [
          "LFP 48V 24AH",
          "LFP 48V 30AH",
          "LFP 60V 24AH",
          "LFP 60V 30AH"
        ]
      },
      {
        "model": "TEZ",
        "variants": [
          "LFP 48V 24AH",
          "LFP 48V 30AH",
          "LFP 60V 24AH",
          "LFP 60V 30AH"
        ]
      },
      {
        "model": "JESPER",
        "variants": [
          "LFP 48V 24AH",
          "LFP 48V 30AH",
          "LFP 60V 24AH",
          "LFP 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "LEXA",
    "models": [
      {
        "model": "ELIZA",
        "variants": [
          "48V 26 AH",
          "60V 26 AH",
          "60V 30 AH"
        ]
      },
      {
        "model": "LIZA",
        "variants": [
          "60V 26 AH",
          "60V 30 AH",
          "72V 30 AH",
          "72V 52 AH",
          "60V 35 AH",
          "60V 40 AH",
          "60V 50 AH",
          "60V 70 AH"
        ]
      },
      {
        "model": "BAZZ",
        "variants": [
          "60V 35 AH"
        ]
      }
    ]
  },
  {
    "make": "LI IONS ELEKTRIK SOLUTIONS",
    "models": [
      {
        "model": "SPOCK",
        "variants": [
          "E SCOOTY",
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "LIBERTY",
    "models": [
      {
        "model": "3V",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "LIIONS ELEKTRIK SOLUTIONS",
    "models": [
      {
        "model": "SPOCK ELECTRIC SCOOTER",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "LIMOTO",
    "models": [
      {
        "model": "DISCOVERY",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "GALAXY",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SPEEDKING",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ROADSTAR",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "LML",
    "models": [
      {
        "model": "ADRENO",
        "variants": [
          "FX",
          "STD",
          "FX DISC BRAKE",
          "STD",
          "FX DRUM BRAKE",
          "100 CC MOTORCYCLE"
        ]
      },
      {
        "model": "NV",
        "variants": [
          "2S SELF START",
          "4S KICK START",
          "DLX",
          "4S LS",
          "2S KICK START",
          "4S SELF START",
          "2S LS",
          "SPL SELF START",
          "SPL KICK START",
          "STD",
          "4 STROKE STAR ES",
          "SPL",
          "4 STROKE KS DELUXE",
          "4 STROKE ES",
          "LS 2STROKE KS",
          "4 DLX",
          "ES"
        ]
      },
      {
        "model": "FREEDOM",
        "variants": [
          "PRIMA 110",
          "PRIMA 125",
          "STD",
          "TOPPER",
          "STANDARD",
          "DLX",
          "STD",
          "STD",
          "DX",
          "LS",
          "PRIMA 110",
          "DX",
          "TOPPER",
          "LS",
          "150",
          "LS 110 CC",
          "DX 110 CC"
        ]
      },
      {
        "model": "CRD",
        "variants": [
          "100 MOTORCYCLE"
        ]
      },
      {
        "model": "GRAPTOR",
        "variants": [
          "MOTORCYCLE",
          "STANDARD",
          "STANDRED",
          "STD",
          "STD",
          "150 CC MOTORCYCLE"
        ]
      },
      {
        "model": "ENERGY",
        "variants": [
          "FX",
          "SELF START",
          "FX",
          "STD"
        ]
      },
      {
        "model": "BEAMER",
        "variants": [
          "MOTORCYCLE",
          "KICK",
          "STD",
          "150 CC MOTORCYCLE"
        ]
      },
      {
        "model": "SELECT II",
        "variants": [
          "SCOOTER",
          "KICK",
          "SELF",
          "ELECTRIC START",
          "KICK START",
          "SELECT 4",
          "STD"
        ]
      },
      {
        "model": "NV SPL",
        "variants": [
          "LS",
          "KS",
          "ELECTRIC START",
          "ES"
        ]
      },
      {
        "model": "PULSE",
        "variants": [
          "SCOOTER",
          "SCOOTER",
          "STD",
          "ELECTRIC START",
          "KICK START",
          "STD",
          "STD",
          "125 CC SCOOTER",
          "75 CC SCOOTER"
        ]
      },
      {
        "model": "PRITHVI",
        "variants": [
          "SCOOTER",
          "STD",
          "STD",
          "STD",
          "150",
          "150 CC SCOOTER"
        ]
      },
      {
        "model": "SELECT 4",
        "variants": [
          "SCOOTER",
          "150CC",
          "ELECTRIC START - 150 CC 4 STROKE SCOOTER"
        ]
      },
      {
        "model": "STAR",
        "variants": [
          "200 4T SCOOTER",
          "EURO GEARLESS 150CC",
          "EURO 200 ES",
          "EURO 200 KS",
          "EURO 150 DRUM",
          "EURO 150 DISC",
          "EURO GEARLESS",
          "XPRESS ELECTRIC START",
          "XPRESS KICK START",
          "EURO GEAR",
          "EURO 200",
          "STD",
          "EURO 150",
          "EURO DISC",
          "EURO MULTISHADE"
        ]
      },
      {
        "model": "STAR EURO",
        "variants": [
          "150 GEARLESS LS",
          "150 GEARLESS STD",
          "150 GEARLESS YNG",
          "200 SELF START DISC BRAKE",
          "150 SELF START",
          "150 SELF START DISC BRAKE",
          "200 SELF START"
        ]
      },
      {
        "model": "SENSATION",
        "variants": [
          "SCOOTER",
          "125",
          "STD",
          "STD",
          "STD",
          "DLX",
          "150 CC SCOOTER",
          "125 CC SCOOTER"
        ]
      },
      {
        "model": "SELECT",
        "variants": [
          "SCOOTER",
          "4 STROKE (KS)",
          "100 STD",
          "150 STD",
          "ELECTRIC START - 150 CC 4 STROKE SCOOTER",
          "KICK START - 150 CC 4 STROKE SCOOTER",
          "KICK START - 150 CC 2 STROKE SCOOTER",
          "ELECTRIC START - 150 CC 2 STROKE SCOOTER",
          "100 CC SCOOTER",
          "150 CC SCOOTER"
        ]
      },
      {
        "model": "VESPA",
        "variants": [
          "SCOOTER",
          "NV SCOOTER",
          "STD",
          "NV",
          "STD",
          "STD",
          "NV 150 CC SCOOTER",
          "XE",
          "T 5 150 CC SCOOTER",
          "SIDE CAR"
        ]
      },
      {
        "model": "STAR XPRESS",
        "variants": [
          "SCOOTER",
          "150 CC SCOOTER"
        ]
      },
      {
        "model": "SUPREMO",
        "variants": [
          "SCOOTER",
          "ELECTRIC START",
          "KICK START",
          "STD",
          "STD",
          "150 CC SCOOTER"
        ]
      },
      {
        "model": "TRENDY",
        "variants": [
          "MOPED",
          "STD",
          "S",
          "STD",
          "DLX"
        ]
      },
      {
        "model": "CRD 100",
        "variants": [
          "STANDARD",
          "STD",
          "100 CC MOTORCYCLE"
        ]
      },
      {
        "model": "EVALIA",
        "variants": [
          "NV"
        ]
      },
      {
        "model": "SUPREME",
        "variants": [
          "SUPREMO"
        ]
      },
      {
        "model": "NV 4 STROKE",
        "variants": [
          "KICK START",
          "SELF START"
        ]
      },
      {
        "model": "FREEDOM LS",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "PRIMA 125",
        "variants": [
          "DISC"
        ]
      },
      {
        "model": "FREEDOM DX",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "NV 2 STROKE",
        "variants": [
          "KICK START"
        ]
      },
      {
        "model": "NV 2S",
        "variants": [
          "ES"
        ]
      },
      {
        "model": "STAR EURO 150",
        "variants": [
          "KS",
          "DRUM KICK START",
          "DISC ELECTRIC START",
          "DISC MULTI SHADE ELECTRIC START",
          "DRUM ELECTRIC START"
        ]
      },
      {
        "model": "LML CRD-100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ENERGY FX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FREEDOM PRIMA",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "LML SENSATION",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STAR X PRESS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TRENDY (SCOOTTERETTE)",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LML  BEAMER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "NV 4S",
        "variants": [
          "ELECTRIC START"
        ]
      },
      {
        "model": "STAR EURO 200",
        "variants": [
          "SELF START DRUM BRAKE",
          "SELF START DISK BRAKE"
        ]
      }
    ]
  },
  {
    "make": "LOG 9 ELECTRIC",
    "models": [
      {
        "model": "RAPID L91",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "RAPID L92",
        "variants": [
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "LOG 9 MATERIALS SCI PVT LTD",
    "models": [
      {
        "model": "RAPID",
        "variants": [
          "L 91",
          "L 92"
        ]
      }
    ]
  },
  {
    "make": "LOHIA",
    "models": [
      {
        "model": "OMASTAR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "FAME",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "GENIUS",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "OMA STAR LI",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "OMA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "OMA STAR",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "LOHIA AUTO",
    "models": [
      {
        "model": "FAME",
        "variants": [
          "STD",
          "ELECTRIC BIKE",
          "STD",
          "E BIKE"
        ]
      },
      {
        "model": "GENIUS",
        "variants": [
          "STD",
          "STD",
          "E BIKE"
        ]
      },
      {
        "model": "OMA STAR",
        "variants": [
          "DX",
          "STD",
          "DX",
          "STD"
        ]
      },
      {
        "model": "OMA",
        "variants": [
          "ELECTRIC BIKE"
        ]
      },
      {
        "model": "OMASTAR",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "LORDS",
    "models": [
      {
        "model": "EKO COSMIC",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "LORDS AUTOMATIVE",
    "models": [
      {
        "model": "LORDS ZOOM",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "ZOOM",
        "variants": [
          "LEAD ACID 60V 27AH"
        ]
      },
      {
        "model": "LZ60",
        "variants": [
          "LITHIUM 60V 28AH"
        ]
      },
      {
        "model": "ZOOM PLUS",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "LORDS AUTOMATIVE PVT LTD",
    "models": [
      {
        "model": "LZ60",
        "variants": [
          "48V 24AH",
          "48V 26AH",
          "60V 24AH",
          "60V 28AH"
        ]
      },
      {
        "model": "ZOOM PLUS",
        "variants": [
          "48V 24AH",
          "48V 26AH",
          "60V 24AH",
          "60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "LUNDAFEI",
    "models": [
      {
        "model": "133S",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "BATTLE LEOPARD",
        "variants": [
          "LA",
          "LI"
        ]
      },
      {
        "model": "LITTLE TURTLE KING",
        "variants": [
          "LA",
          "LI"
        ]
      }
    ]
  },
  {
    "make": "LYRE BIRD",
    "models": [
      {
        "model": "MAX",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "CABANA",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "LYREBIRD",
    "models": [
      {
        "model": "CABANA",
        "variants": [
          "LEAD ACID 48V 24AH",
          "LITHIUM 60V 28AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "MAX",
        "variants": [
          "LEAD ACID 48V 24AH",
          "LITHIUM 60V 28AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "LEAD ACID 48V 24AH",
          "LITHIUM 60V 28AH",
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "M2GO",
    "models": [
      {
        "model": "CIVITAS",
        "variants": [
          "STD",
          "LITHIUM BATTERY",
          "STANDARD",
          "E SCOOTER"
        ]
      },
      {
        "model": "X1",
        "variants": [
          "STD",
          "LITHIUM BATTERY",
          "STANDARD",
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "MAC",
    "models": [
      {
        "model": "WARRIOR",
        "variants": [
          "E BIKE",
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "MEGO",
        "variants": [
          "APOLLO",
          "APOLLO PLUS",
          "APOLLO PRO",
          "NEO",
          "NEO PLUS",
          "NEO PRO",
          "PRIME",
          "PRIME PLUS",
          "PRIME PRO",
          "MILAN",
          "MILAN PLUS",
          "MILAN PRO"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "CUBE",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "KAIZEN",
        "variants": [
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY"
        ]
      }
    ]
  },
  {
    "make": "MAHINDRA",
    "models": [
      {
        "model": "CENTURO",
        "variants": [
          "N1",
          "MIRZYA",
          "O1",
          "O3",
          "STD",
          "ROCKSTAR KICK ALLOY",
          "DISC BRAKE",
          "DISK BRAKE",
          "ELEC ST DRUM BRAKE AWS",
          "MIRZYA SPECIAL EDITION",
          "STD",
          "NXT BSIV",
          "DISC BRAKE BSIV",
          "ROCKSTAR BSIV",
          "ROCKSTAR KICK ALLOY BSIV",
          "DISC BRAKE",
          "NXT",
          "ROCKSTAR",
          "ROCKSTAR KICK ALLOY",
          "XT",
          "NXT",
          "XT",
          "ROCKSTAR",
          "N1",
          "110 DISC",
          "XT",
          "NXT",
          "110 CC",
          "O1 SELF START ALLOY  WHEEL DRUM BREAK",
          "SELF START ALLOY  WHEEL DRUM BREAK",
          "ROCKSTAR ALLOY WHEEL",
          "O3 KICK START ALLOY  WHEEL DRUM BRAKE",
          "N 1 SELF START ALLOY  WHEEL DRUM BREAK",
          "N1 SELF START ALLOY  WHEEL DISC BREAK",
          "SELF START ALLOY  WHEEL DISCK BREAK",
          "MIRZYA"
        ]
      },
      {
        "model": "BLAZE",
        "variants": [
          "DISC BRAKE",
          "DISK"
        ]
      },
      {
        "model": "4S",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MOJO",
        "variants": [
          "STD",
          "STANDARD",
          "BLACK PEARL",
          "GARNET BLACK",
          "RED AGATE",
          "RUBY RED",
          "UT300 BSIV",
          "XT300 BSIV",
          "STD",
          "300",
          "TOURER EDITION",
          "292",
          "XT300",
          "UT 300",
          "XT 300",
          "ABS"
        ]
      },
      {
        "model": "GUSTO",
        "variants": [
          "VX",
          "125 DX",
          "125 VX",
          "DX",
          "HX",
          "VX",
          "DX",
          "RS",
          "DX-CBS",
          "VX-CBS",
          "VX SPL EDITION",
          "DX",
          "VX",
          "VX BSIV",
          "DX BSIV",
          "RS BSIV",
          "DX BSIV",
          "VX BSIV",
          "HX",
          "DX 125",
          "VX 125",
          "RS",
          "DX 125 CC",
          "VX 125 CC",
          "ZX"
        ]
      },
      {
        "model": "PANTERO",
        "variants": [
          "T2 - SELF START",
          "T1 - SELF START",
          "ANALOG DISPLAY KICK START SPOKE WHEELS",
          "T3 - KICK START",
          "ANALOG DISPLAY KICK START ALLOY WHEELS",
          "ANALOG DISPLAY SELF START ALLOY WHEELS",
          "DIGITAL DASHBOARD SELF START ALLOY WHEELS",
          "T4 - KICK START",
          "T1 ELEC ST CAST AWS DIG CONS",
          "T2 ELEC ST CAST AWS ANLOG CONS",
          "T3 KICK ST CAST AWS ANLOG CONS",
          "T4 KICK ST SW ANALOG CONSOLE",
          "STD",
          "T1",
          "T2",
          "T3",
          "T4",
          "T1 - SELF START CAST ALLOY WHEELS DIGITAL CONSOLE",
          "T2 - SELF START CAST ALLOY WHEELS ANALOG CONSOLE",
          "T3 - KICK START CAST ALLOY WHEELS ANALOG CONSOLE",
          "T4 - KICK START SPOKE WHEEL ANALOG CONSOLE"
        ]
      },
      {
        "model": "DURO",
        "variants": [
          "DZ",
          "SCOOTER",
          "STANDARD",
          "DZ",
          "STD",
          "BZ BS III",
          "125",
          "DZ SELF START"
        ]
      },
      {
        "model": "KINE",
        "variants": [
          "SCOOTER",
          "STANDARD",
          "STD",
          "STD",
          "72",
          "70 CC SCOOTER"
        ]
      },
      {
        "model": "FLYTE",
        "variants": [
          "SCOOTER",
          "STANDARD",
          "SOLO",
          "125"
        ]
      },
      {
        "model": "STALLIO",
        "variants": [
          "KICK START",
          "SELF START",
          "KICK ST SW ANALOG SPEEDOMETER",
          "110",
          "KICK START SPOKE WHEEL ANALOG SPEEDOMETER",
          "SELF START ALLOY  WHEEL DIGITAL SPEEDOMETER"
        ]
      },
      {
        "model": "RODEO",
        "variants": [
          "UZO 125 STANDARD",
          "RZ",
          "UZO 125",
          "RZ STANDARD",
          "UZO SCOOTER",
          "RZ SCOOTER",
          "SCOOTER",
          "UZO",
          "STD",
          "RZ",
          "125",
          "UZO 125",
          "RZ 125 CC SCOOTER",
          "UZO 125 CC SCOOTER",
          "125 CC SCOOTER"
        ]
      },
      {
        "model": "STALIO",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "UZO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CENTURO MIRZYA",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MOJO UT300",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MOJO XT300",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "GUSTO 125",
        "variants": [
          "VX-CBS"
        ]
      },
      {
        "model": "ZESTO",
        "variants": [
          "SCOOTER",
          "STD",
          "110 CC SCOOTER"
        ]
      },
      {
        "model": "TWO WHEELERS LTD",
        "variants": [
          "KINE"
        ]
      },
      {
        "model": "SONIC",
        "variants": [
          "E BIKE",
          "ELECTRIC BIKE"
        ]
      },
      {
        "model": "SYM FLYTE",
        "variants": [
          "SCOOTER",
          "SCOOTER STD"
        ]
      },
      {
        "model": "CEVALO",
        "variants": [
          "STD",
          "125"
        ]
      },
      {
        "model": "STALLIO V1",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DIABLO",
        "variants": [
          "300",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "MAJESTIC AUTO LTD",
    "models": [
      {
        "model": "EFFY",
        "variants": [
          "DLX"
        ]
      },
      {
        "model": "PANTHER2S",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PANTHER 4S",
        "variants": [
          "DLX",
          "DLX RED"
        ]
      },
      {
        "model": "PANTHER",
        "variants": [
          "STD",
          "SUPER60",
          "SUPER60 DLX"
        ]
      },
      {
        "model": "POWER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STALLION",
        "variants": [
          "110"
        ]
      },
      {
        "model": "POWER MOPED",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STUDENT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GIZMO",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "MANSA AUTO",
    "models": [
      {
        "model": "MINDA",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "MANTRA",
    "models": [
      {
        "model": "LEGEND",
        "variants": [
          "48V/32AH",
          "60V"
        ]
      },
      {
        "model": "MARVEL",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RIDER",
        "variants": [
          "ECO",
          "STD",
          "DLX"
        ]
      },
      {
        "model": "ROYAL",
        "variants": [
          "STD",
          "DLX"
        ]
      },
      {
        "model": "MONARCH",
        "variants": [
          "LEGEND"
        ]
      }
    ]
  },
  {
    "make": "MANTRA E BIKES",
    "models": [
      {
        "model": "MANTRA",
        "variants": [
          "MARVEL",
          "RIDER",
          "ROYAL",
          "MONARCH",
          "RIDER REGULER STD",
          "RIDER NEW STD",
          "RIDER DLUX",
          "ROYAL DELUX",
          "RIDER SUPER LITHIYAM 60/35",
          "RIDER NEW LITHIYAM 60/35",
          "ROYAL DLX LITHIYAM 60/35"
        ]
      },
      {
        "model": "LEGEND",
        "variants": [
          "E SCOOTER",
          "DELUXE SCOOTER",
          "SUPER LEAD ACID 60V 28AH",
          "DELUX LEAD ACID 60V 28AH",
          "SUPER LITHIUM ION 60V 30AH",
          "DELUX LITHIUM ION 60V 30AH",
          "SUPER LITHIUM ION 52V 40AH",
          "SUPER LEAD ACID 12V 28AH",
          "LITHIUM ION 48V 28AH"
        ]
      },
      {
        "model": "RIDER",
        "variants": [
          "DISC BREAK LEAD ACID 60V 28AH",
          "SUPER LEAD ACID 60V 28AH",
          "DELUX LEAD ACID 60V 28AH",
          "SUPER LITHIUM ION 60V 30AH",
          "DELUX LITHIUM ION 60V 30AH",
          "SUPER LITHIUM ION 52V 40AH",
          "ECO LEAD ACID 60V 28AH",
          "DISC BRAKE LEAD ACID 60V 28AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 28AH"
        ]
      },
      {
        "model": "ROYAL",
        "variants": [
          "DELUX LEAD ACID 60V 28AH",
          "NEW LEAD ACID 60V 28AH",
          "DELUX LITHIUM ION 60V 30AH",
          "NEW LITHIUM ION 60V 30AH",
          "NEW LITHIUM ION 52V 40AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LITHIUM ION 52V 40AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 28AH"
        ]
      },
      {
        "model": "MARVEL",
        "variants": [
          "DELUX LEAD ACID 60V 28AH",
          "DELUX LITHIUM ION 60V 30AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 28AH"
        ]
      },
      {
        "model": "RIDER DELUX",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 28AH"
        ]
      },
      {
        "model": "RIDER SUPER",
        "variants": [
          "LITHIUM ION 52V 40AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "LEGEND DELUX",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "ROYAL DELUX",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM ION 48V 28AH"
        ]
      },
      {
        "model": "LEGEND SUPER",
        "variants": [
          "LEAD ACID 60V 28 AH"
        ]
      },
      {
        "model": "MARVEL DELUX",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "MONARCH",
        "variants": [
          "LITHIUM ION 48V 28AH"
        ]
      },
      {
        "model": "MINI MARVEL",
        "variants": [
          "LITHIUM ION 48V 28AH"
        ]
      }
    ]
  },
  {
    "make": "MARUTHISAN",
    "models": [
      {
        "model": "RACER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "DREAM PLUS",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "BEAT",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "MS 3.0",
        "variants": [
          "60 V 30 AH",
          "72 V 30 AH",
          "72V",
          "60 V"
        ]
      }
    ]
  },
  {
    "make": "MARUTHISAN PVT LTD",
    "models": [
      {
        "model": "MS 3.0",
        "variants": [
          "72V 40AH",
          "72V 29AH"
        ]
      },
      {
        "model": "MS 5.0",
        "variants": [
          "72V 50AH"
        ]
      }
    ]
  },
  {
    "make": "MARVEL",
    "models": [
      {
        "model": "VGO",
        "variants": [
          "60V-24AH"
        ]
      }
    ]
  },
  {
    "make": "MARVEL E AUTO",
    "models": [
      {
        "model": "RAFALE LOADER",
        "variants": [
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "VGO",
        "variants": [
          "LITHIUM 60V 35AH",
          "LITHIUM ION 48V 30AH",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 48V 24AH"
        ]
      },
      {
        "model": "HITZ",
        "variants": [
          "LITHIUM ION 60V",
          "LITHIUM ION 60V 24AH",
          "LITHIUM ION 48V 30AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 48V 24AH"
        ]
      },
      {
        "model": "IRIS",
        "variants": [
          "LITHIUM ION 48V 30AH",
          "LITHIUM ION 48V 24AH",
          "LITHIUM ION 60V 30AH",
          "LITHIUM ION 60V 24AH"
        ]
      }
    ]
  },
  {
    "make": "MATCHLESS",
    "models": [
      {
        "model": "STANDARD",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "MATCHLESS MOTORCYCLES",
    "models": [
      {
        "model": "MATCHLESS",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "MATIN",
    "models": [
      {
        "model": "WOLF WARRIER",
        "variants": [
          "LED",
          "LITHIUM"
        ]
      },
      {
        "model": "JUMBO",
        "variants": [
          "LED",
          "LITHIUM"
        ]
      },
      {
        "model": "JUMBO DLX",
        "variants": [
          "LED",
          "LITHIUM"
        ]
      },
      {
        "model": "PHANTOM",
        "variants": [
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "MATTER",
    "models": [
      {
        "model": "AERA",
        "variants": [
          "5000",
          "5000 PLUS",
          "4000",
          "6000 PLUS",
          "5000",
          "5000 PLUS"
        ]
      }
    ]
  },
  {
    "make": "MAXIM",
    "models": [
      {
        "model": "CHOPPER",
        "variants": [
          "48V-28AH",
          "60V-28AH"
        ]
      },
      {
        "model": "SPRINT",
        "variants": [
          "48V-28AH",
          "60V-28AH"
        ]
      },
      {
        "model": "FUSION",
        "variants": [
          "48V-28AH",
          "60V-28AH"
        ]
      },
      {
        "model": "STREAM",
        "variants": [
          "48V-28AH",
          "60V-28AH"
        ]
      },
      {
        "model": "SPRINT NEW",
        "variants": [
          "66014",
          "60V-28AH"
        ]
      },
      {
        "model": "RUAN",
        "variants": [
          "48V-28AH",
          "60V-28AH"
        ]
      }
    ]
  },
  {
    "make": "MAXIM E BIKE",
    "models": [
      {
        "model": "FUSION",
        "variants": [
          "LEAD ACID",
          "LITHIUM 48V 28AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "STREAM",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "SPRINT",
        "variants": [
          "LITHIUM 48V 28AH",
          "LITHIUM 60V 36AH",
          "LITHIUM 60V 28AH"
        ]
      },
      {
        "model": "CHOPPER",
        "variants": [
          "LITHIUM 48V 28AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "NEW SPRINT",
        "variants": [
          "LITHIUM 48V 28AH"
        ]
      },
      {
        "model": "BIG CHOOPER",
        "variants": [
          "LITHIUM 60V 28AH"
        ]
      },
      {
        "model": "SUPREME",
        "variants": [
          "LITHIUM 60V 34AH"
        ]
      }
    ]
  },
  {
    "make": "MAYURI",
    "models": [
      {
        "model": "SMART E",
        "variants": [
          "60V LITHIUM"
        ]
      },
      {
        "model": "TRENDY",
        "variants": [
          "60V LITHIUM"
        ]
      }
    ]
  },
  {
    "make": "MBK",
    "models": [
      {
        "model": "BOOSTER",
        "variants": [
          "SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "MECPOWER MOBILITY",
    "models": [
      {
        "model": "HS",
        "variants": [
          "RUNR"
        ]
      }
    ]
  },
  {
    "make": "MECPOWER MOBILITY PVT LTD",
    "models": [
      {
        "model": "RUNR HS",
        "variants": [
          "LITHIUM 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "MEERA ELECTROMOTIVES",
    "models": [
      {
        "model": "FELICO",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "MERAKI EZING",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "MEGO",
    "models": [
      {
        "model": "MILAN",
        "variants": [
          "ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "MELHUA",
    "models": [
      {
        "model": "E-TORQ",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "CLASSIC",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "E-ROY",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "E-ROY K2",
        "variants": [
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "MERCURY METALS LTD",
    "models": [
      {
        "model": "THUNDERBOLT",
        "variants": [
          "LITHIUM 48V 35AH"
        ]
      }
    ]
  },
  {
    "make": "MERICO",
    "models": [
      {
        "model": "EAGLE-100 4.8",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "EAGLE-100 6.0",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "FASHIA",
        "variants": [
          "STANDARD",
          "STD",
          "STANDARD"
        ]
      },
      {
        "model": "EVANKA",
        "variants": [
          "STANDARD",
          "ELECTRIC",
          "STANDARD"
        ]
      },
      {
        "model": "SPEEDSTAR",
        "variants": [
          "STANDARD",
          "ELECTRIC",
          "STANDARD"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "6.0 28AH",
          "4.8"
        ]
      },
      {
        "model": "ALEXA",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "EAGLE-100",
        "variants": [
          "STANDARD 48",
          "STANDARD 6.0"
        ]
      }
    ]
  },
  {
    "make": "MERICO ELECTRIC",
    "models": [
      {
        "model": "EAGLE 100",
        "variants": [
          "E SCOOTER",
          "SMOOTHY E SCOOTER"
        ]
      },
      {
        "model": "FASHIA",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "BESTY",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "SPEEDSTAR",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "MILANO PLUS",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "EVANKA",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "MEW",
    "models": [
      {
        "model": "EUROPA EV 1 0",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "MICELIO MOTORS PVT LTD",
    "models": [
      {
        "model": "DIPLOS",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "MICRO AUTOTECH",
    "models": [
      {
        "model": "MICRO ES21",
        "variants": [
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "MILG",
    "models": [
      {
        "model": "JY",
        "variants": [
          "60V"
        ]
      }
    ]
  },
  {
    "make": "MINDA EV",
    "models": [
      {
        "model": "GLIZER",
        "variants": [
          "60V 24AH"
        ]
      },
      {
        "model": "RIDER",
        "variants": [
          "LITHIUM ION 60V 30AH",
          "LEAD ACID 48V 27AH",
          "LEAD ACID 60V 27AH",
          "LITHIUM ION 48V 30AH"
        ]
      },
      {
        "model": "AZZURE",
        "variants": [
          "60V 30AH"
        ]
      },
      {
        "model": "ZOOM",
        "variants": [
          "LITHIUM ION 60V 30AH",
          "LEAD ACID 48V 27AH",
          "LEAD ACID 60V 27AH",
          "LITHIUM ION 48V 30AH"
        ]
      },
      {
        "model": "RACITY",
        "variants": [
          "LITHIUM ION 60V 30AH",
          "LEAD ACID 48V 27AH",
          "LITHIUM ION 48V 30AH"
        ]
      },
      {
        "model": "PULSO LOADER",
        "variants": [
          "60V 36AH"
        ]
      },
      {
        "model": "GLIZER PLUS",
        "variants": [
          "LEAD ACID 48V 27AH",
          "LEAD ACID 60V 27AH",
          "LITHIUM ION 48V 30AH",
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "VGLIDE",
        "variants": [
          "LEAD ACID 48V 27AH",
          "LEAD ACID 60V 27AH",
          "LITHIUM ION 48V 30AH",
          "LITHIUM ION 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "MIRACLE",
    "models": [
      {
        "model": "MIRACLE 5",
        "variants": [
          "SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "MIRACLE 5",
    "models": [
      {
        "model": "MIRACLE 5",
        "variants": [
          "LEADACID BATTERY",
          "LI 48V 30AH",
          "LI 60V 30AH",
          "LEAD ACID",
          "LITHIUMION"
        ]
      },
      {
        "model": "FUTURA",
        "variants": [
          "LEADACID BATTERY",
          "LI 48V 30AH",
          "LI 60V 30AH",
          "NX LI 60V 30AH",
          "LEAD ACID",
          "LITHIUMION",
          "NX ELECTRIC",
          "NX",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 60V 30AH",
          "E BIKE",
          "NX"
        ]
      },
      {
        "model": "RACE 70",
        "variants": [
          "LEADACID BATTERY",
          "LI 48V 30AH",
          "LI 60V 30AH",
          "LEAD ACID",
          "LITHIUMION",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 60V 30AH",
          "LITHIUM BATTERY 48V 30AH"
        ]
      },
      {
        "model": "RACE 80",
        "variants": [
          "LEADACID BATTERY",
          "LI 48V 30AH",
          "LI 60V 30AH",
          "LEAD ACID",
          "LITHIUMION",
          "LITHIUM BATTERY 60V 30AH",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 48V 30AH"
        ]
      },
      {
        "model": "RACE 40",
        "variants": [
          "LI 60V 30AH",
          "LEADACID BATTERY",
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY",
          "E BIKE"
        ]
      },
      {
        "model": "RACE 50",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "RACE 60",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "MIZUKI",
    "models": [
      {
        "model": "SPORT",
        "variants": [
          "ELECTRIC VEHICLE"
        ]
      },
      {
        "model": "SQARE PACE",
        "variants": [
          "LITHIUM 60V 36AH"
        ]
      }
    ]
  },
  {
    "make": "MJS E TECH VEHICLE",
    "models": [
      {
        "model": "Z ONE",
        "variants": [
          "LITHIUM ION  60V 30AH",
          "LITHIUM ION  60V 24AH"
        ]
      },
      {
        "model": "Z 5",
        "variants": [
          "LITHIUM ION  60V 30AH",
          "LITHIUM ION  60V 24AH"
        ]
      },
      {
        "model": "A ONE",
        "variants": [
          "LITHIUM ION  60V 30AH",
          "LITHIUM ION  60V 24AH"
        ]
      },
      {
        "model": "JETXR PLUS",
        "variants": [
          "LITHIUM ION  60V 24AH",
          "LITHIUM ION  60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "MLR AUTO LTD",
    "models": [
      {
        "model": "MLR25",
        "variants": [
          "LEAD ACID 60V 29AH"
        ]
      }
    ]
  },
  {
    "make": "MLR MOTORS",
    "models": [
      {
        "model": "MLR 25",
        "variants": [
          "60V"
        ]
      }
    ]
  },
  {
    "make": "MOBYCY",
    "models": [
      {
        "model": "ZYPP",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "MONTO",
    "models": [
      {
        "model": "COSMO",
        "variants": [
          "BRIGHT",
          "BLASTER",
          "GAZAB"
        ]
      },
      {
        "model": "AVANTI",
        "variants": [
          "MOPED"
        ]
      },
      {
        "model": "PILLION",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "AVANTI MONT",
        "variants": [
          "2 STRKE MOPED"
        ]
      }
    ]
  },
  {
    "make": "MONTO MOTORS",
    "models": [
      {
        "model": "AVANTI KOBRA",
        "variants": [
          "100"
        ]
      },
      {
        "model": "AVANTIKA X BIKE",
        "variants": [
          "100"
        ]
      },
      {
        "model": "PENGOT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "AVANTI",
        "variants": [
          "KOBRA"
        ]
      },
      {
        "model": "COSMO BLASTER",
        "variants": [
          "125"
        ]
      }
    ]
  },
  {
    "make": "MOPED",
    "models": [
      {
        "model": "NV",
        "variants": [
          "150"
        ]
      },
      {
        "model": "SPOT",
        "variants": [
          "MOPED"
        ]
      }
    ]
  },
  {
    "make": "MORELLO YAMASAKI",
    "models": [
      {
        "model": "PUNK",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RIPPLI",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "MOTION MAN",
    "models": [
      {
        "model": "EZGO",
        "variants": [
          "60V 20AH",
          "60V 30AH",
          "60V 40AH",
          "VZPA VLRA",
          "VZPA 20AH"
        ]
      },
      {
        "model": "VZPA",
        "variants": [
          "60V 20AH",
          "60V 30AH",
          "60V 40AH"
        ]
      }
    ]
  },
  {
    "make": "MOTIONMAN SCOOTERS",
    "models": [
      {
        "model": "EZGO",
        "variants": [
          "LEAD ACID",
          "LITHIUM ION 20 AH",
          "LITHIUM ION 30 AH",
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "VZPA",
        "variants": [
          "LEAD ACID",
          "LITHIUM ION 20 AH",
          "LITHIUM ION 30 AH"
        ]
      },
      {
        "model": "EVEX",
        "variants": [
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY"
        ]
      }
    ]
  },
  {
    "make": "MOTO",
    "models": [
      {
        "model": "CALIFORNIA",
        "variants": [
          "CLASSIC"
        ]
      },
      {
        "model": "GUZZI",
        "variants": [
          "BELLAGIO 940",
          "CALIFORNIA 1400",
          "MGX-21",
          "SPORTS 8V",
          "V9 BOBBER",
          "V9 ROAMER"
        ]
      }
    ]
  },
  {
    "make": "MOTO GUZZI",
    "models": [
      {
        "model": "AUDACE",
        "variants": [
          "STANDARD",
          "1400",
          "STD"
        ]
      },
      {
        "model": "BELLAGIO",
        "variants": [
          "BLACK EAGLE",
          "940"
        ]
      },
      {
        "model": "BELLAGIO BLACK EAGLE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CALIFORNIA 1400 ABS TOUR FULL",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CALIFORNIA 1400",
        "variants": [
          "CUSTOM"
        ]
      },
      {
        "model": "ELDORADO",
        "variants": [
          "STANDARD",
          "1400",
          "STD"
        ]
      },
      {
        "model": "GRISO 1200 8V SE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPORTS 8V",
        "variants": [
          "CORSA",
          "STANDARD"
        ]
      },
      {
        "model": "CALIFORNIA",
        "variants": [
          "1400 CUSTOM ABS",
          "1400 TOURING",
          "VINTAGE 1100",
          "1400"
        ]
      },
      {
        "model": "GRISO",
        "variants": [
          "SE"
        ]
      },
      {
        "model": "STELVIO",
        "variants": [
          "NXT"
        ]
      },
      {
        "model": "V9 BOBBER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "V9 ROAMER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MGX-21",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "V85",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "V85 TT",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPORTS",
        "variants": [
          "8V"
        ]
      }
    ]
  },
  {
    "make": "MOTO MORINI",
    "models": [
      {
        "model": "SEIEMMEZZO",
        "variants": [
          "RETRO STREET",
          "SCRAMBLER"
        ]
      },
      {
        "model": "X-CAPE",
        "variants": [
          "STANDARD",
          "X"
        ]
      }
    ]
  },
  {
    "make": "MOTRON ENERGY",
    "models": [
      {
        "model": "CLASSIC PRO",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "MR E BIKE",
    "models": [
      {
        "model": "ENERGY",
        "variants": [
          "LEAD ACID 60V",
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "DRISH",
        "variants": [
          "LEAD ACID 60V",
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "ENVY",
        "variants": [
          "LEAD ACID 48V",
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "VESPA",
        "variants": [
          "LEAD ACID 60V",
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "ARGH",
        "variants": [
          "LEAD ACID 48V",
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "AFFAIR",
        "variants": [
          "LEAD ACID 60V",
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "ZEEZ PLUS",
        "variants": [
          "LEAD ACID 48V",
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "LOADER",
        "variants": [
          "LITHIUM ION 60V"
        ]
      }
    ]
  },
  {
    "make": "MU POWER",
    "models": [
      {
        "model": "BIG BOSS",
        "variants": [
          "60V/30AH",
          "66V ESCOOTER"
        ]
      },
      {
        "model": "ULTRA BEAUTEE",
        "variants": [
          "60V/30AH",
          "40V-45AH"
        ]
      },
      {
        "model": "ELITE",
        "variants": [
          "60V/30AH",
          "40V-45AH"
        ]
      }
    ]
  },
  {
    "make": "MU POWER INDUSTRIES",
    "models": [
      {
        "model": "BIG BOSS",
        "variants": [
          "LEAD ACID 72V 30AH"
        ]
      },
      {
        "model": "DESTINY 7G",
        "variants": [
          "LEAD ACID 72V 30AH"
        ]
      }
    ]
  },
  {
    "make": "MUDIT SR RONGDA",
    "models": [
      {
        "model": "MAXX",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SMART",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "TELSA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "XL",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "CLASSIC",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SUPER",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "EV 400",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "EV 300",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "MUNJAL MOTORS",
    "models": [
      {
        "model": "REGAL",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "REGAL ZX",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "REGAL PLUS",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "CASPER",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "REGAL ACTIVE",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "CASPER PRO DESTINY",
        "variants": [
          "LEAD ACID 48V",
          "LEAD ACID 60V",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "MV",
    "models": [
      {
        "model": "AGUSTA",
        "variants": [
          "1090 BRUTALE",
          "BRUTALE 1090 RR",
          "BRUTALE 675",
          "BRUTALE 800",
          "DRAGSTER 800 RR",
          "F3 675",
          "F3 800",
          "F4 RC"
        ]
      },
      {
        "model": "AGUSTA F4",
        "variants": [
          "R",
          "RR"
        ]
      },
      {
        "model": "AGUSTA TURISMO",
        "variants": [
          "VELOCE 800"
        ]
      }
    ]
  },
  {
    "make": "MV AGUSTA",
    "models": [
      {
        "model": "BRUTALE 1090",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "BRUTALE 1090 RR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "BRUTALE 800",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "F4",
        "variants": [
          "STANDARD",
          "ABS (998 CC)",
          "RC STANDARD",
          "RR STANDARD",
          "RC",
          "F4",
          "RR",
          "LH 44"
        ]
      },
      {
        "model": "BRUTALE",
        "variants": [
          "920",
          "1090",
          "RR 1090",
          "675",
          "800",
          "800",
          "800 RR",
          "800 RR LH4",
          "800 RR PIRELLI",
          "800 RC"
        ]
      },
      {
        "model": "F3",
        "variants": [
          "STD (675 CC)",
          "800",
          "675 RC",
          "800",
          "800 RC",
          "675"
        ]
      },
      {
        "model": "DRAGSTER 800 RR",
        "variants": [
          "STANDARD",
          "AMERICA",
          "PIRELLI"
        ]
      },
      {
        "model": "TURISMO VELOCE 800",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "BRUTALE 800 RR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "F3 800",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "MY ELECTRIC CART",
    "models": [
      {
        "model": "JIVA JABARI",
        "variants": [
          "LA 60V 28AH 1KW",
          "LI 48V 30AH 1KW",
          "LI 60V 30AH 1KW",
          "LA 48V 28AH 1KW"
        ]
      },
      {
        "model": "SNIPPER",
        "variants": [
          "LA 48V 28AH 1KW",
          "LA 60V 28AH 1KW",
          "LI 48V 30AH 1KW",
          "LI 60V 30AH 1KW"
        ]
      },
      {
        "model": "TRIVO",
        "variants": [
          "LA 48V 28AH 1KW",
          "LA 60V 28AH 1KW",
          "LI 48V 30AH 1KW",
          "LI 60V 30AH 1KW"
        ]
      },
      {
        "model": "JIVA SNIPPER",
        "variants": [
          "LA 48V 28AH 1KW",
          "LA 60V 28AH 1KW",
          "LI 48V 30AH 1KW",
          "LI 60V 30AH 1KW"
        ]
      },
      {
        "model": "JIVA TRIVO",
        "variants": [
          "LA 48V 28AH 1KW",
          "LA 60V 28AH 1KW",
          "LI 48V 30AH 1KW",
          "LI 60V 30AH 1KW"
        ]
      },
      {
        "model": "JIVA FUSION",
        "variants": [
          "LA 48V 28AH 1KW",
          "LA 60V 28AH 1KW",
          "LI 48V 30AH 1KW",
          "LI 60V 30AH 1KW"
        ]
      },
      {
        "model": "JIVA VISTA",
        "variants": [
          "LA 48V 28AH 1KW",
          "LA 60V 28AH 1KW",
          "LI 48V 30AH 1KW",
          "LI 60V 30AH 1KW"
        ]
      },
      {
        "model": "JIVA AURUM",
        "variants": [
          "LA 48V 28AH 1KW",
          "LA 60V 28AH 1KW",
          "LI 48V 30AH 1KW",
          "LI 60V 30AH 1KW"
        ]
      },
      {
        "model": "JIVA VENICE",
        "variants": [
          "LA 48V 28AH 1KW",
          "LA 60V 28AH 1KW",
          "LI 48V 30AH 1KW",
          "LI 60V 30AH 1KW"
        ]
      }
    ]
  },
  {
    "make": "MZ",
    "models": [
      {
        "model": "MOSKITO",
        "variants": [
          "125 CC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "NAGAKAWA",
    "models": [
      {
        "model": "NK125",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "NAHAK MOTORS PVT LTD",
    "models": [
      {
        "model": "PARI",
        "variants": [
          "LITHIUM 48V 30AH"
        ]
      }
    ]
  },
  {
    "make": "NDS ECO MOTORS",
    "models": [
      {
        "model": "ALFA",
        "variants": [
          "E BIKE",
          "STD",
          "E SCOOTER"
        ]
      },
      {
        "model": "LIO",
        "variants": [
          "SINGLE LITHIUM ION BATTERY",
          "E BIKE",
          "SINGLE LITHIUM ION BATTERY",
          "PLUS",
          "STD",
          "E SCOOTER"
        ]
      },
      {
        "model": "LIO+",
        "variants": [
          "DOUBLE LITHIUM ION BATTERY",
          "DOUBLE LITHIUM ION BATTERY"
        ]
      },
      {
        "model": "LIO PLUS",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "NDS ECO MOTORS PVT. LTD.",
    "models": [
      {
        "model": "LIO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ALFA",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "NDS MOTORS",
    "models": [
      {
        "model": "LIO",
        "variants": [
          "LIO"
        ]
      }
    ]
  },
  {
    "make": "NEW AGE WHEELS",
    "models": [
      {
        "model": "LYRA",
        "variants": [
          "LA 60V 28AH 250WATT"
        ]
      },
      {
        "model": "PHANTOM",
        "variants": [
          "LA 72V  250WATT"
        ]
      },
      {
        "model": "FUSION",
        "variants": [
          "LA 60V 28AH 250WATT"
        ]
      },
      {
        "model": "DESTINY",
        "variants": [
          "LA 60V 28AH 250WATT"
        ]
      },
      {
        "model": "ASTER",
        "variants": [
          "LA 60V 28AH 250WATT"
        ]
      },
      {
        "model": "OPTIMA",
        "variants": [
          "LA 60V 28AH 250WATT"
        ]
      },
      {
        "model": "FALCON",
        "variants": [
          "LA 60V 28AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "NEWTRON",
    "models": [
      {
        "model": "Z 250",
        "variants": [
          "E BIKE",
          "LI"
        ]
      },
      {
        "model": "Z 300",
        "variants": [
          "E BIKE",
          "LI"
        ]
      },
      {
        "model": "Z 350",
        "variants": [
          "E BIKE",
          "LI"
        ]
      },
      {
        "model": "Z 400",
        "variants": [
          "LEAD ACID",
          "LITHIUM ION 60V 29AH"
        ]
      },
      {
        "model": "XE 750",
        "variants": [
          "LITHIUM ION 72V 40AH"
        ]
      },
      {
        "model": "XE 850",
        "variants": [
          "LITHIUM ION 72V 40AH"
        ]
      }
    ]
  },
  {
    "make": "NEWTRON ELECTRIC",
    "models": [
      {
        "model": "Z350",
        "variants": [
          "60V"
        ]
      },
      {
        "model": "Z300",
        "variants": [
          "60V"
        ]
      },
      {
        "model": "Z250",
        "variants": [
          "60V"
        ]
      },
      {
        "model": "XE750",
        "variants": [
          "E-SCOOTER"
        ]
      },
      {
        "model": "XE850",
        "variants": [
          "LITHIUM 60V 35AH"
        ]
      },
      {
        "model": "XE 850",
        "variants": [
          "LI 60V 25AH 2KW"
        ]
      },
      {
        "model": "XE 750",
        "variants": [
          "LITHIUM ION 60V 35AH 2KW"
        ]
      }
    ]
  },
  {
    "make": "NEXA ELECTRIC",
    "models": [
      {
        "model": "K-26",
        "variants": [
          "60V/26 AH"
        ]
      },
      {
        "model": "R-30",
        "variants": [
          "60V/30 AH"
        ]
      },
      {
        "model": "V-30",
        "variants": [
          "60V/30 AH"
        ]
      },
      {
        "model": "S-40",
        "variants": [
          "60V/40 AH"
        ]
      },
      {
        "model": "R-52",
        "variants": [
          "60V/52 AH"
        ]
      },
      {
        "model": "FANTACY",
        "variants": [
          "60V/30 AH"
        ]
      },
      {
        "model": "FANTACY SUPER PRO",
        "variants": [
          "60V/30 AH"
        ]
      },
      {
        "model": "SINGMA",
        "variants": [
          "60V/30 AH"
        ]
      },
      {
        "model": "SINGMA BX",
        "variants": [
          "60V/30 AH"
        ]
      },
      {
        "model": "AGM V30",
        "variants": [
          "60V-30AH"
        ]
      },
      {
        "model": "AGM R30",
        "variants": [
          "60V-30AH"
        ]
      },
      {
        "model": "AGM K26",
        "variants": [
          "60V-26AH"
        ]
      }
    ]
  },
  {
    "make": "NEXZU MOBILITY PVT LTD",
    "models": [
      {
        "model": "XERO LI-ON TREND E",
        "variants": [
          "LITHIUM SINGLE 24AH"
        ]
      },
      {
        "model": "XERO TREND E",
        "variants": [
          "LITHIUM DOUBLE 24AH"
        ]
      },
      {
        "model": "DEXTRO",
        "variants": [
          "LITHIUM SINGLE 24AH"
        ]
      },
      {
        "model": "DEXTRO PLUS",
        "variants": [
          "LITHIUM SINGLE 24AH",
          "LITHIUM DUBBLE 24AH"
        ]
      }
    ]
  },
  {
    "make": "NIJ AUTOMATIVE",
    "models": [
      {
        "model": "R14",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "QV60",
        "variants": [
          "LEAD 60V 27AH",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "FLION ROUND",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 48V 30AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ACCELERO PLUS",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "FLION SQUARE",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 60V 24AH"
        ]
      },
      {
        "model": "ACCELERO",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ACCELERO X PRO",
        "variants": [
          "LEAD ACID 72V 28AH"
        ]
      }
    ]
  },
  {
    "make": "NIJ AUTOMOTIVE",
    "models": [
      {
        "model": "ACCELERO X-PRO",
        "variants": [
          "60 V 28 AH",
          "60 V 55 AH"
        ]
      },
      {
        "model": "ACCELERO",
        "variants": [
          "LEAD 48V/27AH",
          "LEAD 60V/27AH",
          "LITHIUM 60V/24AH",
          "LITHIUM 60V/30AH",
          "X-PRO 60 V 28 AH",
          "X-PRO 60 V 55 AH",
          "R14 LEAD ACID",
          "R14 LITHIUM ION"
        ]
      },
      {
        "model": "ACCELERO PLUS",
        "variants": [
          "LEAD 48V/27AH",
          "LEAD 60V/27AH",
          "LITHIUM 60V/24AH",
          "LITHIUM 60V/30AH"
        ]
      },
      {
        "model": "FLION ROUND",
        "variants": [
          "LEAD 48V/27AH",
          "LEAD 60V/27AH",
          "LITHIUM 60V/24AH"
        ]
      },
      {
        "model": "FLION SQUARE",
        "variants": [
          "LEAD 48V/27AH",
          "LEAD 60V/27AH",
          "LITHIUM 60V/24AH"
        ]
      },
      {
        "model": "QV60",
        "variants": [
          "LEAD 60V/27AH",
          "LITHIUM 60V/24AH",
          "LITHIUM 60V/30AH"
        ]
      }
    ]
  },
  {
    "make": "NIJ AUTOMOTIVES",
    "models": [
      {
        "model": "ACCELERO",
        "variants": [
          "LITHIUM BATTERY 60V 24AH",
          "LEAD ACID BATTERY 48V 27AH",
          "LEAD ACID BATTERY 60V 27AH",
          "LITHIUM BATTERY 60V 20AH"
        ]
      },
      {
        "model": "FLION ROUND",
        "variants": [
          "LEAD ACID BATTERY 48V 27AH",
          "LEAD ACID BATTERY 60V 27AH",
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "QV 60",
        "variants": [
          "LEAD ACID BATTERY 60V 27AH",
          "LITHIUM BATTERY 60V 20AH",
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "FLION SQUARE",
        "variants": [
          "LEAD ACID BATTERY 48V 27AH",
          "LITHIUM BATTERY 60V 24AH",
          "LEAD ACID BATTERY 60V 27AH"
        ]
      },
      {
        "model": "ACCELERO PLUS",
        "variants": [
          "LITHIUM BATTERY 60V 24AH",
          "LITHIUM BATTERY 60V 20AH",
          "LEAD ACID BATTERY 60V 27AH",
          "LEAD ACID BATTERY 48V 27AH"
        ]
      }
    ]
  },
  {
    "make": "NISCO ELECTRA",
    "models": [
      {
        "model": "WAR+",
        "variants": [
          "E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "NIU",
    "models": [
      {
        "model": "NQI SPORTS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "NQI SHARING",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "NQI LITE",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "NK BIKE",
    "models": [
      {
        "model": "GTR",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "RM VECTOR",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "RM GTR",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "RM NINJA ONE",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "SPIMRI",
        "variants": [
          "LITHIUM 72V 32AH"
        ]
      },
      {
        "model": "GTR WOLF",
        "variants": [
          "LITHIUM 60V 30AH",
          "LEAD ACID"
        ]
      },
      {
        "model": "NINJA",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "NK E  BIKE",
    "models": [
      {
        "model": "SPIMRI",
        "variants": [
          "SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "NK ELECTRIC BIKE",
    "models": [
      {
        "model": "NINJA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SPIMRI",
        "variants": [
          "LITHIUM ION BATTERY 72V 32AH"
        ]
      },
      {
        "model": "WOLF",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "NORTH STAR",
    "models": [
      {
        "model": "GALAXY",
        "variants": [
          "LITHIUM ION 48V 30AH"
        ]
      }
    ]
  },
  {
    "make": "NORTON",
    "models": [
      {
        "model": "600",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SOLO WITH PILLON",
        "variants": [
          "VINTAGE"
        ]
      },
      {
        "model": "COMMANDO 961",
        "variants": [
          "SPORT MK II"
        ]
      },
      {
        "model": "DOMINATOR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "DOMINATOR 650 SS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "NORTON 50",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "COMMANDO",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "NRG AUTO ELECTRIC",
    "models": [
      {
        "model": "VAJRA BROAN",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GT SOUL",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "A1 SUREJA XPLOSIVE",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "NS ENERGY",
    "models": [
      {
        "model": "2916",
        "variants": [
          "60V 36AH"
        ]
      }
    ]
  },
  {
    "make": "NSU MOTORENWERKE",
    "models": [
      {
        "model": "NSU",
        "variants": [
          "QUICKELY"
        ]
      }
    ]
  },
  {
    "make": "NSW",
    "models": [
      {
        "model": "SOLIDER",
        "variants": [
          "E SCOOTERS"
        ]
      },
      {
        "model": "WARRIOR NX",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "SOLDIER",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "NSW E VEHICLES",
    "models": [
      {
        "model": "WARRIER NX",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "NUMEROS MOTORS",
    "models": [
      {
        "model": "DIPLOS",
        "variants": [
          "I PRO",
          "PRO",
          "PLUS DUAL BATTERY",
          "PLUS SINGLE BATTERY",
          "X"
        ]
      }
    ]
  },
  {
    "make": "NXT",
    "models": [
      {
        "model": "GRACE",
        "variants": [
          "48V 26AH"
        ]
      }
    ]
  },
  {
    "make": "NXTE",
    "models": [
      {
        "model": "WINNER",
        "variants": [
          "LITHIUM ION 48V 24AH"
        ]
      },
      {
        "model": "GRACE",
        "variants": [
          "LITHIUM ION 48V 24AH"
        ]
      },
      {
        "model": "PRINCE",
        "variants": [
          "LITHIUM ION 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "OBDU",
    "models": [
      {
        "model": "VAISHGO",
        "variants": [
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "PULSE",
        "variants": [
          "ELECTRIC SCOOTER"
        ]
      },
      {
        "model": "OBEYGO",
        "variants": [
          "ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "OBEN",
    "models": [
      {
        "model": "RORR",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "OCTIVA ELECTRIC VEHICLE",
    "models": [
      {
        "model": "OCTIVA",
        "variants": [
          "48 V LED ACID",
          "60 V LED ACID",
          "48 V LITHIUM 25AH",
          "60 V LITHIUM 25AH"
        ]
      }
    ]
  },
  {
    "make": "ODYSSE",
    "models": [
      {
        "model": "E2GO",
        "variants": [
          "STANDARD",
          "LITE",
          "72V 60AH",
          "LITE 72V 60AH",
          "PLUS 62V 29AH",
          "72V 60AH",
          "LITE 72V 60AH",
          "STD",
          "PLUS",
          "LITE",
          "E BIKE"
        ]
      },
      {
        "model": "HAWK +",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "HAWK LITE",
        "variants": [
          "STANDARD",
          "72V 20AH"
        ]
      },
      {
        "model": "RACER LITE",
        "variants": [
          "STANDARD",
          "72V 28AH"
        ]
      },
      {
        "model": "EVOQIS",
        "variants": [
          "STANDARD",
          "E BIKE",
          "72V 60AH",
          "STD",
          "E BIKE"
        ]
      },
      {
        "model": "V2",
        "variants": [
          "STANDARD",
          "PLUS",
          "LITHIUM ION",
          "PLUS",
          "STANDARD"
        ]
      },
      {
        "model": "HAWK PLUS",
        "variants": [
          "72V 40AH"
        ]
      },
      {
        "model": "V2 PLUS",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "RACER PLUS",
        "variants": [
          "LITHIUM 72V 28AH"
        ]
      },
      {
        "model": "VADER",
        "variants": [
          "STANDARD",
          "LI 4.5 KW"
        ]
      },
      {
        "model": "HAWK",
        "variants": [
          "STANDARD",
          "PLUS",
          "LITE",
          "LEAD ACID BATTERY",
          "PLUS LITHIUM BATTERY",
          "LITE LITHIUM BATTERY"
        ]
      },
      {
        "model": "RACER",
        "variants": [
          "STANDARD",
          "LITE",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "V 2 PLUS",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "V 2",
        "variants": [
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "OITA",
    "models": [
      {
        "model": "OITA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "BLUZ",
        "variants": [
          "60V 30AH LITHIUM"
        ]
      }
    ]
  },
  {
    "make": "OKAYA",
    "models": [
      {
        "model": "FREEDOM",
        "variants": [
          "LI-2",
          "LI",
          "LI-2 48V 30AH",
          "EV SCOOTER"
        ]
      },
      {
        "model": "CLASSIQ",
        "variants": [
          "150 PLUS",
          "100",
          "150",
          "100 PLUS",
          "150 PLUS"
        ]
      },
      {
        "model": "FAAST",
        "variants": [
          "F4-CYAN",
          "F2B",
          "STANDARD",
          "F2T",
          "F2F",
          "F3",
          "F4",
          "48V 30AH",
          "72V 60AH",
          "F4 LITHIUM 72V 30AH",
          "F2B LITHIUM 72V 30AH",
          "F2T LITHIUM 72V 30AH",
          "F3T LITHIUM 72V 24AH",
          "F2T LITHIUM 36V 36AH",
          "F2F LITHIUM 60V 36AH",
          "F3T LITHIUM 72V 48AH",
          "F2B",
          "F2T",
          "F4",
          "STANDARD",
          "F2F",
          "F3",
          "F3",
          "F3 LIFEPO4 72V 48AH 2.5KW",
          "F2T",
          "F2F",
          "F4"
        ]
      },
      {
        "model": "CLASS IQ 150+",
        "variants": [
          "E SCOOTER",
          "LI-2 48V 30AH"
        ]
      },
      {
        "model": "AVION IQ 150+",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "CLASS IQ100+",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "AVION IQ 100+",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "AVION",
        "variants": [
          "IQ 100",
          "IQ 150"
        ]
      },
      {
        "model": "SPORT",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "TRUSS2 X",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "TRIGGER-3X",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "TRENCH-X",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "CLASSIQ 150",
        "variants": [
          "PLUS LFP 48V 30AH 250WATT",
          "LITHIUM ION  48V 24AH"
        ]
      },
      {
        "model": "AVIONIQ 150",
        "variants": [
          "LITHIUM ION  48V 24AH"
        ]
      },
      {
        "model": "AVIONIQ 100",
        "variants": [
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "FREEDUM",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LITHIUM ION  48V 30AH"
        ]
      },
      {
        "model": "CLASSIQ 100",
        "variants": [
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "FAAST F4",
        "variants": [
          "LITHIUM ION  72V 60AH"
        ]
      },
      {
        "model": "FAAST F2B",
        "variants": [
          "LITHIUM PHOSPHATE BATTERY 72V 30AH"
        ]
      }
    ]
  },
  {
    "make": "OKINAWA",
    "models": [
      {
        "model": "RIDGE",
        "variants": [
          "STANDARD",
          "RIDGE 30",
          "PLUS E BIKE",
          "E BIKE",
          "30 EBIKE",
          "PLUS GPS E BIKE",
          "PLUS",
          "PLUS MAGIC BLUE",
          "100 60V 52AH",
          "STD",
          "PLUS E BIKE",
          "E BIKE",
          "PLUS SMART",
          "STD",
          "PLUS",
          "100 SCOOTER",
          "100 SCOOTER........",
          "100",
          "30",
          "E SCOOTER"
        ]
      },
      {
        "model": "PRAISE",
        "variants": [
          "BOV",
          "PRO",
          "STANDARD",
          "DD E BIKE",
          "SD E BIKE",
          "I EBIKE",
          "PRO E BIKE",
          "I PLUS E BIKE",
          "PRO (LION)",
          "E BIKE",
          "I PLUS 72V",
          "DD E BIKE",
          "SD E BIKE",
          "ELECTRIC SCOOTER",
          "PRO ELECTRIC SCOOTER",
          "PRO",
          "PRO SMALL DISK LITHIUM ION TRONTEK",
          "E SCOOTER",
          "I PRAISE",
          "SMALL DISC",
          "BIG DISC",
          "PRO"
        ]
      },
      {
        "model": "RIDGE PLUS",
        "variants": [
          "STANDARD",
          "GPS",
          "E SCOOTER",
          "WITHOUT GPS"
        ]
      },
      {
        "model": "I-PRAISE",
        "variants": [
          "STANDARD",
          "PLUS",
          "STD",
          "PLUS"
        ]
      },
      {
        "model": "LITE",
        "variants": [
          "STANDARD",
          "E BIKE",
          "STD",
          "E SCOOTER"
        ]
      },
      {
        "model": "RAISE",
        "variants": [
          "STANDARD",
          "STANDARD",
          "E SCOOTER"
        ]
      },
      {
        "model": "R30",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "DUAL",
        "variants": [
          "B2B",
          "E BIKE",
          "55 AH",
          "48V 35AH",
          "LITHIUM 48V 55AH",
          "STD",
          "100",
          "48V-35AH",
          "48V-55AH",
          "SUNSHINE YELLOW  55 AH 48 V",
          "E SCOOTER 55 AH",
          "E SCOOTER 35 AH",
          "E SCOOTER 28 AH"
        ]
      },
      {
        "model": "OKHI-90",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "R 30 LI",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "I PRAISE PLUS",
        "variants": [
          "E BIKE",
          "LITHIUM ION BATTERY 72V"
        ]
      },
      {
        "model": "OKHI 90",
        "variants": [
          "E BIKE",
          "LITHIUM ION BATTERY 72V"
        ]
      },
      {
        "model": "DUAL 100",
        "variants": [
          "LITHIUM 60V 52AH",
          "LITHIUM ION BATTERY 60V 52AH"
        ]
      },
      {
        "model": "RIDGE 800",
        "variants": [
          "100 SCOOTER..."
        ]
      },
      {
        "model": "R35",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RIDGE 100",
        "variants": [
          "LI 60V 52AH 1.6KW"
        ]
      },
      {
        "model": "TIRANGA",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "R 30",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "I PRAISE",
        "variants": [
          "PLUS E SCOOTER",
          "SMALL DISC",
          "BIG DISC"
        ]
      },
      {
        "model": "R 35",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "OKOYAMA",
    "models": [
      {
        "model": "SPORT",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "TRUSS2 X",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "TRIGGER-3X",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "TRENCH-X",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "WEXPRO",
        "variants": [
          "60V 250W"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "60V 250W"
        ]
      }
    ]
  },
  {
    "make": "OLA",
    "models": [
      {
        "model": "S1",
        "variants": [
          "PRO",
          "JET BLACK",
          "AIR",
          "STD",
          "PRO GEN 2 STANDARD",
          "PRO",
          "STANDARD",
          "NEO MINT",
          "AIR 4 KWH",
          "AIR 3 KWH",
          "AIR 2 KWH",
          "PRO (E2W-AB-04)",
          "PRO ANTHRACITE",
          "2 KWH"
        ]
      },
      {
        "model": "S1 X",
        "variants": [
          "2 KWH",
          "3 KWH",
          "PLUS"
        ]
      }
    ]
  },
  {
    "make": "OLA ELECTRIC",
    "models": [
      {
        "model": "OLA S1",
        "variants": [
          "STD",
          "PRO",
          "AIR",
          "S1 RANGE 91KM"
        ]
      },
      {
        "model": "S1 AIR",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "S1 X 3KWH",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "S1 PRO 2ND GEN",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "S1 2KWH",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "S1",
        "variants": [
          "STD",
          "AIR 4KWH",
          "AIR 2KWH",
          "AIR",
          "AIR 3KWH",
          "STANDARD"
        ]
      },
      {
        "model": "S1 PRO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "S1 P\\RO",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "OLOO",
    "models": [
      {
        "model": "GALAXY",
        "variants": [
          "E BIKE",
          "LITHIUM ION  60V 24AH",
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "ORIGEN",
        "variants": [
          "SCOOTER",
          "SCOOTER"
        ]
      },
      {
        "model": "ROADSTER",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SPEED KING",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "DISCOVERY",
        "variants": [
          "E BIKE",
          "LITHIUM ION  60V 24AH",
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "GRACIA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SPEEDKING",
        "variants": [
          "LITHIUM ION  60V 24AH",
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "ROADSTAR",
        "variants": [
          "LITHIUM ION  60V 24AH",
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "ORIGIN",
        "variants": [
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "BRIGHT",
        "variants": [
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "GRAVE",
        "variants": [
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "EMPIRE",
        "variants": [
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "SUPER 400",
        "variants": [
          "LITHIUM ION  60V 25AH",
          "LITHIUM ION  60V 30AH",
          "LITHIUM ION  60V 28AH"
        ]
      },
      {
        "model": "PRIME 400",
        "variants": [
          "LITHIUM ION  60V 28AH",
          "LITHIUM ION  60V 25AH",
          "LITHIUM ION  60V 30AH"
        ]
      },
      {
        "model": "ECO",
        "variants": [
          "LITHIUM ION  60V 25AH",
          "LITHIUM ION  48V 28AH"
        ]
      },
      {
        "model": "IGOR",
        "variants": [
          "LITHIUM ION  60V 30AH",
          "LITHIUM ION  60V 28AH",
          "LITHIUM ION  60V 25AH"
        ]
      }
    ]
  },
  {
    "make": "OMEGA",
    "models": [
      {
        "model": "SHARQ",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "LAZER",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "ELECTRA",
        "variants": [
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "OMEGA SEIKI MOBALITY",
    "models": [
      {
        "model": "SHARQ",
        "variants": [
          "60V 250W"
        ]
      },
      {
        "model": "MOPIDO",
        "variants": [
          "48V 250W"
        ]
      }
    ]
  },
  {
    "make": "OMEGA SEIKI MOBILITY",
    "models": [
      {
        "model": "LAZER",
        "variants": [
          "LITHIUM BATTERY 48V 25AH"
        ]
      },
      {
        "model": "SHARQ",
        "variants": [
          "EV 60V 30AH"
        ]
      },
      {
        "model": "ELECTRA",
        "variants": [
          "LITHIUM BATTERY 60V 29AH"
        ]
      },
      {
        "model": "MOPIDO",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "OMJAY EV LIMITED",
    "models": [
      {
        "model": "4U",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "WIND",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "XENIAA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YOUR",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "OMJAY EV LTD",
    "models": [
      {
        "model": "WIND",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "4 U",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YOUR",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "XENIAA",
        "variants": [
          "E BIKE",
          "2.0 BIKE"
        ]
      },
      {
        "model": "ATREO",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "AHAVA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SOUL",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ONE ELECTRIC",
    "models": [
      {
        "model": "KRIDN",
        "variants": [
          "STD",
          "EBIKE",
          "STANDARD",
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "ONE MOTO",
    "models": [
      {
        "model": "COMMUTA",
        "variants": [
          "LITHIUM 60V 27.5AH",
          "E-SCOOTER",
          "LITHIUM ION  60V 28AH"
        ]
      },
      {
        "model": "BYKA",
        "variants": [
          "LITHIUM 72V 48AH",
          "E-SCOOTER",
          "LITHIUM ION  72V 48AH"
        ]
      },
      {
        "model": "ELECTA",
        "variants": [
          "LITHIUM 72V 45AH",
          "E-SCOOTER",
          "LITHIUM ION  72V 45AH"
        ]
      }
    ]
  },
  {
    "make": "ONZO",
    "models": [
      {
        "model": "SUMO",
        "variants": [
          "ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ONZO EV MOTORS",
    "models": [
      {
        "model": "ONZO ECO",
        "variants": [
          "LA 60V 28AH 250WATT",
          "LI 48V 26AH 250WATT",
          "LI 60V 30AH 250WATT"
        ]
      },
      {
        "model": "ONZO KIWIK",
        "variants": [
          "LA 60V 28AH 250WATT",
          "LI 48V 26AH 250WATT",
          "LI 60V 30AH 250WATT"
        ]
      },
      {
        "model": "ONZO GREEN PRO",
        "variants": [
          "LA 60V 28AH 250WATT",
          "LI 48V 26AH 250WATT",
          "LI 60V 30AH 250WATT",
          "LA 48V 28AH 250WATT"
        ]
      },
      {
        "model": "ONZO ONE PLUS",
        "variants": [
          "LI 60V 30AH 250WATT",
          "LA 60V 28AH 250WATT",
          "LI 48V 26AH 250WATT"
        ]
      },
      {
        "model": "ONZO SUMO",
        "variants": [
          "LI 72V 40AH 1.2KW",
          "LI 60V 30AH 1.2KW"
        ]
      },
      {
        "model": "ONZO ONE",
        "variants": [
          "LI 60V 30AH 250WATT",
          "LI 48V 26AH 250WATT",
          "LA 60V 28AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "ONZO EV MOTORS PVT LTD",
    "models": [
      {
        "model": "ONZO GREEN",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 52V 26AH",
          "LITHIUM 63V 30AH",
          "LITHIUM 48V 26AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ONZO ECO",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 52V 26AH",
          "LITHIUM 63V 30AH",
          "LITHIUM 48V 26AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ONZO ONE",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 52V 26AH",
          "LITHIUM 63V 30AH",
          "LITHIUM 48V 26AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ONZO ONE PLUS",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 52V 26AH",
          "LITHIUM 63V 30AH",
          "LITHIUM 48V 26AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ONZO GREEN PRO",
        "variants": [
          "LEAD 48V 28AH",
          "LEAD 60V 28AH",
          "LITHIUM 48V 26AH",
          "LIHTIUM 60V 30AH"
        ]
      },
      {
        "model": "ONZO KWIK",
        "variants": [
          "LEAD 60V 28AH",
          "LITHIUM 48V 26AH",
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "OREVA",
    "models": [
      {
        "model": "ADIDEV",
        "variants": [
          "E BIKE",
          "LIFEPO4"
        ]
      },
      {
        "model": "ALISH 150",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ALISH LI",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ALISH J 50 PLUS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "AARIEYANA",
        "variants": [
          "E BIKE",
          "LEAD ACID BATERY"
        ]
      },
      {
        "model": "J 500 PLUS DELUXE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "PET",
        "variants": [
          "PLUS"
        ]
      },
      {
        "model": "DLX 500",
        "variants": [
          "E-BIKE"
        ]
      },
      {
        "model": "J 50 PLUS",
        "variants": [
          "LEAD BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "ALISH LFP ULTRA",
        "variants": [
          "LIFEPO4"
        ]
      },
      {
        "model": "ALISH LFP MAX",
        "variants": [
          "LIFEPO4"
        ]
      },
      {
        "model": "ALISH",
        "variants": [
          "LIFEPO4 12 - 2",
          "LIFEPO4 12 - 5",
          "LIFEPO4 30 - 2",
          "LIFEPO4 30 - 5",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "ALISH LFP MEGA",
        "variants": [
          "LIFEPO4"
        ]
      },
      {
        "model": "ALISH LFP PREMIUM",
        "variants": [
          "LIFEPO4"
        ]
      },
      {
        "model": "ALISH LFP MICRO",
        "variants": [
          "LIFEPO4"
        ]
      },
      {
        "model": "AARIEYANA LFP PREMIUM",
        "variants": [
          "LIFEPO4"
        ]
      },
      {
        "model": "AARIEYANA LFP MICRO",
        "variants": [
          "LIFEPO4"
        ]
      }
    ]
  },
  {
    "make": "OREVA ELECTRIC",
    "models": [
      {
        "model": "J50",
        "variants": [
          "E",
          "PLUS ALISH"
        ]
      },
      {
        "model": "AARIEYANA",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "ADIDEV",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "OXYLUS MOTORS",
    "models": [
      {
        "model": "VX1",
        "variants": [
          "STROKE"
        ]
      },
      {
        "model": "VX2",
        "variants": [
          "WAVE"
        ]
      },
      {
        "model": "VX3",
        "variants": [
          "PRIME +"
        ]
      },
      {
        "model": "VX4",
        "variants": [
          "TOURQUE PRO"
        ]
      },
      {
        "model": "VX5",
        "variants": [
          "OCTA EV"
        ]
      }
    ]
  },
  {
    "make": "OZONE E BIKE",
    "models": [
      {
        "model": "LEGEND SUPER S",
        "variants": [
          "LITHIUM ION 72V 45AH"
        ]
      },
      {
        "model": "MONARCH SUPER",
        "variants": [
          "LITHIUM ION 72V 45AH"
        ]
      },
      {
        "model": "MONARCH DELUXE",
        "variants": [
          "LITHIUM ION 72V 45AH"
        ]
      },
      {
        "model": "LEGEND S",
        "variants": [
          "LITHIUM ION 60V 36AH"
        ]
      },
      {
        "model": "RIDER NEW",
        "variants": [
          "LITHIUM ION 60V 36AH"
        ]
      },
      {
        "model": "OZONE EV LOADER",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "OZOTEC AUTOMOBILE",
    "models": [
      {
        "model": "FLIO",
        "variants": [
          "E BIKE",
          "PLUS"
        ]
      }
    ]
  },
  {
    "make": "OZOTEC AUTOMOBILE PVT LTD",
    "models": [
      {
        "model": "FLIO",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "PALATINO",
    "models": [
      {
        "model": "RYAN",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "ANGEL",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "SUNSHINE",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "PRINCESS",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "SPYKAR",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "PARADISE ELECTRO AUTO",
    "models": [
      {
        "model": "CLASSIC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ANGEL",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ECO",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "PARSUVI MOTORS",
    "models": [
      {
        "model": "FOX",
        "variants": [
          "60V LA",
          "60V L1"
        ]
      },
      {
        "model": "ADDI",
        "variants": [
          "60V LA",
          "60V L1"
        ]
      },
      {
        "model": "V8 PLUS",
        "variants": [
          "60V LA",
          "60V L1"
        ]
      },
      {
        "model": "TSL",
        "variants": [
          "60V LA",
          "60V L1",
          "48V",
          "60V"
        ]
      },
      {
        "model": "LIVI",
        "variants": [
          "60V LA",
          "E BIKE"
        ]
      },
      {
        "model": "OPIA",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "PARTH SARTHI AUTOMOTIVE",
    "models": [
      {
        "model": "POWER",
        "variants": [
          "60V 25AH LEAD GEL",
          "48V 26AH LITHIUM",
          "60V 30AH LITHIUM",
          "48V 25AH LEAD GEL"
        ]
      },
      {
        "model": "GT",
        "variants": [
          "60V 25AH LEAD GEL",
          "48V 26AH LITHIUM",
          "60V 30AH LITHIUM",
          "48V 25AH LEAD GEL"
        ]
      },
      {
        "model": "V PRO",
        "variants": [
          "60V 25AH LEAD GEL",
          "48V 26AH LITHIUM",
          "60V 30AH LITHIUM",
          "48V 25AH LEAD GEL"
        ]
      },
      {
        "model": "SUV",
        "variants": [
          "60V 25AH LEAD GEL",
          "48V 26AH LITHIUM",
          "60V 30AH LITHIUM",
          "48V 25AH LEAD GEL"
        ]
      }
    ]
  },
  {
    "make": "PETRA",
    "models": [
      {
        "model": "ENVO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KRONO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SPRINT",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "PEUGEOT",
    "models": [
      {
        "model": "SPEEDFIGHT 4",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DJANGO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ELYSEO SCOOTER",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "PGO",
    "models": [
      {
        "model": "BIG MAX 90",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "PIAGGIO",
    "models": [
      {
        "model": "VESPA",
        "variants": [
          "GTS 300",
          "SXL",
          "VXL",
          "ELEGANTE",
          "URBAN CLUB 125",
          "ZX CBS",
          "LX",
          "NOTTE STD",
          "S",
          "STD",
          "SXL 125",
          "SXL 150",
          "VX",
          "VXL 125",
          "VXL 150",
          "ELEGANTE",
          "RED",
          "VXL 150",
          "SXL 150",
          "ELEGANTE",
          "VXL 150 ABS",
          "SXL 150 ABS",
          "ZX CBS BSIV",
          "LX CLUB CBS",
          "VXL 125 CBS ELEGANTE",
          "URBAN CLUB CBS",
          "ZX DISC CBS",
          "VXL 150 ABS BSVI",
          "SXL 150 ABS BSVI",
          "ELEGANTE 150 ABS BSVI",
          "CLUB 125 BSVI",
          "NOTTE 125 BSVI",
          "SXL 125 CBS RACING",
          "SXL 150 ABS RACING",
          "SCOOTER WITH SIDE CAR",
          "946 STD",
          "VXL 149 ABS BSVI",
          "URBAN CLUB 125 CBS BSVI",
          "VXL 75TH 125 CBS BSVI",
          "SXL 125 RACING 60S BSVI",
          "SXL 149 ABS BSVI",
          "SXL 149 RACING 60S BSVI",
          "VXL 149 ABS ELEGANTE BSVI",
          "STD",
          "70TH AE",
          "LXI",
          "NOTTE",
          "SXL 125 CBS",
          "UC 125",
          "VE",
          "VX ELEGANTE",
          "VXL 125 BSVI",
          "VXL 125 CBS",
          "VXL 150 ABS",
          "VXL 150 ELEGANTE",
          "ZX 125",
          "ZX 125 BS6",
          "VXL 125 CBS BSVI",
          "SXL 150 ABS",
          "LX 125 BS-VI",
          "ZX 125 CBS BS VI",
          "SXL 125 CBS BSVI",
          "VX 125",
          "LX 125",
          "URBAN CLUB BSVI",
          "S 125",
          "VX SPL EDT",
          "125 STD",
          "ZX 125 CBS",
          "VXL 150 ELEGANTE ABS",
          "LX 125 CBS BS6",
          "SXL 150 ABS BS6",
          "ELEGANTE 150 BS6",
          "150 ELEGANTE BS6",
          "SXL 125 RACING SIXTIES BS6",
          "ZX 125 CBS BS6",
          "VXL 125 FL CBS BS6",
          "SR 160 ABS BS6",
          "SR 125 STORM BS6",
          "SR 125 STORM FL CBS DISC BS6",
          "SXL 150 FL ABS BS6",
          "SXL 150 RACING SIXTIES BS6",
          "VXL 125 CBS 75TH ANNIVERSARY EDITION",
          "SXL 125 RACING SIXTIES",
          "150 CC SCOOTER",
          "SXL 150 ABS BSVI",
          "VXL 150 ABS BSVI",
          "ELEGANTE 150 ABS",
          "LX 125 CBS BS VI",
          "LX 125 NOTTE",
          "URBAN CLUB",
          "VXL 125 75TH EDITION",
          "SXL 150 RACING SIXTIES",
          "VXL 150 MATT RED",
          "VXL 150 ELEGANTE ABS CONNECTIVITY",
          "ESCLUSIVO",
          "VXL 150 ELEGANT",
          "VXL 150 ABS CONNECTIVITY",
          "VXL 150 CONNECTIVITY"
        ]
      },
      {
        "model": "TYPHOON",
        "variants": [
          "125",
          "STANDARD"
        ]
      },
      {
        "model": "VESPA RED",
        "variants": [
          "125"
        ]
      },
      {
        "model": "FLY",
        "variants": [
          "125"
        ]
      },
      {
        "model": "VESPA LX 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VESPA SXL",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VESPA VXL 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VESPA VXL 150",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "APRILIA SR 150",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VESPA VX 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VESPA HE 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "X10",
        "variants": [
          "500"
        ]
      },
      {
        "model": "VESPA 154 CC",
        "variants": [
          "VXL 150",
          "SXL 150"
        ]
      },
      {
        "model": "FLY 125",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "PINAAKI ELECTRIC VEHICLE",
    "models": [
      {
        "model": "LEADER PRO",
        "variants": [
          "HIGH SPEED",
          "LOW SPEED",
          "48V28AH SMF",
          "60V28AH SMF",
          "48V20AH",
          "48V25AH",
          "48V30AH",
          "60V20AH",
          "60V25AH",
          "60V30AH"
        ]
      },
      {
        "model": "LEADER",
        "variants": [
          "LOW SPEED",
          "HIGH SPEED",
          "48V28AH SMF",
          "60V28AH SMF",
          "48V25AH",
          "48V30AH",
          "60V20AH",
          "60V25AH",
          "60V30AH"
        ]
      },
      {
        "model": "PLATINUM",
        "variants": [
          "LOW SPEED",
          "HIGH SPEED",
          "60V28AH SMF",
          "72V28AH SMF",
          "60V20AH",
          "60V25AH",
          "60V30AH",
          "72V20AH",
          "72V25AH",
          "72V30AH"
        ]
      },
      {
        "model": "DIAMOND",
        "variants": [
          "LOW SPEED",
          "HIGH SPEED",
          "48V28AH SMF",
          "60V28AH SMF",
          "48V20AH",
          "48V25AH",
          "48V30AH",
          "60V20AH",
          "60V25AH",
          "60V30AH"
        ]
      },
      {
        "model": "DIAMOND PRO",
        "variants": [
          "LOW SPEED",
          "HIGH SPEED",
          "48V28AH SMF",
          "60V28AH SMF",
          "48V20AH",
          "48V25AH",
          "48V30AH",
          "60V20AH",
          "60V25AH",
          "60V30AH"
        ]
      },
      {
        "model": "EMRALD",
        "variants": [
          "LOW SPEED",
          "HIGH SPEED"
        ]
      },
      {
        "model": "EMRALD PRO",
        "variants": [
          "LOW SPEED",
          "HIGH SPEED",
          "48V28AH SMF",
          "60V28AH SMF",
          "48V20AH",
          "48V25AH",
          "48V30AH",
          "60V20AH",
          "60V25AH",
          "60V30AH"
        ]
      },
      {
        "model": "PLATINUM PRO",
        "variants": [
          "48V28AH SMF",
          "60V28AH SMF",
          "48V20AH",
          "48V25AH",
          "48V30AH",
          "60V20AH",
          "60V25AH",
          "60V30AH"
        ]
      },
      {
        "model": "SARV SAFARI",
        "variants": [
          "48V28AH SMF",
          "60V28AH SMF",
          "48V20AH",
          "48V25AH",
          "48V30AH",
          "60V20AH",
          "60V25AH",
          "60V30AH"
        ]
      }
    ]
  },
  {
    "make": "PLASMA MOTORS",
    "models": [
      {
        "model": "LEO",
        "variants": [
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "POISE",
    "models": [
      {
        "model": "GRACE",
        "variants": [
          "34 AH",
          "42 AH"
        ]
      },
      {
        "model": "NX 120",
        "variants": [
          "34 AH",
          "43 AH",
          "52 AH"
        ]
      }
    ]
  },
  {
    "make": "POISE ELECTRIC",
    "models": [
      {
        "model": "NX120",
        "variants": [
          "E SCOOTY",
          "LITHIUM 72V 34AH",
          "LITHIUM 72V 43AH",
          "LITHIUM 72V 52AH"
        ]
      },
      {
        "model": "NX 120",
        "variants": [
          "LITHIUM ION  72V 20AH"
        ]
      }
    ]
  },
  {
    "make": "POISE SCOOTERS",
    "models": [
      {
        "model": "GRACE",
        "variants": [
          "34 AH",
          "PRO",
          "PREMIUM",
          "60V/42AH"
        ]
      },
      {
        "model": "NX-120",
        "variants": [
          "STANDARD",
          "72V/20AH",
          "72V/43AH"
        ]
      },
      {
        "model": "NX-60",
        "variants": [
          "72V/20AH"
        ]
      }
    ]
  },
  {
    "make": "POLARIS",
    "models": [
      {
        "model": "VICTORY 12",
        "variants": [
          "VISION",
          "VISION ABS INT BR"
        ]
      }
    ]
  },
  {
    "make": "PORWAL ELECTRIC",
    "models": [
      {
        "model": "ENVIRO",
        "variants": [
          "EVA",
          "X20 PLUS",
          "CLASSIC"
        ]
      }
    ]
  },
  {
    "make": "POWER BIRD",
    "models": [
      {
        "model": "BIKE",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "POWER EV",
    "models": [
      {
        "model": "P-SPORT",
        "variants": [
          "PLUS",
          "STANDARD",
          "PLUS",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "POWORO",
    "models": [
      {
        "model": "NEXPRO",
        "variants": [
          "LITHIUM 48V 30AH"
        ]
      },
      {
        "model": "AMAZE",
        "variants": [
          "LITHIUM 48V 30AH"
        ]
      },
      {
        "model": "CARRY",
        "variants": [
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 36AH",
          "LITHIUM 60V 42AH"
        ]
      }
    ]
  },
  {
    "make": "PRAKRITI",
    "models": [
      {
        "model": "DEFENDER",
        "variants": [
          "LITHIUM 60V 24AH",
          "SINGLE DISC LITHIUM BATTERY 60V 26AH",
          "SINGLE DISC LITHIUM BATTERY 60V 34AH",
          "DOUBLE DISC LITHIUM BATTERY 60V 26AH",
          "DOUBLE DISC LITHIUM BATTERY 60V 34AH",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "SUPER",
        "variants": [
          "LITHIUM 60V 34AH",
          "60V34AH LITHIUM",
          "LITHIUM BATTERY",
          "LITHIUM BATTERY 48V 26AH",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 60V 34AH"
        ]
      },
      {
        "model": "NEO",
        "variants": [
          "LITHIUM 60V 29AH",
          "60V29AH LITHIUM",
          "LITHIUM BATTERY 60V 24AH",
          "LITHIUM BATTERY 60V 29AH",
          "LITHIUM BATTERY 60V 26AH",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "ESPA",
        "variants": [
          "LITHIUM 60V 34AH",
          "2.0 LITHIUM 48V 30AH",
          "60V34AH LITHIUM",
          "LITHIUM BATTERY",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 48V 26AH",
          "LITHIUM BATTERY 60V 34AH",
          "LEAD ACID BATTERY 60V 38 AH",
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "ESPA 2.0",
        "variants": [
          "48V30AH LITHIUM"
        ]
      },
      {
        "model": "GLIDER",
        "variants": [
          "LITHIUM BATTERY 48V 26AH",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 60V 29AH"
        ]
      }
    ]
  },
  {
    "make": "PRASINOS",
    "models": [
      {
        "model": "MOON",
        "variants": [
          "60V28AH LEAD",
          "60V28AH LI"
        ]
      },
      {
        "model": "EASY",
        "variants": [
          "LEAD 1.68KWH",
          "LITHIUM 0.90 KWH",
          "LITHIUM 1.56KWH"
        ]
      }
    ]
  },
  {
    "make": "PRIMA",
    "models": [
      {
        "model": "POWER RIDE",
        "variants": [
          "CHERRY"
        ]
      },
      {
        "model": "POWER BIKE",
        "variants": [
          "RUNAWAY STANDARD"
        ]
      }
    ]
  },
  {
    "make": "PRIMA POWER BIKE",
    "models": [
      {
        "model": "POWERRIDE",
        "variants": [
          "JOE-FLY",
          "STD"
        ]
      },
      {
        "model": "JOE-GO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LECO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LIGHT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RUNAWAY",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "PROSSPA",
    "models": [
      {
        "model": "PATINA",
        "variants": [
          "48V LITHIUM ION"
        ]
      },
      {
        "model": "PRO",
        "variants": [
          "48V LITHIUM ION"
        ]
      },
      {
        "model": "QUADRA",
        "variants": [
          "48V LITHIUM ION"
        ]
      },
      {
        "model": "SAFFIRE",
        "variants": [
          "48V LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "PROSSPA E SCOOOTY",
    "models": [
      {
        "model": "PRO",
        "variants": [
          "PES 01 E SCOOTY",
          "LI-ION V24 E SCOOTY",
          "LI-ION V48 E SCOOTY"
        ]
      },
      {
        "model": "PLATINA",
        "variants": [
          "PES 02 E SCOOTY",
          "LI-ION V24 E SCOOTY",
          "LI-ION V48 E SCOOTY"
        ]
      },
      {
        "model": "SAPPHIRE",
        "variants": [
          "PES 03 E SCOOTY",
          "LI-ION V48 E SCOOTY",
          "LI-ION V48 E SCOOTY"
        ]
      },
      {
        "model": "QUADRA",
        "variants": [
          "PES 04 E SCOOTY",
          "LI-ION V24 E SCOOTY",
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "PS ELECTRIC",
    "models": [
      {
        "model": "MAGIC",
        "variants": [
          "LEAD ACID 60V"
        ]
      }
    ]
  },
  {
    "make": "PURE",
    "models": [
      {
        "model": "E PLUTO 7G",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "PURE ELECTRIC VEHICLE",
    "models": [
      {
        "model": "E PLUTO",
        "variants": [
          "STD",
          "7G E BIKE",
          "7G E SCOOTER",
          "7G PRO",
          "E SCOOTER"
        ]
      },
      {
        "model": "ETRANCE",
        "variants": [
          "PLUS",
          "NEO SCOOTY",
          "PLUS STANDARD",
          "STANDARD",
          "E SCOOTER"
        ]
      },
      {
        "model": "ECO DRYFT",
        "variants": [
          "BIKE"
        ]
      },
      {
        "model": "ETRON",
        "variants": [
          "PLUS"
        ]
      },
      {
        "model": "ETRYST",
        "variants": [
          "350"
        ]
      },
      {
        "model": "ETRANCE NEO",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "ETRANCE PLUS",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "EPLUTO 7G",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "ECODRYFT",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "ETRYST 350",
        "variants": [
          "STANDARD LITHIUM ION BATTERY"
        ]
      }
    ]
  },
  {
    "make": "PURE ELECTRIC VEHICLES",
    "models": [
      {
        "model": "ETRYST 350",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ECO DRYFT",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "EPLUTO",
        "variants": [
          "E BIKE",
          "7G",
          "7G PRO"
        ]
      },
      {
        "model": "E TRANCE",
        "variants": [
          "E BIKE",
          "NEO",
          "E BIKE",
          "PLUS E BIKE",
          "NEO"
        ]
      },
      {
        "model": "E TRANCE PLUS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ETRON +",
        "variants": [
          "E BICYCLE"
        ]
      },
      {
        "model": "EGNITE",
        "variants": [
          "E BICYCLE"
        ]
      },
      {
        "model": "E PLUTO",
        "variants": [
          "E BIKE",
          "7G"
        ]
      }
    ]
  },
  {
    "make": "PURE EV",
    "models": [
      {
        "model": "ETRANCE",
        "variants": [
          "STANDARD",
          "NEO"
        ]
      },
      {
        "model": "ETRANCE PLUS",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "EPLUTO",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "ETRON",
        "variants": [
          "STANDARD",
          "PLUS",
          "NEO"
        ]
      },
      {
        "model": "EGNITE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "EPLUTO 7G",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "QIDA",
    "models": [
      {
        "model": "ALEXIA",
        "variants": [
          "ELECTRIC",
          "PLUS ELECTRIC"
        ]
      },
      {
        "model": "GENIX",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "NEXIA",
        "variants": [
          "ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "QJ MOTOR",
    "models": [
      {
        "model": "SRC 500",
        "variants": [
          "RED WHITE",
          "GOLD BLACK",
          "SILVER BLACK",
          "STANDARD"
        ]
      },
      {
        "model": "SRC 250",
        "variants": [
          "BLACK",
          "RED",
          "SILVER",
          "STANDARD"
        ]
      },
      {
        "model": "SRK 400",
        "variants": [
          "RED",
          "BLACK",
          "WHITE"
        ]
      },
      {
        "model": "SRV 300",
        "variants": [
          "GREEN",
          "BLACK",
          "ORANGE",
          "RED"
        ]
      },
      {
        "model": "SRC 400",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SRC 300",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "QUANTUM",
    "models": [
      {
        "model": "JINKA",
        "variants": [
          "LITHIUM ION 60V"
        ]
      },
      {
        "model": "MILAN",
        "variants": [
          "LITHIUM ION 60V",
          "ELECTRIC"
        ]
      },
      {
        "model": "ELEKTRON",
        "variants": [
          "LITHIUM ION 60V",
          "ELECTRIC"
        ]
      },
      {
        "model": "BZINESS",
        "variants": [
          "LITHIUM ION 60V",
          "ELECTRIC",
          "LITE",
          "JINKA",
          "LFP ELECTRIC"
        ]
      },
      {
        "model": "PLASMA",
        "variants": [
          "LITHIUM ION 60V",
          "ELECTRIC",
          "BLUE ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "QUANTUM ENERGY LIMITED",
    "models": [
      {
        "model": "MILAN",
        "variants": [
          "1000 WATT"
        ]
      },
      {
        "model": "ELEKTRON",
        "variants": [
          "1000 WATT"
        ]
      },
      {
        "model": "BZINESS",
        "variants": [
          "1200 WATT"
        ]
      }
    ]
  },
  {
    "make": "QUITO",
    "models": [
      {
        "model": "QUITO ACTIVE",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "QUITO PLUS",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "VESPA",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "QUITO E-BIKE",
    "models": [
      {
        "model": "VESPA ACE",
        "variants": [
          "48V-28AH",
          "60V-28AH"
        ]
      },
      {
        "model": "PLUS",
        "variants": [
          "48V-28AH",
          "60V-28AH"
        ]
      }
    ]
  },
  {
    "make": "R K MOTORS",
    "models": [
      {
        "model": "MATRIX",
        "variants": [
          "STD",
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "R.K.AUTOMOBILES",
    "models": [
      {
        "model": "AFFAIR",
        "variants": [
          "ELECTRIC",
          "VSP ELECTRIC"
        ]
      },
      {
        "model": "ARGH",
        "variants": [
          "ELECTRIC",
          "PRO ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "RADO ALLOY WHEELS",
    "models": [
      {
        "model": "RADO 1",
        "variants": [
          "LEAD ACID BATTERY 60V"
        ]
      }
    ]
  },
  {
    "make": "RADO E BIKES",
    "models": [
      {
        "model": "RADO",
        "variants": [
          "LEAD ACID 60V"
        ]
      }
    ]
  },
  {
    "make": "RAFT MOTORS",
    "models": [
      {
        "model": "WARRIOR",
        "variants": [
          "60V / 30AH",
          "MAGNUM 60V/36 AH",
          "GRAPHENE",
          "60V 36AH"
        ]
      },
      {
        "model": "RAFT WINDY",
        "variants": [
          "48V / 36AH",
          "LIFE PO4 48V 24AH"
        ]
      },
      {
        "model": "SOLDIER",
        "variants": [
          "60V/ 36AH",
          "GRAPHENE"
        ]
      },
      {
        "model": "POWER PLUS",
        "variants": [
          "60V/ 36AH"
        ]
      },
      {
        "model": "HAWK PLUS",
        "variants": [
          "60V/ 36AH"
        ]
      },
      {
        "model": "NUBRA",
        "variants": [
          "60V 24AH",
          "60V 36AH",
          "GRAPHENE",
          "48V 36AH"
        ]
      },
      {
        "model": "TEESTA",
        "variants": [
          "60V 24AH",
          "60V 36AH"
        ]
      },
      {
        "model": "INDUS",
        "variants": [
          "60V 24AH",
          "GRAPHENE",
          "LITHIUM BATTERY 60V 30 AH"
        ]
      },
      {
        "model": "JISPA",
        "variants": [
          "60V 24AH",
          "60V 42AH",
          "60V 60AH"
        ]
      },
      {
        "model": "RAFT JISPA NX",
        "variants": [
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "RAFT SOLDIER",
        "variants": [
          "LITHIUM BATTERY 60V 36AH"
        ]
      },
      {
        "model": "RAFT WARRIOR",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "INDUS NX",
        "variants": [
          "LITHIUM BATTERY 60V"
        ]
      },
      {
        "model": "RAFT NUBRA",
        "variants": [
          "LIFE PO4 60V 24AH",
          "LIFE PO4 60V 36AH",
          "LIFE PO4 48V 36AH",
          "LIFE PO4 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "RAFT MOTORS PVT LTD",
    "models": [
      {
        "model": "SOLDIER",
        "variants": [
          "E SCOOTER",
          "72V/43AH"
        ]
      },
      {
        "model": "WINDY",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "INDUS",
        "variants": [
          "48V/24AH",
          "EV SCOOTER"
        ]
      },
      {
        "model": "JISPA",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "WARRIOR",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TEESTA",
        "variants": [
          "EV SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "RAFTAAR",
    "models": [
      {
        "model": "ELECTRICA",
        "variants": [
          "STANDARD",
          "E BIKE",
          "3W E BIKE"
        ]
      },
      {
        "model": "GALAXY",
        "variants": [
          "STANDARD",
          "E BIKE",
          "STD"
        ]
      },
      {
        "model": "CRUSIER",
        "variants": [
          "E BIKE",
          "STD"
        ]
      },
      {
        "model": "BUMBLEBEE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "RGR 3W",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "RAFTAAR ELECTRIC",
    "models": [
      {
        "model": "3 WHEELS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BUMBLEBEE",
        "variants": [
          "STD",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "CRUIZE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ELECTRICA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ELECTRICA LI",
        "variants": [
          "STD",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "GALAXY",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ELECTRICA S",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "ELECTRICA 3W",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "CRUZER R1",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "OREVA",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "DIAL",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      }
    ]
  },
  {
    "make": "RAILIES MOTO PVT LTD",
    "models": [
      {
        "model": "E JACO KIWI",
        "variants": [
          "LITHIUM 60V"
        ]
      }
    ]
  },
  {
    "make": "RAJ ELECTRO",
    "models": [
      {
        "model": "GRETA HARPER",
        "variants": [
          "V3 PLUS",
          "V3",
          "V2",
          "V2 PLUS",
          "ZX V2",
          "ZX V3",
          "ZX V2 PLUS"
        ]
      },
      {
        "model": "GRETA EVESPA",
        "variants": [
          "V2",
          "V3",
          "V2 PLUS",
          "V3 PLUS"
        ]
      },
      {
        "model": "GRETA GLIDE",
        "variants": [
          "V2",
          "V3",
          "V2 PLUS",
          "V3 PLUS"
        ]
      }
    ]
  },
  {
    "make": "RAJ ELECTROMOTIVES",
    "models": [
      {
        "model": "HARPER",
        "variants": [
          "LITHIUM BATTERY 48V 27AH"
        ]
      }
    ]
  },
  {
    "make": "RAJDOOT",
    "models": [
      {
        "model": "E RICKSHAW",
        "variants": [
          "DELUXE"
        ]
      },
      {
        "model": "ESCORT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MOTORCYCLE",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "RAL AUTOTECH",
    "models": [
      {
        "model": "RAL 250S",
        "variants": [
          "48/60V"
        ]
      },
      {
        "model": "RAL 250G",
        "variants": [
          "48/60V"
        ]
      },
      {
        "model": "RAL 250P",
        "variants": [
          "48/60V"
        ]
      },
      {
        "model": "250 S",
        "variants": [
          "E BIKE",
          "48 VOLT",
          "60 VOLT"
        ]
      },
      {
        "model": "250 G",
        "variants": [
          "E BIKE",
          "60 VOLT",
          "48 VOLT"
        ]
      },
      {
        "model": "250 P",
        "variants": [
          "E BIKE",
          "60 VOLT",
          "48 VOLT"
        ]
      }
    ]
  },
  {
    "make": "RAVE FOOTGEAR",
    "models": [
      {
        "model": "SCOOTY EV",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "RAY MOTORS",
    "models": [
      {
        "model": "RIDER",
        "variants": [
          "R1 PRO",
          "R1 PRO 60V/30AH"
        ]
      },
      {
        "model": "STROM",
        "variants": [
          "60V 30AH",
          "60V 40AH"
        ]
      }
    ]
  },
  {
    "make": "RAYMOTOS",
    "models": [
      {
        "model": "RIDER R1",
        "variants": [
          "LITHIUM ION 60V / 24 AH"
        ]
      },
      {
        "model": "RAYO X1",
        "variants": [
          "LITHIUM ION  60V 24AH"
        ]
      },
      {
        "model": "STORM PRO",
        "variants": [
          "LITHIUM ION 60V 31AH"
        ]
      },
      {
        "model": "STORM PLUS",
        "variants": [
          "LITHIUM ION 60V 42AH"
        ]
      },
      {
        "model": "STORM",
        "variants": [
          "LITHIUM ION 60V 24AH"
        ]
      }
    ]
  },
  {
    "make": "RAYMOTOSS",
    "models": [
      {
        "model": "RAYO X1",
        "variants": [
          "60V 30AH"
        ]
      },
      {
        "model": "STORM",
        "variants": [
          "60V 30AH",
          "60V 40AH"
        ]
      },
      {
        "model": "RAYO",
        "variants": [
          "X1",
          "X1 PRO"
        ]
      },
      {
        "model": "RIDER",
        "variants": [
          "R1",
          "R1 PRO",
          "R1 PLUS"
        ]
      }
    ]
  },
  {
    "make": "RBSEVA",
    "models": [
      {
        "model": "LEGEND DLX",
        "variants": [
          "60V/24AH",
          "60V/30AH",
          "60V/36AH",
          "LITHIUM 60V 36AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 24AH"
        ]
      },
      {
        "model": "RIDER ECO",
        "variants": [
          "60V/24AH",
          "60V/30AH",
          "60V/36AH",
          "LITHIUM 60V 36AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 24AH"
        ]
      },
      {
        "model": "RIDER NEW",
        "variants": [
          "60V/24AH",
          "60V/30AH",
          "60V/36AH"
        ]
      },
      {
        "model": "RIDER SUPER",
        "variants": [
          "60V/24AH",
          "60V/30AH",
          "60V/36AH",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "ROYAL",
        "variants": [
          "60V/24AH",
          "60V/30AH",
          "60V/36AH",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 36AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "ROYAL DLX",
        "variants": [
          "60V/24AH",
          "60V/30AH",
          "60V/36AH",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "LEGEND SUPER",
        "variants": [
          "60V/24AH",
          "60V/30AH",
          "60V/36AH",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "LEGEND",
        "variants": [
          "STANDARD",
          "LITHIUM 60V 24AH"
        ]
      },
      {
        "model": "MARVEL",
        "variants": [
          "STANDARD",
          "LITHIUM 60V 18AH"
        ]
      },
      {
        "model": "RIDER",
        "variants": [
          "STANDARD",
          "LITHIUM 60V 24AH",
          "LITHIUM 60V 36AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "RIDER DLX",
        "variants": [
          "LITHIUM 60VÂ 24AH"
        ]
      }
    ]
  },
  {
    "make": "REGAL",
    "models": [
      {
        "model": "RAPTOR",
        "variants": [
          "DAYTONA 350",
          "CRUISER 350",
          "BOBBER 350",
          "DDE 9B"
        ]
      }
    ]
  },
  {
    "make": "REGAL RAPTOR",
    "models": [
      {
        "model": "DAYTONA",
        "variants": [
          "STD",
          "CUSTOMISED",
          "DOUBLE COLOR",
          "MAT FINISH",
          "350"
        ]
      },
      {
        "model": "BOBBER",
        "variants": [
          "CUSTOMISED",
          "DOUBLE COLOR",
          "MAT FINISH",
          "350"
        ]
      },
      {
        "model": "DDE 9B",
        "variants": [
          "350",
          "CUSTOMISED",
          "DOUBLE COLOR",
          "MAT FINISH"
        ]
      },
      {
        "model": "CRUISER",
        "variants": [
          "350"
        ]
      },
      {
        "model": "DAYTONA 350",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BOBBER 350",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DD 350",
        "variants": [
          "E 9B"
        ]
      },
      {
        "model": "CRUSIER 350",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "REMARK",
    "models": [
      {
        "model": "HIP HOP",
        "variants": [
          "60V ESCOOTER",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "HIP HOP PLUS",
        "variants": [
          "60V ESCOOTER"
        ]
      },
      {
        "model": "HIP HOP DLX",
        "variants": [
          "60V ESCOOTER",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "HI ENERGY",
        "variants": [
          "60V ESCOOTER",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "HI ENERGY PLUS",
        "variants": [
          "60V ESCOOTER"
        ]
      },
      {
        "model": "ATEN",
        "variants": [
          "60V ESCOOTER",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "ATEN PLUS",
        "variants": [
          "60V ESCOOTER",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "HI ENERGY LD",
        "variants": [
          "60V ESCOOTER",
          "LITHIUM BATTERY 60V 26AH"
        ]
      },
      {
        "model": "ELECTIVA",
        "variants": [
          "LITHIUM BATTERY 60V 34AH"
        ]
      }
    ]
  },
  {
    "make": "REMARK ELECTRIC",
    "models": [
      {
        "model": "HI ENERGY",
        "variants": [
          "LI 48V 26AH",
          "LI 60V 26AH",
          "PLUS LI 48V 26AH",
          "PLUS LI 60V 26AH",
          "LD LITHIUM",
          "LD LED"
        ]
      },
      {
        "model": "ATEN",
        "variants": [
          "LI 60V 26AH",
          "LI 72V 29AH",
          "PLUS LI 60V 26AH",
          "PLUS LI 72V 29AH"
        ]
      },
      {
        "model": "HIP HOP",
        "variants": [
          "LI 48V 26AH",
          "LI 60V 26AH",
          "PLUS LI 48V 26AH",
          "PLUS LI 60V 26AH",
          "DLX LI 48V 26AH",
          "DLX LI 60V 26AH",
          "DLX LEAD 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "REVAMP MOTO",
    "models": [
      {
        "model": "RM BUDDIE",
        "variants": [
          "25 STD LITHIUM 48V 25AH"
        ]
      },
      {
        "model": "RM BUDDIE 25",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "RM",
        "variants": [
          "BUDDIES 25(SINGLE SEAT)"
        ]
      }
    ]
  },
  {
    "make": "REVEAL ELECTRIC",
    "models": [
      {
        "model": "ELEKTRA",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "THRUST",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "VENOM",
        "variants": [
          "ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "REVOLT",
    "models": [
      {
        "model": "RV 300",
        "variants": [
          "STANDARD",
          "E BIKE"
        ]
      },
      {
        "model": "RV 400",
        "variants": [
          "STANDARD",
          "PREMIUM",
          "E BIKE",
          "STD"
        ]
      }
    ]
  },
  {
    "make": "REVOLT INTELLICORP",
    "models": [
      {
        "model": "RV 300",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "RV 400",
        "variants": [
          "E BIKE",
          "E BIKE LITHIUM ION 72 V 3.24 KWH  MID DRIVE MOTOR 3 KW"
        ]
      }
    ]
  },
  {
    "make": "REVOLT INTELLICORP PVT LTD",
    "models": [
      {
        "model": "RV 400",
        "variants": [
          "STD",
          "PREMIUM",
          "2"
        ]
      },
      {
        "model": "RV 300",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "RIAKA",
    "models": [
      {
        "model": "X1",
        "variants": [
          "60V28AH",
          "60V30AH",
          "72V30AH"
        ]
      },
      {
        "model": "X1 PLUS",
        "variants": [
          "60V28AH",
          "60V30AH",
          "72V30AH"
        ]
      },
      {
        "model": "DESTINY",
        "variants": [
          "60V28AH",
          "60V30AH",
          "72V30AH"
        ]
      },
      {
        "model": "XTREME",
        "variants": [
          "60V28AH",
          "60V30AH",
          "72V30AH"
        ]
      },
      {
        "model": "X2",
        "variants": [
          "60V28AH",
          "60V30AH",
          "72V30AH"
        ]
      }
    ]
  },
  {
    "make": "RILOX EV",
    "models": [
      {
        "model": "BIJLI",
        "variants": [
          "60V 34AH",
          "60V 39AH"
        ]
      }
    ]
  },
  {
    "make": "RILOX EV PVT LTD",
    "models": [
      {
        "model": "SPARK ELITE",
        "variants": [
          "LITHIUM 72V 24AH"
        ]
      }
    ]
  },
  {
    "make": "RITH AUTO",
    "models": [
      {
        "model": "EM 3",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "RIVER",
    "models": [
      {
        "model": "INDIE",
        "variants": [
          "STD",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "ROMAI",
    "models": [
      {
        "model": "FALCON",
        "variants": [
          "60 V",
          "LITHIUM 60V"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "MIU MIU",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "HAWK",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "CHAMPION",
        "variants": [
          "LITHIUM 60V 29AH"
        ]
      },
      {
        "model": "MINI CYAN",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "SHARK",
        "variants": [
          "60 V"
        ]
      }
    ]
  },
  {
    "make": "ROMAI ELECTRIC SCOOTER",
    "models": [
      {
        "model": "FALCON",
        "variants": [
          "60V E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "RONFLANT",
    "models": [
      {
        "model": "ZB NITRO",
        "variants": [
          "LEAD ACID 60V"
        ]
      }
    ]
  },
  {
    "make": "ROTARY ELECTRONICS",
    "models": [
      {
        "model": "DEER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "EL- DEER",
        "variants": [
          "600",
          "E-PED",
          "GLIDER"
        ]
      },
      {
        "model": "EL DEER 600",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "EL DEER",
        "variants": [
          "STANDARD MOPED",
          "E PED MOPED",
          "GLIDER MOPED"
        ]
      }
    ]
  },
  {
    "make": "ROWWET",
    "models": [
      {
        "model": "RAME",
        "variants": [
          "STD",
          "LI"
        ]
      },
      {
        "model": "ELEQ",
        "variants": [
          "STD",
          "LI MID SPEED",
          "LI HIGH SPEED",
          "CLICK BATTERY HS"
        ]
      },
      {
        "model": "VEGATRON",
        "variants": [
          "STD",
          "LI BATTERY HIGH SPEED",
          "CLICK BATTERY HIGH SPEED"
        ]
      },
      {
        "model": "ZEPOP",
        "variants": [
          "LEAD ACID",
          "LITHIUM ION",
          "LEAD ACID"
        ]
      },
      {
        "model": "TRONO",
        "variants": [
          "STD",
          "LI HIGH SPPED"
        ]
      }
    ]
  },
  {
    "make": "ROWWET MOBILITY",
    "models": [
      {
        "model": "ELEQ",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "VEGATRON",
        "variants": [
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "RAME",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "ZEPOP",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "TRONO",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "ROWWET MOBILITY PVT LTD",
    "models": [
      {
        "model": "RAME",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "ELEQ",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "ZEPOP",
        "variants": [
          "ELECTRIC",
          "LEAD ACID"
        ]
      },
      {
        "model": "VEGATRON",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "TRONO",
        "variants": [
          "ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "ROYAL",
    "models": [
      {
        "model": "RUBBIE",
        "variants": [
          "60V 28AH",
          "48V 30AH",
          "60V 30AH"
        ]
      },
      {
        "model": "IMPERIAL",
        "variants": [
          "60V 28AH",
          "48V 30AH",
          "60V 30AH"
        ]
      },
      {
        "model": "CHAMPION",
        "variants": [
          "60V 28AH",
          "48V 30AH",
          "60V 30AH"
        ]
      },
      {
        "model": "VIRAJ",
        "variants": [
          "60V 28AH",
          "48V 30AH",
          "60V 30AH"
        ]
      },
      {
        "model": "PRINCE",
        "variants": [
          "60V 30AH",
          "48V 36AH"
        ]
      },
      {
        "model": "WINNER",
        "variants": [
          "LEAD",
          "LITHIUM 48V 30AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "POWER",
        "variants": [
          "LEAD 60V 28AH",
          "LITHIUM 48V 30AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "RACING POWER",
        "variants": [
          "LITHIUM 60V 36AH"
        ]
      }
    ]
  },
  {
    "make": "ROYAL ENFIELD",
    "models": [
      {
        "model": "500",
        "variants": [
          "CC"
        ]
      },
      {
        "model": "CLASSIC 350",
        "variants": [
          "STANDARD",
          "STANDARD ABS",
          "BS VI EFI",
          "GUN METAL GREY BS VI",
          "REDDITCH EDITION",
          "CHROME AND STEALTH BS VI",
          "ORANGE EMBER & METALLO SILVER",
          "SANDSTONE AND AIRBORNE BS VI",
          "SINGLE CHANNEL ABS BS VI",
          "GUN METAL GREY BS VI ALLOY",
          "CHROME RED (CS)",
          "DARK STEALTH BLACK",
          "EFI SINGLE DISC",
          "SINGLE DISC",
          "EFI SINGLES",
          "EFI D. DISK",
          "CHESTNUT BS IV",
          "SIGNALS",
          "STD",
          "ABS - REDDITCH EDITION DD",
          "EFI",
          "HALCYON  SINGLE CHANNEL ABS",
          "HALCYON BLACK BS VI",
          "CHROME",
          "SIGNALS DESERT SAND BS VI",
          "ABS CHROME",
          "HALCYON GREEN BS VI",
          "MARSH GREY BS VI",
          "SIGNAL STORM RIDER SAND BS VI",
          "STELTH BLACK BSVI",
          "BLACK",
          "EFI CHROME",
          "EFI PURE BLACK",
          "MERCURY SILVER ALLOY",
          "GUNMETAL GREY DISC BRAKE BS VI",
          "EFI ORANGE EMBER",
          "REDDITCH SERIES",
          "HALCYON GREY BS VI",
          "EFI STELTH BLACK",
          "REDDITCH EDITION",
          "GUNMETAL GREY DISC BRAKE",
          "SINGALS EDITION ABS",
          "ABS DOUBLE DISC",
          "ELECTRIC START DISC BREAK",
          "UCE TWIN SPARK"
        ]
      },
      {
        "model": "BULLET 500",
        "variants": [
          "REAR DISC",
          "REAR DRUM"
        ]
      },
      {
        "model": "BULLET 350",
        "variants": [
          "STANDARD",
          "ELECTRA TWINSPARK",
          "ELECTRA TWINSPARK DOUBLE DISC",
          "ES X EFI BS VI",
          "KS X EFI BS VI",
          "STANDARD EFI BS VI",
          "ELECTRA 5S CAST IRON ENGINE",
          "TWINSPARK",
          "TWINSPARK DOUBLE DISC",
          "KS ABS NEW COLOUR EDITION",
          "ES ABS NEW COLOUR EDITION",
          "ELECTRA TWINSPARK DOUBLE DISC",
          "ES",
          "UCE BS IV"
        ]
      },
      {
        "model": "THUNDERBIRD 350",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MACHISMO",
        "variants": [
          "500",
          "STD",
          "VOM DISC BRAKE",
          "VOM DRUM BRAKE",
          "STD",
          "350",
          "A 350"
        ]
      },
      {
        "model": "THUNDERBIRD",
        "variants": [
          "TWINSPARK STANDARD",
          "TWINSPARK",
          "TWILIGHT",
          "KICK START",
          "TWILIGHT",
          "DISC BRAKE",
          "500",
          "KICK START",
          "TWINSPARK DISC BRAKE",
          "350X STD",
          "350 STD",
          "350X ABS",
          "350X BSVI",
          "STD",
          "SELF START"
        ]
      },
      {
        "model": "LIGHTING",
        "variants": [
          "535"
        ]
      },
      {
        "model": "DESERT",
        "variants": [
          "STORM"
        ]
      },
      {
        "model": "THUNDERBIRD 350X",
        "variants": [
          "ABS",
          "STANDARD"
        ]
      },
      {
        "model": "THUNDERBIRD 500",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "CLASSIC 500",
        "variants": [
          "DUAL DISC",
          "SINGLE DISC",
          "STD",
          "EFI",
          "SQARDON",
          "DESERT STROM",
          "ELECTRIC START DISC BREAK"
        ]
      },
      {
        "model": "CONTINENTAL GT",
        "variants": [
          "STANDARD",
          "ELEC START DISC BRAKE",
          "650 BSVI",
          "650 SPECIAL",
          "650 ROCKER RED BSVI",
          "650 BRITISH RACING GREEN BSVI",
          "650 VENTURA STORM BSVI",
          "650 DUX DELUX BSVI",
          "650 MR CLEAN BSVI",
          "SELF START DISC BRAKE",
          "650 BLACK MAGIC",
          "650 MISTER CLEAN",
          "650 INDIADR MAYHEM",
          "650 SLIPSTREAM BLUE",
          "650 APEX GREY",
          "650 GREEN",
          "650 DUX DELEUX",
          "650 MR CLEAN",
          "650 VENTURM STORM",
          "650 ROCKER RED",
          "ELECTRIC START DISC BREAK",
          "650 ICE QUEEN"
        ]
      },
      {
        "model": "CLASSIC CHROME",
        "variants": [
          "DUAL DISC",
          "ABS",
          "SINGLE DISC"
        ]
      },
      {
        "model": "STANDARD TRACTOR",
        "variants": [
          "STANDARD 500"
        ]
      },
      {
        "model": "HIMALAYAN",
        "variants": [
          "STANDARD BS VI FACELIFT",
          "FI ABS",
          "PINE GREEN AND GRANITE BLACK",
          "SLEET AND GRAVEL GREY BS VI",
          "FI",
          "SLEET ABS",
          "DUAL TONE BS VI FACELIFT",
          "BSVI",
          "BSVI SCRAM 411",
          "ROCK RED",
          "GRANITE BLACK BSVI",
          "GRAVEL GREY BSVI",
          "MIRAGE SILVER BSVI",
          "LAKE BLUE BSVI",
          "ROCK RED BSVI",
          "PINE GREEN BSVI",
          "ABS",
          "SLEET",
          "STD",
          "STANDARD BS6",
          "410",
          "GRAVEL GREY",
          "SCRAM"
        ]
      },
      {
        "model": "CONTINENTAL GT 650",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "INTERCEPTOR 650",
        "variants": [
          "STD",
          "CUSTOM SILVER SPECTRE",
          "CUSTOM RED",
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "CLASSIC 350 SIGNALS",
        "variants": [
          "ABS"
        ]
      },
      {
        "model": "CLASSIC GUNMETAL",
        "variants": [
          "GREY STANDARD"
        ]
      },
      {
        "model": "THUNDERBIRD 500X",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "CLASSIC DESERT STORM",
        "variants": [
          "DUAL DISC",
          "ABS",
          "SINGLE DISC"
        ]
      },
      {
        "model": "CLASSIC SQUADRON BLUE",
        "variants": [
          "DUAL DISC",
          "ABS",
          "SINGLE DISC"
        ]
      },
      {
        "model": "CLASSIC STEALTH BLACK",
        "variants": [
          "STANDARD",
          "ABS"
        ]
      },
      {
        "model": "CLASSIC GUNMETAL GREY",
        "variants": [
          "REDDITCH EDITION"
        ]
      },
      {
        "model": "METEOR 350",
        "variants": [
          "SUPERNOVA SILVER CS",
          "STD",
          "SUPERNOVA BSVI",
          "STELLAR BLACK",
          "FIREBALL RED",
          "STELLAR RED",
          "FIREBALL BLACK CS BSVI",
          "FIREBALL YELLOW BSVI",
          "C6 FIREBALL WHITE CS BSVI",
          "C18 STELLAR BLUE CS BSVI",
          "C2 FIREBALL WHITE CS BSVI",
          "STELLAR PURE BLACK C BSVI",
          "FIREBALL GREEN CS BSVI",
          "FIREBALL",
          "SUPERNOVA",
          "STELLAR"
        ]
      },
      {
        "model": "SCRAM",
        "variants": [
          "411"
        ]
      },
      {
        "model": "INTERCEPTOR",
        "variants": [
          "650 VENTUR",
          "650 SPECIAL",
          "650 MARK 2 BSVI",
          "650 BAKER EXPRESS BSVI",
          "650 DOWNTOWN DRAG BSVI",
          "650 SUNSET STRIP BSVI",
          "650 ORANGE CRUSH BSVI",
          "650 CANYON RED BSVI",
          "650 VENTURA BLUE BSVI",
          "650 BSVI",
          "650 CHROME",
          "650 CUSTOM",
          "650 STD",
          "STD",
          "650 DOWNTOWN DRAG",
          "650 STD BS6",
          "650 MARK 2",
          "CHROME",
          "CUSTOM",
          "650"
        ]
      },
      {
        "model": "HUNTER 350",
        "variants": [
          "STD",
          "FACTORY BSVI",
          "DAPPER BSVI",
          "REBEL BSVI",
          "STD",
          "METRO REBEL",
          "RETRO",
          "METRO DAPPER",
          "RETRO FACTORY",
          "STD",
          "METRO"
        ]
      },
      {
        "model": "BULLET",
        "variants": [
          "ELECTRA 4S",
          "ELECTRA 5S SELF START DISC BRAKE",
          "350",
          "STANDARD 500 UCE",
          "ELECTRA 5S SELF START",
          "TAURUS",
          "ELECTRA - UCE",
          "MACHISMO",
          "5S",
          "5S- KICK ST DRUM BRAKE",
          "DESERT STORM",
          "ELECTRIC START",
          "ELEC START DISC BRAKE",
          "STANDARD UCE",
          "METAL GREY STD",
          "STD",
          "350 TWINSPARK",
          "350 ABS",
          "350 TRAILS",
          "350 DISC ABS",
          "500 DISC ABS",
          "500 TRAILS",
          "350 ES EFI",
          "LIGHTNING 535 BSVI",
          "TAURUS BSVI",
          "ELECTRA UCE BSVI",
          "X 350 ES EFI JET BLACK BSVI",
          "X 350 ES EFI REGAL RED BSVI",
          "X 350 ES EFI ROYAL BLUE BSVI",
          "350 EFI BLACK BSVI",
          "350 EFI FOREST GREEN BSVI",
          "X 350 EFI BULLET SILVER BSVI",
          "X 350 EFI ONYX BLACK BSVI",
          "350 ABS",
          "350 ES ABS",
          "350 ES ELECTRA",
          "350 KS ABS",
          "500 ABS",
          "CLASSIC 350",
          "CLASSIC 500",
          "ELECTRA",
          "MACHISMO 500",
          "MACHISMO 500 TWIN SPARK",
          "TRIALS 350",
          "TRIALS 500",
          "X 350 ABS",
          "X 350 ES ABS",
          "STD",
          "STD - TWINSPARK UCE",
          "SELF START DISC BRAKE",
          "STD",
          "ELECTRA - TWIN SPARK",
          "LIGHTNING 535",
          "ELECTRA 5S",
          "STD S CLASS",
          "ELECTRA 5S DISC BRAKE",
          "X 350 KS",
          "X 350 ES",
          "350 EFI",
          "TRIALS 350 DUAL CHANNEL ABS",
          "TRIALS 500 DUAL CHANNEL ABS",
          "500",
          "500 UCE",
          "THUNDERBIRD 350 UCE BS III",
          "ELECTRA 5S KS",
          "350 UCE",
          "ELECTRA TWIN SPARK ES",
          "ELECTRA TWIN SPARK KS",
          "THUNDERBIRD TWIN SPARK",
          "ELECTRA 5S",
          "ELECTRA 4S",
          "MACHISMO 350",
          "THUNDERBIRD",
          "STANDARD",
          "350 UCE ABS",
          "MACHISMO A 500 5 SG DB",
          "500 ABS",
          "350 DISC",
          "STD(500)",
          "500 4SP ( DISC ) ES",
          "TRIALS 500",
          "TRIALS 350",
          "MINI BULLET 250",
          "MINI BULLET 200",
          "ELECTRA 4S DRUM",
          "350 ES ABS",
          "MACHISMO DISK",
          "ELECTRA DISK",
          "ELECTRA 5S ES DISC",
          "SELF START DISK",
          "ELECTRA -TWIN SPARK DISC BREAK ELEC START",
          "ELECTRA 5S KS DISC",
          "CLASSIC 500 ES DISC BREAK",
          "ELECTRA 5S ES DRUM",
          "ELECTRA 5S KS DRUM"
        ]
      },
      {
        "model": "ELECTRA",
        "variants": [
          "5S SIDE CAR",
          "DRUM BRAKE",
          "ELEC START DISC BRAKE",
          "UCE ELEC ST DISC BRAKE",
          "500 UCE",
          "UCE",
          "4S",
          "5S",
          "DISC BRAKE",
          "UCE",
          "UCE 350",
          "ELECTRIC START",
          "DISK KS",
          "5SP KS",
          "DISK",
          "UCE ELECTRIC START DISC BRK"
        ]
      },
      {
        "model": "ELEDRO",
        "variants": [
          "DISC BRAKE",
          "STD",
          "DISC"
        ]
      },
      {
        "model": "CLASSIC",
        "variants": [
          "500 SQUADRON BLUE",
          "500 DESERT STORM",
          "350 ELEC START DISC BRAKE",
          "500 ELEC START DISC BRAKE",
          "350 REDDITCH",
          "350 STD",
          "350 GUNMETAL GREY",
          "500 STD",
          "500 STEALTH BLACK",
          "500 CROME",
          "350 SIGNAL STORMRIDER SAND",
          "350 SIGNAL AIRBORNE BLUE",
          "350 ABS",
          "500 CROME ABS",
          "350 EFI",
          "350 EFI DUAL CHANNEL",
          "350 CHESTNUT BSVI",
          "350 SILVER BSVI",
          "350 BSVI",
          "350 HALCYON BSVI",
          "350 DARK GUNMETAL BSVI",
          "350 SIGNAL MARSH GREY BSVI",
          "350 CROME RED BSVI",
          "350 EFI SIGNALS BSVI",
          "350 DARK STEALTH BLACK BSVI",
          "DESERT STORM",
          "ELEC START DISC BRAKE",
          "ELEC START DISC BRAKE",
          "REDDITCH",
          "STD",
          "GUNMETAL GREY",
          "SQUADRON BLUE",
          "BATTLE GREEN",
          "STD",
          "STEALTH BLACK",
          "CROME",
          "350 EFI SILVER",
          "350 ABS",
          "350 BSVI",
          "350 REDDITCH",
          "500 ABS",
          "500 DESERT STROM",
          "500 ELECTRIC DISC BRAKE",
          "ABS SIGNALS EDITION",
          "CHROME",
          "GUNMETAL GREY",
          "STEALTH BLACK 500",
          "350 SELF START DISC BRAKE",
          "350 UCE TWINSPARK",
          "500 BATTLE GREEN",
          "500 CHROME",
          "500 DESERT STORM",
          "500 DESPATCH LIMITED EDITION",
          "500 SELF START DISC BRAKE",
          "500 UCE TWINSPARK",
          "GUNMETAL GREY ABS",
          "350",
          "350",
          "500 CHROME REAR DISC",
          "500 DESERT STROM REAR DISC",
          "500 REAR DISC",
          "500 SQUADRON BLUE REAR DISC",
          "350 SQUADRON BLUE",
          "STEALTH BLACK 500 ABS",
          "DESERT STROM ABS",
          "350 HALCYON DUAL CHANNEL ABC BSVI",
          "350 REDDITCH SINGLE CHANNEL ABS BSVI",
          "350 SIGNALS DUAL CHANNEL ABS BSVI",
          "350 DARK DUAL CHANNEL ABS BSVI",
          "350 CHROME DUAL CHANNEL ABS BSVI",
          "500 DESERT STROM DESPATCH",
          "500",
          "DESERT STROM",
          "BATTEL GREEN",
          "350 ELECTRIC DISC BRAKE"
        ]
      },
      {
        "model": "TAURUS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SOLO WITH PILLION",
        "variants": [
          "VINTAGE BSVI"
        ]
      },
      {
        "model": "SCRAM 411",
        "variants": [
          "GRAPHITE BLUE BSVI",
          "GRAPHITE BSVI",
          "BLAZING BSVI",
          "SKYLINE BSVI",
          "WHITE FLAME BSVI",
          "SILVER SPIRIT BSVI",
          "WHITE FLAME",
          "GRAPHITE BS6",
          "BLAZING BLACK AND SKYLINE BLUE",
          "WHITE FLAME AND SILVER SPIRIT",
          "SILVER SPIRIT",
          "GRAPHITE SERIES",
          "SKYLINE BLUE",
          "BLAZING BLACK"
        ]
      },
      {
        "model": "SUPER METEOR 650",
        "variants": [
          "CELESTIAL BSVI",
          "ASTRAL BSVI",
          "INTERSTELLAR BSVI",
          "ASTRAL",
          "INTERSTELLAR",
          "CELESTIAL",
          "648 CC"
        ]
      },
      {
        "model": "BULLET THUNDERBIRD BSII",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BULLET  MACHISMO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BULLET 500 CC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BULLET ELECTRA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BULLET STANDARD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MACHISMO 500 DISC ES",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "R ENFIELD HIMALAYAN",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "LIGHTNING  535",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CAFE RACER",
        "variants": [
          "500",
          "STANDARD"
        ]
      },
      {
        "model": "CONTINENTAL",
        "variants": [
          "GT 650 STD",
          "GT 650 CUSTOM",
          "GT 650 CHROME",
          "GT 650 STD BS6",
          "GT"
        ]
      },
      {
        "model": "LIGHTNING",
        "variants": [
          "535 CC"
        ]
      },
      {
        "model": "THUNDER BIRD",
        "variants": [
          "350 ABS",
          "350 X",
          "500 X ABS",
          "TWIN SPARK",
          "500 ABS",
          "350 ABS",
          "500 X",
          "350 CC",
          "500 CC",
          "KICK START",
          "X 500",
          "350 ABS FLICKER",
          "CLASSIC 350 ABS",
          "350 ES",
          "350 EFI",
          "X 350",
          "X 350 ABS",
          "TWIN SPARK ES DISC BRAKE",
          "350",
          "KICK START DISC BRAKE"
        ]
      },
      {
        "model": "METEOR",
        "variants": [
          "STD",
          "350 FIREBALL",
          "350 STELLAR",
          "350 SUPERNOVA",
          "350 BSVI"
        ]
      },
      {
        "model": "DESPATCH ED",
        "variants": [
          "RIDER 500"
        ]
      },
      {
        "model": "THUNDER BIRD 500",
        "variants": [
          "X 500",
          "STD",
          "X 500 ABS",
          "TWIN SPARK ES DISCK BREAK"
        ]
      },
      {
        "model": "EXPLORER",
        "variants": [
          "MOTORCYCLE"
        ]
      },
      {
        "model": "SILVER PLUS",
        "variants": [
          "MOPED"
        ]
      }
    ]
  },
  {
    "make": "RUGGED",
    "models": [
      {
        "model": "G1",
        "variants": [
          "PLUS",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "RYOTO ELECTRIX PVT LTD",
    "models": [
      {
        "model": "NEUTRON",
        "variants": [
          "LEAD ACID"
        ]
      },
      {
        "model": "ATOM",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "VIBE",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "E PORTER",
        "variants": [
          "LION 60V 30AH"
        ]
      },
      {
        "model": "STROM",
        "variants": [
          "LITHIUM"
        ]
      },
      {
        "model": "ELEKTRO",
        "variants": [
          "LEAD ACID"
        ]
      }
    ]
  },
  {
    "make": "S ONE ELECTRIX",
    "models": [
      {
        "model": "WIND PRIME",
        "variants": [
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 31AH",
          "LITHIUM ION 60V 35AH",
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "WIND DISC",
        "variants": [
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 31AH",
          "LITHIUM ION 60V 35AH",
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "VICTORY",
        "variants": [
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 31AH",
          "LITHIUM ION 60V 35AH",
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "WIND PLUS",
        "variants": [
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 31AH",
          "LITHIUM ION 60V 35AH",
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "WIND ELITE",
        "variants": [
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 31AH",
          "LITHIUM ION 60V 35AH",
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID BATTERY 60V 30AH"
        ]
      },
      {
        "model": "BOLT DISC",
        "variants": [
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 31AH",
          "LITHIUM ION 60V 35AH",
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "PACE",
        "variants": [
          "LITHIUM ION 48V 25AH",
          "LITHIUM ION 60V 25AH",
          "LITHIUM ION 60V 31AH",
          "LITHIUM ION 60V 35AH",
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "WIND PRO",
        "variants": [
          "LITHIUM ION 60V 35AH",
          "LITHIUM ION 72V 34AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 72V 28AH"
        ]
      },
      {
        "model": "PACE PRO",
        "variants": [
          "LITHIUM ION 60V 35AH",
          "LITHIUM ION 72V 34AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 72V 28AH"
        ]
      },
      {
        "model": "APPU",
        "variants": [
          "LITHIUM ION 60V 35AH",
          "LITHIUM ION 72V 34AH",
          "LEAD ACID 60V 28AH",
          "LEAD ACID 72V 28AH"
        ]
      }
    ]
  },
  {
    "make": "SAERA ELECTRIC AUTO",
    "models": [
      {
        "model": "YOGO SMART",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YOGO TRENDY",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "SAHARA EVOLS",
    "models": [
      {
        "model": "FALCON",
        "variants": [
          "LI"
        ]
      },
      {
        "model": "VERO SMART",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "VERO DELTA",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "FALCON LI 28.8",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "COSBIKE ELECTRA",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "FUSION",
        "variants": [
          "V2",
          "DX",
          "AGILE",
          "LITHIUM ION (60V 24AH)"
        ]
      },
      {
        "model": "KINETIC VARCAS",
        "variants": [
          "ZING"
        ]
      },
      {
        "model": "OKINAWA",
        "variants": [
          "R35"
        ]
      },
      {
        "model": "VARCAS FALCON",
        "variants": [
          "LI"
        ]
      },
      {
        "model": "COSBIKE FUSION",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "GOWEL",
        "variants": [
          "ZX LI"
        ]
      },
      {
        "model": "VICTORY VEERO",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "VICTORY SMART",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "VICTORY SPORT",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "BATTRE LOVE",
        "variants": [
          "60V 28AH"
        ]
      },
      {
        "model": "VICTORY VENTURA",
        "variants": [
          "60V 28AH"
        ]
      },
      {
        "model": "SUPER ECO",
        "variants": [
          "60V 28AH",
          "T1 LITHIUM BATTERY 60V 32AH",
          "T4 LITHIUM BATTERY 60V 32AH",
          "T3 LITHIUM BATTERY 60V 32AH"
        ]
      },
      {
        "model": "ELECTRA",
        "variants": [
          "60V 24AH",
          "LITHIUM ION (60V 24AH)"
        ]
      },
      {
        "model": "HIP HOP DLX",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "APONYX X2",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "COSBIKE AGILE",
        "variants": [
          "60V-30AH",
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "JMT",
        "variants": [
          "1000"
        ]
      },
      {
        "model": "VEERO SPORTS",
        "variants": [
          "60V 25 AH LITHIUM-ION",
          "LITHIUM ION 48V 25AH"
        ]
      },
      {
        "model": "VEERO",
        "variants": [
          "48V 25 AH LITHIUM-ION",
          "SMART LITHIUM ION 650 WATT 60 V 25 AH",
          "SMART LITHIUM ION 650 WATT 48 V 25 AH"
        ]
      },
      {
        "model": "R 35",
        "variants": [
          "LITHIUM ION (48V 28AH)"
        ]
      },
      {
        "model": "VENTURA",
        "variants": [
          "48V 25 AH LITHIUM-ION"
        ]
      },
      {
        "model": "YAKUZA ERICKA",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "YAKUZA AJ STAR",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "ZING",
        "variants": [
          "LITHIUM ION 60V 28.8AH",
          "LITHIUM ION 60V 21.6AH"
        ]
      },
      {
        "model": "Z PLUS",
        "variants": [
          "LITHIUM ION 48V 18AH"
        ]
      }
    ]
  },
  {
    "make": "SAIESHA E VEHICLE PVT LTD",
    "models": [
      {
        "model": "SG PLUS",
        "variants": [
          "LEAD ACID 60V 25AH"
        ]
      },
      {
        "model": "SHALDON",
        "variants": [
          "LEAD ACID 48V"
        ]
      },
      {
        "model": "SIDE DRL",
        "variants": [
          "LEAD ACID 48V"
        ]
      },
      {
        "model": "SQUARE LIGHT",
        "variants": [
          "LEAD ACID 48V"
        ]
      }
    ]
  },
  {
    "make": "SAKSHAM",
    "models": [
      {
        "model": "ROCK FREYA",
        "variants": [
          "LEAD ACID BATTERY 60V 27 AH",
          "LEAD ACID BATTERY 48V 29 AH",
          "LEAD ACID BATTERY 48V 28 AH",
          "LEAD ACID BATTERY 48V 27 AH",
          "LEAD ACID BATTERY 48V 25 AH",
          "LEAD ACID BATTERY 48V 23 AH",
          "LITHIUM BATTERY 60V 25AH",
          "LITHIUM BATTERY 60V 30 AH",
          "LITHIUM BATTERY 48V 36 AH",
          "LITHIUM BATTERY 48V 30 AH",
          "LITHIUM BATTERY 48V 25 AH",
          "LEAD ACID BATTERY 60V 29 AH",
          "LEAD ACID BATTERY 60V 28 AH",
          "LEAD ACID BATTERY 60V 25 AH",
          "LEAD ACID BATTERY 60V 23 AH"
        ]
      },
      {
        "model": "ROCK FREYA S",
        "variants": [
          "LITHIUM BATTERY 72V 45 AH",
          "LITHIUM BATTERY 72V 30 AH",
          "LITHIUM BATTERY 60V 30 AH",
          "LITHIUM BATTERY 60V 25 AH",
          "LEAD ACID BATTERY 60V 29 AH",
          "LEAD ACID BATTERY 60V 28 AH",
          "LEAD ACID BATTERY 60V 27 AH",
          "LEAD ACID BATTERY 60V 25 AH",
          "LEAD ACID BATTERY 60V 23 AH"
        ]
      },
      {
        "model": "RAMBO ZOE",
        "variants": [
          "LEAD ACID BATTERY 48V",
          "LEAD ACID BATTERY 60V",
          "LITHIUM BATTERY 48V 25 AH",
          "LITHIUM BATTERY 48V 30 AH",
          "LITHIUM BATTERY 48V 36 AH",
          "LITHIUM BATTERY 60V 25 AH",
          "LITHIUM BATTERY 60V 30 AH"
        ]
      }
    ]
  },
  {
    "make": "SANVISION VENTURES",
    "models": [
      {
        "model": "SV ELITE",
        "variants": [
          "LITHIUM ION 48V 28AH",
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "SV ELEKTRA",
        "variants": [
          "LITHIUM ION 48V 28AH",
          "LITHIUM ION 60V 30AH"
        ]
      },
      {
        "model": "SV MAGESTIC",
        "variants": [
          "LITHIUM ION 48V 28AH",
          "LITHIUM ION 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "SAWHNEY IMPEX",
    "models": [
      {
        "model": "ETORQ DLX",
        "variants": [
          "LA 48V 28AH 250WATT",
          "LI 60V 24AH 250WATT",
          "LA 60V 28AH 250WATT"
        ]
      },
      {
        "model": "E ROYAL",
        "variants": [
          "LI 72V 30AH 250WATT",
          "LI 60V 24AH 250WATT",
          "LA 72V 28AH 250WATT",
          "LA 60V 28AH 250WATT"
        ]
      },
      {
        "model": "THUNDER",
        "variants": [
          "LA 60V 28AH 250WATT",
          "LA 72V 28AH 250WATT",
          "LI 60V 24AH 250WATT",
          "LI 72V 30AH 250WATT"
        ]
      },
      {
        "model": "CLASSIC",
        "variants": [
          "LA 48V 28AH 250WATT",
          "LA 60V 28AH 250WATT",
          "LI 60V 24AH 250WATT"
        ]
      },
      {
        "model": "E ROY",
        "variants": [
          "LI 60V 24AH 250WATT",
          "LA 60V 28AH 250WATT",
          "LA 48V 28AH 250WATT"
        ]
      },
      {
        "model": "ETORQ",
        "variants": [
          "LI 60V 24AH 250WATT"
        ]
      },
      {
        "model": "E ROY X2",
        "variants": [
          "LA 48V 28AH 250WATT",
          "LI 60V 24AH 250WATT",
          "LA 60V 28AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "SBTEK E MOTO PVT LTD",
    "models": [
      {
        "model": "C1 CITRON",
        "variants": [
          "E BIKE",
          "WITH BATTERY 72V 30AH"
        ]
      },
      {
        "model": "T3 TIGON",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "SBTEK E-MOTO PVT LTD",
    "models": [
      {
        "model": "CITRON",
        "variants": [
          "LITHIUM ION 72V / 30 AH"
        ]
      }
    ]
  },
  {
    "make": "SCOOTER INDIA",
    "models": [
      {
        "model": "VIJAY SUPER",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "SCOOTERS INDIA",
    "models": [
      {
        "model": "VIJAY SUPER",
        "variants": [
          "SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "SCOOTERS INDIA LTD",
    "models": [
      {
        "model": "VIJAY SUPER",
        "variants": [
          "150 CC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "SDEM MOTOCORP",
    "models": [
      {
        "model": "VACHEE",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "VACHEE 2",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "TEJAS",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "AMOGH",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LEAD ACID 48V 28AH",
          "CUBE LITHIUM 72V 40AH",
          "LITHIUM 72V 35AH",
          "DUAL LITHIUM 60V 25AH",
          "SIXTY LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "AMOGH 2",
        "variants": [
          "LITHIUM 72V 30AH"
        ]
      }
    ]
  },
  {
    "make": "SEEKA",
    "models": [
      {
        "model": "VATSAL 250",
        "variants": [
          "LED ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "SM 911",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "SMAK 250",
        "variants": [
          "LITHIUM BATTERY",
          "LED ACID BATTERY"
        ]
      },
      {
        "model": "TY",
        "variants": [
          "LITHIUM BATTERY",
          "LED ACID BATTERY"
        ]
      },
      {
        "model": "JY 9",
        "variants": [
          "LED ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "LTW",
        "variants": [
          "LED ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "SFLASH 250",
        "variants": [
          "LED ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "SMAK",
        "variants": [
          "ZL3D (TH2000-2)",
          "ZL3D (TH2000-2) 1.8",
          "ZL3D (TH2000-2) 1.3"
        ]
      },
      {
        "model": "S BOLT",
        "variants": [
          "E BIKE 72V 43AH 6.1KW"
        ]
      }
    ]
  },
  {
    "make": "SEEKA E MOTORS",
    "models": [
      {
        "model": "SM 250 R",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "VATSAL 250",
        "variants": [
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "SEEKA E MOTORS PVT LTD",
    "models": [
      {
        "model": "SMAK",
        "variants": [
          "ZL3D"
        ]
      }
    ]
  },
  {
    "make": "SEMSY",
    "models": [
      {
        "model": "KASA",
        "variants": [
          "60V30AH",
          "48V30AH",
          "48V 24AH",
          "SMART 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "SENZO",
    "models": [
      {
        "model": "SENZO",
        "variants": [
          "LEAD ACID 72V",
          "LITHIUM ION 60V"
        ]
      }
    ]
  },
  {
    "make": "SERA ELECTRIC AUTO PVT LTD",
    "models": [
      {
        "model": "YOGO",
        "variants": [
          "SMART",
          "TRENY"
        ]
      }
    ]
  },
  {
    "make": "SES",
    "models": [
      {
        "model": "EAGLE",
        "variants": [
          "48 VOLTS",
          "60V"
        ]
      },
      {
        "model": "BOLD",
        "variants": [
          "60 VOLTS"
        ]
      },
      {
        "model": "ZOOM",
        "variants": [
          "48V LITHIUM"
        ]
      },
      {
        "model": "FLY",
        "variants": [
          "48V LITHIUM"
        ]
      },
      {
        "model": "TUFF",
        "variants": [
          "48V LITHIUM"
        ]
      }
    ]
  },
  {
    "make": "SES PVT LTD",
    "models": [
      {
        "model": "FLY",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "STD",
          "LI 48V 25AH",
          "LI 51V 30AH",
          "160"
        ]
      },
      {
        "model": "TUFF",
        "variants": [
          "STD",
          "LI 48V 25AH"
        ]
      },
      {
        "model": "ZOOM",
        "variants": [
          "LI 48V 25AH"
        ]
      },
      {
        "model": "BOLD",
        "variants": [
          "LI 48V 25AH"
        ]
      }
    ]
  },
  {
    "make": "SG EV",
    "models": [
      {
        "model": "V PRO",
        "variants": [
          "LITHIUM BATTERY 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "SGL LINE MOTORS PVT LTD",
    "models": [
      {
        "model": "HIRADO G1",
        "variants": [
          "E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "SHEMA",
    "models": [
      {
        "model": "FLY",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "BOLD",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "48 V, 25 AH",
          "48 V, 35 AH",
          "51.2 V, 31.2 AH"
        ]
      },
      {
        "model": "HOBBY",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TUFF",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "ZOOM",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "SHEMA E VEHICLE",
    "models": [
      {
        "model": "EAGLE",
        "variants": [
          "EBIKE",
          "WOB 250WATT"
        ]
      },
      {
        "model": "FLY",
        "variants": [
          "EBIKE"
        ]
      },
      {
        "model": "HOBBY",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "TUFF",
        "variants": [
          "LITHIUM ION BATTERY 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "SHIVAJI E I COMPANY",
    "models": [
      {
        "model": "OKOYAMA TRUSS 2X",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "OKOYAMA SPORT",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "OKOYAMA VESPA ROUND",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "OKOYAMA TIGGER 3X",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      }
    ]
  },
  {
    "make": "SHIVANSHU ELECTRIC",
    "models": [
      {
        "model": "V5",
        "variants": [
          "LI 60V 30AH 250WATT",
          "LIFEPO4 60V 36AH 250WATT",
          "LIFEPO4 60V 30AH 250WATT",
          "LIFEPO4 60V 24AH 250WATT",
          "LI 60V 35AH 250WATT",
          "LI 60V 25AH 250WATT",
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "V7",
        "variants": [
          "LIFEPO4 60V 30AH 250WATT",
          "LIFEPO4 60V 24AH 250WATT",
          "LI 60V 35AH 250WATT",
          "LI 60V 30AH 250WATT",
          "LI 60V 25AH 250WATT",
          "LIFEPO4 60V 36AH 250WATT",
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "LIFEPO4 60V 36AH 250WATT",
          "LIFEPO4 60V 30AH 250WATT",
          "LIFEPO4 60V 24AH 250WATT",
          "LI 60V 35AH 250WATT",
          "LI 60V 30AH 250WATT",
          "LI 60V 25AH 250WATT",
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "UGO",
        "variants": [
          "LIFEPO4 60V 36AH 250WATT",
          "LIFEPO4 60V 24AH 250WATT",
          "LIFEPO4 60V 30AH 250WATT",
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "TIGER",
        "variants": [
          "LITHIUM BATTERY  60 V 28 AH"
        ]
      },
      {
        "model": "SPIN",
        "variants": [
          "LITHIUM BATTERY 60V 24AH"
        ]
      },
      {
        "model": "VIKTOR 8",
        "variants": [
          "LITHIUM BATTERY 72V 24AH"
        ]
      }
    ]
  },
  {
    "make": "SHIVI ELECTRIC",
    "models": [
      {
        "model": "HARRY 48 V",
        "variants": [
          "BIKE"
        ]
      }
    ]
  },
  {
    "make": "SHRI RAM AUTOTECH PVT. LTD",
    "models": [
      {
        "model": "SLEEK",
        "variants": [
          "SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "SILVELINE",
    "models": [
      {
        "model": "RIDER PLUS",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "RIDER",
        "variants": [
          "LITHIUM 48V 24AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "YUVI PLUS",
        "variants": [
          "LITHIUM 72V 48AH"
        ]
      },
      {
        "model": "CUTE PLUS",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "WARRIOR NX",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "GUDDU NX",
        "variants": [
          "LITHIUM 48V 24AH"
        ]
      },
      {
        "model": "LORDS ZOOM LT",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "SOLIDER",
        "variants": [
          "LITHIUM 72V 48AH",
          "LITHIUM 60V 60AH"
        ]
      },
      {
        "model": "DHANNO PLUS",
        "variants": [
          "LITHIUM 72V 40AH"
        ]
      },
      {
        "model": "WINDY",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "RAYS",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "SILVELINE POWER STATION PRIVATE LIMITED",
    "models": [
      {
        "model": "DHANNO PLUS",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "RAYS PLUS",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "VAYU",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "RIDER",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "RIDER PLUS",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "YUVI PLUS",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "LORDS ZOOM LT",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "CUTE PLUS",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "GUDDU NX",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "SOLIDER",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "WARRIOR NX",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "SIMITAL WHEELS PVT LTD",
    "models": [
      {
        "model": "ACT PRO",
        "variants": [
          "48V25AH LI",
          "48V30AH LI",
          "60V30AH LI"
        ]
      },
      {
        "model": "MES PRO",
        "variants": [
          "48V25AH LI",
          "48V30AH LI",
          "60V30AH LI"
        ]
      },
      {
        "model": "MES PRO PLUS",
        "variants": [
          "48V25AH LI",
          "48V30AH LI",
          "60V30AH LI"
        ]
      },
      {
        "model": "VES PRO",
        "variants": [
          "48V25AH LI"
        ]
      }
    ]
  },
  {
    "make": "SIMPLE ENERGY",
    "models": [
      {
        "model": "SIMPLE ONE",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "ONE",
        "variants": [
          "STANDARD",
          "EXTRA RANGE",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "SINGLA MOTOR",
    "models": [
      {
        "model": "CHALLENGER PRO",
        "variants": [
          "LEAD ACID 48V 27AH"
        ]
      },
      {
        "model": "CHALLENGER SHARK",
        "variants": [
          "LEAD ACID 60V 27AH"
        ]
      },
      {
        "model": "CHALLENGER URBAN",
        "variants": [
          "LEAD ACID 60V 27AH"
        ]
      }
    ]
  },
  {
    "make": "SINIC",
    "models": [
      {
        "model": "3000",
        "variants": [
          "STD",
          "E SCOOTER"
        ]
      },
      {
        "model": "4000",
        "variants": [
          "STD",
          "E SCOOTER"
        ]
      },
      {
        "model": "5000",
        "variants": [
          "STD",
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "SIYARA",
    "models": [
      {
        "model": "GEL BATTERY 48V",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "LITHIUM BATTERY 48V",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "GT",
        "variants": [
          "48V 24AH",
          "48V 30AH"
        ]
      },
      {
        "model": "PRO",
        "variants": [
          "48V 24AH",
          "48V 30AH"
        ]
      },
      {
        "model": "POWER",
        "variants": [
          "60V 24AH",
          "60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "SMAYRA MOTORS",
    "models": [
      {
        "model": "OCTIVA",
        "variants": [
          "E",
          "POWER",
          "PLUS"
        ]
      }
    ]
  },
  {
    "make": "SMAYRA MOTORS EV GROUPS LTD",
    "models": [
      {
        "model": "OCVIVA",
        "variants": [
          "PLUS",
          "POWER",
          "PRO"
        ]
      }
    ]
  },
  {
    "make": "SMP VESPA",
    "models": [
      {
        "model": "SMP RISE",
        "variants": [
          "SQUARE LIGHT LITHIUM 48V/30AH"
        ]
      }
    ]
  },
  {
    "make": "SOKUDO",
    "models": [
      {
        "model": "ACUTE",
        "variants": [
          "LFP 73.6V 30AH"
        ]
      },
      {
        "model": "ACUTE PLUS",
        "variants": [
          "73.6V 42AH LFP"
        ]
      }
    ]
  },
  {
    "make": "SOORAJ",
    "models": [
      {
        "model": "DIESEL",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "SPECTO E BIKE",
    "models": [
      {
        "model": "RASE XI",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "VINTAGE",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "RETRO PLUS",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "EGLE",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "SPEEGO",
    "models": [
      {
        "model": "XPUMA",
        "variants": [
          "E BIKE",
          "E BIKE"
        ]
      },
      {
        "model": "APPEBETOR",
        "variants": [
          "2.0 BIKE",
          "SPORTS E BIKE"
        ]
      },
      {
        "model": "E BUZZ",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "SPEEGO VEHICLES COMPANY LTD",
    "models": [
      {
        "model": "XPUMA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "E-BUZZ",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "APPEBETOR",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "SRIVARU MOTORS",
    "models": [
      {
        "model": "PRANA",
        "variants": [
          "GRAND",
          "ELITE"
        ]
      }
    ]
  },
  {
    "make": "STELLA",
    "models": [
      {
        "model": "SR 2000",
        "variants": [
          "60V 30AH"
        ]
      },
      {
        "model": "SA 3000",
        "variants": [
          "ELECTRIC",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "SA 2000",
        "variants": [
          "ELECTRIC",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "SA 1000",
        "variants": [
          "LITHIUM 60V 30AH",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "BUZZ",
        "variants": [
          "LITHIUM 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "STELLA AUTOMOBILI",
    "models": [
      {
        "model": "SA 1000",
        "variants": [
          "STANDARD",
          "LI"
        ]
      },
      {
        "model": "SA 2000",
        "variants": [
          "STANDARD",
          "LI"
        ]
      },
      {
        "model": "SA 3000",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SA",
        "variants": [
          "1000",
          "2000",
          "1000 STANDARD",
          "1000 LI",
          "2000 STANDARD",
          "2000 LI"
        ]
      }
    ]
  },
  {
    "make": "STORM ELECTRIC",
    "models": [
      {
        "model": "ARCHER",
        "variants": [
          "LEAD ACID 48V 24AH"
        ]
      }
    ]
  },
  {
    "make": "STRIKECO",
    "models": [
      {
        "model": "SPRINT E",
        "variants": [
          "60V/28AH",
          "60V/29AH",
          "60V/36AH",
          "60V",
          "60V-30AH",
          "STANDARD"
        ]
      },
      {
        "model": "VOLT",
        "variants": [
          "60V/28AH",
          "60V/29AH",
          "60V/36AH",
          "60V-30AH"
        ]
      },
      {
        "model": "VERV",
        "variants": [
          "60V/28AH",
          "60V/29AH",
          "60V/36AH",
          "72V",
          "60V-30AH"
        ]
      },
      {
        "model": "SPRINTC",
        "variants": [
          "60V"
        ]
      },
      {
        "model": "CRESTA",
        "variants": [
          "60V-28AH",
          "72V-28AH",
          "60V-30AH",
          "60V-36AH",
          "SCOOTER ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "SUNDAK EV",
    "models": [
      {
        "model": "MARS",
        "variants": [
          "LITHIUM ION 60V"
        ]
      }
    ]
  },
  {
    "make": "SUNDAK SOLAR SOLUTIONS",
    "models": [
      {
        "model": "MARS",
        "variants": [
          "LITHIUM BATTERY 48V 25AH",
          "LITHIUM BATTERY 48V 20AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LEAD ACID BATTERY 48V 28AH",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM ION 60V / 30 AH",
          "LITHIUM ION 60V / 20 AH",
          "LITHIUM ION 60V / 25 AH",
          "LEAD ACID 60V / 25 AH",
          "E SCOOTER"
        ]
      },
      {
        "model": "KEAGLE",
        "variants": [
          "LEAD ACID BATTERY 48V 28AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LITHIUM BATTERY 48V 20AH",
          "LITHIUM BATTERY 48V 25AH",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM ION 60V / 30 AH",
          "LITHIUM ION 60V / 25 AH",
          "LITHIUM ION 60V / 20 AH",
          "LEAD ACID 60V / 25 AH"
        ]
      },
      {
        "model": "YOUNGER",
        "variants": [
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 48V 25AH",
          "LITHIUM BATTERY 48V 20AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LEAD ACID BATTERY 48V 28AH",
          "LEAD ACID 60V / 25 AH",
          "LITHIUM ION 60V / 20 AH",
          "LITHIUM ION 60V / 25 AH",
          "LITHIUM ION 60V / 30 AH",
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "SUNDAK SOLAR SOLUTIONS PVT LTD",
    "models": [
      {
        "model": "MARS",
        "variants": [
          "60V/30AH",
          "48V/35AH",
          "48V/20AH",
          "48V/28AH",
          "60V/28AH",
          "DRUM BRAKE"
        ]
      },
      {
        "model": "KEAGLE",
        "variants": [
          "48V/28AH",
          "60V/28AH",
          "48V/30AH",
          "48V/35AH",
          "60V/30AH",
          "DRUM BRAKE"
        ]
      },
      {
        "model": "YOUNGER",
        "variants": [
          "48V/28AH",
          "60V/28AH",
          "48V/20AH",
          "48V/30AH",
          "48V/35AH",
          "60V/30AH",
          "60V/25AH",
          "DRUM BRAKE"
        ]
      },
      {
        "model": "VESPA",
        "variants": [
          "LEAD ACID",
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "SUNLUX",
    "models": [
      {
        "model": "EXITE 60/28",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "EXITE",
        "variants": [
          "LITHIUM 20V 25AH"
        ]
      },
      {
        "model": "PRIME",
        "variants": [
          "LITHIUM 48V 25AH"
        ]
      }
    ]
  },
  {
    "make": "SUPER ECO",
    "models": [
      {
        "model": "T1",
        "variants": [
          "STANDARD",
          "E BIKE"
        ]
      },
      {
        "model": "SE 2",
        "variants": [
          "STANDARD",
          "E BIKE",
          "STANDARD"
        ]
      },
      {
        "model": "S 2",
        "variants": [
          "STANDARD",
          "E BIKE",
          "STANDARD"
        ]
      },
      {
        "model": "X1",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "S25",
        "variants": [
          "LED",
          "LITHIUM"
        ]
      },
      {
        "model": "SE T4",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "SUPER ECO AUTOMOTIVE",
    "models": [
      {
        "model": "S2",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "T1",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "T2",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "SE2",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "TI FX",
        "variants": [
          "LI 60V 31AH 250W"
        ]
      }
    ]
  },
  {
    "make": "SUPER ECO AUTOMOTIVE CO",
    "models": [
      {
        "model": "T1",
        "variants": [
          "STANDARD",
          "LEAD ACID 28AH",
          "LITHIM ION 60V 31AH",
          "LITHIM ION 60V 28.6AH",
          "LITHIM ION 60V 34AH"
        ]
      },
      {
        "model": "S2 HS",
        "variants": [
          "LEAD ACID 28AH",
          "LITHIM ION 60V 31AH",
          "LITHIM ION 60V 28.6AH",
          "LITHIM ION 60V 34AH"
        ]
      },
      {
        "model": "S25",
        "variants": [
          "LEAD ACID 28AH",
          "LITHIM ION 60V 31AH",
          "LITHIM ION 60V 28.6AH",
          "LITHIM ION 60V 34AH"
        ]
      },
      {
        "model": "T1 FX",
        "variants": [
          "LEAD ACID 28AH",
          "LITHIM ION 60V 31AH",
          "LITHIM ION 60V 28.6AH",
          "LITHIM ION 60V 34AH"
        ]
      },
      {
        "model": "T4",
        "variants": [
          "LEAD ACID 28AH",
          "LITHIM ION 60V 31AH",
          "LITHIM ION 60V 28.6AH",
          "LITHIUM ION 60V 34AH"
        ]
      }
    ]
  },
  {
    "make": "SUPER GO MOTORS",
    "models": [
      {
        "model": "T1",
        "variants": [
          "BLUE SKY PLUS 48V",
          "BLUE SKY PLUS 60V",
          "BLUE SKY PLUS ZXI 48V",
          "BLUE SKY PLUS ZXI 60V"
        ]
      },
      {
        "model": "T3",
        "variants": [
          "OSCAR 48V",
          "OSCAR 60V",
          "OSCAR ZXI 48V",
          "OSCAR ZXI 60V"
        ]
      },
      {
        "model": "T5",
        "variants": [
          "GALAXY 60V",
          "GALAXY 72V",
          "GALAXY ZXI 60V",
          "GALAXY ZXI 72V"
        ]
      },
      {
        "model": "T7",
        "variants": [
          "VSP GOLD 48V",
          "VSP GOLD 60V",
          "VSP GOLD ZXI 48V",
          "VSP GOLD ZXI 60V"
        ]
      },
      {
        "model": "T8",
        "variants": [
          "VSP GOLD PLUS 48V",
          "VSP GOLD PLUS 60V",
          "VSP GOLD PLUS ZXI 48V",
          "VSP GOLD PLUS ZXI 60V"
        ]
      },
      {
        "model": "T9",
        "variants": [
          "ULTRA LION 48V",
          "ULTRA LION 60V",
          "ULTRA LION ZXI 48V",
          "ULTRA LION ZXI 60V"
        ]
      },
      {
        "model": "T10",
        "variants": [
          "GRENO CITY 48V",
          "GRENO CITY 60V",
          "GRENO CITY ZXI 48V",
          "GRENO CITY ZXI 60V"
        ]
      }
    ]
  },
  {
    "make": "SUPERECO ELECTRIC",
    "models": [
      {
        "model": "SANWA",
        "variants": [
          "BIKE"
        ]
      }
    ]
  },
  {
    "make": "SUPERTECH",
    "models": [
      {
        "model": "3 WHEEL 48V",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "POWER",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LITHIUM 48V 26AH",
          "LEAD ACID 48V 28AH",
          "LITHIUM 48V 30AH",
          "LITHIUM 60V 30AH",
          "LEAD ACID 60V 28AH",
          "60V 28AH VRLA",
          "60V 24AH LITHIUM",
          "60V 30AH LITHIUM"
        ]
      },
      {
        "model": "V PRO",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM 48V 26AH",
          "LITHIUM 48V 30AH",
          "LITHIUM 60V 30AH",
          "48V 28AH VRLA",
          "48V 24AH LITHIUM",
          "48V 30AH LITHIUM"
        ]
      },
      {
        "model": "GT",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM 48V 28AH",
          "LITHIUM 48V 30AH",
          "LITHIUM 60V 30AH",
          "48V 28AH VRLA",
          "48V 24AH LITHIUM",
          "48V 30AH LITHIUM"
        ]
      },
      {
        "model": "S PRO",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LITHIUM 48V 26AH",
          "LITHIUM 48V 30AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "SUV",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LEAD ACID 72V 28AH",
          "LITHIUM 60V 30AH",
          "LITHIUM 72V 30AH",
          "60V LI"
        ]
      },
      {
        "model": "ACTIVE",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "SUPERTECH AUTO AGENCY",
    "models": [
      {
        "model": "SUV",
        "variants": [
          "LITHIUM BATTERY 48V 26AH",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "V PRO",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 48V 26AH"
        ]
      },
      {
        "model": "S PRO",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 48V 26AH",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "GT",
        "variants": [
          "LITHIUM BATTERY 48V 30AH",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 48V 26AH",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "POWER",
        "variants": [
          "LITHIUM BATTERY 48V 26AH",
          "LITHIUM BATTERY 48V 30AH",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "SUPERTECH INC",
    "models": [
      {
        "model": "POWER MODEL",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "GT MODEL",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "V PRO",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "V PRO SQ2",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SUV SUPER",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "SUPREME SMART POWER PVT LTD",
    "models": [
      {
        "model": "AIOLOS",
        "variants": [
          "LITHIUM ION 48V"
        ]
      }
    ]
  },
  {
    "make": "SUREJA AUTO",
    "models": [
      {
        "model": "XPLOSIVE",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "SUSHAMA MOTORS",
    "models": [
      {
        "model": "ORITSU",
        "variants": [
          "BELLIC ELECTRIC SCOOTER",
          "NIYO ELECTRIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "SUZUKI",
    "models": [
      {
        "model": "DISK",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DRUM",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "HEAT",
        "variants": [
          "125",
          "ALLOYS",
          "ALLOY WHEELS",
          "SPOKE WHEELS",
          "ALLOY WHEEL",
          "SPOKE WHEEL",
          "GT 125",
          "100",
          "GT 125C"
        ]
      },
      {
        "model": "XDU",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XEU",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XZ",
        "variants": [
          "DISK"
        ]
      },
      {
        "model": "ZEUS",
        "variants": [
          "XCD",
          "XDU",
          "XEU",
          "XU",
          "GT 125 EU",
          "GT 125 XEU",
          "GT 125 DU",
          "GT 125 XDU",
          "GT 125 XCD",
          "GT 125 XU",
          "XZ",
          "XU",
          "DISC BRAKE ALLOY WHEELS",
          "DRUM BRAKE ALLOY WHEELS",
          "ELEC ST DISC BRAKE AWS",
          "ELEC ST DRUM BRAKE AWS",
          "ELEC ST DRUM BRAKE SW",
          "125CC",
          "GT",
          "GT 125 X",
          "STD",
          "X",
          "125 EU",
          "125 XU",
          "125 XEU",
          "125 XDU",
          "125 XCD"
        ]
      },
      {
        "model": "ACCESS 125",
        "variants": [
          "STANDARD",
          "SE DISC CBS FI",
          "DRUM CAST SE FI",
          "DISC",
          "ALLOY WHEEL- DRUM CBS",
          "DISC-CBS",
          "SPECIAL EDITION - DISC CBS",
          "DRUM ALLOY - BLUETOOTH",
          "DISC ALLOY - BLUETOOTH",
          "4 STROKE",
          "SPECIAL EDITION - DRUM",
          "DRUM-CBS",
          "SE- ALLOY WHEEL- DRUM CBS",
          "STEEL WHEEL DRUM CBS FI",
          "ALLOY WHEEL DRUM CBS FI",
          "DISC CBS FI",
          "DRUM",
          "DISC BT OBD",
          "DRUM OBD",
          "OBD",
          "SE DISC FI BSVI",
          "RIDE CONNECT EDITION",
          "RIDE CONNECT EDITION DISC"
        ]
      },
      {
        "model": "SAMURAI",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "GS150R",
        "variants": [
          "STANDARD",
          "ALLOY WHEELS"
        ]
      },
      {
        "model": "GT",
        "variants": [
          "125",
          "185 STD",
          "125 STD"
        ]
      },
      {
        "model": "GSX-R1000",
        "variants": [
          "R",
          "STANDARD",
          "900CC"
        ]
      },
      {
        "model": "HAYABUSA",
        "variants": [
          "STANDARD",
          "2019 EDITION",
          "VZR1800",
          "GSXAL7",
          "GSXRRQM2",
          "STD",
          "STD",
          "STD",
          "GS 1300 R",
          "Z",
          "1300"
        ]
      },
      {
        "model": "HAYATE EP",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "VZR",
        "variants": [
          "1800 ZL3 (INTRUDER 1800 L EDTN)",
          "1800"
        ]
      },
      {
        "model": "INTRUDER M1800R",
        "variants": [
          "STANDARD",
          "B.O.S.S. EDITION"
        ]
      },
      {
        "model": "HAYATE",
        "variants": [
          "KICK START",
          "ELECTRIC START",
          "EP",
          "ELEC ST DRUM BRAKE AWS",
          "KICK ST DRUM BRAKE AWS",
          "EP STD BS4",
          "STD",
          "STD",
          "110",
          "KICK START",
          "SELF START",
          "EP",
          "112",
          "SELF ALLOY",
          "KICK ALLOY",
          "GE 110",
          "EP",
          "KICK START DRUM ALLOY WHEEL",
          "SELF START DRUM ALLOY WHEEL"
        ]
      },
      {
        "model": "LET'S",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "GIXXER SF",
        "variants": [
          "REAR DISC",
          "SPECIAL MOTOGP EDITION",
          "SP REAR DISC",
          "SPECIAL MOTOGP EDITION REAR DISC",
          "NEW STANDARD BS VI",
          "MOTOGP EDITION BS VI",
          "STD"
        ]
      },
      {
        "model": "INAZUMA 250",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "INTRUDER 150",
        "variants": [
          "STANDARD",
          "SP",
          "FI BS VI",
          "FI ABS",
          "ABS",
          "STD"
        ]
      },
      {
        "model": "BURGMAN STREET 125",
        "variants": [
          "STANDARD",
          "EX",
          "BS VI",
          "BLUETOOTH",
          "UB"
        ]
      },
      {
        "model": "INTRUDER 150 FI",
        "variants": [
          "SP",
          "STANDARD"
        ]
      },
      {
        "model": "GIXXER SF 250",
        "variants": [
          "STANDARD",
          "MOTOGP EDITION"
        ]
      },
      {
        "model": "GSX",
        "variants": [
          "R 150",
          "R",
          "S750",
          "R1000 Z",
          "150",
          "R1000",
          "R 1000Z LIMITED EDITION",
          "R1300",
          "R600",
          "R750",
          "S 1000 ABS",
          "S 1000 F ABS",
          "R 1000 ABS",
          "S1000",
          "150",
          "R1000",
          "SF250 RC BS6",
          "R 1000 S",
          "S 750 ABS"
        ]
      },
      {
        "model": "GIXXER 250",
        "variants": [
          "STANDARD",
          "SF BS VI",
          "NAKED BS VI",
          "GIXXER NAKED (CC 249)",
          "SF"
        ]
      },
      {
        "model": "GSX-S1000",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "GSX-S1000F",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "V-STROM 1000",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "BANDIT",
        "variants": [
          "1250SA",
          "1250 S",
          "1250S",
          "GSF 400 GK 75 A"
        ]
      },
      {
        "model": "GSX R1000Z",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "HAYABUSA LIMITED EDITION",
        "variants": [
          "GSX1300R"
        ]
      },
      {
        "model": "M1800RZ",
        "variants": [
          "INTRUDER"
        ]
      },
      {
        "model": "M800",
        "variants": [
          "INTRUDER"
        ]
      },
      {
        "model": "GIXXER",
        "variants": [
          "MATTE FIBRON ORANGE BLACK",
          "SP ABS",
          "NEW DUAL TONE REAR DISC",
          "SINGLE CHANNEL ABS BS VI",
          "DISC BRAKE ALLOY WHEELS",
          "RARE DRUM BREAK",
          "RARE DISC BREAK",
          "SF STD BSIV",
          "SF F1 BSIV",
          "SF SPECIAL ADTN STD BSIV",
          "SF ABS BSIV",
          "SF FI ABS BSIV",
          "SF SPECIAL ADTN ABS BSIV",
          "250 SF ABS",
          "250 MOTO GP",
          "FI ABS NAKED NEW",
          "SF FI ABS MOTO GP EDTN",
          "250 FI ABS NAKED NEW",
          "250 SF FI ABS MOTO GP EDTN",
          "GSX150 F NEW",
          "150 BSVI",
          "SF 150 BSVI",
          "SF 150 BSVI (MOTO GP)",
          "250 BSVI",
          "250 SF MOTO GP BSVI",
          "SF GSX 150BSVI",
          "NAKED 150 BSVI",
          "SF 150 RC BSVI",
          "SF 250 RC BSVI",
          "250",
          "SF SP",
          "SF SP ABS",
          "STD",
          "ABS",
          "SF 150",
          "SF 250",
          "SF FI SP ABS",
          "SP REAR DISC",
          "STD",
          "DISC BRAKE",
          "STANDARD",
          "SF DOUBLE DISC",
          "SF FI",
          "SF MOTO GP RR DOUBLE DISC",
          "SF REAR DISC",
          "SF MOTOGP",
          "SF",
          "SF ABS",
          "REAR DISC",
          "REAR DRUM",
          "SF 250 RACE EDITION RIDE CONNECT",
          "SF FI ABS",
          "250 STD",
          "FI ABS",
          "GIXXER 150 GSXLL9",
          "SF 150",
          "MONO TONE",
          "150 DUAL TONE",
          "SINGLE CHANNEL ABS BS6",
          "SF BS6",
          "SF MOTO GP BS6",
          "SF 250 STD BS6",
          "SF MOTO GP BS6.",
          "150 BS6",
          "250 BS6",
          "150",
          "SF STD",
          "SF SPECIAL M ED",
          "RIDE CONNECT",
          "250 RIDE CONNECT",
          "SF RIDE CONNECT",
          "SF 250 RIDE CONNECT",
          "150 NEW ABS",
          "SF BS VI",
          "NAKED BS VI",
          "FI ABS BS VI",
          "SF FI ABS BS VI",
          "SF DUAL DISC ABS",
          "SF LE RD",
          "SF LE",
          "REAR DISC BRAKE",
          "DUAL TONE RR ( DOUBLE DISC )",
          "SF RR ( DOUBLE DISC )",
          "SPECIAL EDITION",
          "RD SPECIAL EDITION",
          "SINGLE DISC ALLOY WHEEL",
          "DUAL TONE",
          "REAR DRUM BRAKE"
        ]
      },
      {
        "model": "GSX-S750",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SLINGSHOT PLUS",
        "variants": [
          "SEU 125CC",
          "SCD 125CC",
          "KICK START",
          "DRUM BRK ALLOY WHEEL KICK START",
          "DRUM BRK ALLOY WHEEL SELF START",
          "DISC BRK ALLOY WHEEL SELF START"
        ]
      },
      {
        "model": "GSX R",
        "variants": [
          "GSX-R 600",
          "STD"
        ]
      },
      {
        "model": "V-STROM 650 XT",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "DR-Z50",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "AVENIS",
        "variants": [
          "125",
          "SCOOTY",
          "SE SCOOTY",
          "DISC STANDARD BSVI",
          "DISC RACE EDITION BSVI",
          "KGL",
          "YSF",
          "RIDE CONNECT EDITION BT",
          "OBD BSVI",
          "OBD SE BSVI",
          "STD",
          "125 STANDARD",
          "RACE EDITION",
          "RIDE CONNECT EDITION",
          "OBD",
          "RIDE CONNECT EDITION",
          "RACE EDITION",
          "STANDARD EDITION",
          "STANDARD EDITION OBD"
        ]
      },
      {
        "model": "AVINISH",
        "variants": [
          "BT 125 BSVI"
        ]
      },
      {
        "model": "V-STROM SX 250",
        "variants": [
          "CC ABS"
        ]
      },
      {
        "model": "GSF",
        "variants": [
          "1250"
        ]
      },
      {
        "model": "ACCESS",
        "variants": [
          "125 SPECIAL EDITION DISC",
          "125 DRUM SELF AND STEEL",
          "SE",
          "125 DRUM BSIV",
          "125 DISC BREAK BSIV",
          "PERL MIRAGE DISC BREAK SE",
          "125 DISC CBS",
          "125 DRUM CBS",
          "125 DISC CBS SPL EDTN",
          "125 DRUM CBS SPL EDTN",
          "125 ALLOY DRUM CBS",
          "125 ALLOY DRUM CBS SPL EDTN",
          "125 DRUM CAST ALLOY",
          "125 DRUM CAST SPL ALLOY",
          "125 DRUM SPL BSVI",
          "125 DRUM CBS BSVI",
          "125 DRUM CAST BSVI",
          "125 DISC CBS BSVI",
          "125 DISC CBS SPL BSVI",
          "DRUM ALLOY WITH BLUETOOTH",
          "DISC ALLOY WITH BLUETOOTH",
          "DISC SPL ED WITH BLUETOOTH",
          "125 UZ BSVI",
          "125 ALLOY DISC BS6",
          "DRUM SPL ED WITH BLUETOOTH",
          "125 DRUM BSVI",
          "125 BSIII",
          "RIDE CONNECT EDTN DRUM BSVI",
          "RIDE CONNECT EDTN DISC BSVI",
          "125 DISC OBD BSVI",
          "125 DISC SE OBD BSVI",
          "125 DRUM OBD BSVI",
          "125 DISC BT OBD BSVI",
          "125 Z DISC OBD BSVI",
          "STD",
          "125 DISC",
          "125 DISC ALLOY BSVI",
          "125 DISC CBS SPECIAL EDITION BS6",
          "125 DRUM",
          "125 DRUM CAST BS6",
          "125 DRUM CAST SPECIAL EDITION BSVI",
          "CBS DISC",
          "DISC CBS BSVI",
          "DRUM CBS BSVI",
          "DRUM-CBS",
          "SPECIAL EDITION - DISC CBS",
          "STD",
          "125",
          "Z DISC CBS",
          "SPL EDITION",
          "UZ 125",
          "125 Z",
          "DISC-CBS",
          "125 DRUM CAST SPL ED BT BS6",
          "125 DISC SPL ED BT BS6",
          "125 DISC ALLOY BT BS6",
          "125 DRUM ALLOY BT BS6",
          "SELF START",
          "SPECIAL EDITION",
          "UZ 125 SPECIAL EDITIION DRUM CAST BLUETOOTH BS VI",
          "DISC CBS SPECIAL EDITION",
          "FI DRUM BRAKE CBS",
          "FI DRUM CAST",
          "FI DISC BRAKE CBS",
          "FI DRUM CAST SPECIAL EDITION",
          "FI DISC CBS SPECIAL EDITION",
          "SPECIAL EDITION CAST FI",
          "SPECIAL EDITIION ALLOY BLUETOOTH BS VI",
          "SPECIAL EDITIION DISC BLUETOOTH BS VI",
          "CBS SPECIAL EDITION DISC BS VI",
          "DRUM ALLOY BS VI",
          "SP 125 DISC CBS FI",
          "DRUM CAST",
          "DRUM CAST SPECIAL EDITION",
          "SPECIAL EDITIION DISC CBS BS VI",
          "DRUM CAST SPL EDITION BLUETOOTH CBS BS VI",
          "DRUM CAST CBS BS VI",
          "DISC SPL EDITIION BLUETOOTH CBS BS VI",
          "DRUM CAST SPL EDITION CBS BS VI",
          "DISK SPECIAL EDITIION DISC OBD",
          "DISC SPL EDITIION BLUETOOTH OBD",
          "DRUM  OBD",
          "DISC BRAKE",
          "DRUM BRAKE CBS",
          "DRUM BRAKE",
          "DISC BRAKE CBS",
          "SEP DISC CBS125 CC",
          "SEP 125 CC",
          "SEP DISC 125 CC",
          "SEP DRUM 125 CC",
          "SEP LE DRUM 125 CC",
          "SEP LE DISC 125 CC",
          "UZ 125 SPECIAL EDITION",
          "SEP DISC CBS SPL EDITION 125 CC"
        ]
      },
      {
        "model": "FIERO",
        "variants": [
          "150",
          "110",
          "STD",
          "F2 DISC BRAKE",
          "FX STD",
          "F2 STD",
          "FX DISC BRAKE",
          "STANDARD"
        ]
      },
      {
        "model": "BURGMAN",
        "variants": [
          "IMPORTED",
          "STREET",
          "STD (IMPORTED)"
        ]
      },
      {
        "model": "INTRUDER",
        "variants": [
          "M 1800 R",
          "YZR-1800",
          "VZR 1800 BZ L4",
          "STD BSIV",
          "F1 BSIV",
          "M 1800R STD BSIV",
          "150 FI",
          "ABS",
          "FI ABS",
          "ABS SPL",
          "ABS FI SPL",
          "STD BSVI",
          "STD",
          "YZR-1800",
          "STD",
          "STD FI",
          "M800",
          "M 1800 R",
          "STD BS6",
          "800",
          "M 800"
        ]
      },
      {
        "model": "LANZA",
        "variants": [
          "150"
        ]
      },
      {
        "model": "RF",
        "variants": [
          "900 IMPORTED"
        ]
      },
      {
        "model": "INAZUMA",
        "variants": [
          "SELF START",
          "STD",
          "STD",
          "250",
          "250 SELF STRAT DISK CAST WHEEL"
        ]
      },
      {
        "model": "GSR",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SLINGSHOT",
        "variants": [
          "DISC BRAKE",
          "DISC BRAKE ALLOY WHEEL",
          "STD",
          "ALLOY WHEELS",
          "ELEC ST DISC BRAKE AWS",
          "SPOKE WHEELS",
          "PLUS SELF START DISC BRAKE",
          "PLUS DISC SELF SPOKE",
          "PLUS SELF START",
          "DISC BRK ALLOY WHEEL SELF START",
          "ALLOY WHEEL DRUM BREAK SELF START",
          "SPOKE  WHEEL DRUM BREAK SELF START"
        ]
      },
      {
        "model": "SV",
        "variants": [
          "400 S STD"
        ]
      },
      {
        "model": "V2 800 L1",
        "variants": [
          "800"
        ]
      },
      {
        "model": "SWISH",
        "variants": [
          "125 SELF START",
          "125 CC",
          "STD",
          "STD",
          "125",
          "SELF START"
        ]
      },
      {
        "model": "V STROM",
        "variants": [
          "650",
          "1000"
        ]
      },
      {
        "model": "VOLUSIA",
        "variants": [
          "VL 800"
        ]
      },
      {
        "model": "FIERO FX",
        "variants": [
          "SELF START DISC BRAKE"
        ]
      },
      {
        "model": "IND",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MAX",
        "variants": [
          "MAX 100",
          "R 100",
          "100 STD"
        ]
      },
      {
        "model": "UZ",
        "variants": [
          "UZ 125",
          "125"
        ]
      },
      {
        "model": "VSTROM",
        "variants": [
          "ABS",
          "650 ABS",
          "SX 250"
        ]
      },
      {
        "model": "SUPRA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KATANA",
        "variants": [
          "STD BSVI",
          "BS6",
          "STD"
        ]
      },
      {
        "model": "GSX S750",
        "variants": [
          "STD BSVI"
        ]
      },
      {
        "model": "LETS",
        "variants": [
          "STD",
          "STD",
          "DUAL TONE",
          "STD",
          "STD",
          "DUEL TONE COLOUR"
        ]
      },
      {
        "model": "BURGMAN STREET",
        "variants": [
          "UB 125",
          "UB 125 BSVI",
          "BLUETOOTH",
          "BLUETOOTH BSVI",
          "STD",
          "EX BT BSVI",
          "OBD BSVI",
          "RC OBD BSVI",
          "EX OBD2 BSVI",
          "125 BS6",
          "125",
          "BT BS6",
          "EX",
          "125 OBD",
          "125 RIDE CONNECT EDITION"
        ]
      },
      {
        "model": "HAYABUSA GSX",
        "variants": [
          "1300 R"
        ]
      },
      {
        "model": "SV650",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MAX R",
        "variants": [
          "MAX R 100"
        ]
      },
      {
        "model": "ACCESS 125 BSIV",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "V STROM 1000",
        "variants": [
          "STD",
          "1037"
        ]
      },
      {
        "model": "GSX-S1000FAL6",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SUZUKI ZEUS",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SUZUKI HEAT",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "UZ 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BANDIT GSF1250",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SUZUKI 1250",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SUZUKI HAYABUSA",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SUZUKI HAYABUSA.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GT 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GXS 750",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SV 650",
        "variants": [
          "STD",
          "GLADIUS"
        ]
      },
      {
        "model": "INTRUDER VZR 800",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GSX R1",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GS",
        "variants": [
          "STD",
          "150 R ALLOY WHEEL",
          "150 R"
        ]
      },
      {
        "model": "SLING SHOT",
        "variants": [
          "STD",
          "DISC BRAKE",
          "PLUS",
          "PLUS SEU",
          "125",
          "DRUM SPOKES",
          "PLUS DRUM KICK",
          "PLUS DRUM SELF",
          "PLUS DISC SELF",
          "ES DRUM BRAKE",
          "SPOKE WHEEL",
          "DRUM BRAKE"
        ]
      },
      {
        "model": "INTUDER M 1800 R",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "INTRUDER VZR 1800",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "B-KING",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GLADIUS",
        "variants": [
          "650"
        ]
      },
      {
        "model": "GW",
        "variants": [
          "250"
        ]
      },
      {
        "model": "MAX 100R",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "OZARK",
        "variants": [
          "250"
        ]
      },
      {
        "model": "QUADSPORT",
        "variants": [
          "Z 400"
        ]
      },
      {
        "model": "SKYDRIVE",
        "variants": [
          "125",
          "STANDARD",
          "SCOOTER"
        ]
      },
      {
        "model": "SLING SHOT PLUS",
        "variants": [
          "SCD",
          "SCU",
          "ES DISC BRAKE",
          "KS DRUM BRAKE"
        ]
      },
      {
        "model": "V-STROM",
        "variants": [
          "650 XT",
          "650 XT ABS",
          "SX",
          "1000"
        ]
      },
      {
        "model": "RM-Z250",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "RM-Z450",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "GSXR",
        "variants": [
          "1000 R",
          "750",
          "600"
        ]
      },
      {
        "model": "BURGMAN 125",
        "variants": [
          "STREET BLUETOOTH BS VI",
          "STREET BS VI",
          "STREET RIDE CONNECT OBD",
          "STREET OBD",
          "STREET EX BS VI",
          "STREET"
        ]
      },
      {
        "model": "SAVAGE",
        "variants": [
          "650"
        ]
      },
      {
        "model": "GT SERIES",
        "variants": [
          "GT 750",
          "GT 550",
          "GT 50",
          "GT 380",
          "GT 185",
          "GT 125",
          "GT 250"
        ]
      },
      {
        "model": "SWISH 125",
        "variants": [
          "DRUM - ELECTRICAL START"
        ]
      },
      {
        "model": "RF 900",
        "variants": [
          "1995 IMP MOTORCYCLE"
        ]
      }
    ]
  },
  {
    "make": "SV MOTORS",
    "models": [
      {
        "model": "PRANA",
        "variants": [
          "72V 15 A"
        ]
      }
    ]
  },
  {
    "make": "SVM",
    "models": [
      {
        "model": "PRANA",
        "variants": [
          "GRAND",
          "ELITE"
        ]
      }
    ]
  },
  {
    "make": "SWM",
    "models": [
      {
        "model": "SUPERDUAL T",
        "variants": [
          "600CC"
        ]
      }
    ]
  },
  {
    "make": "SYM",
    "models": [
      {
        "model": "MIO",
        "variants": [
          "STD",
          "STD",
          "SCOOTER"
        ]
      },
      {
        "model": "MIO 50",
        "variants": [
          "SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "SYNERGY- ELECTRIC",
    "models": [
      {
        "model": "Y55",
        "variants": [
          "8D"
        ]
      }
    ]
  },
  {
    "make": "TARANG ELECTRIC VEHICLE",
    "models": [
      {
        "model": "ZEAL",
        "variants": [
          "PLUS-W"
        ]
      }
    ]
  },
  {
    "make": "TARANG INNOVATIVE SOLUTIONS",
    "models": [
      {
        "model": "ZEAL PLUS",
        "variants": [
          "LITHIUM ION  60V 24AH"
        ]
      }
    ]
  },
  {
    "make": "TECHNO ELECTRA",
    "models": [
      {
        "model": "RAPTOR",
        "variants": [
          "E SCOOTER",
          "LITHIUM ION BATTERY 60V 30AH"
        ]
      },
      {
        "model": "EMERGE",
        "variants": [
          "E SCOOTER",
          "LITHIUM ION BATTERY 60V 30AH"
        ]
      },
      {
        "model": "NEO",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "SAATHI",
        "variants": [
          "LITHIUM ION 48V/26AH"
        ]
      }
    ]
  },
  {
    "make": "TECHO",
    "models": [
      {
        "model": "RAPTOR",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "EMERGE",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "NEO",
        "variants": [
          "V"
        ]
      },
      {
        "model": "SAATHI",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "ELECTRA RAPTOR",
        "variants": [
          "LITHIUM ION 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "TECHO ELECTRA",
    "models": [
      {
        "model": "EMERGE",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "RAPTOR",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "SAATHI",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "NEO",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "TERRA MOTORS",
    "models": [
      {
        "model": "A4000I",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "KIWAMI",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "THE ROYAL EV",
    "models": [
      {
        "model": "TRANGO",
        "variants": [
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "THOMAS ELECTRO ENERGY PVT LTD",
    "models": [
      {
        "model": "CRAZY",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "T1",
        "variants": [
          "LEAD ACID"
        ]
      }
    ]
  },
  {
    "make": "THUKRAL",
    "models": [
      {
        "model": "E GEN",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "THUKRAL ELECTRIC",
    "models": [
      {
        "model": "E GEN",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "THUKRAL EGEN LI",
        "variants": [
          "LITHIUM BATTERY 48V 30AH",
          "LITHIUM BATTERY 52V 50AH"
        ]
      }
    ]
  },
  {
    "make": "THUNDERBOLT",
    "models": [
      {
        "model": "EZ",
        "variants": [
          "ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "TIRANGA",
    "models": [
      {
        "model": "VAJRA",
        "variants": [
          "E-SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "TIRANGA AUTOTECH",
    "models": [
      {
        "model": "VYOM 60V",
        "variants": [
          "IP LION"
        ]
      },
      {
        "model": "NANDI",
        "variants": [
          "STANDARD",
          "STANDARD LED ACID",
          "EPIC LIFEPO4 48V 24AH",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "VAJRA",
        "variants": [
          "60V 28AH",
          "60V 30AH",
          "60V 40AH",
          "60V 32AH",
          "60V 42AH",
          "60V 54AH",
          "LITHIUM ION  60V 30AH",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "VYOM",
        "variants": [
          "LITHIUM ION  60V 30AH",
          "LEAD ACID 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "TNR",
    "models": [
      {
        "model": "JETTER",
        "variants": [
          "CLASSIC",
          "PRO",
          "E BIKE",
          "STD E BIKE",
          "72V - 28AH",
          "PRO LED",
          "LITHIUM 72V 26AH",
          "PRO LITHIUM 72V 26AH",
          "CLASSIC",
          "E SCOOTER"
        ]
      },
      {
        "model": "JETTER CLASSIC",
        "variants": [
          "E BIKE",
          "STD E BIKE",
          "60V - 27AH",
          "60V 30AH",
          "LITHIUM 60V 26AH"
        ]
      },
      {
        "model": "TURNER",
        "variants": [
          "E BIKE",
          "EX EBIKE",
          "CLASSIC EBIKE",
          "CLASSIC STD E BIKE",
          "EX STD E BIKE",
          "01 STD E BIKE",
          "60V - 27AH",
          "CLASSIC 60V 27AH",
          "60V - 38AH",
          "CLASSIC LITHIUM 48V 26AH",
          "EX LITHIUM 48V 26AH",
          "LITHIUM 60V 26AH",
          "ELECTRIC SCOOTER",
          "EX",
          "CLASSIC"
        ]
      },
      {
        "model": "ZOOBA",
        "variants": [
          "STD E BIKE",
          "Z 30",
          "Z 03",
          "30 LITHIUM 48V 26AH",
          "LITHIUM 48V 26AH",
          "Z 03 LEAD ACID 60V 28AH",
          "E SCOOTER",
          "30 E SCOOTER"
        ]
      },
      {
        "model": "ELECTRIC",
        "variants": [
          "THREE WHEEL"
        ]
      },
      {
        "model": "STELLA",
        "variants": [
          "E BIKE",
          "LITHIUM",
          "60V 27AH LEAD ACID"
        ]
      },
      {
        "model": "LICINO",
        "variants": [
          "E BIKE",
          "LITHIUM 48V 26AH",
          "E SCOOTER"
        ]
      },
      {
        "model": "E STYLA",
        "variants": [
          "E SCOOTER",
          "60V 24AH",
          "LITHIUM 60V 26AH"
        ]
      },
      {
        "model": "KIKO",
        "variants": [
          "SPROT"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SUPER ECO",
        "variants": [
          "S25"
        ]
      },
      {
        "model": "THREE WHEEL",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TRIKE",
        "variants": [
          "3WH"
        ]
      },
      {
        "model": "BENJAMIN MARC",
        "variants": [
          "LEAD ACID 60V"
        ]
      },
      {
        "model": "ESTYLA",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "TNR VEHICLES",
    "models": [
      {
        "model": "JETTER PRO",
        "variants": [
          "LITHIUM BATTERY 72V 38AH"
        ]
      },
      {
        "model": "JETTER",
        "variants": [
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY",
          "CLASSIC 60V E SCOOTER",
          "72V E SCOOTER",
          "39 AH E SCOOTER"
        ]
      },
      {
        "model": "ESTYLA",
        "variants": [
          "LITHIUM ION  60V 30AH",
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "LICINO",
        "variants": [
          "LITHIUM ION  60V 24AH"
        ]
      },
      {
        "model": "STELLA",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "GALAXY",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "EASY GO PLUS",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "LOADER",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "ZOOBA 30",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "TURNER CLASSIC",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "ZOOBA 03",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "TURNER EX",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "TURNER 01",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "JEETER CLASSIC",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "ZOOBA",
        "variants": [
          "30V E SCOOTER",
          "60V E SCOOTER"
        ]
      },
      {
        "model": "TURNER",
        "variants": [
          "60V E SCOOTER",
          "39 AH",
          "CLASSIC 60V E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "TORK",
    "models": [
      {
        "model": "KRATOS",
        "variants": [
          "URBAN",
          "R"
        ]
      }
    ]
  },
  {
    "make": "TORK MOTORS",
    "models": [
      {
        "model": "KRATOS",
        "variants": [
          "E BIKE",
          "48V",
          "E BIKE"
        ]
      },
      {
        "model": "KRATOS R",
        "variants": [
          "E BIKE",
          "E BIKE"
        ]
      },
      {
        "model": "KRATOS-R",
        "variants": [
          "48V",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "TORO",
    "models": [
      {
        "model": "JAZZ",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "TOXMO",
    "models": [
      {
        "model": "RAVE",
        "variants": [
          "48V/24 AH"
        ]
      },
      {
        "model": "RAVE I40",
        "variants": [
          "48V/24 AH"
        ]
      },
      {
        "model": "RHINO",
        "variants": [
          "48V/24 AH"
        ]
      },
      {
        "model": "RHINO PLUS",
        "variants": [
          "48V/24 AH"
        ]
      },
      {
        "model": "ROCK",
        "variants": [
          "60V/24 AH"
        ]
      },
      {
        "model": "RAPID",
        "variants": [
          "60V/28 AH",
          "48V/24 AH"
        ]
      },
      {
        "model": "E-TOX",
        "variants": [
          "72V/24 AH"
        ]
      }
    ]
  },
  {
    "make": "TOXMO ELECTRIC MOTORS",
    "models": [
      {
        "model": "RAPID",
        "variants": [
          "250 WATT (LEAD BATTERY)"
        ]
      },
      {
        "model": "RAVE I40",
        "variants": [
          "LEAD ACID 60 V 28 AH"
        ]
      },
      {
        "model": "RHINO",
        "variants": [
          "LEAD ACID 48V 28AH"
        ]
      },
      {
        "model": "TOX KING",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "E TOX",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "RAVE I30",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "ROCK",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "RHINO PLUS",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LITHIUM BATTERY 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "TOXMO ELECTRIC PVT LTD",
    "models": [
      {
        "model": "RHINO",
        "variants": [
          "LITHIUM ION",
          "LEAD"
        ]
      },
      {
        "model": "ROCK",
        "variants": [
          "LITHIUM ION",
          "LEAD"
        ]
      },
      {
        "model": "RHINA +",
        "variants": [
          "ELECTRIC VEHICLE"
        ]
      },
      {
        "model": "RIEDER",
        "variants": [
          "ELECTRIC VEHICLE"
        ]
      },
      {
        "model": "RAPID",
        "variants": [
          "ELECTRIC VEHICLE"
        ]
      },
      {
        "model": "E-TOX",
        "variants": [
          "ELECTRIC VEHICLE"
        ]
      },
      {
        "model": "RAVE",
        "variants": [
          "ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "TRINITY",
    "models": [
      {
        "model": "AMIGO",
        "variants": [
          "BASIC"
        ]
      },
      {
        "model": "SATHI",
        "variants": [
          "BASIC"
        ]
      },
      {
        "model": "MITRA",
        "variants": [
          "BASIC"
        ]
      },
      {
        "model": "RAFIKI",
        "variants": [
          "BASIC"
        ]
      },
      {
        "model": "FRIEND",
        "variants": [
          "BASIC",
          "PLATINUM"
        ]
      },
      {
        "model": "DOST",
        "variants": [
          "BASIC",
          "PLATINUM"
        ]
      },
      {
        "model": "RAFIKI ZL3",
        "variants": [
          "BASIC",
          "PLATINUM"
        ]
      },
      {
        "model": "YAARII",
        "variants": [
          "BASIC"
        ]
      }
    ]
  },
  {
    "make": "TRINITY MOTORS",
    "models": [
      {
        "model": "PREMIUM YAARI",
        "variants": [
          "48V 30AH"
        ]
      },
      {
        "model": "PREMIUM AMIGO",
        "variants": [
          "48V 30AH"
        ]
      },
      {
        "model": "YAARI BASIC",
        "variants": [
          "48V 30AH"
        ]
      },
      {
        "model": "AMIGO BASIC",
        "variants": [
          "48V 30AH"
        ]
      },
      {
        "model": "MITRA",
        "variants": [
          "LITHIUM 48V 30AH",
          "LIFEPO4 48V 30AH",
          "LIFEPO4 48V 24AH",
          "SILVER LIFEPO4 48V 24AH 250 WATT",
          "GOLD LIFEPO4 48V 30AH 250 WATT"
        ]
      },
      {
        "model": "YAARII 4G",
        "variants": [
          "LIFEPO4 48V 30AH",
          "LIFEPO4 48V 24AH"
        ]
      },
      {
        "model": "SAATHI",
        "variants": [
          "LITHIUM 60V 30AH",
          "LITHIUM 60V 36AH"
        ]
      },
      {
        "model": "RAFIKI",
        "variants": [
          "LITHIUM 48V 30AH",
          "LITHIUM 48V 24AH",
          "48V / 29 AH",
          "SILVER LIFEPO4 48V 24AH 250 WATT",
          "GOLD LIFEPO4 48V 30AH 250 WATT"
        ]
      },
      {
        "model": "FRIEND",
        "variants": [
          "LIHTIUM 72V 24AH",
          "LIHTIUM 72V 30AH",
          "GOLD LIFEPO4 72V 24AH 1.5KW",
          "PLATINUM LIFEPO4 72V 30AH 1.5KW"
        ]
      },
      {
        "model": "DOST",
        "variants": [
          "LITHIUM 60V 30AH",
          "LIHTIUM 60V 36AH",
          "PLATINUM LIFEPO4 60V 36AH 1.5KW",
          "GOLD LIFEPO4 60V 30AH 1.5KW"
        ]
      },
      {
        "model": "RAFIKI ZL3",
        "variants": [
          "LITHIUM PHOSPHATE 48V 30AH",
          "LITHIUM PHOSPHATE 48V 36AH",
          "PLATINUM LIFEPO4 48V 36AH 1.5KW",
          "GOLD LIFEPO4 48V 30AH 1.5KW"
        ]
      },
      {
        "model": "YAARI",
        "variants": [
          "E SCOOTER",
          "51V / 30 AH"
        ]
      },
      {
        "model": "AMIGO",
        "variants": [
          "48V / 29 AH",
          "48V 30AH",
          "PREMIUM",
          "BASIC"
        ]
      },
      {
        "model": "YAARII",
        "variants": [
          "GOLD LIFEPO4 48V 30AH 250 WATT",
          "SILVER LIFEPO4 48V 24AH 250 WATT",
          "BASIC",
          "PREMIUM"
        ]
      },
      {
        "model": "SATHI",
        "variants": [
          "PLATINUM LIFEPO4 60V 36AH 250 WATT",
          "GOLD LIFEPO4 60V 30AH 250 WATT",
          "250 WATT STANDARD"
        ]
      }
    ]
  },
  {
    "make": "TRIUMPH",
    "models": [
      {
        "model": "ROCKET III ROADSTER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "BONNEVILLE",
        "variants": [
          "STANDARD",
          "BLACK",
          "T100",
          "BOBBER",
          "BOBBER",
          "A3",
          "STD",
          "BOBBER STD",
          "BOBBER STD",
          "SPEEDMASTER",
          "T100 STD",
          "865",
          "T100",
          "T120",
          "T100 (900 CC)",
          "SPEEDMASTER 1200 CC",
          "SPEEDMASTER 865 CC"
        ]
      },
      {
        "model": "STREET",
        "variants": [
          "TRIPLE S A2",
          "TWINDP",
          "SCRAMBLER",
          "TRIPLE 675",
          "TRIPLE R",
          "TRIPLE RS",
          "TRIPLE S",
          "TRIPLE",
          "TWIN GOLD LINE EDITION BS6",
          "TWIN STD BS6"
        ]
      },
      {
        "model": "SPEED TWIN",
        "variants": [
          "STANDARD",
          "1200",
          "STD"
        ]
      },
      {
        "model": "ROCKET 3",
        "variants": [
          "R",
          "GT"
        ]
      },
      {
        "model": "THUNDERBIRD LT",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "THUNDERBIRD STORM",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "THRUXTON",
        "variants": [
          "865 CC",
          "STANDARD",
          "900",
          "STD",
          "R"
        ]
      },
      {
        "model": "BONNEVILLE BOBBER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "BONNEVILLE T100",
        "variants": [
          "STANDARD",
          "865CC",
          "BLACK"
        ]
      },
      {
        "model": "BONNEVILLE T120",
        "variants": [
          "STANDARD",
          "BLACK"
        ]
      },
      {
        "model": "DAYTONA 675R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPEED TRIPLE",
        "variants": [
          "ABS",
          "ABS A1",
          "STD",
          "1200 RS"
        ]
      },
      {
        "model": "STREET TRIPLE 675",
        "variants": [
          "ABS"
        ]
      },
      {
        "model": "STREET TRIPLE 765",
        "variants": [
          "S"
        ]
      },
      {
        "model": "THRUXTON R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TIGER 800 XC",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TIGER 800 XCX",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TIGER 800 XR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TIGER 800 XRX",
        "variants": [
          "DIGITAL CDI",
          "STANDARD"
        ]
      },
      {
        "model": "TIGER EXPLORER XCX",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TIGER EXPLORER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "STREET SCRAMBLER",
        "variants": [
          "STANDARD",
          "STD",
          "900 CC"
        ]
      },
      {
        "model": "TIGER 800 XCA",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "STREET TRIPLE RS",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "STREET TWIN",
        "variants": [
          "STANDARD",
          "ABS"
        ]
      },
      {
        "model": "BONNEVILLE SPEEDMASTER",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPEED TRIPLE 1200 RS",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TIGER 850 SPORT",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SCRAMBLER 900",
        "variants": [
          "STANDARD",
          "CHROME EDITION"
        ]
      },
      {
        "model": "SPEED",
        "variants": [
          "400",
          "STD",
          "MASTER",
          "TWIN ABS",
          "TRIPLE"
        ]
      },
      {
        "model": "STREET TRIPLE",
        "variants": [
          "ABS B1 JV",
          "765",
          "ABS",
          "ABS A2",
          "R",
          "RS",
          "S ABS",
          "S CC 765"
        ]
      },
      {
        "model": "ROCKET III",
        "variants": [
          "STD",
          "CLASSIC",
          "TOURING",
          "GT",
          "ROADSTER ABS A2",
          "ROADSTER",
          "R STD"
        ]
      },
      {
        "model": "TIGER",
        "variants": [
          "1050",
          "800 XC",
          "SPORT",
          "EXPLORER ABS A1",
          "EXPLORER XC",
          "XCA",
          "XCX",
          "XR",
          "XRX",
          "900 STD",
          "100 STD",
          "1200 XCX",
          "SOLO",
          "900 GT",
          "900 RALLY",
          "900 RALLY PRO",
          "800 XC",
          "800 XCA",
          "800 XCX",
          "800 XR",
          "800 XRX",
          "EXPLORER XCX",
          "EXPLORER",
          "900",
          "850 SPORT",
          "STD",
          "800 EXPLORER ABS A1",
          "EXPLORE RX CABS WITH SPOKE WHEELS",
          "EXPLORE XC"
        ]
      },
      {
        "model": "ROCKET",
        "variants": [
          "3 GT",
          "STD"
        ]
      },
      {
        "model": "SPEED MASTER",
        "variants": [
          "1200 STD",
          "STD"
        ]
      },
      {
        "model": "THUNDERBIRD",
        "variants": [
          "STD",
          "STORM",
          "LT",
          "STANDARD"
        ]
      },
      {
        "model": "SCRAMBLER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CRUISER",
        "variants": [
          "SPEEDMASTER"
        ]
      },
      {
        "model": "SCRAMBLER 1200",
        "variants": [
          "XC",
          "STEVE MCQUEEN EDITION"
        ]
      },
      {
        "model": "SPRINT",
        "variants": [
          "ST",
          "ST 1050"
        ]
      },
      {
        "model": "TWENTY ONE",
        "variants": [
          "SOLO",
          "STANDARD"
        ]
      },
      {
        "model": "URBAN",
        "variants": [
          "SPORTS"
        ]
      },
      {
        "model": "TRIDENT 660",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DAYTONA",
        "variants": [
          "675 ABS",
          "955I",
          "675",
          "675 R ABS",
          "675 R"
        ]
      },
      {
        "model": "STREET TRIPLE R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "THURXTON",
        "variants": [
          "A2",
          "R",
          "1200 R"
        ]
      },
      {
        "model": "TRIDENT",
        "variants": [
          "660 STD BS6"
        ]
      },
      {
        "model": "SPEED 400",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPEED TWIN 900",
        "variants": [
          "CHROME EDITION"
        ]
      },
      {
        "model": "TIGER 1200",
        "variants": [
          "GT PRO",
          "RALLY PRO",
          "GT EXPLORER",
          "RALLY EXPLORER"
        ]
      },
      {
        "model": "3T",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BONNEVILLE BLACK",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "TUNWAL",
    "models": [
      {
        "model": "TZ 3.3",
        "variants": [
          "LITHIUM 72V 40AH"
        ]
      },
      {
        "model": "ELEKTRIKA",
        "variants": [
          "60(28AH)",
          "60(43AH)",
          "60V 28AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "LITHINO LI",
        "variants": [
          "(28AH)",
          "(43AH)",
          "(60V) (32AH) LITHIUM BATTERY",
          "(60V) 28AH LEAD ACID BATTERY",
          "(60V) 43AH LEAD ACID BATTERY",
          "(60V) 26AH LITHIUM BATTERY",
          "(60V) WITHOUT BATTERY",
          "60V20AH LI PHOSPHATE DUAL BAT",
          "LITHIUM 60V 30AH",
          "LEAD ACID BATTERY 250 WATT 60V 28AH",
          "LEAD ACID BATTERY 43AH",
          "LITHIUM ION BATTERY 1200 WATT 60V 26AH",
          "2.0 BATTERY 250 WATT 60 V"
        ]
      },
      {
        "model": "LITHINO PRO",
        "variants": [
          "12V 43 AH",
          "60V 43 AH",
          "60V 28 AH",
          "WITHOUT BATTERY",
          "60V 26 AH",
          "LITHIUM 60V 30AH",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "SPORT",
        "variants": [
          "63(48V) 28AH",
          "63(60V) 28AH",
          "63(60V) 43AH",
          "63(48V) (26AH) LITHIUM BATTERY",
          "63(60V) (32AH) LITHIUM BATTERY",
          "63(60V) ALFA PRO LEAD ACID",
          "63 ALPHA 48V STD",
          "MINI 63",
          "MID 63",
          "SUPER 63",
          "63",
          "63 ALPHA LITHIUM ION BATTERY 2000 WATT 48V 20AH",
          "63 ALPHA LEAD ACID BATTERY 250 WATT 12V 20AH",
          "63 LEAD ACID BATTERY 60V 43AH",
          "63 LEAD ACID BATTERY 60V 28AH",
          "63 LEAD ACID BATTERY 48V",
          "63 (200KM)",
          "63"
        ]
      },
      {
        "model": "STORM ZX",
        "variants": [
          "28AH",
          "(60V) (26AH) LITHIUM BATTERY",
          "(60V)28AH LEAD ACID BATTERY",
          "(60V) WITHOUT BATTERY",
          "PORTABLE DUAL LITHIUM BATTERY",
          "SINGLE LITHIUM PHOSPHATE BATT",
          "PLUS",
          "60V28AH",
          "LITHIUM ION BATTERY 2000 WATT 60V 26AH",
          "LEAD ACID BATTERY 250 WATT 12V 20AH"
        ]
      },
      {
        "model": "ROMA",
        "variants": [
          "(60V) 28AH LEAD ACID BATTERY",
          "(60V) WITHOUT BATTERY",
          "(60V) 28AH LITHIUM ION BATTERY",
          "S LI BATTERY",
          "60V / 30AH",
          "LITHIUM ION BATTERY 60 V 26 AH 250 WATT",
          "LEAD ACID BATTERY 60 V 28 AH 250 WATT"
        ]
      },
      {
        "model": "ELEKTRIKA 60",
        "variants": [
          "(60V)28AH LEAD ACID BATTERY",
          "(60V)43AH LEAD ACID BATTERY",
          "(60V)WITHOUT BATTERY",
          "LITHIUM 60V 30AH",
          "60 LI"
        ]
      },
      {
        "model": "LITHINO LI 2.0",
        "variants": [
          "(28AH) 28AH LEAD ACID BATTERY",
          "60V (26AH) LITHIUM BATTERY",
          "60V (26AH) WITHOUT BATTERY",
          "60V 28 AH",
          "43 AH"
        ]
      },
      {
        "model": "LITHINO 2.0",
        "variants": [
          "LEAD 60V 28AH"
        ]
      },
      {
        "model": "MINI LITHINO",
        "variants": [
          "LI (48V)28AH LEAD ACID BATTERY",
          "LI 48V (26AH) LITHIUM BATTERY",
          "LI 48V WITHOUT BATTERY",
          "48V 30AH",
          "48V 36AH",
          "60V 26AH LEAD",
          "48V 28AH LEAD",
          "LITHIUM PORTABLE BATTERY 28V 26AH",
          "LEAD ACID BATTERY 48V 28 AH",
          "LITHIUM BATTERY 48V"
        ]
      },
      {
        "model": "SPORT 63(48V) ALFA",
        "variants": [
          "28AH LEAD ACID BATTERY",
          "26AH LITHIUM BATTERY",
          "WITHOUT BATTERY"
        ]
      },
      {
        "model": "SPORT 63(60V)",
        "variants": [
          "28AH LEAD ACID BATTERY",
          "43AH LEAD ACID BATTERY",
          "26AH LITHIUM BATTERY",
          "WITHOUT BATTERY"
        ]
      },
      {
        "model": "SPORT 63(48V)",
        "variants": [
          "28AH LEAD ACID BATTERY",
          "WITHOUT BATTERY"
        ]
      },
      {
        "model": "STORM ADVANCE",
        "variants": [
          "60V 28AH LEAD ACID BATTERY",
          "60V 28AH LEAD ACID BATTERY",
          "60V20AH DUAL REMOVABLE BATTERY",
          "SINGLE LITHIUM ION BATTERY"
        ]
      },
      {
        "model": "T33",
        "variants": [
          "28AH LEAD ACID BATTERY",
          "WITHOUT BATTERY"
        ]
      },
      {
        "model": "T133",
        "variants": [
          "LITHIUM 60V 32 AH",
          "LITHIUM 60V 26AH",
          "LEAD 60V 28AH",
          "LITHIUM 60V 40AH",
          "LEAD 60V 43AH"
        ]
      },
      {
        "model": "SAFIR",
        "variants": [
          "2500 E BIKE"
        ]
      },
      {
        "model": "ALFA PRO",
        "variants": [
          "LI BATTERY",
          "60V 30AH",
          "LITHIUM ION 60V 28AH",
          "LEAD ACID 60V 28AH",
          "60V / 28AH",
          "LEAD ACID BATTERY 48V 28 AH",
          "LITHIUM BATTERY 28V 26AH"
        ]
      },
      {
        "model": "STAR",
        "variants": [
          "3.0 28AH 60V",
          "X 28AH 60V",
          "X LITHINUM BATTERY 60V 41AH"
        ]
      },
      {
        "model": "STAR 3.0",
        "variants": [
          "48V 28AH LEAD"
        ]
      },
      {
        "model": "TEM G33",
        "variants": [
          "LITHIUM 60V 40AH",
          "STANDARD"
        ]
      },
      {
        "model": "E ROBOT",
        "variants": [
          "LEAD 60V 43AH"
        ]
      },
      {
        "model": "ALFA LITE",
        "variants": [
          "LEAD 48V 28AH"
        ]
      },
      {
        "model": "TZ",
        "variants": [
          "72V / 40AH"
        ]
      },
      {
        "model": "ELECTRIKA 48",
        "variants": [
          "VRLA 60V"
        ]
      },
      {
        "model": "ELECTRIKA 48 LI",
        "variants": [
          "LITHIUM ION 60V 28AH"
        ]
      },
      {
        "model": "ELECTRIKA 60",
        "variants": [
          "LITHIUM ION 60V 32AH",
          "LEAD ACID BATTERY 12V 28AH"
        ]
      },
      {
        "model": "LITHINO",
        "variants": [
          "LI VRLA 60V 28AH",
          "LI"
        ]
      },
      {
        "model": "MINI LITHINO 48V",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "T 133",
        "variants": [
          "STANDARD",
          "STANDARD"
        ]
      },
      {
        "model": "STORM",
        "variants": [
          "ZX",
          "ZX"
        ]
      },
      {
        "model": "SUPER SPORT 63",
        "variants": [
          "LITHIUM ION BATTERY 2000 WATT 60V 40AH",
          "LEAD ACID BATTERY 250 WATT 12V 62AH"
        ]
      },
      {
        "model": "MID SPORT 63",
        "variants": [
          "LEAD ACID BATTERY 250 WATT 12V 20AH",
          "LITHIUM ION BATTERY 2000 WATT 60V 20AH"
        ]
      },
      {
        "model": "FLASH",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "ELEKTRIKA 48",
        "variants": [
          "LEAD ACID BATTERY 250 WATT 12V 43AH",
          "LEAD ACID BATTERY 250 WATT 12V 20AH"
        ]
      },
      {
        "model": "MINI SPORT 63",
        "variants": [
          "LEAD ACID BATTERY 250 WATT 12V  28AH"
        ]
      },
      {
        "model": "ELECTRIKA",
        "variants": [
          "48"
        ]
      },
      {
        "model": "MINI",
        "variants": [
          "E BIKE",
          "LITHINO LI BATTERY 250 WATT 48 V0"
        ]
      },
      {
        "model": "T 33",
        "variants": [
          "LEAD ACID BATTERY 60V 43AH",
          "LEAD ACID BATTERY 60V 28 AH"
        ]
      },
      {
        "model": "ROMA S",
        "variants": [
          "LEAD ACID BATTERY"
        ]
      }
    ]
  },
  {
    "make": "TUNWAL E BIKE",
    "models": [
      {
        "model": "ELECTRIKA 48",
        "variants": [
          "VRLA 60V 28AH",
          "VRLA 60V 43AH"
        ]
      },
      {
        "model": "STORM ZX",
        "variants": [
          "VRLA 60V 28AH"
        ]
      },
      {
        "model": "MINI SPORT63",
        "variants": [
          "VRLA 48V 28AH",
          "VRLA 60V 28AH",
          "VRLA 60V 43AH"
        ]
      },
      {
        "model": "SUPER SPORT 63",
        "variants": [
          "VRLA 60V 63AH"
        ]
      },
      {
        "model": "LITHINO LI",
        "variants": [
          "VRLA 60V 28AH",
          "VRLA 60V 43AH"
        ]
      },
      {
        "model": "ELECTRIKA 48 LI",
        "variants": [
          "LITHIUM ION 60V 28AH"
        ]
      },
      {
        "model": "STORM ZX LI",
        "variants": [
          "LITHIUM ION 60V 28AH"
        ]
      },
      {
        "model": "SPORT 63 LI",
        "variants": [
          "LITHIUM ION 60V 28AH"
        ]
      },
      {
        "model": "ELECTRIKA 60",
        "variants": [
          "LITHIUM ION 60V 32AH",
          "LEAD ACID BATTERY 12V 28AH"
        ]
      },
      {
        "model": "LITHINO LI 2.0",
        "variants": [
          "LITHIUM ION 12V 26AH"
        ]
      },
      {
        "model": "MINI LITHINO 48V",
        "variants": [
          "LITHIUM ION 12V 26AH"
        ]
      },
      {
        "model": "SPORT 63",
        "variants": [
          "LEAD ACID BATTERY 12V 28AH",
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "ALFA PRO",
        "variants": [
          "SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "TUSAWADA",
    "models": [
      {
        "model": "GTR",
        "variants": [
          "LITHIUM ION"
        ]
      },
      {
        "model": "SPIMARI",
        "variants": [
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "TUZBY",
    "models": [
      {
        "model": "ELEQ",
        "variants": [
          "NEXUS EV",
          "NEXUS POP"
        ]
      },
      {
        "model": "NEXUS EV PRO",
        "variants": [
          "LITHIUM ION BATTERY 60V 30AH"
        ]
      },
      {
        "model": "NEXUS EV POP",
        "variants": [
          "LITHIUM ION BATTERY 48V 30AH"
        ]
      },
      {
        "model": "NEXUS EV",
        "variants": [
          "LITHIUM ION BATTERY 60V 30AH"
        ]
      },
      {
        "model": "NEXUS EV CLASSIC",
        "variants": [
          "LITHIUM ION BATTERY 60V 30AH"
        ]
      }
    ]
  },
  {
    "make": "TVS",
    "models": [
      {
        "model": "APACHE",
        "variants": [
          "APACHE 150",
          "RTR 160 4V SPL ED BT",
          "KICK START",
          "SELF START",
          "DLX",
          "ELECTRIC START",
          "RTR - ELECTRIC START",
          "RTR - KICK ST",
          "RTR 160 DISC ABS",
          "RTR 4V",
          "RTR FI",
          "RTR MENACE",
          "RTR 160 FR DISC",
          "RTR 160 UPG BSIV",
          "RTR 160 UPG MATT RED BSIV",
          "RTR 160 RR NEW BSIV",
          "RTR 160 RR NW MATT RED BSIV",
          "RTR 180 NEW BSIV",
          "RTR 180 MAT RED BSIV",
          "RTR 180 ABS NEW BSIV",
          "200 BSIV",
          "RTR 200 4V2.0",
          "RTR 200 PIRELLI",
          "RTR 200 EFI",
          "RTR 200 ABS",
          "RTR 160 UP GOLD BSIV",
          "RTR 160 RR OLD BSIV",
          "RTR 180 OLD BSIV",
          "RTR 160 4V REAR DISC",
          "RTR 160 4V FI DUAL DISC",
          "RTR 160 4V SINGLE DISC",
          "RTR 160 4V FD ABS",
          "RTR 160 4V RD ABS",
          "RTR 160 2V RD ABS",
          "RTR 180 DRK MAT BLUE BSVI",
          "RTR 180 NEW BSVI",
          "RTR 160 4V DISC R- MODE",
          "RTR 160 4V DISC R- MODE BT",
          "RTR 160 4V DRUM R- MODE",
          "RTR 160 4V SPECIAL EDITION",
          "RTR 165 RP BSVI",
          "RTR 160 RR ABS BSVI",
          "RTR 180 ABS BSVI",
          "RTR 160 2V RM DISC BSVI",
          "RTR 160 2V RM DRUM BSVI",
          "RTR 180 2V RM DISC BSVI",
          "RTR 160 2V RM DISC BT BSVI",
          "RTR 160 4V RM DISC BSVI",
          "RR 310 S",
          "RTT-160 REF",
          "YELLOW REAR DISC BRAKE",
          "STD",
          "180 RTR ES ABS",
          "RR 310",
          "RTR 150 CC",
          "RTR 160 2V BS6",
          "RTR 160 4V CARBURETOR",
          "RTR 160 4V CARBURETOR REAR DISC",
          "RTR 160 4V DISC",
          "RTR 160 4V DISC ABS",
          "RTR 160 4V DISC ABS BSVI",
          "RTR 160 4V DRUM",
          "RTR 160 4V DRUM ABS",
          "RTR 160 4V DRUM ABS BSVI",
          "RTR 160 4V FI",
          "RTR 160 4V FI ABS",
          "RTR 160 ABS",
          "RTR 160 FR DISK",
          "RTR 160 RD",
          "RTR 160 RR",
          "RTR 160 RR YELLOW",
          "RTR 180",
          "RTR 180 BLACK",
          "RTR 200",
          "RTR 200 4V BSVI",
          "RTR 200 ABS",
          "RTR 200 FI",
          "RTR 220",
          "RTR 250",
          "RTR BLACK CASTWHEEL",
          "RTR CASTWHEEL",
          "RTR REFRESH",
          "RTR160 2V DISC BSVI",
          "RTR160 2V DRUM BSVI",
          "STD",
          "RTR 160 4V RM LE DISC",
          "STD",
          "RTR 180 DUAL CHANNEL ABS",
          "RTR 160 DISC BLUETOOTH",
          "RTR 180 BSIV",
          "RTR 180 ABS.",
          "RTR 180 MATTE RED",
          "RTR 160 2V RM DRUM",
          "RTR 165 4V DISC",
          "RTR 160 BS6",
          "RTR 16 RR DISC SYRAH RED",
          "HYPER EDGE",
          "SS",
          "RTR 160",
          "RTR 160 DISC KICK START",
          "150",
          "RTR 160 YELLOW",
          "160 RTR ES",
          "160 RTR ES UPGRADE",
          "160 RTR YELLOW UPGRADE",
          "180 RTR ES",
          "RTR 160 4V BSVI DUAL DISK WITHOUT CONNECTIVITY",
          "RTR 160 4V BSVI DUAL DISK WITH CONNECTIVITY",
          "160 RTR 4V SPECIAL EDITION",
          "RTR 160 2V DISC FD BS6",
          "RTR 160 2V DRUM RD BS6",
          "RTR 200 4V 1CH ABS BS6",
          "RTR 200 4V 2CH ABSÂ BS6",
          "RTR 180 BS6",
          "RTR 200 CARBURETOR",
          "160 RTR SPECIAL EDITION",
          "RTR 200 CARBURETOR R2",
          "RTR 200 4V BS6",
          "RR 310 BS6",
          "ES",
          "KS"
        ]
      },
      {
        "model": "SCOOTY PEP PLUS",
        "variants": [
          "STANDARD BS VI",
          "MATTE EDITION BS VI",
          "SBT",
          "MUDHAL KADHAL",
          "MATTE EDITION - SBT",
          "STD"
        ]
      },
      {
        "model": "SPORT",
        "variants": [
          "STANDARD",
          "SELF START ALLOY BS VI",
          "KICK START ALLOY BS VI",
          "ELECTRIC START ALLOY-SBT",
          "KICK START SPOKE",
          "KICK START ALLOY-SBT",
          "DURALIFE",
          "KS MAG WHEEL BSIV",
          "ES MAG WHEEL BSIV",
          "ES SPECIAL EDTN BSIV",
          "ELS BSVI",
          "DURALIFE BSIV",
          "KLS",
          "ES BSVI",
          "CVTI",
          "STD",
          "ELECTRIC START",
          "STD",
          "ES ALLOY-SBT",
          "KICK START SPOKE SBT",
          "KS MWL",
          "KS SWL",
          "KLS BSVI",
          "ELECTRIC START ALLOY",
          "SELF START ELS ALLOY WHEELS",
          "OBD IIA",
          "100",
          "100 ES MWL DRM",
          "ELECTRIC START LONG SEAT BS6",
          "KICK START LONG SEAT BS6",
          "KLS MWL SBT",
          "CVTI",
          "DLX",
          "KICK START MAG",
          "KICK START",
          "SELF START",
          "ELECTRIC START MAGWHEEL DRUM BRAKE BS VI",
          "KICK START MAGWHEEL DRUM BRAKE BS VI",
          "KICK START SPOKE WHEEL DRUM BRAKE BS VI",
          "ELECTRIC START LONG SEAT ALLOY WHEEL BS VI",
          "KICK START LONG SEAT ALLOY WHEEL BS VI",
          "ELECTRIC START SPOKE WHEEL DRUM BRAKE",
          "ELECTRIC START MAGWHEEL DRUM BRAKE OBD2",
          "ELECTRIC START LONG SEAT ALLOY WHEEL",
          "REF NON SBT ( 100 CC)",
          "KICK START SPOKE WHEEL DRUM BRAKE",
          "KICK START MAGWHEEL DRUM BREAK",
          "ELECTRIC START MAGWHEEL DRUM BREAK"
        ]
      },
      {
        "model": "STAR CITY PLUS",
        "variants": [
          "KICK START MAG",
          "ELECTRIC START MAG",
          "DRUM",
          "DUAL TONE",
          "ELECTRIC START MAG GOLD",
          "ELECTRIC START MAG-SBT",
          "ELECTRIC START MAG GOLD-SBT",
          "DUAL TONE-SBT",
          "KARGIL EDITION",
          "KARGIL EDITION - SBT",
          "MONO TONE BS VI",
          "DUAL TONE BS VI",
          "DISC",
          "ES DT BSVI",
          "SELF START",
          "GOLD EDITION",
          "KICK START"
        ]
      },
      {
        "model": "VICTOR GLX",
        "variants": [
          "DISC",
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "VICTOR GX",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CENTRA",
        "variants": [
          "STANDARD",
          "DRUM BRAKE",
          "STD",
          "STD",
          "DLX",
          "NEW",
          "STD",
          "DRUM"
        ]
      },
      {
        "model": "CHAMP",
        "variants": [
          "STD",
          "50 XL",
          "STD",
          "STD",
          "XL",
          "STD",
          "STD",
          "STD 100",
          "MOPED",
          "MOPED",
          "SUPER",
          "(50 CC) MOPED",
          "SUPER - 60 CC MOPED"
        ]
      },
      {
        "model": "DLX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FIERO F2",
        "variants": [
          "DISC",
          "DRUM",
          "STD"
        ]
      },
      {
        "model": "FIERO FX",
        "variants": [
          "DRUM",
          "DISC"
        ]
      },
      {
        "model": "MAX4R",
        "variants": [
          "100",
          "STANDARD",
          "100R"
        ]
      },
      {
        "model": "SCOOTY ES",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SHAOLIN",
        "variants": [
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "SHOGUN",
        "variants": [
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "SPECTRA",
        "variants": [
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "STAR DELUXE",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "VICTOR",
        "variants": [
          "DRUM",
          "DISC",
          "PREMIUM",
          "DRUM-SBT",
          "ES DISC BS4",
          "(BS IV) ES DISC SBT",
          "ELECTRIC START DISC BRAKE",
          "ELECTRIC START DRUM BRAKE",
          "GLX DRUM BRAKE",
          "GLX DRUM BRAKE",
          "110 DRUM BSIV",
          "110 DISC BSIV",
          "110 DISC REF BSIV",
          "PREMIUM",
          "DLX",
          "110",
          "110 DISC",
          "110 DRUM",
          "EDGE DRUM BRAKE",
          "EDGE DRUM BRAKE MAGNETICWHEEL",
          "GL",
          "GX ALLOYWHEEL",
          "GX MAGNETIC",
          "PREMIUM-SBT",
          "DISC - SBT",
          "DISC BRAKE MAGNETIC",
          "GX",
          "GX ALLOYWHEEL SELF START",
          "GLX DISC BRAKE",
          "GLX DISC BRAKE SELF START",
          "STD",
          "EDGE",
          "GLX DISC BRAKE",
          "GL",
          "GLX",
          "GLX",
          "GX",
          "STD 125 CC",
          "ES DISC 125 CC",
          "ES DRUM 125 CC",
          "GX 125 CC",
          "GL 125 CC",
          "ES ALLOY DISC (110 CC)",
          "ES ALLOY DRUM (110 CC)",
          "GLX DISC (110 CC)",
          "GLX DRUM (110 CC)",
          "GLX DRUM 125 CC",
          "GLX DISC 125 CC",
          "GLX DISC ES 125 CC",
          "EDGE DISK",
          "EDGE DRUM",
          "PREMIUM EDITION"
        ]
      },
      {
        "model": "VICTOR GL",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "XL SUPER",
        "variants": [
          "STANDARD",
          "SPECIAL EDITION",
          "HD",
          "HEAVY DUTY",
          "STD",
          "STD",
          "HEAVY DUTY",
          "MOPED",
          "HEVY DUTY (70 CC) MOPED",
          "STD (70 CC) MOPED",
          "60 CC MOPED"
        ]
      },
      {
        "model": "HEAVY DUTY SUPER XL",
        "variants": [
          "STANDARD",
          "SPECIAL EDITION"
        ]
      },
      {
        "model": "STAR CITY",
        "variants": [
          "ALLOY WHEELS",
          "SPOKE WHEELS",
          "PLUS 110 KS MWL BSIV",
          "ELECTRIC START ALLOY WHEELS",
          "ELECTRIC START MAG WHEELS",
          "ELECTRIC START SPOKE WHEELS",
          "KICK START MAG WHEELS",
          "KICK START SPOKE WHEELS",
          "MW - ELECTRIC START",
          "PLUS ELECTRIC START MAG WHEEL",
          "PLUS KICK START MAG WHEEL",
          "PLUS 110 ES BSIV",
          "PLUS 110 ES GOLD BSIV",
          "PLUS 100 ES DUEL TONE BSIV",
          "PLUS 110 DUEL SBT",
          "PLUS 110 ES BSVI",
          "PLUS 110 DT BSVI",
          "PLUS ES DISC BS VI",
          "SPORTS DLX",
          "SPORTS MW",
          "KICK START ALLOY WHEEL",
          "KICK START",
          "SELF START ALLOY WHEEL",
          "SELF START",
          "SPORT DLX",
          "STD",
          "SELF START",
          "SPORT",
          "PLUS ALLOY WHEEL  ES (110 CC) BS VI",
          "PLUS STD BS VI",
          "ALLOY ES (110CC)",
          "DRUM BRAKE BS VI 110 CC",
          "DISC BRAKE BS VI 110 CC",
          "PLUS DUAL TONE DRUM ALLOY WHEEL",
          "ELECTRIC START",
          "MAG WHEEL",
          "PLUS STD",
          "PLUS ES BS VI",
          "SPORT 110 CC",
          "SPORT ES 110 CC",
          "SPORT MAG WHEEL 110 CC",
          "PLUS ES DISC OBD2",
          "PLUS DUAL TONE DRUM OBD2",
          "PLUS ES DRUM OBD2",
          "SPOKE ES (110 CC)",
          "SPOKE KS (110 CC)",
          "MAG ES (110 CC)",
          "MAG KS (110 CC)",
          "ES MW",
          "PLUS MAG ES GOLD EDITION",
          "KARGIL EDI",
          "PLUS MAG KS (110 CC)",
          "PLUS MAG ES (110 CC)"
        ]
      },
      {
        "model": "APACHE RTR 160",
        "variants": [
          "SINGLE DISC (NEW)",
          "SINGLE DISC MATTE RED",
          "FRONT DISC ABS BS VI",
          "REAR DISC BRAKE",
          "WHITE RACE EDITION DRUM",
          "REAR DISC-ABS",
          "REAR DISC MATTE RED",
          "WHITE RACE EDITION DISC",
          "REAR DISC ABS BS VI",
          "2V BSVI",
          "DISC",
          "STD",
          "2V DISC ABS BSVI",
          "2V DRUM ABS BSVI",
          "REAR DISC",
          "DRUM ABS",
          "4V RM DRUM BS VI",
          "DRUM",
          "4V EFI",
          "FRONT DISC ABS",
          "FRONT DISC",
          "4V DISC BS6",
          "2V DISC",
          "4V HP DISC BS VI",
          "4V DRUM BS6",
          "4V DISC RM LE BS VI",
          "STD",
          "4V RM DISC BT",
          "4V RM DISC BS6",
          "4V RM SPECIAL EDITION",
          "2V RM DISC OBD2",
          "2V RM DISC BT",
          "UPGRADE",
          "2V RM DRUM OBD2",
          "4V RM DISC OBD2",
          "4V RM SPECIAL EDITION OBD2",
          "2V RM DISC",
          "2V RR ABS",
          "4V RR DISC ABS",
          "2V ABS",
          "4V RR DRUM",
          "EFI RR DISK",
          "FR NEW",
          "FR MATT RED",
          "WHITE SPL FD",
          "WHITE SPL RD",
          "AV-DRUM",
          "FRONT AND REAR DISC",
          "RTR ES",
          "RTR FI"
        ]
      },
      {
        "model": "FLAME",
        "variants": [
          "SR 125 ES",
          "SR 125 KS",
          "DISC BRAKE",
          "MOTORCYCLE",
          "SR 125 - ELEC START DISC BRAKE",
          "KS",
          "DS",
          "SR 125",
          "SR 125 DISC BRAKE",
          "STD",
          "DS 125 - ELECTRIC START DRUM",
          "125 ES DRUM",
          "125 KS DRUM",
          "DS 125 - ELECTRIC START  DISC",
          "SR 125 - ELECTRIC START  DISC",
          "125 ES DISC",
          "SR 125 - ELECTRIC START DRUM"
        ]
      },
      {
        "model": "SCOOTY STREAK",
        "variants": [
          "STANDARD",
          "STD",
          "FASHION",
          "REFRESH",
          "STD"
        ]
      },
      {
        "model": "APACHE RTR 180",
        "variants": [
          "STANDARD",
          "STD R 2.0",
          "NEW STANDARD",
          "BS VI",
          "DISC BLUETOOTH",
          "STD",
          "2V BS VI",
          "STD BS VI",
          "RM DISC BT",
          "U 327",
          "REF MATT RED",
          "2V ABS"
        ]
      },
      {
        "model": "JIVE",
        "variants": [
          "STANDARD",
          "MAG ES",
          "REFRESH",
          "STD",
          "ELECTRIC START",
          "ES",
          "KS",
          "KICK START",
          "SELF START"
        ]
      },
      {
        "model": "WEGO",
        "variants": [
          "STANDARD",
          "WEGO",
          "BSIV",
          "110 CC",
          "BASIC",
          "DRUM",
          "UPG",
          "DISC",
          "DISC BRAKE",
          "STD",
          "110 CC SCOOTER",
          "VS",
          "DISC 110 CC SCOOTER"
        ]
      },
      {
        "model": "PHOENIX",
        "variants": [
          "125 DRUM",
          "125 DISC",
          "ELEC ST DISC BRAKE AWS",
          "ELEC ST DRUM BRAKE AWS",
          "DRUM",
          "DISC",
          "125",
          "DISC BRAKE",
          "STD",
          "SELF START DRUM BREAK ALLOY WHEEL",
          "SELF START DISK BREAK ALLOY WHEEL"
        ]
      },
      {
        "model": "JUPITER",
        "variants": [
          "ZX SBT",
          "CLASSIC EDITION SBT",
          "ZX DRUM BS VI",
          "STANDARD BS VI",
          "CLASSIC BS VI",
          "SHEET METAL WHEEL",
          "ZX DISC SBT",
          "GRANDE DISC SBT",
          "STANDARD SBT",
          "ZX INTELLIGO SX M",
          "ZX INTELLIGO SX C BRONZE",
          "ZX DRUM SMARTXONNECT",
          "ELEC ST DISC BRAKE AWS",
          "ELEC ST DRUM BRAKE AWS",
          "ZX BSIV",
          "LE MLN EDTN BSIV",
          "CLASSIC DISC BSIV",
          "CLASSIC ET BSVI",
          "BSVI",
          "RACE EDITION",
          "BSIII",
          "ZX DISC WITH INTELLIGO",
          "ZX DRUM AOL",
          "CLASSIC DISC BSVI",
          "125 DRUM BSVI",
          "125 DRUM ALLOY BSVI",
          "125 DISC BSVI",
          "125 ALLOY DISC HQ BSVI",
          "JUPITER STD AOL",
          "125 ALLOY DISC BS6",
          "ZX DISC SXC BLUETOOTH",
          "125 SMW BSVI",
          "SMW BSVI",
          "SHEET METAL ALLOY BSVI",
          "STANDARD BSVI",
          "CLASSIC ET FI BSVI",
          "ZX SMARTXONNECT BSVI",
          "CLASSIC DISC BSVI",
          "CLASSIC DISC 110 OBD2",
          "CLASSIC EDITION",
          "MILLIONR SE",
          "STD",
          "ZX",
          "ZX DISC",
          "LE/ZX BSIV",
          "ELEC ST DISC BRAKE AWS",
          "STD",
          "CLASSIC BSVI",
          "GRANDE EDITION",
          "LE",
          "MILLIONR",
          "STD",
          "STD BSVI",
          "ZX BSVI",
          "ZX DISC BRAKE",
          "ZX DISC",
          "BS IV...",
          "ZX DISC BS VI",
          "ZX DRUM BS VI",
          "SMW",
          "GRANDE DISC",
          "GRANDE",
          "ZX",
          "125 BSVI",
          "BSIV",
          "125 DRUM STEEL WHEEL",
          "125 DRUM ALLOY WHEEL",
          "125 DISC ALLOY WHEEL",
          "ZX - OBDIIA DRUM SX",
          "ZX DISC INTELLIGO SX-OBDIIA",
          "CLASSIC BSVI DISC",
          "CLASSIC BSVI SPACE PURPLE",
          "JUPITER BSIV",
          "ZX BS VI DRUM",
          "BSVI - AOL",
          "ZX DISC SMARTXONNECT",
          "110",
          "ZX DISC WITH INTELLIGO BS6",
          "SHEET METAL WHEEL BS6",
          "DRUM ZX BS6",
          "ZX DISC BS6",
          "SELF START",
          "STD AOL BS VI",
          "TITANIUM BS VI",
          "BASIC BS VI",
          "GRAND DISC BS VI",
          "DRUM BRAKE",
          "ZX DRUM ALLOY BS VI",
          "ZX INTELLIGO",
          "ZX SMARTXONNECT",
          "ZX DISC OBD2",
          "CLASSIC EDITION",
          "ZX AOL DRUM BS VI",
          "SMW OBD2",
          "MAG",
          "BASIC",
          "TITANIUM",
          "ZX DRUM",
          "DISC BRAKE",
          "DISC BRAKE LE",
          "SELF START DRUM BREAK ALLOY WHEEL",
          "SPECIAL EDITION"
        ]
      },
      {
        "model": "SCOOTY ZEST 110",
        "variants": [
          "SPECIAL EDITION BSIV",
          "MATTE-SBT",
          "HIMALAYAN HIGHS-SBT",
          "MATTE",
          "HIMALAYAN HIGHS"
        ]
      },
      {
        "model": "XL 100",
        "variants": [
          "STANDARD",
          "HD I TOUCH START BS6",
          "HEAVY DUTY BS IV",
          "COMFORT BS VI",
          "COMFORT I TOUCH START",
          "HEAVY DUTY KICK START BS VI",
          "HEAVY DUTY I TOUCH START BS VI",
          "HEAVY DUTY I- TOUCH START SPECIAL EDITION",
          "HEAVY DUTY I TOUCH START",
          "HEAVY DUTY I- TOUCH START SPECIAL EDITION BS VI",
          "COMFORT I TOUCH START BS VI",
          "HEAVY DUTY I TOUCH WINNER EDITION BS VI",
          "COMFORT KICK START BS VI",
          "HEAVY DUTY BS VI",
          "HEAVYDUTY I-TOUCHSTART WIN EDITION",
          "WIN EDITION",
          "HEAVY DUTY ELECTRIC START",
          "HEAVY DUTY",
          "100 CC MOPED"
        ]
      },
      {
        "model": "APACHE RTR 200 4V",
        "variants": [
          "STANDARD",
          "FI R 2.0",
          "ABS R 2.0",
          "DUAL CHANNEL ABS BS VI",
          "CARBURETOR",
          "CARBURETOR R 2.0",
          "CARBURETOR WITH PIRELLI TYRES",
          "CARBURETOR WITH PIRELLI TYRES R 2.0",
          "FI",
          "ABS",
          "FI E100",
          "CARBURETOR ABS SMART CONNECT",
          "SINGLE CHANNEL ABS BS VI",
          "DUAL CHANNEL ABS WITH MODES",
          "PIRELLI",
          "SINGLE CHANNEL ABS WITH MODES"
        ]
      },
      {
        "model": "NTORQ 125",
        "variants": [
          "STANDARD",
          "DISC BS VI",
          "SUPER SQUAD EDITION - DISC",
          "DRUM BS VI",
          "RACE XP",
          "XT"
        ]
      },
      {
        "model": "APACHE RR310",
        "variants": [
          "STANDARD",
          "SLIPPER CLUTCH",
          "BS VI"
        ]
      },
      {
        "model": "XL 100 COMFORT",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "XL 100 HEAVY DUTY",
        "variants": [
          "STANDARD",
          "I-TOUCH BS VI",
          "I-TOUCH SPECIAL EDITION BS VI",
          "I-TOUCH START WINNER EDITION",
          "STD"
        ]
      },
      {
        "model": "RADEON",
        "variants": [
          "DRUM BS VI",
          "DISC SPECIAL EDITION SBT",
          "DRUM SPECIAL EDITION SBT",
          "COMMUTER BIKE OF THE YEAR DRUM",
          "COMMUTER BIKE OF THE YEAR DISC",
          "DRUM BRAKE",
          "DRUM SPL EDTN",
          "DRUM BRAKE BSVI",
          "DISC BRAKE BSVI",
          "STD BSVI",
          "110 ES MAG BSVI",
          "110 ES MAG REF BSVI",
          "110 DUAL TONE BSVI",
          "110 DISC ES DIGI BSVI",
          "110 DRUM ES DIGI BSVI",
          "110",
          "125",
          "DISC SPECIAL EDITION",
          "DRUM ES ALLOY BS6",
          "DRUM SPECIAL EDITION",
          "STANDARD",
          "DUAL TONE DRUM",
          "DUAL TONE DISC",
          "DRUM BS VI",
          "DISC ES ALLOY BS6",
          "DUAL TONE DISC BS VI",
          "DUAL TONE DRUM BS VI",
          "110 ELECTRIC START MAG BS VI",
          "110 ELECTRIC START MAG REF BS VI",
          "SELF START DRUM BRAKE BS VI",
          "SELF START DRUM BRAKE SPECIAL BS VI",
          "SELF START DISC BRAKE SPECIAL BS VI",
          "SELF START DRUM BREAK ALLOY WHEEL BS VI",
          "SELF START DRUM BRAKE ALLOY WHEEL REF BS VI",
          "SELF START DISC BRAKE ALLOY WHEEL BS VI",
          "COTY DRUM VI",
          "COTY DISC VI",
          "BASE EDITION VI",
          "SELF START MAG DRUM OBD2",
          "DUAL TONE DIGI DISC",
          "DUAL TONE DIGI DRUM",
          "DUAL TONE DIGI DRUM OBD2",
          "SELF START DRUM BREAK ALLOY WHEEL"
        ]
      },
      {
        "model": "JUPITER GRANDE",
        "variants": [
          "DRUM",
          "DISC",
          "DRUM"
        ]
      },
      {
        "model": "METRO",
        "variants": [
          "ES MWL EXP CKD BLK PLUS BLUE",
          "ES MWL EXP CKD BLK PLUS RED",
          "100 KS MWL DRM CKD BLACK PLUS RED",
          "100",
          "STD",
          "PLUS",
          "ES"
        ]
      },
      {
        "model": "FIERO",
        "variants": [
          "DISC",
          "DRUM",
          "F2 ES",
          "STD",
          "F2 DRUM",
          "F2 SWP",
          "FX DRUM",
          "F2",
          "FX",
          "FIERO F2 DISK BRAKE",
          "FIERO F2 DRUM BRAKE",
          "FIERO FX DRUM",
          "FIERO FX DISC",
          "FIERO FX DISC ES"
        ]
      },
      {
        "model": "VICTOR EDGE",
        "variants": [
          "DISC",
          "DRUM"
        ]
      },
      {
        "model": "NEO",
        "variants": [
          "STANDARD",
          "X3I"
        ]
      },
      {
        "model": "IQUBE",
        "variants": [
          "STANDARD",
          "BASIC",
          "S",
          "ST",
          "E BIKE",
          "S LITHIUM ION 52V",
          "ST LITHIUM ION 52V",
          "52V",
          "SMARTXONNECT BS6",
          "S BS6",
          "SMARTXONNECT",
          "S ELECTRIC SCOOTER",
          "ST",
          "S",
          "STD",
          "LITHIUM BATTERY",
          "ST",
          "S"
        ]
      },
      {
        "model": "RAIDER",
        "variants": [
          "DISC 125 BLACK",
          "DISC 125 HARLEQUIN BLUE",
          "DISC RED",
          "DRUM BSVI",
          "DISC BSVI",
          "DISC SX 125 WICKED BLACK",
          "STD",
          "DISC 125 BSVI",
          "DRUM 125 BSVI",
          "DISC 125 BT BSVI",
          "125 SMARTXONNECT",
          "OBD IIA DISC SX 125",
          "125 DISC BS6",
          "125 DRUM BS6",
          "ALLOY WHEEL DISC BRAKE",
          "ALLOY WHEEL DRUM BRAKE",
          "SMARTXCONNECT",
          "SX DISC BRAKE OBD",
          "DISC BRAKE OBD"
        ]
      },
      {
        "model": "STAR",
        "variants": [
          "SPORTS ECONOTECH",
          "SPORT ES AW",
          "HLX ES 125",
          "SPORT CVTI",
          "DLX",
          "SPORT",
          "HLX 100",
          "HLX 150",
          "DELUX STANDARD",
          "CITY",
          "CITY SPOKE WHEEL KS",
          "CITY CARBONBLACK",
          "CITY JETRED",
          "CITY SS",
          "CITY KS",
          "SPORT CVTI ES",
          "SPORT KS",
          "110",
          "SPORT DLX",
          "STD",
          "SPORT ES BS VI",
          "HLX 125",
          "SPORT 125 ES",
          "SPORT ES"
        ]
      },
      {
        "model": "XL",
        "variants": [
          "100 HD I-TOUCH START-BSVI M GREEN",
          "100 HD I-TOUCH START BSVI SPL ADDITION",
          "STD",
          "100 HD ES",
          "HD ITOUCH START",
          "COMFORT ITOUCH START BSVI",
          "HD ITOUCH START BSVI",
          "100 WINNER EDITION",
          "100 HD KS BSVI",
          "100 HD ITS SPL EDITION",
          "100 MOPED",
          "100 HD BSVI",
          "100 ITOUCH WINNER EDTN BSVI",
          "100 ITOUCH SPL EDTN BSVI",
          "HD",
          "SUPER",
          "STD",
          "100 CC BSIV",
          "100 BSVI",
          "100 COMFORT",
          "HD",
          "HD I TOUCH",
          "I-TOUCH BSVI",
          "SUPER HD",
          "100 COMFORT I TOUCH START BS6",
          "50",
          "100",
          "100 HD STD BS6",
          "100 COMFORT BS6",
          "HD I TOUCH START WINNER EDITION BS6",
          "HD I TOUCH BS6",
          "HD I TOUCH SPECIAL EDITION BS6",
          "COMFORT I TOUCH BSVI",
          "100 STD",
          "100 HD"
        ]
      },
      {
        "model": "SPORT CVTI",
        "variants": [
          "100 KS MWL DRM MERCURY GREY"
        ]
      },
      {
        "model": "RONIN 2CH",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RONIN 1CH",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RAIDER 125",
        "variants": [
          "SUPER SQUAD EDITION"
        ]
      },
      {
        "model": "X",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "AKULA",
        "variants": [
          "310",
          "STD"
        ]
      },
      {
        "model": "MAX",
        "variants": [
          "100 STD",
          "100",
          "4R",
          "125 STD",
          "DLX",
          "STD",
          "100 R",
          "4R KICK START",
          "4R LX"
        ]
      },
      {
        "model": "ROCKZ",
        "variants": [
          "STD",
          "125"
        ]
      },
      {
        "model": "SUZUKI FIERO",
        "variants": [
          "ELECTRIC START",
          "F2 DISC BRAKE",
          "F2 DRUM BRAKE",
          "FX DISC BRAKE",
          "STD"
        ]
      },
      {
        "model": "SUZUKI",
        "variants": [
          "STD",
          "SAMURAI METALLIC",
          "SHOGUN SILVER",
          "AX-100 R",
          "SHAOLIN."
        ]
      },
      {
        "model": "VELOCITY",
        "variants": [
          "160",
          "160 STREET FIGHTER"
        ]
      },
      {
        "model": "STRYKER",
        "variants": [
          "125"
        ]
      },
      {
        "model": "RONIN",
        "variants": [
          "SINGLE CHANNEL SINGLE TONE",
          "SINGLE CHANNEL DUAL TONE",
          "DUAL CHANNEL TRIPLE TONE",
          "DUAL TONE",
          "SINGLE TONE",
          "TRIPLE TONE",
          "TD DUAL CHANNEL ABS",
          "SS SINGLE CHANNEL ABS",
          "DS SINGLE CHANNEL ABS",
          "TRIPLE TONE DUAL CHANNEL",
          "DUAL TONE SINGLE CHANNEL",
          "SINGLE TONE SINGLE CHANNEL",
          "SINGLE TONE SINGLE CHANNEL OBD2",
          "DUAL TONE SINGLE CHANNEL OBD2",
          "TRIPLE TONE DUAL CHANNEL OBD2"
        ]
      },
      {
        "model": "MAX 4R",
        "variants": [
          "KICK ST DRUM BRAKE SW",
          "STD",
          "SPOKE WHEEL DRUM BREAK KICK START"
        ]
      },
      {
        "model": "SCOOTY",
        "variants": [
          "ELECTRIC START",
          "PEP PLUS",
          "PEP STD",
          "PEP PLUS REF BSIV",
          "PEP PLUS REF COL BSIV",
          "ZEST BSIV",
          "ZEST MATT SERIES BSIV",
          "PEP PLUS MATT EDITION",
          "ZEST BSVI",
          "PEP PLUS",
          "ZEST 110 BSVI",
          "PEP PLUS MATT EDITION BSVI",
          "PEP PLUS STD BSVI",
          "PEP PLUS PRINCESS BSVI",
          "STREAK RE FRESH",
          "SPORT",
          "PEP REFRESH",
          "PEP TEENZ",
          "STD",
          "90 TEENZ",
          "ELECTRIC",
          "PEP",
          "PEP +",
          "PEP + COLOR ME",
          "PEP PLUS BS6",
          "PEP+ COLLECTION",
          "PEP+ FASHION",
          "PEP+ OLD COLOURS",
          "PEP+ PINK",
          "PEP+ REF PINK",
          "PEP+ STREEK",
          "PEP+ STREEK FASHION",
          "SS",
          "STD",
          "STREAK",
          "STREAK HYBRID",
          "STREAK SIL REF",
          "TEENZ 60CC",
          "WIMBLEDON",
          "ZEST",
          "ZEST BS6",
          "TEENZ",
          "PEP PLUS BS VI",
          "PEP PLUS MATTE EDITION BS VI",
          "STREAK",
          "FASHION",
          "KS",
          "PEP+ RED",
          "STREAK REFRESH",
          "PEP PLUS MATTE EDITION BS6",
          "PEP PLUS GLOSSY BS6",
          "KICK START",
          "SELF START",
          "PEP PLUS HANDICAPED VEHICLE",
          "PEP PLUS GLOSS SERIES BS VI",
          "PEP PLUS PRICELESS PINK BS VI",
          "ZEST GLOSS",
          "PEP DLX (90 CC)",
          "ZEST MATTE",
          "ZEST LE",
          "PEP PLUS (75 CC)",
          "ZEST",
          "KICK START (60 CC)",
          "ELECTRIC START (60 CC)",
          "TEENZ ELECTRIC VEHICLE"
        ]
      },
      {
        "model": "SCOOTY (EV)",
        "variants": [
          "TEENZ ELECTRIC START",
          "TEENZ (EV)",
          "TEENZ (EV) - ELECTRIC START"
        ]
      },
      {
        "model": "ZEST",
        "variants": [
          "SCOOTY STD",
          "110",
          "SCOOTY ZEST MATTE SERIES BS6",
          "SBT MATTE BLUE BS VI",
          "MATTE SERIES",
          "GLOSS",
          "STD BS VI",
          "MATTE BS VI"
        ]
      },
      {
        "model": "NTORQ",
        "variants": [
          "DRUM",
          "DISC",
          "DISC BSVI",
          "SUPER SQUAD BSVI",
          "IRON MAN EDITION",
          "SUPER SQUAD 125 DISC",
          "RACE EDITION 125 DISC BSVI",
          "DRUM BSVI",
          "REV UP",
          "RACE XP BSVI",
          "XT BSVI",
          "125 STD",
          "BSIV",
          "125 DISC - HBS",
          "125 DISC BS6",
          "125 DRUM",
          "125 RACE EDITION",
          "125 RACE EDITION BSVI",
          "STD",
          "RACE EDITION",
          "RACE EDITION BS VI",
          "125 RACE EDITION BS6",
          "125 XT BS6",
          "125 RACE XP",
          "NTORQ RACE EDITION BS6",
          "125 SUPER SQUAD EDITION",
          "DRUM BS6",
          "125 DISC",
          "ALLOY WHEEL DRUM BRAKE",
          "SUPER SQUAD EDITION",
          "ALLOY WHEEL DISC BRAKE BS VI",
          "ALLOY WHEEL DISC BRAKE HBS",
          "ALLOY WHEEL DRUM BRAKE BS VI",
          "RACE XP EDITION BS VI",
          "RACE EDITION XP BLUETOOTH BS VI",
          "RACE EDITION XP OBD2",
          "SUPER SQUAD EDITION OBD2",
          "XT ALLOY WHEEL DISC BRAKE BS VI",
          "XT ALLOY WHEEL DISC BRAKE OBD2",
          "ALLOY WHEEL DRUM BRAKE HBS",
          "ALLOY WHEEL DISC BRAKE"
        ]
      },
      {
        "model": "TEENZ",
        "variants": [
          "E BIKE",
          "E BIKE",
          "STD"
        ]
      },
      {
        "model": "STREAK",
        "variants": [
          "SCOOTY",
          "STANDARD"
        ]
      },
      {
        "model": "TORMAX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "GRAPHITE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "AX 100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "BIKE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SUPRA",
        "variants": [
          "SELF START",
          "SS"
        ]
      },
      {
        "model": "SPECTRA EX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SAMURAI",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TVS STAR",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TVS JIVE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XL50",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SUZUKI SHAOLIN",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SUZUKI SHOGUN",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PHOENIX 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TVS WEGO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SCOOTY PEP.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SCOOTY ZEST",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "APACHE RTR",
        "variants": [
          "STD",
          "160 FI",
          "160 DISC BRAKE",
          "160 SELF START",
          "180 ABS",
          "180 STD",
          "200 4V",
          "200 4V FUEL INJECTION"
        ]
      },
      {
        "model": "SCOOTY TEENZ ELECTRIC BIKE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MAX 100.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FLAME 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VICTOR.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "APACHE RR 310",
        "variants": [
          "STD",
          "STD BS VI",
          "STD"
        ]
      },
      {
        "model": "XL SUPER HD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TVS STAR CITY",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "IQUBE ELECTRIC",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TVS EV 2",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TVS RAIDER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TVS NTORQ 125 RACE EDITION",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TVS SCOOTY ZEST",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "VILAS TEST",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TVS VICTOR EDGE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "TVSTAET",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "SPORTZ",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STARCITY",
        "variants": [
          "110 MAG ES",
          "ES BSVI",
          "ES MAG WHEEL",
          "KS ALLOY WHEEL",
          "KS MAG",
          "PLUS",
          "PLUS DUAL TONE",
          "PLUS DUAL TONE BSVI",
          "PLUS ES MAG",
          "PLUS KARGIL EDITION",
          "PLUS BSVI ES DRUM",
          "ES ALLOY WHEEL",
          "ES SPOKE WHEEL",
          "110 SPK ES",
          "ES SWHL",
          "ES MWHL",
          "KS SP",
          "ES MAG",
          "DT KS SP",
          "DT KS MAG",
          "PLUS KS MAG",
          "PLUS ES DISC BS6",
          "PLUS MONO TONE BS6",
          "ALLOY WHEEL"
        ]
      },
      {
        "model": "APACHE RTR 200",
        "variants": [
          "4V 2CH ABS BS VI",
          "4V ABS BSVI",
          "4V SCH ABS BS VI",
          "4V SCH R-MODE ABS BS VI",
          "4V 2CH R-MODE ABS BS VI",
          "R 2.0 CARB",
          "R 2.0 4V FI",
          "R 2.0 4V PIRELLI",
          "R 2.0 4V",
          "4V",
          "ABS PIRELLI",
          "R 2.0 4V ABS",
          "R 2.0 CURB",
          "4V EFI"
        ]
      },
      {
        "model": "DAZZ",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "APACHE RTR 160 4V",
        "variants": [
          "EFI-ABS"
        ]
      },
      {
        "model": "RTR 165 RP",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SUPER",
        "variants": [
          "XL"
        ]
      },
      {
        "model": "ASTRA",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SCOOTY PEP",
        "variants": [
          "DLX",
          "PLUS",
          "PLUS"
        ]
      },
      {
        "model": "SCOOTY TEENZ",
        "variants": [
          "E BIKE",
          "STD"
        ]
      },
      {
        "model": "TVS 50",
        "variants": [
          "XL"
        ]
      },
      {
        "model": "JUPITER 125",
        "variants": [
          "DRUM",
          "DRUM ALLOY",
          "DISC",
          "DISC ALLOY"
        ]
      },
      {
        "model": "TORMAX 150",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "APACHE RTR 250",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "MAX 100",
        "variants": [
          "DX"
        ]
      }
    ]
  },
  {
    "make": "TWENTY TWO MOTOR",
    "models": [
      {
        "model": "BOUNCE INFINITY E1",
        "variants": [
          "LITHIUM ION BATTERY"
        ]
      }
    ]
  },
  {
    "make": "TX9",
    "models": [
      {
        "model": "FT 350",
        "variants": [
          "LX",
          "DX",
          "DX PLUS",
          "VX"
        ]
      },
      {
        "model": "FT 450",
        "variants": [
          "LX LITHIUM 60V 50AH",
          "VX LITHIUM 60V 60AH"
        ]
      },
      {
        "model": "FT 250",
        "variants": [
          "LITHIUM ION 48V 30AH"
        ]
      },
      {
        "model": "FT350",
        "variants": [
          "DX PLUS",
          "LX"
        ]
      }
    ]
  },
  {
    "make": "TYLOS",
    "models": [
      {
        "model": "PHOENIX",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "TYST DRIVE INDIA PVT LTD",
    "models": [
      {
        "model": "TRIGGER 1 0",
        "variants": [
          "LIFEPO4 72V 30AH"
        ]
      }
    ]
  },
  {
    "make": "UCE",
    "models": [
      {
        "model": "E SCOOTERÂ B6",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "E SCOOTER B6 PLUS",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "E BIKE",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "UJAAS",
    "models": [
      {
        "model": "EGO",
        "variants": [
          "LA 48V",
          "LA 60V",
          "LI 48V",
          "LI 60V",
          "LA 48V",
          "LI 48V",
          "LI 60V",
          "T3 72V",
          "T3 60V",
          "DB LI STANDARD",
          "LEAD ACID BATTERY",
          "DB LITHIUM BATTERY",
          "T3 LEAD ACID BATTERY",
          "DB LEAD ACID BATTERY",
          "LA 60V 26AH 250WATT",
          "LI 60V 26AH 250WATT",
          "LA 48V 26AH 250WATT",
          "LI 48V 26AH 250WATT"
        ]
      },
      {
        "model": "ESPA",
        "variants": [
          "LA 60V",
          "LI 60V",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "EGO DB LI",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "EGO T3",
        "variants": [
          "72V",
          "60V"
        ]
      },
      {
        "model": "EZY",
        "variants": [
          "LA 48V"
        ]
      }
    ]
  },
  {
    "make": "UJAAS ENERGY LTD",
    "models": [
      {
        "model": "E SPA",
        "variants": [
          "E BIKE",
          "LA",
          "LI",
          "LA 60V",
          "LI 60V",
          "XL LA 72V"
        ]
      },
      {
        "model": "E GO",
        "variants": [
          "E BIKE",
          "DB LA",
          "DB LI",
          "T3 LA",
          "LA 48V",
          "LA 60V",
          "LI 48V",
          "LI 60V",
          "T3 LA 72V",
          "T3 LIFE 60V"
        ]
      },
      {
        "model": "E ZY",
        "variants": [
          "LA",
          "LA 48V"
        ]
      },
      {
        "model": "EGO",
        "variants": [
          "STD",
          "T3I",
          "T3",
          "LI",
          "LA",
          "DB LA STANDARD"
        ]
      },
      {
        "model": "ESPA",
        "variants": [
          "MINI",
          "XL",
          "LI",
          "LA",
          "LA 60V",
          "LI 60V"
        ]
      },
      {
        "model": "EZY",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "UKAVA",
    "models": [
      {
        "model": "RAPID",
        "variants": [
          "60V"
        ]
      },
      {
        "model": "WINK",
        "variants": [
          "48V"
        ]
      },
      {
        "model": "ROYAL",
        "variants": [
          "60V"
        ]
      },
      {
        "model": "STORM",
        "variants": [
          "48V"
        ]
      }
    ]
  },
  {
    "make": "ULTRA MOTORS",
    "models": [
      {
        "model": "VELOCITI",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "MARATHON",
        "variants": [
          "STANDARD",
          "LITE",
          "STD",
          "EBIKE"
        ]
      },
      {
        "model": "MAXI",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "OPTIMA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "VELOCITY",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "FUN",
        "variants": [
          "STD",
          "E SCOOTER"
        ]
      },
      {
        "model": "SHAKTI",
        "variants": [
          "STD",
          "E SCOOTER"
        ]
      },
      {
        "model": "VELO PLUS",
        "variants": [
          "STD",
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ULTRAVIOLETTE",
    "models": [
      {
        "model": "F77",
        "variants": [
          "ORIGINAL",
          "RECON",
          "LIMITED EDITION",
          "STANDARD",
          "RECON"
        ]
      },
      {
        "model": "F 77",
        "variants": [
          "LIMITED",
          "RECON",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "UM MOTORCYCLE",
    "models": [
      {
        "model": "RENEGADE COMMANDO",
        "variants": [
          "FI"
        ]
      },
      {
        "model": "RENEGADE CLASSIC",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "RENEGADE SPORTS S",
        "variants": [
          "FI"
        ]
      },
      {
        "model": "RENEGADE",
        "variants": [
          "CLASSIC",
          "MOJAVE STANDARD",
          "DUTY",
          "SPORTS"
        ]
      },
      {
        "model": "XTREET",
        "variants": [
          "250 X"
        ]
      }
    ]
  },
  {
    "make": "UM MOTORCYCLES",
    "models": [
      {
        "model": "RENEGADE",
        "variants": [
          "COMMANDO",
          "DUTY",
          "LIMITED",
          "SPORT",
          "COMMANDO",
          "SPORTS S"
        ]
      },
      {
        "model": "UM RENEGADE",
        "variants": [
          "DUTY"
        ]
      },
      {
        "model": "XTREET",
        "variants": [
          "230",
          "230 R"
        ]
      }
    ]
  },
  {
    "make": "UMA AUTO INDUSTRIES",
    "models": [
      {
        "model": "VEETA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "TRIGGO",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "UNICORN DIGITAL SYSTEM PVT LTD",
    "models": [
      {
        "model": "UNICORN 250 ZS",
        "variants": [
          "60V/25A"
        ]
      },
      {
        "model": "UNICORN 250 NH",
        "variants": [
          "60V/30A"
        ]
      }
    ]
  },
  {
    "make": "UNITED",
    "models": [
      {
        "model": "ELECTRICA",
        "variants": [
          "250"
        ]
      }
    ]
  },
  {
    "make": "UP TELELINKS LTD",
    "models": [
      {
        "model": "SNOW",
        "variants": [
          "48 VOLT 24 AH",
          "48 VOLT 24 AH LITHIUM",
          "60 VOLT 24 AH",
          "60 VOLT 24 AH LITHIUM"
        ]
      },
      {
        "model": "CHEER",
        "variants": [
          "48 VOLTS 24 AH LEAD ACID",
          "48 VOLTS 24 AH LITHIUM ION",
          "60 VOLTS 24 AH LEAD ACID",
          "60 VOLTS 24 AH LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "URBAN E BIKES",
    "models": [
      {
        "model": "FALCON",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LIFEPO4 48V 30AH",
          "LIFEPO4 60V 30AH"
        ]
      },
      {
        "model": "OPTIMA",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LIFEPO4 48V 30AH",
          "LIFEPO4 60V 30AH"
        ]
      },
      {
        "model": "DESTINY",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LIFEPO4 48V 30AH",
          "LIFEPO4 60V 30AH"
        ]
      },
      {
        "model": "LYRA",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LIFEPO4 48V 30AH",
          "LIFEPO4 60V 30AH"
        ]
      },
      {
        "model": "ASTER",
        "variants": [
          "LEAD ACID 48V 28AH",
          "LEAD ACID 60V 28AH",
          "LIFEPO4 48V 30AH",
          "LIFEPO4 60V 30AH"
        ]
      },
      {
        "model": "PHANTOM",
        "variants": [
          "LITHIUM",
          "LED"
        ]
      }
    ]
  },
  {
    "make": "URBAN E-BIKE",
    "models": [
      {
        "model": "FALCON",
        "variants": [
          "48V-28AH",
          "60V-28AH",
          "60V-24AH",
          "60V-30AH"
        ]
      },
      {
        "model": "OPTIMA",
        "variants": [
          "48V-28AH",
          "60V-28AH",
          "60V-24AH",
          "60V-30AH"
        ]
      },
      {
        "model": "DESTINY",
        "variants": [
          "48V-28AH",
          "60V-28AH",
          "60V-24AH",
          "60V-30AH"
        ]
      },
      {
        "model": "LYRA",
        "variants": [
          "48V-28AH",
          "60V-28AH",
          "60V-24AH",
          "60V-30AH"
        ]
      },
      {
        "model": "ASTER",
        "variants": [
          "48V-28AH",
          "60V-28AH",
          "60V-24AH",
          "60V-30AH"
        ]
      },
      {
        "model": "LEGEND",
        "variants": [
          "48V-28AH",
          "60V-28AH",
          "60V-24AH",
          "60V-30AH"
        ]
      }
    ]
  },
  {
    "make": "VABROEVTECH",
    "models": [
      {
        "model": "FAB",
        "variants": [
          "LEAD ACID"
        ]
      }
    ]
  },
  {
    "make": "VAHAK",
    "models": [
      {
        "model": "GRAZIA",
        "variants": [
          "GT",
          "PRO",
          "3W"
        ]
      },
      {
        "model": "VYOM",
        "variants": [
          "60V / 30AH",
          "E BIKE"
        ]
      },
      {
        "model": "GRAZIA GT",
        "variants": [
          "E SCOOTER",
          "E BIKE"
        ]
      },
      {
        "model": "GRAZIA PRO",
        "variants": [
          "E SCOOTER",
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "VANTIC ELECTRIC",
    "models": [
      {
        "model": "STROKE",
        "variants": [
          "LIFEPO4 60V 24AH",
          "LIFEPO4 60V 30AH"
        ]
      },
      {
        "model": "LEXA",
        "variants": [
          "LIFEPO4 60V 24AH",
          "LIFEPO4 60V 30AH"
        ]
      },
      {
        "model": "LAPRA",
        "variants": [
          "LIFEPO4 60V 24AH",
          "LIFEPO4 60V 30AH"
        ]
      },
      {
        "model": "LAPRA PLUS",
        "variants": [
          "LIFEPO4 60V 36AH"
        ]
      },
      {
        "model": "LEXA PLUS",
        "variants": [
          "LIFEPO4 60V 36AH"
        ]
      },
      {
        "model": "THUNDERBOLT ELEKTRA",
        "variants": [
          "LITHIUM 60V 35AH",
          "LITHIUM 60V 40AH"
        ]
      }
    ]
  },
  {
    "make": "VARCAS",
    "models": [
      {
        "model": "EAGAN",
        "variants": [
          "STANDARD",
          "LI 60V 29AH 250WATT"
        ]
      },
      {
        "model": "CRONY",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "FALCON",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "VARCAS AUTOMOBILES",
    "models": [
      {
        "model": "FALCON",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "EAGAN",
        "variants": [
          "ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "VARCUS",
    "models": [
      {
        "model": "RANI",
        "variants": [
          "LX BICYCLE"
        ]
      }
    ]
  },
  {
    "make": "VED MOTORS",
    "models": [
      {
        "model": "CUTE",
        "variants": [
          "E SCOOTER",
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "RUSH",
        "variants": [
          "E SCOOTER",
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "LIAM",
        "variants": [
          "LITHIUM ION BATTERY 72V 26AH"
        ]
      },
      {
        "model": "NAMO",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "VEGH",
    "models": [
      {
        "model": "L25",
        "variants": [
          "LITHIUM ION 60V 26AH",
          "ELECTRIC"
        ]
      },
      {
        "model": "S25",
        "variants": [
          "LITHIUM ION 48V 30AH",
          "ELECTRIC"
        ]
      },
      {
        "model": "S60",
        "variants": [
          "ELECTRIC"
        ]
      },
      {
        "model": "S 25",
        "variants": [
          "LI 48V 28AH 250WATT"
        ]
      },
      {
        "model": "L 25",
        "variants": [
          "LI 60V 26AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "VELOCE LTD",
    "models": [
      {
        "model": "VELOCETTE",
        "variants": [
          "VINTAGE"
        ]
      }
    ]
  },
  {
    "make": "VESPA",
    "models": [
      {
        "model": "946",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "LX 125",
        "variants": [
          "BS VI",
          "STANDARD"
        ]
      },
      {
        "model": "S",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "VX 125",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SXL 150",
        "variants": [
          "STANDARD",
          "MATT RED",
          "CONNECTIVITY",
          "CONNECTIVITY-ABS",
          "MATT RED-CONNECTIVITY",
          "MATT RED CONNECTIVITY-ABS",
          "BS VI FI",
          "CANDY RED ABS BS VI",
          "FACELIFT",
          "RACING SIXTIES",
          "DUAL"
        ]
      },
      {
        "model": "SXL 125",
        "variants": [
          "STANDARD",
          "CBS",
          "BS VI FI",
          "CANDY RED ABS BS VI",
          "FACELIFT",
          "RACING SIXTIES",
          "DUAL"
        ]
      },
      {
        "model": "VXL 150",
        "variants": [
          "STANDARD",
          "CONNECTIVITY",
          "CONNECTIVITY-ABS",
          "BS VI",
          "FACELIFT",
          "DUAL"
        ]
      },
      {
        "model": "VXL 125",
        "variants": [
          "STANDARD",
          "CBS",
          "BS VI",
          "FACELIFT",
          "DUAL",
          "STANDARD"
        ]
      },
      {
        "model": "ZX 125",
        "variants": [
          "CBS",
          "BS VI FI"
        ]
      },
      {
        "model": "NOTTE",
        "variants": [
          "STANDARD",
          "BS VI"
        ]
      },
      {
        "model": "ELEGANTE 125",
        "variants": [
          "STANDARD",
          "CBS",
          "BS VI"
        ]
      },
      {
        "model": "ELEGANTE 150",
        "variants": [
          "STANDARD",
          "CONNECTIVITY",
          "CONNECTIVITY-ABS",
          "BS VI FI"
        ]
      },
      {
        "model": "LX",
        "variants": [
          "125"
        ]
      },
      {
        "model": "RED",
        "variants": [
          "STANDARD",
          "125"
        ]
      },
      {
        "model": "VXL 150 ANNIVERSARY",
        "variants": [
          "EDITION STANDARD"
        ]
      },
      {
        "model": "UC 125",
        "variants": [
          "STANDARD BS VI"
        ]
      },
      {
        "model": "SR",
        "variants": [
          "150",
          "150 RACE"
        ]
      }
    ]
  },
  {
    "make": "VIBGYOR",
    "models": [
      {
        "model": "HUNTER",
        "variants": [
          "STANDARD",
          "SELF START",
          "STD",
          "STD"
        ]
      },
      {
        "model": "GALLOP",
        "variants": [
          "STANDARD",
          "KICK START",
          "SELF START",
          "SELF START ALLOY WHEEL",
          "STD",
          "STD"
        ]
      },
      {
        "model": "SHARK",
        "variants": [
          "STANDARD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "GALLAOP",
        "variants": [
          "KS SPOKE WHEELS DRUM BRAKE"
        ]
      },
      {
        "model": "PERSONA",
        "variants": [
          "110",
          "200"
        ]
      }
    ]
  },
  {
    "make": "VIBGYOR VEHICLES",
    "models": [
      {
        "model": "PERSONA",
        "variants": [
          "150 CC MOTORCYCLE",
          "110 CC MOTORCYCLE"
        ]
      },
      {
        "model": "GALLAOP",
        "variants": [
          "KICK START SPOKE WHEEL DRUM BREAK",
          "ELECTRIC START ALLOY  WHEEL DRUM BREAK",
          "ELECTRIC START SPOKE WHEEL DRUM BREAK"
        ]
      },
      {
        "model": "HUNTER",
        "variants": [
          "ELECTRIC START ALLOY WHEEL DISK BREAK"
        ]
      },
      {
        "model": "SHARK",
        "variants": [
          "KICK START ALLOY WHEEL DISC BRK"
        ]
      }
    ]
  },
  {
    "make": "VICTORY",
    "models": [
      {
        "model": "VEGAS",
        "variants": [
          "JACKPOT",
          "LOW",
          "8 BALL"
        ]
      },
      {
        "model": "VERO SMART",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "DELTA PLUS",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "VENTURA",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "VERO VENTURA",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "SHINE",
        "variants": [
          "E SCOOTY"
        ]
      },
      {
        "model": "SMART NEO",
        "variants": [
          "LITHIUM 60V 30AH",
          "LITHIUM 48V 25AH",
          "LITHIUM BMS"
        ]
      },
      {
        "model": "VERO PLUS",
        "variants": [
          "LITHIUM 60V 30AH",
          "LITHIUM 48V 25AH",
          "LITHIUM BMS"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "LITHIUM 48V 25AH",
          "LITHIUM 60V 30AH",
          "LITHIUM BMS"
        ]
      },
      {
        "model": "HAMMER",
        "variants": [
          "1700",
          "S",
          "8 BALL"
        ]
      },
      {
        "model": "VISION",
        "variants": [
          "CROSS COUNTRY",
          "CROSS ROADS",
          "STREET PREMIUM",
          "TOUR PREMIUM",
          "CROSS ROAD",
          "1800 CC"
        ]
      },
      {
        "model": "SMART",
        "variants": [
          "60V",
          "48V"
        ]
      },
      {
        "model": "VESPA",
        "variants": [
          "ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "VICTORY ELECTRIC INTERNATIONAL",
    "models": [
      {
        "model": "VENTURA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "E SCOOTY",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "E SCOOTY VERO",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "VIDA",
    "models": [
      {
        "model": "V1 PRO",
        "variants": [
          "STD",
          "LITHIUM ION"
        ]
      },
      {
        "model": "V1 PLUS",
        "variants": [
          "STD",
          "LITHIUM ION"
        ]
      }
    ]
  },
  {
    "make": "VIERTRIC",
    "models": [
      {
        "model": "XL",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MIST",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "MAX",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "EAGLE",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "VIERTRIC MOTORS",
    "models": [
      {
        "model": "V4",
        "variants": [
          "XL",
          "EAGLE",
          "MAX",
          "MAX DISK",
          "EAGLE DISK",
          "MAX DRUM",
          "MIST"
        ]
      },
      {
        "model": "V4 MIST",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "V4 EAGLE",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "V4 MAX",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "V4 XL",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "VIJAY SUPER",
    "models": [
      {
        "model": "LAMBERT",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "VIJAYA",
    "models": [
      {
        "model": "GLIDE 25 SUPER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "WNNIE",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "VIJAYA VALUE",
    "models": [
      {
        "model": "WNNIE",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "GLIDE",
        "variants": [
          "25 SUPER"
        ]
      }
    ]
  },
  {
    "make": "VIKE",
    "models": [
      {
        "model": "CARGO HEAVY DUTY",
        "variants": [
          "LFP 60.8V 48AH",
          "LFP 60V 36AH"
        ]
      },
      {
        "model": "CHOLA",
        "variants": [
          "LITHIUM 60V 36AH",
          "LITHIUM 60V 42AH"
        ]
      }
    ]
  },
  {
    "make": "VIKRAM",
    "models": [
      {
        "model": "VEERO",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "VENTURA",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "SPORTS",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "VIRAJA SUPER",
    "models": [
      {
        "model": "SUPER ECO",
        "variants": [
          "S2"
        ]
      }
    ]
  },
  {
    "make": "VOLT ELECTRIC",
    "models": [
      {
        "model": "STROM",
        "variants": [
          "E SCOOTER",
          "LITHIUM 60V 30AH",
          "LEAD ACID 60V 30AH"
        ]
      },
      {
        "model": "SKY",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "OPAL",
        "variants": [
          "E SCOOTER",
          "7G",
          "LEAD ACID 60V 30AH",
          "7G LEAD ACID 72V 30AH",
          "9G LEAD ACID 72V 30AH"
        ]
      },
      {
        "model": "STANO 5G",
        "variants": [
          "LEAD ACID 60V 35AH",
          "LITHIUM 60V 30AH",
          "LEAD ACID 72V 30AH"
        ]
      },
      {
        "model": "STROM PLUS",
        "variants": [
          "LEAD ACID 12V 28AH",
          "LEAD ACID 60V 30AH"
        ]
      },
      {
        "model": "SNIPER",
        "variants": [
          "LEAD ACID 60V 30AH"
        ]
      },
      {
        "model": "OPAL PLUS",
        "variants": [
          "LEAD ACID 60V 30AH"
        ]
      },
      {
        "model": "STREET",
        "variants": [
          "STROM",
          "OPAL"
        ]
      }
    ]
  },
  {
    "make": "VTECH",
    "models": [
      {
        "model": "VTECH AQUILA",
        "variants": [
          "48V 25 AH",
          "60V 25 AH",
          "60V 30 AH",
          "60V 35 AH",
          "60V 40 AH",
          "60V 45 AH"
        ]
      },
      {
        "model": "VTECH LEO",
        "variants": [
          "48V 25 AH",
          "60V 25 AH",
          "60V 30 AH",
          "60V 35 AH",
          "60V 40 AH",
          "60V 45 AH"
        ]
      },
      {
        "model": "VTECH RAPTOR",
        "variants": [
          "48V 25 AH",
          "60V 25 AH",
          "60V 30 AH",
          "60V 35 AH",
          "60V 40 AH",
          "60V 45 AH"
        ]
      },
      {
        "model": "VTECH LOAD KING",
        "variants": [
          "60V 30 AH",
          "60V 35 AH",
          "60V 40 AH",
          "60V 45 AH"
        ]
      },
      {
        "model": "AQUILLA",
        "variants": [
          "60V-22AH",
          "60V-36AH",
          "60V 45AH",
          "51.8V 65AH"
        ]
      },
      {
        "model": "RAPTOR",
        "variants": [
          "60V 22AH",
          "60V 32AH",
          "60V 45AH",
          "51.8V 65AH"
        ]
      },
      {
        "model": "LEO",
        "variants": [
          "60V 22AH",
          "60V 32AH",
          "60V 45AH",
          "51.8V 65AH"
        ]
      },
      {
        "model": "CLASSIC",
        "variants": [
          "60V 26AH"
        ]
      },
      {
        "model": "LOAD KING",
        "variants": [
          "60V 22AH",
          "60V 32AH"
        ]
      }
    ]
  },
  {
    "make": "WALKMAN WORLD",
    "models": [
      {
        "model": "PRINCY",
        "variants": [
          "450",
          "500",
          "350",
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "WARD WIZARD INNOVATIONS & MOBILITY LTD",
    "models": [
      {
        "model": "JOY NANU",
        "variants": [
          "GEN NXT",
          "BUTTERFLY",
          "HONEYBEE"
        ]
      },
      {
        "model": "JOY E MONSTER",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "JOY GLOB",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "JOY WOLF",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "JOY MONSTER",
        "variants": [
          "LITHIUM BATTERY"
        ]
      },
      {
        "model": "WOLF ECO",
        "variants": [
          "LI 60V 31AH 1 KW"
        ]
      }
    ]
  },
  {
    "make": "WARIVO",
    "models": [
      {
        "model": "NEXA",
        "variants": [
          "EX",
          "48V24AH",
          "60V24AH",
          "60V34AH",
          "60V28AH",
          "STD",
          "DS",
          "EX 60V",
          "SX",
          "STANDARD"
        ]
      },
      {
        "model": "ACE",
        "variants": [
          "60V24AH",
          "60V34AH",
          "60V28AH",
          "STD",
          "FALCON",
          "PLUS"
        ]
      },
      {
        "model": "ACE 10",
        "variants": [
          "60V28AH"
        ]
      },
      {
        "model": "ACE F-12",
        "variants": [
          "60V24AH",
          "60V34AH",
          "60V28AH"
        ]
      },
      {
        "model": "ACE FALCON",
        "variants": [
          "60V24AH",
          "60V34AH"
        ]
      },
      {
        "model": "ACE FALCON 10",
        "variants": [
          "60V28AH"
        ]
      },
      {
        "model": "ACE FALCON F-12",
        "variants": [
          "60V28AH"
        ]
      },
      {
        "model": "ACE PLUS",
        "variants": [
          "48V24AH",
          "60V24AH",
          "STANDARD"
        ]
      },
      {
        "model": "ENDURO",
        "variants": [
          "60V34AH",
          "60V/30AH"
        ]
      },
      {
        "model": "ENDURO MAX",
        "variants": [
          "60V34AH",
          "60V42AH"
        ]
      },
      {
        "model": "NEXA DS",
        "variants": [
          "60V24AH",
          "60V28AH"
        ]
      },
      {
        "model": "NEXA EX",
        "variants": [
          "48V24AH",
          "60V24AH",
          "60V34AH",
          "60V28AH"
        ]
      },
      {
        "model": "NEXA SX",
        "variants": [
          "48V24AH",
          "60V24AH",
          "60V28AH"
        ]
      },
      {
        "model": "QUEEN LX",
        "variants": [
          "48V28AH"
        ]
      },
      {
        "model": "QUEEN",
        "variants": [
          "LX",
          "SX",
          "STANDARD"
        ]
      },
      {
        "model": "SMARTY",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "WARIVO MOTOR",
    "models": [
      {
        "model": "QUEEN SX",
        "variants": [
          "LEAD ACID BATTERY 60V 28 AH",
          "LITHIUM BATTERY 60V 30AH",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "ACE FALCON",
        "variants": [
          "LEAD ACID BATTERY 60V",
          "WOB 250WATT",
          "LITHIUM ION  60V"
        ]
      },
      {
        "model": "QUEEN LX",
        "variants": [
          "LEAD ACID BATTERY 48V 28 AH",
          "LITHIUM BATTERY 48V 30AH",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "NEXA",
        "variants": [
          "LEAD ACID BATTERY 48V 28 AH",
          "LITHIUM BATTERY 48V 30AH"
        ]
      },
      {
        "model": "NEXA EX",
        "variants": [
          "LEAD ACID BATTERY 48V 28 AH",
          "LITHIUM BATTERY 48V 30AH",
          "EX LEAD ACID BATTERY"
        ]
      },
      {
        "model": "NEXA DS",
        "variants": [
          "LEAD ACID BATTERY 48V 28 AH",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "ACE PLUS",
        "variants": [
          "LITHIUM BATTERY",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "ENDURO",
        "variants": [
          "LEAD ACID BATTERY 60V 28 AH",
          "LITHIUM BATTERY 60V 30AH",
          "LEAD ACID BATTERY"
        ]
      },
      {
        "model": "FALCON",
        "variants": [
          "LEAD ACID BATTERY 60V 28 AH",
          "LITHIUM BATTERY 60V 30AH"
        ]
      },
      {
        "model": "ACE",
        "variants": [
          "LEAD ACID BATTERY",
          "LITHIUM BATTERY"
        ]
      }
    ]
  },
  {
    "make": "WARIVO MOTOR INDIA PVT LTD",
    "models": [
      {
        "model": "WARIVO NEXA EX",
        "variants": [
          "NEXA EX"
        ]
      },
      {
        "model": "NEXA",
        "variants": [
          "SX E SCOOTY",
          "DS E SCCOTY",
          "SX 30AH",
          "DS 30AH",
          "EX 30 AH",
          "EX 40AH",
          "40 AH",
          "EX LEAD ACID",
          "DS LITHIUM 60V 24AH"
        ]
      },
      {
        "model": "FALCON",
        "variants": [
          "LEAD ACID",
          "LITHIUM",
          "30 AH"
        ]
      },
      {
        "model": "QUEEN",
        "variants": [
          "SCOOTER",
          "SX E SCOOTY",
          "EX E SCOOTY",
          "SX 30AH",
          "EX 30AH",
          "LX E SCOOTY"
        ]
      },
      {
        "model": "SMARTY",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "ENDURO",
        "variants": [
          "SCOOTER",
          "LITHIUM 30 AH"
        ]
      },
      {
        "model": "ACE",
        "variants": [
          "LEAD ACID",
          "PLUS LEAD ACID",
          "LI-ION",
          "PLUS LI-ON"
        ]
      }
    ]
  },
  {
    "make": "WENS ELECTRIC VEHICLES",
    "models": [
      {
        "model": "E SCOOTER",
        "variants": [
          "48 V"
        ]
      }
    ]
  },
  {
    "make": "WHITE CARBON",
    "models": [
      {
        "model": "GT5",
        "variants": [
          "SMART SCOOTER"
        ]
      },
      {
        "model": "O3",
        "variants": [
          "E SCOOTER",
          "E BIKE"
        ]
      },
      {
        "model": "GT 5",
        "variants": [
          "SELF START DISC BRAKE BS VI"
        ]
      },
      {
        "model": "JAZZY",
        "variants": [
          "LI 60V 26AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "WHITE CARBON MOTORS",
    "models": [
      {
        "model": "GT5",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "O3",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "JAZZY",
        "variants": [
          "LITHIUM 60V 26AH"
        ]
      }
    ]
  },
  {
    "make": "WIREBEE INDIA",
    "models": [
      {
        "model": "DRIVE",
        "variants": [
          "48V 24AH LI",
          "60V 25AH LI",
          "60V 30AH LI",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "DRIVE PRO",
        "variants": [
          "48V 24AH LI",
          "60V 24AH LI",
          "60V 30AH LI",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "EASY",
        "variants": [
          "48V 20AH LI"
        ]
      },
      {
        "model": "IMPERIAL",
        "variants": [
          "48V 24AH LI",
          "60V 24AH LI",
          "60V 30AH LI",
          "LEAD ACID"
        ]
      },
      {
        "model": "MERCY",
        "variants": [
          "48V 24AH LI",
          "60V 24AH",
          "60V 30AH LI"
        ]
      },
      {
        "model": "MIRACLE",
        "variants": [
          "48V 24AH",
          "60V 24AH LI",
          "60V 30AH LI",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "MOUJ",
        "variants": [
          "48V 24AH LI"
        ]
      },
      {
        "model": "RIDE",
        "variants": [
          "48V 24AH LI",
          "60V 24AH LI",
          "60V 30AH LI",
          "LEAD ACID"
        ]
      },
      {
        "model": "RIDE PRO",
        "variants": [
          "48V 24AH LI",
          "60V 24AH LI",
          "60V 30AH LI",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "VORTEX",
        "variants": [
          "48V 24AH LI",
          "60V 24AH LI",
          "60V 30AH LI",
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "TRAI",
        "variants": [
          "48V 24AH LI SPECIALLY ABLED",
          "60V 30AH LI SPECIALLY ABLED"
        ]
      }
    ]
  },
  {
    "make": "WONDER RIDE INDIA",
    "models": [
      {
        "model": "T1",
        "variants": [
          "LITHIUM ION 60V 28.6AH"
        ]
      },
      {
        "model": "S2",
        "variants": [
          "LITHIUM ION 60V 31.2AH"
        ]
      }
    ]
  },
  {
    "make": "WOOK ELECTRIX",
    "models": [
      {
        "model": "VICTORY",
        "variants": [
          "LITHIUN ION"
        ]
      }
    ]
  },
  {
    "make": "WROLEY",
    "models": [
      {
        "model": "POSH",
        "variants": [
          "LITHIUM 60V 30AH",
          "LI 60V 30AH 250WATT"
        ]
      },
      {
        "model": "MARS",
        "variants": [
          "LITHIUM 60V 30AH",
          "LI 48V 30AH 250WATT"
        ]
      },
      {
        "model": "PLATINA",
        "variants": [
          "LITHIUM 60V 30AH",
          "LI 60V 30AH 250WATT"
        ]
      }
    ]
  },
  {
    "make": "WROLEY E-INDIA PVT. LTD.",
    "models": [
      {
        "model": "MARS",
        "variants": [
          "48V/30AH"
        ]
      },
      {
        "model": "PLATINA",
        "variants": [
          "60V/30AH"
        ]
      },
      {
        "model": "POSH",
        "variants": [
          "60V/30AH"
        ]
      }
    ]
  },
  {
    "make": "XINDAYANG CHINA",
    "models": [
      {
        "model": "CITY",
        "variants": [
          "WAV E BIKE"
        ]
      }
    ]
  },
  {
    "make": "XING",
    "models": [
      {
        "model": "MATRIX",
        "variants": [
          "SUPER PLUS"
        ]
      }
    ]
  },
  {
    "make": "XITE",
    "models": [
      {
        "model": "K101",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "K102",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "K103",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "XS BIKES",
    "models": [
      {
        "model": "BIGSHARK",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ENERGY",
        "variants": [
          "STD",
          "E SCOOTER"
        ]
      },
      {
        "model": "BIG SHARK",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "XWHEEL",
    "models": [
      {
        "model": "X3",
        "variants": [
          "LITHIUM 48V 28AH"
        ]
      }
    ]
  },
  {
    "make": "XWLS ELECTRIC PRIVATE LTD",
    "models": [
      {
        "model": "X1",
        "variants": [
          "ESCOOTY"
        ]
      }
    ]
  },
  {
    "make": "YAKUZA",
    "models": [
      {
        "model": "EDGE",
        "variants": [
          "LITHIUM BATTERY 62.9V/40AH",
          "60V LEAD",
          "60V LITHIUM",
          "LITHIUM BATTERY 62.9V/30AH",
          "LITHIUM BATTERY 51.4V/30AH"
        ]
      },
      {
        "model": "DELTA",
        "variants": [
          "LITHIUM BATTERY",
          "E SCOOTER",
          "60V LEAD",
          "60V LITHIUM"
        ]
      },
      {
        "model": "ERIKA",
        "variants": [
          "LITHIUM BATTERY 62.9V / 40AH",
          "LITHIUM BATTERY 51.4V / 30 AH",
          "LITHIUM BATTERY 62.9V / 30AH"
        ]
      },
      {
        "model": "SAPPHIRE",
        "variants": [
          "LITHIUM BATTERY 62.9V 30AH",
          "LITHIUM BATTERY 72V 42AH",
          "LITHIUM 72V 45H",
          "60V-30AH",
          "60V-40AH"
        ]
      },
      {
        "model": "RUBIA",
        "variants": [
          "E SCOOTER",
          "48V/28AH GEL",
          "60V/28AH GEL",
          "48V/20AH LI",
          "51.4V/30AH LI",
          "62.9V/30AH LI",
          "62.9V/40AH LI"
        ]
      },
      {
        "model": "VIRAJ",
        "variants": [
          "E SCOOTER",
          "48V LEAD",
          "48V LITHIUM",
          "60V LEAD",
          "60V LITHIUM"
        ]
      },
      {
        "model": "POPPY",
        "variants": [
          "E SCOOTER",
          "48V LEAD",
          "48V LITHIUM",
          "60V LEAD",
          "60V LITHIUM",
          "E BIKE 48V",
          "GEL BATTERY 48V/28AH",
          "GEL BATTERY  60V/28AH",
          "LITHIUM BATTERY 51.4V/30AH",
          "LITHIUM BATTERY 62.9V/30AH"
        ]
      },
      {
        "model": "NEU",
        "variants": [
          "E SCOOTER",
          "48V/28AH GEL",
          "60V/28AH GEL",
          "51.4V/30AH LI",
          "62.9V/30AH LI",
          "62.9V/40AH LI",
          "LITHIUM BATTERY 62.9V/40AH",
          "LITHIUM BATTERY 62.9V/30AH",
          "LITHIUM BATTERY 51.4V/30AH",
          "GEL BATTERY 60V/28AH"
        ]
      },
      {
        "model": "PHANTOM",
        "variants": [
          "E SCOOTER",
          "60V LEAD",
          "60V LITHIUM"
        ]
      },
      {
        "model": "ERICKA",
        "variants": [
          "E SCOOTER",
          "60V LEAD",
          "60V LITHIUM",
          "72V LEAD",
          "72V LITHIUM",
          "E BIKE 72V",
          "E BIKE 60V"
        ]
      },
      {
        "model": "PIRAL",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "AJ STAR",
        "variants": [
          "LED",
          "LITHIUM",
          "60V"
        ]
      },
      {
        "model": "SPARROW",
        "variants": [
          "LEAD ACID 60V 28AH",
          "60V",
          "LI 63V 40AH 250WATT",
          "LA 60V 24AH 250WATT",
          "LI 52V 30AH 250WATT",
          "LI 63V 30AH 250WATT"
        ]
      },
      {
        "model": "CYCLONE",
        "variants": [
          "LEAD ACID 60V"
        ]
      },
      {
        "model": "RUBIE PLUS",
        "variants": [
          "48V"
        ]
      },
      {
        "model": "UTILITY",
        "variants": [
          "60V"
        ]
      },
      {
        "model": "FIRDAUS",
        "variants": [
          "60V",
          "48V",
          "LI 63V 30AH 250WATT",
          "LA 60V 24AH 250WATT",
          "LI 52V 30AH 250WATT"
        ]
      },
      {
        "model": "NEU POWER",
        "variants": [
          "60V LEAD",
          "60V LITHIUM"
        ]
      },
      {
        "model": "PRIAL",
        "variants": [
          "60V LEAD",
          "60V LITHIUM"
        ]
      },
      {
        "model": "RUBIE",
        "variants": [
          "48V LEAD",
          "48V LITHIUM",
          "60V LEAD",
          "60V LITHIUM",
          "E BIKE 60V",
          "E BIKE 48V",
          "GEL BATTERY 48V/28AH",
          "LITHIUM BATTERY 51.4V/30AH",
          "GEL BATTERY  60V/28AH",
          "LITHIUM BATTERY 62.9V/40AH",
          "LITHIUM BATTERY 62.9V/30AH"
        ]
      },
      {
        "model": "RUNNER",
        "variants": [
          "48V LEAD",
          "48V LITHIUM"
        ]
      }
    ]
  },
  {
    "make": "YAMAHA",
    "models": [
      {
        "model": "FZX",
        "variants": [
          "ELECTRIC START DISC BRAKE WITH Y CONNECT",
          "ELECTRIC START DISC BRAKE WITHOUT  Y CONNECT",
          "ELECTRIC START DISC BRAKE OBD2",
          "ABS DISC BT BS VI"
        ]
      },
      {
        "model": "FASCINO",
        "variants": [
          "FI 125 DISC BRAKE HYBRID",
          "FI 125 DISC STD",
          "125 UBS DRUM SPECIAL EDITION BS VI",
          "125 UBS DISK SPECIAL EDITION BS VI",
          "125 UBS DRUM  BS VI",
          "125 UBS DISK BS VI",
          "FI 125 STD DRUM BRAKE HYBRID",
          "FI 125 DLX DRUM BRAKE HYBRID",
          "FI 125 DLX DISC BRAKE HYBRID",
          "FI 125 SPECIAL EDITION HYBRID",
          "125 FI DRUM BRAKE SCOOTER",
          "125 FI DISC BRAKE SCOOTER",
          "113 CC SCOOTER",
          "STANDARD",
          "DARKNIGHT UBS",
          "FI DRUM BSVI",
          "FI DRUM LTD BSVI",
          "FI DISC BSVI",
          "FI DISC LTD BSVI",
          "HYBRID DISC LTD BSVI",
          "HYBRID DRUM BSVI",
          "DS BT SPECIAL",
          "HYB VIVID",
          "HYBRID DLX DISC BSVI",
          "HYBRID DISC SPL BSVI",
          "HYBRID DISC BSVI",
          "HYBRID DISC DELUXE BSVI",
          "HYBRID DLX DRUM BSVI",
          "HYBRID SPL DISC BSVI",
          "UBS",
          "125 FI BSVI",
          "DRUM BSVI",
          "DRUM DLX BSVI",
          "DISC BSVI",
          "DISC DLX BSVI",
          "UBS SP",
          "LE BS6",
          "DISC LE BS6",
          "HYBRID DISC",
          "HYBRID DRUM",
          "125 FI HYBRID DRUM",
          "125 FI HYBRID DISC BS6",
          "STD"
        ]
      },
      {
        "model": "RAY",
        "variants": [
          "ZR DISC STD",
          "ZR DISC BRAKE MONSTER EDITION HYBRID",
          "ZR DRUM HYBRID",
          "ZR STREET RALLY HYBRID",
          "ZR STD DISC HYBRID",
          "ZR DLX DISC HYBRID",
          "ZR OBD2",
          "CYGNUS RAY ZR",
          "ZR DRUM BRAKE",
          "ZR DISC BRAKE",
          "ZR DISC UBS SP",
          "CYGNUS RAY Z",
          "STREEET RALLY EDITION",
          "ZR DRUM UBS BS VI (125 CC)",
          "ZR STREET RALLY (125 CC)",
          "CYGNUS RAY ZR (125 CC)",
          "ZR DISK UBS BS VI (125 CC)",
          "ZR DISK MONSTER",
          "ZR LE UBS",
          "STANDARD",
          "ZR SPECIAL",
          "ZR 125 FI DRUM BSVI",
          "ZR 125 FI DISC BSVI",
          "ZR 125 FI STREET RALLY BSVI",
          "ZR MOTO GP EDITION BS VI",
          "STD",
          "ZR 125 OBD II BSVI",
          "STD",
          "STD",
          "ZR DISC",
          "ZR DISC UBS",
          "ZR DRUM BS6",
          "ZR DRUM UBS",
          "ZR STREET RALLY",
          "Z UBS",
          "ZR SR UBS",
          "ZR LTD DISC UBS",
          "ZR DRUM",
          "ZR B2U1",
          "ZR DISC BS6",
          "ZR 125 FI DRUMÂ HYBRID BT",
          "ZR 125 FI DISCÂ HYBRID BT",
          "ZR 125 FI DISCÂ DLEUXE HYBRID BT",
          "ZR 125 FI DISCÂ MOTOGP EDITION HYBRID",
          "ZR 125 FI DISCÂ STREET RALLY HYBRID",
          "113",
          "BS III",
          "STD",
          "Z"
        ]
      },
      {
        "model": "YZF R15 S",
        "variants": [
          "ABS BS VI",
          "STANDARD"
        ]
      },
      {
        "model": "FZS FI",
        "variants": [
          "VINTAGE EDITION BS VI",
          "DARKNIGHT BS VI",
          "V4 DLX",
          "REAR DISC BRAKE",
          "V3 ABS BLUETOOTH BS VI",
          "V3 ABS BS VI",
          "V3 ABS",
          "V2.0"
        ]
      },
      {
        "model": "AEROX",
        "variants": [
          "STD",
          "155",
          "BLUETOOTH",
          "STANDARD",
          "MOTO GP EDITION BS VI",
          "MONSTER EDITION BSVI",
          "MOTO GP EDITION",
          "STD BS6"
        ]
      },
      {
        "model": "YZF R15 155 CC",
        "variants": [
          "V4 ABS BS VI",
          "V4 ABS OBD2",
          "VERSION 3.0",
          "VERSION 3.0 ABS",
          "MONSTER",
          "V3 ABS BS VI",
          "STD",
          "VERSION 3.0 LA ABS"
        ]
      },
      {
        "model": "YZF R15 M",
        "variants": [
          "STD BS VI",
          "WGP 60 EIDITION",
          "MONSTER EDITION BS VI",
          "V4 BS VI",
          "V4 MONSTER EDITION BS VI"
        ]
      },
      {
        "model": "MT 15",
        "variants": [
          "ABS OBD2",
          "VERSION 2.0",
          "MOTO GP BLUTOOTH",
          "V 2.0",
          "SE",
          "ABS BS VI"
        ]
      },
      {
        "model": "RZ",
        "variants": [
          "250 R"
        ]
      },
      {
        "model": "FZ 250",
        "variants": [
          "250 CC"
        ]
      },
      {
        "model": "FZS 250",
        "variants": [
          "250 CC"
        ]
      },
      {
        "model": "T MAX",
        "variants": [
          "500 CC SCOOTER"
        ]
      },
      {
        "model": "YZF R3",
        "variants": [
          "ABS",
          "STANDARD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "FAZER 25",
        "variants": [
          "STD",
          "ABS D",
          "ABS",
          "STANDARD"
        ]
      },
      {
        "model": "ESCORTS",
        "variants": [
          "110 CC",
          "ACE"
        ]
      },
      {
        "model": "FAZER 153",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FZ FI",
        "variants": [
          "VERSION 3.0",
          "VERSION 3.0 BLUETOOTH BS VI",
          "VERSION 2.0",
          "ABS",
          "STANDARD"
        ]
      },
      {
        "model": "ALPHA",
        "variants": [
          "UBS DRUM",
          "UBS DISC",
          "SPECIAL EDITION",
          "CYGNUS DISC",
          "UBS",
          "STANDARD",
          "DISC-UBS",
          "NEW",
          "CVT",
          "DISC",
          "CYGNUS",
          "DRUM-UBS",
          "STD"
        ]
      },
      {
        "model": "SALUTO",
        "variants": [
          "125 DRUM UBS",
          "125 DISC UBS",
          "125 SP DISC UBS",
          "125 SP DRUM UBS",
          "110 RX UBS",
          "110 RX UBS SP",
          "KICK START DRUM BRAKEALLOY WHEEL",
          "ELECTRIC START DISC BRAKE ALLOY WHEEL",
          "RX STD 110 CC",
          "ELECTRIC START DRUM BRAKE ALLOY WHEEL",
          "DRUM SPECIAL EDITION",
          "DISC",
          "DRUM SPECIAL EDITION UBS",
          "DISC SPECIAL EDITION UBS",
          "ELEC ST DISC BRAKE ALLOY WHEEL",
          "ELEC ST DRUM BRAKE ALLOY WHEEL",
          "KICK START DRUM BRAKE",
          "RX",
          "ELEC ST DISC BRAKE ALLOY",
          "RX UBS",
          "RX SPL UBS",
          "DRUM LE UBS",
          "DISC LE UBS",
          "DRUM UBS",
          "DISC UBS",
          "DRUM",
          "DISC SPECIAL EDITION",
          "DISC BRAKE",
          "STD"
        ]
      },
      {
        "model": "FZ",
        "variants": [
          "FI",
          "SELF CAST DISC",
          "X",
          "STD",
          "FI V2",
          "FZ 150",
          "FZ-S",
          "S LTD",
          "S DOUBLE DISC",
          "25",
          "S FI V3",
          "V 3.0 ABS BSVI",
          "X NON BLUETOOTH",
          "X BLUETOOTH",
          "N 250D-A (BOY100)",
          "STD",
          "250",
          "25 DUAL CHANNEL ABS",
          "25 MONSTER ENERGY EDITION",
          "FI BSVI",
          "FI V3",
          "F1 V3 ABS",
          "250 ABS",
          "25",
          "25 BSVI",
          "S 25 BSVI",
          "F1 V2",
          "STD 153",
          "(O)",
          "(N)",
          "S(O)",
          "LTD",
          "STD"
        ]
      },
      {
        "model": "FZ 25",
        "variants": [
          "STD",
          "ABS D",
          "LIMITED EDITION",
          "ABS BS VI",
          "DUAL CHANNEL ABS BSVI"
        ]
      },
      {
        "model": "FZS",
        "variants": [
          "V3",
          "FI V3 DLX",
          "V3 ABS",
          "V2",
          "FI",
          "V 2",
          "V 3.0 ABS BSVI",
          "25 DUAL CHANNEL ABS BSVI",
          "FI V3 ABS BLUETOOTH",
          "BSIV",
          "FI VINTAGE GREEN BLUTOOTH",
          "FI DELUXE BSVI",
          "V4 BSVI",
          "FI V4 DLX BSVI",
          "FR & RR DISC"
        ]
      },
      {
        "model": "YZF R15",
        "variants": [
          "VERSION 2.0",
          "V 2.0",
          "YZF R15",
          "LTD",
          "NEW",
          "V3 STD",
          "V3 DARKNIGHT ABS",
          "V3 BSVI",
          "BS III",
          "V4Â MONSTER ENERGY EDITION",
          "M MOTOGP EDITION",
          "V3",
          "STD",
          "STD",
          "STANDARD VERSION 1.0",
          "V2",
          "VERSION 3.0"
        ]
      },
      {
        "model": "FAZER",
        "variants": [
          "ABS D",
          "DELUXE",
          "STANDARD",
          "FI V2",
          "25",
          "25 DUAL CHANNEL ABS",
          "25",
          "STD",
          "(O)",
          "125 CC",
          "153 CC",
          "25 DUAL CHANNEL ABS",
          "F1 V 2.0",
          "125 DLX",
          "F1",
          "(N)",
          "LTD",
          "LIMITED EDITION",
          "DLX",
          "DX",
          "LX",
          "153"
        ]
      },
      {
        "model": "ROADSTAR",
        "variants": [
          "S V-TWIN"
        ]
      },
      {
        "model": "FAZER 999",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FZ 16 FI",
        "variants": [
          "V3 ABS BS VI",
          "V3 ABS"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "SCOOTER",
          "SCOOTER",
          "135"
        ]
      },
      {
        "model": "NEO",
        "variants": [
          "SCOOTER",
          "50",
          "50"
        ]
      },
      {
        "model": "JOGR 50",
        "variants": [
          "SCOOTER"
        ]
      },
      {
        "model": "NOUVO",
        "variants": [
          "SCOOTER",
          "STD",
          "135"
        ]
      },
      {
        "model": "FZS 25",
        "variants": [
          "ABS BS VI",
          "STANDARD"
        ]
      },
      {
        "model": "SZ RR V 2.0",
        "variants": [
          "ALLOY WHEEL - DISC BREAK - ELECTRIC START",
          "V 2 STD",
          "V 2 LE",
          "STANDARD",
          "LTD EDITION"
        ]
      },
      {
        "model": "ROYAL STAR",
        "variants": [
          "TOUR DELUXE (1300 CC)"
        ]
      },
      {
        "model": "SZ S",
        "variants": [
          "ALLOY WHEEL - DISC BREAK - ELECTRIC START",
          "STANDARD",
          "SZ S"
        ]
      },
      {
        "model": "RX",
        "variants": [
          "125",
          "100",
          "135"
        ]
      },
      {
        "model": "FJR 1300",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "YZF R1",
        "variants": [
          "M",
          "STANDARD",
          "TCI",
          "LE",
          "SPL",
          "STD"
        ]
      },
      {
        "model": "CRUX",
        "variants": [
          "ES",
          "SPOKE WHEEL- KICK START- DRUM BRK",
          "STANDARD",
          "ELECTRIC START",
          "STD",
          "106",
          "STD",
          "R",
          "S",
          "SELF START",
          "STD"
        ]
      },
      {
        "model": "V MAX",
        "variants": [
          "2 EN 1200",
          "1670 CC",
          "2EN",
          "1670",
          "STD",
          "1200",
          "1670"
        ]
      },
      {
        "model": "SZ",
        "variants": [
          "ALLOY WHEEL - DRUM BREAK - KICK START",
          "STANDARD",
          "SZ",
          "SZ BSIV",
          "STD",
          "RR V 2",
          "RR",
          "S",
          "R",
          "X",
          "STD"
        ]
      },
      {
        "model": "ALBA",
        "variants": [
          "MAG WHEEL- KICK START- DRUM BRK",
          "MAG WHEEL- ELECTRIC START- DRUM BRK",
          "SPOKE WHEEL- KICK START- DRUM BRK",
          "G5",
          "KICK",
          "SELF",
          "STD",
          "106 ES",
          "106 KS",
          "5TS6",
          "5TS9",
          "KICK START",
          "KICK START ALLOY WHEEL",
          "SELF START",
          "STD"
        ]
      },
      {
        "model": "YBR 125",
        "variants": [
          "SPOKE WHEEL- KICK START- DRUM BRK",
          "SS (ELECTRIC START ALLOY WHEEL DISK BRK)",
          "STANDARD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "FZ 1",
        "variants": [
          "SUPER SPORT MOTORCYCLE"
        ]
      },
      {
        "model": "SS 125",
        "variants": [
          "ELECTRIC START DISC BRK ALLOY WHEEL",
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "FZ SERIES",
        "variants": [
          "FZ6 N S2"
        ]
      },
      {
        "model": "ENTICER",
        "variants": [
          "SPOKE WHEEL- KICK START- DRUM BRK",
          "DLX (SPOKE WHEEL - ELECTRIC START - DISC BREAK)",
          "STANDARD",
          "STD",
          "DELUXE",
          "DLX",
          "DLX DISC BRAKE",
          "KICK START",
          "LE",
          "STD"
        ]
      },
      {
        "model": "SZ-R",
        "variants": [
          "ALLOY WHEEL - DISC BREAK - ELECTRIC START",
          "STD"
        ]
      },
      {
        "model": "LIBERO",
        "variants": [
          "G5 -ALLOY WHEEL ES",
          "STANDARD",
          "STD",
          "STD",
          "G5 (106 CC)",
          "DLX (106 CC)",
          "LX",
          "DLX",
          "G5",
          "G5 ALLOY WHEEL",
          "GS",
          "LX"
        ]
      },
      {
        "model": "SZ-X",
        "variants": [
          "ALLOY WHEEL - DRUM BREAK - ELECTRIC START",
          "STD"
        ]
      },
      {
        "model": "YBR 110",
        "variants": [
          "ELECTRIC START ALLOY WHEEL DRUM BRK",
          "STANDARD"
        ]
      },
      {
        "model": "GLADIATOR",
        "variants": [
          "SS",
          "ELECTRIC START DISC BRK ALLOY WHEEL",
          "STANDARD",
          "TYPE RS",
          "GLADIATOR",
          "RS",
          "SS",
          "STD",
          "TYPE JA",
          "DX",
          "GRAFFITI",
          "TYPE SS",
          "DX",
          "ES",
          "SS",
          "GIRAFFITI",
          "DLX",
          "DLX ALLOY WHEEL",
          "DISC BRAKE",
          "GRAFFITI",
          "JA",
          "KICK START",
          "RS",
          "STD",
          "SELF START"
        ]
      },
      {
        "model": "G5",
        "variants": [
          "ALLOY WHEEL ES",
          "ES",
          "(ALLOYS)",
          "STD",
          "ES",
          "G-5",
          "KS",
          "STD"
        ]
      },
      {
        "model": "MT01",
        "variants": [
          "1670 CC MOTORCYCLE",
          "STANDARD",
          "1670"
        ]
      },
      {
        "model": "SZ RR",
        "variants": [
          "ALLOY WHEEL - DISC BREAK - ELECTRIC START",
          "STANDARD",
          "SZ RR",
          "SZ RR",
          "STD",
          "V 2.0"
        ]
      },
      {
        "model": "CRUX S",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "FAZER FI V 2.0",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "LIBERO LX G-5",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "LIBERO LX",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CRUX R",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "RXZ",
        "variants": [
          "STANDARD",
          "135",
          "STD",
          "STD"
        ]
      },
      {
        "model": "R6",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "YD",
        "variants": [
          "125",
          "125",
          "125 STD"
        ]
      },
      {
        "model": "FZ16",
        "variants": [
          "STANDARD",
          "S",
          "V3.0 ABS",
          "STD",
          "153",
          "STD",
          "V2",
          "V2"
        ]
      },
      {
        "model": "FZ S",
        "variants": [
          "STANDARD",
          "F1 V2",
          "FI V3 BS6",
          "V 2.0 REAR DISC",
          "V 3.0 ABS",
          "V3",
          "FI DARK KINGHT BSVI",
          "FI DARK KINGHT BT BSVI",
          "FI V2",
          "FI V3 VINTAGE EDITION BS6",
          "FI V3 DARK KNIGHT BT BS6",
          "FI BT BS6",
          "(N)",
          "LTD",
          "153",
          "STANDARD",
          "FI V3 DLX BS6",
          "STD"
        ]
      },
      {
        "model": "SZ X",
        "variants": [
          "STANDARD",
          "SZ X"
        ]
      },
      {
        "model": "SZ R",
        "variants": [
          "STANDARD",
          "SZ R"
        ]
      },
      {
        "model": "RAY-Z",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "RAY ZR 125",
        "variants": [
          "DRUM",
          "DISC",
          "STREET RALLY"
        ]
      },
      {
        "model": "FZ S V 2.0 FI",
        "variants": [
          "MATT GREEN"
        ]
      },
      {
        "model": "FZ25",
        "variants": [
          "STANDARD",
          "STD"
        ]
      },
      {
        "model": "YZF R15 V3",
        "variants": [
          "STANDARD",
          "DUAL CHANNEL ABS BS VI",
          "DARKNIGHT ABS BS VI",
          "RACING BLUE ABS BS VI",
          "DARK NIGHT BSVI",
          "DARK NIGHT BSVI",
          "MONSTER ENERGY EDITION",
          "METALLIC RED BS6"
        ]
      },
      {
        "model": "FASCINO 125",
        "variants": [
          "STANDARD DRUM BRAKE",
          "STANDARD DISC BRAKE",
          "DLX DRUM BRAKE",
          "DLX DISC BRAKE"
        ]
      },
      {
        "model": "FZ S V 2.0",
        "variants": [
          "LTD EDITION",
          "DARKNIGHT",
          "DARKNIGHT-REAR DISC",
          "LTD EDITION- REAR DISC",
          "STANDARD"
        ]
      },
      {
        "model": "FZ V 2.0",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "CYGNUS RAY ZR",
        "variants": [
          "DARKNIGHT",
          "STREET RALLY",
          "DRUM-UBS",
          "DISC-UBS",
          "DARKNIGHT-UBS",
          "STREET RALLY-UBS",
          "MONSTER ENERGY EDITION",
          "DISC",
          "DRUM",
          "125 FI BS6",
          "125 STREET RALLY BS6"
        ]
      },
      {
        "model": "FZ1",
        "variants": [
          "STANDARD",
          "STD",
          "STD",
          "SUPER SPORT"
        ]
      },
      {
        "model": "MT-09",
        "variants": [
          "900CC",
          "STANDARD"
        ]
      },
      {
        "model": "RX135",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SALUTO RX",
        "variants": [
          "STANDARD",
          "DARKNIGHT",
          "STANDARD UBS",
          "DARKNIGHT UBS",
          "STD"
        ]
      },
      {
        "model": "VMAX",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "YZF R1M",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "X MAX",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "FZ S V 3.0",
        "variants": [
          "SINGLE CHANNEL ABS BS VI",
          "SINGLE CHANNEL ABS DARK BS VI",
          "VINTAGE",
          "VINTAGE BLUETOOTH",
          "STANDARD BLUETOOTH",
          "DARK KNIGHT BLUETOOTH"
        ]
      },
      {
        "model": "MT-15",
        "variants": [
          "SPECIAL EDITION BS VI",
          "2",
          "V2Â MOTOGP EDITION",
          "ABS",
          "STD BS6",
          "BS VI",
          "SINGLE CHANNEL ABS",
          "SPECIAL EDITION BS6",
          "MOTOGP EDITION",
          "V2 BS6"
        ]
      },
      {
        "model": "FZ V 3.0",
        "variants": [
          "BS VI",
          "ABS",
          "SINGLE CHANNEL ABS BS VI"
        ]
      },
      {
        "model": "R-15",
        "variants": [
          "V4 BSVI"
        ]
      },
      {
        "model": "R15",
        "variants": [
          "BSIV",
          "V3",
          "S",
          "SPECIAL EDITION",
          "STD BSVI",
          "V3 ABS BSVI",
          "V3 ABS LTD",
          "LE BS6",
          "V4 M",
          "V4 MONSTER ENERGY EDITION",
          "V4 METALLIC RED",
          "V4 DARK KNIGHT",
          "V4 RACING BLUE",
          "LTD (WHITE)",
          "LTD (RED)",
          "150",
          "153",
          "(NEW)",
          "(LTD)",
          "(SPL)",
          "M",
          "V4.0 ABS BS6",
          "STD"
        ]
      },
      {
        "model": "FZS L2 BS4",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "AEROX 155",
        "variants": [
          "MOTOGP EDITION"
        ]
      },
      {
        "model": "ESCORTS ACE",
        "variants": [
          "ESCORT ACE 175"
        ]
      },
      {
        "model": "FZ 16",
        "variants": [
          "FI",
          "V2.0",
          "FI BS-6",
          "150",
          "V3 ABS",
          "ST",
          "2GS1",
          "153 CC",
          "MIDNIGHTSPECIAL EDITION 153",
          "LIMITED EDITION 153"
        ]
      },
      {
        "model": "MT 01",
        "variants": [
          "MT 01",
          "STD"
        ]
      },
      {
        "model": "RAJDOOT",
        "variants": [
          "STD",
          "OLD MODEL",
          "STD",
          "DELUX",
          "STANDARD",
          "EXCEL-T",
          "RD 350",
          "STD"
        ]
      },
      {
        "model": "RX 135",
        "variants": [
          "STD",
          "STD",
          "STD"
        ]
      },
      {
        "model": "SS",
        "variants": [
          "125",
          "125",
          "125 SELF START"
        ]
      },
      {
        "model": "YBR",
        "variants": [
          "110",
          "STD",
          "110",
          "125",
          "125 SELF START",
          "125 KICK START",
          "110 STD"
        ]
      },
      {
        "model": "YBX",
        "variants": [
          "STD",
          "125",
          "135"
        ]
      },
      {
        "model": "YZF",
        "variants": [
          "125",
          "R15 S",
          "R15 V3 ABS",
          "R15 V3 MOTOGP EDITION",
          "R15 V3 DUAL CHANNEL ABS",
          "R15 V3",
          "R15 V4 M - MOTOGP EDITION",
          "R15 V3 DARK KNIGHT BS6",
          "R3",
          "R125",
          "R6",
          "R15",
          "R1"
        ]
      },
      {
        "model": "ALBA 106",
        "variants": [
          "ALBA 106"
        ]
      },
      {
        "model": "R1",
        "variants": [
          "2SG 100",
          "STD"
        ]
      },
      {
        "model": "R 15",
        "variants": [
          "ABS",
          "V4 DUAL CHANNEL ABS BT",
          "V4 DARK NIGHT ABS BT",
          "M DUAL CHANNEL ABS BT",
          "M MOTOGP EDITION ABS BT",
          "RACING BLUE BLUTOOTH",
          "MONSTER BCW4",
          "V4 ABS BS6",
          "S V3 0"
        ]
      },
      {
        "model": "RD 500",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FINO",
        "variants": [
          "SCOOTER",
          "STD",
          "115"
        ]
      },
      {
        "model": "BWS 125",
        "variants": [
          "125 FI"
        ]
      },
      {
        "model": "JOGR",
        "variants": [
          "50",
          "50"
        ]
      },
      {
        "model": "MAJESTY",
        "variants": [
          "125"
        ]
      },
      {
        "model": "NOZZA GRANDE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "XMAX",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RAYZR",
        "variants": [
          "ZR HYBRID DISC BT",
          "HYBRID DRUM",
          "STREETRALLY HYBRID BSVI",
          "HYBRID FI DISC STD BSVI",
          "HYBRID FI DISC DLX BSVI"
        ]
      },
      {
        "model": "RAY ZR",
        "variants": [
          "DRUM",
          "STD"
        ]
      },
      {
        "model": "RXG",
        "variants": [
          "135",
          "STD",
          "135 CC",
          "STD"
        ]
      },
      {
        "model": "RX 100",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FAZER.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RAJDOOT 350",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "YAMAHA YZF R1",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "YAMAHA ZF 600R",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RD 154",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "ACE 175",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RDX",
        "variants": [
          "STD",
          "STD"
        ]
      },
      {
        "model": "YD 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "YBX 125",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CRUX.",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "YAMAHA FASCINO",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "YAMAHA FAZER",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "YAMAHA FZ-S",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "YAMAHA-FZ",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CYGNUS",
        "variants": [
          "ALPHA",
          "RAY",
          "RAY Z"
        ]
      },
      {
        "model": "FZ-S",
        "variants": [
          "F1 BSVI",
          "FI BSVI",
          "FI ABS",
          "153 CC"
        ]
      },
      {
        "model": "JUPITER",
        "variants": [
          "MX 135"
        ]
      },
      {
        "model": "XJR",
        "variants": [
          "1300"
        ]
      },
      {
        "model": "YZF R15 V4",
        "variants": [
          "M",
          "DARK KNIGHT",
          "RACING BLUE",
          "METALLIC RED"
        ]
      },
      {
        "model": "XJR 1300",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "RAY-ZR",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "R-3",
        "variants": [
          "3 ABS"
        ]
      },
      {
        "model": "MT",
        "variants": [
          "09 ABS",
          "09 STD"
        ]
      },
      {
        "model": "FZ-X",
        "variants": [
          "STD",
          "BLUETOOTH"
        ]
      },
      {
        "model": "RD",
        "variants": [
          "135 CC",
          "350 STD"
        ]
      },
      {
        "model": "FZ S LIMITED EDITION",
        "variants": [
          "153"
        ]
      },
      {
        "model": "FZ S MIDNIGHT SPECIAL EDITION",
        "variants": [
          "153"
        ]
      },
      {
        "model": "V",
        "variants": [
          "MAX"
        ]
      },
      {
        "model": "NEW YZF",
        "variants": [
          "R1"
        ]
      },
      {
        "model": "FAZER MIDNIGHT SPECIAL EDITION",
        "variants": [
          "LX"
        ]
      },
      {
        "model": "FZS FI V4",
        "variants": [
          "DELUXE"
        ]
      },
      {
        "model": "DRAG STAR",
        "variants": [
          "400"
        ]
      },
      {
        "model": "ENTICER SD",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "FAZER FI",
        "variants": [
          "V2.0",
          "STD"
        ]
      },
      {
        "model": "FZ16 FI",
        "variants": [
          "V2.0"
        ]
      },
      {
        "model": "FZ6",
        "variants": [
          "N S2"
        ]
      },
      {
        "model": "SZ-16",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "V STAR",
        "variants": [
          "1100",
          "650 CLASSIC"
        ]
      },
      {
        "model": "XTZ",
        "variants": [
          "125 STD"
        ]
      },
      {
        "model": "YZF R6",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DELTA",
        "variants": [
          "EXUP 800"
        ]
      },
      {
        "model": "FZ-FI",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "CYGNUS RAY",
        "variants": [
          "ZR"
        ]
      },
      {
        "model": "TTR",
        "variants": [
          "600 STD"
        ]
      },
      {
        "model": "SD",
        "variants": [
          "250 STD"
        ]
      },
      {
        "model": "SZ-S",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "YAMMA",
    "models": [
      {
        "model": "GT TIGER",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "VPRO",
        "variants": [
          "E SCOOTER"
        ]
      },
      {
        "model": "VPRO 2",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "YASH",
    "models": [
      {
        "model": "ACUTE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "DOCILE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "PRETTY",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "WHIZ",
        "variants": [
          "STD"
        ]
      }
    ]
  },
  {
    "make": "YED ELECTRIC",
    "models": [
      {
        "model": "ANGEL PLUS",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "MARS",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "GALAXY",
        "variants": [
          "60 V"
        ]
      },
      {
        "model": "ZEUS",
        "variants": [
          "60 V"
        ]
      }
    ]
  },
  {
    "make": "YEZDI",
    "models": [
      {
        "model": "ROADSTER",
        "variants": [
          "GALLANT GREY",
          "SMOKE GREY",
          "DARK SMOKE GREY BSVI",
          "DARK STEEL BLUE BSVI",
          "DARK HUNTER GREEN BSVI",
          "CHROME GALLANT GREY BSVI",
          "CHROME SIN SILVER BSVI",
          "FIRE AND ICE BSVI",
          "GLACIAL WHITE BSVI",
          "INFERNO RED BSVI",
          "CRIMSON DUAL TONE BSVI",
          "DARK",
          "CHROME",
          "CRIMSON DUAL TONE"
        ]
      },
      {
        "model": "SCRAMBLER",
        "variants": [
          "BOLD BLACK - YELLING YELLOW - OUTLAW OLIVE",
          "FIRE ORANGE",
          "OUTLAW OLIVE",
          "MIDNIGHT BLUE",
          "REBEL RED",
          "MEAN GREEN",
          "BOLD BLACK",
          "YELLING YELLOW",
          "SINGLE TONE",
          "DUAL TONE"
        ]
      },
      {
        "model": "DELUXE",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "YEZDI 175",
        "variants": [
          "DELUXE"
        ]
      },
      {
        "model": "JAWA YEZADI",
        "variants": [
          "CL 2"
        ]
      },
      {
        "model": "ADVENTURE",
        "variants": [
          "SLICK SILVER",
          "MAMBO BLACK",
          "RANGER CAMO",
          "WHITEOUT",
          "MATTE",
          "CAMO"
        ]
      },
      {
        "model": "YEZDI 250",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "STD",
        "variants": [
          "250"
        ]
      }
    ]
  },
  {
    "make": "YO",
    "models": [
      {
        "model": "EXL",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPARK",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "ELECTRON",
        "variants": [
          "ER",
          "STANDARD"
        ]
      },
      {
        "model": "SMART",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPEED",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "STYLE",
        "variants": [
          "STANDARD",
          "ER"
        ]
      },
      {
        "model": "XPLOR",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "DRIFT",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "EDGE",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "YO BIKES",
    "models": [
      {
        "model": "SMART",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "SPIN",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "YO",
        "variants": [
          "STYLE.",
          "DRIFT STD",
          "EDGE STD",
          "DRIFT DX",
          "EDGE DX",
          "EXL",
          "SPEED",
          "XPLOR ER",
          "STYLE",
          "ELECTRON ER",
          "SPARK"
        ]
      }
    ]
  },
  {
    "make": "YO BYKES",
    "models": [
      {
        "model": "YO ELECTRON",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YO EXL",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YO SMART",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YO SPARK",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YO SPEED",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YO SPIN",
        "variants": [
          "STD"
        ]
      },
      {
        "model": "YO STYLE",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "YO XPLOR",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  },
  {
    "make": "YOGO",
    "models": [
      {
        "model": "SMART",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "TRENDY",
        "variants": [
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "YOUTH BIKES",
    "models": [
      {
        "model": "E TRENDY",
        "variants": [
          "E SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "YUG ELECTRIC",
    "models": [
      {
        "model": "YUG",
        "variants": [
          "400 60V 28AH LEAD ACID",
          "400 RED 60V 30AH LI ION",
          "400 BLACK 60V 30AH LI ION",
          "400 60V 25AH LI ION",
          "ECO 60V 25AH LI ION",
          "IGNOR 25AH LI ION",
          "IGNOR 60V 30AH",
          "IGNOR 60V 36AH",
          "400 60V 36AH LI ION"
        ]
      }
    ]
  },
  {
    "make": "YUGBIKE",
    "models": [
      {
        "model": "IGOR",
        "variants": [
          "ILS 60V/18AH",
          "IMS 60V/24AH",
          "ILX 60V/30AH",
          "ILZ 60V/18AH"
        ]
      },
      {
        "model": "YUG 400",
        "variants": [
          "YLS 60V/18AH",
          "YMS 60V/24AH",
          "YLX 60V/30AH",
          "YLZ 60V/18AH",
          "YLML 60V/28AH",
          "YLZ 60V/36AH"
        ]
      }
    ]
  },
  {
    "make": "YUKIE",
    "models": [
      {
        "model": "YUVEE",
        "variants": [
          "E BIKE",
          "STANDARD",
          "STD 60V",
          "DLX 60V"
        ]
      },
      {
        "model": "SHIGA",
        "variants": [
          "E BIKE",
          "STANDARD",
          "60V"
        ]
      },
      {
        "model": "TARZEN",
        "variants": [
          "E BIKE"
        ]
      },
      {
        "model": "TEASER",
        "variants": [
          "E BIKE",
          "60V"
        ]
      },
      {
        "model": "EARTH",
        "variants": [
          "LEAD ACID",
          "LITHIUM"
        ]
      },
      {
        "model": "X6",
        "variants": [
          "60V"
        ]
      },
      {
        "model": "TARZAN",
        "variants": [
          "72V"
        ]
      }
    ]
  },
  {
    "make": "YUKIE MOTORS",
    "models": [
      {
        "model": "TEASER",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "X6",
        "variants": [
          "LEAD ACID BATTERY 48V 28AH"
        ]
      },
      {
        "model": "YUVEE DLX",
        "variants": [
          "LEAD ACID BATTERY 48V 28AH"
        ]
      },
      {
        "model": "YUVEE STD",
        "variants": [
          "LEAD ACID BATTERY 48V 28AH"
        ]
      },
      {
        "model": "SHIGA",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "SHIGA PLUS",
        "variants": [
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "TARZAN",
        "variants": [
          "LEAD ACID BATTERY 72V 28AH"
        ]
      }
    ]
  },
  {
    "make": "YVS ENTERPRISES",
    "models": [
      {
        "model": "YUVAN",
        "variants": [
          "E SCOOTY"
        ]
      }
    ]
  },
  {
    "make": "ZELIO",
    "models": [
      {
        "model": "EEVA",
        "variants": [
          "E SCOOTER",
          "LITHIUM 60V 30AH",
          "60V-40AH",
          "GRACY"
        ]
      },
      {
        "model": "EEVA ZX",
        "variants": [
          "E SCOOTER",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "GRACY",
        "variants": [
          "E SCOOTER",
          "LITHIUM 60V 24AH",
          "E-SCOOTER",
          "SCOOTER ELECTRIC"
        ]
      },
      {
        "model": "GRACY 1",
        "variants": [
          "E SCOOTER",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "GRACY PRO",
        "variants": [
          "E SCOOTER",
          "LITHIUM 60V 30AH",
          "60V-40AH",
          "SCOOTER ELECTRIC"
        ]
      },
      {
        "model": "SPEEDX",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LITHIUM 60V 30AH"
        ]
      },
      {
        "model": "LEGENDER",
        "variants": [
          "LEAD ACID 60V 28AH",
          "LEAD ACID 72V 28AH",
          "LFP 60V 32AH",
          "ELECTRIC",
          "STD",
          "PLUS",
          "SCOOTER ELECTRIC"
        ]
      },
      {
        "model": "ALPHA PRO",
        "variants": [
          "LEAD ACID 60V 28AH"
        ]
      },
      {
        "model": "GRACY-I",
        "variants": [
          "60V-40AH"
        ]
      },
      {
        "model": "EEVA-ZX",
        "variants": [
          "60V-40AH",
          "SCOOTER ELECTRIC"
        ]
      },
      {
        "model": "SPEED-X",
        "variants": [
          "60V-40AH",
          "SCOOTER ELECTRIC"
        ]
      },
      {
        "model": "GRACYI",
        "variants": [
          "SCOOTER ELECTRIC"
        ]
      }
    ]
  },
  {
    "make": "ZELIO ELECTRIC VEHICLE",
    "models": [
      {
        "model": "EEVA",
        "variants": [
          "LEAD ACID BATTERY 48V 28AH",
          "LEAD ACID BATTERY 60V 28AH",
          "4B GEL BATTERY 48V"
        ]
      },
      {
        "model": "GRACY",
        "variants": [
          "4B GEL BATTERY 48V",
          "5B GEL BATTERY 60V",
          "LEAD ACID BATTERY 48V 28AH",
          "LEAD ACID BATTERY 60V 28AH",
          "LITHIUM BATTERY 26V 40AH"
        ]
      },
      {
        "model": "GRACY I",
        "variants": [
          "4B GEL BATTERY 48V",
          "5B GEL BATTERY 60V"
        ]
      },
      {
        "model": "EEVA ZX",
        "variants": [
          "LEAD ACID BATTERY 48V 28AH",
          "LITHIUM BATTERY 26V 40AH",
          "LEAD ACID BATTERY 60V 28AH"
        ]
      },
      {
        "model": "GRACY PRO",
        "variants": [
          "LITHIUM BATTERY 26V 40AH",
          "LEAD ACID BATTERY 48V 28AH",
          "LEAD ACID BATTERY 60V 28AH"
        ]
      }
    ]
  },
  {
    "make": "ZELO",
    "models": [
      {
        "model": "ZAEDEN",
        "variants": [
          "LITHIUM ION 48V 24AH",
          "ELECTIC SCOOTER"
        ]
      },
      {
        "model": "KNIGHT",
        "variants": [
          "LITHIUM ION 48V 24AH",
          "ELECTIC SCOOTER"
        ]
      },
      {
        "model": "ZOOP",
        "variants": [
          "LITHIUM ION 48V 24AH",
          "ELECTIC SCOOTER"
        ]
      }
    ]
  },
  {
    "make": "ZONTES",
    "models": [
      {
        "model": "350R",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "350X",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "GK350",
        "variants": [
          "STANDARD"
        ]
      },
      {
        "model": "350T",
        "variants": [
          "ADV",
          "STANDARD"
        ]
      }
    ]
  },
  {
    "make": "ZYPP ELECTRIC",
    "models": [
      {
        "model": "ZYPP COSBIKE",
        "variants": [
          "E BIKE"
        ]
      }
    ]
  }
]