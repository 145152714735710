import React, { useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import UnderConstruction from "../../../../src/images/UnderConstruction.jpg";

export default function Travel() {
  // ---- --useEffect---------
  useEffect(() => {
    // Add the "car-page" class to the body element when the component mounts
    document.body.classList.add("car-page");
    // Clean up function: Remove the "car-page" class when the component unmounts
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);
  // ---methods and jsx------

  return (
    <React.Fragment>
      <Container>
        <img src={UnderConstruction} alt="" width={"50%"} />
      </Container>
    </React.Fragment>
  );
}
