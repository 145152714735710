// LeadDetails.js

import React from 'react';
import { Modal, Button, Container, Row, Col, Form } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";

const LeadDetails = ({ lead }) => {
  return (

    <>
    <Container>
      <Row>
        <Col className="mb-3">
          <Form className="form-bg" >
            <Row>
              <Col sm={12} md={6} className="pt-2">
                <FloatingLabel controlId="firstName" label="First Name">
                  <Form.Control
                    name="firstName"
                    type="text"
                    value={lead.firstName}
                    className="floating-input"
                    placeholder="First Name"
                    disabled
                  />
                </FloatingLabel>
              </Col>
              <Col sm={12} md={6} className="pt-2">
                <FloatingLabel controlId="lastName" label="Last Name">
                  <Form.Control
                    name="lastName"
                    type="text"
                    value={lead.lastName}
                    className="floating-input"
                    placeholder="Last Name"
                  />
                </FloatingLabel>
               
              </Col>
              <Col sm={12} md={6} className="pt-2">
                <FloatingLabel controlId="email" label="Email">
                  <Form.Control
                    name="email"
                    type="email"      
                    value={lead.email}
                    className="floating-input"
                    placeholder="Email"
                  />
                </FloatingLabel>
                
              </Col>
              <Col sm={12} md={6} className="pt-2">
                <FloatingLabel controlId="mobile" label="Mobile">
                  <Form.Control
                    name="mobile"
                    type="text"
                    value={lead.mobile}
                    className="floating-input"
                    placeholder="Mobile Number"
                  />
                </FloatingLabel>
                
              </Col>
             
              <Col sm={12} md={12} className="d-flex pt-4"></Col>
            </Row>
            
          </Form>
        </Col>
      </Row>
    </Container>
  </>

    // <div>
    //   <h2>Lead Details</h2>
    //   <p>Name: {lead.name}</p>
    //   <p>Mobile No: {lead.mobile}</p>
    //   <p>Email ID: {lead.email}</p>
    //   <p>LOB: {lead.lob}</p>
    // </div>
  );
};

export default LeadDetails;
