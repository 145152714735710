import * as Yup from "yup";
const phoneRegExp = /^(0|91)?[6-9][0-9]{9}$/;
const aadhaarRegExp = /^[2-9]{1}[0-9]{11}$/;
class Validations {

 userValidations=Yup.object().shape({
    firstName: Yup.string()
      .required()
      .required(" First Name Is Required")
      .when("currentStepIndex", {
        is: "1",
        then: () =>
          Yup.string()
            .required(" Firts Name Is Required")
            .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
      }),
    lastName: Yup.string()
      .required()
      .required(" Last Name Is Required")
      .when("currentStepIndex", {
        is: "1",
        then: () =>
          Yup.string()
            .required(" Last Name Is Required")
            .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
      }),
    agencyName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Agency Name Is Required")
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
    }),
    agencyId: Yup.string().trim().required("Agency Id Is Required"),
    agentId: Yup.string().trim().required("Agent Id Is Required"),
    branchId: Yup.string().trim().required("Branch Id Is Required"),
    phoneNumber: Yup.string()
      .required()
      .matches(phoneRegExp, "Phone number is not valid"),
    address: Yup.string().required().required("Address Is Required"),
    reportingTo: Yup.string().required("Manager name  Is Required"),
    email: Yup.string().required().email(),
    userName: Yup.string().oneOf(
      [Yup.ref("email"), null],
      "User Name must match email"
    ),
    callerId: Yup.string().required("Caller ID is required"),
  });



  updatePasswordValidations=Yup.object().shape({
    oldPassword:  Yup.string()
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
    password: Yup.string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    newpassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });




  branchValidations=Yup.object().shape({
    //branchId: Yup.string().trim().required("Branch Id Is Required"),
    name: Yup.string()
      .required()
      .when("currentStepIndex", {
        is: "1",
        then: () =>
          Yup.string()
            .required(" Branch Name Is Required")
            .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
      }),
    address: Yup.string().required().matches(/^[a-zA-Z0-9 .,?!@#$%^&*()_+-=;:'"|\\/]*$/, {
      message: ''
    }),
 lineOfBusiness: Yup.array().required().min(1, 'At least one Line of Business is required').required('Line of Business is required'),
//   Yup.string()
//  .required('lineOfBusiness is required'),
//     city: Yup.string()
//       .required()
//       .when("currentStepIndex", {
//         is: "1",
//         then: () =>
//           Yup.string()
//             .required(" City Is Required")
//             .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
//       }),
    state: Yup.string()
      .required()
      .when("currentStepIndex", {
        is: "1",
        then: () =>
          Yup.string()
            .required(" State Is Required")
            .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
      }),
    contactPersonName: Yup.string()
      .required().required("Contact Person Name is Required")
      .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Invalid name format")
      .min(1, "At least one name is required")
      .max(50, "Maximum of four names allowed"),
    mobileNumber: Yup.string()
      .required().required("Phone Number is Required")
      .matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string().required().email(),
    headOffice: Yup.string().required().required("Head Office name Is Required")
    .matches(/^[a-zA-Z0-9 .,?!@#$%^&*()_+-=;:'"|\\/]*$/, {
      message: ''
    }),
    pincode: Yup.number().typeError("Invalid Pincode").required().required("Pincode Is Required").min(100000, "Invalid Pincode").max(999999, "Invalid Pincode"),
  });

  branchViewValidations=Yup.object().shape({
    //branchId: Yup.string().trim().required("Branch Id Is Required"),
    name: Yup.string()
      .required()
      .when("currentStepIndex", {
        is: "1",
        then: () =>
          Yup.string()
            .required(" Branch Name Is Required")
            .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
      }),
    address: Yup.string().required().matches(/^[a-zA-Z0-9 .,?!@#$%^&*()_+-=;:'"|\\/]*$/, {
      message: ''
    }),
 lineOfBusiness: 
  Yup.string()
 .required('lineOfBusiness is required'),
    city: Yup.string()
      .required()
      .when("currentStepIndex", {
        is: "1",
        then: () =>
          Yup.string()
            .required(" City Is Required")
            .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
      }),
    state: Yup.string()
      .required()
      .when("currentStepIndex", {
        is: "1",
        then: () =>
          Yup.string()
            .required(" State Is Required")
            .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
      }),
    contactPersonName: Yup.string()
      .required().required("Contact Person Name is Required")
      .matches(/^[a-zA-Z]+(?: [a-zA-Z]+)*$/, "Invalid name format")
      .min(1, "At least one name is required")
      .max(50, "Maximum of four names allowed"),
    mobileNumber: Yup.string()
      .required().required("Phone Number is Required")
      .matches(phoneRegExp, "Phone number is not valid"),
    email: Yup.string().required().email(),
    headOffice: Yup.string().required().required("Head Office name Is Required")
    .matches(/^[a-zA-Z0-9 .,?!@#$%^&*()_+-=;:'"|\\/]*$/, {
      message: ''
    }),
    pincode: Yup.number().typeError("Invalid Pincode").required().required("Pincode Is Required").min(100000, "Invalid Pincode").max(999999, "Invalid Pincode"),
  });


  AddAgentValidation=Yup.object().shape({
    firstName: Yup.string()
      .required()
      .required(" First Name Is Required")
      .when("currentStepIndex", {
        is: "1",
        then: () =>
          Yup.string()
            .required(" Firts Name Is Required")
            .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
      }),
    lastName: Yup.string()
      .required()
      .required(" Last Name Is Required")
      .when("currentStepIndex", {
        is: "1",
        then: () =>
          Yup.string()
            .required(" Last Name Is Required")
            .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
      }),
    agencyName: Yup.string()
      .required(" Agency Name Is Required")
      .when("currentStepIndex", {
        is: "1",
        then: () =>
          Yup.string()
            .required(" Agency Name Is Required")
            .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
      }),
    agencyId: Yup.string().trim().required("Agency Id Is Required"),
    // agentId: Yup.string().trim().required("Agent Id Is Required"),
    branchId: Yup.string().trim().required("Branch Id Is Required"),
    phoneNumber: Yup.string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number is not valid"),
    address: Yup.string().matches(/^[a-zA-Z0-9 .,?!@#$%^&*()_+-=;:'"|\\/]*$/, {
      message: ''
    }).required().required("Address Is Required"),
    reportingTo: Yup.string().required("Manager name  Is Required"),
    email: Yup.string().required("Email is Required").email(),
    userName: Yup.string().oneOf(
      [Yup.ref("email"), null],
      "User Name must match email"
    ),
    callerId: Yup.string().required("Caller ID is required"),
    role: Yup.string().required("Designation is required"),

    maritalStatus: Yup.string().required("Marital status need"),
    gender: Yup.string().required("Gender is need")
  });

  passwordUpdateValidation =Yup.object().shape({
    id: Yup.string().required("ID is required"),
    newpassword: Yup.string()
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
      confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("newpassword"), null], "Passwords must match"),
  });

  AddAgencyValidation = Yup.object().shape({
    agencyName: Yup.string().required().matches(/^[a-zA-Z0-9 .,&-]*$/, {
      message: ''
    }).required('Agency Name is required'),
  agencyType: Yup.string().required().required('Agency Type is required'),
  lineOfBusiness: Yup.array().required().min(1, 'At least one Line of Business is required').required('Line of Business is required'),
  status: Yup.string().required().required('Status is required')
  });
  
  AgencyViewValidation = Yup.object().shape({
    id: Yup.string().matches(/^[a-zA-Z0-9_-]*$/, {
      message: ''
    }).trim().required("Id Is Required"),
    agencyName: Yup.string()
      .required(" Agency Name Is Required")
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
    lineOfBusiness: Yup.mixed().required(),
    createdBy: Yup.string().required(),
    status: Yup.string().required(),
  });

  BuyInsuranceValidation = Yup.object().shape({
    firstName: Yup.string().required().required("First Name is Required").matches(/^[a-zA-Z .]*$/, {
      message: ''
    }),
    lastName: Yup.string().required().required("Last Name is Required").matches(/^[a-zA-Z .]*$/, {
      message: ''
    }),
    email: Yup.string().required().required("Email Name is Required").email(),
    mobile: Yup.string().nullable().required().required("Mobile Number is required").matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits'),
    lineOfBusiness: Yup.mixed().required().required("Line Of Business Name is Required"),
    
  });
  

  OnboardingPageValidation = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string().email().required("Email is required"),
    address: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    pinCode: Yup.number().required("Pin Code is required").min(100000, "Invalid Pincode").max(999999, "Invalid Pincode"),
    gender: Yup.string().required("Gender is required"),
    maritalStatus: Yup.string().required("Marital Status is required"),
    high_Qualification: Yup.string().required("Highest Qualification is required"),
    board: Yup.string().required("Board/University is required"),
    photo: Yup.mixed(),
    accountNo: Yup.string().required("Account Number is required"),
    ifscCode: Yup.string().required("IFSC Code is required"),
    accHolderName: Yup.string().required("Account Holder Name is required"),
    bankPhoto: Yup.mixed(),
    phoneNumber: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
    panNo: Yup.string()
    .trim()
    .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN Number")
    .required("PAN Number Is Required"),
    aadharNo:Yup.string()
    .matches(aadhaarRegExp, 'Aadhaar number is not valid')
    .required('Aadhaar number is required'),
    pospNumber: Yup.string().required("POSP Number is required")
  });

  LeadUpdateValidation= Yup.object().shape({
    customer_name: Yup.string().nullable().required('Customer name is required'),
    test_motor_lead_1: Yup.string().nullable(),
    mobile_number: Yup.string().nullable().matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits'),
    rtoRegNo: Yup.string().nullable(),
    caller_name: Yup.string().nullable(),
    email_id: Yup.string().nullable().email('Invalid email address'),
    residential_address: Yup.string().nullable(),
    date_of_birth: Yup.date().nullable(),
    date_of_anniversary: Yup.date().nullable(),
    pan_number: Yup.string().nullable(),
    aadhar_no: Yup.string().nullable().matches(/^[0-9]{12}$/, 'Aadhar number must be 12 digits'),
    driving_licence_no: Yup.string().nullable(),
    make: Yup.string().nullable(),
    model: Yup.string().nullable(),
    varient: Yup.string().nullable(),
    registration_date: Yup.date().nullable(),
    Policy_Exp_date: Yup.date().nullable(),
    policy_no: Yup.string().nullable(),
    insurance_company: Yup.string().nullable(),
    chassis_no: Yup.string().nullable(),
    engine_no: Yup.string().nullable(),
    customer_reference_name: Yup.string().nullable(),
    customer_reference_number: Yup.string().nullable(),
  });

}

export default new Validations();
