import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import { useHistory } from "react-router-dom";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";

const BajajUploadDocForKYC = (props) => {
  const [base64String, setBase64String] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState(null);

  const validationSchema = Yup.object({
    kycDocumentCategory: Yup.string().required("Document type is required"),
    documentNumber: Yup.string()
    .matches(/^(?:(?:(?:[A-Z0-9]{8,})|(?:\w{3}\d{7})|(?:\w{2}\d{13})|(?:\d{4})|(?:\d{12})|(?:\d{18})))$/, 'Invalid document number')
    .required('Document number is required'),
    
    documentArray: Yup.mixed().required("Document image is required"),
  });

  const formik = useFormik({
    initialValues: {
      documentExtension: "jpeg",
      kycDocumentCategory: "", // Added field for document type
      documentNumber: "", // Added field for document number
      documentArray: null, // Added field for document image
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      props.onSubmit(values)
     // console.log(values);
    },
  });

 

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      setBase64String(reader.result);
      formik.setFieldValue("documentArray", reader.result);
    };

    reader.readAsDataURL(file);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleRedirect = (e) => {
    e.preventDefault();
    window.open('/','_self')
  };

  // console.log("base64String", base64String);

  return (
    <React.Fragment>
      <Container>
        <Row>
          <h4 className="mb-5 mt-5">Details for KYC</h4>
          <Col className="mb-3">
            <Form className="form-bg" onSubmit={formik.handleSubmit}>
              <Row style={{ textAlign: "left" }}>
                <Col md={6} sm={12} className="mb-3">
                  <FloatingLabel
                    controlId="kycDocumentCategory"
                    label="Document Type*"
                    className=""
                    aria-autocomplete="off"
                  >
                    <Form.Select
                      name="kycDocumentCategory"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.kycDocumentCategory}
                      className="floating-input"
                    >
                      <option value="">Select Document Type</option>
                      <option value="A">Passport</option>
                      <option value="B">Voter ID</option>
                      <option value="D">Driving License</option>
                      <option value="E">UID</option>
                      <option value="F">NREGA Job card</option>
                    </Form.Select>
                  </FloatingLabel>
                  <Form.Text className="text-danger">
                    {formik.touched.kycDocumentCategory &&
                    formik.errors.kycDocumentCategory ? (
                      <div className="text-danger">
                        {formik.errors.kycDocumentCategory}
                      </div>
                    ) : null}
                  </Form.Text>
                </Col>

                <Col md={6} sm={12} className="mb-3">
                  <FloatingLabel
                    controlId="documentNumber"
                    label="Document Number*"
                    className=""
                    aria-autocomplete="off"
                  >
                    <Form.Control
                      name="documentNumber"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.documentNumber}
                      className="floating-input"
                    />
                  </FloatingLabel>
                  <Form.Text className="text-danger">
                    {formik.touched.documentNumber &&
                    formik.errors.documentNumber ? (
                      <div className="text-danger">
                        {formik.errors.documentNumber}
                      </div>
                    ) : null}
                  </Form.Text>
                </Col>

                <Col md={6} sm={12} className="mb-3">
                  <FloatingLabel
                    controlId="documentArray"
                    label="Upload Document Image*"
                    className=""
                    aria-autocomplete="off"
                  >
                    <Form.Control
                      name="documentArray"
                      type="file"
                      accept=".jpeg"
                      onChange={handleFileInputChange}
                      onBlur={formik.handleBlur}
                      className="floating-input"
                    />
                  </FloatingLabel>
                  <Form.Text className="text-danger">
                    {formik.touched.documentArray &&
                    formik.errors.documentArray ? (
                      <div className="text-danger">
                        {formik.errors.documentArray}
                      </div>
                    ) : null}
                  </Form.Text>
                </Col>
              </Row>
              <Button
                className="primary-btn"
                style={{ alignItems: "right", marginRight: "5px" }}
                type="submit"
                disabled={Object.keys(formik.errors).length > 0}
              >
                Submit
              </Button>

              <Button
                type="button"
                className="btn-secondary"
                disabled={!formik.dirty}
                onClick={handleRedirect}
              >
                Cancel
              </Button>
            </Form>

            {showPopup && (
              <Modal show={showPopup} onHide={handleClosePopup}>
                <Modal.Header closeButton>
                  <Modal.Title>Regrate:KYC Failed</Modal.Title>
                </Modal.Header>
                {/* <Modal.Body>KYC Failed</Modal.Body> */}
                <Modal.Footer>
                  <Button
                    type="button"
                    className="primary-btn"
                    onClick={handleRedirect}
                  >
                    Okay!
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};
export default BajajUploadDocForKYC;
