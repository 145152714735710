import axios from "axios";
import AuthHeader from "./auth-header";

const AGENCY_URL = process.env.REACT_APP_AGENCY_URL;

class AgentService {
  getCustomerInformation(getCustomerInformationRequest) {
    let lastUrl;
    if(getCustomerInformationRequest.module==='health'){
        lastUrl='getcustomerhealthinfo'
    }else if(getCustomerInformationRequest.module==='life'){
        lastUrl='getcustomerlifeinfo'
    }else if(getCustomerInformationRequest.module==='motor'){
        lastUrl='GetCustomerInfo'
    }
    return axios.post(
      `${AGENCY_URL}/${lastUrl}`,
      getCustomerInformationRequest,
      {
        headers: AuthHeader(),
      }
    );
  }

  getLeadInfoByID(getLeadRequest) {
    return axios.post(
      `${AGENCY_URL}/GetLead`,
        getLeadRequest ,
      {
        headers: AuthHeader(),
      }
    );
  }


  getLeadsListAgainstAgentID(getLeadsListAgainstAgentRequest) {

    return axios.post(
      `${AGENCY_URL}/`,
      getLeadsListAgainstAgentRequest,
      {
        headers: AuthHeader(),
      }
    );
  }

  createUpdateBT(createUpdateBT){
    return axios.post(
        `${AGENCY_URL}/CreateUpdateTran`,
        createUpdateBT
    )
  }

  getCustomer(customerDataRequest){
    return axios.post(
      `${AGENCY_URL}/ListLeads`,
      customerDataRequest,
      {
        headers: AuthHeader(),
      }
    )
  }

}

export default new AgentService();
