import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Button} from "react-bootstrap";
export default function DownloadFileFromCRM() {
  const { mobileNo } = useParams();
  const { pKey } = useParams();
  useEffect(() => {
    downloadFileFromAPI();
  }, []);

  const downloadFile = (url) => {
    window.location.href = url;
    //console.log("urlllllll",url)
    // const link = document.createElement("a");
    // link.href = url;
    // link.download = "document";
    // link.target = "_blank";
    // document.body.appendChild(link);
    // setTimeout(() => {
    //   link.click();
    //   document.body.removeChild(link);
    // }, 500);
  };

  const downloadFileFromAPI = () => {
    const apiUrl =
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_POLICY_URL;

    // Define request data
    const requestData = {
      header: {
        transactionName: "policyDocDownloadService",
        messageId: "",
        deviceId: "ABCD12345",
      },
      body: {
        mobile: mobileNo,
        pKey: pKey,
      },
    };

    // Make POST request to the API
    axios
      .post(apiUrl, requestData)
      .then((response) => {
        // Extract URL from response data
        const responseData = JSON.parse(response.data);
        const downloadLink = responseData.data.DownloadLink;
        // Trigger file download using the obtained URL
        downloadFile(downloadLink);
      })
      .catch((error) => {
        console.error("Error downloading file from API:", error);
      });
  };

  return(
    <></>
    // <>
    // <Button  className="primary-btn"           style={{ alignItems: "right", marginRight: "5px" }}
    //       type="submit" onClick={()=>{
    //     window.location.reload()
    // }}>
    //     Reload
    //     </Button>
    //     </>
  )
}
