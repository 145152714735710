export const bikeData=["2WI",
    "2WIC",
    "2WN",
    "2WN",
    "2WT",
    "2WT",
    "Adapted Vehicle(2WIC)",
    "INVALID CARRIAGE - MOTOR CYCLE",
    "M_CYC",
    "MCY",
    "MCYCLE",
    "MCYCLE-MOPED",
    "MCYCLE  MOTOR CYCLE",
    "MCYCLE MOTOR CYCLE",
    "MCYCLE-MOTOR CYCLE",
    "M-Cycle/Scooter",
    "M-CYCLE/SCOOTER",
    "MCYCLE-SCOOTER",
    "M-Cycle/Scooter(2WN)",
    "M-CYCLE/SCOOTER(2WN)",
    "M-Cycle/Scooter-With Side Car",
    "M-Cycle/Scooter-With Side Car(2WN)",
    "MCYL",
    "MCYL  MOTOR CYCLE",
    "MCYL MOTOR CYCLE",
    "MCYN",
    "MOTOR CYCLE WITH SIDE CAR",
    "Moped",
    "MOPED",
    "Moped(2WN)",
    "MOPED(SCR)",
    "MOTOR CYCLE",
    "MOTOR CYCLE - ABOVE 95CC",
    "MOTOR CYCLE - ABOVE 95CC(N)",
    "MOTOR CYCLE - BELOW 95CC",
    "Motor Cycle/Scooter-Used For Hire",
    "Motor Cycle/Scooter-Used For Hire(2WT)",
    "MOTOR CYCLE (SCR)",
    "MOTOR CYCLE(SCR)",
    "MOTOR CYCLE WITH GEAR",
    "Motorised Cycle",
    "Motorised Cycle (CC > 25cc)",
    "Motorised Cycle (CC > 25cc)(2WN)",
    "SCOOTER",
    "SCOOTER/MOPED",
    "SCOOTER/SCOOTERATE",
    "SCOOTER(SCR)",
    "SCR",
    "SCOOTER WITHOUT GEAR",
    "SCOOTER WITH SIDE CAR",
    "SCOTER/MOPED",
    "SCOTER  SCOOTER",
    "SCOTER SCOOTER",
    "SCTR  SCOOTER",
    "SCTR SCOOTER",
    "TWO WHEELER (Invalid Carriage)",
    "TWO WHEELER(NT)",
    "TWO WHEELER(T)",
    "CC > 25cc",
    "CC > 25CC",
    "MCWSC-MOTOR CYCLE WITH SIDE",
    "MMCN",
    ]
    
    export const carData = [
        "4WI",
        "4WIC",
        "Adapted Vehicle(4WIC)",
        "CAMP VAN",
        "CAR",
        "CAR - P",
        "Cash Van",
        "Cash Van(LGV)",
        "Cash Van(OTH)",
        "CSH VAN",
        "DELIVERY VAN",
        "DELIVERY VAN(LGV)",
        "DELUXE TAXI",
        "EDU VAN",
        "EXPLOSIVE VAN",
        "FOUR WHEELER (Invalid Carriage)",
        "Hearses",
        "Hearses(LGV)",
        "INVCAR-INVALID CARRIAGE",
        "Invalid Carriage(4WIC)",
        "Jeep",
        "JEEP",
        "JEEP/GYPSY",
        "JEEP TAXI",
        "JEEPTX",
        "LAB VAN",
        "Library Van",
        "Luxory Tourist Cab",
        "Luxury Cab",
        "Luxury Cab(LPV)",
        "MAXCAB",
        "MAXCAB-MAXI CAB",
        "Maxi Cab",
        "MAXI CAB",
        "Maxi Cab(LPV)",
        "MAXI CAB(LPV)",
        "MOB WORK VAN",
        "MOTCAB-MOTOR CAB",
        "MOTOR CAB / CAR TAXI(LPV)",
        "Motor Cab(LPV)",
        "MOTOR CAB(LPV)",
        "Motor Cab",
        "MOTOR CAB",
        "MOTOR CAB / CAR TAXI",
        "Motor Cab(LMV)",
        "Motor Car",
        "MOTOR CAR",
        "Motor Car(LGV)",
        "Motor Car(LMV)",
        "MOTOR CAR(LMV)",
        "ORDINARY TAXI",
        "ORDTAXI",
        "PICK UP VAN",
        "PICKUP VAN",
        "QUADRI TAXI",
        "SCRT",
        "Snorked Ladders",
        "Quadricycle",
        "Semi Luxory Tourist Cab",
        "TEMPO",
        "TOURIST MOTOR CAB / TAXI",
        "TRSTTX  TOURIST TAXI",
        "VAN",
        "TRSTTX  TOURIST TAXI",
        "TSTMCB  TOURIST  MAXI CAB",
        "VAN",
        "LMV"
      ];
      

