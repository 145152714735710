import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import AuthService from "../../services/auth-service";
import { useHistory } from "react-router-dom";
import UserContext from "../../components/Context/UserContext";
import UseFormContext from "../../context/UseFormContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";

export default function Login(props) {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);
  const formContext = UseFormContext();
  const { setUser } = useContext(UserContext);
  const history = useHistory();

  const [data, setData] = useState({
    username: "",
    password: "",
    loginAs: "USER",
  });
  const [formErrors, setFormErrors] = useState({
    username: false,
    password: false,
    loginAs: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({
      ...data,
      [name]: value,
    });
    setFormErrors({
      ...formErrors,
      [name]: value === "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.values(formErrors).some((error) => error)) {
      // If any field is empty, do not proceed
      return;
    }

    try {
      const userData = await AuthService.login(
        data.username,
        data.password,
        data.loginAs
      );
      setUser(userData.loginAs);
      sessionStorage.setItem("isAdmin", "yes");
      formContext.setloginType("yes");
      //history.push("/");
    } catch (error) {
      alert(
        "Please check your email & password. If you are unable to log in, kindly contact the administrator for assistance."
      );
      console.error("Login failed:", error);
    }
  };

  return (
    <div className="bg-image signin-wrap">
      <div className="inner-sign-in">
        <form className="Auth-form" onSubmit={handleSubmit}>
          <div className="Auth-form-content">
            <h3 className="Auth-form-title">Login</h3>

            <div className="form-group mt-3">
              <FloatingLabel
                controlId="floatingInput"
                label="Email"
                className=""
              >
                <Form.Control
                  type="email"
                  name="username"
                  className="floating-input"
                  onChange={handleInputChange}
                  required
                />
              </FloatingLabel>

              <FloatingLabel controlId="floatingInput" label="Password*">
                <Form.Control
                  name="password"
                  type={showPassword ? "text" : "password"}
                  className="floating-input"
                  onChange={handleInputChange}
                  required
                />
                <span
                  className="position-absolute end-0 top-0 mt-2 me-3"
                  onClick={toggleShowPassword}
                  style={{ cursor: "pointer" }}
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </span>
              </FloatingLabel>

              {/* 
              <FloatingLabel
                controlId="floatingInput"
                label="Password"
                className=""
              >
                <Form.Control
                  type="password"
                  name="password"
                  className="floating-input"
                  onChange={handleInputChange}
                  required
                />
              </FloatingLabel> */}
            </div>
            <div className="inner-sign-in">
              <button
                id="btn-login"
                type="submit"
                className="primary-btn"
                disabled={Object.values(formErrors).some((error) => error)}
              >
                Submit
              </button>
            </div>
            <p className="forgot-password text-right mt-2">
              New Here ? <a href="onBoardingPage"> Register As a POSP</a>
            </p>

            {/* <p className="forgot-password text-right mt-2">
              <a href="resetPassword">Forgot password?</a>
            </p> */}
          </div>
        </form>
      </div>
    </div>
  );
}
