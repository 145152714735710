import { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "./Popup.css";
import { useParams, useHistory } from "react-router-dom";
import AdminService from "../../../services/admin.service";
import AuthService from "../../../services/auth-service";
import Validations from "../../../validations/validations";
import { decryptData } from "../../../services/auth-service";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import ErrorMessage from "../../commonModules/ErrorMessage";
import { FaDownload } from "react-icons/fa";
const Popup = ({ onClose, show }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>User Updated Successfully !!</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button type="button" className="primary-btn" onClick={onClose}>
          Okay!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function ContainerForPospView(props) {
  const { email } = props.email;
  console.log(email);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [initialData, setInitialData] = useState(null);
  const [imageForBank, setImageForBank] = useState("");
  const [imageForEducation, setImageForEducation] = useState("");

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      console.log("no user");
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  const formik = useFormik({
    initialValues: initialData,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("values", values);
    },
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const data = { email: email };
      console.log("data to send", data);
      await AdminService.getUserByEmail(data).then(async (res) => {
        console.log("res", res.data.data);
        const userData = res?.data?.data;
        setInitialData(userData);
        formik.setValues(userData);

        const imgForBank = await fetchImageForBank(
          userData?.bankDocumentId,
          "Bank Details Proof"
        );
        setImageForBank(imgForBank);

        const imgForEducation = await fetchImageForBank(
          userData?.educationDocumentId,
          "Education Details Proof"
        );
        setImageForEducation(imgForEducation);
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const base64ToBlob = (base64, contentType) => {
    const byteCharacters = atob(base64);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  };

  const createDownloadLink = (base64Data, extension) => {
    const contentType = `application/${extension}`;
    const blob = base64ToBlob(base64Data, contentType);
    const url = URL.createObjectURL(blob);

    // Set the download URL to state
    return url;
  };

  const fetchImageForBank = async (bankId, title) => {
    try {
      const res = await AdminService.getDocument({ documentId: bankId });
      const parts = bankId.split(".");
      const extension = parts[parts.length - 1];
      const base64Data = res?.data?.data;

      if (!base64Data) {
        console.error("Document data is missing");
        return null;
      }
      console.log("res for doc", base64Data);
      let url = createDownloadLink(base64Data, extension);

      return (
        <>
          <img
            style={{ alignContent: "right", width: "450px", height: "300px" }}
            src={`data:image/${extension};base64,${base64Data}`}
            alt="Bank Document"
          />

          <Row>
            <a
              style={{ marginTop: "10px" }}
              href={url}
              // {`data:image/${extension};base64,${base64Data}`}
              download={`bank-document.${extension}`}
            >
              {" "}
              {title}
              <FaDownload
                style={{
                  color: "black",
                  marginLeft: "5px",
                  //   width: "30px",
                  //   height: "30px",
                }}
                title="Download"
              />
            </a>
          </Row>
        </>
      );
    } catch (error) {
      console.error("Error fetching the document:", error);
    }
  };


  const handleDeactivate = async () => {
    const confirmation = window.confirm("Are you sure ?");
    if (confirmation) {
      const userData = formik.values;
      userData.status =
        formik.values.status === "ACTIVE" ? "DEACTIVE" : "ACTIVE";
      try {
        await AuthService.onBoarding(userData).then((dataRes) => {
          console.log("dataRes", dataRes);
          window.location.href = "/pospusers"
        });
        setShowPopup(true);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
    fetchData();
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = formik.values;
      const response = await AuthService.onBoarding(data);
      console.log("Response:", response.data);
      setShowPopup(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setIsEditing(false);
    window.location.href = "/pospusers";
  };

  return (
    <>
      {initialData && initialData !== undefined && initialData !== null ? (
        <>
          <Form className="mb-2">
            <Row style={{ textAlign: "left" }}>
              <h4 className="mb-3 left-align mt-5 align-left">
                Personal Details
              </h4>

              <Col className="mb-5">
                <div className="form-bg">
                  <Row style={{ textAlign: "left" }}>
                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="First Name"
                        className=""
                      >
                        {isEditing ? (
                          <Form.Control
                            name="firstName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            className="floating-input"
                          />) : (<Form.Control
                            name="firstName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.firstName}
                            className="floating-input"
                            disabled
                          />)}

                        <Form.Text className="text-danger">
                          {formik.touched.firstName &&
                            formik.errors.firstName ? (
                            <div className="text-danger">
                              {formik.errors.firstName}
                            </div>
                          ) : null}
                        </Form.Text>
                      </FloatingLabel>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Last Name"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="lastName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.lastName}
                          className="floating-input"
                        /> :
                          <Form.Control
                            name="lastName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.lastName}
                            className="floating-input"
                            disabled
                          />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.lastName && formik.errors.lastName ? (
                          <div className="text-danger">
                            {formik.errors.lastName}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Email ID"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="email"
                          type="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          className="floating-input"
                        /> :
                          <Form.Control
                            name="email"
                            type="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            className="floating-input"
                            disabled
                          />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.email && formik.errors.email ? (
                          <div className="text-danger">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>

                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Phone Number"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="phoneNumber"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.phoneNumber}
                          className="floating-input"
                        /> :
                          <Form.Control
                            name="phoneNumber"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.phoneNumber}
                            className="floating-input"
                            disabled
                          />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.phoneNumber &&
                          formik.errors.phoneNumber ? (
                          <div className="text-danger">
                            {formik.errors.phoneNumber}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="POSP Number"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="pospNumber"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.pospNumber}
                          className="floating-input"
                        /> : <Form.Control
                          name="pospNumber"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.pospNumber}
                          className="floating-input"
                          disabled
                        />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.pospNumber &&
                          formik.errors.pospNumber ? (
                          <div className="text-danger">
                            {formik.errors.pospNumber}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="PAN No"
                        className=""
                      >
                        <Form.Control
                          name="panNo"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.panNo}
                          className="floating-input"
                          disabled
                        />
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.panNo && formik.errors.panNo ? (
                          <div className="text-danger">
                            {formik.errors.panNo}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Aadhar No"
                        className=""
                      >
                        <Form.Control
                          name="aadharNo"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.aadharNo}
                          className="floating-input"
                          disabled
                        />
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.aadharNo && formik.errors.aadharNo ? (
                          <div className="text-danger">
                            {formik.errors.aadharNo}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>

                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Address"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="address"
                          type="textarea"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.address}
                          className="floating-input"
                          as="textarea"
                        /> :
                          <Form.Control
                            name="address"
                            type="textarea"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.address}
                            className="floating-input"
                            as="textarea"
                            disabled
                          />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.address && formik.errors.address ? (
                          <div className="text-danger">
                            {formik.errors.address}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Pin Code"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="pinCode"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.pinCode}
                          className="floating-input"
                        />
                          : <Form.Control
                            name="pinCode"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.pinCode}
                            className="floating-input"
                            disabled
                          />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.pinCode && formik.errors.pinCode ? (
                          <div className="text-danger">
                            {formik.errors.pinCode}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="City"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="city"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.city}
                          className="floating-input"
                        />
                          : <Form.Control
                            name="city"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.city}
                            className="floating-input"
                            disabled
                          />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.city && formik.errors.city ? (
                          <div className="text-danger">
                            {formik.errors.city}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>
                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="State"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="state"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.state}
                          className="floating-input"
                        />
                          : <Form.Control
                            name="state"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.state}
                            className="floating-input"
                            disabled
                          />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.state && formik.errors.state ? (
                          <div className="text-danger">
                            {formik.errors.state}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>

                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Gender"
                        className=""
                      >
                        <Form.Control
                          name="gender"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.gender}
                          className="floating-input"
                          disabled
                        />
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.gender && formik.errors.gender ? (
                          <div className="text-danger">
                            {formik.errors.gender}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>

                    <Col sm={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="maritalStatus"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="Marital Status"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.maritalStatus}
                          className="floating-input"
                        /> :
                          <Form.Control
                            name="Marital Status"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.maritalStatus}
                            className="floating-input"
                            disabled
                          />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.maritalStatus &&
                          formik.errors.maritalStatus ? (
                          <div className="text-danger">
                            {formik.errors.maritalStatus}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>
                    <Col m={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Highest Qualification"
                        className=""
                      >
                        {isEditing ? <Form.Select
                          name="high_Qualification"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.high_Qualification}
                          className="floating-input"
                        >
                          <option value="">Select...</option>
                          <option value="HSC">HSC</option>
                          <option value="SSC">SSC</option>
                          <option value="Graduation">Graduate</option>
                          <option value="postGraduation">Post Graduate</option>
                        </Form.Select> :
                          <Form.Control
                            name="high_Qualification"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={
                              formik.values.high_Qualification ===
                                "postGraduation"
                                ? "Post Graduate"
                                : formik.values.high_Qualification ===
                                  "Graduation"
                                  ? "Graduate"
                                  : formik.values.high_Qualification
                            }
                            className="floating-input"
                            disabled
                          />}

                        <Form.Text className="text-danger">
                          {formik.touched.high_Qualification &&
                            formik.errors.high_Qualification ? (
                            <div className="text-danger">
                              {formik.errors.high_Qualification}
                            </div>
                          ) : null}
                        </Form.Text>
                      </FloatingLabel>
                    </Col>

                    <Col m={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Board/University"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="board"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.board}
                          className="floating-input"
                        /> : <Form.Control
                          name="board"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.board}
                          className="floating-input"
                          disabled
                        />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.board && formik.errors.board ? (
                          <div className="text-danger">
                            {formik.errors.board}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>
                    <Col m={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Account Number"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="accountNo"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.accountNo}
                          className="floating-input"
                        />
                          : <Form.Control
                            name="accountNo"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.accountNo}
                            className="floating-input"
                            disabled
                          />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.accountNo && formik.errors.accountNo ? (
                          <div className="text-danger">
                            {formik.errors.accountNo}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>

                    <Col m={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="IFSC Code"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="ifscCode"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.ifscCode}
                          className="floating-input"
                        />
                          : <Form.Control
                            name="ifscCode"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.ifscCode}
                            className="floating-input"
                            disabled
                          />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.ifscCode && formik.errors.ifscCode ? (
                          <div className="text-danger">
                            {formik.errors.ifscCode}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>

                    <Col m={12} md={6} lg={4} className="pt-2">
                      <FloatingLabel
                        controlId="floatingInput"
                        label="Account Holder Name"
                        className=""
                      >
                        {isEditing ? <Form.Control
                          name="accHolderName"
                          type="text"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.accHolderName}
                          className="floating-input"
                        />
                          : <Form.Control
                            name="accHolderName"
                            type="text"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.accHolderName}
                            className="floating-input"
                            disabled
                          />}
                      </FloatingLabel>
                      <Form.Text className="text-danger">
                        {formik.touched.accHolderName &&
                          formik.errors.accHolderName ? (
                          <div className="text-danger">
                            {formik.errors.accHolderName}
                          </div>
                        ) : null}
                      </Form.Text>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>

            {/* <Row style={{ textAlign: "left" }}>
              <h4 className="mb-3 left-align mt-2 align-left">
                Education Details
              </h4>

              <Col className="mb-5">
                <div className="form-bg">
                  <Row style={{ textAlign: "left" }}>
                    

                    <Col sm={12} md={6} lg={6} className="pt-2"></Col>
                  </Row>
                </div>
              </Col>
            </Row> */}

            {/* <Row style={{ textAlign: "left" }}>
              <h4 className="mb-3 left-align mt-2 align-left">Bank Details</h4>
              <Col className="mb-5">
                <div className="form-bg">
                  <Row style={{ textAlign: "left" }}>
                  
                  </Row>
                </div>
              </Col>
            </Row> */}

            <Row style={{ textAlign: "center" }}>
              <h4 className="mb-3 left-align mt-2 align-left" style={{ marginBottom: "200px" }}>Documents</h4>

              <Col className="mb-5">{imageForBank}</Col>
              <Col className="mb-5">{imageForEducation}</Col>
            </Row>

            {!isEditing ? (
              <Button
                className="primary-btn"
                style={{ marginRight: "5px" }}
                type="submit"
                onClick={handleEdit}
              >
                Edit
              </Button>
            ) : (
              <Button
                className="primary-btn"
                style={{ alignItems: "right", marginRight: "5px" }}
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            )}
            {!isEditing ? (
              formik.values.status === "ACTIVE" ? (
                <Button
                  type="button"
                  className="btn-secondary"
                  onClick={handleDeactivate}
                >
                  Deactivate
                </Button>
              ) : (
                <Button
                  type="button"
                  className="btn-secondary"
                  onClick={handleDeactivate}
                >
                  Activate
                </Button>
              )
            ) : (
              <>
                <Button
                  type="button"
                  className="btn-secondary"
                  onClick={handleCancel}
                >
                  { }
                  Cancel
                </Button>
              </>
            )}

            {/* <Row style={{ textAlign: "center" , alignContent:"center", alignItems:"center"}}>
              <Col className="mb-5">
                <Row style={{ textAlign: "center" }}>
                  <Col sm={12} md={12} className="d-flex"> */}
            {/* <Button className="primary-btn" type="submit">
                      {formik.values.status === "ACTIVE"
                        ? "Deactivate"
                        : "Activate"}
                    </Button> */}
            {/* </Col>
                </Row>
              </Col>
            </Row> */}
          </Form>
          {showPopup && (
            <Popup
              // message={popupMessage}
              // message1={popupMessage1}
              onClose={handleClosePopup}
              show={true}
            />
          )}
        </>
      ) : (
        <span>No data Found for {email}</span>
      )}
    </>
  );
}
