import React, { useEffect, useState, useRef } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Logo from "../../images/logo.png";
import { NavLink, useLocation } from "react-router-dom";
import {
  routesArrayForAgent,
  routesArrayForAgentLobMotor,
  routesArrayForAgentLobLife,
  routesArrayForAgentLobHealth,
  routesArrayForPosp,
} from "../../routing/RoutingPaths";
import { routesArrayForAdmin } from "../../routing/RoutingPaths";
import {
  routesArrayForCustomer,
  routesArrayForCustomerAfterLogin,
} from "../../routing/RoutingPaths";
import { routesArrayForManager } from "../../routing/RoutingPaths";
import { decryptData } from "../../services/auth-service";
import AdminService from "../../services/admin.service";
import UseFormContext from "../../context/UseFormContext";
import adminService from "../../services/admin.service";
var CryptoJS = require("crypto-js");

function Header() {
  const location = useLocation();
  const [pathName, setPathName] = useState();
  const [user, setUser] = useState(null);
  const [routesArray, setRoutesArray] = useState(routesArrayForCustomer);
  const [routesArrayTemp, setRoutesArrayTemp] = useState([]);
  const rerendered = useRef(false); // useRef to track if component has been rerendered
  const formContext = UseFormContext();

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  useEffect(() => {
    const decryptUser = async () => {
      const encryptedUser = localStorage.getItem("user");
      const normalUser = localStorage.getItem("normalUser");
      if (normalUser) {
        setUser({ ...user, role: "CUSTOMER" });
      } else if (encryptedUser) {
        try {
          console.log("encryptedUser::", encryptedUser);
          const decryptedUser = await decryptData(encryptedUser);
          console.log("decryptedUser.email", decryptedUser.email);
          console.log("request", AdminService.getUserInfoByEmail.toString());
          await AdminService.getUserInfoByEmail({
            email: decryptedUser.email,
          }).then(async (res) => {
            if (res.data?.data?.phoneNumber) {
              console.log("headers data", res.data?.data);
              const phoneNumber = res.data?.data?.phoneNumber;
              const encryptNo = CryptoJS.AES.encrypt(
                phoneNumber,
                "dynamipass"
              ).toString();
              sessionStorage.setItem("userLoggin", encryptNo);
              sessionStorage.setItem("callerID", res.data?.data?.callerId);
              localStorage.setItem("ownerPhoneNumber1", phoneNumber);
            }
            if (res.data?.data?.id) {
              const id = res.data?.data?.id;
              sessionStorage.setItem("id", id);
            }
            if (res.data?.data?.agencyId) {
              const agencyId = res.data?.data?.agencyId;
              sessionStorage.setItem("agencyId", agencyId);
            }
            if (res.data?.data?.branchId) {
              const branchId = res.data?.data?.branchId;
              sessionStorage.setItem("branchId", branchId);
            }

            try {
              // Fetch branch data
              const branchResponse = await AdminService.getBranchByID({
                id: res?.data?.data?.branchId,
              });
              const branchData = branchResponse?.data?.data;

              // Fetch agency data
              const agencyResponse = await AdminService.getAgencyById({
                id: res?.data?.data?.agencyId,
              });
              const agencyData = agencyResponse?.data?.data;

              // Extract LOBs from branch and agency, assuming they are strings like "[HEALTH, LIFE]"
              const branchLobs = branchData?.lineOfBusiness
                .substring(1, branchData?.lineOfBusiness.length - 1)
                .split(", ");
              const agencyLobs = agencyData?.lineOfBusiness
                .substring(1, agencyData?.lineOfBusiness.length - 1)
                .split(", ");

              // Find the intersection of branchLobs and agencyLobs
              const combinedLobs = branchLobs.filter((lob) =>
                agencyLobs?.includes(lob)
              );
              localStorage.setItem("LOB", combinedLobs);
              console.log("combinedLobs", combinedLobs);

              // Get routes based on combined LOBs
              const concatenatedRoutes = getRoutesForLOBs(combinedLobs);

              // Set the routes array
              setRoutesArrayTemp(concatenatedRoutes);
            } catch (error) {
              console.error(error);
            }
          });

          setUser(decryptedUser);
        } catch (error) {
          console.error("Error decrypting user:", error);
        }
      } else {
      }
    };
    decryptUser();
  }, []);

  const getRoutesForLOBs = (lobs) => {
    let concatenatedRoutes = [];
    lobs.forEach((lob) => {
      switch (lob) {
        case "HEALTH":
          concatenatedRoutes = [
            ...concatenatedRoutes,
            ...routesArrayForAgentLobHealth,
          ];
          break;
        case "LIFE":
          concatenatedRoutes = [
            ...concatenatedRoutes,
            ...routesArrayForAgentLobLife,
          ];
          break;
        case "MOTOR":
          concatenatedRoutes = [
            ...concatenatedRoutes,
            ...routesArrayForAgentLobMotor,
          ];
          break;
        default:
          break;
      }
    });
    return concatenatedRoutes;
  };

  // useEffect(() => {
  //   const setRoutes = async () => {
  //     console.log(".............", location, user);
  //     if (location.pathname === "/myaccount" && !user) {
  //       setRoutesArray(routesArrayForCustomerAfterLogin);
  //     } else {
  //       switch (user?.role) {
  //         case "AGENT":
  //           let arr = [...routesArrayTemp, ...routesArrayForAgent];
  //           setRoutesArray(arr);
  //           break;
  //         case "ADMIN":
  //           setRoutesArray(routesArrayForAdmin);
  //           break;
  //         case "MANAGER":
  //           setRoutesArray(routesArrayForManager);
  //           break;
  //         case "CUSTOMER":

  //           setRoutesArray(routesArrayForCustomerAfterLogin);
  //           break;
  //         case "POSP":
  //           setRoutesArray(routesArrayForPosp);
  //           break;
  //         default:

  //           if ( sessionStorage.getItem("userLoggin")) {
  //               console.log(">>>>>>>>customer");
  //             setRoutesArray(routesArrayForCustomerAfterLogin);
  //           } else {
  //                console.log(">>>>>>>>default");
  //             setRoutesArray(routesArrayForCustomer);
  //           }
  //       }

  //     }
  //   };

  //   setRoutes();

  //   // Rerender the component only once after the switch is executed
  //   if (!rerendered.current) {
  //     rerendered.current = true;
  //     // Trigger a rerender by updating state
  //     setUser(user); // This will trigger a rerender but won't change the user state
  //   }
  // }, [user]);

  useEffect(() => {
    const setRoutes = async () => {
      console.log(".............", location, user);
      if (location.pathname === "/myaccount" && !user) {
        setRoutesArray(routesArrayForCustomerAfterLogin);
      } else {
        switch (user?.role) {
          case "AGENT":
            let arr = [...routesArrayTemp, ...routesArrayForAgent];
            setRoutesArray(arr);
            break;
          case "ADMIN":
            setRoutesArray(routesArrayForAdmin);
            break;
          case "MANAGER":
            setRoutesArray(routesArrayForManager);
            break;
          case "CUSTOMER":
            setRoutesArray(routesArrayForCustomerAfterLogin);
            break;
          case "POSP":
            setRoutesArray(routesArrayForPosp);
            break;
          default:
            setRoutesArray(routesArrayForCustomer);
            break;
        }
      }
    };

    setRoutes();

    // Rerender the component only once after the switch is executed
    if (!rerendered.current) {
      rerendered.current = true;
      // Trigger a rerender by updating state
      setUser(user); // This will trigger a rerender but won't change the user state
    }
  }, [user]);

  const isLoginPage = () => {
    return pathName === "/Login";
  };

  return (
    <Navbar expand="lg" className="bg-body-tertiary header-wrap">
      <Container fluid>
        <Navbar.Brand>
          <img src={Logo} alt="" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        {!isLoginPage() && (
          <div className="pages-tab">
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav
                className="me-auto"
                style={{
                  gap: {
                    rowGap: "20px",
                  },
                }}
              >
                {routesArray?.map((data) => (
                  <NavLink
                    style={{
                      gap: {
                        rowGap: "20px",
                      },
                    }}
                    className={`nav-text ${
                      formContext.carbikeformikValues?.formtype ===
                        data?.type &&
                      (pathName === "/quotes" ||
                        pathName === "/proposal" ||
                        pathName === "/quotes/1" ||
                        pathName === "/proposal/2")
                        ? "active"
                        : ""
                    }`}
                    to={data.path}
                    key={data.heading}
                    onClick={() => {
                      if (data?.type === "myaccount") {
                        formContext.setPages("Myprofile");
                      } else if (data?.type === "agencylogin") {
                        formContext.setPages("Agencylogin");
                      }
                    }}
                  >
                    <strong>{data.heading}</strong>
                  </NavLink>
                ))}
              </Nav>
            </Navbar.Collapse>
          </div>
        )}
      </Container>
    </Navbar>
  );
}

export default Header;
