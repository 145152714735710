import { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "./Popup.css";
import { useParams, useHistory } from "react-router-dom";
import AgentService from "../../../services/agent.service";
import Validations from "../../../validations/validations";
import { decryptData } from "../../../services/auth-service";
import crmService from "../../../services/crm.service";
import { toast } from "react-toastify";

const Popup = ({ onClose, show }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Lead Updated Successfully !!</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button type="button" className="primary-btn" onClick={onClose}>
          Okay!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function ContainerForAgentView(props) {
  const initialValues = {
    customer_name: "",
    mobile_number: "",
    caller_name: "",
    email_id: "",
    residential_address: "",
    date_of_birth: "",
    date_of_anniversary: "",
    pan_number: "",
    aadhar_no: "",
    driving_licence_no: "",
    make: "",
    model: "",
    varient: "",
    registration_date: "",
    Policy_Exp_date: "",
    policy_no: "",
    insurance_company: "",
    chassis_no: "",
    engine_no: "",
    customer_reference_name: "",
    customer_reference_number: "",
  };

  // console.log("data", props.id);

  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      console.log("no user");
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [initialData, setInitialData] = useState({
    id: "",
    module: "",
    customerName: "",
    mobileNumber: "",
    alternateNumber1: "",
    alternateNumber2: "",
    alternateNumber3: "",
    emailID: "",
    residentialAddress: "",
    dateOfBirth: "",
    dateOfAnniversary: "",
    panNo: "",
    aadharNo: "",
    gstNo: "",
    drivingLicense: "",
    lineOfBusiness: "",
    leadCampaign: "",
    realTimeLead: "",
    callerName: "",
    policyNumber: "",
    policyExpiryDate: "",
    insuranceCompany: "",
    existingInsuranceCompany: "",
    policyType: "",
    sumAssured: "",
    state: "",
    pinCode: "",
    age: "",
    planType: "",
    planName: "",
    make: "",
    model: "",
    variant: "",
    vinNo: "",
    rtoRegNo: "",
    regDate: "",
    idv: "",
    chassisNumber: "",
    engineNumber: "",
    comment: "",
    link: "",
    remark: "",
  });
  const validationSchema = Validations.LeadUpdateValidation;

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: async  (values) => {
      //   handleSubmit();
      alert("values", values);
    
      
    },
  });

  useEffect(() => {
    fetchData();
  }, []);
  const { email } = useParams();

  const fetchData = async () => {
    try {
      await AgentService.getLeadInfoByID({ id: props.id }).then((res) => {
        console.log("res", res.data);
        setInitialData(res?.data?.data);
        formik.setValues(res?.data?.data);
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [value, setValue] = useState("");
  const maxLength = 256;
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setValue(inputValue);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setIsEditing(false);
    window.location.href = "/mycustomers";
  };

  const handleSubmit = async()=>{
     console.log("values",formik.values)
     await crmService
     .createLeadInMotorLocal(formik.values)
     .then((res) => {
       console.log("lead created locally", res.data);
       toast.success("Lead Updated successfully");
      //  
     }).catch((error)=>{
      console.log("error")
     })

     if(initialData.lineOfBusiness==="MOTOR"){
      await crmService
          .updateLeadMotor({
            customerName: formik.values.customerName ,
            mobileNumber: formik.values.mobileNumber,
            rtoNo: formik.values.rtoRegNo,
            emailId: formik.values.emailID,
          })
          .then((response) => {
            console.log(response);
            if (response.status === 200 || response.status === 201) {
              setShowPopup(true);
              toast.success("Lead Updated successfully");
              console.log("Lead Updated successfully");
            }
          })
          .catch((error)=>{
            toast.error("Error : ",error)
          })
     }
     else if(initialData.lineOfBusiness==="HEALTH"){
      await crmService
      .updateLeadLifeHealth({
        customerName: formik.values.customerName,
        mobileNumber: formik.values.mobileNumber,
        //caller_name: "NIB068",
        // rtoRegNo: formik.values.rtoRegNo,
        emailId: formik.values.emailID,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200 || response.status === 201) {
          setShowPopup(true);
          toast.success("Lead Updated successfully");
          console.log("Lead Updated successfully");
        }
      })
      .catch((error)=>{
        toast.error("Error : ",error)
      })
     }
     else if(initialData.lineOfBusiness==="LIFE"){
      await crmService
      .updateLeadLifeHealth({
        customerName: formik.values.customerName,
        mobileNumber: formik.values.mobileNumber,
        caller_name: "NIB068",
        // rtoRegNo: formik.values.rtoRegNo,
        emailId: formik.values.emailID,
      })
      .then((response) => {
        console.log(response);
        if (response.status === 200 || response.status === 201) {
          setShowPopup(true);
          toast.success("Lead Updated successfully");
          console.log("Lead Updated successfully");
        }
      })
      .catch((error)=>{
        toast.error("Error : ",error)
      })
     }
  }

  return (
    <>
      <>
        <Form className="form-bg" >
          <Row style={{ textAlign: "left" }}>
            {/* <h5 style={{alignContent: "left"}}>Basic Information</h5> */}
            <Col md={6} sm={12} className="mb-3">
              <FloatingLabel
                controlId="customer_name"
                label="Customer Name*"
                className=""
                aria-autocomplete="off"
              >
                <Form.Control
                  name="customerName"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.customerName}
                  className="floating-input"
                  disabled={!isEditing}
                />
              </FloatingLabel>
              <Form.Text className="text-danger">
                {formik.touched.customerName && formik.errors.customerName ? (
                  <div className="text-danger">
                    {formik.errors.customerName}
                  </div>
                ) : null}
              </Form.Text>
            </Col>

            <Col md={6} sm={12} className="mb-3">
              <FloatingLabel
                controlId="mobileNumber"
                label="Mobile Number*"
                className=""
                aria-autocomplete="off"
              >
                <Form.Control
                  name="mobileNumber"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.mobileNumber}
                  className="floating-input"
                  disabled={true}
                />
              </FloatingLabel>
              <Form.Text className="text-danger">
                {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                  <div className="text-danger">{formik.errors.mobileNumber}</div>
                ) : null}
              </Form.Text>
            </Col>

            <Col md={6} sm={12} className="mb-3">
              <FloatingLabel
                controlId="rtoRegNo"
                label="RTO Number"
                className=""
                aria-autocomplete="off"
              >
                <Form.Control
                  name="rtoRegNo"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.rtoRegNo}
                  className="floating-input"
                  disabled={!isEditing}
                />
              </FloatingLabel>
            </Col>
            <Col md={6} sm={12} className="mb-3">
              <FloatingLabel
                controlId="emailID"
                label="Email ID*"
                className=""
                aria-autocomplete="off"
              >
                <Form.Control
                  name="emailID"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailID}
                  className="floating-input"
                  disabled={!isEditing}
                />
              </FloatingLabel>
              <Form.Text className="text-danger">
                {formik.touched.emailID && formik.errors.emailID ? (
                  <div className="text-danger">{formik.errors.emailID}</div>
                ) : null}
              </Form.Text>
            </Col>
            {/* <Col md={6} sm={12} className="mb-3">
              <FloatingLabel
                controlId="residential_address"
                label="Residential Address"
                className=""
                aria-autocomplete="off"
              >
                <Form.Control
                  name="residential_address"
                  as="textarea"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.residential_address}
                  className="floating-input"
                  disabled={!isEditing}
                />
              </FloatingLabel>
            </Col>

            <Col md={6} sm={12} className="mb-3">
              <FloatingLabel
                controlId="date_of_birth"
                label="Date of Birth"
                className=""
                aria-autocomplete="off"
              >
                <Form.Control
                  name="date_of_birth"
                  type="date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.date_of_birth}
                  className="floating-input"
                  disabled={!isEditing}
                />
              </FloatingLabel>
            </Col> */}
          </Row>

          {!isEditing ? (
            <Button
              className="primary-btn"
              style={{ marginRight: "5px" }}
              //type="submit"
              onClick={handleEdit}
            >
              Edit
            </Button>
          ) : (
            <>
              <Button
                type="button"
                className="btn-secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                className="primary-btn"
                style={{ alignItems: "right", marginRight: "5px" }}
               // type="submit"
                onClick={handleSubmit}
                // onClick={formik.handleSubmit}
              >
                Save
              </Button>
            </>
          )}
        </Form>
        {showPopup && <Popup onClose={handleClosePopup} show={true} />}
      </>
    </>
  );
}
