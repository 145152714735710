export const carsJson = [
  {
    make: "AUDI",
    models: [
      {
        model: "80",
        variants: ["B2 1.6 L (1600cc)"],
      },
      {
        model: "A4",
        variants: [
          "30 TFSI TECHNOLOGY (1395cc)",
          "35 TSFI (1798cc)",
          "35 TSFI M (1798cc)",
          "35 TFSI (1395cc)",
          "45 TFSI PREMIUM (1997cc)",
          "1.8 MULTITRONIC (1781cc)",
          "1.8 TFSI NAVI EDITION (1798cc)",
          "1.8 TFSI PREMIUM EDITION (1798cc)",
          "1.8 TFSI TECHNOLOGY EDITION (1798cc)",
          "3.2 FSI QUATTRO WCI PACKAGE (3197cc)",
          "1.8 TFSI PREMIUM PLUS (1798cc)",
          "2.0 TDI (1968cc)",
          "2.0 TDI (1968cc)",
          "2.0 TDI (1968cc)",
          "2.0 TDI BUSINESS EDITION (1968cc)",
          "2.0 TDI NAVI EDITION (1968cc)",
          "2.0 TDI PREMIUM EDITION (1968cc)",
          "2.0 TDI PREMIUM PLUS (1968cc)",
          "3.0 TDI QUATTRO WCI PACKAGE (2967cc)",
          "2.0 TDI WCI PACKAGE (1968cc)",
          "3.0 TDI QUATTRO UNIQUE (2967cc)",
          "35 TDI TECHNOLOGY (1968cc)",
          "30 TFSI TECHNOLOGY PACK (1395cc)",
          "35 TDI (1984cc)",
          "35 TDI PREMIUM (1968cc)",
          "30 TFSI PREMIUM PLUS (1984cc)",
          "30 TFSI PREMIUM PLUS (1395cc)",
          "1.8 TFSI (1798cc)",
          "1.8 TFSI (1798cc)",
          "2.0 TFSI (1968cc)",
          "3.2 FSI QUATTRO (3197cc)",
          "2.0 TDI MULTITRONIC (1968cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "35 TDI PREMIUM PLUS (1968cc)",
          "35 TDI MULTITRONIC (1968cc)",
          "30 TFSI TECHNOLOGY (1968cc)",
          "3.0 TDI QUATTRO WCI PACKAGE (1968cc)",
          "3.0 TDI QUATTRO UNIQUE (1968cc)",
          "30 TFSI PREMIUM PLUS (1968cc)",
          "2.0 TFSI (1984cc)",
          "2.0 TFSI (1984cc)",
          "3.0 TDI QUATTRO (1968cc)",
          "2.0 FSI (1984cc)",
          "2.0 PREMIUM (1984cc)",
          "45 TFSI PREMIUM PLUS (1984cc)",
          "CELEBRATION EDITION (1968cc)",
          "1.4 TFSI (1395cc)",
          "LIFESTYLE EDITION (1395cc)",
          "1.4TFSI MULTITRONIC (1395cc)",
          "3.0 TDI QUATTRO WCI PACKAGE (3197cc)",
          "S4 3.0 TFSI QUATTRO (2995cc)",
          "3.2 FSI QUATTRO (3188cc)",
          "40 TFSI PREMIUM (1984cc)",
          "40 TFSI TECHNOLOGY (1984cc)",
          "40 TFSI PREMIUM PLUS (1984cc)",
          "40 TFSI S TRONIC PREMIUM PLUS (1984cc)",
          "40 TFSI S TRONIC TECHNOLOGY (1984cc)",
          "3.0 TDI (2967cc)",
          "3.0 TDI QUATTRO TECHNOLOGY PACK (2967cc)",
          "3.0 TDI QUATTRO PREMIUM (2967cc)",
          "3.0 TDI QUATTRO PREMIUM PLUS (2967cc)",
          "1.8 T (1798cc)",
          "35 TFSI 170 PS PREMIUM (1798cc)",
          "35 TFSI 170 PS PREMIUM WITH SUNROOF (1798cc)",
          "35 TFSI MULTITRONIC (1798cc)",
          "1.8 TFSI MULTITRONIC PREMIUM (1798cc)",
          "1.8 TFSI MULTITRONIC PREMIUM PLUS (1798cc)",
          "1.8 TFSI MULTITRONIC TECHNOLOGY PACK (1798cc)",
          "35 TFSI PREMIUM SUNROOF (1798cc)",
          "35 TFSI PREMIUM (1798cc)",
          "2.0 TDI CELEBRATION EDITION (1968cc)",
          "2.0 TDI PREMIUM SPORT (1968cc)",
          "B9 35 TDI TECHNOLOGY PACK (1968cc)",
          "35 TDI 177 PS PREMIUM (1968cc)",
          "35 TDI 177 PS PREMIUM WITH SUNROOF (1968cc)",
          "35 TDI 177 PS PREMIUM SPORT (1968cc)",
          "35 TDI 177 PS PREMIUM SPORT WITH SUNROOF (1968cc)",
          "35 TDI 177 PS TECHNOLOGY (1968cc)",
          "2.0 TDI PREMIUM PLUS WITH SUNROOF (1968cc)",
          "35 TDI 177 PS TECHNOLOGY WITH SUNROOF (1968cc)",
          "2.0 TDI TECHNOLOGY EDITION (1968cc)",
          "35 TDI PREMIUM SPORT (1968cc)",
          "35 TDI PREMIUM SPORT + SUNROOF (1968cc)",
          "35 TDI PREMIUM SUNROOF (1968cc)",
          "2.0 TDI (177BHP) TECHNOLOGY PACK (1968cc)",
          "2.0 TDI (143BHP) (1968cc)",
          "2.0 TDI (177BHP) PREMIUM PLUS (1968cc)",
          "35 TDI TECHNOLOGY PACK (1968cc)",
          "2.0 TDI (177BHP) PREMIUM (1968cc)",
          "B9 35 TDI PREMIUM PLUS (1968cc)",
          "35 TDI (1968cc)",
          "30 TSFI PREMIUM PLUS (1395cc)",
          "30 TFSI (1395cc)",
          "B9 30 TFSI TECHNOLOGY PACK (1395cc)",
          "30 TFSI TECHNOLOGY EDITION (1395cc)",
          "35 TFSI PREMIUM PLUS (1395cc)",
          "35 TFSI QUICK LIFT PREMIUM PLUS (1395cc)",
          "35 TFSI QUICK LIFT TECHNOLOGY PACK (1395cc)",
          "35 TFSI QL TECHNOLOGY (1395cc)",
          "35 TFSI QL PREMIUM PLUS (1395cc)",
          "3.2 FSI TIPTRONIC QUATTRO (3197cc)",
          "1.8 T MULTITRONIC (1781cc)",
        ],
      },
      {
        model: "RS5",
        variants: [
          "4.2 FSI (4163cc)",
          "COUPE (2894cc)",
          "4.2 FSI (184 KW) (4163cc)",
          "4.2 COUPE (4163cc)",
          "COUPE 5 STR (4163cc)",
          "4.2 FSI (184 KW) 5 SEAT (4163cc)",
          "COUPE (4163cc)",
          "COUPE (4163cc)",
          "COUPE (4163cc)",
          "TFSI QUATTRO (2984cc)",
          "2.9 TFSI QUATTRO (2984cc)",
          "COUPE (4200cc)",
          "SPORTBACK (2894cc)",
          "SPORTBACK (2894cc)",
        ],
      },
      {
        model: "TT",
        variants: [
          "45 TFSI (2995cc)",
          "COUPE 2.0 TFSIQ (1984cc)",
          "COUPE (3189cc)",
          "ROADSTER (3189cc)",
          "QUATTRO COUPE 3.2 (3189cc)",
          "3.2 COUPE QUATTRO (3189cc)",
          "TT COUPE (3189cc)",
          "COUPE 45 TFSI S TRONIC (1989cc)",
          "COUPE DIESEL (2000cc)",
          "RS QUATTRO (2496cc)",
          "2.0 TFSI Q (1984cc)",
          "108 (1781cc)",
          "3.2 S TRONIC QUATTRO (3189cc)",
          "45 TFSI 5STR (1984cc)",
          "2.0 TFSI QUATTRO (1984cc)",
          "45 TFSI (1984cc)",
          "QUATRA (1984cc)",
          "2.0 FSI (1984cc)",
          "2.0 COUPE TFSI QUATTRO S (1984cc)",
          "2.0 COUPE TFSI QUATTRO (1984cc)",
          "2.0 COUPE TFSI QUATTRO 4 STR (1984cc)",
          "45 TFSI 4 STR (1984cc)",
          "45 TFSI QUATTRO (1984cc)",
          "COUPE 2.0 TFSIQ 4 SEAT (1984cc)",
          "COUPE 45 TFSI (1984cc)",
          "COUPE 45 TFSI 4 SEATER (1984cc)",
          "COUPE 3.2 S-TRONIC QUATTRO (3189cc)",
          "COUPE 3.2  TFSI QUATTRO 2 SEAT (3189cc)",
          "COUPE 3.2  S TRONIC QUATTRO 4 SEAT (3189cc)",
          "1.8 COUPE (1781cc)",
          "COUPE (1781cc)",
          "COUPE 108 (1781cc)",
          "45 TFSI (1948cc)",
        ],
      },
      {
        model: "A8",
        variants: [
          "L 60 TFSI QUATTRO (6299cc)",
          "L 50 TDI QUATTRO (6299cc)",
          "L 50 TDI QUATTRO PREMIUM PLUS (6299cc)",
          "L 60 TDI QUATTRO (6299cc)",
          "3.0 TDI QUATTRO -DIESEL (2967cc)",
          "L 50 TDI QUATTRO PREMIUM PLUS5 SEATER KW 184 (2987cc)",
          "L 50 TDI QUATTRO 5 SEATER KW 184 (2987cc)",
          "L 60 TDI (4172cc)",
          "4.2 QUATTRO (4172cc)",
          "L W12 (6200cc)",
          "L 4.0 TFSI QUATTRO STANDARD (3993cc)",
          "L 60 TFSI QUATTRO (3993cc)",
          "L W12 QUATTRO (6299cc)",
          "L 50 TDI QUATTRO (2967cc)",
          "L 50 TDI QUATTRO PREMIUM PLUS (2967cc)",
          "L 50 TDI QUATTRO PREMIUM PLUS (2967cc)",
          "L 60 TDI QUATTRO (4134cc)",
          "3.0TDI QUATTRO PANORAMIC SR (2967cc)",
          "6.3 W12 QUATTRO (6300cc)",
          "4.2 TDI QUATTRO ADVANCE PACK (4134cc)",
          "CELEBRATION EDITION HYBRID (2995cc)",
          "TECHNOLOGY EDITION HYBRID (2995cc)",
          "4.2 FSI QUATTRO (4163cc)",
          "6.3 FSI W12 (6300cc)",
          "6.0 L QUATTRO W12 AT (5998cc)",
          "3.0 TDI QUATTRO WCI PKG (2967cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "L SECURITY (6299cc)",
          "L 55 TFSI (2995cc)",
          "L 4.2 TDI QUATTRO (4134cc)",
          "L 4.2 TDI QUATTRO (4134cc)",
          "L 4.2 QUATTRO (4163cc)",
          "4.2 L FSI (4163cc)",
          "4.2 FSI QUATTRO 4 SEAT (4163cc)",
          "L 4.2 FSI QUATTRO (4163cc)",
          "4.2L QUATTRO (4163cc)",
          "3.0 TDI (2967cc)",
          "50 TDI QUATTRO PREMIUM PLUS (2967cc)",
          "3.0 TDI QUATTRO WCI PACKAGE (2967cc)",
          "L 50 TDI 4 STR (2967cc)",
          "L 50 TDI QUATTRO PREMIUM KW 184 (2967cc)",
          "3.0 TDI QUATTRO PANORAMIC SUNROOF (2967cc)",
          "L 50 TDI QUATTRO PREMIUM PLUS KW 184 (2967cc)",
          "L 3.0 TDI QUATTRO LUXURY 184 KW (2967cc)",
          "3.0 TDI QUATTRO LWB T9T9 (2967cc)",
          "L 3.0 TDI QUATTRO STANDARD 184 KW (2967cc)",
          "L 50 TDI QUATTRO KW 184 (2967cc)",
          "3.0 TDI BUSINESS EDITION (2967cc)",
          "3.0 TDI BUSINESS EDITION (2967cc)",
          "L 3.0 TDI QUATTRO (2967cc)",
          "L 3.0 TDI QUATTRO (2967cc)",
          "L 50 TDI (2967cc)",
          "L 50 TDI QUATTRO PREMIUM (2967cc)",
          "L 6.3 W12 QUATTRO (6300cc)",
          "L 6.3 W12 QUATTRO (6300cc)",
          "6.3 FSI (6300cc)",
          "6.3 QUATTRO (6300cc)",
          "SECURITY (6300cc)",
          "6.3 W12 QUATTRO PETROL (6300cc)",
          "W12 QUATTRO (6299cc)",
          "L W12 (6299cc)",
          "L 6.3 W12 QUATTRO (6299cc)",
          "6.0 QUATTRO AT (5998cc)",
          "L 6.0 W12 QUATTRO (5998cc)",
          "L 6.0 W12 QUATTRO (5998cc)",
          "6.0L QUATTRO W12 (5998cc)",
          "4.2 TDI 5 STR (4134cc)",
          "L 4.2 TDI QUATTRO STANDARD 258 KW (4134cc)",
          "L 60 TDI QUATTRO KW 283 (4134cc)",
          "4.2 TDI QUATTRO ADVANCE PACKAGE (4134cc)",
          "L 60 TDI (4134cc)",
          "L 60 TDI (4134cc)",
          "L 55 TFSI QUATTRO CELEBRATION EDITION (2995cc)",
          "L 55 TFSI QUATTRO TECHNOLOGY EDITION (2995cc)",
          "L 55 TFSI 4 SEATER (2995cc)",
          "L 4.0 TFSI QUATTRO STANDARD 309 KW (3993cc)",
          "L 60 TFSI QUATTRO KW 320 (3993cc)",
          "L 60 TFSI (3993cc)",
          "L 4.0 TFSI QUATTRO (3993cc)",
        ],
      },
      {
        model: "A6",
        variants: [
          "2.0 TFSI TECHNOLOGY (1984cc)",
          "S6 4.0 TFSI (3993cc)",
          "3.0 TDI QUATTRO TECHNOLOGY (2967cc)",
          "2.0 TDI WCI & WEU (1968cc)",
          "2.8 FSI WCI & WEU (2773cc)",
          "3.0 TDI QUATTRO WCI (2967cc)",
          "3.0 TDI QUATTRO WCI & WEU (2967cc)",
          "3.0 TDI QUATTRO WCI LUX PKG (2967cc)",
          "3.0 TDI QUATTRO WITH WEU WCI (2967cc)",
          "3.0 TFSI QUATTRO LED HEADLIGHT (2995cc)",
          "3.0 TFSI QUATTRO LED & WCI (2995cc)",
          "3.0 TFSI QUATTRO WCI & WEU (2995cc)",
          "3.0 TFSI QUATTRO WCI LUX PKG (2995cc)",
          "3.0 TFSI QUATTRO WEU PKG (2995cc)",
          "35 TDI (1968cc)",
          "3.0 TDI QUATTRO WEU PKG (2967cc)",
          "3.0 MULTITRONIC AT PETROL (2976cc)",
          "3.0 TDI SPECIAL EDITION (2967cc)",
          "DESIGN EDITION (1968cc)",
          "35 TFSI MATRIX (1968cc)",
          "35 TFSI (1968cc)",
          "40 TFSI PREMIUM PLUS (3993cc)",
          "3.0 MULTITRONIC (2967cc)",
          "3.0 MULTITRONIC (2967cc)",
          "3.2 FSI MULTITRONIC (3200cc)",
          "2.8 FSI MULTITRONIC (1984cc)",
          "DIESEL 3.0 TDI (3000cc)",
          "LIFESTYLE EDITION (1798cc)",
          "2.4 L PETROL (2400cc)",
          "DIESEL 2.0 TDI (2000cc)",
          "3.2 FSI QUATTRO (3123cc)",
          "2.0 TDI (1968cc)",
          "2.0 TDI (1968cc)",
          "3.0 MULTITRONIC AT (2976cc)",
          "35 TDI MATRIX EDITION (1968cc)",
          "3.0TDI QUATTRO LED H/L WCI (2967cc)",
          "3.0TDI QUATTRO LED H/L WCI WEU (2967cc)",
          "3.0TDI QUATTRO WCI LUXURY PACK (2967cc)",
          "3.0TDI QUATTRO WEU MULTIMEDIA (2967cc)",
          "3.0TDI QUATTRO WEU WCI LED H/L (2967cc)",
          "3.0 TFSI QUATTRO LED H/L & WCI (2995cc)",
          "3.0TFSI QUATTRO LEDH/L WCI&WEU (2995cc)",
          "3.0TFSI QUATTRO WCI LUXURY PAC (2995cc)",
          "3.0TFSI QUATTRO WEU MULTIMEDIA (2995cc)",
          "35 TDI PREMIUM (1968cc)",
          "2.8 FSI WCI LUXURY PACKAGE (2773cc)",
          "2.8 FSI WEU MULTIMEDIA PACKAGE (2773cc)",
          "2.8 FSI LED HEADLIGHTS (2773cc)",
          "2.8 FSI LED HEADLIGHTS &  WCI (2773cc)",
          "2.8 FSI LED HEADLIGHTS &  WCI & WEU (2773cc)",
          "3.0 TFSI QUATTRO LED HEADLIGHTS (2995cc)",
          "2.0 TDI IMPORTED (1968cc)",
          "2.0 TDI NAVIGATION EDITION (1968cc)",
          "2.0 TDI PREMIUM PLUS EDITION (1968cc)",
          "2.0 TDI WCI LUXURY PACKAGE (1968cc)",
          "2.0 TDI WEU MULTIMEDIA PACKAGE (1968cc)",
          "3.0 TDI NAVIGATION EDITION (2967cc)",
          "3.0 TDI PREMIUM PLUS EDITION (2967cc)",
          "3.0 TDI PREMIUM EDITION (2967cc)",
          "3.0 TDI TECHNOLOGY PACK (2967cc)",
          "2.0 TDI LED HEADLIGHTS (1968cc)",
          "2.0 TDI LED HEADLIGHTS &  WCI (1968cc)",
          "2.0 TDI LED HEADLIGHTS &  WCI & WEU (1968cc)",
          "3.0 TDI QUATTRO LED HEADLIGHTS (2967cc)",
          "2.7 TDI WITH WCI PACKAGE (2698cc)",
          "35 TDI LIMOUSINE (1968cc)",
          "1.8 T MULTITRONIC (1781cc)",
          "35 TFSI MATRIX (1984cc)",
          "35 TFSI MATRIX (1798cc)",
          "2.0 TFSI PREMIUM PLUS (1984cc)",
          "2.0 TFSI PREMIUM (1984cc)",
          "2.0 TFSI TECHNOLOGY PACK (1984cc)",
          "35 TFSI PREMIUM (1984cc)",
          "2.8 FSI (2773cc)",
          "2.8 FSI (2773cc)",
          "3.2 FSI QUATTRO (3132cc)",
          "3.0 TFSI QUATTRO (2995cc)",
          "2.7 TDI (2698cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "2.0 TDI BUSINESS EDITION (1968cc)",
          "2.0 TDI PREMIUM EDITION (1968cc)",
          "35 TFSI (1798cc)",
          "35 TFSI (1984cc)",
          "45 TFSI PREMIUM PLUS (1984cc)",
          "45 TFSI PREMIUM PLUS. (1984cc)",
          "45 TFSI TECHNOLOGY (1984cc)",
          "45 TFSI TECHNOLOGY. (1984cc)",
          "30 TDI S EDITION (1968cc)",
          "35 TDI PREMIUM PLUS (1968cc)",
          "35 TDI LIMOUSINE (4 SEATER) (1968cc)",
          "35 TDI MATRIX (140 KW) (1968cc)",
          "35 TDI PREMIUM  130 KW (1968cc)",
          "35 TDI TECHNOLOGY PACK  130 KW (1968cc)",
          "35 TDI BUSINESS EDITION 130 KW (1968cc)",
          "2.0 TDI PREMIUM (1968cc)",
          "2.0 TDI PREMIUM PLUS (1968cc)",
          "DIESEL 2.0 TDI (1968cc)",
          "35 TDI MATRIX (1968cc)",
          "35 TDI TECHNOLOGY (1968cc)",
          "2.0 TDI TECHNOLOGY PACK (1968cc)",
          "35 TDI TECHNOLGY PACK (1968cc)",
          "TDI 2.7 (2698cc)",
          "2.7 TDI WCI PACKAGE (2698cc)",
          "3.0 TDI (2967cc)",
          "3.0 TDI QUATTRO LED HEADLIGHTS & WCI (2967cc)",
          "3.0 TDI QUATTRO WITH  WEU WCI LED HEAD LIGHT (2967cc)",
          "3.0 TDI QUATTRO WCI LUXURY PACKAGE (2967cc)",
          "3.0 TDI QUATTRO WEU MULTIMEDIA PACKAGE (2967cc)",
          "3.0 TDI QUATTRO TECHNOLOGY PACK (2967cc)",
          "3.0 TDI QUATTRO PREMIUM (2967cc)",
          "3.0 TDI QUATTRO PREMIUM PLUS (2967cc)",
          "3.2 TFSI QUATTRO (3132cc)",
          "BLACK EDITION (2995cc)",
          "3.0 TFSI QUATTRO LED HEADLIGHTS & WCI (2995cc)",
          "3.0 TFSI QUATTRO LED HEADLIGHTS & WCI & WEU (2995cc)",
          "3.0 TFSI QUATTRO WEU MULTIMEDIA PACKAGE (2995cc)",
          "3.0 TFSI QUATTRO WCI LUXURY PACKAGE (2995cc)",
          "3.0I MULTITRONIC (2995cc)",
          "3.0 TFSI (2995cc)",
          "C7 WCILED 3.0TDI (2995cc)",
          "C7 WCILED 3.0TFSI (2995cc)",
          "45 TFSI PREMIUM PLUS BS6 (1984cc)",
          "45 TFSI TECHNOLOGY BS6 (1984cc)",
          "45 TFSI  TECHNOLOGY PLUS 180 KW (1984cc)",
          "45 TFSI PREMIUM PLUS 180 KW (1984cc)",
          "45 TFSI  185 KW (1984cc)",
          "45 TFSI  TECHNOLOGY180 KW (1984cc)",
          "35 TFSI PREMIUM 132 KW (1984cc)",
          "35 TFSI TECHNOLOGY PACK 132 KW (1984cc)",
          "2.0 TFSI 132 KW (1984cc)",
          "2.0 TFSI PREMIUM 132 KW (1984cc)",
          "2.0 TFSI PREMIUM PLUS 132 KW (1984cc)",
          "2.0 TFSI TECHNOLOGY PACK 132 KW (1984cc)",
          "2.0 TDI MULTITRONIC (1984cc)",
          "45 TFSI TECHNOLOGY W/O MATRIX (1984cc)",
          "35 TFSI TECHNOLOGY. (1984cc)",
          "35 TFSI TECHNOLOGY (1984cc)",
          "2.0 TFSI (1984cc)",
          "35 TFSI TECHNOLOGY PACK 140 KW (CC 1798) (1798cc)",
          "35 TFSI MATRIX PETROL (140 KW) (1798cc)",
          "35 TFSI LIFESTYLE EDITION (1798cc)",
          "35 TFSI 1.8 PREMUM 140 KW (1798cc)",
          "3.0 I MULTITRONIC (2976cc)",
          "3.0L MULTITRONIC (2976cc)",
        ],
      },
      {
        model: "Q5",
        variants: [
          "2.0 TDI QUATTRO BUSINESS EDTN (1968cc)",
          "2.0 TDI QUATTRO PANAROMA SRF (1968cc)",
          "2.0 TDI QUATTRO WCI (1968cc)",
          "2.0 TDI TECHNOLOGY PACK (2967cc)",
          "2.0 TDI QUATTRO NAVIGATION (1984cc)",
          "2.0 TDI QUATTRO PANAROMA (1984cc)",
          "2.0 TDI TECHNOLOGY EDITION (1984cc)",
          "2.0 TDI QUATTRO AT (1984cc)",
          "2.0 TDI QUATTRO BUSINESS EDITION (1984cc)",
          "2.0 TDI QUATTRO TECHNOLOGY PACK (1984cc)",
          "2.0 TDI QUATTRO WCI (1984cc)",
          "2.0 TDI QUATTRO (1984cc)",
          "2.0 TDI (1968cc)",
          "2.0 TDI (1968cc)",
          "30 TDI QUATTRO TECHNOLOGY (1968cc)",
          "30 TDI QUATTRO PREMIUM (1968cc)",
          "2.0 TDI PREMIUM PLUS (1968cc)",
          "2.0TDI QUATTRO BUSINES EDITION (1968cc)",
          "2.0 TDI QUATTRO PANAROMA SR (1968cc)",
          "2.0 TDI QUATTRO WCI PANAROM SR (1968cc)",
          "2.0TFSI QUATTRO PANAROMA SR (1984cc)",
          "2.0TFSI QUATTRO WCI&PANAROM SR (1984cc)",
          "3.0TDI QUATTRO PANAROMA SR (2967cc)",
          "3.0TDI QUATTRO WCI&PANAROMA SR (2967cc)",
          "3.0 TDI QUATTRO (AT) (1968cc)",
          "2.0 TFSI QUATTRO WCI (1984cc)",
          "3.0 TDI QUATTRO WCI (2967cc)",
          "30 TDI DESIGN EDITION (1968cc)",
          "45 TDI TECHNOLOGY S LINE (2967cc)",
          "45 TFSI TECHNOLOGY (1984cc)",
          "30 TDI SPORTS EDITION (1968cc)",
          "35 TDI TECHNOLOGY (1968cc)",
          "40 TDI TECHNOLOGY (1968cc)",
          "45 TDI QUATTRO TECHNOLOGY (2967cc)",
          "2.0 TFSI QUATTRO (1984cc)",
          "45 TFSI PREMIUM PLUS (1984cc)",
          "2.0 TDI QUATTRO (1968cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "35 TDI PREMIUM PLUS (1968cc)",
          "40 TDI PREMIUM PLUS (1968cc)",
          "3.0 TDI (2967cc)",
          "3.0 TDI (2967cc)",
          "3.0 TDI QUATTRO PANAROMA (2967cc)",
          "3.0 TDI QUATTRO TECHNOLOGY EDITION S TRONIC (2967cc)",
          "45 TDI QUATTRO PREMIUM+ 180 KW (2967cc)",
          "45 TDI QUATTRO TECHNOLOGY PACK 180 KW (2967cc)",
          "3.0 TDI QUATTRO PANAROMA SUNROOF (2967cc)",
          "3.0 TDI QUATTRO WCI & PANAROMA SUNROOF (2967cc)",
          "3.0 TDI QUATTRO TECHNOLOGY PACK (2967cc)",
          "3.0 TDI QUATTRO PREMIUM PLUS (2967cc)",
          "2.0 TFSI (1984cc)",
          "2.0 TFSI QUATTRO PANAROMA (1984cc)",
          "45 TFSI QUATTRO (1984cc)",
          "2.0 TFSI QUATTRO WCI & PANAROMA SUNROOF (1984cc)",
          "2.0 TFSI QUATTRO PANAROMA SUNROOF (1984cc)",
          "2.0 TFSI QUATTRO SPECIAL EDITION (1984cc)",
          "45 TFSI TECH PACK (1984cc)",
          "2.0 TFSI QUATTRO  PREMIUM PLUS EDITION (1984cc)",
          "45 TFSI QUATTRO TECHNOLOGY (1984cc)",
          "2.0 TFSI QUATTRO PREMIUM PLUS (1984cc)",
          "2.0 TFSI QUATTRO PREMIUM (1984cc)",
          "2.0 TFSI TECHNOLOGY PACK (1984cc)",
          "SPECIAL EDITION (1984cc)",
          "NEW (1968cc)",
          "2.0 TDI QUATTRO BUSINESS EDITION (1968cc)",
          "2.0 TDI QUATTRO TECHNOLOGY PACK (1968cc)",
          "30 TDI DESIGN EDITION 130 KW (1968cc)",
          "40 TDI QUATTRO TECHNOLOGY S TRONIC (1968cc)",
          "30 TDI QUATTRO SPORT EDITION  130 KW (1968cc)",
          "30 TDI QUATTRO  PREMIUM  130 KW (1968cc)",
          "30 TDI QUATTRO TECHNOLOGY PACK  130 KW (1968cc)",
          "30 TDI QUATTRO PREMIUM PLUS  130 KW (1968cc)",
          "30 TDI 177 PS BUSINESS EDITION (1968cc)",
          "35 TDI QUATTRO TECHNOLOGY PACK 140 KW (1968cc)",
          "2.0 TDI QUATTRO PANAROMA SUNROOF (1968cc)",
          "2.0 TDI QUATTRO WCI & PANAROMA SUNROOF (1968cc)",
          "2.0 TDI QUATTRO NAVI PACK OFFROAD (1968cc)",
          "2.0 TDI QUATTRO PREMIUM PLUS OFFROAD (1968cc)",
          "2.0 TDI TECHNOLOGY EDITION WITH SUNROOF (1968cc)",
          "3.0 TDI NAVIGATION S LINE (1968cc)",
          "2.0 TDI QUATTRO NAVIGATION EDITION (1968cc)",
          "2.0 TDI QUATTRO PREMIUM EDITION (1968cc)",
          "2.0 TDI QUATTRO PREMIUM (1968cc)",
          "30 TDI QUATRTRO (1968cc)",
          "2.0 TDI BUSINESS EDI (1968cc)",
          "30 TDI PREMIUM EDITION (1968cc)",
          "2.0 TDI QUATTRO PREMIUM PLUS (1968cc)",
          "30 TDI QUATTRO (1968cc)",
        ],
      },
      {
        model: "Q7",
        variants: [
          "3.0 TFSI 5 SEATER (2995cc)",
          "4.2 TDI 7 SEATER (4134cc)",
          "3.0 FSI (2967cc)",
          "4.2 TDI TECHNOLOGY PACK (2967cc)",
          "DESIGN EDITION (2967cc)",
          "55 TFSI PREMIUM PLUS (1984cc)",
          "LIFESTYLE EDITION (1984cc)",
          "45 TDI TECHNOLOGY PACK + SUNROOF (4134cc)",
          "45 TDI TECHNOLOGY PACK (4134cc)",
          "LIFESTYLE EDITION (1968cc)",
          "45 TFSI TECHNOLOGY (2967cc)",
          "40 TFSI QUATTRO TECHNOLOGY (1984cc)",
          "40 TFSI QUATTRO TECHNOLOGY (2967cc)",
          "40 TFSI QUATTRO PREMIUM PLUS (1984cc)",
          "45 TDI QUATTRO TECHNOLOGY (2967cc)",
          "45 TDI EDITION (2967cc)",
          "40 TFSI QUATTRO (2967cc)",
          "3.0 TDI QUATTRO NAV PACK PROOF (2967cc)",
          "3.5 TDI PERMIUM (4134cc)",
          "45 TFSI (1984cc)",
          "35 TDI QUATTRO AT (2967cc)",
          "3.0 TFSI (2995cc)",
          "4.2 FSI TIPTRONIC QUATTRO (4163cc)",
          "3.6 FSI TIPTRONIC QUATTRO (3597cc)",
          "3.0 TDI (2967cc)",
          "3.0 TDI TIPTRONIC QUATTRO (2967cc)",
          "45 TDI QUATTRO PREMIUM PLUS (2967cc)",
          "4.2 TDI (4134cc)",
          "55 TFSI PREMIUM PLUS (2995cc)",
          "55 TFSI PREMIUM PLUS (2995cc)",
          "55 TFSI TECHNOLOGY (2995cc)",
          "55 TFSI TECHNOLOGY (2995cc)",
          "4.2 FSI (4163cc)",
          "4.2 TDI (4163cc)",
          "4.2 TDI (4163cc)",
          "4.2 TDI (4163cc)",
          "DIESEL 4.2 TDI (4163cc)",
          "4.2 FSI TIPTRONIC QUATTRO (7 SEATER) (4163cc)",
          "4.2 FSI QUATTRO (4163cc)",
          "4.2 FSI QUATTRO (4163cc)",
          "4.2 TDI 6 SEATER (4163cc)",
          "4.2 FSI QUATTRO 7 SEATER (4163cc)",
          "NEW Q7 4.2 FSI QUATTRO (4163cc)",
          "3.0 TDI QUATTRO NAVIGATION PACK (2967cc)",
          "3.0 TDI QUATTRO PREMIUM EDITION (2967cc)",
          "35 TDI QUATTRO AT 7 STR (2967cc)",
          "45 TDI PREMIUM PLUS 5 STR (2967cc)",
          "35 TDI QUATTRO 5 STR (2967cc)",
          "35 TDI PREMIUM PLUS 6 STR (2967cc)",
          "45 TDI TECHNOLOGY 6STR (2967cc)",
          "3.0 TDI QUATTRO 7 SEATER (2967cc)",
          "40 TFSI QUATTRO TECHNOLOGY 7 SEAT (CC 2967) (2967cc)",
          "45 TDI QUATTRO PREMIUM PLUS 6 SEATER (2967cc)",
          "45 TDI PREMIUM PLUS 5 SEATER (2967cc)",
          "40 TFSI QUATTRO PREMIUM PLUS 7 SEAT (CC 2967) (2967cc)",
          "45 TDI PREMIUM PLUS WITH NAVIGATION 5 SEATER (2967cc)",
          "35 TDI QUATTRO 6 STR (2967cc)",
          "45 TDI PREMIUM PLUS WITH NAVIGATION 7 SEATER (2967cc)",
          "45 TDI QUATTRO TECHNOLOGY PACK (2967cc)",
          "45 TDI QUATTRO TECHNOLOGY PACK 6 SEATER (2967cc)",
          "3.0 TDI QUATTRO TECHNOLOGY PACK WITH PANORAMIC SUNROOF (2967cc)",
          "3.0 TDI QUATTRO PREMIUM & P ROOF 6 SEATER (2967cc)",
          "3.0 TDI QUATTRO PREMIUM PLUS & P ROOF 6 SEATER (2967cc)",
          "3.0 TDI QUATTRO NAVIGATION PACK & P ROOF 6 SEATER (2967cc)",
          "35 TDI TECHNOLOGY PACK 6 SEATER (2967cc)",
          "3.0 TDI 6 SEATER (2967cc)",
          "3.0 TDI 6 SEATER (2967cc)",
          "35 TDI PREMIUM PLUS 240 PS 6 SEATER (2967cc)",
          "35 TDI 240 PS PREMIUM WITH SUNROOF (2967cc)",
          "3.0 TDI QUATTRO NAVIGATION PACK & P ROOF 7 SEATER (2967cc)",
          "3.0 TDI QUATTRO PREMIUM PLUS & P ROOF 7 SEATER (2967cc)",
          "35 TDI Q PREMIUM (2967cc)",
          "35 TDI Q TECHNOLOGY (2967cc)",
          "3.0 TDI QUATTRO PREMIUM EDITION & P ROOF (2967cc)",
          "3.0 TDI QUATTRO PREMIUM PLUS & P ROOF (2967cc)",
          "3.0 TDI QUATTRO NAVIGATION PACK & P ROOF (2967cc)",
          "3.0 TDI TIPTRONIC QUATTRO 7 SEATER (2967cc)",
          "35 TDI PREMIUM PLUS 240 PS (2967cc)",
          "35 TDI PREMIUM PLUS WITH SUNROOF 240 PS (2967cc)",
          "35 TDI TECHNOLOGY PACK WITH SUNROOF 240 PS (2967cc)",
          "45 TDI QUATTRO (2967cc)",
          "35 TDI PREMIUM + SUNROOF (2967cc)",
          "35 TDI PREMIUM PLUS (2967cc)",
          "35 TDI PREMIUM PLUS + SUNROOF (2967cc)",
          "35 TDI PREMIUM PLUS SUNROOF (2967cc)",
          "35 TDI TECHNOLOGY PACK (2967cc)",
          "35 TDI TECHNOLOGY PACK + SUNROOF (2967cc)",
          "45 TDI TECHNOLOGY BLACK STYLING (2967cc)",
          "45 TDI QUATRTRO (2967cc)",
          "45 TDI QUATRTRO STR 7 (2967cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "45 TFSI TECHNOLOGY PACK (2967cc)",
          "45 TDI TECHNOLOGY PACK (2967cc)",
          "45 TDI TECHNOLOGY PACK (2967cc)",
          "3.0 TDI NAV PACK 6 SEATER (2967cc)",
          "3.0 TDI QUATTRO 5 SEATER (2967cc)",
          "35 TDI PREMIUM (2967cc)",
          "45 TDI PREMIUM PLUS (2967cc)",
          "45 TDI PREMIUM PLUS (2967cc)",
          "3.0 TDI QUATTRO PREMIUM PLUS (2967cc)",
          "3.0 TDI QUATTRO TECHNOLOGY PACK (2967cc)",
          "3.0 TDI QUATTRO PREMIUM (2967cc)",
          "3.0 TDI TECHNOLOGY PACK (2967cc)",
          "3.0 TDI PREMIUM PLUS (2967cc)",
          "Q7 45 TDI (2967cc)",
          "NEW Q7 3.0 TDI QUATTRO (2967cc)",
          "45 TDI TECHNOLOGY PACK 6 SEATER (2967cc)",
          "35 TDI QUATTRO TECHNOLOGY PACK (2967cc)",
          "45 TDI PREMIUM PLUS 6 SEATER (2967cc)",
          "45 TDI BLACK STYLING (2967cc)",
          "45 TFSI BLACK EDITION. (1984cc)",
          "40 TFSI TECHNOLOGY 5 SEAT (1984cc)",
          "40 TFSI PREMIUM PLUS 5 SEAT (1984cc)",
          "40 TFSI TECHNOLOGY 7 SEAT (CC 1984) (1984cc)",
          "45 TFSI TECHNOLOGY 7 SEATER (1984cc)",
          "40 TFSI PREMIUM PLUS 7 SEATER (CC 1984) (1984cc)",
          "45 TFSI TECHNOLOGY BLACK STYLING (1984cc)",
          "40 TFSI PREMIUM PLUS (1984cc)",
          "40 TFSI PREMIUM PLUS (1984cc)",
          "40 TFSI TECHNOLOGY PACK (1984cc)",
          "40 TFSI TECHNOLOGY PACK (1984cc)",
          "45 TFSI PREMIUM PLUS (1984cc)",
          "45 TFSI PREMIUM PLUS (1984cc)",
          "45 TFSI TECHNOLOGY PACK (1984cc)",
          "45 TFSI TECHNOLOGY PACK (1984cc)",
          "40 TFSI QUATTRO (1984cc)",
          "4.2 TDI QUATTRO 7STR (4134cc)",
          "4.2 TDI QUATTRO 5STR (4134cc)",
          "4.2 TDI QUATTRO TECHNOLOGY PACK (4134cc)",
          "4.2 TDI QUATTRO 6 SEATER (4134cc)",
          "4.2 TDI QUATTRO (4134cc)",
          "4.2 TDI QUATTRO (4134cc)",
          "3.0 TFSI 7STR (2995cc)",
          "55 TFSI QUATTRO (2995cc)",
          "3.0 TFSI 5STR (2995cc)",
          "55 TFSI TECHNOLOGY 7 SEAT (2995cc)",
          "55 TFSI PREMIUM PLUS 7 SEAT (2995cc)",
          "3.0 TFSI QUATTRO (2995cc)",
          "3.0 TFSI QUATTRO (2995cc)",
          "TECHNOLOGY 55 TFSI (2995cc)",
          "55 TFSI TECHNOLOGY W/O MATRIX (2995cc)",
          "LIMITED EDITION (2995cc)",
          "3.0 TFSI QUATTRO 6 SEATER (2995cc)",
          "3.6 FSI (3597cc)",
          "3.6 FSI QUATTRO (3597cc)",
          "3.6 FSI QUATTRO (3597cc)",
          "NEW Q7 3.6 FSI QUATTRO (3597cc)",
        ],
      },
      {
        model: "Q3",
        variants: [
          "30 TFSI PREMIUM FWD (1968cc)",
          "2.0 TDI (1968cc)",
          "2.0 TDI (1968cc)",
          "2.0 TDI PREMIUM PLUS (1968cc)",
          "35 TDI QUATTRO PREMIUM (1968cc)",
          "30 TFSI (1395cc)",
          "40 TFSI PREMIUM PLUS BSVI (1984cc)",
          "2.0 TDI QUATTRO (1968cc)",
          "2.0 TFSI QUATTRO BASE (1984cc)",
          "2.0 TFSI QUATTRO HIGH GRADE (1984cc)",
          "30 TFSI PREMIUM FWD (1984cc)",
          "2.0 TDI QUATTRO BASE (1968cc)",
          "2.0 TDI QUATTRO HIGH GRADE (1968cc)",
          "2.0 TDI BASE GRADE (1968cc)",
          "35 TDI QUATTRO PREMIUM PLUS (1968cc)",
          "1.4 TFSI (1395cc)",
          "30 TDI PREMIUM FWD (1968cc)",
          "35 TDI QUATTRO TECHNOLOGY (1968cc)",
          "TECHNOLOGY 40 TFSI QUATTRO S TRONIC (1984cc)",
          "PREMIUM PLUS 40 TFSI QUATTRO S TRONIC (1984cc)",
          "SPORTBACK TECHNOLOGY PLUS S-LINE (1984cc)",
          "SPORTBACK 40 TFSI QUATTRO (1984cc)",
          "2.0 TDI AT TECHNOLOGY (1984cc)",
          "40 TFSI SPORTBACK (1984cc)",
          "40 TFSI PREMIUM PLUS (1984cc)",
          "2.0 TFSI QUATTRO PREMIUM PLUS (1984cc)",
          "SPORTBACK TECH PLUS S-LINE (1984cc)",
          "2.0 TFSI QUATTRO (1984cc)",
          "40 TFSI TECHNOLOGY (1984cc)",
          "45 TFSI TECHNOLOGY BSVI (1984cc)",
          "PREMIUM PLUS (1984cc)",
          "TECHNOLOGY (1984cc)",
          "2.0 TDI PREMIUM (1968cc)",
          "2.0 TDI S (1968cc)",
          "2.0 TDI Q PREMIUM PLUS (1968cc)",
          "35 TDI QUATTRO (1968cc)",
          "30 TDI QUATTRO PREMIUM PLUS (1968cc)",
          "30 TDI QUATTRO TECHNOLOGY (1968cc)",
          "35 TDI S EDITION (1968cc)",
          "30 TDI PREMIUM (1968cc)",
          "35 TDI QUATTRO PREMIUM WITHOUT SUNROOF (1968cc)",
          "35 TDI QUATTRO TECHNOLOGY WITH NAVIGATION (1968cc)",
          "30 TDI PREMIUM WITH SUNROOF (1968cc)",
          "35 TDI TECHNOLOGY EDITION (1968cc)",
          "2.0 TDI Q DYNAMIC 177 HP (1968cc)",
          "2.0 TDI 3FU WCI (1968cc)",
          "35 TDI PREMIUM PLUS WITH SUNROOF (1968cc)",
          "35 TDI QUATTRO PREMIUM 130 KW (1968cc)",
          "30 TDI S EDITION (1968cc)",
          "35 TDI PREMIUM WITH SUNROOF (1968cc)",
          "S 2.0 TDI 103 KW (1968cc)",
          "2.0 TDI TWD S EDITION 143 HP (1968cc)",
          "2.0 TDI Q PREMIUM + PANORAMIC SUN ROOF - 177 HP (1968cc)",
          "2.0 TDI Q PREMIUM PLUS + PANORAMIC SUN ROOF - 177 HP (1968cc)",
          "2.0 TFSI QUATTRO PREMIUM PLUS (1968cc)",
          "2.0 TDI QUATTRO PREMIUM (1968cc)",
          "2.0 TDI HIGH (1968cc)",
          "35 TDI PREMIUM + SUNROOF (1968cc)",
          "35 TDI PREMIUM PLUS + SUNROOF (1968cc)",
          "35 TDI TECHNOLOGY WITH NAVIGATION (1968cc)",
          "2.0 TFSI HIGH (1968cc)",
          "2.0 TDI QUATTRO PREMIUM PLUS (1968cc)",
          "2.0 TDI QUATTRO DYNAMIC (1968cc)",
          "35 TDI TECHNOLOGY (1968cc)",
          "2.0 TDI S EDITION (1968cc)",
          "30 TDI S (1968cc)",
          "35 TDI DYNAMIC EDITION (1968cc)",
          "35 TDI PREMIUM (1968cc)",
          "35 TDI PREMIUM PLUS (1968cc)",
          "30 TFSI PREMIUM (1395cc)",
          "30 TFSI PREMIUM FWD (1395cc)",
        ],
      },
      {
        model: "R8",
        variants: [
          "4.2 V8 FSI RTRONIC TRANS (4163cc)",
          "GT (5200cc)",
          "5.2 FSI (5200cc)",
          "QUATTRO 42 (1968cc)",
          "LMX (4200cc)",
          "4.2 V8 FSI MT (4163cc)",
          "V10 PLUS (5204cc)",
          "5.2 FSI QUATTRO V10 (5204cc)",
          "4.2 V8 FSI RTRONIC TRANSMISSION (4163cc)",
          "SPYDER 5.2 FSI QUATTRO V10 (5204cc)",
          "4.2 V8 FSI RTRONIC AT (4163cc)",
          "COUPE (4163cc)",
          "4.2 V8 FSI STANDARD (4163cc)",
          "4.2 V8 FSI MANUAL TRANSMISSION (4163cc)",
          "COUPE 4.2 FSI QUATTRO R TRONIC (4163cc)",
          "4.2 V8 COUPE (4163cc)",
          "4.2 V8 COUPE (4163cc)",
          "COPPER EDITION (4163cc)",
          "COUPE 4.2 FSI QUATTRO (4163cc)",
          "R-TRONICE (4163cc)",
          "R8 4.2 QUATTRO (4163cc)",
          "4.2 FSI QUATTRO (4163cc)",
          "4.2 FSI QUATTRO R TRONIC (4163cc)",
          "5.2 TFSI COVERTIBLE (5204cc)",
          "5.2 FSI QUATTRO V10 PLUS SPYDER (5204cc)",
          "5.2 FSI V10 STANDARD (5204cc)",
          "V10 4 STR (5204cc)",
          "5.2 FSI QUATTRO V10 PLUS (5204cc)",
          "5.2 V10 LMX (5204cc)",
          "5.2 V10 SPYDER R-TRONIC 391 KW (5204cc)",
          "5.2 V10 FSI STANDARD (5204cc)",
          "5.2 V10 PLUS (5204cc)",
          "5.2 V10 PLUS (5204cc)",
          "5.2 V10 COUPE (5204cc)",
          "5.2 V10 SPYDER (5204cc)",
          "CONVERTIBLE 5.2 FSI QUATTRO SPYDER (5204cc)",
          "LMX (5204cc)",
          "LMX (5204cc)",
          "5.2 FSI QUATTRO SPYDER (5204cc)",
        ],
      },
      {
        model: "RS6",
        variants: [
          "4.0 TFSI (3993cc)",
          "4.0 TFSI (3993cc)",
          "4.0 AVANT PETROL (3993cc)",
          "AVANT 4.0 TFSI (3993cc)",
          "AVANT 4.0 TFSI QUATTRO (3993cc)",
          "40 TFSI (1984cc)",
        ],
      },
      {
        model: "C7",
        variants: [
          "WCILED 3.0TFSI (2967cc)",
          "3.0 TFSI WCILED (2967cc)",
          "3.0TDI LED WCI (2967cc)",
          "3.0 TDI (2967cc)",
          "WCILED 3.0TDI (2967cc)",
          "BASIS 3.0 TFSI 220KW (2967cc)",
          "3.0 TFSI (2967cc)",
          "2.8 TFSI (2800cc)",
          "BASIC 2.8 TFSI (1422cc)",
        ],
      },
      {
        model: "A7",
        variants: [
          "SPORTBACK 3.0 TDI QUATTRO (2967cc)",
          "RS 7 SPORTBACK (3993cc)",
          "4.2 TDI QUATTRO (4134cc)",
          "2.8 FSI QUATTRO (2773cc)",
          "3.0 TFSI QUATTRO (2995cc)",
          "3.0 TDI SPORTBACK QUATTRO (2967cc)",
          "3.0 V6 TDI FULLY LOADED (2967cc)",
          "3.0 V6 TDI (2967cc)",
          "3.0 TDI (2967cc)",
          "3.0 TDI (2967cc)",
          "3.0 V6 TDI QUATTRO STANDARD (2967cc)",
          "3.0 V6 TDI SPORTBACK QUATTRO (2967cc)",
          "BLACK EDITION (2967cc)",
          "3.0 V6 TDI SPORTBACK QUATTRO (245 BHP) 5 SEATER (2967cc)",
          "3.0 V6 TDI QUATTRO STANDARD 180 KW 5 SEATER (2967cc)",
          "3.0 V6 TDI SPORTBACK QUATTRO (245 BHP) 4 SEATER (2967cc)",
          "3.0 V6 TDI QUATTRO STANDARD 180 KW (2967cc)",
          "3.0 V6 TDI (245 BHP) 5 SEATER (2967cc)",
          "3.0 V6 TDI (245 BHP) FULLY LOADED (2967cc)",
          "3.0 V6 TDI (245 BHP) (2967cc)",
          "3.0 TDI 5 SEATER (2967cc)",
          "SPORTBACK 3.0 TFSI QUATTRO (2995cc)",
        ],
      },
      {
        model: "S4",
        variants: [
          "3.0 TFSI QUATTRO 245 KW (2995cc)",
          "3.0 TFSI QUATTRO (2995cc)",
          "3.0 V6 TFSI (2995cc)",
          "3.0 TFSI (2995cc)",
          "3.0 TFSI QUATTRO TECHNOLOGY PACK (2995cc)",
          "V6 3.0 TFSI (2995cc)",
        ],
      },
      {
        model: "S5 SPORTBACK",
        variants: ["0 (2995cc)"],
      },
      {
        model: "A3",
        variants: [
          "2.0 TDI BASE GRADE (1968cc)",
          "40 TFSI CABRIOLET OPTIONS (1798cc)",
          "40 TFSI PREMIUM PLUS WITH SR (1798cc)",
          "40 TFSI WITH PANORAMIC (1798cc)",
          "40 TFSI PREMIUM PLUS 180 HP (1798cc)",
          "CABRIOLET 1.4 TFSI (1395cc)",
          "35 TFSI PREMIUM PLUS (1968cc)",
          "35 TFSI TECHNOLOGY (1968cc)",
          "35TDI PREMIUM+ WITH SUNROOF (1968cc)",
          "35 TDI TECHNOLOGY PACK (1968cc)",
          "35TDI TECHNOLOGY PACK SUNROOF (1968cc)",
          "35 TDI TECHNOLOGY SUNROOF NAV (1968cc)",
          "40 TFSI CABRIOLET FULL (O) (1798cc)",
          "40 TFSI PREMIUM+ WITH SUNROOF (1798cc)",
          "40TFSI PANORAMIC GLASSROOF (1798cc)",
          "1.4 TFSI CABRIOLET (1395cc)",
          "40 TFSI CABRIOLET (1798cc)",
          "35 TFSI CABRIOLET (1395cc)",
          "35 TDI CABRIOLET (1968cc)",
          "40 TFSI LIMOUSINE (1798cc)",
          "35 TDI LIMOUSINE (1968cc)",
          "40 TFSI PREMIUM PLUS (1798cc)",
          "40 TFSI PREMIUM (1798cc)",
          "35 TDI TECHNOLOGY (1968cc)",
          "35 TDI ATTRACTION (1968cc)",
          "35 TDI PREMIUM (1968cc)",
          "35 TDI PREMIUM PLUS (1968cc)",
          "V6 AT (1395cc)",
          "35 TFSI PREMIUM PLUS (1984cc)",
          "35 TFSI PREMIUM PLUS (1395cc)",
          "35 TFSI TECHNOLOGY (1984cc)",
          "35 TFSI TECHNOLOGY (1395cc)",
          "40 PREMIUM PLUS (1798cc)",
          "PREMIUM PLUS (1798cc)",
          "40 TFSI CABRIOLET FULL OPITIONS (1798cc)",
          "40 TFSI PREMIUM PLUS WITH SUNROOF 180 HP (1798cc)",
          "40 TFSI WITH PANORAMIC GLASSROOF (1798cc)",
          "40 TFSI PREMIUM 180 HP (1798cc)",
          "40 TFSI PREMIUM WITH SUNROOF 180 HP (1798cc)",
          "TURBO (1798cc)",
          "40 TFSI PREMIUM PLUS + SUNROOF (1798cc)",
          "CABRIOLET 40 TFSI (1798cc)",
          "ATTRACTION (1968cc)",
          "PREMIUM (1968cc)",
          "PREMIUM PLUS (1968cc)",
          "35 ATTRACTION (1968cc)",
          "35 PREMIUM (1968cc)",
          "35 PREMIUM PLUS (1968cc)",
          "35 TECHNOLOGY PACK (1968cc)",
          "TECHNOLOGY PACK (1968cc)",
          "2.0 TDI LIMEOUSINE (1968cc)",
          "35 TDI TECHNOLOGY PLUS (1968cc)",
          "35 TDI TECHNOLOGY PACK 143 HP (1968cc)",
          "35 TDI ATTRACTION 143 HP (1968cc)",
          "35 TDI PREMIUM 143 HP (1968cc)",
          "35 TDI PREMIUM PLUS 143 HP (1968cc)",
          "35 TDI PREMIUM WITH SUNROOF 143 HP (1968cc)",
          "35 TDI PREMIUM PLUS WITH SUNROOF 143 HP (1968cc)",
          "35 TDI TECHNOLOGY PACK WITH SUNROOF 143 HP (1968cc)",
          "35 TDI TECHNOLOGY WITH SUNROOF AND NAV 143 PS (1968cc)",
          "35 TDI PREMIUM PLUS + SUNROOF (1968cc)",
          "S DIESEL (1968cc)",
          "35 TDI TECHNOLOGY + SUNROOF (1968cc)",
          "35 TDI PREMIUM + SUNROOF (1968cc)",
          "BASE (1968cc)",
          "1.4 TSI TECHNOLOGY (1390cc)",
          "35 TFSI (1395cc)",
          "CABRIOLET 35 TFSI (1395cc)",
        ],
      },
      {
        model: "S6",
        variants: [
          "4.0 TFSI QUATTRO 309 KW (1199cc)",
          "4.0 TFSI QUATTRO (3993cc)",
          "4.0 TFSI QUATTRO 309 KW (3993cc)",
        ],
      },
      {
        model: "AUDI A6",
        variants: [
          "S6 4.0 TFSI (3993cc)",
          "2.0 TDI (1968cc)",
          "2.0 TDI PREMIUM EDITION (1968cc)",
          "35 TDI MATRIX EDITION (1968cc)",
          "2.0 TDI BUSINESS EDITION (1968cc)",
          "35 TDI (1968cc)",
          "35 TDI PREMIUM (1968cc)",
          "35 TDI TECHNOLOGY PACK (1968cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "35 TFSI MATRIX EDITION (1798cc)",
          "35 TFSI (1798cc)",
          "2.0 TFSI TECHNOLOGY PACK (1984cc)",
          "2.0 TFSI PREMIUM PLUS (1984cc)",
          "2.0 TFSI PREMIUM (1984cc)",
          "35 TFSI TECHNOLOGY PACK (1984cc)",
          "35 TFSI PREMIUM (1984cc)",
          "3.2 FSI QUATTRO (3132cc)",
          "2.8 FSI (2773cc)",
          "3.0 TFSI QUATTRO (2995cc)",
          "2.7 TDI (2698cc)",
        ],
      },
      {
        model: "AUDI A4",
        variants: [
          "2.0 TFSI (1984cc)",
          "35 TDI PREMIUM PLUS (1968cc)",
          "2.0 TDI PREMIUM (1968cc)",
          "2.0 TDI PREMIUM PLUS (1968cc)",
          "2.0 TDI TECHNOLOGY PACK (1968cc)",
          "2.0 TDI MULTITRONIC (1968cc)",
          "2.0 TDI BUSINESS EDITION (1968cc)",
          "35 TDI TECHNOLOGY PACK (1968cc)",
          "35 TDI PREMIUM (1968cc)",
          "35 TDI PREMIUM PLUS SUNROOF (1968cc)",
          "1.8 TFSI PREMIUM (1798cc)",
          "1.8 TFSI (1798cc)",
          "35 TFSI PREMIUM (1798cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "30 TFSI TECHNOLOGY PACK (1395cc)",
          "30 TFSI PREMIUM PLUS (1395cc)",
          "LIFESTYLE EDITION (1395cc)",
          "3.2 FSI QUTTARO (3197cc)",
        ],
      },
      {
        model: "A5",
        variants: [
          "2.0 TDI QUATTRO CABRIOLET (1984cc)",
          "35 TDI CABRIOLET (1984cc)",
          "2.0 TDI CABRIOLET 4 SEAT (1989cc)",
          "2.0 SPORTBACK DIESEL 4 SEAT (1989cc)",
          "SPORTBACK (1989cc)",
          "2.0 TDI CABRIOLET (1968cc)",
          "SPORTBACK (1968cc)",
          "SPORTBACK (1968cc)",
          "S5 (2995cc)",
          "S5 (2995cc)",
          "CABRIOLET (1968cc)",
          "CABRIOLET 35 TDI 4 STR (1968cc)",
          "CABRIOLET 35 TDI (1968cc)",
          "CABRIOLET 35 TDI (1968cc)",
          "40 TDI SPORTBACK (1968cc)",
          "35 TDI SPORTSBACK (1968cc)",
          "40 TDI CABRIOLET (1968cc)",
          "SPORTBACK 35 TDI (1968cc)",
          "CABRIOLET 2.0 TDI (1968cc)",
          "CABRIOLET 40 TDI QUATTRO (1968cc)",
        ],
      },
      {
        model: "RSQ8",
        variants: [
          "55 (3998cc)",
          "4.0 TFSI QUATTRO BS6 (3998cc)",
          "4.0 TFSI QUATTRO (3998cc)",
          "RS Q8 TFSI (3996cc)",
        ],
      },
      {
        model: "RS Q8",
        variants: ["4.0L TFSI (3996cc)"],
      },
      {
        model: "A8 L",
        variants: [
          "4.2 FSI QUATTRO (4200cc)",
          "4.2 TDI QUATTRO (4134cc)",
          "55 TFSI QUATTRO (2995cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "50 TDI (2967cc)",
          "50 TDI PLUS (2967cc)",
          "4.2 FSI QUATTRO (4163cc)",
          "CELEBRATION 55 TFSI 3.0L (2995cc)",
          "TECHNOLOGY 55 TFSI 3.0L (2995cc)",
          "CELEBRATION EDITION (2995cc)",
          "TECHNOLOGY (2995cc)",
          "L 4.0 TFSI QUATTRO (3993cc)",
          "4.0 FSI QUATTRO (3993cc)",
          "4.2 FSI QUATTRO (4134cc)",
        ],
      },
      {
        model: "S8",
        variants: [
          "V10 FSI QUATTRO (5204cc)",
          "V10 FSI QUATTRO (5204cc)",
          "5.2 V10 (5200cc)",
        ],
      },
      {
        model: "Q8",
        variants: [
          "55 E-TRON (300000cc)",
          "SPORTBACK 55 E-TRON (300000cc)",
          "CELEBRATION (2995cc)",
          "55 TFSI QUATTRO (2995cc)",
          "55 TFSI QUATTRO CELEBRATION BS6 (2995cc)",
          "55 TFSI QUATTRO CELEBRATION 9 AQ BS6 (2995cc)",
          "55 TFSI QUATTRO BS6 (2995cc)",
          "55 TFSI QUATTRO CELEBRATION (2995cc)",
          "55 TFSI QUATTRO TIPTRONIC (2995cc)",
          "55 TFSI CELEBRATION (2995cc)",
          "4.0L TFSI (3996cc)",
        ],
      },
      {
        model: "S5",
        variants: [
          "3.0 TFSI QUATTRO (2995cc)",
          "3.0 TFSI QUATTRO SPORTBACK KW 246 (2995cc)",
          "3.0 TFSI QUATTRO STANDARD KW 246 (2995cc)",
          "SPORTBACK 3.0 TFSI QUATTRO (2994cc)",
          "SPORTBACK STD (2995cc)",
          "SPORTBACK 3.0 TFSI QUATTRO (2995cc)",
          "SPORTBACK 3.0 TFSI QUATTRO (2995cc)",
        ],
      },
      {
        model: "RS4",
        variants: [
          "AVANT (4163cc)",
          "AVANT (4163cc)",
          "4.2 V8 FSI QUATTRO (4200cc)",
        ],
      },
      {
        model: "AUDI S8",
        variants: ["0 (4172cc)"],
      },
      {
        model: "AUDI V6",
        variants: ["0 (2773cc)"],
      },
      {
        model: "AUDI 80",
        variants: ["0 (3000cc)"],
      },
      {
        model: "ECOSPORT",
        variants: ["0 (2000cc)"],
      },
      {
        model: "NEW FIGO",
        variants: ["0 (2773cc)"],
      },
      {
        model: "AUDI Q5",
        variants: [
          "2.0 TDI (1984cc)",
          "45 TFSI TECHNOLOGY (1984cc)",
          "2.0 TFSI QUATTRO (1984cc)",
          "45 TFSI PREMIUM PLUS (1984cc)",
          "3.0 TDI QUATTRO PREMIUM PLUS (2967cc)",
          "3.0 TDI QUATTRO TECHNOLOGY PACK (2967cc)",
          "3.0 TDI QUATTRO (2967cc)",
          "45 TDI TECHNOLOGY S LINE (2967cc)",
          "45 TDI QUATTRO TECHNOLOGY (2967cc)",
          "30 TDI QUATTRO PREMIUM PLUS (1968cc)",
          "30 TDI QUATTRO TECHNOLOGY PACK (1968cc)",
          "2.0 TDI QUATTRO (1968cc)",
          "2.0 TDI QUATTRO BUSINESS EDITION (1968cc)",
          "2.0 TDI QUATTRO PREMIUM (1968cc)",
          "2.0 TDI QUATTRO TECHNOLOGY PACK (1968cc)",
          "30 TDI QUATTRO PREMIUM (1968cc)",
          "2.0 TDI QUATTRO PREMIUM PLUS (1968cc)",
          "35 TDI QUATTRO TECHNOLOGY (1968cc)",
          "35 TDI QUATTRO PREMIUM PLUS (1968cc)",
          "40 TDI PREMIUM PLUS (1968cc)",
          "40 TDI TECHNOLOGY (1968cc)",
        ],
      },
      {
        model: "AUDI TT",
        variants: [
          "COUPE 3.2 QUATTRO (3189cc)",
          "TT COUPE 2.0 TFSIQ (1984cc)",
          "2.0 TFSI QUATTRO. (1984cc)",
          "COUPE 45 TFSI (1984cc)",
          "45 TFSI (1984cc)",
        ],
      },
      {
        model: "E TRON",
        variants: [
          "50 AT (2189cc)",
          "55 AT (2189cc)",
          "55 SPORT BACK (2189cc)",
          "GT RS (2189cc)",
          "55 QUATTRO SPORTBACK (95000cc)",
          "55 (95000cc)",
          "55 TECHNOLOGY (95000cc)",
          "SPORTBACK 55 TECHNOLOGY (95000cc)",
          "GT (93400cc)",
          "RS GT (93400cc)",
          "50 (71000cc)",
          "50 QUATTRO (1501cc)",
          "55 QUATTRO TECHNOLOGY PACKAGE (1501cc)",
          "55 QUATTRO (1501cc)",
          "SPORTBACK 55 QUATTRO TECHNOLOGY PACKAGE (1501cc)",
          "SPORTBACK 55 QUATTRO (1501cc)",
          "QUATTRO 55 (4000cc)",
          "QUATTRO 50 (4000cc)",
          "GT S (4000cc)",
          "SPORTBACK 55 (4000cc)",
          "GT RS (4000cc)",
        ],
      },
      {
        model: "Q2",
        variants: [
          "40 TFSI QUATTRO (1984cc)",
          "40 TFSI QUATTRO PLUS (1984cc)",
          "40 TFSI STD (1984cc)",
          "PREMIUM (1984cc)",
          "PREMIUM PLUS I (1984cc)",
          "TECHNOLOGY (1984cc)",
          "40 TFSI QUATTRO STANDARD BS6 (1984cc)",
          "40 TFSI QUATTRO STANDARD WITH SUNROOF BS6 (1984cc)",
          "40 TFSI QUATTRO PREMIUM BS6 (1984cc)",
          "40 TFSI QUATTRO PREMIUM PLUS I BS6 (1984cc)",
          "40 TFSI QUATTRO PREMIUM PLUS II BS6 (1984cc)",
          "40 TFSI QUATTRO TECHNOLOGY BS6 (1984cc)",
          "40 TFSI QUATTRO PREMIUM PLUS TWO (1984cc)",
          "40 TFSI QUATTRO PREMIUM PLUS ONE (1984cc)",
          "PREMIUM PLUS II (1984cc)",
          "STANDARD (1984cc)",
          "STANDARD WITH SUNROOF (1984cc)",
          "40 TFSI QUATTRO PREMIUM PLUS I (1984cc)",
          "40 TFSI QUATTRO PREMIUM PLUS II (1984cc)",
          "40 TFSI QUATTRO STANDARD WITH SUNROOF (1984cc)",
          "40 TFSI QUATTRO TECHNOLOGY (1984cc)",
          "40 TFSI QUATTRO STANDARD (1984cc)",
          "40 TFSI QUATTRO PREMIUM (1984cc)",
        ],
      },
      {
        model: "RS7",
        variants: [
          "SPORTBACK (3993cc)",
          "4.0 TFSI (3996cc)",
          "SPORTBACK PERFORMANCE (3993cc)",
          "SPORTBACK 4.0 TFSI QUATTRO (3996cc)",
          "4.0L TFSI QUATTRO (3996cc)",
          "SPORTBACK 4.0 TFSI QUATTRO (3993cc)",
          "4.0 V8 TFSI SPORTBACK (3993cc)",
          "4.0 FSI (3993cc)",
          "4.0 V8 TFSI SPORTBACK 412 KW 5 SEAT (3993cc)",
          "4.0 V8 TFSI SPORTBACK 412 KW (3993cc)",
          "SPORTBACK 4.0 TFSI PERFORMANCE (3993cc)",
        ],
      },
      {
        model: "E-TRON",
        variants: [
          "50 (230cc)",
          "55 (300cc)",
          "55 TECHNOLOGY (300cc)",
          "SPORTBACK 55 TECHNOLOGY (300cc)",
          "SPORTBACK 55 (300cc)",
        ],
      },
      {
        model: "AUDI A8",
        variants: ["0 (2967cc)"],
      },
      {
        model: "AUDI Q7",
        variants: ["0 (3597cc)"],
      },
      {
        model: "AUDI R8",
        variants: ["0 (4163cc)"],
      },
      {
        model: "E-TRON GT",
        variants: ["S (390cc)", "RS (475cc)"],
      },
      {
        model: "AUDI A3 CABRIOLET",
        variants: ["1.4 TFSI (1395cc)", "35 TFSI (1395cc)"],
      },
      {
        model: "AUDI S4",
        variants: ["3.0 TFSI (2995cc)"],
      },
      {
        model: "AUDI Q3",
        variants: [
          "2.0 TFSI QUATTRO (1984cc)",
          "2.0 TDI QUATTRO (1968cc)",
          "2.0 TDI QUATTRO PREMIUM (1968cc)",
          "2.0 TDI S EDITION (1968cc)",
          "2.0 TDI PREMIUM PLUS (1968cc)",
          "2.0 TDI Q HIGHLINE (1968cc)",
          "35 TDI QUATTRO PREMIUM WITH SUNROOF (1968cc)",
          "35 TDI TECHNOLOGY (1968cc)",
          "35 TDI TECHNOLOGY WITH NAVIGATION (1968cc)",
          "35 TDI QUATTRO PREMIUM PLUS (1968cc)",
          "35 TDI QUATTRO PREMIUM (1968cc)",
          "2.0 TDI BASE GRADE (1968cc)",
          "30 TDI PREMIUM AT FWD (1968cc)",
          "30 TDI S (1968cc)",
          "30 TDI PREMIUM (1968cc)",
          "30 TFSI PREMIUM (1395cc)",
        ],
      },
      {
        model: "AUDI A7",
        variants: [
          "SPORTBACK 3.0 TDI QUATTRO 5 STR (2967cc)",
          "SPORTBACK 3.0 TDI QUATTRO (2967cc)",
        ],
      },
      {
        model: "AUDI A3",
        variants: [
          "35 TDI (1968cc)",
          "35 TDI PREMIUM PLUS SUNROOF (1968cc)",
          "35 TDI ATTRACTION (1968cc)",
          "35 TDI PREMIUM PLUS (1968cc)",
          "35 TDI TECHNOLOGY PACK (1968cc)",
          "35 TDI PREMIUM (1968cc)",
          "35 TDI PREMIUM SUNROOF (1968cc)",
          "35 TDI LIMOUSINE BS IV (1968cc)",
          "40 TFSI (1798cc)",
          "CABRIOLET 40 TFSI (1798cc)",
          "A3 CABRIOLET 40 TFSI (1798cc)",
          "40 TFSI PREMIUM PLUS (1798cc)",
          "CABRIOLET 40 TFSI 5 STR (1798cc)",
          "35 TFSI TECHNOLOGY (1395cc)",
          "35 TFSI PREMIUM PLUS (1395cc)",
        ],
      },
      {
        model: "AUDI S6",
        variants: ["4.0 TFSI (3993cc)"],
      },
      {
        model: "AUDI RS7",
        variants: ["SPORTBACK 4.0 TFSI QUATTRO (3993cc)"],
      },
    ],
  },
  {
    make: "BENTLEY",
    models: [
      {
        model: "CONTINENTAL GTC",
        variants: [
          "CAR (3993cc)",
          "V8 MY14 (4000cc)",
          "V8 (4000cc)",
          "GTC (5998cc)",
          "SPEED (5998cc)",
          "SPEED (5998cc)",
          "CONVERTIBLE (5998cc)",
          "6.0 BASE (5998cc)",
          "V8 (3933cc)",
          "V8S (3993cc)",
          "V8 (3997cc)",
        ],
      },
      {
        model: "CONTINENTAL FLYING SPUR",
        variants: [
          "W12 (5998cc)",
          "W12 (5998cc)",
          "3W 5STR (6000cc)",
          "5 STR (6000cc)",
          "4 STR (6000cc)",
          "V8 (3993cc)",
          "3W 5 SEATER (6000cc)",
          "V8 (3933cc)",
          "V8 (3933cc)",
          "V8 (3933cc)",
          "3W (6000cc)",
          "5 SEATER (6000cc)",
          "MY12 (6000cc)",
          "MY11 (6000cc)",
          "4 SEATER (6000cc)",
          "MY12 - 5 STR (6000cc)",
          "5 SEATER MY 13 (5998cc)",
          "6.0 BASE (5998cc)",
          "SPEED (5998cc)",
          "MY11 - 5 STR (6000cc)",
        ],
      },
      {
        model: "CONTINENTAL SUPERSPORTS",
        variants: [
          "2 SEATER COUPE (5998cc)",
          "CONVERTIBLE (5998cc)",
          "COUPE (5998cc)",
          "SUPERSPORTS (5998cc)",
          "2STR COUPE (5998cc)",
        ],
      },
      {
        model: "AZURE T",
        variants: [
          "6.8 CONVERTIBLE MY 2009 (6761cc)",
          "6.8 CONVERTIBLE (6761cc)",
          "CONVERTIBLE (4297cc)",
          "CONVERTIBLE (6000cc)",
        ],
      },
      {
        model: "BENTAYGA",
        variants: [
          "MY 17 (5950cc)",
          "6.0 BENTAYGA 5 SEATER (5950cc)",
          "6.0 BENTAYGA 4 SEATER (5950cc)",
          "W12 4 STR (5950cc)",
          "4.0 V8 (DISEL) 5 SEATER (3956cc)",
          "V8 (3996cc)",
          "W12 (5950cc)",
          "V8 DIESEL (3996cc)",
          "4.0 V8 5 SEATER (3996cc)",
          "EWB AZURE (3996cc)",
          "V8 PETROL (3996cc)",
          "V8 AT DIESEL (3996cc)",
        ],
      },
      {
        model: "FLYING SPUR",
        variants: [
          "W12 (6000cc)",
          "W12 2014 MY (6000cc)",
          "W12 22 MY (5950cc)",
          "4.0 V8 AUTOMATIC (3993cc)",
          "V8 (3993cc)",
          "W12 BSVI MY22 (IMPORTED) (5950cc)",
        ],
      },
      {
        model: "TURBO R",
        variants: ["STANDARD (6750cc)", "STD (3179cc)", "STD (6750cc)"],
      },
      {
        model: "MULSANNE",
        variants: [
          "6.8 (6752cc)",
          "6.8 BASE (6752cc)",
          "MY 2014 (6752cc)",
          "EWB 2020 (6752cc)",
          "SPEED MY 16 (6752cc)",
          "EXTENDED WHEELBASE (6752cc)",
          "V8 (6752cc)",
          "MY11 (6752cc)",
        ],
      },
      {
        model: "AZURE",
        variants: [
          "6.8 CONVERTIBLE (6761cc)",
          "T (6761cc)",
          "CONVERTIBLE (6761cc)",
          "CONVERTIBLE (6761cc)",
          "AZURE (5998cc)",
          "CONVERTIBLE (6750cc)",
          "6.8 CONVERTIBLE MY 2009 (6761cc)",
        ],
      },
      {
        model: "ARNAGE",
        variants: [
          "R (6761cc)",
          "R (6761cc)",
          "RL (6761cc)",
          "RL (6761cc)",
          "ARANGE RL (6761cc)",
          "ARANGE R (6761cc)",
          "T (6761cc)",
          "R (1600cc)",
        ],
      },
      {
        model: "BENTLEY ARNAGE",
        variants: ["0 (6761cc)"],
      },
      {
        model: "BENTLEY AZURE",
        variants: ["0 (6761cc)"],
      },
      {
        model: "BENTLEY CONTINENTAL FLYING SPUR",
        variants: ["0 (5998cc)"],
      },
      {
        model: "BENTLEY CONTINENTAL GT",
        variants: ["0 (5998cc)"],
      },
      {
        model: "BENTLEY CONTINENTAL FLYING SPUR SPEED",
        variants: ["0 (5998cc)"],
      },
      {
        model: "CONTINENTAL GT",
        variants: [
          "SPEED CONVERTIBLE (5998cc)",
          "6.0 SPEED CONVERTIBLE 4 SEATER (5998cc)",
          "6.0 CONVERTIBLE 4 SEATER (5998cc)",
          "V8 AT (3993cc)",
          "V8 CONVERTIBLE (3993cc)",
          "V8 S AT (3993cc)",
          "4.0 V8 S 4 SEATER (3993cc)",
          "4.0 V8 S CONVERTIBLE 4 SEATER (3993cc)",
          "V8 (3993cc)",
          "V8 (3993cc)",
          "V8 S CONVERTIBLE (3998cc)",
          "4.0 V8 (3998cc)",
          "COUPE V8 MY14 (4000cc)",
          "V8 (3933cc)",
          "V8S (3933cc)",
          "COUPE (3933cc)",
          "19 MY (5998cc)",
          "V8S (3993cc)",
          "SPEED (5998cc)",
          "COUPE (5998cc)",
        ],
      },
      {
        model: "CONTINENTAL",
        variants: ["MARK VI (4887cc)", "SUPERSPORTS (5998cc)"],
      },
      {
        model: "BROOKLANDS",
        variants: ["BROOKLANDS (6761cc)", "6.7 V8 COUPE (6761cc)"],
      },
      {
        model: "BENTLEY CONTINENTAL",
        variants: [
          "GTC V8 (3993cc)",
          "GT V8 (3993cc)",
          "FLYING SPUR V8 (3993cc)",
          "GT V8 S (3993cc)",
          "GT SPEED (5998cc)",
          "GT COUPE (5998cc)",
          "FLYING SPUR SEDAN (5998cc)",
          "GTC CONVERTIBLE (5998cc)",
          "FLYING SPUR W12 (5998cc)",
          "SUPER SPORTS (6000cc)",
        ],
      },
      {
        model: "BENTLEY MULSANNE",
        variants: ["V8 (6752cc)", "SPEED (6752cc)"],
      },
      {
        model: "BENTLEY BROOKLANDS",
        variants: ["COUPE (6761cc)"],
      },
      {
        model: "VINTAGE",
        variants: [
          "VINTAGE CAR (2000cc)",
          "VINTAGE CAR (1598cc)",
          "VINTAGE CAR (4500cc)",
          "M 146 (4000cc)",
          "MK VI (1850cc)",
          "4 TOURER (4257cc)",
          "VI COUPE (4257cc)",
          "CONVERTIBLE (4275cc)",
        ],
      },
    ],
  },
  {
    make: "BMW",
    models: [
      {
        model: "7 SERIES",
        variants: [
          "740LI 4.0 (4000cc)",
          "750LI DESIGN PURE EXCELLENCE (5972cc)",
          "730LD INNOVATION + LUXURY PAC (2993cc)",
          "740LI INNOVATION + LUXURY PAC (2979cc)",
          "750LI INNOVATION + LUXURY PAC (4395cc)",
          "760LI INNOVATION + LUXURY PAC (5972cc)",
          "730LD CKD DPE SIGNATURE (2993cc)",
          "745LI HYBRID (2998cc)",
          "740 LI M SPORTS (2998cc)",
          "730LD M SPORTS PLUS (2993cc)",
          "740LI INNOVATION PACKAGE (2979cc)",
          "750LI INNOVATION PACKAGE (4395cc)",
          "750LI LUXURY PACKAGE (4395cc)",
          "760LI INNOVATION PACKAGE (5972cc)",
          "760LI LUXURY PACKAGE (5972cc)",
          "750 LI  M SPORT N63 4.4 (4395cc)",
          "730LD CKD (2993cc)",
          "730LD CORPORATE EDITION (2993cc)",
          "730LD CUSTOMISED (2993cc)",
          "730LD INNOVATION PACKAGE (2993cc)",
          "730LD LIMOUSINE (2993cc)",
          "730LD LUXURY PACKAGE (2993cc)",
          "730LD M SPORT PLUS (5972cc)",
          "750LI M SPORT CBU (5972cc)",
          "740LI 4.0 L (4000cc)",
          "740LI DPE SIGNATURE (5972cc)",
          "BMW 740 I (4000cc)",
          "740I SEDAN (4000cc)",
          "740 LI. (4000cc)",
          "750I SEDAN (4799cc)",
          "750 LI 4.8 (4799cc)",
          "745D SEDAN (4799cc)",
          "750LI SEDAN (4799cc)",
          "740 LI (4799cc)",
          "750LI (4799cc)",
          "M760LI XDRIVE FACELIFT 4 STR (6592cc)",
          "760 LI M CENTENNIAL EDITION CBU (6592cc)",
          "M 760 LI X DRIVE 4 SEAT (6592cc)",
          "M 760 LI X DRIVE 5 SEAT (6592cc)",
          "M760LI XDRIVE FACELIFT (6592cc)",
          "M 760LI XDRIVE 5 STR (6592cc)",
          "M 760LI XDRIVE (6592cc)",
          "M760LI XDRIVE (6592cc)",
          "730 I (2996cc)",
          "760LI HIGH SECURITY (5250cc)",
          "750 LI 5.4 (5400cc)",
          "745 LI 4.4 LIMOUSINE RHD (4398cc)",
          "735 LI (3000cc)",
          "750 LI (4395cc)",
          "750LI (4395cc)",
          "750 LI (4395cc)",
          "745 LI (4398cc)",
          "M760LI XDRIVE (5972cc)",
          "730LD DESIGN PURE EXCELLENCE (5972cc)",
          "740LI LUXURY PACKAGE (2979cc)",
          "750LI CUSTOMISED (4395cc)",
          "760LI BULLETPROOF (5972cc)",
          "740LI (2979cc)",
          "760LI (5972cc)",
          "760 LI (5972cc)",
          "728I (2793cc)",
          "735LI (3600cc)",
          "735 LI (3600cc)",
          "730LD DPE SIGNATURE (5972cc)",
          "740LI DPE SIGNATURE (2998cc)",
          "730LD (2993cc)",
          "730 LD (2993cc)",
          "730 LD (2979cc)",
          "730LD M SPORT (5972cc)",
          "730LD LIMOUSINE RHD (2993cc)",
          "730LD LUXURY DYNAMIC PACKAGE (2993cc)",
          "740 LD DPE (2993cc)",
          "740 LD DPE SIGNATURE (2993cc)",
          "740 LD DPE M SPORT (2993cc)",
          "730LD LUXURY + DYNAMIC PACKAGE CBU (2993cc)",
          "730LD 4 SEATER 3.0 (2993cc)",
          "730LD M SPORT 4 SEATER 3.0 (2993cc)",
          "730 LD DPE 3.0 (2993cc)",
          "730LD 3.0 M SPORT PLUS (2993cc)",
          "730LD HOTEL SPEC (2993cc)",
          "730LD INNOVATION LUXURY PACKAGE (2993cc)",
          "730LD CBU (2993cc)",
          "730I SEDAN (2993cc)",
          "730LI SEDAN (2993cc)",
          "730LD SIGNATURE (2993cc)",
          "730 LD SIGNATURE (2993cc)",
          "730 LD EMINENCE (2993cc)",
          "730LD EMINENCE (2993cc)",
          "730LD M SPORT PLUS (2993cc)",
          "730LD DPE (2993cc)",
          "730LD SEDAN (2993cc)",
          "730LD PRESTIGE (2993cc)",
          "730 LD PRESTIGE (2993cc)",
          "730LD DPE (CBU) (2993cc)",
          "730LD DESIGN PURE EXCELLENCE (2993cc)",
          "730LD DPE SIGNATURE (2993cc)",
          "730LD M SPORT (2993cc)",
          "740I (2998cc)",
          "740 LI SIGNATURE CBU (2998cc)",
          "740 LI SIGNATURE (2998cc)",
          "745 LE X DRIVE 3.0 HYBRID 5 SEAT (2998cc)",
          "740 LI M SPORT 5 SEATER (2998cc)",
          "745 LE X DRIVE 3.0 HYBRID 4 SEAT (2998cc)",
          "740I LIMOUSINE (CC 2998) (2998cc)",
          "740I SEDAN (2998cc)",
          "745LE XDRIVE HYBRID (2998cc)",
          "740LI M SPORT EDITION (2998cc)",
          "745LE XDRIVE (2998cc)",
          "740 LI M SPORT EDITION INDIVIDUAL (2998cc)",
          "740LI M SPORT (2998cc)",
          "740LI INNOVATION LUXURY PACKAGE (2979cc)",
          "ACTIVE 7 HYBRID (2979cc)",
          "ACTIVE HYBRID (2979cc)",
          "740LI SEDAN (2979cc)",
          "728I (2979cc)",
          "750LI INNOVATION LUXURY PACKAGE (4395cc)",
          "750LI 4.4 DESIGN PURE EXCELLENCE CBU (4395cc)",
          "750LI DPE (CBU) (4395cc)",
          "750 LI EMINENCE (4395cc)",
          "750LI EMINENCE (4395cc)",
          "750LI M SPORT (CBU) (4395cc)",
          "750 LI DPE (4395cc)",
          "750LI M SPORT (4395cc)",
          "750LI SEDAN (4395cc)",
          "760LI INNOVATION LUXURY PACKAGE (5972cc)",
          "760 LI LIMOUSINE AT (5972cc)",
          "M 760 LI X DRIVE 5 SEAT (5972cc)",
          "M 760 LI X DRIVE V12 EXCELLENCE 5 SEAT (5972cc)",
          "760I SEDAN (5972cc)",
          "760LI EMINENCE (5972cc)",
          "760 LI EMINENCE (5972cc)",
          "760LI SEDAN (5972cc)",
        ],
      },
      {
        model: "5 SERIES",
        variants: [
          "520D SPORTS LINE (1995cc)",
          "525D 2.0 (1995cc)",
          "530I SPORTS LINE (1998cc)",
          "523I CKD AT (2497cc)",
          "520D M SPORT BSVI (1995cc)",
          "530I (2996cc)",
          "530I (1998cc)",
          "520D (1995cc)",
          "525D RSE (2993cc)",
          "525I (2497cc)",
          "520I (1995cc)",
          "525D LUXURY PLUS (1995cc)",
          "520D SPORTS LINE (2993cc)",
          "530I SPORTS LINE (2993cc)",
          "525D 2.0L (1995cc)",
          "520I (1997cc)",
          "525D (2993cc)",
          "520D LUXURY LINE (2993cc)",
          "(G30) 530 I M SPORT (1998cc)",
          "(G30) 530 I SPORT LINE (1998cc)",
          "530I M SPORT BS-VI (1998cc)",
          "530I M SPORT CARBON EDITION (1998cc)",
          "530 I 2.0 (1998cc)",
          "50 JAHRE M EDITION (1998cc)",
          "530I SPORT (1998cc)",
          "CARBON EDITION (1998cc)",
          "530I SPORT LINE (1998cc)",
          "(G30) 520 D M SPORT (1995cc)",
          "(G30) 520 D SPORT LINE (1995cc)",
          "(G30) 520 D LUXURY LINE (1995cc)",
          "520D LUXURY LINE BS-VI (1995cc)",
          "520D MODERN (1995cc)",
          "520D LUXURY (1995cc)",
          "520D LUXURY PLUS (1995cc)",
          "520D SALOON (1995cc)",
          "520D EDITION PRO (1995cc)",
          "523I SEDAN (1995cc)",
          "520I SEDAN (1995cc)",
          "520D MODERN LINE (1995cc)",
          "525D SEDAN (1995cc)",
          "520D SPORT LINE (1995cc)",
          "520D SEDAN (1995cc)",
          "520D M SPORT (1995cc)",
          "520D PRESTIGE (1995cc)",
          "520D PRESTIGE PLUS (1995cc)",
          "520I LUXURY LINE (1995cc)",
          "(G30) 530 D M SPORT (2993cc)",
          "GT 530D LE (2993cc)",
          "GT 530D LE (2993cc)",
          "GT DIESEL 530D (2993cc)",
          "530D M SPORT BS-VI (2993cc)",
          "530D M SPORT FL (2993cc)",
          "530D 4 SEATER 3.0 (2993cc)",
          "523I 3.0L (2993cc)",
          "530D NAV HUD RC (2993cc)",
          "535D SEDAN (2993cc)",
          "525 D SIII (2993cc)",
          "530 D GRAN TURISMO (2993cc)",
          "550I GRAN TURISM (2993cc)",
          "525D 3.0 (2993cc)",
          "523I SEDAN (2993cc)",
          "523I SEDAN (2993cc)",
          "530D HIGHLINE SEDAN (2993cc)",
          "530D SEDAN (2993cc)",
          "GT 530I (2996cc)",
          "BMW 530 I (2996cc)",
          "535I SEDAN (2996cc)",
          "545I (2996cc)",
          "530I SEDAN (2996cc)",
          "BMW 520 I (1999cc)",
          "530D/RHD (3000cc)",
          "550I LIMOUSINE RHD (4799cc)",
          "528I SEDAN (2793cc)",
          "520 I (2497cc)",
          "523I 2.5 (2497cc)",
          "525I SEDAN (2497cc)",
          "520I SEDAN (2497cc)",
          "525D SEDAN (2497cc)",
          "550I (4395cc)",
          "545 I (4398cc)",
          "520I SEDAN (1997cc)",
          "520I SEDAN (1997cc)",
          "530D (2933cc)",
          "530D HIGHLINE (2933cc)",
          "540I (4000cc)",
          "528I (2793cc)",
          "525D (1995cc)",
          "530D (2993cc)",
          "530D HIGHLINE (2993cc)",
          "540I (4398cc)",
          "523I (2497cc)",
          "535I (2979cc)",
          "520D LUXURY LINE (1995cc)",
          "530I M SPORT (1998cc)",
          "530D M SPORT (2993cc)",
          "520I LUXURY LINE (1997cc)",
        ],
      },
      {
        model: "6 SERIES COUPE",
        variants: [
          "650I 4.4 (4395cc)",
          "650I DYNAMIC + INNOVATION PAC (4799cc)",
          "640D (2993cc)",
          "640D (2993cc)",
          "640D GRAN COUPE (2993cc)",
          "645 CI (4398cc)",
          "650I DYNAMIC PACKAGE (4799cc)",
          "650I INNOVATION PACKAGE (4799cc)",
          "650I 4.4 L (4395cc)",
          "650I 4 SEATER 4.8 (4799cc)",
          "650I DYNAMIC + INNOVATION PACKAGE (4799cc)",
          "630I (4799cc)",
          "630I GT M SPORT 50 JAHRE M EDITION (1998cc)",
          "630I GT SPORT LINE (1998cc)",
          "630I GT LUXURY LINE (1998cc)",
          "630D GT M SPORT (2993cc)",
          "630D GT LUXURY LINE (2993cc)",
          "640D GRAN COUP DESIGN PURE EXPERIENCE (2993cc)",
          "640D GRAN COUPE EMINENCE (2993cc)",
          "645 CI 5 SEATER (4398cc)",
          "650I GRAN COUPE (4395cc)",
          "650I (4395cc)",
          "650I (4395cc)",
          "650 I (4799cc)",
          "650I (4799cc)",
        ],
      },
      {
        model: "2 SERIES",
        variants: [
          "GRAN COUPE 220I M SPORT PRO (1998cc)",
          "220 D GRAN COUPE SPORT (1995cc)",
          "BLACK SHADOW EDITION (1995cc)",
          "GRAN COUPE 220I SPORT (1198cc)",
          "GRAN COUPE 220I M SPORT (1198cc)",
          "220I SPORT (1998cc)",
          "220I M SPORT GRAN COUPE 5 SEATER (1998cc)",
          "220I M SPORT GRAN COUPE (1998cc)",
          "220I SPORT GRAN COUPE (1998cc)",
          "220I M SPORT PRO (1998cc)",
          "220 D SPORTLINE (1998cc)",
          "220I M SPORT (1998cc)",
          "220D M SPORT (1998cc)",
          "GRAN COUPE 220I BLACK SHADOW EDITION (1998cc)",
          "GRAN COUPE 220I SPORT (1998cc)",
          "GRAN COUPE 220I SPORT (1998cc)",
          "GRAN COUPE 220I M SPORT (1998cc)",
          "220D M SPORT GRAN COUPE (1995cc)",
          "220D M SPORT (1995cc)",
          "GRAN COUPE BLACK SHADOW EDITION (1995cc)",
          "GRAN COUPE 220D M SPORT FACELIFT (1995cc)",
          "GRAN COUPE 220D SPORTLINE (1995cc)",
          "GRAN COUPE 220D M SPORT (1995cc)",
          "220 D GRAN COUPE SPORTLINE (1995cc)",
        ],
      },
      {
        model: "6 SERIES GT",
        variants: [
          "630I M SPORT JAHRE M EDTN (1998cc)",
          "630D M SPORT BS-VI (2993cc)",
          "620D LUXURY LINE BS-VI (1995cc)",
          "630I LUXURY LINE BS-VI (1998cc)",
          "630I M SPORT 50 JAHRE M EDITION (1998cc)",
          "630I SPORT LINE (1998cc)",
          "630D LUXURY LINE (2993cc)",
          "630D M SPORT (2993cc)",
          "620D LUXURY LINE (1995cc)",
          "630I M SPORT (1998cc)",
          "630I LUXURY LINE (1998cc)",
          "630I LUXURY LINE (1998cc)",
        ],
      },
      {
        model: "3 SERIES",
        variants: [
          "320D LUXURY LINE PLUS (1995cc)",
          "320D SPORTS LINE (1995cc)",
          "320I M SPORT (1995cc)",
          "320I SPORTS LINE (1995cc)",
          "330D 5STR (2993cc)",
          "M 340I CKD 2021 (2998cc)",
          "330LI LUXURY LINE (1998cc)",
          "328I SPORTS LINE (1997cc)",
          "320I CORPORATE EDITION (1995cc)",
          "325I LCI (2497cc)",
          "320D EXCLUSIVE (1995cc)",
          "320D DYNAMIC PACKAGE (1995cc)",
          "320D LCI (1995cc)",
          "320D PERFORMANCE EDITION (1995cc)",
          "320D SPORT PLUS (1995cc)",
          "320D SPORT LINE CBU (1995cc)",
          "320D LUXURY PLUS (1995cc)",
          "320I (1995cc)",
          "M 340I (2998cc)",
          "330I M SPORT EDITION (1998cc)",
          "330D 5 SEATER (2993cc)",
          "316I (1596 CC) (1596cc)",
          "BMW 328 I (2793cc)",
          "328I LUXURY LINE (1997cc)",
          "328I SPORT LINE (1997cc)",
          "M340I 50 JAHRE M EDITION (2998cc)",
          "M340I XDRIVE NEW (2998cc)",
          "330CI CONVERTIBLE (2996cc)",
          "330CI COUPE (2996cc)",
          "330I TOURING (2996cc)",
          "330 CD CONVERTIBLE (2996cc)",
          "330CD COUPE (2996cc)",
          "330D SEDAN (2996cc)",
          "330D TOURING (2996cc)",
          "318 (2996cc)",
          "330I SPORT (2996cc)",
          "323 I (2449cc)",
          "320 CORPORATE EDITION (2979cc)",
          "M340I XDRIVE (2998cc)",
          "316I (1596cc)",
          "316I (1895cc)",
          "318I (1995cc)",
          "320I LCI (1995cc)",
          "330I M SPORT (1998cc)",
          "320D HIGHLINE (1995cc)",
          "330I SPORT (1998cc)",
          "320D M SPORT (1995cc)",
          "325I (2497cc)",
          "330I (2996cc)",
          "320D (1995cc)",
          "320D CORPORATE EDITION (1995cc)",
          "320D LUXURY LINE (1995cc)",
          "320D PRESTIGE (1995cc)",
          "320D HIGHLINE LCI (1995cc)",
          "320I PRESTIGE LINE (1998cc)",
          "330LI LUXURY LINE GRAN LIMOUSINE (1998cc)",
          "330I LCI M SPORT (1998cc)",
          "330I M SPORT BS-VI (1998cc)",
          "330 LI GRANE LIMOSUINE LUXURY LINE (1998cc)",
          "320I PRESTIGE (CBU) (1998cc)",
          "G L 330LI M SPORT 1ST EDN (1998cc)",
          "320I PRESTIGE (1998cc)",
          "330I M SPORT DARK (1998cc)",
          "330I M SPORT SHADOW EDITION (1998cc)",
          "330I SPORT LINE (1998cc)",
          "320I LUXURY LINE (1998cc)",
          "320D GRAN TURISMO (1995cc)",
          "320D SEDAN 5 STR (1995cc)",
          "320 D PRESTIGE LCI (1995cc)",
          "320 D SPORTS LCI (1995cc)",
          "320 D LUXURY LCI (1995cc)",
          "320 D M SPORTS LCI (1995cc)",
          "320LD LUXURY LINE GRAN LIMOUSINE (1995cc)",
          "X3 XDRIVE20D LUXURY EDITION (1995cc)",
          "320D EDITION SPORT SHADOW (1995cc)",
          "320D LUXURY LINE (4+1) (1995cc)",
          "320D BASE LINE (1995cc)",
          "320D M SPORTS (1995cc)",
          "320D SPORT LINE BS VI (1995cc)",
          "316CI COUPE (1995cc)",
          "318CI CONVERTIBLE (1995cc)",
          "318CI COUPE (1995cc)",
          "318I SEDAN (1995cc)",
          "318I TOURING (1995cc)",
          "320CI CONVERTIBLE (1995cc)",
          "320CI COUPE (1995cc)",
          "320I TOURING (1995cc)",
          "320SI SEDAN (1995cc)",
          "320CD CONVERTIBLE (1995cc)",
          "316I SEDAN (1995cc)",
          "318D SEDAN (1995cc)",
          "318D TOURING (1995cc)",
          "320CD COUPE (1995cc)",
          "320D SPORT SHADOW EDITION (1995cc)",
          "320D TOURING (1995cc)",
          "DIESEL 320D EXCLUSIVE (1995cc)",
          "G L 320LD LUXURY LINE (1995cc)",
          "330I M SPORT EDITION (1995cc)",
          "320D SPORT LINE (1995cc)",
          "GRAN TURISMO (1995cc)",
          "320I SEDAN (1995cc)",
          "320D HIGHLINE SEDAN (1995cc)",
          "320D SEDAN (1995cc)",
          "320 D EDITION SPORT (1995cc)",
          "320D EDITION SPORT (1995cc)",
          "330I SPORT LINE (1995cc)",
          "320D LUXURY EDITION (1995cc)",
          "BMW 326 I (2497cc)",
          "323 CI (2497cc)",
          "325CI CONVERTIBLE (2497cc)",
          "325CI COUPE (2497cc)",
          "325I TOURING (2497cc)",
          "325I SEDAN (2497cc)",
          "330D CONVERTIBLE (2993cc)",
          "330 D CONVERTIBLE 4 STR (2993cc)",
          "330 D (2993cc)",
          "316I 4DR (1600cc)",
        ],
      },
      {
        model: "Z4",
        variants: [
          "ROADSTER 35I (2979cc)",
          "ROADSTER 35I DPT (2979cc)",
          "ROADSTER 2.0I (2979cc)",
          "35I (2979cc)",
          "35I (2979cc)",
          "3.0 COUPE (2979cc)",
          "ROADSTER 2.5I (2979cc)",
          "ROADSTER 2.5SI (2979cc)",
          "COUPE 3.0SI (2979cc)",
          "ROADSTER 3.0 I (2979cc)",
          "SDRIVE35I (2979cc)",
          "S DRIVE 35I (2979cc)",
          "SDRIVE35I CBU (2979cc)",
          "SDRIVE 35I PRESTIGE (2979cc)",
          "SDRIVE 35I PRESTIGE (2979cc)",
          "ROADSTER SDRIVE 35I (2979cc)",
          "SDRIVE 35I DPT (2979cc)",
          "3.2 M COUPE (3999cc)",
          "3.21 M COUPE (3999cc)",
          "3.21 M COUPE (3999cc)",
          "ROADSTER 3.0I (2997cc)",
          "ROADSTER 3.0 I (2997cc)",
          "M40I (2998cc)",
          "M 40I (2998cc)",
          "SDRIVE 20I (1998cc)",
          "S DRIVE 20I (1998cc)",
        ],
      },
      {
        model: "1 SERIES",
        variants: [
          "120I HATCHBACK (1596cc)",
          "120D HATCHBACK (1596cc)",
          "130I HATCHBACK (1596cc)",
          "118I HATCHBACK (1596cc)",
          "118D LCI (1995cc)",
          "118D 5 DOOR (1995cc)",
          "118D SPORT LINE 5 DOOR (1995cc)",
          "118D SPORT PLUS  5 DOOR (1995cc)",
          "118D HATCHBACK (1995cc)",
          "118D PRESTIGE (1995cc)",
          "118D SPORT PLUS (1995cc)",
          "116I 5 DOOR (1598cc)",
          "118D HATCHBACK (1598cc)",
          "118D (1995cc)",
          "116I (1598cc)",
          "118D SPORT LINE (1995cc)",
        ],
      },
      {
        model: "118 D PRESTIGE",
        variants: ["0 (1995cc)"],
      },
      {
        model: "3 SERIES GRAN LIMOUSINE",
        variants: [
          "330 LI M SPORT FIRST EDITION BS6 (1998cc)",
          "330 LI LUXURY LINE BS6 (1998cc)",
          "330LI M SPORT (1998cc)",
          "330LI ICONIC EDITION (1998cc)",
          "330LI ICONIC EDITION (1998cc)",
          "330LI M SPORT FIRST EDITION (1998cc)",
          "330LI LUXURY LINE (1998cc)",
          "320LD M SPORT (1995cc)",
          "320LD ICONIC EDITION (1995cc)",
          "320LD LUXURY LINE (1995cc)",
        ],
      },
      {
        model: "3 SERIES GT",
        variants: [
          "320D SPORT (1995cc)",
          "320D SPORT (1995cc)",
          "320D LUXURY LINE (1995cc)",
          "320D LUXURY LINE (1995cc)",
          "320D SPORT LINE (1995cc)",
          "320D SPORT LINE (1995cc)",
          "320D SPORTS LINE (1995cc)",
          "320I LUXURY LINE PLUS (1995cc)",
          "320D LCI LUXURY LINE (1995cc)",
          "320LD  ICONIC EDITION (1995cc)",
          "330I M SPORT BS-VI (1998cc)",
          "330I M SPORT SHADOW EDITION (1998cc)",
          "330I M SPORT SHADOW EDITION (1998cc)",
          "330I LUXURY LINE (1998cc)",
          "330I LUXURY LINE (1998cc)",
          "330LI ICONIC EDITION (1998cc)",
          "330I M SPORT (1998cc)",
          "330I M SPORT (1998cc)",
        ],
      },
      {
        model: "320 CI",
        variants: ["0 (2171cc)"],
      },
      {
        model: "320 D",
        variants: ["0 (1995cc)"],
      },
      {
        model: "320 D CORPORATE EDITION",
        variants: ["0 (1995cc)"],
      },
      {
        model: "320 D GT LUXURY LINE",
        variants: ["0 (1995cc)"],
      },
      {
        model: "320 D LUXURY LINE",
        variants: ["0 (1995cc)"],
      },
      {
        model: "320 D SPORT LINE",
        variants: ["0 (1995cc)"],
      },
      {
        model: "320 I",
        variants: ["0 (1995cc)"],
      },
      {
        model: "320 I MT",
        variants: ["0 (1995cc)"],
      },
      {
        model: "320D HIGHLINE",
        variants: ["0 (1995cc)"],
      },
      {
        model: "320D M SPORT",
        variants: ["0 (1995cc)"],
      },
      {
        model: "320D PRESTIGE",
        variants: ["0 (1995cc)"],
      },
      {
        model: "323 I",
        variants: ["0 (2494cc)"],
      },
      {
        model: "328 I",
        variants: ["0 (1997cc)"],
      },
      {
        model: "328 I SPORT LINE",
        variants: ["0 (1997cc)"],
      },
      {
        model: "330D",
        variants: ["0 (2993cc)"],
      },
      {
        model: "4 SERIES",
        variants: ["M4 COUPE (2979cc)"],
      },
      {
        model: "520 D",
        variants: ["0 (1995cc)"],
      },
      {
        model: "520 I LUXURY LINE",
        variants: ["0 (1997cc)"],
      },
      {
        model: "520D LUXURY LINE",
        variants: ["0 (1995cc)"],
      },
      {
        model: "520D M SPORT",
        variants: ["0 (1995cc)"],
      },
      {
        model: "520D PRESTIGE",
        variants: ["0 (1995cc)"],
      },
      {
        model: "520D PRESTIGE PLUS",
        variants: ["0 (1995cc)"],
      },
      {
        model: "523 I",
        variants: ["0 (2497cc)"],
      },
      {
        model: "525 D",
        variants: ["0 (2497cc)"],
      },
      {
        model: "525D.",
        variants: ["0 (1995cc)"],
      },
      {
        model: "530D GT",
        variants: ["0 (2993cc)"],
      },
      {
        model: "530D M SPORT",
        variants: ["0 (2993cc)"],
      },
      {
        model: "535 I",
        variants: ["0 (2979cc)"],
      },
      {
        model: "6 SERIES CONVERTIBLE",
        variants: [
          "650I 4.4 L (4395cc)",
          "650I COMFORT + INNOVATION PACKAGE (4799cc)",
          "650I DYNAMIC + INNOVATION PACKAGE (4799cc)",
          "630I (4799cc)",
          "650I (4395cc)",
          "650I (4395cc)",
          "640D (2993cc)",
          "640D (2993cc)",
          "650I 4.4 (4395cc)",
          "650I COMFORT + INNOVATION PAC (4799cc)",
          "650I DYNAMIC + INNOVATION PAC (4799cc)",
          "650I (4799cc)",
          "650I (4799cc)",
          "650I COMFORT (4799cc)",
          "650I DYNAMIC PACKAGE (4799cc)",
          "650I INNOVATION PACKAGE (4799cc)",
        ],
      },
      {
        model: "6 SERIES GRAN COUPE",
        variants: [
          "GRAN COUPE (4395cc)",
          "640D EMINENCE (2993cc)",
          "640D DESIGN PURE EXPERIENCE (2993cc)",
          "640D 4 STR (2993cc)",
          "640 D 5 STR (2993cc)",
          "640 D (2993cc)",
          "M6 (4395cc)",
        ],
      },
      {
        model: "640 D",
        variants: ["0 (2993cc)"],
      },
      {
        model: "640D GRAN COUPE EMINENCE",
        variants: ["0 (2993cc)"],
      },
      {
        model: "650I CONVERTIBLE",
        variants: ["0 (4799cc)"],
      },
      {
        model: "6-SERIES GT",
        variants: [
          "620D M SPORT (1995cc)",
          "630I SPORTLINE (1998cc)",
          "630D LUXURY LINE (2993cc)",
          "630D M SPORT (2993cc)",
          "620 D LUXURY LINE (1995cc)",
          "630I M SPORT (1998cc)",
        ],
      },
      {
        model: "730 I",
        variants: ["0 (2996cc)"],
      },
      {
        model: "730 LD",
        variants: ["0 (2993cc)"],
      },
      {
        model: "730LD ACTIVE HYBRID",
        variants: ["0 (2979cc)"],
      },
      {
        model: "730LD EMINENCE",
        variants: ["0 (2993cc)"],
      },
      {
        model: "730LD SIGNATURE",
        variants: ["0 (2993cc)"],
      },
      {
        model: "740 LI",
        variants: ["0 (2979cc)"],
      },
      {
        model: "750LI EMINENCE",
        variants: ["0 (4395cc)"],
      },
      {
        model: "760LI EMINENCE",
        variants: ["0 (5972cc)"],
      },
      {
        model: "8 SERIES",
        variants: [
          "840 CI COUPE V8 (3982cc)",
          "840 CI 2DR COUPE 4.0 V8 AUTO (3982cc)",
          "840I GRAN COUPE M SPORT EDITION (2998cc)",
          "840I M SPORT (2998cc)",
          "840I M SPORT EDITION (2998cc)",
          "840I M SPORT EDITION. (2998cc)",
          "840I GRAN COUPE (2998cc)",
        ],
      },
      {
        model: "BMW  645 CI",
        variants: ["0 (4398cc)"],
      },
      {
        model: "BMW  745LI",
        variants: ["0 (4398cc)"],
      },
      {
        model: "BMW 116 I",
        variants: ["0 (1598cc)"],
      },
      {
        model: "BMW 118 D",
        variants: ["0 (1995cc)"],
      },
      {
        model: "BMW 118 D SPORT LINE",
        variants: ["0 (1995cc)"],
      },
      {
        model: "BMW 118 D SPORT PLUS",
        variants: ["0 (1995cc)"],
      },
      {
        model: "BMW 316",
        variants: ["0 (1594cc)"],
      },
      {
        model: "BMW 316 I",
        variants: ["0 (1995cc)"],
      },
      {
        model: "BMW 318 I",
        variants: ["0 (1995cc)"],
      },
      {
        model: "BMW 320D EXCLUSIVE",
        variants: ["0 (1995cc)"],
      },
      {
        model: "BMW 325 I",
        variants: ["0 (2497cc)"],
      },
      {
        model: "BMW 330 I",
        variants: ["0 (2996cc)"],
      },
      {
        model: "BMW 525 D",
        variants: ["0 (2993cc)"],
      },
      {
        model: "BMW 525 I",
        variants: ["0 (2497cc)"],
      },
      {
        model: "BMW 525 TDS",
        variants: ["0 (2498cc)"],
      },
      {
        model: "BMW 530 D",
        variants: ["0 (2993cc)"],
      },
      {
        model: "BMW 540 I",
        variants: ["0 (4398cc)"],
      },
      {
        model: "BMW 545I",
        variants: ["0 (4398cc)"],
      },
      {
        model: "BMW 650 I CABRIO RHD",
        variants: ["0 (4799cc)"],
      },
      {
        model: "BMW 650I COUPE",
        variants: ["0 (4799cc)"],
      },
      {
        model: "BMW 730D",
        variants: ["0 (2993cc)"],
      },
      {
        model: "BMW 735LI",
        variants: ["0 (3600cc)"],
      },
      {
        model: "BMW 740 LI",
        variants: ["0 (4000cc)"],
      },
      {
        model: "BMW 740D",
        variants: ["0 (3901cc)"],
      },
      {
        model: "BMW 750 LI",
        variants: ["0 (4395cc)"],
      },
      {
        model: "BMW 750 LI.",
        variants: ["0 (4799cc)"],
      },
      {
        model: "BMW 750I AL7",
        variants: ["0 (4988cc)"],
      },
      {
        model: "BMW 760LI",
        variants: ["0 (5972cc)"],
      },
      {
        model: "BMW AG75 LI",
        variants: ["0 (2000cc)"],
      },
      {
        model: "BMW GRAN TURISMO",
        variants: ["330I LUXURY LINE (1995cc)"],
      },
      {
        model: "BMW M3",
        variants: ["0 (3999cc)"],
      },
      {
        model: "BMW M5",
        variants: ["0 (4999cc)"],
      },
      {
        model: "BMW M-6 COUPE",
        variants: ["0 (4999cc)"],
      },
      {
        model: "I4",
        variants: [
          "EDRIVE40 (2189cc)",
          "EDRIVE40 M SPORT (99000cc)",
          "EDRIVE40 (99000cc)",
          "EDRIVE40 M SPORT (250cc)",
          "EDRIVE40 (250cc)",
          "EDRIVE 40 (1600cc)",
          "EDRIVE40 (83.9cc)",
          "EDRIVE 40 SPORT (4000cc)",
        ],
      },
      {
        model: "BMW X3",
        variants: ["0 (2497cc)"],
      },
      {
        model: "BMW X5 4.4I",
        variants: ["0 (4398cc)"],
      },
      {
        model: "BMW X5 M",
        variants: ["0 (4395cc)"],
      },
      {
        model: "BMW X5.",
        variants: ["0 (2993cc)"],
      },
      {
        model: "BMW X6",
        variants: ["0 (4395cc)"],
      },
      {
        model: "BMW X7",
        variants: ["0 (5000cc)"],
      },
      {
        model: "BMW Z4 35I",
        variants: ["0 (2979cc)"],
      },
      {
        model: "BMW Z4 ROADSTER",
        variants: ["0 (2979cc)"],
      },
      {
        model: "BMW525 D LUXURY PLUS",
        variants: ["0 (1995cc)"],
      },
      {
        model: "CKD 320 D",
        variants: ["0 (2497cc)"],
      },
      {
        model: "E 36",
        variants: ["316 I (1600cc)"],
      },
      {
        model: "GRAN TURISMO",
        variants: [
          "330I GT M SPORT (1998cc)",
          "S AUTOMATIC 4.7 (4691cc)",
          "AUTOMATIC 4.7 (4691cc)",
          "320D LUXURY LINE (1995cc)",
          "320D SPORT LINE (1995cc)",
          "330I LUXURY LINE (1998cc)",
          "330i GT M sport (2993cc)",
          "GT 630D M SPORTL (2993cc)",
          "GT 630D M SPORT LCI (2993cc)",
          "E 60 (2993cc)",
          "GT 320D (1993cc)",
          "4.2 V8 (4244cc)",
          "4.7 (AT) (4691cc)",
          "S (4691cc)",
          "4.7 S (AT) (4691cc)",
          "GT 630D LUXURY LINE (2993cc)",
          "GT 630D M SPORT (2993cc)",
          "GT 630I LUXURY LINE (1998cc)",
          "GT 320D SPORTS LINE (1995cc)",
          "GT 320I LUXURY LINE PLUS (1998cc)",
          "GT 530D (2993cc)",
          "GT 320D LUXURY LINE (1995cc)",
          "GT 530D (IMPORTED) (2993cc)",
          "GT 630I SPORTLINE (1998cc)",
          "GT 330I M SPORT (1998cc)",
          "GT 330I LUXURY LINE (1998cc)",
          "2.0 DIESEL LUXURY LINE (1995cc)",
          "GT 320D (1995cc)",
          "330I GT LUXURY LINE (1998cc)",
          "GT 630I M SPORT LCI (1998cc)",
          "320D GT LUXURY LINE (1995cc)",
          "320D GT SPORT LINE (1995cc)",
          "GT 320D SPORT LINE (1995cc)",
          "GT 620 D LUXURY LINE (1995cc)",
          "GT 320D LCI LUXURY LINE (1995cc)",
          "2.0 DIESEL SPORT LINE (1995cc)",
          "LUXURY LINE (1995cc)",
          "SPORT LINE (1995cc)",
        ],
      },
      {
        model: "GT 320 D SPORT LINE",
        variants: ["0 (1995cc)"],
      },
      {
        model: "I3S",
        variants: ["3.0 STD (3100cc)"],
      },
      {
        model: "I7",
        variants: [
          "XDRIVE 60 4STR (101700cc)",
          "XDRIVE60 (101700cc)",
          "XDRIVE 60 CBU (400cc)",
          "XDRIVE 60 CBU (150cc)",
          "XDRIVE60 M SPORT (150cc)",
          "XDRIVE 60 (1600cc)",
          "XDRIVE60 (101.7cc)",
        ],
      },
      {
        model: "I8",
        variants: ["1.5 HYBRID (1499cc)", "HYBRID (1499cc)"],
      },
      {
        model: "ICON",
        variants: ["0 (1995cc)"],
      },
      {
        model: "I-SERIES",
        variants: ["I8 (1499cc)"],
      },
      {
        model: "IX",
        variants: [
          "40 (240cc)",
          "XDRIVE 40 (240cc)",
          "40 XDRIVE (150cc)",
          "XDRIVE 40 (99000cc)",
          "XDRIVE40 (1600cc)",
          "XDRIVE40 (250cc)",
          "XDRIVE 40 (4000cc)",
        ],
      },
      {
        model: "M SERIES",
        variants: ["M340I (2998cc)"],
      },
      {
        model: "M SERIES M2",
        variants: [
          "COMPETITION 3.0 (2979cc)",
          "COMPETITION BS-VI (2979cc)",
          "COMPETITON (2979cc)",
          "COMPETITION (2979cc)",
          "3.0 PETROL (2993cc)",
        ],
      },
      {
        model: "M SERIES M3",
        variants: [
          "SEDAN 3.0 (2979cc)",
          "SEDAN (2979cc)",
          "SEDAN (2979cc)",
          "SEDAN (2979cc)",
          "SEDAN (2979cc)",
          "CONVERTIBLE 4 STR (3999cc)",
          "CONVERTIBLE (3999cc)",
          "CONVERTIBLE (3999cc)",
          "CONVERTIBLE (3999cc)",
          "COUPE (3999cc)",
          "COUPE (3999cc)",
          "COUPE (3999cc)",
          "CONVERTIBLE ADVANCED (3999cc)",
          "CONVERTIBLE ADVANCED (3999cc)",
          "COUPE ADVANCED (3999cc)",
          "ADVANCED (3999cc)",
          "M3 (4395cc)",
          "M3 SEDAN (4395cc)",
          "4.0 BASE (4999cc)",
        ],
      },
      {
        model: "X7",
        variants: [
          "XDRIVE 30D DPE (2993cc)",
          "XDRIVE 40I (2998cc)",
          "X DRIVE 40I (2998cc)",
          "XDRIVE 30D DPE SIGNATURE (2993cc)",
          "XDRIVE 30D DPE 7 SEATER (2993cc)",
          "XDRIVE 30D DPE 7 SEAT (2993cc)",
          "XDRIVE30D DPE SIGNATURE 6 SEAT (2993cc)",
          "XDRIVE30D DPE SIGNATURE 5 SEAT (2993cc)",
          "XDRIVE40D M SPORT 6 SEAT (2993cc)",
          "XDRIVE 30D DPE SIGNATURE 6 SEATER (2993cc)",
          "XDRIVE 30D DPE SIGNATURE 7 STR (2993cc)",
          "XDRIVE40D M SPORT (2993cc)",
          "XDRIVE 40D M SPORT (2993cc)",
          "40D M SPORT (2993cc)",
          "XDRIVE 30D DPE SIGNATURE 6 STR (2993cc)",
          "XDRIVE 30D DPE 7 STR (2993cc)",
          "DARK SHADOW EDITION (2993cc)",
          "M 50 D (2993cc)",
          "M50D (2993cc)",
          "XDRIVE 40I M SPORT 6 SEAT (2998cc)",
          "XDRIVE 40I M SPORT  50 JAHRE  EDITION 6 SEAT (2998cc)",
          "XDRIVE 40D M SPORT (2998cc)",
          "40I M SPORT (2998cc)",
          "XDRIVE 40I 6 STR (2998cc)",
          "XDRIVE 40I M SPORT (2998cc)",
          "50 JAHRE M EDITION (2998cc)",
          "M50D (2998cc)",
        ],
      },
      {
        model: "M SERIES M340I",
        variants: [
          "XDRIVE (2998cc)",
          "XDRIVE (2998cc)",
          "XDRIVE 50 JAHRE M EDITION (2998cc)",
        ],
      },
      {
        model: "M SERIES M4",
        variants: [
          "COUPE (2979cc)",
          "COUPE (2979cc)",
          "COUPE (2979cc)",
          "COUPE (2979cc)",
          "COUPE 4 STR (2979cc)",
          "COMPETITION (2993cc)",
          "COMPETITION M XDRIVE COUPE (2993cc)",
          "50 JAHRE EDITION (2993cc)",
          "COUPE (2993cc)",
        ],
      },
      {
        model: "M SERIES M5",
        variants: [
          "INDIVIDUAL (4999cc)",
          "INDIVIDUAL (4999cc)",
          "M5.0 BASE (4999cc)",
          "SEDAN (4999cc)",
          "4.4L (4395cc)",
          "SEDAN 5 STR (4395cc)",
          "COMPETITION EDITION 4.4 (4395cc)",
          "SEDAN (4395cc)",
          "SEDAN (4395cc)",
          "COMPETITION EDITION LCI CBU (4395cc)",
          "50 JAHRE M EDITION (4395cc)",
          "50 JAHRE M EDITION (4395cc)",
          "4.4 (4395cc)",
          "4.4 V8 (4395cc)",
          "M5 (4395cc)",
          "M5 SEDAN (4395cc)",
          "SEDAN 4.4L (4395cc)",
          "INDIVIDUAL (4395cc)",
          "COMPETITION (4395cc)",
          "COMPETITION (2998cc)",
        ],
      },
      {
        model: "M SERIES M6",
        variants: [
          "GRAN COUPE (4395cc)",
          "GRAN COUPE (4395cc)",
          "GRAN COUPE (4395cc)",
          "GRAN COUPE 4.4L (4395cc)",
          "GRAN COUPE 4 SEAT (4395cc)",
          "CONVERTIBLE 4.4 (4395cc)",
          "COUPE 4.4 (4395cc)",
          "COUPE 4.4 (4395cc)",
          "GRAN CONVERTIBLE (4999cc)",
          "GRAN COUPE (4999cc)",
          "CONVERTIBLE (4999cc)",
          "COUPE (4999cc)",
          "COUPE 5.0 BASE (4999cc)",
          "CONVERTIBLE 5.0 BASE (4999cc)",
          "CONVERTIBLE (4799cc)",
          "COUPE (4799cc)",
          "CONVERTIBLE INDIVIDUAL (4799cc)",
          "CONVERTIBLE INDIVIDUAL (4799cc)",
          "COUPE INDIVIDUAL (4799cc)",
          "COUPE INDIVIDUAL (4799cc)",
        ],
      },
      {
        model: "M SERIES M760",
        variants: ["LI X DRIVE (6592cc)"],
      },
      {
        model: "M SERIES M8",
        variants: ["COUPE (4395cc)", "GRAN COUPE (4395cc)"],
      },
      {
        model: "M3 CONVERTIBLE",
        variants: ["4.0 BASE (4999cc)"],
      },
      {
        model: "M6",
        variants: [
          "COUPE (4999cc)",
          "CONVERTIBLE (4999cc)",
          "4.4 GRAN COUPE (4395cc)",
        ],
      },
      {
        model: "M760LI",
        variants: [
          "M760LI XDRIVE (5972cc)",
          "M760LI XDRIVE V12 EXCELLENCE (5972cc)",
          "XDRIVE (5972cc)",
          "XDRIVE V12 EXCELLENCE (5972cc)",
        ],
      },
      {
        model: "M8",
        variants: ["50 JAHRE M EDITION (4395cc)", "COUPE (4395cc)"],
      },
      {
        model: "MINI 1.6 COOPER",
        variants: ["0 (1598cc)"],
      },
      {
        model: "MINI 2.0 COUNTRYMAN COOPER D HIGH",
        variants: ["0 (1995cc)"],
      },
      {
        model: "MINI CLUBMAN COOPER S",
        variants: ["0 (1998cc)"],
      },
      {
        model: "MINI COOPER",
        variants: [
          "COUNTRYMAN S (1998cc)",
          "D3 S (1998cc)",
          "S CONVERTIBLE (1998cc)",
          "CONVERTIBLE S (1998cc)",
          "1.6 (1998cc)",
          "ELECTRIC (99000cc)",
          "SE (135000cc)",
          "1.6 BASE (1598cc)",
          "COUNTRYMAN D HIGH (1598cc)",
          "S (1598cc)",
          "COUNTRYMAN SD (1995cc)",
          "1.6 (1496cc)",
        ],
      },
      {
        model: "MINI COOPER CONVERTIBLE",
        variants: ["0 (1598cc)"],
      },
      {
        model: "MINI COOPER D 3 DOOR",
        variants: ["0 (1496cc)"],
      },
      {
        model: "MINI COOPER D 5 DOOR",
        variants: ["0 (1496cc)"],
      },
      {
        model: "MINI COOPER S 3 DOOR",
        variants: ["0 (1998cc)"],
      },
      {
        model: "MINI COUNTRYMAN COOPER D HIGH",
        variants: ["0 (1598cc)"],
      },
      {
        model: "MINI F55 COOPER D",
        variants: ["0 (1498cc)"],
      },
      {
        model: "R 1250 GS",
        variants: ["PRO (1254cc)", "ADVENTURE PRO (1254cc)"],
      },
      {
        model: "R 1250 R",
        variants: ["STANDARD (1254cc)"],
      },
      {
        model: "X1",
        variants: [
          "SDRIVE 20I XLINE NEW (1998cc)",
          "X1 XDRIVE 20I X LINE (1998cc)",
          "X1 SDRIVE 20I X SPORT (1998cc)",
          "X1 SDIVE 20I TECH EDITION LCI (1998cc)",
          "SDRIVE 20I TECH EDI (1998cc)",
          "X1 SDRIVE 20I SPORTX (1998cc)",
          "SDRIVE 20I SPORTX (1998cc)",
          "SDRIVE 20I TECH EDITION (1998cc)",
          "SDRIVE 20I XLINE (1998cc)",
          "SDRIVE 18D (1995cc)",
          "X1 XDRIVE 20D (1995cc)",
          "X1 XDRIVE 20D M SPORT (1995cc)",
          "X1 XDRIVE 20D XLINE (1995cc)",
          "X1 SDRIVE 20D M SPORT (1995cc)",
          "X1 SDRIVE 20I XLINE (1995cc)",
          "X1 SDRIVE 20D CORPORATE EDITION (1995cc)",
          "X1 SDRIVE 20D EXPEDITION (1995cc)",
          "X1 SDRIVE 20D XLINE (1995cc)",
          "X1 SDRIVE 20D SPORTLINE (1995cc)",
          "SDRIVE20D EXCLUSIVE (1995cc)",
          "SDRIVE18D M SPORT (1995cc)",
          "X1 SDRIVE 20D CORPORATEED (1995cc)",
          "X1 SDRIVE 18I (1995cc)",
          "X1 SDRIVE 20D CBU (1995cc)",
          "X1 SDRIVE 20D (1995cc)",
          "X1 SDRIVE 20D COOL ELEGANCE (1995cc)",
          "X1 SDRIVE 20D EXCLUSIVE (1995cc)",
          "X1 SDRIVE20D INNOVATION (1995cc)",
          "X1 SDRIVE20D HIGHLINE (1995cc)",
          "X1 SDRIVE 18D M SPORT (1995cc)",
          "S DRIVE 20D SPORTLINE (1995cc)",
          "XDRIVE 20D M SPORT (1995cc)",
          "XDRIVE20D M SPORT (1995cc)",
          "SDRIVE 20I X LINE AT (1998cc)",
          "SDRIVE 20D SPORT LINE (1995cc)",
          "S DRIVE 20D (1995cc)",
          "SDRIVE 20D (1995cc)",
          "S DRIVE 20D CKD (1995cc)",
          "SDRIVE 20D CBU (1995cc)",
          "SDRIVE 18I (1995cc)",
          "S DRIVE 18I (1995cc)",
          "SDRIVE20I TECH EDITION (1995cc)",
          "SDRIVE 20D(H) (1995cc)",
          "SDRIVE M 18I SPORT (1499cc)",
          "SDRIVE18I M SPORT (1499cc)",
          "SDRIVE 18I XLINE (1499cc)",
          "SDRIVE18I XLINE (1499cc)",
          "X1 SDRIVE 18I XLINE (1499cc)",
          "XDRIVE 20D XLINE (1995cc)",
          "XDRIVE 20D XLINE (1995cc)",
          "S DRIVE 20 D EXPEDITION (1995cc)",
          "SDRIVE20D EXPEDITION (1995cc)",
          "SDRIVE 20D X LINE (1995cc)",
          "SDRIVE 20D XLINE (1995cc)",
          "SDRIVE20I XLINE (1995cc)",
          "SDRIVE 20I XLINE (1995cc)",
          "SDRIVE20D M SPORT (1995cc)",
        ],
      },
      {
        model: "X5",
        variants: [
          "XDRIVE 30D M SPORT (2993cc)",
          "XDRIVE 30D M SPORT (2993cc)",
          "XDRIVE 35I DESIGN PURE EXP 5S (2979cc)",
          "XDRIVE 30D DESIGN PUREEP (2993cc)",
          "XDRIVE 40I SPORT X PLUS BSVI (2998cc)",
          "XDRIVE 30D SPORT X PLUS BSVI (2993cc)",
          "XDRIVE 30D M SPORT BSVI (2993cc)",
          "XDRIVE 40I M SPORT BSVI (2998cc)",
          "M XDRIVE 30I EXPEDITION (4395cc)",
          "XDRIVE 35I DESIGN PURE EXP 5S (2993cc)",
          "M XDRIVE 30D EXPEDITION (4395cc)",
          "X5 50I (4395cc)",
          "X5 50I LUXURY PACKAGE (4395cc)",
          "X5 50I FULLY LOADED (4395cc)",
          "X5 M 4.4 D AT (4395cc)",
          "X5 M 4.4 AT (4395cc)",
          "XDRIVE 50I HSV CBU (4395cc)",
          "X5 M 4.4 D (4395cc)",
          "SAV 4.4I (4395cc)",
          "M (4395cc)",
          "M (4395cc)",
          "X5 50I 5STR (4395cc)",
          "X5 50I 5STRLUXURY PACKAGE (4395cc)",
          "X5 50I FULLY LOADED 5STR (4395cc)",
          "X5 XDRIVE RHD CBU (IMPORTED) (4395cc)",
          "XDRIVE 50I OPTIONS (4395cc)",
          "X5 50I 5 SEATER LUXURY PACKAGE (4395cc)",
          "X5 50I FULLY LOADED 5 SEATER (4395cc)",
          "X5 50I 5 SEATER (4395cc)",
          "HSV (4395cc)",
          "M COMPETITION (4395cc)",
          "XDRIVE 50I (4395cc)",
          "X5 XDRIVE 35 I DESIGN PURE EXPERIENCE 5 SEATER (2979cc)",
          "XDRIVE35I PURE EXPERIENCE (5 SEATER) (2979cc)",
          "3.0I X5 RHD (2979cc)",
          "X5 3.0I RHD (2979cc)",
          "XDRIVE 35I PURE EXPERIENCE (2979cc)",
          "3.0I RHD (2979cc)",
          "SAV 4.8IS (4799cc)",
          "X5 4.8I 7STR (4799cc)",
          "X5 4.8I (4799cc)",
          "X5 4.8I 7 SEATER (4799cc)",
          "4.8I 7 SEATER (4799cc)",
          "4.8I. (4799cc)",
          "4.8I (4799cc)",
          "X5 4.4I (4398cc)",
          "4.4 I (4398cc)",
          "XDRIVE.30D (2993cc)",
          "XDRIVE 30D (2993cc)",
          "XDRIVE 30D EXPEDITION (2993cc)",
          "XDRIVE 30D EXPEDITION (2993cc)",
          "XDRIVE 30D XLINE (2993cc)",
          "X DRIVE 30D X LINE (2993cc)",
          "X5 XDRIVE 30D CKD (2993cc)",
          "X5 XDRIVE 30D DESIGN (2993cc)",
          "XDRIVE 30D 5 STR (2993cc)",
          "SAV 3.0D 7STR (2993cc)",
          "XDRIVE 30D DESIGN PURE EXPERIENCE 5 STR (2993cc)",
          "X5 30 SAV 5 SEATER (2993cc)",
          "X5 XDRIVE 30 D 7 SEATER CKD (2993cc)",
          "X5 XDRIVE 30 D DESIGN PURE EXPERIENCE (7 SEATER) (2993cc)",
          "X5 XDRIVE 30 D DESIGN PURE EXPERIENCE (2993cc)",
          "X5 XDRIVE 30 D SPORT PLUS (2993cc)",
          "X5 XDRIVE 30 D XLINE (2993cc)",
          "X5 XDRIVE 30 D SPORT (2993cc)",
          "X5 XDRIVE 40 I SPORTX PLUS 2993 CC (2993cc)",
          "X5 XDRIVE 30D EXPEDITION 7 SEATER (2993cc)",
          "X5 3.0D M SPORT (2993cc)",
          "X5 XDRIVE 30 D M SPORT (2993cc)",
          "X5 XDRIVE 30 D EDITION X (2993cc)",
          "X5 XDRIVE 30 D (2993cc)",
          "X5 M 50D 3.0 (2993cc)",
          "X5 XDRIVE 30 D EXPEDITION (2993cc)",
          "X5 XDRIVE 30D CBU (2993cc)",
          "XDRIVE 30D EDITION X (2993cc)",
          "XDRIVE30D PURE EXPERIENCE (5 SEATER) (2993cc)",
          "XDRIVE30D PURE EXPERIENCE (7 SEATER) (2993cc)",
          "SPORTX (2993cc)",
          "SAV 3.0D (2993cc)",
          "SAV 3.0I (2993cc)",
          "3.0D X5 (2993cc)",
          "3.0D X5 7STR (2993cc)",
          "3.0D X5 FF42 7STR (2993cc)",
          "3.0D X5 HIGHLINE (2993cc)",
          "3.0D X5 LUXURY PACKAGE (2993cc)",
          "3.0D X5 SAV (2993cc)",
          "X5 XDRIVE 30D (IMPORTED) (2993cc)",
          "3.0D 1 (2993cc)",
          "X5 3.0D (2993cc)",
          "3.0D 2 (2993cc)",
          "X5 3.0D HIGHLINE (2993cc)",
          "X5 3.0D SAV (2993cc)",
          "X5 3.0D LUXURY PACKAGE (2993cc)",
          "X5 XDRIVE 30 D OPTIONS (2993cc)",
          "X5 3.0D FF42 7 SEATER (2993cc)",
          "X5 3.0D 7 SEATER (2993cc)",
          "XDRIVE 30D DESIGN PURE EXPERIENCE 7STR (2993cc)",
          "XDRIVE 30D EXPEDITION 5STR (2993cc)",
          "XDRIVE 30 D (OPTIONS) (2993cc)",
          "XDRIVE 30D 7STR (2993cc)",
          "XDRIVE 30D SPORT X (2993cc)",
          "XDRIVE 30D SPORT (2993cc)",
          "XDRIVE 30D SPORT X PLUS (2993cc)",
          "3.0D (2993cc)",
          "XDRIVE 30D DESIGN PURE EXPERIENCE (2993cc)",
          "X5 XDRIVE 40 I M SPORT (2998cc)",
          "X5 XDRIVE 40 I SPORTX PLUS (2998cc)",
          "XDRIVE 40I SPORT PLUS (2998cc)",
          "XDRIVE 40I M SPORT (2998cc)",
          "XDRIVE 40I M SPORT (2998cc)",
          "X DRIVE 40I SPORT X PLUS (2998cc)",
          "XDRIVE 40I SPORT X PLUS (2998cc)",
        ],
      },
      {
        model: "X3",
        variants: [
          "XDRIVE20I XLINE (1998cc)",
          "XDRIVE 20D EDITION LUXURY (1995cc)",
          "M40I XDRIVE (2998cc)",
          "XDRIVE20D EXPEDITION (1998cc)",
          "XDRIVE20D XLINE (1998cc)",
          "XDRIVE 30I SPORT X BS6 (1998cc)",
          "X3 XDRIVE 30I SPORTX (1998cc)",
          "X3 XDRIVE 30I M SPORT (1998cc)",
          "X3 XDRIVE 30I SPORTX PLUS (1998cc)",
          "X3 XDRIVE 30I LUXURY LINE (1998cc)",
          "XDRIVE 30I LUXURY LINE BS-VI (1998cc)",
          "XDRIVE30I SPORTX PLUS (1998cc)",
          "XDRIVE 30I SPORTX PLUS (1998cc)",
          "XDRIVE30I M SPORT (1998cc)",
          "XDRIVE 30I M SPORT (1998cc)",
          "XDRIVE30I SPORTX (1998cc)",
          "M40I (2998cc)",
          "SAV 2.0D (1995cc)",
          "X3 XDRIVE 20D XLINE (1995cc)",
          "X3 XDRIVE20D LUXURY EDITION (1995cc)",
          "X3 XDRIVE 20D M SPORT (1995cc)",
          "X3 XDRIVE 20D XLINE NAV (1995cc)",
          "X3 XDRIVE 20D  LUXURY LINE (1995cc)",
          "X3 XDRIVE 20D EXPEDITION (1995cc)",
          "X3 XDRIVE 20D (1995cc)",
          "XDRIVE20D ADVANTAGE (1995cc)",
          "20D M SPORT (1995cc)",
          "SAV 2.0I (1995cc)",
          "2.0D X3 (1995cc)",
          "2.0D X3 ADVANTAGE (1995cc)",
          "XDRIVE 20D M SPORT (1995cc)",
          "XDRIVE 20D M SPORT (1995cc)",
          "X3 2.0D ADVANTAGE (1995cc)",
          "X3 2.0D (1995cc)",
          "2.0D (1995cc)",
          "XDRIVE 20D (1995cc)",
          "XDRIVE20D LUXURY EDITION.. (1995cc)",
          "XDRIVE 20D LUXURY EDITION (1995cc)",
          "XDRIVE 28I XLINE (1995cc)",
          "X3 XDRIVE 30D X LINE (2993cc)",
          "X3 XDRIVE (2993cc)",
          "X3 XDRIVE 30D M SPORT (2993cc)",
          "X3 XDRIVE 30D EXPEDITION (2993cc)",
          "XDRIVE30D EXPEDITION (2993cc)",
          "3.0D X3 (2993cc)",
          "M SPORT (2993cc)",
          "X3 3.0D (2993cc)",
          "XDRIVE 30D M SPORT (2993cc)",
          "XDRIVE 30D M SPORT (2993cc)",
          "M PETROL. (2993cc)",
          "M PETROL (2993cc)",
          "XDRIVE 30D (2993cc)",
          "X3 XDRIVE 28I XLINE (1997cc)",
          "X3 XDRIVE 4.4L (4395cc)",
          "SAV 2.0I (2497cc)",
          "SAV 2.5I (2497cc)",
          "2.5 X3 SI (2497cc)",
          "X3 2.5 SI (2497cc)",
          "2.5SI (2497cc)",
          "SAV 3.0D (2996cc)",
          "SAV 3.0I (2996cc)",
          "3.0SI (2996cc)",
          "3.0 X3 SI (2987cc)",
          "X3 3.0 SI (2987cc)",
          "3.0 SI (2987cc)",
          "XDRIVE 20D EXPEDITION (1995cc)",
          "XDRIVE 20D EXPEDITION (1995cc)",
          "XDRIVE 20D XLINE (1995cc)",
          "XDRIVE-20D XLINE (1995cc)",
          "XDRIVE 30I LUXURY LINE (1998cc)",
          "X DRIVE 28I XLINE (1997cc)",
          "XDRIVE 28I XLINE (1997cc)",
          "XDRIVE 20D LUXURY LINE (1995cc)",
        ],
      },
      {
        model: "X6",
        variants: [
          "M 4.4 V8 4X4 (4395cc)",
          "M 4.4 V8 (4395cc)",
          "X6 M (4395cc)",
          "X DRIVE 5.0I (4395cc)",
          "XDRIVE 50I (4395cc)",
          "M COUPE (4395cc)",
          "M (4395cc)",
          "44M (4395cc)",
          "XDRIVE 40I M SPORT (4395cc)",
          "XDRIVE 40I XLINE (4395cc)",
          "X6 XDRIVE40I M SPORT (2993cc)",
          "XDRIVE40D EXPEDITION (2993cc)",
          "XDRIVE 30D (2993cc)",
          "XDRIVE 30D (2993cc)",
          "XDRIVE 35D (2993cc)",
          "XDRIVE 40D (2993cc)",
          "XDRIVE 40D (2993cc)",
          "35I M SPORT (2979cc)",
          "XDRIVE 30I M SPORT (2979cc)",
          "XDRIVE 35I M SPORT (2979cc)",
          "XDRIVE 40I M SPORT CC 2998 (2998cc)",
          "50 JAHRE M EDITION (2998cc)",
          "XDRIVE 40I M SPORT (2998cc)",
          "XDRIVE 40I XLINE. (2998cc)",
          "XDRIVE 40I XLINE (2998cc)",
          "XDRIVE40D M SPORT (2993cc)",
        ],
      },
      {
        model: "X3 2.0",
        variants: ["0 (1995cc)"],
      },
      {
        model: "X3 3.0",
        variants: ["0 (2993cc)"],
      },
      {
        model: "XM",
        variants: ["XDRIVE (4395cc)", "PLUG-IN HYBRID (4395cc)"],
      },
      {
        model: "X6 SERIES",
        variants: [
          "X DRIVE 35I M SPORT (2979cc)",
          "XDRIVE40I M SPORT (2998cc)",
          "X DRIVE 35D (2993cc)",
          "X DRIVE 30D (2993cc)",
          "X DRIVE 40D (2993cc)",
          "XDRIVE 40D EXPEDITION (2993cc)",
          "X6 XDRIVE 40D M SPORT (2993cc)",
          "X6 XDRIVE 40D EXPEDITION (2993cc)",
          "X6 XDRIVE 35I M SPORT (2979cc)",
          "X6 XDRIVE 40I X LINE (2998cc)",
          "X6 XDRIVE 40I M SPORT (2998cc)",
        ],
      },
      {
        model: "Z4 MODELS",
        variants: [
          "3.0I ROADSTER (2997cc)",
          "M 40I (2998cc)",
          "SDRIVE 20I (1998cc)",
          "SDRIVE 35I PRESTIGE (2979cc)",
          "SDRIVE 35I DPT (2979cc)",
        ],
      },
      {
        model: "3 SERIES CONVERTIBLE",
        variants: ["330D (2993cc)", "330D 5 SEATER (2993cc)"],
      },
      {
        model: "X5 4.8",
        variants: ["0 (4799cc)"],
      },
      {
        model: "Z3 MODELS",
        variants: ["2.2 ROADSTER (2171cc)", "ROADSTER (2979cc)"],
      },
      {
        model: "VINTAGE",
        variants: ["VINTAGE (2900cc)"],
      },
      {
        model: "IX40",
        variants: ["XDRIVE 40 (2189cc)"],
      },
      {
        model: "Z3",
        variants: [
          "ROADSTER (1995cc)",
          "ROADSTER 2.2 (2171cc)",
          "ROADSTER 2.2 (2171cc)",
          "1.8 (1799cc)",
        ],
      },
      {
        model: "M MODELS",
        variants: ["M5 (4999cc)"],
      },
      {
        model: "2-SERIES",
        variants: [
          "220I GRAN COUPE M SPORT (1998cc)",
          "220I GRAN COUPE SPORT (1998cc)",
          "220I GRAN COUPE BLACK SHADOW EDITION (1998cc)",
          "220D GRAN COUPE M SPORT (1995cc)",
        ],
      },
      {
        model: "X4",
        variants: [
          "XDRIVE 30I M SPORT X (1998cc)",
          "XDRIVE30I M SPORT X (1998cc)",
          "XDRIVE20D M SPORT X (1995cc)",
          "XDRIVE30D M SPORT X (2993cc)",
          "X4 XDRIVE 30D 50 JAHRE M EDITION (2993cc)",
          "X4 XDRIVE 30 D M SPORT X SHADOW EDITION (2993cc)",
          "X4 XDRIVE 30 D M SPORT X (2993cc)",
          "XDRIVE 30D 50 JAHRE M EDITION (2993cc)",
          "XDRIVE30D M SPORT X BLACK SHADOW EDITION (2993cc)",
          "XDRIVE30D M SPORT X SILVER SHADOW EDITION (2993cc)",
          "X4 XDRIVE 30I 50 JAHRE M EDITION (1998cc)",
          "X4 XDRIVE 30 I EDITION X (1998cc)",
          "XDRIVE30I M SPORT X BLACK SHADOW EDITION (1998cc)",
          "XDRIVE 30I M SPORT X BS-VI (1998cc)",
          "XDRIVE 30I 50 JAHRE M EDITION (1998cc)",
          "XDRIVE30I M SPORT X SILVER SHADOW EDITION (1998cc)",
          "X4 XDRIVE 20 D M SPORT X (1995cc)",
        ],
      },
      {
        model: "6 SERIES",
        variants: [
          "GRAN COUPE (4395cc)",
          "620D LUXURY LINE (1995cc)",
          "645I 4 STR (4799cc)",
          "645I (4799cc)",
          "650I COMFORT PLUS INNOVATION PACKAGE (4799cc)",
          "650I DYNAMIC PLUS INNOVATION PACKAGE (4799cc)",
          "650I INNOVATION PACKAGE (4799cc)",
          "650I DYNAMIC PACKAGE (4799cc)",
          "650I COMFORT (4799cc)",
          "COUPE (4999cc)",
          "CONVERTIBLE (4395cc)",
          "650I 4.4 L (4395cc)",
          "640 D (2993cc)",
          "640D DESIGN PURE EXPERIENCE (2993cc)",
          "640D EMINENCE (2993cc)",
          "645 CI (4398cc)",
        ],
      },
      {
        model: "M5",
        variants: ["5.0 Base (4395cc)"],
      },
      {
        model: "6-SERIES",
        variants: ["GRAN COUPE (2993cc)"],
      },
      {
        model: "X6 M",
        variants: ["X DRIVE 35I M SPORT (2979cc)"],
      },
    ],
  },
  {
    make: "CHEVROLET",
    models: [
      {
        model: "TAVERA",
        variants: [
          "BASE 10STR BSIV (2499cc)",
          "BASE 9STR BSIV (2499cc)",
          "LS 10STR BSIV (2499cc)",
          "LS 10 STR BS IV (1994cc)",
          "LS 7STR BSIV (2499cc)",
          "LS 9STR BSIV (2499cc)",
          "LT 9STR BSIV (2499cc)",
          "MAX 10STR BSIV (2499cc)",
          "B1 (2499cc)",
          "B2 (2499cc)",
          "ELITE LS B3 (2499cc)",
          "ELITE LT L1 (2499cc)",
          "ELITE LT L2 (2499cc)",
          "NY B1 (2499cc)",
          "NY B2 (2499cc)",
          "NY ELITE LS B3 (2499cc)",
          "NY ELITE LT L1 (2499cc)",
          "NY ELITE LT L2 (2499cc)",
          "NY ELITE SS D1 (2499cc)",
          "ELITE SS- D1 (2499cc)",
          "LS 7C STR BS IV (2499cc)",
          "ELITE LS B3 9 STR (2499cc)",
          "MAX 9 STR BS IV (2499cc)",
          "ELITE LT- L1 9 SEATER (2499cc)",
          "NY ELITE LT L1- 9 SEATER (2499cc)",
          "ELITE LT L1 10 SEATER (2499cc)",
          "ELITE LS B3 10 SEATER (2499cc)",
          "ELITE LS B3 7 SEATER (2499cc)",
          "ELITE LT L1 7 SEATER (2499cc)",
          "ELITE LT L2 8 SEATER (2499cc)",
          "ELITE SS D1 8 SEATER (2499cc)",
          "NY ELITE LS B3 10 SEATER (2499cc)",
          "NY ELITE LS B3 7 SEATER (2499cc)",
          "NY ELITE LT L1 7 SEATER (2499cc)",
          "NY ELITE SS D1 8 SEATER (2499cc)",
          "B1 10 SEATER (2499cc)",
          "B1 7 SEATER (2499cc)",
          "NY B1 10 SEATER (2499cc)",
          "NY B1 7 SEATER (2499cc)",
          "NY B2 10 SEATER (2499cc)",
          "NY B2 7 SEATER (2499cc)",
          "B2 8 SEATER (2499cc)",
          "NEO LT D1 8 SEATER (2499cc)",
          "NEO LT L 8 SEATER (2499cc)",
          "LT L2 8STR (2499cc)",
          "SS D1 8STR (2499cc)",
          "D1 8STR NEO (2499cc)",
          "B2 8 STR BS II (2499cc)",
          "NEO 3 LT 8 STR BS III (2499cc)",
          "BS III 6STR (2499cc)",
          "LT L2 8 SEATER (2499cc)",
          "B2 8 STR BS III (2499cc)",
          "NEO 3 LT 8 STR BS IV (2499cc)",
          "NEO SS D1 8 STR BS III (2499cc)",
          "NEO SS - D1 8-SEATER - BS III (2499cc)",
          "NEO 3 LT 8 SEATS (2499cc)",
          "NEO 2 D1  8 SEATS (2499cc)",
          "B1 6-SEATER (2499cc)",
          "P8A (2499cc)",
          "NEO 3 1.2 EL PS (1242cc)",
          "B1 10- SEATER-BS IV (2000cc)",
          "B1 7-SEATER-BS IV (2000cc)",
          "B2 10 SEATER BS IV (2000cc)",
          "B2 7-SEATER BS IV (2000cc)",
          "B3 7-SEATER BS IV (2000cc)",
          "NEO3 MAX 10 SEATER BS IV (2000cc)",
          "NEO3 MAX 7 SEATER BS IV (2000cc)",
          "E III 8STR (2500cc)",
          "P 8 A BS III (2500cc)",
          "NEO3 LT 7 C STR BS IV (1999cc)",
          "NEO 3 LT 9-SEATER BS IV (1999cc)",
          "NEO 3-10 STR BS IV (1999cc)",
          "NY ELITE LT L2 8 SEATER (2499cc)",
          "NEO LS B4 7 SEATER (2499cc)",
          "NEO LT D1 7 SEATER (2499cc)",
          "NEO LT L1 7 SEATER (2499cc)",
          "NEO LT L1 9 SEATER (2499cc)",
          "NEO LS B3 9STR (2499cc)",
          "B3 5STR (2499cc)",
          "NEO LT L9 9STR (2499cc)",
          "NEO LT L9 7STR (2499cc)",
          "B1 10STR (2499cc)",
          "B1 7STR (2499cc)",
          "ROYALE 10 STR (2499cc)",
          "B3 ELITE 7STR (2499cc)",
          "B3 ELITE 10STR (2499cc)",
          "B2 10STR (2499cc)",
          "B3 10STR (2499cc)",
          "LT L1 7STR (2499cc)",
          "LT L1 9STR (2499cc)",
          "LT L1 NEO 9STR (2499cc)",
          "L1 ELITE 7STR (2499cc)",
          "L1 ELITE 9STR (2499cc)",
          "L2 ELITE 8STR (2499cc)",
          "D1 ELITE 8STR (2499cc)",
          "NEO LT-M 7 STR (2499cc)",
          "D1 7STR NEO (2499cc)",
          "B3 7STR NEO (2499cc)",
          "B3 10STR NEO (2499cc)",
          "B4  7STR (2499cc)",
          "ELITE NEO B1 (2499cc)",
          "B2 2.5 7STR (2499cc)",
          "D1 7STR (2499cc)",
          "B4 10STR (2499cc)",
          "B2 ELITE 10STR (2499cc)",
          "B3 (M) EURO-3 (2499cc)",
          "B1 7 SEATER BSII (2499cc)",
          "B1 10 SEATS (2499cc)",
          "B2 10 SEATS (2499cc)",
          "7 SEATER (2499cc)",
          "B2 10 SEATER (2499cc)",
          "10 SEATER (2499cc)",
          "B2 7 SEATS BSII (2499cc)",
          "ELITE LT 7 SEATER (2499cc)",
          "ELITE LT 10 SEATER (2499cc)",
          "LT - L1 7 SEATER (2499cc)",
          "LS- B3 7 SEATER (2499cc)",
          "ELITE LS 7 SEATER (2499cc)",
          "ELITE SS 8 SEATER (2499cc)",
          "LS- B3 10 SEATER (2499cc)",
          "ELITE LS 10 SEATER (2499cc)",
          "B2 7 SEATER (2499cc)",
          "NY 10 SEATER (2499cc)",
          "NEO LS- B4 7 CAPTAIN (2499cc)",
          "NEO SS- D1 7 SEATER (2499cc)",
          "LT - L2 (2499cc)",
          "SS D1 (2499cc)",
          "NEO LT- L 9 SEATER (2499cc)",
          "NEO LT- L 7 SEATER (2499cc)",
          "NY 7 SEATER (2499cc)",
          "LT - L1 9 SEATER (2499cc)",
          "B1 10 STR BS II (2499cc)",
          "B1 7 STR BS II (2499cc)",
          "ELITE LS B3 10 STR BS II (2499cc)",
          "ELITE LS B3 7 STR BS II (2499cc)",
          "ELITE LT L1 7 STR BS II (2499cc)",
          "ELITE LT L1 9 STR BS II (2499cc)",
          "ELITE LT L2 BS II (2499cc)",
          "ELITE SS D1 BS II (2499cc)",
          "LS B3 10 STR BS II (2499cc)",
          "LS B3 7 STR BS II (2499cc)",
          "LT L1 7 STR BS II (2499cc)",
          "LT L1 9 STR BS II (2499cc)",
          "LT L2 BS II (2499cc)",
          "NY B1 10 STR BS II (2499cc)",
          "NY B1 7 STR BS II (2499cc)",
          "NY B2 10 STR BS II (2499cc)",
          "NY B2 7 STR BS II (2499cc)",
          "NY ELITE LS B3 10 STR BS II (2499cc)",
          "NY ELITE LS B3 7 STR BS II (2499cc)",
          "NY ELITE LT L1 7 STR BS II (2499cc)",
          "NY ELITE LT L1 9 STR BS II (2499cc)",
          "NY ELITE LT L2 BS II (2499cc)",
          "NY ELITE LT-L2-BSII (2499cc)",
          "NY ELITE SS D1 BS II (2499cc)",
          "SS D1 BS II (2499cc)",
          "NEO 3 10 STR BS III (2499cc)",
          "NEO 3 7 STR BS III (2499cc)",
          "NEO 3 MAX 10 STR BS III (2499cc)",
          "NEO 3 MAX 7 STR BS III (2499cc)",
          "NEO 3 LS 10 STR BS III (2499cc)",
          "NEO 3 LS 7 STR BS III (2499cc)",
          "NEO 3 LT 9 STR BS III (2499cc)",
          "NEO3 LS 7 C STR BS III (2499cc)",
          "LS 8 SEATER BS III (2499cc)",
          "NEO3-LS-10-BS3 (2499cc)",
          "P7A BS IV MT (2499cc)",
          "ELITE LS B3 9 SEATER (2499cc)",
          "NEO SS D1 7 STR BS III (2499cc)",
          "NEO LS B4 7 STR BS III (2499cc)",
          "NEO LS B4 7 CAPTAIN BS III (2499cc)",
          "NEO LT L 7 STR BS III (2499cc)",
          "NEO LT L 9 STR BS III (2499cc)",
          "B1 7 STR BS III (2499cc)",
          "B1 10 STR BS III (2499cc)",
          "NEO LS B3 8 STR BS III (2499cc)",
          "LT L1 7 STR BS III (2499cc)",
          "LT L1 9 STR BS III (2499cc)",
          "NEO 3 MAX 7 STR BS IV (2499cc)",
          "NEO 3 LS R 10 STR BS IV (2499cc)",
          "ELITE LT L1 8 STR BS III (2499cc)",
          "ELITE LT L1 7 STR BS III (2499cc)",
          "NY ELITE LT L1 9 STR BS III (2499cc)",
          "NEO 3 MAX 9 STR BS III (2499cc)",
          "MAX 10 STR (2499cc)",
          "NEO 3 LS 10 STR BS IV (2499cc)",
          "NEO 3 LS 8 STR C BS III (2499cc)",
          "NEO 3 MAX 10 STR BS IV (2499cc)",
          "ELITE LT L1 9 STR BS III (2499cc)",
          "LS B3 7 STR BS III (2499cc)",
          "SS D1 BS III (2499cc)",
          "LS 7(C) STR (2499cc)",
          "NEO 3 LS R 7 STR BS III (2499cc)",
          "NEO 3 LT R 9 STR BS III (2499cc)",
          "BASE 10 STR (2499cc)",
          "NEO3 LT 7 C STR BS II (2499cc)",
          "NEO 3 MAX AMBULANCE BS III (2499cc)",
          "LT Ã‚â‚¬â€œ L2 BSIII (2499cc)",
          "LT L2 BS III (2499cc)",
          "ELITE LS B3 10 STR BS III (2499cc)",
          "ELITE LS B3 7 STR BS III (2499cc)",
          "LS 10 STR (2499cc)",
          "LS 7 STR (2499cc)",
          "LS 9 STR (2499cc)",
          "LS B3 10 STR BS III (2499cc)",
          "LT 9 STR (2499cc)",
          "NEO 3 7 STR BS IV (2499cc)",
          "NEO 3 LS 7 STR BS IV (2499cc)",
          "NEO 3 LS 7 STR C BS III (2499cc)",
          "NEO 3 LS 9 STR BS III (2499cc)",
          "NEO LS B3 10 STR BS III (2499cc)",
          "NEO LS B3 7 STR BS III (2499cc)",
          "NEO3 LT 7 C STR BS III (2499cc)",
          "NY B1 10 STR BS III (2499cc)",
          "NY B1 7 STR BS III (2499cc)",
          "NY B2 10 STR BS III (2499cc)",
          "NY B2 7 STR BS III (2499cc)",
          "NY ELITE LS B3 10 STR BS III (2499cc)",
          "NY ELITE LS B3 7 STR BS III (2499cc)",
          "NY ELITE LT L1 7 STR BS III (2499cc)",
          "NEO 3 LT 9 STR BS IV (2499cc)",
          "NY ELITE LT - L1 7-SEATER - BS III (2499cc)",
          "NEO LT - L 7-SEATER - BS III (2499cc)",
          "NY ELITE LT - L1 9-SEATER - BS III (2499cc)",
          "ELITE LT - L1 7-SEATER - BS III (2499cc)",
          "NY ELITE LS - B3 7-SEATER - BS III (2499cc)",
          "NEO LT - L 9-SEATER - BS III (2499cc)",
          "ELITE LS - B3 7-SEATER - BS III (2499cc)",
          "NY ELITE LS - B3 10-SEATER - BS III (2499cc)",
          "ELITE LT - L1 9-SEATER - BS III (2499cc)",
          "B1 7 SEATER BSIII (2499cc)",
          "NEO 3 LT 7 SEATS (CAPTAIN) (2499cc)",
          "NEO 3 LT 9 SEATS (2499cc)",
          "NEO 2 B47(C) (2499cc)",
          "NEO 3 MAX 10 SEATS (2499cc)",
          "NEO 3 LS 7 SEATS (2499cc)",
          "NEO 3 LT 9-SEATER BS III (2499cc)",
          "NEO 3 LS 10 STR (2499cc)",
          "NY B1 7-SEATER - BS III (2499cc)",
          "LS B2 10SEATER (2499cc)",
          "P7C (2499cc)",
          "NEO LS - B3 7-SEATER - BS III (2499cc)",
          "NEO LS B3 10SEATER (2499cc)",
          "NEO LS - B4 7-SEATER - BS III (2499cc)",
          "P10A (2499cc)",
          "NEO B3 10-SEATER - BS III (2499cc)",
          "NEO D1 7-SEATER - BS III (2499cc)",
          "NY B1 10-SEATER - BS III (2499cc)",
          "ELITE LS - B3 10-SEATER - BS III (2499cc)",
          "P7A (2499cc)",
          "NY B2 10-SEATER - BS III (2499cc)",
          "LS B3 (2499cc)",
          "NY B2 7-SEATER - BS III (2499cc)",
          "NEO 2 L7 SEATS (2499cc)",
          "NEO 2 D1  7 SEATS (2499cc)",
          "NEO 3 LS 7 SEATS (CAPTAIN) (2499cc)",
          "B1 10-SEATER - BS III (2499cc)",
          "NEO 2 B3 10 SEATS (2499cc)",
          "NEO 3 LS 10 SEATS (2499cc)",
          "B4 7 NEO 2 (2499cc)",
          "NEO 3 LS 9 STR (2499cc)",
          "NEO 3 MAX 7 SEATS (2499cc)",
          "NEO 2 L9 SEATS (2499cc)",
          "LS B1 10SEATER (2499cc)",
          "ELITE SS D1 BS III (2499cc)",
          "ELITE SS-D1 -BS III (2499cc)",
          "ELITE LT L2 BS III (2499cc)",
          "NY ELITE LT L2 BS III (2499cc)",
          "NY ELITE SS D1 BS III (2499cc)",
          "NEO 3 10 STR BS IV (2499cc)",
          "LS 8 SEATER BS IV (2000cc)",
          "NEO 3 BS IV LS 7 STR (1994cc)",
          "NEO 3 BS IV LT 7 STR CAPTAIN (1994cc)",
          "NEO 3 BS IV LT 8 STR (1994cc)",
          "NEO 3 BS IV LT 9 STR (1994cc)",
          "NEO3 LS 7 C STR BS IV (1999cc)",
        ],
      },
      {
        model: "OPTRA MAGNUM",
        variants: [
          "2.0 TCDI MAX (1991cc)",
          "1.6 LS (1598cc)",
          "2.0 MAX TCDI (1991cc)",
          "2.0 LS TCDI (1991cc)",
          "2.0 LT TCDI (1991cc)",
          "LT 1.6 (1598cc)",
          "LT (1598cc)",
          "1.6 MAX (1598cc)",
          "1.6 LT (1598cc)",
          "LS 2.0 TCDI (1991cc)",
          "LT 2.0 TCDI (1991cc)",
          "MAX 2.0 TCDI (1991cc)",
          "TCDI LS (1991cc)",
          "2.0 LT (1991cc)",
          "MAX (1991cc)",
          "LT (1991cc)",
          "2.0 LT TCDI AAC (1991cc)",
          "LS (1991cc)",
          "LS 1.6 (1598cc)",
          "MAX 1.6 (1598cc)",
          "MAX (1598cc)",
          "LS (1598cc)",
        ],
      },
      {
        model: "OPTRA SRV",
        variants: [
          "1.6 O (1598cc)",
          "2.0 TCDI (1991cc)",
          "SRV 1.6 VGIS PETROL (1598cc)",
          "BASE (1598cc)",
          "OPTION PACK (1598cc)",
          "1.6 (1598cc)",
        ],
      },
      {
        model: "CAPTIVA",
        variants: [
          "2.0 AWD 5STR (AT) (1991cc)",
          "2.0 AWD 7STR (AT) (1991cc)",
          "2.0 LT 7STR (1991cc)",
          "2.0 LTZ 5STR (1991cc)",
          "2.0 LTZ 7STR (1991cc)",
          "2.0 LTZ AWD 5STR (AT) (1991cc)",
          "2.0 LTZ AWD 7STR (AT) (1991cc)",
          "2.2 LTZ AWD 7STR (AT) (2231cc)",
          "2.2L (2231cc)",
          "110 PS RXL ADVENTURE EDITION (1461cc)",
          "2.2 LTZ AWD AT 7 SEATER (2231cc)",
          "2.0 LT 5 SEATER (1991cc)",
          "2.0 LTZ 5 SEATER (1991cc)",
          "2.0 LT 7 SEATER (1991cc)",
          "2.0 LTZ 7SEATER (1991cc)",
          "2.0 AWD AT 5 SEATER (1991cc)",
          "2.0 AWD AT 7 SEATER (1991cc)",
          "2.0 LTZ AWD AT 7 SEATER (1991cc)",
          "2.0 LTZ AWD AT 5 SEATER (1991cc)",
          "XTREME (1991cc)",
          "XTREME (1991cc)",
          "LT (1991cc)",
          "LT (1991cc)",
          "AWD AT (1991cc)",
          "LTZ (1991cc)",
          "2.2 LT (1991cc)",
          "LTZ AWD AT (1991cc)",
          "LTZ AWD XTREME (1991cc)",
          "2.0 LT MT (1991cc)",
          "2.0 VCDI (1991cc)",
          "VCDI AT (1991cc)",
          "2.0 L TZ AWD AT (1991cc)",
          "2.0 XTREME (1991cc)",
          "2.0 LT (1991cc)",
          "2.2 2WD (2231cc)",
          "2.2 LTZ AWD AT (2231cc)",
          "2.2 LT 5 SEATER (2231cc)",
          "2.2 LT 7 SEATER (2231cc)",
          "2.0 MT (2231cc)",
          "2.2 AT AWD (2231cc)",
          "2.2 MT 2WD (2231cc)",
          "LTZ AWD 2.2 (2231cc)",
          "2.2 LTZ AWD (2231cc)",
          "LTZ AWD (2231cc)",
          "2.4 LT AWD (2405cc)",
          "2.0 LT 5 STR (1991cc)",
        ],
      },
      {
        model: "CRUZE",
        variants: [
          "2.0 LT (1991cc)",
          "2.0 LT NEW (1998cc)",
          "2.0 LTZ (1991cc)",
          "2.0 LTZ NEW (AT) (1998cc)",
          "2.0 LTZ NEW (MT) (1998cc)",
          "J300 (2000cc)",
          "2.0 LTZ AMT NEW (1998cc)",
          "2.0 LTZ M TRANSMOSSION NEW (1998cc)",
          "2.0 LTZ AUTOMATIC TRANSMOSSION (1991cc)",
          "2.0 LTZ (1998cc)",
          "VCDI LTZ BSIV (1998cc)",
          "2.0 LTZ AUTOMATIC TRANSMOSSION NEW(1998 CC) (1998cc)",
          "2.0 LTZ MANUAL TRANSMOSSION NEW (1998 CC) (1998cc)",
          "2.0 LT (1998 CC) (1998cc)",
          "LTZ MT (1998cc)",
          "LT (1998cc)",
          "2.0 LTZ AT (1998cc)",
          "1.8 LT (1800cc)",
          "1.8 LTZ (1800cc)",
          "2.0 LT MT BS4 (1991cc)",
          "LT (1991cc)",
          "LT (1991cc)",
          "LTZ (1991cc)",
          "LTZ (1991cc)",
          "LTZ AT (1991cc)",
          "LTZ AT (1991cc)",
          "2.0 LTZ (AT) (1991cc)",
          "LTZ (1998cc)",
          "LTZ AT (1998cc)",
        ],
      },
      {
        model: "SPARK",
        variants: [
          "1 (995cc)",
          "1 (995cc)",
          "1.0 LT (O) (995cc)",
          "1.0 LS MUSIC EDITION (995cc)",
          "1.0 LT (OPTION PACK) (995cc)",
          "1.0 LT LPG (995cc)",
          "1.0 LS LPG (995cc)",
          "1.0 PS LPG (995cc)",
          "1.0 LT (995cc)",
          "1.0 LT (995cc)",
          "1.0 E (995cc)",
          "1.0 E (995cc)",
          "1.0 PS (995cc)",
          "1.0 PS (995cc)",
          "1.0 LS (995cc)",
          "1.0 LS (995cc)",
          "1.0 LS SPORTS (995cc)",
          "LT OPTION PACK (995cc)",
          "CNG (995cc)",
          "E (995cc)",
          "LS (995cc)",
          "LT (995cc)",
          "PS (995cc)",
          "1.0 BS-III (995cc)",
          "BASE (995cc)",
          "LS 1.0 (995cc)",
          "LT 1.0 (995cc)",
          "LS 1.0 BS-III (995cc)",
          "LT 1.0 BS-III (995cc)",
          "PS 1.0 (995cc)",
          "E 1.0 (995cc)",
          "LS 1.0 MUZIC (995cc)",
          "PS 1.0 LPG (995cc)",
          "LS 1.0 LPG (995cc)",
          "LT 1.0 LPG (995cc)",
          "LT 1.0 O (995cc)",
          "LIMITED EDITION (995cc)",
          "LT 1.0 AIRBAG (995cc)",
          "1.0 LT OP (AIRBAG) (995cc)",
          "1.0 LT OP (995cc)",
          "MUZIC 1.0 LS (995cc)",
          "1.0 BS-IV OBDII (995cc)",
          "LS 1.0 BS-IV OBDII (995cc)",
          "LT 1.0 BS-IV OBDII (995cc)",
          "1.2 (1200cc)",
          "LT (999cc)",
          "1.2 (1199cc)",
          "1.0 LS (1000cc)",
        ],
      },
      {
        model: "SAIL",
        variants: [
          "1.3 TCDI (1248cc)",
          "1.3 TCDI (1248cc)",
          "1.2 (1199cc)",
          "1.2 (1199cc)",
          "STD (1199cc)",
          "1.2L ABS (1199cc)",
          "1.2 LS NON ABS (1199cc)",
          "1.2 LS (ABS)(MT) (1199cc)",
          "1.3 TCDI PS (1248cc)",
          "1.2 PS (1199cc)",
          "1.3 TCDI LS (1248cc)",
          "1.3 TCDI LS (1248cc)",
          "1.2 LT (1199cc)",
          "LT (1199cc)",
          "LS (1199cc)",
          "BASE (1199cc)",
          "PS (1199cc)",
          "1.3 TCDI LT (1248cc)",
          "LT (1248cc)",
          "LS (1248cc)",
          "BASE (1248cc)",
          "PS (1248cc)",
          "1.3 PS (1248cc)",
          "1.3 TCDI BASE (1248cc)",
          "1.2 BASE (1150cc)",
          "1.3 TCDI LS ABS (1248cc)",
          "1.3 TCDI LT ABS (1248cc)",
          "1.3 LS ABS (1248cc)",
          "1.3 LT ABS (1248cc)",
          "1.3 BASE (1248cc)",
          "1.3 LS (1248cc)",
          "1.2 LS (1199cc)",
          "1.2 LS (1199cc)",
          "1.2 BASE (1199cc)",
          "1.2 LS ABS (1199cc)",
          "1.2 LT ABS (1199cc)",
        ],
      },
      {
        model: "AVEO",
        variants: [
          "1.6 LT (1598cc)",
          "1.6 LT (1598cc)",
          "1.4  LS (1399cc)",
          "1.4 LS (1399cc)",
          "1.4 (1399cc)",
          "1.4 (1399cc)",
          "1.6 LT (MT) (1598cc)",
          "1.4 E (1399cc)",
          "1.4 LS LTD EDITION (1399cc)",
          "1.4 CNG (1399cc)",
          "1.4 CNG (1399cc)",
          "LS 1.4 (1399cc)",
          "LS 1.4 LTD (1399cc)",
          "LT 1.4 ABS (1399cc)",
          "1.4 LS LIMITED EDITION (1399cc)",
          "LT 1.6 O (1598cc)",
          "1.4 LT (1399cc)",
          "1.6 LT OPTION PACK (1598cc)",
          "1.4 LT ABS (1399cc)",
          "1.6 LT  ABS (1598cc)",
          "E 1.4 (1399cc)",
          "LT 1.4 (1399cc)",
          "CNG 1.4 (1399cc)",
          "LT 1.6 (1598cc)",
          "LT 1.6 ABS (1598cc)",
        ],
      },
      {
        model: "BEAT",
        variants: [
          "1.0 LS (936cc)",
          "1.0 LS (936cc)",
          "1.0 LT (936cc)",
          "1.0 LT (936cc)",
          "1.0 PS (936cc)",
          "1.0 PS (936cc)",
          "PS (936cc)",
          "LT (936cc)",
          "LS (936cc)",
          "LS (1199cc)",
          "LS (1199cc)",
          "LTZ (936cc)",
          "1.0 LT (995cc)",
          "1.0 LT (O) (936cc)",
          "1.2 LT (O) (1199cc)",
          "1.19 BASE (MT) (1199cc)",
          "LT BSIII (1199cc)",
          "1.2 BASE (1199cc)",
          "1.2 LS LPG (1199cc)",
          "1.2  LT LPG (1199cc)",
          "1.0 TCDI DIESEL (936cc)",
          "1.0 LT OPTION PACK DIESEL (936cc)",
          "1.0 PS DIESEL (936cc)",
          "1.0 LS DIESEL (936cc)",
          "1.0 LT DIESEL (936cc)",
          "1.2 LT OPTION PACK (1199cc)",
          "BASE (1199cc)",
          "LT LPG (1199cc)",
          "LS LPG (1199cc)",
          "LS 1.2 LPG (1199cc)",
          "LT 1.2 LPG (1199cc)",
          "BASE (998cc)",
          "1.2 LTZ (1199cc)",
          "1.2 PS (1199cc)",
          "1.2 LS (1199cc)",
          "1.2 LT (1199cc)",
          "1.0 TCDI (936cc)",
          "TCDI (936cc)",
          "TCDI (936cc)",
          "LT OPTION PACK (936cc)",
          "1.0 LTZ DIESEL (936cc)",
          "LS DIESEL (936cc)",
          "LT DIESEL (936cc)",
          "PS DIESEL (936cc)",
          "LTZ DIESEL (936cc)",
          "LT O DIESEL (936cc)",
          "LT 1.0 (936cc)",
          "1.0 LT TCDI MY 12 (936cc)",
          "1.0 PS TCDI (936cc)",
          "1.0 TCDI MY 12 (936cc)",
          "1.0 LS TCDI MY 12 (936cc)",
          "LS 1.0 (936cc)",
          "PS 1.0 (936cc)",
          "LT 1.0 OPT (936cc)",
          "1.0 LT TCDI WITH OPTION PACK MY 12 (936cc)",
          "1.0 LT TCDI (936cc)",
          "LT OPTION PACK (1199cc)",
          "LT OPTION PACK (1199cc)",
          "PS PETROL (1199cc)",
          "LS PETROL (1199cc)",
          "LT PETROL (1199cc)",
          "LTZ PETROL (1199cc)",
          "LT O PETROL (1199cc)",
          "PS (1199cc)",
          "PS (1199cc)",
          "LT (1199cc)",
          "LT (1199cc)",
          "LTZ (1199cc)",
        ],
      },
      {
        model: "OPTRA 1.6 LS",
        variants: [
          "NY (1599cc)",
          "ELITE WITH CNG (1598cc)",
          "ELITE (1598cc)",
          "(CNG) (1599cc)",
          "1.6 LS (1599cc)",
        ],
      },
      {
        model: "TRAILBLAZER",
        variants: [
          "LTZ 4X2 (AT) (2776cc)",
          "LTZ 2WD AT (2766cc)",
          "2.8 LTZ AUTOMATIC TRANSMOSSION (2776cc)",
          "LTZ AT (2776cc)",
          "2.8L AT (2800cc)",
        ],
      },
      {
        model: "TAVERA NEO 3",
        variants: [
          "10STR BS4 (2499cc)",
          "2.5 6STR (2499cc)",
          "2.5 LT 8STR (2499cc)",
          "2.5 10STR (2499cc)",
          "2.5 7STR (2499cc)",
          "2.5 LS 10STR (2499cc)",
          "2.5 LS CAPTAIN 7STR (2499cc)",
          "2.5 LS 7STR (2499cc)",
          "2.5 LS CAPTAIN 7STR BS4 (2499cc)",
          "2.5 LT CAPTAIN 7STR (2499cc)",
          "2.5 LT 9STR (2499cc)",
          "2.5 LT CAPTAIN 7STR BS4 (2499cc)",
          "2.5 MAX 10STR (2499cc)",
          "2.5 MAX 7STR (2499cc)",
          "LS 10STR BS4 (2499cc)",
          "LS 7STR BS4 (2499cc)",
          "LT 8STR BS4 (2499cc)",
          "LT 9STR BS4 (2499cc)",
          "MAX 10STR BS4 (2499cc)",
          "MAX 7STR BS4 (2499cc)",
          "2.5 MAX (2499cc)",
          "B1 (2499cc)",
          "B2 (2499cc)",
          "B3 (2499cc)",
          "LS BS3 (2499cc)",
          "LS 9 BS3 (2499cc)",
          "LS B2 (2499cc)",
          "LS B3 (2499cc)",
          "LS 8 STR BS3 (2499cc)",
          "LS 9 STR BS3 (2499cc)",
          "LS B2 7 STR (2499cc)",
          "LS 7 SEATER BS4 (2499cc)",
          "2.5 LS 7 SEATER (2499cc)",
          "2.5 LS 7 CAPTAIN SEATER (2499cc)",
          "2.5 LS 10 SEATER (2499cc)",
          "2.5 LS BS4 7 CAPTAIN SEATER (2499cc)",
          "LS 10 SEATER BS4 (2499cc)",
          "2.5 MAX 9 STR (2499cc)",
          "B1 6 STR (2499cc)",
          "B1 8 STR (2499cc)",
          "B2 10 STR (2499cc)",
          "B3 10 STR (2499cc)",
          "B3 7 STR (2499cc)",
          "LT 8 SEATER BS4 (2499cc)",
          "LT 9 SEATER BS4 (2499cc)",
          "MAX 10 SEATER BS4 (2499cc)",
          "MAX 7 SEATER BS4 (2499cc)",
          "10 SEATER BS4 (2499cc)",
          "2.5 LT 9 SEATER (2499cc)",
          "2.5  LT 8 SEATER (2499cc)",
          "2.5 LT 7 CAPTAIN SEATER (2499cc)",
          "2.5 10 SEATER (2499cc)",
          "2.5 7 SEATER (2499cc)",
          "2.5 MAX-10 SEATER (2499cc)",
          "2.5 MAX-7 SEATER (2499cc)",
          "2.5 LT BS4 7 CAPTAIN SEATER (2499cc)",
          "2.5  6 SEATER (2499cc)",
          "7 SEATER (1248cc)",
          "6 SEATER (1248cc)",
          "STANDARD (2000cc)",
          "LT (2000cc)",
          "MAX (2000cc)",
          "BASE 6 SEATER (2499cc)",
          "B1 6 SEATER (2499cc)",
          "B1 8 SEATER (2499cc)",
          "LS 7 SEATER (1248cc)",
          "LS (2000cc)",
          "LT (2499cc)",
          "LS 10 SEATER (2499cc)",
          "LS 7 SEATER (2499cc)",
          "CAPTAIN (2499cc)",
          "MAX 10 SEATER (2499cc)",
          "MAX 7 SEATER (2499cc)",
          "BASE 10 SEATER (2499cc)",
          "BASE 7 SEATER (2499cc)",
          "B3 LS 10 SEATER (2499cc)",
          "LS 9 SEATER BS3 (2499cc)",
          "LS 8 SEATER BS3 (2499cc)",
          "LS B3 7 SEATER (2499cc)",
          "LS B2 7 SEATER (2499cc)",
          "B2 10 SEATER (2499cc)",
          "B3 10 SEATER (2499cc)",
          "B3 7 SEATER (2499cc)",
          "2.5 MAX 9 SEATER (2499cc)",
          "2.5 LS 9 SEATER (2499cc)",
          "LS 10 SEATER (1999cc)",
        ],
      },
      {
        model: "AVEO UVA",
        variants: [
          "1.2 LT (O) WITH AIRBAG (1150cc)",
          "1.2 LT (O) (1150cc)",
          "LS (1150cc)",
          "1.2 LT OPTION (1150cc)",
          "1.2 LT OP WITH AIRBAG (1150cc)",
          "1.2 (1150cc)",
          "1.2 (1150cc)",
          "1.2 (1150cc)",
          "1.2 LT (1150cc)",
          "1.2 LT (1150cc)",
          "1.2 LS (1150cc)",
          "1.2 LS (1150cc)",
          "LS 1.2 (1150cc)",
          "LT 1.2 (1150cc)",
          "LT 1.2 ABS (1150cc)",
          "LT 1.2 ABS & AIRBAG (1150cc)",
          "LS 1.2 TECHNO (1150cc)",
          "1.2 LS TECHNO (1150cc)",
        ],
      },
      {
        model: "OPTRA 1.6",
        variants: [
          "PLATINUM (1599cc)",
          "PLATINUM (1599cc)",
          "PLATINUM (1599cc)",
          "NY (1599cc)",
          "ELITE (1599cc)",
          "ELITE (1598cc)",
        ],
      },
      {
        model: "OPTRA 1.8 LT",
        variants: [
          "AT (1799cc)",
          "AT (1799cc)",
          "NY (AT) (1799cc)",
          "NY (1799cc)",
          "AUTO TRANS (1799cc)",
          "NY AUTO TRANS (1799cc)",
          "1.8 LT (1799cc)",
        ],
      },
      {
        model: "OPTRA 1.8 LS",
        variants: [
          "NY (1799cc)",
          "AT (1799cc)",
          "AT (1799cc)",
          "1.8 LS (1799cc)",
        ],
      },
      {
        model: "ENJOY",
        variants: [
          "1.4 LTZ 7 (1399cc)",
          "1.4 LTZ 8 (1399cc)",
          "1.3 LS (1248cc)",
          "1.3 TCDI LT 8 (1248cc)",
          "1.3 TCDI LTZ 7 (1248cc)",
          "1.3 TCDI LTZ 8 (1248cc)",
          "1.4 LS 7 (1399cc)",
          "1.4 LS 8 (1399cc)",
          "1.4 LT 7 (1399cc)",
          "1.4 LT 8 (1399cc)",
          "1.3 TCDI LS 8 (1248cc)",
          "1.3 TCDI LT 7 (1248cc)",
          "1.3 TCDI LS 7 (1248cc)",
          "1.3 TCDI LS / 6 SEATERS (1248cc)",
          "1.4 LS (1399cc)",
          "1.3 TCDI LS 7 STR (1248cc)",
          "1.3 TCDI LT 7 STR (1248cc)",
          "1.3 TCDI LTZ 8 STR. (1248cc)",
          "1.3 TCDI LTZ 7 STR (1248cc)",
          "1.3 TCDI LS 8 STR (1248cc)",
          "1.3 TCDI LT 8 STR (1248cc)",
          "1.4 LS 7 STR (1399cc)",
          "1.4 LS 8 STR (1399cc)",
          "1.4 LT 8 STR (1399cc)",
          "1.4 LT 7 STR (1399cc)",
          "LT (1248cc)",
          "LTZ (1248cc)",
          "LS (1248cc)",
          "1.3 TCDI LS 8 SEATER (1248cc)",
          "1.3 TCDI LS 7 SEATER (1248cc)",
          "1.3 TCDI LT 7 SEATER (1248cc)",
          "1.3 TCDI LTZ 7 SEATER (1248cc)",
          "1.3 TCDI LTZ 8 SEATER (1248cc)",
          "1.3 TCDI LT 8 SEATER (1248cc)",
          "1.3 LT 7 STR (1248cc)",
          "LIMITED EDITION (1248cc)",
          "1.3 LS 7 STR (1248cc)",
          "1.3 LS 8 STR (1248cc)",
          "1.3 LT 8 STR (1248cc)",
          "1.3 LTZ 7 STR (1248cc)",
          "1.3 LTZ 8 STR (1248cc)",
          "LT (1399cc)",
          "LTZ (1399cc)",
          "LS (1399cc)",
          "1.4 LS 8 SEAT (1399cc)",
          "1.4 LS 7 SEATER (1399cc)",
          "1.4 LT 7 SEATER (1399cc)",
          "1.4 LTZ 7 SEATER (1399cc)",
          "1.4 LT 8 SEATER (1399cc)",
          "1.4 LTZ 8 SEATER (1399cc)",
          "1.4 LTZ 8 STR (1399cc)",
          "1.4 LTZ 7 STR (1399cc)",
          "1.4 LS 8 SEATER (1389cc)",
          "1.4 LT 8 SEATER (1389cc)",
          "1.4 LTZ 8 SEATER (1389cc)",
          "1.4 LTZ 8 STR (1389cc)",
          "1.4 LTZ 7 STR (1389cc)",
          "1.4 LS 7 STR (1389cc)",
          "1.4 LS 8 STR (1389cc)",
          "1.4 LT 8 STR (1389cc)",
          "1.4 LT 7 STR (1389cc)",
        ],
      },
      {
        model: "OPTRA",
        variants: [
          "1.6 BASE (1599cc)",
          "LACETTI (1799cc)",
          "VIVA (1799cc)",
          "LACETTI (1800cc)",
          "VIVA (1800cc)",
          "1.6 LS (1599cc)",
          "1.8 LS (1799cc)",
          "1.8 LT (1799cc)",
          "1.4 (1398cc)",
          "NY (1599cc)",
          "PLATINUM (1599cc)",
          "LS (1599cc)",
          "ELITE (1599cc)",
          "LS 1.6 (1599cc)",
          "1.6 (1599cc)",
          "LT (1799cc)",
          "LS (1799cc)",
          "LT 1.8 (1799cc)",
          "LS 1.8 (1799cc)",
          "MAX 1.8 (1799cc)",
          "1.8 (1799cc)",
          "ELITE 1.6 (1598cc)",
        ],
      },
      {
        model: "FORESTER",
        variants: [
          "FLEAT MASTER (2900cc)",
          "FORESTER (1994cc)",
          "STANDARD (2000cc)",
          "AWD PETROL (1994cc)",
          "AWD (1994cc)",
        ],
      },
      {
        model: "SAIL UVA",
        variants: [
          "1.3 PS (1248cc)",
          "LT ABS (2499cc)",
          "1.2 (1199cc)",
          "1.2 (1199cc)",
          "1.3 TCDI LS (1248cc)",
          "1.3 TCDI LS (1248cc)",
          "1.3 TCDI LS ABS (1248cc)",
          "1.3 TCDI LT ABS (1248cc)",
          "1.2 LS (1199cc)",
          "1.2 LS (1199cc)",
          "1.2 LS ABS (1199cc)",
          "1.2 LS ABS (1199cc)",
          "1.2 LT ABS (1199cc)",
          "LS ABS (1199cc)",
          "BASE (1199cc)",
          "LT (1199cc)",
          "LS (1199cc)",
          "PS (1199cc)",
          "1.2 PS (1199cc)",
          "1.2 BASE (1199cc)",
          "LT (1248cc)",
          "LS (1248cc)",
          "1.3 TCDI BASE (1248cc)",
          "1.3 BASE (1248cc)",
          "1.3 LS (1248cc)",
          "1.3 LS ABS (1248cc)",
          "1.3 LT ABS (1248cc)",
        ],
      },
      {
        model: "SUBURBAN",
        variants: ["GMC (4866cc)", "STD (1800cc)"],
      },
      {
        model: "TAVERA NEO 2",
        variants: [
          "LS B3 10STR (2499cc)",
          "LS B3 7STR (2499cc)",
          "LS B4 5STR (2499cc)",
          "LS B4 7STR (2499cc)",
          "LT 7STR (2499cc)",
          "LT 9STR (2499cc)",
          "SS D1 7STR (2499cc)",
          "SS D1 8STR (2499cc)",
          "LS B4 5 SEATER (2499cc)",
          "LS B4 7 SEATER (2499cc)",
          "LS B3 10 SEATER (2499cc)",
          "LS B3 7 SEATER (2499cc)",
          "SS - D1 8 SEATER (2499cc)",
          "LT L 7 SEATER (2499cc)",
          "LT L 9 SEATER (2499cc)",
          "SS D1 7 SEATER (2499cc)",
          "LS B4 7 CAPTAIN (2499cc)",
          "LT L 7 CAPTAIN (2499cc)",
          "SS 7 SEATER (2499cc)",
          "LS 10 SEATER (2499cc)",
          "LT 7 SEATER (2499cc)",
          "SS D1 7 CAPTAIN (2499cc)",
          "LT 9 SEATER (2499cc)",
          "LS 7 SEATER (2499cc)",
        ],
      },
      {
        model: "VINTAGE",
        variants: [
          "1937 (1462cc)",
          "1973 (1570cc)",
          "DIV (990cc)",
          "CORVETTE STING RAY (2000cc)",
          "CHEVROLET NATIONAL (1396cc)",
          "IMPALA (3442cc)",
          "SPECIAL ROADSTER (2800cc)",
          "VINTAGE (4700cc)",
          "VINTAGE (3197cc)",
          "VINTAGE (2673cc)",
          "VINTAGE (2970cc)",
          "VINTAGE (8200cc)",
          "VINTAGE (5700cc)",
          "VINTAGE (3859cc)",
          "VINTAGE (4500cc)",
          "VINTAGE (7400cc)",
          "VINTAGE (1598cc)",
          "VINTAGE (2500cc)",
          "VINTAGE (1501cc)",
          "VINTAGE (510cc)",
          "VINTAGE (1850cc)",
          "VINTAGE (3547cc)",
          "-1961 (796cc)",
        ],
      },
      {
        model: "RUBBY",
        variants: ["PETROL (1990cc)"],
      },
      {
        model: "OPTRA SRV 1.6",
        variants: ["OPTION PACK (1598cc)", "WITH OPTION PACK (1598cc)"],
      },
      {
        model: "SAIL HATCHBACK",
        variants: [
          "1.2 (1199cc)",
          "1.3 TCDI LS (1248cc)",
          "1.3 TCDI (1248cc)",
          "1.3 TCDI LS ABS (1248cc)",
          "1.3 TCDI LT ABS (1248cc)",
          "1.2 LS (1199cc)",
          "1.2 LS ABS (1199cc)",
          "1.2 LT ABS (1199cc)",
          "1.2 BASE (1199cc)",
          "1.3 BASE (1248cc)",
          "1.3 LS (1248cc)",
          "1.3 LS ABS (1248cc)",
          "1.3 LT ABS (1248cc)",
        ],
      },
      {
        model: "SSR",
        variants: ["STD (6000cc)", "STANDARD (6000cc)"],
      },
      {
        model: "CORVETTE",
        variants: ["C6 (6000cc)", "ZR1 (6162cc)"],
      },
      {
        model: "SILVERADO",
        variants: ["ESTATE (1498cc)"],
      },
      {
        model: "PONTIAC",
        variants: ["FIRE BIRD (1800cc)"],
      },
      {
        model: "OPTRA MAGNUM 1.6 LT",
        variants: ["1.6 LT (1598cc)"],
      },
      {
        model: "OPTRA 1.6 LT",
        variants: [
          "ROYALE (1599cc)",
          "OPTRA 1.8 LT (1599cc)",
          "ROYALE (1598cc)",
          "ROYALE (1598cc)",
        ],
      },
      {
        model: "CAMARO",
        variants: ["6.2L V8 CONVERTIBLE (6200cc)", "STANDARD (1600cc)"],
      },
      {
        model: "SAIL SEDAN",
        variants: [
          "LT (1248cc)",
          "LT LIMITED EDITION (1248cc)",
          "LS ABS (1248cc)",
          "LS (1199cc)",
        ],
      },
      {
        model: "IMPALA",
        variants: ["1956 (3442cc)", "0 (2500cc)"],
      },
      {
        model: "LACETTI",
        variants: ["SX (1598cc)", "STANDARD (2000cc)", "SX (1796cc)"],
      },
      {
        model: "OPEN TOURING",
        variants: ["STANDARD (2802cc)"],
      },
      {
        model: "SPRINT",
        variants: ["GEO (1300cc)"],
      },
      {
        model: "BLAZER",
        variants: ["0 (4200cc)"],
      },
      {
        model: "OPTRA 2.0 LT TCDI",
        variants: ["0 (1998cc)"],
      },
      {
        model: "OPTRA 2.0 LS TCDI",
        variants: ["0 (1991cc)"],
      },
      {
        model: "CHEVROLET CORVETTE",
        variants: ["0 (1598cc)"],
      },
      {
        model: "FORESTER AWD 2.0",
        variants: ["0 (1994cc)"],
      },
      {
        model: "CAPTIVA 2.2 LTZ",
        variants: ["0 (2231cc)"],
      },
      {
        model: "SAIL UVA 1.3 TCDI LT",
        variants: ["0 (1248cc)"],
      },
      {
        model: "SAIL UVA 1.2 LT",
        variants: ["0 (1199cc)"],
      },
      {
        model: "KALOS",
        variants: ["STANDARD (1150cc)"],
      },
      {
        model: "CONVERTIBLE",
        variants: ["DELUX (1600cc)"],
      },
      {
        model: "OPTRA MAGNUM 1.6 LS",
        variants: ["1.6 LS (1599cc)"],
      },
      {
        model: "CHEVROLET SAIL",
        variants: ["1.2 LT ABS (1199cc)"],
      },
      {
        model: "AVEO 2009",
        variants: [
          "1.4 LT (1399cc)",
          "1.4 LS (1399cc)",
          "1.4 LT ABS (1399cc)",
          "1.6 LT ABS (1598cc)",
        ],
      },
      {
        model: "CHEVROLET SRV",
        variants: ["1.6 (1598cc)"],
      },
      {
        model: "OPEL",
        variants: ["VECTRA (1999cc)", "FORESTER (1999cc)"],
      },
      {
        model: "SRV",
        variants: ["1.6 WITH OPTION PACK (1598cc)"],
      },
      {
        model: "CHEVROLET OPTRA SRV",
        variants: ["0 (1599cc)"],
      },
      {
        model: "OPTRA 1.6 ELITE WITH CNG",
        variants: ["0 (1598cc)"],
      },
      {
        model: "TAVERA B3 7STR",
        variants: ["0 (2499cc)"],
      },
      {
        model: "TAVERA B3 LT L NEO 9SEATER",
        variants: ["0 (2499cc)"],
      },
      {
        model: "TAVERA 7STR",
        variants: ["0 (2499cc)"],
      },
      {
        model: "TAVERA D1 ELITE 7STR",
        variants: ["0 (2499cc)"],
      },
      {
        model: "TAVERA B1 M E3 8STR",
        variants: ["0 (2499cc)"],
      },
      {
        model: "TAVERA L",
        variants: ["0 (2499cc)"],
      },
      {
        model: "TAVERA BS3-9STR",
        variants: ["0 (2499cc)"],
      },
      {
        model: "TAVERA B4 NEO 7STR",
        variants: ["0 (2499cc)"],
      },
      {
        model: "CHEVROLET BEAT 1.2 LS",
        variants: ["0 (1199cc)"],
      },
      {
        model: "OPTRA LS",
        variants: ["ELITE 1.6 (1598cc)", "NY (1599cc)", "NY (1799cc)"],
      },
      {
        model: "OPTRA LT",
        variants: [
          "AT (1799cc)",
          "NY AT (1799cc)",
          "NY (1799cc)",
          "ROYALE (1599cc)",
        ],
      },
      {
        model: "OPTRA 1.6 BASE",
        variants: ["1.6 BASE (1599cc)"],
      },
      {
        model: "OPTRA 1.8",
        variants: ["1.8 (1799cc)"],
      },
    ],
  },
  {
    make: "FIAT",
    models: [
      {
        model: "500",
        variants: [
          "ABARTH 595 COMPETIZIONE 5 STR (1368cc)",
          "ABARTH 595 (1368cc)",
          "LOUNGE MT (1248cc)",
          "SPORTS (1248cc)",
          "BASE 500 1.3 LOUNGE (1248cc)",
          "BASE 500 1.3 SPORTS (1248cc)",
          "SPORT (1248cc)",
          "LOUNGE (1248cc)",
          "NUOVA 4 SEATER (500 CC) (500cc)",
        ],
      },
      {
        model: "1100",
        variants: [
          "103 MILLECENTO (1242cc)",
          "103 MILLECEN (1089cc)",
          "1.1 PETROL SEDAN 5 SEAT (1089cc)",
          "1.1 PETROL SEDAN (1089cc)",
        ],
      },
      {
        model: "PALIO",
        variants: [
          "STILE MULTIJET 1.3 SDE (1248cc)",
          "STILE MULTIJET 1.3 SDE (1248cc)",
          "STILE 1.6 SPORTS (1596cc)",
          "1.9 EL PS (1910cc)",
          "1.9 ADVENTURE (1910cc)",
          "1.9 ELX (1910cc)",
          "1.2 EL (1242cc)",
          "1.2 EL (1242cc)",
          "1.6 GTX (1596cc)",
          "1.2 SPORT (1242cc)",
          "1.2 ELX (1242cc)",
          "1.6 SPORT (1596cc)",
          "ADVENTURE SPORT (1596cc)",
          "1.2 EL PS (1242cc)",
          "1.2 EL PS. (1242cc)",
          "STILE 1.1 SL (1108cc)",
          "STILE 1.1 SLE (1108cc)",
          "STILE 1.1 SLX (1108cc)",
          "S 10 (1596cc)",
          "ADVENTURE (1596cc)",
          "ADVENTURE PS (1596cc)",
          "1.9 EL PS (DIESEL) (1910cc)",
          "1.9 ELX (DIESEL) (1910cc)",
          "1.9 ADVENTURE (DIESEL) (1910cc)",
          "NV SPORTS 1.6 (1596cc)",
          "1.9 D EL PS (1596cc)",
          "1.6 GTX PS (1596cc)",
          "1.6 S10 (1596cc)",
          "ADVENTURE 1.6 (1596cc)",
          "1.6 WEEKEND SP (1596cc)",
          "1.6 WEEKEND (1596cc)",
          "STILE SPORT (1596cc)",
          "WEEKEND 1.6 SP (1596cc)",
          "STILE SPORT 1.6 (1596cc)",
          "STILE 1.6 SPORT (1596cc)",
          "GTX (1596cc)",
          "NV 1.6 SPORT (1596cc)",
          "1.2 ELX NV (1242cc)",
          "1.2 SPORTS NV (1242cc)",
          "1.2 NV EL (1242cc)",
          "1.2 SPORTS (1242cc)",
          "1.2 (1242cc)",
          "EL (1242cc)",
          "ELX (1242cc)",
          "1.2 ELX PS (1242cc)",
          "NV 1.2 SPORT (1242cc)",
          "NV 1.2 ELX (1242cc)",
          "NV 1.2 EL PS (1242cc)",
          "NV 1.2 EL (1242cc)",
          "1.9 D ELX (1898cc)",
          "STILE SLE (1108cc)",
          "STILE SLE (1108cc)",
          "STILE SLX (1108cc)",
          "STILE SL (1108cc)",
          "WEEKEND 1.6 (1108cc)",
          "STILE SL 1.1 (1108cc)",
          "STILE SLE 1.1 (1108cc)",
          "STILE SLX 1.1 (1108cc)",
          "1.6 GTX SP (1581cc)",
          "1.6 GTX (1581cc)",
          "EL (1910cc)",
          "ELX (1910cc)",
          "ADVENTURE 1.9 D (1910cc)",
          "ADVENTURE (1910cc)",
          "S 10 (1910cc)",
          "STILE SD (1248cc)",
          "STILE SDE (1248cc)",
          "STILE SDX (1248cc)",
          "STILE SD 1.3 (1248cc)",
          "STILE SDE 1.3 (1248cc)",
          "STILE SDX 1.3 (1248cc)",
          "STILE 1.3 SDX (1248cc)",
          "STILE 1.3 SDX (1248cc)",
          "STILE 1.3 SD (1248cc)",
          "STILE 1.3 SDE (1248cc)",
          "STILE 1.3 SD (1298cc)",
          "STILE 1.1 SL (1100cc)",
        ],
      },
      {
        model: "SIENA",
        variants: [
          "1.2 EL PS (1242cc)",
          "1.6 ELX (1596cc)",
          "1.6 ELX (1596cc)",
          "1.6 EL (1596cc)",
          "1.2 ELX (1242cc)",
          "1.2 ELX (1242cc)",
          "1.2 EX (1242cc)",
          "WEEKEND ELX (PS / SP) D (1697cc)",
          "WEEKEND ELX 1.7 SP PS (1697cc)",
          "1.7 TURBO (1697cc)",
          "WEEKEND ELX (1697cc)",
          "1.7 EL (1697cc)",
          "ELX 1.7 PS (1697cc)",
          "EL 1.7 PS. (1697cc)",
          "EL 1.7 PS (1697cc)",
          "WEEKEND ELX 1.6 (1250cc)",
          "1.2 EL (1242cc)",
          "1.2 EL (1242cc)",
          "1.2 ELX SP (1242cc)",
          "1.2 ED (1242cc)",
          "EL 1.2 PS (1242cc)",
          "ELX 1.2 (1242cc)",
          "ED 1.2 (1242cc)",
          "ELX 1.2 SP (1242cc)",
          "1.6 MAESTRO SP (1598cc)",
          "1.6 MAESTRO (1598cc)",
          "WEEKEND 1.7 ELX SP (1655cc)",
          "WEEKEND ELX (1581cc)",
          "WEEKEND ELX (PS) (1581cc)",
          "WEEKEND ELX (PS / SP) (1581cc)",
          "WEEKEND ELX 1.6 SP (1581cc)",
          "EL 1.6 (1581cc)",
          "1.7 TD (1698cc)",
          "MASTERO 1.6 SP (1596cc)",
          "ELX 1.6 (1596cc)",
          "MASTERO 1.6 (1596cc)",
          "1.6 ELX PS (1596cc)",
          "1.6 EL PS (1596cc)",
          "MAESTRO 1.6 PS SP (1596cc)",
          "MAESTRO 1.6 PS (1596cc)",
          "EX 1.2 (1248cc)",
          "1.6 MEASTRO PS (1599cc)",
        ],
      },
      {
        model: "UNO",
        variants: [
          "BASE (1698cc)",
          "1 (999cc)",
          "1.2 EL (1242cc)",
          "1.2 EL (1242cc)",
          "1.2 TREND (1242cc)",
          "1.2 TREND AC (1242cc)",
          "1.2 EL AC (1242cc)",
          "1.2 ELX AC (1242cc)",
          "1.7 TREND (1697cc)",
          "TREND 1.7 (1242cc)",
          "TREND 1.2 (1242cc)",
          "TREND 1.2 (1242cc)",
          "EL (1242cc)",
          "ELX (1242cc)",
          "AC (1242cc)",
          "UNO (1242cc)",
          "TREND 1.2 AC (1242cc)",
          "ELX 1.2 AC (1242cc)",
          "EL 1.2 (1242cc)",
          "EL 1.2 AC (1242cc)",
          "TREND 1.7 (1697cc)",
          "ELX 1.7 AC (1697cc)",
          "JUBILEE AC (1697cc)",
          "JUBLIEE AC D (1697cc)",
          "1.7 D ELX (1697cc)",
          "DLX (999cc)",
          "1.0 DLX AC (999cc)",
          "1.7 (999cc)",
          "PETROL (999cc)",
          "PETROL AC (999cc)",
          "1.7 ELX AC (1698cc)",
          "JUBILLE AC (1698cc)",
          "PS D (1698cc)",
          "DIESEL (1700cc)",
          "TREND 1.7 AC (1700cc)",
          "TREND 1.7 (1700cc)",
          "DIESEL AC (1700cc)",
          "EL 1.7 (1700cc)",
          "EL 1.7 AC (1700cc)",
          "PS(D) (903cc)",
        ],
      },
      {
        model: "GRANDE PUNTO",
        variants: [
          "1.4 ACTIVE (1368cc)",
          "PURE 1.2 (1172cc)",
          "1.2 EVO ACTIVE (1172cc)",
          "1.2 EVO DYNAMIC (1172cc)",
          "1.3 EVO ACTIVE (1248cc)",
          "1.3 EVO DYNAMIC (1248cc)",
          "1.3 EVO EMOTION (1248cc)",
          "1.3 EVO EMOTION SPORT (1248cc)",
          "1.4 EVO EMOTION (1368cc)",
          "1.4 EVO EMOTION SPORT (1368cc)",
          "1.3 EVO SPORTIVO (1248cc)",
          "PURE 1.3 (1248cc)",
          "BASIC (1172cc)",
          "1.3 ACTIVE (1248cc)",
          "1.3 ACTIVE (1248cc)",
          "1.4 EMOTION PACK (1368cc)",
          "1.4 EMOTION (1368cc)",
          "1.2 EMOTION (1172cc)",
          "1.4 DYNAMIC (1368cc)",
          "1.3 DYNAMIC (1248cc)",
          "1.3 EMOTION (1248cc)",
          "1.3 SPORT (1248cc)",
          "1.2 DYNAMIC (1172cc)",
          "1.2 ACTIVE (1172cc)",
          "1.3 EMOTION PACK (1248cc)",
          "EVO 1.2 ACTIVE (P) (1172cc)",
          "EVO 1.2 DYNAMIC (P) (1172cc)",
          "EVO 1.4 EMOTION SPORT (P) (1368cc)",
          "EVO 1.4 EMOTION (P) (1368cc)",
          "EVO 1.3 ACTIVE (D) (1248cc)",
          "EVO 1.3 DYNAMIC (D) (1248cc)",
          "EVO 1.3 EMOTION (D) (1248cc)",
          "EVO 1.3 EMOTION SPORT (D) (1248cc)",
          "90 HP - 1.3 EMOTION PACK () (1248cc)",
          "1.2 DYNAMIC (PETROL) (1172cc)",
          "1.2 EMOTION (PETROL) (1172cc)",
          "1.2 ACTIVE (PETROL) (1172cc)",
          "1.4 DYNAMIC (PETROL) (1368cc)",
          "1.4 EMOTION (PETROL) (1368cc)",
          "1.4 EMOTION PACK (PETROL) (1368cc)",
          "90 HP - 1.3 SPORT (DIESEL) (1248cc)",
          "1.3 ACTIVE (DIESEL) (1248cc)",
          "1.3 DYNAMIC (DIESEL) (1248cc)",
          "1.3 EMOTION (DIESEL) (1248cc)",
          "1.3 EMOTION PACK (DIESEL) (1248cc)",
          "1.2 (1172cc)",
          "EMOTION (1172cc)",
          "ACTIVE (1172cc)",
          "EVO ACTIVE (1172cc)",
          "FIRE EMOTION (1172cc)",
          "PURE 1.2 FIRE (1172cc)",
          "EVO DYNAMIC (1172cc)",
          "PURE 1.2 PETROL (1172cc)",
          "EVO 1.2 ACTIVE PETROL (1172cc)",
          "EVO 1.2 DYNAMIC PETROL (1172cc)",
          "1.2L FIRE ACTIVE BS-IV (1172cc)",
          "EVO ACTIVE 1.2 (1172cc)",
          "EVO DYNAMIC 1.2 (1172cc)",
          "PURE 1.2 FIRE PETROL (1172cc)",
          "1.3 (1248cc)",
          "EMOTION (1248cc)",
          "EMOTION (1248cc)",
          "ACTIVE (1248cc)",
          "EVO EMOTION (1248cc)",
          "EMOTION PACK (1248cc)",
          "EVO ACTIVE (1248cc)",
          "SPORT (1248cc)",
          "EVO DYNAMIC (1248cc)",
          "DYNAMIC (1248cc)",
          "1.3 EVO SPORTIVO DIESEL (1248cc)",
          "PURE 1.3 DIESEL (1248cc)",
          "EVO 1.3 ACTIVE DIESEL (1248cc)",
          "EVO 1.3 DYNAMIC DIESEL (1248cc)",
          "EVO 1.3 EMOTION DIESEL (1248cc)",
          "EVO 1.3 EMOTION SPORT 93 PS DIESEL (1248cc)",
          "90 HP - 1.3 EMOTION PACK (DIESEL) (1248cc)",
          "DYNAMIC 1.3 (1248cc)",
          "1.3 MULTIJET DYNAMIC (1248cc)",
          "1.2L FIRE ACTIVE BS-IV (1248cc)",
          "EVO ACTIVE MULTIJET 1.3 (1248cc)",
          "EVO DYNAMIC MULTIJET 1.3 (1248cc)",
          "EVO MULTIJET 1.3 90 HP (1248cc)",
          "EVO EMOTION MULTIJET 1.3 (1248cc)",
          "1.3 EMOTION PACK 90HP (1248cc)",
          "EMOTION MULTIJET 1.3 (1248cc)",
          "DYNAMIC MULTIJET 1.3 (1248cc)",
          "MULTIJET (1248cc)",
          "ACTIVE MULTIJET 1.3 (1248cc)",
          "MULTIJET 1.3 90 HP (1248cc)",
          "PURE 1.3 MULTIJET DIESEL (1248cc)",
          "EVO SPORT 1.3 MULTIJET 93 PS (1248cc)",
          "1.4 (1368cc)",
          "EMOTION (1368cc)",
          "EVO EMOTION (1368cc)",
          "EMOTION PACK (1368cc)",
          "EVO EMOTION SPORT (1368cc)",
          "1.4 EVO T JET ABARTH (1368cc)",
          "1.4 ACTIVE PETROL (1368cc)",
          "ABARTH 1.4 T JET PETROL (1368cc)",
          "EVO 1.4 EMOTION PETROL (1368cc)",
          "EVO 1.4 EMOTION SPORT 93 PS PETROL (1368cc)",
          "1.2L FIRE ACTIVE BS-IV (1368cc)",
          "T-JET 1.4 ABARTH (1368cc)",
          "EVO EMOTION 1.4 (1368cc)",
          "ABARTH (1368cc)",
          "EMOTION 1.4 (1368cc)",
        ],
      },
      {
        model: "LINEA",
        variants: [
          "EMOTION (1668cc)",
          "EMOTION (1368cc)",
          "EMOTION (1248cc)",
          "EMOTION PACK (1248cc)",
          "EMOTION PACK (1368cc)",
          "DYNAMIC PACK (1368cc)",
          "DYNAMIC PACK (1248cc)",
          "DYNAMIC (1368cc)",
          "DYNAMIC (1248cc)",
          "ACTIVE (1368cc)",
          "ACTIVE (1248cc)",
          "1.4 T-JET ACTIVE (1368cc)",
          "1.4 T-JET DYNAMIC (1368cc)",
          "1.4 T-JET EMOTION (1368cc)",
          "1.3 ACTIVE POWER UP (1248cc)",
          "1.3 DYNAMIC POWER UP (1248cc)",
          "1.3 EMOTION POWER UP (1248cc)",
          "1.4 FIRE ACTIVE POWER UP (1368cc)",
          "1.4 T JET EMOTION POWER UP (1368cc)",
          "T-JET PLUS (1368cc)",
          "T-JET (1368cc)",
          "ACTIVE (PETROL) (1368cc)",
          "DYNAMIC (PETROL) (1368cc)",
          "DYNAMIC PACK (PETROL) (1368cc)",
          "EMOTION (PETROL) (1368cc)",
          "EMOTION PACK (PETROL) (1368cc)",
          "1.4L T-JET ACTIVE PETROL (1368cc)",
          "1.4L T-JET DYNAMIC PETROL (1368cc)",
          "1.4L T-JET EMOTION PETROL (1368cc)",
          "ACTIVE (DIESEL) (1248cc)",
          "DYNAMIC (DIESEL) (1248cc)",
          "DYNAMIC PACK (DIESEL) (1248cc)",
          "EMOTION (DIESEL) (1248cc)",
          "EMOTION PACK (DIESEL) (1248cc)",
          "1.3 EMOTION (1248cc)",
          "1.3 (1248cc)",
          "1.3L MULTIJET ELEGANTE LIMITED EDITION (1248cc)",
          "1.3 CLASSIC PLUS (1248cc)",
          "CLASSIC PLUS ALLOY MULTIJET (1248cc)",
          "CLASSIC MULTIJET (1248cc)",
          "CLASSIC MULTIJET PLUS (1248cc)",
          "ACTIVE ADVANCED MULTIJET DIESEL (1248cc)",
          "DYNAMIC ADVANCED MULTIJET DIESEL (1248cc)",
          "EMOTION ADVANCED MULTIJET DIESEL (1248cc)",
          "1.2 ELEGANTE ADVANCED MULTIJET DIESEL (1248cc)",
          "1.3 CLASSIC PLUS MULTIJET DIESEL (1248cc)",
          "1.3 CLASSIC MULTIJET DIESEL (1248cc)",
          "1.3 CLASSIC MULTIJET DIESEL (1248cc)",
          "EMOTION 1.3 (1248cc)",
          "ACTIVE 1.3 (1248cc)",
          "DYNAMIC MULTIJET 1.3 (1248cc)",
          "EMOTION MULTIJET 1.3 (1248cc)",
          "ACTIVE MULTIJET 1.3 (1248cc)",
          "DYNAMIC 1.3 L ADVANCED MULTIJET (1248cc)",
          "EMOTION 1.3 L ADVANCED MULTIJET (1248cc)",
          "ELEGANTE DYNAMIC 1.3 (1248cc)",
          "1.3 CLASSIC L MULTIJET (1248cc)",
          "1.3 CLASSIC L MULTIJET PLUS (1248cc)",
          "1.3 CLASSIC MULTIJET (1248cc)",
          "1.3 CLASSIC PLUS MULTIJET (1248cc)",
          "1.3 CLASSIC MULTIJET PLUS WITH ALLOY (1248cc)",
          "ACTIVE 1.3 MJD (1248cc)",
          "DYNAMIC 1.3 MJD (1248cc)",
          "EMOTION 1.3 MJD (1248cc)",
          "1.3 D (1248cc)",
          "1.3L MULTIJET CLASSIC PLUS (1248cc)",
          "1.3L MULTIJET CLASSIC (1248cc)",
          "DYNAMIC PK 1.3 MJD (1248cc)",
          "EMOTION PK 1.3 MJD (1248cc)",
          "DYNAMIC 1.3 (1248cc)",
          "1.4 (1368cc)",
          "FIRE ACTIVE (1368cc)",
          "T-JET DYNAMIC (1368cc)",
          "T-JET ACTIVE (1368cc)",
          "T-JET EMOTION (1368cc)",
          "1.4 CLASSIC (1368cc)",
          "1.4 CLASSIC (1368cc)",
          "1.4 FIRE ACTIVE PETROL (1368cc)",
          "1.4 CLASSIC PETROL (1368cc)",
          "1.4 CLASSIC PETROL (1368cc)",
          "EMOTION T-JET (1368cc)",
          "ACTIVE T-JET (1368cc)",
          "DYNAMIC T-JET (1368cc)",
          "1.4 L P CLASSIC (1368cc)",
          "1.4 L P CLASSIC (1368cc)",
          "DYNAMIC 1.4 L T JET (1368cc)",
          "EMOTION T-JET 1.4 (1368cc)",
          "1.4L T-JET EMOTION PREMIUM (1368cc)",
          "1.4L T-JET DYNAMIC (1368cc)",
          "1.4L CLASSIC PETROL (1368cc)",
          "EMOTION PK 1.4 (1368cc)",
          "EMOTION 1.4 (1368cc)",
          "DYNAMIC PK 1.4 (1368cc)",
          "ACTIVE 1.4 (1368cc)",
          "DYNAMIC 1.4 (1368cc)",
          "MILTIJET (EMOTION) (1370cc)",
        ],
      },
      {
        model: "PETRA",
        variants: [
          "1.9 EL (1910cc)",
          "1.9 ELX (1910cc)",
          "1.6 ELX (1592cc)",
          "1.6 EL (1592cc)",
          "1.2 EL (1242cc)",
          "1.2 ELX PS (1242cc)",
          "1.6 EL PS (1596cc)",
          "1.6 EL PS (1596cc)",
          "1.6 ELX (1242cc)",
          "1.9 EL PS (1910cc)",
          "1.9 ELX PS (1910cc)",
          "EL (1910cc)",
          "1.9 (1910cc)",
          "ELX (1910cc)",
          "D 1.9 ELX PS (1910cc)",
          "EL (1592cc)",
          "ELX 1.2 PS (1592cc)",
          "EL 1.2 (1592cc)",
          "1.2 EL (1592cc)",
          "1.2 ELX PS (1592cc)",
          "EL 1.6 PS (1596cc)",
          "ELX 1.6 PS (1596cc)",
          "1.6 ELX (1596cc)",
          "1.6 ELX PS (1596cc)",
          "1.2 ELX (1242cc)",
          "1.2 ELX (1198cc)",
        ],
      },
      {
        model: "AVVENTURA",
        variants: [
          "1.4 ACTIVE (1368cc)",
          "1.4 DYNAMIC (1368cc)",
          "1.3 ACTIVE MULTIJET (1248cc)",
          "1.3 DYNAMIC MULTIJET (1248cc)",
          "1.3 EMOTION MULTIJET (1248cc)",
          "1.3 EMOTION POWER UP (1248cc)",
          "1.3 ACTIVE POWER UP (1248cc)",
          "1.3 DYNAMIC POWER UP (1248cc)",
          "URBAN CROSS 1.3 MULTIJET DYNAMIC (1248cc)",
          "URBAN CROSS 1.3 MULTIJET ACTIVE (1248cc)",
          "URBAN CROSS 1.3 MULTIJET EMOTION (1248cc)",
          "URBAN CROSS 1.4 T JET EMOTION (1396cc)",
          "ACTIVE 1.4 (1368cc)",
          "DYNAMIC 1.4 (1368cc)",
          "ACTIVE MULTIJET 1.3 (1248cc)",
          "DYNAMIC MULTIJET 1.3 (1248cc)",
          "EMOTION MULTIJET 1.3 (1248cc)",
          "ACTIVE FIRE (1368cc)",
          "DYNAMIC FIRE (1368cc)",
          "1.4 T JET PETROL EMOTION (1368cc)",
          "1.4 ACTIVE FIRE PETROL (1368cc)",
          "1.4 DYNAMIC FIRE PETROL (1368cc)",
          "1.4 T JET PETROL ABARTH (1368cc)",
          "T-JET 1.4 (1368cc)",
          "URBAN CROSS 1.4 T- JET EMOTION (1368cc)",
          "1.4 FIRE ACTIVE (1368cc)",
          "1.4 T JET (1368cc)",
          "1.4 FIRE DYNAMIC (1368cc)",
          "ABARTH (1368cc)",
          "ACTIVE (1248cc)",
          "DYNAMIC (1248cc)",
          "EMOTION (1248cc)",
          "URBAN CROSS 1.3 T- MULTIJET EMOTION (1248cc)",
          "1.3 ACTIVE MULTIJET DIESEL (1248cc)",
          "1.3 DYNAMIC MULTIJET  DIESEL (1248cc)",
          "1.3 EMOTION MULTIJET DIESEL (1248cc)",
          "1.3 MULTIJET ACTIVE (1248cc)",
          "1.3 MULTIJET EMOTION (1248cc)",
          "1.3 MULTIJET DYNAMIC (1248cc)",
        ],
      },
      {
        model: "ABARTH PUNTO",
        variants: ["EVO 1.4 T-JET (1368cc)", "T-JET 1.4 ABARTH (1368cc)"],
      },
      {
        model: "MAREA",
        variants: ["100 SX (1596cc)", "1.6 (1596cc)"],
      },
      {
        model: "BRAVO",
        variants: [
          "1.9 JTD SPORT 16 V (1910cc)",
          "SPORT 1.9 JTD 16 V (1910cc)",
          "SPORT (1910cc)",
          "0 (1910cc)",
        ],
      },
      {
        model: "500 LOUNGE",
        variants: ["STD (1248cc)", "0 (1248cc)"],
      },
      {
        model: "500 SPORTS",
        variants: ["STD (1248cc)", "0 (1248cc)"],
      },
      {
        model: "SIENA WEEKENDELX",
        variants: ["STD (1581cc)", "STD (1697cc)"],
      },
      {
        model: "PALIO STILE MULTIJET",
        variants: ["1.3 SD (1248cc)", "1.3 SDX (1248cc)"],
      },
      {
        model: "VINTAGE",
        variants: [
          "VINTAGE CAR (1500cc)",
          "VINTAGE CAR (1697cc)",
          "CABRIOLET (500cc)",
          "VINTAGE (1608cc)",
          "VINTAGE (1089cc)",
          "VINTAGE (1242cc)",
          "VINTAGE (1889cc)",
          "VINTAGE (1200cc)",
          "VINTAGE (1098cc)",
          "VINTAGE (1108cc)",
          "VINTAGE (1150cc)",
          "VINTAGE (1125cc)",
          "VINTAGE (1069cc)",
          "VINTAGE (1100cc)",
          "VINTAGE (800cc)",
          "VINTAGE (1489cc)",
          "VINTAGE (1380cc)",
          "1100 (1089cc)",
          "TIPO (1465cc)",
          "ELEGANT (1089cc)",
          "131 SUPER MANIA (999cc)",
          "TOPOLINO (569cc)",
          "TOPOLINO (498cc)",
          "514 TIPO (1248cc)",
          "1919 TIPO (2296cc)",
          "MILLICENTO (1498cc)",
          "EURO 2 (1408cc)",
          "LINEA 1.4 DYANAMIC (1368cc)",
          "PREMIER PADMINI (1150cc)",
          "PREMIER PADMINI (1100cc)",
          "SEDAN (1150cc)",
          "STATION WAGON (500cc)",
          "3V SEDAN (500cc)",
          "ALFA ROMEO (2600cc)",
        ],
      },
      {
        model: "PUNTO",
        variants: [
          "1.2 ACTIVE (1172cc)",
          "1.4 DYNAMIC (1368cc)",
          "1.2 EMOTION (1172cc)",
          "1.4 EMOTION PACK (1368cc)",
          "1.3 SPORT DIESEL (1248cc)",
          "1.3 ACTIVE (1248cc)",
          "1.2 DYNAMIC (1172cc)",
          "1.3 DYNAMIC (1248cc)",
          "1.3 EMOTION (1248cc)",
          "1.4 EMOTION (1368cc)",
          "1.3 SPORT PETROL (1248cc)",
          "1.3 EMOTION PACK (1248cc)",
          "EMOTION 90HP (1248cc)",
          "EMOTION (1248cc)",
          "EVO ACTIVE (1248cc)",
          "ACTIVE (1248cc)",
          "ADVENTURE (1248cc)",
          "PURE (1248cc)",
          "1.3 PURE (1248cc)",
          "SPORT 1.3 PETROL (1248cc)",
          "SPORT 90HP (1248cc)",
          "ACTIVE 1.3 (1248cc)",
          "EMOTION 1.3 (1248cc)",
          "SPORT 1.3 DIESEL (1248cc)",
          "DYNAMIC 1.3 (1248cc)",
          "EMOTION PACK 1.3 (1248cc)",
          "1.3L PURE ADVANCED MULTI-JET (1248cc)",
          "EMOTION (1368cc)",
          "AVVENTURA ABARTH (1368cc)",
          "ABARTH EVO 1.4 T-JET (1368cc)",
          "EMOTION PACK 1.4 (1368cc)",
          "DYNAMIC 1.4 (1368cc)",
          "EMOTION 1.4 (1368cc)",
          "1.2 PURE (1172cc)",
          "FIRE (1172cc)",
          "ACTIVE 1.2 (1172cc)",
          "DYNAMIC 1.2 (1172cc)",
          "EMOTION 1.2 (1172cc)",
          "1.2L PURE FIRE (1172cc)",
          "1.2 PURE ACTIVE (1172cc)",
        ],
      },
      {
        model: "PUNTO EVO",
        variants: [
          "1.2 DYNAMIC (1172cc)",
          "1.2 ACTIVE (1172cc)",
          "1.3 EMOTION (1248cc)",
          "1.3 ACTIVE (1248cc)",
          "1.3 DYNAMIC (1248cc)",
          "PURE 1.2 FIRE (1172cc)",
          "PURE FIRE (1172cc)",
          "1.2 PURE (1172cc)",
          "ACTIVE 1.2 (1172cc)",
          "DYNAMIC 1.2 (1172cc)",
          "90 HP (1245cc)",
          "90HP SPORT (1248cc)",
          "DYNAMIC (1248cc)",
          "EMOTION (1248cc)",
          "ACTIVE MULTIJET 1.3 90 HP (1248cc)",
          "DYNAMIC MULTIJET 1.3 90 HP (1248cc)",
          "EMOTION MULTIJET 1.3 90 HP (1248cc)",
          "1.3 PURE (1248cc)",
          "SPORTIVO 1.3 (1248cc)",
          "MULTIJET 1.3 90 HP (1248cc)",
          "EMOTION 1.4 (1368cc)",
        ],
      },
      {
        model: "ABARTH AVVENTURA",
        variants: ["1.4 T JET (1368cc)"],
      },
      {
        model: "ALFA ROMEO",
        variants: [
          "STD (3000cc)",
          "2.5 PETROL 156 2 52 Q (2492cc)",
          "STANDARD (3000cc)",
        ],
      },
      {
        model: "CINQUECENTO",
        variants: ["LOUNGE MJTD (1248cc)", "0 (1248cc)"],
      },
      {
        model: "SIENA WEEKENDELX (PS / SP)",
        variants: ["0 (1581cc)"],
      },
      {
        model: "SIENA WEEKENDELX (PS / SP) D",
        variants: ["0 (1697cc)"],
      },
      {
        model: "SIENA WEEKENDELX (PS)",
        variants: ["0 (1581cc)"],
      },
      {
        model: "PETRA DIESEL 1.9 EL",
        variants: ["0 (1910cc)"],
      },
      {
        model: "PETRA DIESEL 1.9 ELX",
        variants: ["0 (1910cc)"],
      },
      {
        model: "ADIRA MOBILE",
        variants: ["680 CARAVAN (2287cc)"],
      },
      {
        model: "URBAN CROSS",
        variants: [
          "EMOTION T-JET 1.4 (1368cc)",
          "ACTIVE MULTIJET 1.3 (1248cc)",
          "DYNAMIC MULTIJET 1.3 (1248cc)",
          "EMOTION MULTIJET 1.3 (1248cc)",
          "1.4 EMOTION T-JET (1368cc)",
          "1.4 T JET PETROL (1368cc)",
          "1.3 ACTIVE MULTIJET (1248cc)",
          "1.3 DYNAMIC MULTIJET (1248cc)",
          "1.3 EMOTION MULTIJET (1248cc)",
          "1.3 EMOTION DIESEL (1248cc)",
          "1.3  DYNAMIC DIESEL (1248cc)",
          "1.3 ACTIVE DIESEL (1248cc)",
        ],
      },
      {
        model: "FIAT 500",
        variants: [
          "ABARTH 595 COMPETIZIONE (1248cc)",
          "ABARTH 595 COMPETIZIONE (1368cc)",
        ],
      },
      {
        model: "PETRA D",
        variants: [
          "ELX 1.9 PS (1910cc)",
          "EL 1.9 PS (1910cc)",
          "1.9 EL PS (1910cc)",
        ],
      },
      {
        model: "PALIO D",
        variants: [
          "EL 1.9 PS (1910cc)",
          "ELX 1.9 (1910cc)",
          "ELX 1.9 SP (1910cc)",
          "1.9 EL PS (1910cc)",
          "1.9 ELX (1910cc)",
        ],
      },
      {
        model: "ADVENTURE",
        variants: [
          "SPORT (1596cc)",
          "SPORT 1.6 (1596cc)",
          "SPORT 1.6 (1596cc)",
          "1.9 D (1910cc)",
        ],
      },
      {
        model: "PREMIER",
        variants: [
          "136D (1366cc)",
          "137 D (1366cc)",
          "138 D (1366cc)",
          "PADMINI (1089cc)",
          "118 NE (1089cc)",
          "118 NE (1171cc)",
          "118NE (1180cc)",
        ],
      },
      {
        model: "DUCATO CARAVAN",
        variants: ["BASE (1984cc)"],
      },
      {
        model: "CV 57L",
        variants: ["CARAVAN (2198cc)"],
      },
      {
        model: "CITY",
        variants: ["VAN (2198cc)"],
      },
      {
        model: "PILOTE AVENTURA",
        variants: ["P 730 LCA (3000cc)"],
      },
      {
        model: "FIAT 600 D",
        variants: ["STD (767cc)"],
      },
      {
        model: "PETRA DIESEL",
        variants: ["1.9 EL (1910cc)", "1.9 ELX (1910cc)"],
      },
      {
        model: "CHEROKEE",
        variants: ["JEEP GRAND OVERLAND (3599cc)", "JEEP GRAND OVER (6400cc)"],
      },
      {
        model: "RENEGADE",
        variants: ["JEEP 4X4 (2000cc)", "JEEP 4X2 (2000cc)"],
      },
      {
        model: "FIAT PALIO 1.1 STILE SLX",
        variants: ["0 (1108cc)"],
      },
      {
        model: "FIAT 500 SPORTS",
        variants: ["0 (1248cc)"],
      },
      {
        model: "DUCATO",
        variants: ["0 (2999cc)"],
      },
      {
        model: "PEUGEOT.",
        variants: ["0 (1360cc)"],
      },
      {
        model: "PEUGEOT",
        variants: ["0 (2000cc)"],
      },
      {
        model: "FIAT PALIO 1.6 STILE SPORT",
        variants: ["0 (1596cc)"],
      },
      {
        model: "ABARTH 595",
        variants: ["COMPETIZIONE (1368cc)", "COMPETIZIONE (1368cc)"],
      },
      {
        model: "FIAT 1100",
        variants: ["SEDAN (1100cc)", "PETROL (1100cc)"],
      },
      {
        model: "SUPER",
        variants: ["SELECT (1089cc)"],
      },
      {
        model: "PAL PEUGEOT",
        variants: ["0 (1360cc)"],
      },
    ],
  },
  {
    make: "FORD",
    models: [
      {
        model: "FIESTA",
        variants: [
          "1.6 DURATEC EXI (1596cc)",
          "1.6 DURATEC S (1596cc)",
          "1.6 DURATEC S (1596cc)",
          "1.5 AMBIENTE (1499cc)",
          "1.6 DURATEC ZXI (1596cc)",
          "1.4 DURATORQ EXI (1399cc)",
          "1.4 DURATORQ ZXI (1399cc)",
          "1.4 EXI LTD EDITION (1388cc)",
          "1.4 EXI TDCI LTD EDITION (1399cc)",
          "1.4 ZXI LTD EDITION (1388cc)",
          "1.4 ZXI TDCI LTD EDITION (1399cc)",
          "1.6 DURATEC SXI (1596cc)",
          "1.4 TDCI CLASSIC CLXI (1399cc)",
          "1.4 TDCI CLASSIC LXI (1399cc)",
          "1.4 TDCI CLASSIC SXI (1399cc)",
          "1.4 TDCI CLASSIC TITANIUM (1399cc)",
          "1.6 CLASSIC DURATEC CLXI (1596cc)",
          "1.6 CLASSIC DURATEC LXI (1596cc)",
          "1.6 CLASSIC DURATEC SXI (1596cc)",
          "1.6 CLASSIC DURATEC TITANIUM (1596cc)",
          "1.6 CLASSIC DURATEC TITANIUM (1596cc)",
          "1.6 CLASSIC  DURATEC TITANIUM (1596cc)",
          "1.5 STYLE (1498cc)",
          "1.5 STYLE (1499cc)",
          "1.5 STYLE (AT) (1499cc)",
          "1.5 TITANIUM (1498cc)",
          "1.5 TITANIUM (1499cc)",
          "1.5 TITANIUM PLUS (1498cc)",
          "1.5 TITANIUM PLUS (1499cc)",
          "1.5 TITANIUM PLUS (AT) (1499cc)",
          "1.5 TREND (1498cc)",
          "1.5 TREND (1499cc)",
          "1.4 CLASSIC BS IV (1398cc)",
          "PLATINUM (1398cc)",
          "1.4 TD SXI ABS (1399cc)",
          "1.6 TITANIUM (1596cc)",
          "1.6 ZXI DUROSPORT (1596cc)",
          "1.6 ZXI DUROSPORT ABS (1596cc)",
          "1.6 EXI (SVP) LTD EDITION (1596cc)",
          "1.4 DURATORQ EXI SVP (1399cc)",
          "1.4 EXI TDCI LTD ABS (1399cc)",
          "1.6 SXI (1596cc)",
          "1.6 SXI (1596cc)",
          "1.4 DURATORQ SXI (1399cc)",
          "1.4 EXI (1400cc)",
          "1.4 EXI (1388cc)",
          "1.4 ZXI (1388cc)",
          "1.6 ZXI (1596cc)",
          "1.4 EXI DURASPORT (1388cc)",
          "1.4 ZXI TDCI ABS (1399cc)",
          "1.4 ZXI TDCI (1399cc)",
          "1.4 SXI TDCI (1399cc)",
          "1.5 TREND DIESEL (1498cc)",
          "1.5 TREND DIESEL (1498cc)",
          "1.5 STYLE DIESEL (1498cc)",
          "1.5 STYLE DIESEL (1498cc)",
          "1.5 TITANIUM DIESEL (1498cc)",
          "1.5 TITANIUM DIESEL (1498cc)",
          "1.5 STYLE PETROL (1499cc)",
          "1.5 STYLE PETROL (1499cc)",
          "1.5 TITANIUM PETROL (1499cc)",
          "1.5 TITANIUM PETROL (1499cc)",
          "1.5 TREND PETROL (1499cc)",
          "1.5 TREND PETROL (1499cc)",
          "1.4 ZXI LTD EDN (1388cc)",
          "1.6 DURATEC EXI (PETROL) (1596cc)",
          "1.6 DURATEC ZXI (PETROL) (1596cc)",
          "1.6 DURATEC SXI (PETROL) (1596cc)",
          "1.6 DURATEC S (PETROL) (1596cc)",
          "1.4 EXI LTD EDN (1388cc)",
          "CLASSIC 1.6 DURATEC LXI (1596cc)",
          "CLASSIC 1.6 DURATEC CLXI (1596cc)",
          "CLASSIC 1.6 DURATEC SXI (1596cc)",
          "CLASSIC 1.4 TDCI LXI (1399cc)",
          "CLASSIC 1.4 TDCI SXI (1399cc)",
          "1.5 TITANIUM PLUS PETROL AT (1499cc)",
          "1.5 TITANIUM PLUS PETROL (1499cc)",
          "1.4 ZXI TDCI LTD EDN (1399cc)",
          "1.4 DURATORQ EXI (DIESEL) (1399cc)",
          "1.4 DURATORQ ZXI (DIESEL) (1399cc)",
          "1.4 DURATORQ SXI (DIESEL) (1399cc)",
          "1.4 EXI TDCI LTD EDN (1399cc)",
          "CLASSIC 1.4 TDCI CLXI (1399cc)",
          "1.6 CLASSIC LXI (1596cc)",
          "1.6 CLASSIC CLXI (1596cc)",
          "1.6 TITANIUM PETROL (1596cc)",
          "1.6 DURA ZXI (1596cc)",
          "CLASSIC DURATEC (1596cc)",
          "CLASSIC 1.6 CLXI (1596cc)",
          "CLASSIC 1.6 LXI (1596cc)",
          "CLASSIC 1.6 SXI (1596cc)",
          "CLASSIC 1.6 S (1596cc)",
          "CLASSIC 1.6 LE (1596cc)",
          "CLASSIC 1.6 ZXI (1596cc)",
          "CLASSIC 1.6 SPECIAL EDITION (1596cc)",
          "CLASSIC 1.6 FIDA EDITION (1596cc)",
          "CLASSIC 1.6 S SPECIAL EDITION (1596cc)",
          "CLASSIC 1.6 ZXI DURASPORT (1596cc)",
          "SXI CLASSIC DURATEC (1596cc)",
          "1.6 TITANIUM DIESEL (1596cc)",
          "EXI 1.6 LTD (1596cc)",
          "1.6 CLASSIC TITANIUM (1596cc)",
          "1.6 CLASSIC TITANIUM (1596cc)",
          "SXI 1.6 CLASSIC (1596cc)",
          "ZXI 1.6 (1596cc)",
          "ZXI 1.6 ABS (1596cc)",
          "EXI 1.6 (1596cc)",
          "S 1.6 (1596cc)",
          "SXI 1.6 (1596cc)",
          "ZXI 1.6 DURASPORT (1596cc)",
          "EXI 1.6 DURATEC LTD (1596cc)",
          "LE 1.6 (1596cc)",
          "SXI 1.6 ABS (1596cc)",
          "ZXI 1.6 DURASPORT ABS (1596cc)",
          "ZXI 1.6 LEATHER (1596cc)",
          "CLXI 1.6 CLASSIC (1596cc)",
          "LXI 1.6 CLASSIC (1596cc)",
          "1.6 SPORTS (1596cc)",
          "1.6 LTD EDITION (1596cc)",
          "1.6 EXI (1596cc)",
          "1.6 EXI (1596cc)",
          "1.6 SXI ABS (1596cc)",
          "1.6 ZXI ABS (1596cc)",
          "1.6 ZXI DURASPORT (1596cc)",
          "1.6 ZXI DURASPORT ABS (1596cc)",
          "1.6 LE (1596cc)",
          "1.6 S (1596cc)",
          "1.6 DURATEC LIMITED EDITION (1596cc)",
          "1.6 CLASSIC DURATEC ZXI ABS (1596cc)",
          "1.6 CLASSIC DURATEC PETROL LXI (1596cc)",
          "1.6 CLASSIC DURATEC PETROL CLXI (1596cc)",
          "1.6 CLASSIC DURATEC PETROL SXI (1596cc)",
          "1.4 LTD EDITION (1399cc)",
          "1.4 TDCI ZXI (1399cc)",
          "1.4 EXI DURA TORQ (1399cc)",
          "1.4 CLASSIC CLXI (1399cc)",
          "1.4 SXI (1399cc)",
          "1.4 TITANIUM DIESEL (1399cc)",
          "EXI (1399cc)",
          "ZXI (1399cc)",
          "CLASSIC 1.4 DURATORQ LIMITED EDITION (1399cc)",
          "CLASSIC CLXI (1399cc)",
          "CLASSIC LXI (1399cc)",
          "CLASSIC SXI (1399cc)",
          "CLASSIC TITANIUM (1399cc)",
          "LIMITED EDITION (1399cc)",
          "SXI (1399cc)",
          "CLASSIC 1.4 TDCI ZXI (1399cc)",
          "EXI 1.4 TDCI BS4 (1399cc)",
          "1.4 CLASSIC ZXI (1399cc)",
          "EXI 1.4 TDCI (1399cc)",
          "EXI 1.4 LTD (1399cc)",
          "SXI 1.4 TDCI (1399cc)",
          "SXI 1.4 TDCI ABS (1399cc)",
          "ZXI 1.4 TDCI (1399cc)",
          "ZXI 1.4 TDCI ABS (1399cc)",
          "ZXI 1.4 TDCI LTD (1399cc)",
          "EXI 1.4 TDCI LTD (1399cc)",
          "CLXI 1.4 CLASSIC TDCI (1399cc)",
          "SXI 1.4 CLASSIC TDCI (1399cc)",
          "LXI 1.4 TDCI CLASSIC (1399cc)",
          "1.4 CLASSIC LXI (1399cc)",
          "1.4 EXI TDCI (1399cc)",
          "1.4 EXI TDCI (1399cc)",
          "1.4 TDCI LIMITED EDITION (1399cc)",
          "1.4 SXI TDCI ABS (1399cc)",
          "1.4 CLASSIC DURATORQ DIESEL CLXI (1399cc)",
          "1.4 CLASSIC DURATORQ DIESEL LXI (1399cc)",
          "1.4 CLASSIC DURATORQ DIESEL SXI (1399cc)",
          "1.4 CLASSIC TDCI TITANIUM (1399cc)",
          "1.5 AMBIENTE PETROL (1499cc)",
          "1.5 AMBIENTE PETROL (1499cc)",
          "AMBIENTE (1499cc)",
          "TITANIUM (1499cc)",
          "STYLE (1499cc)",
          "STYLE AT (1499cc)",
          "TITANIUM PLUS (1499cc)",
          "TITANIUM PLUS AT (1499cc)",
          "TREND (1499cc)",
          "1.5 TDCI TREND (1499cc)",
          "PETROL TITANIUM (1499cc)",
          "STYLE PETROL AT (1499cc)",
          "AMBIENTE PETROL (1499cc)",
          "1.5 TIVCT TITANIUM + (1499cc)",
          "TITANIUM PETROL (1499cc)",
          "STYLE PETROL (1499cc)",
          "TITANIUM PLUS PETROL (1499cc)",
          "TREND PETROL (1499cc)",
          "1.4 NEW (1388cc)",
          "1.4 ZXI PETROL (1388cc)",
          "1.4 DURA EXI (1388cc)",
          "EXI (1388cc)",
          "EXI DURASPORT (1388cc)",
          "ZXI (1388cc)",
          "EXI 1.4 (1388cc)",
          "EXI 1.4 DURASPORT (1388cc)",
          "ZXI 1.4 LTD (1388cc)",
          "AMBIENTE (1498cc)",
          "TITANIUM (1498cc)",
          "STYLE (1498cc)",
          "TITANIUM PLUS (1498cc)",
          "TREND (1498cc)",
          "DIESEL TREND (1498cc)",
          "1.5 TDCI TITANIUM (1498cc)",
          "1.5 PET TITANIUM (1498cc)",
          "TITANIUM PLUS PETROL AT (1498cc)",
          "TREND DIESEL (1498cc)",
          "AMBIENTE DIESEL (1498cc)",
          "STYLE DIESEL (1498cc)",
          "TITANIUM DIESEL (1498cc)",
          "TITANIUM PLUS DIESEL (1498cc)",
          "1.6 ZXI (1600 CC) (1600cc)",
          "1.6 DURATEC EXI (1598cc)",
          "1.4 (1400cc)",
        ],
      },
      {
        model: "MUSTANG",
        variants: [
          "V8 (4951cc)",
          "MUSTANG (2199cc)",
          "MACH 1 (4942cc)",
          "GT FASTBACK 5.0 V8 (4942cc)",
          "STANDARD (3277cc)",
          "GT FASTBACK 5.0L V8 (4951cc)",
          "GT FASTBACK (4951cc)",
        ],
      },
      {
        model: "JEEP",
        variants: [
          "2.2 PETROL (2200cc)",
          "2.5L (2523cc)",
          "CJ 340 (2523cc)",
          "CJ 340 DP (2523cc)",
          "CJ 500 D (2523cc)",
          "CJ 500 DI (2523cc)",
          "CL 550 MDI (2523cc)",
          "COMMANDER 650 DI (2523cc)",
          "MM 540 (2523cc)",
          "MM 540 DP (2523cc)",
          "MM 540 XDB (2523cc)",
          "MM 550 DP (2523cc)",
          "MM 550 PE (2523cc)",
          "MM 550 XDB (2523cc)",
          "MM 775 XDB (2523cc)",
          "MM ISZ (2523cc)",
          "4STR (2523cc)",
          "CJ-3B (2112cc)",
          "COMMANDER 750 DI (2112cc)",
          "COMMANDER 750 ST (2112cc)",
          "2.2 (1560cc)",
          "2.2 (2200cc)",
        ],
      },
      {
        model: "IKON",
        variants: [
          "1.3 (1299cc)",
          "1.3 ZXI (1299cc)",
          "1.3 LXI (1299cc)",
          "1.3 LXI (1299cc)",
          "1.3 FLAIR NXT (1299cc)",
          "1.6 ZXI ROCAM (1597cc)",
          "1.3 FLAIR JOSH 100 (1299cc)",
          "1.6 ZXI NXT (1597cc)",
          "1.8 EXI NXT (1753cc)",
          "1.8 ZXI NXT (1753cc)",
          "1.4 DURATORQ TDCI (1399cc)",
          "1.4 DURATORQ TDCI (1399cc)",
          "1.8 EXI (1753cc)",
          "1.8 EXI (1753cc)",
          "1.3 ROCAM (1297cc)",
          "1.3 ROCAM (1297cc)",
          "1.3 FLAIR (1299cc)",
          "1.3 FLAIR (1299cc)",
          "1.3 EXI (1299cc)",
          "1.3 EXI (1299cc)",
          "1.6 CLXI (1597cc)",
          "1.6 CLXI (1597cc)",
          "1.3 CLXI (1299cc)",
          "1.3 CLXI (1299cc)",
          "1.6 EXI (1597cc)",
          "1.6 EXI (1597cc)",
          "1.6 SXI (1597cc)",
          "1.6 SXI (1597cc)",
          "1.6 ZXI (1597cc)",
          "1.6 ZXI (1597cc)",
          "1.3 IRIS (1299cc)",
          "1.8 SXI (1753cc)",
          "1.8 ZXI (1753cc)",
          "1.4 DURATORQ TDCI (DIESEL) (1399cc)",
          "1.3 CNG (1299cc)",
          "1.6 ZXI CNG (1597cc)",
          "1.8 SXI (DIESEL) (1753cc)",
          "1.8 ZXI (DIESEL) (1753cc)",
          "1.8 DIX (1753cc)",
          "1.4 TDCI TREND (1399cc)",
          "1.4 ZXI (1399cc)",
          "1.4 FLAIR (1399cc)",
          "DURA TORQ 1.4 TDCI (1399cc)",
          "DURATORQ 1.4 TDCI (1399cc)",
          "IKOOL DURA TORQ 1.4 TDCI (1399cc)",
          "IKOOL (1399cc)",
          "IKOOL (1399cc)",
          "FLAIR CNG (1298cc)",
          "1.6 RALLY SPORT (1597cc)",
          "1.6 RALLY SPORT (1597cc)",
          "RALLY SPORT (1597cc)",
          "ZXI (1597cc)",
          "1.6 ZXI JOSH 100 (1597cc)",
          "1.6 EXI NXT (1597cc)",
          "1.6 EXI JOSH 100 (1597cc)",
          "1.6 SXI NXT (1597cc)",
          "1.6 NXT (1597cc)",
          "IKOOL MUSIC EDITION (1299cc)",
          "1.3 JOSH 100 (1299cc)",
          "1.3 CLXI NXT (1299cc)",
          "1.8 SXI NXT (1299cc)",
          "1.3 CLXI NXT FINESSE (1299cc)",
          "1.3 LXI NXT (1299cc)",
          "1.8 EXI NXT (1299cc)",
          "1.8 ZXI NXT (1299cc)",
          "1.8 EXI (1299cc)",
          "1.8 SXI (1299cc)",
          "1.8 ZXI (1299cc)",
          "1.3 SOHC EXI (1297cc)",
          "IKOOL ROCAM 1.3 (1297cc)",
          "ROCAM 1.3 (1297cc)",
          "NXT 1.3 CLXI FINESSE (1297cc)",
          "NXT 1.3 CLXI (1297cc)",
          "FLAIR CNG (1596cc)",
        ],
      },
      {
        model: "FIGO",
        variants: [
          "1.5 TI-VCT TITANIUM (AT) (1498cc)",
          "1.2 TI-VCT TREND (1196cc)",
          "1.2 TI-VCT BASE (1196cc)",
          "1.2 TI-VCT AMBIENTE (1196cc)",
          "1.2 TI-VCT TITANIUM PLUS (1196cc)",
          "1.2 DURATEC ZXI (1196cc)",
          "1.2 DURATEC LXI (1196cc)",
          "1.2 DURATEC EXI (1196cc)",
          "1.2 DURATEC TITANIUM (1196cc)",
          "1.5 TDCI AMBIENTE (1498cc)",
          "1.5 TDCI TITANIUM PLUS (1498cc)",
          "1.5 TDCI TITANIUM (1498cc)",
          "1.5 TDCI TREND (1498cc)",
          "1.2 AMBIENTE (ABS) (MT) (1196cc)",
          "1.2 AMBIENTE (MT) (1196cc)",
          "1.2 BASE (MT) (1196cc)",
          "1.2 SPORTS EDITION (MT) (1196cc)",
          "1.2 TITANIUM (MT) (1196cc)",
          "1.2 TITANIUM (O) (MT) (1196cc)",
          "1.2 TITANIUM PLUS (MT) (1196cc)",
          "1.2 TREND (MT) (1196cc)",
          "1.4 DURATORQ EXI (1399cc)",
          "1.4 DURATORQ LXI (1399cc)",
          "1.4 DURATORQ TITANIUM (1399cc)",
          "1.4 DURATORQ ZXI (1399cc)",
          "1.5 AMBIENTE (ABS) (MT) (1499cc)",
          "1.5 AMBIENTE (MT) (1498cc)",
          "1.5 BASE (MT) (1498cc)",
          "1.5 TITANIUM (MT) (1498cc)",
          "1.5 TITANIUM (O) (MT) (1498cc)",
          "1.5 TITANIUM PLUS (MT) (1498cc)",
          "1.5 TREND (MT) (1498cc)",
          "1.5 TITANIUM (AT) (1499cc)",
          "1.5 TDCI BASE (1498cc)",
          "1.2 TI-VCT TITANIUM (1196cc)",
          "1.4 EXI TDCI BSIV (1399cc)",
          "1.5 SPORTS EDITION MT (1498cc)",
          "1.2P SPORTS EDITION MT (1196cc)",
          "1.2P TITANIUM PLUS MT (1196cc)",
          "1.2P BASE MT (1196cc)",
          "1.2P AMBIENTE ABS MT (1196cc)",
          "1.2P TITANIUM OPT MT (1196cc)",
          "1.2P TREND MT (1196cc)",
          "1.2P TITANIUM MT (1196cc)",
          "1.5P TITANIUM AT (1499cc)",
          "1.2P AMBIENTE MT (1196cc)",
          "1.5D AMBIENTE ABS MT (1499cc)",
          "1.5D AMBIENTE MT (1498cc)",
          "1.5D BASE MT (1498cc)",
          "1.5D SPORTS EDITION MT (1498cc)",
          "TREND 1.5 TDCI (1498cc)",
          "1.5D TITANIUM MT (1498cc)",
          "1.5D TITANIUM PLUS MT (1498cc)",
          "1.5D TREND MT (1498cc)",
          "1.5D TITANIUM OPT MT (1498cc)",
          "TREND 1.2 TI-VCT (1196cc)",
          "AMBIENTE 1.2 TI-VCT (1196cc)",
          "TITANIUM 1.2 TI-VCT (1196cc)",
          "TITANIUM 1.5 TI-VCT AT (1498cc)",
          "BASE 1.2 TI-VCT (1196cc)",
          "TITANIUM PLUS 1.2 TI-VCT (1196cc)",
          "TITANIUM PLUS 1.5 TDCI (1495cc)",
          "TITANIUM 1.5 TDCI (1498cc)",
          "TITANIUM1.5 TDCI (1498cc)",
          "BASE 1.5 TDCI (1498cc)",
          "AMBIENTE 1.5 TDCI (1498cc)",
          "1.2 DURATEC PETROL LXI (1196cc)",
          "1.2 DURATEC PETROL EXI (1196cc)",
          "1.2 DURATEC PETROL ZXI (1196cc)",
          "1.4 DURATORQ DIESEL LXI (1399cc)",
          "1.4 DURATORQ DIESEL EXI (1399cc)",
          "1.4 DURATORQ DIESEL ZXI (1399cc)",
          "1.4 DURATORQ DIESEL TITANIUM (1399cc)",
          "1.4 TITANIUM (1399cc)",
          "1.4 EXI (1399cc)",
          "1.4 ZXI (1399cc)",
          "1.4 LXI (1399cc)",
          "1.4 LXI (1399cc)",
          "CELEBRATION EDITION (1399cc)",
          "EXI (1399cc)",
          "TITANIUM (1399cc)",
          "ZXI (1399cc)",
          "LXI (1399cc)",
          "AMBIENTE (1399cc)",
          "1.4 TDCI TITANIUM (1399cc)",
          "1.4 TDCI DIESEL CELEBRATION EDITION (1399cc)",
          "1.4 TDCI (1399cc)",
          "1.4 EXI TDCI BS 4 (1399cc)",
          "DURATORQ DIESEL EXI 1.4 (1399cc)",
          "DURATORQ DIESEL LXI 1.4 (1399cc)",
          "CELEBRATION EDITION DIESEL (1399cc)",
          "DURATORQ DIESEL TITANIUM 1.4 (1399cc)",
          "DURATORQ DIESEL ZXI 1.4 (1399cc)",
          "1.2 ZXI (1196cc)",
          "1.2 EXI (1196cc)",
          "1 2 EXI (1196cc)",
          "1.2 TI-VCT TREND PLUS (1196cc)",
          "EXI (1196cc)",
          "TITANIUM (1196cc)",
          "ZXI (1196cc)",
          "LXI (1196cc)",
          "TITANIUM DURATEC (1196cc)",
          "1.2 TIVCT PETROL TREND PLUS (1196cc)",
          "1.2 TIVCT PETROL TITANIUM S MT (1196cc)",
          "1.2 TIVCT PETROL TREND S MT (1196cc)",
          "1.2 TIVCT PETROL CELEBRATION EDITION (1196cc)",
          "1.2 TIVCT PETROL ENTRY (1196cc)",
          "1.2 TIVCT PETROL AMBIENTE (1196cc)",
          "1.2 TIVCT PETROL TREND (1196cc)",
          "1.2 TIVCT PETROL TITANIUM (1196cc)",
          "1.2 TIVCT PETROL TITANIUM PLUS (1196cc)",
          "1.2 TIVCT PETROL TREND (O) (1196cc)",
          "TREND PLUS 1.2 TI-VCT (1196cc)",
          "TITANIUM 1.2 TI-VCT SPORTS EDITION (1196cc)",
          "ASPIRE 1.2 PETROL TITANIUM PLUS MT (1196cc)",
          "ASPIRE 1.2 TI-VCT TIT MT (1196cc)",
          "ASPIRE TREND 1.5 TDCI BS-IV (1196cc)",
          "AMBIENTE 1.2 TI-VCT ABS (1196cc)",
          "TITANIUM 1.2 TI-VCT O (1196cc)",
          "DURATEC PETROL TITANIUM 1.2 (1196cc)",
          "ASPIRE 1.2 TI-VCT (1196cc)",
          "CELEBRATION EDITION PETROL (1196cc)",
          "DURATEC PETROL EXI 1.2 (1196cc)",
          "DURATEC PETROL LXI 1.2 (1196cc)",
          "DURATEC PETROL ZXI 1.2 (1196cc)",
          "1.2 LXI (1196cc)",
          "1.2 TITANIUM (1196cc)",
          "1.2 TITANIUM SPORTS PETROL (1196cc)",
          "1.2 TITANIUM PLUS PETROL (1196cc)",
          "1.2 BASE PETROL (1196cc)",
          "1.2 AMBIENTE PETROL (1196cc)",
          "1.2 TREND PETROL (1196cc)",
          "1.2 TITANIUM PETROL (1196cc)",
          "1.2 DURATEC PETROL TITANIUM (1196cc)",
          "CELEBRATION EDITION (1198cc)",
          "1.5 (1498cc)",
          "1.5 TDCI TREND PLUS (1498cc)",
          "TDCI SPORTS EDITION (1498cc)",
          "TITANIUM BLU 1.5 TDCI (1498cc)",
          "TITANIUM 1.5 TDCI BS-VI (1498cc)",
          "1.5 TREND TDCI MT (1498cc)",
          "1.5 TDCI DIESEL TITANIUM MT BSIV (1498cc)",
          "ASPIRE 1.5 TREND (1498cc)",
          "TREND 1.5L TDCI (1498cc)",
          "1.5 TDCI DIESEL TREND PLUS (1498cc)",
          "1.5 TDCI DIESEL CELEBRATION EDITION (1498cc)",
          "1.5 TDCI DIESEL TITANIUM S MT (1498cc)",
          "1.5 TDCI DIESEL TREND S MT (1498cc)",
          "1.5 TDCI DIESEL ENTRY (1498cc)",
          "1.5 TDCI DIESEL AMBIENTE (1498cc)",
          "1.5 TDCI DIESEL TREND (1498cc)",
          "1.5 TDCI DIESEL TITANIUM (1498cc)",
          "1.5 TDCI DIESEL TITANIUM PLUS (1498cc)",
          "TREND 1.5 TDCI BS-VI (1498cc)",
          "1.5 TI VCT P TITANIUM AT (1498cc)",
          "ASPIRE 1.5 DIESLE TREND PLUS MT (1498cc)",
          "TITANIUM 1.5 TDCI SPORTS EDITION (1498cc)",
          "TITANIUM BLU 1.5 TDCI BS-VI (1498cc)",
          "AMBIENTE 1.5 TDCI ABS (1498cc)",
          "TITANIUM 1.5 TDCI O (1498cc)",
          "1.5D AMBIENTE ABS MT (1498cc)",
          "TITANIUM PLUS 1.5 TDCI (1498cc)",
          "1.5 TITANIUM BLU TDCI (1498cc)",
          "1.5 TITANIUM PLUS DIESEL (1498cc)",
          "1.5 TITANIUM DIESEL (1498cc)",
          "1.5 AMBIENTE DIESEL (1498cc)",
          "1.5 BASE DIESEL (1498cc)",
          "1.5 TREND DIESEL (1498cc)",
          "1.5 TITANIUM SPORTS DIESEL (1498cc)",
          "TITANIUM BLU 1.2 TI-VCT (1194cc)",
          "TITANIUM PLUS 1.2 TI-VCT AT BSVI (1194cc)",
          "TITANIUM BLU (1194cc)",
          "1.2 PETROL AMBIENTE MT ( CC 1194 ) (1194cc)",
          "1.2 PETROL TREND PLUS MT ( CC 1194 ) (1194cc)",
          "1.2 PETROL TITANIUM MT ( CC 1194 ) (1194cc)",
          "1.2 PETROL TREND MT ( CC 1194 ) (1194cc)",
          "1.2  PETROL TITANIUM PLUS AT 1194 CC (1194cc)",
          "1.2  PETROL TITANIUM BLU MT 1194 CC (1194cc)",
          "TREND 1.2 TI-VCT BS-VI (1194cc)",
          "AMBIENTE 1.2 TI-VCT BS-VI (1194cc)",
          "1.2 TITANIUM PLUS AT (1194cc)",
          "TITANIUM PLUS 1.2 TI-VCT AT (1194cc)",
          "TITANIUM 1.2 TI-VCT AT (1194cc)",
          "TITANIUM BLU 1.2 TI-VCT BS-VI (1194cc)",
          "TITANIUM 1.2 TI-VCT BS-VI (1194cc)",
          "TITANIUM 1.2 TI-VCT BSVI (1194cc)",
          "AMBIENTE 1.2 TI-VCT (1194cc)",
          "TITANIUM 1.2 TI-VCT (1194cc)",
          "TITANIUM 1.5 TI-VCT AT (1194cc)",
          "1.2 TITANIUM BLU TI-VCT (1194cc)",
          "1.2 TITANIUM PLUS TI-VCT AT (1194cc)",
          "ASPIRE 1.5 TIVCT PET (1499cc)",
          "1.5  DIESEL TITANIUM BLU MT (1499cc)",
          "1.5  DIESEL TITANIUM MT 1499 CC (1499cc)",
          "1.5 TIVCT PETROL TITANIUM AT (1499cc)",
          "ASPIRE 1.5 TI-VCT TIT AT (1499cc)",
          "1.5 TI-VCT TITANIUM AT (1499cc)",
          "1.5 TITANIUM AT PETROL (1499cc)",
          "1.5 PETROL TITANIUM AT ( CC 1497 ) (1497cc)",
          "ASPIRE 1.2 PETROL TREND PULS MT (1497cc)",
          "TREND PLUS 1.5L TDCI (1495cc)",
        ],
      },
      {
        model: "ESCORT",
        variants: [
          "SPORT E (1596cc)",
          "1.3 STD (1297cc)",
          "1.3 LX (1297cc)",
          "1.8 ORION (1753cc)",
          "1.8 STD (1753cc)",
          "1.6 ALPHA (1596cc)",
          "1.8 LX (1753cc)",
          "ALPHA 1.6 (1596cc)",
          "ORION 1.8 (1753cc)",
          "ORION (DIESEL) (1753cc)",
          "4W (1753cc)",
          "ALPHA (PETROL) (1599cc)",
          "STD (1597cc)",
          "ALFA 1.6 (1597cc)",
          "1.6 ALPHA (1597cc)",
          "STD (1796cc)",
          "LX 1.8 (1796cc)",
          "STD 1.8 (1796cc)",
          "STD 1.3 (1297cc)",
          "LX 1.3 (1297cc)",
          "SPORT E 1.6 (1596cc)",
          "1.3 (1299cc)",
        ],
      },
      {
        model: "FUSION",
        variants: [
          "1.4 TDCI (1399cc)",
          "1.4 TDCI (1399cc)",
          "1.6 DURATEC (1596cc)",
          "PLUS 1.6 DURATEC (1596cc)",
          "PLUS 1.4 DURATORQ TDCI (1399cc)",
          "1.6 DURATEC PETROL (1596cc)",
          "PLUS 1.6 DURATEC (PETROL) (1596cc)",
          "1.4 TDCI DIESEL (1399cc)",
          "1.4 TDCI (DIESEL) (1399cc)",
          "S (1596cc)",
          "PLUS (1596cc)",
          "PLUS ABS (1596cc)",
          "1.6 (1596cc)",
          "FUSION PLUS (1596cc)",
          "FUSION PLUS WITH ABS (1596cc)",
          "1.6 PETROL (1596cc)",
          "PLUS PETROL (1596cc)",
          "1.4 D (1399cc)",
          "PLUS DIESEL (1399cc)",
        ],
      },
      {
        model: "MONDEO",
        variants: [
          "GHIA (1999cc)",
          "GHIA (1999cc)",
          "DURATEC (1998cc)",
          "2.0 GHIA DURATEC (1999cc)",
          "2.0 GHIA DURATEC (PETROL) (1999cc)",
          "2.0 DIESEL GHIA (1999cc)",
          "DURATEC HE (1999cc)",
          "GHIA DURATEC (1999cc)",
          "2.0 DURATORQ (1998cc)",
          "2.0 DURATORQ (DIESEL) (1998cc)",
          "DURATORQ DI (1998cc)",
          "DURATEC (1299cc)",
        ],
      },
      {
        model: "ENDEAVOUR",
        variants: [
          "2.2 TITANIUM 4X2 (AT) (2198cc)",
          "2.5 4X2 HURRICANE (MT) (2499cc)",
          "3.0 4X2 HURRICANE (AT) (2953cc)",
          "3.0 4X4 HURRICANE (AT) (2953cc)",
          "3.2 TITANIUM 4X4 (AT) (3198cc)",
          "2.2 TITANIUM 4X4 (AT) (2198cc)",
          "2.2 TITANIUM PLUS 4X2 (AT) (2198cc)",
          "3.2 TITANIUM PLUS 4X4 (AT) (3198cc)",
          "2.2 TITANIUM 4X2 (MT) (2198cc)",
          "2.0 TITANIUM 4X2 (AT) (1996cc)",
          "2.0 TITANIUM PLUS 4X2 (AT) (1996cc)",
          "2.0 TITANIUM PLUS 4X4 (AT) (1996cc)",
          "2.0 TITANIUM + SPORTS 4X4 AT (1996cc)",
          "2.2 TREND 4X4 MT (2198cc)",
          "2.2 TREND 4X2 MT (2198cc)",
          "2.2 TREND 4X2 AT (2198cc)",
          "3.2 TREND AWD AT (3198cc)",
          "3.2 TITANIUM AWD AT (3198cc)",
          "4X2 XLT TDCI (2499cc)",
          "4X2 TDCI LTD (2499cc)",
          "4X4 THUNDER PLUS (2953cc)",
          "4X2 XLT (2499cc)",
          "4X4 XLT (2499cc)",
          "4X4 XLT TDCI (2499cc)",
          "2.5 4X2 TDCI (2495cc)",
          "3.0 4X2 AT (2953cc)",
          "3.0L 4X4 AT (2953cc)",
          "2.0 TITANIUM PLUS 4X2 AT BS6 (1996cc)",
          "2.0 TITANIUM SPORT D AT BS6 (1996cc)",
          "2.0 TITANIUM PLUS 4X4 AT BS6 (1996cc)",
          "2.0 DIESEL SPORT 4X4 AT (1996cc)",
          "2.0L TITANIUM 4X2 AT (1996cc)",
          "2.0 DIESEL TITANIUM PLUS 4X2 AT (1996cc)",
          "2.0 DIESEL TITANIUM PLUS 4X4 AT (1996cc)",
          "SPORT 2.0 4X4 AT (1996cc)",
          "2.0 DIESEL TITANIUM 4X2 AT (1996cc)",
          "2.0 DIESEL TITANIUM 4X4 AT (1996cc)",
          "2.0 DIESEL TITANIUM+ 4X4 AT (1996cc)",
          "2.0L 4X2 TITANIUM AT (1996cc)",
          "2.0L 4X4 TITANIUM PLUS AT (1996cc)",
          "2.0L 4X4 SPORT AT (1996cc)",
          "2.0L 4X2 TITANIUM PLUS AT (1996cc)",
          "TREND 2.2 4X2 MT (2198cc)",
          "TREND 2.2 4X4 MT (2198cc)",
          "2.2 TITANIUM AT 4X2 (2198cc)",
          "2.2 TREND AT 4X2 (2198cc)",
          "2.2 TITANIUM AT 4*2 (2198cc)",
          "2.2L 4Ãƒâ€”2 AT TREND (2198cc)",
          "2.2L TITANIUM 4X4 MT (2198cc)",
          "2.2 TITANIUM (2198cc)",
          "2.2 TITANIUM AT (2198cc)",
          "2.2 TITANIUM PLUS AT (2198cc)",
          "2.2 TREND AWD MT (2198cc)",
          "TITANIUM 2.2 4X2 AT (2198cc)",
          "TREND 2.2 4X2 AT (2198cc)",
          "2.2L DIESEL TITANIUM 4X2 MT (2198cc)",
          "2.2L DIESEL TITANIUM+ 4X2 AT. (2198cc)",
          "2.2L DIESEL TITANIUM+ 4X2 AT (2198cc)",
          "2.2 TREND MT 4X4 (2198cc)",
          "2.2L 4X2 TITANIUM AT (2198cc)",
          "2.2L 4X2 TREND MT (2198cc)",
          "2.2L 4X2 TREND AT (2198cc)",
          "3.2 TREND (2198cc)",
          "2.2L 4X4 TREND MT (2198cc)",
          "2.2 TREND MT 4X2 (2198cc)",
          "2.2L 4X2 TITANIUM MT (2198cc)",
          "2.2L 4X2 TITANIUM PLUS AT (2198cc)",
          "TDCI (2495cc)",
          "4X2 DIESEL (2498cc)",
          "2.5 4X2 MT HURRICANE (2499cc)",
          "HURRICANE (2499cc)",
          "4X2 MT (2499cc)",
          "XLT (2499cc)",
          "4*4 9STR (2499cc)",
          "4X4 AT (2499cc)",
          "XLT TDCI 4X2 (2499cc)",
          "XLT TDCI 4X2 LTD (2499cc)",
          "XLT TDCI 4X4 (2499cc)",
          "4X4 DIESEL (2499cc)",
          "XLT 4 * 2 (2499cc)",
          "XLT 4 * 4 (2499cc)",
          "TDCI 4X2 XLT - LIMITED EDITION (2499cc)",
          "TDCI 4X4 XLT (2499cc)",
          "TDCI 4X2 XLT (2499cc)",
          "2.5L 4X2 (2499cc)",
          "2.5 L 4X2 (2499cc)",
          "XLT 4X2 (2499cc)",
          "XLT 4X4 (2499cc)",
          "3.0 4X2 AT HURRICANE (2953cc)",
          "3.0 4X4 AT HURRICANE (2953cc)",
          "3.0L 4X2 (2953cc)",
          "3.0 L 4X2 (2953cc)",
          "THUNDER PLUS (2953cc)",
          "AT HURRICANE (2953cc)",
          "AT (2953cc)",
          "3.0L 4*4 AT 5STR (2953cc)",
          "3.0L THUNDER PLUS 4X4 (2953cc)",
          "ALLTERRAIN EDITION 4X4 AT (2953cc)",
          "HURRICANE LE (2953cc)",
          "3.0 L 4X4 (2953cc)",
          "4X4 THUNDER (2953cc)",
          "3.0 THUNDER PLUS 4X4 (2953cc)",
          "HURRICANE LIMITED EDITION (2953cc)",
          "3.0L 4X2 AT (2953cc)",
          "3.2 TITANIUM AT 4X4 (3198cc)",
          "3.2 TITANIUM AT (3198cc)",
          "3.2L DIESEL TITANIUM PLUS 4X4 AT (3198cc)",
          "3.2 TREND 4X4 AT 8STR (3198cc)",
          "3.2 TITANIUM 4WD AT (3198cc)",
          "3.2 TITANIUM PLUS AWD AT (3198cc)",
          "3.2L DIESEL TITANIUM+ 4X4 AT (3198cc)",
          "3.2L TITANIUM AWD AT (3198cc)",
          "3.2L TITANIUM 4X4 AT (3198cc)",
          "TREND 3.2 4X4 AT (3198cc)",
          "TITANIUM 3.2 4X4 AT (3198cc)",
          "3.2L 4X4 TITANIUM PLUS AT (3198cc)",
          "3.2 TREND AT 4X4 (3198cc)",
          "3.2L 4X4 AT TITANIUM (3198cc)",
          "3.2L 4X4 TREND AT (3198cc)",
        ],
      },
      {
        model: "FREESTYLE",
        variants: [
          "1.5 TDCI AMBIENTE (1499cc)",
          "1.5 TDCI TREND (1499cc)",
          "1.5 TDCI TITANIUM (1499cc)",
          "1.5 TDCI TITANIUM PLUS (1499cc)",
          "1.2 TI-VCT AMBIENTE (1194cc)",
          "1.2 TI-VCT TREND (1194cc)",
          "1.2 TI-VCT TITANIUM (1194cc)",
          "1.2 TI-VCT TITANIUM PLUS (1194cc)",
          "1.2 TI-VCT TREND PLUS (1194cc)",
          "1.5 TDCI TREND (O) (1499cc)",
          "1.2 TREND MT (1194cc)",
          "FLAIR EDITION DIESEL (1499cc)",
          "FLAIR EDITION PETROL (1194cc)",
          "AMBIENTE 1.2 I-VCT (1194cc)",
          "TREND 1.2 I-VCT (1194cc)",
          "TITANIUM 1.2 I-VCT (1194cc)",
          "AMBIENTE 1.2 1.5-TDCI (1499cc)",
          "TREND 1.5-TDCI (1499cc)",
          "TITANIUM 1.5-TDCI (1499cc)",
          "TITANIUM PLUS (1498cc)",
          "TREND PLUS 1.5L TDCI (1498cc)",
          "TREND 1.5L TDCI (1498cc)",
          "1.5 DIESEL TITANIUM PLUS MT (1498cc)",
          "1.5 DIESEL AMBIENT MT (1498cc)",
          "1.5 DIESEL TREND MT (1498cc)",
          "1.5 DIESEL TITANIUM MT (1498cc)",
          "1.5 DIESEL TREND PLUS MT (1498cc)",
          "TITANIUM 1.5 TDCI BS-VI (1498cc)",
          "TITANIUM PLUS 1.5 TDCI BS-VI (1498cc)",
          "TREND 1.5L TDCI BS-VI (1498cc)",
          "TITANIUM 1.5 DIESEL TITNMMT (1498cc)",
          "TREND PLUS 1.5L TDCI BS-VI (1498cc)",
          "TREND PLUS 1.5 TDCI (1498cc)",
          "TITANIUM PLUS 1.5 TDCI (1498cc)",
          "AMBIENTE 1.5 TDCI (1498cc)",
          "TREND 1.5 TDCI (1498cc)",
          "TITANIUM 1.5 TDCI (1498cc)",
          "1.2 PETROL TITANIUM PLUS MT (1194cc)",
          "1.2 PETROL TREND PLUS MT (1194cc)",
          "1.2 PETROL AMBIENT MT (1194cc)",
          "1.2 PETROL TREND MT (1194cc)",
          "1.2 PETROL TITANIUM MT (1194cc)",
          "TITANIUM 1.2 TI-VCT BS-VI (1194cc)",
          "AMBIENTE 1.2 TI-VCT BS-VI (1194cc)",
          "TITANIUM PLUS 1.2 TI-VCT BS-VI (1194cc)",
          "TREND 1.2 TI-VCT BS-VI (1194cc)",
          "TREND PLUS 1.2 TI-VCT BS-VI (1194cc)",
          "TITANIUM 1.2 BS-VI (1194cc)",
          "TITANIUM PLUS 1.2 MT (1194cc)",
          "TITANIUM 1.2 MT (1194cc)",
          "1.2 PETROL TITNM PLUS MT (1194cc)",
          "FLAIR EDITION 1.2 TI-VCT (1194cc)",
          "AMBIENTE 1.2 TI-VCT (1194cc)",
          "TITANIUM 1.2 TI-VCT (1194cc)",
          "TITANIUM PLUS 1.2 TI-VCT (1194cc)",
          "TREND 1.2 TI-VCT (1194cc)",
          "TREND PLUS 1.2 TI-VCT (1194cc)",
          "1.5 DIESEL TREND MT 1499 CC (1499cc)",
          "1.5  DIESEL TITANIUM PLUS MT 1499 CC (1499cc)",
          "1.5  DIESEL FLAIR MT 1499 CC (1499cc)",
          "1.5  DIESEL TITANIUM MT 1499 CC (1499cc)",
          "FLAIR EDITION 1.5 TDCI (1499cc)",
        ],
      },
      {
        model: "ECOSPORT",
        variants: [
          "TREND (1496cc)",
          "1.5 TITANIUM PLUS (1497cc)",
          "TITANIUM PLUS AT (1496cc)",
          "1.0 ECOBOOST PLATINUM EDITION (999cc)",
          "1.0 ECOBOOST TRENDS PLUS (999cc)",
          "1.5 TDCI AMBIENTE (1498cc)",
          "1.5 TDCI PLATINUM EDITION (1498cc)",
          "1.5 TDCI TITANIUM (1498cc)",
          "1.5 TDCI TITANIUM PLUS (1498cc)",
          "1.5 TDCI TREND (1498cc)",
          "1.5 TDCI TREND PLUS (1498cc)",
          "1.5 TI- VCT TITANIUM (MT) (1497cc)",
          "1.5 TI- VCT TITANIUM (AT) (1497cc)",
          "1.5 TITANIUM O (AT) (1497cc)",
          "1.5 TDCI SIGNATURE EDITION (1498cc)",
          "1.5 TDCI S DIESEL (1498cc)",
          "1.0 ECOBOOST S PETROL (999cc)",
          "1.5 TI- VCT SIGNATURE EDITION (1497cc)",
          "1.5 TI- VCT TITANIUM PLUS (MT) (1497cc)",
          "1.0 ECOBOOST TITANIUM (MT) (999cc)",
          "1.0 ECOBOOST TITANIUM(O) (MT) (999cc)",
          "1.5 TDCI TITANIUM(O) MT (1498cc)",
          "1.5 TI- VCT TUNDER (MT) (1497cc)",
          "1.5 TDCI TUNDER (MT) (1498cc)",
          "1.5 TITANIUM PLUS SPORTS (1498cc)",
          "1.5 TITANIUM PLUS SPORTS (1497cc)",
          "1.5 TITANIUM (1499cc)",
          "SPORTS (1496cc)",
          "1.5L SE MT (1496cc)",
          "1.5 AMBIENTE (1499cc)",
          "1.0 ECOBOOST TITANIUM PLUS. (999cc)",
          "1.5 TITANIUM PLUS AT (1497cc)",
          "1.5 TIVCT TITANIUM PLUS AT (1497cc)",
          "1.5 TIVCT TREND PLUS AT (1497cc)",
          "1.5 TIVCT TREND MT (1497cc)",
          "1.5 TIVCT AMBIENTE MT (1497cc)",
          "1.5 TREND MT (1499cc)",
          "TREND + 1.5L TI-VCT AT (1497cc)",
          "S (1498cc)",
          "S (999cc)",
          "SIGNATURE EDITION (1498cc)",
          "SIGNATURE EDITION (1497cc)",
          "1.5 TI-VCT TITANIUM+ MT (1499cc)",
          "1.5 TI- VCT MT TITANIUM (1499cc)",
          "1.5 TI- VCT MT TREND (1499cc)",
          "1.5 TI- VCTAT TITANIUM (1499cc)",
          "1.5 TI- VCT MT AMBIENTE (1499cc)",
          "1.5 TREND + AT (1499cc)",
          "1.0 ECOBOOST TITANIUM (O) (999cc)",
          "1.5 TITANIUM + AT (1499cc)",
          "TITANIUM AT (1499cc)",
          "AMBIENTE (1499cc)",
          "TREND PLUS AT (1499cc)",
          "1.5L TIVCT PETROL TREND (1499cc)",
          "1.5L TIVCT PETROL TITANIUM (1499cc)",
          "1.5 TIVCT PETROL AMBIENTE MT (1499cc)",
          "1.5 TIVCT PETROL TREND MT (1499cc)",
          "1.5 TIVCT PETROL TITANIUM MT (1499cc)",
          "1.5 TIVCT PETROL TITANIUM AT (1499cc)",
          "1.5 TIVCT PETROL TREND PLUS AT (1499cc)",
          "1.5 TIVCT PETROL TITNIUM PLUS AT (1499cc)",
          "1.5 PETROL TITANIUM PLUS MT (1499cc)",
          "1.5 PETROL TITANIUM MT SIGNATURE SVP (1499cc)",
          "TITANIUM MT (1499cc)",
          "1.5 (P) TITANIUM AT (1499cc)",
          "TITANIUM 1.5L TI-VCT BLACK EDITION (1499cc)",
          "TITANIUM 1.5L TI-VCT BLACK EDITION AT (1499cc)",
          "1.5 SIGNATURE MT TIVCT (1499cc)",
          "1.5 SIGNATURE AT TIVCT (1499cc)",
          "1.0L S DIESEL (999cc)",
          "1.5 PETROL TREND (1499cc)",
          "1.5 TIT MP (1499cc)",
          "1.5 (P) TITANIUM MT (1499cc)",
          "AMBIENTE 1.5L TI-VCT (1499cc)",
          "TITANIUM 1.5L TI-VCT AT (1499cc)",
          "TREND 1.5L TI-VCT (1499cc)",
          "TITANIUM 1.5L TI-VCT (1499cc)",
          "TREND (1499cc)",
          "TRENDLINE 1.5 TI-VCT (1499cc)",
          "AMBIENTE 1.5 TI-VCT (1499cc)",
          "TITANIUM 1.5 TI-VCT AT (1499cc)",
          "1.0L ECOBOOST TREND PLUS MT (999cc)",
          "TREND PLUS (999cc)",
          "TITANIUM PLUS (999cc)",
          "ECOBOOST TITANIUM (999cc)",
          "ECOBOOST TITANIUM OPTION (999cc)",
          "AMBIENTE (1498cc)",
          "1.0 PETROL ECOBOOST TITANIUM MT (999cc)",
          "1.0 PETROL ECOBOOST TITANIUM(O) MT (999cc)",
          "1.0 PETROL TREND PLUS (999cc)",
          "1.0 PETROL TITANIUM PLUS (999cc)",
          "1.0 PETROL S (999cc)",
          "1.0 PETROL ECOBOOST TREND PLUS (999cc)",
          "1.0 PETROL TITANIUM PLUS MT SUNROOF (999cc)",
          "1.0 PETROL ECOBOOST PLATINUM EDITION (999cc)",
          "1.0 PETROL TITANIUM PLUS SPORTS (999cc)",
          "TITANIUM + 1.0L ECOBOOST (999cc)",
          "S PETROL (999cc)",
          "TREND + 1.0L ECOBOOST BLACK EDITION (999cc)",
          "TITANIUM + 1.0L ECOBOOST BLACK EDITION (999cc)",
          "TITANIUM + MT SPORTS (999cc)",
          "TREND + 1.0L ECOBOOST (999cc)",
          "TITANIUM 1.0 ECOBOOST(O) (999cc)",
          "PLATINUM EDITION PETROL (999cc)",
          "TREND PLUS 1.0 ECOBOOST (999cc)",
          "1.0 PLATINUM EDITION PETROL (999cc)",
          "TITANIUM 1.0 ECOBOOST OPT (999cc)",
          "TITANIUM PLUS 1.0 ECOBOOST (999cc)",
          "1.0L S PETROL (999cc)",
          "TITANIUM 1.0 ECOBOOST (999cc)",
          "S MT 1.5 TDCI (1498cc)",
          "1.5  DIESEL SE MT 1498 CC (1498cc)",
          "1.5  DIESEL S MT 1498 CC (1498cc)",
          "1.5 DIESEL SPORT EDITION (1498cc)",
          "1.5 TDCI DIESEL AMBIENTE MT (1498cc)",
          "1.5 PETROL TITANIUM+ AT (1498cc)",
          "1.5D SPORTS (1498cc)",
          "1.5 TITANIUM BSIV (1498cc)",
          "1.5 AMBIENTE MT (P) (1498cc)",
          "AMBIENTE 1.5L TDCI (1498cc)",
          "SE DIESEL (1498cc)",
          "S DIESEL (1498cc)",
          "AMBIENTE 1.5 TDCI (1498cc)",
          "1.5L S DIESEL (1498cc)",
          "1.5L SE MT DIESEL (1498cc)",
          "1.5D SPORTS (1499cc)",
          "1.5(P) TREND MT (1496cc)",
          "TITANIUM PLUS MT SPORTS (1496cc)",
          "TITANIUM PLUS AT BS6 (1496cc)",
          "1.5P SPORTS BLACKROOF (1496cc)",
          "S MT 1.5 TI-VCT (1496cc)",
          "1.5  PETROL SE MT 1496 CC (1496cc)",
          "1.5 PETROL TITANIUM MT+ SPORTS (1496cc)",
          "1.5  PETROL TITANIUM PLUS AT 1496 CC (1496cc)",
          "1.5  PETROL AMBIENTE MT 1496 CC (1496cc)",
          "1.5  PETROL TREND MT 1496 CC (1496cc)",
          "1.5  PETROL TITANIUM MT 1496 CC (1496cc)",
          "1.5  PETROL TITANIUM PLUS MT 1496 CC (1496cc)",
          "1.5  PETROL S MT 1496 CC (1496cc)",
          "1.5 PETROL TITANIUM MT THUNDER (1496cc)",
          "TITANIUM MT (1496cc)",
          "TITANIUM + MT SPORTS (1496cc)",
          "SE PETROL (1496cc)",
          "AMBIENTE 1.5L TI-VCT (1496cc)",
          "TITANIUM 1.5L TI-VCT AT (1496cc)",
          "TITANIUM + 1.5L TI-VCT AT (1496cc)",
          "TREND 1.5L TI-VCT (1496cc)",
          "TITANIUM + 1.5L TI-VCT (1496cc)",
          "TITANIUM 1.5L TI-VCT (1496cc)",
          "THUNDER EDITION PETROL (1496cc)",
          "1.5 TITANIUM AT (1496cc)",
          "1.5L S MT PETROL (1496cc)",
          "1.5L SE MT PETROL (1496cc)",
          "1.5 SIGNATURE EDITION PETROL (1497cc)",
          "TITANIUM PLUS 1.5L TI-VCT (1497cc)",
          "1.5L TIVCT PETROL TITANIUM PLUS (1497cc)",
          "1.5 TIVCT TITANIUM MT (CC1497) (1497cc)",
          "1.5 PETROL THUNDER MT (1497cc)",
          "1.5 SIGNATURE MT TIVCT (1497cc)",
          "1.5L TITANIUM PLUS MT (1497cc)",
          "1.5L TITANIUM MT (1497cc)",
          "1.5(P) AMBIENTE MT BS-IV (1497cc)",
          "1.5 (P) TITANIUM PLUS AT (1497cc)",
          "1.5(P) TITANIUM MT (1497cc)",
          "AMBIENTE 1.5L TI-VCT (1497cc)",
          "TITANIUM + 1.5L TI-VCT AT (1497cc)",
          "TREND 1.5L TI-VCT (1497cc)",
          "TITANIUM 1.5L TI-VCT (1497cc)",
          "THUNDER EDITION PETROL (1497cc)",
          "TITANIUM PLUS AT (1497cc)",
          "1.5 TREND MT (1497cc)",
          "TITANIUM 1.5 TI-VCT (1497cc)",
          "TITANIUM PLUS 1.5 TI-VCT AT (1497cc)",
          "TITANIUM PLUS 1.5 TI-VCT (1497cc)",
          "SIGNATURE EDITION PETROL (1497cc)",
          "TREND PLUS 1.5 TI-VCT AT (1497cc)",
          "1.5 THUNDER MT PETROL (1497cc)",
          "TREND 1.5 TI-VCT (1497cc)",
          "1.5 SIGNATURE TDCI (1497cc)",
          "TITANIUM PLUS (1498cc)",
          "TREND PLUS AT (1498cc)",
          "1.5 TREND PLUS (1498cc)",
          "TITANIUM OPTION (1498cc)",
          "PLATINUM EDITION (1498cc)",
          "1.5L TDCI DIESEL TITANIUM (O) 6 STR (1498cc)",
          "TITANIUM PLUS MT SPORTS DIESEL (1498cc)",
          "1.5 SIGNATURE EDITION DIESEL (1498cc)",
          "1.5 TDCI DIESEL TREND PLUS (1498cc)",
          "1.5 DIESEL TITANIUM PLUS (1498cc)",
          "1.5 TDCI DIESEL PLATINUM MT (1498cc)",
          "1.5 DIESEL TITANIUM MT  SIGNATURE SVP (1498cc)",
          "1.5 DIESEL TITANIUM PLUS MT SUNROOF (1498cc)",
          "1.5 TDCI DIESEL TITANIUM PLUS MT (1498cc)",
          "1.5 DISEL THUNDER MT (1498cc)",
          "1.5 DIESEL TITANIUM MTÂ  THUNDER (1498cc)",
          "1.5 DIESEL TITANIUM MT+ SPORTS (1498cc)",
          "1.5 DIESEL TITANIUM (O) SPORT MT (1498cc)",
          "1.5 TDCI DIESEL TREND MT (1498cc)",
          "1.5 TDCI DIESEL TITANIUM MT (1498cc)",
          "1.5 TDCI DIESEL TITANIUM(O) MT (1498cc)",
          "TITANIUM MT (1498cc)",
          "TREND+ 1.5L TDCI BLACK EDITION (1498cc)",
          "TREND + 1.5L TDCI BLACK EDITION (1498cc)",
          "1.5 SIGNATURE TDCI (1498cc)",
          "TITANIUM + MT SPORTS DIESEL (1498cc)",
          "1.5 TITANIUM MT (1498cc)",
          "TITANIUM+ 1.5L TDCI BLACK EDITION (1498cc)",
          "TITANIUM 1.5L TDCI BLACK EDITION (1498cc)",
          "PLATINUM EDITION DIESEL (1498cc)",
          "TITANIUM 1.5L TDCI (1498cc)",
          "TITANIUM + 1.5L TDCI (1498cc)",
          "THUNDER EDITION DIESEL (1498cc)",
          "TITANIUM 1.5 TDCI (O) (1498cc)",
          "TREND 1.5L TDCI (1498cc)",
          "TREND (1498cc)",
          "TITANIUM 1.5 TCDI OPT (1498cc)",
          "1.5 PLATINUM EDITION DIESEL (1498cc)",
          "TITANIUM 1.5 TDCI OPT (1498cc)",
          "TITANIUM PLUS 1.5 TDCI (1498cc)",
          "SIGNATURE EDITION DIESEL (1498cc)",
          "TREND 1.5 TDCI (1498cc)",
          "TITANIUM 1.5 TDCI (1498cc)",
          "TREND PLUS 1.5 TDCI (1498cc)",
          "TITANIUM 1.5 TCDI (1498cc)",
          "TRENDLINE 1.5 TDCI (1498cc)",
          "1.5 THUNDER MT DIESEL (1498cc)",
          "1.5 THUNDER EDITION DIESEL (1498cc)",
          "1.5 D TREND MT (1499cc)",
        ],
      },
      {
        model: "VINTAGE",
        variants: [
          "V 8 (2900cc)",
          "IKON (2199cc)",
          "MUSTANG (1600cc)",
          "V 7 (2423cc)",
          "JEEP (1000cc)",
          "JEEP (3150cc)",
          "FORD SUPER (995cc)",
          "V 6 (1172cc)",
          "V 5 (4200cc)",
          "ZEBHUR (2963cc)",
          "A MODEL (2900cc)",
          "FORD A (3300cc)",
          "ROADSTER (1000cc)",
          "M MODEL (4700cc)",
          "STATION WAGOR (3900cc)",
          "VINTAGE (5752cc)",
          "VINTAGE (5500cc)",
          "VINTAGE (3286cc)",
          "VINTAGE (4200cc)",
          "VINTAGE (1172cc)",
          "VINTAGE (1298cc)",
          "VINTAGE (4800cc)",
          "VINTAGE (5152cc)",
          "VINTAGE (3703cc)",
          "VINTAGE (1596cc)",
          "VINTAGE (7670cc)",
          "VINTAGE (2896cc)",
          "VINTAGE (4990cc)",
          "VINTAGE (2400cc)",
          "VINTAGE (4000cc)",
          "VINTAGE (3000cc)",
          "VINTAGE (2000cc)",
          "VINTAGE (1520cc)",
          "VINTAGE (900cc)",
          "CLASSIC 1.6 DURATEC (1800cc)",
          "CLASSIC WILLYS MB (1800cc)",
          "PERFECT (1100cc)",
          "OPEN TOERIER (2896cc)",
          "CORTINA (1298cc)",
          "PICKUP STREET (3621cc)",
          "THUNDERBIRD (6390cc)",
          "THUNDERBIRD (4244cc)",
          "CUSTOM (7500cc)",
          "FORD MERCURY (3250cc)",
          "PILOT (1600cc)",
          "-1938 (796cc)",
          "-1928 (796cc)",
          "-1927 (796cc)",
          "-1930 (796cc)",
          "-1935 (796cc)",
        ],
      },
      {
        model: "NEW ASPIRE",
        variants: [
          "1.2 TI-VCT AMBIENTE (MT) (1194cc)",
          "1.2 TI-VCT TITANIUM PLUS (MT) (1194cc)",
          "1.2 TI-VCT TREND PLUS (MT) (1194cc)",
          "1.5 TI-VCT TITANIUM (AT) (1497cc)",
          "1.2 TI-VCT TREND (MT) (1194cc)",
          "1.2 TI-VCT TITANIUM (MT) (1194cc)",
          "1.5 TDCI TITANIUM PLUS (MT) (1498cc)",
          "1.5 TDCI AMBIENTE (MT) (1498cc)",
          "1.5 TDCI TREND (MT) (1498cc)",
          "1.5 TDCI TITANIUM (MT) (1498cc)",
          "1.5 TDCI TREND PLUS (MT) (1498cc)",
          "1.2 TI-VCT TREND PLUS (1194cc)",
          "1.2 TI-VCT AMBIENTE (1194cc)",
        ],
      },
      {
        model: "NEW FIGO",
        variants: [
          "1.5 TDCI TITANIUM BLU (MT) (1498cc)",
          "1.5 TDCI AMBIENTE (MT) (1498cc)",
          "1.5 TDCI TITANIUM (MT) (1498cc)",
          "1.5 TI- VCT TITANIUM (AT) (1497cc)",
          "1.2 TI- VCT AMBIENTE (MT) (1194cc)",
          "1.2 TI- VCT TITANIUM BLU (MT) (1194cc)",
          "1.2 TI- VCT TITANIUM (MT) (1194cc)",
          "1.2 TI- VCT TITANIUM PLUS AT (1194cc)",
        ],
      },
      {
        model: "TOUGER",
        variants: ["CLASSIC (2025cc)"],
      },
      {
        model: "F 150",
        variants: ["3.5L TI-VCT V6 (3496cc)"],
      },
      {
        model: "FIESTA 1.5",
        variants: [
          "TITANIUM PLUS DIESEL (1498cc)",
          "STYLE PETROL AT (1499cc)",
          "AMBIENTE DIESEL (1498cc)",
        ],
      },
      {
        model: "FUSION PLUS",
        variants: ["1.4 DURATORQ TDCI (DIESEL) (1399cc)"],
      },
      {
        model: "CLASSIC",
        variants: [
          "1.4 DURATORQ LXI (1399cc)",
          "1.4 DURATORQ CLXI (1399cc)",
          "1.4 TITANIUM (1399cc)",
          "CLXI (1399cc)",
          "DURATORQ CLXI (1399cc)",
          "1.4 TDCI TITANIUM (1399cc)",
          "1.4 TDCI TITANIUM (1399cc)",
          "1.4 TDCI CLXI (1399cc)",
          "1.4 TDCI LXI (1399cc)",
          "LXI (1596cc)",
          "1.6 DURATEC CLXI (1596cc)",
          "1.6 DURATEC CLXI (1596cc)",
          "1.6 DURATEC TITANIUM (1596cc)",
          "1.6 DURATEC TITANIUM (1596cc)",
          "1.6 DURATEC LXI (1596cc)",
          "1.6 DURATEC LXI (1596cc)",
        ],
      },
      {
        model: "ASPIRE",
        variants: [
          "SPORTS EDITION MT (1196cc)",
          "TI-VCT AMBIENTE (1196cc)",
          "TI-VCT AMBIENTE (1196cc)",
          "TI-VCT (1196cc)",
          "TI-VCT TITANIUM PLUS (1196cc)",
          "TI-VCT TITANIUM PLUS (1196cc)",
          "TI-VCT TITANIUM (1196cc)",
          "TI-VCT SPORTS EDITION (1196cc)",
          "TI-VCT TITANIUM OPTION (1196cc)",
          "TI-VCT TREND (1196cc)",
          "FIGO TI-VCT TITANIUM (1196cc)",
          "FIGO TI-VCT TREND (1196cc)",
          "1.2 TIVCT PETROL TITANIUM S MT (1196cc)",
          "1.2 TIVCT PETROL TREND S MT (1196cc)",
          "1.2 TIVCT PETROL AMBIENTE MT (1196cc)",
          "1.2 TIVCT PETROL TITANIUM PLUS MT (1196cc)",
          "1.2 TIVCT PETROL TREND (O) (1196cc)",
          "1.2 TIVCT PETROL TREND MT (1196cc)",
          "1.2 TIVCT PETROL TITANIUM MT (1196cc)",
          "AMBIENTE 1.2 TI-VCT ABS (1196cc)",
          "TITANIUM 1.2 TI-VCT O (1196cc)",
          "TITANIUM 1.2 TI-VCT SPORTS EDITION (1196cc)",
          "TITANIUM 1.2 TI-VCT SPORTS EDITION (1196cc)",
          "AMBIENCE 1.2 TI-VCT (1196cc)",
          "1.2 TI- VCT AMBIENTE (1196cc)",
          "1.2 TI- VCT AMBIENTE (ABS) (1196cc)",
          "1.2 TI- VCT SPORTS EDITION (1196cc)",
          "1.2 TI- VCT TITANIUM (1196cc)",
          "1.2 TI- VCT TITANIUM (O) (1196cc)",
          "1.2 TI- VCT TITANIUM PLUS (1196cc)",
          "1.2 TI- VCT TREND (1196cc)",
          "1.2 TI-VCT AMBIENCE (1196cc)",
          "1.2 TI- VCT TITANIUM OPT (1196cc)",
          "AMBIENTE 1.2 TI-VCT (1196cc)",
          "AMBIENTE 1.2 TI-VCT (1196cc)",
          "TITANIUM PLUS 1.2 TI-VCT (1196cc)",
          "TITANIUM PLUS 1.2 TI-VCT (1196cc)",
          "TREND 1.2 TI-VCT (1196cc)",
          "TREND 1.2 TI-VCT (1196cc)",
          "TITANIUM 1.2 TI-VCT (1196cc)",
          "TITANIUM 1.2 TI-VCT (1196cc)",
          "TI-VCT TITANIUM (1499cc)",
          "FIGO TITANIUM AT (1499cc)",
          "1.5 TIVCT PETROL TITANIUM AT (1499cc)",
          "TITANIUM PLUS 1.5 MT (1499cc)",
          "1.5 TI-VCT TITANIUM (AT) (1499cc)",
          "1.5 TI- VCT TITANIUM (1499cc)",
          "TITANIUM 1.5 TI-VCT AT (1499cc)",
          "TITANIUM 1.5 TI-VCT AT (1499cc)",
          "TI-VCT TITANIUM (1498cc)",
          "TDCI AMBIENTE (1498cc)",
          "TDCI AMBIENTE (1498cc)",
          "TDCI TITANIUM (1498cc)",
          "TDCI TITANIUM (1498cc)",
          "TDCI TITANIUM PLUS (1498cc)",
          "TDCI TITANIUM PLUS (1498cc)",
          "1.5D BASE MT (1498cc)",
          "TDCI TITANIUM OPTION (1498cc)",
          "TDCI SPORTS EDITION (1498cc)",
          "FIGO TDCI TREND (1498cc)",
          "TDCI TREND (1498cc)",
          "1.5 TITANIUM (1498cc)",
          "1.5 TDCI DIESEL TREND S MT (1498cc)",
          "1.5 TDCI DIESEL TITANIUM S MT (1498cc)",
          "1.5 TDCI DIESEL TITANIUM PLUS MT (1498cc)",
          "1.5 TDCI DIESEL TREND PLUS MT (CC 1498) (1498cc)",
          "1.5 TDCI DIESEL ENTRY (1498cc)",
          "1.5 TDCI DIESEL TREND (O) (1498cc)",
          "1.5 TDCI DIESEL AMBIENTE MT (1498cc)",
          "1.5 TDCI DIESEL TREND MT (1498cc)",
          "1.5 TDCI DIESEL TITANIUM MT (1498cc)",
          "1.5 TDCI (1498cc)",
          "1.5 TDCI (1498cc)",
          "1.5 TDCI DIES (1498cc)",
          "1.5 DIESEL TITNM+ (1498cc)",
          "TREND 1.5 TDCI BS-VI (1498cc)",
          "TITANIUM1.5 TDCI BS-VI (1498cc)",
          "TITANIUM PLUS 1.5 TDCI BS-VI (1498cc)",
          "TITANIUM 1.5 TDCI SPORTS EDITION (1498cc)",
          "TITANIUM 1.5 TDCI SPORTS EDITION (1498cc)",
          "AMBIENTE 1.5 TDCI ABS (1498cc)",
          "TITANIUM BLU 1.5 TDCI (1498cc)",
          "1.2 AMBIENTE TI-VCT (1498cc)",
          "TITANIUM 1.5 TDCI O (1498cc)",
          "1.5 TDCI AMBIENTE (1498cc)",
          "1.5 TDCI AMBIENTE (ABS) (1498cc)",
          "1.5 TDCI SPORTS EDITION (1498cc)",
          "1.5 TDCI TITANIUM (1498cc)",
          "1.5 TDCI TITANIUM OPT (1498cc)",
          "1.5 TDCI TITANIUM PLUS (1498cc)",
          "1.5 TDCI TREND (1498cc)",
          "1.5 TDCI AMBIENTE SP (1498cc)",
          "TITANIUM PLUS 1.5 TDCI (1498cc)",
          "TITANIUM PLUS 1.5 TDCI (1498cc)",
          "TITANIUM1.5 TDCI (1498cc)",
          "TITANIUM 1.5 TDCI (1498cc)",
          "TREND 1.5 TDCI (1498cc)",
          "TREND 1.5 TDCI (1498cc)",
          "AMBIENTE 1.5 TDCI (1498cc)",
          "AMBIENTE 1.5 TDCI (1498cc)",
          "TREND PLUS 1.5 TDCI (1498cc)",
          "TITANIUM 1.5 TI-VCT AT (1498cc)",
          "1.2 PETROL TREND+MT (1194cc)",
          "AMBIENTE 1.2 P MT (1194cc)",
          "1.2 TIVCT CNG TREND PLUS (CC 1194) (1194cc)",
          "1.2 TIVCT PETROL TITANIUM PLUS MT (CC 1194) (1194cc)",
          "1.2 TIVCT PETROL TITANIUM MT (CC1194) (1194cc)",
          "1.2 TIVCT PETROL AMBIENTE MT (CC 1194) (1194cc)",
          "1.2 TIVCT PETROL TREND PLUS MT (CC 1194) (1194cc)",
          "1.2 TIVCT PETROL TREND MT (CC 1194) (1194cc)",
          "AMBIENTE 1.2 TI-VCT BS-VI (1194cc)",
          "TITANIUM 1.2 TI-VCT BS-VI (1194cc)",
          "TITANIUM PLUS 1.2 TI-VCT BS-VI (1194cc)",
          "TITANIUM BLU 1.2 TI-VCT (1194cc)",
          "TREND 1.2 TI-VCT BS-VI (1194cc)",
          "AMBIENTE 1.2 TI-VCT (1194cc)",
          "TITANIUM PLUS 1.2 TI-VCT (1194cc)",
          "TREND 1.2 TI-VCT (1194cc)",
          "TITANIUM 1.2 TI-VCT (1194cc)",
          "AMBIENTE CNG (1194cc)",
          "TREND PLUS CNG (1194cc)",
          "TREND PLUS 1.2 TI-VCT (1194cc)",
          "1.5 TIVCT PETROL TITANIUM AT (CC 1497) (1497cc)",
          "TITANIUM 1.5 TI-VCT AT (1497cc)",
          "1.5 D TIT MT BSIV (1248cc)",
        ],
      },
      {
        model: "LINCOLN",
        variants: [
          "CONTINENTAL (4000cc)",
          "TOWN CAR SIGNATURE 4600CC (4600cc)",
          "TOWN CAR SIGNATURE 4600CC (4600cc)",
          "TOWN CAR SIGNATURE 3800CC (3800cc)",
          "TOWN CAR SIGNATURE 3800CC (3800cc)",
        ],
      },
      {
        model: "FOCUS",
        variants: ["RALLYE SPORTS (2500cc)", "RS (2521cc)"],
      },
      {
        model: "EXPEDITION",
        variants: ["STD (2400cc)", "0 (2700cc)"],
      },
      {
        model: "GALAXY",
        variants: ["STANDARD (1600cc)"],
      },
      {
        model: "MINI BUS",
        variants: ["VAN (2200cc)"],
      },
      {
        model: "LASER",
        variants: ["1.6 GA (1598cc)"],
      },
      {
        model: "TEMPO",
        variants: ["3.0L 5STR (2982cc)"],
      },
      {
        model: "ESCAPE",
        variants: ["STD (2488cc)"],
      },
      {
        model: "EIGHT",
        variants: ["VINTAGE - 1937 (796cc)"],
      },
      {
        model: "FORD ESCORT",
        variants: ["0 (1500cc)"],
      },
      {
        model: "FORD FOCUS",
        variants: ["0 (2499cc)"],
      },
      {
        model: "CORTINA",
        variants: ["STANDARD (1500cc)"],
      },
      {
        model: "FALCON",
        variants: ["SX (2785cc)"],
      },
      {
        model: "JANSES",
        variants: ["STANDARD (1900cc)"],
      },
      {
        model: "PERFECT",
        variants: ["E93A (947cc)"],
      },
      {
        model: "WOODY",
        variants: ["STANDARD (1800cc)"],
      },
      {
        model: "METRO CAB",
        variants: ["STANDARD (2496cc)"],
      },
      {
        model: "FORD",
        variants: ["SPORT TRAC 4X4 (1596cc)"],
      },
    ],
  },
  {
    make: "HONDA",
    models: [
      {
        model: "CITY",
        variants: [
          "ZX CVT I-VTEC (1498cc)",
          "S (1497cc)",
          "V (1498cc)",
          "V (1497cc)",
          "V CVT (1497cc)",
          "1.5 IVTEC (1497cc)",
          "1.7 VXI (1697cc)",
          "1.5 VX (AE) (1498cc)",
          "VMT (1493cc)",
          "ZX V (1497cc)",
          "VX (1498cc)",
          "VX (1497cc)",
          "VX CVT (1497cc)",
          "ZX (1498cc)",
          "1.3 EXI S (1343cc)",
          "ZX G (1497cc)",
          "VX (O) (MT) (1497cc)",
          "VX O MT (1498cc)",
          "1.5 GXI (1493cc)",
          "GXI (1497cc)",
          "SV (1498cc)",
          "SV (1497cc)",
          "1.5 VTEC (1493cc)",
          "1.5 EXI AT (1493cc)",
          "1.3 EXI (1343cc)",
          "1.3 EXI (1343cc)",
          "1.3 DX (1497cc)",
          "1.3 DX (1343cc)",
          "1.3 DX (1343cc)",
          "1.3 LXI (1343cc)",
          "1.3  LXI (1343cc)",
          "1.5 EXI (1493cc)",
          "ZX VTEC PLUS (1497cc)",
          "ZX VTEC PLUS (1497cc)",
          "ZX EXI (1497cc)",
          "ZX GXI (1497cc)",
          "ZX VTEC (1497cc)",
          "5TH GEN ZX CVT PETROL BSVI (1498cc)",
          "5TH GEN ZX CVT PETROL BS-VI (1498cc)",
          "5TH GEN ZX CVT PETROL (1498cc)",
          "5TH GEN ZX PETROL (1498cc)",
          "5TH GEN ZX PETROL BS-VI (1498cc)",
          "5TH GEN ZX DIESEL BS-VI (1498cc)",
          "5TH GEN ZX CVT I VTEC (1498cc)",
          "5TH GEN ZX MT (I-VTEC) HC5GENZXMT (1498cc)",
          "5TH GEN ZX MT I-VTEC (1498cc)",
          "5TH GEN ZX SENSING EHEV (1498cc)",
          "1.5 I-DTEC ZX (1498cc)",
          "5TH GEN ZX MT BS6 (1498cc)",
          "HYBRID EHEV ZX (1498cc)",
          "1.5 ZX MT I DTEC (1498cc)",
          "HYBRID ZX EHEV (1498cc)",
          "1.5 ZX CVT EHEV-HYBRID (1498cc)",
          "ZX 5TH GEN CVT I-VTEC (1498cc)",
          "ZX 5TH GEN MT I-VTEC (1498cc)",
          "ZX CVT PETROL (1498cc)",
          "ZX MT PETROL (1498cc)",
          "ZX DIESEL (1498cc)",
          "1.5 SV MT ( I VTEC ) (1497cc)",
          "1.5 V (1497cc)",
          "1.5 S (1497cc)",
          "1.5 E (1497cc)",
          "1.5 V MANUAL TRANSMISSION CNG. (1497cc)",
          "1.5 E MT (I VTEC) (1497cc)",
          "1.5 S MT ELEGANCE (1497cc)",
          "1.5 EXI NEW (1497cc)",
          "1.5 V MT (I-VTEC) (1497cc)",
          "1.5 VX MT I VETC (1497cc)",
          "1.5 V CVT (I-VTEC) (1497cc)",
          "1.5 VX CVT I-VTEC (1497cc)",
          "1.5 SV CVT (I-VTEC) (1497cc)",
          "1.5 EXI-S (1497cc)",
          "1.5 V AT EXCLUSIVE (1497cc)",
          "1.5 V MT EXCLUSIVE (1497cc)",
          "1.5 V MT SUNROOF (1497cc)",
          "1.5 S MT INSPIRE (1497cc)",
          "1.5 V MT ELEGANCE (1497cc)",
          "1.5 V MT INSPIRE (1497cc)",
          "1.5 GXI (1497cc)",
          "1.5 GXI (1497cc)",
          "ZX 1.5 EXI (1497cc)",
          "2008 1.5 S AT (1497cc)",
          "2008 1.5 S MT (1497cc)",
          "2008 1.5 V MT (1497cc)",
          "2008 1.5 E MT (1497cc)",
          "2008 1.5 V AT (1497cc)",
          "1.5 E MT (1497cc)",
          "1.5 V MT CNG (1497cc)",
          "ZX 1.5 GXI CVT (1497cc)",
          "1.5 V AT LEATHER GRADE (1497cc)",
          "ZX GXI MT (1497cc)",
          "GXI CVT (1497cc)",
          "EXI (1497cc)",
          "1.5 VX (O) (1497cc)",
          "1.5 V AT (1497cc)",
          "1.5 S MT (1497cc)",
          "1.5 GXI CVT (1497cc)",
          "1.5 V MT (1497cc)",
          "1.5 S AT (1497cc)",
          "1.5 V AT SUNROOF (1497cc)",
          "1.5 EXI AT (1497cc)",
          "1.5 EXI (1497cc)",
          "1.5 EXI (1497cc)",
          "ZX CVT (1497cc)",
          "ZX CVT (1497cc)",
          "ZX CVT (1497cc)",
          "1.5 VITEC (1493cc)",
          "1.5 EXI AUTO TRANSMISSION (1493cc)",
          "VX O MT DIESEL (1498cc)",
          "1.5 E MT ( I-DTEC) (1498cc)",
          "ZXI AT (1296cc)",
          "1.3 EXI (1298cc)",
          "1.3 DX (1298cc)",
          "1.3 LXI (1298cc)",
          "1.3 GXI (1343cc)",
          "VTEC (1343cc)",
          "V AT (1493cc)",
          "EXI S 1.5 (1493cc)",
          "1.5 EXI-S (1493cc)",
          "ZX (1493cc)",
          "ZX 1.5 GXI CVT (1493cc)",
          "ZX 1.5 V TEC (1493cc)",
          "1.5 GXI CVT (1493cc)",
          "1.5 (1497cc)",
          "1.5 SV MT I VTEC (1497cc)",
          "ZX VTEC MT (1497cc)",
          "1.5 LXI (1497cc)",
          "I VTEC VX OPTION (1497cc)",
          "I VTEC CVT VX (1497cc)",
          "LXI (1497cc)",
          "V AT (1497cc)",
          "1.5 ZXI (1497cc)",
          "I VTEC S (1497cc)",
          "EMT (1497cc)",
          "S MT (1497cc)",
          "ZX PETROL (1497cc)",
          "VX MT I-VTEC (1497cc)",
          "1.5 ZX CVT I-VTEC AT (1497cc)",
          "VTI + MT (1497cc)",
          "ZX MT (1497cc)",
          "ZX CVT BS6 (1497cc)",
          "ZX CVT PETROL BS-VI (1497cc)",
          "VX CVT PETROL BS-VI (1497cc)",
          "V CVT PETROL BS-VI (1497cc)",
          "VX PETROL BS-VI (1497cc)",
          "ZX PETROL BS-VI (1497cc)",
          "V PETROL BS-VI (1497cc)",
          "SV PETROL BS-VI (1497cc)",
          "V MT CNG (1497cc)",
          "SV PETROL EDGE EDITION (1497cc)",
          "1.5 S MT (BS-IV) CNG (1497cc)",
          "V MT CNG COMPATIBLE (1497cc)",
          "4TH GENERATION V MT (1497cc)",
          "1.5 ZX CVT (I-VTEC) (1497cc)",
          "V AT AVN (1497cc)",
          "SV CVT (1497cc)",
          "E (1497cc)",
          "CORPORATE (1497cc)",
          "SV MT PETROL (1497cc)",
          "V MT PETROL (1497cc)",
          "E MT PETROL (1497cc)",
          "SV CVT PETROL (1497cc)",
          "S MT PETROL (1497cc)",
          "VX CVT PETROL (1497cc)",
          "VX MT PETROL (1497cc)",
          "SV PETROL (1497cc)",
          "1.5 S MT CNG (1497cc)",
          "V PETROL (1497cc)",
          "VX PETROL (1497cc)",
          "1.5 VX MT (DIESEL) (1497cc)",
          "1.5 V MT DIESEL (1497cc)",
          "VX (O) MT PETROL (1497cc)",
          "IVTECH (1497cc)",
          "ZX CVT PETROL (1497cc)",
          "ZX MT PETROL (1497cc)",
          "V CVT PETROL (1497cc)",
          "S PETROL (1497cc)",
          "ANNIVERSARY EDITION PETROL (1497cc)",
          "1.5 CORPORATE MT (1497cc)",
          "V MT AVN (1497cc)",
          "5TH GEN V DIESEL BS6 (1498cc)",
          "5TH GEN VX MT (1498cc)",
          "5TH GEN V CVT I-VTEC BSVI (1498cc)",
          "5TH GEN 1.5 E-HEV (1498cc)",
          "5TH GEN V DIESEL BS-VI (1498cc)",
          "5TH GEN VX DIESEL BS-VI (1498cc)",
          "5TH GEN V CVT PETROL BS-VI (1498cc)",
          "5TH GEN V CVT PETROL (1498cc)",
          "5TH GEN V PETROL (1498cc)",
          "5TH GEN VX CVT PETROL (1498cc)",
          "5TH GEN VX PETROL (1498cc)",
          "5TH GEN VX PETROL BS-VI (1498cc)",
          "5TH GEN VX CVT I-VTEC (1498cc)",
          "5TH GEN VMT (1498cc)",
          "5TH GEN V CVT I-VTEC (1498cc)",
          "5TH GEN VX CVT PETROL BS-VI (1498cc)",
          "5TH GEN V PETROL BSVI (1498cc)",
          "5TH GEN V PETROL BS-VI (1498cc)",
          "5TH GEN SV PETROL (1498cc)",
          "VX OPTION (1498cc)",
          "V I-DTECH (1498cc)",
          "I DTEC SV MT (1498cc)",
          "1.5 I-DTEC S MT (1498cc)",
          "VX MT (1498cc)",
          "1.5 I D TEC V (1498cc)",
          "V MT PETROL BS6 (1498cc)",
          "1.5 V DIESEL (1498cc)",
          "HYBRID EHEV V (1498cc)",
          "1.5 I DTEC VX (1498cc)",
          "1.5 S I-DTEC MT (1498cc)",
          "SV DIESEL EDGE EDITION (1498cc)",
          "1.5 VX MT (I-DTEC) (1498cc)",
          "1.5 VX MT (1498cc)",
          "1.5 SMT I DTEC (1498cc)",
          "1.5 V MT (I-DTEC) (1498cc)",
          "E DIESEL (1498cc)",
          "S DIESEL (1498cc)",
          "S MT DIESEL (1498cc)",
          "E MT DIESEL (1498cc)",
          "VX MT DIESEL (1498cc)",
          "V MT DIESEL (1498cc)",
          "SV MT DIESEL (1498cc)",
          "V DIESEL (1498cc)",
          "VX DIESEL (1498cc)",
          "ANNIVERSARY EDITION DIESEL (1498cc)",
          "1.5 V MT (1498cc)",
          "SV DIESEL (1498cc)",
          "ZX 1.5 GXI (1540cc)",
          "1.6 ZXI (1597cc)",
          "1.7 EXI (1697cc)",
        ],
      },
      {
        model: "ACCORD",
        variants: [
          "VTI-L (MT) (2400cc)",
          "VTI-L MT (2354cc)",
          "VTI L MT (2354cc)",
          "VTI-L AT (2354cc)",
          "VTI L AT (2354cc)",
          "V6 AT (2997cc)",
          "V6 AT (2997cc)",
          "1.6 AT (1596cc)",
          "2.4 (MT) INSPIRE (2354cc)",
          "3.5 V6 (AT) (3471cc)",
          "BASIC (2490cc)",
          "2.3 VTI AT (2254cc)",
          "2.3 VTI MT (2254cc)",
          "1.8 MT (1850cc)",
          "2.4 INSPIRE AT (2354cc)",
          "2.4 ELEGANCE MT (2354cc)",
          "3.5 V6 INSPIRE (3471cc)",
          "2.4 MT (2354cc)",
          "2.4 AT (2354cc)",
          "2.4 ELEGANCE AT (2354cc)",
          "3.5 V6 (3471cc)",
          "3.5 V6 (3471cc)",
          "1.6 ELIGANCE AT (1596cc)",
          "2.4 MANUAL TRANSMISSION (2354cc)",
          "2.4 AUTO TRANSMISSION (2354cc)",
          "V6 AUTO TRANSMISSION (2997cc)",
          "VTI-L MANUAL TRANSMISSION (2354cc)",
          "VTI-L AUTO TRANSMISSION (2354cc)",
          "2.3 VTI MANUAL TRANSMISSION (2254cc)",
          "2.3 VTI AUTO TRANSMISSION (2254cc)",
          "2.4 AUTO TRANSMISSION INSPIRE (2354cc)",
          "HYBRID (1993cc)",
          "2.4 VTI L (IVTEC) AT (2354cc)",
          "2.4 VTIL (IVTEC) MT (2354cc)",
          "INSPIRE AT (2354cc)",
          "INSPIRE AT (2354cc)",
          "ELEGANCE MT (2354cc)",
          "ELEGANCE MT (2354cc)",
          "INSPIRE MT (2354cc)",
          "INSPIRE MT (2354cc)",
          "ELEGANCE AT (2354cc)",
          "ELEGANCE AT (2354cc)",
          "2.4 MANUAL TRANSMISSION INSPIRE (2354cc)",
          "2.3 VTI-L AT (2354cc)",
          "2.4 I VTEC AT (2354cc)",
          "2.3 VTI-L MT (2354cc)",
          "2.4 I VTEC MT (2354cc)",
          "2.4 INSPIRE MT (2354cc)",
          "2.3 VTI AT (2354cc)",
          "2.3 VTI MT (2354cc)",
          "1.8 MT (2354cc)",
          "2008 2.4 ELEGANCE MT (2354cc)",
          "2008 2.4 INSPIRE AT (2354cc)",
          "2008 2.4 MT (2354cc)",
          "2008 INSPIRE MT (2354cc)",
          "2008 2.4 AT (2354cc)",
          "2008 2.4 ELEGANCE AT (2354cc)",
          "2.4 VTI-L AT (2354cc)",
          "2.4 VTI-L MT (2354cc)",
          "2.4 VTI L MT (2354cc)",
          "3.0 V6 AT (2354cc)",
          "V6 INSPIRE (3471cc)",
          "STD MT (3471cc)",
          "2008 3.5 V6 (3471cc)",
          "2.0 HYBRID (1993cc)",
          "1.8 MT (1798cc)",
          "1.6 AUTOMATIC TRANSMISSION (1596cc)",
          "2.3 VTI L (2224cc)",
          "2.3 VTI L (2254cc)",
          "3.0 V6 AT (2997cc)",
        ],
      },
      {
        model: "CR-V",
        variants: [
          "2.4 4WD MT (2354cc)",
          "2.0 RVI AT (1995cc)",
          "2.0 RVI MT (1995cc)",
          "2.4 RVSI AT (2354cc)",
          "2.4 RVSI MT (2354cc)",
          "2.4 RVI AT (2354cc)",
          "2.4 4WD AT (2354cc)",
          "2.0 2WD AT (1997cc)",
          "SPECIAL EDITION (1997cc)",
          "2.4L 2WD (2354cc)",
          "2.4 4WD AVN (AT) (2354cc)",
          "DOHC I-VTEC (2354cc)",
          "SOHC I-VTEC (1997cc)",
          "1.6 2WD 9AT (1597cc)",
          "1.6 4WD 9AT (1597cc)",
          "2.0 2WD CVT (1997cc)",
          "BASIC (1497cc)",
          "2.4 RVI MT (2354cc)",
          "2.0 2WD (1997cc)",
          "2.0 2WD (1997cc)",
          "2.0L 2WD (1997cc)",
          "2.4L 4WD MANUAL TRANSMISSION (2354cc)",
          "2.4L 4WD AUTO TRANSMISSION (2354cc)",
          "2.4 RVSI MANUAL TRANSMISSION (2354cc)",
          "2.4 RVSI AUTO TRANSMISSION (2354cc)",
          "2.0L 2WD AUTO TRANSMISSION (1997cc)",
          "2.4L 4WD AUTO TRANSMISSION AVN (2354cc)",
          "2.0 RVI MANUAL TRANSMISSION (1995cc)",
          "2.4 RVI AUTO TRANSMISSION (2354cc)",
          "2.0 RVI AUTO TRANSMISSION (1995cc)",
          "2.4 RVI MANUAL TRANSMISSION (2354cc)",
          "2 (800.8cc)",
          "AT (2354cc)",
          "M/T 2.4 (2354cc)",
          "RVSI MT 5 STR (2354cc)",
          "2.4 AT 7STR (2354cc)",
          "2.4L 4WD AT (2354cc)",
          "2.0 AT (2354cc)",
          "2007 2.4 AT (2354cc)",
          "2007 2.4 MT (2354cc)",
          "RVI AT (2354cc)",
          "RVI AT (2354cc)",
          "RVI MT (2354cc)",
          "RVI MT (2354cc)",
          "2.4L AT AVN (2354cc)",
          "4WD AT (2354cc)",
          "IVTEC MT (2354cc)",
          "IVTEC AT (2354cc)",
          "RVSI MT (2354cc)",
          "RVSI MT (2354cc)",
          "RVSI AT (2354cc)",
          "RVSI AT (2354cc)",
          "2.4 MT (2354cc)",
          "2.4 AT (2354cc)",
          "2.4 AT (2354cc)",
          "2.4L 4WD AVN (2354cc)",
          "1.6 2WD DIESEL AT (1597cc)",
          "1.6 2WD DIESEL AT 7 STR (1597cc)",
          "1.6 4WD AUTO TRANSMISSION I-DTEC DIESEL (1597cc)",
          "1.6 2WD AUTO TRANSMISSION I-DTEC DIESEL (1597cc)",
          "1.6 9AT  I-DTEC DIESEL (1597cc)",
          "1.6 9AT (I-DTEC) 5 STR (1597cc)",
          "1.6 9AT (I-DTEC) 7 STR (1597cc)",
          "2WD DIESEL AT (1597cc)",
          "AWD DIESEL AT (1597cc)",
          "1.6 9AT DIESEL (1597cc)",
          "1.6 AWD AT DIESEL (1597cc)",
          "DIESEL (1596cc)",
          "2.0 CVT CR (1997cc)",
          "2.0 CVT (1997cc)",
          "2WD PETROL CVT (1997cc)",
          "SPECIAL EDITION 2WD PETROL CVT (1997cc)",
          "2.0 CVT  I VTEC (1997cc)",
          "2.0L 2WD AT (1997cc)",
          "2.0 AT (1997cc)",
          "2WD MT (1997cc)",
          "2007 2.0 2WD (1997cc)",
          "2.0L AT (1997cc)",
          "2.0 CVT PETROL (1997cc)",
          "2.0L MT (1997cc)",
          "2.0L 2WD MT (1997cc)",
          "STD (3179cc)",
          "RVSI MT (1998cc)",
        ],
      },
      {
        model: "CONCERTO",
        variants: [
          "1.5 (1493cc)",
          "1.5 I (1493cc)",
          "EX (2354cc)",
          "1.6I (1590cc)",
        ],
      },
      {
        model: "CIVIC",
        variants: [
          "HYBRID 1.3 I-VTEC (1339cc)",
          "1.8 S ELEGANCE (MT) (1799cc)",
          "1.8 S INSPIRE (MT) (1799cc)",
          "1.8 V (AT) WITH SUNROOF (1799cc)",
          "1.8 V ELEGANCE (AT) (1799cc)",
          "1.8 V INSPIRE (AT) (1799cc)",
          "1.8 V SUNROOF (MT) (1799cc)",
          "1.8 V ELEGANCE (MT) (1799cc)",
          "1.8 V INSPIRE (MT) (1799cc)",
          "1.6 VX MT (1597cc)",
          "1.6 ZX MT (1597cc)",
          "1.8 V CVT (1799cc)",
          "1.8 VX CVT (1799cc)",
          "1.8 ZX CVT (1799cc)",
          "1.5 L (1493cc)",
          "1.8 S MT (1799cc)",
          "1.8 V AT (1799cc)",
          "1.8 V AT (1799cc)",
          "1.8 V MT (1799cc)",
          "1.8 S AT (1799cc)",
          "1.8 E MT (1799cc)",
          "SPORT (1799cc)",
          "1.8 V MT INSPIRE (1799cc)",
          "1.8 V MT ELEGANCE (1799cc)",
          "1.8 S MT ELEGANCE (1799cc)",
          "1.8 V AMT ELEGANCE (1799cc)",
          "1.8 V AMT INSPIRE (1799cc)",
          "1.8 V M TRANS WITH SUNROOF (1799cc)",
          "1.6 VX MT IDTEC (1597cc)",
          "1.8 V AUTO TRANS WITH SUNROOF (1799cc)",
          "1.8 E MANUAL TRANSMISSION (1799cc)",
          "1.8 V MANUAL TRANSMISSION (1799cc)",
          "1.8 V AUTO TRANSMISSION (1799cc)",
          "1.8 S MANUAL TRANSMISSION (1799cc)",
          "1.8 S AUTO TRANSMISSION (1799cc)",
          "1.6 ZX MT (I-DTEC) (1597cc)",
          "1.8 S MT INSPIRE (1799cc)",
          "HYBRID 1.3 L I-VTEC (1300cc)",
          "1.8 MT (17.399cc)",
          "1.8 S (1799cc)",
          "1.8 E (1799cc)",
          "MT V (1799cc)",
          "1.8 V AT ELEGANCE (1799cc)",
          "1.8 V AT INSPIRE (1799cc)",
          "1.8 AT (1799cc)",
          "1.8 V MANUAL TRANS WITH SUNROOF (1799cc)",
          "1.8 S MANUAL TRANSMISSION ELEGANCE (1799cc)",
          "1.8 S MANUAL TRANSMISSION INSPIRE (1799cc)",
          "1.8 V MANUAL TRANSMISSION ELEGANCE (1799cc)",
          "1.8 V MANUAL TRANSMISSION INSPIRE (1799cc)",
          "1.8 V AUTO TRANSMISSION ELEGANCE (1799cc)",
          "1.8 V AUTO TRANSMISSION INSPIRE (1799cc)",
          "1.8 ZX CVT (I-VTEC) (1799cc)",
          "MT (1799cc)",
          "AT (1799cc)",
          "VX CVT PETROL (1799cc)",
          "ZX CVT PETROL (1799cc)",
          "1.8 V AT SUNROOF (1799cc)",
          "1.8 V MT SUNROOF (1799cc)",
          "V CVT PETROL (1799cc)",
          "1.8 SPORT (1796cc)",
          "ZX (1497cc)",
          "FERIO (1600cc)",
          "1.6 VX DIESEL (1597cc)",
          "1.6 ZX DIESEL (1597cc)",
          "VX DIESEL (1597cc)",
          "ZX DIESEL (1597cc)",
          "ZX MT DIESEL (1597cc)",
          "VX MT DIESEL (1597cc)",
          "HYBRID (1339cc)",
          "HYBRID (1339cc)",
          "HYBRID LIMITED EDITION (1339cc)",
          "HYBRID CVT (1339cc)",
          "HYBRID 1.3 I-VTEC (1300cc)",
        ],
      },
      {
        model: "JAZZ",
        variants: [
          "S CVT (1199cc)",
          "V CVT (1199cc)",
          "ZX (1199cc)",
          "ZX CVT (1199cc)",
          "1.2 VX MT (1199cc)",
          "V (1498cc)",
          "V (1199cc)",
          "E (1498cc)",
          "E (1199cc)",
          "VX (1498cc)",
          "VX (1199cc)",
          "SV (1498cc)",
          "SV (1199cc)",
          "1.2 ACTIVE (MT) (1198cc)",
          "1.2 MODE (MT) (1198cc)",
          "1.5 S MT IDTEC (1498cc)",
          "PROTOTYPE (1499cc)",
          "1.2 V CVT I VTEC (1199cc)",
          "S (1498cc)",
          "S (1199cc)",
          "1.2 X (1198cc)",
          "1.2 MT (1198cc)",
          "1.2 SELECT EDITION (1198cc)",
          "1.2 VX CVT(I-VTEC) (1199cc)",
          "1.5 VMT(I-DTEC) (1498cc)",
          "1.2 S MT (1198cc)",
          "1.5 VX MT IDTEC (1498cc)",
          "1.2 V MT I-VTEC (1199cc)",
          "1.2 VX MT (1198cc)",
          "1.2 V MT (1198cc)",
          "1.2 E MT (1198cc)",
          "1.2 XMT (1198cc)",
          "1.2 ZX CVT (1198cc)",
          "1.2 ZX MT (1198cc)",
          "1.5 SV MT (I-DTEC) (1498cc)",
          "1.5 E I DTEC (1498cc)",
          "V IDTEC PRIVILEGE (1498cc)",
          "1.5 E MT DIESEL (1498cc)",
          "1.5 S MT DIESEL (1498cc)",
          "1.5 SV MT DIESEL (1498cc)",
          "1.5 V MT DIESEL (1498cc)",
          "1.5 VX MT DIESEL (1498cc)",
          "PRIVILEGE EDITION DIESEL (1498cc)",
          "VX CVT PETROL EXCLUSIVE EDITION (1498cc)",
          "E DIESEL (1498cc)",
          "S DIESEL (1498cc)",
          "SV DIESEL (1498cc)",
          "V DIESEL (1498cc)",
          "VX DIESEL (1498cc)",
          "METALLIC VX MT (DIESEL) (1498cc)",
          "METALLIC V MT (DIESEL) (1498cc)",
          "METALLIC S MT (DIESEL) (1498cc)",
          "NON METALLIC SV MT (DIESEL) (1498cc)",
          "NON METALLIC E MT (DIESEL) (1498cc)",
          "NON METALLIC V MT (DIESEL) (1498cc)",
          "NON METALLIC S MT (DIESEL) (1498cc)",
          "NON METALLIC VX MT (DIESEL) (1498cc)",
          "METALLIC SV MT (DIESEL) (1498cc)",
          "METALLIC E MT (DIESEL) (1498cc)",
          "VTI (1497cc)",
          "1.5 VX MT (I-DTEC) (1497cc)",
          "1.2 ACTIVE (1198cc)",
          "1.2 MODE (1198cc)",
          "V CVT PETROL BS-VI (1198cc)",
          "S CVT (I-VTEC) (1198cc)",
          "1.2 MT MODE (1198cc)",
          "1.2 MT ACTIVE (1198cc)",
          "1.2 S (1198cc)",
          "1.2 PRIVILEGE EDITION AT PETROL (1198cc)",
          "1.2 E MT PETROL (1198cc)",
          "1.2 S MT PETROL (1198cc)",
          "1.2 SV MT PETROL (1198cc)",
          "1.2 V MT PETROL (1198cc)",
          "1.2 S CVT PETROL (1198cc)",
          "1.2 VX MT PETROL (1198cc)",
          "1.2 VX CVT PETROL (1198cc)",
          "SELECT (1198cc)",
          "S PETROL (1198cc)",
          "1.2 V CVT (1198cc)",
          "VX PETROL (1198cc)",
          "S AT PETROL. (1198cc)",
          "S AT PETROL (1198cc)",
          "V PETROL (1198cc)",
          "V AT PETROL (1198cc)",
          "E PETROL (1198cc)",
          "SV PETROL (1198cc)",
          "1.2 V CVT PETROL (1198cc)",
          "S (1198cc)",
          "ACTIVE (1198cc)",
          "X (1198cc)",
          "MODE (1198cc)",
          "SELECT EDITION (1198cc)",
          "BASE (1198cc)",
          "1.2 V I VTEC (1199cc)",
          "1.2 V I VTEC (1199cc)",
          "VX MT (1199cc)",
          "V CVT PRIVILEGE EDITION (1199cc)",
          "V IVTEC PRIVILEGE AT (1199cc)",
          "1.2 SV I VTEC (1199cc)",
          "1.2 S I VTEC (1199cc)",
          "1.2 V AT I VTEC (1199cc)",
          "1.2 S AT I VTEC (1199cc)",
          "1.2 SV MT PETROL ( 1199 CC ) (1199cc)",
          "1.2 V MT PETROL 1199CC (1199cc)",
          "1.2 VX MT PETROL 1199CC (1199cc)",
          "1.2 V CVT PETROL 1199CC (1199cc)",
          "1.2 ZX MT PETROL 1199CC (1199cc)",
          "1.2 VX CVT PETROL 1199CC (1199cc)",
          "1.2 ZX CVT PETROL 1199CC (1199cc)",
          "1.2 VX CVT PETROL (1199cc)",
          "VX AT PETROL (1199cc)",
          "VX CVT PETROL (1199cc)",
          "PRIVILEGE EDITION AT PETROL (1199cc)",
          "PRIVILEGE EDITION PETROL (1199cc)",
          "1.2 VX MT IVTEC BSIV (1199cc)",
          "1.2 S CVT(I-VTEC) (1199cc)",
          "VX PETROL (1199cc)",
          "ZX PETROL (1199cc)",
          "ZX CVT PETROL (1199cc)",
          "1.2 SV MT IVTEC (1199cc)",
          "V PETROL (1199cc)",
          "V AT PETROL (1199cc)",
          "1.2 V CVT PETROL (1199cc)",
          "1.2 ZX CVT (1199cc)",
          "1.2 ZX MT (1199cc)",
          "NON METALLIC S CVT (1199cc)",
          "NON METALLIC V CVT (1199cc)",
          "NON METALLIC SV MT (PETROL) (1199cc)",
          "NON METALLIC S MT (PETROL) (1199cc)",
          "NON METALLIC E MT (PETROL) (1199cc)",
          "NON METALLIC V MT (PETROL) (1199cc)",
          "NON METALLIC VX MT (PETROL) (1199cc)",
          "SE 1.4 (1339cc)",
          "ACTIVE (1349cc)",
        ],
      },
      {
        model: "BR-V",
        variants: [
          "S (1498cc)",
          "S (1497cc)",
          "V (1498cc)",
          "V (1497cc)",
          "V CVT (1497cc)",
          "VX (1498cc)",
          "VX (1497cc)",
          "E (1498cc)",
          "E (1497cc)",
          "1.5 S PETROL (1497cc)",
          "1.5 V CVT I-VTEC (1497cc)",
          "1.5 S MT (1497cc)",
          "1.5 V MT (1497cc)",
          "PRT 1.5 V MT (1497cc)",
          "1.5 VX MT (1497cc)",
          "1.5 E MT IDTEC (1497cc)",
          "1.5 S MT IDTEC (1497cc)",
          "1.5 VX MT IDTEC (1497cc)",
          "1.5 E MT (1497cc)",
          "1.5 V MT IDTEC (1497cc)",
          "V I-VTEC PETROL (1498cc)",
          "VX I-VTEC PETROL (1498cc)",
          "I-DTEC V MT (1498cc)",
          "I-DTEC VX MT (1498cc)",
          "I-DTEC S MT (1498cc)",
          "I-DTEC E MT (1498cc)",
          "1.5 E DIESEL (1498cc)",
          "1.5 S DIESEL (1498cc)",
          "1.5 V DIESEL (1498cc)",
          "1.5 VX DIESEL (1498cc)",
          "VX DIESEL STYLE EDITION (1498cc)",
          "V DIESEL STYLE EDITION (1498cc)",
          "S DIESEL STYLE EDITION (1498cc)",
          "1.5 V MT (I-DTEC) (1498cc)",
          "S MT DIESEL STYLE EDITION (1498cc)",
          "V MT DIESEL STYLE EDITION (1498cc)",
          "VX MT DIESEL STYLE EDITION (1498cc)",
          "E DIESEL (1498cc)",
          "S DIESEL (1498cc)",
          "V DIESEL (1498cc)",
          "VX DIESEL (1498cc)",
          "I-VTEC V AT (1497cc)",
          "I-VTEC S MT (1497cc)",
          "I-VTEC E MT (1497cc)",
          "I-VTEC V MT (1497cc)",
          "I-VTEC VX MT (1497cc)",
          "I-VTEC V CVT (1497cc)",
          "S PETROL 5 STR (1497cc)",
          "1.5 V CVT PETROL (1497cc)",
          "1.5 E PETROL (1497cc)",
          "1.5 V PETROL (1497cc)",
          "1.5 VX PETROL (1497cc)",
          "1.5 CVT PETROL (1497cc)",
          "VX PETROL STYLE EDITION (1497cc)",
          "V PETROL STYLE EDITION (1497cc)",
          "S PETROL STYLE EDITION (1497cc)",
          "V MT PETROL STYLE EDITION (1497cc)",
          "VX MT PETROL STYLE EDITION (1497cc)",
          "S MT PETROL STYLE EDITION (1497cc)",
          "V CVT PETROL STYLE EDITION (1497cc)",
          "E PETROL (1497cc)",
          "S PETROL (1497cc)",
          "V PETROL (1497cc)",
          "VX PETROL (1497cc)",
          "V CVT PETROL (1497cc)",
        ],
      },
      {
        model: "AMAZE",
        variants: [
          "1.5 S (1498cc)",
          "1.5 S (1498cc)",
          "1.2 SX (1199cc)",
          "1.2 SX (1198cc)",
          "1.2 SX (1198cc)",
          "1.2 E MT (1198cc)",
          "1.2 VX MT (1198cc)",
          "1.2 S AT (1198cc)",
          "1.2 S AT (1199cc)",
          "1.2 SX MT (1198cc)",
          "1.2 VX AT (1198cc)",
          "1.2 E (1199cc)",
          "1.2 S CVT (1199cc)",
          "1.2 EX MT (1198cc)",
          "1.2 S (1199cc)",
          "1.5 S MT (1498cc)",
          "1.5 SX MT (1498cc)",
          "1.5 E MT (1498cc)",
          "1.5 VX MT (1498cc)",
          "1.2 S PLUS (MT) (1198cc)",
          "1.2 VX (O) (MT) (1198cc)",
          "1.5 VX (O) (MT) (1498cc)",
          "1.5 E (1498cc)",
          "1.2 E (O) (1199cc)",
          "1.5 E (O) (1498cc)",
          "1.2 S (O) (1199cc)",
          "1.5 S (O) (1498cc)",
          "1.5 S CVT (1498cc)",
          "1.5 S CVT (O) (1498cc)",
          "1.2 VX (1199cc)",
          "1.2 VX (1198cc)",
          "1.2 V CVT (1199cc)",
          "1.5 V CVT (1498cc)",
          "1.5 V (MT) (1498cc)",
          "1.2 V (MT) (1199cc)",
          "1.2 S (O) CVT (1199cc)",
          "1.5 VX (MT) EXCLUSIVE EDTN (1498cc)",
          "1.2 VX (MT) EXCLUSIVE EDTN (1198cc)",
          "1.5 VX CVT (1498cc)",
          "1.2 E(O) MT (I-VTEC) (1198cc)",
          "1.2 E MT BSVI (1199cc)",
          "1.5 E MT BSVI (1498cc)",
          "MMC 1.2 S MT BSVI (1199cc)",
          "MMC 1.5 S MT BSVI (1498cc)",
          "MMC 1.2 S CVT BSVI (1199cc)",
          "MMC 1.2 VX MT BSVI (1199cc)",
          "MMC 1.5 VX MT BSVI (1498cc)",
          "MMC 1.2 VX CVT BSVI (1199cc)",
          "MMC 1.5 VX CVT BSVI (1498cc)",
          "1.5 EX MT (1498cc)",
          "1.2 VX CVT (1199cc)",
          "1.2 S MT (1198cc)",
          "1.2 SX MT I-VTEC (1198cc)",
          "1.2 S MT PLUS (P) (1198cc)",
          "1.2 SX MT (P) (1198cc)",
          "1.2 VX (O) MT (P) (1198cc)",
          "S CVT (1498cc)",
          "VX CVT (1199cc)",
          "V (1498cc)",
          "V (1199cc)",
          "1.5 SX MT (D) (1498cc)",
          "1.5 VX (O) MT (D) (1498cc)",
          "E (1199cc)",
          "E (OP) (1199cc)",
          "S (1199cc)",
          "S (OP) (1199cc)",
          "S CVT (OP) (1498cc)",
          "SX (1199cc)",
          "VX (1199cc)",
          "1.2 E MANUAL TRANSMISSION (1198cc)",
          "1.2 EX MANUAL TRANSMISSION (1198cc)",
          "1.2 S MANUAL TRANSMISSION (1198cc)",
          "1.2 VX AUTO TRANSMISSION (1198cc)",
          "1.2 VX MANUAL TRANSMISSION (1198cc)",
          "1.5 E MANUAL TRANSMISSION (1498cc)",
          "1.5 S MANUAL TRANSMISSION (1498cc)",
          "1.5 VX MANUAL TRANSMISSION (1498cc)",
          "1.5 EX MANUAL TRANSMISSION (1498cc)",
          "V AT (1199cc)",
          "V AT (1498cc)",
          "1.2 S AUTO TRANSMISSION PETROL (1198cc)",
          "1.5 VX (1498cc)",
          "V CVT (1498cc)",
          "ANNIVERSARY EDITION (1498cc)",
          "I-DTEC PRIVILEGE EDITION (1498cc)",
          "1.5 VX CVT DIESEL BS-VI (1498cc)",
          "1.5 V CVT DIESEL AT (1498cc)",
          "1.5 S CVT DIESEL AT (1498cc)",
          "EXCLUSIVE EDITION CVT DIESEL (1498cc)",
          "1.5 S MT I-DTEC (O) SPL (1498cc)",
          "1.5  DIESEL EXCLUSIVE EDITION (1498cc)",
          "1.5 V MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 V CVT AUTO TRANSMISSION DIESEL (1498cc)",
          "1.5 E (O) MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 S (O) MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 E MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 EX MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 S MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 VX MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 SX MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 VX CVT AUTO TRANSMISSION DIESEL (1498cc)",
          "1.5 S CVT AUTO TRANSMISSION DIESEL (1498cc)",
          "1.5 VX O MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 E I-DTEC BS-VI (1498cc)",
          "1.5 VX I-DTEC BS-VI (1498cc)",
          "1.5 V I-DTEC BS-VI (1498cc)",
          "1.5 VX MT I-DTEC M (1498cc)",
          "1.5 V AT I-DTEC BS-VI (1498cc)",
          "1.5 S AT I-DTEC BS-VI (1498cc)",
          "1.5 S I-DTEC BS-VI (1498cc)",
          "1.5 S I-DTEC O (1498cc)",
          "ACE EDITION DIESEL (1498cc)",
          "ACE EDITION CVT DIESEL (1498cc)",
          "VX MT DIESEL EXCLUSIVE EDITION (1498cc)",
          "VX CVT DIESEL EXCLUSIVE EDITION (1498cc)",
          "1.5 V CVT (I-DTEC) (1498cc)",
          "1.5 V MT I-DTEC (1498cc)",
          "1.5 E I-DTEC (1498cc)",
          "1.5 EX I-DTEC (1498cc)",
          "1.5 S I-DTEC (1498cc)",
          "1.5 SX I-DTEC (1498cc)",
          "1.5 VX I-DTEC (1498cc)",
          "PRIVILEGE EDITION DIESEL (1498cc)",
          "1.5 VX CVT DIESEL. (1498cc)",
          "1.5 VX CVT DIESEL (1498cc)",
          "1.5 E I-DTEC O (1498cc)",
          "1.5 VX O I-DTEC (1498cc)",
          "1.5 S MT DIESEL SPECIAL EDITION (1498cc)",
          "1.5 S CVT DIESEL SPECIAL EDITION (1498cc)",
          "V CVT DIESEL (1498cc)",
          "S DIESEL (1498cc)",
          "1.5 VXMT I-DTEC (1498cc)",
          "VX MT ACE EDITION (D) (1498cc)",
          "VX CVT DIESEL (1498cc)",
          "VX CVT ACE EDITION (D) (1498cc)",
          "VX DIESEL (1498cc)",
          "EX DIESEL (1498cc)",
          "E DIESEL (1498cc)",
          "1.5 E I-DTEC OPT (1498cc)",
          "1.5 S I-DTEC OPT (1498cc)",
          "V MT DIESEL (1498cc)",
          "VX MT EXCLUSIVE EDITION (D) (1498cc)",
          "1.5 SXMT I-DTEC (1498cc)",
          "1.5 EXMT I-DTEC (1498cc)",
          "S CVT SPECIAL EDITION (D) (1498cc)",
          "1.5 V I-DTEC (1498cc)",
          "S MT SPECIAL EDITION (D) (1498cc)",
          "1.5 S AT I-DTEC (1498cc)",
          "1.5 V AT I-DTEC (1498cc)",
          "1.5 S MT I-DTEC (1498cc)",
          "1.5 E MT I-DTEC (1498cc)",
          "1.2 S (1198cc)",
          "I-VTEC PRIVILEGE EDITION (1198cc)",
          "S PLUS (1198cc)",
          "1.2 S CVT OPT (1198cc)",
          "1.2 E MT (O) I VTEC (1198cc)",
          "1.2 S CVT I VTEC AT (1198cc)",
          "1.2 E MANUAL TRANSMISSION PETROL (1198cc)",
          "1.2 EX MANUAL TRANSMISSION PETROL (1198cc)",
          "1.2 S MANUAL TRANSMISSION PETROL (1198cc)",
          "1.2 VX MANUAL TRANSMISSION PETROL (1198cc)",
          "1.2 VX AUTO TRANSMISSION PETROL (1198cc)",
          "1.2 S (O) MANUAL TRANSMISSION PRIVILAGE EDITION PETROL (1198cc)",
          "1.2 S PLUS MANUAL TRANSMISSION PETROL (1198cc)",
          "1.2 E (O) MANUAL TRANSMISSION PETROL (1198cc)",
          "1.2 S (O) MANUAL TRANSMISSION PETROL (1198cc)",
          "1.2 S (O) AUTO TRANSMISSION PETROL (1198cc)",
          "1.2 V MANUAL TRANSMISSION PETROL (1198cc)",
          "1.2 SX MANUAL TRANSMISSION PETROL (1198cc)",
          "1.2 S MANUAL TRANSMISSION PLUS PETROL (1198cc)",
          "1.2 VX O MANUAL TRANSMISSION PETROL (1198cc)",
          "PRIDE EDITION DIESEL (1198cc)",
          "PRIDE EDITION PETROL (1198cc)",
          "PRIVILEGE EDITION PETROL (1198cc)",
          "1.2L VX CVT (1198cc)",
          "1.2 VX MT (I-VTEC) BSIV (1198cc)",
          "1.2 EX I-VTEC (1198cc)",
          "1.2 SX I-VTEC (1198cc)",
          "1.2 VX O I-VTEC (1198cc)",
          "1.2 VX AT I-VTEC (1198cc)",
          "1.2 S PLUS I-VTEC (1198cc)",
          "1.2 VX CVT (1198cc)",
          "1.2 EX (1198cc)",
          "1.2 EXMT I-VTEC (1198cc)",
          "1.2 S (O ) MT I-VTEC (1198cc)",
          "1.2 S MT PLUS I-VTEC (1198cc)",
          "E PETROL (1198cc)",
          "1.2 VXMT I-VTEC (1198cc)",
          "S AT PETROL (1198cc)",
          "1.2 EMT I-VTEC (1198cc)",
          "EX PETROL (1198cc)",
          "VX AT PETROL (1198cc)",
          "VX PETROL (1198cc)",
          "S PETROL (1198cc)",
          "1.2 S I-VTEC (1198cc)",
          "1.2 E I-VTEC (1198cc)",
          "1.2 S MT IVTEC (1198cc)",
          "1.2 S AT I-VTEC (1198cc)",
          "1.2 VX I-VTEC (1198cc)",
          "SV MT (1199cc)",
          "V CVT (1199cc)",
          "1.2 V MT I VTEC (1199cc)",
          "1.2 S MT BSVI (1199cc)",
          "1.2 V CVT I VTEC AT (1199cc)",
          "1.2 VX MT BSVI (1199cc)",
          "1.2 S (OP) CVT PETROL (1199cc)",
          "1.2 V CVT AUTO TRANSMISSION PETROL (1199cc)",
          "1.2 S MT PETROL (1199 CC) (1199cc)",
          "1.2 E MT PETROL (1199 CC) (1199cc)",
          "1.2 V MT PETROL (1199 CC) (1199cc)",
          "1.2 VX MANUAL TRANSMISSION PETROL (CC 1199) (1199cc)",
          "1.2 VX I-VTEC BS-VI (1199cc)",
          "1.2 S AT I-VTEC O (1199cc)",
          "1.2 S I-VTEC BS-VI (1199cc)",
          "1.2 E I-VTEC BS-VI (1199cc)",
          "1.2 S CVT I-VTEC (1199cc)",
          "1.2 S AT I-VTEC BS-VI (1199cc)",
          "1.2 S CVT PETROL SPECIAL EDITION (1199cc)",
          "1.2 VX CVT PETROL BS-VI (1199cc)",
          "1.2 V I-VTEC BS-VI (1199cc)",
          "1.2 V AT I-VTEC BS-VI (1199cc)",
          "1.2 E I-VTEC O (1199cc)",
          "ACE EDITION PETROL (1199cc)",
          "ACE EDITION CVT PETROL (1199cc)",
          "VX MT PETROL EXCLUSIVE EDITION (1199cc)",
          "VX CVT PETROL EXCLUSIVE EDITION (1199cc)",
          "1.2 VX CVT (I-VTECH) (1199cc)",
          "MMC 1.2 VXCVT I-VTEC (1199cc)",
          "1.2 V MT (I-VTECH) (1199cc)",
          "MMC 1.2 VXCVT (I-DTEC) (1199cc)",
          "1.2 V CVT (I-VTECH) (1199cc)",
          "1.2 SX I-VTEC (1199cc)",
          "1.2 VX AT I-VTEC (1199cc)",
          "1.2 S I-VTEC O (1199cc)",
          "1.2 S MT PETROL SPECIAL EDITION (1199cc)",
          "1.2 S CVT PETROL. (1199cc)",
          "1.2 VX CVT PETROL. (1199cc)",
          "1.2 VX CVT PETROL (1199cc)",
          "1.2 S MT (1199cc)",
          "1.2 S I-VTEC (1199cc)",
          "1.2 S I -VTEC (1199cc)",
          "V CVT PETROL (1199cc)",
          "VX CVT PETROL (1199cc)",
          "1.2 V I-VTEC (1199cc)",
          "1.2 V AT I-VTEC (1199cc)",
          "S CVT PETROL (1199cc)",
          "VX MT EXCLUSIVE EDITION (P) (1199cc)",
          "S MT SPECIAL EDITION (P) (1199cc)",
          "S CVT SPECIAL EDITION (P) (1199cc)",
          "1.2 E I-VTEC (1199cc)",
          "1.2 S AT I-VTEC (1199cc)",
          "1.2 VX I-VTEC (1199cc)",
          "VX (Occ)",
        ],
      },
      {
        model: "BRIO",
        variants: [
          "1.2 S MT (1198cc)",
          "1.2 V MT (1198cc)",
          "1.2 VX MT (1198cc)",
          "1.2 VX AT (1198cc)",
          "1.2 E MT (1198cc)",
          "VX BL (MT) (1198cc)",
          "1.2 V AT (1198cc)",
          "1.2 EX MT (1198cc)",
          "NEW E (1198cc)",
          "NEW S (1198cc)",
          "NEW VX (1198cc)",
          "NEW VX (AT) (1198cc)",
          "1.2 S (OP)(AT) (1198cc)",
          "1.2 S (OP)(MT) (1198cc)",
          "VX BL AT (1198cc)",
          "1.2 S MT (O) (1198cc)",
          "1.2 S AT (O) (1198cc)",
          "COMFORT MT (1198cc)",
          "1.2 VX AUTOMATIC TRANSMISSION (1198cc)",
          "1.2 VX MANUAL TRANSMISSION (1198cc)",
          "1.2 V MANUAL TRANSMISSION (1198cc)",
          "1.2 E MANUAL TRANSMISSION (1198cc)",
          "1.2 EX MANUAL TRANSMISSION (1198cc)",
          "1.2 V AUTOMATIC TRANSMISSION (1198cc)",
          "V (1198cc)",
          "1.2 S OPTION MT (1198cc)",
          "1.2 S OPTION AT (1198cc)",
          "1.2 S MANUAL TRANSMISSION (1198cc)",
          "1.2 S (OP) MANUAL TRANSMISSION (1198cc)",
          "1.2 S (OP) AUTOMATIC TRANSMISSION (1198cc)",
          "1.2 VX BL AUTOMATIC TRANSMISSION (1198cc)",
          "1.2 VX BL MANUAL TRANSMISSION (1198cc)",
          "VX 1.2 MT I-VTEC (1198cc)",
          "EXCLUSIVE EDITION (1198cc)",
          "VX O AT (1198cc)",
          "VX O MT (1198cc)",
          "E (1198cc)",
          "S (1198cc)",
          "1.2 S O MT (1198cc)",
          "EX (1198cc)",
          "VX AT (1198cc)",
          "VX AT (1198cc)",
          "EX MT (1198cc)",
          "S MT (1198cc)",
          "V MT (1198cc)",
          "VX MT (1198cc)",
          "E MT (1198cc)",
          "S O MT (1198cc)",
          "V AT (1198cc)",
          "S O AT. (1198cc)",
          "S O AT (1198cc)",
        ],
      },
      {
        model: "WR-V",
        variants: [
          "S I-DTEC (MT) (1498cc)",
          "S I-VTEC (MT) (1199cc)",
          "VX I-VTEC (MT) (1199cc)",
          "VX I-DTEC (MT) (1498cc)",
          "S I-DTEC EDGE EDITION (1199cc)",
          "S I-VTEC (MT) ALIVE EDTN (1199cc)",
          "S I-DTEC (MT) ALIVE EDTN (1498cc)",
          "VX I-VTEC (MT) EXCLUSIVE EDTN (1199cc)",
          "VX I-DTEC (MT) EXCLUSIVE EDTN (1498cc)",
          "V I-DTEC (MT) (1498cc)",
          "SV I-VTEC (MT) BSVI (1199cc)",
          "SV I-DTEC (MT) BSVI (1498cc)",
          "VX I-VTEC (MT) BSVI (1199cc)",
          "VX I-DTEC (MT) BSVI (1498cc)",
          "S MT I-DTEC (1498cc)",
          "S MT I-VTEC (1199cc)",
          "VX MT I-VTEC (1199cc)",
          "VX MT I-DTEC (1498cc)",
          "1.2 SV MT (1199cc)",
          "1.5 SV MT IDTEC (1498cc)",
          "1.5 V MT IDTEC (1498cc)",
          "I VTEC VX MT (1199cc)",
          "I-VTEC VX (1199cc)",
          "I-VTEC S (1199cc)",
          "S EDGE EDITION (1199cc)",
          "1.2 S EDGE EDITION MT PETROL (1199cc)",
          "1.2 SV MT PETROL (1199cc)",
          "1.2 S MT PETROL (1199cc)",
          "1.2 VX MT PETROL (1199cc)",
          "EDGE EDITION PETROL (1199cc)",
          "S PETROL ALIVE EDITION (1199cc)",
          "1.2 VX MT (I-VTEC) (1199cc)",
          "S MT PETROL EDGE+ EDITION (1199cc)",
          "VX MT PETROL EXCLUSIVE EDITION (1199cc)",
          "S MT EDGE EDITION PETROL (1199cc)",
          "S MT ALIVE EDITION (P) (1199cc)",
          "VX MT EXCLUSIVE EDITION (P) (1199cc)",
          "S MT EDGE PLUS EDITION (P) (1199cc)",
          "S MT PETROL (1199cc)",
          "VX MT PETROL (1199cc)",
          "SV MT PETROL (1199cc)",
          "I DTEC S MT (1498cc)",
          "I DTEC VX MT (1498cc)",
          "S EDGE EDITION (1498cc)",
          "I-DTEC VX (1498cc)",
          "I-DTEC S (1498cc)",
          "1.5 S EDGE EDITION MT DIESEL (1498cc)",
          "1.5 V MT DIESEL (1498cc)",
          "1.5 SV MT DIESEL (1498cc)",
          "1.5 S MT DIESEL (1498cc)",
          "1.5 VX MT DIESEL (1498cc)",
          "EDGE EDITION DIESEL (1498cc)",
          "S DIESEL ALIVE EDITION (1498cc)",
          "1.5 VX MT (I-DTEC) BSIV (1498cc)",
          "SV MT (1498cc)",
          "VX MT DIESEL EXCLUSIVE EDITION (1498cc)",
          "S MT DIESEL EDGE+ EDITION (1498cc)",
          "S MT EDGE EDITION DIESEL (1498cc)",
          "S MT EDGE PLUS EDITION (D) (1498cc)",
          "S MT ALIVE EDITION (D) (1498cc)",
          "VX MT EXCLUSIVE EDITION (D) (1498cc)",
          "S MT DIESEL (1498cc)",
          "VX MT DIESEL (1498cc)",
          "SV MT DIESEL (1498cc)",
          "V MT DIESEL (1498cc)",
        ],
      },
      {
        model: "MOBILIO",
        variants: [
          "1.5 E I-VTEC (1497cc)",
          "1.5 S I-DTEC (1498cc)",
          "1.5 S I-VTEC (1497cc)",
          "1.5 V I-VTEC (1497cc)",
          "1.5 V (O) I-VTEC (1497cc)",
          "1.5 E I-DTEC (1498cc)",
          "1.5 RS I-DTEC (1498cc)",
          "1.5 V I-DTEC (1498cc)",
          "1.5 RS (O) I-DTEC (1498cc)",
          "1.5 V (O) I-DTEC (1498cc)",
          "1.5 E  I-VTEC PETROL (1497cc)",
          "1.5 S  I-VTEC PETROL (1497cc)",
          "1.5 V  I-VTEC PETROL (1497cc)",
          "1.5 E I-DTEC DIESEL (1498cc)",
          "1.5 S I-DTEC DIESEL (1498cc)",
          "1.5 V I-DTEC DIESEL (1498cc)",
          "1.5 RS I-DTEC DIESEL (1498cc)",
          "1.5 E (1497cc)",
          "V OPTION (1497cc)",
          "1.5 S (1497cc)",
          "1.5 V (1497cc)",
          "1.5 V MT 5 STR (1497cc)",
          "1.5 S MT (I VTEC) (1497cc)",
          "1.5 V (O) I-VTEC PETROL (1497cc)",
          "E PETROL (1497cc)",
          "S PETROL (1497cc)",
          "V O PETROL (1497cc)",
          "V PETROL (1497cc)",
          "V (1497cc)",
          "V I-VTEC (1497cc)",
          "E I-VTEC (1497cc)",
          "V (O) I-VTEC (1497cc)",
          "S IVTEC (1497cc)",
          "1.5 E (1498cc)",
          "1.5 S (1498cc)",
          "1.5 V (1498cc)",
          "RS OPTION (1498cc)",
          "1.5 RS (1498cc)",
          "V OPTION I DTEC (1498cc)",
          "1.5 RS MT 5 STR (1498cc)",
          "1.5 VMT (1498cc)",
          "1.5 V (O) I-DTEC DIESEL (1498cc)",
          "1.5 RS (O) I-DTEC DIESEL (1498cc)",
          "1.5 RS MT (IDTEC) (1498cc)",
          "1.5 V MT I DTEC (1498cc)",
          "E DIESEL (1498cc)",
          "RS O DIESEL (1498cc)",
          "RS DIESEL (1498cc)",
          "S DIESEL (1498cc)",
          "V O DIESEL (1498cc)",
          "V DIESEL (1498cc)",
          "S. (1498cc)",
          "RS (O) I-DTEC (1498cc)",
          "V I-DTEC (1498cc)",
          "E I-DTEC (1498cc)",
          "S I-DTEC (1498cc)",
          "V (O) I-DTEC (1498cc)",
          "RS I-DTEC (1498cc)",
        ],
      },
      {
        model: "NEW CITY (2004)",
        variants: [
          "EXI (AT) (1497cc)",
          "GXI (AT) (1497cc)",
          "EXI (1497cc)",
          "GXI (1497cc)",
          "GXI AUTO TRANSMISSION (1497cc)",
          "EXI AUTO TRANSMISSION (1497cc)",
        ],
      },
      {
        model: "ACCORD HYBRID",
        variants: ["2 (1993cc)", "2.0L (1993cc)"],
      },
      {
        model: "NEW CITY",
        variants: [
          "1.5 V AT (1497cc)",
          "1.5 S AT (1497cc)",
          "1.5 V MT (1497cc)",
          "1.5 VX MT (1497cc)",
          "1.5 SV MT (1497cc)",
          "1.5 E MT (1497cc)",
          "1.5 S MT (1497cc)",
          "1.5 V CVT (1497cc)",
          "1.5 E I-DTEC (MT) (1498cc)",
          "1.5 S I-DTEC (MT) (1498cc)",
          "1.5 SV CVT (AT) (1497cc)",
          "1.5 SV I-DTEC (MT) (1498cc)",
          "1.5 V INSPIRE (AT) (1497cc)",
          "1.5 V AVN (AT) (1497cc)",
          "1.5 V SUNROOF (AT) (1497cc)",
          "1.5 V L AVN (MT) (1497cc)",
          "1.5 V AVN (MT) (1497cc)",
          "1.5 V I-DTEC (MT) (1498cc)",
          "1.5 V INSPIRE (MT) (1497cc)",
          "1.5 V SUNROOF (MT) (1497cc)",
          "1.5 VX CVT (AT) (1497cc)",
          "1.5 VX I-DTEC (MT) (1498cc)",
          "1.5 ZX CVT (AT) (1497cc)",
          "1.5 ZX I-DTEC (MT) (1498cc)",
          "1.5 V (MT) BSVI (1498cc)",
          "1.5 V (CVT) BSVI (1498cc)",
          "1.5 VX (MT) BSVI (1498cc)",
          "1.5 ZX (MT) BSVI (1498cc)",
          "1.5 VX (CVT) BSVI (1498cc)",
          "1.5 ZX (CVT) BSVI (1498cc)",
          "1.5 VX CVT IVETC (1497cc)",
          "HYBRID ZX SENSING EHEV (1498cc)",
          "HYBRID V SENSING EHEV (1498cc)",
          "1.5 SV (MT) BSVI (1498cc)",
          "1.5 CORPORATE EDITION (1497cc)",
          "1.5 ZX MT (1497cc)",
          "1.5 V MT INSPIRE (1497cc)",
          "1.5 V MANUAL TRANSMISSION AVN (1497cc)",
          "1.5 E MT I-DTEC (1498cc)",
          "1.5 S MT I-DTEC (1498cc)",
          "1.5 SV MT I-DTEC (1498cc)",
          "1.5 V AMT INSPIRE (1497cc)",
          "1.5 V AMT SUNROOF (1497cc)",
          "1.5 V L MT AVN (1497cc)",
          "1.5 V MT I-DTEC (1498cc)",
          "1.5 V MT SUNROOF (1497cc)",
          "1.5 VX MT I-DTEC (1498cc)",
          "1.5 S MANUAL TRANSMISSION (1497cc)",
          "1.5 E MANUAL TRANSMISSION (1497cc)",
          "1.5 V MANUAL TRANSMISSION (1497cc)",
          "1.5 V AUTO TRANSMISSION (1497cc)",
          "1.5 S AUTO TRANSMISSION (1497cc)",
          "1.5 V MANUAL TRANSMISSION CNG (1497cc)",
          "1.5 V AUTO TRANSMISSION AVN (1497cc)",
          "1.5 SV MANUAL TRANSMISSION (1497cc)",
          "1.5 VX MANUAL TRANSMISSION (1497cc)",
          "1.5 SV CVT AUTO TRANSMISSION (1497cc)",
          "1.5 VX CVT AUTO TRANSMISSION (1497cc)",
          "1.5 E MANUAL TRANSMISSION I-DTEC DIESEL (1498cc)",
          "1.5 E MT (1498cc)",
          "ZX CVT (1497cc)",
          "ZX CVT (1497cc)",
          "SV CVT (1497cc)",
          "1.5 ZX CVT AUTO TRANSMISSION (1497cc)",
          "1.5 ZX CVT AT ANNIVERSARY EDITION (1497cc)",
          "VX MT (1498cc)",
          "SV PETROL MT (1498cc)",
          "5TH GEN 1.5 SV MT I-VTEC PETROL (1498cc)",
          "1.5 VX (O) MANUAL TRANSMISSION I-DTEC DIESEL (1498cc)",
          "1.5 S MANUAL TRANSMISSION I-DTEC DIESEL (1498cc)",
          "1.5 SV MANUAL TRANSMISSION I-DTEC  DIESEL (1498cc)",
          "1.5 V MANUAL TRANSMISSION I-DTEC DIESEL (1498cc)",
          "1.5 VX MANUAL TRANSMISSION I-DTEC DIESEL (1498cc)",
          "1.5 VX CVT AUTO TRANSMISSION IDTEC (1498cc)",
          "1.5 V MT (1498cc)",
          "1.5 VX MT (1498cc)",
          "1.5 SV MT (1498cc)",
          "1.5 S MT (1498cc)",
          "VX MT (Occ)",
          "ZX GXI (1497cc)",
          "ZX GXI (1497cc)",
          "1.5 V AT INSPIRE (1497cc)",
          "1.5 VX AT (1497cc)",
          "1.5 SV AT (1497cc)",
          "1.5 V MANUAL TRANSMISSION INSPIRE (1497cc)",
          "1.5 V AUTO TRANSMISSION  INSPIRE (1497cc)",
          "1.5 V MANUAL TRANSMISSION SUNROOF (1497cc)",
          "1.5 V AUTO TRANSMISSION SUNROOF (1497cc)",
          "1.5 GXI CVT (1497cc)",
          "1.5 GXI (1497cc)",
          "1.5 VX (O) MANUAL TRANSMISSION (1497cc)",
          "1.5 V CVT AUTO TRANSMISSION (1497cc)",
          "1.5 V L MANUAL TRANSMISSION AVN (1497cc)",
          "ZX MT (1498cc)",
          "1.5 ZX MANUAL TRANSMISSION I-DTEC DIESEL (1498cc)",
          "1.5 ZX SENSING CVT EHEV HYBRID (1498cc)",
          "1.5 ZX CVT EHEV HYBRID (1498cc)",
          "1.5 V CVT EHEV HYBRID (1498cc)",
          "5TH GEN 1.5 V MT I-VTEC PETROL (1498cc)",
          "1.5 PETROL ZX CVT (1498cc)",
          "5TH GEN 1.5 V CVT I-VTEC PETROL (1498cc)",
          "5TH GEN 1.5 ZX MT I-VTEC PETROL (1498cc)",
          "5TH GEN 1.5 VX MT I-VTEC PETROL (1498cc)",
          "5TH GEN 1.5 VX CVT I-VTEC PETROL (1498cc)",
          "5TH GEN 1.5 ZX CVT I-VTEC PETROL (1498cc)",
        ],
      },
      {
        model: "ELEVATE",
        variants: [
          "SV MT (1498cc)",
          "V MT (1498cc)",
          "VX MT (1498cc)",
          "ZX MT (1498cc)",
          "V CVT (1498cc)",
          "VX CVT (1498cc)",
          "ZX CVT (1498cc)",
          "ZX DT CVT (1498cc)",
        ],
      },
      {
        model: "BRION NEW",
        variants: ["E (1198cc)", "VX (1198cc)", "VX AT (1198cc)", "S (1198cc)"],
      },
      {
        model: "CITY EHEV",
        variants: ["ZX CVT PETROL BS6 (1497cc)", "ZX (1498cc)"],
      },
      {
        model: "INTEGRA",
        variants: [
          "STD (2354cc)",
          "STANDARD (1500cc)",
          "STANDARD (1800cc)",
          "STANDARD (1598cc)",
        ],
      },
      {
        model: "BR - V",
        variants: ["E MT i-DTEC BS-IV (1497cc)"],
      },
      {
        model: "NEW JAZZ",
        variants: [
          "S (1199cc)",
          "SV (1199cc)",
          "V AT (1199cc)",
          "E (1199cc)",
          "V (1199cc)",
          "VX (1199cc)",
          "S AT (1199cc)",
          "S CVT (1199cc)",
          "V CVT (1199cc)",
          "VX CVT (1199cc)",
          "SV MT (1199cc)",
          "V MT (1199cc)",
          "E MT (1199cc)",
          "S MT (1199cc)",
          "VX MT (1199cc)",
          "VX CVT EXCLUSIVE EDITION (P) (1199cc)",
          "ZX MT (1199cc)",
          "ZX CVT (1199cc)",
          "S (1498cc)",
          "SV (1498cc)",
          "E (1498cc)",
          "V (1498cc)",
          "VX (1498cc)",
          "V IDTEC (1498cc)",
          "SV IDTEC (1498cc)",
          "S IDTEC (1498cc)",
          "E IDTEC (1498cc)",
          "VX IDTEC (1498cc)",
        ],
      },
      {
        model: "STEP WAGON",
        variants: ["WAGON (3471cc)", "0 (2000cc)"],
      },
      {
        model: "VEZEL",
        variants: ["STD (1498cc)"],
      },
      {
        model: "PILOT",
        variants: ["EX (3471cc)"],
      },
      {
        model: "ACURA",
        variants: ["NSX (2998cc)"],
      },
      {
        model: "TESTMORATA",
        variants: ["0 (450cc)"],
      },
      {
        model: "HONDA NEW",
        variants: ["0 (260cc)"],
      },
      {
        model: "HONDA INTEGRA",
        variants: ["0 (0.25cc)"],
      },
      {
        model: "PRELUDE",
        variants: ["0 (2156cc)"],
      },
      {
        model: "HONDA BEAT",
        variants: ["0 (2354cc)"],
      },
      {
        model: "HONDA LXI PS",
        variants: ["0 (200cc)"],
      },
      {
        model: "HONDA BRIO",
        variants: ["0 (119811cc)"],
      },
      {
        model: "HONDA SX4",
        variants: ["0 (1900cc)"],
      },
      {
        model: "PRATIK HONDA",
        variants: ["0 (880.5cc)"],
      },
      {
        model: "ACURA NSX",
        variants: ["0 (2354cc)"],
      },
      {
        model: "CITY E HEV",
        variants: ["HYBRID ZX AT (1498cc)", "ZX AT (1498cc)"],
      },
      {
        model: "NEW AMAZE",
        variants: [
          "E (1498cc)",
          "S CVT (1498cc)",
          "S (1498cc)",
          "V CVT (1498cc)",
          "V (1498cc)",
          "VX (1498cc)",
          "E (1199cc)",
          "S CVT (1199cc)",
          "S (1199cc)",
          "V CVT (1199cc)",
          "V (1199cc)",
          "VX (1199cc)",
        ],
      },
      {
        model: "ODYSSEY",
        variants: ["EX (2000cc)", "EX (3471cc)"],
      },
    ],
  },
  {
    make: "HYUNDAI",
    models: [
      {
        model: "VERNA",
        variants: [
          "1.5 GLS (1493cc)",
          "1.5 GLS (1493cc)",
          "1.6 VTVT AUTO SX (O) (1591cc)",
          "1.6 CRDI VGT 4SPEED S (AT) (1582cc)",
          "1.6 CRDI VGT 4SPEED SX(O)(AT) (1582cc)",
          "1.6 CRDI VGT 6SPEED SX (O)(MT) (1582cc)",
          "1.6 U2 CRDI VGT 6SPEED S (MT) (1582cc)",
          "1.6 U2 CRDI VGT 6SPEED SX (MT) (1582cc)",
          "1.6 VTVT S (MT) (1591cc)",
          "1.6 VTVT SX (MT) (1591cc)",
          "1.6 VTVT SX (O) (MT) (1591cc)",
          "1.6 VTVT AUTO EX (1591cc)",
          "1.6 CRDI SX+ (AT) (1582cc)",
          "1.4 VTVT 5-SPEED BASE (MT) (1396cc)",
          "1.6 VTVT AUTO SX+ (1591cc)",
          "VTVT SX3 (1591cc)",
          "1.6 SX GLS CRDI (1599cc)",
          "1.5 GL (1493cc)",
          "1.6 VTVT (1591cc)",
          "1.6 VTVT (1599cc)",
          "1.6 VTVT (1599cc)",
          "1.6 SX VTVT (1591cc)",
          "1.6 SX VTVT (1591cc)",
          "TRANSFORM 1.6 VTVT WITH AUDIO (1599cc)",
          "CRDI (1493cc)",
          "CRDI SX (1493cc)",
          "CRDI_ABS (1493cc)",
          "CRDI SX_ABS (1493cc)",
          "1.5 CRDI VGT (1493cc)",
          "1.5 SX CRDI VGT (1493cc)",
          "1.5 SX CRDI VGT AT (1493cc)",
          "TRANSFORM 1.5 CRDI VGT (1493cc)",
          "TRANSFORM 1.5 CRDI VGT WITH AUDIO (1493cc)",
          "TRANSFORM 1.5 SX CRDI VGT (1493cc)",
          "TRANSFORM 1.5 SX CRDI VGT AT (1493cc)",
          "1.6 VTVT S AT (1591cc)",
          "1.6 VTVT SX AT (1591cc)",
          "1.4L VTVT 5-SPEED MANUAL BASE (1396cc)",
          "1.4L U2 CRDI 6-SPEED M BASE (1396cc)",
          "FLUIDIC 1.4 CX VTVT (1396cc)",
          "1.4 CX VTVT (1396cc)",
          "THE NEXT GEN  1.4 E (1396cc)",
          "VTVT CX 1.4 (1396cc)",
          "FLUIDIC 1.4 VTVT GL (1396cc)",
          "FLUIDIC 1.4 VTVT GL (1396cc)",
          "VTVT SX (1396cc)",
          "1.4 VTVT (1396cc)",
          "1.4 VTVT (1396cc)",
          "FLUIDIC 1.4 VTVT CX (1396cc)",
          "FLUIDIC 1.4 VTVT (1396cc)",
          "1.4 VTVT E (1396cc)",
          "1.5 TURBO GDI SX (1482cc)",
          "1.5 TURBO GDI SX (O) DUAL TONE (1482cc)",
          "1.5 TURBO GDI SX (O) (1482cc)",
          "TRANSFORM 1.5 CRDI VGT AUDIO (1493cc)",
          "1.6L CRDI VGT 4-SPEED AT S (1582cc)",
          "1.6L CRDI VGT 4-SPEED AT SX(O) (1582cc)",
          "1.6L CRDI VGT 6-SPEED M SX (O) (1582cc)",
          "1.6L U2 CRDI VGT 6-SPEED M S (1582cc)",
          "1.6L U2 CRDI VGT 6-SPEED M SX (1582cc)",
          "1.6L VTVT 4-SPEED AUTOMATIC S (1591cc)",
          "1.6L VTVT 4-SPEED AUTOMATIC SX (1591cc)",
          "1.6L VTVT 5-SPEED MANUAL S (1591cc)",
          "1.6L VTVT 5-SPEED MANUAL SX (1591cc)",
          "1.6L VTVT 5-SPEED M SX (O) (1591cc)",
          "TRANSFORM 1.6 VTVT AUDIO (1599cc)",
          "I (1599cc)",
          "CV VT- I 1.6 - PETROL (1599cc)",
          "XXI - PETROL (1599cc)",
          "1.6 XI VTVT (1599cc)",
          "TRANSFORM VTVT (1599cc)",
          "XI ABS (1599cc)",
          "GLS (1599cc)",
          "TRASNFORM 1.6 VTVT W/ (1599cc)",
          "VTVT 1.6 (1599cc)",
          "1.6 GLS (1599cc)",
          "1.5 S VTVT (1497cc)",
          "1.6 VTVT SX (1591cc)",
          "1.4 VTVT EX (1396cc)",
          "1.4 VTVT E (1368cc)",
          "1.6 VTVT E (1591cc)",
          "1.6 VTVT EX (1591cc)",
          "1.6 VTVT SX O (1591cc)",
          "1.6 CRDI SX (1582cc)",
          "1.6 CRDI EX AT (1582cc)",
          "1.6 CRDI SX O (1582cc)",
          "1.6 CRDI EX (1582cc)",
          "XXI ABS (1599cc)",
          "XXI_ABS (1599cc)",
          "XI (1599cc)",
          "XI (1599cc)",
          "XXI (1599cc)",
          "TRANSFORM 1.6 SX VTVT (1599cc)",
          "TRANSFORM 1.6 VTVT (1599cc)",
          "FLUIDIC 4S 1.6 VTVT SX (1591cc)",
          "FLUIDIC 4S 1.6 VTVT S O (1591cc)",
          "FLUIDIC 4S 1.6 VTVT S O AT (1591cc)",
          "VTVT (1591cc)",
          "1.6 SX VTVT OPTION AT (1591cc)",
          "FLUIDIC 1.6 SX VTVT (1591cc)",
          "FLUIDIC 1.6 EX VTVT (1591cc)",
          "FLUIDIC 1.6 VTVT (1591cc)",
          "FLUIDIC 1.6 VTVT (1591cc)",
          "VTVT EX AT (1591cc)",
          "THE NEXT GEN  E (1591cc)",
          "THE NEXT GEN  EX AT (1591cc)",
          "THE NEXT GEN  EX (1591cc)",
          "THE NEXT GEN  SX (1591cc)",
          "THE NEXT GEN  SX OPTION AT (1591cc)",
          "THE NEXT GEN  SX OPTION (1591cc)",
          "FLUIDIC 1.6 VTVT SX O (1591cc)",
          "FLUIDIC 1.6 VTVT SX O AT (1591cc)",
          "FLUIDIC 1.6 VTVT EX (1591cc)",
          "FLUIDIC 1.6 VTVT EX AT (1591cc)",
          "FLUIDIC VTVT S 1.4 BSIV (1591cc)",
          "FLUIDIC 1.6VTVT SX AUTO (1591cc)",
          "NEXT GEN 1.6L GAMMA DUAL VTVT 6-SPEED PETROL MANUAL E (1591cc)",
          "NEXT GEN 1.6L GAMMA DUAL VTVT 6-SPEED PETROL MANUAL EX (1591cc)",
          "NEXT GEN 1.6L GAMMA DUAL VTVT 6-SPEED PETROL AUTOMATIC EX (1591cc)",
          "NEXT GEN 1.6L GAMMA DUAL VTVT 6-SPEED PETROL MANUAL SX (1591cc)",
          "NEXT GEN 1.6L GAMMA DUAL VTVT 6-SPEED PETROL MANUAL SX (O) (1591cc)",
          "NEXT GEN 1.6L GAMMA DUAL VTVT 6-SPEED PETROL AUTOMATIC SX (O) (1591cc)",
          "NEXT GEN 1.6L GAMMA DUAL VTVT 6-SPEED PETROL AUTOMATIC SX PLUS (1591cc)",
          "FLUIDIC 1.6 VTVT SX (1591cc)",
          "FLUIDIC 1.6 VTVT SX (1591cc)",
          "FLUIDIC FL 1.6 VTVT AUTO S (1591cc)",
          "FLUIDIC FL 1.6 VTVT S (1591cc)",
          "1.6 VTVT S (1591cc)",
          "NEXT GEN  1.6 VTVT EX (1591cc)",
          "NEXT GEN  1.6 VTVT SX(O) (1591cc)",
          "NEXT GEN  1.6 VTVT SX (O) (AT) (1591cc)",
          "NEXT GEN  1.6 VTVT EX (AT) (1591cc)",
          "NEXT GEN  1.6 VTVT E (1591cc)",
          "NEXT GEN  1.6 VTVT SX (1591cc)",
          "NEXT GEN  1.6 VTVT SX+ (AT) (1591cc)",
          "1.6 VTVT SX O AT ANNIVERSARY EDITION (1591cc)",
          "1.6 VTVT SX O ANNIVERSARY EDITION (1591cc)",
          "1.6 VTVT EX AT (1591cc)",
          "1.6 VTVT SX PLUS AT (1591cc)",
          "1.6 VTVT SX O AT (1591cc)",
          "SX (1599cc)",
          "SX OPTION AT (1599cc)",
          "TRANSFORM SX (1599cc)",
          "SX AT (1599cc)",
          "1.6 VTVT SX AUTO (1599cc)",
          "1.6 VTVT SX PLUS AUTOMATIC (1599cc)",
          "1.6 SX VTVT (1599cc)",
          "VTVT SX 1.6 (1599cc)",
          "1.6 VTVT SX (1599cc)",
          "FLUIDIC 1.4  CX CRDI (1396cc)",
          "1.4 CRDI (1396cc)",
          "1.4 CRDI (1396cc)",
          "NEXT GEN 1.4L U2 CRDI 6-SPEED DIESEL MANUAL E (1396cc)",
          "NEXT GEN 1.4L U2 CRDI 6-SPEED DIESEL MANUAL EX (1396cc)",
          "FLUIDIC 1.4 CRDI CX (1396cc)",
          "FLUIDIC 1.4 CRDI EX (1396cc)",
          "FLUIDIC 1.4 CRDI GL (1396cc)",
          "NEXT GEN  1.4 CRDI E (1396cc)",
          "NEXT GEN  1.4 CRDI EX (1396cc)",
          "FLUIDIC 1.4 EX CRDI (1396cc)",
          "FLUIDIC 1.4 CRDI (1396cc)",
          "1.4 CRDI EX (1396cc)",
          "1.4 CRDI EX. (1396cc)",
          "1.4 CRDI E (1396cc)",
          "S PLUS 1.5 VTVT BS6 (1497cc)",
          "SX 1.5 MPI MT (1497cc)",
          "SX 1.5 MPI IVT (1497cc)",
          "SX(O) 1.5 MPI MT (1497cc)",
          "S 1.5 MPI MT (1497cc)",
          "1.5 MPI S (1497cc)",
          "1.5 MPI SX (1497cc)",
          "1.5 MPI SX CVT (1497cc)",
          "1.5 MPI SX (O) (1497cc)",
          "1.5 MPI SX (O) CVT (1497cc)",
          "1.5 MPI SX IVT (1497cc)",
          "1.5 MPI SX (O) IVT (1497cc)",
          "1.5 MPI SX AT (1497cc)",
          "1.5 MPI E (1497cc)",
          "1.5 MPI S PLUS (1497cc)",
          "1.5 L MPI PETROL  MANUAL EX (1497cc)",
          "1.5 MPI EX (1497cc)",
          "1.5 TURBO GDI SX DUAL TONE (1497cc)",
          "1.5 TURBO GDI SX (O) DUAL TONE (1497cc)",
          "1.5 MPI MT SX (O) (1497cc)",
          "1.5 MPI MT EX (1497cc)",
          "1.5 MPI MT SX (1497cc)",
          "S PLUS 1.5 VTVT (1497cc)",
          "1.5 MPI MT S (1497cc)",
          "1.5 MPI IVT SX (1497cc)",
          "SX 1.5 VTVT IVT DUAL TONE (1497cc)",
          "SX O 1.5 VTVT IVT (1497cc)",
          "S 1.5 VTVT (1497cc)",
          "E 1.5 VTVT. (1497cc)",
          "E 1.5 VTVT (1497cc)",
          "EX 1.5 VTVT (1497cc)",
          "1.5 MPI IVT SX ( O ) (1497cc)",
          "SX (O) 1.5 TURBO PETROL MT DUAL TONE (1497cc)",
          "SX (O) 1.5 TURBO PETROL DCT DUAL TONE (1497cc)",
          "SX 1.5 VTVT (1497cc)",
          "SX 1.5 VTVT IVT (1497cc)",
          "SX O 1.5 VTVT (1497cc)",
          "1.5 SX (O) VTVT IVT (1497cc)",
          "1.5 SX VTVT (1497cc)",
          "1.5 SX VTVT IVT (1497cc)",
          "1.5 SX (O) VTVT (1497cc)",
          "1.5 S PLUS VTVT (1497cc)",
          "1.5 E VTVT (1497cc)",
          "SX 1.5 TURBO MT (1482cc)",
          "SX (O) 1.5 TURBO MT (1482cc)",
          "SX 1.5 TURBO DCT (1482cc)",
          "SX (O) 1.5 TURBO DCT (1482cc)",
          "1.5 TURBO GDI SX DCT (1482cc)",
          "1.5 TURBO GDI SX (O) DCT (1482cc)",
          "1.5 TURBO GDI SX DCT DUAL TONE AT (1482cc)",
          "1.5 TURBO GDI SX (O) DCT DUAL TONE AT (1482cc)",
          "1.5 TURBO GDI MT SX (1482cc)",
          "1.5 TURBO GDI MT SX DUAL TONE (1482cc)",
          "1.5 TURBO GDI MT SX(O) (1482cc)",
          "1.5 TURBO GDI MT SX (O) DUAL TONE (1482cc)",
          "1.5 TURBO GDI DCT SX (1482cc)",
          "1.5 TURBO GDI DCT SX DUAL TONE (1482cc)",
          "1.5 GDI DCT SX (O) DUAL TONE (1482cc)",
          "SX 1.5 TURBO PETROL DCT (1482cc)",
          "SX 1.5 TURBO PETROL MT (1482cc)",
          "SX O 1.5 TURBO PETROL DCT (1482cc)",
          "SX O 1.5 TURBO PETROL MT (1482cc)",
          "1.5 TURBO GDI DCT SX(O) (1482cc)",
          "SX 1.5 TURBO PETROL DCT DUAL TONE (1482cc)",
          "SX O 1.5 TURBO PETROL MT DUAL TONE (1482cc)",
          "SX O 1.5 TURBO PETROL DCT DUAL TONE (1482cc)",
          "1.0 T SX (O) TURBO DCT (998cc)",
          "SX O 1.0 TURBO DCT (998cc)",
          "1.0 SX (O) TURBO DCT (998cc)",
          "NEXT GEN 1.4L KAPPA DUAL VTVT 6-SPEED MANUAL E (1368cc)",
          "NEXT GEN 1.4L KAPPA DUAL VTVT 6-SPEED MANUAL EX (1368cc)",
          "NEXT GEN  1.4 VTVT E (1368cc)",
          "NEXT GEN  1.4 VTVT EX (1368cc)",
          "1.4 VTVT EX (1368cc)",
          "THE NEXT GEN  SX PLUS AT (1582cc)",
          "FLUIDIC 4S 1.6 CRDI SX AT (1582cc)",
          "FLUIDIC 4S 1.6 CRDI S O (1582cc)",
          "1.6 SX CRDI (1582cc)",
          "1.6 CRDI S OPTION (1582cc)",
          "FLUIDIC 1.6 SX CRDI (1582cc)",
          "FLUIDIC 1.6 EX CRDI (1582cc)",
          "1.6 VGT (1582cc)",
          "1.6 SX CRDI VGT (1582cc)",
          "1.6 CRDI SX OPTION (1582cc)",
          "1.6 SX CRDI BS4 (1582cc)",
          "1.6 CRDI BS4 SX O (1582cc)",
          "FLUIDIC 1.6 CRDI (1582cc)",
          "FLUIDIC 1.6 CRDI (1582cc)",
          "THE NEXT GEN  E (1582cc)",
          "THE NEXT GEN  EX AT (1582cc)",
          "THE NEXT GEN  EX (1582cc)",
          "THE NEXT GEN  SX (1582cc)",
          "THE NEXT GEN  SX OPTION (1582cc)",
          "SX OPTION (1582cc)",
          "FLUIDIC 1.6 CRDI EX (1582cc)",
          "FLUIDIC 1.6 CRDI SX O (1582cc)",
          "FLUIDIC 1.6 CRDI SX O AT (1582cc)",
          "FLUIDIC 1.6 CRDI EX AT (1582cc)",
          "FLUIDIC 1.6 CRDI SX AT (1582cc)",
          "FLUIDIC 1.6 VTVT SX AT (1582cc)",
          "1.6 SX PLUS AT (1582cc)",
          "CRDI E (1582cc)",
          "CRDI EX (1582cc)",
          "FLUIDIC 1.6 CRDI SX (1582cc)",
          "FLUIDIC 1.6 CRDI SX (1582cc)",
          "FLUIDIC 1.6 VGT CRDI (1582cc)",
          "1.6 CRDI VGT (1582cc)",
          "NEXT GEN 1.6L U2 CRDI 6-SPEED DIESEL AUTOMATIC SX (O) (1582cc)",
          "NEXT GEN 1.6L U2 CRDI 6-SPEED DIESEL MANUAL E (1582cc)",
          "NEXT GEN 1.6L U2 CRDI 6-SPEED DIESEL MANUAL EX (1582cc)",
          "NEXT GEN 1.6L U2 CRDI 6-SPEED DIESEL AUTOMATIC EX (1582cc)",
          "NEXT GEN 1.6L U2 CRDI 6-SPEED DIESEL MANUAL SX (1582cc)",
          "NEXT GEN 1.6L U2 CRDI 6-SPEED DIESEL AUTOMATIC SX PLUS (1582cc)",
          "NEXT GEN 1.6L U2 CRDI 6-SPEED DIESEL MANUAL SX (O) (1582cc)",
          "1.6 CRDI SX O ANNIVERSARY EDITION (1582cc)",
          "FLUIDIC SX 1.6 CRDI BSIV (1582cc)",
          "FLUIDIC EX 1.6 CRDI BSIV (1582cc)",
          "1.0 L KAPPA TURBO GDI SX (O) DCT BS-VI (1582cc)",
          "1.6 CRDI S (1582cc)",
          "1.6 CRDI S AT (1582cc)",
          "NEXT GEN  1.6 CRDI E (1582cc)",
          "NEXT GEN  1.6 CRDI EX (1582cc)",
          "NEXT GEN  1.6 CRDI EX (AT) (1582cc)",
          "NEXT GEN  1.6 CRDI SX (1582cc)",
          "NEXT GEN  1.6 CRDI SX+ (AT) (1582cc)",
          "NEXT GEN  1.6 CRDI SX (O) (1582cc)",
          "NEXT GEN  1.6 CRDI SX (O) (AT) (1582cc)",
          "CRDI (1582 CC) (1582cc)",
          "1.6 CRDI E (1582cc)",
          "1.6 CRDI SX PLUS AT (1582cc)",
          "1.6 CRDI SX O AT (1582cc)",
          "GLS 1.5 CRDI SX (1493cc)",
          "1.5 L CRDI VGT (1493cc)",
          "TRANSFORM VGT SX (1493cc)",
          "GL 1.5 PDI (1493cc)",
          "1.5 CRDI SX VGT (1493cc)",
          "TRANSFORM VGT (1493cc)",
          "VGT SX (1493cc)",
          "VGT SX AT (1493cc)",
          "TRANSFORM SX VGT AT (1493cc)",
          "TRANSFORM SX VGT (1493cc)",
          "VGT (1493cc)",
          "GLS 1.6 (1493cc)",
          "CRDI GL (1493cc)",
          "1.5 CRDI S (1493cc)",
          "1.5 CRDI SX (1493cc)",
          "1.5 CRDI SX CVT (1493cc)",
          "1.5 CRDI SX (O) (1493cc)",
          "1.5 CRDI SX (O) CVT (1493cc)",
          "1.5 CRDI SX AT (1493cc)",
          "1.5 CRDI SX (O) AT (1493cc)",
          "1.5 CRDI S PLUS (1493cc)",
          "I ABS (1493cc)",
          "1.5 CRDI MT S PLUS (1493cc)",
          "1.5 CRDI AT SX (1493cc)",
          "SX 1.5 CRDI (1493cc)",
          "SX 1.5 CRDI AT (1493cc)",
          "S PLUS 1.5 CRDI (1493cc)",
          "SX O 1.5 CRDI (1493cc)",
          "SX O 1.5 CRDI AT (1493cc)",
          "SX (1493cc)",
          "TRASNFORM 1.5 VGT W/  CRDI (1493cc)",
          "TRASNFORM 1.5 VGT SX CRDI (1493cc)",
          "TRASNFORM 1.5 VGT SX AT CRDI (1493cc)",
          "XI VTVT (1493cc)",
          "GL (1493cc)",
          "GLS 1.5 (1493cc)",
          "1.5 S PLUS CRDI (1493cc)",
          "1.5 SX CRDI (1493cc)",
          "1.5 SX (O) CRDI (1493cc)",
          "1.5 SX (O) AT CRDI (1493cc)",
          "1.5 SX AT CRDI (1493cc)",
          "CRDI VGT SX 1.5 (1493cc)",
          "VGT CRDI (1493cc)",
          "VGT CRDI SX (1493cc)",
          "CRDI VGT 1.5 (1493cc)",
          "CRDI VGT SX AT 1.5 (1493cc)",
          "VGT CRDI ABS (1493cc)",
          "VGT CRDI SX ABS (1493cc)",
          "TRANSFORM 1.5 CRDI AUDIO (1493cc)",
          "TRANSFORM 1.5 CRDI (1493cc)",
          "TRANSFORM 1.5 SX AT CRDI (1493cc)",
          "TRANSFORM 1.5 SX CRDI (1493cc)",
          "I (1493cc)",
          "1.6 SX CRDI (1599cc)",
          "SX OPTION (1599cc)",
          "1.6 VGT CRDI SX (1599cc)",
          "CRDI EX 1.6 (1599cc)",
          "TRASNFORM 1.6 VGT SX CRDI (1599cc)",
          "1.6 CRDI EX AT (1599cc)",
          "GLS (1559cc)",
          "GLS 1.6 EXI (1559cc)",
          "1.6 GLS (1559cc)",
          "GL1.5 CRDI SX (1173cc)",
          "I (1555cc)",
        ],
      },
      {
        model: "GRAND I10",
        variants: [
          "SPORTZ (1197cc)",
          "MAGNA (1120cc)",
          "1.2 KP DVTVT 4SP MAGNA (AT) (1197cc)",
          "1.2 KP DVTVT 4SP SE (AT)(O) (1197cc)",
          "1.2 KP DVTVT 5SP ASTA (MT) (1197cc)",
          "1.2 KP DVTVT 5SP ERA (MT) (1197cc)",
          "1.2 KP DVTVT 5SP MAGNA SE (1197cc)",
          "1.2 KP DVTVT 5SP SE(MT) (1197cc)",
          "1.2 KP DVTVT 5SP SE (MT)(O) (1197cc)",
          "1.2 U2 CRDI 5SP ASTA (MT) (1186cc)",
          "1.2 U2 CRDI 5SP ERA (MT) (1186cc)",
          "1.2 MAGNA U2 (1186cc)",
          "1.2 U2 CRDI 5SP SPORTZ (MT) (1186cc)",
          "1.2 U2 CRDI 5SP SPORTZ(O)(MT) (1186cc)",
          "1.1U2 CRDI 2ND GEN 5SP ASTA(O) (1120cc)",
          "1.2 KP DVTVT 5SP ASTA (O) (1197cc)",
          "1.1 U2 CRDI 2ND GEN 5SP ASTA (1120cc)",
          "1.2 KP DVTVT 4SP ASTA (AT) (1197cc)",
          "1.2 KP DVTVT 5SP ASTA (1197cc)",
          "1.2 KP DVTVT 5SP ERA (1197cc)",
          "1.0 KAPPA VTVT MAGNA (998cc)",
          "1.2 KP DVTVT 5SP MAGNA (1197cc)",
          "1.1 U2 CRDI 2ND GEN 5SP SE (1120cc)",
          "1.2 KP DVTVT 5SP SE (1197cc)",
          "1.2 KP DVTVT 4SP SE (AT) (1197cc)",
          "1.2 KP DVTVT 4SP ASTA (O)(AT) (1197cc)",
          "1.2 KP DVTVT 4SP ERA (AT) (1197cc)",
          "1.2 KP DVTVT 5SP MAGNA (MT) (1197cc)",
          "1.2 KP DVTVT 5SP SPORTZ (MT) (1197cc)",
          "1.2 KP DVTVT 5SP SPORTZ (O) SE (1197cc)",
          "1.2 KP DVTVT 5SP SPORTZ (O) AT (1197cc)",
          "1.2 KP DVTVT 4SP SPORTZ (AT) (1197cc)",
          "1.2 KP DVTVT 5SP SPORTZ+ (1197cc)",
          "1.2 KP DVTVT 5SP SPORTZ (O) (1197cc)",
          "1.2 KP DVTVT 5SP ASTA SE (1197cc)",
          "1.1 SPORTZ U2 (1120cc)",
          "1.2 KP DVTVT SPORTZ CNG (1197cc)",
          "1.2 SPORTZ U2 (1186cc)",
          "1.2 SPORTZ U2 (O) (1186cc)",
          "1.2 SPORTZ U2 DUAL TONE (1186cc)",
          "ERA 1.1 U2 CRDI 2ND GN 5SP (1120cc)",
          "MAGNA 1.1 U2 CRDI 2ND GN 5SP (1120cc)",
          "1.2 U2 CRDI ERA DSL AT (1186cc)",
          "1.2 U2 CRDI ERA DSL MT (1186cc)",
          "1.2 U2 CRDI ERA T DSL AT (1186cc)",
          "1.2 U2 CRDI ERA T DSL MT (1186cc)",
          "1.2 U2 CRDI ERA DSL T+ AT (1186cc)",
          "1.2 U2 CRDI ERA DSL T+ MT (1186cc)",
          "1.2 U2 CRDI MAGNA DSL AT (1186cc)",
          "1.2 U2 CRDI SPORTZ DSL AT (1186cc)",
          "1.2 U2 CRDI SPORTZ (O)DSL AT (1186cc)",
          "1.2 U2 CRDI ASTA DSL AT (1186cc)",
          "1.2 KP DVTVT SPORTZ DT (1197cc)",
          "1.2 VTVT PRIME (1197cc)",
          "1.2 VTVT PRIME T + (1197cc)",
          "1.2 CRDI PRIME ERA T (1120cc)",
          "1.2 VTVT PRIME ERA T (1197cc)",
          "1.2 ASTA SOLID (1197cc)",
          "1.2 KP DVTVT 5SP MAGNA (O) (1197cc)",
          "1.2 ASTA O BSIV (1120cc)",
          "1.2 SPORTZ AT (1197cc)",
          "1.2 SPORTZ O (1197cc)",
          "1.2 ERA (1197cc)",
          "MAGNA 1.2 LPG (1197cc)",
          "1.2 SPORTZ (1197cc)",
          "1.2 SPORTZ (1186cc)",
          "1.0LKAPPA (998cc)",
          "1.1 ERA (1086cc)",
          "1.2 MAGNA (1197cc)",
          "1.2 ASTA (1197cc)",
          "1.2 U2 CRDI 5-SPEED ERA T (1186cc)",
          "1.2 KP DVTVT 4-SP AT MAGNA (1186cc)",
          "1.2 KP DVTVT 4-SP AT SE(O) (1186cc)",
          "1.2 KP DVTVT 5-SP M ASTA (1186cc)",
          "1.2 KP DVTVT 5-SP M ERA (1186cc)",
          "1.2 KP DVTVT 5-SP M MAGNA (1186cc)",
          "1.2 KAPPA DUAL VTVT 5-SP M SE (1186cc)",
          "1.2 KP DVTVT 5-SP M SE(O) (1186cc)",
          "1.2 U2 CRDI 5-SPEED M ASTA (1186cc)",
          "1.2 U2 CRDI 5-SPEED M MAGNA (1186cc)",
          "1.2 U2 CRDI 5-SPEED M SPORT (1186cc)",
          "1.2 U2 CRDI 5-SP M SPORTZ(O) (1186cc)",
          "ASTA(O)1.1 U2 CRDI 2ND GEN 5SP (1120cc)",
          "ASTA (O) 1.2 KP DVTVT 5 SP (1197cc)",
          "ASTA 1.1 U2 CRDI 2ND GEN 5 SP (1120cc)",
          "ASTA 1.2 AT KP DVTVT 5 SP (1197cc)",
          "ASTA 1.2 KAPPA DUAL VTVT 5 SP (1197cc)",
          "ERA 1.2 KAPPA DUAL VTVT 5 SP (1197cc)",
          "MAGNA 1.2 KAPPA DUAL VTVT 5 SP (1197cc)",
          "SE 1.1 U2 CRDI 2ND GEN 5 SP (1120cc)",
          "SE 1.2 KAPPA DUAL VTVT 5 SP (1197cc)",
          "SE AT 1.2 KAPPA DUAL VTVT 5 SP (1197cc)",
          "ASTA (O) AT 1.2 KP DVTVT 4 SP (1197cc)",
          "ASTA 1.2 AT KP DVTVT 4 -SP (1197cc)",
          "ERA 1.1 U2 CRDI 2ND GEN 5 SP (1197cc)",
          "MAGNA 1.1 U2 CRDI 2ND GEN 5SP (1197cc)",
          "1.2 U2 CRDI 5SP PRIME T+ (1120cc)",
          "1.2 KP DVTVT PRIME T+ (1197cc)",
          "1.2 KP DVTVT PRIME T+ CNG (1197cc)",
          "NIOS 1.2 MT KAPPA SPORTZ (1197cc)",
          "PRIME T CRDI (1120cc)",
          "MAGNA 1.0 KAPPA VTVT LPG (998cc)",
          "SPORTZ 1.2 KAPPA VTVT (1120cc)",
          "PRIME ERA T CRDI (1120cc)",
          "ASTA OPTION 1.1 CRDI (1120cc)",
          "ASTA OPTION (1120cc)",
          "ASTA (1120cc)",
          "ERA (1120cc)",
          "PRIME SPORTS (1120cc)",
          "1.1 PRIME T CRDI (1120cc)",
          "MAGNA 1.1 U2 CRDI 2ND GEN 5 SPEED (1120cc)",
          "SPORTZ 1.1 U2 CRDI 2ND GEN 5 SPEED (1120cc)",
          "ASTA (O) 1.1 U2 CRDI  2ND GEN 5 SPEED (1120cc)",
          "ASTA 1.1 U2 CRDI  2ND GEN 5 SPEED (1120cc)",
          "ERA 1.1 U2 CRDI 2ND GEN 5 SPEED (1120cc)",
          "PRIME T 1.1 CRDI (1120cc)",
          "PRIME SPORTS EDITION 1.1 U2 CRDI 2ND GEN 5 SPEED (1120cc)",
          "PRIME T PLUS 1.1 CRDI (1120cc)",
          "SPORTZ 1.2 (1120cc)",
          "MAGNA 1.1 (1120cc)",
          "SPORTS EDITION 1.1 CRDI (1120cc)",
          "SPORTZ (1120cc)",
          "ASTA 1.1 CRDI (1120cc)",
          "ASTA 1.1 CRDI (1120cc)",
          "ERA 1.1 CRDI (1120cc)",
          "ERA 1.1 CRDI (1120cc)",
          "MAGNA 1.1 CRDI (1120cc)",
          "MAGNA 1.1 CRDI (1120cc)",
          "SPORTZ 1.1 CRDI (1120cc)",
          "SPORTZ 1.1 CRDI (1120cc)",
          "PRIME T PLUS CRDI (1120cc)",
          "ASTA 1.1 CRDI O (1120cc)",
          "PRIME ERA T VTVT (1197cc)",
          "SPORTZ 1.2 VTVT (1197cc)",
          "ASTA 1.2 AT (1197cc)",
          "ASTA 1.2 VTVT (1197cc)",
          "ERA 1.2 VTVT (1197cc)",
          "MAGNA 1.2 VTVT (1197cc)",
          "SPORTZ 1.2 AT (1197cc)",
          "MAGNA 1.2 AT VTVT (1197cc)",
          "SPORTZ 1 2 (1197cc)",
          "SPORTZ 1.2 BS4 (1197cc)",
          "NIOS 1.2 ASTA AMT KAPPA VTVT (1197cc)",
          "ASTA OPTION (1197cc)",
          "ASTA OPTION (1197cc)",
          "ASTA (1197cc)",
          "SPORTZ AT (1197cc)",
          "ERA (1197cc)",
          "ASTA AT (1197cc)",
          "MAGNA AT (1197cc)",
          "SPORTS EDITION (1197cc)",
          "ASTA OPTION AT (1197cc)",
          "SPORTZ OPTION (1197cc)",
          "SPORTZ PLUS (1197cc)",
          "SPORTZ (O) (1197cc)",
          "ASTA (O) 1.2 BSIV (1197cc)",
          "1.2 PRIME T (1197cc)",
          "ERA T CNG VTVT (1197cc)",
          "ASTA 1.2 AT KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "SPORTZ AT 1.2 KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "ASTA 1.2 KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "ERA 1.2 KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "MAGNA 1.2 KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "SPORTZ 1.2 KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "ASTA (O) 1.2 KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "SPORTZ 1.2  AT KAPPA DUAL VTVT 4 -SPEED (1197cc)",
          "ASTA 1.2  AT KAPPA DUAL VTVT 4 -SPEED (1197cc)",
          "MAGNA AT 1.2 KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "PRIME T PLUS 1.2 PETROL (1197cc)",
          "ASTA (O) AT 1.2 KAPPA DUAL VTVT 4 SPEED (1197cc)",
          "SPORTS EDITION 1.2 KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "NIOS 1.2L KAPPA DUAL VTVT MT SPORTZ CNG (1197cc)",
          "NIOS 1.2L KAPPA DUAL VTVT PETROL AMT ASTA (1197cc)",
          "NIOS 1.2L KAPPA DUAL VTVT PETROL  MANUAL ERA (1197cc)",
          "NIOS 1.2L KAPPA DUAL VTVT PETROL MANUAL MAGNA (1197cc)",
          "NIOS 1.2L KAPPA AMT PETROL AUTOMATIC MAGNA (1197cc)",
          "NIOS 1.2L KAPPA DUAL VTVT PETROL MANUAL SPORTZ (1197cc)",
          "NIOS 1.2L KAPPA DUAL VTVT PETROL MT SPORTZ DUAL TONE (1197cc)",
          "NIOS 1.2L KAPPA AMT PETROL  AMT SPORTZ (1197cc)",
          "NIOS 1.2L KAPPA AMT DUAL VTVT CORP EDITION (1197cc)",
          "NIOS 1.2L MAGNA MT CNG (1197cc)",
          "NIOS 1.2 KAPPA CNG MT ASTA (1197cc)",
          "NIOS 1.2 MAGNA CORP EDITION (1197cc)",
          "PRIME T 1.2 CNG (1197cc)",
          "PRIME T 1.2 PETROL (1197cc)",
          "SPORTZ (O) 1.2 KAPPA DUAL VTVT 4 SPEED AUTOMATIC (1197cc)",
          "1.2 KAPPA DUAL VTVT 5-SPEED MANUAL SPORTZ (O) (1197cc)",
          "1.2 KAPPA DUAL VTVT 4-SPEED AUTOMATIC SPORTZ (O) (1197cc)",
          "NIOS 1.2L KAPPA DUAL VTVT CORP EDITION (1197cc)",
          "NIOS 1.2L KAPPA DUAL VTVT PETROL MT ASTA (1197cc)",
          "SPORT PLUS 1.2 KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "MAGNA 1.2 CNG KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "SPORTZ (O) 1.2 KAPPA DUAL VTVT 5 SPEED (1197cc)",
          "PRIME T PLUS 1.2 CNG (1197cc)",
          "NIOS 1.2L KAPPA PETROL MANUAL SPORTZ EXECUTIVE (1197cc)",
          "NIOS 1.2L KAPPA DUAL VTVT PETROL SMART AUTO AMT MAGNA (1197cc)",
          "NIOS 1.2L KAPPA PETROL AMT SPORTZ EXECUTIVE (1197cc)",
          "NIOS 1.2L KAPPA DUAL VTVT PETROL SMART AUTO AMT ASTA (1197cc)",
          "NIOS 1.2L KAPPA DUAL VTVT PETROL SMART AUTO AMT SPORTZ (1197cc)",
          "1.2 PRIME (1197cc)",
          "NIOS SPORTZ CNG (1197cc)",
          "SPORTZ 1.2 AUTO (1197cc)",
          "NIOS SPORTZ (1197cc)",
          "ERA CNG (1197cc)",
          "1.2 SPORTZ PLUS (1197cc)",
          "ASTA CNG (1197cc)",
          "ASTA O CNG (1197cc)",
          "MAGNA CNG (1197cc)",
          "SPORTZ CNG (1197cc)",
          "PRIME ERA T+ CNG VTVT (1197cc)",
          "MAGNA 1.2 KAPPA CNG (1197cc)",
          "SPORTS EDITION 1.2 KAPPA VTVT (1197cc)",
          "MAGNA (1197cc)",
          "SPORTZ 1.2 BSIV (1197cc)",
          "1.2 PRIME SPORTZ EDITION (1197cc)",
          "ASTA 1.2 BSIV (1197cc)",
          "1.2 PRIME T PLUS CNG (1197cc)",
          "1.2 PRIME T CNG (1197cc)",
          "MAGNA 1.2 KAPPA VTVT CNG (1197cc)",
          "MAGNA 1.2 CNG (1197cc)",
          "ASTA 1.2 KAPPA VTVT (1197cc)",
          "MAGNA AT 1.2 KAPPA VTVT (1197cc)",
          "SPORTZ O 1.2 KAPPA VTVT (1197cc)",
          "SPORTZ O AT 1.2 KAPPA VTVT (1197cc)",
          "ASTA AT 1.2 KAPPA VTVT O (1197cc)",
          "ASTA 1.2 KAPPA VTVT O (1197cc)",
          "ASTA AT 1.2 KAPPA VTVT (1197cc)",
          "ERA 1.2 KAPPA VTVT (1197cc)",
          "MAGNA 1.2 KAPPA VTVT (1197cc)",
          "SPORTZ 1.2 KAPPA VTVT DUAL TONE (1197cc)",
          "SPORTZ AT 1.2 KAPPA VTVT (1197cc)",
          "SPORTZ 1.2 KAPPA VTVT (1197cc)",
          "KAPPA MAGNA LPG (998cc)",
          "1.0 MAGNA LPG (998cc)",
          "NIOS 1.0 TURBO MT SPORTZ (998cc)",
          "1.0 MAGNA (998cc)",
          "ECO MAGNA (998cc)",
          "MAGNA (998cc)",
          "MAGNA 1.2 KAPPA VTVT (1197CC) (1500cc)",
          "SPORTZ OPTION (1186cc)",
          "1.2 CRDI ASTA (1186cc)",
          "1.2 CRDI SPORTZ (1186cc)",
          "NIOS1.2L U2 CRDI DIESEL MT MAGNA (1186cc)",
          "NIOS1.2L U2 CRDI DIESEL AMT  SPORTZ (1186cc)",
          "NIOS 1.2L U2 CRDI DIESEL MT  ASTA (1186cc)",
          "NIOS 1.2 CRDI DIESEL MT MAGNA CORP EDITION (1186cc)",
          "NIOS1.2L U2 CRDI DIESEL MT  SPORTZ (1186cc)",
          "ERA 1.2 U2 CRDI 5-SPEED MANUAL (1186cc)",
          "MAGNA 1.2 U2 CRDI 5-SPEED MANUAL (1186cc)",
          "SPORTZ 1.2 U2 CRDI 5-SPEED MANUAL (1186cc)",
          "SPORTZ(O) 1.2 U2 CRDI 5-SPEED MANUAL (1186cc)",
          "ASTA 1.2 U2 CRDI 5-SPEED MANUAL (1186cc)",
          "ASTA U2 1.2 CRDI (1186cc)",
          "ERA U2 1.2 CRDI (1186cc)",
          "MAGNA U2 1.2 CRDI (1186cc)",
          "SPORTZ O U2 1.2 CRDI (1186cc)",
          "SPORTZ U2 1.2 CRDI (1186cc)",
          "SPORTZ U2 1.2 CRDI DUAL TONE (1186cc)",
          "SPORTZ 1.2M (1396cc)",
          "1.0 MAGNA 5STR (1190cc)",
        ],
      },
      {
        model: "SONATA",
        variants: [
          "CRDI (1497cc)",
          "2.7 V6 H MATIC (2657cc)",
          "2.4 GDI AT (2359cc)",
          "2.4 GDI AT (2359cc)",
          "2.4 GDI MT (2359cc)",
          "2.4 GDI MT (2359cc)",
          "2.4 GDI MANUAL TRANSMISSION (2359cc)",
          "2.4 GDI AUTO TRANSMISSION (2359cc)",
          "S20 (1997cc)",
          "S20 (1997cc)",
          "GOLD (1997cc)",
          "GLS (1997cc)",
          "GLS (2359cc)",
          "DSL (1991cc)",
          "2.0 CRDI (1991cc)",
          "AT (1991cc)",
          "TRANSFORM MT (1991cc)",
          "TRANSFORM AT (1991cc)",
          "BASE (1997cc)",
          "ONE GLS (1997cc)",
          "ONE GOLD (1997cc)",
          "ONE S 20 (1997cc)",
          "DSL (1997cc)",
          "TRANSFORM MT (2359cc)",
          "GDI AT (2359cc)",
          "GDI MT (2359cc)",
          "MT (2359cc)",
          "2.4 MT (2359cc)",
          "NEW  2.4 GDI AT (2359cc)",
          "NEW  2.4 GDI MT (2359cc)",
          "S20 (2359cc)",
          "H-MATIC (2657cc)",
          "2.7 V6 (2657cc)",
          "CRDI (AT) 2.0 L (2400cc)",
          "CRDI MT (2400cc)",
          "2.4 (2399cc)",
          "2.7 V6 (2656cc)",
          "GLS (2397cc)",
        ],
      },
      {
        model: "SONATA TRANSFORM",
        variants: [
          "2.4 VTVT (MT) (2359cc)",
          "2.0 CRDI AT TRANSMISSION (1991cc)",
          "2.0 CRDI MANUAL TRANSMISSION (1991cc)",
          "2.4 VTVT MANUAL TRANSMISSION (2359cc)",
          "2.0 CRDI AT (1991cc)",
          "2.0 CRDI MT (1991cc)",
          "2.0 CRDI AUTOMATIC TRANSMISSION (1991cc)",
          "2 (1991cc)",
          "2.4 (2359cc)",
          "2.4 VTVT (2359cc)",
        ],
      },
      {
        model: "I20 ACTIVE",
        variants: [
          "1.2 BASE (1197cc)",
          "1.2 KP DVTVT 5SP BASE (MT) (1197cc)",
          "1.2 KP DUAL VTVT 5SP S (MT) (1197cc)",
          "1.2 KP DUAL VTVT 5SP SX (MT) (1197cc)",
          "1.4 U2 CRDI 6SPEED BASE (MT) (1396cc)",
          "1.4 U2 CRDI 6SPEED S (MT) (1396cc)",
          "1.4 U2 CRDI 6SPEED SX (MT) (1396cc)",
          "1.4 BASE (1396cc)",
          "1.2L KP DVTVT 5-SP M BASE (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SP M S (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SP M SX (1197cc)",
          "1.4L U2 CRDI 6-SPEED M BASE (1396cc)",
          "1.4L U2 CRDI 6-SPEED MANUAL S (1396cc)",
          "1.4L U2 CRDI 6-SPEED MANUAL SX (1396cc)",
          "1.4 S (1396cc)",
          "1.4 S (1396cc)",
          "1.4 SX (1396cc)",
          "1.4 SX (1396cc)",
          "1.2 SX DUAL TONE (1197cc)",
          "1.4 SX DUAL TONE (1396cc)",
          "1.2 S (1197cc)",
          "1.2 S (1197cc)",
          "1.4 (1396cc)",
          "1.4 (1396cc)",
          "1.4 (1396cc)",
          "SX (1396cc)",
          "S (1396cc)",
          "BASE (1396cc)",
          "SX AVN (1396cc)",
          "SX DUAL TONE (1396cc)",
          "1.4 SX O (1396cc)",
          "VTVT SX (1197cc)",
          "SX (1197cc)",
          "S (1197cc)",
          "BASE (1197cc)",
          "SX AVN (1197cc)",
          "SX DUAL TONE (1197cc)",
          "1.2 (1197cc)",
          "1.2 (1197cc)",
          "1.2 SX (1197cc)",
          "1.2 SX (1197cc)",
        ],
      },
      {
        model: "CRETA",
        variants: [
          "1.6 CRDI SX O (1582cc)",
          "1.6 CRDI SX O (1582cc)",
          "1.6 VTVT E (1591cc)",
          "1.6 VTVT E+ (1591cc)",
          "1.4 CRDI 6SPEED E (MT) (1396cc)",
          "1.4 CRDI 6SPEED S (MT) (1396cc)",
          "1.6 CRDI VGT 6SPEED S+ (AT) (1582cc)",
          "1.6 CRDI VGT 6SPEED SX+ (AT) (1582cc)",
          "1.6 CRDI VGT 6SPEED SX (MT) (1582cc)",
          "1.6 CRDI VGT 6SPEED SX(O)(MT) (1582cc)",
          "1.6 CRDI VGT 6-SP SX+ DT (MT) (1582cc)",
          "1.6 DUAL VTVT 6SPEED SX+ (AT) (1591cc)",
          "1.6 DUAL VTVT 6SPEED E (MT) (1591cc)",
          "1.6 DUAL VTVT 6SPEED SX+ (MT) (1591cc)",
          "1.6 DUAL VTVT 6SP SX+ DT (MT) (1591cc)",
          "1.6 DUAL VTVT 6SPEED BASE (MT) (1591cc)",
          "1.6 DUAL VTVT 6SPEED S (MT) (1591cc)",
          "1.6 CRDI VGT 6SP SX+ SE (MT) (1582cc)",
          "1.4L CRDI 6SP BASE (MT) (1396cc)",
          "1.4L CRDI 6SP SX PLUS (MT) (1396cc)",
          "1.6 DUAL VTVT 6SP SX+ SE(MT) (1591cc)",
          "1.6 ANNIVERSARY ADTN (1582cc)",
          "1.6 DUAL VTVT 6SP SX (MT) (1591cc)",
          "1.6 DUAL VTVT 6SP SX (AT) (1591cc)",
          "1.6 DUAL VTVT 6SP SX (O) MT (1591cc)",
          "1.6 CRDI VGT 6SP S (AT) (1582cc)",
          "1.6 CRDI VGT 6SP SX (AT) (1582cc)",
          "1.6 CRDI VGT 6SP SX(O) EXE (1582cc)",
          "1.6 DUAL VTVT 6SP EX (MT) (1591cc)",
          "1.4 CRDI 6SP EX (MT) (1396cc)",
          "1.6 DUAL VTVT 6SP SX (O) EXE (1591cc)",
          "SPORTZ EDITION DUAL TONE (1582cc)",
          "SPORTZ EDITION DUAL TONE (1591cc)",
          "1.6 CRDI VGT 6SP E+ (MT) (1582cc)",
          "SPORTZ EDITION (1582cc)",
          "SPORTZ EDITION (1591cc)",
          "1.6 CRDI VGT 6SP EX (1582cc)",
          "1.5 MPI EX 6MT BSVI (1497cc)",
          "1.5 MPI S 6MT BSVI (1497cc)",
          "1.5 MPI SX 6MT BSVI (1497cc)",
          "1.5 MPI SX IVT BSVI (1497cc)",
          "1.5 MPI SX (O) IVT BSVI (1497cc)",
          "1.4 TURBO GDI SX (O)7DCT BSVI (1353cc)",
          "1.4 TURBO GDI SX 7DCT BSVI (1353cc)",
          "1.5 U2 CRDI E 6MT BSVI (1493cc)",
          "1.5 U2 CRDI EX 6MT BSVI (1493cc)",
          "1.5 U2 CRDI S 6MT BSVI (1493cc)",
          "1.5 U2 CRDI SX 6MT BSVI (1493cc)",
          "1.5 U2 CRDI SX (O) 6MT BSVI (1493cc)",
          "1.5 U2 CRDI SX 6AT BSVI (1493cc)",
          "1.5 U2 CRDI SX (O) 6AT BSVI (1493cc)",
          "1.5 E VTVT (1497cc)",
          "1.5 E CRDI (1497cc)",
          "1.6 CRDI E MT (1582cc)",
          "1.6 SX VTVT (1591cc)",
          "1.5 MPI E 6MT BSVI (1497cc)",
          "SX 1.5 EXECUTIVE (1497cc)",
          "1.5 MPI S+ KNIGHT (MT) (1497cc)",
          "1.5 MPI S+ KNIGHT (MT)(DT) (1497cc)",
          "1.5 MPI SX (O) IVT KNIGHT (1497cc)",
          "1.5 MPI SX (O) IVT KNIGHT (DT) (1497cc)",
          "1.5 U2 CRDI S+ KNIGHT (MT) (1493cc)",
          "1.5 U2 CRDI S+ KNIGHT (MT)(DT) (1493cc)",
          "1.5 U2 CRDI SX (O) KNIGHT (AT) (1493cc)",
          "1.5 U2 CRDI SX(O)KNIGHT(AT)DT (1493cc)",
          "1.5 MPI S IMT (1497cc)",
          "1.4 TURBO GDI S+ 7DCT (1353cc)",
          "1.4 TURBO GDI S+ 7DCT (DT) (1353cc)",
          "1.6 SX O EXECUTIVE (1591cc)",
          "1.6 SX (O) EXECUTIVE (1582cc)",
          "1.6L CRDI VGT 6-SPEED AT S+ (1582cc)",
          "1.6L CRDI VGT 6-SPEED AT SX+ (1582cc)",
          "1.6L CRDI VGT 6-SPEED M SX (1582cc)",
          "1.6L CRDI VGT 6-SPEED M SX (O) (1582cc)",
          "1.6L CRDI VGT 6-SP M SX+ DT (1582cc)",
          "1.6L DUAL VTVT 6-SPEED AT SX+ (1582cc)",
          "1.6L DUAL VTVT 6-SPEED M E (1582cc)",
          "1.6L DUAL VTVT 6-SPEED M SX+ (1582cc)",
          "1.6L DUAL VTVT 6-SP M SX+ DT (1582cc)",
          "1.6L DUAL VTVT 6-SPEED M BASE (1591cc)",
          "1.6L DUAL VTVT 6-SPEED M S (1591cc)",
          "1.4 TURBO GDI DCT SX BSVI (1353cc)",
          "1.4 TURBO GDI DCT SX OP BSVI (1353cc)",
          "1.5 CRDI MT E BSVI (1493cc)",
          "1.5 CRDI MT EX BSVI (1493cc)",
          "1.5 CRDI MT S BSVI (1493cc)",
          "1.5 CRDI MT SX AT BSVI (1493cc)",
          "1.5 CRDI MT SX BSVI (1493cc)",
          "1.5 CRDI MT SX OP AT BSVI (1493cc)",
          "1.5 CRDI MT SX OP BSVI (1493cc)",
          "1.5 MPI IVT SX BSVI (1497cc)",
          "1.5 MPI IVT SX OP BSVI (1497cc)",
          "1.5 MPI MT EX BSVI (1497cc)",
          "1.5 MPI MT S BSVI (1497cc)",
          "1.5 MPI MT SX BSVI (1497cc)",
          "1.4L CRDI 6-SPEED MANUAL S (1396cc)",
          "1.4L CRDI 6-SPEED MANUAL E (1396cc)",
          "1.6 VTVT AT SX PLUS (1591cc)",
          "VTVT SX PLUS (1591cc)",
          "VTVT SX PLUS AT (1591cc)",
          "1.6L DUAL VTVT 6-SPEED MANUAL SX PLUS (1591cc)",
          "1.6L DUAL VTVT 6-SPEED SX PLUS AT (1591cc)",
          "1.6L DUAL VTVT 6-SPEED MANUAL SX PLUS SE (1591cc)",
          "1.6 SX PLUS PETROL SPECIAL EDITION (1591cc)",
          "1.6 VTVT SX PLUS (1591cc)",
          "1.6 VTVT SX PLUS (1591cc)",
          "SX PLUS 1.6 DUAL TONE PETROL (1591cc)",
          "1.6 GAMMA SX PLUS (1591cc)",
          "SX PLUS 1.6 CRDI DUAL TONE (1591cc)",
          "1.6 SX PLUS PETROL DUAL TONE (1591cc)",
          "1.6 SX PLUS PETROL SPL EDITION (1591cc)",
          "1.6 SX PLUS AT PETROL (1591cc)",
          "1.6 SX PLUS PETROL (1591cc)",
          "1.4 TURBO GDI DCT SX O (1353cc)",
          "1.4 TURBO GDI DCT S PLUS BSVI (1353cc)",
          "1.4 TURBO GDI DCT SX (1353cc)",
          "1.4 TURBO GDI DCT SX OP (1353cc)",
          "1.4 KAPPA TURBO GDI PETROL 7 SPEED DCT Â CRETA S PLUS DT (1353cc)",
          "1.4 TURBO GDI DCT SX OP DUAL TONE (1353cc)",
          "1.4 TURBO GDI DCT S PLUS (1353cc)",
          "SX 1.4 TURBO GDI DCT (1353cc)",
          "1.5 CRDI MT SX (1493cc)",
          "1.5 CRDI MT SX (1493cc)",
          "1.5 CRDI AT SX O SE (1493cc)",
          "1.5 CRDI AT SX(O) SE (1493cc)",
          "S PLUS 1.5 CRDI KNIGHT (1493cc)",
          "1.5 CRDI MT SX OP (1493cc)",
          "1.5 CRDI SX OP AT (1493cc)",
          "1.5 CRDI SX EXECUTIVE (1493cc)",
          "1.5 L U2 CRDI DIESEL 6-SPEED MANUAL  DSL S PLUS KNIGHT DT (1493cc)",
          "1.5 L U2 CRDI DIESEL 6-SPEED MANUAL  DSL S PLUS KNIGHT (1493cc)",
          "1.5 L U2 CRDI DIESEL 6-SPEED AUTOMATIC  DSL SX(O) KNIGHT (1493cc)",
          "1.5 L U2 CRDI DIESEL 6-SPEED AUTOMATIC  DSL SX(O) KNIGHT DT (1493cc)",
          "1.5 CRDI AT SX (1493cc)",
          "1.5 L U2 CRDI DIESEL 6-SPEED AUTOMATIC  DSL SX(O) (1493cc)",
          "1.5 L U2 CRDI DIESEL 6-SPEED MANUAL SX EXECUTIVE TRIM(D) (1493cc)",
          "1.5 CRDI MT S PLUS SE (1493cc)",
          "SX 1.5 PETROL (1493cc)",
          "E 1.5 DIESEL (1493cc)",
          "EX 1.5 DIESEL (1493cc)",
          "S 1.5 DIESEL (1493cc)",
          "S 1.5 PETROL (1493cc)",
          "SX O 1.5 DIESEL (1493cc)",
          "SX 1.5 DIESEL (1493cc)",
          "SX 1.5 DIESEL AT (1493cc)",
          "1.5 CRDI MT SX(O) BSVI (1493cc)",
          "SX EXECUTIVE (1493cc)",
          "1.5 CRDI AT SX O (1493cc)",
          "S PLUS 1.5 DIESEL MT KNIGHT (1493cc)",
          "S PLUS1.5 PETROL MT KNIGHT (1493cc)",
          "1.5 CRDI MT S PLUS (1493cc)",
          "SX O 1.5 DIESEL AT (1493cc)",
          "SX 1.5 DIESEL EXECUTIVE TRIM. (1493cc)",
          "SX 1.5 DIESEL EXECUTIVE TRIM (1493cc)",
          "SX 1.5 PETROL EXECUTIVE TRIM (1493cc)",
          "S PLUS 1.5 DIESEL MT DUAL TONE KNIGHT. (1493cc)",
          "S PLUS 1.5 DIESEL MT DUAL TONE KNIGHT (1493cc)",
          "SX O 1.5 DIESEL AT DUAL TONE KNIGHT. (1493cc)",
          "SX O 1.5 DIESEL AT DUAL TONE KNIGHT (1493cc)",
          "SX O 1.5 DIESEL AT KNIGHT (1493cc)",
          "S PLUS 1.5 PETROL MT DUAL TONE KNIGHT (1493cc)",
          "1.5 CRDI MT S (1493cc)",
          "1.5 CRDI MT E (1493cc)",
          "1.5 CRDI MT EX (1493cc)",
          "1.5 MPI MT S PLUS SE (1493cc)",
          "1.5 SX DIESEL (1493cc)",
          "1.5 SX (O) AT DIESEL (1493cc)",
          "1.5 E DIESEL (1493cc)",
          "1.5 EX DIESEL (1493cc)",
          "1.5 S DIESEL (1493cc)",
          "1.5 SX AT DIESEL (1493cc)",
          "1.5 SX (O) DIESEL (1493cc)",
          "1.5 SX EXECUTIVE TRIM DIESEL (1493cc)",
          "1.5 SX (O) AT KNIGHT DUAL TONE DIESEL (1493cc)",
          "1.5 SX (O) AT KNIGHT DIESEL (1493cc)",
          "1.5 S PLUS KNIGHT DUAL TONE DIESEL (1493cc)",
          "1.5 S PLUS KNIGHT DIESEL (1493cc)",
          "1.5 MPI SX EXECUTIVE TRIM MT (1497cc)",
          "S PLUS 1.5 MPI KNIGHT (1497cc)",
          "1.5 SX O KNIGHT IVT DT (1497cc)",
          "1.5 MPI MT S (1497cc)",
          "1.5 MPI IVT SX OP (1497cc)",
          "1.5 L MPI PETROL 6-SPEED IVT SX(O) Â KNIGHT DT (1497cc)",
          "1.5 L MPI PETROL 6-SPEED IVT SX(O) KNIGHT (1497cc)",
          "1.5 L MPI PETROL 6-SPEED MANUAL S KNIGHT DT (1497cc)",
          "1.5 L MPI PETROL 6-SPEED MANUAL S PLUS KNIGHT (1497cc)",
          "1.5 MPI MT SX EXECUTIVE (1497cc)",
          "1.5 MPI PETROL 6 SPEED MT SX EXECUTIVE TRIM(P) (1497cc)",
          "1.5 L MPI PETROL 6-SPEED MANUAL S PLUS KNIGHT DT (1497cc)",
          "1.5 MPI IMT 6 SPEED S (1497cc)",
          "SX O 1.5 PETROL CVT KNIGHT DUAL TONE (1497cc)",
          "SX 1.5 PETROL (1497cc)",
          "EX 1.5 PETROL (1497cc)",
          "S 1.5 PETROL (1497cc)",
          "SX OPT KNIGHT IVT (1497cc)",
          "1.5 MPI IVT SX O (1497cc)",
          "1.5 MPI S PLUS KNIGHT P MT (1497cc)",
          "S PLUS1.5 PETROL MT KNIGHT (1497cc)",
          "SX 1.5 PETROL ADVENTURE EDITION (1497cc)",
          "SX O 1.5 PETROL ADVENTURE EDITION (1497cc)",
          "S 1.5 PETROL IMT. (1497cc)",
          "S 1.5 PETROL IMT (1497cc)",
          "SX O 1.5 PETROL CVT KNIGHT (1497cc)",
          "E 1.5 PETROL (1497cc)",
          "SX 1.5 PETROL EXECUTIVE TRIM. (1497cc)",
          "SX 1.5 PETROL EXECUTIVE TRIM (1497cc)",
          "SX O 1.5 PETROL IVT DUAL TONE KNIGHT. (1497cc)",
          "SX O 1.5 PETROL IVT DUAL TONE KNIGHT (1497cc)",
          "SX 1.5 PETROL CVT. (1497cc)",
          "SX 1.5 PETROL CVT (1497cc)",
          "SX O 1.5 PETROL CVT. (1497cc)",
          "SX O 1.5 PETROL CVT (1497cc)",
          "SX O 1.5 PETROL IVT KNIGHT (1497cc)",
          "S PLUS 1.5 PETROL MT DUAL TONE KNIGHT. (1497cc)",
          "S PLUS 1.5 PETROL MT DUAL TONE KNIGHT (1497cc)",
          "1.5 MPI MT E (1497cc)",
          "1.5 MPI MT EX (1497cc)",
          "1.5 MPI MT SX (1497cc)",
          "1.5 MPI IVT SX (1497cc)",
          "1.5 MPI MT S PLUS SE (1497cc)",
          "1.5 SX (O) CVT PETROL (1497cc)",
          "1.5 S PETROL (1497cc)",
          "1.5 SX CVT PETROL (1497cc)",
          "1.5 EX PETROL (1497cc)",
          "1.5 SX PETROL (1497cc)",
          "1.5 SX (O) IVT PETROL (1497cc)",
          "1.5 SX EXECUTIVE TRIM PETROL (1497cc)",
          "1.5 E PETROL (1497cc)",
          "1.5 SX IVT PETROL (1497cc)",
          "1.5 S PLUS KNIGHT PETROL (1497cc)",
          "1.5 S IMT PETROL (1497cc)",
          "1.5 S PLUS KNIGHT DUAL TONE PETROL (1497cc)",
          "1.5 SX (O) CVT KNIGHT DUAL TONE PETROL (1497cc)",
          "1.5 SX (O) CVT KNIGHT PETROL (1497cc)",
          "1.6 CRDI S PLUS AT (1582cc)",
          "1.6 CRDI SX (1582cc)",
          "1.6 CRDI SX PLUS (1582cc)",
          "1.6 CRDI SX PLUS AT (1582cc)",
          "ANNIVERSARY EDITION (1582cc)",
          "CRDI SX OPTION (1582cc)",
          "1.6 CRDI BASE (1582cc)",
          "CRDI SX (1582cc)",
          "CRDI SX PLUS (1582cc)",
          "1.6 CRDI S (1582cc)",
          "CRDI SX PLUS AT (1582cc)",
          "FACELIFT (1582cc)",
          "1.6 CRDI S AUTO (1582cc)",
          "1.6 CRDI SX AUTO (1582cc)",
          "1.6 CRDI SX MT DUAL TONE (1582cc)",
          "1.6 E PLUS DIESEL (1582cc)",
          "1.6 SX O CRDI EXECUTIVE (1582cc)",
          "1.6L CRDI VGT 6-SPEED MANUAL SX (1582cc)",
          "1.6L CRDI VGT 6-SPEED MANUAL SX PLUS (1582cc)",
          "1.6L CRDI VGT 6-SPEED MANUAL SX (O) (1582cc)",
          "1.6L CRDI VGT 6-SPEED AUTOMATIC SX PLUS (1582cc)",
          "1.6L CRDI VGT 6-SPEED AT S PLUS (1582cc)",
          "1.6L CRDI 6-SPEED MANUAL E PLUS (1582cc)",
          "1.6L CRDI VGT 6-SPEED MANUAL EX (1582cc)",
          "1.6L CRDI 6-SPEED MANUAL SPORTS EDITION (1582cc)",
          "1.6L CRDI VGT 6-SPEED MANUAL SX SPORT EDITION (1582cc)",
          "1.6L CRDI VGT 6-SPEED AUTOMATIC S (1582cc)",
          "1.6L CRDI 6-SPEED MANUAL SX (O) EXECUTIVE (1582cc)",
          "1.6L CRDI VGT 6-SPEED MANUAL SX PLUS SE (1582cc)",
          "1.6L CRDI VGT 6-SPEED AUTOMATIC SX (1582cc)",
          "CRDI 1.6 SX PLUS SPECIAL EDITION (1582cc)",
          "S 1.6 AT CRDI (1582cc)",
          "SX 1.6 CRDI DUAL TONE (1582cc)",
          "SX 1.6 AT CRDI (1582cc)",
          "1.6 E PLUS CRDI (1582cc)",
          "SPORTS EDITION DUAL TONE DIESEL (1582cc)",
          "1.6 SX CRDI (1582cc)",
          "1.6 SX O CRDI (1582cc)",
          "1.6 SX PLUS CRDI (1582cc)",
          "1.6 SX PLUS AT DIESEL (1582cc)",
          "CRDI 1.6 S PLUS AT (1582cc)",
          "SPORTS EDITION DIESEL (1582cc)",
          "1.6 SX PLUS (1582cc)",
          "1.6 SX DIESEL (1582cc)",
          "1.6 SX PLUS CRDI DUAL TONE (1582cc)",
          "1.6 SX AT CRDI (1582cc)",
          "1.6 SX DUAL TONE CRDI (1582cc)",
          "1.6 S PLUS AUTO (1582cc)",
          "1.6 SX PLUS DIESEL SPL EDITION (1582cc)",
          "SPORTS EDITION (D) (1582cc)",
          "SPORTS EDITION DUAL TONE (D) (1582cc)",
          "1.6 SX (O) (1582cc)",
          "1.6 CRDI SX BSVI (1582cc)",
          "1.6 S AT CRDI (1582cc)",
          "1.6 SX PLUS AUTO (1582cc)",
          "1.6 CRDI E PLUS (1582cc)",
          "1.6 SX O EXECUTIVE CRDI (1582cc)",
          "1.6 EX CRDI (1582cc)",
          "1.6 VTVT L (1591cc)",
          "1.6 VTVT S (1591cc)",
          "1.6 VTVT S (1591cc)",
          "ANNIVERSARY EDITION 1.6 VTVT (1591cc)",
          "VTVT L (1591cc)",
          "S AT (1591cc)",
          "VTVT SX (1591cc)",
          "VTVT S (1591cc)",
          "VTVT SX AT (1591cc)",
          "VTVT SX O (1591cc)",
          "1.6 VTVT SX AUTO (1591cc)",
          "1.6 VTVT SX MT (1591cc)",
          "1.6 VTVT SX (O) (1591cc)",
          "1.6 SX O VTVT EXECUTIVE (1591cc)",
          "1.6 VTVT SX MT DUAL TONE (1591cc)",
          "1.6L DUAL VTVT 6-SPEED MANUAL SX (1591cc)",
          "1.6L DUAL VTVT 6-SPEED MANUAL E (1591cc)",
          "1.6L DUAL VTVT 6-SPEED MANUAL BASE (1591cc)",
          "1.6L DUAL VTVT 6-SPEED MANUAL S (1591cc)",
          "1.6L DUAL VTVT 6-SPEED MANUAL SX (O) (1591cc)",
          "1.6L VTVT MT SX DUAL TONE (1591cc)",
          "1.6 VTVT SX SPORTS EDITION (1591cc)",
          "1.6L DUAL VTVT 6-SPEED MANUAL SPORTS EDITION (1591cc)",
          "1.6L DUAL VTVT 6-SPEED MANUAL EX (1591cc)",
          "1.6L DUAL VTVT 6-SPEED MANUAL SX (O) EXECUTIVE (1591cc)",
          "1.6L DUAL VTVT 6-SPEED MANUAL E PLUS (1591cc)",
          "1.6L DUAL VTVT 6-SPEED SX AT (1591cc)",
          "1.6 VTVT E PLUS (1591cc)",
          "1.6 VTVT E PLUS (1591cc)",
          "SX 1.6 PETROL (1591cc)",
          "SX 1.6 (O) PETROL (1591cc)",
          "SX 1.6 AT PETROL (1591cc)",
          "SX 1.6 DUAL TONE PETROL (1591cc)",
          "EX 1.6 (1591cc)",
          "1.6 VTVT AUTO SX (1591cc)",
          "1.6E (1591cc)",
          "1.6E PLUS (1591cc)",
          "1.6 BASE PETROL (1591cc)",
          "1.6 VTVT EX (1591cc)",
          "SPORTS EDITION DUAL TONE PETROL (1591cc)",
          "SPORTS EDITION PETROL (1591cc)",
          "1.6 SX+ GAMMA (1591cc)",
          "1.6 E PLUS PETROL (1591cc)",
          "1.6 BASE (1591cc)",
          "1.6 E PETROL (1591cc)",
          "1.6 VTVT SX AUTO BSIV (1591cc)",
          "1.6 EX VTVT (1591cc)",
          "1.6 VTVT SX AT PETROL (1591cc)",
          "SPORTS EDITION (P) (1591cc)",
          "SPORTS EDITION DUAL TONE (P) (1591cc)",
          "1.6 SX (O) PETROL (1591cc)",
          "1.6 SX DUAL TONE PETROL (1591cc)",
          "1.6 SX (O) EXECUTIVE PETROL (1591cc)",
          "1.6 SX AT PETROL (1591cc)",
          "1.6 SX PETROL (1591cc)",
          "1.6 S (1591cc)",
          "1.4 CRDI E PLUS (1396cc)",
          "1.4 CRDI S PLUS (1396cc)",
          "1.4 CRDI S (1396cc)",
          "1.4 CRDI E (1396cc)",
          "1.4 CRDI E (1396cc)",
          "CRDI E PLUS (1396cc)",
          "CRDI S PLUS (1396cc)",
          "CRDI S (1396cc)",
          "1.4 S DIESEL (1396cc)",
          "1.4 CRDI EX (1396cc)",
          "1.4L CRDI 6-SPEED MANUAL BASE (1396cc)",
          "1.4L CRDI 6-SPEED MANUAL S PLUS (1396cc)",
          "1.4L CRDI 6-SPEED MANUAL SX PLUS AT (1396cc)",
          "1.4L CRDI 6-SPEED MANUAL EX (1396cc)",
          "1.4L CRDI 6-SPEED MANUAL E PLUS (1396cc)",
          "EX 1.4 (1396cc)",
          "1.4 TURBO GDI DCT SX (O) (1396cc)",
          "1.4 BASE D (1396cc)",
          "1.4 S CRDI (1396cc)",
          "1.4 S PLUS CRDI (1396cc)",
          "CRDI 1.4 E PLUS (1396cc)",
          "1.4 BASE (1396cc)",
          "1.4 E PLUS (1396cc)",
          "1.4 S PLUS (1396cc)",
          "1.4 E (1396cc)",
          "1.4 EX CRDI (1396cc)",
          "1.4 S (1396cc)",
          "S PLUS 1.4 TURBO DCT (1353cc)",
          "SX O 1.4 TURBO 7 DCT (1353cc)",
          "SX 1.4 TURBO 7 DCT (1353cc)",
          "S PLUS 1.4 TURBO DCT DUAL TONE (1353cc)",
          "SX 1.4 TURBO 7 DCT DUAL TONE (1353cc)",
          "SX O 1.4 TURBO 7 DCT DUAL TONE. (1353cc)",
          "SX O 1.4 TURBO 7 DCT DUAL TONE (1353cc)",
          "1.4 SX (O) TURBO 7 DCT PETROL (1353cc)",
          "1.4 SX TURBO 7 DCT PETROL (1353cc)",
          "1.4 S PLUS TURBO DCT DUAL TONE PETROL (1353cc)",
          "1.4 S PLUS TURBO DCT PETROL (1353cc)",
          "1.4 L KAPPA TURBO GDI SX (O) BS-VI (2146cc)",
          "1.5 VTVT SX EXECUTIVE (1498cc)",
          "1.4 CRDI L (1396cc)",
          "CRDI L (1396cc)",
        ],
      },
      {
        model: "ACCENT",
        variants: [
          "DLS (PEUGEOT ENG) (1527cc)",
          "GTX (1495cc)",
          "IMM BSIV (1500cc)",
          "1.6 GLS (1599cc)",
          "1.6 GLS (1599cc)",
          "1.5 GLS (1495cc)",
          "1.6 GLS  ABS (1599cc)",
          "SLX (IMPORTED) (1599cc)",
          "GTI (IMPORTED) (1599cc)",
          "CRDI (1493cc)",
          "CRDI (1493cc)",
          "VIVA CRDI (1493cc)",
          "VIVA CRDI (1493cc)",
          "VIVA (1599cc)",
          "VIVA (1599cc)",
          "VIVA (1595cc)",
          "GTX TORNADO (1599cc)",
          "GTX - TORNADO (1599cc)",
          "GVS (1495cc)",
          "GVS (1495cc)",
          "GLX (1495cc)",
          "GLX (1495cc)",
          "GLE (1599cc)",
          "GLE (1495cc)",
          "EXECUTIVE (1495cc)",
          "EXECUTIVE (1495cc)",
          "VIVA ABS (1599cc)",
          "GLE E-4 (1495cc)",
          "GLE E-3 (1495cc)",
          "GLE E-II (1495cc)",
          "EXECUTIVE - CNG (1495cc)",
          "GLS 1.5 (1495cc)",
          "EXECUTIVE EDITION (1495cc)",
          "GLE 2 (1495cc)",
          "EXECUTIVE 1.5 LPG (1495cc)",
          "GLE 1 (1495cc)",
          "EXECUTIVE 1.5 CNG (1495cc)",
          "GLE CNG (1495cc)",
          "EXECUTIVE 1.5 PETROL (1495cc)",
          "1.5 EXECUTIVE CNG (1495cc)",
          "GLS (1495cc)",
          "CRDI (VIVA) (1493cc)",
          "VIVA (1493cc)",
          "IMM (1465cc)",
          "DLS  (PIEGEOT ENG) (1527cc)",
          "DLS (1527cc)",
          "DLS (1527cc)",
          "1.5 DLS (1495cc)",
          "GLE LPG (1495cc)",
          "CNG (1495cc)",
          "EXECUTIVE LPG (1495cc)",
          "EXECUTIVE - LPG (1495cc)",
          "DLS (1493cc)",
          "1.4 EXECUTIVE - LPG BS - IV (1493cc)",
          "GLE ECONOMY (1590cc)",
          "GLE (1590cc)",
          "GLE (1595cc)",
          "TORNADO (1599cc)",
          "GTX (1599cc)",
          "SLX (1599cc)",
          "GTI (1599cc)",
          "VIVA BASE (1599cc)",
          "GLS 1.6 (1599cc)",
          "GLS 1.6 ABS (1599cc)",
        ],
      },
      {
        model: "FLUIDIC VERNA",
        variants: [
          "1.4 CX 5-SPEED MT (1396cc)",
          "1.4 VTVT (1396cc)",
          "4S 1.4 VTVT (1396cc)",
          "1.4 BASE (1396cc)",
          "1.4 VTVT GL 5-SPEED MANUAL (1396cc)",
          "1.4 4S GAMMA VTVT 5 SPEED MANUAL BASE (1396cc)",
          "1.4 CX 5-SPEED MANUAL TRANSMISSION (1396cc)",
          "1.4L KAPPA DUAL VTVT 6-SPEED MANUAL EX (1396cc)",
          "1.4L KAPPA DUAL VTVT 6-SPEED MANUAL E (1396cc)",
          "1.4 VTVT CX (1396cc)",
          "1.6 CRDI (1582cc)",
          "1.6 VTVT (1591cc)",
          "1.6 4S GAMMA VTVT 5 SPEED MANUAL S (1591cc)",
          "1.6 4S GAMMA VTVT 5 SPEED MANUAL S (O) (1591cc)",
          "1.6 4S GAMMA VTVT 5 SPEED MANUAL SX (1591cc)",
          "1.6 VTVT S (1591cc)",
          "1.6 VTVT E (1591cc)",
          "1.6 VTVT S(O) (AT) (1591cc)",
          "1.6 VTVT S(O) (1591cc)",
          "1.4 VTVT GL 5SP (MT) (1368cc)",
          "1.4 VTVT E (1368cc)",
          "1.4 VTVT EX (1368cc)",
          "1.4 VTVT GL (1368cc)",
          "1.6 CRDI S (1582cc)",
          "1.4 CX 5SPEED (MT) (1368cc)",
          "1.4 CX CRDI 6SPEED (MT) (1396cc)",
          "1.6 CRDI E (1582cc)",
          "1.6 CRDI S(O) (1582cc)",
          "1.4 4SGAMMA VTVT BASE 5SP (MT) (1368cc)",
          "1.4 4S U2 CRDI BASE 6SP (MT) (1396cc)",
          "1.4L CRDI GL 6SP (MT) (1396cc)",
          "1.6 4S U2 CRDI VGT SX 6SP (MT) (1582cc)",
          "1.6 CRDI VGT 4SP SX (AT) (1582cc)",
          "1.6L VTVT SX 4SP (AT) (1591cc)",
          "1.4 CRDI GL (1396cc)",
          "1.4 VTVT CX (1368cc)",
          "1.4 CRDI CX (1396cc)",
          "1.6 CRDI SX AT (1582cc)",
          "1.6 VTVT EX AT (1591cc)",
          "1.6 VTVT EX (1591cc)",
          "1.4 VTVT (1368cc)",
          "1.6 VTVT SX (1591cc)",
          "1.6 VTVT SX (O) AT (1591cc)",
          "1.6 VTVT SX (O) (1591cc)",
          "1.4 CRDI EX (1396cc)",
          "1.6 CRDI EX AT (1582cc)",
          "1.6 CRDI EX (1582cc)",
          "1.4 CRDI (1396cc)",
          "1.6 CRDI SX (1582cc)",
          "1.6 CRDI SX (O) (1582cc)",
          "1.6 CRDI SX (O) AT (1582cc)",
          "FLUIDIC 4S 1.6 CRDI S OPTION (1582cc)",
          "1.6 CRDI SX OPTION (1582cc)",
          "1.6 CRDI SX OPTION AT (1582cc)",
          "1.6 CRDI S OPTION (1582cc)",
          "CRDI S AT (1582cc)",
          "1.6 CRDI VGT 4 SPEED AUTOMATIC SX (1582cc)",
          "1.6 4S U2 CRDI VGT 6 SPEED MANUAL S (1582cc)",
          "1.6 4S U2 CRDI VGT 6 SPEED MANUAL S (O) (1582cc)",
          "1.6 4S U2 CRDI VGT 6 SPEED MANUAL SX (1582cc)",
          "1.6 4S U2 CRDI VGT 4 SPEED AUTOMATIC SX (1582cc)",
          "1.6 4S U2 CRDI VGT 6 SPEED AUTOMATIC  S (1582cc)",
          "1.6 4S U2 CRDI VGT 4 SPEED AUTOMATIC SX PLUS (1582cc)",
          "1.4 CX (1396cc)",
          "1.4 CX CRDI 6-SPEED MANUAL TRANSMISSION (1396cc)",
          "1.4L CRDI GL 6-SPEED MANUAL (1396cc)",
          "1.4 4S U2 CRDI 6 SPEED MANUAL BASE (1396cc)",
          "1.6 VTVT S OPTION (1591cc)",
          "1.6 VTVT SX OPTION (1591cc)",
          "1.6 VTVT SX OPTION AT (1591cc)",
          "1.6 VTVT S OPTION AT (1591cc)",
          "1.6L VTVT SX 4-SPEED AUTOMATIC (1591cc)",
          "1.6 4S GAMMA VTVT 4 SPEED AUTOMATIC S (O) (1591cc)",
          "1.6 GAMMA VTVT 6 SPEED AUTOMATIC SX (O) ANNV EDITION (1591cc)",
          "1.6 GAMMA VTVT 6 SPEED MANUAL SX (O) ANNV EDITION (1591cc)",
          "1.6 VTVT SX AT (1591cc)",
          "1.6 VTVT S AT (1591cc)",
        ],
      },
      {
        model: "SONATA EMBERA",
        variants: [
          "2.0 CRDI AT TRANSMISSION (1991cc)",
          "2.0 CRDI MANUALTRANSMISSION (1991cc)",
          "MANUAL TRANSMISSION (2359cc)",
          "AUOTMATIC TRANSMISSION (2359cc)",
          "AT (2359cc)",
          "AT (2359cc)",
          "MT (2359cc)",
          "MT (2359cc)",
          "2.0 CRDI (MT) (1991cc)",
          "2.0 CRDI AT (1991cc)",
          "CRDI (1991cc)",
          "2.0 CRDI AUTOMATIC TRANSMISSION (1991cc)",
          "AT (1991cc)",
          "MT (1991cc)",
          "2.0 CRDI (1991cc)",
          "2.4 AT (2359cc)",
          "AT LEATHER (2359cc)",
          "MT LEATHER (2359cc)",
          "MT FABRIC (2359cc)",
          "AT FABRIC (2359cc)",
        ],
      },
      {
        model: "SANTRO XING",
        variants: [
          "ERLX XL AT (1086cc)",
          "ERLX XO SE (1086cc)",
          "ERLX XO (1086cc)",
          "ERLX XL (1086cc)",
          "ERLX XK NON AC (1086cc)",
          "ERLX XK WITH AC (1086cc)",
          "GL NON AC (1086cc)",
          "GLS AUDIO (1086cc)",
          "GLS PLUS AUDIO (1086cc)",
          "XL (1086cc)",
          "XL (1086cc)",
          "ERLX XO WITH ABS (1086cc)",
          "GLS AT (1086cc)",
          "XS (1086cc)",
          "XS (1086cc)",
          "XP (1086cc)",
          "XP (1086cc)",
          "XE (1086cc)",
          "XE (1086cc)",
          "XG (1086cc)",
          "XG (1086cc)",
          "GLS (1086cc)",
          "GLS (1086cc)",
          "GL (1086cc)",
          "GL PLUS (1086cc)",
          "NON-AC (1086cc)",
          "ERLX XL AUTOMATIC TRANSMISSION (1086cc)",
          "GLS AUTOMATIC TRANSMISSION (1086cc)",
          "GL WITH LPG (1086cc)",
          "GL PLUS WITH LPG (1086cc)",
          "GLS WITH LPG (1086cc)",
          "GLS AUDIO WITH LPG (1086cc)",
          "GL CNG (1086cc)",
          "XO 5STR (1086cc)",
          "GL 5STR (1086cc)",
          "ERLX XK (1086cc)",
          "XM (1086cc)",
          "GS (1086cc)",
          "XG AT ERLX - EURO II (1086cc)",
          "XG ERLX - EURO II (1086cc)",
          "XK ERLX - EURO II (1086cc)",
          "XK NON-AC ERLX - EURO II (1086cc)",
          "XL AT ERLX - EURO II (1086cc)",
          "XL ERLX - EURO II (1086cc)",
          "XO ERLX - EURO II (1086cc)",
          "XS ERLX - EURO II (1086cc)",
          "SPL (1086cc)",
          "XO (1086cc)",
          "GL PLUS SPL EDITION (1086cc)",
          "GL PLUS LPG SPL EDITION (1086cc)",
          "ERLX GL PLUS (1086cc)",
          "ERLX NON A/C (1086cc)",
          "ERLX GLS LPG (1086cc)",
          "ERLX GLS (1086cc)",
          "ERLX GL PLUS LPG (1086cc)",
          "ERLX CNG (1086cc)",
          "XG AT (1086cc)",
          "XG AT ERLX - EURO III (1086cc)",
          "XG ERLX - EURO III (1086cc)",
          "ABS (1086cc)",
          "XK (1086cc)",
          "X (1086cc)",
          "AT (1086cc)",
          "AT (1086cc)",
          "XO (LPG) (1086cc)",
          "XL AT (1086cc)",
          "XL DLI(1.1) CNG (1086cc)",
          "AE GLS CNG (1086cc)",
          "1.1 GLS LPG (1086cc)",
          "XK ERLX - EURO III (1086cc)",
          "GL LPG (1086cc)",
          "GLS LPG (1086cc)",
          "GL PLUS LPG (1086cc)",
          "GL PLUS CNG (1086cc)",
          "XK NON-AC ERLX - EURO III (1086cc)",
          "XL AT ERLX - EURO III (1086cc)",
          "XL ERLX - EURO III (1086cc)",
          "XO ERLX - EURO III (1086cc)",
          "XS ERLX - EURO III (1086cc)",
          "GLS (CNG) (1086cc)",
          "GLS CNG (1086cc)",
          "GLS (M) (999cc)",
        ],
      },
      {
        model: "SANTRO",
        variants: [
          "EIII XL (1086cc)",
          "1.1 GLS (1086cc)",
          "GL MET BSIV (1086cc)",
          "GLS MET BSIV (1086cc)",
          "EIII XO (1086cc)",
          "GLS MET (1086cc)",
          "GL SOLID (1086cc)",
          "GL E3 (1086cc)",
          "CAR (796cc)",
          "XO E3 (1086cc)",
          "E2 (1086cc)",
          "XL (1086cc)",
          "XL (1086cc)",
          "XG (1086cc)",
          "XD (1086cc)",
          "XP (1086cc)",
          "GLX (999cc)",
          "GLX. (999cc)",
          "ZIP (999cc)",
          "ZIP. (999cc)",
          "AT (999cc)",
          "ECO GL (1086cc)",
          "1.1 AT (1086cc)",
          "GL (1086cc)",
          "GL (1086cc)",
          "GS (999cc)",
          "LS (999cc)",
          "LP (999cc)",
          "1.1 LE (1086cc)",
          "1.1 LP (1086cc)",
          "1.1 GS (1086cc)",
          "LE (999cc)",
          "GLS (999cc)",
          "GLS (1086cc)",
          "DX (999cc)",
          "1.1 LS (1086cc)",
          "1.1 AUTOMATIC TRANSMISSION (1086cc)",
          "AUTOMATIC TRANSMISSION (999cc)",
          "LS (999 CC) (999cc)",
          "DX 1 (999cc)",
          "LE (999 CC) (999cc)",
          "L (999 CC) (999cc)",
          "LX (999cc)",
          "GLS SUPER DELUX (999cc)",
          "EURO II (999cc)",
          "5 DOOR (999cc)",
          "GLS I - EURO I (999cc)",
          "GLS II - EURO I (999cc)",
          "GS ZIPDRIVE - EURO I (999cc)",
          "LP - EURO I (999cc)",
          "LS ZIPDRIVE - EURO I (999cc)",
          "DL1 (999cc)",
          "LE ZIPPLUS (999cc)",
          "GS ZIPPLUS (999cc)",
          "L1 (999cc)",
          "LS ZIPDRIVE - EURO II (999cc)",
          "GLS I - EURO II (999cc)",
          "GLS II - EURO II (999cc)",
          "GS ZIPDRIVE - EURO II (999cc)",
          "LP - EURO II (999cc)",
          "LP ZIPPLUS (999cc)",
          "LS ZIPPLUS (999cc)",
          "DX2 (999cc)",
          "GLS II (999cc)",
          "ZIPDRIVE LS (999cc)",
          "L2 (999cc)",
          "GLS 5STR (1086cc)",
          "XG E-2 (1086cc)",
          "LP E 2 (1086cc)",
          "X NON AC (1086cc)",
          "L (1086cc)",
          "XK (1086cc)",
          "NON AC (1086cc)",
          "NON A/C (1086cc)",
          "MAGNA AMT (1086cc)",
          "MAGNA AMT (1086cc)",
          "SPORTZ AMT (1086cc)",
          "SPORTZ AMT (1086cc)",
          "BASE (1086cc)",
          "ZIP PLUS GS (1086cc)",
          "ECO (1086cc)",
          "EURO (1086cc)",
          "XE (1086cc)",
          "LE 1.1 (1086cc)",
          "LPG GLS AUDIO E4 (1086cc)",
          "XL CNG (1086cc)",
          "MAGNA BS6 (1086cc)",
          "GLS 4 STR (1086cc)",
          "GLS I -EURO I (1086cc)",
          "GLS II -EURO I (1086cc)",
          "GS ZIPDRIVE-EURO I (1086cc)",
          "LP-EURO I (1086cc)",
          "LS ZIPDRIVE-EURO I (1086cc)",
          "1.1 DX (1086cc)",
          "ASTA (1086cc)",
          "ECO GLS BSIV (1086cc)",
          "ERA EXECUTIVE (1086cc)",
          "GL NON A/C-SOLID (1086cc)",
          "GLF (1086cc)",
          "GLS BS IV (1086cc)",
          "MAGNA (1086cc)",
          "SPORTZ (1086cc)",
          "SX DLI 1.1 (1086cc)",
          "XS (1086cc)",
          "MAGNA CORPORATE EDITION (1086cc)",
          "MAGNA AMT CORPORATE EDITION (1086cc)",
          "SPORTZ AMT BS-VI (1086cc)",
          "MAGNA BS-VI (1086cc)",
          "ASTA BS-VI (1086cc)",
          "MAGNA AMT BS-VI (1086cc)",
          "SPORTZ BS-VI (1086cc)",
          "SPORTZ AMT SE (1086cc)",
          "1.1 AMT MAGNA (1086cc)",
          "ERA EXECUTIVE BS-VI (1086cc)",
          "1.1 MT SPORTZ (1086cc)",
          "SPORTZ EXECUTIVE CNG (1086cc)",
          "SANTRO 1.1 MG MAGNA CNG (1086cc)",
          "GLF CNG (1086cc)",
          "1.1 MT MAGNA (1086cc)",
          "1.1 L BI-FUEL MAGNA CNG BS-VI (1086cc)",
          "GS ZIPPLUS (1086cc)",
          "L1 (1086cc)",
          "DLITE (1086cc)",
          "MAGNA CNG (1086cc)",
          "SPORTZ CNG (1086cc)",
          "ASTA AMT BS-VI (1086cc)",
          "LS ZIPDRIVE-EURO II (1086cc)",
          "ERA (1086cc)",
          "GLS I -EURO II (1086cc)",
          "GLS II -EURO II (1086cc)",
          "GS ZIPDRIVE-EURO II (1086cc)",
          "LP-EURO II (1086cc)",
          "LP ZIPPLUS (1086cc)",
          "LS ZIPPLUS (1086cc)",
          "SPORTZ SE (1086cc)",
          "AT (1086cc)",
          "ZIP PLUS LS (1086cc)",
          "ZIP PLUS LS (1086cc)",
          "GL NON AC (1086cc)",
          "GL NON AC (1086cc)",
          "ZIP PLUS LP (1086cc)",
          "ZIP PLUS LP (1086cc)",
          "ECO LPG (1086cc)",
          "GL (CNG) (1086cc)",
          "XK NON-AC (1086cc)",
          "GL LPG (1086cc)",
          "GLS AUDIO (1086cc)",
          "GL PLUS LPG (1086cc)",
          "GLS (CNG) (1086cc)",
          "XO (1086cc)",
          "XO (1086cc)",
          "GL PLUS (1086cc)",
          "GL PLUS (1086cc)",
          "GLS LPG (1086cc)",
          "L2 (1086cc)",
          "GS (1086cc)",
          "GS (1086cc)",
          "LS (1086cc)",
          "LS (1086cc)",
          "LP (1086cc)",
          "LE (1086cc)",
          "LE (1086cc)",
          "DX (1086cc)",
          "GS (990 CC) (990cc)",
          "LP (990 CC) (990cc)",
          "LP 990 CC (990cc)",
          "L 999 CC (998cc)",
          "LS ZIP DRIVE (1148cc)",
          "GL MET (1100cc)",
          "ZIP (1100cc)",
          "GL (1100cc)",
          "GL S (1100cc)",
          "EURO II LS (1450cc)",
        ],
      },
      {
        model: "I10",
        variants: [
          "SPORTZ (1120cc)",
          "1.2 KAPPA ASTA SUNROOF (AT) (1197cc)",
          "1.1 MAGNA/CNG (1086cc)",
          "1.2 KAPPA MAGNA ITECH SE (1197cc)",
          "1.2 KAPPA SPORTZ (O) (1197cc)",
          "1.2 KAPPA MAGNA (AT) (1197cc)",
          "1.2 ASTA (1197cc)",
          "1.2 KAPPA GLS ASTA SUNROOF (1197cc)",
          "1.2 KAPPA GLS SPORTZ (AT) (1197cc)",
          "1.2 ERA (1197cc)",
          "1.2 SPORTZ (1197cc)",
          "1.2 MAGNA (1197cc)",
          "1.1 ERA M (1086cc)",
          "KAPPA SPORTS (1086cc)",
          "1.2 MAGNA SE (1197cc)",
          "1.1 GL (1086cc)",
          "1.2 SPORTZ MT (1197cc)",
          "1.2 MAGNA MET (1197cc)",
          "1.1 D-LITE (1086cc)",
          "1.1 ERA (1086cc)",
          "1.1 MAGNA (O) (1086cc)",
          "1.1 MAGNA (O) WITH SUNROOF (1086cc)",
          "1.1 ERA I TECH SPECIAL EDITION (1086cc)",
          "1.1 SPORTZ (1086cc)",
          "1.2 KAPPA MAGNA (1197cc)",
          "1.2 KAPPA GLS SPORTZ (1197cc)",
          "1.1 MAGNA (1086cc)",
          "ASTA (1197cc)",
          "ASTA (1197cc)",
          "ASTA AT (1197cc)",
          "MAGNA AT (1197cc)",
          "SPORTZ AT (1197cc)",
          "SPORTZ GLS (1197cc)",
          "SPORTZ 1.2 (O) BS IV (1197cc)",
          "ERA 5STR (1197cc)",
          "1.2 KAPPA MAGNA AUTO TRANS (1197cc)",
          "1.2 KAPPA GLS SPORTZ AUTO TRANS (1197cc)",
          "1.2 KAPPA GLS ASTA (1197cc)",
          "1.2 KAPPA GLS ASTA WITH SUNROOF (1197cc)",
          "1.2 KAPPA GLS ASTA WITH SUNROOF AUTO TRANS (1197cc)",
          "1.2 KAPPA SPORTZ OPTION (1197cc)",
          "1.2 KAPPA MAGNA I TECH SPECIAL EDITION (1197cc)",
          "MAGNA 1.2 GLS BSIV (1197cc)",
          "NIOS SPORTZ CNG (1197cc)",
          "ASTA 1.2 AT KAPPA 2 (1197cc)",
          "1.2 L KAPPA MAGNA SPECIAL EDITION (1197cc)",
          "SPORTZ (1197cc)",
          "SPORTZ (1197cc)",
          "1.2 KAPPA GLS ASTA (AT) (1197cc)",
          "GLS 1.2 ASTA (1197cc)",
          "MAGNA 1.2 (1197cc)",
          "MAGNA 1.2 (1197cc)",
          "SPORTZ OPTION (1197cc)",
          "MAGNA 1.2 KAPPA2 (1197cc)",
          "ASTA 1.2 KAPPA2 (1197cc)",
          "SPORTZ 1.2 AT KAPPA 2 (1197cc)",
          "ASTA 1.2 AT KAPPA 2 WITH SUNROOF (1197cc)",
          "MAGNA (1197cc)",
          "MAGNA 1.2 AT (1197cc)",
          "SPORTZ 1.2 AT (1197cc)",
          "SPORTZ 1.2 KAPPA 2 (1197cc)",
          "ASTA 1.2 (1197cc)",
          "SPORTZ 1.2 (1197cc)",
          "ERA 1.1 LPG (1197cc)",
          "ASTA 1.2 WITH SUNROOF (1197cc)",
          "SPORTZ 1.2 KAPPA 2 O (1197cc)",
          "1.1 ERA LPG (1086cc)",
          "1.1 MAGNA LPG (1086cc)",
          "1.1 SPORTZ LPG (1086cc)",
          "ASTA 1.2 AT WITH SUNROOF (1197cc)",
          "OPTION (1086cc)",
          "GLS (1086cc)",
          "MAGNA OPTION (1086cc)",
          "1.1L IRDE2 SPORTZ LPG (1086cc)",
          "ERA 1.1 BSIV (1086cc)",
          "MAGNA 1.1 MT (1086cc)",
          "BLUEDRIVE MAGNA (1086cc)",
          "SPORTZ 1.1 BS IV (1086cc)",
          "SPORTZ 1.1 IRDE2 (1086cc)",
          "MAGNA 1.1 (1086cc)",
          "ERA 1.1 BSIV F/L (1086cc)",
          "SPORTZ BSIV (1086cc)",
          "SPORTZ 1.1 LPG (1086cc)",
          "ERA 1.1 IRDE SPECIAL EDITION (1086cc)",
          "MAGNA 1.1 IRDE2 SPECIAL EDITION (1086cc)",
          "SPORTZ (1086cc)",
          "SPORTZ. (1086cc)",
          "1.1L IRDE2 SPORTZ (1086cc)",
          "ERA 1.1 SPECIAL EDITION (1086cc)",
          "ERA 1.1 CNG (1086cc)",
          "ERA 1.1L LPG (1086cc)",
          "1.1L IRDE2 5-SPEED MANUAL ERA METALLIC (1086cc)",
          "D-LITE (1086cc)",
          "D LITE (1086cc)",
          "MAGNA 1.1 CNG (1086cc)",
          "D-LITE 1.1 IRDE2 (1086cc)",
          "MAGNA 1.1 IRDE2 (1086cc)",
          "MAGNA O WITH SUNROOF (1086cc)",
          "ERA (1086cc)",
          "ERA (1086cc)",
          "MAGNA (1086cc)",
          "MAGNA (1086cc)",
          "MAGNA O (1086cc)",
          "MAGNA 1.1 LPG (1086cc)",
          "ERA 1.1 LPG (1086cc)",
          "ERA 1.1 IRDE2 (1086cc)",
          "1.1 ERA LPG (1086CC) (1500cc)",
          "SPORTZ 1.2 MET BS-IV (1200cc)",
          "MAGNA (OPTION) EURO IV (1200cc)",
          "MAGNA 1.2 SOLID BSIV (1200cc)",
          "SPORTZ (1196cc)",
          "ERA (1100 CC) (1100cc)",
        ],
      },
      {
        model: "I20",
        variants: [
          "1.4 SPORTZ (1396cc)",
          "1.4 SPORTZ (1396cc)",
          "1.2 ASTA WITH SUNROOF (O) (AT) (1197cc)",
          "1.2 ASTA WITH AVN (AT) (1197cc)",
          "1.4 ASTA (1396cc)",
          "1.4 ERA (1396cc)",
          "1.4 MAGNA (1396cc)",
          "1.4 MAGNA WITH (ABS) (1396cc)",
          "1.2 SPORTZ (AT) (1197cc)",
          "1.4 SPORTZ (O) (1396cc)",
          "1.2 ASTA VTVT (O) (1197cc)",
          "1.2 ASTA (MT) (1197cc)",
          "ASTA O CRDI (1396cc)",
          "1.4 MAGNA DSL (1396cc)",
          "MAGNA PLUS VTVT (1197cc)",
          "1.4 SPORT (AT) (1396cc)",
          "MAGNA EXECUTIVE VTVT (1197cc)",
          "1.2 ASTA (O) WITH SUNROOF (1197cc)",
          "1.2 MAGNA (1197cc)",
          "1.2 ASTA (1197cc)",
          "1.2 ASTA (O) (1197cc)",
          "1.2 MAGNA (O) (1197cc)",
          "1.4 ASTA DSL WITH AVN (1396cc)",
          "1.4 DSL MAGNA (O) (1396cc)",
          "SPORTZ PLUS VTVT (1197cc)",
          "ASTA VTVT (1197cc)",
          "1.4 SPORTZ DIESEL (1396cc)",
          "1.4 SPORTZ DIESEL (1396cc)",
          "1.4 ASTA (O) WITH SUNROOF (1396cc)",
          "ELITE 1.2 SE(O) KP DVTVT 5SP M (1197cc)",
          "ELITE 1.2 SE KP DVTVT 5-SP M (1197cc)",
          "ELITE 1.4 MAGNA U2 CRDI 6SP M (1396cc)",
          "ELITE 1.4 SE (O) U2 CRDI 6SP M (1396cc)",
          "ELITE 1.4 SE U2 CRDI 6-SP M (1396cc)",
          "1.2 SPORTZ OPTION (1197cc)",
          "1.4 ERA DIESEL (1396cc)",
          "1.4 SPORTZ OPTION DIESEL (1396cc)",
          "1.4 MAGNA DIESEL (1396cc)",
          "1.4 ASTA AT (O) WITH SUNROOF (1396cc)",
          "1.4 ASTA AT WITH AVN (1396cc)",
          "1.4 SPORTZ AT (1396cc)",
          "1.4 MAGNA DIESEL WITH ABS (1396cc)",
          "1.4 ASTA DIESEL (1396cc)",
          "ELITE 1.2 SPORTZ KAPPA DUAL VTVT 5-SPEED MANUAL PETROL (1197cc)",
          "ELITE 1.2 SPORTZ (O) KAPPA DUAL VTVT 5-SPEED MANUAL PETROL (1197cc)",
          "ELITE 1.2L KAPPA DUAL VTVT 5-SPEED MANUAL SPORTZ SPECIAL EDITION PLUS (1197cc)",
          "ELITE 1.2L KAPPA DUAL VTVT PETROL AUTOMATIC  SPORTZ PLUS CVT (1197cc)",
          "ACTIVE 1.4 U2 CRDI 6-SPEED MANUAL BASE (1396cc)",
          "ACTIVE 1.4 U2 CRDI 6-SPEED MANUAL S (1396cc)",
          "ACTIVE 1.4 U2 CRDI 6-SPEED MANUAL SX WITH AVN (1396cc)",
          "ACTIVE 1.4 U2 CRDI 6-SPEED MANUAL SX (1396cc)",
          "ACTIVE 1.4 CRDI S (1396cc)",
          "ANNIVERSAY EDITION 1.4 CRDI (1396cc)",
          "1.2 SPORTZ (O) (1197cc)",
          "1.4 ASTA (AT) (1396cc)",
          "1.2 ASTA WITH AVN (1197cc)",
          "1.2 SPORTZ (1197cc)",
          "1.2 ERA (1197cc)",
          "ERA (1197cc)",
          "ERA (1197cc)",
          "ERA 1.2 BS-IV (1197cc)",
          "ELITE 1.2 ERA KAPPA DUAL VTVT 5-SPEED MANUAL PETROL (1197cc)",
          "ACTIVE 1.2 KAPPA DUAL VTVT 5 SPEED MANUAL BASE (1197cc)",
          "ACTIVE 1.2 KAPPA DUAL VTVT 5-SPEED MANUAL S (1197cc)",
          "ACTIVE 1.2 KAPPA DUAL VTVT 5-SPEED MANUAL SX WITH AVN (1197cc)",
          "ACTIVE 1.2 KAPPA DUAL VTVT 5-SPEED MANUAL SX (1197cc)",
          "ELITE 1.2 KAPPA DUAL VTVT PETROL 5-SPEED SPORT PLUS CVT (1197cc)",
          "ELITE 1.2 KAPPA DUAL VTVT PETROL 5-SPEED SPORT PLUS (1197cc)",
          "ELITE 1.2 KAPPA DUAL VTVT MANUAL SPORT PLUS (1197cc)",
          "ACTIVE VTVT S (1197cc)",
          "1.2 VTVT (1197cc)",
          "ERA 1.2 (1197cc)",
          "ANNIVERSAY EDITION 1.2 (1197cc)",
          "SX VTVT BS IV (1197cc)",
          "ELITE I20 ERA 1.2 (1197cc)",
          "1.2Â KAPPA ERA (1197cc)",
          "REVIEW (1591cc)",
          "ERA 1.4 (1396cc)",
          "MAGNA AT (1368cc)",
          "ELITE 1.4 MAGNA MPI DUAL VTVT 4-SPEED AUTOMATIC PETROL (1368cc)",
          "ELITE I20 MAGNA 1.4 AT VTVT CC 1368 (1368cc)",
          "MAGNA 1.2.. (998cc)",
          "GL ASTA 1.2 (1197cc)",
          "ASTA O 1.2 KAPPA IVT (1197cc)",
          "ASTA OPTION (1197cc)",
          "ASTA OPTION (1197cc)",
          "SPORTZ (1197cc)",
          "SPORTZ (1197cc)",
          "SPORTZ OPTION (1197cc)",
          "ASTA 1.2 DSL E3 (1197cc)",
          "SPORTZ 1.2 MT (1197cc)",
          "MAGNA VTVT BSIV (1197cc)",
          "SPORTZ 1.2 CVT (1197cc)",
          "ELITE 1.2 MAGNA KAPPA DUAL VTVT 5-SPEED MANUAL PETROL (1197cc)",
          "ELITE 1.2 ASTA KAPPA DUAL VTVT 5-SPEED MANUAL PETROL (1197cc)",
          "ELITE 1.2 ASTA (O) KAPPA DUAL VTVT 5-SPEED MANUAL PETROL (1197cc)",
          "ELITE 1.2 KAPPA DUAL VTVT PETROL 5-SPEED MANUAL MAGNA PLUS (1197cc)",
          "ELITE 1.2 KAPPA DUAL VTVT MAGNA EXECUTIVE AUTOMATIC (1197cc)",
          "1.2 PETROL KAPPA ASTA (O) 5MT (1197cc)",
          "1.2 PETROL KAPPA ASTA IVT (1197cc)",
          "1.2 KAPPA PETROL SPORTZ 5MT (1197cc)",
          "1.2 KAPPA PETROL ASTA 5MT (1197cc)",
          "1.2 KAPPA PETROL SPORTS IVT (1197cc)",
          "1.2 KAPPA PETROL SPORTS IVT DUAL TONE (1197cc)",
          "1.2 KAPPA PETROL ASTA IVT (1197cc)",
          "1.2 KAPPA PETROL ASTA IVT DUAL TONE (1197cc)",
          "1.2 PETROL KAPPA ASTA (O) IVT (1197cc)",
          "1.2 KAPPA PETROL ASTA (O) 5MT (1197cc)",
          "1.2 KAPPA PETROL ASTA (O) 5MT DUAL TONE (1197cc)",
          "1.2 KAPPA PETROL SPORTZ IVT (1197cc)",
          "1.2 ASTA (O) CVT (1197cc)",
          "ELITE 1.2 ASTA (O) CVT AT (1197cc)",
          "ELITE 1.2 KAPPA DUAL VTVT AUTOMATIC ASTA CVT (1197cc)",
          "ELITE 1.2 MAGNA EXECUTIVE KAPPA DUAL VTVT 5-SPEED MANUAL PETROL (1197cc)",
          "ELITE 1.2 KAPPA DUAL VTVT AUTOMATIC MAGNA EXECUTIVE CVT (1197cc)",
          "1.2 KAPPA PETROL SPORTZ 5MT DUAL TONE (1197cc)",
          "SPORTZ 1.2 BS-IV (1197cc)",
          "ASTA 1.2 KAPPA MT (1197cc)",
          "MAGNA EXECUTIVE 1.2 (1197cc)",
          "ASTA 1.2 IVT DUAL TONE (1197cc)",
          "SPORTZ PLUS 1.2 VTVT (1197cc)",
          "SPORTZ 1.2 DUAL TONE (1197cc)",
          "SPORTZ 1.2 IVT DUAL TONE (1197cc)",
          "ASTA O 1.2 DUAL TONE (1197cc)",
          "MAGNA 1.2 VTVT (1197cc)",
          "SPORTZ 1.2 KAPPA (MT) (1197cc)",
          "ASTA O VTVT (1197cc)",
          "ASTA O 1.2 IVT. (1197cc)",
          "ASTA O 1.2 IVT (1197cc)",
          "ASTA O 1.2 IVT DUAL TONE. (1197cc)",
          "ASTA O 1.2 IVT DUAL TONE (1197cc)",
          "ASTA 1.2 (1197cc)",
          "ASTA 1.2 O (1197cc)",
          "MAGNA O 1.2 (1197cc)",
          "SPORTZ 1.2 O (1197cc)",
          "SPORTZ 1.2 (1197cc)",
          "ASTA 1.2 IVT (1197cc)",
          "MAGNA EXECUTIVE 1.2 AT. (1197cc)",
          "MAGNA EXECUTIVE 1.2 AT (1197cc)",
          "SPORTZ 1.2 IVT (1197cc)",
          "ASTA 1.2 DUAL TONE (1197cc)",
          "ASTA 1.2 O WITH SUNROOF (1197cc)",
          "ASTA 1.2 WITH AVN (1197cc)",
          "MAGNA (1197cc)",
          "MAGNA (1197cc)",
          "ASTA (1197cc)",
          "ASTA (1197cc)",
          "MAGNA OPTION (1197cc)",
          "MAGNA OPTION (1197cc)",
          "ELITE I20 ASTA 1.2 (O) (1197cc)",
          "ELITE I20 SPORTZ 1.2 (1197cc)",
          "ELITE I20 ASTA 1.2 (1197cc)",
          "ELITE I20 MAGNA 1.2 (1197cc)",
          "ELITE I20 SPORTZ 1.2 (O) (1197cc)",
          "ELITE I20 ASTA 1.2 DUAL TONE (1197cc)",
          "ASTA (O) (1197cc)",
          "1.4 ASTA AT (1197 CC) (1197cc)",
          "1.2 ASTA (O) DIESEL WITH SUNROOF (1197cc)",
          "ELITE I20 MAGNA 1.2 EXECUTIVE (1197cc)",
          "ASTA O WITH SUNROOF (1197cc)",
          "ELITE I20 ASTA 1.2 AT (1197cc)",
          "ELITE I20 MAGNA 1.2 AT EXECUTIVE (1197cc)",
          "ELITE I20 MAGNA PLUS (1197cc)",
          "1.2 ASTA GL (1197cc)",
          "ELITE I20 ASTA (O) CVT (1197cc)",
          "ELITE I20 SPORTZ PLUS CVT (1197cc)",
          "ELITE I20 SPORTZ PLUS VTVT (1197cc)",
          "ELITE I20 SPORTZ PLUS 1.2 DUAL TONE (1197cc)",
          "MAGNA 1.2 (1197cc)",
          "MAGNA 1.4 (1396cc)",
          "ASTA 1.4 (1396cc)",
          "ERA 1.4 CRDI (1396cc)",
          "ERA 1.4 CRDI (1396cc)",
          "ERA (1396cc)",
          "ASTA OPTION (1396cc)",
          "SPORTZ (1396cc)",
          "ASTA AT (1396cc)",
          "ASTA OPTION AT (1396cc)",
          "SPORTZ OPTION (1396cc)",
          "ASTA 1.4 AT AVN (1396cc)",
          "ASTA 1.4 AVN (1396cc)",
          "SPORTZ AT (1396cc)",
          "ERA 1.4 CRDI BS-IV (1396cc)",
          "MAGNA (O) CRDI (1396cc)",
          "SPORTZ 1.4 AT (1396cc)",
          "ASTA 1.4 O AT WITH SUNROOF (1396cc)",
          "ASTA 1.4 DSL (1396cc)",
          "1.4 ASTA (O) DIESEL WITH SUNROOF (1396cc)",
          "ELITE 1.4L U2 CRDI 6-SPEED MANUAL SPORTZ SPECIAL EDITION (1396cc)",
          "ELITE 1.4 ASTA (O) U2 CRDI 6-SPEED MANUAL DIESEL (1396cc)",
          "ELITE 1.4 ERA U2 CRDI 6-SPEED MANUAL DIESEL (1396cc)",
          "ELITE 1.4 MAGNA U2 CRDI 6-SPEED MANUAL DIESEL (1396cc)",
          "ELITE 1.4 SPORTZ U2 CRDI 6-SPEED MANUAL DIESEL (1396cc)",
          "ELITE 1.4 SPORTZ (O) U2 CRDI 6-SPEED MANUAL DIESEL (1396cc)",
          "ELITE 1.4 ASTA U2 CRDI 6-SPEED MANUAL DIESEL (1396cc)",
          "1.4 MAGNA AT (1396cc)",
          "1.4 ASTA (O) DIESEL (1396cc)",
          "ELITE 1.4L U2 CRDI 6-SPEED MANUAL SPORTZ PLUS (1396cc)",
          "ELITE 1.4 ASTA DUAL TONE U2 CRDI 6-SPEED MANUAL DIESEL (1396cc)",
          "ELITE 1.4 MAGNA EXECUTIVE U2 CRDI 6-SPEED MANUAL DIESEL (1396cc)",
          "SPORTZ 1.2 BSIV (1396cc)",
          "SPORTZ 1.4 CRDI (1396cc)",
          "MAGNA O 1.4 CRDI (1396cc)",
          "SPORTZ 1.4 CRDI O (1396cc)",
          "SPORTZ 1.4 CRDI PLUS (1396cc)",
          "ASTA 1.4 CRDI 6 SPEED (1396cc)",
          "ASTA 1.4 CRDI WITH AVN 6 SPEED (1396cc)",
          "ERA 1.4 CRDI 6 SPEED BS-IV (1396cc)",
          "SPORTZ 1.4 GLDSL BSIV (1396cc)",
          "MAGNA E3 (1396cc)",
          "SPORTZ AT 1.4 (1396cc)",
          "MAGNA 1.4 CRDI (1396cc)",
          "SPORTZ 1.4 CRDI BS-IV (1396cc)",
          "ASTA 1.4 AT WITH AVN (1396cc)",
          "ASTA 1.4 CRDI WITH AVN (1396cc)",
          "MAGNA EXECUTIVE 1.4 CRDI (1396cc)",
          "SPORTZ 1.4 CRDI 6 SPEED BS-IV (1396cc)",
          "ASTA 1.4 CRDI.. (1396cc)",
          "ASTA 1.4 CRDI (1396cc)",
          "ASTA 1.4 CRDI O (1396cc)",
          "ASTA 1.4 CRDI (O) (1396cc)",
          "ASTA 1.4 AT (1396cc)",
          "ASTA 1.4 AT O WITH SUNROOF (1396cc)",
          "ASTA 1.4 O WITH SUNROOF (1396cc)",
          "MAGNA 1.4 CRDI 6 SPEED (1396cc)",
          "SPORTZ 1.4 CRDI 6 SPEED O (1396cc)",
          "MAGNA (1396cc)",
          "ASTA (1396cc)",
          "MAGNA OPTION (1396cc)",
          "1.4 ASTA CRDI (1396cc)",
          "I-GEN 1.4 ASTA DIESEL (1396cc)",
          "ELITE I20 SPORTZ 1.4 (O) (1396cc)",
          "ELITE I20 ASTA 1.4 CRDI (O) (1396cc)",
          "ELITE I20 MAGNA 1.4 CRDI (1396cc)",
          "ELITE I20 SPORTZ 1.4 (1396cc)",
          "MAGNA CRDI OPTION (1396cc)",
          "ELITE I20 ASTA 1.4 CRDI (1396cc)",
          "1.4 ASTA (O) WITH SUNROOF DIESEL (1396cc)",
          "1.4 ASTA CRDI WITH AVN (1396cc)",
          "1.4 MAGNA CRDI (1396cc)",
          "1.4 ERA CRDI (1396cc)",
          "ELITE I20 ASTA 1.4 CRDI DUAL TONE (1396cc)",
          "ELITE I20 ERA 1.4 CRDI (1396cc)",
          "ELITE I20 MAGNA 1.4 CRDI EXECUTIVE (1396cc)",
          "1.4L ASTA (AT) DIESEL (1396cc)",
          "1.4 SPORTZ CRDI (1396cc)",
          "1.4 SPORTZ CRDI (O) (1396cc)",
          "ELITE I20 MAGNA 1.4 AT VTVT (1396cc)",
          "ELITE I20 MAGNA PLUS 1.4 (1396cc)",
          "ELITE I20 SPORTZ PLUS 1.4 (1396cc)",
          "ELITE I20 SPORTZ PLUS 1.4 DUAL TONE (1396cc)",
          "ASTA (O) 1.5 CRDI MT (1493cc)",
          "1.5 CRDI DIESEL 6-SPEED MANUAL MAGNA (1493cc)",
          "1.5 CRDI DIESEL 6-SPEED MANUAL SPORTZ (1493cc)",
          "1.5 CRDI DIESEL 6-SPEED MANUAL SPORTZ DUAL TONE (1493cc)",
          "1.5 CRDI DIESEL 6-SPEED MANUAL ASTA(O) (1493cc)",
          "1.5 CRDI DIESEL 6-SPEED MANUAL ASTA(O) DUAL TONE (1493cc)",
          "ASTA 1.5 O (1493cc)",
          "MAGNA 1.5 (1493cc)",
          "SPORTZ 1.5 DIESEL DUAL TONE (1493cc)",
          "ASTA O 1.5 DIESEL DUAL TONE (1493cc)",
          "SPORTZ 1.5 CRDI MT (1493cc)",
          "SPORTZ 1.5 (1493cc)",
          "N LINE N6 1.0 TURBO IMT (998cc)",
          "N LINE N6 1.0 TURBO IMT DUAL TONE (998cc)",
          "N LINE N8 1.0 TURBO DCT (998cc)",
          "N LINE N8 1.0 TURBO DCT DUAL TONE (998cc)",
          "N LINE N8 1.0 TURBO IMT DUAL TONE (998cc)",
          "1.0 PETROL TURBO GDI SPORTS IMT (998cc)",
          "1.0 PETROL TURBO GDI SPORTS IMT DUAL TONE (998cc)",
          "1.0 PETROL TURBO GDI ASTA IMT (998cc)",
          "1.0 PETROL TURBO GDI ASTA IMT DUAL TONE (998cc)",
          "1.0 PETROL TURBO GDI ASTA DCT (998cc)",
          "1.0 PETROL TURBO GDI ASTA DCT DUAL TONE (998cc)",
          "1.0 PETROL TURBO GDI ASTA (O) DCT (998cc)",
          "1.0 PETROL TURBO GDI ASTA (O) DCT DUAL TONE (998cc)",
          "N LINE 1.0 TURBO GDI PETROL DCT N 8 (998cc)",
          "N LINE 1.0 TURBO GDI PETROL IMT N 8 DUAL TONE (998cc)",
          "N LINE 1.0 TURBO GDI PETROL IMT N 8 (998cc)",
          "N LINE 1.0 TURBO GDI PETROL DCT N 8 DUAL TONE (998cc)",
          "N LINE 1.0 TURBO GDI PETROL IMT N 6 (998cc)",
          "N LINE 1.0 TURBO GDI PETROL IMT N 6 DUAL TONE (998cc)",
          "1.0 PETROL TURBO GDI SPORTZ DCT (998cc)",
          "ASTA 1.0 O TURBO DCT (998cc)",
          "ASTA 1.0 TURBO DCT (998cc)",
          "ASTA 1.0 TURBO IMT DUAL TONE (998cc)",
          "ASTA 1.0 TURBO DCT DUAL TONE (998cc)",
          "ASTA O 1.0 TURBO DCT DUAL TONE (998cc)",
          "N LINE N6 1.0 TURBO GDI IMT (998cc)",
          "SPORTZ 1.0 TURBO IMT DUAL TONE (998cc)",
          "SPORTZ 1.0 TURBO IMT. (998cc)",
          "SPORTZ 1.0 TURBO IMT (998cc)",
          "ASTA 1.0 TURBO IMT. (998cc)",
          "ASTA 1.0 TURBO IMT (998cc)",
          "SPORTZ 1.0 TURBO DCT. (998cc)",
          "SPORTZ 1.0 TURBO DCT (998cc)",
          "N LINE N8 1.0 TURBO IMT (998cc)",
          "N LINE N8 1.0 TURBO GDI DCT (998cc)",
          "N LINE 1.0 N8 TURBO DCT (998cc)",
          "N LINE 1.0 N6 TURBO IMT DUAL TONE (998cc)",
          "N LINE 1.0 N8 TURBO DCT DUAL TONE (998cc)",
          "N LINE 1.0 N8 TURBO IMT DUAL TONE (998cc)",
          "N LINE 1.0 N6 TURBO IMT (998cc)",
          "N LINE 1.0 N8 TURBO IMT (998cc)",
        ],
      },
      {
        model: "GETZ PRIME",
        variants: [
          "GLS (1341cc)",
          "GLS (1341cc)",
          "1.1 GVS 175 TYRE + REAR SPOLIER (1086cc)",
          "1.3 GLS (1341cc)",
          "1.1 GLE (1086cc)",
          "1.3 GLX (1341cc)",
          "1.1 GVS (1086cc)",
          "1.3 GVS (1341cc)",
          "1.5 GVS CRDI (1493cc)",
          "GVS (1086cc)",
          "GLE (1086cc)",
          "1.1 GVS O (1086cc)",
          "GTX ABS (1086cc)",
          "1.1 GVS OPTION (1086cc)",
          "GLX (1298cc)",
          "GVS 1.3 (1341cc)",
          "GVS 1.5 VGT (1496cc)",
          "1.5 GVS (1493cc)",
          "1.6 GVS (1560cc)",
        ],
      },
      {
        model: "XCENT",
        variants: [
          "1.2 KP DVTVT BASE CNG T (1197cc)",
          "1.2 KAPPA DUAL VTVT 4SP S (AT) (1197cc)",
          "1.2 KAPPA DUAL VTVT 5SP E (MT) (1197cc)",
          "1.2 KAPPA DUAL VTVT 5SP S (MT) (1197cc)",
          "1.2 KP DVTVT 5SP SX (MT) (1197cc)",
          "1.2 KP DVTVT 5SP SX (O) (MT) (1197cc)",
          "1.2 U2 CRDI 5SPEED E (MT) (1186cc)",
          "1.2 U2 CRDI 5SPEED S (MT) (1186cc)",
          "1.2 U2 CRDI 5SPEED SX (MT) (1186cc)",
          "1.2 U2 CRDI 5SPEED SX(O)(MT) (1186cc)",
          "1.1 U2 CRDI 2ND GEN 5SP E+ MT (1120cc)",
          "1.1 U2 CRDI 2ND GEN 5SP S(O)MT (1120cc)",
          "1.1 U2 CRDI 2ND GEN 5SP SX(MT) (1120cc)",
          "1.2 KP DVTVT 4SP SX (O) (AT) (1197cc)",
          "1.2 KP DVTVT 5SP BASE (MT) (1197cc)",
          "1.2 KP DVTVT 5SP BASE (ABS) MT (1197cc)",
          "1.2 KP DVTVT 5SP S (O) (MT) (1197cc)",
          "1.2 KAPPA DUAL VTVT 5SP S (AT) (1197cc)",
          "1.1 U2 CRDI 2ND GEN 5SP BASE (1120cc)",
          "1.1 U2 CRDI 2ND GEN 5SP SX(O) (1120cc)",
          "1.2 KP DUAL VTVT S 4SP(O)(AT) (1197cc)",
          "VTVT SE (1120cc)",
          "SX O DSL (1197cc)",
          "1.2 U2 CRDI E DSL (1186cc)",
          "1.2 U2 CRDI BASE DSL T (1186cc)",
          "1.2 U2 CRDI S DSL (1186cc)",
          "1.2 U2 CRDI SX DSL (1186cc)",
          "1.2 U2 CRDI SX (O) DSL (1186cc)",
          "1.2 KP DVTVT BASE LPG T (1197cc)",
          "1.2 KP DVTVT S - CNG (1197cc)",
          "1.2 U2 CRDI 2ND GEN 5SP S MT (1120cc)",
          "1.2 KP DVTVT 5SP SE (MT) (1197cc)",
          "PRIME VTVT T PLUS CNG (1197cc)",
          "PRIME VTVT T PLUS (1197cc)",
          "S VTVT BSIV (1197cc)",
          "1.2 VTVT PLUS ABS (1197cc)",
          "VTVT S (1197cc)",
          "1.2 KAPPA DUAL VTVT 4-SP AT S (1197cc)",
          "1.2 KAPPA DUAL VTVT 5-SP M E (1197cc)",
          "1.2 KAPPA DUAL VTVT 5-SP M S (1197cc)",
          "1.2 KAPPA DUAL VTVT 5-SP M SX (1197cc)",
          "1.2 KP DVTVT 5-SP M SX (O) (1197cc)",
          "1.2 U2 CRDI 5-SPEED M SX (O) (1197cc)",
          "1.1 U2 CRDI 2ND GEN 5-SP M E + (1197cc)",
          "1.1 U2 CRDI 2ND GEN 5SP M S(O) (1197cc)",
          "1.1 U2 CRDI 2ND GEN 5-SP M SX (1197cc)",
          "1.2L KP DVTVT 4-SP AT SX (O) (1197cc)",
          "1.2L KP DVTVT 5-SP M BASE (1197cc)",
          "1.2L KP DVTVT 5-SP M BASE ABS (1197cc)",
          "1.2L KP DVTVT 5-SP M S (O) (1197cc)",
          "1.2 U2 CRDI BASE T+ (1120cc)",
          "1.2 KPÂ  DVTVT BASE T (1197cc)",
          "1.2 U2 CRDI PRIME T (1186cc)",
          "1.2 U2 CRDI PRIME T+ (1120cc)",
          "1.2 KP DVTVT PRIME T+ (1197cc)",
          "1.2 KAPPA DUAL VTVT PRIME T (1197cc)",
          "1.1 U2 CRDI S (1120cc)",
          "1.2 U2 CRDI 5-SPEED MANUAL S (1197cc)",
          "1.2 U2 CRDI 5-SPEED MANUAL SX (1197cc)",
          "1.2 U2 CRDI 5-SPEED MANUAL E (1197cc)",
          "1.2 CRDI S (1186cc)",
          "1.2 CRDI S (1186cc)",
          "1.2 CRDI SX OPTION (1186cc)",
          "1.2 CRDI E PLUS (1186cc)",
          "1.2 CRDI E (1186cc)",
          "1.2 CRDI E (1186cc)",
          "1.2 CRDI ABS (1186cc)",
          "1.2 U2 CRDI 5-SPEED MANUAL PRIME T PLUS (1186cc)",
          "1.2 U2 CRDI 5-SPEED MANUAL E PLUS (1186cc)",
          "1.2 U2 CRDI 5-SPEED MANUAL SX (O) (1186cc)",
          "E PLUS (1186cc)",
          "E (1186cc)",
          "E PLUS CRDI (1186cc)",
          "S CRDI (1186cc)",
          "SX CRDI (1186cc)",
          "1.2 U2 CRDI 5-SPEED MANUAL S (1186cc)",
          "1.2 U2 CRDI 5-SPEED MANUAL SX (1186cc)",
          "1.2 U2 CRDI 5-SPEED MANUAL E (1186cc)",
          "1.2 CRDI SX (1186cc)",
          "1.2 CRDI SX (1186cc)",
          "CRDI S (1186cc)",
          "CRDI E (1186cc)",
          "1.2 CRDI SX (O) (1186cc)",
          "CRDI E PLUS (1186cc)",
          "PRIME T PLUS CRDI (1186cc)",
          "PRIME T CRDI (1186cc)",
          "1.2 S (1197cc)",
          "1.2 SX (1197cc)",
          "1.2 KAPPA SX OPTION (1197cc)",
          "1.2 KAPPA SX OPTION AT (1197cc)",
          "1.2 S OPTION (1197cc)",
          "1.2 VTVT S (1197cc)",
          "1.2 VTVT S.. (1197cc)",
          "ASTA (1197cc)",
          "BASE (1197cc)",
          "S OPTION AT (1197cc)",
          "SX OPTION AT (1197cc)",
          "SX OPTION MT (1197cc)",
          "S OPTION MT (1197cc)",
          "SX OPTION (1197cc)",
          "SE (1197cc)",
          "1.2 VTVT SX (1197cc)",
          "1.2 AT S OPTION (1197cc)",
          "1.2 VTVT E PLUS (1197cc)",
          "1.2 VTVT S OPTION (1197cc)",
          "1.2 MT S OPTION (1197cc)",
          "1.2 VTVT PRIME T (1197cc)",
          "1.2 VTVT PRIME T PLUS (1197cc)",
          "1.2 VTVT SX O (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL BASE (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL S (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL S (O) (1197cc)",
          "1.2L KAPPA DUAL VTVT 4-SPEED AUTOMATIC S (O) (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL SX (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL SX (O) (1197cc)",
          "1.2L KAPPA DUAL VTVT 4-SPEED AUTOMATIC SX (O) (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL PRIME T PLUS CNG (1197cc)",
          "1.2L KAPPA DUAL VTVT 4-SPEED AUTOMATIC S (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL S SPECIAL EDITION (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL BASE ABS (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL S ABS (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL PRIME T CNG (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL E (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL E PLUS (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL PRIME T (1197cc)",
          "1.2L KAPPA DUAL VTVT 5-SPEED MANUAL PRIME T PLUS (1197cc)",
          "S 1.2 O (1197cc)",
          "PRIME T (1197cc)",
          "1.2 KAPPA AT SX O (1197cc)",
          "1.2 KAPPA SX O MT (1197cc)",
          "S 1.2 SPECIAL EDITION (1197cc)",
          "1.2 KAPPA SX MT (1197cc)",
          "S ABS 1.2 (1197cc)",
          "VTVT + ABS (1197cc)",
          "1.2 VTVT S O PETROL (1197cc)",
          "S AT 1.2 O (1197cc)",
          "VTVT PRIME T PLUS CNG (1197cc)",
          "E PLUS (1197cc)",
          "S (1197cc)",
          "SX (1197cc)",
          "E (1197cc)",
          "S AT (1197cc)",
          "SX AT 1.2 O (1197cc)",
          "SX CRDI O (1197cc)",
          "SX O (1197cc)",
          "1.2 KAPPA BASE CNG (1197cc)",
          "1.2 KAPPA S CNG (1197cc)",
          "1.2 KAPPA SX CNG (1197cc)",
          "1.2 KAPPA SX O CNG (1197cc)",
          "SX CRDI (1197cc)",
          "1.2 KAPPA BASE (1197cc)",
          "BASE ABS 1.2 (1197cc)",
          "E CRDI (1197cc)",
          "1.2 (1197cc)",
          "VTVT SX (O) (1197cc)",
          "VTVT BASE (1197cc)",
          "PRIME T PLUS CNG (1197cc)",
          "VTVT S (O) AT (1197cc)",
          "VTVT S (O) (1197cc)",
          "VTVT PRIME T PLUS (1197cc)",
          "VTVT SX (O) AT (1197cc)",
          "VTVT E (1197cc)",
          "VTVT S AT (1197cc)",
          "PRIME T PLUS CRDI (1197cc)",
          "VTVT E PLUS (1197cc)",
          "VTVT SX (1197cc)",
          "PRIME T+CNG (1197cc)",
          "PRIME T CNG (1197cc)",
          "PRIME T CRDI (1197cc)",
          "1.1 CRDI (1120cc)",
          "1.1 CRDI S (1120cc)",
          "1.1 CRDI SX (1120cc)",
          "1.1 CRDI SX OPTION (1120cc)",
          "ASTA (1120cc)",
          "BASE (1120cc)",
          "SX OPTION (1120cc)",
          "S OPTION (1120cc)",
          "1.1 CRDI MT SX (1120cc)",
          "CRDI PLUS ABS. (1120cc)",
          "1.1 CRDI PRIME T (1120cc)",
          "1.1 CRDI PRIME T PLUS (1120cc)",
          "2ND GEN 1.1 U2 CRDI 5-SPEED MANUAL PRIME T PLUS (1120cc)",
          "1.1 U2 CRDI 2ND GEN 5-SPEED MANUAL BASE (1120cc)",
          "1.1 U2 CRDI 2ND GEN 5-SPEED MANUAL S (1120cc)",
          "1.1 U2 CRDI 2ND GEN  5-SPEED MANUAL S (O) (1120cc)",
          "1.1 U2 CRDI 2ND GEN 5-SPEED MANUAL SX (1120cc)",
          "1.1 U2 CRDI 2ND GEN 5-SPEED MANUAL SX (O) (1120cc)",
          "2ND GEN 1.1 U2 CRDI 5-SPEED MANUAL BASE ABS (1120cc)",
          "2ND GEN 1.1 U2 CRDI 5-SPEED MANUAL S ABS (1120cc)",
          "2ND GEN 1.1 U2 CRDI 5-SPEED MANUAL PRIME T (1120cc)",
          "2ND GEN 1.1 U2 CRDI 5-SPEED MANUAL PRIME (1120cc)",
          "1.1 U2 CRDI 2ND GEN 5-SPEED MANUAL E (1120cc)",
          "1.1 U2 CRDI 2ND GEN 5-SPEED MANUAL E PLUS (1120cc)",
          "SE 1.1 CRDI (1120cc)",
          "CRDI + ABS (1120cc)",
          "S (1120cc)",
          "SX (1120cc)",
          "1.1 CRDI MT BASE (1120cc)",
          "S 1.1 CRDI (1120cc)",
          "S 1.1 CRDI O (1120cc)",
          "SX 1.1 CRDI O (1120cc)",
          "CRDI BASE (1120cc)",
          "CRDI S (1120cc)",
          "CRDI SX (1120cc)",
          "CRDI SX (O) (1120cc)",
          "CRDI S (O) (1120cc)",
          "1.1 CRDI S OPTION (1120CC) (1500cc)",
        ],
      },
      {
        model: "ELANTRA",
        variants: [
          "CRDI S (1493cc)",
          "2.0 NU MPI 6SPEED SX (AT) (1999cc)",
          "2.0 NU MPI 6SPEED SX(O) (AT) (1999cc)",
          "2.0 NU MPI 6SPEED S (MT) (1999cc)",
          "2.0 NU MPI 6SPEED SX (MT) (1999cc)",
          "2.0 NU MPI 6SPEED SX (O) (MT) (1999cc)",
          "1.6 U2 CRDI 6SPEED SX(O) (AT) (1582cc)",
          "1.6 U2 CRDI 6SPEED S (MT) (1582cc)",
          "1.6 U2 CRDI 6SPEED SX (MT) (1582cc)",
          "1.6 U2 CRDI 6SPEED SX(O) (MT) (1582cc)",
          "VTVT S 1.8 BSIV (1797cc)",
          "1.6 CRDI SX AT (1600cc)",
          "CRDI SX O AUTO (1493cc)",
          "1.8 SX VTVT (1797cc)",
          "CRDI SX (1498cc)",
          "CRDI SX (1493cc)",
          "1.6 S MT (1582cc)",
          "NU 2.0 MPI 6-SPEED MANUAL S (1999cc)",
          "NU 2.0 MPI 6-SPEED MANUAL SX (1999cc)",
          "U2 1.6 CRDI 6-SPEED MANUAL S (1582cc)",
          "U2 1.6 CRDI 6-SPEED MANUAL SX (1582cc)",
          "NU 2.0 MPI 6-SPEED AT SX(O) (1999cc)",
          "NU 2.0 MPI 6-SPEED M SX (O) (1999cc)",
          "U2 1.6 CRDI 6-SPEED AT SX(O) (1582cc)",
          "U2 1.6 CRDI 6-SPEED M SX(O) (1582cc)",
          "NU 2.0 MPI 6-SPEED AT SX (1999cc)",
          "CRDI (1991cc)",
          "CRDI (1991cc)",
          "GLS (1795cc)",
          "GLS (1795cc)",
          "GT (1795cc)",
          "GT (1795cc)",
          "CRDI DECONTENT (1991cc)",
          "CRDI 1.6 SX AT (1582cc)",
          "CRDI 1.6 SX AT (1582cc)",
          "1.6 CRDI (1582cc)",
          "CRDI 1.6 SX OPTION AT (1582CC) 1.6 SX OPTION (1582cc)",
          "CRDI S (1582cc)",
          "CRDI SX (1582cc)",
          "CRDI SX (1582cc)",
          "FLUIDIC SX (1582cc)",
          "FLUIDIC S (1582cc)",
          "FLUIDIC SX AT (1582cc)",
          "FLUIDIC BASE (1582cc)",
          "CRDI 1.6 (1582cc)",
          "CRDI 1.6 S (1582cc)",
          "CRDI 1.6 SX (1582cc)",
          "FLUIDIC CRDI SX 1.6 BSIV (1582cc)",
          "1.6 SX AT (1582cc)",
          "1.6 SX MT (1582cc)",
          "1.6 BASE (1582cc)",
          "1.6 L MT (1582cc)",
          "1.6 SX O (1582cc)",
          "1.6 SX O AT (1582cc)",
          "FLUIDIC SX (1797cc)",
          "FLUIDIC S (1797cc)",
          "FLUIDIC SX AT (1797cc)",
          "SX AT (1797cc)",
          "S (1797cc)",
          "FLUIDIC 1.8 SX AT (1797cc)",
          "1.8 S (1797cc)",
          "1.8 SX MT (1797cc)",
          "1.8 SX AT (1797cc)",
          "S (1999cc)",
          "SX (1999cc)",
          "SX OPTION AT (1999cc)",
          "SX OPTION (1999cc)",
          "VTVT SX(O) (1999cc)",
          "2.0 SX AT (1999cc)",
          "2.0 SX O (1999cc)",
          "2.0 SX O AT (1999cc)",
          "2.0 S MT (1999cc)",
          "2.0 SX MT (1999cc)",
          "SX (Occ)",
          "GLS LEATHER (1795cc)",
          "GT DECONTENT (1795cc)",
          "CRDI. (1795cc)",
          "1.5 SX MT (1493cc)",
          "1.5 SX (O) AT (1493cc)",
          "1.5 SX O AT (1493cc)",
          "CRDI LEATHER (1991cc)",
        ],
      },
      {
        model: "TUCSON",
        variants: [
          "2.0 DUAL VTVT 2WD MT (1999cc)",
          "2.0 E-VGT 2WD AT GL (1995cc)",
          "2.0 E-VGT 2WD MT (1995cc)",
          "2.0 NU 6SPEED MATIC GL (AT) (1999cc)",
          "2.0 NU 6SPEED BASE (MT) (1999cc)",
          "2.0 R 6SPEED MATIC GL (AT) (1995cc)",
          "2.0 R 6SPEED MATIC GLS (AT) (1995cc)",
          "2.0 R 6SPEED BASE (MT) (1995cc)",
          "2.0 NU 6SPEED MATIC GLS (AT) (1999cc)",
          "2.0 E-VGT 4WD AT GLS (1995cc)",
          "2.0 DUAL VTVT GL AT (1999cc)",
          "2.0 DUAL VTVT GLS AT (1999cc)",
          "2.0 DUAL VTVT GL AT (O) (1999cc)",
          "2.0 DUAL VTVT L (1999cc)",
          "2.0 CRDI L (1995cc)",
          "2.0 CRDI AUTO GL (1995cc)",
          "2.0 CRDI AUTO GL(O) (1995cc)",
          "2.0 CRDI 4WD AUTO GLS (1995cc)",
          "GLS CRDI 2WD AUTO (1995cc)",
          "2.0 TURBO CRDI (5 STR) (1991cc)",
          "2.0 TURBO CRDI (1991cc)",
          "NU 2.0 6-SPEED MANUAL BASE (1999cc)",
          "NU 2.0 6-SPEED AUTOMATIC GL (1999cc)",
          "R 2.0 6-SPEED MANUAL BASE (1999cc)",
          "R 2.0 6-SPEED AUTOMATIC GL (1999cc)",
          "R 2.0 6-SPEED AUTOMATIC GLS (1999cc)",
          "TUCSON 2.0PL AT PLATINUM (1999cc)",
          "NU 2.0 6-SPEED AUTOMATIC GL (O) (1999cc)",
          "NU 2.0 PETROL 6-SPEED AUTOMATIC SIGNATURE AT (1999cc)",
          "NU 2.0 PETROL 6-SPEED AUTOMATIC SIGNATURE AT DT (1999cc)",
          "NU 2.0 PETROL 6-SPEED AUTOMATIC PLATINUM AT (1999cc)",
          "NU 2.0 6-SPEED AUTOMATIC GLS (1999cc)",
          "PLATINUM AT (1999cc)",
          "PLATINUM 2.0 AT PETROL (1999cc)",
          "PLATINUM AT BSVI (1999cc)",
          "2.0L PLATINUM AT PETROL (1999cc)",
          "2.0 2WD AT GLS DIESEL 4 STR (1995cc)",
          "R 2.0 DIESEL 8-SPEED AUTOMATIC GLS 4WD (1995cc)",
          "R 2.0 DIESEL 8-SPEED AUTOMATIC GLS (1995cc)",
          "R 2.0 DIESEL 8-SPEED AUTOMATIC DSL SIGNATURE AT (1995cc)",
          "CRDI MT (1991cc)",
          "7 SEATER (1991cc)",
          "5 SEATER (1991cc)",
          "CRDI 7 STR (1991cc)",
          "2.0 CRDI (1991cc)",
          "TUSCON (1991cc)",
          "CRDI 5STR (1991cc)",
          "CRDI (1991cc)",
          "E-VGT GL AT (1995cc)",
          "E-VGT BASE (1995cc)",
          "E-VGT GLS AT (1995cc)",
          "2.0 E-VGT 2WD AT GLS (1995cc)",
          "R 2.0 6-SPEED AUTOMATIC GL(O) (1995cc)",
          "2WD AT GL (O) DIESEL (1995cc)",
          "R 2.0 6-SPEED MANUAL BASE (1995cc)",
          "R 2.0 6-SPEED AUTOMATIC GL (1995cc)",
          "R 2.0 6-SPEED AUTOMATIC GLS (1995cc)",
          "2WD (O) AT GL DIESEL (1995cc)",
          "2WD AT GL DIESEL (1995cc)",
          "2WD AT GLS DIESEL (1995cc)",
          "2WD MT DIESEL (1995cc)",
          "CRDI (1995cc)",
          "4WD AT GLS DIESEL (1995cc)",
          "VTVT  BASE (1999cc)",
          "VTVT GL AT (1999cc)",
          "2.0 DUAL VTVT 2WD AT GL (1999cc)",
          "SIGNATURE 2.0 PETROL AT (1999cc)",
          "GL (O) AT (1999cc)",
          "PL 2WD AT GLS (1999cc)",
          "2WD AT GL (O) PETROL (1999cc)",
          "SIGNATURE AT (1999cc)",
          "SIGNATURE AT DT (1999cc)",
          "SIGNATURE 2.0 AT PETROL DUAL TONE (1999cc)",
          "VTVT AUTO GLS (1999cc)",
          "SIGNATURE 2.0 AT PETROL (1999cc)",
          "SIGNATURE AT BSVI (1999cc)",
          "SIGNATURE AT DT BSVI (1999cc)",
          "2WD (O) AT GL PETROL (1999cc)",
          "2.0L SIGNATURE AT PETROL (1999cc)",
          "2.0L SIGNATURE AT DUAL TONE PETROL (1999cc)",
          "2WD AT GL PETROL (1999cc)",
          "2WD MT PETROL (1999cc)",
          "2WD AT GLS PETROL (1999cc)",
          "TUCSON 2.0DSL AT PLATINUM (1997cc)",
          "R 2.0 DIESEL 8-SPEED AUTOMATIC DSL SIGNATURE AT (1997cc)",
          "R 2.0 DIESEL 8-SPEED AUTOMATIC DSL SIGNATURE 4WD AT (1997cc)",
          "R 2.0 DIESEL 8-SPEED AUTOMATIC DSL PLATINUM AT (1997cc)",
          "R 2.0 DIESEL 8-SPEED AUTOMATIC DSL SIGNATURE AT DT (1997cc)",
          "R 2.0 DIESEL 8-SPEED AUTOMATIC DSL SIGNATURE 4WD AT DT (1997cc)",
          "PLATINUM DIESEL AT (1997cc)",
          "SIGNATURE DIESEL AT (1997cc)",
          "SIGNATURE DIESEL AT DT (1997cc)",
          "SIGNATURE DIESEL 4WD AT (1997cc)",
          "SIGNATURE DIESEL 4WD AT DT (1997cc)",
          "SIGNATURE 2.0 4WD AT DIESEL DUAL TONE (1997cc)",
          "SIGNATURE 2.0 AT DIESEL DUAL TONE (1997cc)",
          "SIGNATURE 2.0 4WD AT DIESEL (1997cc)",
          "PLATINUM 2.0 AT DIESEL (1997cc)",
          "SIGNATURE 2.0 AT DIESEL (1997cc)",
          "PLATINUM AT BSVI (1997cc)",
          "SIGNATURE AT BSVI (1997cc)",
          "SIGNATURE AT DT BSVI (1997cc)",
          "SIGNATURE 4WD AT BSVI (1997cc)",
          "SIGNATURE 4WD AT DT BSVI (1997cc)",
          "2.0L SIGNATURE AT DUAL TONE DIESEL (1997cc)",
          "2.0L SIGNATURE 4WD AT DUAL TONE DIESEL (1997cc)",
          "2.0L SIGNATURE 4WD AT DIESEL (1997cc)",
          "2.0L SIGNATURE AT DIESEL (1997cc)",
          "2.0L PLATINUM AT DIESEL (1997cc)",
        ],
      },
      {
        model: "VENUE",
        variants: [
          "1.2 KAPPA E (MT) (1197cc)",
          "1.2 KAPPA S (MT) (1197cc)",
          "1.0 TURBO GDI S (MT) (998cc)",
          "1.0 TURBO GDI SX (MT) (998cc)",
          "1.0 TURBO GDI SX DT (MT) (998cc)",
          "1.0 TURBO GDI SX (O)(MT) (998cc)",
          "1.0 TURBO GDI S DCT (998cc)",
          "1.0 TURBO GDI SX PLUS DCT (998cc)",
          "1.4 CRDI SX DT (MT) (1396cc)",
          "1.5 CRDI E (MT) (1493cc)",
          "1.5 CRDI S (MT) (1493cc)",
          "1.5 CRDI SX (MT) (1493cc)",
          "1.5 CRDI SX DT (MT) (1493cc)",
          "1.5 CRDI SX (O) (MT) (1493cc)",
          "1.5 CRDI SX DT (O) (MT) (1493cc)",
          "1.2 KAPPA S PLUS BSVI (1197cc)",
          "1.0 TURBO GDI SX IMT (998cc)",
          "1.0 TURBO GDI SX SPORT IMT (998cc)",
          "1.5 CRDI SX SPORT (MT) (1493cc)",
          "1.0 TURBO GDI SX (O) IMT (998cc)",
          "1.0 TURBO GDI SX (O) SPORT IMT (998cc)",
          "1.5 CRDI SX (O) SPORT (MT) (1493cc)",
          "1.5 CRDI SX (O) EXECUTIVE (1493cc)",
          "1.0 TURBO S(O) DCT (998cc)",
          "1.2 KAPPA E (MT) BSVI (1197cc)",
          "1.2 KAPPA S (MT) BSVI (1197cc)",
          "1.2 KAPPA S (O)(MT) BSVI (1197cc)",
          "1.0 TURBO GDI S (O) IMT BSVI (998cc)",
          "1.2 KAPPA SX (MT) BSVI (1197cc)",
          "1.2 KAPPA SX (MT) DT BSVI (1197cc)",
          "1.0 TURBO GDI DCT S (O) BSVI (998cc)",
          "1.0 TURBO GDI IMT SX(O) BSVI (998cc)",
          "1.0 TURBO GDI IMT SX(O)DT BSVI (998cc)",
          "1.0 TURBO GDI DCT SX(O) BSVI (998cc)",
          "1.0 TURBO GDI DCT SX(O)DT BSVI (998cc)",
          "1.5 CRDI S PLUS (MT) BSVI (1493cc)",
          "1.5 CRDI SX (MT) BSVI (1493cc)",
          "1.5 CRDI SX (MT) DT BSVI (1493cc)",
          "1.5 CRDI SX(O)(MT) BSVI (1493cc)",
          "1.5 CRDI SX(O)(MT) DT BSVI (1493cc)",
          "1.4 CRDI E MT (1396cc)",
          "1.4 CRDI S MT (1396cc)",
          "1.4 CRDI SX MT (1396cc)",
          "1.4 CRDI SX O MT (1396cc)",
          "1.0 TURBO GDI DCT SX PLUS (998cc)",
          "1.0 TURBO GDI DCT SX PLUS (998cc)",
          "S 1.0 PETROL (998cc)",
          "S 1.0 AT PETROL (998cc)",
          "SX 1.0 PETROL (998cc)",
          "SX 1.0 AT DUAL TONE PETROL (998cc)",
          "SX PLUS 1.0 AT PETROL (998cc)",
          "SX PLUS 1.0 AT DUAL TONE PETROL (998cc)",
          "1.0 TURBO SX(O) IMT DT BS6 (998cc)",
          "1.0 TURBO SX(O) DCT BS6 (998cc)",
          "1.0 TURBO SX(O) DCT DT BS6 (998cc)",
          "N LINE N6 TURBO DCT (998cc)",
          "N LINE N8 TURBO DCT (998cc)",
          "1.0 TURBO GDI DCT SX+ (998cc)",
          "1.0 CRDI SX O MT TURBO (998cc)",
          "1.0 S TURBO (998cc)",
          "1.0 S TURBO AT (998cc)",
          "1.0 SX MT DUAL TONE TURBO (998cc)",
          "1.0 SX PLUS TURBO DCT (998cc)",
          "1.0 SX TURBO (998cc)",
          "SX 1.0 (O) DUAL TONE PETROL (998cc)",
          "SX 1.0 (O) PETROL (998cc)",
          "1.0 KAPPA TURBO GDI SPORT SX IMT (998cc)",
          "1.0 KAPPA TURBO GDI SX(O) IMT (998cc)",
          "1.0 KAPPA TURBO GDI SX IMT (998cc)",
          "1.0 KAPPA TURBO GDI S(O) IMT (998cc)",
          "1.0 KAPPA TURBO GDI PETROL DCT S (O) (998cc)",
          "1.0 KAPPA TURBO GDI PETROL DCT N6 N LINE (998cc)",
          "1.0 TURBO DCT DT SX(O) (998cc)",
          "1.0 KAPPA TURBO GDI PETROL DCT N8 N LINE (998cc)",
          "1.0 TURBO GDI PETROL DCT SX(O) (998cc)",
          "1.0 KAPPA TURBO GDI SX(O) IMT DT (998cc)",
          "1.0 TURBO DCT SX(O) (998cc)",
          "1.0 KAPPA TURBO GDI PETROL DCT N6 N LINE DUAL TONE (998cc)",
          "1.0 KAPPA TURBO GDI PETROL DCT N8 N LINE DUAL TONE (998cc)",
          "1.0 KAPPA TURBO GDI PETROL 6-SPEED MANUAL S (998cc)",
          "1.0 KAPPA TURBO GDI PETROL 6-SPEED MANUAL SX (998cc)",
          "1.0 KAPPA TURBO GDI PETROL 6-SPEED MANUAL SX DUAL TONE (998cc)",
          "1.0 KAPPA TURBO GDI PETROL 6-SPEED MANUAL SX(O) (998cc)",
          "1.0 KAPPA TURBO GDI PETROL DCT S (998cc)",
          "1.0 KAPPA TURBO GDI PETROL DCT SX PLUS (998cc)",
          "SX (O) 1.0 TURBO DCT (998cc)",
          "SX O 1.0 TURBO DCT (998cc)",
          "SX (O) 1.0 TURBO DCT DUAL TONE (998cc)",
          "SX O 1.0 TURBO DCT DUAL TONE (998cc)",
          "S 1.0 TURBO (998cc)",
          "SX 1.0 TURBO (998cc)",
          "SX 1.0 DUAL TONE PETROL (998cc)",
          "SX 1.0 O TURBO (998cc)",
          "SX PLUS 1.0 TURBO DCT (998cc)",
          "S 1.0 TURBO DCT (998cc)",
          "SX 1.0 TURBO DCT DUAL TONE (998cc)",
          "SX 1.0 O TURBO DUAL TONE (998cc)",
          "1.0 SX IMT PET (998cc)",
          "1.2 S MT (998cc)",
          "1.0 TURBO GDI MT S (998cc)",
          "SX (O) 1.0 TURBO IMT DUAL TONE (998cc)",
          "SX O 1.0 TURBO IMT DUAL TONE (998cc)",
          "N LINE N8 1.0 TURBO DCT (998cc)",
          "S O 1.0 TURBO IMT (998cc)",
          "SX O 1.0 TURBO IMT (998cc)",
          "SX 1.0 TURBO IMT (998cc)",
          "SX PLUS 1.0 TURBO DCT DUAL TONE (998cc)",
          "S O 1.0 TURBO DCT (998cc)",
          "SPORT SX (O) 1.0 TURBO IMT. (998cc)",
          "SPORT SX O 1.0 TURBO IMT (998cc)",
          "SPORT SX 1.0 TURBO IMT. (998cc)",
          "SPORT SX 1.0 TURBO IMT (998cc)",
          "SPORT SX PLUS 1.0 TURBO DCT. (998cc)",
          "SPORT SX PLUS 1.0 TURBO DCT (998cc)",
          "SX PLUS (O) 1.0 AT PETROL. (998cc)",
          "SX PLUS (O) 1.0 AT PETROL (998cc)",
          "N LINE N6 DCT DUAL TONE (998cc)",
          "N LINE N8 DCT DUAL TONE (998cc)",
          "1.0 S AT PETROL (998cc)",
          "1.0 SX DUAL TONE PETROL (998cc)",
          "1.0 SX PETROL (998cc)",
          "1.0 SX PLUS TURBO DCT DT (P) (998cc)",
          "1.0 SX (O) TURBO DUAL TONE PETROL (998cc)",
          "1.0 SX TURBO IMT DT PETROL (998cc)",
          "1.0 SX PLUS AT (O ) PETROL (998cc)",
          "1.0 SX TURBO DUAL TONE PETROL (998cc)",
          "1.0 SX TURBO IMT PETROL (998cc)",
          "1.0 SX PLUS AT PETROL (998cc)",
          "1.0 SX TURBO PETROL (998cc)",
          "1.0 SX (O) TURBO IMT PETROL (998cc)",
          "1.0 SX SPORT TURBO IMT PETROL (998cc)",
          "1.0 SX (O) TURBO PETROL (998cc)",
          "1.0 S TURBO (P) (998cc)",
          "1.0 S TURBO DCT (P) (998cc)",
          "1.0 S (O) TURBO IMT (P) (998cc)",
          "1.0 S (O) TURBO DCT (P) (998cc)",
          "1.0 SX PLUS SPORT TURBO DCT PETROL (998cc)",
          "1.0 SX (O) SPORT TURBO IMT PETROL (998cc)",
          "1.0 S PETROL (998cc)",
          "1.0 SX (O) PETROL (998cc)",
          "1.0 SX PLUS TURBO DCT (P) (998cc)",
          "1.0 SX (O) TURBO IMT DT PETROL (998cc)",
          "1.0 SX (O) TURBO DCT DUAL TONE (P) (998cc)",
          "1.0 SX (O) TURBO DCT PETROL (998cc)",
          "1.2 S PLUS PETROL BS6 (1197cc)",
          "1.2 SX PETROL MT (1197cc)",
          "1.2 S O PETROL BS6 (1197cc)",
          "1.2 SX DT BS6 (1197cc)",
          "1.2 KAPPA DUAL VTVT PETROL 5-SPEED MANUAL S PLUS (1197cc)",
          "1.2 KAPPA PETROL 5-SPEED MANUAL VENUE  S (O) (1197cc)",
          "1.2  KAPPA PETROL 5 SPEED MANUAL SX (1197cc)",
          "1.2  KAPPA PETROL 5 SPEED MANUAL DUAL TONE SX (1197cc)",
          "1.2 KAPPA MT S(O) (1197cc)",
          "1.2 KAPPA DUAL VTVT PETROL 5-SPEED MANUAL E (1197cc)",
          "1.2 KAPPA DUAL VTVT PETROL 5-SPEED MANUAL S (1197cc)",
          "SX 1.2 PETROL (1197cc)",
          "E 1.2 PETROL (1197cc)",
          "S 1.2 PETROL (1197cc)",
          "1.2 SX MT PTR (1197cc)",
          "S (O) 1.2 PETROL (1197cc)",
          "S O 1.2 PETROL (1197cc)",
          "SX 1.2 PETROL DUAL TONE (1197cc)",
          "SX KNIGHT (1197cc)",
          "S PLUS 1.2 PETROL (1197cc)",
          "1.2 KAPPA MT S (1197cc)",
          "1.2 S PLUS PETROL (1197cc)",
          "1.2 SX PETROL (1197cc)",
          "1.2 SX DUAL TONE PETROL (1197cc)",
          "1.2 S (O) PETROL (1197cc)",
          "1.2 E PETROL (1197cc)",
          "1.2 S PETROL (1197cc)",
          "S PLUS (1493cc)",
          "S PLUS 1.5 CRDI (1493cc)",
          "1.5 SX BS6 (1498cc)",
          "1.5 SX DT BS6 (1498cc)",
          "1.5 SX(O) BS6 (1498cc)",
          "1.5 SX(O) DT BS6 (1498cc)",
          "SX TURBO EXECUTIVE (1498cc)",
          "1.5 S PLUS BS6 (1493cc)",
          "1.5 CRDI MT SX (1493cc)",
          "1.5 CRDI MT SX(O) (1493cc)",
          "1.5 DIESEL E (1493cc)",
          "1.5 DIESEL S (1493cc)",
          "1.5 DIESEL SX (1493cc)",
          "1.5 DIESEL SX-DT (1493cc)",
          "1.5 DIESEL SX (OP) (1493cc)",
          "1.5 DIESEL SX (OP) DT (1493cc)",
          "1.5 CRDI DIESEL MANUAL SX (1493cc)",
          "1.5 DIESEL SX (OP) EXECUTIVE (1493cc)",
          "1.5 CRDI DIESEL MANUAL S PLUS (1493cc)",
          "1.5 DIESEL S (OP) (1493cc)",
          "SX (O) 1.5 CRDI MT (1493cc)",
          "SX 1.5 (O) CRDI (1493cc)",
          "SX 1.5 CRDI MT (1493cc)",
          "S O 1.5 CRDI (1493cc)",
          "SX O 1.5 CRDI EXECUTIVE (1493cc)",
          "SX 1.5 O CRDI DUAL TONE (1493cc)",
          "E 1.5 CRDI. (1493cc)",
          "E 1.5 CRDI (1493cc)",
          "SPORT SX (O) 1.5 CRDI. (1493cc)",
          "SPORT SX O 1.5 CRDI (1493cc)",
          "SPORT SX 1.5 CRDI. (1493cc)",
          "SPORT SX 1.5 CRDI (1493cc)",
          "SX 1.5 CRDI. (1493cc)",
          "SX 1.5 CRDI (1493cc)",
          "S 1.5 CRDI (1493cc)",
          "SX 1.5 CRDI DUAL TONE (1493cc)",
          "1.5 SX DUAL TONE CRDI (1493cc)",
          "1.5 E CRDI (1493cc)",
          "1.5 S CRDI (1493cc)",
          "1.5 SX (O) CRDI (1493cc)",
          "1.5 SX (O) DUAL TONE CRDI (1493cc)",
          "1.5 SX CRDI (1493cc)",
          "1.5 S (O) CRDI (1493cc)",
          "1.5 SX (O) EXECUTIVE CRDI (1493cc)",
          "1.5 S PLUS CRDI (1493cc)",
          "1.4 CRDI SX MT DUAL TONE (1396cc)",
          "1.4 U2 CRDI DIESEL 6-SPEED MANUAL E (1396cc)",
          "1.4 U2 CRDI DIESEL 6-SPEED MANUAL S (1396cc)",
          "1.4 U2 CRDI DIESEL 6-SPEED MANUAL SX (1396cc)",
          "1.4 U2 CRDI DIESEL 6-SPEED MANUAL SX DUAL TONE (1396cc)",
          "1.4 U2 CRDI DIESEL 6-SPEED MANUAL SX(O) (1396cc)",
          "E 1.4 CRDI (1396cc)",
          "S 1.4 CRDI (1396cc)",
          "SX 1.4 CRDI (1396cc)",
          "SX 1.4 CRDI DUAL TONE (1396cc)",
          "SX 1.4 (O) CRDI (1396cc)",
          "1.4 CRDI MT SX (O) BSIV (1396cc)",
          "1.4 SX DUAL TONE CRDI (1396cc)",
          "1.4 SX CRDI (1396cc)",
          "1.4 E CRDI (1396cc)",
          "1.4 SX (O) CRDI (1396cc)",
          "1.4 S CRDI (1396cc)",
        ],
      },
      {
        model: "ELITE I20",
        variants: [
          "1.2 KP DVTVT 5-SP M ASTA (1197cc)",
          "1.2 KP DVTVT 5-SP M ASTA DT (1197cc)",
          "1.2 KP DVTVT 5-SP M ASTA (O) (1197cc)",
          "1.2 KAPPA DUAL VTVT 5-SP M ERA (1197cc)",
          "1.2 KP DVTVT 5-SP M MAGNA EX (1197cc)",
          "1.4L MPI DVTVT 4-SP AT MAGNA (1396cc)",
          "1.4L U2 CRDI 6-SPEED M ASTA (1396cc)",
          "1.4L U2 CRDI 6-SP M ASTA DT (1396cc)",
          "1.4L U2 CRDI 6-SP M ASTA (O) (1396cc)",
          "1.4L U2 CRDI 6-SPEED M ERA (1396cc)",
          "1.4L U2 CRDI 6-SP M MAGNA EX (1396cc)",
          "1.4L U2 CRDI 6-SPEED M SPORTZ (1396cc)",
          "SE1.2 KP DVTVT 5-SP M SE (1197cc)",
          "1.2 KP DVTVT 5SP ASTA (MT) (1197cc)",
          "1.2 KP DVTVT 5SP ASTA DT (MT) (1197cc)",
          "1.2 KP DVTVT 5SP ASTA (O)(MT) (1197cc)",
          "1.2 KP DUAL VTVT 5SP ERA(MT) (1197cc)",
          "1.2KP DVTVT 5SP MAGNA EXE(MT) (1197cc)",
          "1.4 MPI DVTVT 4SP MAGNA (AT) (1396cc)",
          "1.4 U2 CRDI 6SPEED ASTA (MT) (1396cc)",
          "1.4 U2 CRDI 6SP ASTA DT (MT) (1396cc)",
          "1.4 U2 CRDI 6SP ASTA (O) (MT) (1396cc)",
          "1.4 U2 CRDI 6SPEED ERA (MT) (1396cc)",
          "1.4 U2 CRDI 6SP MAGNA EXE(MT) (1396cc)",
          "1.4 U2 CRDI 6SPEED SPORTZ (MT) (1396cc)",
          "1.2 KP DVTVT 5SP SE (MT) (1197cc)",
          "1.4 ASTA (O) WITH SUNROOF (1396cc)",
          "1.2KP DVTVT 5SP SE(O)MT (1197cc)",
          "1.2 KP DVTVT SE 5SP(MT) (1197cc)",
          "1.4 CRDI MAGNA U2 6SP MT (1396cc)",
          "1.4 U2 CRDI SE 6SP(O)MT (1396cc)",
          "1.4 SE U2 CRDI 6SP (MT) (1396cc)",
          "1.2 KP DVTVT 5SP SPORTZ (1197cc)",
          "1.2 KP DVTVT 5SP SPORTZ (O) (1197cc)",
          "1.2 KP DVTVT 5SP ASTA(O) (1197cc)",
          "1.2 KP DVTVT 5SP ASTA (1197cc)",
          "1.2 KP DVTVT 5SP ERA (1197cc)",
          "1.2 KP DVTVT 5SP MAGNA (1197cc)",
          "1.4 U2 CRDI 6SP ASTA (MT) (1396cc)",
          "1.4 U2 CRDI 6SP ERA (MT) (1396cc)",
          "1.2 KP DVTVT 5SP MAGNA EXE CVT (1197cc)",
          "1.2 KP DVTVT 5SP ASTA CVT (1197cc)",
          "1.2 KP DVTVT 5SP MAGNA EXE (1197cc)",
          "1.2 KP DVTVT 5SP SPORTZ + CVT (1197cc)",
          "1.2 KP DVTVT 5SP ASTA (O) CVT (1197cc)",
          "1.4 U2 CRDI 6SP MAGNA + (1396cc)",
          "1.4 U2 CRDI 6SP SPORTZ + (1396cc)",
          "1.4 U2 CRDI 6SP SPORTZ + DT (1396cc)",
          "1.2 KP DVTVT 5SP SPORTZ + DT (1197cc)",
          "ASTA OPTION (1197cc)",
          "SPORTZ 1.2 VTVT (1197cc)",
          "ERA 1.2 VTVT (1197cc)",
          "MAGNA 1.2 VTVT (1197cc)",
          "ASTA 1.2 VTVT (1197cc)",
          "SPORTZ OPTION 1.2 (1197cc)",
          "SPORTZ OPTION (1197cc)",
          "MAGNA (1197cc)",
          "ASTA (1197cc)",
          "ERA (1197cc)",
          "ASTA OPTIONAL (1197cc)",
          "SPORTZ (1197cc)",
          "ASTA DUAL TONE (1197cc)",
          "MAGNA EXECUTIVE (1197cc)",
          "ASTA 1.2 O (1197cc)",
          "ASTA O 1.2 (1197cc)",
          "ASTA 1.2 CVT AT (1197cc)",
          "MAGNA EXECUTIVE 1.2 CVT AT (1197cc)",
          "MAGNA PLUS 1.2 (1197cc)",
          "SPORTZ 1.2 DUAL TONE (1197cc)",
          "SPORTZ PLUS 1.2 (1197cc)",
          "SPORTZ PLUS 1.2 DUAL TONE (1197cc)",
          "1.2 ANNIVERSARY EDITION (1197cc)",
          "ASTA 1.2 AT (1197cc)",
          "MAGNA EXECUTIVE 1.2 AT (1197cc)",
          "SPORTZ 1.2 SPECIAL EDITION (1197cc)",
          "SPORTZ PLUS 1.2 BS VI (1197cc)",
          "MAGNA PLUS 1.2 BS VI (1197cc)",
          "ASTA 1.2 (O) VTVT (1197cc)",
          "ASTA O 1.2 BS VI (1197cc)",
          "MAGNA EXECUTIVE 1.2 (1197cc)",
          "ASTA 1.2 (O) CVT (1197cc)",
          "ASTA 1.2 DUAL TONE (1197cc)",
          "SPORTZ PLUS 1.2 CVT (1197cc)",
          "SPORTZ PLUS 1.2 DUAL TONE BS VI (1197cc)",
          "ASTA 1.2 (1197cc)",
          "ASTA 1.2 (1197cc)",
          "ASTA (O) VTVT (1197cc)",
          "ACTIVE VTVT S (1197cc)",
          "ERA 1.2 (1197cc)",
          "MAGNA 1.2 (1197cc)",
          "SPORTZ 1.2 O (1197cc)",
          "SPORTZ 1.2 (1197cc)",
          "ASTA OPTION 1.4 CRDI (1396cc)",
          "SPORTZ  OPTION 1.4 CRDI (1396cc)",
          "SPORTZ 1.4 CRDI (1396cc)",
          "SPORTZ 1.4 CRDI (1396cc)",
          "SPORTZ OPTION (1396cc)",
          "MAGNA (1396cc)",
          "ASTA (1396cc)",
          "ERA (1396cc)",
          "ASTA OPTIONAL (1396cc)",
          "SPORTZ (1396cc)",
          "MAGNA EXECUTIVE (1396cc)",
          "ASTA 1.4 CRDI O (1396cc)",
          "SPORTZ 1.4 SPECIAL EDITION (1396cc)",
          "ASTA 1.4 O CRDI (1396cc)",
          "MAGNA EXECUTIVE 1.4 CRDI (1396cc)",
          "SPORTZ 1.4 O (1396cc)",
          "1.4 CRDI ANNIVERSARY EDITION (1396cc)",
          "ASTA 1.4 CRDI DUAL TONE (1396cc)",
          "MAGNA PLUS 1.4 CRDI (1396cc)",
          "SPORTZ PLUS 1.4 CRDI (1396cc)",
          "SPORTZ PLUS 1.4 CRDI DUAL TONE (1396cc)",
          "SPORTZ O 1.4 CRDI (1396cc)",
          "ASTA 1.4 CRDI (1396cc)",
          "ASTA 1.4 CRDI (1396cc)",
          "ERA 1.4 CRDI (1396cc)",
          "ERA 1.4 CRDI (1396cc)",
          "MAGNA 1.4 CRDI (1396cc)",
          "MAGNA 1.4 CRDI (1396cc)",
          "SPORTZ (O) 1.4 (1396cc)",
          "SPORTZ 1.4 (1396cc)",
          "MAGNA AT (1368cc)",
          "1.4 MAGNA AT. (1368cc)",
          "1.4 AUTO MAGNA (1368cc)",
          "MAGNA 1.4 AT (1368cc)",
          "SPORTZ 1.0 TURBO GDI IMT DT BS6 (998cc)",
        ],
      },
      {
        model: "GRAND I10 NIOS",
        variants: [
          "1.2 KAPPA MAGNA CNG BSVI (1197cc)",
          "1.2 KAPPA SPORTZ CNG BSVI (1197cc)",
          "1.2 AMT KAPPA SPORTZ (1197cc)",
          "1.2 KAPPA ERA (MT) BSVI (1197cc)",
          "1.2 KAPPA MAGNA (MT) BSVI (1197cc)",
          "1.2 KAPPA MAGNA (AMT) BSVI (1197cc)",
          "1.2 KAPPA SPORTZ (MT) BSVI (1197cc)",
          "1.2 KAPPA SPORTZ (AMT) BSVI (1197cc)",
          "1.2 KAPPA SPORTZ DT (MT) BSVI (1197cc)",
          "1.2 KAPPA ASTA (MT) BSVI (1197cc)",
          "1.2 CRDI MAGNA (MT) BSVI (1186cc)",
          "1.2 CRDI SPORTZ (AMT) BSVI (1186cc)",
          "1.2 CRDI ASTA (MT) BSVI (1186cc)",
          "1.2 KAPPA MAGNA CNG (MT) BSVI (1197cc)",
          "1.2 KAPPA SPORTZ CNG(MT)BSVI (1197cc)",
          "1.2 KAPPA ASTA (AMT) BSVI (1197cc)",
          "1.0 TURBO SPORTZ BSVI (998cc)",
          "1.0 TURBO SPORTZ DT BSVI (998cc)",
          "1.2 MT KAPPA CORPORATE BSVI (1197cc)",
          "1.2 MAGNA CE BSVI (1197cc)",
          "1.2 MAGNA CE AMT BSVI (1197cc)",
          "1.2 KAPPA SPORTZ SE (MT) BSVI (1197cc)",
          "1.2 KAPPA ASTA CNG(MT)BSVI (1197cc)",
          "1.2 CRDI SPORTZ (MT) BSVI (1186cc)",
          "MAGNA CORPORATE EDITION 1.2 KAPPA VTVT BSVI (1197cc)",
          "SPORTZ 1.2AMT KAPPA VTVT (1197cc)",
          "SPORTZ 1.2 KAPPA VTVT CNG BS6 (1197cc)",
          "SPORTZ 1.2 MT KAPPA VTVT (1197cc)",
          "CORPORATE EDITION AMT (1197cc)",
          "CORPORATE EDITION MT (1197cc)",
          "SPORTZ 1.2 KAPPA VTVT CNG (1197cc)",
          "SPORTZ 1.2 MT CNG (1197cc)",
          "MAGNA CORPORATE EDITION 1.2 KAPPA VTVT (1197cc)",
          "1.2 MT KAPPA MAGNA (1197cc)",
          "1.2 MT KAPPA (1197cc)",
          "ASTA 1.2 KAPPA VTVT CNG (1197cc)",
          "1.2 MT KAPPA SPORTZDT (1197cc)",
          "1.2 MT CORPORATE (1197cc)",
          "SPORTZ EXECUTIVE 1.2 KAPPA VTVT (1197cc)",
          "SPORTZ EXECUTIVE AMT 1.2 KAPPA VTVT (1197cc)",
          "1.2MT CNG SPORTZ (1197cc)",
          "MAGNA CORPORATE EDITION AMT 1.2 KAPPA VTVT (1197cc)",
          "MAGNA 1.2 KAPPA VTVT CNG (1197cc)",
          "MAGNA 1.2 CNG (1197cc)",
          "SPORTZ 1.2 CNG (1197cc)",
          "MAGNA 1.2 KAPPA VTVT CORPORATE EDITION (1197cc)",
          "MAGNA AMT 1.2 KAPPA VTVT CORPORATE EDITION (1197cc)",
          "ASTA 1.2 CNG (1197cc)",
          "SPORTZ 1.2 KAPPA VTVT (1197cc)",
          "SPORTZ AMT 1.2 KAPPA VTVT (1197cc)",
          "ASTA AMT 1.2 KAPPA VTVT (1197cc)",
          "ASTA 1.2 KAPPA VTVT (1197cc)",
          "ERA 1.2 KAPPA VTVT (1197cc)",
          "MAGNA 1.2 KAPPA VTVT (1197cc)",
          "MAGNA AMT 1.2 KAPPA VTVT (1197cc)",
          "SPORTZ 1.2 KAPPA VTVT DUAL TONE (1197cc)",
          "ASTA 1.2 MT CRDI (1186cc)",
          "MAGNA 1.2 MT CRDI (1186cc)",
          "SPORTZ 1.2 AMT CRDI (1186cc)",
          "SPORTZ AT 1.2 CRDI (1186cc)",
          "SPORTZ MT 1.2 CRDI (1186cc)",
          "MAGNA CORPORATE EDITION U2 1.2 CRDI. (1186cc)",
          "MAGNA CORPORATE EDITION U2 1.2 CRDI (1186cc)",
          "MAGNA U2 1.2 CRDI CORPORATE EDITION (1186cc)",
          "MAGNA U2 1.2 CRDI (1186cc)",
          "ASTA U2 1.2 CRDI (1186cc)",
          "SPORTZ AMT 1.2 CRDI (1186cc)",
          "SPORTZ U2 1.2 CRDI (1186cc)",
          "SPORTZ 1.2 AMT KAPPA VTVT (1198cc)",
          "SPORTZ 1.2 MT KAPPA VTVT DUAL TONE (1198cc)",
          "SPORTZ 1.0 KAPPA VTVT (990cc)",
          "SPORTZ 1.0 KAPPA VTVT DUAL TONE (990cc)",
          "SPORTZ 1.0 TURBO GDI DUAL TONE (998cc)",
          "SPORTZ 1.0 TURBO GDI (998cc)",
        ],
      },
      {
        model: "KONA",
        variants: [
          "PREMIUM AT (1cc)",
          "PREMIUM (1198cc)",
          "PREMIUM DUAL TONE (1198cc)",
          "EV ELECTRIC PREMIUM (100cc)",
          "PREMIUM (AT) (1598cc)",
          "PREMIUM DT (AT) (1598cc)",
        ],
      },
      {
        model: "FLUIDIC VERNA 4S",
        variants: [
          "1.4 VTVT (1396cc)",
          "1.4 VTVT (1396cc)",
          "1.4 VTVT (1396cc)",
          "1.6 CRDI SX (1582cc)",
          "1.6 CRDI  SX (1582cc)",
          "1.6 CRDI SX (1582cc)",
          "1.6 CRDI SX (1582cc)",
          "1.6 CRDI S (1582cc)",
          "1.6 CRDI S (1582cc)",
          "1.6 CRDI S (1582cc)",
          "1.6 CRDI AT SX (1582cc)",
          "1.6 CRDI S AT (1582cc)",
          "1.6 CRDI S O (1582cc)",
          "1.6 CRDI SX AT (1582cc)",
          "1.4 CRDI (1396cc)",
          "1.4 CRDI (1396cc)",
          "1.4 CRDI (1396cc)",
          "1.4 CRDI (1396cc)",
          "1.4 CRDI  (1396CC) (1396cc)",
          "1.6 VTVT SX (1591cc)",
          "1.6 VTVT  SX (1591cc)",
          "1.6 VTVT SX (1591cc)",
          "1.6 VTVT SX (1591cc)",
          "1.6 VTVT  SX OPTION (1591cc)",
          "1.6 VTVT  AT S OPTION (1591cc)",
          "1.6 VTVT S (1591cc)",
          "1.6 VTVT S (1591cc)",
          "1.6 VTVT S (1591cc)",
          "1.6 VTVT S OPTION (1591cc)",
          "1.6 VTVT S O AT (1591cc)",
          "1.6 VTVT S O (1591cc)",
        ],
      },
      {
        model: "KONA ELECTRIC",
        variants: [
          "AUTOMATIC PREMIUM (1600cc)",
          "AUTOMATIC PREMIUM DUAL TONE (1600cc)",
          "PREMIUM (99000cc)",
          "PREMIUM DUAL TONE (99000cc)",
          "PREMIUM (100cc)",
          "PREMIUM DUAL TONE (100cc)",
          "PREMIUM DUAL TONE... (100000cc)",
          "PREMIUM DUAL TONE (136cc)",
        ],
      },
      {
        model: "IONIQ 5",
        variants: [
          "LONG RANGE RWD (3647cc)",
          "LONG RANGE RWD LITHIUM ION MOTOR 160KW (1499cc)",
          "RWD AT (73cc)",
          "RWD (160000cc)",
          "RWD (72.6cc)",
          "0 (2996cc)",
        ],
      },
      {
        model: "SANTRO NEW",
        variants: [
          "1.1 DLITE (MT) (1086cc)",
          "1.1 ERA (MT) (1086cc)",
          "1.1 MAGNA (MT) (1086cc)",
          "1.1 MAGNA CNG (MT) (1086cc)",
          "1.1 MAGNA (AMT) (1086cc)",
          "1.1 SPORTZ (MT) (1086cc)",
          "1.1 SPORTZ CNG (MT) (1086cc)",
          "1.1 SPORTZ (AMT) (1086cc)",
          "1.1 ASTA (MT) (1086cc)",
          "1.1 ERA EXECUTIVE (MT) (1086cc)",
          "1.1 ASTA (AMT) (1086cc)",
          "1.1MT CORPORATE (1086cc)",
          "1.1AMT CORPORATE (1086cc)",
          "1.1 AMT ASTA (1086cc)",
          "1.1 MT ERA EXECUTIVE (1086cc)",
          "1.1 AMT MAGNA CORP EDITION (1086cc)",
          "1.1 MT MAGNA EXECUTIVE CNG (1086cc)",
          "1.1 MT SPORTZ EXECUTIVE CNG (1086cc)",
          "1.1 MT MAGNA CORP EDITION (1086cc)",
          "1.1 MT SPORTZ SE (1086cc)",
          "1.1 MT ASTA (1086cc)",
          "1.1 AMT SPORTZ (1086cc)",
          "1.1 MT ERA (1086cc)",
          "1.1 AMT MAGNA (1086cc)",
          "1.1 MT DLITE (1086cc)",
          "1.1 MT MAGNA (1086cc)",
          "1.1 MT SPORTZ (1086cc)",
          "1.1 MT MAGNA CNG (1086cc)",
          "1.1 MT SPORTZ CNG (1086cc)",
          "MAGNA (1086cc)",
          "ERA (1086cc)",
          "SPORTZ (1086cc)",
          "DLITE (1086cc)",
          "ERA EXECUTIVE (1086cc)",
          "SPORTZ SE (1086cc)",
          "SPORTZ AMT SE (1086cc)",
          "ASTA (1086cc)",
          "MAGNA AMT (1086cc)",
          "SPORTZ CNG (1086cc)",
          "MAGNA CNG (1086cc)",
          "SPORTZ AMT (1086cc)",
          "MAGNA CORPORATE EDITION (1086cc)",
          "ASTA AMT (1086cc)",
          "MAGNA AMT CORPORATE EDITION (1086cc)",
        ],
      },
      {
        model: "NEW I20",
        variants: [
          "1.2 KAPPA ASTA IVT BSVI (1197cc)",
          "1.0 TURBO ASTA GDI IMT BSVI (998cc)",
          "1.0 TURBO SPORTZ GDI IMT BSVI (998cc)",
          "1.2 KAPPA ASTA MT BSVI (1197cc)",
          "1.5 CRDI ASTA (O) MT BSVI (1493cc)",
          "1.2 KAPPA ASTA (O) MT BSVI (1197cc)",
          "1.5 CRDI SPORTZ MT BSVI (1493cc)",
          "1.0 TURBO ASTA GDI DCT BSVI (998cc)",
          "1.2 KAPPA MAGNA MT BSVI (1197cc)",
          "1.5 CRDI MAGNA MT BSVI (1493cc)",
          "1.2 KAPPA SPORTZ MT BSVI (1197cc)",
          "1.0 TURBO ASTA (O) GDI DCT BSVI (998cc)",
          "1.2 KAPPA SPORTZ IVT BSVI (1197cc)",
          "MAGNA 1.2 BS6 (1197cc)",
          "SPORTZ 1.2 BS6 (1197cc)",
          "SPORTZ 1.2 DT BS6 (1197cc)",
          "SPORTZ 1.2 IVT BS6 (1197cc)",
          "SPORTZ 1.2 IVT DT BS6 (1197cc)",
          "ASTA 1.2 BS6 (1197cc)",
          "ASTA 1.2DT BS6 (1197cc)",
          "ASTA 1.2 IVT BS6 (1197cc)",
          "ASTA 1.2 IVT DT BS6 (1197cc)",
          "ASTA 1.2 OP BS6 (1197cc)",
          "ASTA 1.2 OP DT BS6 (1197cc)",
          "ASTA 1.0 TURBO GDI IMT BS6 (1197cc)",
          "1.2 ASTA KAPPA IVT BS6 (1197cc)",
          "ASTA 1.2 KAPPA MT (1197cc)",
          "ASTA(O) 1.2 KAPPA MT (1197cc)",
          "MAGNA 1.2 KAPPA MT (1197cc)",
          "SPORTZ 1.2 KAPPA MT (1197cc)",
          "SPORTZ 1.2 KAPPA IVT (1197cc)",
          "1.2 SPORTZ IVT DUAL TONE (1197cc)",
          "1.2 ASTA MT (1197cc)",
          "1.2 SPORTZ IVT (1197cc)",
          "1.2 ASTA IVT DUAL TONE (1197cc)",
          "1.2 ASTA IVT (1197cc)",
          "1.2 MAGNA MT (1197cc)",
          "1.2 ASTA MT DUAL TONE (1197cc)",
          "1.2 SPORTZ MT DUAL TONE (1197cc)",
          "1.2 ASTA (O) MT DUAL TONE (1197cc)",
          "1.2 SPORTZ MT (1197cc)",
          "1.2 ASTA MT (O) (1197cc)",
          "1.2 ASTA (O) IVT (1197cc)",
          "SPORTZ 1.0 TURBO GDI IMT BS6 (998cc)",
          "ASTA 1.0 TURBO GDI IMT DT BS6 (998cc)",
          "ASTA 1.0 TURBO GDI DCT BS6 (998cc)",
          "ASTA 1.0 TURBO GDI DCT DT BS6 (998cc)",
          "ASTA OP 1.0 TURBO GDI DCT BS6 (998cc)",
          "ASTA OP 1.0 TURBO GDI DCT DT BS6 (998cc)",
          "N LINE 1.0 TURBO GDI N8 (998cc)",
          "N LINE 1.0 TURBO GDI N6 IMT (998cc)",
          "N LINE 1.0 TURBO GDI N6 IMT DT (998cc)",
          "N LINE 1.0 TURBO GDI N8 IMT (998cc)",
          "N LINE 1.0 TURBO GDI N8 IMT DT (998cc)",
          "N LINE 1.0 TURBO GDI N8 DCT (998cc)",
          "1.0 ASTA TURBO GDI IMT (998cc)",
          "SPORTZ 1.0 TURBO GDI IMT (998cc)",
          "ASTA 1.0 TURBO GDI DCT (998cc)",
          "ASTA(O) 1.0 TURBO GDI DCT (998cc)",
          "1.0 ASTA (O) TURBO DCT DUAL TONE (998cc)",
          "1.0 ASTA TURBO DCT DUAL TONE (998cc)",
          "1.0 SPORTZ TURBO IMT DUAL TONE (998cc)",
          "1.0 ASTA TURBO IMT (998cc)",
          "1.0 ASTA TURBO DCT (998cc)",
          "1.0 SPORTZ TURBO IMT (998cc)",
          "1.0 ASTA TURBO DCT (O) (998cc)",
          "1.0 ASTA TURBO IMT DUAL TONE (998cc)",
          "1.0 SPORTZ TURBO DCT (998cc)",
          "N LINE N8 DCT (999cc)",
          "MAGNA 1.5 DSL BS6 (1493cc)",
          "SPORTZ 1.5 DSL BS6 (1493cc)",
          "SPORTZ 1.5 DSL DT BS6 (1493cc)",
          "ASTA OP 1.5 DSL BS6 (1493cc)",
          "ASTA OP 1.5 DSL DT BS6 (1493cc)",
          "ASTA(O) 1.5 CRDI MT (1493cc)",
          "SPORTZ 1.5 CRDI MT (1493cc)",
          "MAGNA 1.5 KAPPA MT (1493cc)",
          "1.5 MAGNA MT DIESEL (1493cc)",
          "1.5 SPORTZ MT DIESEL (1493cc)",
          "1.5 ASTA MT (O) DIESEL (1493cc)",
          "1.5 SPORTZ MT DUAL TONE (1493cc)",
          "1.5 ASTA (O) MT DUAL TONE (1493cc)",
          "MAGNA (1396cc)",
          "1.4 SPORTZ (1396cc)",
        ],
      },
      {
        model: "VENUE N LINE",
        variants: [
          "N6 TURBO DCT BSVI (998cc)",
          "N6 TURBO DCT DT BSVI (998cc)",
          "N8 TURBO DCT BSVI (998cc)",
          "N8 TURBO DCT DT BSVI (998cc)",
          "N6 DCT (998cc)",
          "N8 DCT (998cc)",
          "N6 DCT DUAL TONE (998cc)",
          "N8 DCT DUAL TONE (998cc)",
        ],
      },
      {
        model: "GENESIS",
        variants: [
          "G80 (3778cc)",
          "0 (3800cc)",
          "3.8 AT (3800cc)",
          "G80 3.5 LAMBDA (3778cc)",
          "GV80 2.5L (2497cc)",
          "GV 80 (2497cc)",
          "3.8 L (3788cc)",
        ],
      },
      {
        model: "AURA",
        variants: [
          "1.2 U2 CRDI S (AMT) BSVI (1186cc)",
          "1.2 KAPPA E (MT) BSVI (1197cc)",
          "1.2 KAPPA S (MT) BSVI (1197cc)",
          "1.2 KAPPA S (AMT) BSVI (1197cc)",
          "1.2 KAPPA S CNG (MT) BSVI (1197cc)",
          "1.2 KAPPA SX (MT) BSVI (1197cc)",
          "1.2 U2 CRDI S (MT) BSVI (1186cc)",
          "1.2 KAPPA SX (O) (MT) BSVI (1197cc)",
          "1.2 KAPPA SX PLUS (AMT) BSVI (1197cc)",
          "1.0 TURBO GDI SX PLUS(MT)BSVI (998cc)",
          "1.2 U2 CRDI SX (O) (MT) BSVI (1186cc)",
          "1.2 U2 CRDI SX PLUS (AMT)BSVI (1186cc)",
          "1.2 KAPPA SX CNG (MT) BSVI (1197cc)",
          "E (1197cc)",
          "S (1197cc)",
          "S AMT (1197cc)",
          "SX (1197cc)",
          "SX PLUS AMT (1197cc)",
          "1.2 CNG S (1197cc)",
          "SX CNG MT (1197cc)",
          "AURA 1.2MT CNG SX (1197cc)",
          "AURA 1.2MT CNG S (1197cc)",
          "1.2MT CNG SX (1197cc)",
          "SX O (1197cc)",
          "1.2 KAPPA DUAL VTVT E (1197cc)",
          "1.2 KAPPA DUAL VTVT S (1197cc)",
          "1.2 KAPPA DUAL VTVT S AMT (1197cc)",
          "1.2 KAPPA DUAL VTVT S CNG (1197cc)",
          "1.2 KAPPA DUAL VTVT SX PLUS AMT (1197cc)",
          "1.2 KAPPA DUAL VTVT SX (1197cc)",
          "1.2 KAPPA DUAL KAPPA SX (O) (1197cc)",
          "1.2 KAPPA DUAL VTVT SX CNG (1197cc)",
          "S 1.2 PETROL (1197cc)",
          "SX 1.2 O PETROL (1197cc)",
          "SX PLUS 1.2 AMT PETROL (1197cc)",
          "1.2 MT CNG S (1197cc)",
          "SX 1.2 PETROL (1197cc)",
          "E 1.2 PETROL (1197cc)",
          "S 1.2 AMT PETROL (1197cc)",
          "1.2 MT KAPPA S BSVI (1197cc)",
          "1.2 MT CNG (1197cc)",
          "KAPPA SX(O) (1197cc)",
          "SX 1.2 CNG (1197cc)",
          "1.2 MT KAPPA SX (1197cc)",
          "S 1.2 CNG PETROL (1197cc)",
          "1.2 SX PETROL (1197cc)",
          "1.2 SX (O) PETROL (1197cc)",
          "1.2 S CNG (1197cc)",
          "1.2 E PETROL (1197cc)",
          "1.2 S AMT PETROL (1197cc)",
          "1.2 S PETROL (1197cc)",
          "1.2 SX PLUS AMT PETROL (1197cc)",
          "1.2 SX CNG (1197cc)",
          "S CNG (1186cc)",
          "S AMT DIESEL (1186cc)",
          "S DIESEL (1186cc)",
          "SX O DIESEL (1186cc)",
          "SX PLUS AMT DIESEL (1186cc)",
          "1.2 CRDI S (1186cc)",
          "1.2 CRDI S AMT (1186cc)",
          "1.2 CRDI SX PLUS AMT (1186cc)",
          "1.2 CRDI SX (O) (1186cc)",
          "S 1.2 AMT CRDI (1186cc)",
          "SX PLUS 1.2 AMT CRDI (1186cc)",
          "S 1.2 CRDI (1186cc)",
          "SX 1.2 O CRDI (1186cc)",
          "1.2 SX (O) CRDI (1186cc)",
          "1.2 S AMT CRDI (1186cc)",
          "1.2 S CRDI (1186cc)",
          "1.2 SX PLUS AMT CRDI (1186cc)",
          "SX PLUS TURBO (998cc)",
          "1.0 TURBO GDI SX PLUS (998cc)",
          "SX PLUS 1.0 PETROL (998cc)",
          "1.0 SX PLUS PETROL (998cc)",
        ],
      },
      {
        model: "ALCAZAR",
        variants: [
          "2.0 PETROL AT (1995cc)",
          "2.0 PETROL MT (1995cc)",
          "1.5 DIESEL AT (1497cc)",
          "1.5 DIESEL MT (1497cc)",
          "2.0 MPI PLATINUM(O)(AT) (1999cc)",
          "2.0 MPI PRESTIGE (1999cc)",
          "1.5 CRDI PRESTIGE (1493cc)",
          "2.0 MPI SIGNATURE (1999cc)",
          "1.5 CRDI SIGNATURE (1493cc)",
          "1.5 CRDI PLATINUM(O)(AT) (1493cc)",
          "2.0 MPI SIGNATURE(O)(AT) (1999cc)",
          "1.5 CRDI PRESTIGE(O)(AT) (1493cc)",
          "2.0 MPI PLATINUM (1999cc)",
          "1.5 CRDI SIGNATURE(O)(AT) (1493cc)",
          "2.0 MPI SIGNATURE (DT) (1999cc)",
          "2.0 MPI SIGNATURE(O)(AT)(DT) (1999cc)",
          "1.5 CRDI PLATINUM (1493cc)",
          "1.5 CRDI SIGNATURE DT (1493cc)",
          "1.5 CRDI SIGNATURE(O)(AT)(DT) (1493cc)",
          "2.0 MPI PRESTIGE EXE (1999cc)",
          "1.5 CRDI PRESTIGE EXE (1493cc)",
          "1.5 CRDI PRESTIGE EXE (O) AT (1493cc)",
          "1.5 TURBO PRESTIGE (1482cc)",
          "1.5 TURBO PLATINIUM (1482cc)",
          "1.5 TURBO PLATINIUM (O) DCT (1482cc)",
          "1.5 TURBO SIGNATURE (O) DCT (1482cc)",
          "1.5 CRDI PRESTIGE 7 SEATER MT (1493cc)",
          "1.5 CRDI PRESTIGE 6 SEATER MT (1493cc)",
          "1.5 CRDI PRESTIGE O 7 SEATER AT (1493cc)",
          "1.5 CRDI PLATINUM O 6 SEATER AT (1493cc)",
          "1.5 CRDI SIGNATURE 6 STATER MT (1493cc)",
          "1.5 CRDI SIGNATURE 6 STATER MT DUAL TONE (1493cc)",
          "1.5 CRDI SIGNATURE O 6 STATER AT (1493cc)",
          "1.5 CRDI SIGNATURE O 6 STATER AT DUAL TONE (1493cc)",
          "1.5 CRDI PLATINUM 7 SEATER MT (1493cc)",
          "1.5 AT (O) PLATINUM 7S (1493cc)",
          "1.5 AT SIGNATURE(O) 7 STR (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED AUTOMATIC PLATINUM (O) 7 SEATER (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED AUTOMATIC SIGNATURE (O) 7 SEATER (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED AUTOMATIC SIGNATURE 6 SEATER (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED AUTOMATIC SIGNATURE 6 SEATER DUAL TONE (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED MANUAL PRESTIGE 7 SEATER (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED MANUAL PRESTIGE 6 SEATER (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED AUTOMATIC PRESTIGE (O) 7 SEATER (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED MANUAL PLATINUM 7 SEATER (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED MANUAL SIGNATURE 6 SEATER (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED MANUAL SIGNATURE 6 SEATER DUAL TONE (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED AUTOMATIC PLATINUM (O) 6 SEATER (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED AUTOMATIC SIGNATURE (O) 6 SEATER (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED AUTOMATIC SIGNATURE (O) 6 SEATER DUAL TONE (1493cc)",
          "1.5 AT PRESTIGE(O) EXE 7S (1493cc)",
          "1.5 CRDI DIESEL 6 SPEED AUTOMATIC PRESTIGE 7 SEATER (1493cc)",
          "1.5 MT PRESTIGE EXE 6S (1493cc)",
          "1.5 MT PRESTIGE EXE 7S (1493cc)",
          "1.5 PRESTIGE 6 STR (1493cc)",
          "1.5 PRESTIGE 7 STR (1493cc)",
          "1.5 PRESTIGE O 7 STR AT (1493cc)",
          "1.5 PLATINUM 7 STR (1493cc)",
          "1.5 SIGNATURE 6 STR (1493cc)",
          "1.5 SIGNATURE 6 STR DUAL TONE (1493cc)",
          "1.5 PLATINUM O 6 STR AT (1493cc)",
          "1.5 SIGNATURE O 6 STR AT (1493cc)",
          "1.5 SIGNATURE O 6 STR AT DUAL TONE (1493cc)",
          "SIGNATURE (O) AT 7 STR (1493cc)",
          "1.5 PLATINUM (O) 7 STR (1493cc)",
          "1.5 PRESTIGE EXE 7 STR (1493cc)",
          "1.5 PRESTIGE EXE O 7 STR AT (1493cc)",
          "1.5 PRESTIGE EXE 6 STR (1493cc)",
          "1.5 SIGNATURE MT DT 6 STR (1493cc)",
          "1.5 SIGNATURE (O) DT AT 6 STR (1493cc)",
          "1.5 PLATINUM MT 7 STR (1493cc)",
          "1.5 PLATINUM O 7 STR 1.5 AT (1493cc)",
          "1.5 SIGNATURE O 7 STR 1.5 AT (1493cc)",
          "1.5 PLATINUM (O) AT 7 STR (D) (1493cc)",
          "1.5 PRESTIGE 7 STR (D) (1493cc)",
          "1.5 PLATINIUM 7 STR (D) (1493cc)",
          "1.5 SIGNATURE (O) AT DT 6 STR (D) (1493cc)",
          "1.5 PRESTIGE (O) AT 7 STR (D) (1493cc)",
          "1.5 PLATINIUM (O) AT 6 STR (D) (1493cc)",
          "1.5 SIGNATURE (O) AT 6 STR (D) (1493cc)",
          "1.5 SIGNATURE 6 STR (D) (1493cc)",
          "1.5 PLATINIUM AT 6 STR (D) (1493cc)",
          "1.5 PRESTIGE 6 STR (D) (1493cc)",
          "1.5 SIGNATURE AT 6 STR (D) (1493cc)",
          "1.5 PRESTIGE AT 7 STR (D) (1493cc)",
          "1.5 SIGNATURE (O) AT 7 STR (D) (1493cc)",
          "1.5 PRESTIGE EXECUTIVE 7 STR (D) (1493cc)",
          "1.5 PRESTIGE EXECUTIVE 6 STR (D) (1493cc)",
          "1.5 PRESTIGE EXECUTIVE (O) AT 7 STR (D) (1493cc)",
          "PRESTIGE 7 STR 1.5 TURBO PETROL (1482cc)",
          "PLATINUM (O) 7 STR 1.5 PETROL DCT (1482cc)",
          "PLATINUM (O) 6 STR 1.5 PETROL DCT (1482cc)",
          "SIGNATURE (O) 7 STR 1.5 PETROL DCT (1482cc)",
          "SIGNATURE (O) 6 STR 1.5 PETROL DCT (1482cc)",
          "PLATINUM MT 7 STR 1.5 TURBO (1482cc)",
          "1.5 GDI TURBO PETROL 7 SPEED DCT SIGNATURE (O) 7 SEATER (1482cc)",
          "1.5 GDI TURBO PETROL 7 SPEED DCT SIGNATURE (O) 6 SEATER (1482cc)",
          "1.5 GDI TURBO PETROL 7 SPEED DCT PLATINUM (O) 7 SEATER (1482cc)",
          "1.5 GDI TURBO PETROL 7 SPEED PLATINUM 7 SEATER (1482cc)",
          "1.5 GDI TURBO PETROL 7 SPEED PRESTIGE 7 SEATER (1482cc)",
          "1.5 SIGNATURE (O) DCT 7 STR (1482cc)",
          "1.5 PRESTIGE MT 7 STR (1482cc)",
          "1.5 PLATINUM MT 7 STR (1482cc)",
          "1.5 PLATINUM (O) DCT 7 STR (1482cc)",
          "1.5 PLATINUM (O) DCT 6 STR (1482cc)",
          "1.5 SIGNATURE (O) DCT 6 STR (1482cc)",
          "1.5 SIGNATURE (O) DCT DT 6 STR (1482cc)",
          "1.5 PLATINUM 7 STR TURBO (1482cc)",
          "1.5 PLATINUM O 6 STR DCT (1482cc)",
          "1.5 PLATINUM O 7 STR DCT (1482cc)",
          "1.5 PRESTIGE 7 STR TURBO (1482cc)",
          "1.5 SIGNATURE O 6 STR DCT (1482cc)",
          "1.5 SIGNATURE O 7 STR DCT (1482cc)",
          "1.5 SIGNATURE O 6 STR DCT DUAL TONE (1482cc)",
          "2.0 SIGNATURE (O) AT 7 STR (P) (1999cc)",
          "2.0 MPI PRESTIGE 7 SEATER MT (1999cc)",
          "2.0 MPI PRESTIGE 6 SEATER MT (1999cc)",
          "2.0 MPI PRESTIGE O 6 SEATER AT (1999cc)",
          "2.0 MPI PLATINUM 7 SEATER MT (1999cc)",
          "2.0 MPI PLATINUM O 6 SEATER AT (1999cc)",
          "2.0 MPI SIGNATURE 6 STATER MT (1999cc)",
          "2.0 MPI SIGNATURE 6 STATER MT DUAL TONE (1999cc)",
          "2.0 MPI SIGNATURE O 6 STATER AT (1999cc)",
          "2.0 MPI SIGNATURE O 7 STATER AT (1999cc)",
          "2.0 MPI PETROL 6 SPEED AUTOMATIC PLATINUM (O) 7 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED MANUAL SIGNATURE (O) 7 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED AUTOMATIC PLATINUM 6 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED AUTOMATIC SIGNATURE 6 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED MANUAL PRESTIGE 7 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED MANUAL PRESTIGE 6 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED AUTOMATIC PRESTIGE (O) 6 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED MANUAL PLATINUM 7 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED MANUAL SIGNATURE 6 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED MANUAL SIGNATURE 6 SEATER DUAL TONE (1999cc)",
          "2.0 MPI PETROL 6 SPEED AUTOMATIC PLATINUM (O) 6 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED AUTOMATIC SIGNATURE (O) 6 SEATER DUAL TONE (1999cc)",
          "2.0 MPI PETROL 6 SPEED MANUAL PRESTIGE EXE 7 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED AUTOMATIC SIGNATURE (O) 6 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED AUTOMATIC SIGNATURE (O) 7 SEATER (1999cc)",
          "2.0 MPI PETROL 6 SPEED AUTOMATIC SIGNATURE 6 SEATER DUAL TONE (1999cc)",
          "2.0 PRESTIGE 6 STR (1999cc)",
          "2.0 PRESTIGE O 6 STR AT (1999cc)",
          "2.0 PLATINUM 7 STR (1999cc)",
          "2.0 SIGNATURE 6 STR (1999cc)",
          "2.0 SIGNATURE 6 STR DUAL TONE (1999cc)",
          "2.0 PLATINUM O 6 STR AT (1999cc)",
          "2.0 SIGNATURE O 6 STR AT (1999cc)",
          "2.0 PRESTIGE 7 STR (1999cc)",
          "SIGNATURE AT (1999cc)",
          "2.0 AT SIGNATURE (0) 7 S (1999cc)",
          "2.0 PRESTIGE EXE 7 STR (1999cc)",
          "2.0 SIGNATURE O 7 STR AT (1999cc)",
          "2.0 PLATINUM O 7 STR AT (1999cc)",
          "2.0 SIGNATURE O 6 STR AT DUAL TONE. (1999cc)",
          "2.0 SIGNATURE O 6 STR AT DUAL TONE (1999cc)",
          "2.0 PRESTIGE 6 STR (P) (1999cc)",
          "2.0 PRESTIGE 7 STR (P) (1999cc)",
          "2.0 SIGNATURE AT 6 STR (P) (1999cc)",
          "2.0 SIGNATURE 6 STR (P) (1999cc)",
          "2.0 PLATINIUM AT 6 STR (P) (1999cc)",
          "2.0 PLATINIUM 7 STR (P) (1999cc)",
          "2.0 PRESTIGE AT 6 STR (P) (1999cc)",
          "2.0 SIGNATURE (O) AT DT 6 STR (P) (1999cc)",
          "2.0 PLATINIUM (O) AT 6 STR (P) (1999cc)",
          "2.0 SIGNATURE (O) AT 6 STR (P) (1999cc)",
          "2.0 PRESTIGE (O) AT 6 STR (P) (1999cc)",
          "2.0 PLATINIUM (O) AT 7 STR (P) (1999cc)",
        ],
      },
      {
        model: "SANTA FE",
        variants: [
          "2.2 CRDI 4X2 DSL T (AT) (2199cc)",
          "2.2 CRDI 4X2 DSL (MT) (2199cc)",
          "2.2 CRDI 4X4 DSL (AT) (2199cc)",
          "2.2 CRDI 4X4 DSL (MT) (2199cc)",
          "CRDI 4WD AUTO TOP (2199cc)",
          "4WD MT (2199cc)",
          "2.2 CRDI 4X2 DSL AT T (2199cc)",
          "2.2 CRDI 4X4 DSL AT T (2199cc)",
          "R 2.2L CRDI 6-SPEED AT 2WD (2199cc)",
          "R 2.2L CRDI 6-SPEED AT 4WD (2199cc)",
          "R 2.2L CRDI 6-SPEED MANUAL 2WD (2199cc)",
          "AT (2179cc)",
          "MT (2199cc)",
          "4X4(AT)2.2 L 5 STR (2199cc)",
          "CRDI 2WD AT (2199cc)",
          "2.2 CRDI 4X4 DSL AUTOMATIC TRANSMISSION (2199cc)",
          "2.2 CRDI 4X2 DSL MANUAL TRANSMISSION 5 SEAT (2199cc)",
          "2.2 CRDI 4X2 DSL MANUAL TRANSMISSION (2199cc)",
          "2.2 CRDI 4X4 DSL MANUAL TRANSMISSION (2199cc)",
          "2.2 CRDI 4X4 DSL AUTOMATIC TRANSMISSION 5SEATER (2199cc)",
          "2.2 CRDI 4X2 DSL AUTOMATIC TRANSMISSION (2199cc)",
          "DM CRDI 4WD AT (2199cc)",
          "2WD MT (2199cc)",
          "2.2 CRDi 6Speed 2WD R (AT) (2199cc)",
          "2WD (2199cc)",
          "2WD AT (2199cc)",
          "4WD AT (2199cc)",
          "4WD (2199cc)",
          "2.2 CRDi 6Speed 2WD R (AT) (2188cc)",
        ],
      },
      {
        model: "EON",
        variants: [
          "0.8L IRDE D-LITE (814cc)",
          "0.8L IRDE D-LITE+ (O) (814cc)",
          "0.8L IRDE ERA+ (814cc)",
          "0.8L IRDE ERA+ (O) (814cc)",
          "0.8L IRDE ERA+(SE) (814cc)",
          "0.8L IRDE MAGNA+ (814cc)",
          "0.8L IRDE MAGNA+ (O) (814cc)",
          "0.8L IRDE MAGNA+(SE) (814cc)",
          "0.8L IRDE SPORTZ (814cc)",
          "1.0L KAPPA ERA+ (998cc)",
          "1.0L KAPPA MAGNA (O) (998cc)",
          "1.0 KAPPA MAGNA PLUS 5SP (MT) (998cc)",
          "D-LITE WITH AC (O) (814cc)",
          "0.8L ERA PLUS (814cc)",
          "0.8L MAGNA PLUS (814cc)",
          "1.0L ERA PLUS AT (998cc)",
          "1.0L ERA PLUS MT (998cc)",
          "1.0L MAGNA (O) AT (998cc)",
          "1.0 MAGNA(O) MT (998cc)",
          "1.0L MAGNA (O)OPTN AT (998cc)",
          "1.0L MAGNA (O)OPTN MT (998cc)",
          "0.8L D-LITE AT (814cc)",
          "0.8L D-LITE MT (814cc)",
          "0.8L D-LITE PLUS AT (814cc)",
          "0.8L D-LITE PLUS (O) AT (814cc)",
          "0.8L ERA PLUS AT (814cc)",
          "0.8L ERA PLUS (O) AT (814cc)",
          "0.8L ERA PLUS SE AT (814cc)",
          "0.8L ERA PLUS SE MT (814cc)",
          "0.8L MAGNA PLUS AT (814cc)",
          "0.8L MAGNA PLUS (O) AT (814cc)",
          "0.8L MAGNA PLUS SE AT (814cc)",
          "0.8L SPORTS AT (814cc)",
          "0.8L ERA PLUS SE (814cc)",
          "D-LITE BS4 (814cc)",
          "D-LITE PLUS BS1V (1086cc)",
          "MAGMA S (814cc)",
          "MAGNA PLUS (814cc)",
          "MAGNA PLUS. (814cc)",
          "ERA PLUS (814cc)",
          "ERA PLUS (814cc)",
          "D LITE PLUS (814cc)",
          "D LITE PLUS (814cc)",
          "ERA (814cc)",
          "ERA (814cc)",
          "MAGNA (814cc)",
          "MAGNA (814cc)",
          "MAGNA O (814cc)",
          "MAGNA OPTION (814cc)",
          "D-LITE OPTION (814cc)",
          "MAGNA PLUS OPTION (814cc)",
          "ERA PLUS OPTION (814cc)",
          "ERA PLUS SPORTS EDITION (814cc)",
          "MAGNA PLUS SPORTS EDITION (814cc)",
          "NEW (814cc)",
          "ERA 4 STR (814cc)",
          "ERA + (O) (814cc)",
          "GLS (814cc)",
          "D-LITE LPG PLUS (814cc)",
          "ERA LPG PLUS (814cc)",
          "MAGNA LPG PLUS (814cc)",
          "D-LITE WITH AC OPTION (814cc)",
          "MAGNA PLUS (S) (814cc)",
          "D-LITE PLUS (O) (814cc)",
          "ERA PLUS SPORT EDITION (814cc)",
          "MAGNA + AIRBAG (814cc)",
          "ERA + LPG AIRBAG (814cc)",
          "ERA + AIRBAG (814cc)",
          "ERA + LPG (814cc)",
          "MAGNA + LPG (814cc)",
          "MAGNA + SE (814cc)",
          "D-LITE + AIRBAG (814cc)",
          "MAGNA PLUS LPG (814cc)",
          "MAGNA PLUS LPG (814cc)",
          "ERA PLUS LPG (814cc)",
          "ERA PLUS (O) (814cc)",
          "MAGNA PLUS O (814cc)",
          "ERA PLUS (O) LPG (814cc)",
          "D LITE + LPG (814cc)",
          "D LITE O (814cc)",
          "ERA + SE (814cc)",
          "SPORTZ (814cc)",
          "SPORTZ (814cc)",
          "D-LITE (814cc)",
          "D LITE (814cc)",
          "MAGNA PLUS OPTION (998cc)",
          "1.0 KAPPA 5 SPEED MANUAL MAGNA PLUS (O) (998cc)",
          "1.0 KAPPA 5 SPEED MANUAL MAGNA PLUS (998cc)",
          "1.0 KAPPA 5 SPEED MANUAL ERA (998cc)",
          "1.0 KAPPA 5 SPEED MANUAL ERA PLUS (998cc)",
          "1.0L KAPPA 5-SPEED MANUAL ERA+ (998cc)",
          "1.0 KAPPA ERA + (998cc)",
          "1.0 KAPPA ERA (998cc)",
          "1.0 KAPPA MAGNA AIRBAG (998cc)",
          "1.0 KAPPA MAGNA O AIRBAG (998cc)",
          "1.0 KAPPA MAGNA PLUS (998cc)",
          "MAGNA PLUS (998cc)",
          "MAGNA PLUS (998cc)",
          "1.0 KAPPA MAGNA + (998cc)",
          "1.0 KAPPA MAGNA PLUS (O) (998cc)",
          "MAGNA 1.0L BSIV (1000cc)",
        ],
      },
      {
        model: "FLUIDIC ELANTRA",
        variants: [
          "1.6 CRDI (1582cc)",
          "NU 2.0 MPI SX 6SP (AT) (1999cc)",
          "NU 2.0 MPI SX 6SP (O) (AT) (1999cc)",
          "NU 2.0 MPI S 6SP (MT) (1999cc)",
          "NU 2.0 MPI 6SP SX (MT) (1999cc)",
          "NU 2.0 MPI SX 6SP (O) (MT) (1999cc)",
          "U2 1.6 CRDI 6-S SX(O) (AT) (1582cc)",
          "U2 1.6 CRDI SX 6SP (MT) (1582cc)",
          "U2 1.6 CRDI SX 6SP (O) (MT) (1582cc)",
          "2.0 VTVT SX (AT) (1999cc)",
          "2.0 VTVT S (MT) (1999cc)",
          "2.0 VTVT SX (MT) (1999cc)",
          "2.0 VTVT SX (O) (MT) (1999cc)",
          "2.0 VTVT SX (O) (AT) (1999cc)",
          "1.6 CRDI BASE (1582cc)",
          "1.6 CRDI S (1582cc)",
          "1.6 CRDI SX (1582cc)",
          "1.6 CRDI SX AT (1582cc)",
          "1.8 VTVT S (1797cc)",
          "1.8 VTVT SX (1797cc)",
          "1.8 VTVT SX AT (1797cc)",
          "1.6 CRDI L (1582cc)",
          "NEO ELANTRA 1.8 VTVT SX AT (1797cc)",
          "1.5 CRDI SX MT (1493cc)",
          "1.5 CRDI SX (O) AT (1493cc)",
          "NU 2.0 MPI 6-SPEED MANUAL S (1999cc)",
          "NU 2.0 MPI 6-SPEED MANUAL SX (1999cc)",
          "NU 2.0 MPI 6-SPEED AUTOMATIC SX AT (1999cc)",
          "NU 2.0 MPI 6-SPEED MANUAL SX (O) (1999cc)",
          "NU 2.0 MPI 6-SPEED AUTOMATIC SX(O) AT (1999cc)",
          "U2 1.6 CRDI 6-SPEED MANUAL S (1582cc)",
          "U2 1.6 CRDI 6-SPEED MANUAL SX (1582cc)",
          "U2 1.6 CRDI 6-SPEED MANUAL SX(O) (1582cc)",
          "U2 1.6 CRDI 6-SPEED AUTOMATIC SX(O) AT (1582cc)",
          "2.0 VTVT SX (O) AT (1591cc)",
        ],
      },
      {
        model: "EXTER",
        variants: [
          "1.2 KAPPA EX (MT) BSVI (1197cc)",
          "1.2 KAPPA EX (O)(MT) BSVI (1197cc)",
          "1.2 KAPPA S (MT) BSVI (1197cc)",
          "1.2 KAPPA S (O)(MT) BSVI (1197cc)",
          "1.2 KAPPA S (AMT) BSVI (1197cc)",
          "1.2 KAPPA SX (MT) BSVI (1197cc)",
          "1.2 KAPPA SX (MT) DT BSVI (1197cc)",
          "1.2 KAPPA SX (O)(MT) BSVI (1197cc)",
          "1.2 KAPPA SX (AMT) BSVI (1197cc)",
          "1.2 KAPPA SX (AMT) DT BSVI (1197cc)",
          "1.2 KAPPA SX (O)(MT)(C)BSVI (1197cc)",
          "1.2 KAPPA SX (O)(AMT) BSVI (1197cc)",
          "1.2 KAPPA SX (O)(MT)DT(C)BSVI (1197cc)",
          "1.2 KAPPA SX (O)(AMT)(C) BSVI (1197cc)",
          "1.2 KAPPA SX (O)(AMT)DT(C)BSVI (1197cc)",
          "1.2 KAPPA S CNG (MT) BSVI (1197cc)",
          "1.2 KAPPA SX CNG (MT) BSVI (1197cc)",
          "1.2 S AMT (1197cc)",
          "1.2 SX (O) AMT (1197cc)",
          "1.2 SX (O) CONNECT AMT (1197cc)",
          "1.2 SX (O) CONNECT AMT DUAL TONE (1197cc)",
          "AMT S (1197cc)",
          "AMT SX (1197cc)",
          "AMT SX DT (1197cc)",
          "AMT SX(O) (1197cc)",
          "AMT SX(O) CONNECT (1197cc)",
          "AMT SX(O) CONNECT DT (1197cc)",
          "EX (1197cc)",
          "EX(O) (1197cc)",
          "S (1197cc)",
          "S(O) (1197cc)",
          "SX (1197cc)",
          "SX DT (1197cc)",
          "SX(O) (1197cc)",
          "SX(O) CONNECT (1197cc)",
          "SX(O) CONNECT DT (1197cc)",
          "1.2L SX CNG (1197cc)",
          "1.2 EX MT (1197cc)",
          "1.2 EX (O) MT (1197cc)",
          "1.2 S MT (1197cc)",
          "1.2 S (O) MT (1197cc)",
          "1.2 SX MT (1197cc)",
          "1.2 SX MT DUAL TONE (1197cc)",
          "1.2 S MT CNG (1197cc)",
          "1.2 SX (O) MT (1197cc)",
          "1.2 SX AMT (1197cc)",
          "1.2 SX AMT DUAL TONE (1197cc)",
          "1.2 SX MT CNG (1197cc)",
          "1.2 SX (O) CONNECT MT (1197cc)",
          "1.2 SX (O) CONNECT MT DUAL TONE (1197cc)",
          "S CNG (1197cc)",
          "SX CNG (1197cc)",
        ],
      },
      {
        model: "CRETA FACELIFT",
        variants: [
          "1.6 DUAL VTVT 6SP E (1591cc)",
          "1.6 DUAL VTVT 6SP SX (1591cc)",
          "1.6 DUAL VTVT 6SP SX (DT) (1591cc)",
          "1.6 DUAL VTVT 6SP SX (AT) (1591cc)",
          "1.6 DUAL VTVT 6SP SX (O) (1591cc)",
          "1.4 CRDI 6SP E+ (1396cc)",
          "1.4 CRDI 6SP S (1396cc)",
          "1.6 CRDI 6SP S (AT) (1582cc)",
          "1.6 CRDI 6SP SX (1582cc)",
          "1.6 CRDI 6SP SX (DT) (1582cc)",
          "1.6 CRDI 6SP SX (AT) (1582cc)",
          "1.6 CRDI 6SP SX (O) (1582cc)",
        ],
      },
      {
        model: "ELITE I20 FACELIFT",
        variants: [
          "1.2 KP DVTVT 5SP ERA (1197cc)",
          "1.2 KP DVTVT 5SP MAGNA EXE (1197cc)",
          "1.2 KP DVTVT 5SP SPORTZ (1197cc)",
          "1.2 KP DVTVT 5SP ASTA (1197cc)",
          "1.2 KP DVTVT 5SP ASTA DUAL (1197cc)",
          "1.2 KP DVTVT 5SP ASTA (O) (1197cc)",
          "1.4 U2 CRDI 6SP ERA (1396cc)",
          "1.4 U2 CRDI 6SP MAGNA EXE (1396cc)",
          "1.4 U2 CRDI 6SP SPORTZ (1396cc)",
          "1.4 U2 CRDI 6SP ASTA (1396cc)",
          "1.4 U2 CRDI 6SP ASTA DUAL (1396cc)",
          "1.4 U2 CRDI 6SP ASTA DSL (O) (1396cc)",
        ],
      },
      {
        model: "NEW VERNA",
        variants: [
          "1.5 MPI S (MT) BSVI (1497cc)",
          "1.5 U2 CRDI S PLUS (MT) BSVI (1493cc)",
          "1.5 MPI SX (MT) BSVI (1497cc)",
          "1.5 MPI SX IVT BSVI (1497cc)",
          "1.5 U2 CRDI SX (MT) BSVI (1493cc)",
          "1.5 MPI SX (O) (MT) BSVI (1497cc)",
          "1.5 U2 CRDI SX (AT) BSVI (1493cc)",
          "1.5 MPI SX (O) IVT BSVI (1497cc)",
          "1.5 U2 CRDI SX (O)(MT) BSVI (1493cc)",
          "1.0 TURBO GDI SX(O) BSVI (998cc)",
          "1.5 U2 CRDI SX (O) (AT) BSVI (1493cc)",
          "1.6 CRDI SX (1596cc)",
          "1.5 MPI S PLUS (MT) BSVI (1497cc)",
        ],
      },
      {
        model: "ALL NEW TUCSON",
        variants: [
          "PLATINUM AT BSVI (1999cc)",
          "PLATINUM AT BSVI (1997cc)",
          "SIGNATURE AT BSVI (1999cc)",
          "SIGNATURE AT BSVI (1997cc)",
          "SIGNATURE AT DT BSVI (1999cc)",
          "SIGNATURE AT DT BSVI (1997cc)",
          "SIGNATURE 4WD AT BSVI (1997cc)",
          "SIGNATURE 4WD AT DT BSVI (1997cc)",
        ],
      },
      {
        model: "ALL NEW VERNA",
        variants: [
          "1.5 MPI MT S BSVI (1497cc)",
          "1.5 MPI MT SX BSVI (1497cc)",
          "1.5 MPI MT SX(O) BSVI (1497cc)",
          "1.5 MPI MT EX BSVI (1497cc)",
          "1.5 MPI IVT SX BSVI (1497cc)",
          "1.5 MPI IVT SX(O) BSVI (1497cc)",
          "1.5 TURBO GDI MT SX BSVI (1482cc)",
          "1.5 TURBO GDI MT SX(O) BSVI (1482cc)",
          "1.5 TURBO GDI DCT SX BSVI (1482cc)",
          "1.5 TURBO GDI DCT SX(O) BSVI (1482cc)",
          "1.5 TURBO GDI MT SX DT BSVI (1482cc)",
          "1.5 TURBO GDI MT SX(O) DT BSVI (1482cc)",
          "1.5 TURBO GDI DCT SX DT BSVI (1482cc)",
        ],
      },
      {
        model: "I 10 1.2",
        variants: [
          "GLS ASTA (1197cc)",
          "GLS ASTA WITH SUNROOF (1197cc)",
          "GLS ASTA WITH SUNROOF AT (1197cc)",
          "GLS SPORTZ (1197cc)",
          "GLS SPORTZ AUTO TRANS (1197cc)",
          "MAGNA AUTO TRANS (1197cc)",
          "MAGNA I TECH SPECIAL EDTN (1197cc)",
          "SPORTZ OPTION (1197cc)",
        ],
      },
      {
        model: "TERRACAN",
        variants: [
          "2.9 CRDI (2902cc)",
          "2.9 CRDI (2902cc)",
          "2.9 CRDI 5 SEAT (2902cc)",
          "CRDI (2902cc)",
          "2.9 CRDI TURBO (2902cc)",
        ],
      },
      {
        model: "GETZ GLS",
        variants: [
          "ABS (1341cc)",
          "E III (1341cc)",
          "GLS (1341cc)",
          "1.6 (1599cc)",
        ],
      },
      {
        model: "GETZ",
        variants: [
          "GL (1341cc)",
          "GLE (1341cc)",
          "GVS (1341cc)",
          "GLS (1341cc)",
          "GLX (1341cc)",
          "DLS CRDI (1493cc)",
          "GL (1086cc)",
        ],
      },
      {
        model: "EON 1.0L KAPPA",
        variants: ["MAGNA (O)(O) (998cc)", "1.0 KAPPA MAGNA + (998cc)"],
      },
      {
        model: "EON 0.8L IRDE",
        variants: [
          "D-LITE LPG PLUS (814cc)",
          "LPG ERA PLUS (O) (814cc)",
          "ERA LPG PLUS (814cc)",
          "MAGNA LPG PLUS (814cc)",
        ],
      },
      {
        model: "H1 SERIES",
        variants: ["2.5 CRDI 12S (2497cc)"],
      },
      {
        model: "C-CLASS",
        variants: ["C200 (1991cc)"],
      },
      {
        model: "ELANTRA NEW",
        variants: [
          "2.0 VTVT SX (O) AT (1999cc)",
          "2.0 VTVT SX (1999cc)",
          "2.0 VTVT SX AT (1999cc)",
          "2.0 SX OPTIONAL AT (1999cc)",
          "2.0 VTVT S (1999cc)",
          "1.6 SX OPTION AT (1582cc)",
        ],
      },
      {
        model: "EXCEL",
        variants: ["STD (1599cc)", "STANDARD (1599cc)"],
      },
      {
        model: "GETZ GL",
        variants: ["GETZ (1341cc)", "GL (1341cc)"],
      },
      {
        model: "GETZ GLE",
        variants: ["GLE (1341cc)"],
      },
      {
        model: "GETZ GLX",
        variants: ["GLX (1341cc)"],
      },
      {
        model: "GETZ GVS",
        variants: [
          "1.5 CRDI (1493cc)",
          "1.1 (1086cc)",
          "1.6 (1599cc)",
          "GVS (1341cc)",
        ],
      },
      {
        model: "GRANDEUR",
        variants: ["XG (3342cc)", "TG (3342cc)"],
      },
      {
        model: "H1",
        variants: ["XG GASOLINE AT (2359cc)", "WAGON (2497cc)"],
      },
      {
        model: "HYUNDAI - ELANTRA CRDI 1.6 SX OPTION AT (1582CC)",
        variants: ["0 (1396cc)"],
      },
      {
        model: "HYUNDAI I 20",
        variants: ["0 (1197cc)"],
      },
      {
        model: "I 10 1.1",
        variants: ["MAGNA SPL EDITION (1086cc)"],
      },
      {
        model: "I10 GRAND",
        variants: ["PRIME (1197cc)"],
      },
      {
        model: "NEO ELANTRA",
        variants: [
          "VTVT 1.8 SX (1797cc)",
          "VTVT 1.8 S (1797cc)",
          "VTVT 1.8 SX AT (1797cc)",
          "CRDI 1.6 (1582cc)",
          "CRDI 1.6 S (1582cc)",
          "CRDI 1.6 SX (1582cc)",
          "CRDI 1.6 SX PETROL (1582cc)",
        ],
      },
      {
        model: "NEO FLUIDIC ELANTRA",
        variants: [
          "1.8 SX AT (1797cc)",
          "1.8 S (1797cc)",
          "1.8 SX (1797cc)",
          "VTVT 1.8 SX (1797cc)",
          "VTVT 1.8 SX AT (1797cc)",
          "VTVT 1.8 S (1797cc)",
          "SX AT (1797cc)",
          "2.0 SX OPTION AT (1999cc)",
          "2.0 SX MT (1999cc)",
          "2.0 SX AT (1999cc)",
          "2.0 SX (O) AT (1999cc)",
          "2.0 SX (O) (1999cc)",
          "2.0 S MT (1999cc)",
          "CRDI 1.6 (1582cc)",
          "CRDI 1.6 SX (1582cc)",
          "CRDI 1.6 S (1582cc)",
          "CRDI 1.6 SX AT (1582cc)",
          "1.6 SX (O) AT (1582cc)",
          "1.6 SX (O) (1582cc)",
          "CRDI S (1582cc)",
          "CRDI SX (1582cc)",
          "CRDI BASE (1582cc)",
          "CRDI SX AT (1582cc)",
          "SX (2720cc)",
          "S (1598cc)",
        ],
      },
      {
        model: "NEXO",
        variants: ["ELECTRIC (1598cc)", "FCEV (99000cc)", "0 (120cc)"],
      },
      {
        model: "SANTRO 1.1",
        variants: ["ZIP PLUS GS (1086cc)"],
      },
      {
        model: "SONATA GLS",
        variants: ["GLS (1997cc)"],
      },
      {
        model: "SONATA GOLD",
        variants: ["GOLD (1997cc)"],
      },
      {
        model: "TG AZERA",
        variants: ["0 (3342cc)"],
      },
    ],
  },
  {
    make: "ASHOK LEYLAND",
    models: [
      {
        model: "STILE",
        variants: [
          "LE (1461cc)",
          "LS (1461cc)",
          "LX (1461cc)",
          "LE 8 SEATER (1461cc)",
          "LS 8 SEATER (1461cc)",
          "LX 7 SEATER CAPTAIN SEAT ALLOY WHEEL (1461cc)",
          "LX 7 SEATER CAPTAIN SEAT (1461cc)",
          "LX 8 SEATER (1461cc)",
          "LS 7 SEATER CAPTAIN SEAT ALLOY WHEEL (1461cc)",
          "LS 7 SEATER CAPTAIN SEAT (1461cc)",
          "LS (O) (1461cc)",
          "LS O (1461cc)",
          "LE 8 STR (1461cc)",
          "LE 8STR (1461cc)",
          "LE 7 STR (1461cc)",
          "LS 7 STR (1461cc)",
          "LS 8 STR (1461cc)",
          "LX 7 STR (1461cc)",
          "LX 7 STR ALLOY (1461cc)",
          "LX 8 STR (1461cc)",
        ],
      },
      {
        model: "DOST",
        variants: [
          "DOST EXPRESS (1478cc)",
          "EXPRESS/12 SEATER (1478cc)",
          "14 SEATER/DIESEL (1478cc)",
          "EXPRESS DELUXE (1478cc)",
          "EXPRESS DELUXE BSVI (1478cc)",
          "EXPRESS 11 SEATER/DIESEL BSVI (120cc)",
        ],
      },
      {
        model: "MINI BUS",
        variants: ["13 SEATER BSVI (2300cc)"],
      },
      {
        model: "VIKING",
        variants: ["ALPSV 4/186 CAMPER VAN (5660cc)"],
      },
      {
        model: "ALPSV CAMPER VAN",
        variants: ["0 (3598cc)"],
      },
      {
        model: "MBPV 4X4",
        variants: ["0 (5759cc)"],
      },
    ],
  },
  {
    make: "ASTON MARTIN",
    models: [
      {
        model: "DBS",
        variants: [
          "COUPE 2 SEATER (5935cc)",
          "6 L V12 (5935cc)",
          "COUPE (5935cc)",
          "COUPE (5935cc)",
          "VOLANTE (5935cc)",
          "COUPE 2STR (5935cc)",
        ],
      },
      {
        model: "DBX",
        variants: [
          "V8 (3982cc)",
          "707 (3982cc)",
          "0 (3982cc)",
          "2020 (3982cc)",
        ],
      },
      {
        model: "V8 VANTAGE",
        variants: [
          "SPORT (4735cc)",
          "COUPE (4735cc)",
          "COUPE (4735cc)",
          "S COUPE SPORTSHIFT (4735cc)",
          "S (4735cc)",
          "SP10 (4735cc)",
          "S ROADSTER (4735cc)",
          "4.7 L (4735cc)",
          "GT4 (4735cc)",
          "N420 (4735cc)",
          "ROADSTER (4735cc)",
          "S COUPE (4735cc)",
          "N420 ROADSTER (5935cc)",
          "VOLANTE (5935cc)",
          "N420 (5935cc)",
          "COUPE (3982cc)",
          "ROADSTER (4282cc)",
        ],
      },
      {
        model: "VANQUISH",
        variants: [
          "V12 AT (5935cc)",
          "6.0 V12 TOUCHTRONIC (5935cc)",
          "T II (5935cc)",
          "V12 (5935cc)",
        ],
      },
      {
        model: "DB11",
        variants: [
          "V12 AT (5198cc)",
          "EVOLUTION (5198cc)",
          "EVOLUTION (5198cc)",
          "V12 COUPE (5935cc)",
          "V8 VOLANTE (3982cc)",
          "V12 (3880cc)",
          "V12 (5204cc)",
          "V8 COUPE (3880cc)",
        ],
      },
      {
        model: "V12 VANTAGE",
        variants: ["ROADSTER (5935cc)", "COUPE (5935cc)"],
      },
      {
        model: "RAPIDE",
        variants: [
          "6.0 S (5935cc)",
          "SV12 (5935cc)",
          "V12 (5935cc)",
          "S (5935cc)",
          "LUXE (5935cc)",
          "6.0 RHD (5935cc)",
          "6.0 RHD TOUCHTRONIC (5935cc)",
        ],
      },
      {
        model: "DB9",
        variants: [
          "V12 (5935cc)",
          "VOLANTE 4 STR (5935cc)",
          "6 L V12 (5935cc)",
          "VOLANTE (5935cc)",
          "COUPE (5935cc)",
        ],
      },
      {
        model: "VIRAGE",
        variants: [
          "V12 LIMITED EDITION (5935cc)",
          "COUPE (5935cc)",
          "VOLANTE (5935cc)",
          "6.0 V12 VOLANTE (5935cc)",
        ],
      },
      {
        model: "AUSTIN",
        variants: ["7 (747cc)", "0 (700cc)"],
      },
      {
        model: "V12",
        variants: ["ZAGATO (5935cc)"],
      },
      {
        model: "VANTAGE",
        variants: ["ROADSTER (4735cc)", "V12 (5935cc)"],
      },
      {
        model: "VINTAGE",
        variants: ["AUSTIN TEN (1000cc)"],
      },
      {
        model: "ONE 77",
        variants: ["V12 (7312cc)"],
      },
      {
        model: "VANTAGE V8",
        variants: ["ROADSTER (4735cc)"],
      },
    ],
  },
  {
    make: "ISUZU",
    models: [
      {
        model: "D-MAX",
        variants: [
          "CREW S CAB (2499cc)",
          "V CROSS Z HIGH (2499cc)",
          "S CAB SR AC BSVI (2499cc)",
          "S CAB HR AC BSVI (2499cc)",
          "HI LANDER 4X2 MT BSVI (1898cc)",
          "V CROSS Z 4X2 AT BSVI (1898cc)",
          "V CROSS Z 4X4 MT BSVI (1898cc)",
          "V CROSS Z PRESTIGE 4X4 AT BSVI (1898cc)",
          "V CROSS HIGH PLUS (AT) (2999cc)",
          "V CROSS HIGH PLUS (AT) (1898cc)",
          "SPACE CAB ARCHED DECK (2499cc)",
          "V CROSS (2499cc)",
          "V-CROSS 4X4 (2499cc)",
          "V CROSS 4X4 (2499cc)",
          "V-CROSS HIGH PLUS AT (2499cc)",
          "BS4 (2499cc)",
          "CREWCAB HR (2499cc)",
          "S CAB HR (2499cc)",
          "CREW CAB AC (2499cc)",
          "SPACE CAB ARCHED DECK (2 SEATER) (2499cc)",
          "SINGLE CAB (2499cc)",
          "SINGLE CAB (2499cc)",
          "DOUBLE CAB (2499cc)",
          "SPACE CAB (2499cc)",
          "HIGH (2499cc)",
          "CAB CHASSIS A/C BS-VI (2499cc)",
          "S CAB (2499cc)",
          "V CROSS HIGH PLUS (2499cc)",
          "SPACE CAB FLAT DECK BS4 (2490cc)",
          "V CROSS 4X4 Z AUTOMATIC TRANSMISSION (1898cc)",
          "V CROSS 4X4 Z (1898cc)",
          "HI-LANDER (1898cc)",
          "V CROSS HIGH PLUS Z PRESTIGE (1898cc)",
          "V CROSS (2999cc)",
        ],
      },
      {
        model: "D-MAX V-CROSS",
        variants: [
          "Z PRESTIGE (1898cc)",
          "STANDARD (2499cc)",
          "HIGH (Z) (2499cc)",
          "HIGH (2499cc)",
        ],
      },
      {
        model: "MU-7",
        variants: [
          "PREMIUM AT (2999cc)",
          "PREMIUM AT (2999cc)",
          "BASE BS IV (2999cc)",
          "HIGH BS IV (2999cc)",
          "MT (2999cc)",
          "BS4 4X2 MT (2999cc)",
          "V CROSS (2999cc)",
          "4X2 (2999cc)",
          "4X2 HIGH (2999cc)",
          "4X2 PREMIUM AT (2999cc)",
          "3.0 MT 7 SEATER (2999cc)",
          "4X2 BASE (2999cc)",
          "HIGH BS III (2999cc)",
          "BASE BS III (2999cc)",
          "BASE (2999cc)",
          "HIGH (2999cc)",
          "BASE (6749cc)",
        ],
      },
      {
        model: "MU-X",
        variants: [
          "4X2 (2999cc)",
          "4X4 (2999cc)",
          "4X4 AT (1898cc)",
          "4X2 AT (1898cc)",
          "4X4 AT (2999cc)",
          "4X2 AT (2999cc)",
          "1.9 AT 7 SEATER (1898cc)",
          "4X2 (1898cc)",
          "4X4 (1898cc)",
          "4X2 AT 1898CC (1898cc)",
          "4X4 AT 1898CC (1898cc)",
          "3.0 4X4 7 SEATER (2999cc)",
          "3.0 4X2 7 SEATER (2999cc)",
          "3.0L 2WD AT (2999cc)",
        ],
      },
      {
        model: "PANTHER",
        variants: ["TBRS4 (2499cc)", "0 (2499cc)"],
      },
      {
        model: "D MAX",
        variants: ["CAB CHASSIS A/C BS-VI (3455cc)"],
      },
      {
        model: "MU 7",
        variants: ["0 (2999cc)", "0 (2999cc)"],
      },
      {
        model: "HI LANDER",
        variants: ["1.9 4X2 5 SEATER (1898cc)", "2WD MT (1898cc)"],
      },
      {
        model: "V-CROSS",
        variants: [
          "AT Z PRESTIGE (1898cc)",
          "HIGH PLUS (1898cc)",
          "2WD AT Z (1898cc)",
          "4WD AT Z (1898cc)",
          "4WD MT Z (1898cc)",
        ],
      },
      {
        model: "TROOPER",
        variants: ["JEEP (2800cc)", "JEEP 9 SEAT (3455cc)", "0 (1297cc)"],
      },
      {
        model: "S-CAB",
        variants: [
          "2WD HR BS6 (2499cc)",
          "SR STANDARD RIDE (2499cc)",
          "SR (2499cc)",
          "HR (2499cc)",
        ],
      },
      {
        model: "FR",
        variants: ["1318 (5193cc)"],
      },
      {
        model: "D-MAX V-CROSS Z",
        variants: [
          "PRESTIGE 4X4 AT (1898cc)",
          "4X4 MT (1898cc)",
          "4X2 AT. (1898cc)",
          "4X2 AT (1898cc)",
        ],
      },
    ],
  },
  {
    make: "JEEP",
    models: [
      {
        model: "COMPASS",
        variants: [
          "1.4 LIMITED (1368cc)",
          "1.4 LIMITED (O) (1368cc)",
          "1.4 NIGHT EAGLE (1368cc)",
          "1.4 LIMITED (O) AT (1368cc)",
          "2.0D LIMITED (O) (1956cc)",
          "2.0D 4X4 LIMITED (O) (1956cc)",
          "1.4 LIMITED AT (1368cc)",
          "2.0D LIMITED (1956cc)",
          "2.0D 4X4 LIMITED (1956cc)",
          "2.0D LONGITUDE (O) (1956cc)",
          "2.0D LONGITUDE (1956cc)",
          "1.4 4X2 SPORT (1368cc)",
          "2.0D SPORT (1956cc)",
          "2.0D LIMITED 4X2 (1956cc)",
          "2.0D LIMITED 4X4 (1956cc)",
          "2.0D LIMITED 4X2 (O) (1956cc)",
          "2.0D LIMITED 4X4 (O) (1956cc)",
          "2.0D 4X2 BEDROCK (1956cc)",
          "1.4 4X2 BEDROCK (1368cc)",
          "2.0D SPORT BEDROCK 4X2 (1956cc)",
          "1.4 SPORT BEDROCK 4X2 (1368cc)",
          "1.4 LIMITED PLUS (1368cc)",
          "2.0D LIMITED PLUS 4X4 (1956cc)",
          "2.0D LIMITED PLUS 4X2 (1956cc)",
          "1.4 LONGITUDE 4X2 (O) AT (1368cc)",
          "1.4 4X2 SPORT PLUS (1368cc)",
          "2.0D SPORT PLUS (1956cc)",
          "2.0D TRAIL HAWK 4X4(O)SUNROOF (1956cc)",
          "2.0D TRAIL HAWK 4X4 (O) (1956cc)",
          "2.0D LONGITUDE 4X4 (AT) (1956cc)",
          "2.0D LIMITED PLUS 4X4 (AT) (1956cc)",
          "1.4 LONGITUDEÂ PLUS (1368cc)",
          "2.0D LONGITUDE PLUS (1956cc)",
          "2.0D NIGHT EAGLE MT (1956cc)",
          "2.0D NIGHT EAGLE 9 AT (1956cc)",
          "2.0D LIMITED PLUS 4X4 9AT (1956cc)",
          "1.4L ANNIVARSARY EDD (AT) (1368cc)",
          "1.4 S (AT) (1368cc)",
          "2.0D MT AE (1956cc)",
          "2.0D AT AE (1956cc)",
          "2.0D 80TH ANNIVERSARY 4X2 (1956cc)",
          "2.0D 80TH ANNIVERSARY 4X4 (1956cc)",
          "1.4 SPORT AT (1368cc)",
          "1.4 S MAIR DDCT (1368cc)",
          "2.0D S AT 4X4 (1956cc)",
          "2.0D S MT 4X2 (1956cc)",
          "2.0D 80TH ANNIVERSARY MT (1956cc)",
          "2.0D AT LONGITUDE PLUS 4X4 (1956cc)",
          "2.0D LIMITED O 4X4 AT (1956cc)",
          "1.4 SPORT MAIR DDCT (1368cc)",
          "1.4 S O DCT (1368cc)",
          "80 AT (1368cc)",
          "1.4 MAIR SPORT MT BSVI (1368cc)",
          "1.4 MAIR SPORT DDCT BSVI (1368cc)",
          "1.4 MAIR LONGITUDE(O)DDCT BSVI (1368cc)",
          "1.4 MAIR NIGHT EAGLE DDCT BSVI (1368cc)",
          "1.4 MAIR LIMITED(O)DDCT BSVI (1368cc)",
          "1.4 MAIR MODEL S(O2)DDCT BSVI (1368cc)",
          "2.0D SPORT MT BSVI (1956cc)",
          "2.0D LONGITUDE(O)MT BSVI (1956cc)",
          "2.0D NIGHT EAGLE MT BSVI (1956cc)",
          "2.0D LIMITED(O)MT BSVI (1956cc)",
          "2.0D MODEL S (O2)MT BSVI (1956cc)",
          "2.0D LIMITED(O)4X4 AT BSVI (1956cc)",
          "2.0D MODEL S (O2)4X4 AT BSVI (1956cc)",
          "2.0D TRAIL HAWK 4X4 AT BSVI (1956cc)",
          "2.0D MODEL S (O1) MT BSVI (1956cc)",
          "2.0D 5TH ANNIVER MT BSVI (1956cc)",
          "2.0D 5TH ANNIVER AT 4X4 BSVI (1956cc)",
          "1.4 MAIR 5TH ANNIVER DDCT BSVI (1368cc)",
          "2.0D NIGHT EAGLE(O)MT BSVI (1956cc)",
          "1.4 NIGHT EAGLE(O)DDCT BSVI (1368cc)",
          "1.4 CLUB EDITION DDCT BSVI (1368cc)",
          "NEW  MODEL S 2.0D 9AT (1956cc)",
          "NEW  MODEL S (O2) 4X4 2.0D AT (1956cc)",
          "NEW  2.0 NIGHT EAGLE (1956cc)",
          "NEW  S (O2) 4X4 2.0D AT (1956cc)",
          "NEW  S (O2) 2.0D MT (1956cc)",
          "NEW  (O) 4X4 2.0D AT (1956cc)",
          "NEW 2.0 TRAILHAWK 4X4 D (O2) AT (1956cc)",
          "NEW  MODS O1 4X4 AT (1956cc)",
          "LIMITED PLUS 2.0 D BSIV (1956cc)",
          "SPORT (1956cc)",
          "LIMITED (1956cc)",
          "LONGITUDE OPTION (1956cc)",
          "LONGITUDE (1956cc)",
          "LIMITED OPTION (1956cc)",
          "LIMITED 4X4 (1956cc)",
          "LIMITED OPTION 4X4 (1956cc)",
          "LIMITED PLUS DIESEL (1956cc)",
          "LIMITED PLUS DIESEL 4X4 (1956cc)",
          "LIMITED (O) 2.0 DIESEL BLACK PACK (1956cc)",
          "LIMITED (O) 2.0 DIESEL 4X4 BLACK PACK (1956cc)",
          "LIMITED O 2.0 DIESEL BS-VI (1956cc)",
          "LONGITUDE O 2.0 DIESEL BS-VI (1956cc)",
          "LIMITED 2.0 DIESEL (1956cc)",
          "TRAILHAWK 2.0 4X4 AT (1956cc)",
          "SPORT 2.0 DIESEL BS6 (1956cc)",
          "LONGITUDE OPT 2.0 DIESEL BS6 (1956cc)",
          "LIMITED OPT 2.0 DIESEL BS6 (1956cc)",
          "LIMITED OPT 2.0 DIESEL 4X4 AT BS6 (1956cc)",
          "LIMITED 80 ANNIVERSARY 2.0 DIESEL BS6 (1956cc)",
          "LIMITED 80 ANNIVERSARY 2.0 DIESEL 4X4 AT BS6 (1956cc)",
          "S 2.0 DIESEL BS6 (1956cc)",
          "S 2.0 DIESEL 4X4 AT BS6 (1956cc)",
          "MODEL S O 2.0 DIESEL (1956cc)",
          "MODEL S O DIESEL 4X4 AT (1956cc)",
          "TRAILHAWK (O2) 2.0 4X4 (1956cc)",
          "2.0 5TH ANNIVERSARY D MT (1956cc)",
          "2.0 BEDROCK (1956cc)",
          "LIMITED PLUS 2.0 DIESEL AT (1956cc)",
          "LONGITUDE 2.0 DIESEL AT (1956cc)",
          "LONGITUDE PLUS 2.0 D BS6 (1956cc)",
          "TRAILHAWK (1956cc)",
          "TRAILHAWK (O) 2.0 4X4 (1956cc)",
          "2.0 DIESEL 4X4 NIGHT EAGLE 5 SEAT (1956cc)",
          "2.0 DIESEL 4X2 NIGHT EAGLE 5 SEAT (1956cc)",
          "2.0 DIESEL LONGITUDE PLUS (1956cc)",
          "2.0 DIESEL 4X4 (O) TRAILHAWK AT (1956cc)",
          "2.0 DIESEL LONGITUDE (4X4) AT (1956cc)",
          "2.0 DIESE LIMITED PLUS (4X4) AT (1956cc)",
          "2.0 DIESEL (O) LONGITUDE 5 SEAT (1956cc)",
          "2.0 DIESEL LIMITED 5 SEAT (1956cc)",
          "2.0 DIESEL (O) LIMITED 5 SEAT (1956cc)",
          "2.0 DIESEL 4X4 LIMITED 5 SEAT (1956cc)",
          "2.0 DIESEL 4X4 (O) LIMITED 5 SEAT (1956cc)",
          "2.0 DIESEL SPORT PLUS 5 SEAT (1956cc)",
          "2.0 DIESEL LONGITUDE 5 SEAT (1956cc)",
          "2.0 DIESEL SPORT 5 SEAT (1956cc)",
          "2.0 DIESEL 4X4 TRAILHAWK AT (1956cc)",
          "2.0 DIESEL 4X4 LIMITED PLUS 5 SEAT (1956cc)",
          "2.0 MULTIJET DIESEL SPORTS 4X2 MT (1956cc)",
          "2.0 MULTIJET DIESEL LONGITUDE (O) 4X2 MT (1956cc)",
          "2.0 MULTIJET DIESEL LIMITED (O) 4X2 MT (1956cc)",
          "2.0 MULTIJET DIESEL LIMITED 80 ANNIVERSARY  4X2 MT (1956cc)",
          "2.0 MULTIJET DIESEL  S  4X2 MT (1956cc)",
          "2.0 MULTIJET DIESEL LIMITED (O) 4X4 AT (1956cc)",
          "2.0 MULTIJET DIESEL LIMITED 80 ANNIVERSARY  4X4 AT (1956cc)",
          "2.0 MULTIJET DIESEL  S  4X4 AT (1956cc)",
          "2.0 MULTIJET DIESEL S (O1) 4X4 MT (1956cc)",
          "2.0 MULTIJET DIESEL S (O1) 4X4 AT (1956cc)",
          "2.0 MULTIJET DIESEL S (O2) 5 SEAT MT (1956cc)",
          "2.0 MULTIJET DIESEL S (O2) 4X4  5 SEAT AT (1956cc)",
          "2.0 DIESEL 4X4 (O) LIMITED 5 SEAT AT (1956cc)",
          "2.0 MODEL S (O1) DIESEL MT BS VI (1956cc)",
          "2.0 DIESEL NIGHT EAGLE (O) 5 SEAT (1956cc)",
          "2.0  DIESEL LONGITUDE (1956cc)",
          "2.0  DIESEL LIMITED 4X4 (1956cc)",
          "2.0  DIESEL NIGHT EAGLE 4X4 AT (1956cc)",
          "2.0  DIESEL LIMITED PLUS 4X4 (1956cc)",
          "2.0  DIESEL LIMITED PLUS 4X4 AT (1956cc)",
          "2.0  DIESEL LONGITUDE PLUSE 4X4 AT (1956cc)",
          "2.0  DIESEL TRAILHAWK 4X4 D (O) AT BSVI (1956cc)",
          "2.0  DIESEL TRAILHAWK 4X4 D AT BSVI (1956cc)",
          "2.0 DIESEL LIMITED PLUS 5 SEAT (1956cc)",
          "LONGITUDE 2.0 DIESEL BS-VI (1956cc)",
          "LIMITED O 2.0 DIESEL 4X4 BS-VI (1956cc)",
          "LIMITED 2.0 DIESEL 4X4 BS-VI (1956cc)",
          "LIMITED 2.0 DIESEL BS-VI (1956cc)",
          "SPORT BEDROCK 2.0 DIESEL (1956cc)",
          "LIMITED PLUS DIESEL 4X4 BS-VI (1956cc)",
          "LIMITED PLUS DIESEL BS-VI (1956cc)",
          "LIMITED 2.0 DIESEL BS4 (1956cc)",
          "LIMITED 2.0 DIESEL 4X4 AT (1956cc)",
          "LIMITED 2.0 D (1956cc)",
          "SPORT PLUS 2.0 DIESEL BS-VI (1956cc)",
          "TRAILHAWK 2.0 4X4 BS-VI (1956cc)",
          "TRAILHAWK (O) 2.0 4X4 BS-VI (1956cc)",
          "2.0 NIGHT EAGLE (O) DIESEL (1956cc)",
          "S 2.0 DIESEL 4X4 AT (1956cc)",
          "80 ANNIVERSARY 2.0 DIESEL 4X4 AT (1956cc)",
          "SPORT 4X2 MT (1956cc)",
          "LONGITUDE OPTION 4X2 MT (1956cc)",
          "LIMITED OPTION 4X2 MT (1956cc)",
          "MODEL S (O2) 4X2 MT (1956cc)",
          "MODEL S (O2) 4X4 AT (1956cc)",
          "TRAIL HWAK 4X4 AT (1956cc)",
          "5TH ANNIVERSARY 4X4 2.0D AT (1956cc)",
          "5TH ANNIVERSARY 4X2 2.0D MT (1956cc)",
          "LIMITED (O) 4X4 AT (1956cc)",
          "LIMITED (O) 2.0 DIESEL 4X4 BLACK PACK BS-VI (1956cc)",
          "LIMITED (O) 2.0 DIESEL BLACK PACK BS-VI (1956cc)",
          "LIMITED O 2.0 DIESEL 4X4 AT (1956cc)",
          "S O 2.0 DIESEL (1956cc)",
          "S O 2.0 DIESEL 4X4 AT (1956cc)",
          "BLACK SHARK (O) (1956cc)",
          "BLACK SHARK (O) 4X2 AT (1956cc)",
          "1.4 SPORT PLUS (1956cc)",
          "LIMITED O 2.0 DIESEL (1956cc)",
          "NIGHT EAGLE O 2.0 DIESEL (1956cc)",
          "5TH ANNIVERSARY EDITION 2.0 DIESEL 4X4 AT (1956cc)",
          "TRAILHAWK 2.0 4X4 (1956cc)",
          "2.0 LIMITED (O) BLACK PACK (1956cc)",
          "2.0 LIMITED (O) 4X4 BLACK PACK (1956cc)",
          "2.0 SPORT BEDROCK (1956cc)",
          "2.0 TRAILHAWK 4X4 (O) AT BSVI (1956cc)",
          "2.0 TRAILHAWK 4X4 AT BSVI (1956cc)",
          "LIMITED (O) 2.0 DIESEL 4X2 (1956cc)",
          "LIMITED 2.0 DIESEL 4X2 (1956cc)",
          "SPORT PLUS 2.0 DIESEL (1956cc)",
          "S 2.0 4X4 AT DIESEL (1956cc)",
          "MODEL S 2.0 (O) DIESEL (1956cc)",
          "MODEL S 2.0 4X4 AT (O) DIESEL (1956cc)",
          "80 ANNIVERSARY 2.0 4X4 AT DIESEL (1956cc)",
          "LIMITED 2.0 4X4 AT (O) DIESEL (1956cc)",
          "MODEL S 2.0 4X4 AT (O2) DIESEL (1956cc)",
          "5TH ANNIVERSARY EDITION 2.0 4X4 AT (1956cc)",
          "LIMITED O 2.0 DIESEL 4X4 (1956cc)",
          "LIMITED 2.0 DIESEL 4X4 (1956cc)",
          "LONGITUDE 2.0 DIESEL (1956cc)",
          "LIMITED PLUS 2.0 DIESEL (1956cc)",
          "LIMITED PLUS 2.0 DIESEL 4X4 (1956cc)",
          "LONGITUDE 2.0 DIESEL 4X4 AT (1956cc)",
          "LIMITED PLUS 2.0 DIESEL 4X4 AT (1956cc)",
          "LONGITUDE PLUS 2.0 DIESEL (1956cc)",
          "NIGHT EAGLE 2.0 DIESEL 4X4 AT (1956cc)",
          "80 ANNIVERSARY 2.0 DIESEL (1956cc)",
          "S 2.0 DIESEL (1956cc)",
          "LONGITUDE O 2.0 DIESEL (1956cc)",
          "SPORT 2.0 DIESEL (1956cc)",
          "NIGHT EAGLE 2.0 DIESEL (1956cc)",
          "LONGITUDE PLUS 2.0 DIESEL 4X4 AT (1956cc)",
          "5TH ANNIVERSARY EDITION 2.0 DIESEL (1956cc)",
          "1.4 SPORT (1368cc)",
          "2.0 LIMITED (O) (1956cc)",
          "2.0 SPORT (1956cc)",
          "2.0 LONGITUDE (1956cc)",
          "2.0 LONGITUDE (O) (1956cc)",
          "2.0 LIMITED 4X4 (1956cc)",
          "2.0 LIMITED (1956cc)",
          "2.0 LIMITED (O) 4X4 (1956cc)",
          "2.0 4X4 LIMITED (O) (1956cc)",
          "2.0 4X4 LIMITEDÃ‚ (1956cc)",
          "2.0 4X2 BEDROCK (1956cc)",
          "2.0 SPORT BEDROCK 4X2 (1956cc)",
          "2.0 TRAIL HAWK 4X4 (O) SUNROOF (1956cc)",
          "2.0 TRAIL HAWK 4X4 (O) (1956cc)",
          "2.0 SPORT PLUS (1956cc)",
          "NEW  S (O2) 1.4MAIR DDCT (1368cc)",
          "NEW  LONGITUDE O AT (1368cc)",
          "NEW  1.4 NIGHT EAGLE (1368cc)",
          "LIMITED PLUS MAIR DDCT (1368cc)",
          "LIMITED PLUS DDCT BSVI (1368cc)",
          "SPORT 1.4 L (1368cc)",
          "LTD 1.4MAIR DDCT (1368cc)",
          "NEW  LIMITED O AT (1368cc)",
          "LIMITED PLUS 1.4MAIR (1368CC) (1368cc)",
          "SPORT 1.4 L MULTIAIR (1368cc)",
          "SPORT (1368cc)",
          "LIMITED (1368cc)",
          "LIMITED OPTION AT (1368cc)",
          "LIMITED PLUS PETROL AT (1368cc)",
          "LIMITED (O) 1.4 PETROL AT BLACK PACK (1368cc)",
          "MODEL S O 1.4 PETROL DCT (1368cc)",
          "LIMITED PLUS PETROL BS6 (1368cc)",
          "SPORT 1.4 MULTI AIR MT BS6 (1368cc)",
          "SPORT 1.4 MULTI AIR DCT BS6 (1368cc)",
          "LONGITUDE OPT 1.4 MULTI AIR DCT BS6 (1368cc)",
          "LIMITED OPT 1.4 MULTI AIR DCT BS6 (1368cc)",
          "LIMITED 80 ANNIVERSARY 1.4 MULTI AIR DCT BS6 (1368cc)",
          "S 1.4 MULTI AIR DCT BS6 (1368cc)",
          "NIGHT EAGLE O 1.4 PETROL AT (1368cc)",
          "1.4L MULTAIR PETROL LIMITED OPTION PACK 4*2 AT (1368cc)",
          "NIGHT EAGLE 1.4 MAIR DDCT (1368cc)",
          "LONGITUDE PLUS 1.4 MAIR DDCT BS6 (1368cc)",
          "1.4 MULTI AIR PETROL DDCT LONGITUDE PLUS 5 SEAT (1368cc)",
          "1.4 PETROL DDCT NIGHT EAGLE 5 SEAT (1368cc)",
          "1.4 MULTI AIR PETROL DDCT LIMITED 5 SEAT (1368cc)",
          "1.4 MULTI AIR PETROL DDCT (O) LIMITED 5 SEAT (1368cc)",
          "1.4 MULTI AIR PETROL SPORT PLUS (1368cc)",
          "1.4 MULTI AIR PETROL SPORT 5 SEAT (1368cc)",
          "1.4 MULTI AIR PETROL DDCT LIMITED 5 SEAT AT (1368cc)",
          "1.4 MULTI AIR PETROL DDCT LIMITED PLUS 5 SEAT (1368cc)",
          "1.4 MULTIAIR PETROL SPORTS 4X2 MT (1368cc)",
          "1.4 MULTIAIR PETROL DDCT LIMITED 80 ANNIVERSARY  4X2 AT (1368cc)",
          "1.4 MULTIAIR PETROL DDCT  S  4X2 AT (1368cc)",
          "1.4 MULTIAIR PETROL DDCT SPORTS 4X2 AT (1368cc)",
          "1.4 MULTIAIR PETROL DDCT LONGITUDE (O) 4X2 AT (1368cc)",
          "1.4 MULTIAIR PETROL DDCT LIMITED (O) 4X2 AT (1368cc)",
          "1.4 MULTI AIR PETROL DDCT  S (O2) 5 SEAT (1368cc)",
          "1.4 MULTIAIR PETROL DDCT MODEL S (01) 4X2 AT (1368cc)",
          "1.4 PETROL CLUB EDITION DCT (1368cc)",
          "1.4 LONGITUDE (O) MAIR DDCT BS VI (1368cc)",
          "1.4 MODEL S (O1) MAIR DDCT BS VI (1368cc)",
          "1.4 MODEL S (O2) PETROL AT (1368cc)",
          "1.4 MULTI AIR PETROL DDCT LONGITUDE (O) 5 SEAT (1368cc)",
          "NIGHT EAGLE O 1.4 PETROL DCT (1368cc)",
          "LIMITED 1.4 PETROL AT BS-VI (1368cc)",
          "LIMITED O 1.4 PETROL AT BS-VI (1368cc)",
          "LIMITED O 1.4 PETROL DCT (1368cc)",
          "LIMITED PLUS PETROL AT BS-VI (1368cc)",
          "LONGITUDE (O) 1.4 PETROL AT BS-VI (1368cc)",
          "SPORT PLUS 1.4 PETROL BS-VI (1368cc)",
          "S 1.4 PETROL DCT (1368cc)",
          "LONGITUDE O 1.4 PETROL DCT (1368cc)",
          "SPORT 1.4 PETROL DCT (1368cc)",
          "1.4 DCT CLUB EDITION (1368cc)",
          "80 ANNIVERSARY 1.4 PETROL DCT (1368cc)",
          "1.4 NIGHT EAGLE (O) DCT PETROL (1368cc)",
          "S(O2) 1.4 PET AT (1368cc)",
          "SPORT 4X2 MT (1368cc)",
          "SPORT 4X2 AT (1368cc)",
          "LONGITUDE OPTION 4X2 AT (1368cc)",
          "MODEL S (O2) 4X2 AT (1368cc)",
          "S(O2) 1.4 MAIR DDCT (1368cc)",
          "5TH ANNIVERSARY EDITION 1.4 PETROL DCT (1368cc)",
          "LIMITED (O) 1.4 PETROL AT BLACK PACK BS-VI (1368cc)",
          "S O 1.4 PETROL DCT (1368cc)",
          "5TH ANNIVERSARY 1.4 MAIR DDCT (1368cc)",
          "CLUB EDITION 1.4 DCT (1368cc)",
          "1.4 LIMITED (O) BLACK PACK AT (1368cc)",
          "LIMITED (O) 1.4 DCT PETROL (1368cc)",
          "MODEL S (O) 1.4 DCT PETROL (1368cc)",
          "SPORT 1.4 DCT PETROL (1368cc)",
          "S 1.4 DCT PETROL (1368cc)",
          "80 ANNIVERSARY 1.4 DCT PETROL (1368cc)",
          "5TH ANNIVERSARY EDITION 1.4 DCT PETROL (1368cc)",
          "LIMITED O 1.4 PETROL AT (1368cc)",
          "LONGITUDE (O) 1.4 PETROL AT (1368cc)",
          "LONGITUDE O 1.4 PETROL AT (1368cc)",
          "LIMITED 1.4 PETROL AT (1368cc)",
          "LIMITED PLUS 1.4 PETROL AT (1368cc)",
          "SPORT PLUS 1.4 PETROL (1368cc)",
          "LONGITUDE PLUS 1.4 PETROL AT (1368cc)",
          "SPORT 1.4 PETROL (1368cc)",
          "NIGHT EAGLE 1.4 PETROL AT (1368cc)",
          "1.4 PETROL DDCT NIGHT EAGLE (O) 5 SEAT (1396cc)",
        ],
      },
      {
        model: "MERIDIAN",
        variants: [
          "LIMITED 4X2 (MT) (1956cc)",
          "LIMITED 4X2 MT (1956cc)",
          "LIMITED 4X2 (AT) (1956cc)",
          "LIMITED 4X2 AT (1956cc)",
          "LIMITED (O) 4X2 (MT) (1956cc)",
          "LIMITED O 4X2 MT (1956cc)",
          "LIMITED (O) 4X4 (AT) (1956cc)",
          "LIMITED O 4X4 AT (1956cc)",
          "LIMITED (O) UPLAND 4X4 (MT) (1956cc)",
          "LIMITED (O) UPLAND 4X2 (AT) (1956cc)",
          "LIMITED PLUS X 4X2 (AT) (1956cc)",
          "LIMITED PLUS X 4X4 (AT) (1956cc)",
          "LIMITED (O) UPLAND 4X4 (AT) (1956cc)",
          "LIMITED O 4X2 AT (1956cc)",
          "LIMITED (1956cc)",
          "LIMITED (1956cc)",
          "LIMITED (1956cc)",
          "LIMITED (1956cc)",
          "LIMITED (1956cc)",
          "LIMITED (1956cc)",
          "LIMITED (1956cc)",
          "LIMITED (1956cc)",
          "LIMITED MT (1956cc)",
          "LIMITED (O) MT (1956cc)",
          "LIMITED AT (1956cc)",
          "LIMITED (O) AT (1956cc)",
          "LIMITED (O) AT 4*4 (1956cc)",
          "LIMITED PLUS X AT (1956cc)",
          "2.0D LIMITED 6MT (1956cc)",
          "2.0D LIMITED (O) 6MT (1956cc)",
          "2.0D LIMITED PLUS AT (1956cc)",
          "2.0D LIMITED (O) 9AT FWD (1956cc)",
          "2.0D LIMITED (O) 9AT 4X4 (1956cc)",
          "2.0D LIMITED 9AT FWD (1956cc)",
          "2.0D CLUB EDITION 4X2 MT (1956cc)",
          "CLUB EDITION 4X2 MT (1956cc)",
          "UPLAND (1956cc)",
          "X (1956cc)",
          "OVERLAND 9AT FWD (1956cc)",
          "OVERLAND 9AT 4X4 (1956cc)",
          "2.0 LIMITED (O) 4X4 AT DIESEL (1956cc)",
          "2.0 LIMITED (O) 4X2 AT DIESEL (1956cc)",
          "2.0 LIMITED (O) 4X2 MT DIESEL (1956cc)",
          "2.0 LIMITED 4X2 MT DIESEL (1956cc)",
          "2.0 LIMITED 4X2 AT DIESEL (1956cc)",
        ],
      },
      {
        model: "WRANGLER",
        variants: [
          "UNLIMITED 4X4 (2799cc)",
          "UNLIMITED 4X4 (3604cc)",
          "LIMITED JL (AT) (1996cc)",
          "UNLIMITED JL (AT) (1996cc)",
          "JL RUBICON (1996cc)",
          "2.0 UNLIMITED AT BSVI (1998cc)",
          "2.0 RUBICON AT BSVI (1998cc)",
          "UNLIMITED 4X4 (3600cc)",
          "UNLIMITED 4X4 SPORT (3778cc)",
          "UNLIMITED (1998cc)",
          "2.0 RUBICON 4X4 PETROL 5 SEAT (1998cc)",
          "2.0 UNLIMITED 4X4 PETROL 5 SEAT (1998cc)",
          "2.0 4X4 PETROL (1998cc)",
          "RUBICON (1998cc)",
          "2.0 RUBICON (1998cc)",
          "2.0 UNLIMITED (1998cc)",
          "UNLIMITED 2.8 SPORT (2776cc)",
          "UNLIMITED 2.8 CRD TURBO DIESEL 4WD 5 SEAT (2799cc)",
          "UNLIMITED 4X4 DIESEL (2799cc)",
          "UNLIMITED 2.8 (2799cc)",
          "UNLIMITED 3.6 V6 PETROL 4WD 5 SEAT (3604cc)",
          "SPORT 4X4 RHD (3604cc)",
          "UNLIMITED 4X4 PETROL (3604cc)",
          "UNLIMITED SPORTS 4*4 (3778cc)",
          "UNLIMITED 3.6L RUBICON 4DR 4X4 (3600cc)",
          "2.0 PETROL (1999cc)",
          "UNLIMITED 4X4 PETROL (1999cc)",
        ],
      },
      {
        model: "GRAND CHEROKEE",
        variants: [
          "SUMMIT (2987cc)",
          "LIMITED (2987cc)",
          "LIMITED (3604cc)",
          "SRT (6417cc)",
          "LIMITED AT (O) 4X4 (1995cc)",
          "2.0 PETROL LIMITED (O) 4X4 AT (1995cc)",
          "LIMITED (O) 4X4 AT (1995cc)",
          "3.0 SRT (2998cc)",
          "LIMITED DIESEL (2998cc)",
          "SUMMIT PETROL (3604cc)",
          "SUMMIT (3604cc)",
          "LIMITED PETROL (3604cc)",
          "SRT 6.4 HEMI V8 (6417cc)",
          "3.6 SUMMIT PETROL (3600cc)",
          "3.0 LIMITED CC 2987 (2987cc)",
          "6.2 TRACKHAWK (2987cc)",
          "3.0 SUMMIT DIESEL (2987cc)",
          "LIMITED DIESEL (2987cc)",
          "SUMMIT 3.0 (2987cc)",
          "LIMITED BSIV (1956cc)",
          "LIMITED (O) 4X4 AT (1998cc)",
        ],
      },
      {
        model: "RENEGADE",
        variants: ["4X4 (2000cc)", "4X2 (2000cc)"],
      },
    ],
  },
  {
    make: "LAND ROVER",
    models: [
      {
        model: "RANGE ROVER SPORT",
        variants: [
          "3.0 SE (2993cc)",
          "3.0 SE (2995cc)",
          "3.0 HSE (2993cc)",
          "3.0 HSE (2995cc)",
          "5.0 SC AUTOBIOGRAPHY DYNAMIC (4999cc)",
          "5.0 BIOGRAPHY SC V8 (AT) (5000cc)",
          "5.0 SUPERCHARGED V8 5STR (5000cc)",
          "4.4 SDV8 HSE (4367cc)",
          "4.4 SDV8 AUTOBIOGRAPHY DYNAMIC (4367cc)",
          "3.0 S (2993cc)",
          "4.4 HSE (4367cc)",
          "3.0 TDV6 4WD AUTO HSE (2993cc)",
          "5.0 SVR (4999cc)",
          "2.0 S (1997cc)",
          "2.0 SE (1997cc)",
          "2.0 HSE (1997cc)",
          "3.0 HSE DYNAMIC (2993cc)",
          "3.0 SWB AUTOBIOGRAPHY BSVI (2997cc)",
          "3.0 SDV6 HSE (2993cc)",
          "3.0 SDV6 S (2993cc)",
          "3.0 SDV6 SE (2993cc)",
          "4.4 SDV8 HSE DYNAMIC (4367cc)",
          "3.0 TDV6 DYNAMIC (2993cc)",
          "3.0 TDV6 HSE (2993cc)",
          "3.0 TDV6 SE (2993cc)",
          "SX (2720cc)",
          "SUPERCHARGED V8 (4197cc)",
          "TDV8 (3628cc)",
          "3.0 V6 HSE PETROL (2995cc)",
          "3.0 SUPERCHARGED V6 PETROL (2995cc)",
          "3.0 PETROL V6 SE 5 SEATER (2995cc)",
          "V6 HSE (2995cc)",
          "V6 SE (2995cc)",
          "3.0L V6 SE (2995cc)",
          "5.0 AUTOBIOGRAPHY SC V8 (5000cc)",
          "5.0 SUPERCHARGED V8 5 SEATER (5000cc)",
          "SUPERCHARGED V8 PETROL (4197cc)",
          "TDV8 DIESEL (3628cc)",
          "HSC (2720cc)",
          "HSC (2720cc)",
          "HSC (2720cc)",
          "TDV6 2.7 HSC (2720cc)",
          "2.7 (2720cc)",
          "HSC (2993cc)",
          "3.0 TDV6 (2993cc)",
          "3.0 TDV6 (2993cc)",
          "3.0 TDV6 HSC (2993cc)",
          "TDV5 SE (2993cc)",
          "3.0 SDV 6 (2993cc)",
          "3.0 TDV6 DIESEL SE (2993cc)",
          "3.0L V6 SUPERCHARGED PETROL 4WD AUTO 5DOOR SE (2993cc)",
          "3.0L V6 SUPERCHARGED PETROL 4WD AUTO 5DOOR HSE (2993cc)",
          "3.0 L TDV6 DIESEL 4WD AUTO 5 DOOR HSE (2993cc)",
          "3.0 TDV6 DIESEL 4WD AUTO 5 DOOR S CBU (2993cc)",
          "3.0 TDV6 DIESEL 4WD AUTO 5 DOOR HSE DYNAMIC CBU (2993cc)",
          "3.0 TDV6 DIESEL 4WD AUTO 5 DOOR AUTOBIOGRAPHY DYNAMIC CBU (2993cc)",
          "3.0 SDV6  S DIESEL (2993cc)",
          "3.0 SDV6  SE DIESEL (2993cc)",
          "3.0 L TDV6 DIESEL 4WD AUTO 5 DOOR SE (2993cc)",
          "SDV6 HSE (2993cc)",
          "SDV6 S (2993cc)",
          "3.0 V6 DIESEL HSE DYNAMIC SWB (2993cc)",
          "SE 3.0 DIESEL (2993cc)",
          "S 3.0 DIESEL (2993cc)",
          "HSE 3.0 DIESEL (2993cc)",
          "3.0 DIESEL HSE SILVER (2993cc)",
          "HSE 3.0 DIESEL DYNAMIC BLACK (2993cc)",
          "3.0 DIESEL AUTOBIOGRAPHY DYNAMIC (2993cc)",
          "3.0 DIESEL AUTOBIOGRAPHY (2993cc)",
          "3.0 V6 DIESEL AUTOBIOGRAPHY DYNAMIC SWB (2993cc)",
          "HSE 3.0 DIESEL DYNAMIC (2993cc)",
          "SE 3.0 DIESEL DYNAMIC (2993cc)",
          "3.0 DIESEL FIRST EDITION (2993cc)",
          "3.0 TDV6 HSE DIESEL 5 STR (2993cc)",
          "3.0 HSE DYNAMIC DIESEL (2993cc)",
          "3.0 TDV6 SE DIESEL (2993cc)",
          "3.0 TDV6 SE 5 STR (2993cc)",
          "3.0L AUTOBIOGRAPHY DIESEL (2993cc)",
          "3.0L SE DYNAMIC DIESEL (2993cc)",
          "3.0L FIRST EDITION DIESEL (2993cc)",
          "3.0 SDV6  HSE DIESEL (2993cc)",
          "SDV6 SE (2993cc)",
          "3.0 TDV6 HSE DIESEL (2993cc)",
          "V8 (3600cc)",
          "5.0 V8 (5000cc)",
          "5.0 V8 (5000cc)",
          "5.0 SUPERCHARGED V8 (5000cc)",
          "5.0 AUTOBIOGRAPHY SUPERCHARGED V8 (5000cc)",
          "5.0 SUPERCHARGED V8  PETROL 5 SEATER (5000cc)",
          "5.0 V8 SUPERCHARGED (5000cc)",
          "5.0L V8 SC SVR (5000cc)",
          "5.0 AUTOBIOGRAPHY SUPERCHARGED V8  PETROL (5000cc)",
          "5.0 SUPERCHARGED V8 (4398cc)",
          "2.0 SI4 PETROL S (1997cc)",
          "2.0 SI4 PETROL SE (1997cc)",
          "2.0 HSE AT 4WD (1997cc)",
          "SE 2.0 PETROL (1997cc)",
          "HSE 2.0 PETROL (1997cc)",
          "S 2.0 PETROL (1997cc)",
          "2.0 S PETROL (1997cc)",
          "2.0 HSE PETROL (1997cc)",
          "2.0 SE PETROL (1997cc)",
          "4.2 SUPERCHARGED V8 PETROL 5 SEATER (4197cc)",
          "5.0L V8 SUPERCHARGED PETROL 4WD AUTO 5DOOR AUTOBIOGRAPHY DYNAMIC (4997cc)",
          "5.0L V8 SUPERCHARGED PETROL 4WD AUTO 5DOOR SVR (4997cc)",
          "4.4 SDV8 DIESEL 4WD AUTO 5 DOOR HSE CBU (4367cc)",
          "4.4 SDV8 DIESEL 4WD AUTO 5 DOOR HSE DYNAMIC CBU (4367cc)",
          "4.4 SDV8 DIESEL 4WD AUTO 5 DOOR AUTOBIOGRAPHY DYNAMIC CBU (4367cc)",
          "SDV8 HSE (4367cc)",
          "SDV8 AUTOBIOGRAPHY DYNAMIC (4367cc)",
          "SDV8 HSE DYNAMIC (4367cc)",
          "4.4 SDV8 HSE DIESEL (4367cc)",
          "3.0 DIESEL DYNAMIC SE 5 SEATER (2997cc)",
          "5.0 SUPERCHARGED V8 SVR PETROL (4999cc)",
          "V8 SC AUTOBIOGRAPHY (4999cc)",
          "V8 SC SVR (4999cc)",
          "V8 SC AUTOBIOGRAPHY DYNAMIC (4999cc)",
          "4.4 V8 PETROL (4397cc)",
          "3.6 TDV8 DIESEL (3628cc)",
          "3.6 TDV 8 (3628cc)",
          "TDV8 HSE (2700cc)",
        ],
      },
      {
        model: "RANGE ROVER",
        variants: [
          "3.6 V8 (3628cc)",
          "3.6 V8 (3628cc)",
          "3.6 TDV8 VOGUE SE 5STR (3628cc)",
          "4.2 V8 SC AB (4197cc)",
          "4.4 TDV8 VOGUE SE 5STR (4367cc)",
          "4.4 TDV8 VOGUE SE AB 5 (4367cc)",
          "5.0 BIOGRAPHY UL 5 (AT) (5000cc)",
          "5.0 SUPERCHARGED (4999cc)",
          "4.4 SDV8 AUTOBIOGRAPHY LWD (4367cc)",
          "ESTATE (3000cc)",
          "4.4 LWB SV AUTOBIOGRAPHY (4367cc)",
          "5.0 LWB AUTOBIOGRAPHY (4999cc)",
          "3.0 AUTOBIOGRAPHY (2993cc)",
          "3.0 AUTOBIOGRAPHY (2996cc)",
          "3.0 LWB AUTOBIOGRAPHY (2996cc)",
          "3.0 LWB FIFTY (2993cc)",
          "3.0 LWB SV AUTOBIOGRAPHY (2993cc)",
          "3.0 LWB SVAUTOBIOGRAPHY (2995cc)",
          "3.0 LWD WESTMINSTER (2995cc)",
          "3.0 FIFTY (2995cc)",
          "3.0 WESTMINSTER BLACK (2995cc)",
          "3.0 SE (2996cc)",
          "3.0 SE LWB (2996cc)",
          "3.0 SE LWB (2997cc)",
          "3.0 HSE LWB (2996cc)",
          "3.0 HSE LWB (2997cc)",
          "3.0 AUTOBIOGRAPHY LWB (2996cc)",
          "3.0 AUTOBIOGRAPHY LWB (2997cc)",
          "3.0 FIRST EDITION (2996cc)",
          "3.0 FIRST EDITION LWB (2996cc)",
          "4.4 SE LWB (4395cc)",
          "4.4 HSE LWB (4395cc)",
          "4.4 AUTOBIOGRAPHY LWB (4395cc)",
          "4.4 SV AUTOBIOGRAPHY LWB (4367cc)",
          "4.4 SV AUTOBIOGRAPHY LWB (4395cc)",
          "5.0 AUTOBIOGRAPHY (5000cc)",
          "4.4 LWB AUTOBIOGRAPHY (4367cc)",
          "SPORTS TDV8 HSE (2700cc)",
          "3.0 HSE (2996cc)",
          "3.0 DIESEL LWB WESTMINSTER (2993cc)",
          "3.0 DIESEL LWB WESTMINSTER. (2993cc)",
          "SUPERCHARGED V8 (4197cc)",
          "3.6 TDV8 VOGUE SE (3628cc)",
          "3.0 L (2998cc)",
          "4.4 TDV8 VOGUE SE 5 SEATER (4367cc)",
          "5.0 AUTOBIOGRAPHY UL 5 (5000cc)",
          "4.4 TDV8 SE AUTOBIOGRAPHY (4367cc)",
          "3.0 V6 VOGUE (2993cc)",
          "3.0 VOGUE SE PETROL LWB (2996cc)",
          "3.6 TDV8 VOGUE SE 5 SEATER (3628cc)",
          "SUPERCHARGED V8 PETROL (4197cc)",
          "5.0 AUTOBIOGRAPHY 5 SEATER (5000cc)",
          "VOGUE 3.0 SE PETROL (2995cc)",
          "VOGUE 3.0 S PETROL (2995cc)",
          "LWB 3.0 VOGUE (2995cc)",
          "LWB 3.0 VOGUE SE (2995cc)",
          "SWB 3.0 VOGUE (2995cc)",
          "3.0 V6 (2995cc)",
          "3.0 PETROL VOGUE S (2995cc)",
          "3.0 DIESEL LWB (2995cc)",
          "3.0 PETROL VOGUE SE LWB (2995cc)",
          "3.0 PETROL VOGUE LWB (2995cc)",
          "3.0 D VOGUE SWB (2995cc)",
          "3.0 V6 PETROL VOGUE (2995cc)",
          "3.0 SC VOGUE SE LWB (2995cc)",
          "3.0 PETROL VOGUE SE (2995cc)",
          "3.0 AUTOBIOGRAPHY PETROL LWB (2995cc)",
          "3.0 PETROL SV AUTOBIOGRAPHY LWB (2995cc)",
          "3.0 AUTOBIOGRAPHY PETROL (2995cc)",
          "3.0 VOGUE SE LWB PETROL (2995cc)",
          "3.0L V6 S/C PETROL 4WD (2995cc)",
          "VOGUE 3.0 PETROL LWB 5 SEAT (2996cc)",
          "3.0 VOGUE LWB BS6 (2996cc)",
          "3.0 SV AUTOBIOGRAPHY PETROL LWB. (2996cc)",
          "SE-3.0 PETROL (2996cc)",
          "3.0 AUTOBIOGRAPHY PETROL (CC 2996) (2996cc)",
          "3.0 PETROL VOGUE SE LWB (2996cc)",
          "3.0 PETROL VOGUE LWB (2996cc)",
          "3.0 V6 PETROL VOGUE (2996cc)",
          "3.0 PETROL SE (2996cc)",
          "3.0 PETROL SE LWB (2996cc)",
          "3.0 PETROL HSE (2996cc)",
          "3.0 PETROL HSE LWB (2996cc)",
          "3.0 PETROL FIRST EDITION (2996cc)",
          "3.0 PETROL FIRST EDITION LWB (2996cc)",
          "3.0 PETROL SE LWB 7 STR (2996cc)",
          "3.0 DIESEL HSE LWB 7 STR (2996cc)",
          "3.0 PETROL HSE LWB 7 STR (2996cc)",
          "3.0 PETROL AUTOBIOGRAPHY 7 STR (2996cc)",
          "3.0 PHEV 324 KW AUTOBIOGRAPHY (2996cc)",
          "3.0 PHEV 324 KW FIRST EDITION (2996cc)",
          "3.0 PHEV 324 KW HSE (2996cc)",
          "3.0 PHEV 324 KW SE (2996cc)",
          "3.0 PHEV 375 KW AUTOBIOGRAPHY (2996cc)",
          "3.0 PHEV 375 KW FIRST EDITION (2996cc)",
          "3.0 PHEV 375 KW HSE (2996cc)",
          "3.0 PHEV 375 KW SE (2996cc)",
          "3.0 PHEV 375 KW SV (2996cc)",
          "3.0 PHEV LWB AUTOBIOGRAPHY (2996cc)",
          "3.0 PHEV LWB FIRST EDITION (2996cc)",
          "3.0 PHEV LWB HSE (2996cc)",
          "3.0 PHEV LWB SE (2996cc)",
          "3.0 RANGE ROVER FIFTY PETROL LWB. (2996cc)",
          "3.0 RANGE ROVER FIFTY PETROL LWB (2996cc)",
          "3.0 WESTMINSTER BLACK PETROL LWB. (2996cc)",
          "3.0 WESTMINSTER BLACK PETROL LWB (2996cc)",
          "3.0 WESTMINSTER PETROL LWB. (2996cc)",
          "3.0 WESTMINSTER PETROL LWB (2996cc)",
          "3.0 PETROL VOGUE SE (2996cc)",
          "3.0 AUTOBIOGRAPHY PETROL LWB (2996cc)",
          "3.0 PETROL SV AUTOBIOGRAPHY LWB (2996cc)",
          "3.0 AUTOBIOGRAPHY PETROL. (2996cc)",
          "3.0 AUTOBIOGRAPHY PETROL (2996cc)",
          "3.0L FIFTY LWB PETROL (2996cc)",
          "3.0L VOGUE LWB PETROL (2996cc)",
          "3.0L SV AUTOBIOGRAPHY LWB PETROL (2996cc)",
          "3.0L VOGUE PETROL (2996cc)",
          "3.0L AUTOBIOGRAPHY LWB PETROL (2996cc)",
          "3.0L AUTOBIOGRAPHY PETROL (2996cc)",
          "3.0L VOGUE SE PETROL (2996cc)",
          "3.0L HSE LWB PETROL (2996cc)",
          "VOGUE 4.4 TDV8 VOGUE SE DIESEL 5 SEATER (4367cc)",
          "VOGUE 4.4 SDV8 4WD AUTOBIOGRAPHY DIESEL 5 SEATER (4367cc)",
          "VOGUE 4.4 SDV8 SWB SE DIESEL 5 SEATER (4367cc)",
          "VOGUE 4.4 SDV8 LWB SE DIESEL 5 SEATER (4367cc)",
          "VOGUE 4.4 SDV8 LWB AUTOBIOGRAPHY SV DIESEL 5 SEATER (4367cc)",
          "VOGUE 4.4 TDV8 VOGUE SE DIESEL (4367cc)",
          "VOGUE 4.4 SDV6 AUTOBIOGRAPHY (4367cc)",
          "AUTOBIOGRAPHY 4.4L SDV8 DIESEL (4367cc)",
          "AUTOBIOGRAPHY 4.4L SDV8 DIESEL LWB (4367cc)",
          "AUTOBIOGRAPHY 4.4L SDV8 DIESEL LWB BLACK (4367cc)",
          "AUTOBIOGRAPHY 4.4 LR SDV8 DIESEL (4367cc)",
          "4.4 VOGUE SE (4367cc)",
          "4.4 V8 (4367cc)",
          "LWB 4.4 AUTOBIOGRAPHY (4367cc)",
          "LWB 4.4 VOGUE SE (4367cc)",
          "SWB 4.4 AUTOBIOGRAPHY (4367cc)",
          "SWB 4.4 VOGUE SE (4367cc)",
          "4.4 SDV8 AUTOBIOGRAPHY LWB (4367cc)",
          "4.4 SDV8 VOGUE SE LWB (4367cc)",
          "4.4 SDV 8 AUTOBIGRAPHY (4367cc)",
          "4.4 SDV8 AUTOBIOGRAPHY 5 STR (4367cc)",
          "4.4 SDV 8 SV AUTOBIGRAPHY LWB (4367cc)",
          "4.4 SDV8 SV AUTOBIOGRAPHY LWB (4367cc)",
          "4.4 PETROL SE 5 SEATER AT SV LWB (4367cc)",
          "4.4 TDV8 VOGUE SE DIESEL 5 SEATER (4367cc)",
          "4.4 TDV8 VOGUE SE DIESEL AUTOBIOGRAPHY 5 SEATER (4367cc)",
          "4.4 SDV8 VOGUE SE DIESEL 5 SEATER (4367cc)",
          "4.4 TDV8 DIESEL 5 SEATER (4367cc)",
          "4.4 DIESEL SV AUTOBIOGRAPHY LWB (4367cc)",
          "4.4 DIESEL AUTOBIOGRAPHY LWB (4367cc)",
          "4.4 DIESEL VOGUE SE LWB (4367cc)",
          "4.4 TDV8 (4367cc)",
          "3.8D (4367cc)",
          "LWB 4.4 SDV8 SV AUTOBIOGRAPHY (4367cc)",
          "LWB 4.4 SDV8 AUTOBIOGRAPHY (4367cc)",
          "LWB 4.4 SDV8 VOGUE SE (4367cc)",
          "4.4L AUTOBIOGRAPHY (DIESEL) (4367cc)",
          "4.4 TDV8 AUTOBIOGRAPHY (4367cc)",
          "4.4 TDV8 VOGUE SE (4367cc)",
          "4.4 SDV8 AUTOBIOGRAPHY (4367cc)",
          "4.4 SDV8 VOGUE SE (4367cc)",
          "VOGUE 3.0 V6 PETROL 4 WD AT 5 DOOR SE LWB 4 SEAT (2993cc)",
          "VOGUE 3.0 TDV6 SE LWB 5 SEATER (2993cc)",
          "VOGUE 3.0 V6 SC PETROL 4 WD AT 5 DOOR (2993cc)",
          "VOGUE 3.0 TDV6 LWB DIESEL 5 SEATER (2993cc)",
          "VOGUE 3.0 LR TDV6 DIESEL (2993cc)",
          "3.0 V6 DIESEL VOGUE LWB (2993cc)",
          "LWB VOGUE (2993cc)",
          "LWB VOGUE SE (2993cc)",
          "SWB VOGUE (2993cc)",
          "3.0 SDV6 VOGUE (2993cc)",
          "3.0 TDV6 DIESEL VOGUE LWB SE (2993cc)",
          "3.0 TDV6 HSE DIESEL (2993cc)",
          "3.0 TDV6 DIESEL VOGUE LWB SE 4 STR (2993cc)",
          "3.0 SDV6 VOGUE DIESEL (2993cc)",
          "3.0 TDV6 VOGUE (2993cc)",
          "3.0 SE VOGUE DIESEL LWB (2993cc)",
          "3.0 V6 DIESEL VOGUE SUV (2993cc)",
          "3.0 DIESEL VOGUE LWB (2993cc)",
          "3.0 DIESEL VOGUE (2993cc)",
          "3.0 AUTOBIOGRAPHY DIESEL LWB. (2993cc)",
          "3.0 AUTOBIOGRAPHY DIESEL LWB (2993cc)",
          "3.0 DIESEL LWB WESTMINSTER BLACK. (2993cc)",
          "3.0 DIESEL LWB WESTMINSTER BLACK (2993cc)",
          "3.0 DIESEL RANGE ROVER FIFTY LWB. (2993cc)",
          "3.0 DIESEL RANGE ROVER FIFTY LWB (2993cc)",
          "3.0 SV AUTOBIOGRAPHY DIESEL LWB. (2993cc)",
          "3.0 SV AUTOBIOGRAPHY DIESEL LWB (2993cc)",
          "3.0 HSE (2993cc)",
          "LWB 3.0L TDV6 VOGUE (2993cc)",
          "3.0 DIESEL V6 (2993cc)",
          "VOGUE SE 3.0 LWB 4 SEATER (2993cc)",
          "3.0L SV AUTOBIOGRAPHY LWB DIESEL (2993cc)",
          "3.0L WESTMINSTER LWB DIESEL (2993cc)",
          "3.0L RANGE ROVER FIFTY LWB DIESEL (2993cc)",
          "3.0 V6 DIESEL VOGUE (2993cc)",
          "3.0L AUTOBIOGRAPHY LWB DIESEL (2993cc)",
          "3.0L WESTMINSTER BLACK LWB DIESEL (2993cc)",
          "VOGUE 5.0 V8 LWB SUPERCHARED AUTOBIOGRAPHY PETROL 5 SEATER (4999cc)",
          "VOGUE 5.0 V8 LWB SUPERCHARED AUTOBIOGRAPHY SV PETROL 5 SEATER (4999cc)",
          "VOGUE 5.0 V8 SUPERCHARGED AUTOBIOGRAPHY 7 SEATER (4999cc)",
          "VOGUE 5.0 V8 SUPERCHARGED AUTOBIOGRAPHY 5 SEATER (4999cc)",
          "5.0 V8 (4999cc)",
          "LWB 5.0 AUTOBIOGRAPHY (4999cc)",
          "SWB 5.0 AUTOBIOGRAPHY (4999cc)",
          "5.0 V8 SV AUTOBIOGRAPHY LWB (4999cc)",
          "5.8 V8 SV AUTOBIOGRAPHY DYNAMIC (4999cc)",
          "5.0 L SUPERCHARGED (4999cc)",
          "5.0L SUPERCHARGED 5STR (4999cc)",
          "5.0 V8 AUTOBIOGRAPHY (4999cc)",
          "5.0 V8 AUTOBIOGRAPHY LWB 5 STR (4999cc)",
          "5.0L LWB AUTOBIOGRAPHY PETROL (4999cc)",
          "5.0 V8 AUTOBIOGRAPHY LWB (4999cc)",
          "5.0 PETROL SV AUTOBIOGRAPHY DYNAMIC (4999cc)",
          "5.0 AUTOBIOGRAPHY PETROL (4999cc)",
          "5.0 SUPERCHARGED V8 PETROL (4999cc)",
          "5.0 PETROL SV AUTOBIOGRAPHY LWB (4999cc)",
          "LWB 5.0L V8 SUPERCHARGED AUTOBIOGRAPHY 5 SEATER (4999cc)",
          "5.0L SUPERCHARGED AUTOBIOGRAPHY (PETROL) (4999cc)",
          "5.0L LWB AUTOBIOGRAPHY (4999cc)",
          "5.0 V8 SUPERCHARGED AUTOBIOGRAPHY (4999cc)",
          "5.0 V8 SUPERCHARGED AUTOBIOGRAPHY 4 STR (4999cc)",
          "AUTOBIOGRAPHY 5.0L V8 SC PETROL (5000cc)",
          "AUTOBIOGRAPHY 5.0L V8 SC PETROL LWB (5000cc)",
          "AUTOBIOGRAPHY 5.0L V8 SC PETROL LWB BLACK (5000cc)",
          "5.OL (5000cc)",
          "5.0 SUPERCHARGED AUTOBIOGRAPHY (5000cc)",
          "5.0 AUTOBIOGRAPHY ULTIMATE EDITION (5000cc)",
          "5.0 AUTOBIOGRAPHY ULTIMATE EDITION 5 SEATER (5000cc)",
          "5.0 SC AWD 5DR SWB AUTOBIOGRAPHY PETROL (5000cc)",
          "5.0 SUPERCHARGED AUTOBIOGRAPHY 5 SEAT PETROL (5000cc)",
          "LWB 5.0L V8 SUPERCHARGED AUTOBIOGRAPHY (5000cc)",
          "5.0 S C AUTOBIOGRAPHY ULTIMATE EDITION (5000cc)",
          "5.0L SUPERCHARGED AUTOBIOGRAPHY (5000cc)",
          "VOGUE  SE (4400cc)",
          "2.7 V6 (2720cc)",
          "4.2 V8 SUPERCHARGED AUTOBIOGRAPHY (4197cc)",
          "4.2 V8 SUPERCHARGED AUTOBIOGRAPHI (4197cc)",
          "2.7 (3628cc)",
          "3.6L TDV8 VOGUE SE (3628cc)",
          "3.6 TDV8 VOGUE SE DIESEL (3628cc)",
          "TDV8 DIESEL (3628cc)",
          "TDV8 (3628cc)",
          "EVOQUE 2.0 TD4 HSE DYNAMIC EMBER (1999cc)",
          "HSE DYNAMIC PETROL (1999cc)",
          "VELAR (1999cc)",
          "EVOQUE 2.0 SE R DYNAMIC DIESEL (1999cc)",
          "EVOQUE 2.0 S DIESEL (1999cc)",
          "EVOQUE 2.0 HSE DIESEL (1999cc)",
          "EVOQUE HSE PETROL (1999cc)",
          "EVOQUE 2.0 SE DIESEL (1999cc)",
          "EVOQUE 2.0 SE DYNAMIC DIESEL (1999cc)",
          "EVOQUE 2.0 HSE DYNAMIC DIESEL (1999cc)",
          "2.0L SE R DYNAMIC DIESEL 5 STR (1999cc)",
          "2.0L S DIESEL 5 STR (1999cc)",
          "EVOQUE 2.0 LANDMARK EDITION (1999cc)",
          "EVOQUE SE PETROL (1999cc)",
          "3.0 TDV6 DIESEL VOGUE (2999cc)",
          "VELAR (2999cc)",
          "3.0 TD6 (2926cc)",
          "4.4 PETROL (4450cc)",
          "3.0L DIESEL AT LWB AUTOBIOGRAPHY (2997cc)",
          "3.0 D350 D SWB 5DOOR SE (2997cc)",
          "3.0 DIESEL SV LWB 5 DOOR 4 SEATER (2997cc)",
          "3.0 DIESEL LWB AUTOBIOGRAPHY (2997cc)",
          "3.0 DIESEL SE LWB 7 STR (2997cc)",
          "3.0 DIESEL HSE LWB 7 STR (2997cc)",
          "3.0 DIESEL AUTOBIOGRAPHY LWB 7 STR (2997cc)",
          "3.0 DIESEL AUTOBIOGRAPHY (2997cc)",
          "3.0 DIESEL FIRST EDITION (2997cc)",
          "3.0 DIESEL LWB FIRST EDITION (2997cc)",
          "3.0 DIESEL LWB HSE (2997cc)",
          "3.0 DIESEL LWB SE (2997cc)",
          "3.0 DIESEL SE (2997cc)",
          "3.0 DIESEL SV (2997cc)",
          "3.0 DIESEL SV LWB (2997cc)",
          "3.0 DIESEL VOGUE SE LWB (2997cc)",
          "3.0 DIESEL AUTOBIOGRAPHY LWB (2997cc)",
          "3.0L HSE DIESEL (2997cc)",
          "3.0L HSE LWB DIESEL (2997cc)",
          "3.0L HSE LWB DIESEL 7 STR (2997cc)",
          "VOGUE SE 3.0 LWB 5 SEATER (2997cc)",
          "4.4 P 530 LWB AUTOBIOGRAPHY 5 DOOR PETROL 5 SEATER (4395cc)",
          "4.4 PETROL SE LWB 7 STR (4395cc)",
          "4.4 PETROL HSE LWB 7 STR (4395cc)",
          "4.4 PETROL AUTOBIOGRAPHY LWB 7 STR (4395cc)",
          "4.4 PETROL AUTOBIOGRAPHY (4395cc)",
          "4.4 PETROL FIRST EDITION (4395cc)",
          "4.4 PETROL HSE (4395cc)",
          "4.4 PETROL LWB AUTOBIOGRAPHY (4395cc)",
          "4.4 PETROL LWB FIRST EDITION (4395cc)",
          "4.4 PETROL LWB SE (4395cc)",
          "4.4 PETROL SE (4395cc)",
          "4.4 PETROL SV (4395cc)",
          "4.4 PETROL SV LWB (4395cc)",
          "4.4 PETROL LWB HSE (4395cc)",
          "4.4 LWB AUTOBIOGRAPHY (4395cc)",
          "4.4L LWB SV PETROL (4395cc)",
          "4.4L LWB FIRST EDITION PETROL (4395cc)",
          "4.4L AUTOBIOGRAPHY PETROL (4395cc)",
          "MONTEGO (2100cc)",
          "3.8 D (3825cc)",
          "EVOQUE 2.0 SE R DYNAMIC PETROL (1997cc)",
          "2.0L S PETROL 5 STR (1997cc)",
          "2.0L SE R DYNAMIC PETROL 5 STR (1997cc)",
          "VOGUE SE 4.4L (4394cc)",
          "4.4 VX (4394cc)",
          "EVOQUE HSE SD4 (2179cc)",
          "EVOQUE EMBER EDITION (2179cc)",
          "EVOQUE HSE DYNAMIC (2179cc)",
        ],
      },
      {
        model: "RANGE ROVER EVOQUE",
        variants: [
          "CONVERTIBLE HSE DYNAMIC (1999cc)",
          "2.0 TD4 HSE (1999cc)",
          "2.0 TD4 SE (1999cc)",
          "2.0 TD4 HSE DYNAMIC (1999cc)",
          "2.0 DYNAMIC COUPE 4 WD 5STR (1999cc)",
          "2.2 DYNAMIC 4WD 5STR (2179cc)",
          "2.2 PRESTIGE 4 WD 5STR (2179cc)",
          "2.2 PURE 4WD 5STR (2179cc)",
          "2.0 TD4 LANDMARK EDTN (1999cc)",
          "2.2 SD4 PURE (2179cc)",
          "2.2 SD4 PRESTIGE (2179cc)",
          "2.2 SD4 DYNAMIC (2179cc)",
          "ESI 4 (2993cc)",
          "2.0 R DYNAMIC SE BSVI (1997cc)",
          "2.0 DYNAMIC COUPE 4 WD 5 SEAT (1999cc)",
          "2.0 DYNAMIC +COUPE 4 WD 5 ST (1999cc)",
          "2.2 DYNAMIC PLUS 4 WD 5 SEAT (2179cc)",
          "2.2 PRESTIGE 4 WD 5 SEAT (2179cc)",
          "2.2 DIESEL PURE 4 WD 5 SEAT (2179cc)",
          "2.2 DIESEL DYNAMIC 4 WD 5 SEAT (2179cc)",
          "DYNAMIC SD4 5STR (2179cc)",
          "SD4 PRESTIGE (2179cc)",
          "SD4 PURE (2179cc)",
          "2.2 PRESTIGE (2179cc)",
          "2.2 DYNAMIC PLUS (2179cc)",
          "2.2 DYNAMIC (2179cc)",
          "2.2 PURE (2179cc)",
          "2.2 SD4 DIESEL HSE 190 7 SEATER (2179cc)",
          "2.2 SD4 DIESEL PURE 4X4 AT (2179cc)",
          "2.2 SD4 DIESEL HSE 190 (2179cc)",
          "2.2 SD4 DIESEL SE 190 (2179cc)",
          "2.2 HSE DYNAMIC (2179cc)",
          "2.2 DIESEL PRESTIGE 4 WD 5 SEAT (2179cc)",
          "2.2 DIESEL DYNAMIC PLUS 4 WD 5 SEAT (2179cc)",
          "DYNAMIC SD4 CBU (2179cc)",
          "PRESTIGE SD4 CBU (2179cc)",
          "PURE SD4 CBU (2179cc)",
          "HSE (2179cc)",
          "DYNAMIC SD4 (2179cc)",
          "DYNAMIC SD4 (2179cc)",
          "PRESTIGE SD4 (2179cc)",
          "PURE SD4 (2179cc)",
          "SE (2179cc)",
          "2.0 DYNAMIC COUPE (1999cc)",
          "2.0 DYNAMIC PLUS COUPE (1999cc)",
          "2.0 SI4 SE (1999cc)",
          ".0 TD4 SE DYNAMIC (1999cc)",
          "2.0 TD4 PURE (1999cc)",
          "2.0 L D EMBER EDI (1999cc)",
          "2.0L TD4 LANDMARK EDITION (1999cc)",
          "2.0L TD4 SE (1999cc)",
          "2.0 PETROL DYNAMIC COUPE 4 WD 5 SEAT (1999cc)",
          "2.0L DIESEL R DYNAMIC SE 4WD 5 SEATER (1999cc)",
          "2.0L PETROL S 4WD 5 SEATER (1999cc)",
          "2.0L PETROL R DYNAMIC SE 4WD 5 SEATER (1999cc)",
          "2.0L DIESEL S 4WD 5 SEATER (1999cc)",
          "2.0L DIESEL HSE (1999cc)",
          "2.0 TD4 SE 5 SEATER 132 KW (1999cc)",
          "2.0 SI4 PETROL 4WD SE 5 SEATER (1999cc)",
          "2.0 TD4 HSE DYNAMIC EMBER EDITION (1999cc)",
          "2.0 TD4 HSE 5 SEATER 132 KW (1999cc)",
          "2.0 TD4  LANDMARK EDITION (1999cc)",
          "2.0 DIESEL PURE CBU (1999cc)",
          "2.0 DIESEL SE CBU (1999cc)",
          "2.0 DIESEL HSE CBU (1999cc)",
          "2.0 DIESEL HSE DYNAMIC CBU (1999cc)",
          "2.0 DIESEL EMBER EDITION CBU (1999cc)",
          "2.0 DIESEL PURE CKD (1999cc)",
          "2.0 DIESEL SE DYNAMIC CKD (1999cc)",
          "2.0 DIESEL HSE DYNAMIC CKD (1999cc)",
          "2.0 PETROL SE CKD (1999cc)",
          "2.0 TD4 SE DYNAMIC 5 SEATER (1999cc)",
          "2.0 PETROL DYNAMIC SE 4WD 5 SEATER (1999cc)",
          "2.0 PETROL DYNAMIC COUPE 4 WD 4 SEAT (1999cc)",
          "2.0 TD4 DIESEL 4X4 5 DOOR 5 SEATER (1999cc)",
          "2.0L TD4 132 KW DIESEL LANDMARK EDITION (1999cc)",
          "2.0 L PETROL HSE DYNAMIC (1999cc)",
          "2.0 PETROL SE DYNAMIC 5 SEAT (1999cc)",
          "SE TRIM (1999cc)",
          "LANDMARK EDITION (1999cc)",
          "DYNAMIC SI4 COUPE CBU (1999cc)",
          "HSE (1999cc)",
          "SE DYNAMIC (1999cc)",
          "PURE (1999cc)",
          "S DIESEL (1999cc)",
          "SE R-DYNAMIC DIESEL (1999cc)",
          "2.0 PETROL DYNAMIC PLUS COUPE 4 WD 5 SEAT (1999cc)",
          "EMBER EDITION (1999cc)",
          "HSE DYNAMIC PETROL. (1999cc)",
          "HSE DYNAMIC PETROL (1999cc)",
          "DYNAMIC SI4 COUPE (1999cc)",
          "HSE DYNAMIC (1999cc)",
          "SE (1999cc)",
          "HSE 3.0 DIESEL DYNAMIC (2993cc)",
          "2.0L HSE DYNAMIC CONVERTIBLE (1997cc)",
          "2.0 HSE DYNAMIC CONVERTIBLE 4 SEAT (1997cc)",
          "2.0L PETROL R DYNAMIC SE 4WD 5 SEATER CC 1997 (1997cc)",
          "SE R-DYNAMIC PETROL FACELIFT (1997cc)",
          "SE R-DYNAMIC PETROL (1997cc)",
          "SE PETROL (1997cc)",
          "HSE DYNAMIC CONVERTIBLE (1997cc)",
          "S PETROL (1997cc)",
        ],
      },
      {
        model: "DISCOVERY",
        variants: [
          "3.0 TD6 S (2993cc)",
          "3.0 TD6 SE (2993cc)",
          "3.0 TD6 HSE (2993cc)",
          "3.0 TD6 HSE LUXURY (2993cc)",
          "3.0 TD6 FIRST EDITION (2993cc)",
          "3.0 SI6 S (2995cc)",
          "3.0 SI6 SE (2995cc)",
          "3.0 SI6 HSE (2995cc)",
          "3.0 SI6 HSE LUXURY (2995cc)",
          "3.0 SI6 FIRST EDITION (2995cc)",
          "4 TDV6 (2495cc)",
          "4 TDV6 (2500cc)",
          "3.0 SWD SE (2995cc)",
          "FIRST EDITION SI6 (2995cc)",
          "HSE LUXURY SI6 (2995cc)",
          "HSE SI6 (2995cc)",
          "S (2995cc)",
          "SE (2995cc)",
          "S 3.0 SI6. (2995cc)",
          "3.0 SI6 PETROL 4WD AUTO 5 DOOR FIRST EDITION 7 SEAT (2995cc)",
          "3.0 SI6 V6 PETROL 4WD AUTO 5 DOOR S 7 SEAT CBU (2995cc)",
          "3.0 SI6 V6 PETROL 4WD AUTO 5 DOOR SE 7 SEAT CBU (2995cc)",
          "3.0 SI6 V6 PETROL 4WD AUTO 5 DOOR HSE 7 SEAT CBU (2995cc)",
          "3.0 SI6 V6 PETROL 4WD AUTO 5 DOOR HSE LUXURY 7 SEAT CBU (2995cc)",
          "3.0 PETROL HSE LUXURY 7 SEATER (2995cc)",
          "3.0 HSE LUXURY PETROL (2995cc)",
          "3.0 SE PETROL (2995cc)",
          "3.0 HSE FIRST EDITION PETROL (2995cc)",
          "3.0 S PETROL (2995cc)",
          "3.0 HSE PETROL (2995cc)",
          "3.0L HSE LUXURY PETROL 7 SEATER (2995cc)",
          "3.0L FIRST EDITION PETROL (2995cc)",
          "3.0L HSE LUXURY PETROL (2995cc)",
          "3.0L HSE PETROL (2995cc)",
          "3.0L S PETROL (2995cc)",
          "3.0L SE PETROL (2995cc)",
          "3.0L SE PETROL 7 SEATER (2995cc)",
          "3.0 S DIESEL 5 STR (2996cc)",
          "3.0 S PETROL 5 STR (2996cc)",
          "3.0 TDV6 DIESEL SE 7 SEATER (2996cc)",
          "3.0 SE DIESEL (2996cc)",
          "3.0 R-DYNAMIC SE DIESEL (2996cc)",
          "METROPOLITAN EDITION (2996cc)",
          "3.0 S DIESEL (2996cc)",
          "3.0 S PETROL (2996cc)",
          "2.0 PETROL SE 5 DOOR 4WD 7 STR (1997cc)",
          "2.0 L PETROL SE 5 STR (1997cc)",
          "2.0 PETROL HSE 4WD AT 7 SEAT (1997cc)",
          "2.0 PETROL R DYNAMIC HSE 7 SEAT (1997cc)",
          "2.0 S PETROL (1997cc)",
          "2.0 SE PETROL (1997cc)",
          "2.0 HSE PETROL (1997cc)",
          "2.0 HSE LUXURY PETROL (1997cc)",
          "2.0L S PETROL (1997cc)",
          "2.0L HSE LUXURY PETROL (1997cc)",
          "2.0L SE PETROL (1997cc)",
          "2.0L HSE PETROL (1997cc)",
          "2.0L HSE R DYNAMIC PETROL (1997cc)",
          "( 2017) S 3.0 SI6 (2995cc)",
          "( 2017) SE 3.0 SI6 (2995cc)",
          "( 2017) HSE 3.0 SI6 (2995cc)",
          "( 2017) S3.0 TD6 (2993cc)",
          "( 2017) HSE LUXURY 3.0 SI6 (2995cc)",
          "( 2017) FIRST EDITION 3.0 SI6 (2995cc)",
          "( 2017) SE 3.0 TD6 (2993cc)",
          "( 2017) HSE 3.0 TD6 (2993cc)",
          "( 2017) HSE LUXURY 3.0 TD6 (2993cc)",
          "( 2017) FIRST EDITION 3.0 TD6 (2993cc)",
          "V8I (4000cc)",
          "3.0 L. (2993cc)",
          "SE (2993cc)",
          "FIRST EDITION TD6 (2993cc)",
          "HSE LUXURY TD6 (2993cc)",
          "HSE TD6 (2993cc)",
          "S TD6 (2993cc)",
          "SE TD6 (2993cc)",
          "HSE (2993cc)",
          "3.0L TDV6 HSE DIESEL (2993cc)",
          "3.0L TDV6 SE DIESEL (2993cc)",
          "3.0 DIESEL HSE 5 DOOR 4WD (2993cc)",
          "3.0 TD6 DIESEL 4WD AUTO 5 DOOR FIRST EDITION 7 SEAT (2993cc)",
          "3.0 L HSE LUXURY 7 SEATER (2993cc)",
          "3.0 SDV6 LANDMARK (2993cc)",
          "3.0 SDV6 SE (2993cc)",
          "3.0 SE DIESEL (2993cc)",
          "3.0 HSE FIRST EDITION DIESEL (2993cc)",
          "3.0 HSE LUXURY DIESEL (2993cc)",
          "3.0 S DIESEL (2993cc)",
          "3.0 HSE DIESEL (2993cc)",
          "3.0L FIRST EDITION DIESEL (2993cc)",
          "3.0L SE DIESEL (2993cc)",
          "3.0L HSE LUXURY DIESEL (2993cc)",
          "3.0L HSE DIESEL (2993cc)",
          "3.0L S DIESEL (2993cc)",
          "2.5 (2179cc)",
          "SPORT TD4 HSE (2179cc)",
          "2.5 DIESEL 6 SEAT (2179cc)",
          "3.0 SE PETROL 5 STR (2998cc)",
          "3.0 R-DYNAMIC HSE DIESEL (2998cc)",
          "3.0 R-DYNAMIC S DIESEL (2998cc)",
          "3.0 R-DYNAMIC S PETROL (2998cc)",
          "3.0 R-DYNAMIC SE PETROL (2998cc)",
          "METROPOLITAN EDITION PETROL (2998cc)",
          "3.0 SE PETROL (2998cc)",
          "3.0 HSE PETROL (2998cc)",
          "3.0 HSE DIESEL (2998cc)",
          "2.0 DIESEL HSE 7 SEAT (1999cc)",
          "2.0 S DIESEL (1999cc)",
          "2.0 SE DIESEL (1999cc)",
          "2.0 HSE LUXURY DIESEL (1999cc)",
          "2.0 HSE DIESEL (1999cc)",
          "2.0 HSE LUXURY 4WD 7 STR (1999cc)",
          "2.0 HSE 4WD (1999cc)",
          "2.0 SE 4WD (1999cc)",
          "2.0 HSE LUXURY 4WD (1999cc)",
          "2.0 S 4WD (1999cc)",
          "2.0 PETROL (1998cc)",
          "2.0 R-DYNAMIC HSE PETROL (1998cc)",
          "2.0 R-DYNAMIC S PETROL (1998cc)",
          "2.0 R-DYNAMIC SE PETROL (1998cc)",
          "2.0 S PETROL (1998cc)",
          "2.0 SE PETROL (1998cc)",
          "2.0 HSE PETROL (1998cc)",
        ],
      },
      {
        model: "DISCOVERY 4",
        variants: [
          "3.0 SDV6 HSC 7STR (AT) (2993cc)",
          "3.0 L TDV6 (AT) (2993cc)",
          "3.0 L TDV6 HSC (AT) (2993cc)",
          "3.0L TDV6 SE (2993cc)",
          "3.0 L SDV6 HSC AT () 7 SEATER (2993cc)",
          "3.0 L TDV6 HSC AUTO (DIESEL) (2993cc)",
          "3.0 L TDV6 AUTO (DIESEL) (2993cc)",
          "SDV6 HSC AT (2993cc)",
          "TDV6 AT (2993cc)",
          "TDV6 HSC AT (2993cc)",
          "3.0L TDV6 SE 5 STR (2993cc)",
          "3.0 L HSE 7 SEATER (2993cc)",
          "3.0 L SDV6 HSC AT (DIESEL) 7 SEATER (2993cc)",
          "3.0 L TDV6 AUTO DIESEL 5 SEATER (2993cc)",
          "3.0 TDV6 HSE (2993cc)",
          "3.0L SDV6 SE (2993cc)",
          "3.0L SDV6 SE WITH SUNROOF (2993cc)",
          "3.0 L TDV6 DIESEL (2993cc)",
          "2.7 BASE (2720cc)",
        ],
      },
      {
        model: "FREELANDER 2",
        variants: [
          "2.2D S (2179cc)",
          "2.2D HSE (2179cc)",
          "2.2D SD4 HSE (2179cc)",
          "2.2D SE (2179cc)",
          "2.2 SE (2179cc)",
          "2.2 SD4 HSE (2179cc)",
          "2.2 L (2179cc)",
          "2.2 TD4 (2179cc)",
          "SE TD4 (2179cc)",
          "STERLING EDITION (2179cc)",
          "S BE TD4 (2179cc)",
          "HSE SD4 (2179cc)",
          "2.2 SD4 AUTOMATIC (2179cc)",
          "S (2179cc)",
          "HSE SD4 (2197cc)",
          "TD4 AUTOMATIC SE (DIESEL) (2720cc)",
        ],
      },
      {
        model: "DISCOVERY 3",
        variants: [
          "TD V6 GS 7STR (2720cc)",
          "TD V6 HSE 7STR (2720cc)",
          "3.0 L HSE 7STR (2995cc)",
          "TD V6 GS 7 SEATER (2720cc)",
          "TD V6 HSE 7 SEATER (2720cc)",
          "TD V6 HSE (2720cc)",
          "TD V6 GS (2720cc)",
          "TDV6 DIESEL AUTOMATIC (2720cc)",
          "V6 (2419cc)",
        ],
      },
      {
        model: "DISCOVERY SPORT",
        variants: [
          "HSE 7STR (2179cc)",
          "HSE LUXURY 7STR (2179cc)",
          "HSE 5STR (2179cc)",
          "2.0 HSE 5STR (1999cc)",
          "2.0 HSE LUXURY (1999cc)",
          "2.0 SE (1999cc)",
          "2.0 HSE PETROL (1999cc)",
          "2.0 PURE (1999cc)",
          "2.0 HSE (1999cc)",
          "2.0 SE PETROL (1999cc)",
          "2.0 LANDMARK EDITION (1999cc)",
          "2.0 LTR S (1999cc)",
          "3.6 TDV8 (3628cc)",
          "2.0 R DYNAMIC SE (1999cc)",
          "2.0 L HSE (1999cc)",
          "SE 7STR (2179cc)",
          "S (2179cc)",
          "SE (2179cc)",
          "HSE LUXURY (2179cc)",
          "HSE (2179cc)",
          "HSE PETROL (1999cc)",
          "PURE (1999cc)",
          "HSE LUXURY 7-SEATER (2179cc)",
          "HSE 7-SEATER (2179cc)",
          "SE 7-SEATER (2179cc)",
          "SI4 HSE (1999cc)",
          "SPORT (1999cc)",
          "LANDMARK EDITION 7STR (1999cc)",
          "2.0L HSE LUXURY 7 STR (1999cc)",
          "2.0L SE 7 STR (1999cc)",
          "LE (1999cc)",
          "2.0 DIESEL R-DYNAMIC SE 7 SEATER (1999cc)",
          "2.0 DIESEL 5 DOOR  S 7 SEATER (1999cc)",
          "2.0 D 5 SEATER LANDMARK EDITION (1999cc)",
          "2.0 D 7 SEATER LANDMARK EDITION (1999cc)",
          "2.0 SI4 HSE PETROL 240 PS 7 SEATER (1999cc)",
          "2.0 TD4 HSE DIESEL 7 SEAT (1999cc)",
          "2.0 TD4 SE 5 DOOR 4 WD DIESEL 7 SEAT (1999cc)",
          "2.0 D HSE 5 DOOR 150 PS 5 SEAT (1999cc)",
          "2.0 TD4 HSE LUXURY DIESEL 7 SEATER (1999cc)",
          "2.0 SI4 PETROL 7 SEATER (1999cc)",
          "2.0 D HSE 4WD 5 DOOR 150 PS 7 SEAT (1999cc)",
          "2.0 D HSE 5 DOOR 180 PS 5 SEAT (1999cc)",
          "2.0 D HSE 5 DOOR 180 PS 7 SEAT (1999cc)",
          "2.0 DIESEL HSE LUXURY CKD (1999cc)",
          "2.0 PETROL HSE CKD 7 SEAT (1999cc)",
          "2.0 D PURE 4WD 5 DOOR  150 PS 5 SEAT (1999cc)",
          "2.0 D SE 5 DOOR 150 PS 5 SEAT (1999cc)",
          "2.0 SI4 HSE PETROL 5 SEATER (1999cc)",
          "2.0 TD4 HSE 4WD AUTOMATIIC DIESEL 7 SEAT (1999cc)",
          "2.0 D 5 SEATER HSE LUXURY CKD (1999cc)",
          "HSE LUXURY 5-SEATER (1999cc)",
          "SE 5-SEATER (1999cc)",
          "SE PETROL (1999cc)",
          "R-DYNAMIC SE FACELIFT (1999cc)",
          "R-DYNAMIC SE PETROL FACELIFT (1999cc)",
          "R-DYNAMIC SE (1999cc)",
          "R-DYNAMIC SE PETROL (1999cc)",
          "HSE 5-SEATER (1999cc)",
          "LANDMARK EDITION (1999cc)",
          "S PETROL (1999cc)",
          "HSE PETROL 7-SEATER (1999cc)",
          "SE 7 STR (1999cc)",
          "2.0L R DYNAMIC SE DIESEL (1999cc)",
          "2.0L S DIESEL (1999cc)",
          "2.0L HSE LUXURY DIESEL 7 SEATER (1999cc)",
          "2.0L SI4 PETROL HSE 5 SEATER (1999cc)",
          "2.0L HSE DIESEL 7 SEATER (1999cc)",
          "2.0L LANDMARK EDITION (1999cc)",
          "2.0L SE DIESEL 5 SEATER (1999cc)",
          "2.0L HSE LUXURY DIESEL 5 SEATER. (1999cc)",
          "2.0L PURE DIESEL 5 SEATER (1999cc)",
          "2.0L SE DIESEL 7 SEATER (1999cc)",
          "2.0L S PETROL 7 STR (1999cc)",
          "2.0L SE R DYNAMIC PETROL 7 STR (1999cc)",
          "S (1999cc)",
          "SE (1999cc)",
          "HSE LUXURY (1999cc)",
          "HSE (1999cc)",
          "TD4 HSE 7S (2179cc)",
          "SD4 HSE LUXURY (2179cc)",
          "SD4 HSE LUXURY 7S (2179cc)",
          "TD4 S (2179cc)",
          "TD4 SE (2179cc)",
          "TD4 SE 7S (2179cc)",
          "D4 HSE 5 S (2179cc)",
          "2.2L SD4 HSE LUXURY 5STR (2179cc)",
          "S DIESEL (2179cc)",
          "2.2 TD4 HSE DIESEL 7 SEAT (2179cc)",
          "TD4 D 7 SEATER SE (2179cc)",
          "TD4 D 7 SEATER HSE (2179cc)",
          "SD4 D 7 SEATER HSE LUXURY (2179cc)",
          "TD4 D 5 SEATER S (2179cc)",
          "TD4 D 5 SEATER SE (2179cc)",
          "TD4 D 5 SEATER HSE (2179cc)",
          "SD4 D 5 SEATER HSE LUXURY (2179cc)",
          "2.2L TD4 (2179cc)",
          "2.2 HSE 5 ST (2179cc)",
          "2.2 HSE 5 ST GTDI (2179cc)",
          "NEW DISCOVERY SPORTS HSE LUXURY 2.2 SD4 D (7-SEATER) (2179cc)",
          "3.0 TDV6 (2993cc)",
          "2.0 PETROL R-DYNAMIC SE 7 SEATER (1997cc)",
          "SE PETROL (1997cc)",
          "HSE PETROL 7-SEATER (1997cc)",
          "2.0L SE PETROL 7 SEATER (1997cc)",
          "2.0L SE PETROL 5 SEATER (1997cc)",
          "2.0L HSE PETROL 7 SEATER (1997cc)",
          "2.2 L TD4 (2494cc)",
          "2.0L HSE LUXURY DIESEL 5 SEATER (1988cc)",
        ],
      },
      {
        model: "DEFENDER",
        variants: [
          "2.5 (2500cc)",
          "127 (2996cc)",
          "2.0 L FIRST EDITION (1997cc)",
          "3.0 DIESEL 110 X (2996cc)",
          "3.0 DIESEL 90 X (2996cc)",
          "3.0 DIESEL 90 SE (2996cc)",
          "3.0 L X DYNAMIC (2995cc)",
          "5.0 110 V8 (4997cc)",
          "2.0 PETROL 110 HSE (1997cc)",
          "3.0 DIESEL 110 HSE (2996cc)",
          "110 P300 X DYNAMIC SE (2995cc)",
          "110 D300 X (2996cc)",
          "90 D300 X (2996cc)",
          "90 P300 (1997cc)",
          "90 P300 SE (1997cc)",
          "110 P300 (1997cc)",
          "90 P400 SE (2995cc)",
          "90 D300 SE (2996cc)",
          "110 P400 HSE (2995cc)",
          "110 D300 SE (2996cc)",
          "110 D300 X-DYNAMIC HSE 7 STR (2996cc)",
          "110 P400 X (2996cc)",
          "3.0L PETROL (130) HSE (2996cc)",
          "3.0L PETROL (130) X (2996cc)",
          "90 D300 HSE (2996cc)",
          "110 D300 HSE (2996cc)",
          "90 D300 X-DYNAMIC HSE (2996cc)",
          "110 D300 X-DYNAMIC HSE (2996cc)",
          "3.0L 110 HSE PETROL 5 STR (2996cc)",
          "3.0L 110 X DYNAMIC HSE DIESEL (2996cc)",
          "3.0L 90 X DYNAMIC HSE DIESEL (2996cc)",
          "3.0L 110 SE DIESEL (2996cc)",
          "3.0L 90 HSE DIESEL (2996cc)",
          "3.0L 90 SE DIESEL (2996cc)",
          "3.0L 110 HSE DIESEL (2996cc)",
          "3.0L 90 X DIESEL (2996cc)",
          "3.0L 110 X DIESEL (2996cc)",
          "90 P300 FIRST EDITION 6 STR (1997cc)",
          "90 (1997cc)",
          "90 S (1997cc)",
          "90 SE (1997cc)",
          "90 FIRST EDITION (1997cc)",
          "2.0L PETROL (110) HSE (1997cc)",
          "2.0L PETROL (110) SE (1997cc)",
          "2.0L PETROL (110) X-DYNAMIC HSE (1997cc)",
          "2.0L PETROL (90) HSE (1997cc)",
          "2.0L PETROL (90) X-DYNAMIC HSE (1997cc)",
          "110 (1997cc)",
          "110 FIRST EDITION. (1997cc)",
          "110 FIRST EDITION (1997cc)",
          "110 HSE. (1997cc)",
          "110 HSE (1997cc)",
          "90 HSE. (1997cc)",
          "90 HSE (1997cc)",
          "110 S (1997cc)",
          "110 SE (1997cc)",
          "90 P300 S (1997cc)",
          "90 P300 X DYNAMIC S (1997cc)",
          "90 P300 X-DYNAMIC S (1997cc)",
          "90 P300 X DYNAMIC SE (1997cc)",
          "90 P300 X-DYNAMIC SE (1997cc)",
          "110 P300 S (1997cc)",
          "90 P300 FIRST EDITION (1997cc)",
          "110 P300 X-DYNAMIC S (1997cc)",
          "110 P300 SE (1997cc)",
          "110 P300 FIRST EDITION (1997cc)",
          "110 P300 HSE (1997cc)",
          "110 P300 X-DYNAMIC HSE (1997cc)",
          "90 P300 HSE (1997cc)",
          "110 P300 X-DYNAMIC SE (1997cc)",
          "2.0L 110 HSE PETROL 5 STR (1997cc)",
          "2.0L 110 SE PETROL 5 STR (1997cc)",
          "2.0L 110 FIRST EDITION PETROL 5 STR (1997cc)",
          "2.0L 110 X DYNAMIC SE PETROL (1997cc)",
          "2.0L 110 PETROL 5 STR (1997cc)",
          "2.0L 90 X DYNAMIC S PETROL (1997cc)",
          "2.0L 90 X DYNAMIC SE PETROL (1997cc)",
          "2.0L 110 X DYNAMIC S PETROL (1997cc)",
          "2.0L 110 X DYNAMIC HSE PETROL (1997cc)",
          "2.0L 90 S PETROL (1997cc)",
          "2.0L 90 FIRST EDITION PETROL (1997cc)",
          "2.0L 110 PETROL (1997cc)",
          "2.0L 110 FIRST EDITION PETROL (1997cc)",
          "2.0L 110 SE PETROL (1997cc)",
          "2.0L 90 HSE PETROL (1997cc)",
          "2.0L 110 S PETROL 5 STR (1997cc)",
          "2.0L 90 PETROL (1997cc)",
          "2.0L 110 S PETROL (1997cc)",
          "2.0L 110 HSE PETROL (1997cc)",
          "2.0L 90 SE PETROL (1997cc)",
          "5.0 P (4999cc)",
          "5.0L 110 CARPATHIAN PETROL (4999cc)",
          "5.0L 90 V8 PETROL (4999cc)",
          "5.0L 90 CARPATHIAN PETROL (4999cc)",
          "5.0L 110 V8 PETROL (4999cc)",
          "130 HSE 3.0 DIESEL (2997cc)",
          "3.0L DIESEL (130) HSE (2997cc)",
          "3.0L DIESEL (130) X (2997cc)",
          "3.0L PETROL (110) X (2997cc)",
          "3.0L PETROL (90) X (2997cc)",
          "3.0 DIESEL 110 HSE (2997cc)",
          "110 D300 X (2997cc)",
          "90 D300 X (2997cc)",
          "L 663 (2000cc)",
          "L663 (1999cc)",
          "90 P400 X-DYNAMIC HSE (2995cc)",
          "90 P400 XS EDITION (2995cc)",
          "90 P400 X-DYNAMIC SE (2995cc)",
          "90 P400 HSE (2995cc)",
          "90 P400 FIRST EDITION (2995cc)",
          "110 P400 SE (2995cc)",
          "110 P400 X-DYNAMIC SE (2995cc)",
          "110 P400 FIRST EDITION (2995cc)",
          "3.0L 110 X DYNAMIC SE PETROL (2995cc)",
          "3.0L 110 FIRST EDITION PETROL (2995cc)",
          "3.0L 90 SE PETROL (2995cc)",
          "3.0L 90 X DYNAMIC HSE PETROL (2995cc)",
          "3.0L 90 FIRST EDITION PETROL 6 STR (2995cc)",
          "3.0L 110 SE PETROL (2995cc)",
          "3.0L 90 HSE PETROL (2995cc)",
          "3.0L 90 X DYNAMIC SE PETROL (2995cc)",
          "3.0L 110 HSE PETROL (2995cc)",
          "3.0L 110 X PETROL (2995cc)",
          "2.0 PETROL HSE (1998cc)",
          "2.0 PETROL HSE 7 STR (1998cc)",
          "2.0L PETROL PHEV (110) 75TH EDITION (1998cc)",
          "2.0L PETROL PHEV (110) X (1998cc)",
          "2.0L PETROL PHEV (110) X-DYNAMIC HSE (1998cc)",
          "3.0L DIESEL (110) 75TH EDITION (2998cc)",
          "3.0L DIESEL (130) HSE (2998cc)",
          "3.0L DIESEL (130) X (2998cc)",
          "3.0L DIESEL (90) 75TH EDITION (2998cc)",
          "3.0L PETROL (110) 75TH EDITION (2998cc)",
          "3.0L PETROL (130) HSE (2998cc)",
          "3.0L PETROL (130) X (2998cc)",
          "3.0L PETROL (90) 75TH EDITION (2998cc)",
          "5.0L PETROL (110) CARPATHIAN (4997cc)",
          "5.0L PETROL (110) V8 (4997cc)",
          "5.0L PETROL (90) CARPATHIAN (4997cc)",
          "5.0L PETROL (90) V8 (4997cc)",
          "90 P300 HSE (1197cc)",
          "110 P300 X-DYNAMIC SE (1197cc)",
          "110 2.5 L PETROL (2500cc)",
        ],
      },
      {
        model: "RANGE ROVER VELAR",
        variants: [
          "2.0 P250 (1997cc)",
          "2.0 P250 S (1997cc)",
          "2.0 P250 SE (1997cc)",
          "2.0 P250 HSE (1997cc)",
          "2.0 D180 (1999cc)",
          "2.0 D180 S (1999cc)",
          "2.0 D180 SE (1999cc)",
          "2.0 D180 HSE (1999cc)",
          "3.0 D300 (2993cc)",
          "3.0 D300 S (2993cc)",
          "3.0 D300 SE (2993cc)",
          "3.0 D300 HSE (2993cc)",
          "2.0 D180 R DYNAMIC (1999cc)",
          "2.0 D180 R DYNAMIC S (1999cc)",
          "2.0 D180 R DYNAMIC SE (1999cc)",
          "2.0 D180 R DYNAMIC HSE (1999cc)",
          "2.0 P250 R DYNAMIC (1997cc)",
          "2.0 P250 R DYNAMIC S (1997cc)",
          "2.0 P250 R DYNAMIC SE (1997cc)",
          "2.0 P250 R DYNAMIC HSE (1997cc)",
          "3.0 D300 R DYNAMIC (2993cc)",
          "3.0 D300 R DYNAMIC S (2993cc)",
          "3.0 D300 R DYNAMIC SE (2993cc)",
          "3.0 D300 R DYNAMIC HSE (2993cc)",
          "3.0 D300 R FIRST EDITION (2993cc)",
          "2.0 R DYNAMIC S E 250 (1997cc)",
          "2.0 D180 R DYNAMIC S (MY19) (1999cc)",
          "2.0 P250 R DYNAMIC S (MY19) (1997cc)",
          "2.0 LT (1999cc)",
          "P 250 SE (1997cc)",
          "P 250 S (1997cc)",
          "P 250 (1997cc)",
          "P 250 HSE (1997cc)",
          "P 250 R DYNAMIC (1997cc)",
          "P 250 R DYNAMIC HSE (1997cc)",
          "P 250 R DYNAMIC S (1997cc)",
          "P 250 R DYNAMIC SE (1997cc)",
          "2.0 PETROL R DYNAMIC S 5 DOOR (1997cc)",
          "2.0 R-DYNAMIC HSE PETROL 250 (1997cc)",
          "2.0 PETROL 250 (1997cc)",
          "2.0 HSE PETROL 250 (1997cc)",
          "2.0 R-DYNAMIC PETROL 250 (1997cc)",
          "2.0 S PETROL 250... (1997cc)",
          "2.0 S PETROL 250 (1997cc)",
          "2.0 R-DYNAMIC S PETROL 250 (1997cc)",
          "2.0 R-DYNAMIC SE PETROL 250 (1997cc)",
          "2.0 SE PETROL 250 (1997cc)",
          "2.0L R-DYNAMIC PETROL 250PS (1997cc)",
          "2.0L R-DYNAMIC SE PETROL 250PS (1997cc)",
          "2.0L SE PETROL 250PS (1997cc)",
          "2.0L R-DYNAMIC HSE PETROL 250PS (1997cc)",
          "2.0L S PETROL 250PS (1997cc)",
          "2.0L R-DYNAMIC S PETROL 250PS (1997cc)",
          "2.0L PETROL 250PS (1997cc)",
          "2.0L S R DYNAMIC PETROL (1997cc)",
          "2.0L HSE PETROL 250PS (1997cc)",
          "D 180 (1999cc)",
          "D 180 HSE (1999cc)",
          "D 180 R DYNAMIC (1999cc)",
          "D 180 R DYNAMIC HSE (1999cc)",
          "D 180 R DYNAMIC S (1999cc)",
          "D 180 R DYNAMIC SE (1999cc)",
          "D 180 S (1999cc)",
          "D 180 SE (1999cc)",
          "2.0 HSE DIESEL 180 (1999cc)",
          "2.0 SE DIESEL 180 (1999cc)",
          "2.0 R-DYNAMIC HSE DIESEL 180 (1999cc)",
          "2.0 S DIESEL 180 (1999cc)",
          "2.0 R-DYNAMIC DIESEL 180... (1999cc)",
          "2.0 R-DYNAMIC DIESEL 180 (1999cc)",
          "2.0 DIESEL 180 (1999cc)",
          "2.0 R-DYNAMIC S DIESEL 180 (1999cc)",
          "2.0 R-DYNAMIC SE DIESEL 180 (1999cc)",
          "2.0L R-DYNAMIC S DIESEL (1999cc)",
          "2.0L S DIESEL 180PS (1999cc)",
          "2.0L DIESEL 180PS (1999cc)",
          "2.0L R-DYNAMIC DIESEL 180PS (1999cc)",
          "2.0L R-DYNAMIC S DIESEL 180PS (1999cc)",
          "2.0L HSE DIESEL 180PS (1999cc)",
          "2.0L R-DYNAMIC SE DIESEL 180PS (1999cc)",
          "2.0L R-DYNAMIC HSE DIESEL 180PS (1999cc)",
          "2.0L SE DIESEL 180PS (1999cc)",
          "D 300 (2993cc)",
          "D 300 HSE (2993cc)",
          "D 300 R DYNAMIC HSE (2993cc)",
          "D 300 SE (2993cc)",
          "D 300 FIRST EDITION. (2993cc)",
          "D 300 R DYNAMIC. (2993cc)",
          "D 300 R DYNAMIC S. (2993cc)",
          "D 300 R DYNAMIC SE. (2993cc)",
          "D 300 S. (2993cc)",
          "3.0 SE DIESEL 300 (2993cc)",
          "3.0 DIESEL 300 (2993cc)",
          "3.0 I DIESEL (D300) FIRST EDITION (2993cc)",
          "3.0 R-DYNAMIC DIESEL 300 (2993cc)",
          "3.0 R-DYNAMIC S DIESEL 300 (2993cc)",
          "3.0 R-DYNAMIC SE DIESEL 300 (2993cc)",
          "3.0 S DIESEL 300 (2993cc)",
          "3.0 R-DYNAMIC HSE DIESEL 300... (2993cc)",
          "3.0 R-DYNAMIC HSE DIESEL 300 (2993cc)",
          "3.0 HSE DIESEL 300 (2993cc)",
          "3.0L S DIESEL 300PS (2993cc)",
          "3.0L R-DYNAMIC SE DIESEL 300PS (2993cc)",
          "3.0L R-DYNAMIC HSE DIESEL 300PS (2993cc)",
          "3.0L DIESEL 300PS (2993cc)",
          "3.0L HSE DIESEL 300PS (2993cc)",
          "3.0L FIRST EDITION DIESEL (2993cc)",
          "3.0L SE DIESEL 300PS (2993cc)",
          "3.0L R-DYNAMIC DIESEL 300PS (2993cc)",
          "3.0L R-DYNAMIC S DIESEL 300PS (2993cc)",
          "2.0 DIESEL R DYNAMIC SE 5 DOOR (1998cc)",
        ],
      },
      {
        model: "RANGE ROVER VOUGE",
        variants: [
          "4.4 SV AUTOBIOGRAPHY (4367cc)",
          "3.0 TDV6 (2993cc)",
          "3.0 TDV6 LWD (2993cc)",
          "4.4 SDV8 LWD SE (4367cc)",
          "3.0 V6 SUPERCHARGED (2995cc)",
          "3.0 V6 SUPERCHARGED SE (2995cc)",
          "5.0 V8 SUPERCHARGED (4999cc)",
          "3.0 SE LWB DIESEL (2993cc)",
          "3.0 SWB (2995cc)",
        ],
      },
      {
        model: "VINTAGE",
        variants: [
          "LAND ROVER (1500cc)",
          "LAND ROVER (1600cc)",
          "LAND ROVE (1000cc)",
          "DISCOVERY (2993cc)",
          "RANGE ROVER (3500cc)",
          "RANGE ROVER (2000cc)",
        ],
      },
      {
        model: "VELAR",
        variants: [
          "D 180 SE (1999cc)",
          "D 180 (1999cc)",
          "D 180 R DYNAMIC (1999cc)",
          "D 180 S (1999cc)",
          "D 180 R DYNAMIC S (1999cc)",
          "D 180 R DYNAMIC SE (1999cc)",
          "D 180 HSE (1999cc)",
          "D 180 R DYNAMIC HSE (1999cc)",
          "D 300 (2993cc)",
          "D 300 R DYNAMIC (2993cc)",
          "D 300 S (2993cc)",
          "D 300 R DYNAMIC S (2993cc)",
          "D 300 SE (2993cc)",
          "D 300 R DYNAMIC SE (2993cc)",
          "D 300 HSE (2993cc)",
          "D 300 R DYNAMIC HSE (2993cc)",
          "D 300 FIRST EDITION (2993cc)",
          "P 250 (1997cc)",
          "P 250 R DYNAMIC (1997cc)",
          "P 250 S (1997cc)",
          "P 250 R DYNAMIC S (1997cc)",
          "P 250 SE (1997cc)",
          "P 250 R DYNAMIC SE (1997cc)",
          "P 250 HSE (1997cc)",
          "P 250 R DYNAMIC SHE (1997cc)",
        ],
      },
      {
        model: "DFENDER",
        variants: [
          "110 (2500cc)",
          "110 2.5 L DIESEL (2500cc)",
          "2.0 PETROL 110 FIRST EDITION 7 SEATER (1997cc)",
          "2.0 PETROL 110 HSE 7 SEATER (1997cc)",
          "2.0 PETROL 110 BASE 7 SEATER (1997cc)",
          "2.0 PETROL 110 S 7 SEATER (1997cc)",
          "2.0 PETROL 110 SE 7 SEATER (1997cc)",
          "2.0 PETROL 110 4WD 5 DOOR S 5 SEAT (1997cc)",
          "2.0 PETROL 110 BASE 5 SEATER (1997cc)",
          "2.0L PETROL 110 SE 4WD AUTO 5 DOOR Â 5 SEATR BSVI (1997cc)",
          "2.0 PETROL 110 X DYNAMIC HSE 5 SEAT (1997cc)",
          "2.0 PETROL 110 HSE 5 SEATER (1997cc)",
          "2.0 PETROL 110 S 5 SEATER (1997cc)",
          "2.0 PETROL 90  4WD AUTO 3 DOOR HSE 5 SEATER (1997cc)",
          "3.0 DIESEL X 4WD AUTO 5 DOOR (2996cc)",
          "3.0 PETROL P400 X (2996cc)",
          "3.0 DIESEL 110 SE SE 4WD AUTO 5 DOOR 7 SEATER (2996cc)",
          "3.0 DIESEL 110 HSE 4WD AUTO 5 DOOR 5 SEATER (2996cc)",
          "3.0 PETROL SE 110 5 SEATER (2995cc)",
          "3.0 DIESEL SE 110 5 SEATER (2995cc)",
          "3.0 PETROL 110 FIRST EDITION  4WD 5 DOOR 7 SEAT (2995cc)",
          "3.0 PETROL 110 FIRST EDITION (2995cc)",
          "3.0 PETROL HSE 110 5 DOOR 5 SEAT (2995cc)",
          "3.0 DIESEL 130 X LWB 8 SEATER (2997cc)",
          "3.0 DIESEL X DYNAMIC HSE 7 SEATER (2997cc)",
          "3.0 PETROL 130 X LWB 8 SEATER (2997cc)",
          "3.0 DIESEL 130 HSE LWB 8 SEATER (2997cc)",
          "3.0 PETROL 130 HSE LWB 8 SEATER (2997cc)",
          "130 TDS (2495cc)",
          "5.0L 110 PETROL 525PS Â V8 CARPATHAIN EDITION (5000cc)",
        ],
      },
      {
        model: "FREELANDER",
        variants: [
          "1.8 L (1800cc)",
          "2.0 L (2000cc)",
          "3.2 I6 HSE AT (2179cc)",
          "2 HSE (2179cc)",
          "2 HSE (2179cc)",
          "2 SE (2179cc)",
          "2 SE (2179cc)",
          "2 3.2 I6 HSE AT (3192cc)",
          "1.8 (1796cc)",
        ],
      },
      {
        model: "LAND ROVER DEFENDER",
        variants: ["0 (3498cc)"],
      },
      {
        model: "LAND ROVER DISCOVERY",
        variants: ["0 (2720cc)"],
      },
    ],
  },
  {
    make: "MARUTI SUZUKI",
    models: [
      {
        model: "800",
        variants: [
          "EX 5 SPEED (796cc)",
          "EX 5 SPEED (796cc)",
          "DUO AC (796cc)",
          "STANDARD (796cc)",
          "YE3 DP2 (796cc)",
          "STD EURO I (796cc)",
          "AC E3 IMMOB (796cc)",
          "AC BHARATH 111 (1400cc)",
          "DUO STANDARD (796cc)",
          "AC (796cc)",
          "AC (796cc)",
          "UNIQ (796cc)",
          "UNIQ (796cc)",
          "DX (796cc)",
          "DX (796cc)",
          "MPI DX (796cc)",
          "YE3 DP2 CNG (796cc)",
          "AC 4 SPEED (796cc)",
          "AC 5 SPEED (796cc)",
          "EX 4 SPEED (796cc)",
          "STD (800cc)",
          "DX 4STR (796cc)",
          "AC 4STR (796cc)",
          "STANDARD 5 SEAT LPG (796cc)",
          "STANDARD 5 SEAT (796cc)",
          "MPI (796cc)",
          "STD 5 STR (796cc)",
          "AC 5 STR (796cc)",
          "AC UNIQ 5STR (796cc)",
          "DUO AC LPG (796cc)",
          "DUO AC LPG (796cc)",
          "DUO STD LPG (796cc)",
          "DUO STD LPG (796cc)",
          "AC BS-III (796cc)",
          "AC - BS III (796cc)",
          "AC UNIQ (796cc)",
          "AC UNIQ (796cc)",
          "STD BS-III (796cc)",
          "AC BS-II (796cc)",
          "DX BS-II (796cc)",
          "DX BS-II (796cc)",
          "EX BS-II (796cc)",
          "EX BS-II (796cc)",
          "STD BS-II (796cc)",
          "STD MPFI (796cc)",
          "STD MPFI (796cc)",
          "LPG AC (796cc)",
          "STANDARD LPG (796cc)",
          "EX (796cc)",
          "EX (796cc)",
          "STD. (796cc)",
          "STD (796cc)",
          "LPG BS III AC 4 STR (796cc)",
          "MSTD LPG (796cc)",
          "MA/C LPG (796cc)",
          "MA/C LPG (M) (796cc)",
          "LPG STD 4 STR (796cc)",
          "MSTD LPG (M) (796cc)",
          "DX MPI (796cc)",
          "DLX (796cc)",
          "MPI STD (WITH IMMOBILISER) (M) (796cc)",
          "800.0 STD (796cc)",
          "MPI AC (WITH IMMOBILISER) (M) (796cc)",
          "MPI AC (WITH IMMOBILISER) (796cc)",
          "UNIQ (4 STR) (796cc)",
          "MPI STD (WITH IMMOBILISER) (796cc)",
          "DX 5 SPEED (800cc)",
          "DX 5 SPEED (800cc)",
        ],
      },
      {
        model: "1000",
        variants: [
          "STANDARD (1000cc)",
          "STD (970cc)",
          "STD (970cc)",
          "AC (970cc)",
          "AC (970cc)",
          "STANDARD (970cc)",
          "STD (1000cc)",
        ],
      },
      {
        model: "4461",
        variants: ["0 (57cc)"],
      },
      {
        model: "WAGON R",
        variants: [
          "VX (998cc)",
          "VX (998cc)",
          "LXI LPG (998cc)",
          "LXI LPG (998cc)",
          "VXI BS-III (1061cc)",
          "VXI BS-III (1061cc)",
          "LXI O (998cc)",
          "LXI O (998cc)",
          "VXI O (998cc)",
          "VXI O (998cc)",
          "VXI BS-II (1061cc)",
          "VXI BS-II (1061cc)",
          "GREEN LXI(O) (998cc)",
          "AX (1061cc)",
          "AX (1061cc)",
          "AX (AT) (1061cc)",
          "VXI (K-SERIES 1.0) (AT) (998cc)",
          "VXI (ABS) (998cc)",
          "VXI (AMT) (O) (998cc)",
          "VXI AGS (998cc)",
          "VXI AGS (O) (998cc)",
          "STINGRAY VXI AGS (998cc)",
          "STINGRAY VXI AGS (O) (998cc)",
          "MINOR VXI+ AGS (O) (998cc)",
          "WAGON-R MINOR VXI (O) AGS (998cc)",
          "LX LPG (SE) (1086cc)",
          "VXI (K-SERIES 1.0) (MT) (998cc)",
          "VXI KML EDITION (998cc)",
          "DUO LXI BSIII (1061cc)",
          "1.0 LXI 5MT BSVI (998cc)",
          "1.0 VXI 5MT BSVI (998cc)",
          "1.2 ZXI 5MT BSVI (1197cc)",
          "1.2 ZXI PLUS 5MT BSVI (1197cc)",
          "1.2 ZXI AGS 5MT BSVI (1197cc)",
          "LXI (SE) (1086cc)",
          "DUO LXI (K-SERIES 1.0) (998cc)",
          "LXI (K-SERIES 1.0) (O) (998cc)",
          "VXI (K-SERIES 1.0) (AT) (O) (998cc)",
          "MINOR VXI+(AMT) (998cc)",
          "VX (SE) (1086cc)",
          "VXI BSIV (998cc)",
          "LXI NEW (SE) (1086cc)",
          "LXI BS3 (1061cc)",
          "DUO LXI BSIV (998cc)",
          "GREEN LXI (SE) (1086cc)",
          "LX BSIV (998cc)",
          "VX BSIV (998cc)",
          "LXI BSIII IMMO (1061cc)",
          "1.0 LXI CNG 5MT BSVI (998cc)",
          "1.0 VXI CNG 5MT BSVI (998cc)",
          "1.2 ZXI PLUS AGS BSVI (1197cc)",
          "VXI AMT (998cc)",
          "LIMITED EDITION - PREMIA (1061cc)",
          "LX (K-SERIES 1.0) (998cc)",
          "LXI (K-SERIES 1.0) (998cc)",
          "STINGRAY LXI (O) (998cc)",
          "STINGRAY VXI (998cc)",
          "1.0 VXI AGS BSVI (998cc)",
          "LXI BS-IV (998cc)",
          "STINGRAY LXI (998cc)",
          "VX (1061cc)",
          "VX (1061cc)",
          "VXI. (998cc)",
          "VXI (998cc)",
          "VXI (1061cc)",
          "VXI (1061cc)",
          "LX (1061cc)",
          "LX (1061cc)",
          "LXI. (998cc)",
          "LXI (998cc)",
          "LXI (1061cc)",
          "LXI (1061cc)",
          "MINOR LXI(O) (998cc)",
          "MINOR LXI (998cc)",
          "MINOR VXI(O) (998cc)",
          "LXI ABS (998cc)",
          "MINOR LPG (1061cc)",
          "1.2 VXI AGS (O) (1197cc)",
          "1.2 ZXI AGS (1197cc)",
          "1.2 ZXI (1197cc)",
          "1.2 VXI (1197cc)",
          "1.2 VXI (O) (1197cc)",
          "1.2 VXI AGS (1197cc)",
          "DUO LXI (1061cc)",
          "MINOR VXI (998cc)",
          "MINOR VXI AGS (998cc)",
          "GREEN LXI (998cc)",
          "LXI LPG (1061cc)",
          "1.0 LX (998cc)",
          "1.0 LXI (998cc)",
          "1.0 LXI CNG (998cc)",
          "1.0 LXI LPG (998cc)",
          "1.0 VXI (998cc)",
          "1.0 VXI ABS AIRBAG (998cc)",
          "1.0 LXI ABS (998cc)",
          "1.0 VXI AMT (998cc)",
          "1.0 LXI CNG O (998cc)",
          "1.0 VXI ABS (998cc)",
          "1.0 VXI + O (998cc)",
          "1.0 VXI AMT O (998cc)",
          "1.0 LXI AVANCE CNG EDITION (998cc)",
          "1.0 LXI AVANCE EDITION PETROL (998cc)",
          "1.0 DUO LXI LPG (998cc)",
          "LXI O CNG (998cc)",
          "BIG NEW K10LXI (998cc)",
          "BIG NEW K10 VXI (998cc)",
          "BIG NEW K10 VXI (O) (998cc)",
          "BIG NEW K10 VXI (O) AGS (998cc)",
          "BIG NEW K10 VXI AGS (998cc)",
          "LXI CNG (O) (K-SERIES 1.0) (998cc)",
          "BIG NEW K10 LXI (O) (998cc)",
          "VXI PLUS (O) (K-SERIES 1.0) (998cc)",
          "BIG NEW K10 VXI CNG (998cc)",
          "VXI PLUS (O) AMT (K-SERIES 1.0) (998cc)",
          "BIG NEW K10 LXI CNG (998cc)",
          "BIG NEW K10 VXI (O) CNG (998cc)",
          "VXI (O) (K-SERIES 1.0) (998cc)",
          "LXI AVANCE EDITION (998cc)",
          "LXI OPTION (998cc)",
          "VXI OPTION AMT (998cc)",
          "VXI OPTION (998cc)",
          "VXI PLUS AMT (998cc)",
          "VXI PLUS OPTION AMT (998cc)",
          "VXI PLUS OPTIONAL (998cc)",
          "STINGRAY VXI AMT OPTION (998cc)",
          "STINGRAY VXI OPTION (998cc)",
          "STINGRAY LXI OPTION (998cc)",
          "LXI DUO BS IV (998cc)",
          "VXI BS4 (998cc)",
          "LXI 1.0 CNG BS6 (998cc)",
          "VXI 1.0 CNG BS6 (998cc)",
          "VXI 1.0 BS6 (998cc)",
          "LXI 1.0 BS6 (998cc)",
          "GREEN LXI BS4 (998cc)",
          "VXI CNG (998cc)",
          "VXI (O) AMT (998cc)",
          "VXI PLUS (998cc)",
          "LXI (O) 1.0 BSVI (998cc)",
          "TOUR H3 CNG (998cc)",
          "TOUR H3 CNG (998cc)",
          "LXI 1.0 (998cc)",
          "LXI 1.0 (998cc)",
          "LXI 1.0 (O) (998cc)",
          "VXI 1.0 (998cc)",
          "VXI 1.0 (998cc)",
          "VXI 1.0 AMT (998cc)",
          "VXI 1.0 AMT (998cc)",
          "VXI 1.0 (O) (998cc)",
          "LXI 1.0 CNG (998cc)",
          "LXI 1.0 CNG. (998cc)",
          "LXI 1.0 (O) CNG (998cc)",
          "LXI 1.0 BS-VI (998cc)",
          "LXI 1.0 BS-VI (998cc)",
          "LXI 1.0 (O) BS-VI (998cc)",
          "LXI 1.0 (O) BS-VI (998cc)",
          "VXI 1.0 BS-VI (998cc)",
          "VXI 1.0 BS-VI (998cc)",
          "VXI 1.0 AMT BS-VI (998cc)",
          "VXI 1.0 AMT BS-VI (998cc)",
          "VXI 1.0 (O) BS-VI (998cc)",
          "VXI 1.0 (O) BS-VI (998cc)",
          "LXI 1.0 CNG BS-VI (998cc)",
          "LXI 1.0 CNG BS-VI (998cc)",
          "LXI 1.0 (O) CNG BS-VI (998cc)",
          "LXI 1.0 (O) CNG BS-VI (998cc)",
          "VXI 1.0 CNG (998cc)",
          "VXI 1.0 CNG (998cc)",
          "LXI 1.0 CNG BS-IV (998cc)",
          "LXI 1.0L CNG BS-VI (998cc)",
          "VXI 1.0 BSIV (998cc)",
          "VXI CNG 1L 5MT (998cc)",
          "VXI AGS 1.0L (998cc)",
          "LXI CNG TOUR (998cc)",
          "LXI CNG SE (998cc)",
          "AVANCE LXI (998cc)",
          "STINGRAY VXI AMT (998cc)",
          "VXI (K-SERIES 1.0) (998cc)",
          "VXI (K-SERIES 1.0) WITH ABS (998cc)",
          "LXI CNG (K-SERIES 1.0) (998cc)",
          "DUO LPG LXI (K-SERIES 1.0) (998cc)",
          "STINGRAY VXI (O) (998cc)",
          "STINGRAY VXI AMT (O) (998cc)",
          "LXI (O) (K-SERIES 1.0) (998cc)",
          "VXI AMT (K-SERIES 1.0) (998cc)",
          "VXI AMT (O) (K-SERIES 1.0) (998cc)",
          "LX. (998cc)",
          "LX (998cc)",
          "DUO (998cc)",
          "MINOR VXI PLUS AGS (998cc)",
          "MINOR VXI (O) AGS (998cc)",
          "MINOR VXI PLUS AGS (O) (998cc)",
          "MINOR GREEN LXI (998cc)",
          "MINOR GREEN LXI (O) (998cc)",
          "MINOR VXI PLUS (998cc)",
          "LXI LPG 5 STR (998cc)",
          "MINOR VXI PLUS (O) (998cc)",
          "VXI PLUS (O) (998cc)",
          "MINOR LXI 4 STR (998cc)",
          "1.0 LXI (O) (998cc)",
          "1.0 VXI CNG (998cc)",
          "LXI CNG (O) (998cc)",
          "AVANCE LXI CNG (998cc)",
          "LXI CNG (998cc)",
          "DUO LXI (998cc)",
          "LXI LPG (K-SERIES 1.0) (998cc)",
          "LXI 4STR (1061cc)",
          "LXI 4 STR (1061cc)",
          "LXI 4 SEATER (1061cc)",
          "LX 4 SEATER (1061cc)",
          "LXI LPG 4 SEATER (1061cc)",
          "AX BS-II (1061cc)",
          "AX BS-II (1061cc)",
          "LXI CNG 5 STR (1061cc)",
          "DUO LXI LPG (1061cc)",
          "DUO LXI LPG (1061cc)",
          "DUO LXI 4 STR (1061cc)",
          "LX BS-II (1061cc)",
          "LX BS-II (1061cc)",
          "LXI BS-II (1061cc)",
          "LXI BS-II (1061cc)",
          "LXI BS-III 4 STR (1061cc)",
          "AX MINOR (1061cc)",
          "AX MINOR (1061cc)",
          "LX MINOR (1061cc)",
          "LX MINOR (1061cc)",
          "LXI MINOR (1061cc)",
          "LXI MINOR (1061cc)",
          "VXI MINOR (1061cc)",
          "VXI MINOR (1061cc)",
          "VXI WITH ABS MINOR (1061cc)",
          "VXI WITH ABS MINOR (1061cc)",
          "DUO LX LPG (1061cc)",
          "DUO LX LPG (1061cc)",
          "AX BS-III (1061cc)",
          "AX BS-III (1061cc)",
          "LX BS-III (1061cc)",
          "LX BS-III (1061cc)",
          "LXI BS-III (1061cc)",
          "LXI BS-III (1061cc)",
          "LXI DUO (1061cc)",
          "LXI E -LLL (1061cc)",
          "VXI ABS (1061cc)",
          "LXI E3 (1061cc)",
          "AX (AUTOMATIC TRANSMISSION) (1061cc)",
          "DUO (1061cc)",
          "DUO (1061cc)",
          "VXI MINOR-06 (1061cc)",
          "VXI WITH ABS MINOR-06 (1061cc)",
          "LXI LPG (1061 CC) (1061cc)",
          "VXI WITH ABS - BS III (1061cc)",
          "LXI MINOR-06 (1061cc)",
          "LX MINOR-06 (1061cc)",
          "AX MINOR-06 (1061cc)",
          "LXI DUO (WITH IMMOBILISER) (M) (1061cc)",
          "LX DUO (WITH IMMOBILISER) (M) (1061cc)",
          "LX DUO (WITH IMMOBILISER) (1061cc)",
          "VX 4 STR (1061cc)",
          "AX 4 STR (1061cc)",
          "VXI 4 STR (1061cc)",
          "LX 4STR (1061cc)",
          "BIG NEW K12 VXI (1197cc)",
          "BIG NEW K12 VXI (O) (1197cc)",
          "BIG NEW K12 VXI (O) AGS (1197cc)",
          "BIG NEW K12 VXI AGS (1197cc)",
          "BIG NEW K12 ZXI AGS (1197cc)",
          "BIG NEW K12 ZXI (1197cc)",
          "BIG NEW K12 ZXI PLUS (1197cc)",
          "1.2 ZXI PLUS DUAL TONE AGS (1197cc)",
          "ZXI 1.2 BS6 (1197cc)",
          "ZXI PLUS 1.2 AGS BS6 (1197cc)",
          "ZXI PLUS 1.2 AGS DUAL TONE BS6 (1197cc)",
          "ZXI PLUS 1.2 BS6 (1197cc)",
          "ZXI PLUS 1.2L AGS (1197cc)",
          "VXI 1.2 (1197cc)",
          "VXI 1.2 (1197cc)",
          "VXI AMT 1.2 (1197cc)",
          "VXI AMT O 1.2 (1197cc)",
          "VXI O 1.2 (1197cc)",
          "ZXI AMT 1.2 (1197cc)",
          "ZXI 1.2 (1197cc)",
          "ZXI 1.2 (1197cc)",
          "VXI 1.2 AMT (1197cc)",
          "VXI 1.2 (O) (1197cc)",
          "VXI 1.2 AMT (O) (1197cc)",
          "ZXI 1.2 AMT (1197cc)",
          "ZXI 1.2 AMT (1197cc)",
          "VXI 1.0 AMT (O) (1197cc)",
          "VXI 1.0 AMT (O) BS-VI (1197cc)",
          "VXI 1.0 AMT (O) BS-VI (1197cc)",
          "VXI 1.2 BS-VI (1197cc)",
          "VXI 1.2 BS-VI (1197cc)",
          "VXI 1.2 AMT BS-VI (1197cc)",
          "VXI 1.2 AMT BS-VI (1197cc)",
          "VXI 1.2 (O) BS-VI (1197cc)",
          "VXI 1.2 (O) BS-VI (1197cc)",
          "VXI 1.2 AMT (O) BS-VI (1197cc)",
          "VXI 1.2 AMT (O) BS-VI (1197cc)",
          "ZXI 1.2 BS-VI (1197cc)",
          "ZXI 1.2 BS-VI (1197cc)",
          "ZXI 1.2 AMT BS-VI (1197cc)",
          "ZXI 1.2 AMT BS-VI (1197cc)",
          "ZXI PLUS 1.2 (1197cc)",
          "ZXI PLUS 1.2 (1197cc)",
          "ZXI PLUS 1.2 DUAL TONE (1197cc)",
          "ZXI PLUS 1.2 DUAL TONE (1197cc)",
          "ZXI PLUS 1.2 AGS (1197cc)",
          "ZXI PLUS 1.2 AGS (1197cc)",
          "ZXI PLUS 1.2 AGS DUAL TONE (1197cc)",
          "ZXI PLUS 1.2 AGS DUAL TONE (1197cc)",
          "VXI 1.2L (1197cc)",
          "VXI 1.2L AGS (1197cc)",
          "ZXI PLUS AGS (1197cc)",
          "ZXI 1.2L BS IV (1197cc)",
          "1.2 ZXI PLUS AGS (1197cc)",
          "1.2 ZXI PLUS (1197cc)",
          "1.2 ZXI PLUS AGS DUAL TONE (1197cc)",
          "1.2 ZXI PLUS DUAL TONE (1197cc)",
          "PREMIA (1061cc)",
          "PRIMEA (1061cc)",
          "PRIMEA (1061cc)",
          "DUO LPG LXI (1061cc)",
          "PREMIA LIMITED EDITION (1061cc)",
          "DUO LX (1061cc)",
          "LX LPG (1061cc)",
          "LXI O CNG (998cc)",
          "PRIDE (998cc)",
          "PRIDE (998cc)",
          "PRIMEA (998cc)",
          "PRIMEA (998cc)",
          "TOUR H3 PETROL (998cc)",
          "TOUR H3 PETROL (998cc)",
          "1.2L ZXI MT (998cc)",
          "AX (998cc)",
          "PRO (998cc)",
          "TOUR H3 (998cc)",
          "LXI-996CC (996cc)",
          "1.0 ANNIVERSARY (1498cc)",
          "PRIDE (1061cc)",
          "PRIDE (1061cc)",
          "MINOR LPG (5 SEATER) (1061cc)",
          "CBU 5 (SEATER) (658cc)",
          "CBU 658 (658cc)",
          "CBU 660 (660cc)",
        ],
      },
      {
        model: "ZEN ESTILO",
        variants: [
          "LX (1061cc)",
          "LX (1061cc)",
          "VXI (1061cc)",
          "VXI (1061cc)",
          "LXI (1061cc)",
          "LXI (1061cc)",
          "LXI (998cc)",
          "SPORTS (1100cc)",
          "LX BS-III (998cc)",
          "LX BS-IV (998cc)",
          "LX BS-IV (998cc)",
          "LXI BS-IV (998cc)",
          "LXI BS-IV (998cc)",
          "VXI BS-IV (998cc)",
          "VXI BS-IV (998cc)",
          "LXI CNG BS-IV (998cc)",
          "LXI CNG BS-IV (998cc)",
          "VXI ABS BS-IV (998cc)",
          "VXI ABS BS-IV (998cc)",
          "LX (998cc)",
          "LX (998cc)",
          "VXI (998cc)",
          "VXI (998cc)",
          "LXI CNG (998cc)",
          "VXI CNG 4 STR (998cc)",
          "GREEN LX BSIV (998cc)",
          "1.0 LXI (998cc)",
          "1.0 LX (998cc)",
          "1.0 VXI (998cc)",
          "1.0 VXI ABS (998cc)",
          "LXI 4 STR (998cc)",
          "GREEN LXI (M) (998cc)",
          "GREEN LXI (998cc)",
          "VXI CNG (998cc)",
          "VXI BS III (1061cc)",
          "LXI BSIII (1061cc)",
          "VXI ABS (1061cc)",
          "VXI ABS (1061cc)",
          "SPORTS (1061cc)",
          "SPORTS (1061cc)",
          "CNG (1061cc)",
          "1.2 VXI CNG (1061cc)",
          "SPORTS. (993cc)",
          "VXI (1100cc)",
        ],
      },
      {
        model: "EECO",
        variants: [
          "7STR STD (1196cc)",
          "TOUR V AC (1196cc)",
          "TOUR V 5 STR AC CNG BSVI (1196cc)",
          "1.2L 5MT 5 STR AC O BSVI (1196cc)",
          "1.2L 5MT 5 STR STD BSVI (1196cc)",
          "1.2L 5MT 7 STR STD BSVI (1196cc)",
          "1.2L 5MT 5 STR AC CNG BSVI (1196cc)",
          "1.2L 5MT 5 STR AC BSVI (1196cc)",
          "7 STR WITH A/C+HTR (1196cc)",
          "5 STR AC (1196cc)",
          "5 STR AC (1196cc)",
          "5STR STD (1196cc)",
          "5 STR (1196cc)",
          "5 STR (1196cc)",
          "5 STR WITH AC + HTR (1196cc)",
          "5 STR WITH AC + HTR (1196cc)",
          "5 STR BSVI  STD (O) (1196cc)",
          "7 STR BS VI (1196cc)",
          "5 STR BSVI AC (O) (1196cc)",
          "7 STR STD (O) (1196cc)",
          "5 STR STD (O) (1196cc)",
          "TOUR V 5 STR STD (1196cc)",
          "TOUR V 5 STR AC CNG (1196cc)",
          "TOUR V 5 STR AC CNG (O) (1196cc)",
          "TOUR V 5 STR AC (O) (1196cc)",
          "TOUR V 7 STR STD (O) (1196cc)",
          "5 STR WITH A/C CNG (1196cc)",
          "FLEXI 5 SEATER (1196cc)",
          "FLEXI GREEN 5 SEATER (1196cc)",
          "8 STR (1196cc)",
          "GREEN 5 SEATER AC HTR (1196cc)",
          "5 SEATER (1196cc)",
          "7 SEATER (1196cc)",
          "EECO FLEXI GREEN (1196cc)",
          "EECO (1196cc)",
          "5 SEATER WITH A/C (1196cc)",
          "TOUR V 5 SEATER AC CNG (1196cc)",
          "TOUR V 7 STR (1196cc)",
          "TOUR V 7 SEATER (1196cc)",
          "CARGO (1196cc)",
          "GREEN 5 SEATER WITH AC (1196cc)",
          "GREEN 5 SEATER WITH AC (1196cc)",
          "TOUR V STD 5 SEATER (1196cc)",
          "TOUR V STD 5 SEATER (1196cc)",
          "TOUR V STD 7 SEATER (1196cc)",
          "TOUR V STD 7 SEATER (1196cc)",
          "TOUR V STD 5 SEATER AC (1196cc)",
          "TOUR V STD 5 SEATER AC (1196cc)",
          "TOUR V STD 5 SEATER AC WITH CNG (1196cc)",
          "TOUR V STD 5 SEATER AC WITH CNG (1196cc)",
          "5 STR AC CNG (1196cc)",
          "5 STR AC CNG (1196cc)",
          "5 STR AC CNG O. (1196cc)",
          "5 STR AC CNG O (1196cc)",
          "5 STR AC O. (1196cc)",
          "5 STR AC O (1196cc)",
          "5 STR O (1196cc)",
          "5 STR O (1196cc)",
          "7 STR O (1196cc)",
          "7 STR O (1196cc)",
          "5 SEATER - AC (1196cc)",
          "5 SEATER STD (1196cc)",
          "5 STR AC WITH CNG (1196cc)",
          "5 STR STD WITH CNG (1196cc)",
          "7 SEATER STD (1196cc)",
          "8 SEATER/SCH VAN (1196cc)",
          "9 SEATER/SCH VAN (1196cc)",
          "11 SEATER/SCH VAN (1196cc)",
          "13 SEATER/SCH VAN (1196cc)",
          "14 SEATER/SCH VAN (1196cc)",
          "TOUR V AC GREEN (1196cc)",
          "TOUR V (1196cc)",
          "TOUR V CNG (1196cc)",
          "1.2L TOUR V 5 STR STD BSVI (1196cc)",
          "1.2L TOUR V 7 STR STD BSVI (1196cc)",
          "1.2L TOUR V 5 STR AC BSVI (1196cc)",
          "1.2L TOUR V 5 STR AC CNG BSVI (1196cc)",
          "1.2L TOUR V 5 STR STD (O) BSVI (1196cc)",
          "1.2L TOUR V 7 STR STD (O) BSVI (1196cc)",
          "7 STR CNG (1196cc)",
          "5 STR CNG (1196cc)",
          "AC 5STR (1196cc)",
          "7 STR (1196cc)",
          "7 STR (1196cc)",
          "5 STR WITH A/C+HTR CNG (1196cc)",
          "5 STR WITH A/C+HTR CNG (1196cc)",
          "5 STR WITH A/C (1196cc)",
          "5 STR WITH A/C (1196cc)",
          "FLEXI GREEN (1196cc)",
          "FLEXI GREEN (1196cc)",
          "5 STR WITH HTR CNG (1196cc)",
          "5 STR WITH HTR CNG (1196cc)",
          "5 STR AC BS-VI (1196cc)",
          "STD (O) 7 STR (1196cc)",
          "TOUR V 7 STR STD (1196cc)",
          "EECO CNG WITH HTR 5STR (M) (1196cc)",
          "7 STR WITHOUT A/C WITH CNG (1196cc)",
          "7 STR A/C CNG (1196cc)",
          "7 STR WITH A/C (10 STR) (1196cc)",
          "TOUR V 5 STR AC GREEN (1196cc)",
          "7STR WITH AC (1196cc)",
          "EECO CNG 5STR WITH A/C HTR (M) (1196cc)",
          "CARE (1196cc)",
          "7 STR WITHOUT A/C (10 STR) (1196cc)",
          "EECO FLEXI GREEN (M) (1196cc)",
          "EECO CNG WITH HTR 5 STR (1196cc)",
          "AC (O) CNG 5 STR (1196cc)",
          "STD (O) 5 STR (1196cc)",
          "AC (O) 5 STR (1196cc)",
          "TOUR V 5 STR AC (1196cc)",
          "AC CNG 5 STR (1197cc)",
          "5STR (998cc)",
          "7 STR W/O AC (1196cc)",
          "DIESEL 5 SEATR WITH AC (792cc)",
          "5STR WITH AC (792cc)",
        ],
      },
      {
        model: "SWIFT",
        variants: [
          "VXI ABS (1298cc)",
          "VXI ABS (1298cc)",
          "LXI O (1197cc)",
          "LXI O (1197cc)",
          "VXI AMT (1197cc)",
          "VXI AMT (1197cc)",
          "ZXI AMT (1197cc)",
          "ZXI AMT (1197cc)",
          "VXI O (1197cc)",
          "VXI O (1197cc)",
          "VDI ABS (1248cc)",
          "VDI ABS (1248cc)",
          "LDI O (1248cc)",
          "LDI O (1248cc)",
          "VDI O (1248cc)",
          "VDI (O) (1248cc)",
          "ZDI AMT (1248cc)",
          "ZDI AMT (1248cc)",
          "VDI AMT (1248cc)",
          "VDI AMT (1248cc)",
          "VXI CNG BSVI (1197cc)",
          "LXI BSVI (1197cc)",
          "VXI BSVI (1197cc)",
          "ZXI BSVI (1197cc)",
          "VXI GLAM (1197cc)",
          "DLX 1.2 (1197cc)",
          "1.3 DIESEL (1248cc)",
          "ZXI BSIII (1298cc)",
          "VXI AMT BSVI (1197cc)",
          "ZXI AMT BSVI (1197cc)",
          "ZXI CNG BSVI (1197cc)",
          "ZXI ABS (1298cc)",
          "VDI AGS (1248cc)",
          "KB VXI (O) (1197cc)",
          "KB LXI (1197cc)",
          "KB VXI (1197cc)",
          "KB VXI ABS (1197cc)",
          "KB ZXI ABS (1197cc)",
          "KB VXI (ONE MILLION) (1197cc)",
          "KB LXI (O) (1197cc)",
          "VXI ONE MILLION (1197cc)",
          "VXI AGS (1197cc)",
          "LDI (1248cc)",
          "LDI (1248cc)",
          "VDI (1248cc)",
          "VDI (1248cc)",
          "VDI (1298cc)",
          "ZDI (1248cc)",
          "ZDI (1248cc)",
          "GLAM (1298cc)",
          "GLAM (1298cc)",
          "VXI (1298cc)",
          "VXI (1298cc)",
          "VXI. (1197cc)",
          "VXI (1197cc)",
          "LXI. (1197cc)",
          "LXI (1197cc)",
          "LXI (1298cc)",
          "LXI (1298cc)",
          "ZXI. (1197cc)",
          "ZXI (1197cc)",
          "DECA VXI (1197cc)",
          "DECA VDI (1248cc)",
          "VXI ABS (1061cc)",
          "2018 LDI (1248cc)",
          "2018 VDI AMT (1248cc)",
          "2018 VDI (1248cc)",
          "2018 ZDI AMT (1248cc)",
          "2018 ZDI (1248cc)",
          "2018 ZDI PLUS (1248cc)",
          "ZDI PLUS AMT (1248cc)",
          "NEW ZDI PLUS AGS (CC- 1248) (1248cc)",
          "NEW ZDI PLUS (CC- 1248) (1248cc)",
          "ZDI PLUS (1248cc)",
          "GLORY LIMITED EDITION VDI (1248cc)",
          "NEW LDI (CC- 1248) (1248cc)",
          "NEW VDI  (CC- 1248) (1248cc)",
          "NEW ZDI  (CC- 1248) (1248cc)",
          "LDI OPTIONAL (1248cc)",
          "LDI SP LIMITED EDITION (1248cc)",
          "NEW (1248cc)",
          "VDI OPTIONAL (1248cc)",
          "VDI GLORY EDITION (1248cc)",
          "VDI DECA (1248cc)",
          "VDI DECA (1248cc)",
          "LDI BS-IV (1248cc)",
          "VDI ABS BS-IV (1248cc)",
          "VDI ABS BS IV (1248cc)",
          "VDI BS-IV (1248cc)",
          "VDI BS IV (1248cc)",
          "LDI ABS (1248cc)",
          "LDI ABS (1248cc)",
          "1.3 DLX (1248cc)",
          "1.3 DLX (1248cc)",
          "VDI GLORY LIMITED EDITION (1248cc)",
          "VDI GLORY LIMITED EDITION (1248cc)",
          "LIMITED EDITION DIESEL (1248cc)",
          "LIMITED EDITION DIESEL (1248cc)",
          "LDI SPECIAL EDITION (1248cc)",
          "LDI SPECIAL EDITION (1248cc)",
          "STAR LDI (1248cc)",
          "STAR LDI (1248cc)",
          "STAR VDI (1248cc)",
          "STAR VDI (1248cc)",
          "RS D (1248cc)",
          "RS D (1248cc)",
          "SP D (1248cc)",
          "SP D (1248cc)",
          "WINDSONG D (1248cc)",
          "WINDSONG D (1248cc)",
          "DECA LIMITED EDITION DIESEL (1248cc)",
          "GLORY EDITION DIESEL (1248cc)",
          "LDI ABS BSIV (1248cc)",
          "RS DIESEL (1248cc)",
          "SP DIESEL (1248cc)",
          "WINDSONG DIESEL (1248cc)",
          "VDI STAR (1248cc)",
          "1.2 LDI DLX (1248cc)",
          "VXI ONE MILLION (1248cc)",
          "LDI (DIESEL) (1248cc)",
          "ZDI PLUS AGS (1248cc)",
          "1.3 ZDI AMT (1248cc)",
          "VDI (DIESEL) (1248cc)",
          "VDI ABS (DIESEL) (1248cc)",
          "ZDI DIESEL (1248cc)",
          "AMT CBU (1248cc)",
          "WINDSONG VDI (1248cc)",
          "EXTEDER CBU (1248cc)",
          "1.3 VDI AMT (1248cc)",
          "1.3 ZDI PLUS (1248cc)",
          "NEW VDI (O) (1248cc)",
          "1.3 LDI (1248cc)",
          "1.3 ZDI (1248cc)",
          "1.3 VDI (1248cc)",
          "2018 LXI (1197cc)",
          "2018 VXI AMT (1197cc)",
          "2018 VXI (1197cc)",
          "2018 ZXI AMT (1197cc)",
          "2018 ZXI (1197cc)",
          "2018 ZXI PLUS (1197cc)",
          "ZXI AGS (1197cc)",
          "VXI AMT (CC 1197) (1197cc)",
          "NEW ZXI AGS (CC- 1197) (1197cc)",
          "NEW ZXI PLUS AGS (CC- 1197) (1197cc)",
          "NEW ZXI PLUS (CC- 1197) (1197cc)",
          "NEW VXI (O) (CC- 1197) (1197cc)",
          "NEW VXI AGS (CC- 1197) (1197cc)",
          "ZXI (CC 1197) (1197cc)",
          "NEW LXI (CC- 1197) (1197cc)",
          "NEW VXI (CC- 1197) (1197cc)",
          "NEW ZXI (CC- 1197) (1197cc)",
          "NEW LXI (O) (CC- 1197) (1197cc)",
          "LXI OPTION (1197cc)",
          "LXI OPTION SP LIMITED EDITION (1197cc)",
          "VXI OPTIONAL (1197cc)",
          "VXI GLORY EDITION (1197cc)",
          "KB ZXI PLUS AGS (1197cc)",
          "KB ZXI PLUS (1197cc)",
          "KB ZXI AMT (1197cc)",
          "KB GLORY LIMITED EDITION VXI (1197cc)",
          "KB VXI AGS (1197cc)",
          "KB ZXI (1197cc)",
          "KB VXI DECA (1197cc)",
          "ZXI PLUS BS6 (1197cc)",
          "VXI BS6 (1197cc)",
          "VXI AMT BS6 (1197cc)",
          "LXI BS6 (1197cc)",
          "ZXI BS6 (1197cc)",
          "ZXI AMT BS6 (1197cc)",
          "ZXI PLUS AMT BS6 (1197cc)",
          "LXI [OPTIO.] BSIV (1197cc)",
          "LXI 1.2 BS-IV (1197cc)",
          "LXI 1.2 BS-IV (1197cc)",
          "VXI 1.2 BS-IV (1197cc)",
          "VXI 1.2 BS-IV (1197cc)",
          "VXI 1.2 O (1197cc)",
          "VXI 1.2 O (1197cc)",
          "ZXI 1.2 BS-IV (1197cc)",
          "ZXI 1.2 BS-IV (1197cc)",
          "VXI ABS (1197cc)",
          "VXI ABS (1197cc)",
          "VXI 1.2 ABS BS-IV (1197cc)",
          "VXI 1.2 ABS BS-IV (1197cc)",
          "1.2 DLX (1197cc)",
          "1.2 DLX (1197cc)",
          "VXI DECA (1197cc)",
          "VXI DECA (1197cc)",
          "VXI GLORY LIMITED EDITION (1197cc)",
          "VXI GLORY LIMITED EDITION (1197cc)",
          "LIMITED EDITION PETROL (1197cc)",
          "LIMITED EDITION PETROL (1197cc)",
          "RANGE EXTENDER (1197cc)",
          "RANGE EXTENDER (1197cc)",
          "ZDI + AMT (1197cc)",
          "VXI CNG (1197cc)",
          "VXI CNG (1197cc)",
          "ZXI CNG (1197cc)",
          "ZXI CNG (1197cc)",
          "LXI ABS O (1197cc)",
          "LXI ABS O (1197cc)",
          "LXI SPECIAL EDITION (1197cc)",
          "LXI SPECIAL EDITION. (1197cc)",
          "STAR LXI (1197cc)",
          "STAR LXI (1197cc)",
          "STAR VXI (1197cc)",
          "STAR VXI (1197cc)",
          "RS P (1197cc)",
          "RS P (1197cc)",
          "SP P (1197cc)",
          "SP P (1197cc)",
          "WINDSONG P (1197cc)",
          "WINDSONG P (1197cc)",
          "LXI ABS (1197cc)",
          "LXI ABS (1197cc)",
          "ZXI + DUAL TONE (1197cc)",
          "ZXI + DUAL TONE (1197cc)",
          "ZXI + AMT DUAL TONE (1197cc)",
          "ZXI + AMT DUAL TONE (1197cc)",
          "LXI FACELIFT (1197cc)",
          "LXI FACELIFT (1197cc)",
          "VXI 1.2 AGS (1197cc)",
          "DECA LIMITED EDITION PETROL (1197cc)",
          "GLORY EDITION PETROL (1197cc)",
          "RS PETROL (1197cc)",
          "SP PETROL (1197cc)",
          "WINDSONG PETROL (1197cc)",
          "VXI STAR (1197cc)",
          "ZXI PLUS AGS (1197cc)",
          "1.2 ZXI BS III (1197cc)",
          "1.2 VXI BS IV (1197cc)",
          "1.2 LXI (1197cc)",
          "WINDSONG VXI (1197cc)",
          "1.2 VXI (ABS) BS IV (1197cc)",
          "1.2 LXI BS IV (1197cc)",
          "ONE MILLION EDITION VI (1197cc)",
          "1.2 ZXI BS IV (1197cc)",
          "ZXI PLUS (1197cc)",
          "1.2 VXI AMT (1197cc)",
          "1.2 ZXI AMT (1197cc)",
          "1.2 ZXI (1197cc)",
          "1.2 LXI BSVI (1197cc)",
          "1.2 VXI CNG (1197cc)",
          "1.2 ZXI PLUS AMT DUAL TONE (1197cc)",
          "LXI 1.3 (1298cc)",
          "VXI 1.3 (1298cc)",
          "ZXI 1.3 (1298cc)",
          "VXI BIII (1298cc)",
          "VXI E III (1298cc)",
          "VXI 1.3 ABS (1298cc)",
          "DZIRE VXI E3 (1298cc)",
          "DZIRE LXI BSIII (1298cc)",
          "LDI BSIII (1298cc)",
          "ZXI EIII (1298cc)",
          "VXI GLAM (1298cc)",
          "VXI BSIII (1298cc)",
          "ZXI (1298cc)",
          "ZXI (1298cc)",
          "VXI(NON-ABS,BS III 4STR) (1298cc)",
          "RANGE EXTENDERS (658cc)",
          "RANGE EXTENDER (658cc)",
          "RANGE EXTEDER CBU (658cc)",
          "AMT (CBU) (1328cc)",
        ],
      },
      {
        model: "GRAND VITARA",
        variants: [
          "AT (1995cc)",
          "MT (1995cc)",
          "7STR (AT) (1995cc)",
          "7STR (MT) (1995cc)",
          "XL-7 5STR (AT) (2700cc)",
          "XL-7 5STR (MT) (2700cc)",
          "2.4 AT (2393cc)",
          "2.4 AT (2393cc)",
          "2.4 MT (2393cc)",
          "2.4 MT (2393cc)",
          "XL-7 AT (2700cc)",
          "XL-7 MT (2700cc)",
          "1.5L INTELLIGENT ELECTRIC HYBRID ALPHA PLUS CVT (1462cc)",
          "1.5L SMART HYBRID SIGMA 5MT (1462cc)",
          "1.5L SMART HYBRID DELTA 5MT (1462cc)",
          "1.5L SMART HYBRID ZETA 5MT (1462cc)",
          "1.5L SMART HYBRID ALPHA 5MT (1462cc)",
          "1.5L SMART HYBRID ALPHA ALLGRIP(4WD) 5MT (1462cc)",
          "1.5L SMART HYBRID ALPHA 6AT (1462cc)",
          "1.5L SMART HYBRID DELTA 6AT (1462cc)",
          "1.5L SMART HYBRID ZETA 6AT (1462cc)",
          "1.5L ZETA CNG (1462cc)",
          "1.5L DELTA CNG (1462cc)",
          "SMART HYBRID SIGMA (1462cc)",
          "SMART HYBRID DELTA (1462cc)",
          "SMART HYBRID ALPHA AT (1462cc)",
          "DELTA CNG 1.5L 5MT (1462cc)",
          "SMART HYBRID DELTA AT (1462cc)",
          "DELTA CNG MT (1462cc)",
          "ZETA 1.5 MT (1462cc)",
          "ZETA 1.5 AT (1462cc)",
          "SIGMA MT (1462cc)",
          "ALPHA MT (1462cc)",
          "ZETA SMART HYBRID MT (1462cc)",
          "DELTA AT 1.5 L (1462cc)",
          "ZETA SMART HYBRID CNG (1462cc)",
          "ZETA SMART HYBRID CNG (1462cc)",
          "ALPHA DUAL TONE SMART HYBRID AT (1462cc)",
          "ALPHA DUAL TONE SMART HYBRID AT (1462cc)",
          "ALPHA DUAL TONE SMART HYBRID ALLGRIP (1462cc)",
          "ALPHA DUAL TONE SMART HYBRID ALLGRIP (1462cc)",
          "ALPHA DUAL TONE SMART HYBRID (1462cc)",
          "ALPHA DUAL TONE SMART HYBRID (1462cc)",
          "ZETA CNG (1462cc)",
          "DELTA SMART HYBRID CNG (1462cc)",
          "DELTA SMART HYBRID CNG (1462cc)",
          "ALPHA SMART HYBRID (1462cc)",
          "ALPHA SMART HYBRID (1462cc)",
          "SIGMA SMART HYBRID (1462cc)",
          "SIGMA SMART HYBRID (1462cc)",
          "ALPHA SMART HYBRID AT (1462cc)",
          "ALPHA SMART HYBRID AT (1462cc)",
          "ALPHA SMART HYBRID ALLGRIP (1462cc)",
          "ALPHA SMART HYBRID ALLGRIP (1462cc)",
          "ZETA SMART HYBRID (1462cc)",
          "ZETA SMART HYBRID (1462cc)",
          "ZETA SMART HYBRID AT (1462cc)",
          "ZETA SMART HYBRID AT (1462cc)",
          "DELTA SMART HYBRID (1462cc)",
          "DELTA SMART HYBRID (1462cc)",
          "DELTA SMART HYBRID AT (1462cc)",
          "DELTA SMART HYBRID AT (1462cc)",
          "ALPHA DT SMART HYBRID (1462cc)",
          "ALPHA DT SMART HYBRID ALLGRIP (1462cc)",
          "ALPHA DT SMART HYBRID AT (1462cc)",
          "2.4 MANUAL TRANSMISSION (2393cc)",
          "2.4 AUTOMATIC TRANSMISSION (2393cc)",
          "AX (2393cc)",
          "BREZZA ZDI PLUS (1248cc)",
          "XL-7 AT 5 SEATER (2700cc)",
          "XL-7 AUTOMATIC TRANSMISSION (2700cc)",
          "XL-7 AT TRANSMISSION 5 SEATER (2700cc)",
          "XL-7  AUTOMATIC TRANSMISSION 5 SEATER (2700cc)",
          "AT 7 SEATER (1995cc)",
          "AT TRANSMISSION 7 SEATER (1995cc)",
          "AUTOMATIC TRANSMISSION 7 SEATER (1995cc)",
          "XL-7 5 SEATER (2700cc)",
          "XL-7 MT 5 SEATER (2700cc)",
          "XL-7 MANUAL TRANSMISSION (2700cc)",
          "MT 7 SEATER (1995cc)",
          "AUTOMATIC TRANSMISSION (1995cc)",
          "MANUAL TRANSMISSION (1995cc)",
          "2.0 AT (1995cc)",
          "2.0 AT (1995cc)",
          "2.0 MT (1995cc)",
          "2.0 MT (1995cc)",
          "GRAND VITARA AT BS - IV (M) (1995cc)",
          "MANUAL TRANSMISSION  7 SEATER (1995cc)",
          "1.5L STRONG HYBRID ZETA PLUS CVT (1490cc)",
          "ZETA PLUS CVT (1490cc)",
          "ALPHA+1.5LCVT IE STRONG HYBRID (1490cc)",
          "STRONG HYBRID ZETA PLUS (1490cc)",
          "GV ALPHA AMT (1490cc)",
          "HYBRID ALPHA 1.5 L CVT (1490cc)",
          "ALPHA PLUS CVT (1490cc)",
          "SMART HYBRID ZETA 1.5L 5MT â€“ GVR4DZ1 (1490cc)",
          "ALPHA PLUS DUAL TONE INTELLIGENT HYBRID ECVT (1490cc)",
          "ALPHA PLUS DUAL TONE INTELLIGENT HYBRID ECVT (1490cc)",
          "ZETA PLUS DUAL TONE INTELLIGENT HYBRID ECVT (1490cc)",
          "ZETA PLUS DUAL TONE INTELLIGENT HYBRID ECVT (1490cc)",
          "ALPHA PLUS INTELLIGENT HYBRID ECVT (1490cc)",
          "ALPHA PLUS INTELLIGENT HYBRID ECVT (1490cc)",
          "ZETA PLUS INTELLIGENT HYBRID ECVT (1490cc)",
          "ZETA PLUS INTELLIGENT HYBRID ECVT (1490cc)",
          "ZETA PLUS DT INTELLIGENT HYBRID ECVT (1490cc)",
          "ALPHA PLUS DT INTELLIGENT HYBRID ECVT (1490cc)",
          "XL-7  MANUAL TRANSMISSION 5 SEATER (2737cc)",
          "XL-7  AUTOMATIC TRANSMISSION (2737cc)",
          "XL-7  AUTOMATIC TRANSMISSION 5 SEATER (2737cc)",
          "XL-7  MANUAL TRANSMISSION (2737cc)",
          "LR4CD1 (1995cc)",
          "XL-7 LTD (2736cc)",
          "XL-7 LTD (2736cc)",
          "XL 7 (2736cc)",
          "XL-7 AT (2736cc)",
          "XL-7 AT (2736cc)",
          "XL-7 MT (2736cc)",
          "XL-7 MT (2736cc)",
          "2.7 AUTOMATIC TRANSMISSION (2730cc)",
          "ALPHA PLUS INTELLIGENT HYBRID ECVT (1497cc)",
        ],
      },
      {
        model: "VERSA",
        variants: [
          "DX 7 SEATER (1298cc)",
          "DX 5STR (1298cc)",
          "DX 8STR (1298cc)",
          "DX 8STR (1298cc)",
          "DX1 5STR (1298cc)",
          "DX1 8STR (1298cc)",
          "DX2 5STR (1298cc)",
          "DX2 8STR (1298cc)",
          "DX2 8STR (1298cc)",
          "SDX 5STR (1298cc)",
          "STANDARD 5STR (1298cc)",
          "STANDARD 8STR (1298cc)",
          "SDX (1298cc)",
          "SDX (1298cc)",
          "LPG 8 SEATER (1298cc)",
          "LPG 7 SEATER (1298cc)",
          "LPG 5 SEATER (1298cc)",
          "CNG 7 SEATER (1298cc)",
          "CNG 5 SEATER (1298cc)",
          "SDX 7 SEATER (1298cc)",
          "DX BS II (1298cc)",
          "DX BS II (1298cc)",
          "DX2 BS II (1298cc)",
          "DX2 BS II (1298cc)",
          "STD 5 STR BS III (1298cc)",
          "STD 5 STR BS III (1298cc)",
          "DX 5 STR BS III (1298cc)",
          "DX 5 STR BS III (1298cc)",
          "DX2 5 STR BS III (1298cc)",
          "DX2 5 STR BS III (1298cc)",
          "DX 8 SEATER (1298cc)",
          "DX1 5 SEATER (1298cc)",
          "STANDARD 8 SEATER (1298cc)",
          "SDX 5 SEATER (1298cc)",
          "DX2 8 SEATER (1298cc)",
          "STANDARD 5 SEATER (1298cc)",
          "DX 5 SEATER (1298cc)",
          "SDX 8 SEATER (1298cc)",
          "DX2 5 SEATER (1298cc)",
          "DX1 8 SEATER (1298cc)",
          "SX (1298cc)",
          "DX 5 STR BS II (1298cc)",
          "DX BS III (1298cc)",
          "DX - BS III (1298cc)",
          "DX2 5 STR BS II (1298cc)",
          "DX2 BS III (1298cc)",
          "DX2 - BS III (1298cc)",
          "STD BS II (1298cc)",
          "STD BS III (1298cc)",
          "STD BS III (1298cc)",
          "DX2 (1298cc)",
          "DX (1298cc)",
          "STD (1298cc)",
          "DX2 5 SEATER - BS III (1298cc)",
          "STD 5-SEATER - BS III (1298cc)",
          "O2 7STR (1298cc)",
          "DX 5 SEATER - BS III (1298cc)",
          "O2 5STR CNG (1298cc)",
          "CRDI 5STR (1850cc)",
          "CARGO (1196cc)",
          "O2 LPG (1200cc)",
          "02-LPG L/ROOF CARGO (8 SEATER) (1200cc)",
          "02-LPG L/ROOF CARGO (7 SEATER) (1200cc)",
          "O2-LPG (8 SEATER) (1200cc)",
          "02-LPG L/ROOF CARGO (1200cc)",
          "O2-LPG (2 SEATER) (1200cc)",
        ],
      },
      {
        model: "OMNI",
        variants: [
          "4STR (796cc)",
          "5 STR (796cc)",
          "5 STR (796cc)",
          "8 STR (796cc)",
          "4 SEATER (796cc)",
          "5 STR BS-II (796cc)",
          "8 STR BS-II (796cc)",
          "5 STR BS-III (796cc)",
          "5 STR BS-IV (796cc)",
          "5 STR BS-IV (796cc)",
          "MPI STD BSIV 5 STR (796cc)",
          "E 8 STR (796cc)",
          "5 SEATER (796cc)",
          "8 SEATER (796cc)",
          "5 SEATER BS II (796cc)",
          "5 SEATER BS III (796cc)",
          "8 SEATER BS II (796cc)",
          "8 SEATER BS III (796cc)",
          "8 SEATER BS III (796cc)",
          "E 8 STR BS-IV (796cc)",
          "E MPI STD BSIV 15 STR (796cc)",
          "8 SEATER (10 STR) (796cc)",
          "XL 5 SEATER (798cc)",
          "XL 5 SEATER (798cc)",
          "XL 8 SEATER (798cc)",
          "XL 8 SEATER (798cc)",
          "4 STR (725cc)",
          "E MPI (796cc)",
          "E MPI STD BS III (796cc)",
          "MPI CARGO BSIII W/ IMMOBILISER (796cc)",
          "MPI STD BS III. (796cc)",
          "MPI STD BSIII 5-STR W/ IMMOBILISER (796cc)",
          "MPI STD BSIII 8-STR W/ IMMOBILISER (796cc)",
          "MPI STD (796cc)",
          "MPI AMBULANCE BSIV (796cc)",
          "MPI AMBULANCE BSIV (796cc)",
          "E MPI STD BS IV (796cc)",
          "E MPI BS2 7STR (796cc)",
          "MPI STD 8STR (796cc)",
          "E MPI STD 5STR (796cc)",
          "E MPI STD (796cc)",
          "E MPI STD (796cc)",
          "MPI STD BSIV (796cc)",
          "4 SEATER LPG (796cc)",
          "8 SEATER CNG (796cc)",
          "LPG BS IV (796cc)",
          "LPG BS IV (796cc)",
          "STD 8 STR LPG (796cc)",
          "E 8STR (796cc)",
          "LIMITED EDITION (796cc)",
          "LIMITED EDITION (796cc)",
          "E MPI BS III (796cc)",
          "CNG (796cc)",
          "CNG (796cc)",
          "CARGO BS IV (796cc)",
          "CARGO BS IV (796cc)",
          "E MPI /SCHOOL VAN (796cc)",
          "14 SEATER/SCH VAN (796cc)",
          "13 SEATER/SCH VAN (796cc)",
          "9 SEATER/SCH VAN (796cc)",
          "11 SEATER/SCH VAN (796cc)",
          "6 SEATER/SCH VAN (796cc)",
          "5 SEATER/DIESEL (796cc)",
          "7 SEATER/DIESEL (796cc)",
          "10 SEATER/SCH VAN (796cc)",
          "5 SEATER (796cc)",
          "8 SEATER (796cc)",
          "5 SEATER LPG (796cc)",
          "8 SEATER LPG (796cc)",
          "5 SEATER CNG (796cc)",
          "E2 8STR (796cc)",
          "STD (796cc)",
          "STD (796cc)",
          "E3 8STR (796cc)",
          "CNG 5STR METALIC (796cc)",
          "5-SEATER - BS III (796cc)",
          "CARGO LPG BS III (796cc)",
          "CARGO LPG BS-III (796cc)",
          "LPG BS III (796cc)",
          "LPG BS III (796cc)",
          "CARGO BS III (796cc)",
          "CARGO BS-III (796cc)",
          "CARGO LPG (796cc)",
          "LPG STD 5-STR (796cc)",
          "LPG STD 5 STR (M) (796cc)",
          "11 STR (796cc)",
          "CNG 5STR (796cc)",
          "LPG STD 8STR (796cc)",
          "CARRY (793cc)",
        ],
      },
      {
        model: "CELERIO",
        variants: [
          "X VXI AMT (998cc)",
          "X VXI O AMT (998cc)",
          "X ZXI AMT (998cc)",
          "X ZXI O AMT (998cc)",
          "LXI (998cc)",
          "LXI (998cc)",
          "VXI (998cc)",
          "VXI (998cc)",
          "ZXI (998cc)",
          "ZXI (998cc)",
          "ZXI O (998cc)",
          "ZXI O (998cc)",
          "ZXI AMT (998cc)",
          "ZXI AMT (998cc)",
          "LXI O (998cc)",
          "LXI O (998cc)",
          "VXI O (998cc)",
          "VXI O (998cc)",
          "VXI AGS (998cc)",
          "VXI AGS (998cc)",
          "ZXI PLUS AMT (998cc)",
          "ZXI PLUS AMT. (998cc)",
          "X VXI (998cc)",
          "X VXI O (998cc)",
          "X VXI (O) (998cc)",
          "X ZXI (998cc)",
          "X ZXI O (998cc)",
          "X ZXI (O) (998cc)",
          "LDI (793cc)",
          "LDI (793cc)",
          "VDI (793cc)",
          "VDI (793cc)",
          "ZDI (793cc)",
          "ZDI (793cc)",
          "ZDI O (793cc)",
          "ZDI O (793cc)",
          "LDI O (793cc)",
          "LDI O (793cc)",
          "VDI O (793cc)",
          "VDI O (793cc)",
          "TOUR H2 (998cc)",
          "TOUR H2. (998cc)",
          "LXI ABS (998cc)",
          "LXI ABS (998cc)",
          "VXI ABS (998cc)",
          "VXI ABS (998cc)",
          "LXI (O) (AT) (998cc)",
          "VXI GREEN (4+1) (998cc)",
          "ZXI (AT) (998cc)",
          "ZXI (O) (AT) (998cc)",
          "ZXI (O) AGS (998cc)",
          "VXI (O) AGS (998cc)",
          "LXI ABS (O) (998cc)",
          "CNG VXI BSIV (998cc)",
          "TOUR H2 CNG BSVI (998cc)",
          "VXI LPG (998cc)",
          "ZXI PLUS MT (998cc)",
          "1.0 VXI 5MT CNG BSVI (998cc)",
          "1.0 LXI 5MT BSVI (998cc)",
          "1.0 VXI 5MT BSVI (998cc)",
          "1.0 ZXI 5MT BSVI (998cc)",
          "1.0 VXI AGS BSVI (998cc)",
          "1.0 ZXI AGS BSVI (998cc)",
          "1.0 ZXI PLUS 5MT BSVI (998cc)",
          "1.0 ZXI PLUS AGS BSVI (998cc)",
          "ZXI AGS (998cc)",
          "VXI GREEN (O) (998cc)",
          "GREEN VXI (O) (998cc)",
          "VXI AT (998cc)",
          "LXI AT (998cc)",
          "LXI AGS (O) (998cc)",
          "VXI AMT (O) (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "X (998cc)",
          "ZXI PLUS ISS AGS (998cc)",
          "ZXI ISS AGS (998cc)",
          "VXI ISS AGS (998cc)",
          "ZXI PLUS ISS (998cc)",
          "ZXI ISS (998cc)",
          "LXI ISS (998cc)",
          "VXI ISS (998cc)",
          "X VXI AGS (998cc)",
          "X VXI (O) AGS (998cc)",
          "X ZXI AGS (998cc)",
          "X ZXI (O) AGS (998cc)",
          "ZXI PLUS AGS (998cc)",
          "GREEN VXI CNG (O) (998cc)",
          "GREEN VXI OPTIONAL (998cc)",
          "LXI OPTION (998cc)",
          "VXI AGS OPTIONAL (998cc)",
          "VXI AMT OPTIONAL (998cc)",
          "VXI OPTIONAL (998cc)",
          "ZXI AT OPTIONAL (998cc)",
          "ZXI OPTIONAL AMT (998cc)",
          "ZXI OPTIONAL (998cc)",
          "VXI OPTION (998cc)",
          "1.0 LXI (998cc)",
          "VXI O AMT (998cc)",
          "VXI O AMT (998cc)",
          "ZXI (O) AMT BSVI (998cc)",
          "ZXI 1L AGS (998cc)",
          "1.0 ZXI AMT O (998cc)",
          "1.0 ZXI AMT O (998cc)",
          "VXI AMT BS6 (998cc)",
          "VXI BS6 (998cc)",
          "LXI AMT (998cc)",
          "LXI AMT (998cc)",
          "VXI AMT (998cc)",
          "VXI AMT (998cc)",
          "LXI AGS (998cc)",
          "LXI AGS (998cc)",
          "ZXI PLUS (998cc)",
          "ZXI PLUS (998cc)",
          "VXI CNG O (998cc)",
          "VXI CNG O (998cc)",
          "VXI CNG (998cc)",
          "VXI CNG (998cc)",
          "LXI AT O (998cc)",
          "LXI AT O (998cc)",
          "LXI BS-VI (998cc)",
          "LXI BS-VI (998cc)",
          "LXI O BS-VI (998cc)",
          "LXI O BS-VI (998cc)",
          "VXI BS-VI (998cc)",
          "VXI BS-VI (998cc)",
          "VXI O BS-VI (998cc)",
          "VXI O BS-VI (998cc)",
          "VXI CNG O BS-VI (998cc)",
          "VXI CNG O BS-VI. (998cc)",
          "VXI AMT BS-VI (998cc)",
          "VXI AMT BS-VI (998cc)",
          "VXI CNG BS-VI (998cc)",
          "VXI CNG BS-VI (998cc)",
          "ZXI BS-VI (998cc)",
          "ZXI BS-VI (998cc)",
          "ZXI AMT BS-VI (998cc)",
          "ZXI AMT BS-VI (998cc)",
          "TOUR H2 CNG (998cc)",
          "TOUR H2 CNG (998cc)",
          "LXI AMT ABS (998cc)",
          "LXI AMT ABS (998cc)",
          "VXI AMT ABS (998cc)",
          "VXI AMT ABS (998cc)",
          "ZXI AMT ABS (998cc)",
          "ZXI AMT ABS (998cc)",
          "VXI LIMITED EDITION (998cc)",
          "VXI LIMITED EDITION (998cc)",
          "ZXI LIMITED EDITION (998cc)",
          "ZXI LIMITED EDITION (998cc)",
          "VXI O AMT BS-VI (998cc)",
          "VXI O AMT BS-VI (998cc)",
          "1.0 ZXI AMT O BS-VI (998cc)",
          "1.0 ZXI AMT O BS-VI (998cc)",
          "ZXI O BS-VI (998cc)",
          "ZXI O BS-VI (998cc)",
          "ZXI AMT O BS-VI (998cc)",
          "VXI GREEN CNG (998cc)",
          "ZXI PLUS 1L ISS AGS (998cc)",
          "LXI AMT(O) (998cc)",
          "H2 TOUR (998cc)",
          "H2 TOUR CNG (998cc)",
          "VXI GREEN CNG (4+1) (998cc)",
          "ZXI AMT (O) (998cc)",
          "VXI (O) CNG (998cc)",
          "ZXI OPT (998cc)",
          "ZXI AMT OPT (998cc)",
          "GREEN VXI (998cc)",
          "VDI (793CC) (993cc)",
          "ZDI OPTION (793cc)",
          "ZDI PLUS (793cc)",
          "ZDI OPT (793cc)",
          "LDI ABS (793cc)",
          "LDI ABS (793cc)",
          "VDI ABS (793cc)",
          "VDI ABS (793cc)",
          "X M CELERIO VXI (998cc)",
        ],
      },
      {
        model: "S CROSS",
        variants: [
          "1.3 ALPHA (1248cc)",
          "1.3 DELTA (1248cc)",
          "1.3 SIGMA (1248cc)",
          "1.6 ALPHA (1598cc)",
          "SIGMA SMART HYBRID (1248cc)",
          "DELTA SMART HYBRID (1248cc)",
          "ZETA SMART HYBRID (1248cc)",
          "ALPHA SMART HYBRID (1248cc)",
          "S CROSS SGX (1491cc)",
          "1.3 DDIS 200 SIGMA (O) (1248cc)",
          "1.5 SMART HYBRID SIGMA BSVI (1462cc)",
          "1.5 SMART HYBRID DELTA BSVI (1462cc)",
          "1.5 SMART HYBRID ZETA BSVI (1462cc)",
          "1.5 SMART HYBRID DELTA(AT)BSVI (1462cc)",
          "1.5 SMART HYBRID ALPHA BSVI (1462cc)",
          "1.5 SMART HYBRID ZETA(AT)BSVI (1462cc)",
          "1.5 SMART HYBRID ALPHA(AT)BSVI (1462cc)",
          "1.5 SMART HYBRID SIGMA (1462cc)",
          "1.3 ZETA (1248cc)",
          "1.5 SGX (1491cc)",
          "1.3 SIGMA (O) DDIS 200 (1248cc)",
          "1.3 SMART HYBRID SIGMA DDIS 200 (1248cc)",
          "1.3 SMART HYBRID DELTA DDIS 200 (1248cc)",
          "1.3 SMART HYBRID ZETA DDIS 200 (1248cc)",
          "1.3 SMART HYBRID ALPA DDIS 200 (1248cc)",
          "1.3 ALPHA DDIS 200 (1248cc)",
          "1.3 DELTA DDIS 200 (1248cc)",
          "1.3 SIGMA DDIS 200 (1248cc)",
          "1.3 ZETA DDIS 200 (1248cc)",
          "200 SIGMA OPTION (1248cc)",
          "200 ALPHA (1248cc)",
          "200 DELTA (1248cc)",
          "200 SIGMA (1248cc)",
          "200 ZETA (1248cc)",
          "ZETA 1.3 LIMITED EDITION (1248cc)",
          "1.3D SIGMA (O) 200 (1248cc)",
          "1.5 SMART HYBRID ALPHA AT PETROL (1462cc)",
          "1.5 SMART HYBRID ZETA AT PETROL (1462cc)",
          "1.5 SMART HYBRID ALPHA PETROL (1462cc)",
          "1.5 SMART HYBRID DELTA PETROL (1462cc)",
          "1.5 SMART HYBRID SIGMA PETROL (1462cc)",
          "1.5 SMART HYBRID DELTA AT PETROL (1462cc)",
          "1.5 SMART HYBRID ZETA PETROL (1462cc)",
          "1.6 ZETA DDIS 320 (1598cc)",
          "1.6 ALPHA DDIS 320 (1598cc)",
          "1.6 DELTA DDIS 320 (1598cc)",
          "320 ALPHA (1598cc)",
          "320 DELTA (1598cc)",
          "320 ZETA (1598cc)",
          "1.6D ALPHA (1598cc)",
          "1.6D DELTA (1598cc)",
          "1.6D ZETA (1598cc)",
          "1.5D SGX (1491cc)",
          "1.5 SIGMA BSVI (1462cc)",
          "1.5 DELTA BSVI (1462cc)",
          "1.5 ZETA BSVI (1462cc)",
          "1.5 DELTA (AT) BSVI (1462cc)",
          "1.5 ALPHA BSVI (1462cc)",
          "1.5 ZETA (AT) BSVI (1462cc)",
          "1.5 ALPHA (AT) BSVI (1462cc)",
        ],
      },
      {
        model: "KIZASHI",
        variants: [
          "2.4 MT (2393cc)",
          "2.4 CVT (2393cc)",
          "2.4 MANUAL TRANSMISSION (2393cc)",
          "CVT (2393cc)",
          "CVT (2393cc)",
          "MT (2393cc)",
          "MT (2393cc)",
        ],
      },
      {
        model: "DZIRE",
        variants: [
          "VDI (AT) (1248cc)",
          "ZXI (AT) (1197cc)",
          "ZDI+ (AT) (1248cc)",
          "ZXI CNG BSVI (1197cc)",
          "LXI BSVI (1197cc)",
          "LXI BS-VI (1197cc)",
          "VXI AGS BSVI (1197cc)",
          "ZXI BSVI (1197cc)",
          "ZXI BS-VI (1197cc)",
          "VXI CNG BSVI (1197cc)",
          "ZXI AGS BSVI (1197cc)",
          "TOUR S (O) CNG BSVI (1197cc)",
          "VXI AT (1197cc)",
          "TOUR S (1248cc)",
          "TOUR S (1248cc)",
          "TOUR S (1197cc)",
          "VXI BSVI (1197cc)",
          "VXI BS-VI (1197cc)",
          "LXI (1197cc)",
          "LXI (1197cc)",
          "VXI (1197cc)",
          "VXI (1197cc)",
          "ZXI (1197cc)",
          "ZXI (1197cc)",
          "VXI CNG (1197cc)",
          "VXI CNG (1197cc)",
          "LDI (1248cc)",
          "LDI (1248cc)",
          "VDI (1248cc)",
          "VDI (1248cc)",
          "ZDI (1248cc)",
          "ZDI (1248cc)",
          "TOUR S PETROL (1197cc)",
          "ZXI (1298cc)",
          "VDI PLUS (1248cc)",
          "VDI PLUS AGS (1248cc)",
          "VDI AGS (1248cc)",
          "ZDI AGS (1248cc)",
          "ZDI PLUS AGS (1248cc)",
          "AMT VDI (1248cc)",
          "LDI SPECIAL EDITION (1248cc)",
          "LDI SPECIAL EDITION (1248cc)",
          "TOUR LDI (1248cc)",
          "TOUR LDI (1248cc)",
          "ZDI PLUS (1248cc)",
          "ZDI PLUS (1248cc)",
          "VDI AMT (1248cc)",
          "VDI AMT (1248cc)",
          "ZDI AMT (1248cc)",
          "ZDI AMT (1248cc)",
          "ZDI PLUS AMT (1248cc)",
          "ZDI PLUS AMT (1248cc)",
          "TOUR LDI DIESEL (1248cc)",
          "TOUR S DIESEL (1248cc)",
          "AMT ZXI PLUS (1197cc)",
          "AMT ZXI (1197cc)",
          "REGAL (1197cc)",
          "LXI OPTION (1197cc)",
          "ZXI PLUS AMT BS-VI (1197cc)",
          "ZXI PLUS AMT BS-VI (1197cc)",
          "VXI AMT BS-VI (1197cc)",
          "VXI AMT BS-VI (1197cc)",
          "LXI SPECIAL EDITION (1197cc)",
          "LXI SPECIAL EDITION (1197cc)",
          "ZXI AMT BS-VI (1197cc)",
          "ZXI AMT BS-VI (1197cc)",
          "TOUR S O PETROL (1197cc)",
          "TOUR S O PETROL (1197cc)",
          "ZXI BS-4 (1197cc)",
          "TOUR LXI (1197cc)",
          "TOUR LXI (1197cc)",
          "ZXI CNG 1.2L 5MT (1197cc)",
          "TOUR V5 (1197cc)",
          "ZXI PLUS (1197cc)",
          "ZXI PLUS (1197cc)",
          "ZXI CNG (1197cc)",
          "ZXI CNG (1197cc)",
          "VXI AMT (1197cc)",
          "VXI AMT (1197cc)",
          "ZXI AMT (1197cc)",
          "ZXI AMT (1197cc)",
          "TOUR S CNG (1197cc)",
          "TOUR S CNG (1197cc)",
          "VXI AGS (1197cc)",
          "VXI AGS (1197cc)",
          "ZXI AGS (1197cc)",
          "ZXI AGS (1197cc)",
          "ZXI PLUS AGS (1197cc)",
          "ZXI PLUS AGS (1197cc)",
          "TOUR S O CNG (1197cc)",
          "TOUR S (O) CNG (1197cc)",
          "ZXI PLUS AMT (1197cc)",
          "ZXI PLUS AMT (1197cc)",
          "TOUR LXI CNG (1197cc)",
          "TOUR LXI CNG (1197cc)",
          "ZXI PLUS BS-VI (1197cc)",
          "LXI TOUR (1197cc)",
        ],
      },
      {
        model: "S PRESSO",
        variants: [
          "STD BSVI (998cc)",
          "LXI BSVI (998cc)",
          "VXI BSVI (998cc)",
          "VXI AGS BSVI (998cc)",
          "STD (O) BSVI (998cc)",
          "LXI (O) BSVI (998cc)",
          "VXI (O) BSVI (998cc)",
          "VXI AGS (O) BSVI (998cc)",
          "VXI+ (O) AMT BSVI (998cc)",
          "VXI PLUS (O) AGS (998cc)",
          "LXI CNG (O) (998cc)",
          "VXI CNG (O) (998cc)",
          "VXI AGS (O) (998cc)",
          "VXI AGS (998cc)",
          "VXI (O) AGS (998cc)",
          "VXI PLUS AGS (998cc)",
        ],
      },
      {
        model: "S-PRESSO",
        variants: [
          "LXI CNG (998cc)",
          "LXI CNG (998cc)",
          "VXI CNG (998cc)",
          "VXI CNG. (998cc)",
          "LXI O CNG (998cc)",
          "LXI (O) CNG. (998cc)",
          "VXI O CNG (998cc)",
          "VXI O CNG (998cc)",
          "LXI 4STR (998cc)",
          "VXI 4 STR (998cc)",
          "VXI (O) CNG BS6 (998cc)",
          "VXI (O) AMT (998cc)",
          "VXI(O)AMT (998cc)",
          "VXI AMT (998cc)",
          "VXI AMT (998cc)",
          "VXI PLUS AMT (998cc)",
          "VXI PLUS AMT (998cc)",
          "LXI (998cc)",
          "LXI (998cc)",
          "VXI (998cc)",
          "VXI (998cc)",
          "VXI PLUS (998cc)",
          "VXI PLUS (998cc)",
          "STD (998cc)",
          "STD (998cc)",
          "STD(O) (998cc)",
          "STD (O) (998cc)",
          "LXI(O) (998cc)",
          "LXI (O) (998cc)",
          "VXI(O) (998cc)",
          "VXI (O) (998cc)",
          "VXI PLUS O AMT (998cc)",
          "VXI PLUS O AMT (998cc)",
        ],
      },
      {
        model: "VITARA BREZZA",
        variants: [
          "VDI (AT) (1248cc)",
          "ZDI (AT) (1248cc)",
          "ZDI+ DUAL TONE (AT) (1248cc)",
          "ZDI+ DUAL TONE (MT) (1248cc)",
          "LXI BSVI (1462cc)",
          "ZXI BSVI (1462cc)",
          "VXI (AT) SHVS BSVI (1462cc)",
          "ZXI+ DUAL TONE BSVI (1462cc)",
          "ZXI (AT) SHVS BSVI (1462cc)",
          "ZXI+ (AT) DUAL TONE BSVI (1462cc)",
          "VXI BS-VI (1462cc)",
          "LDI (1248cc)",
          "LDI O (1248cc)",
          "LDI O (1248cc)",
          "VDI (1248cc)",
          "VDI O (1248cc)",
          "VDI O (1248cc)",
          "ZDI (1248cc)",
          "1.5 SMART HYBRID ZXI PLUS (1462cc)",
          "1.5 K15C ISG 5MT LXI (1462cc)",
          "1.5 K15C ISG 5MT VXI (1462cc)",
          "1.5 K15C ISG 5MT ZXI (1462cc)",
          "1.5 K15C ISG 6AT VXI (1462cc)",
          "1.5 K15C ISG 6AT ZXI (1462cc)",
          "1.5 K15C ISG 6AT ZXI PLUS (1462cc)",
          "1.5 SMART HYBRID ZXI AT (1462cc)",
          "1.5 SMART HYBRID VXI AT (1462cc)",
          "1.5 LXI (1462cc)",
          "1.5 VXI (1462cc)",
          "1.5 ZXI (1462cc)",
          "1.5 ZXI PLUS (1462cc)",
          "1.5 VXI AT (1462cc)",
          "1.5 ZXI AT (1462cc)",
          "1.5 ZXI PLUS AT (1462cc)",
          "1.5 SMART HYBRID ZXI PLUS AT (1462cc)",
          "SMART HYBRID VXI AT (1462cc)",
          "LXI BS6 (1462cc)",
          "VXI AT BS6 (1462cc)",
          "VXI BS6 (1462cc)",
          "ZXI+ 1.5 (1462cc)",
          "ZXI+ AT (1462cc)",
          "ZXI PLUS AT SHVS (1462cc)",
          "ZXI PLUS AT SHVS (1462cc)",
          "SMART HYBRID VXI 1.5L AT (1462cc)",
          "VXI 1.5 L 5MT (1462cc)",
          "ZXI PLUS BSVI (1462cc)",
          "ZXI PLUS AT DUAL TONE. (1462cc)",
          "ZXI PLUS AT DUAL TONE (1462cc)",
          "ZXI+ DUAL TONE (1462cc)",
          "ZXI+ (AT) DUAL TONE (1462cc)",
          "VXI AT SHVS (1462cc)",
          "VXI AT SHVS (1462cc)",
          "ZXI AT SHVS (1462cc)",
          "ZXI AT SHVS (1462cc)",
          "LXI (1462cc)",
          "LXI (1462cc)",
          "VXI (1462cc)",
          "VXI (1462cc)",
          "ZXI (1462cc)",
          "ZXI (1462cc)",
          "ZXI PLUS (1462cc)",
          "ZXI PLUS (1462cc)",
          "ZXI PLUS DUAL TONE (1462cc)",
          "ZXI PLUS DUAL TONE (1462cc)",
          "ZXI PLUS AGS SHVS (1462cc)",
          "ZXI AGS SHVS (1462cc)",
          "VXI AGS SHVS (1462cc)",
          "ZXI PLUS AGS DUAL TONE (1462cc)",
          "1.2 ZDI PLUS  AMT DDIS 200 (1248cc)",
          "1.2 VDI AMT DDIS 200 (1248cc)",
          "1.2 ZDI  AMT DDIS 200 (1248cc)",
          "1.2 LDI DDIS 200 (1248cc)",
          "1.2 LDI (O) DDIS 200 (1248cc)",
          "1.2 VDI DDIS 200 (1248cc)",
          "1.2 VDI (O) DDIS 200 (1248cc)",
          "1.2 ZDI DDIS 200 (1248cc)",
          "1.2 ZDI PLUS DDIS 200 (1248cc)",
          "ZDI PLUS DUAL TONE AMT (1248cc)",
          "ZDI+ DUAL TONE AGS (1248cc)",
          "ZDI+ DUAL TONE AGS (1248cc)",
          "NEW ZDI PLUS DUAL TONE (1248cc)",
          "NEW ZDI PLUS DUAL TONE (1248cc)",
          "ZDI PLUS (1248cc)",
          "ZDI PLUS DUAL TONE (1248cc)",
          "VDI AGS (1248cc)",
          "VDI AGS (1248cc)",
          "ZDI AGS (1248cc)",
          "ZDI+ AGS (1248cc)",
          "ZDI PLUS AGS (1248cc)",
          "ZDI PLUS DUAL TONE AGS (1248cc)",
          "ZDI PLUS AMT (1248cc)",
          "VDI AMT (1248cc)",
          "ZDI AMT (1248cc)",
          "1.5 K15C ISG 5MT ZXI PLUS (1462cc)",
          "SMART HYBRID ZXI PLUS AT (1462cc)",
          "ZXI+ 1.5L AT (1248cc)",
        ],
      },
      {
        model: "VITARA",
        variants: [
          "BREZZA LDI (1248cc)",
          "BREZZA VDI (1248cc)",
          "BREZZA ZDI (1248cc)",
          "BREZZA ZDI PLUS (1248cc)",
          "BREZZA ZDI PLUS DUAL TONE (1248cc)",
          "BREZZA VDI OPTION (1248cc)",
          "BREZZA LDI OPTION (1248cc)",
        ],
      },
      {
        model: "STINGRAY",
        variants: [
          "VXI (998cc)",
          "VXI (998cc)",
          "LXI O (998cc)",
          "LXI O (998cc)",
          "VXI AMT O (998cc)",
          "VXI AMT O (998cc)",
          "VXI O (998cc)",
          "VXI O (998cc)",
          "LXI (998cc)",
          "LXI (998cc)",
          "VXI AMT (998cc)",
          "VXI AMT (998cc)",
          "VXI OPT (998cc)",
        ],
      },
      {
        model: "RITZ",
        variants: [
          "GENUS VXI (1197cc)",
          "GENUS VXI (1197cc)",
          "GENUS VDI (1248cc)",
          "GENUS VDI (1248cc)",
          "VXI CNG (1197cc)",
          "LDI (1248cc)",
          "LDI (1248cc)",
          "LXI (1197cc)",
          "LXI (1197cc)",
          "VDI (1248cc)",
          "VDI (1248cc)",
          "VXI (1197cc)",
          "VXI (1197cc)",
          "ZDI (1248cc)",
          "ZDI (1248cc)",
          "ZXI (1197cc)",
          "ZXI (1197cc)",
          "VDI (ABS) (1248cc)",
          "VXI ( ABS) (1197cc)",
          "MC LDI (1248cc)",
          "MC VDI (1248cc)",
          "MC VDI ABS (1248cc)",
          "1.3 (1248cc)",
          "LDI BS-IV (1248cc)",
          "LDI BS-IV (1248cc)",
          "VDI ABS BS-IV (1248cc)",
          "VDI ABS BS-IV (1248cc)",
          "VDI BS-IV (1248cc)",
          "VDI BS-IV (1248cc)",
          "ZDI BS-IV (1248cc)",
          "ZDI BS-IV (1248cc)",
          "ELATE D (1248cc)",
          "ELATE D (1248cc)",
          "MC ZDI (1248cc)",
          "ELATE VDI (1248cc)",
          "1.2 (1197cc)",
          "AUTO TRANSMISSION PETROL (1197cc)",
          "AUTO TRANSMISSION ABS PETROL (1197cc)",
          "AT PETROL (1197cc)",
          "LXI BS-IV (1197cc)",
          "LXI BS-IV (1197cc)",
          "VXI ABS BS-IV (1197cc)",
          "VXI ABS BS-IV (1197cc)",
          "VXI BS-IV (1197cc)",
          "VXI BS-IV (1197cc)",
          "ZXI BS-IV (1197cc)",
          "ZXI BS-IV (1197cc)",
          "BUZZ (1197cc)",
          "BUZZ (1197cc)",
          "VXI AT BS-IV (1197cc)",
          "VXI AT BS-IV (1197cc)",
          "AUTOMATIC (1197cc)",
          "AUTOMATIC (1197cc)",
          "ELATE P (1197cc)",
          "ELATE P (1197cc)",
          "LXI /CNG (1197cc)",
          "ELATE VXI (1197cc)",
          "AT ABS (1197cc)",
          "AT (1197cc)",
        ],
      },
      {
        model: "ALTO",
        variants: [
          "VXI (796cc)",
          "VXI (796cc)",
          "XFUN LTD LXI (796cc)",
          "XFUN LTD LXI (796cc)",
          "LXI O (796cc)",
          "LXI O. (796cc)",
          "(AT) (796cc)",
          "(AT) (ABS) (796cc)",
          "AX (AT) (796cc)",
          "LXI WITH AIRBAG (796cc)",
          "1061 VXI 5STR (1061cc)",
          "1061 LXI 5STR (1061cc)",
          "LX STD (1061cc)",
          "VXI PLUS 0.8L 5MT (796cc)",
          "STANDARD (796cc)",
          "XFUN LTD LX (796cc)",
          "K10B VXI (998cc)",
          "XCITE (796cc)",
          "XCITE (796cc)",
          "LXI (796cc)",
          "LXI (796cc)",
          "LX (796cc)",
          "LX (796cc)",
          "VX (796cc)",
          "LXI (998cc)",
          "STD (1061cc)",
          "VXI BS-2 (1061cc)",
          "LXI 5 SEATER CC 1061 (1061cc)",
          "LXI 4 SEATER CC 1061 (1061cc)",
          "VXI 1061CC (1061cc)",
          "LXI 1061 CC (1061cc)",
          "VX 1.1 (1061cc)",
          "VX 1.1 (1061cc)",
          "VX (1061cc)",
          "VX (1061cc)",
          "1.1 VXI (1061cc)",
          "1.1 LXI (1061cc)",
          "VXI (4 STR) (1061cc)",
          "VX (4 STR) (1061cc)",
          "LXI (1061cc)",
          "VXI (1061cc)",
          "VXI PLUS MT (796cc)",
          "LX 4 SEATER (796cc)",
          "STANDARD (O) (796cc)",
          "LXI 4 SEATER (796cc)",
          "XFUN (796cc)",
          "LXI BS III (796cc)",
          "LXI BS-III (796cc)",
          "LXI BS6 (796cc)",
          "LXI PETROL (796cc)",
          "LX BS-III (796cc)",
          "LX BS III (796cc)",
          "LXI BS-IV (796cc)",
          "LXI BS-IV (796cc)",
          "STD BS-IV (796cc)",
          "STD BS-IV (796cc)",
          "VXI BS IV (796cc)",
          "LX BS-II (796cc)",
          "LX BS-IV (796cc)",
          "LX BS-IV (796cc)",
          "LXI BS-II (796cc)",
          "STD BS-II (796cc)",
          "STD BS-III (796cc)",
          "STD O (796cc)",
          "STD O. (796cc)",
          "VXI PLUS (796cc)",
          "VXI PLUS (796cc)",
          "LXI WITH AIRBAGS (796cc)",
          "LXI 4STR (796cc)",
          "LXI 4 STR (796cc)",
          "STD (796cc)",
          "STD (796cc)",
          "AX (796cc)",
          "AX. (796cc)",
          "XCITE (4 STR) (796cc)",
          "GREEN STD. (M) (796cc)",
          "XFUN LTD LXI (4 SEATER) (796cc)",
          "GREEN LXI (M) (796cc)",
          "GREEN STD. (796cc)",
          "XFUN LTD LX (4 SEATER) (796cc)",
          "GREEN LX (M) (796cc)",
          "STD (O) PETROL (796cc)",
          "LXI (O) PETROL (796cc)",
          "LX 4 STR (796cc)",
          "STD 4 STR (796cc)",
          "K10 LXI (O) CNG 4 STR (998cc)",
          "VXI AGS (O) (998cc)",
          "K10 VXI PLUS AMT (998cc)",
          "AX (998cc)",
          "AX  (AUTOMATIC TRANSMISSION) (1061cc)",
          "AX (1061cc)",
          "GREEN STANDARD (796cc)",
          "GREEN LXI BS IV 4 STR (796cc)",
          "LXI CNG 4 STR (796cc)",
          "K10 GREEN LXI (796cc)",
          "LX CNG (796cc)",
          "LX CNG (796cc)",
          "LXI CNG (796cc)",
          "LXI CNG (796cc)",
          "STD CNG (796cc)",
          "STD CNG (796cc)",
          "LXI CNG BS IV (796cc)",
          "LXI CNG BS IV (796cc)",
          "LXI CNG O (796cc)",
          "LXI CNG O (796cc)",
          "LXI S CNG (796cc)",
          "LXI S CNG (796cc)",
          "LXI S CNG O (796cc)",
          "LXI S CNG O (796cc)",
          "STANDARD CNG (796cc)",
          "LXI CNG 4 SEATER (796cc)",
          "LX CNG (4 SEATER) (796cc)",
          "STD WITH CNG (4 SEATER) (796cc)",
          "LXI (O) CNG (796cc)",
          "GREEN LX (796cc)",
          "GREEN LXI (796cc)",
          "AUTO TRANSMISSION ABS PETROL (1197cc)",
          "AUTO TRANSMISSION PETROL (1197cc)",
          "LXI (O) (1197cc)",
          "NEW (1000cc)",
          "VX 1.0 (1000cc)",
          "CBU 5 (SEATER) (1248cc)",
          "CBU (1248cc)",
        ],
      },
      {
        model: "ZEN",
        variants: [
          "POWER STEERING (1527cc)",
          "LX AC (993cc)",
          "RAGE (730cc)",
          "ESTILO VXI EURO 4 (998cc)",
          "MPI (796cc)",
          "MPI (993cc)",
          "MPI (1061cc)",
          "STD (1527cc)",
          "STD (1076cc)",
          "STD (993cc)",
          "STD (993cc)",
          "CLASSIC (993cc)",
          "CLASSIC (993cc)",
          "LXI (993cc)",
          "LXI (993cc)",
          "3 DOOR (993cc)",
          "3 DOOR (993cc)",
          "VX (993cc)",
          "VX (993cc)",
          "VXI (993cc)",
          "VXI (993cc)",
          "ESTILO VXI (ABS) (1100cc)",
          "LXI (1061cc)",
          "AT (993cc)",
          "LX (993cc)",
          "LX E-II (997cc)",
          "LX BS-3 (993cc)",
          "LXI BS-1 (993cc)",
          "LX BS-I (993cc)",
          "LXI BS-3 (993cc)",
          "LXI BS-2 (993cc)",
          "2-DOOR (993cc)",
          "2 DOOR (993cc)",
          "LXI BS-III (993cc)",
          "LXI BS-III (993cc)",
          "VXI BS-III (993cc)",
          "VXI BS-III (993cc)",
          "AUTOMATIC TRANSMISSION (993cc)",
          "LX BS-III (993cc)",
          "LX BS-III (993cc)",
          "VXI BS-II (993cc)",
          "LXI BS-II (993cc)",
          "LX (PETROL) (2006) (993cc)",
          "AX (993cc)",
          "MT (993cc)",
          "A/C (993cc)",
          "D (993cc)",
          "D PS (993cc)",
          "ESTILO VXI E-3 (1061cc)",
          "LXI BS-III (1061cc)",
          "VXI BS III (1061cc)",
          "ESTILO LXI CNG (1061cc)",
          "ESTILO VXI CNG (1061cc)",
          "D (1527cc)",
          "D (1527cc)",
          "STANDARD (1527cc)",
          "AT BS-II (1527cc)",
          "AT BS-II (1527cc)",
          "DIESEL (1527cc)",
          "DIESEL (POWER STEERING) (1527cc)",
          "LX (1527cc)",
          "AT (1527cc)",
          "D PS (1527cc)",
          "D PS (1527cc)",
          "LX BS-II (1527cc)",
          "DI (1527cc)",
        ],
      },
      {
        model: "ERTIGA",
        variants: [
          "ZDI (1248cc)",
          "ZDI (1248cc)",
          "LDI (1248cc)",
          "LDI (1248cc)",
          "VDI (1248cc)",
          "VDI (1248cc)",
          "VXI ABS (1373cc)",
          "VXI ABS (1373cc)",
          "LXI O (1373cc)",
          "LXI O (1373cc)",
          "VXI AT (1373cc)",
          "VXI AT (1373cc)",
          "LDI SHVS (1248cc)",
          "LDI SHVS (1248cc)",
          "VDI SHVS (1248cc)",
          "VDI SHVS (1248cc)",
          "ZDI SHVS (1248cc)",
          "ZDI SHVS (1248cc)",
          "TOUR M (1462cc)",
          "TOUR M (1462cc)",
          "LDI(O) (1248cc)",
          "SMART HYBRID LDI (O) (1248cc)",
          "LIMITED EDITION VXI (1373cc)",
          "LIMITED EDITION VXI CNG (1373cc)",
          "LIMITED EDITION VDI (1248cc)",
          "SMART HYBRID VXI (O) (1462cc)",
          "SMART HYBRID VXI CNG (1462cc)",
          "SMART HYBRID VXI 1.5L AT (1462cc)",
          "VXI CNG 1.5L 5MT (1462cc)",
          "ZXI PLUS CNG (1462cc)",
          "GREEN VXI (1373cc)",
          "1.5 SMART HYBRID LXI 5MT BSVI (1462cc)",
          "1.5 SMART HYBRID VXI 5MT BSVI (1462cc)",
          "1.5 SMART HYBRID ZXI 5MT BSVI (1462cc)",
          "1.5 SMART HYBRID VXI 6AT BSVI (1462cc)",
          "1.5 SMART HYBRID ZXI 6AT BSVI (1462cc)",
          "1.5 VXI CNG 5MT BSVI (1462cc)",
          "1.5 ZXI CNG 5MT BSVI (1462cc)",
          "1.5 SMART HYBRID ZXI(O) BSVI (1462cc)",
          "1.5 SMART HYBRID VXI(O) BSVI (1462cc)",
          "1.5 SMART HYBRID LXI(O) BSVI (1462cc)",
          "1.5 ZXI(O)CNG 5MT BSVI (1462cc)",
          "1.5 VXI(O)CNG 5MT BSVI (1462cc)",
          "VXI (O) (1373cc)",
          "LXI (1373cc)",
          "LXI (1373cc)",
          "VXI (1373cc)",
          "VXI (1373cc)",
          "ZXI (1373cc)",
          "ZXI (1373cc)",
          "SMART HYBRID LDI (1248cc)",
          "SMART HYBRID ZDI (1248cc)",
          "1.5 VDI (1498cc)",
          "1.5 ZDI (1498cc)",
          "SMART HYBRID ZXI (1462cc)",
          "SMART HYBRID VDI (1248cc)",
          "SMART HYBRID LXI (1462cc)",
          "SMART HYBRID VXI (1462cc)",
          "SMART HYBRID ZXI AT (1462cc)",
          "1.5 TOUR M PETROL (1462cc)",
          "1.5 TOUR M (O) PETROL (1462cc)",
          "1.5 TOUR M (O) CNG (1462cc)",
          "ZXI (O) SMART HYBRID (1462cc)",
          "VXI (O) SMART HYBRID (1462cc)",
          "VXI SMART HYBRID AUTOMATIC (1462cc)",
          "ZXI SMART HYBRID AUTOMATIC (1462cc)",
          "LXI SMART HYBRID (1462cc)",
          "VXI SMART HYBRID (1462cc)",
          "ZXI SMART HYBRID (1462cc)",
          "ZXI PLUS SMART HYBRID (1462cc)",
          "ZXI PLUS AUTOMATIC SMART HYBRID (1462cc)",
          "1.5 TOUR M CNG (1462cc)",
          "ZXI PLUS BS6 (1462cc)",
          "ZXI CNG BS6 (1462cc)",
          "ZXI O CNG BS6 (1462cc)",
          "SMART HYBRID LXI ( O ) MT (1462cc)",
          "1.5 LXI (1462cc)",
          "1.5 TOUR M (1462cc)",
          "1.5 VXI (1462cc)",
          "1.5 ZXI (1462cc)",
          "1.5 ZXI AT (1462cc)",
          "VXI AUTOMATIC (1462cc)",
          "LXI (1462cc)",
          "LXI (1462cc)",
          "VXI (1462cc)",
          "VXI (1462cc)",
          "ZXI (1462cc)",
          "ZXI (1462cc)",
          "VXI CNG (1462cc)",
          "VXI CNG. (1462cc)",
          "LXI O (1462cc)",
          "LXI O (1462cc)",
          "VXI AT (1462cc)",
          "VXI AT.. (1462cc)",
          "ZXI PLUS (1462cc)",
          "VXI CNG BS-VI (1462cc)",
          "VXI CNG BS-VI (1462cc)",
          "ZXI CNG (1462cc)",
          "ZXI CNG (1462cc)",
          "ZXI PLUS AT (1462cc)",
          "ZXI PLUS AT (1462cc)",
          "ZXI AT (1462cc)",
          "ZXI AT (1462cc)",
          "LXI BS-VI (1462cc)",
          "LXI BS-VI (1462cc)",
          "VXI BS-VI (1462cc)",
          "VXI BSVI (1462cc)",
          "ZXI BS-VI (1462cc)",
          "ZXI BS-VI (1462cc)",
          "VXI AT BS-VI (1462cc)",
          "VXI AT BS-VI (1462cc)",
          "ZXI PLUS BS-VI (1462cc)",
          "ZXI PLUS BS-VI (1462cc)",
          "ZXI AT BS-VI (1462cc)",
          "ZXI AT BS-VI (1462cc)",
          "TOUR M CNG (1462cc)",
          "TOUR M CNG. (1462cc)",
          "VXI O (1462cc)",
          "VXI O (1462cc)",
          "VXI CNG O (1462cc)",
          "VXI CNG O (1462cc)",
          "ZXI O (1462cc)",
          "ZXI O (1462cc)",
          "ZXI CNG O (1462cc)",
          "ZXI CNG O (1462cc)",
          "TOUR M CNG O (1462cc)",
          "TOUR M CNG O (1462cc)",
          "VXI AT FACELIFT (1462cc)",
          "VXI AT FACELIFT (1462cc)",
          "ZXI AT FACELIFT (1462cc)",
          "ZXI AT FACELIFT (1462cc)",
          "VXI 1.5L 5MT BS6 (1462cc)",
          "VXI (O) CNG 1.5L 5MT (1462cc)",
          "SMART HYBRID ZXI PLUS AT (1462cc)",
          "SMART HYBRID VXI 1.5L 4MT (1462cc)",
          "SMART HYBRID VXI 1.5L 4AT (1462cc)",
          "LXi (1462cc)",
          "1.5 TOUR M (O) BSVI (1462cc)",
          "1.5 ZXI (O) CNG (1462cc)",
          "1.5 ZXI CNG (1462cc)",
          "1.5 VXI CNG (1462cc)",
          "TOUR M PETROL (1462cc)",
          "1.5 ZXI (O) (1462cc)",
          "1.5 VXI (O) (1462cc)",
          "1.5 LXI (O) (1462cc)",
          "1.5 VXI (O) CNG (1462cc)",
          "1.5 ZDI PLUS (1462cc)",
          "1.5 ZXI PLUS (1462cc)",
          "1.5 ZXI PLUS AT (1462cc)",
          "SMART HYBRID VXI AT (1462cc)",
          "SMART HYBRID ZXI PLUS (1462cc)",
          "LDI (O) SMART HYBRID (1248cc)",
          "LDI SMART HYBRID (1248cc)",
          "VDI SMART HYBRID (1248cc)",
          "ZDI SMART HYBRID (1248cc)",
          "ZDI PLUS SMART HYBRID (1248cc)",
          "SMART HYBRID LDI OPTION (1248cc)",
          "ZDI 5 STR (1248cc)",
          "VDI 5 STR (1248cc)",
          "LDI SHVS O (1248cc)",
          "LDI SHVS O (1248cc)",
          "VDI SHVS (LTD) (1248cc)",
          "VDI SHVS (LTD) (1248cc)",
          "ZDI PLUS SHVS (1248cc)",
          "ZDI PLUS SHVS (1248cc)",
          "PASEO VDI (1248cc)",
          "PASEO VDI (1248cc)",
          "HYBRID ZDI PLUS (1248cc)",
          "HYBRID ZDI (1248cc)",
          "SMART HYBRID Z (1248cc)",
          "VDI LIMITED EDITION (1248cc)",
          "ZDI PLUS (1248cc)",
          "LDI (O) SHVS (1248cc)",
          "SMART HYBRID ZDI PLUS (1248cc)",
          "LXI OPTION (1373cc)",
          "GREEN LXI M BS4 (1373cc)",
          "LX O (1373cc)",
          "VXI 5 STR (1373cc)",
          "LXI CNG (1373cc)",
          "LXI CNG (1373cc)",
          "VXI CNG (1373cc)",
          "VXI CNG (1373cc)",
          "ZXI PLUS (1373cc)",
          "ZXI PLUS (1373cc)",
          "VXI (LTD) (1373cc)",
          "VXI (LTD) (1373cc)",
          "PASEO VXI (1373cc)",
          "PASEO VXI (1373cc)",
          "ZXI BSIV (1373cc)",
          "VXI GREEN (1373cc)",
          "VXI AT STR 7 (1373cc)",
          "VXI BSVI (1373cc)",
          "VXI LIMITED EDITION (1373cc)",
          "VDI 1.5 DIESEL (1498cc)",
          "ZDI 1.5 DIESEL (1498cc)",
          "VDI 1.5 (1498cc)",
          "VDI 1.5 (1498cc)",
          "ZDI 1.5 (1498cc)",
          "ZDI 1.5 (1498cc)",
          "ZDI PLUS 1.5 (1498cc)",
          "ZDI PLUS 1.5 (1498cc)",
          "1.5 ZDI PLUS (1498cc)",
          "SMART HYBRID ZDI PLUS (1498cc)",
          "TOUR M DIESEL (1462cc)",
          "1.5 TOUR M (6+1) (1498cc)",
          "TOUR M D (1498cc)",
          "TOUR M D (1498cc)",
          "TOUR M DIESEL (1498cc)",
          "SMART HYBRID ZXI 1.5L 5MT (1373cc)",
          "ZXI 5 STR (1400cc)",
          "ZXI CNG 1.5L 5MT BS-VI (1492cc)",
        ],
      },
      {
        model: "SWIFT DZIRE",
        variants: [
          "LDI. (1248cc)",
          "LDI (1248cc)",
          "VDI (1248cc)",
          "VDI (1248cc)",
          "ZXI (1197cc)",
          "ZXI (1197cc)",
          "VDI O (1248cc)",
          "VDI O (1248cc)",
          "LXI O (1197cc)",
          "LXI O (1197cc)",
          "VXI O (1197cc)",
          "VXI O (1197cc)",
          "LDI O (1248cc)",
          "LDI O (1248cc)",
          "TOUR S CNG (1197cc)",
          "TOUR S CNG (1197cc)",
          "TOUR (1248cc)",
          "TOUR (1248cc)",
          "TOUR S PETROL (1197cc)",
          "TOUR S PETROL. (1197cc)",
          "NEW ZDI+ (1248cc)",
          "ZXI AGS (1197cc)",
          "VXI AGS (1197cc)",
          "ZDI+ AGS (1248cc)",
          "VDI AGS (1248cc)",
          "VXI BS4 (1197cc)",
          "KB VXI (1197cc)",
          "ZXI AMT (1197cc)",
          "ZXI (1298cc)",
          "ZXI (1298cc)",
          "LXI. (1197cc)",
          "LXI (1197cc)",
          "LXI (1298cc)",
          "LXI (1298cc)",
          "ZDI (1248cc)",
          "ZDI (1248cc)",
          "VXI (1298cc)",
          "VXI (1298cc)",
          "VXI. (1197cc)",
          "VXI (1197cc)",
          "ZDI AMT (1248cc)",
          "VXI (1200cc)",
          "LDI (1298cc)",
          "VDI (1298 CC) (1298cc)",
          "VXI (CC 1197) (1197cc)",
          "TOUR S (O) CNG (1197cc)",
          "TOUR LXI (1197cc)",
          "LXI OPTION (1197cc)",
          "LXI OPTIONAL (1197cc)",
          "VXI OPTION (1197cc)",
          "VXI AT OPT (1197cc)",
          "ZXI PLUS BS6 (1197cc)",
          "TOUR S PETROL O BSVI (1197cc)",
          "1.2 VXI (1197cc)",
          "VXI BS-IV (1197cc)",
          "AT (1197cc)",
          "VXI 1.2 (1197cc)",
          "VXI 1.2 BS-IV (1197cc)",
          "VXI 1.2 BS-IV (1197cc)",
          "AUTOMATIC (1197cc)",
          "AUTOMATIC (1197cc)",
          "VXI AT (1197cc)",
          "VXI AT (1197cc)",
          "REGAL LTD (1197cc)",
          "REGAL LTD (1197cc)",
          "ZXI 1.2 BS-IV (1197cc)",
          "ZXI 1.2 BS-IV (1197cc)",
          "LXI ABS (1197cc)",
          "LXI ABS (1197cc)",
          "VXI ABS (1197cc)",
          "VXI ABS (1197cc)",
          "VXI AT ABS (1197cc)",
          "VXI AT ABS (1197cc)",
          "1.2 ZXI PLUS (1197cc)",
          "1.2 ZXI PLUS (1197cc)",
          "1.2 ZXI AMT PLUS (1197cc)",
          "1.2 ZXI AMT PLUS (1197cc)",
          "VXI LIMITED EDITION (1197cc)",
          "VXI LIMITED EDITION (1197cc)",
          "TOUR P (1197cc)",
          "TOUR P (1197cc)",
          "TOUR S PETROL O (1197cc)",
          "TOUR S PETROL O (1197cc)",
          "TOUR S CNG O (1197cc)",
          "TOUR S CNG O (1197cc)",
          "VXI CNG (1197cc)",
          "VXI 1.2L LSS AGS (1197cc)",
          "ZXI CNG (1197cc)",
          "REGALIAÃ‚ (1197cc)",
          "VXI (O) (AUTOMATIC) (1197cc)",
          "LXI OPTIONAL(0) (1197cc)",
          "ZXI PLUS AMT (1197cc)",
          "VXI AMT (1197cc)",
          "TOUR S (1197cc)",
          "1.2 LXI BS IV (1197cc)",
          "ZXI PLUS AT (1197cc)",
          "ZXI AT (1197cc)",
          "1.2 VXI BS IV (1197cc)",
          "1.2 ZXI BS IV (1197cc)",
          "1.2 TOUR S STD (O) CNG (1197cc)",
          "1.2 TOUR S STD (O) PETROL (1197cc)",
          "ZXI PLUS (1197cc)",
          "VDI (O) (1197cc)",
          "TOUR LDI (1248cc)",
          "VDI OPTIONAL (1248cc)",
          "TOUR S DIESEL (1248cc)",
          "TOUR S DIESEL (1248cc)",
          "LDI BS-IV (1248cc)",
          "VDI BS-IV (1248cc)",
          "VDI BSIV (1248cc)",
          "ZDI BS-IV (1248cc)",
          "LDI ABS (1248cc)",
          "LDI ABS (1248cc)",
          "VDI ABS (1248cc)",
          "VDI ABS (1248cc)",
          "ZDI AT (1248cc)",
          "ZDI AT (1248cc)",
          "1.3 ZDI PLUS (1248cc)",
          "1.3 ZDI PLUS (1248cc)",
          "1.3 ZDI AMT PLUS (1248cc)",
          "1.3 ZDI AMT PLUS (1248cc)",
          "1.3 VDI AMT (1248cc)",
          "1.3 VDI AMT (1248cc)",
          "LDIX (1248cc)",
          "VDI AMT (1248cc)",
          "ZDI PLUS AMT (1248cc)",
          "ZDI PLUS (1248cc)",
          "TOUR S (1248cc)",
          "TOUR S CNG (1248cc)",
          "NEW VDI (O) (1248cc)",
          "TOUR DIESEL (1248cc)",
        ],
      },
      {
        model: "BALENO",
        variants: [
          "ALTURA (1590cc)",
          "ALTURA (1590cc)",
          "LXI (1590cc)",
          "LXI (1590cc)",
          "VXI (1590cc)",
          "VXI (1590cc)",
          "GLX BS-2 (1590cc)",
          "ALTURA BS-2 (1590cc)",
          "STD (1590cc)",
          "STD (1590cc)",
          "LXI BS-III (1590cc)",
          "LXI BS-III (1590cc)",
          "VXI BS-III (1590cc)",
          "VXI BS-III (1590cc)",
          "SEDAN (1590cc)",
          "1.6 GLX (1590cc)",
          "1.2 DELTA (1197cc)",
          "SIGMA 1.2 (1197cc)",
          "SIGMA 1.2 (1197cc)",
          "DELTA 1.2 (1197cc)",
          "DELTA 1.2 (1197cc)",
          "ZETA 1.2 (1197cc)",
          "ZETA 1.2 (1197cc)",
          "ALPHA 1.2 (1197cc)",
          "ALPHA 1.2 (1197cc)",
          "DELTA AT 1.2 (1197cc)",
          "DELTA AT 1.2 (1197cc)",
          "ZETA AT 1.2 (1197cc)",
          "ZETA AT 1.2 (1197cc)",
          "ALPHA 1.2 AT (1197cc)",
          "ALPHA 1.2 AT (1197cc)",
          "DELTA 1.2 DUAL JET (1197cc)",
          "ZETA 1.2 DUAL JET (1197cc)",
          "SIGMA 1.2 BS-VI (1197cc)",
          "SIGMA 1.2 BS-VI (1197cc)",
          "DELTA 1.2 BS-VI (1197cc)",
          "DELTA 1.2 BS-VI (1197cc)",
          "ZETA 1.2 BS-VI (1197cc)",
          "ZETA 1.2 BS-VI (1197cc)",
          "DELTA AT 1.2 BS-VI (1197cc)",
          "DELTA AT 1.2 BS-VI (1197cc)",
          "ALPHA 1.2 BS-VI (1197cc)",
          "ALPHA 1.2 BS-VI (1197cc)",
          "ZETA AT 1.2 BS-VI (1197cc)",
          "ZETA AT 1.2 BS-VI (1197cc)",
          "ALPHA 1.2 AT BS-VI (1197cc)",
          "ALPHA 1.2 AT BS-VI (1197cc)",
          "DELTA 1.2 DUAL JET BS-VI (1197cc)",
          "DELTA 1.2 DUAL JET BS-VI (1197cc)",
          "ZETA 1.2 DUAL JET BS-VI (1197cc)",
          "ZETA 1.2 DUAL JET BS-VI (1197cc)",
          "DELTA AGS 1.2 (1197cc)",
          "DELTA AGS 1.2 (1197cc)",
          "ZETA AGS 1.2 (1197cc)",
          "ZETA AGS 1.2 (1197cc)",
          "ALPHA AGS 1.2 (1197cc)",
          "ALPHA AGS 1.2 (1197cc)",
          "DELTA 1.2 CNG (1197cc)",
          "DELTA 1.2 CNG (1197cc)",
          "ZETA 1.2 CNG (1197cc)",
          "ZETA 1.2 CNG (1197cc)",
          "1.2 CVT DELTA (1197cc)",
          "1.2 SIGMA (1197cc)",
          "1.2 ALPHA (1197cc)",
          "1.2 ALPHA (1197cc)",
          "1.2 ZETA (1197cc)",
          "CVT ZETA AT (1197cc)",
          "1.2 ALPHA CVT (1197cc)",
          "DELTA 1.2 BS6 (1197cc)",
          "1.2L ZETA CNG 5 MT (1197cc)",
          "ALPHA PETROL (1197cc)",
          "ALPHA PETROL (1197cc)",
          "1.2 ZETA AGS (1197cc)",
          "1.2 ALPHA AGS (1197cc)",
          "DELTA BSIV (1197cc)",
          "ALPHA MT (1197cc)",
          "ZETA BS-IV (1197cc)",
          "1.2 ZETA CNG MT (1197cc)",
          "1.2 ZETA CNG (1197cc)",
          "DELTA CNG 1.2 L 5MT (1197cc)",
          "ALPHA 1.2L ISS AGS (1197cc)",
          "1.2 VXI CVT (1197cc)",
          "1.2 DELTA CNG BSVI (1197cc)",
          "1.2 ZETA CNG BSVI (1197cc)",
          "DELTA PETROL (1197cc)",
          "ZETA PETROL (1197cc)",
          "SIGMA PETROL (1197cc)",
          "VXI CVT (1197cc)",
          "DELTA AT (1197cc)",
          "ZETA (1197cc)",
          "DELTA (1197cc)",
          "RS 1.0 (998cc)",
          "RS 1.0 (998cc)",
          "RS (998cc)",
          "RS (998cc)",
          "SIGMA 1.3 (1248cc)",
          "SIGMA 1.3 (1248cc)",
          "DELTA 1.3 (1248cc)",
          "DELTA 1.3 (1248cc)",
          "ZETA 1.3 (1248cc)",
          "ZETA 1.3 (1248cc)",
          "ALPHA 1.3 (1248cc)",
          "ALPHA 1.3 (1248cc)",
          "1.3 SIGMA (1248cc)",
          "1.3 ZETA (1248cc)",
          "1.3 DELTA (1248cc)",
          "1.3 ALPHA (1248cc)",
          "ALPHA 1.3 AT (1248cc)",
          "SIGMA (1248cc)",
          "ALPHA (1248cc)",
          "ZETA (1248cc)",
          "DELTA (1248cc)",
          "DELTA PETROL (1197cc)",
          "5 STR (1495cc)",
          "LXI 5STR (1298cc)",
        ],
      },
      {
        model: "GYPSY",
        variants: [
          "1 (970cc)",
          "1.0 4STR (970cc)",
          "1.0 5STR (970cc)",
          "1.0 6STR (970cc)",
          "6STR (1298cc)",
          "1.3 SOFT TOP (1298cc)",
          "1.3 HARD TOP (1298cc)",
          "KING MPI BSIV (1298cc)",
          "HT (1298cc)",
          "KING ST MPI (1298cc)",
          "1.3 SOFT TOP 7 SEAT (1298cc)",
          "1.3 SOFT TOP 6 SEAT (1298cc)",
          "1.3 SOFT TOP 5 SEAT (1298cc)",
          "W410 4X4 (1298cc)",
          "AMBULANCE (1298cc)",
          "KING HARD TOP AMBULANCE (1298cc)",
          "KING (1298cc)",
          "KING (1298cc)",
          "KING HARD TOP MPI BSIV (1298cc)",
          "5 STR. (1298cc)",
          "7 STR (1298cc)",
          "KING HT (1298cc)",
          "KING HT (1298cc)",
          "KING SOFT TOP MPI BSIV (1298cc)",
          "KING ST (1298cc)",
          "KING ST (1298cc)",
          "MG410W HT (1298cc)",
          "MG410W ST (1298cc)",
          "KING HT BS-II (1298cc)",
          "KING HT BS-III (1298cc)",
          "KING HT - BS III (1298cc)",
          "KING HT BS-IV (1298cc)",
          "KING HT BS-IV (1298cc)",
          "KING ST BS-II (1298cc)",
          "KING ST BS-III (1298cc)",
          "KING ST - BS III (1298cc)",
          "KING ST BS-IV (1298cc)",
          "KING ST BS-IV (1298cc)",
          "KING SOFT TOP (1298cc)",
          "GYPSY (1298cc)",
          "1.3 (1298cc)",
          "KING HARD TOP (1298cc)",
          "ST (1525cc)",
          "1.0  (6 SEATER) (970cc)",
          "5 SEATER (970cc)",
          "8 SEATER (970cc)",
          "8 STR (970cc)",
          "MG410W HT (970cc)",
          "MG410W HT (970cc)",
          "MG410W ST (970cc)",
          "MG410W ST (970cc)",
          "STD (970cc)",
          "MPI BSIV (SOFT TOP) (M) (970cc)",
          "GYPSY 1.0 (970cc)",
          "1.0  (4 SEATER) (970cc)",
          "1.0  (5 SEATER) (970cc)",
          "KING MPI BSIV (1286cc)",
          "ST (990cc)",
        ],
      },
      {
        model: "SX4",
        variants: [
          "VXI (1586cc)",
          "VXI (1586cc)",
          "ZXI (1586cc)",
          "ZXI (1586cc)",
          "ZXI O (1586cc)",
          "ZXI O (1586cc)",
          "ZDI LEATHER (1248cc)",
          "ZDI LEATHER (1248cc)",
          "ZDI O (1248cc)",
          "ZDI (O) (1248cc)",
          "CELEBRATION EDITION (1586cc)",
          "CELEBRATION EDITION (1586cc)",
          "CELEBRATION EDITION (1248cc)",
          "ZXI AT (1586cc)",
          "ZXI LEATHER (AT) (1586cc)",
          "SX4 AX (1586cc)",
          "ZXI LIMITED EDITION (1586cc)",
          "BASE (1586cc)",
          "VXI LIMITED EDITION (1586cc)",
          "ZXI LEATHER (1586cc)",
          "VDI (1248cc)",
          "VDI (1248cc)",
          "ZDI (1248cc)",
          "ZDI (1248cc)",
          "1.6 VXI (1586cc)",
          "1.6 ZXI (1586cc)",
          "ZXI AUTOMATIC TRANSMISSION LEATHER (1586cc)",
          "VXI GREEN (CNG) (1586cc)",
          "CELEBRATION (1586cc)",
          "LXI (1586cc)",
          "ZXI OPTION (1586cc)",
          "VXI CNG (1586cc)",
          "VXI CNG (1586cc)",
          "ZXI AT BS-IV (1586cc)",
          "ZXI AT BS-IV (1586cc)",
          "ZXI AT LEATHER BS-IV (1586cc)",
          "ZXI AT LEATHER BS-IV (1586cc)",
          "ZXI MT BS-IV (1586cc)",
          "ZXI MT BS-IV (1586cc)",
          "ZXI MT LEATHER BS-IV (1586cc)",
          "ZXI MT LEATHER BS-IV (1586cc)",
          "CELEBRATION PETROL (1586cc)",
          "CELEBRATION PETROL (1586cc)",
          "VXI BS-IV (1586cc)",
          "VXI (BSIV) (1586cc)",
          "VXI CNG BS-IV (1586cc)",
          "ZXI LEATHER O (1586cc)",
          "ZXI LEATHER O (1586cc)",
          "CELEBRATION EDITION - GASOLINE (1586cc)",
          "CELEBRATION EDITION - GASOLINE (1586cc)",
          "AUTOMATIC (1586cc)",
          "AUTOMATIC (1586cc)",
          "ZXI BS-IV (1586cc)",
          "ZXI AT TRANSMISSION LEATHER (1586cc)",
          "ZXI AUTOMATIC TRANSMISSION (1586cc)",
          "CELEBRATION EDITION (PETROL) (1586cc)",
          "ZXI (LEATHER OPTION) (1586cc)",
          "AX (1586cc)",
          "GREEN VXI (1586cc)",
          "ZDI OPTION (1248cc)",
          "CELEBRATION DIESEL (1248cc)",
          "CELEBRATION DIESEL (1248cc)",
          "CELEBRATION EDITION (DIESEL) (1248cc)",
          "ZXI AT BS4 (1022cc)",
          "GREEN VXI (1275cc)",
        ],
      },
      {
        model: "CIAZ",
        variants: [
          "VXI (1373cc)",
          "VXI (1373cc)",
          "VXI PLUS (1373cc)",
          "VXI PLUS (1373cc)",
          "ZXI (1373cc)",
          "ZXI (1373cc)",
          "ZXI O (1373cc)",
          "ZXI O (1373cc)",
          "ZXI AT (1373cc)",
          "ZXI AT (1373cc)",
          "VXI O (1373cc)",
          "VXI O (1373cc)",
          "ZXI PLUS (1373cc)",
          "ZXI PLUS (1373cc)",
          "ZXI PLUS AT (1373cc)",
          "ZXI PLUS AT (1373cc)",
          "VXI PLUS AT (1373cc)",
          "VXI PLUS AT (1373cc)",
          "VDI (1248cc)",
          "VDI (1248cc)",
          "VDI PLUS (1248cc)",
          "VDI PLUS (1248cc)",
          "ZDI (1248cc)",
          "ZDI (1248cc)",
          "ZDI O (1248cc)",
          "ZDI O (1248cc)",
          "ZDI PLUS (1248cc)",
          "ZDI PLUS (1248cc)",
          "ZETA (1373cc)",
          "1.4 ALPHA (1373cc)",
          "ALPHA SMART (1373cc)",
          "SMART HYBRID VDI (1248cc)",
          "SMART HYBRID VDI(O) (1248cc)",
          "SMART HYBRID ZDI (1248cc)",
          "DIESEL SHVS RS (1248cc)",
          "RS (1373cc)",
          "ALPHA SMART HYBRID (1462cc)",
          "ALPHA SMART HYBRID (1248cc)",
          "DELTA SMART HYBRID (1462cc)",
          "DELTA SMART HYBRID (1248cc)",
          "ZETA SMART HYBRID (1462cc)",
          "ZETA SMART HYBRID (1248cc)",
          "ALPHA SMART HYBRID (AT) (1462cc)",
          "DELTA SMART HYBRID (AT) (1462cc)",
          "ZETA SMART HYBRID (AT) (1462cc)",
          "ZETA (AT) (1373cc)",
          "ALPHA (AT) (1373cc)",
          "ALPHA (1373cc)",
          "DELTA (AT) (1373cc)",
          "DELTA (1373cc)",
          "SIGMA (1373cc)",
          "ZETA DDIS 225 (1498cc)",
          "ALPHA DDIS 225 (1498cc)",
          "DELTA DDIS 225 (1498cc)",
          "S (1373cc)",
          "S SMART HYBRID (1248cc)",
          "SIGMA SMART HYBRID (1462cc)",
          "SIGMA SMART HYBRID (1248cc)",
          "VXI+ AT (1373cc)",
          "SMART HYBRID AUTOMATIC DELTA (1462cc)",
          "1.5 SMART HYBRID ZETA AT PETROL (1462cc)",
          "1.5 SMART HYBRID SIGMA PETROL (1462cc)",
          "1.5 SMART HYBRID ZETA PETROL (1462cc)",
          "1.5 SMART HYBRID ALPHA PETROL (1462cc)",
          "1.5 ALPHA AUTOMATIC PETROL (1462cc)",
          "1.5 ALPHA PETROL (1462cc)",
          "1.5 DELTA AUTOMATIC PETROL (1462cc)",
          "1.5 DELTA PETROL (1462cc)",
          "1.5 SIGMA PETROL (1462cc)",
          "1.5 ZETA AUTOMATIC PETROL (1462cc)",
          "1.5 ZETA PETROL (1462cc)",
          "1.5 SMART HYBRID S PETROL (1462cc)",
          "SMART HYBRID AUTOMATIC ALPHA PETROL (1462cc)",
          "1.5L SIGMA SMART HYBRID (1462cc)",
          "1.5 ALPHA AT (1462cc)",
          "ALPHA AT BS6 (1462cc)",
          "ALPHA BS6 (1462cc)",
          "ALPHA 1.5 MT (1462cc)",
          "ALPHA 1.5 MT (1462cc)",
          "ALPHA 1.5 AT (1462cc)",
          "ALPHA 1.5 AT (1462cc)",
          "DELTA 1.5 MT (1462cc)",
          "DELTA 1.5 MT (1462cc)",
          "DELTA 1.5 AT (1462cc)",
          "DELTA 1.5 AT (1462cc)",
          "SIGMA 1.5 MT (1462cc)",
          "SIGMA 1.5 MT (1462cc)",
          "ZETA 1.5 MT (1462cc)",
          "ZETA 1.5 MT (1462cc)",
          "ZETA 1.5 AT (1462cc)",
          "ZETA 1.5 AT (1462cc)",
          "S 1.5 MT (1462cc)",
          "S 1.5 MT (1462cc)",
          "SMART HYBRID ALPHA 1.5L AT (1462cc)",
          "ALPHA 1.5 AT DUAL TONE (1462cc)",
          "ALPHA 1.5 MT DUAL TONE (1462cc)",
          "SMART HYBRID DELTA PETROL (1462cc)",
          "SMART HYBRID SIGMA PETROL (1462cc)",
          "SMART HYBRID DELTA AT (P) (1462cc)",
          "SMART HYBRID ALPHA PETROL (1462cc)",
          "SMART HYBRID ZETA PETROL (1462cc)",
          "1.5 S MT (1462cc)",
          "SMART HYBRID ALPHA AT (1462cc)",
          "SMART HYBRID ZETA AT (P) (1462cc)",
          "SMART HYBRID DELTA (1462cc)",
          "SMART HYBRID ALPHA (1462cc)",
          "SMART HYBRID ZETA (1462cc)",
          "1.5 ALPHA DDIS 225 DIESEL (1498cc)",
          "1.5 DELTA DDIS 225 DIESEL (1498cc)",
          "1.5 ZETA DDIS 225 DIESEL (1498cc)",
          "ALPHA 1.5 DIESEL (1498cc)",
          "ALPHA 1.5 DIESEL (1498cc)",
          "DELTA 1.5 DIESEL (1498cc)",
          "DELTA 1.5 DIESEL (1498cc)",
          "ZETA 1.5 DIESEL (1498cc)",
          "ZETA 1.5 DIESEL (1498cc)",
          "DELTA AUTOMATIC PETROL (1373cc)",
          "ALPHA PETROL (1373cc)",
          "ZETA  AUTOMATIC PETROL (1373cc)",
          "ALPHA AUTOMATIC PETROL (1373cc)",
          "RS PETROL (1373cc)",
          "SIGMA PETROL (1373cc)",
          "DELTA PETROL (1373cc)",
          "ZETA PETROL (1373cc)",
          "ZXI OPTION (1373cc)",
          "VXI OPTION (1373cc)",
          "ZXI RS PLUS (1373cc)",
          "SIGMA 1.4 MT (1373cc)",
          "SIGMA 1.4 MT (1373cc)",
          "DELTA 1.4 MT (1373cc)",
          "DELTA 1.4 MT (1373cc)",
          "ZETA 1.4 MT (1373cc)",
          "ZETA 1.4 MT (1373cc)",
          "DELTA 1.4 AT (1373cc)",
          "DELTA 1.4 AT (1373cc)",
          "ALPHA 1.4 MT (1373cc)",
          "ALPHA 1.4 MT (1373cc)",
          "ZETA 1.4 AT (1373cc)",
          "ZETA 1.4 AT (1373cc)",
          "ALPHA 1.4 AT (1373cc)",
          "ALPHA 1.4 AT (1373cc)",
          "ZXI PLUS RS (1373cc)",
          "ZXI PLUS RS (1373cc)",
          "S 1.4 MT (1373cc)",
          "S 1.4 MT (1373cc)",
          "PLUS RS (1373cc)",
          "S 5STR (1373cc)",
          "AT VXI PLUS (1373cc)",
          "AT ZXI (1373cc)",
          "S PETROL (1373cc)",
          "ZXI + RS (1373cc)",
          "SMART HYBRID SIGMA DIESEL (1248cc)",
          "SMART HYBRID DELTA DIESEL (1248cc)",
          "SMART HYBRID ZETA DIESEL (1248cc)",
          "SMART HYBRID ALPHA DIESEL (1248cc)",
          "ISG VDI (1248cc)",
          "ISG VDI (O) (1248cc)",
          "ISG VD PLUS (1248cc)",
          "ISG ZDI (1248cc)",
          "ISG ZDI PLUS (1248cc)",
          "SHVS VDI (1248cc)",
          "SHVS VDI PLUS (1248cc)",
          "SHVS ZDI (1248cc)",
          "SHVS ZDI PLUS (1248cc)",
          "SHVS VDI (O) (1248cc)",
          "SHVS RS DIESEL (1248cc)",
          "ZDI OPTION (1248cc)",
          "VDI OPTION SHVS (1248cc)",
          "VDI SHVS (1248cc)",
          "VDI SHVS (1248cc)",
          "VDI O SHVS (1248cc)",
          "VDI O SHVS (1248cc)",
          "ZDI SHVS (1248cc)",
          "ZDI SHVS (1248cc)",
          "VDI PLUS SHVS (1248cc)",
          "VDI PLUS SHVS (1248cc)",
          "ZDI PLUS SHVS (1248cc)",
          "ZDI PLUS SHVS (1248cc)",
          "ZDI PLUS SHVS RS (1248cc)",
          "ZDI PLUS SHVS RS (1248cc)",
          "SIGMA 1.3 HYBRID (1248cc)",
          "SIGMA 1.3 HYBRID (1248cc)",
          "DELTA 1.3 HYBRID (1248cc)",
          "DELTA 1.3 HYBRID (1248cc)",
          "ZETA 1.3 HYBRID (1248cc)",
          "ZETA 1.3 HYBRID (1248cc)",
          "ALPHA 1.3 HYBRID (1248cc)",
          "ALPHA 1.3 HYBRID (1248cc)",
          "S 1.3 HYBRID (1248cc)",
          "S 1.3 HYBRID (1248cc)",
          "SMART HYBRID DELTA BSIV (1248cc)",
          "ZETA (1248cc)",
          "RS 5STR (1248cc)",
          "S (1248cc)",
          "SMART HYBRID SIGMA (1248cc)",
          "SMART HYBRID DELTA (1248cc)",
          "SMART HYBRID ALPHA (1248cc)",
          "SMART HYBRID ZETA (1248cc)",
          "VDI (O) (1248cc)",
          "AT VXI PLUS (1373CC) (796cc)",
          "VXI AT (1248cc)",
        ],
      },
      {
        model: "ESTEEM",
        variants: [
          "LX (1298cc)",
          "LX (1298cc)",
          "AX (AT) (1298cc)",
          "STD (1527cc)",
          "DI WITH POWER STEERING (1527cc)",
          "VXI BS3 (1298cc)",
          "MPT STANDARD (1298cc)",
          "LXI (1298cc)",
          "LXI (1298cc)",
          "VXI (1298cc)",
          "VXI (1298cc)",
          "VX (1298cc)",
          "VX (1298cc)",
          "D-NEW (1527cc)",
          "DI BS-2 (1527cc)",
          "DIESEL (DI-WITH POWER STEERING) (1527cc)",
          "D (1527cc)",
          "D (1527cc)",
          "DI (1527cc)",
          "DI (1527cc)",
          "DIESEL (1527cc)",
          "STANDARD (1527cc)",
          "ESTEEM DI (1527cc)",
          "ESTEEM D (1527cc)",
          "VELOCITY BS-2 (1298cc)",
          "AX (1298cc)",
          "AX (1298cc)",
          "AX BS-II (1298cc)",
          "AX BS-II (1298cc)",
          "LX BS-II (1298cc)",
          "LX BS-III (1298cc)",
          "LX BS-III (1298cc)",
          "LXI BS-II (1298cc)",
          "LXI BS-III (1298cc)",
          "LXI BS-III (1298cc)",
          "VX BS-II (1298cc)",
          "VX BS-II (1298cc)",
          "VXI BS-II (1298cc)",
          "VXI BS-III (1298cc)",
          "VXI BS-III (1298cc)",
          "DI (1298cc)",
          "D (1298cc)",
          "VXI CNG (1298cc)",
          "AX  (AUTOMATIC TRANSMISSION) (1298cc)",
          "STANDARD (1298cc)",
          "ESTEEM AX (1298cc)",
          "LX BS-2 (1298cc)",
        ],
      },
      {
        model: "ALTO 800",
        variants: [
          "LXI AIRBAG (796cc)",
          "LXI AIRBAG (796cc)",
          "VXI (796cc)",
          "VXI (796cc)",
          "VXI AIRBAG (796cc)",
          "VXI AIRBAG (796cc)",
          "LXI ANNIVERSARY EDITION (796cc)",
          "LXI ANNIVERSARY EDITION (796cc)",
          "STD O (796cc)",
          "STD (O) (796cc)",
          "LX O (796cc)",
          "LX O (796cc)",
          "LXI O (796cc)",
          "LXI O (796cc)",
          "VXI O (796cc)",
          "VXI O (796cc)",
          "TOUR H1 (796cc)",
          "TOUR H1 (796cc)",
          "LX (SE) (796cc)",
          "LXI (SE) (796cc)",
          "STD (SE) (796cc)",
          "VXI (SE) (796cc)",
          "STD (O) BSVI (796cc)",
          "LXI (O) BSVI (796cc)",
          "VXI BSVI (796cc)",
          "VXI PLUS BSVI (796cc)",
          "LXI (O) S CNG BSVI (796cc)",
          "STD BSVI (796cc)",
          "VXI PLUS (796cc)",
          "VXI ABS (796cc)",
          "GREEN LXI (796cc)",
          "STD (796cc)",
          "STD (796cc)",
          "LXI (796cc)",
          "LXI (796cc)",
          "LX (796cc)",
          "LX (796cc)",
          "STANDARD (796cc)",
          "LXI OPTIONAL (796cc)",
          "GREEN LXI BS IV 4 STR (796cc)",
          "GREEN LXI BS IV (796cc)",
          "LXI CNG BSVI (796cc)",
          "STD CNG (796cc)",
          "STD CNG (796cc)",
          "LX CNG (796cc)",
          "LX CNG (796cc)",
          "LXI CNG (796cc)",
          "LXI CNG (796cc)",
          "LXI CNG O (796cc)",
          "LXI CNG O (796cc)",
          "LXI CNG AIRBAG (796cc)",
          "LXI CNG AIRBAG (796cc)",
          "LXI CNG (4 STR) (796cc)",
          "LXI 4 STR (796cc)",
          "LX 4STR (796cc)",
          "STD 4 STR (796cc)",
          "LXI ABS (796cc)",
          "800 LXI WITH AIRBAGS CNG (796cc)",
          "STD CNG (4 STR) (796cc)",
          "LX CNG (4 STR) (796cc)",
          "LXI (O) CNG (796cc)",
          "DX 5 STR (796cc)",
          "DX 4 STR (796cc)",
          "CBU PETROL (1248cc)",
          "CBU DIESEL (1248cc)",
          "SUZUKI CBU (1248cc)",
          "LXI 4 SEATER (796cc)",
          "TOUR H1 (O) (796cc)",
          "VXI OPTION (796cc)",
          "LX OPTIONAL (796cc)",
          "STD OPTIONAL (796cc)",
          "UTSAV EDITION (796cc)",
          "VXI BSIV 4 STR (796cc)",
          "LX AIRBAG (796cc)",
          "STD AIRBAG (796cc)",
          "DX (796cc)",
          "800 DX (4 SEATER) (796cc)",
          "P10 DX (796cc)",
        ],
      },
      {
        model: "A-STAR",
        variants: [
          "ZXI AIRBAG (ABS) (998cc)",
          "ZXI CNG (998cc)",
          "LXI (998cc)",
          "LXI (998cc)",
          "VXI (998cc)",
          "VXI (998cc)",
          "ZXI (998cc)",
          "ZXI (998cc)",
          "AT (998cc)",
          "AUTOMATIC TRANSMISSION (998cc)",
          "AUTOMATIC (998cc)",
          "AUTOMATIC (998cc)",
          "VXI ABS AT (998cc)",
          "VXI ABS AT (998cc)",
          "ZXI O (998cc)",
          "ZXI O (998cc)",
          "ZXI ABS (998cc)",
          "ZXI ABS (998cc)",
          "VXI AKTIV (998cc)",
          "VXI AKTIV (998cc)",
          "AKTIV (998cc)",
          "AKTIV (998cc)",
          "ZXI ABS AIR BAG (998cc)",
          "VXI AT (998cc)",
          "VXI AT (998cc)",
          "PIXO DX6 (998cc)",
        ],
      },
      {
        model: "ALTO K10",
        variants: [
          "VXI AGS (998cc)",
          "VXI AGS (998cc)",
          "VXI O (998cc)",
          "VXI O (998cc)",
          "LX (998cc)",
          "LX (998cc)",
          "LXI O (998cc)",
          "LXI O (998cc)",
          "LX O (998cc)",
          "LX O (998cc)",
          "VXI AIRBAG (ABS) (998cc)",
          "VXI AGS (O) (998cc)",
          "LXI AMT (998cc)",
          "VXI O AMT (998cc)",
          "STD BSVI (998cc)",
          "LXI BSVI (998cc)",
          "VXI BSVI (998cc)",
          "VXI PLUS BSVI (998cc)",
          "VXI AGS BSVI (998cc)",
          "VXI PLUS AGS BSVI (998cc)",
          "VXI S CNG BSVI (998cc)",
          "LXI (998cc)",
          "LXI (998cc)",
          "VXI (998cc)",
          "VXI (998cc)",
          "LXI OPTIONAL (998cc)",
          "LXI CNG (998cc)",
          "LXI (AIRBAG) (998cc)",
          "LXI CNG O (998cc)",
          "VXI CNG (998cc)",
          "VXI AMT O (998cc)",
          "VXI OPTION (998cc)",
          "LX OPTIONAL (998cc)",
          "PLUS EDITION (998cc)",
          "VXI AGS OPTIONAL (998cc)",
          "VXI OPTIONAL AT (998cc)",
          "VXI OPTIONAL (998cc)",
          "VXI+ MT (998cc)",
          "VXI CNG 1L 5MT BS6 (998cc)",
          "VXI+ 1L AGS (998cc)",
          "TOUR H1 MT (998cc)",
          "LXI CNG (998cc)",
          "VXI AMT (998cc)",
          "VXI AMT (998cc)",
          "VXI PLUS (998cc)",
          "VXI PLUS (998cc)",
          "VXI PLUS AGS (998cc)",
          "VXI PLUS AGS (998cc)",
          "LXI CNG AIRBAG (998cc)",
          "LXI CNG AIRBAG (998cc)",
          "LXI AIRBAG (998cc)",
          "VXI AMT AIRBAG (998cc)",
          "VXI AMT (AIRBAG) (998cc)",
          "LX AIR BAG (998cc)",
          "LX AIR BAG (998cc)",
          "K 10 KNIGHTRACER (998cc)",
          "K 10 KNIGHTRACER (998cc)",
          "VXI AMT O (998cc)",
          "LXI XPLORE (998cc)",
          "LXI XPLORE (998cc)",
          "STANDARD (998cc)",
          "STANDARD (998cc)",
          "VXI S-CNG (998cc)",
          "VXI S-CNG (998cc)",
          "STD (998cc)",
          "VXI AT (998cc)",
        ],
      },
      {
        model: "IGNIS",
        variants: [
          "SIGMA 1.2 MT (1197cc)",
          "SIGMA 1.2 MT (1197cc)",
          "DELTA 1.2 MT (1197cc)",
          "DELTA 1.2 MT (1197cc)",
          "DELTA 1.2 AT (1197cc)",
          "DELTA 1.2 AT (1197cc)",
          "ALPHA 1.2 MT (1197cc)",
          "ALPHA 1.2 MT (1197cc)",
          "DELTA 1.3 MT (1248cc)",
          "DELTA 1.3 MT (1248cc)",
          "DELTA 1.3 AT (1248cc)",
          "DELTA 1.3 AT (1248cc)",
          "ALPHA 1.3 MT (1248cc)",
          "ALPHA 1.3 MT (1248cc)",
          "1.2 ZETA (AT) (1197cc)",
          "1.2 ZETA (MT) (1197cc)",
          "1.3 ZETA (AT) (1248cc)",
          "1.3 ZETA (MT) (1248cc)",
          "1.2 ZETA BSVI (1197cc)",
          "1.2 ALPHA BSVI (1197cc)",
          "1.2 ZETA BSVI (AMT) (1197cc)",
          "1.2 ALPHA BSVI (AMT) (1197cc)",
          "1.2 ALPHA BSVI (AMT)(SE) (1197cc)",
          "ZETA (D) (AMT) (1248cc)",
          "ALPHA (AMT) (1248cc)",
          "ALPHA (D) (AMT) (1248cc)",
          "ZETA (D) (1248cc)",
          "ALPHA (D) (1248cc)",
          "1.2 DELTA BSVI (MT) (1197cc)",
          "1.2 SIGMA BSVI (1197cc)",
          "1.2 DELTA BSVI (AMT) (1197cc)",
          "1.2 ZETA (1197cc)",
          "ZETA 1.2 (1197cc)",
          "ZETA 1.2 MT (1197cc)",
          "ZETA 1.2 MT (1197cc)",
          "ZETA 1.2 MT DUAL TONE (1197cc)",
          "ZETA 1.2 MT DUAL TONE (1197cc)",
          "ZETA AGS (1197cc)",
          "ZETA PETROL (1197cc)",
          "SIGMA 1.3 MT (1248cc)",
          "SIGMA 1.3 MT (1248cc)",
          "1.2 ALPHA (1197cc)",
          "1.2 AMT ALPHA (1197cc)",
          "1.2 SIGMA (1197cc)",
          "1.2 DELTA (1197cc)",
          "1.2 AMT DELTA (1197cc)",
          "1.2 AMT ZETA (1197cc)",
          "DELTA 1.2 (1197cc)",
          "SIGMA 1.2 (1197cc)",
          "ALPHA 1.2 (1197cc)",
          "ALPHA 1.2 AMT (1197cc)",
          "ZETA 1.2 AT (1197cc)",
          "ZETA 1.2 AT (1197cc)",
          "ALPHA 1.2 AT (1197cc)",
          "ALPHA 1.2 AT (1197cc)",
          "ZETA 1.2 AMT DUAL TONE (1197cc)",
          "ZETA 1.2 AMT DUAL TONE (1197cc)",
          "ALPHA 1.2 MT DUAL TONE (1197cc)",
          "ALPHA 1.2 MT DUAL TONE (1197cc)",
          "ALPHA 1.2 AMT DUAL TONE (1197cc)",
          "ALPHA 1.2 AMT DUAL TONE (1197cc)",
          "DELTA 1.2 AMT (1197cc)",
          "ALPHA AGS (1197cc)",
          "ALPHA PETROL (1197cc)",
          "ZETA PETROL AMT (1197cc)",
          "DELTA PETROL AMT (1197cc)",
          "SIGMA PETROL (1197cc)",
          "DELTA PETROL (1197cc)",
          "ALPHA PETROL AMT (1197cc)",
          "1.3 AMT ALPHA (1248cc)",
          "1.3 DELTA (1248cc)",
          "1.3 ZETA (1248cc)",
          "1.3 AMT DELTA (1248cc)",
          "1.3 AMT ZETA (1248cc)",
          "1.3 ALPHA (1248cc)",
          "ALPHA 1.3 (1248cc)",
          "DELTA 1.3 (1248cc)",
          "ZETA 1.3 (1248cc)",
          "ALPHA 1.3 AMT (1248cc)",
          "ZETA 1.3 MT (1248cc)",
          "ZETA 1.3 MT (1248cc)",
          "ZETA 1.3 AT (1248cc)",
          "ZETA 1.3 AT (1248cc)",
          "ALPHA 1.3 AT (1248cc)",
          "ALPHA 1.3 AT (1248cc)",
          "ZETA DIESEL AMT (1248cc)",
          "ZETA DIESEL (1248cc)",
          "DELTA DIESEL AMT (1248cc)",
          "ALPHA DIESEL (1248cc)",
          "DELTA DIESEL (1248cc)",
        ],
      },
      {
        model: "XL6",
        variants: [
          "SMART HYBRID ALPHA (MT) BSVI (1462cc)",
          "SMART HYBRID ALPHA (AT) BSVI (1462cc)",
          "SMART HYBRID ZETA (MT) BSVI (1462cc)",
          "SMART HYBRID ZETA (AT) BSVI (1462cc)",
          "SMART HYBRID ALPHA PLUS MT (1462cc)",
          "SMART HYBRID ALPHA PLUS MT(DT) (1462cc)",
          "SMART HYBRID ALPHA PLUS (AT) (1462cc)",
          "SMART HYBRID ALPHA PLUS AT(DT) (1462cc)",
          "SMART HYBRID ZETA 5MT CNG (1462cc)",
          "1.5 ZETA SMART HYBRID AT (1462cc)",
          "1.5 ALPHA SMART HYBRID MT (1462cc)",
          "1.5 ZETA CNG (1462cc)",
          "1.5 ALPHA PLUS SMART HYBRID AT (1462cc)",
          "1.5 ALPHA PLUS SMART HYBRID MT (1462cc)",
          "1.5 ALPHA SMART HYBRID AT (1462cc)",
          "1.5 ZETA SMART HYBRID (1462cc)",
          "1.5 ZETA (1462cc)",
          "1.5 ZETA  AUTOMATIC (1462cc)",
          "1.5 ALPHA AUTOMATIC (1462cc)",
          "ALPHA MT PETROL BSVI (1462cc)",
          "ALPHA PLUS MT PETROL BSVI (1462cc)",
          "ALPHA PLUS AT PETROL BSVI (1462cc)",
          "ZETA CNG MT (1462cc)",
          "SMART HYBRID ALPHA AT (1462cc)",
          "ALPHA PLUS MT PETROL (1462cc)",
          "ALPHA PLUS MT PETROL (1462cc)",
          "ALPHA PLUS MT PETROL DUAL TONE (1462cc)",
          "ALPHA PLUS MT PETROL DUAL TONE (1462cc)",
          "ALPHA PLUS AT PETROL (1462cc)",
          "ALPHA PLUS AT PETROL (1462cc)",
          "ALPHA PLUS AT PETROL DUAL TONE (1462cc)",
          "ALPHA PLUS AT PETROL DUAL TONE (1462cc)",
          "ZETA MT PETROL (1462cc)",
          "ZETA MT PETROL (1462cc)",
          "ZETA AT PETROL (1462cc)",
          "ZETA AT PETROL (1462cc)",
          "ALPHA MT PETROL (1462cc)",
          "ALPHA MT PETROL (1462cc)",
          "ALPHA AT PETROL (1462cc)",
          "ALPHA AT PETROL (1462cc)",
          "ZETA MT CNG (1462cc)",
          "ZETA MT CNG (1462cc)",
          "SMART HYBRID ZETA AT (1462cc)",
          "SMART HYBRID ALPHA PULS 1.5L 6AT (1462cc)",
          "SMART HYBRID ALPHA PULS MT (1462cc)",
          "ALPHA PLUS DUAL TONE (1462cc)",
          "ZETA CNG 1.5L 5MT XLRRCBZA (1462cc)",
          "SMART HYBRID ALPHA + (1462cc)",
          "SMART HYBRID ZETA (1462cc)",
          "ALPHA AT (1462cc)",
          "ZETA AT (1462cc)",
          "ZETA MT (1462cc)",
          "ALPHA MT (1462cc)",
          "ALPHA PLUS DUAL TONE AT (1462cc)",
          "ALPHA PLUS DUAL TONE MT (1462cc)",
          "ALPHA PLUS AT (1462cc)",
          "ALPHA PLUS MT (1462cc)",
        ],
      },
      {
        model: "BALENO NEW",
        variants: [
          "1.2 ALPHA (1197cc)",
          "1.3 ALPHA (1248cc)",
          "1.2 DELTA (1197cc)",
          "1.3 DELTA (1248cc)",
          "1.2 SIGMA (1197cc)",
          "1.3 SIGMA (1248cc)",
          "1.2 ZETA (1197cc)",
          "1.3 ZETA (1248cc)",
          "1.2 ALPHA (AT) (1197cc)",
          "1.2 DELTA CVT (1197cc)",
          "1.2 ZETA CVT (1197cc)",
          "1.2 ALPHA CVT (1197cc)",
          "1.2 DELTA (AT) (1197cc)",
          "1.0 RS (998cc)",
          "1.2 VXI CVT (1197cc)",
          "1.2 ZETA (AT) (1197cc)",
          "1.2 SIGMA BSVI (1197cc)",
          "1.2 DELTA BSVI (1197cc)",
          "1.2 ZETA BSVI (1197cc)",
          "1.2 ALPHA BSVI (1197cc)",
          "1.2 DELTA AGS BSVI (1197cc)",
          "1.2 ZETA AGS BSVI (1197cc)",
          "1.2 ALPHA AGS BSVI (1197cc)",
          "1.2 DELTA CNG BSVI (1197cc)",
          "1.2 ZETA CNG BSVI (1197cc)",
          "SIGMA MT (1197cc)",
          "DELTA MT (1197cc)",
          "DELTA AGS (1197cc)",
          "ZETA MT (1197cc)",
          "ZETA AGS (1197cc)",
          "ALPHA MT (1197cc)",
          "ALPHA AGS (1197cc)",
          "DELTA MT CNG (1197cc)",
          "DELTA CNG (1197cc)",
          "ZETA CNG (1197cc)",
          "DELTA PETROL (1197cc)",
          "SIGMA PETROL (1197cc)",
          "ZETA PETROL (1197cc)",
          "ALPHA PETROL AT (1197cc)",
          "AGE SIGMA PETROL (1197cc)",
          "AGE DELTA PETROL (1197cc)",
          "AGE DELTA PETROL AGS (1197cc)",
          "AGE ZETA PETROL (1197cc)",
          "AGE ZETA PETROL AGS (1197cc)",
          "AGE ALPHA PETROL (1197cc)",
          "AGE ALPHA PETROL AGS (1197cc)",
          "DELTA PETROL CVT (1197cc)",
          "ALPHA PETROL (1197cc)",
          "VXI CVT PETROL (1197cc)",
          "DELTA AT PETROL (1197cc)",
          "ZETA AT PETROL (1197cc)",
          "DELTA SMART HYBRID PETROL (1197cc)",
          "ZETA SMART HYBRID PETROL (1197cc)",
          "RS PETROL 1.0 (998cc)",
          "ALPHA DIESEL (1248cc)",
          "DELTA DIESEL (1248cc)",
          "SIGMA DIESEL (1248cc)",
          "ZETA DIESEL (1248cc)",
        ],
      },
      {
        model: "FRONX",
        variants: [
          "1.2 SIGMA BSVI (1197cc)",
          "1.2 DELTA BSVI (1197cc)",
          "1.2 DELTA PLUS BSVI (1197cc)",
          "1.2 DELTA AMT BSVI (1197cc)",
          "1.2 DELTA PLUS AMT BSVI (1197cc)",
          "1.0 TURBO DELTA PLUS BSVI (998cc)",
          "1.0 TURBO ZETA BSVI (998cc)",
          "1.0 TURBO ALPHA BSVI (998cc)",
          "1.0 TURBO ALPHA DT BSVI (998cc)",
          "1.0 TUBRO ZETA AT BSVI (998cc)",
          "1.0 TURBO ALPHA AT BSVI (998cc)",
          "1.0 TURBO ALPHA DT AT BSVI (998cc)",
          "1.2 SIGMA CNG BSVI (1197cc)",
          "1.2 DELTA CNG BSVI (1197cc)",
          "1.0L TURBO DELTA PLUS SMART HYBRID 5MT (998cc)",
          "DELTA PLUS 1.0 TURBO MT (998cc)",
          "1.0L TURBO ZETA SMART HYBRID 5MT (998cc)",
          "1.0L TURBO ZETA SMART HYBRID 6AT (998cc)",
          "1.2L SIGMA 5MT (1197cc)",
          "1.2L DELTA 5MT (1197cc)",
          "1.2L DELTA PLUS 5MT (1197cc)",
          "1.2L DELTA AGS (1197cc)",
          "1.2L DELTA PLUS AGS (1197cc)",
          "SIGMA 1.2L MT (1197cc)",
          "DELTA 1.2L MT (1197cc)",
          "DELTA PLUS 1.2L MT (1197cc)",
          "DELTA 1.2L AGS (1197cc)",
          "DELTA PLUS 1.2L AGS (1197cc)",
          "1.0L TURBO ALPHA SMART HYBRID 5MT (998cc)",
          "1.0L TURBO ALPHA SMART HYBRID 6AT (998cc)",
          "ZETA TURBO AT (998cc)",
          "1.5L ALPHA AT (998cc)",
          "ZETA 1.0L TURBO 6 AT (998cc)",
          "ZETA 1.0L TURBO MT (998cc)",
          "ALPHA 1.0L TURBO MT (998cc)",
          "ALPHA 1.0L TURBO MT DUAL TONE (998cc)",
          "ALPHA 1.0L TURBO 6 AT (998cc)",
          "ALPHA 1.0L TURBO 6 AT DUAL TONE (998cc)",
        ],
      },
      {
        model: "INVICTO",
        variants: [
          "2.0 ZETA PLUS HYBRID AT BSVI (1987cc)",
          "2.0 ALPHA PLUS HYBRID AT BSVI (1987cc)",
          "ALPHA PLUS (1987cc)",
          "STRONG HYBRID ZETA+ 7S (1987cc)",
          "ZETA PLUS CVT 7 STR (1987cc)",
          "ZETA PLUS CVT 8 STR (1987cc)",
          "ALPHA PLUS CVT 7 STR (1987cc)",
        ],
      },
      {
        model: "ALTO K10B",
        variants: [
          "VXI (AT) (998cc)",
          "LXI (O) (998cc)",
          "LXI (998cc)",
          "LX (998cc)",
          "LXI CNG 5 SEATER (998cc)",
          "LXI CNG (O) (998cc)",
          "LXI CNG (998cc)",
          "VXI PLUS (998cc)",
          "STD (998cc)",
          "VXI AGS (O) (998cc)",
          "LX (O) (998cc)",
          "VXI AGS (998cc)",
          "VXI AMT (998cc)",
          "KNIGHTRACER (998cc)",
          "VXI CNG (998cc)",
          "XPLORE (998cc)",
          "VXI (O) (998cc)",
        ],
      },
      {
        model: "SWIFT DZIRE KB",
        variants: [
          "VXI (O) (1197cc)",
          "LXI (1197cc)",
          "ZXI (1197cc)",
          "AT (1197cc)",
          "LXI (O) (1197cc)",
          "VXI LIMITED EDITION (1197cc)",
          "VXI AT (1197cc)",
          "ZXI  AMT (1197cc)",
          "ZXI PLUS AMT (1197cc)",
          "ZXI PLUS AGS (1197cc)",
        ],
      },
      {
        model: "JIMNY",
        variants: [
          "LXI (1462cc)",
          "ZETA MT (1462cc)",
          "ALPHA MT (1462cc)",
          "ALPHA MT DUAL TONE (1462cc)",
          "ZETA AT (1462cc)",
          "ALPHA AT (1462cc)",
          "ALPHA AT DUAL TONE (1462cc)",
        ],
      },
      {
        model: "ESTILO NEW",
        variants: [
          "1.0 LX (998cc)",
          "1.0 LXI (998cc)",
          "1.0 LXI GREEN (998cc)",
          "1.0 VXI (998cc)",
          "1.0 VXI (ABS) (998cc)",
          "1.0 VXI GREEN (998cc)",
          "VXI BSIII (1021cc)",
        ],
      },
      {
        model: "NEW GRAND VITARA",
        variants: [
          "SIGMA MT BSVI (1462cc)",
          "DELTA MT BSVI (1462cc)",
          "DELTA AT BSVI (1462cc)",
          "ZETA PLUS MT BSVI (1462cc)",
          "ZETA MT BSVI (1462cc)",
          "ALPHA MT BSVI (1462cc)",
          "ALPHA PLUS MT BSVI (1462cc)",
          "ZETA AT BSVI (1462cc)",
          "ZETA PLUS AT BSVI (1462cc)",
          "ALPHA DT MT BSVI (1462cc)",
          "ALPHA PLUS DT MT BSVI (1462cc)",
          "ALPHA PLUS 4WD MT BSVI (1462cc)",
          "ALPHA 4WD MT BSVI (1462cc)",
          "ALPHA PLUS AT BSVI (1462cc)",
          "ALPHA AT BSVI (1462cc)",
          "ALPHA DT AT BSVI (1462cc)",
          "ALPHA PLUS DT AT BSVI (1462cc)",
          "ALPHA DT 4WD MT BSVI (1462cc)",
          "ALPHA PLUS DT 4WD MT BSVI (1462cc)",
          "ZETA ECVT BSVI (1490cc)",
          "ZETA PLUS ECVT BSVI (1490cc)",
          "ZETA DT ECVT BSVI (1490cc)",
          "ZETA PLUS DT ECVT BSVI (1490cc)",
          "ALPHA ECVT BSVI (1490cc)",
          "ALPHA PLUS ECVT BSVI (1490cc)",
          "ALPHA DT ECVT BSVI (1490cc)",
          "ALPHA PLUS DT ECVT BSVI (1490cc)",
          "ZETA CNG MT BSVI (1462cc)",
          "DELTA CNG MT BSVI (1462cc)",
        ],
      },
      {
        model: "NEW JIMNY",
        variants: [
          "1.5 ZETA BSVI (1462cc)",
          "1.5 ALPHA BSVI (1462cc)",
          "1.5 ALPHA DT BSVI (1462cc)",
          "1.5 ZETA AT BSVI (1462cc)",
          "1.5 ALPHA AT BSVI (1462cc)",
          "1.5 ALPHA AT DT BSVI (1462cc)",
        ],
      },
      {
        model: "S-CROSS",
        variants: [
          "1.6 ZETA (1598cc)",
          "1.6 DELTA (1598cc)",
          "HYBRID ZETA (1248cc)",
          "ZETA 1.3 LE (1248cc)",
          "ZETA 1.3 LE (1248cc)",
          "SIGMA 1.3 (1248cc)",
          "SIGMA 1.3 (1248cc)",
          "DELTA 1.3 (1248cc)",
          "DELTA 1.3 (1248cc)",
          "ZETA 1.3 (1248cc)",
          "ZETA 1.3 (1248cc)",
          "ALPHA 1.3 (1248cc)",
          "ALPHA 1.3 (1248cc)",
          "SIGMA (O) (1248cc)",
          "SMART HYBRID DELTA (1248cc)",
          "SMART HYBRID ZETA (1248cc)",
          "ALPHA (1248cc)",
          "SMART HYBRID SIGMA (1248cc)",
          "SMART HYBRID ALPHA (1248cc)",
          "SMART HYBRID ZETA AT (1462cc)",
          "SMART HYBRID ZETA 1.5 MT (1462cc)",
          "SMART HYBRID SIGMA (1462cc)",
          "DELTA 1.6 (1598cc)",
          "DELTA 1.6 (1598cc)",
          "ZETA 1.6 (1598cc)",
          "ZETA 1.6 (1598cc)",
          "ALPHA 1.6 (1598cc)",
          "ALPHA 1.6 (1598cc)",
          "SGX (1491cc)",
          "ALPHA 1.5 BS6 (1462cc)",
          "1.5 ALPHA AT BS6 (1462cc)",
          "SIGMA PETROL BS6 (1462cc)",
          "ZETA AT BS6 (1462cc)",
          "SIGMA (1462cc)",
          "SIGMA (1462cc)",
          "ALPHA AT (1462cc)",
          "ALPHA AT (1462cc)",
          "ZETA AT (1462cc)",
          "ZETA AT (1462cc)",
          "ZETA (1462cc)",
          "ZETA (1462cc)",
          "DELTA (1462cc)",
          "DELTA (1462cc)",
          "DELTA AT (1462cc)",
          "DELTA AT (1462cc)",
          "ALPHA (1462cc)",
          "ALPHA (1462cc)",
          "1.5 ALPHA (1462cc)",
          "1.5 SIGMA (1462cc)",
          "1.5 DELTA (1462cc)",
          "1.5 ALPHA AT (1462cc)",
          "1.5 DELTA AT (1462cc)",
          "1.5 ZETA (1462cc)",
          "1.5 ZETA AT (1462cc)",
          "PREMIA 1.3 (1248cc)",
          "PREMIA 1.3 (1248cc)",
          "VDI (1248cc)",
          "ZDI (1248cc)",
          "SMART HYBRID ZETA AT (1462cc)",
          "LXI CNG. (998cc)",
        ],
      },
      {
        model: "NEW BREZZA",
        variants: [
          "SMART HYBRID ISG 5MT LXI BSVI (1462cc)",
          "SMART HYBRID ISG 5MT VXI BSVI (1462cc)",
          "SMART HYBRID ISG 5MT ZXI BSVI (1462cc)",
          "SMART HYBRID ISG 6AT VXI BSVI (1462cc)",
          "SMART HYBRID ISG 6AT ZXI BSVI (1462cc)",
        ],
      },
      {
        model: "WAGON R 1.0",
        variants: [
          "LX (998cc)",
          "LXI (998cc)",
          "LXI CNG (998cc)",
          "LXI LPG (998cc)",
          "VXI (998cc)",
          "VXI ABS AIRBAG (998cc)",
          "LXI ABS (998cc)",
          "VXI + AMT (998cc)",
          "LXI CNG O (998cc)",
          "VXI ABS (998cc)",
          "VXI + O (998cc)",
          "VXI AMT O (998cc)",
          "LXI AVANCE CNG EDITION (998cc)",
          "LXI AVANCE EDITION PETROL (998cc)",
          "DUO LXI LPG (998cc)",
        ],
      },
      {
        model: "WAGONR 1.0",
        variants: ["VXI PLUS (998cc)", "LXI 4STR (1061cc)"],
      },
      {
        model: "ECO",
        variants: ["MARUTI ECO (1196cc)", "MARUTI ECOÂ  AC (1196cc)"],
      },
      {
        model: "MARUTI EECO",
        variants: ["0 (1196cc)"],
      },
      {
        model: "BREZZA",
        variants: [
          "1.5Â  K15C 5MT LXI CNG (1462cc)",
          "1.5 K 15C 5MT VXIÂ CNG (1462cc)",
          "1.5 K 15C ISG 6AT ZXI PLUS (1462cc)",
          "1.5 K 15C ISG 6AT ZXI (1462cc)",
          "1.5 K 15C ISG 5MT ZXI PLUS (1462cc)",
          "1.5 K15C ISG 6AT VXI (1462cc)",
          "1.5 K 15C ISG 5MT ZXI (1462cc)",
          "1.5 K 15C ISG 5MT VXI (1462cc)",
          "1.5Â  K15C ISG 5MT LXI (1462cc)",
          "1.5 K 15C 5MT ZXI CNG DUAL TONE (1462cc)",
          "1.5 K 15C 5MT ZXI CNG (1462cc)",
          "SMART HYBRID ZXI+ 1.5L 6AT (1462cc)",
          "SMART HYBRID ZXI 1.5L BSVI (1462cc)",
          "LXI (O) CNG MT (1462cc)",
          "1.5L VXI (O) CNG MT (1462cc)",
          "SMART HYBRID ZXI+ 1.5L MT (1462cc)",
          "VXI 1.5 L 5MT (1462cc)",
          "ZXI (O) CNG 1.5L 5MT (1462cc)",
          "LXI S-CNG (1462cc)",
          "VXI S-CNG (1462cc)",
          "ZXI S-CNG (1462cc)",
          "ZXI S-CNG DUAL TONE (1462cc)",
          "LXI (1462cc)",
          "LXI (1462cc)",
          "VXI (1462cc)",
          "VXI.. (1462cc)",
          "VXI AT (1462cc)",
          "VXI AT.. (1462cc)",
          "ZXI (1462cc)",
          "ZXI (1462cc)",
          "ZXI PLUS (1462cc)",
          "ZXI PLUS (1462cc)",
          "ZXI DUAL TONE (1462cc)",
          "ZXI DUAL TONE.. (1462cc)",
          "ZXI PLUS DUAL TONE (1462cc)",
          "ZXI PLUS DUAL TONE.. (1462cc)",
          "ZXI AT (1462cc)",
          "ZXI AT.. (1462cc)",
          "ZXI PLUS AT (1462cc)",
          "ZXI PLUS AT (1462cc)",
          "ZXI AT DUAL TONE (1462cc)",
          "ZXI AT DUAL TONE.. (1462cc)",
          "ZXI PLUS AT DUAL TONE (1462cc)",
          "ZXI PLUS AT DUAL TONE.. (1462cc)",
          "SMART HYBRID LXI (1497cc)",
          "SMART HYBRID VXI (1497cc)",
          "SMART HYBRID ZXI 1.5 AT (1497cc)",
          "SMART HYBRID ZXI PLUS 1.5L 6AT (1462cc)",
          "SMART HYBRID VXI 1.5L 5MT (1462cc)",
          "SMART HYBRID ZXI 1.5L 5MT (1462cc)",
        ],
      },
      {
        model: "ALTURA",
        variants: ["ALTURA (1590cc)"],
      },
      {
        model: "ESTILO",
        variants: [
          "LXI (998cc)",
          "LXI (998cc)",
          "VXI (998cc)",
          "VXI (998cc)",
          "LX (998cc)",
        ],
      },
      {
        model: "ESTILO 1.0  NEW",
        variants: [
          "LXI GREEN (CNG) (998cc)",
          "VXI GREEN (CNG) (998cc)",
          "LX (998cc)",
          "LXI (998cc)",
          "VXI (998cc)",
          "VXI ABS (998cc)",
        ],
      },
      {
        model: "LIANA",
        variants: ["0 (1300cc)"],
      },
      {
        model: "MARUTHI VAN",
        variants: ["VAN (796cc)"],
      },
      {
        model: "MARUTI 1000",
        variants: ["ESTEEM (1000cc)"],
      },
      {
        model: "MARUTI VAN",
        variants: ["RIGHT HAND (796cc)"],
      },
      {
        model: "NEW SWIFT DZIRE",
        variants: [
          "AUTOMATIC (1197cc)",
          "LXI (1197cc)",
          "ZXI (1197cc)",
          "VXI (1197cc)",
          "ZDI (1248cc)",
          "LDI (1248cc)",
          "VDI (1248cc)",
        ],
      },
      {
        model: "PIXO",
        variants: ["DX6 (998cc)", "DX6 5 SEATER (998cc)"],
      },
      {
        model: "TOUR H1",
        variants: ["PETROL (996cc)"],
      },
      {
        model: "TOUR H2",
        variants: ["CNG (998cc)", "PETROL (998cc)"],
      },
      {
        model: "TOUR M",
        variants: ["CNG (1462cc)"],
      },
      {
        model: "TOUR S",
        variants: ["CNG (1197cc)", "PETROL (1197cc)", "DIESEL (1248cc)"],
      },
      {
        model: "TOUR V",
        variants: ["PETROL (1196cc)", "5 STR AC (1196cc)"],
      },
      {
        model: "YE3",
        variants: ["DP2 (796cc)"],
      },
      {
        model: "ZEN ESTILO 2009",
        variants: [
          "LX (998cc)",
          "VXI ABS (998cc)",
          "VXI (998cc)",
          "LXI (998cc)",
        ],
      },
      {
        model: "BALENO HATCHBACK",
        variants: [
          "ZETA 1.3 (1248cc)",
          "SIGMA 1.3 (1248cc)",
          "ALPHA 1.3 (1248cc)",
          "DELTA 1.3 (1248cc)",
          "DELTA 1.2 (1197cc)",
          "ZETA AT 1.2 (1197cc)",
          "SIGMA 1.2 (1197cc)",
          "DELTA AT 1.2 (1197cc)",
          "ZETA 1.2 (1197cc)",
          "ALPHA 1.2 AT (1197cc)",
          "ALPHA 1.2 (1197cc)",
          "DELTA MT CNG (1197cc)",
          "ZETA MT CNG (1197cc)",
          "RS (998cc)",
        ],
      },
    ],
  },
  {
    make: "MERCEDES BENZ",
    models: [
      {
        model: "190",
        variants: [
          "W110 (2298cc)",
          "D (2497cc)",
          "2.5 D (2497cc)",
          "D (1793cc)",
          "D (1796cc)",
        ],
      },
      {
        model: "250",
        variants: ["D (2497cc)"],
      },
      {
        model: "300",
        variants: [
          "SEL (3000cc)",
          "D 3 (3000cc)",
          "D (2987cc)",
          "D 2 (1996cc)",
        ],
      },
      {
        model: "320",
        variants: ["0 (3200cc)"],
      },
      {
        model: "560",
        variants: ["SEL (5547cc)"],
      },
      {
        model: "5600",
        variants: ["L (2148cc)"],
      },
      {
        model: "VIANO",
        variants: [
          "2.2 6STR VAN (2199cc)",
          "3.5 5STR VAN (3498cc)",
          "2.2 PETROL 6 SEATER VAN (2199cc)",
          "3.5 PETROL 5 SEATER VAN (3498cc)",
          "2.2 CDI LONG (2143cc)",
          "2.2 CDI LONG (2143cc)",
          "CDI 20 AVG K 8 SEATER (2143cc)",
          "CDI 2.2 TREND (2143cc)",
          "MT (3500cc)",
          "MT (3500cc)",
          "3.5 (3498cc)",
          "3.5 (3498cc)",
          "3.5 TREND LONG (3498cc)",
          ". (3498cc)",
          "2.2 (2199cc)",
          "2.2 CDI (2148cc)",
          "2.2 CDI 8 SEATER VAN (2148cc)",
          "3.5 PETROL (2874cc)",
        ],
      },
      {
        model: "C-CLASS",
        variants: [
          "C 220 CDI AVANTGARDE (2143cc)",
          "C 220 CDI AVANTGARDE (2143cc)",
          "C 220 CDI STYLE (2143cc)",
          "C 220 CDI STYLE (2143cc)",
          "C 63 S AMG (3982cc)",
          "C 63 S AMG (3982cc)",
          "C 220 CDI ELEGANCE MT (2148cc)",
          "C 220 CDI ELEGANCE MT (2148cc)",
          "C 220D PRIME (1950cc)",
          "C 220D PRIME (1950cc)",
          "C 220D PROGRESSIVE (1950cc)",
          "C 220D PROGRESSIVE (1950cc)",
          "C300D AMG LINE (1950cc)",
          "C 300D AMG LINE (1950cc)",
          "C 200 PROGRESSIVE (1497cc)",
          "C 200 PROGRESSIVE (1497cc)",
          "C 220 CDI CLASSIC (2143cc)",
          "C 220 CDI CLASSIC (2143cc)",
          "C 200 (1496cc)",
          "C 200 (1496cc)",
          "C 220D (1993cc)",
          "C220D (1993cc)",
          "C 300D (1993cc)",
          "C 300D (1993cc)",
          "CABRIOLET C 300 (1991cc)",
          "CABRIOLET C 300 (1991cc)",
          "C 220 CDI MT (2148cc)",
          "C 220 CDI MT (2148cc)",
          "C 200 CGI CLASSIC (1796cc)",
          "C 200 CGI CLASSIC (1796cc)",
          "C 220 CDI AT (2148cc)",
          "C 220 CDI AT (2148cc)",
          "C 180 CLASSIC (1998cc)",
          "C 180 CLASSIC (1998cc)",
          "C 200 CGI ELEGANCE (1796cc)",
          "C 200 CGI ELEGANCE (1796cc)",
          "C 200 K CLASSIC AT (1796cc)",
          "C 230 AVANT AT (2496cc)",
          "C 63 AMG AT (6208cc)",
          "C 220 CDI AVANT (AT) (2143cc)",
          "C 220 CDI EXECUTIVE (AT) (2143cc)",
          "C 200 CGI AVANTGRADE (1796cc)",
          "C 200 CGI BE AVANTGRADE (1796cc)",
          "C 200 CGI CLASSIC BE (1796cc)",
          "C 200 K CLASSIC (1796cc)",
          "C 200 K ELEGANCE (AT) (1796cc)",
          "C 220 CDI ELEGANCE (AT) (2143cc)",
          "C 220 CDI ELEGANCE AT (2148cc)",
          "C 250 CDI AVANTGRADE (AT) (2143cc)",
          "C 250 CDI BE AVANTGRADE (2143cc)",
          "C 250 V6 AVANTGRADE (AT) (2497cc)",
          "MERCEDES AMG C 43 (2982cc)",
          "W204 C 230 AVANTGRADE (2496cc)",
          "C 200 AVANTGRADE (AT) (1991cc)",
          "C 200 EDITON C (1991cc)",
          "C 220 D STYLE 1 & 3 (2143cc)",
          "C 220 D EDITION C (2143cc)",
          "C 250 D EDITION C (2143cc)",
          "C 250 D AVANTGRADE 1 (2143cc)",
          "C 200 AVANTGRADE 2 (1991cc)",
          "C 200 AVANTGRADE 3 (1991cc)",
          "C 200 AVANTGRADE 4 (1991cc)",
          "C 200 AVANTGRADE EDTN C (1991cc)",
          "C 220 D AVANTGRADE 2 (2143cc)",
          "C 220 D AVANTGRADE 3 (2143cc)",
          "C 220 D AVANTGRADE 4 (2143cc)",
          "C 220 D AVANTGRADE EDTN C (2143cc)",
          "C 250 D AVANTGRADE (2143cc)",
          "C 250 D AVANTGRADE EDTN C (2143cc)",
          "C 220 CDI BLUEEFFICIENCY (2143cc)",
          "C 220 CDI AVANTGRADE 4 AT (2148cc)",
          "W 204 (1796cc)",
          "C 250 CDI AVANTGARDE (2143cc)",
          "C 230 (2295cc)",
          "C 220 CDI (2143cc)",
          "C 200 BE ELEGANCE (1796cc)",
          "C 200 CDI CLASSIC (2148cc)",
          "C 200 CDI ELEGANCE (2148cc)",
          "C 220 CDI CLASSIC (AT) (2143cc)",
          "C 250 CDI ELEGANCE AT (2143cc)",
          "C 220 CDI BE CLASSIC (2143cc)",
          "C 220 CDI BE EXECUTIVE (2143cc)",
          "C 220 CDI BE CORPORATE EDITION (2143cc)",
          "C 220 CDI BE SPORTS (2143cc)",
          "C 220 CDI BE AVANT (2143cc)",
          "C 250 CDI BE ELEGANCE (2143cc)",
          "C 200 CDI (2148cc)",
          "C 200 PRIME (1950cc)",
          "C 180 ELEGANCE (1998cc)",
          "C 200 PROGRESSIVE (1991cc)",
          "MERCEDES - AMG C 43 (3982cc)",
          "C 200 K CLASSIC PETROL (1796cc)",
          "C 220 CGI (3982cc)",
          "C 250 CDI AVANTGARDE (3982cc)",
          "C 63 AMG AT TRANSMISSION (6208cc)",
          "C 250 V6 AVANTGARDE AT (2496cc)",
          "C 200 CGI CLASSIC BE PETROL (1796cc)",
          "C 230 AVANT PETROL AT (2496cc)",
          "C 200 CGI BE AVANTGARDE PETROL (1796cc)",
          "C 200 CGI ELEGANCE AT PETROL (1795cc)",
          "C 200 CGI CLASSIC PETROL (1795cc)",
          "C 200 CGI AVANTGARDE PETROL (1796cc)",
          "C 200 K ELEGENCE PETROL AT (1796cc)",
          "W204 C 230 AVANTGARDE (2496cc)",
          "C 200 K CLASSIC PETROL AT (1796cc)",
          "C 200 CDI CLASSIC (1991cc)",
          "C 200 CDI ELEGANCE (1991cc)",
          "C 220 CDI CLASSIC AT (2148cc)",
          "C 250 CDI AVANTGARDE AT (2143cc)",
          "C 220 CDI BE CLASSIC (2148cc)",
          "C 250 CDI BE AVANTGARDE (2143cc)",
          "C 220 CDI EXECUTIVE AT DIESEL (2148cc)",
          "C 220 CDI ELEGENCE DIESEL AT (2148cc)",
          "C 220 CDI AVANT DIESEL AT (2148cc)",
          "CABRIOLET C 300 (3982cc)",
          "C 220 CDI AVANTGARDE (3982cc)",
          "C 220 CDI STYLE (3982cc)",
          "180 ELEGANCE MT (1998cc)",
          "180 ELEGANCE MT (1998cc)",
          "C 180 ELEGANCE MT (1998cc)",
          "C 180 K (1998cc)",
          "C 200 K ADVANTGRADE (1998cc)",
          "180 CLASSIC (1998cc)",
          "180 CLASSIC (1998cc)",
          "180 ELEGANCE AT (1998cc)",
          "180 ELEGANCE (1998cc)",
          "200 CDI CLASSIC (2148cc)",
          "200 CDI CLASSIC (2148cc)",
          "200 CDI ELEGANCE (2148cc)",
          "200 CDI ELEGANCE (2148cc)",
          "220 CDI AT (2148cc)",
          "220 CDI AT (2148cc)",
          "220 CDI AVANTGARDE AT (2148cc)",
          "220 CDI AVANTGARDE AT (2148cc)",
          "220 CDI ELEGANCE AT (2148cc)",
          "220 CDI ELEGANCE AT (2148cc)",
          "220 CDI MT (2148cc)",
          "220 CDI MT (2148cc)",
          "C 200 CDI MT (2148cc)",
          "C 200 CDI AT (2148cc)",
          "C 220 CDI C2 (2148cc)",
          "C 220 CDI C1 (2148cc)",
          "C 220 CDI SPORTS (2148cc)",
          "C 220 CDI ELEGENCE AT (2148cc)",
          "C 220 CDI CLASSIC MT (2148cc)",
          "C 200 CDI CLASSIC (CC 2148) (2148cc)",
          "C 200 CDI ELEGANCE (CC 2148) (2148cc)",
          "C 200 CDI AVANTGARDE (2148cc)",
          "220 CDI (2148cc)",
          "C 220 CDI AVANT AT (2148cc)",
          "C 220 CDI EXECUTIVE AT (2148cc)",
          "C 220 CDI CLASSIC (2148cc)",
          "220 CDI SPORT (2148cc)",
          "C220 OLD SHAPE (2148cc)",
          "200 CGI AVANTGARDE (1796cc)",
          "200 CGI AVANTGARDE (1796cc)",
          "200 K ELEGANCE AT (1796cc)",
          "200 K ELEGANCE AT (1796cc)",
          "200 K AT (1796cc)",
          "200 K AT (1796cc)",
          "200 K MT (1796cc)",
          "200 K MT (1796cc)",
          "200 CGI ELEGANCE (1796cc)",
          "200 CGI ELEGANCE (1796cc)",
          "C 200 KOMPRESSOR ELEGANCE MT (1796cc)",
          "C 200 KOMPRESSOR ELEGANCE MT (1796cc)",
          "180 ELEGANCE AT (1796cc)",
          "180 ELEGANCE AT (1796cc)",
          "GRAND EDITION PETROL (1796cc)",
          "GRAND EDITION PETROL (1796cc)",
          "C 200 K C-2 (1796cc)",
          "C 200 CGI AVANTGARDE (1796cc)",
          "C 200 CGI AVANTGARDE (1796cc)",
          "C 200 K  MT (1796cc)",
          "C 200 K  AT (1796cc)",
          "C 200 K ELEGANCE (1796cc)",
          "C 200 K CLASSIC C1 METALLIC (1796cc)",
          "C 200 BE (1796cc)",
          "C 200 BE (1796cc)",
          "C 200 K ELEGENCE AT (1796cc)",
          "C 200 K CLASSIC MT (1796cc)",
          "C 200 ELEGANCE (1796cc)",
          "CKD W 204 C200 AVANTAGRADE (1796cc)",
          "250 CGI ELEGANCE (1796cc)",
          "C 200 BE CLASSIC (1796cc)",
          "C 200 KOMPRESSOR ELEGANCE AT (1796cc)",
          "CKD W 205 C 200 AVANTAGRADE (1796cc)",
          "C 200 K CLASSIC PETROL MT (1796cc)",
          "C 200 AVANTGARDE GRAND EDITION (1796cc)",
          "C 250 CGI ELEGANCE (1796cc)",
          "200 CGI (1796cc)",
          "C 200 BLUE EFFICIENCY (1796cc)",
          "200 CGI ADVANTGARDE (1796cc)",
          "200 CGI BLUE EFFICIENCY (1796cc)",
          "C180 CLASSIC (1796cc)",
          "C180 ELEGANCE (1796cc)",
          "230 AVANTGARDE (2496cc)",
          "230 AVANTGARDE (2496cc)",
          "C 230 (2496cc)",
          "C 230 AVANTGARDE (2496cc)",
          "C 250 (2496cc)",
          "250 AVANTGARDE (2497cc)",
          "250 AVANTGARDE (2497cc)",
          "C 250 AVANTGARDE (2497cc)",
          "C250 AVANTGARDE (2497cc)",
          "C 250 D (2497cc)",
          "CKD W -204 (2497cc)",
          "W204 C250 AT (2497cc)",
          "250 ADVANTGARDE (2497cc)",
          "C 250 (2497cc)",
          "250 CDI AVANTGARDE (2143cc)",
          "250 CDI AVANTGARDE (2143cc)",
          "250 CDI CLASSIC (2143cc)",
          "250 CDI CLASSIC (2143cc)",
          "250 CDI ELEGANCE (2143cc)",
          "250 CDI ELEGANCE (2143cc)",
          "250 CDI (2143cc)",
          "250 CDI (2143cc)",
          "220 BLUEEFFICIENCY (2143cc)",
          "220 BLUEEFFICIENCY (2143cc)",
          "EDITION C (2143cc)",
          "EDITION C (2143cc)",
          "C 250 D (2143cc)",
          "C 250 D (2143cc)",
          "GRAND EDITION (2143cc)",
          "GRAND EDITION (2143cc)",
          "C 220 CDI SPORT EDITION (2143cc)",
          "C 220 CDI SPORT EDITION (2143cc)",
          "C 220 CDI EXECUTIVE EDITION (2143cc)",
          "C 220 CDI EXECUTIVE EDITION (2143cc)",
          "C 250 CDI (2143cc)",
          "C 220 CDI BE (2143cc)",
          "C 220 CDI EXECUTIVE (2143cc)",
          "C 250 CDI ELEGANCE (2143cc)",
          "C 220 CDI CORPORATE EDITION (2143cc)",
          "C 220 CDI SPORTS (2143cc)",
          "C 220 CDI AVANT (2143cc)",
          "CKD W 204 C200BE AVANTAGRADE (2143cc)",
          "C 250 CDI AMG AVANTGARDE (2143cc)",
          "C 250 CDI BE AMG AVANTGARDE (2143cc)",
          "C 250 CDI CLASSIC (2143cc)",
          "C 220 CDI AVANTGARDE LAUNCH EDITION (2143cc)",
          "C 220 CDI AVANTGARDE GRAND EDITION (2143cc)",
          "C 220 CDI EDITION C (2143cc)",
          "C 220 D AVANTGARDE (2143cc)",
          "C 250 D AVANTGARDE (2143cc)",
          "C 220 D SKD W 206 (2143cc)",
          "C 200 CDI (2143cc)",
          "C 220 CDI GRAND EDITION (2143cc)",
          "220 CDI BLUE EFFICIENCY (2143cc)",
          "C250CDI AVANTGARDE BLUEEFFICIE (N/M) (2143cc)",
          "C250 (N/M) (2143cc)",
          "C250 (M) (2143cc)",
          "C250CDI AVANTGARDE BLUEEFFICIE (M) (2143cc)",
          "250 CDI ADVANTGARDE (2143cc)",
          "C 220 D (2143cc)",
          "63 AMG (6208cc)",
          "63 AMG (6208cc)",
          "C 63 AMG (6208cc)",
          "63 AMG 5 STR (6208cc)",
          "C 63 AMG AUTOMATIC TRANSMISSION (6208cc)",
          "C 200 AVANTGARDE (1991cc)",
          "C 200 AVANTGARDE (1991cc)",
          "200 CGI (1991cc)",
          "200 CGI (1991cc)",
          "C 200 PROGRESSIVE BS6. (1991cc)",
          "C 200 PROGRESSIVE 3 (1991cc)",
          "CABRIOLET C 300 5 STR (1991cc)",
          "C 300 CABRIOLET PETROL (1991cc)",
          "C 200 PROGRESSIVE (1991 CC) (1991cc)",
          "C 200 PRIME 5 (1991 CC) (1991cc)",
          "C 300 CABRIOLET 4 SEATER PETROL (1991cc)",
          "C 200 CBU PETROL CC 1991 (1991cc)",
          "C 200 AVANTGARDE 2.0L PETROL (1991cc)",
          "C 200 CGI (1991cc)",
          "C 300 CABRIOLET (1991cc)",
          "C 200 PROGRESSIVE CC 1991 (1991cc)",
          "C 200 PRIME CC 1991 (1991cc)",
          "C 43 AMG (2982cc)",
          "C 43 AMG (2982cc)",
          "C 43 AMG COUPE (2996cc)",
          "C 43 AMG COUPE BS-VI (2996cc)",
          "C 43 AMG COUPE BS-VI (2996cc)",
          "43 AMG (2996cc)",
          "C 43 AMG 4 MATIC (2996cc)",
          "43 AMG 4MATIC COUPE (2996cc)",
          "43 AMG COUPE (2996cc)",
          "C 43 AMG (2996cc)",
          "C 200 PRIME (1497cc)",
          "C 200 PRIME (1497cc)",
          "C 200 PROGRESSIVE BS-VI (1497cc)",
          "C 200 PROGRESSIVE BS-VI (1497cc)",
          "C 200 PRIME BS-VI (1497cc)",
          "C 200 PRIME BS-VI (1497cc)",
          "C 200 PROGRESSIVE BS6 (1497cc)",
          "C 200 PEROL 1497 CC (1497cc)",
          "C 200 PROGRESSIVE 1.5 PETROL CKD (1497cc)",
          "C 200 PRIME 1.5 PETROL (1497cc)",
          "C 220D PRIME BS-VI (1497cc)",
          "C 200 (1497cc)",
          "C 220D PRIME BS-VI (1950cc)",
          "C 220D PRIME BS-VI (1950cc)",
          "C 220D PROGRESSIVE BS-VI (1950cc)",
          "C 220D PROGRESSIVE BS-VI (1950cc)",
          "C300D AMG LINE BS-VI (1950cc)",
          "C300D AMG LINE BS-VI (1950cc)",
          "C 300D AMG LINE BS6 (1950cc)",
          "C 200 D PROGRESSIVE (1950cc)",
          "C 220 D PRIME CC 1950 (1950cc)",
          "C 220 D PROGRESSIVE CC 1950 (1950cc)",
          "SKD W206 C 300D (1950cc)",
          "C 63 AMG COUPE BS-VI (3982cc)",
          "C 63 AMG COUPE BS-VI (3982cc)",
          "C 63 AMG  S 4.0 (3982cc)",
          "63 S AMG (3982cc)",
          "C 270 CDI (2000cc)",
          "C 280 (2000cc)",
          "C 300 (2987cc)",
          "C 300 (2987cc)",
          "C 200 CGI ELEGANCE AT (1795cc)",
          "C 200 KOMPRESSOR SPORTS COUPE (1795cc)",
          "CBU W 246 B 180SP 761 W 246 (1598cc)",
          "CBU W 246 SPORTS TOURER B180 (1598cc)",
          "C 220D AT DIESEL CC 1993 (1993cc)",
          "C 300D AT DIESEL CC 1993 (1993cc)",
          "C 220D SKD W206 (1993cc)",
          "C 200 AT PETROL CC 1496 (1496cc)",
          "C 200 SKD W206 (1496cc)",
          "C 280 SPORT AUTO (CC 2799) (2799cc)",
          "240 D (2400cc)",
          "C200 OLD SHAPE (1968cc)",
        ],
      },
      {
        model: "S-CLASS",
        variants: [
          "MAYBACH S 500 (4663cc)",
          "MAYBACH S 500 (4663cc)",
          "MAYBACH S 560 (3982cc)",
          "MAYBACH S 560 (3982cc)",
          "S 350D (2925cc)",
          "S 350D (2925cc)",
          "S 500 (5461cc)",
          "S 500 (5461cc)",
          "S 350 CDI L (2987cc)",
          "S 350 CDI L (2987cc)",
          "S 320 CDIL (2987cc)",
          "S 320 CDI L (2987cc)",
          "S 500 COUPE (5980cc)",
          "S 63 AMG COUPE (5980cc)",
          "S 500 LONG (4966cc)",
          "S 500 L LAUNCH EDITION (4663cc)",
          "350 DL (2143cc)",
          "S 350 CONNOISSEURS EDITION (5980cc)",
          "S 300 L (2987cc)",
          "S 300 L (2997cc)",
          "S 350 L (AT) (3498cc)",
          "S-CLASS 400 (3996cc)",
          "S-CLASS S400 CONNOISSEURED (2996cc)",
          "S 350D CONNOISSEUR EDITION (2987cc)",
          "S 400L CONNOISSEUR EDITION (2996cc)",
          "S 450 FL (2996cc)",
          "S 350D MY 17 (2925cc)",
          "S 450 FACELIFT (2987cc)",
          "MAESTRO EDITION (2925cc)",
          "450 4 MATIC (2999cc)",
          "400 4 MATIC (2995cc)",
          "S 450 LUNCH EDITION (2996cc)",
          "550 S (4700cc)",
          "S 450 4 MATIC (2022) (2999cc)",
          "S 350 (3724cc)",
          "S 350 BE L (3724cc)",
          "S 600 LONG (5786cc)",
          "S 500 BEL (4966cc)",
          "S 350 L CBU (3498cc)",
          "S 320 L (3199cc)",
          "350 L BE PANAROMIC SUNROOF (3498cc)",
          "S 63 AMG V8 RHD (6208cc)",
          "S 320 CDI L (3222cc)",
          "S 350 CDI L (3498cc)",
          "S 350 D FL (2987cc)",
          "S 350 CDI L BE PANAROMIC SUNROOF (2987cc)",
          "S 350 L (3498cc)",
          "S 320 CDI (3222cc)",
          "S 400 CONNOISSEURS EDITION (5980cc)",
          "S 350 CDI (5980cc)",
          "MAYBACH S500 (5980cc)",
          "S 430 (4266cc)",
          "S 280 (2799cc)",
          "S 63 AMG (5980cc)",
          "S400 (5980cc)",
          "MAYBACH S 600 (5980cc)",
          "MAYBACH S 600 (5980cc)",
          "S GUARD (5980cc)",
          "S GUARD (5980cc)",
          "MAYBACH S 600 GUARD (5980cc)",
          "MAYBACH S 600 GUARD (5980cc)",
          "S 430 (4300cc)",
          "S 430 (4300cc)",
          "S 500 LONG (5461cc)",
          "S-CLASS 400 (2996cc)",
          "S 350 BE L (3498cc)",
          "S 600 LONG (5513cc)",
          "S 300 L PETROL (2997cc)",
          "S 350 L PETROL AT (3498cc)",
          "S 300 L DIESEL (2987cc)",
          "S 350 L (3199cc)",
          "S 320 CDI (2987cc)",
          "S 280 (2800cc)",
          "S 500 (4966cc)",
          "320 CDI (2987cc)",
          "320 CDI (2987cc)",
          "320 L (2987cc)",
          "320 L (2987cc)",
          "350 CDI LONG BLUE EFFICIENCY (2987cc)",
          "350 CDI LONG BLUE EFFICIENCY (2987cc)",
          "350 CDI L (2987cc)",
          "350 CDI L (2987cc)",
          "S 350 CDI (2987cc)",
          "S 350 CDI (2987cc)",
          "S 350 CDI CONNOISSEUR'S EDITION (2987cc)",
          "S 350 CDI CONNOISSEURS EDITION (2987cc)",
          "S 350D (2987cc)",
          "S 350D (2987cc)",
          "280 AT (2987cc)",
          "280 AT (2987cc)",
          "S 350D BS-VI (2987cc)",
          "S 350D BS-VI (2987cc)",
          "S 300 (2987cc)",
          "S 350 CDI L CBU (2987cc)",
          "320 CDI L (2987cc)",
          "300L CKD WN221 (2987cc)",
          "300 L (2987cc)",
          "350 L (3498cc)",
          "350 L (3498cc)",
          "350 (3498cc)",
          "350 (3498cc)",
          "55 AMG (3498cc)",
          "55 AMG (3498cc)",
          "S 350 AT (3498cc)",
          "S 350 V 221 (3498cc)",
          "S 400 PETROL CC 3498 (3498cc)",
          "S 350 BE L PANAROMIC SUNROOF (3498cc)",
          "500 L (5461cc)",
          "500 L (5461cc)",
          "S 63 AMG (5461cc)",
          "S 63 AMG (5461cc)",
          "S 550 (5461cc)",
          "S 63 AMG 5.5 V8 RHD (5461cc)",
          "S 500 LONG 4 SEAT (CC 5461) (5461cc)",
          "S 500 LONG (CC 5461) (5461cc)",
          "55 AMG (5461cc)",
          "S 500 L (5461cc)",
          "300 L (2997cc)",
          "300 L (2997cc)",
          "S 300 (2997cc)",
          "S 350 L 3.0 PETROL (2997cc)",
          "300L CKD WN221 (2997cc)",
          "S 400 (2996cc)",
          "S400 (2996cc)",
          "S 400 CONNOISSEUR'S EDITION (2996cc)",
          "S 400 CONNOISSEUR'S EDITION (2996cc)",
          "S 450 (2996cc)",
          "S 450 (2996cc)",
          "S 450 MAESTRO EDITION (2996cc)",
          "S 450 MAESTRO EDITION (2996cc)",
          "S 300 L (2996cc)",
          "S 450 5 SEAT (2996cc)",
          "S 400 L (2996cc)",
          "S 450 5 STR (2996cc)",
          "S 500 (4663cc)",
          "S 500 (4663cc)",
          "S 500 CABRIOLET (4663cc)",
          "S 500 CABRIOLET (4663cc)",
          "S 500 BE (4663cc)",
          "S 500 AT (4663cc)",
          "S 500 4STR (4663cc)",
          "S 500 XL 4.7 MAYBACH SKD 5 SEAT (4663cc)",
          "S 500 MAYBACH 4.7 (4663cc)",
          "S 550 XL 4.7 (4663cc)",
          "S 400 PETROL CC 4663 (4663cc)",
          "S 500 L AT V221 ( CC 4663) (4663cc)",
          "S 500 CABRIOLET CC 4663 (4663cc)",
          "S 500 PE 4 SEAT (4663cc)",
          "S 500L CLASSIC AT 7 SPEED (4663cc)",
          "S 500 BEL (CC 4663) (4663cc)",
          "S 500 XL 4.7 MAYBACH SKD (4663cc)",
          "S 500 4.7 V8 (4663cc)",
          "S 500 L CKD (4663cc)",
          "S 500L AT 7 SPEED WV222 (4663cc)",
          "S 500 MAYBACH (4663cc)",
          "S 500 XL (4663cc)",
          "S 500 LAUNCH EDITION (4663cc)",
          "S 500 5 SEATER (4663cc)",
          "S 450 (4663cc)",
          "MAYBACH S 650 (5980cc)",
          "MAYBACH S 650 BS-VI (5980cc)",
          "MAYBACH S 650 BS-VI (5980cc)",
          "W222 MAYBACH S 650 (5980cc)",
          "W222 MAYBACH S 650 (5980cc)",
          "MAYBACH S 680 4MATIC (W223) (5980cc)",
          "MAYBACH S 680 4MATIC (W223) (5980cc)",
          "S65 AMG L (5980cc)",
          "S 65 AMG L (5980cc)",
          "S 680 MAYBACH 4 MATIC Z223 CBU (5980cc)",
          "S 650 MAYBACH 5 SEATER (5980cc)",
          "S 600 XL MAYBACH (5980cc)",
          "S600 GUARD (5980cc)",
          "S65 AMG (5980cc)",
          "MAYBACH S 680 4MATIC HYBRID (5980cc)",
          "S 680 MAYBACH 4MATIC (5980cc)",
          "S 650 MAYBACH (5980cc)",
          "S 560 MAESTRO (3982cc)",
          "S 560 MAESTRO (3982cc)",
          "W222 MAYBACH S 560 MAESTRO EDITION (3982cc)",
          "W222 MAYBACH S 560 MAESTRO EDITION (3982cc)",
          "MAYBACH S 580 4MATIC (W223) (3982cc)",
          "MAYBACH S 580 4MATIC (W223) (3982cc)",
          "S 560 XL MAYBACH 5 SEAT (3982cc)",
          "S 580 MAYBACH MILD HYBRID 5 SEAT (3982cc)",
          "S 560 MAYBACH 5 SEAT (3982cc)",
          "S 580 MAYBACH 4 SEAT (3982cc)",
          "S 560 XL (3982cc)",
          "S 560 MAYBACH 5 STR (3982cc)",
          "S 560 MAYBACH (3982cc)",
          "S 560 MAYBACH MAESTRO EDITION (3982cc)",
          "MAYBACH S 580 4MATIC HYBRID (3982cc)",
          "S 580 MAYBACH (3982cc)",
          "S 350 MAESTRO EDITION (2925cc)",
          "S 350 MAESTRO EDITION. (2925cc)",
          "S 400D 4MATIC (2925cc)",
          "S 400D 4MATIC (2925cc)",
          "S 400D LIMITED EDITION (2925cc)",
          "S 400 D 4M LAUNCH EDITION CBU 5 SEATER (2925cc)",
          "S 400 D LAUCNCH EDITION CBU (2925cc)",
          "S 350 D 4 SEAT (CC 2925) (2925cc)",
          "S 350 D 5 SEAT (CC 2925) (2925cc)",
          "350 D (2925cc)",
          "350 D MAESTRO EDITION (2925cc)",
          "S 350 D 4MATIC (2925cc)",
          "S 450 4MATIC (2999cc)",
          "S 450 4MATIC (2999cc)",
          "S 450 4MATIC FACELIFT (2999cc)",
          "S 450 4MATIC FACELIFT (2999cc)",
          "S 450 CKD BSVI (2999cc)",
          "S 450 LAUCNCH EDITION CBU PETROL (2999cc)",
          "S 420 (4196cc)",
          "S55 (5500cc)",
          "S 600 L (5513cc)",
          "S 600 L (5513cc)",
          "S 600 (5513cc)",
          "S 600 L GUARD (5513cc)",
          "600 L (5513cc)",
          "600 L GUARD (5513cc)",
          "S63 AMG (6300cc)",
          "S 320 (3199cc)",
          "S 320 (3199cc)",
          "320L (3199cc)",
          "S350 (3500cc)",
          "S 220 (2195cc)",
          "S 220 CLASSIC CAR (2195cc)",
          "SEC 560 (5547cc)",
          "SEC 560 5.5 VL 8 (5547cc)",
          "430 (1796cc)",
          "280 (1796cc)",
          "320 CDI (1796cc)",
          "600 (5596cc)",
          "SKD Z223 MMS5804M (2143cc)",
          "S 320 CDI (5987cc)",
          "S 320 (3200cc)",
          "65 AMG (6208cc)",
          "63 AMG (6208cc)",
          "S 63 AMG (6208cc)",
          "S580 MAYBACH (3986cc)",
          "S 280 (2398cc)",
        ],
      },
      {
        model: "E-CLASS",
        variants: [
          "E 200 (1991cc)",
          "E 200 (1991cc)",
          "E250 CDI CLASSIC (2143cc)",
          "E 250 CDI CLASSIC (2143cc)",
          "E350 CABRIOLET (3498cc)",
          "E 350 CABRIOLET (3498cc)",
          "E 350 CABRIOLET (3498cc)",
          "E350 CDI ELEGANCE (2987cc)",
          "E 350 CDI ELEGANCE (2987cc)",
          "E350 COUPE (3498cc)",
          "E 350 COUPE (3498cc)",
          "E 350 COUPE (3498cc)",
          "E 350 COUPE (3498cc)",
          "E 350 D (2987cc)",
          "E 350 D (2987cc)",
          "E 63 AMG (5461cc)",
          "E 63 AMG (5461cc)",
          "E350 (3498cc)",
          "E 350 (3498cc)",
          "E 220 D (1950cc)",
          "E 220D (1950cc)",
          "E 220 D (2143cc)",
          "E 220 D (2143cc)",
          "E 200 EXCLUSIVE (1991cc)",
          "E 200 EXCLUSIVE (1991cc)",
          "E 220 EXPRESSION (1991cc)",
          "E 220 EXPRESSION (1991cc)",
          "E 220D EXCLUSIVE BS-VI (1950cc)",
          "E 400 CABRIOLET (2996cc)",
          "E 400 CABRIOLET (2996cc)",
          "E 400 CABRIOLET (2996cc)",
          "E 400 CABRIOLET (2996cc)",
          "E 350 CDI (2987cc)",
          "E350 CDI (2987cc)",
          "E 200 CGI (1796cc)",
          "E 200 CGI (1796cc)",
          "E 280 CDI (2987cc)",
          "E 280 CDI (2987cc)",
          "E 200 K CLASSIC (1796cc)",
          "E 200 K CLASSIC (1796cc)",
          "E 240 (2597cc)",
          "E 240 (2597cc)",
          "E 250 D (2497cc)",
          "E 250 D (2497cc)",
          "E 200 CGI ELEGANCE (1991cc)",
          "E 230 CLASSIC AT (2497cc)",
          "E 320 (3199cc)",
          "E400 CABRIOLET EDITION (2996cc)",
          "E 200 CGI AVANTGRADE CL (1991cc)",
          "E 230 CLASSIC 2.3 W 210 (2497cc)",
          "E 230 ELEGANCE (AT) (2497cc)",
          "E 240 (2398cc)",
          "E 250 CDI BE AVANTGARDE (2143cc)",
          "E 250 ELEGANCE (AT) (2497cc)",
          "E 280 FL (AT) (2997cc)",
          "E 350 AVANTGRADE 7G (AT) (3498cc)",
          "E 350 CDI AVANTGRADE 7G (AT) (2987cc)",
          "E 350 RSEP AVANTGRADE (3498cc)",
          "W212 E 250 CDI (AT) (2143cc)",
          "E 200 AVANTGRADE (1991cc)",
          "E 200 MY 18 (1991cc)",
          "E 220 D MY 18 (1950cc)",
          "E 350 D MY 18 (2987cc)",
          "2.3L E230 CLASSIC W210 (2295cc)",
          "2.0L E 200 (1991cc)",
          "E 200 V213 (1991cc)",
          "E 350D V213 (2987cc)",
          "E 220D V213 (1950cc)",
          "E 200 MY 19 (1991cc)",
          "E 190 (2997cc)",
          "E 190 CLASSIC (1997cc)",
          "AMG E 53 4MATIC + (2999cc)",
          "E 220 (2199cc)",
          "E 63 S AMG (3982cc)",
          "E 200 K FL CLASSIC (1991cc)",
          "E 260 (2599cc)",
          "E 280 FL (2997cc)",
          "E 300 (1991cc)",
          "E 240 (AT) (2597cc)",
          "E 240 AT (2398cc)",
          "E 350 D ELITE (2925cc)",
          "E 320 CDI (3222cc)",
          "E 250 CDI BE EXCECUTIVE (2143cc)",
          "E 280 CDI FL (2987cc)",
          "E 220 CDI AVANTGRADE (AT) (2148cc)",
          "E 200 CGI CLASSIC (1991cc)",
          "E 220 CDI SPORT EDITION (2148cc)",
          "E 220 CDI AT (2148cc)",
          "E 250 (2717cc)",
          "E 200 K ELEGANCE (1796cc)",
          "E 200 K ELEGANCE (1991cc)",
          "E 230 (2497cc)",
          "E 270 CDI (2685cc)",
          "E 200 (2996cc)",
          "E 350 D AMG LINE (2950cc)",
          "E 220 CDI ELEGANCE (2148cc)",
          "E 250 CDI ELEGANCE (2143cc)",
          "E 220 CDI ELEGANCE (2143cc)",
          "E 220 CDI ELEGANCE (2143cc)",
          "E 220 CDI SPORT EDITION (2143cc)",
          "E 220 CDI SPORT EDITION (2143cc)",
          "E200 CGI CLASSIC (1796cc)",
          "E 200 CGI CLASSIC (1796cc)",
          "E 200 CGI CLASSIC (1796cc)",
          "E 200 CGI AVANTGARDEE CL (1998cc)",
          "E 230 CLASSIC 2.3 L W 210 (2295cc)",
          "E 200 CGI ELEGENCE (1796cc)",
          "E 200 K FL CLASSIC (1796cc)",
          "E 300 (2998cc)",
          "E 280 FL PETROL AT (2997cc)",
          "E 350 RSEP AVANTGARDE (3498cc)",
          "E 350 AVANTGRADE 7G AUTO TRANS (3498cc)",
          "E 250 ELEGENCE PETROL AT (2497cc)",
          "E 220 PETROL (2199cc)",
          "E 230 ELEGENCE PETROL AT (2497cc)",
          "E 230 CLASSIC PETROL AT (2497cc)",
          "E 320 PETROL (3199cc)",
          "E 220 CDI AVANTGARDE AT (2143cc)",
          "E 220 CDI DIESEL AT (2148cc)",
          "E 250 CDI (CLASSIC) (DIESEL) (2143cc)",
          "E 250 CDI BE AVANTEGARDE (2143cc)",
          "E 280 CDI DIESEL (2987cc)",
          "E 63 AMG (6208cc)",
          "E 350 D (2996cc)",
          "E 220 D (2996cc)",
          "E 200 (2143cc)",
          "E220 CDI BLUEEFFICIENCY (2143cc)",
          "E220 CDI BLUEEFFICIENCY (2143cc)",
          "E 250 CDI AVANTGARDE (2143cc)",
          "E 250 CDI AVANTGARDE (2143cc)",
          "E 250 CDI LAUNCH EDITION (2143cc)",
          "E 250 CDI LAUNCH EDITION (2143cc)",
          "E250 CDI BLUEEFFICIENCY (2143cc)",
          "E 250 CDI BLUEEFFICIENCY (2143cc)",
          "E 220 CDI AVANTGRADE AT (2143cc)",
          "E 220 CDI AVANTGRADE AT (2143cc)",
          "E 220 CDI ELEGANCE SUNROOF (2143cc)",
          "E 220 CDI ELEGANCE SUNROOF (2143cc)",
          "E 250 CDI EDITION E (2143cc)",
          "E 250 CDI EDITION E (2143cc)",
          "E 250 CDI (2143cc)",
          "E 250 CDI (2143cc)",
          "E 250 CDI EXCECUTIVE (2143cc)",
          "E 250 CDI AVANTGARDE AT (2143cc)",
          "E 250 CDI AVANTEGARDE (2143cc)",
          "E250 DIESEL (2143cc)",
          "220 CDI (2143cc)",
          "E 200 CDI (2143cc)",
          "E 200 CGI 5 STR (2143cc)",
          "E 250 CDI AVANTGARDE EDITION E (2143cc)",
          "E 200 AVANTGARDE 2.0 DISEL (2143cc)",
          "E 250 CDI 2.2 AVANTGARDE (2143cc)",
          "E 250 CDI AVANTEGARDE BS (2143cc)",
          "E 250 CDI AVANTEGARDE VE (2143cc)",
          "V213 E220 D EXCLUSIVE MY22 (2143cc)",
          "250CDIBLUEEFFICIENCY (2143cc)",
          "250 CDI AVANTGARDE (2143cc)",
          "E 220 CDI MT (2143cc)",
          "E220 CDI AVANTGARDE (2143cc)",
          "E 220 CDI AT (2143cc)",
          "220 (4266cc)",
          "220 (4266cc)",
          "250 D ( W210 ) (4266cc)",
          "250 D ( W210 ) (4266cc)",
          "230 (2496cc)",
          "230 (2496cc)",
          "230 CLASSIC AT (2496cc)",
          "230 CLASSIC AT (2496cc)",
          "200 K CLASSIC (1796cc)",
          "200 K CLASSIC (1796cc)",
          "200 K ELEGANCE (1796cc)",
          "200 K ELEGANCE. (1796cc)",
          "E200 CGI BLUEEFFICIENCY (1796cc)",
          "E200 CGI BLUEEFFICIENCY (1796cc)",
          "E 200 CGI AVANTGRADE (1796cc)",
          "E 200 CGI AVANTGRADE (1796cc)",
          "240 D ( W123 ) (1796cc)",
          "240 D ( W123 ) (1796cc)",
          "E 200 CGI AVANTGARDE (1796cc)",
          "E 200 CGI AVANTGARDE (1796cc)",
          "200 D (1796cc)",
          "220 CGI (1796cc)",
          "E 200 PANAROMIC (1796cc)",
          "230 CLASSIC AT (1796cc)",
          "230 (1796cc)",
          "E 230 ELEGANCE (1796cc)",
          "200 CGI BLUE EFFICIENCY (1796cc)",
          "E250-ELEGANCE (1796cc)",
          "E 250 (1796cc)",
          "220 CDI AT (2148cc)",
          "220 CDI AT (2148cc)",
          "220 CDI MT (2148cc)",
          "220 CDI MT (2148cc)",
          "E 220 CDI AVANTGARDE (2148cc)",
          "E 220 CDI AVANTGARDE (2148cc)",
          "E 220 CDI ELEGANCE FE (2148cc)",
          "E 220 CDI ELEGANCE FE (2148cc)",
          "E 280 CDI AVANTGARDE (2148cc)",
          "E 280 CDI AVANTGARDE (2148cc)",
          "E 220 CDI (2148cc)",
          "E 220 CDI CLASSIC (2148cc)",
          "E 220 CDI ELEGANCE SPORTS (2148cc)",
          "E 250 CDI AVANTGARDE (2148cc)",
          "240 V6 AT (2597cc)",
          "240 V6 AT (2597cc)",
          "240 V6 MT (2597cc)",
          "240 V6 MT (2597cc)",
          "E 240 AT 2.6 (2597cc)",
          "E 240 MT 2.6 (2597cc)",
          "E 240 AT (2597 CC) (2597cc)",
          "E 240 MT (2597 CC) (2597cc)",
          "250 D (W124) (2597cc)",
          "250 D (W210) (2597cc)",
          "250 D ( W124 ) (2497cc)",
          "250 D ( W124 ) (2497cc)",
          "E230 ELEGANCE (2497cc)",
          "E 230 ELEGANCE (2497cc)",
          "E 230 ELEGANCE (2497cc)",
          "E250 CDI ELEGANCE (2497cc)",
          "E250 CDI ELEGANCE (2497cc)",
          "E250 ELEGANCE (2497cc)",
          "E250 ELEGANCE (2497cc)",
          "E 250 (2497cc)",
          "E 250 (2497cc)",
          "E 250 ELEGENCE AT (2497cc)",
          "E 230 ELEGENCE AT (2497cc)",
          "E 250 PETROL (2497cc)",
          "E 230 PETROL AT (2497cc)",
          "E 220 (2497cc)",
          "W211-E230 AUTOMATIC TRANSMISSION- BS III (2497cc)",
          "270 CDI (2685cc)",
          "270 CDI (2685cc)",
          "280 CDI ELEGANCE (2987cc)",
          "280 CDI ELEGANCE (2987cc)",
          "E350 CDI AVANTGARDE (2987cc)",
          "E350 CDI AVANTGARDE (2987cc)",
          "E350 CDI BLUEEFFICIENCY (2987cc)",
          "E350 CDI BLUEEFFICIENCY (2987cc)",
          "E 350 D BS-VI (2987cc)",
          "E 350 CDI EDITION E (2987cc)",
          "E 350 CDI EDITION E (2987cc)",
          "300 D (2987cc)",
          "300 D (2987cc)",
          "E 320 CDI (2987cc)",
          "E 350 CDI AVANTGRADE AT (2987cc)",
          "E 350 D 3.0 LWB (2987cc)",
          "E 280 ELEGANCE FE (2987cc)",
          "E 350 D AVANTGARDE 3.0 (2987cc)",
          "E 350 D 9G TRONIC AT (2987cc)",
          "E 280 3.0 CDI ELEGANCE (2987cc)",
          "E 280 CDI STANDARD - 29.75 HP (2987cc)",
          "STANDARD HORSE POWER 29.75 (2987cc)",
          "E 350 CDI AVANTGARDE EDITION E (2987cc)",
          "E 350 CDI AVANTGARDE CKD (2987cc)",
          "E 350 CDI BEI (2987cc)",
          "E 350 CDI AVANTGRADE 7G AUTO TRANS (2987cc)",
          "W212 E350 CDI AT (2987cc)",
          "E 350 D AVANTGARDE (2987cc)",
          "200 CDI (2987cc)",
          "E350 AVANTGARDE (2987cc)",
          "280 ELEGANCE (2997cc)",
          "280 ELEGANCE (2997cc)",
          "E 280. (2997cc)",
          "300 D (2997cc)",
          "E230 ELEGANCE (1991cc)",
          "E 200 EXPRESSION (1991cc)",
          "E 200 EXPRESSION (1991cc)",
          "E 200 EXCLUSIVE BS-VI (1991cc)",
          "E 200 EDITION E (1991cc)",
          "E 200 EDITION E (1991cc)",
          "E 200 EXPRESSION FACELIFT (1991cc)",
          "E 200 EXPRESSION FACELIFT (1991cc)",
          "E 200 EXCLUSIVE FACELIFT (1991cc)",
          "E 200 EXCLUSIVE FACELIFT (1991cc)",
          "E 200 BSVI (1991cc)",
          "E 200 BLUE EFFICIENCY AT (1991cc)",
          "E 200 AVANTGARDE 3 PETROL (1991cc)",
          "E 200 CGI 2.0L PETROL BLUE EFFICIENCY (1991cc)",
          "E 200 2.0 PANAROMIC PETROL (1991cc)",
          "E 200 AVANTGARDE 2.0 PETROL (1991cc)",
          "E 200 LWB (1991cc)",
          "E 200 EXCLUSIVE 2.0 PETROL (1991cc)",
          "E 200 SEDAN RHD IMPORTED (1991cc)",
          "E 200 CGI 2.0L PETROL CLASSIC (1991cc)",
          "E 200 CGI 2.0L PETROL ELEGANCE (1991cc)",
          "E 200 CGI 2.0L PETROL AVANTGARDE (1991cc)",
          "E 200 CGI 2.0L PETROL AVANTGARDE VE (1991cc)",
          "E 200 2.0L PETROL (1991cc)",
          "200 EXCLUSIVE (1991cc)",
          "200 EXPRESSION (1991cc)",
          "E 200 AVANTGARDE (1991cc)",
          "E 200 CGI AVANTGARDE (1991cc)",
          "E 63 AMG (3982cc)",
          "E 63 AMG (3982cc)",
          "E 63 AMG S 4MATIC+ (3982cc)",
          "E 63 AMG S 4MATIC+ (3982cc)",
          "E 63 AMG S 4MATIC+ BS-VI (3982cc)",
          "E 63 S AMG 4.0 PETROL (3982cc)",
          "63 AMG. (3982cc)",
          "63 AMG S 4MATIC PLUS (3982cc)",
          "E 63 AMG S 4MATIC PLUS (P) (3982cc)",
          "E 220D EXPRESSION (1950cc)",
          "E220 D EXPRESSION (1950cc)",
          "E 220D EXCLUSIVE (1950cc)",
          "E 220 D EXCLUSIVE (1950cc)",
          "E 220D EXPRESSION BS-VI (1950cc)",
          "200 D EXCLUSIVE (1950cc)",
          "200 D EXCLUSIVE (1950cc)",
          "E 220D EXPRESSION FACELIFT (1950cc)",
          "E 220D EXPRESSION FACELIFT (1950cc)",
          "E 220D EXCLUSIVE FACELIFT (1950cc)",
          "E 220D EXCLUSIVE FACELIFT (1950cc)",
          "E 220 D ALL TERRAIN (1950cc)",
          "E 220 D ALL TERRAIN (1950cc)",
          "E 220 D ALL TERRAIN (1950cc)",
          "E 220D EXCLUSIVE BS6 (1950cc)",
          "E 220 D V 213 CC 1950 (1950cc)",
          "E 220 D AVANTGARDE 2.0 AT (1950cc)",
          "E 220 D LWB (1950cc)",
          "E 220 D  2.0 V 213 AVANTGARDE 3 (1950cc)",
          "200 D LIMOUSINE (1950cc)",
          "E 220 D EXCLUSIVE MY 22 (1950cc)",
          "220 D EXCLUSIVE (1950cc)",
          "220 D EXPRESSION (1950cc)",
          "E 350 AVANTGARDE (3498cc)",
          "E 350 AVANTGARDE (3498cc)",
          "E 350 AVANTGARDE (3498cc)",
          "E 350 BENZ W212 (3498cc)",
          "E 350 L (3498cc)",
          "E 350 AVANTGRADE AT (3498cc)",
          "E 350 CGI LIMOUSINE (3498cc)",
          "E 350 CGI LIMOUSINE (3498cc)",
          "E 350 COUPE 4 STR (3498cc)",
          "E 350 CABRIOLET 5SEATER (3498cc)",
          "E 350 5 SEATER CABRIOLET (3498cc)",
          "E 350 MANGO ALANITE GRAY CABRIOLET (3498cc)",
          "E 350 PALLADIUM SIVER MATT CABRIOLET (3498cc)",
          "E 350 DIAMOND WHITE CABRIOLET (3498cc)",
          "E 350 MAGNO ALANITE GRAY CABRIOLET (3498cc)",
          "E 350 METALLIC CABRIOLET (3498cc)",
          "E 350 PALLADIUM SILVER MATT CABRIOLET (3498cc)",
          "E 350 COUPE (CBU) (M) (3498cc)",
          "E 350 CGI (3498cc)",
          "E 400 CABRIOLET (3498cc)",
          "E 350 D AMG LINE (2925cc)",
          "E 350 D AMG LINE. (2925cc)",
          "E 350 ELITE BS6 (2925cc)",
          "E 350 D AMG (2925cc)",
          "350 D ELITE (2925cc)",
          "AMG E53 4MATIC PLUS (2999cc)",
          "AMG E53 4MATIC PLUS (2999cc)",
          "AMG E53 CABRIOLET 4MATIC (2999cc)",
          "AMG E53 CABRIOLET 4MATIC (2999cc)",
          "E 53 AMG GLE 4MATIC PLUS (2999cc)",
          "E 53 AMG 4MATIC PLUS (2999cc)",
          "E210 (2295cc)",
          "E 230 CLASSIC 2.3 L PETROL W 210 (2295cc)",
          "E 230 CLASSIC (2295cc)",
          "ELEGANCE 230 (2295cc)",
          "E 350 BENZ W211 (3500cc)",
          "E 220 K (2199cc)",
          "220 (2199cc)",
          "E 240 AT 2.4 (2398cc)",
          "E 240 2.4 (2398cc)",
          "E 240 MT (2398cc)",
          "E AMG 63 (6208cc)",
          "E 63 AMG 6.2 V8 PETROL (6208cc)",
          "E 400 CABRIOLET 5STR (2996cc)",
          "E 400 CABRIOLET EDITION E (2996cc)",
          "E 400 CABRIOLET 4 SEATER (2996cc)",
          "E 400 (2996cc)",
          "E 230 4 SEAT (2299cc)",
          "E 230 2.3 ELEGANCE PETROL (2300cc)",
          "E 63 AMG 5.5 V8 PETROL (5461cc)",
          "63 AMG (5461cc)",
          "CKD V213 E350D ELITE MY 22 (2134cc)",
          "E 230 CLASSIC (2298cc)",
          "E 230 CDI ELEGANCE (2298cc)",
          "55 AMG (5439cc)",
          "E 250 D NEW SHAPE (2498cc)",
          "E 270 CDI (2699cc)",
        ],
      },
      {
        model: "G-CLASS",
        variants: [
          "G 350D (2925cc)",
          "G 350D (2925cc)",
          "G55 AMG 7STR (5439cc)",
          "GLS 350D (2987cc)",
          "AMG 63 (5461cc)",
          "G 55 AMG 5 STR (5439cc)",
          "G 63 AMG (3982cc)",
          "G 350 D BSVI (2925cc)",
          "ADVENTURE EDITION (2925cc)",
          "G 400 D AMG LINE (2925cc)",
          "350 D (2925cc)",
          "350 D 4MATIC (2925cc)",
          "G55 AMG 7 SEATER (5439cc)",
          "G55 AMG 5 SEATER (5439cc)",
          "G 63 AMG (5461cc)",
          "G 63 AMG (5461cc)",
          "G 63 AMG CRAZY COLOUR EDITION (5461cc)",
          "G 63 AMG CRAZY COLOUR EDITION (5461cc)",
          "G 63 EDITION 463 (5461cc)",
          "G 63 EDITION 463 (5461cc)",
          "500 GRAND EDITION (5461cc)",
          "500 GRAND EDITION (5461cc)",
          "55 AMG GRAND EDITION (5461cc)",
          "55 AMG GRAND EDITION (5461cc)",
          "G63 AMG AT (5461cc)",
          "G63 AMG EDITION 463 5 SEATER (5461cc)",
          "G63 AMG 5 SEATER 7 SPEED AT (5461cc)",
          "500 (5461cc)",
          "G 63 AMG (3998cc)",
          "G 63 AMG. (3998cc)",
          "G 63 AMG BS-VI (3998cc)",
          "G 63 AMG BS-VI (3998cc)",
          "G 55 AMG (5439cc)",
          "G 55 AMG (5439cc)",
          "G55 AMG (5439cc)",
          "G 63 AMG (5439cc)",
          "G63 AMG (5439cc)",
          "55 AMG (5439cc)",
          "500 (3498cc)",
          "500 (3498cc)",
          "300 (3498cc)",
          "300 (3498cc)",
          "G 63 AMG 4MATIC BSVI (3982cc)",
          "G63 AMG 4.0 L 5 SEATER CBU (3982cc)",
          "G63 AMG 4MATIC (3982cc)",
          "G 63 AMG 4MATIC CC 3982 (3982cc)",
          "350 BLUE TEC 650 (2978cc)",
        ],
      },
      {
        model: "R-CLASS",
        variants: [
          "3.0 R 350 4MATIC 7STR (2987cc)",
          "R 350 4MATIC 7STR (3498cc)",
          "R 107 (3000cc)",
          "R 350 CDI AT (2987cc)",
          "R 350 4 MATIC 7 SEATER (3498cc)",
          "R 350 4 MATIC 3.0L 7 SEATER (2987cc)",
          "R 350 4MATIC (3496cc)",
          "R 350 4MATIC (3496cc)",
          "R350L CDI (3496cc)",
          "R350L CDI (3496cc)",
          "R 350 (3496cc)",
          "R 350 DIESEL (3498cc)",
          "R 350 DIESEL (3498cc)",
          "R 350 (3498cc)",
          "R 350 4MATIC 5 STR (3498cc)",
          "R 350 4 MATIC 5 SEATER (3498cc)",
          "R 350 4MATIC (3498cc)",
          "R 350 CDI (2987cc)",
          "R 350 CDI (2987cc)",
          "R 350 4 MATIC 3.0L 5 SEATER (2987cc)",
          "R 350 CDI 4MATIC (2987cc)",
          "R 350 4MATIC (2987cc)",
          "R 500 L RHD (4966cc)",
          "500 DIESEL (2497cc)",
          "500 PETROL (5461cc)",
          "CBU R-171 (1795cc)",
          "R 500 L 4MATIC (4986cc)",
        ],
      },
      {
        model: "SL-CLASS",
        variants: [
          "SL 500 (4966cc)",
          "SL 500 (4966cc)",
          "SL 500 (4966cc)",
          "SL 350 (3498cc)",
          "SL 350 (3498cc)",
          "SL 500 R230 (4966cc)",
          "SL 320 (3199cc)",
          "SL 55 AMG ROADSTER (5439cc)",
          "280 SL (2745cc)",
          "380 SL (3818cc)",
          "300 SL 24 (2962cc)",
          "SL 63 AMG (5980cc)",
          "SL 500 (5461cc)",
          "SL 63 AMG (6208cc)",
          "SL 63 AMG (6208cc)",
          "SL 63 AMG (6208cc)",
          "280 SL (2476cc)",
          "SL (5461cc)",
          "600 ROADSTER (5461cc)",
          "600 ROADSTER (5461cc)",
          "SL 500 5.5 (5461cc)",
          "SL 500 (5461 CC) (5461cc)",
          "500 (5461cc)",
          "SL 65 AMG (5980cc)",
          "SL 65 AMG (5980cc)",
          "55 AMG ROADSTER (3498cc)",
          "55 AMG ROADSTER (3498cc)",
          "300 (3498cc)",
          "320 (3498cc)",
          "65 AMG ROADSTER (3498cc)",
          "350 (3498cc)",
          "350 (3498cc)",
          "SL 300 (2962cc)",
          "SL 500 5.0 (4966cc)",
          "SL 500 R 230 (4966 CC) (4966cc)",
          "SL 500 (4966 CC) (4966cc)",
          "SL 500 (4663 CC) (4663cc)",
          "SL 190 (1897cc)",
          "SL 280 (2476cc)",
          "SL 280 (2996cc)",
          "SL 380 (3818cc)",
          "SL 380 (3839cc)",
        ],
      },
      {
        model: "GL-CLASS",
        variants: [
          "GL 320 CDI (2987cc)",
          "GL 320 CDI (2987cc)",
          "GL 500 4MATIC 7STR (5461cc)",
          "GL 500 4MATIC (5461cc)",
          "GL 63 AMG (5461cc)",
          "GL 350 CDI EXECUTIVE (2987cc)",
          "GL 350 CDI GRAND STANDARD (2987cc)",
          "GL 350 CDI GRAND LUXURY (2987cc)",
          "GL 350 CDI LX (2987cc)",
          "GL 350 CDI GRAND EXECUTIVE (2987cc)",
          "GL 420 CDI RHD AT (3996cc)",
          "GL 320 CDI (3200cc)",
          "GL 500 4MATIC 7 SEATER (5461cc)",
          "3.0 GRAND EDITION EXECUTIVE (2987cc)",
          "GL 350 CDI. (2987cc)",
          "GL 350 CDI (2987cc)",
          "GL 350 CDI LUXURY (2987cc)",
          "GL 320 CDI 3.0 5 SEATER (2987cc)",
          "GL 320 CDI  3.0 (2987cc)",
          "350 CDI 6 SEATER (2987cc)",
          "GL 350 CDI BE 3.0 DIESEL 7 SEATER (2987cc)",
          "GL 350 CDI BE 3.0 DIESEL 5 SEATER (2987cc)",
          "GL 350 CDI 3.0 LUXURY GRAND EDITION 7 SEATER (2987cc)",
          "GL 350 CDI CBU (2987cc)",
          "GL 350 CDI 3.0 DIESEL 7 SEATER (2987cc)",
          "GL 350 CDI 4MATIC LUXURY (2987cc)",
          "3.0 GRAND EDITION LUXURY (2143cc)",
          "350 CDI BLUEEFFICIENCY (2143cc)",
          "63 AMG (5461cc)",
          "63 AMG (5461cc)",
          "GL 500 (5461cc)",
          "63 AMG 5 STR (5461cc)",
          "GL 63 AMG 7 SEATER (5461cc)",
          "500 (5461cc)",
          "GL 420 CDI AT (3996cc)",
          "GL 500 5 SEATER (4663cc)",
        ],
      },
      {
        model: "ML CLASS",
        variants: [
          "ML 63 AMG (5461cc)",
          "ML 350 3.7 5STR (3498cc)",
          "ML 350 CDI LIMITEDED 5STR (2987cc)",
          "ML 350 CDI CORPORATE 5STR (2987cc)",
          "ML 350 CDI LUXURY 7STR (AT) (2987cc)",
          "ML 420 CDI (3996cc)",
          "ML 350 CDI CORPORATE EDITION (2987cc)",
          "ML 320 CDI (2987cc)",
          "ML 270 CDI (2688cc)",
          "ML 350 CDI GRAND EXECUTIVE (2987cc)",
          "ML 250 CDI 4MATIC (2143cc)",
          "ML 350 CDI GRAND SPORTS (2987cc)",
          "ML 350 CDI (2987cc)",
          "ML 350 CDI EDITION 2 (2987cc)",
          "ML 350 CDI EDITION 1 (2987cc)",
          "ML 350 CDI LIMITEDED 5 (2987cc)",
          "ML 350 CDI CORPORATEED 5 (2987cc)",
          "ML 350 CDI LUXURY AT 7 STR (2987cc)",
          "ML 250 CDI AT (2987cc)",
          "ML 350 CDI LUXURY AT (2987cc)",
          "ML 350 CDI  SPORTS (2987cc)",
          "ML 350 CDI LAUNCH EDITION (2987cc)",
          "ML 250 CDI 5 SEAT (2987cc)",
          "ML 250 CDI AT 5 SEAT (2987cc)",
          "ML 350 CDI CORPORATE EDITION 5 SEATER (2987cc)",
          "ML 320 CDI (7 SEATER) (2987cc)",
          "ML 350 CDI 4MATIC (2987cc)",
          "ML 350 CDI CBU LIMITED EDITION 5 SEATER (2987cc)",
          "ML 250 CDI AT 7 SEAT (2987cc)",
          "ML 350 CDI LUXURY AT 5 STR (2987cc)",
          "ML 270 CDI CC 2685 (2685cc)",
          "ML 63 AMG 5 SEAT (5461cc)",
        ],
      },
      {
        model: "ML-CLASS",
        variants: [
          "ML 350 CDI (2987cc)",
          "ML 430 (4266cc)",
          "ML 350 4MATIC (3498cc)",
          "ML 350 3.7 PETROL 5 SEATER (3724cc)",
          "ML 270 CDI BASIC (2000cc)",
          "ML 250 CDI (2143cc)",
          "ML 420 CDI (3986cc)",
          "ML 420 CDI (3986cc)",
          "ML 350 (3498cc)",
          "ML 350 (3724cc)",
        ],
      },
      {
        model: "A-CLASS",
        variants: [
          "A 180 CDI STYLE (2143cc)",
          "A 180 CDI STYLE (2143cc)",
          "A 180 NIGHT EDITION (1595cc)",
          "A 180 NIGHT EDITION (1595cc)",
          "A 200D NIGHT EDITION (2143cc)",
          "A 200 D NIGHT EDITION (2143cc)",
          "A180 STYLE (1595cc)",
          "A180 CDI AVANTGRADE (1991cc)",
          "A35 AMG (1998cc)",
          "200 (1332cc)",
          "200D (1950cc)",
          "A45 AMG S 4MATIC PLUS (1991cc)",
          "A 200 D SPORT (2143cc)",
          "A 200 LIMOUSINE (1332cc)",
          "A 180 SPORT (1595cc)",
          "A180 CDI (2147cc)",
          "A 180 CDI AVANTGRADE (2143cc)",
          "A180 SPORT (2143cc)",
          "LIMOUSINE 200 (1332cc)",
          "CKD V177 A200 MY23 (1332cc)",
          "A 200 LIMOUSINE PETROL (CC 1332) (1332cc)",
          "CKD V177 A200 (1332cc)",
          "LIMOUSINE 200D (1950cc)",
          "A 200D LIMOUSINE (1950cc)",
          "A 200 D LIMOUSINE DIESEL (CC 1950) (1950cc)",
          "A 220 D 9G TRONIC DIESEL (1950cc)",
          "A 200D (1950cc)",
          "LIMOUSINE AMG 35 4MATIC (1991cc)",
          "LIMOUSINE AMG 35 4MATIC (1991cc)",
          "AMG A45 S 4MATIC PLUS (1991cc)",
          "AMGA35 4M (1991cc)",
          "A 35 AMG 4MATIC (1991cc)",
          "A 180 (1595cc)",
          "A 180 (1595cc)",
          "A 180 SPORT PETROL (1595cc)",
          "A 180 SPORT PETROL (1595cc)",
          "A180 UEFA SPORT EDITION (1595cc)",
          "CBU W-176 A 180 (1595cc)",
          "A 180 STYLE PETROL (1595cc)",
          "A200 CDI SPORT (2143cc)",
          "A200 CDI SPORT (2143cc)",
          "EDITION 1 (2143cc)",
          "EDITION 1 (2143cc)",
          "A 200D (2143cc)",
          "A 200D (2143cc)",
          "A 200D UEFA SPORT EDITION (2143cc)",
          "200 CDI STYLE (2143cc)",
          "A 200 D SPORTS (2143cc)",
          "A 200 CDI STYLE (2143cc)",
          "A 200 CDI SPORTS (2143cc)",
          "A 180 CDI EDITION 1 (2143cc)",
          "A 200 CDI (2143cc)",
          "A 180 CDI (2143cc)",
          "35 AMG LIMOUSINE (1998cc)",
          "170 (1699cc)",
          "1.7 PETROL (1699cc)",
          "A 200D (1995cc)",
          "A 200 CDI SPORT (2987cc)",
        ],
      },
      {
        model: "V CLASS",
        variants: [
          "V 220D EXCLUSIVE (2143cc)",
          "V 220D EXCLUSIVE (1950cc)",
          "V 220D EXPRESSION (2143cc)",
          "MICRO POLO (1950cc)",
          "V 220 D EXPRESSION 7 SEAT (2143cc)",
          "V 220 D DIESEL 7 SEAT (2143cc)",
          "V 220 DAVA DIESEL 6 SEATER (2143cc)",
          "V 220 SE DIESEL 5 SEAT (2143cc)",
          "V 220 D 6 SEAT (2143cc)",
          "V 220 D EXCLUSIVE 6 SEAT (2143cc)",
          "220 D EXCLUSIVE (2143cc)",
          "220 D EXPRESSION (2143cc)",
          "ELITE (1950cc)",
          "V 220 D ELITE 7 SEAT (1950cc)",
          "V 220 D EXPRESSION 7 SEAT (1950 CC) (1950cc)",
          "V 220 D EXCLUSIVE 6 SEAT (1950 CC) (1950cc)",
        ],
      },
      {
        model: "EQS",
        variants: [
          "53 4MATIC PLUS AMG (2359cc)",
          "580 4MATIC (2359cc)",
          "53 4MATIC PLUS (108cc)",
          "580 4MATIC (107.8cc)",
          "53 AMG 4MATIC PLUS (1501cc)",
          "SKD V297 580  4MATIC (1501cc)",
          "580  4MATIC (1501cc)",
          "53 4MATIC PLUS (560cc)",
          "53 4MATIC PLUS AMG (107800cc)",
          "580 4MATIC (107800cc)",
          "580 4MATIC (385cc)",
          "580 4MATIC (2996cc)",
        ],
      },
      {
        model: "CLS",
        variants: [
          "CLS 320 CDI ELEGANCE (AT) (2987cc)",
          "CLS 350 CDI (2987cc)",
          "CLS 350 CGI (3498cc)",
          "CLS 500 (5461cc)",
          "CLS 55 (5439cc)",
          "CLS 300 D (1950cc)",
          "CLS 350 CDI (AT) (3498cc)",
          "250 CDI (2143cc)",
          "320 CDI (3498cc)",
          "320 CDI (3498cc)",
          "350 STANDARD (3498cc)",
          "350 (3498cc)",
          "320 CDI (2987cc)",
          "320 (2987cc)",
          "350 CDI. (2987cc)",
          "CLS 63 AMG (6208cc)",
          "350 CDI (1796cc)",
          "350 (1796cc)",
          "300D (1950cc)",
        ],
      },
      {
        model: "CLK",
        variants: [
          "CLK 320 CABRIOLET (3199cc)",
          "CLK 350 CABRIOLET (3498cc)",
          "230 K (1796cc)",
          "320 CABRIOLET (1796cc)",
          "320 COUPE (1796cc)",
          "320 CABRIOLET. (3199cc)",
          "320 COUPE (3199cc)",
          "350 CABRIOLET. (3498cc)",
          "350 (3498cc)",
          "350 STD (3498cc)",
          "500 CABRIOLET (4966cc)",
          "500 CABRIOLET (5461cc)",
          "500 COUPE (5461cc)",
          "55 AMG CABRIOLET (5461cc)",
          "55 AMG COUPE (5461cc)",
          "500 COUPE (5445cc)",
          "55 AMG CABRIOLET (5445cc)",
          "55 AMG COUPE (5445cc)",
          "350 (6208cc)",
        ],
      },
      {
        model: "B-CLASS",
        variants: [
          "B 180 SPORT (1595cc)",
          "B 180 SPORT (1595cc)",
          "B 180 NIGHT EDITION (1595cc)",
          "B 180 NIGHT EDITION (1595cc)",
          "B 200D NIGHT EDITION (2143cc)",
          "B 180 SPORTS AT (1595cc)",
          "B 200 CDI SPORT (2143cc)",
          "B 180 CDI (2147cc)",
          "B 180 (1595cc)",
          "DIESEL EDITION 1 (2143cc)",
          "B 180 SPORT (2143cc)",
          "B 200 SPORT CDI (2143cc)",
          "B 200 SPORT CDI (2143cc)",
          "B 200 NIGHT EDITION (2143cc)",
          "B 200 NIGHT EDITION (2143cc)",
          "B180 (2143cc)",
          "B180 (2143cc)",
          "EDITION 1 (2143cc)",
          "EDITION 1 (2143cc)",
          "B 200 D SPORT (2143cc)",
          "B 200 D SPORTS (2143cc)",
          "B 200 D (2143cc)",
          "B 200 CDI STYLE (2143cc)",
          "B 180 CDI STYLE (2143cc)",
          "200 CDI SPORT (2143cc)",
          "B200 CDI (2143cc)",
          "B 180 CDI (2143cc)",
          "B 180 CDI STYLE (2147cc)",
          "B 180 CDI STYLE (2147cc)",
          "B 180 CDI SPORTS TOURER (2147cc)",
          "B 180 CDI BE SPORTS TOURER (2147cc)",
          "B 180 CDI ED 1 (2147cc)",
          "STD 5 STR (2997cc)",
          "B 180 STYLE PETROL (1595cc)",
          "B 180 SPORTS (1595cc)",
        ],
      },
      {
        model: "EQB",
        variants: [
          "350 4MATIC (1990cc)",
          "300 4MATIC (1990cc)",
          "300 4MATIC (66.5cc)",
          "CBU X-243 EQB3004M (168000cc)",
          "350 MY 23 (168000cc)",
          "CBU X-243 EQB3504M (168000cc)",
          "300 4MATIC (168000cc)",
          "300 4MATIC 7 SEAT (1501cc)",
          "350 4MATIC 7 SEAT (1501cc)",
          "350 4MATIC (215cc)",
          "300 4MATIC (168cc)",
          "300 4MATIC (2996cc)",
        ],
      },
      {
        model: "GLB",
        variants: [
          "200 PROGRESSIVE LINE BSVI (1332cc)",
          "220D PROGRESSIVE LINE BSVI (1950cc)",
          "220D 4MATIC AMG LINE BSVI (1950cc)",
          "200 (1332cc)",
          "GLB 200 CLASS (1332cc)",
          "200 PROGRESSIVE LINE (1332cc)",
          "220D (1950cc)",
          "220D 4MATIC AMG LINE (1950cc)",
          "220D PROGRESSIVE LINE (1950cc)",
          "300 4MATIC (66.5cc)",
        ],
      },
      {
        model: "SLS",
        variants: ["SLS AMG COUPE V8 (6208cc)", "AMG COUPE 2 STR (6208cc)"],
      },
      {
        model: "VINTAGE",
        variants: [
          "VINTAGE CAR (2581cc)",
          "W141 (2148cc)",
          "C CLASS (2900cc)",
          "C CLASS (1991cc)",
          "E CLASS (2148cc)",
          "V 170 (1685cc)",
          "1948 (4000cc)",
          "BENZ 250 (4622cc)",
          "W121 (1200cc)",
          "600 LIMOUSINE (6332cc)",
          "W115 (2400cc)",
          "W 115 (1590cc)",
          "W112 (1897cc)",
          "W113 (5675cc)",
          "DAIMLER AG (2550cc)",
          "VINTAGE (1998cc)",
          "VINTAGE (2200cc)",
          "VINTAGE (2778cc)",
          "VINTAGE (1897cc)",
          "VINTAGE (1790cc)",
          "VINTAGE (2306cc)",
          "VINTAGE (4973cc)",
          "VINTAGE (1340cc)",
          "VINTAGE (3408cc)",
          "VINTAGE (1988cc)",
          "220 E (2200cc)",
          "SL CLASS 280 L (2745cc)",
          "-1985 (2148cc)",
          "-1937 (2148cc)",
          "-1972 (796cc)",
          "-1974 (796cc)",
          "-1961 (2987cc)",
        ],
      },
      {
        model: "A 200 LIMOUSINE",
        variants: ["D P MY-2021 (1950cc)"],
      },
      {
        model: "W123",
        variants: ["230 SEDAN (1884cc)"],
      },
      {
        model: "BENZ 209",
        variants: ["3.0 209 (2900cc)"],
      },
      {
        model: "D CLASS",
        variants: ["190 E (2497cc)", "190 D (1997cc)", "200 D (2496cc)"],
      },
      {
        model: "W126",
        variants: ["4.0 PETROL (3880cc)"],
      },
      {
        model: "MAYBACH S-CLASS",
        variants: [
          "X222 S560 XL (3982cc)",
          "X222 S650 XL (5980cc)",
          "X222 S560 (3982cc)",
          "MAYBACH S500 (4663cc)",
          "MAYBACH S600 (5980cc)",
          "MAYBACH S600 GUARD (5980cc)",
          "S 350 CDI (2987cc)",
          "S 350D CONNOISSEURS EDITION (2987cc)",
          "S 400 (2996cc)",
          "S 400 CONNOISSEURS EDITION (2996cc)",
          "S 500 COUPE (4663cc)",
          "S 63 AMG (5461cc)",
          "S 63 AMG COUPE (5461cc)",
          "S GUARD (5980cc)",
          "S 500 (4663cc)",
          "S580 5 STR (3982cc)",
          "S580 (3982cc)",
          "S680 4 STR (5980cc)",
          "S680 (5980cc)",
          "MAYBACH S500 (5980cc)",
          "S 350 CDI (5980cc)",
          "S 350D CONNOISSEURS EDITION (5980cc)",
          "S 400 (5980cc)",
          "S 400 CONNOISSEURS EDITION (5980cc)",
          "S 500 COUPE (5980cc)",
          "S 63 AMG (5980cc)",
          "S 63 AMG COUPE (5980cc)",
        ],
      },
      {
        model: "GT S",
        variants: ["4.0 AMG COUPE (3982cc)", "4.0 AMG COUPE (3985cc)"],
      },
      {
        model: "EQC",
        variants: [
          "400 4MATIC (2359cc)",
          "400 4MATIC (300cc)",
          "400 4MATIC (300cc)",
          "400 4 MATIC (99000cc)",
          "400 4MATIC (1501cc)",
          "400 4MATIC (2996cc)",
        ],
      },
      {
        model: "SLK-CLASS",
        variants: [
          "SLK 200 K (1796cc)",
          "SLK 200K (1796cc)",
          "SLK 200 K (1796cc)",
          "SLK 200K (1796cc)",
          "SLK 55 AMG (5439cc)",
          "SLK 55 AMG (5439cc)",
          "SLK 55 AMG (5439cc)",
          "SLK 55 AMG (5439cc)",
          "SLK 350 ROADSTER (3498cc)",
          "SLK 350 ROADSTER (3498cc)",
          "SLK 230K (2295cc)",
          "SLK 230K (2295cc)",
          "SLK (5461cc)",
          "SLK 55 AMG (5461cc)",
          "SLK 55 AMG (5461cc)",
          "S 500 LONG 4 SEAT CC 5461 (5461cc)",
          "55 AMG (5461cc)",
          "SLK 200 K (1998cc)",
          "SLK 200 K (1998cc)",
          "SLK 230 K (1998cc)",
          "SLK 230 K (1998cc)",
          "SLK 320 (1998cc)",
          "SLK 320 (1998cc)",
          "SLK 230 K (1796cc)",
          "SLK 230 K (1796cc)",
          "SLK 320 (1796cc)",
          "SLK 350 BLUE EFFICIENCY (3498cc)",
          "SLK 350 BLUE EFFICIENCY (3498cc)",
          "SLK 350 BLUE EFFICIENCY (3498cc)",
          "SLK 350 (3498cc)",
          "SLK 350 (3498cc)",
          "350 (3498cc)",
          "350 (3498cc)",
          "350 PETROL (3498cc)",
          "SLK 280 (2996cc)",
          "SLK 230 (2295cc)",
          "230 K (2295cc)",
          "SLS AMG COUPE (6208cc)",
        ],
      },
      {
        model: "SLS-CLASS",
        variants: [
          "SLS AMG COUPE V8 PETROL (6208cc)",
          "AMG COUPE (6208cc)",
          "AMG COUPE (6208cc)",
          "AMG ROADSTER (6208cc)",
          "AMG ROADSTER (6208cc)",
          "SLS AMG (6208cc)",
          "AMG COUPE. (6500cc)",
          "AMG COUPE. (6500cc)",
        ],
      },
      {
        model: "CLS-CLASS",
        variants: [
          "250 CDI (2143cc)",
          "250 CDI (2143cc)",
          "CLS 350 CDI (2987cc)",
          "CLS 350 CDI (2987cc)",
          "CLS 350 CGI (3498cc)",
          "CLS 350 (3498cc)",
          "CLS 350 (3498cc)",
          "CLS 500 (4966cc)",
          "CLS 55 (5439cc)",
          "CLS 320 CDI ELEGANCE AT (2987cc)",
          "350 BLUEEFFICIENCY (3498cc)",
          "350 BLUEEFFICIENCY (3498cc)",
          "CLS 350 AT (3498cc)",
          "CLS 350 7 SPEED AT (3498cc)",
          "63 AMG (5461cc)",
          "63 AMG (5461cc)",
          "5.5 CLS 63 AMG 5 SEATER (5461cc)",
          "5.5 CLS 63 AMG 4 SEATER (5461cc)",
          "500 (5461cc)",
          "300D (1950cc)",
          "300 D (1950cc)",
          "CLS 320 CBU W 246 B 180SP 761 W 246 (1598cc)",
          "CLS 320 CDI (2987cc)",
          "CLS 250 CDI 4 SEATER (2143cc)",
          "CLS 250 CDI COUPE (2143cc)",
          "63 AMG (6208cc)",
        ],
      },
      {
        model: "GLE-CLASS",
        variants: [
          "350 D 4 MATIC (2987cc)",
          "350D 4MATIC (2987cc)",
          "250 D 4MATIC (2143cc)",
          "250D 4MATIC (2143cc)",
          "250 D (2143cc)",
          "250 D (2143cc)",
          "250D (2143cc)",
          "GLE 250 D 4MATIC (2143cc)",
          "250 D 4MATIC MY 16 (2143cc)",
          "250 D 4MATIC MY 17 (2143cc)",
          "200 4 MATIC (2143cc)",
          "350 D (2987cc)",
          "350 D (2987cc)",
          "350D (2987cc)",
          "GLE 350 D 4MATIC (2987cc)",
          "GLE 350 D (2987cc)",
          "350 D 4MATIC MY 16 (2987cc)",
          "350 D 4MATIC MY 17 (2987cc)",
          "400 4MATIC (2996cc)",
          "400 4MATIC (2996cc)",
          "400 4MATIC (2996cc)",
          "COUPE 450 AMG (2996cc)",
          "COUPE 450 AMG (2996cc)",
          "COUPE 43 4MATIC (2996cc)",
          "COUPE 43 4MATIC (2996cc)",
          "COUPE 43 AMG 4MATIC (2996cc)",
          "COUPE 43 AMG 4MATIC (2996cc)",
          "400 MATIC (2996cc)",
          "450 AMG (2996cc)",
          "GLE 43 AMG 4MATIC COUPE (CC 2996) (2996cc)",
          "GLE 400 PETROL 4 MATIC (2996cc)",
          "GLE 450 COUPE AMG (2996cc)",
          "43 AMG COUPE (2996cc)",
          "250D (2996cc)",
          "350D (2996cc)",
          "GLE 43 AMG COUPE (2996cc)",
          "450 LWB 4MATIC (2996cc)",
          "450 AMG 4MATIC COUPE (2996cc)",
          "450 AMG COUPE (2996cc)",
          "43 4MATIC AMG COUPE (2996cc)",
          "500 4MATIC GUARD (2996cc)",
          "43 4MATIC AMG COUPE PETROL (2996cc)",
          "300D 4MATIC LWB (1950cc)",
          "300D 4MATIC LWB (1950cc)",
          "300D 4MATIC LWB (1950cc)",
          "300D 4MATIC LWB BSVI (1950cc)",
          "GLE 300 D (1950cc)",
          "GLE 300 D 4MATIC LWB (1950cc)",
          "300 D 4MATIC (1950cc)",
          "400D 4MATIC HIP HOP EDITION (2999cc)",
          "400D 4MATIC HIP HOP EDITION (2999cc)",
          "450 4MATIC LWB (2999cc)",
          "450 4MATIC LWB (2999cc)",
          "450 4MATIC LWB (2999cc)",
          "450 4MATIC LWB FACELIFT (2999cc)",
          "450 4MATIC LWB FACELIFT (2999cc)",
          "COUPE 53 AMG 4MATIC PLUS (2999cc)",
          "COUPE 53 AMG 4MATIC PLUS (2999cc)",
          "GLE 450 4MATIC LWB (2999cc)",
          "GLE 53 AMG 4MATIC COUPE (CC 2999) (2999cc)",
          "GLE 53C AMG 4MATIC (2999cc)",
          "53 AMG COUPE 4 MATIC (2999cc)",
          "53 4MATIC PLUS AMG COUPE (P) (2999cc)",
          "53 4MATIC PLUS AMG COUPE (2999cc)",
          "400D 4MATIC LWB (2925cc)",
          "400D 4MATIC LWB (2925cc)",
          "400D 4MATIC LWB (2925cc)",
          "GLE 400 D 4MATIC LWB (2925cc)",
          "400 D LWB 4MATIC (2925cc)",
          "400D 4MATIC LWB HIP HOP EDITION (2925cc)",
          "COUPE 63 S AMG 4MATIC PLUS (3982cc)",
          "COUPE 63 S AMG 4MATIC PLUS (3982cc)",
          "AMG GLE 63 S (3982cc)",
          "GLE 63 S AMG CBU (3982cc)",
          "63 S AMG 4MATIC PLUS (3982cc)",
          "AMG 63 S 4MATIC PLUS COUPE (3982cc)",
          "GLE 500 (4663cc)",
          "GLE 43 AMG 4MATIC COUPE (2956cc)",
        ],
      },
      {
        model: "CLK-CLASS",
        variants: [
          "CLK 320 CABRIOLET (3199cc)",
          "CLK 350 CABRIOLET (3498cc)",
          "320 CABRIOLET (3199cc)",
          "320 COUPE (3199cc)",
          "CLK 320 CA (3199cc)",
          "350 CABRIOLET (3498cc)",
          "CLK 350 (3498cc)",
          "230 K (1796cc)",
          "500 CABRIOLET (4966cc)",
          "500 COUPE (5445cc)",
          "55 AMG CABRIOLET (5445cc)",
          "55 AMG COUPE (5445cc)",
        ],
      },
      {
        model: "A CLASS LIMOUSINE",
        variants: ["200 (1332cc)", "200D (1950cc)"],
      },
      {
        model: "CLA-CLASS",
        variants: [
          "45 AMG (1991cc)",
          "45 AMG (1991cc)",
          "200 PETROL SPORT (1991cc)",
          "200 PETROL SPORT (1991cc)",
          "45 AERO EDITION (1991cc)",
          "45 AERO EDITION (1991cc)",
          "200 URBAN SPORT (1991cc)",
          "200 URBAN SPORT (1991cc)",
          "200 UEFA SPORT EDITION (1991cc)",
          "200 CBU (1991cc)",
          "200 CDI (1991cc)",
          "CLA 45 AMG COUPE (1991cc)",
          "CLA 45 AMG COUPE 4 SEATER (1991cc)",
          "CLA 200 PETROL SPORTS (1991cc)",
          "CLA 200 PETROL (1991cc)",
          "200 CGI SPORT (1991cc)",
          "CLA 200 SP MY 808 (1991cc)",
          "CLA 200 SPORT FACELIFT (1991cc)",
          "CLA 200 URBAN SPORT (1991cc)",
          "200 SPORT (1991cc)",
          "200 CDI SPORT (1991cc)",
          "200 CDI STYLE (1991cc)",
          "200 CDI STYLE (2143cc)",
          "200 CDI STYLE (2143cc)",
          "200 CDI STYLE (2143cc)",
          "200 CDI SPORT (2143cc)",
          "200 CDI SPORT (2143cc)",
          "200 CDI SPORT (2143cc)",
          "200 D URBAN SPORT (2143cc)",
          "200 D URBAN SPORT (2143cc)",
          "200 CDI UEFA SPORT EDITION (2143cc)",
          "200 D (2143cc)",
          "CLA 200 D STYLE (2143cc)",
          "CLA 200 D SPORTS (2143cc)",
          "CLA 200 CDI SPORTS (2143cc)",
          "CLA 200 CDI STYLE (2143cc)",
          "CLA 200 CDI (2143cc)",
          "CLA 200 D SPORTS 808 (2143cc)",
          "CLA 200 D STYLE MY 808 (2143cc)",
          "CLA 200 D STYLE FACELIFT (2143cc)",
          "CLA 200 D SPORT FACELIFT (2143cc)",
          "CLA 200 D URBAN SPORT (2143cc)",
          "200 D SPORT (2143cc)",
          "200 (2143cc)",
          "45 AMG 4MATIC (5461cc)",
          "45 AMG 4MATIC (5461cc)",
        ],
      },
      {
        model: "GLA-CLASS",
        variants: [
          "200 CDI SPORT (2143cc)",
          "200 CDI SPORT (2143cc)",
          "200 CDI STYLE (2143cc)",
          "200 CDI STYLE (2143cc)",
          "220 D ACTIVITY EDITION (2143cc)",
          "220 D ACTIVITY EDITION (2143cc)",
          "200 D STYLE (2143cc)",
          "200 D STYLE (2143cc)",
          "200 D STYLE (2143cc)",
          "200 D SPORT (2143cc)",
          "200 D SPORT (2143cc)",
          "220 D 4MATIC (2143cc)",
          "220 D 4MATIC (2143cc)",
          "200 D URBAN EDITION (2143cc)",
          "200 D URBAN EDITION (2143cc)",
          "220 D URBAN EDITION (2143cc)",
          "220 D URBAN EDITION (2143cc)",
          "200 CDI UEFA SPORT EDITION (2143cc)",
          "200 D EDITION (2143cc)",
          "220 D 4 MATIC SPORTS (2143cc)",
          "GLA 220 D ACTIVITY EDITION (2143cc)",
          "GLA 220 D 4MATIC (2143cc)",
          "GLA 220 D 4MATIC SPORT (2143cc)",
          "GLA 200 D STYLE (2143cc)",
          "GLA 200 D SPORTS (2143cc)",
          "GLA 200 CDI SPORTS (2143cc)",
          "GLA 200 CDI (2143cc)",
          "GLA 200 CDI STYLE (2143cc)",
          "GLA 220 D ACTIVITY EDITION /FL (2143cc)",
          "GLA 200 D SPORTS/FL (2143cc)",
          "GLA 200 D STYLE/FL (2143cc)",
          "GLA 200 D URBAN EDITION (2143cc)",
          "GLA 200 D 4MATIC (2143cc)",
          "220 D 4MATIC ACTIVITY EDTN (2143cc)",
          "220 D 4MATIC ACTIVITY EDITION (2143cc)",
          "220 D 4MATIC SPORT (2143cc)",
          "200 SPORT (1991cc)",
          "200 SPORT (1991cc)",
          "45 AMG (1991cc)",
          "45 AMG (1991cc)",
          "45 AMG 4MATIC (1991cc)",
          "45 AMG 4MATIC (1991cc)",
          "45 AERO EDITION (1991cc)",
          "45 AERO EDITION (1991cc)",
          "URBAN EDITION (1991cc)",
          "URBAN EDITION (1991cc)",
          "35 AMG 4MATIC (1991cc)",
          "35 AMG 4MATIC (1991cc)",
          "35 AMG 4MATIC (1991cc)",
          "200 UEFA SPORT EDITION (1991cc)",
          "GLA 200 (1991cc)",
          "200 EDITION (1991cc)",
          "GLA 45 AMG (1991cc)",
          "GLA 35 AMG 4 MATIC 5 SEATER (CC 1991) (1991cc)",
          "GLA 200 SPORT CBU PETROL (1991cc)",
          "GLA 200 URBAN EDITION DCT (1991cc)",
          "GLA 200 2.0 (1991cc)",
          "GLA 200 SPORTS PETROL (1991cc)",
          "35 AMG (1991cc)",
          "4MATIC (1991cc)",
          "GLA 200 SPORTS /FL (1991cc)",
          "GLA 200 URBAN EDITION (1991cc)",
          "AMG GLA 35 4MATIC (1991cc)",
          "AMG 35 4MATIC (1991cc)",
          "220 D 4MATIC (1950cc)",
          "220 D 4MATIC (1950cc)",
          "220D (1950cc)",
          "220 D (1950cc)",
          "220D 4M (1950cc)",
          "GLA 220D (CC 1950) (1950cc)",
          "GLA 220D 4MATIC (CC 1950) (1950cc)",
          "GLA 220D 4MATIC (1950cc)",
          "GLA 220D (1950cc)",
          "200 (1332cc)",
          "200 (1332cc)",
          "200 BS6 (1332cc)",
          "1.3 TURBO GLA 200 (1332cc)",
          "GLA 200 DCT (1332cc)",
          "GLA 200 (1332cc)",
          "200 CDI 7 STR (2987cc)",
          "350 D (2987cc)",
          "A CLASS (2874cc)",
        ],
      },
      {
        model: "GLC-CLASS",
        variants: [
          "220 D (2143cc)",
          "220 D (2143cc)",
          "220 D STYLE (2143cc)",
          "220 D STYLE (2143cc)",
          "220 D SPORT (2143cc)",
          "220 D SPORT (2143cc)",
          "CELEBRATION EDITION DIESEL (2143cc)",
          "CELEBRATION EDITION DIESEL (2143cc)",
          "220 D PRIME (2143cc)",
          "220 D PRIME (2143cc)",
          "220 D PROGRESSIVE (2143cc)",
          "220 D PROGRESSIVE (2143cc)",
          "220 D PROGRESSIVE (2143cc)",
          "220 D 4MATIC (2143cc)",
          "220 D 4MATIC (2143cc)",
          "GLC 220 D 4MATIC (2143cc)",
          "GLC 220D PROGRESSIVE (2143cc)",
          "GLC 220 D 4MATIC SPORTS (2143cc)",
          "GLC 220 D 4MATIC STYLE 5 SEATER (2143cc)",
          "GLC 220 D 4MATIC STYLE 7 SEATER (2143cc)",
          "GLC 220 D 4MATIC STYLE (2143cc)",
          "GLC 220 D PRIME (2143cc)",
          "220D 4MATIC SPORTS (2143cc)",
          "220D 4MATIC SPORTS MY 18 (2143cc)",
          "220D 4MATIC STYLE MY 18 (2143cc)",
          "CKD X253 GLC200 MY21 (2143cc)",
          "220 D 4MATIC SPORT (2143cc)",
          "220 D 4MATIC STYLE (2143cc)",
          "220 D 4 MATIC PROGRESSIVE (2143cc)",
          "300 (1991cc)",
          "300 (1991cc)",
          "300 SPORT (1991cc)",
          "300 SPORT (1991cc)",
          "CELEBRATION EDITION PETROL (1991cc)",
          "CELEBRATION EDITION PETROL (1991cc)",
          "300 PROGRESSIVE (1991cc)",
          "300 PROGRESSIVE (1991cc)",
          "200 4MATIC (1991cc)",
          "200 4MATIC (1991cc)",
          "200 4MATIC (1991cc)",
          "COUPE 300 4MATIC (1991cc)",
          "COUPE 300 4MATIC (1991cc)",
          "300 4MATIC (1991cc)",
          "300 4MATIC (1991cc)",
          "200 PROGRESSIVE (1991cc)",
          "200 PROGRESSIVE (1991cc)",
          "GLC 200 CKD (1991cc)",
          "GLC 200 CBU (1991cc)",
          "GLC 300 4MATIC SPORT PETROL (1991cc)",
          "GLC 300 PETROL 4MATIC COUPE (1991cc)",
          "GLC 200 (1991cc)",
          "GLC 300D PROGRESSIVE (1991cc)",
          "GLC 200 PROGRESSIVE (1991cc)",
          "GLC 200 4MATIC (1991cc)",
          "GLC 300 4MATIC PETROL (1991cc)",
          "CKD X253 GLC 200 MY 22 (1991cc)",
          "220D 4MATIC SPORTS (1991cc)",
          "300 4MATIC SPORTS (1991cc)",
          "300 4MATIC SPORTS MY 18 (1991cc)",
          "X253 GLC 200 (1991cc)",
          "200 MY 2020 (1991cc)",
          "200 PROGRESSIVE MY22 (1991cc)",
          "220D 4MATIC STYLE (1991cc)",
          "300 4MATIC COUPE (1991cc)",
          "43 AMG (2996cc)",
          "43 AMG (2996cc)",
          "COUPE 43 AMG (2996cc)",
          "COUPE 43 AMG (2996cc)",
          "43 AMG COUPE (2996cc)",
          "43 AMG COUPE (2996cc)",
          "GLC 45 AMG 4 MATIC COUPE 5 SEATER (2996cc)",
          "GLC 43 AMG 4 MATIC COUPE 5 SEATER (2996cc)",
          "43 AMG COUPE 4MATIC (2996cc)",
          "220 D 4MATIC (1950cc)",
          "220 D 4MATIC (1950cc)",
          "220 D 4MATIC (1950cc)",
          "COUPE 300D 4MATIC (1950cc)",
          "COUPE 300D 4MATIC (1950cc)",
          "300D 4MATIC (1950cc)",
          "GLC 220 D 4MATIC (1950 CC) (1950cc)",
          "GLC 300 D 4MATIC COUPE (1950cc)",
          "GLC 220 D  PROGRESSIVE 4MATIC (1950 CC) (1950cc)",
          "300D 4M COUPE FL BSVI (1950cc)",
          "220D PROGRESSIVE MY22 (1950cc)",
          "300D 4MATIC COUPE (1950cc)",
          "220D 4MATIC (1950 CC) (1950cc)",
          "220D 4MATIC PROGRESSIVE (1950cc)",
          "300 4MATIC (1999cc)",
        ],
      },
      {
        model: "SLC-CLASS",
        variants: [
          "43 AMG (2996cc)",
          "43 AMG (2996cc)",
          "3.0 SLC 43 AMG PETROL (2996cc)",
        ],
      },
      {
        model: "GLS-CLASS",
        variants: [
          "350 D (2987cc)",
          "350 D (2987cc)",
          "350 D (2987cc)",
          "GRAND EDITION DIESEL (2987cc)",
          "GRAND EDITION DIESEL. (2987cc)",
          "GRAND EDITION DIESEL (2987cc)",
          "350 D 4MATIC (2987cc)",
          "GLS 350 D 4MATIC 7 SEATER (2987cc)",
          "GLS  350 D 4MATIC (2987cc)",
          "350 D 4MATIC MY 18 (2987cc)",
          "350 D 4MATIC GRAND EDTN (2987cc)",
          "400 D 4M BSVI (2987cc)",
          "350 (2987cc)",
          "400 4MATIC (2996cc)",
          "400 4MATIC (2996cc)",
          "400 4MATIC (2996cc)",
          "GRAND EDITION PETROL (2996cc)",
          "GRAND EDITION PETROL. (2996cc)",
          "GRAND EDITION PETROL (2996cc)",
          "GLS 400 4MATIC PETROL 7 SEATER (2996cc)",
          "400 4MATIC MY 18 (2996cc)",
          "400 4MATIC GRAND EDTN (2996cc)",
          "400 4MATIC GRAND EDITION (2996cc)",
          "63 AMG (5461cc)",
          "63 AMG (5461cc)",
          "63 AMG (5461cc)",
          "GLS 63 AMG 7 SEATER CC 5461 (5461cc)",
          "AMG 63 (5461cc)",
          "400 4MATIC (5461cc)",
          "350D 4MATIC (5461cc)",
          "450 4MATIC (2999cc)",
          "450 4MATIC (2999cc)",
          "450 4MATIC (2999cc)",
          "450 4MATIC FACELIFT (2999cc)",
          "450 4MATIC FACELIFT (2999cc)",
          "GLS 450 4MATIC 7 SEATER (2999cc)",
          "400D 4MATIC (2925cc)",
          "400D 4MATIC (2925cc)",
          "400 D 4MATIC (2925cc)",
          "GLS 400 D 4MATIC 7 SEATER (2925cc)",
          "MAYBACH 600 4MATIC (3982cc)",
          "MAYBACH 600 4MATIC (3982cc)",
          "MAYBACH 600 4MATIC (3982cc)",
          "GLS 600 MAYBACH 4MATIC PETROL 4 SEATER (3982cc)",
          "600 MAYBACH (3982cc)",
        ],
      },
      {
        model: "AMG GT",
        variants: [
          "S (3982cc)",
          "S (3982cc)",
          "ROADSTER (3982cc)",
          "ROADSTER (3982cc)",
          "R (3982cc)",
          "R (3982cc)",
          "COUPE 63 S (3982cc)",
          "COUPE 63 S (3982cc)",
          "R 4.0 COUPE (3982cc)",
          "4.0 ROADSTAR (3982cc)",
          "63 S 4MATIC PLUS (3982cc)",
          "63 S E PERFORMANCE LIMOUSINE (3982cc)",
          "4 DOOR 63S COUPE (3982cc)",
        ],
      },
      {
        model: "M-CLASS",
        variants: [
          "350 CDI (2987cc)",
          "320 CDI (2987cc)",
          "320 CDI (2987cc)",
          "350 CDI BLUE EFFICIENCY (2987cc)",
          "350 CDI BLUE EFFICIENCY (2987cc)",
          "W164 (2987cc)",
          "M GUARD (4663cc)",
          "M GUARD (4663cc)",
          "350 (2143cc)",
          "350 (2143cc)",
          "ML 250 CDI (2143cc)",
          "ML 250 CDI (2143cc)",
          "ML 270 (2685cc)",
          "ML 270 (2685cc)",
          "ML 270 CDI (2685cc)",
          "ML 500 (5461cc)",
          "ML 500 (5461cc)",
          "ML 63 AMG (5461cc)",
          "ML 63 AMG (5461cc)",
          "ML 350 CDI (2997cc)",
          "ML 350 CDI (2997cc)",
          "ML 350 4MATIC BLUEEFFICIENCY (2997cc)",
          "ML 420 CDI (3986cc)",
          "ML 350 AT (3498cc)",
          "ML 350 (3498cc)",
          "ML 430 (4299cc)",
        ],
      },
      {
        model: "M CLASS",
        variants: ["350 CDI (2987cc)", "ML 320 CDI (2987cc)"],
      },
      {
        model: "AMG",
        variants: [
          "A45 S 4MATIC PLUS (1991cc)",
          "A45 S 4MATIC PLUS (1991cc)",
          "GLE 43C (2996cc)",
          "EQS 53 4MATIC PLUS (2996cc)",
          "E53 CABRIOLET 4MATIC (2999cc)",
          "SL 55 ROADSTER 4MATIC+ (3982cc)",
          "G63 (3982cc)",
        ],
      },
      {
        model: "MAYBACH",
        variants: [
          "S 500 (4663cc)",
          "S 500 EURO IV (4663cc)",
          "S 500 V8 (4663cc)",
          "S580 5 STR (3982cc)",
          "0 (2000cc)",
          "S 600 V12 (5980cc)",
        ],
      },
      {
        model: "V-CLASS",
        variants: [
          "EXPRESSION ELWB (2143cc)",
          "EXPRESSION ELWB (2143cc)",
          "EXCLUSIVE LWB (2143cc)",
          "EXCLUSIVE LWB (2143cc)",
          "V-220 D (2143cc)",
          "220 D (2143cc)",
          "ELITE (1950cc)",
          "MARCO POLO (1950cc)",
          "MARCO POLO (1950cc)",
          "MARCO POLO HORIZON (1950cc)",
          "MARCO POLO HORIZON (1950cc)",
          "220 D ELITE (1950cc)",
          "220 D EXCLUSIVE (1950cc)",
          "220 D EXPRESSION (1950cc)",
        ],
      },
      {
        model: "GL",
        variants: [
          "350 CDI LX (2987cc)",
          "GL 350 GRAND LUXURY (2987cc)",
          "350 CDI 7STR (2987cc)",
          "3.0 GRAND EDITION EXECUTIVE (2987cc)",
          "350 CDI EXECUTIVE (2987cc)",
          "350 CDI GRAND LUXURY (2987cc)",
          "350 CDI EXECUTIVE (M) (2987cc)",
          "350 CDI BLUEEFFICIENCY LUXURY (2987cc)",
          "GL 350 CDI LUXURY (M) (2987cc)",
          "350 CDI LAUNCH EDITION (2987cc)",
          "320 CDI (2987cc)",
          "350 CDI BLUEEFFICIENCY. (2987cc)",
          "350 CDI. (2987cc)",
          "3.0 GRAND EDITION LUXURY (2143cc)",
          "350 CDI BLUE EFFICIENCY (2143cc)",
          "GL 500 4MATIC (5461cc)",
        ],
      },
      {
        model: "CL",
        variants: [
          "500 COUPE 4 STR (5461cc)",
          "55 AMG COUPE (5461cc)",
          "CL 500 (5461cc)",
          "500 COUPE (5461cc)",
          "600 COUPE (5513cc)",
          "CL 600 (5513cc)",
          "65 AMG (5980cc)",
          "CL 63 AMG (6208cc)",
        ],
      },
      {
        model: "SL",
        variants: [
          "500 (5461cc)",
          "600 ROADSTER (5461cc)",
          "SL 63 AMG. (6208cc)",
          "63 AMG (6208cc)",
          "65 AMG ROADSTER (5596cc)",
          "63 AMG (5596cc)",
          "55 AMG ROADSTER (3982cc)",
          "55 AMG ROADSTER. (3498cc)",
          "560 (5549cc)",
          "600 ROADSTER (5513cc)",
        ],
      },
      {
        model: "SPRINTER",
        variants: ["313 CDI CAMPER VAN (2150cc)", "316 CDI (2685cc)"],
      },
      {
        model: "MAYBACH 62",
        variants: ["62 (5980cc)", "62 (5980cc)"],
      },
      {
        model: "MAYBACH 57 S",
        variants: ["57 S (5980cc)", "57 S (5980cc)"],
      },
      {
        model: "S-COUPE",
        variants: [
          "S 500 (4663cc)",
          "S 500 (4663cc)",
          "S 63 AMG (5461cc)",
          "S 63 AMG 4MATIC + (3982cc)",
          "S 63 AMG 4MATIC + (3982cc)",
        ],
      },
      {
        model: "MB-CLASS",
        variants: [
          "MB 100 VAN (2874cc)",
          "MB 140 VAN (2874cc)",
          "2.9 MB 100 12 STR (2874cc)",
          "2.9 MB 100 (2874cc)",
          "MB 100 15 SEATER VAN (2874cc)",
          "MB 100 10 SEATER VAN (2874cc)",
          "MB 100 11 SEATER VAN (2874cc)",
          "MB 100 12 SEATER VAN (2874cc)",
          "MB 140D 15 SEATER VAN (2874cc)",
          "MB 140D VAN (2874cc)",
          "MB140D VAN (2874cc)",
          "MB140D VAN STR 15 (2874cc)",
          "MB 100 D 2.5 (1796cc)",
        ],
      },
      {
        model: "AMG GLC",
        variants: ["43 COUPE 4MATIC (2996cc)", "43 COUPE 4MATIC (2996cc)"],
      },
      {
        model: "GLB-CLASS",
        variants: [
          "200 PROGRESSIVE LINE (1332cc)",
          "GLB 220 (CC 1332) (1332cc)",
          "220D PROGRESSIVE LINE (1950cc)",
          "220D 4MATIC AMG LINE (1950cc)",
          "GLB 220 D (CC 1950) (1950cc)",
          "GLB 220 D 4 MATIC (CC 1950) (1950cc)",
        ],
      },
      {
        model: "CL 600",
        variants: ["COUPE (5513cc)"],
      },
      {
        model: "240D",
        variants: ["SALOON (2399cc)"],
      },
      {
        model: "200D",
        variants: ["SALOON (1988cc)"],
      },
      {
        model: "VITO",
        variants: ["115 CDI (2148cc)"],
      },
      {
        model: "350D",
        variants: ["4MATIC 5 STR (2987cc)"],
      },
      {
        model: "M4",
        variants: ["COMETITION X DRIVE (2993cc)"],
      },
      {
        model: "GWAGON",
        variants: ["G-WAGON (2090cc)"],
      },
      {
        model: "DAIMLER",
        variants: ["BENZ (4966cc)", "BENZ (1796cc)"],
      },
      {
        model: "MERCEDES CITY",
        variants: ["PASSION SMART CAR (698cc)"],
      },
      {
        model: "V-220",
        variants: ["D ELITE (2143cc)", "D L AT (1950cc)"],
      },
      {
        model: "W204",
        variants: [
          "C220 CDI BLUE EFFIC AT (2143cc)",
          "C250 CDI BE BSIV (2143cc)",
          "C200 CGI AT BS IV (1796cc)",
          "C200 CGI AT (1796cc)",
        ],
      },
      {
        model: "W463",
        variants: ["AMG G63 (2143cc)"],
      },
      {
        model: "BENZ 140-D",
        variants: ["0 (2874cc)"],
      },
      {
        model: "2000 D",
        variants: ["0 (2000cc)"],
      },
      {
        model: "OMNI BUS",
        variants: ["0 (2874cc)"],
      },
      {
        model: "D C 200 CLASSIC",
        variants: ["0 (2148cc)"],
      },
      {
        model: "B 180 SPORT",
        variants: ["0 (1595cc)"],
      },
      {
        model: "JEEP CHEROKEE",
        variants: ["0 (2000cc)"],
      },
      {
        model: "B 180",
        variants: ["0 (1595cc)"],
      },
      {
        model: "E 270 CDI",
        variants: ["0 (2685cc)"],
      },
      {
        model: "E 230",
        variants: ["0 (2497cc)"],
      },
      {
        model: "S-280",
        variants: ["0 (2000cc)"],
      },
      {
        model: "ML 320 CDI",
        variants: ["0 (2987cc)"],
      },
      {
        model: "S 350 L",
        variants: ["0 (3498cc)"],
      },
      {
        model: "MB 100 2.9 D",
        variants: ["0 (2874cc)"],
      },
      {
        model: "E 220 CDI AVANTGARDE",
        variants: ["0 (2143cc)"],
      },
      {
        model: "C200 CGI ADVANTGARDE",
        variants: ["0 (1796cc)"],
      },
      {
        model: "C 180 ELEGANCE AT",
        variants: ["0 (1998cc)"],
      },
      {
        model: "SMART",
        variants: ["0 (1000cc)"],
      },
      {
        model: "GLC CLASS 200",
        variants: ["0 (1991cc)"],
      },
      {
        model: "CLS 500",
        variants: ["0 (5461cc)"],
      },
      {
        model: "CLS 350",
        variants: ["0 (3498cc)"],
      },
      {
        model: "140D",
        variants: ["STD (4172cc)", "STD (2874cc)"],
      },
      {
        model: "CL 65",
        variants: ["AMG (5980cc)"],
      },
      {
        model: "CL CLASS",
        variants: ["CL 500 (5461cc)", "CL 600 (5513cc)"],
      },
      {
        model: "MB",
        variants: ["MB140--VAN (2874cc)", "100 D (2874cc)", "140 D (2874cc)"],
      },
      {
        model: "SMART FOR TWO",
        variants: ["0 (600cc)"],
      },
      {
        model: "220 S",
        variants: ["0 (2195cc)"],
      },
      {
        model: "170 V",
        variants: ["0 (1697cc)"],
      },
      {
        model: "SUPER EIGHT",
        variants: ["0 (2522cc)"],
      },
    ],
  },
  {
    make: "MITSUBISHI",
    models: [
      {
        model: "PAJERO",
        variants: [
          "2.5 CRDI SPORTS 7STR (2477cc)",
          "2.8 GL 6STR (2835cc)",
          "2.8 GL 7STR (2835cc)",
          "2.8 SFX 6STR (2835cc)",
          "3.2 DI 7STR (3200cc)",
          "GLX CRZ 5STR (2835cc)",
          "GLX CRZ 6STR (2835cc)",
          "GLX CRZ 7STR (2835cc)",
          "2.5 TD (2497cc)",
          "SPORT LIMITED EDITION (2477cc)",
          "SPORT 4X4 MT (2477cc)",
          "SPORT 4X2 AT (2477cc)",
          "2.8 GL 6 SEATER (2835cc)",
          "2.8 GL 7 SEATER (2835cc)",
          "GLX CRZ 6 SEATER (2835cc)",
          "GLX CRZ 7 SEATER (2835cc)",
          "2.8 SFX 6 SEATER (2835cc)",
          "2.5 CRDI SPORTS 7 SEATER (2477cc)",
          "3.2 DI  7 SEATER (3200cc)",
          "GLX CRZ 5 SEATER (2835cc)",
          "SPORT (2477cc)",
          "SPORT (2477cc)",
          "SPORT (2477cc)",
          "SPORT (2477cc)",
          "SPORT (2477cc)",
          "SPORT (2477cc)",
          "SPORT (2477cc)",
          "SPORT (2477cc)",
          "SPORT (2477cc)",
          "SPORT (2477cc)",
          "SPORT (2477cc)",
          "SPORT 2.5 (2477cc)",
          "SPORT 2.5 7STR (2477cc)",
          "2.5 CRDI SPORTS (2477cc)",
          "SPORT 5 STR (2477cc)",
          "SELECT PLUS MT (2477cc)",
          "2.5 CRDI 4X2 SPORTS SELECT PLUS AT 7 SEATER (2477cc)",
          "2.5 CRDI 4X4 SPORTS MT 7 SEATER (2477cc)",
          "2.5 CRDI 4X4 SPORTS MT 5 SEATER (2477cc)",
          "2.5 CRDI 4X2 SPORTS AT 7 SEATER (2477cc)",
          "2.5 CRDI 4X2 SPORTS AT 6 SEATER (2477cc)",
          "SPORT (7 SEATER) (2477cc)",
          "2.5 CRDI SPORTS 5 SEATER (2477cc)",
          "Q (2835cc)",
          "2.8 DSL (2835cc)",
          "2.8 GLX E-3 (2835cc)",
          "2.8 GLX E-2 (2835cc)",
          "2.8 GXI (2835cc)",
          "2.8 CRZ (2835cc)",
          "2.8 GLX (2835cc)",
          "2.8 CRZ BS 3 (2835cc)",
          "GLX (2835cc)",
          "GLX CRZ (2835cc)",
          "2.8 L (2835cc)",
          "2.8 GLX 6 STR (2835cc)",
          "2.8 T (2835cc)",
          "2.8 SFX 5 SEATER (2835cc)",
          "2.8 SFX SPORT 5 SEATER (2835cc)",
          "2.8 5 SEATER (2835cc)",
          "2.8 GLX 8 SEATER (2835cc)",
          "GLX 2.8 (2835cc)",
          "SFX 2.8 DUAL TONE BS IV (2835cc)",
          "SFX 2.8 SINGLE TONE BS IV (2835cc)",
          "GLX 2.8 CRZ (2835cc)",
          "GL 2.8 (2835cc)",
          "DI D 3.2 (2835cc)",
          "SFX 2.8 (2835cc)",
          "SFX 2.8. (2835cc)",
          "MINI (2835cc)",
          "2.8 GL (2835cc)",
          "2.8 GL (2835cc)",
          "2.8 SFX (2835cc)",
          "2.8 SFX (2835cc)",
          "GLS (2835cc)",
          "GLS (2835cc)",
          "2.8 (2835cc)",
          "2.8 SFX 7 STR (2835cc)",
          "GLX 1.8 (1834cc)",
          "2.8 SPORT SEATER (2800cc)",
          "SPORT (2800cc)",
          "2.8 SFX 7 SEATER (2535cc)",
          "3.0 GL 5 SEATER (2972cc)",
          "DI-D 3.2 (3198cc)",
          "SPORT (1499cc)",
          "3.2 DI (3200cc)",
          "3.2 DI (3200cc)",
        ],
      },
      {
        model: "OUTLANDER",
        variants: [
          "2.4 (AT) 7STR (2360cc)",
          "2.4 7STR (MT) (2360cc)",
          "2.4 MT (2360cc)",
          "2.4 AT (2360cc)",
          "2.4 4X4 (2360cc)",
          "2.4 MT 7 SEAT (2360cc)",
          "2.4 AT 7 SEAT (2360cc)",
          "7STR (2360cc)",
          "NEW (2360cc)",
          "2.4 PETROL (2360cc)",
          "4X4 (2360cc)",
          "CHROME (2360cc)",
          "2.4 MIVEC 7 SEATER (2360cc)",
          "2.4 L (2360cc)",
          "2.4 MIVEC (2360cc)",
        ],
      },
      {
        model: "LANCER",
        variants: [
          "CEDIA SELECT (1999cc)",
          "CEDIA SELECT (1999cc)",
          "CEDIA EXI (1999cc)",
          "CEDIA SELECT A (1999cc)",
          "CEDIA SPIRIT (1999cc)",
          "CEDIA SPIRIT A (1999cc)",
          "STD (2800cc)",
          "CEDIA SPORTS (1999cc)",
          "CEDIA SPORTS (1999cc)",
          "CEDIA ELEGANCE (1999cc)",
          "CEDIA ELEGANCE (1999cc)",
          "1.5 GLI (1468cc)",
          "1.8 LX (1834cc)",
          "1.8 SFXI (1834cc)",
          "1.5 SLXI LE (1468cc)",
          "CEDIA 2.0 LXI (1999cc)",
          "2.0 GLD (1998cc)",
          "2.0 GLXD (1998cc)",
          "2.0 LXD (1998cc)",
          "2.0 XLD (1998cc)",
          "2.0 LED (1998cc)",
          "2.0 SLXD (1998cc)",
          "2.0 SFXD (1998cc)",
          "2.0 LXDV (1998cc)",
          "2.0 GLXD  (BLACK) (1998cc)",
          "2.0 SLXD LE (1998cc)",
          "1.5 LX (1468cc)",
          "1.5 LX (1468cc)",
          "1.8 INVEX LE (1834cc)",
          "EVOLUTION (2000cc)",
          "1.5 SFXI (1468cc)",
          "1.5 SFXI (1468cc)",
          "1.8 XLI (1834cc)",
          "1.5 GLXI (1468cc)",
          "1.8 LEI (1834cc)",
          "1.5 LXI (1468cc)",
          "1.5 SLXI (1468cc)",
          "1.5 LEI (1468cc)",
          "EVOLUTION X (1998cc)",
          "EVOLUTION X (1998cc)",
          "CEDIA SELECT ALPG (1999cc)",
          "CEDIA SPIRIT ALPG (1999cc)",
          "GLXD (DIESEL) (1998cc)",
          "SFXD (DIESEL) (1998cc)",
          "SLXD  LE (1998cc)",
          "EVOLUTION 7 (1998cc)",
          "SLXD (DIESEL ) (1998cc)",
          "LED 2.0 (1998cc)",
          "LED 2.0 (1998cc)",
          "DIESEL LXD 2.0 (1998cc)",
          "2.0 EVOLUTION X (1998cc)",
          "SFXD 2.0 (1998cc)",
          "XLI 1.5 (1998cc)",
          "XLD 2.0 (1998cc)",
          "GLD 2.0 (1998cc)",
          "GLXD 2.0 (1998cc)",
          "SLXD 2.0 (1998cc)",
          "XLI 1.8 (1998cc)",
          "EXEL 2.0 (1998cc)",
          "EVO IX 2.0 (1998cc)",
          "LXD 2.0 (1998cc)",
          "LXD 2.0 (1998cc)",
          "GLX (1998cc)",
          "2.0 LX (1998cc)",
          "SFXI (PETROL) (1468cc)",
          "SLXI (PETROL) (1468cc)",
          "LEI (PETROL) LUXURY ED (1468cc)",
          "GLI  LX (1468cc)",
          "GLXI (PETROL) (1468cc)",
          "LXI 1.5 (1468cc)",
          "LXI 1.5 (1468cc)",
          "EXEL 1.5 (1468cc)",
          "GLI 1.5 (1468cc)",
          "GLXI 1.5 (1468cc)",
          "SLXI 1.5 (1468cc)",
          "SFXI 1.5 (1468cc)",
          "LEI 1.5 (1468cc)",
          "LEI 1.8 (1468cc)",
          "LXI 1.8 (1468cc)",
          "SFXI 1.8. (1468cc)",
          "SFXI 1.8 (1468cc)",
          "GLX (1468cc)",
          "GLI (1468cc)",
          "1.5 GLX (1468cc)",
          "GLXD (1468cc)",
          "1.8 INVEX AT (1468cc)",
          "LXI 1.8 E-3 (1834cc)",
          "1.8 SFX INVEX (1834cc)",
          "1.8 LE  INVEX (1834cc)",
          "1.8 LX AT (1834cc)",
          "1.8  XL  INVEX (1834cc)",
          "GL 1.8 INVEX (1834cc)",
          "SFXI (1834cc)",
          "1.8 INVEX AT (1834cc)",
          "2.0 EVOLUTION (2000cc)",
          "CNX 1.5 (1997cc)",
          "CNE 1.5 (1997cc)",
        ],
      },
      {
        model: "MONTERO",
        variants: [
          "3.2 DI-D (3200cc)",
          "DI D (3200cc)",
          "DIESEL  BLACK MICA M (3200cc)",
          "3.2 DI (3200cc)",
          "3.2 L (3200cc)",
          "3.2 GLX (3200cc)",
          "3.2 DSL TURBO (3200cc)",
          "3.2 DI-D MT (3200cc)",
          "3.2 DI-D AT (3200cc)",
          "3.2 GLS (3200cc)",
          "3.2 GLS (3200cc)",
        ],
      },
      {
        model: "VINTAGE",
        variants: ["LANCER (1360cc)"],
      },
      {
        model: "GTO",
        variants: ["TWIN TURBO (2972cc)", "TWIN TURBO (3000cc)"],
      },
      {
        model: "FTO",
        variants: ["STD (1799cc)", "STANDARD (1799cc)", "2000 (1998cc)"],
      },
      {
        model: "SHOGUN",
        variants: [
          "3.2 (3200cc)",
          "LWB (3200cc)",
          "3.2 DIESEL 7 SEATER (3200cc)",
          "SALOON (3200cc)",
          "3.2 DI DC SWB SG2  AUTOMATIC DIESEL (3200cc)",
          "SALOON (2477cc)",
          "SALOON (2500cc)",
        ],
      },
      {
        model: "CEDIA",
        variants: [
          "2.8 (2800cc)",
          "EXI (1999cc)",
          "SPORTS LPG (1999cc)",
          "SPORTS (1999cc)",
          "SPORTS (1999cc)",
          "SPIRIT (1999cc)",
          "SPIRIT (1999cc)",
          "NEW SPIRIT (1999cc)",
          "NEW SPORTS (1999cc)",
          "SELECT ALPG (1999cc)",
          "SPIRIT ALPG (1999cc)",
          "ELEGANCE (1999cc)",
          "SELECT (1999cc)",
          "SELECT (1986cc)",
        ],
      },
      {
        model: "DELICA",
        variants: ["D5 (2800cc)", "D5 (2500cc)", "D5 (2359cc)"],
      },
      {
        model: "CHALLENGER",
        variants: [
          "2.8 GLX (2800cc)",
          "2.8 L GLX (2800cc)",
          "2.8 DIESEL (2796cc)",
          "2.8 (2835cc)",
        ],
      },
      {
        model: "SPORTS",
        variants: ["RVR (1998cc)"],
      },
      {
        model: "CRZ",
        variants: ["0 (2835cc)"],
      },
      {
        model: "MITSUBISHI CHALLENGER",
        variants: ["0 (6286cc)"],
      },
      {
        model: "MITSUBISHI RVR",
        variants: ["0 (1834cc)"],
      },
      {
        model: "MITSUBISHI KUDA",
        variants: ["0 (3000cc)"],
      },
      {
        model: "MITSUBISHI  F10",
        variants: ["0 (1998cc)"],
      },
      {
        model: "SIGME",
        variants: ["STANDARD (1400cc)"],
      },
    ],
  },
  {
    make: "PORSCHE",
    models: [
      {
        model: "718",
        variants: [
          "CAYMAN PLATINUM EDITION (2967cc)",
          "2.0 CAYMAN PETROL (1998cc)",
          "2.0 BOXSTER PETROL (1998cc)",
          "4.0 SPYDER (3995cc)",
          "BOXSTER GTS 4.0 (3995cc)",
          "CAYMAN GT4 AT (3995cc)",
          "CAYMAN GTS 4.0 (3995cc)",
          "SPYDER AT (3995cc)",
          "CAYMAN GT4 PDK (3995cc)",
          "SPYDER PDK (3995cc)",
          "SPYDER (3995cc)",
          "CAYMAN GT4 (3995cc)",
          "CAYMAN GT4 RS (3996cc)",
          "CAYMAN STYLE EDITION (1988cc)",
          "BOXSTER STYLE EDITION (1988cc)",
          "BOXSTER (1988cc)",
          "CAYMAN (1988cc)",
          "CAYMAN GT4 (3800cc)",
        ],
      },
      {
        model: "911",
        variants: [
          "CARRERA 3.6 (3596cc)",
          "CARRERA 3.6 COUPE 4STR (3596cc)",
          "3.6 CARRERA 4 (3596cc)",
          "CARRERA CABRIOLET 3.6 4STR (3596cc)",
          "3.8 CARRERA 4S (3800cc)",
          "CARRERA CABRIOLET 3.8 4STR (3800cc)",
          "CARRERA CABRIOLET 3.6 (3596cc)",
          "3.8 CARRERA S (3800cc)",
          "3.8 CABRIOLET CARRERA S (3824cc)",
          "3.6 GT2 (3596cc)",
          "3.6 TARGA 4 (3596cc)",
          "3.6 TARGA 4STR (3596cc)",
          "3.8 TARGA 4S (3800cc)",
          "TURBO 3.6 (3600cc)",
          "3.6 TURBO CABRIOLET (3596cc)",
          "TURBO S CABRIOLET (3800cc)",
          "TURBO 3.6 COUPE (3600cc)",
          "CARRERA S CABRIOLET (2981cc)",
          "GT3 (3996cc)",
          "TURBO S (3800cc)",
          "CARRERA 3.0 PDK (2981cc)",
          "CARRERA CABRIOLET 3.0 PDK (2981cc)",
          "CARRERA S 3.0 PDK (2981cc)",
          "CARRERA S CABRIOLET 3.0 PDK (2981cc)",
          "GT3 PDK (3996cc)",
          "CARRERA 3.6 COUPE M TRANS 4 (3600cc)",
          "CARRERA 4 CABRIOLET 3.6 M T (3614cc)",
          "CARRERA 4S CABRIOLET 3.8 M T (3800cc)",
          "CARRERA CABRIOLET 3.6 M TRANS (3614cc)",
          "CARRERA S CABRIOLET 3.8 M T (3800cc)",
          "TARGA 4 - 3.6 M TRANS (4 ) (3614cc)",
          "TURBO CABRIOLET 3.6 M TRANS (3600cc)",
          "TURBO 3.6 MANUAL TRANS (3600cc)",
          "CARRERA S 3.8 MANUAL TRANS (3800cc)",
          "CARRERA 4 - 3.6 MANUAL TRANS (3614cc)",
          "CARRERA 4S - 3.8 MANUAL TRANS (3800cc)",
          "TARGA 4 - 3.6 MANUAL TRANS (3614cc)",
          "GT2 3.6 MANUAL TRANS (3600cc)",
          "GT3 MANUAL (3996cc)",
          "TURBO CABRIOLET 3.6 TIPTRONIC S (3600cc)",
          "CARRERA 3.6  MANUAL TRANS (3614cc)",
          "TARGA 4S - 3.8 MANUAL TRANS (3800cc)",
          "CARRERA S 2STR (3824cc)",
          "CARRERA 4 S CA (3824cc)",
          "GT2 (3824cc)",
          "TARGA 4S (3824cc)",
          "TARGA 4S (3824cc)",
          "CARRERA 4S (3824cc)",
          "CARRERA 4S CABRIOLET (3824cc)",
          "CARRERA S (3824cc)",
          "4.0 GT3 (3996cc)",
          "4.0 GT3 2 STR (3996cc)",
          "GT3 WITH TOURING PACKAGE (3996cc)",
          "GT3 WITH TOURING PACKAGE MANUAL (3996cc)",
          "GT3 RS 2 STR (3996cc)",
          "GT3 4STR (3996cc)",
          "GT2 RS (3996cc)",
          "GT3 RS (3996cc)",
          "CARRERA CABRIOLET 3.6 MANUAL TRANS (3614cc)",
          "CARRERA 4 CABRIOLET 3.6 MANUAL TRANS (3614cc)",
          "TARGA 4 - 3.6 MANUAL TRANS (4 SEATER) (3614cc)",
          "CARRERA 4 3.6 (3614cc)",
          "TARGA 4 (3614cc)",
          "CARRERA 4 CABRIOLET (3614cc)",
          "CARRERA 4 (3614cc)",
          "CARRERA CABRIOLET (3614cc)",
          "CARRERA S CABRIOLET 3.8 MANUAL TRANS (3800cc)",
          "CARRERA 4S CABRIOLET 3.8 MANUAL TRANS (3800cc)",
          "TARGA 4S 3.8  COUPE 4 SEATER (3800cc)",
          "CARRERA S COUPE 3.8 (3800cc)",
          "3.8 TURBO (3800cc)",
          "3.8 TURBO S (3800cc)",
          "CARRERA S COUPE (3800cc)",
          "TURBO S 3.8 (3800cc)",
          "CARRERA S 3.8 4 SEAT (3800cc)",
          "3.8 GTS COUPE 4 SEATER (3800cc)",
          "CARRERA AT (3800cc)",
          "CARRERA S CABRIOLET AT (3800cc)",
          "TARGA 4 AT (3800cc)",
          "CARRERA S AT (3800cc)",
          "CARRERA S TURBO (3800cc)",
          "CARRERA 4S CABRIOLET MT (3800cc)",
          "CARRERA 4S CABRIOLET AT (3800cc)",
          "TARGA 4S AT (3800cc)",
          "CARRERA 4S MT (3800cc)",
          "CARRERA 4S AT (3800cc)",
          "CARRERA S CABRIOLET MT (3800cc)",
          "CARRERA 4 GTS (3800cc)",
          "3.8 CARRERA S PDK (991) (3800cc)",
          "TARGA 4S (3800cc)",
          "TURBO (3800cc)",
          "CARRERA S (3800cc)",
          "CARRERA 4S (3800cc)",
          "TURBO CABRIOLET (3800cc)",
          "CARRERA 4S CABRIOLET (3800cc)",
          "CARRERA S CABRIOLET (3800cc)",
          "TURBO CABRIOLET 3.6 MANUAL TRANS (3600cc)",
          "CARRERA 3.6 COUPE MANUAL TRANS 4 SEATER (3600cc)",
          "GT2 (3600cc)",
          "TURBO (3600cc)",
          "TURBO (3600cc)",
          "TURBO CABRIOLET (3600cc)",
          "GT3 RS (3600cc)",
          "3.0 CARRERA 4 CABRIOLET (2981cc)",
          "3.0 CARRERA 4S (2981cc)",
          "3.0 CARRERA 4S CABRIOLET (2981cc)",
          "3.0 CARRERA CABRIOLET (2981cc)",
          "3.0 CARRERA S (2981cc)",
          "3.0 CARRERA S CABRIOLET (2981cc)",
          "3.0 TARGA 4S (2981cc)",
          "3.0 CARRERA AUTOMATIC 4 SEAT (2981cc)",
          "3.0 CARRERA 991 (2981cc)",
          "CARRERA T (2981cc)",
          "CARRERA 3.0 (2981cc)",
          "CARRERA S CABRIOLET 4 STR (2981cc)",
          "CARRERA 2S CABRIOLET (2981cc)",
          "CARRERA S 3.0 (2981cc)",
          "CARRERA S (2981cc)",
          "CARRERA (2981cc)",
          "CARRERA CABRIOLET (2981cc)",
          "3.4 CARRERA CABRIOLET (3436cc)",
          "CARRERA 3.4 (3436cc)",
          "TARGA 4 (3436cc)",
          "CARRERA 4 CABRIOLET (3436cc)",
          "CARRERA 4 (3436cc)",
          "CARRERA (3436cc)",
          "CARRERA CABRIOLET (3436cc)",
          "2.0 STD (1991cc)",
          "2.4 COUPE (2400cc)",
          "CARRERA 4 CABRIOLET AT (3414cc)",
          "CARRERA S MT (3414cc)",
          "TURBO CABRIOLET MT (3414cc)",
          "CARRERA CABRIOLET MT (3414cc)",
          "TURBO MT (3414cc)",
          "TURBO AT (3414cc)",
          "CARRERA MT (3414cc)",
          "TURBO CABRIOLET AT (3414cc)",
          "CARRERA CABRIOLET AT (3414cc)",
          "CARRERA 4 MT (3414cc)",
          "TARGA 4S MT (3414cc)",
          "TARGA 4 MT (3414cc)",
          "CARRERA 4 AT (3414cc)",
          "CARRERA 4 CABRIOLET MT (3414cc)",
          "TARGA 4 (3596cc)",
          "CARRERA 4 CABRIOLET (3596cc)",
          "CARRERA 4 (3596cc)",
          "CARRERA (3596cc)",
          "GT3 (3799cc)",
          "GT3 (3797cc)",
          "TURBO S (3745cc)",
        ],
      },
      {
        model: "CAYENNE",
        variants: [
          "COUPE BASE (2995cc)",
          "COUPE BASE (2995cc)",
          "TURBO S 4.8 (MT) (4806cc)",
          "GTS 4.8 (MT) (4806cc)",
          "TURBO 4.5 (MT) (4511cc)",
          "TURBO 4.8 (MT) (4806cc)",
          "TURBO JET BLACK METELLIC (3996cc)",
          "S II (3604cc)",
          "GTS II (3604cc)",
          "GTS 4.8 (AT) (4806cc)",
          "STD (2967cc)",
          "3.0 PANAROMIC ROOF AS (2967cc)",
          "3.0 E2 TIPTRONIC S (2967cc)",
          "S DEISEL (4134cc)",
          "II (3604cc)",
          "DIESEL II (2967cc)",
          "S DIESEL II (4134cc)",
          "TURBO II (4806cc)",
          "TURBO S II (4806cc)",
          "DIESEL PLATINUM EDITION (2967cc)",
          "S PLATINUM EDITION (3604cc)",
          "S DIESEL PLATINUM EDITION (4134cc)",
          "2.9 TIPTRONIC S (2860cc)",
          "3.0 E3 (2995cc)",
          "TURBO E3 (3996cc)",
          "PLATINUM EDITION BSVI (2995cc)",
          "BASE (3189cc)",
          "BASE (3000cc)",
          "GTS (4806cc)",
          "GTS. (4806cc)",
          "TURBO 4.8 (AT) (4806cc)",
          "S 4.8 (MT) (4806cc)",
          "S 3.0 HYBRID TIPTRONIC S (2993cc)",
          "S 4.8 HYBRID TIPTRONIC S (4806cc)",
          "S 4.8 TIPTRONIC (4806cc)",
          "V6 (2995cc)",
          "V6 3.6 TIPTRONIC (3598cc)",
          "3.2 TIPTRONIC TITANIUM METALLIC (3199cc)",
          "S (4806cc)",
          "S (4806cc)",
          "TURBO (4806cc)",
          "TURBO (4806cc)",
          "PLATINUM EDITION (3604cc)",
          "S HYBRID (2995cc)",
          "TURBO S (4806cc)",
          "E-HYBRID (2995cc)",
          "E HYBRID (2995cc)",
          "S 4.5 V8 MT (4511cc)",
          "TURBO S 3.0 V6 250 KW (2995cc)",
          "COUPE PLATINUM EDITION (2995cc)",
          "COUPE TURBO BS6 (2995cc)",
          "E HYBIRD (2995cc)",
          "3.0 V6 PETROL E3 (2995cc)",
          "3.2 V6 PETROL (2995cc)",
          "E3 (2995cc)",
          "COUPLE BASE (2995cc)",
          "COUPLE PLATINUM EDITION BSVI (2995cc)",
          "BASE (2995cc)",
          "COUPE E-HYBRID PLATINUM EDITION (2995cc)",
          "E-HYBRID PLATINUM EDITION (2995cc)",
          "E-HYBRID PLATINUM EDITION (2995cc)",
          "V6 PETROL (2995cc)",
          "COUPE 4 STR (2995cc)",
          "E-HYBRID PLATINUM EDITION COUPE (2995cc)",
          "COUPE PLATINUM EDITION 4 STR (2995cc)",
          "COUPE PLATINUM EDITION 5 STR (2995cc)",
          "COUPE (2995cc)",
          "PLATINUM EDITION (2995cc)",
          "DIESEL (2995cc)",
          "GTS 4.0 TWIN TURBO V8 AWD 5 SEAT (3996 CC) (3996cc)",
          "COUPE TURBO (3996cc)",
          "COUPE GTS COUPE (3996cc)",
          "TURBO 4.0 AUTO TRANS (3996cc)",
          "COUPLE (3996cc)",
          "GTS (3996cc)",
          "COUPE TURBO GT (3996cc)",
          "TURBO PETROL (3996cc)",
          "TURBO COUPE (3996cc)",
          "GTS COUPE (3996cc)",
          "TURBO (3996cc)",
          "GTS 4.8 MANUAL TRANS (4806cc)",
          "3.0 PANAROMIC ROOF AS (3000cc)",
          "3.0 TIPTRONIC S PLATINUM EDTN (2967cc)",
          "3.6 TIPTRONIC S PLATINUM EDTN (3598cc)",
          "3.2 TIPTRONIC TITANIUM M (3199cc)",
          "4.8 MANUAL TRANS (4806cc)",
          "TURBO S 4.8 MANUAL TRANS (4806cc)",
          "GTS 4.8 AUTOMATIC TRANS (4806cc)",
          "DIESEL 3.0 (3000cc)",
          "3.0 TIPTRONIC S DIESEL (2967cc)",
          "E2 DIESEL 3.0 TIPTRONIC S (2967cc)",
          "BASE (3598cc)",
          "GTS (3604cc)",
          "S 3.6 TIPTRONIC S PLATINUM EDTN (3598cc)",
          "S 4.0 TIPTRONIC S DIESEL (4124cc)",
          "S 4.0 TIPTRONIC S PLATINUM EDTN (4124cc)",
          "S 4.8 MANUAL TRANS (4806cc)",
          "TURBO 4.8 MANUAL TRANS (4806cc)",
          "TURBO 4.8 AUTO TRANS (4806cc)",
          "DIESEL (2967cc)",
          "DIESEL (2967cc)",
          "4.5 PETROL (4511cc)",
          "2.9 TIPTRONIC S DIESEL (2860cc)",
          "S (3604cc)",
          "S HYBRID 4.8 TIPTRONIC S (4806cc)",
          "S HYBRID 3.0 TIPTRONIC S (2993cc)",
          "TURBO 4.5 MANUAL TRANS (4511cc)",
          "TURBO S 4.8 MANUAL TRANS 5 SEATER (4806cc)",
          "V8 TIPTRONIC (4806cc)",
          "V6 AT (4806cc)",
          "S V8 MANUAL (4806cc)",
          "GTS TIPTRONIC (4806cc)",
          "V8 TURBO (4806cc)",
          "V6 MANUAL (4806cc)",
          "S V8 TIPTRONIC (4806cc)",
          "TURBO TIPTRONIC (4806cc)",
          "GTS 3.6 V6 TIPTRONIC S (3604cc)",
          "S PETROL (3604cc)",
          "GTS V6 3.6L (3604cc)",
          "GTS 3.4 TIPTRONIC COUPE 2 SEAT (3436 CC) (3436cc)",
          "S 3.4 PDK TERRACOTTA (3436cc)",
          "3.2 (3189cc)",
          "V6 (3189cc)",
          "TIPTRONIC (3598cc)",
          "3.6 TIPTRONIC S (3598cc)",
          "V6 (3598cc)",
          "V6. 5 SEATER (3598cc)",
          "BASE AT (3598cc)",
          "PLATINUM EDITION 5 STR (3598cc)",
          "V6 TIPTRONIC (3598cc)",
          "S (3598cc)",
          "PLATINUM EDITION (3598cc)",
          "DIESEL 3.0 (2967) (2967cc)",
          "DIESEL PLATINUM EDITION (2967) (2967cc)",
          "V6. (2967cc)",
          "3.0 DIESEL (2967cc)",
          "PLATINUM EDITION DIESEL (2967cc)",
          "PLATINUM EDITION (2967cc)",
          "PETROL PLATINUM EDITION  (2998) (2998cc)",
          "S 4.2 DIESEL TIPTRONIC (4134cc)",
          "S 4.2 DIESEL TIPTRONIC FULLY LOADED (4134cc)",
          "S DIESEL (4134cc)",
          "S (4134cc)",
          "S 4.5 TIPTRONIC (4511cc)",
          "S TIPTRONIC (3387cc)",
          "V8 (4800cc)",
          "DIESEL 3.0 PANAROMIC ROOF AIR SUSPENSION (3000cc)",
          "V6 MANUAL (4500cc)",
        ],
      },
      {
        model: "PANAMERA",
        variants: [
          "3.6 V6 (MT) (3605cc)",
          "S 4.8 (MT) (4806cc)",
          "STD (4134cc)",
          "EDITION (2967cc)",
          "4 E HYBRID G2 (2894cc)",
          "4 3.6 V6 PDK (3605cc)",
          "TURBO 4.8 (AT) (4806cc)",
          "4S 4.8 (AT) (4806cc)",
          "TURBO EXECUTIVE (3996cc)",
          "TURBO SPORT TURISMO (3996cc)",
          "TURBO 3.0 EXECUTIVE PDK (3996cc)",
          "TURBO 3.0 SPORT TURISMO PDK (3996cc)",
          "S 4.8 MANUAL TRANSMISSION (4806cc)",
          "TURBO 4.8 AUTO TRANSMISSION (4806cc)",
          "4S 4.8 AUTO TRANSMISSION (4806cc)",
          "3.6 V6 MANUAL TRANS (3605cc)",
          "DIESEL (4134cc)",
          "TURBO 4.0 EXECUTIVE (3996cc)",
          "TURBO S E-HYBRID (3996cc)",
          "TURBO 4 STR (3996cc)",
          "BASE (3996cc)",
          "GTS FACELIFT (3996cc)",
          "TURBO S (3996cc)",
          "4.0 TURBO (3996cc)",
          "TURBO 5 STR (3996cc)",
          "4.0 GTS (3996cc)",
          "GTS (3996cc)",
          "GTS SPORT TURISMO (3996cc)",
          "TURBO S E-HYBRID SPORT TURISMO (3996cc)",
          "TURBO S E-HYBRID EXECUTIVE (3996cc)",
          "TURBO S E-HYBRID FACELIFT (3996cc)",
          "TURBO (3996cc)",
          "GRAN TURISMO EDITION (2967cc)",
          "DIESEL 4 STR (2967cc)",
          "DIESEL 5 STR (2967cc)",
          "3.0 DIESEL (2967cc)",
          "DIESEL GRAN TURISMO EDITION (2967cc)",
          "DIESEL (2967cc)",
          "DIESEL (2967cc)",
          "PANRAMERA 4.0 (3605cc)",
          "BASE (3605cc)",
          "V6 (3605cc)",
          "3.6 V6 PDK (3605cc)",
          "4 (3605cc)",
          "3.0 S (2997cc)",
          "3.0 4S (2997cc)",
          "4.8 GTS (4806cc)",
          "GTS (4806cc)",
          "TURBO (4806cc)",
          "TURBO (4806cc)",
          "4S (4806cc)",
          "4S (4806cc)",
          "S (4806cc)",
          "TURBO SPORT TURISMO (4806cc)",
          "3.0 PLATINUM EDITION (2899cc)",
          "V6 (3600cc)",
          "3.0 V6 (2984cc)",
          "S E Ã‚â‚¬â€œ HYBRID (2995cc)",
          "S E - HYBRID (2995cc)",
          "4 (2894cc)",
          "PLATINUM EDITION (2894cc)",
          "STANDARD (2894cc)",
          "4 10 YEARS EDITION (2894cc)",
          "4S. (2894cc)",
        ],
      },
      {
        model: "CAYMAN",
        variants: [
          "718 (1988cc)",
          "2.9 6SPEED (MT) (2893cc)",
          "S (MT) (3387cc)",
          "S 3.4 6SPEED (MT) (3436cc)",
          "S 3.4 PDK TERRACOTTA (3436cc)",
          "S TIPTRONIC S (3387cc)",
          "2.9 MANUAL TRANS 6 SPEED (2893cc)",
          "S 3.4 MANUAL TRANS 6 SPEED (3436cc)",
          "S MANUAL TRANSMISSION (3387cc)",
          "S (3436cc)",
          "S. (3436cc)",
          "GTS MT (3436cc)",
          "GTS AT (3436cc)",
          "3.4 GTS AT (3436cc)",
          "3.4 GTS MT (3436cc)",
          "S 3.4 TIPTRONIC COUPE (3436 CC) (3436cc)",
          "GTS (3436cc)",
          "S TIPTRONIC (3436cc)",
          "2.7 CAYMAN AT (2706cc)",
          "2.7 CAYMAN MT (2706cc)",
          "BASE TIPTRONIC (2706cc)",
          "3.8 GT4 (3800cc)",
          "GT4 (3800cc)",
          "3.0L (2893cc)",
          "S (3387cc)",
          "S MANUAL (3387cc)",
          "BASE MANUAL (2687cc)",
          "BASE MT (3598cc)",
          "BASE AT (3598cc)",
        ],
      },
      {
        model: "MACAN",
        variants: [
          "2L (1984cc)",
          "MACAN (1984cc)",
          "TURBO PERFORMANCE PACKAGE (3604cc)",
          "GTS (2894cc)",
          "S (2967cc)",
          "S (2995cc)",
          "TURBO (3604cc)",
          "R4 (1984cc)",
          "2 (1984cc)",
          "2.0 L (1984cc)",
          "PETROL (1984cc)",
          "MACAN 2.0 PETROL (1984cc)",
          "MACAN R4 2.0 PETROL (1984cc)",
          "BASE (1984cc)",
          "2.0 PETROL (1984cc)",
          "2.0L (1968cc)",
          "MACAN 3.6 TURBO 294 KW PDK (3604cc)",
          "MACAN S 3.0 DIESEL (2997cc)",
          "MACAN S 3.0 DIESEL (2967 CC) (2967cc)",
          "S DIESEL (2967cc)",
          "MACAN GTS 3.0 PETROL (2894cc)",
          "GTS PETROL (2894cc)",
          "S PETROL (2894cc)",
          "S (2894cc)",
          "MACAN S 3.0 PETROL (2995cc)",
          "S PETROL (2995cc)",
        ],
      },
      {
        model: "TAYCAN",
        variants: [
          "RWD (3604cc)",
          "STD (99000cc)",
          "TURBO S (99000cc)",
          "ELECTRIC MOTOR (300000cc)",
          "BASE (300cc)",
          "CROSS TURISMO TURBO (4000cc)",
          "CROSS TURISMO TURBO S (4000cc)",
          "CROSS TURISMO 4S (4000cc)",
          "0 (4000cc)",
          "TURBO S (4000cc)",
          "4S (4000cc)",
          "TURBO (4000cc)",
          "GTS (4000cc)",
          "RWD (4000cc)",
          "CROSS TURISMO TURBO (466cc)",
          "CROSS TURISMO TURBO S (466cc)",
          "TURBO S (466cc)",
          "TURBO (466cc)",
          "CROSS TURISMO 4S (365cc)",
          "0 (390cc)",
          "4S (390cc)",
          "TURBO S (560cc)",
          "4S (361000cc)",
          "4S (324cc)",
          "TURBO (359990cc)",
          "GTS (359990cc)",
          "RWD (359990cc)",
          "GTS (440cc)",
          "GTS (93cc)",
        ],
      },
      {
        model: "BOXSTER",
        variants: [
          "3 (2706cc)",
          "S TIPTRONIC (2706cc)",
          "BASE TIPTRONIC (2706cc)",
          "GTS MT (3436cc)",
          "GTS 3.4 MT (3436cc)",
          "BOXSTER S II (3436cc)",
          "S 3.4 BASE TIPTRONIC (3436cc)",
          "S 3.4 BASE  TIPTRONIC (3436cc)",
          "S 3.4 PDK 981 (3436cc)",
          "S 3.4 6 SPEED 1MANUAL TRASMISSION (3436cc)",
          "3.5 S (3436cc)",
          "S AT (3436cc)",
          "GTS (3436cc)",
          "S (3436cc)",
          "718 (1988cc)",
          "718 (1988cc)",
          "S 4 STR (3179cc)",
          "S 3.2 (MT) (3179cc)",
          "S (3179cc)",
          "S MANUAL 5 STR (3387cc)",
          "BOXSTER S II (3387cc)",
          "S 3.4 BASE TIPTRONIC (3387cc)",
          "S 3.4 BASE MANUAL TRANSMISSION (3387cc)",
          "S 3.4 BASE MANUAL TRANSMISSION (3387cc)",
          "S 3.4 BASE (MT) (3387cc)",
          "S MANUAL (3387cc)",
          "S TIPTRONIC (3387cc)",
          "2.7 BASE MANUAL TRANSMISSION (2687cc)",
          "2.7 BASE MANUAL TRANSMISSION (2687cc)",
          "2.7 BASE (MT) (2687cc)",
          "BASE MANUAL (2687cc)",
          "BASE TIPTRONIC (2687cc)",
          "3.8 SPYDER (3800cc)",
          "3.2 S ROADSTER (3200cc)",
          "S 3.2 MANUAL TRANS (3200cc)",
          "S 3.2 MANUAL TRANS (3200cc)",
          "3.2 S (3200cc)",
          "S I (981) (3400cc)",
          "S (3400cc)",
          "2.9 BASE TIPTRONIC (2893cc)",
          "2.9 BASE  TIPTRONIC (2893cc)",
          "S 3.6 (MT) (3600cc)",
          "S 3.6 MANUAL TRANS (3600cc)",
          "BASE MT (3598cc)",
          "BASE AT (3598cc)",
          "S (4800cc)",
        ],
      },
      {
        model: "CARRERAS",
        variants: ["0 (3824cc)"],
      },
      {
        model: "9PAE",
        variants: ["A22 (4511cc)"],
      },
    ],
  },
  {
    make: "PREMIER",
    models: [
      {
        model: "RIO",
        variants: [
          "DX (1489cc)",
          "DX (1489cc)",
          "EX (1489cc)",
          "EX (1489cc)",
          "LX (1489cc)",
          "LX (1489cc)",
          "GLX (1173cc)",
          "GLX (1173cc)",
          "GX (1173cc)",
          "GX (1173cc)",
          "CRDI4 LX (1248cc)",
          "CRDI4 DX (1248cc)",
          "PLUS GX (1173cc)",
          "PLUS GLX (1173cc)",
          "EX (1248cc)",
          "PLUS LX (1489cc)",
          "PLUS DX (1489cc)",
          "PLUS 1.3 CRDI LX (1248cc)",
          "PLUS 1.3 CRDI DX (1248cc)",
          "1.3 DX (1248cc)",
          "1.3 LX (1248cc)",
          "1.3 CRDI DX (1248cc)",
          "1.3 CRDI LX (1248cc)",
          "CRDI4 (1248cc)",
          "PLUS LX (1248cc)",
          "PLUS DX (1248cc)",
          "PREMIER PLUS DX (1248cc)",
          "PREMIER CRDI4 (1248cc)",
          "PREMIER LX CRDI (1248cc)",
          "PREMIER LX CRDI4 (1248cc)",
          "PREMIER DX CRDI (1248cc)",
          "PREMIER PLUS LX (1248cc)",
          "PLUS EX (1489cc)",
          "DX BEIGE INTERIOR (1489cc)",
          "PREMIER LX (1489cc)",
          "PREMIER EX (1489cc)",
          "PREMIER DX (1489cc)",
          "PREMIER PLUS DX (1489 CC) (1489cc)",
          "PETROL GLX (1173cc)",
          "GX BEIEG INTERIOR (1173cc)",
          "PETROL GX (1173cc)",
          "PREMIER PLUS GLX (1173cc)",
          "PREMIER GX (BEIGE INTERIOR) (1173cc)",
          "PREMIER GLX (BEIGE INTERIOR) (1173cc)",
          "PREMIER GLX (GREY INTERIOR) (1173cc)",
          "PREMIER GX (1173cc)",
          "PREMIER PLUS GX (1173cc)",
          "PREMIER GX (GREY INTERIOR) (1173cc)",
          "PREMIER GLX (1173cc)",
        ],
      },
      {
        model: "PADMINI",
        variants: [
          "CLASSIC (1089cc)",
          "STD (1366cc)",
          "NE 118 (1172cc)",
          "138D (1366cc)",
          "(DELUXE) BE (1089cc)",
          "STANDARD (1089cc)",
          "PADMINI (1089cc)",
          "STD (1089cc)",
        ],
      },
      {
        model: "VINTAGE",
        variants: [
          "PAL 118NE VICEROY (1171cc)",
          "PADMINI (700cc)",
          "PRESIDENT (1069cc)",
        ],
      },
      {
        model: "SIGMA",
        variants: [
          "DX 9 SEATER (2000cc)",
          "DX 7 SEATER (2000cc)",
          "DX 8 SEATER (2000cc)",
          "DX 7 STR (1995cc)",
          "DX (1995cc)",
          "EX (1995cc)",
          "DSL EX (1995cc)",
          "DLX (1995cc)",
          "5 SEATER (1489cc)",
          "8 SEATER (1489cc)",
          "1.4 8X (1489cc)",
          "AC 1.4 TC (1489cc)",
          "DX (1489cc)",
          "1.4 TURBO A/C (1489cc)",
          "1.4 DIESEL (1489cc)",
          "1.4 5X AC (1489cc)",
          "1.4 7X AC (1489cc)",
          "1.4 8X AC (1489cc)",
          "1.4 (1489cc)",
          "DX 7STR (2000cc)",
          "DX 9STR (2000cc)",
          "EX 7STR (2000cc)",
          "EX 9STR (2000cc)",
          "DX (2000cc)",
          "EX (2000cc)",
          "EX 9 SEATER (2000cc)",
          "EX 7 SEATER (2000cc)",
          "EX 8 SEATER (2000cc)",
        ],
      },
      {
        model: "PEUGEOT",
        variants: [
          "STD (1360cc)",
          "STANDARD (1360cc)",
          "308 TPH 156 (1596cc)",
          "SEDAN (2524cc)",
          "PG (1597cc)",
        ],
      },
      {
        model: "138 D",
        variants: ["STANDARD (1366cc)", "STD (1366cc)"],
      },
      {
        model: "& SHAURYA ER005",
        variants: ["STR 4 (345cc)", "STR 5 (510cc)"],
      },
      {
        model: "118 NE",
        variants: ["STD (1180cc)", "STD (1180cc)"],
      },
    ],
  },
  {
    make: "SKODA",
    models: [
      {
        model: "FABIA",
        variants: [
          "1.2 MPI ACTIVE PLUS (1198cc)",
          "1.2 TDI ACTIVE PLUS CR (1199cc)",
          "1.2 TDI ACTIVE CR (1199cc)",
          "1.2 CRDI AMBIENTE (1199cc)",
          "1.2 MPI AMBIENTE PLUS (1198cc)",
          "1.2 TDI AMBIENTE PLUS CR (1199cc)",
          "1.2 MPI AMBITION (1198cc)",
          "1.2 MPI AMBITION PLUS (1198cc)",
          "1.2 TDI AMBITION PLUS (1199cc)",
          "1.2 TDI AMBITION (1199cc)",
          "1.2 CRDI CLASSIC (1199cc)",
          "1.2 CRDI ELEGANCE (1199cc)",
          "1.2 TDI ELEGANCE (1199cc)",
          "1.4 TDI ELEGANCE PD (MT) (1422cc)",
          "1.2 MPI SCOUT (1198cc)",
          "1.2 TDI SCOUT (1199cc)",
          "1.6 ELEGANCE MPI (1598cc)",
          "1.2 MPI ACTIVE (1198cc)",
          "1.2 MPI CLASSIC (1198cc)",
          "1.2 MPI AMBIENTE (1198cc)",
          "1.4 MPI CLASSIC (1390cc)",
          "1.4 MPI ELEGANCE (1390cc)",
          "1.2 MPI ELEGANCE (1198cc)",
          "1.4 TDI PD ACTIVE (1422cc)",
          "1.4 TDI PD CLASSIC (1422cc)",
          "1.4 TDI PD AMBIENTE (1422cc)",
          "SCOUT 1.2 TDI (1199cc)",
          "SCOUT 1.2 TDI (1199cc)",
          "SCOUT 1.2 TDI (1199cc)",
          "1.2 AMBIENTE PLUS MPI (1198cc)",
          "1.2 ACTIVE PLUS MPI (1198cc)",
          "1.2 AMBITION PLUS MPI (1198cc)",
          "1.2 AMBITION MPI (1198cc)",
          "1.2 ELEGANCE CRDI (1199cc)",
          "1.2 AMBIENTE PLUS TDI CR (1199cc)",
          "1.2 ELEGANCE TDI CR (1199cc)",
          "1.2 ACTIVE PLUS TDI CR (1199cc)",
          "1.2 AMBITION PLUS TDI CR (1199cc)",
          "ELEGANCE 1.4 TDI PD MT (1422cc)",
          "1.2 ACTIVE TDI CR (1199cc)",
          "1.2 AMBITION TDI CR (1199cc)",
          "1.2 CLASSIC CRDI (1199cc)",
          "1.2 AMBIENTE CRDI (1199cc)",
          "SCOUT 1.2 MPI (1198cc)",
          "SCOUT 1.2 MPI (1198cc)",
          "SCOUT 1.2 MPI (1199cc)",
          "ELEGANCE 1.2 TDI (1199cc)",
          "ELEGANCE 1.2 TDI (1199cc)",
          "CLASSIC 1.2 TDI (1199cc)",
          "CLASSIC 1.2 TDI (1199cc)",
          "AMBITION 1.2 TDI (1199cc)",
          "AMBITION 1.2 TDI (1199cc)",
          "1.2 AMBIENTE (1199cc)",
          "1.2 AMBIENTE PLUS (1199cc)",
          "1.2 AMBITION PLUS (1199cc)",
          "1.2 ELEGANCE (1199cc)",
          "1.2 AMBITION (1199cc)",
          "1.2 CLASSIC (1199cc)",
          "SCOUT (1199cc)",
          "1.2 TDI ACTIVE (1199cc)",
          "1.2 TDI ACTIVE PLUS (1199cc)",
          "AMBIENTE 1.2 TDI (1199cc)",
          "AMBIENTE 1.2 TDI (1199cc)",
          "AMBITION PLUS 1.2 TDI CR (1199cc)",
          "NEW CLASSIC 1.2 TDI (1199cc)",
          "NEW ELEGANCE 1.2 TDI (1199cc)",
          "NEW AMBIENTE 1.2 TDI (1199cc)",
          "ELEGANCE 1.2 CRDI (1199cc)",
          "AMBITION PLUS 1.2 TDI (1199cc)",
          "SCOUT 1.2 MPI PETROL (1199cc)",
          "ACTIVE 1.2 TDI (1199cc)",
          "ACTIVE PLUS 1.2 TDI CR (1199cc)",
          "ELEGANCE 1.4 (1422cc)",
          "AMBIENTE 1.4 (1422cc)",
          "CLASSIC 1.4 (1422cc)",
          "1.4 CLASSIC (1422cc)",
          "1.4 ELEGANCE (1422cc)",
          "1.4 ACTIVE (1422cc)",
          "1.4 AMBIENTE (1422cc)",
          "1.4 ELEGANCE MPI (1422cc)",
          "AMBIENTE 1.4 PD TDI (1422cc)",
          "ELEGANCE 1.4 PD TDI (1422cc)",
          "CLASSIC 1.4 PD TDI (1422cc)",
          "ACTIVE 1.4 PD TDI (1422cc)",
          "ACTIVE 1.4 (1390cc)",
          "1.4 CLASSIC (1390cc)",
          "1.4 MPI AMBIENTE (1390cc)",
          "1.4 MPI ACTIVE (1390cc)",
          "1.4 ELEGANCE (1390cc)",
          "ACTIVE 1.4 MPI (1390cc)",
          "AMBIENTE 1.4 MPI (1390cc)",
          "CLASSIC 1.4 MPI (1390cc)",
          "CLASSIC 1.4 PD TDI (1390cc)",
          "ELEGANCE 1.4 MPI (1390cc)",
          "ELEGANCE 1.4 MPI (1390cc)",
          "COMBI 1.9 TDI (1896cc)",
          "AMBITION 1.2 MPI (1198cc)",
          "AMBITION 1.2 MPI (1198cc)",
          "1.2 AMBIENTE (1198cc)",
          "1.2 AMBIENTE PLUS (1198cc)",
          "1.2 AMBITION PLUS (1198cc)",
          "1.2 ELEGANCE (1198cc)",
          "1.2 AMBITION (1198cc)",
          "1.2 CLASSIC (1198cc)",
          "ACTIVE PLUS (1198cc)",
          "SCOUT (1198cc)",
          "ACTIVE (1198cc)",
          "AMBITION PLUS 1.2 MPI (1198cc)",
          "ACTIVE 1.2 TDI (1198cc)",
          "NEW ELEGANCE 1.2 MPI (1198cc)",
          "FABIA AMBITION 1.2 MPI (1198cc)",
          "FABIA AMBITION PLUS 1.2 MPI (1198cc)",
          "NEW CLASSIC 1.2 MPI (1198cc)",
          "NEW AMBIENTE 1.2 MPI (1198cc)",
          "AMBIENTE 1.2 MPI (1198cc)",
          "AMBIENTE 1.2 MPI (1198cc)",
          "CLASSIC 1.2 MPI (1198cc)",
          "CLASSIC 1.2 MPI (1198cc)",
          "ACTIVE 1.2 MPI (1198cc)",
          "ACTIVE 1.2 MPI (1198cc)",
          "ELEGANCE 1.2 MPI (1198cc)",
          "ELEGANCE 1.2 MPI (1198cc)",
          "ACTIVE PLUS 1.2 MPI (1198cc)",
          "1.6 ELEGANCE (1598cc)",
          "1.6 ELEGANCE (1598cc)",
          "ELEGANCE 1.6 MPI (1598cc)",
          "AMBIENTE PLUS (1968cc)",
        ],
      },
      {
        model: "OCTAVIA",
        variants: [
          "COMBI L&K (1896cc)",
          "COMBI L&K (1896cc)",
          "1.8 BLITZ (1781cc)",
          "STYLE 1.4 TSI MT (1395cc)",
          "1.4 TSI ACTIVE NEW (MT) (1395cc)",
          "2.0 TDI ACTIVE CR NEW (MT) (1968cc)",
          "2.0 AMBIENTE MPI (1984cc)",
          "1.8 AMBIENTE TURBO (1781cc)",
          "1.9 AMBIENTE TDI (1896cc)",
          "1.4 TSI AMBITION (MT) (1395cc)",
          "1.4 TSI AMBITION NEW (MT) (1395cc)",
          "1.8 TSI AMBITION NEW (1798cc)",
          "2.0 TDI AMBITION (AT) (1968cc)",
          "2.0 TDI AMBITION (MT) (1968cc)",
          "1.4 TSI AMBITION PLUS (MT) (1395cc)",
          "2.0 TDI AMBITION PLUS (AT) (1968cc)",
          "2.0 TDI AMBITION PLUS (MT) (1968cc)",
          "1.9 TDI ELEGANCE (MT) (1896cc)",
          "2.0 MPI ELEGANCE (1984cc)",
          "1.9 TDI ELEGANCE (AT) (1896cc)",
          "1.9 TDI ELEGANCE PLUS (1896cc)",
          "1.9 TDI L&K (1896cc)",
          "1.8 TPI RIDER (1781cc)",
          "1.8 TURBO RIDER (1781cc)",
          "1.9 CLASSIC RIDER (1896cc)",
          "1.9 TDI RIDER (AT) (1896cc)",
          "1.9 TDI RIDER (MT) (1896cc)",
          "2.0 MPI RIDER (1984cc)",
          "1.8 RS TURBO (1781cc)",
          "2.0 TDI STYLE PLUS (AT) (1968cc)",
          "1.8 TSI ELEGANCE NEW (AT) (1798cc)",
          "1.8 TSI STYLE (MT) (1798cc)",
          "1.4 MPI ELEGANCE (1395cc)",
          "STYLE 2.0 TDI MT (1968cc)",
          "2.0 TDI L&K (AT) (1968cc)",
          "2.0 TDI STYLE (AT) (1968cc)",
          "2.0 TDI ELEGANCE (AT) (1968cc)",
          "2.0 TSI VRS (AT) (1984cc)",
          "RS 245 (AT) BSVI (1984cc)",
          "LAURIN AND KLEMENT (1984cc)",
          "2.0 TSI L&K AT (1984cc)",
          "2.0 TSI STYLE AT (1984cc)",
          "1.8 TSI STYLE AT (1798cc)",
          "1.8 TSI AMBITION PLUS AT (1798cc)",
          "1.8 TSI STYLE PLUS AT (1798cc)",
          "L&K (1896cc)",
          "L & K 1.8 TSI AT (1798cc)",
          "RS 2.0 TSI AT (1984cc)",
          "ONYX 1.8 TSI AT (1798cc)",
          "ONYX 2.0 TDI AT (1968cc)",
          "COMBI RS (1781cc)",
          "COMBI RS (1781cc)",
          "L K 1.9 TDI (1896cc)",
          "L&K 1.9 TDI (1896cc)",
          "AMBITION PLUS 1.4 TSI MT (1395cc)",
          "RIDER 1.9 CLASSIC (1896cc)",
          "AMBITION 1.4 TSI MT (1395cc)",
          "AMBITION 2.0 TDI MT (1968cc)",
          "AMBITION PLUS 2.0 TDI AT (1968cc)",
          "AMBITION PLUS 2.0 TDI MT (1968cc)",
          "ACTIVE 1.4 TSI MT 103 KW NEW (1395cc)",
          "ACTIVE 2.0 TDI CR MT 105 KW N (1968cc)",
          "AMBITION 1.4 TSI MT 103 KW N (1395cc)",
          "AMBITION 1.8 TSI 118 KW NEW (1798cc)",
          "ELEGANCE 1.8 TSI AT 118 KW N (1798cc)",
          "ELEGANCE 1.8 TSI AT 132 KW N (1798cc)",
          "RIDER 1.8 TPI (1781cc)",
          "RIDER 1.8 TPI (1781cc)",
          "STYLE PLUS 1.8 TSI AT (1798cc)",
          "1.8 BLITZ PETROL (1781cc)",
          "AMBIENT 2.0 MPI (1984cc)",
          "RIDER 1.9 TDI  AUTO TRANS (1896cc)",
          "RIDER 1.9 TDI MANUAL TRANS (1896cc)",
          "ELEGANCE PLUS 1.9 TDI 66 KW (1896cc)",
          "ELEGANCE 1.9 TDI MANUAL TRANS (1896cc)",
          "ELEGANCE AUTO TRANS 1.9 TDI (1896cc)",
          "AMBITION 2.0 TDI AT (1968cc)",
          "RS 1.8 TURBO (1800cc)",
          "AMBIENTE 1.9 TDI (1896cc)",
          "AMBIENTE 1.9 TDI (1896cc)",
          "STYLE PLUS 2.0 TDI AT (1968cc)",
          "AMBITION PLUS 1.8 TSI AT (1798cc)",
          "RIDER 1.8 TURBO (1781cc)",
          "ELEGANCE 2.0 MPI (1984cc)",
          "ELEGANCE 2.0 MPI (1984cc)",
          "RIDER 2.0 MPI (1984cc)",
          "RIDER 2.0 MPI (1984cc)",
          "AMBIENTE 1.8 TURBO (1781cc)",
          "RIDER CLASSIC 1.9 TDI (1896cc)",
          "ELEGANCE 1.9 TDI MT (1896cc)",
          "L & K 1.9 TDI COMBI (1896cc)",
          "1.9 ELEGANCE AT (1896cc)",
          "1.9 ELEGANCE PLUS (1896cc)",
          "1.9 ELEGANCE MT (1896cc)",
          "CLASSIC 1.9 TDI MT (1896cc)",
          "AMBIENTE (1896cc)",
          "RIDER 1.9 TDI MT (1896cc)",
          "RIDER 1.9 TDI AT (1896cc)",
          "RIDER 1.9 TDI CLASSIC (1896cc)",
          "COMBI LANDK (1896cc)",
          "RIDER 1.9 TDI (1896cc)",
          "RIDER 1.9 TDI (1896cc)",
          "1.9 TDI (1896cc)",
          "1.9 TDI (1896cc)",
          "BLITZ (1896cc)",
          "L AND K (1896cc)",
          "ELEGANCE AT 1.9 TDI (1896cc)",
          "RIDER AT 1.9 TDI (1896cc)",
          "AMBIENTE 2.0 MPI (1896cc)",
          "ELEGANCE PLUS 1.9 TDI (1896cc)",
          "ELEGANCE 1.9 TDI (1896cc)",
          "ELEGANCE 1.9 TDI (AT) (1984cc)",
          "2.0 RIDER (1984cc)",
          "2.0 ELEGANCE (1984cc)",
          "2.0 STYLE TSI AT BS6 (1984cc)",
          "2.0 L&K TSI AT BS6 (1984cc)",
          "RS 2.0 TSI AT BS6 (1984cc)",
          "RS 245 BS6 (1984cc)",
          "L&K 2.0 TSI AT (1984cc)",
          "2.0 RS 245 PETROL 180 KW AUTOMATIC (1984cc)",
          "2.0 STYLE TSI AT (1984cc)",
          "2.0 L AND K TSI AUTOMATIC 140 KW (1984cc)",
          "RS 2.0 TSI AT 169 KW (1984cc)",
          "VRS 2.0 TSI (1984cc)",
          "2.0 L&K (1984cc)",
          "2.0 STYLE (1984cc)",
          "LAURIN KLEMENT 2.0 TSI AT (1984cc)",
          "RS (1984cc)",
          "ZEAL ELEGANCE 2.0 TDI AT (1984cc)",
          "2.0 AMBIENTE (1984cc)",
          "BLITZ (1984cc)",
          "2.0 STYLE AT (1984cc)",
          "2.0 L&K AT (1984cc)",
          "1.8 TSI AT ONYX (1984cc)",
          "RS 245 (1984cc)",
          "AMBIENTE 2.0 MPI (1984cc)",
          "AMBIENTE 2.0 MPI (1984cc)",
          "2.0 MPI (1984cc)",
          "RS BLITZ 1.8 TPI COMBI (1781cc)",
          "AMBIENTE 1.8 TPI (1781cc)",
          "1.8 AMBIENTE (1781cc)",
          "1.8 RIDER (1781cc)",
          "COMBI 1.8 TURBO RS MT (1781cc)",
          "1.8 TPI (1781cc)",
          "1.8 TPI E-III (1781cc)",
          "AMBITION 1.8 TSI AT (1781cc)",
          "RS 1.8 TURBO (1781cc)",
          "COMBI BLITZ RS 1.8 TPI (1781cc)",
          "COMBI 1.8 TPI (1781cc)",
          "1.8 TDI (1781cc)",
          "AMBIENTE 2.0 MPI (1781cc)",
          "1.4 AMBITION (1395cc)",
          "ACTIVE (1395cc)",
          "1.4 AMBITION PLUS MT (1395cc)",
          "STYLE 1.4 TSI (1395cc)",
          "ELEGANCE 1.4 MPI 103 KW (1395cc)",
          "ACTIVE 1.4 TSI PETROL MT 103 KW NEW (1395cc)",
          "AMBITION 1.4 TSI PETROL MT 103 KW  NEW (1395cc)",
          "AMBITION PLUS 1.4 TSI PETROL MT 103 KW NEW (1395cc)",
          "STYLE 1.4 MPI 103 KW (1395cc)",
          "STYLE (1395cc)",
          "CORPORATE EDITION P (1395cc)",
          "CORPORATE EDITION PETROL (1395cc)",
          "ACTIVE 1.4 TSI (1395cc)",
          "AMBITION 1.4 TSI (1395cc)",
          "1.4 TSI AMBITION PLUS (1395cc)",
          "1.4 TSI STYLE (1395cc)",
          "1.8 ELEGANCE (1798cc)",
          "1.8 AMBITION (1798cc)",
          "ELEGANCE AT (1798cc)",
          "1.8 AMBITION PLUS AT (1798cc)",
          "STYLE 1.8 TSI AT (1798cc)",
          "AMBITION 1.8 TSI PETROL 118 KW NEW (1798cc)",
          "ELEGANCE 1.8 TSI PETROL AT 118 KW  NEW (1798cc)",
          "1.8 TSI 132 KW ONYX AUTOMATIC TRANSMISSION (1798cc)",
          "1.8 TSI 132 KW L&K AUTOMATIC TRANSMISSION (1798cc)",
          "1.8 TSI 132 KW AMBITION PLUS AUTOMATIC TRANSMISSION (1798cc)",
          "1.8 TSI 132 KW STYLE MANUAL TRANSMISSION (1798cc)",
          "ELEGANCE 1.8 TSI PETROL AT 132 KW  NEW (1798cc)",
          "STYLE (1798cc)",
          "ONYX EDITION PETROL (1798cc)",
          "STYLE AT ( 132 KW TSI ) (1798cc)",
          "STYLE PLUS 1.8 TSI AT ANNIVERSARY LE (1798cc)",
          "AMBITION 1.8 TSI AT (1798cc)",
          "1.8 TSI L&K (1798cc)",
          "STYLE AT 1.8 TSI (1798cc)",
          "STYLE PLUS AT 1.8 TSI (1798cc)",
          "ELEGANCE 1.8 TSI AT (1798cc)",
          "ACTIVE (1968cc)",
          "AMBITION AT (1968cc)",
          "2.0 ELEGANCE AT (1968cc)",
          "2.0 AT ANN EDI (1968cc)",
          "AMBITION 2.0 TDI (1968cc)",
          "L&K 2.0 TDI MT 105 KW (1968cc)",
          "2.0 RS TDI AUTOMATIC (1968cc)",
          "STYLE PLUS 2.0 TDI CR AT (1968cc)",
          "2.0 TDI CR AMBITION PLUS MANUAL TRANSMISSION 105 KW (1968cc)",
          "2.0 TDI CR AMBITION PLUS AUTOMATIC TRANSMISSION 105 KW (1968cc)",
          "2.0 TDI CR AUTOMATIC TRANSMISSION 105 KW (1968cc)",
          "ACTIVE 2.0 TDI CR DIESEL MT 105 KW  NEW (1968cc)",
          "AMBITION 2.0 TDI CR DIESEL MT 105 KW NEW (1968cc)",
          "AMBITION 2.0 TDI CR DIESEL AT 105 KW NEW (1968cc)",
          "ELEGANCE 2.0 TDI CR DIESEL AT 105 KW NEW (1968cc)",
          "STYLE (1968cc)",
          "AMBITION MT (1968cc)",
          "2.0 TDI CR AMBITION (1968cc)",
          "STYLE PLUS TDI AT ANNIVERSARY LE (1968cc)",
          "ONYX EDITION DIESEL (1968cc)",
          "AMBITION MT 105KMW TDI (1968cc)",
          "ELEGANCE 2.0 TDI AT (1968cc)",
          "ZEAL ELEGANCE 2.0 TDI AT (1968cc)",
          "CORPORATE EDITION D (1968cc)",
          "2.0 TDI L&K (1968cc)",
          "2.0 TDI CR AMBITION PLUS (1968cc)",
          "2.0 TDI CR AMBITION PLUS AT (1968cc)",
          "2.0 TDI CR STYLE (1968cc)",
          "2.0 TDI CR STYLE AT (1968cc)",
          "2.0 TDI CR STYLE PLUS AT (1968cc)",
          "AMBITION 2.0 TDI CR AT (1968cc)",
          "AMBITION 2.0 TDI CR (1968cc)",
          "CORPORATE EDITION DIESEL (1968cc)",
          "AMBITION PLUS 2.0 TDI CR AT (1968cc)",
          "AMBITION PLUS 2.0 TDI CR (1968cc)",
          "ELEGANCE 2.0 TDI CR AT (1968cc)",
          "L&K 2.0 TDI CR AT (1968cc)",
          "STYLE 2.0 TDI CR AT (1968cc)",
          "STYLE 2.0 TDI CR MT (1968cc)",
          "STYLE PLUS AT 1 8 TSI (1968cc)",
          "ACTIVE 2.0 TDI CR (1968cc)",
          "ELEGANCE 2.0 MPI (1968cc)",
          "RIDER 2.0 MPI (1968cc)",
          "2.0 AMBIENTE (1996cc)",
          "2.0 ELEGENCE (1996cc)",
        ],
      },
      {
        model: "RAPID",
        variants: [
          "1.6 MPI AMBITION (1598cc)",
          "1.6 MPI ELEGANCE (AT) (1598cc)",
          "1.6 MPI ELEGANCE (MT) (1598cc)",
          "1.6 MPI STYLE (1598cc)",
          "1.5 TDI AMBITION (1498cc)",
          "1.5 TDI AMBITION AT (1498cc)",
          "1.5 TDI ACTIVE (1498cc)",
          "1.5 TDI CR AMBITION PL (AW) (1498cc)",
          "1.5 TDI CR AMBITION PL (AW) AT (1498cc)",
          "1.5 TDI CR STYLE PLUS BPAC(AT) (1498cc)",
          "1.5 TDI CR STYLE PLUS BPAC(MT) (1498cc)",
          "1.5 TDI STYLE (1498cc)",
          "1.6 MPI AMBITION PL (AW) (1598cc)",
          "1.6 MPI AMBITION PL (AW) (AT) (1598cc)",
          "1.6 MPI STYLE PLUS B PAC (AT) (1598cc)",
          "1.6 MPI STYLE PLUS B PAC (MT) (1598cc)",
          "1.6 MPI ACTIVE (MT) (1598cc)",
          "1.6 TDI CR ACTIVE (MT) (1598cc)",
          "1.6 MPI ACTIVE PLUS (MT) (1598cc)",
          "1.6 TDI CR ACTIVE PLUS (MT) (1598cc)",
          "1.6 TDI AMBITION CR (MT) (1598cc)",
          "1.6 MPI AMBITION PLUS (AT) (1598cc)",
          "1.6 MPI AMBITION PLUS (MT) (1598cc)",
          "1.6 TDI AMBITION PLUS CR (MT) (1598cc)",
          "1.6 TDI ELEGANCE CR (MT) (1598cc)",
          "1.6 TDI ELEGANCE PLUS (1598cc)",
          "1.6 MPI LEISURE (AT) (1598cc)",
          "1.6 MPI LEISURE (MT) (1598cc)",
          "1.6 TDI CR LEISURE PLUS (MT) (1598cc)",
          "1.6TDI PRESTIGE CR LTD E (MT) (1598cc)",
          "1.6 MPI ULTIMA AMBITION PL(AT) (1598cc)",
          "1.6 MPI ULTIMA AMBITION PL(MT) (1598cc)",
          "1.6TDI ULTIMA AMBITION PLCR MT (1598cc)",
          "1.6 MPI ULTIMA ELEGANCE (AT) (1598cc)",
          "1.6 MPI ULTIMA ELEGANCE (MT) (1598cc)",
          "1.6 TDI CR ULTIMA ELEGANCE(MT) (1598cc)",
          "X 1.6 MPI MT (1598cc)",
          "X 1.6 MPI AT (1598cc)",
          "1.5 TDI ACTIVE MT (1498cc)",
          "1.6 EDITION X TDI CR (MT) (1498cc)",
          "1.6 EDITION X TDI CR (AT) (1498cc)",
          "1.6 MPI STYLE EDITIONX (MT) (1598cc)",
          "1.6 MPI STYLE EDITIONX (AT) (1598cc)",
          "1.5 TDI STYLE EDITIONX (MT) (1498cc)",
          "1.5 TDI STYLE EDITIONX (AT) (1498cc)",
          "1.5 TDI STYLE LIMITED EDTN AT (1498cc)",
          "1.5 TDI ONYX (MT) (1498cc)",
          "1.5 TDI ONYX (AT) (1498cc)",
          "1.6 MPI RIDER LIMITED EDTN (1598cc)",
          "1.0 TSI RIDER (MT) BSVI (999cc)",
          "1.0 TSI AMBITION (MT) BSVI (999cc)",
          "1.0 TSI ONYX (MT) BSVI (999cc)",
          "1.0 TSI STYLE (MT) BSVI (999cc)",
          "1.0 TSI MONTE CARLO(MT) BSVI (999cc)",
          "1.0 TSI ACTIVE (MT) BSVI (999cc)",
          "AMBITION 77KW TDI CR (1757cc)",
          "1.0 TSI RIDER PLUS (MT) BSVI (999cc)",
          "AMBITION 77KW MPI (1145cc)",
          "1.0 TSI MT MATTE FINISH (999cc)",
          "1.0 TSI AT MATTE FINISH (999cc)",
          "1.5 TDI STYLE AT (1498cc)",
          "1.0 TSI RIDER PLUS AT BSVI (999cc)",
          "1.0 TSI AMBITION AT BSVI (999cc)",
          "1.0 TSI ONYX AT BSVI (999cc)",
          "1.0 TSI STYLE AT BSVI (999cc)",
          "1.0 TSI MONTE CARLO AT BSVI (999cc)",
          "ELEGANCE MPI (1798cc)",
          "1.6 MPI STYLE PLUS MT (1598cc)",
          "1.6 MPI ONYX AT (1598cc)",
          "1.6 MPI ONYX MT (1598cc)",
          "1.5 TDI CR STYLE PLUS MT (1498cc)",
          "1.5 TDI CR ACTIVE MT (1498cc)",
          "1.5 TDI CR AMBITION PLUS MT (1498cc)",
          "1.5 TDI CR ELEGANCE MT (1498cc)",
          "1.6 MPI ELEGANCE PLUS AT (1598cc)",
          "1.6 MPI ELEGANCE PLUS MT (1598cc)",
          "1.6 MPI ACTIVE (1598cc)",
          "1.6 MPI AMBITION MT (1598cc)",
          "1.5 TDI CR AMBITION (1498cc)",
          "1.6 MPI STYLE PLUS AT (1598cc)",
          "MONTE CARLO 1.5 TDI AT (1498cc)",
          "MONTE CARLO 1.5 TDI MT (1498cc)",
          "1.5 TDI CR ELEGANCE PLUS MT (1498cc)",
          "1.6 MPI AMBITION AT (1598cc)",
          "1.6 MPI STYLE MT (1598cc)",
          "1.0 TSI ACTIVE AT (999cc)",
          "1.5 TDI CR STYLE AT (1498cc)",
          "1.5 TDI CR STYLE MT (1498cc)",
          "1.6 MPI STYLE AT (1598cc)",
          "1.5 TDI CR STYLE PLUS AT (1498cc)",
          "1.5 TDI CR ELEGANCE PLUS AT (1498cc)",
          "MONTE CARLO 1.6 MPI AT (1598cc)",
          "MONTE CARLO 1.6 MPI MT (1598cc)",
          "1.5 TDI CR ELEGANCE AT (1498cc)",
          "1.5 TDI CR AMBITION AT (1498cc)",
          "1.5 TDI CR AMBITION PLUS AT (1498cc)",
          "NEW 1.0 TSI AMBITION AT (999cc)",
          "NEW 1.0 TSI AMBITION MT (999cc)",
          "NEW 1.0 TSI MONTE CARLO AT (999cc)",
          "NEW 1.0 TSI MONTE CARLO MT (999cc)",
          "NEW 1.0 TSI ONYX AT (999cc)",
          "NEW 1.0 TSI ONYX MT (999cc)",
          "NEW 1.0 TSI RIDER (999cc)",
          "NEW 1.0 TSI RIDER PLUS AT (999cc)",
          "NEW 1.0 TSI RIDER PLUS MT (999cc)",
          "NEW 1.0 TSI STYLE AT (999cc)",
          "NEW 1.0 TSI STYLE MT (999cc)",
          "1.5 TDI AT AMBITION (1498cc)",
          "1.5 TDI AT STYLE (1498cc)",
          "1.6 MPI AT AMBITION (1598cc)",
          "1.6 MPI AT STYLE (1598cc)",
          "1.5 TDI CR AMB PL (AW) (1498cc)",
          "1.5 TDI CR AMB PL AT (AW) (1498cc)",
          "1.5 TDI CR STYLE PLUS AT B PAC (1498cc)",
          "1.5 TDI CR STYLE PLUS MT B PAC (1498cc)",
          "1.6 MPI AMB PL (AW) (1598cc)",
          "1.6 MPI AMB PL AT (AW) (1598cc)",
          "1.6 MPI STYLE PLUS AT B PAC (1598cc)",
          "1.6 MPI STYLE PLUS MT B PAC (1598cc)",
          "ULTIMA AMB PL 1.6 MPI AT (1598cc)",
          "ULTIMA AMB PL 1.6 MPI MT (1598cc)",
          "ULTIMA AMB PL 1.6 TDI CR MT (1598cc)",
          "ULTIMA ELEGANCE 1.6 MPI AT (1598cc)",
          "ULTIMA ELEGANCE 1.6 MPI MT (1598cc)",
          "ACTIVE PLUS 1.6 TDI CR MT (1598cc)",
          "AMBITION PLUS 1.6 MPI AT (1598cc)",
          "PRESTIGE 1.6 TDI CR MT LTD EDN (1598cc)",
          "ULTIMA ELEGANCE 1.6 TDI CR MT (1598cc)",
          "AMBITION PLUS 1.6 TDI CR MT (1598cc)",
          "ELEGANCE PLUS 1.6 TDI (1598cc)",
          "LEISURE 1.6 TDI CR MT PLUS (1598cc)",
          "ACTIVE PLUS 1.6 MPI MT (1598cc)",
          "AMBITION PLUS 1.6 MPI MT (1598cc)",
          "LEISURE 1.6 MPI AT (1598cc)",
          "ELEGANCE PLUS 1.6 MPI AT (1598cc)",
          "ACTIVE 1.6 MPI MT (1598cc)",
          "AMBITION 1.6 MPI MT (1598cc)",
          "ELEGANCE PLUS 1.6 MPI MT (1598cc)",
          "AMBITION 1.6 MPI AT (1598cc)",
          "ELEGANCE 1.6 MPI AT (1598cc)",
          "ELEGANCE 1.6 MPI MT (1598cc)",
          "LEISURE 1.6 MPI MT (1598cc)",
          "ACTIVE 1.6 TDI CR MT (1598cc)",
          "AMBITION 1.6 TDI CR MT (1598cc)",
          "ELEGANCE 1.6 TDI CR MT (1598cc)",
          "ELEGANCE 1.6 TDI (1598cc)",
          "ACTIVE 1.6 TDI (1598cc)",
          "ELEGANCE 1.6 MPI (1598cc)",
          "AMBITION 1.6 TDI (1598cc)",
          "ACTIVE 1.6 MPI (1598cc)",
          "AMBITION 1.6 MPI (1598cc)",
          "AMBITION PLUS 1.6 TDI (1598cc)",
          "ACTIVE PLUS 1.6 TDI (1598cc)",
          "ACTIVE (1598cc)",
          "AMBITION MT (1598cc)",
          "ELEGANCE AT (1598cc)",
          "ZEAL 1.6 MPI ELEGANCE PLUS (1598cc)",
          "ELEGANCE PLUS AT (1598cc)",
          "LEISURE AT (1598cc)",
          "ELEGANCE MT (1598cc)",
          "AMBITION PLUS AT (1598cc)",
          "AMBITION PLUS MT (1598cc)",
          "AMBITION AT (1598cc)",
          "ELEGANCE PLUS MT (1598cc)",
          "ULTIMA ELEGANCE AT (1598cc)",
          "ULTIMA AMBITION PLUS MT (1598cc)",
          "ULTIMA AMBITION PLUS AT (1598cc)",
          "ULTIMA ELEGANCE MT (1598cc)",
          "LEISURE MT (1598cc)",
          "1.6 AMBITION PLUS MT (1598cc)",
          "1.6 AMBITION MT (1598cc)",
          "1.6 ACTIVE (1598cc)",
          "ACTIVE PLUS (1598cc)",
          "LEISURE (1598cc)",
          "ELEGANCE 1.6 TDI CR (1598cc)",
          "1.6 ELEGANCE MT (1598cc)",
          "PRESTIGE (1598cc)",
          "LEISURE PLUS (1598cc)",
          "ULTIMA ELEGANCE (1598cc)",
          "ULTIMA 1.6 AMBITION PLUS (1598cc)",
          "STYLE 1.6 MPI AT (1598cc)",
          "1.6 ELEGANCE PLUS (1598cc)",
          "1.6 MPI ONYX AT LE (1598cc)",
          "1.6 MPI ONYX MT LE (1598cc)",
          "1.6 MPI STYLE PLUS AT BLACK PACKAGE (1598cc)",
          "1.6 MPI STYLE PLUS MT BLACK PACKAGE (1598cc)",
          "ULTIMA AMBITION PLUS 1.6 MPI MT (1598cc)",
          "ULTIMA AMBITION PLUS 1.6 MPI AT (1598cc)",
          "1.6 L ONYX (1598cc)",
          "1.6 L RIDER (1598cc)",
          "1.6 AMBITION ONYX AT (1598cc)",
          "1.6 EDITION X MPI AT (1598cc)",
          "1.6 EDITION X MPI (1598cc)",
          "1.6 MPI STYLE MT (NON LE) (1598cc)",
          "1.6 MPI STYLE AT LE (1598cc)",
          "ULTIMA AMBITION PLUS 1.6 TDI CR MT (1598cc)",
          "1.6 MPI AMBITION PLUS WITH ALLOY WHEELS (1598cc)",
          "1.6 MPI AMBITION PLUS WITH ALLOY WHEELS AT (1598cc)",
          "ELEGANCE 1.6 MPI MT BLACK PACKAGE (1598cc)",
          "ELEGANCE 1.6 MPI AT BLACK PACKAGE (1598cc)",
          "ELEGANCE PLUS 1.6 MPI MT BLACK PACKAGE (1598cc)",
          "1.6 MPI STYLE MT LE (1598cc)",
          "AMBITION 77KW BSIV (1598cc)",
          "1.6 ULTIMA AMBITION PLUS TDI CR MT (1598cc)",
          "1.6 ULTIMA AMBITION PLUS MPI AT (1598cc)",
          "1.6 ULTIMA AMBITION PLUS MPI MT (1598cc)",
          "1.6 ULTIMA ELEGANCE TDI CR MT (1598cc)",
          "ONYX 1.6 MPI AT LE (1598cc)",
          "EDITION 1.6 MPI (1598cc)",
          "1.6 ULTIMA ELEGANCE MPI AT (1598cc)",
          "ZEAL ELEGANCE PLUS 1.6 MPI (1598cc)",
          "1.6 MPI STYLE PLUS (1598cc)",
          "ACTIVE 1.6 MPI MT PLUS (1598cc)",
          "STYLE 1.6 MPI (1598cc)",
          "1.6 ULTIMA ELEGANCE MPI MT (1598cc)",
          "PRESTIGE 1.6 TDI (1598cc)",
          "1.6 ELEGANCE PLUS BLACK PACKAGE (1598cc)",
          "1.6 ELEGANCE PLUS BLACK PACKAGE AT (1598cc)",
          "1.6 LEISURE TDI CR MT PLUS (1598cc)",
          "1.6 MPI ELEGANCE BLACK PACKAGE (1598cc)",
          "1.6 MPI ELEGANCE BLACK PACKAGE AT (1598cc)",
          "1.6 MPI STYLE PLUS BLACK PACKAGE (1598cc)",
          "1.6 PRESTIGE TDI CR MT PLUS (1598cc)",
          "ACTIVE 1.6 TDI CR MT PLUS (1598cc)",
          "EDITION 1.6 MPI AT (1598cc)",
          "ONYX 1.6 MPI AT (1598cc)",
          "STYLE 1.6 MPI MT PLUS (1598cc)",
          "EDITION X 1.6 MPI MT (1598cc)",
          "STYLE 1.6 MPI AT PLUS (1598cc)",
          "ONYX 1.6 MPI LE (1598cc)",
          "AMBITION 1.6 TDI CR MT PLUS (1598cc)",
          "1.6 MPI STYLE PLUS BLACK PACKAGE AT (1598cc)",
          "AMBITION 1.6 MPI AT PLUS (1598cc)",
          "AMBITION 1.6 MPI MT PLUS (1598cc)",
          "LEISURE 1.6 TDI MT (1598cc)",
          "RIDER LIMITED EDITION (1598cc)",
          "ELEGANCE (1798cc)",
          "ELEGANCE 1.6 TSI MT (1798cc)",
          "1.5 ACTIVE (1498cc)",
          "1.5 ELEGANCE MT (1498cc)",
          "1.5 AMBITION PLUS AT (1498cc)",
          "1.5 AMBITION PLUS MT (1498cc)",
          "1.5 ELEGANCE AT (1498cc)",
          "ZEAL 1.5 TDI ELEGANCE PLUS (1498cc)",
          "1.5 CR AMBITION PLUS AT (1498cc)",
          "ZEAL 1.5 TDI ELEGANCE PLUS AT (1498cc)",
          "1.5 ELEGANCE PLUS AT (1498cc)",
          "1.5 EDITION TDI CR AT (1498cc)",
          "1.5 TDI ONYX AT LE (1498cc)",
          "1.5 TDI ONYX MT LE (1498cc)",
          "1.5 EDITION X TDI (1498cc)",
          "1.5 TDI AT MONTE CARLO (1498cc)",
          "1.5 EDITION X TDI AT (1498cc)",
          "1.5 AMBITION ONYX MT (1498cc)",
          "1.5 AMBITION ONYX AT (1498cc)",
          "1.5 TDI CR STYLE PLUS AT BLACK PACKAGE (1498cc)",
          "1.5 TDI CR STYLE PLUS MT BLACK PACKAGE (1498cc)",
          "1.5 TDI CR STYLE MT (NON LE) (1498cc)",
          "1.5 TDI CR AMBITION PLUS WITH ALLOY WHEELS (1498cc)",
          "1.5 TDI CR AMBITION PLUS AT WITH ALLOY WHEELS AT (1498cc)",
          "1.5 TDI CR ELEGANCE PLUS AT ANNIVERSARY EDITION (1498cc)",
          "1.5 TDI CR ELEGANCE MT BLACK PACKAGE (1498cc)",
          "1.5 TDI CR ELEGANCE AT BLACK PACKAGE (1498cc)",
          "1.5 TDI CR STYLE MT LE (1498cc)",
          "1.5 TDI CR STYLE AT LE (1498cc)",
          "ONYX 1.5 TDI AT LE (1498cc)",
          "EDITION 1.5 TDI AT (1498cc)",
          "EDITION 1.5 TDI (1498cc)",
          "ZEAL ELEGANCE PLUS 1.5 TDI (1498cc)",
          "AMBITION 81KW TDI CR B (1498cc)",
          "1.5 ELEGANCE PLUS BLACK PACKAGE (1498cc)",
          "1.5 TDI CR ELEGANCE BLACK PACKAGE (1498cc)",
          "1.5 TDI CR ELEGANCE BLACK PACKAGE AT (1498cc)",
          "STYLE 1.5 TDI (1498cc)",
          "STYLE 1.5 TDI AT (1498cc)",
          "1.5 TDI CR ELEGANCE PLUS BLACK PACKAGE AT (1498cc)",
          "1.5 TDI CR ELEGANCE PLUS (1498cc)",
          "MONTE CARLO 1.5 TDI CR AT (1498cc)",
          "ONYX 1.5 TDI AT (1498cc)",
          "1.5 TDI CR AMBITION WITH ALLOY WHEELS (1498cc)",
          "1.5 TDI CR ELEGANCE BLACK (1498cc)",
          "1.5 TDI CR ELEGANCE BLACK AT (1498cc)",
          "1.5 TDI CR AMBITION AT WITH ALLOY WHEELS (1498cc)",
          "EDITION X 1.5 TDI DSG AT (1498cc)",
          "MONTE CARLO 1.5 TDI CR MT (1498cc)",
          "1.5 TDI CR AMBITION MT (1498cc)",
          "ONYX 1.5 TDI LE (1498cc)",
          "1.5 TDI CR STYLE PLUS (1498cc)",
          "1.5 TDI CR ACTIVE (1498cc)",
          "1.5 TDI CR ELEGANCE (1498cc)",
          "1.5 TDI CR AMBITION PLUS (1498cc)",
          "1.5 TDI CR STYLE PLUS BLACK PACKAGE (1498cc)",
          "1.5 TDI CR STYLE PLUS BLACK PACKAGE AT (1498cc)",
          "ZEAL ELEGANCE 2.0 TDI CR AT (1984cc)",
          "1.0 TSI RIDER PLUS BS6 (999cc)",
          "ACTIVE 1.0 TSI MT BS6 (999cc)",
          "ACTIVE 1.0 TSI AT BS6 (999cc)",
          "MATTE FINISH 1.0 TSI MT (999cc)",
          "MATTE FINISH 1.0 TSI AT (999cc)",
          "1.0 TSI AMBITION BS6 (999cc)",
          "1.0 TSI MONTE CARLO BS6 (999cc)",
          "1.0 TSI ONYX BS6 (999cc)",
          "1.0 TSI RIDER BS6 (999cc)",
          "1.0 TSI STYLE BS6 (999cc)",
          "1.0 MONTE CARLO AT (999cc)",
          "1.0 TSI STYLE MT 81 KW (999cc)",
          "1.0 TSI ONYX MT 81 KW (999cc)",
          "1.0 TSI AMBITION MT 81 KW (999cc)",
          "1.0 TSI RIDER PLUS MT 81 KW (999cc)",
          "1.0 TSI MONTE CARLO MT 81 KW (999cc)",
          "1.0 TSI RIDER MT 81 KW (999cc)",
          "1.0 TSI PETROL MATTE EDITION AT (999cc)",
          "1.0 TSI PETROL MATTE EDITION (999cc)",
          "1.0 TSI RIDER MT (999cc)",
          "ACTIVE 1.0 TSI MT BSVI (999cc)",
          "1.0 TSI RIDER (999cc)",
          "1.0 TSI ACTIVE MT (999cc)",
          "1.0 TSI MONTE CARLO (999cc)",
          "1.0 TSI ONYX (999cc)",
          "1.0 TSI AMBITION (999cc)",
          "1.0 TSI MONTE CARLO AT (999cc)",
          "1.0 TSI STYLE (999cc)",
          "1.0 TSI RIDER PLUS (999cc)",
          "1.0 TSI MATTE LIMITED EDITION (999cc)",
          "1.0 TSI MATTE LIMITED EDITION AT (999cc)",
          "1.0 TSI STYLE AT (999cc)",
          "1.0 TSI ONYX AT (999cc)",
          "1.0 TSI AMBITION AT (999cc)",
          "1.0 TSI RIDER PLUS AT (999cc)",
        ],
      },
      {
        model: "SUPERB",
        variants: [
          "1.8 TSI L&K (AT) (1798cc)",
          "1.8 TSI STYLE (AT) (1798cc)",
          "1.8 TSI AMBITION (MT) (1798cc)",
          "1.8 TSI ELEGANCE (AT) (1798cc)",
          "1.8 TSI ELEGANCE (MT) (1798cc)",
          "2.0 TDI CR AMBITION (AT) (1968cc)",
          "2.0 TDI ELEGANCE PD (1968cc)",
          "2.0 TDI L&K (AT) (1968cc)",
          "1.8 TSI STYLE (MT) (1798cc)",
          "2.0 TDI ELEGANCE(AT) (1968cc)",
          "1.8 TSI SPORTLINE (AT) (1798cc)",
          "2.0 TDI SPORTLINE (AT) (1968cc)",
          "2.0 TSI SPORTLINE (AT) BSVI (1984cc)",
          "2.0 TSI L&K (AT) BSVI (1984cc)",
          "2.0 ELEGANCE TDI (1978cc)",
          "3.6 V6 FSI ELEGANCE (3597cc)",
          "3.6 V6 FSI (3597cc)",
          "2.0 TDI STYLE AT (1968cc)",
          "2.0 TDI CR ELEGANCE (1968cc)",
          "2.5 TDI ELEGANCE (2496cc)",
          "2.8 V6 AT (2771cc)",
          "2.5 TDI AT (2496cc)",
          "2.5 TDI COMFORT AT (2496cc)",
          "2.0 TDI PD (1968cc)",
          "2.0 TDI PD (1968cc)",
          "1.8 TSI AT (1798cc)",
          "1.8 TSI MT (1798cc)",
          "2.0 TDI CR (1968cc)",
          "STYLE 1.8 TSI MT (1798cc)",
          "1.8 TSI AMBTION MT (1798cc)",
          "1.8 TSI ELEGANCE AT T (1798cc)",
          "2.0 L&K TSI AT (1984cc)",
          "2.0 SPORTLINE TSI AT (1984cc)",
          "1.8 TSI MANUAL TRANSMISSION (1798cc)",
          "2.8 V6 AUTO TRANS (2771cc)",
          "1.8 TSI AUTOMATIC TRANSMISSION (1798cc)",
          "2.0 TDI CR AMBTION AT (1968cc)",
          "2.5 TDI COMFORT AUTO TRANS (2496cc)",
          "2.5 TDI AUTO TRANS (2496cc)",
          "ELEGANCE 2.0 TDI PD (1968cc)",
          "STYLE 1.8 TSI AT (1798cc)",
          "L&K 1.8 TSI AT (1798cc)",
          "L&K 2.0 TDI AT (1968cc)",
          "STYLE 2.0 TDI AT (1968cc)",
          "2.8 V6 (2771cc)",
          "2.8 V6 (2771cc)",
          "ELEGANCE 2.8 PETROL (2771cc)",
          "V6 AT (2771cc)",
          "1.8 TSI ELEGANCE (1798cc)",
          "1.8 TSI (1798cc)",
          "AMBITION 1.8 TSI (1798cc)",
          "ELEGANCE MT (1798cc)",
          "AT (1798cc)",
          "AMBITION MT (1798cc)",
          "ELEGANCE AT (1798cc)",
          "MT (1798cc)",
          "AMBITION 1.8 TSI AT (1798cc)",
          "1.8 STYLE TSI MT (1798cc)",
          "SUPERB ELEGANCE 1.8 TSI AT (1798cc)",
          "1.8 TSI STYLE AUTOMATIC TRANSMISSION (1798cc)",
          "1.8 TSI AT SPORTLINE (1798cc)",
          "STYLE 1.8 TSI CORPORATE EDITION (1798cc)",
          "1.8 TSI L&K AUTOMATIC TRANSMISSION (1798cc)",
          "1.8 TSI STYLE MANUAL TRANSMISSION (1798cc)",
          "1.8 TSI AMBTION MANUAL TRANSMISSION (1798cc)",
          "1.8 TSI ELEGANCE MANUAL TRANSMISSION (1798cc)",
          "1.8 TSI ELEGANCE AUTOMATIC TRANSMISSION (1798cc)",
          "STYLE TSI MT (1798cc)",
          "SPORTLINE TSI AT (1798cc)",
          "L&K TSI AT (1798cc)",
          "STYLE TSI AT (1798cc)",
          "CORPORATE EDITION (1798cc)",
          "2009 1.8 TSI (1798cc)",
          "STYLE 1.8 TSI (1798cc)",
          "CORPORATE EDITION PETROL (1798cc)",
          "SPORTLINE 1.8 TSI AT (1798cc)",
          "ELEGANCE 1.8 TSI AT (1798cc)",
          "AMBITION 1.8 TSI MT (1798cc)",
          "ELEGANCE 1.8 TSI MT (1798cc)",
          "ELEGANCE 2.0 (1968cc)",
          "AMBITION 2.0 TDI (1968cc)",
          "2.0 TDI CR ELEGANCE AT (1968cc)",
          "2.0 ELEGANCE (1968cc)",
          "2.0 TDI (1968cc)",
          "2.0 AMBTION AT (1968cc)",
          "AMBITION (1968cc)",
          "L&K AT (1968cc)",
          "2.0 TDI CR SPORTLINE (1968cc)",
          "2.0 TDI L&K AUTOMATIC TRANSMISSION (1968cc)",
          "2.0 TDI ELEGANCE AUTOMATIC (1968cc)",
          "SPORTLINE TDI AT (1968cc)",
          "L&K TDI AT (1968cc)",
          "AMBITION 2.0 TDI CR AT (1968cc)",
          "STYLE TDI AT (1968cc)",
          "2009 TDI PD (1968cc)",
          "AMBITION 2.0 TDI AT (1968cc)",
          "ELEGANCE 2.0 TDI CR (1968cc)",
          "SPORTLINE 2.0 TDI AT (1968cc)",
          "ELEGANCE 2.0 TDI CR AT (1968cc)",
          "ELEGANCE 2.5 E-III (2496cc)",
          "2.5 TDI (2496cc)",
          "2.5 TDI (2496cc)",
          "2.5 AT (2496cc)",
          "COMFORT 2.5 V6 TDI (2496cc)",
          "2.5 ELEGANCE (2496cc)",
          "2.5 COMFORT AT (2496cc)",
          "2.5 ELEGANCE V6 (2496cc)",
          "ELEGANCE 2.5 V6 (2496cc)",
          "2.5 TDI COMFORT (2496cc)",
          "2.5 V6 TDI (2496cc)",
          "3.6 V6 (3597cc)",
          "V6 (3597cc)",
          "V6 ELEGANCE (3597cc)",
          "2009 3.6 FSI 4X4 (3597cc)",
          "3.6 ELEGANCE AT (3597cc)",
          "3.6 FSI 4X4 (3597cc)",
          "L&K 2.0 TSI AT BS6 (1984cc)",
          "SPORTLINE 2.0 TSI AT BS6 (1984cc)",
          "2.0 TSI L&K AT 140 KW (1984cc)",
          "2.0 TSI SPORTLINE AT 140 KW (1984cc)",
          "2.0 TFSI L&K 140 (1984cc)",
          "SPORTLINE TSI AT (1984cc)",
          "L&K TSI AT (1984cc)",
          "SPORTLINE 2.0 AT (1984cc)",
          "L&K 2.0 AT (1984cc)",
          "ELEGANCE 1.8 TSI AT (1800 CC) (1800cc)",
          "2.8 PETROL (2776cc)",
        ],
      },
      {
        model: "YETI",
        variants: [
          "2.0 TDI ACTIVE CR (4X2)(MT) (1968cc)",
          "2.0 TDI AMBIENTE CR 4X4 (1968cc)",
          "2.0 TDI AMBIENTE CR (4X2)(MT) (1968cc)",
          "2.0 TDI AMBITION CR (4X2) (MT) (1968cc)",
          "2.0 TDI AMBITION CR (4X4)(MT) (1968cc)",
          "2.0 TDI ELEGANCE CR 4X4 (1968cc)",
          "2.0 AMBITION TDI CR MT (4X2) (1968cc)",
          "2.0 AMBITION TDI CR MT (4X4) (1968cc)",
          "2.0 AMBIENTE 4X4 TDI CR (103KW) (1968cc)",
          "2.0 ELEGANCE 4X4 TDI CR (103KW) (1968cc)",
          "2.0 ACTIVE TDI CR MT (4X2) (1968cc)",
          "2.0 AMBIENTE TDI CR MT (4X2) (1968cc)",
          "ACTIVE (1968cc)",
          "ACTIVE (1968cc)",
          "AMBITION (1968cc)",
          "AMBITION (1968cc)",
          "YETI AMBIENTE (1968cc)",
          "ACTIVE 2. 0 TDI CR MT 4X4 (1968cc)",
          "2.0 STYLE TDI CRDI (4X2) (1968cc)",
          "2.0 STYLE TDI CRDI (4X4) (1968cc)",
          "2.0 ELEGANCE 4X2 TDI CR 81 KW (1968cc)",
          "ZEAL ELEGANCE 4X2 (1968cc)",
          "2.0 TDI (1968cc)",
          "ELEGANCE (1968cc)",
          "ELEGANCE (1968cc)",
          "ELEGANCE 4 X 4 (1968cc)",
          "AMBITION 2.0 TDI CR 4X4 (1968cc)",
          "ACTIVE 2.0 TDI CR 4X2 (1968cc)",
          "AMBITION 2.0 TDI CR 4X2 (1968cc)",
          "ELEGANCE 2.0 TDI CR 4X4 (1968cc)",
          "ELEGANCE 4X2 (1968cc)",
          "STYLE 4X2 (1968cc)",
          "STYLE 4X4 (1968cc)",
          "AMBIENTE (1968cc)",
          "AMBIENTE (1968cc)",
          "ZEAL ELEGANCE 4X2 (1984cc)",
        ],
      },
      {
        model: "KODIAQ",
        variants: [
          "2.0 TDI STYLE (4X4) (AT) (1968cc)",
          "2.0 TDI L AND K (4X4) (AT) (1968cc)",
          "2.0 TDI STYLE CORPORATE EDTN (1968cc)",
          "2.0 TDI SCOUT (AT) (1968cc)",
          "2.0 TSI L&K AT (1984cc)",
          "2.0 TSI STYLE AT (1984cc)",
          "2.0 TSI SPORT LINE AT (1984cc)",
          "TDI STYLE (1968cc)",
          "2.0 TDI L&K AT (4X4) 110 KW 5 SEATER (1968cc)",
          "2.0 TDI SCOUT AT (4X4) 110 KW 7 SEATER (1968cc)",
          "2.0 TDI STYLE AT (4X4) 110 KW 7 SEATER (1968cc)",
          "2.0 TDI STYLE AT (4X4) 110 KW (1968cc)",
          "2.0 TDI L&K AT (4X4) 110 KW (1968cc)",
          "CORPORATE EDITION (1968cc)",
          "STYLE AT 110KW TDI CR (1968cc)",
          "L&K TDI 4X4 AT (1968cc)",
          "SCOUT (1968cc)",
          "L&K TDI AT (1968cc)",
          "SCOUT 2.0 TDI AT (1968cc)",
          "2.0 L&K TDI AT 4X4 (1968cc)",
          "STYLE TDI 4X4 AT (1968cc)",
          "L&K 2.0 TSI AT 7 STR (1984cc)",
          "2.0 TSI STYLE AT 7 SEATER (1984cc)",
          "2.O TSI L&K AT 7 SEATER (1984cc)",
          "2.0 TSI SPORT LINE AT 7 SEATER (1984cc)",
          "2.O TSI L&K (4X4) 140 KW (1984cc)",
          "SPORTLINE AT (140 KW TSI4 X 4) (1984cc)",
          "L&K 2.0 MT TSI (1984cc)",
          "SPORTSLINE 2.0 TSI AT (1984cc)",
          "STYLE TSI AT (1984cc)",
          "L&K 2.0 TSI AT (1984cc)",
          "L&K (1984cc)",
          "SPORTLINE (1984cc)",
          "STYLE (1984cc)",
          "SPORTLINE AT PETROL (1984cc)",
          "L&K AT PETROL (1984cc)",
          "STYLE AT PETROL (1984cc)",
        ],
      },
      {
        model: "LAURA",
        variants: [
          "1.8 TSI RS (MT) (1798cc)",
          "1.9 5SPEED (MT) (1896cc)",
          "1.8 TSI ACTIVE (MT) (1798cc)",
          "1.9 PD AMBIENTE (1896cc)",
          "2.0 TDI CR AMBIENTE (AT) (1968cc)",
          "2.0 TDI CR AMBIENTE (MT) (1968cc)",
          "1.9 TDI AMBIENTE PD (AT) (1896cc)",
          "1.8 TSI AMBITION (1798cc)",
          "1.8 TSI AMBITION (AT) (1798cc)",
          "2.0 TDI CR AMBITION (AT) (1968cc)",
          "2.0 TDI CR AMBITION (MT) (1968cc)",
          "1.8 TSI CLASSIC (MT) (1798cc)",
          "DSG 6SPEED (AT) (1896cc)",
          "1.9 PD ELEGANCE (1896cc)",
          "2.0 TDI CR ELEGANCE (AT) (1968cc)",
          "2.0 TDI CR ELEGANCE (MT) (1968cc)",
          "1.9 TDI ELEGANCE PD (AT) (1896cc)",
          "2.0 TDI CR L&K (AT) (1968cc)",
          "1.9 L&K PD (1896cc)",
          "1.9 PD L&K (AT) (1896cc)",
          "2.0 TDI L&K (AT) (1968cc)",
          "1.9 TDI (1896cc)",
          "1.8 TSI AMBIENTE (1798cc)",
          "1.9 MT 5 SPEED (1896cc)",
          "ELEGANCE AT 1.9 TDI PD 77 KW (1896cc)",
          "AMBIENTE AT 1.9 TDI PD 77 KW (1896cc)",
          "AMBITION 1.8 TSI AT 118 KW (1798cc)",
          "AMBIENTE 1.8 TSI 118 KW (1798cc)",
          "ACTIVE 1.8 TSI MT  118 KW (1798cc)",
          "RS 1.8 TSI MT 118 KW (1798cc)",
          "CLASSIC 1.8 TSI MT (1798cc)",
          "AMBITION 1.8 TSI 118 KW (1798cc)",
          "ELEGANCE 2.0 TDI CR MT 103 KW (1968cc)",
          "AMBITION 2.0 TDI CR MT 81 KW (1968cc)",
          "AMBITION 2.0 TDI CR AT 103 KW (1968cc)",
          "AMBIENTE 2.0 TDI CR MT 81 KW (1968cc)",
          "AMBIENTE 2.0 TDI CR AT 103 KW (1968cc)",
          "ELEGANCE 2.0 TDI CR MT 81 KW (1968cc)",
          "ELEGANCE 2.0 TDI CR AT 103 KW (1968cc)",
          "L AND K 2.0 TDI CR AT 103 KW (1968cc)",
          "L&K 2.0 TDI AUTO TRANS (1968cc)",
          "DSG AUTO TRANS 6 SPEED (1896cc)",
          "L&K 1.9 PD (1896cc)",
          "L&K 1.9 PD AUTO TRANS (1896cc)",
          "ELEGANCE 1.9 PD (1896cc)",
          "ELEGANCE 1.9 PD (1896cc)",
          "AMBIENTE 1.8 TSI (1798cc)",
          "AMBIENTE 1.9 PD (1896cc)",
          "1.9 AMBIENTE (1896cc)",
          "1.9 AMBIENTE (1896cc)",
          "1.9 ELEGANCE TDI AT (1896cc)",
          "1.9 ELEGANCE TDI MT (1896cc)",
          "1.9 PD (1896cc)",
          "L AND K (1896cc)",
          "1.9 ELEGANCE (1896cc)",
          "1.9 ELEGANCE AT (1896cc)",
          "1.9 MT (1896cc)",
          "1.9 L&K (1896cc)",
          "1.9 AMBIENTE AT (1896cc)",
          "L N K 1.9 PD AT (1896cc)",
          "L N K 1.9 PD (1896cc)",
          "1.8 TSI BS III (1896cc)",
          "ELEGANCE 1.9 PD 4 SEAT (1896cc)",
          "AMBIENTE AUTO TRANS 1.9 TDI PD 77 KW (1896cc)",
          "ELEGANCE AUTO TRANS 1.9 TDI PD 77 KW (1896cc)",
          "1.9 MANUAL TRANSMISSION 5 SPEED (1896cc)",
          "ELEGANCE 1.9 TDI AT (1896cc)",
          "AMBIENTE 1.9 TDI AT (1896cc)",
          "L&K 1.9 PD AT (1896cc)",
          "AMBIENTE 1.9 TDI MT (1896cc)",
          "ELEGANCE 1.9 TDI MT (1896cc)",
          "L&K 1.9 PD MT (1896cc)",
          "1.9 TDI AT (1896cc)",
          "L AND K 1.9 PD AT (1896cc)",
          "1.9 TDI MT ELEGANCE (1896cc)",
          "1.9 TDI MT AMBIENTE (1896cc)",
          "1.9 TDI MT (1896cc)",
          "1.9 TDI AT AMBIENTE (1896cc)",
          "1.9 TDI AT ELEGANCE (1896cc)",
          "L AND K 1.9 PD (1896cc)",
          "L&K 1.9 MT (1896cc)",
          "1.9TDI PD (1896cc)",
          "2.0 AMBIENTE (1968cc)",
          "2.0 AMBIENTE (1968cc)",
          "2.0 ELEGANCE (1968cc)",
          "2.0 ELEGANCE (1968cc)",
          "L AND K AT (1968cc)",
          "L AND K MT (1968cc)",
          "AMBIENTE 2.0 AT (1968cc)",
          "2.0 TDI (1968cc)",
          "2.0 ELEGANCE AT (1968cc)",
          "2.0 TDI AMBITION AT (1968cc)",
          "2.0 AMBITION (1968cc)",
          "2.0 AMBIENTE AT (1968cc)",
          "2.0 L&K (1968cc)",
          "L&K 2.0 TDI AT (1968cc)",
          "2.0 TDI AT L AND K (1968cc)",
          "L&K 2.0 TDI CR AT (1968cc)",
          "AMBITION 2.0 TDI CR AT (1968cc)",
          "AMBITION 2.0 TDI CR MT (1968cc)",
          "AMBIENTE 2.0 TDI CR AT (1968cc)",
          "ELEGANCE 2.0 TDI CR AT (1968cc)",
          "AMBIENTE 2.0 TDI CR MT (1968cc)",
          "ELEGANCE 2.0 TDI CR MT (1968cc)",
          "RS 1.8 (1798cc)",
          "1.8 AMBIENTE (1798cc)",
          "AMBITION (1798cc)",
          "AMBITION (1798cc)",
          "1.8 RALLY SPORTS TSI (1798cc)",
          "ACTIVE (1798cc)",
          "TSI AMBITION AT (1798cc)",
          "CLASSIC (1798cc)",
          "AMBIENTE (1798cc)",
          "AMBITION 1.8 TSI AT (1798cc)",
          "AMBITION 1.8 TSI (1798cc)",
          "LAURA RS (1798cc)",
          "ACTIVE 1.8 TSI (1798cc)",
          "ACTIVE 1.8 TSI (1798cc)",
          "CLASSIC 1.8 TSI (1798cc)",
          "CLASSIC 1.8 TSI (1798cc)",
          "RS (1798cc)",
          "RS (2446cc)",
        ],
      },
      {
        model: "SLAVIA",
        variants: [
          "1.0 ACTIVE TSI MT BSVI (999cc)",
          "1.0 AMBITION TSI MT BSVI (999cc)",
          "1.0 AMBITION TSI AT BSVI (999cc)",
          "1.0 STYLE TSI MT BSVI (999cc)",
          "1.0 STYLE TSI MT (SRF) BSVI (999cc)",
          "1.0 STYLE TSI AT (SRF) BSVI (999cc)",
          "1.5 STYLE TSI AT BSVI (1498cc)",
          "1.5 STYLE TSI MT BSVI (1498cc)",
          "1.0 MONTE CARLO TSI AT BSVI (999cc)",
          "1.5 TSI AMBITION MT BSVI (1498cc)",
          "1.5 TSI AMBITION AT BSVI (1498cc)",
          "1.5 TSI AMBITION AT DT BSVI (1498cc)",
          "1.0 STYLE TSI MT NSR LAVA BSVI (999cc)",
          "1.0 STYLE TSI MT LAVA BSVI (999cc)",
          "1.5 STYLE TSI MT LAVA BSVI (1498cc)",
          "1.5 STYLE TSI MT OB AE BSVI (1498cc)",
          "1.0 STYLE TSI AT LAVA BSVI (999cc)",
          "1.5 STYLE TSI AT LAVA BSVI (1498cc)",
          "1.5 STYLE TSI AT OB AE BSVI (1498cc)",
          "1.5L TSI AT STYLE BS6 (1498cc)",
          "STYLE 1.5 TSI DSG DUAL TONE (1498cc)",
          "1.5 AMBITION TSI MT (1498cc)",
          "1.5 STYLE TSI DSG DUAL TONE (1498cc)",
          "1.5 AMBITION TSI DSG DUAL TONE (1498cc)",
          "1.5 AMBITION TSI AT (1498cc)",
          "1.5 AMBITION TSI DSG (1498cc)",
          "1.5 TSI ANNIVERSARY EDITION AT (1498cc)",
          "1.5 TSI ANNIVERSARY EDITION DSG DUAL TONE (1498cc)",
          "AMBITION 1.5 TSI MT (1498cc)",
          "STYLE 1.5L TSI DSG (1498cc)",
          "STYLE 1.5L TSI DSG DUAL TONE (1498cc)",
          "STYLE 1.5L TSI MT (1498cc)",
          "STYLE 1.5 TSI AT (1498cc)",
          "STYLE 1.5 TSI MT (1498cc)",
          "AMBITION 1.5L TSI MT (1498cc)",
          "AMBITION 1.5L TSI DSG DUAL TONE (1498cc)",
          "ANNIVERSARY EDITION 1.5L TSI MT (1498cc)",
          "ANNIVERSARY EDITION 1.5L TSI DSG (1498cc)",
          "AMBITION 1.5L TSI DSG (1498cc)",
          "1.5 TSI STYLE DSG AT (1498cc)",
          "1.5 TSI STYLE MT (1498cc)",
          "STYLE 1.0 TSI MT (999cc)",
          "STYLE 1.0 TSI AT (999cc)",
          "STYLE 1.0 TSI AT DUAL TONE (999cc)",
          "1.0 STYLE TSI AT (999cc)",
          "1.0 STYLE TSI MT NON SUNROOF (999cc)",
          "ACTIVE 1.0L TSI MT (999cc)",
          "AMBITION 1.0L TSI MT (999cc)",
          "AMBITION 1.0L TSI AT (999cc)",
          "STYLE 1.0L TSI MT (999cc)",
          "STYLE 1.0L TSI AT (999cc)",
          "STYLE 1.0L TSI AT DUAL TONE (999cc)",
          "STYLE 1.0L TSI MT (NON-SUNROOF) (999cc)",
          "1.0 TSI AMBITION AT (999cc)",
          "1.0 TSI STYLE MT ( NON SUNROOF ) (999cc)",
          "1.0 TSI STYLE MT (999cc)",
          "1.0 TSI ACTIVE MT (999cc)",
          "1.0 TSI STYLE AT (999cc)",
          "1.0 TSI AMBITION MT (999cc)",
          "1.0 MONTE CARLO TSI AT (999cc)",
          "1.0 STYLE TSI MT (999cc)",
          "1.5 STYLE TSI AT (1498cc)",
          "1.5 STYLE TSI MT (1498cc)",
          "1.0 ACTIVE TSI MT (999cc)",
          "1.0 AMBITION TSI MT (999cc)",
          "1.0 AMBITION TSI AT (999cc)",
          "1.0 STYLE TSI MT SUNROOF (999cc)",
          "1.0 STYLE TSI AT SUNROOF (999cc)",
        ],
      },
      {
        model: "KUSHAQ",
        variants: [
          "1.0 TSI ACTIVE MT BSVI (999cc)",
          "1.0 TSI AMBITION MT BSVI (999cc)",
          "1.0 TSI STYLE MT BSVI (999cc)",
          "1.0 TSI AMBITION AT BSVI (999cc)",
          "1.0 TSI STYLE AT BSVI (999cc)",
          "1.5 TSI STYLE MT BSVI (1498cc)",
          "1.5 TSI STYLE AT BSVI (1498cc)",
          "1.0 TSI ACTIVE PEACE MT BSVI (999cc)",
          "1.5 TSI STYLE AT (6 AGS) BSVI (1498cc)",
          "1.0 TSI AMBITION CLASSIC MT (999cc)",
          "1.0 TSI MONTE CARLO MT BSVI (999cc)",
          "1.0 TSI MONTE CARLO AT BSVI (999cc)",
          "1.5 TSI MONTE CARLO MT BSVI (1498cc)",
          "1.5 TSI MONTE CARLO AT BSVI (1498cc)",
          "1.5 TSI AMBITION MT BSVI (1498cc)",
          "1.5 TSI AMBITION AT BSVI (1498cc)",
          "1.5 TSI AMBITION AT DT BSVI (1498cc)",
          "1.0 TSI ONYX EDITION MT BSVI (999cc)",
          "1.0 TSI STYLE AT MATTE BSVI (999cc)",
          "1.5 TSI STYLE AT MATTE BSVI (1498cc)",
          "1.5 TSI STYLE AT OBE LAVA BSVI (1498cc)",
          "1.0 TSI STYLE MT MATTE BSVI (999cc)",
          "1.5 TSI STYLE MT MATTE BSVI (1498cc)",
          "1.5 TSI STYLE MT OBE LAVA BSVI (1498cc)",
          "1.0 TSI AMBITION CLASSIC AT (999cc)",
          "AMBITION 1.0 TSI MT (999cc)",
          "STYLE 1.0 TSI MT (999cc)",
          "AMBITION 1.0 TSI AT (999cc)",
          "STYLE 1.0 TSI AT (999cc)",
          "STYLE 1.0 TSI AT 6 AIRBAG (999cc)",
          "ACTIVE 1.0 TSI MT PEACE (999cc)",
          "AMBITION 1.0 TSI CLASSIC MT (999cc)",
          "AMBITION 1.0 TSI CLASSIC AT (999cc)",
          "ANNIVERSARY EDITION STYLE 1.0 TSI AT (999cc)",
          "EDITION STYLE 1.5 TSI AT (999cc)",
          "EDITION STYLE 1.0 TSI MT (999cc)",
          "STYLE ANNIVERSARY 1.0 TSI MT (999cc)",
          "STYLE (2 AB) 1.0 TSI AT (999cc)",
          "1.0 TSI ONYX (999cc)",
          "STYLE 1.0 TSI AT DUAL TONE (999cc)",
          "1.0 TSI AMBITION AT 85KW (999cc)",
          "1.0 TSI STYLE AT 85KW (999cc)",
          "1.0 TSI STYLE 85KW (999cc)",
          "1.0 TSI AMBITION 85KW (999cc)",
          "1.0 TSI ACTIVE 85KW (999cc)",
          "1.0 TSI STYLE AT 6 AIRBAGS (999cc)",
          "1.0 TSI STYLE AT ANNIVERSARY EDITION (999cc)",
          "1.0 TSI AMBITION CLASSIC (999cc)",
          "1.0 TSI STYLE  ANNIVERSARY EDITION (999cc)",
          "1.0 TSI ACTIVE 85KW ONYX EDITION (999cc)",
          "1.0 TSI STYLE AT 85KW DUAL TONE (999cc)",
          "1.0 TSI STYLE 85KW NON SUNROOF (999cc)",
          "1.0 TSI ONYX EDITION (999cc)",
          "AMBITION CLASSIC 1.0 TSI MT (999cc)",
          "AMBITION CLASSIC 1.0L TSI AT (999cc)",
          "MONTE CARLO 1.0L TSI MT (999cc)",
          "STYLE 1.0L TSI AT 6 AIRBAGS (999cc)",
          "AMBITION 1.0L TSI MT (999cc)",
          "AMBITION 1.0L TSI AT (999cc)",
          "STYLE 1.0L TSI MT (999cc)",
          "STYLE 1.0L TSI AT (999cc)",
          "AMBITION CLASSIC 1.0 TSI AT (999cc)",
          "ACTIVE PLUS ONYX MT (999cc)",
          "AMBITION CLASSIC 1.0L TSI MT (999cc)",
          "MONTE CARLO 1.0L TSI AT (999cc)",
          "ONYX EDITION 1.0 TSI (999cc)",
          "STYLE 1.0L TSI AT ANNIVERSARY (999cc)",
          "STYLE 1.0L TSI AT DUAL TONE (999cc)",
          "STYLE 1.0L TSI MT ANNIVERSARY (999cc)",
          "ACTIVE 1.0 TSI MT (999cc)",
          "STYLE 1.0L TSI MT (NON-SUNROOF) (999cc)",
          "STYLE 1.0L TSI MT NON-SUNROOF (999cc)",
          "MATTE EDITION 1.0L TSI AT (999cc)",
          "MATTE EDITION 1.0L TSI MT (999cc)",
          "MONTE CARLO 1.0 TSI AT (999cc)",
          "MONTE CARLO 1.0 TSI MT (999cc)",
          "1.0 STYLE TSI AT (999cc)",
          "1.0 ACTIVE TSI MT (999cc)",
          "1.0 STYLE TSI MT (999cc)",
          "1.0 AMBITION TSI MT (999cc)",
          "1.0 AMBITION TSI AT (999cc)",
          "1.0 STYLE TSI AT ANNIVERSARY (999cc)",
          "1.0 STYLE TSI MT ANNIVERSARY (999cc)",
          "1.0 ACTIVE PEACE TSI MT (999cc)",
          "1.0 MONTE KARLO TSI AT (999cc)",
          "1.0 AMBITION CLASSIC TSI MT (999cc)",
          "1.0 MONTE KARLO TSI MT (999cc)",
          "1.0 AMBITION CLASSIC TSI AT (999cc)",
          "STYLE 1.5 TSI MT (1498cc)",
          "STYLE 1.5 TSI AT (1498cc)",
          "STYLE 1.5 TSI DSG 6 AIRBAG (1498cc)",
          "STYLE ANNIVERSARY 1.5 TSI MT (1498cc)",
          "STYLE (2 AB) 1.5 TSI AT (1498cc)",
          "STYLE ANNIVERSARY 1.5 TSI AT (1498cc)",
          "STYLE 1.5 TSI DSG DUAL TONE (1498cc)",
          "1.5 TSI STYLE AT (1498 CC) (1498cc)",
          "1.5 TSI STYLE ANNIVERSARY EDITION (1498cc)",
          "1.5 TSI STYLE AT ANNIVERSARY EDITION (1498cc)",
          "1.5 TSI STYLE DSG (1498cc)",
          "MONTE CARLO 1.5 TSI DSG (CC 1498) (1498cc)",
          "MONTE CARLO 1.5 TSI MT (CC1498) (1498cc)",
          "1.5 TSI AMBITION (1498cc)",
          "1.5 TSI STYLE (1498cc)",
          "1.5 TSI STYLE AT (1498 CC) DUAL TONE (1498cc)",
          "1.5 TSI AMBITION DSG 110KW DUAL TONE (1498cc)",
          "1.5 TSI AMBITION DSG 110KW (1498cc)",
          "1.5 TSI STYLE DSG DUAL TONE (1498cc)",
          "MONTE CARLO 1.5L TSI DSG (1498cc)",
          "STYLE 1.5L TSI DSG 6 AIRBAGS (1498cc)",
          "STYLE 1.5L TSI MT (1498cc)",
          "STYLE 1.5L TSI AT (1498cc)",
          "AMBITION 1.5 MT (1498cc)",
          "AMBITION 1.5 AT (1498cc)",
          "STYLE LAVA BLUE EDITION MT (1498cc)",
          "STYLE LAVA BLUE EDITION AT (1498cc)",
          "AMBITION DUAL TONE AT (1498cc)",
          "STYLE DUAL TONE AT (1498cc)",
          "STYLE  DUAL TONE (1498cc)",
          "Ã‚Â LAVA BLUE EDITION 1.5L TSI  DSG (1498cc)",
          "Ã‚Â LAVA BLUE EDITION 1.5L TSI  MT (1498cc)",
          "MONTE CARLO 1.5L TSI MT (1498cc)",
          "STYLE 1.5L TSI DSG ANNIVERSARY (1498cc)",
          "STYLE 1.5L TSI DSG DUAL TONE (1498cc)",
          "STYLE 1.5L TSI MT ANNIVERSARY (1498cc)",
          "STYLE 1.5L TSI DSG (1498cc)",
          "AMBITION 1.5L TSI MT (1498cc)",
          "AMBITION 1.5L TSI DSG (1498cc)",
          "AMBITION 1.5L TSI DSG DUAL TONE (1498cc)",
          "MATTE EDITION 1.5L TSI DSG (1498cc)",
          "MATTE EDITION 1.5L TSI MT (1498cc)",
          "MONTE CARLO 1.5 TSI AT (1498cc)",
          "MONTE CARLO 1.5 TSI MT (1498cc)",
          "1.5 STYLE TSI DSG AT (1498cc)",
          "1.5 STYLE TSI MT (1498cc)",
          "1.5 STYLE TSI MT ANNIVERSARY (1498cc)",
          "1.5 STYLE TSI DSG AT ANNIVERSARY (1498cc)",
          "1.5 MONTE KARLO TSI DSG AT (1498cc)",
          "1.5 MONTE KARLO TSI MT (1498cc)",
          "1.5 TSI STYLE 110 KW (1495cc)",
          "1.5 TSI STYLE AT 110 KW (1495cc)",
          "1.5 TSI STYLE AT 6 AIRBAGS (1495cc)",
          "MONTE CARLO 1.5 TSI AT (1495cc)",
          "MONTE CARLO 1.5 TSI MT (1495cc)",
        ],
      },
      {
        model: "KAROQ",
        variants: [
          "1.5 TSI STYLE BSVI (1498cc)",
          "STYLE AT BS6 (1498cc)",
          "1.5 TSI STYLE AT 110 KW (1498cc)",
          "1.5 TSI (1498cc)",
          "1.5 TSI STYLE AT (1498cc)",
        ],
      },
      {
        model: "WT 50 TC",
        variants: ["0 (1896cc)"],
      },
      {
        model: "SUPER",
        variants: ["0 (1198cc)", "0 (1598cc)"],
      },
    ],
  },
  {
    make: "TATA",
    models: [
      {
        model: "1",
        variants: ["FEARLESS PLUS S 1.5 REVOTORQ DIESEL 6AMT DT (1497cc)"],
      },
      {
        model: "207",
        variants: [
          "DI 6STR (2956cc)",
          "DI 6STR (2956cc)",
          "DI EX (2956cc)",
          "207/31 CREW CAB (2956cc)",
          "207/31 CREW CAB (6 SEATER) (2956cc)",
          "DI EX CREW CAB 5 SETAER (2956cc)",
          "0 (2956cc)",
          "DI HARD TOP (2956cc)",
          "207/31 DI CCPU (2956cc)",
          "DI 11 STR (1948cc)",
          "10 STR (1948cc)",
          "CREW CAB WITH SOFT TOP (11 SETAER) (1948cc)",
          "CREW CAB WITH HARD TOP (10 SETAER) (1948cc)",
          "CREW CAB WITH SOFT TOP (10 SETAER) (1948cc)",
          "207/31 CREW CAB (1948cc)",
          "CREW CAB (1948cc)",
          "DI (2523cc)",
        ],
      },
      {
        model: "407",
        variants: ["6 STR (2956cc)"],
      },
      {
        model: "1210",
        variants: ["0 (1998cc)"],
      },
      {
        model: "1510",
        variants: ["0 (1650cc)"],
      },
      {
        model: "NANO",
        variants: [
          "EMAX NANO XM (624cc)",
          "EMAX XM (624cc)",
          "TWIST XTA (624cc)",
          "BASE (624cc)",
          "BASE (624cc)",
          "TWIST XT (624cc)",
          "TWIST XT (624cc)",
          "CX (624cc)",
          "CX (624cc)",
          "TWIST XE (624cc)",
          "TWIST XE (624cc)",
          "LX (624cc)",
          "LX (624cc)",
          "EMAX NANO XM CNG (624cc)",
          "LX CNG (624cc)",
          "CX CNG (624cc)",
          "EMAX XM CNG (624cc)",
          "CNG XM (624cc)",
          "GENX NANO EASY SHIFT XMA (624cc)",
          "GENX NANO EASY SHIFT XTA (624cc)",
          "GENX NANO XE (624cc)",
          "GENX NANO XM (624cc)",
          "GENX NANO XT (624cc)",
          "LUXURY (624cc)",
          "XTA (624cc)",
          "XTA (624cc)",
          "XMA (624cc)",
          "XMA (624cc)",
          "XT (624cc)",
          "XT (624cc)",
          "XE (624cc)",
          "XE (624cc)",
          "CX EMAX (624cc)",
          "CX BS IV (624cc)",
          "TWIST (624cc)",
          "XM 5STR (624cc)",
          "TWIST XM BS4 (624cc)",
          "CX SPECIAL EDITION (624cc)",
          "CX SPECIAL EDITION (624cc)",
          "DIESEL (624cc)",
          "DIESEL (624cc)",
          "HYBRID (624cc)",
          "HYBRID (624cc)",
          "STD (624cc)",
          "STD (624cc)",
          "GENX XE (624cc)",
          "GENX XE (624cc)",
          "GENX XM (624cc)",
          "GENX XM (624cc)",
          "GENX XT (624cc)",
          "GENX XT (624cc)",
          "GENX XMA (624cc)",
          "GENX XMA (624cc)",
          "GENX XTA (624cc)",
          "GENX XTA (624cc)",
          "XM CNG (624cc)",
          "TWIST XM (624cc)",
          "LX SPECIAL-EDITION (624cc)",
          "LX SPECIAL EDITION (624cc)",
          "CNG EMAX CX (624cc)",
          "CNG EMAX LX (624cc)",
          "XM (624cc)",
          "XM (624cc)",
          "CNG EMAX XM (1248cc)",
          "CNG EMAX CX (1248cc)",
          "CNG EMAX CX (1248cc)",
          "CNG EMAX LX (1248cc)",
          "CNG EMAX LX (1248cc)",
        ],
      },
      {
        model: "TIAGO",
        variants: [
          "JTP (1199cc)",
          "XZ PLUS CNG DUAL TONE (1199cc)",
          "XZA REVOTRON (1199cc)",
          "NRG (1047cc)",
          "NRG (1199cc)",
          "REVOTRON WIZZ (1199cc)",
          "REVOTORQ WIZZ (1047cc)",
          "REVOTROQ NRG XZ (1047cc)",
          "REVOTRON NRG XZ (1199cc)",
          "REVOTRON XZ PLUS DT (1199cc)",
          "REVOTORQ XZ PLUS DT (1047cc)",
          "REVOTRON XZA PLUS DT (1199cc)",
          "1.2 XZ RTN (1199cc)",
          "XT 1.2 RTN BS4 (1199cc)",
          "1.2 NRG MT (1199cc)",
          "1.2 NRG AMT (1199cc)",
          "1.2 XT BSVI (1199cc)",
          "1.2 XZ CNG BSVI (1199cc)",
          "XT 1.05 RTQ (1199cc)",
          "1.2 XZ PLUS DT BSVI (1199cc)",
          "XZA PLUS DUAL TONE (1199cc)",
          "1.2 XT RHYTHM BSVI (1199cc)",
          "EV XZ PLUS LONG RANGE FC (1199cc)",
          "EV XZ PLUS TECH LUX LONG RA FC (1199cc)",
          "1.2 XE BSVI (1199cc)",
          "1.2 XT (O) BSVI (1199cc)",
          "1.2 XZ PLUS BSVI (1199cc)",
          "1.2 XTA BSVI (1199cc)",
          "1.2 XZA PLUS BSVI (1199cc)",
          "1.2 XZA PLUS DT BSVI (1199cc)",
          "1.2 XT NRG BSVI (1199cc)",
          "1.2 NRG BSVI (1199cc)",
          "1.2 XT NRG CNG BSVI (1199cc)",
          "1.2 XZ NRG BSVI (1199cc)",
          "1.2 XE ICNG BSVI (1199cc)",
          "1.2 XM ICNG BSVI (1199cc)",
          "1.2 XT ICNG BSVI (1199cc)",
          "1.2 XT NRG ICNG BSVI (1199cc)",
          "1.2 XZ NRG ICNG BSVI (1199cc)",
          "1.2 XZ PLUS ICNG BSVI (1199cc)",
          "1.2 XZ PLUS DT ICNG BSVI (1199cc)",
          "XT ICNG (1186cc)",
          "REVOTORQ XZ O (1047cc)",
          "REVOTORQ XZ O (1047cc)",
          "REVOTORQ  XB (1047cc)",
          "REVOTORQ XB (1047cc)",
          "REVOTORQ  XZ (1047cc)",
          "REVOTORQ XZ (1047cc)",
          "REVOTORQ  XT (1047cc)",
          "REVOTORQ XT (1047cc)",
          "REVOTORQ  XM (1047cc)",
          "REVOTORQ XM (1047cc)",
          "REVOTORQ  XE (1047cc)",
          "REVOTORQ XE (1047cc)",
          "REVOTRON XB (1199cc)",
          "REVOTRON XB (1199cc)",
          "XE CNG (1199cc)",
          "XE CNG (1199cc)",
          "XM CNG (1199cc)",
          "XM CNG (1199cc)",
          "XT CNG (1199cc)",
          "XT CNG (1199cc)",
          "XZ PLUS CNG (1199cc)",
          "XZ PLUS CNG (1199cc)",
          "REVOTORQ XZ PLUS (1047cc)",
          "REVOTORQ XZ PLUS (1047cc)",
          "REVOTRON XZ (1199cc)",
          "REVOTRON XZ (1199cc)",
          "REVOTRON XT (1199cc)",
          "REVOTRON XT (1199cc)",
          "REVOTRON XM (1199cc)",
          "REVOTRON XM (1199cc)",
          "REVOTRON XE (1199cc)",
          "REVOTRON XE (1199cc)",
          "REVOTRON XE O (1199cc)",
          "REVOTRON XE O (1199cc)",
          "REVOTRON XM O (1199cc)",
          "REVOTRON XM O (1199cc)",
          "REVOTRON XT O (1199cc)",
          "REVOTRON XT O (1199cc)",
          "REVOTRON XTA (1199cc)",
          "REVOTRON XTA (1199cc)",
          "REVOTORQ XE O (1047cc)",
          "REVOTORQ XE O (1047cc)",
          "REVOTORQ XM O (1047cc)",
          "REVOTORQ XM O (1047cc)",
          "REVOTORQ XT O (1047cc)",
          "REVOTORQ XT O (1047cc)",
          "REVOTRON XZ PLUS (1199cc)",
          "REVOTRON XZ PLUS (1199cc)",
          "REVOTRON XZ O (1199cc)",
          "REVOTRON XZ O (1199cc)",
          "REVOTRON XZA PLUS (1199cc)",
          "REVOTRON XZA PLUS (1199cc)",
          "JTP 1.2 (1199cc)",
          "JTP 1.2 (1199cc)",
          "JTP 1.2 RT (1199cc)",
          "REVOTRON XE OPTION (1199cc)",
          "REVOTRON XE OPTION (1199cc)",
          "REVOTRON XT OPTION (1199cc)",
          "REVOTRON XT OPTION (1199cc)",
          "REVOTRON XM OPTION (1199cc)",
          "REVOTRON XM OPTION (1199cc)",
          "REVOTRON XTA AMT (1199cc)",
          "REVOTRON XZ WO ALLOY (1199cc)",
          "WIZZ REVOTRON (1199cc)",
          "XZA PLUS AMT BS6 (1199cc)",
          "XE PETROL BS6 (1199cc)",
          "XE ICNG (1199cc)",
          "XM ICNG (1199cc)",
          "XZ PLUS ICNG (1199cc)",
          "XZ PLUS ICNG DUAL TONE (1199cc)",
          "XT RP WITH REVOTRON 1.2L (1199cc)",
          "1.2 XZ PLUS CNG (1199cc)",
          "1.2 XT CNG (1199cc)",
          "1.2 XT REVOTRON PETROL (1199cc)",
          "1.2 XM CNG (1199cc)",
          "NRG REVOTRON PETROL AMT (1199cc)",
          "XZA PLUS REVOTRON PETROL (1199cc)",
          "XZ PLUS REVOTRON PETROL DT (1199cc)",
          "XZA PLUS REVOTRON PETROL DT (1199cc)",
          "1.2 REVOTRON XT RHYTM PETROL (1199cc)",
          "JTP TURBOCHARGED PETROL (1199cc)",
          "WIZZ PETROL (1199cc)",
          "XT (O) REVOTRON PETROL (1199cc)",
          "XM (O) REVOTRON PETROL (1199cc)",
          "XE (O) REVOTRON PETROL (1199cc)",
          "XB REVOTRON PETROL (1199cc)",
          "XE REVOTRON PETROL (1199cc)",
          "XM REVOTRON PETROL (1199cc)",
          "XT REVOTRON PETROL (1199cc)",
          "XZ REVOTRON PETROL (1199cc)",
          "XZA REVOTRON PETROL (1199cc)",
          "XTA REVOTRON PETROL (1199cc)",
          "XT NRG REVOTRON PETROL (1199cc)",
          "1.2 XE CNG (1199cc)",
          "1.2 XT NRG CNG (1199cc)",
          "1.2 XZ NRG CNG (1199cc)",
          "1.2 XZ PLUS DT CNG (1199cc)",
          "1.2 NRGA PETROL AMT (1199cc)",
          "1.2 XTO PETROL (1199cc)",
          "1.2 XZA REVOTRON AMT (1199cc)",
          "XT REVOTRON LIMITED EDITION PETROL (1199cc)",
          "XZ PLUS REVOTRON PETROL (1199cc)",
          "NEW REVOTRON XB (1199cc)",
          "NEW REVOTRON XB (1199cc)",
          "XF 2.0 PORTFOLIO DIESEL (1199cc)",
          "XT 1.2 RTN BSIV (1199cc)",
          "XT RHYTHM (P) (1199cc)",
          "XE 1.2 RTN BSVI (1199cc)",
          "LIMITED EDITION (1199cc)",
          "LIMITED EDITION (1199cc)",
          "REVOTRON XZA (1199cc)",
          "REVOTRON XZA (1199cc)",
          "XZ W/O ALLOY REVOTRON PETROL (1199cc)",
          "XZ W/O ALLOY REVOTRON PETROL (1199cc)",
          "REVOTORQ XZ PLUS DUAL TONE (1199cc)",
          "1.2 NRGA BSVI (1199cc)",
          "XT ICNG (1199cc)",
          "JTP 1.2L TURBOCHARGED (1199cc)",
          "REVOTRON XZA AMT (1199cc)",
          "NRG REVOTRON AMT (P) (1199cc)",
          "NRG XT CNG (1199cc)",
          "NRG XZ CNG (1199cc)",
          "NRG REVOTRON PETROL (1199cc)",
          "XT LIMITED EDITION (1199cc)",
          "XZ PLUS DUAL TONE CNG (1199cc)",
          "XZ PLUS DUAL TONE CNG (1199cc)",
          "WIZZ EDITION PETROL (1199cc)",
          "WIZZ EDITION PETROL (1199cc)",
          "REVOTRON XZA PLUS DUAL TONE (1199cc)",
          "REVOTRON XZA PLUS DUAL TONE (1199cc)",
          "REVOTRON XZ PLUS DUAL TONE (1199cc)",
          "REVOTRON XZ PLUS DUAL TONE (1199cc)",
          "XT RHYTHM (1199cc)",
          "XT RHYTHM (1199cc)",
          "REVOTORQ  XE OPTION (1047cc)",
          "REVOTORQ XE OPTION (1047cc)",
          "REVOTORQ  XM OPTION (1047cc)",
          "REVOTORQ XM OPTION (1047cc)",
          "REVOTORQ  XT OPTION (1047cc)",
          "REVOTORQ XT OPTION (1047cc)",
          "XE 1.05 D (1047cc)",
          "XZ  1.05 D (1047cc)",
          "XZ WO ALLOY (1047cc)",
          "XM 1.05 L (1047cc)",
          "REVOTORQ XZ PLUS 5 STR (1047cc)",
          "XZ PLUS REVOTORQ DIESEL DT 1047 CC (1047cc)",
          "XT (O) REVOTORQ DIESEL (1047cc)",
          "XE (O) REVOTORQ DIESEL (1047cc)",
          "XM (O) REVOTORQ DIESEL (1047cc)",
          "XE REVOTORQ DIESEL (1047cc)",
          "XM REVOTORQ DIESEL (1047cc)",
          "XT REVOTORQ DIESEL (1047cc)",
          "XZ REVOTORQ DIESEL (1047cc)",
          "XZ PLUS REVOTORQ DIESEL (1047cc)",
          "XZ W/O ALLOY REVOTORQ DIESEL (1047cc)",
          "XZ W/O ALLOY REVOTORQ DIESEL (1047cc)",
          "REVOTORQ XZ PLUS DUAL TONE (1047cc)",
          "REVOTORQ XZ PLUS DUAL TONE (1047cc)",
          "EV XZ PLUS LR TECH LUX (55000cc)",
          "EV XT LR (55000cc)",
          "EV XZ+ 7.2KW FAST CHARGER (55000cc)",
          "EV XZ+ TECH LUX 7.2KW FAST CHARGER (55000cc)",
          "EV XE MEDIUM RANGE 44.87 KW 19.2 KWH 250 KM (1200cc)",
          "EV XT MEDIUM RANGE 45 KW 19.2 KWH 250 KM (1200cc)",
          "EV XT LONG RANGE 55 KW 24 KWH 315 KM (1200cc)",
          "EV XZ PLUS LONG RANGE 55 KW 24 KWH 315 KM (1200cc)",
          "EV XZ PLUS TECH LUX LONG RANGE 55 KW 24 KWH 315 KM (1200cc)",
          "EV XZ PLUS LONG RANGE FAST CHARGER 55 KW 24 KWH 315 KM (1200cc)",
          "EV XZ PLUS TECH LUX LONG RANGE FAST CHARGER 55 KW 24 KWH 315 KM (1200cc)",
          "CNG XZ PLUS (1198cc)",
          "CNG XM (1198cc)",
          "XT (1198cc)",
          "EV ZIPTRON XZ PLUS DT (55cc)",
          "EV LR XT (55cc)",
          "EV XZ PLUS TECH AC FC (55cc)",
          "EV MR XT (55cc)",
          "EV XZ PLUS TECH LUX 24KWH (55cc)",
          "EV MR XE (55cc)",
          "EV MR XT (150cc)",
          "WIZZ  1.05 D (1047cc)",
          "WIZZ DIESEL (1047cc)",
          "XB REVOTORQ DIESEL (1047cc)",
          "NEW REVOTORQ XB (1047cc)",
          "NEW REVOTORQ XB (1047cc)",
          "1.05 RTQ BS IV (1047cc)",
          "NRG REVOTORQ DIESEL (1047cc)",
          "WIZZ EDITION DIESEL (1047cc)",
          "WIZZ EDITION DIESEL (1047cc)",
          "XZ 1.05 D (1396cc)",
          "CNG XT WITH REVOTRON 1.2 L PETROL (1197cc)",
        ],
      },
      {
        model: "INDICA V2",
        variants: [
          "TURBO DLX (ABS) & DUAL AIRBAG (1405cc)",
          "LGI (1405cc)",
          "LGI (1405cc)",
          "STD (1405cc)",
          "AC (1405cc)",
          "LS NA BSIII (1405cc)",
          "LX NA BSIII (1405cc)",
          "DLG (1405cc)",
          "DLG (1405cc)",
          "DL (1405cc)",
          "DL (1405cc)",
          "LS TA65 (1396cc)",
          "LX TA65 (1396cc)",
          "DICOR DLG (1396cc)",
          "DICOR DLS (1396cc)",
          "LXI (1405cc)",
          "LEI (1405cc)",
          "LEI (1405cc)",
          "LSI (1405cc)",
          "LSI (1405cc)",
          "DLE (1405cc)",
          "DLE (1405cc)",
          "TURBO DLG (1405cc)",
          "TURBO DLG (1405cc)",
          "TURBO DLG (1405cc)",
          "TURBO DLS (1405cc)",
          "TURBO DLS (1405cc)",
          "TURBO DLS (1405cc)",
          "TURBO DLX (1405cc)",
          "TURBO DLX (1405cc)",
          "TURBO DLX (1405cc)",
          "LE (1396cc)",
          "LE (1396cc)",
          "LX (1396cc)",
          "LX (1396cc)",
          "DLX (1405cc)",
          "DLX (1405cc)",
          "DLS (1405cc)",
          "DLS (1405cc)",
          "LI (1405cc)",
          "L (1396cc)",
          "L (1396cc)",
          "LS (1396cc)",
          "LS (1396cc)",
          "1.2 XETA GV (1193cc)",
          "LXI BS-II (1193cc)",
          "XETA EGLE LPG BS-IV (1193cc)",
          "XETA EGLE LPG BS-IV (1193cc)",
          "LEI BS-II (1193cc)",
          "XETA EGLS LPG BS-IV (1193cc)",
          "XETA EGLS LPG BS-IV (1193cc)",
          "XETA GLE 1.2 LPG (1193cc)",
          "XETA GLE 1.2 LPG (1193cc)",
          "XETA GV BS-III (1193cc)",
          "XETA GV BS-III (1193cc)",
          "XETA GLE BS-IV (1193cc)",
          "XETA GLE BS-IV (1193cc)",
          "XETA GLE LPG BS-IV (1193cc)",
          "XETA GLE LPG BS-IV (1193cc)",
          "XETA GLS 1.2 LPG (1193cc)",
          "XETA GLS 1.2 LPG (1193cc)",
          "XETA GLS BS-IV (1193cc)",
          "XETA GLS BS-IV (1193cc)",
          "XETA GLS LPG BS-IV (1193cc)",
          "XETA GLS LPG BS-IV (1193cc)",
          "LXI BS-III (1193cc)",
          "LXI BS-III (1193cc)",
          "XETA EGL BS-IV (1193cc)",
          "XETA EGL BS-IV (1193cc)",
          "XETA EGLE BS-IV (1193cc)",
          "XETA EGLE BS-IV (1193cc)",
          "XETA EGLS BS-IV (1193cc)",
          "XETA EGLS BS-IV (1193cc)",
          "XETA EGLX BS-IV (1193cc)",
          "XETA EGLX BS-IV (1193cc)",
          "XETA GL 1.2 BS-III (1193cc)",
          "XETA GL 1.2 BS-III (1193cc)",
          "XETA GL BS-IV (1193cc)",
          "XETA GL BS-IV (1193cc)",
          "XETA GLE 1.2 BS-III (1193cc)",
          "XETA GLE 1.2 BS-III (1193cc)",
          "XETA GLS 1.2 BS-III (1193cc)",
          "XETA GLS 1.2 BS-III (1193cc)",
          "XETA GLG 1.2 BS-III (1193cc)",
          "XETA GLG 1.2 BS-III (1193cc)",
          "LXI (1193cc)",
          "XETA GVG BS-III (1193cc)",
          "XETA GLS SPORT (1193cc)",
          "XETA GLS SPORT (1193cc)",
          "XETA GVE BS-III (1193cc)",
          "XETA GVE BS-III (1193cc)",
          "XETA GVS BS-III (1193cc)",
          "XETA GVS BS-III (1193cc)",
          "TURBO (1368cc)",
          "DL BS-II (1396cc)",
          "DLE BS-II (1396cc)",
          "DLS BS-II (1396cc)",
          "DLX BS-II (1396cc)",
          "LGI BS-II (1396cc)",
          "LI BS-II (1396cc)",
          "XETA GLS BS-III (1396cc)",
          "LSI BS-II (1396cc)",
          "EL (1396cc)",
          "ELX (1396cc)",
          "LXI BS-II (1396cc)",
          "ELE (1396cc)",
          "ELS (1396cc)",
          "LGI BS-III (1396cc)",
          "LGI BS-III (1396cc)",
          "LI BS-III (1396cc)",
          "LI BS-III (1396cc)",
          "LEI BS-II (1396cc)",
          "LEI BS-II (1396cc)",
          "LGI (1396cc)",
          "XETA GL BS-III (1396cc)",
          "XETA GLE BS-III (1396cc)",
          "DLS DICOR (1396cc)",
          "DLE BS-III (1396cc)",
          "DLE BS-III (1396cc)",
          "DLS DICOR BS-III (1396cc)",
          "DLS DICOR BS-III (1396cc)",
          "XETA GLG BS-III (1396cc)",
          "XETA GLG BS-III (1396cc)",
          "XETA GLX BS-III (1396cc)",
          "XETA GLX BS-III (1396cc)",
          "DLG DICOR BS-III (1396cc)",
          "DLG DICOR BS-III (1396cc)",
          "LI (1396cc)",
          "TURBO DLX ABS & DUAL AIRBAGS (1405cc)",
          "LS BSIII (1405cc)",
          "CNG (1405cc)",
          "CNG AC (1405cc)",
          "LX (1405cc)",
          "LX (1405cc)",
          "DLG E-3 5STR (1405cc)",
          "DLX TC E3 (1405cc)",
          "TURBO DLX E-3 (1405cc)",
          "DLS 5STR (1405cc)",
          "DLE E3 (1405cc)",
          "EGLS (1405cc)",
          "DL BS-II (1405cc)",
          "DLE BS-II (1405cc)",
          "DLG BS-II (1405cc)",
          "DLS BS-II (1405cc)",
          "DLX BS-II (1405cc)",
          "LSI BS-II (1405cc)",
          "LXI BS-II (1405cc)",
          "LS (1405 CC) (1405cc)",
          "LX (1405 CC) (1405cc)",
          "TURBOMAX DLE (1405cc)",
          "TURBOMAX DLS (1405cc)",
          "LXI BS-III (1405cc)",
          "DLS BS-III (1405cc)",
          "DLS BS-III (1405cc)",
          "DLX BS-III (1405cc)",
          "DLX BS-III (1405cc)",
          "TURBOMAX DLE BS-IV (1405cc)",
          "TURBOMAX DLE BS-IV (1405cc)",
          "TURBOMAX DLS BS-IV (1405cc)",
          "TURBOMAX DLS BS-IV (1405cc)",
          "TURBO DLX ABS & AIRBAGS (1405cc)",
          "TURBO DLX ABS & AIRBAGS (1405cc)",
          "TURBO DLX - ABS AND AIRBAGS (1405cc)",
          "LS (1405cc)",
          "LS (1405cc)",
          "1.4 LS (1405cc)",
          "DLE-BS III (1405cc)",
          "DLE BS-III (1405cc)",
          "DL BS-III (1405cc)",
          "DL BS-III (1405cc)",
          "DLG BS-III (1405cc)",
          "DLG BS-III (1405cc)",
          "LSI BS-III (1405cc)",
          "LSI BS-III (1405cc)",
        ],
      },
      {
        model: "SAFARI",
        variants: [
          "(1998) 4X4 8STR (1948cc)",
          "(1998) 7STR (1948cc)",
          "EXI 4X4 7STR (2092cc)",
          "EXI 4X4 8STR (2092cc)",
          "EXI 7STR (2092cc)",
          "EXI 8STR (2092cc)",
          "TCIC EX 4X4 7STR (1948cc)",
          "TCIC EX 4X4 8STR (1948cc)",
          "TCIC EX 7STR (1948cc)",
          "TCIC EX 8STR (1948cc)",
          "TCIC LX 4X4 7STR (1948cc)",
          "TCIC LX 4X4 8STR (1948cc)",
          "TCIC LX 7STR (1948cc)",
          "TCIC LX 8STR (1948cc)",
          "STORME VX 400NM (2179cc)",
          "2.2 DICOR VTT EX 4X2 (2179cc)",
          "VXI 4X4 7STR (2092cc)",
          "VXI 4X4 8STR (2092cc)",
          "VXI 7STR (2092cc)",
          "EX 4X2 (2179cc)",
          "4X2 GX DICOR BSIV (2956cc)",
          "EX (4X2) 7STR E-3 (1948cc)",
          "LX (4X2) (1948cc)",
          "LX (4X4) 7STR (1948cc)",
          "LX (4X4) (1948cc)",
          "LX (4X2) 7STR (1948cc)",
          "EX MICO E2 5STR (1948cc)",
          "EX (4X4) 7STR E-3 (1948cc)",
          "LX TC ICI 7 STR (1948cc)",
          "EX (4X4) (1948cc)",
          "EX 4X4 (1948cc)",
          "LX 5STR (1948cc)",
          "LX 5 STR (1948cc)",
          "EX 4X2 (1948cc)",
          "4X2 LX (1948cc)",
          "4X2 LX (1948cc)",
          "LX (1948cc)",
          "EX (1948cc)",
          "4X4 LX TCIC (1948cc)",
          "4X4 LX TCIC (1948cc)",
          "4X2 EX TCIC (1948cc)",
          "4X2 EX TCIC (1948cc)",
          "4X2 LX TCIC (1948cc)",
          "4X2 LX TCIC (1948cc)",
          "4X4 EX TCIC (1948cc)",
          "4X4 EX TCIC (1948cc)",
          "4X4 VXI F/L E-3 (2092cc)",
          "EXI (4X4) 7STR E-3 (2092cc)",
          "4X2 VXI 7STR E-3 (2092cc)",
          "4X2 VXI F/L E-3 (2092cc)",
          "EXI (4X2) 7STR E-3 (2092cc)",
          "4X4 VXI 7STR (2092cc)",
          "EXI 7 SEATER (2092cc)",
          "VXI 7 SEATER (2092cc)",
          "4X2 EXI BS-II (2092cc)",
          "4X2 VXI BS-II (2092cc)",
          "4X4 EXI BS-II (2092cc)",
          "4X4 VXI BS-II (2092cc)",
          "4*2 EXI BS-III (2092cc)",
          "4*2 VXI BS-III (2092cc)",
          "4*4 EXI BS-III (2092cc)",
          "4X2 EX (2092cc)",
          "4X2 EX (2092cc)",
          "4X4 EX (2092cc)",
          "4X4 EX (2092cc)",
          "4X2 (2092cc)",
          "4X2 (2092cc)",
          "4X4 (2092cc)",
          "4X4 (2092cc)",
          "EXI  (4X4) (2092cc)",
          "EXI  (4X2) (2092cc)",
          "4X2 VXI - BS III (2092cc)",
          "4X2 VXI BS-III (2092cc)",
          "4X4 EXI - BS III (2092cc)",
          "4X4 EXI BS-III (2092cc)",
          "4X2 EXI - BS III (2092cc)",
          "4X2 EXI BS-III (2092cc)",
          "4X4 VXI BS-III (2092cc)",
          "4X4 VXI BS-III (2092cc)",
          "4*4 EXI BS-II (2179cc)",
          "2.2 DICOR VX (2179cc)",
          "4*2 EX 9 STR (2179cc)",
          "4*2 EXI BS-II (2179cc)",
          "4*2 VXI BS-II (2179cc)",
          "EX 4*2 6 STR (2179cc)",
          "LX 6 STR (2179cc)",
          "MINOR FACELIFT EX 4X2 (2179cc)",
          "MINOR FACELIFT VX 4X2 (2179cc)",
          "2.2 VARICOR EX 7 SEATER (2179cc)",
          "4X4 EX DICOR BS IV (2179cc)",
          "4X4 EX DICOR BS IV (2179cc)",
          "4X4 LX (2179cc)",
          "4X4 LX (2179cc)",
          "4X2 EX DICOR BS-III (2179cc)",
          "4X2 LX DICOR BS-III (2179cc)",
          "4X2 VX DICOR BS-III (2179cc)",
          "4X2 EX DICOR BS IV BULLET RESISTANT (2179cc)",
          "2.2 VTT EX 8 SEATER (2179cc)",
          "2.2 VTT LX 8 SEATER (2179cc)",
          "2.2 VTT EX 4X4 5 SEATER (2179cc)",
          "4X2 EX DICOR 2.2 VTT (2179cc)",
          "4X2 EX DICOR 2.2 VTT (2179cc)",
          "4X2 EX DICOR BS IV (2179cc)",
          "4X2 EX DICOR BS IV (2179cc)",
          "4X2 LX DICOR 2.2 VTT (2179cc)",
          "4X2 LX DICOR 2.2 VTT (2179cc)",
          "4X2 LX DICOR BS IV (2179cc)",
          "4X2 LX DICOR BS IV (2179cc)",
          "4X2 VX DICOR 2.2 VTT (2179cc)",
          "4X2 VX DICOR 2.2 VTT (2179cc)",
          "4X2 VX DICOR BS IV (2179cc)",
          "4X2 VX DICOR BS IV (2179cc)",
          "4X4 EX DICOR 2.2 VTT (2179cc)",
          "4X4 EX DICOR 2.2 VTT (2179cc)",
          "4X4 VX DICOR 2.2 VTT (2179cc)",
          "4X4 VX DICOR 2.2 VTT (2179cc)",
          "4X4 VX DICOR BS IV (2179cc)",
          "4X4 VX DICOR BS IV (2179cc)",
          "4X4 VX DICOR BS-III (2956cc)",
          "4*2 VX DICOR BS-III (2956cc)",
          "4*2 LX DICOR 3.0 BS III (2956cc)",
          "4*4 EX DICOR BS-III (2956cc)",
          "4*4 VX DICOR BS-III (2956cc)",
          "EX 4X2 (2956cc)",
          "4X2 EX DICOR - BS III (2956cc)",
          "4X2 LX DICOR - BS III (2956cc)",
          "4X4 EX DICOR - BS III (2956cc)",
          "4X4 EX DICOR BS-III (2956cc)",
          "EX DICOR - BS III (2956cc)",
          "VX DICOR - BS III (2956cc)",
          "4X2 VX DICOR - BS III (2956cc)",
          "4X4 EX DICOR 3.0 L 8 SEATER (2956cc)",
          "EX DICOR 3.0 L 8 SEATER (2956cc)",
          "4X2 GX DICOR (2956cc)",
          "4X4 LX DICOR 3.0 L 7 SEATER (2956cc)",
          "4X4 EXI BS-III (2956cc)",
          "4X4 EXI BS-III (2956cc)",
          "4X4 LX DICOR BS-III (2956cc)",
          "4X4 LX DICOR BS-III (2956cc)",
          "XZA BS6 (1856cc)",
          "(1998) 7 SEATER (1948cc)",
          "(1998) 4X4 8 SEATER (1948cc)",
          "TCIC EX 4X4 8 SEATER (1948cc)",
          "TCIC EX 7 SEATER (1948cc)",
          "TCIC EX 8 SEATER (1948cc)",
          "TCIC EX 4X4 7 SEATER (1948cc)",
          "TCIC LX 7 SEATER (1948cc)",
          "TCIC LX 8 SEATER (1948cc)",
          "TCIC LX 4X4 7 SEATER (1948cc)",
          "TCIC LX 4X4 8 SEATER (1948cc)",
          "VX 4X2 7STR (1948cc)",
          "LE (4X4) (1948cc)",
          "LE (4X2) (1948cc)",
          "4*2 8 STR (1948cc)",
          "LE 5STR (1948cc)",
          "PETROL 5 STR (1948cc)",
          "PETROL 6 STR (1948cc)",
          "PETROL 7STR (1948cc)",
          "PETROL 8 STR (1948cc)",
          "5 SEATER (1948cc)",
          "EX  4X2 (PETROL) (1948cc)",
          "4X4 8 SEATER (1948cc)",
          "NEW XT PLUS BSVI (1956cc)",
          "NEW XE MT BSVI (1956cc)",
          "NEW XM MT BSVI (1956cc)",
          "NEW XMA BSVI (1956cc)",
          "NEW XT MT BSVI (1956cc)",
          "NEW XZ MT BSVI (1956cc)",
          "NEW XZ PLUS MT BSVI (1956cc)",
          "NEW XZA PLUS BSVI (1956cc)",
          "NEW XZA BSVI (1956cc)",
          "NEW XZA PLUS GOLD BSVI (1956cc)",
          "NEW XZA PLUS ADVENTURE BSVI (1956cc)",
          "NEW XT PLUS DARK MT BSVI (1956cc)",
          "NEW XZ PLUS DARK MT BSVI (1956cc)",
          "NEW XZ PLUS 6S DARK MT BSVI (1956cc)",
          "NEW XTA PLUS DARK AT BSVI (1956cc)",
          "NEW XTZ PLUS DARK AT BSVI (1956cc)",
          "NEW XTZ PLUS 6S DARK AT BSVI (1956cc)",
          "NEW 2.0 XM L KRYOTEC BSVI (1956cc)",
          "NEW XZ PLUS 6S MT BSVI (1956cc)",
          "NEW XZ PLUS KAZIRANGA MT BSVI (1956cc)",
          "NEW XZ PLUS ADVENTURE MT BSVI (1956cc)",
          "NEW XZ PLUS KAZIRANGA 6S MT BSVI (1956cc)",
          "NEW XZ PLUS ADVENTURE 6S MT BSVI (1956cc)",
          "NEW XZ PLUS GOLD MT BSVI (1956cc)",
          "NEW XZ PLUS GOLD PLUS 6S MT BSVI (1956cc)",
          "NEW XTA PLUS AT BSVI (1956cc)",
          "NEW XZA PLUS KAZIRANGA AT BSVI (1956cc)",
          "NEW XZA PLUS KAZIRANGA 6S AT BSVI (1956cc)",
          "NEW XZA PLUS ADVENTURE AT BSVI (1956cc)",
          "NEW XZA PLUS DARK EDITION AT BSVI (1956cc)",
          "NEW XZA PLUS 6S DARK EDITION BSVI (1956cc)",
          "NEW XT PLUS JET MT BSVI (1956cc)",
          "NEW XT PLUS JET 6S MT BSVI (1956cc)",
          "NEW XZA PLUS JET AT BSVI (1956cc)",
          "NEW XZA PLUS JET 6S AT BSVI (1956cc)",
          "NEW XMS BSVI (1956cc)",
          "NEW XMAS AT BSVI (1956cc)",
          "NEW XZA PLUS 6S ADV (1956cc)",
          "NEW XZA PLUS (O) (1956cc)",
          "NEW XZA PLUS 6S (O) (1956cc)",
          "NEW XZA PLUS 6S (O) ADV (1956cc)",
          "NEW XZA PLUS (O) DARK (1956cc)",
          "NEW XZA PLUS 6S (O) DARK (1956cc)",
          "XZ PLUS DIESEL BS6 (1956cc)",
          "XT PLUS BS6 (1956cc)",
          "XZA PLUS 6 STR BS6 (1956cc)",
          "XZA PLUS DIESEL BS6 (1956cc)",
          "XZA BS 6 (1956cc)",
          "XZA PLUS ADVENTURE BS6 (1956cc)",
          "XMA BS6 (1956cc)",
          "XM BS6 (1956cc)",
          "XZ PLUS 6 STR BS6 (1956cc)",
          "XE BS6 (1956cc)",
          "XTA PLUS BS6 (1956cc)",
          "XZ PLUS GOLD BS6 (1956cc)",
          "XZ PLUS GOLD 6 STR BS6 (1956cc)",
          "XZA PLUS GOLD BS6 (1956cc)",
          "XZA PLUS GOLD 6 STR BS6 (1956cc)",
          "KAZIRANGA XZ +. (1956cc)",
          "XZ PLUS 6S ADVENTURE NEW. (1956cc)",
          "XZA PLUS 6S ADVENTURE NEW. (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS KAZIRANGA (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS KAZIRANGA 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS KAZIRANGA AT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS KAZIRANGA AT 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XE (1956cc)",
          "2.0 TURBO KRYOTEC XM (1956cc)",
          "2.0 TURBO KRYOTEC XMA AT (1956cc)",
          "2.0 TURBO KRYOTEC XT (1956cc)",
          "2.0 TURBO KRYOTEC XT PLUS (1956cc)",
          "2.0 TURBO KRYOTEC XZ (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS ADVENTURE (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS AT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS ADVENTURE AT (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS 6 SEAT AT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS AT DARK (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS AT 6 SEAT DARK (1956cc)",
          "2.0 TURBO KRYOTEC XMS (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS ADVENTURE 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS ADVENTURE 7 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS DARK EDITION 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS DARK EDITION 7 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS RED DARK EDITION 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS RED DARK EDITION 7 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS JET 6 SEAT AT (1956cc)",
          "2.0 TURBO KRYOTEC XMAS AT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS ADVENTURE EDITION 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS RED DARK EDITION 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZ PLUS JET (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS (O) 7 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS RED DARK EDITION 7 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS (O) 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS (O) ADVENTURE EDITION 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS (O) DARK EDITION 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS (O) RED DARK EDITION 6 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS (O) RED DARK EDITION 7 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS (O) DARK EDITION 7 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS DARK EDITION 7 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS (O) ADVENTURE EDITION 7 SEAT (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS GOLD 6 SEAT AT (1956cc)",
          "2.0 TURBO KRYOTEC XTA PLUS AT (1956cc)",
          "2.0 TURBO KRYOTEC XZA AT (1956cc)",
          "2.0 TURBO KRYOTEC XZA (1956cc)",
          "2.0 TURBO KRYOTEC XZA PLUS ADVENTURE (1956cc)",
          "XT PLUS 2.0L KRYOTEC BSVI (1956cc)",
          "XTA PLUS (1956cc)",
          "XTA PLUS (1956cc)",
          "XTA (1956cc)",
          "XZ (1956cc)",
          "XT PLUS KRYOTEC (1956cc)",
          "XZA PLUS GOLD (1956cc)",
          "XZA PLUS GOLD (1956cc)",
          "XZA PLUS GOL 6G (1956cc)",
          "XZ PLUS GOLD (1956cc)",
          "XZ PLUS GOLD (1956cc)",
          "XT BSIV 7S (1956cc)",
          "XZA PLUS DARK (1956cc)",
          "XZA PLUS KZR (1956cc)",
          "XTA PLUS DARK (1956cc)",
          "XZPLUS KZR (1956cc)",
          "X T PLUS DARK (1956cc)",
          "XM 2.0L KRYOTEC BSVI (1956cc)",
          "XZ PLUS DARK EDI DSL (1956cc)",
          "XZA PLUS 2 0L KRYOTEC BSVI (1956cc)",
          "XZA PLUS O BSVI (1956cc)",
          "XZA PLUS (O) 7STR (1956cc)",
          "KAZIRANGA XZ PLUS (1956cc)",
          "KAZIRANGA XZ PLUS (1956cc)",
          "XMAS (1956cc)",
          "XMAS (1956cc)",
          "XZ PLUS 6 STR MT (1956cc)",
          "XZ PLUS 6 STR MT (1956cc)",
          "XZ PLUS 6S ADVENTURE MT (1956cc)",
          "XZ PLUS 6S ADVENTURE MT (1956cc)",
          "XZ PLUS RED DARK EDITION (1956cc)",
          "XZA PLUS 6S (1956cc)",
          "XZA PLUS 6S (1956cc)",
          "XZA PLUS 6S ADVENTURE (1956cc)",
          "XZA PLUS 6S ADVENTURE (1956cc)",
          "XZA PLUS GOLD 6 STR (1956cc)",
          "XZA PLUS GOLD 6 STR (1956cc)",
          "XZA PLUS BS6 (1956cc)",
          "KAZIRANGA XZ PLUS 6S. (1956cc)",
          "KAZIRANGA XZ PLUS 6S (1956cc)",
          "KAZIRANGA XZA PLUS 6S. (1956cc)",
          "KAZIRANGA XZA PLUS 6S (1956cc)",
          "KAZIRANGA XZA PLUS. (1956cc)",
          "KAZIRANGA XZA PLUS (1956cc)",
          "XT PLUS DARK EDITION. (1956cc)",
          "XT PLUS DARK EDITION (1956cc)",
          "XTA PLUS DARK EDITION. (1956cc)",
          "XTA PLUS DARK EDITION (1956cc)",
          "XZ PLUS 6 S DARK EDITION. (1956cc)",
          "XZ PLUS 6S DARK EDITION (1956cc)",
          "XZ PLUS DARK EDITION. (1956cc)",
          "XZ PLUS DARK EDITION (1956cc)",
          "XZA PLUS 6 S DARK EDITION. (1956cc)",
          "XZA PLUS 6S DARK EDITION (1956cc)",
          "XZA PLUS DARK EDITION. (1956cc)",
          "XZA PLUS DARK EDITION (1956cc)",
          "XZA PLUS JET (1956cc)",
          "XZA PLUS JET (1956cc)",
          "XZA PLUS (O) (1956cc)",
          "XZA PLUS (O) 6S ADVENTURE (1956cc)",
          "XZA PLUS 6S JET (1956cc)",
          "XZA PLUS 6S JET (1956cc)",
          "XZ PLUS 6S JET (1956cc)",
          "XZ PLUS 6S JET (1956cc)",
          "XZ PLUS JET (1956cc)",
          "XZ PLUS JET (1956cc)",
          "XMS (1956cc)",
          "XMS (1956cc)",
          "XZ PLUS 6S RED DARK EDITION (1956cc)",
          "XZA PLUS O 6S (1956cc)",
          "XZA PLUS O 6S DARK EDITION (1956cc)",
          "XZA PLUS O 6S RED DARK EDITION (1956cc)",
          "XZA PLUS 6S RED DARK EDITION (1956cc)",
          "XZA PLUS O ADVENTURE (1956cc)",
          "XZA PLUS O DARK EDITION (1956cc)",
          "XZA PLUS O RED DARK EDITION (1956cc)",
          "XZA PLUS RED DARK EDITION (1956cc)",
          "XZ PLUS JET 6 STR (1956cc)",
          "XZ PLUS JET 7 STR (1956cc)",
          "XZA PLUS AMT JET 7 STR (1956cc)",
          "XZA PLUS AMT JET 6 STR (1956cc)",
          "XZ PLUS ADVENTURE MT 6 STR (1956cc)",
          "XZA PLUS 6 STR (1956cc)",
          "XZ PLUS MT 6 STR (1956cc)",
          "XZA PLUS ADVENTURE MT 6 STR (1956cc)",
          "XTA PLUS AMT (1956cc)",
          "XZ PLUS GOLD 7 STR (1956cc)",
          "XZA PLUS GOLD AMT 6 STR (1956cc)",
          "XZA PLUS GOLD AMT 7 STR (1956cc)",
          "XTA PLUS DARK EDITION AMT 7 STR (1956cc)",
          "XZ PLUS DARK EDITION 6 STR (1956cc)",
          "XZA PLUS DARK EDITION AMT 7 STR (1956cc)",
          "XT PLUS DARK EDITION 7 STR (1956cc)",
          "XZ PLUS DARK EDITION 7 STR (1956cc)",
          "XZA PLUS DARK EDITION AMT 6 STR (1956cc)",
          "KAZIRANGA XZ PLUS 7 STR (1956cc)",
          "KAZIRANGA XZA PLUS AMT 7 STR (1956cc)",
          "KAZIRANGA XZ PLUS 6 STR (1956cc)",
          "KAZIRANGA XZA PLUS AMT 6 STR (1956cc)",
          "XZ PLUS MT (1956cc)",
          "XZ PLUS MT (1956cc)",
          "XZA PLUS (1956cc)",
          "XZA PLUS (1956cc)",
          "XT PLUS MT (1956cc)",
          "XT PLUS MT (1956cc)",
          "XZA PLUS ADVENTURE (1956cc)",
          "XZA PLUS ADVENTURE (1956cc)",
          "XMA (1956cc)",
          "XMA (1956cc)",
          "XZA (1956cc)",
          "XZA (1956cc)",
          "XE MT (1956cc)",
          "XE MT (1956cc)",
          "XM MT (1956cc)",
          "XM MT (1956cc)",
          "XZ PLUS GOLD 6 STR (1956cc)",
          "XZ PLUS GOLD 6 STR (1956cc)",
          "XT MT (1956cc)",
          "XT MT (1956cc)",
          "XZ PLUS ADVENTURE MT (1956cc)",
          "XZ PLUS ADVENTURE MT (1956cc)",
          "XZ MT (1956cc)",
          "XZ MT (1956cc)",
          "EXI 4X4 (1998cc)",
          "EXI 4X2 (1998cc)",
          "XZA PLUS DARK (2000cc)",
          "4X2 EX BSIV FL (2040cc)",
          "PETROL VXI 7 SEATER (2092cc)",
          "PETROL VXI 4X4 7 SEATER (2092cc)",
          "PETROL VXI 4X4 8 SEATER (2092cc)",
          "PETROL EXI 7 SEATER (2092cc)",
          "PETROL EXI 8 SEATER (2092cc)",
          "PETROL EXI 4X4 7 SEATER (2092cc)",
          "PETROL EXI 4X4 8 SEATER (2092cc)",
          "2.2 GX (2179cc)",
          "4X4 EXI (2179cc)",
          "4*4 VXI BS-II (2179cc)",
          "DICOR 10 STR (2179cc)",
          "GX 4X2 (2179cc)",
          "4X4 GX DICOR BS IV (2179cc)",
          "4X4 GX DICOR BS IV (2179cc)",
          "2.2 EURO III (2179cc)",
          "4X2 VXI BS-III (2179cc)",
          "4X2 VXI BS-III (2179cc)",
          "4X2 EXI BS-III (2179cc)",
          "4X2 EXI BS-III (2179cc)",
          "4X2 GX DICOR 2.2 VTT (2179cc)",
          "4X2 GX DICOR 2.2 VTT (2179cc)",
          "4X4 GX DICOR 2.2 VTT (2179cc)",
          "4X4 GX DICOR 2.2 VTT (2179cc)",
          "4X2 GX DICOR BS IV (2179cc)",
          "4X2 GX DICOR BS IV (2179cc)",
          "CX (2197cc)",
          "EX (8 SEATER) (2197cc)",
          "LX (9 SEATER) (2197cc)",
          "GX (9 SEATER) (2197cc)",
          "CX (8 SEATER) (2197cc)",
          "LX (8 SEATER) (2197cc)",
          "LX (2197cc)",
          "GX (2197cc)",
          "EX (2197cc)",
          "GX (8 SEATER) (2197cc)",
          "DICOR (2954cc)",
        ],
      },
      {
        model: "INDICA EV2",
        variants: [
          "CR4 L (1396cc)",
          "CR4 LE (1396cc)",
          "CR4 LS (1396cc)",
          "CR4 LX (1396cc)",
          "GLE (1193cc)",
          "GLX (1193cc)",
          "EMAX GLS (1193cc)",
          "EMAX GLX (1193cc)",
          "GLX EMAX (1193cc)",
          "GLX EMAX (1193cc)",
          "GLS EMAX (1193cc)",
          "GLS EMAX (1193cc)",
          "GLS (1193cc)",
          "GLS (1193cc)",
          "GLS CNG (EMAX) (1193cc)",
          "CR4 (DIESEL) L (1396cc)",
          "CR4 (DIESEL) LE (1396cc)",
          "CR4 (DIESEL) LX (1396cc)",
          "CR4 (DIESEL) LS (1396cc)",
          "DL (1396cc)",
          "ELS (1396cc)",
          "ELX (1396cc)",
          "EVX (1396cc)",
          "LE (1396cc)",
          "LE (1396cc)",
          "LS (1396cc)",
          "LS (1396cc)",
          "LX (1396cc)",
          "LX (1396cc)",
          "L (1396cc)",
          "L (1396cc)",
          "LS (1405cc)",
          "LX (1405cc)",
        ],
      },
      {
        model: "SIERRA",
        variants: [
          "STD (1948cc)",
          "STD (1948cc)",
          "TC (1948cc)",
          "TC (1948cc)",
          "TURBO (1948cc)",
          "TURBO (1948cc)",
          "TURBO 4 X 2 (1948cc)",
          "TURBO 4 X 4 (1948cc)",
          "SIERRA TURBO (1948cc)",
          "SIERRA (1948cc)",
          "TURBO (4806cc)",
        ],
      },
      {
        model: "SUMO GRANDE",
        variants: [
          "EX 7STR (2179cc)",
          "EX 7STR (2179cc)",
          "LX 7STR (2179cc)",
          "LX 7STR (2179cc)",
          "EX 9STR (2179cc)",
          "GX 7STR (2179cc)",
          "LX TCIC 7STR (1948cc)",
          "LX TCIC 9STR (1948cc)",
          "LX TURBO 7STR (1948cc)",
          "LX TURBO 9STR (1948cc)",
          "MK II EX 7STR (2179cc)",
          "MK II EX 9STR (2179cc)",
          "MK II GX 7STR (2179cc)",
          "MK II LX 7STR (2179cc)",
          "MK II LX 9STR (2179cc)",
          "MK II TURBO EX 9STR (1948cc)",
          "DICOR CX BS IV 7STR (2179cc)",
          "DICOR CX BS IV 9STR (2179cc)",
          "GX 8STR (2179cc)",
          "GX 8STR (2179cc)",
          "LX 8STR (2179cc)",
          "EX 8 STR (2179cc)",
          "MK II TURBO EX 9 SEATER (1948cc)",
          "MK II GX 7 SEATER (2179cc)",
          "LX 8 SEATER (2179cc)",
          "EX 8 SEATER (2179cc)",
          "GX 7 SEATER (2179cc)",
          "LX TCIC 9 SEATER (1948cc)",
          "LX TCIC 7 SEATER (1948cc)",
          "EX 7 SEATER (2179cc)",
          "LX 7 SEATER (2179cc)",
          "MK II LX 7 SEATER (2179cc)",
          "LX TURBO 9 SEATER (1948cc)",
          "MK II EX 9 SEATER (2179cc)",
          "LX TURBO 7 SEATER (1948cc)",
          "MK II EX 7 SEATER (2179cc)",
          "MK II LX 9 SEATER (2179cc)",
          "GX 8 SEATER (2179cc)",
          "EX 9 SEATER (2179cc)",
          "MK II LX BS-IV (2179cc)",
          "MK II LX BS-IV (2179cc)",
          "MK II EX BS-IV (2179cc)",
          "MK II EX BS-IV (2179cc)",
          "MK II GX BS-IV (2179cc)",
          "MK II GX BS-IV (2179cc)",
          "MK II CX BS-IV (2179cc)",
          "MK II CX BS-IV (2179cc)",
          "LX 9STR (2179cc)",
          "LX 5 STR (2179cc)",
          "CX 7 SEATER (2179cc)",
          "EX (2179cc)",
          "EX (2179cc)",
          "GX (2179cc)",
          "GX (2179cc)",
          "LX (2179cc)",
          "LX (2179cc)",
          "MK II LX (2179cc)",
          "MK II EX (2179cc)",
          "MK II CX 9STR (2179cc)",
          "GX (9 SEATER) (2179cc)",
          "LX (9 SEATER) (2179cc)",
          "MK II CX (2179cc)",
          "MK II GX (2179cc)",
          "CX 8 SEATER (2179cc)",
          "CX (2179cc)",
          "CX (2179cc)",
          "LX 9STR (1948cc)",
          "EX TCIC 8STR (1948cc)",
          "EX TCIC 7STR (1948cc)",
          "LX 10STR (1948cc)",
          "GX 6 SEATER  (1948 CC) (1948cc)",
          "EX 5 SEATER (1948 CC) (1948cc)",
          "EX TCIC 7 SEATER (1948cc)",
          "CX TURBO (1948cc)",
          "CX TURBO (1948cc)",
          "EX TURBO (1948cc)",
          "EX TURBO (1948cc)",
          "MK II EX TURBO (1948cc)",
          "MK II EX TURBO (1948cc)",
          "MK II CX TURBO (1948cc)",
          "MK II CX TURBO (1948cc)",
          "MK II LX TURBO (1948cc)",
          "MK II LX TURBO (1948cc)",
          "EX TURBO 9 SEATER (1948cc)",
          "EX (1948cc)",
          "LX TURBO (1948cc)",
          "LX TURBO (1948cc)",
          "CX (8 SEATER) (1405cc)",
          "CX (624cc)",
        ],
      },
      {
        model: "INDIGO CS",
        variants: [
          "LX (1405cc)",
          "ELE TDI (1405cc)",
          "ELX (1396cc)",
          "ELX (1396cc)",
          "ELS (1396cc)",
          "ELS (1396cc)",
          "LS DICOR (1405cc)",
          "LS DICOR (1405cc)",
          "LX TDI (1405cc)",
          "LX (TDI) (1405cc)",
          "LE TDI (1405cc)",
          "LE (TDI) (1405cc)",
          "E LX TDI (1405cc)",
          "E LS TDI (1405cc)",
          "EGLX (1193cc)",
          "EGLX (1193cc)",
          "EGVX (1193cc)",
          "EGLS (1193cc)",
          "EVX (1396cc)",
          "GLE (1193cc)",
          "GLE (1193cc)",
          "GLS (1193cc)",
          "GLS (1193cc)",
          "GLX (1193cc)",
          "GLX (1193cc)",
          "LX DICOR (1396cc)",
          "LX DICOR (1396cc)",
          "LS TDI (1405cc)",
          "LS (TDI) (1405cc)",
          "1.4 EVX (1396cc)",
          "1.4 EVX (1396cc)",
          "LS DICOR (1396cc)",
          "LS DICOR (1396cc)",
          "ELE (1405cc)",
          "ELE TDI DIESEL (1405cc)",
          "ELS TDI DIESEL (1405cc)",
          "ELX TDI DIESEL (1405cc)",
          "LE (1405cc)",
          "LS (1405cc)",
          "LS (1405cc)",
          "LX DICOR (1405cc)",
          "LX DICOR (1405cc)",
        ],
      },
      {
        model: "INDIGO MANZA",
        variants: [
          "GLX SAFIRE (1368cc)",
          "GLS SAFIRE (1368cc)",
          "GEX SAFIRE (1368cc)",
          "GVX SAFIRE (1368cc)",
          "AURA QUADRAJET (ABS) (1248cc)",
          "AURA SAFIRE (ABS) (1368cc)",
          "ELAN QUADRAJET (1248cc)",
          "ELAN QUADRAJET (1248cc)",
          "AURA QUADRAJET (1248cc)",
          "AURA QUADRAJET (1248cc)",
          "AQUA QUADRAJET (1248cc)",
          "AQUA QUADRAJET (1248cc)",
          "AURA SAFIRE (1368cc)",
          "AURA SAFIRE (1368cc)",
          "AQUA SAFIRE (1368cc)",
          "ELAN SAFIRE (1368cc)",
          "EXL QUADRAJET (1248cc)",
          "LX QUADRAJET (1248cc)",
          "EX QUADRAJET (1248cc)",
          "VX QUADRAJET (1248cc)",
          "LS QUADRAJET (1248cc)",
          "ELAN QUADRAJET90 DIESEL (1248cc)",
          "AQUA QUADRAJET90 DIESEL (1248cc)",
          "AURA QUADRAJET90 DIESEL (1248cc)",
          "AURA (ABS) QUADRAJET90 DIESEL (1248cc)",
          "LS QUADRAJET90 (1248cc)",
          "LX QUADRAJET90 (1248cc)",
          "VX QUADRAJET90 (1248cc)",
          "EX QUADRAJET90 (1248cc)",
          "EXL QUADRAJET90 (1248cc)",
          "1.2 (1248cc)",
          "AURA PLUS (1248cc)",
          "EX (1248cc)",
          "AURA (1248cc)",
          "ELAN (1248cc)",
          "LS (1248cc)",
          "AQUA (1248cc)",
          "EXL (1248cc)",
          "VX (1248cc)",
          "LX (1248cc)",
          "AURA NEW QUADRAJET 90 BS III (1248cc)",
          "EXL QUA BS IV (1248cc)",
          "AQUA QUADRAJET BS-IV (1248cc)",
          "AURA PLUS QUADRAJET BS-IV (1248cc)",
          "SAFIRE LS (1248cc)",
          "SAFIRE EXL (1248cc)",
          "CLUB CLASS EX QUADRAJET (1248cc)",
          "CELEBRATION QUADRAJET (1248cc)",
          "SAFIRE LX (1248cc)",
          "SAFIRE VX (1248cc)",
          "SAFIRE EX (1248cc)",
          "ELAN QUADRAJET BS-IV (1248cc)",
          "AURA QUADRAJET BSIV (1248cc)",
          "AURA ABS QUADRAJET BSIV (1248cc)",
          "AURA ABS SAFIRE BS-IV (1248cc)",
          "AURA PLUS QUADRAJET (1248cc)",
          "AURA (ABS) QUADRAJET (1248cc)",
          "GLS SAFIRE90 (1368cc)",
          "GLX SAFIRE90 (1368cc)",
          "GVX SAFIRE90 (1368cc)",
          "GEX SAFIRE90 (1368cc)",
          "ELAN SAFIRE90 PETROL (1368cc)",
          "AQUA SAFIRE90 PETROL (1368cc)",
          "AURA SAFIRE90 PETROL (1368cc)",
          "AURA (ABS) SAFIRE90 PETROL (1368cc)",
          "AURA PLUS (1368cc)",
          "AURA SAFIRE90 BS-IV (1368cc)",
          "AURA SAFIRE BS-III (1368cc)",
          "AURA+ QUADRAJET 90-BSIV (1368cc)",
          "AQUA SAFIRE BS-III (1368cc)",
          "AURA (ABS) SAFIRE BS-III (1368cc)",
          "SAFFIRE ELAN (1368cc)",
          "GEXL SAFIRE90 (1368cc)",
          "AURA SAFIRE BS-IV (1368cc)",
          "SAFIRE GEX (1368cc)",
          "SAFIRE GLS (1368cc)",
          "SAFIRE GLX (1368cc)",
          "SAFIRE GVX (1368cc)",
          "AURA PLUS SAFIRE BS-IV (1368cc)",
          "GLS (1368cc)",
          "GLX (1368cc)",
          "GVX (1368cc)",
          "GEX (1368cc)",
          "AQUA SAFIRE BS-IV (1368cc)",
          "ELAN SAFIRE BS-IV (1368cc)",
          "AURA (ABS) SAFIRE BS-IV (1368cc)",
          "1.3 (1368cc)",
          "AURA (ABS) SAFIRE (1368cc)",
          "AURA PLUS SAFIRE (1368cc)",
        ],
      },
      {
        model: "INDIGO",
        variants: [
          "VS (1405cc)",
          "VS (1405cc)",
          "GLE (1405cc)",
          "GLE (1405cc)",
          "GSX (1405cc)",
          "LX DICOR (1396cc)",
          "LX TDI (1405cc)",
          "SX (1405cc)",
          "GLS (1396cc)",
          "GLS (1396cc)",
          "GLX (1396cc)",
          "GLX (1396cc)",
          "LS DICOR (1396cc)",
          "LS TDI (1405cc)",
          "GV (1193cc)",
          "GXS (1193cc)",
          "FACELIFT GLX (1396cc)",
          "GVS (1396cc)",
          "GV (1396cc)",
          "GVE (1396cc)",
          "GLS BS-III (1396cc)",
          "GLS BS-III (1396cc)",
          "LX (1396cc)",
          "LX (1396cc)",
          "LS DICOR BS-III (1396cc)",
          "LS DICOR BS-III (1396cc)",
          "FACELIFT LS (1396cc)",
          "GLX BS-III (1396cc)",
          "GLX BS-III (1396cc)",
          "GV BS-III (1396cc)",
          "GV BS-III (1396cc)",
          "GVE BS-III (1396cc)",
          "GVE BS-III (1396cc)",
          "LX DICOR BS-III (1396cc)",
          "LX DICOR BS-III (1396cc)",
          "GVS BS-III (1396cc)",
          "GVS BS-III (1396cc)",
          "DICOR LS (1405cc)",
          "FACELIFT LX (1405cc)",
          "GRAND DICOR (1405cc)",
          "GRAND XL FL E -3 (1405cc)",
          "GV E3 (1405cc)",
          "GVS E3 (1405cc)",
          "CLASSIC  XL (1405cc)",
          "SX  E-3 (1405cc)",
          "VX (1405cc)",
          "GSX  E-3 (1405cc)",
          "LS (1405cc)",
          "LS (1405cc)",
          "GVE E3 (1405cc)",
          "GLX 1405 CC (1405cc)",
          "GLS 1405 CC (1405cc)",
          "LS E-2 (1405cc)",
          "CLASSIC  E3 (1405cc)",
          "LS E-3 (1405cc)",
          "GLX - BS III (1405cc)",
          "GLE BS-III (1405cc)",
          "GLE BS-III (1405cc)",
          "LS FACE LIFT (1405cc)",
          "LS TDI BS-III (1405cc)",
          "GSX BS-III (1405cc)",
          "GSX BS-III (1405cc)",
          "LS TDI BS-III (1405cc)",
          "LX (1405cc)",
          "V BS-III (1405cc)",
          "VE BS-III (1405cc)",
          "VE BS-III (1405cc)",
          "VS BS-III (1405cc)",
          "VS BS-III (1405cc)",
          "GLX (1405cc)",
          "GLX (1405cc)",
          "GLS (1405cc)",
          "LX  E-3 (1405cc)",
          "LX DICOR (1405cc)",
          "LX E II 5STR (1405cc)",
          "LX TDI BS-III (1405cc)",
          "LX TDI BS-III (1405cc)",
          "SX BS-III (1405cc)",
          "SX BS-III (1405cc)",
          "V (1405cc)",
          "V BS-3 (1405cc)",
          "V BS-III (1405cc)",
          "V E3 DIESEL (1405cc)",
          "VE (1405cc)",
          "VE E3 DIESEL (1405cc)",
        ],
      },
      {
        model: "INDIGO MARINA",
        variants: [
          "GLE (1396cc)",
          "GLS (1396cc)",
          "GLX (1396cc)",
          "GVS (1396cc)",
          "GSX (1396cc)",
          "VS (TDI) (1405cc)",
          "SX (1405cc)",
          "LX (TDI) (1405cc)",
          "LS (TDI) (1405cc)",
          "LX DICOR (1396cc)",
          "ELAN Q JET BS IV (1248cc)",
          "LX DICOR - BS III (1366cc)",
          "GVS (PETROL) (1396cc)",
          "GLS (PETROL) (1396cc)",
          "GLX (PETROL) (1396cc)",
          "GLE  (PETROL) (1396cc)",
          "VS (1396cc)",
          "VS (1396cc)",
          "GLS BS-III (1396cc)",
          "GLS BS-III (1396cc)",
          "GLX BS-III (1396cc)",
          "GLX BS-III (1396cc)",
          "GSX BS-III (1396cc)",
          "GSX BS-III (1396cc)",
          "GLE BS-III (1396cc)",
          "LX DICOR BS-III (1396cc)",
          "LX DICOR BS-III (1396cc)",
          "LX (TDI) (1396cc)",
          "LS TDI (1396cc)",
          "DICOR LX (1405cc)",
          "DLX (1405cc)",
          "GLX (DIESEL) (1405cc)",
          "LS (1405cc)",
          "LS (1405cc)",
          "LS BS-3 (1405cc)",
          "LS BS-III (1405cc)",
          "LX (1405cc)",
          "LX (1405cc)",
          "VS (1405cc)",
          "LX BS-II (1405cc)",
          "GLS BS-II (1405cc)",
          "GLS BS-II (1405cc)",
          "GLE (1405cc)",
          "GLS (1405cc)",
          "GLX (1405cc)",
          "GSX (1405cc)",
          "LS - BS II (1405cc)",
          "LS BS-II (1405cc)",
          "GLE BS-II (1405cc)",
          "GLE BS-II (1405cc)",
          "GLX BS-II (1405cc)",
          "GLX BS-II (1405cc)",
          "GLS - BS III (1405cc)",
          "GLE BS-III (1405cc)",
          "GLE BS-III (1405cc)",
          "LS BS-III (1405cc)",
          "LX BS-III (1405cc)",
          "LX BS-III (1405cc)",
          "SX BS-III (1405cc)",
          "SX BS-III (1405cc)",
          "SW LX BS IV (1405cc)",
          "SW LX BS IV (1405cc)",
          "SX E 3 (1405cc)",
          "VS (1405cc)",
        ],
      },
      {
        model: "INDICA V2 XETA",
        variants: [
          "GLX (1405cc)",
          "1.4 GLE (1396cc)",
          "1.4 GLS (1396cc)",
          "1.4 GLS (1405cc)",
          "1.2 GL (1193cc)",
          "1.2 GLE (1193cc)",
          "1.2 GLG (1193cc)",
          "1.2 GLS (1193cc)",
          "1.4 GLX (1396cc)",
          "1.2 GV (1193cc)",
          "1.2 GVE (1193cc)",
          "1.2 GVG (1193cc)",
          "1.2 GVS (1193cc)",
          "1.4 GLG (1396cc)",
          "1.2 GLS LPG (1193cc)",
          "1.2 GLE LPG (1193cc)",
          "GVE (1193cc)",
          "GVE (1193cc)",
          "GVG (1193cc)",
          "GLG (1193cc)",
          "GLE (1193cc)",
          "GLE (1193cc)",
          "GLE LPG (1193cc)",
          "GLS LPG (1193cc)",
          "GVS (1193cc)",
          "GV (1193cc)",
          "GLS (1193cc)",
          "GLG 1.2 (1193cc)",
          "1.2 GLG - BS III (1193cc)",
          "1.2 GLE - BS III (1193cc)",
          "1.2 GL - BS III (1193cc)",
          "GL (1193cc)",
          "GL (1193cc)",
          "GVG BS-III (1193cc)",
          "GVS (1341cc)",
          "GV E3 (1396cc)",
          "XETA GLX BS-III (1396cc)",
          "GLG (1396cc)",
          "GLX (1396cc)",
          "GLE (1396cc)",
          "GLS (1396cc)",
          "1.4 GLS - BS III (1396cc)",
          "GL BS-III (1396cc)",
          "GLE BS-III (1396cc)",
          "GL (1396cc)",
          "1.4 GLG (1405cc)",
          "GLG (1405cc)",
          "1.4 GLX (1405 CC) (1405cc)",
          "GLS (1405cc)",
          "GLG (1405 CC) (1405cc)",
          "1.4 GLS (1405 CC) (1405cc)",
        ],
      },
      {
        model: "TIGOR",
        variants: [
          "1.2 XE (1199cc)",
          "1.2 XZ (O) (1199cc)",
          "1.2 XZ (1199cc)",
          "1.2 XM (1199cc)",
          "1.2 XTA (AT) (1199cc)",
          "1.2 XZA (AT) (1199cc)",
          "1.05 XE (1047cc)",
          "1.05 XT (1047cc)",
          "1.2 XT (1199cc)",
          "1.05 XZ (O) (1047cc)",
          "1.05 XZ (1047cc)",
          "1.05 XM (1047cc)",
          "1.05 BUZZ (1047cc)",
          "1.05 XZ PLUS (1047cc)",
          "1.2 XZ PLUS (1199cc)",
          "1.2 XE EV (1199cc)",
          "1.2 XM EV (1199cc)",
          "1.2 XT EV (1199cc)",
          "1.2 XZA PLUS (1199cc)",
          "1.2 XMA (1199cc)",
          "1.2 BUZZ (1199cc)",
          "1.2 XZA PLUS (AT) (1199cc)",
          "1.2 XMA (AT) (1199cc)",
          "1.2 XZ PLUS DT(EV) (1199cc)",
          "1.2 XE BSVI (1199cc)",
          "1.2 XM BSVI (1199cc)",
          "1.2 XZ BSVI (1199cc)",
          "1.2 XMA BSVI (1199cc)",
          "1.2 XZ PLUS BSVI (1199cc)",
          "1.2 XZ PLUS LEATHERETTE BSVI (1199cc)",
          "1.2 XZA PLUS AMT BSVI (1199cc)",
          "1.2 XZA PLUS LEATHERETTE BSVI (1199cc)",
          "REVOTRON XZ OPTION (1199cc)",
          "REVOTRON XZ OPTION (1199cc)",
          "REVOTRON XB (1199cc)",
          "XZA PLUS DUAL TONE. (1199cc)",
          "XZ PLUS DUAL TONE (1199cc)",
          "XZ ICNG (1199cc)",
          "XZ PLUS ICNG (1199cc)",
          "XZ PLUS ICNG DUAL TONE (1199cc)",
          "RTN XZA PLUS (1199cc)",
          "1.2 XE PLUS ELECTRIC VEHICLE (1199cc)",
          "XPRESS T XM PLUS ELECTRIC VEHICLE (1199cc)",
          "1.2 XT PLUS ELECTRIC VEHICLE (1199cc)",
          "XZ PLUS LITHIUM ION (1199cc)",
          "1.2 XZ CNG (1199cc)",
          "1.2 XZ PLUS CNG (1199cc)",
          "1.2 XZA PLUS PETROL (1199cc)",
          "1.2 XZA PLUS AMT (1199cc)",
          "1.2 XTA PETROL (1199cc)",
          "1.2 XMA AT PETROL 1199 CC (1199cc)",
          "1.2 XZ PLUS PETROL (1199cc)",
          "1.2 XZA PETROL (1199cc)",
          "1.2 XT EV LI 72V 216AH 30KW (1199cc)",
          "1.2 XT EVHIGH ENERGY DENSITY  LI 55KW (1199cc)",
          "JTP TURBOCHARGED PETROL (1199cc)",
          "1.2 XM PETROL (1199cc)",
          "1.2 XE PETROL (1199cc)",
          "1.2 XT PETROL (1199cc)",
          "1.2 XZ PETROL (1199cc)",
          "1.2 XZ (O) PETROL (1199cc)",
          "1.2 BUZZ PETROL (1199cc)",
          "1.2 XM CNG (1199cc)",
          "1.2 XZA PLUS DT AMT PETROL (1199cc)",
          "1.2 XZ PLUS LP PETROL (1199cc)",
          "1.2 XZA PLUS LP AMT (1199cc)",
          "1.2 XZ PLUS LP CNG (1199cc)",
          "1.2 XT PLUS LUX ELECTRIC VEHICLE (1199cc)",
          "XZ PLUS DT (1199cc)",
          "CNG XZ (1199cc)",
          "XZA 1.2 RTN BS-IV (1199cc)",
          "XZ PLUS 1.2 RTN BS-IV (1199cc)",
          "REVOTRON XZ+ DUAL TONE (1199cc)",
          "REVOTRON XZ+ DUAL TONE (1199cc)",
          "REVOTRON XZA+ DUAL TONE (1199cc)",
          "REVOTRON XZA+ DUAL TONE (1199cc)",
          "XZA PLUS DUAL TONE LEATHERETTE PACK (1199cc)",
          "XZA PLUS DUAL TONE LEATHERETTE PACK (1199cc)",
          "JTP 1.2 (1199cc)",
          "JTP 1.2 (1199cc)",
          "REVOTRON XZA (1199cc)",
          "REVOTRON XZA (1199cc)",
          "REVOTRON XMA (1199cc)",
          "REVOTRON XMA (1199cc)",
          "XZA PLUS LEATHERETTE PACK (1199cc)",
          "XZA PLUS LEATHERETTE PACK (1199cc)",
          "REVOTRON XTA (1199cc)",
          "REVOTRON XTA (1199cc)",
          "REVOTRON XTA AMT (1199cc)",
          "REVOTRON XZA AMT (1199cc)",
          "REVOTRON XMA AMT (1199cc)",
          "XZA PLUS AMT DUAL TONE LEATHERETTE PACK (1199cc)",
          "XZA PLUS AMT LEATHERETTE PACK (1199cc)",
          "REVOTRON XZ PLUS (1199cc)",
          "EV XE PLUS (1199cc)",
          "REVOTRON XZA PLUS (1199cc)",
          "XZ PLUS CNG DUAL TONE LEATHERETTE PACK (1199cc)",
          "XZ PLUS CNG DUAL TONE LEATHERETTE PACK (1199cc)",
          "XZ PLUS DUAL TONE LEATHERETTE PACK (1199cc)",
          "XZ PLUS DUAL TONE LEATHERETTE PACK (1199cc)",
          "JTP 1.2L TURBOCHARGED (1199cc)",
          "XZ PLUS CNG (1199cc)",
          "XZ PLUS CNG (1199cc)",
          "XZ CNG (1199cc)",
          "XZ CNG (1199cc)",
          "REVOTRON XM (1199cc)",
          "REVOTRON XM (1199cc)",
          "BUZZ PETROL (1199cc)",
          "BUZZ PETROL (1199cc)",
          "XM CNG (1199cc)",
          "XM CNG (1199cc)",
          "XZ PLUS DUAL TONE CNG (1199cc)",
          "XZ PLUS DUAL TONE CNG (1199cc)",
          "XZ PLUS LEATHERETTE PACK (1199cc)",
          "XZ PLUS LEATHERETTE PACK (1199cc)",
          "XZ PLUS CNG LEATHERETTE PACK (1199cc)",
          "XZ PLUS CNG LEATHERETTE PACK (1199cc)",
          "1.2 XM PLUS ELECTRIC VEHICLE (1198cc)",
          "XZ PLUS EV (1200cc)",
          "EV MR XT (45cc)",
          "EV XE PLUS (55000cc)",
          "EV XZ PLUS (97cc)",
          "XE 1.05 (1047cc)",
          "XT 1.05 (1047cc)",
          "XZ (O) 1.05 (1047cc)",
          "XZ 1.05 (1047cc)",
          "1.05 REVOTORQ XM (1047cc)",
          "REVOTORQ XZ (1047cc)",
          "REVOTORQ XZ (1047cc)",
          "1.2 XT ELECTRIC VEHICLE (1199cc)",
          "REVOTORQ XT (1047cc)",
          "REVOTORQ XT (1047cc)",
          "REVOTORQ XE (1047cc)",
          "REVOTORQ XE (1047cc)",
          "REVOTORQ XZ O (1047cc)",
          "REVOTORQ XZ O (1047cc)",
          "REVOTORQ XZ OPTION (1047cc)",
          "REVOTORQ XZ OPTION (1047cc)",
          "XM (MODEL 2019) ELECTRIC (998cc)",
          "1.2 XZ PLUS ELECTRIC VEHICLE (1199cc)",
          "1.2 XM ELECTRIC VEHICLE (1199cc)",
          "1.2 XE ELECTRIC VEHICLE (1199cc)",
          "REVOTORQ XZ PLUS. (1047cc)",
          "1.05 XZ PLUS DIESEL (1047cc)",
          "1.05 XZ DIESEL (1047cc)",
          "1.05 XZ (O) DIESEL (1047cc)",
          "XZ 1.05 D (1047cc)",
          "REVOTORQ XZ PLUS (D) (1047cc)",
          "REVOTORQ XB (1047cc)",
          "REVOTORQ XM (O) (1047cc)",
          "1.05 XM DIESEL (1047cc)",
          "1.05 XE DIESEL (1047cc)",
          "1.05 XT DIESEL (1047cc)",
          "1.05 BUZZ DIESEL (1047cc)",
          "BUZZ DIESEL (1047cc)",
          "BUZZ DIESEL (1047cc)",
          "REVOTORQ XM (1047cc)",
          "REVOTORQ XM (1047cc)",
          "1.2 XZ PLUS REFRESH (1198cc)",
          "1.2 REVOTRON XM (1199cc)",
          "XTA (1199cc)",
          "XZA (1199cc)",
          "1.2 XZ PLUS CNG BSVI (1199cc)",
          "1.2 XZ CNG BSVI (1199cc)",
          "1.2 XM CNG BSVI (1199cc)",
          "XE 1.2 (1199cc)",
          "XT 1.2 (1199cc)",
          "XZ (O) 1.2 (1199cc)",
          "XZ 1.2 (1199cc)",
          "REVOTRON XT (1199cc)",
          "REVOTRON XT (1199cc)",
          "REVOTRON XZ (1199cc)",
          "REVOTRON XZ (1199cc)",
          "REVOTRON XE (1199cc)",
          "REVOTRON XE (1199cc)",
          "REVOTRON XZ O (1199cc)",
          "REVOTRON XZ O (1199cc)",
          "EV XZ PLUS (26KWcc)",
        ],
      },
      {
        model: "INDICA",
        variants: [
          "DLX BSIV (1200cc)",
          "GLS (1193cc)",
          "GLE (1193cc)",
          "DL (1405cc)",
          "DLX (1405cc)",
          "DLX (1405cc)",
          "GLX (1193cc)",
          "DLE (1405cc)",
          "DLE (1405cc)",
          "DLS (1405cc)",
          "DLS (1405cc)",
          "2000 (1193cc)",
          "EMAX GLS (1193cc)",
          "CNG (1193cc)",
          "GLS BS III (1193cc)",
          "EMAX GLS CNG (1193cc)",
          "EMAX GLX CNG (1193cc)",
          "LI (1197cc)",
          "LGI (1197cc)",
          "LSI (1197cc)",
          "LEI (1197cc)",
          "2000 PL (1368cc)",
          "2000 MPFI LSI (1368cc)",
          "STANDARD (1368cc)",
          "CNG (1368cc)",
          "VISTA LS (1396cc)",
          "DLE 1.4 (1396cc)",
          "GLX (1396cc)",
          "STD (1405cc)",
          "DLX TC (1405cc)",
          "DL (E-1) (1405cc)",
          "LI  E-3 (1405cc)",
          "LSI  E-3 (1405cc)",
          "LI  E-2 (1405cc)",
          "LSI  E-2 (1405cc)",
          "LXI  E-2 (1405cc)",
          "LEI  E-3 (1405cc)",
          "LEI  E-2 (1405cc)",
          "LXI  E-3 (1405cc)",
          "GVE (1405cc)",
          "DL (E-3) (1405cc)",
          "DL (E-2) (1405cc)",
          "LXI V2 (1405cc)",
          "CE V2 (1405cc)",
          "PL (1405cc)",
          "PLE (1405cc)",
          "PLX (1405cc)",
          "GLS (1405cc)",
          "LSI (1405cc)",
          "LSI (1405cc)",
          "LEI (1405cc)",
          "LEI (1405cc)",
          "LXI (1405cc)",
          "LXI (1405cc)",
        ],
      },
      {
        model: "INDICA VISTA",
        variants: [
          "AURA SAFIRE (ABS) (1368cc)",
          "GLS SAFIRE (1172cc)",
          "GLS SAFIRE (1368cc)",
          "1.3 QUADRAJET (1179cc)",
          "AURA SAFIRE ANNIVERSARI EDN (1172cc)",
          "AQUA SAFIRE (1172cc)",
          "AURA SAFIRE (1172cc)",
          "TERRA SAFIRE (1172cc)",
          "AURA PLUS QUADRAJET (1248cc)",
          "AURA (ABS) QUADRAJET (1248cc)",
          "AURA QUADRAJET (1248cc)",
          "AQUA TDI (1405cc)",
          "AQUA QUADRAJET (1248cc)",
          "TERRA TDI (1405cc)",
          "AURA QUADRAJET ANNIVERSARI EDN (1248cc)",
          "GLX SAFIRE 90 (1368cc)",
          "GLX SAFIRE90 (1368cc)",
          "GLS SAFIRE 65 (1193cc)",
          "GZX SAFIRE 90 (1368cc)",
          "GZX SAFIRE90 (1368cc)",
          "LE QUADRAJET (1248cc)",
          "LE QUADRAJET (1248cc)",
          "LS QUADRAJET (1248cc)",
          "LS QUADRAJET (1248cc)",
          "VX QUADRAJET (1248cc)",
          "VX QUADRAJET (1248cc)",
          "LX TDI (1405cc)",
          "LX TDI (1405cc)",
          "LS TDI (1405cc)",
          "LS TDI (1405cc)",
          "ZX QUADRAJET (1248cc)",
          "ZX QUADRAJET (1248cc)",
          "GVX SAFIRE 65 (1172cc)",
          "GLX SAFIRE 65 (1172cc)",
          "LE SAFIRE (1172cc)",
          "TERRA SAFIRE 90 (1368cc)",
          "TERRA QUADRAJET (1248cc)",
          "AURA ABS 1.2 SAFIRE (1172cc)",
          "AURA ABS 1.2 SAFIRE (1172cc)",
          "AURA 1.2 SAFIRE (1172cc)",
          "AURA 1.2 SAFIRE (1172cc)",
          "AURA PLUS 1.2 SAFIRE (1172cc)",
          "AURA PLUS 1.2 SAFIRE (1172cc)",
          "GVX SAFIRE 90 (1368cc)",
          "GVX SAFIRE90 (1368cc)",
          "LX QUADRAJET (1248cc)",
          "LX QUADRAJET (1248cc)",
          "GLS SAFIRE 65 (1172cc)",
          "GLS SAFIRE65 (1172cc)",
          "SAFIRE AQUA (1172cc)",
          "SAFIRE AURA (1172cc)",
          "SAFIRE TERRA (1172cc)",
          "AQUA ABS 1.2 SAFIRE (1172cc)",
          "AQUA SAFIRE BS-III (1172cc)",
          "AURA SAFIRE BS-III (1172cc)",
          "AURA ABS SAFIRE BS-III (1172cc)",
          "AURA PLUS SAFIRE BS-III (1172cc)",
          "TERRA SAFIRE BS-III (1172cc)",
          "IGNIS SAFIRE BS-IV (1172cc)",
          "1.2 AURA SAFIRE ABS 90 (1172cc)",
          "GLX SAFIRE65 BS-IV (1172cc)",
          "GLX SAFIRE65 BS-IV (1172cc)",
          "GVX SAFIRE65 BS-IV (1172cc)",
          "GVX SAFIRE65 BS-IV (1172cc)",
          "AURA SAFIRE ANNIVERSARY EDITION (1172cc)",
          "AURA SAFIRE ANNIVERSARY EDITION (1172cc)",
          "AURA SAFIRE BS-IV (1172cc)",
          "AURA SAFIRE BS-IV (1172cc)",
          "LE SAFIRE BS-III (1172cc)",
          "LE SAFIRE BS-III (1172cc)",
          "AQUA SAFIRE BS-IV (1172cc)",
          "AQUA SAFIRE BS-IV (1172cc)",
          "AURA ABS SAFIRE BS-IV (1172cc)",
          "AURA ABS SAFIRE BS-IV (1172cc)",
          "AURA PLUS SAFIRE BS-IV (1172cc)",
          "AURA PLUS SAFIRE BS-IV (1172cc)",
          "DRIVETECH4 TERRA 1.2 SAFIRE (1172cc)",
          "DRIVETECH4 AQUA 1.2 SAFIRE (1172cc)",
          "DRIVETECH4 AURA 1.2 SAFIRE (1172cc)",
          "DRIVETECH4 AURA ABS 1.2 SAFIRE (1172cc)",
          "TERRA 1.2 SAFIRE (1172cc)",
          "TERRA 1.2 SAFIRE (1172cc)",
          "AQUA 1.2 SAFIRE (1172cc)",
          "AQUA 1.2 SAFIRE (1172cc)",
          "VX Q-JET (BS IV) (1196cc)",
          "QUADRAJET AQUA (1248cc)",
          "QUADRAJET AURA (1248cc)",
          "QUADRAJET TERRA (1248cc)",
          "LS (1248cc)",
          "AQUA (1248cc)",
          "AURA PLUS (1248cc)",
          "TERRA (1248cc)",
          "LX (1248cc)",
          "ZX (1248cc)",
          "VX (1248cc)",
          "AQUA QUADRAJET BS-III (1248cc)",
          "AURA QUADRAJET BS-III (1248cc)",
          "AURA PLUS QUADRAJET BS-III (1248cc)",
          "TERRA QUADRAJET BS-III (1248cc)",
          "AURA + SAFIRE 90 (1248cc)",
          "D90 ZX+ (1248cc)",
          "D 90 VX (1248cc)",
          "TERRA 1.3 QUADRAJET (1248cc)",
          "TERRA 1.3 QUADRAJET (1248cc)",
          "VX QUADRAJET BS-IV (1248cc)",
          "VX QUADRAJET BS-IV (1248cc)",
          "LS QUADRAJET BS-IV (1248cc)",
          "LS QUADRAJET BS-IV (1248cc)",
          "QUADRAJET ANNIVERSARY EDITION (1248cc)",
          "QUADRAJET ANNIVERSARY EDITION (1248cc)",
          "TERRA SAFIRE BS-IV (1248cc)",
          "TERRA SAFIRE BS-IV (1248cc)",
          "AQUA QUADRAJET BS-IV (1248cc)",
          "AQUA QUADRAJET BS-IV (1248cc)",
          "AURA ABS 1.3 QUADRAJET BS-III (1248cc)",
          "AURA ABS 1.3 QUADRAJET BS-III (1248cc)",
          "AURA ABS QUADRAJET BS-IV (1248cc)",
          "AURA ABS QUADRAJET BS-IV (1248cc)",
          "AURA QUADRAJET BS-IV (1248cc)",
          "AURA QUADRAJET BS-IV (1248cc)",
          "AURA PLUS QUADRAJET BS-IV (1248cc)",
          "AURA PLUS QUADRAJET BS-IV (1248cc)",
          "LE QUADRAJET BS-III (1248cc)",
          "LE QUADRAJET BS-III (1248cc)",
          "TERRA QUADRAJET BS-IV (1248cc)",
          "TERRA QUADRAJET BS-IV (1248cc)",
          "D90 ZX PLUS BS-IV (1248cc)",
          "D90 ZX PLUS BS-IV (1248cc)",
          "D90 VX BS-IV (1248cc)",
          "D90 VX BS-IV (1248cc)",
          "DRIVETECH4 AURA ABS 1.3 QUADRAJET (1248cc)",
          "DRIVETECH4 AQUA 1.3 QUADRAJET (1248cc)",
          "DRIVETECH4 AURA 1.3 QUADRAJET (1248cc)",
          "AURA ABS 1.3 QUADRAJET (1248cc)",
          "AURA ABS 1.3 QUADRAJET (1248cc)",
          "DRIVETECH4 TERRA QUADRAJET (1248cc)",
          "AURA (1248cc)",
          "AURA 1.3 QUADRAJET (1248cc)",
          "AURA 1.3 QUADRAJET (1248cc)",
          "AQUA 1.3 QUADRAJET (1248cc)",
          "AQUA 1.3 QUADRAJET (1248cc)",
          "AURA PLUS 1.3 QUADRAJET (1248cc)",
          "AURA PLUS 1.3 QUADRAJET (1248cc)",
          "AURA SAFIRE ABS 90 (1368cc)",
          "GLS SAFIRE 90 (1368cc)",
          "AURA PLUS SAFIRE 90 (1368cc)",
          "AQUA PLUS SAFIRE 90 (1368cc)",
          "AQUA SAFIRE 90 (1368cc)",
          "VISTA AQUA SAFIRE 90 (1368cc)",
          "AQUA SAFIRE 90 BS-IV (1368cc)",
          "AQUA SAFIRE 90 BS-IV (1368cc)",
          "AURA ABS SAFIRE 90 BS-IV (1368cc)",
          "AURA ABS SAFIRE 90 BS-IV (1368cc)",
          "AURA PLUS SAFIRE 90 BS-IV (1368cc)",
          "AURA PLUS SAFIRE 90 BS-IV (1368cc)",
          "TERRA SAFIRE 90 BS-IV (1368cc)",
          "TERRA SAFIRE 90 BS-IV (1368cc)",
          "AQUA SAFIRE (1368cc)",
          "AURA SAFIRE (1368cc)",
          "GVX SAFIRE (1368cc)",
          "1.4 AQUA SAFIRE (1396cc)",
          "GVX SAFIRE 90 (1398cc)",
          "TDI TERRA (1405cc)",
          "TDI AQUA (1405cc)",
          "IGNIS (1405cc)",
          "LS (1405cc)",
          "AQUA (1405cc)",
          "TERRA (1405cc)",
          "LX (1405cc)",
          "QUADRAJET (1405cc)",
          "AQUA TDI BS-III (1405cc)",
          "AQUA TDI BS-III (1405cc)",
          "LS TDI BS-III (1405cc)",
          "LS TDI BS-III (1405cc)",
          "LX TDI BS-III (1405cc)",
          "LX TDI BS-III (1405cc)",
          "TERRA TDI BS-III (1405cc)",
          "TERRA TDI BS-III (1405cc)",
          "AURA (1405cc)",
          "AQUA 1.4 TDI (1405cc)",
          "AQUA 1.4 TDI (1405cc)",
          "TERRA 1.4 TDI (1405cc)",
          "TERRA 1.4 TDI (1405cc)",
        ],
      },
      {
        model: "NEXON",
        variants: [
          "1.2 REVOTRON XE (1198cc)",
          "1.2 REVOTRON XM (1198cc)",
          "1.2 REVOTRON XT (1198cc)",
          "1.2 REVOTRON XZ PLUS DUAL TONE (1198cc)",
          "1.2 REVOTRON XZ PLUS (1198cc)",
          "1.5 REVOTORQ XZ PLUS DUAL TONE (1497cc)",
          "1.5 XM PLUS BSVI (1497cc)",
          "1.2 XZ PLUS (P) DARK EDITION (1199cc)",
          "1.5 REVOTORQ XZ (1497cc)",
          "1.5 REVOTORQ XZA PLUS (1497cc)",
          "1.5 REVOTORQ XZA PLUS DT (1497cc)",
          "1.2 REVOTRON XZ (1198cc)",
          "1.2 REVOTRON XZA PLUS (AT) (1198cc)",
          "1.2 REVOTRON XZA PLUS DT (1198cc)",
          "1.5 REVOTORQ XMA (1497cc)",
          "1.2 REVOTRON XMA (1198cc)",
          "1.2 KRAZ (1198cc)",
          "1.2 KRAZ PLUS (1198cc)",
          "1.5 KRAZ (1497cc)",
          "1.5 KRAZ PLUS (1497cc)",
          "1.2 REVOTRON XT PLUS (1198cc)",
          "1.5 REVOTORQ XT PLUS (1497cc)",
          "1.2 REVOTRON XMA (AT) (1198cc)",
          "1.5 REVOTORQ XMA (AT) (1497cc)",
          "1.2 REVOTRON XZA PLUS (O) AT (1198cc)",
          "1.2 REVOTRON XZA PLUS DT(O)AT (1198cc)",
          "1.5 REVOTORQ XZA PLUS (O)(AT) (1497cc)",
          "1.5 REVOTORQ XZA PLUS DT(O)AT (1497cc)",
          "1.2 REVOTRON XZ PLUS (O) (1198cc)",
          "1.2 REVOTRON XZ PLUS DT (O) (1198cc)",
          "1.5 REVOTORQ XZ PLUS (O) (1497cc)",
          "1.5 REVOTORQ XZ PLUS DT (O) (1497cc)",
          "1.2 XE BSVI (1199cc)",
          "1.2 XM BSVI (1199cc)",
          "1.2 XMA BSVI (1199cc)",
          "1.2 XZ BSVI (1199cc)",
          "1.2 XZ PLUS BSVI (1199cc)",
          "1.2 XZ PLUS (DT) BSVI (1199cc)",
          "1.2 XZ PLUSÂ (O) BSVI (1199cc)",
          "1.2 XZ PLUS (DT) (O) BSVI (1199cc)",
          "1.2 XZA PLUS (AMT) BSVI (1199cc)",
          "1.2 XZA PLUS (DT) (AMT) BSVI (1199cc)",
          "1.2 XZA PLUS (O)(AMT) BSVI (1199cc)",
          "1.2 XZA PLUS (DT)(O) BSVI (1199cc)",
          "1.5 XE BSVI (1497cc)",
          "1.5 XM BSVI (1497cc)",
          "1.5 XMA (AMT) BSVI (1497cc)",
          "1.5 XZ BSVI (1497cc)",
          "1.5 XZ PLUS BSVI (1497cc)",
          "1.5 XZ PLUS (DT) BSVI (1497cc)",
          "1.5 XZ PLUS (O) BSVI (1497cc)",
          "1.5 XZ PLUS (DT)(O) BSVI (1497cc)",
          "1.5 XZA PLUS (AMT) BSVI (1497cc)",
          "1.5 XZA PLUS (DT) (AMT) BSVI (1497cc)",
          "1.5 XZA PLUS(O)(AMT) BSVI (1497cc)",
          "1.5 XZA PLUS (DT)(O)(AMT)BSVI (1497cc)",
          "1.2 XM (S) BSVI (1199cc)",
          "1.2 XMA (S) (AMT) BSVI (1199cc)",
          "1.5 XM (S) BSVI (1497cc)",
          "1.2 XZ PLUS (HS) BSVI (1199cc)",
          "1.5 XMA (S) (AMT) BSVI (1497cc)",
          "1.2 XZ PLUS (HS)(DT) BSVI (1199cc)",
          "1.2 XZA PLUS (S)(AMT) BSVI (1199cc)",
          "1.2 XZA PLUS (S)(DT)(AMT) BSVI (1199cc)",
          "1.5 XZ PLUS (S) BSVI (1497cc)",
          "1.5 XZ PLUS (S) (DT)BSVI (1497cc)",
          "1.5 XZA PLUS (S) (AMT)BSVI (1497cc)",
          "1.5 XZA PLUS (S)(DT) (AMT)BSVI (1497cc)",
          "1.5 XZ PLUS RTQ BS4 (1497cc)",
          "1.5 XZ PLUS (O) DARK EDITION (1497cc)",
          "1.5 XZA PLUS (O) DARK EDITION (1497cc)",
          "1.5 XZ PLUS P (1497cc)",
          "1.5 XZ PLUS (DT) P (1497cc)",
          "1.2 XZ PLUS KAZIRANAGA (1199cc)",
          "1.5 XZ PLUS KAZIRANAGA (1497cc)",
          "1.2 XZA PLUS KAZIRANAGA (AMT) (1199cc)",
          "1.5 XZA PLUS KAZIRANAGA (AMT) (1497cc)",
          "1.2 XZA PLUS DARK EDITION (1199cc)",
          "1.5 XZA PLUS DARK EDITION (1497cc)",
          "1.2 XMA PLUS BSVI (1199cc)",
          "1.2 XM PLUS BSVI (1199cc)",
          "1.5 XZ PLUS DARK EDITION BSVI (1497cc)",
          "1.2 XZ PLUS (P) JET BSVI (1199cc)",
          "1.2 XZA PLUS (P) JET BSVI (1199cc)",
          "1.5 XZ PLUS (P) JET BSVI (1497cc)",
          "1.5 XZA PLUS (P) JET BSVI (1497cc)",
          "1.2 XZ PLUS (L) BSVI (1199cc)",
          "1.2 XZ PLUS (L) DT BSVI (1199cc)",
          "1.2 XZ PLUS (L) DARK EDTN BSVI (1199cc)",
          "1.2 XZA PLUS (L) BSVI (1199cc)",
          "1.2 XZA PLUS (L) DT BSVI (1199cc)",
          "1.2 XZA PLUS (L)DARK EDTN BSVI (1199cc)",
          "1.5 XZ PLUS (L) BSVI (1497cc)",
          "1.5 XZ PLUS (L) DT BSVI (1497cc)",
          "1.5 XZ PLUS (L)DARK EDTN BSVI (1497cc)",
          "1.5 XZA PLUS (L) BSVI (1497cc)",
          "1.5 XZA PLUS (L) DT BSVI (1497cc)",
          "1.5 XZA PLUS (L)DARK EDTN BSVI (1497cc)",
          "1.5 XZ PLUS (HS)DARK EDTN BSVI (1497cc)",
          "1.5 XZA PLUS(HS)DARK EDTN BSVI (1497cc)",
          "1.2 XZA PLUS(HS)DARK EDTN BSVI (1199cc)",
          "EV PRIME XZ PLUS JET (1582cc)",
          "EV MAX XZ PLUS JET (1582cc)",
          "EV MAX XZ PLUS AC FC WMU JET (1582cc)",
          "1.2 XZA PLUS (P) BSVI (1199cc)",
          "1.5 XZA PLUS (P) BSVI (1497cc)",
          "EV MAX XM (1582cc)",
          "EV MAX XM AC FC WMU (1582cc)",
          "1.2 XZ PLUS (S) BSVI (1199cc)",
          "1.5 REVOTORQ XE (1497cc)",
          "1.5 REVOTORQ XM (1497cc)",
          "1.5 REVOTORQ XT (1497cc)",
          "1.5 REVOTORQ XZ PLUS (1497cc)",
          "1.2 XZ PLUS (O) DARK EDITION (1199cc)",
          "1.2 XZ PLUS DARK EDITION (1199cc)",
          "XMA S 1.2 RTN BS6 (1199cc)",
          "XZA PLUS S 1.2 RTN BS6 (1199cc)",
          "XM 1.2 RTN BS6 (1199cc)",
          "XZ PLUS 1.2 RTN BS6 (1199cc)",
          "XM (S) PETROL BSVI (1199cc)",
          "1.2 XMA RTN BS6 (1199cc)",
          "XE BS6. (1199cc)",
          "XZ PLUS DUAL TONE BS6 (1199cc)",
          "XZ PLUS S BS6 (1199cc)",
          "XMA S BS6 (1199cc)",
          "XM BS6 (1199cc)",
          "XZ BS6 (1199cc)",
          "XZA PLUS S BS6 (1199cc)",
          "XZA PLUS DUAL TONE ROOF AMT BS6 (1199cc)",
          "XZ PLUS PETROL BS6 (1199cc)",
          "XZ+(HS) WITH 120PS REVOTRON 1.2 PETROL BS6 (1199cc)",
          "ZX+ TURBO 110 PS BSIV (1199cc)",
          "XZ+ TURBO 110 PS BSIV (1199cc)",
          "XZ+LXS WITH REVOTRON 1.2L PETROL ENGINE BS6PH2 (1199cc)",
          "XMZ + S (1199cc)",
          "XZA PLUS S DARK EDITION AMT (1199cc)",
          "XZA PLUS DUAL TONE S NEW (1199cc)",
          "1.2 PETROL XZ PLUS KAZIRANGA (1199cc)",
          "1.2 XZA PLUS (O) DARK EDITION AT (1199cc)",
          "1.2 PETROL XZA PLUS KAZIRANGA (1199cc)",
          "1.2 XZ  PLUS (O) DUAL TONE (1199cc)",
          "1.2 PETROL XMA (S) 1199 CC (1199cc)",
          "1.2 XZA PLUS (O) (1199cc)",
          "1.2 PETROL XZ PLUS (O) (1199cc)",
          "1.2 PETROL XM CC 1199 (1199cc)",
          "1.2 PETROL XMA CC 1199 (1199cc)",
          "1.2 PETROL XZ PLUS (S) (1199cc)",
          "1.2 XZ PLUS DUAL TONE (S) (1199cc)",
          "1.2 PETROL XZ 1199 CC (1199cc)",
          "1.2 PETROL XM (S) 1199 CC (1199cc)",
          "1.2 PETROL XZ PLUS DT 1199 CC (1199cc)",
          "1.2 PETROL XZ PLUS (S) 1199 CC (1199cc)",
          "1.2 PETROL XZ PLUS DT (S) 1199 CC (1199cc)",
          "1.2 PETROL XZ PLUS (O)1199 CC (1199cc)",
          "1.2 PETROL XZ PLUS DT (O) 1199 CC (1199cc)",
          "1.2 PETROL XZA PLUS  1199 CC (1199cc)",
          "1.2 PETROL XZA PLUS DT 1199 CC (1199cc)",
          "1.2 PETROL XZ PLUS 1199 CC (1199cc)",
          "1.2 PETROL XZA PLUS (S) AMT (1199cc)",
          "1.2 PETROL XE CC 1199 (1199cc)",
          "1.2 PETROL XZA PLUS DT (O) 1199 CC (1199cc)",
          "1.2 PETROL XZA PLUS (O)1199 CC (1199cc)",
          "1.2 PETROL XZA PLUS DT (S) 1199 CC (1199cc)",
          "1.2 PETROL XZAPLUS (S) 1199 CC (1199cc)",
          "1.2 PETROL XZ PLUS DARK (L) (1199cc)",
          "1.2 PETROL XZ PLUS (L) (1199cc)",
          "1.2 PETROL XZ PLUS LUX (1199cc)",
          "1.2 XZA PLUS PETROL (P) (1199cc)",
          "1.2 XZA PLUS P JET EDITION AMT (1199cc)",
          "1.2 PETROL XZ PLUS LUXS RED DARK EDITION (1199cc)",
          "1.2 PETROL XZA PLUS (L) DARK AMT (1199cc)",
          "1.2 PETROL XZ PLUS (S) DARK EDITION (1199cc)",
          "1.2 PETROL XZA PLUS LUXS RED DARK (1199cc)",
          "1.2 PETROL XMA PLUS (S) 1199 CC (1199cc)",
          "1.2 PETROL XZA PLUS (L) DT AMT (1199cc)",
          "1.2 PETROL XM PLUS (S) 1199 CC (1199cc)",
          "1.2 PETROL XZA PLUS (L) BS6 (1199cc)",
          "1.2 PETROL XZ PLUS LUXS (1199cc)",
          "1.2 PETROL XZ PLUS LUXS KAZIRANGA (1199cc)",
          "1.2 PETROL XZ PLUS LUXS DUAL TONE (1199cc)",
          "1.2 PETROL XZA PLUS LUXS (1199cc)",
          "1.2 PETROL XZ PLUS LUXS DARK EDITION (1199cc)",
          "1.2 PETROL XZA PLUS LUX DUAL TONE (1199cc)",
          "1.2 PETROL XZA PLUS LUX DARK EDITION (1199cc)",
          "1.2 PETROL XZA PLUS LUXS KAZIRANGA (1199cc)",
          "1.2 PETROL XZA PLUS LUXS DUAL TONE (1199cc)",
          "1.2 PETROL XZ PLUS (P) KAZIRANGA (1199cc)",
          "1.2 PETROL XZ PLUS (P) JET EDITION (1199cc)",
          "1.2 PETROL XZ PLUS (P) 1199 CC (1199cc)",
          "1.2 PETROL XZ PLUS (P) DT 1199 CC (1199cc)",
          "1.2 XZ PLUS (P) DARK EDITION 1199 CC (1199cc)",
          "1.2 PETROL XZ PLUS HS CC 1199 (1199cc)",
          "1.2 PETROL XZ PLUS HS DT CC 1199 (1199cc)",
          "1.2 PETROL XZ PLUS HS DARK CC 1199 (1199cc)",
          "1.2 PETROL XZA PLUS HS CC 1199 (1199cc)",
          "1.2 PETROL XZA PLUS HS DT CC 1199 (1199cc)",
          "1.2 PETROL XZA PLUS HS DARK CC 1199 (1199cc)",
          "1.2 PETROL XZA PLUS (P) DARK EDITION 1199 CC (1199cc)",
          "1.2 PETROL XZ PLUS LUX DARK EDITION (1199cc)",
          "1.2 PETROL XZ PLUS LUX DT (1199cc)",
          "1.2 PETROL XZA PLUS LUXS DARK (1199cc)",
          "1.2 PETROL XZA PLUS (P)  1199 CC (1199cc)",
          "1.2 PETROL XZA PLUS (P) DT 1199 CC (1199cc)",
          "XM S (1199cc)",
          "XM S (1199cc)",
          "XZ PLUS HS (1199cc)",
          "XZ PLUS HS (1199cc)",
          "XZAPLUS(P) (1199cc)",
          "XZA PLUS P (1199cc)",
          "XZ PLUS DT HS (1199cc)",
          "XZA PLUS PREMIUM DARK EDITION (1199cc)",
          "XZA PLUS PREMIUM DARK EDITION (1199cc)",
          "XZA PLUS PETROL DUAL TONE (1199cc)",
          "XZA PLUS PETROL DUAL TONE (1199cc)",
          "XZA PLUS (1199cc)",
          "XZ PLUS S (1199cc)",
          "XZ PLUS S (1199cc)",
          "XZ 1.2  RTN BS VI (1199cc)",
          "XMA S (1199cc)",
          "XMA S (1199cc)",
          "XZA PLUS S (1199cc)",
          "XZA PLUS S (1199cc)",
          "XZA PLUS DUAL TONE S (1199cc)",
          "XZA PLUS DUAL TONE S (1199cc)",
          "XZ PLUS (O) BSVI (1199cc)",
          "XZ PLUS (S) 1.2 RTN BSVI (1199cc)",
          "XMA 1.2 RTN BSVI (1199cc)",
          "(P) XZPLUS KZR (1199cc)",
          "(P) XZPLUS HS (1199cc)",
          "(P) XZPLUSP  DARK (1199cc)",
          "XZA PLUS KZR (1199cc)",
          "(D) XZ PLUSÂ DT P (1199cc)",
          "XZ PLUS (P) DK (1199cc)",
          "(P) XZ PLUS DT P (1199cc)",
          "XZ PLUS LUXS (1199cc)",
          "XZA PLUS LUXS RED DARK EDITION (1199cc)",
          "XZA PLUS LUX DT (1199cc)",
          "XZ PLUS DUAL TONE HS (1199cc)",
          "XZ PLUS DUAL TONE HS (1199cc)",
          "XZ PLUS HS DARK EDITION (1199cc)",
          "XZ PLUS HS DARK EDITION (1199cc)",
          "XZ PLUS L (1199cc)",
          "XZ PLUS L (1199cc)",
          "XZ PLUS L DUAL TONE (1199cc)",
          "XZ PLUS L DUAL TONE (1199cc)",
          "XZ PLUS LUXS DIESEL RED DARK EDITION (1199cc)",
          "XZ PLUS LUXS RED DARK EDITION (1199cc)",
          "XZ PLUS S DARK EDITION (1199cc)",
          "XZ PLUS S DARK EDITION (1199cc)",
          "XZA PLUS DUAL TONE HS (1199cc)",
          "XZA PLUS DUAL TONE HS (1199cc)",
          "XZA PLUS LUXS DIESEL RED DARK EDITION (1199cc)",
          "XZ PLUS LUXS JET (1199cc)",
          "XZA PLUS LUXS JET (1199cc)",
          "SMART 1.2 REVOTRON PETROL 5MT (1199cc)",
          "SMART PLUS 1.2 REVOTRON PETROL 5MT (1199cc)",
          "PURE 1.2 REVOTRON PETROL 6MT (1199cc)",
          "SMART PLUS S 1.2 REVOTRON PETROL 5MT (1199cc)",
          "PURE S 1.2 REVOTRON PETROL 6MT (1199cc)",
          "XZ PLUS (S) DUAL TONE (1199cc)",
          "CREATIVE 1.2 REVOTRON PETROL 6MT (1199cc)",
          "CREATIVE 1.2 REVOTRON PETROL 6MT DT (1199cc)",
          "CREATIVE PLUS 1.2 REVOTRON PETROL 6MT DT (1199cc)",
          "CREATIVE PLUS 1.2 REVOTRON PETROL 6MT (1199cc)",
          "CREATIVE 1.2 REVOTRON PETROL 6AMT (1199cc)",
          "CREATIVE 1.2 REVOTRON PETROL 6AMT DT (1199cc)",
          "CREATIVE PLUS S 1.2 REVOTRON PETROL 6MT DT (1199cc)",
          "CREATIVE 1.2 REVOTRON PETROL 7DCA (1199cc)",
          "CREATIVE 1.2 REVOTRON PETROL 7DCA DT (1199cc)",
          "CREATIVE PLUS S 1.2 REVOTRON PETROL 6MT (1199cc)",
          "CREATIVE PLUS 1.2 REVOTRON PETROL 6AMT (1199cc)",
          "CREATIVE PLUS 1.2 REVOTRON PETROL 6AMT DT (1199cc)",
          "FEARLESS PR 1.2 REVOTRON PETROL 6MT DT (1199cc)",
          "FEARLESS 1.2 REVOTRON PETROL 6MT DT (1199cc)",
          "CREATIVE PLUS 1.2 REVOTRON PETROL 7DCA DT (1199cc)",
          "CREATIVE PLUS 1.2 REVOTRON PETROL 7DCA (1199cc)",
          "CREATIVE PLUS S 1.2 REVOTRON PETROL 6AMT (1199cc)",
          "CREATIVE PLUS S 1.2 REVOTRON PETROL 6AMT DT (1199cc)",
          "FEARLESS S 1.2 REVOTRON PETROL 6MT DT (1199cc)",
          "FEARLESS PR S 1.2 REVOTRON PETROL 6MT DT (1199cc)",
          "FEARLESS PR 1.2 REVOTRON PETROL 6MT (1199cc)",
          "FEARLESS PLUS 1.2 REVOTRON PETROL 6MT (1199cc)",
          "CREATIVE PLUS S 1.2 REVOTRON PETROL 7DCA DT (1199cc)",
          "CREATIVE PLUS S 1.2 REVOTRON PETROL 7DCA (1199cc)",
          "FEARLESS PLUS S 1.2 REVOTRON PETROL 6MT DT (1199cc)",
          "FEARLESS PR 1.2 REVOTRON PETROL 7DCA DT (1199cc)",
          "FEARLESS 1.2 REVOTRON PETROL 7DCA DT (1199cc)",
          "FEARLESS PLUS 1.2 REVOTRON PETROL 7DCA DT (1199cc)",
          "FEARLESS PR S 1.2 REVOTRON PETROL 7DCA DT (1199cc)",
          "FEARLESS PR PLUS 1.2 REVOTRON PETROL 7DCA DT (1199cc)",
          "FEARLESS S 1.5 REVOTORQ DIESEL 6MT DT (1199cc)",
          "FEARLESS PR PLUS S 1.2 REVOTRON PETROL 7DCA DT (1199cc)",
          "FEARLESS PR PLUS S 1.2 REVOTRON PETROL 7DCA DUAL TONE (1199cc)",
          "FEARLESS PLUS S 1.2 REVOTRON PETROL 7DCA DT (1199cc)",
          "FEARLESS S 1.5 REVOTORQ DIESEL 6AMT DT (1199cc)",
          "XZ PLUS PREMIUM DARK EDITION (1199cc)",
          "XZ PLUS PREMIUM DARK EDITION (1199cc)",
          "XE (1199cc)",
          "XE (1199cc)",
          "XM (1199cc)",
          "XM (1199cc)",
          "XZ (1199cc)",
          "XZ (1199cc)",
          "XZ PLUS O (1199cc)",
          "XZ PLUS (O) (1199cc)",
          "XZ PLUS DARK EDITION (1199cc)",
          "XZ PLUS DARK EDITION (1199cc)",
          "XZA PLUS DARK EDITION (1199cc)",
          "XZA PLUS DARK EDITION (1199cc)",
          "XZ PLUS O DARK EDITION (1199cc)",
          "XZ PLUS O DARK EDITION (1199cc)",
          "XZA PLUS O DARK EDITION (1199cc)",
          "XZA PLUS O DARK EDITION (1199cc)",
          "KAZIRANGA XZ PLUS (P). (1199cc)",
          "KAZIRANGA XZ PLUS P (1199cc)",
          "KAZIRANGA XZA PLUS (P). (1199cc)",
          "KAZIRANGA XZA PLUS P (1199cc)",
          "XZA PLUS HS (1199cc)",
          "XZA PLUS HS (1199cc)",
          "XMA PLUS S (1199cc)",
          "XMA PLUS S (1199cc)",
          "XM PLUS S (1199cc)",
          "XM PLUS S (1199cc)",
          "XZ PLUS PREMIUM JET (1199cc)",
          "XZ PLUS PREMIUM JET (1199cc)",
          "XZ PLUS LUX DUAL TONE (1199cc)",
          "XZ PLUS LUX DUAL TONE (1199cc)",
          "XZ PLUS LUX DARK EDITION (1199cc)",
          "XZ PLUS LUX DARK EDITION (1199cc)",
          "XZ PLUS LUXS DARK EDITION (1199cc)",
          "XZ PLUS LUXS DARK EDITION (1199cc)",
          "XZ PLUS LUXS KAZIRANGA (1199cc)",
          "XZ PLUS LUXS KAZIRANGA (1199cc)",
          "XZ PLUS LUX... (1199cc)",
          "XZ PLUS LUX (1199cc)",
          "XZ PLUS LUXS DUAL TONE (1199cc)",
          "XZ PLUS LUXS DUAL TONE (1199cc)",
          "XZA PLUS LUX (1199cc)",
          "XZA PLUS LUX (1199cc)",
          "XZA PLUS LUX DUAL TONE (1199cc)",
          "XZA PLUS LUX DUAL TONE (1199cc)",
          "XZA PLUS LUX DARK EDITION (1199cc)",
          "XZA PLUS LUX DARK EDITION (1199cc)",
          "XZA PLUS LUXS (1199cc)",
          "XZA PLUS LUXS (1199cc)",
          "XZA PLUS LUXS KAZIRANGA (1199cc)",
          "XZA PLUS LUXS KAZIRANGA (1199cc)",
          "XZA PLUS LUXS DUAL TONE (1199cc)",
          "XZA PLUS LUXS DUAL TONE (1199cc)",
          "XZA PLUS LUXS DARK EDITION (1199cc)",
          "XZA PLUS LUXS DARK EDITION (1199cc)",
          "XZA PLUS DUAL TONE PREMIUM (1199cc)",
          "XZA PLUS DUAL TONE PREMIUM (1199cc)",
          "XZ PLUS DUAL TONE PREMIUM (1199cc)",
          "XZ PLUS DUAL TONE PREMIUM (1199cc)",
          "XZ PLUS L DARK EDITION (1199cc)",
          "XZ PLUS L DARK EDITION (1199cc)",
          "XZA PLUS L DARK EDITION (1199cc)",
          "XZA PLUS L DARK EDITION (1199cc)",
          "XZA PLUS L DUAL TONE (1199cc)",
          "XZA PLUS L DUAL TONE (1199cc)",
          "XZA PLUS L (1199cc)",
          "XZA PLUS L (1199cc)",
          "XZA PLUS HS DARK EDITION (1199cc)",
          "XZA PLUS HS DARK EDITION (1199cc)",
          "XZ PLUS PREMIUM JET DIESEL (1199cc)",
          "XZ PLUS PREMIUM JET DIESEL (1199cc)",
          "XZ PLUS DUAL TONE S (1199cc)",
          "XZ PLUS DUAL TONE S (1199cc)",
          "XZA PLUS S DARK EDITION (1199cc)",
          "XZA PLUS S DARK EDITION (1199cc)",
          "XZ PLUS P (1199cc)",
          "XZ PLUS P (1199cc)",
          "XZ PLUS (1199cc)",
          "XZ PLUS (1199cc)",
          "XZ PLUS DUAL TONE (1199cc)",
          "XZ PLUS DUAL TONE (1199cc)",
          "XZA PLUS O (1199cc)",
          "XZA PLUS (O) (1199cc)",
          "XZA PLUS O DUAL TONE (1199cc)",
          "XZA PLUS (O) DUAL TONE (1199cc)",
          "XZ PLUS O DUAL TONE (1199cc)",
          "XZ PLUS (O) DUAL TONE (1199cc)",
          "XZ PLUS (O) PETROL (1199cc)",
          "XZA PLUS (O) AMT PETROL (1199cc)",
          "XZ PLUS (O) DUAL TONE PETROL (1199cc)",
          "XZA PLUS (O) AMT DUAL TONE PETROL (1199cc)",
          "XMA (S) AMT PETROL (1199cc)",
          "XZ PLUS (S) PETROL (1199cc)",
          "XZ PLUS DUAL TONE (S) PETROL (1199cc)",
          "XZA PLUS (S) AMT PETROL (1199cc)",
          "XZA PLUS DUAL TONE (S) AMT PETROL (1199cc)",
          "XZA PLUS AMT PETROL (1199cc)",
          "XM (S) PETROL (1199cc)",
          "XZA PLUS DARK EDITION AMT PETROL (1199cc)",
          "XZ PLUS DARK EDITION PETROL (1199cc)",
          "XZA PLUS (O) DARK EDITION AMT PETROL (1199cc)",
          "XZ PLUS (O) DARK EDITION PETROL (1199cc)",
          "XZ PLUS (P) JET PETROL (1199cc)",
          "XZA PLUS (P) AMT JET PETROL (1199cc)",
          "XZA PLUS (L) AMT DARK EDITION PETROL (1199cc)",
          "XZ PLUS (L) PETROL (1199cc)",
          "XZ PLUS (L) DUAL TONE PETROL (1199cc)",
          "XZ PLUS (L) DARK EDITION PETROL (1199cc)",
          "XZA PLUS (L) AMT PETROL (1199cc)",
          "XZA PLUS (L) AMT DUAL TONE PETROL (1199cc)",
          "XZ PLUS PETROL (1199cc)",
          "XM PLUS (S) PETROL (1199cc)",
          "XMA PLUS (S) AMT PETROL (1199cc)",
          "XZA PLUS AMT (P) PETROL (1199cc)",
          "XZ PLUS (P) PETROL (1199cc)",
          "XZ PLUS (HS) DARK EDITION PETROL (1199cc)",
          "XZ PLUS (P) DUAL TONE PETROL (1199cc)",
          "XZ PLUS (HS) DUAL TONE PETROL (1199cc)",
          "XZ PLUS (P) DARK EDITION PETROL (1199cc)",
          "XZA PLUS AMT (P) DUAL TONE PETROL (1199cc)",
          "XZA PLUS AMT (P) DARK EDITION PETROL (1199cc)",
          "XZA PLUS AMT (HS) PETROL (1199cc)",
          "KAZIRANGA XZA PLUS AMT (P) PETROL (1199cc)",
          "KAZIRANGA XZ PLUS (P) PETROL (1199cc)",
          "XZ PLUS (HS) PETROL (1199cc)",
          "XZA PLUS PETROL (1199cc)",
          "XZA PLUS PETROL (1199cc)",
          "XMA PETROL (1199cc)",
          "XMA PETROL (1199cc)",
          "XZ PLUS 1.5 RTQ (1497cc)",
          "1.5 (1497cc)",
          "XZA PLUS AT (1497cc)",
          "XZ PLUS DIESEL BS6 (1497cc)",
          "XZA PLUS DIESEL S BS6 (1497cc)",
          "XMA S BS6. (1497cc)",
          "XZ PLUS S DIESEL BS6 (1497cc)",
          "XE PETRO 1.2L (1497cc)",
          "XZA PLUS P JET (1497cc)",
          "XZ PLUS S WITH REVOTORQ 1.5L D BS6 (1497cc)",
          "XZ PLUS LUX DIESEL (1497cc)",
          "XZ PLUS LUXS KAZIRANGA DIESEL (1497cc)",
          "XZ PLUS LUXS DARK EDITION DIESEL (1497cc)",
          "1.5 XZA PLUS (1497cc)",
          "1.5 XZA PLUS DUAL TONE (1497cc)",
          "1.5 DIESEL XZA PLUS KAZIRANGA (1497cc)",
          "1.5 DIESEL XMA (S) (1497cc)",
          "1.5 DIESEL XM (S) (1497cc)",
          "KRAZ PLUS (1497cc)",
          "1.5 DIESEL XZA PLUS (S) (1497cc)",
          "1.5 DIESEL XZA PLUS DUAL TONE (O) (1497cc)",
          "1.5 DIESEL XT PLUS (1497cc)",
          "1.5 DIESEL XZA PLUS (1497cc)",
          "1.5 DIESEL XZ PLUS (1497cc)",
          "1.5 DIESEL XZ PLUS DT (1497cc)",
          "1.5 DIESEL XT (1497cc)",
          "1.5 DIESEL XM (1497cc)",
          "1.5 DIESEL XE (1497cc)",
          "1.5 DIESEL XZA PLUS LUXS RED DARK AMT (1497cc)",
          "1.5 XZA PLUS JET AMT (1497cc)",
          "1.5 DIESEL XZ (1497cc)",
          "1.5 DIESEL XZ PLUS DUAL TONE ROOF (1497cc)",
          "1.5 DIESEL KRAZ (1497cc)",
          "1.5 DIESEL XMA (1497cc)",
          "1.5 DIESEL XZA PLUS LUX (1497cc)",
          "1.5 DIESEL XZ PLUS HS (1497cc)",
          "1.5 DIESEL XZA PLUS LUXS KAZIRANGA (1497cc)",
          "1.5 DIESEL XZA PLUS LUXS DT (1497cc)",
          "1.5 DIESEL XZA PLUS LUX DT (1497cc)",
          "1.5 DIESEL  XZ PLUS (L)  1497 CC (1497cc)",
          "1.5 DIESEL XZ PLUS S DARK (1497cc)",
          "1.5 DIESEL XZ PLUS S (1497cc)",
          "1.5 DIESEL XZ PLUS LUXS (1497cc)",
          "1.5 DIESEL XM PLUS (S) (1497cc)",
          "1.5 DIESEL XMA PLUS (S) AMT (1497cc)",
          "1.5 DIESEL  XZA PLUS (L)  1497 CC (1497cc)",
          "1.5 DIESEL XZA PLUS LUX DARK (1497cc)",
          "1.5 DIESEL XZ PLUS LUX (1497cc)",
          "1.5 DIESEL XZ PLUS LUX DT (1497cc)",
          "1.5 DIESEL XZ PLUS LUX DARK (1497cc)",
          "1.5 DIESEL XZ PLUS LUXS KAZIRANGA (1497cc)",
          "1.5 DIESEL XZ PLUS LUXS DT (1497cc)",
          "1.5 DIESEL XZ PLUS LUXS DARK (1497cc)",
          "1.5 DIESEL XZ PLUS (L) DARK (1497cc)",
          "1.5 DIESEL  XZ PLUS (P)  1497 CC (1497cc)",
          "1.5 DIESEL XZ PLUS (P) DT 1497 CC (1497cc)",
          "1.5 DIESEL XZ PLUS (P) DARK EDITION 1497 CC (1497cc)",
          "1.5 DIESEL XZ PLUS HS DT (1497cc)",
          "1.5 DIESEL XZ PLUS HS DARK (1497cc)",
          "1.5 DIESEL XZA PLUS HS DARK (1497cc)",
          "1.5 DIESEL XZ PLUS S DT (1497cc)",
          "1.5 DIESEL XZA PLUS LUXS (1497cc)",
          "1.5 DIESEL  XZA PLUS (P)  1497 CC (1497cc)",
          "1.5 DIESEL XZA PLUS (P) DT 1497 CC (1497cc)",
          "1.5 DIESEL XZA PLUS (P) DARK EDITION 1497 CC (1497cc)",
          "1.5 DIESEL XZ PLUS (O) (1497cc)",
          "1.5 DIESEL XZA PLUS (O) (1497cc)",
          "XZ PLUS  HS (1497cc)",
          "XT MT DIESEL (1497cc)",
          "XT MT DIESEL (1497cc)",
          "XZA PLUS (1497cc)",
          "KRAZ PLUS AMT DIESEL (1497cc)",
          "KRAZ PLUS AMT DIESEL (1497cc)",
          "XZ PLUS DIESEL S (1497cc)",
          "XZ PLUS DIESEL S (1497cc)",
          "XZA PLUS DIESEL S (1497cc)",
          "XZA PLUS DIESEL S (1497cc)",
          "XMA S DIESEL (1497cc)",
          "XMA S DIESEL (1497cc)",
          "NEXON XE 1.5 RTQ BS4 (1497cc)",
          "XZAPLUS(P) WITH REVOTORQ 1.5L BS6 (1497cc)",
          "(D) XZAPLUS DT P (1497cc)",
          "(D) XZA PLUS HS DARK (1497cc)",
          "(D) XZ PLUS P (1497cc)",
          "(D) XZ PLUSÂ HS (1497cc)",
          "(D) XZ PLUSÂ DT HS (1497cc)",
          "(P) XZ PLUS P (1497cc)",
          "XZ PLUS LUXS (1497cc)",
          "XZA PLUS (O) 1.5 RTQ BSVI (1497cc)",
          "XMA 1.5 RTQ BS-IV (1497cc)",
          "1.5 RTQ BS-IV (1497cc)",
          "XZ PLUS DIESEL LUX (1497cc)",
          "XZ PLUS DIESEL LUX (1497cc)",
          "XZ PLUS DUAL TONE HS DIESEL (1497cc)",
          "XZ PLUS DUAL TONE HS DIESEL (1497cc)",
          "XZ PLUS LUXS DIESEL DARK EDITION (1497cc)",
          "XZ PLUS LUXS DIESEL DARK EDITION (1497cc)",
          "XZ PLUS LUXS DIESEL KAZIRANGA (1497cc)",
          "XZ PLUS LUXS DIESEL KAZIRANGA (1497cc)",
          "XZA PLUS LUXS DIESEL DARK EDITION (1497cc)",
          "XZA PLUS LUXS DIESEL DARK EDITION (1497cc)",
          "XZ PLUS LUXS DIESEL JET (1497cc)",
          "XZA PLUS LUXS DIESEL JET (1497cc)",
          "PURE 1.5 REVOTORQ DIESEL 6MT (1497cc)",
          "PURE S 1.5 REVOTORQ DIESEL 6MT (1497cc)",
          "CREATIVE 1.5 REVOTORQ DIESEL 6MT (1497cc)",
          "CREATIVE 1.5 REVOTORQ DIESEL 6MT DT (1497cc)",
          "CREATIVE 1.5 REVOTORQ DIESEL 6AMT (1497cc)",
          "CREATIVE 1.5 REVOTORQ DIESEL 6AMT DT (1497cc)",
          "CREATIVE PLUS 1.5 REVOTORQ DIESEL 6MT (1497cc)",
          "CREATIVE PLUS 1.5 REVOTORQ DIESEL 6MT DT (1497cc)",
          "CREATIVE PLUS S 1.5 REVOTORQ DIESEL 6MT DT (1497cc)",
          "CREATIVE PLUS S 1.5 REVOTORQ DIESEL 6MT (1497cc)",
          "CREATIVE PLUS 1.5 REVOTORQ DIESEL 6AMT (1497cc)",
          "CREATIVE PLUS 1.5 REVOTORQ DIESEL 6AMT DT (1497cc)",
          "FEARLESS 1.5 REVOTORQ DIESEL 6MT DT (1497cc)",
          "FEARLESS PR 1.5 REVOTORQ DIESEL 6MT DT (1497cc)",
          "CREATIVE PLUS S 1.5 REVOTORQ DIESEL 6AMT DT (1497cc)",
          "CREATIVE PLUS S 1.5 REVOTORQ DIESEL 6AMT (1497cc)",
          "FEARLESS PLUS 1.5 REVOTORQ DIESEL 6MT DT (1497cc)",
          "FEARLESS PR PLUS 1.5 REVOTORQ DIESEL 6MT DT (1497cc)",
          "FEARLESS PR S 1.5 REVOTORQ DIESEL 6MT DT (1497cc)",
          "FEARLESS PR 1.5 REVOTORQ DIESEL 6AMT DT (1497cc)",
          "FEARLESS 1.5 REVOTORQ DIESEL 6AMT DT (1497cc)",
          "FEARLESS PR PLUS S 1.5 REVOTORQ DIESEL 6MT DT (1497cc)",
          "FEARLESS PLUS S 1.5 REVOTORQ DIESEL 6MT DT (1497cc)",
          "FEARLESS PR PLUS 1.5 REVOTORQ DIESEL 6AMT DT (1497cc)",
          "FEARLESS PLUS 1.5 REVOTORQ DIESEL 6AMT DT (1497cc)",
          "FEARLESS PR S 1.5 REVOTORQ DIESEL 6AMT DT (1497cc)",
          "FEARLESS PR PLUS S 1.5 REVOTORQ DIESEL 6AMT DT (1497cc)",
          "XM 1.5 RTQ (1497cc)",
          "XZA PLUS DIESEL DUAL TONE S (1497cc)",
          "XZA PLUS DIESEL DUAL TONE S (1497cc)",
          "XZ PLUS DIESEL DARK EDITION (1497cc)",
          "XZ PLUS DIESEL DARK EDITION (1497cc)",
          "XZA PLUS O DIESEL DARK EDITION (1497cc)",
          "XZA PLUS O DIESEL DARK EDITION (1497cc)",
          "XZ PLUS O DIESEL DARK EDITION (1497cc)",
          "XZ PLUS O DIESEL DARK EDITION (1497cc)",
          "XZA PLUS DIESEL DARK EDITION (1497cc)",
          "XZA PLUS DIESEL DARK EDITION (1497cc)",
          "KRAZ MT DIESEL (1497cc)",
          "KRAZ MT DIESEL (1497cc)",
          "KAZIRANGA XZA PLUS (P) DIESEL. (1497cc)",
          "KAZIRANGA XZA PLUS P DIESEL (1497cc)",
          "XMA PLUS S DIESEL (1497cc)",
          "XMA PLUS S DIESEL (1497cc)",
          "XZ PLUS L DIESEL DARK EDITION (1497cc)",
          "XZ PLUS L DIESEL DARK EDITION (1497cc)",
          "XZ PLUS PREMIUM DIESEL (1497cc)",
          "XZ PLUS PREMIUM DIESEL (1497cc)",
          "XZA PLUS HS DIESEL DARK EDITION (1497cc)",
          "XZA PLUS HS DIESEL DARK EDITION (1497cc)",
          "XZA PLUS DUAL TONE PREMIUM DIESEL (1497cc)",
          "XZA PLUS DUAL TONE PREMIUM DIESEL (1497cc)",
          "XZA PLUS PREMIUM DIESEL DARK EDITION (1497cc)",
          "XZA PLUS PREMIUM DIESEL DARK EDITION (1497cc)",
          "XZA PLUS PREMIUM JET DIESEL (1497cc)",
          "XZA PLUS PREMIUM JET DIESEL (1497cc)",
          "XZ PLUS LUX DIESEL DUAL TONE (1497cc)",
          "XZ PLUS LUX DIESEL DUAL TONE (1497cc)",
          "XZ PLUS LUX DIESEL DARK EDITION (1497cc)",
          "XZ PLUS LUX DIESEL DARK EDITION (1497cc)",
          "XZ PLUS LUXS DIESEL (1497cc)",
          "XZ PLUS LUXS DIESEL (1497cc)",
          "XZA PLUS LUX DIESEL (1497cc)",
          "XZA PLUS LUX DIESEL (1497cc)",
          "XZ PLUS LUXS DIESEL DUAL TONE (1497cc)",
          "XZ PLUS LUXS DIESEL DUAL TONE (1497cc)",
          "XZA PLUS LUX DIESEL DUAL TONE (1497cc)",
          "XZA PLUS LUX DIESEL DUAL TONE (1497cc)",
          "XZA PLUS LUX DIESEL DARK EDITION (1497cc)",
          "XZA PLUS LUX DIESEL DARK EDITION (1497cc)",
          "XZA PLUS LUXS DIESEL (1497cc)",
          "XZA PLUS LUXS DIESEL (1497cc)",
          "XZA PLUS LUXS DIESEL KAZIRANGA (1497cc)",
          "XZA PLUS LUXS DIESEL KAZIRANGA (1497cc)",
          "XZA PLUS LUXS DIESEL DUAL TONE (1497cc)",
          "XZA PLUS LUXS DIESEL DUAL TONE (1497cc)",
          "XZA PLUS PREMIUM JET (1497cc)",
          "XZA PLUS PREMIUM JET (1497cc)",
          "XZA PLUS PREMIUM DIESEL (1497cc)",
          "XZA PLUS PREMIUM DIESEL (1497cc)",
          "XZ PLUS PREMIUM DIESEL DARK EDITION (1497cc)",
          "XZ PLUS PREMIUM DIESEL DARK EDITION (1497cc)",
          "XZA PLUS L DIESEL DARK EDITION (1497cc)",
          "XZA PLUS L DIESEL DARK EDITION (1497cc)",
          "XZA PLUS L DUAL TONE DIESEL (1497cc)",
          "XZA PLUS L DUAL TONE DIESEL (1497cc)",
          "XZA PLUS L DIESEL (1497cc)",
          "XZA PLUS L DIESEL (1497cc)",
          "XZ PLUS HS DIESEL DARK EDITION (1497cc)",
          "XZ PLUS HS DIESEL DARK EDITION (1497cc)",
          "XZ PLUS S DIESEL DARK EDITION (1497cc)",
          "XZ PLUS S DIESEL DARK EDITION (1497cc)",
          "XZ PLUS DUAL TONE PREMIUM DIESEL (1497cc)",
          "XZ PLUS DUAL TONE PREMIUM DIESEL (1497cc)",
          "XZ PLUS DIESEL DUAL TONE S (1497cc)",
          "XZ PLUS DIESEL DUAL TONE S (1497cc)",
          "XZA PLUS (O) DIESEL (1497cc)",
          "XZA PLUS DIESEL DUAL TONE (1497cc)",
          "XZA PLUS DIESEL DUAL TONE (1497cc)",
          "XZ PLUS DIESEL DUAL TONE (1497cc)",
          "XZ PLUS DIESEL DUAL TONE (1497cc)",
          "XZ PLUS DUAL TONE (1497cc)",
          "XZ PLUS (O) DIESEL DUAL TONE (1497cc)",
          "XZA PLUS (O) DIESEL DUAL TONE (1497cc)",
          "XM DIESEL (1497cc)",
          "XM DIESEL (1497cc)",
          "KRAZ DIESEL (1497cc)",
          "KRAZ DIESEL (1497cc)",
          "XZ PLUS DUAL TONE ROOF DIESEL (1497cc)",
          "XZA PLUS DUAL TONE DIESEL (1497cc)",
          "XMA AMT DIESEL (1497cc)",
          "XMA AMT (S) DIESEL (1497cc)",
          "XZA PLUS DUAL TONE (S) AMT DIESEL (1497cc)",
          "XZ PLUS DUAL TONE (S) DIESEL (1497cc)",
          "XZA PLUS (O) DARK EDITION AMT DIESEL (1497cc)",
          "XZ PLUS (O) DARK EDITION DIESEL (1497cc)",
          "XZA PLUS DARK EDITION AMT DIESEL (1497cc)",
          "XZ PLUS DARK EDITION DIESEL (1497cc)",
          "XZA PLUS (S) AMT DIESEL (1497cc)",
          "XZ PLUS (P) JET DIESEL (1497cc)",
          "XZA PLUS (P) AMT JET DIESEL (1497cc)",
          "XZA PLUS (L) AMT DUAL TONE DIESEL (1497cc)",
          "XZ PLUS (L) DARK EDITION DIESEL (1497cc)",
          "XZA PLUS (L) AMT DARK EDITION DIESEL (1497cc)",
          "XZA PLUS (L) AMT DIESEL (1497cc)",
          "XMA PLUS (S) AMT DIESEL (1497cc)",
          "XZ PLUS (P) DUAL TONE DIESEL (1497cc)",
          "XZA PLUS (HS) AMT DARK EDITION DIESEL (1497cc)",
          "XZA PLUS AMT (P) DUAL TONE DIESEL (1497cc)",
          "XZ PLUS (HS) DARK EDITION DIESEL (1497cc)",
          "XZA PLUS (P) AMT DIESEL (1497cc)",
          "XZ PLUS (P) DARK EDITION DIESEL (1497cc)",
          "XZA PLUS AMT (P) DARK EDITION DIESEL (1497cc)",
          "XZ PLUS (P) DIESEL (1497cc)",
          "XZ PLUS (HS) DUAL TONE DIESEL (1497cc)",
          "KAZIRANGA XZA PLUS AMT (P) DIESEL (1497cc)",
          "XT DIESEL (1497cc)",
          "XT DIESEL (1497cc)",
          "XZ PLUS HS DIESEL (1497cc)",
          "XZ PLUS HS DIESEL (1497cc)",
          "XZ PLUS L DIESEL (1497cc)",
          "XZ PLUS L DIESEL (1497cc)",
          "XZ PLUS L DUAL TONE DIESEL (1497cc)",
          "XZ PLUS L DUAL TONE DIESEL (1497cc)",
          "XZ PLUS S DIESEL (1497cc)",
          "XZ PLUS S DIESEL (1497cc)",
          "XZA PLUS DIESEL (1497cc)",
          "XZA PLUS DIESEL (1497cc)",
          "XM S DIESEL (1497cc)",
          "XM S DIESEL (1497cc)",
          "KAZIRANGA XZ PLUS (P) DIESEL. (1497cc)",
          "KAZIRANGA XZ PLUS P DIESEL (1497cc)",
          "XT PLUS DIESEL. (1497cc)",
          "XT PLUS DIESEL (1497cc)",
          "XM PLUS S DIESEL (1497cc)",
          "XM PLUS S DIESEL (1497cc)",
          "XZ PLUS (O) DIESEL (1497cc)",
          "XZ PLUS DIESEL (1497cc)",
          "XZ PLUS DIESEL (1497cc)",
          "XE DIESEL (1497cc)",
          "XE DIESEL (1497cc)",
          "XZ DIESEL (1497cc)",
          "XZ DIESEL (1497cc)",
          "XMA DIESEL (1497cc)",
          "XMA DIESEL (1497cc)",
          "KRAZ PLUS DIESEL (1497cc)",
          "KRAZ PLUS DIESEL (1497cc)",
          "XZ PLUS DT (1198cc)",
          "1.2 (1198cc)",
          "REVOTRON XZ (1198cc)",
          "XT (1198cc)",
          "XT (1198cc)",
          "XZ+(P) WITH 120PS REVOTRON 1.2 PETROL ENGINE, BS6 (1198cc)",
          "XZ+(P) WITH 120PS REVOTRON 1.2 PETROL ENGINE, BSVI (1198cc)",
          "XM 1.2 RTN (1198cc)",
          "1.2 XZA PLUS (1198cc)",
          "1.2 XZA PLUS DUAL TONE (1198cc)",
          "1.2 PETROL XT PLUS (1198cc)",
          "1.2 PETROL XZA PLUS AMT (1198cc)",
          "1.2 PETROL KRAZ PLUS (1198cc)",
          "1.2 PETROL XT (1198cc)",
          "1.2 PETROL XZ PLUS (1198cc)",
          "1.2 PETROL XZ PLUS DT (1198cc)",
          "1.2 PETROL XE (1198cc)",
          "1.2 PETROL XM (1198cc)",
          "1.2 PETROL XZ (1198cc)",
          "1.2 PETROL XZA PLUS (1198cc)",
          "1.2 PETROL XMA (1198cc)",
          "1.2 PETROL KRAZ (1198cc)",
          "XT MT (1198cc)",
          "XT MT (1198cc)",
          "KRAZ MT PETROL (1198cc)",
          "KRAZ MT PETROL (1198cc)",
          "XZA PLUS PETROL DUAL TONE (1198cc)",
          "XT PLUS (1198cc)",
          "XT PLUS (1198cc)",
          "KRAZ PLUS AMT PETROL (1198cc)",
          "KRAZ PLUS AMT PETROL (1198cc)",
          "XM 1.2 RTN BS4 (1198cc)",
          "XZA PLUS (HS) DT WITH 1.2PS (1198cc)",
          "XZ PLUS 1.2 RTN BS-IV (1198cc)",
          "XE (1198cc)",
          "XE (1198cc)",
          "XM (1198cc)",
          "XM (1198cc)",
          "XZ (1198cc)",
          "XZ (1198cc)",
          "XZ PLUS (1198cc)",
          "XZ PLUS (1198cc)",
          "XZ PLUS DUAL TONE (1198cc)",
          "XZ PLUS DUAL TONE (1198cc)",
          "XZA PLUS DUAL TONE PETROL (1198cc)",
          "XZ PLUS DUAL TONE ROOF PETROL (1198cc)",
          "XZ PLUS DUAL TONE PETROL (1198cc)",
          "XM PETROL (1198cc)",
          "XT PETROL (1198cc)",
          "XZ PETROL (1198cc)",
          "XE PETROL (1198cc)",
          "KRAZ PETROL (1198cc)",
          "KRAZ PETROL (1198cc)",
          "XT PLUS PETROL. (1198cc)",
          "KRAZ PLUS PETROL (1198cc)",
          "KRAZ PLUS PETROL (1198cc)",
          "XZA PLUS PETROL (1198cc)",
          "XZA PLUS PETROL (1198cc)",
          "XMA PETROL (1198cc)",
          "XMA PETROL (1198cc)",
          "MAX EV XZ PLUS (97000cc)",
          "XZ PLUS LUX (97000cc)",
          "XZ PLUS LUX EV (1501cc)",
          "XZ PLUS EV (1501cc)",
          "XM EV (1501cc)",
          "XZ PLUS DARK EV (1501cc)",
          "XZ PLUS LUX DARK EV (1501cc)",
          "XZ PLUS LUX EV MAX FAST CHARGER (1501cc)",
          "XZ PLUS EV MAX FAST CHARGER (1501cc)",
          "XZ PLUS LUX EV MAX (1501cc)",
          "XZ PLUS EV MAX (1501cc)",
          "XZ PLUS LUX EV MAX JET (1501cc)",
          "EV XZ PLUS PRIME (1501cc)",
          "1.5 DIESEL XZ PLUS  KAZIRANGA (1499cc)",
          "(D) XZ PLUSÂ HS (1500cc)",
          "XZA PLUS O DIESEL (1496cc)",
          "XZA PLUS O DIESEL (1496cc)",
          "XZA PLUS DIESEL DUAL TONE (1496cc)",
          "XZA PLUS DIESEL DUAL TONE (1496cc)",
          "XZ PLUS DIESEL DUAL TONE (1496cc)",
          "XZ PLUS DIESEL DUAL TONE (1496cc)",
          "XZ PLUS O DIESEL DUAL TONE (1496cc)",
          "XZ PLUS O DIESEL DUAL TONE (1496cc)",
          "XZA PLUS O DIESEL DUAL TONE (1496cc)",
          "XZA PLUS O DIESEL DUAL TONE (1496cc)",
          "XZA PLUS (O) AMT DUAL TONE DIESEL (1496cc)",
          "XZ PLUS (O) DUAL TONE DIESEL (1496cc)",
          "XZA PLUS (O) AMT DIESEL (1496cc)",
          "XZA PLUS DIESEL (1496cc)",
          "XZA PLUS DIESEL (1496cc)",
          "XM DIESEL (1496cc)",
          "XM DIESEL (1496cc)",
          "XZ PLUS O DIESEL (1496cc)",
          "XZ PLUS O DIESEL (1496cc)",
          "XZ PLUS DIESEL (1496cc)",
          "XZ PLUS DIESEL (1496cc)",
          "XE DIESEL (1496cc)",
          "XE DIESEL (1496cc)",
          "XZ DIESEL (1496cc)",
          "XZ DIESEL (1496cc)",
          "XMA DIESEL (1496cc)",
          "XMA DIESEL (1496cc)",
          "1.2 REVOTRON XZA PLUS (1198cc)",
        ],
      },
      {
        model: "PUNCH",
        variants: [
          "PURE RHYTHM PACK MT (1199cc)",
          "KAZIRANGA MT (1199cc)",
          "KAZIRANGA IRA MT (1199cc)",
          "KAZIRANGA AMT (1199cc)",
          "KAZIRANGA IRA AMT (1199cc)",
          "CREATIVE ST (1199cc)",
          "CREATIVE IRA ST (1199cc)",
          "CREATIVE AMT ST (1199cc)",
          "CREATIVE IRA AMT ST (1199cc)",
          "CAMO ADVENTURE (1199cc)",
          "CAMO ADVENTURE RHYTHM (1199cc)",
          "CAMO ACCOMPLISHED (1199cc)",
          "CAMO ACCOMPLISHED DAZZLE (1199cc)",
          "CREATIVE DT (1199cc)",
          "CREATIVE IRA DT (1199cc)",
          "CREATIVE AMT DT (1199cc)",
          "CREATIVE IRA AMT DT (1199cc)",
          "ACCOMPLISHED DAZZLE AMT (1199cc)",
          "CREATIVE IRA AMT (1199cc)",
          "CREATIVE IRA PACK (1199cc)",
          "CREATIVE IRA PACK (1199cc)",
          "ACCOMPLISHED DAZZLE PACK (1199cc)",
          "ACCOMPLISHED DAZZLE PACK (1199cc)",
          "ADVENTURE RHYTHM PACK (1199cc)",
          "ADVENTURE RHYTHM PACK (1199cc)",
          "ADVENTURE RHYTHM AMT (1199cc)",
          "PURE MT (1199cc)",
          "PURE MT (1199cc)",
          "ADVENTURE MT (1199cc)",
          "ADVENTURE MT (1199cc)",
          "ACCOMPLISHED MT (1199cc)",
          "ACCOMPLISHED MT (1199cc)",
          "CREATIVE MT (1199cc)",
          "CREATIVE MT (1199cc)",
          "ADVENTURE AMT (1199cc)",
          "ADVENTURE AMT (1199cc)",
          "ACCOMPLISHED AMT (1199cc)",
          "ACCOMPLISHED AMT (1199cc)",
          "CREATIVE AMT (1199cc)",
          "CREATIVE AMT (1199cc)",
          "CAMO ADVENTURE AMT (1199cc)",
          "CAMO ADVENTURE AMT (1199cc)",
          "CAMO ADVENTURE RHYTHM AMT (1199cc)",
          "CAMO ADVENTURE RHYTHM AMT (1199cc)",
          "CAMO ACCOMPLISHED AMT (1199cc)",
          "CAMO ACCOMPLISHED AMT (1199cc)",
          "CAMO ACCOMPLISHED DAZZLE AMT (1199cc)",
          "CAMO ACCOMPLISHED DAZZLE AMT (1199cc)",
          "ADV 1.2P BS6 MT (1199cc)",
          "PURE (1199cc)",
          "PURE RHYTHM (1199cc)",
          "ADVENTURE RHYTHM (1199cc)",
          "ACCOMPLISHED (1199cc)",
          "ACCOMPLISHED DAZZLE (1199cc)",
          "CREATIVE (1199cc)",
          "CREATIVE IRA (1199cc)",
          "ADVENTURE CAMO MT (1199cc)",
          "ADVENTURE RHYTHM CAMO MT (1199cc)",
          "ACCOMPLISHED CAMO MT (1199cc)",
          "ACCOMPLISHED DAZZLE CAMO MT (1199cc)",
          "CREATIVE DUAL TONE MT (1199cc)",
          "CREATIVE DUAL TONE AMT (1199cc)",
          "CREATIVE IRA DUAL TONE AMT (1199cc)",
          "ICNG (1199cc)",
          "ADVENTURE ICNG (1199cc)",
          "1.2 REVOTRON PETROL PURE (1199cc)",
          "1.2 REVOTRON PETROL PURE RHYTHM PACK (1199cc)",
          "1.2 REVOTRON PETROL ADVENTURE (1199cc)",
          "1.2 REVOTRON PETROL ADVENTURE RHYTHM PACK (1199cc)",
          "1.2 REVOTRON PETROL ACCOMPLISHED (1199cc)",
          "1.2 REVOTRON PETROL ACCOMPLISHED DAZZLE PACK (1199cc)",
          "1.2 REVOTRON PETROL CREATIVE (1199cc)",
          "1.2 REVOTRON PETROL CREATIVE IRA PACK (1199cc)",
          "1.2 REVOTRON PETROL ADVENTURE AT (1199cc)",
          "1.2 REVOTRON PETROL ADVENTURE RHYTHM PACK AT (1199cc)",
          "1.2 REVOTRON PETROL ACCOMPLISHED AT (1199cc)",
          "1.2 REVOTRON PETROL ACCOMPLISHED DAZZLE PACK AT (1199cc)",
          "1.2 REVOTRON PETROL CREATIVE AT (1199cc)",
          "1.2 REVOTRON PETROL CREATIVE IRA PACK AT (1199cc)",
          "1.2 REVOTRON PETROL  KAZIRANGA CREATIVE IRA (1199cc)",
          "1.2 REVOTRON PETROL KAZIRANGA CREATIVE (1199cc)",
          "1.2 REVOTRON PETROL AT  KAZIRANGA CREATIVE IRA (1199cc)",
          "1.2 REVOTRON PETROL AT KAZIRANGA CREATIVE (1199cc)",
          "1.2 REVOTRON PETROL ADVENTURE RHYTHM CAMO (1199cc)",
          "1.2 REVOTRON PETROL ACCOMPLISHED AMT (1199cc)",
          "1.2 REVOTRON PETROL ADVENTURE CAMO AMT (1199cc)",
          "1.2 REVOTRON PETROL ADVENTURE AMT (1199cc)",
          "1.2 REVOTRON PETROL ACCOMPLISHED DAZZLE CAMO (1199cc)",
          "1.2 REVOTRON PETROL CREATIVE DT (1199cc)",
          "1.2 REVOTRON PETROL CREATIVE IRA PACK DT (1199cc)",
          "1.2 REVOTRON PETROL ADVENTURE RHYTHM CAMO AMT (1199cc)",
          "1.2 REVOTRON PETROL ACCOMPLISHED CAMO AMT (1199cc)",
          "1.2 REVOTRON PETROL ACCOMPLISHED DAZZLE CAMO AMT (1199cc)",
          "1.2 REVOTRON PETROL CREATIVE DT AMT (1199cc)",
          "1.2 REVOTRON PETROL CREATIVE IRA PACK DT AMT (1199cc)",
          "1.2 REVOTRON PETROL CREATIVE AMT (1199cc)",
          "1.2 REVOTRON PETROL ACCOMPLISHED CAMO (1199cc)",
          "1.2 REVOTRON PETROL ADVENTURE CAMO (1199cc)",
          "ADVENTURE MT (P) (1199cc)",
          "CREATIVE (IRA PACK)1.2 MT (1199cc)",
          "CREATIVE (IRA PACK)1.2 AMT (1199cc)",
          "1.2L PETROL AMT BS6 (1199cc)",
          "ACCOMPLISHED AT (1199cc)",
          "REVOTRON 1.2L MT BS6 EMISSION (1199cc)",
          "KZR AMT (1199cc)",
          "KZR (1199cc)",
          "KAZIRANGA REVOTRON  AMT BSVI (1199cc)",
          "1.2P BS6 MT (1199cc)",
          "CREATIVE MT DUAL TONE (1199cc)",
          "CREATIVE MT DUAL TONE (1199cc)",
          "CREATIVE AMT DUAL TONE (1199cc)",
          "CREATIVE AMT DUAL TONE (1199cc)",
          "CREATIVE IRA AMT DUAL TONE (1199cc)",
          "CREATIVE IRA AMT DUAL TONE (1199cc)",
          "PURE RHYTHM PACK (1199cc)",
          "PURE RHYTHM PACK (1199cc)",
          "ADVENTURE (1199cc)",
          "CREATIVE IRA DUAL TONE (1199cc)",
          "CREATIVE IRA DUAL TONE (1199cc)",
          "ACCOMPLISHED DAZZLE S CNG (1199cc)",
          "ACCOMPLISHED ICNG (1199cc)",
          "ADVENTURE RHYTHM ICNG (1199cc)",
          "PURE ICNG (1199cc)",
          "CREATIVE IRA PACK MT (1199cc)",
          "ACCOMPLISHED DAZZLE PACK MT (1199cc)",
          "ADVENTURE RHYTHM MT (1199cc)",
          "PURE RHYTHM MT (1199cc)",
          "ACCOMPLISHED DAZZLE PACK AMT (1199cc)",
          "ACCOMPLISHED DAZZLE PACK AMT (1199cc)",
          "ADVENTURE RHYTHM PACK AMT (1199cc)",
          "ADVENTURE RHYTHM PACK AMT (1199cc)",
          "CREATIVE IRA PACK AMT (1199cc)",
          "CREATIVE IRA PACK AMT (1199cc)",
          "CAMO ADVENTURE RHYTHM MT (1199cc)",
          "CAMO ADVENTURE RHYTHM MT (1199cc)",
          "CAMO ACCOMPLISHED MT (1199cc)",
          "CAMO ACCOMPLISHED MT (1199cc)",
          "CAMO ACCOMPLISHED DAZZLE MT (1199cc)",
          "CAMO ACCOMPLISHED DAZZLE MT (1199cc)",
          "KAZIRANGA CREATIVE MT (1199cc)",
          "KAZIRANGA CREATIVE MT (1199cc)",
          "KAZIRANGA CREATIVE AMT (1199cc)",
          "KAZIRANGA CREATIVE AMT (1199cc)",
          "CAMO ADVENTURE MT (1199cc)",
          "CAMO ADVENTURE MT (1199cc)",
          "KAZIRANGA CREATIVE IRA PACK AMT (1199cc)",
          "KAZIRANGA CREATIVE IRA PACK AMT (1199cc)",
          "KAZIRANGA CREATIVE IRA PACK MT (1199cc)",
          "KAZIRANGA CREATIVE IRA PACK MT (1199cc)",
          "UNCH KZR IRA AMT (1999cc)",
        ],
      },
      {
        model: "ZEST",
        variants: [
          "XM REVOTRON (1193cc)",
          "QUADRAJET XM (1248cc)",
          "QUADRAJET XMA (1248cc)",
          "QUADRAJET XTA (1248cc)",
          "REVOTRON XE (1193cc)",
          "REVOTRON XM (1193cc)",
          "REVOTRON XMS (1193cc)",
          "REVOTRON XT (1193cc)",
          "XE REVOTRON (1193cc)",
          "XMS REVOTRON (1193cc)",
          "XT QUADRAJET (1248cc)",
          "XT REVOTRON (1193cc)",
          "XE QUADRAJET (1248cc)",
          "XM QUADRAJET (1248cc)",
          "PREMIO QUADRAJET (1248cc)",
          "XMS QUADRAJET (1248cc)",
          "QUADRAJET XE (1248cc)",
          "QUADRAJET XMS (1248cc)",
          "QUADRAJET XT (1248cc)",
          "XE REVOTRON 90 PS PETROL (1193cc)",
          "XM REVOTRON 90 PS PETROL (1193cc)",
          "XMS REVOTRON 90 PS PETROL (1193cc)",
          "XT REVOTRON 90 PS PETROL (1193cc)",
          "XE QUADRAJET 75 PS DIESEL (1248cc)",
          "XM QUADRAJET 90 PS DIESEL (1248cc)",
          "XMA QUADRAJET 90 PS DIESEL (1248cc)",
          "XMS QUADRAJET 90 PS DIESEL (1248cc)",
          "XT QUADRAJET 90 PS DIESEL (1248cc)",
          "XMS DIESEL (1248cc)",
          "XE DIESEL (1248cc)",
          "QUADRAJET 1.3 XTA (1248cc)",
          "QUADRAJET 1.3 XTA (1248cc)",
          "XM DIESEL (1248cc)",
          "XM DIESEL (1248cc)",
          "XMA DIESEL (1248cc)",
          "XMA DIESEL (1248cc)",
          "XT DIESEL (1248cc)",
          "XT DIESEL (1248cc)",
          "ANNIVERSARY EDITION (1248cc)",
          "XT (1248cc)",
          "XMS (1248cc)",
          "XM (1248cc)",
          "XE (1248cc)",
          "XMA (1248cc)",
          "XTA (1248cc)",
          "XMA DIESEL AT (1248cc)",
          "QUADRAJET 1.3 PREMIO (1248cc)",
          "XE QUADRAJET 90 PS DIESEL (1248cc)",
          "PREMIO XM QUADRAJET 75 PS DIESEL (1248cc)",
          "PREMIO (1248cc)",
          "PREMIO (1248cc)",
          "XE QJET (1248cc)",
          "XMS DIESEL ANNIVERSARY LE (1248cc)",
          "XMS DIESEL ANNIVERSARY LE (1248cc)",
          "XE 75 PS DIESEL (1248cc)",
          "XE 75 PS DIESEL (1248cc)",
          "XM 75 PS DIESEL (1248cc)",
          "XM 75 PS DIESEL (1248cc)",
          "XMS 75 PS DIESEL (1248cc)",
          "XMS 75 PS DIESEL (1248cc)",
          "XTA DIESEL (1248cc)",
          "XTA DIESEL (1248cc)",
          "XMS 90 PS DIESEL (1248cc)",
          "XMS 90 PS DIESEL (1248cc)",
          "XM 90 PS DIESEL (1248cc)",
          "XM 90 PS DIESEL (1248cc)",
          "PREMIO QUADRAJET 75PS DIESEL (1248cc)",
          "XMS ANNIVERSARY LE DIESEL (1248cc)",
          "XMS QUADRAJET 75 PS DIESEL (1248cc)",
          "XM QUADRAJET 75 PS DIESEL (1248cc)",
          "XTA QUADRAJET 90 PS DIESEL (1248cc)",
          "XE PETROL (1193cc)",
          "XE PETROL (1193cc)",
          "XM PETROL (1193cc)",
          "XM PETROL (1193cc)",
          "XMS PETROL (1193cc)",
          "XMS PETROL (1193cc)",
          "XT PETROL (1193cc)",
          "XT PETROL (1193cc)",
          "ANNIVERSARY EDITION (1193cc)",
          "XT (1193cc)",
          "XMS (1193cc)",
          "XM (1193cc)",
          "XE (1193cc)",
          "XT PETROL MT (1193cc)",
          "XT 1.2 RT 90 PS BSIV (1193cc)",
          "1.2 RT ABS XMS (1193cc)",
          "XMS PETROL ANNIVERSARY LE (1193cc)",
          "XMS PETROL ANNIVERSARY LE (1193cc)",
          "XM 1.2 RT 90PS BSIV (1193cc)",
          "XMS ANNIVERSARY LE PETROL (1193cc)",
        ],
      },
      {
        model: "BOLT",
        variants: [
          "1.3 XE QUADRAJET (1248cc)",
          "1.3 XM QUADRAJET (1248cc)",
          "1.3 XMS QUADRAJET (1248cc)",
          "1.3 XT QUADRAJET (1248cc)",
          "1.2T XE REVOTRON (1193cc)",
          "1.2T XM REVOTRON (1193cc)",
          "1.2T XMS REVOTRON (1193cc)",
          "1.2T XT REVOTRON (1193cc)",
          "XE QUADRAJET (1248cc)",
          "XE REVOTRON (1193cc)",
          "XM QUADRAJET (1248cc)",
          "XM REVOTRON (1193cc)",
          "XMS QUADRAJET (1248cc)",
          "XMS REVOTRON (1193cc)",
          "XT QUADRAJET (1248cc)",
          "XT REVOTRON (1193cc)",
          "QUADRAJET 1.3 XE (1248cc)",
          "QUADRAJET 1.3 XM (1248cc)",
          "QUADRAJET 1.3 XMS (1248cc)",
          "QUADRAJET 1.3 XT (1248cc)",
          "REVOTRON 1.2T XE (1248cc)",
          "REVOTRON 1.2T XM (1248cc)",
          "REVOTRON 1.2T XMS (1248cc)",
          "REVOTRON 1.2T XT (1248cc)",
          "XMS REVOTRON 90 PS PETROL (1193cc)",
          "XM REVOTRON 90 PS PETROL (1193cc)",
          "XE REVOTRON 90 PS PETROL (1193cc)",
          "XT QUADRAJET 75 PS DIESEL (1248cc)",
          "XMS QUADRAJET 75 PS DIESEL (1248cc)",
          "XM QUADRAJET 75 PS DIESEL (1248cc)",
          "XE QUADRAJET 75 PS DIESEL (1248cc)",
          "XT REVOTRON 90 PS PETROL (1193cc)",
          "QUADRAJET XE (1248cc)",
          "QUADRAJET XM (1248cc)",
          "QUADRAJET XMS (1248cc)",
          "QUADRAJET XT (1248cc)",
          "XT (1248cc)",
          "XMS (1248cc)",
          "XE (1248cc)",
          "XM (1248cc)",
          "SPORTS (1248cc)",
          "SPORT (1248cc)",
          "SPORT (1248cc)",
          "XE DIESEL (1248cc)",
          "XE DIESEL (1248cc)",
          "XM DIESEL (1248cc)",
          "XM DIESEL (1248cc)",
          "XMS DIESEL (1248cc)",
          "XMS DIESEL (1248cc)",
          "XT DIESEL (1248cc)",
          "XT DIESEL (1248cc)",
          "XT (1193cc)",
          "XMS (1193cc)",
          "XE (1193cc)",
          "XM (1193cc)",
          "REVOTRON XM (1193cc)",
          "REVOTRON XT (1193cc)",
          "REVOTRON XE (1193cc)",
          "REVOTRON XMS (1193cc)",
          "XE PETROL (1193cc)",
          "XE PETROL (1193cc)",
          "XM PETROL (1193cc)",
          "XM PETROL (1193cc)",
          "XMS PETROL (1193cc)",
          "XMS PETROL (1193cc)",
          "XT PETROL (1193cc)",
          "XT PETROL (1193cc)",
        ],
      },
      {
        model: "ALTROZ",
        variants: [
          "1.2 REVOTRON XE BSVI (1199cc)",
          "1.2 REVOTRON XM BSVI (1199cc)",
          "1.2 REVOTRON XT BSVI (1199cc)",
          "1.2 REVOTRON XZ BSVI (1199cc)",
          "1.2 REVOTRON XZ(O)BSVI (1199cc)",
          "1.5 REVOTORQ XE BSVI (1497cc)",
          "1.5 REVOTORQ XM BSVI (1497cc)",
          "1.5 REVOTORQ XT BSVI (1497cc)",
          "1.5 REVOTORQ XZ BSVI (1497cc)",
          "1.5 REVOTORQ XZ(O) BSVI (1497cc)",
          "1.2 REVOTRON XE RHYTM BSVI (1199cc)",
          "1.2 REVOTRON XM STYLE BSVI (1199cc)",
          "1.2 REVOTRON XM RHYTM BSVI (1199cc)",
          "1.2 XM RHYTM PLUS STYLE BSVI (1199cc)",
          "1.2 REVOTRON XT LUXE BSVI (1199cc)",
          "1.5 REVOTORQ XE RHYTM BSVI (1497cc)",
          "1.2 REVOTRON XZ URBAN BSVI (1199cc)",
          "1.5 REVOTORQ XM RHYTM BSVI (1497cc)",
          "1.5 XM RHYTM PLUS STYLE BSVI (1497cc)",
          "1.5 REVOTORQ XT LUXE BSVI (1497cc)",
          "1.5 REVOTORQ XZ URBAN BSVI (1497cc)",
          "1.2 ITURBO XT BSVI (1199cc)",
          "1.2 XZ PLUS RTN BS6 (1199cc)",
          "1.2 ITURBO XZ BSVI (1199cc)",
          "1.2 REVOTRON XM PLUS BSVI (1199cc)",
          "1.5 REVOTORQ XZ PLUS BSVI (1497cc)",
          "1.2 REVOTRON XE PLUS BSVI (1199cc)",
          "1.5 REVOTORQ XE PLUS BSVI (1497cc)",
          "1.2 REVOTRON XT DARK BSVI (1199cc)",
          "1.2 REVOTRON XTA DCA BSVI (1199cc)",
          "1.2 REVOTRON XZ PLUS DARK BSVI (1199cc)",
          "1.2 REVOTRON XTA DARK DCA BSVI (1199cc)",
          "1.2 REVOTRON XZA DCA BSVI (1199cc)",
          "1.2 REVOTRON XZA (O)DCA BSVI (1199cc)",
          "1.2 REVOTRON XZA PLUS DCA BSVI (1199cc)",
          "1.2 REVOTRON XZA PLUS(O)P DCA (1199cc)",
          "1.2 REVOTRON XZA PLUS DARK DCA (1199cc)",
          "1.5 REVOTORQ XM PLUS BSVI (1497cc)",
          "1.5 REVOTORQ XT DARK BSVI (1497cc)",
          "1.5 REVOTORQ XZ PLUS DARK BSVI (1497cc)",
          "1.2 ITURBO XT DARK BSVI (1199cc)",
          "1.2 ITURBO XZ (O) BSVI (1199cc)",
          "1.2 ITURBO XZ PLUS BSVI (1199cc)",
          "1.2 ITURBO XZ PLUS DARK BSVI (1199cc)",
          "1.2 XTA DCT (1199cc)",
          "1.2 REVOTRON XMA PLUS DCA (1199cc)",
          "1.2 XM PLUS ICNG BSVI (1199cc)",
          "1.2 XM PLUS S ICNG BSVI (1199cc)",
          "1.2 XZ ICNG BSVI (1199cc)",
          "1.2 XZ PLUS (O) S ICNG BSVI (1199cc)",
          "1.2 XZ PLUS S ICNG BSVI (1199cc)",
          "1.2 XE ICNG BSVI (1199cc)",
          "1.2 REVOTRON XM PLUS (S) BSVI (1199cc)",
          "1.2 REVOTRON XMA PLUS (S) BSVI (1199cc)",
          "1.2 REVOTRON XZ PLUS (S) BSVI (1199cc)",
          "1.2 REVOTRON XZ PLUS(O)(S)BSVI (1199cc)",
          "1.2 REVOTRON XZA PLUS (S) BSVI (1199cc)",
          "1.2 REVOTRON XZA PLUS O(S)BSVI (1199cc)",
          "1.5 REVOTORQ XM (S) PLUS BSVI (1497cc)",
          "1.5 REVOTORQ XZ (S) PLUS BSVI (1497cc)",
          "1.5 REVOTORQ XZ PLUS(S)(D)BSVI (1497cc)",
          "1.2 ITURBO XZ PLUS (S) BSVI (1199cc)",
          "1.2 ITURBO XZ PLUS (S)(D) BSVI (1199cc)",
          "1.2 REVOTRON XZ PLUS(S)(D)BSVI (1199cc)",
          "1.2 REVOTRON XZA PLUS S(D)BSVI (1199cc)",
          "DCA XZ PLUS 1.2P BS6PH2 (1199cc)",
          "XE 1.2 RTN BS6 (1199cc)",
          "XZ I-TURBO PETROL O (1199cc)",
          "XE (1199cc)",
          "XM (1199cc)",
          "XT (1199cc)",
          "XZ (1199cc)",
          "XM RHYTM PLUS STYLE (1199cc)",
          "XZ PLUS TC P (1199cc)",
          "XTA DCT (1199cc)",
          "XZA PLUS BS6 (1199cc)",
          "XZA PLUS O BS6 (1199cc)",
          "XZA DCT BS6 (1199cc)",
          "XZA O BS6 (1199cc)",
          "XZ REVOTRON (1199cc)",
          "XZ PLUS OS CNG (1199cc)",
          "XMA PLUS (S) (1199cc)",
          "XZO (1199cc)",
          "1.2 REVOTRON XT  DARK PETROL (1199cc)",
          "1.2 XT I-TURBO PETROL (1199cc)",
          "1.2 XZ I-TURBO PETROL (1199cc)",
          "1.2 XZ PLUS I-TURBO PETROL DARK EDITION (1199cc)",
          "1.2 XZ I-TURBO (O) PETROL (1199cc)",
          "1.2 REVOTRON XE PETROL (1199cc)",
          "1.2 REVOTRON XE RHYTM PETROL (1199cc)",
          "1.2 REVOTRON XM PETROL (1199cc)",
          "1.2 REVOTRON XM STYLE PETROL (1199cc)",
          "1.2 REVOTRON XM RHYTM PETROL (1199cc)",
          "1.2 REVOTRON XM  RHYTM PLUS STYLE PETROL (1199cc)",
          "1.2 REVOTRON XT PETROL (1199cc)",
          "1.2 REVOTRON XT LUXE PETROL (1199cc)",
          "1.2 REVOTRON XZ PETROL (1199cc)",
          "1.2 REVOTRON XZ (O) PETROL (1199cc)",
          "1.2 REVOTRON XZ URBAN PETROL (1199cc)",
          "1.2 REVOTRON XTA DCA PETROL (1199cc)",
          "1.2 REVOTRON XZ PLUS DCA PETROL (1199cc)",
          "1.2 REVOTRON XZA (O) PETROL (1199cc)",
          "1.2 REVOTRON XZA PLUS DARK PETROL (1199cc)",
          "1.2 REVOTRON XZA PETROL (1199cc)",
          "1.2 REVOTRON XZ DCA PETROL (1199cc)",
          "1.2 REVOTRON XZA PLUS PETROL (1199cc)",
          "1.2 REVOTRON XT DCA PETROL (1199cc)",
          "1.2 XZ PLUS I-TURBO PETROL (1199cc)",
          "1.2 REVOTRON XZA DCA PETROL (1199cc)",
          "1.2 REVOTRON XMA PLUS PETROL (1199cc)",
          "1.2 REVOTRON XTA DCA DARK PETROL (1199cc)",
          "1.2 REVOTRON XZA PLUS DARK DCA PETROL (1199cc)",
          "1.2 REVOTRON XZA PLUS DCA PETROL (1199cc)",
          "1.2 REVOTRON XZ PLUS PETROL (1199cc)",
          "1.2 TURBOCHARGED XZ PLUS PETROL (1199cc)",
          "1.2 REVOTRON XM PLUS PETROL (1199cc)",
          "1.2 REVOTRON XE PLUS PETROL (1199cc)",
          "1.2 REVOTRON XZ PLUS DARK EDITION PETROL (1199cc)",
          "XT I-TURBO PETROL DARK EDITION (1199cc)",
          "XT I-TURBO PETROL DARK EDITION (1199cc)",
          "XTA PETROL (1199cc)",
          "XTA PETROL (1199cc)",
          "XZA O PETROL (1199cc)",
          "XZA O PETROL (1199cc)",
          "XZA PETROL (1199cc)",
          "XZA PETROL (1199cc)",
          "XZA PLUS PETROL (1199cc)",
          "XZA PLUS PETROL (1199cc)",
          "XM PETROL PLUS (1199cc)",
          "XM PETROL PLUS (1199cc)",
          "XTA 1.2 P DARK (1199cc)",
          "XM PLUS 1.2 (1199cc)",
          "XT 1.2 RTN BS-VI (1199cc)",
          "XM PLUS S CNG (1199cc)",
          "XZ CNG (1199cc)",
          "XM PLUS O(S) CNG (1199cc)",
          "XZ PLUS S CNG (1199cc)",
          "XZ I-TURBO PETROL O FACELIFT (1199cc)",
          "XZ I-TURBO PETROL O FACELIFT (1199cc)",
          "XMA PLUS S PETROL (1199cc)",
          "XZ PLUS O S ICNG (1199cc)",
          "XT PETROL DARK EDITION (1199cc)",
          "XT PETROL DARK EDITION (1199cc)",
          "XZ PLUS PETROL DARK EDITION (1199cc)",
          "XZ PLUS PETROL DARK EDITION (1199cc)",
          "XZ PLUS I-TURBO PETROL DARK EDITION (1199cc)",
          "XZ PLUS I-TURBO PETROL DARK EDITION (1199cc)",
          "XMA PLUS PETROL (1199cc)",
          "XMA PLUS PETROL (1199cc)",
          "XTA PETROL DARK EDITION (1199cc)",
          "XTA PETROL DARK EDITION (1199cc)",
          "XZA PLUS PETROL DARK EDITION (1199cc)",
          "XZA PLUS PETROL DARK EDITION (1199cc)",
          "XM PLUS (S) ICNG (1199cc)",
          "XE CNG (1199cc)",
          "XM PLUS ICNG (1199cc)",
          "XZ ICNG (1199cc)",
          "XZ PLUS (S) ICNG (1199cc)",
          "XM PLUS (S) PETROL (1199cc)",
          "XZ PLUS (S) (1199cc)",
          "XZ PLUS (O) (S) PETROL (1199cc)",
          "XZ PLUS I-TURBO (S) PETROL (1199cc)",
          "XZA PLUS (S) PETROL (1199cc)",
          "XZA PLUS (O) (S) PETROL (1199cc)",
          "XZ PLUS S PETROL DARK EDITION (1199cc)",
          "XZ PLUS I-TURBO S PETROL DARK EDITION (1199cc)",
          "XZA PLUS S PETROL DARK EDITION (1199cc)",
          "XZ PLUS DARK EDITION PETROL (1199cc)",
          "XZ PLUS I-TURBO DARK EDITION PETROL (1199cc)",
          "XMA PLUS AMT PETROL (1199cc)",
          "XZA PLUS AMT PETROL (1199cc)",
          "XTA AMT PETROL (1199cc)",
          "XZA AMT PETROL (1199cc)",
          "XZA (O) AMT PETROL (1199cc)",
          "XTA DARK EDITION AMT PETROL (1199cc)",
          "XZA PLUS DARK EDITION AMT PETROL (1199cc)",
          "XT DARK EDITION PETROL (1199cc)",
          "XM PLUS PETROL (1199cc)",
          "XZ I-TURBO (O) PETROL (1199cc)",
          "XZ PETROL (1199cc)",
          "XZ PETROL (1199cc)",
          "XE PETROL (1199cc)",
          "XE PETROL (1199cc)",
          "XM PETROL (1199cc)",
          "XM PETROL (1199cc)",
          "XT PETROL (1199cc)",
          "XT PETROL (1199cc)",
          "XZ O PETROL (1199cc)",
          "XZ O PETROL (1199cc)",
          "XM RHYTM PETROL (1199cc)",
          "XM RHYTM PETROL (1199cc)",
          "XM RHYTM PLUS STYLE PETROL (1199cc)",
          "XM RHYTM PLUS STYLE PETROL (1199cc)",
          "XM STYLE PETROL (1199cc)",
          "XM STYLE PETROL (1199cc)",
          "XT LUXE PETROL (1199cc)",
          "XT LUXE PETROL (1199cc)",
          "XE PLUS PETROL. (1199cc)",
          "XE PLUS PETROL (1199cc)",
          "XE RHYTM PETROL (1199cc)",
          "XE RHYTM PETROL (1199cc)",
          "XZ URBAN PETROL (1199cc)",
          "XZ URBAN PETROL (1199cc)",
          "XZ PLUS PETROL (1199cc)",
          "XZ PLUS PETROL (1199cc)",
          "XZ PLUS I TURBO PETROL (1199cc)",
          "XZ PLUS I-TURBO PETROL (1199cc)",
          "XT I-TURBO PETROL (1199cc)",
          "XT I-TURBO PETROL (1199cc)",
          "XZ I-TURBO PETROL (1199cc)",
          "XZ I-TURBO PETROL (1199cc)",
          "XE. (1497cc)",
          "XM. (1497cc)",
          "XT. (1497cc)",
          "XZ. (1497cc)",
          "XM PLUS DIESEL BS6 (1497cc)",
          "XZO. (1497cc)",
          "1.5 REVOTRON XM STYLE DIESEL (1497cc)",
          "1.5 REVOTRON XM RHYTM DIESEL (1497cc)",
          "1.5 REVOTRON XM  RHYTM PLUS STYLE DIESEL (1497cc)",
          "1.5 REVOTRON XT DIESEL (1497cc)",
          "1.5 REVOTRON XT LUXE DIESEL (1497cc)",
          "1.5 REVOTRON XZ DIESEL (1497cc)",
          "1.5 REVOTRON XZ (O) DIESEL (1497cc)",
          "1.5 REVOTRON XZ URBAN DIESEL (1497cc)",
          "1.5 REVOTRON XE DIESEL (1497cc)",
          "1.5 REVOTRON XE RHYTM DIESEL (1497cc)",
          "1.5 REVOTRON XM DIESEL (1497cc)",
          "1.5 REVOTRON XZ PLUS DARK DIESEL (1497cc)",
          "1.5 XE PLUS TURBOCHARGED REVOTORQ DIESEL (1497cc)",
          "1.5 XM PLUS TURBOCHARGED REVOTORQ DIESEL (1497cc)",
          "1.5 XT TURBOCHARGED REVOTORQ DIESEL (1497cc)",
          "1.5 XZ TURBOCHARGED REVOTORQ DIESEL (1497cc)",
          "1.5 XZ PLUS TURBOCHARGED REVOTORQ DIESEL (1497cc)",
          "1.5 XZ PLUS DARK TURBOCHARGED REVOTORQ DIESEL (1497cc)",
          "1.5 REVOTRON XZ PLUS DIESEL (1497cc)",
          "XZ PLUS S DIESEL DARK EDITION (1497cc)",
          "XZ PLUS DIESEL DARK EDITION (1497cc)",
          "XZ PLUS DIESEL DARK EDITION (1497cc)",
          "XZ DIESEL DARK EDITION (1497cc)",
          "XZ DIESEL DARK EDITION (1497cc)",
          "XM PLUS (S) DIESEL (1497cc)",
          "XZ PLUS (S) DIESEL (1497cc)",
          "XT DIESEL DARK EDITION (1497cc)",
          "XT DIESEL DARK EDITION (1497cc)",
          "XE DIESEL (1497cc)",
          "XE DIESEL (1497cc)",
          "XM DIESEL (1497cc)",
          "XM DIESEL (1497cc)",
          "XT DIESEL (1497cc)",
          "XT DIESEL (1497cc)",
          "XZ DIESEL (1497cc)",
          "XZ DIESEL (1497cc)",
          "XZ O DIESEL (1497cc)",
          "XZ O DIESEL (1497cc)",
          "XM RHYTM DIESEL (1497cc)",
          "XM RHYTM DIESEL (1497cc)",
          "XM RHYTM PLUS STYLE DIESEL (1497cc)",
          "XM RHYTM PLUS STYLE DIESEL (1497cc)",
          "XM STYLE DIESEL (1497cc)",
          "XM STYLE DIESEL (1497cc)",
          "XT LUXE DIESEL (1497cc)",
          "XT LUXE DIESEL (1497cc)",
          "XZ URBAN DIESEL (1497cc)",
          "XZ URBAN DIESEL (1497cc)",
          "XM PLUS DIESEL (1497cc)",
          "XM PLUS DIESEL (1497cc)",
          "XE RHYTM DIESEL (1497cc)",
          "XE RHYTM DIESEL (1497cc)",
          "XZ PLUS DIESEL (1497cc)",
          "XZ PLUS DIESEL (1497cc)",
          "XE PLUS DIESEL. (1497cc)",
          "XE PLUS DIESEL (1497cc)",
          "1.2 REVOTRON XZ PLUS DCA DARK EDITION PETROL (1198cc)",
          "XZA 1.2P (1198cc)",
          "XZ PLUS DCA  1.2 P BS6 (1198cc)",
          "DCA (1198cc)",
          "XZA PLUS 1.2 P DARK (1197cc)",
          "1.5 REVOTRON XE PLUS DIESEL (1498cc)",
        ],
      },
      {
        model: "XENON",
        variants: [
          "CREW CABIN LUXURY (2956cc)",
          "4X4 CREW CAB (2179cc)",
          "4X2 CREW CAB (2179cc)",
          "XT EX 4X2 (2179cc)",
          "XT EX 4X2 (2179cc)",
          "XT EX 4X4 (2179cc)",
          "XT EX 4X4 (2179cc)",
          "XT (2179cc)",
          "XT (2179cc)",
          "T 4X4 (2179cc)",
          "T 4X2 (2179cc)",
          "DC (2179cc)",
          "XT EX 4X2 CREW CAB (2179cc)",
          "XT 4X2 CREW CAB (2179cc)",
          "XT 4X4 CREW CAB (2179cc)",
          "3 (2956cc)",
          "CREW CAB (2956cc)",
          "YODHA CREW CAB (2956cc)",
          "3.0 SINGLE CAB (2956cc)",
          "3.0 4X2 CREW CAB (2956cc)",
          "3.0 4X4 CREW CAB (2956cc)",
          "CREW CAB 4*2 (2956cc)",
          "CREW CABIN LUXURY 4X2 (2956cc)",
          "CREW CABIN LUXURY 4X4 (2956cc)",
          "YODHA BS IV + (2956cc)",
          "YODHA BS IV 4X4 DO (2956cc)",
          "YODHA BS IV SINGLE (2956cc)",
          "CREW CABIN (2956cc)",
          "XT 3.0 RX 4X2 CREW CAB (2956cc)",
          "YODHA 5 STR (2956cc)",
          "5 STR (2956cc)",
        ],
      },
      {
        model: "SUMO GOLD",
        variants: [
          "CX 10STR (2956cc)",
          "CX 7STR BS4 (2956cc)",
          "CX 9STR BS4 (2956cc)",
          "DI CX 10STR (2956cc)",
          "DI CX 7STR (2956cc)",
          "DI CX 9STR (2956cc)",
          "DI EX 7STR (2956cc)",
          "DI EX 9STR (2956cc)",
          "DI GX 7STR (2956cc)",
          "DI GX 8STR (2956cc)",
          "DI GX 9STR (2956cc)",
          "DI LX 7STR (2956cc)",
          "DI LX 9STR (2956cc)",
          "EX 10STR (2956cc)",
          "EX BS4 7STR (2956cc)",
          "EX 9STR BS4 (2956cc)",
          "GX 5STR (2956cc)",
          "GX 7STR BS4 (2956cc)",
          "GX 9STR BS4 (2956cc)",
          "GX TC 7STR (2956cc)",
          "GX TC 8STR (2956cc)",
          "LX 10STR (2956cc)",
          "LX BS4 7STR (2956cc)",
          "LX BS4 9STR (2956cc)",
          "FX BS4 7STR (2956cc)",
          "FX BS4 9STR (2956cc)",
          "CX BS-IV (2956cc)",
          "CX BS-IV (2956cc)",
          "EX BS-IV (2956cc)",
          "EX BS-IV (2956cc)",
          "GX BS-IV (2956cc)",
          "GX BS-IV (2956cc)",
          "CX PS BS-IV (2956cc)",
          "CX PS BS-IV (2956cc)",
          "CX 9STR (2956cc)",
          "CX 9STR (2956cc)",
          "LX 9STR (2956cc)",
          "LX 9STR (2956cc)",
          "EX 9STR (2956cc)",
          "EX 9STR (2956cc)",
          "GX 9STR (2956cc)",
          "GX 9STR (2956cc)",
          "EX 7STR (2956cc)",
          "CX 7STR (2956cc)",
          "GX 7STR (2956cc)",
          "LX 7STR (2956cc)",
          "CX 10 SEATER (2956cc)",
          "LX 10 SEATER (2956cc)",
          "EX 10 SEATER (2956cc)",
          "GX TC 7 SEATER (2956cc)",
          "GX TC 8 SEATER (2956cc)",
          "DI CX 10 SEATER (2956cc)",
          "DI LX 7 SEATER (2956cc)",
          "DI GX 9 SEATER (2956cc)",
          "DI GX 8 SEATER (2956cc)",
          "DI EX 7 SEATER (2956cc)",
          "DI EX 9 SEATER (2956cc)",
          "DI GX 7 SEATER (2956cc)",
          "DI LX 9 SEATER (2956cc)",
          "LX 9 SEATER (2956cc)",
          "GX 5 SEATER (2956cc)",
          "GX 7 SEATER (2956cc)",
          "LX 7 SEATER (2956cc)",
          "CX 7 SEATER (2956cc)",
          "DI CX 9 SEATER (2956cc)",
          "EX 7 SEATER (2956cc)",
          "GX 9 SEATER (2956cc)",
          "DI CX 7 SEATER (2956cc)",
          "CX 9 SEATER (2956cc)",
          "EX 9 SEATER (2956cc)",
          "CX BS4 - 7 SEATER (2956cc)",
          "CX BS4 - 9 SEATER (2956cc)",
          "LX BS4 - 7 SEATER (2956cc)",
          "LX BS4 - 9 SEATERER (2956cc)",
          "EX BS4 - 7 SEATER (2956cc)",
          "EX BS4 - 9 SEATER (2956cc)",
          "GX BS4 - 7 SEATER (2956cc)",
          "GX BS4 - 9 SEATER (2956cc)",
          "FX (2956cc)",
          "GX TC (2956cc)",
          "CX BS-III (2956cc)",
          "LX BS-III (2956cc)",
          "EX BS-III (2956cc)",
          "GX BS-III (2956cc)",
          "CX PS BS III (2956cc)",
          "GX BSIV 9STR (2956cc)",
          "CX BSIV 11 STR (2956cc)",
          "SA GOLD (2956cc)",
          "SA GOLD (2956cc)",
          "LX BS-IV (2956cc)",
          "LX BS-IV (2956cc)",
          "FX BS IV (2956cc)",
          "FX BS IV (2956cc)",
          "LX (2956cc)",
          "GX (2956cc)",
          "EX (2956cc)",
          "CX (2956cc)",
          "CX 11STR (2956cc)",
          "FX 7STR (2956cc)",
          "FX 9STR (2956cc)",
          "GOLD CX (2956cc)",
          "GOLD CX (9 SEATER) (2956cc)",
        ],
      },
      {
        model: "SUMO",
        variants: [
          "4X4 10STR (1948cc)",
          "DX 10STR (1948cc)",
          "DX TURBO 10STR (1948cc)",
          "EX 7STR (1948cc)",
          "EX 8STR (1948cc)",
          "EX TURBO 7STR (1948cc)",
          "EX TURBO 8STR (1948cc)",
          "EZI 10STR (1948cc)",
          "EZI TURBO 10STR (1948cc)",
          "GOLD BSIII (2956cc)",
          "SE 10STR (1948cc)",
          "SE 7STR (1948cc)",
          "SE PLUS 10STR (1948cc)",
          "SE PLUS 9STR (1948cc)",
          "STD 10STR (1998-2000) (1948cc)",
          "STD 9STR (1998-2000) (1948cc)",
          "EX 10STR (1948cc)",
          "TOURIN (1948cc)",
          "VICTA GX 7STR (1948cc)",
          "VICTA CX 7STR (1948cc)",
          "VICTA LX 7STR (1948cc)",
          "SE TC (2956cc)",
          "SE TC (2956cc)",
          "GOLD BS-IV CX (2956cc)",
          "GOLD BS-IV CX - PS (2956cc)",
          "GOLD BS-IV EX (2956cc)",
          "GOLD BS-IV GX (2956cc)",
          "STD 9 SEATER (1998-2000) (1948cc)",
          "STD 10 SEATER (1998-2000) (1948cc)",
          "DX 10 SEATER (1948cc)",
          "DX TURBO 10 SEATER (1948cc)",
          "EZI 10 SEATER (1948cc)",
          "EZI TURBO 10 SEATER (1948cc)",
          "SE PLUS 9 SEATER (1948cc)",
          "SE PLUS 10 SEATER (1948cc)",
          "EX 8 SEATER (1948cc)",
          "EX TURBO 7 SEATER (1948cc)",
          "EX TURBO 8 SEATER (1948cc)",
          "SE 7 SEATER (1948cc)",
          "EX 7 SEATER (1948cc)",
          "SE 10 SEATER (1948cc)",
          "EX 10 SEATER (1948cc)",
          "4X4 10 SEATER (1948cc)",
          "DLX (1948cc)",
          "DLX (1948cc)",
          "SE PLUS BS II (1948cc)",
          "EX TURBO (1948cc)",
          "DX TURBO (1948cc)",
          "EZI TURBO (1948cc)",
          "SPACIO 6 SEATER (1948cc)",
          "STANDARD (1948cc)",
          "EZI 6STR (1948cc)",
          "GRANDE (1948cc)",
          "DLX 8 SEATER (1948cc)",
          "EZI (1948cc)",
          "EZI (1948cc)",
          "SE (1948cc)",
          "SE+ (1948cc)",
          "4X4 (1948cc)",
          "4X4 (1948cc)",
          "SE PLUS BS III (1948cc)",
          "SE PLUS BS III (1948cc)",
          "SA (1948cc)",
          "PLUS (1948cc)",
          "PLUS (1948cc)",
          "EX (1948cc)",
          "EX+ (1948cc)",
          "SA TC (1948cc)",
          "EX PLUS (1948cc)",
          "EX PLUS (1948cc)",
          "4X4 PLUS (1948cc)",
          "SPACIO SA (1948cc)",
          "SC  E2 10STR (1948cc)",
          "SA (STANDARD) EGR (1948cc)",
          "SA (STANDARD) (1948cc)",
          "AC DLX (1948cc)",
          "SA (STANDARD) TURBO CHARGED (1948cc)",
          "DLX 8 STR (1948cc)",
          "DLX  8STR (1948cc)",
          "SUMO 4X4 ECONOMY (1948cc)",
          "SE EGR (1948cc)",
          "DX 9STR (1948cc)",
          "SA EGR (1948cc)",
          "DX 8STR (1948cc)",
          "GX TC 7-SEATER (1948cc)",
          "DI CX 10 STR (1948cc)",
          "EX TC (1948cc)",
          "SUMO 4X4 TC (1948cc)",
          "DI CX 9 STR (1948cc)",
          "SPACIO 10 STR (1948cc)",
          "GX TC 8-SEATER (1948cc)",
          "DL (1948cc)",
          "SPACIO (1948cc)",
          "DX (1948cc)",
          "DX (1948cc)",
          "EZI TC (1948cc)",
          "SA PLUS (1948cc)",
          "SE PLUS (1948cc)",
          "SPACIO SA GOLD (1948cc)",
          "SPACIO ST (1948cc)",
          "DLX 7STR (1948cc)",
          "CX 10 STR (2956cc)",
          "DI (2956cc)",
          "GOLD EX BS IV 7 STR (2956cc)",
          "GOLD EX BS IV 9 STR (2956cc)",
          "EZI (2956cc)",
          "EZI (2956cc)",
          "SE (2956cc)",
          "SE (2956cc)",
          "4X4 (2956cc)",
          "4X4 (2956cc)",
          "SA (2956cc)",
          "SA (2956cc)",
          "DX TC (2956cc)",
          "DX TC (2956cc)",
          "4X4 ECONOMY BS III (2956cc)",
          "4X4 ECONOMY BS III (2956cc)",
          "4X4 TC BS III (2956cc)",
          "4X4 TC BS III (2956cc)",
          "VICTA IDI EX BS III (2956cc)",
          "VICTA IDI EX BS III (2956cc)",
          "SE BS III (2956cc)",
          "SE BS III (2956cc)",
          "VICTA DI GX 9STR BSIII (2956cc)",
          "SA TC (2956cc)",
          "SA TC (2956cc)",
          "SE 4X4 (2956cc)",
          "SE 4X4 (2956cc)",
          "4X4 PLUS (2956cc)",
          "4X4 PLUS (2956cc)",
          "DELUX (2956cc)",
          "DELUX (2956cc)",
          "EX 10 STR (2956cc)",
          "TOURIN (2956cc)",
          "TOURIN (2956cc)",
          "GX TC 10 STR (2956cc)",
          "SPACIO A1 ST (2956cc)",
          "SPACIO 11STR (2956cc)",
          "SPACIO GOLD SA 9STR (2956cc)",
          "SPACIO GOLD 8STR (2956cc)",
          "SPACIO GOLD 7STR (2956cc)",
          "DI GX (2956cc)",
          "DI EX (2956cc)",
          "SPACIO 10 STR (2956cc)",
          "4X4 7 STR (2956cc)",
          "DX (2956cc)",
          "DX (2956cc)",
          "EZI TC (2956cc)",
          "EZI TC (2956cc)",
          "SA PLUS (2956cc)",
          "SA PLUS (2956cc)",
          "STD (2956cc)",
          "STD (2956cc)",
          "SE PLUS (2956cc)",
          "SE PLUS (2956cc)",
          "VICTA CX (2956cc)",
          "SPACIO A1 HT (2856cc)",
          "SPACIO GOLD 9 STR (2954cc)",
          "3.0 DX 7 SEATER (2996cc)",
          "DLX 7STR (1998cc)",
          "DLX 8STR (1998cc)",
          "DLX 10STR (1998cc)",
          "STD (1998cc)",
        ],
      },
      {
        model: "SUMO VICTA",
        variants: [
          "DI CX 10STR (2956cc)",
          "DI CX 10STR (2956cc)",
          "DI EX 7STR (2956cc)",
          "DI EX 7STR (2956cc)",
          "DI LX 7STR (2956cc)",
          "DI LX 7STR (2956cc)",
          "EX 10STR (1948cc)",
          "EX 10STR (1948cc)",
          "EX 8STR (1948cc)",
          "EX 8STR (1948cc)",
          "LX 9STR (1948cc)",
          "LX 9STR (1948cc)",
          "CX 10STR (1948cc)",
          "DI GX 8STR (2956cc)",
          "GX 9STR (1948cc)",
          "LX 6STR (1948cc)",
          "LX 10STR (1948cc)",
          "LX 10STR (1948cc)",
          "DI CX 7 STR (2956cc)",
          "DI CX 7STR (2956cc)",
          "CX 9 STR (1948cc)",
          "CX 9STR (1948cc)",
          "GX TC 8 STR (1948cc)",
          "GX TC 8 STR (1948cc)",
          "GX TC 7 STR (1948cc)",
          "GX TC 7 STR (1948cc)",
          "GX 7STR (1948cc)",
          "DI EX 9 STR (2956cc)",
          "DI EX 9STR (2956cc)",
          "DI GX 9 STR (2956cc)",
          "DI LX 9 STR (2956cc)",
          "DI LX 9STR (2956cc)",
          "EX 9 STR (1948cc)",
          "EX  9STR (1948cc)",
          "EX 7 STR (1948cc)",
          "DI CX 9STR (2956cc)",
          "DI GX (2956cc)",
          "DI GX (2956cc)",
          "CX 6 SEATER (1948cc)",
          "EX 8 SEATER (1948cc)",
          "CX 10 SEATER (1948cc)",
          "LX 10 SEATER (1948cc)",
          "EX 10 SEATER (1948cc)",
          "DI CX 10 SEATER (2956cc)",
          "DI GX 8 SEATER (2956cc)",
          "CX 9 SEATER (1948cc)",
          "DI EX 7 SEATER (2956cc)",
          "CX 7 SEATER (1948cc)",
          "DI CX 9 SEATER (2956cc)",
          "DI CX 7 SEATER (2956cc)",
          "LX 9 SEATER (1948cc)",
          "DI LX 7 SEATER (2956cc)",
          "GX 7 SEATER (1948cc)",
          "DI EX 9 SEATER (2956cc)",
          "DI LX 9 SEATER (2956cc)",
          "GX 9 SEATER (1948cc)",
          "DI GX 7 SEATER (2956cc)",
          "EX 7 SEATER (1948cc)",
          "LX 7 SEATER (1948cc)",
          "DI GX 9 SEATER (2956cc)",
          "EX 9 SEATER (1948cc)",
          "GX TC 7 SEATER (1948cc)",
          "GX TC 8 SEATER (1948cc)",
          "CX S (2956cc)",
          "EX TC (2956cc)",
          "LX 10STR (2956cc)",
          "DI CX (2956cc)",
          "DI CX (2956cc)",
          "EX (2956cc)",
          "DI EX (2956cc)",
          "DI EX (2956cc)",
          "DI LX (2956cc)",
          "DI LX (2956cc)",
          "GX (2956cc)",
          "GX TC (1948cc)",
          "SE PLUS (1948cc)",
          "EX 6 STR (1948cc)",
          "EX 6STR (1948cc)",
          "CX 7 STR - BS II (1948cc)",
          "CX 7 STR - BS III (1948cc)",
          "LX 8 SEATER (1948cc)",
          "GX 10 SEATER (1948cc)",
          "CX 'S' 6 SEATER (1948cc)",
          "EX TC (1948cc)",
          "EX TC (1948cc)",
          "EX E2 7STR (1948cc)",
          "EX EURO II (1948cc)",
          "CX EGR 6STR (1948cc)",
          "GX TC E3 10STR (1948cc)",
          "GX  8STR (1948cc)",
          "CX E3 (1948cc)",
          "EX E-3 7STR (1948cc)",
          "GX BS2 10STR (1948cc)",
          "CX EGR 10STR (1948cc)",
          "CX 6STR (1948cc)",
          "CX (1948cc)",
          "CX (1948cc)",
          "EX (1948cc)",
          "EX (1948cc)",
          "GX (1948cc)",
          "GX (1948cc)",
          "LX (1948cc)",
          "LX (1948cc)",
          "DI GX 9STR (1948cc)",
        ],
      },
      {
        model: "HEXA",
        variants: [
          "XE 4X2 (2179cc)",
          "XM 4X2 (2179cc)",
          "XMA 4X2 (2179cc)",
          "XT 4X2 (2179cc)",
          "XT 4X4 (6/7) (2179cc)",
          "XTA 4X2 (6/7) (2179cc)",
          "XTA 4X2 (2179cc)",
          "XM PLUS 7 SEATER (2179cc)",
          "XTA 7 SEATER (2179cc)",
          "XTA 6 SEATER (2179cc)",
          "XT 4X4 7 SEATER (2179cc)",
          "XT 7 SEATER (2179cc)",
          "XT 6 SEATER (2179cc)",
          "XE 7 SEATER (2179cc)",
          "XM 7 SEATER (2179cc)",
          "XMA 7 SEATER (2179cc)",
          "XTA 4X2 DUAL TONE 6 STR (2179cc)",
          "XTA 4X2 DUAL TONE 6 STR (2179cc)",
          "XT 4X2 DUAL TONE 6 STR (2179cc)",
          "XT 4X2 DUAL TONE 6 STR (2179cc)",
          "XT 4X4 DUAL TONE 6 STR (2179cc)",
          "XT 4X4 DUAL TONE 6 STR (2179cc)",
          "XT 4X2 DUAL TONE 7 STR (2179cc)",
          "XT 4X2 DUAL TONE 7 STR (2179cc)",
          "XT 4X4 DUAL TONE 7 STR (2179cc)",
          "XT 4X4 DUAL TONE 7 STR (2179cc)",
          "XTA 4X2 DUAL TONE 7 STR (2179cc)",
          "XTA 4X2 DUAL TONE 7 STR (2179cc)",
          "XE 4X2 7 STR (2179cc)",
          "XE 4X2 7 STR (2179cc)",
          "XE 4X2 6 STR (2179cc)",
          "XE 4X2 6 STR (2179cc)",
          "XM 4X2 7 STR (2179cc)",
          "XM 4X2 7 STR (2179cc)",
          "XMA 4X2 7 STR (2179cc)",
          "XMA 4X2 7 STR (2179cc)",
          "XT 4X2 7 STR (2179cc)",
          "XT 4X2 7 STR (2179cc)",
          "XT 4X4 7 STR (2179cc)",
          "XT 4X4 7 STR (2179cc)",
          "XTA 4X2 7 STR (2179cc)",
          "XTA 4X2 7 STR (2179cc)",
          "XT 4X2 6 STR (2179cc)",
          "XT 4X2 6 STR (2179cc)",
          "XTA 4X2 6 STR (2179cc)",
          "XTA 4X2 6 STR (2179cc)",
          "XM 4X2 6 STR (2179cc)",
          "XM 4X2 6 STR (2179cc)",
          "XMA 4X2 6 STR (2179cc)",
          "XMA 4X2 6 STR (2179cc)",
          "XT 4X4 6 STR (2179cc)",
          "XT 4X4 6 STR (2179cc)",
          "XT 4X4 (2179cc)",
          "XT 4X4 (2179cc)",
          "XE (2179cc)",
          "XE (2179cc)",
          "XT (2179cc)",
          "XT (2179cc)",
          "XMA (2179cc)",
          "XMA (2179cc)",
          "XTA (2179cc)",
          "XTA (2179cc)",
          "XM (2179cc)",
          "XM (2179cc)",
          "XTA 4X2 6 SEATER (2179cc)",
          "XM PLUS 4X2 (2179cc)",
          "XT 4X2 7 SEATER (2179cc)",
          "XT 4X2 6 SEATER (2179cc)",
          "XT 4X4 6 SEATER (2179cc)",
        ],
      },
      {
        model: "HARRIER",
        variants: [
          "XMA (AT) (1956cc)",
          "XZA (AT) (1956cc)",
          "XZ PLUS DT (1956cc)",
          "XZA DT (AT) (1956cc)",
          "XZA DARK EDITION (AT) (1956cc)",
          "XZA PLUS (AT) (1956cc)",
          "XZA PLUS DT (AT) (1956cc)",
          "XZA PLUS DARK EDITION (AT) (1956cc)",
          "CAMO XZA+ST (1956cc)",
          "XT PLUS DARK (1956cc)",
          "XZA 2.0 L BS VI (1956cc)",
          "XTA PLUS AT (1956cc)",
          "XZ PLUS KAZIRANGA (1956cc)",
          "XZA PLUS KAZIRANGA (1956cc)",
          "XZS BSVI (1956cc)",
          "XZS DT BSVI (1956cc)",
          "XZS DARK EDITION BSVI (1956cc)",
          "XZ PLUS JET BSVI (1956cc)",
          "XZAS BSVI (1956cc)",
          "XZAS DUAL TONE BSVI (1956cc)",
          "XZAS DARK EDITION BSVI (1956cc)",
          "XZA PLUS JET BSVI (1956cc)",
          "XMS BSVI (1956cc)",
          "XMA BSVI (1956cc)",
          "XMAS BSVI (1956cc)",
          "XT PLUS BSVI (1956cc)",
          "XZA PLUS (O) DT BSVI (1956cc)",
          "XZA PLUS (O) DARK BSVI (1956cc)",
          "XZA PLUS O BSVI (1956cc)",
          "XE (1956cc)",
          "XE (1956cc)",
          "XM (1956cc)",
          "XM (1956cc)",
          "XT (1956cc)",
          "XT (1956cc)",
          "XZ (1956cc)",
          "XZ (1956cc)",
          "XZ DUAL TONE (1956cc)",
          "XZ DUAL TONE (1956cc)",
          "XZ DARK EDITION (1956cc)",
          "XZ DARK EDITION (1956cc)",
          "XZ PLUS (1956cc)",
          "XZ PLUS (1956cc)",
          "XZ PLUS DARK EDITION (1956cc)",
          "XZ PLUS DARK EDITION (1956cc)",
          "XT PLUS BS6 (1956cc)",
          "XT PLUS CAMO BS6 (1956cc)",
          "XZ CAMO BS6 (1956cc)",
          "XZA PLUS CAMO BS6 (1956cc)",
          "XZA CAMO BS6 (1956cc)",
          "XZ PLUS CAMO BS6 (1956cc)",
          "XZ DUAL TONE BS6 (1956cc)",
          "XTA PLUS BS6 (1956cc)",
          "XZA PLUS KAZIRANGA EDITION BS6 (1956cc)",
          "XZA BS6 (1956cc)",
          "2.0 XZ PLUS DIESEL KAZIRANGA (1956cc)",
          "2.0 XZ PLUS DUAL TONE (1956cc)",
          "2.0 XZA PLUS KAZIRANGA AT DIESEL (1956cc)",
          "2.0 XTA PLUS DARK EDITION (1956cc)",
          "2.0 XTA PLUS (1956cc)",
          "2.0 XZ DARK EDITION (1956cc)",
          "2.0 XT CAMO (1956cc)",
          "2.0 XZ PLUS DIESEL (1956cc)",
          "2.0 XZA PLUS DIESEL (1956cc)",
          "2.0 XMA AT DIESEL (1956cc)",
          "2.0 XZA PLUS CAMO (1956cc)",
          "2.0 XZA PLUS DARK EDITION (1956cc)",
          "2.0 XZA PLUS DUAL TONE DIESEL (1956cc)",
          "2.0 XZA AT DIESEL (1956cc)",
          "2.0 XT PLUS DARK EDITION (1956cc)",
          "2.0 XT DARK EDITION (1956cc)",
          "2.0 XT PLUS DIESEL (1956cc)",
          "2.0 XZ PLUS RED DARK EDITION (1956cc)",
          "2.0 XE DIESEL (1956cc)",
          "2.0 XM DIESEL (1956cc)",
          "2.0 XT DIESEL (1956cc)",
          "2.0 XZ DIESEL (1956cc)",
          "2.0 XZS DARK EDITION DIESEL (1956cc)",
          "2.0 XZAS AT DIESEL (1956cc)",
          "2.0 XZAS AT DIESEL DUAL TONE (1956cc)",
          "2.0 XZAS AT DIESEL DARK EDITION (1956cc)",
          "2.0 XMAS AT DIESEL (1956cc)",
          "2.0 XZA PLUS AT (O) DIESEL (1956cc)",
          "2.0 XZA PLUS AT (O) DUAL TONE DIESEL (1956cc)",
          "2.0 XZA PLUS AT (O) DARK EDITION (1956cc)",
          "2.0 XZA PLUS AT (O) RED DARK EDITION (1956cc)",
          "2.0 XZ DUAL TONE DIESEL (1956cc)",
          "2.0 XZA PLUS JET DIESEL (1956cc)",
          "2.0 XZA DUAL TONE DIESEL (1956cc)",
          "2.0 XMS DIESEL (1956cc)",
          "2.0 XZS DIESEL (1956cc)",
          "2.0  XZS DUAL TONE  DIESEL (1956cc)",
          "2.0 XZ DUAL TONE (1956cc)",
          "2.0 XZ PLUS AT RED DARK EDITION (1956cc)",
          "2.0 XZA PLUS AT RED DARK EDITION (1956cc)",
          "2.0 XZ CAMO (1956cc)",
          "2.0 XT PLUS CAMO (1956cc)",
          "2.0 XZ PLUS DARK EDITION (1956cc)",
          "XT PLUS 2.0L KRYOTEC BSVI (1956cc)",
          "XT PLUS 2.0L KRYOTEC BSVI (1956cc)",
          "XZA PLUS DARK EDITION NEW (1956cc)",
          "XZA PLUS DARK EDITION NEW (1956cc)",
          "XZ PLUS DARK EDITION NEW (1956cc)",
          "XZ PLUS DARK EDITION NEW (1956cc)",
          "XT PLUS DARK EDITION NEW (1956cc)",
          "XT PLUS DARK EDITION NEW (1956cc)",
          "XZA CAMO (1956cc)",
          "XZA CAMO (1956cc)",
          "XZA PLUS CAMO (1956cc)",
          "XZA PLUS CAMO (1956cc)",
          "XTA PLUS (1956cc)",
          "XTA PLUS (1956cc)",
          "XZA 2.0L KRYOTEC BSVI (1956cc)",
          "XT 2.0 L KRYOTEC BSIV (1956cc)",
          "XZPLUS KZR (1956cc)",
          "XZAPLUS KZR (1956cc)",
          "XZ 2.0L KRYOTEC BSIV (1956cc)",
          "XZA PLUS 2.0L KRYOTEC BSVI (1956cc)",
          "XZA PLUS O RDK (1956cc)",
          "XZA PLUS O DUAL TONE (1956cc)",
          "XZA PLUS O (1956cc)",
          "XZ PLUS RED DARK EDITION (1956cc)",
          "XZA PLUS O DARK EDITION (1956cc)",
          "XZA PLUS O RED DARK EDITION (1956cc)",
          "XZA PLUS RED DARK EDITION (1956cc)",
          "XZAS DARK EDITION (1956cc)",
          "XZAS DARK EDITION (1956cc)",
          "XZAS DUAL TONE (1956cc)",
          "XZAS DUAL TONE (1956cc)",
          "XTA PLUS DARK EDITION (1956cc)",
          "XTA PLUS DARK EDITION (1956cc)",
          "XMAS (1956cc)",
          "XMAS (1956cc)",
          "XZA PLUS JET (1956cc)",
          "XZA PLUS JET (1956cc)",
          "XZAS (1956cc)",
          "XZAS (1956cc)",
          "KAZIRANGA XZA PLUS (1956cc)",
          "KAZIRANGA XZA PLUS (1956cc)",
          "XZA PLUS O 2LBS6PH2 RDRK (1956cc)",
          "XTA PLUS AMT DARK EDITION (1956cc)",
          "XTA PLUS AMT (1956cc)",
          "XZA AMT CAMO (1956cc)",
          "XZA PLUS AMT CAMO (1956cc)",
          "XMA S AMT (1956cc)",
          "XZA PLUS JET AMT (1956cc)",
          "KAZIRANGA XZA PLUS AMT (1956cc)",
          "XZA S AMT DUAL TONE (1956cc)",
          "XZA S AMT DARK EDITION (1956cc)",
          "XZA S AMT (1956cc)",
          "XZA (1956cc)",
          "XZA (1956cc)",
          "XT PLUS (1956cc)",
          "XT PLUS (1956cc)",
          "XT PLUS CAMO (1956cc)",
          "XT PLUS CAMO (1956cc)",
          "XZ CAMO (1956cc)",
          "XZ CAMO (1956cc)",
          "XZ PLUS CAMO (1956cc)",
          "XZ PLUS CAMO (1956cc)",
          "XZS DARK EDITION (1956cc)",
          "XZS DARK EDITION (1956cc)",
          "XZS DUAL TONE (1956cc)",
          "XZS DUAL TONE (1956cc)",
          "XT PLUS DARK EDITION (1956cc)",
          "XT PLUS DARK EDITION (1956cc)",
          "KAZIRANGA XZ PLUS. (1956cc)",
          "KAZIRANGA XZ PLUS (1956cc)",
          "XZS (1956cc)",
          "XZS (1956cc)",
          "XMS (1956cc)",
          "XMS (1956cc)",
          "XZ PLUS JET (1956cc)",
          "XZ PLUS JET (1956cc)",
          "XT DARK EDITION (1956cc)",
          "XT DARK EDITION (1956cc)",
          "XT CAMO (1956cc)",
          "XT CAMO (1956cc)",
          "XMA (1956cc)",
          "XMA (1956cc)",
          "XZ PLUS DUAL TONE (1956cc)",
          "XZ PLUS DUAL TONE (1956cc)",
          "XZA DUAL TONE (1956cc)",
          "XZA DUAL TONE (1956cc)",
          "XZA DARK EDITION (1956cc)",
          "XZA DARK EDITION (1956cc)",
          "XZA PLUS (1956cc)",
          "XZA PLUS (1956cc)",
          "XZA PLUS DARK EDITION (1956cc)",
          "XZA PLUS DARK EDITION (1956cc)",
          "XZA PLUS DUAL TONE (1956cc)",
          "XZA PLUS DUAL TONE (1956cc)",
          "XZ PLUS KZR (2000cc)",
          "XZA PLUS (O) (2000cc)",
        ],
      },
      {
        model: "VENTURE",
        variants: [
          "EX 7STR (1405cc)",
          "EX 7STR (1405cc)",
          "CX 8STR (1405cc)",
          "EX 5STR (1405cc)",
          "GX 8 STR (1405cc)",
          "GX 8STR (1405cc)",
          "LX 7 STR (1405cc)",
          "LX 7 STR (1405cc)",
          "EX 8 STR (1405cc)",
          "EX 8 STR (1405cc)",
          "LX 8 STR (1405cc)",
          "LX 8 STR (1405cc)",
          "GX 7 STR (1405cc)",
          "2100/EX (1405cc)",
          "2100/GX (1405cc)",
          "2100/LX (1405cc)",
          "CX - 8 SEATER (1405cc)",
          "EX 7 SEATER (1405cc)",
          "GX 7 SEATER (1405cc)",
          "GX 8 SEATER (1405cc)",
          "EX 5 SEATER (1405cc)",
          "EX 8 SEATER (1405cc)",
          "LX CAPTAIN 7 SEATER (1405cc)",
          "CX 5STR (1405cc)",
          "GX 5 STR (1405cc)",
          "LX 5STR (1405cc)",
          "CX - 7 SEATER (1405cc)",
          "LX - 7 SEATER (1405cc)",
          "8 SEATER HARDTOP VAN (1405cc)",
          "LX 8 SEATER (1405cc)",
          "EX BSIII 10 STR (1405cc)",
          "CX (1405cc)",
          "CX (1405cc)",
          "EX (1405cc)",
          "EX (1405cc)",
          "GX (1405cc)",
          "GX (1405cc)",
        ],
      },
      {
        model: "SAFARI STORME",
        variants: [
          "LX (2179cc)",
          "VX (2179cc)",
          "EX (2179cc)",
          "2.2 4X2 LX (2179cc)",
          "VX 4WD (2179cc)",
          "EX 8STR (2179cc)",
          "2.2 EX 4X4 (2179cc)",
          "2.2 EX 4X4 (2179cc)",
          "2.2 VX 4X4 VARICOR400 (2179cc)",
          "2.2 VX 4X4 VARICOR400 (2179cc)",
          "2.2 VX 4X2 VARICOR400 (2179cc)",
          "2.2 VX 4X2 VARICOR400 (2179cc)",
          "EXPLORER EDITION (2179cc)",
          "EXPLORER EDITION (2179cc)",
          "2.2 LX (2179cc)",
          "2.2 EX (2179cc)",
          "2.2 VTT STORME VX (2179cc)",
          "2.2 VTT STORME EX (2179cc)",
          "2.2 VTT STORME LX (2179cc)",
          "2.2 VX 4X4 (2179cc)",
          "2.2 VX 4X4 (2179cc)",
          "2.2 EX 4X2 (2179cc)",
          "2.2 EX 4X2 (2179cc)",
          "2.2 VX 4X2 (2179cc)",
          "2.2 LX 4X2 (2179cc)",
          "2.2 LX 4X2 (2179cc)",
          "2.2 VX 4X2 (2179cc)",
        ],
      },
      {
        model: "WINGER",
        variants: [
          "STD (1948cc)",
          "STD (1948cc)",
          "2800 WB 13 SEATER/CNG (2092cc)",
          "2800 BSIV 13 SEATER (2200cc)",
          "3200/BS-IV DELUXEACSCHOOLCNG (2092cc)",
          "3200/BS-III LUXURYFLATROOFAC (1948cc)",
          "3200/BS-III DELUXEHIGHROOFAC (1948cc)",
          "3200/BS-III DELUXEFLATROOFAC (1948cc)",
          "3200/BS-III DELUXEHIGHROOFNONAC (1948cc)",
          "3200/BS-III DELUXEFLATROOF/NONAC (1948cc)",
          "3200/BS-III 12 SEATER/SCH VAN (1948cc)",
          "2800/BS-III FLATROOFNONAC (1948cc)",
          "PLATINUM 2800/BS-III/FBV (1948cc)",
          "3200 13 SEATER/SCH VAN (1948cc)",
          "3200 13 SEATER/DELUXE (1948cc)",
          "32WB 13 SEATER/DIESEL (2179cc)",
          "32WB 11 SEATER/SCH VAN (2179cc)",
          "32WB 6 SEATER/SCH VAN (1948cc)",
          "32WB 7 SEATER/SCH VAN (1948cc)",
          "32WB 8 SEATER/SCH VAN (1948cc)",
          "32WB 13 SEATER/DELUXE (1948cc)",
          "35WB 12 SEATER/DELUXE (2179cc)",
          "3200/BS-III DELUXE AC SCHOOL (1948cc)",
          "2800/BS-III / STANDARD SCHOOL (1948cc)",
          "WINGER SRANDARD (1948cc)",
          "13 SEATER/DELUXE SKOOL (1948cc)",
          "14 SEATER/SCH VAN (1948cc)",
          "7 SEATER/SCH VAN (1948cc)",
          "13 SEATER/SCH VAN (1948cc)",
          "10 SEATER/SCH VAN (1948cc)",
          "9 SEATER/DIESEL BSVI (2179cc)",
          "SK BSVI (2179cc)",
          "FL 3200 FR 9S AC BSVI (2179cc)",
          "FL 3200 FR AC BSVI (2179cc)",
          "FL AMB B 3200 (3200cc)",
          "FL AMB B 3200 AC (3200cc)",
          "FL AMB 35SH AC (3200cc)",
          "FLAT ROOF AC (1948cc)",
          "HI ROOF AC (1948cc)",
          "HI ROOF (1948cc)",
          "LUXURY 7 SEATER (1948cc)",
          "10 SEATER (1948cc)",
          "LUXURY 13 SEATER (1948cc)",
          "FLAT ROOF NON AC (1948cc)",
          "14 SEATER (1948cc)",
          "LUXURY 10 SEATER (1948cc)",
          "DELUXE (1948cc)",
          "DELUXE (1948cc)",
          "PLATINUM BS-III (1948cc)",
          "7STR (1948cc)",
          "STANDARD 10 STR (1948cc)",
          "STANDARD 13 STR (1948cc)",
          "STANDARD 14 STR (1948cc)",
          "LUXURY 10 STR (1948cc)",
          "STANDARD 11 STR (1948cc)",
          "PLATINUM BS-IV (1948cc)",
          "PLATINUM BS-IV (1948cc)",
          "LUXARY AC (1948cc)",
          "LUXARY AC (1948cc)",
          "DELUXE LONG WB HI ROOF NON AC (1948cc)",
          "DELUXE LONG WB HI ROOF NON AC (1948cc)",
          "STANDARD-SHORT WB FLAT ROOF NON AC (1948cc)",
          "STANDARD-SHORT WB FLAT ROOF NON AC (1948cc)",
          "DELUXE FLAT ROOF (AC) (1948cc)",
          "DELUXE FLAT ROOF (AC) (1948cc)",
          "4X2 MONOCOQUE (1948cc)",
          "STANDARD NON AC (1948cc)",
          "DELUXE AC (1948cc)",
          "AC (1948cc)",
          "DELUXE NON AC (1948cc)",
          "DUAL AC (1948cc)",
          "LUXURY AC (1948cc)",
          "NON AC DELUXE (1948cc)",
          "NON AC STANDARD (1948cc)",
          "STANDARD (1948cc)",
          "STANDARD AC (1948cc)",
          "PLATINUM (1948cc)",
          "HI ROOF (Non-ACcc)",
          "0 (2670cc)",
          "DLX 14STR (2670cc)",
          "12STR (2670cc)",
          "DICOR (2179cc)",
          "STD 13 STR (2179cc)",
          "FL 3488 HR AC (2179cc)",
          "2.2 DICOR FLAT ROOF 10 STR (2179cc)",
          "2.2 DICOR FLAT ROOF 14 STR (2179cc)",
          "2.2 DICOR HIGH ROOF 16 STR (2179cc)",
          "DICOR HIGH ROOF DELUXE AC BS IV 10 STR (2179cc)",
          "LUXURY AC 2179CC (2179cc)",
          "FL 3200 (2072cc)",
          "CARGO EIII (2500cc)",
          "FL AMB-C 3488 AC (3200cc)",
        ],
      },
      {
        model: "INDIGO ECS",
        variants: [
          "LE TDI (1405cc)",
          "EMAX GLX (1193cc)",
          "EMAX GLX (1193cc)",
          "EMAX GLS (1193cc)",
          "EMAX GLS (1193cc)",
          "LX TDI (1405cc)",
          "LX TDI (1405cc)",
          "VX (1396cc)",
          "VX (1396cc)",
          "LX (1396cc)",
          "LX (1396cc)",
          "LS TDI (1405cc)",
          "LS (1396cc)",
          "VX (1405cc)",
          "GVX (1193cc)",
          "GVX (1193cc)",
          "GLX (1193cc)",
          "GLX (1193cc)",
          "GLS (1193cc)",
          "GLS (1193cc)",
          "GLE (1193cc)",
          "CNG (1193cc)",
          "GLX E MAX. (1193cc)",
          "GLX E MAX (1193cc)",
          "GLS E MAX (1193cc)",
          "GLS E MAX (1193cc)",
          "GL BI FUEL CNG BSIV (1193cc)",
          "GLX EMAX CNG (1193cc)",
          "GLS EMAX CNG (1193cc)",
          "LX TDI (1193cc)",
          "VX CR4 BS-IV (1396cc)",
          "VX CR4 BS-IV (1396cc)",
          "LX CR4 BS-IV (1396cc)",
          "LX CR4 BS-IV (1396cc)",
          "LS CR4 BS-IV (1396cc)",
          "LS CR4 BS-IV (1396cc)",
          "GLX (1396cc)",
          "LE (1405cc)",
          "LE TDI BS-III (1405cc)",
          "LE TDI BS-III. (1405cc)",
          "LE TDI DIESEL (1405cc)",
          "LS (1405cc)",
          "LS (1405cc)",
          "LS TDI BS-III (1405cc)",
          "LS TDI BS-III (1405cc)",
          "LS TDI DIESEL (1405cc)",
          "LX TDI BS-III (1405cc)",
          "LX TDI BS-III (1405cc)",
          "LX TDI DIESEL (1405cc)",
        ],
      },
      {
        model: "INDIGO XL",
        variants: [
          "CLASSIC (1396cc)",
          "GRAND (1396cc)",
          "BIFUEL (1396cc)",
          "CR4 (1396cc)",
          "TDI (1405cc)",
          "CLASSIC DICOR (1396cc)",
          "CLASSIC DICOR (1396cc)",
          "GRAND DICOR (1396cc)",
          "GRAND DICOR (1396cc)",
          "BIFUEL PETROL CNG (1396cc)",
          "CLASSIC PETROL (1396cc)",
          "CLASSIC PETROL (1396cc)",
          "GRAND PETROL (1396cc)",
          "GRAND PETROL (1396cc)",
          "CNG (1396cc)",
          "CNG (1396cc)",
          "CNG OE (1396cc)",
          "CNG OE (1396cc)",
          "SD CR4 (1396cc)",
          "SD CR4 (1396cc)",
          "CLASSIC DICOR (1405cc)",
          "DICOR CLASSIC (D) (1405cc)",
          "SD CR4 (1405cc)",
          "EURO 4 (1405cc)",
        ],
      },
      {
        model: "MOBIL",
        variants: ["MOBIL (1948cc)", "STANDARD (1948cc)"],
      },
      {
        model: "VISTA",
        variants: [
          "TECH LS BS III (1405cc)",
          "TECH LS BS III (1405cc)",
          "TECH LS BS IV (1248cc)",
          "TECH LS BS IV (1248cc)",
          "TECH LX BS III (1405cc)",
          "TECH VX BS IV (1248cc)",
          "TECH VX BS IV (1248cc)",
          "TECH  BS III (1405cc)",
          "QUADRAJET 75 LS (1248cc)",
          "QUADRAJET 75 LS (1248cc)",
          "QUADRAJET 75 VX (1248cc)",
          "QUADRAJET 75 VX (1248cc)",
          "QUADRAJET 90 VX (1248cc)",
          "QUADRAJET 90 VX (1248cc)",
          "QUADRAJET 90 ZX PLUS (1248cc)",
          "QUADRAJET 90 ZX PLUS (1248cc)",
          "QJET 75PS LS TECH (1248cc)",
          "QJET 75PS LS TECH (1248cc)",
          "QJET 75PS VX TECH (1248cc)",
          "QJET 75PS VX TECH (1248cc)",
          "QJET 90PS VX (1248cc)",
          "QJET 90PS VX (1248cc)",
          "QJET 90PS ZX (1248cc)",
          "QJET 90PS ZX (1248cc)",
          "QJET 90PS ZX PLUS (1248cc)",
          "QJET 90PS ZX PLUS (1248cc)",
        ],
      },
      {
        model: "INDIGO V SERIES",
        variants: [
          "VS (1405cc)",
          "V (1405cc)",
          "VE (1405cc)",
          "GV (1396cc)",
          "GVE (1396cc)",
          "GVS (1396cc)",
        ],
      },
      {
        model: "SPACIO",
        variants: [
          "A1 10STR (2956cc)",
          "A1 6STR (2956cc)",
          "GOLD 10STR (2956cc)",
          "GOLD 9STR (2956cc)",
          "GOLD PLUS 7STR (2956cc)",
          "GOLD PLUS 9STR (2956cc)",
          "GOLD SA 10STR (2956cc)",
          "SA S 6STR (2956cc)",
          "GOLD 6 STR (2956cc)",
          "SOFT TOP (2956cc)",
          "GOLD 10 SEATER (2956cc)",
          "GOLD PLUS 7 SEATER (2956cc)",
          "GOLD 9 SEATER (2956cc)",
          "SA S 6 SEATER (2956cc)",
          "GOLD 6 SEATER (2956cc)",
          "GOLD SA 10 SEATER (2956cc)",
          "A1 10 SEATER (2956cc)",
          "GOLD PLUS 9 SEATER (2956cc)",
          "A1 6 SEATER (2956cc)",
          "GOLD (2956cc)",
          "GOLD (2956cc)",
          "EGR (2956cc)",
          "EGR 9 STR (2956cc)",
          "10 STR. (2956cc)",
          "GOLD 11 SEATER (2956cc)",
          "HT (2956cc)",
          "SA (2956cc)",
          "GOLD PLUS SA 7STR (2956cc)",
          "ST (2956cc)",
          "10 SEATER (2956cc)",
          "GOLD E II (2956cc)",
          "GOLD PLUS SA 9STR (2956cc)",
          "E2 (2956cc)",
          "A1 SA E2 (2956cc)",
        ],
      },
      {
        model: "SAFARI DICOR",
        variants: [
          "2.2 VTT EX 4X4 5STR (2179cc)",
          "2.2 VTTEX 4X4 7STR (2179cc)",
          "2.2 VTT EX 4X4 8STR (2179cc)",
          "2.2 VTT EX 5STR (2179cc)",
          "2.2 VTT EX 7STR (2179cc)",
          "2.2 VTT EX 8STR (2179cc)",
          "2.2 VTT GX 4X4 7STR (2179cc)",
          "2.2 VTT GX 7STR (2179cc)",
          "2.2 VTT LX 5STR (2179cc)",
          "2.2 VTT LX 7STR (2179cc)",
          "2.2 VTT LX 8STR (2179cc)",
          "2.2 VTT VX 4X4 7STR (2179cc)",
          "2.2 VTT VX 4X4 8STR (2179cc)",
          "2.2 VTT VX 5STR (2179cc)",
          "2.2 VTT VX 7STR (2179cc)",
          "2.2 VTT VX 8STR (2179cc)",
          "3.0 EX 4X4 7STR (2956cc)",
          "3.0 EX 4X4 8STR (2956cc)",
          "3.0 EX 7STR (2956cc)",
          "3.0 EX 8STR (2956cc)",
          "3.0 LX 4X4 7STR (2956cc)",
          "3.0 LX 4X4 8STR (2956cc)",
          "3.0 LX 7STR (2956cc)",
          "3.0 LX 8STR (2956cc)",
          "3.0 VX 4X4 7STR (2956cc)",
          "3.0 VX 4X4 8STR (2956cc)",
          "3.0 VX 5STR (2956cc)",
          "3.0 VX 7STR (2956cc)",
          "3.0 VX 8STR (2956cc)",
          "4X2 EX DICOR BS-III (2179cc)",
          "4X2 LX DICOR BS-III (2956cc)",
          "2.2 VTT STORME EX (2179cc)",
          "2.2 VTT STORME VX (2179cc)",
          "2.2 VTT STORME VX 4X4 (2179cc)",
          "2.2 VTT STORME LX (2179cc)",
          "2.2 VTT VX 4X4 (2179cc)",
          "2.2 VTT EX 4X4 5 SEATER (2179cc)",
          "2.2 VTT VX 5 SEATER (2179cc)",
          "2.2 VTT EX 5 SEATER (2179cc)",
          "2.2 VTT LX 5 SEATER (2179cc)",
          "2.2 VTT EX 7 SEATER (2179cc)",
          "2.2 VTT EX 8 SEATER (2179cc)",
          "2.2 VTT EX 4X4 7 SEATER (2179cc)",
          "2.2 VTT EX 4X4 8 SEATER (2179cc)",
          "2.2 VTT LX 7 SEATER (2179cc)",
          "2.2 VTT LX 8 SEATER (2179cc)",
          "2.2 VTT VX 7 SEATER (2179cc)",
          "2.2 VTT VX 8 SEATER (2179cc)",
          "2.2 VTT VX 4X4 7 SEATER (2179cc)",
          "2.2 VTT VX 4X4 8 SEATER (2179cc)",
          "2.2 VTT GX 7 SEATER (2179cc)",
          "2.2 VTT GX 4X4 7 SEATER (2179cc)",
          "3.0 L VX 5 SEATER (2956cc)",
          "3.0 L EX 7 SEATER (2956cc)",
          "3.0 L EX 8 SEATER (2956cc)",
          "3.0 L EX 4X4 7 SEATER (2956cc)",
          "3.0 L EX 4X4 8 SEATER (2956cc)",
          "3.0 L LX 7 SEATER (2956cc)",
          "3.0 L LX 8 SEATER (2956cc)",
          "3.0 L LX 4X4 7 SEATER (2956cc)",
          "3.0 L LX 4X4 8 SEATER (2956cc)",
          "3.0 L VX 7 SEATER (2956cc)",
          "3.0 L VX 8 SEATER (2956cc)",
          "3.0 L VX 4X4 7 SEATER (2956cc)",
          "GX (2179cc)",
          "STORME EX (2179cc)",
          "VX 5 SEATER (2179cc)",
          "EX 7 SEATER (2179cc)",
          "LX 7 SEATER (2179cc)",
          "VX 7 SEATER (2179cc)",
          "EX 5 SEATER (2179cc)",
          "STORME LX (2179cc)",
          "LX 5 SEATER (2179cc)",
          "STORME VX (2179cc)",
          "2.2 VTT EXPLORER EDITION 7 SEATER (2179cc)",
          "2.2 VTT LX 4X4 7 SEATER (2179cc)",
          "2.2 VTT STORME VX 400 NM 7 SEATER (2179cc)",
          "2.2 VTT STORME VX 400 NM 4X4 7 SEATER (2179cc)",
          "2.2 VTT EX 6 SEATER (2179cc)",
          "2.2 VTT STORME VX 400 NM (2179cc)",
          "2.2 VTT STORME VX 400 NM 4X4 (2179cc)",
          "2.2 VTT STORME LX 5 SEATER (2179cc)",
          "4X2 EX DICOR 2.2 VTT (2179cc)",
          "VX VTT DICOR 2.2 4X2 (2179cc)",
          "LX 2.2 VTT DICOR (2179cc)",
          "4X4 EX DICOR 2.2 (2179cc)",
          "EX DICOR 2.2 4X2 (2179cc)",
          "VX DICOR 2.2 4X4 (2179cc)",
          "LX DICOR 4X2 (2179cc)",
          "3.0 L VX 4X4 8 SEATER (2956cc)",
          "VX 5 SEATER (2956cc)",
          "EX 7 SEATER (2956cc)",
          "VX 7 SEATER (2956cc)",
          "LX (2956cc)",
          "4X2 GX DICOR BS IV (2956cc)",
          "4X2 EX DICOR F/LIFT E-3 (2956cc)",
          "4X2 LX DICOR F/LIFT (2956cc)",
          "VX  2WD (2956cc)",
          "EX  2WD (2956cc)",
          "4X4 EX DICOR F/L E-3 (2956cc)",
          "VX  4WD 8STR (2956cc)",
          "EX  4WD (2956cc)",
          "LX  4WD (2956cc)",
          "LX  2WD (2956cc)",
          "4X2 VX FLIFT 8STR (2956cc)",
          "4X4 LX DICOR F/L E-3 (2956cc)",
          "EX (2956cc)",
          "VX  4WD (2956cc)",
        ],
      },
      {
        model: "YODHA",
        variants: [
          "CREW CABIN 4X2 VX (2200cc)",
          "CREW CABIN 4X2 BS6 (2179cc)",
          "2.2 4X2 CREW CAB (2179cc)",
        ],
      },
      {
        model: "ARIA",
        variants: [
          "PRESTIGE LEATHER 4X4 (2179cc)",
          "PRESTIGE LEATHER 4X4 (2179cc)",
          "PRESTIGE 4X4 (2179cc)",
          "PRESTIGE 4X4 (2179cc)",
          "PRIDE 4X4 (2179cc)",
          "PRIDE 4X4 (2179cc)",
          "PLEASURE 4X2 (2179cc)",
          "PLEASURE 4X2 (2179cc)",
          "PLEASURE 4X4 (2179cc)",
          "PLEASURE 4X4 (2179cc)",
          "PRESTIGE 4X2 (2179cc)",
          "PRESTIGE 4X2 (2179cc)",
          "PURE 4X2 (2179cc)",
          "PURE 4X2 (2179cc)",
          "PURE LX 4X2 (2179cc)",
          "PURE LX 4X2 (2179cc)",
          "0 (2179cc)",
          "PRESTIGE (2179cc)",
          "PRIDE (2179cc)",
          "PRIDE (2179cc)",
          "PURE (2179cc)",
          "PURE (2179cc)",
          "PLEASURE (2179cc)",
          "PRESTIGE PLUS 4X4 (2179cc)",
          "PRIDE 4X2 (2179cc)",
          "PRIDE 4X2 (2179cc)",
          "PURE LX (2179cc)",
          "PRIDE LEATHER 4X4 (2179cc)",
          "PRIDE LEATHER 4X4 (2179cc)",
          "PRESTIGE (2197cc)",
          "PLEASURE (2197cc)",
          "PRESTIGE 4X4 (2197cc)",
          "PRIDE 4X4 (2197cc)",
        ],
      },
      {
        model: "MOVUS",
        variants: [
          "CX 8 STR (2179cc)",
          "CX 8 STR (2179cc)",
          "CX 9 STR (2179cc)",
          "CX 9 STR (2179cc)",
          "LX 8 STR (2179cc)",
          "LX 8STR (2179cc)",
          "LX 9 STR (2179cc)",
          "LX 9 STR (2179cc)",
          "CX 7 STR (2179cc)",
          "CX 7 STR (2179cc)",
          "LX 7 STR (2179cc)",
          "LX 7 STR (2179cc)",
          "CX (2179cc)",
          "CX (2179cc)",
          "LX (2179cc)",
          "LX (2179cc)",
          "LX 9 SEATER (2179cc)",
          "CX 7 SEATER (2179cc)",
          "CX 8 SEATER (2179cc)",
          "CX 9 SEATER (2179cc)",
          "LX 7 SEATER (2179cc)",
          "LX 8 SEATER (2179cc)",
          "CX 7 STR CAPTAIN (2179cc)",
          "CX 7STR CAPTAIN (2179cc)",
          "LX 7 STR CAPTAIN (2179cc)",
          "LX 7 STR CAPTAIN (2179cc)",
          "1.5 REVOTORQ XZ PLUS DUAL TONE (1497cc)",
        ],
      },
      {
        model: "TIAGO EV",
        variants: [
          "XT LONG RANGE (1199cc)",
          "XE MEDIUM RANGE (1199cc)",
          "XT MEDIUM RANGE (1199cc)",
          "XZ PLUS LONG RANGE (1199cc)",
          "XZ PLUS TECH LUX LONG RANGE (1199cc)",
          "XT ELECTRIC (55000cc)",
          "XZ PLUS TECH LUX LONG RANGE FAST CHARGER (55000cc)",
          "XZ PLUS LONG RANGE FAST CHARGER (55000cc)",
          "XZ PLUS LONG RANGE (55000cc)",
          "XZ PLUS TECH LUX LONG RANGE (55000cc)",
          "XZ PLUS TECH LUX LONG RANGE FAST CHARGER (24cc)",
          "XZ PLUS TECH LUX LONG RANGE FAST CHARGER (24cc)",
          "XZ PLUS LONG RANGE FAST CHARGER (24cc)",
          "XZ PLUS LONG RANGE FAST CHARGER (24cc)",
          "XT LONG RANGE (24cc)",
          "XT LONG RANGE (24cc)",
          "XZ PLUS LONG RANGE (24cc)",
          "XZ PLUS LONG RANGE (24cc)",
          "XZ PLUS TECH LUX LONG RANGE (24cc)",
          "XZ PLUS TECH LUX LONG RANGE (24cc)",
          "XZ PLUS TECH LUX LONG RANGE FAST CHA (1199cc)",
          "XZ PLUS LONG RANGE FAST CHARGER (1199cc)",
          "XE MEDIUM RANGE (45000cc)",
          "XT MEDIUM RANGE (45000cc)",
          "XE MEDIUM RANGE (19.2cc)",
          "XT MEDIUM RANGE (19.2cc)",
        ],
      },
      {
        model: "INDICA EV2 XETA",
        variants: [
          "GLX (1193cc)",
          "GLX (1193cc)",
          "GL (1193cc)",
          "GLE (1193cc)",
          "GLS (1193cc)",
          "GLE (LPG) (1193cc)",
          "GLS (LPG) (1193cc)",
          "GVG (1193cc)",
          "GV (1193cc)",
          "GLG (1193cc)",
          "DLE (1193cc)",
          "GL (CNG) (1193cc)",
          "GLX BSV (CNG) (1193cc)",
        ],
      },
      {
        model: "NEXON EV",
        variants: [
          "MAX XZ PLUS FAST CHARGER (1582cc)",
          "MAX XZ PLUS (1582cc)",
          "XM (1582cc)",
          "XZ PLUS (1582cc)",
          "XZ PLUS LUX (1582cc)",
          "XZ PLUS DARK EDITION (1582cc)",
          "MAX XZ PLUS LUX (1582cc)",
          "MAX XZ PLUS LUXFAST CHARGER (1582cc)",
          "PRIME XM (1582cc)",
          "PRIME XZ PLUS (1582cc)",
          "PRIME XZ PLUS LUX (1582cc)",
          "EMPOWERED PLUS LR (107cc)",
          "FEARLESS LR (107cc)",
          "XZ PLUS LUX DARK EDITION (95cc)",
          "XZ PLUS LUX DARK EDITION (95cc)",
          "XZ PLUS (95cc)",
          "XZ PLUS LUX (95cc)",
          "XZ PLUS DARK EDITION (95cc)",
          "XZ PLUS DARK EDITION (95cc)",
          "XZ PLUS LUX DARK EDITION (1598cc)",
          "XZ PLUS (1598cc)",
          "XZ PLUS LUX (1598cc)",
          "XZ PLUS DARK EDITION (1598cc)",
          "XZ PLUS LUX DARK EDITION (97000cc)",
          "XZ PLUS (97000cc)",
          "XZ PLUS LUX (97000cc)",
          "XZ PLUS DARK EDITION (97000cc)",
          "XZ PLUS LUX DARK EDITION (97cc)",
          "XZ PLUS (97cc)",
          "XZ PLUS LUX (97cc)",
          "XZ PLUS. (1198cc)",
          "XZ PLUS LUX. (1198cc)",
          "XZ PLUS DARK EDITION. (1198cc)",
          "MAX XZ PLUS 3.3 KW (40.5cc)",
          "MAX XZ PLUS 7.2 KW FAST CHARGER (40.5cc)",
          "MAX XZ PLUS LUX 3.3 KW (40.5cc)",
          "MAX XZ PLUS LUX 7.2 KW FAST CHARGER (40.5cc)",
          "MAX XZ PLUS LUX 3.3 KW DARK EDITION (40.5cc)",
          "MAX XZ PLUS LUX 7.2 KW FAST CHARGER DARK EDITION (40.5cc)",
          "MAX XM 7.2 KW FAST CHARGER (40.5cc)",
          "MAX XZ PLUS LUX 3.3 KW JET (40.5cc)",
          "MAX XZ PLUS LUX 7.2 KW FAST CHARGER JET (40.5cc)",
          "MAX XM 3.3 KW (40.5cc)",
          "MAX XZ PLUS FAST CHARGER (40.5cc)",
          "MAX XZ PLUS (40.5cc)",
          "MAX XZ PLUS LUX (40.5cc)",
          "MAX XZ PLUS LUX FAST CHARGER (40.5cc)",
          "NEXON 2.0 EV MAX XZ PLUS LUX 7.2 (105cc)",
          "NEXON 2.0 EV MAX XZ PLUS 3.3 (105cc)",
          "MAX XZ PLUS LUX 3.3 KW JET (105cc)",
          "MAX XZ PLUS LUX 7.2 KW FAST CHARGER JET (105cc)",
          "MAX XM 3.3 KW (105cc)",
          "MAX XM 7.2 KW (105cc)",
          "MAX XZ PLUS LUX 3.3 KW JET (105000cc)",
          "MAX XZ PLUS LUX 7.2 KW FAST CHARGER JET (105000cc)",
          "PRIME XM (105000cc)",
          "MAX XZ PLUS LUX (105000cc)",
          "MAX XZ PLUS LUX FAST CHARGER (105000cc)",
          "MAX XM 3.3 KW (40500cc)",
          "MAX XM 7.2 KW (40500cc)",
          "PRIME XZ PLUS DARK EDITION (95000cc)",
          "PRIME XZ PLUS LUX DARK EDITION (95000cc)",
          "PRIME XZ PLUS LUX JET (95000cc)",
          "PRIME XZ PLUS (95000cc)",
          "PRIME XZ PLUS LUX (95000cc)",
          "PRIME XZ PLUS DARK EDITION (95cc)",
          "PRIME XZ PLUS LUX DARK EDITION (95cc)",
          "PRIME XZ PLUS LUX JET (95cc)",
          "PRIME XM (95cc)",
          "PRIME XZ PLUS (95cc)",
          "PRIME XZ PLUS LUX (95cc)",
          "XM (95cc)",
          "PRIME XZ PLUS DARK EDITION (1598cc)",
          "PRIME XZ PLUS LUX DARK EDITION (1598cc)",
          "PRIME XZ PLUS LUX JET (1598cc)",
          "PRIME XM (1598cc)",
          "PRIME XZ PLUS (1598cc)",
          "PRIME XZ PLUS LUX (1598cc)",
          "MAX XZ PLUS LUX JET (1598cc)",
          "MAX XZ PLUS LUX FAST CHARGER JET (1598cc)",
          "MAX XZ PLUS FAST CHARGER (1598cc)",
          "MAX XZ PLUS (1598cc)",
          "XM (1598cc)",
          "MAX XZ PLUS LUX (1598cc)",
          "MAX XZ PLUS LUX FAST CHARGER (1598cc)",
          "PRIME XZ PLUS DARK EDITION (30.2cc)",
          "PRIME XZ PLUS LUX DARK EDITION (30.2cc)",
          "PRIME XZ PLUS LUX JET (30.2cc)",
          "PRIME XM (30.2cc)",
          "PRIME XZ PLUS (30.2cc)",
          "PRIME XZ PLUS LUX (30.2cc)",
          "XM (97000cc)",
          "MAX XZ PLUS LUX (97000cc)",
          "XM (97cc)",
        ],
      },
      {
        model: "TIGOR EV",
        variants: [
          "XM PLUS BS6 (55000cc)",
          "XZ PLUS BS6 (55000cc)",
          "XZ PLUS LUX (55000cc)",
          "XT (55000cc)",
          "XZ PLUS DUAL TONE (55000cc)",
          "XM (55000cc)",
          "XE (55000cc)",
          "ZIPTRON XM (55cc)",
          "XZ+ (55cc)",
          "XZ+ (55cc)",
          "XZ+ LUX (55cc)",
          "XZ+ DUAL TONE (55cc)",
          "XZ+ DUAL TONE (55cc)",
          "XT (55cc)",
          "XM (55cc)",
          "XM (55cc)",
          "XE (55cc)",
          "XE (55cc)",
          "XZ+ (26cc)",
          "XZ+ LUX (26cc)",
          "XT (26cc)",
          "XE (26cc)",
          "XT (30cc)",
          "XT (30cc)",
          "XM (30cc)",
          "XE+ (30cc)",
          "XE+ (30cc)",
          "XM PLUS (1199cc)",
          "XZ PLUS DUAL TONE (1199cc)",
          "XT PLUS (1199cc)",
          "XZ PLUS (1199cc)",
          "XM (1199cc)",
          "XE (1199cc)",
          "XT PLUS (30000cc)",
        ],
      },
      {
        model: "MAGIC",
        variants: [
          "EXPRESS 14 SEATER/BSIV/DIESEL (798cc)",
          "EXPRESS 7 SEATER/DIESEL (798cc)",
          "EXPRESS 7 SEATER/SCH VAN (798cc)",
          "EXPRESS 6 SEATER/DIESEL BSVI (798cc)",
          "EXPRESS 9 SEATER/DIESEL BSVI (798cc)",
          "EXPRESS 11 SEATER/DIESEL BSVI (798cc)",
          "IRIS ZIVA 1650/FBV (3783cc)",
          "EXPRESS  M2 9 SEATER/DIESEL (798cc)",
          "TAXI 7+1 SEATER/SCH VAN (702cc)",
          "FACELIFT 7+1 SEATER/SCH VAN BSVI (702cc)",
          "FACELIFT 7 SEATER/SCH VAN BSVI (702cc)",
          "FACELIFT 6 SEATER/SCH VAN BSVI (702cc)",
          "FACELIFT SOFT TOP PETROL BSVI (702cc)",
          "IRIS 4 STR MINI VAN (611cc)",
          "MANTRA 6 SEATER/DIESEL (798cc)",
          "MANTRA 7 SEATER/DIESEL (798cc)",
          "MANTRA 9 SEATER/DIESEL (798cc)",
          "IRIS BS-IV/CNG (611cc)",
          "IRIS BS-III/DIESEL (611cc)",
          "IRIS 3 SEATER/CNG (611cc)",
          "BS-IV/CNG (702cc)",
          "BS-III/CNG (702cc)",
          "BS-IV/DIESEL (702cc)",
          "BS-III/DIESEL (702cc)",
          "ACE MAGIC (702cc)",
          "8 SEATER/DIESEL BSVI (702cc)",
          "10 SEATER BSVI (702cc)",
          "ACE MAGIC SOFT (702cc)",
          "7STR (702cc)",
          "7 STR (702cc)",
          "8 SEATER (702cc)",
          "8 STR (702cc)",
          "EXPRESS BS6 (702cc)",
          "BS-III (702cc)",
          "BS-IV (702cc)",
          "5 SEATER CNG BS III (702cc)",
          "5 SEATER CNG BS III (702cc)",
          "7 SEATER BS III (702cc)",
          "7 SEATER BS III (702cc)",
          "7 SEATER CNG BS III (702cc)",
          "7 SEATER CNG BS III (702cc)",
          "5 SEATER BS III (702cc)",
          "5 SEATER BS III (702cc)",
          "IRIS (702cc)",
          "MAGIC (702cc)",
          "CNG 5 SEATER (702cc)",
          "5 SEATER (702cc)",
          "EXPRESS 9 SEATER BSVI (798cc)",
          "MANTRA (798cc)",
          "MANTRA (798cc)",
          "EXPRESS 8 STR (798cc)",
          "MANTRA 8 STR (798cc)",
          "MANTRA 7 SEATER (798cc)",
          "EXPRESS 10 SEATER (798cc)",
          "EXPRESS 10 SEATER PETROL (798cc)",
          "EXPRESS D-9 BSVI (798cc)",
          "MANTRA BSIV 8STR GBS (798cc)",
          "MANTRA BSIV 8 STR (798cc)",
          "EXPRESS 10 STR. (798cc)",
          "EXPRESS (798cc)",
          "IRIS STANDARD (611cc)",
          "IRIS (611cc)",
          "IRIS (611cc)",
          "IRIS 4 STR (611cc)",
          "IRIS E BSIII (611cc)",
          "IRIS 4 SEATER (611cc)",
          "IRIS 5 SEATER (611cc)",
          "IRIS 4 SEATER CNG (611cc)",
          "EXPRESS 9 STR BS6 (978cc)",
          "1600 CC (1699cc)",
        ],
      },
      {
        model: "ACE",
        variants: [
          "702 (702cc)",
          "702/SCH VAN (702cc)",
          "MAGIC SOFT TOP (702cc)",
          "IRIS (611cc)",
          "HT (702cc)",
          "8 SEATER/DIESEL (702cc)",
          "MAGIC 7+1 SEATER/ SCH VAN (702cc)",
          "MAGIC BSIV/DIESEL (702cc)",
          "MAGIC BSIV/PETROL (702cc)",
          "MAGIC 4 SEATER/DIESEL (611cc)",
          "MAGIC 14 SEATER/DIESEL (702cc)",
          "MAGIC 9 SEATER/SCH VAN (702cc)",
          "MAGIC 11 SEATER/SCH VAN (702cc)",
          "MAGIC 13 SEATER/SCH VAN (702cc)",
          "MAGIC 7 SEATER/CNG (702cc)",
          "MAGIC 10 SEATER/DIESEL (702cc)",
          "MAGIC (702cc)",
          "MAGIC SOFT TOP 7 SEATER (702cc)",
          "MAGIC HARD TOP 5 SEATER (702cc)",
          "MAGIC HARD TOP 8 SEATER (702cc)",
          "MAGIC SOFT TOP 8 SEATER (702cc)",
          "MAGIC 6 SEATER (702cc)",
          "MAGIC HARD TOP 7 SEATER (702cc)",
          "MAGIC SOFT TOP 8 SEATER CNG (702cc)",
          "MAGIC BS III (702cc)",
          "HT 7 SEATERS (702cc)",
          "702 BSVI (702cc)",
          "MAGIC 8STR (702cc)",
          "HARD TOP (702cc)",
          "SUPER (1406cc)",
        ],
      },
      {
        model: "SFC 407",
        variants: ["11 SEATER/DIESEL (2956cc)"],
      },
      {
        model: "LP 407",
        variants: ["14 SEATER/DIESEL (2956cc)", "10 SEATER/DIESEL (2956cc)"],
      },
      {
        model: "LPT 407",
        variants: ["14 SEATER/CNG (2956cc)"],
      },
      {
        model: "LPO 1624",
        variants: ["12 SEATER/DIESEL (3783cc)"],
      },
      {
        model: "JAYEM NEO",
        variants: ["NEO 48 V (624cc)"],
      },
      {
        model: "MINI BUS",
        variants: ["13 SEATER BSVI (1948cc)"],
      },
      {
        model: "INDICA XETA",
        variants: [
          "GLS-BS III (1193cc)",
          "GLG-BS III (1193cc)",
          "GVS BSIII (1193cc)",
          "GVG BSIII (1193cc)",
          "GLE (1193cc)",
          "GLX (1193cc)",
          "GLG PETROL (1193cc)",
          "GLS (1193cc)",
          "GLS V2 (1193cc)",
          "GL-BS III (1396cc)",
          "GLG (1396cc)",
          "DLE E 2 (1396cc)",
          "GLS V2 BSIII (1396cc)",
          "GLE-BS III (1396cc)",
          "GLX-BS III (1396cc)",
          "GVG (1396cc)",
          "GVE P E-3 (1396cc)",
          "GLS 5 STR (1396cc)",
          "GLS V2 BSIII. (1405cc)",
          "1.4 GLS 5 STR (1405cc)",
          "GLS (1405cc)",
        ],
      },
      {
        model: "ESTATE",
        variants: [
          "STANDARD (1405cc)",
          "STANDARD (2540cc)",
          "STD (2540cc)",
          "STANDARD (1800cc)",
          "STD (1800) (1800cc)",
          "1.9  5 SEATER (1948cc)",
          "1.9  8 SEATER (1948cc)",
          "STD (1948cc)",
          "STD (1948cc)",
          "0 (1948cc)",
          "ESTATE (1948cc)",
        ],
      },
      {
        model: "XPRES-T EV",
        variants: [
          "0 (30000cc)",
          "XM PLUS (30000cc)",
          "XM ++ (31000cc)",
          "XT PLUS (31000cc)",
          "XM PLUS (30cc)",
          "XT PLUS (30cc)",
          "XM (998cc)",
          "XM PLUS (998cc)",
          "XZ (998cc)",
          "XZ PLUS (998cc)",
          "XT PLUS (998cc)",
          "XM PLUS (1598cc)",
          "XT PLUS (1598cc)",
          "XM PLUS (21.5cc)",
          "XT PLUS (21.5cc)",
        ],
      },
      {
        model: "SUMO SPACIO",
        variants: [
          "STD (2956cc)",
          "STD (2956cc)",
          "SA (2956cc)",
          "SA (2956cc)",
          "A1 10 STR (2956cc)",
          "A1 10 STR (2956cc)",
          "SA 6 STR (2956cc)",
          "SA 6 STR (2956cc)",
          "SA GOLD (2956cc)",
          "SA GOLD (2956cc)",
          "ST (2956cc)",
          "ST (2956cc)",
          "6 STR (2956cc)",
          "6 STR (2956cc)",
          "GOLD (2956cc)",
          "GOLD (2956cc)",
        ],
      },
      {
        model: "TOURIN",
        variants: [
          "0 (1948cc)",
          "0 (1948cc)",
          "0 (1948cc)",
          "TOURIN 10 STR (1948cc)",
          "TOURIN 6 STR (1948cc)",
        ],
      },
      {
        model: "GRANDE DICOR",
        variants: [
          "CX (2179cc)",
          "EX DICOR (2179cc)",
          "CX TURBO (2179cc)",
          "LX TURBO (2179cc)",
          "EX TURBO (2179cc)",
          "LX DICOR (2179cc)",
        ],
      },
      {
        model: "INDICAB",
        variants: [
          "DL BS-II (1405cc)",
          "DLE BS-II (1405cc)",
          "1.4 (1405cc)",
          "DLE (1405cc)",
          "DLE (1405cc)",
          "DL (1405cc)",
          "DL (1405cc)",
          "DL BS-III (1405cc)",
          "DL BS-III (1405cc)",
          "DLE BS-III (1405cc)",
          "DLE BS-III (1405cc)",
        ],
      },
      {
        model: "MANZA",
        variants: [
          "EX QUADRAJET (1248cc)",
          "EX QUADRAJET (1248cc)",
          "LS QUADRAJET (1248cc)",
          "LS QUADRAJET (1248cc)",
          "LX QUADRAJET (1248cc)",
          "LX QUADRAJET (1248cc)",
          "VX QUADRAJET (1248cc)",
          "VX QUADRAJET (1248cc)",
          "EXL QUADRAJET (1248cc)",
          "EXL QUADRAJET. (1248cc)",
          "AURA QUADRAJET (1248cc)",
          "AQUA QUADRAJET BS-III (1248cc)",
          "AQUA QUADRAJET BS-IV (1248cc)",
          "AURA ABS QUADRAJET BS-III (1248cc)",
          "AURA ABS QUADRAJET BS-IV (1248cc)",
          "AURA ABS QUADRAJET BS-IV (1248cc)",
          "AURA ABS SAFIRE BS-IV (1248cc)",
          "AURA QUADRAJET BS-III (1248cc)",
          "AURA QUADRAJET BS-IV (1248cc)",
          "AURA QUADRAJET BS-IV (1248cc)",
          "AURA PLUS QUADRAJET BS-III (1248cc)",
          "CELEBRATION QUADRAJET (1248cc)",
          "ELAN QUADRAJET BS-IV (1248cc)",
          "ELAN QUADRAJET BS-IV (1248cc)",
          "SAFIRE LS (1248cc)",
          "SAFIRE LX (1248cc)",
          "SAFIRE VX (1248cc)",
          "SAFIRE EX (1248cc)",
          "SAFIRE EXL (1248cc)",
          "ELAN QUADRAJET BS III (1248cc)",
          "CLUB CLASS EX QUADRAJET (1248cc)",
          "AURA PLUS QUADRAJET BS-IV (1248cc)",
          "AURA PLUS QUADRAJET BS-IV (1248cc)",
          "AQUA SAFIRE (1368cc)",
          "CLUB CLASS EXL (1368cc)",
          "CLUB CLASS VX (1368cc)",
          "CLUB CLASS LX (1368cc)",
          "CLUB CLASS EX (1368cc)",
          "CLUB CLASS LS (1368cc)",
          "CLUB CLASS GEXL (1368cc)",
          "AQUA SAFIRE BS-III (1368cc)",
          "AQUA SAFIRE BS-IV (1368cc)",
          "AQUA SAFIRE BS-IV (1368cc)",
          "AURA ABS SAFIRE BS-III (1368cc)",
          "AURA ABS SAFIRE BS-IV (1368cc)",
          "AURA SAFIRE BS-III (1368cc)",
          "AURA SAFIRE BS-IV (1368cc)",
          "AURA SAFIRE BS-IV (1368cc)",
          "AURA PLUS SAFIRE BS-III (1368cc)",
          "AURA PLUS SAFIRE BS-IV (1368cc)",
          "AURA PLUS SAFIRE BS-IV (1368cc)",
          "ELAN SAFIRE BS-IV (1368cc)",
          "GLS (1368cc)",
          "GLX (1368cc)",
          "GVX (1368cc)",
          "GEX (1368cc)",
          "SAFIRE GLS. (1368cc)",
          "SAFIRE GLS (1368cc)",
          "SAFIRE GEX (1368cc)",
          "SAFIRE GEX (1368cc)",
          "SAFIRE GLX (1368cc)",
          "SAFIRE GLX (1368cc)",
          "SAFIRE GVX (1368cc)",
          "SAFIRE GVX (1368cc)",
          "AURA SAFIRE (1368cc)",
          "ELAN SAFIRE (1368cc)",
          "CLUB CLASS SAFIRE90 GLS (1368cc)",
          "CLUB CLASS SAFIRE90 GEX (1368cc)",
          "CLUB CLASS SAFIRE90 GVX (1368cc)",
          "CLUB CLASS SAFIRE90 GLX (1368cc)",
        ],
      },
      {
        model: "TELCOLINE",
        variants: [
          "11 STR (1948cc)",
          "SINGLE CABIN 4X4 (1948cc)",
          "DOUBLE CABIN 4X2 (1948cc)",
          "DOUBLE CABIN 4X4 (1948cc)",
          "4WD TURBO INTERCOOLER (2092cc)",
          "4X2 - STR 11 (2820cc)",
        ],
      },
      {
        model: "TL 4X4",
        variants: [
          "STD (1948cc)",
          "CREW CAB 7 SEATER (1948cc)",
          "CREW CAB (1948cc)",
          "CREW CAB PASSENGER BODY WITH SOFT TOP (1948cc)",
          "CC PASS (1948cc)",
        ],
      },
      {
        model: "CREW CAB",
        variants: [
          "5 STR (1948cc)",
          "2 (1948cc)",
          "TL 4X4 CREWCAB (1948cc)",
          "0 (1948cc)",
        ],
      },
      {
        model: "SUPER ACE",
        variants: ["5 SEATER (1406cc)"],
      },
      {
        model: "LP 712",
        variants: ["EX CAMPER VAN (3783cc)"],
      },
      {
        model: "TL 4X2",
        variants: ["CREW CAB WITH SOFT TOP 11 SEATER (1948cc)"],
      },
      {
        model: "INDICA GV",
        variants: ["0 (1405cc)"],
      },
      {
        model: "PRATIK HONDA 01",
        variants: ["0 (900.5cc)"],
      },
      {
        model: "1612 MAKE UP VAN",
        variants: ["0 (3783cc)"],
      },
      {
        model: "LP 909",
        variants: ["0 (3783cc)"],
      },
      {
        model: "1613 VANITY VAN",
        variants: ["0 (4000cc)"],
      },
      {
        model: "XM",
        variants: ["FITTED WITH 1.5 CRAIL 110PS BS4 (1198cc)"],
      },
      {
        model: "XTA 1.2 L RTN BSIV",
        variants: ["0 (1199cc)"],
      },
      {
        model: "TIAGO NRG",
        variants: [
          "DIESEL (1047cc)",
          "DIESEL (1047cc)",
          "XZA REVOTRN 1.2L (1199cc)",
          "AMT (1199cc)",
          "AMT (1199cc)",
          "XT ICNG (1199cc)",
          "XT ICNG (1199cc)",
          "XZ ICNG (1199cc)",
          "XZ ICNG (1199cc)",
          "XZ AMT (1199cc)",
          "XZ AMT (1199cc)",
          "XZ MT (1199cc)",
          "XZ MT (1199cc)",
          "XT MT (1199cc)",
          "XT MT (1199cc)",
          "PETROL (1199cc)",
          "PETROL (1199cc)",
        ],
      },
    ],
  },
  {
    make: "AUSTIN",
    models: [
      {
        model: "AUSTIN",
        variants: ["CLASSIC (3000cc)", "2000 CC (2000cc)"],
      },
      {
        model: "MG",
        variants: ["MIDGET TOURER (1061cc)"],
      },
      {
        model: "VINTAGE",
        variants: [
          "AUSTIN 7 (997cc)",
          "AUSTIN (900cc)",
          "AUSTIN (2660cc)",
          "AUSTIN (796cc)",
          "AUSTIN (747cc)",
          "BABY 7 CONVERTIBLE (700cc)",
          "TOURER (1030cc)",
          "AUSTIN 10 (1396cc)",
          "A 40 DEVON (985cc)",
          "CAMBRIDGE (1622cc)",
          "PRINCESS (3995cc)",
        ],
      },
    ],
  },
  {
    make: "TOYOTA",
    models: [
      {
        model: "3244",
        variants: ["0 (27cc)"],
      },
      {
        model: "LAND CRUISER",
        variants: [
          "PRADO 2.8 D LJ90R (2779cc)",
          "PRADO 2.8 D LJ90R (2779cc)",
          "PRADO 3.0 VXL (2982cc)",
          "PRADO LAND CRUISER PRADO (2800cc)",
          "PRADO VX AT (3956cc)",
          "2.4 EFI TURBO 7STR (2446cc)",
          "5.7 V8 4X4 (5663cc)",
          "LX 470 (4163cc)",
          "4.2 UZJ 100 (4163cc)",
          "4.5 UZJ 200 V8 (4500cc)",
          "4.7 UZJ 200 V8 5STR (4663cc)",
          "4.0 VX V8 (4461cc)",
          "STD (4461cc)",
          "LC200 VX PREMIUM (4461cc)",
          "LC200 VX STANDARD (4461cc)",
          "4.7 UZJ 200 V8 (4663cc)",
          "LC300 ZX (3340cc)",
          "PRADO (2890cc)",
          "200 (4700cc)",
          "120 (3956cc)",
          "GX (4163cc)",
          "4.2 AMAZON TD VX (4163cc)",
          "4.5 V8 D-4D (4461cc)",
          "VDJ 200 R GENTZW (4461cc)",
          "JEEP (3000cc)",
          "V8 (4461cc)",
          "PRADO TX LIMITED (3000cc)",
          "PRADO VX-L (2982cc)",
          "PRADO VX L (2982cc)",
          "LC 200 VX (4461cc)",
          "2.0 LC5 DIESEL 6 SEATER (2000cc)",
          "PRADO VX (2179cc)",
          "2.4 EFI TURBO DIESEL 7 SEATER (2446cc)",
          "EFI TURBO (2446cc)",
          "V8 (2500cc)",
          "PRADO 2.7 TX LIMITED AT (2693cc)",
          "PRADO TX LIMITED (2693cc)",
          "PRADO 2.8L 8 SEATER (2779cc)",
          "PRADO 8 SEATER (2779cc)",
          "PRADO LJ90R (2779cc)",
          "PRADO 2.6 DIESEL (2800cc)",
          "PRADO. (2850cc)",
          "PRADO STANDARD (2890cc)",
          "PRADO VX-L 3.0 (2982cc)",
          "PRADO VX-L 3.0 (2982cc)",
          "PRADO VX - L AUTOMATIC 3.0 (2982cc)",
          "PRADO 3.0 L (2982cc)",
          "PRADO 3.2 (2982cc)",
          "AX (2982cc)",
          "PRADO 30 VX A/T (2982cc)",
          "STATION WAGON (3000cc)",
          "PRADO TZ (3000cc)",
          "LC 300 BSVI (3346cc)",
          "300 ZX DIESEL 5 SEATER AT (3346cc)",
          "LC 300 (3346cc)",
          "ZX (3346cc)",
          "PRADO TZ (3378cc)",
          "FJ 60 (3431cc)",
          "PS (3500cc)",
          "V8 (3596cc)",
          "3.0 FJ CRUSIER PETROL (3950cc)",
          "PRADO VX (AUTOMATIC TRANSMISSION) (3956cc)",
          "PRADO VX (AUTOMATIC TRANSMISSION) (3956cc)",
          "PRADO 4.0 VX 5 SEATER (120 SERIES) (3956cc)",
          "VX (3956cc)",
          "PRADO VX 8 STR (3956cc)",
          "4.0 AT 7 SEATER (3956cc)",
          "PRADO (3956cc)",
          "PRADO VT (3956cc)",
          "PRADO TX (3956cc)",
          "PRADO VXL (3956cc)",
          "P5 (3956cc)",
          "PRADO VX (3956cc)",
          "STATION WAGON (3976cc)",
          "PRADO WAGON (3976cc)",
          "PRADO V6 (3987cc)",
          "4.2 VX LC 200 5 SEATER (4160cc)",
          "UZJ 100 - 4.2 L (4163cc)",
          "UZJ 100 (4163cc)",
          "LX 470 5 SEATER (4163cc)",
          "AMAZON TD VX (4163cc)",
          "LX 470 7 SEATER (4163cc)",
          "LX 470 4.2 DIESEL 7 SEATER (4163cc)",
          "4.2 VX (4200cc)",
          "4.2 AMAZON TD VX 5 SEATER (4200cc)",
          "V8 (4200cc)",
          "G SELECTION (4200cc)",
          "Prado (4200cc)",
          "0 (4461cc)",
          "VX 4.0 V8 (4461cc)",
          "PRADO GRJ 120R 10 SEATER (4461cc)",
          "200 VX AT 4.5L 5 SEATER (4461cc)",
          "200 VX AT 4.5 HIGH SECURE (8 SEATER) (4461cc)",
          "200 4.5 VX (AT) (4461cc)",
          "200 4.5 VX L (4461cc)",
          "200 VX STANDARD (4461cc)",
          "200 VX STANDARD (4461cc)",
          "200 VX PREMIUM (4461cc)",
          "200 VX PREMIUM (4461cc)",
          "200 VX AT 4.5 (4461cc)",
          "200 VX AT (4461cc)",
          "200 VDJ GENTZW (4461cc)",
          "200 LJ (4461cc)",
          "VX 7STR (4461cc)",
          "VX 8STR (4461cc)",
          "200 7STR (4461cc)",
          "LC200 VX STD (4461cc)",
          "200 VX OPTION PACK (4461cc)",
          "LC 101 (4461cc)",
          "VX V8 (4461cc)",
          "VX (4461cc)",
          "4.5 V8 AUTOMATIC (4461cc)",
          "LC 200 8 STR (4461cc)",
          "VX 200 8 STR (4461cc)",
          "LC 200 (4461cc)",
          "V8 4.5 D-4D EXECUTIVE (4461cc)",
          "LC200 VX PREMIUM 2 (4461cc)",
          "LC200 VX STANDARD 1 (4461cc)",
          "200 (4461cc)",
          "200 V8 (4461cc)",
          "200 VX (4461cc)",
          "200 VX STANDARD AT (4461cc)",
          "LC200 VX OP 1 (4461cc)",
          "LC200 VX OP 2 (4461cc)",
          "V8 4.5 D (4461cc)",
          "200 VX PREMIUM AT (4461cc)",
          "UZJ 200 4.5 V8 (4500cc)",
          "GXL V8 (4500cc)",
          "GXL V8 7STR (4500cc)",
          "UZJ 200 4.5 V8 6 SEAT (4500cc)",
          "V8 (4500cc)",
          "200 V8 (4500cc)",
          "200 V8 (4500cc)",
          "LX 450 (4500cc)",
          "Prado (4500cc)",
          "URJ202 (4600cc)",
          "V8 7 STR (4608cc)",
          "V8 PETROL (4608cc)",
          "VX 200 PETROL (4608cc)",
          "UZJ 200 4.7 V8 8 SEAT (4660cc)",
          "UZJ 200 4.7 V8 5 SEAT (4663cc)",
          "CYGNUS (4663cc)",
          "UZJ 200 4.7 V8 7 SEAT (4663cc)",
          "200 V8 (4663cc)",
          "AMAZON VX (4663cc)",
          "PRADO V8 (4700cc)",
          "200 STANDARD (4700cc)",
          "4.7 CYGNUS 8 SEATER (4700cc)",
          "CYGNUS (4700cc)",
          "4X4 5.7L V8 (5663cc)",
          "V8 (5663cc)",
        ],
      },
      {
        model: "COROLLA",
        variants: [
          "1.8 G (1798cc)",
          "1.8 G CVT (1798cc)",
          "1.8 GL (1798cc)",
          "1.8 VL (1798cc)",
          "1.8 J H1 (1794cc)",
          "1.8 E H2 (1794cc)",
          "1.8 E H5 (1794cc)",
          "1.8 E H6 (1794cc)",
          "1.8 H7 (1794cc)",
          "1.8 J H8 (1794cc)",
          "DG (1364cc)",
          "DGL (1364cc)",
          "HE 1.8 J / CNG (1794cc)",
          "K CLASSIC (1364cc)",
          "PETROL (1794cc)",
          "H5 (ANNIVERSARY) (1794cc)",
          "H3 1.8 G (1794cc)",
          "H4 1.8 G (1794cc)",
          "HE 1.8 J (1794cc)",
          "DX (1762cc)",
          "DG (1798cc)",
          "DGL (1798cc)",
          "H7 1.8 (1794cc)",
          "LEWIN FZ (1364cc)",
          "ALTIS D 40 J (BS IV) (1364cc)",
          "H3 (1794cc)",
          "LE HOUSTON TX (1794cc)",
          "H5 (1794cc)",
          "HE 1.8 (1794cc)",
          "H 1 (1794cc)",
          "H4 1.8 (1794cc)",
          "1.8 J (1794cc)",
          "1.8 J (1794cc)",
          "H7 (1794cc)",
          "H1 J (1794cc)",
          "H6 E (1794cc)",
          "H5 E (1794cc)",
          "H4 G (1794cc)",
          "H2 E (1794cc)",
          "HE J (1794cc)",
          "H3 G (1794cc)",
          "H1 1,8 (1794cc)",
          "H6 (1794cc)",
          "AE (1794cc)",
          "H2 (1794cc)",
          "1.8 LE H7 (1794cc)",
          "DX (1794cc)",
          "NZ (1490cc)",
          "NZ  1.4 PETROL (1490cc)",
          "5 STR (1394cc)",
          "H7 (1798cc)",
          "H2 1.8E (1798cc)",
          "H1 1.8J (1798cc)",
          "H3 1.8G (1798cc)",
          "H4 1.8G (1798cc)",
          "H5 1.8E (1798cc)",
          "HE 1.8J (1798cc)",
          "SE (1500cc)",
          "H6 (1799cc)",
          "DX (1298cc)",
          "H6 1.8 E (1794cc)",
          "H2 1.8 E (1794cc)",
          "H2 1.8 E (1794cc)",
          "H1 1.8 J (1794cc)",
          "H5 1.8 E (1794cc)",
        ],
      },
      {
        model: "QUALIS",
        variants: [
          "GS 10STR (2446cc)",
          "GS (2446cc)",
          "GST (2446cc)",
          "FLEET A3 (2446cc)",
          "MYST L6 (2446cc)",
          "FS (2446cc)",
          "RS (1998cc)",
          "RS (2446cc)",
          "RS PETROL (1998cc)",
          "2.0 GS 8 SEATER (1998cc)",
          "RS DIESEL (2446cc)",
          "GS 10 SEATER (2446cc)",
          "FS F4 (2446cc)",
          "GS C2 (2446cc)",
          "GS C6 (2446cc)",
          "2.4D GS E 8 STR (2446cc)",
          "2.4 DFS 5 STR (2446cc)",
          "2.4D GS 9 STR (2446cc)",
          "2.4D RS 7STR (2446cc)",
          "2.4 D FS 10 SEATER (2446cc)",
          "GS 5 SEATER (2446cc)",
          "MYST 8 SEATER (2446cc)",
          "MYST 10 SEATER (2446cc)",
          "2.4 D FS 8 SEATER (2446cc)",
          "2.4 D FS 9 SEATER (2446cc)",
          "2.4 D RS (2446cc)",
          "GS 9 SEATER (2446cc)",
          "FS F2 (2446cc)",
          "FS- F2 (2446cc)",
          "GST D2 (2446cc)",
          "GST D5 (2446cc)",
          "RS E3 (2446cc)",
          "GST SUPER (2446cc)",
          "FLEET A1 (2446cc)",
          "GS G8 (2446cc)",
          "MYST L5 (2446cc)",
          "GS G9 (2446cc)",
          "GS G4 (2446cc)",
          "RST (2446cc)",
          "2.4 8STR (2446cc)",
          "L6  BLACK (2446cc)",
          "8-G4 DUAL AC (2446cc)",
          "L6  LTD EDITION (2446cc)",
          "FS L3 (2446cc)",
          "FS A2 10STR (2446cc)",
          "FS D AC 7STR (2446cc)",
          "GS C2 10STR (2446cc)",
          "FS L4 (2446cc)",
          "8-G8 DUAL AC (2446cc)",
          "GS-G5(M) (2446cc)",
          "GS C1 10STR (2446cc)",
          "FS B4 8STR (2446cc)",
          "RS-E3(M) (2446cc)",
          "FS A1 10STR (2446cc)",
          "RS-E2(M) (2446cc)",
          "L5 LTD EDITION (2446cc)",
          "GS C9 (2446cc)",
          "RS E2 (2446cc)",
          "FS A3 (2446cc)",
          "FS-A3 (2446cc)",
          "RS E3 DIESEL (2446cc)",
          "QUALIS B2 (2446cc)",
          "RST 8 STR (2446cc)",
          "FS F6 8STR (2446cc)",
          "RS E2 PETROL (2446cc)",
          "2.4D RS E (2446cc)",
          "FS A1 (2446cc)",
          "2.4 D GS (2446cc)",
          "2.4D FS (2446cc)",
          "GST D6 (2446cc)",
          "GST-D6 (2446cc)",
          "GST D3 (2446cc)",
          "GST-D3 (2446cc)",
          "FS B4 (2446cc)",
          "FS-B4 (2446cc)",
          "FS F7 (2446cc)",
          "FS-F7 (2446cc)",
          "FS B1 (2446cc)",
          "FS-B 1 (2446cc)",
          "FS F3 (2446cc)",
          "FS- F3 (2446cc)",
          "FS B2 (2446cc)",
          "FS-B 2 (2446cc)",
          "FS F6 (2446cc)",
          "FS-F6 (2446cc)",
          "FS B6 (2446cc)",
          "FS B6 (2446cc)",
          "FS B3 (2446cc)",
          "FS-B 3 (2446cc)",
          "FS F5 (2446cc)",
          "FS-F5 (2446cc)",
          "GS C1 (2446cc)",
          "GS C1 (2446cc)",
          "GS C7 (2446cc)",
          "GS C7 (2446cc)",
          "GS C3 (2446cc)",
          "GS C3 (2446cc)",
          "GS C8 (2446cc)",
          "GS C8 (2446cc)",
          "GS C4 (2446cc)",
          "GS C4 (2446cc)",
          "GS C5 (2446cc)",
          "GS C5 (2446cc)",
          "GS G1 (2446cc)",
          "GS G1 (2446cc)",
          "FS B5 (2446cc)",
          "GS G5 (2446cc)",
          "L5/L6 (2446cc)",
          "2.4D GS E 9 STR (2449cc)",
          "2.4 D LS 8 SEATER (2494cc)",
          "2.4 D GST 8 SEATER (2496cc)",
        ],
      },
      {
        model: "FORTUNER",
        variants: [
          "FA MT (2982cc)",
          "FL MT (2982cc)",
          "2.8 4X2 (AT) SE (2755cc)",
          "2.7 4X4 (AT) (2694cc)",
          "2.7 4X4 (MT) (2694cc)",
          "FB 4X4 (MT) (2982cc)",
          "FC 4X2 (MT) (2982cc)",
          "FD 4X2 (AT) (2982cc)",
          "FX TRD SPORTIVO (AT) (2982cc)",
          "FY TRD SPORTIVO 4X2 (MT) (2982cc)",
          "FZ TRD SPORTIVO 4X2 (AT) (2982cc)",
          "3.0 TRD SPORTIVO 2WD (MT) (2982cc)",
          "FE 4X2 (AT) (2982cc)",
          "3.0L 4WD (2982cc)",
          "FE 4X2 (MT) (2982cc)",
          "2.8L 4X2 AT (2755cc)",
          "2.5 4X2 (2494cc)",
          "TRD SPORTIVO AT (2755cc)",
          "2.8 4X4 AT LEGENDER (2755cc)",
          "2.8 ZV MT (2755cc)",
          "2.8 4X2 AT LEGENDER (2755cc)",
          "3.0L 2WD MT (2982cc)",
          "3.0L 2WD AT (2982cc)",
          "2.7 4X2 AT (2694cc)",
          "2.7 4X2 MT (2694cc)",
          "2.8 4X2 AT (2755cc)",
          "2.8 4X2 MT (2755cc)",
          "2.8 4X4 AT (2755cc)",
          "2.8 4X4 MT (2755cc)",
          "(2.7L) 4X2 AT (2694cc)",
          "(2.7L) 4X2 MT (2694cc)",
          "(2.8L) 4X2 MT (2755cc)",
          "(2.8L) 4X4 AT (2755cc)",
          "(2.8L) 4X4 MT (2755cc)",
          "FA MANUAL TRANSMISSION (2982cc)",
          "FB 4X4 MANUAL (2982cc)",
          "(2016-2020) 2.8 4X2 AT TRD (2755cc)",
          "(2016-2020) SIGMA 4 (2755cc)",
          "(2016-2020) TRD SPORTIVO (2755cc)",
          "2.8 2WD AT (2755cc)",
          "2.8 2WD MT (2755cc)",
          "2.8 4WD AT (2755cc)",
          "2.8 4WD AT (2755cc)",
          "2.8 4*4 AT (2755cc)",
          "2.8 4*2 MT (2755cc)",
          "2.8 4*2 AT (2755cc)",
          "2.8 4*4 MT (2755cc)",
          "LEGENDER 2.8 4*4 AT (2755cc)",
          "2.8L SIGMA 4 AT (2755cc)",
          "GRS 2.8L 4WD AT (2755cc)",
          "2. 8 TRD SPORTIVO 4X2 AT (2755cc)",
          "2.8L SIGMA AT (2755cc)",
          "2.8L SIGMA (2755cc)",
          "2.8 4X2 AT TRD LIMITED EDITION (2755cc)",
          "2.8 4X4 AT TRD LIMITED EDITION (2755cc)",
          "2WD 2.8L 6AT SP (2755cc)",
          "2.8L 2WD 6MT (2755cc)",
          "LEGENDER 2.8 4X4 AT (2755cc)",
          "TRD CELEBRATORY EDITION (2755cc)",
          "TRD SPORTIVO (2755cc)",
          "LEGENDER 2.8 4X2 AT (2755cc)",
          "SIGMA 4 (2755cc)",
          "GR-S (2755cc)",
          "2.8 LEGENDER 4X2 AT (2755cc)",
          "2.8 TRD SPORTIVO (2755cc)",
          "2.8 LEGENDER 4X4 AT (2755cc)",
          "2.8 GR S 4X4 AT (2755cc)",
          "2.8 4X2 AT TRD (2755cc)",
          "L (2982cc)",
          "V (2982cc)",
          "2WD AT TRD SPORTIVO (2982cc)",
          "AT SPORTIVO (2982cc)",
          "FD AT (2982cc)",
          "FE AT (2982cc)",
          "4X4 (2982cc)",
          "FE MT (2982cc)",
          "FB MT (2982cc)",
          "FC MT (2982cc)",
          "FZ SPORTIVO AT (2982cc)",
          "FX SPORTIVO AT (2982cc)",
          "SPORTIVO (2982cc)",
          "FY SPORTIVO MT (2982cc)",
          "MT (2982cc)",
          "AT (2982cc)",
          "2.7L 4X2 AT BS-VI (2982cc)",
          "3.0 L 4X2 MT TRD SPORTIVO (2982cc)",
          "3.0L 4WD MT 6 STR (2982cc)",
          "3.0 L 2WD MT 10STR (2982cc)",
          "3.0 L 2WD (2982cc)",
          "3.0 L 2WD 6 STR (2982cc)",
          "ANNIVERSARY EDITION (2982cc)",
          "3.0L 4WD MT BSIV (2982cc)",
          "TRD SPORTIVO (2982cc)",
          "3.0 4X2 MT (2982cc)",
          "3.0 4X2 AT (2982cc)",
          "3.0 4X4 AT (2982cc)",
          "3.0 4X4 MT (2982cc)",
          "3.0 LTD (2982cc)",
          "4X2 AT (2982cc)",
          "4 X 2 AT (2982cc)",
          "4X2 MT (2982cc)",
          "4 X 2 MT (2982cc)",
          "SPORTIVO 4 X 2 MT (2982cc)",
          "4 X 4 MT (2982cc)",
          "2012 4 X 2 AT (2982cc)",
          "2012 4 X 2 MT (2982cc)",
          "2012 4 X 4 MT (2982cc)",
          "FD 2WD (2982cc)",
          "4X2 MT 3.0L (2982cc)",
          "3.0L 4X4 AT (2982cc)",
          "3.0L 4X2 AT (2982cc)",
          "TRD SPORTIVO LIMTED EDITION (2982cc)",
          "3.0 L (2982cc)",
          "4X4 MT LIMITED EDITION (2982cc)",
          "SPORTIVO 4X2 AT (2982cc)",
          "3.0 MT (2982cc)",
          "G (2494cc)",
          "SPORTIVO (2494cc)",
          "2.5 SPORTIVO 4X2 MT (2494cc)",
          "2.5 SPORTIVO 4X2 AT (2494cc)",
          "2.5 TRD SPORTIVO 4 X 2 AT (2494cc)",
          "2.7 4*2 AT (2694cc)",
          "2.7 4*2 MT (2694cc)",
          "2.7 VVTI RB 6AT (2694cc)",
          "2.7 4WD (2698cc)",
          "2.5L 4*2 (2499cc)",
          "2.5L 4X2 (2499cc)",
          "FC 4X2 MANUAL (2982cc)",
          "FD 4X2 AUTO (2982cc)",
          "FL MANUAL TRANSMISSION (2982cc)",
          "3.0L 2WD MT TRD SPORTIVO (2755cc)",
          "FE 4X2 AUTOMATIC TRANSMISSION (2982cc)",
          "FX TRD SPORTIVO AT T (2982cc)",
          "FZ TRD SPORTIVO 4X2 AT T (2982cc)",
          "2.5 TRD SPORTIVO 4 X 2 MT (2494cc)",
        ],
      },
      {
        model: "LEXUS",
        variants: [
          "LX 470 V8 8STR (4700cc)",
          "LS 500H DISTINCT (3456cc)",
          "LS 600 HYBRID L (4969cc)",
          "LX 500 D BSVI (3346cc)",
          "LX 570 (5663cc)",
          "LS 460 SE (4605cc)",
          "SC 430 (4298cc)",
          "SC 430 (4298cc)",
          "LS 400 (3968cc)",
          "LC 430 (4298cc)",
          "TLEXS (4300cc)",
          "LX 470 (4700cc)",
          "LX 470 (4700cc)",
          "LS 500H LUXURY (3456cc)",
          "LS 500H ULTRA LUXURY (3456cc)",
          "RX 450H LUXURY (3456cc)",
          "ES300H LUXURY (2487cc)",
          "NX 300H F SPORT (2499cc)",
          "NX 350H LUXURY HYBRID (2499cc)",
          "NX 300H LUXURY (2499cc)",
          "RX 450H F-SPORT (3456cc)",
          "ES 300 H (2487cc)",
          "LX 450 D (4461cc)",
          "ES 300H EXQUISITE (2487cc)",
          "V8-470 (4664cc)",
          "LX 470 D (4664cc)",
          "LX V8 470 (4664cc)",
          "LS 460 SE L (4600cc)",
          "LS 460 L (4600cc)",
          "LS 460 (4600cc)",
          "LS 460 AWD (4600cc)",
          "LS 460 L AWD (4600cc)",
          "GX 460 (4600cc)",
          "GS 460 RWD (4600cc)",
          "GS-300 (2997cc)",
          "ALTEZZA (2000cc)",
          "V8 (2000cc)",
          "JEEP (2000cc)",
          "LS 600 (4969cc)",
          "LS 600 HL (4969cc)",
          "LS 600H L (4969cc)",
          "LS 600 H (4969cc)",
          "RC F (4969cc)",
          "SOARER 430 (4292cc)",
          "LX 470 V8 (4700cc)",
          "470 (4700cc)",
          "LX 470 V8 (6 SEATER) (4700cc)",
          "STD (4700cc)",
          "GX 470 (4700cc)",
          "STANDARD (1600cc)",
          "STD (1600cc)",
          "ES 300h (4298cc)",
          "LS 430 (4298cc)",
          "LX 570 (8 SEATER) (5663cc)",
          "LX 570 7 STR (5663cc)",
          "LX 570 5 STR (5663cc)",
          "LX 570 8 STR (5663cc)",
          "LS 500 H (3456cc)",
          "RX 450 H F SPORT 5 SEAT (3456cc)",
          "RX 450 H LUXURY 5 SEAT (3456cc)",
          "GS 450 5 SEAT (3456cc)",
          "RX 4X 450 H 5 SEAT (3456cc)",
          "LS 500H NISHIJIN (3456cc)",
          "RX 350 (3456cc)",
          "RX 450H (3456cc)",
          "RX 450HL (3456cc)",
          "SC NISHIJIN (3456cc)",
          "SC 500H HYBRID (3456cc)",
          "SC 500H LIMITED EDITION HYBRID (3456cc)",
          "IS 350 (3456cc)",
          "GS 350 (3456cc)",
          "GS 450H (3456cc)",
          "LX 570 AT 7 SEATER (5700cc)",
          "LX 570 (5700cc)",
          "LX 570 (5700cc)",
          "LX 570 SUV (5700cc)",
          "RX 300 PETROL (2960cc)",
          "LS 430 (4300cc)",
          "LS 400 (3998cc)",
          "NX 300H HYBRID (2494cc)",
          "NX 300H EXQUISITE (2494cc)",
          "NX 350H EXQUISITE HYBRID (2494cc)",
          "NX 350H F-SPORT HYBRID (2494cc)",
          "NX 300H EXQUISITE HYBRID (2494cc)",
          "NX 300H LUXURY HYBRID (2494cc)",
          "NX 300H F-SPORT HYBRID (2494cc)",
          "NX 350H EXQUISITE (2494cc)",
          "NX 350H F SPORT (2494cc)",
          "NX 350H LUXURY (2494cc)",
          "ES 300H HYBRID (2494cc)",
          "NX 300H F SPORT (2494cc)",
          "NX 350H LUXURY HYBRID (2494cc)",
          "NX 300H LUXURY (2494cc)",
          "LS 430 (4293cc)",
          "RX 300 (2995cc)",
          "LX 450 D 5 SEATER (4461cc)",
          "IS 350C (3500cc)",
          "ES 350 (3500cc)",
          "IS 250C (2500cc)",
          "IS 250 (2500cc)",
          "HS 250H (2400cc)",
          "LX 450 D (4500cc)",
          "NX 300H (2499cc)",
          "LX 470 V8 (8 SEATER) (4700cc)",
          "ES 300H (2499cc)",
        ],
      },
      {
        model: "SERA",
        variants: [
          "PETROL (1600cc)",
          "STANDARD (1497cc)",
          "COUPE (1497cc)",
          "STANDARD (2500cc)",
          "STANDARD (1496cc)",
          "COUPE (1400cc)",
          "SERA (1599cc)",
          "STANDARD (1488cc)",
        ],
      },
      {
        model: "ALPHARD",
        variants: [
          "V6 (IMPORTED) (3456cc)",
          "HYBRID (2493cc)",
          "HYBRID (IMPORTED) (2493cc)",
          "2.4 (2362cc)",
          "2.4 (2362cc)",
          "350 GL (3450cc)",
          "3.5 SA C 7 SEATER (3450cc)",
          ". (3450cc)",
          "350 G (3450cc)",
          "350G 8 SEATER (3456cc)",
          "3.5L V6 (3456cc)",
          "3.5 G (3456cc)",
          "350 G L PACKAGE 7 SEATER (3456cc)",
          "350G 5 SEATER (3456cc)",
          "3.0 V6 (3456cc)",
          "350 G (3456cc)",
          "STANDARD (2000cc)",
          "STD (2000cc)",
          "HYBIRD (2493cc)",
          "2.5 EXECUTIVE LONGE 4WD 7 SEATER (2493cc)",
          "3.2L 7 SEATER (3200cc)",
          "4WD (3000cc)",
          "3.0 G (3000cc)",
          "3.0 V6 (2990cc)",
        ],
      },
      {
        model: "MRS",
        variants: [
          "MRS (1800cc)",
          "SALOON (4200cc)",
          "SALOON (1794cc)",
          "EX (1600cc)",
        ],
      },
      {
        model: "INNOVA",
        variants: [
          "2.5 GX 7STR (2494cc)",
          "2.5 GX 7STR (2494cc)",
          "2.5 V (2494cc)",
          "2.5 V (2494cc)",
          "2.5 Z 7STR (2494cc)",
          "2.5 Z 7 STR (2494cc)",
          "2.5 V 7 STR (2494cc)",
          "2.5 V 7 STR (2494cc)",
          "CRYSTA 2.7 VX MT (2694cc)",
          "2.0 GX (1998cc)",
          "2.0 VX (1998cc)",
          "CRYSTA 2.7 GX MT (2694cc)",
          "CRYSTA 2.7 GX AT (2694cc)",
          "2.5 E POWER STEERING (2494cc)",
          "2.5 EV STEERING (MT) (2494cc)",
          "2.5 EV POWER STEERING (2494cc)",
          "2.5 EV PS W/O A/C 7STR (2494cc)",
          "2.5 EV PS W/O A/C 8STR (2494cc)",
          "2.5 G (2494cc)",
          "2.5 G 6STR (2494cc)",
          "2.5 G4 (2494cc)",
          "2.5 GX (2494cc)",
          "2.5 VX (2494cc)",
          "2.5 VX 7STR (2494cc)",
          "2.5 VX 7 STR (2494cc)",
          "2.5 VX CRYSTA 7STR (2494cc)",
          "2.5 ZX 7STR (2494cc)",
          "2.5 Z 8STR (2494cc)",
          "2.5 V (E3) BSIII (2494cc)",
          "2.5 G (M) BSIV (2494cc)",
          "2.5 V (E4) (2494cc)",
          "2.5L G4 (2394cc)",
          "2.5 L V (2494cc)",
          "CRYSTA 2.8 G (2755cc)",
          "2.0 E (1998cc)",
          "2.0 G3 (1998cc)",
          "2.5 G2 (2494cc)",
          "2.5 G4 7 STR (2494cc)",
          "2.0 G (1998cc)",
          "2.0 G (1998cc)",
          "2.0 G1 (1998cc)",
          "CRYSTA 2.8 Z (2755cc)",
          "2.5 G 7STR (2494cc)",
          "2.5 G1 (2494cc)",
          "2.0 G4 (1998cc)",
          "2.0 G4. (1998cc)",
          "2.0 G2 (1998cc)",
          "2.0 V (1998cc)",
          "2.5 E (2494cc)",
          "2.5 G3 (2494cc)",
          "2.5 E () POWER STEERING 7 (2494cc)",
          "2.5 EV () MANUAL STEERING (2494cc)",
          "2.5 EV () POWER STEERING 7 (2494cc)",
          "2.5 EV () PS W/O A/C 7 SEATER (2494cc)",
          "2.5 EV () PS W/O A/C 8 SEATER (2494cc)",
          "2.5 VX CRYSTA () 7 SEATER (2494cc)",
          "2.0 VX (PETROL) (1998cc)",
          "2.0 E (PETROL) (1998cc)",
          "2.0 V (PETROL) (1998cc)",
          "2.5 E (DIESEL) (2494cc)",
          "2.5 GX (DIESEL) (2494cc)",
          "2.0 GX (PETROL) (1998cc)",
          "2.5 VX (DIESEL) (2494cc)",
          "2.5 EV (DIESEL) POWER STEERING (2494cc)",
          "2.0 G1 (PETROL) (1998cc)",
          "2.5 G1 (DIESEL) (2494cc)",
          "2.0 G2 (PETROL) (1998cc)",
          "2.5 G2 (DIESEL) (2494cc)",
          "2.0 G3 (PETROL) (1998cc)",
          "2.5 G3 (DIESEL) (2494cc)",
          "2.0 G4 (PETROL) (1998cc)",
          "2.5 G4 (DIESEL) (2494cc)",
          "2.5 V (DIESEL) (2494cc)",
          "2.5 Z DIESEL 8 SEATER (2494cc)",
          "2.5 GX (DIESEL) 7 SEATER (2494cc)",
          "2.5 VX (DIESEL) 7 SEATER (2494cc)",
          "2.5 G (DIESEL) 6 SEATER (2494cc)",
          "2.5 V (DIESEL) 7 SEATER (2494cc)",
          "2.5 G (DIESEL) (2494cc)",
          "2.0 G (PETROL) (1998cc)",
          "2.5 G (DIESEL) 7 SEATER (2494cc)",
          "2.5 ZX (DIESEL) 7 SEATER (2494cc)",
          "2.5 G4 (DIESEL) 7 SEATER (2494cc)",
          "5STR (1794cc)",
          "G (1998cc)",
          "GX (1998cc)",
          "G1 PETROL (2494cc)",
          "V PETROL 8STR (2494cc)",
          "G3 PETROL (2494cc)",
          "VX (1998cc)",
          "AERO LTD 7 STR BS-III (2494cc)",
          "2.0 G1 8 STR (1998cc)",
          "VX 7STR (2494cc)",
          "VX 7STR (2494cc)",
          "VX 8STR (2494cc)",
          "VX 8STR (2494cc)",
          "V DIESEL 7 STR (2494cc)",
          "E DIESEL 7STR (2494cc)",
          "AD 2.5 V DSL 7STR (2494cc)",
          "AERO LTD 8 STR BS-III (2494cc)",
          "2.5 L EURO 3 (2494cc)",
          "G2 DIESEL (2494cc)",
          "AR E DSL 7STR (2494cc)",
          "E DIESEL 8STR (2494cc)",
          "2.0 GX 8 STR BS-IV (2494cc)",
          "G4 DIESEL 9STR (2494cc)",
          "2.5 G1-8STR (2494cc)",
          "G 8STR (2494cc)",
          "G4 DIESEL 8STR (2494cc)",
          "G4 DIESEL 7STR (2494cc)",
          "G3 DIESEL (2494cc)",
          "V DIESEL 8STR (2494cc)",
          "2.5 L LIMITED EDITION (2494cc)",
          "AQ-M DSL 8STR (2494cc)",
          "2.5L G (2494cc)",
          "AERO LTD 7 STR BS-IV (2494cc)",
          "V2 10STR (2494cc)",
          "G1 7STR (2494cc)",
          "2.5 GX 8STR (2494cc)",
          "G2 (2494cc)",
          "G2 (2494cc)",
          "G1 (2494cc)",
          "G (2494cc)",
          "E (2494cc)",
          "G4 (2494cc)",
          "V (2494cc)",
          "G3 (2494cc)",
          "GX (2494cc)",
          "EV (2494cc)",
          "CRYSTA 2.5 VX (2494cc)",
          "Z (2494cc)",
          "ZX (2494cc)",
          "2.5 EV MS 7 STR (2494cc)",
          "2.5 EV MS 8 STR (2494cc)",
          "2.5 EV PS 7 STR (2494cc)",
          "2.5 EV PS 8 STR (2494cc)",
          "2.5 VX 8 STR (2494cc)",
          "CRYSTA 2.5 VX BS-III (2494cc)",
          "2.5 E MS 7 STR BS-III (2494cc)",
          "2.5 E MS 8 STR BS-III (2494cc)",
          "2.5 E PS 7 STR BS-III (2494cc)",
          "2.5 E PS 8 STR BS-III (2494cc)",
          "2.5 G 7 STR BS-III (2494cc)",
          "2.5 ZX 7 STR BS-IV (2494cc)",
          "2.5 ZX 7 STR BS-III (2494cc)",
          "2.5 GX 7 STR BS-III (2494cc)",
          "2.5 GX 8 STR BS-III (2494cc)",
          "2.5 VX 7 STR BS-III (2494cc)",
          "2.5 VX 8 STR BS-III (2494cc)",
          "AERO LTD 8 STR BS-IV (2494cc)",
          "TOURING SPORT 2.7 ZX AT (2494cc)",
          "2.5 EV PS 7 STR BS-III (2494cc)",
          "2.5 EV PS 8 STR BS-III (2494cc)",
          "2.5 GX 7 STR BS-III LTD (2494cc)",
          "2.5 GX 8 STR BS-III LTD (2494cc)",
          "2.5 ZX BS-III 7 STR (2494cc)",
          "2.5 G E4 (2494cc)",
          "AERO GX 7 STR (2494cc)",
          "V E4 (2494cc)",
          "2.5 L VX 5 STR (2494cc)",
          "2.5 G 8 STR BS-III (2494cc)",
          "2.5 G 8 STR BS III (2494cc)",
          "2.5 G1 DIESEL 8 SEATER (2494cc)",
          "2.5 STD 5 STR (2494cc)",
          "2.5 VX BS-IV 7 STR (2494cc)",
          "2.5 VX BS-IV 8 STR (2494cc)",
          "2.5 Z 7 STR BS-III (2494cc)",
          "2.5 G3 (DIESEL) 7 SEATER (2494cc)",
          "2.5 E (DIESEL) 7 SEATER (2494cc)",
          "2.5 E (DIESEL) POWER STEERING 7 SEATER (2494cc)",
          "2.5 VX CRYSTA (DIESEL) 7 SEATER (2494cc)",
          "2.5 EV (DIESEL) MANUAL STEERING (2494cc)",
          "2.5 G (DIESEL) 10 SEATER (2494cc)",
          "2.5 EV (DIESEL) PS W/O A/C 7 SEATER (2494cc)",
          "2.5 EV (DIESEL) PS W/O A/C 8 SEATER (2494cc)",
          "2.5 EV (DIESEL) POWER STEERING 7 SEATER (2494cc)",
          "2.5 G3 (DIESEL) 5 SEAT (2494cc)",
          "2.5 AERO LTD 7 SEATER (2494cc)",
          "2.5 AERO LTD 8 SEATER (2494cc)",
          "2.5 E 9 SEATER (2494cc)",
          "2.5 Z DIESEL 7 SEATER (2494cc)",
          "2.5 E DIESEL POWER STEERING W/O AC 8 SEATER (2494cc)",
          "2.5 E DIESEL POWER STEERING W/O AC 7 SEATER (2494cc)",
          "2.5 E DIESEL POWER STEERING 8 SEATER (2494cc)",
          "2.5 E MS 7 STR BS-IV (2494cc)",
          "2.5 E PS 8 STR BS-IV (2494cc)",
          "2.5 E PS 7 STR BS-IV (2494cc)",
          "2.5 VX 7 STR BS-IV (2494cc)",
          "2.5 VX 8 STR BS-IV (2494cc)",
          "2.5 Z 7 STR BS-IV (2494cc)",
          "2.5 V M 7 STR (2494cc)",
          "2.5 E MS 8 STR BS-IV (2494cc)",
          "2.5 GX 7 STR BS-IV LTD (2494cc)",
          "2.5 GX 8 STR BS-IV LTD (2494cc)",
          "2.5 LE 7 STR (2494cc)",
          "2.5 LE 8 STR (2494cc)",
          "2.5 E4 (2494cc)",
          "VX CNG (2494cc)",
          "G CNG (2494cc)",
          "GX CNG (2494cc)",
          "TOURING SPORT 2.7 VX MT (2494cc)",
          "2.5 E 8 STR (2494cc)",
          "2.5 EV CS 7 STR BS-IV (2494cc)",
          "2.5 EV MS 7 STR BS-IV (2494cc)",
          "2.5 EV MS 8 STR BS-IV (2494cc)",
          "2.5 EV PS 7 STR BS-IV (2494cc)",
          "2.5 EV PS 8 STR BS-IV (2494cc)",
          "2.5 G 7 STR BS-IV (2494cc)",
          "2.5 G 8 STR BS-IV (2494cc)",
          "2.5 G1 BS-IV (2494cc)",
          "2.5 G4 8 STR (2494cc)",
          "2.5 GX 7 STR BS-IV (2494cc)",
          "2.5 GX 8 STR BS-IV (2494cc)",
          "2.5 V 8 STR (2494cc)",
          "2.5 ZX BS-IV 7 STR (2494cc)",
          "CRYSTA TOURING SPORT MT (2393cc)",
          "CRYSTA 2.5 VX BS-IV (2494cc)",
          "2.5 VX - 7 SEATER (2494cc)",
          "2.5 G2 8STR (2494cc)",
          "2.5 E - 7 SEATER (2494cc)",
          "2.5 EV MS - 7-SEATER (2494cc)",
          "2.5 V - 7 SEATER (2494cc)",
          "2.5 VX - 8 SEATER (2494cc)",
          "2.5 G2 7STR (2494cc)",
          "2.5 G4 - 7 SEATER (2494cc)",
          "2.5 GX - 8 SEATER (2494cc)",
          "2.5 E - 8 SEATER (2494cc)",
          "2.5 V - 8 SEATER (2494cc)",
          "CRYSTA 2.5 VX - 7 STR (2494cc)",
          "2.5 EV PS - 8-SEATER (2494cc)",
          "2.5 G4 - 8 SEATER (2494cc)",
          "2.5 GX 7 SEATER (2494cc)",
          "AERO LIMITED EDITION 7 STR (2494cc)",
          "2.5 EV PS - 7-SEATER (2494cc)",
          "LIMITED (2494cc)",
          "2.5V (E3)BSLLL (2494cc)",
          "2.5 ZX - 7 SEATER (2494cc)",
          "2.5 EV MS - 8-SEATER (2494cc)",
          "2.5 G 8STR (2494cc)",
          "CRYSTA G MT 8 SEATER (2393cc)",
          "CRYSTA V (2393cc)",
          "CRYSTA Z (2393cc)",
          "G2 PETROL (1998cc)",
          "G4 PETROL 7STR (1998cc)",
          "2.0 G4 PETROL 8 SEATER (1998cc)",
          "E PETROL 8STR (1998cc)",
          "2.0 V 8STR (1998cc)",
          "V PETROL 7STR (1998cc)",
          "G2 (1998cc)",
          "G1 (1998cc)",
          "CRYSTA GX MT 8 SEATER (2393cc)",
          "E (1998cc)",
          "G4 (1998cc)",
          "V (1998cc)",
          "G3 (1998cc)",
          "CRYSTA VX MT (2393cc)",
          "CRYSTA VX MT 8 SEATER (2393cc)",
          "2.0 VX 8 STR (1998cc)",
          "CRYSTA 2.4 ZX DIESEL AT 7 SEATER (2393cc)",
          "2.0 GX 8 STR (1998cc)",
          "2.0 G PETROL 7 SEATER (1998cc)",
          "2.0 V (PETROL) 7 SEATER (1998cc)",
          "2.0 G4 (PETROL) 7 SEATER (1998cc)",
          "2.0 G 8 STR BS-IV (1998cc)",
          "2.0 G1 BS-IV (1998cc)",
          "2.0 VX 7 STR BS-IV (1998cc)",
          "2.0 GX 8 STR BS-IV (1998cc)",
          "2.0 VX 8 STR BS-IV (1998cc)",
          "2.0 G 8 STR (1998cc)",
          "2.0 G 10 STR (1998cc)",
          "2.0 VX - 8 SEATER (1998cc)",
          "2.0 V 7STR (1998cc)",
          "2.0 V (7 SEATER) (1998cc)",
          "2.0 GX - 8 SEATER (1998cc)",
          "CRYSTA 2.4 GX DIESEL AT 8 SEATER (2393cc)",
          "CRYSTA LEADERSHIP EDITION 2.4 DIESEL MT 7 SEATER (2393cc)",
          "CRYSTA 2.4 G PLUS DIESEL MT 7 SEATER (2393cc)",
          "CRYSTA 2.4 G PLUS DIESEL MT 8 SEATER (2393cc)",
          "CRYSTA 2.4 GX DIESEL AT 7 SEATER (2393cc)",
          "TOURING SPORT 2.4 DIESEL MT 7 SEATER (2393cc)",
          "TOURING SPORT 2.4 DIESEL VX MT 7 SEATER (2393cc)",
          "CRYSTA 2.4 G DIESEL MT 7 SEATER (2393cc)",
          "CRYSTA 2.4 G DIESEL MT 8 SEATER (2393cc)",
          "CRYSTA 2.4 GX DIESEL MT 8 SEATER (2393cc)",
          "CRYSTA 2.4 VX DIESEL MT 7 SEATER (2393cc)",
          "CRYSTA 2.4 VX DIESEL MT 8 SEATER (2393cc)",
          "CRYSTA 2.4 ZX DIESEL MT 7 SEATER (2393cc)",
          "CRYSTA 2.4 GX DIESEL MT 7 SEATER (2393cc)",
          "CRYSTA G MT (2393cc)",
          "CRYSTA GX MT (2393cc)",
          "CRYSTA ZX MT (2393cc)",
          "CRYSTA VX7 (TC) (2393cc)",
          "TOURING SPORT 2.4 VX MT (2393cc)",
          "HYCROSS 2.0  PETROL GX AT 8 SEATER (1987cc)",
          "HYCROSS 2.0  PETROL G-SLF AT 7 SEATER (1987cc)",
          "HYCROSS 2.0  PETROL G-SLF AT 8 SEATER (1987cc)",
          "HYCROSS 2.0  PETROL GX AT 7 SEATER (1987cc)",
          "HYCROSS 2.0  PETROL HYBRID ZX AT  NIMH 137 KW (1987cc)",
          "HYCROSS 2.0  PETROL HYBRID VX AT 8 SEATER  NIMH 137 KW (1987cc)",
          "HYCROSS 2.0  PETROL HYBRID VX AT 7 SEATER NIMH 137 KW (1987cc)",
          "HYCROSS 2.0  PETROL HYBRID ZX(O) AT  NIMH 137 KW (1987cc)",
          "HYCROSS 2.0  PETROL HYBRID VX(O) AT 7 SEATER NIMH 137 KW (1987cc)",
          "HYCROSS 2.0  PETROL HYBRID VX(O) AT 8 SEATER NIMH 137 KW (1987cc)",
          "HYCROSS ZX HYBRID 8 STR (1987cc)",
          "HYCROSS VX (1987cc)",
          "HYCROSS VX (O) HYBRID 7 STR (1987cc)",
          "HYCROSS VX (O) HYBRID 8 STR (1987cc)",
          "CRYSTA ZX 7 SEATER 2.4 MT (2399cc)",
          "EV 8STR (2496cc)",
          "CRYSTA 2.7 GX MT 8 SEATER (2694cc)",
          "CRYSTA TOURING SPORT AT (2694cc)",
          "CRYSTA TOURING SPORT MT (2694cc)",
          "CRYSTA TOURING SPORT ZX AT (2694cc)",
          "CRYSTA 2.7 VX PETROL MT 8 SEATER (2694cc)",
          "TOURING SPORT 2.7 PETROL ZX AT 7 SEATER (2694cc)",
          "TOURING SPORT 2.7 PETROL VX MT 7 SEATER (2694cc)",
          "CRYSTA 2.7 GX PETROL MT 7 SEATER (2694cc)",
          "CRYSTA 2.7 GX PETROL MT 8 SEATER (2694cc)",
          "CRYSTA 2.7 VX PETROL MT 7 SEATER (2694cc)",
          "CRYSTA 2.7 GX PETROL AT 7 SEATER (2694cc)",
          "CRYSTA 2.7 GX PETROL AT 8 SEATER (2694cc)",
          "CRYSTA 2.7 ZX PETROL AT 7 SEATER (2694cc)",
          "TOURING SPORT 2.7 PETROL VX AT 7 SEATER (2694cc)",
          "TOURING SPORT 2.7 VX MT (2694cc)",
          "TOURING SPORT 2.7 ZX AT (2694cc)",
          "CRYSTA TOURING SPORT AT (2755cc)",
          "CRYSTA Z AT (2755cc)",
          "CRYSTA GX AT 8 SEATER (2755cc)",
          "CRYSTA 2.8 ZX DIESEL MT 7 SEATER (2755cc)",
          "CRYSTA 2.8 Z DIESEL AT 7 SEATER (2755cc)",
          "CRYSTA 2.8 GX DIESEL MT 7 SEATER (2755cc)",
          "CRYSTA 2.8 GX DIESEL MT 8 SEATER (2755cc)",
          "TOURING SPORT 2.8 DIESEL ZX AT 7 SEATER (2755cc)",
          "CRYSTA 2.8 ZX DIESEL AT 8 SEATER (2755cc)",
          "CRYSTA 2.8 Z DIESEL MT 7 SEATER (2755cc)",
          "CRYSTA 2.8 GX DIESEL AT 7 SEATER (2755cc)",
          "CRYSTA 2.8 GX DIESEL AT 8 SEATER (2755cc)",
          "CRYSTA 2.8 ZX DIESEL AT 7 SEATER (2755cc)",
          "CRYSTA 2.4 GX 8STR AT XN (2755cc)",
          "CRYSTA GX AT (2755cc)",
          "CRYSTA ZX AT (2755cc)",
          "TOURING SPORT 2.8 ZX AT (2755cc)",
          "CRYSTA LC 200 VX (4461cc)",
        ],
      },
      {
        model: "CAMRY",
        variants: [
          "2.5 G AT (2494cc)",
          "2.5L - (2494cc)",
          "CAMRY HYBRID (2494cc)",
          "2.5 AT (2495cc)",
          "2.5 HYBRID WB AT (2494cc)",
          "ACV 40R - AEANKW (2362cc)",
          "V2 (2362cc)",
          "ACV 30R AEANKW (2362cc)",
          "W5 (AT) (2362cc)",
          "V4 (2362cc)",
          "V6 (2362cc)",
          "HYBRID (2487cc)",
          "HYBRID. (2487cc)",
          "W4 AT (2362cc)",
          "W3 MT (2362cc)",
          "W1 MT (2362cc)",
          "W2 AT (2362cc)",
          "V1 (2362cc)",
          "V1 (2362cc)",
          "V3 (2362cc)",
          "V3 (2362cc)",
          "2.5L - PETROL (2494cc)",
          "2.5 HYBRID WB AUTOMATIC (2494cc)",
          "W1 (MANUAL TRANSMISSION) (2362cc)",
          "W2 (AUTOMATIC TRANSMISSION) (2362cc)",
          "W3 (MANUAL TRANSMISSION) (2362cc)",
          "W4 (AUTOMATIC TRANSMISSION) (2362cc)",
          "2.5 G AUTOMATIC (2494cc)",
          "2.5 AUTOMATIC (2495cc)",
          "W6 (2494cc)",
          "W8 (2494cc)",
          "2.5 HYBRID (2494cc)",
          "2.5 HYBRID WB AUTOMATIC (2494 CC) (2494cc)",
          "V4 MT (2494cc)",
          "V6 AT (2494cc)",
          "2.5V AT (2494cc)",
          "2.5 G (2494cc)",
          "2.5 G (2494cc)",
          "2.5L AT (2494cc)",
          "W5 (AT) (2494cc)",
          "HYBRID (2494cc)",
          "HYBRID (2494cc)",
          "V1 (2494cc)",
          "V3 (2494cc)",
          "80 (80cc)",
          "V4  MT (2362cc)",
          "V4 MT (2362cc)",
          "V6  AT (2362cc)",
          "V6 AT (2362cc)",
          "ACV (2362cc)",
          "W2 (2362cc)",
          "W3 (2362cc)",
          "W4 (2362cc)",
          "W1 (2362cc)",
          "ACV30R (2362cc)",
          "ACV30R (2362cc)",
          "ACV 40R (2362cc)",
          "ACV40R (2362cc)",
          "HYBRID. 5STR (2487cc)",
          "2.5 HYBRID GI (2487 CC) (2487cc)",
          "2.5 HYBRID WB AUTOMATIC (2487 CC) (2487cc)",
          "2.5 HYBRID VG (2487 CC) (2487cc)",
          "2.5 HYBRID VM (2487 CC) (2487cc)",
          "HYBRID 2487 CC (2487cc)",
          "HYBRID (131cc)",
        ],
      },
      {
        model: "COROLLA ALTIS",
        variants: [
          "1.4 HG (1364cc)",
          "1.4 HG (1364cc)",
          "AERO (1364cc)",
          "AERO (1798cc)",
          "1.8 G CVT AT (1798cc)",
          "1.8 V (1794cc)",
          "1.8 J (HH) LIMITED EDITION (1798cc)",
          "JS (1798cc)",
          "1.4 D-4D J (1364cc)",
          "1.4 D-4D G (1364cc)",
          "1.4 D-4D GL (1364cc)",
          "1.8 LE (1798cc)",
          "1.8 VL (1798cc)",
          "1.8 J (1798cc)",
          "1.8 J (1798cc)",
          "1.8 G (1798cc)",
          "1.8 G (1798cc)",
          "1.8 GL (1798cc)",
          "1.8 G L (1798cc)",
          "AERO PETROL (1798cc)",
          "1.8 J (HH) PETROL LTD EDITION (1798cc)",
          "1.8 G CVT AUTOMATIC (1798cc)",
          "AERO DIESEL (1364cc)",
          "GL (1794cc)",
          "SPORT (1794cc)",
          "HC (1794cc)",
          "G (1794cc)",
          "J (1794cc)",
          "AERO (1794cc)",
          "1.8 SPORT (1794cc)",
          "1.8 VL AT (1794cc)",
          "1.8 LE (1794cc)",
          "1.8 VL (1794cc)",
          "1.8 J (1794cc)",
          "1.8 GL (1794cc)",
          "D 4D G (1364cc)",
          "D 4D G (1364cc)",
          "1.4 HL (1364cc)",
          "1.4 HJ (1364cc)",
          "HN (1364cc)",
          "1.4 GL (1364cc)",
          "1.4 G (1364cc)",
          "D (1364cc)",
          "GL (1364cc)",
          "DGL (1364cc)",
          "HM (1364cc)",
          "HG 1.4 DIESEL (1364cc)",
          "HN 1.4 DIESEL (1364cc)",
          "1.4 D4-D JS (1364cc)",
          "D 4 DJ BS-IV (1364cc)",
          "J (1364cc)",
          "AERO LTD DIESEL (1364cc)",
          "JS DIESEL (1364cc)",
          "DIESEL LTD (1364cc)",
          "LE DIESEL (1364cc)",
          "D 4D G DIESEL (1364cc)",
          "D-4D GL (1364cc)",
          "DG (1364cc)",
          "1.4 HO DIESEL (1364cc)",
          "HN G DIESEL (1364cc)",
          "D 4D J DIESEL (1364cc)",
          "D-4D JS (1364cc)",
          "GL DIESEL (1364cc)",
          "J DIESEL (1364cc)",
          "G DIESEL (1364cc)",
          "VL (1798cc)",
          "VL (1798cc)",
          "1.8 JS MT (1798cc)",
          "D-4D LIMITED EDITION (1798cc)",
          "VL MT (1798cc)",
          "LIMITED EDITION (1798cc)",
          "G CVT (1798cc)",
          "VL CVT (1798cc)",
          "GL (1798cc)",
          "1.8 YD AT (1798cc)",
          "1.8 VL AUTOMATIC TRANSMISSION (1798cc)",
          "1.8 V F (1798cc)",
          "JS PETROL (1798cc)",
          "PETROL LTD (1798cc)",
          "1.8 LIMITED EDITION (1798cc)",
          "1.8 G CNG (1798cc)",
          "1.8 GL CNG (1798cc)",
          "1.8 J CNG (1798cc)",
          "1.8 G AT (1798cc)",
          "J+ (1798cc)",
          "AERO LTD PETROL (1798cc)",
          "LE PETROL (1798cc)",
          "1.8 J S (1798cc)",
          "1.8 JS (1798cc)",
          "1.8 G CVT (1798cc)",
          "1.8 J PLUS MT (1798cc)",
          "1.8G AT PETROL (1798cc)",
          "1.8 JS PETROL (1798cc)",
          "1.8 SPORT (1798cc)",
          "1.8 VL AT (1798cc)",
          "J DIESEL (1796cc)",
        ],
      },
      {
        model: "URBAN CRUISER",
        variants: [
          "MID (AT) BSVI (1462cc)",
          "HIGH (AT) BSVI (1462cc)",
          "PREMIUM (AT) BSVI (1462cc)",
          "MID BSVI (1462cc)",
          "HIGH BSVI (1462cc)",
          "PREMIUM BSVI (1462cc)",
          "HIGH MT (1462cc)",
          "S MT CNG (1462cc)",
          "1.5 MID AT PETROL (1462cc)",
          "1.5 PREMIUM AT PETROL (1462cc)",
          "1.5 HIGH AT PETROL (1462cc)",
          "1.5 PREMIUM MT PETROL (1462cc)",
          "1.5 HIGH MT PETROL (1462cc)",
          "1.5 MID MT PETROL (1462cc)",
          "HYRYDER G CNG MT (1462cc)",
          "HYRYDER E MT 2WD NEO DRIVE (1462cc)",
          "HYRYDER S AT 2WD NEO DRIVE (1462cc)",
          "HIGH GRADE MT (1462cc)",
          "HIGH GRADE AT (1462cc)",
          "MID GRADE AT (1462cc)",
          "MID GRADE MT (1462cc)",
          "PREMIUM GRADE AT (1462cc)",
          "PREMIUM GRADE AT DUAL TONE (1462cc)",
          "PREMIUM GRADE MT (1462cc)",
          "PREMIUM GRADE MT DUAL TONE (1462cc)",
          "HYRYDER G NEO DRIVE (1462cc)",
          "HYRYDER V NEO DRIVE (1462cc)",
          "HYRYDER E NEO DRIVE (1462cc)",
          "HYRYDER S AT NEO DRIVE (1462cc)",
          "HYRYDER G AT NEO DRIVE (1462cc)",
          "PREMIUM GRANDE AT DUAL TONE (1462cc)",
          "PREMIUM GRANDE MT (1462cc)",
          "HIGH GRANDE AT (1462cc)",
          "MID GRANDE MT (1462cc)",
          "HIGH GRANDE MT (1462cc)",
          "PREMIUM GRANDE MT DUAL TONE (1462cc)",
          "MID GRANDE AT (1462cc)",
          "PREMIUM GRANDE AT (1462cc)",
          "G HYBRID (1490cc)",
          "HYRIDER HYBRID S AT (1490cc)",
          "HYRYDER G EDRIVE 2WD HYBRID (1490cc)",
          "HYRYDER S HYBRID EDRIVE 2WD (1490cc)",
          "HYRYDER V HYBIRD (1490cc)",
          "HYRYDER V MT 2WD NEO DRIVE (1490cc)",
          "HYRYDER 1.5L G E-DRIVE 2WD (1490cc)",
          "HYRYDER V EDRIVE 2WD HYBRID (1490cc)",
          "HYRYDER V AT NEO DRIVE (1490cc)",
          "HYRYDER V HYBRID (1490cc)",
        ],
      },
      {
        model: "INNOVA CRYSTA",
        variants: [
          "2.7 ZX (AT) (2694cc)",
          "2.4 GX (2393cc)",
          "2.4 VX (2393cc)",
          "2.4 ZX (2393cc)",
          "2.8 ZX (AT) (2755cc)",
          "2.4 G (2393cc)",
          "2.4 G (MT) (2393cc)",
          "2.4 GX (AT) (2393cc)",
          "2.4 GX (MT) (2393cc)",
          "2.7 VX (2694cc)",
          "2.4 ZX (AT) (2393cc)",
          "2.4 ZX (AT) (2694cc)",
          "2.4 ZX (MT) (2393cc)",
          "2.8 (AT) (2755cc)",
          "2.7 V (AT) (2694cc)",
          "2.4 (MT) (2393cc)",
          "2.7 V (MT) (2694cc)",
          "2.7 TOURING SPORT (MT) (2694cc)",
          "2.7 TOURING SPORT (AT) (2694cc)",
          "2.4 TOURING SPORT (MT) (2393cc)",
          "2.8 TOURING SPORT (AT) (2755cc)",
          "2.8 GX (MT) (2755cc)",
          "2.8 ZX (MT) (2755cc)",
          "2.4 GX (AT) ANNIVERSARY EDTN (2393cc)",
          "2.4 Z (2393cc)",
          "2.4 LEADERSHIP EDITION (2393cc)",
          "2.4 Z AT (2393cc)",
          "2.7 GX (MT) BSVI (2694cc)",
          "2.7 GX (AT) BSVI (2694cc)",
          "2.7 VX (MT) BSVI (2694cc)",
          "2.7 ZX (AT) BSVI (2694cc)",
          "2.4 G (MT) BSVI (2393cc)",
          "2.4 G PLUS (MT) BSVI (2393cc)",
          "2.4 GX (MT) BSVI (2393cc)",
          "2.4 GX (AT) BSVI (2393cc)",
          "2.4 VX (MT) BSVI (2393cc)",
          "2.4 ZX (MT) BSVI (2393cc)",
          "2.4 ZX (AT) BSVI (2393cc)",
          "2.8 GX AT (2755cc)",
          "2.4 V (2393cc)",
          "MT (2393cc)",
          "CRYSTA 2.4 G 7 SEATER (2393cc)",
          "CRYSTA 2.4 G (2393cc)",
          "2.4 G BSIV (2393cc)",
          "2.4 GX 7 STR (2393cc)",
          "2.4 GX 8 STR (2393cc)",
          "2.4 VX 7 STR (2393cc)",
          "2.4 ZX 7 STR (2393cc)",
          "2.4 V 8 SEATER (2393cc)",
          "2.4 GX MT 8 STR (2393cc)",
          "2.4 GX MT 8 STR (2393cc)",
          "2.4 VX MT 7 STR (2393cc)",
          "2.4 VX MT 7 STR (2393cc)",
          "2.4 ZX MT 7 STR (2393cc)",
          "2.4 ZX MT 7 STR (2393cc)",
          "2.4 ZX AT 7 STR BS6 (2393cc)",
          "TOURING SPORT DIESEL AT BS6 (2393cc)",
          "2.4 G 7 STR (2393cc)",
          "2.4 G 8 STR (2393cc)",
          "2.4 G PLUS 7 STR (2393cc)",
          "2.4 G PLUS 8 STR (2393cc)",
          "2.4 V 8STR (2393cc)",
          "2.4 ZX AT 7 STR BS-VI (2393cc)",
          "2.4 VX 7 STR BS-VI (2393cc)",
          "2.4 G 8 STR BS-VI (2393cc)",
          "2.4 VX 8 STR BS-VI (2393cc)",
          "2.4 ZX 7 STR BS-VI (2393cc)",
          "2.4 G 7 STR BS-VI (2393cc)",
          "2.4 GX 7 STR BS-VI (2393cc)",
          "2.4 GX 8 STR BS-VI (2393cc)",
          "TOURING SPORT DIESEL MT BS-VI (2393cc)",
          "2.4 G PLUS 7 STR BS-VI (2393cc)",
          "2.4 G PLUS 8 STR BS-VI (2393cc)",
          "2.4 Z AT 7 STR (2393cc)",
          "GX MT 8S (2393cc)",
          "2.4 GX 7 STR AT LIMITED EDITION (2393cc)",
          "2.4 GX 7 STR LIMITED EDITION (2393cc)",
          "2.4 GX 8 STR AT LIMITED EDITION (2393cc)",
          "2.4 GX 8 STR LIMITED EDITION (2393cc)",
          "2.4 VX FLT 7 STR (2393cc)",
          "2.4 VX FLT 8 STR (2393cc)",
          "2.4 GX AT 8 STR BSVI (2393cc)",
          "2.4 GX AT 8 STR BS-VI (2393cc)",
          "2.4 GX AT 7 STR BS-VI (2393cc)",
          "G-SLF 7 STR (2393cc)",
          "G-SLF 8 STR (2393cc)",
          "2.4 VX 8 STR (2393cc)",
          "2.4 Z 7 STR (2393cc)",
          "2.4 GX MT 7 STR (2393cc)",
          "2.4 G MT 8 STR (2393cc)",
          "TOURING SPORT 2.4 VX MT 7 STR (2393cc)",
          "2.4 G MT 7 STR (2393cc)",
          "2.4 V 8S (2393cc)",
          "2.4 VX MT 8 STR (2393cc)",
          "2.4 V 7S (2393cc)",
          "2.4 VX MT (2393cc)",
          "2.4 ZX AT 7 STR (2393cc)",
          "2.4 GX AT 8 STR (2393cc)",
          "2.4 Z MT 7 STR (2393cc)",
          "2.4 G PLUS MT 8 STR (2393cc)",
          "2.4 GX AT 7 STR (2393cc)",
          "2.4 GX AT LIMITED EDITION 8 STR (2393cc)",
          "2.4 GX AT LIMITED EDITION 7 STR (2393cc)",
          "2.4 GX MT LIMITED EDITION 8 STR (2393cc)",
          "2.4 GX MT LIMITED EDITION 7 STR (2393cc)",
          "TOURING SPORT DIESEL MT (2393cc)",
          "LEADERSHIP EDITION (2393cc)",
          "G MT (2694cc)",
          "ZX MT (2694cc)",
          "GX AT (2694cc)",
          "ZX AT (2694cc)",
          "GX MT (2694cc)",
          "VX (2694cc)",
          "TOURING SPORT PETROL MT (2694cc)",
          "TOURING SPORT PETROL AT (2694cc)",
          "2.7 GX 7 STR (2694cc)",
          "2.7 GX 8 STR (2694cc)",
          "2.7 VX 7 STR (2694cc)",
          "TOURING SPORT PETROL MT BS6 (2694cc)",
          "2.7 ZX AT 7 STR BS-VI (2694cc)",
          "2.7 G MT 7STR (2694cc)",
          "2.7 G MT 8STR (2694cc)",
          "2.7 GX AT 8 STR BS-VI (2694cc)",
          "2.7 GX AT 7 STR BS-VI (2694cc)",
          "2.7 VX 7 STR BS-VI (2694cc)",
          "TOURING SPORT PETROL MT BS-VI (2694cc)",
          "2.7 Z AT 7 STR (2694cc)",
          "2.7 GX 7 STR AT LIMITED EDITION (2694cc)",
          "GX MT 8 STR (2694cc)",
          "2.7 GX 7 STR LIMITED EDITION (2694cc)",
          "2.7 GX 8 STR AT LIMITED EDITION (2694cc)",
          "2.7 GX 8 STR LIMITED EDITION (2694cc)",
          "TOURING SPORT PETROL AT BS-VI (2694cc)",
          "GX 7 STR (2694cc)",
          "GX 8 STR (2694cc)",
          "2.7 GX 7 STR BS-VI (2694cc)",
          "2.7 GX 8 STR BS-VI (2694cc)",
          "2.7 GX MT 7 STR (2694cc)",
          "2.7 GX AT LIMITED EDITION 8 STR (2694cc)",
          "2.7 GX AT LIMITED EDITION 7 STR (2694cc)",
          "2.7 GX MT LIMITED EDITION 8 STR (2694cc)",
          "2.7 GX MT LIMITED EDITION 7 STR (2694cc)",
          "2.7 GX AT 7 STR (2694cc)",
          "2.7 VX MT 7 STR (2694cc)",
          "2.7 GX MT 8 STR (2694cc)",
          "TOURING SPORT 2.7 VX MT 7 STR (2694cc)",
          "TOURING SPORT 2.7 ZX AT 7 STR (2694cc)",
          "2.7 GX AT 8 STR (2694cc)",
          "2.7 ZX AT 7 STR (2694cc)",
          "2.7 G (5Q) (2697cc)",
          "TOURING SPORT 2.8 AT (2755cc)",
          "2.8 GX AT 8 STR (2755cc)",
          "2.8 GX AT 8 STR (2755cc)",
          "2.8 G BSIV (2755cc)",
          "2.8 G 9 STR (2755cc)",
          "TOURING SPORT DIESEL AT BS-VI (2755cc)",
          "2.8 Z 8 STR (2755cc)",
          "2.8 G 8STR (2755cc)",
          "2.5 G1 MT (2494cc)",
          "2.0 GX AT 8 STR (1998cc)",
          "TOURING SPORT 2.8 ZX AT 7 STR (2755cc)",
          "TOURING SPORT DIESEL AT (2755cc)",
          "2.8 Z 7 STR (2755cc)",
          "AT (2755cc)",
          "2.8 GX AT 7 STR (2755cc)",
          "2.8 ZX AT 7 STR (2755cc)",
          "2.8 GX AT 7 STR (2755CC) (4664cc)",
        ],
      },
      {
        model: "HIACE",
        variants: [
          "COMMUTER VAN (2800cc)",
          "SCHOOL VAN (2800cc)",
          "5 SEATER (2500cc)",
          "RHD 2WD MT (2500cc)",
          "D 7 SEATER (3000cc)",
          "8 SEATER (3000cc)",
          "D AT 10 SEATER (3000cc)",
          "WELCAB (3000cc)",
          "COMMUTER 3.0 AT (3000cc)",
          "10 SEATER (2494cc)",
          "COMMUTER (2494cc)",
          "12 SEATER (2400cc)",
          "COMMUTER (2400cc)",
          "GL 14 SEATER (2982cc)",
          "COMMUTER 10 STR (2982cc)",
          "COMMUTER 5STR (2982cc)",
          "COMMUTER VAN (2982cc)",
          "COMMUTER (2982cc)",
          "REGIUS VAN (2982cc)",
          "7 STR (2982cc)",
          "GL 14 STR (2982cc)",
          "HX (2982cc)",
          "0 (2982cc)",
          "2.8L DIESEL BS6 (2755cc)",
          "VAN (2800cc)",
          "8 STR (2609cc)",
          "0 (2980cc)",
        ],
      },
      {
        model: "HILUX",
        variants: [
          "STD MT (2755cc)",
          "HIGH MT (2755cc)",
          "HIGH AT (2755cc)",
          "2.4 D 4X4 (2393cc)",
          "SURF 3 (3000cc)",
          "STD (2362cc)",
          "0 (2494cc)",
          "STANDARD 4X4 MT (2755cc)",
          "STD 4X4 MT (2755cc)",
          "HIGH 4X4 AT (2755cc)",
          "HIGH 4X4 MT (2755cc)",
          "0 (2000cc)",
        ],
      },
      {
        model: "URBAN CRUISER HYRYDER",
        variants: [
          "S EDRIVE 2WD HYBRID BSVI (1490cc)",
          "V NEODRIVE 2WD AT BSVI (1462cc)",
          "G EDRIVE 2WD HYBRID BSVI (1490cc)",
          "V EDRIVE 2WD HYBRID BSVI (1490cc)",
          "E NEODRIVE 2WD MT BSVI (1462cc)",
          "S NEODRIVE 2WD MT BSVI (1462cc)",
          "S NEODRIVE 2WD AT BSVI (1462cc)",
          "G NEODRIVE 2WD MT BSVI (1462cc)",
          "G NEODRIVE 2WD AT BSVI (1462cc)",
          "V NEODRIVE 2WD MT BSVI (1462cc)",
          "V NEODRIVE AWD MT BSVI (1462cc)",
          "S MT NEO DRIVE (1490cc)",
          "1.5 V E-DRIVE 2WD HYBRID (1490cc)",
          "1.5 S E-DRIVE 2WD HYBRID (1490cc)",
          "1.5 G E-DRIVE 2WD HYBRID (1490cc)",
          "1.5 HYBRID V DUAL TONE (1490cc)",
          "1.5 HYBRID G DUAL TONE (1490cc)",
          "1.5 HYBRID V (1490cc)",
          "1.5 HYBRID G (1490cc)",
          "1.5 HYBRID S (1490cc)",
          "V HYBRID AT (1490cc)",
          "V AT NEO DRIVE (1490cc)",
          "V HYBRID (1490cc)",
          "G HYBRID (1490cc)",
          "S HYBRID (1490cc)",
          "1.5 G MT CNG (1462cc)",
          "1.5 S MT CNG (1462cc)",
          "1.5 NEO DRIVE V (1462cc)",
          "1.5 NEO DRIVE E (1462cc)",
          "1.5 NEO DRIVE G AT (1462cc)",
          "1.5 NEO DRIVE G (1462cc)",
          "1.5 4WD NEO DRIVE V (1462cc)",
          "1.5 NEO DRIVE S (1462cc)",
          "1.5 NEO DRIVE S AT (1462cc)",
          "1.5 NEO DRIVE V AT (1462cc)",
          "1.5 NEO DRIVE V AT DUAL TONE (1462cc)",
          "S E-CNG (1462cc)",
          "E (1462cc)",
          "G E-CNG (1462cc)",
          "G NEO DRIVE (1462cc)",
          "V NEO DRIVE (1462cc)",
          "E NEO DRIVE (1462cc)",
          "S AT NEO DRIVE (1462cc)",
          "G AT NEO DRIVE (1462cc)",
          "V AT NEO DRIVE (1462cc)",
          "V HYBRID (1462cc)",
          "V AWD NEO DRIVE (1462cc)",
          "S NEO DRIVE (1462cc)",
        ],
      },
      {
        model: "ETIOS CROSS",
        variants: [
          "G (1197cc)",
          "G (1197cc)",
          "V (1496cc)",
          "GX (1197cc)",
          "VD (1364cc)",
          "GD (1364cc)",
          "1.2 G (1197cc)",
          "1.5 V (1496cc)",
          "1.4 GD (1364cc)",
          "1.4 VD (1364cc)",
          "G X EDITION (1197cc)",
          "1.2L G (1197cc)",
          "X-EDITION PETROL (1197cc)",
          "1.2 LIMITED EDITION (1197cc)",
          "VD X EDITION (1364cc)",
          "X-EDITION DIESEL (1364cc)",
          "1.4 LIMITED EDITION (1364cc)",
        ],
      },
      {
        model: "YARIS",
        variants: [
          "1.5 J (O) (1496cc)",
          "1.5 J (O) CVT (1496cc)",
          "1.5 G (O) (1496cc)",
          "1.5 G (O) CVT (1496cc)",
          "1.5 V (O) (1496cc)",
          "1.5 V (O) CVT (1496cc)",
          "1.6 (1600cc)",
          "1.5 V (1496cc)",
          "1.5 J CVT (1496cc)",
          "1.5 J (1496cc)",
          "1.5 G (1496cc)",
          "1.5 VX (1496cc)",
          "1.5 VX CVT (1496cc)",
          "1.5 G CVT (1496cc)",
          "1.5 V CVT (1496cc)",
          "VX CVT (1496cc)",
          "V CVT OPT DUAL TONE (1496cc)",
          "G CVT (1496cc)",
          "J CVT (1496cc)",
          "V CVT (1496cc)",
          "1.5 J-OPTIONAL (1496cc)",
          "1.5 G-OPTIONAL (1496cc)",
          "1.5 V-OPTIONAL (1496cc)",
          "1.5 J-OPTIONAL CVT (1496cc)",
          "1.5 G-OPTIONALCVT (1496cc)",
          "1.5 V-OPTIONAL CVT (1496cc)",
          "J MT OPT (1496cc)",
          "J AT OPT (1496cc)",
          "G MT OPT (1496cc)",
          "G AT OPT (1496cc)",
          "V AT OPT DUAL TONE (1496cc)",
          "V MT OPT DUAL TONE. (1496cc)",
          "V MT OPT DUAL TONE (1496cc)",
          "V CVT-I (1496cc)",
          "G CVT-I (O) (1496cc)",
          "V CVT-I (O) DUAL TONE (1496cc)",
          "J CVT-I (O) (1496cc)",
          "J (1496cc)",
          "G (1496cc)",
          "VX (1496cc)",
          "G AT (1496cc)",
          "J AT (1496cc)",
          "V AT (1496cc)",
          "V MT (1496cc)",
          "VX AT (1496cc)",
          "V MT (O) DUAL TONE (1496cc)",
          "J MT (O) (1496cc)",
          "G MT (O) (1496cc)",
          "G MT (1496cc)",
          "J MT (1496cc)",
          "VX MT (1496cc)",
          "S (1600cc)",
        ],
      },
      {
        model: "VELLFIRE",
        variants: [
          "EXECUTIVE LOUNGE (2494cc)",
          "EXECUTIVE LOUNGE 3.5 4WD (3456cc)",
          "VL WITH ELECTRIC WHEEL (3496cc)",
          "3.5 VL EDITION (3456cc)",
          "HYBRID (2494cc)",
          "HYBIRD (2500cc)",
          "HYBRID (2500cc)",
          "VL 3500G (3456cc)",
          "EXECUTIVE LOUNGE BS-VI (3456cc)",
          "2.5 EXECUTIVE LOUNGE AT (2494cc)",
          "HYBRID  X LOUNGE (2494cc)",
          "3.5 EXECUTIVE LOUNGE 4 WD 7 SEAT (3450cc)",
        ],
      },
      {
        model: "GLANZA",
        variants: [
          "1.2 G (MT) (1197cc)",
          "1.2 V (MT) (1197cc)",
          "1.2 G (CVT) (1197cc)",
          "1.2 V (CVT) (1197cc)",
          "7X V MT (1197cc)",
          "1.2 S MT BSVI (1197cc)",
          "1.2 G MT BSVI (1197cc)",
          "V CVT BS6 (1197cc)",
          "S AT (1197cc)",
          "S CNG (MT) (1197cc)",
          "G CNG (MT) (1197cc)",
          "G MANUAL TRANSMISSION CNG (1197cc)",
          "S MANUAL TRANSMISSION CNG (1197cc)",
          "S MANUAL TRANSMISSION (1197cc)",
          "E MANUAL TRANSMISSION (1197cc)",
          "G MANUAL TRANSMISSION (1197cc)",
          "V MANUAL TRANSMISSION (1197cc)",
          "E (1197cc)",
          "S (1197cc)",
          "S E-CNG (1197cc)",
          "G E-CNG (1197cc)",
          "G AT (1197cc)",
          "V AT (1197cc)",
          "1.2 E MT (1197cc)",
          "1.2 S MT (1197cc)",
          "1.2 S AMT (1197cc)",
          "1.2 G AMT (1197cc)",
          "1.2 V AMT (1197cc)",
          "G MT CNG (1197cc)",
          "S MT CNG (1197cc)",
          "E MT (1197cc)",
          "S MT (1197cc)",
          "G CVT (1197cc)",
          "G HYBRID (1197cc)",
          "G (1197cc)",
          "V (1197cc)",
          "G AMT (1197cc)",
          "S AMT (1197cc)",
          "V CVT (1197cc)",
          "V AMT (1197cc)",
          "V MT (1197cc)",
          "G MT (1197cc)",
        ],
      },
      {
        model: "PRIUS",
        variants: [
          "Z8 CVT (1798cc)",
          "1.8 Z6 (1798cc)",
          "1.8 Z4 (1798cc)",
          "1.8 Z3 (1798cc)",
          "Z8 CVT (1497cc)",
          "PRIUS 1.8 Z8 BS-IV (1798cc)",
          "1.8 Z7 (1798cc)",
          "1.8 Z5 (1798cc)",
          "1.8 Z8 (1798cc)",
          "Z6 (1798cc)",
          "Z5 (1798cc)",
          "1.5 (1497cc)",
        ],
      },
      {
        model: "ETIOS",
        variants: [
          "1.5 G (1496cc)",
          "1.5 G SP* (1496cc)",
          "1.5 J (1496cc)",
          "1.5 J PS (1496cc)",
          "1.5 V (1496cc)",
          "1.5 V SP* (1496cc)",
          "1.5 VX (1496cc)",
          "1.5 GS OPTION (1496cc)",
          "1.5 XCLUSIVE (1496cc)",
          "TRD SPORTIVO (1364cc)",
          "TRD SPORTIVO (1496cc)",
          "1.4 XCLUSIVE (1364cc)",
          "1.4 D-4D GD (1364cc)",
          "1.4 D-4D GD SP* (1364cc)",
          "1.4 D-4D VD (1364cc)",
          "1.4 D-4D VXD (1364cc)",
          "1.4 D-4D VD SP* (1364cc)",
          "1.4 D-4D JD (1364cc)",
          "LIVA V (M) BSIV (1197cc)",
          "XCLUSIVE PETROL (1197cc)",
          "G SP (1197cc)",
          "TAXI CAB (1496cc)",
          "1.5 J PETROL (1496cc)",
          "1.5 VX PETROL (1496cc)",
          "1.5 G PETROL (1496cc)",
          "1.5 G SP* PETROL (1496cc)",
          "1.5 V PETROL (1496cc)",
          "1.5 V SP* PETROL (1496cc)",
          "1.5 J PS PETROL (1496cc)",
          "TRD SPORTIVO DIESEL (1364cc)",
          "TRD SPORTIVO DIESEL (1364cc)",
          "TRD SPORTIVO PETROL (1496cc)",
          "TRD SPORTIVO PETROL (1496cc)",
          "V XCLUSIVE (1496cc)",
          "SPORTIVO (1496cc)",
          "GX (1496cc)",
          "V DIESEL (1496cc)",
          "G XCLUSIVE (1496cc)",
          "1.4 D-4D GD XCLUSIVE (1496cc)",
          "PLATINUM 1.5 VX PETROL (1496cc)",
          "PLATINUM 1.5 V PETROL (1496cc)",
          "PLATINUM 1.5 GX PETROL (1496cc)",
          "PLATINUM 1.5 G PETROL (1496cc)",
          "1.5 G XCULUSIVE PETROL (1496cc)",
          "1.5 V XCULUSIVE PETROL (1496cc)",
          "VX XCLUSIVE (1496cc)",
          "V SP (1496cc)",
          "J PS (1496cc)",
          "G SAFETY (1496cc)",
          "TRD SPORTIVO PETROL LTD (1496cc)",
          "G OPT (1496cc)",
          "1.5 EI G (1496cc)",
          "XCLUSIVE PETROL (1496cc)",
          "J (1496cc)",
          "J (1496cc)",
          "V (1496cc)",
          "V (1496cc)",
          "VX (1496cc)",
          "VX (1496cc)",
          "G (1496cc)",
          "G (1496cc)",
          "G SP (1496cc)",
          "VX-D (1496cc)",
          "GD (1496cc)",
          "VD (1496cc)",
          "GD SP (1496cc)",
          "SPORTIVO (1364cc)",
          "XCLUSIVE (1364cc)",
          "D-4D J (1364cc)",
          "EXCLUSIVE (1364cc)",
          "TRD SPORTIVO DIESEL LTD MT (1364cc)",
          "PLATINUM 1.4 D-4D VXD (1364cc)",
          "PLATINUM 1.4 D-4D VD (1364cc)",
          "PLATINUM 1.4 D-4D GXD (1364cc)",
          "PLATINUM 1.4 D-4D GD (1364cc)",
          "1.4 D-4D XCULUSIVE DIESEL (1364cc)",
          "XCLUSIVE DIESEL (1364cc)",
          "VX M (1364cc)",
          "TRD SPORTIVO DIESEL LTD. (1364cc)",
          "TRD SPORTIVO DIESEL LTD (1364cc)",
          "VXD XCLUSIVE (1364cc)",
          "VXD (1364cc)",
          "VXD (1364cc)",
          "GD (1364cc)",
          "GD (1364cc)",
          "VD (1364cc)",
          "VD (1364cc)",
          "GD XCLUSIVE EDITION (1364cc)",
          "VD SP (1364cc)",
          "V (1364cc)",
          "GD SP (1364cc)",
          "JD (1364cc)",
        ],
      },
      {
        model: "ETIOS LIVA",
        variants: [
          "1.2 G (1197cc)",
          "1.2 G SP* (1197cc)",
          "1.2 J (1197cc)",
          "1.2 J PS (1197cc)",
          "1.2 V (1197cc)",
          "1.2 V SP* (1197cc)",
          "1.2 VX (1197cc)",
          "TRD SPORTIVO (1364cc)",
          "TRD SPORTIVO (1496cc)",
          "1.2 XCLUSIVE (1197cc)",
          "1.4 XCLUSIVE (1364cc)",
          "1.4 SPORTS (1364cc)",
          "PETROL (1197cc)",
          "DIESEL (1197cc)",
          "1.4 D-4D GD (1364cc)",
          "1.4 D-4D GD SP* (1364cc)",
          "1.4 D-4D VXD (1364cc)",
          "1.4 D-4D VD (1364cc)",
          "1.4 D-4D VD SP* (1364cc)",
          "1.4 D-4D JD (1364cc)",
          "GX (1197cc)",
          "GXD (1364cc)",
          "GD (1364cc)",
          "GD (1364cc)",
          "VXD (1364cc)",
          "VXD (1364cc)",
          "G (1197cc)",
          "G (1197cc)",
          "V (1197cc)",
          "V (1197cc)",
          "VX (1197cc)",
          "VX (1197cc)",
          "VD (1364cc)",
          "SPORTIVO (1197cc)",
          "XCLUSIVE (1197cc)",
          "V LIMITED EDITION (1197cc)",
          "LE (1197cc)",
          "1.2 GX PETROL (1197cc)",
          "CROSS 1.2 GX PETROL (1197cc)",
          "1.2 TRD SPORTIVO PETROL (1197cc)",
          "CROSS 1.2L G (1197cc)",
          "V SP (1197cc)",
          "G PLUS (1197cc)",
          "VX DUAL TONE (1197cc)",
          "J PS (1197cc)",
          "VX DUAL TONE LE (1197cc)",
          "LE PETROL (1197cc)",
          "XCLUSIVE PETROL (1197cc)",
          "V DUAL TONE (1197cc)",
          "J (1197cc)",
          "J (1197cc)",
          "G SP (1197cc)",
          "GD SP (1197cc)",
          "GD (1197cc)",
          "1.2 J PETROL (1197cc)",
          "1.2 VX PETROL (1197cc)",
          "1.2 XCLUSIVE (PETROL) (1197cc)",
          "1.2 G PETROL (1197cc)",
          "1.2 G SP* PETROL (1197cc)",
          "1.2 V PETROL (1197cc)",
          "1.4 XCLUSIVE (DIESEL) (1364cc)",
          "1.2 J PS  PETROL (1197cc)",
          "1.2 V SP* PETROL (1197cc)",
          "TRD SPORTIVO PETROL (1197cc)",
          "TRD SPORTIVO DIESEL (1364cc)",
          "SPORTIVO (1364cc)",
          "XCLUSIVE (1364cc)",
          "LE (1364cc)",
          "CROSS 1.4L D-4D GD (1364cc)",
          "CROSS 1.4L D-4D VD (1364cc)",
          "V SP (1364cc)",
          "VXD DUAL TONE LE (1364cc)",
          "TRD SPORTIVO DIESEL LTD (1364cc)",
          "LE DIESEL (1364cc)",
          "VD SP (1364cc)",
          "VXD DUAL TONE (1364cc)",
          "VD DUAL TONE (1364cc)",
          "G SP (1364cc)",
          "GD SP (1364cc)",
          "JD (1364cc)",
          "XCLUSIVE DIESEL (1364cc)",
          "SPORTIVO (1496cc)",
          "1.5 TRD SPORTIVO PETROL (1496cc)",
          "CROSS 1.5L V (1496cc)",
          "TRD SPORTIVO PETROL LTD (1496cc)",
          "TRD SPORTIVO PETROL (1496cc)",
        ],
      },
      {
        model: "RAV4",
        variants: [
          "TYPE G (1998cc)",
          "2.4 G (2360cc)",
          "E SXA10G (1998cc)",
          "2.4 G (1998cc)",
          "STD (1600cc)",
          "EV (2362cc)",
          "2.4 G (2000cc)",
          "XLE (2494cc)",
        ],
      },
      {
        model: "PRADO",
        variants: [
          "VX (3986cc)",
          "TZ (3000cc)",
          "DLX (3200cc)",
          "P5 (2596cc)",
          "P6 (2982cc)",
        ],
      },
      {
        model: "INNOVA HYCROSS",
        variants: [
          "G SLF CVT BSVI (1987cc)",
          "GX CVT BSVI (1987cc)",
          "VX HYBRID BSVI (1987cc)",
          "ZX HYBRID BSVI (1987cc)",
          "ZX (O) HYBRID BSVI (1987cc)",
          "VX (O) HYBRID BSVI (1987cc)",
          "G-SLF 7 STR (1987cc)",
          "GX 7 STR (1987cc)",
          "VX HYBRID 7 STR (1987cc)",
          "ZX HYBRID 7 STR (1987cc)",
          "ZX (O) HYBRID 7 STR (1987cc)",
          "ZX O HYBRID 7 STR (1987cc)",
          "G-SLF 8 STR (1987cc)",
          "GX 8 STR (1987cc)",
          "VX HYBRID 8 STR (1987cc)",
        ],
      },
      {
        model: "CARONA",
        variants: ["1993 (1600cc)"],
      },
      {
        model: "TARAGO",
        variants: ["GLX (2500cc)"],
      },
      {
        model: "LIBYA",
        variants: ["1.8 STD (1750cc)"],
      },
      {
        model: "RUMION",
        variants: [
          "S MT NEO DRIVE (1462cc)",
          "S AT NEO DRIVE (1462cc)",
          "S CNG (1462cc)",
          "G MT NEO DRIVE (1462cc)",
          "V MT NEO DRIVE (1462cc)",
          "V AT NEO DRIVE (1462cc)",
        ],
      },
      {
        model: "CELSIOR",
        variants: [
          "STD (2362cc)",
          "STANDARD (2400cc)",
          "350 G (2400cc)",
          "STANDARD (2000cc)",
          "STANDARD (3200cc)",
        ],
      },
      {
        model: "ETIOS PLATINUM",
        variants: [
          "GXD (1364cc)",
          "GXD (1364cc)",
          "VD (1364cc)",
          "VD (1364cc)",
          "VXD (1364cc)",
          "VXD (1364cc)",
          "LIMITED EDITION DIESEL (1364cc)",
          "GD (1364cc)",
          "GX (1496cc)",
          "GX (1496cc)",
          "V (1496cc)",
          "V (1496cc)",
          "VX (1496cc)",
          "VX (1496cc)",
          "LIMITED EDITION PETROL (1496cc)",
          "G (1496cc)",
          "GD (1346cc)",
          "VXD (1346cc)",
          "GXD (1346cc)",
          "VD (1346cc)",
        ],
      },
      {
        model: "CELICA",
        variants: [
          "COUPE (2362cc)",
          "DLX (2000cc)",
          "DLX (2000cc)",
          "DLX (1800cc)",
          "DLX (1948cc)",
        ],
      },
      {
        model: "CROWN",
        variants: [
          "DI (2300cc)",
          "3.0 ROYAL SALOON (2000cc)",
          "3.0 ROYAL SALOON (2000cc)",
          "STD (2997cc)",
          "3.0 ROYAL SALOON (2997cc)",
          "3.0 ROYAL SALOON (3000cc)",
          "2WD (4600cc)",
          "4WD (4300cc)",
        ],
      },
      {
        model: "HARRIER",
        variants: [
          "CARINA (3000cc)",
          "0 (2000cc)",
          "4WD (2400cc)",
          "2WD (2400cc)",
          "0 (2400cc)",
          "0 (2900cc)",
        ],
      },
      {
        model: "STARLET",
        variants: ["BASE (1332cc)", "LS (1468cc)", "0 (2000cc)", "0 (1340cc)"],
      },
      {
        model: "GRANVIA",
        variants: ["3.0 D (3000cc)", "0 (3000cc)", "3.0 TD (3000cc)"],
      },
      {
        model: "RUNNER",
        variants: ["DLX (2779cc)", "CRS V6 (3000cc)"],
      },
      {
        model: "VOXY",
        variants: ["X (1999cc)", "0 (1986cc)"],
      },
      {
        model: "CORSA",
        variants: [
          "1.5 (1453cc)",
          "STANDARD (1600cc)",
          "STD (1600cc)",
          "0 (1493cc)",
          "0 (2000cc)",
          "CORSA (1794cc)",
        ],
      },
      {
        model: "KIJANG",
        variants: ["0 (2494cc)", "LGX (2446cc)", "LGX (1800cc)"],
      },
      {
        model: "ESTIMA",
        variants: [
          "STANDARD (1900cc)",
          "2.4 (2362cc)",
          "LUCIDA VAN (2184cc)",
          "2WD CVT I (2400cc)",
          "AT (2400cc)",
          "PETROL AT (2904cc)",
          "PETROL AT (2000cc)",
        ],
      },
      {
        model: "CORONA",
        variants: [
          "CX (1600cc)",
          "1.6 ROYALE (1600cc)",
          "3.0 ROYAL SALOON PETROL (1600cc)",
          "3.0 ROYAL SALOON DIESEL (1600cc)",
          "DX (1900cc)",
          "1600 (1587cc)",
          "PETROL (1500cc)",
          "PETROL (1968cc)",
        ],
      },
      {
        model: "GRAND HIACE",
        variants: [
          "6 SEATER (3400cc)",
          "6 SEATER (3400cc)",
          "6 SEATER (2982cc)",
        ],
      },
      {
        model: "ARISTO",
        variants: [
          "STD (2362cc)",
          "SALOON (2997cc)",
          "SALOON (2997cc)",
          "SALOON (1600cc)",
        ],
      },
      {
        model: "LUCIDA",
        variants: [
          "STD (2362cc)",
          "STANDARD (2200cc)",
          "STANDARD (2000cc)",
          "STANDARD (2500cc)",
        ],
      },
      {
        model: "PREMIO",
        variants: ["1.5 F (1790cc)", "X (1794cc)", "BASE (1497cc)"],
      },
      {
        model: "MARK II",
        variants: ["SALOON (2500cc)", "STANDARD (1988cc)", "STANDARD (1988cc)"],
      },
      {
        model: "CYNOS",
        variants: ["1.5 COUPE (1497cc)", "1.5 COUPE (1497cc)"],
      },
      {
        model: "VENTURY",
        variants: [
          "MAJESTY (2694cc)",
          "2.7 V AT (2694cc)",
          "2.7 V AT (2700cc)",
        ],
      },
      {
        model: "CRESSIDA",
        variants: [
          "2 (2000cc)",
          "STD (2362cc)",
          "GL (1998cc)",
          "GLE (1998cc)",
          "2 (1988cc)",
        ],
      },
      {
        model: "SUPRA",
        variants: [
          "STANDARD (2992cc)",
          "STANDARD (2997cc)",
          "STANDARD (3000cc)",
        ],
      },
      {
        model: "CHASER",
        variants: ["XL (2000cc)", "XL (2000cc)"],
      },
      {
        model: "TERCEL",
        variants: ["VT (2000cc)", "VT (1499cc)"],
      },
      {
        model: "CARINA",
        variants: ["CARIB 5STR (1580cc)", "1.8 (1770cc)"],
      },
      {
        model: "SURF",
        variants: ["STANDARD (2500cc)", "0 (2000cc)"],
      },
      {
        model: "COMMUTER",
        variants: ["BASE (2982cc)"],
      },
      {
        model: "VISTA",
        variants: ["V40 2.2 (2184cc)"],
      },
      {
        model: "MIRAI",
        variants: ["HYDROGEN EV (124000cc)"],
      },
      {
        model: "HYCROSS",
        variants: ["ZX O (2494cc)", "HYBRID VX 75 (1987cc)"],
      },
      {
        model: "WISH",
        variants: ["2.0 D-4 (2500cc)"],
      },
      {
        model: "4RUNNER",
        variants: ["5 STR (2500cc)"],
      },
      {
        model: "MR2",
        variants: ["AW11(4A-GE) (2362cc)"],
      },
      {
        model: "IPSUM",
        variants: ["STATION WAGON (2400cc)"],
      },
      {
        model: "MARK",
        variants: ["STD (2362cc)"],
      },
      {
        model: "AYGO",
        variants: ["X-CLUSIVE (998cc)"],
      },
      {
        model: "COMFORT",
        variants: ["ZVW30R-AHXEBW (1798cc)"],
      },
      {
        model: "MARINO",
        variants: ["MARONA (1600cc)"],
      },
      {
        model: "REGIUS",
        variants: ["MA0254 (2982cc)"],
      },
      {
        model: "WAGON R",
        variants: ["WAGON (2700cc)"],
      },
      {
        model: "SSR X RF",
        variants: ["0 (2982cc)"],
      },
      {
        model: "IMPORTED VAN",
        variants: ["0 (3431cc)"],
      },
      {
        model: "DCM",
        variants: ["0 (2200cc)"],
      },
      {
        model: "TRUENO SPRINTER",
        variants: ["0 (1453cc)"],
      },
      {
        model: "VIGO HILUX",
        variants: ["0 (2500cc)"],
      },
      {
        model: "TOWN ACE NOAH",
        variants: ["0 (2000cc)"],
      },
      {
        model: "CALDINA",
        variants: ["0 (2000cc)"],
      },
      {
        model: "PREVIA",
        variants: ["BASE (2362cc)"],
      },
      {
        model: "SPRINTER MARINO",
        variants: ["STANDARD (1590cc)"],
      },
      {
        model: "VITZ",
        variants: ["STANDARD (997cc)"],
      },
      {
        model: "TRACER",
        variants: ["STANDARD (1500cc)"],
      },
      {
        model: "WAGAN HARRIER",
        variants: ["STANDARD (2994cc)"],
      },
      {
        model: "MAJESTA",
        variants: ["3.0V6 AT (2362cc)"],
      },
      {
        model: "EMINA",
        variants: ["0 (1453cc)"],
      },
    ],
  },
  {
    make: "VOLKSWAGEN",
    models: [
      {
        model: "VENTO",
        variants: [
          "1.6 TRENDLINE (1598cc)",
          "1.6 TRENDLINE (1598cc)",
          "1.6 HIGHLINE AT (1598cc)",
          "1.6 HIGHLINE AT (1598cc)",
          "1.6 COMFORTLINE MT (1598cc)",
          "GT 1.2 TSI HIGHLINE (1197cc)",
          "1.6 HIGHLINE MT (1598cc)",
          "1.6 TRENDLINE MT (1598cc)",
          "GT 1.2 TSI COMFORTLINE (1197cc)",
          "1.2 TSI COMFORTLINE (1197cc)",
          "1.5 TRENDLINE MT (1498cc)",
          "1.5 TDI COMFORTLINE (1498cc)",
          "1.5 TDI COMFORTLINE (AT) (1498cc)",
          "1.5 TDI HIGHLINE (1498cc)",
          "1.5 TDI HIGHLINE (AT) (1498cc)",
          "1.5 TDI HIGHLINE PLUS (1498cc)",
          "1.5 TDI HIGHLINE PLUS (AT) (1498cc)",
          "1.5 TDI TRENDLINE (1498cc)",
          "1.6 COMFORTLINE (1598cc)",
          "1.6 HIGHLINE PLUS (1598cc)",
          "COMFORTLINE (1598cc)",
          "HIGHLINE (1598cc)",
          "TRENDLINE (1598cc)",
          "1.5 HIGHLINE PLUS (MT) (1498cc)",
          "1.6 TRENDLINE BLACK & SILVER (1598cc)",
          "1.2TSI HIGHLINE PLUS DSG(AT) (1197cc)",
          "1.6 ALL STAR (1598cc)",
          "1.0 TSI TRENDLINE BSVI (999cc)",
          "1.0 TSI COMFORTLINE PLUS BSVI (999cc)",
          "1.0 TSI HIGHLINE BSVI (999cc)",
          "1.0 TSI EDITION BSVI (999cc)",
          "1.0 TSI HIGHLINE PLUS BSVI (999cc)",
          "1.0 TSI HIGHLINE (AT) BSVI (999cc)",
          "1.0 TSI HIGHLINE PLUS(AT)BSVI (999cc)",
          "GENERATION (1896cc)",
          "1.0 TSI COMFORTLINE BSVI (999cc)",
          "1.5 HIGHLINE MT (1498cc)",
          "1.5 HIGHLINE AT (1498cc)",
          "1.5 COMFORTLINE AT (1498cc)",
          "1.5 COMFORTLINE MT (1498cc)",
          "GT 1.2 LINE (1197cc)",
          "GT 1.5 LINE (1498cc)",
          "1.5 TDI ALL STAR (1498cc)",
          "1.2 TSI HIGHLINE PLUS AT (1197cc)",
          "1.2 TSI COMFORTLINE AT (1197cc)",
          "1.2 TSI HIGHLINE AT (1197cc)",
          "1.2 TSI HIGHLINE (1197cc)",
          "MAGNIFIC 1.6 COMFORTLINE (1598cc)",
          "MAGNIFIC 1.6 COMFORTLINE (1598cc)",
          "COMFORTLINE TDI (1598cc)",
          "COMFORTLINE MPI (1598cc)",
          "1.6 HIGHLINE TDI (1598cc)",
          "1.6 TRENDLINE TDI (1598cc)",
          "ALLSTAR (1598cc)",
          "1.6 MPI HIGHLINE MT (1598cc)",
          "MAGNIFIC 1.6 TDI HIGHLINE (1598cc)",
          "1.6 ALLSTAR PETROL (1598cc)",
          "1.6 HIGHLINE AUTOMATIC TRANSMISSION PETROL (1598cc)",
          "1.6 HIGHLINE MANUAL TRANSMISSION DIESEL (1598cc)",
          "1.6 TRENDLINE MANUAL TRANSMISSION PETROL (1598cc)",
          "1.6 HIGHLINE MANUAL TRANSMISSION PETROL (1598cc)",
          "1.6 TRENDLINE MANUAL TRANSMISSION DIESEL (1598cc)",
          "1.6 HIGHLINE PLUS MANUAL TRANSMISSION PETROL (1598cc)",
          "1.6 IPL EDITION (1598cc)",
          "1.6 STYLE MT DIESEL (1598cc)",
          "1.6 STYLE MT PETROL (1598cc)",
          "1.6 COMFORTLINE MANUAL TRANSMISSION DIESEL (1598cc)",
          "1.6 COMFORTLINE MANUAL TRANSMISSION PETROL (1598cc)",
          "1.6 MPI TRENDLINE MT (1598cc)",
          "TSI AT (1598cc)",
          "TSI AT (1598cc)",
          "HIGHLINE 1.6 (P) CONNECT EDITION (1598cc)",
          "HIGHLINE 1.6 (P) CONNECT EDITION (1598cc)",
          "MAGNIFIC 1.6 HIGHLINE (1598cc)",
          "MAGNIFIC 1.6 HIGHLINE (1598cc)",
          "HIGHLINE 1.6 MPI MT (1598cc)",
          "1.6 CR MT TRENDLINE (1598cc)",
          "1.6 CR MT HITGHLINE (1598cc)",
          "1.6 MPI AT HIGHLINE (1598cc)",
          "1.6 CR MT COMFORTLINE (1598cc)",
          "IPL EDITION (1598cc)",
          "IPL EDITION (1598cc)",
          "1.6 MPI MT ALL STAR (1598cc)",
          "1.6 MPI MT ALL STAR (1598cc)",
          "PETROL STYLE (1598cc)",
          "PETROL STYLE (1598cc)",
          "DIESEL STYLE (1598cc)",
          "DIESEL STYLE (1598cc)",
          "KONEKT DIESEL COMFORTLINE (1598cc)",
          "KONEKT DIESEL COMFORTLINE (1598cc)",
          "KONEKT DIESEL HIGHLINE (1598cc)",
          "KONEKT DIESEL HIGHLINE (1598cc)",
          "CUP EDITION PETROL (1598cc)",
          "CUP EDITION PETROL (1598cc)",
          "TRENDLINE MPI (1598cc)",
          "HIGHLINE MT (1598cc)",
          "HIGHLINE TDI (1598cc)",
          "1.6 MPI ALL STAR (1598cc)",
          "1.6 CR (MT) HIGHLINE DIESEL (1598cc)",
          "HIGHLINE DIESEL (1598cc)",
          "HIGHLINE DIESEL (1598cc)",
          "TRENDLINE DIESEL (1598cc)",
          "TRENDLINE DIESEL (1598cc)",
          "1.6 TRENDLINE MANUAL TRANSMISSION PETROL BLACK & SILVER (1598cc)",
          "1.6 TRENDLINE MANUAL TRANSMISSION DIESEL BLACK & SILVER (1598cc)",
          "TRENDLINE PETROL (1598cc)",
          "TRENDLINE PETROL (1598cc)",
          "COMFORTLINE DIESEL (1598cc)",
          "COMFORTLINE DIESEL (1598cc)",
          "HIGHLINE PETROL (1598cc)",
          "HIGHLINE PETROL (1598cc)",
          "HIGHLINE PETROL AT (1598cc)",
          "HIGHLINE PETROL AT (1598cc)",
          "COMFORTLINE PETROL (1598cc)",
          "COMFORTLINE PETROL (1598cc)",
          "HIGHLINE PLUS 1.6 PETROL (1598cc)",
          "HIGHLINE PLUS 1.6 PETROL (1598cc)",
          "BREEZE (1199cc)",
          "STYLE LIMITED EDITION (1199cc)",
          "TRENDLINE DIESEL (1199cc)",
          "TRENDLINE PETROL (1199cc)",
          "BREEZE (1198cc)",
          "STYLE LIMITED EDITION (1198cc)",
          "TRENDLINE AT (1198cc)",
          "1.2 TDI HIGHLINE (1198cc)",
          "COMFORTLINE PETROL AT (1198cc)",
          "TRENDLINE PETROL (1198cc)",
          "1.2 TSI HIGHLINE (1198cc)",
          "ALLSTAR (1498cc)",
          "1.5 COMFORTLINE ALL STAR (1498cc)",
          "COMFORTLINE 1.5 DIESEL (1498cc)",
          "1.5 ALLSTAR DIESEL (1498cc)",
          "1.5 HIGHLINE PLUS MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 TDI HIGHLINE PLUS DSG AUTOMATIC TRANSMISSION DIESEL (1498cc)",
          "1.5 TRENDLINE MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 COMFORTLINE MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 HIGHLINE MANUAL TRANSMISSION DIESEL (1498cc)",
          "1.5 COMFORTLINE AUTOMATIC TRANSMISSION DIESEL (1498cc)",
          "1.5 HIGHLINE AUTOMATIC TRANSMISSION DIESEL (1498cc)",
          "HIGHLINE 1.5 (D) CONNECT EDITION (1498cc)",
          "HIGHLINE 1.5 (D) CONNECT EDITION (1498cc)",
          "GT LINE DIESEL (1498cc)",
          "GT LINE DIESEL (1498cc)",
          "MAGNIFIC 1.5 HIGHLINE (1498cc)",
          "MAGNIFIC 1.5 HIGHLINE (1498cc)",
          "MAGNIFIC 1.5 COMFORTLINE (1498cc)",
          "MAGNIFIC 1.5 COMFORTLINE (1498cc)",
          "PREFERRED EDITION DIESEL (1498cc)",
          "PREFERRED EDITION DIESEL (1498cc)",
          "PREFERRED EDITION DIESEL AT (1498cc)",
          "PREFERRED EDITION DIESEL AT (1498cc)",
          "HIGHLINE 1.5 DIESEL (1498cc)",
          "HIGHLINE 1.5 DIESEL (1498cc)",
          "HIGHLINE DIESEL AT (1498cc)",
          "HIGHLINE DIESEL AT (1498cc)",
          "COMFORTLINE DIESEL AT (1498cc)",
          "COMFORTLINE DIESEL AT (1498cc)",
          "TRENDLINE 1.5 DIESEL (1498cc)",
          "TRENDLINE 1.5 DIESEL (1498cc)",
          "CUP EDITION DIESEL (1498cc)",
          "CUP EDITION DIESEL (1498cc)",
          "1.5 COMFORTLINE DIESEL AT (1498cc)",
          "1.5 TRENDLINE DIESEL (1498cc)",
          "1.5 HIGHLINE DIESEL AT (1498cc)",
          "1.5 HIGHLINE DIESEL (1498cc)",
          "1.5 COMFORTLINE DIESEL (1498cc)",
          "GT LINE (D). (1498cc)",
          "1.5 TDI MT ALL STAR (1498cc)",
          "1.5 TDI MT ALL STAR (1498cc)",
          "COMFORTLINE DIESEL (1498cc)",
          "COMFORTLINE DIESEL (1498cc)",
          "HIGHLINE PLUS 1.5 DIESEL (1498cc)",
          "HIGHLINE PLUS 1.5 DIESEL (1498cc)",
          "HIGHLINE PLUS 1.5 DIESEL AT (1498cc)",
          "HIGHLINE PLUS 1.5 DIESEL AT (1498cc)",
          "12.L CELESTE HIGHLINE (1197cc)",
          "1.2 COMFORTLINE ALL STAR (1197cc)",
          "TSI COMFORTLINE (1197cc)",
          "1.2 TSI HIGHLINE PETROL (1197cc)",
          "GT 1.2 TSI COMFORTLINE PETROL (1197cc)",
          "GT 1.2 TSI HIGHLINE PETROL (1197cc)",
          "1.2 TSI COMFORTLINE PETROL (1197cc)",
          "1.2 TSI HIGHLINE  PLUS DSG AUTOMATIC TRANSMISSION PETROL (1197cc)",
          "1.2 TSI DRL HIGHLINE AUTOMATIC TRANSMISSION PETROL (1197cc)",
          "1.2 TSI COMFORTLINE AUTOMATIC TRANSMISSION PETROL (1197cc)",
          "1.2 TSI HIGHLINE AUTOMATIC TRANSMISSION PETROL (1197cc)",
          "HIGHLINE PLUS 1.2 TSI (1197cc)",
          "GT LINE PETROL (1197cc)",
          "GT LINE PETROL (1197cc)",
          "PREFERRED EDITION PETROL (1197cc)",
          "PREFERRED EDITION PETROL (1197cc)",
          "PREFERRED EDITION PETROL AT (1197cc)",
          "PREFERRED EDITION PETROL AT (1197cc)",
          "COMFORTLINE PETROL AT (1197cc)",
          "COMFORTLINE PETROL AT (1197cc)",
          "HIGHLINE 1.2 PETROL AT (1197cc)",
          "HIGHLINE 1.2 PETROL AT (1197cc)",
          "1.2 TSI HIGHLINE PLUS (1197cc)",
          "GT LINE (P). (1197cc)",
          "TSI (1197cc)",
          "TSI (1197cc)",
          "HIGHLINE PLUS 1.2 PETROL AT (1197cc)",
          "HIGHLINE PLUS 1.2 PETROL AT (1197cc)",
          "1.0 HIGHLINE TSI EDITION (999cc)",
          "1.0 TSI TRENDLINE BS6 (999cc)",
          "1.0 TSI COMFORTLINE PLUS BS6 (999cc)",
          "1,0 TSI HIGHLINE PLUS BS6 (999cc)",
          "1,0 TSI HIGHLINE AT BS6 (999cc)",
          "1,0 TSI HIGHLINE PLUS AT BS6 (999cc)",
          "COMFORTLINE 1.0L TSI BS6 (999cc)",
          "TURBO EDITION 1.0L TSI BS6 (999cc)",
          "1.0 TSI TREND LINE 81 KW (999cc)",
          "1.0 TSI COMFORT LINE  81 KW (999cc)",
          "1.0 TSI HIGHLINE MT 81 KW (999cc)",
          "1.0 TSI HIGHLINE PLUSÂ MT 81 KW (999cc)",
          "1.0 TSI  HIGHLINE AT81 KW (999cc)",
          "1.0 TSI HIGHLINE PLUS AT 81 KW (999cc)",
          "RED AND WHITE EDITION (999cc)",
          "RED AND WHITE EDITION (999cc)",
          "COMFORTLINE 1.0 PLUS PETROL (999cc)",
          "COMFORTLINE 1.0 PLUS PETROL (999cc)",
          "HIGHLINE PLUS 1.0 PETROL (999cc)",
          "HIGHLINE PLUS 1.0 PETROL (999cc)",
          "TRENDLINE 1.0 PETROL (999cc)",
          "TRENDLINE 1.0 PETROL (999cc)",
          "HIGHLINE 1.0 PETROL AT (999cc)",
          "HIGHLINE 1.0 PETROL AT (999cc)",
          "HIGHLINE PLUS 1.0 PETROL AT (999cc)",
          "HIGHLINE PLUS 1.0 PETROL AT (999cc)",
          "MATT EDITION HIGHLINE 1.0 PETROL AT (999cc)",
          "MATT EDITION HIGHLINE 1.0 PETROL AT (999cc)",
          "MATT EDITION HIGHLINE PLUS 1.0 PETROL AT (999cc)",
          "MATT EDITION HIGHLINE PLUS 1.0 PETROL AT (999cc)",
          "TURBO EDITION 1.0L TSI (999cc)",
          "TURBO EDITION 1.0L TSI (999cc)",
          "HIGHLINE 1.0 PETROL (999cc)",
          "HIGHLINE 1.0 PETROL (999cc)",
          "LIMITED TSI EDITION (999cc)",
          "LIMITED TSI EDITION (999cc)",
          "1.0 HIGHLINE PLUS (999cc)",
          "1.0 HIGHLINE PLUS AT (999cc)",
          "1.0 HIGHLINE (999cc)",
          "1.0 HIGHLINE AT (999cc)",
          "1.0 TRENDLINE (999cc)",
          "1.0 COMFORTLINE PLUS (999cc)",
          "1.0 LIMITED TSI EDITION (999cc)",
          "1.0 MATT EDITION HIGHLINE AT (999cc)",
          "1.0 MATT EDITION HIGHLINE PLUS AT (999cc)",
          "1.6 HIGHLINE (1598cc)",
          "1.6 HIGHLINE (1598cc)",
        ],
      },
      {
        model: "POLO",
        variants: [
          "1.5 TDI TRENDLINE (1498cc)",
          "1.5 TDI TRENDLINE (1498cc)",
          "1.5 TDI COMFORTLINE (1498cc)",
          "1.5 TDI COMFORTLINE (1498cc)",
          "1.5 TDI HIGHLINE (1498cc)",
          "1.5 TDI HIGHLINE (1498cc)",
          "GT 1.5 TDI (1498cc)",
          "GT 1.5 TDI (1498cc)",
          "1.5 TDI HIGHLINE PLUS (1498cc)",
          "1.5 TDI HIGHLINE PLUS (1498cc)",
          "CROSS 1.5 TDI (1498cc)",
          "1.2 HIGHLINE PLUS (1198cc)",
          "1.6 HIGHLINE MT (1598cc)",
          "1.2 HIGHLINE SR (1198cc)",
          "1.2 HIGHLINE AT (1198cc)",
          "1.2 MPI HIGHLINE (1198cc)",
          "GT TSI SPORT EDITION (1198cc)",
          "1.2 MPI ANNIVERSARY EDITION (1198cc)",
          "1.2 TRENDLINE (1198cc)",
          "1.2 COMFORTLINE (1198cc)",
          "GT 1.2 TSI HIGHLINE (1197cc)",
          "GTI 1.8 TSI (AT) (1798cc)",
          "CROSS 1.2 MPI (1198cc)",
          "1.2 MPI COMFORTLINE (1198cc)",
          "1.2 MPI TRENDLINE (1198cc)",
          "1.2 COMFORTLINE ALLOY (1198cc)",
          "1.2 TDI COMFORTLINE CROSS (1199cc)",
          "1.2 MPI ALL STAR (1198cc)",
          "1.5 TDI ALL STAR (1498cc)",
          "1.5 TDI HIGHLINE CROSS (1498cc)",
          "1.5 TDI GT (1498cc)",
          "1.2 MPI HIGHLINE PLUS (1198cc)",
          "1.5 TDI ANNIVERSARY EDITION (1498cc)",
          "1.0 MPI COMFORTLINE (999cc)",
          "1.0 MPI HIGHLINE (999cc)",
          "1.0 PACE COMFORTLINE (999cc)",
          "1.2 GT TSI HIGHLINE (1198cc)",
          "(AT) (1968cc)",
          "1.0 MPI TRENDLINE BSVI (999cc)",
          "1.0 MPI COMFORTLINE BSVI (999cc)",
          "1.0 TSI HIGHLINE PLUS BSVI (999cc)",
          "1.0 GT TSI HIGHLINE BSVI (999cc)",
          "1.0 TSI HIGHLINE PLUS(AT)BSVI (999cc)",
          "TSI COMFORTLINE MT (999cc)",
          "1.0 TSI COMFORTLINE BSVI (999cc)",
          "1.2 HIGHLINE (1198cc)",
          "GT 1.2 TSI NEW (1197cc)",
          "GT 1.5 TDI NEW (1498cc)",
          "GT TDI SPORT EDITION (1498cc)",
          "1.0 MPI HIGHLINE PLUS (999cc)",
          "1.2 MPI CROSS (1198cc)",
          "1.0 TSI COMFORTLINE AT (999cc)",
          "1.2 TDI HIGHLINE CROSS (1199cc)",
          "1.2 TDI TRENDLINE (1199cc)",
          "1.2 TDI COMFORTLINE (1199cc)",
          "1.2 TDI HIGHLINE (1199cc)",
          "GT 1.6 TDI HIGHLINE (1598cc)",
          "GT TSI (999cc)",
          "GT TSI (999cc)",
          "GT TSI (1198cc)",
          "GT TSI (1198cc)",
          "HIGHLINE PLUS (999cc)",
          "TRENDLINE 1.0L BS6 (999cc)",
          "COMFORTLINE 1.0L MPI BS6 (999cc)",
          "HIGHLINE PLUS 1.0L TSI BS6 (999cc)",
          "HIGHLINE PLUS 1.0L TSI AT BS6 (999cc)",
          "GT 1.0L TSI BS6 (999cc)",
          "COMFORTLINE 1.0L TSI BS6 (999cc)",
          "COMFORTLINE TURBO EDITION BS6 (999cc)",
          "COMFORTLINE TURBO EDITION METALLIC BS6 (999cc)",
          "TRENDLINE 1.0L METALLIC BS6 (999cc)",
          "COMFORTLINE METALLIC 1.0L BS6 (999cc)",
          "1.0 AT TSI COMFORTLINE METALLIC (999cc)",
          "GT 1.0L TSI AT BS6 (999cc)",
          "1.0 MII T/L BSVI (999cc)",
          "1.0 TSI  HIGHLINE PLUS 81 KW (999cc)",
          "1.0 TSI  HIGHLINE PLUS AT 81 KW (999cc)",
          "1.0  GT TSI 81 KW (999cc)",
          "1.0  MPI COMFORT LINE 56 KW (999cc)",
          "1.0  MPI TREND LINE 56 KW (999cc)",
          "1.0 PETROL COMFORTLINE PLUS (999cc)",
          "1.0 TSI TURBO EDITION MT (999cc)",
          "1.0 PETROL LEGEND EDITION (999cc)",
          "1.0 PETROL HIGHLINE PLUS (999cc)",
          "1.0 PETROL HIGHLINE (999cc)",
          "1.0 PETROL TRENDLINE (999cc)",
          "1.0 TSI COMFORTLINE (999cc)",
          "1.0 PETROL COMFORTLINE (999cc)",
          "COMFORTLINE PLUS 1.0L MPI (999cc)",
          "COMFORTLINE PLUS 1.0L MPI (999cc)",
          "HIGHLINE PLUS 1.0L TSI AT (999cc)",
          "HIGHLINE PLUS 1.0L TSI AT (999cc)",
          "TRENDLINE 1.0L MPI (999cc)",
          "TRENDLINE 1.0L MPI (999cc)",
          "COMFORTLINE 1.0L TSI (999cc)",
          "COMFORTLINE 1.0L TSI (999cc)",
          "HIGHLINE PLUS 1.0 P (999cc)",
          "HIGHLINE PLUS 1.0 P (999cc)",
          "HIGHLINE PLUS 1.0 (P) CONNECT EDITION (999cc)",
          "HIGHLINE PLUS 1.0 (P) CONNECT EDITION (999cc)",
          "HIGHLINE PLUS 1.0 AT (999cc)",
          "HIGHLINE PLUS 1.0 AT (999cc)",
          "LEGEND EDITION (999cc)",
          "LEGEND EDITION (999cc)",
          "MATT EDITION (999cc)",
          "MATT EDITION (999cc)",
          "TURBO EDITION 1.0L TSI (999cc)",
          "TURBO EDITION 1.0L TSI (999cc)",
          "RED AND WHITE EDITION. (999cc)",
          "RED AND WHITE EDITION (999cc)",
          "HIGHLINE PLUS 1.0L TSI (999cc)",
          "HIGHLINE PLUS 1.0L TSI (999cc)",
          "COMFORTLINE 1.0L TSI AT (999cc)",
          "COMFORTLINE 1.0L TSI AT (999cc)",
          "TRENDLINE 1.0L P (999cc)",
          "TRENDLINE 1.0L P (999cc)",
          "COMFORTLINE 1.0L P (999cc)",
          "COMFORTLINE 1.0L P (999cc)",
          "HIGHLINE 1.0L P (999cc)",
          "HIGHLINE 1.0L P (999cc)",
          "CUP EDITION PETROL (999cc)",
          "CUP EDITION PETROL (999cc)",
          "1.0 MPI TRENDINE (999cc)",
          "1.0L COMFORTLINE (P) (999cc)",
          "1.0L TRENDLINE (P) (999cc)",
          "1.0L HIGHLINE PLUS AT (P) (999cc)",
          "1.0L COMFORTLINE TSI AT (P) (999cc)",
          "1.0L TRENDLINE MPI (P) (999cc)",
          "1.0L COMFORTLINE PLUS MPI (P) (999cc)",
          "1.0L HIGHLINE PLUS (P) (999cc)",
          "1.0L HIGHLINE (P) (999cc)",
          "1.0 GT TSI (999cc)",
          "1.0 TURBO EDITION TSI (999cc)",
          "1.0L COMFORTLINE TSI (P) (999cc)",
          "1.0 HIGHLINE PLUS TSI (999cc)",
          "1.0 HIGHLINE PLUS TSI AT (999cc)",
          "1.0 MATT EDITION (999cc)",
          "1.0 LEGEND EDITION (999cc)",
          "1.0L LEGEND EDITION AT (999cc)",
          "1.0 PACE (999cc)",
          "1.0 PACE (999cc)",
          "LIMITED TSI EDITION (999cc)",
          "LIMITED TSI EDITION (999cc)",
          "CROSS 1.2 TRENDLINE (1199cc)",
          "CROSS 1.2 TDI HIGHLINE (1199cc)",
          "HIGH LINE DIESEL (1199cc)",
          "TREND LINE DIESEL (1199cc)",
          "COMFORT LINE DIESEL (1199cc)",
          "COMFORTLINE 1.2L D (1199cc)",
          "COMFORTLINE 1.2L D (1199cc)",
          "HIGHLINE 1.2L D (1199cc)",
          "HIGHLINE 1.2L D (1199cc)",
          "TRENDLINE 1.2L D (1199cc)",
          "TRENDLINE 1.2L D (1199cc)",
          "1.2L COMFORTLINE (D) (1199cc)",
          "1.2L TRENDLINE (D) (1199cc)",
          "1.2L HIGHLINE (D) (1199cc)",
          "1.2 PETROL HIGHLINE (1199cc)",
          "1.2 PETROL TRENDLINE (1199cc)",
          "1.2 PETROL COMFORTLINE (1199cc)",
          "1.5 TDI HIGHLINE GP (1498cc)",
          "GT 1.5 TDI SPORT (1498cc)",
          "1.5 TDI COMFORTLINE GP (1498cc)",
          "ALL STAR 1.5 TDI HIGHLINE (1498cc)",
          "HIGHLINE PLUS (1498cc)",
          "1.5 ALL STAR TDI (1498cc)",
          "1.5 GT TDI SPORT (1498cc)",
          "ALL STAR 1.5 DIESEL HIGHLINE (1498cc)",
          "1.5 EXQUISITE DIESEL (1498cc)",
          "GT TDI SPORT (1498cc)",
          "GT TDI SPORT (1498cc)",
          "HIGHLINE PLUS 1.5 DIESEL (1498cc)",
          "HIGHLINE PLUS 1.5 DIESEL (1498cc)",
          "HIGHLINE PLUS 1.5 (D) CONNECT EDITION (1498cc)",
          "HIGHLINE PLUS 1.5 (D) CONNECT EDITION (1498cc)",
          "HIGHLINE 1.5 TDI BSIV (1498cc)",
          "HIGHLINE EXQUISITE D (1498cc)",
          "HIGHLINE EXQUISITE D (1498cc)",
          "HIGHLINE 1.5L D (1498cc)",
          "HIGHLINE 1.5L D (1498cc)",
          "COMFORTLINE 1.5L D (1498cc)",
          "COMFORTLINE 1.5L D (1498cc)",
          "TRENDLINE 1.5L D (1498cc)",
          "TRENDLINE 1.5L D (1498cc)",
          "CUP EDITION DIESEL (1498cc)",
          "CUP EDITION DIESEL (1498cc)",
          "1.5L TRENDLINE (D) (1498cc)",
          "1.5L COMFORTLINE (D) (1498cc)",
          "1.5L HIGHLINE (D) (1498cc)",
          "1.5 HIGHLINE PLUS DIESEL (1498cc)",
          "1.5 GT TDI (1498cc)",
          "GT TDI (1498cc)",
          "GT TDI (1498cc)",
          "ALLSTAR 1.5 D (1498cc)",
          "ALLSTAR 1.5 D (1498cc)",
          "GTI 1.8 BMT GTI (1798cc)",
          "GTI 1.8 TSI AT 7 SPEED DSG (1798cc)",
          "GTI AT 1.8 L (1798cc)",
          "1.8 BMT GTI (1798cc)",
          "GTI 1.8 AT (1798cc)",
          "TREND LINE PETROL (1198cc)",
          "COMFORT LINE PETROL (1198cc)",
          "HIGH LINE PETROL (1198cc)",
          "BREEZE (1198cc)",
          "ALLSTAR (1198cc)",
          "MPI HIGHLINE PLUS (1198cc)",
          "1.2 GT TSI SPORT. (1198cc)",
          "COMFORTLINE 1.2L PETROL (1198cc)",
          "1.2 MPI SR (1198cc)",
          "1.2 PETROL HIGHLINE GP (1198cc)",
          "1.2 PETROL TRENDLINE GP (1198cc)",
          "1.2 EXQUISITE PETROL (1198cc)",
          "1.2 PETROL COMFORTLINE GP (1198cc)",
          "ALL STAR 1.2 PETROL HIGHLINE (1198cc)",
          "GT 1.2 TSI  SPORT PETROL (1198cc)",
          "SR 1.2L P (1198cc)",
          "SR 1.2L P (1198cc)",
          "HIGHLINE PLUS 1.2 PETROL (1198cc)",
          "HIGHLINE PLUS 1.2 PETROL (1198cc)",
          "HIGHLINE 1.2L P (1198cc)",
          "HIGHLINE 1.2L P (1198cc)",
          "TRENDLINE 1.2L P (1198cc)",
          "TRENDLINE 1.2L P (1198cc)",
          "HIGHLINE EXQUISITE P (1198cc)",
          "HIGHLINE EXQUISITE P (1198cc)",
          "COMFORTLINE 1.2L P (1198cc)",
          "COMFORTLINE 1.2L P (1198cc)",
          "1.2L COMFORTLINE (1198cc)",
          "1.2L TRENDLINE (P) (1198cc)",
          "1.2L HIGHLINE (P) (1198cc)",
          "1.2L COMFORTLINE (P) (1198cc)",
          "1.2L HIGHLINE PLUS (P) (1198cc)",
          "GT TSI SPORT (1198cc)",
          "GT TSI SPORT (1198cc)",
          "ALLSTAR 1.2 P (1198cc)",
          "ALLSTAR 1.2 P (1198cc)",
          "1.2 TDI COMFORTLINE ALLOY (1198cc)",
          "1.2 GT TSI BSIV (1197cc)",
          "GT TSI PETROL (1197cc)",
          "1.2 GT TSI (1197cc)",
          "GT TSI SPORT EDITION (1197cc)",
          "1.2 HIGHLINE GT TSI AT (1197cc)",
          "GT TSI SPORT (1197cc)",
          "GT TSI (1197cc)",
          "GT TSI (1197cc)",
          "1.6 HIGHLINE GT DIESEL (1598cc)",
          "HIGHLINE 1.6L P (1598cc)",
          "HIGHLINE 1.6L P (1598cc)",
          "1.6 GT TDI (1598cc)",
          "1.6 HIGH LINE PETROL (1598cc)",
          "1.6L HIGHLINE (P) (1598cc)",
          "1.5 GT TDI (1598cc)",
          "GT TDI (1598cc)",
          "GT TDI (1598cc)",
          "A05 GT (1968cc)",
          "1.2 TSI (1200cc)",
          "CROSS 1.5 TDI HIGHLINE (1498cc)",
          "CROSS 1.5 TDI HIGHLINE (1498cc)",
          "GT TSI SPORT EDITION (1498cc)",
          "1.0 TRENDLINE (999cc)",
          "1.0 COMFORTLINE (999cc)",
          "1.0 HIGHLINE (999cc)",
          "ALL STAR 1.2 MPI (1198cc)",
          "ALL STAR 1.5 TDI (1498cc)",
          "1.0 PACE COMFORTLINE(M) (999cc)",
          "1.2 PETROL HIGHLINE PLUS (1198cc)",
          "1.2 PETROL HIGHLINE SR (1198cc)",
          "1.6 PETROL HIGHLINE MT (1598cc)",
          "1.2 PETROL COMFORTLINE ALLOY (1198cc)",
          "1.2 PETROL HIGHLINE AT (1198cc)",
          "GT 1.6 TDI HIGHLINE DIESEL (1598cc)",
          "GT 1.2 TSI HIGHLINE PETROL (1197cc)",
          "1.2 PETROL HIGHLINE (1198cc)",
          "1.2 PETROL TRENDLINE (1198cc)",
          "1.2 PETROL COMFORTLINE (1198cc)",
          "1.2 TDI COMFORTLINE ALLOY (1199cc)",
          "GT TSI (1498cc)",
        ],
      },
      {
        model: "TOUAREG",
        variants: [
          "5.0 V10 TDI SPL (4921cc)",
          "3.0 V6 TDI SPL (2967cc)",
          "V6 TDI AT (2967cc)",
          "5.0 V10 TDI (4921cc)",
          "5.0 V10 TDI (4921cc)",
          "4.2 V8 FSI (4163cc)",
          "4.2 V8 FSI (4163cc)",
          "3.6 V6 FSI (3598cc)",
          "3.6 V6 FSI (3598cc)",
          "3.0 V6 TDI (2967cc)",
          "3.0 V6 TDI (2967cc)",
          "4.2 V8 FSI 6 STR (4200cc)",
          "V8 4.2 TFSI (4200cc)",
          "V8 4.2 ALTITUDE TDI (4200cc)",
          "V6 TDI 3.0 (2967cc)",
          "V6 3.0 TDI 7 SEATER (2967cc)",
          "V6 3.0 TDI (2967cc)",
          "4.2 V8 FSI (2967cc)",
          "5.0 V10 TDI (4998cc)",
          "4.2 V8 FSI (4198cc)",
          "3.6 V6 FSI (3597cc)",
          "V10 5.0 TDI (4923cc)",
          "TDI (2967cc)",
          "V10 (4921cc)",
          "V10 (4921cc)",
        ],
      },
      {
        model: "JETTA",
        variants: [
          "1.4 TSIÂ COMFORTLINE (1390cc)",
          "1.4 TSI COMFORTLINE (MT) (1390cc)",
          "1.4 TSI TRENDLINE (MT) (1390cc)",
          "1.4 TSI TRENDLINE (1390cc)",
          "2.0 CR TDI HIGHLINE DSG (AT) (1968cc)",
          "2.0 CR TDI COMFORTLINE (MT) (1968cc)",
          "2.0 CR TDI HIGHLINE (MT) (1968cc)",
          "2.0 CR TDI TRENDLINE (MT) (1968cc)",
          "2.0 TDI HIGHLINE (1968cc)",
          "2.0 TDI HIGHLINE (AT) (1968cc)",
          "2.0 TDI TRENDLINE (1968cc)",
          "1.6 TRENDLINE (MT) (1695cc)",
          "2.0 TRENDLINE MT (1968cc)",
          "2.0 COMFORTLINE MT (1968cc)",
          "1.9 TDI MT (1896cc)",
          "1.9 TDI AT (1896cc)",
          "1.6 TRENDLINE (1595cc)",
          "2.0 HIGHLINE (1968cc)",
          "2.0 HIGHLINE AT (1968cc)",
          "2.0 HIGHLINE MT (1968cc)",
          "2.0 TDI (1968cc)",
          "2.0 CR TDI MT COMFORTLINE DIESEL (1968cc)",
          "2.0 CR TDI AT HIGHLINE DSG DIESEL (1968cc)",
          "2.0 TRENDLINE MANUAL TRANSMISSION DIESEL (1968cc)",
          "2.0 COMFORTLINE MANUAL TRANSMISSION DIESEL (1968cc)",
          "2.OL CR TDI AT (1968cc)",
          "TRENDLINE TDI (1968cc)",
          "TRENDLINE TDI (1968cc)",
          "COMFORTLINE TDI (1968cc)",
          "COMFORTLINE TDI (1968cc)",
          "2.0 TDI CONFORTLINE (1968cc)",
          "2.0 AT DIESEL HIGHLINE (1968cc)",
          "2.0 AT HIGHLINE (1968cc)",
          "TRENDLINE 2.0 TDI (1968cc)",
          "2.0 L COMFORTLINE (1968cc)",
          "2.0 L CR HIGHLINE TDI MT (1968cc)",
          "HIGHLINE TDI (1968cc)",
          "HIGHLINE TDI (1968cc)",
          "1.9 L TDI (1968cc)",
          "1.9 L TDI (1968cc)",
          "HIGHLINE TDI AT (1968cc)",
          "HIGHLINE TDI AT (1968cc)",
          "1.4 TRENDLINE (1390cc)",
          "1.4 COMFORTLINE (1390cc)",
          "1.4 TRENDLINE MT (1390cc)",
          "1.4 COMFORTLINE MT (1390cc)",
          "TRENDLINE TSI (1390cc)",
          "TRENDLINE TSI (1390cc)",
          "COMFORTLINE TSI (1390cc)",
          "COMFORTLINE TSI (1390cc)",
          "1.4 COMFORTLINE TSI (1390cc)",
          "1.4 TRENDLINE TSI (1390cc)",
          "2.0 TRENDLINE (1986cc)",
          "2.0 COMFORTLINE (1986cc)",
          "2.0 CR TDI AT HIGHLINE (CC 1986) (1986cc)",
          "2.0 COMFORTLINE (CC 1986) (1986cc)",
          "2.0 TRENDLINE (CC 1986) (1986cc)",
          "COMFORTLINE 2.0L TDI (1986cc)",
          "COMFORTLINE 2.0L TDI (1986cc)",
          "COMFORTLINE 1.9 TDI AT (1986cc)",
          "COMFORTLINE 2.0 TDI (1986cc)",
          "1.6 (1595cc)",
          "TRENDLINE 1.6 (1595cc)",
          "TRENDLINE 1.6 (1595cc)",
          "1.9 TDI TRENDLINE (1896cc)",
          "1.9 TDI AT COMFORTLINE (1896cc)",
          "TRENDLINE 2.0L TDI (1896cc)",
          "TRENDLINE 2.0L TDI (1896cc)",
          "COMFORTLINE 1.9 TDI AT (1896cc)",
          "COMFORTLINE 1.9 TDI AT (1896cc)",
          "TRENDLINE 1.9 TDI (1896cc)",
          "TRENDLINE 1.9 TDI (1896cc)",
          "TDI 1.9 COMFORTLINE (1896cc)",
          "TDI 1.9 TRENDLINE (1896cc)",
          "1.4 TSI (1896cc)",
          "1.9L TDI (1896cc)",
          "2.0 TDI (1596cc)",
          "1.4 TSI (1098cc)",
          "1.4 TSI TRENDLINE (1395cc)",
          "1.4 TSI CONFORTLINE (1395cc)",
          "2.0L TDI CONFORTLINE (1968cc)",
          "2.0L TDI HIGHLINE (1968cc)",
          "2.0L TDI HIGHLINE AT (1968cc)",
          "2.0L TDI TRENDLINE (1968cc)",
          "2.0 CR TDI AT HIGHLINE DSG (1968cc)",
          "2.0 CR TDI MT COMFORTLINE (1968cc)",
          "1.6 TRENDLINE (PETROL) (1595cc)",
          "2.0 CR TDI MT TRENDLINE DIESEL (1968cc)",
          "2.0 CR TDI MT HIGHLINE DIESEL (1968cc)",
          "1.4 TSI MT TRENDLINE PETROL (1390cc)",
          "1.4 TSI MT COMFORTLINE PETROL (1390cc)",
        ],
      },
      {
        model: "PASSAT",
        variants: [
          "2.0 CRDI TRENDLINE DSG (AT) (1968cc)",
          "S (1588cc)",
          "2.0 CRDI COMFORTLINE DSG (1968cc)",
          "2.0 CRDI COMFORTLINE (1968cc)",
          "2.0 TDI HIGHLINE DSG (1968cc)",
          "2.0 TDI HIGHLINE DSG S (1968cc)",
          "2.0 TDI HIGHLINE AT (1968cc)",
          "1.8 TSI (MT) (1798cc)",
          "1.8 TSI (1968cc)",
          "DSG S (1968cc)",
          "HIGHLINE AT (1968cc)",
          "2.0 TDI COMFORTLINE DSG AT (1968cc)",
          "2.0 TDI HIGHLINE DSG AT (1968cc)",
          "2.0 CRDI TRENDLINE MT (1968cc)",
          "COMFORTLINE CONNECT (1968cc)",
          "COMFORTLINE CONNECT (1968cc)",
          "HIGHLINE CONNECT (1968cc)",
          "HIGHLINE CONNECT (1968cc)",
          "COMFORTLINE MT (1968cc)",
          "COMFORTLINE MT (1968cc)",
          "2.0 PD DSG S (1968cc)",
          "2.0 PD DSG S (1968cc)",
          "2.0 PD DSG (1968cc)",
          "2.0 PD DSG (1968cc)",
          "HIGHLINE 2.0 TDI (1968cc)",
          "HIGHLINE DSG AT (1968cc)",
          "AT (1968cc)",
          "TRENDLINE MT (1968cc)",
          "TRENDLINE MT (1968cc)",
          "HIGHLINE DSG (1968cc)",
          "HIGHLINE DSG (1968cc)",
          "HIGHLINE DSG S (1968cc)",
          "HIGHLINE DSG S (1968cc)",
          "COMFORTLINE (1968cc)",
          "COMFORTLINE (1968cc)",
          "COMFORTLINE DSG (1968cc)",
          "COMFORTLINE DSG (1968cc)",
          "HIGHLINE (1968cc)",
          "HIGHLINE (1968cc)",
          "AT 2.0 TDI (1986cc)",
          "MT (1798cc)",
          "1.8 TSI MANUAL TRANSMISSION (PETROL) (1798cc)",
          "1.8 MT (1798cc)",
          "1.8L TSI (1798cc)",
          "1.8L TSI (1798cc)",
          "1.8 TSI (1798cc)",
          "1.8 TSI (1798cc)",
          "AT (2393cc)",
          "2.0 CRDI TRENDLINE DSG MT (1968cc)",
          "1.8 TSI MANUAL TRANSMISSION (1798cc)",
          "2.0 CRDI HIGHLINE AT (1968cc)",
        ],
      },
      {
        model: "PHAETON",
        variants: [
          "3.6L V6 PETROL (3597cc)",
          "3.6L V6 (2967cc)",
          "TOUAREG V10 5.0 TDI (4921cc)",
          "TOUAREG V10 5.0 TDI (4921cc)",
          "3.2 (3198cc)",
          "3.2 (3198cc)",
          "3.0 TDI (3000cc)",
          "V6 (3000cc)",
          "4.2 V8 (4200cc)",
          "3.2L V6 PETROL (3189cc)",
          ". (3189cc)",
          "36L V6 (3597cc)",
          "3.6 V6 (3597cc)",
          "3.6 (3597cc)",
          "V6 (3597cc)",
          "V6 (3597cc)",
          "V6 (2656cc)",
          "3.6L (3597cc)",
          "3.6L (3597cc)",
        ],
      },
      {
        model: "TAIGUN",
        variants: [
          "TOPLINE 1.0 TSI AT (999cc)",
          "TOPLINE 1.0 TSI AT (999cc)",
          "TOPLINE 1.0 TSI 85KW MT (999cc)",
          "GT 1.5 TSI DSG (1498cc)",
          "GT PLUS 1.5 TSI MT (1498cc)",
          "GT PLUS 1.5 TSI MT EDGE (1498cc)",
          "GT PLUS 1.5 TSI DSG EDGE (1498cc)",
          "GT 1.5 TSI MT (1498cc)",
          "GT 1.5 TSI MT (1498cc)",
          "COMFORTLINE 1.0 TSI MT (999cc)",
          "COMFORTLINE 1.0 TSI MT (999cc)",
          "HIGHLINE 1.0 TSI MT (999cc)",
          "HIGHLINE 1.0 TSI MT (999cc)",
          "HIGHLINE 1.0 TSI AT (999cc)",
          "HIGHLINE 1.0 TSI AT (999cc)",
          "GT PLUS 1.5 TSI DSG (1498cc)",
          "GT PLUS 1.5 TSI DSG (1498cc)",
          "DYANAMIC LINE 1.0 TSI COMFORTLINE MT (999cc)",
          "TOPLINE 1.0L TSI AT (999cc)",
          "1.0 TSI PETROL TOPLINE AT (999cc)",
          "1.0 TSI PETROL TOPLINE (999cc)",
          "1.0 TSI PETROL HIGHLINE AT (999cc)",
          "1.0 TSI PETROL COMFORTLINE (999cc)",
          "1.0 TSI PETROL HIGHLINE (999cc)",
          "TOPLINE AT (999cc)",
          "1.0 COMFORTLINE MT (999cc)",
          "1.0 HIGHLINE AT (999cc)",
          "1.0 HIGHLINE MT (999cc)",
          "1.0 TOPLINE MT (999cc)",
          "1.0 L TSI AT TOPLINE (999cc)",
          "1.0 L TSI MT TOPLINE (999cc)",
          "TOPLINE 1.0 TSI MT (999cc)",
          "TOPLINE 1.0 TSI MT (999cc)",
          "1.0 HIGHLINE TSI AT (999cc)",
          "1.0 HIGHLINE TSI MT (999cc)",
          "1.0 COMFORTLINE TSI MT (999cc)",
          "1.0 TOPLINE TSI MT (999cc)",
          "1.0 TOPLINE TSI AT (999cc)",
          "PERFORMANCE LINE 1.5 TSI GT MT (1498cc)",
          "PERFORMANCE LINE 1.5 TSI GT PLUS DSG (1498cc)",
          "GT PLUS 1.5TSI 110KW MT (1498cc)",
          "GT PLUS 1.5TSI 110KW DSG (1498cc)",
          "1.5 TSI PETROL GT PLUS AT (1498cc)",
          "1.5 TSI PETROL GT PLUS AT DSG (1498cc)",
          "1.5 TSI PETROL GT PLUS AT DSG VENTILATED SEAT (1498cc)",
          "GT PLUS 1.5L TSI 110KW DSG BSVI (1498cc)",
          "1.5 GT MT (1498cc)",
          "1.5 GT PLUS AT (1498cc)",
          "1.5 GT PLUS (1498cc)",
          "1.5L TSI GT PLUS DSG (1498cc)",
          "GT PLUS 1.5 TSI DSG WITH VENTILATED SEATS (1498cc)",
          "1.5 GT PLUS TSI DSG AT (1498cc)",
          "1.5 GT TSI MT (1498cc)",
          "HIGHLINE TDI (1968cc)",
          "1.0 TSI PETROL ANNIVERSARY AT (999cc)",
          "1.0 TSI PETROL ANNIVERSARY (999cc)",
          "FIRST ANNIVERSARY 1.0TSI AT (999cc)",
          "FIRST ANNIVERSARY 1.0TSI AT (999cc)",
          "FIRST ANNIVERSARY 1.0TSI MT (999cc)",
          "FIRST ANNIVERSARY 1.0TSI MT (999cc)",
          "1.5 GT TSI AT (1498cc)",
          "GT 1.5 TSI 110KW DSG (1498cc)",
          "1.5 TSI PETROL GT (1498cc)",
          "GT EDGE LIMITED EDITION DEEP BLACK PEARL (1498cc)",
          "GT EDGE LIMITED EDITION DSG DEEP BLACK PEARL (1498cc)",
          "GT EDGE LIMITED EDITION CARBON STEEL GREY MATTE (1498cc)",
          "TOPLINE 1.0 TSI AT. (1498cc)",
          "2 L TSI ELEGANCE (1968cc)",
          "2.0 ELEGANCE TSI DSG (1984cc)",
          "1.0 TSI COMFORTLINE BS-VI (1000cc)",
          "COMFORTLINE TDI (1968cc)",
        ],
      },
      {
        model: "AMEO",
        variants: [
          "1.0 MPI COMFORTLINE PLUS (999cc)",
          "1.2 MPI HIGHLINE (1198cc)",
          "1.2 MPI COMFORTLINE (1198cc)",
          "1.2 MPI TRENDLINE (1198cc)",
          "1.5 TDI TRENDLINE (1498cc)",
          "1.5 TDI COMFORTLINE (1498cc)",
          "1.5 TDI COMFORTLINE AT (1498cc)",
          "1.5 TDI HIGHLINE (1498cc)",
          "1.5 TDI HIGHLINE AT (1498cc)",
          "1.5 COMFORTLINE (1498cc)",
          "1.5 COMFORTLINE (AT) (1498cc)",
          "1.5 HIGHLINE (1498cc)",
          "1.5 HIGHLINE (AT) (1498cc)",
          "1.5 TRENDLINE (1498cc)",
          "1.2 COMFORTLINE PLUS (1198cc)",
          "1.5 COMFORTLINE PLUS (1498cc)",
          "1.5 COMFORTLINE DSG PLUS (AT) (1498cc)",
          "1.5 HIGHLINE PLUS (1498cc)",
          "1.5 HIGHLINE PLUS DSG (1498cc)",
          "1.5 TDI ANNIVERSARY EDITION (1498cc)",
          "1.0 MPI TRENDLINE (999cc)",
          "1.0 MPI COMFORTLINE (999cc)",
          "1.0 MPI HIGHLINE (999cc)",
          "1.0 MPI HIGHLINE PLUS (999cc)",
          "1.0 MPI AMEO PACE (999cc)",
          "1.2 HIGHLINE PLUS (1198cc)",
          "1.2 TRENDLINE (1198cc)",
          "1.2 COMFORTLINE (1198cc)",
          "1.2 HIGHLINE (1198cc)",
          "1.2 MPI ANNIVERSARY EDITION (1198cc)",
          "1.2 MPI ANNIVERSARY EDITION (1198cc)",
          "MPI ANNIVERSARY EDITION (1198cc)",
          "MPI HIGHLINE 16 ALLOY (1198cc)",
          "1.2 L HIGHLINE PLUS (1198cc)",
          "1.2 COMFORTLINE PLUS PETROL (1198cc)",
          "1.2 COMFORTLINE ANNIVERSARY EDITION (1198cc)",
          "HIGHLINE 1.2L PLUS (P) (1198cc)",
          "HIGHLINE 1.2L PLUS (P) (1198cc)",
          "COMFORTLINE 1.2L (P) (1198cc)",
          "COMFORTLINE 1.2L (P) (1198cc)",
          "HIGHLINE 1.2L (P) (1198cc)",
          "HIGHLINE 1.2L (P) (1198cc)",
          "TRENDLINE 1.2L (P) (1198cc)",
          "TRENDLINE 1.2L (P) (1198cc)",
          "COMFORTLINE PLUS 1.2L (P) (1198cc)",
          "COMFORTLINE PLUS 1.2L (P) (1198cc)",
          "HIGHLINE (1198cc)",
          "1.2 HIGHLINE PLUS PETROL (1198cc)",
          "COMFORTLINE (1198cc)",
          "TRENDLINE (METALLIC) (1198cc)",
          "TRENDLINE (1198cc)",
          "COMFORTLINE (METALLIC) (1198cc)",
          "1.2L COMFORTLINE PLUS PETROL (1198cc)",
          "TDI HIGHLINE 16 ALLOY (1498cc)",
          "TDI HIGHLINE AT 16 ALLOY (1498cc)",
          "1.5 TDI MT HIGHLINE (1498cc)",
          "1.5 COMFORTLINE PLUS DIESEL (1498cc)",
          "1.5 GT HIGHLINE DIESEL (1498cc)",
          "1.5 TRENDLINE DIESEL (1498cc)",
          "1.5 COMFORTLINE DIESEL (1498cc)",
          "1.5 COMFORTLINE AUTOMATIC DSG DIESEL (1498cc)",
          "1.5 HIGHLINE DIESEL (1498cc)",
          "1.5 HIGHLINE AUTOMATIC DSG DIESEL (1498cc)",
          "1.5 HIGHLINE PLUS AUTOMATIC DSG DIESEL (1498cc)",
          "1.5 HIGHLINE PLUS DIESEL (1498cc)",
          "HIGHLINE PLUS 1.5L (D) CONNECT EDITION (1498cc)",
          "HIGHLINE PLUS 1.5L (D) CONNECT EDITION (1498cc)",
          "GT LINE (D) (1498cc)",
          "GT LINE (D) (1498cc)",
          "HIGHLINE 1.5L AT (D) (1498cc)",
          "HIGHLINE 1.5L AT (D) (1498cc)",
          "HIGHLINE PLUS 1.5L (D) (1498cc)",
          "HIGHLINE PLUS 1.5L (D) (1498cc)",
          "HIGHLINE PLUS 1.5L AT (D) (1498cc)",
          "HIGHLINE PLUS 1.5L AT (D) (1498cc)",
          "TRENDLINE 1.5L (D) (1498cc)",
          "TRENDLINE 1.5L (D) (1498cc)",
          "COMFORTLINE 1.5L (D) (1498cc)",
          "COMFORTLINE 1.5L (D) (1498cc)",
          "COMFORTLINE 1.5L AT (D) (1498cc)",
          "COMFORTLINE 1.5L AT (D) (1498cc)",
          "HIGHLINE 1.5L (D) (1498cc)",
          "HIGHLINE 1.5L (D) (1498cc)",
          "COMFORTLINE PLUS 1.5L (D) (1498cc)",
          "COMFORTLINE PLUS 1.5L (D) (1498cc)",
          "COMFORTLINE PLUS 1.5L AT (D) (1498cc)",
          "COMFORTLINE PLUS 1.5L AT (D) (1498cc)",
          "CUP EDITION DIESEL (1498cc)",
          "CUP EDITION DIESEL (1498cc)",
          "1.5L TDI MT COMFORTLINE PLUS (1498cc)",
          "1.5L HIGHLINE (D) (1498cc)",
          "1.5L COMFORTLINE AT (D) (1498cc)",
          "1.5L HIGHLINE AT (D) (1498cc)",
          "1.5L TRENDLINE (D) (1498cc)",
          "1.5L COMFORTLINE (D) (1498cc)",
          "1.5L HIGHLINE AT PLUS (D) (1498cc)",
          "1.5L HIGHLINE PLUS (D) (1498cc)",
          "1.0 COMFORTLINE MT (999cc)",
          "1.0 HIGHLINE MT (999cc)",
          "1.0 HIGHLINE PLUS MT (999cc)",
          "1.0 TRENDLINE MT (999cc)",
          "1.0 COMFORTLINE PLUS PETROL (999cc)",
          "1.0 HIGHLINE PETROL (999cc)",
          "1.0 COMFORTLINE PETROL (999cc)",
          "1.0 TRENDLINE PERTOL (999cc)",
          "1.0 HIGHLINE PLUS PETROL (999cc)",
          "COMFORTLINE 1.0L (P) (999cc)",
          "COMFORTLINE 1.0L (P) (999cc)",
          "HIGHLINE 1.0L (P) (999cc)",
          "HIGHLINE 1.0L (P) (999cc)",
          "HIGHLINE 1.0L PLUS (P) (999cc)",
          "HIGHLINE 1.0L PLUS (P) (999cc)",
          "TRENDLINE 1.0L (P) (999cc)",
          "TRENDLINE 1.0L (P) (999cc)",
          "HIGHLINE PLUS 1.0L (P) CONNECT EDITION (999cc)",
          "HIGHLINE PLUS 1.0L (P) CONNECT EDITION (999cc)",
          "1.0 PACE (999cc)",
          "1.0 PACE (999cc)",
          "CUP EDITION PETROL (999cc)",
          "CUP EDITION PETROL (999cc)",
          "1.0L PACE PETROL (999cc)",
          "1.0L HIGHLINE PLUS (P) (999cc)",
          "1.0L TRENDLINE (P) (999cc)",
          "1.0L COMFORTLINE (P) (999cc)",
          "COMFORTLINE 1.2L (1198cc)",
          "HIGHLINE1.2L (1198cc)",
          "TRENDLINE 1.2L (1198cc)",
          "COMFORTLINE 1.5L (1498cc)",
          "COMFORTLINE 1.5L AT (1498cc)",
          "HIGHLINE1.5L (1498cc)",
          "HIGHLINE1.5L AT (1498cc)",
          "TRENDLINE 1.5L (1498cc)",
          "1.0 TRENDLINE (999cc)",
          "1.0 COMFORTLINE (999cc)",
          "1.0 COMFORTLINE PLUS (999cc)",
          "1.0 HIGHLINE (999cc)",
          "1.0 HIGHLINE PLUS (999cc)",
          "1.0 AMEO PACE (999cc)",
        ],
      },
      {
        model: "T ROC",
        variants: ["1.5 TSI HIGHLINE DSG (1498cc)"],
      },
      {
        model: "T-ROC",
        variants: [
          "1.5 TSI GT PLUS DSG (1498cc)",
          "1.5 TSI DSG BS6 (1498cc)",
          "1.5 TSI EVO 5 SEATER (1498cc)",
          "1.5 TSI (1498cc)",
          "1.5 TSI (1498cc)",
        ],
      },
      {
        model: "TIGUAN",
        variants: [
          "2.0 TDI COMFORTLINE (1968cc)",
          "2.0 TDI HIGHLINE (1968cc)",
          "2.0 ELEGANCE TSI DSG (1984cc)",
          "ALLSPACE 2.0 TSI DSG (1984cc)",
          "HIGHLINE (1968cc)",
          "HIGHLINE TDI AT (1968cc)",
          "2.0 TDI HIGHLINE 5 SEATER (1968cc)",
          "2.0L TDI HIGHLINE (AT) (1968cc)",
          "HIGHLINE TDI (1968cc)",
          "HIGHLINE TDI (1968cc)",
          "HIGHLINE 2.0 TSI (1984cc)",
          "ALLSPACE 2.0 TSI DSG BS6 (1984cc)",
          "2.0 TSI ALL SPACE 7 SEATER (1984cc)",
          "2.0 TSI ELEGANCE (1984cc)",
          "2.0 TSI ALL SPACE 5 SEATER (1984cc)",
          "ELEGANCE 2.0 TSI DSG (1984cc)",
          "ELEGANCE 2.0 TSI DSG (1984cc)",
          "2.0 ELEGANCE TSI DSG (AT) (1984cc)",
          "ALLSPACE 2.0 TSI (1984cc)",
          "ALLSPACE 2.0 TSI (1984cc)",
          "EXCLUSIVE EDITION (1984cc)",
          "EXCLUSIVE EDITION (1984cc)",
          "2.0 TDI CONFORTLINE (1968cc)",
          "COMFORTLINE (1968cc)",
          "2.0 TDI COMFORTLINE 5 SEATER (1968cc)",
          "COMFORTLINE TDI (1968cc)",
          "COMFORTLINE TDI (1968cc)",
        ],
      },
      {
        model: "VINTAGE",
        variants: [
          "BEETLE (1089cc)",
          "BEETLE (975cc)",
          "BEETLE (1470cc)",
          "BEETLE 1200 (1300cc)",
          "OVAL BEETLE (1192cc)",
          "OVAL BEETLE (6768cc)",
          "1973 (1489cc)",
          "VINATGE (1192cc)",
          "VINATGE (1600cc)",
          "VINATGE (2000cc)",
          "STATION WAGON A G (1131cc)",
          "KARMANN GHIA (1200cc)",
          "VINATGE (1740cc)",
        ],
      },
      {
        model: "CABROLAT",
        variants: ["PETROL (1781cc)"],
      },
      {
        model: "GTI",
        variants: ["1.8 TSI (1798cc)", "1.8 TSI (1798cc)", "TSI (1798cc)"],
      },
      {
        model: "BEETLE",
        variants: [
          "1.4 TSI (1395cc)",
          "1.4 TSI (1395cc)",
          "2.0 MT (1984cc)",
          "2.0 PETROL (1984cc)",
          "NEW 2.0 PETROL AUTOMATIC TRANSMISSION (1984cc)",
          "2.0 DIESEL MT (1984cc)",
          "2.0 DIESEL MT (1984cc)",
          "NEW 2.0 AUTOMATIC TRANSMISSION (1984cc)",
          "(NEW) (1984cc)",
          "2 (1984cc)",
          "NEW  2.0 (AT) (1984cc)",
          "2.0 AT (1984cc)",
          "2.0 AT (1984cc)",
          "1.4 TSI 5 STR (1395cc)",
          "NEW 1.4 TSI PETROL 110 KW (1395cc)",
          "NEW 1.4 TSI PETROL 110 KW 5 SEATER (1395cc)",
          "1.4 TSI AT (1395cc)",
          "1.3 PETROL (1297cc)",
          "NEW 1.6 CABRIOLET (1595cc)",
          "NEW 1.9 TDI (1896cc)",
          "1.9 TDI MT (1896cc)",
          "1.9 TDI (1896cc)",
          "1.9 TDI (1896cc)",
          "GLS (2000cc)",
          "2 (1276cc)",
          "1.4 TSI (1390cc)",
        ],
      },
      {
        model: "VIRTUS",
        variants: [
          "TOPLINE 1.0 TSI MT (999cc)",
          "COMFORTLINE 1.0 TSI MT (999cc)",
          "HIGHLINE 1.0 TSI MT (999cc)",
          "TOPLINE 1.0 TSI AT (999cc)",
          "COMFORTLINE MT (999cc)",
          "HIGHLINE MT (999cc)",
          "TOPLINE MT (999cc)",
          "HIGHLINE AT (999cc)",
          "TOPLINE AT (999cc)",
          "1.0L TSI AT HIGHLINE (999cc)",
          "1.0L TSI AT TOPLINE (999cc)",
          "1.0L TSI MT COMFORTLINE (999cc)",
          "1.0L TSI MT HIGHLINE (999cc)",
          "1.0L TSI MT TOPLINE (999cc)",
          "HIGHLINE 1.0L TSI MT (999cc)",
          "TOPLINE 1.0L TSI AT (999cc)",
          "HIGHLINE 1.0L TSI AT (999cc)",
          "TOPLINE 1.0L TSI MT (999cc)",
          "COMFORTLINE 1.0L TSI MT (999cc)",
          "1.0 TSI MT COMFORTLINE (999cc)",
          "1.0 TSI MT HIGHLINE (999cc)",
          "1.0 TSI AT HIGHLINE (999cc)",
          "1.0 TSI MT TOPLINE (999cc)",
          "1.0 TSI AT TOPLINE (999cc)",
          "HIGHLINE 1.0 TSI AT (999cc)",
          "1.0 TOPLINE TSI MT (999cc)",
          "1.0 COMFORTLINE TSI MT (999cc)",
          "1.0 HIGHLINE TSI MT (999cc)",
          "1.0 TOPLINE TSI AT (999cc)",
          "1.0 HIGHLINE TSI AT (999cc)",
          "GT PLUS (1498cc)",
          "GT PLUS 1.5TSI 110KW MT (1498cc)",
          "GT PLUS 1.5TSI 110KW DSG (1498cc)",
          "1.5L TSI DSG GT PLUS (1498cc)",
          "GT PLUS 1.5 TSI EVO DSG (1498cc)",
          "GT PLUS 1.5 TSI EVO DSG (1498cc)",
          "1.5L TSI GT PLUS DSG (1498cc)",
          "GT PLUS 1.5 MT (1498cc)",
          "GT 1.5 TSI DSG (1498cc)",
          "GT EDGE LIMITED EDITION DSG (1498cc)",
          "GT EDGE LIMITED EDITION MT (1498cc)",
          "1.5 TSI DSG GT PLUS (1498cc)",
          "1.5 GT PLUS TSI EVO DSG (AT) (1498cc)",
        ],
      },
      {
        model: "CROSS POLO",
        variants: [
          "1.2 TSI (1197cc)",
          "1.2 TSI (1197cc)",
          "1.2 TDI (1199cc)",
          "1.2 TDI (1199cc)",
          "1.5 TDI (1498cc)",
          "1.5 TDI (1498cc)",
          "1.2 MPI (1198cc)",
          "1.2 MPI (1198cc)",
        ],
      },
      {
        model: "CARAVELLE",
        variants: [
          "2.0 TDI (1968cc)",
          "2.0 EXECUTIVE BMT TDI (1968cc)",
          "VAN (1968cc)",
          "SE (1968cc)",
          "T3 (1584cc)",
          "T3 (1984cc)",
          "2.0 I TDI TRENDLINE 15 SEATER (2000cc)",
          "SE 3.2 (3200cc)",
          "SE VAN (2460cc)",
          "TRENDLINE (2459cc)",
        ],
      },
      {
        model: "MULTIVAN",
        variants: ["TDI (2500cc)", "HIGH LINE 3.2 SI V6 (3198cc)"],
      },
      {
        model: "TRANSPORTER",
        variants: [
          "T5 (2461cc)",
          "T5 - 2.0 TDI 5 SEATER (1968cc)",
          "T5 - 2.5 TDI (9 SEATER) (2450cc)",
          "T5 - 2.5 TDI (7 SEATER) (2450cc)",
        ],
      },
      {
        model: "CRAFTER",
        variants: ["11 STR (2461cc)"],
      },
      {
        model: "SHUTTLE",
        variants: ["1.9TDI (1896cc)"],
      },
      {
        model: "AMAROK",
        variants: ["2.0 TDI CORE EDITION 4X4 (1968cc)"],
      },
      {
        model: "CADDY",
        variants: ["COMBI (1968cc)"],
      },
      {
        model: "CARAVELLS",
        variants: ["2.5I (2458cc)", "AG (2459cc)"],
      },
      {
        model: "TYPE 1300",
        variants: ["STANDARD (1300cc)"],
      },
      {
        model: "VW 1500",
        variants: ["NOTCHBACK (1500cc)"],
      },
      {
        model: "1600 L",
        variants: ["FASTBACK (1584cc)", "FAST BACK (1984cc)"],
      },
      {
        model: "T5 MULTIVAN",
        variants: ["HIGHLINE (2461cc)"],
      },
      {
        model: "LUPO",
        variants: ["0 (999cc)"],
      },
      {
        model: "GOLF",
        variants: ["1.5 TDI (1471cc)"],
      },
      {
        model: "KARMANN GHIA",
        variants: ["0 (1798cc)"],
      },
    ],
  },
  {
    make: "DAEWOO",
    models: [
      {
        model: "CIELO",
        variants: [
          "GL (1498cc)",
          "GL (1498cc)",
          "CNG (1498cc)",
          "CNG (1498cc)",
          "STD (1800cc)",
          "GLE (1498cc)",
          "GLE (1498cc)",
          "GLX (1498cc)",
          "GLX (1498cc)",
          "CNG PS (1498cc)",
          "CNG PS (1498cc)",
          "NEXIA DELUXE (1498cc)",
          "NEXIA DELUXE (1498cc)",
          "STD PS (1498cc)",
          "STD PS (1498cc)",
          "STD (1498cc)",
          "STD (1498cc)",
          "GLX AT (1498cc)",
          "GLX AT (1498cc)",
          "GLE AT (1498cc)",
          "GLE AT (1498cc)",
          "STANDARD (1800cc)",
          "CNG (1500cc)",
          "STD (3179cc)",
        ],
      },
      {
        model: "MATIZ",
        variants: [
          "SA (796cc)",
          "SA (796cc)",
          "SD (796cc)",
          "SD (796cc)",
          "SS (796cc)",
          "SS (796cc)",
          "SG (796cc)",
          "SG (796cc)",
          "SP (796cc)",
          "SP (796cc)",
          "SE (796cc)",
          "SE (796cc)",
        ],
      },
      {
        model: "NEXIA",
        variants: [
          "DELUXE (1498cc)",
          "DELUXE (1498cc)",
          "STANDARD (1498cc)",
          "STD (1498cc)",
          "STD (1498cc)",
        ],
      },
      {
        model: "LACETT",
        variants: ["0 (2000cc)"],
      },
    ],
  },
  {
    make: "HINDUSTAN",
    models: [
      {
        model: "CONTESSA",
        variants: [
          "1.8 CLASSIC GLX (1817cc)",
          "2.0 CLASSIC DLX (1995cc)",
          "2.0T CLASSIC (1995cc)",
          "CLASSIC DLX (1995cc)",
          "CLASSIC ISUZU (1995cc)",
          "CLASSIC 2.0 DLX ISUZU PETROL (1995cc)",
          "CLASSIC 2.0 DLX ISUZU DIESEL (1995cc)",
          "CLASSIC 2.0T ISUZU TURBO DIESEL (1995cc)",
          "2.0 DSL (1995cc)",
          "CLASSIC 2.0 DL (1995cc)",
          "2.0 DL (1995cc)",
          "CLASSIC GLX (1817cc)",
          "CLASSIC 1.8 GLX ISUZU PETROL (1817cc)",
          "CLASSIC 1.8 GLX (1817cc)",
          "CLASSIC 1.8 GLX (1817cc)",
          "1.8 GLX (1000cc)",
          "1.8 GL CLASSIC (1000cc)",
        ],
      },
      {
        model: "AMBASSADOR",
        variants: [
          "2000 DSL (1995cc)",
          "2000 DSL. (1995cc)",
          "GRAND 2000 DSZ (1995cc)",
          "GRAND 2000 DSZ (1995cc)",
          "MARK III (1489cc)",
          "GRAND 1800 ISZ (1817cc)",
          "GRAND HARTIC 1800 (1817cc)",
          "MARK II (1489cc)",
          "GRAND 1800 ISZ AC SUNROOF (1817cc)",
          "1800 ISZ (1817cc)",
          "CLASSIC 1800 ISZ (1817cc)",
          "CLASSIC 1800 ISZ AC (1817cc)",
          "GRAND 1500 DSZ (1489cc)",
          "CLASSIC 1500 DSL (1489cc)",
          "CLASSIC 1500 DSL AC (1489cc)",
          "NOVA (1489cc)",
          "AVIGO 1800 ISZ MPFI (1817cc)",
          "CLASSIC 1800 ISZ MPFI (1817cc)",
          "CLASSIC 1800 ISZ MPFI AC (1817cc)",
          "CLASSIC 1800 ISZ MPFI PS (1817cc)",
          "GRAND 1800 ISZ MPFI (1817cc)",
          "AVIGO 2000 DSZ (1995cc)",
          "CLASSIC 2000 DSZ (1995cc)",
          "CLASSIC 2000 DSZ AC (1995cc)",
          "CLASSIC 2000 DSZ AC PS (1995cc)",
          "MARK III PETROL (1489cc)",
          "1800 ISZ LPG (1817cc)",
          "GRAND HARTIC 1800 CNG (1817cc)",
          "CLASSIC 1800 ISZ AC CNG (1817cc)",
          "CLASSIC 1800 ISZ CNG (1817cc)",
          "GRAND 1800 ISZ CNG (1817cc)",
          "2000 DSL FSS A/C PS E3 (1995cc)",
          "DSL E RLD TYT (1995cc)",
          "2000 ISZ E-11 (1995cc)",
          "DSL 6STR (1995cc)",
          "GRAND 2000 DSL FSS AC PS (1995cc)",
          "GRAND (1995cc)",
          "2000 (1995cc)",
          "CLASSIC (1995cc)",
          "AVIGO (1995cc)",
          "GRAND 2000 DSL (1995cc)",
          "ENCORE BS IV (1995cc)",
          "GRAND 1500 DSZ (1995cc)",
          "AMB DIESEL (1995cc)",
          "DIESEL (1995cc)",
          "GRAND 2000 DSZ PW CL (1995cc)",
          "DSL 1500CC SPLIT RDL TG 5S AC (1499cc)",
          "1500 DSL AC (1499cc)",
          "CLASSIC 1.5 DSL (1489cc)",
          "ORD (1489cc)",
          "1.5 D (1489cc)",
          "1.5 PETROL (1489cc)",
          "DSL NYL (1489cc)",
          "GRAND (1489cc)",
          "MARK I (1489cc)",
          "1500 (1489cc)",
          "CLASSIC (1489cc)",
          "ENCORE (1489cc)",
          "ENCORE 1500 (1489cc)",
          "GRAND 1500 DSL (1489cc)",
          "CLASSIC 1500 DS (1489cc)",
          "GRAND 1500 DSL BSIII (1489cc)",
          "ENCORE 1500 DS (1489cc)",
          "2000 DLS (1489cc)",
          "1500 DIESEL FSS AC (1489cc)",
          "1500 DIESEL FSS PS (1489cc)",
          "ENCORE FSS (1489cc)",
          "ENCORE FSS PS (1489cc)",
          "ENCORE FSS AC (1489cc)",
          "ENCORE FSS PS AC (1489cc)",
          "MARK I  PETROL (1489cc)",
          "CLASSIC 1500 DSL 6 SEAT (1489cc)",
          "ENCORE BS IV (1489cc)",
          "NOVA DIESEL (1489cc)",
          "CLASSIC NOVA DELUXE (1489cc)",
          "CLASSIC NOVA DIESEL (1489cc)",
          "NOVA PETROL (1489cc)",
          "NOVA DELUXE (1489cc)",
          "ENCORE 1.5 DSL (1489cc)",
          "1.5 DSL (1489cc)",
          "1.5 DIESEL (1489cc)",
          "1.5 DLS (1489cc)",
          "1.8ISZCLASSIC NONAC PET E3 (1817cc)",
          "1.8 DSL (1817cc)",
          "1800 1SZ BU TG E2 (1817cc)",
          "1.8 PETROL (1817cc)",
          "1.8 PETROL (1817cc)",
          "1800 (1817cc)",
          "GRAND (1817cc)",
          "STANDARD (1817cc)",
          "CLASSIC (1817cc)",
          "AVIGO (1817cc)",
          "GRAND 1800 ISZ MPFI 6 STR (1817cc)",
          "NOVA CAR (1817cc)",
          "1800 ISZ LPG 6 SEAT (1817cc)",
          "CLASSIC NOVA PETROL (1817cc)",
          "1.8 LPG (1817cc)",
          "GRAND PETROL AC PS PW (1817cc)",
          "GRAND CNG AC (1817cc)",
          "GRAND CNG AC PS (1817cc)",
          "GRAND CNG AC PS PW (1817cc)",
          "GRAND LPG NON AC (1817cc)",
          "GRAND LPG AC (1817cc)",
          "1.8 CNG (1817cc)",
          "CLASSIC 1800 ISZ CNG AC (1817cc)",
          "GRAND 1800 ISZ MPFI PW CL (1817cc)",
          "1800 ISZ MPFI PW CL (1817cc)",
          "AMB CNG (1817cc)",
          "GRAND 1800 ISZ MPFI PW CL CNG (1817cc)",
          "CLASSIC 1800 ISZ MPFI AC PS (1817cc)",
          "1.5 DSL BE/AC (1498cc)",
          "CLASSIC FSS AC MS (1498cc)",
          "GRAND (1800cc)",
          "GRAND 1800 ISZ MPFI BE AC (1800cc)",
          "GRAND 1800 ISZ MPFI BE AC PS (1800cc)",
          "GRAND 1800 ISZ MPFI BU AC PS RSM (1800cc)",
          "GRAND HARITC1800 CNG BU AC PS RSM PW CL (1800cc)",
          "GRAND 1800 ISZ MPFI BU AC PS RSM PS CL (1800cc)",
          "GRAND HARITC1800 CNG BU AC (1800cc)",
          "CLASSIC 1800 ISZ MPFI BU (1800cc)",
          "GRAND 1800 ISZ MPFI BU AC (1800cc)",
          "GRAND HARITC1800 CNG BU AC PS RSM (1800cc)",
          "GRAND 1800 ISZ MPFI BU AC PS (1800cc)",
          "CLASSIC 1800 ISZ MPFI BE (1800cc)",
          "NOVA (1800cc)",
          "GRAND 2000 DSZ NA BU AC RSM (2000cc)",
          "GRAND 2000 DSZ NA FSS AC (2000cc)",
          "GRAND 2000 DSZ NA BU AC PS RSM (2000cc)",
          "GRAND 2000 DSZ NA FSS (2000cc)",
          "GRAND 2000 DSZ NA BU AC PS PW CL (2000cc)",
          "GRAND 2000 DSZ NA FSS AC & PC (2000cc)",
          "GRAND 2000 DSZ NA BU RSM (2000cc)",
        ],
      },
      {
        model: "VINTAGE",
        variants: [
          "LAND MASTER (1400cc)",
          "LAND MASTER (1476cc)",
          "AMBASSADOR (1495cc)",
          "AMBASSADOR (1415cc)",
          "AMBASSADOR (1092cc)",
          "AMBASSADOR (1450cc)",
          "AMBASSADOR (860cc)",
          "VINTAGE (1500cc)",
          "VINTAGE (980cc)",
          "VINTAGE (2800cc)",
        ],
      },
      {
        model: "CONTESSA CLASSIC1.8 GLX ISUZU PETROL",
        variants: ["0 (1817cc)"],
      },
      {
        model: "CONTESSA CLASSIC2.0 DLX ISUZU DIESEL",
        variants: ["0 (1995cc)"],
      },
      {
        model: "CONTESSA CLASSIC2.0T ISUZU TURBO DIESEL",
        variants: ["0 (1995cc)"],
      },
      {
        model: "COSMO MINIBUS",
        variants: ["14 SEATER/CNG (1800cc)", "14 SEATER/DIESEL (1800cc)"],
      },
      {
        model: "TREKKER",
        variants: [
          "STD (1489cc)",
          "10 SEATER (1489cc)",
          "DIESEL 6 STR (1489cc)",
          "ISZ (1489cc)",
          "0 (1489cc)",
        ],
      },
      {
        model: "RTV",
        variants: ["DAC (1995cc)"],
      },
      {
        model: "LANCER DIESEL",
        variants: ["LXD (1998cc)"],
      },
      {
        model: "CLASSIC",
        variants: [
          "1500  DSL  SE  E-II (1489cc)",
          "1800  ISZ  MPFI  E-II   BE (1817cc)",
          "1800  ISZ  MPFI  E-II   BU (1817cc)",
          "2000  DSZ E-II  BE (1995cc)",
          "2000  DSZ E-II  BU (1995cc)",
        ],
      },
      {
        model: "GRAND",
        variants: [
          "DIESEL E2BUAC (1489cc)",
          "1800  ISZ  MPFI  E-II  BE/AC/PS (1817cc)",
          "1800  ISZ  MPFI  E-II  BU/AC/PS/RSM (1817cc)",
        ],
      },
      {
        model: "NOVA",
        variants: ["DIESEL - DELUXE (1489cc)", "PETROL (1489cc)"],
      },
      {
        model: "AVIGO 1800",
        variants: ["ISZ  MPFI  BS-II  AC/PS/LS/MP/MS/AW/RL (1817cc)"],
      },
      {
        model: "AVIGO 2000",
        variants: ["DSZ  E-II  AC/PS/MP/MS/AW/RL (1995cc)"],
      },
      {
        model: "FSS NON AC TG 5SPEED BSIII",
        variants: ["0 (1489cc)"],
      },
      {
        model: "AMBYLIMO",
        variants: ["0 (1800cc)"],
      },
      {
        model: "HM 14",
        variants: ["0 (1475cc)"],
      },
    ],
  },
  {
    make: "FERRARI",
    models: [
      {
        model: "296",
        variants: ["GTB (2992cc)"],
      },
      {
        model: "360",
        variants: ["SPIDER (3586cc)", "FERRARI 360 (3586cc)"],
      },
      {
        model: "458",
        variants: [
          "4.5 ITALIA COUPE (4496cc)",
          "4.5 SPIDER (4499cc)",
          "ITALIA (4499cc)",
          "ITALIA (4499cc)",
          "SPECIALE (4499cc)",
          "SPIDER 4.5 (4499cc)",
          "ITALIA 4.5 COUPE (4496cc)",
          "SPECIALE A (4497cc)",
          "SPECIALE V8 AT (4497cc)",
          "SPIDER (4497cc)",
          "SPECIALE (4497cc)",
          "ITALIA COUPE (4499cc)",
          "SPECIALE (2297cc)",
        ],
      },
      {
        model: "488",
        variants: ["PISTA (3902cc)", "SPIDER (3902cc)", "GTB (3902cc)"],
      },
      {
        model: "599",
        variants: ["GTB (5999cc)", "GTB F1 (5999cc)", "GTB FIORANO (5999cc)"],
      },
      {
        model: "612",
        variants: ["SCAGLIETTI (5748cc)"],
      },
      {
        model: "812",
        variants: ["SUPERFAST (6496cc)"],
      },
      {
        model: "F430",
        variants: [
          "F1 COUPE (4308cc)",
          "COUPE (4308cc)",
          "SPIDER (4308cc)",
          "SPIDER (4308cc)",
          "SCUDERIA (4308cc)",
          "F 430 (4308cc)",
        ],
      },
      {
        model: "F355",
        variants: ["COUPE (3496cc)", "F 355 (3496cc)"],
      },
      {
        model: "FF",
        variants: [
          "6.3 V12 (6262cc)",
          "6.3L V12 (6262cc)",
          "GT (6262cc)",
          "GT (6262cc)",
        ],
      },
      {
        model: "ABARTH",
        variants: ["695 (1368cc)", "695 (1368cc)"],
      },
      {
        model: "SF90 STRADALE S-A",
        variants: ["HYBRID ELEC (3990cc)"],
      },
      {
        model: "PORTOFLINO",
        variants: ["PORTOFLINO (3855cc)"],
      },
      {
        model: "F149",
        variants: [
          "4.3 V8 (4297cc)",
          "CALIFORNIA (4297cc)",
          "F1 SPIDER SCUDERIA (4308cc)",
          "F1 COUPE (4308cc)",
          "F1 SPIDER CONVERTIBLE (4308cc)",
        ],
      },
      {
        model: "F151",
        variants: ["CALIFORNIA (4297cc)"],
      },
      {
        model: "F308",
        variants: ["GTB (2926cc)"],
      },
      {
        model: "LA FERRARI",
        variants: ["6.3 V12 (6262cc)"],
      },
      {
        model: "TESTAROSSA",
        variants: ["COUPE (4943cc)"],
      },
      {
        model: "GTC4",
        variants: [
          "LUSSO T (3855cc)",
          "LUSSO (6262cc)",
          "LUSSO T (3900cc)",
          "LUSSO V12 (6262cc)",
        ],
      },
      {
        model: "F12 BERLINETTA",
        variants: ["BERLINETTA (6262cc)", "F12 TDF (6262cc)", "V12 (6262cc)"],
      },
      {
        model: "CALIFORNIA",
        variants: [
          "T (3855cc)",
          "CALIFORNIA CONVERTIBLE (4600cc)",
          "CONVERTIBLE 4 STR (4297cc)",
          "CONVERTIBLE (4297cc)",
          "T (4855cc)",
        ],
      },
      {
        model: "ROMA",
        variants: [
          "V8 COUPE (3855cc)",
          "COUPE V8 (3855cc)",
          "COUPE V8 (3855cc)",
        ],
      },
      {
        model: "F 430.",
        variants: ["SPIDER (4308cc)"],
      },
      {
        model: "SF90",
        variants: ["COUPE V8 (3990cc)"],
      },
      {
        model: "SF 90",
        variants: ["STRADALE (3990cc)"],
      },
      {
        model: "599 GTB",
        variants: ["FIORANO COUPE (5999cc)", "FIORANO (5999cc)"],
      },
      {
        model: "F 355",
        variants: ["Coupe (3496cc)"],
      },
      {
        model: "PORTOFINO",
        variants: [
          "V8 GT (3855cc)",
          "COUPE 4 STR (3855cc)",
          "COUPE (3855cc)",
          "M (3855cc)",
        ],
      },
      {
        model: "612 SCAGLIETTI",
        variants: ["COUPE (4297cc)"],
      },
      {
        model: "FERRARI 360",
        variants: ["0 (3586cc)"],
      },
      {
        model: "348 TS",
        variants: ["0 (3405cc)"],
      },
      {
        model: "FERRARI FF",
        variants: ["0 (6262cc)"],
      },
      {
        model: "F8",
        variants: ["SPIDER (3902cc)"],
      },
      {
        model: "F430 SPIDER",
        variants: ["F430 SPIDER (4308cc)"],
      },
      {
        model: "F 458 ITALIA",
        variants: ["0 (4499cc)", "COUPE (4499cc)"],
      },
      {
        model: "430 I",
        variants: ["430 I (4308cc)"],
      },
      {
        model: "F12",
        variants: ["BERLINETTA (6262cc)"],
      },
      {
        model: "GTC4 LUSSO",
        variants: ["V8 T (3900cc)"],
      },
      {
        model: "F8 TRIBUTO",
        variants: ["BERLINETTA (3902cc)"],
      },
    ],
  },
  {
    make: "LAMBORGHINI",
    models: [
      {
        model: "MURCIELAGO",
        variants: [
          "COUPE (6498cc)",
          "LP640 COUPE (6496cc)",
          "COUPE (4961cc)",
          "LP670-4 SUPERVELOCE (6500cc)",
          "LP670-4 SUPERVELOCE (6500cc)",
          "LP640 (6496cc)",
          "LP640 (6496cc)",
        ],
      },
      {
        model: "URUS",
        variants: [
          "V8 TWIN TURBO (3996cc)",
          "S TWIN TURBO (3999cc)",
          "MY 20 BSVI (3996cc)",
          "AT (3996cc)",
          "V8 (3996cc)",
          "PERFORMANTE TWIN TURBO V8 (3996cc)",
          "TWIN TURBO V8 5 STR (3996cc)",
          "PEARL CAPSULE (3996cc)",
          "PEARL CAPSULE (3996cc)",
          "TWIN-TURBO V8 (3996cc)",
          "TWIN TURBO V8 (3996cc)",
        ],
      },
      {
        model: "GALLARDO",
        variants: [
          "SPYDER (4961cc)",
          "SPYDER (4961cc)",
          "COUPE (4961cc)",
          "COUPE (4961cc)",
          "LP 550-2 SPYDER (5204cc)",
          "LP 560 COUPE (5204cc)",
          "L 550-4 COUPE (5204cc)",
          "MY13 COUPE (5204cc)",
          "INDIA LTD EDITION LP 550 2 (5204cc)",
          "INDIA LTD EDITION LP 550 2 (5204cc)",
          "LP 570 4 EDIZIONE TECNICA (5204cc)",
          "LP 570 4 EDIZIONE TECNICA (5204cc)",
          "LP570 4 S. PERFORMANTE ET (5204cc)",
          "LP570 4 S. PERFORMANTE ET (5204cc)",
          "LP 560 4 COUPE (5204cc)",
          "LP 560 4 COUPE (5204cc)",
          "LP 560 4 (5204cc)",
          "LP 560 4 (5204cc)",
          "LP550 2 (5204cc)",
          "LP550 2 (5204cc)",
          "SPYDER (5204cc)",
          "SPYDER (5204cc)",
          "MY 13 (5198cc)",
          "MY12 (5198cc)",
          "LP570 4 SUPERLEGGERA ET (5240cc)",
          "LP570 4 SUPERLEGGERA ET (5240cc)",
          "LP550 2 SPYDER (5240cc)",
          "LP550 2 SPYDER (5240cc)",
          "SUPERLEGGERA (4961cc)",
          "SUPERLEGGERA (4961cc)",
          "LP 560-4 (4961cc)",
        ],
      },
      {
        model: "AVENTADOR",
        variants: [
          "COUPE (6498cc)",
          "LP 700-4 (6498cc)",
          "LP 700-4 (6498cc)",
          "S ROADSTER AT (6498cc)",
          "LP700 4 BSIV (6498cc)",
          "S COUPE (6498cc)",
          "S (6498cc)",
          "S (6498cc)",
          "LP 700-4 ROADSTER (6498cc)",
          "LP 700-4 ROADSTER (6498cc)",
          "S ROADSTER (6498cc)",
          "S LP 740-4 (6498cc)",
          "SVJ (6498cc)",
        ],
      },
      {
        model: "COUNTACH",
        variants: ["COUPE (6498cc)"],
      },
      {
        model: "HURACAN",
        variants: [
          "LP 610-4 AVIO (5204cc)",
          "LP 610-4 AVIO (5204cc)",
          "LP 580-2 (5204cc)",
          "LP 580 2 (5204cc)",
          "PERFORMANTE (5204cc)",
          "PERFORMANTE (5204cc)",
          "TECNICA V10 (5204cc)",
          "TECNICA V10 (5204cc)",
          "EVO BS-VI (5204cc)",
          "EVO BS-VI (5204cc)",
          "EVO LP 580-2 (5204cc)",
          "EVO LP 580-2 (5204cc)",
          "EVO LP 580-2 SPYDER (5204cc)",
          "EVO LP 580-2 SPYDER (5204cc)",
          "EVO LP 610-4 (5204cc)",
          "EVO LP 610-4 (5204cc)",
          "EVO LP 610-4 AVIO (5204cc)",
          "EVO LP 610-4 AVIO (5204cc)",
          "EVO LP 610-4 SPYDER (5204cc)",
          "EVO LP 610-4 SPYDER (5204cc)",
          "EVO PERFORMANTE (5204cc)",
          "EVO PERFORMANTE (5204cc)",
          "EVO SPYDER BS-VI (5204cc)",
          "EVO SPYDER BS-VI (5204cc)",
          "STO SPECIAL EDITION (5204cc)",
          "STO SPECIAL EDITION (5204cc)",
          "AVIO (5204cc)",
          "RWD SPYDER (5204cc)",
          "EVO RWD (5204cc)",
          "EVO RWD (5204cc)",
          "EVO (5204cc)",
          "EVO (5204cc)",
          "EVO SPYDER AWD (5204cc)",
          "LP 580-2 SPYDER (5204cc)",
          "LP 580-2 SPYDER (5204cc)",
          "EVO SPYDER RWD (5204cc)",
          "EVO SPYDER RWD (5204cc)",
          "EVO SPYDER (5204cc)",
          "EVO SPYDER (5204cc)",
          "LP 610-4 (5204cc)",
          "LP 610 4 (5204cc)",
          "LP 610-4 SPYDER (5204cc)",
          "LP 610 4 SPYDER (5204cc)",
        ],
      },
      {
        model: "URUS PERFORMANTE",
        variants: ["TWIN-TURBO V8 (3996cc)"],
      },
    ],
  },
  {
    make: "JAGUAR",
    models: [
      {
        model: "XK",
        variants: [
          "5.0 V8 SUPERCHARGED COUPE (5000cc)",
          "5.0 V8 SC CONVERTIBLE (5000cc)",
          "5.0 L V8 SUPERCHARGED COUPE (5000cc)",
          "5.0 L V8 SC COVERTIBLE (5000cc)",
          "150 STD (3442cc)",
          "120 (3442cc)",
          "V8 SUPERCHARGED COVERTIBLE (5000cc)",
          "V8 SUPERCHARGED COUPE (5000cc)",
          "V 8 COUPE SPL (5000cc)",
          "RS (5000cc)",
          "CONVERTIBLE SPL (5000cc)",
          "5.0L V8 PETROL COUPE (5000cc)",
          "5.0L V8 PETROL CONVERTIBLE (5000cc)",
          "5.0 LITRE V8 PETROL SUPERCHARGED COUPE (5000cc)",
          "5.0 LITRE V8 PETROL SUPERCHARGED COVERTIBLE (5000cc)",
          "RV8 COUPE (5000cc)",
          "RV8 CONVERTIBLE (5000cc)",
          "V8 CONVERTIBLE (2967cc)",
          "V8 COUPE (2967cc)",
        ],
      },
      {
        model: "XF",
        variants: [
          "2.0 PRESTIGE (1999cc)",
          "2.0L (1999cc)",
          "3.0 L (2993cc)",
          "2.0 PURE (1999cc)",
          "2.0 PORTFOLIO (1999cc)",
          "2.2 LUXURY (2179cc)",
          "3.0 V6 PREMIUM LUXURY (2993cc)",
          "3.0 V6 S (2993cc)",
          "4.2 V8 PREMIUM LUXURY 2009 (4196cc)",
          "5.0 NA V8 (5000cc)",
          "5.0 SUPERCHARGED V8 XFR (5000cc)",
          "5.0 V8 (5000cc)",
          "5.0 V8 R SUPERCHARGED (5000cc)",
          "2.7 D V6 LUXURY (2720cc)",
          "AERO SPORT EDITION (2179cc)",
          "2.0 PETROL PORTFOLIO (1999cc)",
          "2.0 DIESEL PRESTIGE (1999cc)",
          "2.0 DIESEL PURE (1999cc)",
          "2.0 PETROL PRESTIGE (1999cc)",
          "2.0 DIESEL PORTFOLIO (1999cc)",
          "4.2 V8 PREM LUX 2009 (4196cc)",
          "R SUPERCHARGED 5.0LITER V8 (5000cc)",
          "5.0 L SUPERCHARGED V8 - XFR (5000cc)",
          "5.0 LITRE NA V8 PETROL (5000cc)",
          "3.0 V6 DIESEL S (2993cc)",
          "3.0 V6 DIESEL PREMIUM LUXURY (2993cc)",
          "5.0 LITRE V8 PETROL (5000cc)",
          "2.2 DIESEL LUXURY (2179cc)",
          "2.2 (2179cc)",
          "LUXURY (2179cc)",
          "PORTFOLIO (2179cc)",
          "PRESTIGE (2179cc)",
          "PURE (2179cc)",
          "2.2 EXECUTIVE EDITION (2179cc)",
          "2.2 DIESEL EXECUTIVE (2179cc)",
          "2.2 DIESEL AERO SPORT (2179cc)",
          "2.2 DIESEL (2179cc)",
          "2.2 LITRE LUXURY (2179cc)",
          "AERO SPORT (2179cc)",
          "2.2L EXECUTIVE EDITION (2179cc)",
          "5 (5000cc)",
          "SUPERCHARGED V8 (5000cc)",
          "R-SPORT V8 (5000cc)",
          "R SUPERCHARGED 5.0 LITRE V8 PETROL (5000cc)",
          "5.0 XFR PETROL (5000cc)",
          "PETROL V8 (5000cc)",
          "5.0 V8 PORTFOLIO (5000cc)",
          "R 5.0 V8 SUPERCHARGED (5000cc)",
          "5.0 NA (5000cc)",
          "R (5000cc)",
          "V8 (5000cc)",
          "R 5.0 BASE (5000cc)",
          "5.0 LITRE SUPERCHARGED V8 PETROL - XFR (5000cc)",
          "R V8 (5000cc)",
          "2.7 V6 (2720cc)",
          "V6 LUXURY (2720cc)",
          "2.7 TDV6 (2720cc)",
          "LUXURY (1999cc)",
          "PORTFOLIO (1999cc)",
          "PRESTIGE (1999cc)",
          "PURE (1999cc)",
          "2.0L PURE DIESEL (1999cc)",
          "2.0 PORTFOLIO RWD AUTO 177 KW (1999cc)",
          "2.0 PORTFOLIO RWD AUTO 132KW (1999cc)",
          "2.0 PORTFOLIO DIESEL CBU (1999cc)",
          "2.0 PURE DIESEL CKD (1999cc)",
          "2.0 PRESTIGE PETROL CKD (1999cc)",
          "2.0 PORTFOLIO PETROL CKD (1999cc)",
          "2.0 PETROL PORTFOLIO 177 KW (1999cc)",
          "2.0 PETROL PRESTIGE 177 KW (1999cc)",
          "2.0 DIESEL PORTFOLIO 132 KW (1999cc)",
          "2.0 DIESEL PRESTIGE 132 KW (1999cc)",
          "2.0 DIESEL PURE 132 KW132 (1999cc)",
          "2.0 L LUXURY (1999cc)",
          "PORTFOLIO 2.0L DIESEL (1999cc)",
          "PURE DIESEL (1999cc)",
          "PORTFOLIO DIESEL (1999cc)",
          "PORTFOLIO PETROL (1999cc)",
          "PRESTIGE DIESEL (1999cc)",
          "PETROL 2.0 (1999cc)",
          "PRESTIGE PETROL (1999cc)",
          "2.0 PRESTIGE PETROL (1999cc)",
          "2.0 PORTFOLIO PETROL (1999cc)",
          "2.0 PURE DIESEL (1999cc)",
          "2.0 PRESTIGE DIESEL (1999cc)",
          "2.0 PORTFOLIO DIESEL (1999cc)",
          "2.0L PETROL LUXURY (1999cc)",
          "V6 PREMIUM LUXURY (2993cc)",
          "V6 S (2993cc)",
          "V6 (2993cc)",
          "DIESEL (2993cc)",
          "3.0 DIESEL PREMIUM LUX (2993cc)",
          "3.0 PREMIUM LUXURY (2993cc)",
          "3 (2993cc)",
          "3 (2993cc)",
          "3.0 V6 DIESEL S PREMIUM LUXURY (2993cc)",
          "S V6 (2993cc)",
          "3.0 V6 S PREMIUM LUXURY (2993cc)",
          "3.0L DIESEL S (2993cc)",
          "3.0L PREMIUM LUXURY (2993cc)",
          "V8 PREMIUM LUXUXY (4196cc)",
          "3 (3000cc)",
          "2.0 R-DYNAMIC S DIESEL (1997cc)",
          "2.0 R-DYNAMIC S PETROL (1997cc)",
          "PRESTIGE PETROL (1997cc)",
          "5.0 BASE (4999cc)",
        ],
      },
      {
        model: "XJ",
        variants: [
          "2.0 PORTFOLIO (1999cc)",
          "3.0 V6 S (2993cc)",
          "3.0 PORTFOLIO (2993cc)",
          "3.0 PREMIUM LUXURY (2993cc)",
          "5.0 V8 (5000cc)",
          "5.0 V8 SUPERSPORT (5000cc)",
          "L 50 (ANNIVERSARY EDITION) (2993cc)",
          "3.0L LWB ULTIMATE (2993cc)",
          "L 3.0 PORTFOLIO (2993cc)",
          "L 3.0 PORTFOLIO (2993cc)",
          "2.0L PORTFOLIO (1999cc)",
          "3.0L PREMIUM LUXURY (2993cc)",
          "5.0 LITRE V8 PETROL (5000cc)",
          "5.0 LITRE V8 SUPERSPORT PETROL (5000cc)",
          "3.0 LITRE V6 DIESEL S (2993cc)",
          "3.0 L PORTFOLIO (2993cc)",
          "SUPER SPORT (5000cc)",
          "V8 (5000cc)",
          "V8 SUPERSPORT (5000cc)",
          "LWB 5.0 V8 PETROL (5000cc)",
          "L 5.0 PETROL (5000cc)",
          "L 5.0 V8 (5000cc)",
          "L 5.0SUPERSPORT (5000cc)",
          "L 5.0 V8 AUTOBIOGRAPHY (5000cc)",
          "L 5.0 V8 AUTOBIOGRAPHY (5000cc)",
          "5.0 PORTFOLIO (5000cc)",
          "5.0 L (5000cc)",
          "5.0 L (5000cc)",
          "5.0L ULTIMATE EDITION (5000cc)",
          "5.0L V8 SC SUPER SPORT (5000cc)",
          "3 (2993cc)",
          "V6 RSE (2993cc)",
          "V6 PORTFOLIO (2993cc)",
          "V6 S (2993cc)",
          "3.0 DIESEL (2993cc)",
          "3.0 L (2993cc)",
          "3.0 L 50 SPECIAL EDITION (2993cc)",
          "3.0 DIESEL PREMIUM LUXURY CKD (2993cc)",
          "3.0 DIESEL PORTFOLIO CKD (2993cc)",
          "LWB 3.0 V6 PREMIUM LUXURY (2993cc)",
          "LWB 3.0 V6 DIESEL PORTFOLIO (2993cc)",
          "3.0 DIESEL XJ 50 (2993cc)",
          "3.0 LITRE 1X DIESEL V6 275 PORTFOLIO (2993cc)",
          "3.0 LITRE DIESEL V6 PORTFOLIO (2993cc)",
          "3.0 LITRE V6 DIESEL RSE (2993cc)",
          "3.0 L DIESEL PREMIUM LUXURY (2993cc)",
          "L 3.0 V6 (2993cc)",
          "L 3.0 DIESEL (2993cc)",
          "L 3.0 V6 PREMIUM-LUXURY (2993cc)",
          "L 3.0 V6 PORTFOLIO (2993cc)",
          "L 3.0 PREMIUM LUXURY (2993cc)",
          "L 3.0 PREMIUM LUXURY (2993cc)",
          "3.0L PREMIUM LUX (2993cc)",
          "3.0 L LWB PORTFOLIO (2993cc)",
          "L 50 (2993cc)",
          "3.0 L D ULTIMATE (2993cc)",
          "3.0 L 50 (2993cc)",
          "3.0 L PREMIUM LUXURY LWB (2993cc)",
          "3.0 D PORTFOLIO LWB (2993cc)",
          "3.0 L DIESEL S PORTFOLIO (2993cc)",
          "3.0 V6 (2993cc)",
          "4.0 PETROL (3980cc)",
          "LWB 2.0 L PORTFOLIO (1999cc)",
          "2.0 LITRE PETROL LWB (1999cc)",
          "2.0 PETROL PORTFOLIO (1999cc)",
          "L 2.0 PETROL PREMIUM LUXURY (1999cc)",
          "L 2.0 PETROL (1999cc)",
          "L 2.0 PORTFOLIO (1999cc)",
          "L 2.0 PORTFOLIO (1999cc)",
          "2.0 L PETROL PORTFOLIO (1999cc)",
          "LWB 3.0 V6 PETROL (2995cc)",
          "XJ6 4.2 PETROL (4200cc)",
          "L 5.0 PETROL SC ULTIMATE (5998cc)",
          "3.0 V6 (2997cc)",
        ],
      },
      {
        model: "XKR",
        variants: [
          "5.0 V8 SC CONVERTIBLE (5000cc)",
          "5.0 V8 SUPERCHARGED COUPE (5000cc)",
          "5 (5000cc)",
          "V8 SUPERCHARGED CONVERTIBLE (5000cc)",
          "5.0 CONVERTIBLE (5000cc)",
          "5.0L COUPE 3 STR (5000cc)",
          "5.0 COUPE (5000cc)",
          "5.0 V8 SUPERCHARGED CONVERTIBLE (5000cc)",
          "5.0L V8 SUPERCHARGED CONVERTIBLE (5000cc)",
          "XKR CONVERTIBLE 5.0 BASE (5000cc)",
          "4.2 V8 SUPERCHARGED COUPE (4200cc)",
          "4.2 V8 SUPERCHARGED COUPE (4200cc)",
          "5.0 BASE (4999cc)",
        ],
      },
      {
        model: "XE",
        variants: [
          "SE (1997cc)",
          "2.0 PORTFOLIO (1999cc)",
          "2.0 PRESTIGE (1999cc)",
          "2.0 PURE (1999cc)",
          "2.0L PORTFOLIO WITH 5 STR (1999cc)",
          "PORTFOLIO (1999cc)",
          "PURE (1999cc)",
          "PRESTIGE (1999cc)",
          "2.0L DIESEL PORTFOLIO (1999cc)",
          "2.0L DIESEL PRESTIGE (1999cc)",
          "2.0L DIESEL PURE (1999cc)",
          "2.0 PORTFOLIO DIESEL CKD (1999cc)",
          "2.0 PORTFOLIO PETROL (1999cc)",
          "2.0 DIESEL S (1999cc)",
          "2.0 PRESTIGE 177 KW (1999cc)",
          "2.0 DIESEL PRESTIGE (1999cc)",
          "2.0 DIESEL PORTFOLIO (1999cc)",
          "2.0 DIESEL PURE (1999cc)",
          "2.0 PURE 147 KW (1999cc)",
          "2.0 PORTFOLIO 177 KW (1999cc)",
          "S DIESEL (1999cc)",
          "SE DIESEL (1999cc)",
          "2.0 S DIESEL (1999cc)",
          "2.0 SE DIESEL (1999cc)",
          "PRESTIGE DIESEL (1999cc)",
          "PURE DIESEL (1999cc)",
          "PORTFOLIO DIESEL (1999cc)",
          "2.0 PETROL S (1997cc)",
          "2.0 PETROL SE (1997cc)",
          "S PETROL (1997cc)",
          "SE PETROL (1997cc)",
          "2.0 S PETROL (1997cc)",
          "2.0 SE PETROL (1997cc)",
          "PORTFOLIO (1997cc)",
          "PURE (1997cc)",
          "PRESTIGE (1997cc)",
        ],
      },
      {
        model: "F TYPE",
        variants: [
          "V6 S CONVERTIBLE (2995cc)",
          "5.0 L CONVER BSIV (5000cc)",
          "V8 S AT (5000cc)",
          "5.0 V8 405 KW AWD AUTO CONVERTIBLE R CBU (5000cc)",
          "5.0 V8 423 KW AWD AUTO CONVERTIBLE SVR CBU (5000cc)",
          "5.0 AWD SVR (5000cc)",
          "5.0 V8 405 KW AWD AUTO COUPE R CBU (5000cc)",
          "5.0 V8 405 KW RWD AUTO CONVERTIBLE CBU (5000cc)",
          "5.0 V8 405 KW RWD AUTO COUPE CBU (5000cc)",
          "5.0 V8 405 KW AWD AUTO COUPE CBU (5000cc)",
          "5.0 V8 423 KW AWD AUTO COUPE SVR CBU (5000cc)",
          "V8 S AT PETROL (5000cc)",
          "5.0L V8 S/C PETROL CONVERTIBLE S (5000cc)",
          "SVR CONVERTIBLE (5000cc)",
          "SVR COUPE (5000cc)",
          "R CONVERTIBLE (5000cc)",
          "R COUPE (5000cc)",
          "5.0 V8 R COUPE AWD (5000cc)",
          "5.0 V8 R CONVERTIBLE AWD (5000cc)",
          "V8 S (5000cc)",
          "5.0 V8 CONVERTIBLE AWD R (5000cc)",
          "5.0 V8 COUPE AWD R (5000cc)",
          "5.0 V8 CONVERTIBLE R-DYNAMIC (5000cc)",
          "5.0 V8 COUPE R-DYNAMIC (5000cc)",
          "5.0 V8 COUPE FIRST EDITION (5000cc)",
          "V6 S AT (2995cc)",
          "3.0 V6 280 KW RWD AUTO CONVERTIBLE CBU (2995cc)",
          "3.0 V6 250 KW RWD AUTO COUPE CBU (2995cc)",
          "3.0 V6 280 KW RWD AUTO COUPE CBU (2995cc)",
          "3.0 V6 280 KW RWD AUTO COUPE BRITISH DESIGN EDITION CBU (2995cc)",
          "V6 S AT PETROL (2995cc)",
          "COUPE (2995cc)",
          "S CONVERTIBLE (2995cc)",
          "V6 S (2995cc)",
          "S COUPE (2995cc)",
          "2.0 COUPER R DYNAMIC (1997cc)",
          "2.0 L PETROL R DYNAMIC (1997cc)",
          "2.0 COUPE FIRST EDITION (1997cc)",
          "2.0 CONVERTIBLE R DYNAMIC (1997cc)",
          "2.0 COUPE R DYNAMIC (1997cc)",
          "2.0 CONVERTIBLE (1997cc)",
          "2.0 COUPE (1997cc)",
        ],
      },
      {
        model: "F-PACE",
        variants: [
          "3.0 FIRST EDITION AWD (2993cc)",
          "2.0 PRESTIGE AWD (1999cc)",
          "2.0 PRESTIGE AWD (1997cc)",
          "2.0 PURE AWD (1999cc)",
          "3.0 R SPORT AWD (2993cc)",
          "2.0 R DYNAMIC S (1997cc)",
          "2.0 R DYNAMIC S (1999cc)",
          "PRESTIGE 2.0 AWD (1999cc)",
          "FIRST EDITION 3.0 AWD (2993cc)",
          "PURE 2.0 AWD (1999cc)",
          "R SPORT 3.0 AWD (2993cc)",
          "PRESTIGE AWD (1999cc)",
          "2.0 PRESTIGE 132 KW AWD AUTO (1999cc)",
          "2.0 PRESTIGE 132 KW AWD AUTO CKD (1999cc)",
          "2.0 PURE 132 KW AWD AUTO (1999cc)",
          "2.0 PRESTIGE (1999cc)",
          "2.0 PRESTIGE PETROL TURBOCHARGED 182 KW (1999cc)",
          "PETROL PRESTIGE KD (1999cc)",
          "2.0 L DIESEL R-DYNAMIC S (1999cc)",
          "PRESTIGE (1999cc)",
          "PURE (1999cc)",
          "2.0 R DYNAMIC S DIESEL. (1997cc)",
          "2.0 DIESEL R DYNAMIC S (1997cc)",
          "2.0 PETROL R DYNAMIC S (1997cc)",
          "2.0 DIESEL DYNAMIC S (1997cc)",
          "PETROL PRESTIGE (1997cc)",
          "PETROL PRESTIGE (1997cc)",
          "2.0 L PETROL R-DYNAMIC S (1997cc)",
          "2.0 L PETROL R-DYNAMIC S (1997cc)",
          "R DYNAMIC S PETROL (1997cc)",
          "R DYNAMIC S DIESEL (1997cc)",
          "PRESTIGE PETROL (1997cc)",
          "3.0 FIRST EDITION TURBO DIESEL 221 KW AWD AUTO (2993cc)",
          "3.0 R SPORT TURBO DIESEL 221 KW AWD AUTO (2993cc)",
          "FIRST EDITION (2993cc)",
          "R-SPORT (2993cc)",
          "2.0 DIESEL PRESTIGE180 PS (1998cc)",
          "2.0 PETROL R SPORT AUTO 221 KW (2000cc)",
          "5.0 PETROL P550 SVR AWD AT (4999cc)",
          "SVR (4999cc)",
          "5.0 SVR (4999cc)",
          "FIRST EDITION (2933cc)",
          "R-SPORT (2933cc)",
        ],
      },
      {
        model: "XKR-S",
        variants: [
          "5.0 V8 SUPERCHARGED COUPE (5000cc)",
          "5.0 V8 SUPERCHARGED COUPE PETROL (5000cc)",
        ],
      },
      {
        model: "I-PACE",
        variants: [
          "BLACK (1997cc)",
          "SE (1997cc)",
          "HSE (1997cc)",
          "SE BLACK EDITION (294cc)",
          "HSE (294cc)",
          "HSE (294cc)",
          "S (294cc)",
          "SE (294cc)",
          "SE AWD AT 294 KW (1600cc)",
          "HSE AWD AT 294 KW (1600cc)",
          "S AWD AT 294 KW (1600cc)",
          "S (294000cc)",
          "HSE (294000cc)",
          "S (4000cc)",
          "SE (4000cc)",
          "HSE (4000cc)",
          "SE (90000cc)",
        ],
      },
      {
        model: "E TYPE",
        variants: ["V12 COUPE (5343cc)", "V12 (5343cc)", "V12 4 STR (5343cc)"],
      },
      {
        model: "MARK IV",
        variants: ["1.5 L (1776cc)", "1.5 (1776cc)"],
      },
      {
        model: "S TYPE",
        variants: [
          "3.0 V6 (IMPORTED) (3000cc)",
          "3.0 V6 (IMPORTED) (5400cc)",
          "3.0 PETROL (2967cc)",
        ],
      },
      {
        model: "SOVEREIGN",
        variants: ["XJ 2.7 (2720cc)", "XJ (2720cc)", "0 (3996cc)"],
      },
      {
        model: "VINTAGE",
        variants: [
          "JAGUAR 1948 (2500cc)",
          "XF (3442cc)",
          "MK IV (1500cc)",
          "ROADSTER (2500cc)",
          "SALOON (3500cc)",
          "VINTAGE (2179cc)",
          "VINTAGE (2792cc)",
          "VINTAGE (4235cc)",
          "VINTAGE (1540cc)",
        ],
      },
      {
        model: "MK II",
        variants: ["2.5 L (2483cc)"],
      },
      {
        model: "TOURER",
        variants: ["STD (PETROL) (9 SEATER) (2112cc)"],
      },
      {
        model: "X36",
        variants: ["0 (2967cc)"],
      },
      {
        model: "JAGUAR",
        variants: ["XF 4.2 (4196cc)", "0 (2000cc)"],
      },
      {
        model: "MARK X",
        variants: ["4.2 (4235cc)"],
      },
      {
        model: "MAXXIMO",
        variants: ["5.0 BASE (5000cc)"],
      },
      {
        model: "XF SALLON",
        variants: ["4.2 V8 PREM LUX 2009 (4196cc)"],
      },
      {
        model: "DAIMLER",
        variants: ["2.9 (2919cc)"],
      },
    ],
  },
  {
    make: "MAHINDRA RENAULT",
    models: [
      {
        model: "LOGAN EDGE",
        variants: [
          "1.5 DLS (ABS) (1461cc)",
          "1.5 DLS (1461cc)",
          "1.6 GLS ABS (1598cc)",
          "1.6 GLS ABS (1598cc)",
          "1.4 GLX (1390cc)",
          "1.4 GLX (1390cc)",
          "1.6 GLS (1598cc)",
          "1.6 GLS (1598cc)",
          "CONNECT 1.4 GLX (1390cc)",
          "CONNECT 1.4 GLX (1390cc)",
          "1.5 DLS ABS (DLSX) (1461cc)",
          "1.5 DLSX (1461cc)",
          "1.5 DLSX (1461cc)",
          "DLS 1.5 DCI ABS (1461cc)",
          "DLS 1.5 DCI ABS (1461cc)",
          "1.5 DLX CONNECT BS3 (1461cc)",
          "1.5 DLX CONNECT BS3 (1461cc)",
          "CONNECT 1.5 DLX (1461cc)",
          "DLS 1.5 DCI (1461cc)",
          "DLS 1.5 DCI (1461cc)",
          "CONNECT 1.5 DLS (1461cc)",
          "CONNECT 1.5 DLS (1461cc)",
          "CONNECT 1.5 DLS ABS (1461cc)",
          "CONNECT 1.5 DLS ABS (1461cc)",
          "1.6 GLS ABS (GLSX) (1598cc)",
          "1.6 GLSX (1598cc)",
          "1.6 GLSX (1598cc)",
          "CONNECT 1.6 GLS ABS (1598cc)",
          "CONNECT 1.6 GLS ABS (1598cc)",
        ],
      },
      {
        model: "LOGAN",
        variants: [
          "1.5 DLX (1461cc)",
          "1.5 DLX (1461cc)",
          "1.5 DLS (ABS) (1461cc)",
          "K9K PS DLS AIRBAG (1461cc)",
          "K7 (4161cc)",
          "1.5 DLE (1461cc)",
          "1.5 DLS (1461cc)",
          "1.6 DLX (1598cc)",
          "1.4 GL (1390cc)",
          "1.4 GLE (1390cc)",
          "1.4 GLX (1390cc)",
          "1.6 GLS (1598cc)",
          "1.6 GLX (1598cc)",
          "1.6 GLE (1598cc)",
          "K9K PS E2 5 JS (1140cc)",
          "PETROL K7J PS GLX 1.4 5 RT (1390cc)",
          "PETROL K7J PS GLE (1390cc)",
          "PLAY GLX 1.4 (1390cc)",
          "TOURER GLX 1.4 (1390cc)",
          "GLE 1.4 BS-IV (1390cc)",
          "GLE 1.4 BS-IV (1390cc)",
          "GLX 1.4 BS-IV (1390cc)",
          "GLX 1.4 BS-IV (1390cc)",
          "TOURER GLX 1.4 BS-IV (1390cc)",
          "TOURER GLX 1.4 BS-IV (1390cc)",
          "PLAY GLX 1.4 BS-IV (1390cc)",
          "PLAY GLX 1.4 BS-IV (1390cc)",
          "CNG GLE (1390cc)",
          "CNG GLE (1390cc)",
          "CNG GLX (1390cc)",
          "CNG GLX (1390cc)",
          "1.4 GSL (1390cc)",
          "1.4 GSL (1390cc)",
          "K7J PS GLE 5 GB BS4 (1390cc)",
          "PLAY 1.4 GLX (1390cc)",
          "GLE 1.4 (1390cc)",
          "GLE 1.4 (1390cc)",
          "GLX 1.4 (1390cc)",
          "GLX 1.4 (1390cc)",
          "GL 1.4 (1390cc)",
          "GL 1.4 (1390cc)",
          "K9K PS DLS 5WD AIR BAG (1461cc)",
          "1.5 DLS ABS (DLSX) (1461cc)",
          "DISEL K9K PS DLS 5RT AIRBAG (1461cc)",
          "DIESEL K9K PS E2 5 XF AIR BAG (1461cc)",
          "DIESEL K9K PS E2 5 RT AIR BAG (1461cc)",
          "DIESEL K9K PS E2 5 SM AIR BAG (1461cc)",
          "DIESEL K9K PS E2 5 JS AIR BAG (1461cc)",
          "DIESEL K9K PS E0 DLE (1461cc)",
          "PLAY DLX 1.5 (1461cc)",
          "DLE 1.5 DCI BS-IV (1461cc)",
          "DLE 1.5 DCI BS-IV (1461cc)",
          "DLX 1.5 DCI BS-IV (1461cc)",
          "DLX 1.5 DCI BS-IV (1461cc)",
          "DLS 1.5 ABS BS-IV (1461cc)",
          "DLS 1.5 ABS BS-IV (1461cc)",
          "TOURER DLX 1.5 BS-IV (1461cc)",
          "TOURER DLX 1.5 BS-IV (1461cc)",
          "PLAY DLX 1.5 BS-IV (1461cc)",
          "PLAY DLX 1.5 BS-IV (1461cc)",
          "DLE 1.5 DCI (1461cc)",
          "DLE 1.5 DCI (1461cc)",
          "DLS 1.5 DCI (1461cc)",
          "DLS 1.5 DCI (1461cc)",
          "DLSX 1.5 (1461cc)",
          "DLX 1.5 DCI (1461cc)",
          "DLX 1.5 DCI (1461cc)",
          "DLS 1.5 DCI ABS (1461cc)",
          "PLAY 1.5 DLX (1461cc)",
          "DIESEL K9K. (1461cc)",
          "DSL 1.5 E1 (1461cc)",
          "K9K (1461cc)",
          "DSL 1.5 E2 (1461cc)",
          "PETROL K7M PS E2 5 SM AIR BAG (1593cc)",
          "1.6 GLX CNG (1598cc)",
          "1.6 GLE CNG (1598cc)",
          "1.6 GLS ABS (GLSX) (1598cc)",
          "PETROL K7J (1598cc)",
          "PETROL K7M PS E2 5 RT AIR BAG (1598cc)",
          "PETROL K7M PS GLX 1.6 5WD (1598cc)",
          "PETROL K7M PS E2 5 JS AIR BAG (1598cc)",
          "PETROL K7M PS E2 5 XF AIR BAG (1598cc)",
          "1.6 GLSX (1598cc)",
          "GLE (1598cc)",
          "GLX (1598cc)",
          "CNG (1598cc)",
          "CNG (1598cc)",
          "GLS 1.6 (1598cc)",
          "GLS 1.6 (1598cc)",
          "LOGAN GLS 1.6 (1598cc)",
          "GLSX 1.6 (1598cc)",
          "K9K PS DLS (1598cc)",
          "GLX 1.6 (1598cc)",
          "GLX 1.6 (1598cc)",
        ],
      },
      {
        model: "LOGAN 1.6 GLS",
        variants: ["(ABS) (1598cc)", "1.6 GLS (1598cc)"],
      },
      {
        model: "LOGAN TOURER",
        variants: ["1.5 DLX (1461cc)", "1.4 GLX (1390cc)"],
      },
      {
        model: "LOGAN 1.4 GLX",
        variants: ["1.4 GLX (1390cc)", "CONNECT (1390cc)"],
      },
      {
        model: "LOGAN PLAY",
        variants: ["1.4 (1390cc)", "1.5 (1461cc)"],
      },
      {
        model: "LOGAN 1.4 GL",
        variants: ["1.4 GL (1398cc)"],
      },
      {
        model: "LOGAN 1.4 GLE",
        variants: ["1.4 GLE (1398cc)"],
      },
      {
        model: "LOGAN 1.6 GLX",
        variants: ["1.6 GLX (1598cc)"],
      },
      {
        model: "LOGAN 1.6 GLE",
        variants: ["1.6 GLE (1598cc)"],
      },
    ],
  },
  {
    make: "VOLVO",
    models: [
      {
        model: "V40",
        variants: [
          "D3 INSCRIPTION (1969cc)",
          "D3-DESIGN (1984cc)",
          "T4 MOMENTUM (1596cc)",
          "D3 KINETIC (1969cc)",
          "D3 R-DESIGN (1969cc)",
          "2.0 KINETIC D3 (CC 1969) (1969cc)",
          "2.0 R DESIGN D3 ( CC1969) (1969cc)",
          "2.0 CROSS COUNTRY DIESEL 1969CC (1969cc)",
          "D3 2.0L (1969cc)",
          "CROSS COUNTRY D3 INSCRIPTION (1969cc)",
          "CROSS COUNTRY T4 (1969cc)",
          "D3 KINETIC (1984cc)",
          "D3 R-DESIGN (1984cc)",
          "CROSS COUNTRY D3 INSCRIPTION (1984cc)",
          "CROSS COUNTRY T4 MOMENTUM (1596cc)",
          "2.0 CROSS COUNTRY D3 (1984cc)",
          "2.0 KINETIC D3 (1984cc)",
          "2.0 INSCRIPTION D3 (1984cc)",
          "2.0 SUMMUM D3 (1984cc)",
          "2.0 R DESIGN D3 (1984cc)",
          "R DESIGN (1984cc)",
          "CROSS COUNTRY D3 (1984cc)",
          "1.6 CROSS COUNTRY T4 MOMENTUM (1596cc)",
          "T4 (1596cc)",
          "CROSS COUNTRY T4 (1596cc)",
        ],
      },
      {
        model: "S80",
        variants: [
          "KINETIC D4 (1984cc)",
          "D4 KINETIC (2400cc)",
          "D3 KINETIC (2400cc)",
          "D4 SUMMUM (2400cc)",
          "STD (2400cc)",
          "D5 (2400cc)",
          "D5 (2400cc)",
          "D3 SUMMUM (2400cc)",
          "SUMMUM D4 (1984cc)",
          "SUMMUM D3 (1984cc)",
          "KINETIC D3 (1984cc)",
          "3.2 (3192cc)",
          "3.2 (3192cc)",
          "V8 AWD (4414cc)",
          "D3 (1984cc)",
          "MOMENTUM (1984cc)",
          "2.0 MOMENTUM D4 (1984cc)",
          "2.0 KINETIC D4 (1984cc)",
          "2.0 SUMMUM D4 (1984cc)",
          "D3 SUMMUM (1984cc)",
          "T6 SUMMUM (3200cc)",
          "VOLVO D4 SUMMUM (2400cc)",
          "INSCRIPTION (2400cc)",
          "2.4 INSCRIPTION D5 (2400cc)",
          "2.4 SUMMUM D5 (2400cc)",
          "SUMMUM D5 (2400cc)",
          "25 D5 AWD (2400cc)",
          "2.4 PETROL (2435cc)",
          "4.4L V-8 (4414cc)",
          "44L V8 (4414cc)",
          "LIMOUSINE 4D (4414cc)",
          "1.6 T4 (1596cc)",
          "T4 AUTO (1596cc)",
          "2.0  D4 (1969cc)",
          "3.2 AWD (3192cc)",
          "32L I6 (3192cc)",
          "2.5 T (2521cc)",
          "0 (2400cc)",
          "D4 (2400cc)",
        ],
      },
      {
        model: "XC90",
        variants: [
          "INSCRIPTION (1969cc)",
          "D5 MY21 (1969cc)",
          "2.4 SUMMUM D5 (2400cc)",
          "T8 EXCELLENCE (1969cc)",
          "D5 R DESIGN (1969cc)",
          "D5 MOMENTUM (1969cc)",
          "D5 INSCRIPTION (1969cc)",
          "T8 INSCRIPTION (1969cc)",
          "B6 INSCRIPTION (1969cc)",
          "3.2 AWD (3192cc)",
          "V8 AWD (4414cc)",
          "D5 AWD (2400cc)",
          "B6 ULTIMATE HYBRID (1969cc)",
          "2.0L T8 7 STR BS IV (1969cc)",
          "2.0L T8 BS IV (1969cc)",
          "2.0L T8 EXCELLENCE (1969cc)",
          "3 STR (1969cc)",
          "D5 MOMENTUM 5 STR (1969cc)",
          "2.0 D5 MOMENTUM LUXURY DIESEL 5 SEATER (1969cc)",
          "2.0 D4 INSCRIPTION 7 SEATER (1969cc)",
          "2.0 D5 INSCRIPTION 5 SEATER (1969cc)",
          "2.0 D4 MOMENTUM 7 SEATER (1969cc)",
          "2.0 T8 EXCELLENCE (1969cc)",
          "2.0 D5 MOMENTUM 5 SEATER (1969cc)",
          "2.0 T8 AWD (407 HP) (1969cc)",
          "D5 R DESIGN 7 SEAT (1969cc)",
          "2.0 B6 INSCRIPTION 7 SEATER (1969cc)",
          "2.0 T8 INSCRIPTION (1969cc)",
          "2.0 T8 EXCELLENCE LOUNGE 3 SEAT (1969cc)",
          "2.0 D5 INSCRIPTION 7 SEATER (1969cc)",
          "2.0 D5 MOMENTUM LUXURY (1969cc)",
          "D5 (1969cc)",
          "INSCRIPTION LUXURY (1969cc)",
          "MOMENTUM LUXURY (1969cc)",
          "RECHARGE HYBRID (1969cc)",
          "B6 INSCRIPTION HYBRID (1969cc)",
          "EXCELLENCE LOUNGE (1969cc)",
          "RECHARGE (1969cc)",
          "B6 ULTIMATE (1969cc)",
          "D5 7STR (2400cc)",
          "D5 (2400cc)",
          "D5 AWD AT (2400cc)",
          "D5 5STR (2400cc)",
          "D5 AWD 5 SEATER (2400cc)",
          "EXCELLENCE LOUNGE BSIV (3192cc)",
          "32L I6 (3192cc)",
          "R-DESIGN (4414cc)",
          "44L V8 (4414cc)",
          "V8 (4414cc)",
          "V8 (4414cc)",
          "RACE EDITION (4414cc)",
          "MOMENTUM (1969cc)",
          "D5 AWD 7 SEATER (2400cc)",
          "R DESIGN (1969cc)",
          "R-DESIGN. (1969cc)",
          "EXCELLENCE (1969cc)",
        ],
      },
      {
        model: "S60",
        variants: [
          "2.4 D5 AWD (2400cc)",
          "2.0L D4 (1984cc)",
          "INSCRIPTION (2400cc)",
          "T6 (1969cc)",
          "SUMMUM T6 (2953cc)",
          "KINETIC D5 (2400cc)",
          "SUMMUM D5 (2400cc)",
          "D4 MOMENTUM (1969cc)",
          "D5 INSCRIPTION (1969cc)",
          "D4 KINETIC (1969cc)",
          "SUMMUM D4 (1984cc)",
          "POLESTAR (1969cc)",
          "T4 INSCRIPTION (1969cc)",
          "T4 INSCRIPTION. (1969cc)",
          "KINETIC D3 (1984cc)",
          "SUMMUM D3 (1984cc)",
          "D5 (2400cc)",
          "I5 2.4 L DIESEL (2400cc)",
          "D4 CROSS COUNTRY (2400cc)",
          "2.4 CROSS COUNTRY D4 (2400cc)",
          "2.4 INSCRIPTION D5 (2400cc)",
          "INSCRIPTION D4 AWD (2400cc)",
          "D5 2.4 L (2400cc)",
          "T5 (2400cc)",
          "D5 SUMMUM (2400cc)",
          "D5 KINETIC (2400cc)",
          "CROSS COUNTRY INSCRIPTION (2400cc)",
          "D5 INSCRIPTION (2400cc)",
          "2.0 4 DOORS SEDAN WITHOUGHT SUN ROOF (1798cc)",
          "2 OT (1984cc)",
          "D3 (1984cc)",
          "D3 (1984cc)",
          "2.0 D4 (1984cc)",
          "2.0 MOMENTUM D4 (1984cc)",
          "2.0 R DESIGN (1984cc)",
          "2.0 KINETIC D4 (1984cc)",
          "2.0 SUMMUM D4 (1984cc)",
          "KINETIC D4 (1984cc)",
          "D3 CROSS COUNTRY (1984cc)",
          "MOMENTUM (1984cc)",
          "D4 (1984cc)",
          "D4 (1984cc)",
          "2.0 D4 MOMENTUM (1984cc)",
          "D4 MOMENTUM (1984cc)",
          "D4 KINETIC (1984cc)",
          "D4 R-DESIGN (1969cc)",
          "D5 AWD AT (1969cc)",
          "D4 SUMMUM (1969cc)",
          "2.0 T4 ISNCRIPTION (1969cc)",
          "2.0 T6 SUMMUM (CC 1969) (1969cc)",
          "2.0 D4 R DESIGN (CC 1969) (1969cc)",
          "2.0 D4 MOMENTUM (CC 1969) (1969cc)",
          "2.0 D4 KINETIC (CC 1969) (1969cc)",
          "T4 (1969cc)",
          "MOMENTUM (1969cc)",
          "KINETIC (1969cc)",
          "R-DESIGN (1969cc)",
          "D4 2.0L (1969cc)",
          "D4 (1969cc)",
          "T6 INSCRIPTION (2000cc)",
          "I5 2.0L DIESEL (2000cc)",
          "2.0 T6 (2000cc)",
          "2.0 T6 INSCRIPTION (2000cc)",
          "T6 (2000cc)",
          "T6 PETROL (2000cc)",
          "SEDAN (1990cc)",
          "T6 (2953cc)",
          "T6 SUMMUM (2953cc)",
          "CROSS COUNTRY (2400cc)",
          "2.4 D5 AWD  DIESEL (2400cc)",
          "T6 (1984cc)",
          "R DESIGN (1984cc)",
        ],
      },
      {
        model: "XC60",
        variants: [
          "2.5 D5 AT (2400cc)",
          "2.0 D3 (AT) (1984cc)",
          "D4 INSCRIPTION (1969cc)",
          "D4 MOMENTUM (1969cc)",
          "D5 SUMMUM (2400cc)",
          "D4 SUMMUM (1984cc)",
          "2.0 D4 (AT) (1964cc)",
          "D4 (1984cc)",
          "D4 (1984cc)",
          "D4 KINETIC. (1984cc)",
          "KINETIC D3 (1984cc)",
          "D3 (2400cc)",
          "D5 INSCRIPTION (2400cc)",
          "D5 INSCRIPTION. (1969cc)",
          "B5 INSCRIPTION (1969cc)",
          "SUMMUM D3 (1984cc)",
          "D5 AWD AT (2400cc)",
          "B5 ULTIMATE (1969cc)",
          "D5 (1969cc)",
          "2.0 D5 INSCRIPTION 7 STR (1969cc)",
          "2.0 D5 INSCRIPTION (CC 1969) (1969cc)",
          "INSCRIPTION (1969cc)",
          "MOMENTUM. (1969cc)",
          "MOMENTUM (1969cc)",
          "D4 (1969cc)",
          "MOMENTUM D4 (1969cc)",
          "D4 R-DESIGN (1969cc)",
          "2.0 PETROL B5 INSCRIPTION (CC 1969) (1969cc)",
          "B5 INSCRIPTION HYBRID (1969cc)",
          "D4 S (2400cc)",
          "2.4 D5 INSCRIPTION (2400cc)",
          "D4 K (2400cc)",
          "INS (2400cc)",
          "2.4 D5 DIESEL AUTO TRANSMISSION (2400cc)",
          "2.4 INSCRIPTION D5 (2400cc)",
          "2.4 SUMMUM D5 (2400cc)",
          "INSCRIPTION (2400cc)",
          "D5 (2400cc)",
          "2.5 D5 DIESEL AUTO TRANSMISSION (2400cc)",
          "SUMMUM D5 (2400cc)",
          "SUMMUM D5 (2400cc)",
          "I5 2.0 L DIESEL (1984cc)",
          "2.0 KINETIC D4 (1984cc)",
          "2.0 SUMMUM D4 (1984cc)",
          "KINETIC (1984cc)",
          "MOMENTUM (1984cc)",
          "2.0 KINETIC D3 (1984cc)",
          "KINETIC D4 (1984cc)",
          "SUMMUM D4 (1984cc)",
          "D3 (1984cc)",
          "D3 (1984cc)",
          "2.0 R DESIGN (1968cc)",
          "2.0 MOMENTUM D4 (1968cc)",
          "2.5 D5 DIESEL AT (2400cc)",
          "2.0 D3 DIESEL AT (163 HP) (1948cc)",
          "2.0 D3 AT (1948cc)",
        ],
      },
      {
        model: "V90",
        variants: [
          "D5 INSCRIPTION (1968cc)",
          "D5 INSCRIPTION (1969cc)",
          "CROSS COUNTRY (1968cc)",
          "2.0 CROSS COUNTRY D5 (1968cc)",
          "CROSS COUNTRY D5 INSCRIPTION (1968cc)",
          "CROSS COUNTRY D5 AWD (1969cc)",
          "CROSS COUNTRY D5 INSCRIPTION (1969cc)",
        ],
      },
      {
        model: "S90",
        variants: [
          "B5 ULTIMATE (1969cc)",
          "D4 MOMENTUM (1969cc)",
          "INSCRIPTION AUTOMATIC (1969cc)",
          "D4 INSCRIPTION (1969cc)",
          "2.0 PETROL INSCRIPTION B5 (1969cc)",
          "2.0 MOMENTUM  D4 (1969cc)",
          "2.0 INSCRIPTION D4 (1969cc)",
          "B5 INSCRIPTION (BS6) (1969cc)",
          "D5 INSCRIPTION (1969cc)",
          "INSCRIPTION D4 (1969cc)",
          "B5 INSCRIPTION (1969cc)",
          "INSCRIPTION LUXURY (1969cc)",
          "MOMENTUM D4 (1969cc)",
        ],
      },
      {
        model: "XC40",
        variants: [
          "D4 INSCRIPTION (1969cc)",
          "RECHARGE (1969cc)",
          "B4 ULTIMATE (1969cc)",
          "D4 MOMENTUM (1969cc)",
          "T4 R-DESIGN (1969cc)",
          "D4 R-DESIGN (1969cc)",
          "D4 R DESIGN (1969cc)",
          "T4 R DESIGN B6S (1969cc)",
          "B4 (1969cc)",
          "2.0 D4 R DESIGN (1969cc)",
          "2.0 D4 190 4WD MOMENTUM AT (1969cc)",
          "2.0 T4 190 R-DESIGN AT (1969cc)",
          "2.0 D4 INSCRIPTION (1969cc)",
          "INSCRIPTION (1969cc)",
          "MOMENTUM (1969cc)",
          "D4 INSCRITION (1969cc)",
          "RECHARGE P8 AWD (1969cc)",
          "EV (78000cc)",
          "RECHARGE P8 AWD (78000cc)",
          "RECHARGE (300cc)",
          "RECHARGE P8 AWD (300cc)",
          "RECHARGE P8 AWD (78cc)",
        ],
      },
      {
        model: "VINTAGE",
        variants: ["VINTAGE (1780cc)", "-1968 (993cc)"],
      },
      {
        model: "V60",
        variants: [
          "D4 KINETIC (1969cc)",
          "D4 MOMENTUM (1969cc)",
          "D5 INSCRIPTION (1969cc)",
          "POLESTAR (1969cc)",
          "T6 (1969cc)",
        ],
      },
      {
        model: "XC70",
        variants: ["2.5T AWD (2500cc)"],
      },
      {
        model: "C40",
        variants: ["RECHARGE E80 (78000cc)"],
      },
      {
        model: "C70",
        variants: [
          "T5 (2319cc)",
          "2.5 (2520cc)",
          "2.5 T5 CONV (2500cc)",
          "CONV T5 (2521cc)",
        ],
      },
      {
        model: "XC91",
        variants: ["3.2 AWD (3192cc)"],
      },
      {
        model: "V50",
        variants: ["T5 AWD (2521cc)"],
      },
      {
        model: "XC92",
        variants: ["V8 AWD (4414cc)"],
      },
      {
        model: "CAMPER VAN",
        variants: ["9400 B9R 6X2 M3 BS 3 (9365cc)"],
      },
      {
        model: "VOLVO AB",
        variants: ["0 (2922cc)"],
      },
      {
        model: "V70",
        variants: ["0 (2435cc)", "2.0T (1984cc)"],
      },
      {
        model: "9400 B8R",
        variants: ["0 (7700cc)"],
      },
    ],
  },
  {
    make: "ROLLS ROYCE",
    models: [
      {
        model: "1938",
        variants: ["STANDARD (2900cc)"],
      },
      {
        model: "DAWN",
        variants: [
          "ZX82 RHD (6592cc)",
          "6.6 CONVERTIBLE XZ 82 RHD (6598cc)",
          "CONVERTIBLE (6598cc)",
          "CONVERTIBLE (6598cc)",
        ],
      },
      {
        model: "GHOST",
        variants: [
          "V12 (6750cc)",
          "V12 EXTENDED (6750cc)",
          "6.6 FK 42 RHD EWB (6592cc)",
          "6.6 FK 42 RHD (6592cc)",
          "6.6 V12 TWIN TURBO (6592cc)",
          "SERIES II STD (6592cc)",
          "SERIES II EXTENDED WL BASE (6592cc)",
          "SERIES II LHD (6600cc)",
          "EXTENDED RHD (6750cc)",
          "6.6 L FK 42 RHD EWB (6592cc)",
          "6.6 L V12 TWIN TURBO (6592cc)",
          "6.6 L FK 42 RHD (6592cc)",
          "SERIES II 6.6 (6592cc)",
          "SERIES II EWB (6592cc)",
          "6.5 (6592cc)",
          "6.6 EWB XZ 42 RHD (6592cc)",
          "EXTENDED WHEELBASE AT (6592cc)",
          "6.6 EXTENDED RHD (6592cc)",
          "6.6 L FK 42 RHD SWB (6592cc)",
          "6.6 L EWB XZ 42 RHD (6592cc)",
          "EXTENDED WHEELBASE (6592cc)",
          "SERIES II SWB (6592cc)",
          "FK42 RHD (6592cc)",
          "6.6L V12 (6592cc)",
          "6.6L V12 (6592cc)",
          "5.4 SALOON (5350cc)",
        ],
      },
      {
        model: "WRAITH",
        variants: [
          "COUPE (6562cc)",
          "6.6 V12 (6592cc)",
          "6.6 L V12 (6592cc)",
          "COUPE (6592cc)",
          "BASE (6592cc)",
        ],
      },
      {
        model: "CULLINAN",
        variants: [
          "V12 (6750cc)",
          "SUV 4 STR (6749cc)",
          "SUV (6749cc)",
          "RHD (6592cc)",
          "6.7 V12 PETROL (6751cc)",
        ],
      },
      {
        model: "PHANTOM",
        variants: [
          "DROPHEAD COUPE (6749cc)",
          "EWB FK 02 RHD (6749cc)",
          "FK 62 RL (6749cc)",
          "SWB FJ 62 (6749cc)",
          "EWB FJ 02 RHD (6749cc)",
          "COUPE 6.8 L (6749cc)",
          "SERIES II (6749cc)",
          "VIII SEDAN (6749cc)",
          "COUPE BASE (6749cc)",
          "SWB (6749cc)",
          "DROPHEAD COUPE CONVERTIBLE (6749cc)",
          "DROPHEAD COUPE MY 11 (6749cc)",
          "SEDAN (6749cc)",
          "SEDAN (6749cc)",
          "EWB (6749cc)",
          "6.8 EXTENDED WHEEL BASE 5 SEAT (6750cc)",
          "6.0 COUPE 4 SEATER (6000cc)",
          "DROPHEAD COUPE MY 11 (6752cc)",
        ],
      },
      {
        model: "SILVER",
        variants: ["SPORTS (2000cc)", "SPUR (5750cc)", "SERAPH 5.4 (5379cc)"],
      },
      {
        model: "VINTAGE",
        variants: [
          "ROLLS ROYAL (1501cc)",
          "PHANTOM II (7700cc)",
          "PHANTOM (6592cc)",
          "20/25 TOURER (3588cc)",
          "TOURER (7428cc)",
          "SILVER CLOUD 3 (6230cc)",
          "20/25 HOOPER LIMOUSINE (3699cc)",
          "V 2 (7668cc)",
          "SALOON (4600cc)",
          "COUPE (3669cc)",
          "PHANTOM 11 CABRIOLET (7668cc)",
          "VINTAGE (6230cc)",
          "VINTAGE (6290cc)",
          "VINTAGE (4887cc)",
          "VINTAGE (3699cc)",
          "VINTAGE (3127cc)",
          "VINTAGE (3500cc)",
          "VINTAGE (2199cc)",
          "PHANTOM III (7340cc)",
          "V 1 (7700cc)",
        ],
      },
      {
        model: "SPUR",
        variants: ["III (3000cc)"],
      },
      {
        model: "DROPHEAD COUPE",
        variants: ["CONVERTIBLE (6749cc)"],
      },
    ],
  },
  {
    make: "HUMMER",
    models: [
      {
        model: "H2 SUV",
        variants: [
          "6.2 7STR (6200cc)",
          "4WD 4DOOR (6162cc)",
          "4WD 4 DOOR (6000cc)",
          "6.2 PETROL 7 SEATER (6200cc)",
          "4WD (6000cc)",
          "6.0 DIESELL 4WD 7 SEATER (6000cc)",
          "6.2 (6200cc)",
        ],
      },
      {
        model: "H3 SUV",
        variants: [
          "RHD 2007 (3653cc)",
          "3.7 PETROL 8 SEATER (3653cc)",
          "LUXURY 3.7 AUTO 5 SEATER (3653cc)",
          "LUXURY (3653cc)",
        ],
      },
      {
        model: "VINTAGE",
        variants: ["H3 LUX (3700cc)"],
      },
      {
        model: "H2",
        variants: [
          "STD (3653cc)",
          "7 STR (6000cc)",
          "H2 (6199cc)",
          "SUV (6199cc)",
          "SUV (3700cc)",
        ],
      },
      {
        model: "H3",
        variants: [
          "STD (3653cc)",
          "BASE AT (3700cc)",
          "AT (3700cc)",
          "SUV (3700cc)",
          "H3 (3700cc)",
        ],
      },
      {
        model: "H1",
        variants: ["STD (6500cc)"],
      },
      {
        model: "H1 SUV",
        variants: ["BASE (6000cc)"],
      },
      {
        model: "HUMMER H2",
        variants: ["0 (6000cc)"],
      },
      {
        model: "HUMMER H3",
        variants: ["0 (3700cc)", "0 (3520cc)"],
      },
      {
        model: "HUMMER H3.",
        variants: ["0 (3653cc)"],
      },
    ],
  },
  {
    make: "KIA",
    models: [
      {
        model: "SELTOS",
        variants: [
          "1.4 GTX PLUS 6 MT BSVI (1353cc)",
          "1.5 HTK PLUS 6 MT BSVI (1493cc)",
          "1.5 HTK PLUSÂ 6 MT BSVI (1497cc)",
          "1.5 HTE 6 MT BSVI (1497cc)",
          "1.5 HTE 6 MT BSVI (1493cc)",
          "1.5 HTK 6 MT BSVI (1497cc)",
          "1.5 HTK 6 MT BSVI (1493cc)",
          "1.5 HTX 6 MT BSVI (1497cc)",
          "1.5 HTX 6 MT BSVI (1493cc)",
          "1.5 HTX CVT BSVI (1497cc)",
          "1.4 GTK 6 MT BSVI (1353cc)",
          "1.4 GTX 6 MT BSVI (1353cc)",
          "1.4 GTX DCT BSVI (1353cc)",
          "1.5 HTK PLUSÂ  6 AT BSVI (1493cc)",
          "1.5 HTX PLUSÂ  6 MT BSVI (1493cc)",
          "1.5 HTX PLUS 6 AT BSVI (1493cc)",
          "1.4 GTX PLUS DCT BSVI (1353cc)",
          "1.5 GTX PLUS 6 AT BSVI (1493cc)",
          "1.5 HTX 6 MT DUAL TONE BSVI (1497cc)",
          "1.5 HTX 6 MT DUAL TONE BSVI (1493cc)",
          "1.4 GTK 6 MT DUAL TONE BSVI (1353cc)",
          "1.5 HTX CVT DUAL TONE BSVI (1497cc)",
          "1.4 GTX 6 MT DUAL TONE BSVI (1353cc)",
          "1.4 GTX DCT DUAL TONE BSVI (1353cc)",
          "1.5 GTX PLUS AT BSVI (1493cc)",
          "1.5 HTX IVT BSVI (1497cc)",
          "1.5 HTX 6MT(AE) (1493cc)",
          "1.5 HTX IVT BSVI (AE) (1497cc)",
          "1.5D X LINE 6 AT BSVI (1493cc)",
          "1.5 HTK PLUS IMT BSVI (1497cc)",
          "1.4 T X LINE DCT BSVI (1353cc)",
          "1.5 HTX 6MT (DT) BSVI (1493cc)",
          "1.5 HTX IVT AT BSVI (1497cc)",
          "1.5 CRDI VGT 6MT HTX BSVI (AE) (1493cc)",
          "1.5 IMT HTK BSVI (1493cc)",
          "1.5 IMT HTX BSVI (1493cc)",
          "1.5 IMT HTX PLUS BSVI (1493cc)",
          "1.5 IMT HTE BSVI (1493cc)",
          "1.5 IMT HTK PLUS BSVI (1493cc)",
          "1.5 HTX 6AT BSVI (1493cc)",
          "1.5 HTK PLUS TURBO IMT BSVI (1482cc)",
          "1.5 HTX PLUS TURBO IMT BSVI (1482cc)",
          "1.5 HTX PLUS TURBO DCT BSVI (1482cc)",
          "1.5 GTX PLUS TURBO DCT BSVI (1482cc)",
          "1.5 X LINE TURBO DCT BSVI (1482cc)",
          "X-LINE DCT (998cc)",
          "1.0 HTX 7DCT PETROL (999cc)",
          "1.0 GTX PLUS 7DCT PETROL DUAL TONE (999cc)",
          "1.0 GTX PLUS 7DCT PETROL (999cc)",
          "1.0 GTX PLUS IMT PETROL DUAL TONE (999cc)",
          "1.0 HTK PLUS IMT PETROL (999cc)",
          "1.0 HTX PLUS IMT PETROL (999cc)",
          "1.0 HTX IMT PETROL (999cc)",
          "1.0 GTX PLUS IMT PETROL (999cc)",
          "1.0 HTX PLUS IMT PETROL DUAL TONE (999cc)",
          "1.4 GTX PLUS 6 MT (1353cc)",
          "1.4 GTK 6 MT (1353cc)",
          "1.4 GTX 6 MT (1353cc)",
          "1.4 GTX DCT (1353cc)",
          "1.4 GTX PLUS 6 AT (1353cc)",
          "GTX PLUS DCT 1.4 BS6 (1353cc)",
          "GTX 1.4 O BS6 (1353cc)",
          "1.4 GTX PLUS 7DCT SMARTSTREAM (1353cc)",
          "1.4 GTX (O) 6 MT PETROL (1353cc)",
          "1.4 GTX PLUS 7 DCT (1353cc)",
          "1.4 GTX PLUS AT (1353cc)",
          "1.4 GTX DCT SMARTSTREAM (1353cc)",
          "1.4 GTX PLUS 6 MT PETROL (1353cc)",
          "1.4 GTX 6 MT PETROL (1353cc)",
          "1.4 GTX DCT PETROL (1353cc)",
          "1.4 GTK 6 MT PETROL (1353cc)",
          "1.4 GDI 7DCT SMARTSTREAM X LINE (1353cc)",
          "1.4 GTX PLUS GDI 7DCT SMARTSTREAM (1353cc)",
          "GTX PLUS 1.4 FACELIFT (1353cc)",
          "GTX PLUS 1.4 FACELIFT (1353cc)",
          "GTX PLUS DCT 1.4 FACELIFT (1353cc)",
          "GTX PLUS DCT 1.4 FACELIFT (1353cc)",
          "GTX 1.4 DUAL TONE (1353cc)",
          "GTX 1.4 DUAL TONE (1353cc)",
          "GTX PLUS 1.4 DUAL TONE (1353cc)",
          "GTX PLUS 1.4 DUAL TONE (1353cc)",
          "GTX (O) 1.4 (1353cc)",
          "GTX (O) 1.4 (1353cc)",
          "X LINE 1.4 TORBO DCT (1353cc)",
          "1.4 PETROL 7DCT XLINE (1353cc)",
          "X LINE DCT 1.4T PETROL (1353cc)",
          "G1.4 7 DCT GTX PLUS (1353cc)",
          "SMARTSTREAM G1.4 T-GDI 7DCT GTS PLUS (1353cc)",
          "G1.4 6MT GTX PLUS (1353cc)",
          "GTX PLUS DCT 1.4 (1353cc)",
          "GTX PLUS DCT 1.4 (1353cc)",
          "GTX AT 1.4. (1353cc)",
          "GTX AT 1.4 (1353cc)",
          "GTK 1.4 (1353cc)",
          "GTK 1.4 (1353cc)",
          "GTX 1.4 (1353cc)",
          "GTX 1.4 (1353cc)",
          "GTX PLUS DCT 1.4 DUAL TONE (1353cc)",
          "GTX PLUS DCT 1.4 DUAL TONE (1353cc)",
          "X LINE 1.4 DCT (1353cc)",
          "X LINE 1.4 DCT (1353cc)",
          "GTK 1.4 DUAL TONE. (1353cc)",
          "GTK 1.4 DUAL TONE (1353cc)",
          "GTX PLUS AT 1.4. (1353cc)",
          "GTX PLUS AT 1.4 (1353cc)",
          "GTX AT 1.4 DUAL TONE (1353cc)",
          "GTX AT 1.4 DUAL TONE (1353cc)",
          "GTX PLUS 1.4 (1353cc)",
          "GTX PLUS 1.4 (1353cc)",
          "1.4 GTX DUAL TONE (P) (1353cc)",
          "1.4 GTX PLUS AT (P) (1353cc)",
          "1.4 GTX AT (P) (1353cc)",
          "1.4 GTX PLUS (P) (1353cc)",
          "1.4 GTX AT DUAL TONE (P) (1353cc)",
          "1.4 GTK DUAL TONE (P) (1353cc)",
          "1.4 GTK (P) (1353cc)",
          "1.4 GTX (P) (1353cc)",
          "1.4 GTX PLUS DCT DUAL TONE (P) (1353cc)",
          "1.4 GTX PLUS DCT (P) (1353cc)",
          "1.4 GTX (O) (P) (1353cc)",
          "1.4 X LINE DCT (P) (1353cc)",
          "1.5 HTX PLUS IMT (1482cc)",
          "1.5 HTX PLUS DCT (1482cc)",
          "1.5 X-LINE DCT (1482cc)",
          "1.5 HTK PLUS IMT (1482cc)",
          "X-LINE TURBO DCT (1482cc)",
          "HTX PLUS TURBO DCT (1482cc)",
          "HTK PLUS TURBO IMT (1482cc)",
          "1.5 GTX PLUS DCT (1482cc)",
          "GTX PLUS TURBO DCT (1482cc)",
          "HTX PLUS TURBO IMT (1482cc)",
          "XLINE DCT (1482cc)",
          "1.5 HTK PLUS 6 MT (1493cc)",
          "1.5 HTE 6 MT (1493cc)",
          "1.5 HTK 6 MT (1493cc)",
          "1.5 HTX 6 MT (1493cc)",
          "1.5 HTK PLUSÂ  6 AT (1493cc)",
          "1.5 HTX PLUSÂ 6 MT (1493cc)",
          "1.5 HTX PLUS 6 AT (1493cc)",
          "1.5 GTX PLUS 6 AT (1493cc)",
          "1.5 GTX PLUS AT (1493cc)",
          "D1.5 6MT HTX PLUS (1493cc)",
          "HTE 1.5 CRDI IMT (1493cc)",
          "1.5 CRDI VGT 6AT X LINE (1493cc)",
          "1.5 HTX PLUS 6 MT DIESEL (1493cc)",
          "1.5 HTX PLUS 6 AT DIESEL (1493cc)",
          "1.5 HTX 6 MT DIESEL (1493cc)",
          "1.5 HTK PLUS 6AT DIESEL (1493cc)",
          "1.5 HTK PLUS 6 MT DIESEL (1493cc)",
          "1.5 HTK 6 MT DIESEL (1493cc)",
          "1.5 HTE 6 MT DIESEL (1493cc)",
          "1.5 HTX ANNIVERSARY EDITION 6MT DIESEL (1493cc)",
          "1.5 GTX PLUS 6AT DIESEL (1493cc)",
          "1.5 IMT HTK DIESEL (1493cc)",
          "1.5 IMT HTK PLUS DIESEL (1493cc)",
          "1.5 HTX 6 AT DIESEL (1493cc)",
          "1.5 GTX PLUS DCT (1493cc)",
          "HTK 1.5 DIESEL FACELIFT (1493cc)",
          "HTK 1.5 DIESEL FACELIFT (1493cc)",
          "HTK PLUS 1.5 DIESEL FACELIFT (1493cc)",
          "HTK PLUS 1.5 DIESEL FACELIFT (1493cc)",
          "HTK PLUS AT 1.5 DIESEL FACELIFT (1493cc)",
          "HTK PLUS AT 1.5 DIESEL FACELIFT (1493cc)",
          "HTX 1.5 DIESEL FACELIFT (1493cc)",
          "HTX 1.5 DIESEL FACELIFT (1493cc)",
          "HTX PLUS 1.5 DIESEL FACELIFT (1493cc)",
          "HTX PLUS 1.5 DIESEL FACELIFT (1493cc)",
          "GTX PLUS AT 1.5 DIESEL FACELIFT (1493cc)",
          "GTX PLUS AT 1.5 DIESEL FACELIFT (1493cc)",
          "HTE 1.5 DIESEL FACELIFT (1493cc)",
          "HTE 1.5 DIESEL FACELIFT (1493cc)",
          "GTX PLUS AT 1.5 DSL (1493cc)",
          "D 1.5 CRDI VGT 6AT X LINE (1493cc)",
          "D 1.5 CRDI VGT 6MT HTK PLUS GLACIER (1493cc)",
          "1.5 HTX PLUS MT (1493cc)",
          "D1.5 CRDI 6MT HTX (1493cc)",
          "1.5 6MT HTX PLUS (1493cc)",
          "X LINE 1.5 AT D (1493cc)",
          "D 1.5 6AT GTX PLUS (1493cc)",
          "D1 5 6AT HTK PLUS (1493cc)",
          "D1.5 6MT HTK PLUS (1493cc)",
          "D 1.5 6MT THE (1493cc)",
          "D1.5 6MT HTK (1493cc)",
          "HTE 1.5 DIESEL IMT (1493cc)",
          "HTK PLUS 1.5 DIESEL IMT (1493cc)",
          "HTK PLUS 1.5 DIESEL IMT (1493cc)",
          "HTX PLUS 1.5 DIESEL IMT DUAL TONE (1493cc)",
          "HTX PLUS AT 1.5 DIESEL FACELIFT (1493cc)",
          "HTX PLUS AT 1.5 DIESEL FACELIFT (1493cc)",
          "HTX PLUS 1.5 DCT (1493cc)",
          "D1.5 6MT HTX AE (1493cc)",
          "GTX PLUS AT 1.5 DIESEL DUAL TONE (1493cc)",
          "GTX PLUS AT 1.5 DIESEL DUAL TONE (1493cc)",
          "HTX PLUS 1.5 DIESEL DUAL TONE (1493cc)",
          "HTX PLUS 1.5 DIESEL DUAL TONE (1493cc)",
          "HTX PLUS AT 1.5 DIESEL DUAL TONE (1493cc)",
          "HTX PLUS AT 1.5 DIESEL DUAL TONE (1493cc)",
          "ANNIVERSARY EDITION 1.5 DIESEL (1493cc)",
          "ANNIVERSARY EDITION 1.5 DIESEL (1493cc)",
          "X LINE 1.5 DIESEL AT (1493cc)",
          "X LINE 1.5 DIESEL AT (1493cc)",
          "HTX 1.5 DIESEL DUAL TONE. (1493cc)",
          "HTX 1.5 DIESEL DUAL TONE (1493cc)",
          "HTX 1.5 DIESEL AT (1493cc)",
          "HTX 1.5 DIESEL AT (1493cc)",
          "HTK 1.5 DIESEL IMT (1493cc)",
          "HTX 1.5 DIESEL IMT (1493cc)",
          "HTX PLUS 1.5 DIESEL IMT (1493cc)",
          "HTK PLUS 1.5 IMT (1493cc)",
          "GTX AT 1.4 DUAL TONE (1493cc)",
          "HTE 1.5 DIESEL (1493cc)",
          "HTE 1.5 DIESEL (1493cc)",
          "HTK 1.5 DIESEL (1493cc)",
          "HTK 1.5 DIESEL (1493cc)",
          "HTK PLUS 1.5 DIESEL (1493cc)",
          "HTK PLUS 1.5 DIESEL (1493cc)",
          "HTK PLUS AT 1.5 DIESEL (1493cc)",
          "HTK PLUS AT 1.5 DIESEL (1493cc)",
          "HTX 1.5 DIESEL (1493cc)",
          "HTX 1.5 DIESEL (1493cc)",
          "HTX PLUS 1.5 DIESEL (1493cc)",
          "HTX PLUS 1.5 DIESEL (1493cc)",
          "HTX PLUS AT 1.5 DIESEL (1493cc)",
          "HTX PLUS AT 1.5 DIESEL (1493cc)",
          "GTX PLUS AT 1.5 DIESEL (1493cc)",
          "GTX PLUS AT 1.5 DIESEL (1493cc)",
          "1.5 HTE (D) (1493cc)",
          "1.5 GTX PLUS AT (D) (1493cc)",
          "1.5 HTX DUAL TONE (D) (1493cc)",
          "1.5 HTX PLUS AT (D) (1493cc)",
          "1.5 HTX PLUS (D) (1493cc)",
          "1.5 HTK (D) (1493cc)",
          "1.5 HTK PLUS AT (D) (1493cc)",
          "1.5 HTK PLUS (D) (1493cc)",
          "1.5 HTX (D) (1493cc)",
          "1.5 ANNIVERSARY EDITION (D) (1493cc)",
          "1.5 X LINE AT (D) (1493cc)",
          "1.5 HTX AT (D) (1493cc)",
          "1.5 HTK PLUS IMT (D) (1493cc)",
          "1.5 HTK PLUSÂ 6 MT (1497cc)",
          "1.5 HTE 6 MT (1497cc)",
          "1.5 HTK 6 MT (1497cc)",
          "1.5 HTX 6 MT (1497cc)",
          "1.5 HTX CVT (1497cc)",
          "1.5 HTX IVT (1497cc)",
          "G 1.5 HTE MPI (1497cc)",
          "G1.5 6MT HTX (1497cc)",
          "G1 5 6MT HTX (1497cc)",
          "G1.5 6MT HTX AE (1497cc)",
          "G1.5 IVT HTX AE (1497cc)",
          "HTK PLUS IMT 1.5 PETROL BS6 (1497cc)",
          "HTK PLUS 1.5 PETROL (1497cc)",
          "HTX CVT 1.5 PETROL (1497cc)",
          "1.5 HTX IVT SMARTSTREAM (1497cc)",
          "1.5 HTK PLUS IMT SMARTSTREAM (1497cc)",
          "1.5 HTX CVT G PETROL (1497cc)",
          "1.5 HTX CVT PETROL (1497cc)",
          "1.5 HTX 6 MT PETROL (1497cc)",
          "1.5 HTK PLUS 6 MT PETROL (1497cc)",
          "1.5 HTK 6 MT PETROL (1497cc)",
          "1.5 HTE 6 MT PETROL (1497cc)",
          "1.5 HTX ANNIVERSARY EDITION IVT PETROL (1497cc)",
          "1.5 HTX ANNIVERSARY EDITION 6MT PETROL (1497cc)",
          "1.5 HTX IVT PETROL (1497cc)",
          "1.5 HTK PLUS IMT 6 MT PETROL (1497cc)",
          "1.5 HTX PLUS IVT PETROL (1497cc)",
          "1.5 G HTX IVT (1497cc)",
          "1.5 G HTX AE (1497cc)",
          "HTX CVT 1.5 (1497cc)",
          "HTX CVT 1.5 (1497cc)",
          "HTE 1.5 FACELIFT (1497cc)",
          "HTE 1.5 FACELIFT (1497cc)",
          "HTK 1.5 FACELIFT (1497cc)",
          "HTK 1.5 FACELIFT (1497cc)",
          "HTK PLUS 1.5 FACELIFT (1497cc)",
          "HTK PLUS 1.5 FACELIFT (1497cc)",
          "HTX 1.5 FACELIFT (1497cc)",
          "HTX 1.5 FACELIFT (1497cc)",
          "HTX CVT 1.5 FACELIFT (1497cc)",
          "HTX CVT 1.5 FACELIFT (1497cc)",
          "HTK PLUS 1.5 MT (1497cc)",
          "ANNIVERSARY EDITION CVT 1.5 (1497cc)",
          "ANNIVERSARY EDITION CVT 1.5 (1497cc)",
          "G 1.5 IVT HTX (1497cc)",
          "HTX IVT 1.5 AT (1497cc)",
          "G1 2.5 MT HTK PLUS (1497cc)",
          "G1.5 6MT HTK PLUS (1497cc)",
          "HTK SMARTSTREAM G1.5 6MT (1497cc)",
          "G1.5 IMT HTK PLUS (1497cc)",
          "D 1.5 CRDI VGT 6MT HTE (1497cc)",
          "HTK PLUS 1.5 (1497cc)",
          "HTK PLUS 1.5 (1497cc)",
          "G1.5 6MT HTK (1497cc)",
          "HTE 1.5 (1497cc)",
          "HTE 1.5 (1497cc)",
          "HTK 1.5 (1497cc)",
          "HTK 1.5 (1497cc)",
          "HTX 1.5 (1497cc)",
          "HTX 1.5 (1497cc)",
          "ANNIVERSARY EDITION 1.5 (1497cc)",
          "ANNIVERSARY EDITION 1.5 (1497cc)",
          "HTX 1.5 DUAL TONE. (1497cc)",
          "HTX 1.5 DUAL TONE (1497cc)",
          "HTX CVT 1.5 DUAL TONE. (1497cc)",
          "HTX CVT 1.5 DUAL TONE (1497cc)",
          "HTK PLUS 1.5 IMT (1497cc)",
          "HTK PLUS 1.5 IMT (1497cc)",
          "1.5 HTK (P) (1497cc)",
          "1.5 HTX DUAL TONE (P) (1497cc)",
          "1.5 HTX CVT DUAL TONE (P) (1497cc)",
          "1.5 HTE (P) (1497cc)",
          "1.5 HTK PLUS (P) (1497cc)",
          "1.5 HTX CVT (P) (1497cc)",
          "1.5 HTX (P) (1497cc)",
          "G 1.5 IVT HTX PETROL (1497cc)",
          "1.5 ANNIVERSARY EDITION (P) (1497cc)",
          "1.5 ANNIVERSARY EDITION CVT (P) (1497cc)",
          "1.5 HTK PLUS IMT (P) (1497cc)",
          "X-LINE AT D (1499cc)",
        ],
      },
      {
        model: "CARNIVAL",
        variants: [
          "2.2 8AT LIMOUSINE BSVI (2199cc)",
          "2.2 PREMIUM 7 BSVI (2199cc)",
          "2.2 PREMIUM 8 BSVI (2199cc)",
          "2.2 PRESTIGE 7 BSVI (2199cc)",
          "2.2 PRESTIGE 9 BSVI (2199cc)",
          "2.2 LIMOUSINE 7 BSVI (2199cc)",
          "2.2 8AT PRESTIGE BSVI (2199cc)",
          "2.3 8AT LIMOUSINE BSVI (2199cc)",
          "2.2 8AT LIMOUSINE PLUS BSVI (2199cc)",
          "2.2 PREMIUM AT (2199cc)",
          "2.2 PRESTIGE AT (2199cc)",
          "2.2 LIMOUSINE AT (2199cc)",
          "PRESTIGE 6 STR BS6 (2199cc)",
          "LIMOUSINE 7 STR BS6 (2199cc)",
          "PREMIUM 7 STR BS6 (2199cc)",
          "PREMIUM 8 STR BS6 (2199cc)",
          "PRESTIGE 7 STR BS6 (2199cc)",
          "PRESTIGE 9 STR BS6 (2199cc)",
          "2.2 D PREMIUM 8 SEATER AT (2199cc)",
          "2.2 D PREMIUM AT  7 SEATER (2199cc)",
          "2.2 D PRESTIGE 9 SEATER (2199cc)",
          "2.2 D PREMIUM 7 SEATER (2199cc)",
          "2.2 D PREMIUM 8 SEATER (2199cc)",
          "2.2 D PRESTIGE 7 SEATER (2199cc)",
          "2.2 D LIMOUSINE 7 SEATER (2199cc)",
          "2.2 D PRESTIGE 6 SEATER (2199cc)",
          "2.2 D LIMOUSINE PLUS 7 SEATER (2199cc)",
          "2.2 D PRESTIGE AT 7 SEATER (2199cc)",
          "LIMOUSINE PLUS 8 AT (2199cc)",
          "LIMOUSINE PLUS 7S AT (2199cc)",
          "PREMIUM 7 STR (2199cc)",
          "PREMIUM 7 STR (2199cc)",
          "PRESTIGE 7 STR (2199cc)",
          "PRESTIGE 7 STR (2199cc)",
          "LIMOUSINE 7 STR (2199cc)",
          "LIMOUSINE 7 STR (2199cc)",
          "PRESTIGE 9 STR (2199cc)",
          "PRESTIGE 9 STR (2199cc)",
          "PREMIUM 8 STR (2199cc)",
          "PREMIUM 8 STR (2199cc)",
          "PRESTIGE 6 STR (2199cc)",
          "PRESTIGE 6 STR (2199cc)",
          "LIMOUSINE PLUS 7 STR (2199cc)",
          "LIMOUSINE PLUS 7 STR (2199cc)",
          "STATION WAGON (3800cc)",
        ],
      },
      {
        model: "CARENS",
        variants: [
          "D1.5 6MT TURBO PREMIUM (1493cc)",
          "D1.5 6MT TURBO PRESTIGE (1493cc)",
          "D1.5 6MT TURBO PRESTIGE PLUS (1493cc)",
          "G1.5 6MT PREMIUM (1497cc)",
          "G1.4 6MT TURBO PREMIUM (1353cc)",
          "G1.4 DCT TURBO PRESTIGE PLUS (1353cc)",
          "D1.5 6AT TURBO LUXURY PLUS (1493cc)",
          "G1.4 DCT TURBO LUXURY PLUS (1353cc)",
          "G1.4 6AT TURBO LUXURY PLUS (1353cc)",
          "G1.4 6MT TURBO PRESTIGE (1353cc)",
          "G1.4 6MT TURBO PRESTIGE PLUS (1353cc)",
          "G1.4 6MT TURBO LUXURY (1353cc)",
          "G1.4 6MT TURBO LUXURY PLUS (1353cc)",
          "G1.4 DCT TUBRO LUXURY PLUS (1353cc)",
          "D1.5 6MT TURBO LUXURY (1493cc)",
          "D1.5 6MT TURBO LUXURY PLUS (1493cc)",
          "G1.5 6MT PRESTIGE (1497cc)",
          "D1.5 6MT TUBRO PRESTIGE PLUS (1493cc)",
          "D1.5 VGT 6IMT PREMIUM (1493cc)",
          "D1.5 VGT 6IMT PRESTIGE (1493cc)",
          "D1.5 VGT 6IMT PRESTIGE PLUS (1493cc)",
          "D1.5 VGT 6IMT LUXURY (1493cc)",
          "D1.5 VGT 6IMT LUXURY PLUS (1493cc)",
          "G1.5 7DCT LUXURY (O) (1482cc)",
          "D1.5 6AT LUXURY (O) (1493cc)",
          "D1.5 IMT MDRIVE7 (1493cc)",
          "G1.5 6MT PREMIUM 7 STR (1493cc)",
          "D1.5 6MT PREMIUM 7 STR (1493cc)",
          "D1.5 6MT PRESTIGE 7 STR (1493cc)",
          "D1.5 6MT PRESTIGEPLUS 7 STR (1493cc)",
          "D1.5 6MT LUXURY 7 STR (1493cc)",
          "D1.5 6MT LUXURY PLUS 6 STR (1493cc)",
          "D1.5 6MT LUXURY PLUS 7 STR (1493cc)",
          "D1.5 6AT LUXURY PLUS 6 STR (1493cc)",
          "D1.5 6AT LUXURY PLUS 7 STR (1493cc)",
          "D1.5 IMT PRESTIGE PLUS (1493cc)",
          "D1.5 IMT PRESTIGE (1493cc)",
          "D1.5 IMT M DRIVE (1493cc)",
          "1.5 LUXURY PLUS IMT (1493cc)",
          "D1.5 IMT PREMIUM 7 STR (1493cc)",
          "D1.5 6MT LUXURYPLUS 7 SEAT (1493cc)",
          "D1.5 6MT PRESTIGEPLUS 7 SEAT (1493cc)",
          "D1.5 6MT LUXURY 7 SEAT (1493cc)",
          "D1.5 6MT LUXURYPLUS 6 SEAT (1493cc)",
          "D1.5 6AT LUXURYPLUS 6 SEAT (1493cc)",
          "D1.5 6AT LUXURYPLUS 7 SEAT (1493cc)",
          "D1.5 6MT PRESTIGE 7 SEAT (1493cc)",
          "D1.5 6MT PREMIUM 7 SEAT (1493cc)",
          "D1.5 6 AT LUXURY PLUS 6 SEAT IMT (1493cc)",
          "D1.5 6 AT LUXURY 7 SEAT IMT (1493cc)",
          "D1.5 6 AT LUXURY PLUS 7 SEAT IMT (1493cc)",
          "D1.5 6 AT PREMIUM 7 SEAT IMT (1493cc)",
          "D1.5 6 AT PRESTIGE 7 SEAT IMT (1493cc)",
          "D1.5 6 AT PRESTIGEPLUS 7 SEAT IMT (1493cc)",
          "SMARTSTREAM G1 4T -GDI 6MT LUXURY PLUS (1493cc)",
          "D1.5 6MT LUXURY PLUS 7 (1493cc)",
          "D 1.5 CRDI (1493cc)",
          "LUXURY PLUS G1.4 DCT 7 (1493cc)",
          "D1 6AT LUXURY PLUS 6 (1493cc)",
          "G1.4  GDI 6 MT PRESTIGE (1493cc)",
          "D1.5 CRDI VGT 6MT PRESTIGE PLUS 7 (1493cc)",
          "SMARTSTREAM G1.4T-GDI 7DCT LUXURY PLUS 6 (1493cc)",
          "D1.5 CRDI VGT 6AT LUXURY PLUS 7 (1493cc)",
          "D1.5 6AT LUXURY PLUS 7 BSVI (1493cc)",
          "SMARTSTREAM G1.4 T-GDI PREMIUM (1493cc)",
          "SMARTSTREAM G1.4 T-GDI PRESTIGE PLUS (1493cc)",
          "SMARTSTREAM G1.4 T-GDI LUXURY (1493cc)",
          "SMARTSTREAM G1.5 PREMIUM (1493cc)",
          "SMARTSTREAM G1.5 PRESTIGE (1493cc)",
          "SMARTSTREAM G1.5 PRESTIGE PLUS (1493cc)",
          "SMARTSTREAM G1.5 LUXURY (1493cc)",
          "SMARTSTREAM G1.5 LUXURY PLUS (1493cc)",
          "1.5L CRDI VGT PREMIUM (1493cc)",
          "1.5L CRDI VGT PRESTIGE (1493cc)",
          "1.5L CRDI VGT LUXURY (1493cc)",
          "1.5 LUXURY PLUS 6S MT (1493cc)",
          "1.5 LUXURY PLUS 6S MT STR 6 (1493cc)",
          "SMARTSTREAM G1.4T GDI 6MT PRESTIGE PLUS 7 GLACIER (1493cc)",
          "D1.5 6MT LUXURY PLUS 6 (1493cc)",
          "1.5 LUXURY PLUS 6S AT DSL (1493cc)",
          "D 1.5 CRDI VGT 6AT L (1493cc)",
          "D1.5 6MT PRESTIGE PLUS 7 (1493cc)",
          "SMARTSTREAM G1.5T GDI IMT PRESTIGE 7 STR (1493cc)",
          "LUXURY PLUS 1.5 T DCT 7 STR (1493cc)",
          "LUXURY 1.5 DIESEL 7 STR (1493cc)",
          "LUXURY 1.5 DIESEL 7 STR (1493cc)",
          "LUXURY 1.5 DIESEL IMT 7 STR (1493cc)",
          "LUXURY O 1.5 DIESEL AT 7 STR (1493cc)",
          "LUXURY PLUS 1.5 DIESEL 6 STR (1493cc)",
          "LUXURY PLUS 1.5 DIESEL 6 STR (1493cc)",
          "LUXURY PLUS 1.5 DIESEL 7 STR (1493cc)",
          "LUXURY PLUS 1.5 DIESEL 7 STR (1493cc)",
          "LUXURY PLUS 1.5 DIESEL AT 6 STR (1493cc)",
          "LUXURY PLUS 1.5 DIESEL AT 6 STR (1493cc)",
          "LUXURY PLUS 1.5 DIESEL AT 7 STR (1493cc)",
          "LUXURY PLUS 1.5 DIESEL AT 7 STR (1493cc)",
          "LUXURY PLUS 1.5 DIESEL IMT 6 STR (1493cc)",
          "LUXURY PLUS 1.5 DIESEL IMT 7 STR (1493cc)",
          "PREMIUM 1.5 DIESEL 7 STR (1493cc)",
          "PREMIUM 1.5 DIESEL 7 STR (1493cc)",
          "PREMIUM 1.5 DIESEL IMT 7 STR (1493cc)",
          "PRESTIGE 1.5 DIESEL 7 STR (1493cc)",
          "PRESTIGE 1.5 DIESEL 7 STR (1493cc)",
          "PRESTIGE 1.5 DIESEL IMT 7 STR (1493cc)",
          "PRESTIGE PLUS 1.5 DIESEL 7 STR (1493cc)",
          "PRESTIGE PLUS 1.5 DIESEL 7 STR (1493cc)",
          "PRESTIGE PLUS 1.5 DIESEL IMT 7 STR (1493cc)",
          "D1.5 M DRIVE IMT (1493cc)",
          "1.5 PREMIUM DIESEL 7 STR (1493cc)",
          "1.5 PRESTIGE DIESEL 7 STR (1493cc)",
          "1.5 PRESTIGE PLUS DIESEL 7 STR (1493cc)",
          "1.5 LUXURY DIESEL 7 STR (1493cc)",
          "1.5 LUXURY PLUS DIESEL 7 STR (1493cc)",
          "1.5 LUXURY PLUS DIESEL 6 STR (1493cc)",
          "1.5 LUXURY PLUS AT DIESEL 7 STR (1493cc)",
          "1.5 LUXURY PLUS AT DIESEL 6 STR (1493cc)",
          "LUXURY PLUS DCT (1482cc)",
          "PRESTIGE PLUS 1.5 TURBO DCT (1482cc)",
          "D1.5 CRDI LUXURY PLUS AT (1482cc)",
          "LUXURY (O) 1.5 TURBO DCT (1482cc)",
          "G1.5 IMT PRESTIGE 7 SEAT AT (1482cc)",
          "G1.5 IMT LUXURY PLUS 7 SEAT AT (1482cc)",
          "G1.5 IMT LUXURY 7 SEAT AT (1482cc)",
          "G1.5 IMT PREMIUM 7 SEAT AT (1482cc)",
          "G1.5 IMT LUXURY PLUS 6 SEAT AT (1482cc)",
          "G1.5 IMT PRESTIGE PLUS 7 SEAT AT (1482cc)",
          "G1.5 DCT LUXURY PLUS 7 SEAT AT (1482cc)",
          "G1.5 DCT LUXURY PLUS 6 SEAT AT (1482cc)",
          "G1.5 DCT PRESTIGE PLUS 7 SEAT AT (1482cc)",
          "G1.5 DCT LUXURY PLUS STR 7 (1482cc)",
          "G1.5 DCT LUXURY (O) STR 7 (1482cc)",
          "LUXURY O 1.5 TURBO PETROL DCT 7 STR (1482cc)",
          "LUXURY PLUS 1.5 TURBO PETROL DCT 7 STR (1482cc)",
          "PREMIUM 1.5 TURBO PETROL IMT 7 STR (1482cc)",
          "PRESTIGE PLUS 1.5 TURBO PETROL DCT 7 STR (1482cc)",
          "PRESTIGE 1.5 TURBO PETROL IMT 7 STR (1482cc)",
          "PRESTIGE PLUS 1.5 TURBO PETROL IMT 7 STR (1482cc)",
          "LUXURY 1.5 TURBO PETROL IMT 7 STR (1482cc)",
          "LUXURY PLUS 1.5 TURBO PETROL IMT 6 STR (1482cc)",
          "LUXURY PLUS 1.5 TURBO PETROL IMT 7 STR (1482cc)",
          "LUXURY PLUS 1.5 TURBO PETROL DCT 6 STR (1482cc)",
          "D 1.5 6MT TARBO PREMIUM (1493cc)",
          "G1.5 6MT PRESTIGE 7 STR (1497cc)",
          "G1.5 6MT PRESTIGE 7 SEAT (1497cc)",
          "G1.5 6MT PREMIUM 7 SEAT (1497cc)",
          "G1.5T SMARTSTREAM 6IMT PRESTIGE PLUS 7 SEAT (1497cc)",
          "PREMIUM 1.5 PETROL 7 STR (1497cc)",
          "PREMIUM 1.5 PETROL 7 STR (1497cc)",
          "PRESTIGE 1.5 PETROL 7 STR (1497cc)",
          "PRESTIGE 1.5 PETROL 7 STR (1497cc)",
          "G1.5 6MT PRESTIGE 7 (1497cc)",
          "PRESTIGE PLUS 1.4T DCT (1497cc)",
          "G1.4 6MT PRESTIGE PLUS 7 (1497cc)",
          "1.5L CRDI VGT PRESTIGE PLUS (1497cc)",
          "1.5L CRDI VGT LUXURY PLUS (1497cc)",
          "D1.5 6MT PRESTIGE 7 (1497cc)",
          "G1.5 6MT PREMIUM 7 (1497cc)",
          "G1.5 DCT PRESTIGE PLUS 7 STR (1497cc)",
          "G1.5 6IMT PRESTIGE PLUS 7 (1497cc)",
          "SMARTSTREAM G1.5 7 DCT LUXURY PLUS (1497cc)",
          "G1.5 6IMT TURBO PREMIUM (1497cc)",
          "G1.5 6IMT TURBO PRESTIGE (1497cc)",
          "G1.5 6IMT TURBO PRESTIGE PLUS (1497cc)",
          "G1.5 7DCT TURBO PRESTIGE PLUS (1497cc)",
          "G1.5 6IMT TURBO LUXURY (1497cc)",
          "G1.5 6IMT TURBO LUXURY PLUS (1497cc)",
          "G1.5 7DCT TURBO LUXURY PLUS (1497cc)",
          "G1.5 6MT MDRIVE7 (1497cc)",
          "1.5 PREMIUM PETROL 7 STR (1497cc)",
          "1.5 PRESTIGE PETROL 7 STR (1497cc)",
          "G1.4 6MT PREMIUM 7 STR (1353cc)",
          "G1.4 6MT PRESTIGE 7 STR (1353cc)",
          "G1.4 6MT PRESTIGEPLUS 7 STR (1353cc)",
          "G1.4 DCT PRESTIGEPLUS 7 STR (1353cc)",
          "G1.4 6MT LUXURY 7 STR (1353cc)",
          "G1.4 6MT LUXURY PLUS 6 STR (1353cc)",
          "G1.4 6MT LUXURY PLUS 7 STR (1353cc)",
          "G1.4 DCT LUXURY PLUS 6 STR (1353cc)",
          "G1.4 DCT LUXURY PLUS 7 STR (1353cc)",
          "G1.4 6MT LUXURY 7 SEAT (1353cc)",
          "G1.4 6MT PRESTIGEPLUS 7 SEAT (1353cc)",
          "G1.4 6MT PRESTIGE 7 SEAT (1353cc)",
          "G1.4 6MT PREMIUM 7 SEAT (1353cc)",
          "G1.4 DCT PRESTIGEPLUS 7 SEAT (1353cc)",
          "G1.4 DCT LUXURYPLUS 7 SEAT (1353cc)",
          "G1.4 DCT LUXURYPLUS 6 SEAT (1353cc)",
          "G1.4 6MT LUXURYPLUS 7 SEAT (1353cc)",
          "G1.4 6MT LUXURYPLUS 6 SEAT (1353cc)",
          "LUXURY 1.4 PETROL 7STR (1353cc)",
          "LUXURY 1.4 PETROL 7 STR (1353cc)",
          "LUXURY PLUS 1.4 PETROL 6 STR (1353cc)",
          "LUXURY PLUS 1.4 PETROL 6 STR (1353cc)",
          "LUXURY PLUS 1.4 PETROL 7 STR (1353cc)",
          "LUXURY PLUS 1.4 PETROL 7 STR (1353cc)",
          "LUXURY PLUS 1.4 PETROL DCT 6 STR (1353cc)",
          "LUXURY PLUS 1.4 PETROL DCT 6 STR (1353cc)",
          "LUXURY PLUS 1.4 PETROL DCT 7 STR (1353cc)",
          "LUXURY PLUS 1.4 PETROL DCT 7 STR (1353cc)",
          "PREMIUM 1.4 PETROL 7 STR (1353cc)",
          "PREMIUM 1.4 PETROL 7 STR (1353cc)",
          "PRESTIGE 1.4 PETROL 7 STR (1353cc)",
          "PRESTIGE 1.4 PETROL 7 STR (1353cc)",
          "PRESTIGE PLUS 1.4 PETROL 7 STR (1353cc)",
          "PRESTIGE PLUS 1.4 PETROL 7 STR (1353cc)",
          "PRESTIGE PLUS 1.4 PETROL DCT 7 STR (1353cc)",
          "PRESTIGE PLUS 1.4 PETROL DCT 7 STR (1353cc)",
          "SMARTSTREAM G1.4T GDI 7 DCT LUXURY PLUS 7 (1353cc)",
          "SMARTSTREAM G1.4 T-GDI PRESTIGE (1353cc)",
          "SMARTSTREAM G1.4 T-GDI LUXURY PLUS (1353cc)",
          "G1.4 6MT PRESTIGE 7 (1353cc)",
          "G1.4  6MT LUXURY PLUS 6 (1353cc)",
          "G1.4 6MT PRESTIGE PLUS STR 7 (1353cc)",
          "SMARTSTREAM G1.4 T-GDI 7DCT PRESTIGE PLUS 7 AURORA (1353cc)",
          "G 1.4 T-GDI 6MT LUXURY 7 (1353cc)",
          "G 1.4  DCT LUXURY PLUS 6 (1353cc)",
          "1.4 LUXURY PLUS PETROL 7 STR (1353cc)",
          "1.4 LUXURY PLUS DCT PETROL 6 STR (1353cc)",
          "1.4 PRESTIGE PLUS DCT PETROL 7 STR (1353cc)",
          "1.4 LUXURY PETROL 7 STR (1353cc)",
          "1.4 LUXURY PLUS DCT PETROL 7 STR (1353cc)",
          "1.4 PRESTIGE PLUS PETROL 7 STR (1353cc)",
          "1.4 PRESTIGE PETROL 7 STR (1353cc)",
          "1.4 LUXURY PLUS PETROL 6 STR (1353cc)",
          "1.4 PREMIUM PETROL 7 STR (1353cc)",
          "D1.5 M DRIVE IMT (1500cc)",
        ],
      },
      {
        model: "SONET",
        variants: [
          "D1.5 6AT GTX PLUS BSVI (1493cc)",
          "G1.0 T 7DCT GTX PLUS BSVI (998cc)",
          "G1.0 T IMT GTX PLUS BSVI (998cc)",
          "G1.2 5MT HTE BSVI (1197cc)",
          "G1.2 5MT HTK BSVI (1197cc)",
          "D1.5 6MT HTE BSVI (1493cc)",
          "G1.2 5MT HTK PLUS BSVI (1197cc)",
          "D1.5 6MT HTK PLUS BSVI (1493cc)",
          "G1.0 T IMT HTK PLUS BSVI (998cc)",
          "D1.5 6MT HTX BSVI (1493cc)",
          "G1.0 T IMT HTX BSVI (998cc)",
          "D1.5 6AT HTX PLUS BSVI (1493cc)",
          "G1.0 T 7DCT HTX PLUS BSVI (998cc)",
          "D1.5 6MT HTX PLUS BSVI (1493cc)",
          "G1.0 T IMT PLUS BSVI (998cc)",
          "G1.0 T IMT HTX PLUS BSVI (1493cc)",
          "D1.5 6MT HTK (1493cc)",
          "G1.0T 7DCT HTX (998cc)",
          "1.5 GTX PLUS MT BSVI (1493cc)",
          "G1.0T IMT HTX PLUS (998cc)",
          "G1.0T 7 DCT HTK PLUS (998cc)",
          "D1.5 6AT HTK PLUS BSVI (1493cc)",
          "D1.5 6AT HTX CRDI VGT BSVI (1493cc)",
          "D1.5 6MT GTX PLUS BSVI (1493cc)",
          "D1.5 6AT X LINE BSVI (1493cc)",
          "G1.0T 7DCT X LINE BSVI (998cc)",
          "D1.5 6IMT HTK BSVI (1493cc)",
          "D1.5 6IMT HTK PLUS BSVI (1493cc)",
          "D1.5 6IMT HTX BSVI (1493cc)",
          "D1.5 6IMT HTX PLUS BSVI (1493cc)",
          "D1.5 6IMT GTX PLUS BSVI (1493cc)",
          "D1.5 6IMT HTE BSVI (1493cc)",
          "1.5D GTX PLUS 6MT (1493cc)",
          "GTX PLUS AT 1.5 DIESEL BSVI (1493cc)",
          "HTE 1.5 DIESEL BSVI (1493cc)",
          "HTK 1.5 DIESEL BSVI (1493cc)",
          "HTK PLUS 1.5 DIESEL BSVI (1493cc)",
          "HTK PLUS AT 1.5 DIESEL BSVI (1493cc)",
          "HTX 1.5 DIESEL BSVI (1493cc)",
          "HTX PLUS 1.5 DIESEL BSVI (1493cc)",
          "GTX PLUS 1.5 DIESEL BSVI (1493cc)",
          "HTX 1.5 AT DIESEL BSVI (1493cc)",
          "ANNIVERSARY EDITION 1.5 MT. (1493cc)",
          "X-LINE DIESEL AT (1493cc)",
          "1.5 CRDI VGT 6AT HTK PLUS DIESEL (1493cc)",
          "1.5 CRDI WGT 6MT GTX PLUS DIESEL (1493cc)",
          "1.5 CRDI WGT 6MT HTX PLUS DIESEL (1493cc)",
          "1.5 CRDI WGT 6MT HTX DIESEL (1493cc)",
          "1.5 CRDI WGT 6MT HTK PLUS DIESEL (1493cc)",
          "1.5 CRDI WGT 6MT HTK DIESEL (1493cc)",
          "1.5 CRDI WGT 6MT HTE DIESEL (1493cc)",
          "1.5 CRDI VGT 6AT GTX PLUS DIESEL (1493cc)",
          "1.5 CRDI IMT HTX DIESEL (1493cc)",
          "1.5 CRDI WGT 6AT HTX DIESEL (1493cc)",
          "1.5 CRDI VGT 6AT HTX ANNIVERSARY EDITION (1493cc)",
          "1.5 CRDI WGT 6MT HTX ANNIVERSARY EDITION (1493cc)",
          "X LINE  1.5 CRDI  6AT VGT (1493cc)",
          "HTK PLUS 1.5 FACELIFT (1493cc)",
          "HTK PLUS 1.5 FACELIFT (1493cc)",
          "GTX PLUS 1.5 AT FACELIFT (1493cc)",
          "GTX PLUS 1.5 AT FACELIFT (1493cc)",
          "HTX 1.5 FACELIFT (1493cc)",
          "HTX 1.5 FACELIFT (1493cc)",
          "GTX PLUS 1.5 FACELIFT (1493cc)",
          "GTX PLUS 1.5 FACELIFT (1493cc)",
          "HTK PLUS 1.5 AT (1493cc)",
          "HTK PLUS 1.5 AT (1493cc)",
          "HTE 1.5 FACELIFT (1493cc)",
          "HTE 1.5 FACELIFT (1493cc)",
          "HTK 1.5 FACELIFT (1493cc)",
          "HTK 1.5 FACELIFT (1493cc)",
          "HTX PLUS 1.5 FACELIFT (1493cc)",
          "HTX PLUS 1.5 FACELIFT (1493cc)",
          "D1.5 6MT GTX PLUS (1493cc)",
          "D1.5 6AT GTX PLUS (1493cc)",
          "D1.5 CRDI 6 MT HTX (1493cc)",
          "D1.5 6MT HTX (1493cc)",
          "D1.5 6MT HTK PLUS (1493cc)",
          "D1.5 6AT HTX (1493cc)",
          "D1.5 CRDI VGT 6AT X LINE (1493cc)",
          "X LINE 1.5 AT (1493cc)",
          "X LINE 1.5 AT (1493cc)",
          "D1.5 CRDI WGT 6MT HTK PLUS (1493cc)",
          "ANNIVERSARY EDITION 1.5 (1493cc)",
          "ANNIVERSARY EDITION 1.5 (1493cc)",
          "GTX PLUS 1.5 (1493cc)",
          "GTX PLUS 1.5 (1493cc)",
          "GTX PLUS 1.5 AT (1493cc)",
          "GTX PLUS 1.5 AT (1493cc)",
          "HTE 1.5 (1493cc)",
          "HTE 1.5 (1493cc)",
          "HTK 1.5 (1493cc)",
          "HTK 1.5 (1493cc)",
          "HTK PLUS 1.5 (1493cc)",
          "HTK PLUS 1.5 (1493cc)",
          "HTX 1.5 (1493cc)",
          "HTX 1.5 (1493cc)",
          "HTX 1.5 AT (1493cc)",
          "HTX 1.5 AT (1493cc)",
          "HTX PLUS 1.5 (1493cc)",
          "HTX PLUS 1.5 (1493cc)",
          "D1.5 CRDI VGT IMT HTK PLUS (1493cc)",
          "ANNIVERSARY EDITION 1.5 AT. (1493cc)",
          "ANNIVERSARY EDITION 1.5 AT (1493cc)",
          "GTX PLUS 1.5 AT DUAL TONE. (1493cc)",
          "GTX PLUS 1.5 AT DUAL TONE (1493cc)",
          "GTX PLUS 1.5 DUAL TONE. (1493cc)",
          "GTX PLUS 1.5 DUAL TONE (1493cc)",
          "HTX PLUS 1.5 DUAL TONE. (1493cc)",
          "HTX PLUS 1.5 DUAL TONE (1493cc)",
          "AUROCHS EDITION 1.5 AT (1493cc)",
          "AUROCHS EDITION 1.5 IMT (1493cc)",
          "HTE 1.5 IMT (1493cc)",
          "HTK 1.5 IMT (1493cc)",
          "HTK PLUS 1.5 IMT (1493cc)",
          "HTX 1.5 IMT (1493cc)",
          "HTX PLUS 1.5 IMT (1493cc)",
          "HTX PLUS 1.5 IMT DUAL TONE (1493cc)",
          "GTX PLUS 1.5 IMT (1493cc)",
          "GTX PLUS 1.5 IMT DUAL TONE (1493cc)",
          "1.5 HTK (1493cc)",
          "1.5 HTX (1493cc)",
          "1.5 GTX PLUS (1493cc)",
          "1.5 HTX PLUS (1493cc)",
          "1.5 HTK PLUS AT (1493cc)",
          "1.5 HTE (1493cc)",
          "1.5 HTK PLUS (1493cc)",
          "1.5 HTX AT (1493cc)",
          "1.5 GTX PLUS AT (1493cc)",
          "1.5 X LINE AT (1493cc)",
          "1.5 GTX PLUS AT DUAL TONE DIESEL (1493cc)",
          "1.5 ANNIVERSARY EDITION (1493cc)",
          "1.5 ANNIVERSARY EDITION AT (1493cc)",
          "GTX PLUS IMT 1.0 TURBO BSVI (998cc)",
          "HTK PLUS IMT 1.0 TURBO BSVI (998cc)",
          "HTK PLUS DCT 1.0 TURBO BSVI (998cc)",
          "HTX IMT 1.0 TURBO BSVI (998cc)",
          "HTX PLUS IMT 1.0 TURBO BSVI (998cc)",
          "GTX PLUS DCT 1.0 TURBO BSVI (998cc)",
          "X-LINE DCT (998cc)",
          "1.0 T GDI SMARTSTREAM 6IMT HTK PLUS PETROL (998cc)",
          "1.0 T GDI SMARTSTREAM 7DCT HTK PLUS PETROL (998cc)",
          "1.0 T GDI SMARTSTREAM 6IMT GTX PLUS PETROL (998cc)",
          "1.0 T GDI SMARTSTREAM 6IMT HTX PLUS PETROL (998cc)",
          "1.0 T GDI SMARTSTREAM 6IMT HTX PETROL (998cc)",
          "1.0 T GDI SMARTSTREAM 7DCT GTX PLUS PETROL (998cc)",
          "1.0 T GDI SMARTSTREAM 7DCT HTX PETROL (998cc)",
          "1.0 T GDI SMARTSTREAM IMT HTX ANNIVERSARY EDITION (998cc)",
          "1.0 T GDI SMARTSTREAM 7DCT GTX PETROL (998cc)",
          "1.0 T GDI SMARTSTREAM 7DCT HTX ANNIVERSARY EDITION (998cc)",
          "G1.0T 7DCT SMARTSTREAM X LINE (998cc)",
          "1.0 HTX GDI 7DCT SMARTSTREAM (998cc)",
          "HTX TURBO IMT (998cc)",
          "HTX PLUS 1.0 IMT FACELIFT (998cc)",
          "HTX PLUS 1.0 IMT FACELIFT (998cc)",
          "HTX 1.0 IMT FACELIFT (998cc)",
          "HTX 1.0 IMT FACELIFT (998cc)",
          "GTX PLUS 1.0 DCT FACELIFT (998cc)",
          "GTX PLUS 1.0 DCT FACELIFT (998cc)",
          "HTK PLUS 1.0 DCT (998cc)",
          "HTK PLUS 1.0 DCT (998cc)",
          "HTK PLUS 1.0 IMT FACELIFT (998cc)",
          "HTK PLUS 1.0 IMT FACELIFT (998cc)",
          "G1.0 T IMT HTK PLUS (998cc)",
          "GTX PLUS 1.0 IMT FACELIFT (998cc)",
          "GTX PLUS 1.0 IMT FACELIFT (998cc)",
          "G1.0 T GDI SMARTSTREAM IMT HTK PLUS (998cc)",
          "G1.0T 7DCT GTX PLUS (998cc)",
          "G1.0T IMT GTX PLUS (998cc)",
          "G1.0T HTX DCT AE (998cc)",
          "G1.0T IMT HTX (998cc)",
          "G1.0T 7DCT HTX AE (998cc)",
          "X LINE 1.0 DCT (998cc)",
          "X LINE 1.0 DCT (998cc)",
          "G1.0 T GDI SMARTSTREAM 7DCT HTX (998cc)",
          "GTX PLUS 1.0 DCT (998cc)",
          "GTX PLUS 1.0 DCT (998cc)",
          "GTX PLUS 1.0 IMT (998cc)",
          "GTX PLUS 1.0 IMT (998cc)",
          "HTK PLUS 1.0 IMT (998cc)",
          "HTK PLUS 1.0 IMT (998cc)",
          "HTX 1.0 IMT (998cc)",
          "HTX 1.0 IMT (998cc)",
          "HTX PLUS 1.0 IMT (998cc)",
          "HTX PLUS 1.0 IMT (998cc)",
          "ANNIVERSARY EDITION 1.0 DCT. (998cc)",
          "ANNIVERSARY EDITION 1.0 DCT (998cc)",
          "ANNIVERSARY EDITION 1.0 IMT. (998cc)",
          "ANNIVERSARY EDITION 1.0 IMT (998cc)",
          "HTX 1.0 DCT (998cc)",
          "HTX 1.0 DCT (998cc)",
          "GTX PLUS 1.0 DCT DUAL TONE. (998cc)",
          "GTX PLUS 1.0 DCT DUAL TONE (998cc)",
          "GTX PLUS 1.0 IMT DUAL TONE. (998cc)",
          "GTX PLUS 1.0 IMT DUAL TONE (998cc)",
          "HTK PLUS 1.0 IMT DUAL TONE. (998cc)",
          "HTK PLUS 1.0 IMT DUAL TONE (998cc)",
          "HTX PLUS 1.0 IMT DUAL TONE. (998cc)",
          "HTX PLUS 1.0 IMT DUAL TONE (998cc)",
          "AUROCHS EDITION 1.0 IMT (998cc)",
          "AUROCHS EDITION 1.0 7 DCT (998cc)",
          "1.0 HTX DCT (998cc)",
          "1.0 GTK PLUS DCT (998cc)",
          "1.0 GTX PLUS DCT (998cc)",
          "1.0 GTX PLUS DCT DUAL TONE (998cc)",
          "1.0 GTX PLUS IMT (998cc)",
          "1.0 HTX IMT (998cc)",
          "1.0 HTX PLUS IMT (998cc)",
          "1.0 HTK PLUS IMT (998cc)",
          "1.0 HTK PLUS DCT (998cc)",
          "1.0 X LINE DCT (998cc)",
          "1.0 ANNIVERSARY EDITION IMT (998cc)",
          "1.0 ANNIVERSARY EDITION DCT (998cc)",
          "HTE 1.2 BSVI (1197cc)",
          "HTK 1.2 BSVI (1197cc)",
          "HTK PLUS 1.2 BSVI (1197cc)",
          "1.2 MPI SMASTSTREAM 5MT HTK PLUS PETROL (1197cc)",
          "1.2 MPI SMASTSTREAM 5MT HTK PETROL (1197cc)",
          "1.2 MPI SMASTSTREAM 5MT HTE PETROL (1197cc)",
          "HTK 1.2 FACELIFT (1197cc)",
          "HTK 1.2 FACELIFT (1197cc)",
          "HTK PLUS 1.2 FACELIFT (1197cc)",
          "HTK PLUS 1.2 FACELIFT (1197cc)",
          "HTE 1.2 FACELIFT (1197cc)",
          "HTE 1.2 FACELIFT (1197cc)",
          "G1.2 5MT HTK (1197cc)",
          "SMARTSTREAM G 1.2 5 MT HTK (1197cc)",
          "HTE 1.2 (1197cc)",
          "HTE 1.2 (1197cc)",
          "HTK 1.2 (1197cc)",
          "HTK 1.2 (1197cc)",
          "HTK PLUS 1.2 (1197cc)",
          "HTK PLUS 1.2 (1197cc)",
          "1.2 HTK PLUS (1197cc)",
          "1.2 HTE (1197cc)",
          "1.2 HTK (1197cc)",
        ],
      },
      {
        model: "SORENTO",
        variants: [
          "BSIV (3470cc)",
          "3.3L 2WD AT (3342cc)",
          "3.3L 2WD AT 5 STR (3342cc)",
        ],
      },
      {
        model: "SONET ANNIVERSARY EDITION",
        variants: [
          "G1.0 T-GDI BSVI (998cc)",
          "1.5 L CRDI VGT BSVI (1493cc)",
          "1.5 L CRDI WGT BSVI (1493cc)",
        ],
      },
      {
        model: "NIRO",
        variants: ["BSIV (1580cc)"],
      },
      {
        model: "SPORTAGE",
        variants: ["BSIV (1999cc)"],
      },
      {
        model: "EV6",
        variants: [
          "GT LINE AWD (1995cc)",
          "GT LINE (1995cc)",
          "AWD (239370cc)",
          "GT LINE AWD (239370cc)",
          "GT LINE AWD (240cc)",
          "GT LINE AWD (1598cc)",
          "GT LINE (1598cc)",
          "GT LINE AWD (77.4cc)",
          "GT LINE (77.4cc)",
          "GT LINE (168530cc)",
          "GT LINE (169cc)",
        ],
      },
      {
        model: "EV 6",
        variants: ["GT LINE AWD (1600cc)", "GT LINE (1600cc)"],
      },
      {
        model: "STINGER",
        variants: ["GT (1498cc)"],
      },
      {
        model: "CLARUS",
        variants: ["0 (1998cc)"],
      },
    ],
  },
  {
    make: "RENAULT",
    models: [
      {
        model: "FLUENCE",
        variants: [
          "1.5 E2 ADVANCE EDITION (1461cc)",
          "DIESEL PH2 E2 (1461cc)",
          "DIESEL PH2 E4 (1461cc)",
          "E2 DIESEL PHASE 2 (1461cc)",
          "PETROL 2.0L (1997cc)",
          "STEP4 E2 DIESEL 1.5L (1461cc)",
          "STEP4 E4 DIESEL 1.5L (1461cc)",
          "1.5 E4 (1461cc)",
          "2.0 E4 (1997cc)",
          "1.5 E2 (1461cc)",
          "2.0 PETROL E4 (1997cc)",
          "1.5 DIESEL E2 ADVANCE EDITION (1461cc)",
          "1.5 DIESEL E2 (1461cc)",
          "1.5 DIESEL E4 (1461cc)",
          "1.5 (1461cc)",
          "E2 ADVANCE (1461cc)",
          "15000 (1461cc)",
          "DIESEL E2 (1461cc)",
          "ADVANTAGE EDITION (1461cc)",
          "DIESEL E4 (1461cc)",
          "E2 (1461cc)",
          "E2 (1461cc)",
          "E4. (1461cc)",
          "E4 (1461cc)",
          "2 (1997cc)",
          "20000 (1997cc)",
          "E4 (1997cc)",
        ],
      },
      {
        model: "KWID",
        variants: [
          "CLIMBER (999cc)",
          "0.8 RXE (799cc)",
          "0.8 RXL (799cc)",
          "1.0 RXL (AT) (999cc)",
          "0.8 RXT (799cc)",
          "0.8 RXT (O) (799cc)",
          "1.0 RXT (AT) (999cc)",
          "0.8 STD (799cc)",
          "RXT 1.0 EASY-R (999cc)",
          "1.0 RXT (O) AT (999cc)",
          "1.0 CAPTAIN AMERICA (MT) (999cc)",
          "1.0 CAPTAIN AMERICA (AT) (999cc)",
          "1.0 IRON MAN (MT) (999cc)",
          "1.0 IRON MAN (AT) (999cc)",
          "CLIMBER (AT) (999cc)",
          "RXL 1.0 EASY-R (999cc)",
          "RXL 1.0 SCE (999cc)",
          "RXL 1.0 SCE SPL ANN EDTN (999cc)",
          "RXL SPECIAL ANNIVERSARY EDTN (799cc)",
          "RXT 1.0 CLIMBER (999cc)",
          "RXT 1.0 EASY-R CLIMBER (999cc)",
          "RXT 1.0 SCE (998cc)",
          "RXT 1.0 SCE. (999cc)",
          "RXT 1.0 SCE AMT DR ABAG (998cc)",
          "RXT 1.0 SCE EASY R OPTION (998cc)",
          "RXT 1.0 SCE EASY R OPT (999cc)",
          "RXT 1.0 SCE OPT (998cc)",
          "RXT 1.0 SCE OPTION (999cc)",
          "RXT 1.0 SCE SPL ANN EDTN (999cc)",
          "RXT SPL ANNIVERSARY EDTN (799cc)",
          "1.0 RXL ANNIVERSARY EDTN (999cc)",
          "1.0 RXT ANNIVERSARY EDTN (999cc)",
          "MARVEL (O) S MT (999cc)",
          "MARVEL (O) S AT (999cc)",
          "1.0 CLIMBER (O) (999cc)",
          "NEOTECH AMT (799cc)",
          "RXL 0.8 SCE BSVI (799cc)",
          "RXL (O) 0.8 SCE BSVI (799cc)",
          "RXL 1.0 SCE BSVI (999cc)",
          "RXL (O) 1.0 SCE BSVI (999cc)",
          "RXT 1.0 SCE BSVI (999cc)",
          "RXT 1.0 SCE EASY R BSVI (999cc)",
          "RXT (O) 1.0 SCE EASY R BSVI (999cc)",
          "CLIMBER 1.0 SCE BSVI (999cc)",
          "CLIMBER 1.0 SCE EASY R BSVI (999cc)",
          "1.0 CLIMBER (O) AMT (999cc)",
          "RXT DRIVER AIRBAG OPTION (799cc)",
          "RXE O (799cc)",
          "1.0 RXT O (999cc)",
          "RXT O (799cc)",
          "1.0 NEOTECH MT (999cc)",
          "1.0 RXT (999cc)",
          "1.0 RXT (999cc)",
          "1.0 RXL (999cc)",
          "CLIMBER PETROL EASY-R 1.0 (999cc)",
          "AMT RXL (999cc)",
          "1.0 RXT OPTIONAL AMT (999cc)",
          "1.0 RXT OPTIONAL (999cc)",
          "CLIMBER MT (999cc)",
          "RELOADED (999cc)",
          "RXL ANNIVERSARY EDITION (999cc)",
          "RXL SCE (999cc)",
          "RXT AMT ESI (999cc)",
          "RXT ANNIVERSARY EDITION (999cc)",
          "RXT SCE (999cc)",
          "1.0 RXT AMT O (999cc)",
          "NEOTECH PETROL EASY- R 1.0 SCE (999cc)",
          "1.0 RXL BSVI (999cc)",
          "1.0 RXL BS VI (999cc)",
          "RXT 1.0L AMT (999cc)",
          "CLIMBER 1.0L AMT (999cc)",
          "CLIMBER 1.0L AMT DUAL TONE (999cc)",
          "1.0 RXT (O) BS6 (999cc)",
          "1.0 RXT O MT BSIV (999cc)",
          "CLIMBER 1.0 O BS6 (999cc)",
          "1.0 NEOTECH (999cc)",
          "1.0 CLIMBER (O) MT (999cc)",
          "1.0 RXT (O) AMT (999cc)",
          "1.0 CLIMBER EASY R (999cc)",
          "1.0 CLIMBER (O) AMT DUAL TONE (999cc)",
          "1.0 CLIMBER (O) MT DUAL TONE (999cc)",
          "1.0 RXE (999cc)",
          "1.0 RXT SPECIAL ANNIVERSARY EDITION (999cc)",
          "1.0 AMT (999cc)",
          "1.0 RXT DRIVER AIRBAG OPTION (999cc)",
          "1.0 RXT AMT MARVEL EDITION (999cc)",
          "1.0 RXT MARVEL EDITION (999cc)",
          "1.0 RXT AMT DRIVER AIRBAG OPTION (999cc)",
          "1.0 CLIMBER AMT (999cc)",
          "1.0 CLIMBER (999cc)",
          "1.0 CLIMBER (O) EASY R (999cc)",
          "CLIMBER (O) 1.0 AMT DUAL TONE (999cc)",
          "CLIMBER (O) 1.0 DUAL TONE (999cc)",
          "CLIMBER 1.0L EASY-R BS6 ORVM MY22 DT (999cc)",
          "CLIMBER 1.0L MT BS6 ORVM MY22 DT (999cc)",
          "1.0 S RXT (999cc)",
          "1.0 RXT AMT O BS VI (999cc)",
          "1.0 RXT O BS VI (999cc)",
          "CLIMBER 1.0 O BS VI (999cc)",
          "CLIMBER 1.0 (999cc)",
          "NEOTECH RXL 1.0 AMT (999cc)",
          "NEOTECH RXL 1.0 MT (999cc)",
          "CLIMBER 1.0 AMT O BS VI (999cc)",
          "1.0 RXL AMT BS VI (999cc)",
          "CLIMBER 1.0 AMT O DUAL TONE (999cc)",
          "1.0 RXL EDITION (999cc)",
          "1.0 RXT EDITION (999cc)",
          "1.0 RXL O (999cc)",
          "CLIMBER AMT (999cc)",
          "1.0 RXT AMT (999cc)",
          "1.0 RXT AMT (999cc)",
          "1.0 RXT OPT (999cc)",
          "1.0 RXT AMT OPT (999cc)",
          "CLIMBER 1.0 MT (999cc)",
          "CLIMBER 1.0 (O) EASY R (999cc)",
          "1.0 RXL EASY R (999cc)",
          "1.0 RXT (O) EASY R (999cc)",
          "1.0 RXT EASY R (999cc)",
          "1.0 RXL SPECIAL ANNIVERSARY EDITION (999cc)",
          "1.0 NEOTECH EASY-R (999cc)",
          "CLIMBER 1.0 AMT O (999cc)",
          "CLIMBER 1.0 AMT (999cc)",
          "CLIMBER 1.0 O DUAL TONE (999cc)",
          "CLIMBER 1.0 O (999cc)",
          "CLIMBER 1.0 (O) (999cc)",
          "1.0 MARVEL CAPTAIN AMERICA EDITION (999cc)",
          "1.0 MARVEL CAPTAIN AMERICA EDITION AMT (999cc)",
          "1.0 MARVEL IRON MAN EDITION (999cc)",
          "1.0 MARVEL IRON MAN EDITION AMT (999cc)",
          "1.0 RXL AMT (999cc)",
          "CLIMBER AMT (799cc)",
          "CLIMBER (799cc)",
          "RXE 0.8 (799cc)",
          "RXL 0.8 (799cc)",
          "RXT 0.8 (799cc)",
          "RXT 0.8 (O) (799cc)",
          "RXT 1.0 (999cc)",
          "RXT 1.0 (O) (999cc)",
          "STD 0.8 (799cc)",
          "RXL 1.0 (999cc)",
          "RXL 1.0 AMT (999cc)",
          "RXT 1.0 AMT (999cc)",
          "RXT OPTIONAL (799cc)",
          "RXE OPTION (799cc)",
          "RXL ANNIVERSARY EDITION (799cc)",
          "RXT ANNIVERSARY EDITION (799cc)",
          "RXT OPTION (799cc)",
          "1.0 RXT AMT O (799cc)",
          "RXT BS6 (799cc)",
          "RXT PETROL (799cc)",
          "FESTIVE (799cc)",
          "RXE RADIO OPTION (799cc)",
          "RXL SPECIAL ANNIVERSARY EDITION (799cc)",
          "RXT SPECIAL ANNIVERSARY EDITION (799cc)",
          "RXE BS VI (799cc)",
          "RXL BS VI (799cc)",
          "RXT BS VI (799cc)",
          "NEOTECH RXL (799cc)",
          "RXL EDITION (799cc)",
          "RXT EDITION (799cc)",
          "RXL O (799cc)",
          "RXE OPT (799cc)",
          "RXT OPT (799cc)",
          "RXL (O) MT (799cc)",
          "NEOTECH (799cc)",
          "RXE (799cc)",
          "RXE (799cc)",
          "RXL (799cc)",
          "RXL (799cc)",
          "RXT (799cc)",
          "RXT (799cc)",
          "STD (799cc)",
          "STD (799cc)",
          "RXT 1.0 (Occ)",
          "1.0 RXT AMT (1000cc)",
        ],
      },
      {
        model: "DUSTER",
        variants: [
          "RXL PLUS (1461cc)",
          "RXS (1461cc)",
          "RXS (1498cc)",
          "RXE (1498cc)",
          "RXE (1461cc)",
          "RXE (1598cc)",
          "PETROL RXL PH1 (1461cc)",
          "PETROL RXL PH1. (1598cc)",
          "RXE BSVI (1498cc)",
          "RXS BSVI (1498cc)",
          "RXZ PLUS (1461cc)",
          "RXZ AMD (1498cc)",
          "RXZ (AT) (1461cc)",
          "RXZ 4X2 (MT) (1461cc)",
          "RXZ (1461cc)",
          "STD (1498cc)",
          "RXL (O) (1461cc)",
          "DIESEL E2 THP 4X4 PH1 (1461cc)",
          "DIESEL E3 THP 4X4 PH1 (1461cc)",
          "DIESEL HP (O) RXL(ALY+NAV)PH1 (1461cc)",
          "HP RXE (W/O PWR WI) PH1 (1461cc)",
          "DIESEL HP RXE 85 PS (1461cc)",
          "DIESEL HP RXE 85 PS - ADV EDN (1461cc)",
          "HP RXE 85 PS W/O PWRWDOW (1461cc)",
          "DIESEL HP RXL (1461cc)",
          "HP RXL OPT (ALLOYS + NAV) PH1 (1461cc)",
          "HP RXL OPT WITH ABAG& ABS (1461cc)",
          "DIESEL HP RXL OPTION (1461cc)",
          "DIESEL HP RXL PH1 (1461cc)",
          "HP RXL PLUS(ABS DBAG)PH1 (1461cc)",
          "DIESEL THP OPTION RXL (1461cc)",
          "DIESEL THP RXL (1461cc)",
          "DIESEL THP RXL (A BAG) PH1 (1461cc)",
          "DIESEL THP RXL 110 PS ADV EDN (1461cc)",
          "THP RXL WITH DUAL AIRBAGS (1461cc)",
          "DIESEL THP RXZ (1461cc)",
          "DIESEL THP RXZ FABRIC (1461cc)",
          "DIESEL THP RXZ WITH LEATHER (1461cc)",
          "DSL THP RXL 110 PS ADV EDNPH1 (1461cc)",
          "110 PS RXZ OPTION (WITH RR AC) (1461cc)",
          "110 PS RXZ PLS (W/O RR AC) (1461cc)",
          "DIESEL HP OPTION RXL PH1 (1461cc)",
          "HP RXE W/O PWR WINDOW PH1 (1461cc)",
          "RXE W/O POWER WINDOW PH1 (1598cc)",
          "HP RXL PLUS (ABS+DUAL ABAG)PH1 (1461cc)",
          "PETROL RXE W/O POWER WINDOW (1598cc)",
          "PETROL RXL WITH DUAL ABAG PH1 (1598cc)",
          "PETROL RXL WITH DUAL AIRBAGS (1598cc)",
          "RXE - 85 PS (1461cc)",
          "RXE - 85 PS ADVENTURE EDITION (1461cc)",
          "RXE - 85 PS PH2. (1461cc)",
          "RXE - PH2 (1461cc)",
          "RXE - PETROL PH2 (1598cc)",
          "RXL - 110 PS ADVENTURE EDITION (1461cc)",
          "RXL - 110 PS AMT PH2 (1461cc)",
          "RXL - 110 PS PH2 (1461cc)",
          "RXL - 85 PS ADVENTURE EDITION (1461cc)",
          "RXL - 85 PS PH2 (1461cc)",
          "RXL - DIESEL PH2 (1461cc)",
          "RXL - PETROL PH2 (1598cc)",
          "RXS - 85 PS (1461cc)",
          "RXS - 85 PS PH2 (1461cc)",
          "RXS 110 PS SANDSTORM EDITION (1461cc)",
          "RXS 85 PS SANDSTORM EDITION (1461cc)",
          "RXZ - 110 PS AMT PH2 (1461cc)",
          "RXZ - 110 PS AWD (1461cc)",
          "RXZ 110 PS AWD ADVENTURE EDN (1461cc)",
          "RXZ - 110 PS AWD PH2 (1461cc)",
          "RXZ - 110 PS PH2 (1461cc)",
          "RXZ - 85 PS (1461cc)",
          "RXZ - 85 PS (1498cc)",
          "RXZ - 85 PS PH2 (1461cc)",
          "STD - 85 PS PH2 (1461cc)",
          "THP RXL (ABAG) PH1 (1461cc)",
          "THP RXZ PLUS L (NAVW) PH1 (1461cc)",
          "RXL (AT) (1461cc)",
          "RXZ (O) (1461cc)",
          "RXS (O) CVT (1498cc)",
          "RXS (O) (1461cc)",
          "RXZ BSVI (1498cc)",
          "RXE 1.3 TURBO MT BSVI (1330cc)",
          "RXS 1.3 TURBO MT BSVI (1330cc)",
          "RXZ 1.3 TURBO MT BSVI (1330cc)",
          "RXS 1.3 TURBO CVT BSVI (1330cc)",
          "RXZ 1.3 TURBO CVT BSVI (1330cc)",
          "RXL 85 PS PLUS (1461cc)",
          "RXZ 110 PS (1461cc)",
          "STD 85 PS (1461cc)",
          "RXL (1461cc)",
          "RXL (1598cc)",
          "85 PS RXE (1461cc)",
          "RXS CVT PETROL (1498cc)",
          "RXE PETROL (1498cc)",
          "RXL PETROL (1498cc)",
          "RXL PETROL MT 106 PS (1498cc)",
          "110PS RXZ (1498cc)",
          "110PS RXZ AMD (1498cc)",
          "85 PS RXZ 4X2 MT (1461cc)",
          "85PS RXS (1498cc)",
          "85PS RXZ (1498cc)",
          "85PS STD (1498cc)",
          "85PS STD (1461cc)",
          "RXS 110 SANDSTORM EDITION (1461cc)",
          "RXS 85 SANDSTORM EDITION (1461cc)",
          "ADVENTURE EDITION RXE 85PS (1461cc)",
          "ADVENTURE EDITION RXZ 110PS (1461cc)",
          "ADVENTURE EDITION RXL 85PS (1461cc)",
          "RXE 5 SEATER (1498cc)",
          "RXS PETROL CVT 106 PS (1498cc)",
          "RXE PETROL 104 PS (1598cc)",
          "RXL PETROL 104 PS (1598cc)",
          "RXZ DIESEL AWD 110 PS (1461cc)",
          "RXL DIESEL 85 PS (1461cc)",
          "RXZ AMT DIESEL 110 PS (1461cc)",
          "RXL AMT DIESEL 110 PS (1461cc)",
          "RXS DIESEL 85 PS (1461cc)",
          "RXE DIESEL 85 PS (1461cc)",
          "RXL DIESEL (OPT) 85 PS (1461cc)",
          "RXL DIESEL 110 PS (1461cc)",
          "RXZ DIESEL 110 PS (1461cc)",
          "RXZ DIESEL (OPT) 110 PS (1461cc)",
          "RXL (1498cc)",
          "ANNIVERSARY EDITION (1461cc)",
          "110PS RXZ AMT (1498cc)",
          "RXS CVT (1498cc)",
          "2ND ANNIVERSARY EDITION (1461cc)",
          "85PS DIESEL STD (1461cc)",
          "85PS  DIESEL RXL EXPLORE (1461cc)",
          "110 PS DIESEL RXZ (1461cc)",
          "ONE LAKH EDITION (1461cc)",
          "RXL ADVENTURE (1461cc)",
          "85PS RXE ADVENTURE (1461cc)",
          "RXZ OPTION (1461cc)",
          "RXL OPTION (1461cc)",
          "RXL AWD (1461cc)",
          "RXZ AWD (1461cc)",
          "110 PS RXL AMT (1461cc)",
          "RXZ AMT (1461cc)",
          "RXL AMT (1461cc)",
          "110 PS RXZ 4X2 MT (1461cc)",
          "1.4 RXE 85 PS (1461cc)",
          "4X4 (1461cc)",
          "THP RXL DIESEL (1461cc)",
          "104 PS RXL PLUS (1461cc)",
          "85 PS RXL DIESEL O WITH NAV (1461cc)",
          "RXZ DIESEL 85 PS1 (1461cc)",
          "RXS SANDSTORM EDITION 110 PS (1461cc)",
          "RXS SANDSTORM EDITION 85 PS (1461cc)",
          "RXZ 110 PS AWD ADVENTURE (1461cc)",
          "RXL DIESEL ADVENTURE EDITION 85 PS (1461cc)",
          "RXE PETROL 104 PS (1461 CC) (1461cc)",
          "RXS DIESEL (OPT) AWD 110 PS (1461cc)",
          "RXL DIESEL AWD 110 PS (1461cc)",
          "RXL DIESEL (OPT) 110 PS (1461cc)",
          "RXE DIESEL ADVENTURE 85 PS (1461cc)",
          "RXZ DIESEL PLUS 110 PS (1461cc)",
          "RXL DIESEL PLUS 85 PS (1461cc)",
          "RXL DIESEL ADVENTURE EDITION 110 PS (1461cc)",
          "RXL DIESEL PLUS 110 PS (1461cc)",
          "RXS AMT 110 PS (1461cc)",
          "RXL DIESEL EXPLORE 110 PS (1461cc)",
          "RXL DIESEL EXPLORE 85 PS (1461cc)",
          "110 PS RXZ 4X4 MT (1461cc)",
          "85 PS RXL O (1461cc)",
          "110 PS RXL AWD (1461cc)",
          "110 PS RXZ AWD (1461cc)",
          "110 PS RXL (1461cc)",
          "RXZ DCI 85 PS HSRAWE (1461cc)",
          "RXS DCI 85 PS (HSRAWE) (1461cc)",
          "110 PS RXL 4X2 AMT (1461cc)",
          "85 PS RXS 4X2 MT (1461cc)",
          "85 PS BASE 4X2 MT (1461cc)",
          "1.5 DCI HP RXL (1461cc)",
          "110 PS RXL EXPLORE LE (1461cc)",
          "110 PS RXS 4X2 AMT (1461cc)",
          "110 PS RXS 4X2 MT (1461cc)",
          "110 PS RXS 4X4 OPT DIESEL (1461cc)",
          "110 PS RXZ 4X2 AMT (1461cc)",
          "110 PS RXZ DIESEL O (1461cc)",
          "110 PS RXZ O (1461cc)",
          "110 PS RXZ PLUS (1461cc)",
          "110 PS SANDSTORM EDITION (1461cc)",
          "85 PS DSL RXZ (1461cc)",
          "85 PS RXL DIESEL O (1461cc)",
          "85 PS RXL EXPLORE LE (1461cc)",
          "85 PS SANDSTORM EDITION (1461cc)",
          "ADVENTURE EDITION 110 PS RXZ 4X4 MT (1461cc)",
          "ADVENTURE EDITION 85 PS RXL 4X2 MT (1461cc)",
          "85 PS RXL (1461cc)",
          "85 PS RXS (1461cc)",
          "85 PS RXZ (1461cc)",
          "110 PS RXZ AMT (1461cc)",
          "RXE DIESEL (1461cc)",
          "RXE DIESEL (1461cc)",
          "RXL DIESEL (1461cc)",
          "RXL DIESEL (1461cc)",
          "RXZ DIESEL (1461cc)",
          "RXZ DIESEL (1461cc)",
          "110 PS RXS (O) AWD (1461cc)",
          "110 PS RXS 4X4 (O) DIESEL (1461cc)",
          "110 PS RXS AMT DIESEL (1461cc)",
          "110 PS RXZ DIESEL AMT (1461cc)",
          "85PS DIESEL STANDARD (1461cc)",
          "110 PS RXS SANDSTORM EDITION DIESEL (1461cc)",
          "85 PS RXS SANDSTORM EDITION DIESEL (1461cc)",
          "85 PS RXL ADVENTURE (1461cc)",
          "110 PS RXL DIESEL EXPLORE (1461cc)",
          "85 PS RXS DIESEL (1461cc)",
          "110 PS RXL DIESEL AMT (1461cc)",
          "85 PS RXL DIESEL EXPLORE (1461cc)",
          "RXL DIESEL OPT (1461cc)",
          "85 PS RXZ DIESEL (1461cc)",
          "110 PS RXZ DIESEL ADVENTURE (1461cc)",
          "85 PS RXL DIESEL OPT (1461cc)",
          "110 PS RXZ DIESEL OPT (1461cc)",
          "110 PS RXS DIESEL (1461cc)",
          "110 PS RXZ DIESEL PLUS (1461cc)",
          "110 PS RXL DIESEL (1461cc)",
          "110 PS RXZ DIESEL (1461cc)",
          "85 PS RXE DIESEL ADVENTURE (1461cc)",
          "85 PS RXL DIESEL (1461cc)",
          "110 PS RXL AWD DIESEL (1461cc)",
          "110 PS RXL ADVENTURE. (1461cc)",
          "110 PS RXL ADVENTURE (1461cc)",
          "110 PS RXZ AWD DIESEL (1461cc)",
          "85 PS RXE DIESEL (1461cc)",
          "85 PS RXL DIESEL PLUS (1461cc)",
          "PETROL RXE (1598cc)",
          "PETROL RXL (1598cc)",
          "104 PS RXL PETROL (1598cc)",
          "RXS CVT PETROL 104 PS 1598 CC (1598cc)",
          "RXL PETROL PLUS 104 PS (1598cc)",
          "RXL DCI BSIV (1598cc)",
          "110 PS RXL (1598cc)",
          "RXL PLUS (1598cc)",
          "RXE PETROL (1598cc)",
          "RXE PETROL (1598cc)",
          "RXL PETROL (1598cc)",
          "RXL PETROL (1598cc)",
          "RXZ 1.3 TURBO PETROL MT BS6 (1330cc)",
          "RXZ 1.3L TURBO X TRONIC BS6 (1330cc)",
          "RXS 1.3L TURBO PETROL X TRONIC 156 PS (1330cc)",
          "RXZ 1.3L TURBO PETROL 156 PS (1330cc)",
          "RXZ 1.3L TURBO PETROL X TRONIC 156 PS (1330cc)",
          "RXE 1.3L TURBO PETROL 156 PS (1330cc)",
          "RXS 1.3L TURBO PETROL 156 PS (1330cc)",
          "RXZ 1.3 TURBO PETROL MT (1330cc)",
          "RXS 1.3 TURBO PETROL CVT (1330cc)",
          "RXZ 1.3 TURBO PETROL CVT (1330cc)",
          "RXS 1.3 TURBO PETROL MT (1330cc)",
          "RXE 1.3 TURBO PETROL MT (1330cc)",
          "1.3 RXZ TURBO MT (P) (1330cc)",
          "1.3 RXS TURBO MT (P) (1330cc)",
          "1.3 RXS TURBO CVT (P) (1330cc)",
          "1.3 RXZ TURBO CVT (P) (1330cc)",
          "1.3 RXE TURBO MT (P) (1330cc)",
          "RXE PETROL BSVI (1498cc)",
          "RXS PETROL CVT 104 PS (1498cc)",
          "RXZ PETROL 106 PS (1498cc)",
          "RXZ PETROL MT (1498cc)",
          "RXS PETROL MT 106 PS (1498cc)",
          "RXE PETROL 106 PS (1498cc)",
          "RXL PETROL 106 PS (1498cc)",
          "RXS OPT CVT (1498cc)",
          "RXZ PETROL (1498cc)",
          "RXS (O) CVT PETROL (1498cc)",
          "1.5 RXE PETROL (1498cc)",
          "1.5 RXZ PETROL (1498cc)",
          "1.5 RXL PETROL (1498cc)",
          "RXS PETROL (1498cc)",
        ],
      },
      {
        model: "SCALA",
        variants: [
          "RXE (1498cc)",
          "RXZ (1461cc)",
          "RXZ XTRONIC CVT (1498cc)",
          "PETROL RXL WITH DUAL ABAG (1498cc)",
          "RXL (1461cc)",
          "RXL (1498cc)",
          "RXL XTRONIC CVT (1498cc)",
          "DIESEL RXE WITH SINGLE AIRBAG. (1461cc)",
          "DIESEL RXZ WITH DUAL AIRBAG (1461cc)",
          "PETROL RXE WITH SINGLE AIRBAG (1498cc)",
          "RXE PETROL 99 PS (1498cc)",
          "RXZ XTRONIC CVT PETROL 101 PS (1498cc)",
          "RXL XTRONIC CVT PETROL 101 PS (1498cc)",
          "RXL CVT PETROL (1498cc)",
          "RXL XTRONIC (1498cc)",
          "RXZ AT (1498cc)",
          "RXL PETROL 99 PS (1498cc)",
          "RXZ CVT PETROL 99 PS (1498cc)",
          "RXL PETROL AT TRAVELOGUE (1498cc)",
          "RXZ PETROL AT TRAVELOGUE (1498cc)",
          "RXL PETROL TRAVELOGUE (1498cc)",
          "RXZ (1498cc)",
          "RXZ CVT PETROL (1498cc)",
          "RXZ CVT PETROL (1498cc)",
          "RXE PETROL (1498cc)",
          "RXE PETROL (1498cc)",
          "RXL PETROL (1498cc)",
          "RXL PETROL (1498cc)",
          "RXZ PETROL AT (1498cc)",
          "RXL PETROL AT (1498cc)",
          "STD 5 STR (1461cc)",
          "RXL DIESEL 86 PS (1461cc)",
          "RXZ DIESEL 86 PS (1461cc)",
          "RXE DIESEL 86 PS (1461cc)",
          "RXL DIESEL TRAVELOGUE (1461cc)",
          "RXZ DIESEL TRAVELOGUE (1461cc)",
          "RXE (1461cc)",
          "RXZ DIESEL (1461cc)",
          "RXZ DIESEL (1461cc)",
          "RXE DIESEL (1461cc)",
          "RXE DIESEL (1461cc)",
          "RXL DIESEL (1461cc)",
          "RXL DIESEL (1461cc)",
          "RXZ CVT PETROL TRAVELOGUE (1461cc)",
          "TRAVELOGUE EDITION (1461cc)",
        ],
      },
      {
        model: "LODGY",
        variants: [
          "RXE (1461cc)",
          "RXE (1461cc)",
          "STD (1461cc)",
          "DCI - JSRGW4 (8 SEATER) (1461cc)",
          "DCI - JSRKF7 WORLD EDITION (1461cc)",
          "DCI RXE - JSR2W4 (7 SEATER) (1461cc)",
          "DCI RXE - JSRBW4 7 SEATER (1461cc)",
          "DCI RXE - JSRGW4 (1461cc)",
          "DCI RXL - JSRGW4 WORLD EDITION (1461cc)",
          "DCI STEPWAY RXL - JSRGWB (1461cc)",
          "DCI STEPWAY RXZ - JSRGWB (1461cc)",
          "STEPWAY DCI RXL - JSRKF9 8STR (1461cc)",
          "STEPWAY DCI RXZ - JSRDF9 7STR (1461cc)",
          "STEPWAY DCI RXZ - JSRKF9 8STR (1461cc)",
          "RXE 85PS 7S (1461cc)",
          "RXE 85PS 8S (1461cc)",
          "RXL 110PS 7S (1461cc)",
          "RXL 110PS 8S (1461cc)",
          "RXL 110PS 8S LE (1461cc)",
          "RXL 110PS STEPWAY 8S (1461cc)",
          "RXL 85PS 8S (1461cc)",
          "RXL 85PS 8S LE (1461cc)",
          "RXL 85PS STEPWAY 8S (1461cc)",
          "RXZ 110PS 7S LEATHER SEATS (1461cc)",
          "RXZ 110PS 7S WT LEATHER SEATS (1461cc)",
          "RXZ 110PS 8S (1461cc)",
          "RXZ 85PS 8S (1461cc)",
          "RXZ STEPWAY 110PS 7S LDR SEAT (1461cc)",
          "RXZ STEPWAY 8S (1461cc)",
          "STD 85PS 8S (1461cc)",
          "WORLD EDITION RXL 110PS 8S (1461cc)",
          "WORLD EDITION RXL 85PS 8S (1461cc)",
          "BASE (1461cc)",
          "RXL (1461cc)",
          "RXZ (1461cc)",
          "110PS RXL 7 SEATER (1461cc)",
          "110 PS RXL 7 SEATER (1461cc)",
          "RXZ 8 STR (1461cc)",
          "STEPWAY 7 STR (1461cc)",
          "110 PS RXZ 8 SEATER (1461cc)",
          "STEPWAY 110PS RXL 8S (1461cc)",
          "STEPWAY 110PS RXZ 7S (1461cc)",
          "STEPWAY 110PS RXZ 8S (1461cc)",
          "STEPWAY 85PS RXL 8S (1461cc)",
          "STEPWAY 85PS RXZ 8S (1461cc)",
          "WORLD EDITION 110PS (1461cc)",
          "WORLD EDITION 85PS (1461cc)",
          "110 PS RXZ 7 SEATER STEPWAY (1461cc)",
          "110 PS RXZ (1461cc)",
          "STEPWAY EDITION (1461cc)",
          "STANDARD (1461cc)",
          "85 PS RXE 7 SEATER (1461cc)",
          "RXL 85 PS 7 SEATER (1461cc)",
          "RXZ DIESEL 110 PS 8 SEATER (1461cc)",
          "BASE 85 PS 8 SEATER (1461cc)",
          "RXE 85 PS 8 SEATER (1461cc)",
          "RXL 85 PS 8 SEATER (1461cc)",
          "RXL 110 PS 8 SEATER (1461cc)",
          "RXZ 85 PS 8 SEATER (1461cc)",
          "RXZ 110 PS 7 SEATER (1461cc)",
          "RXE 85 PS 7 SEATER (1461cc)",
          "RXL 110 PS 7 SEATER (1461cc)",
          "STEPWAY RXZ 110 PS 7 SEATER (1461cc)",
          "STEPWAY RXZ 110 PS 8 SEATER (1461cc)",
          "STEPWAY RXL 85 PS 8 SEATER (1461cc)",
          "110 PS RXL 7STR (1461cc)",
          "110 PS RXZ STEPWAY 7 STR (1461cc)",
          "110 PS WORLD EDITION 8 STR (1461cc)",
          "85 PS WORLD EDITION 8 STR (1461cc)",
          "110 PS RXL STEPWAY 8 STR (1461cc)",
          "85 PS RXE 8 STR (1461cc)",
          "85 PS RXZ 8 STR (1461cc)",
          "85 PS STD 8 STR (1461cc)",
          "85 PS RXE 7STR (1461cc)",
          "85 PS RXE 7 STR (1461cc)",
          "85PS RXL (1461cc)",
          "85 PS RXL (1461cc)",
          "RXL 110PS STEPWAY 8 STR (1461cc)",
          "STEPWAY RXZ (7 SEATER) (1461cc)",
          "STEPWAY RXZ (8 SEATER) (1461cc)",
          "85 PS RXZ (1461cc)",
          "85PS RXE (1461cc)",
          "110 PS RXZ 7 SEATER (1461cc)",
          "110 PS RXZ 8 STR (1461cc)",
          "110 PS RXZ 7 STR (1461cc)",
          "85PS STD (1461cc)",
          "WORLD EDITION 85 PS 8 SEATER (1461cc)",
          "WORLD EDITION 110 PS 8 SEATER (1461cc)",
          "110 PS RXZ STEPWAY 8 STR (1461cc)",
          "85 PS RXL STEPWAY 8 STR (1461cc)",
          "85 PS RXZ STEPWAY 8 STR (1461cc)",
          "110 PS RXL (1461cc)",
        ],
      },
      {
        model: "KOLEOS",
        variants: [
          "2.0 4X4 (AT) (1995cc)",
          "DIESEL 2.0L (1995cc)",
          "DIESEL M/T 4X2 (1995cc)",
          "DIESEL M/T 4X4 (1995cc)",
          "2.0 4X4 AUTOMATIC TRANSMISION (1995cc)",
          "2.0 DCI (1995cc)",
          "2.0 MT (1995cc)",
          "2.0 AT (1995cc)",
          "2.0 DIESEL 4X2 MANUAL TRANSMISION (1995cc)",
          "2.0 DIESEL 4X4 MANUAL TRANSMISION (1995cc)",
          "2.0 DIESEL 4X4 AUTOMATIC TRANSMISION (1995cc)",
          "4X2 MT (1995cc)",
          "0 (1995cc)",
          "4X4 AT (1995cc)",
          "4X4 (1995cc)",
          "2.5 PETROL RHD (2488 CC) (2488cc)",
        ],
      },
      {
        model: "PULSE",
        variants: [
          "RXE (1198cc)",
          "RXE (1461cc)",
          "RXL AIRBAG (O) (1461cc)",
          "RXZ AIRBAG (O) (1461cc)",
          "DIESEL RXL DCI SINGLE AIRBAG (1468cc)",
          "DIESEL RXL SINGLE AIRBAG (1468cc)",
          "DIESEL RXL SINGLE AIRBAG +ABS (1468cc)",
          "DIESEL RXZ DCI DUAL AIRBAG (1468cc)",
          "DIESEL RXZ DCI SINGLE ABAG (1468cc)",
          "DIESEL RXZ DUAL AIRBAG WITHABS (1468cc)",
          "PETROL RXL WITH DUAL AIRBAG (1198cc)",
          "PETROL RXL WITH SINGLE AIRBAG (1198cc)",
          "PETROL RXZ WITH DUAL AIRBAG (1198cc)",
          "RXL DIESEL SINGLE AIRBAG (1468cc)",
          "RXL DIESEL SINGLE AIRBAG +ABS (1468cc)",
          "RXL SA (1461cc)",
          "RXL ABS (1461cc)",
          "RXL (1198cc)",
          "RXZ (1198cc)",
          "RXZ (1461cc)",
          "RXZ (1461cc)",
          "RXE PETROL (1198cc)",
          "RXE PETROL (1198cc)",
          "RXL SA DIESEL (1461cc)",
          "RXL AIRBAGS OPTIONAL (1461cc)",
          "RXZ AIRBAGS OPTIONAL (1461cc)",
          "RXL (1461cc)",
          "RXL (1461cc)",
          "RXL PETROL (1198cc)",
          "RXL PETROL (1198cc)",
          "RXE DIESEL (1461cc)",
          "RXZ OPTIONAL (1461cc)",
          "RXZ SINGLE AIRBAG (1461cc)",
          "RXL OPTIONAL (1461cc)",
          "DSL RXL SINGLE AIRBAG (1461cc)",
          "RXL ABS DIESEL (1461cc)",
          "RXZ O (1461cc)",
          "RXL DIESEL (1461cc)",
          "RXZ DIESEL (1461cc)",
          "RXZ AIRBAGS (1461cc)",
          "RXL WITH AIRBAGS (1461cc)",
          "RXZ WITH AIRBAGS (1461cc)",
          "RXZ PETROL (1198cc)",
          "RXZ PETROL (1198cc)",
        ],
      },
      {
        model: "KIGER",
        variants: [
          "1.0 TURBO RXZ XTRONIC(CVT)DT (999cc)",
          "1.0 TURBO RXT XTRONIC(O)CVT DT (999cc)",
          "1.0 ENERGY RXL AMT (999cc)",
          "1.0 TURBO RXT (O) MT DT (999cc)",
          "1.0 ENERGY RXZ MT DT (999cc)",
          "1.0 ENERGY RXT (O) MT (999cc)",
          "1.0 TURBO RXT(O) XTRONIC (CVT) (999cc)",
          "1.0 ENERGY RXT(O) EASY-R AMT (999cc)",
          "1.0 TURBO RXT AMT DT (999cc)",
          "1.0 ENERGY RXT(O)EASY-R AMT DT (999cc)",
          "1.0 ENERGY RXT (O) MT DT (999cc)",
          "1.0 TURBO RXT(O)MT (999cc)",
          "1.0 ENERGY RXZ EASY-R AMT DT (999cc)",
          "1.0 TURBO RXZ MT DT (999cc)",
          "1.0 ENERGY RXT EASY R AMT (999cc)",
          "1.0 TURBO RXL MT (999cc)",
          "1.0 ENERGYÂ RXZ MT (999cc)",
          "1.0 ENERGY RXZ EASY-R AMT (999cc)",
          "1.0 TURBOÂ RXZ MT (999cc)",
          "1.0 TURBO RXZ X-TRONIC CVT (999cc)",
          "1.0 ENERGY RXT MT (999cc)",
          "1.0 ENERGY RXL MT (999cc)",
          "1.0 ENERGY RXE MT (999cc)",
          "RXE MT BS6 (999cc)",
          "RXE MT DUAL TONE BS6 (999cc)",
          "RXL MT BS6 (999cc)",
          "RXL MT DUAL TONE BS6 (999cc)",
          "RXL AMT BS6 (999cc)",
          "RXT MT BS6 (999cc)",
          "RXL AMT DUAL TONE BS6 (999cc)",
          "RXT MT DUAL TONE BS6 (999cc)",
          "RXT AMT BS6 (999cc)",
          "RXL 1.0 TURBO MT BS6 (999cc)",
          "RXT AMT DUAL TONE BS6 (999cc)",
          "RXL 1.0 TURBO MT DUAL TONE BS6 (999cc)",
          "RXZ MT BS6 (999cc)",
          "RXT 1.0 TURBO MT BS6 (999cc)",
          "RXZ MT DUAL TONE BS6 (999cc)",
          "RXT 1.0 TURBO MT DUAL TONE BS6 (999cc)",
          "RXZ AMT BS6 (999cc)",
          "RXZ AMT DUAL TONE BS6 (999cc)",
          "RXT TURBO CVT BS6 (999cc)",
          "RXZ 1.0 TURBO MT DUAL TONE BS6 (999cc)",
          "RXT TURBO CVT DUAL TONE BS6 (999cc)",
          "RXZ TURBO CVT BS6 (999cc)",
          "RXZ TURBO CVT DUAL TONE BS6 (999cc)",
          "RXZ 1.0 TURBO MT BS6 (999cc)",
          "RXT 1.0 X TRONIC TURBO BS6 (999cc)",
          "RXZ 1.0 X TRONIC TURBO BS6 (999cc)",
          "RXT O TURBO CVT BS6 (999cc)",
          "1.0 ENERGY EASY-R RXT MT (O) (999cc)",
          "1.0 ENERGY RXL EASY R AMT DUAL TONE (999cc)",
          "1.0 ENERGY RXT MT DUAL TONE (999cc)",
          "1.0 ENERGY RXT EASY-R AMT DUAL TONE (999cc)",
          "1.0 TURBOÂ RXL MT DUAL TONE (999cc)",
          "1.0 TURBO RXT MT (999cc)",
          "1.0 ENERGYÂ RXZ MT DUAL TONE (999cc)",
          "1.0 TURBOÂ RXT MT DUAL TONE (999cc)",
          "1.0L ENERGY RXZ EASY-R AMT DUAL TONE (999cc)",
          "1.0 TURBOÂ RXT X-TRONIC CVT (999cc)",
          "1.0 TURBO RXZ MT DUAL TONE (999cc)",
          "1.0 TURBOÂ RXT X-TRONIC CVT DUAL TONE (999cc)",
          "1.0 TURBO RXZ X-TRONIC CVT DUAL TONE (999cc)",
          "1.0 ENERGY RXL EASY R AMT (999cc)",
          "1.0 ENERGY RXL MT DUAL TONE (999cc)",
          "1.0 ENERGY RXE MT DUAL TONE (999cc)",
          "1.0 TURBO RXZ X-TRONIC (999cc)",
          "1.0 ENERGY RXT MT (O) (999cc)",
          "1.0 TURBO RXZ X-TRONIC CVT OPTION (999cc)",
          "1.0 TURBO RXZ X-TRONIC CVT DUAL TONE OPTION (999cc)",
          "1.0 TURBOÂ RXT X-TRONIC CVT OPTION (999cc)",
          "1.0 TURBOÂ RXT X-TRONIC CVT DUAL TONE OPTION (999cc)",
          "1.0 TURBOÂ RXT (O) DUAL TONE (999cc)",
          "1.0 TURBOÂ RXT (O) (999cc)",
          "1.0 ENERGY EASY-R RXT AMT (O) DUAL TONE (999cc)",
          "1.0 ENERGY RXT MT (O)  DUAL TONE (999cc)",
          "1.0 TURBO RXZ AMT (999cc)",
          "1.0 ENERGY EASY-R RXT AMT (O) (999cc)",
          "RXT O TURBO CVT DUAL TONE (999cc)",
          "RXZ 1.0 TURBO MT (999cc)",
          "RXL 1.0 TURBO MT (999cc)",
          "RXL 1.0 TURBO MT DUAL TONE (999cc)",
          "RXT 1.0 TURBO MT (999cc)",
          "RXT 1.0 TURBO MT DUAL TONE (999cc)",
          "RXZ 1.0 TURBO MT DUAL TONE (999cc)",
          "1LTR RXZ TURBO CVT (999cc)",
          "RXT OPTION PETROL MT 1.0 L ECE (999cc)",
          "KIGER RXT PETROL MT 10 ECE (999cc)",
          "RXT PETROL X-TRONIC 1.0L TURBO (999cc)",
          "RXT (O) CVT TURBO DT (999cc)",
          "RXZ PERTOL X TRONIC 1.0 L (999cc)",
          "RXT O TURBO MT DUAL TONE (999cc)",
          "RXZ MT 1.0 ECE (999cc)",
          "RXT O TURBO MT (999cc)",
          "RXZ TURBO MT DUAL TONE (999cc)",
          "RXL TURBO MT DUAL TONE (999cc)",
          "RXT TURBO MT DUAL TONE (999cc)",
          "RXT O TURBO CVT (999cc)",
          "RXL AMT DUAL TONE (999cc)",
          "RXL MT DUAL TONE (999cc)",
          "RXT AMT DUAL TONE (999cc)",
          "RXT MT DUAL TONE (999cc)",
          "RXZ AMT DUAL TONE (999cc)",
          "RXZ MT DUAL TONE (999cc)",
          "RXE MT DUAL TONE (999cc)",
          "RXT (O) AMT DUAL TONE. (999cc)",
          "RXT O AMT DUAL TONE (999cc)",
          "RXT O MT (999cc)",
          "RXT O MT DUAL TONE (999cc)",
          "RXT (O) AMT. (999cc)",
          "RXT O AMT (999cc)",
          "RXE DUAL TONE MT (999cc)",
          "RXL DUAL TONE MT (999cc)",
          "RXL TURBO DUAL TONE MT (999cc)",
          "RXT DUAL TONE AMT (999cc)",
          "RXT DUAL TONE MT (999cc)",
          "RXT TURBO DUAL TONE MT (999cc)",
          "RXZ DUAL TONE AMT (999cc)",
          "RXZ DUAL TONE MT (999cc)",
          "RXZ TURBO DUAL TONE MT (999cc)",
          "RXT TURBO MT (999cc)",
          "RXL TURBO MT (999cc)",
          "RXZ TURBO MT (999cc)",
          "RXE MT (999cc)",
          "RXL AMT (999cc)",
          "RXL MT (999cc)",
          "RXT AMT (999cc)",
          "RXT MT (999cc)",
          "RXZ AMT (999cc)",
          "RXZ MT (999cc)",
          "RXT TURBO CVT (999cc)",
          "RXT TURBO CVT DUAL TONE (999cc)",
          "RXZ TURBO CVT (999cc)",
          "RXZ TURBO CVT DUAL TONE (999cc)",
        ],
      },
      {
        model: "CAPTUR",
        variants: [
          "RXE (1461cc)",
          "RXE (1498cc)",
          "RXL (1461cc)",
          "RXL (1498cc)",
          "RXT (1461cc)",
          "RXT (1498cc)",
          "RXT MONO (1461cc)",
          "RXT MONO (1498cc)",
          "PLATINE DIESEL MT (1461cc)",
          "PLATINE DIESEL MT. (1498cc)",
          "PLATINE DIESEL MT MONO (1461cc)",
          "RXE DIESEL MT (1461cc)",
          "RXE PETROL MT (1498cc)",
          "RXL DIESEL MT (1461cc)",
          "RXL PETROL MT (1498cc)",
          "RXT DIESEL MT (1461cc)",
          "RXT DIESEL MT MONO (1461cc)",
          "RXT DUAL TONE (1498cc)",
          "RXT DUAL TONE (1461cc)",
          "RXT PETROL MT (1498cc)",
          "RXE PETROL 2019 (1498cc)",
          "PLATINE DUAL TONE 2019 (1498cc)",
          "PLATINE DUAL TONE 2019 (1461cc)",
          "RXE DIESEL 2019 (1461cc)",
          "PLATINE DUAL TONE (1498cc)",
          "PLATINE (1461cc)",
          "PLATINE MONO (1461cc)",
          "PLATINE DIESEL DUAL TONE (1461cc)",
          "1.5 RXE (1498cc)",
          "1.5 RXE (1461cc)",
          "1.5 RXL (1498cc)",
          "1.5 RXL (1461cc)",
          "1.5 RXT MONO (1498cc)",
          "1.5 RXT MONO (1461cc)",
          "1.5 RXT (1461cc)",
          "1.5 PLATINE (1461cc)",
          "1.5 PLATINE MONO (1461cc)",
          "STD (1461cc)",
          "1.5 RXE DIESEL (1461cc)",
          "1.5 RXL DIESEL (1461cc)",
          "1.5 RXT MONO DIESEL (1461cc)",
          "1.5 RXT DIESEL (1461cc)",
          "1.5 PLATINE MONO DIESEL (1461cc)",
          "1.5 PLATINE DIESEL (1461cc)",
          "RXT MT PETROL (1461cc)",
          "PLATINE DIESEL (1461cc)",
          "PLATINE MONO DIESEL (1461cc)",
          "RXT MONO DIESEL (1461cc)",
          "PLATINE DIESEL MT DUAL TONE (1461cc)",
          "PLATINE DIESEL MT MONO TONE (1461cc)",
          "RXT DIESEL MONO TONE (1461cc)",
          "RXE DIESEL (1461cc)",
          "RXL DIESEL (1461cc)",
          "RXT DIESEL (1461cc)",
          "RXT DIESEL DUAL TONE (1461cc)",
          "1.5 RXL PETROL (1498cc)",
          "1.5 RXT MONO PETROL (1498cc)",
          "1.5 RXT PETROL (1498cc)",
          "1.5 RXE PETROL (1498cc)",
          "1.5 PLATINE PETROL (1498cc)",
          "RXT MONO PETROL (1498cc)",
          "RXT PETROL (1498cc)",
          "RXT PETROL MONO TONE (1498cc)",
          "RXE PETROL (1498cc)",
          "RXL PETROL (1498cc)",
          "PLATINE PETROL DUAL TONE (1498cc)",
          "RXT PETROL DUAL TONE (1498cc)",
        ],
      },
      {
        model: "TRIBER",
        variants: [
          "RXL AMT BSVI (999cc)",
          "RXZ AMT MY 21 BSVI (999cc)",
          "LIMITED EDITION BSVI (999cc)",
          "RXT AMT BSVI (999cc)",
          "RXZ (DT) BSVI (999cc)",
          "LIMITED EDITION BSVI (AMT) (999cc)",
          "RXZ (DT) (AMT) BSVI (999cc)",
          "RXL BS-VI (999cc)",
          "RXT BS-VI (999cc)",
          "RXZ BS-VI (999cc)",
          "RXE BS-VI (999cc)",
          "RXE BSVI (999cc)",
          "LIMITED EDITION MT (999cc)",
          "CLIMBER 1.0 MT (O) BSVI (999cc)",
          "RXZ MT (999cc)",
          "RXT LIMITED EDITION (999cc)",
          "RXT EASY R LIMITED EDITION (999cc)",
          "RXL AMT (999cc)",
          "RXZ EASY-R DUALTONE (999cc)",
          "RXZ EASY-R (999cc)",
          "RXT EASY-R (999cc)",
          "RXT AMT (999cc)",
          "RXZ AMT (999cc)",
          "RXT PETROL MT 1.0L ECE (999cc)",
          "RXZ PETROL MT 1.0L ECE (999cc)",
          "RXL PETROL MT 1.0L ECE (999cc)",
          "LIMITED EDITION 2022 MT MY21 LE 1.0L (999cc)",
          "LIMITED EDITION 2022 AMT MY21 LE EASY 1.0L (999cc)",
          "RXZ (999cc)",
          "RXZ DUALTONE (999cc)",
          "RXZ DUAL TONE (999cc)",
          "RXE (999cc)",
          "RXL (999cc)",
          "RXT (999cc)",
          "RXZ DUAL TONE PETROL (999cc)",
          "RXL PETROL (999cc)",
          "RXE PETROL (999cc)",
          "RXZ PETROL (999cc)",
          "RXT PETROL (999cc)",
          "LIMITED EDITION (999cc)",
          "RXL EASY-R AMT (999cc)",
          "LIMITED EDITION AMT (999cc)",
          "RXZ EASY-R AMT DUAL TONE (999cc)",
          "RXT EASY-R AMT (999cc)",
          "RXZ EASY-R AMT (999cc)",
        ],
      },
      {
        model: "VINTAGE",
        variants: ["AX BHP (1060cc)", "NN1 (950cc)", "TOWN CAR (2400cc)"],
      },
      {
        model: "REINASTELLA",
        variants: ["VINTAGE (4257cc)"],
      },
      {
        model: "SAFRANE",
        variants: ["2.5L (2499cc)"],
      },
      {
        model: "RILOTE",
        variants: ["EXPLORATEUR P 715 FJ (2987cc)"],
      },
      {
        model: "SANDERO",
        variants: ["STEPWAY (1461cc)"],
      },
      {
        model: "RN 19",
        variants: ["0 (2000cc)", "0 (2000cc)"],
      },
      {
        model: "MEGANE SCENIC",
        variants: ["2.0 PRIVILEGE (1998cc)"],
      },
      {
        model: "KANGOO",
        variants: ["RHS (1598cc)"],
      },
      {
        model: "VEL SATIS",
        variants: ["0 (2200cc)"],
      },
    ],
  },
  {
    make: "BHARAT BENZ",
    models: [
      {
        model: "1017 CHS",
        variants: ["0 (3907cc)"],
      },
    ],
  },
  {
    make: "MASERATI",
    models: [
      {
        model: "QUATTROPORTE",
        variants: [
          "QUATTROPORTE (AT) (2987cc)",
          "GTS (3799cc)",
          "S (4691cc)",
          "S FL 4.7 (4691cc)",
          "S GTS 4.7 (4691cc)",
          "GT 4.7 (4691cc)",
          "SPORT GT 4.7 (4691cc)",
          "S 4.7 5STR (4691cc)",
          "S 4.7 (4691cc)",
          "S (2979cc)",
          "STANDARD (2979cc)",
          "350 GRANLUSSO (2979cc)",
          "MODENA (2979cc)",
          "3.0L S (2979cc)",
          "3.0L STANDARD (2979cc)",
          "S (2987cc)",
          "S Q4 (2987cc)",
          "DIESEL BS VI (2987cc)",
          "GRAN LUSSO BS VI (2987cc)",
          "GRAN SPORT BS VI (2987cc)",
          "GT (2987cc)",
          "GRAN SPORT 3.0L DIESEL (2987cc)",
          "MODENA (2987cc)",
          "GRAN SPORT (2987cc)",
          "GRAN LUSSO (2987cc)",
          "DIESEL (2987cc)",
          "FL 4.2 (4224cc)",
          "GTS GRAN LUSSO BS VI (3799cc)",
          "GTS GRAN SPORT BS VI (3799cc)",
          "TROFEO (3799cc)",
          "GTS GRAN LUSSO (3799cc)",
          "GTS GRAN SPORT (3799cc)",
          "4.2 AT (4244cc)",
          "STD (4244cc)",
          "4.2 (4244cc)",
          "4.2 5STR (4244cc)",
        ],
      },
      {
        model: "GRAN TURISMO",
        variants: [
          "MC (4691cc)",
          "4.2 L COUPE (4244cc)",
          "4.2 (4244cc)",
          "4.7 V8 SPORT (4691cc)",
          "4.7 V8 MC (4691cc)",
          "S 4.7 AUTO (4691cc)",
          "S 4.7 (4691cc)",
          "4.7 (4691cc)",
          "S AUTOMATIC (4691cc)",
          "MC STRADALE (4691cc)",
          "SPORT AUTO (4691cc)",
          "4.2 AUTO (4224cc)",
          "4.2 V8 (4224cc)",
        ],
      },
      {
        model: "GRAN CABRIO",
        variants: [
          "4.7 V8 RHD (4691cc)",
          "V8 (4691cc)",
          "CONVERTIBLE (4691cc)",
          "4.7 (4691cc)",
        ],
      },
      {
        model: "LAVANTE",
        variants: [
          "GRAN LUSSO (2989cc)",
          "DIESEL (2987cc)",
          "350 GRANSPORT (2979cc)",
          "430 GRANLUSSO (2979cc)",
          "430 GRANSPORT (2979cc)",
        ],
      },
      {
        model: "LEVANTE",
        variants: [
          "STD (2987cc)",
          "DIESEL (2987cc)",
          "DIESEL BS VI (2987cc)",
          "V6 TURBO DIESEL (2987cc)",
          "STD (2979cc)",
          "350 GRANLUSSO (2979cc)",
          "S 430 GS (2979cc)",
          "GRANLUSSO BS VI (2979cc)",
          "GRANSPORT BS VI (2979cc)",
          "GRANLUSSO PETROL 350 (2979cc)",
          "GRANLUSSO PETROL 430 (2979cc)",
          "GRANSPORT PETROL 350 (2979cc)",
          "GRANSPORT PETROL 430 (2979cc)",
          "PETROL 350 (2979cc)",
          "PETROL 430 (2979cc)",
          "GRANSPORT (2979cc)",
          "GRANLUSSO (2979cc)",
          "GRANLUSSO PETROL (2979cc)",
          "GRANLUSSO 350 PETROL (2979cc)",
          "GT HYBRID (2979cc)",
          "MODENA (2979cc)",
          "MODENA S (2979cc)",
          "S (2979cc)",
          "STANDARD (2979cc)",
          "TROFEO... (2979cc)",
          "TROFEO (2979cc)",
        ],
      },
      {
        model: "GHIBLI",
        variants: [
          "STD (2987cc)",
          "DIESEL (2987cc)",
          "DIESEL BS VI (2987cc)",
          "GRANLUSSO BS VI (2987cc)",
          "GRANSPORT BS VI (2987cc)",
          "V6 GRANLUSSO (2987cc)",
          "V6 GRANSPORT (2987cc)",
          "V6 DIESEL (2987cc)",
          "GRANLUSSO (2987cc)",
          "GRANSPORT (2987cc)",
          "HYBRID 330 (1995cc)",
          "MODENA S (2979cc)",
          "S (2979cc)",
          "STANDARD (2979cc)",
          "GT HYBRID (1998cc)",
          "HYBRID BASE (1998cc)",
          "HYBRID GRANLUSSO (1998cc)",
          "HYBRID GRANSPORT (1998cc)",
          "TROFEO (3799cc)",
        ],
      },
      {
        model: "M 138",
        variants: ["0 (4244cc)"],
      },
      {
        model: "MC20",
        variants: ["COUPE (3000cc)"],
      },
    ],
  },
  {
    make: "FORCE",
    models: [
      {
        model: "FORCE ONE",
        variants: [
          "EX BSIV (2650cc)",
          "AC (2149cc)",
          "SX ABS 7 SEATER (2149cc)",
          "SX (2149cc)",
          "SX (2149cc)",
          "2.2 (2149cc)",
          "2.2 SEATER 6 (2149cc)",
          "2.2 SEATER 7 (2149cc)",
          "EX (2149cc)",
          "SX 6 STR (2149cc)",
          "SX 7 STR (2149cc)",
          "SX ABS 6 STR (2149cc)",
          "SX ABS 6 STR (2149cc)",
          "SX ABS 7 STR (2149cc)",
          "SX ABS 7 STR (2149cc)",
          "LX ABS 7 STR (2149cc)",
          "LX ABS 7 STR (2149cc)",
          "LX 4X4 (2200cc)",
          "LX (2200cc)",
          "LX (2200cc)",
          "4X2 (2200cc)",
          "SX 6 STR (2200cc)",
          "EX (1405cc)",
          "EX 7 STR (2650cc)",
        ],
      },
      {
        model: "GURKHA",
        variants: [
          "XPEDITION HT 4X2 (2596cc)",
          "2.6 CR 4X4 BSVI (2596cc)",
          "XPEDITION (2596cc)",
          "XPLORER (2596cc)",
          "EXPLORER 7 STR (2596cc)",
          "EXPLORER 8 STR (2596cc)",
          "EXPLORER 9 STR (2596cc)",
          "XPEDITION 5 DOOR (2596cc)",
          "XPEDITION 5 DOOR (2596cc)",
          "XPLORER 5 DOOR (2596cc)",
          "XPLORER 5 DOOR (2596cc)",
          "XPEDITION 3 DOOR (2596cc)",
          "XPEDITION 3 DOOR (2596cc)",
          "HARD TOP 4X2 (2596cc)",
          "HARD TOP 4X2 (2596cc)",
          "XPLORER 3 DOOR (2596cc)",
          "XPLORER 3 DOOR (2596cc)",
          "XPLORER 3 DOOR ABS (2596cc)",
          "XPLORER 3 DOOR ABS (2596cc)",
          "XPLORER 5 DOOR ABS (2596cc)",
          "XPLORER 5 DOOR ABS (2596cc)",
          "2.6 AWD DSL (2596cc)",
          "HARD TOP 4X4 (2596cc)",
          "SOFT TOP 4X4 (2596cc)",
          "SOFT TOP 4X2 (2596cc)",
          "4X2 SOFT TOP (2596cc)",
          "GURKHA 4X4 HARD TOP (2596cc)",
          "4X4 SOFT TOP (2596cc)",
          "4X4 HARD TOP (2596cc)",
          "4X2 HARD TOP (2596cc)",
          "XPLORER 4X4 3DOOR (2596cc)",
          "4X4 4STR (2596cc)",
          "XTREME ABS (2149cc)",
          "XTREME ABS (2149cc)",
          "XTREME (2149cc)",
          "XTREME 4X4 (2149cc)",
        ],
      },
      {
        model: "TRAX",
        variants: [
          "CRUSIER 10STR (2596cc)",
          "CRUSIER CLASSIC 10STR (2596cc)",
          "GAMA CLASSIC 10STR (2596cc)",
          "GAMA DX 10STR (2596cc)",
          "GURKHA 4X4 HARD TOP (2596cc)",
          "GURKHA 4X4 SOFT TOP (2596cc)",
          "TOOFAN CLASSIC 12STR (2596cc)",
          "CRUSIER 10 SEATER (2596cc)",
          "CRUSIER CLASSIC 10 SEATER (2596cc)",
          "GAMA CLASSIC 10 SEATER (2596cc)",
          "GAMA DX 10 SEATER (2596cc)",
          "TOOFAN CLASSIC 12 SEATER (2596cc)",
          "GAMA DX 5 SEATER (2569cc)",
          "TOOFAN 12 SEATER (2596cc)",
          "TOOFAN 13 SEATER (2596cc)",
          "11 SEATER/SCH VAN (2596cc)",
          "TOOFAN BSVI (2570cc)",
          "CRUISER DELUXE (2596cc)",
          "CRUSIER CLASSIC 14 SEATER (2596cc)",
          "CRUSIER CLASSIC (2596cc)",
          "GAMA DX (2596cc)",
          "CRUSIER (2596cc)",
          "TOOFAN (2596cc)",
          "14 STR (2596cc)",
          "2.6 DELUX 10 STR (2596cc)",
          "CRUISER 11 STR (2596cc)",
          "CRUISER 13 STR (2596cc)",
          "CRUISER CLASSIC 12 STR (2596cc)",
          "CRUSIER 9 STR (2596cc)",
          "GURKHA 4*4 BS III (2596cc)",
          "CRUISER TAXI STR 6 (2596cc)",
          "HARD TOP (6+D) (2596cc)",
          "CRUISER DI (2596cc)",
          "TOOFAN CLASSIC (2596cc)",
          "CHALLENGER (2596cc)",
          "GAMA DLX (2596cc)",
          "GURKHA HARD TOP (2596cc)",
          "GURKHA SOFT TOP (2596cc)",
          "GAMA CLASSIC (2596cc)",
          "CRUISER CLASSIC (2596cc)",
          "GAMA CLASSIC DLX (2600cc)",
          "TOOFAN (1947cc)",
          "2.0 GAMA PS 6 STR (1947cc)",
          "CRUISER BS IV (1947cc)",
          "CRUISER CLASSIC ABS PS AC (1947cc)",
          "CRUISER CLASSIC FM 2.0 CR ABS (1947cc)",
          "CRUISER CLASSIC FM 2.0 CR ABS PS (1947cc)",
          "2.0 GAMA PS 10 STR (1497cc)",
          "CRUISER CLASSIC PS (2526cc)",
        ],
      },
      {
        model: "TEMPO",
        variants: ["TRAVELLER (2200cc)", "TRAX (2596cc)"],
      },
      {
        model: "TRAX CRUISER",
        variants: [
          "12 SEATER/DIESEL (2596cc)",
          "9 SEATER/BS-III/DIESEL (2596cc)",
          "5 SEATER/BS-III/DIESEL (2596cc)",
          "13 SEATER/BS-III/DIESEL (2596cc)",
          "13 SEATER CRUISER (2596cc)",
          "13 SEATER/DIESEL (1947cc)",
          "CRUISER (2596cc)",
          "14 SEATER CRUISER (2596cc)",
          "16 SEATER CRUISER (2696cc)",
          "11 SEATER/DIESEL (1947cc)",
          "15 SEATER CRUISER (2596cc)",
          "8 SEATER/DIESEL (2596cc)",
          "17 SEATER BSVI (2570cc)",
          "9 SEATER/DIESEL BSVI (2596cc)",
          "12 SEATER BSIII (2596cc)",
          "9 SEATER BSIII (2596cc)",
          "CLASSIC 10 SEATER BS III (2596cc)",
          "CLASSIC 10 SEATER BS III (2596cc)",
          "CLASSIC 13 SEATER BS III (2596cc)",
          "CLASSIC 13 SEATER BS III (2596cc)",
          "CLASSIC PS 10 SEATER BS III (2596cc)",
          "CLASSIC PS 10 SEATER BS III (2596cc)",
          "CLASSIC PS 14 SEATER BS III (2596cc)",
          "CLASSIC PS 14 SEATER BS III (2596cc)",
          "CLASSIC PS ABS 14 SEATER BS III (2596cc)",
          "CLASSIC PS ABS 14 SEATER BS III (2596cc)",
          "12 SEATER BS VI (2596cc)",
          "12 SEATER BS VI (2596cc)",
          "9 SEATER BS VI (2596cc)",
          "9 SEATER BS VI (2596cc)",
          "12 SEATER BS IV (2596cc)",
          "12 SEATER BS IV (2596cc)",
          "9 SEATER BS IV (2596cc)",
          "9 SEATER BS IV (2596cc)",
          "12 SEATER AC (2596cc)",
          "9 SEATER AC (2596cc)",
          "0 (2596cc)",
          "BS III (2596cc)",
          "FM 2.6 AC PS ABS 13 STR (2596cc)",
          "STD (1900 CC) (1900cc)",
          "FM 2.0 CR AC PS (1947cc)",
        ],
      },
      {
        model: "TRAVELLER",
        variants: [
          "TEMPO TRAVELLER (2596cc)",
          "13 SEATER/PS AC (2596cc)",
          "10 SEATER/DIESEL (2596cc)",
          "14 SEATER/DELUXE (2596cc)",
          "13 SEATER (2596cc)",
          "3050 (2596cc)",
          "10 STR 2596 CC (2596cc)",
          "3050 WB BS4 (2596cc)",
          "5 STR (2596cc)",
          "8 STR (2596cc)",
          "BS III 10 STR (2596cc)",
          "BS III 13 STR (2596cc)",
          "DI (2596cc)",
          "T1 PS ABS BS IV 10 STR (2596cc)",
          "T1 PS ABS BS IV 6 STR (2596cc)",
          "T1 PS ABS BS IV CNG (2596cc)",
          "TD2650 13 STR (2596cc)",
          "TD2650 14 STR (2596cc)",
          "TD2650 9 STR (2596cc)",
          "TD2650 BSII STD (2596cc)",
          "0 (2596cc)",
          "T1 STATION WAGON(HR) (2596cc)",
          "3350 WB TD2650 BSIV AC PS ABS (2596cc)",
          "26 4020WB BSIII TD2200 (2596cc)",
          "3050 WB 14 STR (2596cc)",
          "T2 CARAVAN SHELL 4020 PS ABS (2596cc)",
          "10 STR (2399cc)",
          "TD2650 10 STR (2399cc)",
          "TD2650 15 STR (2399cc)",
          "TD2650 7 STR (2399cc)",
          "14 STR (2149cc)",
          "BS IV (2149cc)",
          "T2 27 STR (2149cc)",
          "T2 PS ABS BS IV (2149cc)",
          "AC+PS BSIII (3500cc)",
        ],
      },
      {
        model: "TRAX TOOFAN",
        variants: [
          "11 SEATER/BS-III/DIESEL (2596cc)",
          "11 SEATER BSIII (2596cc)",
          "11 SEATER BS VI (2596cc)",
          "11 SEATER BS VI (2596cc)",
          "11 SEATER BS IV (2596cc)",
          "11 SEATER BS IV (2596cc)",
          "FM 2.0 CR BSIV ABS (1947cc)",
        ],
      },
      {
        model: "URBANIA",
        variants: ["3615 WB BSVI (2596cc)", "17 SEATER/DIESEL BSVI (2596cc)"],
      },
      {
        model: "TRAVELLER ROYALE",
        variants: ["15 SEATER/BS-IV (2596cc)", "17 SEATER/CNG (2596cc)"],
      },
      {
        model: "TRAVELLER SUPER",
        variants: ["9 SEATER/BS-IV (2596cc)"],
      },
      {
        model: "TRAVELLER SMOOTH",
        variants: ["SMOOTH (2596cc)", "17 SEATER BSVI (2596cc)"],
      },
      {
        model: "CITILINE",
        variants: ["11 SEATER/SCH VAN (2596cc)", "13 SEATER BSVI (2100cc)"],
      },
      {
        model: "TRAVELLER T2",
        variants: [
          "19 SEATER/SCH VAN (2149cc)",
          "16 SEATER/DIESEL (2149cc)",
          "19 SEATER/CNG (2149cc)",
          "15 SEATER/DIESEL (2149cc)",
          "15 SEATER/DELUXE (2149cc)",
          "12 SEATER/DIESEL (2596cc)",
          "22 SEATER/DIESEL BSVI (2149cc)",
        ],
      },
      {
        model: "TRUMP 40",
        variants: ["17 SEATER/SCH VAN (2956cc)"],
      },
      {
        model: "TRAVELLER TD22",
        variants: [
          "14 SEATER/DIESEL (2149cc)",
          "17 SEATER/DIESEL (2596cc)",
          "11 SEATER/DIESEL (2149cc)",
        ],
      },
      {
        model: "TRAVELLER T1",
        variants: [
          "15 SEATER/DIESEL (2149cc)",
          "16 SEATER/DIESEL (2596cc)",
          "9 SEATER/DIESEL (2149cc)",
          "9 SEATER/CNG (2149cc)",
          "17 SEATER/DIESEL (2596cc)",
          "14 SEATER BSVI (2596cc)",
          "12 SEATER/DIESEL BSVI (2596cc)",
          "14 SEATER/CNG BSVI (2596cc)",
          "17 SEATER/CNG BSVI (2596cc)",
          "12 SEATER/BSVI (2596cc)",
          "21 SEATER/DIESEL BSVI (2149cc)",
          "13 SEATER/DIESEL BSVI (2149cc)",
        ],
      },
      {
        model: "TRAX CRUISER FM 2.6",
        variants: ["12 SEATER BSVI (2596cc)"],
      },
      {
        model: "TRAVELLER MINI BUS",
        variants: [
          "17 SEATER/DIESEL BSVI (2596cc)",
          "20 SEATER/DIESEL BSVI (2596cc)",
        ],
      },
      {
        model: "TRAVELLER T1 3050",
        variants: ["13 SEATER/DELUXE BSVI (2596cc)"],
      },
      {
        model: "TRAVELLER T1 4020",
        variants: ["15 SEATER/CNG BSVI (2596cc)"],
      },
      {
        model: "TOOFAN",
        variants: [
          "CLASSIC (2586cc)",
          "CLASSIC BS IV (1947cc)",
          "CLASSIC BSIV. (2956cc)",
          "CLASSIC DUAL AC PS DELUX (2523cc)",
          "CLASSIC (2523cc)",
        ],
      },
      {
        model: "FORCE ONE.",
        variants: ["0 (2149cc)"],
      },
      {
        model: "TEMPO TRAVELLER",
        variants: [
          "OMNI VAN (2800cc)",
          "0 (2596cc)",
          "CARAVAN (2596cc)",
          "5 SEATER (2142cc)",
        ],
      },
      {
        model: "TRIP",
        variants: [
          "7 SEATER (600cc)",
          "TD 600 6STR (600cc)",
          "TD 600 7STR (600cc)",
          "PASSANGER STR 7 (600cc)",
          "PASSANGER STR 8 (600cc)",
          "(6+D) BSIII (600cc)",
          "0 (600cc)",
        ],
      },
      {
        model: "TEMPO TRAX TOOFAN",
        variants: [
          "DELUXE 11 SEATER BS IV (2596cc)",
          "DELUXE 12 SEATER BS IV (2596cc)",
          "DELUXE 9 SEATER BS IV (2596cc)",
          "DELUXE 11 SEATER BS VI (2596cc)",
          "DELUXE 11 SEATER BS VI (2596cc)",
          "DELUXE 12 SEATER BS VI (2596cc)",
          "DELUXE 12 SEATER BS VI (2596cc)",
          "DELUXE 9 SEATER BS VI (2596cc)",
          "DELUXE 9 SEATER BS VI (2596cc)",
          "CLASSIC EGR (2596cc)",
          "12STR (2596cc)",
        ],
      },
      {
        model: "CRUISER",
        variants: [
          "CASH VAN STR 6 (2650cc)",
          "BSIII NA DI 4X2 (2596cc)",
          "CLASSIC FM2.6CR (2596cc)",
          "0 (2596cc)",
          "CLASSIC (2596cc)",
          "CLASSIC 10STR (2596cc)",
        ],
      },
      {
        model: "MINIDOR",
        variants: [
          "MINIDOOR CB STR 4 (510cc)",
          "TEMPO STR 6 (1500cc)",
          "STR 3 (356cc)",
          "STR 5 (350cc)",
        ],
      },
      {
        model: "TEMPO TRAX CRUISER",
        variants: ["10 STR (2596cc)", "9 STR (2596cc)", "EIII 9STR (1850cc)"],
      },
      {
        model: "TEMPO TRAX",
        variants: ["12 STR (2596cc)", "0 (2399cc)"],
      },
      {
        model: "F307",
        variants: ["METADOR (2399cc)"],
      },
      {
        model: "TEMPO TRAX GAMA",
        variants: [
          "9 SEATER BS IV (1947cc)",
          "9 SEATER BS IV (1947cc)",
          "5 SEATER BS IV (1947cc)",
          "5 SEATER BS IV (1947cc)",
        ],
      },
      {
        model: "JUDO",
        variants: ["DLX EII (2750cc)"],
      },
      {
        model: "TEMPO TRAX CHALLENGER",
        variants: ["9+D BS IV (2596cc)", "9+D BS IV (2596cc)"],
      },
      {
        model: "RE",
        variants: ["STR 7 (350cc)"],
      },
      {
        model: "TRAVELLER T3",
        variants: ["52 SEATER/DIESEL BSVI (2149cc)"],
      },
      {
        model: "TRAVELLER DI HR BSII 10STR",
        variants: ["0 (2596cc)"],
      },
      {
        model: "TRAVELLER MEGA BUS 7STR",
        variants: ["0 (1998cc)"],
      },
      {
        model: "TEMPO TRAVELLER 5STR",
        variants: ["0 (3595cc)"],
      },
      {
        model: "TEMPO TRAVELLER MEGA BUS 3050MM",
        variants: ["0 (2596cc)"],
      },
      {
        model: "TRAVELLER MINI BUS 12STR",
        variants: ["0 (2536cc)"],
      },
      {
        model: "GAMA CLASSIC",
        variants: ["0 (2596cc)"],
      },
      {
        model: "TEMPO TRAVELLER 14STR",
        variants: ["0 (2596cc)"],
      },
      {
        model: "TEMPO TRAVELLER 3050",
        variants: [
          "12SEATER/BS-IV/DIESEL (2596cc)",
          "12SEATER/BS-III VE/DIESEL (2596cc)",
          "9SEATER/BS-III VE/DIESEL (2596cc)",
          "9SEATER/BS-IV/DIESEL (2149cc)",
          "12SEATER/BS-III/DIESEL (2149cc)",
          "9SEATER/BS-III/DIESEL (2149cc)",
        ],
      },
      {
        model: "TEMPO TRAVELLER 3350",
        variants: [
          "12SEATER/BS-III/DIESEL (2596cc)",
          "14SEATER/TD 2650 FTI ED/DIESEL (2596cc)",
          "13 SEATER/CNG (2596cc)",
          "14 SEATER/CNG (2596cc)",
          "21 SEATER/DIESEL BSVI (2596cc)",
          "14SEATER/BS-III/DIESEL (2149cc)",
          "12SEATER/BS-IV/DIESEL (2149cc)",
          "14SEATER/BS-IV/DIESEL (2149cc)",
          "12SEATER/TD 2650 FTI ED/DIESEL (2149cc)",
        ],
      },
      {
        model: "TEMPO TRAVELLER 3700",
        variants: [
          "17SEATER/BS-IV/DIESEL (2596cc)",
          "16 SEATER/DIESEL (2596cc)",
          "16 SEATER/CNG (2596cc)",
          "17 SEATER/DIESEL (2596cc)",
          "17 SEATER/CNG (2596cc)",
          "21 SEATER/DIESEL BSVI (2596cc)",
          "12 SEATER/DIESEL BSVI (2596cc)",
          "17 SEATER/DIESEL BSVI (2596cc)",
        ],
      },
      {
        model: "TEMPO TRAVELLER 4020",
        variants: [
          "19SEATER/BS-IV/DIESEL (2149cc)",
          "20 SEATER/DIESEL BSVI (2596cc)",
          "20 SEATER/CNG BSVI (2596cc)",
        ],
      },
      {
        model: "TEMPO TRAVELLER RANGE 10",
        variants: ["T- 2200 (2149cc)"],
      },
      {
        model: "TEMPO TRAVELLER 10",
        variants: ["0 (2596cc)"],
      },
      {
        model: "TRAVELLER 26 4020WB TD 2200 16 STR",
        variants: ["0 (2596cc)"],
      },
      {
        model: "TRAVELLER T2 TD 2200 4020 WB AC PS 13 STR",
        variants: ["0 (2149cc)"],
      },
      {
        model: "TRAVELLER T1 AC PS",
        variants: ["0 (2149cc)"],
      },
    ],
  },
  {
    make: "ICML",
    models: [
      {
        model: "EXTREME",
        variants: [
          "LD CRD-FI (1994cc)",
          "LD DI (1994cc)",
          "SD CRDI-FI (1994cc)",
          "SD DI (1994cc)",
          "VD DI (1994cc)",
          "9 SEATER/SCH VAN (1994cc)",
          "6 SEATER/SCH VAN (1994cc)",
          "LD (1994cc)",
          "SD (1994cc)",
          "VD (1994cc)",
          "SCHOOL VAN (1994cc)",
          "LD CRD-FI 10 SEATER (1994cc)",
          "SD DI 9 SEATER (1994cc)",
          "LD DI 8 SEATER (1994cc)",
          "LD CRD-FI 7 SEATER (1994cc)",
          "SD CRD-FI 8 SEATER (1994cc)",
          "SD DI 7 SEATER (1994cc)",
          "SD CRD-FI 7 SEATER (1994cc)",
          "LD DI 9 SEATER (1994cc)",
          "LD CRD-FI 8 SEATER (1994cc)",
          "LD DI 7 SEATER (1994cc)",
          "LD CRD-FI 9 SEATER (1994cc)",
          "VD DI  7 SEATER (1994cc)",
          "SD CRD-FI 9 SEATER (1994cc)",
          "SD DI 9 SEATER BS III (1994cc)",
          "LD CRDFI NON AC 9 SEATER BS IV (1994cc)",
          "LD CRDFI PS AC 9 SEATER BS IV (1994cc)",
          "LD DI NON AC 9 SEATER BS III (1994cc)",
          "LD DI PS AC 9 SEATER BS III (1994cc)",
          "SD CRDFI 9 SEATER BS IV (1994cc)",
          "VD CRDFI 7 SEATER BS IV (1994cc)",
          "DI 8STR (1994cc)",
          "LD 10STR (1994cc)",
          "LD 7STR (1994cc)",
          "LD 8STR (1994cc)",
          "SD 7STR (1994cc)",
          "SD 8STR (1994cc)",
          "VD 7STR (1994cc)",
          "DI BS III (1994cc)",
          "LD PS AC DI BS III 9 SEATER (1994cc)",
          "LD NON AC DI BS III 9 SEATER (1994cc)",
          "SD CRDFI BS IV 9 SEATER (1994cc)",
          "LD PS AC CRDFI BS IV 9 SEATER (1994cc)",
          "LD PS AC CRDFI BS III 9 SEATER (1994cc)",
          "VD DI BS III 7 SEATER (1994cc)",
          "SD CRDFI BS III 9 SEATER (1994cc)",
          "SD DI BS III 9 SEATER (1994cc)",
          "LD NON AC CRDFI BS IV 9 SEATER (1994cc)",
          "VD CRDFI BS IV 7 SEATER (1994cc)",
          "VD CRDFI BS III 7 SEATER (1994cc)",
        ],
      },
      {
        model: "RHINO RX",
        variants: [
          "7 SEATER/SCH VAN (1994cc)",
          "RX S1 NON-AC (1994cc)",
          "RX DLX (1994cc)",
          "RX S1 AC (1994cc)",
          "RX S2 (1994cc)",
          "ROYAL CRDFI (1994cc)",
          "S1 (1994cc)",
          "DELITE CRDFI 7 STR BS IV (1994cc)",
          "ROYALE CRDFI 7 STR BS III (1994cc)",
          "ROYALE CRDFI 8 STR BS IV (1994cc)",
          "WINNER CRDFI 10 STR BS IV (1994cc)",
          "WINNER CRDFI 9 STR BS IV (1994cc)",
          "WINNER CRDFI 9 STR BS III AC (1994cc)",
          "WINNER CRDFI 9 STR BS III NON AC (1994cc)",
          "WINNER CRDFI 9 STR BS IV WITH PS (1994cc)",
          "WINNER DI NON AC MS 9 STR BS III (1994cc)",
          "XCITER 8 STR BS III (1994cc)",
          "XCITER 9 STR BS III (1994cc)",
          "XCITER CRDFI 8 STR BS IV (1994cc)",
          "XCITER CRDFI 9 STR BS IV (1994cc)",
          "WINNER DI PS AC 9 STR BS III (1994cc)",
          "WINNER CRDI AC 9STR BS IV (1994cc)",
          "XCITER DI DAC 9 STR BS III (1994cc)",
          "XCITER CRDFI DAC 9 STR BS III (1994cc)",
          "DELIGHT DI DAC 7 STR BS III (1994cc)",
          "XCITER CRDI DAC 9 STR BS IV (1994cc)",
          "DELIGHT CRDI DAC 7 STR BS IV (1994cc)",
          "XCITER DI 2.0 CRDFI 8 SEATER (1994cc)",
          "WINNER DI 2.0 DI A/C & P.S. 9 SEATER (1994cc)",
          "WINNER DI 2.0 DI A/C 10 SEATER (1994cc)",
          "2.0 CRDFI 7 SEATER (1994cc)",
          "ROYAL 2.0 CRDFI 7 SEATER (1994cc)",
          "WINNER CRDFI A/C  MT 10 SEATER (1994cc)",
          "S1 9 SEATER (1994cc)",
          "S2 9 SEATER (1994cc)",
          "S2 8 SEATER (1994cc)",
          "WINNER DI 2.0 DI NON A/C 9 SEATER (1994cc)",
          "XCITER CRDFI 2.0 CRDFI 8 SEATER (1994cc)",
          "S1 10 SEATER (1994cc)",
          "WINNER CRDFI 2.0 CRDFI 9 SEATER (1994cc)",
          "CRDFI 7 STR (1994cc)",
          "S2 8STR (1994cc)",
          "S2 9STR (1994cc)",
          "DLX (1994cc)",
          "S1 AC (1994cc)",
          "DLX DOUBLE AC (1994cc)",
          "S1 NON AC (1994cc)",
          "S2 (1994cc)",
          "DLX 8 SEATER (2609cc)",
          "DLX 7 SEATER (2609cc)",
          "S2 (2000cc)",
        ],
      },
      {
        model: "WINNER",
        variants: [
          "2.0 DI A/C & P.S. 9 SEATER (1994cc)",
          "2.0 DI NON A/C 9 SEATER (1994cc)",
          "2.0 DI A/C 10 SEATER (1994cc)",
          "2.0 CRDFI 9 SEATER (1994cc)",
        ],
      },
      {
        model: "XCITER",
        variants: ["2.0 CRDFI 8 SEATER (1994cc)"],
      },
      {
        model: "XCITER DI",
        variants: ["2.0 CRDFI 8 SEATER (1994cc)"],
      },
      {
        model: "DELITE",
        variants: ["2.0 CRDFI 7 SEATER (1994cc)"],
      },
      {
        model: "ROYALE",
        variants: ["2.0 CRDFI 7 SEATER (1994cc)"],
      },
    ],
  },
  {
    make: "MCLAREN",
    models: [
      {
        model: "720 S",
        variants: ["(IMPORTED) (3800cc)", "COUPE (3994cc)", "SPIDER (3994cc)"],
      },
      {
        model: "SPORTS SERIES",
        variants: ["540C (3799cc)"],
      },
      {
        model: "SENNA",
        variants: ["720 S (IMPORTED) (3999cc)"],
      },
      {
        model: "GT",
        variants: ["COUPE (3994cc)"],
      },
      {
        model: "620R",
        variants: ["COUPE (3799cc)"],
      },
      {
        model: "570 S",
        variants: ["COUPE (3799cc)"],
      },
      {
        model: "720S",
        variants: ["COUPE (3994cc)", "SPIDER (3994cc)", "SPIDER (3944cc)"],
      },
    ],
  },
  {
    make: "DATSUN",
    models: [
      {
        model: "GO PLUS",
        variants: [
          "ANNIVERSERY EDITION (1198cc)",
          "T CVT (1198cc)",
          "REMIX EDITION (1198cc)",
          "T (1198cc)",
          "T (1198cc)",
          "A EPS (1198cc)",
          "T O (1198cc)",
          "D1 (1198cc)",
          "D (1198cc)",
          "A (1198cc)",
          "T OPTION (1198cc)",
          "STYLE (1198cc)",
          "A 5 STR (1198cc)",
          "1.2 REMIX PETROL (1198cc)",
          "1.2 T (O) PETROL 68 PS (1198cc)",
          "1.2 A EPS PETROL 68 PS (1198cc)",
          "1.2 T CVT (1198cc)",
          "1.2 T (O) CVT (1198cc)",
          "1.2 T CVT W/ VDC (1198cc)",
          "1.2 T (O) CVT W/ VDC (1198cc)",
          "1.2 A (O) PETROL 68 PS (1198cc)",
          "1.2 D PETROL 68 PS (1198cc)",
          "1.2 D1 PETROL 68 PS (1198cc)",
          "1.2 A PETROL 68 PS (1198cc)",
          "1.2 T PETROL 68 PS (1198cc)",
          "ACTIVE MULTIJET (1198cc)",
          "T (O) CVT W VDC (1198cc)",
          "T CVT W VDC (1198cc)",
          "STYLE EDITION (1198cc)",
          "D XE (1198cc)",
          "T XV(O) (1198cc)",
          "T XV (1198cc)",
          "REMIX LIMITED EDITION (1198cc)",
          "T O CVT (1198cc)",
          "T W VDC (1198cc)",
          "T (O) W VDC (1198cc)",
          "A O (1198cc)",
          "ANNIVERSARY EDITION (1198cc)",
        ],
      },
      {
        model: "GO",
        variants: [
          "T (O) BS VI (1198cc)",
          "ANNIVERSERY EDITION (1198cc)",
          "REMIX EDITION (1198cc)",
          "A EPS (1198cc)",
          "T O (1198cc)",
          "D1 (1198cc)",
          "A (1198cc)",
          "D (1198cc)",
          "T (1198cc)",
          "T OPTION (1198cc)",
          "T/ CNG (1198cc)",
          "1.2 REMIX PETROL (1198cc)",
          "1.2 ANNIVERSARY EDITION (1198cc)",
          "1.2 T CVT W/ VDC (1198cc)",
          "1.2 T (O) CVT W/ VDC (1198cc)",
          "1.2 NXT PETROL 68 PS (1198cc)",
          "1.2 T (O) PETROL 68 PS (1198cc)",
          "1.2 A EPS PETROL 68 PS (1198cc)",
          "1.2 D PETROL 68 PS (1198cc)",
          "1.2 D1 PETROL 68 PS (1198cc)",
          "1.2 A PETROL 68 PS (1198cc)",
          "1.2 T PETROL 68 PS (1198cc)",
          "T W VDC (1198cc)",
          "T CVT W VDC (1198cc)",
          "T (O) CVT W VDC (1198cc)",
          "T (O) W VDC (1198cc)",
          "A O (1198cc)",
          "T CVT (1198cc)",
          "T O CVT (1198cc)",
          "STYLE EDITION (1198cc)",
          "REMIX LIMITED EDITION (1198cc)",
          "GO NEXT (1198cc)",
          "ANNIVERSARY EDITION (1198cc)",
          "NXT (1198cc)",
        ],
      },
      {
        model: "REDI GO",
        variants: [
          "LIMITED EDITION (799cc)",
          "T O (799cc)",
          "1.0 S (999cc)",
          "SPORT (799cc)",
          "1.0 S AMT (999cc)",
          "1.0 T O AMT (999cc)",
          "D (799cc)",
          "T (799cc)",
          "A (799cc)",
          "S (799cc)",
          "GOLD LIMITED EDITION (999cc)",
          "1.0 T O (999cc)",
          "GOLD (999cc)",
          "T OPTION (799cc)",
          "T(O) (999cc)",
          "T OPTION 1.0 LTR (999cc)",
          "S 1.0 LTR (999cc)",
          "1.0 T OPTION AMT (999cc)",
          "1.0 T OPTION (999cc)",
          "AMT (999cc)",
          "1.0 T O BS-VI (999cc)",
          "1.0 GOLD PETROL (999cc)",
          "1.0 T(O) PETROL (999cc)",
          "1.0 S PETROL (999cc)",
          "1.0 S AMT PETROL (999cc)",
          "1.0 T(O) AMT PETROL (999cc)",
          "T O 1.0L LIMITED EDITION (999cc)",
          "SPORT PETROL 54 PS (799cc)",
          "S(O) PETROL 54 PS (799cc)",
          "T PETROL 54 PS (799cc)",
          "S PETROL 54 PS (799cc)",
          "T(O) PETROL 54 PS (799cc)",
          "D PETROL 54 PS (799cc)",
          "A PETROL 54 PS (799cc)",
          "T O 0.8L LIMITED EDITION (799cc)",
        ],
      },
      {
        model: "BLUEBIRD",
        variants: ["D10 (860cc)"],
      },
    ],
  },
  {
    make: "CITROEN",
    models: [
      {
        model: "C3",
        variants: [
          "1.2 LIVE BSVI (1198cc)",
          "1.2 FEEL BSVI (1198cc)",
          "1.2 FEEL DT BSVI (1198cc)",
          "1.2 FEEL VIBE PACK BSVI (1198cc)",
          "1.2 FEEL VIBE PACK DT BSVI (1198cc)",
          "1.2 FEEL TURBO VIBE DT BSVI (1198cc)",
          "PURETECH 82 LIVE BSVI (1198cc)",
          "PURETECH 82 FEEL BSVI (1198cc)",
          "PURETECH 82 FEEL DT BSVI (1198cc)",
          "PURETECH 82 SHINE BSVI (1198cc)",
          "PURETECH 82 SHINE DT BSVI (1198cc)",
          "FEEL DT TURBO BSIV (1198cc)",
          "PURETECH 110 FEEL BSVI (1198cc)",
          "SHINE DT TURBO BSVI (1198cc)",
          "FEEL ELECTRIC (42000cc)",
          "1.2 SHINE (1198cc)",
          "1.2 SHINE VIBE PACK (1198cc)",
          "1.2 SHINE DUAL TONE (1198cc)",
          "1.2 SHINE VIBE PACK DUAL TONE (1198cc)",
          "1.2 SHINE PURETECH 82 5MT CC 1198 (1198cc)",
          "1.2 SHINE PURETECH DUAL TONE 82 5MT CC 1198 (1198cc)",
          "1.2 LIVE PURETECH 82 5MT CC 1198 (1198cc)",
          "1.2 FEEL PURETECH 82 5MT CC 1198 (1198cc)",
          "1.2 FEEL PURETECH DUAL TONE 5MT CC 1198 (1198cc)",
          "SHINE 1.2 PETROL (1198cc)",
          "SHINE 1.2 PETROL DUAL TONE (1198cc)",
          "SHINE 1.2 PETROL VIBE PACK (1198cc)",
          "SHINE 1.2 PETROL VIBE PACK DUAL TONE (1198cc)",
          "FEEL 1.2 PETROL (1198cc)",
          "LIVE 1.2 PETROL (1198cc)",
          "FEEL 1.2 PETROL VIBE PACK DUAL TONE (1198cc)",
          "FEEL 1.2 PETROL VIBE PACK (1198cc)",
          "FEEL 1.2 PETROL DUAL TONE (1198cc)",
          "1.2 FEEL DUAL TONE (P) (1198cc)",
          "1.2 FEEL VIBE PACK DUAL TONE (P) (1198cc)",
          "1.2 FEEL VIBE PACK (P) (1198cc)",
          "1.2 LIVE (P) (1198cc)",
          "1.2 FEEL (P) (1198cc)",
          "1.2 FEEL TURBO DUAL TONE (1199cc)",
          "1.2 SHINE TURBO DUAL TONE (1199cc)",
          "1.2 SHINE TURBO VIBE PACK DUAL TONE (1199cc)",
          "1.2 FEEL PURETECH 110 6MT CC 1199 (1199cc)",
          "FEEL 1.2 TURBO VIBE PACK (1199cc)",
          "1.2 L BS-VI (1199cc)",
          "FEEL 1.2 PETROL TURBO DUAL TONE (1199cc)",
          "SHINE 1.2 PETROL TURBO DUAL TONE (1199cc)",
          "SHINE 1.2 PETROL TURBO VIBE PACK DUAL TONE (1199cc)",
          "FEEL 1.2 PETROL TURBO VIBE PACK DUAL TONE (1199cc)",
          "1.2 FEEL TURBO VIBE PACK DUAL TONE (P) (1199cc)",
        ],
      },
      {
        model: "EC3",
        variants: [
          "1.2 LIVE BSVI (1198cc)",
          "1.2 FEEL BSVI (1198cc)",
          "1.2 FEEL VIBE PACK BSVI (1198cc)",
          "1.2 FEEL VIBE PACK DT BSVI (1198cc)",
          "FEEL (1198cc)",
          "LIVE AT (42000cc)",
          "FEEL AT (42000cc)",
          "FEEL VIBE PACK AT (42000cc)",
          "FEEL VIBE PACK DUAL TONE AT (42000cc)",
          "ELECTRIC (42cc)",
          "LIVE (42cc)",
          "FEEL VIBE PACK (42cc)",
          "FEEL VIBE PACK DUAL TONE (42cc)",
          "FEEL (42cc)",
        ],
      },
      {
        model: "VINTAGE",
        variants: [
          "VINTAGE CAR (1973cc)",
          "CITRON (1600cc)",
          "CITROEN 2CV (602cc)",
          "VINTAGE (1911cc)",
        ],
      },
      {
        model: "C5 AIRCROSS",
        variants: [
          "FEEL (1997cc)",
          "SHINE (1997cc)",
          "2.0 DIESEL FEEL AT (1997cc)",
          "2.0 DIESEL SHINE AT (1997cc)",
          "2.0 SHINE DUAL TONE (1997cc)",
          "2.0 SHINE (1997cc)",
          "2.0 FEEL (1997cc)",
          "2.0 FEEL DUAL TONE (1997cc)",
          "FEEL DUAL TONE (1997cc)",
          "SHINE DUAL TONE (1997cc)",
        ],
      },
      {
        model: "E C3",
        variants: [
          "LIVE LI 29.2 KWH 42KW (1200cc)",
          "FEEL LI 29.2 KWH 42KW (1200cc)",
          "FEEL VIBE PACK DUAL TONE LI 29.2 KWH 42KW (1200cc)",
          "FEEL VIBE PACK LI 29.2 KWH 42KW (1200cc)",
        ],
      },
      {
        model: "C4",
        variants: ["1.6 HDI FAP STYLE (1560cc)"],
      },
      {
        model: "CITROEN FIRST",
        variants: ["0 (360.2cc)"],
      },
      {
        model: "CITROEN SECOND",
        variants: ["0 (1000cc)"],
      },
      {
        model: "CITROEN THIRF",
        variants: ["0 (600.2cc)"],
      },
      {
        model: "CITROEN 1001",
        variants: ["0 (1497cc)"],
      },
      {
        model: "CITROEN SUPER CAR",
        variants: ["0 (1497cc)"],
      },
      {
        model: "CITROEN PRATIK",
        variants: ["0 (1497cc)"],
      },
      {
        model: "C5",
        variants: ["AIRCROSS (1997cc)"],
      },
    ],
  },
  {
    make: "CADILLAC",
    models: [
      {
        model: "ESCALADE",
        variants: [
          "6.2L (IMPORTED) (6199cc)",
          "AWD 6.2 L V8 (6200cc)",
          "PREMIUM 8 SPEED AT (6200cc)",
          "7 STR (6200cc)",
        ],
      },
      {
        model: "V 16",
        variants: ["CLASSIC (6208cc)"],
      },
      {
        model: "VINTAGE",
        variants: [
          "V 16 (5000cc)",
          "CALAIS (5700cc)",
          "CADILLAC (7200cc)",
          "CADILLAC (6400cc)",
          "CADILLAC. (2500cc)",
          "CADILLAC (7417cc)",
          "CADILLAC (3127cc)",
          "CADILLAC (2664cc)",
          "CADILLAC (5781cc)",
          "CADILLAC (512cc)",
          "V8 SPORT PHAETON (5588cc)",
          "16 ROADSTER (1825cc)",
          "30 FIVE (6000cc)",
          "K VICTORIA RUNABOUT (1600cc)",
          "CONVERTIBLE (5500cc)",
          "CONVERTIBLE (5981cc)",
          "CONVERTIBLE (5120cc)",
          "CADILAC (5670cc)",
          "CADILAC (1560cc)",
          "355 D (5785cc)",
          "DEVILLE (7025cc)",
          "CTS (3249cc)",
          "STS (3598cc)",
          "CUSTOM (5424cc)",
        ],
      },
      {
        model: "SPORTS LUX STS",
        variants: ["(RHD) (3564cc)"],
      },
      {
        model: "CADILLAC FLEETWOOD CAR",
        variants: ["0 (4200cc)"],
      },
      {
        model: "CTS",
        variants: ["STD (3600cc)"],
      },
      {
        model: "XLR",
        variants: ["STD (4600cc)"],
      },
      {
        model: "FLEETWOOD CAR",
        variants: ["0 (4200cc)"],
      },
    ],
  },
  {
    make: "MINI",
    models: [
      {
        model: "MINI COOPER CONVERTIBLE",
        variants: [
          "S COUNTRY MAN (1998cc)",
          "2.0 S 141 KW (1998cc)",
          "2.0 S 141 KW 4 SEATER (1998cc)",
          "1.6 L 90 KW R571.6 L 90 KW FKE1 B28 (1598cc)",
          "1.6 L 90 KW R57 (1598cc)",
        ],
      },
      {
        model: "MINI COOPER D COUNTRYMAN",
        variants: [
          "2.0 L R60 HIGH (AT) (1998cc)",
          "2.0 L LCI (AT) (1995cc)",
          "2.0 L R60 AUTO HIGH (1998cc)",
          "1.6 L AUTO (1598cc)",
          "1.6 L AUTO HIGH (1598cc)",
          "2.0 L LCI (1995cc)",
          "2.0 L R60 AUTO HIGH (1995cc)",
          "1.5  LCI AUTO DIESEL (1495cc)",
        ],
      },
      {
        model: "MINI COOPER CABRIO",
        variants: [
          "1.6 (1598cc)",
          "2.0 SD (1995cc)",
          "1.6L 90 KW 4 SEATER (1598cc)",
        ],
      },
      {
        model: "MINI COOPER S COUNTRYMAN",
        variants: [
          "1.6 135 KW (1598cc)",
          "1.6 HIGH 135 KW (1598cc)",
          "2.0 SD (1995cc)",
        ],
      },
      {
        model: "MINI COOPER",
        variants: ["S (1998cc)"],
      },
      {
        model: "MINI COOPER COUNTRYMAN",
        variants: [
          "DIESEL (1995cc)",
          "HIGH DIESEL (1995cc)",
          "2.0 COOPER S JCW INSPIRED PETROL (1998cc)",
        ],
      },
      {
        model: "COOPER S",
        variants: [
          "1.6L HATCH (1598cc)",
          "1.6 HIGH HATCH R60 (1598cc)",
          "1.6 HIGH HATCH R60 (1598cc)",
          "1.6L HATCH R56 (1598cc)",
          "COUNTRYMAN 1.6L HIGH (1598cc)",
          "1.6L HATCH R60 (1598cc)",
          "COUNTRYMAN 1.6L (1598cc)",
          "1.6 L 135 KW HATCH R56 4 SEAT (1598cc)",
          "1.6 L 135 KW HATCH 4 SEAT (1598cc)",
          "1.6 (1598cc)",
          "1.6 (1598cc)",
          "1.6 HIGH (1598cc)",
          "1.6 HIGH (1598cc)",
          "1.6 HIGH (1598cc)",
          "1.6 HATCH (1598cc)",
          "1.6 HATCH R56 (1598cc)",
          "1.6 HATCH R60 (1598cc)",
          "1.6 L 135 KW HATCH R60 (1598cc)",
          "1.6 L 135 KW HATCH R56 (1598cc)",
          "1.6 L HIGH 135 KW HATCH R60 (1598cc)",
          "1.6 L 135 KW HATCH (1598cc)",
          "2 (1998cc)",
          "2.0 HATCH 3 DOOR (1998cc)",
          "2.0 COOPER S CLUBMAN AUTO 4 SEAT (1998cc)",
          "2.0 PETROL CONVERTIBLE (1998cc)",
          "3 DOOR (1998cc)",
          "3 DOOR (1998cc)",
          "3 DOOR OXFORD EDITION (1998cc)",
          "HATCH 3 DOOR (1998cc)",
          "STEPTRONIC SPORT (1998cc)",
          "F56 (1998cc)",
          "CLUBMAN (1998cc)",
          "CLUBMAN (1998cc)",
          "3 DOOR CARBON EDITION (1998cc)",
          "CARBON EDITION (1998cc)",
          "3 DOOR HATCH (1998cc)",
          "PRO EDITION (1998cc)",
          "OXFORD EDITION (1998cc)",
          "2.0 OXFORD (1995cc)",
        ],
      },
      {
        model: "COOPER COUNTRYMAN",
        variants: [
          "ONE  1.6 L 72 KW R 60 ONE AC (1600cc)",
          "2.0 COOPER S F60 PETROL (1998cc)",
          "2.0 COOPER S PETROL (1998cc)",
          "2.0 COOPER S JCW INSPIRED PETROL (1998cc)",
          "COOPER S (1998cc)",
          "COOPER S (1998cc)",
          "COOPER S JCW INSPIRED (1998cc)",
          "COOPER S JCW INSPIRED (1998cc)",
          "2.0 COOPER SD DIESEL (1995cc)",
          "COOPER SD (1995cc)",
          "D  2.0 L R60 AUTO HIGH (1995cc)",
          "2.0L COOPER D (1995cc)",
          "COOPER D (1995cc)",
          "S  1.6 L 135 KW (1598cc)",
          "S  1.6 L HIGH 135 KW (1598cc)",
          "S  1.6 L HIGH 135 KW (1598cc)",
          "ONE (1598cc)",
          "1.6 (1598cc)",
          "COOPER D HIGH (1598cc)",
          "COOPER S (1598cc)",
          "COOPER S (1598cc)",
          "COOPER D (1598cc)",
        ],
      },
      {
        model: "COOPER CABRIO",
        variants: ["1.6L (1598cc)", "1.6L 90 KW (1598cc)", "1.6 (1598cc)"],
      },
      {
        model: "COUNTRYMAN",
        variants: [
          "1.6L AUTO (1598cc)",
          "1.6L AUTO HIGH (1598cc)",
          "ONE (1598cc)",
          "2.0 R60 AUTO HIGH (1995cc)",
          "COOPER D (1995cc)",
          "COOPER SD (1995cc)",
          "COOPER D (1998cc)",
          "COOPER D HIGH (1998cc)",
          "COOPER S JCW INSPIRED BLACK EDITION (1998cc)",
        ],
      },
      {
        model: "COOPER",
        variants: [
          "1.6 D 5 DOOR F56 (1598cc)",
          "1.6 D 5 DOOR F55 (1598cc)",
          "1.6 L 90 KW HATCH R56 (1598cc)",
          "1.6 L 90 KW HATCH R56 (1598cc)",
          "1.6 (1598cc)",
          "1.6 (1598cc)",
          "CABRIO BS IV (1598cc)",
          "1.6 HATCH (1598cc)",
          "SE ELECTRIC (1598cc)",
          "1.6 L 90 KW HATCH (1598cc)",
          "1.6 L 90 KW HATCH (1598cc)",
          "1.6 D 5 DOOR (1598cc)",
          "HATCH (1598cc)",
          "1.6L (1598cc)",
          "S (1598cc)",
          "1.4 D 3 DOOR HATCH WITH AT (1496cc)",
          "1.4 D 5 DOOE HATCH WITH AT 4 SEATER (1496cc)",
          "1.4 D 3 DOOR HATCH WITH AT 4 SEATER (1496cc)",
          "D 3 DOOR (1496cc)",
          "D 3 DOOR (1496cc)",
          "D 5 DOOR (1496cc)",
          "D 5 DOOR (1496cc)",
          "D 3 DOOR 4STR (1496cc)",
          "D 3 DOOR 4 STR (1496cc)",
          "D-5 DOOR HATCH 4STR (1496cc)",
          "D (1496cc)",
          "1.4 D 5 DOOR HATCH WITH AT (1496cc)",
          "ELECTRIC SE 3 DOOR 135 KW 4 SEAT (1500cc)",
          "2.0 PETROL JCW HATCH (1998cc)",
          "3 DOOR (1998cc)",
          "D COUNTRYMAN (1998cc)",
          "SC WAT (1998cc)",
          "PADDY HOPKIRK EDITION (1998cc)",
          "JCW (1998cc)",
          "S (1998cc)",
          "3-DOOR COOPER SE (137cc)",
          "D 5 DOOR (1995) (1995cc)",
          "SE 3 DOOR (4000cc)",
        ],
      },
      {
        model: "COOPER CONVERTIBLE",
        variants: [
          "1.6L R57 (1598cc)",
          "1.6 L  R57 (1598cc)",
          "1.6 L  R57 (1598cc)",
          "1.6 R57 (1598cc)",
          "1.6 (1598cc)",
          "1.6 (1598cc)",
          "1.6 L 90 KW R57 (1598cc)",
          "1.6L (1598cc)",
          "S (1998cc)",
          "S STEPTRONIC SPORT (1998cc)",
          "COVERTIBLE 2.0 (1998cc)",
          "2 (1998cc)",
        ],
      },
      {
        model: "ROVER",
        variants: ["1275 GT (1275cc)", "1275 GT (1275cc)"],
      },
      {
        model: "COOPER CLUBMAN",
        variants: [
          "COOPER S 5 STR (1998cc)",
          "2.0 COOPER S AUTO 4 SEATER (1998cc)",
          "2.0 COOPER S AUTO (1998cc)",
          "INDIAN SUMMER RED EDITION (1998cc)",
          "COOPER S (1998cc)",
        ],
      },
      {
        model: "HATCH",
        variants: ["1.6L (1598cc)", "1.6 LR56 (1598cc)", "5 DOOR (1496cc)"],
      },
      {
        model: "JOHN COOPER",
        variants: [
          "WORKS HATCH (1998cc)",
          "WORKS GP INSPIRED EDITION (1998cc)",
        ],
      },
      {
        model: "PADDY",
        variants: ["HOPKIRK EDITION (1998cc)"],
      },
      {
        model: "COOPER SE",
        variants: ["3 DOOR (137cc)"],
      },
      {
        model: "MINI S",
        variants: ["1.6. (1598cc)"],
      },
    ],
  },
  {
    make: "EICHER POLARIS",
    models: [
      {
        model: "MUTLIX",
        variants: ["MX (510cc)", "AX PLUS (510cc)"],
      },
      {
        model: "MULTIX",
        variants: [
          "AX L (511cc)",
          "AX PLUS (511cc)",
          "AX (511cc)",
          "MX (511cc)",
          "MX 5 SEATER 652 CC (652cc)",
          "AX PLUS 5 SETAER (510cc)",
          "MX 5 SEATER (510cc)",
        ],
      },
    ],
  },
  {
    make: "GENERAL MOTORS",
    models: [
      {
        model: "VINTAGE",
        variants: [
          "HERALD D (865cc)",
          "PONTIAC (3917cc)",
          "BUICK (1408cc)",
          "BUICK (5247cc)",
          "BUICK (3150cc)",
          "BUICK (3000cc)",
          "STATION WAGON (3150cc)",
          "CHRYSLER PLYMOUTH (2364cc)",
          "VINTAGE (1560cc)",
        ],
      },
      {
        model: "SPARK",
        variants: ["1.0 LS (1278cc)", "1.0 LT BEIGE BS4 (1270cc)"],
      },
      {
        model: "CARSA 1.6 RAYELE",
        variants: ["1.0 LS (1598cc)"],
      },
      {
        model: "SIERRA",
        variants: ["2500 HD (6600cc)"],
      },
      {
        model: "ENJOY TCDI LT",
        variants: ["MAXI CAB (1248cc)", "TAXI CAB (1248cc)"],
      },
      {
        model: "CORSA",
        variants: [
          "1.4 GL (1398cc)",
          "1.4 GLS (1398cc)",
          "1.4 GSI (1389cc)",
          "SWING 1.4 (1389cc)",
          "1.4 ELITE (1389cc)",
          "SAIL 1.4 (1389cc)",
          "1.6 GLS (1589cc)",
          "1.6 GSI (1589cc)",
          "1.6 ROYALE (1589cc)",
          "SWING 1.6 (1598cc)",
          "1.6 SWING (1598cc)",
          "SAIL 1.6 (1598cc)",
          "1.4 SWING (1399cc)",
        ],
      },
      {
        model: "ASTRA",
        variants: [
          "1.6 (1598cc)",
          "1.6 ADVANTAGE (1598cc)",
          "1.6 CLUB (1598cc)",
          "1.6 CLUB AT (1598cc)",
          "1.6 GLI (1598cc)",
          "1.6 METRO (1598cc)",
          "1.6 MY99 (1598cc)",
          "1.6 MY99 AT (1598cc)",
          "1.7 ADVANTAGE (1598cc)",
          "1.7 METRO (1598cc)",
          "1.7 MY99 (1598cc)",
          "1.7 CLUB (1686cc)",
          "1.7 GL (1686cc)",
          "1.4 16V ENJOY 5DR (1399cc)",
        ],
      },
      {
        model: "OPEL ASTRA",
        variants: [
          "STD (1199cc)",
          "GSI (1389cc)",
          "CLUB 1.6 PETROL (1589cc)",
          "ADVANTAGE (1599cc)",
          "1.7 GSI DIESEL (1686cc)",
          "CLUB 1.7 DIESEL (1686cc)",
          "GLI 1.7 DIESEL (1686cc)",
          "ADVANTAGE 1.7 TD (1686cc)",
          "1.6 GSI (1598cc)",
          "GLI 1.6 PETROL (1598cc)",
        ],
      },
      {
        model: "VECTRA",
        variants: ["2.2 COMFORT (2198cc)", "DIESEL AT (2198cc)"],
      },
      {
        model: "FIRE BIRD",
        variants: ["0 (2500cc)"],
      },
      {
        model: "OPEL CORSA",
        variants: [
          "GLS 1.6 PETROL (1599cc)",
          "1.4 GLX (1389cc)",
          "1.4  GLS  PETROL (1389cc)",
          "1.4 GSI (1389cc)",
          "1.4 GL (1389cc)",
          "1.6 GSI (1598cc)",
          "CDTI (1248cc)",
        ],
      },
      {
        model: "OPEL SWING",
        variants: ["GLS 1.6 PETROL (1598cc)", "GL 1.6 PETROL (1598cc)"],
      },
      {
        model: "CHEVROLET KALOS",
        variants: ["0 (1399cc)"],
      },
      {
        model: "OPEL VECTRA",
        variants: ["0 (2198cc)"],
      },
      {
        model: "OPEL KAPITAN",
        variants: ["0 (2473cc)"],
      },
    ],
  },
  {
    make: "BYD",
    models: [
      {
        model: "ATTO 3",
        variants: [
          "ATTO 3 (150000cc)",
          "SPECIAL RANGE (150000cc)",
          "EXTENDED RANGE (150000cc)",
          "BASE ELECTRIC AT (1600cc)",
          "SPECIAL EDITION (60.48cc)",
          "EXTENDED RANGE (60.48cc)",
          "SPECIAL RANGE (150cc)",
          "SPECIAL EDITION (150cc)",
          "EXTENDED RANGE (150cc)",
          "ELECTRIC (1990cc)",
          "EXTENDED RANGE (1496cc)",
        ],
      },
      {
        model: "E6",
        variants: [
          "BASE ELECTRIC AT (1200cc)",
          "STANDARD (70000cc)",
          "STANDARD (70cc)",
          "ELECTRIC (1990cc)",
          "ELECTRIC (1cc)",
          "0 (72cc)",
          "0 (1598cc)",
        ],
      },
      {
        model: "M3",
        variants: ["ELECTRIC (2000cc)"],
      },
    ],
  },
  {
    make: "MAHINDRA REVA",
    models: [
      {
        model: "E 2O",
        variants: [
          "T 01 BASE (999cc)",
          "T 2 PREMIUM (999cc)",
          "T 6 LI ION (999cc)",
        ],
      },
      {
        model: "I",
        variants: [
          "A/C (50cc)",
          "STANDARD (50cc)",
          "CLASSE (48cc)",
          "AC (650cc)",
          "CLASSE (650cc)",
          "STANDARD (650cc)",
          "AC (999cc)",
          "CLASSE (999cc)",
          "STANDARD (999cc)",
          "AC (1000cc)",
          "AC (1000cc)",
          "CLASSE (1000cc)",
          "STANDARD (1000cc)",
        ],
      },
      {
        model: "E 20",
        variants: [
          "T20 (800cc)",
          "T2 (800cc)",
          "T 01 BASE (999cc)",
          "T 2 PREMIUM (999cc)",
          "T 2 (999cc)",
          "PLUS P2 (999cc)",
          "T 0 (999cc)",
          "PLUS P4 (999cc)",
          "PLUS P8 (999cc)",
          "PLUS P6 (999cc)",
          "T6 LI ION (999cc)",
        ],
      },
      {
        model: "REVA",
        variants: ["0 (1000cc)"],
      },
      {
        model: "REVA CLASSE",
        variants: ["0 (1000cc)"],
      },
      {
        model: "REVA  ZEPHYR",
        variants: ["0 (1000cc)"],
      },
    ],
  },
  {
    make: "MG CAR COMPANY LTD",
    models: [
      {
        model: "VINTAGE",
        variants: ["MG TD (1250cc)", "2.5 MIDGET (2523cc)", "VINTAGE (1800cc)"],
      },
    ],
  },
  {
    make: "R ROVER",
    models: [
      {
        model: "VINTAGE",
        variants: ["SPORT 3.0L TDV6 (4200cc)"],
      },
    ],
  },
  {
    make: "CHANGSHA BYD AUTO",
    models: [
      {
        model: "M3",
        variants: ["ELECTRIC MINI VAN (200cc)"],
      },
    ],
  },
  {
    make: "DC",
    models: [
      {
        model: "AVANTI",
        variants: [
          "SPORTS CAR (1998cc)",
          "STANDARD (1998cc)",
          "S 125 (1998cc)",
          "310 SPECIAL EDITION (1998cc)",
          "AVANTI (2000cc)",
          "310 SPECIAL EDITION (2000cc)",
        ],
      },
    ],
  },
  {
    make: "MAHINDRA",
    models: [
      {
        model: "BOLERO",
        variants: [
          "DI GLX (2498cc)",
          "CAMPER DX MDI (2498cc)",
          "DI DX (2498cc)",
          "DI SPORTZ (2498cc)",
          "GLX 7 SEATER (2498cc)",
          "INVADER GLX 8 SEATER (2498 CC) (2498cc)",
          "GLX (2498cc)",
          "GLX (2498cc)",
          "DI 2WD (2498cc)",
          "CAMPER GOLD ZX (2498cc)",
          "CAMPER DX 5STR (2498cc)",
          "CAMPER GOLD 5STR (2498cc)",
          "DI GLX 7STR (2498cc)",
          "DI GLX 8STR (2498cc)",
          "DI SPORTZ 7STR (2498cc)",
          "DI SPORTZ 8STR (2498cc)",
          "INVADER GLX 7STR (2498cc)",
          "SLX 4WD 7STR (2498cc)",
          "2.49 CRDE VLX 7STR (2498cc)",
          "CAMPER GOLD VX (2498cc)",
          "GLX 8STR (2498cc)",
          "XLS 8 SEATER (2498cc)",
          "SLX 4WD 7 SEATER (2498cc)",
          "CAMPER DX 5 SEATER (2498cc)",
          "CAMPER GOLD 5 SEATER (2498cc)",
          "VLX 2.49 L CRDE 7 SEATER (2498cc)",
          "DI GLX 7 SEATER (2498cc)",
          "DI GLX 8 SEATER (2498cc)",
          "DI SPORTZ 7 SEATER (2498cc)",
          "DI SPORTZ 8 SEATER (2498cc)",
          "DI DX 8 SEATER (2498cc)",
          "DI DX 8STR (2498cc)",
          "DI LX 8STR (2498cc)",
          "SLX 4WD BSII (2498cc)",
          "2.5 GLX 2WD (2498cc)",
          "XLS  2WD  PS  10 STR (2498cc)",
          "SPORTS DI 7STR (2498cc)",
          "XLS  2WD  9STR (2498cc)",
          "GLX 2WD 7STR (2498cc)",
          "XLS 2WD 7STR (2498cc)",
          "GLX 4WD (2498cc)",
          "GLX 4WD (2498cc)",
          "GLX 2WD (2498cc)",
          "XLS (2498cc)",
          "LX MDIT CNG T 2 WD 7STR BS3 (2498cc)",
          "CAMPER GOLD (2498cc)",
          "VLX (2498cc)",
          "VLX CRDE. (2498cc)",
          "INVADER GLX (2498cc)",
          "INVADER GLX (2498cc)",
          "SLX 4WD (2498cc)",
          "SLX (2498cc)",
          "XLS 8STR (2498cc)",
          "GLX 8 SEATER (2498cc)",
          "XLS 10 SEATER (2498cc)",
          "INVADER GLX 7 SEATER (2498cc)",
          "XLS 9 SEATER (2498cc)",
          "XLS 9STR (2498cc)",
          "XLS 10STR (2498cc)",
          "DI (2498cc)",
          "LX 4WD BS III (2523cc)",
          "LX 4WD BS III (FOR GOVT) (2523cc)",
          "CAMPER 4WD PS BS6 (2523cc)",
          "PLUS 8 STR (2523cc)",
          "XL PLUS 9 SEATER (2523cc)",
          "MDI/ AC BS III 7STR (2523cc)",
          "EX 2WD 8 STR (2523cc)",
          "MDI TC 7 STR (2523cc)",
          "CAMPER 2WD 10 STR. (2523cc)",
          "DI - AC BS III 9 STR (2523cc)",
          "DI 2 WD 9 STR (2523cc)",
          "DI 2WD 7STR (2523cc)",
          "DI 2WD NON AC BS III 7STR (2523cc)",
          "DI 4WD AC BS III (2523cc)",
          "EX 2 WD 7 STR (2523cc)",
          "LX 10 STR (2523cc)",
          "MDI TC 8 STR (2523cc)",
          "SLE BS II 8 STR (2523cc)",
          "SLX 5STR (2523cc)",
          "SLX 8 STR (2523cc)",
          "SLX 8 STR BS3 (2523cc)",
          "SLX 9 STR (2523cc)",
          "GLX 7 SEATER (2523cc)",
          "SLX 4WD 7 SEATER (2523 CC) (2523cc)",
          "DIZ 8 SEATER (2523cc)",
          "DI DX 7 SEATER (2523 CC) (2523cc)",
          "CAMPER GOLD 6 SEATER 2523CC (2523cc)",
          "ZLX 5 SEATER (2523cc)",
          "CAMPER GOLD ZX PS 5 SEAT (2523cc)",
          "ZLX MICROHYBRID AC PS 7 SEATER (2523cc)",
          "XLS 8 SEATER (CC 2523) (2523cc)",
          "CAMPER 4WD PS 5 SEAT (2523cc)",
          "XLS 10 SEATER CC 2523 (2523cc)",
          "MDI TC AC 8 SEATER (2523cc)",
          "CAMPER GOLD VX 5 SEATER (2523cc)",
          "BOLERO PLUS NON AC 5 SEATER (2523cc)",
          "BOLERO PLUS AC 5 SEATER6 (2523cc)",
          "CAMPER 8 SEATER (2523cc)",
          "CAMPER 2WD PS 5 SEAT (2523cc)",
          "SLE 8 SEATER (2523cc)",
          "MDI TC LX 9 SEATER (2523cc)",
          "XLS 9 STR AC MDI TC (2523cc)",
          "DI 4WD AC (2523cc)",
          "DI TC 8 SEATER (2523cc)",
          "PLUS AC PS 9 SEAT (2523cc)",
          "CAMPER GOLD 5 SEATER 2523CC (2523cc)",
          "CAMPER HUBER MDI 5 SEATER (2523cc)",
          "GLX 7 SEATER (2523 CC) (2523cc)",
          "DI CNG 8 SEATER (2523cc)",
          "PLUS AC PS 8 SEAT (2523cc)",
          "XL AC MDI TC 9 SEATER (2523cc)",
          "DI 4WD 7 SEATR (2523cc)",
          "SLX 2WD 7 SEATER AC (2523cc)",
          "MDI TC LX 4WD 7 SEATER (2523cc)",
          "EX AC 7 SEATER (2523cc)",
          "EX NON AC 7 SEATER (2523cc)",
          "EX NON AC 8 SEATER (2523cc)",
          "EX AC 8 SEATER (2523cc)",
          "XLS 7 SEATER (2523cc)",
          "CL 550 (MAJOR) 2WD (2523cc)",
          "CL 550 (MAJOR) 4WD (2523cc)",
          "LX 4WD BS IV (2523cc)",
          "LX 4WD BS IV (2523cc)",
          "DI CNG 2WD RIGID BSIV 8 STR (2523cc)",
          "PLS 9 STR (2523cc)",
          "XL 7 STR NON-AC (2523cc)",
          "XL 7 STR NON-AC (2523cc)",
          "XL 9 STR NON-AC (2523cc)",
          "XL 9 STR NON-AC (2523cc)",
          "DI 4WD BS III (2523cc)",
          "DI 4WD BS III (2523cc)",
          "DI DX 7 STR (2523cc)",
          "DI DX 7 STR (2523cc)",
          "XL 10 STR NON-AC (2523cc)",
          "XL 10 STR NON-AC (2523cc)",
          "XLS 7 STR (2523cc)",
          "XLS 7 STR (2523cc)",
          "DI 7STR (2523cc)",
          "DI 7STR (2523cc)",
          "PLUS AC 10STR (2523cc)",
          "PLUS A/C 10STR (2523cc)",
          "PLUS AC 8STR (2523cc)",
          "PLUS NON AC 10STR (2523cc)",
          "PLUS NON A/C 10STR (2523cc)",
          "CAMPER 5STR (2523cc)",
          "CAMPER DX MDI 5STR (2523cc)",
          "DIZ 7STR (2523cc)",
          "GLX 10STR (2523cc)",
          "INVADER 7STR (2523cc)",
          "INVADER GLX 8STR (2523cc)",
          "LX 4WD NON AC BSIV (2523cc)",
          "LX 8STR (2523cc)",
          "MDI TC DX 7STR (2523cc)",
          "MDI TC DX 8STR (2523cc)",
          "MDI TC LX 7STR (2523cc)",
          "MDI TC LX 8STR (2523cc)",
          "MDI TC SLX 7STR (2523cc)",
          "MDI TC SLX 8STR (2523cc)",
          "PLUS AC 7STR (2523cc)",
          "PLUS NON AC 7STR (2523cc)",
          "PLUS NON AC 8STR (2523cc)",
          "SLX 2WD 7STR (2523cc)",
          "VLX STROM CRDE 7STR (2523cc)",
          "LX 4WD (2523cc)",
          "GLX 8STR (2523cc)",
          "XL 8 STR (2523cc)",
          "GLX 10 SEATER (2523cc)",
          "XL 8 SEATER (2523cc)",
          "PLUS NON AC 7 SEATER (2523cc)",
          "DI 7 SEATER (2523cc)",
          "PLUS AC 7 SEATER (2523cc)",
          "PLUS NON AC 9 SEATER (2523cc)",
          "PLUS AC 10 SEATER (2523cc)",
          "PLUS NON AC 8 SEATER (2523cc)",
          "PLUS NON AC 10 SEATER (2523cc)",
          "SLE 7 SEATER (2523cc)",
          "VLX CRDE 7 SEATER (2523cc)",
          "VLX STROM CRDE 7 SEATER (2523cc)",
          "DI AC 7 SEATER (2523cc)",
          "DI  AC 8 SEATER (2523cc)",
          "DI 8 SEATER (2523cc)",
          "DIZ 7 SEATER (2523cc)",
          "INVADER GLX 8 SEATER (2523cc)",
          "LX 7 SEATER (2523cc)",
          "LX 8 SEATER (2523cc)",
          "MDI TC DX 7 SEATER (2523cc)",
          "MDI TC DX 8 SEATER (2523cc)",
          "MDI TC LX 7 SEATER (2523cc)",
          "MDI TC LX 8 SEATER (2523cc)",
          "MDI TC SLX 7 SEATER (2523cc)",
          "MDI TC SLX 8 SEATER (2523cc)",
          "CAMPER 5 SEATER (2523cc)",
          "DI DX 8 SEATER (2523cc)",
          "DI DX 8 STR (2523cc)",
          "DI DX 8 STR (2523cc)",
          "XL 10 STR (2523cc)",
          "XL 10STR (2523cc)",
          "XL 7 STR (2523cc)",
          "XL 7STR (2523cc)",
          "XL 9 STR (2523cc)",
          "XL 9 STR (2523cc)",
          "DI DX 2WD 7STR (2523cc)",
          "DIZ 2WD 7STR (2523cc)",
          "XLS 2WD 8STR (2523cc)",
          "LX 2WD 7 STR (2523cc)",
          "XL A/C 8 STR BS-3 (2523cc)",
          "XL 2WD 9 STR E-3 (2523cc)",
          "SLE 7STR (2523cc)",
          "SLE 8STR (2523cc)",
          "GLX 2WD 10STR (2523cc)",
          "XL 2WD 9 STR BS-II (2523cc)",
          "CAMPER MDI TC BSII 4WD (2523cc)",
          "DI DX 2WD 8STR (2523cc)",
          "GLX 4WD 7STR (2523cc)",
          "INVADER  DI 2WD 7STR (2523cc)",
          "SLX MDI 8STR BS3 (2523cc)",
          "INVADER DI 4WD 7STR (2523cc)",
          "INVADER DI 4WD 8STR (2523cc)",
          "INVADER  DI 2WD 8STR (2523cc)",
          "EX 7STR AC (2523cc)",
          "EX 8STR NON A/C (2523cc)",
          "EX 8STR AC (2523cc)",
          "DI 10STR (2523cc)",
          "DI 10 STR (2523cc)",
          "DX 7STR (2523cc)",
          "SLX 2WD BS3 7STR (2523cc)",
          "SLX 7 SEATER (2523cc)",
          "EX AC 8 STR (2523cc)",
          "ZLX 7 STR 2WD (2523cc)",
          "XL 10-SEATER NON-AC (2523cc)",
          "XL 7-SEATER NON-AC (2523cc)",
          "LX 4WD NON AC BSIV 7STR (2523cc)",
          "MDI TC NGT 8STR (2523cc)",
          "PLUS 10 SEATER (2523cc)",
          "MDI TC NGT 7STR (2523cc)",
          "ZLX 7 STR (2523cc)",
          "VLX CRDE 2WD NGT 530 R 7STR BS4 (2523cc)",
          "PLUS 7 STR (2523cc)",
          "INVADER DI 8STR (2523cc)",
          "PLUS AC PS 5STR (2523cc)",
          "XLS 2WD 8STR AC PS MDITC-NGT 520 BS3 (2523cc)",
          "CAMPER 4WD PS (2523cc)",
          "PLUS 10 STR (2523cc)",
          "SLX 4WD (2523cc)",
          "SLX 4WD (2523cc)",
          "DI 8STR (2523cc)",
          "DI 8 STR (2523cc)",
          "VLX CRDE 7 STR. (2523cc)",
          "DI AC 8STR (2523cc)",
          "DI AC 7STR (2523cc)",
          "XLS 8STR (2523cc)",
          "LX 7 STR (2523cc)",
          "XL 7 SEATER (2523cc)",
          "XL 9 SEATER (2523cc)",
          "XL 10 SEATER (2523cc)",
          "SLX 2WD 7 SEATER (2523cc)",
          "PLUS AC 9 SEATER (2523cc)",
          "PLUS AC 8 SEATER (2523cc)",
          "CAMPER DX MDI 5 SEATER (2523cc)",
          "INVADER 7 SEATER (2523cc)",
          "XLS 9 SEATER (2523cc)",
          "XLS 9 STR (2523cc)",
          "XLS 9 STR (2523cc)",
          "XLS 10 STR (2523cc)",
          "XLS 10 STR (2523cc)",
          "SLX D70 POWER PLUS (1493cc)",
          "ZLX POWER PLUS (1493cc)",
          "POWER PLUS ZLX 5 STR (1493cc)",
          "B2 BS6 (1493cc)",
          "B 4 (1493 CC) 7 SEATER (1493cc)",
          "B 6 (1493 CC) 7 SEATER (1493cc)",
          "B 6 OPT (1493 CC) 7 SEATER (1493cc)",
          "B 2 (1493 CC) 7 SEATER (1493cc)",
          "1.5 NEO N10 (1493cc)",
          "POWER PLUS LX 7 SEATER (1493cc)",
          "POWER PLUS SLE 7 SEATER (1493cc)",
          "POWER PLUS SLX 7 SEATER (1493cc)",
          "POWER PLUS ZLX 7 SEATER (1493cc)",
          "NEO N10 (O) (1493cc)",
          "LX POWER PLUS BS4 (1493cc)",
          "LX POWER PLUS BS4 (1493cc)",
          "POWER PLUS ABS (1493cc)",
          "POWER PLUS ABS (1493cc)",
          "POWER PLUS D70 SLE (1493cc)",
          "POWER PLUS D70 SLX (1493cc)",
          "POWER PLUS D70 ZLX (1493cc)",
          "B6 BSVI (1493cc)",
          "B6 (O) BSVI (1493cc)",
          "SLE POWER PLUS (1493cc)",
          "ZLX (1493cc)",
          "B2 (1493cc)",
          "B2 (1493cc)",
          "NEO N4 BSVI (1493cc)",
          "NEO N10 BSVI (1493cc)",
          "POWER PLUS LX (1493cc)",
          "POWER PLUS SLE (1493cc)",
          "POWER PLUS SLE (1493cc)",
          "POWER PLUS SLX (1493cc)",
          "POWER PLUS SLX (1493cc)",
          "POWER PLUS ZLX (1493cc)",
          "POWER PLUS ZLX (1493cc)",
          "B6 (1493cc)",
          "B6 (1493cc)",
          "B6 O (1493cc)",
          "B6 (O) (1493cc)",
          "DI 2WD 1498CC (1498cc)",
          "B6 (O) 7 SEATER (1498cc)",
          "B6  7 SEATER (1498cc)",
          "B4  7 SEATER (1498cc)",
          "B2 ( CC 1498 ) 7 SEATER (1498cc)",
          "B2 (1498cc)",
          "MHAWK D70 SLE (1493cc)",
          "B4 BS VI (1493cc)",
          "B4 (1493cc)",
          "B4 (1493cc)",
          "NEO PLUS P10 (2000cc)",
          "XLS MID TC NGT 2WD 9 SEATER (2179cc)",
          "SLE (2179cc)",
          "VLX (2490cc)",
          "2.5 DLX 4WD (2496cc)",
          "PLUS AC 8STR (2496cc)",
          "SLX 2WD 7 STR AC (2498cc)",
          "E 4 CRDE (2498cc)",
          "E 8 CRDE 2WD AIRBAG (2498cc)",
          "GLZ 2WD (2498cc)",
          "SLX 2WD 10 STR (2498cc)",
          "BOLERO PLUS AC 9 SEATER (2498 CC) (2498cc)",
          "LX 4WD (2498cc)",
          "LX 4WD (2498cc)",
          "ZLX (2498cc)",
          "SPORTS E 3 (2498cc)",
          "DC NEF TCI RHD EII (2498cc)",
          "SPORTZ 8 SEATER (2498cc)",
          "LXS PORT (2498cc)",
          "SPORTZ 7 SEATER (2498cc)",
          "LX (2498cc)",
          "SPORTZ (2498cc)",
          "LX 7 STR (2498cc)",
          "CAMPER DX MDI (2523cc)",
          "CAMPER HUBER (2523cc)",
          "MDI TC LX (2523cc)",
          "VLX STROM (2523cc)",
          "MAXX (2523cc)",
          "MDI TC DX (2523cc)",
          "MDI TC SLX (2523cc)",
          "PLUS BS III (2523cc)",
          "ZLX BS III (2523cc)",
          "EX BS III (2523cc)",
          "LX BS III (FOR GOVT) (2523cc)",
          "CAMPER 2WD PS BS6 (2523cc)",
          "CAMPER GOLD ZX 2WD BS6 (2523cc)",
          "CAMPER GOLD ZX 2WD PS BS6 (2523cc)",
          "CAMPER GOLD ZX BSIV (2523cc)",
          "PLUS AC BS III (2523cc)",
          "PLUS AC BS III (2523cc)",
          "LX MDI (2523cc)",
          "SLX BS III (2523cc)",
          "SLX BS III (2523cc)",
          "SLX 6 STR (2523cc)",
          "MDI TC (2523cc)",
          "PICK UP F.B. (2523cc)",
          "INVADER (2523cc)",
          "CAMPER GOLD VX 2WD (2523cc)",
          "CAMPER ZX BS6 (2523cc)",
          "DI 2WD NON AC BS III (2523cc)",
          "DI BSII (2523cc)",
          "DIZ BS III (2523cc)",
          "DIZ BSII (2523cc)",
          "EX 2WD NON/AC BS 4 (2523cc)",
          "EX BS 3 (2523cc)",
          "LX 6 STR (2523cc)",
          "MAX STD BSIII (2523cc)",
          "PLUS 2WD (2523cc)",
          "PLUS 2WD 9STR (2523cc)",
          "PLUS AC BS II (2523cc)",
          "PLUS AC PS BS4 (2523cc)",
          "PLUS NON AC BS 4. (2523cc)",
          "PLUS-AC PLUS PS BSIII (2523cc)",
          "XL A/C BSIII PS (2523cc)",
          "XLS BS III (2523cc)",
          "ZLX 6 STR (2523cc)",
          "CAMPER 4 SEATER (2523cc)",
          "SLX 6 SEATER (2523cc)",
          "DI AC (2523cc)",
          "ZLX SPECIAL EDITION (2523cc)",
          "MAXX (12) (2523cc)",
          "CAMPER HUBER 2WD BS3 (2523cc)",
          "EX AC BS IV (2523cc)",
          "EX AC BS IV (2523cc)",
          "XL 2WD (2523cc)",
          "ZX AC (2523cc)",
          "ZX AC (2523cc)",
          "LX BS IV (FOR GOVT ) (2523cc)",
          "LX BS IV (FOR GOVT ) (2523cc)",
          "STINGER (2523cc)",
          "STINGER (2523cc)",
          "POWER PLUS SPECIAL EDITION (2523cc)",
          "POWER PLUS SPECIAL EDITION (2523cc)",
          "SLX 2WD BSIII (2523cc)",
          "LX 2WD (2523cc)",
          "ZLX 2WD (2523cc)",
          "ZLX 2WD BS-III (2523cc)",
          "XLS 2WD CNG 9 STR (2523cc)",
          "PLUS - NON-AC BSIII (2523cc)",
          "PLUS - NON-AC BSIII (2523cc)",
          "PLUS AC BS IV (2523cc)",
          "PLUS AC BS IV (2523cc)",
          "CAMPER (2523cc)",
          "CAMPER (2523cc)",
          "DI 2WD (2523cc)",
          "DI 2WD (2523cc)",
          "SLE BS IV (2523cc)",
          "SLE BS IV (2523cc)",
          "ZLX BS IV (2523cc)",
          "ZLX BS IV (2523cc)",
          "DI AC BS III (2523cc)",
          "DI AC BS III (2523cc)",
          "PLUS BS IV (2523cc)",
          "PLUS BS IV (2523cc)",
          "STD (2523cc)",
          "STD (2523cc)",
          "EX BS IV (2523cc)",
          "EX BSIV (2523cc)",
          "CAMPER 2WD BS2 (2523cc)",
          "CAMPER 2WD BS2 (2523cc)",
          "PLUS - NON-AC BSII (2523cc)",
          "PLUS - NON-AC BSII (2523cc)",
          "VLX 2WD BSIII (2523cc)",
          "VLX 2WD BSIII (2523cc)",
          "DI BS III (2523cc)",
          "DI BSIII (2523cc)",
          "PLUS NON AC 9STR (2523cc)",
          "PLUS NON AC 9STR (2523cc)",
          "CAMPER DX MDI 6STR (2523cc)",
          "CAMPER HUBER MDI 6STR (2523cc)",
          "LX NON AC (2523cc)",
          "PLUS AC BSIV PS (2523cc)",
          "PLUS NON AC (2523cc)",
          "PLUS NON AC BSIV PS (2523cc)",
          "EX 2WD (2523cc)",
          "CAMPER PS 2WD (2523cc)",
          "CAMPER ZX 2WD PS BSVI (2523cc)",
          "EX NON-AC (2523cc)",
          "CAMPER GOLD 6 SEATER (2523cc)",
          "ZLX (2523cc)",
          "ZLX (2523cc)",
          "SPECIAL EDITION (2523cc)",
          "SPECIAL EDITION (2523cc)",
          "CAMPER 4 WD (2523cc)",
          "CAMPER SC XL 2WD TC (2523cc)",
          "INVADER GLX XDB 4 WD (2523cc)",
          "LX SPORTZ (2523cc)",
          "CAMPER DX 2WD (2523cc)",
          "CAMPER GOLD LIFE STYLE (2523cc)",
          "PICKUP CBC (2523cc)",
          "XLS 2WD 9STR AC (2523cc)",
          "CAMPER GOLD ZX (2523cc)",
          "SLX BS IV (2523cc)",
          "SLX BS IV (2523cc)",
          "PLUS 9STR (2523cc)",
          "PLUS 9STR (2523cc)",
          "CAMPER 2WD (2523cc)",
          "CAMPER 2 WD (2523cc)",
          "CAMPER GOLD ZX 2WD (2523cc)",
          "DX (2523cc)",
          "XL 9-SEATER NON-AC (2523cc)",
          "CAMPER GOLD 6 STR (2523cc)",
          "XLS 9STR AC (2523cc)",
          "CAMPER GOLD VX 2WD BISV (2523cc)",
          "PLUS (2523cc)",
          "CAMPER GOLD (2523cc)",
          "CAMPER GOLD (2523cc)",
          "EX (2523cc)",
          "VLX (2523cc)",
          "SLE BS III (2523cc)",
          "SLE BS III (2523cc)",
          "VLX CRDE (2523cc)",
          "VLX CRDE (2523cc)",
          "CAMPER DX. (2523cc)",
          "CAMPER DX (2523cc)",
          "SLX 2WD (2523cc)",
          "SLX 2WD (2523cc)",
          "DIZ (2523cc)",
          "DIZ (2523cc)",
          "INVADER GLX (2523cc)",
          "INVADER GLX (2523cc)",
          "LX (2523cc)",
          "LX (2523cc)",
          "SPORTZ (2523cc)",
          "SPORTZ (2523cc)",
          "INVADER DI (2523cc)",
          "INVADER DI (2523cc)",
          "PLUS AC PS (2523cc)",
          "PLUS AC PS (2523cc)",
          "PLUS AC (2523cc)",
          "PLUS AC 9STR (2523cc)",
          "SLX (2523cc)",
          "EX AC (2523cc)",
          "CAMPER DX MDI 6 SEATER (2523cc)",
          "CAMPER HUBER MDI 6 SEATER (2523cc)",
          "SPL EDITION (2523cc)",
          "SLE (2523cc)",
          "SLE (2523cc)",
          "DI CNG 2WD RIGID BS4 (2523cc)",
          "DI (2523cc)",
          "SLX 2WD (7 SEATER) (2609cc)",
        ],
      },
      {
        model: "BOLERO NEO",
        variants: [
          "N10 O (1493cc)",
          "N10(O) (1493cc)",
          "N10 BS6 (1493cc)",
          "N4 BS6 (1493cc)",
          "N10 O BS6 (1493cc)",
          "1.5 N10 (R) (1493cc)",
          "1.5 N4 (1493cc)",
          "1.5 N8 (1493cc)",
          "1.5 N10 (1493cc)",
          "1.5 N10 (O) (1493cc)",
          "1.5 N10 LIMITED EDITION (1493cc)",
          "N10 (1493cc)",
          "N10 (1493cc)",
          "N4 (1493cc)",
          "N4 (1493cc)",
          "N8 BS6 (1493cc)",
          "LIMITED EDITION (1493cc)",
          "LIMITED EDITION (1493cc)",
          "N8 (1493cc)",
          "N8 (1493cc)",
          "N10 BS6 - LIMITED EDITION (1497cc)",
        ],
      },
      {
        model: "JEEP",
        variants: [
          "6 STR (1300cc)",
          "68 (1489cc)",
          "CL 550 1.5 MDI (1489cc)",
          "C 75 (2000cc)",
          "CLASSIC (2010cc)",
          "(1991-2010) CJ 340 (2112cc)",
          "(1991-2010) CJ 340 DP (2112cc)",
          "(1991-2010) COMMANDER 750 DI (2112cc)",
          "(1991-2010) COMMANDER 750 DP (2112cc)",
          "(1991-2010) COMMANDER 750 ST (2112cc)",
          "(1991-2010) MM 540 (2112cc)",
          "(1991-2010) MM 540 DP (2112cc)",
          "(1991-2010) MM 540 XDB (2112cc)",
          "JEEP (2112cc)",
          "5 STR (2112cc)",
          "STC (2112cc)",
          "6 STR (2112cc)",
          "COMMANDER 750 DP 11 STR (2112cc)",
          "MM 540 DP (2112cc)",
          "MM 540 XDB (2112cc)",
          "STD (2112cc)",
          "COMMANDER 750 DI (2112cc)",
          "COMMANDER 750 DP (2112cc)",
          "COMMANDER 750 DP (2112cc)",
          "COMMANDER 750 ST (2112cc)",
          "MM 540 (2112cc)",
          "MM 540 (2112cc)",
          "CJ 340 (2112cc)",
          "CJ 340 (2112cc)",
          "CJ 340 DP (2112cc)",
          "CJ 340 DP (2112cc)",
          "STD 8 SEATER (2112cc)",
          "(1991-2010) CJ 3B (2195cc)",
          "CJ 3B (2195cc)",
          "CJ 3B (2195cc)",
          "JEEP (2199cc)",
          "JEEP (2400cc)",
          "JEEP (2400cc)",
          "(1991-2010) 2.5L (2489cc)",
          "SA (2489cc)",
          "JEEP (2489cc)",
          "SALOON /6 (2489cc)",
          "SALOON (2489cc)",
          "2.5L (2489cc)",
          "2.5L (2489cc)",
          "(1991-2010) CLASSIC (2498cc)",
          "(1991-2010) MM 550 DP (2498cc)",
          "(1991-2010) MM 550 PE (2498cc)",
          "(1991-2010) MM 550 XDB (2498cc)",
          "(1991-2010) MM 775 XDB (2498cc)",
          "(1991-2010) MM ISZ PETROL (2498cc)",
          "STD (2498cc)",
          "8 STR (2498cc)",
          "MM 540 DP 4WD (2498cc)",
          "CLASSIC (2498cc)",
          "CLASSIC (2498cc)",
          "MM ISZ PETROL (2498cc)",
          "MM ISZ PETROL (2498cc)",
          "MM 550 DP (2498cc)",
          "MM 550 DP (2498cc)",
          "MM 550 PE (2498cc)",
          "MM 550 PE (2498cc)",
          "MM 550 XDB (2498cc)",
          "MM 550 XDB (2498cc)",
          "MM 775 XDB (2498cc)",
          "MM 775 XDB (2498cc)",
          "550 DP 6STR (2498cc)",
          "550 DP 7STR (2498cc)",
          "550 DP 8STR (2498cc)",
          "540 DP 6STR (2498cc)",
          "540 DP 7STR (2498cc)",
          "540 DP 8STR (2498cc)",
          "500 MDI 6STR (2498cc)",
          "500 MDI 7STR (2498cc)",
          "340 DP 6STR (2498cc)",
          "340 DP 7STR (2498cc)",
          "340 DP 8STR (2498cc)",
          "640 DP 7STR (2498cc)",
          "CJ 640 DP 2WD (2520cc)",
          "(1991-2010) CJ 500 D (2523cc)",
          "(1991-2010) CJ 500 DI (2523cc)",
          "(1991-2010) CL 500 MDI (2523cc)",
          "(1991-2010) CL 550 MDI (2523cc)",
          "(1991-2010) COMMANDER 650 DI (2523cc)",
          "(1991-2010) COMMANDER 655 DI (2523cc)",
          "MM ISZ (2523cc)",
          "MM ISZ (2523cc)",
          "10STR (2523cc)",
          "10 STR (2523cc)",
          "6 STR (2523cc)",
          "JEEP (2523cc)",
          "JEEP (2523cc)",
          "CL500 DI (2523cc)",
          "CL500 DI 7 STR (2523cc)",
          "12 STR. (2523cc)",
          "COMMANDER 750 DI 9 STR (2523cc)",
          "NC 665DP (2523cc)",
          "CL500 MD (2523cc)",
          "COMMANDER 650 DI 8STR (2523cc)",
          "STD (2523cc)",
          "COMMANDER 655 DI (2523cc)",
          "COMMANDER 655 DI (2523cc)",
          "CL 500 MDI (2523cc)",
          "CL 500 MDI (2523cc)",
          "CLASSIC (2523cc)",
          "COMMANDER 750 DI (2523cc)",
          "COMMANDER 750 DP (2523cc)",
          "COMMANDER 750 ST (2523cc)",
          "MM ISZ PETROL (2523cc)",
          "CJ 500 DI (2523cc)",
          "CL 550 MDI (2523cc)",
          "COMMANDER 650 DI (2523cc)",
          "775 DP 7STR (2523cc)",
          "775 DP 8STR (2523cc)",
          "775 DP 9STR (2523cc)",
          "5STR (2523cc)",
          "5STR (2523cc)",
          "MM900 MD 4WD (2523cc)",
          "JEEP 8STR (2523cc)",
          "STANDARD 6 SEATER (2523cc)",
          "CJ 500 (2523cc)",
          "JEEP 10STR (2523cc)",
          "CJ 500 D (2523cc)",
        ],
      },
      {
        model: "VERITO",
        variants: [
          "VIBE 1.5 D2 (1461cc)",
          "VIBE 1.5 D4 (1461cc)",
          "VIBE 1.5 D6 (1461cc)",
          "D6 (1461cc)",
          "D6 (1461cc)",
          "D4 (1461cc)",
          "D4 (1461cc)",
          "D2 (1461cc)",
          "D2 (1461cc)",
          "1.5 D6 EXECUTIVE BS-III (1461cc)",
          "D6 EXECUTIVE (1461cc)",
          "D4 PLAY (1461cc)",
          "VIBE D2 (1461cc)",
          "VIBE D6 (1461cc)",
          "VIBE D4 (1461cc)",
          "1.5 D2 BS IV 4 STR (1461cc)",
          "C2 72V 199AH ELECTRIC (1461cc)",
          "1.5 E D2 ELECTRIC (1461cc)",
          "1.5 E D4 ELECTRIC (1461cc)",
          "1.5 E D6 ELECTRIC (1461cc)",
          "1.5 D6 ABS DIESEL (1461cc)",
          "1.5 D6 ABS EXECUTIVE (1461cc)",
          "1.5 D2 DIESEL (1461cc)",
          "1.5 D4 DIESEL (1461cc)",
          "1.5 D6 DIESEL (1461cc)",
          "1.5 D6 EXECUTIVE BS-IV (1461cc)",
          "1.5 D6 EXECUTIVE BS-IV (1461cc)",
          "1.5 D4 (1461cc)",
          "1.5 D4 PLAY (1461cc)",
          "1.5 D6 (1461cc)",
          "1.5 EXECUTIVE EDITION (1461cc)",
          "1.5 D2 (1461cc)",
          "1.5 D6 EXECUTIVE (1461cc)",
          "1.5 D4 PLAY DIESEL (1461cc)",
          "1.5 D2 BS III (1461cc)",
          "1.5 D2 BS-III (1461cc)",
          "1.5 D4 BS III (1461cc)",
          "1.5 D4 BS-III (1461cc)",
          "1.5 D6 BS III (1461cc)",
          "1.5 D6 BS-III (1461cc)",
          "1.5 D2 BS IV (1461cc)",
          "1.5 D2 BS-IV (1461cc)",
          "1.5 D4 BS IV (1461cc)",
          "1.5 D4 BS-IV (1461cc)",
          "1.5 D4 PLAY BS-III (1461cc)",
          "1.5 D4 PLAY BS-III (1461cc)",
          "1.5 D6 BS IV (1461cc)",
          "1.5 D6 BS-IV (1461cc)",
          "G4 (1390cc)",
          "G4 (1390cc)",
          "G2 (1390cc)",
          "G2 (1390cc)",
          "G4 PLAY (1390cc)",
          "1.4 GLX (1390cc)",
          "1.4 G2 (1390cc)",
          "1.4 G4 (1390cc)",
          "1.4 G4 PLAY (1390cc)",
          "1.4 G2 PETROL (1390cc)",
          "1.4 G4 PETROL (1390cc)",
          "1.4 G4 PLAY PETROL (1390cc)",
          "1.4 G2 BS III (1390cc)",
          "1.4 G2 BS-III (1390cc)",
          "1.4 G4 BS III (1390cc)",
          "1.4 G4 BS-III (1390cc)",
          "K7J PS 5 GB BS4 (1390cc)",
          "1.4 G4 PLAY BS-III (1390cc)",
          "1.4 G4 PLAY BS-III (1390cc)",
          "1.4 G2 BS-IV (1390cc)",
          "1.4 G2 BS-IV (1390cc)",
          "1.4 G4 BS-IV (1390cc)",
          "1.4 G4 BS-IV (1390cc)",
          "1.6 GLX (1598cc)",
          "1.6 GLS (1598cc)",
          "G6 EXECUTIVE (1598cc)",
          "1.6 G6 EXECUTIVE BS-III (1598cc)",
          "1.6 G6 EXECUTIVE BS-IV (1598cc)",
          "1.6 G6 EXECUTIVE BS-IV (1598cc)",
          "1.6 G6 EXECUTIVE (1598cc)",
        ],
      },
      {
        model: "MARSHAL",
        variants: [
          "7 SEATER (2523cc)",
          "10 SEATER (2523cc)",
          "DI DX (2523cc)",
          "DI 8 STR (2523cc)",
          "MDI 4WD 8 SEATER (2523cc)",
          "MDI 8 SEATER (2523cc)",
          "ROYAL JEEP 6 SEAT (2523cc)",
          "DLX ROYALE EURO II 2WD (2523cc)",
          "10 STR BS II (2523cc)",
          "ROYALE (2523cc)",
          "2000 DELUXE ROYALE (2523cc)",
          "ECONOMY DI 4WD (2523cc)",
          "STD (10) (2523cc)",
          "8 SEATER/DIESEL (2523cc)",
          "9 SEATER/DIESEL (2523cc)",
          "10 SEATER/DIESEL (2523cc)",
          "STD (2523cc)",
          "STD (2523cc)",
          "2000 DX (2523cc)",
          "DLX (2523cc)",
          "DLX 4WD 8 STR BS 2 (2523cc)",
          "DI 6STR (2523cc)",
          "200DX (2523cc)",
          "ROYAL 2WD 8STR B-2 (2523cc)",
          "DLX ROYAL 2WD (2523cc)",
          "2000 DELUXE (2523cc)",
          "2000 DELUXE (2523cc)",
          "DX ROYALE (2523cc)",
          "MARSHAL 7STR (2523cc)",
          "DI (2523cc)",
          "DI (2523cc)",
          "DI DX (2498cc)",
          "DX ROYALE (2498cc)",
          "DI (2498cc)",
          "DI HARD TOP 10 SEATER (2112cc)",
          "ROYAL JEEP (2112cc)",
          "ROYAL JEEP (2112cc)",
          "DI (2112cc)",
          "14 SEATER/DIESEL (1900cc)",
          "STD (1998cc)",
          "DX ROYALE (1998cc)",
        ],
      },
      {
        model: "SCORPIO",
        variants: [
          "U321 7 STR (1493cc)",
          "MDI 8STR (1915cc)",
          "REV 116 (1948cc)",
          "S4 PLUS 1.99 INTELLI-HYBRID (1997cc)",
          "S4 PLUS 1.99 INTELLI-HYBRID (1997cc)",
          "S10 4WD 1.99 INTELLI-HYBRID (1997cc)",
          "S10 4WD 1.99 INTELLI-HYBRID (1997cc)",
          "S10 1.99 INTELLI-HYBRID (1997cc)",
          "S10 1.99 INTELLI-HYBRID (1997cc)",
          "S8 1.99 INTELLI-HYBRID (1997cc)",
          "S8 1.99 INTELLI-HYBRID (1997cc)",
          "S4 PLUS 4WD 1.99 INTELLI-HYBRID (1997cc)",
          "S4 PLUS 4WD 1.99 INTELLI-HYBRID (1997cc)",
          "S6 PLUS 1.99 INTELLI-HYBRID (1997cc)",
          "S6 PLUS 1.99 INTELLI-HYBRID (1997cc)",
          "S4 1.99 INTELLI-HYBRID (1997cc)",
          "S4 1.99 INTELLI-HYBRID (1997cc)",
          "ADVENTURE LE 2WD 1.99 INTELLI-HYBRID (1997cc)",
          "ADVENTURE LE 2WD 1.99 INTELLI-HYBRID (1997cc)",
          "ADVENTURE LE 4WD 1.99 INTELLI-HYBRID (1997cc)",
          "ADVENTURE LE 4WD 1.99 INTELLI-HYBRID (1997cc)",
          "S4 PLUS 4WD (1997cc)",
          "S4 PLUS 4WD (1997cc)",
          "1.9 S4 (1997cc)",
          "S4 PLUS 1.99 (1997cc)",
          "S4 PLUS 1.99 (1997cc)",
          "S6 PLUS 1.99 (1997cc)",
          "S6 PLUS 1.99 (1997cc)",
          "S4 PLUS 4WD 1.99 (1997cc)",
          "S4 PLUS 4WD 1.99 (1997cc)",
          "S4  4WD (1997cc)",
          "S4 4WD (1997cc)",
          "S4 PLUS (1997cc)",
          "S8 1.99 (1997cc)",
          "S8 1.99 (1997cc)",
          "S4 1.99 (1997cc)",
          "S4 1.99 (1997cc)",
          "S10 4WD 1.99 (1997cc)",
          "S10 4WD 1.99 (1997cc)",
          "S10 1.99 (1997cc)",
          "S10 1.99 (1997cc)",
          "1.9 S4 M HAWK 2WD 9 STR (1997cc)",
          "1.9 S6 PLUS 8 STR (1997cc)",
          "S10 1.99 IH 2WD (1997cc)",
          "1.99 6 PLUS (1997cc)",
          "1.99 S10 (1997cc)",
          "1.99 S10 4WD (1997cc)",
          "1.99 S4 (1997cc)",
          "1.99 S4 PLUS (1997cc)",
          "1.99 S4 PLUS 4WD (1997cc)",
          "1.99 S8 (1997cc)",
          "S6 PLUS 1.99 7STR (1997cc)",
          "S10 (1997cc)",
          "S4 1.99 7STR (1997cc)",
          "S6 PLUS 1.99 8STR (1997cc)",
          "S4 PLUS 4WD 1.99 7STR (1997cc)",
          "S4 PLUS 4WD 1.99 8STR (1997cc)",
          "S8 1.99 8STR (1997cc)",
          "S10 1.99 7STR (1997cc)",
          "S10 1.99 8STR (1997cc)",
          "S10 4WD 1.99 7STR (1997cc)",
          "S10 4WD 1.99 8STR (1997cc)",
          "S4 1.99 9STR (1997cc)",
          "S4 PLUS 1.99 7STR (1997cc)",
          "S8 1.99 7STR (1997cc)",
          "S4 PLUS 1.99 9STR (1997cc)",
          "S6 (1997cc)",
          "S8 (1997cc)",
          "S6 PLUS (1997cc)",
          "SLX (1998cc)",
          "2.0 REV 116  PETROL 8 SEATER (1998cc)",
          "PETROL REV 116 (1998cc)",
          "2WD REV 116 (1998cc)",
          "REV 116 (1998cc)",
          "S2 (2000cc)",
          "2.6 TURBO 5 STR (2069cc)",
          "VLX (2179cc)",
          "VLX (2179cc)",
          "2.2 M HAWK VLX 8STR (2179cc)",
          "2.2 M HAWK SLE 8STR (2179cc)",
          "2.2 M HAWK SLE 7STR (2179cc)",
          "HAWK BS4 TC5 (2179cc)",
          "2.2 CRDE M HAWK VLX (2179cc)",
          "HAWK BS3 ABS TC5 (2179cc)",
          "2.2 M HAWK LX (2179cc)",
          "VLS 7STR (2179cc)",
          "2.2 M HAWK VLX 7STR (2179cc)",
          "2.2 M HAWK LX 8STR (2179cc)",
          "2.2 M HAWK LX 7STR (2179cc)",
          "2.2 M HAWK VLS 8STR (2179cc)",
          "GETAWAY 2WD BS III (2179cc)",
          "GETAWAY 4WD BS III (2179cc)",
          "2.6 CRDE (2179cc)",
          "2.6 CRDE (2179cc)",
          "2.6 SLX (2179cc)",
          "2.6 SLX (2179cc)",
          "SLX 4WD (2179cc)",
          "SLX 4WD (2179cc)",
          "XTREME (2179cc)",
          "XTREME (2179cc)",
          "LX 2.2 HMH 4WD BSIV (2179cc)",
          "S7 (2179cc)",
          "S7 (2179cc)",
          "S9 (2179cc)",
          "S9 (2179cc)",
          "VLX 2WD AIRBAG SPECIAL EDITION BS-IV (2179cc)",
          "VLX 2WD AIRBAG SPECIAL EDITION BS-IV (2179cc)",
          "S3 PLUS (2179cc)",
          "S3 PLUS (2179cc)",
          "VLX 2WD ABS AT BS-III (2179cc)",
          "VLX 2WD ABS AT BS-III (2179cc)",
          "LX 4WD BS-IV (2179cc)",
          "LX 4WD BS-IV (2179cc)",
          "S10 2WD INTELLI-HYBRID (2179cc)",
          "S10 2WD INTELLI-HYBRID (2179cc)",
          "ANNIVERSARY BS4 7SF PEARLWHITE (2179cc)",
          "S2 2.5 M2DICR 2WD MT (2179cc)",
          "RF HWK BS4 SLE 8 STR (2179cc)",
          "S-10 2.2 MHAWK (2179cc)",
          "SLE 2.2 HAWK (2179cc)",
          "S4 + IH 2WD (2179cc)",
          "LX HAWK 2WD (2179cc)",
          "VLX HAWK BSIV (2179cc)",
          "VLX 2WD AIRBAG BS-IV (2179cc)",
          "VLX 2WD AIRBAG BS-IV (2179cc)",
          "S5 (2179cc)",
          "S5 (2179cc)",
          "DX (2179cc)",
          "CRDE (2179cc)",
          "GLX AWD LIMITED EDITION (2179cc)",
          "DX AWD (2179cc)",
          "GLX (2179cc)",
          "GLX REV116 (2179cc)",
          "LX (2179cc)",
          "LX 4WD (2179cc)",
          "LX AWD (2179cc)",
          "LS (2179cc)",
          "VLX AT (2179cc)",
          "VLX AWD (2179cc)",
          "SPORTS (2179cc)",
          "VLX LIMITED EDITION (2179cc)",
          "VLX AWD AT (2179cc)",
          "S10 AT (2179cc)",
          "ADVENTURE EDITION (2179cc)",
          "S4 4Ãƒâ€”4 (2179cc)",
          "LX BS-III (2179cc)",
          "VLX 4WD AT BS-III (2179cc)",
          "VLX 4WD BS-III (2179cc)",
          "VLX 2WD AIRBAG BS-III (2179cc)",
          "VLX 2WD AT BS-III (2179cc)",
          "VLX 2WD BS-III (2179cc)",
          "VLX SPECIAL EDITION BS-III (2179cc)",
          "S9 2WD (2179cc)",
          "RF (2179cc)",
          "S7 2WD 140 (2179cc)",
          "VLX 2WD AT BS-IV (2179cc)",
          "VLX 2WD AT BS-IV (2179cc)",
          "S7 140 2WD (2179cc)",
          "S7 140 2WD (2179cc)",
          "SLE BS-IV (2179cc)",
          "SLE BS-IV (2179cc)",
          "VLS AT 2.2 MHAWK (2179cc)",
          "VLS AT 2.2 MHAWK (2179cc)",
          "2.6 SPORTZ CRDE (2179cc)",
          "2.6 SPORTZ CRDE (2179cc)",
          "ADVENTURE LE 4WD (2179cc)",
          "ADVENTURE LE 4WD (2179cc)",
          "S10 4WD (2179cc)",
          "S10 4WD (2179cc)",
          "S4 PLUS (2179cc)",
          "S4 PLUS (2179cc)",
          "S10 4WD AT (2179cc)",
          "S10 4WD AT (2179cc)",
          "LX BS-IV (2179cc)",
          "LX BS-IV (2179cc)",
          "VLX 4WD ABS AT BS-III (2179cc)",
          "VLX 4WD ABS AT BS-III (2179cc)",
          "VLX 4WD AIRBAG AT BS-IV (2179cc)",
          "VLX 4WD AIRBAG AT BS-IV (2179cc)",
          "VLX 4WD ABS BS-III (2179cc)",
          "VLX 4WD ABS BS-III (2179cc)",
          "VLX 4WD AIRBAG BS-IV (2179cc)",
          "VLX 4WD AIRBAG BS-IV (2179cc)",
          "VLX 4WD AT BS-IV (2179cc)",
          "VLX 4WD AT BS-IV (2179cc)",
          "VLX 4WD BS-IV (2179cc)",
          "VLX 4WD BS-IV (2179cc)",
          "VLX SPECIAL EDITION BS-IV (2179cc)",
          "VLX SPECIAL EDITION BS-IV (2179cc)",
          "VLX 2WD BS-IV (2179cc)",
          "VLX 2WD BS-IV (2179cc)",
          "VLX 2WD AIRBAG AT BS-IV (2179cc)",
          "VLX 2WD AIRBAG AT BS-IV (2179cc)",
          "S6 PLUS 4WD (2179cc)",
          "S6 PLUS 4WD (2179cc)",
          "S10 2WD (2179cc)",
          "S10 2WD (2179cc)",
          "S10 2WD AT (2179cc)",
          "S10 2WD AT (2179cc)",
          "ADVENTURE LE 2WD (2179cc)",
          "ADVENTURE LE 2WD (2179cc)",
          "RF HWK (2179cc)",
          "2.2 VLX HAWK 7 STR E III (2179cc)",
          "RF 8 STR (2179cc)",
          "S10 M HAWK 7STR (2179cc)",
          "VLX HAWK 8STR (2179cc)",
          "RF HAWK LX 9STR (2179cc)",
          "S10 M HAWK 8STR (2179cc)",
          "S11 2WD (2179cc)",
          "S11 2WD (2179cc)",
          "S5 2WD (2179cc)",
          "S5 2WD (2179cc)",
          "VLX 5 STR (2179cc)",
          "S7 140 2WD 8 STR BS6 (2179cc)",
          "S11 2WD BSVI (2179cc)",
          "S10 6 STR (2179cc)",
          "S3 PLUS BS6 (2179cc)",
          "S3 PLUS BS6 9STR (2179cc)",
          "S5 BS6 9 STR (2179cc)",
          "S3 8 STR (2179cc)",
          "S8 2.2 MHAWK (2179cc)",
          "REV 116 8 STR (2179cc)",
          "CLASSIC S11 2WD (2179cc)",
          "CLASSIC S11 BS6 9 STR (2179cc)",
          "ANNIVERSARY BS4 7SF (2179cc)",
          "LX BS3 (2179cc)",
          "2.2 HAWK 2WD STD (2179cc)",
          "2.2 VLX HAWK 8 STR E III (2179cc)",
          "2WD CRDE SLE (2179cc)",
          "5 STR BS III (2179cc)",
          "ADVENTURE EDI 2WD 8STR (2179cc)",
          "HAWK BSIII (2179cc)",
          "HAWK BSIII 8 STR (2179cc)",
          "LX 5STR (2179cc)",
          "RF HAWK BS 3 2WD (2179cc)",
          "RF HWK 2WD TC (2179cc)",
          "RF HWK BS3 2WD (2179cc)",
          "RF HWK BSIV 2WD TC (2179cc)",
          "S10 5 STR (2179cc)",
          "S10 9 STR (2179cc)",
          "S6 PLUS 6 STR (2179cc)",
          "SLE BS-IV 6 STR (2179cc)",
          "SLE BS-IV 8STR (2179cc)",
          "VLS 8 STR (2179cc)",
          "VLX 2 WD 6 STR (2179cc)",
          "VLX AIRBAG BS-III (2179cc)",
          "VLX HAWK CRDE (2179cc)",
          "VLX TURBO (2179cc)",
          "S4 PLUS INTELLI-HYBRID 4WD (2179cc)",
          "S4 PLUS INTELLI-HYBRID 4WD (2179cc)",
          "S6 PLUS INTELLI-HYBRID (2179cc)",
          "S6 PLUS INTELLI-HYBRID (2179cc)",
          "S4 INTELLI-HYBRID (2179cc)",
          "S4 INTELLI-HYBRID (2179cc)",
          "S11 (2179cc)",
          "S11 (2179cc)",
          "ADVENTURE EDITION 2WD (2179cc)",
          "ADVENTURE EDITION 4WD (2179cc)",
          "S10 INTELLI HYBRID 4WD (2179cc)",
          "S10 INTELLI HYBRID (2179cc)",
          "S8 7C (2179cc)",
          "S5 BS IV (2179cc)",
          "S7 140 (2179cc)",
          "S4 INTELLI HYBRID 9STR (2179cc)",
          "S4 PLUS INTELLI HYBRID 9STR (2179cc)",
          "S4+ INTELLI HYBRID 4WD 9STR (2179cc)",
          "S6 PLUS INTELLI HYBRID 8STR (2179cc)",
          "S8 INTELLI HYBRID 8STR (2179cc)",
          "S10 INTELLI HYBRID 8STR (2179cc)",
          "S10 INTELLI HYBRID 4WD 8STR (2179cc)",
          "S5 BSVI (2179cc)",
          "S11 BS6 (2179cc)",
          "S4 PLUS 4WD INTELLI HYBRID (2179cc)",
          "S3 PLUS 2WD (2179cc)",
          "S7 120 (2179cc)",
          "S4 PLUS INTELLI-HYBRID (2179cc)",
          "S4 PLUS INTELLI-HYBRID (2179cc)",
          "S8 INTELLI-HYBRID (2179cc)",
          "S8 INTELLI-HYBRID (2179cc)",
          "S10 4WD INTELLI-HYBRID (2179cc)",
          "S10 4WD INTELLI-HYBRID (2179cc)",
          "S11 4WD (2179cc)",
          "S11 4WD (2179cc)",
          "SLE (2179cc)",
          "SLE BS-III (2179cc)",
          "SLE BS-III (2179cc)",
          "S7 120 2WD (2179cc)",
          "S7 120 2WD (2179cc)",
          "CLASSIC (2179cc)",
          "CLASSIC (2179cc)",
          "LX 7STR (2179cc)",
          "LX 7STR (2179cc)",
          "SLE 8STR (2179cc)",
          "SLE 8STR (2179cc)",
          "LX 9STR (2179cc)",
          "LX 9STR (2179cc)",
          "S6 8STR (2179cc)",
          "S6 8STR (2179cc)",
          "GETAWAY 4WD BS IV (2179cc)",
          "GETAWAY 4WD BS IV (2179cc)",
          "GETAWAY 2WD BS IV (2179cc)",
          "GETAWAY 2WD BS IV (2179cc)",
          "S11 4WD 8 STR (2179cc)",
          "S11 4WD 8 STR (2179cc)",
          "S7 120 2WD 8 STR (2179cc)",
          "S7 120 2WD 8 STR (2179cc)",
          "S3 PLUS 9 STR (2179cc)",
          "S3 PLUS 9 STR (2179cc)",
          "LX 2.2 MHAWK (2179cc)",
          "VLX BS-III 4WD (2179cc)",
          "S10 BS4 (2179cc)",
          "S10 (2179cc)",
          "S10 2.2 MHAWK 2WD (2179cc)",
          "HAWK BS4 ABS TC5 4WD RHD TB DMWD (2179cc)",
          "DC MHAWK EV 4WD TCE3 LHD (2179cc)",
          "S4 4WD 7STR (2179cc)",
          "VLX 2.2 MHAWK BS III AIRBAG (2179cc)",
          "S4 4WD 8STR (2179cc)",
          "S4 PLUS 4WD 9STR (2179cc)",
          "S4 PLUS 7STR (2179cc)",
          "VLX 2WD HE  (SE) 8 SEATER (2179cc)",
          "HWK BS4 AT 2WD TC5 BLK 8FF RHD DWHT (2179cc)",
          "S8 7STR (2179cc)",
          "S8 8STR (2179cc)",
          "S6 2.2 MHAWK 2WD (2179cc)",
          "DC MHAWK BS4 RHD 4WD (2179cc)",
          "S7 120 2WD 7STR (2179cc)",
          "RF HWK BS4 2WD TC5 T&B 7SF RHD DMD (2179cc)",
          "W MHAWK EV 2WD TCE3 LHD (2179cc)",
          "RF HWK BS3 2WD TC5 7SF RHD (2179cc)",
          "S11 2WD 7STR (2179cc)",
          "S6 7STR (2179cc)",
          "S4 7STR (2179cc)",
          "ADVENTURE 4WD (2179cc)",
          "VLX 2.2 MHAWK BS - III (2179cc)",
          "VLX 2.2 MHAWK BS IV AIRBAG (2179cc)",
          "VLX 2.2 MHAWK BS IV (2179cc)",
          "S6 PLUS 7STR (2179cc)",
          "S10 4WD 7STR (2179cc)",
          "S11 2.2MHAWK 140 2WD (2179cc)",
          "S11 4WD 7STR (2179cc)",
          "S7 140 2WD 7STR (2179cc)",
          "S10 4WD 8STR (2179cc)",
          "S10 2WD AT 7STR (2179cc)",
          "S10 7STR (2179cc)",
          "VLX AT 4WD (2179cc)",
          "VLX BS-III 4WD AIRBAG (2179cc)",
          "VLX AT 4WD AIRBAG (2179cc)",
          "S11 4WD 9STR (2179cc)",
          "S11 2WD 9STR (2179cc)",
          "VLX 2WD HE  (SE) 7 SEATER (2179cc)",
          "S10 2WD AT 8STR (2179cc)",
          "S6 PLUS B4 IH (2179cc)",
          "SLE 2.2 MHAWK (2179cc)",
          "VLX AT 2.2 MHAWK BS - III (2179cc)",
          "VLX AT 2.2 MHAWK BS III AIRBAG (2179cc)",
          "HAWK 2WD (2179cc)",
          "CRDE MHAWK ENGINE VLX-BS-IV (2179cc)",
          "S5 2WD 7STR (2179cc)",
          "VLX 2.2 MHAWK (2179cc)",
          "S10 4WD AT 7STR (2179cc)",
          "S3 PLUS 7 STR (2179cc)",
          "ADVENTURE 2WD (2179cc)",
          "CLASSIC S11 9 STR (2179cc)",
          "EX (2179cc)",
          "M2DI (2179cc)",
          "M2DI (2179cc)",
          "S6 (2179cc)",
          "S6 (2179cc)",
          "VLS 2.2 MHAWK (2179cc)",
          "VLS 2.2 MHAWK (2179cc)",
          "CLASSIC S 9 STR (2179cc)",
          "S8 (2179cc)",
          "S8 (2179cc)",
          "S6 PLUS (2179cc)",
          "S6 PLUS (2179cc)",
          "S4 (2179cc)",
          "S4 (2179cc)",
          "S5 2WD 9 STR (2179cc)",
          "S5 2WD 9 STR (2179cc)",
          "S7 140 2WD 8 STR (2179cc)",
          "S7 140 2WD 8 STR (2179cc)",
          "S11 2WD 8 STR (2179cc)",
          "S11 2WD 8 STR (2179cc)",
          "S9 2WD 8 STR (2179cc)",
          "S9 2WD 8 STR (2179cc)",
          "S4 9 STR (2179cc)",
          "STD (2179cc)",
          "EX 9STR (2179cc)",
          "S10 8STR (2179cc)",
          "S4 8STR (2179cc)",
          "S4 PLUS 9 STR (2179cc)",
          "S6 PLUS 8 STR (2179cc)",
          "S9 2WD 7 STR (2179cc)",
          "GETAWAY 2 WD (2179cc)",
          "2WD AC (2179cc)",
          "REV 116. (2179cc)",
          "REV 116 (2179cc)",
          "M2DI 9STR (2179cc)",
          "S2 (2179cc)",
          "GETAWAY 4 WD (2179cc)",
          "CLASSIC S MT DIESEL 7 SEATER (2183cc)",
          "S3 PLUS 7 STR. (2183cc)",
          "CLASSIC (2184cc)",
          "CLASSIC (2184cc)",
          "CLASSIC STR 9 (2184cc)",
          "CLASSIC S BS6 7STR (2184cc)",
          "CLASSIC S BS6 9STR (2184cc)",
          "CLASSIC S11 BS6 7STR (2184cc)",
          "CLASSIC S (2184cc)",
          "CLASSIC S 9STR (2184cc)",
          "RF HWK BS4 2WD (2198cc)",
          "SLE 8FF 2WD (2200cc)",
          "RE HWK SLE (2200cc)",
          "HWK (2200cc)",
          "RF HWK 10 STR (2200cc)",
          "HAWK T2B5 4WD DLX LHD T&B ROCK BIEG (2200cc)",
          "W105 HWK BS4 2WD TC5 (2200cc)",
          "STD (2400cc)",
          "EX (2493cc)",
          "M2 CRDE EX 2WD BS3 (2498cc)",
          "M2DI MDI (2523cc)",
          "S3 7STR (2523cc)",
          "S3 9STR (2523cc)",
          "S3 9 STR (2523cc)",
          "EX 8STR (2523cc)",
          "EX 8 STR (2523cc)",
          "VLX (2523cc)",
          "S6 PLUS 2.2 MHAWK 2WD 7S (2523cc)",
          "S3 MH 2WD 9 STR (2523cc)",
          "EX 2.5 M2DICR-2WD BS3 (2523cc)",
          "S3 PLUS MT (2523cc)",
          "S3 MH 2WD BSIV (2523cc)",
          "DX (2523cc)",
          "DX AWD (2523cc)",
          "EX M2DICR (2523cc)",
          "LX (2523cc)",
          "VLS (2523cc)",
          "SLX AWD LIMITED EDITION (2523cc)",
          "S3 (2523cc)",
          "S3 2WD (2523cc)",
          "S3 2WD (2523cc)",
          "2.5 LX 2WD (2523cc)",
          "CRDE 2WD (2523cc)",
          "GETAWAY 4WD 5 STR (2523cc)",
          "LX HAWK BS III (2523cc)",
          "M2DI 8 STR (2523cc)",
          "MDI 10 STR (2523cc)",
          "S2 6 STR (2523cc)",
          "SLX 5 SEATER (2523 CC) (2523cc)",
          "M2DI 8 SEATER (2523cc)",
          "M2DI 7STR (2523cc)",
          "S3 BS IV (2523cc)",
          "MDI BS2 TC (2523cc)",
          "SLE (2523cc)",
          "M2DI 7 SEATER (2523cc)",
          "M2DI 9 SEATER (2523cc)",
          "EX. (2523cc)",
          "EX (2523cc)",
          "M2DI (2523cc)",
          "M2 DI (2523cc)",
          "M2DI 9STR (2523cc)",
          "M2DI 9 STR (2523cc)",
          "S3 2WD 9 STR (2523cc)",
          "S3 2WD 9 STR (2523cc)",
          "S3 2WD 7STR (2523cc)",
          "EX 10 SEATER (2523cc)",
          "S2 7STR (2523cc)",
          "LX 9 STR (2523cc)",
          "2.6 GLX (2523cc)",
          "2.6 GLX (2523cc)",
          "S2 9 STR (2523cc)",
          "S2 9STR (2523cc)",
          "STD (2523cc)",
          "EX 9 STR (2523cc)",
          "S2 8 STR (2523cc)",
          "S2 (2523cc)",
          "S2 (2523cc)",
          "2.6 CRDE SLE 7STR (2549cc)",
          "2.6 CRDE SLE 8STR (2549cc)",
          "2.6 GETWAY 4WD (2598cc)",
          "DX (2600cc)",
          "MDI 9STR 2WD (2600cc)",
          "STD TURBO 8STR (2600cc)",
          "DX 7STR (2600cc)",
          "DX 9STR (2600cc)",
          "DX 8STR (2600cc)",
          "STD 7STR (2002) (2600cc)",
          "DX 7 SEATER (2600cc)",
          "DX 8 SEATER (2600cc)",
          "STD 7 SEATER (2002) (2600cc)",
          "DX 9 SEATER (2600cc)",
          "TURBO 2.6 GLX 2WD 7STR (2609cc)",
          "2.6 DI DX 8STR (2609cc)",
          "DI 8STR (2609cc)",
          "LX CRDE 7STR (2609cc)",
          "TURBO 2.6 DIESEL (2609cc)",
          "STD E 2 8 STR (2609cc)",
          "2WD DX CRDE W (2609cc)",
          "TURBO 2.6 CRDE 5STR (2609cc)",
          "TURBO 2.6 GLX  2WD  8 STR (2609cc)",
          "TURBO 2.6 SLX 2WD 9 STR (2609cc)",
          "TURBO 2.6 STD CRDE E3 (2609cc)",
          "GETAWAY W CRDE BS3 5STR (2609cc)",
          "2.6 TURBO DLX 7STR (2609cc)",
          "TURBO 2.6SLX CRDE 7STR (2609cc)",
          "DLX CRDE 8STR (2609cc)",
          "TURBO 2.6 CRDE 6STR (2609cc)",
          "STD CRDE 7STR (2609cc)",
          "TURBO 2.6 SLXCRDE 8STR (2609cc)",
          "STD CRDE 9STR (2609cc)",
          "2.6 LX 9STR (2609cc)",
          "2.6 LX 9 STR (2609cc)",
          "GETAWAY 2WD BS-III (2609cc)",
          "GETAWAY 4WD BS-III (2609cc)",
          "DX-7 STR (2609cc)",
          "DX 9STR (2609cc)",
          "GETAWAY (2609cc)",
          "GETAWAY (2609cc)",
          "SLX (2609cc)",
          "2.6 (2609cc)",
          "2.6 (2609cc)",
          "2.6 CRDE (2609cc)",
          "SLX 2.6 TURBO (2609cc)",
          "DX 2.6 TURBO (2609cc)",
          "2.6 TURBO (2609cc)",
          "TURBO 2.6 CAMPER VAN (2609cc)",
          "2.6 TURBO 9 STR (2609cc)",
          "2.6 TURBO 9 STR (2609cc)",
          "2WD (2609cc)",
          "GLX TURBO (2609cc)",
          "LX (2609cc)",
          "LX TURBO (2609cc)",
          "STANDARD (2609cc)",
          "SPORTZ (2609cc)",
          "SLX TURBO (2609cc)",
          "2.6 DX CRDE (2609cc)",
          "2.6 DX CRDE (2609cc)",
          "DX 2.6 TURBO 7 STR (2609cc)",
          "DX 2.6 TURBO 7 STR (2609cc)",
          "SLX 2.6 TURBO 8 STR (2609cc)",
          "SLX 2.6 TURBO 8 STR (2609cc)",
          "2.6 SPORTZ CRDE (2609cc)",
          "2.6 DX (2609cc)",
          "2.6 DX (2609cc)",
          "2.6 SLX CRDE (2609cc)",
          "2.6 SLX CRDE (2609cc)",
          "2.6 TURBO 7 STR (2609cc)",
          "2.6 TURBO 7 STR (2609cc)",
          "DX 2.6 TURBO 8 STR (2609cc)",
          "DX 2.6 TURBO 8 STR (2609cc)",
          "SLX 2.6 TURBO 7 STR (2609cc)",
          "SLX 2.6 TURBO 7 STR (2609cc)",
          "LX BS III 9 STR (2609cc)",
          "MDI 9 STR (2609cc)",
          "MDI BS2 2WD (2609cc)",
          "EX 9S BS IV WD (2609cc)",
          "2.6 CRDE GETAWAY (2609cc)",
          "2.6 DX 9STR (2609cc)",
          "2.6 HAWK VLS (2609cc)",
          "2.6 SLX 6STR (2609cc)",
          "2.6 STD CRDE 8 STR (2609cc)",
          "CRDE 9 STR (2609cc)",
          "CRDE SLX (2609cc)",
          "DLX CRDE BSIII (2609cc)",
          "EX BS III 9 STR (2609cc)",
          "HAWK BS3 ABS TC5 2WD (2609cc)",
          "MDI 7STR (2609cc)",
          "SLX CRDE 10 STR (2609cc)",
          "VLX 2WD 8 AIR BAG (2609cc)",
          "VLX 7 STR 2WD (2609cc)",
          "XLXT 2.6 CRDE WD 7S (2609cc)",
          "SLX TURBO 9 SEATER (2609cc)",
          "DLX 7STR (2609cc)",
          "DX TURBO 10STR (2609cc)",
          "DX TURBO 7STR (2609cc)",
          "DX TURBO 8STR (2609cc)",
          "DX TURBO 9STR (2609cc)",
          "GLX TURBO 7STR (2609cc)",
          "GLX TURBO 8STR (2609cc)",
          "GLX TURBO 9STR (2609cc)",
          "LX TURBO 7STR (2609cc)",
          "LX TURBO 9STR (2609cc)",
          "SLX TURBO 7STR (2609cc)",
          "SLX TURBO 8STR (2609cc)",
          "SLE (2609cc)",
          "DX TURBO 7 SEATER (2609cc)",
          "DX TURBO 9 SEATER (2609cc)",
          "LX TURBO 7 SEATER (2609cc)",
          "LX TURBO 9 SEATER (2609cc)",
          "DX TURBO 10 SEATER (2609cc)",
          "DX TURBO 8 SEATER (2609cc)",
          "GLX TURBO 7 SEATER (2609cc)",
          "GLX TURBO 8 SEATER (2609cc)",
          "GLX TURBO 9 SEATER (2609cc)",
          "SLX TURBO 7 SEATER (2609cc)",
          "SLX TURBO 8 SEATER (2609cc)",
          "DLX 7 SEATER (2609cc)",
          "2.6 TURBO 10 SEATER (2609cc)",
          "CRDE 7STR (2609cc)",
          "SLX 2.6 TURBO CRDE 4WD (2609cc)",
          "2.6 TURBO 7-SEATER (2609cc)",
          "SLX 2.6 TURBO 7-SEATER (2609cc)",
          "DX 2.6 TURBO 8-SEATER (2609cc)",
          "DX 2.6 TURBO 7-SEATER (2609cc)",
          "DLX 10STR (2609cc)",
          "SLX 2.6 TURBO 8-SEATER (2609cc)",
          "CRDE 8STR (2609cc)",
          "2.6 GLX 8STR (2609cc)",
          "M2 DI (2609cc)",
          "LX 2.6 TURBO (2609cc)",
          "LX 2.6 TURBO (2609cc)",
          "2.6 GLX (2609cc)",
          "2.6 GLX (2609cc)",
          "SLE 2.6 CRDE (2609cc)",
          "SLE 2.6 CRDE (2609cc)",
          "DLX (2609cc)",
          "2.6 TURBO 9 SEATER (2609cc)",
          "GETAWAY 2WD (2609cc)",
          "2WD AC (2609cc)",
          "REV 116 (2609cc)",
          "GETAWAY 4 WD (2609cc)",
          "2WD CRDE SF 7 STR (2860cc)",
          "SLX 2.6 TURBO 4WD (2906cc)",
          "H2 (2956cc)",
          "H3 (2956cc)",
        ],
      },
      {
        model: "SCORPIO N",
        variants: [
          "Z2 P MT (1997cc)",
          "Z4 P MT (1997cc)",
          "Z8 P MT (1997cc)",
          "Z8 L P MT (1997cc)",
          "Z4 AT 7STR PETROL (1997cc)",
          "Z8 AT 7STR PETROL (1997cc)",
          "Z8 L AT 7STR PETROL (1997cc)",
          "Z8 L MT 6STR PETROL (1997cc)",
          "Z8 L AT 6STR PETROL (1997cc)",
          "G MT 2WD Z4 7S SF (1997cc)",
          "G AT 2WD Z8L 7S (1997cc)",
          "Z4 MT 7 SEATER PETROL (E) (1997cc)",
          "Z2 MT 7SEATER PETROL (E) (1997cc)",
          "Z4 PETROL AT 7 SEAT (1997cc)",
          "Z2 E PETROL 7 SEAT (1997cc)",
          "Z4 PETROL 7 SEAT (1997cc)",
          "Z8 L PETROL 6 STR (1997cc)",
          "Z2 PETROL 7 SEAT (1997cc)",
          "Z4 E PETROL 7 SEAT (1997cc)",
          "Z8 PETROL 7 STR (1997cc)",
          "Z8 L PETROL 7 STR (1997cc)",
          "Z4 PETROL AT 7 STR (1997cc)",
          "Z4 PETROL AT 7 STR (1997cc)",
          "Z8 PETROL AT 7 STR (1997cc)",
          "Z8 PETROL AT 7 STR (1997cc)",
          "Z8 L PETROL MT 6 STR (1997cc)",
          "Z8 L PETROL MT 6 STR (1997cc)",
          "Z8 L PETROL AT 7 STR (1997cc)",
          "Z8 L PETROL AT 7 STR (1997cc)",
          "Z8 L PETROL AT 6 STR (1997cc)",
          "Z8 L PETROL AT 6 STR (1997cc)",
          "Z2 PETROL MT 7 STR E (1997cc)",
          "Z2 PETROL MT 7 STR E (1997cc)",
          "Z4 PETROL MT 7 STR E (1997cc)",
          "Z4 PETROL MT 7 STR E (1997cc)",
          "Z2 MT BSVI (1997cc)",
          "Z4 MT BSVI (1997cc)",
          "Z8 MT BSVI (1997cc)",
          "Z8 L MT BSVI (1997cc)",
          "Z4 AT BSVI (1997cc)",
          "Z8 AT BSVI (1997cc)",
          "Z8 L AT BSVI (1997cc)",
          "Z2 PETROL MT 7 STR (1997cc)",
          "Z4 PETROL MT 7 STR (1997cc)",
          "Z4 PETROL MT 7 STR (1997cc)",
          "Z8 PETROL MT 7 STR (1997cc)",
          "Z8 PETROL MT 7 STR (1997cc)",
          "Z8 L PETROL MT 7 STR (1997cc)",
          "Z8 L PETROL MT 7 STR (1997cc)",
          "Z8 L MT PETROL 6 STR (1997cc)",
          "Z4 AT PETROL 7 STR (1997cc)",
          "Z8 L AT PETROL 6 STR (1997cc)",
          "Z8 AT PETROL 7 STR (1997cc)",
          "Z4 MT PETROL 7 STR (1997cc)",
          "Z8 L MT PETROL 7 STR (1997cc)",
          "Z8 MT PETROL 7 STR (1997cc)",
          "Z2 MT PETROL 7 STR (1997cc)",
          "Z8 L AT PETROL 7 STR (1997cc)",
          "Z2 D MT (2184cc)",
          "Z4 D MT (2184cc)",
          "Z6 D MT (2184cc)",
          "Z8 D MT (2184cc)",
          "Z8 L D MT (2184cc)",
          "Z8 L MT 6STR DIESEL.. (2184cc)",
          "Z4 D AT 2WD 7STR (2184cc)",
          "Z2 MT 7SEATER DIESEL (E) (2184cc)",
          "G MT 2WD Z8L 7S (2184cc)",
          "Z4 DIESEL AWD 7 SEAT (2184cc)",
          "Z8 DIESEL AT 6 STR (2184cc)",
          "Z4 DIESEL 7 SEAT (2184cc)",
          "Z4 DIESEL AT 7 SEAT (2184cc)",
          "Z8 L DIESEL 6 STR (2184cc)",
          "Z8 DIESEL 4X4 7 SEATING (2184cc)",
          "Z8 L DIESEL 4X4  7 SEATING (2184cc)",
          "Z8 L DIESEL 4X4 AT 7 SEATING (2184cc)",
          "Z2 DIESEL 7 SEAT (2184cc)",
          "Z8 L AT DIESEL 7 STR (2184cc)",
          "Z4 DIESEL AT 4WD 7 SEAT (2184cc)",
          "Z6 DIESEL 7 STR (2184cc)",
          "Z8 L DIESEL 7 STR (2184cc)",
          "Z8 DIESEL 7 STR (2184cc)",
          "Z2 DIESEL MT 7 STR (2184cc)",
          "Z2 DIESEL MT 7 STR (2184cc)",
          "Z4 DIESEL MT 2WD 7 STR (2184cc)",
          "Z4 DIESEL MT 2WD 7 STR (2184cc)",
          "Z4 DIESEL AT 2WD 7 STR (2184cc)",
          "Z4 DIESEL AT 2WD 7 STR (2184cc)",
          "Z8 L DIESEL MT 2WD 6 STR (2184cc)",
          "Z8 L DIESEL MT 2WD 6 STR (2184cc)",
          "Z2 DIESEL MT 7 STR E (2184cc)",
          "Z2 DIESEL MT 7 STR E (2184cc)",
          "Z4 DIESEL MT 2WD 7 STR E (2184cc)",
          "Z4 DIESEL MT 2WD 7 STR E (2184cc)",
          "Z4 DIESEL MT 4WD 7 STR E (2184cc)",
          "Z4 DIESEL MT 4WD 7 STR E (2184cc)",
          "Z8 DIESEL AT 4WD 7 STR (2184cc)",
          "Z8 DIESEL AT 4WD 7 STR (2184cc)",
          "Z8 DIESEL AT 2WD 7 STR (2184cc)",
          "Z8 DIESEL AT 2WD 7 STR (2184cc)",
          "Z8 L DIESEL MT 4WD 7 STR... (2184cc)",
          "Z8 L DIESEL MT 4WD 7 STR (2184cc)",
          "Z8 DIESEL MT 4WD 7 STR... (2184cc)",
          "Z8 DIESEL MT 4WD 7 STR (2184cc)",
          "Z6 DIESEL AT 2WD 7 STR (2184cc)",
          "Z6 DIESEL AT 2WD 7 STR (2184cc)",
          "Z4 DIESEL MT 4WD 7 STR (2184cc)",
          "Z4 DIESEL MT 4WD 7 STR (2184cc)",
          "Z8 DIESEL MT 2WD 7 STR (2184cc)",
          "Z8 DIESEL MT 2WD 7 STR (2184cc)",
          "Z8 L DIESEL MT 2WD 7 STR (2184cc)",
          "Z8 L DIESEL MT 2WD 7 STR (2184cc)",
          "Z6 DIESEL MT 2WD 7 STR (2184cc)",
          "Z6 DIESEL MT 2WD 7 STR (2184cc)",
          "Z8 L DIESEL AT 4WD 7 STR (2184cc)",
          "Z8 L DIESEL AT 4WD 7 STR (2184cc)",
          "Z8 L DIESEL AT 2WD 6 STR (2184cc)",
          "Z8 L DIESEL AT 2WD 6 STR (2184cc)",
          "Z8 L DIESEL AT 2WD 7 STR (2184cc)",
          "Z8 L DIESEL AT 2WD 7 STR (2184cc)",
          "Z4 4WD MT DIESEL 7 STR (2184cc)",
          "Z4 2WD AT DIESEL 7 STR (2184cc)",
          "Z8 L 2WD MT DIESEL 6 STR (2184cc)",
          "Z6 2WD AT DIESEL 7 STR (2184cc)",
          "Z8 L 2WD AT DIESEL 7 STR (2184cc)",
          "Z8 L 2WD AT DIESEL 6 STR (2184cc)",
          "Z8 4WD AT DIESEL 7 STR (2184cc)",
          "Z8 2WD AT DIESEL 7 STR (2184cc)",
          "Z8 L 4WD AT DIESEL 7 STR (2184cc)",
          "Z2 MT DIESEL 7 STR (2184cc)",
          "Z4 2WD MT DIESEL 7 STR (2184cc)",
          "Z6 2WD MT DIESEL 7 STR (2184cc)",
          "Z8 2WD MT DIESEL 7 STR (2184cc)",
          "Z8 L 2WD MT DIESEL 7 STR (2184cc)",
          "Z8 4WD MT DIESEL 7 STR (2184cc)",
          "Z8 L 4WD MT DIESEL 7 STR (2184cc)",
          "Z4 AT 7STR DIESEL (2198cc)",
          "Z4 MT 7STR 4WD DIESEL (2198cc)",
          "Z6 AT 7STR DIESEL (2198cc)",
          "Z8 AT 7STR DIESEL (2198cc)",
          "Z8 MT 7STR 4WD DIESEL (2198cc)",
          "Z8 AT 7STR 4WD DIESEL (2198cc)",
          "Z8 L AT 7STR DIESEL (2198cc)",
          "Z8 L MT 7STR 4WD DIESEL (2198cc)",
          "Z8 L AT 7STR 4WD DIESEL (2198cc)",
          "Z8 L MT 6STR DIESEL (2198cc)",
          "Z8 L AT 6STR DIESEL (2198cc)",
          "Z2 E DIESEL 7 SEAT (2198 CC) (2198cc)",
          "Z8 L DIESEL 7 STR AT (2198 CC) (2198cc)",
          "Z4 E DIESEL 4X4 7 SEAT (2198 CC) (2198cc)",
          "Z4 E DIESEL 7 SEAT (2198 CC) (2198cc)",
          "Z8 L DIESEL 7 STR (2198 CC) (2198cc)",
          "Z2 MT BSVI (2198cc)",
          "Z4 MT BSVI (2198cc)",
          "Z6 MT BSVI (2198cc)",
          "Z8 MT BSVI (2198cc)",
          "Z8 L MT BSVI (2198cc)",
          "Z4 AT BSVI (2198cc)",
          "Z8 AT BSVI (2198cc)",
          "Z8 L AT BSVI (2198cc)",
          "Z4 MT 4WD BSVI (2198cc)",
          "Z6 AT BSVI (2198cc)",
          "Z8 MT 4WD BSVI (2198cc)",
          "Z8 AT 4WD BSVI (2198cc)",
          "Z8 L MT 4WD BSVI (2198cc)",
          "Z8 L AT 4WD BSVI (2198cc)",
          "Z2 PETROL MT 7 STR (2609cc)",
        ],
      },
      {
        model: "SCORPIO MHAWK",
        variants: [
          "S4 PLUS (1997cc)",
          "VLS AT (2179cc)",
          "VLS (2179cc)",
          "SLE (2179cc)",
          "VLX AT (2179cc)",
          "S4 PLUS (2179cc)",
          "VLX (2179cc)",
          "S6 PLUS (2179cc)",
          "2WD TCI 9ST (2179cc)",
          "S4 (2179cc)",
          "S6 (2179cc)",
          "LX 9 SEATER (2179cc)",
          "S8 (2179cc)",
          "S10 (2179cc)",
          "LX 7 SEATER (2179cc)",
          "S10 (2609cc)",
        ],
      },
      {
        model: "SCORPIO M HAWK",
        variants: [
          "2.0 S4 7 SEATER (1997cc)",
          "2.0 S4 4WD 7 SEATER (1997cc)",
          "2.0 S4 PLUS 4WD 7 SEATER (1997cc)",
          "2.0 S4 8 SEATER (1997cc)",
          "2.0 S4 9 SEATER (1997cc)",
          "2.0 S4 PLUS 4WD 9 SEATER (1997cc)",
          "2.0 S4 PLUS 9 SEATER (1997cc)",
          "2.0 S10 7 SEATER (1997cc)",
          "2.0 S6 PLUS 8 SEATER (1997cc)",
          "2.0 S8 8 SEATER (1997cc)",
          "2.0 S10 4 WD 8 SEATER (1997cc)",
          "2.0 S4 PLUS 7 SEATER (1997cc)",
          "2.0 S6 PLUS 7 SEATER (1997cc)",
          "2.0 S8 PLUS 7 SEATER (1997cc)",
          "2.0 S8 7 SEATER (1997cc)",
          "1.99 S6 PLUS (1997cc)",
          "1.99 S10 (1997cc)",
          "1.99 S10 4WD (1997cc)",
          "1.99 S4 (1997cc)",
          "1.99 S4 PLUS (1997cc)",
          "1.99 S4 PLUS 4WD (1997cc)",
          "1.99 S8 (1997cc)",
          "S5 140 BHP 7 SEATER (2179cc)",
          "S11 4WD BULLET PROOF 7 SEAT (2179cc)",
          "S9 8 SEATER 140 PS (2179cc)",
          "S5 7 SEATER BULLET PROOF (2179cc)",
          "S4 2WD 7 SEATER (2179cc)",
          "S5 140 BHP 9 SEATER (2179cc)",
          "S3 PLUS 120 BHP 9 SEATER (2179cc)",
          "S3 PLUS 120 BHP 7 SEATER (2179cc)",
          "S11 4WD 8 SEATER (2179cc)",
          "GETAWAY 4WD 5 SEATER (2179cc)",
          "S9 7 SEATER 140 PS (2179cc)",
          "S7 140 BHP 7 SEATER (2179cc)",
          "VLX 5 SEATER (2179cc)",
          "GETAWAY 5 SEATER (2179cc)",
          "S11 8 SEATER (2179cc)",
          "S6 10 SEATER (2179cc)",
          "S5 7 SEATER (2179cc)",
          "S5 9 SEATER (2179cc)",
          "S7 120 BHP 7 SEATER (2179cc)",
          "S7 140 BHP 9 SEATER (2179cc)",
          "S11 7 SEATER (2179cc)",
          "S11 4WD 7 SEATER (2179cc)",
          "S7 120 BHP 9 SEATER (2179cc)",
          "S10 4WD 8 SEATER (2179cc)",
          "S10 AT 8 SEATER (2179cc)",
          "VLX 7 SEATER 4WD AT (2179cc)",
          "VLX 8 SEATER 4WD AT (2179cc)",
          "VLX ABS 7 SEATER 2WD AT (2179cc)",
          "VLX ABS 7 SEATER 4WD AT (2179cc)",
          "VLX AIRBAG 8 SEATER 2WD MT (2179cc)",
          "S7 120 BHP 8 SEATER (2179cc)",
          "S7 140 BHP 8 SEATER (2179cc)",
          "S10 ADVENTURE 7 SEATER (2179cc)",
          "S10 ADVENTURE 8 SEATER (2179cc)",
          "S10 AT 7 SEATER (2179cc)",
          "S10 4WD AT 7 SEATER (2179cc)",
          "LX 8 SEATER (2179cc)",
          "VLX 9 SEATER (2179cc)",
          "LX 4WD 7 SEATER (2179cc)",
          "LX 7STR (2179cc)",
          "LX 9STR (2179cc)",
          "S10 8STR (2179cc)",
          "S4 4X2 8STR (2179cc)",
          "S4 9STR (2179cc)",
          "S4 PLUS 4WD 7STR (2179cc)",
          "S4 PLUS 4WD 9STR (2179cc)",
          "S4 PLUS 7STR (2179cc)",
          "S4 PLUS 9STR (2179cc)",
          "S6 8STR (2179cc)",
          "S6 PLUS 7STR (2179cc)",
          "S6 PLUS 8STR (2179cc)",
          "S8 8STR (2179cc)",
          "SLE 7STR (2179cc)",
          "SLE 8STR (2179cc)",
          "SPECIAL EDITION (VLX) 7STR (2179cc)",
          "SPECIAL EDITION (VLX) 8STR (2179cc)",
          "VLS 7STR (2179cc)",
          "VLS 8STR (2179cc)",
          "VLS 7STR (AT) (2179cc)",
          "VLS 8STR (AT) (2179cc)",
          "VLX 4WD 7STR (2179cc)",
          "VLX 4WD 8STR (2179cc)",
          "VLX 4WD AIRBAG 7STR (2179cc)",
          "VLX 4WD AIRBAG 8STR (2179cc)",
          "VLX 7STR (2179cc)",
          "VLX 7STR AIRBAG (2179cc)",
          "VLX 8STR (2179cc)",
          "VLX 4WD AIRBAG 7STR (AT) (2179cc)",
          "VLX 4WD AIRBAG 8STR (AT) (2179cc)",
          "VLX 7STR (AT) (2179cc)",
          "VLX 8STR (AT) (2179cc)",
          "VLX AIRBAG 7STR (AT) (2179cc)",
          "VLX AIRBAG 8STR (AT) (2179cc)",
          "S5 2WD (2179cc)",
          "S9 2WD (2179cc)",
          "S11 4WD BULLET PROOF (2179cc)",
          "S11 2W (2179cc)",
          "2.2 S3 + MHAWAK (2179cc)",
          "S3 + 2W (2179cc)",
          "S3 PLUS BSVI (2179cc)",
          "S5 BSVI (2179cc)",
          "S7 BSVI (2179cc)",
          "S9 BSVI (2179cc)",
          "S11 BSVI (2179cc)",
          "S7 8STR BSVI (2179cc)",
          "S4 PLUS 4WD 7 SEATER (2179cc)",
          "S5 MH 2.2 140 2WD 9S (2179cc)",
          "2.2 S3 PLUS (2179cc)",
          "S5 2WD 7 SEATER (2179cc)",
          "S5 2WD 9 SEATER (2179cc)",
          "S7 120 2WD 7 SEATER (2179cc)",
          "S7 120 2WD 8 SEATER (2179cc)",
          "S7 140 2WD 7 SEATER (2179cc)",
          "S7 140 2WD 8 SEATER (2179cc)",
          "S11 2WD (2179cc)",
          "S11 4WD (2179cc)",
          "S10 4WD (2179cc)",
          "S4 4WD (2179cc)",
          "S6 PLUS 7 SEATER (2179cc)",
          "S4 4X2 8 SEATER (2179cc)",
          "S6 8 SEATER (2179cc)",
          "S6 PLUS 8 SEATER (2179cc)",
          "S4 9 SEATER (2179cc)",
          "S4 PLUS 7 SEATER (2179cc)",
          "S4 PLUS 9 SEATER (2179cc)",
          "S4 PLUS 4WD 9 SEATER (2179cc)",
          "SLE 7 SEATER (2179cc)",
          "SLE 8 SEATER (2179cc)",
          "S8 8 SEATER (2179cc)",
          "S10 8 SEATER (2179cc)",
          "SPECIAL EDITION (VLX) 7 SEATER (2179cc)",
          "SPECIAL EDITION (VLX) 8 SEATER (2179cc)",
          "VLX 4WD 7 SEATER (2179cc)",
          "VLX 4WD 8 SEATER (2179cc)",
          "VLX AT AIR BAG 7 SEATER (2179cc)",
          "VLX AT AIR BAG 8 SEATER (2179cc)",
          "VLX AT 4WD AIR BAG 7 SEATER (2179cc)",
          "VLX AT 4WD AIR BAG 8 SEATER (2179cc)",
          "VLS 7 SEATER (2179cc)",
          "VLS AT 7 SEATER (2179cc)",
          "VLX 7 SEATER (2179cc)",
          "VLX 8 SEATER (2179cc)",
          "VLX AT 7 SEATER (2179cc)",
          "VLX AT 8 SEATER (2179cc)",
          "VLX 4WD AIR BAG 7 SEATER (2179cc)",
          "VLX 4WD AIR BAG 8 SEATER (2179cc)",
          "VLS 8 SEATER (2179cc)",
          "VLS AT 8 SEATER (2179cc)",
          "VLX 7 SEATER AIR BAG (2179cc)",
          "SC RF (2200cc)",
          "S2 9STR (2523cc)",
          "S3 PLUS 2WD (2523cc)",
          "S2 (2523cc)",
          "S2 9 SEATER (2523cc)",
          "S3 2WD SEATER (2523cc)",
          "S3 2WD 9 SEATER (2523cc)",
        ],
      },
      {
        model: "SCORPIO CLASSIC",
        variants: [
          "S11 (2179cc)",
          "S11 (2179cc)",
          "S MT BSVI (2183cc)",
          "S11 MT BSVI (2183cc)",
          "S MT 9STR BSVI (2183cc)",
          "2.2 S MT DIESEL 9 SEATER 2184 CC (2184cc)",
          "2.2 S MT DIESEL 7 SEATER 2184 CC (2184cc)",
          "2.2 S11 MT DIESEL 7 SEATER 2184 CC (2184cc)",
          "S11 7S CC (2184cc)",
          "S11 7S CC (2184cc)",
        ],
      },
      {
        model: "SUPRO",
        variants: [
          "7+1 SEATER VX/ BSIV (909cc)",
          "MINIVAN 8STR (909cc)",
          "MINI VAN VX CNG (909cc)",
          "VX BS III 5 STR (909cc)",
          "VX BS III 8 STR (909cc)",
          "ZX BS III 5 STR (909cc)",
          "8 STR (909cc)",
          "LX BS III 8 STR (909cc)",
          "LX BSIII 8STR (909cc)",
          "LX 10 STR (909cc)",
          "LX 10 STR ABS (909cc)",
          "VX 10 STR (909cc)",
          "VX 10STR ABS (909cc)",
          "VX BSIV (909cc)",
          "ZX 10 STR ABS (909cc)",
          "LX 10 SEATER (909cc)",
          "VX 10 SEATER (909cc)",
          "ZX 10 SEATER (909cc)",
          "LX 8 SEATER (909cc)",
          "MINIVAN 8 SEATER 19 KW (909cc)",
          "ZX 5 SEATER (909cc)",
          "ZX BS IV 5 STR (909cc)",
          "ZX BS IV 5 STR (909cc)",
          "VX BS IV 8 STR (909cc)",
          "VX BS IV 8 STR (909cc)",
          "LX BS IV 8 STR (909cc)",
          "LX BS IV 8 STR (909cc)",
          "VX BS IV 5 STR (909cc)",
          "VX BS IV 5 STR (909cc)",
          "MINI VAN (909cc)",
          "ZX BS IV (909cc)",
          "5SEATER/BS-III/ZX (909cc)",
          "5SEATER/BS-III/VX (909cc)",
          "8SEATER/BS-III/VX (909cc)",
          "8SEATER/BS-III/LX (909cc)",
          "SUPRO (909cc)",
          "VX/CNG (909cc)",
          "9 SEATER/SCH VAN (909cc)",
          "11 SEATER/SCH VAN (909cc)",
          "13 SEATER/SCH VAN (909cc)",
          "MINI VAN VX YG BSVI (909cc)",
          "VX YG BSVI (909cc)",
          "LX (909cc)",
          "VX (909cc)",
          "ZX (909cc)",
          "ZX 8 SEATER (909cc)",
          "VX 5 SEATER (909cc)",
          "VX 8 SEATER (909cc)",
          "VX 8 STR (909cc)",
          "VX 8STR (909cc)",
          "LX 8 STR (909cc)",
          "LX 8STR (909cc)",
          "ZX 8STR (909cc)",
          "PASSENGER VAN (909cc)",
          "MINIVAN VX 8STR (909cc)",
          "VX 7STR (909cc)",
          "LX ABS 10STR (909cc)",
          "MINIVAN VX 10STR (909cc)",
          "ZX ABS 10STR (909cc)",
          "VX ABS 10STR (909cc)",
          "ZX 5 STR (909cc)",
          "VX 5 STR (909cc)",
        ],
      },
      {
        model: "XUV 500",
        variants: [
          "SPORTZ EDITION (2179cc)",
          "W11 OPTION (2179cc)",
          "W11 OPTION (2179cc)",
          "XCLUSIVE (2179cc)",
          "W11 OPTION AT (2179cc)",
          "W11 OPTION AWD AT (2179cc)",
          "W11 OPTION AWD (2179cc)",
          "FWD 7STBSIV (2179cc)",
          "G AT (2179cc)",
          "G AT (2179cc)",
          "G AT (2179cc)",
          "FWD W6 (2179cc)",
          "W8 FWD SPORTZ (2179cc)",
          "6 STR (2179cc)",
          "W9 BS6 (2179cc)",
          "W7 BS6 (2179cc)",
          "4 STR (2179cc)",
          "STD 7 STR (2179cc)",
          "W4 FWD 5 STR (2179cc)",
          "W6 AWD 7STR (2179cc)",
          "W6 FWD 4STR (2179cc)",
          "W8 8 STR (2179cc)",
          "W8 FWD 5 STR (2179cc)",
          "W4 FWD 8 SEATER (2179cc)",
          "W11 FWD 7 SEATER (2179cc)",
          "W8 AT 7SEATER (2179cc)",
          "W10 AWD AT 7 SEATER (2179cc)",
          "PLUSH W11 AT (2179cc)",
          "W6 FWD AT 7 SEATER (2179cc)",
          "W9 FWD MT (2179cc)",
          "W6 5 SEATER (2179cc)",
          "W6 4 SEATER (2179cc)",
          "PLUSH W9 AT (2179cc)",
          "W6 2013 (2179cc)",
          "W6 2013 (2179cc)",
          "W8 AWD 2013 (2179cc)",
          "W8 AWD 2013 (2179cc)",
          "W8 FWD BSIV (2179cc)",
          "W10 AWD AT BLACK INTERIORS (2179cc)",
          "W10 AWD AT BLACK INTERIORS (2179cc)",
          "W10 AT BLACK INTERIORS (2179cc)",
          "W10 AT BLACK INTERIORS (2179cc)",
          "W10 AWD BLACK INTERIORS (2179cc)",
          "W10 AWD BLACK INTERIORS (2179cc)",
          "W10 BLACK INTERIORS (2179cc)",
          "W10 BLACK INTERIORS (2179cc)",
          "W8 MT (2179cc)",
          "FWD W11 (2179cc)",
          "FWD W7 (2179cc)",
          "W8 2013 (2179cc)",
          "W8 2013 (2179cc)",
          "W10 1.99 (2179cc)",
          "W9 1.99 (2179cc)",
          "W9 AT 1.99 (2179cc)",
          "SPORTZ EDITION LTD AT (2179cc)",
          "SPORTZ EDITION LTD AT (2179cc)",
          "SPORTZ EDITION LTD (2179cc)",
          "SPORTZ EDITION LTD (2179cc)",
          "XCLUSIVE EDITION (2179cc)",
          "XCLUSIVE EDITION (2179cc)",
          "W10 AT (2179cc)",
          "W10 AT (2179cc)",
          "W6 AT (2179cc)",
          "W6 AT (2179cc)",
          "XCLUSIVE (DRIVER POWER SEAT) (2179cc)",
          "XCLUSIVE (DRIVER POWER SEAT) (2179cc)",
          "W11 (O) AWD (2179cc)",
          "W11 (O) AWD (2179cc)",
          "W6 2WD (AT) (2179cc)",
          "SPORTZ AWD (AT) (2179cc)",
          "SPORTZ AWD (MT) (2179cc)",
          "W10 2WD (2179cc)",
          "W6 FWD AT 2.2 (2179cc)",
          "W9 FWD (2179cc)",
          "W9 2WD (2179cc)",
          "W9 2WD (AT) (2179cc)",
          "W11 FWD (2179cc)",
          "W11 FWD (O) (2179cc)",
          "W11 AWD (O) (2179cc)",
          "W11 FWD (O) AT (2179cc)",
          "W11 AWD (O) AT (2179cc)",
          "G FWD (AT) (2179cc)",
          "W5 FWD (2179cc)",
          "W7 FWD (AT) (2179cc)",
          "W9 FWD (AT) (2179cc)",
          "W4 FWD REFRESH (2179cc)",
          "W6 FWD REFRESH (2179cc)",
          "W10 FWD SPORTZ (2179cc)",
          "W10 FWD AT REFRESH (2179cc)",
          "W10 AWD REFRESH (2179cc)",
          "W10 AWD AT REFRESH (2179cc)",
          "W3 FWD (2179cc)",
          "AT W6 2WD (2179cc)",
          "AT W10 AWD (2179cc)",
          "AT W10 AWD (2179cc)",
          "AT W10 FWD (2179cc)",
          "AT W8 FWD (2179cc)",
          "SPORTZ MT AWD (2179cc)",
          "W6 AT 7STR (2179cc)",
          "W9 7STR (2179cc)",
          "W9 AMT 7STR (2179cc)",
          "SPORTZ LTD 7STR (2179cc)",
          "W10 FWD 7STR (2179cc)",
          "W8 2WD 7STR (2179cc)",
          "W8 AWD 7STR (2179cc)",
          "W8 FWD 7STR (2179cc)",
          "W8 FWD XCLUSIVE EDTN (2179cc)",
          "W10 7STR (2179cc)",
          "W10 AWD 7STR (2179cc)",
          "W6 7STR (2179cc)",
          "W8 AT 7STR (2179cc)",
          "W10 AT 7STR (2179cc)",
          "W10 AWD AT 7STR (2179cc)",
          "W5 7STR (2179cc)",
          "W7 7STR (2179cc)",
          "W7 AT 7STR (2179cc)",
          "G AT 7STR (2179cc)",
          "W11 7STR (2179cc)",
          "W9 AT 7STR (2179cc)",
          "W11(O) 7STR (2179cc)",
          "W11 AT 7STR (2179cc)",
          "W11(O) AWD 7STR (2179cc)",
          "W11(O) AT 7STR (2179cc)",
          "SPORTZ AT AWD (2179cc)",
          "W4 FWD 7STR (2179cc)",
          "W6 FWD 7 STR (2179cc)",
          "W7 FWD (2179cc)",
          "W4 FWD (2179cc)",
          "W8 FWD XCLUSIVE EDITION (2179cc)",
          "W8 AT (2179cc)",
          "W8 AT (2179cc)",
          "SPORTZ LTD (2179cc)",
          "SPORTZ LTD (2179cc)",
          "W10 FWD REFRESH (2179cc)",
          "W10 FWD REFRESH (2179cc)",
          "G AT PETROL (2179cc)",
          "W10 R AWD (2179cc)",
          "W8 R FWD (2179cc)",
          "W4 R FWD (2179cc)",
          "W8 4WD (2179cc)",
          "SPORTZ (2179cc)",
          "W6 FWD AT (2179cc)",
          "W11 AWD OPT (2179cc)",
          "W6 R FWD (2179cc)",
          "W11 AWD AT (2179cc)",
          "W10 R FWD (2179cc)",
          "W8 (2179cc)",
          "W8 (2179cc)",
          "W11 (O) AT (2179cc)",
          "W11 (O) AT (2179cc)",
          "W11 (O) AWD AT (2179cc)",
          "W11 (O) AWD AT (2179cc)",
          "W11(O) (2179cc)",
          "W11(O) (2179cc)",
          "W10 (2179cc)",
          "W10 (2179cc)",
          "W9 (2179cc)",
          "W9 (2179cc)",
          "W9 AT (2179cc)",
          "W9 AT (2179cc)",
          "W5 (2179cc)",
          "W5 (2179cc)",
          "W7 AT (2179cc)",
          "W7 AT (2179cc)",
          "W11 (2179cc)",
          "W11 (2179cc)",
          "W11 AT (2179cc)",
          "W11 AT (2179cc)",
          "W7 (2179cc)",
          "W7 (2179cc)",
          "W3 (2179cc)",
          "W3 (2179cc)",
          "W8 FWD AT (2179cc)",
          "W11 FWD AT (2179cc)",
          "W8 2WD (2179cc)",
          "W10 FWD AT (2179cc)",
          "W6 FWD (2179cc)",
          "W4 (2179cc)",
          "W4 (2179cc)",
          "W10 AWD AT (2179cc)",
          "W10 AWD AT (2179cc)",
          "W8 FWD (2179cc)",
          "W8 AWD (2179cc)",
          "W8 AWD (2179cc)",
          "W10 AWD (2179cc)",
          "W10 AWD (2179cc)",
          "W6 (2179cc)",
          "W6 (2179cc)",
          "W10 FWD. (2179cc)",
          "W8 1.9 AWD (1997cc)",
          "W10 1.9 AWD (1997cc)",
          "R W8 FWD (1997cc)",
          "W8 1.9 FWD (1997cc)",
          "W10  1997CC (1997cc)",
          "W10 AT 1997 CC (1997cc)",
          "2.0 W6 FWD AT 7 SEATER (1997cc)",
          "2.0 W8 FWD AT 7 SEATER (1997cc)",
          "2.0 W10 FWD 7 SEATER (1997cc)",
          "2.0 W9 FWD MT 7 SEATER (1997cc)",
          "2.0 W9 FWD AT 7 SEATER (1997cc)",
          "2.0 W8 FWD XCLUSIVE EDITION 7 SEATER (1997cc)",
          "2.0 W10 7 SEATER (1997cc)",
          "2.0 W10 AWD 7 SEATER (1997cc)",
          "2.0 W4 FWD 7 SEATER (1997cc)",
          "2.0 W6 7 SEATER (1997cc)",
          "2.0 W6 FWD 7 SEATER (1997cc)",
          "2.0 W8 7 SEATER (1997cc)",
          "2.0 W8 AWD 7 SEATER (1997cc)",
          "2.0 W8 FWD 7 SEATER (1997cc)",
          "2.0 W10 AT 7 SEATER (1997cc)",
          "R 1.99 FWD W (1997cc)",
          "R 1.99 FWD W8 (1997cc)",
          "W10 1.99 (1997cc)",
          "W10 1.99 (1997cc)",
          "W6 1.99 (1997cc)",
          "W6 1.99 (1997cc)",
          "W9 1.99 (1997cc)",
          "W9 1.99 (1997cc)",
          "W9 AT 1.99 (1997cc)",
          "W9 AT 1.99 (1997cc)",
          "W8 AT 1.99 (1997cc)",
          "W8 AT 1.99 (1997cc)",
          "W6 AT 1.99 (1997cc)",
          "W6 AT 1.99 (1997cc)",
          "W10 AT (1997cc)",
          "W6 AT (1997cc)",
          "W4 1.99 (1997cc)",
          "W4 1.99 (1997cc)",
          "W10 AT 1.99 (1997cc)",
          "W10 AT 1.99 (1997cc)",
          "W8 1.99 (1997cc)",
          "W8 1.99 (1997cc)",
          "W10 1.99 MHAWK (AT) (1997cc)",
          "W6 1.99 MHAWK (AT) (1997cc)",
          "W8 1.99 MHAWK (AT) (1997cc)",
          "W10 1.99 MHAWK (1997cc)",
          "W4 1.99 MHAWK (1997cc)",
          "W6 1.99 MHAWK (1997cc)",
          "W8 1.99 MHAWK (1997cc)",
          "2.0 W10 FWD 7STR (AT) (1997cc)",
          "1.9 W9 FWD MHAWK (1997cc)",
          "1.9 W9 FWD (AT) MHAWK (1997cc)",
          "2.0 W10 (1997cc)",
          "2.0 W10 AWD (1997cc)",
          "2.0 W4 FWD (1997cc)",
          "2.0 W6 (1997cc)",
          "2.0 W6 FWD (1997cc)",
          "2.0 W8 (1997cc)",
          "2.0 W8 AWD (1997cc)",
          "2.0 W8 FWD (1997cc)",
          "AT W10 1.99 MHAWK (1997cc)",
          "AT W6 1.99 MHAWK (1997cc)",
          "AT W8 1.99 MHAWK (1997cc)",
          "W6 AT (1997) 7STR (1997cc)",
          "W9 7STR (1997cc)",
          "W9 AMT 7STR (1997cc)",
          "W10 (1997) (1997cc)",
          "2.0 W10 AWD 7 STR (1997cc)",
          "W4 (1997) (1997cc)",
          "W6 (1997) (1997cc)",
          "2.0 W6 FWD 7 STR (1997cc)",
          "W8 (1997) (1997cc)",
          "2.0 W8 AWD 7 STR (1997cc)",
          "2.0 W8 FWD 7 STR (1997cc)",
          "W10 AT (1997) 7STR (1997cc)",
          "2.0 W10 FWD AT 7 SEATER (1997cc)",
          "W8 AT (1997cc)",
          "1.9L W9 (1997cc)",
          "1.9L W8 FWD (1997cc)",
          "1.9L W10 R FWD AT (1997cc)",
          "1.9L W6 FWD AT (1997cc)",
          "1.9L W9 AT (1997cc)",
          "1.9L W4 (1997cc)",
          "1.9L W10 R FWD (1997cc)",
          "1.9L W6 (1997cc)",
          "1.9L W8 FWD AT (1997cc)",
          "1.99L W10 R FWD AT (1997cc)",
          "W9 (1997cc)",
          "W9 AT (1997cc)",
          "W4 (1997cc)",
          "W6 (1997cc)",
          "W9 (1197cc)",
        ],
      },
      {
        model: "VOYAGER",
        variants: [
          "STANDARD (2498cc)",
          "STD (2498cc)",
          "STD (2498cc)",
          "VOYAGER (2498cc)",
          "(1997-2002) AC (2533cc)",
          "(1997-2002) STD (2533cc)",
          "AC (2533cc)",
          "AC (2533cc)",
          "STD (2533cc)",
          "STD (2533cc)",
          "7 SEATER/DIESEL (2533cc)",
          "8 SEATER/DIESEL (2533cc)",
          "9 SEATER/DIESEL (2533cc)",
          "10 SEATER/DIESEL (2533cc)",
          "12 SEATER/DIESEL (2533cc)",
          "AC (1498cc)",
        ],
      },
      {
        model: "SCORPIO CRDE",
        variants: [
          "M2 EX 2WD (2498cc)",
          "SLE 7 SEATER (2523 CC) (2523cc)",
          "SLE 5 SEATER (2609cc)",
          "LX 8 SEATER (2609cc)",
          "SLE 6 SEATER (2609cc)",
          "DLX 7STR (2609cc)",
          "DLX 8STR (2609cc)",
          "DX 4WD 7STR (2609cc)",
          "DX 4WD 8STR (2609cc)",
          "DX 7STR (2609cc)",
          "DX 8STR (2609cc)",
          "DX 9STR (2609cc)",
          "LX 7STR (2609cc)",
          "LX 9STR (2609cc)",
          "M2 DI 7STR (2609cc)",
          "M2 DI 9STR (2609cc)",
          "SLE 7STR (2609cc)",
          "SLE 8STR (2609cc)",
          "SLX 7STR (2609cc)",
          "SLX 8STR (2609cc)",
          "SPORTZ 7STR (2609cc)",
          "SPORTZ 8STR (2609cc)",
          "DX 9 SEATER (2609cc)",
          "LX 7 SEATER (2609cc)",
          "SLE 7 SEATER (2609cc)",
          "SLX 7 SEATER (2609cc)",
          "DX 4 WHEEL DRIVE 7 SEATER (2609cc)",
          "DX 4 WHEEL DRIVE 8 SEATER (2609cc)",
          "DX 7 SEATER (2609cc)",
          "DX 8 SEATER (2609cc)",
          "DLX 7 SEATER (2609cc)",
          "DLX 8 SEATER (2609cc)",
          "LX 9 SEATER (2609cc)",
          "SLE 8 SEATER (2609cc)",
          "SLX 8 SEATER (2609cc)",
          "M2 DI 7 SEATER (2609cc)",
          "M2 DI 9 SEATER (2609cc)",
          "SPORTZ 7 SEATER (2609cc)",
          "SPORTZ 8 SEATER (2609cc)",
        ],
      },
      {
        model: "E VERITO",
        variants: [
          "1.1 (31000cc)",
          "D2 (ELECTRIC) (1390cc)",
          "D4 (ELECTRIC) (1390cc)",
          "D6 (ELECTRIC) (1390cc)",
          "C4 (1461cc)",
          "C2 (1461cc)",
          "C6 (1461cc)",
          "C2 (30500cc)",
          "C6 (30500cc)",
        ],
      },
      {
        model: "E-VERITO",
        variants: [
          "D4 (31000cc)",
          "D6 (31000cc)",
          "D2 (31cc)",
          "D2 (31cc)",
          "D4 (31cc)",
          "D4 (31cc)",
          "D6 (31cc)",
          "D6 (31cc)",
          "D2 (1461cc)",
          "D4 (1461cc)",
          "D6 (1461cc)",
          "D2 (30500cc)",
          "D4 (30500cc)",
          "D6 (30500cc)",
          "D2 (31500cc)",
          "D2 (72cc)",
          "D4 (72cc)",
          "D6 (72cc)",
          "D2 (1000cc)",
          "D4 (1000cc)",
          "D6 (1000cc)",
        ],
      },
      {
        model: "EVERITO",
        variants: [
          "C4 (31cc)",
          "C4 (31cc)",
          "C2 (31cc)",
          "C2 (31cc)",
          "C6 (31cc)",
          "C6 (31cc)",
        ],
      },
      {
        model: "KUV 100",
        variants: [
          "TRIP 6STR (1198cc)",
          "TRIP 6STR (1198cc)",
          "MFALCON G80 K4 (1198cc)",
          "MFALCON G80 K2 PLUS (1198cc)",
          "MFALCON G80 K6 5 SEATER (1198cc)",
          "MFALCON G80 K8 5 SEATER (1198cc)",
          "MFALCON G80 K6 PLUS 5 SEATER (1198cc)",
          "MFALCON G80 K4 5 SEATER (1198cc)",
          "MFALCON G80 K4 PLUS (1198cc)",
          "MFALCON G80 K6 (1198cc)",
          "MFALCON G80 K8 (1198cc)",
          "MFALCON G80 K2 (1198cc)",
          "MFALCON G80 K4 PLUS 5 SEATER (1198cc)",
          "MFALCON G80 K6 PLUS (1198cc)",
          "MFALCON D75 K8 5 SEATER (1198cc)",
          "MFALCON D75 K4 5 SEATER (1198cc)",
          "MFALCON D75 K6 (1198cc)",
          "MFALCON D75 K6 5 SEATER (1198cc)",
          "MFALCON D75 K4 PLUS 5 SEATER (1198cc)",
          "MFALCON D75 K6 PLUS (1198cc)",
          "MFALCON D75 K6 PLUS 5 SEATER (1198cc)",
          "MFALCON D75 K8 (1198cc)",
          "TRIP CNG. (1198cc)",
          "K8 G80 (1198cc)",
          "K4 G80 PLUS (1198cc)",
          "K6 D75 PLUS (1198cc)",
          "K6 5 STR P (1198cc)",
          "K2 5 STR (1198cc)",
          "K2 PLUS 5STR (1198cc)",
          "K8 G80 6 STR BS6 (1198cc)",
          "K4 MFALCOND75 (1198cc)",
          "K2 6 STR PLUS (1198cc)",
          "K4 G80 (1198cc)",
          "K6 D75 (1198cc)",
          "K6 G80 (1198cc)",
          "K6 G80 PLUS (1198cc)",
          "K8 D75 (1198cc)",
          "K8 G80 6 STR (1198cc)",
          "TRIP 5 STR (1198cc)",
          "TRIP 6 STR BI-FUEL (1198cc)",
          "1.2 TRIP DIESEL 6 SEATER (1198cc)",
          "NXT 1.2 TRIP DIESEL 6 SEATER (1198cc)",
          "NXT 1.2 K6 DIESEL PLUS 6 SEATER (1198cc)",
          "NXT 1.2 TRIP CNG 6 SEATER (1198cc)",
          "K2 NXT PETROL 6 SEATER (1198cc)",
          "K2 NXT DIESEL 6 SEATER (1198cc)",
          "K2 NXT PLUS PETROL 6 SEATER (1198cc)",
          "K2 NXT PLUS DIESEL 6 SEATER (1198cc)",
          "NXT 1.2 K8 DIESEL 6 SEATER (1198cc)",
          "1.2 K2 DIESEL 6 SEATER (1198cc)",
          "1.2 K2 PLUS DIESEL 6 SEATER (1198cc)",
          "1.2 K4 DIESEL 5 SEATER (1198cc)",
          "1.2 K4 DIESEL 6 SEATER (1198cc)",
          "1.2 K4 PLUS DIESEL 5 SEATER (1198cc)",
          "1.2 K4 PLUS DIESEL 6 SEATER (1198cc)",
          "1.2 K6 DIESEL 5 SEATER (1198cc)",
          "1.2 K6 DIESEL 6 SEATER (1198cc)",
          "1.2 K6 PLUS DIESEL 5 SEATER (1198cc)",
          "1.2 K6 PLUS DIESEL 6 SEATER (1198cc)",
          "1.2 K8 DIESEL 5 SEATER (1198cc)",
          "1.2 K8 DIESEL 6 SEATER (1198cc)",
          "1.2 K2 PETROL 6 SEATER (1198cc)",
          "1.2 K2 PLUS PETROL 6 SEATER (1198cc)",
          "1.2 K4 PETROL 5 SEATER (1198cc)",
          "1.2 K4 PETROL 6 SEATER (1198cc)",
          "1.2 K4 PLUS PETROL 5 SEATER (1198cc)",
          "1.2 K4 PLUS PETROL 6 SEATER (1198cc)",
          "1.2 K6 PETROL 5 SEATER (1198cc)",
          "1.2 K6 PETROL 6 SEATER (1198cc)",
          "1.2 K6 PLUS PETROL 6 SEATER (1198cc)",
          "1.2 K8 PETROL 5 SEATER (1198cc)",
          "1.2 K8 PETROL 6 SEATER (1198cc)",
          "1.2 K8 NXT PETROL 6 SEATER (1198cc)",
          "1.2 K8 NXT PETROL 5 SEATER (1198cc)",
          "1.2 TRIP LPG 6 SEATER (1198cc)",
          "NXT 1.2 K2 PETROL 6 SEATER (1198cc)",
          "NXT 1.2 K2  PETROL PLUS 6 SEATER (1198cc)",
          "NXT 1.2 K4  PETROL PLUS 5 SEATER (1198cc)",
          "NXT 1.2 K4  PETROL PLUS 6 SEATER (1198cc)",
          "NXT 1.2 K6  PETROL PLUS 5 SEATER (1198cc)",
          "NXT 1.2 K6  PETROL PLUS 6 SEATER (1198cc)",
          "NXT 1.2 K8  PETROL PLUS 5 SEATER (1198cc)",
          "NXT 1.2 K8  PETROL PLUS 6 SEATER (1198cc)",
          "NXT 1.2 K8  PETROL PLUS DUEL TONE 6 SEATER (1198cc)",
          "NXT 1.2 K2 DIESEL 6 SEATER (1198cc)",
          "NXT 1.2 K2  DIESEL PLUS 6 SEATER (1198cc)",
          "NXT 1.2 K4  DIESEL PLUS 5 SEATER (1198cc)",
          "NXT 1.2 K4  DIESEL PLUS 6 SEATER (1198cc)",
          "NXT 1.2 K6  DIESEL PLUS 5 SEATER (1198cc)",
          "NXT 1.2 K8  DIESEL PLUS 5 SEATER (1198cc)",
          "NXT 1.2 K8  DIESEL PLUS 6 SEATER (1198cc)",
          "NXT 1.2 K8  DIESEL PLUS DUEL TONE 6 SEATER (1198cc)",
          "NXT 1.2 K8 PETROL 6 SEATER (1198cc)",
          "K6PLUS D 6 STR (1198cc)",
          "K6PLUS D 6 STR (1198cc)",
          "K4PLUS 6 STR (1198cc)",
          "K4PLUS 6 STR (1198cc)",
          "K8 D 6 STR DUAL TONE (1198cc)",
          "K8 D 6 STR DUAL TONE (1198cc)",
          "K8 6 STR DUAL TONE (1198cc)",
          "K8 6 STR DUAL TONE (1198cc)",
          "K8 MFALCON G80 5S NP (1198cc)",
          "TRIP 6S BI-FUEL (1198cc)",
          "K6 PLUS MFALCON G80 (1198cc)",
          "K8 MFALCON D75 5S NP (1198cc)",
          "K6PLUS D 5 STR (1198cc)",
          "K6PLUS D 5 STR (1198cc)",
          "K2PLUS D 6 STR... (1198cc)",
          "K2PLUS D 6 STR (1198cc)",
          "K4PLUS D 5 STR... (1198cc)",
          "K4PLUS D 5 STR (1198cc)",
          "K2PLUS 6 STR (1198cc)",
          "K2PLUS 6 STR (1198cc)",
          "K4PLUS 5 STR (1198cc)",
          "K4 PLUS 5STR (1198cc)",
          "K4PLUS D 6 STR (1198cc)",
          "K4PLUS D 6 STR (1198cc)",
          "K6PLUS 6 STR (1198cc)",
          "K6 PLUS 6 STR (1198cc)",
          "NXT G80 K4 5STR (1198cc)",
          "NXT G80 K6 5STR (1198cc)",
          "NXT K6 6STR (1198cc)",
          "NXT K6 5STR (1198cc)",
          "G80 K8 DUAL TONE (1198cc)",
          "1.2 K6 PLUS 5STR (1198cc)",
          "ANNIVERSERY EDITION (1198cc)",
          "NXT G80 ANNIVERSERY EDITION (1198cc)",
          "NXT G80 K4 PLUS 5STR (1198cc)",
          "NXT G80 K6 AW (1198cc)",
          "NXT G80 K6 PLUS (1198cc)",
          "NXT G80 K6 PLUS 5STR AW (1198cc)",
          "NXT G80 K8 5STR (1198cc)",
          "NXT G80 K8 AW (1198cc)",
          "NXT G80 K8 DUAL TONE (1198cc)",
          "NXT G80 K2 (1198cc)",
          "NXT G80 K2 PLUS (1198cc)",
          "K6 AW (1198cc)",
          "K6 PLUS AW (1198cc)",
          "K8 AW (1198cc)",
          "K8 DUAL TONE (1198cc)",
          "NXT M FALCON D75 K2 (1198cc)",
          "NXT M FALCON D75 K2 PLUS (1198cc)",
          "NXT M FALCON D75 K4 (1198cc)",
          "NXT M FALCON D75 K4 5STR (1198cc)",
          "NXT M FALCON D75 K4 PLUS (1198cc)",
          "NXT M FALCON D75 K4 PLUS 5STR (1198cc)",
          "NXT G80 K4 PLUS (1198cc)",
          "NXT G80 K2 6STR (1198cc)",
          "K6 5STR 14 AW (1198cc)",
          "K6+ 5 STR DIESEL 14 AW (1198cc)",
          "K8 5STR 15 AW (1198cc)",
          "K6 6STR 14 AW (1198cc)",
          "K8 6 STR 15 AW (1198cc)",
          "K8 6 STR 15 AW DUAL TONE (1198cc)",
          "K6+ 5 STR PETROL 14 AW (1198cc)",
          "K6+ 6 STR PETROL 14 AW (1198cc)",
          "K8 6 STR PETROL 15 AW (1198cc)",
          "NXT K8 DUAL TONE 6STR (1198cc)",
          "TRIP BI-FUEL 6STR (1198cc)",
          "NXT G80 K6 PLUS 6STR (1198cc)",
          "K2+ MFALCON G80 6S RX (1198cc)",
          "MFALCON D75 K4 PLUS (1198cc)",
          "MFALCON D75 K2 PLUS (1198cc)",
          "MFALCON D75 K4 (1198cc)",
          "MFALCON D75 K2 (1198cc)",
          "G80 ANNIVERSERY EDITION (1198cc)",
          "G80 K4 5STR (1198cc)",
          "G80 K4 PLUS 5STR (1198cc)",
          "G80 K6 5STR (1198cc)",
          "G80 K6 AW (1198cc)",
          "G80 K6 PLUS (1198cc)",
          "G80 K6 PLUS 5STR AW (1198cc)",
          "G80 K8 5STR (1198cc)",
          "G80 K8 AW (1198cc)",
          "G800 K2 (1198cc)",
          "G800 K2 PLUS (1198cc)",
          "M FALCON D75 K4 5STR (1198cc)",
          "M FALCON D75 K4 PLUS 5STR (1198cc)",
          "TRIP (1198cc)",
          "1.2 K6 PLUS PETROL 5 SEATER (1198cc)",
          "K8 D 5 STR (1198cc)",
          "K8 D 5 STR (1198cc)",
          "K2 D 6 STR (1198cc)",
          "K2 D 6 STR (1198cc)",
          "K4 D 5 STR (1198cc)",
          "K4 D 5 STR (1198cc)",
          "K4 D 6 STR (1198cc)",
          "K4 D 6 STR (1198cc)",
          "K6 D 5 STR (1198cc)",
          "K6 D 5 STR (1198cc)",
          "K6 D 6 STR (1198cc)",
          "K6 D 6 STR (1198cc)",
          "K8 D 6 STR (1198cc)",
          "K8 D 6 STR (1198cc)",
          "K6PLUS 5 STR (1198cc)",
          "K6 PLUS 5 STR (1198cc)",
          "TRIP 6STR CNG (1198cc)",
          "TRIP 6 STR CNG (1198cc)",
          "K2 PLUS 6 STR NXT PETROL (1198cc)",
          "K8 5 STR DIESEL (1198cc)",
          "K8 6 STR DIESEL (1198cc)",
          "K6 5 STR DIESEL (1198cc)",
          "K6 PLUS 5 STR NXT DIESEL (1198cc)",
          "K6 6 STR DIESEL (1198cc)",
          "K6 PLUS 5 STR DIESEL (1198cc)",
          "K6 PLUS 6 STR DIESEL (1198cc)",
          "K8 6 STR NXT DIESEL (1198cc)",
          "K4 PLUS 5 STR NXT PETROL (1198cc)",
          "K8 6 STR DUAL TONE NXT DIESEL (1198cc)",
          "K6 PLUS 6 STR NXT DIESEL (1198cc)",
          "K8 6 STR NXT PETROL (1198cc)",
          "K6 PLUS 5 STR PETROL (1198cc)",
          "K6 PLUS 6 STR PETROL (1198cc)",
          "K8 5 STR PETROL (1198cc)",
          "K8 6 STR PETROL (1198cc)",
          "K2 6 STR DIESEL (1198cc)",
          "K2 PLUS 6 STR DIESEL (1198cc)",
          "K4 PLUS 6 STR NXT PETROL (1198cc)",
          "K8 6 STR DUAL TONE NXT PETROL (1198cc)",
          "K4 PLUS 5 STR NXT DIESEL (1198cc)",
          "K2 6 STR NXT DIESEL (1198cc)",
          "K4 PLUS 6 STR NXT DIESEL (1198cc)",
          "K2 6 STR PETROL (1198cc)",
          "K2 PLUS 6 STR PETROL (1198cc)",
          "K4 PLUS 5 STR PETROL (1198cc)",
          "K4 PLUS 6 STR PETROL (1198cc)",
          "K6 5 STR PETROL (1198cc)",
          "K6 6 STR PETROL (1198cc)",
          "K2 PLUS 6 STR NXT DIESEL (1198cc)",
          "K4 PLUS 5 STR DIESEL (1198cc)",
          "K4 PLUS 6 STR DIESEL (1198cc)",
          "K4 5 STR PETROL (1198cc)",
          "K4 6 STR PETROL (1198cc)",
          "K8 5 STR NXT DIESEL (1198cc)",
          "K2 6 STR NXT PETROL (1198cc)",
          "K8 5 STR NXT PETROL (1198cc)",
          "K6 PLUS 5 STR NXT PETROL (1198cc)",
          "K6 PLUS 6 STR NXT PETROL (1198cc)",
          "K4 5 STR DIESEL (1198cc)",
          "K4 6 STR DIESEL (1198cc)",
          "TRIP MFALCON D75 6S WD (1198cc)",
          "TRIP 5 STR CNG (1198cc)",
          "K8 6 STR (1198cc)",
          "K8 6 STR (1198cc)",
          "K8 5 STR (1198cc)",
          "K8 5 STR (1198cc)",
          "K4 6 STR (1198cc)",
          "K4 6 STR (1198cc)",
          "K2 6 STR (1198cc)",
          "K2 6 STR (1198cc)",
          "K4 5 STR (1198cc)",
          "K4 5 STR (1198cc)",
          "K6 5 STR (1198cc)",
          "K6 5 STR (1198cc)",
          "K6 6 STR (1198cc)",
          "K6 6 STR (1198cc)",
          "K10 (1493cc)",
          "K8 6 STR (1197cc)",
          "K8 5 STR (1197cc)",
        ],
      },
      {
        model: "XUV 300",
        variants: [
          "1.2 W4 (1197cc)",
          "1.2 W4 (1197cc)",
          "1.2 W4 BS6 (1197cc)",
          "1.2 W4 BS-VI (1197cc)",
          "1.2 W4 BS-VI (1197cc)",
          "1.2 W6 (1197cc)",
          "1.2 W6 (1197cc)",
          "1.2 W6 AMT (1197cc)",
          "1.2 W6 AMT (1197cc)",
          "1.2 W6 BS6 (1197cc)",
          "1.2 W6 BS-VI (1197cc)",
          "1.2 W6 BS-VI (1197cc)",
          "1.2 W8 (1197cc)",
          "1.2 W8 (1197cc)",
          "1.2 W8 (O) (1197cc)",
          "1.2 W8 (O) (1197cc)",
          "1.2 W8 (O) AMT (1197cc)",
          "1.2 W8 (O) AMT DUAL TONE BS-VI (1197cc)",
          "1.2 W8 (O) AMT DUAL TONE BS-VI. (1197cc)",
          "1.2 W8 (O) BS6 (1197cc)",
          "1.2 W8 (O) BS-VI (1197cc)",
          "1.2 W8 (O) BS-VI (1197cc)",
          "1.2 W8 (O) DT (1197cc)",
          "1.2 W8 (O) DUAL TONE (1197cc)",
          "1.2 W8 (O) DUAL TONE BS-VI (1197cc)",
          "1.2 W8 (O) DUAL TONE BS-VI (1197cc)",
          "1.2 W8 (O) DUEL TONE BS6 (1197cc)",
          "1.2 W8 BS6 (1197cc)",
          "1.2 W8 BSIV (1197cc)",
          "1.2 W8 BS-VI (1197cc)",
          "1.2 W8 BS-VI (1197cc)",
          "1.2 W8 O AMT (1197cc)",
          "1.2 W8 OPTION (1197cc)",
          "GASOLINE W8 (1197cc)",
          "TURBO SPORT W6 (1197cc)",
          "TURBO SPORT W6 (1197cc)",
          "TURBO SPORT W8 (1197cc)",
          "TURBO SPORT W8 (1197cc)",
          "TURBO SPORT W8 DUAL TONE (1197cc)",
          "TURBO SPORT W8 DUAL TONE (1197cc)",
          "TURBO SPORT W8 O (1197cc)",
          "TURBO SPORT W8 O (1197cc)",
          "TURBO SPORT W8 O DUAL TONE (1197cc)",
          "TURBO SPORT W8 O DUAL TONE (1197cc)",
          "W4 PETROL (1197cc)",
          "W4 PM BS6 MT RX (1197cc)",
          "W6 AMT BS6 (1197cc)",
          "W6 AMT PETROL (1197cc)",
          "W6 PETROL (1197cc)",
          "W6-SUNROOF NT TGDI (1197cc)",
          "W8 (O) AMT DUAL TONE PETROL (1197cc)",
          "W8 (O) AMT PETROL (1197cc)",
          "W8 (O) DUAL TONE PETROL (1197cc)",
          "W8 O PETROL (1197cc)",
          "W8 O PG BSVI MT (1197cc)",
          "W8 OPT AMT BS6 (1197cc)",
          "W8 PETROL (1197cc)",
          "W8 PM BS6 SUNROOF TGDI (1197cc)",
          "W8 PM BS6 SUNROOF TGDI DUAL TONE (1197cc)",
          "W8 PM OPT BS6 TGDI (1197cc)",
          "W8 PM OPT BS6 TGDI DUAL TONE (1197cc)",
          "1.5 W8 (1497cc)",
          "1.5 W8 (1497cc)",
          "1.5 W8 (O) DUAL TONE (1497cc)",
          "1.5 W8 BS6 (1497cc)",
          "W4 DIESEL BS6 MT (1497cc)",
          "W4 DIESEL (1497cc)",
          "W8 DIESEL (1497cc)",
          "W8 O DIESEL (1497cc)",
          "1.5 W8 DSL AMT (1497cc)",
          "1.5 W6 AMT SUNROOF (1497cc)",
          "1.5 W8 (O) AMT (1497cc)",
          "1.5 W8 (O) AMT (1497cc)",
          "1.5 W6 AMT (1497cc)",
          "1.5 W6 AMT (1497cc)",
          "1.5 W8 AMT BS-VI (1497cc)",
          "1.5 W8 AMT BS-VI (1497cc)",
          "1.5 W6 AMT BS-VI (1497cc)",
          "1.5 W6 AMT BS-VI (1497cc)",
          "1.5 W8 BS-VI (1497cc)",
          "1.5 W8 BS-VI (1497cc)",
          "1.5 W4 BS-VI (1497cc)",
          "1.5 W4 BS-VI (1497cc)",
          "W6 DSL AMT (1497cc)",
          "1.5 W8 (O) AMT BS-VI (1497cc)",
          "1.5 W8 (O) AMT BS-VI (1497cc)",
          "1.5 W8 (O) DUAL TONE BS-VI (1497cc)",
          "1.5 W8 (O) DUAL TONE BS-VI (1497cc)",
          "1.5 W6 BSIV (1497cc)",
          "W6 PM BS6 ASRX (1497cc)",
          "1.5 W6 BSVI (1497cc)",
          "1.5 W6 BS-VI (1497cc)",
          "1.5 W8 (O) BSVI (1497cc)",
          "1.5 W8 (O) BS-VI (1497cc)",
          "1.5 W8 (O) AMT DUAL TONE BS-VI (1497cc)",
          "1.5 W8 (O) AMT DUAL TONE BS-VI (1497cc)",
          "1.5 W8 (O) DT (1497cc)",
          "1.5 W8 AMT (O) (1497cc)",
          "W6 DLS AMT (1497cc)",
          "1.5 W8 AMT (1497cc)",
          "1.5 W8 AMT (1497cc)",
          "1.5 W4 (1497cc)",
          "1.5 W4 (1497cc)",
          "1.5 W6 (1497cc)",
          "1.5 W6 (1497cc)",
          "1.5 W8 (O) (1497cc)",
          "1.5 W8 (O) (1497cc)",
          "W8 AMT (1497cc)",
          "W8 (O) DUAL TONE DIESEL (1497cc)",
          "W8 (O) (1497cc)",
          "W4 (1497cc)",
          "W6 AMT (1497cc)",
          "W8 (1497cc)",
          "W8 (O) AMT (1497cc)",
          "W6 DIESEL (1497cc)",
        ],
      },
      {
        model: "XUV 700",
        variants: [
          "AX5 DSL MT 7SEATER (2184cc)",
          "AX 7 DIESEL MT 7 STR (2184cc)",
          "MX DIESEL MT 5 SEATER (2184cc)",
          "AX3 DIESEL MT 5 SEATER (2184cc)",
          "AX3 DIESEL AT 5 SEATER (2184cc)",
          "AX5 DIESEL MT 5 SEATER (2184cc)",
          "AX5 DIESEL AT 5 SEATER (2184cc)",
          "AX5 DIESEL MT 7 SEATER (2184cc)",
          "AX5 DIESEL AT 7 SEATER (2184cc)",
          "AX7 DIESEL AT 7 SEATER (2184cc)",
          "AX7 DIESEL AT AWD 7 SEATER (2184cc)",
          "AX7 LUXURY DIESEL AT 7 SEATER (2184cc)",
          "AX3 DIESEL MT 7 SEATER (2184cc)",
          "AX7 LUXURY DIESEL MT 7 SEATER (2184cc)",
          "AX7 DIESEL AT AWD LUXURY (2184cc)",
          "2.2 CRDI AX7 DIESEL 7 SEAT (2184cc)",
          "2.2 CRDI AX7 LUXURY  AT DIESEL 7 SEAT (2184cc)",
          "2.2 CRDI AX7 LUXURY  DIESEL 7 SEAT (2184cc)",
          "2.2 CRDI AX7 LUXURY AT AWD DIESEL 7 SEAT (2184cc)",
          "2.2 CRDI AX7 DIESEL AT  AWD 7 SEAT (2184cc)",
          "2.2 CRDI AX3 DIESEL AT (2184cc)",
          "2.2 CRDI AX5 DIESEL 7 SEATER (2184cc)",
          "2.2 CRDI AX5 DIESEL 5 SEAT (2184cc)",
          "2.2 CRDI AX3 DIESEL 7 SEAT (2184cc)",
          "2.2 CRDI AX7 DIESEL AT 7 SEAT (2184cc)",
          "2.2 CRDI MX DIESEL (2184cc)",
          "2.2 CRDI AX5 DIESEL AT 5 SEAT (2184cc)",
          "2.2 CRDI AX5 DIESEL AT 7 SEATER (2184cc)",
          "2.2 CRDI AX3 DIESEL 5 SEAT (2184cc)",
          "MX MT (2184cc)",
          "AX7 (2184cc)",
          "AX5 DSL AT 5 STR (2184cc)",
          "AX3 DSL AT 5 STR (2184cc)",
          "AX5 DSL MT 5 STR (2184cc)",
          "AX3 DSL MT 5 STR (2184cc)",
          "MX DSL MT 5 STR (2184cc)",
          "AX7 L DSL AT 7 STR (2184cc)",
          "AX7 DSL AT AWD 7 STR (2184cc)",
          "AX7 DSL AT 7 STR (2184cc)",
          "AX7 DSL MT 7 STR (2184cc)",
          "AX5 DSL AT 7 STR (2184cc)",
          "AX5 DSL MT 7 STR (2184cc)",
          "AX3 DSL MT 7 STR (2184cc)",
          "AX7 L DSL MT 7 STR (2184cc)",
          "AX3 DIESEL (2184cc)",
          "AX3 DIESEL (2184cc)",
          "AX3 DIESEL 7STR (2184cc)",
          "AX3 DIESEL 7STR (2184cc)",
          "AX3 DIESEL AT (2184cc)",
          "AX3 DIESEL AT (2184cc)",
          "AX5 DIESEL (2184cc)",
          "AX5 DIESEL (2184cc)",
          "AX5 DIESEL 7STR (2184cc)",
          "AX5 DIESEL 7STR (2184cc)",
          "AX5 DIESEL AT (2184cc)",
          "AX5 DIESEL AT (2184cc)",
          "AX5 DIESEL AT 7STR (2184cc)",
          "AX5 DIESEL AT 7STR (2184cc)",
          "AX7 DIESEL (2184cc)",
          "AX7 DIESEL (2184cc)",
          "AX7 DIESEL AT (2184cc)",
          "AX7 DIESEL AT (2184cc)",
          "AX7 DIESEL AT AWD 7STR (2184cc)",
          "AX7 DIESEL AT AWD 7STR (2184cc)",
          "AX7 DIESEL AT LUXURY PACK (2184cc)",
          "AX7 DIESEL AT LUXURY PACK (2184cc)",
          "AX7 DIESEL LUXURY PACK (2184cc)",
          "AX7 DIESEL LUXURY PACK (2184cc)",
          "AX7 DIESEL AT AWD LUXURY PACK. (2184cc)",
          "AX7 DIESEL AT AWD LUXURY PACK (2184cc)",
          "MX DIESEL E (2184cc)",
          "MX DIESEL E (2184cc)",
          "AX3 DIESEL E (2184cc)",
          "AX3 DIESEL E (2184cc)",
          "AX3 DIESEL 7STR E (2184cc)",
          "AX3 DIESEL 7STR E (2184cc)",
          "AX3 MT 5 STR (2184cc)",
          "AX3 AT 5 STR (2184cc)",
          "AX5 MT 5 STR (2184cc)",
          "AX5 AT 5 STR (2184cc)",
          "AX5 MT 7 STR (2184cc)",
          "AX7 MT 7 STR (2184cc)",
          "AX7 AT 7 STR (2184cc)",
          "AX7 L AT 7 STR (2184cc)",
          "AX3 MT 7 STR (2184cc)",
          "AX7 L MT 7 STR (2184cc)",
          "AX5 AT 7 STR (2184cc)",
          "AX7 AT AWD 7 STR (2184cc)",
          "AX7 L AT AWD 7 STR (2184cc)",
          "AX 7 AT DIESEL 7 STR (2184cc)",
          "AX 7 AT AWD DIESEL 7 STR (2184cc)",
          "AX 3 MT DIESEL 5 STR (2184cc)",
          "AX 5 AT DIESEL 5 STR (2184cc)",
          "AX 5 AT DIESEL 7 STR (2184cc)",
          "AX 5 MT DIESEL 5 STR (2184cc)",
          "AX 3 MT DIESEL 7 STR (2184cc)",
          "MX MT DIESEL 5 STR (2184cc)",
          "AX 5 MT DIESEL 7 STR (2184cc)",
          "AX 7 AT LUXURY PACK DIESEL 7 STR (2184cc)",
          "AX 7 MT DIESEL 7 STR (2184cc)",
          "AX 3 AT DIESEL 5 STR (2184cc)",
          "AX 7 MT LUXURY PACK DIESEL 7 STR (2184cc)",
          "AX 7 AT AWD LUXURY PACK DIESEL 7 STR (2184cc)",
          "AX 7 PETROL AT 7 STR (1997cc)",
          "MX PETROL MT 5 STR (1997cc)",
          "AX 3 PETROL AT 5 STR (1997cc)",
          "AX 3 PETROL MT 5 STR (1997cc)",
          "AX 5 PETROL MT 5 STR (1997cc)",
          "AX 5 PETROL AT 5 STR (1997cc)",
          "AX 5 PETROL MT 7 STR (1997cc)",
          "AX7 LUXURY PETROL AT 7 SEATER (1997cc)",
          "AX7 PETROL MT 7 SEATER (1997cc)",
          "2.0 TDGI AX7 GASOLINE 7 SEAT AT (1997cc)",
          "2.0 TDGI AX7 LUXURY  AT PETROL 7 SEAT (1997cc)",
          "2.0 TDGI AX7 LUXURY   PETROL  7 SEAT (1997cc)",
          "2.0 TDGI AX7 GASOLINE 7 SEAT (1997cc)",
          "2.0 TDGI MX GASOLINE (1997cc)",
          "2.0 TDGI AX5 GASOLINE 7 SEAT (1997cc)",
          "2.0 TDGI AX3 GASOLINE (1997cc)",
          "2.0 TDGI AX3 AT PETROL 5 SEAT (1997cc)",
          "2.0 TDGI AX5 GASOLINE (1997cc)",
          "2.0 TDGI AX7 PETROL 7 SEAT (1997cc)",
          "2.0 TDGI AX5 GASOLINE AT 5 SEATER (1997cc)",
          "MX MT (1997cc)",
          "AX5 PET AT 5 STR (1997cc)",
          "AX3 PET AT 5 STR (1997cc)",
          "AX5 PET MT 5 STR (1997cc)",
          "AX3 PET MT 5 STR (1997cc)",
          "MX PET MT 5 STR (1997cc)",
          "AX7 L DSL AT 7 STR (1997cc)",
          "AX7 L PET AT 7 STR (1997cc)",
          "AX7 PET AT 7 STR (1997cc)",
          "AX7 PET MT 7 STR (1997cc)",
          "AX5 PET MT 7 STR (1997cc)",
          "AX3 PET MT 5 SEATER SLR (1997cc)",
          "AX5 PET AT 5 SEATER WHT (1997cc)",
          "AX5 PET AT 5 SEATER SLR (1997cc)",
          "AX5 PET MT 7 SEATER BLU (1997cc)",
          "MX E (1997cc)",
          "MX E (1997cc)",
          "AX3 E (1997cc)",
          "AX3 E (1997cc)",
          "AX5 E (1997cc)",
          "AX5 E (1997cc)",
          "AX5 7STR E (1997cc)",
          "AX5 7STR E (1997cc)",
          "AX 7 MT PETROL 7 STR (1997cc)",
          "AX 5 MT PETROL 5 STR (1997cc)",
          "AX 7 AT PETROL 7 STR (1997cc)",
          "MX MT PETROL 5 STR (1997cc)",
          "AX 3 AT PETROL 5 STR (1997cc)",
          "AX 5 MT PETROL 7 STR (1997cc)",
          "AX 5 AT PETROL 5 STR (1997cc)",
          "AX 3 MT PETROL 5 STR (1997cc)",
          "AX 7 AT LUXURY PACK PETROL 7 STR (1997cc)",
          "AX7 L PET AT 7 SEATER BSVI (1999cc)",
          "AX7 (1999cc)",
          "AX3 (1999cc)",
          "AX3 (1999cc)",
          "AX3 AT (1999cc)",
          "AX3 AT (1999cc)",
          "AX5 (1999cc)",
          "AX5 (1999cc)",
          "AX5 7STR (1999cc)",
          "AX5 7STR (1999cc)",
          "AX5 AT (1999cc)",
          "AX5 AT (1999cc)",
          "AX7 AT (1999cc)",
          "AX7 AT (1999cc)",
          "AX7 AT LUXURY PACK (1999cc)",
          "AX7 AT LUXURY PACK (1999cc)",
          "MX (1999cc)",
          "MX (1999cc)",
          "MX MT 5 STR (1999cc)",
          "AX3 MT 5 STR (1999cc)",
          "AX3 AT 5 STR (1999cc)",
          "AX5 MT 5 STR (1999cc)",
          "AX5 AT 5 STR (1999cc)",
          "AX5 MT 7 STR (1999cc)",
          "AX7 MT 7 STR (1999cc)",
          "AX7 AT 7 STR (1999cc)",
          "AX7 L AT 7 STR (1999cc)",
          "2.2 CRDI AX5 DIESEL AT 5 SEAT (2198 CC) (2198cc)",
          "2.2 CRDI AX7 LUXURY  AT DIESEL 7 SEAT (CC 2198) (2198cc)",
          "AX7 L DSL AT 7 STR (2198cc)",
          "MX DIESEL (2198cc)",
          "MX DIESEL (2198cc)",
          "MX MT 5 STR (2198cc)",
          "AX7 T DSL MT 7 SEATER BLU (2179cc)",
          "AX3 PET MT 5 SEATER WHT (2000cc)",
        ],
      },
      {
        model: "REVA I",
        variants: ["AC (1400cc)"],
      },
      {
        model: "REVA-I",
        variants: [
          "AC (19000cc)",
          "CLASSE (19000cc)",
          "STANDARD (19000cc)",
          "AC (13cc)",
          "AC (13cc)",
          "CLASSE (13cc)",
          "CLASSE (13cc)",
          "STANDARD (13cc)",
          "STANDARD (13cc)",
        ],
      },
      {
        model: "REVA",
        variants: [
          "I CLASSE (48cc)",
          "E20 T20 (800cc)",
          "CLASS (800cc)",
          "E20 T2 (800cc)",
          "AC (800cc)",
          "STANDARD (800cc)",
          "XE OPTION (0cc)",
          "I A/C (0cc)",
          "E2 (19000cc)",
          "AC (13cc)",
          "AC (13cc)",
          "STANDARD (13cc)",
          "STANDARD (13cc)",
          "CLASSE (13cc)",
          "CLASSE (13cc)",
          "E2O T2 (650cc)",
          "E2O T0 (650cc)",
        ],
      },
      {
        model: "XYLO",
        variants: [
          "E8 5STR (1461cc)",
          "E9 1.5L BSIV 2WD RHD 8FF (1493cc)",
          "E9 1.5L BSIV 2WD RHD 8FF DW (1493cc)",
          "U321 RHD FWD 1.5 LT 4CYL MHAWK (1499cc)",
          "W MHAWK EV 4WD TCE4 LHD (2123cc)",
          "E9 MHAWK (2179cc)",
          "H8 M-HAWK CRDE 2WD (2179cc)",
          "E9 BS-IV 7STR (2179cc)",
          "H4 MHAWK CRDE 7 SEATER (2179cc)",
          "H4 6 SEATER (2179cc)",
          "D2 (2179 CC) (2179cc)",
          "H8 MHAWK 7 SEATER (2179cc)",
          "H8 MHAWK 8 SEATER (2179cc)",
          "E8 8 SEATER (2179cc)",
          "E9 MHAWK 8 SEATER (2179cc)",
          "H4 ABS AIRBAG BS IV (2179cc)",
          "H4 ABS AIRBAG BS IV (2179cc)",
          "E9 BS-IV (2179cc)",
          "E9 BS-IV (2179cc)",
          "E9 7STR (2179cc)",
          "E9 7STR (2179cc)",
          "E9 8STR (ABS) AIRBAG (2179cc)",
          "H4 8STR (2179cc)",
          "H4 (ABS) (2179cc)",
          "H4 7STR (ABS) (2179cc)",
          "H4 8STR (ABS) (2179cc)",
          "H8 7STR (ABS) (2179cc)",
          "H8 8STR (ABS) (2179cc)",
          "H8 7STR (ABS) AIRBAG (2179cc)",
          "H8 8STR (ABS) AIRBAG (2179cc)",
          "H9 7STR (2179cc)",
          "H9 8STR (2179cc)",
          "H4 (2179cc)",
          "H8 (2179cc)",
          "H4 7STR (2179cc)",
          "E8 7 SEATER (2179cc)",
          "E9 7 SEATER (2179cc)",
          "H4 7 SEATER (2179cc)",
          "H4 ABS 7 SEATER (2179cc)",
          "H8 ABS 7 SEATER (2179cc)",
          "H8 ABS AIR BAG 7 SEATER (2179cc)",
          "H9 7 SEATER (2179cc)",
          "H8 ABS AIR BAG 8 SEATER (2179cc)",
          "H9 8 SEATER (2179cc)",
          "H4 8 SEATER (2179cc)",
          "H4 ABS 8 SEATER (2179cc)",
          "H8 ABS 8 SEATER (2179cc)",
          "E9 ABS AIR BAG 8 SEATER (2179cc)",
          "E9 8STR (2179cc)",
          "U203 2 2L M-HAWK EURO IV 2WD RHD (2179cc)",
          "E9 (2179cc)",
          "H9 (2179cc)",
          "H8 MHAWK EIV 2WD LHD 7FF ABS (2179cc)",
          "CRDE 2WD 7CC BS4 (2179cc)",
          "U321 RHD FWD (2179cc)",
          "E9 M-HAWK CRDE BSIV 2WD 8FF (2179cc)",
          "H4 ABS BS IV 8STR (2179cc)",
          "H8 ABS AIRBAG BS IV 8STR (2179cc)",
          "H4 BS IV 8 STR (2179cc)",
          "H9 BS IV 8STR (2179cc)",
          "H8 ABS BS IV 8STR (2179cc)",
          "H4 ABS BS-IV (2179cc)",
          "H4 ABS BS-IV (2179cc)",
          "H9 BS-IV (2179cc)",
          "H9 BS-IV (2179cc)",
          "H4 BS-IV (2179cc)",
          "H4 BS IV (2179cc)",
          "H8 ABS BS-IV (2179cc)",
          "H8 ABS BS-IV (2179cc)",
          "H8 ABS AIRBAG BS-IV (2179cc)",
          "H8 ABS AIRBAG BS-IV (2179cc)",
          "E8 (2179cc)",
          "D4 BS-III (2489cc)",
          "D2 BS-III (2489cc)",
          "D2 BS-III (2489cc)",
          "D4 7 STRBSIV (2489cc)",
          "D4 MDI CRDE 7 SEAT (2489cc)",
          "D4 MDI CRDE 8 SEAT (2489cc)",
          "D2 MDI CRDE 8 SEAT (2489cc)",
          "D2 MDI CRDE 9 SEAT (2489cc)",
          "D2 MDI CRDE 7 SEAT (2489cc)",
          "E8 MDI CRDE 8 SEATER (2489cc)",
          "D2 BS-IV (2489cc)",
          "D2 BS IV (2489cc)",
          "D4 BS-IV (2489cc)",
          "D4 BS-IV (2489cc)",
          "D2 MAXX (2489cc)",
          "D2 MAXX (2489cc)",
          "D2 MAXX BSIV (2489cc)",
          "H8 MDI 7STR (2489cc)",
          "D2 7 STR (2489cc)",
          "H8 MDI 7 SEAT (2489cc)",
          "D2 8STR (2489cc)",
          "CELEBRATION EDITION BS4 (2489cc)",
          "E4 8 SEATER (2489cc)",
          "E6 8 SEATER (2489cc)",
          "XYLO CELEBRATION EDITIONBS4 (2489cc)",
          "E4 ABS 7 SEATER (2489cc)",
          "D2 9STR (2489cc)",
          "E4 7 SEATER (2489cc)",
          "D4 (2489cc)",
          "D4. (2489cc)",
          "D4 7STR (2489cc)",
          "D2 (2489cc)",
          "E6 7 SEATER (2489cc)",
          "E4 ABS 8 SEATER (2489cc)",
          "U203 2 2L M-HAWK EURO V 4WD RHD DW (2490cc)",
          "U203 2 2L M-HAWK EURO V 4WD LHD DW (2490cc)",
          "U203 2 2L M HAWK E IV 4WD LHD (2490cc)",
          "U 203 M HAWK EURO V 4WD RHD (2490cc)",
          "U202 DC STYLED MHAWK EIV 4WD LHD (2490cc)",
          "U203 3CYL MHAWK BSIV 2WD RHD (2490cc)",
          "H8 MHAWK EIV 2WD RHD 7FF ABS BLK (2490cc)",
          "DC MUSTANG BSIII 2WD RHD (2490cc)",
          "W MHAWK EV 2WD (2490cc)",
          "CELEBRATION EDITION BS-III (2498cc)",
          "E6 BS-III (2498cc)",
          "E6 CELEBRATION (2498cc)",
          "E8 ABS AIRBAG BS-III (2498cc)",
          "E8 ABS BS-III (2498cc)",
          "E8 BS-III (2498cc)",
          "E4 ABS BS-III (2498cc)",
          "E2 7 STR (2498cc)",
          "E2 7STR (2498cc)",
          "BS3 D4 (2498cc)",
          "E8 CRDE BS-III (2498cc)",
          "E8 CRDE 5 STR (2498cc)",
          "E4 BS-III (2498cc)",
          "E4 BS-III (2498cc)",
          "BODY KIT (2498cc)",
          "BS3 D2 (2498cc)",
          "BS3 E4 (2498cc)",
          "BS3 E8 ABS AIR BAG (2498cc)",
          "D4 8 STR BS III (2498cc)",
          "E4 5 STR (2498cc)",
          "E8 MEAGLE CRDE 7 SEATER (2498cc)",
          "E8 MEAGLE CRDE 8 SEATER (2498cc)",
          "E2 7 SEATER (2498cc)",
          "E8 MEAGLE CRDE ABS AIR BAG 9 SEATER (2498cc)",
          "E8 2.5 CRDE 7 SEATER (2498cc)",
          "E8 MEAGLE CRDE ABS AIR BAG 7 SEATER (2498cc)",
          "E8 MEAGLE CRDE 6 SEATER (2498cc)",
          "D2 MAXX 9 SEATER (2498cc)",
          "E9 ABS AIR BAG 7 SEATER CAPTAIN (2498cc)",
          "CELEBRATION EDITION (2498cc)",
          "CELEBRATION EDITION BS-IV (2498cc)",
          "CELEBRATION EDITION BS-IV (2498cc)",
          "E4 BS-IV (2498cc)",
          "E4 BS-IV (2498cc)",
          "E6 BS-IV (2498cc)",
          "E6 BS-IV (2498cc)",
          "E6 CELEBRATION BS-IV (2498cc)",
          "E6 CELEBRATION BS-IV (2498cc)",
          "E8 ABS AIRBAG BS-IV (2498cc)",
          "E8 ABS AIRBAG BS-IV (2498cc)",
          "E8 ABS BS-IV (2498cc)",
          "E8 ABS BS IV (2498cc)",
          "E8 BS-IV (2498cc)",
          "E8 BS-IV (2498cc)",
          "E4 ABS BS-IV (2498cc)",
          "E4 ABS BS-IV (2498cc)",
          "D2 MAXX (2498cc)",
          "E8 7STR (2498cc)",
          "E8 7STR (2498cc)",
          "E9 8STR (2498cc)",
          "D4 8STR (2498cc)",
          "E4 (ABS) 8STR (2498cc)",
          "E6 7STR (2498cc)",
          "E9 CAPTAIN 7STR (ABS) AIRBAG (2498cc)",
          "E9 ABS AIR BAG 7 CAPTAIN (2498cc)",
          "D2 7STR (2498cc)",
          "E8 7 SEATER (2498cc)",
          "E9 7 SEATER (2498cc)",
          "D2 7 SEATER (2498cc)",
          "D4 7 SEATER (2498cc)",
          "D4 8 SEATER (2498cc)",
          "E9 8 SEATER (2498cc)",
          "E4 7STR (2498cc)",
          "E8 8STR (2498cc)",
          "E8 8STR (2498cc)",
          "CELEBRATION (2498cc)",
          "CELEBRATION. (2498cc)",
          "E9 (2498cc)",
          "H8 MHAWK EIV 4WD (2498cc)",
          "H8 MHAWK EIV 4WD RHD (2498cc)",
          "E8 ABS AIRBAG 8STR (2498cc)",
          "E8 ABS 7 SEATER (2498cc)",
          "E2 8STR (2498cc)",
          "M EAGLE CRDE 2WD (2498cc)",
          "E8 ABS 8STR (2498cc)",
          "H2 MHAWK EII 2WD LHD 8FF G (2498cc)",
          "CELEBRATION 8STR (2498cc)",
          "E6 8 STR (2498cc)",
          "E6 8STR (2498cc)",
          "E4 8STR (2498cc)",
          "E4 8STR (2498cc)",
          "D2 9STR (2498cc)",
          "E4 7 SEATER (2498cc)",
          "D4 7STR (2498cc)",
          "D2 (2498cc)",
          "D2 (2498cc)",
          "E6 (2498cc)",
          "E6 (2498cc)",
          "E4 (2498cc)",
          "E8 (2498cc)",
          "E6 7 SEATER (2498cc)",
          "E4 ABS 8 SEATER (2498cc)",
          "E8 ABS AIR BAG (2498cc)",
          "E8 ABS (2498cc)",
          "E2 (2498cc)",
          "E2 (2498cc)",
          "D2 8STR (2498cc)",
          "E9 M-HAWK CRDE BSIV 2WD 7CC DW (2550cc)",
        ],
      },
      {
        model: "MAXX",
        variants: [
          "TX (2523cc)",
          "TX 2WD 6 STR (2523cc)",
          "TX 2WD 6 STR (2523cc)",
          "FESTARA 2WD 9 STR (2523cc)",
          "PICKUP (2523cc)",
          "LX 6 SEATER (2523cc)",
          "LX 9 SEATER (2523cc)",
          "10 SEATER (2523cc)",
          "FESTIVA 10 SEATER (2523cc)",
          "TX 6 SEATER (2523cc)",
          "(2001-2012) 2WD (2523cc)",
          "STD BS III (2523cc)",
          "12 STR (2523cc)",
          "0 (2523cc)",
          "2WD (2523cc)",
          "2WD (2523cc)",
          "110 2WD BS3 (2523cc)",
          "TX 2WD 6 STR BS3 (2523cc)",
          "FESTARA 2WD (2523cc)",
          "10 STR (2523cc)",
          "10 STR (2523cc)",
          "FESTIVA (2523cc)",
          "FESTIVA (2523cc)",
          "FESTIVA (2523cc)",
          "STD (2523cc)",
          "6 SEATER/CNG (2523cc)",
          "7 SEATER/DIESEL (2523cc)",
          "8 SEATER/DIESEL (2523cc)",
          "9 SEATER/DIESEL (2523cc)",
          "10 SEATER/DIESEL (2523cc)",
          "LX 2WD (2523cc)",
          "FESTARA (2523cc)",
          "110 (2523cc)",
          "LX  2WD 10 STR (2523cc)",
          "LX 7STR (2523cc)",
          "LX 2WD 9 STR FESTRA (2523cc)",
          "LX (2523cc)",
          "RIGID MDI (2523cc)",
          "RIGID 4WD TC NGT 9 SF BS3 (2523cc)",
          "FESTARA 10STR (2523cc)",
          "MAXX 10 SEATER (2523cc)",
          "MAXX 6 SEATER (2523cc)",
          "FESTARA 8STR (2523cc)",
          "FESTIVA 9STR (2523cc)",
          "FESTARA 9STR (2523cc)",
          "110 FESTARA 9 SEATER (2523cc)",
          "LX 2WD BSII (1720cc)",
          "2WD BS3 10 STR (2112cc)",
          "FESTARA (2112cc)",
          "2WD 8 STR (2112cc)",
          "FESTIVA 6STR (2112cc)",
          "2WD (2680cc)",
          "2WD BS 3 (2680cc)",
          "FESTARA 2WD 10STR BS2 (2526cc)",
          "DI 9STR (1666cc)",
          "110 XL (2600cc)",
        ],
      },
      {
        model: "ALTURAS G4",
        variants: [
          "2.2 4X2 DIESEL AT (2157cc)",
          "2.2 4X4 DIESEL AT (2157cc)",
          "2WD HIGH AT (2157cc)",
          "2WD HIGH AT (2157cc)",
          "4X2 AT (2157cc)",
          "4X4 AT (2157cc)",
          "AT 4WD (2157cc)",
          "2WD AT (2157cc)",
          "2WD AT (2157cc)",
          "4WD AT (2157cc)",
          "4WD AT (2157cc)",
        ],
      },
      {
        model: "JEETO",
        variants: [
          "PLUS-16 BSVI WD (625cc)",
          "VXD 5 SEATER (625cc)",
          "LXD 5 SEATER (625cc)",
          "MINIVAN LDX (625cc)",
          "MINI VAN LX (625cc)",
          "MINI VAN VXD (625cc)",
          "MINI VAN VX (625cc)",
          "MINI VAN LXP (625cc)",
          "MINI VAN LXD 5STR (625cc)",
          "MINIVAN LXD (625cc)",
          "MINIVAN LX CNG 6 STR (625cc)",
          "MINIVAN (625cc)",
          "MINIVAN LXD BSIV (625cc)",
          "MINIVAN VXD BSIV (625cc)",
        ],
      },
      {
        model: "TUV 300",
        variants: [
          "PLUS (2523cc)",
          "T10 5 SEATER (1493cc)",
          "T8 MHAWK100 (1493cc)",
          "T10 DUAL TONE (1493cc)",
          "T10 AMT DUAL TONE (1493cc)",
          "T4 PLUS (1493cc)",
          "T4 PLUS (1493cc)",
          "T8 (1493cc)",
          "T8 (1493cc)",
          "T6 PLUS (1493cc)",
          "T6 PLUS (1493cc)",
          "T10 (1493cc)",
          "T10 (1493cc)",
          "T8 AMT (1493cc)",
          "T4 (1493cc)",
          "T6 PLUS AMT (1493cc)",
          "T6 (1493cc)",
          "T10 AMT (1493cc)",
          "P8 PLUS 9 SEATER (2179cc)",
          "P6 PLUS 9 SEATER (2179cc)",
          "P4 PLUS 9 SEATER (2179cc)",
          "PLUS P8 (2179cc)",
          "PLUS P8 (2179cc)",
          "PLUS (2179cc)",
          "PLUS (2179cc)",
          "PLUS (2179cc)",
          "PLUS (2179cc)",
          "PLUS (1997cc)",
        ],
      },
      {
        model: "TUV300",
        variants: [
          "T4 PLUS 9 STR (1493cc)",
          "T10 (O) DUAL TONE (1493cc)",
          "T10 (O) DUAL TONE (1493cc)",
          "T10 MHAWK100 (1493cc)",
          "T8 MHAWK100 DUAL TONE (1493cc)",
          "T8 MHAWK100 DUAL TONE (1493cc)",
          "T10 (O) (1493cc)",
          "T10 (O) (1493cc)",
          "MHAWK100 T8 (1493cc)",
          "MHAWK100 T8 (AT) (1493cc)",
          "MHAWK100 T8 DUEL TONE (1493cc)",
          "T6 PLUS (AT) (1493cc)",
          "T8 (AT) (1493cc)",
          "T10 DUAL TON (1493cc)",
          "T10 (AT) (1493cc)",
          "T10 DUAL TON (AT) (1493cc)",
          "MHAWK100 T8 AMT (1493cc)",
          "T8 DUAL TONE (1493cc)",
          "T4 8STR (1493cc)",
          "T4 PLUS 8STR (1493cc)",
          "T6 8STR (1493cc)",
          "T6 PLUS 8STR (1493cc)",
          "T6 PLUS AMT 8STR (1493cc)",
          "T8 8STR (1493cc)",
          "T8 AMT 8STR (1493cc)",
          "P8 PLUS MHAWK SE (1493cc)",
          "T8 AMT MHAWK 100 (1493cc)",
          "T8 AMT MHAWK 100 (1493cc)",
          "T8 MHAWK 100 (1493cc)",
          "T10 DUAL TONE (1493cc)",
          "T10 AMT DUAL TONE (1493cc)",
          "T8 AMT (1493cc)",
          "T4 (1493cc)",
          "T6 PLUS AMT (1493cc)",
          "T6 (1493cc)",
          "T10 AMT (1493cc)",
          "P6 PLUS (2179cc)",
          "P8 PLUS (2179cc)",
          "P4 PLUS (2179cc)",
        ],
      },
      {
        model: "MARAZZO",
        variants: [
          "M4 PLUS BS6 (1497cc)",
          "M4 PLUS 8 STR BS6 (1497cc)",
          "M6 PLUS BS6 (1497cc)",
          "M6 PLUS 8 STR BS6 (1497cc)",
          "1.5 M6 PLUS 8 SEATER (1497cc)",
          "1.5 M4 PLUS 8 SEATER (1497cc)",
          "1.5 M6 PLUS 7 SEATER (1497cc)",
          "1.5 M4 PLUS 7 SEATER (1497cc)",
          "1.5 M8 8 SEATER (1497cc)",
          "1.5 M2 7 SEATER (1497cc)",
          "1.5 M2 8 SEATER (1497cc)",
          "1.5 M4 7 SEATER (1497cc)",
          "1.5 M4 8 SEATER (1497cc)",
          "1.5 M6 7 SEATER (1497cc)",
          "1.5 M6 8 SEATER (1497cc)",
          "1.5 M8 7 SEATER (1497cc)",
          "M2 (1497cc)",
          "M4 (1497cc)",
          "M6 (1497cc)",
          "M8 (1497cc)",
          "M4 PLUS (1497cc)",
          "M6 PLUS (1497cc)",
          "M6 PLUS 7 STR (1497cc)",
          "M6 PLUS 7 STR (1497cc)",
          "M4 PLUS 7 STR (1497cc)",
          "M4 PLUS 7 STR (1497cc)",
          "M4 PLUS 8 STR (1497cc)",
          "M4 PLUS 8 STR (1497cc)",
          "M6 PLUS 8 STR (1497cc)",
          "M6 PLUS 8 STR (1497cc)",
          "M8 8 STR (1497cc)",
          "M8 8 STR (1497cc)",
          "M2 7 STR (1497cc)",
          "M2 7 STR (1497cc)",
          "M2 8 STR (1497cc)",
          "M2 8 STR (1497cc)",
          "M4 7 STR (1497cc)",
          "M4 7 STR (1497cc)",
          "M4 8 STR (1497cc)",
          "M4 8 STR (1497cc)",
          "M6 7 STR (1497cc)",
          "M6 7 STR (1497cc)",
          "M6 8 STR (1497cc)",
          "M6 8 STR (1497cc)",
          "M8 7 STR (1497cc)",
          "M8 7 STR (1497cc)",
        ],
      },
      {
        model: "IMPERIO",
        variants: [
          "DC VX 5 STR (2498cc)",
          "SC VX (2498cc)",
          "DC VX (2498cc)",
          "SC (2498cc)",
          "DC (2498cc)",
          "DOUBLE CABIN  5 SEATER (2489cc)",
          "DOUBLE CABIN VX 5 SEATER (2489cc)",
          "DC BS6 (2489cc)",
          "DC VX BS6 (2489cc)",
          "SC VX (2489cc)",
          "DC VX (2489cc)",
          "SC (2489cc)",
          "DC (2489cc)",
          "DC (2489cc)",
          "DC VX 2WD BS4 (2489cc)",
          "DC VX 2WD BS III (2489cc)",
        ],
      },
      {
        model: "SAVARI",
        variants: [
          "10 SEATER (2523cc)",
          "7 SEATER (2523cc)",
          "11 SEATER (2523cc)",
          "STR 10 (2523cc)",
          "STR 11 (2523cc)",
          "0 (2523cc)",
          "0 (2523cc)",
          "DI 4X2 (2523cc)",
          "9 SEATERS/SAVARI (2523cc)",
          "6 SEATER/DIESEL (2523cc)",
          "8 SEATER/DIESEL (2523cc)",
          "12 SEATER/DIESEL (2523cc)",
          "10 SEATER/DIESEL (2523cc)",
          "12STR (2523cc)",
          "DI 6STR (2523cc)",
          "SAVARI (2523cc)",
          "12 SEATER (2523cc)",
          "SAVARIÃ‚Â  9 SEATER (2498cc)",
        ],
      },
      {
        model: "COMMANDER",
        variants: [
          "750 DI (2523cc)",
          "750 DI (2523cc)",
          "650 MDI TC (2523cc)",
          "JEEP (2523cc)",
          "650 DI 11 SEATER (2523cc)",
          "750 DP HT 6 SEATER (2523 CC) (2523cc)",
          "650 MDI TC 7STR (2523cc)",
          "750 DI 9STR (2523cc)",
          "650 DI 6 STR (2523cc)",
          "650 DI 7 STR (2523cc)",
          "650 DI 8 STR (2523cc)",
          "650 DI 9 STR (2523cc)",
          "655 DI (2523cc)",
          "CDR 650 (2523cc)",
          "CDR 750 (2523cc)",
          "750 DI 9 SEATER (2523cc)",
          "650 MDI TC 7 SEATER (2523cc)",
          "650 DI (2523cc)",
          "650 DI (2523cc)",
          "750DP 2WD ST (2523cc)",
          "750 DP (2523cc)",
          "750 DP (2523cc)",
          "655 DI 2WD (2523cc)",
          "COMMANDER 650 DI (2523cc)",
          "CL 550 MDI (2523cc)",
          "CDR 650 DI 4WD (2523cc)",
          "CDR 650 DI 2WD (2523cc)",
          "CDR 650 DI (2523cc)",
          "CL 550 MDI (7 SEATER) (2523cc)",
          "STANDARD (2112cc)",
          "150 DP ST (2112cc)",
          "750 DI 11 SEATER (2112 CC) (2112cc)",
          "750DP 2WD HT (2112cc)",
          "750 DP HT 10STR (2112cc)",
          "750 DP 5 STR (2112cc)",
          "750 DP 8 STR (2112cc)",
          "750 DP 9 STR (2112cc)",
          "750 DP 10 STR (2112cc)",
          "750 DP HT 10 SEATER (2112cc)",
          "STD (2112cc)",
          "750 DP (2112cc)",
        ],
      },
      {
        model: "GENIO DC",
        variants: [
          "STANDARD (2489cc)",
          "VX 5 SEATER (2489cc)",
          "STANDARD 5 SEATER (2489cc)",
          "VX (2489cc)",
          "VX (2489cc)",
          "STYLED MHK EIV 4WD LHDA (2490cc)",
          "STYLD MHK EIV2WD RHD (2500cc)",
        ],
      },
      {
        model: "ALFA",
        variants: [
          "PASSENGER DX CNG BSVI TURQ (395cc)",
          "BS II (395cc)",
          "PASSENGER (395cc)",
          "DX CNG BSVI TURQ (395cc)",
          "PAX CHAMP (395cc)",
          "0 (395cc)",
          "ALFA 4STR (395cc)",
          "PAX COMFY (436cc)",
          "G435 A BSIII (436cc)",
          "DX (436cc)",
          "CHAMP (436cc)",
          "DX 7STR (436cc)",
          "DX CNG (436cc)",
          "PAX DX TURQUOI (436cc)",
          "PAX CHAMP (436cc)",
          "DX DSL BSVI (597cc)",
        ],
      },
      {
        model: "CL 500",
        variants: [
          "SOFT TOP 8 SEATER (2523cc)",
          "SOFT TOP 5 SEATER (2523cc)",
          "SOFT TOP 6 SEATER (2523cc)",
          "SOFT TOP 7 SEATER (2523cc)",
          "MD 4WD 6 SEATER (2523cc)",
          "MDI 2WD 8 SEATER (2523cc)",
          "MDI 4WD ST (2523cc)",
          "DI 4WD (2523cc)",
          "MDI 2WD (2523cc)",
          "MAJOR DI (2523cc)",
          "CL 500 (2523cc)",
          "MDI (2523cc)",
        ],
      },
      {
        model: "CL 550",
        variants: [
          "SOFT TOP 8 SEATER (2523cc)",
          "SOFT TOP 6 SEATER (2523cc)",
          "SOFT TOP 7 SEATER (2523cc)",
          "4WD (2523cc)",
          "DI 2WD MAJOR (2523cc)",
          "7STR (2523cc)",
          "MDI (2523cc)",
          "MDI (7 SEATER) (2523cc)",
          "550 2WD (2523cc)",
          "CL 550 (2523cc)",
          "550 4WD (2498cc)",
        ],
      },
      {
        model: "THAR CRDE",
        variants: [
          "4X4 AC 6STR (2498cc)",
          "4X4 6STR (2498cc)",
          "4X4 AC (2498cc)",
          "4X4 AC (2498cc)",
          "0 (2498cc)",
        ],
      },
      {
        model: "THAR",
        variants: [
          "DI (2523cc)",
          "DI 4X4 PS (2523cc)",
          "DI 4X2 PS (2523cc)",
          "DI 7 SEATER (4X2) (2523cc)",
          "DI 7 SEATER (4X4) (2523cc)",
          "DI 2WD BS IV (2523cc)",
          "DI 2WD BS IV (2523cc)",
          "DI 4WD PS BS IV (2523cc)",
          "DI 4WD PS BS IV (2523cc)",
          "DI 2WD PS BS III (2523cc)",
          "DI 2WD PS BS III (2523cc)",
          "DI 4WD PS BS III (2523cc)",
          "DI 4WD PS BS III (2523cc)",
          "DI 4WD BS IV (2523cc)",
          "DI 4WD BS IV (2523cc)",
          "DI 4X4 (2523cc)",
          "CRDE 4X4 (2523cc)",
          "CRDE (2523cc)",
          "DI 4WD (2523cc)",
          "DI 2WD (2523cc)",
          "DI 2WD (2523cc)",
          "DI 4X2 (2523cc)",
          "CRDE 7 STR (2498cc)",
          "4 WD (2498cc)",
          "4WD 7STR (2498cc)",
          "4X4 (2498cc)",
          "CRDE 6 STR (2498cc)",
          "CRDE 6 SEATER (4X4) (2498cc)",
          "CRDE AC 6 SEATER (4X4) (2498cc)",
          "CRDE AC 7 SEATER (4X4) (2498cc)",
          "CRDE 7 SEATER (4X4) (2498cc)",
          "DI TC 4WD (2498cc)",
          "CRDE 4X4 AC1 (2498cc)",
          "CRDE 4X4 AC1 (2498cc)",
          "CRDE 4X4 NON AC (2498cc)",
          "CRDE 4X4 NON AC (2498cc)",
          "4WD CRDE (2498cc)",
          "CDRE (2498cc)",
          "CRDE 4X4 (2498cc)",
          "CRDE 4X4 ABS (2498cc)",
          "CRDE 4X4 ABS (2498cc)",
          "700 SPECIAL EDITION (2498cc)",
          "700 SPECIAL EDITION (2498cc)",
          "CRDE (2498cc)",
          "CRDE 4WD AC 7 STR (2498cc)",
          "CRDE 4X2 (2498cc)",
          "RHD CRDE E IV 4WD (2498cc)",
          "CRDE 4WD AC (2498cc)",
          "CRDE ABS 700 SPECIAL EDITION (2498cc)",
          "CRDE ABS (2498cc)",
          "CRDE 4WD (2498cc)",
          "CRDE 4X4 BS-IV (2498cc)",
          "AX AC B P MT 4WD 6S SOFT TOP STD BS6 (1997cc)",
          "AX AC P MT 4WD 6S SOFT TOP BS6 (1997cc)",
          "AX OPT P MT 4WD 4S CONVERTIBLE TOP BS6 (1997cc)",
          "LX P MT 4WD 4S HARD TOP BS6 (1997cc)",
          "LX P AT 4WD 4S CONVERTIBLE TOP BS6 (1997cc)",
          "LX P AT 4WD 4S HARD TOP BS6 (1997cc)",
          "LX AT 4 STR HARD TOP P 2WD (1997cc)",
          "LX MT 4 STR HARD TOP D 2WD (1997cc)",
          "2.0 AX AC 4WD STD NON MLD MT PETROL 6 SEAT (1997cc)",
          "2.0 AX AC 4WD SOFT TOP MT PETROL 6 SEAT (1997cc)",
          "2.0 AX OPT 4WD CONVERTIBLE MT PETROL 4 SEAT (1997cc)",
          "2.0 LX 4WD HARD TOP PETROL MT 4 SEAT (1997cc)",
          "2.0 LX 4WD CONVERTIBLE PETROL AT 4 SEAT (1997cc)",
          "2.0 LX 4WD HARD TOP PETROL AT 4 SEAT (1997cc)",
          "2.0 LX 2WD HARD TOP PETROL AT 4 SEAT (1997cc)",
          "LX 4-STR CONVERTIBLE PETROL AT (1997cc)",
          "LX 4-STR CONVERTIBLE PETROL AT (1997cc)",
          "LX 4-STR HARD TOP PETROL MT (1997cc)",
          "LX 4-STR HARD TOP PETROL MT (1997cc)",
          "LX 4-STR HARD TOP PETROL AT (1997cc)",
          "LX 4-STR HARD TOP PETROL AT (1997cc)",
          "AX STD 6-STR SOFT TOP PETROL MT (1997cc)",
          "AX STD 6-STR SOFT TOP PETROL MT (1997cc)",
          "AX 4-STR CONVERTIBLE PETROL MT (1997cc)",
          "AX 4-STR CONVERTIBLE PETROL MT (1997cc)",
          "AX 6-STR SOFT TOP PETROL MT (1997cc)",
          "AX 6-STR SOFT TOP PETROL MT (1997cc)",
          "AX O CONVERTIBLE TOP PETROL MT 4WD (1997cc)",
          "AX O CONVERTIBLE TOP PETROL MT 4WD (1997cc)",
          "LX CONVERTIBLE TOP PETROL AT 4WD (1997cc)",
          "LX CONVERTIBLE TOP PETROL AT 4WD (1997cc)",
          "LX HARD TOP PETROL AT 4WD (1997cc)",
          "LX HARD TOP PETROL AT 4WD (1997cc)",
          "LX HARD TOP PETROL MT 4WD (1997cc)",
          "LX HARD TOP PETROL MT 4WD (1997cc)",
          "LX 4-STR RWD HARD TOP PETROL AT (1997cc)",
          "LX 4-STR RWD HARD TOP PETROL AT (1997cc)",
          "AX HARD TOP (1997cc)",
          "LX P MT 4WD 4S HT (1997cc)",
          "2020 LX AT (1997cc)",
          "AX AC B P MT 4WD 6S ST (STD) (1997cc)",
          "AX AC P MT 4WD 6S ST (1997cc)",
          "AX OPT P MT 4WD 4S CT (1997cc)",
          "LX P AT 2WD HT BSVI (1997cc)",
          "LX P AT 4WD 4S HT (1997cc)",
          "LX P AT 4WD 4S CT (1997cc)",
          "AX SOFT TOP MT 6STR PETROL (1997cc)",
          "AX STD SOFT TOP MT 6STR PETROL (1997cc)",
          "LX CONVERTIBLE AT 4STR PETROL (1997cc)",
          "AX CONVERTIBLE MT 4STR PETROL (1997cc)",
          "LX HARD TOP MT 4STR PETROL (1997cc)",
          "LX HARD TOP AT 4STR PETROL (1997cc)",
          "AX AC D MT 4WD 6S SOFT TOP BS6 (2184cc)",
          "AX OPT D MT 4WD 4S CONVERTIBLE TOP BS6 (2184cc)",
          "AX OPT D MT 4WD 4S HARD TOP BS6 (2184cc)",
          "LX D MT 4WD 4S CONVERTIBLE TOP BS6 (2184cc)",
          "LX D MT 4WD 4S HARD TOP BS6 (2184cc)",
          "LX D AT 4WD 4S CONVERTIBLE TOP BS6 (2184cc)",
          "LX D AT 4WD 4S HARD TOP BS6 (2184cc)",
          "2.2 AX AC 4WD SOFT TOP MT DIESEL 6 SEAT (2184cc)",
          "2.2 AX OPT 4WD CONVERTIBLE MT DIESEL 4 SEAT (2184cc)",
          "2.2 AX OPT 4WD HARD TOP MT DIESEL 4 SEAT (2184cc)",
          "2.2 LX 4WD CONVERTIBLE DIESEL MT 4SEAT (2184cc)",
          "2.2 LX 4WD HARD TOP DIESEL MT 4SEAT (2184cc)",
          "2.2 LX 4WD CONVERTIBLE DIESEL AT 4SEAT (2184cc)",
          "2.2 LX 4WD HARD TOP DIESEL AT 4SEAT (2184cc)",
          "LX 4-STR HARD TOP DIESEL MT (2184cc)",
          "LX 4-STR HARD TOP DIESEL MT (2184cc)",
          "AX 6-STR SOFT TOP DIESEL MT (2184cc)",
          "AX 6-STR SOFT TOP DIESEL MT (2184cc)",
          "AX 4-STR CONVERTIBLE DIESEL MT (2184cc)",
          "AX 4-STR CONVERTIBLE DIESEL MT (2184cc)",
          "AX 4-STR HARD TOP DIESEL MT (2184cc)",
          "AX 4-STR HARD TOP DIESEL MT (2184cc)",
          "LX 4-STR CONVERTIBLE DIESEL AT (2184cc)",
          "LX 4-STR CONVERTIBLE DIESEL AT (2184cc)",
          "LX 4-STR HARD TOP DIESEL AT (2184cc)",
          "LX 4-STR HARD TOP DIESEL AT (2184cc)",
          "LX DSL AT 4WD HT BS6 (2184cc)",
          "AX(O) CONVERTIBLE TOP DIESEL MT 4WD (2184cc)",
          "AX O CONVERTIBLE TOP DIESEL MT 4WD (2184cc)",
          "AX(O) HARD TOP DIESEL MT 4WD (2184cc)",
          "AX 4-STR O HARD TOP DIESEL MT (2184cc)",
          "AX 4-STR O HARD TOP DIESEL MT (2184cc)",
          "LX 4-STR CONVERTIBLE DIESEL MT (2184cc)",
          "LX 4-STR CONVERTIBLE DIESEL MT (2184cc)",
          "LX CONVERTIBLE TOP DIESEL AT 4WD (2184cc)",
          "LX CONVERTIBLE TOP DIESEL AT 4WD (2184cc)",
          "LX CONVERTIBLE TOP DIESEL MT 4WD (2184cc)",
          "LX CONVERTIBLE TOP DIESEL MT 4WD (2184cc)",
          "LX HARD TOP DIESEL AT 4WD (2184cc)",
          "LX HARD TOP DIESEL AT 4WD (2184cc)",
          "LX HARD TOP DIESEL MT 4WD (2184cc)",
          "LX HARD TOP DIESEL MT 4WD (2184cc)",
          "LX AT 4WD 4S CT (2184cc)",
          "LX D AT 4WD 4S CT (2184cc)",
          "AX AC D MT 4WD 6S ST (2184cc)",
          "AX OPT D MT 4WD 4S CT (2184cc)",
          "AX OPT D MT 4WD 4S HT (2184cc)",
          "AX OPT P MT 4WD 4S CT (2184cc)",
          "LX D MT 4WD 4S CT (2184cc)",
          "LX D AT 4WD 4S HT (2184cc)",
          "LX D MT 4WD 4S HT (2184cc)",
          "LX HARD TOP MT 4STR DIESEL (2184cc)",
          "AX SOFT TOP MT 6STR DIESEL (2184cc)",
          "AX HARD TOP MT 4STR DIESEL (2184cc)",
          "LX CONVERTIBLE AT 4STR DIESEL (2184cc)",
          "LX HARD TOP AT 4STR DIESEL (2184cc)",
          "AX CONVERTIBLE MT 4STR DIESEL (2184cc)",
          "AX HARD TOP (O) MT 4STR DIESEL (2184cc)",
          "LX CONVERTIBLE MT 4STR DIESEL (2184cc)",
          "AX OPT D MT 2WD 4S HT BS6 (1497cc)",
          "LX D MT 2WD 4S HT BS6 (1497cc)",
          "LX P AT 2WD 4S HT (1497cc)",
          "1.5 AX (O) RWD HARD TOP DIESEL 4 SEAT (1497cc)",
          "1.5 LX RWD HARD TOP DIESEL 4 SEAT (1497cc)",
          "LX 4-STR HARD TOP RWD (1497cc)",
          "LX D MT 2WD 4S HT (1497cc)",
          "AX(O) HARD TOP MT RWD (1497cc)",
          "LX HARD TOP MT RWD (1497cc)",
          "AX 4-STR O HARD TOP DIESEL MT (1497cc)",
          "LX 4-STR RWD HARD TOP DIESEL MT (1497cc)",
          "LX 4-STR RWD HARD TOP DIESEL MT (1497cc)",
          "AX O HARD TOP DIESEL MT RWD (1497cc)",
          "AX O HARD TOP DIESEL MT RWD (1497cc)",
          "AX OPT D MT 2WD HT BSVI (1497cc)",
          "LX D MT 2WD HT BSVI (1497cc)",
          "CRDE 4WD 6 STR (2609cc)",
          "CRDE 4WD (2609cc)",
          "LX HARD TOP AT 4STR PETROL. (2163cc)",
        ],
      },
      {
        model: "NUVOSPORT",
        variants: [
          "N6 (AT) (1493cc)",
          "N8 (AT) (1493cc)",
          "N8 AS MHAWK BS4 2WD (1493cc)",
          "N4 PLUS (1493cc)",
          "N4 (1493cc)",
          "N6 (1493cc)",
          "N8 AMT (1493cc)",
          "N8 (1493cc)",
          "N6 AMT (1493cc)",
        ],
      },
      {
        model: "NUVO SPORT",
        variants: [
          "N4 PLUS (1493cc)",
          "N4 (1493cc)",
          "N6 (1493cc)",
          "N8 AMT (1493cc)",
          "N8 (1493cc)",
          "N6 AMT (1493cc)",
        ],
      },
      {
        model: "QUANTO",
        variants: [
          "C8 2 STR (1493cc)",
          "C2 7STR (1493cc)",
          "C4 7STR (1493cc)",
          "C6 7STR (1493cc)",
          "C8 7STR (1493cc)",
          "C2 7 SEAT (1493cc)",
          "C4 7 SEAT (1493cc)",
          "C6 7 SEAT (1493cc)",
          "C8 7 SEAT (1493cc)",
          "C8 BSIV 2WD RHD 7SF (1493cc)",
          "C4 (1493cc)",
          "C2 (1493cc)",
          "C8 (1493cc)",
          "C6 (1493cc)",
          "C8 BSIV (2523cc)",
        ],
      },
      {
        model: "SCORPIO M2DICR",
        variants: [
          "2.5 LX 9 SEATER (2523cc)",
          "2.5 S3 7 SEATER (2523cc)",
          "2.5 S3 9 SEATER (2523cc)",
          "S2 9 SEATER (2523cc)",
          "S2 (2523cc)",
          "2.5 EX 7STR (2523cc)",
          "2.5 EX 8STR (2523cc)",
          "2.5 EX 9STR (2523cc)",
          "2.5 EX 9 SEATER (2523cc)",
          "2.5 EX 7 SEATER (2523cc)",
          "2.5 EX 8 SEATER (2523cc)",
        ],
      },
      {
        model: "SCORPIO W CRDE",
        variants: [
          "DC 4WD (2609cc)",
          "SLX 5 SEATER (2609cc)",
          "DLX 7STR (2609cc)",
          "DLX 8STR (2609cc)",
          "DX 7STR (2609cc)",
          "DX 8STR (2609cc)",
          "DX 9STR (2609cc)",
          "SLX 7STR (2609cc)",
          "SLX 8STR (2609cc)",
          "GATEWAY 4 WD (2609cc)",
          "SLX 7 SEATER (2609cc)",
          "SLX 8 SEATER (2609cc)",
          "GATEWAY (2609cc)",
          "DX 8 SEATER (2609cc)",
          "DX 9 SEATER (2609cc)",
          "DLX 7 SEATER (2609cc)",
          "DLX 8 SEATER (2609cc)",
          "DX 7 SEATER (2609cc)",
        ],
      },
      {
        model: "GENIO",
        variants: [
          "DC BSIV (2489cc)",
          "DOUBLE CAB (2489cc)",
          "DOUBLE CAB VX (2498cc)",
          "DC STAYLED MHK EIV4WD LHDABS (2500cc)",
          "DC 2WD BS3 NON AC (2500cc)",
          "SC 2WD BS3 (2500cc)",
          "SC 2WD BS4 (2500cc)",
          "DC 2WD BS3 AC (2500cc)",
        ],
      },
      {
        model: "VERITO VIBE CS",
        variants: [
          "1.5 DCI 6 (1461cc)",
          "1.5 DCI D2 (1461cc)",
          "1.5 DCI D4 (1461cc)",
          "1.5 D2 (1461cc)",
          "1.5 D2 (1461cc)",
          "1.5 D4 (1461cc)",
          "1.5 D4 (1461cc)",
          "1.5 D6 (1461cc)",
          "1.5 D6 (1461cc)",
        ],
      },
      {
        model: "TREO",
        variants: [
          "YAARI (200cc)",
          "YAARI HRT (150cc)",
          "SOFT TOP (150cc)",
          "SFT. (650cc)",
        ],
      },
      {
        model: "U321",
        variants: ["8 STR (1497cc)", "7 STR (1497cc)", "8 SEATER (1499cc)"],
      },
      {
        model: "S 201",
        variants: ["HIGH PETROL MT (1197cc)", "HIGH DIESEL MT (1493cc)"],
      },
      {
        model: "S201",
        variants: [
          "OTS2 MID GASOLINE (TGDI) BS4 MT (1197cc)",
          "OTS2 HIGH PETROL (TC MPFI) BS4 MT 7 STR (1197cc)",
          "HIGH (O) GASOLINE (TC MPFI) (1197cc)",
          "OTS2 HIGH PETROL (TC MPFI) BS4 MT (1197cc)",
          "OTS2 MID PETROL (TC MPFI) BS4 MT (1197cc)",
          "OTS2 HIGH DIESEL BS4 MT 7 STR (1497cc)",
          "HIGH(O) DIESEL BS4 MT RX (1497cc)",
          "OTS2 MID DIESEL BS4 MT (1497cc)",
          "OTS2 HIGH (O) DIESEL BS6 MT (1497cc)",
          "OTS2 HIGH DIESEL BS4 AMT (1497cc)",
          "S201 HIGH DIESEL BS4 MT SK (1497cc)",
          "VP HIGH DIESEL BS4 AMT GR (1497cc)",
          "OTS2 BASE DIESEL BS4 MT (1497cc)",
          "OTS2 HIGH DIESEL BS4 MT (1497cc)",
        ],
      },
      {
        model: "U325",
        variants: ["BS6 MT (1497cc)"],
      },
      {
        model: "U 325",
        variants: ["HIGH PLUS 7 STR BS6 MT WP (1497cc)"],
      },
      {
        model: "ECONOMY",
        variants: [
          "STANDARD (1990cc)",
          "STD (1990cc)",
          "STD (2523cc)",
          "DI (2523cc)",
          "0 (2523cc)",
          "DI 2WD (2523cc)",
        ],
      },
      {
        model: "MM 540",
        variants: [
          "DP 2WD (2112cc)",
          "DP FRP BODY 8 SEATER (2112cc)",
          "DP FRP BODY 6 SEATER (2112cc)",
          "540 DP 9 STR (2112cc)",
          "540 DP (2112cc)",
          "DP FRP BODY 5 SEATER (2523cc)",
          "SOFT TOP 6 SEATER (2523cc)",
          "LEGEND (2523cc)",
        ],
      },
      {
        model: "MM 550",
        variants: [
          "DP (2112cc)",
          "DP ST 12 SEATER (2112cc)",
          "DP ST 8 SEATER (2112cc)",
          "550 DP (2112cc)",
          "ST (2495cc)",
        ],
      },
      {
        model: "MM550",
        variants: [
          "XD 4WD 6 SETAER (2112cc)",
          "DP FRP 5 SEATER (2112cc)",
          "2.5 ST 8 SEATER (2495cc)",
        ],
      },
      {
        model: "MAXXIMO",
        variants: [
          "VX (909cc)",
          "HARD TOP (909cc)",
          "VX MINI VAN (909cc)",
          "MINI VAN 9 STR (909cc)",
          "MINI VAN 8 STR (909cc)",
          "PLUS 8 SEATER (909cc)",
          "HARD TOP 5 SEATER (909cc)",
          "VX 7 SEATER (909cc)",
          "HARD TOP 8 SEATER (909cc)",
          "HARD TOP 7 SEATER (909cc)",
          "PASSANGER VAN (909cc)",
          "MINIVAN BS III (909cc)",
          "MINIVAN VX BSIII (SCHOOL VAN) (909cc)",
          "CYL MHAWK TC BS3 (909cc)",
          "MINI VAN (909cc)",
          "SCH VAN (909cc)",
          "MINI VAN VX 8STR (909cc)",
          "MINI VAN VX 5STR (909cc)",
          "9 SEATER/SCH VAN (909cc)",
          "11 SEATER/SCH VAN (909cc)",
          "14 SEATER/SCH VAN (909cc)",
          "MINI VAN VX BSVI (909cc)",
          "MINI VAN 11 STR BSVI (909cc)",
          "MINI VAN VX 7STR (909cc)",
          "MINI VAN VX (909cc)",
          "8 SEATER (909cc)",
          "VX 8 SEATER (909cc)",
          "7 SEATER (909cc)",
        ],
      },
      {
        model: "MAXXIMO.",
        variants: ["0 (909cc)"],
      },
      {
        model: "ARMADA",
        variants: [
          "STANDARD 10 SEATER (2112cc)",
          "STANDARD 8 SEATER (2112cc)",
          "4WD (2112cc)",
          "2WD (2112cc)",
          "0 (2112cc)",
          "AC (2112cc)",
          "AC (2112cc)",
          "GRAND 2WD.. (2112cc)",
          "GRAND 2 WD (2112cc)",
          "GRAND 4WD.. (2112cc)",
          "GRAND 4 WD (2112cc)",
          "STD. (2112cc)",
          "STD (2112cc)",
          "7 SEATER/DIESEL (2112cc)",
          "8 SEATER/DIESEL (2112cc)",
          "9 SEATER/DIESEL (2112cc)",
          "10 SEATER/DIESEL (2112cc)",
          "9 SEATER/PETROL (2112cc)",
          "14 SEATER/DIESEL (2112cc)",
          "STD 8 SEATER (2112cc)",
          "STD 10 SEATER (2112cc)",
          "GRAND (2498cc)",
          "GRAND 8 SEATER (2498cc)",
          "ARMADA GRAND A/C (2498cc)",
          "7 SEATER (2498cc)",
          "8 SEATER (2498cc)",
          "9 SEATER (2498cc)",
          "10 SEATER (2498cc)",
        ],
      },
      {
        model: "ECONOMY DI",
        variants: ["JEEP (2523cc)", "0 (2523cc)"],
      },
      {
        model: "GIO",
        variants: [
          "7 SEATER (441cc)",
          "4 SEATER (441cc)",
          "COMPACT CAB 7 SEATER (441cc)",
          "COMPACT CAB 5 SEATER (441cc)",
          "COMPACT CAB 4 SEATER (441cc)",
          "0 (441cc)",
          "0 (441cc)",
          "7 STR (441cc)",
          "CAB 4STR (442cc)",
          "CAB 5STR (442cc)",
          "PASSENGER STR 8 (442cc)",
        ],
      },
      {
        model: "KUV100 NXT",
        variants: [
          "K2 PLUS 6 STR BS6 (1198cc)",
          "K4 PLUS 6 STR BS6 (1198cc)",
          "K6 PLUS 6 STR BS6 (1198cc)",
          "K8 6 STR BS6 (1198cc)",
          "K2 PLUS D 6 STR (1198cc)",
          "K4 PLUS D 5 STR (1198cc)",
          "K4 PLUS D 6 STR (1198cc)",
          "K6 PLUS D 5 STR (1198cc)",
          "K6 PLUS D 6 STR (1198cc)",
          "K4+ D 5 STR (1198cc)",
          "K4+ D 6 STR (1198cc)",
          "K6+ D 5 STR (1198cc)",
          "K6+ D 6 STR (1198cc)",
          "K2 D 6 STR (1198cc)",
          "K8 D 5 STR (1198cc)",
          "K8 D 6 STR (1198cc)",
          "K8 D 6 STR DUAL TONE (1198cc)",
          "TRIP CNG (1197cc)",
          "TRIP CNG (1197cc)",
          "TRIP D (1197cc)",
          "TRIP D (1197cc)",
          "TRIP P (1197cc)",
          "TRIP P (1197cc)",
        ],
      },
      {
        model: "KUV 100 NXT",
        variants: [
          "K2 PLUS D 6 STR (1198cc)",
          "K4 PLUS D 5 STR (1198cc)",
          "K4 PLUS D 6 STR (1198cc)",
          "K6 PLUS D 5 STR (1198cc)",
          "K6 PLUS D 6 STR (1198cc)",
          "K2 D 6 STR (1198cc)",
          "K8 D 5 STR (1198cc)",
          "K8 D 6 STR (1198cc)",
          "K8 D 6 STR DUAL TONE (1198cc)",
          "K2 6STR DIESEL (1198cc)",
          "K2 6STR PETROL (1198cc)",
          "K2 PLUS 6 STR (1198cc)",
          "K2 PLUS 6 STR (1198cc)",
          "K4 PLUS 5 STR (1198cc)",
          "K4 PLUS 5 STR (1198cc)",
          "K4 PLUS 6 STR (1198cc)",
          "K4 PLUS 6 STR (1198cc)",
          "K4+ 5 STR (1198cc)",
          "K4+ 6 STR (1198cc)",
          "K2 6 STR (1198cc)",
          "K2 6 STR (1198cc)",
          "K6 PLUS 5 STR (1198cc)",
          "K6 PLUS 5 STR (1198cc)",
          "K6 PLUS 6 STR (1198cc)",
          "K6 PLUS 6 STR (1198cc)",
          "K8 5 STR (1198cc)",
          "K8 5 STR (1198cc)",
          "K8 6 STR (1198cc)",
          "K8 6 STR (1198cc)",
          "K8 6 STR DUAL TONE (1198cc)",
          "K8 6 STR DUAL TONE (1198cc)",
        ],
      },
      {
        model: "E2O",
        variants: [
          "PLUS P2 (19000cc)",
          "T2 5 STR (19000cc)",
          "T1 (19000cc)",
          "T6 (19000cc)",
          "T0 (19000cc)",
          "T2 (19000cc)",
          "PLUS P4 (19000cc)",
          "PLUS P6 (19000cc)",
          "PLUS P8 (19000cc)",
          "PLUS P2 (19cc)",
          "PLUS P2 (19cc)",
          "PLUS P4 ELECTRIC (19cc)",
          "T20 (19cc)",
          "T20 (19cc)",
          "T2 (19cc)",
          "T2 (19cc)",
          "PLUS P4 (19cc)",
          "PLUS P4 (19cc)",
          "PLUS P6 (19cc)",
          "PLUS P6 (19cc)",
          "PLUS P8 (19cc)",
          "PLUS P2 (999cc)",
          "STD (999cc)",
          "NXT PLUS ME (999cc)",
          "T01 (999cc)",
          "PLUS CITYSMART P4 (999cc)",
          "PLUS CITYSMART P6 (999cc)",
          "PLUS CITYSMART P8 (999cc)",
          "T20 (999cc)",
          "T0 (999cc)",
          "T2 (999cc)",
          "PLUS P4 (999cc)",
          "PLUS P6 (999cc)",
          "PLUS P8 (999cc)",
          "STD (1522cc)",
          "PLUS CITYSMART P2 (1000cc)",
          "T 1 (1000cc)",
          "T 6 (1000cc)",
          "T 20 (1000cc)",
          "T0 (15cc)",
          "T0 (15cc)",
          "T0 (0cc)",
          "T2 (0cc)",
          "PLUS P8 (30cc)",
          "PLUS P2 (30cc)",
        ],
      },
      {
        model: "UNIVERSAL",
        variants: [
          "2.5 JEEP 8 SEATER (2498cc)",
          "7 SEATER (2498cc)",
          "7 SEATER (2112cc)",
          "6 SEATER (2112cc)",
        ],
      },
      {
        model: "XUV 400",
        variants: [
          "EV EL FAST CHARGER DT (39400cc)",
          "EV EL FAST CHARGER (39400cc)",
          "EL FAST CHARGER 7.2 KW (110000cc)",
          "EL FAST CHARGER 7.2 KW DT (110000cc)",
          "EL FC DUAL TONE (1600cc)",
          "EL FC (1600cc)",
          "EC FC (1600cc)",
          "EC (1600cc)",
          "EL 5S ZK (110cc)",
          "EL DT (110cc)",
          "EV EC (110cc)",
          "EV EC FAST CHARGER (110cc)",
          "EV EL FAST CHARGER (110cc)",
          "EV (110cc)",
          "EL 7.2 KW DT (39.4cc)",
          "EL 7.2 KW (39.4cc)",
          "EC 3.3 KW (34.5cc)",
          "EC 7.2 KW (34.5cc)",
          "EV EC (34500cc)",
          "EV EC FAST CHARGER (34500cc)",
          "EV EC (2179cc)",
          "EV EC FAST CHARGER (2179cc)",
          "EV EL FAST CHARGER (2179cc)",
        ],
      },
      {
        model: "CHAMPION",
        variants: [
          "(PASSENGER) (510cc)",
          "(PASSENGER) CNG BSIII (510cc)",
          "STR 14 (510cc)",
          "LOAD CARRIER (510cc)",
          "LOAD CABIN/BS-IV (510cc)",
          "LOAD COWL/BS-IV (510cc)",
          "LOAD BS-IV (510cc)",
          "LOAD DIESEL/BS-IV (510cc)",
          "LOAD CNG (510cc)",
          "6STR (510cc)",
          "7STR (510cc)",
          "(PASSENGER) (395cc)",
          "ALFA CNG (395cc)",
          "BIJILEE 10 SEATER (395cc)",
          "BIJLEE SEMI (395cc)",
          "STR 8 (450cc)",
          "STR 6 (499cc)",
          "CHAMPION PASSENGER CN (499cc)",
          "CHAMPION DIESEL PASSE (550cc)",
          "MAHINDRA CHAMPION CNG (436cc)",
        ],
      },
      {
        model: "LEGEND",
        variants: ["SOFT TOP (2523cc)", "4WD BS3 (2050cc)"],
      },
      {
        model: "CJ 500 DI",
        variants: [
          "4WD (2112 CC) (2112cc)",
          "6 SEAT (1489 CC) (1489cc)",
          "TOURER (2350cc)",
          "2WD (2306cc)",
        ],
      },
      {
        model: "CDR 650",
        variants: [
          "SOFT TOP 10 SEATER (2523cc)",
          "SOFT TOP TOURER MDI (6+11) (2523cc)",
          "MDI TC 2WD 7STR SB2 (2523cc)",
          "MDI TC (2523cc)",
          "0 (2523cc)",
        ],
      },
      {
        model: "MAX FESTARA",
        variants: [
          "6 SEATER (2112cc)",
          "9 SEATER (2112cc)",
          "STD 6 SEATER (2112cc)",
          "STD 9 SEATER (2112cc)",
          "STD 10 SEATER (2112cc)",
          "FESTARA (2523cc)",
          "6 STR (2523cc)",
        ],
      },
      {
        model: "MAJOR",
        variants: [
          "CL 550 (2523cc)",
          "CL (2523cc)",
          "STANDARD (2523cc)",
          "CL 550 4WD 7 SEATER (2523cc)",
          "CL 550 8 SEATER (2523cc)",
          "CL 550 4WD 8 SEATER (2523cc)",
          "STD (2523cc)",
          "0 (2523cc)",
          "SOFT TOP 7 SEATER (2523cc)",
          "CL4WD (2523cc)",
          "CL 550 4WD (2523cc)",
        ],
      },
      {
        model: "WILLYS",
        variants: ["LOW BONET (2179cc)", "JEEP (2112cc)", "JEEP (2199cc)"],
      },
      {
        model: "CLASSIC",
        variants: [
          "CL 340 DP 4WD (2523cc)",
          "CL 500Â  MDI 2 WD (2523cc)",
          "CL 550 (2523cc)",
          "340DP 4WD (2112cc)",
          "CL 340 (2112cc)",
        ],
      },
      {
        model: "REXTON",
        variants: ["RX7 AT AWD BSIV (2696cc)", "RX7 XVT (2696cc)"],
      },
      {
        model: "FESTIVA",
        variants: ["STANDARD (2523cc)", "STD (2523cc)"],
      },
      {
        model: "FORESTER",
        variants: [
          "8 SEATER (2000cc)",
          "5 SEATER (2000cc)",
          "STD (8) (2000cc)",
          "STD (2000cc)",
        ],
      },
      {
        model: "E SUPRO",
        variants: [
          "PASSENGER VAN (25000cc)",
          "7 SEATER VAN (909cc)",
          "8 SEATER VAN (909cc)",
        ],
      },
      {
        model: "AXE 2700",
        variants: ["STANDARD (2700cc)"],
      },
      {
        model: "CJ 500",
        variants: ["TOURER (2350cc)"],
      },
      {
        model: "MAH MINISTAR",
        variants: ["15 STR (2523cc)"],
      },
      {
        model: "M 550",
        variants: ["DO (2112cc)"],
      },
      {
        model: "CJ 520",
        variants: ["6 SEAT (2520cc)"],
      },
      {
        model: "CJ 340",
        variants: ["DP 6 SEAT (2112cc)"],
      },
      {
        model: "FESTARA",
        variants: ["6 SEATER (2523cc)"],
      },
      {
        model: "550 DP",
        variants: ["SOFT TOP 4WD (2523cc)"],
      },
      {
        model: "E ALFA",
        variants: ["0 (150cc)"],
      },
      {
        model: "E-ALFA",
        variants: ["MINI (1000cc)"],
      },
      {
        model: "INVADER",
        variants: ["MDI TC 2WD 7 STR (2523cc)"],
      },
      {
        model: "MAXI",
        variants: ["CAB STR 13 (3330cc)"],
      },
      {
        model: "INVENDOR",
        variants: ["4WD JEEP (2523cc)"],
      },
      {
        model: "CJ",
        variants: ["640 DP 2WD (2523cc)"],
      },
      {
        model: "MINIVAN",
        variants: ["LXD (625cc)"],
      },
      {
        model: "TEMPO",
        variants: ["CITY LINE 3WD (450cc)"],
      },
      {
        model: "ALPHA",
        variants: ["DX (436cc)", "CHAMP (436cc)", "CHAMPION (436cc)"],
      },
      {
        model: "PICKUP",
        variants: ["BOLERO PIK UP CNG PS BSVI (2523cc)"],
      },
      {
        model: "TREKKER",
        variants: ["0 (2523cc)"],
      },
      {
        model: "VISTA TECH",
        variants: ["W9 FED AT (2179cc)"],
      },
      {
        model: "VINTAGE",
        variants: [
          "MAHINDRA JEEP (1067cc)",
          "MAHINDRA JEEP (2122cc)",
          "MAHINDRA JEEP (1540cc)",
          "JEEP (2000cc)",
        ],
      },
      {
        model: "MARKSMAN",
        variants: ["ARMOURED PERSONAL CARRIER (2179cc)"],
      },
      {
        model: "GIO PASSENGER",
        variants: [
          "6 SEATER/COMPACT CAB (510cc)",
          "4 SEATER/COMPACT CAB (510cc)",
        ],
      },
      {
        model: "GIO COMPACT CAB",
        variants: ["FBV (935cc)"],
      },
      {
        model: "9 SEATER JEEP",
        variants: ["SALOON (2533cc)"],
      },
      {
        model: "11 SEATER JEEP",
        variants: ["HARD TOP (2750cc)"],
      },
      {
        model: "MINI BUS",
        variants: ["13 SEATER/DIESEL (2523cc)", "12 SEATER/CNG (2523cc)"],
      },
      {
        model: "MTB T15 MDI",
        variants: ["12 SEATER/DIESEL (2523cc)"],
      },
      {
        model: "TOURISTER",
        variants: [
          "10 SEATER/DIESEL BSVI (2523cc)",
          "14 SEATER/DIESEL (2523cc)",
        ],
      },
      {
        model: "MAX",
        variants: ["MAXX 2 WD - LMV (2523cc)"],
      },
      {
        model: "MAXXIMO MINI VAN",
        variants: ["7SEATER/VX/DIESEL (909cc)", "7SEATER/DIESEL (909cc)"],
      },
      {
        model: "MAXXIMO SCHOOL VAN",
        variants: ["7SEATER/VX/DIESEL (909cc)"],
      },
      {
        model: "SUPRO ELECTRIC",
        variants: [
          "1950/FBV (909cc)",
          "7 SEATER/ELECTRIC (909cc)",
          "9 SEATER/ELECTRIC (909cc)",
        ],
      },
      {
        model: "SUPRO MINIVAN",
        variants: [
          "8SEATER/VX/CNG (909cc)",
          "8SEATER/VX/DIESEL (909cc)",
          "8SEATER/DIESEL (909cc)",
        ],
      },
      {
        model: "SUPRO SCHOOL VAN",
        variants: ["8SEATER/DIESEL (909cc)"],
      },
      {
        model: "TOURISTER COSMO",
        variants: ["09SEATER/BS-III/T 15/2.5 LTR (2523cc)"],
      },
      {
        model: "540 DP",
        variants: ["4WD (2112cc)", "SOFT TOP 2WD (2050cc)"],
      },
      {
        model: "INGENIO",
        variants: ["8STR (2600cc)"],
      },
      {
        model: "900 MD",
        variants: [
          "2WD 7STR (2670cc)",
          "2WD 9STR (2523cc)",
          "2WD DOUBLE CAB (2523cc)",
        ],
      },
      {
        model: "CDR 660",
        variants: ["MDI TC 2WD 10STR (1850cc)"],
      },
      {
        model: "FJ 470",
        variants: ["DS4 13STR (3333cc)"],
      },
      {
        model: "THAR DI",
        variants: ["0 (2523cc)"],
      },
      {
        model: "CL500 DI 2WD",
        variants: ["0 (2523cc)"],
      },
      {
        model: "SINGLE CAB EII",
        variants: ["0 (2498cc)"],
      },
      {
        model: "MM 540 DP",
        variants: ["0 (2112cc)"],
      },
      {
        model: "TUV 500",
        variants: [
          "T6 (1493cc)",
          "T4 PLUS (1493cc)",
          "T4 (1493cc)",
          "T8+ AMT (1493cc)",
          "T6+ AMT (1493cc)",
          "T8 (1493cc)",
          "T6 PLUS (1493cc)",
        ],
      },
      {
        model: "CL 340",
        variants: ["CL 340 (2112cc)"],
      },
      {
        model: "W201",
        variants: ["AWD W4 MHAWK BS4 7JA PWHT (2179cc)", "VP33 (2498cc)"],
      },
      {
        model: "PROTO",
        variants: ["FAV 5 (2523cc)"],
      },
      {
        model: "MXMO MINIVAN",
        variants: ["MAXXIMO MINIVAN BS3 (2498cc)"],
      },
      {
        model: "RAKSHAK",
        variants: ["PLUS (2179cc)"],
      },
      {
        model: "NC 640 DP",
        variants: ["0 (2112cc)"],
      },
      {
        model: "DI 550",
        variants: ["0 (2523cc)"],
      },
      {
        model: "QUANTO C4",
        variants: ["0 (1493cc)"],
      },
      {
        model: "QUANTO C2",
        variants: ["0 (1493cc)"],
      },
      {
        model: "QUANTO C8",
        variants: ["0 (1493cc)"],
      },
      {
        model: "QUANTO C6",
        variants: ["0 (1493cc)"],
      },
    ],
  },
  {
    make: "FOTON",
    models: [
      {
        model: "CS2",
        variants: ["9 SEATER/DIESEL (2776cc)", "12 SEATER/DIESEL (2776cc)"],
      },
      {
        model: "VIEW CS2",
        variants: ["2.8 DIESEL10 SEATER (2776cc)"],
      },
    ],
  },
  {
    make: "GMC",
    models: [
      {
        model: "SIERRA",
        variants: ["HD DEALI 2500 4WD CREW CAB PICK UP (6600cc)"],
      },
      {
        model: "YUKON",
        variants: ["4WD (6200cc)", "4WD 8 STR (6200cc)"],
      },
    ],
  },
  {
    make: "INDIAN MOTORS",
    models: [
      {
        model: "ROCKET",
        variants: ["& ROCKET (150cc)", "0 (150cc)"],
      },
    ],
  },
  {
    make: "J S AUTO",
    models: [
      {
        model: "VICTORY",
        variants: [
          "1000 D (435cc)",
          "PLUS 1000 D (435cc)",
          "PLUS (395cc)",
          "PLUS /G 400 WV CNG (395cc)",
          "RE (436cc)",
          "PLUS CNG (436cc)",
        ],
      },
      {
        model: "TIGER",
        variants: ["0 (510cc)"],
      },
    ],
  },
  {
    make: "LINCOLN LIMOUSINE",
    models: [
      {
        model: "TOWN CAR LLIMOUSINE",
        variants: ["0 (2000cc)"],
      },
    ],
  },
  {
    make: "MAHINDRA SSANGYONG",
    models: [
      {
        model: "REXTON",
        variants: [
          "RX5 4 STR (2696cc)",
          "RX 270 XVT (2696cc)",
          "RX7 7STR (2696cc)",
          "RX5 7STR (2696cc)",
          "RX 270 XDI (2696cc)",
          "0 (2696cc)",
          "RX6 (2696cc)",
          "RX6 (2696cc)",
          "RX5 (2696cc)",
          "RX5 (2696cc)",
          "RX7 (2696cc)",
          "RX7 (2696cc)",
          "2.7 (2696cc)",
          "YL7 (2696cc)",
          "RX D20DTR AT (1998cc)",
          "VX4 D20DTR MT (1998cc)",
        ],
      },
      {
        model: "OMNI",
        variants: ["STR 12 (2879cc)", "0 (2879cc)"],
      },
      {
        model: "MUSSO",
        variants: [
          "TD SALOON (2900cc)",
          "STD (2900cc)",
          "0 (2900cc)",
          "0 (2900cc)",
          "2.3 TC (2299cc)",
        ],
      },
      {
        model: "KORANDO",
        variants: ["STD (1998cc)", "0 (1998cc)"],
      },
      {
        model: "TIVOLI",
        variants: [
          "SE 2WD DIESEL (1597cc)",
          "EX 2WD PETROL (1597cc)",
          "1.6 EX PETROL (1597cc)",
          "1.6 SE DIESEL (1597cc)",
        ],
      },
      {
        model: "MB100",
        variants: ["STANDARD (2879cc)"],
      },
      {
        model: "ACTYON",
        variants: ["STD (1998cc)"],
      },
      {
        model: "KYRON",
        variants: ["STD (1998cc)"],
      },
      {
        model: "STEEL WORKS",
        variants: ["0 (1998cc)"],
      },
      {
        model: "ACTYON SPORT Q150",
        variants: ["0 (1998cc)"],
      },
    ],
  },
  {
    make: "MAYBACH",
    models: [
      {
        model: "57",
        variants: ["S (5980cc)"],
      },
      {
        model: "62",
        variants: ["SEDAN (5513cc)"],
      },
      {
        model: "62 SEDAN",
        variants: ["SG H 1 5 STR (5513cc)"],
      },
      {
        model: "MAYBACH 62",
        variants: ["S 5.5PETROL AT (5513cc)"],
      },
      {
        model: "MAYBACH 57",
        variants: ["5 SEATER SEADAN (5513cc)"],
      },
      {
        model: "S 500 MAYBACH",
        variants: ["0 (4663cc)"],
      },
      {
        model: "S 600 MAYBACH",
        variants: ["0 (5980cc)"],
      },
    ],
  },
  {
    make: "MG MOTOR",
    models: [
      {
        model: "HECTOR",
        variants: [
          "1.5 SHARP DCT (1451cc)",
          "1.5 SHARP DCT (1451cc)",
          "SHARP HYBRID 1.5 PETROL BS-VI (1451cc)",
          "SHARP 1.5 DCT PETROL BS-VI (1451cc)",
          "SMART HYBRID 1.5 PETROL BS-VI (1451cc)",
          "SMART 1.5 DCT PETROL BS-VI (1451cc)",
          "SUPER HYBRID 1.5 PETROL BS-VI (1451cc)",
          "SUPER 1.5 PETROL BS-VI (1451cc)",
          "STYLE 1.5 PETROL BS-VI (1451cc)",
          "SMART CVT BS6 (1451cc)",
          "SHARP CVT BS6 (1451cc)",
          "SHARP 1.5 PETROL CVT 6 STR. (1451cc)",
          "SAVVY 1.5 PRO CVT (1451cc)",
          "STYLE MT NEXT GEN (1451cc)",
          "SMART PRO MT (1451cc)",
          "SHARP PRO CVT (1451cc)",
          "SHARP PRO MT (1451cc)",
          "SMART EX MT (1451cc)",
          "SMART EX CVT (1451cc)",
          "SMART PRO EX MT (1451cc)",
          "SHARP PRO EX MT (1451cc)",
          "SHARP PRO EX CVT (1451cc)",
          "SHARP HYBIRD 1.5 PETROL (1451cc)",
          "SMART HYBIRD 1.5 PETROL (1451cc)",
          "STYLE 1.5 PETORL (1451cc)",
          "SUPER HYBIRD 1.5 PETROL (1451cc)",
          "1.5 SUPER PETROL MT (1451cc)",
          "1.5 SHARP PETROL DCT HYBRID MT (1451cc)",
          "1.5 SUPER PETROL HYBRID MT (1451cc)",
          "1.5 SMART PETROL HYBRID MT (1451cc)",
          "1.5 SHARP PETROL HYBRID MT (1451cc)",
          "1.5 SMART PETROL DCT HYBRID MT (1451cc)",
          "1.5 SHARP GASOLINE HYBRID MT (1451cc)",
          "1.5 SHINE PETROL CVT 5 SEAT (1451cc)",
          "PLUS 1.5 STYLE PETROL MT 7 SEAT (1451cc)",
          "PLUS 1.5 SUPER PETROL MT 7 SEAT (1451cc)",
          "1.5 SMART PETROL DCT (1451cc)",
          "PLUS 1.5 SMART GASOLINE CVT 6 SEAT (1451cc)",
          "1.5 SHARP PETROL CVT (1451cc)",
          "1.5 SHINE PETROL MT 5 SEAT (1451cc)",
          "1.5 TURBO SMART PRO PETROL (1451cc)",
          "1.5 TURBO SAVVY PRO PETROL CVT (1451cc)",
          "PLUS 1.5 TURBO SAVVY PRO PETROL CVT 7 SEAT (1451cc)",
          "PLUS 1.5 TURBO SHARP PRO PETROL CVT 7 SEAT (1451cc)",
          "PLUS 1.5 TURBO SAVVY PRO PETROL CVT 6 SEAT (1451cc)",
          "PLUS 1.5 TURBO SHARP PRO PETROL CVT 6 SEAT (1451cc)",
          "1.5 TURBO SHARP PRO PETROL CVT (1451cc)",
          "1.5 TURBO SHARP PRO PETROL (1451cc)",
          "PLUS 1.5 SHARP PRO GASOLINE 6 SEAT (1451cc)",
          "PLUS 1.5 SHARP CVT PETROL (1451cc)",
          "PLUS 1.5 TURBO SHARP PRO PETROL MT 7 SEAT (1451cc)",
          "1.5 STYLE PETROL MT (1451cc)",
          "PLUS 1.5 SHARP PLUS GASOLINE HYBRID MT 6 SEAT (1451cc)",
          "1.5 SHARP PETROL DCT (1451cc)",
          "PLUS 1.5 SMART PETROL AT (1451cc)",
          "PLUS 1.5 STYLE GASOLINE MT 6 SEAT (1451cc)",
          "PLUS 1.5 SHARP GASOLINE HYBRID MT 6 SEAT (1451cc)",
          "PLUS 1.5 SMART GASOLINE DCT 6 SEAT (1451cc)",
          "PLUS 1.5 SHARP GASOLINE DCT  6 SEAT (1451cc)",
          "PLUS 1.5 STYLE PETROL MT (1451cc)",
          "PLUS 1.5 SHARP PETROL AT (1451cc)",
          "1.5 SHARP PETROL HYBRID AT (1451cc)",
          "PLUS 1.5 SHARP GASOLINE DCT PLUS 6 SEAT (1451cc)",
          "PLUS 1.5 SHARP GASOLINE CVT 6 SEAT (1451cc)",
          "1.5 SMART PETROL CVT (1451cc)",
          "STYLE 1.5 PETROL (1451cc)",
          "SUPER HYBRID 1.5 PETROL (1451cc)",
          "SMART HYBRID 1.5 PETROL (1451cc)",
          "SHARP HYBRID 1.5 PETROL (1451cc)",
          "SMART 1.5 PETROL CVT (1451cc)",
          "SHARP 1.5 PETROL CVT (1451cc)",
          "SHINE MT (1451cc)",
          "SHINE 1.5 TCIC CVT AT (1451cc)",
          "GASOLINE 1.5 TCIC CVT SHINE BSVI (1451cc)",
          "SHARP HYBRID 1.5 MT PETROL (1451cc)",
          "SAVVY PRO (1451cc)",
          "SMART PRO 1611GCA (1451cc)",
          "SMART PRO EX 1.5 TURBO MT (1451cc)",
          "SHARP PRO (1451cc)",
          "SHARP PRO EX 1.5 PETROL TURBO CVT (1451cc)",
          "SHARP PRO EX 1.5 TURBO MT (1451cc)",
          "SMART 1.5 PETROL TURBO CVT (1451cc)",
          "SMART 1.5 TURBO MT (1451cc)",
          "SMART EX 1.5 PETROL TURBO CVT (1451cc)",
          "SMART EX 1.5 TURBO MT (1451cc)",
          "STYLE 1.5 TURBO MT (1451cc)",
          "SHARP PRO 1.5 TURBO MT DUAL TONE (1451cc)",
          "SHARP PRO 1.5 PETROL TURBO CVT DUAL TONE (1451cc)",
          "SAVVY PRO 1.5 TURBO CVT DUAL TONE (1451cc)",
          "SHINE 1.5 PETROL TURBO MT (1451cc)",
          "SHINE 1.5 PETROL TURBO CVT (1451cc)",
          "SHARP 1.5 DCT PETROL DUAL TONE. (1451cc)",
          "SHARP 1.5 DCT PETROL DUAL TONE (1451cc)",
          "SPECIAL ANNIVERSARY EDITION 1.5 PETROL. (1451cc)",
          "SPECIAL ANNIVERSARY EDITION 1.5 PETROL (1451cc)",
          "SHARP HYBRID 1.5 PETROL DUAL TONE. (1451cc)",
          "SHARP HYBRID 1.5 PETROL DUAL TONE (1451cc)",
          "SAVVY PRO 1.5 TURBO CVT.... (1451cc)",
          "SAVVY PRO 1.5 TURBO CVT (1451cc)",
          "SHARP PRO 1.5 TURBO MT (1451cc)",
          "SMART PRO 1.5 TURBO MT (1451cc)",
          "SHARP PRO 1.5 PETROL TURBO CVT (1451cc)",
          "SHARP 1.5 DCT PETROL (1451cc)",
          "SMART 1.5 DCT PETROL (1451cc)",
          "SUPER 1.5 PETROL (1451cc)",
          "1.5 SHARP HYBRID (1451cc)",
          "1.5 SMART HYBRID (1451cc)",
          "1.5 SMART DCT (1451cc)",
          "1.5 SUPER (1451cc)",
          "1.5 STYLE (1451cc)",
          "1.5 SUPER HYBRID (1451cc)",
          "1.5 SHARP CVT BSVI (1451cc)",
          "1.5 SMART CVT BSVI (1451cc)",
          "1.5 SHINE TURBO MT (1451cc)",
          "1.5 SHINE TURBO CVT (1451cc)",
          "1.5 STYLE TURBO MT BSVI (1451cc)",
          "1.5 SHINE TURBO MT BSVI (1451cc)",
          "1.5 SHINE TURBO CVT BSVI (1451cc)",
          "1.5 SMART TURBO HYBRID MT BSVI (1451cc)",
          "1.5 SMART TURBO DCT BSVI (1451cc)",
          "1.5 SHARP TURBO HYBRID MT BSVI (1451cc)",
          "1.5 SHARP TURBO HYBRID DT BSVI (1451cc)",
          "1.5 SHARP TURBO DCT BSVI (1451cc)",
          "1.5 SHARP TURBO DCT DT BSVI (1451cc)",
          "1.5 STYLE MT NEXT GEN BSVI (1451cc)",
          "1.5 SMART PRO MT BSVI (1451cc)",
          "1.5 SHARP PRO CVT BSVI (1451cc)",
          "1.5 SHARP PRO MT BSVI (1451cc)",
          "1.5 SAVVY PRO CVT BSVI (1451cc)",
          "1.5 SMART EX MT BSVI (1451cc)",
          "1.5 SMART EX CVT BSVI (1451cc)",
          "1.5 SMART PRO EX MT BSVI (1451cc)",
          "1.5 SHARP PRO EX MT BSVI (1451cc)",
          "1.5 SHARP PRO EX CVT BSVI (1451cc)",
          "PLUS 1.5 SHARP HYBRID MT (1451cc)",
          "1.5 SHINE TURBO PETROL (1451cc)",
          "1.5 STYLE PETROL (1451cc)",
          "1.5 SUPER HYBRID PETROL (1451cc)",
          "1.5 SUPER PETROL (1451cc)",
          "1.5 SMART HYBRID PETROL (1451cc)",
          "1.5 SMART TURBO CVT PLUS PETROL 6 STR (1451cc)",
          "1.5 STYLE PLUS PETROL (1451cc)",
          "1.5 SHARP HYBRID PLUS PETROL (1451cc)",
          "1.5 SMART DCT PLUS PETROL (1451cc)",
          "1.5 SHARP DCT PLUS PETROL (1451cc)",
          "1.5 SHARP TURBO DCT PLUS DT PETROL (1451cc)",
          "1.5 SMART CVT PETROL 5 STR (1451cc)",
          "1.5 SUPER TURBO HYBRID PLUS PETROL (1451cc)",
          "1.5 SHARP CVT PETROL (1451cc)",
          "1.5 SHARP TURBO DCT DT PETROL (1451cc)",
          "1.5 SHINE TURBO CVT PETROL (1451cc)",
          "1.5 SUPER TURBO PETROL (1451cc)",
          "1.5 SHARP TURBO CVT PLUS PETROL (1451cc)",
          "1.5 SHARP DCT PETROL (1451cc)",
          "1.5 SHARP HYBRID PETROL (1451cc)",
          "1.5 SMART TURBO HYBRID ( H ) (1451cc)",
          "1.5 SHARP TURBO HYBRID ( H ) (1451cc)",
          "1.5 SHARP TURBO HYBRID DUAL TONE ( H ) (1451cc)",
          "1.5 SMART DCT PETROL (1451cc)",
          "SHARP 2.0 DIESEL BS-VI (1956cc)",
          "SMART 2.0 DIESEL BS-VI (1956cc)",
          "SUPER 2.0 DIESEL BS-VI (1956cc)",
          "STYLE 2.0 DIESEL BS-VI (1956cc)",
          "SMART PRO DSL MT (1956cc)",
          "SHINE DIESEL MT (1956cc)",
          "SHARP DIESEL MT (1956cc)",
          "2.0 SMART PRO DIESEL (1956cc)",
          "PLUS 2.0 SMART PRO DIESEL MT 6 SEAT (1956cc)",
          "2.0 SHARP DIESEL MT (1956cc)",
          "2.0 SMART DIESEL MT (1956cc)",
          "2.0 SUPER DIESEL MT (1956cc)",
          "2.0 STYLE DIESEL MT (1956cc)",
          "2.0 SHINE DIESEL MT 5 SEAT (1956cc)",
          "PLUS 2.0 STYLE DIESEL MT 7 SEAT (1956cc)",
          "PLUS 2.O SUPER DIESEL MT 7 SEAT (1956cc)",
          "PLUS 2.O SMART DIESEL MT 7 SEAT (1956cc)",
          "PLUS 2.O SELECT DIESEL MT 7 SEAT (1956cc)",
          "PLUS 2.0 SHARP PRO DIESEL 6 SEAT (1956cc)",
          "2.0 SHARP PRO DIESEL (1956cc)",
          "PLUS 2.0 SHARP PRO DIESEL MT 7 SEAT (1956cc)",
          "PLUS 2.0 SUPER DIESEL MT (1956cc)",
          "PLUS 2.0 SMART DIESEL MT (1956cc)",
          "PLUS 2.0 STYLE DIESEL MT 6 SEAT (1956cc)",
          "PLUS 2.0 SHARP DIESEL MT 6 SEAT (1956cc)",
          "PLUS 2.0 SUPER DIESEL MT 6 SEAT (1956cc)",
          "PLUS 2.0 SMART DIESEL MT 6 SEAT (1956cc)",
          "PLUS 2.0 STYLE DIESEL MT (1956cc)",
          "PLUS 2.0 SHARP DIESEL MT (1956cc)",
          "SHARP 2.0 DIESEL (1956cc)",
          "SHARP 2.0 MT DIESEL (1956cc)",
          "SHINE MT (1956cc)",
          "SMART 2.0 TURBO DIESEL (1956cc)",
          "SHARP PRO 2.0 TURBO DIESEL DUAL TONE (1956cc)",
          "SMART PRO 2.0 TURBO DIESEL DUAL TONE (1956cc)",
          "SHINE 2.0 DIESEL TURBO MT (1956cc)",
          "SHARP 2.0 DIESEL DUAL TONE. (1956cc)",
          "SHARP 2.0 DIESEL DUAL TONE (1956cc)",
          "SPECIAL ANNIVERSARY EDITION 2.0 DIESEL. (1956cc)",
          "SPECIAL ANNIVERSARY EDITION 2.0 DIESEL (1956cc)",
          "SHARP PRO 2.0 TURBO DIESEL (1956cc)",
          "SMART PRO 2.0 TURBO DIESEL (1956cc)",
          "SMART 2.0 DIESEL (1956cc)",
          "STYLE 2.0 DIESEL (1956cc)",
          "SUPER 2.0 DIESEL (1956cc)",
          "2.0 SHARP (1956cc)",
          "2.0 SMART (1956cc)",
          "2.0 SUPER (1956cc)",
          "2.0 STYLE (1956cc)",
          "2.0 SHINE TURBO MT (1956cc)",
          "2.0 STYLE TURBO MT BSVI (1956cc)",
          "2.0 SHINE TURBO MT BSVI (1956cc)",
          "2.0 SMART TURBO MT BSVI (1956cc)",
          "2.0 SHARP TURBO MT BSVI (1956cc)",
          "2.0 SHARP TURBO MT DT BSVI (1956cc)",
          "2.0 SMART PRO MT BSVI (1956cc)",
          "2.0 SHARP PRO MT BSVI (1956cc)",
          "2.0 SMART DSL MT (1956cc)",
          "2.0 SHARP PLUS DIESEL (1956cc)",
          "2.0 STYLE DIESEL (1956cc)",
          "2.0 SUPER DIESEL (1956cc)",
          "2.0 SUPER PLUS DIESEL (1956cc)",
          "2.0 STYLE PLUS DIESEL (1956cc)",
          "2.0 SELECT PLUS TURBO MT DIESEL (1956cc)",
          "2.0 SMART PLUS DIESEL (1956cc)",
          "2.0 SMART DIESEL (1956cc)",
          "2.0 SHARP DIESEL (1956cc)",
          "2.0 SMART PLUS TURBO MT DIESEL (1956cc)",
          "2.0 SHINE TURBO DIESEL (1956cc)",
          "2.0 SHARP PLUS TURBO DUAL TONE MT DIESEL (1956cc)",
        ],
      },
      {
        model: "GLOSTER",
        variants: [
          "SMART 6 STR 2.0 TURBO 2WD BS6 (1996cc)",
          "SHARP 6 STR 2.0 TWIN TURBOO 4WD (1996cc)",
          "SAVVY 6 STR 2.0 TWIN TURBO 4WD (1996cc)",
          "SAVVY 7 STR 2.0 TWIN TURBO 4WD (1996cc)",
          "BLACK STORM 7STR (1996cc)",
          "2.0 TWIN TURBO 4X4 AT SAVVY 7 SEATER (1996cc)",
          "2.0 TWIN TURBO 4X2 AT SAVVY 6 SEATER (1996cc)",
          "2.0 TWIN TURBO 4X2 AT SAVVY 7 SEATER (1996cc)",
          "2.0 TWIN TURBO 4X2 AT SHARP 7 SEATER (1996cc)",
          "2.0 TURBO 4X2 AT SUPER 7 SEATER (1996cc)",
          "2.0 TURBO 4X2 AT SMART 6 SEATER (1996cc)",
          "2.0 TWIN TURBO 4X4 AT SHARP 6 SEATER (1996cc)",
          "2.0 TWIN TURBO 4X4 AT SAVVY 6 SEATER (1996cc)",
          "2.0 TWIN TURBO 4X4 AT SHARP 7 SEATER (1996cc)",
          "SUPER 2.0 TURBO 7 STR (1996cc)",
          "SMART 2.0 TURBO 6 STR (1996cc)",
          "SHARP 2.0 TWIN TURBO 7 STR 4WD (1996cc)",
          "SHARP 2.0 TWIN TURBO 6 STR 4WD (1996cc)",
          "DIESEL 2.0 TWIN TURBO 4X4 8 SEATER AT (1996cc)",
          "SAVVY 2.0 7 SEATER (1996cc)",
          "SAVVY 2.0 TWIN TURBO 7 STR 4WD (1996cc)",
          "SAVVY 2.0L AT 2WD 7 STR (1996cc)",
          "4X4 7S SAVVY 1405DDG (1996cc)",
          "4 X 4 6 SSAVVY 1414 (1996cc)",
          "BLACKSTORM 6 STR 2.0 TURBO 2WD (1996cc)",
          "BLACKSTORM 7 STR 2.0 TURBO 2WD (1996cc)",
          "BLACKSTORM 6 STR 2.0 TWIN TURBO 4WD (1996cc)",
          "BLACKSTORM 7 STR 2.0 TWIN TURBO 4WD (1996cc)",
          "SAVVY 2.0 TWIN TURBO 6 STR 4WD (1996cc)",
          "SAVVY 2.0 TURBO 7 STR (1996cc)",
          "SAVVY 2.0 TURBO 6 STR (1996cc)",
          "SHARP 2.0 TURBO 7 STR (1996cc)",
          "2.0L TWIN TURBO (1996cc)",
          "SMART 6 STR 2.0 TURBO 2WD (1996cc)",
          "SHARP 6 STR 2.0 TWIN TURBO 4WD (1996cc)",
          "SAVVY 6 STR 2.0 TWIN TURBO (1996cc)",
          "SAVVY 7 STR 2.0 TWIN TURBO (1996cc)",
          "SUPER 7 STR 2.0 TURBO 2WD (1996cc)",
          "SHARP 7 STR 2.0 TWIN TURBO 4WD (1996cc)",
          "2.0 SAVVY TWIN TURBO 4WD 6 STR (1996cc)",
          "2.0 SHARP TWIN TURBO 4WD 7 STR (1996cc)",
          "2.0 SMART TURBO 2WD 6 STR (1996cc)",
          "2.0 SUPER TURBO 2WD 7 STR (1996cc)",
          "2.0 SHARP TWIN TURBO 4WD 6 STR (1996cc)",
          "2.0 SAVVY TWIN TURBO 4WD 7 STR (1996cc)",
          "2.0 SAVVY TWIN TURBO 2WD 6 STR (1996cc)",
          "2.0 SAVVY TWIN TURBO 2WD 7 STR (1996cc)",
        ],
      },
      {
        model: "ASTOR",
        variants: [
          "SAVVY 1.5 CVT (1498cc)",
          "1.5 VTI TECH STYLE EX (1498cc)",
          "1.5 VTI TECH SUPER EX (1498cc)",
          "1.5 VTI TECH SMART EX (1498cc)",
          "1.5 VTI TECH SHARP EX (1498cc)",
          "1.5 VTI TECH SAVVY CVT (1498cc)",
          "1.5 VTI TECH SHARP (O) CVT (1498cc)",
          "1.5 VTI TECH SHARP CVT (1498cc)",
          "1.5 VTI TECH STYLE (1498cc)",
          "1.5 VTI TECH SUPER CVT (1498cc)",
          "1.5 VTI TECH SHARP (1498cc)",
          "1.5 VTI TECH SMART (1498cc)",
          "1.5 VTI TECH SUPER (1498cc)",
          "1.5 VTI TECH SMART CVT (1498cc)",
          "1.5 L MT (1498cc)",
          "1.5 L CVT (1498cc)",
          "ZS VIT TECH MT SHARP (1498cc)",
          "ZS VIT TECH CVT SAVVY RED (1498cc)",
          "ZS 220TURBO 6AT (1498cc)",
          "SHARP O CVT (1498cc)",
          "ZS VTI-TECH CVT SMART (1498cc)",
          "SUPER VTI-TECH CVT (1498cc)",
          "SMART VTI-TECH MT (1498cc)",
          "SHARP VTI-TECH MT (1498cc)",
          "ZS VTI-TECH CVT SUPPER (1498cc)",
          "STYLE EX (1498cc)",
          "1.5 CVT SMART (1498cc)",
          "ZS VTI TECH CVT SAVVY (1498cc)",
          "ZS VTI-TECH CVT (1498cc)",
          "SAVVY 1.5 CVT S RED (1498cc)",
          "SHARP 1.5 CVT SANGARIA (1498cc)",
          "SHARP 1.5 MT SANGRIA (1498cc)",
          "SHARP 1.5 MT IVORY (1498cc)",
          "SHARP 1.5 CVT IVORY (1498cc)",
          "SHARP O 1.5 CVT RED (1498cc)",
          "SHARP VTI-TECH CVT (1498cc)",
          "SAVVY ZS VTI-TECH CVT (1498cc)",
          "SAVVY CVT (1498cc)",
          "SHARP O 1.5 CVT (1498cc)",
          "STYLE 1.5 MT (1498cc)",
          "SUPER 1.5 MT (1498cc)",
          "SUPER 1.5 CVT (1498cc)",
          "SMART 1.5 MT (1498cc)",
          "SHARP 1.5 MT (1498cc)",
          "SMART 1.5 CVT (1498cc)",
          "SHARP 1.5 CVT (1498cc)",
          "SHARP O 1.5 CVT IVORY (1498cc)",
          "SHARP O 1.5 CVT SANGRIA (1498cc)",
          "STYLE EX 1.5 MT (1498cc)",
          "SUPER EX 1.5 MT (1498cc)",
          "SHARP EX 1.5 MT (1498cc)",
          "SMART EX 1.5 MT (1498cc)",
          "1.5 SMART MT (1498cc)",
          "1.5 STYLE MT (1498cc)",
          "1.5 SUPER MT (1498cc)",
          "1.5 SMART CVT (1498cc)",
          "1.5 SUPER CVT (1498cc)",
          "1.5 SAVVY CVT (1498cc)",
          "1.5 SHARP (O) CVT (1498cc)",
          "1.5 SHARP CVT (1498cc)",
          "1.5 SHARP MT (1498cc)",
          "1.5 SMART EX MT (1498cc)",
          "1.5 STYLE EX MT (1498cc)",
          "1.5 SHARP EX MT (1498cc)",
          "1.5 SAVVY CVT S RED (1498cc)",
          "1.5 SUPER EX MT (1498cc)",
          "1.5 SHARP (O) CVT SANGRIA (1498cc)",
          "1.5 SHARP (O) CVT IVORY (1498cc)",
          "SAVVY 1.3 TURBO AT (1349cc)",
          "1.3 220 TURBO SAVVY AT (1349cc)",
          "1.3 220 TURBO SHARP AT (1349cc)",
          "1.3 220 TURBO SHARP (0) AT (1349cc)",
          "1.3 L AT (1349cc)",
          "SAVVY 1.3 TURBO AT S RED (1349cc)",
          "ZS 220 TURBO 6AT SAVVY RED (1349cc)",
          "SHARP O 1.3 TURBO AT (1349cc)",
          "SHARP 1.3 TURBO AT (1349cc)",
          "SHARP 220 TURBO 6AT (1349cc)",
          "SAVVY TURBO AT (1349cc)",
          "SMART 1.3 TURBO AT (1349cc)",
          "1.3 SAVVY TURBO AT (1349cc)",
          "1.3 SHARP TURBO AT (1349cc)",
          "1.3 SMART TURBO AT (1349cc)",
          "1.3 SHARP (O) TURBO AT (1349cc)",
          "1.3 SAVVY TURBO AT S RED (1349cc)",
        ],
      },
      {
        model: "ZS EV",
        variants: [
          "EXCLUSIVE DUAL TONE ICONIC IVORY (129990cc)",
          "EXCLUSIVE PRO DUAL TONE ICONIC IVORY (129990cc)",
          "EXCLUSIVE PRO (129990cc)",
          "EXCLUSIVE PRO (130cc)",
          "EXCLUSIVE (130cc)",
          "EXCITE (130cc)",
          "EXCLUSIVE ICONIC IVORY (1451cc)",
          "EXCLUSIVE (1451cc)",
          "EXCITE (1451cc)",
          "EXCLUSIVE ICONIC IVORY (50.3cc)",
          "EXCLUSIVE (50.3cc)",
          "EXCITE (50.3cc)",
          "EXCLUSIVE (99000cc)",
          "EXCITE (99000cc)",
          "EXCLUSIVE (1600cc)",
          "EXCITE (1600cc)",
          "EXCLUSIVE. (1598cc)",
          "EXCITE. (1598cc)",
          "EXCLUSIVE (1956cc)",
          "EXCITE (1956cc)",
          "EXCLUSIVE (105cc)",
          "EXCITE (105cc)",
        ],
      },
      {
        model: "COMET EV",
        variants: [
          "BASE M-31KW  B-17KWH (1001cc)",
          "PACE (30880cc)",
          "PLAY (30880cc)",
          "PLUSH (30880cc)",
          "PACE (1080cc)",
          "PLAY (1080cc)",
          "PLUSH (1080cc)",
          "PLUSH (31cc)",
        ],
      },
      {
        model: "MIDGET",
        variants: ["MK3 (1500cc)"],
      },
      {
        model: "COMET",
        variants: ["EV (31310cc)", "EV (31cc)"],
      },
      {
        model: "VECTOR",
        variants: ["1.5 SHARP D (1451cc)"],
      },
      {
        model: "ZS ASTOR 220TURBO 6AT SAVVYRED",
        variants: ["0 (1349cc)"],
      },
      {
        model: "HECTOR PLUS",
        variants: [
          "SELECT 2.0 DIESEL (1956cc)",
          "2.0 TURBO MT 7 STR BS6 (1956cc)",
          "SMART MT 7STR BS6 (1956cc)",
          "STYLE DIESEL MT 7STR BS6 (1956cc)",
          "SMART PRO DSL MT 6STR (1956cc)",
          "SHARP PRO DSL MT 6STR (1956cc)",
          "SHARP PRO DSL MT 7STR (1956cc)",
          "STYLE 2.0 DIESEL 7 STR (1956cc)",
          "SMART 2.0 DIESEL 7 STR (1956cc)",
          "SELECT 2.0 DIESEL 7 STR (1956cc)",
          "SHARP 2.0 DIESEL TURBO MT 6-STR (1956cc)",
          "SHARP 2.0 DIESEL TURBO MT 6-STR DUAL TONE (1956cc)",
          "SMART 2.0 TURBO DIESEL 7 STR (1956cc)",
          "SHARP PRO 2.0 TURBO DIESEL 6 STR DUAL TONE (1956cc)",
          "SHARP PRO 2.0 TURBO DIESEL 7 STR DUAL TONE (1956cc)",
          "STYLE 2.0 DIESEL (1956cc)",
          "SUPER 2.0 DIESEL (1956cc)",
          "SMART 2.0 DIESEL (1956cc)",
          "SHARP 2.0 DIESEL (1956cc)",
          "SUPER 2.0 DIESEL 7 STR (1956cc)",
          "SMART PRO 2.0 TURBO DIESEL 6 STR (1956cc)",
          "SHARP PRO 2.0 TURBO DIESEL 7 STR (1956cc)",
          "SHARP PRO 2.0 TURBO DIESEL 6 STR (1956cc)",
          "2.0 STYLE MT (1956cc)",
          "2.0 SUPER MT (1956cc)",
          "2.0 SMART (1956cc)",
          "2.0 SHARP MT (1956cc)",
          "2.0 SELECT BSVI (1956cc)",
          "2.0 SUPER BSVI (1956cc)",
          "2.0 STYLE MT BSVI (1956cc)",
          "2.0 SMART MT BSVI (1956cc)",
          "2.0 SMART DIESEL MT BSVI (1956cc)",
          "2.0 SHARP MT BSVI (1956cc)",
          "2.0 SMART PRO MT BSVI (1956cc)",
          "2.0 SHARP PRO MT BSVI (1956cc)",
          "SUPER 1.5 DIESEL 7 STR (1451cc)",
          "STYLE 1.5 MT 7 STR (1451cc)",
          "SHARP 1.5 PETROL CVT 6 STR BS6 (1451cc)",
          "SAVVY 1.5 PRO CVT 6STR (1451cc)",
          "SHARP 1.5 PRO MT 7STR (1451cc)",
          "SHARP 1.5 PRO CVT 7STR (1451cc)",
          "SAVVY 1.5 PRO CVT 7STR (1451cc)",
          "SHARP PRO CVT 6STR (1451cc)",
          "SHARP PRO MT 6STR (1451cc)",
          "SHARP PRO EX MT 6STR (1451cc)",
          "SHARP PRO EX CVT 6STR (1451cc)",
          "STYLE 1.5 PETROL 7 STR (1451cc)",
          "SHARP 1.5 DCT BSVI (1451cc)",
          "SHARP 1.5 PETROL TURBO CVT 6-STR (1451cc)",
          "GASOLIN 1.5 TCIC CVT SHARP BSVI (1451cc)",
          "SHARP PRO 1323 GEH 1.5 TCIC CVT BSVI (1451cc)",
          "SHARP PRO CVT 1.5 TURBO PETROL BSVI 7 STR (1451cc)",
          "SAVVY PRO 6 STR (1451cc)",
          "SAVVY PRO CVT 7 STR (1451cc)",
          "SHARP 1.5 CVT BSVI (1451cc)",
          "SHARP 1.5 PETROL TURBO DCT 6-STR DUAL TONE (1451cc)",
          "SHARP 1.5 PETROL TURBO HYBRID MT 6-STR DUAL TONE (1451cc)",
          "SHARP PRO 1.5 PETROL TURBO CVT 7 STR (1451cc)",
          "SHARP PRO EX 1.5 PETROL TURBO CVT 6 STR (1451cc)",
          "SHARP PRO EX 1.5 TURBO MT 6 STR (1451cc)",
          "SMART 1.5 TURBO MT 7 STR (1451cc)",
          "SMART EX 1.5 TURBO MT 7 STR (1451cc)",
          "SHARP PRO 1.5 TURBO PETROL 6 STR DUAL TONE (1451cc)",
          "SHARP PRO 1.5 TURBO PETROL 7 STR DUAL TONE (1451cc)",
          "SHARP PRO 1.5 TURBO PETROL CVT 6 STR DUAL TONE (1451cc)",
          "SHARP PRO 1.5 TURBO PETROL CVT 7 STR DUAL TONE (1451cc)",
          "SAVVY PRO 1.5 TURBO PETROL CVT 6 STR DUAL TONE (1451cc)",
          "SAVVY PRO 1.5 TURBO PETROL CVT 7 STR DUAL TONE (1451cc)",
          "STYLE 1.5 PETROL (1451cc)",
          "SMART 1.5 DCT PETROL (1451cc)",
          "SHARP HYBRID 1.5 PETROL (1451cc)",
          "SHARP 1.5 DCT PETROL (1451cc)",
          "SMART 1.5 PETROL TURBO CVT 6 STR (1451cc)",
          "SMART 1.5 PETROL TURBO CVT 6-STR (1451cc)",
          "SUPER 1.5 PETROL 7 STR (1451cc)",
          "SHARP PRO 1.5 TURBO MT 7 STR (1451cc)",
          "SAVVY PRO 1.5 TURBO CVT 6 STR (1451cc)",
          "SAVVY PRO 1.5 TURBO CVT 7 STR (1451cc)",
          "SHARP PRO 1.5 PETROL TURBO CVT 6 STR (1451cc)",
          "SHARP PRO 1.5 TURBO MT 6 STR (1451cc)",
          "1.5 STYLE MT (1451cc)",
          "1.5 SMART DCT (1451cc)",
          "1.5 SHARP DCT (1451cc)",
          "1.5 SUPER HYBRID (1451cc)",
          "SHARP CVT (1451cc)",
          "1.5 SUPER HYBRID MT BSVI (1451cc)",
          "1.5 SMART CVT BSVI (1451cc)",
          "1.5 SHARP HYBRID MT BSVI (1451cc)",
          "1.5 SHARP CVT BSVI (1451cc)",
          "1.5 SHARP PRO CVT BSVI (1451cc)",
          "1.5 SHARP PRO MT BSVI (1451cc)",
          "1.5 SAVVY PRO CVT BSVI (1451cc)",
          "1.5 SHARP PRO EX MT BSVI (1451cc)",
          "1.5 SHARP PRO EX CVT BSVI (1451cc)",
        ],
      },
    ],
  },
  {
    make: "MINI METRO",
    models: [
      {
        model: "MINI METRO",
        variants: [
          "& MINI METRO BASE (1540cc)",
          "MINI METRO BASE (150cc)",
          "& VX (1cc)",
          "& MINI METRO PASSENGER (1cc)",
          "& MINI METRO GOLDEN (1cc)",
          "& MINI METRO LOADER (1cc)",
        ],
      },
      {
        model: "MINI METRO LD400",
        variants: ["0 (150cc)"],
      },
    ],
  },
  {
    make: "NISSAN",
    models: [
      {
        model: "GT-R",
        variants: [
          "SPORT (3799cc)",
          "V6 (3799cc)",
          "V6 (3799cc)",
          "550 RECARO (3799cc)",
          "ENHANCED 530 (3799cc)",
          "PREMIUM (3799cc)",
          "RECARO 550 (3799cc)",
          "3.8L TWIN TURBO V6 (3799cc)",
          "GT-R (3799cc)",
          "EGOIST (3799cc)",
          "SPORT (2349cc)",
          "V6 (2979cc)",
          "EGOIST (3797cc)",
        ],
      },
      {
        model: "EVALIA",
        variants: [
          "1.5 XL OPTION (1461cc)",
          "1.5 XV OPTION (1461cc)",
          "XL 6 STR (1461cc)",
          "XV (O) 6 STR (1461cc)",
          "XV 8 STR (1461cc)",
          "XE 8 STR (1461cc)",
          "XL 8 STR (1461cc)",
          "XV (O) 8 STR (1461cc)",
          "1.5 XV S DIESEL (1461cc)",
          "1.5 SV DIESEL (1461cc)",
          "1.5 XL OPTION DIESEL (1461cc)",
          "1.5 XV OPTION DIESEL (1461cc)",
          "XV S (1461cc)",
          "SV (1461cc)",
          "1.5 SV (1461cc)",
          "1.5 XV S (1461cc)",
          "1.5 XE DIESEL (1461cc)",
          "1.5 XL DIESEL (1461cc)",
          "1.5 XV DIESEL (1461cc)",
          "1.5 XE PLUS (1461cc)",
          "1.5 XE (1461cc)",
          "1.5 XL (1461cc)",
          "1.5 XV (1461cc)",
          "XV PACK 2.5 (1461cc)",
          "XE PACK 4 (1461cc)",
          "XV PACK 2.5 (O) (1461cc)",
          "XE PACK 2.5 (1461cc)",
          "XL PACK 2.5 (O) (1461cc)",
          "XE PACK 4 (O) (1461cc)",
          "XE PLUS PACK 2.5 (1461cc)",
          "XL 6SEATER (1461cc)",
          "XL PACK 4 (1461cc)",
          "XL PACK 2.5 (1461cc)",
          "XVD PACK 4 (O) (1461cc)",
          "XV PACK 4 (1461cc)",
          "XE PLUS PACK 4 (1461cc)",
          "XV O (1461cc)",
          "XV O (1461cc)",
          "XL (O) (1461cc)",
          "XL O (1461cc)",
          "XE+ (1461cc)",
          "XE (1461cc)",
          "XL (1461cc)",
          "XL (1461cc)",
          "XV (1461cc)",
          "XV (1461cc)",
          "SV (1431cc)",
        ],
      },
      {
        model: "X -TRAIL",
        variants: [
          "SLX (1995cc)",
          "2.0 T 31 (1995cc)",
          "ATM1BS3 IMPORTED (1995cc)",
          "T 31 (1995cc)",
          "SLX MANUAL TRANSMISSION (1995cc)",
          "SLX AUTOMATIC TRANSMISSION (1995cc)",
          "LE (1995cc)",
          "LE (1995cc)",
          "SLX AT (1995cc)",
          "SLX MT (1995cc)",
          "2.2 DI (2184cc)",
          "T30 (2184cc)",
          "T 30 (2184cc)",
          "NEW  2.2 DI (2184cc)",
          "ELEGANCE (2184cc)",
          "ELEGANCE (2184cc)",
          "X PECIAL (2184cc)",
          "X PECIAL (2184cc)",
          "COMFORT (2184cc)",
          "COMFORT (2184cc)",
        ],
      },
      {
        model: "INFINITI",
        variants: [
          "FX 30D (2993cc)",
          "Q45 (4494cc)",
          "Q-45 (4494cc)",
          "Q 45 (3700cc)",
          "Q45 (3500cc)",
        ],
      },
      {
        model: "BLUEBIRD",
        variants: [
          "G10 1.8 (1598cc)",
          "G10 1.8 (1800cc)",
          "BLUE BIRD (2184cc)",
        ],
      },
      {
        model: "TEANA",
        variants: [
          "XL (2496cc)",
          "XV (2496cc)",
          "TEANA (2496cc)",
          "MID (2496cc)",
          "UPPER (2496cc)",
          "STD (2496cc)",
          "250 XV (2496cc)",
          "250 XL (2496cc)",
          "BASE (2349cc)",
          "JM 230 (2349cc)",
          "230JM (2349cc)",
          "230JM (2349cc)",
          "STD (1461cc)",
        ],
      },
      {
        model: "350Z",
        variants: [
          "COUPE (3498cc)",
          "COUPE (3498cc)",
          "COUPE (2349cc)",
          "BASE COUPE (3500cc)",
        ],
      },
      {
        model: "MICRA",
        variants: [
          "XL OPTIONAL (1198cc)",
          "CHANGE XL (1198cc)",
          "X-SHIFT LIMITED EDITION CVT (1198cc)",
          "XL FASHION EDITION CVT (1198cc)",
          "XL COMFORT PETROL (1198cc)",
          "1.2 X SHIFT (1198cc)",
          "FASHION EDITION (1198cc)",
          "XL PRIMO (1198cc)",
          "XV PRIMO (1198cc)",
          "XE PETROL (1198cc)",
          "XE PLUS PETROL (1198cc)",
          "XL PETROL (1198cc)",
          "XV PETROL (1198cc)",
          "XL O (1198cc)",
          "PRIMO XV (1198cc)",
          "PRIMO XL (1198cc)",
          "PRIMO XL (1198cc)",
          "PRIMO XV PETROL (1198cc)",
          "PRIMO XV PETROL (1198cc)",
          "1.2 CHANGE XL P (1198cc)",
          "PRIMO XL PETROL (1198cc)",
          "MC XL PETROL (O) (1198cc)",
          "MC XL PETROL CVT (1198cc)",
          "MC XL PETROL (1198cc)",
          "MC XV PETROL CVT (1198cc)",
          "XV CVT (1198cc)",
          "XV CVT (1198cc)",
          "XL CVT (1198cc)",
          "XL CVT (1198cc)",
          "X SHIFT (1198cc)",
          "XL (1198cc)",
          "XL (1198cc)",
          "XE PLUS (1198cc)",
          "XE PLUS (1198cc)",
          "XV (1198cc)",
          "XV (1198cc)",
          "XE (1198cc)",
          "XE (1198cc)",
          "XL O CVT (1198cc)",
          "XL OPTIONAL DIESEL (1461cc)",
          "DIESEL XV PREMIUM (1461cc)",
          "XL OPTIONAL (1461cc)",
          "1.5 XE (1461cc)",
          "XL COMFORTLINE (1461cc)",
          "DCI K13 (1461cc)",
          "PRIMO XV DIESEL (1461cc)",
          "XL COMFORT DIESEL (1461cc)",
          "XV P DIESEL (1461cc)",
          "XV PREMIUM PRIMO (1461cc)",
          "XV PRIMO 5 STR (1461cc)",
          "PRIMO XV (1461cc)",
          "PRIMO XV (1461cc)",
          "1.5 XV PREMIUM (1461cc)",
          "PRIMO XV PREMIUM (1461cc)",
          "1.5 XV (1461cc)",
          "1.5 XV DIESEL (1461cc)",
          "1.5 XV PREMIUM DIESEL (1461cc)",
          "PRIMO XV PREMIUM DIESEL (1461cc)",
          "XE DIESEL (1461cc)",
          "XE DIESEL (1461cc)",
          "XV DIESEL (1461cc)",
          "XV DIESEL (1461cc)",
          "XL DIESEL (1461cc)",
          "XL DIESEL (1461cc)",
          "MC XE DCI DIESEL (1461cc)",
          "MC XL DCI DIESEL (1461cc)",
          "MC XL DCI DIESEL (O) (1461cc)",
          "MC XED DIESEL (1461cc)",
          "MC XV DCI DIESEL PREMIUM (1461cc)",
          "MC XV DCI DIESEL (1461cc)",
          "DCI XV (1461cc)",
          "DCI XV PREMIUM (1461cc)",
          "XV PREMIUM DIESEL (1461cc)",
          "XL O DIESEL (1461cc)",
          "XV (1461cc)",
          "XE (1461cc)",
          "XV DCI BSIV (1299cc)",
        ],
      },
      {
        model: "TERRANO",
        variants: [
          "XV (1461cc)",
          "XV (1461cc)",
          "XE (1461cc)",
          "XE (1461cc)",
          "XL PLUS (1461cc)",
          "XL PLUS (1461cc)",
          "XL DIESEL (1461cc)",
          "XV PREMIUM (1461cc)",
          "XLD OPTION 85PS (1461cc)",
          "1.5 XV PREMIUM 110 PS (1461cc)",
          "1.5 XV PREMIUM 110 (1461cc)",
          "XV 110 PS LIMITED EDITION (1461cc)",
          "XV PREMIUM 110 PS ANNIVERSARY EDITION (1461cc)",
          "GROOVE EDITION (1461cc)",
          "XV D PRE AMT (1461cc)",
          "XV D THP PREMIUM 110 PS AMT (1461cc)",
          "XE D 85 PS (1461cc)",
          "XL D 85 PS (1461cc)",
          "XL D 85 PS PLUS (1461cc)",
          "GROOVE LE (1461cc)",
          "ICC WT20 EDITION (1461cc)",
          "XL O (D) (1461cc)",
          "XL 1.5 85 PS (D) (1461cc)",
          "XV PREMIUM D (1461cc)",
          "XV D THP PREMIUM 110 PS (1461cc)",
          "XL D THP 110 PS (1461cc)",
          "XV D THP 110 PS (1461cc)",
          "XL (1461cc)",
          "PH1 XED (1461cc)",
          "PH1 XLD (OPTION) (1461cc)",
          "PH1 XLD (OPTION) SV (1461cc)",
          "PH1 XVD (THP) (PRE) (1461cc)",
          "PRIME XED (1461cc)",
          "PRIME XLD (1461cc)",
          "PRIME XLD (OPTION) (1461cc)",
          "PRIME XLD (OPTION) SV (1461cc)",
          "PRIME XVD (THP) (1461cc)",
          "XLD (OPTION) SV (1461cc)",
          "XVD (THP) (PRE) SV (1461cc)",
          "XVD PRE 110PS (1461cc)",
          "XVD PRE 110PS ADVANCED (1461cc)",
          "XVD THP PRE (1461cc)",
          "XL DCI (1461cc)",
          "XL D OPTION (1461cc)",
          "XL D (O) (1461cc)",
          "SPORT EDITION (1461cc)",
          "XVD PREMIUM AMT (1461cc)",
          "XV D THP PREMIUM (1461cc)",
          "XL D THP (1461cc)",
          "XV D THP (1461cc)",
          "XL D (1461cc)",
          "XL P (1461cc)",
          "XE D (1461cc)",
          "XL D PLUS (1461cc)",
          "SE WAGON (2664cc)",
          "XL P 104 PS (1598cc)",
          "XV PREMIUM AUTO DRIVE (1598cc)",
          "XL (1598cc)",
          "XL (1598cc)",
          "PRIME XL (1598cc)",
          "XL P (1598cc)",
        ],
      },
      {
        model: "SUNNY",
        variants: [
          "XV PETROL (1498cc)",
          "XL PETROL (1498cc)",
          "1.5 XV PREMIUM (1498cc)",
          "1.5 XV PREMIUM (1498cc)",
          "XL AT SPECIAL EDITION (1498cc)",
          "AT (1498cc)",
          "XTRONIC XV CVT (1498cc)",
          "1.5 XV CVT PETROL (1498cc)",
          "1.5 XV PETROL SPECIAL EDITION (1498cc)",
          "1.5 XL CVT PETROL (1498cc)",
          "SPECIAL EDITION XV PETROL (1498cc)",
          "SPECIAL EDITION (1498cc)",
          "SPECIAL EDITION (P) (1498cc)",
          "XL CVT (1498cc)",
          "XL CVT (1498cc)",
          "1.5 XV (1498cc)",
          "1.5 XL CVT (1498cc)",
          "1.5 XV CVT (1498cc)",
          "XV SPL EDN (1498cc)",
          "1.5 XE (1498cc)",
          "1.5 XL (1498cc)",
          "XL CVT SPL EDN (1498cc)",
          "1.5 XE PETROL (1498cc)",
          "1.5 XL PETROL (1498cc)",
          "1.5 XV PETROL (1498cc)",
          "XV CVT (1498cc)",
          "XV CVT (1498cc)",
          "MC XP (L) (1498cc)",
          "MC XL CVT PETROL (1498cc)",
          "MC XE PETROL (1498cc)",
          "XV PETROL SPL EDN (1498cc)",
          "MC XV CVT (1498cc)",
          "XL CVT (O) PETROL (1498cc)",
          "XV CVT PETROL (1498cc)",
          "MC XL PETROL (1498cc)",
          "XL CVT AT (1498cc)",
          "XV (1498cc)",
          "XL (1498cc)",
          "XE (1498cc)",
          "XE (1498cc)",
          "1.5 XV PREMIUM (2000cc)",
          "1.7 (1700cc)",
          "1.7 DIESEL (1700cc)",
          "XV PREMIUM 1 (1461cc)",
          "1.5 XE DIESEL (1461cc)",
          "1.5 XV DIESEL SPECIAL EDITION (1461cc)",
          "1.5 XV DIESEL LEATHER (1461cc)",
          "1.5 XV DIESEL SAFETY (1461cc)",
          "SPECIAL EDITION (1461cc)",
          "SPECIAL EDITION XV DIESEL (1461cc)",
          "XV PREMIUM PACK (LEATHER) (1461cc)",
          "XV PREMIUM PACK (SAFETY) (1461cc)",
          "1.5 XV (1461cc)",
          "SPECIAL EDITION PETROL (1461cc)",
          "1.5 XE (1461cc)",
          "1.5 XL (1461cc)",
          "NEW XED (1461cc)",
          "NEW XLD (1461cc)",
          "NEW XVD (L) (1461cc)",
          "NEW XVD (S) (1461cc)",
          "XVD (L) (1461cc)",
          "XVD (S) (1461cc)",
          "XVD LOUNGE (1461cc)",
          "XLD DIESEL (1461cc)",
          "SPECIAL EDITION DIESEL (1461cc)",
          "XV DIESEL PREMIUM LEATHER (1461cc)",
          "XV DIESEL PREMIUM SAFETY (1461cc)",
          "1.5 XL DIESEL (1461cc)",
          "1.5 XV DIESEL (1461cc)",
          "XE D (1461cc)",
          "XL D (1461cc)",
          "XV D (1461cc)",
          "XE DIESEL (1461cc)",
          "XE DIESEL (1461cc)",
          "XV PREMIUM SAFETY (1461cc)",
          "XV PREMIUM (SAFETY) (1461cc)",
          "XV PREMIUM LEATHER (1461cc)",
          "XV PREMIUM (LEATHER) (1461cc)",
          "MC XV PREMIUM(LEATHER) (1461cc)",
          "MC XV DIESEL (1461cc)",
          "MC XV PREMIUM(SAFETY) (1461cc)",
          "XV DIESEL (1461cc)",
          "MC XL DIESEL (1461cc)",
          "MC XE DIESEL (1461cc)",
          "XV DIESEL SPL EDN (1461cc)",
          "XL DIESEL (1461cc)",
          "XV (1461cc)",
          "XL (1461cc)",
          "STD 1.6 (1600cc)",
          "1.6 PETROL (1600cc)",
          "1.5 XV DIESEL PREMIUM (1491cc)",
          "XV DCI (1598cc)",
        ],
      },
      {
        model: "PATROL",
        variants: [
          "3.0 DI (2953cc)",
          "0 (5552cc)",
          "0 (5552cc)",
          "5.6 L (5600cc)",
          "5.6 L V8 (5600cc)",
          "RHD (5600cc)",
          "Y61 WAGON (2000cc)",
          "4X4 (4163cc)",
        ],
      },
      {
        model: "FAIR LADY",
        variants: [
          "300ZX 3 (2349cc)",
          "0 (3000cc)",
          "SPORTS (2000cc)",
          "Z (3498cc)",
          "Z (3500cc)",
        ],
      },
      {
        model: "CARAVAN",
        variants: ["SLWB DX (2953cc)", "2WD (2480cc)"],
      },
      {
        model: "MICRA ACTIVE",
        variants: [
          "1.2 XE (1198cc)",
          "1.2 XL (1198cc)",
          "1.2 XV (1198cc)",
          "1.2 XV S (1198cc)",
          "1.2 XL (O) (1198cc)",
          "1.2 XE PETROL (1198cc)",
          "1.2 XL PETROL (1198cc)",
          "1.2 XV PETROL (1198cc)",
          "1.2 XV S PETROL (1198cc)",
          "ICC WT20 EDITION (1198cc)",
          "XV SAFETY PACK (1198cc)",
          "XL PH1 (1198cc)",
          "XV PH1 (1198cc)",
          "XV S PH1 (1198cc)",
          "XL O (1198cc)",
          "XV S (1198cc)",
          "XV S (1198cc)",
          "XV (1198cc)",
          "XV (1198cc)",
          "XL (1198cc)",
          "XL (1198cc)",
          "XE (1198cc)",
          "XE (1198cc)",
        ],
      },
      {
        model: "MAGNITE",
        variants: [
          "XE BS6 (999cc)",
          "XL BS6 (999cc)",
          "XV BS6 (999cc)",
          "XV PREMIUM BS6 (999cc)",
          "XL TURBO BS6 (999cc)",
          "XV TURBO BS6 (999cc)",
          "XV PREMIUM TURBO BS6 (999cc)",
          "XV PREMIUM TURBO (O) BS6 (999cc)",
          "XL TURBO CVT BS6 (999cc)",
          "XV TURBO CVT BS6 (999cc)",
          "XV PREMIUM TURBO CVT BS6 (999cc)",
          "XV PREMIUM (O) TURBO CVT (999cc)",
          "XV PREMIUM TURBO DUAL TONE BS6 (999cc)",
          "XV PREMIUM DUAL TONE BS6 (999cc)",
          "XV DUAL TONE BS6 (999cc)",
          "XV TURBO DUAL TONE BS6 (999cc)",
          "XV MT RED EDITION (999cc)",
          "XV TURBO CVT RED EDITION BS6 (999cc)",
          "XV TURBO MT RED EDITION BS6 (999cc)",
          "1.0 HRAO TURBO XV EXECUTIVE PETROL CVT (999cc)",
          "1.0 B4D XE PETROL MT (999cc)",
          "1.0 B4D XL PETROL MT (999cc)",
          "1.0 B4D XV PETROL MT (999cc)",
          "1.0 B4D XV PREMIUM PETROL MT (999cc)",
          "1.0 HRAO TURBO XL  PETROL MT (999cc)",
          "1.0 HRAO TURBO XV PREMIUM PETROL MT (999cc)",
          "1.0 HRAO TURBO XL PETROL CVT (999cc)",
          "1.0 HRAO TURBO XV PETROL CVT (999cc)",
          "1.0 HRAO TURBO XV PREMIUM PETROL CVT (999cc)",
          "1.0 HRAO TURBO XV  PETROL MT (999cc)",
          "1.0 B4D XV EXECUTIVE PETROL MT (999cc)",
          "1.0 HRAO TURBO XV PREMIUM (O) PETROL MT (999cc)",
          "1.0 HRAO TURBO XV PREMIUM (O) PETROL CVT (999cc)",
          "1.0 HRAO TURBO XV DUAL TONE CVT (O) (999cc)",
          "1.0 B4D XV DUAL TONE MT (999cc)",
          "1.0 B4D XV PREMIUM DUAL TONE (999cc)",
          "1.0 HRAO TURBO XV DUAL TONE CVT (999cc)",
          "1.0 HRAO TURBO XV PREMIUM DUAL TONE (999cc)",
          "1.0 HRAO TURBO XV PREMIUM DUAL TONE (O) (999cc)",
          "1.0 HRAO B4D XV DUAL TONE CVT (999cc)",
          "1.0 HRAO TURBO XV DUAL TONE (999cc)",
          "XV TURBO EXECUTIVE (999cc)",
          "XE MT (999cc)",
          "XL MT (999cc)",
          "XV DUAL TONE MT (999cc)",
          "XV PREMIUM MT (999cc)",
          "XV PREMIUM TURBO DUAL TONE 1.0 MT (999cc)",
          "XL TURBO 1.0 MT (999cc)",
          "XV TURBO 1.0 MT (999cc)",
          "XV TURBO DUAL TONE 1.0 MT (999cc)",
          "XV PREMIUM TURBO 1.0 MT (999cc)",
          "XV PREMIUM DUAL TONE MT (999cc)",
          "XV PREMIUM TURBO O 1.0 MT (999cc)",
          "XV PREMIUM TURBO O DUAL TONE 1.0 MT (999cc)",
          "XL TURBO 1.0 CVT (999cc)",
          "XV TURBO 1.0 CVT (999cc)",
          "XV PREMIUM TURBO 1.0 CVT (999cc)",
          "XV PREMIUM TURBO DUAL TONE 1.0 CVT (999cc)",
          "XV PREMIUM TURBO O 1.0 CVT (999cc)",
          "TURBO MT XV EXEUCTIVE (999cc)",
          "MT XV EXEUCTIVE (999cc)",
          "XV PREMIUM TURBO O 1.0 CVT NEW (999cc)",
          "XV PREMIUM TURBO O 1.0 MT NEW (999cc)",
          "XV TURBO CVT EXECUTIVE (999cc)",
          "GEZA EDITION (999cc)",
          "XV PREMIUM TURBO O DUAL TONE 1.0 MT NEW (999cc)",
          "XV PREMIUM TURBO O DUAL TONE 1.0 CVT (999cc)",
          "XV TURBO DUAL TONE 1.0 CVT (999cc)",
          "XE (999cc)",
          "XL (999cc)",
          "XV (999cc)",
          "XV PREMIUM (999cc)",
          "XV PREMIUM TURBO DT (999cc)",
          "XV PREMIUM TURBO CVT (999cc)",
          "XV PREMIUM TURBO O DT (999cc)",
          "XV TURBO DT (999cc)",
          "XV TURBO CVT DT (999cc)",
          "XV CVT (999cc)",
          "XV PREMIUM CVT (999cc)",
          "XL CVT (999cc)",
          "XV TURBO MT (999cc)",
          "XV PREMIUM TURBO MT (999cc)",
          "XV PREMIUM TURBO O MT (999cc)",
          "XL TURBO MT (999cc)",
          "XV TURBO EXE CVT (999cc)",
          "XV TURBO CVT (999cc)",
          "XV EXECUTIVE MT (999cc)",
          "XV EXECUTIVE TURBO MT (999cc)",
          "GEZA EDITION MT (999cc)",
          "1.0 XV DUAL TONE MT (999cc)",
          "1.0 XV PREMIUM DUAL TONE MT (999cc)",
          "1.0 XV PREMIUM TURBO O MT (999cc)",
          "1.0 XV TURBO DUAL TONE CVT (999cc)",
          "1.0 XV TURBO DUAL TONE MT (999cc)",
          "XV MT (999cc)",
          "XV RED EDITION (999cc)",
          "XV TURBO RED EDITION (999cc)",
          "XV TURBO CVT RED EDITION (999cc)",
          "XV EXECUTIVE (999cc)",
          "1.0 XV PREMIUM MT DUAL TONE (999cc)",
          "1.0 XV TURBO MT DUAL TONE (999cc)",
          "1.0 XV PREMIUM TURBO (O) MT DUAL TONE (999cc)",
          "1.0 XV PREMIUM TURBO (O) CVT DUAL TONE (999cc)",
          "1.0 XV MT DUAL TONE (999cc)",
          "1.0 XV PREMIUM TURBO MT DUAL TONE (999cc)",
          "1.0 XV TURBO CVT DUAL TONE (999cc)",
          "1.0 XV PREMIUM TURBO CVT DUAL TONE (999cc)",
          "1.0 XV PREMIUM TURBO (O) (999cc)",
          "1.0 XV MT EXECUTIVE (999cc)",
          "1.0 SV TURBO CVT RED EDITION (999cc)",
          "1.0 SV TURBO RED EDITION (999cc)",
          "1.0 SV RED EDITION (999cc)",
          "1.0 XV RED EDITION (999cc)",
          "1.0 XV TURBO CVT RED EDITION (999cc)",
          "1.0 XV TURBO RED EDITION (999cc)",
          "1.0 XV PREMIUM TURBO MT (999cc)",
          "1.0 XV TURBO CVT (999cc)",
          "1.0 XV PREMIUM TURBO CVT (999cc)",
          "1.0 XE MT (999cc)",
          "1.0 XL TURBO MT (999cc)",
          "1.0 XL MT (999cc)",
          "1.0 XV TURBO MT (999cc)",
          "1.0 XV MT (999cc)",
          "1.0 XV PREMIUM MT (999cc)",
          "1.0 XL TURBO CVT (999cc)",
          "1.0 XV PREMIUM TURBO (O) CVT (999cc)",
        ],
      },
      {
        model: "KICKS",
        variants: [
          "XV TURBO 1.3 CVT BS6 (1330cc)",
          "XV PREMIUM DUAL TONE (1330cc)",
          "1.3 TURBO XV PRE OPTION (1330cc)",
          "1.3 TURBO XV CVT (1330cc)",
          "1.3 TURBO XV PRE (1330cc)",
          "1.3 TURBO XV (1330cc)",
          "1.3 TURBO XV PRE OPTION DT (1330cc)",
          "XV TURBO 1.3 CVT (1330cc)",
          "XV TURBO 1.3 (1330cc)",
          "XV PRE O TURBO 1.3 DUAL TONE (1330cc)",
          "XV PRE TURBO 1.3 (1330cc)",
          "XV PRE O TURBO 1.3 (1330cc)",
          "XV PRE TURBO 1.3 CVT (1330cc)",
          "1.3 TURBO XV PRE BSVI (1330cc)",
          "1.3 TURBO XV CVT BSVI (1330cc)",
          "1.3 TURBO XV PRE CVT (1330cc)",
          "1.3 XV TURBO PRE (1330cc)",
          "1.3 XV TURBO PRE (O) DUAL TONE (1330cc)",
          "1.3 XV TURBO (1330cc)",
          "1.3 XV TURBO PRE (O) (1330cc)",
          "1.3 XV TURBO CVT (1330cc)",
          "1.3 XV PRE TURBO CVT (1330cc)",
          "1.5 XV PREMIUM DIESEL (1461cc)",
          "1.5 XV PREMIUM OPTION DIESEL (1461cc)",
          "1.5 XE DIESEL (1461cc)",
          "1.5 XV PRE DIESEL (1461cc)",
          "1.5 XV PRE OPTION DIESEL (1461cc)",
          "1.5 XL DIESEL (1461cc)",
          "1.5 XV DIESEL (1461cc)",
          "XV PRE 1.5 D (1461cc)",
          "XV PRE (O) 1.5 D (1461cc)",
          "XE 1.5 D ABS (1461cc)",
          "XV 1.5 D ABS (1461cc)",
          "XL 1.5 D ABS (1461cc)",
          "XV PRE 1.5 D ABS (1461cc)",
          "1.5 XL D (1461cc)",
          "1.5 XV D (1461cc)",
          "1.5 XV PREMIUM D (1461cc)",
          "1.5 XV PREMIUM OPTION D (1461cc)",
          "1.5 XE D (1461cc)",
          "XV PREMIUM 1.5 D (1461cc)",
          "XV PREMIUM ( O ) 1.5 D (1461cc)",
          "XE 1.5 D (1461cc)",
          "XL 1.5 D (1461cc)",
          "XV 1.5 D (1461cc)",
          "1.5 XL PETROL (1498cc)",
          "1.5 XV PETROL (1498cc)",
          "1.5 XL (1498cc)",
          "1.5 XV (1498cc)",
          "XV PETROL (1498cc)",
          "XL 1.5 (1498cc)",
          "XV 1.5 (1498cc)",
          "1.3 TURBO XV BSVI (1298cc)",
          "1.3 TURBO XV PRE BSVI (1298cc)",
          "1.3 TURBO XV PRE (O) BSVI (1298cc)",
          "1.3 TURBO XV PRE (O) DT BSVI (1298cc)",
        ],
      },
      {
        model: "370Z",
        variants: [
          "3.7 Touring Coupe AT (3696cc)",
          "MT (3696cc)",
          "AT (3696cc)",
          "TOURING COUPE MT (3696cc)",
          "TOURING COUPE AT (3696cc)",
        ],
      },
      {
        model: "JONGA",
        variants: ["4X2 (2112cc)", "4X2 (3596cc)"],
      },
      {
        model: "SKYLINE",
        variants: ["COOPE (2560cc)", "GTR-2 (3799cc)"],
      },
      {
        model: "URVAN",
        variants: [
          "15 SEATER (2700cc)",
          "BASE (2993cc)",
          "3.0  DIESEL 12 SEATER (2953cc)",
          "2.4 (10 SEATER) (2400cc)",
          "10 SEATER (2400cc)",
        ],
      },
      {
        model: "MICRA NEW",
        variants: [
          "1.5 XL OPTION (1461cc)",
          "1.5 XV (1461cc)",
          "1.5 XL (1461cc)",
          "1.2 XL (1198cc)",
          "1.2 XV (1198cc)",
          "1.2 XL OPTION (1198cc)",
        ],
      },
      {
        model: "CEFIRO",
        variants: ["30 GV (3498cc)", "GV (2988cc)", "30 GV (3000cc)"],
      },
      {
        model: "CEDRIC",
        variants: ["STD (2349cc)", "RD 28 (2000cc)"],
      },
      {
        model: "SENTRA",
        variants: ["STD (2349cc)", "0 (2000cc)"],
      },
      {
        model: "PRIMASTAR",
        variants: ["VAN 12 STR (1995cc)", "2.5D BSIV (2400cc)"],
      },
      {
        model: "DATSUN",
        variants: [
          "GO PLUS I BS IV (1198cc)",
          "GO PLUS T (O) (1198cc)",
          "GO 1.2 PETROL T (1198cc)",
        ],
      },
      {
        model: "TIIDA",
        variants: ["1.5 (1500cc)"],
      },
      {
        model: "SERENA",
        variants: ["2.0L DIESEL (1968cc)"],
      },
      {
        model: "LAUREL",
        variants: ["STD (1990cc)"],
      },
      {
        model: "CIMA",
        variants: ["V8 LIMITED EDITION (3696cc)"],
      },
      {
        model: "QX",
        variants: ["IX (2998cc)"],
      },
      {
        model: "RECARO",
        variants: ["GTR 3.8 (3759cc)"],
      },
      {
        model: "NIKHIL FURNITURE",
        variants: ["0 (1998cc)"],
      },
      {
        model: "VINTAGE",
        variants: [
          "S 13 SPORTZ (2200cc)",
          "S 13 SPORTZ (1815cc)",
          "S 15 SPORTZ (4000cc)",
          "PRESIDENT (4414cc)",
          "PRESIDENT (3988cc)",
        ],
      },
      {
        model: "REDI-GO",
        variants: [
          "T 1.0 AMT (999cc)",
          "T O 1.0 AMT (999cc)",
          "T OPTION (799cc)",
        ],
      },
      {
        model: "ELGRAND",
        variants: ["0 (2000cc)"],
      },
      {
        model: "T",
        variants: ["0 (1198cc)"],
      },
      {
        model: "MICRA NEW MC",
        variants: [
          "1.2 XL (O) CVT (1198cc)",
          "1.2 XL CVT FASHION (1198cc)",
          "1.2 XL (O) (1198cc)",
          "1.2 XL CVT (1198cc)",
          "1.2 XL (1198cc)",
          "1.2 XV CVT (1198cc)",
          "XV CVT (1198cc)",
          "MICRA XL CVT (1198cc)",
          "MICRA XV CVT (1198cc)",
          "1.2 X SHIFT (1198cc)",
          "1.2 XL COMFORT (1198cc)",
          "MCIRA XLD O PH1 (1198cc)",
          "MICRA ACT CLR EDN WHT WITH RED (1198cc)",
          "MICRA ACTIVE COLOUR EDN BLUE (1198cc)",
          "MICRA ACTIVE COLOUR EDN WHITE (1198cc)",
          "MICRA ANV. EDN XL PETROL (1198cc)",
          "MICRA ANV. EDN XV PETROL (1198cc)",
          "XL (1198cc)",
          "XL CVT FASHION (1198cc)",
          "XL O (1198cc)",
          "XL O TRIM (1198cc)",
          "XL TRIM (1198cc)",
          "XV CVT ORANGE THEME (1198cc)",
          "MICRA XE PEROL (1198cc)",
          "MICRA XE PETROL (1198cc)",
          "MICRA XE PETROL (R) (1198cc)",
          "MICRA XE PLUS PETROL (1198cc)",
          "MICRA XE PLUS PETROL (R) (1198cc)",
          "MICRA XL O (1198cc)",
          "MICRA XL PETROL (1198cc)",
          "MICRA XL PETROL (R) (1198cc)",
          "MICRA XV CVT PH1 (1198cc)",
          "MICRA XV PETROL (1198cc)",
          "MICRA XV PETROL (R) (1198cc)",
          "1.2 X SHIFT PETROL (1198cc)",
          "1.2 XL CVT PETROL (1198cc)",
          "1.2 XL PETROL (1198cc)",
          "1.2 XL (O) PETROL (1198cc)",
          "1.2 XV CVT PETROL (1198cc)",
          "1.2 XL COMFORT PETROL (1198cc)",
          "1.5 XE (1461cc)",
          "1.5 XL COMFORT (1461cc)",
          "1.5 XL (1461cc)",
          "1.5 XL (O) (1461cc)",
          "1.5 XV (1461cc)",
          "1.5 XV PREMIUM (1461cc)",
          "1.5 XVP (1461cc)",
          "MCRA XVD (1461cc)",
          "MICRA ANV EDN XVPREMIUM DIESEL (1461cc)",
          "MICRA ANV. EDN XV DIESEL (1461cc)",
          "MICRA DIESEL XV (R) (1461cc)",
          "MICRA DIESEL XV PREMIUM (1461cc)",
          "MICRA DIESEL XV PREMIUM (R) (1461cc)",
          "XED (1461cc)",
          "XLD (1461cc)",
          "XLD O (1461cc)",
          "XLD O TRIM (1461cc)",
          "XLD TRIM (1461cc)",
          "XVD (1461cc)",
          "XVD PRE (1461cc)",
          "MICRA XLD (1461cc)",
          "MICRA XLD O (1461cc)",
          "MICRA XLD O ORANGE THEME (1461cc)",
          "MICRA XVD (1461cc)",
          "MICRA XVD PRE (1461cc)",
          "1.5 XV PREMIUM DIESEL (1461cc)",
          "1.5 XE DIESEL (1461cc)",
          "1.5 XL DIESEL (1461cc)",
          "1.5 XL(O) DIESEL (1461cc)",
          "1.5 XV DIESEL (1461cc)",
          "1.5 XV(P) DIESEL (1461cc)",
          "1.5 XL COMFORT DIESEL (1461cc)",
        ],
      },
      {
        model: "MARCH K12",
        variants: ["1.3 (1300cc)"],
      },
      {
        model: "MURANO",
        variants: ["3.5 SL AWD (3500cc)"],
      },
      {
        model: "GLORIA",
        variants: ["V6 SEDAN (2349cc)"],
      },
      {
        model: "PRIMERA",
        variants: [
          "1.8 16V ESTATE AT (1769cc)",
          "1.8 16V ESTATE (1769cc)",
          "1.8 16V AT (1769cc)",
          "1.8 16V (1769cc)",
          "1.8 ESTATE (1769cc)",
          "1.8 4/5 DOOR AT (1769cc)",
          "1.8 ESTATE AT (1769cc)",
          "1.8 4/5 DOOR (1769cc)",
          "2.0 ESTATE AT (1998cc)",
          "2.0 16V ESTATE AT (1998cc)",
          "2.0 16V (1998cc)",
          "2.0 4/5 DOOR (1998cc)",
          "2.0 4/5 DOOR AT (1998cc)",
          "2.0 16V AT (1998cc)",
          "2.0 ESTATE (1998cc)",
          "2.0 16V ESTATE (1998cc)",
          "2.2TD 4/5 DOOR ESTATE (2184cc)",
          "2.2TD ESTATE (2000cc)",
          "STD (2349cc)",
        ],
      },
      {
        model: "LEAF",
        variants: ["TEKNA (1498cc)"],
      },
      {
        model: "SAFARI",
        variants: ["0 (4500cc)"],
      },
    ],
  },
  {
    make: "PIAGGIO",
    models: [
      {
        model: "APE",
        variants: [
          "EXTRA 501 PASSENGER (395cc)",
          "XTRA (395cc)",
          "CITY STR 4 (395cc)",
          "CITY COMPACT DIESEL (395cc)",
          "XTRA DLX LPG (395cc)",
          "CITY BSIV (395cc)",
          "AUTO PLUS (395cc)",
          "AUTO BSIV DX CNG (395cc)",
          "1.3 TON STR 7 (395cc)",
          "HC STR 4 (395cc)",
          "CNG STR 4 (395cc)",
          "CITY 200 CNG (395cc)",
          "CITY SMART 1920/CNG (395cc)",
          "CITY SMART CNG (395cc)",
          "CITY PLUS (395cc)",
          "AUTO DX CNG (395cc)",
          "STR 5 (395cc)",
          "APE DX (395cc)",
          "APE AUTO DX (395cc)",
          "APE XTRA DLX PASSENGER (395cc)",
          "APE CITY DLX (395cc)",
          "APE D3S (395cc)",
          "APE CITY PAXX (395cc)",
          "XTRA LDX PLUS DSL PU HD OBD2A (395cc)",
          "CITY PLUS LPG (395cc)",
          "CITY LPG (395cc)",
          "CITY DIESEL (395cc)",
          "3 WHEELER (395cc)",
          "AUTO D3S (395cc)",
          "4STR (395cc)",
          "CITY STR 4 (275cc)",
          "XTRA DLX PASSENGER (436cc)",
          "EXTRA LDX (436cc)",
          "PAXX (436cc)",
          "XTRA PASSENGER NUOVO (436cc)",
          "XTRA DXL (436cc)",
          "PAXX NEW NUOVO (436cc)",
          "D3S STR 7 (436cc)",
          "APE PV DELUX (436cc)",
          "APE PAXX 501 (436cc)",
          "APE NUOVO DX (436cc)",
          "APE XTRA DLX PASSENGER (436cc)",
          "APE D3S (436cc)",
          "APE XTRA PASSENGER STD (436cc)",
          "CITY DIESEL (436cc)",
          "XTRA BS III (436cc)",
          "XTRA DLX (436cc)",
          "CITY 200 STR 4 (197cc)",
          "CITY LPG MONO (197cc)",
          "CITY LPG (197cc)",
          "CITY LPG 197 CC (197cc)",
          "CITY PETROL (197cc)",
          "MINI (441cc)",
          "AUTO PLUS (435cc)",
          "AUTO BSIV DX DIESEL (435cc)",
          "XTRA PASSENGER STD (435cc)",
          "AUTO PAXX DX BSVI DIESEL (435cc)",
          "CITY COMPACT (435cc)",
          "XTRA LD (435cc)",
          "APE XTRA PASSENGER PLUS (435cc)",
          "APE CITY PLUS (435cc)",
          "APE PAXX D5S (435cc)",
          "AUTO DX (435cc)",
          "AUTO DX CLS (435cc)",
          "XTRA DX CLASSIC (435cc)",
          "CITY DIESEL (435cc)",
          "AUTO D PLUS (435cc)",
          "CITY CNG DLX BS1V 200 (99cc)",
          "CITY 200 (200cc)",
          "501 PAXX WB BS IV (450cc)",
          "E CITY (150cc)",
          "E CITY FX LB (150cc)",
          "AUTO DX BSVI (598cc)",
          "AUTO DX BSVI (599cc)",
          "CITY CNG (SMALL) BSVI (599cc)",
          "CITY CNG PLUS BSVI (599cc)",
          "AUTO DXL BSVI (599cc)",
          "1.0 TON STR 4 (750cc)",
          "CARGO D600 STR 7 (350cc)",
          "D600 D600 (350cc)",
          "STR 4 (350cc)",
          "DBS STR 7 (422cc)",
          "STR 4 (422cc)",
          "CITY PLUS (230cc)",
          "CITY CNG SMALL (230cc)",
          "CITY PETROL BSVI (230cc)",
          "AUTO DX CNG (230cc)",
          "CITY LPG BSVI (230cc)",
          "APE CITY PLUS (230cc)",
          "CITY PLUS CNG BS1V 200 (230cc)",
          "CITY NXT PLUS LPG BSVI (230cc)",
          "CITY PLUS METRO CNG OBD2A (230cc)",
          "CITY PLUS METRO LPG OBD2A (230cc)",
          "CITY PLUS (597cc)",
          "AUTO DX CLS BSVI (597cc)",
          "AUTO HT DX BS VI CNG (300cc)",
          "E-CITY FX (6cc)",
          "E-CITY FX MAX (6cc)",
          "XTRA DL (145cc)",
          "3 WHEELER 7 STR (659cc)",
          "A/R (398cc)",
        ],
      },
      {
        model: "PASSENGER",
        variants: ["D3S STR 4 (395cc)"],
      },
      {
        model: "DIESEL",
        variants: ["MEGA STR 4 (250cc)"],
      },
      {
        model: "PETROL",
        variants: ["2S STR 4 (250cc)"],
      },
      {
        model: "REAR",
        variants: ["ENGINE PCV STR 4 (350cc)"],
      },
      {
        model: "GARV",
        variants: ["SHAKTI 435 DÂ  API G 435 D (436cc)"],
      },
    ],
  },
  {
    make: "PREMIER ESOL TECHNOLOGIES",
    models: [
      {
        model: "ESOL",
        variants: ["0 (150cc)"],
      },
    ],
  },
  {
    make: "SCANIA",
    models: [
      {
        model: "MERTOLINK",
        variants: ["HD 310 (9300cc)"],
      },
    ],
  },
  {
    make: "TESLA",
    models: [
      {
        model: "X",
        variants: [
          "0 (99000cc)",
          "P100D 6 STR (4000cc)",
          "P100D 7 STR (4000cc)",
          "P100D 5 STR (4000cc)",
        ],
      },
      {
        model: "MODEL",
        variants: ["3 (99000cc)"],
      },
      {
        model: "Y",
        variants: ["0 (336000cc)"],
      },
      {
        model: "S",
        variants: ["100D (4000cc)"],
      },
    ],
  },
];
