// App.js

import React, { useState } from 'react';
import LeadDetails from './LeadDetails';
import QuoteList from './QuoteList';
import PolicyList from './PolicyList'
import ProposalList from './ProposalList'

const ListQuoteOrPolicies = () => {

  const hash = window.location.hash.substring(1).split("/")[0];
  console.log("hash",hash)
  // Example data for lead and quotes (replace with actual state management)
  const [lead, setLead] = useState({
    firstName: 'John',
    lastName:'deo',
    mobile: '123-456-7890',
    email: 'john.doe@example.com',
    lob: 'Auto Insurance'
  });

  const [quotes, setQuotes] = useState([
    {
      id: 1,
      generatedDate: '2024-06-20',
      rtoNumber: 'RTO1234',
      make: 'Toyota',
      model: 'Camry',
      startDate: '2024-07-01',
      endDate: '2025-07-01'
    },
    {
      id: 2,
      generatedDate: '2024-06-21',
      rtoNumber: 'RTO5678',
      make: 'Honda',
      model: 'Accord',
      startDate: '2024-07-01',
      endDate: '2025-07-01'
    }
  ]);

  return (
    <div>
    <h5 className="mb-3 mt-2">{hash==="quotes" ? 'Quotes' : 'Polices'}</h5>
      <LeadDetails lead={lead}  />
      {hash==='quotes' && <QuoteList quotes={quotes} /> }
      {hash==='proposals' && <ProposalList/> }
      {hash==='policies' && <PolicyList  /> }
    </div>
  );
};

export default ListQuoteOrPolicies;
