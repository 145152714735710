import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import info from "../../../images/info.png";
import MultistepProposal from './MultistepProposal';
import UseFormContext from '../../../context/UseFormContext';

const HealthProposal = (props) => {
  const formContext = UseFormContext();
  let history = useHistory();


  const backButtonPressed = () => {
    formContext.setgetquotesApiFlag(false)
    history.push("/quotes/1");
  };

  useEffect(() => {
    // Add the "car-page" class to the body element when the component mounts
    document.body.classList.add("car-page");
    // Clean up function: Remove the "car-page" class when the component unmounts
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);

  return (
    <>
      <div className="proposal-wrap rider-selection-wrap">
        <Container fluid className="form-bg">
          <Row>
            <Col className="p-left" sm={12} md={4}>
              <div className="leftside-form">
                <h3 class="title mb-3">Premium Amount ₹ 1711</h3>
                <div className="summary-details mb-3" style={{ textAlign: "left" }}>
                  <div class="heading-title pt-2 pb-2"><h6 class="title_text mb-0 fw-600">Summary</h6></div>
                  <div className="content-wrap">
                    <div class="amount-info">
                      <div class="amount-item">
                        <span className="text-grey text-left">Base Premium - 1 Year:</span>
                        <span className="text-right">₹ 14,614</span>
                      </div>
                    </div>
                    <div className="mt-3 mb-3">
                      <label className="mb-1 fw-600">Select rider(s)</label>
                      <div class="amount-item border-box">
                        <label className="text-grey">Missing out on benefits</label>
                        <a className="text-success decoration-none">View riders</a>
                      </div>
                    </div>
                    <div className="mt-3 mb-3">
                      <label className="mb-1 fw-600">Select Add-ons</label>
                      <div class="amount-item border-box">
                        <label className="text-grey">No add-ons Selected</label>
                        <a className="text-success decoration-none">View add-ons</a>
                      </div>
                    </div>
                    <div>
                      <div className="policy-type">

                        <p className="text-grey">
                          <span>
                            <OverlayTrigger
                              overlay={
                                <Tooltip id="tooltip-tex">Info text</Tooltip>
                              }
                            >
                              <span className="info-icon-wrap">
                                <img className="infoicon" src={info} />
                              </span>
                            </OverlayTrigger></span>Port option is only available from <span className="text-dark fw-600">₹7 Lakhs </span>or above cover amount <a href="#" className="text-success decoration-none">change cover amount</a></p>

                      </div>
                    </div>
                  </div>
                  <div class="total-info content-bottom-wrap">
                    <div class="amount-item total-item">
                      <span className="text-dark  fw-600 text-left">Total Premium</span>
                      <span className="text-right fw-600">₹ 14,614</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={8} className="mb-3">
              <MultistepProposal healthMainFormData={formContext.healthMainFormData} healthQuoteFormikData={formContext.healthQuoteFormikData} selectedHealthQuoteData={formContext.selectedHealthQuoteData} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HealthProposal;