import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
} from "react-bootstrap";
import ErrorMessage from "../../commonModules/ErrorMessage";
import InputFieldText from "../../commonModules/InputFieldText";
import InputFieldCheckBox from "../../commonModules/InputFieldCheckBox";
import { formikFieldVisibility } from "../../commonModules/CommonCode";
import * as api from "../../../API/authCurd";
import UseFormContext from "../../../../src/context/UseFormContext";

export default function AddressDetails(props) {
  const formContext = UseFormContext();
  const [fieldVisibility, setFieldVisibility] = useState(true);
  const { isRegisteredAddressSame, ownerFlatNumber, ownerAddress, ownerLocation, ownerPincode, ownerCity, ownerState } = props.formik.values
  const [pincodeStatus, setpincodeStatus] = useState(true);
  // Set initial visibility of formik fields when the component mounts
  useEffect(() => {
    formikFieldVisibility(["ownerFlatNumber", "ownerAddress", "ownerLocation", "ownerPincode", "ownerCity", "ownerState", "isRegisteredAddressSame", "ownerFlatNumberReg", "ownerAddressReg", "ownerLocationReg", "ownerPincodeReg", "ownerCityReg", "ownerStateReg"], props.formik)
    if (!props?.formik?.values?.ownerPincode) {
      defaultValueForFields()
    }
  }, []);

  useEffect(() => {
    if (props?.formik?.values?.ownerPincode?.length === 6 && pincodeStatus) {
      formContext.setloaderStatus(true);
      setTimeout(() => {
        formContext.setloaderStatus(false);
      }, 2000);

      api.getFullAddress(props?.formik?.values?.ownerPincode).then(data => { fillAddress(data.data) }).catch(err => { }).finally((res) => { formContext.setloaderStatus(false); })
    }

  }, [props?.formik?.values?.ownerPincode]);
  const defaultValueForFields = () => {
    // kyc data for fields
    if (formContext.kycApiRes) {
      setpincodeStatus(false)
      setTimeout(() => {
        setpincodeStatus(true)
      }, 2000);
      const nestedData = formContext?.kycApiRes?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS
      props.formik.setFieldValue("ownerPincode", nestedData?.PERM_PIN ? nestedData?.PERM_PIN : "");
      props.formik.setFieldValue("ownerCity", nestedData?.PERM_CITY ? nestedData?.PERM_CITY : "");
      props.formik.setFieldValue("ownerState", nestedData?.PERM_STATE ? nestedData?.PERM_STATE : "");
      props.formik.setFieldValue("ownerLocation", nestedData?.PERM_CITY ? nestedData?.PERM_CITY : "");
      props.formik.setFieldValue("ownerAddress", nestedData?.PERM_LINE1 ? nestedData?.PERM_LINE1 + nestedData?.PERM_LINE2 + nestedData?.PERM_LINE3 : "");
    } else {
      setpincodeStatus(true);
    }
  }
  useEffect(() => {
    // if Registered address same as above  is checked
    if (props.formik.values.isRegisteredAddressSame) {
      props.formik.setFieldValue('ownerFlatNumberReg', props.formik.values.ownerFlatNumber);
      props.formik.setFieldValue('ownerAddressReg', props.formik.values.ownerAddress);
      props.formik.setFieldValue('ownerLocationReg', props.formik.values.ownerLocation);
      props.formik.setFieldValue('ownerPincodeReg', props.formik.values.ownerPincode);
      props.formik.setFieldValue("ownerCityReg", props.formik.values.ownerCity);
      props.formik.setFieldValue("ownerStateReg", props.formik.values.ownerState);
      setFieldVisibility(true)
    } else {
      setFieldVisibility(false)
    }
  }, [isRegisteredAddressSame, ownerAddress, ownerFlatNumber, ownerState, ownerCity, ownerPincode, ownerLocation]);

  const fillAddress = (address) => {
    const addressObj = address?.[0]?.PostOffice[0]
    props.formik.setFieldValue('ownerLocation', addressObj?.Block);
    props.formik.setFieldValue("ownerCity", addressObj?.Name);
    props.formik.setFieldValue("ownerState", addressObj?.State);

  }
  return (
    <React.Fragment>
      <div>
        <Row>
          <Col sm={4} className="mb-4">
            <div className="inputWrap">
              <InputFieldText formikFieldName="ownerPincode" placeholder="Pin Code*" formik={props.formik} />
              {formContext.loaderStatus && formContext.spinner}
            </div>
            <ErrorMessage formik={props.formik} fieldValue="ownerPincode" />

          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerFlatNumber" placeholder="Flat number*" formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="ownerFlatNumber" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerAddress" placeholder="Enter address*" formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="ownerAddress" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerLocation" placeholder="Enter a location*" formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="ownerLocation" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerCity" placeholder="City*" formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="ownerCity" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerState" placeholder="State*" formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="ownerState" />
          </Col>
        </Row>

        <InputFieldCheckBox formik={props.formik} formikFieldName="isRegisteredAddressSame" label="Registered address same as above" />

        <Row>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerPincodeReg" placeholder="Pin code*" formik={props.formik} disabled={fieldVisibility} />
            <ErrorMessage formik={props.formik} fieldValue="ownerPincodeReg" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerFlatNumberReg" placeholder="Flat number*" formik={props.formik} disabled={fieldVisibility} />
            <ErrorMessage formik={props.formik} fieldValue="ownerFlatNumberReg" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerAddressReg" placeholder="Enter address*" formik={props.formik} disabled={fieldVisibility} />
            <ErrorMessage formik={props.formik} fieldValue="ownerAddressReg" />
          </Col>
          <Col sm={4}>
            <InputFieldText formikFieldName="ownerLocationReg" placeholder="Enter a location*" formik={props.formik} disabled={fieldVisibility} />
            <ErrorMessage formik={props.formik} fieldValue="ownerLocationReg" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerCityReg" placeholder="City*" formik={props.formik} disabled={fieldVisibility} />
            <ErrorMessage formik={props.formik} fieldValue="ownerCityReg" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerStateReg" placeholder="State*" formik={props.formik} disabled={fieldVisibility} />
            <ErrorMessage formik={props.formik} fieldValue="ownerStateReg" />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
