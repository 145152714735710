import React, { useEffect, useState } from "react";
import { downloadPolicy } from "../../../API/authCurd";
import UseFormContext from "../../../../src/context/UseFormContext";
import { useHistory } from "react-router-dom";
import MailService from "../../../services/mail.service";
import { decryptData } from "../../../services/auth-service";
import axios from "axios";
import * as api from "../../../API/authCurd";

export const PaymentSuccessAdmin = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const formContext = UseFormContext();
    const history = useHistory();
    const [user, setUser] = useState(null);
    const objForUpdateStage = {}
    // useEffect(() => {
    //   let user1 = decryptUser();
    //   if (!user1) {
    //     console.log("no user");
    //     history.push("/login");
    //   }
    // }, [history]);

    // const decryptUser = async () => {
    //   const encryptedUser = localStorage.getItem("user");
    //   if (encryptedUser) {
    //     try {
    //       const user1 = await decryptData(encryptedUser);
    //       setUser(user1)
    //       return user1;
    //     } catch (error) {
    //       console.error("Error decrypting user:", error);
    //     }
    //   }
    // };

    const valuesForPayload = {
        policyNo: queryParams.get("policyNo") ? queryParams.get("policyNo") : "",
        lastName: queryParams.get("LastName") ? queryParams.get("LastName") : "",
        email: queryParams.get("Email") ? queryParams.get("Email") : "",

    };

    useEffect(() => {
        let user = localStorage.getItem("user");
        if (user) {
            apiCall();
        }
        apiCall();
    }, []);

    // to download policy first step
    const apiCall = async () => {
        console.log("here");
        if (
            localStorage.getItem("insuranceCompany") !==
            "ICICI Lombard General Insurance Co. Ltd"
        ) {
            downloadPolicy(localStorage.getItem("customerphoneno"))
                .then(async (data) => {
                    //console.log("DATA",da)
                    const jsonData = JSON.parse(data?.data);
                    const arraylength = jsonData?.data?.policyDetails.length;
                    console.log("jsonData", jsonData);
                    let url = jsonData?.data?.policyDetails[arraylength - 1].DownloadLink
                        ? jsonData?.data?.policyDetails[arraylength - 1].DownloadLink
                        : jsonData?.data?.policyDetails[arraylength - 1].downloadUrl;

                    console.log("url", url);

                    let carrierId = jsonData?.data?.policyDetails[arraylength - 1]?.carrierId
                    let mobile = jsonData?.data?.policyDetails[arraylength - 1]?.mobile
                    let pkey = jsonData?.data?.policyDetails[arraylength - 1]?.secretKey
                    // crm update state
                    crmStateUpdate(jsonData?.data?.policyDetails[arraylength - 1])
                    // Call download file from API and send mail
                    downloadFileFromAPI(carrierId, mobile, pkey, url);
                })
                .catch((err) => {
                    console.log("err", err);
                    formContext.notifyError("An error occurred while fetching data");
                });
        }
    };
    // crm user stage update
    const crmStateUpdate = async (obj) => {
        objForUpdateStage.customer_name = obj?.Name
        objForUpdateStage.mobile_number = obj?.mobile
        objForUpdateStage.RTO_no = obj?.registrationNumber
        objForUpdateStage.module = "motor"

        objForUpdateStage.KYC_Status = "completed"
        objForUpdateStage.proposal_stage = "completed"
        objForUpdateStage.policy_stage = "completed"

        const crmToken = await formContext.setCrmTokenMethod();
        api.crmStageUpdateApi(crmToken?.data?.token, objForUpdateStage).then(res => { }).catch(err => { })
    }
    const downloadFileFromAPI = (carrierId, mobile, pKey, url) => {
        const apiUrl =
            process.env.REACT_APP_BASE_URL + process.env.REACT_APP_POLICY_URL;

        // Define request data
        const requestData = {
            header: {
                transactionName: "policyDocDownloadService",
                messageId: "",
                deviceId: "ABCD12345",
            },
            body: {
                mobile: mobile,
                pKey: pKey,
            },
        };

        // Make POST request to the API
        axios
            .post(apiUrl, requestData)
            .then((response) => {
                // Extract URL from response data
                const responseData = JSON.parse(response.data);
                const downloadLink = responseData.data.DownloadLink;
                console.log("downloadLink", downloadLink);

                // Create mail content with download link
                const to = localStorage.getItem("ownerEmail");
                const bcc = ["tejashrik@comsenseconsulting.com"]
                const cc= ["sanyogita.sawant@navnitinsurance.com"]
                const subject = `Your Policy Has Been Successfully Generated`;
                const content = `
                <!DOCTYPE html>
                <html lang="en">
                <head>
                    <meta charset="UTF-8">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0">
                    <title>Policy Generation Confirmation</title>
                </head>
                <body style="font-family: Arial, sans-serif; line-height: 1.6;">
                    <table width="100%" cellpadding="0" cellspacing="0" border="0">
                        <tr>
                            <td>
                                <table align="center" width="600" cellpadding="0" cellspacing="0" border="0" style="border: 1px solid #ddd; padding: 20px; background-color: #f9f9f9;">
                                    <tr>
                                        <td style="padding: 10px 0; text-align: center;">
                                            <h1 style="font-size: 24px; color: #333;">Policy365</h1>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="padding: 20px; color: #555;">
                                            <p>Dear,</p>
                                            <p>We are pleased to inform you that your policy has been successfully generated. You can now download and review your policy document at your convenience.</p>
                                            <p>To download policy, please click <a href="${downloadLink}" download="policy_document.pdf">here</a>.</p>
                                            <p>If you have any questions or need further assistance, please do not hesitate to contact our customer support team.</p>
                                            <p>Thank you for choosing Policy365.</p>
                                            <p>Best regards,</p>
                                            <p>Policy365<br>
                                                Policy365 Customer Support<br>
                                                022-62526252<br>
                                                contact@policies365.com</p>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </body>
                </html>
            `;

                // Send mail

                const data1 = { to, bcc, cc, subject, content };
                console.log("data1", data1)
                MailService.sendMail(data1).then((res) => {
                    console.log("data after mail", res.data);
                });
            })
            .catch((error) => {
                console.error("Error downloading file from API:", error);
            });
    };



    const downloadFile = (url) => {
        const link = document.createElement("a");
        link.href = url;
        link.download = "document";
        link.target = "_blank";
        document.body.appendChild(link);
        setTimeout(() => {
            link.click();
            document.body.removeChild(link);
        }, 500);
    };



    const handleRedirect = (e) => {
        e.preventDefault();
        window.location.href = "/myaccount?tab=5";
    };

    console.log(
        "context",
        formContext?.carrierName,
        formContext?.singleQuotesData?.insuranceCompany
    );

    return (
        <div className="payment-success-wrap">
            <h4>Payment Success</h4>
            <p>
                We thank you for choosing Policies365 for your insurance needs. You
                would receive a confirmation for the premium paid along with the policy
                copy attached in short while
            </p>
            <div className="details-wrap">
                <p className="sub-head">The Policy details are as follow:</p>
                <div className="details">
                    <div className="row-wrap">
                        <p className="label-text">Policy Certificate Number</p>
                        <span className="label-text">:</span>
                        <p className="value-policy ">{valuesForPayload.policyNo} </p>
                    </div>
                </div>
                <hr className="bordertop" />
                <div className="bottom-info">
                    <p className="label-text">
                        In case of any queries or assistance,please call us on our Helpline:
                        <a>(022) 68284343</a>
                    </p>
                    <p className="label-text">
                        (Office hours 10:00 am to 7:00 pm) Or write to us @:
                        <a>contact@policies365.com</a>
                    </p>
                </div>
            </div>

            <button className="goback-btn" onClick={handleRedirect}>
                Download Policy
            </button>
        </div>
    );
};
