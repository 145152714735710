import BikesPage from "../components/pages/Bikes/BikePage";
import CarPage from "../components/pages/Cars/CarPage";
import HealthPage from "../components/pages/Health/HealthPage";
import Home from "../components/pages/Home";
import ProposalPage from "../components/pages/ProposalPage";
import QuotesPage from "../components/pages/QuotesPage";
import SignIn from "../components/pages/SignIn";
import Login from "../components/pages/Login";
import Contact from "../components/pages/contact";
import PaymentSuccessFailure from "../components/pages/PaymentSuccessFailure";
import { MyProfile } from "../components/pages/MyProfile";
import { MyProfileAdmin } from "../components/pages/MyprofileAdmin";
import { PaymentSuccess } from "../components/pages/Payments/PaymentSuccess";
import { PaymentFailed } from "../components/pages/Payments/PaymentFailed";
import { PaymentRedirect } from "../components/pages/Payments/PaymentRedirect";
import { FutureQuotesPayment } from "../components/pages/Payments/FutureQuotesPayment";
import { HDFCQuotesPayment } from "../components/pages/Payments/HDFCQuotesPayment";
import AddAgent from "../components/pages/Manager/AddAgent";
import AddBranch from "../components/pages/Admin/AddBranch";
import ResetPassword from "../components/pages/ResetPassword";
import AddAgency from "../components/pages/Admin/AddAgency";
import BuyInsurance from "../components/pages/Agent/BuyInsurance";
import MyCustomers from "../components/pages/Agent/Mycustomers";
import ListOfAgent from "../components/pages/Manager/ListOfAgent";
import ViewAgent from "../components/pages/Admin/ViewAgent";
import ViewBranch from "../components/pages/Admin/ViewBranch";
import ListBranch from "../components/pages/Admin/ListBranch";
import ListAgency from "../components/pages/Admin/ListAgency";
import ViewAgency from "../components/pages/Admin/ViewAgency";
import OnBoardingPage from "../components/pages/POSP/OnBoardingPage";
import HealthQuote from "../components/pages/Health/HealthQuote";
import HealthProposal from "../components/pages/Health/HealthProposal";
import UpdatePassword from "../components/pages/UpdatePassword";
import AccessDenied from "../components/pages/AccessDenied";
import decryptData from "../services/auth-service";
import ViewLead from "../components/pages/Agent/ViewLeadslist"
import GetLeadInfo from "../components/pages/Agent/GetLeadInfo"
import { components } from "react-select";
import ListQuoteOrPolicies from "../components/pages/Agent/ListQuoteOrPolicies"
import UpdateLead from "../components/pages/Agent/UpdateLeadInfo"
import ProposalPageForRedirection from "../components/pages/Agent/ProposalPageForRedirection"
import DownloadFileFromCRM from "../components/pages/DownloadFileFromCRM";
import ListPospUsers from "../components/pages/Admin/ListPospUsers"
import ViewPosp from "../components/pages/Admin/ViewPosp"
import Travel from "../components/pages/Travel/Travel";
import TermLife from "../components/pages/TermLife/TermLife";

export const routesArray = [
  {
    path: "/bike/:id?",
    component: BikesPage,
    exact: true,
  },
  {
    path: "/",
    component: Home,
    exact: true,
  },
  {
    path: "/car/:id?",
    component: CarPage,
    exact: true,
  },
  {
    path: "/quotes/:id?",
    component: QuotesPage,
    //(role === "AGENT" ? QuotesPage  : AccessDenied )
    exact: true,
  },
  {
    path: "/signin",
    component: SignIn,
    exact: true,
  },
  {
    path: "/login",
    component: Login,
    exact: true,
  },
  {
    path: "/proposal/:id?",
    component: ProposalPage,
    exact: true,
  },
  {
    path: "/proposal/:id/:proposalID",
    component: ProposalPage,
    exact: true,
  },
  {
    path: "/home",
    component: Home,
    exact: true,
  },
  {
    path: "/contact",
    component: Contact,
    exact: true,
  },
  {
    path: "/paymentStatus",
    component: PaymentSuccessFailure,
    exact: true,
  },
  {
    path: "/paymentStatus",
    component: PaymentSuccessFailure,
    exact: true,
  },
  {
    path: "/myaccount/:id?",
    component: MyProfile,
    exact: true,
    componentName:"MyProfile"
  },
  {
    path: "/myaccount/:tab",
    component: MyProfile,
    exact: true,
    componentName:"MyProfile"

  },
  {
    path: "/paymentSuccess",
    component: PaymentSuccess,
    exact: true,
  },
  {
    path: "/paymentFailed/:id?",
    component: PaymentFailed,
    exact: true,
  },
  {
    path: "/paymentRedirect",
    component: PaymentRedirect,
    exact: true,
  },
  {
    path: "/futureinsurance",
    component: FutureQuotesPayment,
    exact: true,
  },
  {
    path: "/hdfcinsurance",
    component: HDFCQuotesPayment,
    exact: true,
  },
  {
    path: "/resetPassword",
    component: ResetPassword,
    exact: true,
  },
  {
    path: "/updatePassword",
    component: UpdatePassword,

    exact: true,
  },
  {
    path: "/addAgent",
    component: AddAgent,
    //component: role === "ADMIN" || role === "MANAGER" ? AddAgent : AccessDenied,
    exact: true,
  },

  {
    path: "/addbranch",
    component: AddBranch,
    //role === "ADMIN" || role === "MANAGER" ? AddBranch : AccessDenied,
  },
  {
    path: "/addAgency",
    component: AddAgency,
    //role === "ADMIN" ? AddAgency : AccessDenied,

    exact: true,
  },
  {
    path: "/myCustomers",
    component: MyCustomers,
    // role === "ADMIN" || role === "MANAGER" ? MyCustomers : AccessDenied,

    exact: true,
  },

  {
    path: "/listOfAgents",
    component: ListOfAgent,
    //  role === "ADMIN" || role === "MANAGER" ? ListOfAgent : AccessDenied,

    exact: true,
  },
  {
    path: "/listOfBranch",
    component: ListBranch,
    //role === "ADMIN" || role === "MANAGER" ? ListBranch : AccessDenied,

    exact: true,
  },
  {
    path: "/ListAgency",
    component: ListAgency,
    //role === "ADMIN" ? ListAgency : AccessDenied,
    exact: true,
  },
  {
    path: "/viewAgent/:email",
    component: ViewAgent,
    //   role === "ADMIN" || role === "MANAGER" ? ViewAgent : AccessDenied,

    exact: true,
  },
  {
    path: "/viewBranch/:id",
    component: ViewBranch,
    // role === "ADMIN" || role === "MANAGER" ? ViewBranch : AccessDenied,

    exact: true,
  },
  {
    path: "/viewAgency/:id",
    component: ViewAgency,
    // role === "ADMIN" ? ViewAgency : AccessDenied,

    exact: true,
  },
  {
    path: "/buyInsurance",
    component: BuyInsurance,
    //   role === "ADMIN" || role === "MANAGER" ? BuyInsurance : AccessDenied,
    exact: true,
  },

  {
    path: "/OnBoardingPage",
    component: OnBoardingPage,
    exact: true,
  },

  // {
  //   path: "*",
  //   component: Home,
  //   exact: true,
  // },
  {
    path: "/healthQuotes",
    component: HealthQuote,
    exact: true,
  },
  {
    path: "/health/:id?",
    component: HealthPage,
    exact: true,
  },
  {
    path: "/healthProposal",
    component: HealthProposal,
    exact: true,
  },
  {
    path: "/viewLead",
    component: ViewLead,
    exact: true
  },
  {
    path: "/getLeadInfo",
    component: GetLeadInfo,
    exact: true
  },
  {
    path:"/updateLead/:id",
    component: UpdateLead,
    exact: true
  },
  {
    path:"/ListQuoteOrPolicies",
    component: ListQuoteOrPolicies,
    exact: true
  },
  {
    path:"/ListQuoteOrPolicies#policies/id",
    component: ListQuoteOrPolicies,
    exact:true
  },
  {
    path:"/dowloadFileForCRM/:mobileNo/:pKey",
    component: DownloadFileFromCRM,
    exact: true
  },
  {
    path: "/pospusers",
    component: ListPospUsers,
    exact: true
  },
  {
    path:"/viewPosp/:email",
    component: ViewPosp,
    exact:true
  },
{
  path:"/termlife",
  component: TermLife,
  exact:true
},
{
  path:"/travel",
  component: Travel,
  exact:true
}



];

export const routesArrayForCustomer = [
  {
    path: "/home",
    heading: "Home",
    type: "home",
  },
  {
    path: "/car",
    heading: "Car",
    type: "car",
  },
  {
    path: "/bike",
    heading: "Bike",
    type: "bike",
  },
  {
    path: "/health",
    heading: "Health",
    type: "health",
  },
  {
    path: "/termlife",
    heading: "Term Life",
    type: "termlife",
  },
  {
    path: "/travel",
    heading: "Travel",
    type: "travel",
  },
  {
    path: "/myaccount",
    heading: "Login",
    type: "myaccount",
  },
  {
    path: '/onBoardingPage',
    heading: "Join Us As POSP",
    type: "agencylogin"
  }
];

export const routesArrayForCustomerAfterLogin = [
  {
    path: "/home",
    heading: "Home",
    type: "home",
  },
  {
    path: "/car",
    heading: "Car",
    type: "car",
  },
  {
    path: "/bike",
    heading: "Bike",
    type: "bike",
  },
  {
    path: "/health",
    heading: "Health",
    type: "health",
  },
  {
    path: "/termlife",
    heading: "Term Life",
    type: "termlife",
  },
  {
    path: "/travel",
    heading: "Travel",
    type: "travel",
  },
  {
    path: "/myaccount",
    heading: "My Account",
    type: "myaccount",
  },
  {
    path: '/onBoardingPage',
    heading: "Join Us As POSP",
    type: "agencylogin"
  }
];

export const routesArrayForAdmin = [
  {
    path: "/home",
    heading: "Home",
    type: "home",
  },
  {
    path: "/ListAgency",
    heading: "Agencies",
    type: "agencies",
  },
  {
    path: "/listOfBranch",
    heading: "Branches",
    type: "branches",
  },
  {
    path: "/listOfAgents",
    heading: "Agents",
    type: "agents",
  },
  // {
  //   path: "/buyinsurance",
  //   heading: "Buy Insurance",
  //   type: "buyinsurance",
  // },
  {
    path: "/pospusers",
    heading: "POSP Users",
    type: "pospusers",
  },
  {
    path: "/myaccount",
    heading: "My Account",
    type: "myaccount",
  },
];

export const routesArrayForManager = [
  {
    path: "/home",
    heading: "Home",
    type: "home",
  },
  {
    path: "/ListAgency",
    heading: "Agencies",
    type: "agencies",
  },
  {
    path: "/listOfBranch",
    heading: "Branches",
    type: "branches",
  },
  {
    path: "/listOfAgents",
    heading: "Agents",
    type: "agents",
  },
  {
    path: "/buyinsurance",
    heading: "Buy Insurance",
    type: "buyinsurance",
  },
  // {
  //   path: "/activitylog",
  //   heading: "Activity Log",
  //   type: "activitylog",
  // },
  {
    path: "/mycustomers",
    heading: "My Customers",
    type: "mycustomers",
  },
  {
    path: "/myaccount",
    heading: "My Account",
    type: "myaccount",
  },
];

export const routesArrayForAgent = [

  {
    path: "/dashboard",
    heading: "DashBoard",
    type: "dashboard",
  },
  {
    path: "/buyinsurance",
    heading: "Buy Insurance",
    type: "buyinsurance",
  },
  // {
  //   path: "/getquotes",
  //   heading: "Get Quotes",
  //   type: "getquotes",
  // },
  // {
  //   path:"/viewLead",
  //   heading: "View Leads",
  //   type: "viewleads",
  // },
  // {
  //   path: "/pendingproposal",
  //   heading: "Pending Proposal",
  //   type: "pendingproposal",
  // },
  // {
  //   path: "/pendingpayment",
  //   heading: "Pending Payment",
  //   type: "pendingpayment",
  // },
  {
    path: "/mycustomers",
    heading: "My Customers",
    type: "mycustomers",
  },
  {
    path: "/myaccount",
    heading: "My Account",
    type: "myaccount",
  }
];

export const routesArrayForPosp = [



  // {
  //   path: "/dashboard",
  //   heading: "DashBoard",
  //   type: "dashboard",
  // },
  {
    path: "/car",
    heading: "Car",
    type: "car",
  },
  {
    path: "/bike",
    heading: "Bike",
    type: "bike",
  },
  {
    path: "/health",
    heading: "Health",
    type: "health",
  },
  {
    path: "/termlife",
    heading: "Term Life",
    type: "termlife",
  },
  {
    path: "/travel",
    heading: "Travel",
    type: "travel",
  },
  // {
  //   path: "/getquotes",
  //   heading: "Get Quotes",
  //   type: "getquotes",
  // },
  // {
  //   path:"/viewLead",
  //   heading: "View Leads",
  //   type: "viewleads",
  // },
  // {
  //   path: "/pendingproposal",
  //   heading: "Pending Proposal",
  //   type: "pendingproposal",
  // },
  // {
  //   path: "/pendingpayment",
  //   heading: "Pending Payment",
  //   type: "pendingpayment",
  // },
  {
    path: "/buyinsurance",
    heading: "Buy Insurance",
    type: "buyinsurance",
  },
  {
    path: "/mycustomers",
    heading: "My Customers",
    type: "mycustomers",
  },
  {
    path: "/myaccount",
    heading: "My Account",
    type: "myaccount",
  }
];

export const routesArrayForAgentLobMotor = [
  {
    path: "/car",
    heading: "Car",
    type: "car",
  },
  {
    path: "/bike",
    heading: "Bike",
    type: "bike",
  },
];

export const routesArrayForAgentLobHealth = [
  {
    path: "/health",
    heading: "Health",
    type: "health",
  },
];

export const routesArrayForAgentLobLife = [
  {
    path: "/termlife",
    heading: "Term Life",
    type: "termlife",
  },
];
