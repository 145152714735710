import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import Validations from "../../../validations/validations";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Button, Form } from "react-bootstrap";
import validations from "../../../validations/validations";
import authService from "../../../services/auth-service";
import { FaEye, FaEyeSlash } from "react-icons/fa";
export default function ContainerForPassword(props) {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const toggleShowNewPassword = () => setShowNewPassword(!showNewPassword);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  const [errorMsg, setErrorMsg] = useState();
  const [showPasswordField, setShowPasswordField] = useState(false);

  let initialData = {
    email: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required().email(),
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //  alert(JSON.stringify(values, null, 2));
      generateOTP();
    },
    onReset: (values) => {
      "";
    },
  });

  const generateOTP = async () => {
    const data = { emailId: formik.values.email };
    try {
      await authService.generatePassword(data).then(async (response) => {
        console.log("response ::::::::", response);
        if (response.data.data) {
          alert("OTP sent successfully");
          setShowPasswordField(true);
          // window.location.href='/login'
        }
      });
    } catch (error) {
      console.error("Error:", error);
      setErrorMsg(error?.response?.data?.data?.id);
    }
  };

  let initialDataforPasswordReset = {
    email: "",
    otp: "",
    newpassword: "",
    confirmPassword: "",
  };

  const validationForPasswordReset = validations.passwordUpdateValidation;

  const formikForPasswordReset = useFormik({
    initialValues: initialDataforPasswordReset,
    validationSchema: validationForPasswordReset,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("values", values);
      //resetPassword(values);
    },
    onReset: (values) => { "" },
  });


  const resetPassword =async ()=>{
   // e.preventDefault()
    console.log("formikForPasswordReset.values",formikForPasswordReset.values)
    let data = {
      emailId: formikForPasswordReset.values.email,
      otp: formikForPasswordReset.values.otp,
      newpassword: formikForPasswordReset.values.newpassword,
    };
    try {
      await authService.forgotPassword(data).then((res) => {
        console.log("res::", res);
        alert("Successful!")
        window.location.reload("/")
      });
    } catch (error) {
      alert(error?.data?.invalidRequest)
      setErrorMsg(error?.response?.data?.data?.id);
    }
  }
  return !showPasswordField ? (
    <>
      <div className="bg-image signin-wrap" style={{ height: "auto" , padding: "0"}}>
        <div className="inner-sign-in">
          <Form className="Auth-form">
            <div className="Auth-form-content">
              <FloatingLabel
                controlId="email"
                label="Email*"
                className=""
                aria-autocomplete="off"
              >
                <Form.Control
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className="floating-input"
                />
                {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
                <Form.Text className="text-danger">
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger">{formik.errors.email}</div>
                  ) : null}
                </Form.Text>
              </FloatingLabel>

              <div className="inner-sign-in">
                <Button
                  className="primary-btn"
                  style={{ alignItems: "right", marginRight: "5px" }}
                  type="submit"
                  onClick={formik.handleSubmit}
                >
                  Reset
                </Button>

                <Button
                  type="button"
                  className="btn-secondary"
                  disabled={!formik.dirty}
                  onClick={() => formik.resetForm()}
                >
                  Clear
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </div>
    </>
  ) :  (
    <div className="bg-image signin-wrap" style={{ height: "auto" , padding: "0"}}>
      <div className="inner-sign-in">
        <Form className="Auth-form" onSubmit={formikForPasswordReset.handleSubmit}>
          <div className="Auth-form-content">
            <FloatingLabel controlId="email" label="Email*" className="" aria-autocomplete="off">
              <Form.Control
                name="email"
                type="email"
                onChange={formikForPasswordReset.handleChange}
                onBlur={formikForPasswordReset.handleBlur}
                value={formikForPasswordReset.values.email}
                className="floating-input"
              />
              <Form.Text className="text-danger">
                {formikForPasswordReset.touched.email && formikForPasswordReset.errors.email ? (
                  <div className="text-danger">{formikForPasswordReset.errors.email}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>

            <FloatingLabel controlId="otp" label="OTP*" className="">
              <Form.Control
                name="otp"
                type="text"
                onChange={formikForPasswordReset.handleChange}
                onBlur={formikForPasswordReset.handleBlur}
                value={formikForPasswordReset.values.otp}
                className="floating-input"
              />
              <Form.Text className="text-danger">
                {formikForPasswordReset.touched.otp && formikForPasswordReset.errors.otp ? (
                  <div className="text-danger">{formikForPasswordReset.errors.otp}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>

            <FloatingLabel controlId="newpassword" label="New Password*" className="position-relative">
              <Form.Control
                name="newpassword"
                type={showNewPassword ? "text" : "password"}
                onChange={formikForPasswordReset.handleChange}
                onBlur={formikForPasswordReset.handleBlur}
                value={formikForPasswordReset.values.newpassword}
                className="floating-input"
              />
              <span className="position-absolute end-0 top-0 mt-2 me-3" onClick={toggleShowNewPassword} style={{ cursor: 'pointer' }}>
                {showNewPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
              <Form.Text className="text-danger">
                {formikForPasswordReset.touched.newpassword && formikForPasswordReset.errors.newpassword ? (
                  <div className="text-danger">{formikForPasswordReset.errors.newpassword}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>

            <FloatingLabel controlId="confirmPassword" label="Confirm Password*" className="position-relative">
              <Form.Control
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                onChange={formikForPasswordReset.handleChange}
                onBlur={formikForPasswordReset.handleBlur}
                value={formikForPasswordReset.values.confirmPassword}
                className="floating-input"
              />
              <span className="position-absolute end-0 top-0 mt-2 me-3" onClick={toggleShowConfirmPassword} style={{ cursor: 'pointer' }}>
                {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
              </span>
              <Form.Text className="text-danger">
                {formikForPasswordReset.touched.confirmPassword && formikForPasswordReset.errors.confirmPassword ? (
                  <div className="text-danger">{formikForPasswordReset.errors.confirmPassword}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>

            <div className="inner-sign-in">
              <Button
                className="primary-btn"
                style={{ alignItems: "right", marginRight: "5px" }}
                type="submit"
                onClick={()=> { 
                  console.log("submitted")
                  formikForPasswordReset.handleSubmit()
                  resetPassword()
                } }
              >
                Reset
              </Button>
              <Button
                type="button"
                className="btn-secondary"
                disabled={!formikForPasswordReset.dirty}
                onClick={() => formikForPasswordReset.resetForm()}
              >
                Clear
              </Button>
            </div>

          </div>
        </Form>
      </div>
    </div>
  );
}
