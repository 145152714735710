import { useEffect, useState } from "react";
import axios from "axios";
import * as Yup from "yup";
import { useFormik } from "formik";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Modal, Button, Row, Col, Form } from "react-bootstrap";
import "./Popup.css";
import { useParams, useHistory } from "react-router-dom";
import AdminService from "../../../services/admin.service";
import Validations from "../../../validations/validations";
import { decryptData } from "../../../services/auth-service";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import ErrorMessage from "../../commonModules/ErrorMessage";

const Popup = ({ onClose, show }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>User Updated Successfully !!</Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button type="button" className="primary-btn" onClick={onClose}>
          Okay!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default function ContainerForAgentView(props) {
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [gender, setGender] = useState([
    { value: "", label: "Please Select Gender" }, // Set the default value to blank
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ]);

  const [maritalStatus, setMaritalStatus] = useState([
    { value: "", label: "Please Select" }, // Set the default value to blank
    { value: "married", label: "Married" },
    { value: "single", label: "Single" },
  ]);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      console.log("no user");
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [allAgencies, setAllAgencies] = useState([]);
  const [branches, setBranches] = useState([]);
  const [managerList, setManagerList] = useState([
    {
      email: "ManagerA@email.com",
      id: 14,
      name: "ManagerA",
    },
  ]);
  const [initialData, setInitialData] = useState(null);
  const validationSchema = Validations.userValidations;

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("values", values);
    },
  });

  useEffect(() => {
    fetchData();
    fetchAgencies();
  }, []);
  const { email } = useParams();

  const fetchData = async () => {
    try {
      const data = {
        email: email,
      };

      await AdminService.getUserByEmail(data).then((res) => {
        console.log("res", res.data.data);
        setInitialData(res?.data?.data);
      
        formik.setValues(res?.data?.data);
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [value, setValue] = useState("");
  const maxLength = 256;
  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setValue(inputValue);
    }
  };

  const handleEdit = (e) => {
    e.preventDefault();
    setIsEditing(true);
    fetchManagers();
  };

  const fetchManagers = async () => {
    const data = { role: "MANAGER" };
    try {
      await AdminService.listUsersByRole(data).then((data) => {
        setManagerList(data?.data?.data);
      });
    } catch (error) {
      console.log("no managers found", error);
    }
  };
  const fetchAgencies = async () => {
    try {
      await AdminService.listAgency().then((response) => {
        setAllAgencies(response?.data?.data);
      });
    } catch (error) {
      console.log(error);
      // console.error("Error:", error.response.data.data);
      // let errorObj = error.response.data.data;
      // alert(Object.values(errorObj));
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    setIsEditing(false);
  };

  const setId = async () => {
    if (formik.values.agencyName !== "") {
      const agency = allAgencies.filter(
        (agency) => agency.agencyName === formik.values.agencyName
      );

      formik.setFieldValue("agencyId", agency[0].id);
      try {
        const data = { agencyId: agency[0].id };
        await AdminService.getAllBranchByAgencyId(data).then((response) => {
          setBranches(response.data.data);
        });
      } catch (error) {
        console.log("Error", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = formik.values;
      const response = await AdminService.createUpdateUser(data);
      console.log("Response:", response.data);
      setShowPopup(true);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleClosePopup = () => {
    setShowPopup(false);
    setIsEditing(false);
    window.location.href = "/ListOfAgents";
  };

  const handleDeactivate = async () => {
    const confirmation = window.confirm("Are you sure ?");
    if (confirmation) {
      const userData = formik.values;
      userData.status =
        formik.values.status === "ACTIVE" ? "DEACTIVE" : "ACTIVE";
      try {
        await AdminService.createUpdateUser(userData);
        setShowPopup(true);
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  return (
    <>
      {initialData && initialData !== undefined && initialData !== null ? (
        <>
          <Form className="form-bg">
            {isEditing ? <h6 className="float-end">*Editing</h6> : null}

            <Row style={{ textAlign: "left" }}>
              {/* <h5 style={{alignContent: "left"}}>Basic Information</h5> */}
              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="firstName"
                  label="First Name*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="firstName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      className="floating-input"
                    />
                  ) : (
                    <Form.Control
                      name="firstName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.firstName}
                      className="floating-input"
                      disabled
                    />
                  )}
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.firstName && formik.errors.firstName ? (
                    <div className="text-danger">{formik.errors.firstName}</div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="lastName"
                  label="Last Name*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="lastName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      className="floating-input"
                    />
                  ) : (
                    <Form.Control
                      name="lastName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.lastName}
                      className="floating-input"
                      disabled
                    />
                  )}
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.lastName && formik.errors.lastName ? (
                    <div className="text-danger">{formik.errors.lastName}</div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col md={6} sm={12} className="mb-3">
            <InputFieldDropdown
              formikFieldName="gender"
              label="Gender*"
              optionsArray={gender}
              formik={formik}
              disabled={!isEditing}
            />
            <ErrorMessage formik={formik} fieldValue="gender" />
          </Col>
          <Col md={6} sm={12} className="mb-3">
            <InputFieldDropdown
              formikFieldName="maritalStatus"
              label="Marital status*"
              optionsArray={maritalStatus}
              formik={formik}
              disabled={!isEditing}
            />
            <ErrorMessage formik={formik} fieldValue="maritalStatus" />
          </Col>

          <Col md={6} sm={12} className="mb-3">
            <FloatingLabel
              controlId="callerId"
              label="Caller ID*"
              className=""
              aria-autocomplete="off"
            >
              <Form.Control
                name="callerId"
                type="callerId"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.callerId}
                className="floating-input"
                disabled={!isEditing}
                
              />
              {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
              <Form.Text className="text-danger">
                {formik.touched.callerId && formik.errors.callerId ? (
                  <div className="text-danger">{formik.errors.callerId}</div>
                ) : null}
              </Form.Text>
            </FloatingLabel>
          </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="agencyName"
                  label="Agency Name*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    // <Form.Control
                    //   name="agencyName"
                    //   type="text"
                    //   onChange={formik.handleChange}
                    //   onBlur={formik.handleBlur}
                    //   value={formik.values.agencyName}
                    //   className="floating-input"
                    // />

                    <Form.Select
                      name="agencyName"
                      onChange={(e) => {
                        formik
                          .setFieldValue("agencyName", e.target.value)
                          .then(() => setId());
                      }}
                      onBlur={formik.handleBlur}
                      value={formik.values.agencyName}
                      className="floating-input"
                    >
                      <option hidden>Select</option>
                      {allAgencies.map((agency) => {
                        return (
                          <option key={agency.id} value={agency.agencyName}>
                            {agency.agencyName}
                          </option>
                        );
                      })}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      name="agencyName"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.agencyName}
                      className="floating-input"
                      disabled
                    />
                  )}
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.agencyName && formik.errors.agencyName ? (
                    <div className="text-danger">
                      {formik.errors.agencyName}
                    </div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="agencyId"
                  label="Agency Id*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="agencyId"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.agencyId}
                      className="floating-input"
                      disabled
                    />
                  ) : (
                    <Form.Control
                      name="agencyId"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.agencyId}
                      className="floating-input"
                      disabled
                    />
                  )}
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.agencyId && formik.errors.agencyId ? (
                    <div className="text-danger">{formik.errors.agencyId}</div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="branchId"
                  label="Branch Id*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                     <Form.Select
                     name="branchId"
                     onChange={formik.handleChange}
                     onBlur={formik.handleBlur}
                     value={formik.values.branchId}
                     className="floating-input"
                   >
                     <option hidden>Select</option>
                   {formik.values.agencyId===initialData.agencyId ? <option value={formik.values.branchId}>{formik.values.branchId}</option> : null }
                     {branches ? (
                       branches.map((branch) => {
                         return (
                           <option value={branch.branchId}>{branch.name}</option>
                         );
                       })
                     ) : (
                       <option>No Branch Found</option>
                     )}
                   </Form.Select>
                  ) : (
                    <Form.Control
                      name="branchId"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.branchId}
                      className="floating-input"
                      disabled
                    />
                  )}
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.branchId && formik.errors.branchId ? (
                    <div className="text-danger">{formik.errors.branchId}</div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="phoneNumber"
                  label="Phone Number*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="phoneNumber"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                      className="floating-input"
                    />
                  ) : (
                    <Form.Control
                      name="phoneNumber"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.phoneNumber}
                      className="floating-input"
                      disabled
                    />
                  )}
                </FloatingLabel>
                <Form.Text className="text-danger">
                  {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                    <div className="text-danger">
                      {formik.errors.phoneNumber}
                    </div>
                  ) : null}
                </Form.Text>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="address"
                  label="Address*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="address"
                      type="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      className="floating-input"
                      maxLength={maxLength}
                    />
                  ) : (
                    <Form.Control
                      name="address"
                      type="address"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.address}
                      className="floating-input"
                      maxLength={maxLength}
                      disabled
                    />
                  )}
                  {formik.values.address ? (
                    <div
                      style={{
                        marginTop: "5px",
                        fontSize: "0.6rem",
                        textAlign: "right",
                      }}
                    >
                      {maxLength - formik.values.address.length}/256
                    </div>
                  ) : (
                    <div
                      style={{
                        marginTop: "5px",
                        fontSize: "0.6rem",
                        textAlign: "right",
                      }}
                    >
                      256/256
                    </div>
                  )}

                  {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
                  <Form.Text className="text-danger">
                    {formik.touched.address && formik.errors.address ? (
                      <div className="text-danger">{formik.errors.address}</div>
                    ) : null}
                  </Form.Text>
                </FloatingLabel>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="email"
                  label="Email*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Control
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="floating-input"
                      placeholder="xyz@abc.com"
                      disabled
                    />
                  ) : (
                    <Form.Control
                      name="email"
                      type="email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                      className="floating-input"
                      placeholder="xyz@abc.com"
                      disabled
                    />
                  )}

                  {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
                  <Form.Text className="text-danger">
                    {formik.touched.email && formik.errors.email ? (
                      <div className="text-danger">{formik.errors.email}</div>
                    ) : null}
                  </Form.Text>
                </FloatingLabel>
              </Col>

              {/* <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="userName"
                  label="Username*"
                  className=""
                  aria-autocomplete="off"
                >
                  <Form.Control
                    name="userName"
                    type="userName"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    className="floating-input"
                    disabled
                  />
                  <Form.Text className="text-danger">
                    {formik.touched.userName && formik.errors.userName ? (
                      <div className="text-danger">
                        {formik.errors.userName}
                      </div>
                    ) : null}
                  </Form.Text>
                </FloatingLabel>
              </Col> */}

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="role"
                  label="Designation*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Select
                      name="role"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.role}
                      className="floating-input"
                    >
                      {/* Here you can define your dropdown options */}
                      <option value="">Select...</option>
                      <option value="ADMIN">Admin</option>
                      <option value="MANAGER">Manager</option>
                      <option value="AGENT">Agent</option>
                      {/* Add more options as needed */}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      name="role"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.role}
                      className="floating-input"
                      disabled
                    />
                  )}

                  {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
                  <Form.Text className="text-danger">
                    {formik.touched.role && formik.errors.role ? (
                      <div className="text-danger">{formik.errors.role}</div>
                    ) : null}
                  </Form.Text>
                </FloatingLabel>
              </Col>

              <Col md={6} sm={12} className="mb-3">
                <FloatingLabel
                  controlId="reportingTo"
                  label="Reporting To*"
                  className=""
                  aria-autocomplete="off"
                >
                  {isEditing ? (
                    <Form.Select
                      name="reportingTo"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.reportingTo}
                      className="floating-input"
                    >
                      <option value={formik.values.reportingTo}>
                        {formik.values.reportingTo}
                      </option>
                      {managerList.map((option) => {
                        return (
                          <option value={option.email}>{option.name}</option>
                        );
                      })}
                    </Form.Select>
                  ) : (
                    <Form.Control
                      name="reportingTo"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.reportingTo}
                      className="floating-input"
                      disabled
                    />
                  )}

                  {/* <ErrorMessage formik={formik} fieldValue="email" /> */}
                  <Form.Text className="text-danger">
                    {formik.touched.reportingTo && formik.errors.reportingTo ? (
                      <div className="text-danger">
                        {formik.errors.emreportingToail}
                      </div>
                    ) : null}
                  </Form.Text>
                </FloatingLabel>
              </Col>
            </Row>

            {!isEditing ? (
              <Button
                className="primary-btn"
                style={{ marginRight: "5px" }}
                type="submit"
                onClick={handleEdit}
              >
                Edit
              </Button>
            ) : (
              <Button
                className="primary-btn"
                style={{ alignItems: "right", marginRight: "5px" }}
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            )}
            {!isEditing ? (
              formik.values.status === "ACTIVE" ? (
                <Button
                  type="button"
                  className="btn-secondary"
                  onClick={handleDeactivate}
                >
                  Deactivate
                </Button>
              ) : (
                <Button
                  type="button"
                  className="btn-secondary"
                  onClick={handleDeactivate}
                >
                  Activate
                </Button>
              )
            ) : (
              <>
                <Button
                  type="button"
                  className="btn-secondary"
                  onClick={handleCancel}
                >
                  {}
                  Cancel
                </Button>
              </>
            )}
          </Form>
          {showPopup && (
            <Popup
              // message={popupMessage}
              // message1={popupMessage1}
              onClose={handleClosePopup}
              show={true}
            />
          )}
        </>
      ) : (
        <span>No data Found for {email}</span>
      )}
    </>
  );
}
