/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import UseMultistepForm from "../../MultipleForms/UseMultiStepForms";
import BikeFormVehicleDetails from "../Bikes/BikeFormVehicleDetails";
import BikeFormPersonDetails from "../Bikes/BikeFormPersonDetails";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as api from "../../../API/authCurd"
import UseFormContext from "../../../../src/context/UseFormContext";
import { yearValidation, expDateValidation } from "../../commonModules/CommonCode";
import InputFieldCheckBox from "../../commonModules/InputFieldCheckBox";

import { Row, Col } from "react-bootstrap";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";

function MedicalQuestionnaire(props) {

  const initialData = {
    questions: [
      {
        questionId: 0,
        questionDesc: "Do you smoke, comsume alcohol, or chew tobacco, ghutka, or paan or use any recreationar drugs.",
        ...props.healthQuoteFormikData.healthInsurefor.reduce((acc, member) => {
          acc[member.id] = false;
          return acc;
        }, {})
      },
    ],
  };

  const formik = useFormik({
    initialValues: initialData,
    onSubmit: (values) => { console.log(values) },
  });

  const handleCheckboxChange = (event, questionId, memberId) => {
    console.log({event, questionId, memberId})
    formik.setFieldValue('questions', formik.values.questions.map((question) => {
      if (question.questionId === questionId) {
        question[memberId] = event.target.checked;
      }
      return question;
    }));
  };

  return (
    <div className="bg-white p-3 radius-12  mt-3 mb-3" style={{ textAlign: "left" }}>
      <div class="form-title pt-0 d-block mb-3">
        <h6 class="form-sub-title">Help us know the medical condition, if any</h6>
        <p className="text-grey mb-0">we'll only ask for the details insurance companied need</p>
      </div>

      {formik.values.questions.map((question) => {
        return (<Row>
          <Col sm={12} md={12} lg={12} className="pt-1">
            <label className="mb-1 fw-600">{question.questionDesc}</label>
            {/* <p className="text-grey mb-0">select member(s) who smoked or comsume alcohol</p> */}
          </Col>

          {props.healthQuoteFormikData.healthInsurefor.map((member) => {
            return <Col sm={12} md={12} lg={6} className="pt-0">
              <div className={`d-flex align-items-center cust-checkbox-wrap ${question[member.id] ? 'checkBox' : ''}`}>

                <label className="d-flex align-items-center">
                  <input
                    type="checkbox"
                    checked={question[member.id]}
                    onChange={(event) => handleCheckboxChange(event, question.questionId, member.id)}
                  />
                  <p className="mb-0 ml-4">
                    <span className="text-grey fs-10">{member.relation}</span>
                  </p>
                </label>
              </div>
            </Col>;
          })}
        </Row>)
      })}

    </div>
  );
}

export default MedicalQuestionnaire;
