import React, { useEffect,useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContainerForAgencyView from "../CommonComponents/ContainerForAgencyView"
import { useHistory } from 'react-router-dom';
import { decryptData } from "../../../services/auth-service";
export default function ViewAgency() {

  const history = useHistory();

  useEffect(() => {
    let user = decryptUser();

    if (!user) {
      console.log("no user");
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user = await decryptData(encryptedUser);
        return user;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

    return (
        <React.Fragment>
            <Container>
                <Row>
                    <h4 className="mb-5 mt-5">Agency</h4>

                    <Col className="mb-3">
                        <ContainerForAgencyView />
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}
