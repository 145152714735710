import React, { useState } from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { Form } from "react-bootstrap";

const Dropdown = ({ options, handleSet }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);
    setIsOpen(value.trim().length > 0);
  };

  const handleOptionSelect = (option) => {
    setInputValue(option);
    setIsOpen(false);
    handleSet(option);
  };

  return (
    <div style={{ position: "relative" }}>
      <Form.Control
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter Agency Name"
      />
      {isOpen && (
        <>
         <div style={{zIndex: '5', position: "absolute", border:"	rgb(211 211 211) solid 0.5px ", borderRadius:"1px",width:"100%"}}>
          {options
            .filter((option) =>
              option.agencyName.toLowerCase().includes(inputValue.toLowerCase())
            )
            .map((option, index) => (
              <div
                style={{zIndex: 3 ,  backgroundColor: "white",  paddingLeft: '10px', paddingRight: '10px', }}
                key={index}
                className="dropdown-option"
                onClick={() => handleOptionSelect(option.agencyName)}
              >
                {option.agencyName}
              </div>
            ))}
            </div>
        </>
      )}
    </div>
  );
};

export default Dropdown;
