import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ContainerForAgentView from "../CommonComponents/ContainerForAgentView";
import { useHistory } from "react-router-dom";
import { decryptData } from "../../../services/auth-service";

export default function ViewAgent() {
  const history = useHistory();
  const [user, setUser] = useState(null);

  useEffect(() => {
    let user1 = decryptUser();
    if (!user1) {
      console.log("no user");
      history.push("/login");
    }
  }, [history]);

  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <h4 className="mb-5 mt-5">Agent</h4>

          <Col className="mb-3">
            <ContainerForAgentView />
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}
