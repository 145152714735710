import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import UseFormContext from '../../../context/UseFormContext';
import { useHistory, useLocation } from 'react-router-dom';
import InputFieldText from '../../commonModules/InputFieldText';
import InputFieldDropdown from '../../commonModules/InputFieldDropdown';
import { useFormik } from 'formik';
import FloatingLabel from "react-bootstrap/FloatingLabel";
import reliance from "../../../images/reliance.png";
import Star from "../../../images/star.svg";
import Best from "../../../images/best-value.svg";
import Lowest from "../../../images/lowest.svg";
import futureGenrali from "../../../images/futureGenrali.png";
import ICICI from "../../../images/ICICI-insurance.png"
import Godigit from "../../../images/Godigit.png";
import NewIndia from "../../../images/new-india.png"
import SVG from "react-inlinesvg";
import { FullStar } from "../../pages/Rating/FullStar";
import { HalfStar } from "../../pages/Rating/HalfStar";
import { ZeroStar } from "../../pages/Rating/ZeroStar";
import { date } from 'yup';

const sampleQuote = [
  {
    "responseCode": "P365RES100",
    "message": "success",
    "status": 0,
    "data": {
      "quotes": [
        {
          "grossPremium": "1914.40",
          "serviceTax": "292.03",
          "netPremium": "1622.37",
          "minIdvValue": 26836,
          "maxIdvValue": 40253,
          "insuredDeclareValue": 33544,
          "ncbPercentage": "0",
          "policyDays": 1.04,
          "carrierVariantDisplayName": "HONDA ACTIVA HET Petrol",
          "ridersList": [
            {
              "riderName": "Personal Accident Cover",
              "riderId": 11
            }
          ],
          "policyType": "renew",
          "insuranceCompany": "GO DIGIT General Insurance CO. LTD",
          "productId": 16,
          "compulsoryDeductible": 20000,
          "claimIndex": 5,
          "claimRatio": "93.95%",
          "insurerIndex": 3.92,
          "carrierId": 61,
          "premiumRatio": 0.04456,
          "quoteType": 2,
          "productType": "Two Wheeler Comprehensive Insurance",
          "odpremium": 0,
          "discountList": [
            {
              "discountAmount": 0,
              "type": "NCB Discount",
              "discountId": 4
            }
          ],
          "llDriverCover": 0,
          "tppremium": 0,
          "basicCoverage": ".00",
          "totalDiscountAmount": ".00",
          "totalRiderAmount": ".00"
        }
      ],
      "status": 0,
      "encryptedQuoteId": "",
      "messageId": "856ecd60-0078-40cf-b5f0-1502bec877d0",
      "QUOTE_ID": ""
    },
    "QUOTE_ID": "BIKEQUOTE00176767",
    "messageId": "6be144e8-5d3a-42da-903a-5318ad5cad0e"
  },
  {
    "responseCode": "P365RES100",
    "message": "success",
    "status": 0,
    "data": {
      "quotes": [
        {
          "odpremium": 924.884,
          "tppremium": 842.52,
          "discount": 60,
          "insuredDeclareValue": 51122,
          "QuotationNo": "0000501301",
          "grossPremium": 1767.404,
          "ncbPercentage": "20",
          "carrierVariantDisplayName": "HONDA ACTIVA 5G petrol",
          "claimIndex": 4.03,
          "claimRatio": "75.72%",
          "insurerIndex": 4.29,
          "insuranceCompany": "Future Generali India Insurance Co. Ltd",
          "compulsoryDeductible": 100,
          "serviceTax": 269.60400000000004,
          "netPremium": 1497.8,
          "maxIdvValue": 0,
          "basicCoverage": "1767.40",
          "carrierId": 37,
          "ridersList": [

          ],
          "quoteType": 2,
          "CarDamage": "",
          "productType": "Two Wheeler Comprehensive Insurance",
          "discountList": [
            {
              "discountId": 2,
              "type": "NCB Discount",
              "discountAmount": 0
            },
            {
              "type": "OD Discount",
              "discountAmount": 0,
              "discountId": 3
            }
          ],
          "paidDriverCover": 0,
          "productId": 7,
          "minIdvValue": 0,
          "policyType": "renew",
          "totalDiscountAmount": ".00",
          "totalRiderAmount": ".00"
        }
      ],
      "QUOTE_ID": "BIKEQUOTE00176767",
      "encryptedQuoteId": "",
      "status": 0
    },
    "QUOTE_ID": "BIKEQUOTE00176767",
    "messageId": "12153989-d2f5-495d-9743-5a9e14c08e6e"
  }
];

const HealthQuote = (props) => {
  const formContext = UseFormContext();
  const location = useLocation();
  const history = useHistory();

  const [isFormDirty, setIsFormDirty] = useState(false);

  const [quotesResultsArray, setquotesResultsArray] = useState([]);

  const imgArrayForCard = {
    "Reliance General Insurance Co. Ltd": reliance,
    "New India Assurance": NewIndia,
    "Future Generali India Insurance Co. Ltd": futureGenrali,
    "GO DIGIT General Insurance CO. LTD": Godigit,
    "ICICI Lombard General Insurance Co. Ltd": ICICI,

  }

  const sumInsuredValues = [
    { label: "Select", value: "" },
    { label: "5 lakhs", value: "5" },
    { label: "7 lakhs", value: "7" },
    { label: "7.5 lakhs", value: "7.5" },
    { label: "10 lakhs", value: "10" },
    { label: "15 lakhs", value: "15" },
    { label: "20 lakhs", value: "20" },
    { label: "25 lakhs", value: "25" },
    { label: "30 lakhs", value: "30" },
    { label: "35 lakhs", value: "35" },
    { label: "40 lakhs", value: "40" },
    { label: "45 lakhs", value: "45" },
    { label: "50 lakhs", value: "50" },
    { label: "75 lakhs", value: "75" },
    { label: "1 crore", value: "100" },
    { label: "1.5 crores", value: "150" },
    { label: "2 crores", value: "200" },
  ];

  const policyTermValues = [
    { label: '1 Year', value: "1" },
    { label: '2 Year', value: "2" },
    { label: '3 Year', value: "3" },
  ];

  const initialData = {
    gender: location.state?.values?.gender,
    existanceIllness: location.state?.values?.existanceIllness,
    hospitalLimit: location.state?.values?.hospitalLimit,
    pincode: location.state?.values?.pincode,
    firstName: location.state?.values?.firstName,
    lastName: location.state?.values?.lastName,
    email: location.state?.values?.email,
    phoneNumber: location.state?.values?.phoneNumber,
    illnesses: {
      diabetes: location.state?.values?.illnesses.diabetes,
      bloodPressure: location.state?.values?.illnesses.bloodPressure,
      thyroid: location.state?.values?.illnesses.thyroid,
      asthma: location.state?.values?.illnesses.asthma,
      anySurgery: location.state?.values?.illnesses.anySurgery,
      otherDiseases: location.state?.values?.illnesses.otherDiseases,
    },
    sumInsured: location.state?.values?.hospitalLimit,
    healthInsurefor: JSON.parse(location.state?.values?.healthInsurefor),
    policyTerm: '1'
  };

  const formik = useFormik({
    initialValues: initialData,
    onSubmit: (values) => {
      console.log(values);
    }
  });

  useEffect(() => {
    for (const data of sampleQuote) {
      apiResopnse(data);
    }
  }, []);

  useEffect(() => {
    if (formik.dirty) {
      setIsFormDirty(true);
    }
  }, [formik?.values, formik?.dirty]);

  const handleCancel = () => {
    formik.resetForm();
    setTimeoutMethod();
  };
  const setTimeoutMethod = () => {
    setTimeout(() => {
      setIsFormDirty(false);
    }, 500);
  };

  const handleSave = () => {
    // formContext.setquotesPageFormikData(formik?.values)
    // formik.handleSubmit();
    setIsFormDirty(false);
  };

  const changeDOB = (event, memberId) => {
    formik.setFieldValue('healthInsurefor', formik.values.healthInsurefor.map((member) => {
      if (member.id === memberId) {
        member.dateOfBirth = event.target.value;
      }
      return member;
    }))
    formik.setIsFormDirty(true);
  };

  const apiResopnse = (data) => {
    const jsonData = data.data;
    if (jsonData?.quotes) {
      setquotesResultsArray((prevdata) => { return [...prevdata, ...jsonData?.quotes] })
    }
  }

  const giveRating = (rating) => {
    return (<div className="">
      {(rating >= 1)
        ? <FullStar />
        : (rating >= 0.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 2)
        ? <FullStar />
        : (rating >= 1.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 3)
        ? <FullStar />
        : (rating >= 2.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 4)
        ? <FullStar />
        : (rating >= 3.5)
          ? <HalfStar />
          : <ZeroStar />
      }
      {(rating >= 5)
        ? <FullStar />
        : (rating >= 4.5)
          ? <HalfStar />
          : <ZeroStar />
      }
    </div>
    )
  }

  return (
    <>
      <Container fluid className="get-quotes-wrap">
        <Row className='p-3 first-row'>
          <Col sm={12} md={12}>
            <Form>
              <Row className='d-flex'>
                <Col sm={12} md={6}>
                  <InputFieldDropdown formik={formik} formikFieldName="sumInsured" label="Sum Insured" optionsArray={sumInsuredValues} />
                </Col>
                <Col sm={12} md={6}>
                  <InputFieldDropdown formikFieldName="policyTerm" formik={formik} label="Policy Term" optionsArray={policyTermValues} />
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} style={{ textAlign: 'left' }}>Insurance For</Col>
                {formik.values.healthInsurefor.map((member) => {
                  return (
                    <>
                      <Col sm={6} md={2} className='d-flex justify-content-center align-items-center'>
                        <div>{member.relation}</div>
                      </Col>
                      <Col sm={6} md={4}>
                        <FloatingLabel
                          controlId={`dateOfBirth-${member.id}`}
                          label="Date of birth "
                          className="mb-3"
                          aria-autocomplete="off"
                        >
                          <Form.Control
                            name={`dateOfBirth-${member.id}`}
                            type="date"
                            placeholder=""
                            className="floating-input"
                            value={member.dateOfBirth}
                            max={new Date()?.toISOString()?.slice(0, 10)}
                            onChange={(event) => changeDOB(event, member.id)}
                          />
                        </FloatingLabel>
                      </Col>
                    </>
                  );
                })}
              </Row>
              {isFormDirty && (
                <div className="text-center mt-4 footer-btn-wrap">
                  <Button className="back-btn" onClick={handleCancel}>Cancel</Button>
                  <Button className="primary-btn" onClick={handleSave}>Save</Button>
                </div>
              )}
            </Form>
          </Col>
        </Row>
        <Row className=" card-wrap">
          {quotesResultsArray?.filter((filterData) => (Number(filterData?.netPremium)) !== 0)?.map((data, index) => {
            // Calculate the average of the three ratings
            const rating = (((data?.insurerIndex) + (data?.claimIndex) + 4.0) / 3)?.toFixed(1);
            return (
              <Card key={index}>
                <Card.Body>
                  <div className="card-top card-header">
                    <div className="logo-wrap">
                      <img
                        src={imgArrayForCard?.[data?.insuranceCompany]}
                        alt=""
                        className="logo"
                      />
                    </div>
                    <Button
                      variant="primary"
                      className="buy-btn"
                      onClick={() => {
                        formContext.setSelectedHealthQuoteData(data); formContext.setHealthQuoteFormikData(formik?.values); history.push("/healthProposal"); formContext.setloaderStatus(false)
                      }}
                    >
                      Buy Now
                    </Button>
                  </div>
                  <div className="offer-wrap">
                    {/* {(Number(data?.insuredDeclareValue)) === (Number(idvValue?.betstValue)) && < SVG src={Best} alt="" className="best-icon" fill="#d4d4d4" />} */}
                    {/* {(Number(data?.netPremium)) === (Number(idvValue?.netPremium)) && < SVG
                      src={Lowest}
                      alt=""
                      className="lowest-icon"
                      fill="#d4d4d4"
                    />} */}
                  </div>
                  <div className="card-miidle  card-details">
                    <div className="card-rating-wrap">
                      <div className="card_rating mb-0">
                        <span className="label-text">Rating</span>
                        <span className="label-text badge">{rating}</span>
                      </div>
                      <div className="star-icon-wrap">
                        {giveRating(rating)}
                      </div>
                    </div>
                    <div className="idv-wrap">
                      <p className="label-text">IDV</p>
                      <p className="idv-text">₹ {Math.floor(data?.insuredDeclareValue)}</p>
                    </div>
                    <div className="idv-wrap">
                      <p className="label-text">Premium</p>
                      <p className="premium-text">₹ {(Number(data?.netPremium)?.toFixed(0))}</p>
                    </div>
                  </div>
                  <div className="card-bottom rating d-flex gap-4">
                    <div className="premium">
                      <div className="star rating-star">
                        <SVG
                          src={Star}
                          alt=""
                          className="rating-star-icon"
                          fill="#d4d4d4"
                        />
                        <span className="rating-point">4.0</span>
                      </div>
                      <p className="premium-text">Premium</p>
                    </div>
                    <div className="premium">
                      <div className="star rating-star">
                        <SVG
                          src={Star}
                          alt=""
                          className="rating-star-icon"
                          fill="#d4d4d4"
                        />
                        <span className="rating-point">{(data?.insurerIndex)?.toFixed(1)}</span>
                      </div>
                      <p className="premium-text">Servicing</p>
                    </div>
                    <div className="premium">
                      <div className="star rating-star">
                        <SVG
                          src={Star}
                          alt=""
                          className="rating-star-icon"
                          fill="#d4d4d4"
                        />
                        <span className="rating-point">{(data?.claimIndex)?.toFixed(1)}</span>
                      </div>
                      <p className="premium-text">Claims</p>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            )
          })}
        </Row>
      </Container>
    </>
  );
};

export default HealthQuote;
