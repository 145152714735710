import axios from "axios";
import AuthHeader from "./auth-header";

const AGENCY_URL = process.env.REACT_APP_AGENCY_URL;
class MailService {

  sendMail(sendMailRequest) {

    return axios.post(
      `${AGENCY_URL}/sendEmail`,
      sendMailRequest,
      {
        headers: AuthHeader(),
      }
    );
  }

}

export default new MailService();
